import InactiveAccountInfo from './InactiveAccountInfo';
import SubscriptionIssueModal from './SubscriptionIssueModal';

const InactiveAccountInfoModal = ({
    organizationName,
    activeUsersCount,
}: {
    organizationName: string;
    activeUsersCount: number;
}) => {
    return (
        <SubscriptionIssueModal organizationName={organizationName} activeUsersCount={activeUsersCount}>
            <InactiveAccountInfo />
        </SubscriptionIssueModal>
    );
};

export default InactiveAccountInfoModal;
