import { FC } from 'react';
import { Loader2 } from 'lucide-react';
import { PlanCode, SubscriptionDetailsDto, SubscriptionStatus } from '@zaplify/subscriptions';
import { useChargebeeSubscription } from '../hooks/use-chargebee-subscription';
import { SubscriptionDetails } from './components/subscription-details';
import { BillingDetails } from './components/billing-details';
import { CancelSubscriptionDialog } from './components/dialogs/cancel-subscription-dialog';
import { RenewalSubscriptionDialog } from './components/dialogs/renewal-subscription-dialog';
import { PickPlanDialog } from './components/dialogs/pick-plan-dialog';
import { useParams, useNavigate } from 'react-router-dom';
import { SuccessDialog } from './components/dialogs/success-dialog';
import { useAuth } from '../../../providers/authentication-provider';
import { getZaplifySdk } from '@zaplify/sdk';
import { useToast } from '@shadcn/ui/hooks/use-toast';

type ModalType = 'renew-plan' | 'cancel-plan' | 'upgrade-plan' | 'success' | 'none';

export const SubscriptionPage: FC = () => {
    const {
        subscription,
        isLoadingSubscription,
        loadSelfServicePortal,
        requestSubscriptionCancel,
        refetchSubscription,
    } = useChargebeeSubscription();
    const {
        reloadClaims,
        authState: { userOrganizationId },
    } = useAuth();
    // const [activeModal, setActiveModal] = useState<ModalType>('none');
    const { activeModal } = useParams<{ activeModal: ModalType }>();
    const navigate = useNavigate();
    const { toast } = useToast();

    if (isLoadingSubscription) {
        return (
            <div className="flex items-center justify-center h-full">
                <Loader2 className="w-6 h-6 animate-spin" />
            </div>
        );
    }

    if (!subscription) {
        return <p>No subscription found</p>;
    }

    const subscriptionDetails = subscription as SubscriptionDetailsDto;
    const isEnterprise = subscriptionDetails.subscription?.current?.planCode === PlanCode.ENTERPRISE;
    const isLegacy = subscriptionDetails.subscription?.isLegacy;
    const isLockedFromSelfService = isEnterprise || isLegacy;

    const handleShowModal = (type: ModalType) => {
        navigate(`./${type}`);
    };

    const handleCloseModal = () => {
        navigate('..', { relative: 'path' });
    };

    const handleCancelSubscription = async () => {
        try {
            await requestSubscriptionCancel();
            refetchSubscription().then(() => {
                handleCloseModal();
            });
        } catch (error) {
            console.error('Failed to cancel subscription:', error);
        }
    };

    const handleRenewSubscription = async () => {
        const profilesSdk = getZaplifySdk().profiles;
        const upgradePlanDTO = {
            numberOfSeats: subscriptionDetails.subscription.current.seatCount,
            billingPeriod: {
                amount: subscriptionDetails.subscription.current.periodAmount,
                unit: subscriptionDetails.subscription.current.periodUnit,
            },
            creditNumber: subscriptionDetails.subscription.current.credits,
            planCode: subscriptionDetails.subscription.current.planCode,
            couponCode: '',
            oneTimeCredits: 0,
        };

        try {
            await profilesSdk.subscription.requestSubscriptionUpdate(userOrganizationId, upgradePlanDTO);
            toast({
                title: 'Plan renewed successfully',
                description: 'Your plan has been renewed successfully',
                variant: 'success',
            });
        } catch (error) {
            toast({
                title: 'Failed to renew plan',
                description: 'Please try again',
                variant: 'error',
            });
        } finally {
            refetchSubscription().then(() => {
                handleCloseModal();
            });
        }
    };

    const handleSuccess = () => {
        navigate('../success', { relative: 'path' });
    };

    return (
        <div className="space-y-4">
            <SubscriptionDetails
                subscription={subscriptionDetails}
                isLockedFromSelfService={isLockedFromSelfService}
                onUpgradePlan={() => handleShowModal('upgrade-plan')}
                onCancelPlan={() => handleShowModal('cancel-plan')}
                onRenewPlan={() => handleShowModal('renew-plan')}
            />

            <BillingDetails
                subscription={subscriptionDetails}
                onLoadSelfServicePortal={loadSelfServicePortal}
                refetchSubscription={refetchSubscription}
            />

            <CancelSubscriptionDialog
                isOpen={activeModal === 'cancel-plan'}
                onClose={handleCloseModal}
                onConfirm={handleCancelSubscription}
            />

            <RenewalSubscriptionDialog
                isOpen={activeModal === 'renew-plan'}
                onClose={handleCloseModal}
                onConfirm={handleRenewSubscription}
                renewalDate={
                    subscriptionDetails.subscription.renewalAt
                        ? new Date(subscriptionDetails.subscription.renewalAt).toLocaleDateString('en-US', {
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric',
                          })
                        : ''
                }
            />

            <PickPlanDialog
                isOpen={activeModal === 'upgrade-plan'}
                onClose={handleCloseModal}
                onSuccess={handleSuccess}
            />

            <SuccessDialog
                isOpen={activeModal === 'success'}
                onClose={() => {
                    Promise.all([reloadClaims(userOrganizationId), refetchSubscription()]).then(() => {
                        handleCloseModal();
                    });
                }}
            />
        </div>
    );
};

export default SubscriptionPage;
