import { BookOpen, Users, ChevronDown } from 'lucide-react';
import { Card, CardContent } from '@shadcn/ui/components/ui/card';
import { useQuery } from '@apollo/client';
import { GET_PLAYBOOKS, GET_TOTAL_NUMBER_OF_USER_CONTACTS_IN_ANY_PLAYBOOK } from '@zaplify/graphql';
import { useMemo, useState } from 'react';
import { AnimatePresence, motion } from 'motion/react';
import { cn } from '@shadcn/ui/lib/utils';
import { TypewriterText } from '../../../../components/typewriter-text';

export default function FirstPlaybookCard() {
    const [isExpanded, setIsExpanded] = useState(false);
    const { data: playbooks } = useQuery(GET_PLAYBOOKS, {
        variables: {
            name: '%',
        },
    });
    const firstPlaybook = useMemo(() => playbooks?.Groups?.[0], [playbooks]);

    if (!firstPlaybook) {
        return null;
    }

    return (
        <Card className="overflow-hidden border-l-4 border-l-blue-500">
            <CardContent className="p-0">
                <div className="bg-blue-50 px-4 py-2 flex items-center gap-2">
                    <BookOpen size={16} className="text-blue-600" />
                    <span className="font-medium text-sm">Playbooks guide Andsend towards your goals</span>
                </div>
                <div className="p-4">
                    <p className="text-gray-600 text-sm pb-3">
                        <TypewriterText
                            text={`Based on your profile, we've created your first playbook to help you ${firstPlaybook.AssistantSetting.purpose.toLowerCase()}. We're analyzing your recent conversations and assigning a few contacts to your Playbook to help you get started.`}
                            delay={0.5}
                        />
                    </p>

                    <div className="">
                        <div className="flex justify-start gap-2 items-center mb-4">
                            <img src={firstPlaybook.imgSrc} alt={firstPlaybook.name} className="w-10 h-10 rounded-md" />
                            <h3 className="text-lg font-medium">{firstPlaybook.name}</h3>
                        </div>
                        {firstPlaybook.AssistantSetting.context && (
                            <div className="relative">
                                <AnimatePresence initial={false}>
                                    <motion.div
                                        className="bg-background-secondary p-4 rounded-md border border-background-secondary shadow-sm relative overflow-hidden"
                                        initial={{ height: '130px' }}
                                        animate={{ height: isExpanded ? 'auto' : '130px' }}
                                        transition={{ duration: 0.3, ease: 'easeInOut' }}
                                    >
                                        <h3 className="text-sm font-medium mb-2">
                                            Initial Playbook instructions based on your profile:
                                        </h3>
                                        <p className="text-gray-600 text-xs whitespace-pre-line">
                                            {firstPlaybook.AssistantSetting.context}
                                        </p>

                                        {!isExpanded && (
                                            <motion.div
                                                className="absolute bottom-0 left-0 right-0 h-12 bg-gradient-to-t from-background-secondary to-transparent"
                                                initial={{ opacity: 0 }}
                                                animate={{ opacity: 1 }}
                                                exit={{ opacity: 0 }}
                                            />
                                        )}
                                    </motion.div>

                                    <motion.div
                                        className="cursor-pointer flex items-end justify-center py-2"
                                        onClick={() => setIsExpanded(!isExpanded)}
                                        initial={{ opacity: 0.8 }}
                                        whileHover={{ opacity: 1, scale: 1.02 }}
                                        whileTap={{ scale: 0.98 }}
                                    >
                                        <div className="flex items-center gap-1 text-sm text-gray-600 hover:text-gray-800 transition-colors">
                                            <span>{isExpanded ? 'Show less' : 'Show more'}</span>
                                            <ChevronDown
                                                size={16}
                                                className={cn(
                                                    'transition-transform duration-300',
                                                    isExpanded && 'transform rotate-180'
                                                )}
                                            />
                                        </div>
                                    </motion.div>
                                </AnimatePresence>
                            </div>
                        )}
                    </div>
                </div>
                <div className="bg-blue-50/50 p-4 border-t border-blue-100">
                    <div className="flex items-start gap-2">
                        <div className="mt-1">
                            <BookOpen size={16} className="text-blue-600" />
                        </div>
                        <div>
                            <h4 className="font-medium text-sm mb-1">
                                Playbooks affect your messages and recommendations
                            </h4>
                            <p className="text-xs text-gray-600">
                                The Playbooks you create will help you organize your network and customize your
                                communication approach for different contacts. Each playbook can have their own goal,
                                target group, and custom instructions.
                            </p>
                        </div>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
}
