import { IEvent, Topics } from '@zaplify/common';
import { AccountLocationDto, IUserData, UserRole } from '../users';

export class UserCreatedAccountEvent extends IEvent {
    public static override readonly TOPIC = Topics.USERS;
    id: any;
    userId: string;
    firebaseId: string;
    organizationId: string;
    email: string;
    firstName: string;
    lastName: string;
    location: AccountLocationDto;
    isSubscribed: boolean;
    roles: UserRole[];
    createdAt?: Date;

    constructor(user: IUserData) {
        super();
        this.id = user.id;
        this.userId = user.id;
        this.firebaseId = user.firebaseId;
        this.organizationId = user.userOrganizationId;
        this.email = user.email;
        this.firstName = user.firstName;
        this.lastName = user.lastName;
        this.location = user.location;
        this.isSubscribed = user.isSubscribed;
        this.roles = user.roles;
        this.createdAt = user.createdAt;
    }

    get partitionKey(): string {
        if (!this.organizationId) {
            return this.id;
        }

        return this.organizationId;
    }
}
