import {
    InvitationDto,
    UserOrInvitationRowDto,
    UserOrganizationComposedDto,
    OrganizationRewardsDto,
    UpdateUserOrganizationDto,
} from '@zaplify/users/shared';
import { BaseSdk } from '../../base.sdk';

export class userOrganizationSdk extends BaseSdk {
    async getUsersByOrganization(userOrganizationId: string) {
        return this.get<UserOrInvitationRowDto[]>(`${this.userOrganizationsPath}/${userOrganizationId}/users`);
    }

    async getOrganizationOfferingSummary(): Promise<string | null> {
        return this.get<string>(`${this.userOrganizationsPath}/offering-summary`);
    }

    async getUsersAndInvitationsByOrganization(userOrganizationId: string) {
        return this.get<(UserOrInvitationRowDto | InvitationDto)[]>(
            `${this.userOrganizationsPath}/${userOrganizationId}/users?include=invitations`,
        );
    }

    async getOrganizationByUser(userId: string) {
        return this.get<UserOrganizationComposedDto[]>(`${this.userOrganizationsPath}/byUser/${userId}`);
    }

    async getOrganizationRewards(organizationId: string) {
        return this.get<OrganizationRewardsDto>(`${this.userOrganizationsPath}/${organizationId}/rewards`);
    }

    async updateOrganization(organizationId: string, organizationDto: UpdateUserOrganizationDto) {
        return this.put<void>(`${this.userOrganizationsPath}/${organizationId}`, organizationDto);
    }

    async getOfferingSummary() {
        return this.get<string>(`${this.userOrganizationsPath}/offering-summary`);
    }

    async getActiveUsersCount(organizationId: string) {
        return this.get<{ count: number }>(`${this.userOrganizationsPath}/${organizationId}/users/activeUsersCount`);
    }
}
