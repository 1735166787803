import * as React from 'react';
import { Skeleton } from '@shadcn/ui/components/ui/skeleton';
import { cn } from '@shadcn/ui/lib/utils';

export const ConversationCardSkeleton = React.forwardRef<
    HTMLDivElement,
    React.ComponentProps<'div'> & { withBadge?: boolean }
>(({ withBadge = true, ...props }, ref) => {
    return (
        <div ref={ref} className={cn('flex gap-2', 'rounded-md', 'p-2', props.className)}>
            <Skeleton className="h-8 w-8 rounded-full bg-background-brand-primary-subtle" />
            <div className="flex flex-col gap-1 items-start">
                <div className="flex items-center gap-1">
                    <Skeleton className="h-4 w-44 bg-background-brand-primary-subtle" />
                </div>
                {withBadge && <Skeleton className="h-6 w-24 rounded-full bg-background-brand-primary-subtle" />}
                <Skeleton className="h-4 w-48 bg-background-brand-primary-subtle" />
            </div>
        </div>
    );
});
