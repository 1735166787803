import { Theme, adaptV4Theme, createTheme } from '@mui/material/styles';
import { palette } from './palette';

export const v5Theme: Theme = createTheme({
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    MuiOutlinedInput: {
                        border: 'none',
                        height: '1.1876em',
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    height: '1.1876em',
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                inputRoot: {
                    paddingBottom: '1px',
                    paddingRight: '5px',
                    '& .MuiInput-input': {
                        padding: '6px 0',
                    },
                },
                input: {
                    height: '1.1876em',
                    padding: '6px 0',
                },
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    '& .MuiSvgIcon-fontSizeSmall': {
                        fontSize: '20px',
                    },
                    fontSize: '24px',
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    svg: {
                        fontSize: '20px',
                    },
                },
            },
        },
    },
    palette: palette,
    typography: {
        fontFamily: [
            'Helvetica Neue',
            'Inter',
            'Arial',
            '-apple-system',
            'BlinkMacSystemFont',
            'Segoe UI',
            'Roboto',
            'Oxygen',
            'Ubuntu',
            'Cantarell',
            'Fira Sans',
            'Droid Sans',
            'Helvetica',
            'sans-serif',
        ].join(','),
        fontSize: 15,
        h1: {
            color: palette.text.primary,
            fontWeight: 500,
            fontSize: '35px',
            letterSpacing: '-0.24px',
            lineHeight: '40px',
        },
        h2: {
            color: palette.text.primary,
            fontWeight: 500,
            fontSize: '29px',
            letterSpacing: '-0.24px',
            lineHeight: '32px',
        },
        h3: {
            color: palette.text.primary,
            fontWeight: 500,
            fontSize: '24px',
            letterSpacing: '-0.06px',
            lineHeight: '28px',
        },
        h4: {
            color: palette.text.primary,
            fontWeight: 500,
            fontSize: '20px',
            letterSpacing: '-0.06px',
            lineHeight: '24px',
        },
        h5: {
            color: palette.text.primary,
            fontWeight: 500,
            fontSize: '16px',
            letterSpacing: '-0.05px',
            lineHeight: '20px',
        },
        h6: {
            color: palette.text.primary,
            fontWeight: 500,
            fontSize: '14px',
            letterSpacing: '-0.05px',
            lineHeight: '20px',
        },
        subtitle1: {
            color: palette.text.primary,
            fontSize: '16px',
            letterSpacing: '-0.05px',
            lineHeight: '25px',
        },
        subtitle2: {
            color: palette.text.secondary,
            fontWeight: 400,
            fontSize: '14px',
            letterSpacing: '-0.05px',
            lineHeight: '21px',
        },
        body1: {
            color: palette.text.primary,
            fontSize: '15px',
            letterSpacing: '-0.05px',
            lineHeight: '21px',
        },
        body2: {
            color: palette.text.secondary,
            fontSize: '13px',
            letterSpacing: '-0.04px',
            lineHeight: '18px',
        },
        button: {
            color: palette.text.primary,
            fontSize: '14px',
            textTransform: 'none',
        },
        caption: {
            color: palette.text.secondary,
            fontSize: '11px',
            letterSpacing: '0.33px',
            lineHeight: '13px',
        },
        overline: {
            color: palette.text.secondary,
            fontSize: '11px',
            fontWeight: 500,
            letterSpacing: '0.33px',
            lineHeight: '13px',
            textTransform: 'uppercase',
        },
    },
});

export const oldThemeShadow = {
    g: '0px 10px 40px rgba(100, 111, 121, 0.12)',
    background: '0px 10px 40px rgba(100, 111, 121, 0.12)',
    card: '0px 2px 5px rgba(0, 0, 0, 0.05)',
    hover: '0px 10px 40px rgba(100, 111, 121, 0.12)',
    main: '0px 2px 20px rgba(0, 15, 94, 0.05)',
};

export const theme: Theme = createTheme(
    adaptV4Theme({
        overrides: {
            // Style sheet name ⚛️
            MuiTooltip: {
                // Name of the rule
                arrow: {
                    color: palette.black,
                },
                tooltip: {
                    backgroundColor: palette.black,
                },
            },
        },
        palette,
        shadow: {
            g: '0px 10px 40px rgba(100, 111, 121, 0.12)',
            background: '0px 10px 40px rgba(100, 111, 121, 0.12)',
            card: '0px 2px 5px rgba(0, 0, 0, 0.05)',
            hover: '0px 10px 40px rgba(100, 111, 121, 0.12)',
            main: '0px 2px 20px rgba(0, 15, 94, 0.05)',
        },
        typography: {
            fontFamily: [
                'Helvetica Neue',
                'Inter',
                'Arial',
                '-apple-system',
                'BlinkMacSystemFont',
                'Segoe UI',
                'Oxygen',
                'Ubuntu',
                'Cantarell',
                'Fira Sans',
                'Droid Sans',
                'Helvetica',
                'sans-serif',
            ].join(','),
            h1: {
                color: palette.text.primary,
                fontWeight: 500,
                fontSize: '35px',
                letterSpacing: '-0.24px',
                lineHeight: '40px',
            },
            h2: {
                color: palette.text.primary,
                fontWeight: 500,
                fontSize: '29px',
                letterSpacing: '-0.24px',
                lineHeight: '32px',
            },
            h3: {
                color: palette.text.primary,
                fontWeight: 500,
                fontSize: '24px',
                letterSpacing: '-0.06px',
                lineHeight: '28px',
            },
            h4: {
                color: palette.text.primary,
                fontWeight: 500,
                fontSize: '20px',
                letterSpacing: '-0.06px',
                lineHeight: '24px',
            },
            h5: {
                color: palette.text.primary,
                fontWeight: 500,
                fontSize: '16px',
                letterSpacing: '-0.05px',
                lineHeight: '20px',
            },
            h6: {
                color: palette.text.primary,
                fontWeight: 500,
                fontSize: '14px',
                letterSpacing: '-0.05px',
                lineHeight: '20px',
            },
            subtitle1: {
                color: palette.text.primary,
                fontSize: '16px',
                letterSpacing: '-0.05px',
                lineHeight: '25px',
            },
            subtitle2: {
                color: palette.text.secondary,
                fontWeight: 400,
                fontSize: '14px',
                letterSpacing: '-0.05px',
                lineHeight: '21px',
            },
            body1: {
                color: palette.text.primary,
                fontSize: '15px',
                letterSpacing: '-0.05px',
                lineHeight: '21px',
            },
            body2: {
                color: palette.text.secondary,
                fontSize: '13px',
                letterSpacing: '-0.04px',
                lineHeight: '18px',
            },
            button: {
                color: palette.text.primary,
                fontSize: '14px',
                textTransform: 'none',
            },
            caption: {
                color: palette.text.secondary,
                fontSize: '11px',
                letterSpacing: '0.33px',
                lineHeight: '13px',
            },
            overline: {
                color: palette.text.secondary,
                fontSize: '11px',
                fontWeight: 500,
                letterSpacing: '0.33px',
                lineHeight: '13px',
                textTransform: 'uppercase',
            },
        },
    } as any),
);
