import { useMutation } from '@tanstack/react-query';
import { Dispatch } from 'react';
import { SET_NOTIFICATION } from '../constants/state';
import { ConfirmEmailAction } from '../types/state';
import { getAuth, sendEmailVerification } from 'firebase/auth';

const useResendEmailVerification = ({ dispatch }: { dispatch: Dispatch<ConfirmEmailAction> }) => {
    const {
        mutate: resendEmailVerification,
        status: resendStatus,
        error: resendError,
    } = useMutation({
        mutationFn: async () => {
            const user = getAuth().currentUser;
            if (!user) {
                throw new Error('User is not authenticated');
            }
            return await sendEmailVerification(user);
        },
        onSuccess: (data) => {
            console.log(`🚀 [ConfirmEmail] resendEmailVerification > onSuccess: ${JSON.stringify(data)}`);
            dispatch({
                type: SET_NOTIFICATION,
                payload: { isVisible: true, message: 'Email sent', severity: 'success' },
            });
        },
        onError: (data) => {
            console.error(`🔥 [ConfirmEmail] resendEmailVerification > onError: ${JSON.stringify(data)}`);
            dispatch({
                type: SET_NOTIFICATION,
                payload: { isVisible: true, message: data.message, severity: 'error' },
            });
        },
    });

    return { resendEmailVerification, resendStatus, resendError };
};

export default useResendEmailVerification;
