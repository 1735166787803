import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { LocalStorageKeys } from '../../../../config';
import { useEffect } from 'react';
import { useMediaQuery } from '@mui/material';

const navigationSidebarOpenAtom = atomWithStorage<boolean | undefined>(
    LocalStorageKeys.NAVIGATION_SIDEBAR_OPEN,
    undefined,
    undefined,
    {
        getOnInit: true,
    }
);

export const useSidebarCollapseMemory = () => {
    const [isNavigationSidebarOpen, setIsNavigationSidebarOpen] = useAtom(navigationSidebarOpenAtom);
    const matches = useMediaQuery('(max-width: 1390px)');

    useEffect(() => {
        if (isNavigationSidebarOpen === undefined) {
            setIsNavigationSidebarOpen(!matches);
        }
    }, [isNavigationSidebarOpen, matches]);

    return { isNavigationSidebarOpen, setIsNavigationSidebarOpen };
};
