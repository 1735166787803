import React from 'react';
import { cn } from '@shadcn/ui/lib/utils';
import { paths } from '../../../routes/paths';

// Basic styles for text content
const pStyle = 'text-base my-2 mb-4';
const h2Style = 'text-2xl font-semibold mt-8 mb-4';
const h3Style = 'text-xl font-semibold mt-6 mb-3';
const linkStyle = 'text-primary underline hover:text-primary/90';
const listItemStyle = 'ml-6 my-1';
const listAlphaStyle = 'list-[lower-alpha] pl-6 mt-2';
const listNumberStyle = 'list-decimal pl-6 mb-4';

export default function TermsOfService20241219() {
    return (
        <div className="container mx-auto py-12 px-4 max-w-4xl">
            <h1 className="text-4xl font-bold mb-6">Andsend Terms and Conditions</h1>

            <div>
                <h4 className="text-xl mb-8">Effective Date: December 19, 2024</h4>

                <p className={pStyle}>
                    This version of the T&C's is effective for new Customers on or after December 19, 2024. If you are
                    an existing Customer or previously agreed to an older version of these T&C's before December 19,
                    2024, this current version of the T&C's set forth below will be effective on January 31, 2025 and
                    will completely replace any prior version to which you agreed. Please be aware that your continued
                    use of the Service after January 31, 2025 constitutes your acceptance of this T&Cs. The most recent
                    prior version of the T&C is available{' '}
                    <a href={paths.LEGAL.TERMS_2023_07_09} className={linkStyle}>
                        here
                    </a>
                    .
                </p>

                <p className={pStyle}>
                    These Terms and Conditions of Software-as-a-Service ("T&Cs") entered into, on the Effective Date, by
                    and between Andsend Group AB (organization number 559489-6481), with its primary office located at
                    Drottninggatan 33, 111 51, Stockholm, Sweden ("Andsend") and the customer set forth on the Order
                    ("Customer"). Customer and Andsend are referred to as "Parties" and each individually as "Party".
                </p>
                <p className={cn(pStyle, 'uppercase font-medium')}>
                    BY ACCEPTING THESE T&Cs, BY (1) CLICKING A BOX INDICATING ACCEPTANCE, (2) EXECUTING AN ORDER THAT
                    REFERENCES THESE T&Cs, OR (3) USING ANY FREE PRODUCT, CUSTOMER AGREES TO THE TERMS OF THESE T&Cs
                    AND, AS APPLICABLE, THE ORDER. IF THE INDIVIDUAL ACCEPTING THIS AGREEMENT IS ACCEPTING ON BEHALF OF
                    A COMPANY OR OTHER LEGAL ENTITY, SUCH INDIVIDUAL REPRESENTS THEY HAVE THE AUTHORITY TO BIND SUCH
                    ENTITY TO THESE TERMS AND CONDITIONS, IN WHICH CASE THE TERM "CUSTOMER" SHALL REFER TO SUCH ENTITY.
                    IF THE INDIVIDUAL ACCEPTING THIS AGREEMENT DOES NOT HAVE SUCH AUTHORITY, OR DOES NOT AGREE WITH
                    THESE TERMS AND CONDITIONS, SUCH INDIVIDUAL MUST NOT ACCEPT THIS AGREEMENT AND MAY NOT USE THE
                    PRODUCTS. READ THESE T&CS, THE DPA, IF APPLICABLE, AND THE ORDER (COLLECTIVELY, THE "AGREEMENT")
                    CAREFULLY. BY USING ANY PRODUCT: (i) YOU AGREE TO THE TERMS AND CONDITIONS SET FORTH IN THE
                    AGREEMENT, AND A LEGALLY BINDING AGREEMENT IS ENTERED INTO BY AND BETWEEN YOU AND ANDSEND.
                </p>

                <h2 className={h2Style}>Background</h2>
                <p className={pStyle}>
                    Andsend has developed certain software-as-a-service Products used by companies in connection with
                    business-to-business sales enablement; and Customer desires to use one or more Product(s) and to
                    enter into the related Product subscriptions as set forth in the Order.
                </p>

                <h2 className={h2Style}>1. Definitions</h2>
                <p className={pStyle}>
                    <strong>1.1 "Authorized User"</strong> – means an individual employee or consultant of Customer who
                    is authorized by Customer to use the Product(s) on behalf of Customer. Customer shall ensure
                    Authorized Users comply with all terms of this T&Cs.
                </p>
                <p className={pStyle}>
                    <strong>1.2 "Confidential Information"</strong> – means (a) the Products and any trade secrets or
                    non-public information related to the foregoing and (b) all other information which is disclosed by
                    Andsend or Customer (hereinafter referred to as the "Disclosing Party") to the other Party
                    (hereinafter referred to as the "Receiving Party") orally, electronically, visually, or in a
                    document or other tangible form or medium, which is either identified as or should be reasonably
                    understood to be confidential and/or proprietary.
                </p>
                <p className={pStyle}>
                    <strong>1.3 "Customer Data"</strong> – means data, files, Authorized User information, hypertext
                    markup language files, documents, audio and visual information, graphics, scripts, programs, applets
                    or servlets that Customer creates, installs, uploads to or transfers in or through the Products or
                    provides in the course of using the Products.
                </p>
                <p className={pStyle}>
                    <strong>1.4 "Effective Date"</strong> – means the effective date of the first Order (whether this be
                    registration for a Free Product Version or the submission of a fee-based Order). These T&Cs govern
                    such initial Order and any future purchases by Customer which reference these T&Cs.
                </p>
                <p className={pStyle}>
                    <strong>1.5 "Data Processing Agreement" or "DPA"</strong> - means the data processing agreement, if
                    applicable, incorporated into these T&Cs.
                </p>
                <p className={pStyle}>
                    <strong>1.6 "Derivatives"</strong> – means (a) for copyrighted or copyrightable material, any
                    abridgment, revision, translation, enhancement, improvement, modification, expansion, compilation or
                    other form in which an existing work may be recast, transformed or adapted, (b) for patented or
                    patentable material, any improvement thereof, or (c) for trade secrets, any new material derived
                    from such existing trade secret material, including new material which may be protected by
                    copyright, patent or trade secret.
                </p>
                <p className={pStyle}>
                    <strong>1.7 "Feedback"</strong> – means any and all information (in oral, written, electronic or any
                    other form) communicated voluntarily by Customer, directly or indirectly, to Andsend, under this
                    T&Cs or otherwise pertaining to Products, services, or Confidential Information, including, without
                    limitation, performance data, error data, reports, comments, requests, and suggestions.
                </p>
                <p className={pStyle}>
                    <strong>1.8 "Fees"</strong> – means the fees, as applicable, set forth in the Order and subject to
                    change from time-to-time pursuant to the terms of this Agreement. To the extent Customer desires to
                    purchase any consulting services from Andsend, such consulting services shall be subject to a
                    separate agreement (specifying fees and deliverable(s)), in writing, by and between the Parties.
                </p>
                <p className={pStyle}>
                    <strong>1.9 "Free Product Version"</strong> – means any Product which is offered, in writing, by
                    Andsend and used by Customer on a free-of-charge basis.
                </p>
                <p className={pStyle}>
                    <strong>1.10 "Intellectual Property Rights" or "IPR"</strong> – means any and all now or hereafter
                    known tangible and intangible: (a) right associated with works of authorship throughout the world,
                    including but not limited to, copyrights, moral rights, and mask works; (b) trademarks and trade
                    name rights and similar rights, throughout the world; (c) trade secret and know-how rights
                    throughout the world; (d) patents, designs, algorithms, and other intellectual and/or industrial
                    property rights (of every kind and nature throughout the world and however designated) whether
                    arising by operation of law, contract, license or otherwise; (e) all registrations, initial
                    applications, renewals, extension, continuations, divisions or re-issues thereof now or hereafter in
                    force; and (f) Derivatives.
                </p>
                <p className={pStyle}>
                    <strong>1.11 "Order"</strong> – means any Andsend Order Form, online registration or order
                    confirmation referencing these T&Cs. Any Order placed online for fee-based Products and/or regarding
                    any registration for a Free Product Version at the website domain name andsend.com or any
                    subordinate subdomains (including but not limited to app.andsend.com) shall be subject to any
                    restrictions set forth on such website with respect to such Order.
                </p>
                <p className={pStyle}>
                    <strong>1.12 "Products"</strong> – mean Andsend SaaS offerings as set forth in the Order.
                </p>
                <p className={pStyle}>
                    <strong>1.13 "Product Upgrade" or "Upgrade"</strong> – means new Orders or upgrades of the Products,
                    an increase in the number of Authorized Users during the term of the Agreement, and/or new fee-based
                    features or expanded usage levels.
                </p>
                <p className={pStyle}>
                    <strong>1.14 "T&Cs"</strong> – means this agreement between Customer and Andsend and shall include
                    the Order(s), DPA, if applicable, and any other Andsend provided legal documents. The T&Cs are
                    subject to change from time to time and any updated version shall take effect upon any subsequent
                    purchase and/or auto renewal.
                </p>

                <h2 className={h2Style}>2. Access</h2>
                <p className={pStyle}>
                    <strong>2.1</strong> A high-speed Internet connection is required for proper use of the Products.
                    Customer is responsible for procuring and maintaining the network connections that connect its
                    network to the Products, including, but not limited to, updated latest or second latest versions of
                    the applicable "web browsers", such as, for example, Microsoft Internet Explorer, Google Chrome, or
                    Firefox and to follow procedures for accessing services that support the access to the Products.
                    Andsend is not responsible for notifying Customer of any upgrades, fixes, or enhancements to any
                    such software or for any compromise of data, including Customer Data, transmitted across computer
                    networks or telecommunications facilities (including but not limited to the Internet) which are not
                    owned, operated, or controlled by Andsend. Andsend assumes no responsibility for the reliability or
                    performance of any connections as described in this section.
                </p>
                <p className={pStyle}>
                    <strong>2.2</strong> Products shall be generally accessible to Customer within 5 business days
                    following Andsend's receipt of the full, initial payment of Fees with respect to the applicable
                    Products.
                </p>

                <h2 className={h2Style}>3. Grants</h2>
                <p className={pStyle}>
                    <strong>3.1</strong> Subject to the terms and conditions of the Agreement and any applicable fees,
                    Andsend hereby grants Customer, during the term of the Agreement, a non-exclusive, non-transferable,
                    time-limited, right to access the Products for the sole purpose of using the Products and for the
                    storage of Customer information therein. Products may only be used by Customer, or any authorized
                    third parties, for such purposes as permitted by Andsend in writing. To use Products, Customer must
                    create valid user account(s) for each Authorized User and keep all information current, accurate,
                    and true.
                </p>
                <p className={pStyle}>
                    <strong>3.2</strong> For the avoidance of doubt, it is expressly agreed that the Customer's right to
                    use Products shall include an obligation for Customer and Authorized Users to comply with all
                    instructions and guidelines generally issued by Andsend during the term of the Agreement.
                </p>
                <p className={pStyle}>
                    <strong>3.3</strong> As a condition of Customer's use of the Products, Customer and Authorized Users
                    will not use the Products for any purpose that is unlawful, prohibited by or not expressly permitted
                    under this T&Cs. Customer and Authorized Users may not use Products in any manner that in Andsend's
                    sole discretion could damage, disable, overburden, impair or interfere with any other Customer's or
                    any other authorized party's use of the Products. Customer and Authorized Users may not
                    intentionally interfere with or damage the operation of the Products or any user's enjoyment of the
                    Products, by any means, including uploading or otherwise disseminating viruses, worms, or other
                    malicious code. Customer and Authorized Users may not remove, circumvent, disable, damage, or
                    otherwise interfere with any security-related features of the Products, features that prevent or
                    restrict the use or copying of any content accessible through the Products, or features that enforce
                    limitations on the use of the Products. Customer and Authorized Users may not attempt to gain
                    unauthorized access to the Product, or any part of it, other accounts, computer systems or networks
                    connected to the Products, or any part of it, through hacking, reverse engineering, password mining
                    or any other means or interfere or attempt to interfere with the proper working of the Products or
                    any activities conducted in relation to the Products. Customer and Authorized Users may not obtain
                    or attempt to obtain any materials or information through any means not intentionally made available
                    through the Products. Customer and Authorized Users agree neither to modify the Products in any
                    manner or form, nor to use modified versions of the Products, including (without limitation) for the
                    purpose of obtaining unauthorized access to the Products.
                </p>

                <p className={pStyle}>
                    <strong>3.4</strong> Except as permitted by this Agreement, Customer and Authorized Users shall not:
                </p>
                <ol className={listAlphaStyle}>
                    <li className={listItemStyle}>
                        copy Products for any purpose nor arrange or create Derivatives based on the Products (in whole
                        or in part) without Andsend's express prior written consent in each case;
                    </li>
                    <li className={listItemStyle}>
                        modify or in any other way change or alter the Products, nor allow anyone else to do so; or
                    </li>
                    <li className={listItemStyle}>
                        take any action that imposes an unreasonable or disproportionately large load on the Products;
                        use the Products, to bypass any login architecture or create or provide any other means through
                        which the Products may be accessed, and/or the Products may be used by others.
                    </li>
                </ol>

                <p className={pStyle}>
                    <strong>3.5</strong> In the event the Uniform Computer Information Transactions Act, any version
                    thereof, a substantially similar law or any other statutes implied for Internet transactions
                    (collectively "UCITA") is enacted as part of the law of any applicable state of the United States or
                    any applicable country or any applicable region within any country, such statute shall not govern
                    any aspect of this T&Cs, any rights or licenses granted hereunder, nor any of the Parties' rights
                    and/or obligations arising pursuant to this T&Cs. This Agreement and the Parties' rights and
                    obligations arising hereunder shall be governed according to this Agreement solely on its express
                    terms.
                </p>

                <h2 className={h2Style}>4. Data Storage and Transmission</h2>
                <p className={pStyle}>
                    <strong>4.1</strong> Customer grants Andsend all rights and permissions necessary and provides
                    Andsend access to Customer Data and Authorized Users accounts in order for Andsend to provide the
                    Products and any services in connection therewith. These permissions also extend to trusted third
                    parties Andsend collaborates with to provide the Products and any services- for example (without
                    intending to be exhaustive), third parties providing cloud hosting services.
                </p>

                <p className={pStyle}>
                    <strong>4.2</strong> Andsend shall use commercially reasonable efforts to maintain administrative,
                    physical, and technical safeguards for protection of the security, confidentiality, and integrity of
                    Customer Data. Andsend shall not: (a) modify Customer Data with the exceptions of providing the
                    service and as otherwise stated in this T&Cs; (b) disclose Customer Data except as compelled by law,
                    this T&Cs or as expressly permitted in writing by Customer; or (c) access Customer Data except to
                    provide the Products, any services, and to prevent or address service or technical problems, or at
                    Customer's request in connection with customer support matters.
                </p>

                <p className={pStyle}>
                    <strong>4.3</strong> Customer is responsible for all data it stores in the Products and the cloud
                    and shall indemnify and hold Andsend harmless against any third-party claims against Andsend in
                    connection with Customer Data.
                </p>

                <p className={pStyle}>
                    <strong>4.4</strong> Customer understands and agrees neither Andsend nor any Andsend hosting
                    supplier has control over the content of the data processed and that Andsend (or the hosting
                    supplier, as applicable) provides the Products and any related services on Customer's behalf. Any
                    and all hosting facilitated by Andsend is handled by Andsend's then current hosting service
                    provider(s) and shall be subject to such service provider(s) terms and conditions. Andsend makes no
                    warranty as to such third-party service providers' service level.
                </p>

                <p className={pStyle}>
                    <strong>4.5</strong> Products and services may be subject to limitations, such as, for example,
                    limits on disk storage space, on the number of calls Customer and Authorized Users are permitted to
                    make against Andsend's application programming interface, or other usage limits. Andsend will notify
                    Customer of such limits.
                </p>

                <h2 className={h2Style}>5. Fees and Payment</h2>
                <p className={pStyle}>
                    <strong>5.1</strong> Customer shall pay Fees as set forth in the Order, subject to change from
                    time-to-time by Andsend. Payment shall be made by Credit Card or against Andsend's invoice. Unless
                    otherwise indicated, all Fees associated with the Products are due in full upon commencement of the
                    subscription term for which Fees apply. Fees are non-refundable once taking effect. To the extent
                    Customer uses a credit card, paypal or any other online payment solution, Andsend may securely share
                    Customer's credit card information with third parties as necessary for payment processing. Moreover,
                    to the extent any recurring or additional Fees shall apply in connection with any Customer
                    subscription or purchase, such subsequent payments of Fees can be applied to the same payment method
                    used for the immediately preceding applied charges – unless Customer has informed Andsend otherwise
                    in writing (i.e., an alternative payment method).
                </p>

                <p className={pStyle}>
                    <strong>5.2</strong> No refunds or credits for Products Fees or other Fees or payments will be
                    provided to Customer if Customer elects to downgrade the services ordered during the respective
                    subscription term. Downgrading the services may cause loss of content, features, or capacity of the
                    Products and any services as available to Customer under Customer's account, and Andsend shall not
                    be liable for any such loss.
                </p>

                <p className={pStyle}>
                    <strong>5.3</strong> Customer shall fully and timely pay all Fees for the Products, Upgrades, and
                    related services ordered. Customer is responsible for providing Andsend with valid payment and
                    credit card information and/or invoice address and related information to secure payment. Andsend
                    reserves the right to cancel any Order if the payment, invoice, or credit card information provided
                    is invalid.
                </p>

                <p className={pStyle}>
                    <strong>5.4</strong> If Customer fails to timely pay any Fee on the date due for payment, Andsend
                    may suspend the services or terminate the Agreement (in whole or in part) immediately without
                    further notice. Upon termination, the rights granted by Andsend to Customer pursuant to Section 3
                    hereof shall automatically expire. In addition to any other remedies available under this Agreement
                    or pursuant to compulsorily applicable law, Andsend shall in the event of Customer's delay with any
                    payment hereunder, be entitled to:
                </p>
                <ol className={listAlphaStyle}>
                    <li className={listItemStyle}>
                        immediately suspend performance of all Products and services under this Agreement or otherwise
                        in relation to Customer until full payment has been received; and
                    </li>
                    <li className={listItemStyle}>
                        receive all costs of collection, attorneys' fees, and penalty interest on all unpaid amounts,
                        calculated at 1% per commenced month of delinquency.
                    </li>
                </ol>

                <p className={pStyle}>
                    <strong>5.5</strong> The amounts payable to Andsend pursuant to the Agreement are exclusive of any
                    taxes (including, without limitation, value added taxes and withholding taxes), duties or
                    governmental charges. Customer's payment of Fees shall be made free and clear without deduction for
                    any and all present and future taxes, duties or governmental charges imposed by any taxing
                    authority, and/or any other setoffs.
                </p>

                <p className={pStyle}>
                    <strong>5.6</strong> Customer shall pay all applicable banking and transactional fees, etc. that may
                    be associated with the payment of Fees to Andsend.
                </p>

                <h2 className={h2Style}>6. Confidentiality</h2>
                <p className={pStyle}>
                    <strong>6.1</strong> Neither Party shall disclose Confidential Information to any third Party. Each
                    Party shall further safeguard Confidential Information with the same degree of care as it uses to
                    protect its own confidential information of like importance but in no event with less than
                    reasonable care. Except for the lawful exercise of its rights under this T&Cs, neither Party shall
                    use, make, or have made any copies of Confidential Information, in whole or in part, without the
                    prior written authorization of the other Party. Each Party shall only disclose Confidential
                    Information to its employees and consultants ("Representatives") having a need to know for the
                    purposes of this Agreement. Each Party shall notify and inform its Representatives of
                    its/her/his/their limitations, duties, and obligations regarding use, access to, and nondisclosure
                    of Confidential Information and shall obtain or have obtained its written agreement to comply with
                    such limitations, duties, and obligations. Each Party shall be liable for any breach of the terms of
                    this T&Cs by any of its Representatives.
                </p>

                <p className={pStyle}>
                    <strong>6.2</strong> The obligations set forth in Section 6.1 above shall not apply with respect to
                    information that:
                </p>
                <ol className={listAlphaStyle}>
                    <li className={listItemStyle}>
                        is or becomes lawfully known by the Receiving Party without an obligation to maintain its
                        confidentiality (as evidenced by Receiving Party's written records);
                    </li>
                    <li className={listItemStyle}>
                        is or becomes generally known to the public through no act or omission of Receiving Party;
                    </li>
                    <li className={listItemStyle}>
                        is independently developed by the Receiving Party without use of the Confidential Information
                        (as evidenced by Receiving Party's written records); or
                    </li>
                    <li className={listItemStyle}>
                        is required to be disclosed by law, order or regulation or governmental agency or court of
                        competent jurisdiction.
                    </li>
                </ol>

                <p className={pStyle}>
                    <strong>6.3</strong> In the event Receiving Party is required to disclose Confidential Information
                    pursuant to law, order or regulation or governmental agency or court of competent jurisdiction, such
                    Party shall notify the Disclosing Party of the required disclosure with sufficient time to seek
                    relief, cooperate with the Disclosing Party in taking appropriate protective measures, and shall
                    make such disclosure in the fashion which maximizes protection of the Confidential Information from
                    further disclosure.
                </p>

                <p className={pStyle}>
                    <strong>6.4</strong> The obligations set forth under this Section 6 shall continue for as long as
                    the Confidential Information is of value to Disclosing Party and until one of the exclusions in
                    Section 6.2 applies thereto.
                </p>

                <p className={pStyle}>
                    <strong>6.5</strong> Customer agrees Andsend and its suppliers shall have the right to access
                    Customer Data and to use, modify, reproduce, distribute, display, and disclose Customer Data solely
                    to the extent necessary to provide the Products and related services, including, without limitation,
                    in response to support requests. Any third-party service providers will only be given access to
                    Customer's account and Customer Data as is reasonably necessary to provide the Products/services and
                    will be subject to confidentiality obligations.
                </p>

                <p className={pStyle}>
                    <strong>6.6</strong> Andsend may also access or disclose information about Customer, Customer's
                    account, and Customer Data, in order to: (a) comply with the law or respond to lawful requests or
                    legal process; (b) protect Andsend's or its customers', partners', or suppliers' rights or property,
                    including enforcement of the T&Cs or other policies associated with the Products or services; (c)
                    act on a good faith belief that such disclosure is necessary to protect personal safety or avoid
                    violation of applicable law or regulation.
                </p>

                <p className={pStyle}>
                    <strong>6.7</strong> Andsend may collect certain information about Customer as well as Customer's
                    mobile devices, computers, and use of the Products and services. Andsend uses, discloses, and
                    protects this information as described in the DPA, if applicable.
                </p>

                <p className={pStyle}>
                    <strong>6.8</strong> Upon execution of the T&Cs, Andsend shall have the right to publicly reference,
                    in Andsend's marketing materials – whether offline or online – Customer as a Andsend customer, and
                    Andsend shall be permitted to use any relevant Customer trademark and/or logo in connection with
                    such marketing usage. Customer shall assist Andsend in procuring any and all relevant information
                    regarding Customer's use of the Products. Andsend shall have the right to use information and
                    material in a case study for in-house use and, upon Customer's approval, publication by Andsend on
                    its websites and in marketing material.
                </p>

                <h2 className={h2Style}>7. Intellectual Property Rights</h2>
                <p className={pStyle}>
                    <strong>7.1</strong> Except for the usage rights expressly granted to Customer pursuant to Section
                    3.1 above, Andsend retains all right, title, and interest in and to the Products, services, and any
                    Feedback provided by Customer to Andsend. Andsend shall be free to use and/or commercialize any
                    Feedback without having to make any accounting or payment to Customer, any Authorized User, and/or
                    any Customer Representative. Should Customer retain Andsend for the provision of any consulting
                    services, the Parties agree all IPR emanating therefrom shall vest in Andsend; however, Customer
                    shall have a right to use such consulting services deliverables on the same terms as Customer has
                    the right to use the Products pursuant to this Agreement and during the term of this Agreement.
                    CUSTOMER ASSIGNS TO ANDSEND ALL IPR AS NECESSARY TO GIVE FULL EFFECT TO THIS SECTION 7.1
                </p>

                <p className={pStyle}>
                    <strong>7.2</strong> As between Customer and Andsend, Customer owns all right, title, and interest
                    in and to Customer Data; Andsend is only a custodian of such data when it is stored in the
                    Customer's account(s).
                </p>

                <h2 className={h2Style}>8. Proprietary Notices</h2>
                <p className={pStyle}>
                    <strong>8.1</strong> Customer and Authorized Users shall retain, reproduce, and apply any copyright
                    notices and/or other proprietary rights notices included on or embedded in the Products and
                    services.
                </p>

                <h2 className={h2Style}>9. Andsend's Indemnification Responsibility</h2>
                <p className={pStyle}>
                    <strong>9.1</strong> Andsend will defend, indemnify, and hold harmless Customer from and against any
                    actions, claims, costs, demands, liability, and expenses, including reasonable attorneys' fees,
                    arising out of Andsend's breach of warranty set forth in Section 11.1 herein.
                </p>

                <h2 className={h2Style}>10. Customer's Indemnification Liability</h2>
                <p className={pStyle}>
                    <strong>10.1</strong> Customer shall defend, indemnify, and hold harmless Andsend and its
                    affiliates, from and against any actions, claims, costs, demands, liability, and expenses, including
                    reasonable attorneys' fees, arising out of Customer Data or Customer's use of the Products and/or
                    any services in any manner not expressly permitted under this Agreement.
                </p>

                <h2 className={h2Style}>11. Warranties and Limitation of Liability</h2>
                <p className={pStyle}>
                    <strong>11.1</strong> Andsend warrants that, to the best of its knowledge, the Products, solely in
                    their unmodified form, do not infringe any third party's copyright(s), trade secret(s) or any
                    patent(s) granted as of the Effective Date. Andsend's indemnification obligation relating hereto (as
                    set forth in Section 9.1 herein) expressly excludes any claims arising from Customer's improper use
                    of the Product(s); in the event the Product(s) have been modified or altered in any manner; or if
                    the claim results from the combination of any Product with any third-party software, SaaS, or
                    hardware. Further, Andsend shall have no obligation to indemnify Customer if Customer had not, at
                    the time the claim against the Customer arose, been current in paying any and all Fees, to Andsend,
                    under this Agreement.
                </p>

                <p className={pStyle}>
                    <strong>11.2</strong> IN NO EVENT, EXCEPTING ANY ACTION BASED UPON WILLFUL MISCONDUCT, EXPLICITLY
                    UNDERTAKEN INDEMNIFICATION OBLIGATIONS, CUSTOMER FAILURE TO TIMELY PAY, ONE PARTY'S INFRINGEMENT OF
                    THE OTHER PARTY'S IPR OR BREACH OF CONFIDENTIALITY, SHALL EITHER PARTY HERETO BE ENTITLED TO,
                    REGARDLESS OF WHETHER A CLAIM OR ACTION IS BASED UPON STRICT LIABILITY; NEGLIGENCE; INDEMNIFICATION;
                    BREACH OF CONTRACT, WARRANTY, SERVICES, DPA if applicable, UPGRADES, OF ANY KIND, INDEMNIFICATION OR
                    ANY OTHER THEORY OF LIABILITY IN CONNECTION WITH THIS T&CS (INCLUDING THE APPENDICES AND ANY
                    ORDER(S)), ANY:
                </p>
                <ol className={listAlphaStyle}>
                    <li className={listItemStyle}>
                        PUNITIVE, SPECIAL, INDIRECT, INCIDENTAL OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS OR FOR
                        LOST DATA, WHETHER OR NOT THE PARTY HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGES, NOR
                    </li>
                    <li className={listItemStyle}>
                        AN AGGREGATE AMOUNT OF DAMAGES EXCEEDING THE LESSER OF EURO TEN THOUSAND (10,000) OR THE AMOUNT
                        ACTUALLY PAID BY CUSTOMER TO ANDSEND DURING THE 1 YEAR PERIOD IMMEDIATELY PRECEDING THE CLAIM IN
                        QUESTION. ANY PAYMENTS DUE AND OWING BY CUSTOMER TO ANDSEND (INCLUDING ANY ATTORNEYS' FEES
                        AND/OR COLLECTION COSTS RELATED THERETO) SHALL NOT BE DEEMED "DAMAGES" UNDER THIS SECTION. THIS
                        LIMITATION OF LIABILITY CAP IS AGGREGATED AND NOT PER INCIDENT.
                    </li>
                </ol>

                <p className={pStyle}>
                    <strong>11.3</strong> UNLESS STATED EXPRESSLY OTHERWISE IN THIS SECTION 11, THE PRODUCTS AND ANY
                    SERVICES RELATED THERETO ARE PROVIDED ON AN "AS IS" BASIS WITHOUT WARRANTY OF ANY KIND, WHETHER
                    EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE. ANDSEND AND ITS SUPPLIERS SPECIFICALLY DISCLAIM ALL
                    IMPLIED WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR A PARTICULAR PURPOSE. FREE
                    PRODUCT VERSIONS ARE PROVIDED ON AN "AS IS" BASIS ONLY AND NO WARRANTIES OF ANY KIND SHALL APPLY
                    THERETO WHETHER EXPRESS OR IMPLIED THUS EXCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTY
                    MERCHANTABILITY, THE IMPLIED WARRANTY AGAINST INFRINGEMENT, AND THE IMPLIED WARRANTY OF FITNESS FOR
                    A PARTICULAR PURPOSE.
                </p>

                <p className={pStyle}>
                    <strong>11.4</strong> THE LIMITATIONS OF LIABILITY AND WARRANTY DISCLAIMERS IN THIS SECTION 11 ARE
                    MATERIAL, BARGAINED FOR BASES OF THE AGREEMENT, AND HAVE BEEN RELIED UPON IN DETERMINING THE
                    CONSIDERATION (IF ANY) TO BE PAID UNDER THIS AGREEMENT AND IN THE DECISION BY EACH PARTY TO ENTER
                    INTO THIS AGREEMENT.
                </p>

                <p className={pStyle}>
                    <strong>11.5</strong> Should Customer desire to obtain a higher level of liability coverage and/or
                    any type of extended warranty, support or service level, Customer shall contact Andsend with a
                    specific request in writing, and Andsend shall reply with a quote for such additional Andsend
                    undertakings.
                </p>

                <p className={pStyle}>
                    <strong>11.6</strong> Customer understands and agrees Andsend has no responsibility or liability for
                    the deletion or failure to store any content and/or for other communications maintained or
                    transmitted through the Products or any services related thereto. Customer further acknowledges
                    Customer is solely responsible for properly configuring all services contemplated herein, for taking
                    steps to maintain appropriate security, and for backing up content and Customer Data.
                </p>

                <h2 className={h2Style}>12. Term and Termination</h2>
                <p className={pStyle}>
                    <strong>12.1</strong> Unless stated otherwise in the Order, these T&Cs shall commence on the
                    Effective Date and run for 1 year from the date thereof. The Agreement shall automatically renew for
                    periods of 1 year at a time unless either party submits termination notice to the other, in writing,
                    not less than 30 calendar days prior to the conclusion of the initial term or any annual extension
                    thereafter, subject to any lengthier termination requirements of any existing Order subscriptions
                    (as additional subscriptions may be entered into after the Effective Date of the Agreement). If any
                    existing Product subscription term(s) exceed the Agreement term, the Agreement shall be extended
                    until the conclusion of the outstanding Product subscription terms. In the event of a material
                    breach (including, without limitation, delinquent payment) of this Agreement by either party not
                    remedied (to the extent capable of remedy) within 30 calendar days from the other party's written
                    notice thereof or in case either party should become bankrupt or insolvent, the other party shall
                    have the right to terminate this T&Cs with immediate effect. Any notice of termination must be sent
                    by Customer to Andsend at support@andsend.com.
                </p>

                <p className={pStyle}>
                    <strong>12.2</strong> After termination of the T&Cs by either party pursuant to Section 12.1 above,
                    Customer's rights with respect to Products shall cease and Customer shall promptly, completely, and
                    irrevocably purge and otherwise destroy any and all copies of the same.
                </p>

                <p className={pStyle}>
                    <strong>12.3</strong> The foregoing rights of termination are in addition to all other rights and
                    remedies provided in this T&Cs or at law, subject to the express limitations of liability set forth
                    in Section 11hereof.
                </p>

                <h2 className={h2Style}>13. Consequences upon Termination</h2>
                <p className={pStyle}>
                    <strong>13.1</strong> The provisions of Sections 1, 5-11, 13, and 16 shall survive any termination
                    of this T&Cs as well as any other term or condition which by its nature is clearly intended to
                    survive termination of this T&Cs. Such survival period shall be as long as necessary to give
                    reasonable legal effect to the respective surviving clause in question.
                </p>

                <h2 className={h2Style}>14. Notices</h2>
                <p className={pStyle}>
                    <strong>14.1</strong> Under this T&Cs, Customer consents to communicate with Andsend electronically.
                    Andsend will communicate by email or by posting notices within the Products, services related
                    thereto, and/or on the Andsend website. Customer agrees that all agreements, notices, disclosures,
                    and other communications provided by Andsend electronically shall satisfy any legal requirement that
                    such communications be in writing.
                </p>

                <h2 className={h2Style}>15. Assignment</h2>
                <p className={pStyle}>
                    <strong>15.1</strong> Customer may not assign or otherwise transfer any of its rights or obligations
                    under this T&Cs, without the prior written consent of Andsend.
                </p>

                <p className={pStyle}>
                    <strong>15.2</strong> Andsend may assign or otherwise transfer any of its rights or obligations
                    under this T&Cs to a third party, without the prior written consent of Customer, provided the
                    assignee agrees all assigned obligations under this T&Cs shall be fully binding upon such assignee.
                </p>

                <h2 className={h2Style}>16. General Provisions</h2>
                <p className={pStyle}>
                    <strong>16.1 Governing Law.</strong> This T&Cs is governed by the substantive laws of Sweden, giving
                    no effect to any conflict-of-law provisions. ANY DISPUTE SHALL BE FINALLY AND EXCLUSIVELY SETTLED BY
                    EXPEDITED ARBITRATION IN STOCKHOLM, SWEDEN IN ACCORDANCE WITH THE ARBITRATION RULES OF THE STOCKHOLM
                    CHAMBER OF COMMERCE. CUSTOMER WAIVES ANY RIGHT TO A JURY TRIAL. The losing Party shall pay the
                    prevailing Party's reasonable arbitration costs and attorneys' fees. Each Party agrees any judgment
                    or arbitration award shall be enforceable against it in any jurisdiction as necessary to reasonably
                    enforce such judgment or award. The United Nations Convention on the International Sale of Goods is
                    expressly excluded from this T&Cs.
                </p>

                <p className={pStyle}>
                    <strong>16.2 Amendment, Waiver, Entire Agreement.</strong> No amendment to this T&Cs or waiver shall
                    be valid unless based on a written and signed undertaking. Any waiver of any term or condition of
                    this T&Cs shall not be deemed to constitute a continuing waiver. This T&Cs contains the entire
                    agreement between the Parties on the subject matter of this T&Cs, and supersedes all memoranda,
                    representations, undertakings, and agreements, whether oral or written, previously made between the
                    Parties with respect to the subject matter of this T&Cs. In the event any provision of this T&Cs is
                    deemed by a court or tribunal of competent jurisdiction to be unenforceable, such provision(s) shall
                    be limited or eliminated to the minimum extent necessary for this T&Cs to otherwise remain in full
                    force and effect. In the event of inconsistency between the Order, the T&Cs, and/or any appendix,
                    the documents shall prevail in the following order: i) main T&Cs, ii) DPA, if applicable, iii) the
                    Order, and iv) any other Andsend legal documents in its/their sequential order, with the sole
                    exception of any longer Agreement term set forth in the Order which shall prevail over the Agreement
                    term set forth in this T&Cs. The T&Cs may be digitally entered into, copied, and stored – and if
                    introduced as evidence in any judicial, arbitration, mediation or administrative proceedings, will
                    be admissible to the same extent and under the same conditions as other business records originated
                    and maintained in documentary form and neither Party will object on the basis that such business
                    records were not originated or maintained in documentary form under any rule of evidence.
                </p>

                <p className={pStyle}>
                    <strong>16.3 Relationship between the Parties.</strong> No relationship of principal to agent,
                    employer to employee, partnership, or joint venture is established between the Parties. Neither
                    Party shall bind the other Party or incur any obligation on its behalf.
                </p>

                <p className={pStyle}>
                    <strong>16.4 Export.</strong> In addition to the terms of this T&Cs, Customer may not use or
                    otherwise export or re-export the Products in any manner violative of the laws of the United States,
                    the laws of the jurisdiction in which the Product(s) was/were obtained as well as the laws of any
                    other country which are applicable to this Agreement.
                </p>
            </div>
        </div>
    );
}

TermsOfService20241219.displayName = 'TermsOfService20241219';
