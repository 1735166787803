import { Badge } from '@shadcn/ui/components/ui/badge';
import { useActions } from '../hooks/use-actions';

const LIMIT = 30;

export const ActionsCountBadge = () => {
    const { totalActionsCount } = useActions();
    return (
        <Badge variant="warning">
            {Math.min(totalActionsCount, LIMIT)} {totalActionsCount > LIMIT ? '+' : ''}
        </Badge>
    );
};

export const CompletedActionsCountBadge = () => {
    const { totalCompletedActions } = useActions();
    return (
        <Badge variant="outline">
            {Math.min(totalCompletedActions, LIMIT)} {totalCompletedActions > LIMIT ? '+' : ''}
        </Badge>
    );
};
