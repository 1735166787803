import { Typography } from '@mui/material';
import { theme } from '../theme';

const colors = (theme.palette as any).colors;

const InactiveAccountInfo = () => (
    <div style={{ display: 'flex', paddingTop: 42, flexDirection: 'column', alignItems: 'center' }}>
        <Typography
            style={{
                marginBottom: 18,
                fontWeight: 500,
                fontSize: 24,
                color: colors.black,
            }}
        >
            Your account is inactive
        </Typography>
        <Typography style={{ fontSize: 17, color: colors.subline }}>
            Ask your admin to reactivate your account
        </Typography>
    </div>
);

export default InactiveAccountInfo;
