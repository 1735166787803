export enum ActionType {
    emailMessage = 'EMAIL_MESSAGE',
    emailTrigger = 'EMAIL_TRIGGER',
    linkedinChatTrigger = 'LINKEDIN_CHAT_TRIGGER', //@TODO Deprecated
    linkedinActivityScanner = 'LINKEDIN_ACTIVITY_SCANNER',
    linkedinConnectionRequest = 'LINKEDIN_CONNECTION_REQUEST',
    linkedinMessage = 'LINKEDIN_MESSAGE',
    linkedinProfileVisit = 'LINKEDIN_PROFILE_VISIT',
}

export enum WorkflowActionStatus {
    AWAITING = 'AWAITING',
    AWAITING_AUTHENTICATION = 'AWAITING_AUTHENTICATION',
    ON_HOLD = 'ON_HOLD',
    IN_PROGRESS = 'IN_PROGRESS',
    STARTED = 'STARTED',
    FINISHED = 'FINISHED',
    FAILED = 'FAILED',
}

export enum ExecutionStatus {
    AUTHENTICATION_INVALID = 'AUTHENTICATION_INVALID',
    PROXY_FAILED = 'PROXY_FAILED',
    ERROR = 'ERROR',
    FAILED_NO_RETRY = 'FAILED_NO_RETRY',
    FAILED = 'FAILED',
    SUCCESS = 'SUCCESS',
    SUCCESS_NO_CONTINUE = 'SUCCESS_NO_CONTINUE',
    RETRY_IN_30_MINUTES = 'RETRY_IN_30_MINUTES',
    RETRY_IN_1_HOURS = 'RETRY_IN_1_HOURS',
    RETRY_IN_1_DAYS = 'RETRY_IN_1_DAYS',
    RETRY_IN_2_DAYS = 'RETRY_IN_2_DAYS',
}
