import React from 'react';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

const AutoSuggestHighlight = ({
    text,
    value,
    fullWidth = false,
}: {
    text: string;
    value: string;
    fullWidth?: boolean;
}) => {
    if (!value || !text) {
        return (
            <span
                style={{
                    fontWeight: 400,
                    color: '#3A4B59',
                }}
            >
                {text}
            </span>
        );
    }

    const matches = match(text, value, { insideWords: true, findAllOccurrences: true });
    const parts = parse(text, matches);

    return (
        <div
            style={{
                maxWidth: fullWidth ? '350px' : '170px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            }}
        >
            {parts.map((part, index) => (
                <span
                    key={index}
                    style={{
                        fontWeight: !part.highlight ? 400 : 500,
                        color: !part.highlight ? '#3A4B59' : '#23323F',
                    }}
                >
                    {part.text}
                </span>
            ))}
        </div>
    );
};

export default AutoSuggestHighlight;
