import { UseQueryOptions } from '@tanstack/react-query';
import { getZaplifySdk } from '@zaplify/sdk';

const globalSearchFn = (query: string) => {
    return getZaplifySdk().profiles.search.globalSearch(query);
};
type GlobalSearchData = Awaited<ReturnType<typeof globalSearchFn>>;
export enum GlobalSearchTypes {
    CAMPAIGNS = 'campaigns',
    COMPANIES = 'companies',
    COMPANY_NAMES = 'companyNames',
    PROSPECTS = 'prospects',
    PROSPECTS_FROM_LINKEDIN = 'prospectsFromLinkedin',
    ACCOUNTS = 'accounts',
    PEOPLE = 'people',
    CONTACTS = 'contacts',
}

type GlobalSearchOptions = {
    match: string;
    page: number;
    perPage: number;
    searchIn?: GlobalSearchTypes[];
};
export const search = (
    searchQuery: GlobalSearchOptions,
    options?: Omit<UseQueryOptions<GlobalSearchData, Error>, 'queryKey' | 'queryFn'>
): UseQueryOptions<GlobalSearchData, Error> => {
    const encodedSearchIn = encodeURIComponent(JSON.stringify(searchQuery.searchIn || []));
    const queryParams = new URLSearchParams({
        match: searchQuery.match.toLowerCase(),
        page: searchQuery.page.toString(),
        perPage: searchQuery.perPage.toString(),
        searchIn: encodedSearchIn,
    });
    const query = queryParams.toString();

    return {
        queryKey: [`globalSearch-${searchQuery.searchIn}`, query],
        queryFn: () => globalSearchFn(query),
        enabled: !!searchQuery.match,
        ...options,
    };
};

const globalSearchInCompanyFn = (query: string) => {
    return getZaplifySdk().profiles.search.globalSearchInCompany(query);
};
type GlobalSearchInCompanyData = Awaited<ReturnType<typeof globalSearchInCompanyFn>>;
type GlobalSearchInCompanyOptions = {
    match: string;
    page: number;
    perPage: number;
    accountId: string;
};
export const searchInCompany = (
    searchQuery: GlobalSearchInCompanyOptions,
    options?: Omit<UseQueryOptions<GlobalSearchInCompanyData, Error>, 'queryKey' | 'queryFn'>
): UseQueryOptions<GlobalSearchInCompanyData, Error> => {
    const queryParams = new URLSearchParams({
        match: searchQuery.match.toLowerCase(),
        page: searchQuery.page.toString(),
        perPage: searchQuery.perPage.toString(),
        companyId: searchQuery.accountId,
    });
    const query = queryParams.toString();

    return {
        queryKey: ['globalSearchInCompany', query],
        queryFn: () => globalSearchInCompanyFn(query),
        enabled: !!searchQuery.accountId,
        ...options,
    };
};
