import { useSubscription } from '@apollo/client';
import { GetNotesSubscription, NOTES_SUBSCRIPTION } from '@zaplify/graphql';
import { useSetAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { prospectIdToIsGeneratingMessageAtom } from '../../../atoms/chats';
import { Creator } from '@zaplify/services/notes/shared';
import { useSdk } from '../../../new/sdk';
import { useMutation } from '@tanstack/react-query';

export const useNotes = () => {
    const [notes, setNotes] = useState<Record<string, GetNotesSubscription['Notes']>>({});
    const [loading, setLoading] = useState(false);
    const setProspectIdsToIGeneratingMessage = useSetAtom(prospectIdToIsGeneratingMessageAtom);
    const {
        notes: { deleteNote: deleteNoteMutationFn, createNote: createNoteMutationFn },
    } = useSdk();

    const { mutateAsync: deleteNoteMutation } = useMutation(deleteNoteMutationFn());
    const { mutateAsync: createNoteMutation } = useMutation(createNoteMutationFn());

    const { loading: isLoading } = useSubscription(NOTES_SUBSCRIPTION, {
        onData: async (data) => {
            const fetchedNotes = data.data.data?.Notes;
            if (!fetchedNotes) {
                return;
            }
            const updatedNotes = {};
            for (const note of fetchedNotes) {
                updatedNotes[note.prospectId] = [...(updatedNotes[note.prospectId] || []), note];
            }
            setNotes(updatedNotes);
        },
    });

    const saveNote = async (
        note: { content: string; title: string; prospectId: string; userId: string; creator?: Creator },
        generateNewMessage: boolean = false
    ) => {
        const newNote = await createNoteMutation({
            content: note.content,
            title: note.title,
            prospectId: note.prospectId,
            userId: note.userId,
        });

        setLoading(true);
        setNotes((prevNotes) => ({
            ...prevNotes,
            [note.prospectId]: [
                ...(prevNotes[note.prospectId] || []),
                {
                    id: newNote.id,
                    prospectId: newNote.prospectId,
                    creator: newNote.creator,
                    content: newNote.content,
                    title: newNote.title,
                    createdAt: newNote.createdAt,
                    updatedAt: newNote.updatedAt,
                },
            ],
        }));
        setLoading(false);

        if (generateNewMessage) {
            setProspectIdsToIGeneratingMessage((prev) => {
                const newMap = new Map(prev);
                newMap.set(newNote.prospectId, true);
                return newMap;
            });
        }
    };

    const deleteNote = async (noteId: string) => {
        await deleteNoteMutation({ noteId });
        setLoading(true);
        setNotes((prevNotes) => {
            const updatedNotes = {};
            for (const prospectId in prevNotes) {
                updatedNotes[prospectId] = prevNotes[prospectId].filter((note) => note.id !== noteId);
            }
            return updatedNotes;
        });
        setLoading(false);
    };

    useEffect(() => {
        setLoading(isLoading);
    }, [isLoading]);

    const getNotes = (prospectId: string) => {
        return notes[prospectId] || [];
    };

    return {
        notes,
        loading,
        getNotes,
        saveNote,
        deleteNote,
    };
};
