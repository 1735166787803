import { Typography } from '@mui/material';

export const SectionTitle = ({ title, size }: { title: string; size?: string }) => {
    return (
        <Typography
            sx={{
                color: 'var(--grey-palette-grey-4, #6B7985)',
                letterSpacing: 0.1,
                textTransform: 'uppercase',
                marginBottom: '6px',
                fontSize: size || '10px',
                fontWeight: '500',
            }}
        >
            {title}
        </Typography>
    );
};
