import { getZaplifySdk } from '@zaplify/sdk';
import { LocalStorageKeys } from '../../config';
import { User as FbUser } from 'firebase/auth';

export const cacheInvitationFromURL = (searchParams: URLSearchParams) => {
    const invitationIdFromURL = searchParams.get('invitationId');
    const securityTokenFromURL = searchParams.get('securityToken');
    const invitationIdFromLocalStorage = localStorage.getItem(LocalStorageKeys.INVITATION_ID);

    if (invitationIdFromURL?.length > 0 && securityTokenFromURL?.length > 0) {
        localStorage.setItem(LocalStorageKeys.INVITATION_ID, invitationIdFromURL);
        localStorage.setItem(LocalStorageKeys.INVITATION_SECURITY_TOKEN, securityTokenFromURL);
        if (invitationIdFromURL !== invitationIdFromLocalStorage) {
            localStorage.setItem(LocalStorageKeys.INVITATION_STATUS, 'pending');
        }
    }
};

export const claimWaitingInvitations = async (fbUser: FbUser) => {
    try {
        const invitationId = localStorage.getItem(LocalStorageKeys.INVITATION_ID);
        const securityToken = localStorage.getItem(LocalStorageKeys.INVITATION_SECURITY_TOKEN);

        let didClaimInvitation = false;
        if (invitationId && securityToken && fbUser?.uid) {
            console.log('Claiming invitation...', { authUserId: fbUser?.uid, invitationId });
            await getZaplifySdk().profiles.user.notifyInvitationClicked(invitationId, securityToken);
            // @TODO Make sure to throw if invitation is either not found, expired or some other error occurs
            // So that we don't accidentally end up in a state where INVITATION_ID and INVITATION_SECURITY_TOKEN is set in local storage forever
            // We would slow down the app significantly if this request needs to be called on every load,
            didClaimInvitation = true;
        }
        return didClaimInvitation;
    } catch (error) {
        console.error('Error while handling invitation', error);
        localStorage.removeItem(LocalStorageKeys.INVITATION_ID);
        localStorage.removeItem(LocalStorageKeys.INVITATION_SECURITY_TOKEN);
        // We might consider to turn this into a hook and notify on error
        // Fallback to redirecting to login on error (most likely it's due to that they already accepted the inviation)
        // notify({
        //     message: 'Could not accept invitation 😢',
        //     subMessage:
        //         'We could not find this invitation or it might have expired. You can ask the person who invited you to send over a new invitation, or feel free to contact our support.',
        //     type: 'info',
        //     duration: 15_000,
        // });
        // navigate(paths.LOGIN);
    }
};
