import { styled } from '@mui/styles';

export const ActivityRowStyled = styled('div')(({ theme: { palette } }: any) => ({
    marginTop: '8px',
    background: '#FFFFFF',
    boxShadow: '0px 2px 6px rgba(112, 144, 176, 0.07)',
    borderRadius: 8,
    padding: '8px 23px 8px 17px',
    boxSizing: 'border-box',

    '& > .main-status-container': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',

        '& > div:first-child': {
            display: 'flex',
            flexDirection: 'column',
            '& > p': {
                fontSize: '13px',
            },
        },
        '& > div:last-child': {
            display: 'flex',
            flexDirection: 'row',

            '& .status-text': {
                color: palette.subline,
                fontSize: '13px',
                fontWeight: 500,
            },
            '& .time-text': {
                color: palette.colors.lightGrey,
                fontSize: '13px',
                fontWeight: 500,
                marginLeft: '4px',
            },
        },
    },
    '& > .due-text': {
        marginTop: 2,
        textAlign: 'end',
        fontSize: '13px',
        fontWeight: 500,
        color: palette.colors.lightGrey,
    },
    '& > .sub-text': {
        marginTop: 2,
        fontSize: '13px',
        textAlign: 'end',
        color: palette.colors.lightGrey,
    },
}));
