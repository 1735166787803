import { useGoogleLogin } from '@react-oauth/google';
import { emailConfig } from '../../../../../config';
import { BaseAppCard } from './base-app-card';
import { ReactComponent as GmailIcon } from '../../../../../components/icons/gmail-bigger.svg';
import { ChannelAccountStatus } from '@zaplify/channel-accounts/shared';

interface GmailAuthCardProps {
    onSuccess: (code: string) => void;
    isLoading: boolean;
    isAdded: boolean;
    status: ChannelAccountStatus;
}

export const GmailAuthCard = ({ onSuccess, isLoading, isAdded, status }: GmailAuthCardProps) => {
    const handleConnect = useGoogleLogin({
        onSuccess: (tokenResponse) => {
            // Google's auth-code flow returns the code directly
            if (tokenResponse.code) {
                onSuccess(tokenResponse.code);
            }
        },
        flow: 'auth-code',
        scope: emailConfig.google.scopes.join(' '),
        onError: (error) => console.error('Gmail login failed:', error),
    });

    return (
        <BaseAppCard
            title="Gmail"
            appId="gmail"
            bgColor="bg-red-50"
            icon={<GmailIcon className="h-6 w-6 object-contain" />}
            isConnected={isAdded && status === ChannelAccountStatus.AUTHENTICATED}
            onConnect={handleConnect}
            isActive={isAdded && status === ChannelAccountStatus.AUTHENTICATED}
            isLoading={isLoading}
            connectButtonText={status === ChannelAccountStatus.NOT_AUTHENTICATED ? 'Reconnect Gmail' : 'Connect Gmail'}
            connectedText="Your Gmail account is connected."
            notConnectedText="Connect your Gmail account to send emails."
        />
    );
};
