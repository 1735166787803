import { UseQueryOptions, UseSuspenseQueryOptions, UseMutationOptions } from '@tanstack/react-query';
import { getZaplifySdk } from '@zaplify/sdk';
import { AccountDto } from '@zaplify/services/accounts/shared';
import { createMutationOptions } from './sdk-utils';

const fetchAccountData = (accountId: string) => {
    return getZaplifySdk().profiles.prospects.getAccountById(accountId);
};

type AccountData = Awaited<ReturnType<typeof fetchAccountData>>;
export const getAccountById = (
    accountId: string,
    options?: Omit<
        UseQueryOptions<AccountData, Error> & UseSuspenseQueryOptions<AccountData, Error>,
        'queryKey' | 'queryFn'
    >
): UseQueryOptions<AccountData, Error> & UseSuspenseQueryOptions<AccountData, Error> => {
    return {
        queryKey: ['getAccountById', accountId],
        queryFn: () => fetchAccountData(accountId),
        enabled: !!accountId,
        staleTime: 1000 * 60 * 60, // 1 hour
        ...options,
    };
};

const getAccountFn = (body: { linkedinCompanyId?: string; accountId?: string }) => {
    return getZaplifySdk().profiles.prospects.getAccount(body);
};

type AccountDataByGetAccount = Awaited<ReturnType<typeof getAccountFn>>;
export const getAccount = (
    body: { linkedinCompanyId?: string; accountId?: string },
    options?: Omit<
        UseQueryOptions<AccountDataByGetAccount, Error> & UseSuspenseQueryOptions<AccountDataByGetAccount, Error>,
        'queryKey' | 'queryFn'
    >
): UseQueryOptions<AccountDataByGetAccount, Error> & UseSuspenseQueryOptions<AccountDataByGetAccount, Error> => {
    return {
        queryKey: ['getAccount', body],
        queryFn: () => getAccountFn(body),
        enabled: !!body.linkedinCompanyId || !!body.accountId,
        ...options,
    };
};

const createAccountFn = (account: AccountDto) => {
    return getZaplifySdk().profiles.prospects.createAccount(account);
};

type AccountDataByCreateAccount = Awaited<ReturnType<typeof createAccountFn>>;

export const createAccount = (
    options?: Omit<UseMutationOptions<AccountDataByCreateAccount, Error, AccountDto>, 'mutationFn'>
): UseMutationOptions<AccountDataByCreateAccount, Error, AccountDto> => {
    return createMutationOptions<AccountDataByCreateAccount, Error, AccountDto>({
        mutationFn: (variables) => createAccountFn(variables),
        invalidateQueries: (data) => [],
        ...options,
    });
};
