import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '@shadcn/ui/components/ui/dialog';
import { Button } from '@shadcn/ui/components/ui/button';
import { paths } from '../../../routes/paths';
import { Building2 } from 'lucide-react';
import { getZaplifySdk } from '@zaplify/sdk';
import { getAuthenticationToken } from '@zaplify/utils';
import { useQueryClient } from '@tanstack/react-query';
import { useApolloClient } from '@apollo/client';

type SubscriptionIssueDialogProps = {
    organizationName: string;
    activeUsersCount: number;
    children?: ReactNode;
    open?: boolean;
};

const BasicOrganizationInfo = ({
    organizationName,
    activeUsersCount,
}: {
    organizationName: string;
    activeUsersCount: number;
}) => (
    <div className="w-full flex items-center justify-center py-7 bg-secondary rounded-t-md">
        <Building2 className="h-12 w-12" />
        <div className="flex flex-col justify-center ml-4">
            <h3 className="text-lg font-medium text-secondary-foreground uppercase">{organizationName}</h3>
            <p className="text-sm text-secondary-foreground">
                {activeUsersCount} {activeUsersCount === 1 ? 'user' : 'users'}
            </p>
        </div>
    </div>
);

const SubscriptionIssueDialog = ({
    children,
    organizationName,
    activeUsersCount,
    open = true,
}: SubscriptionIssueDialogProps) => {
    const navigate = useNavigate();

    const tanStackClient = useQueryClient();
    const apolloClient = useApolloClient();

    return (
        <Dialog open={open} modal>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Subscription Issue</DialogTitle>
                </DialogHeader>
                <DialogDescription></DialogDescription>
                <BasicOrganizationInfo organizationName={organizationName} activeUsersCount={activeUsersCount} />
                {children}
                <div className="flex justify-center items-center gap-2 my-5 text-center">
                    <Button variant="secondary" onClick={() => navigate('/login')}>
                        Log out
                    </Button>
                    <span className="text-muted-foreground">or</span>
                    <Button variant="default" onClick={() => navigate(paths.OLD_ACCOUNTS)}>
                        Switch workspace
                    </Button>
                    <span className="text-muted-foreground">or</span>
                    <Button
                        variant="outline"
                        onClick={async () => {
                            await getZaplifySdk().profiles.authentication.chooseOrganization();
                            await getAuthenticationToken(true);
                            await Promise.all([tanStackClient.clear(), apolloClient.resetStore()]);
                            navigate(paths.OLD_CONFIGURE_PROFILE);
                        }}
                    >
                        Create new workspace
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default SubscriptionIssueDialog;
