import { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getZaplifySdk } from '@zaplify/sdk';
import { PageViewDto } from '@zaplify/users/shared';
import { useAppSelector } from '../redux/store/configureStore';

interface Props {
    children: ReactNode;
}

export const PageViewTracker: React.FC<Props> = ({ children }) => {
    const location = useLocation();
    const sdk = getZaplifySdk();
    const userId = useAppSelector((state) => state.user.zaplifyUser.id);
    const userOrganizationId = useAppSelector((state) => state.user.zaplifyUser?.userOrganization?.id);

    const getAnonymousId = () => {
        const cookies = document.cookie;
        let anonymousId = null;
        const cookiesArray = cookies.split('; ');
        for (let cookie of cookiesArray) {
            if (cookie.startsWith('ajs_anonymous_id=')) {
                anonymousId = cookie.split('=')[1];
                break;
            }
        }

        if (!anonymousId) {
            anonymousId = crypto.randomUUID();
            document.cookie = `ajs_anonymous_id=${anonymousId}; path=/;`;
        }

        return anonymousId;
    };

    const createPageView = () => {
        const hostname = window.location.hostname;
        const path = location.pathname;
        const pageTitle = document.title;
        const userAgent = navigator.userAgent;
        const referrer = document.referrer || '';
        const url = window.location.href;
        const search = location.search;
        const anonymousId = getAnonymousId();

        const searchParams = new URLSearchParams(search);

        const pageView: PageViewDto = {
            hostname,
            path,
            pageTitle,
            userId: userId || null,
            anonymousId,
            organizationId: userOrganizationId,
            userAgent,
            referrer,
            url,
            search,
            utm_content: searchParams.get('utm_content'),
            utm_campaign: searchParams.get('utm_campaign'),
            utm_medium: searchParams.get('utm_medium'),
            utm_source: searchParams.get('utm_source'),
            utm_term: searchParams.get('utm_term'),
        };
        return pageView;
    };

    const trackPageView = async (pageView: PageViewDto) => {
        try {
            await sdk.profiles.pageView.recordPageView(pageView);
        } catch (error) {
            console.error('Error calling endpoint:', error);
        }
    };

    useEffect(() => {
        const pageView = createPageView();
        trackPageView(pageView);
    }, [location.pathname]);

    return <>{children}</>;
};
