import { IQuery } from '@zaplify/common';
import { Topics } from '@zaplify/common';
import { IUserSessionData } from '../authentication';

export class GetSessionQuery extends IQuery<IUserSessionData> {
    public static override readonly TOPIC = Topics.USERS;
    constructor(
        readonly token: string,
        readonly organizationId?: string | undefined,
        readonly authenticateAs?: 'inherit' | 'none' | string | undefined,
        // @TODO Remove the endpoint property. Was only included for logging purposes to be able to correlate requests in user-service with api gateway
        readonly endpoint?: string,
    ) {
        super();
    }
}
