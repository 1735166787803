import { LinkedinTriggerActivityDto } from '@zaplify/campaigns';
import { ICommand, Topics } from '@zaplify/common';

export class TrackMessagesCommand extends ICommand<void> {
    public static override readonly TOPIC = Topics.CAMPAIGNS;

    constructor(
        public readonly userId: string,
        readonly channelAccountId: string,
        readonly prospectId: string,
        readonly linkedinUserId: string,
        readonly activities?: LinkedinTriggerActivityDto[],
    ) {
        super();
    }
}
