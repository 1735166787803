import {
    IconButton,
    Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material';
import { Button } from '@zaplify/ui';
import React from 'react';
import { Avatar } from '../../../../../components/atoms/avatar';
import { ModalContainer } from './containers';
import { ReactComponent as Error } from '../../../../../components/icons/error-info.svg';
import FileDownloader from '../../../../../services/utils/FileHelpers/FileDownloader';
import Close from '@mui/icons-material/Close';
import { findError, mapDataPoint } from './functions';
import { revertCamelCase } from '../../../../../services/utils/revert-camel-case';

export const ViewProspectsModal = ({ onClose, prospects, errorModal = false }) => {
    const prospectKeysSet = new Set<string>();
    prospects.forEach((prospect) => {
        Object.keys(prospect.prospectData ? prospect.prospectData : prospect).forEach(
            (key) => key !== 'rowNumber' && prospectKeysSet.add(key)
        );
    });
    const prospectKeys = Array.from(prospectKeysSet);

    return (
        <Modal className="eror-modal" open={true} onClose={onClose}>
            <ModalContainer>
                <ModalHeader errorModal={errorModal} prospects={prospects} onClose={onClose} />
                <TableContainer>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {errorModal && <TableCell>Row</TableCell>}
                                {prospectKeys.map((key) => (
                                    <TableCell key={key}>{revertCamelCase(key)}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {prospects.map((prospect, idx) => (
                                <TableRowComponent
                                    key={idx}
                                    prospect={prospect}
                                    errorModal={errorModal}
                                    prospectKeys={prospectKeys}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </ModalContainer>
        </Modal>
    );
};

const ModalHeader = ({ errorModal, prospects, onClose }) => (
    <div className="header-container">
        <div>
            <Typography variant="h3">View {errorModal ? 'error' : 'skipped'} prospects</Typography>
            <Typography className="subtitle">
                {prospects.length} rows in your list were identified as{' '}
                {errorModal ? 'errors' : 'existing in organization'}. These prospects will not be created.
            </Typography>
        </div>
        <div>
            <ProspectDownload prospects={prospects} fileName={errorModal ? 'error-prospects' : 'skipped-prospects'} />
            <IconButton className="close-button" onClick={onClose} size="large">
                <Avatar className="close-button-circle">
                    <Close />
                </Avatar>
            </IconButton>
        </div>
    </div>
);

const TableRowComponent = ({ prospect, errorModal, prospectKeys }) => (
    <TableRow>
        {errorModal && (
            <TableCell className={errorModal ? 'valid' : 'invalid'} align="left">
                {prospect.errors[0]?.row}
            </TableCell>
        )}
        {prospectKeys.map((key) => (
            <CustomTableCell key={key} dataPoint={key} prospect={prospect} />
        ))}
    </TableRow>
);

const CustomTableCell = ({ prospect, dataPoint }) => {
    const error = findError(prospect, dataPoint);
    const data = mapDataPoint(prospect, dataPoint);

    return (
        <>
            {error ? (
                <Tooltip title={error}>
                    <TableCell className={'error'} align="left">
                        <Error />
                        {data}
                    </TableCell>
                </Tooltip>
            ) : (
                <TableCell className={'valid'} align="left">
                    {data}
                </TableCell>
            )}
        </>
    );
};

export const ProspectDownload = ({ prospects, fileName }) => {
    const handleDataCallback = () => {
        if (prospects[0].prospectData) return prospects.map((prospect) => prospect.prospectData);
        else return prospects;
    };

    return (
        <FileDownloader
            className="error-prospects-download"
            dataCallback={handleDataCallback}
            bom={true}
            config={{ delimiter: ';' }}
            filename={fileName}
        >
            <Button variant="secondary" text="Download List" />
        </FileDownloader>
    );
};
