import { ICommand, Topics } from '@zaplify/common';
import { CoreMessage } from 'ai';

export class UpdateThreadCommand extends ICommand<void> {
    public static override readonly TOPIC = Topics.PROSPECTS;

    public readonly userId: string;
    public readonly threadId: string;
    public readonly title?: string;
    public readonly lastMessageAt?: Date;
    public readonly metadata?: unknown;
    public readonly isArchived?: boolean;

    constructor(input: {
        userId: string;
        threadId: string;
        title?: string;
        lastMessageAt?: Date;
        metadata?: unknown;
        isArchived?: boolean;
    }) {
        super();
        this.userId = input.userId;
        this.threadId = input.threadId;
        this.title = input.title;
        this.lastMessageAt = input.lastMessageAt;
        this.metadata = input.metadata;
        this.isArchived = input.isArchived;
    }
}
