import { IEvent, Topics } from '@zaplify/common';
import { ProspectDto } from '../prospects';

export class ProspectOwnerSetEvent extends IEvent {
    public static override readonly TOPIC: Topics = Topics.PROSPECTS;
    constructor(readonly prospectId: string, readonly userOrganizationId: string, readonly userId: string) {
        super();
    }

    get partitionKey(): string {
        return this.userOrganizationId;
    }
}
