import { PlusCircle } from 'lucide-react';
import { FC } from 'react';

import { cva, type VariantProps } from 'class-variance-authority';
import React, { HTMLAttributes } from 'react';
import { cn } from '@shadcn/ui/lib/utils';

interface CreatePlaybookCardProps extends VariantProps<typeof cardVariants>, HTMLAttributes<HTMLDivElement> {}

const cardVariants = cva(
    'flex flex-col rounded-2xl bg-background-tertiary justify-center items-center cursor-pointer',
    {
        variants: {
            size: {
                default: 'w-64 h-60',
                small: 'sm:w-48 xl:w-60 h-52',
            },
        },
        defaultVariants: {
            size: 'default',
        },
    }
);

export const CreatePlaybookCard: FC<CreatePlaybookCardProps> = React.forwardRef<
    HTMLDivElement,
    CreatePlaybookCardProps
>(({ size, className, ...props }, ref) => {
    return (
        <div ref={ref} className={cn(cardVariants({ size, className }))} {...props}>
            <div className="flex flex-col p-4 gap-1 items-center">
                <PlusCircle className="w-12 h-12 stroke-[1] text-text-tertiary" />
                <span className="text-md font-medium">New playbook</span>
                <span className="text-sm">Create a new playbook</span>
            </div>
        </div>
    );
});
