export enum UserRole {
    ORGANIZATION_ADMIN = 'ORGANIZATION_ADMIN',
    ORGANIZATION_MEMBER = 'ORGANIZATION_MEMBER',
    GLOBAL_ADMIN = 'GLOBAL_ADMIN',
    // SYSTEM_ADMIN = 'SYSTEM_ADMIN',
    // BILLING_ADMIN = 'BILLING_ADMIN',
    // TEAM_LEADER = 'TEAM_LEADER',
}

export enum UserStatus {
    ACTIVATED = 'ACTIVATED',
    DEACTIVATED = 'DEACTIVATED',
    INVITED = 'INVITED',
    NO_SEATS = 'NO_SEATS',
    NO_SEAT = 'NO_SEAT',
}
