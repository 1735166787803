import { ChannelProvider } from '@zaplify/channel-accounts/shared';
import { IEvent, Topics } from '@zaplify/common';

export class TrackerExpiredEvent extends IEvent {
    public static override readonly TOPIC = Topics.CAMPAIGNS;

    constructor(
        readonly channelAccountId: string,
        readonly provider: ChannelProvider,
        readonly recipient: string,
        readonly accessToken: string,
        readonly refreshToken: string,
        readonly afterTimestamp: number,
        readonly userId: string,
        readonly organizationId: string,
    ) {
        super();
    }

    get partitionKey(): string {
        return this.organizationId;
    }
}
