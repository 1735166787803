import * as React from 'react';
import {
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogFooter,
    DialogClose,
    DialogDescription,
} from '@shadcn/ui/components/ui/dialog';
import { Button } from '@shadcn/ui/components/ui/button';
import { Trash } from 'lucide-react';
import { useMessages } from '../../hooks/messaging/use-messages';
import { SearchParamDialog, useSearchParamDialog } from '@shadcn/ui/components/search-param-dialog';
import { useParams } from 'react-router-dom';

export const useDeleteNoteDialog = () => {
    const { open, close, value } = useSearchParamDialog('delete-note');

    return {
        openDeleteNoteDialog: (noteId: string) => open(noteId),
        closeDeleteNoteDialog: close,
        value,
    };
};

export const DeleteNoteDialog: React.FC = () => {
    const { prospectId: pageProspectId } = useParams();
    const { removeNote } = useMessages({ prospectId: pageProspectId });
    const { value: noteId } = useDeleteNoteDialog();

    return (
        <SearchParamDialog param={'delete-note'}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Delete Note</DialogTitle>
                </DialogHeader>
                <DialogDescription>Are you sure you want to delete this note?</DialogDescription>
                <DialogFooter>
                    <DialogClose asChild>
                        <Button variant="outline">Cancel</Button>
                    </DialogClose>
                    <DialogClose asChild>
                        <Button variant="destructive" onClick={() => removeNote({ noteId })}>
                            <Trash className="w-5 h-5" />
                            Delete
                        </Button>
                    </DialogClose>
                </DialogFooter>
            </DialogContent>
        </SearchParamDialog>
    );
};
