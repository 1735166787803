import makeStyles from '@mui/styles/makeStyles';

export const styles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            flexBasis: '80%',
        },
        '&.MuiInputLabel-outlined': {
            color: 'red',
        },
    },
    input: {
        backgroundColor: '#fff',
        border: '0.8px solid #E4E6EB',
        boxShadow: '0px 2px 6px rgba(112, 144, 176, 0.07)',
        borderRadius: '8px',
        width: '343px',
        margin: '20px',
    },
    searchIcon: {
        color: '#96A2AC',
    },
}));
