import { Box, Button, TextField, Typography } from '@mui/material';
import { useState } from 'react';

const CouponCode = ({
    handleCouponCode,
    isCouponCodeValid,
    setIsCouponCodeValid,
}: {
    handleCouponCode: (couponCode: string) => void;
    isCouponCodeValid: boolean | string;
    setIsCouponCodeValid: (couponCode: string | boolean) => void;
}) => {
    const [couponCode, setCouponCode] = useState('');

    return (
        <div style={{ display: 'flex', flexDirection: 'column', paddingTop: '26px' }}>
            <Typography style={{ color: '#6B7985' }}>Promo code</Typography>
            <Box>
                <TextField
                    variant="standard"
                    placeholder="E30OFFTODAY"
                    onChange={(event) => {
                        setCouponCode(event.target.value);
                        setIsCouponCodeValid('');
                    }}
                    value={couponCode || ''}
                    InputProps={{
                        style: {
                            boxSizing: 'border-box',
                            width: 212,
                            height: 38,
                            marginTop: '9px',
                            borderRadius: 8,
                            border: '0.8px solid #E4E6EB',
                            paddingLeft: 11,
                        },
                        disableUnderline: true,
                    }}
                />
                <Button
                    style={{
                        height: 38,
                        padding: 12,
                        borderRadius: 8,
                        border: '0.8px solid #E4E6EB',
                        paddingLeft: 11,
                        margin: '9px 0 0 7px',
                        color: '#3A4B59',
                    }}
                    onClick={() => {
                        handleCouponCode(couponCode);
                    }}
                >
                    Apply
                </Button>
            </Box>

            {!isCouponCodeValid && typeof isCouponCodeValid === 'boolean' && (
                <Typography
                    style={{
                        color: '#FE5C5E',
                        fontSize: 15,
                        marginTop: 4,
                    }}
                >
                    Promo code couldn't be applied
                </Typography>
            )}
        </div>
    );
};

export default CouponCode;
