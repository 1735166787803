import { useSubscription } from '@apollo/client';
import { GET_CONVERSATIONS_W_LAST_MSG_SUBSCRIPTION } from '@zaplify/graphql';
import { useSetAtom } from 'jotai';
import { messagesAtom, messagesLoadingAtom } from '../../atoms/chats';
import useProspectAvatars from '../../views/chats/hooks/use-prospect-avatars';
import { useEffect } from 'react';

export const MessagesSubscription = () => {
    const setConversations = useSetAtom(messagesAtom);
    const setMessagesLoading = useSetAtom(messagesLoadingAtom);
    const { fetchProspectAvatarUrls } = useProspectAvatars();

    const { loading } = useSubscription(GET_CONVERSATIONS_W_LAST_MSG_SUBSCRIPTION, {
        onData: async (data) => {
            if (data.data.data?.Messages) {
                const prospectIds = data.data.data.Messages.map((m) => m.prospectId);
                await fetchProspectAvatarUrls(prospectIds);

                const sortedConversationsDesc = data.data.data.Messages.sort((a, b) => {
                    return new Date(b.sentOn).getTime() - new Date(a.sentOn).getTime();
                });

                setConversations(sortedConversationsDesc);
            }
        },
    });

    useEffect(() => {
        setMessagesLoading(loading);
    }, [loading]);

    return null;
};
