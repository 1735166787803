import { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import { Textarea } from '@shadcn/ui/components/ui/textarea';
import { PopoverContent } from '@shadcn/ui/components/ui/popover';
import { useMessageComposer } from '../../../../hooks/messaging/use-message-composer';
import { Badge } from '@shadcn/ui/components/ui/badge';
import { useSdk } from '../../../../sdk/use-sdk';
import { useQuery } from '@tanstack/react-query';
import { Tooltip, TooltipContent, TooltipTrigger } from '@shadcn/ui/components/ui/tooltip';
import { Button } from '@shadcn/ui/components/ui/button';
import { useUser } from '../../../../hooks/use-user';
import { useAuth } from '../../../../providers/authentication-provider';
import { PlanCode } from '@zaplify/subscriptions';

interface RegenerateMessageCommandProps {
    onClose: () => void;
}

export const RegenerateMessagePopoverContent: React.FC<RegenerateMessageCommandProps> = ({ onClose }) => {
    const [inputValue, setInputValue] = useState('');
    const { regenerateMessage } = useMessageComposer();
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const {
        authState: {
            tokenResult: {
                claims: { plan },
            },
        },
    } = useAuth();

    const handleChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setInputValue(event.target.value);
    }, []);

    const {
        user: { getUserReferralLink },
    } = useSdk();

    const { data } = useQuery(getUserReferralLink());

    const referralLink = useMemo(
        () =>
            data?.id
                ? `${window.location.protocol}//${window.location.host}/register?referralLinkId=${data.id}`
                : '<Referral link>',
        [data?.id]
    );

    const referralMessage = useMemo(
        () => `Remove everything from the existing message and write a message with the sole purpose of recommending them to TRY OUT Andsend in a CASUAL "BY THE WAY" KIND OF WAY. Assume that they know nothing about the platform and keep the recommendation really short. 
    
    Some context about Andsend. This is the promise: "Just review and send. The Customer Relationship Agent that helps you focus on the contacts that matter. Send great messages to the right people and never forget a follow-up". 
    
    You have to include my referral link: ${referralLink}`,
        [referralLink]
    );

    const easterMessage = useMemo(
        () => `Do not continue the previous topic in the conversation. Instead, start a new conversation topic and focus on the easter greeting. Send cheerful wishes and easter greetings to clients, partners, and colleagues.
Personalize messages with light, friendly touches such as Easter symbols and spring themes.
You can optionally add a light Easter emoji (like 🐣🌸🥚) if it fits the tone.
Keep it short and positive.
The goal is to strengthen relationships and leave a positive impression. Easter occurs on April 20th.`,
        []
    );

    const submitWithMessage = useCallback(
        (message: string) => {
            setInputValue(message);
            regenerateMessage({ feedback: message });
            onClose();
            setInputValue('');
        },
        [regenerateMessage, onClose]
    );

    const handleKeyDown = useCallback(
        (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
            if (event.key === 'Enter' && !event.shiftKey) {
                event.preventDefault();
                submitWithMessage(inputValue);
            }
        },
        [submitWithMessage, inputValue]
    );

    const useAutosizeTextArea = (textAreaRef: HTMLTextAreaElement | null, value: string) => {
        useEffect(() => {
            if (textAreaRef) {
                // We need to reset the height momentarily to get the correct scrollHeight for the textarea
                textAreaRef.style.height = '0px';
                const scrollHeight = textAreaRef.scrollHeight;

                // We then set the height directly, outside of the render loop
                // Trying to set this with state or a ref will product an incorrect value.
                textAreaRef.style.height = scrollHeight + 22 + 'px';
            }
        }, [textAreaRef, value]);
    };
    useAutosizeTextArea(textareaRef.current, inputValue);

    const shouldShowEasterGreeting = useMemo(() => {
        const today = new Date();
        const easter = new Date(today.getFullYear(), 3, 20); // April 20th as reference
        const twoWeeksBefore = new Date(easter);
        twoWeeksBefore.setDate(easter.getDate() - 14);
        const twoDaysAfter = new Date(easter);
        twoDaysAfter.setDate(easter.getDate() + 2);
        return today >= twoWeeksBefore && today <= twoDaysAfter;
    }, [inputValue]);

    return (
        <PopoverContent
            className="rounded-2xl p-0 w-96"
            side="top"
            onOpenAutoFocus={(e) => {
                e.preventDefault();
                textareaRef?.current?.focus?.();
            }}
        >
            <div className="absolute top-2 left-2 flex gap-2 items-center">
                <span className="text-xs text-muted-foreground">Suggested:</span>
                <Tooltip delayDuration={300}>
                    <TooltipContent>
                        <p>
                            Invite them to use Andsend.{' '}
                            {plan === PlanCode.FREE
                                ? 'Your contact limit will increase when they sign up using your link.'
                                : ''}
                        </p>
                    </TooltipContent>
                    <TooltipTrigger>
                        <Badge
                            variant="outline"
                            className="cursor-pointer hover:bg-background-primary-alt"
                            onClick={() => {
                                window?.analytics?.track?.('User clicked invite to Andsend in regenerate message');
                                submitWithMessage(referralMessage);
                            }}
                        >
                            Invite to Andsend 🤝
                        </Badge>
                    </TooltipTrigger>
                </Tooltip>
                {shouldShowEasterGreeting && (
                    <Badge
                        variant="outline"
                        className="cursor-pointer hover:bg-background-primary-alt"
                        onClick={() => submitWithMessage(easterMessage)}
                    >
                        Easter greeting 🐣
                    </Badge>
                )}
            </div>
            <Textarea
                ref={textareaRef}
                value={inputValue}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                className="rounded-2xl p-2 pt-10 pb-8 w-96 h-auto resize-none"
                placeholder="How would you like to change the message?"
            />
            <div className="absolute bottom-2 right-2 flex justify-end">
                <Button variant="outline" size="sm" onClick={() => submitWithMessage(inputValue)}>
                    Regenerate
                </Button>
            </div>
        </PopoverContent>
    );
};
