import { webhooksActionTypes } from '../actionTypes';

const initialState = {
    addedWebhooks: [],
    availableEvents: [],
    url: '',
    eventName: 'ProspectBatchQualified',
    testResult: {},
    drawerMode: 0,
    id: '',
};

const webhooksReducer = (state = initialState, action) => {
    switch (action.type) {
        case webhooksActionTypes.UPDATE_ADDED_WEBHOOKS_EVENTS:
            return {
                ...state,
                addedWebhooks: action.payload,
            };
        case webhooksActionTypes.UPDATE_AVAILABLE_WEBHOOKS_EVENTS:
            return {
                ...state,
                availableEvents: action.payload,
            };
        case webhooksActionTypes.UPDATE_WEBHOOK_DRAWER:
            return {
                ...state,
                drawerMode: action.payload.mode,
                url: action.payload.requestUrl || '',
                id: action.payload.id || '',
                eventName: action.payload.eventName || 'ProspectBatchQualified',
            };
        case webhooksActionTypes.UPDATE_WEBHOOK_URL:
            return {
                ...state,
                url: action.payload,
            };
        case webhooksActionTypes.UPDATE_WEBHOOK_EVENT_NAME:
            return {
                ...state,
                eventName: action.payload,
            };
        case webhooksActionTypes.UPDATE_WEBHOOK_TEST_RESULT:
            return {
                ...state,
                testResult: action.payload,
            };
        default:
            return { ...state };
    }
};

export default webhooksReducer;
