import { z } from 'zod';
import { tool } from 'ai';

const WebSearchToolArgsZ = z.object({
    query: z.string().describe('The search query to look up on the web'),
});

export type WebSearchToolArgs = z.infer<typeof WebSearchToolArgsZ>;

export type WebSearchToolResult = string;

export const WebSearchToolFactory = {
    name: 'webSearch',
    makeTool: (execute: (args: WebSearchToolArgs) => Promise<WebSearchToolResult>) =>
        tool({
            description:
                'Search the web for real-time information about any topic. Use this tool when you need current or factual information.',
            parameters: WebSearchToolArgsZ,
            execute,
        }),
};
