import { Suspense } from 'react';
import {
    ContactDetailsSidebarComponent,
    ContactDetailsSidebarSkeleton,
} from '../../../components/contact-details-sidebar/contact-details-sidebar';
import { useActionCardDetails } from '../hooks/use-action-card-details';
import { cn } from '@shadcn/ui/lib/utils';
export const ActionCardDetails = ({ className }: { className?: string }) => {
    const { isOpen, onClose } = useActionCardDetails();
    return (
        <div className={cn('max-w-[280px]', className)}>
            <Suspense
                fallback={
                    <ContactDetailsSidebarSkeleton
                        isOpen={isOpen}
                        onClose={onClose}
                        direction="left"
                        className={cn('rounded-xl border pl-4 -ml-4 z-1 max-h-full  ', isOpen && 'shadow-lg')}
                    />
                }
            >
                <ContactDetailsSidebarComponent
                    className={cn('rounded-xl border pl-4 -ml-4 z-1 max-h-full  ', isOpen && 'shadow-lg')}
                    isOpen={isOpen}
                    onClose={onClose}
                    direction="left"
                />
            </Suspense>
        </div>
    );
};
