import { styled } from '@mui/styles';

export const CSVModalContainer = styled('div')(({ theme }: any) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '600px',
    alignItems: 'center',
    textAlign: 'center',
    padding: '45px 70px',
    '& > h3:first-child': {
        fontWeight: '300',
        marginBottom: '20px',
    },
    '& > p:nth-child(2)': {
        marginBottom: '30px',
    },
    '& > div:nth-child(3)': {
        marginBottom: '15px',
        '& > div:nth-child(2)': {
            '& > div:first-child': {
                width: '300px !important',
                height: '90px !important',
                background: '#F7F8FA !important',
            },
        },
    },
    '& > p:nth-child(4)': {
        marginBottom: '10px',
        fontSize: '22px',
        fontWeight: '300',
    },
}));
