import { BaseSdk } from '../../../base.sdk';
import { UpdateSettingsDto } from '@zaplify/pipedrive';

export class PipedriveSdk extends BaseSdk {
    async getSettings(organizationId: string) {
        return this.get(`${this.appsPath}/${this.pipedrivePath}/settings?organizationId=${organizationId}`);
    }

    async updateSettings(updateSettingsDTO: UpdateSettingsDto, errorCallback: (error: any) => void) {
        return this.put<void>(`${this.appsPath}/${this.pipedrivePath}/settings`, updateSettingsDTO, errorCallback);
    }

    async connect(organizationId: string, code: string) {
        return this.post<void>({
            path: `${this.appsPath}/${this.pipedrivePath}/connect`,
            payload: { organizationId: organizationId, code: code },
        });
    }

    async disconnect(organizationId: string) {
        return this.post<void>({
            path: `${this.appsPath}/${this.pipedrivePath}/disconnect`,
            payload: { organizationId: organizationId },
        });
    }

    async pause(organizationId: string) {
        return this.post<void>({
            path: `${this.appsPath}/${this.pipedrivePath}/pause`,
            payload: { organizationId: organizationId },
        });
    }

    async resume(organizationId: string) {
        return this.post<void>({
            path: `${this.appsPath}/${this.pipedrivePath}/resume`,
            payload: { organizationId: organizationId },
        });
    }

    async getProspectPipedriveActivitiesById(prospectId: string) {
        return this.post<void>({ path: `${this.appsPath}/${this.pipedrivePath}/prospect/${prospectId}/activities` });
    }

    async getAllFields(organizationId: string) {
        return this.get<any>(`${this.appsPath}/${this.pipedrivePath}/mapping/fields?organizationId=${organizationId}`);
    }

    async migrateCampaign(campaignId: string) {
        return this.post<any[]>({ path: `${this.appsPath}/${this.pipedrivePath}/persons/fromCampaign/${campaignId}` });
    }
}
