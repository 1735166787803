import { FC, useState, useEffect, useMemo, memo } from 'react';
import { Button } from '@shadcn/ui/components/ui/button';
import { Input } from '@shadcn/ui/components/ui/input';
import { Check, X, Pencil, Trash, Search, Loader2, AlertCircle, ChevronLeft, ChevronRight, Info } from 'lucide-react';
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogDescription,
    DialogFooter,
} from '@shadcn/ui/components/ui/dialog';
import { toast } from '@shadcn/ui/hooks/use-toast';
import { CreateProspectDataDto } from '@zaplify/prospects';
import { validateProspect, ProspectValidationError, getFieldMetadata } from '../validation';
import { Badge } from '@shadcn/ui/components/ui/badge';

interface ReviewStepProps {
    mappedProspects: CreateProspectDataDto[];
    isProcessing: boolean;
    setIsProcessing: (isProcessing: boolean) => void;
    onImportComplete: () => void;
    onProspectsUpdate: (prospects: CreateProspectDataDto[]) => void;
    onValidationUpdate?: (invalidCount: number, warnings: { fieldId: string; message: string }[]) => void;
}

export const ReviewStep: FC<ReviewStepProps> = ({
    mappedProspects,
    isProcessing,
    setIsProcessing,
    onImportComplete,
    onProspectsUpdate,
    onValidationUpdate,
}) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedProspects, setSelectedProspects] = useState<number[]>([]);
    const [editingProspect, setEditingProspect] = useState<{ index: number; data: CreateProspectDataDto } | null>(null);
    const [editedData, setEditedData] = useState<CreateProspectDataDto | null>(null);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [prospectsToDelete, setProspectsToDelete] = useState<number[]>([]);
    const [validationErrors, setValidationErrors] = useState<Record<number, ProspectValidationError[]>>({});

    // Pagination state
    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(20); // Default 20 contacts per page

    // Validate all prospects on mount and when they change
    useEffect(() => {
        console.log('Starting validation of', mappedProspects.length, 'prospects');
        const startTime = Date.now();

        // Skip validation if there are no prospects
        if (mappedProspects.length === 0) {
            setValidationErrors({});
            if (onValidationUpdate) {
                onValidationUpdate(0, []);
            }
            return;
        }

        const errors: Record<number, ProspectValidationError[]> = {};
        mappedProspects.forEach((prospect, index) => {
            const prospectErrors = validateProspect(prospect);
            if (prospectErrors.length > 0) {
                errors[index] = prospectErrors;
            }
        });
        setValidationErrors(errors);

        // Notify parent component about validation changes
        if (onValidationUpdate) {
            const invalidCount = Object.keys(errors).length;

            // Format errors for parent component
            const warnings = Object.entries(errors).flatMap(([_, prospectErrors]) =>
                prospectErrors.map((error) => ({
                    fieldId: error.path?.join('.') || '',
                    message: typeof error.message === 'string' ? error.message : JSON.stringify(error.message),
                }))
            );

            onValidationUpdate(invalidCount, warnings);
        }

        console.log('Validation completed in', Date.now() - startTime, 'ms');
    }, [mappedProspects]); // Only run when mappedProspects changes

    const filteredProspects = mappedProspects.filter((prospect) => {
        if (!searchQuery) return true;
        const query = searchQuery.toLowerCase();

        return (
            prospect.firstName?.toLowerCase().includes(query) ||
            prospect.lastName?.toLowerCase().includes(query) ||
            prospect.email?.toLowerCase().includes(query) ||
            prospect.organizationName?.toLowerCase().includes(query) ||
            prospect.occupationTitle?.toLowerCase().includes(query)
        );
    });

    // Pagination logic
    const totalPages = Math.ceil(filteredProspects.length / itemsPerPage);
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedProspects = filteredProspects.slice(startIndex, endIndex);

    const goToPage = (page: number) => {
        if (page >= 0 && page < totalPages) {
            setCurrentPage(page);
        }
    };

    const handleToggleSelectAll = () => {
        if (selectedProspects.length === paginatedProspects.length) {
            // Deselect all on current page
            const currentPageIndexes = paginatedProspects.map((_, i) => startIndex + i);
            setSelectedProspects(selectedProspects.filter((idx) => !currentPageIndexes.includes(idx)));
        } else {
            // Select all on current page
            const currentPageIndexes = paginatedProspects.map((_, i) => startIndex + i);
            const newSelected = [...selectedProspects];

            currentPageIndexes.forEach((idx) => {
                if (!newSelected.includes(idx)) {
                    newSelected.push(idx);
                }
            });

            setSelectedProspects(newSelected);
        }
    };

    const handleToggleSelect = (index: number) => {
        if (selectedProspects.includes(index)) {
            setSelectedProspects(selectedProspects.filter((i) => i !== index));
        } else {
            setSelectedProspects([...selectedProspects, index]);
        }
    };

    const handleEditProspect = (index: number) => {
        setEditingProspect({
            index,
            data: { ...mappedProspects[index] },
        });
        setEditedData({ ...mappedProspects[index] });
    };

    const handleSaveEdit = () => {
        if (!editingProspect || !editedData) return;

        // Validate the edited data
        const errors = validateProspect(editedData);
        if (errors.length > 0) {
            toast({
                title: 'Validation Error',
                description: errors.map((err) => `${err.path.join('.')}: ${err.message}`).join('\n'),
                variant: 'destructive',
            });
            return;
        }

        // Create a new array to avoid mutating the original
        const updatedProspects = [...mappedProspects];
        updatedProspects[editingProspect.index] = editedData;

        // Update the parent component's state
        onProspectsUpdate(updatedProspects);
        setEditingProspect(null);
        setEditedData(null);
    };

    const handleDeleteSelected = () => {
        setProspectsToDelete(selectedProspects);
        setShowDeleteDialog(true);
    };

    const confirmDelete = () => {
        // Create a new array with prospects that aren't selected for deletion
        const updatedProspects = mappedProspects.filter((_, index) => !prospectsToDelete.includes(index));

        // Update the parent component's state
        onProspectsUpdate(updatedProspects);

        setSelectedProspects([]);
        setShowDeleteDialog(false);
        setProspectsToDelete([]);

        toast({
            title: 'Prospects removed',
            description: `Removed ${prospectsToDelete.length} prospects from the import list`,
            variant: 'success',
        });
    };

    // Render validation errors for a prospect
    const renderValidationErrors = (index: number) => {
        const errors = validationErrors[index];
        if (!errors?.length) return null;

        return (
            <div className="mt-1 text-xs text-destructive">
                {errors.map((err, i) => (
                    <div key={i}>{`${err.path.join('.')}: ${err.message}`}</div>
                ))}
            </div>
        );
    };

    // Simple button to show validation issues
    const ValidationIssuesButton = () => {
        const invalidCount = Object.keys(validationErrors).length;
        const [isDialogOpen, setIsDialogOpen] = useState(false);

        if (invalidCount === 0) return null;

        // Get field metadata for user-friendly labels
        const fieldMetadata = getFieldMetadata();

        // Simple list of validation issues
        const getValidationSummary = () => {
            const issues: string[] = [];

            Object.entries(validationErrors).forEach(([index, errors]) => {
                errors.forEach((error) => {
                    const fieldName = fieldMetadata[error.path.join('.')]?.label || error.path.join('.');
                    const message = typeof error.message === 'string' ? error.message : JSON.stringify(error.message);
                    issues.push(`${fieldName}: ${message}`);
                });
            });

            return [...new Set(issues)]; // Remove duplicates
        };

        return (
            <>
                <Button
                    variant="outline"
                    size="sm"
                    onClick={() => setIsDialogOpen(true)}
                    className="h-8 bg-yellow-50 hover:bg-yellow-100 border-yellow-200 text-yellow-700 gap-1"
                >
                    <AlertCircle className="h-3.5 w-3.5 mr-1" />
                    {invalidCount} {invalidCount === 1 ? 'issue' : 'issues'}
                </Button>

                <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                    <DialogContent>
                        <DialogHeader>
                            <DialogTitle>Validation Issues</DialogTitle>
                            <DialogDescription>
                                Please fix the following issues by editing the contacts below:
                            </DialogDescription>
                        </DialogHeader>

                        <div className="max-h-[300px] overflow-y-auto">
                            <ul className="space-y-2 my-4">
                                {getValidationSummary().map((issue, index) => (
                                    <li key={index} className="text-sm">
                                        • {issue}
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <DialogFooter>
                            <Button onClick={() => setIsDialogOpen(false)}>Close</Button>
                        </DialogFooter>
                    </DialogContent>
                </Dialog>
            </>
        );
    };

    if (mappedProspects.length === 0) {
        return (
            <div className="text-center py-10">
                <AlertCircle className="h-12 w-12 text-muted-foreground mx-auto mb-4" />
                <h3 className="text-lg font-medium mb-2">No contacts to review</h3>
                <p className="text-muted-foreground mb-4">
                    Please go back to the previous steps to upload and map your contacts.
                </p>
                <Button variant="outline" onClick={() => window.history.back()}>
                    Go Back
                </Button>
            </div>
        );
    }

    return (
        <div className="space-y-6 w-full">
            <div className="flex justify-between items-center flex-wrap gap-2">
                <div className="flex items-center gap-2">
                    <Button variant="outline" size="sm" className="h-8" onClick={handleToggleSelectAll}>
                        {paginatedProspects.every((_, i) => selectedProspects.includes(startIndex + i))
                            ? 'Deselect All'
                            : 'Select All'}
                    </Button>

                    {selectedProspects.length > 0 && (
                        <Button variant="destructive" size="sm" className="h-8" onClick={handleDeleteSelected}>
                            <Trash className="h-4 w-4 mr-1" />
                            Delete Selected
                        </Button>
                    )}

                    <ValidationIssuesButton />
                </div>

                <div className="relative">
                    <Search className="h-4 w-4 absolute left-2.5 top-2.5 text-muted-foreground" />
                    <Input
                        placeholder="Search contacts..."
                        className="pl-9 h-9 w-48"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </div>
            </div>

            <div className="border rounded-lg overflow-hidden">
                <div className="bg-muted px-4 py-3 flex items-center">
                    <div className="w-10 flex-none">
                        <span className="sr-only">Select</span>
                    </div>
                    <div className="w-1/4 text-sm">Name</div>
                    <div className="w-1/4 text-sm">Email</div>
                    <div className="w-1/4 text-sm">Company</div>
                    <div className="w-1/4 text-sm">Title</div>
                    <div className="w-20 flex-none text-sm text-right">Actions</div>
                </div>

                <div className="max-h-[calc(100vh-480px)] overflow-auto">
                    {paginatedProspects.map((prospect, i) => {
                        const index = startIndex + i;
                        return (
                            <div
                                key={index}
                                className={`border-t flex flex-col px-4 py-3 hover:bg-muted/50 ${
                                    validationErrors[index]?.length ? 'bg-destructive/5' : ''
                                }`}
                            >
                                <div className="flex items-center">
                                    <div className="w-10 flex-none">
                                        <Button
                                            variant="ghost"
                                            size="icon"
                                            className="h-6 w-6"
                                            onClick={() => handleToggleSelect(index)}
                                        >
                                            {selectedProspects.includes(index) ? (
                                                <Check className="h-4 w-4 text-primary" />
                                            ) : (
                                                <div className="h-4 w-4 rounded border border-muted-foreground/30" />
                                            )}
                                        </Button>
                                    </div>
                                    <div className="w-1/4 text-sm truncate">
                                        {prospect.fullName
                                            ? prospect.fullName.length > 30
                                                ? prospect.fullName.substring(0, 30) + '...'
                                                : prospect.fullName
                                            : '—'}
                                    </div>
                                    <div className="w-1/4 truncate text-muted-foreground text-sm">
                                        {prospect.email
                                            ? prospect.email.length > 30
                                                ? prospect.email.substring(0, 30) + '...'
                                                : prospect.email
                                            : '—'}
                                    </div>
                                    <div className="w-1/4 truncate text-muted-foreground text-sm">
                                        {prospect.organizationName
                                            ? prospect.organizationName.length > 30
                                                ? prospect.organizationName.substring(0, 30) + '...'
                                                : prospect.organizationName
                                            : '—'}
                                    </div>
                                    <div className="w-1/4 truncate text-muted-foreground text-sm">
                                        {prospect.occupationTitle
                                            ? prospect.occupationTitle.length > 30
                                                ? prospect.occupationTitle.substring(0, 30) + '...'
                                                : prospect.occupationTitle
                                            : '—'}
                                    </div>
                                    <div className="w-20 flex-none text-right">
                                        <div className="flex items-center justify-end space-x-1">
                                            <Button
                                                variant="ghost"
                                                size="icon"
                                                className="h-7 w-7"
                                                onClick={() => handleEditProspect(index)}
                                            >
                                                <Pencil className="h-3.5 w-3.5" />
                                            </Button>
                                            <Button
                                                variant="ghost"
                                                size="icon"
                                                className="h-7 w-7 text-destructive"
                                                onClick={() => {
                                                    setProspectsToDelete([index]);
                                                    setShowDeleteDialog(true);
                                                }}
                                            >
                                                <Trash className="h-3.5 w-3.5" />
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                {validationErrors[index]?.length > 0 && (
                                    <div className="ml-10 mt-1 text-xs text-destructive">
                                        {validationErrors[index].map((err, i) => (
                                            <div key={i}>{`${err.path.join('.')}: ${err.message}`}</div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        );
                    })}

                    {paginatedProspects.length === 0 && (
                        <div className="h-24 flex items-center justify-center text-center text-muted-foreground">
                            No contacts match your search.
                        </div>
                    )}
                </div>
            </div>

            <div className="flex flex-wrap items-center justify-between gap-4 text-sm text-muted-foreground">
                <div>
                    {searchQuery
                        ? `Showing ${startIndex + 1}-${Math.min(endIndex, filteredProspects.length)} of ${
                              filteredProspects.length
                          } contacts`
                        : `${startIndex + 1}-${Math.min(endIndex, mappedProspects.length)} of ${
                              mappedProspects.length
                          } contacts`}
                </div>

                {/* Pagination controls */}
                {totalPages > 1 && (
                    <div className="flex items-center gap-2">
                        <Button
                            variant="outline"
                            size="sm"
                            onClick={() => goToPage(currentPage - 1)}
                            disabled={currentPage === 0}
                            className="h-8 w-8 p-0"
                        >
                            <ChevronLeft className="h-4 w-4" />
                        </Button>
                        <span className="text-sm">
                            Page {currentPage + 1} of {totalPages}
                        </span>
                        <Button
                            variant="outline"
                            size="sm"
                            onClick={() => goToPage(currentPage + 1)}
                            disabled={currentPage === totalPages - 1}
                            className="h-8 w-8 p-0"
                        >
                            <ChevronRight className="h-4 w-4" />
                        </Button>
                    </div>
                )}

                <div>{selectedProspects.length > 0 && `${selectedProspects.length} contacts selected`}</div>
            </div>

            {/* Edit Contact Dialog */}
            <Dialog open={!!editingProspect} onOpenChange={(open) => !open && setEditingProspect(null)}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Edit Contact</DialogTitle>
                        <DialogDescription>Make changes to the contact information.</DialogDescription>
                    </DialogHeader>

                    {editingProspect && (
                        <div className="grid gap-4 py-4">
                            <div className="grid grid-cols-2 gap-4">
                                <div className="space-y-2">
                                    <label className="text-sm font-medium">First Name</label>
                                    <Input
                                        value={editedData.firstName || ''}
                                        onChange={(e) => setEditedData({ ...editedData, firstName: e.target.value })}
                                    />
                                </div>
                                <div className="space-y-2">
                                    <label className="text-sm font-medium">Last Name</label>
                                    <Input
                                        value={editedData.lastName || ''}
                                        onChange={(e) => setEditedData({ ...editedData, lastName: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className="space-y-2">
                                <label className="text-sm font-medium">Email</label>
                                <Input
                                    value={editedData.email || ''}
                                    onChange={(e) => setEditedData({ ...editedData, email: e.target.value })}
                                />
                            </div>
                            <div className="space-y-2">
                                <label className="text-sm font-medium">Company</label>
                                <Input
                                    value={editedData.organizationName || ''}
                                    onChange={(e) => setEditedData({ ...editedData, organizationName: e.target.value })}
                                />
                            </div>
                            <div className="space-y-2">
                                <label className="text-sm font-medium">Position</label>
                                <Input
                                    value={editedData.occupationTitle || ''}
                                    onChange={(e) => setEditedData({ ...editedData, occupationTitle: e.target.value })}
                                />
                            </div>
                            <div className="space-y-2">
                                <label className="text-sm font-medium">LinkedIn URL</label>
                                <Input
                                    value={editedData.linkedinProfileUrl || ''}
                                    onChange={(e) =>
                                        setEditedData({ ...editedData, linkedinProfileUrl: e.target.value })
                                    }
                                />
                            </div>
                        </div>
                    )}

                    <DialogFooter>
                        <Button variant="outline" onClick={() => setEditingProspect(null)}>
                            Cancel
                        </Button>
                        <Button onClick={handleSaveEdit}>Save changes</Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>

            {/* Delete Confirmation Dialog */}
            <Dialog open={showDeleteDialog} onOpenChange={setShowDeleteDialog}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Confirm Deletion</DialogTitle>
                        <DialogDescription>
                            Are you sure you want to delete {prospectsToDelete.length}
                            {prospectsToDelete.length === 1 ? ' contact' : ' contacts'}? This action cannot be undone.
                        </DialogDescription>
                    </DialogHeader>
                    <DialogFooter>
                        <Button variant="outline" onClick={() => setShowDeleteDialog(false)}>
                            Cancel
                        </Button>
                        <Button variant="destructive" onClick={confirmDelete}>
                            Delete
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default ReviewStep;
