import { ICommand, Topics } from '@zaplify/common';
import { MessageDto } from '../dtos';

export class GenerateAsEmailCommand extends ICommand<MessageDto> {
    public static override readonly TOPIC = Topics.CAMPAIGNS;

    constructor(
        public readonly messageId: string,
        public readonly userId: string,
        public readonly content: string,
        public readonly subjectLine?: string,
    ) {
        super();
    }
}
