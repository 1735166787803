const productionApiEndpoint = 'https://api.andsend.com/api/v1';
const devFallbackApiEndpoint = 'http://localhost:8000/api/v1';

const apiEndpointDef = typeof import.meta?.env?.MODE === 'undefined' ? process.env.NODE_ENV : import.meta.env.MODE;

function getEnvVariable(key: string, defaultValue: string): string {
    try {
        if (typeof import.meta !== 'undefined' && import.meta.env) {
            const value = import.meta.env[key];
            if (value) return value;
        }
    } catch {}

    if (typeof process !== 'undefined' && process.env) {
        const value = process.env[key];
        if (value) return value;
    }

    return defaultValue;
}

export const apiEndpoint = getEnvVariable(
    'VITE_ZAPLIFY_API_ENDPOINT',
    apiEndpointDef === 'production' ? productionApiEndpoint : devFallbackApiEndpoint,
);
