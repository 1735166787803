import { ChannelType } from '@zaplify/channel-accounts/shared';
import { IEvent, Topics } from '@zaplify/common';
import { MessageType } from '../enums';

export class MessageFoundEvent extends IEvent {
    public static override readonly TOPIC = Topics.CAMPAIGNS;

    constructor(
        readonly userId: string,
        readonly organizationId: string,
        readonly messageId: string,
        readonly prospectId: string,
        readonly channelAccountId: string,
        readonly direction: string,
        readonly sentOn: Date,
        readonly channelType: ChannelType,
        readonly messageType: MessageType,
    ) {
        super();
    }

    get partitionKey(): string {
        return this.prospectId;
    }
}
