import { useEffect } from 'react';
import { Notification } from '../components/atoms/notification';
import { useNotifications } from '../hooks/use-notifications';
import { useAppSelector } from '../redux/store/configureStore';

interface Props {
    children: React.ReactNode;
}

export const NotificationProvider: React.FC<Props> = ({ children }) => {
    const notificationRedux = useAppSelector((state) => state.ui.notification);
    const { notification, notify } = useNotifications();

    // Backward compatibility for old redux notification system
    useEffect(() => {
        if (notificationRedux?.message) {
            notify({
                message: notificationRedux.message,
                type: notificationRedux.severity,
            });
        }
    }, [notificationRedux]);

    return (
        <>
            {children}
            {notification && (
                <Notification
                    onClose={() => (notification?.action?.onClose ? notification.action.onClose() : notify(undefined))}
                    severity={notification?.type}
                    text={notification?.message}
                    subText={notification?.subMessage}
                    action={notification?.action}
                    horizontal="right"
                    vertical="top"
                    open={!!notification}
                    duration={notification?.duration}
                    clickAwayListenerProps={notification?.clickAwayListenerProps}
                />
            )}
        </>
    );
};
