import { IEvent, Topics } from '@zaplify/common';
import { IUserData } from '../users';

export class UserAccountCreatedEvent extends IEvent {
    public static override readonly TOPIC = Topics.USERS;

    constructor(readonly user: IUserData, readonly referredByLinkId?: string) {
        super();
    }

    get partitionKey(): string {
        if (!this.user.userOrganizationId) {
            return this.user.id;
        }

        return this.user.userOrganizationId;
    }
}
