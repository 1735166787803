import { ICommand } from '@zaplify/common';
import { Topics } from '@zaplify/common';

export class CreateReferralLinkCommand extends ICommand {
    public static override readonly TOPIC = Topics.USERS;

    constructor(readonly referrerId: string, readonly organizationId: string) {
        super();
    }
}
