export const alerts = {
    messageMissing: false,
    messageOverflow: false,
    subjectMissing: false,
    subjectOverflow: false,
    daysMissing: false,
    timeError: false,
};

export const draftPreState = {
    workflow: null,
    sourceId: '',
    name: '',
    step: 0,
};
