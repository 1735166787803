import { IconButton, MenuItem, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Modal from '@mui/material/Modal';
import Select from '@mui/material/Select';
import { useEffect, useState } from 'react';
import CloseIcon from '../icons/close';
import { useGroups } from '../../views/chats/hooks/use-groups';
import { SelectGroupModalStyled } from './selectGroupModal.styled';

type Props = {
    onModalClose: () => void;
    onSubmit: (groupId: string) => Promise<void>;
};

export function SelectGroupModal({ onModalClose, onSubmit }: Props) {
    const { groups } = useGroups();
    const [selectedGroupId, setSelectedGroupId] = useState(groups?.find((group) => group.default)?.id || '');
    const [isDisabled, setIsDisabled] = useState(true);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setIsDisabled(selectedGroupId === '');
    }, [selectedGroupId]);

    const handleClose = () => {
        onModalClose();
    };

    const handleChange = (event) => {
        setSelectedGroupId(event.target.value);
    };

    const handleSubmit = async (groupId) => {
        setLoading(true);
        await onSubmit(groupId);
        setLoading(false);
        handleClose();
    };

    useEffect(() => {
        setSelectedGroupId(groups?.find((group) => group.default)?.id || '');
    }, [groups]);

    return (
        <>
            <Modal open onClose={handleClose} onClick={(event) => event.stopPropagation()}>
                <SelectGroupModalStyled>
                    <div className="modal-container">
                        <div className="input-container">
                            <div>
                                <IconButton
                                    onClick={handleClose}
                                    disableRipple
                                    className="close-icon"
                                    data-Cy="close-icon"
                                    size="large"
                                >
                                    <CloseIcon />
                                </IconButton>
                                <Typography variant="h3" className="title margin-bottom">
                                    Select Playbook
                                </Typography>
                            </div>
                            <div>
                                <Typography className="typography title-small">Playbook</Typography>
                                <FormControl className="form-control" variant="filled">
                                    <Select
                                        value={selectedGroupId}
                                        onChange={handleChange}
                                        displayEmpty
                                        disableUnderline
                                        className={selectedGroupId === '' ? 'disabled' : 'active'}
                                        MenuProps={{ style: { maxWidth: '1px' } }} // kinda hacky, but it works for line MenuItem span
                                        data-cy="select-campaign-dropdown"
                                    >
                                        {groups &&
                                            groups.map(({ name, id }) => (
                                                <MenuItem key={id} value={id} data-id={id} id={id} className="option">
                                                    <span
                                                        style={{
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                        }}
                                                    >
                                                        {name}
                                                    </span>
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <Typography className="additional-info">
                                The new contacts will be assigned to the selected playbook.
                            </Typography>
                        </div>
                        <Divider className="divider" />
                        <div className="buttons-container">
                            <Button
                                className="button button-outlined-blue "
                                variant="outlined"
                                data-cy="close-button"
                                onClick={handleClose}
                            >
                                Close
                            </Button>
                            <Button
                                className={`${isDisabled ? 'disabled' : 'button-blue'} button`}
                                disabled={isDisabled}
                                variant="contained"
                                endIcon={loading && <CircularProgress color="inherit" size={20} />}
                                onClick={() => handleSubmit(selectedGroupId)}
                                data-cy="move-button"
                            >
                                Add
                            </Button>
                        </div>
                    </div>
                </SelectGroupModalStyled>
            </Modal>
        </>
    );
}
