import { styled } from '@mui/styles';

export const CampaignActivityStyled = styled('div')(({ theme }: any) => ({
    width: '100%',
    minHeight: '100%',
    background: '#F6F6F6',

    '& > div:first-child': {
        width: '90%',
        margin: 'auto',
        paddingTop: 20,
        boxSizing: 'border-box',

        '& > .data-header': {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            padding: '0 23px 0 16px',
            justifyContent: 'space-between',
            boxSizing: 'border-box',

            '& > p': {
                color: theme.palette,
                fontSize: 13,
                fontWeight: 500,
            },
        },
        '& > .outreach-information': {
            padding: 20,

            '& > p': {
                color: theme.palette.subline,
            },
            '& > *:last-child': {
                marginTop: 5,
            },
        },
    },
}));
