import { newCampaignActionTypes } from '../../actionTypes';
import { initialCampaign } from '../../../services/utils/constants';
import { alerts, draftPreState } from './constants';
import { CreateCampaignType } from '@zaplify/enums';

const initialState = {
    name: '',
    campaign: initialCampaign,
    personFilterId: null,
    prospectsInReview: [],
    prospectsInCampaign: [],
    step: 0,
    showLoader: false,
    alertMessage: alerts,
    redirect: '',
    isModalVisible: false,
    campaignId: '',
    draftPreState: draftPreState,
    successNotification: '',
    errorNotification: '',
    status: 'DRAFT',
    loading: false,
    saving: false,
    saveTime: null,
    createCampaignType: CreateCampaignType.MANUAL,
};

const newCampaignReducer = (state = initialState, action) => {
    switch (action.type) {
        case newCampaignActionTypes.UPDATE_VALUE:
            return {
                ...state,
                [action.key]: action.value,
            };
        case newCampaignActionTypes.NEXT_STEP:
            return {
                ...state,
                step: state.step + 1,
            };
        case newCampaignActionTypes.PREVIOUS_STEP:
            return {
                ...state,
                step: state.step - 1,
            };
        case newCampaignActionTypes.UPDATE_STEP:
            return {
                ...state,
                step: action.step,
            };
        case newCampaignActionTypes.UPDATE_PERSON_FILTER:
            return {
                ...state,
                personFilterId: action.value || action.personFilterId,
            };
        case newCampaignActionTypes.UPDATE_CAMPAIGN_NAME:
            return {
                ...state,
                name: action.name,
            };
        case newCampaignActionTypes.REDIRECT:
            return {
                ...state,
                redirect: action.url,
            };
        case newCampaignActionTypes.UPDATE_SAVED_TIME:
            return {
                ...state,
                saveTime: action.payload,
            };
        case newCampaignActionTypes.RESET_CAMPAIGN:
            return {
                ...initialState,
            };
        case newCampaignActionTypes.CHANGE_CREATE_CAMPAIGN_TYPE:
            return {
                ...state,
                createCampaignType: action.value,
            };
        default:
            return { ...state };
    }
};

export default newCampaignReducer;
