export namespace CampaignPausedReasons {
    export function isPausedByUserReason(reason: PausedReasonBase): reason is PausedByUserReasonDto {
        return reason.type === 'PausedByUserReason';
    }

    export function isPausedDueToBadWorkflowReason(
        reason: PausedReasonBase,
    ): reason is PausedDueToBadWorkflowReasonDto {
        return reason.type === 'PausedDueToBadWorkflowReason';
    }

    export abstract class PausedReasonBase<T = string> {
        abstract readonly reasonSummary: string;
        abstract readonly pausedByUser: boolean;
        abstract readonly type: T;
    }

    export class PausedByUserReasonDto extends PausedReasonBase<'PausedByUserReason'> {
        readonly type = 'PausedByUserReason';
        readonly reasonSummary = 'Paused by user';
        readonly pausedByUser = true;
    }

    export class PausedDueToBadWorkflowReasonDto extends PausedReasonBase<'PausedDueToBadWorkflowReason'> {
        readonly reasonSummary = 'Paused due to invalid workflow';
        readonly pausedByUser = false;
        readonly type = 'PausedDueToBadWorkflowReason';

        constructor(readonly issues: IWorkflowValidationErrorDescription[]) {
            super();
        }
    }

    export interface IWorkflowValidationErrorDescription {
        readonly issues: WorkflowValidationIssue[];
        readonly stepName: string;
        readonly stepId: string;
    }
}

export enum WorkflowValidationIssue {
    CHARACTER_LIMIT_EXCEEDED = 'CHARACTER_LIMIT_EXCEEDED',
    CONTENT_MISSING = 'CONTENT_MISSING',
    CONTENT_NOT_ALLOWED = 'CONTENT_NOT_ALLOWED',
}
