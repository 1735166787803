import { IEvent, Topics } from '@zaplify/common';

export class UserChangedOrganizationEvent extends IEvent {
    public static override readonly TOPIC = Topics.USERS;

    constructor(
        readonly userId: string,
        readonly oldOrganizationId: string,
        readonly newOrganizationId: string,
        readonly isCompensationEvent: boolean = false,
    ) {
        super();
    }

    get partitionKey(): string {
        return this.newOrganizationId;
    }
}
