import { WebhooksSdk } from './integrations/webhooks/webhooks.sdk';
import { HubspotSdk } from './integrations/hubspot/hubspot.sdk';
import { CampaignsSdk } from './campaigns/campaigns.sdk';
import { ProspectsSdk } from './prospects/prospects.sdk';
import { TargetSearchesSdk } from './prospects/targetSearches.sdk';
import { UserSdk } from './users/user.sdk';
import { UserAdminSdk } from './users/user-admin.sdk';
import { WorkflowSdk } from './campaigns/workflow.sdk';
import { AuthenticationSdk } from './users/authentication.sdk';
import { userOrganizationSdk } from './users/user-organizations.sdk';
import { channelAccountSdk } from './users/channelAccount.sdk';
import { SourcesSdk } from './prospects/sources.sdk';
import { RootSdk } from './root.sdk';
import { AppsSdk } from './integrations/apps.sdk';
import { PipedriveSdk } from './integrations/pipedrive/pipedrive.sdk';
import { UpsalesSdk } from './integrations/upsales/upsales.sdk';
import { SalesforceSdk } from './integrations/salesforce/salesforce.sdk';
import { BillingSdk } from './subscription/billing.sdk';
import { SubscriptionSdk } from './subscription/subscription.sdk';
import { SearchSdk } from './prospects/search.sdk';
import { OAuthSdk } from './users/oauth.sdk';
import { TasksSdk } from './campaigns/tasks.sdk';
import { ContactsSuggestionsSdk } from './contact-suggestions/contact-suggestions.sdk';
import { TrackersSdk } from './campaigns/trackers.sdk';
import { AssistantSdk } from './assistant/assistant.sdk';
import { OutreachSuggestionsSdk } from './outreach-suggestion/outreach-suggestion.sdk';
import { GroupsSdk } from './user-contacts/groups.sdk';
import { UserContactsSdk } from './user-contacts/user-contacts.sdk';
import { NotesSdk } from './notes/notes.sdk';
import { PageViewSdk } from './users/page-view.sdk';
import { MessengerSdk } from './campaigns/messenger.sdk';
import { DataSourcesSyncSdk } from './data-source-sync/data-source-sync.sdk';
import { LinkedinProfilesSdk } from './linkedin-profiles/linkedin-profiles.sdk';
import { LinkedinConnectionsSdk } from './linkedin-profiles/linkedin-connections.sdk';
import { LinkedinConversationsSdk } from './linkedin-profiles/linkedin-conversations.sdk';
import { FeedbackSdk } from './feedback/feedback.sdk';

export class ProfilesSdk {
    user: UserSdk;
    userAdmin: UserAdminSdk;
    campaigns: CampaignsSdk;
    workflow: WorkflowSdk;
    prospects: ProspectsSdk;
    targetSearches: TargetSearchesSdk;
    authentication: AuthenticationSdk;
    userOrganization: userOrganizationSdk;
    channelAccount: channelAccountSdk;
    sources: SourcesSdk;
    hubspot: HubspotSdk;
    webhooks: WebhooksSdk;
    pipedrive: PipedriveSdk;
    upsales: UpsalesSdk;
    oauth: OAuthSdk;
    salesforce: SalesforceSdk;
    apps: AppsSdk;
    billing: BillingSdk;
    root: RootSdk;
    subscription: SubscriptionSdk;
    search: SearchSdk;
    tasks: TasksSdk;
    contactSuggestions: ContactsSuggestionsSdk;
    trackers: TrackersSdk;
    assistant: AssistantSdk;
    outreachSuggestions: OutreachSuggestionsSdk;
    groups: GroupsSdk;
    notes: NotesSdk;
    userContacts: UserContactsSdk;
    pageView: PageViewSdk;
    messenger: MessengerSdk;
    dataSourcesSync: DataSourcesSyncSdk;
    linkedinProfiles: LinkedinProfilesSdk;
    linkedinConnections: LinkedinConnectionsSdk;
    linkedinConversations: LinkedinConversationsSdk;
    feedback: FeedbackSdk;

    constructor(apiEndpoint: string, token?: string) {
        this.user = new UserSdk(apiEndpoint, token);
        this.userAdmin = new UserAdminSdk(apiEndpoint, token);
        this.campaigns = new CampaignsSdk(apiEndpoint, token);
        this.workflow = new WorkflowSdk(apiEndpoint, token);
        this.prospects = new ProspectsSdk(apiEndpoint, token);
        this.targetSearches = new TargetSearchesSdk(apiEndpoint, token);
        this.authentication = new AuthenticationSdk(apiEndpoint, token);
        this.userOrganization = new userOrganizationSdk(apiEndpoint, token);
        this.channelAccount = new channelAccountSdk(apiEndpoint, token);
        this.sources = new SourcesSdk(apiEndpoint, token);
        this.hubspot = new HubspotSdk(apiEndpoint, token);
        this.webhooks = new WebhooksSdk(apiEndpoint, token);
        this.pipedrive = new PipedriveSdk(apiEndpoint, token);
        this.upsales = new UpsalesSdk(apiEndpoint, token);
        this.oauth = new OAuthSdk(apiEndpoint, token);
        this.salesforce = new SalesforceSdk(apiEndpoint, token);
        this.billing = new BillingSdk(apiEndpoint, token);
        this.root = new RootSdk(apiEndpoint, token);
        this.apps = new AppsSdk(apiEndpoint, token);
        this.subscription = new SubscriptionSdk(apiEndpoint, token);
        this.search = new SearchSdk(apiEndpoint, token);
        this.tasks = new TasksSdk(apiEndpoint, token);
        this.contactSuggestions = new ContactsSuggestionsSdk(apiEndpoint, token);
        this.trackers = new TrackersSdk(apiEndpoint, token);
        this.assistant = new AssistantSdk(apiEndpoint, token);
        this.outreachSuggestions = new OutreachSuggestionsSdk(apiEndpoint, token);
        this.groups = new GroupsSdk(apiEndpoint, token);
        this.userContacts = new UserContactsSdk(apiEndpoint, token);
        this.notes = new NotesSdk(apiEndpoint, token);
        this.pageView = new PageViewSdk(apiEndpoint, token);
        this.messenger = new MessengerSdk(apiEndpoint, token);
        this.dataSourcesSync = new DataSourcesSyncSdk(apiEndpoint, token);
        this.linkedinProfiles = new LinkedinProfilesSdk(apiEndpoint, token);
        this.linkedinConnections = new LinkedinConnectionsSdk(apiEndpoint, token);
        this.linkedinConversations = new LinkedinConversationsSdk(apiEndpoint, token);
        this.feedback = new FeedbackSdk(apiEndpoint, token);
    }
}
