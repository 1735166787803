import React from 'react';
import { AnimatePresence, motion } from 'motion/react';
import { cn } from '@shadcn/ui/lib/utils';
import { cva, VariantProps } from 'class-variance-authority';

const infoBoxVariants = cva(
    'flex w-full min-w-full gap-3 justify-between bg-background-brand-section-subtle rounded-t-xl px-3 pb-6 pt-3 border text-sm',
    {
        variants: {
            variant: {
                default: 'border-transparent bg-primary text-primary-foreground',
                secondary: 'border-transparent bg-secondary text-secondary-foreground',
                destructive: 'border-transparent bg-destructive text-white',
                outline: 'text-foreground',
                success:
                    'border-success-200 bg-success-50 color-success-700 text-text-primary dark:bg-success-50/20 dark:text-primary-foreground',
                warning: 'border-warning-300 bg-warning-50 text-warning-700 color-warning-700',
                blue: 'border-blue-utility-200 bg-blue-utility-50 text-blue-utility-700 color-blue-utility-700',
                gray: 'border-gray-utility-200 bg-gray-utility-50 text-gray-utility-700 color-gray-utility-700',
                brand: 'border-brand-300 bg-brand-100 text-brand-500 color-brand-500',
            },
        },
        defaultVariants: {
            variant: 'default',
        },
    }
);

export type InfoBoxVariant = VariantProps<typeof infoBoxVariants>['variant'];

interface InfoBoxContainerProps {
    className?: string;
    children: React.ReactNode;
}

export const InfoBoxContainer: React.FC<InfoBoxContainerProps & VariantProps<typeof infoBoxVariants>> = ({
    className,
    children,
    variant,
}) => {
    return (
        <AnimatePresence>
            <motion.div
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 30 }}
                transition={{ type: 'spring', stiffness: 100, damping: 20 }}
                className={cn(infoBoxVariants({ variant }), className)}
                style={{ translateY: '1rem' }}
            >
                {children}
            </motion.div>
        </AnimatePresence>
    );
};
