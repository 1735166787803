import { useQuery } from '@tanstack/react-query';

const fetchCountries = async () => {
    const response = await fetch('https://restcountries.com/v3.1/all');
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.json().then((data) =>
        data
            .map((country: any) => ({
                commonName: country.name.common,
                countryCode: country.cca2,
            }))
            .sort((a: any, b: any) => a.commonName.localeCompare(b.commonName))
    );
};

const useCountries = () => {
    const {
        data: countryList = [],
        status,
        isError,
        error,
    } = useQuery({
        queryKey: ['countries'],
        queryFn: fetchCountries,
        staleTime: 1000 * 60 * 60 * 24,
    });

    return { countries: countryList, status, isError, error };
};

export default useCountries;
