import { ButtonBase } from '@mui/material';
import { withStyles } from '@mui/styles';
import { palette } from '../../theme/palette';

const RoundIconButton = withStyles({
    root: {
        boxShadow: '0px 2px 6px rgba(112, 144, 176, 0.07)',
        border: `1px solid ${palette.colors.greyInformationLight}`,
        borderRadius: 50,
        padding: 8,
    },
})(ButtonBase);

export default RoundIconButton;
