import React, { useState } from 'react';
import { SplitButton } from '@zaplify/ui';
import { Check, ChevronDown, Plus } from 'lucide-react';
import { palette } from '../../theme/palette';
import { Button, CircularProgress, Menu, MenuItem, SxProps, darken } from '@mui/material';

interface AddPersonButtonProps {
    added: boolean;
    disabled?: boolean;
    isAddingLoader?: boolean;
    handleAddClick: (e: React.MouseEvent, groupId?: string) => void;
    groups?: { id: string; name: string }[];
    className?: string;
    variant?: 'primary' | 'secondary';
    sx?: SxProps;
    addedText?: string;
}

const AddPersonButton: React.FC<AddPersonButtonProps> = ({
    added,
    disabled,
    isAddingLoader,
    handleAddClick,
    groups,
    className,
    variant = 'primary',
    sx = {},
    addedText,
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isOpen = Boolean(anchorEl);

    const onClickRight = (event: React.MouseEvent<HTMLLIElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const onClose = () => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    const cropOptionName = (text: string, length: number) => {
        return text.length > length ? `${text.slice(0, length)}...` : text;
    };

    const onAdd = (e: React.MouseEvent, groupId: string) => {
        handleAddClick(e, groupId);
        setAnchorEl(null);
    };

    const startIcon = added ? (
        <Check strokeWidth={1} size={20} />
    ) : isAddingLoader ? (
        <CircularProgress size={20} style={{ color: 'var(--grey-palette-white, #FFF)' }} />
    ) : (
        <Plus strokeWidth={1} size={20} />
    );

    if ((!added && !groups) || groups.length < 2) {
        return (
            <Button
                disabled={added || disabled}
                className={className}
                sx={{
                    height: '32px',
                    borderRadius: '8px',
                    display: 'flex',
                    padding: '12px 16px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 0, // Removed the gap between startIcon and text
                    alignSelf: 'stretch',
                    ':hover': {
                        background: added || disabled ? palette.graphics.disabled : palette.primary.main,
                    },
                    background: added || disabled ? palette.graphics.disabled : palette.primary.light,
                    color: 'var(--grey-palette-white, #FFF)',
                    ...(variant === 'primary' && {
                        ':hover': {
                            background: added || disabled ? palette.graphics.disabled : palette.primary.main,
                        },
                        whiteSpace: 'nowrap',
                        color: 'var(--grey-palette-white, #FFF)',
                        background: added || disabled ? palette.graphics.disabled : palette.primary.light,
                        // border: '1px solid var(--grey-palette-grey-2, #E4E6EB)',
                    }),
                    ...(variant === 'secondary' && {
                        ':hover': {
                            background: added || disabled ? palette.graphics.disabled : palette.text.disabled,
                            border: '1px solid ' + darken(palette.background.grey, 0.1),
                        },
                        border: '1px solid ' + darken(palette.background.grey, 0.1),
                        whiteSpace: 'nowrap',
                        color: palette.text.primary,
                        background: added || disabled ? palette.graphics.disabled : 'var(--grey-palette-white, #FFF)',
                    }),
                    ...sx,
                }}
                startIcon={startIcon}
                onClick={handleAddClick}
            >
                {added ? addedText || 'Added' : isAddingLoader ? 'Adding' : 'Add'}
            </Button>
        );
    }

    return (
        <>
            <SplitButton
                disabled={added || disabled}
                className={className}
                sx={{
                    // marginLeft: 'auto',
                    minWidth: 120,
                    height: '32px',
                    display: 'flex',
                    borderRadius: '8px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignSelf: 'stretch',
                    ...(variant === 'primary' && {
                        '& > button': {
                            ':hover': {
                                background: added || disabled ? palette.graphics.disabled : palette.primary.main,
                            },
                            whiteSpace: 'nowrap',
                            color: 'var(--grey-palette-white, #FFF)',
                            background: added || disabled ? palette.graphics.disabled : palette.primary.light,
                            display: 'flex',
                            justifyContent: 'center',
                            // border: '1px solid var(--grey-palette-grey-2, #E4E6EB)',
                        },
                    }),
                    ...(variant === 'secondary' && {
                        '& > button': {
                            ':hover': {
                                background: added || disabled ? palette.graphics.disabled : palette.text.disabled,
                                border: '1px solid ' + darken(palette.background.grey, 0.1),
                            },
                            border: '1px solid ' + darken(palette.background.grey, 0.1),
                            whiteSpace: 'nowrap',
                            color: palette.text.primary,
                            background:
                                added || disabled ? palette.graphics.disabled : 'var(--grey-palette-white, #FFF)',
                            display: 'flex',
                            justifyContent: 'center',
                        },
                    }),
                    ...sx,
                    ...(isOpen && { visibility: 'visible' }),
                }}
                leftProps={{
                    startIcon: startIcon,
                }}
                leftClick={handleAddClick}
                leftContent={added ? addedText || 'Added' : isAddingLoader ? 'Adding' : 'Add'}
                rightContent={!added && <ChevronDown size={24.5} strokeWidth={1} />}
                rightClick={!added && onClickRight}
            />
            <Menu
                anchorEl={anchorEl}
                open={isOpen}
                onClose={onClose}
                onClick={(e) => e.stopPropagation()}
                sx={{
                    zIndex: 6000,
                    maxHeight: 'calc(48px * 7)', // Assuming each item is 48px high
                    '& .MuiPaper-root': {
                        // ...(!suggestionId && { minWidth: '300px' }),
                    },
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                className="dropdown"
            >
                {groups?.map((option) => (
                    <MenuItem key={option.id} onClick={(e) => onAdd(e, option.id)}>
                        {cropOptionName(option.name, 30)}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

export default AddPersonButton;
