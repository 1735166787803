import { IEvent, Topics } from '@zaplify/common';

export class ProspectReviewRejectedEvent extends IEvent {
    public static override readonly TOPIC = Topics.PROSPECTS;
    constructor(
        readonly userId: string,
        readonly organizationId: string,
        readonly campaignId: string,
        readonly prospectId: string,
    ) {
        super();
    }
    get partitionKey(): string {
        return this.organizationId;
    }
}
