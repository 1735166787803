import ArrowBack from '@mui/icons-material/ArrowBack';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import { IconButton, Typography } from '@mui/material';
import { GuardedLink } from '@zaplify/ui';
import { useDispatch } from 'react-redux-latest';
import { Button } from '../../../../../components/atoms/button';
import { ReactComponent as HubspotIcon } from '../../../../../components/icons/hubspot-icon.svg';
import { updateHubspotSettings } from '../../../../../redux/ducks/hubspot/operations';
import { AppDispatch, useAppSelector } from '../../../../../redux/store/configureStore';
import { TopContainer } from './containers';

export const TopBar = () => {
    const dispatch = useDispatch<AppDispatch>();
    const hubspotAccountId = useAppSelector((state) => state.hubspot.account.portalId);
    const saveStatus = useAppSelector((state) => state.hubspot.saveStatus);

    return (
        <TopContainer>
            <GuardedLink to="/integrations">
                <IconButton data-cy="go-back-button" size="large">
                    <ArrowBack />
                </IconButton>
            </GuardedLink>
            <div>
                <HubspotIcon />
                <div>
                    <Typography variant="h3">HubSpot</Typography>
                    <Typography>CRM Software</Typography>
                </div>
                <div>
                    <Typography>HubSpot Hub: {hubspotAccountId}</Typography>
                </div>
            </div>
            <Button
                dataCy={'save-hubspot-settings'}
                disabled={!saveStatus}
                borderRadius="300px"
                height="45px"
                text="Save"
                onClick={() => dispatch(updateHubspotSettings())}
                startIcon={<ArrowUpward />}
            />
        </TopContainer>
    );
};
