import { Typography } from '@mui/material';
import { styled } from '@mui/styles';
import { Loader } from '@zaplify/frontend-common';
import { useEffect, useState } from 'react';

const PaymentProcessingStyled = styled('div')(({ theme: { palette } }: any) => ({
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',

    '& .header-container': {
        display: 'flex',
        alignItems: 'center',
        gap: 10,
        marginBottom: 15,
        '& h3': {
            whiteSpace: 'nowrap',
        },
        '& .MuiCircularProgress-root': {
            width: 20,
            height: 20,
        },
    },
    '& .info': {
        fontSize: 17,
        color: palette.subline,
    },

    '& .refresh-info': {
        fontSize: 12,
        marginTop: 15,
        '& .refresh-text': {
            cursor: 'pointer',
            color: '-webkit-link',
        },
    },
}));

const PaymentProcessing = () => {
    const [displayTimeoutBox, setDisplayTimeoutBox] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setDisplayTimeoutBox(true);
        }, 20000);

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    const handleRefresh = () => window.location.reload();

    return (
        <PaymentProcessingStyled>
            <div className="header-container">
                <Typography variant="h3">Processing order</Typography>
                <Loader width={18} height={18} size={25} />
            </div>
            <Typography className="info">Your order’s being processed…</Typography>
            {displayTimeoutBox && (
                <div>
                    <Typography className="refresh-info">
                        Taking longer than expected? Try refresh the page by{' '}
                        <span onClick={handleRefresh} className="refresh-text">
                            clicking here.
                        </span>
                    </Typography>
                </div>
            )}
        </PaymentProcessingStyled>
    );
};

export default PaymentProcessing;
