import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux-latest';
import { startLoading, stopLoading } from '../../../redux/actions';
import { AppDispatch } from '../../../redux/store/configureStore';
import { getAuthenticationToken } from '@zaplify/utils';
import { applyActionCode, getAuth } from 'firebase/auth';

const useVerifyAccount = ({ onAuthError }: { onAuthError: (error: any) => void }) => {
    const dispatch = useDispatch<AppDispatch>();

    const { mutate: handleVerifyAccount, status: verifyStatus } = useMutation({
        mutationFn: async (actionCode: string) => {
            try {
                dispatch(startLoading());
                await applyActionCode(getAuth(), actionCode);
                // Reload user needed to see the new emailVerified=true state on the token and user
                await getAuth().currentUser.reload();
                // Refresh token to trigger the page redirect to next route (configureProfile) through the authorization-provider.
                // Note that what's extra powerful with this is that the refreshed token triggers a page refresh across ALL tabs (often users have a second tab with the email verification view, so it's good if we get redirected from there)
                await getAuthenticationToken(true);

                return {
                    isVerified: true,
                    message: 'Your email has been verified',
                };
            } catch (error) {
                console.error('verify email error', error);
                return {
                    isVerified: false,
                    message: 'Your request to verify your email has expired or the link has already been used',
                };
            } finally {
                dispatch(stopLoading());
            }
        },
        onSuccess: (data) => {
            console.log(`🚀 [ConfirmEmail] verifyAccount > onSuccess: ${JSON.stringify(data)}`);
        },
        onError: (error: any) => {
            console.error(`🔥 [ConfirmEmail] verifyAccount > onError: ${JSON.stringify(error)}`);
            onAuthError('Your request to verify your email has expired or the link has already been used');
        },
    });

    return { handleVerifyAccount, verifyStatus };
};

export default useVerifyAccount;
