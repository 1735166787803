import { getZaplifySdk } from '@zaplify/sdk';
import { OAuthCodeDto } from '@zaplify/users/shared';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux-latest';
import { setNotification } from '../../redux/actions';
import { updateHubspotFirstTimeState } from '../../redux/ducks/hubspot/slice';
import { updatePipedriveFirstTimeState } from '../../redux/ducks/pipedrive/slice';
import { updateLoadingStatus, updateSalesforceFirstTimeState } from '../../redux/ducks/salesforce/slice';
import { AppDispatch, useAppSelector } from '../../redux/store/configureStore';
import { paths } from '../../routes/paths';
import { clearLocalStorageByKey, getLocalStorageByKey } from '../../services/utils/localStorage';
import { salesforceLocalstorageKey } from './apps/salesforce/constants';

export const useAppOAuth = (appRoute, navigate) => {
    const dispatch = useDispatch<AppDispatch>();
    const urlParams = new URLSearchParams(window.location.search);
    const integration = urlParams.get('integration');
    const code = urlParams.get('code');
    const clientId = urlParams.get('client_id');

    const auth = useAppSelector((state) => state.user);

    const connectHubspot = async () => {
        const profilesSdk = getZaplifySdk().profiles;
        try {
            if (!code) return;
            await profilesSdk.hubspot.connect(auth.zaplifyUser.userOrganization.id, code);
            navigate(paths.INTEGRATIONS + '/' + integration);
            dispatch(updateHubspotFirstTimeState(true));
        } catch (error) {
            console.log('error connecting hubspot: ');
        }
    };

    const connectPipedrive = async () => {
        const profilesSdk = getZaplifySdk().profiles;
        try {
            if (!code) return;
            await profilesSdk.pipedrive.connect(auth.zaplifyUser.userOrganization.id, code);
            navigate(paths.INTEGRATIONS + '/' + integration);
            dispatch(updatePipedriveFirstTimeState(true));
        } catch (error) {
            console.log('error connecting pipedrive: ');
        }
    };

    const connectSalesforce = async () => {
        const profilesSdk = getZaplifySdk().profiles;
        try {
            if (!code) return;
            dispatch(updateLoadingStatus(true));
            await profilesSdk.salesforce.connect(
                auth.zaplifyUser.userOrganization.id,
                code,
                getLocalStorageByKey(salesforceLocalstorageKey)
            );
            clearLocalStorageByKey(salesforceLocalstorageKey);
            navigate(paths.INTEGRATIONS + '/' + integration);
            dispatch(updateSalesforceFirstTimeState(true));
        } catch (error) {
            console.log('error connecting Salesforce: ');
            dispatch(
                setNotification(
                    'It looks like you are using Salesforce Essentials. To use integrations with Salesforce, Salesforce require you to be on Professional or higher. If you think this is a mistake please contact the support.',
                    'error'
                )
            );
        } finally {
            dispatch(updateLoadingStatus(false));
        }
    };

    const connectUpsales = async () => {
        const profilesSdk = getZaplifySdk().profiles;
        try {
            if (!clientId) return;
            const authorize = (await profilesSdk.oauth.authorize(clientId)) as OAuthCodeDto;
            window.location.replace(
                `https://power.upsales.com/#/oauthLanding/${import.meta.env.VITE_UPSALES_APP_ID}/client?code=${
                    authorize.code
                }`
            );
        } catch (error) {
            console.log('error connecting upsales: ');
        }
    };

    useEffect(() => {
        if (appRoute !== 'oauth-callback') return;
        if (code && integration === 'pipedrive') connectPipedrive();
        if (code && integration === 'hubspot') connectHubspot();
        if (code && integration === 'salesforce') connectSalesforce();
        if (clientId && integration === 'upsales') connectUpsales();
        // eslint-disable-next-line
    }, []);
};
