import { ChannelType } from '@zaplify/channel-accounts/shared';
import { MessageType } from '../enums/message-type.enum';
import { IEvent, Topics } from '@zaplify/common';

export class FirstMessageSentEvent extends IEvent {
    public static override readonly TOPIC = Topics.CAMPAIGNS;
    readonly organizationId: string;
    readonly userId: string;
    readonly prospectId: string;
    readonly messageId: string;
    readonly sentOn: Date;
    readonly channelType: ChannelType;
    readonly messagetype: MessageType;
    readonly content?: string;
    readonly metadata?: {
        completedTodo: boolean;
        editedSuggestion?: boolean;
        assistantId?: string;
    };

    constructor(dto: {
        organizationId: string;
        userId: string;
        prospectId: string;
        messageId: string;
        sentOn: Date;
        channelType: ChannelType;
        messagetype: MessageType;
        content?: string;
        metadata?: {
            completedTodo: boolean;
            editedSuggestion?: boolean;
            assistantId?: string;
        };
    }) {
        super();
        this.organizationId = dto.organizationId;
        this.userId = dto.userId;
        this.prospectId = dto.prospectId;
        this.messageId = dto.messageId;
        this.sentOn = dto.sentOn;
        this.channelType = dto.channelType;
        this.messagetype = dto.messagetype;
        this.content = dto.content;
        this.metadata = dto.metadata;
    }

    get partitionKey(): string {
        return this.organizationId;
    }
}
