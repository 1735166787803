import { Box, Typography } from '@mui/material';
import { AndSendLoader } from '@zaplify/ui';
import { useState, useEffect } from 'react';
import { useSetupUserProfile } from './hooks/use-setup-user-profile';
import { paths } from '../../../routes/paths';
import { LocalStorageKeys } from '../../../config';
import { useNavigate } from 'react-router-dom';
import { setLocalStorage } from '../../../services/utils/localStorage';
import { ProspectDataWithConnectionStatus, getZaplifySdk } from '@zaplify/sdk';
import { ConversationMessageParsed } from '@zaplify/web-extension-shared';
import { parseLinkedinConversationToUserActivity } from '../../../helpers/trackers';
import { ContactSource } from '@zaplify/services/user-contacts/shared';
import { useProspectSync } from '../../../hooks/use-prospect-sync';
import { motion } from 'motion/react';
import { useGroups } from '../../chats/hooks/use-groups';
import { ProgressChecklist } from './progress-checklist';
import { channelAccountsAtom } from '../../../atoms/chats';
import { useAtom } from 'jotai';
import { useAuth } from '../../../new/providers/authentication-provider';

interface CompletingOnboardingLoadingScreenProps {
    firstPlaybook: {
        name: string;
        tone: string;
        purpose: string;
        context: string;
        language: string;
        targetGroup?: string;
    };
    userOrganizationName: string;
    userSelectedWebsite: string;
    domain: string;
    setupUserPromise: Promise<{
        playbookId: string;
        assistantSettingsId: string;
        lastConversations: { profile: ProspectDataWithConnectionStatus; conversation: ConversationMessageParsed[] }[];
    }>;
}

export type StepProgress = 'inProgress' | 'completed' | 'notStarted';

export const CompletingOnboardingLoadingScreen = ({
    firstPlaybook,
    userOrganizationName,
    userSelectedWebsite,
    domain: emailDomain,
    setupUserPromise,
}: CompletingOnboardingLoadingScreenProps) => {
    const [steps, setSteps] = useState({
        fetchLinkedInConversations: {
            status: 'inProgress' as StepProgress,
            infoText: 'Fetching your recent LinkedIn conversations',
        },
        playbookSetup: {
            status: 'notStarted' as StepProgress,
            infoText: `Setting up your first playbook: ${firstPlaybook.name}`,
        },
        analyzeWebsite: {
            status: 'notStarted' as StepProgress,
            infoText: `Analyzing ${userSelectedWebsite}`,
        },
        analyzeConversations: {
            status: 'notStarted' as StepProgress,
            infoText: `Picking the most relevant conversations for your purpose`,
        },
        generateContacts: {
            status: 'notStarted' as StepProgress,
            infoText: `Generating feed of new people you can reach out to`,
        },
        researching: {
            status: 'notStarted' as StepProgress,
            infoText: 'Researching contacts and generating messages',
        },
    });

    const navigate = useNavigate();
    const { refreshOnboardingState } = useAuth();
    const { syncMany } = useProspectSync();
    const { handleSaveSettings, updateFirstPlaybookSettings, getCompanyProfile } = useSetupUserProfile();
    const { renameGroup } = useGroups();
    const [isFadingOut, setIsFadingOut] = useState<boolean>(false);
    const [channelAccounts] = useAtom(channelAccountsAtom);

    useEffect(() => {
        setupUserPromise.then(() => {
            setSteps((prevSteps) => ({
                ...prevSteps,
                fetchLinkedInConversations: { ...prevSteps.fetchLinkedInConversations, status: 'completed' },
            }));
        });
    }, []);

    const [initiateCompletedOnboardingAnimation, setInitiateCompletedOnboardingAnimation] = useState<boolean>(false);
    useEffect(() => {
        if (!!initiateCompletedOnboardingAnimation) {
            setTimeout(() => setIsFadingOut(true), 3500);
        }
    }, [initiateCompletedOnboardingAnimation]);

    const completeUserOnboarding = async () => {
        const DEFAULT_GOAL = 'Start and continue ongoing conversations';
        const { assistantSettingsId, lastConversations, playbookId } = await setupUserPromise;
        try {
            const purpose = firstPlaybook.purpose || DEFAULT_GOAL;
            await renameGroup(playbookId, firstPlaybook.name);
            setSteps((prevSteps) => ({
                ...prevSteps,
                analyzeWebsite: { ...prevSteps.analyzeWebsite, status: 'inProgress' },
                playbookSetup: { ...prevSteps.playbookSetup, status: 'inProgress' },
            }));

            let playbookContext;
            try {
                const companyProfile = await getCompanyProfile(userSelectedWebsite);
                playbookContext = `
                My approach:\n${firstPlaybook.context}\n\n${companyProfile}`.trim();
            } catch (error) {
                console.error('Error getting company profile', error);
                playbookContext = `
                My approach:\n${firstPlaybook.context}
                `.trim();
            }

            await Promise.all([
                handleSaveSettings(userOrganizationName, emailDomain, userSelectedWebsite).then(() =>
                    setSteps((prevSteps) => ({
                        ...prevSteps,
                        analyzeWebsite: { ...prevSteps.analyzeWebsite, status: 'completed' },
                    }))
                ),
                updateFirstPlaybookSettings({
                    purpose: purpose,
                    context: playbookContext,
                    tone: firstPlaybook.tone,
                    language: firstPlaybook.language,
                    assistantSettingId: assistantSettingsId,
                    targetGroup: firstPlaybook.targetGroup,
                }).then(() =>
                    setSteps((prevSteps) => ({
                        ...prevSteps,
                        playbookSetup: { ...prevSteps.playbookSetup, status: 'completed' },
                    }))
                ),
            ]);

            console.log('onboarding: handled save settings');
            setLocalStorage(LocalStorageKeys.AI_PROFILE_ONBOARDING, JSON.stringify(firstPlaybook));

            setSteps((prevSteps) => ({
                ...prevSteps,
                analyzeConversations: { ...prevSteps.analyzeConversations, status: 'inProgress' },
            }));
            const createdProspects = await getZaplifySdk().profiles.messenger.importLinkedinAnalysis({
                profiles: lastConversations.map((c) => ({
                    profile: c.profile,
                    activities: parseLinkedinConversationToUserActivity(
                        c.conversation,
                        channelAccounts.linkedIn.value,
                        c.profile.connectionStatus
                    ),
                })),
                assistantId: assistantSettingsId,
            });
            if (createdProspects.length < 3) {
                const notCreatedProspects = lastConversations
                    .filter(
                        (c) =>
                            c.profile.linkedinUserId &&
                            !createdProspects.find((p) => p.data.linkedinUserId === c.profile.linkedinUserId)
                    )
                    .map((c) => c.profile);

                await getZaplifySdk().profiles.sources.runBulkImport(
                    notCreatedProspects.slice(0, 3 - createdProspects.length),
                    ContactSource.Onboarding
                );
            }
            setSteps((prevSteps) => ({
                ...prevSteps,
                analyzeConversations: { ...prevSteps.analyzeConversations, status: 'completed' },
            }));

            syncMany(createdProspects.map((p) => ({ prospectId: p.id })));

            await simulateFinalProcessing();
            await refreshOnboardingState();
            console.log('onboarding: completed!');
            setTimeout(() => setInitiateCompletedOnboardingAnimation(true), 2000);
        } catch (error) {
            console.log('Error setting up AI profile settings', error);
            window.location.reload();
        } finally {
            if (localStorage.getItem(LocalStorageKeys.TURN_ON_MESSENGER)) {
                localStorage.removeItem(LocalStorageKeys.TURN_ON_MESSENGER);
            }
        }
    };

    const simulateFinalProcessing = async () => {
        setSteps((prevSteps) => ({
            ...prevSteps,
            generateContacts: { ...prevSteps.generateContacts, status: 'inProgress' },
        }));
        await new Promise((resolve) => setTimeout(resolve, 1800));
        setSteps((prevSteps) => ({
            ...prevSteps,
            generateContacts: { ...prevSteps.generateContacts, status: 'completed' },
        }));

        setSteps((prevSteps) => ({
            ...prevSteps,
            researching: { ...prevSteps.researching, status: 'inProgress' },
        }));

        await new Promise((resolve) => setTimeout(resolve, 10_000));
        setSteps((prevSteps) => ({
            ...prevSteps,
            researching: { ...prevSteps.researching, status: 'completed' },
        }));
    };

    useEffect(() => {
        completeUserOnboarding();
    }, []);

    return (
        <motion.div
            initial={{ opacity: 1 }}
            animate={{ opacity: isFadingOut ? 0 : 1 }}
            transition={{ duration: 1.5 }}
            onAnimationComplete={() => {
                if (isFadingOut) {
                    navigate(paths.HOME);
                }
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    paddingTop: '10vh',
                }}
            >
                <Box
                    sx={{
                        width: '600px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '8px',
                    }}
                >
                    <Box sx={{ height: '180px', marginBottom: '16px' }}>
                        {initiateCompletedOnboardingAnimation && (
                            <AndSendLoader variation="color" height="180px" start={50} loop={false} />
                        )}
                    </Box>
                    <Typography
                        sx={{
                            fontSize: '48px',
                            lineHeight: '56px',
                            fontWeight: 500,
                            textWrap: 'balance',
                            textAlign: 'center',
                        }}
                    >
                        Building your experience
                    </Typography>

                    <Box sx={{ padding: 3, width: '100%' }}>
                        <ProgressChecklist steps={steps} />
                    </Box>
                </Box>
            </Box>
        </motion.div>
    );
};
