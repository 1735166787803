import React, { FC, useCallback, useState, useRef, useEffect } from 'react';
import { Input } from '@shadcn/ui/components/ui/input';
import { cn } from '@shadcn/ui/lib/utils';
import { Sparkles } from 'lucide-react';
import { Button } from '@shadcn/ui/components/ui/button';
import { Badge } from '@shadcn/ui/components/ui/badge';

export interface PurposeStepContentProps {
    purpose: string;
    targetGroup: string;
    name: string;
    setValue: (field: 'purpose' | 'targetGroup' | 'name', value: string) => void;
    targetGroupAndPurposeData?: {
        purposeSuggestions?: string[];
        targetGroupSuggestions?: string[];
    };
}

export const PurposeStepContent: FC<PurposeStepContentProps> = ({
    purpose,
    targetGroup,
    name,
    setValue,
    targetGroupAndPurposeData,
}) => {
    const handleNameChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => setValue('name', e.target.value),
        [setValue]
    );

    const handlePurposeChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => setValue('purpose', e.target.value),
        [setValue]
    );

    const handleTargetGroupChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => setValue('targetGroup', e.target.value),
        [setValue]
    );

    const handleSuggestionClick = useCallback(
        (field: 'purpose' | 'targetGroup', value: string) => setValue(field, value),
        [setValue]
    );

    return (
        <div className="space-y-6">
            <div>
                <h2 className="text-lg font-semibold mb-2">Playbook name</h2>
                <Input
                    placeholder="New playbook"
                    value={name}
                    onChange={handleNameChange}
                    className="h-12 text-base border-slate-200 shadow-sm transition-all focus-visible:ring-offset-0 focus-visible:ring-1"
                />
            </div>

            <InputWithSuggestions
                label="Purpose"
                placeholder="What's the main goal of this playbook?"
                value={purpose}
                onChange={handlePurposeChange}
                description="Your purpose helps Andsend steer your conversations in the right direction."
                suggestions={targetGroupAndPurposeData?.purposeSuggestions}
                onSuggestionClick={(suggestion) => handleSuggestionClick('purpose', suggestion)}
            />

            <InputWithSuggestions
                label="Your target group"
                placeholder="Who are you trying to reach?"
                value={targetGroup}
                onChange={handleTargetGroupChange}
                description="Specifying your target group helps identify the right people to connect with."
                suggestions={targetGroupAndPurposeData?.targetGroupSuggestions}
                onSuggestionClick={(suggestion) => handleSuggestionClick('targetGroup', suggestion)}
            />
        </div>
    );
};

interface InputWithSuggestionsProps {
    label: string;
    placeholder: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    description: string;
    suggestions?: string[];
    onSuggestionClick?: (suggestion: string) => void;
}

const InputWithSuggestions: FC<InputWithSuggestionsProps> = ({
    label,
    placeholder,
    value,
    onChange,
    description,
    suggestions,
    onSuggestionClick,
}) => {
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [shouldShowButton, setShouldShowButton] = useState(true);
    const formRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const textWidthRef = useRef<HTMLSpanElement>(null);

    // Show suggestions only when there are suggestions
    const hasSuggestions = suggestions?.length !== 0;

    // Check if text is too long for button
    useEffect(() => {
        const checkTextLength = () => {
            if (inputRef.current && value) {
                const inputWidth = inputRef.current.offsetWidth;
                // Create a hidden span to measure text width
                if (!textWidthRef.current) {
                    const span = document.createElement('span');
                    span.style.visibility = 'hidden';
                    span.style.position = 'absolute';
                    span.style.whiteSpace = 'nowrap';
                    span.style.fontSize = getComputedStyle(inputRef.current).fontSize;
                    span.style.fontFamily = getComputedStyle(inputRef.current).fontFamily;
                    document.body.appendChild(span);
                    textWidthRef.current = span;
                }

                textWidthRef.current.textContent = value;
                const textWidth = textWidthRef.current.offsetWidth;

                // Consider button width + padding (120px is approximate button width + some margin)
                const thresholdWidth = inputWidth - 120;

                setShouldShowButton(textWidth < thresholdWidth);
            } else {
                setShouldShowButton(true);
            }
        };

        checkTextLength();

        return () => {
            if (textWidthRef.current && textWidthRef.current.parentNode) {
                textWidthRef.current.parentNode.removeChild(textWidthRef.current);
            }
        };
    }, [value]);

    // Handle click outside to close dropdown
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (formRef.current && !formRef.current.contains(event.target as Node)) {
                setShowSuggestions(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleSuggestionSelect = useCallback(
        (suggestion: string) => {
            if (onSuggestionClick) {
                onSuggestionClick(suggestion);
                setShowSuggestions(false);
            }
        },
        [onSuggestionClick]
    );

    // Toggle suggestions visibility
    const toggleSuggestions = useCallback(() => {
        setShowSuggestions((prev) => !prev);
    }, []);

    return (
        <div className="space-y-2" ref={formRef}>
            <h2 className="text-lg font-semibold mb-2">{label}</h2>
            <div className="relative">
                <div className="relative">
                    <Input
                        ref={inputRef}
                        value={value}
                        onChange={onChange}
                        onFocus={() => setShowSuggestions(true)}
                        placeholder={placeholder}
                        className={cn(
                            'h-12 text-base border-slate-200 shadow-sm transition-all focus-visible:ring-offset-0 focus-visible:ring-1',
                            shouldShowButton ? 'pr-28' : 'pr-3'
                        )}
                    />
                    {hasSuggestions && shouldShowButton && (
                        <Button
                            size="sm"
                            variant="ghost"
                            className={cn(
                                'absolute right-2 top-1/2 -translate-y-1/2 text-text-primary hover:text-text-brand-tertiary hover:bg-transparent',
                                showSuggestions && 'text-text-brand-tertiary'
                            )}
                            onClick={toggleSuggestions}
                        >
                            <Sparkles className="h-4 w-4 mr-1" />
                            <span className="text-sm">Suggested</span>
                        </Button>
                    )}
                </div>

                {showSuggestions && hasSuggestions && (
                    <div className="absolute z-10 w-full mt-1 bg-white rounded-md shadow-md border border-slate-100 p-3 transition-all">
                        <div className="flex items-center text-xs text-muted-foreground mb-2">
                            <Sparkles className="h-3 w-3 mr-1" /> Suggestions
                        </div>
                        <div className="flex flex-wrap gap-2">
                            {suggestions?.map((suggestion) => (
                                <Badge
                                    key={suggestion}
                                    variant={value === suggestion ? 'brand' : 'outline'}
                                    className="cursor-pointer p-1.5 rounded-md hover:bg-slate-100 border-slate-200"
                                    onClick={() => handleSuggestionSelect(suggestion)}
                                >
                                    {suggestion}
                                </Badge>
                            ))}
                        </div>
                    </div>
                )}
            </div>

            <p className="text-sm text-muted-foreground ml-1 mt-1">{description}</p>
        </div>
    );
};
