const GmailScope = {
    GMAIL_SEND: 'https://www.googleapis.com/auth/gmail.send',
    GMAIL_METADATA: 'https://www.googleapis.com/auth/gmail.metadata',
    GMAIL_READ: 'https://www.googleapis.com/auth/gmail.readonly',
    USERINFO_EMAIL: 'https://www.googleapis.com/auth/userinfo.email',
    USERINFO_PROFILE: 'https://www.googleapis.com/auth/userinfo.profile',
};

const OutlookScope = {
    OPENID: 'openid',
    PROFILE: 'profile',
    EMAIL: 'email',
    USER_READ: 'User.Read',
    MAIL_SEND: 'Mail.Send',
    MAIL_READWRITE: 'Mail.ReadWrite',
    OFFLINE_ACCESS: 'offline_access',
};

abstract class EmailScopes {
    protected static all: string[];
    protected static required: string[];

    static get optional() {
        return this.all.filter((scope) => !this.required.includes(scope));
    }
}

export class GmailScopes extends EmailScopes {
    static override all = [
        GmailScope.USERINFO_EMAIL,
        GmailScope.USERINFO_PROFILE,
        // GmailScope.GMAIL_METADATA,
        GmailScope.GMAIL_SEND,
        GmailScope.GMAIL_READ,
    ];
    static override required = [
        GmailScope.USERINFO_EMAIL,
        GmailScope.USERINFO_PROFILE,
        // GmailScope.GMAIL_METADATA,
        GmailScope.GMAIL_READ,
        GmailScope.GMAIL_SEND,
    ];
}

export class OutlookScopes extends EmailScopes {
    static override all = [
        OutlookScope.OFFLINE_ACCESS,
        OutlookScope.OPENID,
        OutlookScope.PROFILE,
        OutlookScope.EMAIL,
        OutlookScope.USER_READ,
        OutlookScope.MAIL_SEND,
        OutlookScope.MAIL_READWRITE,
    ];
    static override required = [
        // OutlookScope.OFFLINE_ACCESS, it's required, but for some reason this isn't returned from the accessToken, so we've removed it for now
        OutlookScope.OPENID,
        OutlookScope.PROFILE,
        OutlookScope.EMAIL,
        OutlookScope.USER_READ,
        OutlookScope.MAIL_SEND,
        OutlookScope.MAIL_READWRITE,
    ];
}
