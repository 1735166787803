export interface InvitationSendResponse {
    prospect: InvitationSendProspect;
    invitationId: string;
    unrestrictType: string;
    sentAt: number;
}

export interface InvitationSendProspect {
    firstName: string;
    lastName: string;
    headline: string;
    publicIdentifier: string;
    memberId: string;
    profilePicture: string;
    influencer: boolean;
    premium: boolean;
    profileUrl: string;
    status: string;
}

export enum InvitationSendErrorReason {
    MAX_CONNECTIONS_WITH_MESSAGE_SENT = 'MAX_CONNECTIONS_WITH_MESSAGE_SENT',
    REINVITE_THREE_WEEKS_DELAY = 'REINVITE_THREE_WEEKS_DELAY',
    CUSTOM_MESSAGE_TOO_LONG = 'CUSTOM_MESSAGE_TOO_LONG',
    PROFILE_CANT_BE_ACCESSED = 'PROFILE_CANT_BE_ACCESSED',
    ACCOUNT_BLOCKED = 'ACCOUNT_BLOCKED',
    INVALID_INVITATION_STATE = 'INVALID_INVITATION_STATE',
    NO_EXTENSION_RESPONSE = 'NO_EXTENSION_RESPONSE',
    DESKTOP_CONNECT_RESTRICTED = 'DESKTOP_CONNECT_RESTRICTED',
}

export interface InvitationSendError {
    error: string;
    reason: InvitationSendErrorReason;
}
