import { ICommand } from '@zaplify/common';
import { Topics } from '@zaplify/common';
import { AccountLocationDto, UpdateUserSettingsDto } from '../users';

export class UpdateUserSettingsCommand extends ICommand<void> {
    public static override readonly TOPIC = Topics.USERS;
    readonly firstName?: string;
    readonly lastName?: string;
    readonly location?: AccountLocationDto;
    readonly email?: string;
    readonly handle?: string;

    constructor(readonly userId: string, dto: UpdateUserSettingsDto) {
        super();
        this.firstName = dto.firstName;
        this.lastName = dto.lastName;
        this.location = dto.location;
        this.email = dto.email;
        this.handle = dto.handle;
    }
}
