import { ButtonBaseProps } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { withStyles } from '@mui/styles';
import { palette } from '../../theme/palette';
import RoundIconButton from './RoundIconButton';

const ArrowBackStyled = withStyles({
    root: {
        fontSize: 29,
        color: palette.colors.greyDark,
    },
})((props) => <ArrowBack {...props} />);

const GoBackIconButton = (props: ButtonBaseProps) => {
    return (
        <RoundIconButton {...props}>
            <ArrowBackStyled />
        </RoundIconButton>
    );
};

export default GoBackIconButton;
