import { CampaignStatus } from '@zaplify/campaigns';
import { getZaplifySdk } from '@zaplify/sdk';
import { batch } from 'react-redux-latest';
import { LocalStorageKeys } from '../../config';
import { paths } from '../../routes/paths';
import { getManualTasks } from '../../services/utils/tasks';
import { campaignActionTypes } from '../actionTypes';
import { unselectProspect } from '../ducks/prospect-search-result/slice';
import { fetchProspectsToManage, getProspectsToManage } from '../ducks/prospects/operations';
import { deleteProspectsById, updateSelected } from '../ducks/prospects/slice';
import { handleErrorNotification, handleMoveProspects, handleSuccessNotification } from './ui';
import { getOrganizationRewards } from './userOrganization';

export const updateCampaigns = (campaigns) => {
    return {
        type: campaignActionTypes.UPDATE_CAMPAIGNS,
        campaigns: campaigns,
    };
};

export const updateStatistics = (statistics) => {
    return {
        type: campaignActionTypes.UPDATE_STATISTICS,
        statistics: statistics,
    };
};

export const updateCampaign = (campaign) => {
    return {
        type: campaignActionTypes.UPDATE_CAMPAIGN,
        campaign: campaign,
    };
};

export const updateCampaignName = (id, name) => {
    return {
        type: campaignActionTypes.UPDATE_CAMPAIGN_NAME,
        payload: { id, name },
    };
};

export const updateSources = (sources) => {
    return {
        type: campaignActionTypes.UPDATE_SOURCES,
        sources: sources,
    };
};

export const addSource = (source, campaignId) => {
    return {
        type: campaignActionTypes.ADD_SOURCE,
        source: source,
        campaignId: campaignId,
    };
};

export const addCampaign = (campaign) => {
    return {
        type: campaignActionTypes.ADD_CAMPAIGN,
        campaign: campaign,
    };
};

export const updateIsLoadingData = (value) => ({
    type: campaignActionTypes.IS_LOADING_DATA,
    payload: value,
});

export const fetchStatistics = (campaigns) => async (dispatch, getState) => {
    const profilesSdk = getZaplifySdk().profiles;
    try {
        const statistics = await Promise.all(
            campaigns.map(async (campaign) => {
                if (campaign.status !== CampaignStatus.pending) {
                    const stat = await profilesSdk.campaigns.getCampaignStatistics(campaign.id);
                    if (stat) (stat as any).id = campaign.id;
                    return stat;
                }
            })
        );
        await dispatch(updateStatistics(statistics));
        return statistics;
    } catch (error) {
        console.log("Couldn't fetch statistics.");
        //dispatch(registerError(error));
    } finally {
        //dispatch(stopLoading());
    }
};

export const fetchCampaigns = () => async (dispatch) => {
    dispatch(updateIsLoadingData(true));
    dispatch(getOrganizationRewards());
    try {
        const campaigns = await getZaplifySdk().profiles.campaigns.getUserCampaigns();
        Object.keys(campaigns || []).map((id) => (campaigns[id].calculatedTasks = getManualTasks(campaigns[id])));
        dispatch(updateCampaigns(campaigns));
        dispatch(updateIsLoadingData(false));
        return campaigns;
    } catch (error) {
        console.log('error fetching');
        dispatch(updateIsLoadingData(false));
    }
};

export const cloneCampaign = (campaignId) => async (dispatch, getState) => {
    const profilesSdk = getZaplifySdk().profiles;

    try {
        const errorCallback = (error) => {
            throw new Error(error);
        };
        await profilesSdk.campaigns.cloneCampaign(campaignId, errorCallback);
        await dispatch(fetchCampaigns());
        dispatch(handleSuccessNotification('Successfully copied campaign', 2000));
    } catch (error) {
        if (error['message']) {
            const parseError = JSON.parse(error['message']);
            dispatch(handleErrorNotification(parseError.data.message, 2000));
        } else {
            dispatch(handleErrorNotification('Failed to copy campaign', 2000));
        }
    }
};

export const deleteCampaign = (campaignId, navigate) => async (dispatch) => {
    const profilesSdk = getZaplifySdk().profiles;
    try {
        await profilesSdk.campaigns.archiveCampaign(campaignId);
        dispatch(fetchCampaigns());
        dispatch(handleSuccessNotification('Successfully deleted campaign', 2000));
        navigate(paths.HOME);
    } catch (error) {
        dispatch(handleErrorNotification('Failed to delete campaign', 2000));
        const formattedError = typeof error === 'object' ? JSON.stringify(error) : error;
        console.log('error deleting campaign', formattedError);
    }
};

export const releaseRemoveProspectsOld = (campaignId, arrayOfProspectsId, text) => async (dispatch) => {
    const profilesSdk = getZaplifySdk().profiles;
    try {
        await profilesSdk.campaigns.removeProspectsFromCampaign(campaignId, arrayOfProspectsId);
        await dispatch(getProspectsToManage(campaignId));
        dispatch(handleSuccessNotification(text, 2000));
    } catch (error) {
        console.log('error releaseRemoveProspects');
        dispatch(handleErrorNotification('Failed to delete prospects', 2000));
    }
};

export const releaseRemoveProspects =
    (campaignId, arrayOfProspectsId, text, deleteProspectsWhenReleasing) => async (dispatch, getState) => {
        const profilesSdk = getZaplifySdk().profiles;

        if (!deleteProspectsWhenReleasing) {
            dispatch(releaseRemoveProspectsOld(campaignId, arrayOfProspectsId, text));
            return;
        }

        try {
            await profilesSdk.campaigns.removeProspectsFromCampaign(campaignId, arrayOfProspectsId);

            const prospectManagement = await fetchProspectsToManage(
                campaignId,
                getState().prospects,
                profilesSdk.campaigns.getProspects.bind(profilesSdk.campaigns)
            );

            //TODO: add feature flag for deleteProspectsById - fallback should be getProspectsToManage
            batch(() => {
                dispatch(handleSuccessNotification(text, 2000));
                dispatch(deleteProspectsById(arrayOfProspectsId));
            });

            if (prospectManagement.pagesCount !== getState().prospects.pagesCount) {
                await dispatch(getProspectsToManage(campaignId));
            }
        } catch (error) {
            console.log('error releaseRemoveProspects');
            dispatch(handleErrorNotification('Failed to delete prospects', 2000));
        }
    };

// This method below could use same logic as releaseRemoveProspects
// move prospects -> deleteProspectsById -> page count comparison and eventually getProspectsToManage
export const moveProspectsToAnotherCampaign =
    ({
        campaignId,
        toCampaignId,
        prospectIds,
        text,
        moveSelectedProspects = false,
        moveFromProspectRow = false,
        prospect,
        syncMany,
    }) =>
    async (dispatch) => {
        const profilesSdk = getZaplifySdk().profiles;
        try {
            moveFromProspectRow && dispatch(unselectProspect(prospect));
            await profilesSdk.campaigns.moveProspectsToAnotherCampaign(campaignId, { toCampaignId, prospectIds });
            batch(() => {
                dispatch(handleSuccessNotification(text, 2000));
                moveSelectedProspects && dispatch(updateSelected({}));
                dispatch(getProspectsToManage(campaignId));
            });
            dispatch(handleMoveProspects(false));

            syncMany(
                prospectIds.map((prospectId) => ({
                    prospectId,
                }))
            );
            return true;
        } catch (error) {
            console.log('error moveProspectsToAnotherCampaign');
            dispatch(handleErrorNotification('Something went wrong', 2000));
        }
    };

export const canMoveProspectsToAnotherCampaign = (campaignId, prospectIds, callback) => async (dispatch) => {
    const profilesSdk = getZaplifySdk().profiles;
    try {
        const canMoveResponse = await profilesSdk.campaigns.canMoveProspectsToAnotherCampaign(campaignId, prospectIds);
        callback(canMoveResponse);
    } catch (error) {
        console.log('error canMoveProspectsToAnotherCampaign');
        dispatch(handleErrorNotification('Something went wrong', 2000));
    }
};
