import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './constants';
import { ProspectSearchFilterState, ResetLastAddedFilter, UpdateSearchPayload } from './types';
import { isAnyFilterSelected } from './helpers';
import { SearchScopes } from '@zaplify/constants';

const searchSlice = createSlice({
    name: 'prospectSearchFilters',
    initialState: initialState,
    reducers: {
        // always use "thunkUpdateSearch" when want to update filters
        // "thunkUpdateSearch" will update hasSomeFiltersSelected and lastFilterPointName as well
        updateSearch(state, action: PayloadAction<UpdateSearchPayload>) {
            state[action.payload.point] = action.payload.value;
        },
        resetProspectSearch(state) {
            return initialState;
        },
        removeLastAddedFilter(state, { payload: { point } }: PayloadAction<ResetLastAddedFilter>) {
            if (!isNaN(state[point])) {
                state[point] = 0;
                return;
            }

            const collection = [...state[point]];

            if (typeof state[point] === 'object' && !Array.isArray(collection)) {
            }

            if (Array.isArray(collection)) {
                collection.pop();
                state[point] = collection;
            }
            state.lastFilterPointName = { point: '', value: '' };
        },
        replaceProspectSearch(state, action: PayloadAction<any>) {
            return { ...state, ...action.payload };
        },
        updateHasSomeFiltersSelected(state, action: PayloadAction<boolean>) {
            state.hasSomeFiltersSelected = action.payload;
        },
        updateHasSomeFiltersSelectedByProspectSearchFilter(state, action: PayloadAction<ProspectSearchFilterState>) {
            state.hasSomeFiltersSelected = isAnyFilterSelected(action.payload);
        },
        updateLastFilterPointName(state, action: PayloadAction<UpdateSearchPayload>) {
            state.lastFilterPointName = { point: action.payload.point, value: action.payload.value };
        },
        updateOneProspectPerCompany(state, action: PayloadAction<boolean>) {
            state.oneProspectPerCompany = action.payload;
        },
        updateHideOwnedCrmCompanies(state, action: PayloadAction<boolean>) {
            state.hideOwnedCrmCompanies = action.payload;
        },
        updateHideFilterPanel(state) {
            state.hideFilterPanel = !state.hideFilterPanel;
        },
        updateSearchScope(state, action: PayloadAction<SearchScopes>) {
            state.searchScope = action.payload;
        },
    },
});

const {
    updateSearch,
    removeLastAddedFilter,
    resetProspectSearch,
    replaceProspectSearch,
    updateHasSomeFiltersSelected,
    updateHasSomeFiltersSelectedByProspectSearchFilter,
    updateLastFilterPointName,
    updateOneProspectPerCompany,
    updateHideOwnedCrmCompanies,
    updateHideFilterPanel,
    updateSearchScope,
} = searchSlice.actions;
export {
    updateSearch,
    removeLastAddedFilter,
    resetProspectSearch,
    replaceProspectSearch,
    updateHasSomeFiltersSelected,
    updateLastFilterPointName,
    updateOneProspectPerCompany,
    updateHideOwnedCrmCompanies,
    updateHasSomeFiltersSelectedByProspectSearchFilter,
    updateHideFilterPanel,
    updateSearchScope,
};
export const prospectSearchReducer = searchSlice.reducer;
