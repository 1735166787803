export const ProspectDataUploadableFields = [
    { name: 'firstName', label: 'First Name' },
    { name: 'lastName', label: 'Last Name' },
    { name: 'fullName', label: 'Full Name' },
    { name: 'location', label: 'Location' },
    { name: 'email', label: 'Email' },
    { name: 'phoneNumber', label: 'Phone Number' },
    { name: 'linkedinProfileUrl', label: 'LinkedIn Profile URL' },
    { name: 'industry', label: 'Industry' },
    { name: 'occupationTitle', label: 'Occupation Title' },
    { name: 'organizationName', label: 'Organization Name' },
    { name: 'location', label: 'Contact location' },
    { name: 'organizationDomain', label: 'Website' },
    { name: 'occupationTitle', label: 'Job title' },
];
