import { useState, useEffect, useCallback, FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApolloClient } from '@apollo/client';
import { getAuth } from 'firebase/auth';
import { getEmailDomain } from '@zaplify/utils';
import { v4 as uuidv4 } from 'uuid';
import { Button } from '@shadcn/ui/components/ui/button';
import { Card, CardContent } from '@shadcn/ui/components/ui/card';
import { Skeleton } from '@shadcn/ui/components/ui/skeleton';
import { Separator } from '@shadcn/ui/components/ui/separator';
import { Loader2, MoreVertical, Pen } from 'lucide-react';
import { useAuth } from '../../providers/authentication-provider';
import { useSdk } from '../../sdk/use-sdk';
import { useSeatsAndCredits } from '../../hooks/use-seat-and-credits';
import { LocalStorageKeys } from '../../../config';
import { paths } from '../../../routes/paths';
import { getAuthenticationToken } from '@zaplify/utils';
import { toast } from '@shadcn/ui/hooks/use-toast';
import { CreateUserDto } from '@zaplify/users/shared';
import { AndSendLogotype } from '../../../components/icons/and-send/andsend-logo';
import { AuthHeader } from '../../pages/auth/components/auth-header';
import { IUserAccount } from '@zaplify/users/shared';
import { cn } from '@shadcn/ui/lib/utils';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogClose,
} from '@shadcn/ui/components/ui/dialog';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '@shadcn/ui/components/ui/dropdown-menu';
import { Input } from '@shadcn/ui/components/ui/input';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@shadcn/ui/components/ui/form';

const formSchema = z.object({
    organizationName: z.string().min(1, 'Organization name is required'),
});

// @TODO: This is the worst piece of code I've ever copied. We should refactor this.
// we should not have multiple async operations in a component. most of this should be handled on the server side.
export const WorkspacesPage: FC = () => {
    const navigate = useNavigate();
    const { workspaceId } = useParams();
    const {
        authState: { userOrganizationId, authUserId, user },
    } = useAuth();
    // Safely access userOrganization properties with optional chaining
    const domain = user?.userOrganization?.domain;
    const website = user?.userOrganization?.website;
    const [userInvitations, setInvitations] = useState([]);
    const [userAccounts, setUserAccounts] = useState<IUserAccount[]>([]);
    const [loading, setLoading] = useState(true);
    const [hasDomainOrganization, setHasDomainOrganization] = useState(false);
    const [numberOfUsersInOrg, setNumberOfUsersInOrg] = useState({});
    const [editingOrg, setEditingOrg] = useState<{ id: string; name: string; domain: string; website: string } | null>(
        null
    );
    const [isUpdatingOrgName, setIsUpdatingOrgName] = useState(false);
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            organizationName: '',
        },
    });

    const {
        user: { getUserAccounts, getUserCreditsAndSeatSummary, notifyInvitationClicked, createUser },
        authentication: { chooseOrganization },
        userOrganization: { updateOrganizationName },
    } = useSdk();
    const { refetch: refetchCredits } = useSeatsAndCredits();

    const tanStackClient = useQueryClient();
    const apolloClient = useApolloClient();
    const { mutateAsync: chooseOrganizationMutation } = useMutation(chooseOrganization());
    const { mutateAsync: createUserMutation } = useMutation(createUser());
    const { mutateAsync: updateOrgNameMutation } = useMutation(updateOrganizationName());

    const getAccounts = useCallback(async () => {
        try {
            const currentUser = getAuth().currentUser;
            if (!currentUser) return;

            const domain = getEmailDomain(currentUser.email);

            // Using the appropriate method from the SDK to get accounts
            // In the real implementation, you'll need to adapt this based on the actual SDK methods
            const accountResponse = await tanStackClient.fetchQuery(getUserAccounts());

            // Mock structure for development purposes - replace with actual data once we know the structure
            const accountData = accountResponse || { users: [], invitations: [] };
            const users = accountData.users || [];
            const invitations = accountData.invitations || [];

            console.log('users and invitations', { users, invitations });

            const userHasAccountWithDomain = users?.some((user) =>
                domain.includes(user.organization?.name?.toLowerCase())
            );

            try {
                // Fetch the seat summary for each organization if possible
                for (const account of users) {
                    if (account.userId && account.organization?.id) {
                        // Again, adapt this call based on actual SDK method
                        const creditSummary = await tanStackClient.fetchQuery(
                            getUserCreditsAndSeatSummary(account.userId)
                        );
                        // Assuming the response structure based on method name
                        const activeUsers = creditSummary?.seatSummary?.occupiedSeatsCount || 0;

                        setNumberOfUsersInOrg((prev) => ({
                            ...prev,
                            [account.organization.id]: activeUsers,
                        }));
                    }
                }
            } catch (error) {
                console.error('Error getting number of users in org', error);
            }

            setHasDomainOrganization(userHasAccountWithDomain);
            setInvitations(invitations);
            setUserAccounts(users);
            refetchCredits();
            setLoading(false);
        } catch (error) {
            console.error('error accounts', error);
            toast({
                title: 'Error getting accounts',
                variant: 'destructive',
            });
            setLoading(false);
        }
    }, [refetchCredits]);

    const handleSelect = async (action: 'select_account' | 'create_new_workspace', organizationId?: string) => {
        const selectOrganization = async (organizationId?: string) => {
            await chooseOrganizationMutation({ organizationId });
            await getAuthenticationToken(true);
            console.log('reloaded claims before clearing cache');
            await Promise.all([tanStackClient.clear(), apolloClient.resetStore()]);
        };

        if (action === 'select_account' && organizationId) {
            console.log('did select organization');
            await selectOrganization(organizationId);
            navigate(`${paths.WORKSPACES.ROOT}/${organizationId}`);
            return;
        }

        if (action === 'create_new_workspace') {
            console.log('did select create new workspace');
            await selectOrganization(); // exiting to "no chosen user" required to create new account
            navigate(`${paths.WORKSPACES.NEW}`);
            return;
        }
    };

    const handleJoinOrganization = async ({
        userOrganizationId,
        invitationId,
        organizationName,
        userAccountToBaseNewAccountOf,
    }: {
        userOrganizationId: string;
        invitationId: string;
        organizationName: string;
        userAccountToBaseNewAccountOf?: any;
    }) => {
        if (!userAccountToBaseNewAccountOf && organizationName) {
            console.log('joining org by invitation: no user in andsend yet. redirecting to create first user account');
            navigate(paths.WORKSPACES.NEW);
            return;
        } else if (userAccountToBaseNewAccountOf && userOrganizationId && invitationId) {
            navigate(`${paths.WORKSPACES}/${userOrganizationId}`);

            console.log('joining org by invitation: already has user in andsend');
            try {
                const payload: CreateUserDto = {
                    firebaseId: authUserId,
                    email: userAccountToBaseNewAccountOf.email,
                    firstName: userAccountToBaseNewAccountOf.firstName,
                    lastName: userAccountToBaseNewAccountOf.lastName,
                    location: userAccountToBaseNewAccountOf.location,
                    isSubscribed: userAccountToBaseNewAccountOf.isSubscribed,
                    joinByInvitation: {
                        id: invitationId,
                        organizationId: userOrganizationId,
                    },
                    // note, referral link not relevant when joining organization – only when creating account for first time
                };

                await createUserMutation(payload);

                localStorage.removeItem(LocalStorageKeys.INVITATION_ID);
                localStorage.removeItem(LocalStorageKeys.INVITATION_SECURITY_TOKEN);
                localStorage.removeItem(LocalStorageKeys.INVITATION_STATUS);

                await getAuthenticationToken(true);
                await Promise.all([tanStackClient.clear(), apolloClient.resetStore()]);

                toast({
                    title: 'Successfully joined organization',
                    variant: 'success',
                });
            } catch (error) {
                console.error('Error handleJoinOrganization', error);
                toast({
                    title: 'Unable to join this user organization',
                    variant: 'destructive',
                });
            }
        } else {
            console.error(
                'joining org by invitation: something in this invitation is off. Seems like either the organizationId or invitationId is missing',
                { userAccountToBaseNewAccountOf, userOrganizationId, invitationId }
            );
        }
    };

    const handleEditOrganization = async (values: z.infer<typeof formSchema>) => {
        if (!editingOrg) return;

        try {
            setIsUpdatingOrgName(true);
            await updateOrgNameMutation({
                organizationId: editingOrg.id,
                updateUserOrganizationDto: {
                    name: values.organizationName,
                    domain: editingOrg.domain, // These are required by the DTO but won't be updated
                    website: editingOrg.website, // These are required by the DTO but won't be updated
                },
            });

            await getAccounts(); // Refresh the accounts list
            setEditingOrg(null);
            toast({
                title: 'Workspace name updated successfully',
                variant: 'success',
            });
        } catch (error) {
            console.error('Error updating workspace name:', error);
            toast({
                title: 'Failed to update workspace name',
                variant: 'destructive',
            });
        } finally {
            setIsUpdatingOrgName(false);
        }
    };

    useEffect(() => {
        if (editingOrg) {
            form.reset({
                organizationName: editingOrg.name || '',
            });
        }
    }, [editingOrg]);

    // This effect handles redirects based on URL param and auth state
    useEffect(() => {
        // Skip if still loading
        if (loading) return;

        // If user selected a workspace and auth state has updated to match that selection,
        // redirect to the dashboard
        if (workspaceId && workspaceId === userOrganizationId) {
            try {
                // Hard reload to clear cache
                // TODO: this is a hack to clear the cache. We should find a better way to do this.
                window.location.href = paths.NEW.ACTIONS;
            } catch (error) {
                console.error('Failed to redirect using window.location:', error);
                navigate(paths.NEW.ACTIONS);
            }
            return;
        }

        // Note: We don't need a special case for "new" workspace anymore
        // The handleSelect function navigates directly to ${paths.WORKSPACES}/new after clearing the organization
    }, [userOrganizationId, navigate, workspaceId, loading]);

    useEffect(() => {
        getAccounts();
    }, [getAccounts]);

    return (
        <div className="flex flex-col min-h-screen bg-background-secondary">
            {/* Header */}
            {!workspaceId && (
                <>
                    <div className="flex flex-col gap-4 p-4 md:p-6 z-10">
                        <AuthHeader />
                    </div>

                    {/* Content */}
                    <main className="flex-1 py-12">
                        <div className="container max-w-3xl mx-auto space-y-8 px-4 sm:px-6">
                            <div className="text-center">
                                <h1 className="text-4xl font-bold tracking-tight">Your Andsend Workspaces</h1>
                            </div>

                            <div className="space-y-4">
                                {loading && (
                                    <Card>
                                        <CardContent className="pt-6">
                                            <div className="flex items-center gap-4">
                                                <Skeleton className="h-14 w-14 rounded-full" />
                                                <div className="space-y-2">
                                                    <Skeleton className="h-4 w-[250px]" />
                                                    <Skeleton className="h-3 w-[150px]" />
                                                </div>
                                                <Skeleton className="h-10 w-[100px] ml-auto" />
                                            </div>
                                        </CardContent>
                                    </Card>
                                )}

                                {!loading &&
                                    !workspaceId &&
                                    userAccounts.map((account) => (
                                        <Card
                                            key={account.organization?.id}
                                            className={cn(
                                                userOrganizationId === account.organization?.id &&
                                                    'bg-accent/50 shadow-md border-1 border-accent',
                                                'hover:bg-accent/50 hover:border-accent/50 hover:shadow-xl transition-all duration-300'
                                            )}
                                        >
                                            <CardContent className="p-6">
                                                <div className="flex items-center gap-4">
                                                    <div className="h-14 w-14 rounded-full bg-muted flex items-center justify-center">
                                                        <span className="text-lg font-semibold text-muted-foreground">
                                                            {account.organization.name?.charAt(0).toUpperCase() || 'O'}
                                                        </span>
                                                    </div>
                                                    <div className="space-y-1">
                                                        <h3 className="font-medium text-lg">
                                                            {account.organization?.name}
                                                        </h3>
                                                        {Boolean(numberOfUsersInOrg[account.organization?.id]) && (
                                                            <p className="text-sm text-muted-foreground">
                                                                {numberOfUsersInOrg[account.organization?.id]} users
                                                            </p>
                                                        )}
                                                    </div>
                                                    <div className="ml-auto flex items-center gap-2">
                                                        {userOrganizationId === account.organization?.id && (
                                                            <DropdownMenu>
                                                                <DropdownMenuTrigger asChild>
                                                                    <Button
                                                                        variant="ghost"
                                                                        size="icon"
                                                                        className="h-8 w-8"
                                                                    >
                                                                        <MoreVertical className="h-4 w-4" />
                                                                    </Button>
                                                                </DropdownMenuTrigger>
                                                                <DropdownMenuContent align="end">
                                                                    <DropdownMenuItem
                                                                        onClick={() => {
                                                                            setEditingOrg({
                                                                                id: account.organization.id,
                                                                                name: account.organization.name,
                                                                                domain: domain,
                                                                                website: website,
                                                                            });
                                                                            console.log('account', account);
                                                                            console.log(
                                                                                'userOrganizationId',
                                                                                userOrganizationId
                                                                            );
                                                                            console.log('domain', domain);
                                                                            console.log('website', website);
                                                                        }}
                                                                    >
                                                                        <Pen className="mr-2 h-4 w-4" />
                                                                        Edit name
                                                                    </DropdownMenuItem>
                                                                </DropdownMenuContent>
                                                            </DropdownMenu>
                                                        )}
                                                        <Button
                                                            onClick={() =>
                                                                handleSelect('select_account', account.organization.id)
                                                            }
                                                            disabled={workspaceId === account.organization.id}
                                                            variant="outline"
                                                        >
                                                            {workspaceId === account.organization.id ? (
                                                                <>
                                                                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                                                    Selecting...
                                                                </>
                                                            ) : (
                                                                'Select'
                                                            )}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    ))}

                                {!loading &&
                                    userInvitations.map((invitation) => (
                                        <Card key={uuidv4()}>
                                            <CardContent className="pt-6">
                                                <div className="flex items-center gap-4">
                                                    <div className="h-14 w-14 rounded-full bg-muted flex items-center justify-center">
                                                        <span className="text-lg font-semibold text-muted-foreground">
                                                            {invitation.organization.name?.charAt(0).toUpperCase() ||
                                                                'O'}
                                                        </span>
                                                    </div>
                                                    <div className="space-y-1">
                                                        <h3 className="font-medium text-lg">
                                                            {invitation.organization.name}
                                                        </h3>
                                                        {Boolean(numberOfUsersInOrg[invitation.organization?.id]) && (
                                                            <p className="text-sm text-muted-foreground">
                                                                {numberOfUsersInOrg[invitation.organization.id]} users
                                                            </p>
                                                        )}
                                                    </div>
                                                    <Button
                                                        className="ml-auto"
                                                        onClick={() =>
                                                            handleJoinOrganization({
                                                                userOrganizationId: invitation.organization.id,
                                                                invitationId: invitation.invitationId,
                                                                organizationName: invitation.organization.name,
                                                                userAccountToBaseNewAccountOf: userAccounts?.length
                                                                    ? userAccounts[0]
                                                                    : undefined,
                                                            })
                                                        }
                                                        disabled={workspaceId === invitation.organization.id}
                                                    >
                                                        {workspaceId === invitation.organization.id ? (
                                                            <>
                                                                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                                                Joining...
                                                            </>
                                                        ) : (
                                                            'Join team'
                                                        )}
                                                    </Button>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    ))}

                                {!loading && (
                                    <>
                                        <Separator className="my-8" />
                                        <div className="text-center text-sm text-muted-foreground">
                                            <p>
                                                Looking for a different workspace?
                                                <br />
                                                Ask someone to invite you or{' '}
                                                <Button
                                                    variant="link"
                                                    className="p-0 h-auto font-normal"
                                                    onClick={async (e) => {
                                                        e.preventDefault();
                                                        navigate(paths.WORKSPACES.NEW);
                                                    }}
                                                >
                                                    create a new workspace
                                                </Button>
                                            </p>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </main>
                </>
            )}

            <Dialog
                open={!!editingOrg}
                onOpenChange={(open) => {
                    if (!open) {
                        setEditingOrg(null);
                        form.reset();
                    }
                }}
            >
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Edit Workspace Name</DialogTitle>
                        <DialogDescription>
                            Change the name of your workspace. This will be visible to all members.
                        </DialogDescription>
                    </DialogHeader>
                    <Form {...form}>
                        <form onSubmit={form.handleSubmit(handleEditOrganization)} className="space-y-4">
                            <FormField
                                control={form.control}
                                name="organizationName"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Workspace Name</FormLabel>
                                        <FormControl>
                                            <Input placeholder="Enter workspace name" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <DialogFooter>
                                <DialogClose asChild>
                                    <Button variant="outline" type="button" onClick={() => form.reset()}>
                                        Cancel
                                    </Button>
                                </DialogClose>
                                <Button type="submit" disabled={isUpdatingOrgName || !form.formState.isDirty}>
                                    {isUpdatingOrgName ? (
                                        <>
                                            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                            Saving...
                                        </>
                                    ) : (
                                        'Save changes'
                                    )}
                                </Button>
                            </DialogFooter>
                        </form>
                    </Form>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default WorkspacesPage;
