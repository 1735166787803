import { cn } from '@shadcn/ui/lib/utils';
import { GetProspectMessagesHistoryQuery, GetProspectNotesQuery } from '@zaplify/graphql';
import { MessageFooter } from './message-footer';
import { MessageContent } from './message-content';
import { ContactAvatar } from '../../../../components/contact-avatar';
import { MeAvatar } from '../../../../components/me-avatar';
import clsx from 'clsx';
import { Check, CircleAlert, Reply, Trash, UserRoundPlus } from 'lucide-react';
import { Tooltip, TooltipContent } from '@shadcn/ui/components/ui/tooltip';
import { TooltipTrigger } from '@radix-ui/react-tooltip';
import { useDeleteNoteDialog } from '../../../../components/dialogs/delete-note-dialog';
import { SystemAvatar } from '../../../../components/system-avatar';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useEditContactDetailsDialog } from '../../../../components/dialogs/edit-contact-details-dialog';
import { useDraftMessage } from '../../../../hooks/messaging/use-draft-messages';
import { useMessages } from '../../../../hooks/messaging/use-messages';
import { useParams } from 'react-router-dom';
import { useSdk } from '../../../../sdk';
import { useQuery } from '@tanstack/react-query';
dayjs.extend(updateLocale);
dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.updateLocale('en', {
    relativeTime: {
        future: 'in %s',
        past: '%s ago',
        s: 'just now',
        m: '1m',
        mm: '%dm',
        h: '1h',
        hh: '%dh',
        d: '1d',
        dd: '%dd',
        M: '1mo',
        MM: '%dmo',
        y: '1y',
        yy: '%dy',
    },
});

type Message = GetProspectMessagesHistoryQuery['Messages'][number] | GetProspectNotesQuery['Notes'][number];
type MessageProps = Message & { conversationId?: string; className?: string };

export const Message = (message: MessageProps) => {
    if (
        message.__typename === 'Messages' &&
        message.actionType === 'LINKEDIN_CONNECTION_REQUEST' &&
        !message.content?.length
    ) {
        return <ConnectionRequestSentComponent {...message} />;
    } else if (message.__typename === 'Messages' && message.actionType === 'LINKEDIN_CONNECTION_REQUEST_ACCEPTED') {
        return <ConnectionRequestAcceptedComponent {...message} />;
    }
    return <MessageComponent {...message} />;
};

const ConnectionRequestSentComponent = (
    message: GetProspectMessagesHistoryQuery['Messages'][number] & { className?: string }
) => {
    const sentOn = dayjs.utc(message.sentOn);
    const sentOnLocal = dayjs.utc(sentOn).local();
    const sentOnString = sentOnLocal.isBefore(dayjs().subtract(1, 'year'))
        ? sentOnLocal.format('MMM D, YYYY HH:mm')
        : sentOnLocal.format('MMM D HH:mm');

    return (
        <div className={cn('flex gap-2 justify-center text-text-tertiary text-sm', message.className)}>
            <Tooltip>
                <TooltipTrigger asChild>
                    <div className="flex gap-2 items-center cursor-pointer">
                        <UserRoundPlus className="h-5 inline-block" />
                        <span className="inline-block">Connection request sent</span>
                    </div>
                </TooltipTrigger>
                <TooltipContent side="bottom">Sent {sentOnString}</TooltipContent>
            </Tooltip>
        </div>
    );
};

const ConnectionRequestAcceptedComponent = (
    message: GetProspectMessagesHistoryQuery['Messages'][number] & { className?: string }
) => {
    const sentOn = dayjs.utc(message.sentOn);
    const sentOnLocal = dayjs.utc(sentOn).local();
    const sentOnString = sentOnLocal.isBefore(dayjs().subtract(1, 'year'))
        ? sentOnLocal.format('MMM D, YYYY HH:mm')
        : sentOnLocal.format('MMM D HH:mm');

    return (
        <div className={cn('flex gap-2 justify-center text-text-tertiary text-sm', message.className)}>
            <Tooltip>
                <TooltipTrigger asChild>
                    <div className="flex gap-2 items-center cursor-pointer">
                        <Check className="h-5 inline-block" />
                        <span className="inline-block">Connection request accepted</span>
                    </div>
                </TooltipTrigger>
                <TooltipContent side="bottom">Accepted {sentOnString}</TooltipContent>
            </Tooltip>
        </div>
    );
};

const MessageComponent = (message: MessageProps) => {
    const { prospectId: pageProspectId } = useParams();
    const isNote = message.__typename === 'Notes';
    const isSystemNote = isNote && message.creator === 'SYSTEM';
    const sentOn = dayjs.utc(!isNote ? message.sentOn : message.createdAt);
    const channelType = !isNote ? message.channelType : 'NOTE';
    const direction = !isNote ? message.direction : 'out';
    const sentOnLocal = isNote ? dayjs.utc(message.happenedOn).local() : dayjs.utc(sentOn).local();
    let sentOnString = sentOnLocal.fromNow(true);
    const { openDeleteNoteDialog } = useDeleteNoteDialog();
    const { setDraftMessage } = useDraftMessage(message.prospectId);
    const { openEditContactDetailsDialog } = useEditContactDetailsDialog();
    const {
        prospect: { getProspectById },
    } = useSdk();
    const { data: prospect } = useQuery(getProspectById(message.prospectId, { enabled: !!message.prospectId }));

    if (!isNote && message.deliveryStatus === 'PENDING') {
        sentOnString = 'Sending...';
    }
    if (!isNote && message.deliveryStatus === 'ERROR') {
        sentOnString = 'Error';
    }

    let colorClass: clsx.ClassValue;
    if (isNote) {
        colorClass = 'bg-background-note';
    } else if (direction === 'out') {
        colorClass = 'bg-background-brand-primary-alt text-text-primary-on-brand-subtle';
    } else {
        colorClass = 'bg-muted';
    }

    const subjectLine = isNote ? message.title : message.subjectLine;

    const handleReply = () => {
        setDraftMessage({
            // content: draftMessage.content,
            channelType: channelType,
            subjectLine: subjectLine,
        });
    };

    return (
        <div
            key={message.id}
            className={cn(
                'group/message',
                'flex gap-2',
                direction === 'in' ? '' : 'flex-row-reverse',
                !isNote && message.deliveryStatus === 'PENDING' ? 'opacity-50' : '',
                message.className
            )}
        >
            {direction === 'out' ? (
                isSystemNote ? (
                    <SystemAvatar />
                ) : (
                    <MeAvatar />
                )
            ) : (
                <ContactAvatar avatarUrl={prospect?.data?.linkedinProfileImgUrl} fullName={prospect?.data?.fullName} />
            )}
            <div className={cn('flex flex-col gap-1 rounded-lg p-3 max-w-[75%] min-w-44', colorClass)}>
                <MessageContent
                    subjectLine={subjectLine}
                    content={message.content}
                    channelType={channelType}
                    isNote={isNote}
                    actionType={(message as GetProspectMessagesHistoryQuery['Messages'][number]).actionType}
                    attachments={(message as GetProspectMessagesHistoryQuery['Messages'][number])?.Attachments}
                />
                <MessageFooter
                    channelType={channelType}
                    conversationId={message?.conversationId}
                    sentOn={sentOnString}
                    className={cn(direction === 'in' ? '' : 'justify-end')}
                />
            </div>
            {/* {!isNote && direction === 'in' && isConversationMultiChannel && (
                <div className="hidden flex-col self-stretch justify-center group-hover:flex">
                    <Tooltip>
                        <TooltipTrigger>
                            <Reply
                                className="h-4 w-4 text-text-primary stroke-[1] hover:cursor-pointer"
                                onClick={handleReply}
                            />
                        </TooltipTrigger>
                        <TooltipContent>Reply</TooltipContent>
                    </Tooltip>
                </div>
            )} */}
            {isNote && (
                <div className="hidden flex-col self-stretch justify-center group-hover/message:flex">
                    <Tooltip>
                        <TooltipTrigger>
                            <Trash
                                className="h-4 w-4 text-destructive stroke-[1] hover:cursor-pointer"
                                onClick={() => openDeleteNoteDialog(message.id)}
                            />
                        </TooltipTrigger>
                        <TooltipContent>Remove note</TooltipContent>
                    </Tooltip>
                </div>
            )}
            {!isNote && message.deliveryStatus === 'BOUNCED' && (
                <div className="flex flex-col-reverse">
                    <Tooltip>
                        <TooltipTrigger className="flex gap-1 items-center text-destructive">
                            <span className="inline-block text-xs">Could not deliver</span>
                            <CircleAlert
                                className="h-4 w-4 stroke-[2] cursor-pointer inline-block"
                                onClick={() => openEditContactDetailsDialog(message.prospectId)}
                            />
                        </TooltipTrigger>
                        <TooltipContent>Change the contact's email address.</TooltipContent>
                    </Tooltip>
                </div>
            )}
            {!isNote && message.deliveryStatus === 'ERROR' && (
                <div className="flex flex-col-reverse">
                    <div className="flex gap-1 items-center text-destructive">
                        <span className="inline-block text-xs">{`An error occured. Please check your ${message?.channelType?.toLowerCase()} to see if the message was delivered.`}</span>
                        <CircleAlert
                            className="h-4 w-4 stroke-[2] cursor-pointer inline-block"
                            onClick={() =>
                                channelType === 'LINKEDIN' &&
                                message?.conversationId &&
                                window.open(
                                    `https://www.linkedin.com/messaging/thread/${message?.conversationId}`,
                                    '_blank'
                                )
                            }
                        />
                    </div>
                </div>
            )}
        </div>
    );
};
