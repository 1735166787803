import React, { Suspense } from 'react';
import { motion } from 'motion/react';
import { Skeleton } from '@shadcn/ui/components/ui/skeleton';
import { useSuggestionsFeed } from '../../hooks/use-suggestions-feed';
import { SplitContactSuggestionCardComponent } from './split-contact-suggestion-card-component';

export const SplitContactSuggestionCard: React.FC = () => {
    const { currentContactSuggestionId } = useSuggestionsFeed();
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="flex w-full h-full"
            key={currentContactSuggestionId}
        >
            {currentContactSuggestionId ? (
                <Suspense
                    fallback={
                        <Skeleton className="flex flex-col items-center justify-center h-full w-full bg-background-secondary rounded-2xl" />
                    }
                >
                    <SplitContactSuggestionCardComponent />
                </Suspense>
            ) : (
                <Skeleton className="flex flex-col items-center justify-center h-full w-full bg-background-secondary rounded-2xl" />
            )}
        </motion.div>
    );
};
