import { FC } from 'react';
import { LinkedInConnectionStatus } from '../hooks/use-apps';
import { ReactComponent as LinkedInIcon } from '../../../../../components/icons/linkedin-bigger.svg';
import { BaseAppCard, ConnectionStatus } from './base-app-card';
import { ChannelAccountStatus } from '@zaplify/channel-accounts/shared';

interface LinkedInAuthCardProps {
    onConnect: () => void;
    isLoading: boolean;
    isAdded: boolean;
    status: ChannelAccountStatus;
    connectionStatus: LinkedInConnectionStatus;
}

export const LinkedInAuthCard: FC<LinkedInAuthCardProps> = ({
    onConnect,
    isLoading,
    isAdded,
    status,
    connectionStatus,
}) => {
    // Map LinkedIn connection status to base card connection status
    const mapConnectionStatus = (): ConnectionStatus => {
        if (isAdded) return 'connected';
        if (connectionStatus === 'pending') return 'pending';
        if (connectionStatus === 'error') return 'error';
        return 'not_connected';
    };

    return (
        <BaseAppCard
            title="LinkedIn"
            appId="linkedin"
            bgColor="bg-blue-50"
            icon={<LinkedInIcon className="h-6 w-6 object-contain" />}
            isConnected={isAdded && status === ChannelAccountStatus.AUTHENTICATED}
            isActive={isAdded && status === ChannelAccountStatus.AUTHENTICATED}
            connectionStatus={mapConnectionStatus()}
            onConnect={onConnect}
            isLoading={isLoading}
            connectButtonText={
                status === ChannelAccountStatus.NOT_AUTHENTICATED ? 'Reconnect LinkedIn' : 'Connect LinkedIn'
            }
            notConnectedText="Connect your LinkedIn account to send messages."
            connectedText="Your LinkedIn account is connected."
            hideSettingsButton
        />
    );
};
