import { CircularProgress, CircularProgressProps } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStylesProgress = makeStyles((theme) => ({
    root: {
        position: 'relative',
    },
    bottom: {
        color: '#F0F1F4',
    },
    top: {
        color: '#5F8EFA',
        animationDuration: '550ms',
        position: 'absolute',
        left: 0,
    },
    circle: {
        strokeLinecap: 'round',
    },
    svg: { transform: 'rotateX(180deg)' },
}));

export interface CircularProgressCustomProps extends CircularProgressProps {
    /**
     * The color of the component. It supports those theme colors that make sense for this component.
     */
    color?: 'primary' | 'secondary' | 'inherit';
    /**
     * If `true`, the shrink animation is disabled.
     * This only works if variant is `indeterminate`.
     */
    disableShrink?: boolean;
    /**
     * The size of the circle.
     * If using a number, the pixel unit is assumed.
     * If using a string, you need to provide the CSS unit, e.g '3rem'.
     */
    size?: number | string;
    /**
     * The thickness of the circle.
     */
    thickness?: number;
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     */
    value?: number;
    /**
     * The variant to use.
     * Use indeterminate when there is no progress value.
     */
    variant?: 'determinate' | 'indeterminate';
}

export const CircularProgressCustom = (props: CircularProgressCustomProps) => {
    const classes = useStylesProgress();
    const scaleValue = Math.round(((props.value || 0) * 100) / 14);

    return (
        <div className={classes.root}>
            <CircularProgress
                variant='determinate'
                className={classes.bottom}
                size={57}
                thickness={4}
                {...props}
                value={100}
            />
            <CircularProgress
                variant='determinate'
                className={classes.top}
                classes={{
                    circle: classes.circle,
                    svg: classes.svg,
                }}
                size={57}
                thickness={4}
                {...props}
                value={scaleValue}
            />
        </div>
    );
};

export default CircularProgressCustom;
