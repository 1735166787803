import { Box, Typography } from '@mui/material';
import ConfigureProfileForm from '../components/auth-configure-profile-form';
import { companyNameCorrectlyCased } from '../../../enums/company-name';

export const Component = () => {
    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '8px' }}>
                <Typography
                    component="h1"
                    sx={{
                        fontSize: '32px',
                        fontWeight: '600',
                        lineHeight: '40px',
                        textAlign: 'center',
                        textWrap: 'balance',
                    }}
                >
                    Let’s set you up!
                </Typography>
                <Typography sx={{ textAlign: 'center', textWrap: 'balance' }}>
                    {`Introduce yourself to tailor your ${companyNameCorrectlyCased} experience`}
                </Typography>
            </Box>
            <ConfigureProfileForm />
        </>
    );
};

Component.displayName = 'ConfigureProfile';
