import AddIcon from '@mui/icons-material/Add';
import { Typography } from '@mui/material';
import { useDispatch } from 'react-redux-latest';
import { Button } from '../../../../../../components/atoms/button';
import { updateSalesforceSaveStatus } from '../../../../../../redux/ducks/salesforce/operations';
import { addField, deleteField, updateField } from '../../../../../../redux/ducks/salesforce/slice';
import { AppDispatch, useAppSelector } from '../../../../../../redux/store/configureStore';
import { disableUsedFieldSalesforce } from '../../../utils/fields';
import { ObjectTypes } from '../../constants';
import { FieldMappingContainer } from '../containers';
import { FieldMapHeader } from '../fieldMapHeader';
import { ProgressBar } from '../progressBar';
import { RefreshFields } from '../refreshFields';
import { FieldSelector } from '../selector/fieldSelector';
import { StaticFieldSelector } from '../selector/staticFieldSelector';
import { fieldValidator } from '../utils';

export const FieldMapContact = () => {
    const dispatch = useDispatch<AppDispatch>();
    const fields = useAppSelector((state) => state.salesforce.fieldMapping).map((field, idx) => {
        return { ...field, idx };
    });

    const prospectFields = useAppSelector((state) => state.salesforce.availableFields?.prospect || []);
    const contactFields = useAppSelector((state) => state.salesforce.availableFields?.contact || []);
    const contactPickListFields = useAppSelector((state) => state.salesforce.availableFields?.contactPickList || []);
    const accountFields = useAppSelector((state) => state.salesforce.availableFields?.account || []);
    const accountPickListFields = useAppSelector((state) => state.salesforce.availableFields?.accountPickList || []);

    const fieldsContact = fields.filter((field) => field.destination.objectType === ObjectTypes.contact);
    const fieldsAccount = fields.filter((field) => field.destination.objectType === ObjectTypes.account);

    const getFieldFromAvailableListByName = (fieldName, objectType) => {
        return objectType === 'Account'
            ? [...accountFields, ...accountPickListFields].find((accountField) => accountField.name === fieldName)
            : [...contactFields, ...contactPickListFields].find((contactField) => contactField.name === fieldName);
    };

    const handleDeleteField = (index) => {
        dispatch(deleteField({ index: index }));
        dispatch(updateSalesforceSaveStatus());
    };

    const leftOnChange = (e, index) => {
        const prospectField = prospectFields.find((field) => field.name === e.target.value);

        if (!prospectField) {
            return;
        }

        dispatch(
            updateField({ idx: index, type: 'source', objectType: prospectField.resource, fieldName: e.target.value })
        );
        dispatch(updateSalesforceSaveStatus());
    };
    const rightOnChangeAccount = (e, index) => {
        const field = getFieldFromAvailableListByName(e.target.value, ObjectTypes.account);

        if (!field) {
            return;
        }
        dispatch(
            updateField({
                idx: index,
                type: 'destination',
                objectType: field.resource,
                fieldName: e.target.value,
                isStatic: !!field.options?.length,
            })
        );
        dispatch(updateSalesforceSaveStatus());
    };

    const rightOnChangeContact = (e, index) => {
        const field = getFieldFromAvailableListByName(e.target.value, ObjectTypes.contact);

        if (!field) {
            return;
        }

        dispatch(
            updateField({
                idx: index,
                type: 'destination',
                objectType: field.resource,
                fieldName: e.target.value,
                isStatic: !!field.options?.length,
            })
        );
        dispatch(updateSalesforceSaveStatus());
    };

    const getStaticContactFieldOptions = (field) => {
        const contactField = contactPickListFields.find(
            (contactField) => contactField.name === field.destination.fieldName
        );

        if (contactField && contactField.options) {
            return contactField.options.map((option) => option.value);
        }

        return [];
    };

    const getStaticAccountFieldOptions = (field) => {
        const accountField = accountPickListFields.find(
            (accountField) => accountField.name === field.destination.fieldName
        );
        if (accountField && accountField.options) {
            return accountField?.options.map((option) => option.value);
        }
        return [];
    };

    const getStaticContactPlaceholder = (field) => {
        const contactField = contactPickListFields.find(
            (contactField) => contactField.name === field.destination.fieldName
        );
        if (contactField) {
            return `Select *${contactField.label}*`;
        }
        return `Select * NOT FIELD FIND *`;
    };

    const getStaticAccountPlaceholder = (field) => {
        const accountField = accountPickListFields.find(
            (accountField) => accountField.name === field.destination.fieldName
        );
        if (accountField) {
            return `Select *${accountField.label}*`;
        }
        return `Select * NOT FIELD FIND *`;
    };

    const formattedProspectFields = prospectFields.map((field) => ({ name: field.name, label: field.label }));
    const formattedContactFields = contactFields.map((field) => ({ name: field.name, label: field.label }));
    const formattedPickListContactFields = contactPickListFields.map((field) => ({
        name: field.name,
        label: field.label,
    }));
    const formattedAccountFields = accountFields.map((field) => ({ name: field.name, label: field.label }));
    const formattedPickListAccountFields = accountPickListFields.map((field) => ({
        name: field.name,
        label: field.label,
    }));
    const concatFormattedContact = [...formattedContactFields, ...formattedPickListContactFields].map(
        (formattedContactField) => disableUsedFieldSalesforce(formattedContactField, fieldsContact, 'fieldName', 'name')
    );
    const concatFormattedAccount = [...formattedAccountFields, ...formattedPickListAccountFields].map(
        (formattedAccountField) => disableUsedFieldSalesforce(formattedAccountField, fieldsAccount, 'fieldName', 'name')
    );

    if (!contactFields.length) {
        return (
            <FieldMappingContainer>
                <ProgressBar show={!contactFields.length} />
            </FieldMappingContainer>
        );
    }

    return (
        <FieldMappingContainer>
            <FieldMapHeader />
            <Typography variant="body2">Prospect</Typography>
            <div>
                {fieldsContact.map((field) => {
                    if (field.source.type === 'static') {
                        return (
                            <StaticFieldSelector
                                field={fieldValidator(field)}
                                getStaticPlaceholder={getStaticContactPlaceholder}
                                getStaticFieldOptions={getStaticContactFieldOptions}
                                leftOnChange={leftOnChange}
                                handleDeleteField={handleDeleteField}
                            />
                        );
                    }
                    return (
                        <FieldSelector
                            field={fieldValidator(field)}
                            leftOptions={formattedProspectFields}
                            rightOptions={concatFormattedContact}
                            leftOnChange={leftOnChange}
                            rightOnChange={rightOnChangeContact}
                            handleDeleteField={handleDeleteField}
                        />
                    );
                })}
            </div>
            <Button
                borderRadius="100px"
                variant="outlined"
                height="40px"
                startIcon={<AddIcon />}
                text="Add field"
                onClick={() => {
                    dispatch(addField({ objectType: ObjectTypes.contact }));
                    dispatch(updateSalesforceSaveStatus());
                }}
            />

            <Typography variant="body2">Company</Typography>
            <div>
                {fieldsAccount.map((field) => {
                    if (field.source.type === 'static') {
                        return (
                            <StaticFieldSelector
                                field={fieldValidator(field)}
                                getStaticPlaceholder={getStaticAccountPlaceholder}
                                getStaticFieldOptions={getStaticAccountFieldOptions}
                                leftOnChange={leftOnChange}
                                handleDeleteField={handleDeleteField}
                            />
                        );
                    }
                    return (
                        <FieldSelector
                            field={fieldValidator(field)}
                            leftOptions={formattedProspectFields}
                            rightOptions={concatFormattedAccount}
                            leftOnChange={leftOnChange}
                            rightOnChange={rightOnChangeAccount}
                            handleDeleteField={handleDeleteField}
                        />
                    );
                })}
            </div>

            <Button
                borderRadius="100px"
                variant="outlined"
                height="40px"
                startIcon={<AddIcon />}
                text="Add field"
                onClick={() => {
                    dispatch(addField({ objectType: ObjectTypes.account }));
                    dispatch(updateSalesforceSaveStatus());
                }}
            />

            <div className={'refresh-container'}>
                <RefreshFields />
            </div>
        </FieldMappingContainer>
    );
};
