import { FC } from 'react';
import { DiscoverPageHeader } from './components/discover-page-header';
import { Outlet } from 'react-router-dom';

export const DiscoverPage: FC = () => {
    return (
        <div className="flex max-h-[calc(100vh-3.5rem)] md:max-h-full h-full max-w-full w-full overflow-hidden">
            <div className="flex flex-col min-w-0 min-h-0 h-full max-h-full w-full overflow-hidden gap-2 p-4">
                <Outlet />
            </div>
        </div>
    );
};

export default DiscoverPage;
