import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthPageLayout } from './components/auth-page-layout';
import { Button } from '@shadcn/ui/components/ui/button';
import { Card, CardContent } from '@shadcn/ui/components/ui/card';
import { Input } from '@shadcn/ui/components/ui/input';
import { Form, FormField, FormLabel, FormMessage, FormItem, FormControl } from '@shadcn/ui/components/ui/form';
import { Skeleton } from '@shadcn/ui/components/ui/skeleton';
import { paths } from '../../../routes/paths';
import { firebaseActionLink } from '../../../services/utils/urlHelper';
import { getAuth, confirmPasswordReset } from 'firebase/auth';
import { useAuth } from '../../../new/providers/authentication-provider';
import { Loader2 } from 'lucide-react';
import { useMutation } from '@tanstack/react-query';
import { useSdk } from '../../../new/sdk/use-sdk';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useToast } from '@shadcn/ui/hooks/use-toast';
import { Link } from 'react-router-dom';
import { getZaplifySdk } from '@zaplify/sdk';

// Define the different views for the reset password page
enum ResetPasswordViews {
    ForgotPassword,
    NewPassword,
    Loading,
}

// Form schema for the forgot password form
const forgotPasswordSchema = z.object({
    email: z.string().email('Please enter a valid email address'),
});

// Form schema for the new password form
const newPasswordSchema = z.object({
    password: z.string().min(6, 'Password must be at least 6 characters'),
});

type ForgotPasswordFormValues = z.infer<typeof forgotPasswordSchema>;
type NewPasswordFormValues = z.infer<typeof newPasswordSchema>;

export const ResetPasswordPage = () => {
    const navigate = useNavigate();
    const { toast } = useToast();
    const { loading: isLoadingUser } = useAuth();
    const { authentication } = useSdk();
    const [currentView, setCurrentView] = useState<ResetPasswordViews>(ResetPasswordViews.Loading);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    // Forgot password form
    const forgotPasswordForm = useForm<ForgotPasswordFormValues>({
        resolver: zodResolver(forgotPasswordSchema),
        defaultValues: {
            email: '',
        },
        mode: 'onChange',
    });

    // New password form
    const newPasswordForm = useForm<NewPasswordFormValues>({
        resolver: zodResolver(newPasswordSchema),
        defaultValues: {
            password: '',
        },
        mode: 'onChange',
    });

    // Reset password mutation for the forgot password form
    const resetPasswordMutation = useMutation({
        mutationFn: async (data: ForgotPasswordFormValues) => {
            return await getZaplifySdk().profiles.authentication.resetPassword(data.email);
        },
        onSuccess: (data) => {
            toast({
                title: 'Success',
                description: 'Password reset email has been sent. Please check your inbox.',
            });
            navigate(
                `${paths.NEW_CONFIRM_PASSWORD_RESET}?email=${encodeURIComponent(
                    forgotPasswordForm.getValues('email')
                )}&resetPassword=true`
            );
        },
        onError: (error: any) => {
            setErrorMessage(error.message || 'An error occurred while sending the password reset email.');
            toast({
                title: 'Error',
                description: error.message || 'An error occurred while sending the password reset email.',
                variant: 'destructive',
            });
        },
    });

    // Confirm password reset mutation for the new password form
    const confirmPasswordResetMutation = useMutation({
        mutationFn: async (data: NewPasswordFormValues) => {
            const actionCode = firebaseActionLink.getCurrentActionCode();
            if (!actionCode) {
                throw new Error('Invalid password reset link. Please request a new password reset email.');
            }
            return await confirmPasswordReset(getAuth(), actionCode, data.password);
        },
        onSuccess: () => {
            toast({
                title: 'Success',
                description: 'Your password has been successfully reset.',
            });
            navigate(paths.NEW_LOGIN);
        },
        onError: (error: any) => {
            setErrorMessage(error.message || 'An error occurred while resetting your password.');
            toast({
                title: 'Error',
                description: error.message || 'An error occurred while resetting your password.',
                variant: 'destructive',
            });
        },
    });

    // Handle forgot password form submission
    const onForgotPasswordSubmit = (data: ForgotPasswordFormValues) => {
        resetPasswordMutation.mutate(data);
    };

    // Handle new password form submission
    const onNewPasswordSubmit = (data: NewPasswordFormValues) => {
        confirmPasswordResetMutation.mutate(data);
    };

    // Determine which view to show based on the Firebase action code
    useEffect(() => {
        const mode = firebaseActionLink.getCurrentMode();
        const actionCode = firebaseActionLink.getCurrentActionCode();

        if (mode === firebaseActionLink.resetPasswordModeName && actionCode) {
            setCurrentView(ResetPasswordViews.NewPassword);
        } else {
            setCurrentView(ResetPasswordViews.ForgotPassword);
        }
    }, []);

    if (isLoadingUser || currentView === ResetPasswordViews.Loading) {
        return (
            <AuthPageLayout>
                <div className="flex flex-col items-center space-y-6 w-full max-w-md">
                    <Skeleton className="h-12 w-48 rounded-md" />
                    <Skeleton className="h-4 w-64 rounded-md" />
                    <Skeleton className="h-12 w-full rounded-md" />
                    <Skeleton className="h-12 w-full rounded-md" />
                </div>
            </AuthPageLayout>
        );
    }

    const isForgotPasswordSubmitting = resetPasswordMutation.isPending;
    const isNewPasswordSubmitting = confirmPasswordResetMutation.isPending;

    return (
        <AuthPageLayout>
            <div className="flex flex-col gap-6 w-full max-w-md mx-auto">
                <Card className="w-full border-none shadow-none bg-transparent">
                    <CardContent className="space-y-6 px-0">
                        {currentView === ResetPasswordViews.ForgotPassword && (
                            <>
                                <div className="text-center space-y-2">
                                    <h1 className="text-3xl font-semibold ">Forgot password?</h1>
                                    <p>Enter your email and we'll send you a password reset link</p>
                                </div>
                                <Form {...forgotPasswordForm}>
                                    <form
                                        onSubmit={forgotPasswordForm.handleSubmit(onForgotPasswordSubmit)}
                                        className="space-y-4"
                                    >
                                        <FormField
                                            control={forgotPasswordForm.control}
                                            name="email"
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormLabel>Email</FormLabel>
                                                    <FormControl>
                                                        <Input
                                                            placeholder="name@work-email.com"
                                                            type="email"
                                                            autoComplete="email"
                                                            {...field}
                                                            disabled={isForgotPasswordSubmitting}
                                                        />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                        {errorMessage && <div className="text-sm text-destructive">{errorMessage}</div>}
                                        <Button
                                            type="submit"
                                            variant="default"
                                            disabled={
                                                !forgotPasswordForm.formState.isValid || isForgotPasswordSubmitting
                                            }
                                            className="w-full mt-3"
                                        >
                                            {isForgotPasswordSubmitting ? (
                                                <>
                                                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                                    Sending instructions...
                                                </>
                                            ) : (
                                                'Send instructions'
                                            )}
                                        </Button>
                                    </form>
                                    <div className="text-center text-sm mt-4">
                                        <Link
                                            to={paths.NEW_LOGIN}
                                            className="underline underline-offset-4 text-primary"
                                        >
                                            Back to login
                                        </Link>
                                    </div>
                                </Form>
                            </>
                        )}

                        {currentView === ResetPasswordViews.NewPassword && (
                            <>
                                <div className="text-center space-y-2">
                                    <h1 className="text-3xl font-semibold ">Create new password</h1>
                                    <p>Enter your new password below</p>
                                </div>
                                <Form {...newPasswordForm}>
                                    <form
                                        onSubmit={newPasswordForm.handleSubmit(onNewPasswordSubmit)}
                                        className="space-y-4"
                                    >
                                        <FormField
                                            control={newPasswordForm.control}
                                            name="password"
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormLabel>New Password</FormLabel>
                                                    <FormControl>
                                                        <Input
                                                            placeholder="Enter your new password"
                                                            type="password"
                                                            autoComplete="new-password"
                                                            {...field}
                                                            disabled={isNewPasswordSubmitting}
                                                        />
                                                    </FormControl>
                                                    <p className="text-xs text-muted-foreground mt-1">
                                                        Should contain at least 6 characters.
                                                    </p>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                        {errorMessage && <div className="text-sm text-destructive">{errorMessage}</div>}
                                        <Button
                                            type="submit"
                                            variant="default"
                                            disabled={!newPasswordForm.formState.isValid || isNewPasswordSubmitting}
                                            className="w-full mt-3"
                                        >
                                            {isNewPasswordSubmitting ? (
                                                <>
                                                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                                    Changing password...
                                                </>
                                            ) : (
                                                'Change password'
                                            )}
                                        </Button>
                                    </form>
                                    <div className="text-center text-sm mt-4">
                                        <Link
                                            to={paths.NEW_LOGIN}
                                            className="underline underline-offset-4 text-primary"
                                        >
                                            Back to login
                                        </Link>
                                    </div>
                                </Form>
                            </>
                        )}
                    </CardContent>
                </Card>
            </div>
        </AuthPageLayout>
    );
};

export default ResetPasswordPage;
