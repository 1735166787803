import { Tooltip } from '@mui/material';
import { BookOpen } from 'lucide-react';
import { paths } from '../../../../routes/paths';
import { NavigationMainItem } from './navigation-main-item';
import { useLocation } from 'react-router-dom';

export const PlaybooksListItem = ({ openSidebar }: { openSidebar: boolean }) => {
    const { pathname } = useLocation();
    const isActive = pathname.startsWith(paths.PLAYBOOKS);

    return (
        <Tooltip title={openSidebar ? '' : 'Playbooks'} placement="right" arrow>
            {/* important className for Frigade */}
            <span className="playbooks-nav-item">
                <NavigationMainItem
                    title="Playbooks"
                    to={'/new' + paths.PLAYBOOKS}
                    icon={<BookOpen size={20} strokeWidth="1px" />}
                    isActive={isActive}
                    isSidebarPinned={openSidebar}
                />
            </span>
        </Tooltip>
    );
};
