import { FC, useState, useEffect } from 'react';
import { Button } from '@shadcn/ui/components/ui/button';
import { CheckCircle, MapPin, XCircle, CreditCard } from 'lucide-react';
import {
    PlanCode,
    IPlan,
    IPlanPackage,
    SubscriptionDetailsDto,
    readableBillingFrequencyUnit,
    CreatedEstimateDto,
} from '@zaplify/subscriptions';
import { ChargebeePortalSection } from '../../../../../../enums/chargebee';
import { VatInformation } from '../vat-information';
import { getPriceReadableFormat } from '../../../../../functions/currency-utils';
import { cn } from '@shadcn/ui/lib/utils';
import { Input } from '@shadcn/ui/components/ui/input';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@shadcn/ui/components/ui/tooltip';
import { DiscountCode } from '../discount-code';

interface ConfirmationViewProps {
    onBack: () => void;
    selectedPlan: {
        plan: IPlan;
        package: IPlanPackage;
    };
    onLoadSelfServicePortal: (section?: ChargebeePortalSection, callback?: () => void) => Promise<void>;
    onPurchase: (couponCode?: string) => Promise<void>;
    subscription: SubscriptionDetailsDto;
    couponCode: string;
    onCouponCodeChange: (code: string) => Promise<void>;
    isCouponCodeValid: boolean;
    loadingState: 'idle' | 'loading';
    selectedPlanEstimatesSummary: CreatedEstimateDto & {
        planCode: PlanCode;
    };
    selectedPlanEstimatesTotal: CreatedEstimateDto & {
        planCode: PlanCode;
    };
    refetchSubscription: () => void;
}

export const ConfirmationView: FC<ConfirmationViewProps> = ({
    onBack,
    selectedPlan,
    onLoadSelfServicePortal,
    onPurchase,
    subscription,
    couponCode,
    onCouponCodeChange,
    isCouponCodeValid,
    loadingState,
    selectedPlanEstimatesSummary,
    selectedPlanEstimatesTotal,
    refetchSubscription,
}) => {
    const [hasBillingDetails, setHasBillingDetails] = useState(!!subscription.customer?.address?.line1);
    const [hasPaymentMethod, setHasPaymentMethod] = useState(!!subscription.customer?.card?.last4);

    // Update state when subscription data changes
    useEffect(() => {
        setHasBillingDetails(!!subscription.customer?.address?.line1);
        setHasPaymentMethod(!!subscription.customer?.card?.last4);
    }, [subscription]);

    const handlePurchase = () => {
        if (isCouponCodeValid && couponCode) {
            onPurchase(couponCode);
        } else {
            onPurchase();
        }
    };
    const immediatePurchase = selectedPlanEstimatesTotal?.immediate?.total !== 0;
    const hasRenewalDate = subscription.subscription?.renewalAt;
    const renewalDate = hasRenewalDate ? new Date(subscription.subscription?.renewalAt) : new Date();
    const renewalAt = renewalDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
    const readableBillingFrequencyValue = readableBillingFrequencyUnit(
        selectedPlan.plan.billingPeriod.amount,
        selectedPlan.plan.billingPeriod.unit
    );
    const planName = selectedPlan.plan.code === 'basic' ? 'Pro' : 'Team';

    const totalAmountToChargeReadable = immediatePurchase
        ? getPriceReadableFormat({
              price: (selectedPlanEstimatesTotal?.immediate.total || 0) / 100,
              maximumFractionDigits: 2,
              billingCurrency: subscription.subscription.current.currency,
          })
        : getPriceReadableFormat({
              price: (selectedPlanEstimatesTotal?.scheduled.total || 0) / 100,
              maximumFractionDigits: 2,
              billingCurrency: subscription.subscription.current.currency,
          });
    const estimatedPricePerMonth = immediatePurchase
        ? getPriceReadableFormat({
              price: (selectedPlanEstimatesSummary?.immediate.total || 0) / 100,
              maximumFractionDigits: 2,
              billingCurrency: subscription.subscription.current.currency,
          })
        : getPriceReadableFormat({
              price: (selectedPlanEstimatesSummary?.scheduled.total || 0) / 100,
              maximumFractionDigits: 2,
              billingCurrency: subscription.subscription.current.currency,
          });
    const vatTotalReadable = !selectedPlanEstimatesTotal?.immediate?.vat
        ? null
        : immediatePurchase
        ? getPriceReadableFormat({
              price: (selectedPlanEstimatesTotal?.immediate.vat || 0) / 100,
              maximumFractionDigits: 2,
              billingCurrency: subscription.subscription.current.currency,
              round: false,
          })
        : getPriceReadableFormat({
              price: (selectedPlanEstimatesTotal?.scheduled.vat || 0) / 100,
              maximumFractionDigits: 2,
              billingCurrency: subscription.subscription.current.currency,
              round: false,
          });

    console.log('hasPaymentMethod', hasPaymentMethod);
    console.log('hasBillingDetails', hasBillingDetails);

    return (
        <div className="grid grid-cols-2 gap-6 px-6">
            <div className="">
                <h3 className="text-lg font-medium mb-6">Payment Details</h3>
                <div className="flex flex-col gap-4">
                    <div className="flex flex-col gap-1">
                        <span className="text-sm text-text-tertiary">Payment Method</span>
                        <div className="flex items-center gap-2">
                            <CreditCard className="w-4 h-4" />
                            <span className="font-medium">
                                {hasPaymentMethod
                                    ? `•••• •••• •••• ${subscription.customer.card.last4}`
                                    : 'No payment method'}
                            </span>
                        </div>
                        <Button
                            variant="outline"
                            className="mt-2"
                            onClick={() => {
                                onLoadSelfServicePortal(ChargebeePortalSection.PAYMENT_SOURCES, async () => {
                                    await refetchSubscription();
                                    setHasPaymentMethod(true); // Optimistically set to true
                                });
                            }}
                        >
                            Update Payment Method
                        </Button>
                    </div>
                    <div className="flex flex-col gap-1">
                        <span className="text-sm text-text-tertiary">Billing Address</span>
                        <div className="flex items-center gap-2">
                            <MapPin className="w-4 h-4" />
                            <span className="font-medium">
                                {hasBillingDetails ? (
                                    <>
                                        {subscription.customer.address.line1}
                                        {subscription.customer.address.line2 &&
                                            subscription.customer.address.line2.trim() &&
                                            `, ${subscription.customer.address.line2}`}
                                        {(subscription.customer.address.city ||
                                            subscription.customer.address.state ||
                                            subscription.customer.address.zip) && <br />}
                                        {subscription.customer.address.city && subscription.customer.address.city}
                                        {subscription.customer.address.state &&
                                            `${subscription.customer.address.city ? ', ' : ''}${
                                                subscription.customer.address.state
                                            }`}
                                        {subscription.customer.address.zip && ` ${subscription.customer.address.zip}`}
                                        {subscription.customer.address.country && (
                                            <>
                                                <br />
                                                {subscription.customer.address.country}
                                            </>
                                        )}
                                    </>
                                ) : (
                                    'No billing address'
                                )}
                            </span>
                        </div>
                        <Button
                            variant="outline"
                            className="mt-2"
                            onClick={() => {
                                onLoadSelfServicePortal(ChargebeePortalSection.ADDRESS, async () => {
                                    await refetchSubscription();
                                    setHasBillingDetails(true); // Optimistically set to true
                                });
                            }}
                        >
                            Update Billing Address
                        </Button>
                    </div>
                    <div className="flex flex-col gap-2">
                        <DiscountCode
                            couponCode={couponCode}
                            onCouponCodeChange={onCouponCodeChange}
                            isCouponCodeValid={isCouponCodeValid}
                            isLoading={loadingState === 'loading'}
                        />
                    </div>
                </div>
            </div>
            <div className="px-7 pt-7">
                <h3 className="text-sm text-text-tertiary">Order summary</h3>
                <div className="flex justify-between mt-[18px]">
                    <span className="text-base font-medium" data-cy="summary-plan-name">
                        {planName}
                    </span>
                    <span className="text-base font-medium" data-cy="summary-plan-price">
                        {estimatedPricePerMonth} {readableBillingFrequencyValue}
                    </span>
                </div>
                <div className="flex mt-0.5">
                    <span className="text-sm text-text-secondary" data-cy="summary-users">
                        {selectedPlan.package.seats} user{selectedPlan.package.seats > 1 && 's'}
                    </span>
                </div>
                {!immediatePurchase && (
                    <div className="mt-[5px]">
                        <span className="text-sm text-text-secondary">New plan price starting {renewalAt}</span>
                    </div>
                )}
                <div className="py-[22px]">
                    <div className="border-t border-border" />
                </div>
                <div className="flex justify-between pb-[5px]">
                    <span className="text-sm">Total (charged today)</span>
                    <span className="text-base font-medium" data-cy="summary-total-price">
                        {totalAmountToChargeReadable}
                    </span>
                </div>
                <div className="flex justify-end items-center pb-[5px]">
                    <VatInformation vat={vatTotalReadable} customerCountry={subscription.customer.address.country} />
                </div>
                {couponCode && isCouponCodeValid && (
                    <span className="text-xs text-green-700 font-bold mb-[10px] ml-0">Discount applied</span>
                )}
                <div className="border-t pt-4 mt-2">
                    <TooltipProvider>
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <div className="w-full">
                                    <Button
                                        onClick={handlePurchase}
                                        className="w-full"
                                        disabled={
                                            !(hasPaymentMethod && hasBillingDetails) || loadingState === 'loading'
                                        }
                                    >
                                        {loadingState === 'loading' ? (
                                            <div className="flex items-center gap-2">
                                                <svg
                                                    className="animate-spin h-4 w-4"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <circle
                                                        className="opacity-25"
                                                        cx="12"
                                                        cy="12"
                                                        r="10"
                                                        stroke="currentColor"
                                                        strokeWidth="4"
                                                    ></circle>
                                                    <path
                                                        className="opacity-75"
                                                        fill="currentColor"
                                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                    ></path>
                                                </svg>
                                                Processing...
                                            </div>
                                        ) : (
                                            'Confirm Purchase'
                                        )}
                                    </Button>
                                </div>
                            </TooltipTrigger>
                            {!hasPaymentMethod || !hasBillingDetails ? (
                                <TooltipContent side="top" className="max-w-xs">
                                    {!hasPaymentMethod && !hasBillingDetails
                                        ? 'Please add both a payment method and billing details to proceed'
                                        : !hasPaymentMethod
                                        ? 'Please add a payment method to proceed'
                                        : 'Please add your billing details to proceed'}
                                </TooltipContent>
                            ) : null}
                        </Tooltip>
                    </TooltipProvider>
                    <Button
                        variant="outline"
                        onClick={onBack}
                        className="w-full mt-2"
                        disabled={loadingState === 'loading'}
                    >
                        Back
                    </Button>
                </div>
                <div className="py-7">
                    <span className="text-sm text-text-secondary">
                        Your payment will be {readableBillingFrequencyValue} auto-renewed unless cancelled before next
                        renewal date.
                    </span>
                </div>
            </div>
        </div>
    );
};
