import { useQuery } from '@apollo/client';
import { Box, ButtonBase, Typography } from '@mui/material';
import {
    //APPROVE_OUTREACH_SUGGESTION,
    GET_CONTACT_SUGGESTIONS,
} from '@zaplify/graphql';
import { Button, Notification } from '@zaplify/ui';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { ReactComponent as ProspectsFeedIllustration } from './components/prospects-feed-illustration.svg';
import { paths } from '../../routes/paths';
import { SparklesIcon } from 'lucide-react';

export const Component = () => {
    const { pathname } = useLocation();
    const { data, loading } = useQuery(GET_CONTACT_SUGGESTIONS);
    const [notifcation, setNotification] = useState<{
        text: string;
        severity: 'success' | 'error' | 'info' | 'warning';
    }>({ text: '', severity: 'success' });

    const numberOfSuggestions = data?.ContactSuggestions?.length || 0;

    return (
        <Box sx={{ overflow: 'hidden', height: '100%' }}>
            <Box
                sx={{
                    boxSizing: 'border-box',
                    padding: '16px',
                    gap: '16px',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    fontFamily:
                        '"Helvetica Neue", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu","Cantarell", "Fira Sans", "Droid Sans", sans-serif !important',
                }}
            >
                <Box sx={{ gap: '16px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography variant="h3" sx={{ fontSize: '24px' }}>
                        Discover
                    </Typography>
                    <Link to={paths.DISCOVER_PATHS.SUGGESTIONS}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: '8px',
                                border: '1px solid rgb(192, 200, 216)',
                                padding: '8px 12px',
                                borderRadius: '8px',
                                '&:hover': { backgroundColor: 'hsl(240 60% 90%)' },
                            }}
                        >
                            <SparklesIcon size={16} strokeWidth={1} />
                            <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: 500 }}>
                                Suggestions
                            </Typography>
                        </Box>
                    </Link>
                    {/* <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '16px',
                            '& button': {
                                fontFamily:
                                    '"Helvetica Neue", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu","Cantarell", "Fira Sans", "Droid Sans", sans-serif !important',
                                display: 'flex',
                                height: '32px',
                                padding: '4px 0px',
                                alignItems: 'center',
                                gap: 'var(--S-s-regular, 8px)',
                                borderBottom: '2px solid transparent',
                                fontWeight: 500,
                                fontSize: '15px',
                                transition: '200ms',
                                '&.active': {
                                    borderBottom: '2px solid var(--grey-palette-grey-6, #3A4B59)',
                                    color: '#3A4B59',
                                },
                                '&.inactive': {
                                    color: 'var(--grey-palette-grey-4, #969FAC)',
                                    '&:hover': {
                                        color: '#3A4B59',
                                    },
                                },
                            },
                        }}
                    >
                        <Link to={paths.DISCOVER_PATHS.SUGGESTIONS} style={{ textDecoration: 'none' }}>
                            <ButtonBase
                                className={pathname.includes('suggestions') ? 'active' : 'inactive'}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    gap: '6px',
                                }}
                            >
                                Suggestions
                                {!loading && (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            padding: 'var(--spacing-2, 2px) var(--spacing-3, 6px)',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            fontWeight: 400,
                                            gap: 'var(--spacing-0, 0px)',
                                            borderRadius: 'var(--radius-full, 360px)',
                                            background: 'var(--background-badge-gray, #E9EAEC)',
                                            color: 'var(--text-base-secondary, rgba(15, 19, 36, 0.60))',
                                        }}
                                    >
                                        {numberOfSuggestions}
                                    </Box>
                                )}
                            </ButtonBase>
                        </Link>
                        <Link to={paths.DISCOVER_PATHS.SEARCH} style={{ textDecoration: 'none' }}>
                            <ButtonBase className={pathname.includes('search') ? 'active' : 'inactive'}>
                                Filter Search
                            </ButtonBase>
                        </Link>
                    </Box> */}
                </Box>
                <Outlet />
                {Boolean(notifcation.text) && (
                    <Notification
                        open={Boolean(notifcation.text)}
                        onClose={() => setNotification({ ...notifcation, text: '' })}
                        text={notifcation.text}
                        severity={notifcation.severity}
                    />
                )}
            </Box>
        </Box>
    );
};

Component.displayName = 'ProspectingView';

export const EmptyProspectsPanel = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 'var(--M-m-spacious, 32px)',
                flex: '1 0 0',
                alignSelf: 'stretch',
                paddingTop: '50px',
                '& p': {
                    color: 'var(--grey-palette-grey-4, #6B7985)',
                    fontSize: 15,
                    fontWeight: 400,
                },
                '& button': {
                    boxShadow: 'none',
                    height: '48px',
                    borderRadius: '8px',
                    background: 'var(--primary-20-base, #6A76FB)',
                    '&:hover': {
                        boxShadow: 'none',
                    },
                },
                '& svg': {
                    width: '270px',
                },
            }}
        >
            <ProspectsFeedIllustration />
            <Box sx={{ gap: '8px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant="h3">Get a curated feed of prospects</Typography>
                <Typography>Add prospects to receive AI suggestions of potential leads</Typography>
            </Box>
        </Box>
    );
};
