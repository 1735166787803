export enum ChargebeePortalSection {
    PAYMENT_SOURCES = 'PAYMENT_SOURCES',
    BILLING_HISTORY = 'BILLING_HISTORY',
    ADDRESS = 'ADDRESS',
    ACCOUNT_DETAILS = 'ACCOUNT_DETAILS',
    EDIT_ACCOUNT_DETAILS = 'EDIT_ACCOUNT_DETAILS',
}

export enum PaymentStatus {
    NONE = 'none',
    PROCESSING = 'processing',
    SUCCESS = 'success',
    FAILED = 'failed',
    PROCESSING_FREE = 'processing_free',
}
