import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { Observable, getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { CachePersistor, LocalStorageWrapper } from 'apollo3-cache-persist';
import { auth } from '../services/firebase';
export enum GraphQLHeaders {
    HASURA_ROLE = 'x-hasura-role',
    HASURA_ORGANIZATION_ID = 'x-hasura-org-id',
}

export const createApolloClient = async (
    url: string = import.meta.env.VITE_HASURA_GRAPHQL_API_ENDPOINT,
    wsUrl: string = import.meta.env.VITE_HASURA_GRAPHQL_WS_ENDPOINT
) => {
    const getHeaders = async () => {
        const currentUser = auth.currentUser;
        const token = currentUser ? await currentUser.getIdToken() : null;
        return {
            authorization: `Bearer ${token}`,
        };
    };
    const wsLink = new GraphQLWsLink(
        createClient({
            url: wsUrl,
            connectionParams: async () => {
                try {
                    console.log('running connectionParams');
                    const headers = await getHeaders();
                    return { headers };
                } catch (error) {
                    console.error('[GraphQL WebSocket] Connection error:', error);
                    throw error;
                }
            },
            on: {
                error: (error) => {
                    console.error('[GraphQL WebSocket on Error Handler] Error:', error);
                },
            },
            retryAttempts: 5,
            retryWait: async (retryCount) => {
                console.warn('[GraphQL WebSocket] Reconnecting...', retryCount);
                return new Promise((resolve) => setTimeout(resolve, Math.min(1000 * 2 ** retryCount, 30000)));
            },
        })
    );

    const authLink = new ApolloLink((operation, forward) => {
        return new Observable((observer) => {
            const ensureToken = async () => {
                const authHeaders = await getHeaders();
                if (authHeaders.authorization?.length < 10) {
                    console.error('No authorization header could be generated');
                    return;
                }

                operation.setContext(({ headers = {} }) => ({
                    headers: {
                        ...headers,
                        ...authHeaders,
                    },
                }));

                forward(operation).subscribe({
                    next: observer.next.bind(observer),
                    error: observer.error.bind(observer),
                    complete: observer.complete.bind(observer),
                });
            };
            ensureToken();
        });
    });

    const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
        if (graphQLErrors) {
            console.error(`[GraphQL auth client error]:`, operation.operationName, operation.variables, graphQLErrors);
            // get a new token
        }
        if (networkError) {
            console.error(`[GraphQL Network error]: ${networkError}`);
        }
    });

    const httpLink = new HttpLink({
        uri: url,
    });

    const splitLink = ApolloLink.split(
        ({ query }) => {
            const definition = getMainDefinition(query);
            return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
        },
        wsLink,
        httpLink
    );

    const link = ApolloLink.from([errorLink, authLink, splitLink]);

    const cache = new InMemoryCache();
    // const persistor = new CachePersistor({
    //     cache,
    //     storage: new LocalStorageWrapper(window.localStorage),
    //     debug: import.meta.env.DEV,
    //     trigger: 'write',
    //     maxSize: 12582912,
    //     debounce: 1000,
    // });
    // console.log('restoring cache');
    // // await persistor.restore().catch((error) => {
    // //     console.error('Error restoring cache', error);
    // // });
    // console.log('cache restored');

    const client = new ApolloClient({
        link: link,
        cache,
    });

    return {
        client,
        // persistor,
        clearCache: async () => {
            console.log('clearing cache');
            // await persistor.purge();
            await client.clearStore();
        },
        resetCache: async () => {
            try {
                await client.resetStore();
            } catch (e) {
                console.error('Error resetting the cache:', e);
            }
        },
    };
};
