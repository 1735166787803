import { BaseSdk } from '../../base.sdk';
import {
    CompletedTaskDataDto,
    GetTasksDto,
    RecurringTaskDto,
    RecurringTaskStatus,
    TaskName,
    SyncTaskStatus,
} from '@zaplify/services/data-source-sync/shared';

export class DataSourcesSyncSdk extends BaseSdk {
    async getTasks(workerId: string) {
        return await this.get<GetTasksDto>(`${this.dataSourcesSyncPath}/tasks`, {
            headers: {
                'x-worker-id': workerId,
            },
        });
    }

    async startTask(workerId: string, taskName: TaskName) {
        return await this.post<RecurringTaskDto>({
            path: `${this.dataSourcesSyncPath}/recurring/task/start`,
            payload: { taskName },
            headers: {
                'x-worker-id': workerId,
            },
        });
    }

    async completeTask(data: CompletedTaskDataDto, workerId: string) {
        return await this.post<void>({
            path: `${this.dataSourcesSyncPath}/recurring/task/complete`,
            payload: data,
            headers: {
                'x-worker-id': workerId,
            },
        });
    }

    async startFetchProfileTask(workerId: string, memberId: string) {
        return await this.post<void>({
            path: `${this.dataSourcesSyncPath}/fetch-profile/task/start`,
            payload: { memberId },
            headers: { 'x-worker-id': workerId },
        });
    }

    async completeFetchProfileTask(workerId: string, memberId: string, taskData: CompletedTaskDataDto) {
        return await this.post<void>({
            path: `${this.dataSourcesSyncPath}/fetch-profile/task/complete`,
            payload: { memberId, taskData },
            headers: { 'x-worker-id': workerId },
        });
    }

    async startSyncTask(workerId: string, taskId: string) {
        return await this.post<void>({
            path: `${this.dataSourcesSyncPath}/sync-task/start`,
            payload: { taskId },
            headers: { 'x-worker-id': workerId },
        });
    }

    async completeSyncTask(workerId: string, taskId: string, taskStatus: SyncTaskStatus, rawResult: any) {
        return await this.post<void>({
            path: `${this.dataSourcesSyncPath}/sync-task/complete`,
            payload: { taskId, taskStatus, rawResult },
            headers: { 'x-worker-id': workerId },
        });
    }
}
