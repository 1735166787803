import { getZaplifySdk } from '@zaplify/sdk';
import { createMutationOptions } from './sdk-utils';
import { LinkedinUserActivitiesDto } from '@zaplify/campaigns';
import { ProspectDataDto } from '@zaplify/prospects';
import { ProspectDto } from '@zaplify/prospects';
import { MutationOptions } from '@tanstack/react-query';

export const setupMessenger = () => {
    return createMutationOptions<{ playbookId: string; assistantSettingsId: string }, Error, { language?: string }>({
        mutationFn: (variables) => getZaplifySdk().profiles.campaigns.setupMessenger(variables.language),
        invalidateQueries: () => [],
    });
};

export const importLinkedinAnalysis = (
    options?: Omit<
        MutationOptions<
            ProspectDto[],
            Error,
            { profiles: { profile: ProspectDataDto; activities: LinkedinUserActivitiesDto }[]; assistantId: string }
        >,
        'mutationFn'
    >
) => {
    return createMutationOptions<
        ProspectDto[],
        Error,
        { profiles: { profile: ProspectDataDto; activities: LinkedinUserActivitiesDto }[]; assistantId: string }
    >({
        mutationFn: (variables) => getZaplifySdk().profiles.messenger.importLinkedinAnalysis(variables),
        invalidateQueries: () => [],
        ...options,
    });
};
