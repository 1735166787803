import { useQuery } from '@apollo/client';
import { TextEditor } from './text-editor';
import { useMemo, useState } from 'react';
import { GET_ALL_PROSPECT_SUBJECT_LINES } from '@zaplify/graphql';
import { useParams } from 'react-router-dom';
import { ChannelSelectDropdown } from './channel-select-dropdown';
import { SendButton } from './send-button';
import { MessageComposerInfoBox } from './info-box/message-composer-info-box';
import { useMessageComposer } from '../../../../hooks/messaging/use-message-composer';
import { RegenerateMessageButton } from './regenerate-message-button';
import { Popover, PopoverAnchor, PopoverTrigger } from '@shadcn/ui/components/ui/popover';
import { RegenerateMessagePopoverContent } from './regenerate-message-popover-content';
import { cn } from '@shadcn/ui/lib/utils';

interface MessageComposerProps {
    size: 'large' | 'small';
    bigOnFocus?: boolean;
    intercomAdjustedSendButton?: boolean;
    className?: string;
    disabled?: boolean;
}

export const MessageComposer: React.FC<MessageComposerProps> = ({
    size,
    intercomAdjustedSendButton,
    bigOnFocus,
    className,
    disabled,
}) => {
    const {
        prospectId,
        body,
        handleBodyTextChange,
        handleSubjectLineChange,
        subjectLine,
        messageType,
        isGeneratingMessage,
        isSendable,
        messageComposerPrimaryAction,
        isSending,
        isStreaming,
    } = useMessageComposer();
    const [regeneratePopoverOpen, setRegeneratePopoverOpen] = useState(false);
    const { data, loading, error } = useQuery(GET_ALL_PROSPECT_SUBJECT_LINES, {
        variables: {
            prospectId: prospectId,
        },
        skip: !prospectId,
    });
    const subjectLines = useMemo(
        () =>
            Array.from(
                data?.Messages ? new Set(data?.Messages.map((message) => message.subjectLine).filter((s) => s)) : []
            ),
        [data]
    );

    const handleKeyDown = (event: KeyboardEvent) => {
        if ((event.ctrlKey || event.metaKey) && event.key === 'Enter' && isSendable) {
            event.preventDefault();
            event.stopPropagation();
            messageComposerPrimaryAction();
        }
    };

    return (
        <>
            <Popover open={regeneratePopoverOpen} onOpenChange={setRegeneratePopoverOpen}>
                <PopoverAnchor className={cn('w-full', className)}>
                    <MessageComposerInfoBox />
                    <TextEditor
                        selectedMessageType={messageType}
                        content={body}
                        onBodyTextChange={handleBodyTextChange}
                        isGenerating={isGeneratingMessage}
                        isStreaming={isStreaming}
                        isSending={isSending}
                        isEditable={!disabled}
                        subjectLine={subjectLine}
                        allSubjectLines={subjectLines}
                        onKeyDown={(e) => {
                            handleKeyDown(e.nativeEvent);
                        }}
                        size={size}
                        bigOnFocus={bigOnFocus}
                        onSubjectLineChange={handleSubjectLineChange}
                        isLoadingSubjectLines={loading}
                        topActions={<ChannelSelectDropdown />}
                        bottomLeftActions={<></>}
                        intercomAdjustedSendButton={intercomAdjustedSendButton}
                        bottomRightActions={
                            <>
                                <PopoverTrigger asChild className="editor-regenerate-button">
                                    <RegenerateMessageButton />
                                </PopoverTrigger>
                                <SendButton disabled={disabled} />
                            </>
                        }
                    />
                </PopoverAnchor>
                <RegenerateMessagePopoverContent onClose={() => setRegeneratePopoverOpen(false)} />
            </Popover>
        </>
    );
};
