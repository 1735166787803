import EditorContent from './editor-content';
import { EmojisPicker } from './emojis-picker';
import LinkEditMenu from './link-edit-menu';
import { ToolbarMenu } from './toolbar-menu';
import './text-editor.css';
import { useTextEditor } from '../../../../hooks/messaging/text-editor/use-text-editor';
import { cn } from '@shadcn/ui/lib/utils';
import { AutoComplete } from '@shadcn/ui/components/autocomplete';
import { MessageType } from '../../../../types/message-type';
import { useIsMobile } from '@shadcn/ui/hooks/use-mobile';
import { useSidebars } from '@shadcn/ui/hooks/use-sidebars';
import { useState } from 'react';

const getPlaceholderText = (isEditable: boolean, isNote: boolean) => {
    if (!isEditable) {
        return 'Generating message...';
    } else if (isNote) {
        return 'Save information for yourself and the assistant to keep track and write better messages';
    } else {
        return 'Start typing...';
    }
};

interface TextEditorProps {
    selectedMessageType: MessageType;
    content: string;
    topActions?: React.ReactNode;
    bottomLeftActions?: React.ReactNode;
    bottomRightActions?: React.ReactNode;
    subjectLine: string;
    allSubjectLines: string[];
    onBodyTextChange: (bodyText: string) => void;
    onSubjectLineChange: (subjectLine: string) => void;
    isStreaming?: boolean;
    isSending: boolean;
    isEditable?: boolean;
    onKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => void;
    isLoadingSubjectLines?: boolean;
    isGenerating?: boolean;
    size: 'large' | 'small';
    intercomAdjustedSendButton?: boolean;
    bigOnFocus?: boolean;
}

export const TextEditor = ({
    selectedMessageType,
    content,
    topActions,
    bottomLeftActions,
    bottomRightActions,
    subjectLine,
    allSubjectLines,
    onBodyTextChange,
    onSubjectLineChange,
    isStreaming,
    isSending,
    isEditable = true,
    onKeyDown,
    isLoadingSubjectLines,
    isGenerating = false,
    size,
    intercomAdjustedSendButton,
    bigOnFocus,
}: TextEditorProps) => {
    const displayProspectsPanel = true;
    const subjectlinePlaceholder = selectedMessageType === MessageType.note ? "What's the note about?" : '';
    const editorPlaceholder = getPlaceholderText(isEditable, selectedMessageType === MessageType.note);
    const isMobile = useIsMobile();
    const { sidebarState } = useSidebars();
    const contactDetailsSidePanelOpen = sidebarState?.details;
    const [isBigOnFocus, setIsBigOnFocus] = useState(false);

    const { editor, openLinkPopover, closeLinkPopover, linkState, handleLinkChange, setLink, isRunningStreaming } =
        useTextEditor({
            content,
            onBodyTextChange,
            type: selectedMessageType === MessageType.linkedinMessage ? 'plain' : 'html',
            isEditable,
            placeholder: editorPlaceholder,
            isStreaming,
            isSending,
        });

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            setLink();
        }
    };

    const handleFocus = () => {
        if (bigOnFocus) {
            setIsBigOnFocus(true);
        }
    };

    const handleBlur = () => {
        if (bigOnFocus) {
            setIsBigOnFocus(false);
        }
    };

    const options = allSubjectLines.map((subjectLine) => ({ value: subjectLine, label: subjectLine }));

    if (!editor) return null;

    const shouldShowToolbarMenu = selectedMessageType === MessageType.emailMessage;

    return (
        <div
            className="text-editor-container w-full sticky z-[20]"
            onKeyDown={onKeyDown}
            onFocus={handleFocus}
            onBlur={handleBlur}
        >
            <div
                className={cn(
                    'flex flex-col border md:rounded-xl shadow-md',
                    isRunningStreaming ? 'animate-pulsate-border-brand' : '',
                    selectedMessageType === MessageType.note
                        ? 'bg-background-note border-border-note'
                        : 'bg-background-primary border-border-secondary',
                    displayProspectsPanel ? '-5px 24px 24px 24px' : '-5px 48px 24px 24px', //leave room for intercom button when no prospects panel
                    isGenerating ? 'animate-pulsate-border-brand' : ''
                )}
            >
                <div className="top-actions flex w-full min-w-min mt-1 px-3">{topActions}</div>

                {selectedMessageType === MessageType.emailMessage && (
                    <>
                        <div className={cn('subject-line flex gap-2 pt-1 pl-4 pr-4 pb-2 w-full border-b')}>
                            <p className="text-text-tertiary">Subject:</p>
                            <AutoComplete
                                emptyMessage="There are no previous subject lines."
                                options={options}
                                onValueChange={(option) => onSubjectLineChange(option.value)}
                                value={{ value: subjectLine, label: subjectLine }}
                                className="w-full"
                                isLoading={isLoadingSubjectLines}
                                allowCustomValue
                            />
                        </div>
                    </>
                )}
                <EditorContent editor={editor} size={isBigOnFocus ? 'large' : size} />
                <div
                    className={cn('bottom-section flex justify-between min-w-min px-4 pb-4 py-4', {
                        'pr-8': isMobile && intercomAdjustedSendButton,
                    })}
                >
                    {[
                        MessageType.emailMessage,
                        MessageType.linkedinConnectionRequest,
                        MessageType.linkedinMessage,
                        MessageType.note,
                    ].includes(selectedMessageType) && (
                        <>
                            <div className="editor-options flex gap-4">
                                <EmojisPicker editor={editor} className="z-[1000]" />
                                {bottomLeftActions}
                            </div>
                            <div
                                className={cn('actions-options flex gap-2', {
                                    'mr-8': !contactDetailsSidePanelOpen && intercomAdjustedSendButton,
                                })}
                            >
                                {bottomRightActions}
                            </div>
                        </>
                    )}
                </div>
                <ToolbarMenu
                    editor={editor}
                    openLinkPopover={openLinkPopover}
                    shouldShowToolbarMenu={shouldShowToolbarMenu}
                />
                <LinkEditMenu
                    editor={editor}
                    linkState={linkState}
                    closeLinkPopover={closeLinkPopover}
                    handleLinkChange={handleLinkChange}
                    handleKeyDown={handleKeyDown}
                />
            </div>
        </div>
    );
};
