import { FC, useState } from 'react';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@shadcn/ui/components/ui/dialog';
import { Button } from '@shadcn/ui/components/ui/button';
import { Textarea } from '@shadcn/ui/components/ui/textarea';
import { Label } from '@shadcn/ui/components/ui/label';
import { UserRole } from '@zaplify/users/shared';
import { RadioGroup, RadioGroupItem } from '@shadcn/ui/components/ui/radio-group';

interface InviteUsersDialogProps {
    isOpen: boolean;
    onClose: () => void;
    onInvite: (emails: string[], role: UserRole) => void;
}

export const InviteUsersDialog: FC<InviteUsersDialogProps> = ({ isOpen, onClose, onInvite }) => {
    const [emails, setEmails] = useState('');
    const [role, setRole] = useState<UserRole>(UserRole.ORGANIZATION_MEMBER);

    const handleInvite = () => {
        const emailList = emails
            .split(/[\n,]/)
            .map((email) => email.trim())
            .filter((email) => email.length > 0);
        onInvite(emailList, role);
        setEmails('');
        setRole(UserRole.ORGANIZATION_MEMBER);
    };

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>Invite Members</DialogTitle>
                    <DialogDescription>
                        Enter email addresses of the people you want to invite. Separate multiple emails with commas or
                        new lines.
                    </DialogDescription>
                </DialogHeader>
                <div className="grid gap-4 py-4">
                    <div className="grid gap-2">
                        <Label htmlFor="emails">Email addresses</Label>
                        <Textarea
                            id="emails"
                            value={emails}
                            onChange={(e) => setEmails(e.target.value)}
                            placeholder="john@example.com, jane@example.com"
                            className="h-[120px]"
                        />
                    </div>
                    <div className="grid gap-2">
                        <Label>Role</Label>
                        <RadioGroup value={role} onValueChange={(value) => setRole(value as UserRole)}>
                            <div className="flex items-center space-x-2">
                                <RadioGroupItem value={UserRole.ORGANIZATION_MEMBER} id="member" />
                                <Label htmlFor="member">Member</Label>
                            </div>
                            <div className="flex items-center space-x-2">
                                <RadioGroupItem value={UserRole.ORGANIZATION_ADMIN} id="admin" />
                                <Label htmlFor="admin">Admin</Label>
                            </div>
                        </RadioGroup>
                    </div>
                </div>
                <DialogFooter>
                    <Button variant="outline" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button onClick={handleInvite}>Send Invites</Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};
