import { combineReducers } from '@reduxjs/toolkit';
import { hubspotReducer } from '../../redux/ducks/hubspot/slice';
import { pipedriveReducer } from '../../redux/ducks/pipedrive/slice';
import { salesforceReducer } from '../../redux/ducks/salesforce/slice';
import { upsalesReducer } from '../../redux/ducks/upsales/slice';
import { taskReducer } from '../../redux/ducks/task/slice';
import { authStates } from '../authStates';
import { outreachReducer } from '../ducks/outreach/slice';
import { prospectSearchReducer } from '../ducks/prospect-search-filters';
import prospectSearchResultSlice from '../ducks/prospect-search-result/slice';
import prospectsSlice from '../ducks/prospects/slice';
import campaignReducer from './campaigns';
import importProspectsReducer from './newCampaign/importProspects';
import newCampaignReducer from './newCampaign/main';
import uiReducer from './ui';
import userReducer from './user';
import userOrganizationReducer from './userOrganization';
import socketClientReducer from './utils/socketClient';
import webhooksReducer from './webhooks';

const appReducer = combineReducers({
    campaigns: campaignReducer,
    importProspects: importProspectsReducer,
    newCampaign: newCampaignReducer,
    hubspot: hubspotReducer,
    outreach: outreachReducer,
    pipedrive: pipedriveReducer,
    upsales: upsalesReducer,
    prospects: prospectsSlice,
    prospectSearchFilters: prospectSearchReducer,
    prospectSearchResult: prospectSearchResultSlice,
    socketClient: socketClientReducer,
    // statistics: statisticsReducer,
    salesforce: salesforceReducer,
    ui: uiReducer,
    user: userReducer,
    userOrganization: userOrganizationReducer,
    webhooks: webhooksReducer,
    task: taskReducer,
});

const rootReducer = (state, action) => {
    if (action.type === 'RESET_STORE') {
        state = {
            user: {
                zaplifyUser: {},
                firebaseUser: {},
                currentState: authStates.LOGGED_OUT,
                authErrorMessage: null,
            },
        };
    }

    return appReducer(state, action);
};

export default rootReducer;
