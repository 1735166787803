import { Route } from 'react-router-dom';
import { DefaultTemplate } from '../../templates/default';
import { paths } from '../paths';
import { Component as Auth } from '../../views/auth/auth';
import { Component as NotFound } from '../../views/notFound';
import { Component as ScheduleOnboardingView } from '../../views/onboarding/scheduleOnboardingView';
import { Component as JoinOrganization } from '../../views/JoinOrganization';
import { OnboardingTemplate } from '../../templates/onboarding';

export const legacyPublicRoutes = (
    <>
        <Route element={<OnboardingTemplate />}>
            <Route path={paths.OLD_JOIN_ORGANIZATION} element={<JoinOrganization />} />
            <Route path={paths.OLD_SCHEDULE_ONBOARDING} element={<ScheduleOnboardingView />} />
        </Route>
    </>
);
