import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { MouseEvent } from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

interface GuardedLinkProps extends RouterLinkProps {
    to: string;
}

export const GuardedLink: React.FC<GuardedLinkProps> = ({ to, ...props }) => {
    const { externalLinkWarning } = useFlags();
    const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
        try {
            const url = new URL(to, window.location.origin);
            if (url.hostname !== window.location.hostname && externalLinkWarning) {
                const userConfirmed = window.confirm('You will be redirected to an external resource. Continue?');
                if (!userConfirmed) {
                    event.preventDefault();
                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    return <RouterLink to={to} {...props} onClick={handleClick} />;
};
