import { RequireAtLeastOne } from '@zaplify/utils';
import { BaseSdk } from '../../base.sdk';
import { ProspectDataDto } from '@zaplify/prospects';
import { LinkedinProfileDto } from '@andsend/services/linkedin-profiles/shared';

export class ContactsSuggestionsSdk extends BaseSdk {
    async approveSuggestion(suggestionsId: string, destination?: RequireAtLeastOne<{ groupId: string }>) {
        return await this.post<string>({
            path: `${this.contactSuggestionsPath}/${suggestionsId}/approve`,
            payload: { groupId: destination?.groupId },
        });
    }

    async rejectSuggestion(suggestionsId: string): Promise<void> {
        return await this.post<void>({
            path: `${this.contactSuggestionsPath}/${suggestionsId}/reject`,
        });
    }

    async createSuggestion(data: ProspectDataDto): Promise<void> {
        return await this.post<void>({
            path: `${this.contactSuggestionsPath}`,
            payload: { data },
        });
    }

    async getRecommendationsForPurpose(purpose: string, targetGroup: string): Promise<LinkedinProfileDto[]> {
        const response = await this.get<LinkedinProfileDto[]>(
            `${this.contactSuggestionsPath}/purpose/recommendations`,
            {
                params: { purpose, targetGroup },
            },
        );
        return response || [];
    }
}
