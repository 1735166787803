import { FC } from 'react';
import React from 'react';
import { Template } from '../../hooks/use-playbook-templates';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../../../../routes/paths';
interface CreatePlaybookCardProps {
    imageSrc: string;
    title: string;
    description: string;
    template: Template;
}

export const PlaybookTemplateCard: FC<CreatePlaybookCardProps> = React.forwardRef<
    HTMLDivElement,
    CreatePlaybookCardProps
>(({ imageSrc, title, description, template, ...props }, ref) => {
    const navigate = useNavigate();
    return (
        <div
            ref={ref}
            {...props}
            onClick={() => navigate('/new' + paths.PLAYBOOKS_PATHS.NEW + '?template=' + template)}
            className="flex flex-col rounded-2xl items-center w-60 cursor-pointer"
        >
            <div className="overflow-hidden rounded-t-2xl w-full">
                <img src={imageSrc} alt="playbook" className="w-full h-40 object-fill rounded-t-2xl scale-105" />
            </div>
            <div className="flex flex-col p-4 border rounded-b-2xl max-w-full w-full sm:min-h-28 xl:min-h-24 justify-start">
                <h3 className="font-medium text-sm">{title}</h3>
                <span className="text-sm font-normal text-text-tertiary">{description}</span>
            </div>
        </div>
    );
});
