import { HubspotCompanyDetailsPreviewDto, UpdateSettingsDTO } from '@zaplify/hubspot';
import { BaseSdk } from '../../../base.sdk';

export class HubspotSdk extends BaseSdk {
    async getSettings(organizationId: string) {
        return this.get<any>(`${this.appsPath}/${this.hubspotPath}/settings?organizationId=${organizationId}`);
    }

    async getCompanyDetailsPreview(
        organizationId: string,
        companyId: string,
    ): Promise<HubspotCompanyDetailsPreviewDto | null> {
        return this.get<HubspotCompanyDetailsPreviewDto>(
            `${this.appsPath}/${this.hubspotPath}/company-details-preview?organizationId=${organizationId}&companyId=${companyId}`,
        );
    }

    async bulkSyncCompanies(personsByCompanies: { [key: string]: string[] }) {
        return this.post<void>({
            path: `${this.appsPath}/${this.hubspotPath}/bulk-sync-companies`,
            payload: personsByCompanies,
        });
    }

    async updateSettings(updateSettingsDTO: UpdateSettingsDTO, errorCallback: (error: any) => void) {
        return this.put<void>(`${this.appsPath}/${this.hubspotPath}/settings`, updateSettingsDTO, errorCallback);
    }

    async connect(organizationId: string, code: string) {
        return this.post<void>({
            path: `${this.appsPath}/${this.hubspotPath}/connect`,
            payload: { organizationId: organizationId, code: code },
        });
    }

    async disconnect(organizationId: string) {
        return this.post<void>({
            path: `${this.appsPath}/${this.hubspotPath}/disconnect`,
            payload: { organizationId: organizationId },
        });
    }

    async pause(organizationId: string) {
        return this.post<void>({
            path: `${this.appsPath}/${this.hubspotPath}/pause`,
            payload: { organizationId: organizationId },
        });
    }

    async resume(organizationId: string) {
        return this.post<void>({
            path: `${this.appsPath}/${this.hubspotPath}/resume`,
            payload: { organizationId: organizationId },
        });
    }

    async getAllFields(organizationId: string) {
        return this.get<any>(`${this.appsPath}/${this.hubspotPath}/mapping/fields?organizationId=${organizationId}`);
    }

    async getChannelAccounts(userId: string) {
        return this.get<any[]>(`${this.usersPath}/${userId}/channel-accounts`);
    }

    async migrateCampaign(campaignId: string) {
        return this.post<any[]>({ path: `${this.appsPath}/${this.hubspotPath}/contacts/fromCampaign/${campaignId}` });
    }
}
