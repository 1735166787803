import { Errors, ProspectHits, ProspectSearchResultStateType } from './types';

export const prospectSearchResultInitialState: ProspectSearchResultStateType = {
    prospectsInCompanies: [],
    prospectTransactionState: {},
    detailedProspects: {},
    selected: {},
    syncingProspects: {},
    hubspotRequestedProspects: {},
    pipedriveRequestedProspects: {},
    salesforceRequestedProspects: {},
    upsalesRequestedProspects: {},
    webhookRequestedProspects: {},
    prospectsSyncedHubspot: {},
    prospectsSyncedPipedrive: {},
    prospectsSyncedSalesforce: {},
    prospectsSyncedUpsales: {},
    hubspotSyncedCompanies: {},
    pipedriveSyncedCompanies: {},
    salesforceSyncedCompanies: {},
    upsalesSyncedCompanies: {},
    prospectsSyncedWebhooks: {},
    webhooksSyncedCompanies: {},
    prospectsCount: 0,
    hits: {} as ProspectHits,
    prospectDrawerProspect: null,
    prospectDrawerState: false,
    loading: false,
    loadingHits: false,
    loadingMore: false,
    loadingExtendedSelection: false,
    extendProspectsInCompanyLoading: undefined,
    hasMoreProspects: {},
    purchasing: false,
    purchasingToOtherCampaign: false,
    allProspectsAdded: false,
    errors: {} as Errors,
    page: 1,
    allProspectsExtended: {},
    loadingAIAssistant: false,
    loadingFeed: false,
};
