import { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux-latest';
import { getAuth, User } from 'firebase/auth';
import { AppDispatch, RootState } from '../../redux/store/configureStore';
import { setUserAction, setChannelAccountsAction, updateSubscription } from '../../redux/actions';
import { LocalStorageKeys } from '../../config';
import { getZaplifySdk } from '@zaplify/sdk';
import { UserComposedDto } from '@zaplify/users/shared';
import { GroupDto } from '@zaplify/services/user-contacts/shared';
import { useSeatsAndCredits } from '../../new/hooks/use-seat-and-credits';
import { LoaderView } from '../../views/loader';
import { SubscriptionDto } from '@zaplify/subscriptions';
import { useSdk } from '../../new/sdk';
import { useAuth as useAuthNew } from '../../new/providers/authentication-provider';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ChannelAccountDto, ChannelType } from '@zaplify/channel-accounts/shared';

class OnboardingState {
    hasCompletedOnboarding: boolean;
    isImpersonating: boolean;
    hasLinkedInConnected: boolean;

    constructor({
        groups,
        isImpersonating,
        channelAccounts,
    }: {
        groups?: GroupDto[];
        isImpersonating?: boolean;
        channelAccounts?: ChannelAccountDto[];
    }) {
        this.isImpersonating = isImpersonating;
        this.hasCompletedOnboarding = groups?.length > 0;
        this.hasLinkedInConnected = channelAccounts.some((account) => account.type === ChannelType.LINKEDIN);
    }
}

interface IAuthState {
    isLoggedIn: boolean;
    isEmailVerified: boolean;
    hasSelectedUser: boolean;
    nbrOfUserAccounts: number;
    nbrOfInvitations: number;
    userId: string;
    userOrganizationId: string;
    user: UserComposedDto;
    onboardingState: OnboardingState;
}

interface AuthContextType {
    authState: IAuthState;
    loading: boolean;
    login: (email: string, password: string) => void;
    logout: () => void;
    refreshAuthToken: () => Promise<string> | string;
    refreshOnboardingState: () => Promise<void>;
    subscription: SubscriptionDto | null;
    // loadSubscription: () => Promise<void>;
}

const authStateDefault: IAuthState = {
    isLoggedIn: false,
    isEmailVerified: false,
    hasSelectedUser: false,
    nbrOfUserAccounts: 0,
    nbrOfInvitations: 0,
    userId: '',
    userOrganizationId: '',
    onboardingState: null,
    user: null,
};

const AuthContext = createContext<AuthContextType>({
    authState: authStateDefault,
    loading: true,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    login: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    logout: () => {},
    refreshAuthToken: () => '',
    refreshOnboardingState: async () => {},
    subscription: null,
    // loadSubscription: async () => {},
});

export const useAuth = () => useContext(AuthContext);

export const AuthorizationProvider = ({ children }: { children?: ReactNode }) => {
    const [isPageLoading, setIsPageLoading] = useState(true);
    const allAuthState = useAuthNew();
    const dispatch = useDispatch<AppDispatch>();

    const {
        channelAccount: { getMyChannelAccounts },
    } = useSdk();
    const tanstackQueryClient = useQueryClient();

    const [authUser, setAuthUser] = useState<User | null>(null);
    const [authState, setAuthState] = useState<IAuthState>(authStateDefault);

    const zaplifySdk = getZaplifySdk();
    const { refetch: refetchSeatsAndCredits, seat: { subscription } = {} } = useSeatsAndCredits();
    const preventRedirectToLoginFromRedux = useSelector((state: RootState) => state.user.preventRedirectToLogin);
    const preventRedirectToLogin = useRef();
    preventRedirectToLogin.current = preventRedirectToLoginFromRedux;

    // const notifyInvitationClicked = async ({
    //     invitationId,
    //     securityToken,
    // }: {
    //     invitationId?: string;
    //     securityToken?: string;
    // }) => {
    //     try {
    //         if (securityToken && invitationId) {
    //             await zaplifySdk.profiles.user.notifyInvitationClicked(invitationId, securityToken);
    //         }
    //     } catch (error) {
    //         console.error('Error while claiming invitation', error);
    //         dispatch(
    //             fetchUserError(
    //                 'We encountered an issue while accepting this invitation. Please try again or contact support if the problem persists.'
    //             )
    //         );
    //     }
    // };

    // const getAccountsAndInvitations = async (): Promise<{
    //     accounts: IUserAccount[];
    //     invitations: IInvitationReduced[];
    // }> => {
    //     try {
    //         const accountsAndInvitations = await getZaplifySdk().profiles.user.getUserAccounts();
    //         return {
    //             accounts: accountsAndInvitations?.users || [],
    //             invitations: accountsAndInvitations?.invitations || [],
    //         };
    //     } catch (err) {
    //         return {
    //             accounts: [],
    //             invitations: [],
    //         };
    //     }
    // };

    const loadSubscription = async () => {
        // @TODO Replace "seat" with just looking at the seatId from the auth token around the app in places where we need the seat (eg. when showing the no seats modal)
        // After doing that we can stop fetching seats as a blocking call here
        await refetchSeatsAndCredits().catch(null);
        // @TODO Move this legacy code of special fetching into the app / providers in lower layers. Shouldn't be triggered from here. Ideally, this auth provider should only handle redirection/navigation based on state.
        dispatch(updateSubscription());
    };

    const loadUser = async () => {
        const [zaplifyUser, groups, isImpersonating, channelAccounts] = await Promise.all([
            allAuthState.authState.user,
            zaplifySdk.profiles.groups.getGroups().catch(() => []),
            allAuthState.authState.isImpersonating,
            tanstackQueryClient.fetchQuery(getMyChannelAccounts()).catch(() => []),
        ]);
        const onboardingState = new OnboardingState({
            groups,
            isImpersonating,
            channelAccounts,
        });
        if (zaplifyUser) {
            // setUser(zaplifyUser);
            // @TODO Deprecate redux
            dispatch(setUserAction(zaplifyUser));
            dispatch(setChannelAccountsAction(channelAccounts));
        }

        return { onboardingState, user: zaplifyUser };
    };

    const logout = async () => {
        await getAuth().signOut();
        window?.analytics?.track('User Signed Out', {});
        Object.keys(LocalStorageKeys).forEach((key) => localStorage.removeItem(LocalStorageKeys[key]));
    };

    const loadAuthState = async (_authUser?: User | null | undefined, options?: { excludeSubscription: boolean }) => {
        // Default to empty states if there is no auth user
        if (!_authUser) {
            setAuthUser(null);
            setAuthState(authStateDefault);
            return;
        }

        if (_authUser !== authUser) {
            setAuthUser(_authUser);
        }

        // // Load user and app
        // const tokenResult = await _authUser.getIdTokenResult();
        // const { accounts, invitations } = await getAccountsAndInvitations();
        // // console.log('onIdTokenChanged: triggered with user', {
        // //     fbToken,
        // //     authUser,
        // // });
        // const currentAuthUserClaims = tokenResult?.claims || {};
        // const authUserId = currentAuthUserClaims.authUserId || _authUser.uid;

        // const selectedUserId = currentAuthUserClaims.userId;
        const { user, onboardingState } = await loadUser();
        // @TODO Remove load subscription from authorization provider – should be ok to just trust the plan and seat on the JWT token
        if (allAuthState.authState.user && !options?.excludeSubscription) {
            await loadSubscription();
        }

        setAuthState(allAuthState.authState);
    };

    // useEffect(() => {
    //     const unsubscribe = getFirebase()
    //         .auth()
    //         .onIdTokenChanged(async (fbUser) => {
    //             try {
    //                 // dispatch(startLoading());
    //                 // Handle invitation
    //                 // @TODO Consider moving invitation logic to different place. It's not really part of authorizing the user
    //                 const securityToken = localStorage.getItem(LocalStorageKeys.INVITATION_SECURITY_TOKEN);
    //                 const invitationId = localStorage.getItem(LocalStorageKeys.INVITATION_ID);
    //                 if (invitationId && securityToken) {
    //                     await notifyInvitationClicked({ invitationId, securityToken });
    //                 }
    //                 await loadAuthState(fbUser);
    //             } catch (error) {
    //                 console.error('Error while fetching user data', error);
    //                 dispatch(
    //                     fetchUserError(
    //                         'Oops, something went wrong while fetching your user. Please try again or contact support if the problem persists.'
    //                     )
    //                 );
    //                 // @TODO Display some error page (like with a refresh button or something) instead that actually helps the user
    //             } finally {
    //                 setLoading(false);
    //                 // dispatch(stopLoading());
    //             }
    //         });
    //     return () => unsubscribe();
    // }, []);

    useEffect(() => {
        console.log('useEffect authorization provider runs');
        if (!allAuthState.loading) {
            loadAuthState(getAuth().currentUser)
                .catch((e) => {
                    console.error('Error while loading auth state in authorization provider', e);
                })
                .finally(() => {
                    setIsPageLoading(false);
                });
        }
    }, [allAuthState.loading]);

    return (
        <AuthContext.Provider
            value={{
                // @TODO Maybe split authState into eg. user, authState and onboardingState
                authState,
                loading: allAuthState.loading,
                login: () => {},
                logout,
                refreshAuthToken: () => '{}',
                refreshOnboardingState: async () => {
                    try {
                        await loadAuthState(authUser, { excludeSubscription: true });
                    } catch (err) {
                        console.error('Error while trying to loadAuthState in refreshOnboardingState', err);
                        throw err;
                    }
                },
                subscription,
            }}
        >
            {isPageLoading ? <LoaderView /> : children}
        </AuthContext.Provider>
    );
};
