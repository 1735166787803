import { companyNameCorrectlyCased } from '../../../../../enums/company-name';
import { FieldMapping } from '../../../../../redux/ducks/salesforce/slice';

export const fieldValidator = (field: FieldMapping): FieldMapping => {
    if (field.destination.fieldName === '' && field.source.fieldName === '') {
        field.errorText = 'Please select fields.';
    } else if (field.destination.fieldName === '' && field.source.fieldName !== '') {
        field.errorText = 'Please select Salesforce field column.';
    } else if (field.destination.fieldName !== '' && field.source.fieldName === '') {
        field.errorText = `Please select ${companyNameCorrectlyCased} field column.`;
    } else if (field.source.type === 'static' && field.source.value === '') {
        field.errorText = 'Please select Salesforce option.';
    }
    return field;
};
