import { Box, ButtonBase, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import { ReactNode } from 'react';
import { useDispatch } from 'react-redux-latest';
import { useNavigate } from 'react-router-dom';
import { logoutAndRedirect } from '../redux/actions';
import { AppDispatch } from '../redux/store/configureStore';
import { palette } from '../theme/palette';
import BasicOrganizationInfo from './BasicOrganizationInfo';
import { paths } from '../routes/paths';
import { getZaplifySdk } from '@zaplify/sdk';
import { getAuthenticationToken } from '@zaplify/utils';
import { useQueryClient } from '@tanstack/react-query';
import { useApolloClient } from '@apollo/client';

type NoSeatsAvailableProps = {
    organizationName: string;
    activeUsersCount: number;
    children?: ReactNode;
};

const SubscriptionIssueModal = ({ children, organizationName, activeUsersCount }: NoSeatsAvailableProps) => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const tanStackClient = useQueryClient();
    const apolloClient = useApolloClient();

    const handleLogout = () => {
        dispatch(logoutAndRedirect());
    };

    const handleChangeOrganization = async () => {
        navigate(paths.OLD_ACCOUNTS);
    };

    return (
        <Modal open>
            <div
                style={{
                    width: 558,
                    background: 'white',
                    boxShadow: '0px 2px 14px rgba(57, 57, 57, 0.24)',
                    borderRadius: '14px',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: `translate(-50%, -50%)`,
                    backgroundColor: '#fff',
                    outline: 0,
                }}
            >
                <BasicOrganizationInfo organizationName={organizationName} activeUsersCount={activeUsersCount} />
                {children}
                <Box
                    sx={{ display: 'flex', justifyContent: 'center', my: '19px', textAlign: 'center' }}
                    style={{ gap: 10 }}
                >
                    <ButtonBase onClick={handleLogout}>
                        <Typography style={{ textDecoration: 'underline', color: palette.colors.greyDark }}>
                            Log out
                        </Typography>
                    </ButtonBase>
                    <Typography style={{ color: palette.colors.greyDark }}>or</Typography>
                    <ButtonBase onClick={handleChangeOrganization}>
                        <Typography style={{ textDecoration: 'underline', color: palette.colors.greyDark }}>
                            Switch workspace
                        </Typography>
                    </ButtonBase>
                    <Typography style={{ color: palette.colors.greyDark }}>or</Typography>
                    <ButtonBase
                        onClick={async () => {
                            await getZaplifySdk().profiles.authentication.chooseOrganization();
                            await getAuthenticationToken(true);
                            await Promise.all([tanStackClient.clear(), apolloClient.resetStore()]);
                            navigate(paths.OLD_CONFIGURE_PROFILE);
                        }}
                    >
                        <Typography style={{ textDecoration: 'underline', color: palette.colors.greyDark }}>
                            Create new workspace
                        </Typography>
                    </ButtonBase>
                </Box>
            </div>
        </Modal>
    );
};

export default SubscriptionIssueModal;
