import { IEvent, Topics } from '@zaplify/common';
import { ProspectDataExperienceDto } from '../prospects';

export class ProspectSelectedMainExperienceEvent extends IEvent {
    public static override readonly TOPIC: Topics = Topics.PROSPECTS;
    readonly organizationId: string;
    readonly prospectId: string;
    readonly experience: ProspectDataExperienceDto;

    constructor(command: { organizationId: string; prospectId: string; experience: ProspectDataExperienceDto }) {
        super();
        this.organizationId = command.organizationId;
        this.prospectId = command.prospectId;
        this.experience = command.experience;
    }

    get partitionKey(): string {
        return this.organizationId;
    }
}
