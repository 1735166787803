import { useState, FC, useMemo } from 'react';
import { ArrowRight, Loader2 } from 'lucide-react';
import { Button } from '@shadcn/ui/components/ui/button';
import { Input } from '@shadcn/ui/components/ui/input';
import { SectionTitle } from '../../../components/onboarding/section-title';
import { useAuth } from '../../../providers/authentication-provider';
import { CompletingOnboardingLoadingScreen } from './complete-onboarding-screen';
import { useOnboarding, PlaybookTemplate } from '../../../providers/onboarding-provider';
import { Template, usePlaybookTemplates } from '../../playbooks/hooks/use-playbook-templates';
import { paths } from '../../../../routes/paths';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useNavigate } from 'react-router-dom';

type Steps = 'select purpose' | 'select website' | 'configuring profile';

// TODO: Fix with prev implementation
function convertCountryToLanguage(country: string | undefined): string {
    if (!country) return 'English';
    const countryMappings: Record<string, string> = {
        'United States': 'English',
        'United Kingdom': 'English',
        Germany: 'German',
        France: 'French',
        Spain: 'Spanish',
        Italy: 'Italian',
        Sweden: 'Swedish',
        Netherlands: 'Dutch',
        Belgium: 'Dutch',
        Denmark: 'Danish',
        Norway: 'Norwegian',
        Finland: 'Finnish',
        Poland: 'Polish',
        Portugal: 'Portuguese',
    };

    return countryMappings[country] || 'English';
}

export const SetupPlaybookOnboardingPage: FC = () => {
    const auth = useAuth();
    const { setupNewWorkspace, error, setupUser, updatePlaybook, updateWorkspace } = useOnboarding();
    const navigate = useNavigate();
    const { feedOnboarding } = useFlags();

    const userAccount = auth.authState.userAccounts.find((account) => account.userId === auth.authState.userId);
    const country = auth.authState?.user?.location?.country || '';
    const language = useMemo(() => convertCountryToLanguage(country), [country]);
    const userEmail = auth.authState?.user?.email || '';
    const domainFromEmail = userEmail.split('@')?.[1] || '';
    const name = userAccount?.organization?.name || '';
    const [isLoading, setIsLoading] = useState(false);

    // Check if domain is a private email domain
    const userSignedUpWithPrivateEmailDomain = [
        'gmail.com',
        'hotmail.com',
        'outlook.com',
        'yahoo.com',
        'icloud.com',
    ].includes(domainFromEmail.toLowerCase());

    const [firstPlaybook, setFirstPlaybook] = useState<Partial<PlaybookTemplate>>(undefined);
    const [website, setWebsite] = useState<string>(userSignedUpWithPrivateEmailDomain ? '' : domainFromEmail);
    const [step, setStep] = useState<Steps>('select purpose');

    const handleNextStep = async () => {
        if (step === 'select purpose') {
            setStep('select website');
        } else if (step === 'select website') {
            const fullPlaybook: PlaybookTemplate = {
                name: 'My first playbook',
                purpose: firstPlaybook?.purpose || '',
                context: firstPlaybook?.context || '',
                targetGroup: firstPlaybook?.targetGroup,
                tone: firstPlaybook?.tone || '',
                language: language,
            };

            if (feedOnboarding) {
                setIsLoading(true);
                const userSetup = await setupUser({ language });
                await Promise.all([
                    updatePlaybook(userSetup.assistantSettingsId, fullPlaybook),
                    updateWorkspace(name, domainFromEmail, website),
                ]);
                await auth.refreshOnboardingState();
                setIsLoading(false);
                navigate(paths.NEW.ONBOARDING_PATHS.ACTION_FEED);
            } else {
                setupNewWorkspace(fullPlaybook, website, country, name, domainFromEmail);
            }

            setStep('configuring profile');
        }
    };

    if (step === 'configuring profile') {
        return <CompletingOnboardingLoadingScreen />;
    }

    return (
        <div className="flex flex-col items-start justify-center h-screen max-w-3xl w-full p-16">
            <div className="flex flex-col gap-8">
                {step === 'select purpose' && (
                    <SelectPurposeStep setPlaybook={setFirstPlaybook} onContinue={handleNextStep} />
                )}
                {step === 'select website' && (
                    <SelectWebsiteStep
                        domain={website}
                        setDomain={setWebsite}
                        onContinue={handleNextStep}
                        isLoading={isLoading}
                    />
                )}
            </div>

            {error && <div className="mt-4 p-4 bg-red-50 text-red-800 rounded-md">{error}</div>}
        </div>
    );
};

// Purpose selection step
interface SelectPurposeStepProps {
    setPlaybook: (playbook: Partial<PlaybookTemplate>) => void;
    onContinue: () => void;
}

function SelectPurposeStep({ onContinue, setPlaybook }: SelectPurposeStepProps) {
    const [selectedOther, setSelectedOther] = useState(false);
    const [purpose, setPurpose] = useState('');
    const { getTemplate } = usePlaybookTemplates();

    const completeStep = () => {
        if (['sales', 'investors', 'network'].includes(purpose)) {
            const playbook = getTemplate(purpose as Template);
            setPlaybook({
                name: playbook.name,
                tone: playbook.tone,
                purpose: playbook.purpose,
                context: playbook.context,
            });
        } else {
            setPlaybook({
                name: purpose,
                tone: 'Casual',
                purpose: purpose,
            });
        }

        onContinue();
    };

    return (
        <div className="max-w-xl">
            <SectionTitle
                title="What would you like to use Andsend for?"
                subtitle="Your purpose helps Andsend steer your conversations in the right direction."
            />

            <div className="flex flex-col gap-4 py-4">
                <SelectChip
                    label="Start sales conversations 💬"
                    onClick={() => {
                        setSelectedOther(false);
                        setPurpose('sales');
                    }}
                    selected={purpose === 'sales'}
                />
                <SelectChip
                    label="Get in touch with investors 🤝"
                    onClick={() => {
                        setSelectedOther(false);
                        setPurpose('investors');
                    }}
                    selected={purpose === 'investors'}
                />
                <SelectChip
                    label="Nurture my network 🌳"
                    onClick={() => {
                        setSelectedOther(false);
                        setPurpose('network');
                    }}
                    selected={purpose === 'network'}
                />
                <SelectChip
                    label="Other"
                    onClick={() => {
                        setPurpose('');
                        setSelectedOther(true);
                    }}
                    selected={selectedOther}
                />

                {selectedOther && (
                    <Input
                        className="mt-2"
                        placeholder="Set the purpose of your first playbook"
                        value={purpose}
                        onChange={(e) => setPurpose(e.target.value)}
                    />
                )}
            </div>

            {(!!purpose || selectedOther) && (
                <Button onClick={completeStep} disabled={!purpose} className="mt-4 flex items-center gap-2">
                    Continue <ArrowRight size={15} />
                </Button>
            )}
        </div>
    );
}

// Selection chip component
interface SelectChipProps {
    label: string;
    onClick: () => void;
    selected?: boolean;
}

function SelectChip({ label, onClick, selected = false }: SelectChipProps) {
    return (
        <button
            onClick={onClick}
            className={`flex h-14 w-full items-center px-6 rounded-lg border text-start font-medium transition-all
        ${
            selected
                ? 'border-border-brand shadow-md bg-background-active/60'
                : 'border-border bg-white shadow-sm hover:border-muted-foreground/30'
        }`}
        >
            {label}
        </button>
    );
}

// Website selection step
interface SelectWebsiteStepProps {
    domain: string;
    setDomain: (domain: string) => void;
    onContinue: () => void;
    isLoading: boolean;
}

function SelectWebsiteStep({ domain, setDomain, onContinue, isLoading }: SelectWebsiteStepProps) {
    const [error, setError] = useState('');
    const { isLoading: onboardingLoading } = useOnboarding();

    const loading = isLoading || onboardingLoading;

    // Basic URL validation
    const isUrlValid = (url: string) => {
        const pattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)*\/?$/;
        return pattern.test(url);
    };

    // Handle continuing to the next step
    const handleContinue = (userDomain: string) => {
        if (!domain || isUrlValid(userDomain)) {
            onContinue();
        } else {
            setError('Please enter a valid URL or completely remove it.');
        }
    };

    // Handle input changes
    const onChange = (value: string) => {
        setDomain(value);
        setError('');
    };

    return (
        <div className="max-w-lg">
            <SectionTitle
                title="Enter your website"
                subtitle="Andsend will analyze your website to understand your offering and business context"
            />

            <div className="flex flex-col gap-4 py-4">
                <div className="space-y-2">
                    <label htmlFor="website" className="text-sm font-medium">
                        Website URL
                    </label>
                    <div className="relative">
                        <Input
                            id="website"
                            value={domain}
                            onChange={(e) => onChange(e.target.value)}
                            placeholder="mywebsite.com"
                            className={error ? 'border-destructive' : ''}
                            disabled={loading}
                        />
                    </div>
                    {error && <p className="text-sm text-destructive">{error}</p>}
                </div>
            </div>

            {domain ? (
                <Button
                    onClick={() => handleContinue(domain)}
                    className="mt-4 flex items-center gap-2"
                    disabled={loading}
                >
                    {loading && <Loader2 size={15} className="animate-spin" />} Continue <ArrowRight size={15} />
                </Button>
            ) : (
                <div className="flex flex-col gap-2 mt-4">
                    <Button
                        onClick={() => handleContinue(domain)}
                        variant="default"
                        className="flex items-center gap-2"
                        disabled={loading}
                    >
                        Continue without website <ArrowRight size={15} />
                    </Button>
                    <p className="text-sm text-muted-foreground">
                        Adding a website allows us to give you better message suggestions and contact recommendations.
                    </p>
                </div>
            )}
        </div>
    );
}
