import { AndsendAssistantRuntimeProvider } from './providers/andsend-assistant-runtime-provider';
// import { ChartToolUI } from '../components/tools/chart-tools-ui';
// import { Thread } from '@shadcn/ui/components/assistant-ui/thread';
import { PriceSnapshotTool } from './components/tools/price-snapshot';
import { Thread, ThreadList, AssistantMessage } from '@assistant-ui/react-ui';
import { WeatherTool } from './components/tools/weather-tool';
import { ActionsListTool } from './components/tools/actions-list';
import { ThreadWelcome } from './components/thread-welcome';
import { playbookToolsUI } from './components/tools/playbook-tools-ui';
import { MarkdownText } from '@shadcn/ui/components/assistant-ui/markdown-text';
import { ToolFallback } from '@shadcn/ui/components/assistant-ui/tool-fallback';
import AndsendAgentAvatar from './assets/andsend-agent-avatar.png';
import { UserMessage } from './components/user-message';
import { contactToolsUI } from './components/tools/contact-tools-ui';
import { webSearchToolsUI } from './components/tools/web-search-tools-ui';

export const HomePage = () => {
    return (
        <AndsendAssistantRuntimeProvider>
            <div className="h-dvh w-full p-6 justify-center">
                {/* <ThreadList /> */}

                <div className="flex h-full flex-col">
                    <Thread
                        welcome={{
                            message: "Hi, I'm your Customer Relationship Agent. How can I help you today?",
                            suggestions: [
                                {
                                    prompt: 'What should I do today?',
                                },
                                {
                                    prompt: 'What playbooks do I have?',
                                },
                                {
                                    prompt: 'Show me the best contacts in my network',
                                },
                            ],
                        }}
                        composer={{
                            allowAttachments: true,
                        }}
                        components={{
                            ThreadWelcome: ThreadWelcome,
                            UserMessage: UserMessage,
                            // AssistantMessage: AssistantMessage,
                        }}
                        tools={[
                            PriceSnapshotTool,
                            WeatherTool,
                            ActionsListTool,
                            ...playbookToolsUI,
                            ...contactToolsUI,
                            ...webSearchToolsUI,
                        ]}
                        assistantMessage={{
                            components: {
                                Text: MarkdownText,
                                ToolFallback: ToolFallback,
                            },
                        }}
                        assistantAvatar={{
                            src: AndsendAgentAvatar,
                            fallback: 'A',
                        }}
                    />
                </div>
            </div>
        </AndsendAssistantRuntimeProvider>
    );
};

export default HomePage;
