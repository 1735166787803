import { Badge } from '@shadcn/ui/components/ui/badge';
import { Tooltip, TooltipContent, TooltipTrigger } from '@shadcn/ui/components/ui/tooltip';
import { TooltipProvider } from '@shadcn/ui/components/ui/tooltip';
import * as React from 'react';

export const NewContactBadge: React.FC<{ addedAt: string }> = ({ addedAt }) => {
    return (
        <TooltipProvider>
            <Tooltip delayDuration={300}>
                <TooltipTrigger>
                    <Badge variant="success">
                        <div className="flex flex-row gap-2 items-center">
                            <div className="w-1.5 h-1.5 rounded-full bg-success-700 dark:bg-success-500" />
                            New contact
                        </div>
                    </Badge>
                </TooltipTrigger>
                <TooltipContent>Added {addedAt}</TooltipContent>
            </Tooltip>
        </TooltipProvider>
    );
};
