import { LucideBookOpen, Search, MessageSquare, Users, BookOpen } from 'lucide-react';

export function EmptyConversationState() {
    return (
        <div className="h-full flex flex-col items-center justify-center p-8 text-center">
            <div className="max-w-xl">
                <div className="mb-8">
                    <div className="bg-blueLight-100 rounded-full h-16 w-16 flex items-center justify-center mx-auto mb-4">
                        <MessageSquare className="h-8 w-8 text-blueLight-600" />
                    </div>
                    <h2 className="text-2xl font-semibold text-text-primary mb-2">Your conversations</h2>
                    <p className="text-text-secondary">
                        Select a conversation from the list or discover new connections
                    </p>
                </div>

                <div className="space-y-6 bg-background-primary rounded-lg border border-border-secondary p-6">
                    <h3 className="font-medium text-lg text-text-primary border-b border-border-tertiary pb-3">
                        Getting Started Guide
                    </h3>

                    <div className="flex items-start gap-3 text-left">
                        <div className="bg-blueLight-100 rounded-full p-2 mt-1">
                            <Search className="h-5 w-5 text-blueLight-600" />
                        </div>
                        <div>
                            <h4 className="font-medium text-text-primary">Find connections</h4>
                            <p className="text-sm text-text-secondary">
                                Use the search bar to find anyone in your LinkedIn network
                            </p>
                        </div>
                    </div>

                    <div className="flex items-start gap-3 text-left">
                        <div className="bg-greenLight-100 rounded-full p-2 mt-1">
                            <Users className="h-5 w-5 text-greenLight-600" />
                        </div>
                        <div>
                            <h4 className="font-medium text-text-primary">Manage contacts</h4>
                            <p className="text-sm text-text-secondary">
                                Add and organize your professional contacts from LinkedIn
                            </p>
                        </div>
                    </div>

                    <div className="flex items-start gap-3 text-left">
                        <div className="bg-purple-100 rounded-full p-2 mt-1">
                            <BookOpen className="h-5 w-5 text-purple-500/85" />
                        </div>
                        <div>
                            <h4 className="font-medium text-text-primary">Use playbooks</h4>
                            <p className="text-sm text-text-secondary">
                                Andsend tracks your contacts and suggests timely follow-ups
                            </p>
                        </div>
                    </div>

                    <p className="text-xs text-text-tertiary italic mt-2 text-center pt-3 border-t border-border-tertiary">
                        Add contacts directly from LinkedIn using the "Add to Playbook" button on profile pages
                    </p>
                </div>
            </div>
        </div>
    );
}

export const NoConversationSelected = () => {
    return (
        <div className="flex flex-1 flex-col h-full items-center justify-center bg-background-secondary/70">
            <EmptyConversationState />
        </div>
    );
};

export default NoConversationSelected;
