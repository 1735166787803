import { useEffect } from 'react';

export const useWindowResizing = (onResize: (width: number, height: number) => void) => {
    useEffect(() => {
        const handleResize = () => {
            onResize(window.innerWidth, window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
};
