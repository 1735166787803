import { ICommand, Topics } from '@zaplify/common';
import { ChannelAccountDto, LinkedinDto } from '../types/channel-accounts';

export class CreateLinkedinChannelAccountCommand extends ICommand<ChannelAccountDto> {
    public static override readonly TOPIC = Topics.CHANNEL_ACCOUNTS;
    constructor(
        readonly userId: string,
        readonly organizationId: string,
        readonly name: string,
        readonly LINKEDIN: Required<
            Omit<LinkedinDto, 'proxy' | 'userId' | 'cookies' | 'numberOfConnections' | 'restriction'>
        >,
    ) {
        super();
    }
}
