// First, let's split the providers into separate files
import { NewAppProviders } from './provider-groups/new-app-providers';
import { LegacyAppProviders } from './provider-groups/legacy-app-providers';
import { LegacyAuthProviders } from './provider-groups/legacy-auth-providers';
import { BaseProviders } from './provider-groups/base-providers';

// Split routes into logical groups
import { newAppRoutes } from './route-groups/new-app-routes';
import { legacyAppRoutes } from './route-groups/legacy-app-routes';
import { legacyAuthRoutes } from './route-groups/legacy-auth-routes';
import { legacyPublicRoutes } from './route-groups/legacy-public-routes';
import { createRoutesFromElements, Route, Outlet, Navigate } from 'react-router-dom';
import { paths } from './paths';
import RequireAuth from './require-auth';
import { OfflinePage } from '../new/pages/offline-page';
import { newAuthRoutes } from './route-groups/new-auth-routes';
import { ReactRouterErrorBoundary } from './react-router-error-boundary';
import { newPublicRoutes } from './route-groups/new-public-routes';

export const routes = createRoutesFromElements(
    <Route
        element={
            <BaseProviders>
                <Outlet />
            </BaseProviders>
        }
        ErrorBoundary={ReactRouterErrorBoundary}
    >
        {/* New UI Routes - These will eventually be the only in app routes */}
        <Route
            element={
                <NewAppProviders>
                    <Outlet />
                </NewAppProviders>
            }
            ErrorBoundary={ReactRouterErrorBoundary}
        >
            {newAppRoutes}
        </Route>
        {/* Auth Routes */}
        {/* <Route
            element={
                <NewAuthProviders>
                    <Outlet />
                </NewAuthProviders>
            }
        > */}
        {newAuthRoutes}
        {/* </Route> */}

        {/* Public Routes */}
        {newPublicRoutes}

        {/* Legacy Routes - These will be removed once migration is complete */}
        <Route
            element={
                <LegacyAppProviders>
                    <Outlet />
                </LegacyAppProviders>
            }
            ErrorBoundary={ReactRouterErrorBoundary}
        >
            {legacyAppRoutes}
        </Route>

        {/* Auth Routes */}
        <Route
            element={
                <LegacyAuthProviders>
                    <Outlet />
                </LegacyAuthProviders>
            }
            ErrorBoundary={ReactRouterErrorBoundary}
        >
            {legacyAuthRoutes}
            {legacyPublicRoutes}
        </Route>

        {/* Fallbacks */}
        <Route
            // errorElement={<ErrorPage />}
            element={<RequireAuth requiredUserState={'offline'} />}
        >
            <Route path={paths.SERVER_OFFLINE} element={<OfflinePage />} />
        </Route>
        {/* Redirect Routes */}
        <Route path={paths.HOME} element={<Navigate to={paths.NEW.ACTIONS} replace />} />
        <Route path="*" element={<Navigate to={paths.HOME} replace />} />
    </Route>
);
