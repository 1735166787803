import React, { Suspense, useMemo } from 'react';
import { useSdk } from '../../sdk';
import { GET_TOTAL_NUMBER_OF_USER_CONTACTS_IN_ANY_PLAYBOOK } from '@zaplify/graphql';
import { useSuspenseQuery as useSuspenseQueryTan } from '@tanstack/react-query';
import { useSuspenseQuery } from '@apollo/client';
import { Progress } from '@shadcn/ui/components/ui/progress';
import { Button } from '@shadcn/ui/components/ui/button';
import { CircleArrowUp, Loader2, Share2 } from 'lucide-react';
import { useReferFriendsDialog } from '../dialogs/refer-friends-dialog';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../../routes/paths';
import { useSidebars } from '@shadcn/ui/hooks/use-sidebars';
import { SidebarMenuButton } from '@shadcn/ui/components/ui/sidebar';
import { Tooltip, TooltipContent, TooltipTrigger } from '@shadcn/ui/components/ui/tooltip';

export const ContactLimitNavBarItem: React.FC = () => {
    const { sidebarState } = useSidebars();
    return (
        <Suspense
            fallback={
                <div className="flex w-full py-4 justify-center">
                    <Loader2 className="w-4 h-4 animate-spin" />
                </div>
            }
        >
            {sidebarState.main ? <ContactLimitCard /> : <UpgradeButton />}
        </Suspense>
    );
};

const UpgradeButton: React.FC = () => {
    const {
        userContacts: { getUserContactLimit },
    } = useSdk();
    let { data: limit } = useSuspenseQueryTan(getUserContactLimit());
    const navigate = useNavigate();

    if (limit === 'none') {
        return null;
    }

    return (
        <Tooltip>
            <TooltipTrigger asChild>
                <SidebarMenuButton
                    variant="outline"
                    className="mb-1"
                    onClick={() => navigate(paths.ACCOUNT_MANAGEMENT + '/subscription')}
                >
                    <CircleArrowUp className="stroke-[2] text-background-brand-primary" />
                </SidebarMenuButton>
            </TooltipTrigger>
            <TooltipContent side="right">Upgrade</TooltipContent>
        </Tooltip>
    );
};

const ContactLimitCard: React.FC = () => {
    const {
        userContacts: { getUserContactLimit },
    } = useSdk();
    const { data: totalNumberOfUserContactsInAnyPlaybook } = useSuspenseQuery(
        GET_TOTAL_NUMBER_OF_USER_CONTACTS_IN_ANY_PLAYBOOK
    );
    const { data: limit } = useSuspenseQueryTan(getUserContactLimit());
    const { openReferFriendsDialog } = useReferFriendsDialog();
    const navigate = useNavigate();
    const numberOfContacts = totalNumberOfUserContactsInAnyPlaybook.UserContacts_aggregate.aggregate.count;
    const hasReachedLimit = useMemo(
        () => numberOfContacts >= (typeof limit === 'string' ? 0 : limit),
        [numberOfContacts, limit]
    );

    const handleClick = () => {
        if (hasReachedLimit) {
            navigate(paths.ACCOUNT_MANAGEMENT + '/subscription');
        } else {
            openReferFriendsDialog();
        }
    };

    if (limit === 'none') {
        return null;
    }

    return (
        <div className="rounded-xl shadow border w-full p-2 mb-2 bg-background-primary text-xs flex flex-col gap-2">
            <div>
                <span className="inline-block font-semibold">
                    {numberOfContacts} / {limit}
                </span>{' '}
                contacts used
            </div>
            <Progress
                className="[&>div]:bg-brand-gradient-1"
                value={(Math.min(numberOfContacts, limit) / limit) * 100}
            />
            <Button variant="outline" size="sm" className="w-full text-sm" onClick={handleClick}>
                {hasReachedLimit ? (
                    <>
                        <CircleArrowUp className="stroke-[1]" />
                        <span>Upgrade</span>
                    </>
                ) : (
                    <>
                        <Share2 className="stroke-[1]" />
                        <span className="text-xs">Refer to increase limit</span>
                    </>
                )}
            </Button>
        </div>
    );
};
