export enum ChannelType {
    EMAIL = 'EMAIL',
    LINKEDIN = 'LINKEDIN',
    TWITTER = 'TWITTER',
}

export const actionTypeToChannelType = (actionType: string): ChannelType => {
    const lowerCaseActionType = actionType.toLowerCase();
    if (lowerCaseActionType.includes('linkedin')) {
        return ChannelType.LINKEDIN;
    } else if (lowerCaseActionType.includes('email')) {
        return ChannelType.EMAIL;
    }
    return ChannelType.LINKEDIN;
};

export enum ChannelProvider {
    GMAIL = 'GMAIL',
    OUTLOOK = 'OUTLOOK',
    LINKEDIN = 'LINKEDIN',
}

export enum ChannelAccountStatus {
    AUTHENTICATED = 'AUTHENTICATED',
    NOT_AUTHENTICATED = 'NOT_AUTHENTICATED',
    SCOPES = 'SCOPES',
}

export interface ProxyAddress {
    url: string;
    userName: string;
    password: string;
}

export class ProxyDto {
    location: ProxyLocationDto;
    addresses?: ProxyAddress[];

    constructor(proxy: ProxyDto) {
        this.location = {
            city: proxy?.location?.city,
            country: proxy?.location?.country,
        };
        if (proxy?.addresses) {
            this.addresses = proxy?.addresses?.map((proxy) => {
                return {
                    url: proxy?.url,
                    userName: proxy?.userName,
                    password: proxy?.password,
                };
            });
        }
    }

    static fromDocument(proxy: ProxyDto): ProxyDto {
        return new ProxyDto(proxy);
    }
}

export class GmailDto {
    emailAddress?: string;
    scopes?: string[];
    accessToken?: string;
    refreshToken?: string;

    static fromDocument(gmail: GmailDto): GmailDto {
        return new GmailDto(gmail);
    }

    constructor(gmail: GmailDto) {
        this.emailAddress = gmail.emailAddress;
        this.scopes = gmail.scopes;
        this.accessToken = gmail.accessToken;
        this.refreshToken = gmail.refreshToken;
    }
}

export class OutlookDto {
    emailAddress?: string;
    scopes?: string[];
    accessToken?: string;
    refreshToken?: string;

    static fromDocument(outlook: OutlookDto): OutlookDto {
        return new OutlookDto(outlook);
    }
    constructor(outlook: OutlookDto) {
        this.emailAddress = outlook.emailAddress;
        this.scopes = outlook.scopes;
        this.accessToken = outlook.accessToken;
        this.refreshToken = outlook.refreshToken;
    }
}

export class RestrictionDto {
    lastRestricted: Date;

    lastChecked: Date;

    restrictionCheckingEnabled?: boolean;

    static fromDocument(restriction: RestrictionDto): RestrictionDto {
        return new RestrictionDto(restriction);
    }

    constructor(restriction: RestrictionDto) {
        this.lastRestricted = restriction.lastRestricted
            ? new Date(restriction.lastRestricted)
            : restriction.lastRestricted;
        this.lastChecked = restriction.lastChecked ? new Date(restriction.lastChecked) : restriction.lastChecked;
        this.restrictionCheckingEnabled = restriction?.restrictionCheckingEnabled;
    }
}
export class LinkedinDto {
    headline?: string;
    userId?: string;
    username?: string;
    sessionCookie?: string;
    userAgent?: string;
    cookies?: BrowserCookie[]; /** @experimental */
    proxy: ProxyDto;
    restriction?: RestrictionDto;
    isPremiumAccount?: boolean;
    numberOfConnections?: number;

    static fromDocument(linkedin: LinkedinDto): LinkedinDto {
        return new LinkedinDto(linkedin);
    }
    constructor(linkedin: LinkedinDto) {
        this.headline = linkedin.headline;
        this.userId = linkedin.userId;
        this.username = linkedin.username;
        this.sessionCookie = linkedin.sessionCookie;
        this.isPremiumAccount = linkedin?.isPremiumAccount;
        this.numberOfConnections = linkedin?.numberOfConnections;
        if (linkedin.restriction) this.restriction = RestrictionDto.fromDocument(linkedin.restriction);
        this.proxy = ProxyDto.fromDocument(linkedin.proxy);
        this.cookies = linkedin?.cookies;
        this.userAgent = linkedin?.userAgent;
    }
}

export interface ProxyLocationDto {
    country: string;
    city: string;
}

interface IGmail {
    emailAddress?: string;
    scopes?: string[];
    accessToken?: string;
    refreshToken?: string;
}

interface IOutlook {
    emailAddress?: string;
    scopes?: string[];
    accessToken?: string;
    refreshToken?: string;
}

interface ILinkedin {
    headline?: string;
    userId?: string;
    username?: string;
    sessionCookie?: string;
    numberOfConnections?: number;
    isPremiumAccount?: boolean;
    proxy: IProxy;
    restriction?: IRestriction;
}
interface IThrottle {
    autoThrottle: number;
    customThrottle: number;
}

interface IProxy {
    location: ProxyLocationDto;
    addresses?: ProxyAddress[];
}

interface IRestriction {
    lastRestricted: Date;
    lastChecked: Date;
    restrictionCheckingEnabled?: boolean;
}
interface IChannelAccount {
    id?: any;
    type: ChannelType;
    provider: ChannelProvider;
    status: ChannelAccountStatus;
    name?: string;
    avatarUrl?: string;
    signature?: string;
    notifyTo: string;
    throttle: IThrottle;
    GMAIL?: IGmail;
    OUTLOOK?: IOutlook;
    LINKEDIN?: ILinkedin;
    createdAt?: Date;
    updatedAt?: Date;
}
export class ChannelAccountDto {
    id?: string;
    type: ChannelType;
    provider: ChannelProvider;
    status: ChannelAccountStatus;
    notifyTo: string;
    name?: string;
    avatarUrl?: string;
    signature?: string;
    throttle: number;
    GMAIL?: GmailDto;
    OUTLOOK?: OutlookDto;
    LINKEDIN?: LinkedinDto;

    createdAt?: Date;
    updatedAt?: Date;

    static fromDocument(channelAccount: IChannelAccount | ChannelAccountDto): ChannelAccountDto {
        return new ChannelAccountDto(channelAccount);
    }
    constructor(channelAccount: IChannelAccount | ChannelAccountDto) {
        this.id = channelAccount.id;
        this.type = channelAccount.type;
        this.provider = channelAccount.provider;
        this.status = channelAccount.status;
        this.notifyTo = channelAccount.notifyTo;
        this.name = channelAccount.name;
        this.avatarUrl = channelAccount.avatarUrl;
        this.signature = channelAccount.signature;
        if (typeof channelAccount.throttle === 'number') {
            this.throttle = channelAccount.throttle;
        } else if (typeof channelAccount.throttle === 'object') {
            this.throttle = channelAccount.throttle.customThrottle ?? channelAccount.throttle.autoThrottle ?? 0;
        } else {
            this.throttle = 0;
        }
        if (channelAccount.GMAIL) {
            this.GMAIL = GmailDto.fromDocument(channelAccount.GMAIL);
        }
        if (channelAccount.OUTLOOK) {
            this.OUTLOOK = OutlookDto.fromDocument(channelAccount.OUTLOOK);
        }
        if (channelAccount.LINKEDIN) {
            this.LINKEDIN = LinkedinDto.fromDocument(channelAccount.LINKEDIN);
        }
        this.createdAt = channelAccount.createdAt;
        this.updatedAt = channelAccount.updatedAt;
    }
}

export interface BaseCreateChannelAccountDto {
    type: ChannelType;
    provider: ChannelProvider;
    name?: string;
    avatarUrl?: string;
}

export interface CreateGmailChannelAccountDto extends BaseCreateChannelAccountDto {
    type: ChannelType.EMAIL;
    provider: ChannelProvider.GMAIL;
    GMAIL: {
        authCode: string;
    };
}

export interface CreateOutlookChannelAccountDto extends BaseCreateChannelAccountDto {
    type: ChannelType.EMAIL;
    provider: ChannelProvider.OUTLOOK;
    OUTLOOK: {
        authCode: string;
    };
}

export interface CreateLinkedinChannelAccountDto extends BaseCreateChannelAccountDto {
    LINKEDIN: {
        headline: string;
        userId: string;
        username: string;
        sessionCookie: string;
    };
}

export interface BrowserCookie {
    domain: string;
    expirationDate?: number;
    hostOnly: boolean;
    httpOnly: boolean;
    name: string;
    path: string;
    sameSite: string;
    secure: boolean;
    session: boolean;
    storeId: string;
    value: string;
}

export type CreateLinkedinDto = {
    cookies?: BrowserCookie[];
    sessionCookie: string;
    userAgent: string;
    proxy?: {
        location: ProxyLocationDto;
        addresses?: ProxyAddress[];
    };
};

type AuthCode = {
    authCode: string;
};

export type CreateChannelAccountDto = {
    userId: string;

    type: ChannelType;
    provider: ChannelProvider;

    name?: string;

    avatarUrl?: string;
    throttle?: number;

    LINKEDIN?: CreateLinkedinDto;
    GMAIL?: AuthCode;
    OUTLOOK?: AuthCode;
};

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

export interface ReauthorizeLinkedinChannelAccountDto {
    LINKEDIN: Optional<CreateLinkedinDto, 'sessionCookie'>;
}
export interface ReauthorizeGmailChannelAccountDto {
    GMAIL: AuthCode;
}
export interface ReauthorizeOutlookChannelAccountDto {
    OUTLOOK: AuthCode;
}

export type ReauthorizeChannelAccountDto =
    | ReauthorizeLinkedinChannelAccountDto
    | ReauthorizeGmailChannelAccountDto
    | ReauthorizeOutlookChannelAccountDto;

// export interface ReauthorizeChannelAccountDto {
//     LINKEDIN?: Linkedin | null;
//     GMAIL?: AuthCode;
//     OUTLOOK?: AuthCode;
// }

export class UpdateChannelAccountDto {
    name?: string;
    avatarUrl?: string;
}

export class AccountLocationDto {
    country: string;
    city: string;

    static fromDocument(location: AccountLocationDto) {
        return new AccountLocationDto(location);
    }
    constructor(location: AccountLocationDto) {
        this.country = location.country;
        this.city = location.city;
    }
}
