import { styled } from '@mui/styles';

const BillingPeriodSelectorStyled = styled('div')(({ theme: { palette } }: any) => ({
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 4,
    gap: 10,
    background: palette.colors.white,
    border: `1px solid ${palette.colors.greyInformationLight}`,
    boxShadow: '0px 2px 6px rgb(112 144 176 / 7%)',
    borderRadius: 300,

    '& .outlined': {
        backgroundColor: '#E0E8FF !important',
    },
}));

export default BillingPeriodSelectorStyled;
