import { useQuery, useSubscription } from '@apollo/client';
import {
    PROSPECT_ACTIONS_SUBSCRIPTION,
    GetOutreachSuggestionSubscription,
    GET_ALL_PROSPECT_SUBJECT_LINES,
} from '@zaplify/graphql';
import React, { createContext, useContext, useState, useCallback, useMemo, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDraftMessage } from './use-draft-messages';
import { useLinkedin } from '../use-linkedin';
import { getEmailState } from '../../functions/email-state';
import { ChannelType } from '../../types/channel-type';
import { MessageType } from '../../types/message-type';
import { MessageNotSentError, useMessages } from './use-messages';
import dayjs from 'dayjs';
import { useQuery as useTanQuery, useMutation } from '@tanstack/react-query';
import { useSdk } from '../../sdk';
import { MessageType as BEMessageType } from '@zaplify/services/messaging/shared';
import { ChannelType as BEChannelType, ChannelAccountStatus } from '@zaplify/channel-accounts/shared';
import { htmlToPlain } from '../../../helpers/utils';
import utc from 'dayjs/plugin/utc';
import { ProspectDataDto, ProspectLinkedinProfileVerifiedStatus } from '@zaplify/prospects';
import { useEmail } from '../use-email';
import { useIsMobile } from '@shadcn/ui/hooks/use-mobile';

dayjs.extend(utc);

export enum DisabledPrimaryActionReason {
    MESSAGE_EMPTY = 'MESSAGE_EMPTY',
    MESSAGE_TOO_LONG = 'MESSAGE_TOO_LONG',
    SUBJECT_LINE_EMPTY = 'SUBJECT_LINE_EMPTY',
    RECIPIENT_MISSING_LINKEDIN_PROFILE = 'MISSING_LINKEDIN_PROFILE',
    RECIPIENT_CONNECTION_PENDING = 'CONNECTION_PENDING',
    RECIPIENT_INVALID_EMAIL = 'INVALID_EMAIL',
    EXTENSION_NOT_INSTALLED = 'EXTENSION_NOT_INSTALLED',
    LINKEDIN_MISSING_CONFIG = 'LINKEDIN_MISSING_CONFIG',
    LINKEDIN_NOT_AUTHENTICATED = 'LINKEDIN_NOT_AUTHENTICATED',
    LINKEDIN_SYNCING = 'LINKEDIN_SYNCING',
    EMAIL_NOT_CONNECTED = 'EMAIL_NOT_CONNECTED',
    EMAIL_NOT_AUTHENTICATED = 'EMAIL_NOT_AUTHENTICATED',
    EMAIL_SCOPES = 'EMAIL_SCOPES',
    LINKEDIN_NOT_SUPPORTED_ON_MOBILE = 'LINKEDIN_NOT_SUPPORTED_ON_MOBILE',
}

type MessageComposerContextType = {
    body: string;
    bodyLength: number;
    handleBodyTextChange: (value: string) => void;
    subjectLine: string;
    handleSubjectLineChange: (subjectLine: string) => void;
    prospectId: string | undefined;
    messageType: MessageType;
    handleShowSuggestedMessage: () => void;
    suggestedDiffersFromDraft: boolean;
    channelType: ChannelType;
    setDraftMessage: (message: ActiveMessage) => void;
    handleChannelTypeChange: (channelType: ChannelType) => void;
    isSendable: boolean;
    messageComposerPrimaryAction: (options?: { omitMessage?: boolean }) => void;
    isGeneratingMessage: boolean;
    regenerateMessage: ({ feedback }: { feedback: string }) => Promise<void>;
    isSending: boolean;
    handleGenerateMessageAsEmail: () => void;
    disabledPrimaryActionReason?: DisabledPrimaryActionReason;
    isStreaming: boolean;
    loadingOutreachSuggestion: boolean;
};

interface ActiveMessage {
    content: string;
    subjectLine: string;
    channelType: ChannelType;
    id?: string;
    shouldStream?: boolean;
}

type OutreachSuggestion = GetOutreachSuggestionSubscription['OutreachSuggestions'][number]['Message'];

const MessageComposerContext = createContext<MessageComposerContextType | undefined>(undefined);

const log = (message: string, data?: unknown) => {
    console.log(`[MessageComposer] ${message}`, data || '');
};

const useMessageState = (prospectId: string | undefined) => {
    const { draftMessage, setDraftMessage, removeDraftMessage } = useDraftMessage(prospectId);
    const [isGeneratingMessage, setIsGeneratingMessage] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [draftCreatedThisSession, setDraftCreatedThisSession] = useState(false);
    const location = useLocation();
    const isConversation = !location.pathname.includes('action');

    return {
        draftMessage,
        setDraftMessage,
        removeDraftMessage,
        isGeneratingMessage,
        setIsGeneratingMessage,
        isSending,
        setIsSending,
        isConversation,
        draftCreatedThisSession,
        setDraftCreatedThisSession,
    };
};

const useMessageSubscription = (
    prospectId: string | undefined
): {
    outreachSuggestion: OutreachSuggestion | undefined;
    subscribedOutreachSuggestion: GetOutreachSuggestionSubscription;
    isOutreachSuggestionLoading: boolean;
} => {
    const scheduledOnBefore = dayjs().add(1, 'days').startOf('day').format('YYYY-MM-DD');

    const { data: subscribedOutreachSuggestion, loading: isOutreachSuggestionLoading } = useSubscription(
        PROSPECT_ACTIONS_SUBSCRIPTION,
        {
            variables: {
                prospectId,
                scheduledOnBefore,
            },
            skip: !prospectId,
        }
    );

    const outreachSuggestion = useMemo(
        () => subscribedOutreachSuggestion?.OutreachSuggestions?.[0]?.Message,
        [
            subscribedOutreachSuggestion?.OutreachSuggestions?.[0]?.Message?.content,
            subscribedOutreachSuggestion?.OutreachSuggestions?.[0]?.scheduledOn,
        ]
    );

    return { outreachSuggestion, subscribedOutreachSuggestion, isOutreachSuggestionLoading };
};

const useSubjectLines = (prospectId: string | undefined) => {
    const { data: subjectLinesData, loading: subjectLinesLoading } = useQuery(GET_ALL_PROSPECT_SUBJECT_LINES, {
        variables: {
            prospectId: prospectId,
        },
        skip: !prospectId,
    });

    const subjectLines = useMemo(
        () =>
            Array.from(
                subjectLinesData?.Messages
                    ? new Set(subjectLinesData?.Messages.map((message) => message.subjectLine).filter((s) => s))
                    : []
            ),
        [subjectLinesData, subjectLinesLoading]
    );

    return { subjectLines, subjectLinesLoading };
};

interface ValidationResult {
    valid: boolean;
    reason?: DisabledPrimaryActionReason;
}

const useMessageValidation = (
    messageType: MessageType,
    activeMessage: ActiveMessage,
    contact: ProspectDataDto | undefined,
    connectionStatus: string | undefined
) => {
    const { isPremium, extensionStatus } = useLinkedin();
    const { emailStatus } = useEmail();
    const isMobile = useIsMobile();

    const isValidMessage = useCallback((): ValidationResult => {
        if (
            isMobile &&
            (messageType === MessageType.linkedinMessage || messageType === MessageType.linkedinConnectionRequest)
        ) {
            return { valid: false, reason: DisabledPrimaryActionReason.LINKEDIN_NOT_SUPPORTED_ON_MOBILE };
        }

        if (messageType === MessageType.linkedinMessage && !activeMessage.content?.trim().length) {
            return { valid: false, reason: DisabledPrimaryActionReason.MESSAGE_EMPTY };
        }

        if (messageType === MessageType.emailMessage) {
            if (!activeMessage.content?.length) {
                return { valid: false, reason: DisabledPrimaryActionReason.MESSAGE_EMPTY };
            }
            if (!activeMessage.subjectLine?.length) {
                return { valid: false, reason: DisabledPrimaryActionReason.SUBJECT_LINE_EMPTY };
            }
        }

        if (messageType === MessageType.linkedinConnectionRequest) {
            const limit = isPremium ? 300 : 200;
            if (activeMessage.content?.length > limit) {
                return { valid: false, reason: DisabledPrimaryActionReason.MESSAGE_TOO_LONG };
            }
        }

        return { valid: true };
    }, [messageType, activeMessage, isPremium]);

    const isValidRecipient = useCallback((): ValidationResult => {
        if (isMobile && activeMessage.channelType === ChannelType.LINKEDIN) {
            return { valid: false, reason: DisabledPrimaryActionReason.LINKEDIN_NOT_SUPPORTED_ON_MOBILE };
        }
        if (activeMessage.channelType === ChannelType.LINKEDIN) {
            if (
                !contact?.linkedinProfileUrl ||
                contact?.linkedinProfileVerification?.status === ProspectLinkedinProfileVerifiedStatus.profile_not_found
            ) {
                return { valid: false, reason: DisabledPrimaryActionReason.RECIPIENT_MISSING_LINKEDIN_PROFILE };
            }
            if (connectionStatus === 'pending') {
                return { valid: false, reason: DisabledPrimaryActionReason.RECIPIENT_CONNECTION_PENDING };
            }
        }

        if (activeMessage.channelType === ChannelType.EMAIL && getEmailState(contact?.email) !== 'Email exists') {
            return { valid: false, reason: DisabledPrimaryActionReason.RECIPIENT_INVALID_EMAIL };
        }

        return { valid: true };
    }, [activeMessage.channelType, contact, connectionStatus, isMobile]);

    const isValidConfiguration = useCallback((): ValidationResult => {
        if (activeMessage.channelType === ChannelType.LINKEDIN) {
            if (extensionStatus === 'NOT_INSTALLED') {
                return { valid: false, reason: DisabledPrimaryActionReason.EXTENSION_NOT_INSTALLED };
            }
            if (extensionStatus === 'MISSING_CONFIG') {
                return { valid: false, reason: DisabledPrimaryActionReason.LINKEDIN_MISSING_CONFIG };
            }
            if (extensionStatus === 'NOT_AUTHENTICATED') {
                return { valid: false, reason: DisabledPrimaryActionReason.LINKEDIN_NOT_AUTHENTICATED };
            }
            if (!connectionStatus || connectionStatus === 'syncing') {
                return { valid: false, reason: DisabledPrimaryActionReason.LINKEDIN_SYNCING };
            }
        }

        if (activeMessage.channelType === ChannelType.EMAIL) {
            if (!emailStatus) {
                return { valid: false, reason: DisabledPrimaryActionReason.EMAIL_NOT_CONNECTED };
            }
            if (emailStatus === ChannelAccountStatus.NOT_AUTHENTICATED) {
                return { valid: false, reason: DisabledPrimaryActionReason.EMAIL_NOT_AUTHENTICATED };
            }
            if (emailStatus === ChannelAccountStatus.SCOPES) {
                return { valid: false, reason: DisabledPrimaryActionReason.EMAIL_SCOPES };
            }
        }

        return { valid: true };
    }, [activeMessage.channelType, extensionStatus, emailStatus, connectionStatus]);

    return {
        isValidMessage,
        isValidRecipient,
        isValidConfiguration,
    };
};

type MessageActionsProps = {
    prospectId: string | undefined;
    activeMessage: ActiveMessage;
    setDraftMessage: (message: ActiveMessage) => void;
    removeDraftMessage: (options: { all: boolean }) => void;
    setIsGeneratingMessage: (isGenerating: boolean) => void;
    setIsSending: (isSending: boolean) => void;
    messageType: MessageType;
    outreachSuggestion: OutreachSuggestion | undefined;
    contact: ProspectDataDto | undefined;
};

const useMessageActions = ({
    prospectId,
    activeMessage,
    setDraftMessage,
    removeDraftMessage,
    setIsGeneratingMessage,
    setIsSending,
    messageType,
    outreachSuggestion,
    contact,
}: MessageActionsProps) => {
    const { sendMessage, addNote } = useMessages({ prospectId });
    const {
        messaging: {
            getRegeneratedMessage,
            regenerateMessage: regenerateMessageMutation,
            generateMessageAsEmail: generateMessageAsEmailMutation,
        },
    } = useSdk();

    const { mutateAsync: previewRegeneratedMessageContent } = useMutation(getRegeneratedMessage());
    const { mutateAsync: regenerateMessageContent } = useMutation(regenerateMessageMutation());
    const { mutateAsync: generateMessageAsEmail } = useMutation(generateMessageAsEmailMutation());

    const regenerateMessage = async ({ feedback }: { feedback: string }) => {
        if (activeMessage.channelType === ChannelType.NOTE) return;

        setIsGeneratingMessage(true);
        try {
            const messageId = outreachSuggestion?.id;
            if (messageId) {
                const regeneratedMessage = await regenerateMessageContent({
                    messageId,
                    feedback,
                    actionType: messageType as BEMessageType,
                    content: activeMessage.content,
                    subjectLine: activeMessage.subjectLine,
                });
                setDraftMessage({
                    content: regeneratedMessage.content,
                    subjectLine: regeneratedMessage.subjectLine,
                    channelType: activeMessage.channelType,
                });
            } else {
                const regeneratedMessage = await previewRegeneratedMessageContent({
                    prospectId,
                    channelType:
                        activeMessage.channelType === ChannelType.EMAIL ? BEChannelType.EMAIL : BEChannelType.LINKEDIN,
                    actionType: messageType as BEMessageType,
                    feedback,
                    message: {
                        content: activeMessage.content,
                        subjectLine: activeMessage.subjectLine,
                    },
                });
                setDraftMessage({
                    content: regeneratedMessage.content,
                    subjectLine: regeneratedMessage.subjectLine,
                    channelType: activeMessage.channelType,
                });
            }
        } catch (error) {
            console.error('Failed to regenerate message:', error);
            throw new MessageNotSentError('An error occurred when trying to regenerate your message.');
        } finally {
            setIsGeneratingMessage(false);
        }
    };

    const primaryAction = async (options: { omitMessage?: boolean } = { omitMessage: false }) => {
        if (!prospectId || !contact) return;

        const tempMessage = {
            content: activeMessage.content,
            subjectLine: activeMessage.subjectLine,
            channelType: activeMessage.channelType,
        };
        removeDraftMessage({ all: true });
        setIsSending(true);
        try {
            if (messageType === MessageType.note) {
                await addNote({
                    content: activeMessage.content,
                    title: undefined,
                });
            } else {
                const suggestedChannelType = outreachSuggestion?.channelType;
                const messageToSend = {
                    body: options.omitMessage ? '' : activeMessage.content,
                    subjectLine: options.omitMessage ? '' : activeMessage.subjectLine,
                    actionType: messageType,
                    messageId: outreachSuggestion?.id,
                };
                await sendMessage({
                    message: messageToSend,
                    recipient: {
                        linkedinUserId: contact.linkedinUserId,
                        prospectId,
                        prospectName: contact.fullName,
                    },
                    onError: () => {
                        console.error('Message send failed');
                    },
                    suggestedChannelType,
                });
            }
            log('Message sent successfully');
        } catch (error) {
            console.error('Failed to send message:', error);
            setDraftMessage(tempMessage);
        } finally {
            setTimeout(() => {
                setIsSending(false);
            }, 2000);
        }
    };

    const handleGenerateMessageAsEmail = async () => {
        if (!outreachSuggestion?.id) {
            return;
        }
        setIsGeneratingMessage(true);
        try {
            const newMessage = await generateMessageAsEmail({
                messageId: outreachSuggestion?.id,
                content: activeMessage.content,
                subjectLine: activeMessage.subjectLine,
            });
            setDraftMessage({
                content: newMessage.content,
                subjectLine: newMessage.subjectLine,
                channelType: ChannelType.EMAIL,
            });
        } catch (error) {
            console.error('Failed to generate email message:', error);
        } finally {
            setIsGeneratingMessage(false);
        }
    };

    return {
        regenerateMessage,
        messageComposerPrimaryAction: primaryAction,
        handleGenerateMessageAsEmail,
    };
};

export function MessageComposerProvider({ children, prospectId }: { children: React.ReactNode; prospectId: string }) {
    const { linkedinAccount } = useLinkedin();
    const {
        prospect: { getProspectById },
        trackers: { getProspectLinkedinTracker },
    } = useSdk();

    const {
        draftMessage,
        setDraftMessage,
        removeDraftMessage,
        isGeneratingMessage,
        setIsGeneratingMessage,
        isSending,
        setIsSending,
        isConversation,
        draftCreatedThisSession,
        setDraftCreatedThisSession,
    } = useMessageState(prospectId);

    const { outreachSuggestion, subscribedOutreachSuggestion, isOutreachSuggestionLoading } =
        useMessageSubscription(prospectId);
    const { subjectLines } = useSubjectLines(prospectId);

    const { data: prospect } = useTanQuery(getProspectById(prospectId));
    const { data: prospectLinkedinTracker } = useTanQuery(getProspectLinkedinTracker(prospectId, linkedinAccount?.id));
    const contact = useMemo(() => prospect?.data, [prospect]);
    const connectionStatus = useMemo(() => prospectLinkedinTracker?.connectionStatus, [prospectLinkedinTracker]);
    const activeMessage: ActiveMessage = useMemo(() => {
        if (isSending) {
            return {
                content: '',
                subjectLine: '',
                channelType: draftMessage?.channelType || outreachSuggestion?.channelType || ChannelType.LINKEDIN,
            };
        }
        if (isOutreachSuggestionLoading)
            return {
                content: '',
                subjectLine: '',
                channelType: draftMessage?.channelType || ChannelType.LINKEDIN,
            };

        if (draftMessage?.content || draftMessage?.subjectLine) {
            return {
                content: draftMessage.content || '',
                subjectLine:
                    draftMessage.channelType === ChannelType.LINKEDIN
                        ? ''
                        : draftMessage?.subjectLine || subjectLines[0] || '',
                channelType: draftMessage.channelType,
            };
        }

        const outreachSuggestionShouldBeActive = outreachSuggestion?.content && !isConversation;
        const content = outreachSuggestionShouldBeActive ? outreachSuggestion.content : draftMessage?.content || '';
        const channelType = outreachSuggestionShouldBeActive
            ? outreachSuggestion.channelType
            : draftMessage?.channelType || ChannelType.LINKEDIN;

        const subjectLine =
            channelType === ChannelType.LINKEDIN
                ? ''
                : subjectLines[0] || (outreachSuggestionShouldBeActive ? outreachSuggestion.subjectLine : '');

        let shouldStream = false;
        if (
            content?.length &&
            outreachSuggestion?.createdAt &&
            Math.abs(dayjs.utc(outreachSuggestion?.createdAt).diff(dayjs.utc(), 'seconds')) < 20
        ) {
            shouldStream = true;
        }

        return {
            content,
            subjectLine,
            channelType,
            shouldStream,
        };
    }, [draftMessage, outreachSuggestion, subjectLines, isSending, isConversation, isOutreachSuggestionLoading]);

    const messageType = useMemo(() => {
        if (activeMessage.channelType === ChannelType.LINKEDIN) {
            return connectionStatus === 'not_connected'
                ? MessageType.linkedinConnectionRequest
                : MessageType.linkedinMessage;
        } else if (activeMessage.channelType === ChannelType.EMAIL) {
            return MessageType.emailMessage;
        } else if (activeMessage.channelType === ChannelType.NOTE) {
            return MessageType.note;
        }
        return MessageType.linkedinMessage;
    }, [activeMessage.channelType, connectionStatus]);

    const { isValidMessage, isValidRecipient, isValidConfiguration } = useMessageValidation(
        messageType,
        activeMessage,
        contact,
        connectionStatus
    );

    const { regenerateMessage, messageComposerPrimaryAction, handleGenerateMessageAsEmail } = useMessageActions({
        prospectId,
        activeMessage,
        setDraftMessage,
        removeDraftMessage,
        setIsGeneratingMessage,
        setIsSending,
        messageType,
        outreachSuggestion,
        contact,
    });

    const handleBodyTextChange = useCallback(
        (value: string) => {
            if (!prospectId) return;
            const isSameAsDraft = htmlToPlain(value) === htmlToPlain(draftMessage?.content);
            if (value && htmlToPlain(value) !== htmlToPlain(activeMessage?.content) && !isSameAsDraft) {
                setDraftMessage({
                    content: value,
                    subjectLine: activeMessage.subjectLine,
                    channelType: activeMessage.channelType,
                });
                if (!draftMessage?.channelType) setDraftCreatedThisSession(true);
            }
        },
        [prospectId, activeMessage, setDraftMessage]
    );

    const handleSubjectLineChange = useCallback(
        (value: string) => {
            if (!prospectId) return;
            const isSameAsDraft = htmlToPlain(value) === htmlToPlain(draftMessage?.subjectLine);
            if (value && htmlToPlain(value) !== htmlToPlain(activeMessage?.subjectLine) && !isSameAsDraft) {
                setDraftMessage({
                    content: activeMessage.content,
                    subjectLine: value,
                    channelType: activeMessage.channelType,
                });
                if (!draftMessage?.channelType) setDraftCreatedThisSession(true);
            }
        },
        [prospectId, activeMessage, setDraftMessage]
    );

    const handleChannelTypeChange = useCallback(
        (channelType: ChannelType) => {
            if (!prospectId || channelType === activeMessage.channelType) return;
            setDraftMessage({
                content: activeMessage.content || '',
                subjectLine: activeMessage.subjectLine || '',
                channelType,
            });
        },
        [prospectId, activeMessage, setDraftMessage]
    );

    const handleShowSuggestedMessage = useCallback(() => {
        if (outreachSuggestion && prospectId) {
            setDraftMessage({
                content: outreachSuggestion.content,
                subjectLine: outreachSuggestion.subjectLine,
                channelType: outreachSuggestion.channelType,
            });
        }
    }, [outreachSuggestion, prospectId, setDraftMessage]);

    useEffect(() => {
        if (
            dayjs.utc(subscribedOutreachSuggestion?.OutreachSuggestions?.[0]?.updatedAt).diff(dayjs.utc()) <
            2 * 60 * 1000
        ) {
            setIsGeneratingMessage(subscribedOutreachSuggestion?.OutreachSuggestions?.[0]?.isGenerating || false);
        }
    }, [subscribedOutreachSuggestion]);

    useEffect(() => {
        if (draftCreatedThisSession) {
            setDraftCreatedThisSession(false);
        }
    }, [prospectId]);

    const suggestedDiffersFromDraft = useMemo(() => {
        return !!(
            outreachSuggestion?.content &&
            htmlToPlain(activeMessage.content) !== htmlToPlain(outreachSuggestion.content) &&
            activeMessage.channelType !== ChannelType.NOTE
        );
    }, [outreachSuggestion, activeMessage.content]);

    const validationResult: ValidationResult = useMemo(() => {
        const configurationValidationResult = isValidConfiguration();
        const validMessage = isValidMessage();
        const validRecipient = isValidRecipient();

        if (!configurationValidationResult.valid) {
            return configurationValidationResult;
        }
        if (!validRecipient.valid) {
            return validRecipient;
        }
        if (!validMessage.valid) {
            return validMessage;
        }
        return { valid: true };
    }, [isValidMessage, isValidRecipient, isValidConfiguration]);

    return (
        <MessageComposerContext.Provider
            value={{
                body: activeMessage.content,
                bodyLength: activeMessage.content?.length || 0,
                handleBodyTextChange,
                subjectLine: activeMessage.subjectLine,
                handleSubjectLineChange,
                prospectId,
                messageType,
                handleShowSuggestedMessage,
                setDraftMessage,
                suggestedDiffersFromDraft: suggestedDiffersFromDraft && !draftCreatedThisSession,
                channelType: activeMessage.channelType,
                handleChannelTypeChange,
                isSendable: validationResult.valid,
                messageComposerPrimaryAction,
                isGeneratingMessage,
                regenerateMessage,
                isSending,
                handleGenerateMessageAsEmail,
                disabledPrimaryActionReason: validationResult?.reason,
                isStreaming: activeMessage.shouldStream,
                loadingOutreachSuggestion: isOutreachSuggestionLoading,
            }}
        >
            {children}
        </MessageComposerContext.Provider>
    );
}

export function useMessageComposer() {
    const context = useContext(MessageComposerContext);
    if (context === undefined) {
        console.error('useMessageComposer must be used within a MessageComposerProvider', new Error().stack);
    }
    return context;
}
