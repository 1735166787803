import { Box, ButtonBase, Tooltip, Typography } from '@mui/material';
import { Button } from '@zaplify/ui';
import { Command, Search } from 'lucide-react';
import * as React from 'react';
import { useGlobalSearchModal } from '../../../components/GlobalSearch/hooks/use-global-search-modal';

export const SearchBox = ({ sideBarIsOpen }: { sideBarIsOpen: boolean }) => {
    const { open: openGlobalSearchModal, isOpen, close } = useGlobalSearchModal();

    React.useEffect(() => {
        function handleKeyDown(e: KeyboardEvent) {
            if ((navigator?.platform?.toLowerCase().includes('mac') ? e.metaKey : e.ctrlKey) && e.key === 'k') {
                e.preventDefault();
                e.stopPropagation();
                console.log('here2');

                if (isOpen) {
                    close();
                } else {
                    openGlobalSearchModal();
                }
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <Box sx={{ paddingX: '4px !important', paddingY: '8px !important' }}>
            {sideBarIsOpen ? (
                <Button
                    className="search-button"
                    variant="secondary"
                    borderRadius="6px"
                    onClick={() => openGlobalSearchModal()}
                    sx={{
                        display: 'flex',
                        gap: 3,
                        height: '38px !important',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        minWidth: '180px',
                        border: '0 !important',
                        paddingX: '8px !important',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '6px',
                        }}
                    >
                        <Search
                            strokeWidth="1px"
                            size="16px"
                            style={{ color: 'var(--grey-palette-grey-6, #3A4B59)' }}
                        />
                        <Typography
                            variant="body2"
                            sx={{
                                color: 'var(--grey-palette-grey-6, #3A4B59)',
                                fontSize: '14px',
                                fontWeight: 500,
                            }}
                        >
                            Search
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 'var(--S-2xs, 4px)',
                            color: '#6B7985',
                        }}
                    >
                        <Command size="14px" strokeWidth="2px" />
                        <Typography variant="body2">K</Typography>
                    </Box>
                </Button>
            ) : (
                <ButtonBase
                    sx={{
                        border: '0.8px solid #E1E6E8',
                        padding: 1,
                        backgroundColor: '#fff',
                        borderRadius: '8px',
                    }}
                    onClick={() => openGlobalSearchModal()}
                >
                    <Tooltip title={'Search'} placement="right" arrow>
                        <div style={{ height: '20px' }}>
                            <Search strokeWidth="1px" size="20px" color="#6B7985" />
                        </div>
                    </Tooltip>{' '}
                </ButtonBase>
            )}
        </Box>
    );
};
