import { BillingPeriodUnit } from '@zaplify/subscriptions';
import { sleep } from '@zaplify/utils';
import { useSdk } from '../sdk/use-sdk';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useAuth } from '../providers/authentication-provider';

export const useSeatsAndCredits = () => {
    // TODO: remove this once we have a proper auth provider on top
    const {
        authState: { user },
    } = useAuth();

    const {
        user: { getUserCreditsAndSeatSummary },
    } = useSdk();

    const {
        data: userCreditsAndSeatSummary,
        isLoading: loading,
        error,
        refetch,
    } = useQuery(
        user?.id ? getUserCreditsAndSeatSummary(user.id) : { queryKey: [], queryFn: () => Promise.resolve(null) }
    );
    const { seat, seatSummary } = userCreditsAndSeatSummary || {};
    const unableToFetch = error !== undefined;

    const credits = useMemo(
        () => ({
            used: seat?.credits.creditsUsed,
            usedThisMonth: seat?.credits.creditsUsedThisMonth,
            available: seat?.credits.creditsAvailable,
            organizationCreditsAvailable: seat?.credits.organizationCreditsAvailable,
            personalCreditsAvailable: seat?.credits.personalCreditsAvailable,
            personalCredits: seat?.credits.personalCredits,
            periodLimit: seat?.credits.creditsLimit,
            periodUnit: seat?.subscription.periodUnit || BillingPeriodUnit.MONTH,
            periodAmount: seat?.subscription.periodAmount || 1,
            periodStart: seat?.subscription.renewedAt || new Date(),
            periodEnd: seat?.subscription.periodEnd || new Date(),
        }),
        [seat]
    );

    const waitForSeats = async (retries = 20, delay = 3000) => {
        for (let i = 0; i < retries; i++) {
            try {
                const success = await refetch();
                console.log('Refetch seats and credits success:', success);
                if (success) {
                    return;
                } else {
                    throw new Error('Refetch failed');
                }
            } catch (error) {
                if (i < retries - 1) {
                    console.log('Refetch seats and credits failed, retrying');
                    await sleep(delay);
                } else {
                    console.error(`Refetch failed after ${retries} retries:`, error);
                }
            }
        }
    };

    return { seat, seatSummary, credits, refetch, loading, waitForSeats, unableToFetch };
};
