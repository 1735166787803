import { useNavigate, useParams } from 'react-router-dom';
import HubspotView from './apps/hubspot';
import PipedriveView from './apps/pipedrive';
import SalesforceView from './apps/salesforce';
import UpsalesView from './apps/upsales';
import { ViewContainer } from './containers';
import IntegrationsView from './integrationsView';
import { useAppOAuth } from './useAppOAuth';

export const Component = () => {
    const navigate = useNavigate();
    const params = useParams();
    const appRoute = params?.app;

    useAppOAuth(appRoute, navigate);

    return (
        <ViewContainer style={{ height: '100%', overflowY: 'auto' }}>
            {(appRoute === 'hubspot' && <HubspotView />) ||
                // (appRoute === 'webhooks' && <WebhooksView />) ||
                (appRoute === 'pipedrive' && <PipedriveView />) ||
                (appRoute === 'upsales' && <UpsalesView />) ||
                (appRoute === 'salesforce' && <SalesforceView />) || <IntegrationsView />}
        </ViewContainer>
    );
};

Component.displayName = 'IntegrationsView';
