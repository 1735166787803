import './polyfills';
// import { scan } from 'react-scan';

import { createRoot } from 'react-dom/client';
import 'typeface-roboto';
import App from './App';
import './index.css';
import '@shadcn/ui/lib/utils/global.css';

// import * as serviceWorker from './serviceWorker';
import { LocalStorageKeys } from './config';
import LogRocket from 'logrocket';
if (import.meta.env.MODE === 'production') {
    LogRocket.init('kdqs9m/appzaplify', { ingestServer: 'https://core5.andsend.com/la' });
}
// scan({
//     enabled: true,
// });
const container = document.getElementById('root');
const root = createRoot(container);

root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

function cleanupLocalStorage() {
    localStorage.removeItem(LocalStorageKeys.DEP_SELECTED_ORGANIZATION_ID);
    localStorage.removeItem(LocalStorageKeys.DEP_CURRENT_ORGANIZATION_ID);
    localStorage.removeItem(LocalStorageKeys.DEP_PREVIOUS_ORGANIZATION_ID);
    localStorage.removeItem(LocalStorageKeys.DEP_JOIN_ORGANIZATION_NAME);
    localStorage.removeItem(LocalStorageKeys.DEP_AUTHENTICATE_AS_USER);
    localStorage.removeItem(LocalStorageKeys.DEP_TURN_ON_NEW_ZAPLIFY);
    // @TODO    Fill up with more removeItems that aren't needed anymore.
    //          We want to clean up our users local storage from legacy.
}

if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        // Unregister the specific service worker
        navigator.serviceWorker
            .getRegistrations()
            .then((registrations) => {
                registrations.forEach((registration) => {
                    if (registration.active && registration.active.scriptURL.includes('/serviceWorker1.js')) {
                        registration.unregister().then(() => {
                            console.log('Old service worker unregistered');
                        });
                    }
                });
            })
            .then(() => {
                navigator.serviceWorker
                    .register('/serviceWorker1.js')
                    .then((registration) => {
                        console.log('Service Worker registered with scope:', registration.scope);
                    })
                    .catch((error) => {
                        console.error('Service Worker registration failed:', error);
                    });
            });
    });
}
cleanupLocalStorage();
console.log('andsend cache testing: 3');
