import { getZaplifySdk } from '@zaplify/sdk';
import { handleErrorNotification, setNotification, updateIntegrations } from '../../../redux/actions';
import { getSpecialPipedriveMapping } from './helpers';
import {
    updateAvailableFields,
    updateFieldMapping,
    updatePipedriveActiveState,
    updatePipedriveCustomSwitches,
    updateSaveStatus,
    updateSettings,
} from './slice';

export const getPipedriveSettings = () => async (dispatch, getState) => {
    const profilesSdk = getZaplifySdk().profiles;
    const user = getState().user.zaplifyUser;
    try {
        let pipedriveSettings: any = await profilesSdk.pipedrive.getSettings(user.userOrganization.id);

        if (pipedriveSettings) {
            dispatch(updateSettings(pipedriveSettings));
            dispatch(updatePipedriveSaveStatus());
        }
    } catch (error) {
        console.log('error fetching pipedrive settings');
        dispatch(
            updateSettings({
                account: {},
                integrationScope: {},
                fieldMapping: [],
                isActive: false,
                availableFields: {},
                firstTime: false,
            })
        );
    } finally {
    }
};

export const getPipedriveFields = () => async (dispatch, getState) => {
    const profilesSdk = getZaplifySdk().profiles;
    const user = getState().user.zaplifyUser;
    try {
        const fields = await profilesSdk.pipedrive.getAllFields(user.userOrganization.id);
        dispatch(updateAvailableFields(fields));
    } catch (error) {
        console.log('error loading fields');
    } finally {
    }
};

export const updatePipedriveSettings = () => async (dispatch, getState) => {
    const profilesSdk = getZaplifySdk().profiles;
    const user = getState().user.zaplifyUser;
    const pipedrive = getState().pipedrive;
    try {
        const fieldMapping = [...pipedrive.fieldMapping];

        const newFields = getSpecialPipedriveMapping(pipedrive, fieldMapping);
        const newFieldMapping = [...fieldMapping, ...newFields];

        const updateSettingsObj = {
            organizationId: user.userOrganization.id,
            fieldMapping: newFieldMapping,
            integrationScope: pipedrive.integrationScope,
            isActive: pipedrive.isActive,
        };
        const errorCallback = (error) => {
            throw new Error(error);
        };

        await profilesSdk.pipedrive.updateSettings(updateSettingsObj, errorCallback);
        const updatedSettings: any = await profilesSdk.pipedrive.getSettings(user.userOrganization.id);
        dispatch(updateFieldMapping(updatedSettings.fieldMapping));
        dispatch(updatePipedriveSaveStatus());
        dispatch(getPipedriveFields());
        dispatch(updatePipedriveCustomSwitches(false));
        if (pipedrive.firstTime) {
            await dispatch(resumeOrPausePipedrive(true));
            dispatch(setNotification('Successfully started your pipedrive integration', 'success'));
        } else {
            dispatch(setNotification('Successfully updated your pipedrive settings', 'success'));
        }
        window?.analytics?.track('User Updated Integration', {
            type: 'Pipedrive',
        });
    } catch (error: any) {
        if (error.message) {
            const parseError = JSON.parse(error.message);
            dispatch(handleErrorNotification(parseError.data.message, 2000));
        } else {
            dispatch(setNotification("Couldn't update your pipedrive settings", 'error'));
        }
        console.log('error loading fields');
    } finally {
        dispatch(updateIntegrations());
    }
};

export const resumeOrPausePipedrive = (value) => async (dispatch, getState) => {
    const profilesSdk = getZaplifySdk().profiles;
    const user = getState().user.zaplifyUser;
    try {
        if (value) await profilesSdk.pipedrive.resume(user.userOrganization.id);
        else await profilesSdk.pipedrive.pause(user.userOrganization.id);
        await dispatch(updatePipedriveActiveState(value));
    } catch (error) {
        console.log('error resume or pause');
    } finally {
        dispatch(updateIntegrations());
    }
};

export const updatePipedriveSaveStatus = () => async (dispatch, getState) => {
    try {
        const pipedrive = getState().pipedrive;
        const fieldMapping = pipedrive.fieldMapping;

        let saveStatus = true;

        fieldMapping.forEach((field) => {
            const secondField = field.personField || field.organizationField;
            //Check source
            if (field.prospectField === '' || secondField === undefined || secondField === '') {
                saveStatus = false;
            }
        });
        dispatch(updateSaveStatus(saveStatus));
    } catch (error) {
        console.log('error loading fields');
    } finally {
    }
};

export const disconnectPipedrive = () => async (dispatch, getState) => {
    const profilesSdk = getZaplifySdk().profiles;
    const user = getState().user.zaplifyUser;
    try {
        await profilesSdk.pipedrive.disconnect(user.userOrganization.id);

        //Resetting pipedrive state to initial
        dispatch(
            updateSettings({
                account: {},
                integrationScope: {},
                fieldMapping: [],
                isActive: false,
                availableFields: {},
                firstTime: false,
            })
        );

        dispatch(setNotification('Successfully disconnected your pipedrive integration', 'success'));
    } catch (error) {
        console.log('error resume or pause');
    } finally {
        dispatch(updateIntegrations());
    }
};
