import { GlobalSearchPeopleInCompanyResultDto, PeopleResponseDto, ProspectOwnerDto } from '@zaplify/prospects';
import { StyledPersonRow, StyledPersonsSection } from './PersonTable.styled';
import { Avatar, Box, TextField, Typography, debounce } from '@mui/material';
import { Briefcase, Check, MapPin, Search, UsersRound } from 'lucide-react';
import { useEffect, useState } from 'react';
import { Loader } from '@zaplify/frontend-common';
import ProspectsPerCompany from '../../../../views/campaign/newCampaign/addProspects/prospectSearch/components/company-card/prospect-row/prospects-per-company';
import { CardClass, userAddedProspectInGlobalSearch, userClickedShowMorePeopleInGlobalSearch } from '../../tracking';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useGlobalSearch } from '../../hooks/GlobalSearch';
import { stringToColor } from '../../../../views/chats/utils/string-to-color';
import { UsersAvatarGroup } from '../../../avatars/users-avatar-group';
import { useAtom } from 'jotai';
import { channelAccountsAtom } from '../../../../atoms/chats';
import AddPersonButton from '../../../atoms/add-person-button';

export const PersonTable = ({
    userId,
    prospects,
    companyId,
    groups,
    onAdd,
    setNumberOfPeopleShown,
}: {
    userId: string;
    prospects: GlobalSearchPeopleInCompanyResultDto;
    companyId: string;
    groups: { id: string; name: string }[];
    onAdd: (personId: string, groupId?: string) => Promise<boolean>;
    setNumberOfPeopleShown: (a: number) => void;
}) => {
    const { searchInCompany: searchGlobalSearch } = useGlobalSearch();
    const { searchForPeopleEnabled } = useFlags();

    const [isLoadingSearchResult, setIsLoadingSearchResult] = useState(false);
    const [isLoadingShowMore, setIsLoadingShowMore] = useState(false);
    const [searchResults, setSearchResults] = useState<GlobalSearchPeopleInCompanyResultDto>(null);
    const [searchTerm, setSearchTerm] = useState('');

    const showSearchResult = searchTerm.length > 2 && !isLoadingSearchResult;

    const hasMorePages = prospects?.pagination?.numberOfPages > prospects?.pagination?.page;
    const loading = prospects === null || isLoadingSearchResult;

    const peopleToShow: PeopleResponseDto[] = loading ? null : showSearchResult ? searchResults.data : prospects.data;

    const onExpand = () => {
        setIsLoadingShowMore(true);
        setNumberOfPeopleShown(prospects.data.length + 10);
        userClickedShowMorePeopleInGlobalSearch(prospects.data.length);
    };

    useEffect(() => {
        setSearchTerm('');
        setSearchResults(null);
        setIsLoadingSearchResult(false);
        setIsLoadingShowMore(false);
    }, [prospects]);

    useEffect(() => {
        const debouncedSearch = debounce(async () => {
            setSearchResults(null);
            if (!searchTerm || searchTerm.length < 3) {
                setIsLoadingSearchResult(false);
                return;
            }
            try {
                setIsLoadingSearchResult(true);
                const prospects = await performSearch(searchTerm);
                if (!prospects) {
                    throw new Error('No prospects found');
                }
                setSearchResults(prospects);
            } catch {
                setSearchResults(null);
            } finally {
                setIsLoadingSearchResult(false);
            }
        }, 500);

        debouncedSearch();

        // Cleanup function to cancel the debounce if the component unmounts
        return () => {
            debouncedSearch.clear();
        };
    }, [searchTerm]); // Now searchTerm is a dependency

    async function performSearch(term: string) {
        const query = {
            match: searchTerm,
            perPage: 15,
            page: 1,
            companyId: companyId,
        };
        return searchGlobalSearch(query);
    }

    const handleOnSearchValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        if (newValue.length > 2) {
            setIsLoadingSearchResult(true);
        } else {
            setIsLoadingSearchResult(false);
        }

        setSearchTerm(newValue);
    };

    return (
        <StyledPersonsSection>
            {searchForPeopleEnabled && Boolean(prospects?.data?.length) && (
                <Box sx={{ marginBottom: '20px', alignSelf: 'flex-start', width: '100%' }}>
                    <TextField
                        fullWidth
                        size="small"
                        placeholder="Search people..."
                        value={searchTerm}
                        onChange={handleOnSearchValueChange}
                        InputProps={{
                            startAdornment: (
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Search
                                        strokeWidth={1}
                                        className="icon"
                                        size={14}
                                        stroke="#6B7985"
                                        style={{ marginRight: '4px' }}
                                    />
                                </Box>
                            ),
                        }}
                    />
                </Box>
            )}
            {Boolean(peopleToShow?.length) ? (
                <>
                    {peopleToShow?.map((prospect) => (
                        <PersonRow
                            userId={userId}
                            key={prospect._id}
                            person={prospect}
                            groups={groups}
                            onAdd={onAdd}
                            totalNumberOfRows={prospects?.data?.length}
                        />
                    ))}
                    <ProspectsPerCompany
                        onExpandCollapse={() => onExpand()}
                        loading={isLoadingShowMore}
                        showLess={false}
                        showInfo={!hasMorePages}
                        showMore={hasMorePages && !showSearchResult}
                    />
                </>
            ) : loading ? (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                        width: '100%',
                        '& > div': {
                            justifyContent: 'center',
                            alignItems: 'center',
                        },
                    }}
                >
                    <Loader />
                </Box>
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                        width: '100%',
                    }}
                >
                    <Typography>No people found</Typography>
                </Box>
            )}
        </StyledPersonsSection>
    );
};

export const PersonRow = ({
    userId,
    person,
    groups,
    totalNumberOfRows,
    onAdd,
}: {
    userId: string;
    person: PeopleResponseDto;
    groups: { id: string; name: string }[];
    totalNumberOfRows: number;
    onAdd: (personId: string, groupId?: string) => Promise<boolean>;
}) => {
    const [isAdded, setIsAdded] = useState(false);
    const getPersonInitials = (prospect: PeopleResponseDto) => {
        const initials = `${prospect?.fullName.split(' ')?.[0]?.[0] || ''}${
            prospect?.fullName.split(' ')?.[1]?.[0] || ''
        }`;
        return initials;
    };
    const onClickAdd = (personId: string, groupId?: string) => {
        onAdd(personId, groupId).then((success) => {
            if (!success) setIsAdded(false);
        });
        setIsAdded(true);
    };

    return (
        <StyledPersonRow>
            <Avatar
                sx={{
                    bgcolor: stringToColor(person?.fullName),
                }}
            >
                {getPersonInitials(person)}
            </Avatar>
            <Box className="details">
                <Name prospect={person} />
                <Title person={person} />
                <Location person={person} />
            </Box>
            <ActionButton
                userId={userId}
                groups={groups}
                totalNumberOfRows={totalNumberOfRows}
                onAdd={(groupId) => onClickAdd(person._id, groupId)}
                isAssigned={person.isAssigned || isAdded}
                owners={person.assignedTo}
            />
        </StyledPersonRow>
    );
};

export const SuccessTag = ({ children }: { children: React.ReactNode }) => {
    return (
        <Box className="success-tag">
            <Box className="icon-container">
                <Check strokeWidth={1} className="icon" />
            </Box>
            <Typography>{children}</Typography>
        </Box>
    );
};

export const Name = ({ prospect }: { prospect: PeopleResponseDto }) => {
    return (
        <Box className="name">
            <Typography className="text">{prospect.fullName}</Typography>
        </Box>
    );
};

export const Title = ({ person }: { person: PeopleResponseDto }) => {
    return (
        <Box className="title">
            <Box className="icon-container">
                <Briefcase strokeWidth={1} className="icon" />
            </Box>
            <Typography className="text">
                {person?.title?.length > 60 ? `${person.title.slice(0, 60)}...` : person.title}
            </Typography>
        </Box>
    );
};

export const Location = ({ person }: { person: PeopleResponseDto }) => {
    return (
        <Box className="location">
            <Box className="icon-container">
                <MapPin strokeWidth={1} className="icon" />
            </Box>
            <Typography className="text">{person.location}</Typography>
        </Box>
    );
};

const getAddedText = (disabled: boolean, ownerName?: string) => {
    if (disabled) {
        return ownerName ? `Added by ${ownerName}` : 'Added';
    }

    return 'Add';
};

export const ActionButton = ({
    userId,
    groups,
    totalNumberOfRows,
    onAdd,
    isAssigned,
    owners,
}: {
    userId: string;
    groups: { id: string; name: string }[];
    totalNumberOfRows: number;
    onAdd: (groupId?: string) => void;
    isAssigned?: boolean;
    owners?: ProspectOwnerDto[];
}) => {
    const [channelAccounts] = useAtom(channelAccountsAtom);

    const onClickAdd = (groupId?: string) => {
        onAdd(groupId);
        userAddedProspectInGlobalSearch(totalNumberOfRows, CardClass.Account);
    };

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    gap: '8px',
                }}
            >
                {owners?.length > 0 && (
                    <UsersAvatarGroup
                        users={owners}
                        size={'small'}
                        userId={userId}
                        userAvatarUrl={channelAccounts?.linkedIn?.avatarUrl}
                        startIcon={<UsersRound size={20} strokeWidth={1} style={{ marginRight: '2px' }} />}
                        ignoreYou
                        maxNumAvatars={2}
                    />
                )}
                <AddPersonButton
                    added={isAssigned}
                    isAddingLoader={false}
                    handleAddClick={(e, groupId) => onClickAdd(groupId)}
                    groups={groups}
                    variant="secondary"
                    addedText={getAddedText(isAssigned, owners?.[0]?.fullName)}
                    className="add-person-button"
                />
            </Box>
        </>
    );
};
