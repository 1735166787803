import { SearchHistory } from '../hooks/SearchHistory';
import { SearchSectionTitle } from '../globalSearchPopup';
import { SearchHistoryRow } from '../SearchHistoryRow';

export const RecentSearchSection = ({
    searchHistory,
    onClickSearchHistoryEntry,
}: {
    searchHistory: SearchHistory;
    onClickSearchHistoryEntry: (value: string) => void;
}) => {
    return (
        <div style={{ padding: '18px' }}>
            <SearchSectionTitle title="Recent searches" size="12px" />
            {searchHistory?.map((search, index) => (
                <SearchHistoryRow
                    key={index}
                    searchTerm={search.searchTerm}
                    onClick={() => onClickSearchHistoryEntry(search.searchTerm)}
                />
            ))}
        </div>
    );
};
