import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../../redux/store/configureStore';
import { paths } from '../../../routes/paths';
import { firebaseActionLink } from '../../../services/utils/urlHelper';
import CreatePasswordForm from '../components/auth-create-new-password';
import ResetPasswordForm from '../components/auth-reset-password-form';
import { ResetPasswordViews } from '../config';
import { Loader, ViewContainer } from '../containers';

export const TextFieldNames = {
    email: 'email',
    password: 'password',
};

export const Component = () => {
    const isLoadingUser = useAppSelector((state) => state.user.isLoadingData);
    const [currentView, setCurrentView] = useState(ResetPasswordViews.forgotPasswordWithLoader);

    useEffect(() => {
        const mode = firebaseActionLink.getCurrentMode();
        const actionCode = firebaseActionLink.getCurrentActionCode();
        if (mode === firebaseActionLink.resetPasswordModeName && actionCode) {
            setCurrentView(ResetPasswordViews.resetPassword);
        } else {
            setCurrentView(ResetPasswordViews.forgotPassword);
        }
    }, []);

    if (isLoadingUser) {
        return null;
    }

    const shouldShowLoader =
        currentView === ResetPasswordViews.forgotPasswordWithLoader ||
        currentView === ResetPasswordViews.resetPasswordWithLoader;
    const shouldShowForgotPassword =
        currentView === ResetPasswordViews.forgotPassword ||
        currentView === ResetPasswordViews.forgotPasswordWithLoader;
    const shouldShowResetConfirmation = currentView === ResetPasswordViews.forgotPasswordCompleted;
    const shouldShowResetPassword =
        currentView === ResetPasswordViews.resetPassword || currentView === ResetPasswordViews.resetPasswordWithLoader;

    if (shouldShowLoader) {
        return (
            <ViewContainer>
                <Loader />
            </ViewContainer>
        );
    }

    if (shouldShowForgotPassword) {
        return (
            <Box
                sx={{
                    padding: '16px',
                    boxSizing: 'border-box',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    gap: '32px',
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '8px' }}>
                    <Typography
                        component="h1"
                        sx={{
                            fontSize: '32px',
                            fontWeight: '600',
                            lineHeight: '40px',
                            textAlign: 'center',
                            textWrap: 'balance',
                        }}
                    >
                        Forgot password?
                    </Typography>
                    <Typography sx={{ textAlign: 'center', textWrap: 'balance' }}>
                        Enter your email address and we’ll send you instructions on password reset
                    </Typography>
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '16px',
                    }}
                >
                    <ResetPasswordForm />
                </Box>
            </Box>
        );
    }

    if (shouldShowResetPassword) {
        return (
            <Box
                sx={{
                    width: '360px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    gap: '32px',
                }}
            >
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '8px' }}>
                    <Typography
                        component="h1"
                        sx={{
                            fontSize: '32px',
                            fontWeight: '600',
                            lineHeight: '40px',
                            textAlign: 'center',
                            textWrap: 'balance',
                        }}
                    >
                        Create new password
                    </Typography>
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '16px',
                    }}
                >
                    <CreatePasswordForm />
                </Box>
            </Box>
        );
    }

    return (
        <Box
            sx={{
                width: '360px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '8px',
                maxWidth: '360px',
            }}
        >
            <Typography
                component="h1"
                sx={{
                    fontSize: '32px',
                    fontWeight: '600',
                    lineHeight: '40px',
                    textAlign: 'center',
                    textWrap: 'balance',
                }}
            >
                Password changed! 🎉
            </Typography>
            <Typography sx={{ textAlign: 'center', textWrap: 'balance' }}>
                We’ve just sent you an email with instructions
            </Typography>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginTop: '24px',
                    gap: '16px',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '8px',
                    }}
                >
                    <Link to={paths.OLD_LOGIN} style={{ textDecoration: 'none' }}>
                        <Typography
                            component={'span'}
                            sx={{
                                color: '#6583EF',
                                fontSize: '14px',
                                fontWeight: 500,
                                lineHeight: '21px',
                            }}
                        >
                            Go back to Sign in
                        </Typography>
                    </Link>
                </Box>
            </Box>
        </Box>
    );
};

Component.displayName = 'ResetPassword';
