import { Suspense } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@shadcn/ui/theme-provider';
import { Toaster } from '@shadcn/ui/components/ui/toaster';
import { LoaderView } from '../components/loader';
import { OnboardingRightSection } from '../pages/onboarding/components/onboarding-right-section';
import { AndSendLogotype } from '../../components/icons/and-send/andsend-logo';
import { Button } from '@shadcn/ui/components/ui/button';
import { useAuth } from '../providers/authentication-provider';
import { paths } from '../../routes/paths';
import { LinkedinProvider } from '../hooks/use-linkedin';
import { OnboardingProvider } from '../providers/onboarding-provider';

interface SplitViewProps {
    leftComponent?: React.ReactNode;
    rightComponent?: React.ReactNode;
}

export const SplitView = ({ leftComponent, rightComponent }: SplitViewProps) => {
    return (
        <div className="flex flex-row w-full min-h-screen overflow-hidden">
            <div className="w-[55%] flex-shrink-0 overflow-y-auto max-h-screen">
                <div className="flex items-start justify-start gap-8 w-full">{leftComponent}</div>
            </div>

            <div className="w-[45%] relative bg-cover bg-center flex justify-center items-center min-h-screen flex-shrink-0">
                {rightComponent}
            </div>
        </div>
    );
};

const ExitButton = () => {
    const { logout } = useAuth();
    const navigate = useNavigate();

    const handleExit = async () => {
        await logout();
        navigate(paths.NEW_REGISTER);
    };

    return (
        <div className="flex flex-row items-center gap-4">
            <AndSendLogotype variation="transparent_black" className="w-48" />
            <Button variant="outline" onClick={handleExit}>
                Exit
            </Button>
        </div>
    );
};

export function OnboardingLayout() {
    return (
        <Suspense fallback={<LoaderView />}>
            <ThemeProvider defaultTheme="light" storageKey="andsend-vite-ui-theme">
                <LinkedinProvider>
                    <OnboardingProvider>
                        <div className="min-h-screen flex flex-col">
                            <main className="flex-1">
                                <div className="absolute top-6 left-6">
                                    <ExitButton />
                                </div>
                                <SplitView leftComponent={<Outlet />} rightComponent={<OnboardingRightSection />} />
                            </main>
                        </div>
                        <Toaster />
                    </OnboardingProvider>
                </LinkedinProvider>
            </ThemeProvider>
        </Suspense>
    );
}

export default OnboardingLayout;
