import { Box } from '@mui/material';

const DividerWithText = ({ children }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '8px',
            }}
        >
            <Box sx={{ borderBottom: '1px solid #E4E6EB', width: '100%' }} />
            {children}
            <Box sx={{ borderBottom: '1px solid #E4E6EB', width: '100%' }} />
        </Box>
    );
};

export default DividerWithText;
