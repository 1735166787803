import { Topics } from '@zaplify/common';
import { ICommand } from '@zaplify/common';
import { CompanyResearchSummaryDto } from '../dtos';

export class ResearchCompanyByDomainCommand extends ICommand<CompanyResearchSummaryDto> {
    public static override readonly TOPIC = Topics.CAMPAIGNS;

    constructor(public readonly domain: string, public readonly userId: string) {
        super();
    }
}
