import { useEffect, useRef, useState } from 'react';

export function useDebounce<T>(value: T, delay: number): T {
    const [debouncedValue, setDebouncedValue] = useState<T>(value);

    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(timer);
        };
    }, [value, delay]);

    return debouncedValue;
}

interface UseDebounceOptions {
    minLength?: number;
    initialValue?: boolean;
}

export function useDebounceNew<T>(
    value: T,
    delay: number,
    options: UseDebounceOptions = {}
): {
    debouncedValue: T;
    isDebouncing: boolean;
    isPending: boolean;
} {
    const { minLength = 0, initialValue = false } = options;
    const [debouncedValue, setDebouncedValue] = useState<T>(value);
    const [isDebouncing, setIsDebouncing] = useState(false);
    const [isPending, setIsPending] = useState(initialValue);
    const timerRef = useRef<NodeJS.Timeout | null>(null);
    const initialRender = useRef(true);

    useEffect(() => {
        // Skip the initial render if initialValue is false
        if (initialRender.current && !initialValue) {
            initialRender.current = false;
            return;
        }

        // Check if the value meets minimum length requirement (for string values)
        const meetsMinLength = typeof value === 'string' ? value.length >= minLength : true;

        // Clear any existing timer
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }

        // Set debouncing state to true
        setIsDebouncing(true);

        // Only set pending if value meets minimum length
        if (meetsMinLength) {
            setIsPending(true);
        }

        // Set a new timer
        timerRef.current = setTimeout(() => {
            // Only update the debounced value if it meets minimum length
            if (meetsMinLength) {
                setDebouncedValue(value);
            }
            setIsDebouncing(false);
            setIsPending(false);
        }, delay);

        // Cleanup on unmount
        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        };
    }, [value, delay, minLength, initialValue]);

    return { debouncedValue, isDebouncing, isPending };
}
