import React from 'react';
import { ArrowBigUpDash } from 'lucide-react';
import { Button } from '@shadcn/ui/components/ui/button';
import { paths } from '../../../routes/paths';
import { Link } from 'react-router-dom';

export const UpgradeSection: React.FC = () => {
    return (
        <div className="flex items-center gap-2 p-4 bg-background-brand-primary-subtle rounded-lg">
            <ArrowBigUpDash className="text-blue-500 stroke-[1] w-8" />
            <div className="flex flex-col flex-1 gap-1">
                <p className="font-medium">Upgrade your Company</p>
                <p className="text-sm text-muted-foreground">Get unlimited contacts and premium features</p>
            </div>
            <Button asChild>
                <Link
                    to={
                        paths.NEW.ACCOUNT_MANAGEMENT_PATHS.ROOT +
                        '/' +
                        paths.NEW.ACCOUNT_MANAGEMENT_PATHS.SUBSCRIPTION +
                        '/upgrade-plan'
                    }
                >
                    Upgrade
                </Link>
            </Button>
        </div>
    );
};
