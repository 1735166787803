import { socketClientActionTypes } from '../../actionTypes';

export default function reducer(state = {}, action = {}) {
    switch (action.type) {
        case socketClientActionTypes.SEND: {
            return {
                ...state,
                isSending: true,
            };
        }
        case socketClientActionTypes.SEND_SUCCESS: {
            return {
                ...state,
                isSending: false,
            };
        }
        case socketClientActionTypes.SEND_FAIL: {
            return {
                ...state,
                isSending: false,
            };
        }
        default: {
            return state;
        }
    }
}
