import { IconButton, Typography, Box, Button } from '@mui/material';
import { X } from 'lucide-react';
import { Modal } from '@zaplify/frontend-common';
import { displayOutOfCreditsModalAtom } from '../../atoms/outOfCreditsModal';
import { useAtom, useAtomValue } from 'jotai';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const ContactLimitExceededPopover = () => {
    const [shouldBeOpen, setShouldBeOpen] = useAtom(displayOutOfCreditsModalAtom);
    const [openModal, setOpenModal] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (shouldBeOpen) {
            setOpenModal(true);
        }
    }, [shouldBeOpen]);

    const onClose = () => {
        setOpenModal(false);
        setShouldBeOpen(false);
    };

    return (
        <Modal
            isOpen={openModal}
            closeModal={onClose}
            zIndex={1500}
            style={{
                content: {
                    borderRadius: '14px',
                    padding: '24px',
                    width: '400px',
                    height: '170px',
                    margin: 'auto',
                },
            }}
        >
            <IconButton sx={{ position: 'absolute', top: '16px', right: '16px', zIndex: 10 }} onClick={onClose}>
                <X size={20} />
            </IconButton>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '24px',
                    position: 'relative',
                }}
            >
                <Typography
                    variant="h6"
                    sx={{ fontWeight: 500, fontSize: '17px', lineHeight: '24px', color: '#23323F' }}
                >
                    You've reached the limit of 50 contacts
                </Typography>
                <Typography
                    variant="body2"
                    sx={{ textAlign: 'left', fontSize: '15px', lineHeight: '20px', color: '#6B7280' }}
                >
                    You're limited to 50 contacts on a free plan. <br />
                    Upgrade your plan or remove contacts to add new.
                </Typography>
                <Box
                    sx={{ display: 'flex', gap: '16px', marginTop: '16px', width: '100%', justifyContent: 'flex-end' }}
                >
                    <Button
                        variant="text"
                        sx={{
                            color: '#6366F1',
                            textTransform: 'none',
                            fontSize: '14px',
                        }}
                        onClick={onClose}
                    >
                        Close
                    </Button>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: '#6366F1',
                            color: 'white',
                            textTransform: 'none',
                            fontSize: '14px',
                            '&:hover': {
                                backgroundColor: '#4F46E5',
                            },
                        }}
                        onClick={() => {
                            navigate('/account-management/subscription');
                            onClose();
                        }}
                    >
                        View plans
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};
