import { IEvent, Topics } from '@zaplify/common';

export class OutreachSuggestionSnoozedEvent extends IEvent {
    public static override readonly TOPIC: Topics = Topics.CAMPAIGNS;
    public readonly userId: string;
    public readonly organizationId: string;
    public readonly prospectId: string;
    public readonly outreachSuggestionId: string;
    public readonly scheduledOn: Date;

    constructor(params: {
        userId: string;
        organizationId: string;
        outreachSuggestionId: string;
        scheduledOn: Date;
        prospectId: string;
    }) {
        super();
        this.userId = params.userId;
        this.organizationId = params.organizationId;
        this.outreachSuggestionId = params.outreachSuggestionId;
        this.prospectId = params.prospectId;
        this.scheduledOn = params.scheduledOn;
    }

    get partitionKey(): string {
        return this.organizationId;
    }
}
