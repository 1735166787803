import * as React from 'react';
import { Avatar, AvatarFallback, AvatarImage } from '@shadcn/ui/components/ui/avatar';
import { AndSendIcon } from '@shadcn/ui/components/icons/and-send/andsend-icon';

interface SystemAvatarProps {}

export const SystemAvatar: React.FC<SystemAvatarProps> = ({}) => {
    return (
        <div className="flex items-center justify-center rounded-full bg-background-brand-section-subtle w-9 h-9">
            <AndSendIcon variation="black-striped" height="1.25rem" />
        </div>
    );
};
