import { useNavigate, useSearchParams } from 'react-router-dom';
import { paths } from '../../routes/paths';
import { useEffect, useState } from 'react';
import { useAuth } from '../../new/providers/authentication-provider';
import { cacheInvitationFromURL } from '../../new/functions/invitations-handling';
import { LoaderView } from '../../new/components/loader';
import { useNotifications } from '../../hooks/use-notifications';

export const Component = () => {
    const navigate = useNavigate();
    const [searchParams, _] = useSearchParams();
    const { authState } = useAuth();
    const [isLoading, setIsLoading] = useState(true);
    const { notify } = useNotifications();

    useEffect(() => {
        console.log('join organization page', { authState, searchParams });
        cacheInvitationFromURL(searchParams);

        if (authState.isLoggedIn && authState.nbrOfUserAccounts > 0) {
            navigate(paths.OLD_ACCOUNTS);
            notify({
                message: 'You have a new invite waiting 🎉',
                subMessage: `Just hit the "Join Team" button to accept the invitation.`,
                type: 'success',
                duration: 10_000,
                action: { label: "Can't see the button? Click here", type: 'link', url: paths.OLD_ACCOUNTS },
            });
        } else if (authState.isLoggedIn && authState.nbrOfUserAccounts == 0) {
            navigate(paths.OLD_CONFIGURE_PROFILE);
            notify({
                message: 'An invite is waiting for you! 🎉',
                subMessage: 'You are just a few steps away from getting started with Andsend',
                type: 'success',
                duration: 10_000,
            });
        } else {
            // Fallback to redirecting to register
            notify({
                message: 'An invite is waiting for you! 🎉',
                subMessage: 'Login or register to join your team',
                type: 'success',
                duration: 10_000,
            });
            navigate(paths.OLD_REGISTER);
        }
    }, [navigate]);

    return <div style={{ width: '100%' }}>{isLoading && <LoaderView />}</div>;
};

Component.displayName = 'JoinOrganization';
