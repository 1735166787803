export enum Topics {
    USERS = 'users',
    PROSPECTS = 'prospects',
    CAMPAIGNS = 'campaigns',
    ZAPS = 'zaps',
    CHANNEL_ACCOUNTS = 'channel-accounts',
    WORKFLOW_ACTIONS = 'workflow-actions',
    HUB_SPOT_APP = 'hubspot',
    PIPEDRIVE_APP = 'pipedrive',
    SALESFORCE_APP = 'salesforce',
    UPSALES_APP = 'upsales',
    WEBHOOKS_APP = 'webhooks',
    SEGMENT_IO = 'segment-io',
    BILLING = 'billing',
    APPS_LIBRARY = 'apps-library',
}
