/// <reference types="chrome"/>

import { isMobile } from 'is-mobile';

declare global {
    interface Window {
        opr: any;
        chrome: typeof chrome;
    }
}

export function isMobileDevice() {
    const userAgent = navigator.userAgent || navigator.vendor;
    return isMobile({ ua: userAgent, tablet: true });
}

export const areWeTestingWithJest = () => {
    return process.env['JEST_WORKER_ID'] !== undefined && process.env['IS_PROJECT_E2E'] === undefined;
};

export const isProjectE2E = () => {
    return process.env['IS_PROJECT_E2E'] !== undefined;
};

export function isChromeBrowser() {
    let opera = window.opr;
    let chrome = window.chrome;
    return (function (agent) {
        switch (true) {
            case agent.indexOf('edge') > -1:
                return false;
            case agent.indexOf('edg') > -1:
                return false;
            case agent.indexOf('opr') > -1 && !!opera:
                return false;
            case agent.indexOf('chrome') > -1 && !!chrome:
                return true;
            case agent.indexOf('trident') > -1:
                return false;
            case agent.indexOf('firefox') > -1:
                return false;
            case agent.indexOf('safari') > -1:
                return false;
            default:
                return false;
        }
    })(window.navigator.userAgent.toLowerCase());
}

export const sleep = (m: number | undefined) => new Promise((r) => setTimeout(r, m));

export const errorToJson = (err: any) => {
    return JSON.parse(JSON.stringify(err, Object.getOwnPropertyNames(err)));
};

type ILogger = {
    log: (obj: { message: string } & Record<string, any>) => void;
    warn: (obj: { message: string } & Record<string, any>) => void;
    error: (obj: { message: string } & Record<string, any>) => void;
};

export async function retryWithBackoff<T>(
    fn: () => Promise<T>,
    retries = 3,
    minTimeout = 1000,
    factor = 2,
    logger?: ILogger,
): Promise<T> {
    let attempt = 1;
    while (true) {
        try {
            return await fn();
        } catch (error) {
            if (attempt >= retries) throw error;
            const timeout = minTimeout * Math.pow(factor, attempt - 1);
            if (logger) {
                logger.warn({
                    message: `Retrying attempt ${attempt}`,
                    error: errorToJson(error),
                });
            }
            await sleep(timeout);
            attempt++;
        }
    }
}
