import { companyNameCorrectlyCased } from '../../../../../enums/company-name';
import { FieldMapping } from '../../../../../redux/ducks/hubspot/slice';

export const fieldValidator = (field: FieldMapping): FieldMapping => {
    if (field.prospectField === '' && field.contactField === '') {
        field.errorText = 'Please select fields.';
    } else if (field.prospectField === '' && field.contactField !== '') {
        field.errorText = `Please select ${companyNameCorrectlyCased} field column.`;
    } else if (field.prospectField !== '' && field.contactField === '') {
        field.errorText = 'Please select Hubspot field column.';
    }
    return field;
};
