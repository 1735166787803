import { Backdrop, Box, Button, CircularProgress, Skeleton, Typography } from '@mui/material';
import React, { useCallback, useEffect, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import FeedbackMessage from '../../../components/form/feedback-message';
import { ReactComponent as GmailLogo } from '../../../components/icons/gmail-logo.svg';
import { ReactComponent as OutlookLogo } from '../../../components/icons/outlook-logo.svg';
import { useAppSelector } from '../../../redux/store/configureStore';
import { paths } from '../../../routes/paths';
import { firebaseActionLink } from '../../../services/utils/urlHelper';
import { SET_EMAIL, SET_ERROR, SET_LOADING, SET_NOTIFICATION, SET_RESET_PASSWORD } from '../constants/state';
import useDeleteFirebaseUser from '../hooks/use-delete-firebase-user';
import useResendEmailVerification from '../hooks/use-resend-email-verification';
import useResetEmail from '../hooks/use-reset-email';
import useVerifyAccount from '../hooks/use-verify-account';
import { ConfirmEmailAction, ConfirmEmailState } from '../types/state';
import { getAuth } from 'firebase/auth';

// Constants
const EMAIL_QUERY_PARAM = 'email';
const RESET_PASSWORD_QUERY_PARAM = 'resetPassword';

// Reducer
const confirmEmailReducer = (state: ConfirmEmailState, action: ConfirmEmailAction): ConfirmEmailState => {
    switch (action.type) {
        case SET_LOADING:
            return { ...state, loading: action.payload };
        case SET_ERROR:
            return { ...state, errorMessage: action.payload };
        case SET_EMAIL:
            return { ...state, email: action.payload };
        case SET_RESET_PASSWORD:
            return { ...state, isResetPassword: action.payload };
        case SET_NOTIFICATION:
            return { ...state, notification: action.payload };
        default:
            return state;
    }
};

// Main Component
export const Component = () => {
    const navigate = useNavigate();
    const search = window.location.search;
    const urlParams = new URLSearchParams(search);
    const emailFromQuery = decodeURIComponent(urlParams.get(EMAIL_QUERY_PARAM) || '');
    const isLoadingUser = useAppSelector((state) => state.user.isLoadingData);
    const initialState: ConfirmEmailState = {
        loading: false,
        errorMessage: null,
        email: emailFromQuery || getAuth().currentUser?.email || '',
        isResetPassword: urlParams.get(RESET_PASSWORD_QUERY_PARAM) === 'true',
        notification: {
            isVisible: false,
            message: '',
            severity: null,
        },
    };
    const [state, dispatch] = useReducer(confirmEmailReducer, initialState);
    const { deleteFirebaseUser, deletingStatus, deletingError } = useDeleteFirebaseUser({ dispatch });
    const { resendEmailVerification, resendStatus, resendError } = useResendEmailVerification({ dispatch });
    const { resendResetEmail, resetStatus, resetError } = useResetEmail({ emailFromQuery, dispatch });
    const { handleVerifyAccount, verifyStatus } = useVerifyAccount({
        onAuthError: (error: string) => dispatch({ type: SET_ERROR, payload: error }),
    });

    const handleReenterEmail = useCallback(async () => {
        if (deletingStatus === 'pending') return;

        if (state.isResetPassword) {
            navigate(paths.OLD_RESET_PASSWORD);
        } else {
            await deleteFirebaseUser();
            navigate(paths.OLD_REGISTER);
        }
    }, [deletingStatus, state.isResetPassword, deleteFirebaseUser, navigate]);

    const handleResendEmail = useCallback(() => {
        if (resendStatus === 'pending' || resetStatus === 'pending') return;

        if (state.isResetPassword) {
            resendResetEmail();
        } else {
            resendEmailVerification();
        }
    }, [resendStatus, resetStatus, state.isResetPassword, resendResetEmail, resendEmailVerification]);

    useEffect(() => {
        const mode = firebaseActionLink.getCurrentMode();
        const actionCode = firebaseActionLink.getCurrentActionCode();

        if (mode === firebaseActionLink.verifyEmailModeName && actionCode) {
            handleVerifyAccount(actionCode);
        } else {
            dispatch({ type: SET_LOADING, payload: false });
        }
    }, []);

    useEffect(() => {
        const errorMessage =
            deletingError?.message || resendError?.message || resetError?.message || state.errorMessage;
        dispatch({ type: SET_ERROR, payload: errorMessage || null });
    }, [deletingError, resendError, resetError, state.errorMessage]);

    if (isLoadingUser) {
        return null;
    }

    if (verifyStatus === 'pending' || state.loading) {
        return <LoadingState />;
    }

    const isRequesting = deletingStatus === 'pending' || resendStatus === 'pending' || resetStatus === 'pending';

    return (
        <Box
            sx={{
                padding: '16px',
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
            }}
        >
            <HeaderSection />
            <EmailClientButtons />
            <EmailDisplay email={state.email} errorMessage={state.errorMessage} />
            <AdditionalInfo onReenterEmail={handleReenterEmail} onResendEmail={handleResendEmail} />

            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isRequesting}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    );
};

Component.displayName = 'ConfirmEmail';

// Sub-components
const LoadingState = () => (
    <Box sx={{ maxWidth: '370px', display: 'flex', flexDirection: 'column', gap: '32px' }}>
        <Skeleton variant="rounded" width={448} height={464} />
    </Box>
);

const HeaderSection = () => (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '8px' }}>
        <Typography
            component="h1"
            sx={{
                fontSize: '32px',
                fontWeight: '600',
                lineHeight: '40px',
                textAlign: 'center',
                textWrap: 'balance',
            }}
        >
            Check your email 📧
        </Typography>
        <Typography sx={{ textAlign: 'center', textWrap: 'balance' }}>
            We've just sent you an email with instructions
        </Typography>
    </Box>
);

const EmailClientButtons = () => (
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px', width: '100%' }}>
        <GmailButtonLink />
        <OutlookButtonLink />
    </Box>
);

const EmailDisplay = ({ email, errorMessage }: { email: string; errorMessage?: string | null }) => (
    <Box
        sx={{
            alignSelf: 'stretch',
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            fontSize: 14,
            fontWeight: 400,
            whiteSpace: 'nowrap',
            lineHeight: '150%',
            overflow: 'auto',
        }}
    >
        <Typography sx={{ color: '#3a4b59)', width: '100%' }}>Email</Typography>
        <Box
            sx={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 1,
                textOverflow: 'ellipsis',
                overflow: 'auto',
                borderRadius: 'var(--radius-md, 8px)',
                border: '1px solid rgba(228, 230, 235, 1)',
                backgroundColor: 'var(--grey-palette-grey-1, #f7f8fa)',
                color: 'var(--grey-palette-grey-5, #6b7985)',
                padding: '9px 12px',
            }}
            id="confirm-email-email-display"
        >
            {email}
        </Box>
        <FeedbackMessage id="confirm-email-feedback-message" error={errorMessage} info={''} />
    </Box>
);

const AdditionalInfo = ({
    onReenterEmail,
    onResendEmail,
}: {
    onReenterEmail: () => void;
    onResendEmail: () => void;
}) => (
    <Box
        component="ul"
        sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            color: '#6B7985',
        }}
    >
        <AdditionalInfoListElement>
            Wrong email address?{' '}
            <Typography component="span" sx={{ color: '#6B7985', fontSize: 14, fontWeight: 400 }}>
                Please{' '}
            </Typography>
            <Typography onClick={onReenterEmail} component="span" sx={{ color: '#6583EF', cursor: 'pointer' }}>
                re-enter email address
            </Typography>
        </AdditionalInfoListElement>
        <AdditionalInfoListElement>
            Haven't received an email?{' '}
            <Typography component="span" sx={{ color: '#6B7985', fontSize: 14, fontWeight: 400 }}>
                Check your spam or{' '}
            </Typography>
            <Typography onClick={onResendEmail} component="span" sx={{ color: '#6583EF', cursor: 'pointer' }}>
                resend email
            </Typography>
        </AdditionalInfoListElement>
    </Box>
);

const AdditionalInfoListElement = ({ children }: { children: React.ReactNode }) => (
    <li style={{ listStylePosition: 'outside' }}>
        <Typography
            component="span"
            sx={{
                color: '#6B7985',
                fontSize: 14,
                fontWeight: 500,
                lineHeight: '21px',
            }}
        >
            {children}
        </Typography>
    </li>
);

const GmailButtonLink = () => (
    <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <ExternalLink href="https://mail.google.com/">
            <MailClientButton>
                <GmailLogo />
                <Typography
                    component="span"
                    sx={{
                        fontSize: '14px',
                        fontWeight: 500,
                        color: '#3a4b59',
                        lineHeight: 1.5,
                    }}
                >
                    Open Gmail
                </Typography>
            </MailClientButton>
        </ExternalLink>
    </Box>
);

const OutlookButtonLink = () => (
    <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <ExternalLink href="https://outlook.live.com/">
            <MailClientButton>
                <OutlookLogo />
                <Typography
                    component="span"
                    sx={{
                        fontSize: '14px',
                        fontWeight: 500,
                        color: '#3a4b59',
                        lineHeight: 1.5,
                    }}
                >
                    Open Outlook
                </Typography>
            </MailClientButton>
        </ExternalLink>
    </Box>
);

const ExternalLink = ({ href, children }: { href: string; children: React.ReactNode }) => (
    <a href={href} target="_blank" rel="noopener noreferrer" style={{ width: '100%', textDecoration: 'none' }}>
        {children}
    </a>
);

const MailClientButton = ({ children }: { children: React.ReactNode }) => (
    <Button
        sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            padding: '8px 16px',
            border: '1px solid rgba(228, 230, 235, 1)',
            borderRadius: 2,
            boxShadow: '0px 1px 2px rgba(20, 21, 26, 0.06)',
            backgroundColor: 'var(--grey-palette-white, #fff)',
            gap: '6px',
            cursor: 'pointer',
        }}
        disableElevation
    >
        {children}
    </Button>
);
