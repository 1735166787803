import { Box, Button, ButtonBase, CircularProgress, Typography, styled } from '@mui/material';
import { Loader } from '@zaplify/frontend-common';
import { getZaplifySdk } from '@zaplify/sdk';
import { theme } from '@zaplify/ui';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux-latest';
import { v4 as uuid4 } from 'uuid';
import { logoutAndRedirect, setNotification } from '../redux/actions';
import { AppDispatch } from '../redux/store/configureStore';
import TopBarAuth from './organisms/TopBarAuth/TopBarAuth';
import { useNavigate } from 'react-router-dom';
import { paths } from '../routes/paths';
import { useSeatsAndCredits } from '../new/hooks/use-seat-and-credits';

const palette = theme.palette as any;
const idempotencyKey = uuid4();

const PaymentProcessingStyled = styled('div')(({ theme: { palette } }: any) => ({
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',

    '& .header-container': {
        display: 'flex',
        alignItems: 'center',
        gap: 10,
        marginBottom: 15,
        '& h3': {
            whiteSpace: 'nowrap',
        },
        '& .MuiCircularProgress-root': {
            width: 20,
            height: 20,
        },
    },
    '& .info': {
        fontSize: 17,
        color: palette.subline,
    },
}));

const PaymentProcessingFree = ({ fromCancelled = false }: { fromCancelled?: boolean }) => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const { refetch: refetchCredits } = useSeatsAndCredits();

    useEffect(() => {
        console.log('PaymentProcessingFree useEffect', { fromCancelled });
        const intervalId = setInterval(refetchCredits, 5000);
        setTimeout(() => clearInterval(intervalId), 30000);

        return () => clearInterval(intervalId);
    }, []);

    const createFreeSubscription = async () => {
        const profilesSdk = getZaplifySdk().profiles;

        try {
            await profilesSdk.billing.createFreeSubscription({ 'idempotency-key': idempotencyKey });
        } catch (error: any) {
            setLoading(false);
            dispatch(setNotification(!!error.message ? error.message : 'Failed to start plan', 'error'));
        }
    };

    const handleReactivate = () => {
        setLoading(true);
        createFreeSubscription();
    };

    const handleLogout = () => {
        dispatch(logoutAndRedirect());
    };

    const handleSwitchOrg = async () => {
        navigate(paths.OLD_ACCOUNTS);
    };

    return (
        <>
            {fromCancelled && (
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{ height: '100vh' }}
                    sx={{ backgroundColor: palette.colors.whiteDark }}
                >
                    <TopBarAuth />
                    <Typography variant="h3" gutterBottom>
                        Your subscription is cancelled
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Reactivate account on free plan
                    </Typography>
                    <Box mt={2} mb={1}>
                        <Button
                            data-cy="reactivate-button"
                            variant="contained"
                            sx={{
                                width: '290px',
                                backgroundColor: loading
                                    ? palette.colors.greyInformationLight
                                    : palette.colors.bluePale,
                                color: loading ? palette.colors.greyDark : palette.colors.white,
                                borderRadius: '30px',
                                padding: '10px 20px',
                                fontSize: '15px',
                            }}
                            disableElevation
                            onClick={handleReactivate}
                            endIcon={loading && <CircularProgress style={{ height: 20, width: 20, color: 'white' }} />}
                            disabled={loading}
                        >
                            Reactivate
                        </Button>
                    </Box>

                    {/* <ButtonBase onClick={handleSwitchOrg} style={{ marginTop: '15px', padding: '10px 0' }}>
                        <Typography
                            style={{
                                fontWeight: 500,
                                color: palette.colors.greyDark,
                                fontSize: '15px',
                            }}
                        >
                            Switch organization
                        </Typography>
                    </ButtonBase> */}
                    <ButtonBase onClick={handleLogout} style={{ margin: '0', padding: '10px 0' }}>
                        <Typography
                            style={{
                                fontWeight: 500,
                                color: palette.colors.greyDark,
                                fontSize: '15px',
                            }}
                        >
                            Log out
                        </Typography>
                    </ButtonBase>
                </Box>
            )}
            {!fromCancelled && (
                <PaymentProcessingStyled>
                    <div className="header-container">
                        <Typography variant="h3">Creating your account</Typography>
                        <Loader width={18} height={18} size={25} />
                    </div>
                    <Typography className="info">Setting up the last details...</Typography>
                </PaymentProcessingStyled>
            )}
        </>
    );
};

export default PaymentProcessingFree;
