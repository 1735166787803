import { importProspectsActionTypes } from '../../actionTypes';

const initialState = {
    prospects: [],
    fieldMapping: [
        { imported: '', prospect: 'firstName', required: true },
        { imported: '', prospect: 'lastName', required: true },
        { imported: '', prospect: 'organizationName', required: true },
        {
            imported: '',
            prospect: 'email',
            required: true,
            options: [
                { name: 'email', label: 'Email' },
                { name: 'linkedinProfileUrl', label: 'LinkedIn Profile Url' },
            ],
        },
        { imported: '', prospect: 'occupationTitle', required: false },
        { imported: '', prospect: 'location', required: false },
        { imported: '', prospect: 'organizationDomain', required: false },
    ],
    importedProspectsFields: [],
    reviewDetails: {},
    step: 0,
};

export const initialStateProspectingView = {
    prospects: [],
    fieldMapping: [
        { imported: '', prospect: 'firstName', required: true },
        { imported: '', prospect: 'lastName', required: true },
        { imported: '', prospect: 'organizationName', required: true },
        {
            imported: '',
            prospect: 'email',
            required: true,
            options: [
                { name: 'email', label: 'Email' },
                { name: 'linkedinProfileUrl', label: 'LinkedIn Profile Url' },
            ],
        },
        { imported: '', prospect: 'occupationTitle', required: false },
        { imported: '', prospect: 'organizationDomain', required: false },
    ],
    importedProspectsFields: [],
    reviewDetails: {},
    step: 0,
};

function getFieldMapping(fieldMapping, importedFields) {
    return fieldMapping.map((field) => ({
        ...field,
        imported: importedFields?.find((e) => e === field.prospect) || '',
    }));
}

const importProspectsReducer = (state = initialState, action) => {
    switch (action.type) {
        case importProspectsActionTypes.UPDATE_STEP:
            return {
                ...state,
                step: action.step,
            };
        case importProspectsActionTypes.UPDATE_PROSPECTS:
            return {
                ...state,
                prospects: action.prospects,
            };
        case importProspectsActionTypes.UPDATE_REVIEW_DETAILS:
            return {
                ...state,
                reviewDetails: action.reviewDetails,
            };
        case importProspectsActionTypes.UPDATE_IMPORTED_PROSPECTS_FIELDS:
            return {
                ...state,
                importedProspectsFields: action.fields,
                fieldMapping: getFieldMapping(state.fieldMapping, action.fields),
            };
        case importProspectsActionTypes.UPDATE_FIELD: {
            const tempFieldMapping = [...state.fieldMapping];
            const tempField = { ...tempFieldMapping[action.payload.idx], [action.payload.field]: action.payload.value };
            tempFieldMapping[action.payload.idx] = tempField;
            return {
                ...state,
                fieldMapping: tempFieldMapping,
            };
        }
        case importProspectsActionTypes.ADD_FIELD:
            return {
                ...state,
                fieldMapping: [...state.fieldMapping, { imported: '', prospect: '' }],
            };
        case importProspectsActionTypes.DELETE_FIELD:
            let tempDeleteFieldMapping = [...state.fieldMapping];
            tempDeleteFieldMapping.splice(action.index, 1);
            return {
                ...state,
                fieldMapping: tempDeleteFieldMapping,
            };
        case importProspectsActionTypes.UPDATE_WHOLE_STATE:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return { ...state };
    }
};

export default importProspectsReducer;
