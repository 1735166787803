import { ICommand, Topics } from '@zaplify/common';
import { ContactSource, UserContactDto, UserContactStatus } from '../dtos';

export class CreateUserContactCommand extends ICommand<UserContactDto> {
    public static override readonly TOPIC = Topics.PROSPECTS;

    constructor(
        readonly body: {
            prospectId: string;
            userId: string;
            groupId: string | 'default' | 'none';
            status?: UserContactStatus;
            source?: ContactSource;
        },
    ) {
        super();
    }
}
