import { FC, useState } from 'react';
import { Button } from '@shadcn/ui/components/ui/button';
import { AlertCircle, Trash2, Mail, Settings2 } from 'lucide-react';
import { Separator } from '@shadcn/ui/components/ui/separator';
import { Link } from 'react-router-dom';
import { EmailSignatureEditor } from './email-signature-editor';
import { ReactComponent as GmailIcon } from '../../../../../../components/icons/gmail-bigger.svg';
import { useAuth } from '../../../../../providers/authentication-provider';

interface GmailSettingsProps {
    isConnected: boolean;
    onDisconnect: () => void;
    isDisconnecting: boolean;
    emailChannelAccountId: string;
    signature: string;
    updateSignature: (signature: string, emailChannelAccountId: string) => Promise<void>;
    resyncGmailSignature: (emailChannelAccountId: string) => Promise<{ signature: string }>;
    refreshAccount?: () => Promise<void>;
}

export const GmailSettings: FC<GmailSettingsProps> = ({
    isConnected,
    onDisconnect,
    isDisconnecting,
    emailChannelAccountId,
    signature,
    updateSignature,
    resyncGmailSignature,
    refreshAccount,
}) => {
    const {
        authState: { isGlobalAdmin },
    } = useAuth();
    if (!isConnected) {
        return (
            <div className="flex flex-col items-center justify-center py-12">
                <AlertCircle className="h-12 w-12 text-yellow-500 mb-4" />
                <h3 className="text-lg font-medium mb-2">Not Connected</h3>
                <p className="text-gray-500 text-center mb-6">
                    This Gmail account is not connected. Please connect it from the apps page.
                </p>
                <Button variant="outline" asChild>
                    <Link to="/account-management/apps">Go to Apps</Link>
                </Button>
            </div>
        );
    }

    return (
        <div className="space-y-6">
            <div className="flex items-center">
                <GmailIcon className="h-12 w-12 object-contain mr-3" />
                <div>
                    <h3 className="text-lg font-semibold">Gmail</h3>
                    <p className="text-sm text-gray-500">Manage your Gmail connection settings</p>
                </div>
            </div>

            <Separator />

            {/* Email Signature Editor */}
            <EmailSignatureEditor
                savedSignature={signature}
                emailChannelAccountId={emailChannelAccountId}
                isOutlook={false}
                updateSignature={updateSignature}
                resyncGmailSignature={resyncGmailSignature}
                onSave={refreshAccount}
            />

            <Separator />

            {/* <div className="space-y-4">
                <h4 className="text-md font-medium">Account Settings</h4>

                <div className="rounded-md border p-4">
                    <div className="flex items-start gap-4">
                        <Settings2 className="h-5 w-5 text-gray-500 mt-0.5" />
                        <div className="space-y-1 flex-1">
                            <p className="text-sm font-medium">Email Sending Preferences</p>
                            <p className="text-sm text-gray-500">
                                Configure how emails are sent from your Gmail account
                            </p>
                        </div>
                        <Button variant="outline" size="sm">
                            Configure
                        </Button>
                    </div>
                </div>
            </div>

            <Separator /> */}

            {/* Danger zone */}
            {isGlobalAdmin && (
                <>
                    <Separator />
                    <div className="space-y-4">
                        <h4 className="text-md font-medium text-red-500">Danger Zone</h4>

                        <div className="rounded-md border border-red-200 p-4">
                            <div className="flex items-start gap-4">
                                <Trash2 className="h-5 w-5 text-red-500 mt-0.5" />
                                <div className="space-y-1 flex-1">
                                    <p className="text-sm font-medium">Disconnect Account</p>
                                    <p className="text-sm text-gray-500">
                                        Remove this Gmail account from your connected accounts. All associated messages
                                        will be removed from your conversations.
                                    </p>
                                </div>
                                <Button
                                    variant="destructive"
                                    size="sm"
                                    onClick={onDisconnect}
                                    disabled={isDisconnecting}
                                >
                                    {isDisconnecting ? 'Disconnecting...' : 'Disconnect'}
                                </Button>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};
