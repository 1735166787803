import { IGroupDto, IUserContactDto } from '../interfaces';

export enum UserContactStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
}

export enum ContactSource {
    GlobalSearch = 'GlobalSearch',
    Recommended = 'Recommended',
    UserSearch = 'UserSearch',
    FileImport = 'FileImport',
    LinkedinImport = 'LinkedinImport',
    Migrated = 'Migrated',
    Onboarding = 'Onboarding',
    ConversationAutoImport = 'ConversationAutoImport',
    Playbook = 'Playbook',
    LinkedinExtension = 'LinkedinExtension',
    Agent = 'Agent',
}

export class UserContactDto {
    id: string;
    prospectId: string;
    userId: string;
    groups: IGroupDto[];
    status: UserContactStatus;
    source?: ContactSource;
    createdAt: Date;
    updatedAt: Date;

    constructor(params: IUserContactDto) {
        this.id = params.id;
        this.prospectId = params.prospectId;
        this.userId = params.userId;
        this.groups = params.groups;
        this.status = params.status;
        this.source = params.source;
        this.createdAt = params.createdAt;
        this.updatedAt = params.updatedAt;
    }
}
