import { MemoryLevel, UserMemoryDto } from '@zaplify/services/messaging/shared';
import { UseQueryOptions, UseSuspenseQueryOptions } from '@tanstack/react-query';
import { createMutationOptions } from './sdk-utils';
import { getZaplifySdk } from '@zaplify/sdk';

const getUserMemoriesFn = (level: MemoryLevel, prospectId?: string, groupId?: string) => {
    return getZaplifySdk().profiles.messenger.getUserMemories(level, prospectId, groupId);
};

type UserMemoryData = Awaited<ReturnType<typeof getUserMemoriesFn>>;

export const getUserMemories = (
    level: MemoryLevel,
    prospectId?: string,
    groupId?: string,
    options?: Omit<
        UseQueryOptions<UserMemoryData, Error> & UseSuspenseQueryOptions<UserMemoryData, Error>,
        'queryKey' | 'queryFn'
    >
): UseQueryOptions<UserMemoryData, Error> & UseSuspenseQueryOptions<UserMemoryData, Error> => {
    return {
        queryKey: ['user-memories', prospectId, groupId, level],
        queryFn: () => getUserMemoriesFn(level, prospectId, groupId),
        ...options,
    };
};

export const updateUserMemory = () => {
    return createMutationOptions<UserMemoryDto, Error, { memoryId: string; memory: string }>({
        mutationFn: (variables) =>
            getZaplifySdk().profiles.messenger.updateUserMemory(variables.memoryId, variables.memory),
        invalidateQueries: (data) => {
            // Invalidate the specific query and also the broader query
            return [['user-memories', data.prospectId, data.groupId, data.level], ['user-memories']];
        },
    });
};

export const deleteUserMemory = () => {
    return createMutationOptions<
        void,
        Error,
        { memoryId: string; level?: MemoryLevel; prospectId?: string; groupId?: string }
    >({
        mutationFn: (variables) => getZaplifySdk().profiles.messenger.deleteUserMemory(variables.memoryId),
        invalidateQueries: (_, variables) => {
            // If we have the specific query parameters, invalidate that specific query
            if (variables.level) {
                return [['user-memories', variables.prospectId, variables.groupId, variables.level]];
            }
            // Otherwise invalidate all user-memories queries
            return [['user-memories']];
        },
    });
};
