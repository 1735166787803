import { Button } from '@shadcn/ui/components/ui/button';
import { Link } from 'react-router-dom';

interface ViewConversationButtonProps {
    prospectId: string;
}

interface ViewConversationButtonProps {
    prospectId: string;
    className?: string;
    variant?: 'default' | 'secondary' | 'ghost' | 'outline';
    size?: 'default' | 'sm' | 'lg';
}

export const ViewConversationButton: React.FC<ViewConversationButtonProps> = ({
    prospectId,
    className,
    variant = 'outline',
    size = 'default',
}) => {
    return (
        <Link to={`/new/messages/${prospectId}`}>
            <Button variant={variant} size={size} className={className}>
                View conversation
            </Button>
        </Link>
    );
};
