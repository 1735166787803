import SvgIcon from '@mui/material/SvgIcon';

export const OrganizationIcon = ({ ...props }) => {
    return (
        <SvgIcon {...props} viewBox="0 0 84 84">
            <rect width="84" height="84" rx="18" fill="url(#paint0_linear_12459_217294)" />
            <rect width="84" height="84" rx="18" fill="url(#paint1_linear_12459_217294)" />
            <g clipPath="url(#clip0_12459_217294)">
                <path
                    d="M41.3924 19.2274C42.0956 18.9242 42.9042 18.9242 43.6073 19.2274L63.2948 27.6651C64.5252 28.1934 65.2108 29.5117 64.9383 30.8125C64.6747 32.1221 63.5233 32.9834 62.1083 33.0625V33.7656C62.1083 34.9346 61.2469 35.875 59.9989 35.875H24.8426C23.7563 35.875 22.7333 34.9346 22.7333 33.7656V33.0625C21.4773 32.9834 20.3258 32.1221 20.0572 30.8125C19.7887 29.5117 20.477 28.1934 21.7041 27.6651L41.3924 19.2274ZM42.4999 30.25C44.0555 30.25 45.3124 28.9932 45.3124 27.4375C45.3124 25.8845 44.0555 24.625 42.4999 24.625C40.9442 24.625 39.6874 25.8845 39.6874 27.4375C39.6874 28.9932 40.9442 30.25 42.4999 30.25ZM31.1708 55.5625H34.6864V38.6875H40.3114V55.5625H44.6092V38.6875H50.2342V55.5625H53.7499V38.6875H59.3749V55.9404C59.4276 55.9668 59.4803 55.9229 59.5331 56.0371L63.7518 58.8496C64.7801 59.5352 65.2372 60.8184 64.8768 62.0049C64.5165 63.1914 63.4266 64 62.1874 64H22.7333C21.5731 64 20.4796 63.1914 20.1204 62.0049C19.7612 60.8184 20.2209 59.5352 21.2523 58.8496L25.471 56.0371C25.5211 55.9229 25.573 55.9668 25.5458 55.9404V38.6875H31.1708V55.5625Z"
                    fill="url(#paint2_linear_12459_217294)"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_12459_217294"
                    x1="0"
                    y1="84"
                    x2="84.4258"
                    y2="83.5699"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#959BEB" />
                    <stop offset="1" stopColor="#E6A097" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_12459_217294"
                    x1="0"
                    y1="84"
                    x2="84.4258"
                    y2="83.5699"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#DEE1FF" />
                    <stop offset="1" stopColor="#FFE9E6" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_12459_217294"
                    x1="19.9995"
                    y1="64"
                    x2="63.4239"
                    y2="54.9849"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#8B92E4" />
                    <stop offset="1" stopColor="#F2A99E" />
                </linearGradient>
                <clipPath id="clip0_12459_217294">
                    <rect width="45" height="45" fill="white" transform="translate(20 19)" />
                </clipPath>
            </defs>
        </SvgIcon>
    );
};
