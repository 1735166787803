import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@shadcn/ui/lib/utils';

const badgeVariants = cva(
    'inline-flex items-center rounded-full border px-2.5 py-0.5 text-xs font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
    {
        variants: {
            variant: {
                default: 'border-transparent bg-primary text-primary-foreground',
                secondary: 'border-transparent bg-secondary text-secondary-foreground',
                destructive: 'border-transparent bg-destructive text-white',
                outline: 'text-foreground',
                success:
                    'border-success-200 bg-success-50 color-success-700 text-success-700 dark:bg-success-50/20 dark:text-primary-foreground',
                warning: 'border-warning-200 bg-warning-50 text-warning-700 color-warning-700',
                blue: 'border-blue-utility-200 bg-blue-utility-50 text-blue-utility-700 color-blue-utility-700',
                gray: 'border-gray-utility-200 bg-gray-utility-50 text-gray-utility-700 color-gray-utility-700',
                brand: 'border-brand-300 bg-brand-100 text-brand-500 color-brand-500',
            },
        },
        defaultVariants: {
            variant: 'default',
        },
    },
);

export interface BadgeProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
    return <div className={cn(badgeVariants({ variant }), className)} {...props} />;
}

export { Badge, badgeVariants };
