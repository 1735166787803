import { FC, useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Card, CardContent } from '@shadcn/ui/components/ui/card';
import { Button } from '@shadcn/ui/components/ui/button';
import { AlertCircle, ArrowLeft } from 'lucide-react';
import { Alert, AlertTitle, AlertDescription } from '@shadcn/ui/components/ui/alert';
import { useApps } from './hooks/use-apps';
import { GmailSettings } from './components/settings/gmail-settings';
import { OutlookSettings } from './components/settings/outlook-settings';
import { LinkedInSettings } from './components/settings/linkedin-settings';
import { SalesforceSettings } from './components/settings/salesforce-settings';
import { HubspotSettings } from './components/settings/hubspot-settings';
import { PipedriveSettings } from './components/settings/pipedrive-settings';
import { useCrmAccounts } from './hooks/use-crm-accounts';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAuth } from '../../../providers/authentication-provider';
import { useSdk } from '../../../sdk/use-sdk';
import { ChannelAccountStatus } from '@zaplify/channel-accounts/shared';
import { FieldMapping } from './types';
import {
    HubspotFieldMapping,
    PipedriveFieldMapping,
    SalesforceFieldMapping,
} from '../../../sdk/internal/user-organization.sdk';

export const AppSettingsPage: FC = () => {
    const { appId } = useParams<{ appId: string }>();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [error, setError] = useState<string | null>(null);
    const [isDisconnecting, setIsDisconnecting] = useState(false);
    const {
        accounts: emailAccounts,
        isLoading: emailLoading,
        disconnectAccount: disconnectEmailAccount,
        refreshAccounts,
        channelAccounts,
    } = useApps();
    // Map appId to ChannelProvider
    const getChannelProviderFromAppId = (appId: string | undefined) => {
        if (!appId) return null;

        switch (appId.toLowerCase()) {
            case 'gmail':
                return 'GMAIL';
            case 'outlook':
                return 'OUTLOOK';
            case 'linkedin':
                return 'LINKEDIN';
            default:
                return null;
        }
    };

    const channelProvider = getChannelProviderFromAppId(appId);
    const currentEmailAccount = channelAccounts?.find((account) => account.provider === channelProvider);
    console.log('currentEmailAccount', currentEmailAccount);
    const {
        accounts,
        isUpdatingSettings,
        updateScope,
        updateFieldMapping,
        disconnectCrm,
        isLoading: crmLoading,
        availableFields,
        loadAvailableFields,
        isLoadingFields,
        isHubspotFields,
        isPipedriveFields,
        isSalesforceContactFields,
        isSalesforceLeadFields,
        updateIntegrationStrategy,
    } = useCrmAccounts();

    const [connectedAccountId, setConnectedAccountId] = useState<string | null>(null);

    const {
        channelAccount: { updateEmailSignature, resyncEmailSignature },
    } = useSdk();

    // Get the connected account ID for email accounts
    useEffect(() => {
        const fetchAccountId = async () => {
            if (!appId) return;

            try {
                // This is a placeholder for getting the account ID from the accounts data
                // In a real implementation, you would get the account ID from the email/crm accounts
                const accounts = await queryClient.getQueryData(['getMyChannelAccounts']);

                if (accounts) {
                    const account = (accounts as any[]).find((acc) => {
                        if (appId === 'gmail' && acc.provider === 'GMAIL') return true;
                        if (appId === 'outlook' && acc.provider === 'OUTLOOK') return true;
                        if (appId === 'linkedin' && acc.provider === 'LINKEDIN') return true;
                        return false;
                    });

                    if (account) {
                        setConnectedAccountId(account.id);
                    }
                }
            } catch (err) {
                console.error('Error fetching account ID:', err);
                setError('Could not load account information.');
            }
        };

        fetchAccountId();
    }, [appId, queryClient]);

    // Add these functions for handling signatures

    // Mutation for updating email signature
    const updateSignatureMutation = useMutation(updateEmailSignature());

    const updateSignature = async (signature: string, emailChannelAccountId: string) => {
        try {
            await updateSignatureMutation.mutateAsync({ signature, emailChannelAccountId });
            return Promise.resolve();
        } catch (error) {
            return Promise.reject(error);
        }
    };

    // Mutation for resyncing Gmail signature
    const resyncSignatureMutation = useMutation(resyncEmailSignature());

    const resyncGmailSignature = async (emailChannelAccountId: string) => {
        try {
            const result = await resyncSignatureMutation.mutateAsync({ channelAccountId: emailChannelAccountId });
            return result;
        } catch (error) {
            return Promise.reject(error);
        }
    };

    const renderSettings = () => {
        if (!appId) return null;

        switch (appId) {
            case 'gmail':
                return (
                    <GmailSettings
                        isConnected={
                            emailAccounts.gmail.isAdded &&
                            emailAccounts.gmail.status === ChannelAccountStatus.AUTHENTICATED
                        }
                        onDisconnect={handleDisconnect}
                        isDisconnecting={isDisconnecting}
                        emailChannelAccountId={connectedAccountId || ''}
                        signature={currentEmailAccount?.signature || ''}
                        updateSignature={updateSignature}
                        resyncGmailSignature={resyncGmailSignature}
                        refreshAccount={refreshAccounts}
                    />
                );
            case 'outlook':
                return (
                    <OutlookSettings
                        isConnected={
                            emailAccounts.outlook.isAdded &&
                            emailAccounts.outlook.status === ChannelAccountStatus.AUTHENTICATED
                        }
                        onDisconnect={handleDisconnect}
                        isDisconnecting={isDisconnecting}
                        emailChannelAccountId={connectedAccountId || ''}
                        signature={currentEmailAccount?.signature || ''}
                        updateSignature={updateSignature}
                        refreshAccount={refreshAccounts}
                    />
                );
            case 'linkedin':
                return (
                    <LinkedInSettings
                        isConnected={
                            emailAccounts.linkedin.isAdded &&
                            emailAccounts.linkedin.status === ChannelAccountStatus.AUTHENTICATED
                        }
                        onDisconnect={handleDisconnect}
                        isDisconnecting={isDisconnecting}
                    />
                );
            case 'salesforce':
                return (
                    <SalesforceSettings
                        isConnected={!!accounts.salesforce}
                        onDisconnect={() => disconnectCrm('salesforce')}
                        isDisconnecting={isDisconnecting}
                        account={accounts.salesforce}
                        onUpdateScope={(scope, value) => updateScope('salesforce', scope, value)}
                        onUpdateFieldMapping={async (fieldMapping: SalesforceFieldMapping[]) => {
                            await updateFieldMapping('salesforce', fieldMapping as unknown as FieldMapping[]);
                        }}
                        onUpdateIntegrationStrategy={(strategy) =>
                            updateIntegrationStrategy('salesforce', strategy as any)
                        }
                        isUpdatingSettings={isUpdatingSettings}
                        availableFields={availableFields}
                        loadAvailableFields={loadAvailableFields}
                        isLoadingFields={isLoadingFields}
                    />
                );
            case 'hubspot':
                return (
                    <HubspotSettings
                        isConnected={!!accounts.hubspot}
                        onDisconnect={() => disconnectCrm('hubspot')}
                        isDisconnecting={isDisconnecting}
                        account={accounts.hubspot}
                        onUpdateScope={(scope, value) => updateScope('hubspot', scope, value)}
                        onUpdateFieldMapping={async (fieldMapping: HubspotFieldMapping[]) => {
                            // Convert FieldMapping[] to HubspotFieldMapping[]
                            const hubspotMapping: HubspotFieldMapping[] = fieldMapping.map((mapping) => ({
                                prospectField: mapping.prospectField,
                                contactField: mapping.contactField,
                            }));
                            await updateFieldMapping('hubspot', hubspotMapping as unknown as FieldMapping[]);
                        }}
                        isUpdatingSettings={isUpdatingSettings}
                        availableFields={isHubspotFields(availableFields) ? availableFields : undefined}
                        loadAvailableFields={loadAvailableFields}
                        isLoadingFields={isLoadingFields}
                    />
                );
            case 'pipedrive':
                return (
                    <PipedriveSettings
                        isConnected={!!accounts.pipedrive}
                        onDisconnect={() => disconnectCrm('pipedrive')}
                        isDisconnecting={isDisconnecting}
                        account={accounts.pipedrive}
                        onUpdateScope={(scope, value) => updateScope('pipedrive', scope, value)}
                        onUpdateFieldMapping={async (fieldMapping: PipedriveFieldMapping[]) => {
                            // The PipedriveFieldMapping is already in the correct format
                            // Pass it directly without any type conversion
                            console.log('About to update Pipedrive field mappings:', fieldMapping);
                            await updateFieldMapping('pipedrive', fieldMapping as any);
                        }}
                        isUpdatingSettings={isUpdatingSettings}
                        availableFields={isPipedriveFields(availableFields) ? availableFields : undefined}
                        loadAvailableFields={loadAvailableFields}
                        isLoadingFields={isLoadingFields}
                    />
                );
            default:
                return <div>App not found</div>;
        }
    };

    const handleDisconnect = async () => {
        if (!connectedAccountId) {
            setError('No account found to disconnect');
            return;
        }

        try {
            setIsDisconnecting(true);
            await disconnectEmailAccount(connectedAccountId);
            navigate('..');
        } catch (err) {
            console.error('Error disconnecting account:', err);
            setError('Could not disconnect account. Please try again.');
        } finally {
            setIsDisconnecting(false);
        }
    };

    if (emailLoading) {
        return (
            <div className="container py-6">
                <div className="flex items-center justify-center h-64">
                    <div className="animate-spin h-8 w-8 border-2 border-primary rounded-full border-t-transparent"></div>
                </div>
            </div>
        );
    }

    return (
        <div className="container py-0 space-y-6">
            <div className="flex items-center">
                <Button variant="outline" size="sm" className="mr-2" asChild>
                    <Link to="../apps">
                        <ArrowLeft className="h-4 w-4 mr-1" />
                        Back
                    </Link>
                </Button>
                {/* <h1 className="text-2xl font-bold tracking-tight">
                    {appId ? appId.charAt(0).toUpperCase() + appId.slice(1) : ''} Settings
                </h1> */}
            </div>

            {error && (
                <Alert variant="destructive">
                    <AlertCircle className="h-4 w-4" />
                    <AlertTitle>Error</AlertTitle>
                    <AlertDescription>{error}</AlertDescription>
                </Alert>
            )}

            <div className="border-none border border-border-secondary shadow-none">{renderSettings()}</div>
        </div>
    );
};
