import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { useDispatch } from 'react-redux-latest';
import { updateSelectedStrategy } from '../../../../../redux/ducks/salesforce/slice';
import { AppDispatch, useAppSelector } from '../../../../../redux/store/configureStore';
import { salesforceContactStrategy, salesforceLeadStrategy } from '../constants';

export const RadioGroupStrategy = () => {
    const dispatch = useDispatch<AppDispatch>();
    const selectedStrategy = useAppSelector((state) => state.salesforce.selectedStrategy);

    return (
        <FormControl component="fieldset">
            <Typography>
                <b>Create prospects and companies as:</b>
            </Typography>
            <RadioGroup row aria-label="position" name="position" value={selectedStrategy}>
                <FormControlLabel
                    value={salesforceContactStrategy}
                    control={<Radio color="primary" />}
                    label="Contacts & Accounts"
                    labelPlacement="end"
                    onClick={() => dispatch(updateSelectedStrategy(salesforceContactStrategy))}
                />
                <FormControlLabel
                    value={salesforceLeadStrategy}
                    control={<Radio color="primary" />}
                    label="Leads"
                    labelPlacement="end"
                    onClick={() => dispatch(updateSelectedStrategy(salesforceLeadStrategy))}
                />
            </RadioGroup>
        </FormControl>
    );
};
