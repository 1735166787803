import * as React from 'react';
import {
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogFooter,
    DialogClose,
    DialogDescription,
} from '@shadcn/ui/components/ui/dialog';
import { Button } from '@shadcn/ui/components/ui/button';
import { SearchParamDialog, useSearchParamDialog } from '@shadcn/ui/components/search-param-dialog';
import { Link, useNavigate } from 'react-router-dom';

export const useConnectionRequestsLimitReachedDialog = () => {
    const { open, close, value } = useSearchParamDialog('connection-requests-limit-reached');

    return {
        openConnectionRequestsLimitReachedDialog: () => open(),
        closeConnectionRequestsLimitReachedDialog: close,
        value,
    };
};

export const ConnectionRequestsLimitReachedDialog: React.FC = () => {
    return (
        <SearchParamDialog param={'connection-requests-limit-reached'}>
            <DialogContent className="p-6 rounded-lg shadow-lg">
                <DialogHeader>
                    <DialogTitle className="text-lg font-semibold">
                        You're out of Personalized Connection Requests
                    </DialogTitle>
                </DialogHeader>
                <p className="mt-2 text-sm">
                    LinkedIn are limiting non-premium members to only send a few personalized connection requests per
                    month.
                </p>
                <p className="mt-2 text-sm">
                    You can continue sending connection requests without messages, or upgrade to LinkedIn Premium.
                </p>
                <p className="mt-2 text-sm">
                    Read more about LinkedIn's policy{' '}
                    <Link
                        target="_blank"
                        to={'https://www.linkedin.com/help/linkedin/answer/a563153'}
                        className="hover:underline text-text-brand-secondary"
                    >
                        <strong>here</strong>
                    </Link>
                    .
                </p>
                <div className="mt-2 bg-background-secondary rounded-lg p-4">
                    <p className="text-sm italic">
                        <strong>Pro tip:</strong> Working within your existing network yields 5x higher response rates,
                        so we recommend you to add existing connections for best results.
                    </p>
                </div>
                <DialogFooter className="mt-4">
                    <DialogClose asChild>
                        <Button variant="outline">Close</Button>
                    </DialogClose>
                </DialogFooter>
            </DialogContent>
        </SearchParamDialog>
    );
};
