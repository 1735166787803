import { CircularProgress } from '@mui/material';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';
import { SearchTypes, contactsDataAtom, contactsLoadingAtom } from '../../../atoms/globalSearch';
import { paths } from '../../../routes/paths';
import useProspectAvatars from '../../../views/chats/hooks/use-prospect-avatars';
import CategoryExpandShrink from '../CategoryShowMore';
import ProspectRow from '../ProspectRow';
import { SearchSectionTitle } from '../globalSearchPopup';
import { useGlobalSearch } from '../hooks/GlobalSearch';

export const ContactSection = ({
    searchValue,
    userId,
    onClickAway,
    onClickTracking,
}: {
    searchValue: string;
    userId: string;
    onClickAway: () => void;
    onClickTracking: () => void;
}) => {
    const navigate = useNavigate();
    const globalSearch = useGlobalSearch();
    const [contacts, setContacts] = useAtom(contactsDataAtom);
    const [loading, setLoading] = useAtom(contactsLoadingAtom);
    const pagination = contacts?.pagination;
    const numberOfContacts = contacts?.data?.length;
    const { prospectAvatarUrls } = useProspectAvatars();

    const handleProspectClick = ({ prospectId }: { prospectId: string }) => {
        const prospectDetailsURLPath = `${paths.CHATS}/${prospectId}`;
        onClickAway();

        if (window.location.pathname !== prospectDetailsURLPath) {
            navigate(prospectDetailsURLPath);
        }
    };

    const sectionTitle = 'Contacts';

    const handleShowMore = async () => {
        try {
            setLoading(true);
            const query = {
                match: searchValue,
                perPage: 5,
                page: pagination.page + 1,
                searchIn: [SearchTypes.PROSPECTS],
            };
            const response = await globalSearch.search(query);
            setContacts((prev) => ({
                data: [...prev.data, ...(response?.contacts?.data || [])],
                pagination: response?.prospects?.pagination,
            }));
        } finally {
            setLoading(false);
        }
    };

    if (numberOfContacts === 0) return null;
    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <SearchSectionTitle title={sectionTitle} size="12px" />
                {!contacts && loading && <CircularProgress size="12px" style={{ marginLeft: '8px' }} />}
            </div>
            {contacts?.data?.map((userContact, i) => (
                <ProspectRow
                    onClick={() =>
                        handleProspectClick({
                            prospectId: userContact.prospectId,
                        })
                    }
                    onClickTracking={onClickTracking}
                    key={i}
                    prospect={userContact}
                    searchValue={searchValue}
                    onClickAway={onClickAway}
                    avatarUrl={prospectAvatarUrls[userContact['prospectId']]}
                    userId={userId}
                />
            ))}
            <CategoryExpandShrink
                page={pagination?.page}
                numberOfPages={pagination?.numberOfPages}
                loading={loading}
                numberOfRows={numberOfContacts}
                onShowMore={() => handleShowMore()}
            />
        </div>
    );
};
