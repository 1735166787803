import { useApolloClient, useQuery } from '@apollo/client';
import {
    GET_ALL_USER_CONTACTS,
    GET_TOTAL_NUMBER_OF_USER_CONTACTS,
    GET_TOTAL_NUMBER_OF_USER_CONTACTS_IN_ANY_PLAYBOOK,
    GET_USER_CONTACT_BY_PROSPECT_ID,
    GET_USER_CONTACTS_BY_GROUP_IDS,
} from '@zaplify/graphql';
import { useToast } from '@shadcn/ui/hooks/use-toast';
import { ContactSource } from '@zaplify/services/user-contacts/shared';
import { useMutation, useQuery as useQueryTan } from '@tanstack/react-query';
import { useSdk } from '../sdk';
import { CreateProspectDataDto, ProspectDto } from '@zaplify/prospects';
import { useContactLimitReachedDialog } from '../components/dialogs/contact-limit-reached-dialog';
import { useState, useMemo } from 'react';
import { useProspectSync } from './use-prospect-sync';
import { useWebExtension } from './use-web-extension';
import { useLinkedin } from './use-linkedin';

export interface ContactToAdd {
    personId?: string;
    linkedinUrl?: string;
    linkedinUserId?: string;
    userContactId?: string;
    createProspect?: CreateProspectDataDto;
}

export function useAddContacts() {
    const { toast } = useToast();
    const { openContactLimitReachedDialog } = useContactLimitReachedDialog();
    const [purchasingInProgress, setPurchasingInProgress] = useState(false);
    const { extensionStatus } = useLinkedin();

    const {
        userContacts: { getUserContactLimit, assignUserContactsToGroup },
    } = useSdk();

    const {
        prospect: { purchaseProspects: purchaseProspectsMutation, runBulkImport: runBulkImportMutation },
    } = useSdk();
    const { mutateAsync: purchaseFn } = useMutation(purchaseProspectsMutation());
    const { mutateAsync: importFn } = useMutation(runBulkImportMutation());
    const { syncMany } = useProspectSync();
    const { getLinkedInProfileFull } = useWebExtension();
    const { data: numberOfUserContactsData } = useQuery(GET_TOTAL_NUMBER_OF_USER_CONTACTS_IN_ANY_PLAYBOOK);
    const { data: limit } = useQueryTan(getUserContactLimit());
    const { mutateAsync: assignContactsToGroup } = useMutation(assignUserContactsToGroup());
    const apolloClient = useApolloClient();

    const hasReachedLimit = useMemo(() => {
        if (!numberOfUserContactsData || typeof limit !== 'number') return false;
        return numberOfUserContactsData.UserContacts_aggregate.aggregate.count >= limit;
    }, [numberOfUserContactsData, limit]);

    const addContacts = async (
        contacts: ContactToAdd[],
        options: {
            groupId?: string;
            source: ContactSource;
            onStartedAdding?: () => void;
            onAdded?: () => void;
        }
    ) => {
        const { groupId, source, onStartedAdding, onAdded } = options;

        try {
            if (hasReachedLimit) {
                openContactLimitReachedDialog();
                return { success: false };
            }

            setPurchasingInProgress(true);
            onStartedAdding?.();

            let addedProspectIds: string[] = [];
            // Handle existing contacts (with userContactId)
            const existingContactIds = contacts
                .filter((contact) => contact.userContactId)
                .map((contact) => contact.userContactId) as string[];

            if (existingContactIds.length > 0 && groupId) {
                const updatedUserContacts = await assignContactsToGroup({
                    contactIds: existingContactIds,
                    groupId,
                });
                if (updatedUserContacts.length > 0) {
                    addedProspectIds.push(...updatedUserContacts.map((c) => c.prospectId));
                }
                apolloClient.refetchQueries({
                    include: [
                        GET_USER_CONTACT_BY_PROSPECT_ID,
                        GET_TOTAL_NUMBER_OF_USER_CONTACTS,
                        GET_TOTAL_NUMBER_OF_USER_CONTACTS_IN_ANY_PLAYBOOK,
                    ],
                });
                console.log('addContacts: assigned contacts to group', existingContactIds, groupId);
            }

            // Handle new contacts
            const newContacts = contacts.filter((contact) => !contact.userContactId);

            if (newContacts.length > 0) {
                // First attempt: Try LinkedIn for all contacts that have LinkedIn data
                const linkedinContacts = newContacts.filter((c) => c.linkedinUrl || c.linkedinUserId);
                const linkedinResults =
                    linkedinContacts.length > 0
                        ? await purchaseFromLinkedinSync({
                              linkedinUrls: linkedinContacts
                                  .filter((c) => c.linkedinUrl)
                                  .map((c) => c.linkedinUrl!) as string[],
                              linkedinMemberIds: linkedinContacts
                                  .filter((c) => c.linkedinUserId)
                                  .map((c) => c.linkedinUserId!) as string[],
                              groupId,
                              source,
                          })
                        : [];

                // Track which contacts were successfully added via LinkedIn
                const successfulLinkedinProspectIds = new Set(linkedinResults.map((p) => p.id));
                addedProspectIds.push(...successfulLinkedinProspectIds);

                // Second attempt: Try import for contacts that failed LinkedIn or don't have LinkedIn data
                const remainingForImport = newContacts.filter((contact) => {
                    const linkedinAttempted = contact.linkedinUrl || contact.linkedinUserId;
                    // Include if either:
                    // 1. Has import data and LinkedIn wasn't attempted
                    // 2. Has import data and LinkedIn attempt failed
                    return (
                        contact.createProspect &&
                        (!linkedinAttempted ||
                            (linkedinAttempted &&
                                !linkedinResults.some((r) => {
                                    if (contact.linkedinUrl) return r.data.linkedinProfileUrl === contact.linkedinUrl;
                                    if (contact.linkedinUserId) return r.data.linkedinUserId === contact.linkedinUserId;
                                    return false;
                                })))
                    );
                });

                const importResults =
                    remainingForImport.length > 0
                        ? await addFromImport({
                              prospectData: remainingForImport.map((c) => c.createProspect!),
                              groupId,
                              source,
                          })
                        : [];

                // Track which contacts were successfully imported
                const successfulImportIds = new Set(importResults.map((p) => p.id));
                addedProspectIds.push(...successfulImportIds);

                // Final attempt: Try purchase for remaining contacts that have personIds
                const remainingForPurchase = newContacts.filter((contact) => {
                    const linkedinAttempted = contact.linkedinUrl || contact.linkedinUserId;
                    const importAttempted = contact.createProspect;
                    return (
                        contact.personId &&
                        (!linkedinAttempted || !successfulLinkedinProspectIds.has(contact.personId)) &&
                        (!importAttempted || !successfulImportIds.has(contact.personId))
                    );
                });

                if (remainingForPurchase.length > 0) {
                    const purchasedIds = await addFromPurchasedProspects({
                        personIds: remainingForPurchase.map((c) => c.personId!),
                        groupId,
                        source,
                    });
                    addedProspectIds.push(...purchasedIds);
                }

                // Show appropriate error message based on what failed
                if (addedProspectIds.length === 0) {
                    const attemptedMethods = new Set<string>();
                    if (linkedinContacts.length > 0) attemptedMethods.add('LinkedIn');
                    if (remainingForImport.length > 0) attemptedMethods.add('import');
                    if (remainingForPurchase.length > 0) attemptedMethods.add('purchase');

                    if (linkedinContacts?.length && extensionStatus === 'NOT_INSTALLED') {
                        toast({
                            title: 'Could not add contacts',
                            description: 'Please install the LinkedIn extension to add contacts from LinkedIn.',
                            variant: 'destructive',
                            duration: 10000,
                        });
                    } else {
                        toast({
                            title: 'Could not add contacts',
                            description: `Failed to add contacts. Please try again later.`,
                            variant: 'destructive',
                            duration: 10000,
                        });
                    }
                    return { success: false };
                }

                // Partial success message if some contacts failed
                if (addedProspectIds.length < newContacts.length) {
                    toast({
                        title: 'Some contacts could not be added',
                        description: `Successfully added ${addedProspectIds.length} out of ${newContacts.length} contacts.`,
                        variant: 'default',
                        duration: 10000,
                    });
                }
            }

            if (addedProspectIds.length === 0) {
                return { success: false };
            }

            syncMany(addedProspectIds.map((id) => ({ prospectId: id }))).catch((err) => {
                console.error('Purchase Prospect had error when syncing prospects', err);
            });

            onAdded?.();
            return { success: true };
        } catch (error) {
            console.error('Error adding contacts', error);
            if (contacts.length === 1) {
                toast({
                    title: 'Could not add contact',
                    description: 'An error occurred while adding contacts. Please try again.',
                    variant: 'destructive',
                    duration: 10000,
                });
            } else {
                toast({
                    title: 'Could not add some or all contacts',
                    description: 'Please check your contacts and try again.',
                    variant: 'destructive',
                    duration: 10000,
                });
            }
            return { success: false, error };
        } finally {
            await apolloClient.refetchQueries({
                include: [
                    GET_ALL_USER_CONTACTS,
                    GET_USER_CONTACTS_BY_GROUP_IDS,
                    GET_USER_CONTACT_BY_PROSPECT_ID,
                    GET_TOTAL_NUMBER_OF_USER_CONTACTS,
                    GET_TOTAL_NUMBER_OF_USER_CONTACTS_IN_ANY_PLAYBOOK,
                ],
            });
            setPurchasingInProgress(false);
        }
    };

    const purchaseFromLinkedinSync = async (params: {
        linkedinUrls: string[];
        linkedinMemberIds: string[];
        groupId?: string | 'default' | 'none';
        source: ContactSource;
    }): Promise<ProspectDto[]> => {
        try {
            const profilesFromUrls =
                params.linkedinUrls?.length > 0
                    ? await Promise.all(
                          params.linkedinUrls.map((url) =>
                              getLinkedInProfileFull({ url }).then((res) => {
                                  if (res.success) return res.prospectData;
                              })
                          )
                      )
                    : [];
            const profilesFromMemberIds =
                params.linkedinMemberIds?.length > 0
                    ? await Promise.all(
                          params.linkedinMemberIds.map((memberId) =>
                              getLinkedInProfileFull({ memberId }).then((res) => {
                                  if (res.success) return res.prospectData;
                              })
                          )
                      )
                    : [];
            const profiles = [...profilesFromUrls, ...profilesFromMemberIds];
            const prospectData = profiles.map((p) => p);
            const result = await importFn({ prospectData, groupId: params.groupId, source: params.source });
            return result;
        } catch (error) {
            console.error('Error purchasing prospects from LinkedIn sync', error);
            return [];
        }
    };

    const addFromImport = async (params: {
        prospectData: CreateProspectDataDto[];
        groupId?: string | 'default' | 'none';
        source: ContactSource;
    }) => {
        try {
            const result = await importFn({
                prospectData: params.prospectData,
                groupId: params.groupId,
                source: params.source,
            });
            return result;
        } catch (error) {
            console.error('Error importing prospects', error);
            return [];
        }
    };

    const addFromPurchasedProspects = async (params: {
        personIds: string[];
        groupId?: string | 'default' | 'none';
        source: ContactSource;
    }) => {
        try {
            const result = await purchaseFn({
                personIds: params.personIds,
                linkedinUrls: [],
                groupId: params.groupId,
                source: params.source,
            });
            return result;
        } catch (error) {
            console.error('Error purchasing prospects', error);
            return [];
        }
    };

    return {
        addContacts,
        purchasingInProgress,
        hasReachedLimit,
    };
}
