import classNames from 'classnames';
import { ButtonBase } from '@mui/material';
import { styled } from '@mui/styles';

export const AddAudienceContainer = styled(({ children, className, dataCy, ...props }) => (
    <ButtonBase focusRipple className={className} onClick={props.onClick} data-cy={dataCy}>
        {children}
    </ButtonBase>
))(({ theme }: any) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    background: 'white',
    padding: '22px 29px',
    borderRadius: '10px',
    boxShadow: `${theme.shadow.g}`,
    width: '353px',
    height: '99px',
    justifyContent: 'space-between',
    transition: '0.1s',
    '& > svg:first-child': {
        width: '50px',
        height: '50px',
    },
    '& > div': {
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
        '& > h5:first-child': {
            marginBottom: '4px',
            fontWeight: '500',
            fontSize: '17px',
        },
        '& > h5:last-child': {
            fontWeight: '300',
            fontSize: '13px',
        },
    },
    '& > svg:last-child': {
        width: '10px',
        height: '12px',
        color: theme.palette.graphics.iconDefault,
    },
    '&:hover': {
        boxShadow: theme.shadow.card,
    },
}));

export const InsideContainer = styled('div')(({ theme }: any) => ({
    '& > div:first-child': {
        display: 'flex',
        alignContent: 'flex-start',
        flexDirection: 'column',
        textAlign: 'left',
        '& > p': {
            color: theme.palette.colors.white,
        },
        '& > h5': {
            color: theme.palette.colors.white,
        },
    },
    '& > div:last-child': {
        marginTop: '13px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        '& > div:first-child': {
            display: 'flex',
            alignContent: 'center',
            flexDirection: 'row',
            marginRight: '55px',
            '& > div': {
                marginRight: '7px',
            },
        },
    },
}));

export const TileContainer = styled(({ children, className, ...props }) => (
    <div className={classNames(className, { emphasize: props.emphasize })}>{children}</div>
))(({ theme }: any) => ({
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.colors.blueLight,
    boxShadow: `0 2px 40px ${theme.palette.colors.overlay.dark(14)}`,
    borderRadius: '4px',
    padding: theme.spacing(2, 6),
    marginBottom: theme.spacing(1),
    '&:hover': {
        backgroundColor: theme.palette.primary.main,
    },
    '& svg': {
        width: '4rem',
        height: '4rem',
    },
    '&.emphasize': {
        padding: theme.spacing(3, 8),
        backgroundColor: theme.palette.colors.blackLight,
        '&:hover': {
            backgroundColor: theme.palette.colors.blackLighter,
        },
        '& svg': {
            width: '5rem',
            height: '5rem',
        },
    },
}));
