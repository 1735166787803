import { Button as ButtonMaterialUI } from '@mui/material';
import { darken } from '@mui/material/styles';
import { styled } from '@mui/styles';
import { GuardedAnchor, GuardedLink } from '@zaplify/ui';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const ButtonComponent = styled(
    ({ children, className, ...props }) =>
        (props.link && (
            <GuardedLink to={props.link} className={className}>
                <ButtonMaterialUI
                    className={classNames(props.mode, props.variant || 'contained')}
                    variant={props.variant || 'contained'}
                    onClick={props.onClick || (() => {})}
                    type={props.type || 'button'}
                    startIcon={props.startIcon}
                    endIcon={props.endIcon}
                    disabled={props.disabled}
                    size={props.size}
                    data-cy={props.dataCy}
                >
                    {children}
                </ButtonMaterialUI>
            </GuardedLink>
        )) ||
        (props.href && (
            <GuardedAnchor href={props.href} target={props.target || '_blank'} className={className}>
                <ButtonMaterialUI
                    className={classNames(props.mode, props.variant || 'contained')}
                    variant={props.variant || 'contained'}
                    onClick={props.onClick || (() => {})}
                    type={props.type || 'button'}
                    startIcon={props.startIcon}
                    endIcon={props.endIcon}
                    disabled={props.disabled}
                    size={props.size}
                    data-cy={props.dataCy}
                >
                    {children}
                </ButtonMaterialUI>
            </GuardedAnchor>
        )) ||
        (true && (
            <span className={className} style={props.fullWidth ? { width: '100%' } : {}}>
                <ButtonMaterialUI
                    fullWidth={props.fullWidth || false}
                    className={classNames(props.mode, props.variant || 'contained')}
                    variant={props.variant || 'contained'}
                    onClick={props.onClick || (() => {})}
                    href={props.href}
                    target={props.target}
                    type={props.type || 'button'}
                    startIcon={props.startIcon}
                    endIcon={props.endIcon}
                    disabled={props.disabled}
                    size={props.size}
                    data-cy={props.dataCy}
                >
                    {children}
                </ButtonMaterialUI>
            </span>
        )),
)(({ theme, borderRadius, height, width, customColor }: any) => ({
    textDecoration: 'none',
    height: height,
    '&:hover': {
        textDecoration: 'none',
    },
    '& > button': {
        color: theme.palette.colors.white,
        borderRadius: borderRadius || '8px',
        paddingTop: '12px',
        paddingBottom: '12px',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        backgroundColor: theme.palette.primary.light,
        boxShadow: 'none',
        height: height || 50,
        boxSizing: 'border-box',
        width: width,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            boxShadow: 'none',
        },
        '&.outlined': {
            paddingTop: '8px',
            paddingBottom: '8px',
            backgroundColor: 'transparent',
            color: customColor || theme.palette.primary.light,
            borderColor: customColor || theme.palette.primary.light,
            '&:hover': {
                color: (customColor && darken(customColor, 0.05)) || theme.palette.primary.main,
                borderColor: (customColor && darken(customColor, 0.05)) || theme.palette.primary.main,
            },
        },
        '&.text': {
            paddingTop: '8px',
            paddingBottom: '8px',
            backgroundColor: 'transparent',
            color: customColor || theme.palette.graphics.icon,
            '&:hover': {
                color: (customColor && darken(customColor, 0.05)) || theme.palette.graphics.infoBox,
            },
        },
        '&.secondary': {
            border: '0.8px solid #E1E6E8',
            backgroundColor: 'white',
            color: '#3A4B59',
            height: height || 34,
            '&:hover': {
                color: theme.palette.graphics.infoBox,
                backgroundColor: 'rgba(21, 27, 38, 0.04)',
            },
            '&.Mui-disabled': {
                color: theme.palette.colors.grey,
            },
        },
        '&.grey': {
            border: '0.8px solid #E1E6E8',
            backgroundColor: theme.palette.colors.greyStroke,
            color: theme.palette.colors.greyDark,
            '&:hover': {
                backgroundColor: theme.palette.colors.greyLight,
            },
        },
        '&.red': {
            backgroundColor: theme.palette.colors.redPale,
            '&:hover': {
                backgroundColor: theme.palette.colors.red,
            },
            '&.outlined': {
                backgroundColor: 'transparent',
                color: theme.palette.colors.redPale,
                borderColor: theme.palette.colors.redPale,
                '&:hover': {
                    color: theme.palette.colors.red,
                    borderColor: theme.palette.colors.red,
                },
            },
        },
        '&.green': {
            backgroundColor: theme.palette.colors.greenPale,
            '&:hover': {
                backgroundColor: theme.palette.colors.green,
            },
            '&.outlined': {
                backgroundColor: 'transparent',
                color: theme.palette.colors.greenPale,
                borderColor: theme.palette.colors.greenPale,
                '&:hover': {
                    color: theme.palette.colors.green,
                    borderColor: theme.palette.colors.green,
                },
            },
        },
        '&.blue': {
            backgroundColor: theme.palette.colors.bluePale,
            '&:hover': {
                backgroundColor: theme.palette.colors.blue,
            },
            '&.outlined': {
                backgroundColor: 'transparent',
                color: theme.palette.colors.bluePale,
                borderColor: theme.palette.colors.bluePale,
                '&:hover': {
                    color: theme.palette.colors.blue,
                    borderColor: theme.palette.colors.blue,
                },
            },
        },
        '&.white-font': {
            color: theme.palette.colors.white,
        },
        '&.black-font': {
            color: theme.palette.colors.black,
        },
        '&.transparent': {
            boxShadow: 'none',
            backgroundColor: 'transparent',
            '&:hover': {
                boxShadow: 'none',
                backgroundColor: 'transparent',
            },
            '&.outlined': {
                borderColor: 'transparent',
            },
        },
        '&.black': {
            backgroundColor: '#23323F',
            '&:hover': {
                background: '#14223A',
            },
            '&.Mui-disabled': {
                color: theme.palette.colors.grey,
            },
        },
    },
}));

export const ButtonFontColor = {
    white: 'white-font',
    black: 'black-font',
};
export const ButtonColor = {
    red: 'red',
    blue: 'blue',
    green: 'green',
    transparent: 'transparent',
};

export const Button = (props) => {
    return (
        <ButtonComponent
            fullWidth={props.fullWidth}
            variant={props.variant}
            mode={props.classes}
            link={props.link}
            onClick={props.onClick}
            href={props.href}
            target={props.target}
            type={props.type}
            startIcon={props.startIcon}
            endIcon={props.endIcon}
            disabled={props.disabled}
            borderRadius={props.borderRadius}
            height={props.height}
            width={props.width}
            customColor={props.customColor}
            size={props.size}
            dataCy={props.dataCy}
        >
            {props.text}
        </ButtonComponent>
    );
};

Button.propTypes = {
    text: PropTypes.string.isRequired,
    variant: PropTypes.oneOf(['outlined', 'contained', 'text', 'secondary']),
    classes: PropTypes.arrayOf(PropTypes.string),
    link: PropTypes.string,
    onClick: PropTypes.func,
    href: PropTypes.string,
    target: PropTypes.string,
    type: PropTypes.string,
    startIcon: PropTypes.node,
    endIcon: PropTypes.node,
    disabled: PropTypes.bool,
    borderRadius: PropTypes.string,
    fullWidth: PropTypes.bool,
    width: PropTypes.string || PropTypes.number,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    size: PropTypes.string,
    customColor: PropTypes.string,
    dataCy: PropTypes.string,
};
