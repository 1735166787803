import { FC, useEffect, useState } from 'react';
import {
    format,
    subDays,
    startOfDay,
    endOfDay,
    startOfWeek,
    endOfWeek,
    startOfMonth,
    endOfMonth,
    startOfYear,
    endOfYear,
    isSameDay,
    subWeeks,
    subMonths,
    subYears,
} from 'date-fns';
import { Calendar as CalendarIcon } from 'lucide-react';
import { DateRange } from 'react-day-picker';
import { cn } from '@shadcn/ui/lib/utils';
import { Button } from '@shadcn/ui/components/ui/button';
import { Calendar } from '@shadcn/ui/components/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from '@shadcn/ui/components/ui/popover';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@shadcn/ui/components/ui/select';

interface DatePickerWithRangeProps {
    date: DateRange | undefined;
    setDate: (date: DateRange | undefined) => void;
}

const today = new Date();

const presets = [
    {
        label: 'Today',
        getValue: () => ({
            from: startOfDay(today),
            to: endOfDay(today),
        }),
    },
    {
        label: 'Yesterday',
        getValue: () => ({
            from: startOfDay(subDays(today, 1)),
            to: endOfDay(subDays(today, 1)),
        }),
    },
    {
        label: 'Last 7 days',
        getValue: () => ({
            from: subDays(today, 7),
            to: today,
        }),
    },
    {
        label: 'Last 30 days',
        getValue: () => ({
            from: subDays(today, 30),
            to: today,
        }),
    },
    {
        label: 'This week',
        getValue: () => ({
            from: startOfWeek(today),
            to: today,
        }),
    },
    {
        label: 'This month',
        getValue: () => ({
            from: startOfMonth(today),
            to: today,
        }),
    },
    {
        label: 'This year',
        getValue: () => ({
            from: startOfYear(today),
            to: today,
        }),
    },
    {
        label: 'Last week',
        getValue: () => ({
            from: startOfWeek(subWeeks(today, 1)),
            to: endOfWeek(subWeeks(today, 1)),
        }),
    },
    {
        label: 'Last month',
        getValue: () => ({
            from: startOfMonth(subMonths(today, 1)),
            to: endOfMonth(subMonths(today, 1)),
        }),
    },
    {
        label: 'Last year',
        getValue: () => ({
            from: startOfYear(subYears(today, 1)),
            to: endOfYear(subYears(today, 1)),
        }),
    },
];

export const DatePickerWithRange: FC<DatePickerWithRangeProps> = ({ date, setDate }) => {
    const [selectedPreset, setSelectedPreset] = useState<string>('');

    useEffect(() => {
        if (date) {
            const matchingPreset = presets.find((preset) => {
                const presetValue = preset.getValue();
                return isSameDay(date.from, presetValue.from) && isSameDay(date.to, presetValue.to);
            });

            if (matchingPreset) {
                setSelectedPreset(matchingPreset.label);
            } else {
                setSelectedPreset('');
            }
        }
    }, [date]);

    return (
        <div className="flex flex-wrap gap-2">
            <Select
                value={selectedPreset}
                onValueChange={(value) => {
                    const preset = presets.find((preset) => preset.label === value);
                    if (preset) {
                        setDate(preset.getValue());
                        setSelectedPreset(value);
                    }
                }}
            >
                <SelectTrigger className="w-full md:w-fit md:max-w-[300px]">
                    <SelectValue placeholder="Select a preset..." />
                </SelectTrigger>
                <SelectContent position="popper">
                    {presets.map((preset) => (
                        <SelectItem key={preset.label} value={preset.label}>
                            {preset.label}
                        </SelectItem>
                    ))}
                </SelectContent>
            </Select>
            <Popover>
                <PopoverTrigger asChild>
                    <Button
                        id="date"
                        variant="outline"
                        className={cn(
                            'w-full md:w-fit md:max-w-[300px] justify-start text-left font-normal',
                            !date && 'text-muted-foreground'
                        )}
                    >
                        <CalendarIcon className="mr-2 h-4 w-4" />
                        {date?.from ? (
                            date?.to ? (
                                <>
                                    {format(date.from, 'LLL dd, y')} - {format(date.to, 'LLL dd, y')}
                                </>
                            ) : (
                                format(date.from, 'LLL dd, y')
                            )
                        ) : (
                            <span>Pick a date</span>
                        )}
                    </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                        initialFocus
                        mode="range"
                        defaultMonth={date?.from}
                        selected={date}
                        onSelect={setDate}
                        numberOfMonths={2}
                        disabled={{ after: today }}
                        toDate={today}
                    />
                </PopoverContent>
            </Popover>
        </div>
    );
};
