import { useEffect, useState } from 'react';
import { useAppSelector } from '../redux/store/configureStore';
import { getZaplifySdk } from '@zaplify/sdk';
import { IReferralLinkData } from '@zaplify/users/shared';

export const useReferrals = () => {
    const sdk = getZaplifySdk().profiles;
    const userId = useAppSelector((state) => state.user.zaplifyUser.id);
    const [referralLink, setReferralLink] = useState<string>();

    useEffect(() => {
        sdk.user.getReferralLink().then((result) => {
            setReferralLink(formatReferralLink(result));
        });
    }, [userId]);

    const formatReferralLink = (referralLinkData: IReferralLinkData) => {
        if (!referralLinkData?.id) {
            return '';
        }

        const protocol = window.location.protocol;
        const host = window.location.host;

        return `${protocol}//${host}/register?referralLinkId=${referralLinkData.id}`;
    };

    return {
        referralLink,
    };
};
