import { styled } from '@mui/styles';

export const TooltipBody = styled('div')(() => ({
    backgroundColor: '#2B2D36',
    maxWidth: '250px',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.05)',
    borderRadius: '3px',
    display: 'flex',
    color: 'white',
    flexDirection: 'column',
    padding: '16px 16px',
    alignItems: 'flex-start',
    fontSize: '13px',
    '& > button': {
        marginTop: '12px',
        border: 'none',
        backgroundColor: 'transparent',
        color: 'white',
        fontWeight: '700',
        marginLeft: '-4px',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
}));

export const Tooltip = ({ step, tooltipProps, closeProps }) => (
    <TooltipBody {...tooltipProps}>
        {step.content}
        <button {...closeProps}>Got it</button>
    </TooltipBody>
);
