import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { X } from 'lucide-react';

interface ModalProps {
    open: boolean;
    onClose: () => void;
    title: string;
    subTitle: string;
    children: React.ReactNode;
}

export const Modal: React.FC<ModalProps> = ({ open, onClose, title, subTitle, children }) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" PaperProps={{ sx: { borderRadius: '14px' } }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
                <DialogTitle
                    sx={{
                        padding: '24px 24px 0',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '8px',
                    }}
                >
                    <Box>
                        <Typography sx={{ color: '#23323F', fontSize: '19px', fontWeight: 500, lineHeight: '28px' }}>
                            {title}
                        </Typography>
                        <Typography sx={{ color: '#6B7985', fontSize: '15px', lineHeight: '20px' }}>
                            {subTitle}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            flexDirection: 'column',
                        }}
                    >
                        <IconButton sx={{}} onClick={onClose}>
                            <X size={20} />
                        </IconButton>
                    </Box>
                </DialogTitle>

                <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '48px' }}>{children}</DialogContent>
            </Box>
        </Dialog>
    );
};
