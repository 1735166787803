import { Box, Typography } from '@mui/material';
import { AccountResponseDto } from '@zaplify/prospects';
import AutoSuggestHighlight from './AutoSuggestHighlight';
import { ArrowUpRight } from 'lucide-react';
import GlobalSearchAccountRowStyled from './AccountRow.styled';
import { useState } from 'react';
import { getUrlHostname } from './util';

const AccountRow = ({
    account,
    searchValue,
    onClick,
}: {
    account: AccountResponseDto;
    searchValue: string;
    onClick: () => void;
}) => {
    const [isHovering, setIsHovering] = useState(false);

    const handleMouseOver = () => {
        setIsHovering(true);
    };

    const handleMouseOut = () => {
        setIsHovering(false);
    };

    return (
        <GlobalSearchAccountRowStyled onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} onClick={onClick}>
            <Box
                sx={{
                    display: 'flex',
                    paddingY: 'var(--S-xs, 6px) var(--S-s-regular, 8px)',
                    alignItems: 'center',
                    gap: 'var(--S-s-regular, 12px)',
                    alignSelf: 'stretch',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 'var(--S-s-spacious, 12px)',
                    }}
                >
                    <img
                        className="logo"
                        style={{
                            width: '40px',
                            height: '40px',
                            borderRadius: 'var(--radius-round, 100px)',
                            background: 'url(<path-to-image>), lightgray 50% / contain no-repeat',
                        }}
                        onError={(e) => {
                            (e.target as HTMLImageElement).onerror = null;
                            (e.target as HTMLImageElement).src = '/images/no-logo-icon.svg';
                        }}
                        src={
                            account?.website
                                ? `https://logo.clearbit.com/${getUrlHostname(account?.website)}`
                                : '/images/no-logo-icon.svg'
                        }
                    />

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            gap: 'var(--S-3xs, 2px)',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: 'var(--S-s-regular, 8px)',
                            }}
                        >
                            <AutoSuggestHighlight text={account?.name} value={searchValue} fullWidth />
                        </Box>
                        <Typography
                            sx={{
                                color: 'var(--grey-palette-grey-4, #6B7985)',
                                textAlign: 'right',
                                fontSize: '13px',
                                fontWeight: 400,
                                lineHeight: '19px',
                            }}
                        >
                            {account?.industry}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <ArrowUpRight
                size={20}
                strokeWidth={1}
                style={{ color: '#6B7985', display: isHovering ? 'inherit' : 'none' }}
            />
        </GlobalSearchAccountRowStyled>
    );
};

export default AccountRow;
