import { IntegrationType } from '@zaplify/enums';

export const selectors = {
    SIDEBAR_CONTAINER: 'sidebar-container',
    PROSPECTS_CONTAINER: 'prospects-container',
};

export const elements = {
    sidebarContainer: (): HTMLElement | null => document.querySelector(`#${selectors.SIDEBAR_CONTAINER}`),
    prospectsContainer: (): HTMLElement | null => document.querySelector(`#${selectors.PROSPECTS_CONTAINER}`),
};

export const DEFAULT_PROSPECTS_PER_COMPANY = 5;
export const DEFAULT_COMPANIES_PER_PAGE = 15;
export const BLOCKLIST_LIMIT = 5000;

export const featureToggle: {
    tableTopSection: boolean;
    disableDQWebSockets: boolean;
    globalSearchLessButton: boolean;
} = {
    // component hidden until we can show proper number of prospects on pages
    tableTopSection: false,
    disableDQWebSockets: true,
    globalSearchLessButton: false,
};

export const SocketEvents = {
    prospect: {
        ADDED_TO_CAMPAIGN: 'prospects_added_to_campaign',
        ENTERED_REVIEW_PROCESS: 'prospect_entered_review_process',
        QUEUED_IN_CAMPAIGN: 'prospect_queued_in_campaign',
        REVIEW_REQUESTED: 'prospect_review_requested',
        STARTED_IN_CAMPAIGN: 'prospect_started_in_campaign',
        FINiSHED_IN_CAMPAIGN: 'prospect_finished_in_campaign',
        PROSPECT_ENRICHMENT_FINISHED: 'prospect_enrichment_finished',
        SYNCED_WITH_HUBSPOT: 'prospect_synced_with_hubspot',
        SYNCED_WITH_UPSALES: 'prospect_synced_with_upsales',
        SYNCED_WITH_PIPEDRIVE: 'prospect_synced_with_pipedrive',
        SYNCED_WITH_SALESFORCE: 'prospect_synced_with_salesforce',
        SYNCED_WITH_WEBHOOKS: 'prospect_synced_with_webhooks',
    },
    company: {
        SYNCED_WITH_HUBSPOT: 'company_synced_with_hubspot',
        SYNCED_WITH_UPSALES: 'company_synced_with_upsales',
        SYNCED_WITH_PIPEDRIVE: 'company_synced_with_pipedrive',
        SYNCED_WITH_SALESFORCE: 'company_synced_with_salesforce',
        SYNCED_WITH_WEBHOOKS: 'company_synced_with_webhooks',
    },
    user: {
        rewards: {
            FIRST_CAMPAIGN_CREATED_REWARD_GRANTED: 'first-campaign-created-reward-granted',
        },
    },
    billing: {
        PAYMENT_SUCCEEDED: 'payment_succeeded',
        SEAT_ASSIGNED: 'seat_assigned',
        SUBSCRIPTION_CHANGED: 'subscription_changed',
        SEAT_UNASSIGNED: 'seat_unassigned',
        CREDIT_SPENT: 'credit_spent',
    },
    integration: {
        UPSALES_ACCOUNT_CREATED: 'upsales_account_created',
    },
    campaign: {
        MessageGenerationDataSourceFoundEvent: 'message_generation_data_source_found_event',
    },
};

export const LocalStorageKeys = {
    GLOBAL_SEARCH_VALUE: 'global_search_value',
    INVITATION_ID: 'invitation_id',
    INVITATION_SECURITY_TOKEN: 'invitation_securityToken',
    INVITATION_STATUS: 'invitation_status',
    PLAN: 'plan',
    REDIRECT_TO_URL: 'redirect_to_url',
    REDIRECT_TO_URL_COUNTER: 'redirect_to_url_counter',
    WORKFLOW_GENERATED_COPY_HASH: 'workflow_generated_copy_hash',
    LI_AUTHENTICATION_ERROR_NOTIFICATION: 'li_authentication_error_notification',
    LI_AUTHENTICATION_ATTEMPTS: 'li_authentication_attempts',
    REFERRAL_LINK: 'referral_link_id',
    AI_PROFILE_ONBOARDING: `ai-profile-onboarding`,
    EXTENSION_ERROR: 'extension_error',

    // Legacy keys that have been deprecated
    DEP_AUTHENTICATE_AS_USER: 'x-authenticate-as-user',
    DEP_JOIN_ORGANIZATION_NAME: 'join_organization_name',
    DEP_CURRENT_ORGANIZATION_NAME: 'current_organization_name',
    DEP_CREATE_ORGANIZATION_NAME: 'create_organization_name',
    DEP_CREATE_ORGANIZATION_DOMAIN: 'create_organization_domain',
    DEP_SELECTED_ORGANIZATION_ID: 'selected_organization_id',
    DEP_CURRENT_ORGANIZATION_ID: 'current_organization_id',
    DEP_PREVIOUS_ORGANIZATION_ID: 'previous_organization_id',
    DEP_TURN_ON_NEW_ZAPLIFY: 'turn_on_new_zaplify',

    TURN_ON_MESSENGER: 'turn_on_messenger',
    INITIAL_OUTREACH_ACCOUNTS_MODAL: 'initial_outreach_accounts_modal',
    MESSANGER_PROSPECTS: 'messanger_prospects',
    PROSPECT_AVATAR_URLS: 'prospect_avatar_urls',
    TOGGLE_SIDEBAR_PANEL: 'toggle_sidebar_panel',
    NAVIGATION_SIDEBAR_OPEN: 'navigation_sidebar_open',
    SNOOZE_TODO_IN_DAYS: 'snooze_todo_in_days',
    DRAFT_MESSAGES: 'draft_messages',
    GROUP_ID_TO_PLAYBOOK_IMAGE: 'group_id_to_playbook_image',
    SIDEBAR_PIN_STATE: 'sidebar_pin_state',
};

export const IntegrationConfigForPreviewProspectsAction = {
    [IntegrationType.HUBSPOT]: {
        syncedCompaniesStateField: 'hubspotSyncedCompanies',
        requestedProspectsStateField: 'hubspotRequestedProspects',
        syncedCompanyURLField: 'hubspotCompanyUrl',
        prospectsSyncedStateField: 'prospectsSyncedHubspot',
    },
};

import { GmailScopes, OutlookScopes } from '@zaplify/channel-accounts/shared';

const emailConfigProviders = ['ms', 'google'] as const;
export const emailConfig: {
    [key in typeof emailConfigProviders[number]]: {
        clientId: string;
        redirectUrl: string;
        scopes: string[];
    };
} = {
    ms: {
        clientId: import.meta.env.VITE_OAUTH_OUTLOOK_CLIENT_ID,
        redirectUrl: window.location.origin,
        scopes: OutlookScopes.all,
    },
    google: {
        clientId: import.meta.env.VITE_OAUTH_GOOGLE_CLIENT_ID,
        redirectUrl: window.location.origin,
        scopes: GmailScopes.all,
    },
};

export const googleWorkspaceOAuthId = '733572450029-a2050rev93ddkc6b324a9uigcnj090bm.apps.googleusercontent.com';
export const outlookOauthUrl = 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize';
