import { WhereInTime } from '@zaplify/campaigns';

export const isBetween = (date: Date, min: Date, max: Date) =>
    date.getTime() >= min.getTime() && date.getTime() <= max.getTime();

export const isBefore = (date: Date, max: Date) => date.getTime() < max.getTime();

export const isAfter = (date: Date, min: Date) => date.getTime() > min.getTime();

export const checkWhereInTime = (now: string | Date, min: string | Date, max: string | Date): WhereInTime => {
    let whereInTime: WhereInTime = WhereInTime.BEFORE;

    if (isBefore(new Date(now), new Date(min))) {
        whereInTime = WhereInTime.BEFORE;
    }

    if (isAfter(new Date(now), new Date(max))) {
        whereInTime = WhereInTime.AFTER;
    }

    if (isBetween(new Date(now), new Date(min), new Date(max))) {
        whereInTime = WhereInTime.BETWEEN;
    }

    return whereInTime;
};

export function getDayShortNameByIndex(index: number): string {
    const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    return days[index];
}
