import React from 'react';
import { Modal, Typography } from '@mui/material';
import { IntegrationNotCompletedModalContainer } from './containers';
import Button from '@mui/material/Button';

export const IntegrationNotCompletedModal = ({ isOpen, setIsOpen, handleLeave }) => {
    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <>
            <Modal open={isOpen} onClose={handleClose}>
                <IntegrationNotCompletedModalContainer>
                    <div className="modal-container">
                        <div className="input-container">
                            <div>
                                <Typography variant="h4" paragraph className="typography title-small">
                                    Leave integration setup?
                                </Typography>
                                <Typography paragraph className="typography title-small">
                                    Your integration is not completed. Do you want to leave anyways?
                                </Typography>
                            </div>
                        </div>
                        <div className="buttons-container">
                            <Button className={'button-outlined-blue button'} variant="outlined" onClick={handleClose}>
                                No, complete setup
                            </Button>
                            <Button className={'button-blue button'} variant="contained" onClick={handleLeave}>
                                Yes, leave anyways
                            </Button>
                        </div>
                    </div>
                </IntegrationNotCompletedModalContainer>
            </Modal>
        </>
    );
};
