import { useMutation, useQuery as useTanQuery } from '@tanstack/react-query';
import { useApolloClient, useQuery } from '@apollo/client';
import { useMemo, useState, useEffect } from 'react';
import { BookOpen, Trash2Icon, ChevronDown, Download, ViewIcon } from 'lucide-react';
import { useSdk } from '../../../sdk';
import { TooltipContent, TooltipProvider, TooltipTrigger, Tooltip } from '@shadcn/ui/components/ui/tooltip';
import ContactsTable from './contacts-table';
import { Button } from '@shadcn/ui/components/ui/button';
import {
    GET_GROUPS,
    GET_ALL_USER_CONTACTS,
    GET_USER_CONTACTS_BY_GROUP_IDS,
    GET_USER_CONTACT_BY_PROSPECT_ID,
} from '@zaplify/graphql';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
    DropdownMenuCheckboxItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
} from '@shadcn/ui/components/ui/dropdown-menu';
import { DeleteContactDialog } from '../../../components/dialogs/delete-contact-dialog';
import { useExportContactsDialog } from '../../../components/dialogs/export-contacts-dialog';
import { useDebounce } from '../../../hooks/use-debounce';
import { Dialog } from '@shadcn/ui/components/ui/dialog';
import { PlaybookFilterBadge } from '../../../components/playbook-filter-badge';
import { SearchInput } from '../../../components/search-input';
interface ContactsTableToolBarProps {
    table: ContactsTable;
}

export const ContactsTableToolBar: React.FC<ContactsTableToolBarProps> = ({ table }) => {
    const [filterValue, setFilterValue] = useState(table.getColumn('contact')?.getFilterValue() as string);
    const debouncedFilterValue = useDebounce(filterValue, 200);
    // Get total count of all contacts
    // const { data: allUserContactsData } = useQuery(GET_ALL_USER_CONTACTS, {
    //     variables: {
    //         limit: 0,
    //     },
    // });
    // const allUserContactsCount = allUserContactsData?.totalCount?.aggregate?.count;

    useEffect(() => {
        table.getColumn('contact')?.setFilterValue(debouncedFilterValue);
    }, [debouncedFilterValue, table]);

    return (
        <div className="flex flex-col gap-4 pb-4">
            <div className="flex items-center justify-between">
                <div className="flex flex-row gap-2">
                    <PlaybookFilterBadge className="rounded-md" showGroupCounts />
                    <SearchInput
                        className="max-w-md"
                        placeholder="Filter contacts..."
                        filterValue={filterValue}
                        setFilterValue={setFilterValue}
                    />
                </div>
                <div className="flex flex-row gap-2 mr-4">
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="outline" className="ml-auto">
                                <ViewIcon className="mr-2 h-4 w-4" />
                                View
                                <ChevronDown className="ml-2 h-4 w-4" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                            <DropdownMenuLabel>Toggle columns</DropdownMenuLabel>
                            <DropdownMenuSeparator />
                            {table
                                .getAllColumns()
                                .filter((column) => column.getCanHide())
                                .map((column) => {
                                    return (
                                        <DropdownMenuCheckboxItem
                                            key={column.id}
                                            className="capitalize"
                                            checked={column.getIsVisible()}
                                            onCheckedChange={(value) => column.toggleVisibility(!!value)}
                                        >
                                            {(column.columnDef?.header as string) || column.id}
                                        </DropdownMenuCheckboxItem>
                                    );
                                })}
                        </DropdownMenuContent>
                    </DropdownMenu>
                </div>
            </div>
            <div className="flex flex-row gap-4">
                <ContactsDeleteButton table={table} />
                <AssignContactsToPlaybookButton table={table} />
                <ExportContactsButton table={table} />
            </div>
        </div>
    );
};

export const ContactsDeleteButton = ({ table }: { table: ContactsTable }) => {
    const selectedRows = table.getSelectedRowModel().rows;
    const isDisabled = selectedRows.length === 0;

    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <Dialog open={isOpen} onOpenChange={setIsOpen}>
                <DeleteContactDialog
                    userContactIds={selectedRows.map((row) => row.original.contact.id)}
                    onDeleted={() => table.resetRowSelection()}
                />
            </Dialog>
            <Tooltip delayDuration={800}>
                <TooltipTrigger asChild>
                    <div>
                        <Button variant="outline" disabled={isDisabled} onClick={() => setIsOpen(true)}>
                            <Trash2Icon className="w-4 h-4" />
                            Remove
                        </Button>
                    </div>
                </TooltipTrigger>
                <TooltipContent side="bottom">
                    {isDisabled ? 'Select contacts to delete.' : 'Remove selected contacts'}
                </TooltipContent>
            </Tooltip>
        </>
    );
};

export const AssignContactsToPlaybookButton = ({ table }: { table: ContactsTable }) => {
    const {
        userContacts: { assignUserContactsToGroup },
    } = useSdk();
    const { data } = useQuery(GET_GROUPS);
    const groups = useMemo(() => data?.Groups, [data]);
    const client = useApolloClient();

    const { mutateAsync } = useMutation(assignUserContactsToGroup());
    const selectedRows = table.getSelectedRowModel().rows;
    const isDisabled = selectedRows.length === 0;

    return (
        <Tooltip delayDuration={800}>
            <DropdownMenu>
                <DropdownMenuTrigger asChild disabled={isDisabled}>
                    <TooltipTrigger asChild>
                        <Button variant="outline" disabled={isDisabled}>
                            <BookOpen className="w-4 h-4" />
                            <span>Assign to Playbook</span>
                            <ChevronDown className="ml-2 h-4 w-4" />
                        </Button>
                    </TooltipTrigger>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                    {groups?.map((group) => (
                        <DropdownMenuItem
                            key={group.id}
                            onClick={async () => {
                                await mutateAsync({
                                    contactIds: selectedRows.map((row) => row.original.contact.id),
                                    groupId: group.id,
                                });
                                await client.refetchQueries({
                                    include: [
                                        GET_ALL_USER_CONTACTS,
                                        GET_USER_CONTACTS_BY_GROUP_IDS,
                                        GET_USER_CONTACT_BY_PROSPECT_ID,
                                    ],
                                });
                            }}
                        >
                            {group.name}
                        </DropdownMenuItem>
                    ))}
                </DropdownMenuContent>
            </DropdownMenu>
            <TooltipContent side="bottom">
                {isDisabled ? 'Select contacts to enable this action.' : 'Assign selected contacts to a playbook'}
            </TooltipContent>
        </Tooltip>
    );
};

export const ExportContactsButton = ({ table }: { table: ContactsTable }) => {
    const selectedRows = table.getSelectedRowModel().rows;
    const { openExportContactsDialog } = useExportContactsDialog();

    return (
        <Tooltip delayDuration={800}>
            <TooltipTrigger asChild>
                <div className="hidden md:block">
                    <Button
                        variant="outline"
                        onClick={() => openExportContactsDialog(selectedRows.map((row) => row.original.contact.id))}
                    >
                        <Download className="w-4 h-4 mr-2" />
                        Export
                    </Button>
                </div>
            </TooltipTrigger>
            <TooltipContent side="bottom">{'Export all/selected contacts contacts'}</TooltipContent>
        </Tooltip>
    );
};
