import { FC } from 'react';
import { Button } from '@shadcn/ui/components/ui/button';
import { AlertCircle, RefreshCw, Settings2 } from 'lucide-react';
import { ReactComponent as LinkedInIcon } from '../../../../../../components/icons/linkedin-bigger.svg';
import { Separator } from '@shadcn/ui/components/ui/separator';
import { Link } from 'react-router-dom';

interface LinkedInSettingsProps {
    isConnected: boolean;
    onDisconnect: () => void;
    isDisconnecting: boolean;
}

export const LinkedInSettings: FC<LinkedInSettingsProps> = ({ isConnected, onDisconnect, isDisconnecting }) => {
    if (!isConnected) {
        return (
            <div className="flex flex-col items-center justify-center py-12">
                <AlertCircle className="h-12 w-12 text-yellow-500 mb-4" />
                <h3 className="text-lg font-medium mb-2">Not Connected</h3>
                <p className="text-gray-500 text-center mb-6">
                    Your LinkedIn account is not connected. Please connect it from the apps page.
                </p>
                <Button variant="default" asChild>
                    <Link to="..">Go to Apps</Link>
                </Button>
            </div>
        );
    }

    return (
        <div className="space-y-6">
            <div className="flex items-center">
                <LinkedInIcon className="h-10 w-10 object-contain mr-3" />
                <div>
                    <h3 className="text-lg font-semibold">LinkedIn</h3>
                    <p className="text-sm text-gray-500">Manage your LinkedIn connection settings</p>
                </div>
            </div>

            <Separator />

            <div className="space-y-4">
                <h4 className="text-md font-medium">Account Settings</h4>

                {/* Extension status */}
                <div className="rounded-md border p-4">
                    <div className="flex items-start gap-4">
                        <RefreshCw className="h-5 w-5 text-gray-500 mt-0.5" />
                        <div className="space-y-1 flex-1">
                            <p className="text-sm font-medium">Browser Extension Status</p>
                            <p className="text-sm text-gray-500">
                                The LinkedIn connection works through our browser extension. Check the status and
                                reconnect if needed.
                            </p>
                            <div className="mt-2 flex items-center">
                                <div className="h-2.5 w-2.5 rounded-full bg-green-500 mr-2"></div>
                                <span className="text-sm text-green-600">Connected</span>
                            </div>
                        </div>
                        <Button variant="outline" size="sm">
                            Check Extension
                        </Button>
                    </div>
                </div>

                {/* Message sending preferences */}
                <div className="rounded-md border p-4">
                    <div className="flex items-start gap-4">
                        <Settings2 className="h-5 w-5 text-gray-500 mt-0.5" />
                        <div className="space-y-1 flex-1">
                            <p className="text-sm font-medium">Message Sending Preferences</p>
                            <p className="text-sm text-gray-500">
                                Configure how messages are sent through your LinkedIn account
                            </p>
                        </div>
                        <Button variant="outline" size="sm">
                            Configure
                        </Button>
                    </div>
                </div>
            </div>

            {/* <Separator /> */}

            {/* Danger zone */}
            {/* <div className="space-y-4">
                <h4 className="text-md font-medium text-red-500">Danger Zone</h4>

                <div className="rounded-md border border-red-200 p-4">
                    <div className="flex items-start gap-4">
                        <Trash2 className="h-5 w-5 text-red-500 mt-0.5" />
                        <div className="space-y-1 flex-1">
                            <p className="text-sm font-medium">Disconnect Account</p>
                            <p className="text-sm text-gray-500">
                                Remove this LinkedIn account from your connected accounts. All associated messages will
                                be removed from your conversations.
                            </p>
                        </div>
                        <Button variant="destructive" size="sm" onClick={onDisconnect} disabled={isDisconnecting}>
                            {isDisconnecting ? 'Disconnecting...' : 'Disconnect'}
                        </Button>
                    </div>
                </div>
            </div> */}
        </div>
    );
};
