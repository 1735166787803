import { makeAssistantToolUI } from '@assistant-ui/react';
import { PlaybookCard } from '../../../playbooks/components/playbook-cards/playbook-card';
import {
    GetAllPlaybooksToolArgs,
    GetAllPlaybooksToolFactory,
    GetAllPlaybooksToolResult,
    GetPlaybookByNameToolArgs,
    GetPlaybookByNameToolFactory,
    GetPlaybookByNameToolResult,
    GetPlaybookByGroupIdToolArgs,
    GetPlaybookByGroupIdToolFactory,
    GetPlaybookByGroupIdToolResult,
} from '@andsend/services/agent/shared';

const PlaybookCardsUI = ({
    args,
    result,
}: {
    args: GetAllPlaybooksToolArgs | GetPlaybookByNameToolArgs | GetPlaybookByGroupIdToolArgs;
    result: GetAllPlaybooksToolResult | GetPlaybookByNameToolResult | GetPlaybookByGroupIdToolResult;
}) => {
    if (!result) {
        return <div>No playbooks found</div>;
    }

    return (
        <div className="flex flex-row items-center gap-4 mb-2">
            {result.playbooks.map((playbook) => (
                <PlaybookCard key={playbook.group.id} groupId={playbook.group.id} />
            ))}
        </div>
    );
};

const AllPlaybooksToolUI = makeAssistantToolUI<GetAllPlaybooksToolArgs, GetAllPlaybooksToolResult>({
    toolName: GetAllPlaybooksToolFactory.name,
    render: ({ args, result }) => PlaybookCardsUI({ args, result }),
});

const PlaybookByNameToolUI = makeAssistantToolUI<GetPlaybookByNameToolArgs, GetPlaybookByNameToolResult>({
    toolName: GetPlaybookByNameToolFactory.name,
    render: ({ args, result }) => PlaybookCardsUI({ args, result }),
});

const PlaybookByIdToolUI = makeAssistantToolUI<GetPlaybookByGroupIdToolArgs, GetPlaybookByGroupIdToolResult>({
    toolName: GetPlaybookByGroupIdToolFactory.name,
    render: ({ args, result }) => PlaybookCardsUI({ args, result }),
});

export const playbookToolsUI = [AllPlaybooksToolUI, PlaybookByNameToolUI, PlaybookByIdToolUI];
