import { ReactComponent as AndSendLogotypeSmallBlack } from './ANDSEND_logotype_small_black_transparent.svg';
import { ReactComponent as AndSendLogotypeSmallWhite } from './ANDSEND_logotype_small_transparent.svg';
import { ReactComponent as AndSendLogotypeTransparentBlack } from './ANDSEND_logotype_transparent_black.svg';
import { ReactComponent as AndSendLogotypeTransparentWhite } from './ANDSEND_logotype_transparent_white.svg';

export const AndSendLogotype = (
    props: {
        variation: 'small_black' | 'small_white' | 'transparent_black' | 'transparent_white';
    } & React.SVGProps<SVGSVGElement>
) => {
    switch (props.variation) {
        case 'small_black':
            return <AndSendLogotypeSmallBlack {...props} />;
        case 'small_white':
            return <AndSendLogotypeSmallWhite {...props} />;
        case 'transparent_black':
            return <AndSendLogotypeTransparentBlack {...props} />;
        case 'transparent_white':
            return <AndSendLogotypeTransparentWhite {...props} />;
        default:
            return <AndSendLogotypeSmallBlack {...props} />;
    }
};
