import { FC } from 'react';
import ContactSuggestionFilters from './components/contact-suggestion-filters';
import { Outlet, useSearchParams } from 'react-router-dom';
import { SuggestionsFeedProvider } from './hooks/use-suggestions-feed';
import { DiscoverPageHeader } from '../components/discover-page-header';

export const SuggestionsPage: FC = () => {
    const [searchParams] = useSearchParams();
    const groupId = searchParams.get('groupId');
    return (
        <div className="flex flex-col h-full">
            <DiscoverPageHeader />
            <SuggestionsFeedProvider groupId={groupId}>
                <ContactSuggestionFilters />
                <div className="flex-1 min-h-0 overflow-hidden">
                    <div className="h-full w-full p-4 md:pr-10">
                        <Outlet />
                    </div>
                </div>
            </SuggestionsFeedProvider>
        </div>
    );
};

export default SuggestionsPage;
