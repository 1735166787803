import { getZaplifySdk } from '@zaplify/sdk';
import { getAuthenticationToken } from '@zaplify/utils';
import { Button } from '@shadcn/ui/components/ui/button';
import { Input } from '@shadcn/ui/components/ui/input';
import {
    DropdownMenu,
    DropdownMenuTrigger,
    DropdownMenuContent,
    DropdownMenuItem,
} from '@shadcn/ui/components/ui/dropdown-menu';
import { useAuth } from './authentication-provider';

export const UserImpersonationProvider = ({ children }: { children: React.ReactNode }) => {
    const {
        authState: { isImpersonating, isGlobalAdmin },
    } = useAuth();
    const zaplifySdk = getZaplifySdk();

    const handleImpersonation = async (value: string) => {
        if (
            !value ||
            (!value.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/) && !value.match(/^[a-f\d]{24}$/))
        ) {
            return;
        }

        window?.analytics?.reset();
        // The next line is what updates our claims
        await zaplifySdk.profiles.authentication.chooseOrganization('', value);
        await getAuthenticationToken(true);
        window.location.reload();
    };

    const exitImpersonation = async () => {
        window?.analytics?.reset();
        await zaplifySdk.profiles.authentication.chooseOrganization('', 'none');
        await getAuthenticationToken(true);
        window.location.reload();
    };

    if (!isGlobalAdmin) return <>{children}</>;

    return (
        <>
            <DropdownMenu>
                <DropdownMenuTrigger asChild>
                    <Button variant="outline" size="icon" className="fixed bottom-0 left-0 z-[99999]">
                        ▼
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-56">
                    {isImpersonating ? (
                        <DropdownMenuItem onClick={exitImpersonation}>Exit impersonation</DropdownMenuItem>
                    ) : (
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                const input = e.currentTarget.querySelector('input');
                                if (input) handleImpersonation(input.value);
                            }}
                            className="p-2"
                        >
                            <Input
                                placeholder="Enter email or ID"
                                className="h-8"
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        handleImpersonation(e.currentTarget.value);
                                    }
                                }}
                            />
                        </form>
                    )}
                </DropdownMenuContent>
            </DropdownMenu>
            {children}
        </>
    );
};
