import { Badge } from '@shadcn/ui/components/ui/badge';
import * as React from 'react';

export const HotConversationBadge: React.FC<{}> = () => {
    return (
        <Badge variant="warning">
            <div className="flex flex-row gap-2 items-center">
                <div className="w-1.5 h-1.5 rounded-full bg-warning-700" />
                Hot conversation
            </div>
        </Badge>
    );
};
