import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { GET_PLAYBOOK_BY_GROUP_ID } from '@zaplify/graphql';
import { AndSendIcon } from '@shadcn/ui/components/icons/and-send/andsend-icon';
import { useSuggestionsFeed } from '../../../hooks/use-suggestions-feed';

export const ContactCardRecommendationReason = () => {
    const { currentContactSuggestion: contactSuggestion } = useSuggestionsFeed();
    const { data, loading } = useQuery(GET_PLAYBOOK_BY_GROUP_ID, {
        variables: { groupId: contactSuggestion?.groupId },
        skip: !contactSuggestion?.groupId,
    });
    const linkedinUrl = contactSuggestion?.contactLinkedinUrl;
    const suggestedFromNetwork = !!linkedinUrl;

    const groupName = useMemo(() => data?.Groups[0]?.name, [data]);
    const text = useMemo(() => {
        if (groupName) {
            return `Suggested for ${groupName}`;
        } else if (suggestedFromNetwork) {
            return `Found in your network`;
        } else {
            return `Similar to your contacts`;
        }
    }, [groupName, linkedinUrl]);

    if (loading) return <div>Loading...</div>;

    return (
        <div className="flex w-fit items-center justify-start gap-1 bg-background-brand-primary-subtle border border-background-brand-primary rounded-full px-2 py-0.5 text-sm font-medium text-text-brand-primary-subtle">
            <AndSendIcon variation="color-striped" height={14} width={14} />
            <span className="line-clamp-1">{text}</span>
        </div>
    );
};
