import { UseQueryOptions, UseSuspenseQueryOptions, UseMutationOptions } from '@tanstack/react-query';
import { getZaplifySdk } from '@zaplify/sdk';
import { SyncProspectRequestDto } from '@zaplify/services/messaging/shared';
import { createMutationOptions } from './sdk-utils';

const fetchLinkedinCursorToSyncFrom = () => {
    return getZaplifySdk().profiles.trackers.getLinkedinCursorToSyncFrom();
};

type LinkedinCursorToSyncFrom = Awaited<ReturnType<typeof fetchLinkedinCursorToSyncFrom>>;

export const getLinkedinCursorToSyncFrom = (
    options?: Omit<UseQueryOptions<LinkedinCursorToSyncFrom, Error>, 'queryKey' | 'queryFn'> &
        Omit<UseSuspenseQueryOptions<LinkedinCursorToSyncFrom, Error>, 'queryKey' | 'queryFn'>
): UseQueryOptions<LinkedinCursorToSyncFrom, Error> & UseSuspenseQueryOptions<LinkedinCursorToSyncFrom, Error> => {
    return {
        queryKey: [],
        queryFn: () => fetchLinkedinCursorToSyncFrom(),
        ...options,
    };
};

const getProspectLinkedinTrackerFn = (prospectId: string, channelAccountId: string) => {
    if (!prospectId || !channelAccountId) return null;
    return getZaplifySdk().profiles.trackers.getLinkedinTracker(channelAccountId, prospectId);
};

const getProspectLinkedinTrackerCacheKey = (prospectId: string) => ['getProspectLinkedinTracker', prospectId];

type ProspectLinkedinTrackerData = Awaited<ReturnType<typeof getProspectLinkedinTrackerFn>>;

export const getProspectLinkedinTracker = (
    prospectId: string,
    channelAccountId: string,
    options?: Omit<
        UseQueryOptions<ProspectLinkedinTrackerData, Error> &
            UseSuspenseQueryOptions<ProspectLinkedinTrackerData, Error>,
        'queryKey' | 'queryFn'
    >
): UseQueryOptions<ProspectLinkedinTrackerData, Error> &
    UseSuspenseQueryOptions<ProspectLinkedinTrackerData, Error> => {
    return {
        queryKey: getProspectLinkedinTrackerCacheKey(prospectId),
        queryFn: () => getProspectLinkedinTrackerFn(prospectId, channelAccountId),
        enabled: !!prospectId && !!channelAccountId,
        ...options,
    };
};

const syncProspectFn = (data: SyncProspectRequestDto) => {
    return getZaplifySdk().profiles.trackers.syncProspect(data);
};

type SyncProspectData = Awaited<ReturnType<typeof syncProspectFn>>;

export const syncProspect = (
    options?: Omit<UseMutationOptions<SyncProspectData, Error, SyncProspectRequestDto>, 'mutationFn'>
) => {
    return createMutationOptions<void, Error, SyncProspectRequestDto>({
        mutationFn: (variables) => syncProspectFn(variables),
        invalidateQueries: (data, variables) => [getProspectLinkedinTrackerCacheKey(variables.prospectId)],
        ...options,
    });
};
