import { RegisterForm } from './components/register-form';
import { AuthPageLayout } from './components/auth-page-layout';
import { useSearchParams } from 'react-router-dom';
import { cacheSignupParams } from '../../functions/referral-handling';

export const RegisterPage = () => {
    const [searchParams] = useSearchParams();
    const referralLinkFromQuery = searchParams.get('referralLinkId');

    cacheSignupParams({ referralLinkId: referralLinkFromQuery });

    return (
        <AuthPageLayout>
            <RegisterForm />
        </AuthPageLayout>
    );
};
