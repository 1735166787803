import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { styled } from '@mui/styles';
import { ErrorIcon } from './error-icon';
import { SuccessIcon } from './success-icon';
import { WarningIcon } from './warning-icon';
import parse from 'html-react-parser';
import { palette } from '../../theme/palette';

const AVERAGE_READ_SPEED = 20;
const PREVENT_AUTO_HIDE = null;

const alertIcons = {
    success: <SuccessIcon />,
    error: <ErrorIcon />,
    info: <WarningIcon color="#5F8EFA" />,
    warning: <WarningIcon color="#E9A847" />,
};

export const SnackbarContainer = styled('div')(({ theme }) => ({
    '& .snackbar-container': {
        boxShadow: ' 0px 2px 7px rgba(112, 144, 176, 0.24)',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& .alert': {
            color: `${palette.subline}`,
            fontSize: '14px',
            '& .MuiSvgIcon-root': {
                fontSize: '18px',
                justifySelf: 'center',
            },
            '& .MuiAlert-icon': {
                padding: 0,
                alignItems: 'center',
            },
        },
        '& .success': {
            background: palette.colors.greenLight,
        },
        '& .error': {
            background: palette.colors.redLight,
        },
        '& .info': {
            background: palette.colors.blueLight,
        },
        '& .warning': {
            background: palette.colors.orangeWhite,
        },
    },
}));

type Props = {
    onClose?: () => any;
    severity: 'success' | 'error' | 'info' | 'warning';
    text: string;
    horizontal?: any;
    vertical?: any;
    open?: boolean;
};

export const Notification = ({ onClose, severity, text, horizontal, vertical, open = true }: Props) => {
    function setAutoHideDuration(textLength: number) {
        const minDuration = severity === 'error' ? 12000 : 6000;
        const textAverageReadTime = textLength * AVERAGE_READ_SPEED;

        if (textAverageReadTime < minDuration) {
            return minDuration;
        }

        if (textAverageReadTime > minDuration && textAverageReadTime < 5000) {
            return textAverageReadTime;
        }

        if (textAverageReadTime > 5000) {
            return PREVENT_AUTO_HIDE;
        }
    }

    return (
        <SnackbarContainer>
            <Snackbar
                className={`snackbar-container`}
                anchorOrigin={{ vertical: vertical || 'bottom', horizontal: horizontal || 'left' }}
                open={open}
                autoHideDuration={setAutoHideDuration(text.length)}
                onClose={onClose}
                sx={{
                    zIndex: 9999,
                }}
            >
                <MuiAlert
                    onClose={onClose}
                    severity={severity || 'success'}
                    className={`alert ${severity}`}
                    icon={alertIcons[severity]}
                >
                    {parse(text)}
                </MuiAlert>
            </Snackbar>
        </SnackbarContainer>
    );
};
