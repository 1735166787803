import * as React from 'react';
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogFooter,
    DialogClose,
    DialogTrigger,
    DialogDescription,
} from '@shadcn/ui/components/ui/dialog';
import { Button } from '@shadcn/ui/components/ui/button';
import { useMemo } from 'react';
import { Label } from '@shadcn/ui/components/ui/label';
import { Input } from '@shadcn/ui/components/ui/input';
import { Calendar } from '@shadcn/ui/components/ui/calendar';
import { AlarmClock, CalendarDaysIcon, Trash } from 'lucide-react';
import { Popover, PopoverContent, PopoverTrigger } from '@shadcn/ui/components/ui/popover';
import { Tooltip, TooltipContent, TooltipTrigger } from '@shadcn/ui/components/ui/tooltip';
import dayjs from 'dayjs';
import { cn } from '@shadcn/ui/lib/utils';
import { useQuery } from '@tanstack/react-query';
import { useSdk } from '../../sdk';
import { SearchParamDialog, useSearchParamDialog } from '@shadcn/ui/components/search-param-dialog';
import { useQuery as useQueryApollo, useSubscription } from '@apollo/client';
import {
    PROSPECT_ACTIONS_SUBSCRIPTION,
    PROSPECT_FUTURE_ACTIONS_SUBSCRIPTION,
    PROSPECT_UNSCHEDULED_ACTIONS_SUBSCRIPTION,
} from '@zaplify/graphql';
import { useActions } from '../../hooks/use-actions';
import { useDraftMessage } from '../../hooks/messaging/use-draft-messages';

interface SnoozeDialogProps {}
export const snoozeParam = 'snooze-action';

export const useSnoozeDialog = () => {
    const { open, close, value } = useSearchParamDialog(snoozeParam);

    return {
        openSnoozeDialog: ({ prospectId }: { prospectId: string }) => open(prospectId),
        closeSnoozeDialog: close,
        value,
    };
};
export const SnoozeDialog: React.FC<SnoozeDialogProps> = ({}) => {
    const [nbrDays, setNbrDays] = React.useState<number | null>(1);
    const [animateInput, setAnimateInput] = React.useState<boolean>(false);
    const [popoverOpen, setPopoverOpen] = React.useState<boolean>(false);
    const { value: prospectId } = useSnoozeDialog();
    const {
        actionsScheduledOnLtTimestamp,
        handleSnooze: handleSnoozeAction,
        handleStopSuggestingActions,
    } = useActions();
    const {
        prospect: { getProspectById },
    } = useSdk();
    const { data } = useQuery(getProspectById(prospectId));
    const { data: nextActionData, loading: nextActionLoading } = useSubscription(PROSPECT_ACTIONS_SUBSCRIPTION, {
        variables: { prospectId, scheduledOnBefore: actionsScheduledOnLtTimestamp },
        skip: !prospectId,
    });

    const { data: futureActionsData, loading: futureActionsLoading } = useSubscription(
        PROSPECT_FUTURE_ACTIONS_SUBSCRIPTION,
        {
            variables: { prospectId, scheduledOnBefore: actionsScheduledOnLtTimestamp },
            skip: !prospectId,
        }
    );

    const { data: unscheduledActionsData, loading: unscheduledActionsLoading } = useSubscription(
        PROSPECT_UNSCHEDULED_ACTIONS_SUBSCRIPTION,
        { variables: { prospectId }, skip: !prospectId }
    );

    const { removeDraftMessage } = useDraftMessage(prospectId);

    const handleSnooze = async () => {
        await handleSnoozeAction(prospectId, nbrDays || 1);
        removeDraftMessage({ all: true });
    };

    const suggestionId = useMemo(
        () =>
            nextActionData?.OutreachSuggestions?.[0]?.id ||
            futureActionsData?.OutreachSuggestions?.[0]?.id ||
            unscheduledActionsData?.OutreachSuggestions?.[0]?.id,
        [nextActionData, futureActionsData, unscheduledActionsData]
    );

    const prospect = useMemo(() => data?.data, [data]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value ? parseInt(event.target.value, 10) : null;
        if (value === null || (!isNaN(value) && value > 0)) {
            setNbrDays(value);
        }
    };

    const handleCalendarSelect = (date: Date) => {
        const today = dayjs();
        const selectedDate = dayjs(date);
        const duration = selectedDate.diff(today, 'day');
        setNbrDays(duration + 1);
        setAnimateInput(true);
        setTimeout(() => setAnimateInput(false), 150); // Reset animation state after 1 second
        setPopoverOpen(false); // Close the popover
    };

    const snoozeButtonRef = React.useRef<HTMLButtonElement | null>(null);
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && nbrDays !== null) {
            snoozeButtonRef.current?.click();
        }
    };

    return (
        <SearchParamDialog param={snoozeParam}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Snooze Action</DialogTitle>
                </DialogHeader>
                <DialogDescription>
                    In how many days would you like to reach out to {prospect?.firstName}?
                </DialogDescription>
                <div className="flex flex-col gap-4">
                    <div className="flex gap-4 justify-center items-center">
                        <Label htmlFor="snooze-input">In (days)</Label>
                        <Input
                            id="snooze-input"
                            type="number"
                            className={cn(
                                `w-[30%] transition-colors duration-150 ${
                                    animateInput ? 'bg-background-brand-primary-subtle' : 'bg-transparent'
                                }`
                            )}
                            value={nbrDays !== null ? nbrDays : ''}
                            onChange={handleInputChange}
                            onKeyDown={handleKeyDown}
                        />
                        <Popover modal open={popoverOpen} onOpenChange={setPopoverOpen}>
                            <PopoverTrigger asChild>
                                <Button size="icon" variant="outline">
                                    <CalendarDaysIcon className="w-6 h-6 stroke-[1]" />
                                </Button>
                            </PopoverTrigger>
                            <PopoverContent>
                                <Calendar
                                    mode="single"
                                    disabled={[
                                        {
                                            before: dayjs().add(1, 'day').toDate(),
                                            after: dayjs().add(180, 'days').toDate(),
                                        },
                                    ]}
                                    selected={dayjs()
                                        .add(nbrDays || 0, 'day')
                                        .toDate()}
                                    weekStartsOn={1}
                                    onSelect={handleCalendarSelect}
                                    defaultMonth={dayjs()
                                        .add(nbrDays || 1, 'day')
                                        .toDate()}
                                />
                            </PopoverContent>
                        </Popover>
                    </div>
                    <div className="flex justify-center">
                        <span className="text-xs text-text-tertiary">
                            Don't worry, if you receive a reply a new action will be created for you.
                        </span>
                    </div>
                </div>
                <DialogFooter className="flex sm:flex-row sm:justify-center">
                    <Tooltip delayDuration={600}>
                        <TooltipTrigger asChild>
                            <DialogClose asChild>
                                <Button variant="outline" onClick={() => handleStopSuggestingActions(prospectId)}>
                                    <Trash className="w-5 h-5" />
                                    Stop suggesting actions
                                </Button>
                            </DialogClose>
                        </TooltipTrigger>
                        <TooltipContent className="max-w-72 space-y-1">
                            <p>{`Delete action and pause follow-ups until ${
                                prospect?.firstName || 'this contact'
                            } replies.`}</p>
                            <p>{` If ${
                                prospect?.firstName || 'this contact'
                            } replies we will notify you, and follow-up suggestions will resume.`}</p>
                            <p>{` You can still find ${
                                prospect?.firstName || 'this contact'
                            } under My Contacts and send messages from the Conversations page.`}</p>
                        </TooltipContent>
                    </Tooltip>
                    <DialogClose asChild>
                        <Button ref={snoozeButtonRef} onClick={handleSnooze} disabled={nbrDays === null}>
                            <AlarmClock className="w-5 h-5" />
                            Snooze
                        </Button>
                    </DialogClose>
                </DialogFooter>
            </DialogContent>
        </SearchParamDialog>
    );
};
