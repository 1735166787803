// PlanCardPremium component
import { Box, Skeleton, Typography } from '@mui/material';
import { IPlan, IPlanPackage, PlanCode } from '@zaplify/subscriptions';
import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { PlanSelectionState, selectedPlanAtom } from '../../atoms/selfService';
import { getPriceReadableFormat } from '../../helpers/currency';
import { defineSelectionStateOperation } from '../../helpers/subscription';
import { useAppSelector } from '../../redux/store/configureStore';
import { palette } from '../../theme/palette';
import AllPlanInformations from './AllPlanInformations';
import PackageSelect from './PackageSelect';
import PlanCardWrapper from './PlanCardWrapper';
import PricePerUserPerMonth from './PlanEstimates';
import TotalPricePerPerMonth from './TotalPricePerPerMonth';
import UsersExceedsSelectedPlan from './UsersExceedsSelectedPlan';
import { SelectPackageDropdown } from './select-package-dropdown';
import { useSeatsAndCredits } from '../../new/hooks/use-seat-and-credits';

type Props = {
    children: React.ReactChild | React.ReactChild[];
    loadingState: 'loading' | 'idle';
    onPackageChange: (selectedPackage: IPlanPackage, planCode: PlanCode) => void;
    onPlanSelect: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    plan: IPlan;
    selectedPackage: IPlanPackage & { seatsLeftAfterPackageUpdate: number };
    dataCy?: string;
    premium?: boolean;
};

const PlanCard = ({
    children,
    loadingState,
    onPackageChange,
    onPlanSelect,
    plan,
    selectedPackage,
    dataCy,
    premium,
}: Props) => {
    const [selectedPlan] = useAtom(selectedPlanAtom);
    const [selectionState, setSelectionState] = useState<PlanSelectionState>('disabled');

    const { seatSummary } = useSeatsAndCredits();

    const userSubscription = useAppSelector((state) => state.user.subscription);
    const currentUsersCount = userSubscription?.subscription?.totalUsers;
    const billingCurrency = userSubscription?.billingCurrency;
    const subscriptionStatus = userSubscription.subscription?.status;

    const billingPeriodUnit = plan.billingPeriod.unit;
    const billingPeriodAmount = plan.billingPeriod.amount;

    const pricePerSeat = plan.pricePerSeat;
    const monthlyPricePerSeat = billingPeriodUnit === 'year' ? pricePerSeat / 12 : pricePerSeat;
    const pricePerSeatPerMonth = getPriceReadableFormat({
        price: monthlyPricePerSeat,
        billingCurrency: billingCurrency,
    });

    const totalPricePerMonth = getPriceReadableFormat({
        price: monthlyPricePerSeat * selectedPackage?.seats,
        billingCurrency: billingCurrency,
    });

    useEffect(() => {
        if (!selectedPackage || !selectedPlan) return;

        const selectionState = defineSelectionStateOperation({
            currentPackage: {
                credits: userSubscription.subscription?.scheduled?.credits,
                seats: userSubscription.subscription?.scheduled?.seatCount,
            },
            selectedPackage,
            subscriptionSeatSummary: seatSummary,
            currentPlanBillingPeriod: billingPeriodUnit,
            selectedPlan,
            subscriptionStatus,
            currentPlanBillingAmount: billingPeriodAmount,
            currentPlanBillingCode: userSubscription.subscription?.current?.planCode,
            selectedPlanBillingCode: selectedPlan.code,
        });

        setSelectionState(selectionState);
    }, [selectedPackage, selectedPlan, billingPeriodUnit, billingPeriodAmount]);

    return (
        <PlanCardWrapper premium={premium}>
            {loadingState === 'loading' && <PlanCardPremiumLoader />}
            {loadingState === 'idle' && (
                <Box
                    sx={{
                        width: '100%',
                        marginTop: '6px',
                        borderRadius: '0 0 8px 8px',
                        padding: '20px 24px',
                        background: palette.colors.white,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        height: '100%',
                    }}
                >
                    {children}
                    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                        <Box marginTop={'15px'}>
                            <SelectPackageDropdown
                                onPackageChange={(selectedPackage) => onPackageChange(selectedPackage, plan.code)}
                                packages={plan?.packages}
                                selectedPackage={selectedPackage!}
                                dataCy={dataCy + '-plan-card-dropdown'}
                            />
                        </Box>
                        <Box marginTop={'21px'}>
                            <PricePerUserPerMonth
                                estimatedPricePerMonth={pricePerSeatPerMonth}
                                dataCy={'per-month-price-for-' + dataCy + '-plan'}
                            />
                        </Box>
                        <Box>
                            {selectedPackage && plan && (
                                <TotalPricePerPerMonth
                                    dataCy={dataCy}
                                    price={totalPricePerMonth}
                                    billingPeriod={plan?.billingPeriod.unit}
                                />
                            )}
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            flex: 1,
                            flexGrow: 5,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            height: '100%',
                        }}
                    >
                        <AllPlanInformations planCode={plan.code} />
                        <Box marginTop={'24px'}>
                            {selectedPackage?.seatsLeftAfterPackageUpdate < 0 && (
                                <Box marginBottom={'6px'}>
                                    <UsersExceedsSelectedPlan
                                        exceed={selectedPackage?.seatsLeftAfterPackageUpdate}
                                        currentUsersCount={currentUsersCount}
                                        currentPlanCode={userSubscription.subscription?.current?.planCode}
                                    />
                                </Box>
                            )}
                            <PackageSelect
                                state={selectionState}
                                onPlanSelect={onPlanSelect}
                                dataCy={'select-' + dataCy + '-plan-button'}
                            />
                        </Box>
                    </Box>
                </Box>
            )}
        </PlanCardWrapper>
    );
};

function PlanCardPremiumHeader({ children }) {
    return (
        <Typography variant="h4">
            <span style={{ textTransform: 'capitalize' }}>{children}</span>
        </Typography>
    );
}

function PlanCardPremiumTitle({ children }) {
    return (
        <Box marginTop={0.8}>
            <span style={{ color: palette.colors.greyDark }}>{children}</span>
        </Box>
    );
}

export default PlanCard;
export { PlanCardPremiumHeader, PlanCardPremiumTitle };

const PlanCardPremiumLoader = () => {
    return (
        <div
            style={{
                marginTop: '6px',
                borderRadius: '0 0 8px 8px',
                padding: '28px 26px',
                background: palette.colors.white,
                boxSizing: 'border-box',
                width: '100%',
                height: '100%',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    height: '100%',
                }}
            >
                <Box>
                    <Skeleton
                        animation="wave"
                        variant="rectangular"
                        width={100}
                        height={24}
                        style={{ marginBottom: 12, borderRadius: 4 }}
                    />
                    <Skeleton
                        animation="wave"
                        variant="rectangular"
                        width="90%"
                        height={21}
                        style={{ borderRadius: 4 }}
                    />
                    <Skeleton
                        animation="wave"
                        variant="rectangular"
                        width={155}
                        height={34}
                        style={{ marginTop: 21, borderRadius: 4 }}
                    />
                    <Skeleton
                        animation="wave"
                        variant="rectangular"
                        width="100%"
                        height={48}
                        style={{ marginTop: 10, borderRadius: 8 }}
                    />
                    <Skeleton
                        animation="wave"
                        variant="rectangular"
                        width="100%"
                        height={48}
                        style={{ marginTop: 14, borderRadius: 8 }}
                    />
                    <Skeleton
                        animation="wave"
                        variant={'text'}
                        width="100%"
                        height={18}
                        style={{ marginTop: 12, borderRadius: 4 }}
                    />
                </Box>
                <Box>
                    <Skeleton
                        animation="wave"
                        variant="rectangular"
                        width="100%"
                        height={54}
                        style={{ marginTop: 14, borderRadius: 30 }}
                    />
                    <Skeleton
                        animation="wave"
                        variant={'text'}
                        width={160}
                        height={20}
                        style={{ marginTop: 21.5, borderRadius: 4 }}
                    />
                </Box>
            </Box>
        </div>
    );
};
