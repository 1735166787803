import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { styled } from '@mui/styles';
import PropTypes from 'prop-types';
import { useState } from 'react';

const TextFieldComponent = styled(({ className, ...props }) => (
    <FormControl variant='outlined' fullWidth error={Boolean(props.isIncorrect)} className={className}>
        {props.label ? <InputLabel>{props.label}</InputLabel> : null}
        <OutlinedInput
            name={props.name}
            type={props.isValueVisible ? 'text' : 'password'}
            placeholder={props.placeholder}
            value={props.value}
            onChange={props.onChange}
            onBlur={props.onBlur ? props.onBlur : () => {}}
            endAdornment={
                props.onClick ? (
                    <InputAdornment position='end'>
                        <IconButton
                            aria-label='toggle password visibility'
                            onClick={props.onClick}
                            edge='end'
                            size='large'
                        >
                            {props.isValueVisible ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                ) : null
            }
        />
        {props.errorMessage !== undefined ? (
            <FormHelperText>{props.errorMessage === '' ? ' ' : props.errorMessage}</FormHelperText>
        ) : null}
    </FormControl>
))(({ theme }: any) => ({
    '& > label.MuiInputLabel-shrink': {
        backgroundColor: theme.palette.colors.white,
        padding: '0 8px',
    },
    '& input:-webkit-autofill': {
        MozBoxShadow: `0 0 0 100px ${theme.palette.colors.white} inset`,
        WebkitBoxShadow: `0 0 0 100px ${theme.palette.colors.white} inset`,
        boxShadow: `0 0 0 100px ${theme.palette.colors.white} inset`,
    },
}));

export const TextField = ({
    isPasswordInput,
    name,
    label,
    placeholder,
    value,
    onChange,
    onBlur,
    isIncorrect,
    errorMessage,
}) => {
    const [visibility, setVisibility] = useState(false);
    return (
        <TextFieldComponent
            name={name}
            label={label}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            isIncorrect={isIncorrect}
            errorMessage={errorMessage}
            isValueVisible={isPasswordInput ? visibility : true}
            onClick={isPasswordInput ? () => setVisibility(!visibility) : null}
        />
    );
};

TextField.propTypes = {
    isPasswordInput: PropTypes.bool,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    isIncorrect: PropTypes.bool,
    errorMessage: PropTypes.string,
};
