import { Box, ButtonBase, TextField, Typography } from '@mui/material';
import { paletteAndsend } from '@zaplify/ui';
import { Globe, ArrowRight } from 'lucide-react';
import { ActionButton } from '../../onboarding/connect-linkedin-step-action';
import { SectionTitle } from './section-title';
import { isUrlValid } from '../../auth/validate';
import { useState } from 'react';

export const SelectWebsiteStep = ({ domain, setDomain, onContinue }) => {
    const [error, setError] = useState('');

    const handleContinue = (userDomain: string) => {
        if (!domain || isUrlValid(userDomain)) {
            onContinue();
        } else {
            setError('Please enter a valid URL or completely remove it.');
        }
    };
    const onChange = (value: string) => {
        setDomain(value);
        setError('');
    };

    return (
        <Box
            sx={{
                maxWidth: '800px',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                flexDirection: 'column',
                paddingY: 'var(--M-m-regular, 24px)',
                gap: 'var(--M-m-compact, 32px)',
            }}
        >
            <SectionTitle
                title={'Enter your website'}
                subtitle="Andsend will analyze your website to understand your offering and business context"
            />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: 'var(--M-m-compact, 16px)',
                    alignSelf: 'stretch',
                    borderRadius: 'var(--radius-none, 0px)',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        width: '400px',
                    }}
                >
                    <Typography
                        sx={{
                            color: '#344054',
                            marginBottom: '8px',
                            fontSize: '14px',
                            fontWeight: 500,
                            lineHeight: '20px',
                            textAlign: 'left',
                        }}
                    >
                        Website URL
                    </Typography>
                    <TextField
                        error={!!error}
                        helperText={error}
                        value={domain}
                        onChange={(e) => onChange(e.target.value)}
                        placeholder="mywebsite.com"
                        InputProps={{
                            startAdornment: <Globe style={{ marginRight: '14px' }} />,
                        }}
                        sx={{
                            width: '100%',
                            maxWidth: '400px',
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '8px',
                                backgroundColor: '#fff',
                            },
                        }}
                    />
                </Box>
            </Box>
            {domain ? (
                <ActionButton onClick={() => handleContinue(domain)}>
                    Continue <ArrowRight size={15} style={{ marginLeft: '8px', color: 'inherit' }} />
                </ActionButton>
            ) : (
                <Box
                    sx={{
                        alignItems: 'flex-start',
                        width: '400px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                    }}
                >
                    <ButtonBase
                        sx={{
                            minWidth: '100px',
                            boxSizing: 'border-box',
                            borderRadius: '6px',
                            background: paletteAndsend.primary.main,
                            padding: '11px 16px',
                            color: '#FFFFFF',
                            fontSize: '15px',
                            fontWeight: 500,
                            ':hover': {
                                background: paletteAndsend.primary.light,
                            },
                        }}
                        onClick={() => handleContinue(domain)}
                    >
                        Continue without website
                        <ArrowRight size={15} style={{ marginLeft: '8px', color: 'inherit' }} />
                    </ButtonBase>
                    <Typography
                        sx={{
                            fontSize: '14px',
                            color: '#475467',
                        }}
                    >
                        Adding a website allows us to give you better message suggestions and contact recommendations.
                    </Typography>
                </Box>
            )}
        </Box>
    );
};
