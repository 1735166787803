import { Box, Typography } from '@mui/material';
import { ImportProspectsStepViewContainer } from '../containers';
import { ReviewContainer } from './containers';
import { ImportedProspectsOverview } from './elements';
import { WarningBox } from '@zaplify/ui';
import { useAppSelector } from '../../../../redux/store/configureStore';
import { useEffect, useState } from 'react';
import { getZaplifySdk } from '@zaplify/sdk';
import { GetCsvImportCreditsResult } from '@zaplify/users/shared';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { PlanCode } from '@zaplify/subscriptions';
import { ImportProspectSkippedIssueMapping } from '../../../../enums/prospects';

export const ReviewImportedProspects = (props) => {
    const reviewDetails = useAppSelector((state) => state.importProspects?.reviewDetails || {});
    const userId = useAppSelector((state) => state.user.zaplifyUser.id);
    const userIsOnFreePlan = useAppSelector(
        (state) => state.user?.zaplifyUser?.currentSeat?.subscription?.planCode === PlanCode.FREE
    );
    const [remainingUploadCredits, setRemainingUploadCredits] = useState<GetCsvImportCreditsResult>(null);
    const { csvImportFreeUserQuota } = useFlags();

    const getRemainingUploadCredits = async () => {
        const profilesSdk = getZaplifySdk().profiles;
        const remainingCredits = await profilesSdk.user.getRemainingUploadProspectsCredits(userId);
        setRemainingUploadCredits(remainingCredits);
    };
    useEffect(() => {
        getRemainingUploadCredits();
    }, []);

    const skippedProspectsWithIssues = reviewDetails.skippedProspects?.map((skippedProspect) => {
        const { issues, prospectData } = skippedProspect;
        const mappedIssues = issues ? issues.map((issue) => ImportProspectSkippedIssueMapping[issue]).join(', ') : '';
        return { issues: mappedIssues, ...prospectData };
    });

    return (
        <ImportProspectsStepViewContainer>
            <ReviewContainer>
                <Typography variant="h4" className="review-imported-heading">
                    Review
                </Typography>
                <Typography className="review-imported-subline">
                    Success! We found{' '}
                    {Array.isArray(reviewDetails.validProspects) && reviewDetails.validProspects.length} new contacts to
                    add from your CSV.
                </Typography>
                <ImportedProspectsOverview
                    validProspects={reviewDetails.validProspects}
                    errorProspects={reviewDetails.errorProspects}
                    skippedProspects={skippedProspectsWithIssues}
                />
                {userIsOnFreePlan && reviewDetails.validProspects && (
                    <Box sx={{ marginTop: '10px', width: '100%' }}>
                        <WarningBox
                            text={`Only the first ${
                                remainingUploadCredits?.remaining ?? 0
                            } contacts will be uploaded. Upgrade plan to upload more than ${
                                csvImportFreeUserQuota ?? 50
                            } contacts / month.`}
                        />
                    </Box>
                )}
            </ReviewContainer>
        </ImportProspectsStepViewContainer>
    );
};

ReviewImportedProspects.propTypes = {};
