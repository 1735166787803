import { Box, Typography } from '@mui/material';
import { AndSendIcon } from '../../components/icons/and-send/andsend-icon';
import { LinkedInIcon } from '../../components/icons/icon-linkedin';
import { ZaplifyIcon } from '../../components/icons/zaplify-logo';
import { isAndSend } from '../../helpers/is-and-send';
import { IconCircle } from '../prompt-view/ai-preferences-onboarding/ai-preferences-onboarding-info-section';
import LiInfoBackgroundInfo from './assets/li-onboarding-background.jpg';
import { Linkedin, LinkedinIcon } from 'lucide-react';
import { LinkedinMessageIcon } from '../../components/icons/linkedin_message';
import { LinkedIn } from '@mui/icons-material';

export const ConnectLinkedinInfoSection = () => {
    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                backgroundImage: `url(${LiInfoBackgroundInfo})`,
                backgroundColor: `black`,
                alignContent: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundSize: 'cover',
                backgroundPosition: '30%',
                paddingX: '24px',
            }}
        >
            <Box
                sx={{
                    padding: '24px',
                    maxWidth: '80%',
                    backgroundColor: 'rgba(255, 255, 255, 1)',
                    borderRadius: '16px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '20px',
                        gap: '16px',
                        mb: 3,
                        transform: 'translateY(-30px)',
                    }}
                >
                    <IconCircle>
                        <LinkedIn style={{ width: '40px', height: '40px', color: '#2066C2' }} />
                    </IconCircle>
                    <IconCircle>
                        {isAndSend() ? (
                            <AndSendIcon
                                variation="black-striped"
                                style={{ width: '40px', height: '40px' }}
                                opacity="80%"
                            />
                        ) : (
                            <ZaplifyIcon style={{ width: '40px', height: '40px' }} />
                        )}
                    </IconCircle>
                </Box>
                <Typography
                    sx={{
                        fontSize: '18px',
                        fontWeight: 600,
                        lineHeight: '28px',
                        textAlign: 'left',
                        mb: 2,
                    }}
                >
                    Connect your LinkedIn account to:
                </Typography>
                <Typography
                    sx={{
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '24px',
                        textAlign: 'left',
                        color: 'text.secondary',
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '12px' }}>
                        <Box>📲 Seamlessly import your LinkedIn conversations</Box>
                        <Box>💬 Get suggested replies and follow-ups that sound just like you</Box>
                        <Box>🤝 Start new conversations with recommended contacts</Box>
                        <Box>🔄 Keeps contact information up to date</Box>
                        <Box>🚀 Andsend will monitor updates in your network</Box>
                    </Box>
                </Typography>
            </Box>
        </Box>
    );
};
