import { IEvent, Topics } from '@zaplify/common';

export interface IProspectRefreshInfo {
    prospectId: string;
    newExperienceFound: boolean;
    newCurrentPositionFound: boolean;
    currentPositionOutdated: boolean;
}

export class ProspectRefreshedEvent extends IEvent {
    public static override readonly TOPIC: Topics = Topics.PROSPECTS;
    constructor(readonly organizationId: string, readonly refreshInfo: IProspectRefreshInfo) {
        super();
    }

    get partitionKey(): string {
        return this.organizationId;
    }
}
