import { IQuery, Topics } from '@zaplify/common';

interface GlobalSearchDto {
    match: {
        query: string;
        userId: string;
        userOrganizationId: string;
    };
    perPage: number;
    page: number;
}

interface GlobalSearchItem {
    data: any[];
    pagination: {
        page: number;
        limit: number;
        numberOfPages: number;
    };
}

export interface GlobalSearchProspectResponse {
    people: GlobalSearchItem;
    accounts: GlobalSearchItem;
    companyNames: GlobalSearchItem;
    contacts: GlobalSearchItem;
}

export enum SearchTypesProspects {
    COMPANIES = 'companies',
    COMPANY_NAMES = 'companyNames',
    PROSPECTS = 'prospects',
    CONTACTS = 'contacts',
    PEOPLE = 'people',
    ACCOUNTS = 'accounts',
}

export class GlobalSearchProspectsQuery extends IQuery<GlobalSearchProspectResponse> {
    public static override readonly TOPIC = Topics.PROSPECTS;
    constructor(
        public readonly userId: string,
        public readonly globalSearchQuery: GlobalSearchDto,
        public readonly searchIn: SearchTypesProspects[],
    ) {
        super();
    }
}
