import { Button } from '@shadcn/ui/components/ui/button';
import { cn } from '@shadcn/ui/lib/utils';
import { useSearchParams } from 'react-router-dom';
import { LinkedinSearchType } from '../hooks/use-linkedin-search';

export const LINKEDIN_SEARCH_TYPE_PARAM = 'searchType';

export const TypeSwitcher = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const type = (searchParams.get(LINKEDIN_SEARCH_TYPE_PARAM) as LinkedinSearchType) || 'inbox';

    const updateType = (newType: LinkedinSearchType) => {
        setSearchParams((prev) => {
            prev.set(LINKEDIN_SEARCH_TYPE_PARAM, newType);
            return prev;
        });
    };

    return (
        <div className="flex items-center justify-between w-full">
            <div className="flex items-center border rounded-md p-1 gap-0.5 w-full md:w-auto">
                <SwitcherButton active={type === 'inbox'} onClick={() => updateType('inbox')}>
                    Add from Inbox
                </SwitcherButton>
                <SwitcherButton active={type === 'connections'} onClick={() => updateType('connections')}>
                    Add from My Network
                </SwitcherButton>
            </div>
        </div>
    );
};

const SwitcherButton = ({
    active,
    onClick,
    children,
}: {
    active: boolean;
    onClick: () => void;
    children: React.ReactNode;
}) => {
    return (
        <Button
            variant={active ? 'outline' : 'text'}
            className={cn(
                'hover:bg-background-secondary-hover w-full md:w-auto',
                active && 'bg-background-primary-alt'
            )}
            onClick={onClick}
        >
            {children}
        </Button>
    );
};
