import { FC, useState, useCallback, useEffect } from 'react';
import { Button } from '@shadcn/ui/components/ui/button';
import { AlertCircle, Trash2, RefreshCw, Settings2, PlusCircle, Loader2, Users, MessageSquare } from 'lucide-react';
import { Separator } from '@shadcn/ui/components/ui/separator';
import { Switch } from '@shadcn/ui/components/ui/switch';
import { Alert } from '@shadcn/ui/components/ui/alert';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
    SelectLabel,
    SelectGroup,
} from '@shadcn/ui/components/ui/select';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
    DialogFooter,
} from '@shadcn/ui/components/ui/dialog';
import { ReactComponent as Arrow } from '../../../../../../components/icons/arrow.svg';
import {
    OrganizationIntegration,
    AvailableFields,
    PipedriveFieldMapping,
    AvailablePipedriveFields,
} from '../../../../../sdk/internal/user-organization.sdk';
import { FieldMapping as BaseFieldMapping } from '../../types';

// Extended FieldMapping type for pipedrive that includes required property
interface FieldMapping extends BaseFieldMapping {
    required?: boolean;
}

// Simple Pipedrive logo component
import { ReactComponent as PipedriveLogo } from '../../../../../../components/icons/pipedrive-logo.svg';

// Utility functions (defined outside any components)
const isOrganizationField = (prospectField: string): boolean => {
    return !!prospectField && prospectField.includes('organization');
};

const convertToPipedriveMapping = (mapping: FieldMapping): PipedriveFieldMapping => {
    const isOrgField = isOrganizationField(mapping.prospectField);
    if (isOrgField) {
        return {
            prospectField: mapping.prospectField,
            organizationField: mapping.crmField,
            required: false,
        };
    } else {
        return {
            prospectField: mapping.prospectField,
            personField: mapping.crmField,
            required: false,
        };
    }
};

const convertFromPipedriveMapping = (mapping: PipedriveFieldMapping): FieldMapping => {
    // Handle both personField and organizationField
    let crmField = '';
    if (mapping.personField) {
        crmField = mapping.personField;
    } else if (mapping.organizationField) {
        crmField = mapping.organizationField;
    }

    return {
        prospectField: mapping.prospectField || '',
        crmField: crmField,
        required: mapping.required || false,
    };
};

// Types
interface FieldMappingDialogProps {
    fieldMapping: PipedriveFieldMapping[];
    onUpdateMapping?: (mapping: PipedriveFieldMapping[]) => void;
    availableFields?: AvailablePipedriveFields;
    loadAvailableFields?: (crmType: string) => void;
    isLoadingFields?: boolean;
}

interface PipedriveSettingsProps {
    isConnected: boolean;
    onDisconnect: () => void;
    isDisconnecting: boolean;
    account?: OrganizationIntegration;
    onUpdateScope?: (scope: string, value: boolean) => void;
    onUpdateFieldMapping?: (fieldMapping: PipedriveFieldMapping[]) => void;
    isUpdatingSettings?: boolean;
    availableFields?: AvailablePipedriveFields;
    loadAvailableFields?: (crmType: string) => void;
    isLoadingFields?: boolean;
}

// Field Mapping Dialog Component
const FieldMappingDialog: FC<FieldMappingDialogProps> = ({
    fieldMapping = [],
    onUpdateMapping,
    availableFields,
    loadAvailableFields,
    isLoadingFields = false,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [localMapping, setLocalMapping] = useState<FieldMapping[]>([]);
    const [errors, setErrors] = useState<Record<number | string, string>>({});

    // Open the dialog and load fields if they're not already loaded
    const openDialog = useCallback(() => {
        if (loadAvailableFields) {
            loadAvailableFields('pipedrive');
        }
        setIsOpen(true);
    }, [loadAvailableFields]);

    // Initialize local mappings when dialog opens or field mapping changes
    useEffect(() => {
        if (isOpen) {
            // Convert API field mappings to local format
            const mappings = fieldMapping.map(convertFromPipedriveMapping);

            // Make sure we have the required fullName field and at least one of each type of field
            let hasPersonMapping = mappings.some((m) => m.prospectField && !isOrganizationField(m.prospectField));
            let hasOrgMapping = mappings.some((m) => m.prospectField && isOrganizationField(m.prospectField));
            let hasFullNameMapping = mappings.some((m) => m.prospectField === 'prospect.fullName' && m.crmField);

            // If no mappings exist or required fields are missing, create default ones
            let updatedMappings = [...mappings];
            let shouldUpdateMappings = false;

            // Ensure we have the required fullName field
            if (!hasFullNameMapping) {
                // Check if fullName exists but has no crmField
                const existingFullNameIndex = updatedMappings.findIndex((m) => m.prospectField === 'prospect.fullName');

                if (existingFullNameIndex >= 0) {
                    // It exists but might not have a value
                } else {
                    // Add the required fullName field
                    updatedMappings.push({
                        prospectField: 'prospect.fullName',
                        crmField: '',
                    });
                    shouldUpdateMappings = true;
                }
            }

            // Add person field if missing
            if (!hasPersonMapping) {
                updatedMappings.push({
                    prospectField: 'prospect.firstName',
                    crmField: '',
                });
                shouldUpdateMappings = true;
            }

            // Add organization field if missing
            if (!hasOrgMapping) {
                updatedMappings.push({
                    prospectField: 'prospect.organizationName',
                    crmField: '',
                });
                shouldUpdateMappings = true;
            }

            // If we added any fields or have no mappings at all, use the updated mappings
            if (shouldUpdateMappings || mappings.length === 0) {
                setLocalMapping(updatedMappings);
            } else {
                setLocalMapping(mappings);
            }

            // Reset errors
            setErrors({});
        }
    }, [fieldMapping, isOpen]);

    // Get separated mappings for person and organization fields
    const personMappings = localMapping.filter(
        (mapping) => mapping.prospectField && !isOrganizationField(mapping.prospectField)
    );

    const organizationMappings = localMapping.filter(
        (mapping) => mapping.prospectField && isOrganizationField(mapping.prospectField)
    );

    // Ensure fields are loaded when dialog is open
    useEffect(() => {
        if (isOpen && loadAvailableFields && (!availableFields || !availableFields.person)) {
            loadAvailableFields('pipedrive');
        }
    }, [isOpen, availableFields, loadAvailableFields]);

    // Check for duplicates and update the errors state
    const validateMappings = useCallback((mappings: FieldMapping[]) => {
        const newErrors: Record<number, string> = {};
        const personFieldsUsed: Record<string, number> = {};
        const orgFieldsUsed: Record<string, number> = {};

        mappings.forEach((mapping, index) => {
            if (!mapping.prospectField) return;

            const isOrgField = isOrganizationField(mapping.prospectField);

            if (mapping.crmField) {
                if (isOrgField) {
                    if (orgFieldsUsed[mapping.crmField] !== undefined) {
                        newErrors[index] = 'This Pipedrive organization field is already mapped';
                        newErrors[orgFieldsUsed[mapping.crmField]] =
                            'This Pipedrive organization field is already mapped';
                    } else {
                        orgFieldsUsed[mapping.crmField] = index;
                    }
                } else {
                    if (personFieldsUsed[mapping.crmField] !== undefined) {
                        newErrors[index] = 'This Pipedrive person field is already mapped';
                        newErrors[personFieldsUsed[mapping.crmField]] = 'This Pipedrive person field is already mapped';
                    } else {
                        personFieldsUsed[mapping.crmField] = index;
                    }
                }
            }
        });

        setErrors(newErrors);
        return newErrors;
    }, []);

    // Handle updating a mapping field
    const handleUpdateMapping = useCallback(
        (index: number, field: 'prospectField' | 'crmField', value: string) => {
            const newMapping = [...localMapping];

            if (field === 'prospectField') {
                // When changing the prospect field, we need to reset the CRM field as the options will change
                let fieldValue = value;
                // Add prospect. prefix if not already present
                if (!fieldValue.startsWith('prospect.')) {
                    fieldValue = `prospect.${fieldValue}`;
                }

                const isFullName = fieldValue === 'prospect.fullName';

                newMapping[index] = {
                    prospectField: fieldValue,
                    crmField: '', // Reset the CRM field
                    required: isFullName,
                };
            } else {
                // Just update the CRM field value
                newMapping[index] = {
                    ...newMapping[index],
                    [field]: value,
                };
            }

            setLocalMapping(newMapping);
            validateMappings(newMapping);
        },
        [localMapping, validateMappings]
    );

    // Add a new mapping
    const handleAddMapping = useCallback(
        (type: 'person' | 'organization') => {
            // Create initial field values based on type
            let initialProspectField = '';

            if (type === 'organization') {
                // Find a valid organization field from available fields
                const orgField = availableFields?.prospect?.find((f) => f.key.includes('organization'));
                if (orgField) {
                    initialProspectField = `prospect.${orgField.key}`;
                } else {
                    // Fallback to a default organization field
                    initialProspectField = 'prospect.organizationName';
                }
            } else {
                // Find a valid person field from available fields
                const personField = availableFields?.prospect?.find((f) => !f.key.includes('organization'));
                if (personField) {
                    initialProspectField = `prospect.${personField.key}`;
                } else {
                    // Fallback to a default person field
                    initialProspectField = 'prospect.firstName';
                }
            }

            setLocalMapping((prev) => [
                ...prev,
                {
                    prospectField: initialProspectField,
                    crmField: '',
                },
            ]);
        },
        [availableFields]
    );

    // Remove a mapping
    const handleRemoveMapping = useCallback(
        (index: number) => {
            setLocalMapping((prev) => {
                const newMapping = prev.filter((_, i) => i !== index);
                validateMappings(newMapping);
                return newMapping;
            });
        },
        [validateMappings]
    );

    // Save mappings
    const handleSave = useCallback(() => {
        const newErrors = validateMappings(localMapping);

        // Verify fullName mapping is present and has a person field
        const hasFullNameMapping = localMapping.some(
            (mapping) =>
                mapping.prospectField === 'prospect.fullName' &&
                mapping.crmField &&
                !isOrganizationField(mapping.prospectField)
        );

        if (!hasFullNameMapping) {
            setErrors((prev) => ({
                ...prev,
                fullNameError: 'A mapping for prospect.fullName to a person field is required',
            }));
            return;
        }

        if (Object.keys(newErrors).length === 0) {
            // Filter out any incomplete mappings
            const validMappings = localMapping.filter((mapping) => mapping.prospectField && mapping.crmField);

            // Convert each mapping to the expected PipedriveFieldMapping format EXACTLY as expected by the API
            const pipedriveFieldMappings = validMappings.map((mapping) => {
                const isOrgField = isOrganizationField(mapping.prospectField);
                const isFullName = mapping.prospectField === 'prospect.fullName';

                if (isOrgField) {
                    // Organization fields must use organizationField property, NOT personField
                    return {
                        prospectField: mapping.prospectField,
                        organizationField: mapping.crmField,
                        required: false,
                    };
                } else {
                    // Person fields must use personField property, NOT organizationField
                    return {
                        prospectField: mapping.prospectField,
                        personField: mapping.crmField,
                        required: isFullName,
                    };
                }
            });

            console.log('Sending to API:', JSON.stringify(pipedriveFieldMappings, null, 2));

            if (onUpdateMapping) {
                onUpdateMapping(pipedriveFieldMappings);
                setIsOpen(false);
            }
        }
    }, [localMapping, onUpdateMapping, validateMappings]);

    // Check if a Pipedrive field is already selected
    const isPipedriveFieldSelected = useCallback(
        (fieldName: string, fieldType: 'person' | 'organization', currentIndex: number) => {
            return localMapping.some(
                (mapping, idx) =>
                    idx !== currentIndex &&
                    mapping.crmField === fieldName &&
                    (fieldType === 'organization'
                        ? isOrganizationField(mapping.prospectField)
                        : !isOrganizationField(mapping.prospectField))
            );
        },
        [localMapping]
    );

    // Render a field mapping row
    const renderMappingRow = useCallback(
        (item: FieldMapping, idx: number) => {
            if (!item || !item.prospectField) return null;
            if (!availableFields) return null;

            const isOrgField = isOrganizationField(item.prospectField);

            if ((!availableFields.person || !availableFields.organization) && !isLoadingFields) {
                return <div className="py-2 text-center text-sm text-muted-foreground">Loading field data...</div>;
            }

            return (
                <div key={idx} className="grid grid-cols-[1fr,40px,1fr,40px] items-center gap-2">
                    <Select
                        value={item.prospectField}
                        onValueChange={(value) =>
                            handleUpdateMapping(idx, 'prospectField', value.replace('prospect.', ''))
                        }
                    >
                        <SelectTrigger className={`w-full`}>
                            <SelectValue placeholder="Select Andsend field" />
                        </SelectTrigger>
                        <SelectContent>
                            {availableFields?.prospect?.map((field) => (
                                <SelectItem
                                    key={field.key}
                                    value={field.key}
                                    // Only show organization fields in org section and vice versa
                                    disabled={isOrgField !== field.key.includes('organization')}
                                >
                                    {field.name} {field.key === 'fullName' && <span className="text-red-500">*</span>}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                    <Arrow className="w-5 h-5 text-muted-foreground" />
                    <Select
                        value={item.crmField}
                        onValueChange={(value) => handleUpdateMapping(idx, 'crmField', value)}
                    >
                        <SelectTrigger className={`w-full ${errors[idx] ? 'border-red-500' : ''}`}>
                            <SelectValue
                                placeholder={`Select Pipedrive ${isOrgField ? 'organization' : 'person'} field`}
                            />
                        </SelectTrigger>
                        <SelectContent>
                            {isOrgField
                                ? // Organization fields
                                  availableFields?.organization?.length > 0 && (
                                      <SelectGroup>
                                          <SelectLabel>Organization Fields</SelectLabel>
                                          {availableFields.organization.map((field) => (
                                              <SelectItem
                                                  key={field.key}
                                                  value={field.key}
                                                  className={
                                                      isPipedriveFieldSelected(field.key, 'organization', idx)
                                                          ? 'text-muted-foreground'
                                                          : ''
                                                  }
                                              >
                                                  {field.name}
                                              </SelectItem>
                                          ))}
                                      </SelectGroup>
                                  )
                                : // Person fields
                                  availableFields?.person?.length > 0 && (
                                      <SelectGroup>
                                          <SelectLabel>Person Fields</SelectLabel>
                                          {availableFields.person.map((field) => (
                                              <SelectItem
                                                  key={field.key}
                                                  value={field.key}
                                                  className={
                                                      isPipedriveFieldSelected(field.key, 'person', idx)
                                                          ? 'text-muted-foreground'
                                                          : ''
                                                  }
                                              >
                                                  {field.name}
                                              </SelectItem>
                                          ))}
                                      </SelectGroup>
                                  )}
                        </SelectContent>
                    </Select>
                    <Button variant="ghost" size="icon" onClick={() => handleRemoveMapping(idx)}>
                        <Trash2 className="h-4 w-4" />
                    </Button>
                    {errors[idx] && <p className="text-red-500 text-xs col-span-4 px-1">{errors[idx]}</p>}
                </div>
            );
        },
        [availableFields, errors, handleRemoveMapping, handleUpdateMapping, isPipedriveFieldSelected, isLoadingFields]
    );

    return (
        <Dialog open={isOpen} onOpenChange={setIsOpen}>
            <DialogTrigger asChild>
                <Button variant="outline" size="sm" onClick={openDialog}>
                    View & Configure
                </Button>
            </DialogTrigger>
            <DialogContent className="max-w-3xl max-h-[80vh] flex flex-col">
                <DialogHeader className="mb-6">
                    <DialogTitle className="text-xl font-semibold">Field Mapping Configuration</DialogTitle>
                    <DialogDescription>Configure how fields are mapped between Andsend and Pipedrive</DialogDescription>
                </DialogHeader>

                <div className="flex-1 overflow-y-auto pb-6">
                    {/* Header row with labels */}
                    <div className="grid grid-cols-[1fr,40px,1fr,40px] items-center mb-6 px-1">
                        <div className="text-center font-medium text-base">Andsend</div>
                        <div></div>
                        <div className="text-center font-medium text-base">Pipedrive</div>
                        <div></div>
                    </div>

                    {isLoadingFields ? (
                        <div className="flex flex-col items-center justify-center py-12">
                            <Loader2 className="h-8 w-8 animate-spin text-primary mb-4" />
                            <p className="text-sm text-muted-foreground">Loading available fields...</p>
                        </div>
                    ) : (
                        <div className="space-y-8">
                            {/* Person fields section */}
                            <div className="space-y-4">
                                <h3 className="text-base font-medium">Person Fields</h3>
                                <div className="space-y-4">
                                    {personMappings.map((mapping) =>
                                        renderMappingRow(
                                            mapping,
                                            localMapping.findIndex((m) => m === mapping)
                                        )
                                    )}
                                </div>
                                <Button
                                    variant="outline"
                                    size="sm"
                                    className="w-full"
                                    onClick={() => handleAddMapping('person')}
                                >
                                    <PlusCircle className="h-4 w-4 mr-2" />
                                    Add Person Field Mapping
                                </Button>
                            </div>

                            {/* Organization fields section */}
                            <div className="space-y-4">
                                <h3 className="text-base font-medium">Organization Fields</h3>
                                <div className="space-y-4">
                                    {organizationMappings.map((mapping) =>
                                        renderMappingRow(
                                            mapping,
                                            localMapping.findIndex((m) => m === mapping)
                                        )
                                    )}
                                </div>
                                <Button
                                    variant="outline"
                                    size="sm"
                                    className="w-full"
                                    onClick={() => handleAddMapping('organization')}
                                >
                                    <PlusCircle className="h-4 w-4 mr-2" />
                                    Add Organization Field Mapping
                                </Button>
                            </div>
                        </div>
                    )}
                </div>

                <div className="mt-4 text-sm text-muted-foreground">
                    <p>
                        <span className="text-red-500">*</span> Required fields
                    </p>
                    <p className="mt-1">
                        The fullName field is required by Pipedrive and must be mapped to a person field.
                    </p>
                </div>

                <DialogFooter className="pt-4 border-t mt-auto">
                    {errors.fullNameError && (
                        <div className="w-full mb-2">
                            <Alert variant="destructive" className="p-2">
                                <AlertCircle className="h-4 w-4 mr-2" />
                                <span className="text-xs">{errors.fullNameError}</span>
                            </Alert>
                        </div>
                    )}
                    <Button variant="outline" onClick={() => setIsOpen(false)}>
                        Cancel
                    </Button>
                    <Button onClick={handleSave} disabled={isLoadingFields || Object.keys(errors).length > 0}>
                        Save Changes
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

// Main Pipedrive Settings Component
export const PipedriveSettings: FC<PipedriveSettingsProps> = ({
    isConnected,
    onDisconnect,
    isDisconnecting,
    account,
    onUpdateScope,
    onUpdateFieldMapping,
    isUpdatingSettings = false,
    availableFields,
    loadAvailableFields,
    isLoadingFields,
}) => {
    // Load available fields when component mounts
    useEffect(() => {
        if (isConnected && loadAvailableFields) {
            loadAvailableFields('pipedrive');
        }
    }, [isConnected, loadAvailableFields]);

    if (!isConnected) {
        return (
            <div className="space-y-6">
                <div className="flex flex-col items-center justify-center py-12 rounded-md border border-dashed">
                    <PipedriveLogo className="h-12 mb-6" />
                    <h3 className="text-lg font-medium mb-2">Connect Pipedrive</h3>
                    <p className="text-sm text-muted-foreground text-center max-w-md mb-6">
                        Connect your Pipedrive account to sync contacts and log activities automatically.
                    </p>
                    <Button asChild>
                        <a href="/new/account-management/apps/connect/pipedrive">Connect Pipedrive</a>
                    </Button>
                </div>
            </div>
        );
    }

    return (
        <div className="space-y-6">
            <div className="flex flex-col items-left">
                <PipedriveLogo className="object-contain" />
                <div className="mt-2">
                    <p className="text-sm text-muted-foreground">Manage your Pipedrive connection settings</p>
                </div>
            </div>

            <Separator />

            <div className="space-y-4">
                <h4 className="text-md font-medium flex items-center gap-2">
                    <RefreshCw className="h-5 w-5 text-muted-foreground mt-0.5" />
                    Sync Settings
                </h4>

                {/* Contacts/Prospects Sync */}
                <div className="rounded-md border p-4">
                    <div className="flex items-center justify-between">
                        <div className="flex items-start gap-4">
                            <Users className="h-5 w-5 text-muted-foreground mt-0.5" />
                            <div className="space-y-1">
                                <p className="text-sm font-medium">Contact Sync</p>
                                <p className="text-sm text-muted-foreground">
                                    Sync contacts between Andsend and Pipedrive
                                </p>
                            </div>
                        </div>
                        <Switch
                            checked={account?.settings?.integrationScope?.prospects ?? false}
                            onCheckedChange={(checked) => onUpdateScope?.('prospects', checked)}
                            disabled={isUpdatingSettings}
                            className="data-[state=checked]:bg-background-brand-primary data-[state=unchecked]:bg-input"
                        />
                    </div>
                </div>

                {/* Actions Logging */}
                <div className="rounded-md border p-4">
                    <div className="flex items-center justify-between">
                        <div className="flex items-start gap-4">
                            <MessageSquare className="h-5 w-5 text-muted-foreground mt-0.5" />
                            <div className="space-y-1">
                                <p className="text-sm font-medium">Action Logging</p>
                                <p className="text-sm text-muted-foreground">
                                    Log Andsend actions and messages in Pipedrive
                                </p>
                            </div>
                        </div>
                        <Switch
                            checked={account?.settings?.integrationScope?.actionMessages ?? false}
                            onCheckedChange={(value) => onUpdateScope?.('actionMessages', value)}
                            disabled={isUpdatingSettings}
                            className="data-[state=checked]:bg-background-brand-primary data-[state=unchecked]:bg-input"
                        />
                    </div>
                </div>
            </div>

            <Separator />

            <div className="space-y-4">
                <div className="flex items-start gap-4 justify-between">
                    <div className="flex items-start gap-4">
                        <Settings2 className="h-5 w-5 text-muted-foreground mt-0.5" />
                        <div className="space-y-1">
                            <p className="text-sm font-medium">Field Mapping</p>
                            <p className="text-sm text-muted-foreground">
                                Configure how fields map between Andsend and Pipedrive
                            </p>
                            <div className="mt-2 text-xs text-muted-foreground">
                                {account?.settings?.fieldMapping && (
                                    <p>• {account.settings.fieldMapping.length || 0} field mappings configured</p>
                                )}
                            </div>
                        </div>
                    </div>

                    <FieldMappingDialog
                        fieldMapping={(account?.settings?.fieldMapping as PipedriveFieldMapping[]) || []}
                        onUpdateMapping={onUpdateFieldMapping}
                        availableFields={availableFields}
                        loadAvailableFields={loadAvailableFields}
                        isLoadingFields={isLoadingFields}
                    />
                </div>
            </div>
            <Separator />

            {/* Danger zone */}
            <div className="space-y-4">
                <h4 className="text-md font-medium text-red-500">Danger Zone</h4>

                <div className="rounded-md border border-red-200 p-4">
                    <div className="flex items-start gap-4">
                        <Trash2 className="h-5 w-5 text-red-500 mt-0.5" />
                        <div className="space-y-1 flex-1">
                            <p className="text-sm font-medium">Disconnect Account</p>
                            <p className="text-sm text-muted-foreground">
                                Remove this Pipedrive account from your connected accounts. This will stop all syncing
                                between Andsend and Pipedrive.
                            </p>
                        </div>
                        <Button variant="destructive" size="sm" onClick={onDisconnect} disabled={isDisconnecting}>
                            {isDisconnecting ? 'Disconnecting...' : 'Disconnect'}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};
