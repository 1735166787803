import { Box, Typography } from '@mui/material';
import { companyNameCorrectlyCased } from '../../enums/company-name';

export const ChromiumInfo = () => {
    return (
        <Box
            sx={{
                maxWidth: '560px',
                padding: '0 24px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                gap: '32px',
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '8px' }}>
                <Typography
                    component="h1"
                    sx={{
                        fontSize: '32px',
                        fontWeight: '600',
                        lineHeight: '40px',
                        textAlign: 'center',
                        textWrap: 'balance',
                    }}
                >
                    Ensure that your browser is either Google Chrome, Microsoft Edge, or another Chromium-based browser.
                </Typography>
                <Typography sx={{ textAlign: 'center', textWrap: 'balance' }}>
                    {`Only above mentioned browsers are supported for connecting your LinkedIn account to ${companyNameCorrectlyCased} with our
                    Chrome Extension.`}
                </Typography>
            </Box>
        </Box>
    );
};
