import { UpdateSettingsDto } from '@zaplify/pipedrive';
import { BaseSdk } from '../../../base.sdk';

export class SalesforceSdk extends BaseSdk {
    async getSettings(organizationId: string): Promise<UpdateSettingsDto> {
        return (await this.get(
            `${this.appsPath}/${this.salesforcePath}/settings?organizationId=${organizationId}`,
        )) as UpdateSettingsDto;
    }

    async updateSettings(updateSettingsDTO: UpdateSettingsDto, errorCallback: (error: any) => void) {
        return this.put<void>(`${this.appsPath}/${this.salesforcePath}/settings`, updateSettingsDTO, errorCallback);
    }

    async connect(organizationId: string, code: string, loginUrl = 'https://login.salesforce.com') {
        return this.post<void>({
            path: `${this.appsPath}/${this.salesforcePath}/connect`,
            payload: { organizationId: organizationId, code: code, loginUrl },
        });
    }

    async disconnect(organizationId: string) {
        return this.post<void>({
            path: `${this.appsPath}/${this.salesforcePath}/disconnect`,
            payload: { organizationId: organizationId },
        });
    }

    async pause(organizationId: string) {
        return this.post<void>({
            path: `${this.appsPath}/${this.salesforcePath}/pause`,
            payload: { organizationId: organizationId },
        });
    }

    async resume(organizationId: string) {
        return this.post<void>({
            path: `${this.appsPath}/${this.salesforcePath}/resume`,
            payload: { organizationId: organizationId },
        });
    }

    async getProspectPipedriveActivitiesById(prospectId: string) {
        return this.post<void>({ path: `${this.appsPath}/${this.salesforcePath}/prospect/${prospectId}/activities` });
    }

    async getAllFields(organizationId: string, integrationStrategy: string) {
        return this.get<any>(
            `${this.appsPath}/${this.salesforcePath}/mapping/fields?organizationId=${organizationId}&integrationStrategy=${integrationStrategy}`,
        );
    }

    async getDefaultFieldMapping(organizationId: string, integrationStrategy: string) {
        return this.get<any>(
            `${this.appsPath}/${this.salesforcePath}/mapping/default?organizationId=${organizationId}&integrationStrategy=${integrationStrategy}`,
        );
    }

    async migrateCampaign(campaignId: string) {
        return this.post<any[]>({ path: `${this.appsPath}/${this.salesforcePath}/objects/fromCampaign/${campaignId}` });
    }
}
