import { ButtonBase, Link } from '@mui/material';

export const LinkButton = ({ children, link }: { children: React.ReactNode; link: string }) => {
    return (
        <Link href={link} target="_blank">
            <ButtonBase
                sx={{
                    boxSizing: 'border-box',
                    borderRadius: '6px',
                    background: '#6A76FB',
                    padding: '11px 16px',
                    color: '#FFFFFF',
                    fontSize: '15px',
                    fontWeight: 500,
                    ':hover': {
                        background: '#838dfc',
                    },
                }}
            >
                {children}
            </ButtonBase>
        </Link>
    );
};
