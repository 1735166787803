import { ICommand, Topics } from '@zaplify/common';
import { ProspectDataDto } from '@zaplify/prospects';
import { LinkedinUserActivitiesDto } from '@zaplify/campaigns';

export class LinkedinConversationAnalysisCommand extends ICommand<
    { shouldAdd: boolean; motivation?: string; prospectData: ProspectDataDto }[]
> {
    public static override readonly TOPIC = Topics.CAMPAIGNS;

    constructor(
        public readonly userId: string,
        public readonly personAndConversation: {
            profile: ProspectDataDto;
            activities: LinkedinUserActivitiesDto;
        }[],
        public readonly assistantId: string,
    ) {
        super();
    }
}
