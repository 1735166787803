export function getEmailDomain(email) {
    return email?.split('@')?.pop();
}

export function stripTopLevelDomain(domain) {
    return domain.substring(0, domain.lastIndexOf('.'));
}

export function isEmailCorrect(email) {
    return /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
    );
}

export const isEmail = (email) => {
    var pattern = /\S+@\S+\.\S+/;
    try {
        return pattern.test(email.trim());
    } catch (err) {}

    return false;
};

/* 
EMAIL STATUSES:
"": No email, no enrichment has/will happen
null:  No email, no enrichment has/will happen
"...": enriching right now
"none" or "None" enrichment ran, but didn't found email
Everthing else should be a valid email */

export function getEmailState(prospect) {
    const { data } = prospect;

    let emailState = '';
    const emailStatuses = {
        enriching: '...',
        emailIssues: ['none', 'None', '', null],
    };

    if (isEmail(data.email)) {
        emailState = 'Email exists';
    } else if (data.email === emailStatuses.enriching) {
        emailState = `Enrichment`;
    } else if (emailStatuses.emailIssues.includes(data.email)) {
        emailState = 'Email missing';
    } else {
        emailState = 'Email missing';
    }
    return emailState;
}

export function isLinkedInCorrect(url) {
    return url.includes('linkedin.com');
}

export async function validateEmailDomain(email) {
    console.log('email', email);
    return true;
    // return import('./blacklistedEmails.js')
    //     .then(({ blacklistedEmails }) => {
    //         return !blacklistedEmails.some((domain) => getEmailDomain(email) === domain);
    //     })
    //     .catch((error) => {
    //         console.log('error during validate email domain');
    //     });
}
