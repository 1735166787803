import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { styled } from '@mui/styles';
import PropTypes from 'prop-types';

const DropDownComponent = styled(({ className, children, ...props }) => (
    <FormControl className={className} size='small' variant='outlined'>
        <InputLabel id='dropDownLabel'>{props.inputLabel}</InputLabel>
        <Select labelId='dropDownLabel' id='dropDownSelector' value={props.currentValue} onChange={props.onChange}>
            {children}
        </Select>
    </FormControl>
))(() => ({
    '& *.MuiSelect-select': {
        minWidth: '30px',
    },
}));

export const DropDown = (props) => {
    const { inputLabel, currentValue, onChange, values } = props;
    return (
        <DropDownComponent inputLabel={inputLabel} onChange={onChange} currentValue={currentValue}>
            {values.map((value, idx) => (
                <MenuItem value={value} key={idx}>
                    {value}
                </MenuItem>
            ))}
        </DropDownComponent>
    );
};

DropDown.propTypes = {
    values: PropTypes.array.isRequired,
    inputLabel: PropTypes.string.isRequired,
    currentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onChange: PropTypes.func.isRequired,
};
