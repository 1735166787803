import { useToast } from '@shadcn/ui/hooks/use-toast';
import { useApolloClient } from '@apollo/client';
import { useSuggestionsFeed } from '../../../hooks/use-suggestions-feed';
import { useSdk } from '../../../../../../sdk';
import { useMutation } from '@tanstack/react-query';
import { Button } from '@shadcn/ui/components/ui/button';
import { RedoIcon } from 'lucide-react';
import { AddContactButton } from '../../../../../../components/buttons/add-contact-button';
import { ContactSource } from '@zaplify/services/user-contacts/shared';
import {
    GET_CONTACT_SUGGESTION_BY_ID,
    GET_CONTACT_SUGGESTIONS,
    GET_CONTACT_SUGGESTIONS_BY_GROUP_ID,
    GET_TOTAL_NUMBER_OF_USER_CONTACTS_IN_ANY_PLAYBOOK,
} from '@zaplify/graphql';
import { cn } from '@shadcn/ui/lib/utils';

export const ContactCardActionRow = ({ className }: { className?: string }) => {
    const { toast } = useToast();
    const client = useApolloClient();
    const { goToNextSuggestion } = useSuggestionsFeed();
    const { currentContactSuggestion: contactSuggestion } = useSuggestionsFeed();

    const {
        contactSuggestions: {
            skipContactSuggestion: skipContactSuggestionFn,
            approveContactSuggestion: approveContactSuggestionFn,
        },
    } = useSdk();

    const { mutate: skipContactSuggestion } = useMutation(
        skipContactSuggestionFn({
            onSuccess: () => {
                client.refetchQueries({
                    include: [
                        GET_CONTACT_SUGGESTION_BY_ID,
                        GET_CONTACT_SUGGESTIONS_BY_GROUP_ID,
                        GET_CONTACT_SUGGESTIONS,
                    ],
                });
            },
            onError: (error) => {
                console.error('Error skipping contact suggestion', error);
            },
        })
    );
    const { mutate: approveContactSuggestion } = useMutation(
        approveContactSuggestionFn({
            onSuccess: () => {
                client.refetchQueries({
                    include: [
                        GET_CONTACT_SUGGESTION_BY_ID,
                        GET_CONTACT_SUGGESTIONS_BY_GROUP_ID,
                        GET_CONTACT_SUGGESTIONS,
                        GET_TOTAL_NUMBER_OF_USER_CONTACTS_IN_ANY_PLAYBOOK,
                    ],
                });
                toast({
                    title: `Added ${contactSuggestion?.contactName} to your contacts`,
                    description: 'An action for this contact is being created',
                });
            },
            onError: (error) => {
                console.error('Error approving contact suggestion', error);
                toast({
                    variant: 'error',
                    title: `Could not add ${contactSuggestion?.contactName} to your contacts`,
                    description: 'Please try again later',
                });
            },
        })
    );

    const handleSkipContactSuggestion = () => {
        skipContactSuggestion({ contactSuggestionId: contactSuggestion?.id });
        goToNextSuggestion();
    };

    const onAdded = () => {
        approveContactSuggestion({ contactSuggestionId: contactSuggestion?.id, groupId: contactSuggestion?.groupId });
    };

    return (
        <div className={cn('flex flex-row items-center justify-center gap-2', className)}>
            <Button variant="outline" className="w-fit h-9" onClick={handleSkipContactSuggestion}>
                <RedoIcon className="w-4 h-4" />
                <span>Skip</span>
            </Button>
            <AddContactButton
                className="h-9"
                personId={contactSuggestion?.personId}
                linkedinUrl={contactSuggestion?.contactLinkedinUrl}
                source={ContactSource.Recommended}
                onAdded={onAdded}
                addToGroupId={contactSuggestion?.groupId}
                onStartedAdding={() => {
                    setTimeout(() => {
                        goToNextSuggestion();
                    }, 700);
                }}
            />
        </div>
    );
};
