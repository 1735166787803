import { IQuery } from '@zaplify/common';
import { Topics } from '@zaplify/common';
import { UserDto } from '../users';

export class GetUserByFirebaseIdQuery extends IQuery<UserDto[]> {
    public static override readonly TOPIC = Topics.USERS;
    constructor(readonly firebaseId: string) {
        super();
    }
}
