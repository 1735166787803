import { styled } from '@mui/styles';

const GlobalSearchAccountRowStyled = styled('div')(({ theme: { palette } }: any) => ({
    marginBottom: '5px',
    borderRadius: '4px',
    padding: '5px 8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '&:hover': {
        backgroundColor: palette.colors.whiteDark,
        cursor: 'pointer',
    },
    '& .main': {
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
        '& .logo-container': {
            height: 38,
            width: 38,
            '& .logo': {
                maxHeight: '100%',
                maxWidth: '100%',
                borderRadius: '8px',
            },
        },
        '& .info': {
            textAlign: 'left',

            '& .info-title': {
                marginBottom: '3px',

                '& .full-name': {
                    fontSize: '16px',
                    fontWeight: '600',
                    color: palette.colors.black,

                    '& span': {
                        color: palette.colors.subline,
                        fontWeight: '400',
                    },
                },
            },
            '& .info-subtitle': {
                fontSize: '11px',
                color: palette.colors.greyDark,
            },
        },
    },
    '& .launch': {
        marginLeft: '15px',
        fontSize: '17px',
        color: palette.colors.lightGrey,
    },
}));

export default GlobalSearchAccountRowStyled;
