import * as React from 'react';
import {
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogFooter,
    DialogClose,
    DialogDescription,
} from '@shadcn/ui/components/ui/dialog';
import { Button } from '@shadcn/ui/components/ui/button';
import { Trash } from 'lucide-react';
import {
    GET_ALL_USER_CONTACTS,
    GET_TOTAL_NUMBER_OF_USER_CONTACTS_IN_ANY_PLAYBOOK,
    GET_USER_CONTACTS_BY_GROUP_IDS,
} from '@zaplify/graphql';
import { useApolloClient } from '@apollo/client';
import { useToast } from '@shadcn/ui/hooks/use-toast';
import { SearchParamDialog, useSearchParamDialog } from '@shadcn/ui/components/search-param-dialog';
import { useSdk } from '../../sdk';
import { useMutation } from '@tanstack/react-query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useLocation, useNavigate } from 'react-router-dom';

export const useDeleteContactDialog = () => {
    const { open, close, value } = useSearchParamDialog('delete-contact');

    return {
        openDeleteContactDialog: (userContactIds: string[]) => open(userContactIds.join(',')),
        closeDeleteContactDialog: close,
        value: value && decodeURIComponent(value)?.split(','),
    };
};

export const DeleteContactSearchParamDialog = () => {
    const { value: userContactIds } = useDeleteContactDialog();

    return (
        <SearchParamDialog param={'delete-contact'}>
            <DeleteContactDialog userContactIds={userContactIds} />
        </SearchParamDialog>
    );
};

export const DeleteContactDialog = ({
    userContactIds,
    onDeleted,
}: {
    userContactIds: string[];
    onDeleted?: () => void;
}) => {
    const apolloClient = useApolloClient();
    const { toast } = useToast();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const {
        userContacts: { deleteUserContacts, assignUserContactsToGroup },
    } = useSdk();
    const { mutateAsync: assignContactsToGroup } = useMutation(assignUserContactsToGroup());
    const { mutateAsync: deleteContacts } = useMutation(deleteUserContacts());
    const { createProspectsFromAllLiConversation } = useFlags();

    const removeContacts = async () => {
        try {
            if (createProspectsFromAllLiConversation) {
                await assignContactsToGroup({
                    contactIds: userContactIds,
                    groupId: 'none',
                });
                if (pathname.includes('action-feed')) {
                    navigate('/new/action-feed');
                }
            } else {
                await deleteContacts({
                    contactIds: userContactIds,
                });
            }
            setTimeout(() => {
                apolloClient.refetchQueries({
                    include: [
                        GET_ALL_USER_CONTACTS,
                        GET_USER_CONTACTS_BY_GROUP_IDS,
                        GET_TOTAL_NUMBER_OF_USER_CONTACTS_IN_ANY_PLAYBOOK,
                    ],
                });
            }, 100);
            toast({
                title: 'Contact removed successfully',
                variant: 'success',
            });

            onDeleted?.();
        } catch (error) {
            toast({
                title: 'An error occurred while removing the contact. Please try again.',
                variant: 'error',
            });
        }
    };

    if (!userContactIds) return null;

    return (
        <DialogContent>
            <DialogHeader>
                <DialogTitle>Remove Contact</DialogTitle>
            </DialogHeader>
            <DialogDescription>
                Are you sure you want to remove{' '}
                {userContactIds?.length === 1 ? 'this contact' : `${userContactIds.length} contacts`}?
            </DialogDescription>
            <p className="text-sm">
                Your contacts will be removed from your contact list and you will no longer be able to send messages to
                them.
            </p>
            <DialogFooter>
                <DialogClose asChild>
                    <Button variant="outline">Cancel</Button>
                </DialogClose>
                <DialogClose asChild>
                    <Button variant="destructive" onClick={removeContacts} autoFocus>
                        <Trash className="w-5 h-5" />
                        Remove
                    </Button>
                </DialogClose>
            </DialogFooter>
        </DialogContent>
    );
};
