import { Topics, IQuery } from '@zaplify/common';

export interface IProspectToSync {
    prospectId: string;
    linkedinProfileUrl?: string;
    email?: string;
    retries?: number;
}

export class GetProspectsToSyncQuery extends IQuery<IProspectToSync[]> {
    public static override readonly TOPIC = Topics.CAMPAIGNS;
    constructor(
        readonly channelAccounts: { linkedinId?: string; emailId?: string },
        readonly userId: string,
        /**
         * Returns all if undefined
         */
        readonly prospectIds?: string[],
    ) {
        super();
    }
}
