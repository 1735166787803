import { styled } from '@mui/styles';

export const SmallModalContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    textAlign: 'center',
    padding: '0',
    '& > div:first-child': {
        padding: '25px',
        '& > h3': {
            fontWeight: '300',
            marginBottom: '1em',
            lineHeight: '1.5',
        },
        '& > p': {
            marginBottom: '16px',
        },
    },
    '& > div:nth-child(2)': {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        '& > button:first-child': {
            color: '#5F8EFA',
            fontWeight: '400',
        },
        '& > button:last-child': {
            background: '#2F3138',
            borderRadius: '300px',
            boxShadow: 'none',
            '& > span': {
                color: 'white',
            },
            '&:hover': {
                background: '#4F5158',
            },
        },
    },
}));
