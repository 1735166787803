import { createContext, ReactNode, useContext, useMemo, useState, useCallback } from 'react';
import { GetProspectMessagesHistoryQuery } from '@zaplify/graphql';

type Message = GetProspectMessagesHistoryQuery['Messages'][number];

interface PendingMessagesContextValue {
    pendingMessages: { [prospectId: string]: Message };
    addPendingMessage: (message: Message, prospectId: string) => void;
    removePendingMessage: (prospectId: string) => void;
    getPendingMessage: (prospectId: string) => Message | undefined;
    watchPendingMessage: (prospectId: string) => Message | undefined;
    setPendingMessageError: (prospectId: string) => void;
}

const PendingMessagesContext = createContext<PendingMessagesContextValue | undefined>(undefined);

export function PendingMessagesProvider({ children }: { children: ReactNode }) {
    const [pendingMessages, setPendingMessages] = useState<{ [prospectId: string]: Message }>({});

    const addPendingMessage = useCallback((message: Message, prospectId: string) => {
        setPendingMessages((prev) => ({
            ...prev,
            [prospectId]: { ...message, deliveryStatus: 'PENDING' },
        }));
    }, []);

    const setPendingMessageError = useCallback((prospectId: string) => {
        setPendingMessages((prev) => ({
            ...prev,
            [prospectId]: { ...prev[prospectId], deliveryStatus: 'ERROR' },
        }));
    }, []);

    const removePendingMessage = useCallback((prospectId: string) => {
        setPendingMessages((prev) => {
            const { [prospectId]: _, ...rest } = prev;
            return rest;
        });
    }, []);

    const getPendingMessage = useCallback(
        (prospectId: string) => {
            return pendingMessages[prospectId];
        },
        [pendingMessages]
    );

    const watchPendingMessage = useCallback(
        (prospectId: string) => {
            return pendingMessages[prospectId];
        },
        [pendingMessages]
    );

    const value = useMemo(
        () => ({
            pendingMessages,
            addPendingMessage,
            removePendingMessage,
            setPendingMessageError,
            getPendingMessage,
            watchPendingMessage,
        }),
        [pendingMessages, addPendingMessage, removePendingMessage, getPendingMessage, watchPendingMessage]
    );

    return <PendingMessagesContext.Provider value={value}>{children}</PendingMessagesContext.Provider>;
}

export const usePendingMessages = () => {
    const context = useContext(PendingMessagesContext);
    if (!context) {
        throw new Error('usePendingMessagesContext must be used within a PendingMessagesProvider');
    }
    return context;
};
