import { ButtonBase, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import Crown from '../../components/icons/Crown';
import { GuardedAnchor } from '@zaplify/ui';

const PaymentSuccessStyled = styled('div')(({ theme: { palette } }: any) => ({
    padding: '0 26px 43px',
    textAlign: 'center',

    '& .close': {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    '& .crown': {
        margin: '0 0 22px',
        fontSize: 68,
    },
    '& * .email': {
        '& > a': {
            textDecoration: 'none',
            color: palette.darkBlue,
        },
    },
    '& .info': {
        margin: '9px 0px 44px',
        fontSize: 15,
    },
    '& .finish': {
        width: '100%',
        boxSizing: 'border-box',
        padding: '14px 0px',
        borderRadius: 40,
        backgroundColor: palette.colors.bluePale,
        fontSize: 15,
        fontWeight: 500,
        color: palette.colors.white,
    },
}));

const PaymentSuccess = ({ onPressedDone }: { onPressedDone: () => void }) => {
    return (
        <PaymentSuccessStyled>
            <Crown className="crown" />
            <Typography variant={'h3'}>Plan upgraded</Typography>
            <Typography className="info">
                Please contact{' '}
                <span className="email">
                    <GuardedAnchor href="mailto:support@andsend.com">support@andsend.com</GuardedAnchor>
                </span>{' '}
                if you have any questions
            </Typography>
            <ButtonBase className="finish" onClick={onPressedDone} data-cy="upgrade-plan-done-button">
                Done
            </ButtonBase>
        </PaymentSuccessStyled>
    );
};

export default PaymentSuccess;
