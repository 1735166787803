import { ICommand, Topics } from '@zaplify/common';
import { GroupDto } from '../dtos';

export class UpdateGroupCommand extends ICommand<GroupDto> {
    public static override readonly TOPIC = Topics.PROSPECTS;

    constructor(
        public readonly userId: string,
        public readonly group: Partial<Omit<GroupDto, 'createdAt' | 'updatedAt' | 'assistantSettingsId' | 'userId'>> & {
            id: string;
        },
    ) {
        super();
    }
}
