import { useQuery } from '@apollo/client';
import { GET_PLAYBOOK_BY_GROUP_ID } from '@zaplify/graphql';
import { FC, useMemo, useEffect, useState } from 'react';
import { useNavigate, useSearchParams, unstable_useBlocker as useBlocker, Location } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { usePlaybookImages } from '../../../hooks/use-playbook-images';
import { z } from 'zod';
import { Form, FormControl, FormField, FormItem, FormLabel } from '@shadcn/ui/components/ui/form';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Input } from '@shadcn/ui/components/ui/input';
import { Checkbox } from '@shadcn/ui/components/ui/checkbox';
import { Textarea } from '@shadcn/ui/components/ui/textarea';
import { Mail, Linkedin, ArrowLeftIcon, Info } from 'lucide-react';
import LanguageSelector from '../../../components/language-selector';
import { ToggleGroup, ToggleGroupItem } from '@shadcn/ui/components/toggle-group';
import { usePlaybookForm } from '../../../hooks/use-playbook-form';
import { paths } from '../../../../../../routes/paths';
import { Button } from '@shadcn/ui/components/ui/button';
import { ChannelType } from '@zaplify/channel-accounts/shared';
import { Tooltip, TooltipContent, TooltipTrigger } from '@shadcn/ui/components/ui/tooltip';
import { TooltipProvider } from '@shadcn/ui/components/ui/tooltip';
import { Skeleton } from '@shadcn/ui/components/ui/skeleton';
import { useSdk } from '../../../../../sdk/use-sdk';
import { useMutation } from '@tanstack/react-query';
import { Popover, PopoverContent, PopoverTrigger } from '@shadcn/ui/components/ui/popover';
import { Template, usePlaybookTemplates } from '../../../hooks/use-playbook-templates';
import { useToast } from '@shadcn/ui/hooks/use-toast';
import { SparklesAI } from '../../../../actions/components/message-composer/sparkles-ai';
import { cn } from '@shadcn/ui/lib/utils';
import { DirtyFieldsNavigationDialog } from '../../../components/dialogs/dirty-fields-navigation-dialog';

export const PlaybookForm: FC = () => {
    const navigate = useNavigate();
    const { groupId } = useParams();
    const [searchParams] = useSearchParams();
    const template: Template | undefined = searchParams.get('template') as Template | undefined;
    const { getTemplate } = usePlaybookTemplates();
    const { toast } = useToast();

    const { unusedImgSrc } = usePlaybookImages();
    const { data: playbookData, loading: queryLoading } = useQuery(GET_PLAYBOOK_BY_GROUP_ID, {
        variables: { groupId },
        skip: !groupId,
    });
    const playbook = useMemo(() => playbookData?.Groups[0], [playbookData]);
    const { formSchema, savePlaybook, formId } = usePlaybookForm(groupId, playbook?.AssistantSetting?.id);

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            name: 'New playbook',
            purpose: '',
            tone: '',
            languages: [],
            defaultChannel: ChannelType.LINKEDIN,
            context: '',
            default: false,
            imgSrc: unusedImgSrc,
        },
    });

    const [showDialog, setShowDialog] = useState(false);
    const [pendingLocation, setPendingLocation] = useState<Location | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const blocker = useBlocker(
        ({ currentLocation, nextLocation }) =>
            form.formState.isDirty && !isSubmitting && currentLocation.pathname !== nextLocation.pathname
    );

    useEffect(() => {
        if (blocker.state === 'blocked') {
            setShowDialog(true);
            setPendingLocation(blocker.location);
        }
    }, [blocker]);

    useEffect(() => {
        if (playbook && !form.formState.isDirty) {
            const defaultChannel =
                playbook.AssistantSetting.defaultChannel === ChannelType.EMAIL
                    ? ChannelType.EMAIL
                    : ChannelType.LINKEDIN;
            form.reset(
                {
                    name: playbook.name ?? 'New playbook',
                    purpose: playbook.AssistantSetting.purpose ?? '',
                    tone: playbook.AssistantSetting.tone ?? '',
                    languages: playbook.AssistantSetting.languages ?? [],
                    defaultChannel,
                    context: playbook.AssistantSetting.context ?? '',
                    default: playbook.default ?? false,
                    imgSrc: playbook.imgSrc ?? unusedImgSrc,
                },
                {
                    keepDirty: false,
                    keepValues: false,
                }
            );
        }
    }, [playbook]);

    useEffect(() => {
        if (template) {
            const templateValues = getTemplate(template);
            form.reset({
                name: templateValues.name,
                purpose: templateValues.purpose,
                tone: templateValues.tone,
                context: templateValues.context,
                imgSrc: templateValues.preview.imageSrc,
                languages: ['English'],
            });
        }
    }, [template]);

    useEffect(() => {
        if (unusedImgSrc && !form.watch('imgSrc')) {
            form.setValue('imgSrc', unusedImgSrc);
        }
    }, [unusedImgSrc]);

    const onSubmit = async (values: z.infer<typeof formSchema>) => {
        setIsSubmitting(true);
        try {
            await savePlaybook(values);
            navigate('/new' + paths.PLAYBOOKS);
        } catch (error) {
            console.error('Failed to save playbook:', error);
            const formErrors = form.formState.errors;
            const errorMessages = Object.entries(formErrors)
                .map(([field, error]) => `${field}: ${error?.message}`)
                .join('\n');

            toast({
                title: 'Form Validation Failed',
                description: errorMessages || 'Please check all required fields are filled correctly',
                variant: 'error',
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleSubmit = async (data: z.infer<typeof formSchema>) => {
        setIsSubmitting(true);
        try {
            await onSubmit(data);
            navigate('/new' + paths.PLAYBOOKS);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleNavigateWithoutSaving = () => {
        if (blocker.state === 'blocked') {
            setShowDialog(false);
            setPendingLocation(null);
            blocker.proceed();
        }
    };

    const handleModalSave = async () => {
        if (blocker.state === 'blocked') {
            try {
                await form.handleSubmit(onSubmit)();
                setShowDialog(false);
                setPendingLocation(null);
                blocker.proceed();
            } catch (error) {
                console.error('Error saving:', error);
            }
        }
    };

    if (queryLoading) {
        return (
            <div className="flex justify-center items-center p-4">
                <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-primary"></div>
            </div>
        );
    }

    return (
        <>
            <PageHeader formId={formId} onSubmit={() => form.handleSubmit(handleSubmit)()} />
            <div className="flex flex-col w-full justify-center items-center pb-8">
                <form
                    onSubmit={form.handleSubmit(onSubmit, (errors) => {
                        const errorMessages = Object.entries(errors)
                            .map(([field, error]) => error?.message)
                            .join(', ');

                        toast({
                            title: 'Please add all required information:',
                            description: errorMessages || 'Please check all required fields are filled correctly',
                            variant: 'destructive',
                            duration: 10000,
                        });
                    })}
                    className="w-full max-w-2xl"
                    id={formId}
                >
                    <Form {...form}>
                        <div className="flex flex-col">
                            <div className="flex flex-col gap-2 max-w-full">
                                <div className="flex gap-4 items-center">
                                    <img
                                        src={form.watch('imgSrc')}
                                        className="object-cover rounded-xl min-w-[70px] min-h-[70px] max-w-[70px] max-h-[70px]"
                                    />
                                    <div className="w-full">
                                        <FormField
                                            control={form.control}
                                            name="name"
                                            render={({ field }) => (
                                                <input
                                                    type="text"
                                                    autoFocus
                                                    className="w-full text-4xl font-semibold bg-transparent border-none outline-none focus:outline-none focus:ring-0"
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className="rounded-xl bg-background-tertiary w-full max-w-full p-3 text-sm my-1">
                                    Setup how you communicate with your contacts. Choose your purpose and provide any
                                    additional information that can help improve your communication.
                                </div>
                            </div>
                            <div className="flex flex-col gap-2 max-w-full pt-4">
                                <FormField
                                    control={form.control}
                                    name="purpose"
                                    render={({ field }) => (
                                        // Id needed for onboarding tour
                                        <FormItem id="purpose-field">
                                            <FormLabel>Purpose</FormLabel>
                                            <FormControl>
                                                <Input
                                                    {...field}
                                                    placeholder="Enter the purpose of this playbook (e.g. 'Introduce our new product to existing customers')"
                                                />
                                            </FormControl>
                                        </FormItem>
                                    )}
                                />

                                <FormField
                                    control={form.control}
                                    name="tone"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Tone</FormLabel>
                                            <FormControl>
                                                <Input
                                                    {...field}
                                                    placeholder="Enter your desired tone and communication style (e.g. 'Casual and short')"
                                                />
                                            </FormControl>
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="languages"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Languages</FormLabel>
                                            <FormControl>
                                                <LanguageSelector value={field.value} onChange={field.onChange} />
                                            </FormControl>
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="defaultChannel"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Default channel</FormLabel>
                                            <FormControl>
                                                <ToggleGroup
                                                    type="single"
                                                    value={field.value}
                                                    onValueChange={field.onChange}
                                                    className="justify-start"
                                                >
                                                    <ToggleGroupItem
                                                        value={ChannelType.LINKEDIN}
                                                        aria-label="Toggle LinkedIn"
                                                    >
                                                        <Linkedin className="h-4 w-4 " />
                                                        LinkedIn
                                                    </ToggleGroupItem>
                                                    <ToggleGroupItem
                                                        value={ChannelType.EMAIL}
                                                        aria-label="Toggle Email"
                                                    >
                                                        <Mail className="h-4 w-4 " />
                                                        Email
                                                    </ToggleGroupItem>
                                                </ToggleGroup>
                                            </FormControl>
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="context"
                                    render={({ field }) => {
                                        const [suggestionsOpen, setSuggestionsOpen] = useState(false);
                                        const [isGenerating, setIsGenerating] = useState(false);
                                        return (
                                            <FormItem id="playbook-context-field">
                                                {/* Id needed for onboarding tour */}
                                                <div className="flex items-center justify-between">
                                                    <div className="flex items-center justify-start gap-2">
                                                        <FormLabel>Custom instructions</FormLabel>
                                                        <CustomInstructionsDialog />
                                                    </div>
                                                    <SuggestionsDialog
                                                        open={suggestionsOpen}
                                                        setModalOpenState={setSuggestionsOpen}
                                                        currentContext={form.watch('context')}
                                                        onSuggestionSelect={(suggestion) => {
                                                            if (!suggestion) {
                                                                setIsGenerating(true);
                                                                field.onChange('');
                                                                return;
                                                            }
                                                            field.onChange(suggestion);
                                                            setIsGenerating(false);
                                                        }}
                                                    />
                                                </div>
                                                <FormControl>
                                                    <div className="relative">
                                                        <Textarea
                                                            {...field}
                                                            // Set the height of the textarea to the height of the content
                                                            ref={(el) => {
                                                                if (el) {
                                                                    el.style.height = '0px';
                                                                    el.style.height = el.scrollHeight + 'px';
                                                                }
                                                            }}
                                                            placeholder="Enter additional instructions, relevant links, or context for message customization (e.g. details about special deals, meeting links, or strategies for engaging customers)"
                                                            disabled={isGenerating}
                                                            className={cn(isGenerating && 'opacity-0')}
                                                        />
                                                        {isGenerating && (
                                                            <div className="absolute inset-0 flex flex-col gap-3 bg-background p-4 rounded-lg border border-border">
                                                                <Skeleton className="w-1/3 h-4" />
                                                                <Skeleton className="w-2/3 h-4" />
                                                                <Skeleton className="w-full h-4" />
                                                                <Skeleton className="w-4/5 h-4" />
                                                                <Skeleton className="w-3/4 h-4" />
                                                            </div>
                                                        )}
                                                    </div>
                                                </FormControl>
                                            </FormItem>
                                        );
                                    }}
                                />
                                <FormField
                                    control={form.control}
                                    name="default"
                                    render={({ field }) => (
                                        <FormItem className="flex gap-2 mt-2 items-center">
                                            <FormLabel>Set as Default Playbook</FormLabel>
                                            <FormControl className="flex items-center">
                                                <Checkbox
                                                    className="!mt-0"
                                                    checked={field.value}
                                                    onCheckedChange={field.onChange}
                                                />
                                            </FormControl>
                                        </FormItem>
                                    )}
                                />
                            </div>
                        </div>
                    </Form>
                </form>
            </div>

            <DirtyFieldsNavigationDialog
                open={showDialog}
                onOpenChange={(open) => {
                    setShowDialog(open);
                    if (!open && blocker.state === 'blocked') {
                        setPendingLocation(null);
                        blocker.reset();
                    }
                }}
                onSave={handleModalSave}
                onProceed={handleNavigateWithoutSaving}
            />
        </>
    );
};

const PageHeader: FC<{ formId: string; onSubmit: () => void }> = ({ formId, onSubmit }) => {
    const navigate = useNavigate();

    return (
        <header className="sticky top-0 my-0 backdrop-blur-lg bg-background/80 ">
            <div className="flex items-center justify-between py-2 md:px-4 lg:px-8 mx-auto">
                <Button variant="ghost" onClick={() => navigate('/new' + paths.PLAYBOOKS)} size="sm">
                    <ArrowLeftIcon className="h-4 w-4 mr-2" />
                    Back to Playbooks
                </Button>
                <Button type="button" onClick={onSubmit} size="sm">
                    Save changes
                </Button>
            </div>
        </header>
    );
};

const CustomInstructionsDialog: FC = () => {
    const [open, setOpen] = useState(false);
    return (
        <TooltipProvider>
            <Tooltip delayDuration={0}>
                <TooltipTrigger asChild>
                    <Info className="w-3 h-3" />
                </TooltipTrigger>
                <TooltipContent className="bg-background-primary text-text-primary border shadow-lg opacity-100 max-w-sm p-4">
                    <h3 className="text-sm font-medium ">Custom instructions</h3>
                    <p className="mb-4">
                        Custom instructions help guide the AI in crafting your messages, as well as recommendations for
                        new contacts.
                        <br />
                        Include details about your target audience, specific products or services you want to mention,
                        key talking points, how you want to communicate.
                    </p>
                    <h3 className="text-sm font-medium">Example</h3>
                    <p className="italic">
                        Focus on CTOs at enterprise companies, emphasize our AI capabilities, mention our 99.9% uptime.
                        <br />
                        Start off slow and build rapport before asking if they want to book a demo.
                        <br />
                        Avoid technical jargon and keep messages under 150 words.
                    </p>
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    );
};

const SuggestionsDialog: FC<{
    currentContext: string;
    open: boolean;
    setModalOpenState: (open: boolean) => void;
    onSuggestionSelect: (suggestion: string) => void;
}> = ({ currentContext, open, setModalOpenState, onSuggestionSelect }) => {
    const [input, setInput] = useState('');
    const {
        playbook: { generatePlaybookContext: generatePlaybookContextMutation },
    } = useSdk();
    const { mutateAsync: generatePlaybookContext, isPending: loading } = useMutation(generatePlaybookContextMutation());

    const handleGenerate = async (feedback: string) => {
        try {
            setInput('');
            onSuggestionSelect(''); // Trigger loading state first
            setModalOpenState(false);
            const generatedContext = await generatePlaybookContext({
                currentContext,
                feedback,
            });
            onSuggestionSelect(generatedContext);
        } catch (error) {
            console.error('Failed to generate context:', error);
            setInput(feedback);
            onSuggestionSelect(currentContext); // Restore previous context on error
        }
    };

    let placeholder = currentContext
        ? 'How would you like to change the context?'
        : 'What type of context would you like to add';

    placeholder =
        placeholder + '\n\n(e.g. add details about special deals, meeting links, or strategies for engaging customers)';

    return (
        <Popover open={open} onOpenChange={setModalOpenState}>
            <TooltipProvider>
                <Tooltip>
                    <TooltipTrigger asChild>
                        <PopoverTrigger asChild>
                            <Button type="button" variant="ghost" size="sm" className="h-8 w-8 p-0">
                                <SparklesAI animate={loading} width="32px" height="32px" />
                            </Button>
                        </PopoverTrigger>
                    </TooltipTrigger>
                    <TooltipContent>
                        <p>Get AI suggestions for playbook context</p>
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>
            <PopoverContent className="w-[400px] p-0" align="end" side="top">
                <div className="flex flex-col gap-4 p-2">
                    <Textarea
                        rows={6}
                        placeholder={placeholder}
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        disabled={loading}
                        className="resize-none border-0 focus-visible:ring-0 focus-visible:ring-offset-0 shadow-none"
                    />
                    <div className="flex justify-end">
                        <Button onClick={() => handleGenerate(input)} disabled={loading || !input.trim()}>
                            {loading ? (
                                <>
                                    <div className="mr-2 h-4 w-4 animate-spin rounded-full border-2 border-background border-t-foreground"></div>
                                    Update...
                                </>
                            ) : (
                                'Update'
                            )}
                        </Button>
                    </div>
                </div>
            </PopoverContent>
        </Popover>
    );
};
