import { Loader2 } from 'lucide-react';
import { motion, AnimatePresence } from 'motion/react';

interface SearchResultsProps {
    sectionTitle: string;
    results?: Array<React.ReactNode>;
    showNoResults?: boolean;
    isLoading?: boolean;
}

export function SearchResults({ sectionTitle, results, showNoResults, isLoading }: SearchResultsProps) {
    const renderResults = () => (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
        >
            {results?.map((result, index) => (
                <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{
                        duration: 0.08,
                        delay: index * 0.05,
                    }}
                    className="flex items-center"
                >
                    {result}
                </motion.div>
            ))}
        </motion.div>
    );

    const renderNoResults = () => (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="py-1 text-center text-sm text-muted-foreground"
        >
            No results
        </motion.div>
    );

    const renderLoading = () => (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="flex justify-center"
        >
            <Loader2 className="h-5 w-5 animate-spin" />
        </motion.div>
    );

    const renderContent = () => {
        if (results?.length > 0) return renderResults();
        if (!isLoading && (showNoResults || results?.length === 0)) return renderNoResults();
        if (isLoading) return renderLoading();
        return null;
    };

    return (
        <div className="flex flex-col gap-2 p-2">
            <div className="flex items-center justify-between">{results && sectionTitle}</div>
            <AnimatePresence mode="wait">{renderContent()}</AnimatePresence>
        </div>
    );
}
