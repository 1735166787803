import { IUntypedCommand } from './command.interface';

export interface IMigrationSource {
    resourceIds?: string[];
    userId?: string;
    userOrganizationId: string;
}
export interface IMigrationTarget {
    userId?: string;
    userOrganizationId: string;
}

export abstract class AbstractMigrateResourceCommand extends IUntypedCommand {
    constructor(public readonly source: IMigrationSource, public readonly target: IMigrationTarget) {
        super();
    }
}
