import { Search } from 'lucide-react';
import { SidebarGroup, SidebarGroupContent } from '@shadcn/ui/components/ui/sidebar';
import { useSidebars } from '@shadcn/ui/hooks/use-sidebars';
import { cn } from '@shadcn/ui/lib/utils';
import { Button } from '@shadcn/ui/components/ui/button';
import { forwardRef } from 'react';

export const SearchSidebarBox = forwardRef<HTMLDivElement, { onFocus?: () => void }>(({ onFocus }, ref) => {
    const {
        sidebarState: { main: isExpanded },
    } = useSidebars();

    return (
        <SidebarGroup className="p-0">
            <SidebarGroupContent>
                <Button
                    variant="ghost"
                    onClick={onFocus}
                    className={cn(
                        'w-full h-9 relative flex items-center gap-2 bg-background border-1 border-gray-400 rounded-md',
                        isExpanded ? 'justify-start px-2' : '',
                        'hover:text-accent-foreground',
                        'text-muted-foreground'
                    )}
                >
                    <Search className="w-4 h-4 flex-none" />

                    {isExpanded && (
                        <>
                            <span className="flex-grow text-left">Search</span>
                            <kbd className="pointer-events-none inline-flex h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium">
                                <span className="text-xs">⌘</span>K
                            </kbd>
                        </>
                    )}
                </Button>
            </SidebarGroupContent>
        </SidebarGroup>
    );
});
