import axios, { AxiosRequestConfig, AxiosResponse, Method, RawAxiosRequestHeaders } from 'axios';
import { getAuthenticationToken } from '@zaplify/utils';
import { ServerError } from './utils/errors';

// @TODO Refactor: Make all get, post, patch, put, delete methods lightweight, by moving also the error handling into the request() method

export class BaseSdk {
    readonly campaignsPath = `campaigns`;
    readonly outreachSettingsPath = `outreach-settings`;
    readonly usersPath = `users`;
    readonly userAdminPath = `admin/users`;
    readonly userOrganizationsPath = `user-organizations`;
    readonly prospectsPath = `prospects`;
    readonly targetSearchesPath = `target-searches`;
    readonly authenticationPath = `authentication`;
    readonly channelAccountPath = `channel-accounts`;
    readonly sourcesPath = `sources`;
    readonly appsPath = `apps`;
    readonly hubspotPath = `hubspot`;
    readonly webhooksPath = `webhooks`;
    readonly pipedrivePath = `pipedrive`;
    readonly upsalesPath = `upsales`;
    readonly oauthPath = `oauth`;
    readonly salesforcePath = `salesforce`;
    readonly billingPath = `billing`;
    readonly subscriptionPath = `subscriptions`;
    readonly search = `search`;
    readonly rootPath = ``;
    readonly trackersPath = 'trackers';
    readonly tasksPath = 'tasks';
    readonly discountsPath = 'discounts';
    readonly contactSuggestionsPath = 'contact-suggestions';
    readonly outreachSuggestionPath = 'outreach-suggestions';
    readonly assistantPath = 'assistant';
    readonly groupsPath = 'groups';
    readonly notesPath = 'notes';
    readonly userContactsPath = 'user-contacts';
    readonly pageViewPath = 'user-move';
    readonly messengerPath = 'messenger';
    readonly dataSourcesSyncPath = 'data-source-sync';
    readonly linkedinProfilesPath = 'linkedin-profiles';
    readonly linkedinConnectionsPath = 'linkedin-connections';
    readonly linkedinConversationsPath = 'linkedin-conversations';
    readonly feedbackPath = 'feedback';
    apiEndpoint: string;

    constructor(apiEndpoint: string, token?: string) {
        this.apiEndpoint = apiEndpoint;
    }

    protected async request<T>(
        path: string,
        method: Method,
        _config?: AxiosRequestConfig,
        retriesLeft = 2, // @TODO Change to 1
    ): Promise<AxiosResponse<T>> {
        let config: AxiosRequestConfig | undefined;
        try {
            config = await this.getRequestConfig(_config);
            const result = await axios<T>(`${this.apiEndpoint}/${path}`, {
                method: method,
                ...config,
            });
            return result;
        } catch (error) {
            const errorStatus = (error as any)?.response?.status;
            if (errorStatus === 401 && retriesLeft > 0) {
                // await sleep(3000);
                await getAuthenticationToken(true);
                return await this.request(path, method, config || _config, retriesLeft - 1);
            }
            throw error;
        }
    }

    protected async get<T>(
        path: string,
        options?: { params?: any; headers?: { [key: string]: string } },
    ): Promise<T | null> {
        try {
            const result = await this.request<T>(path, 'GET', {
                params: options?.params,
                headers: options?.headers,
            });
            return result?.data || null;
        } catch (error) {
            const errorStatus = (error as any)?.response?.status;
            if (errorStatus === 404) {
                return null;
            }
            throw new ServerError(`HTTP GET failed, error: ${(error as any).message}`);
        }
    }

    protected async post<T>({
        path,
        payload,
        callback,
        headers,
    }: {
        path: string;
        payload?: {};
        callback?: (...args: any) => any;
        headers?: { [key: string]: string };
    }): Promise<T> {
        try {
            const result = await this.request<T>(path, 'POST', {
                headers,
                data: payload,
            });
            return result.data;
        } catch (error: any) {
            // Error handling is strange over here.
            // If we combine what callback does with the exception style it would be great.
            // maybe we should introduce something like v2 version of this one?
            if (callback) {
                return callback(error.response?.data?.message || JSON.stringify(error.response));
            } else if (error?.response?.data?.type === 'ClientPresentableException') {
                throw error.response?.data;
            }

            throw new ServerError(`HTTP POST failed, error: ${(error as any).message}`);
        }
    }

    protected async put<T>(path: string, payload?: object, callback?: (...args: any) => any): Promise<T> {
        try {
            const result = await this.request<T>(path, 'PUT', {
                data: payload,
            });
            return result.data;
        } catch (error: any) {
            if (callback) {
                return callback(JSON.stringify(error.response));
            } else if (error?.response?.data?.type === 'ClientPresentableException') {
                throw error.response?.data;
            }

            if (error.response?.data?.payload) {
                throw new Error(`${JSON.stringify(error.response?.data)}`);
            }
            console.error(`HTTP PUT failed, error:`, error.response?.data || error.message);
            throw new ServerError(`HTTP PUT failed, error: ${error.response?.data || error.message}`);
        }
    }

    protected async patch<T>(path: string, payload?: object): Promise<T> {
        try {
            const result = await this.request<T>(path, 'PATCH', {
                data: payload,
            });
            return result.data;
        } catch (error) {
            throw new ServerError(`HTTP PATCH failed, error: ${(error as any).message}`);
        }
    }

    protected async delete<T>(path: string, payload?: object): Promise<T> {
        try {
            const result = await this.request<T>(path, 'DELETE', {
                data: payload,
            });
            return result.data;
        } catch (error) {
            throw new ServerError(`HTTP DELETE failed, error: ${(error as any).message}`);
        }
    }

    private async getRequestConfig(options: AxiosRequestConfig = {}): Promise<AxiosRequestConfig> {
        const token = await getAuthenticationToken();
        const config: AxiosRequestConfig = {
            headers: this.getRequestConfigHeaders(token.token, options.headers),
        };
        if (options.params) {
            config.params = options.params;
        }
        if (options.data) {
            config.data = options.data;
        }
        return config;
    }

    //Not using this one anymore, header is created inside get and post functions below
    // an 'any' is used as it will require deeper understanding of types in AxiosRequestConfig
    private getRequestConfigHeaders(
        authenticationToken: string,
        headers?: RawAxiosRequestHeaders,
    ): RawAxiosRequestHeaders {
        let authenticateAsUserId = null;
        if (typeof localStorage !== 'undefined') {
            authenticateAsUserId = localStorage.getItem('x-authenticate-as-user');
        }

        const requestHeaders: RawAxiosRequestHeaders = !authenticationToken
            ? {}
            : {
                  AcceptEncoding: 'gzip',
                  ...(authenticateAsUserId && {
                      'x-authenticate-as-user': authenticateAsUserId,
                  }),
                  ...(!!headers && headers),
                  Authorization: `Bearer ${authenticationToken}`,
              };

        return requestHeaders;
    }
}
