import { useState, useEffect, useCallback, FC, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getAuth } from 'firebase/auth';
import { getEmailDomain } from '@zaplify/utils';

import { Button } from '@shadcn/ui/components/ui/button';
import { Card, CardContent } from '@shadcn/ui/components/ui/card';

import { Loader2 } from 'lucide-react';
import { useSdk } from '../../sdk/use-sdk';
import { LocalStorageKeys } from '../../../config';
import { getAuthenticationToken } from '@zaplify/utils';
import { toast } from '@shadcn/ui/hooks/use-toast';
import { CreateUserDto, UserOrganizationByDomainDto } from '@zaplify/users/shared';
import { AndSendLogotype } from '../../../components/icons/and-send/andsend-logo';
import { IUserAccount } from '@zaplify/users/shared';
import { cn } from '@shadcn/ui/lib/utils';
import { v4 as uuid4 } from 'uuid';
import { Input } from '@shadcn/ui/components/ui/input';
import { AuthHeader } from '../../pages/auth/components/auth-header';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@shadcn/ui/components/ui/form';
import { PlanCode } from '@zaplify/subscriptions';
import { getZaplifySdk } from '@zaplify/sdk';
import useCountries from '../../hooks/use-countries';
// import useFallbackCountries from '../../hooks/use-fallback-countries';
import { stripTopLevelDomain } from '../../functions/email-helpers';
import { Checkbox } from '@shadcn/ui/components/ui/checkbox';
import { Popover, PopoverContent, PopoverTrigger } from '@shadcn/ui/components/ui/popover';
import { Check, ChevronsUpDown } from 'lucide-react';
import { useApolloClient } from '@apollo/client';
import { paths } from '../../../routes/paths';
import { useAuth } from '../../providers/authentication-provider';
import { Link } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface IInputState {
    authUser: {
        authUserId: string;
        authUserEmail: string;
        domain: string;
    } | null;
    referralLinkId: string | null;
    joinByDomain: { organizationId: string; domain: string } | null;
    joinByInvitation: { invitationId: string; organizationId: string } | null;
    createOrganization: {} | null;
}

const formSchema = z.object({
    firstName: z.string().min(1, 'First name is required'),
    lastName: z.string().min(1, 'Last name is required'),
    country: z.string().min(1, 'Country is required'),
    website: z.string(),
    companyName: z.string(),
    companyDomain: z.string(),
    isSubscribed: z.boolean().default(true),
});

type ConfigureProfileFormValues = z.infer<typeof formSchema>;
const isOnWindows = navigator?.userAgent?.includes('Windows');

const countryCodeToFlagEmoji = (countryCode: string) => {
    return countryCode.toUpperCase().replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397));
};

const getAnonymousId = (): string => {
    const cookies = document.cookie;
    let anonymousId = null;
    const cookiesArray = cookies.split('; ');
    for (let cookie of cookiesArray) {
        if (cookie.startsWith('ajs_anonymous_id=')) {
            anonymousId = cookie.split('=')[1];
            return anonymousId;
        }
    }
};

export const NewWorkspacePage: FC = () => {
    const navigate = useNavigate();
    const tanStackClient = useQueryClient();
    const apolloClient = useApolloClient();
    const { shadcnOnboardingFlow } = useFlags();
    const {
        user: { getUserAccounts, getUserOrganizationByDomain },
        authentication: { chooseOrganization },
    } = useSdk();
    const {
        authState: { userId },
    } = useAuth();
    const { mutateAsync: chooseOrganizationMutation } = useMutation(chooseOrganization());
    const [authErrorMessage, setAuthErrorMessage] = useState<string | null>(null);
    const [suggestedFormValues, setSuggestedFormValues] = useState<{ companyName: string; domain: string } | null>(
        null
    );
    const [loading, setLoading] = useState(true);
    const [inputState, setInputState] = useState<IInputState>({
        authUser: null,
        referralLinkId: null,
        joinByDomain: null,
        joinByInvitation: null,
        createOrganization: null,
    });
    const idempotencyKey = uuid4();
    const hasCreatedWorkspace = useRef(false);
    const isLoggedOutFromWorkspace = !userId;

    const configureProfile = async (createUser: CreateUserDto) => {
        try {
            const zaplifyUser = await getZaplifySdk().profiles.user.createUser(createUser);
            if (!zaplifyUser) {
                return;
            }

            localStorage.removeItem(LocalStorageKeys.REFERRAL_LINK);
            localStorage.removeItem(LocalStorageKeys.INVITATION_ID);
            localStorage.removeItem(LocalStorageKeys.INVITATION_SECURITY_TOKEN);
            hasCreatedWorkspace.current = true;

            // Important that we make sure the auth token is refreshed also after creating the new user, so that subsequent requests are made using correct user
            await getAuthenticationToken(true);
            await getZaplifySdk().profiles.billing.createFreeSubscription({ 'idempotency-key': idempotencyKey });

            return zaplifyUser;
        } catch (error) {
            throw new Error('Error configuring profile. Try again later', {
                cause: error,
            });
        }
    };

    const getAccounts = useCallback(async () => {
        try {
            const authUser = getAuth().currentUser;
            if (!authUser) return;
            const domain = getEmailDomain(authUser.email);
            const referralLinkId = localStorage.getItem(LocalStorageKeys.REFERRAL_LINK);
            const invitationId = localStorage.getItem(LocalStorageKeys.INVITATION_ID);
            const invitationSecurityToken = localStorage.getItem(LocalStorageKeys.INVITATION_SECURITY_TOKEN);

            const [accountResponse, organizationMatchingDomainResponse, invitation] = await Promise.all([
                tanStackClient.fetchQuery(getUserAccounts()),
                tanStackClient.fetchQuery(getUserOrganizationByDomain(domain)),
                invitationId &&
                    invitationSecurityToken &&
                    getZaplifySdk().profiles.user.getInvitationDetails(invitationId, invitationSecurityToken),
            ]);

            // Mock structure for development purposes - replace with actual data once we know the structure
            const accountData = accountResponse || { users: [], invitations: [] };
            const users = accountData.users || [];

            const userHasAccountWithDomain = users?.some((user) =>
                domain.includes(user.organization?.name?.toLowerCase())
            );

            const organizationHasTeamFeatures = [PlanCode.TEAM, PlanCode.ENTERPRISE].includes(
                organizationMatchingDomainResponse?.subscription?.subscription?.planCode
            );

            const isJoinByDomain =
                organizationMatchingDomainResponse && !userHasAccountWithDomain && organizationHasTeamFeatures;
            const isJoinByInvitation = Boolean(invitationId && invitationSecurityToken);
            const isCreateOrganization = !isJoinByDomain && !isJoinByInvitation;

            setInputState({
                authUser: {
                    authUserId: authUser.uid,
                    authUserEmail: authUser.email,
                    domain: domain,
                },
                referralLinkId: referralLinkId,
                joinByDomain: isJoinByDomain
                    ? {
                          organizationId: organizationMatchingDomainResponse.organization.id,
                          domain: organizationMatchingDomainResponse.organization.domain,
                      }
                    : null,
                joinByInvitation: isJoinByInvitation
                    ? {
                          invitationId: invitation?.id,
                          organizationId: invitation?.organizationId,
                      }
                    : null,
                createOrganization: isCreateOrganization ? {} : null,
            });

            setLoading(false);
        } catch (error) {
            console.error('error accounts', error);
            toast({
                title: 'Error getting accounts',
                variant: 'destructive',
            });
            setLoading(false);
        }
    }, []);

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            firstName: '',
            lastName: '',
            country: '',
            website: '',
            companyName: '',
            companyDomain: '',
            isSubscribed: true,
        },
        mode: 'all',
    });

    const { mutate, status: formStatus } = useMutation({
        mutationFn: async (data: z.infer<typeof formSchema>) => {
            // @TODO Split the form into three different forms for the three ways of creating/joining a workspace
            //       Then, split this function into three different functions, one for each way of creating/joining a workspace
            const { authUser, joinByInvitation, joinByDomain, createOrganization, referralLinkId } = inputState;
            const anonymousId = getAnonymousId();
            const parsedCountry = data.country.split(' ')[1];

            console.log({
                message: 'the info',
                anonymousId,
                parsedCountry,
                ...inputState,
            });

            if (!authUser) {
                throw new Error('Could not determine the logged in user');
            }

            let createUserDto: CreateUserDto;
            if (joinByInvitation) {
                createUserDto = {
                    anonymousId: anonymousId,
                    firebaseId: authUser.authUserId,
                    email: authUser.authUserEmail,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    location: {
                        country: parsedCountry,
                        city: '',
                    },
                    isSubscribed: data.isSubscribed,
                    referredByReferralLinkId: referralLinkId,
                    joinByInvitation: {
                        id: joinByInvitation.invitationId,
                        organizationId: joinByInvitation.organizationId,
                    },
                };
            } else if (joinByDomain) {
                createUserDto = {
                    anonymousId: anonymousId,
                    firebaseId: authUser.authUserId,
                    email: authUser.authUserEmail,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    location: {
                        country: parsedCountry,
                        city: '',
                    },
                    isSubscribed: data.isSubscribed,
                    referredByReferralLinkId: referralLinkId,
                    joinByDomain: {
                        // @TODO Require organizationId as well for security purposes..?
                        domain: joinByDomain.domain,
                    },
                };
            } else if (createOrganization) {
                createUserDto = {
                    anonymousId: anonymousId,
                    firebaseId: authUser.authUserId,
                    email: authUser.authUserEmail,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    location: {
                        country: parsedCountry,
                        city: '',
                    },
                    isSubscribed: data.isSubscribed,
                    referredByReferralLinkId: referralLinkId,
                    createOrganization: {
                        name: data.companyName,
                        domain: data.companyDomain,
                        website: data.website,
                    },
                };
            } else {
                throw new Error('Unsupported scenario');
            }

            return await configureProfile(createUserDto);
        },
        onSuccess: () => {
            navigate(
                shadcnOnboardingFlow ? paths.NEW.ONBOARDING_PATHS.CONNECT_LINKEDIN : paths.ONBOARDING_CONNECT_LINKEDIN
            );
        },
        onError: (error: unknown) => {
            if (error instanceof Error) {
                setAuthErrorMessage(
                    'An error occurred while setting up your workspace. Please try again or contact support.'
                );
            } else {
                setAuthErrorMessage('An error occurred');
            }
        },
    });
    const { countries, status: countriesStatus, isError: countriesIsError, error: countriesError } = useCountries();
    // const [fallbackCountries] = useFallbackCountries();

    const onSubmit = (data: ConfigureProfileFormValues) => {
        mutate(data);
    };
    const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    useEffect(() => {
        getAccounts();
    }, [getAccounts]);

    useEffect(() => {
        const signoutFromWorkspace = async () => {
            try {
                await chooseOrganizationMutation({});
                await getAuthenticationToken(true);
                await Promise.all([tanStackClient.clear(), apolloClient.resetStore()]);
            } catch (error) {
                toast({
                    title: 'Unable to sign out from workspace',
                    description:
                        'Something went wrong. Please try to refresh the page or contact our support if the problem persists.',
                    variant: 'destructive',
                });
            }
        };
        if (!isLoggedOutFromWorkspace && !hasCreatedWorkspace.current) {
            signoutFromWorkspace();
        }
    }, []);

    // Update the form's default value once, when the data is available
    useEffect(() => {
        if (inputState.authUser?.domain && !suggestedFormValues) {
            const cleanedDomain = stripTopLevelDomain(inputState.authUser?.domain);
            const companyName = cleanedDomain[0].toUpperCase() + cleanedDomain.slice(1);
            setSuggestedFormValues({ companyName, domain: inputState.authUser?.domain });

            form.setValue('companyName', companyName);
            form.setValue('companyDomain', inputState.authUser?.domain);
        }
    }, [inputState.createOrganization, suggestedFormValues, form]);

    const isLoading =
        formStatus === 'pending' ||
        countriesStatus === 'pending' ||
        // !fallbackCountries.length ||
        // Note: We do not want to stop loading if formStatus is 'success', because if the submission succeeds the token is refreshed and there's a bunch of asynchronous proccesses happening that eventually will lead to us being redirecting from this view to the next. And, while we wait for those asynchronous processes, we don't want the user to be able to "Next" again, since that would create a new second user in a second workspace.
        // This means that, if the form submission is successful, we will never stop loading, because we will be redirected a few seconds later anyway
        formStatus === 'success' ||
        (!isLoggedOutFromWorkspace && !hasCreatedWorkspace.current) ||
        loading;

    // Add this state for country search
    const [countrySearch, setCountrySearch] = useState('');

    // Add this function to filter countries by search term
    const filteredCountries =
        countries?.filter((country) => country.commonName.toLowerCase().includes(countrySearch.toLowerCase())) || [];

    // Add this state to control the popover
    const [countryPopoverOpen, setCountryPopoverOpen] = useState(false);

    return (
        <div className="flex flex-col min-h-screen bg-background-secondary">
            {/* Header */}
            <div className="flex flex-col gap-4 p-4 md:p-6 z-10">
                <AuthHeader />
            </div>

            {/* Content */}
            <main className="flex-1 py-12">
                <div className="container max-w-3xl mx-auto space-y-10 px-4 sm:px-6">
                    <div className="text-center space-y-4">
                        <h1 className="text-4xl font-bold tracking-tight">Let's customize your workspace</h1>
                        <p className="text-primary-muted">The first step towards customer relationship success</p>
                    </div>
                    {!isLoggedOutFromWorkspace && !hasCreatedWorkspace.current && (
                        <div className="space-y-4 hover:shadow-md focus-within:shadow-md transition-shadow duration-300">
                            <Card>
                                <CardContent className="space-y-4 p-6">
                                    <div className="flex items-center justify-center">
                                        Signing out from your current workspace...{' '}
                                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    )}
                    {isLoggedOutFromWorkspace && (
                        <div className="max-w-md mx-auto space-y-4 hover:shadow-md focus-within:shadow-md transition-shadow duration-300">
                            <Card>
                                <CardContent className="space-y-4 p-6">
                                    <Form {...form}>
                                        <form
                                            onSubmit={form.handleSubmit(onSubmit)}
                                            onKeyDown={handleKeyDown}
                                            className="space-y-4"
                                        >
                                            <FormField
                                                control={form.control}
                                                name="firstName"
                                                render={({ field }) => (
                                                    <FormItem>
                                                        <FormLabel>First name</FormLabel>
                                                        <FormControl>
                                                            <Input placeholder="John" {...field} />
                                                        </FormControl>
                                                        <FormMessage />
                                                    </FormItem>
                                                )}
                                            />

                                            <FormField
                                                control={form.control}
                                                name="lastName"
                                                render={({ field }) => (
                                                    <FormItem>
                                                        <FormLabel>Last name</FormLabel>
                                                        <FormControl>
                                                            <Input placeholder="Doe" {...field} />
                                                        </FormControl>
                                                        <FormMessage />
                                                    </FormItem>
                                                )}
                                            />
                                            <div className="hidden">
                                                <FormField
                                                    control={form.control}
                                                    name="companyName"
                                                    render={({ field }) => (
                                                        <FormItem>
                                                            <FormLabel>Workspace name</FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    placeholder="Workspace name"
                                                                    {...field}
                                                                    disabled={
                                                                        !inputState.createOrganization || isLoading
                                                                    }
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )}
                                                />
                                            </div>
                                            <div className="hidden">
                                                <FormField
                                                    control={form.control}
                                                    name="companyDomain"
                                                    render={({ field }) => (
                                                        <FormItem>
                                                            <FormLabel>Company domain</FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    placeholder="Company domain"
                                                                    {...field}
                                                                    disabled={
                                                                        !inputState.createOrganization || isLoading
                                                                    }
                                                                />
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )}
                                                />
                                            </div>

                                            <FormField
                                                control={form.control}
                                                name="country"
                                                render={({ field }) => (
                                                    <FormItem>
                                                        <FormLabel>Country</FormLabel>
                                                        <Popover
                                                            open={countryPopoverOpen}
                                                            onOpenChange={(open) => {
                                                                setCountryPopoverOpen(open);
                                                                // Reset search when closing without selecting
                                                                if (!open) setCountrySearch('');
                                                            }}
                                                        >
                                                            <PopoverTrigger asChild>
                                                                <FormControl>
                                                                    <Button
                                                                        variant="outline"
                                                                        role="combobox"
                                                                        onClick={() => setCountryPopoverOpen(true)}
                                                                        className={cn(
                                                                            'w-full justify-between',
                                                                            !field.value && 'text-muted-foreground'
                                                                        )}
                                                                        type="button" // Ensure it doesn't submit the form
                                                                    >
                                                                        {field.value ? (
                                                                            <>
                                                                                {!isOnWindows &&
                                                                                field.value.includes(' ')
                                                                                    ? countryCodeToFlagEmoji(
                                                                                          field.value.split(' ')[0]
                                                                                      ) + ' '
                                                                                    : ''}
                                                                                {field.value.includes(' ')
                                                                                    ? field.value
                                                                                          .split(' ')
                                                                                          .slice(1)
                                                                                          .join(' ')
                                                                                    : field.value}
                                                                            </>
                                                                        ) : (
                                                                            'Select your country'
                                                                        )}
                                                                        <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                                                                    </Button>
                                                                </FormControl>
                                                            </PopoverTrigger>
                                                            <PopoverContent
                                                                className="w-[var(--radix-popover-trigger-width)] p-0"
                                                                align="start"
                                                            >
                                                                {countriesStatus === 'pending' ? (
                                                                    <div className="p-4 text-center">
                                                                        <Loader2 className="h-6 w-6 animate-spin mx-auto" />
                                                                        <p className="text-sm text-muted-foreground mt-2">
                                                                            Loading countries...
                                                                        </p>
                                                                    </div>
                                                                ) : countriesIsError ? (
                                                                    <div className="p-4 text-center">
                                                                        <p className="text-sm text-destructive">
                                                                            Error loading countries
                                                                        </p>
                                                                    </div>
                                                                ) : (
                                                                    <div className="flex flex-col">
                                                                        <div className="p-2">
                                                                            {/* This input is ONLY for filtering, not for setting form values */}
                                                                            <Input
                                                                                placeholder="Search countries..."
                                                                                value={countrySearch}
                                                                                onChange={(e) =>
                                                                                    setCountrySearch(e.target.value)
                                                                                }
                                                                                className="h-9"
                                                                                // Stop propagation so it doesn't close the popover
                                                                                onClick={(e) => e.stopPropagation()}
                                                                                // Prevent enter from submitting the form
                                                                                onKeyDown={(e) => {
                                                                                    if (e.key === 'Enter')
                                                                                        e.preventDefault();
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div className="max-h-[300px] overflow-y-auto p-1">
                                                                            {filteredCountries.length === 0 ? (
                                                                                <div className="p-2 text-center text-sm text-muted-foreground">
                                                                                    No countries found
                                                                                </div>
                                                                            ) : (
                                                                                filteredCountries.map((country) => (
                                                                                    <div
                                                                                        key={country.countryCode}
                                                                                        className={cn(
                                                                                            'flex items-center px-2 py-1.5 rounded-sm text-sm cursor-pointer hover:bg-accent',
                                                                                            field.value ===
                                                                                                `${country.countryCode} ${country.commonName}` &&
                                                                                                'bg-accent'
                                                                                        )}
                                                                                        onClick={() => {
                                                                                            const value = `${country.countryCode} ${country.commonName}`;
                                                                                            form.setValue(
                                                                                                'country',
                                                                                                value
                                                                                            );
                                                                                            form.trigger('country');
                                                                                            setCountrySearch('');
                                                                                            setCountryPopoverOpen(
                                                                                                false
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        <Check
                                                                                            className={cn(
                                                                                                'mr-2 h-4 w-4',
                                                                                                field.value ===
                                                                                                    `${country.countryCode} ${country.commonName}`
                                                                                                    ? 'opacity-100'
                                                                                                    : 'opacity-0'
                                                                                            )}
                                                                                        />
                                                                                        {!isOnWindows
                                                                                            ? `${countryCodeToFlagEmoji(
                                                                                                  country.countryCode
                                                                                              )} ${country.commonName}`
                                                                                            : country.commonName}
                                                                                    </div>
                                                                                ))
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </PopoverContent>
                                                        </Popover>
                                                        <FormMessage />
                                                    </FormItem>
                                                )}
                                            />

                                            <FormField
                                                control={form.control}
                                                name="isSubscribed"
                                                render={({ field }) => (
                                                    <FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md p-4">
                                                        <FormControl>
                                                            <Checkbox
                                                                checked={field.value}
                                                                onCheckedChange={field.onChange}
                                                            />
                                                        </FormControl>
                                                        <div className="space-y-1 leading-none">
                                                            <FormLabel className="text-sm text-gray-500">
                                                                Get feature updates and tips via email
                                                            </FormLabel>
                                                        </div>
                                                    </FormItem>
                                                )}
                                            />

                                            <div className="flex flex-col space-y-4">
                                                <div className="flex space-x-4 w-full">
                                                    {inputState.createOrganization && (
                                                        <Button
                                                            type="button"
                                                            variant="outline"
                                                            onClick={() => navigate(-1)}
                                                            disabled={isLoading}
                                                            className="w-full"
                                                        >
                                                            Back
                                                        </Button>
                                                    )}
                                                    <Button type="submit" disabled={isLoading} className="w-full">
                                                        {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                                                        Next
                                                    </Button>
                                                </div>

                                                <div className="text-balance text-center text-xs text-muted-foreground [&_a]:font-medium [&_a]:hover:text-primary">
                                                    By continuing, you agree to our{' '}
                                                    <Link
                                                        to={paths.LEGAL.TERMS}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Terms
                                                    </Link>
                                                    ,{' '}
                                                    <Link
                                                        to={paths.LEGAL.PRIVACY_POLICY}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Privacy Policy
                                                    </Link>{' '}
                                                    and{' '}
                                                    <a href={paths.LEGAL.DPA} target="_blank" rel="noopener noreferrer">
                                                        DPA
                                                    </a>
                                                    .
                                                </div>

                                                {authErrorMessage && (
                                                    <p className="text-sm text-red-500 text-center">
                                                        {authErrorMessage}
                                                    </p>
                                                )}
                                            </div>
                                        </form>
                                    </Form>
                                </CardContent>
                            </Card>
                        </div>
                    )}
                </div>
            </main>
        </div>
    );
};

export default NewWorkspacePage;
