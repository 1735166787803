import { FC } from 'react';
import { Gauge } from 'lucide-react';

export const AdminHeader: FC = () => {
    return (
        <header className="sticky top-0 z-50 w-full border-b bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
            <div className="flex h-14 items-center justify-between gap-4 px-4">
                {/* Left section */}

                <div className="flex items-center gap-4 w-[304px] h-full pr-4">
                    <Gauge className="h-5 w-5" />
                    <span className="font-medium text-2xl">Admin Dashboard</span>
                </div>

                {/* Center section */}
                <div className="flex flex-1 items-center justify-between pl-4">
                    <div className="flex items-center gap-3 flex-grow">
                        {/* <Skeleton className="w-48 h-2 bg-background-brand-primary-subtle" /> */}
                    </div>

                    {/* Right section */}
                    <div className="flex items-center gap-4">
                        {/* <TooltipProvider>
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <Button
                                        variant="outline"
                                        size="sm"
                                        className="flex items-center gap-2"
                                        onClick={onAddContact}
                                    >
                                        <Plus className="h-4 w-4" />
                                        <span className="hidden lg:inline">Add contact</span>
                                    </Button>
                                </TooltipTrigger>
                                <TooltipContent>
                                    <kbd className="pointer-events-none inline-flex h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium text-muted-foreground opacity-100">
                                        <span className="text-xs">⌘</span>+
                                    </kbd>
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider> */}
                    </div>
                </div>
            </div>
        </header>
    );
};

export default AdminHeader;
