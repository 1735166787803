import { IEvent, Topics } from '@zaplify/common';
import { ContactSource } from '../dtos';

export class UserContactCreatedEvent extends IEvent {
    public static override readonly TOPIC: Topics = Topics.PROSPECTS;
    public readonly id: string;
    public readonly prospectId: string;
    public readonly userId: string;
    public readonly groupIds: string[];
    public readonly createdAt: Date;
    public readonly source?: ContactSource;

    constructor(params: {
        id: string;
        prospectId: string;
        userId: string;
        groupIds: string[];
        createdAt: Date;
        source?: ContactSource;
    }) {
        super();
        this.id = params.id;
        this.prospectId = params.prospectId;
        this.userId = params.userId;
        this.groupIds = params.groupIds;
        this.createdAt = params.createdAt;
        this.source = params.source;
    }

    get partitionKey(): string {
        return this.prospectId;
    }
}
