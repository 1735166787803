import { ListItemIcon, MenuItem as MaterialMenuItem } from '@mui/material';
import PropTypes from 'prop-types';

const MenuItem = (props) => {
    const { title, icon, onClick, className } = props;
    return (
        <MaterialMenuItem className={className} onClick={onClick}>
            {icon && <ListItemIcon>{icon}</ListItemIcon>}
            {title}
        </MaterialMenuItem>
    );
};

MenuItem.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.node,
    onClick: PropTypes.func,
    className: PropTypes.string,
};

export default MenuItem;
