import * as React from 'react';
import {
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogFooter,
    DialogClose,
    DialogDescription,
} from '@shadcn/ui/components/ui/dialog';
import { chunkArray } from '@zaplify/utils';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@shadcn/ui/components/ui/tooltip';
import { Button } from '@shadcn/ui/components/ui/button';
import { GET_USER_CONTACTS_BY_IDS, GET_ALL_USER_CONTACTS, Order_By } from '@zaplify/graphql';
import { useLazyQuery } from '@apollo/client';
import { useToast } from '@shadcn/ui/hooks/use-toast';
import { SearchParamDialog, useSearchParamDialog } from '@shadcn/ui/components/search-param-dialog';
import { Label } from '@shadcn/ui/components/ui/label';
import { RadioGroup, RadioGroupItem } from '@shadcn/ui/components/ui/radio-group';
import { useEffect, useState } from 'react';
import { useFileExport } from '../../hooks/use-file-export';
import { useFormattedContact } from './export-contacts-dialog/hooks/use-formatted-contact';
import { ProspectDto } from '@zaplify/prospects';
import { useQueryClient } from '@tanstack/react-query';
import { useSdk } from '../../sdk';
import { Progress } from '@shadcn/ui/components/ui/progress';

export const useExportContactsDialog = () => {
    const { open, close, value, isOpen } = useSearchParamDialog('export-contacts');

    return {
        openExportContactsDialog: (userContactIds: string[]) => open(userContactIds?.join(',') || 'none'),
        closeExportContactsDialog: close,
        value: value === 'none' ? [] : decodeURIComponent(value)?.split(','),
        isOpen,
    };
};

export const ExportContactsDialog: React.FC = () => {
    const { toast } = useToast();
    const { downloadCSV, downloadExcel } = useFileExport();
    const { value: userContactIds, isOpen } = useExportContactsDialog();
    const {
        prospect: { getProspectById },
    } = useSdk();
    const queryClient = useQueryClient();
    const { getContactData } = useFormattedContact();
    const [format, setFormat] = useState<'csv' | 'xlsx'>('csv');
    const [selectedContacts, setSelectedContacts] = useState<'selected' | 'all'>('selected');
    const [isExporting, setIsExporting] = useState(false);
    const [progress, setProgress] = useState(0);
    const [showProgress, setShowProgress] = useState(false);

    const [loadAllContacts] = useLazyQuery(GET_ALL_USER_CONTACTS, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
    });

    const [loadSelectedContacts] = useLazyQuery(GET_USER_CONTACTS_BY_IDS, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
    });

    const handleExport = async () => {
        try {
            setIsExporting(true);
            setProgress(0);

            // Load contacts based on selection
            const { data: contactsData } =
                selectedContacts === 'all'
                    ? await loadAllContacts({
                          variables: {
                              orderBy: [{ createdAt: Order_By.Desc }],
                          },
                      })
                    : await loadSelectedContacts({
                          variables: {
                              ids: userContactIds,
                          },
                      });

            const contacts = contactsData?.UserContacts;

            if (!contacts || contacts.length === 0) {
                throw new Error('No contacts found to export. Selected contacts: ' + selectedContacts);
            }

            // Show progress bar for large exports
            const showProgressBar = contacts.length > 200;
            setShowProgress(showProgressBar);

            // Fetch prospect data for each contact
            const batches = chunkArray(contacts, 50);
            const prospectsBatches = [];

            for (let i = 0; i < batches.length; i++) {
                const batch = batches[i];
                const batchResults = await Promise.all(
                    batch.map((contact) => queryClient.fetchQuery(getProspectById(contact.prospectId)))
                );
                prospectsBatches.push(batchResults);

                if (showProgressBar) {
                    setProgress(((i + 1) / batches.length) * 100);
                }
            }
            const prospects = prospectsBatches.flat();

            // Combine contacts with their prospect data
            const contactsWithProspects = contacts.map((contact, index) => ({
                userContactId: contact.id,
                prospect: prospects[index],
            }));

            // Download in selected format
            if (format === 'csv') {
                await downloadContacts(contactsWithProspects, 'csv');
            } else {
                await downloadContacts(contactsWithProspects, 'xlsx');
            }

            toast({
                title: 'Success',
                description: 'Contacts exported successfully',
            });
        } catch (error) {
            console.error('Export error:', error);
            toast({
                title: 'Error',
                description: 'Failed to export contacts. Please try again.',
                variant: 'destructive',
            });
        } finally {
            setIsExporting(false);
            setShowProgress(false);
            setProgress(0);
        }
    };

    useEffect(() => {
        if (userContactIds.length === 0) {
            setSelectedContacts('all');
        }
    }, [userContactIds]);

    const downloadContacts = async (
        contacts: { userContactId: string; prospect: ProspectDto }[],
        format: 'csv' | 'xlsx',
        fileNameEnding = ''
    ) => {
        const contactsFormatted = contacts.map((contact) => getContactData(contact));
        if (format === 'csv') {
            downloadCSV(contactsFormatted, 'contacts' + fileNameEnding);
        } else {
            downloadExcel(contactsFormatted, 'contacts' + fileNameEnding);
        }
    };

    if (!isOpen) return null;

    return (
        <SearchParamDialog param={'export-contacts'}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Export Contacts</DialogTitle>
                    <DialogDescription>Choose your export preferences below</DialogDescription>
                </DialogHeader>

                <div className="grid gap-4 py-4">
                    <div className="space-y-4">
                        <Label>Export Format</Label>
                        <RadioGroup
                            defaultValue={format}
                            onValueChange={(value) => setFormat(value as 'csv' | 'xlsx')}
                            className="flex flex-col space-y-1"
                        >
                            <div className="flex items-center space-x-2">
                                <RadioGroupItem value="csv" id="csv" />
                                <Label htmlFor="csv">CSV</Label>
                            </div>
                            <div className="flex items-center space-x-2">
                                <RadioGroupItem value="xlsx" id="xlsx" />
                                <Label htmlFor="xlsx">Excel (XLSX)</Label>
                            </div>
                        </RadioGroup>
                    </div>

                    <div className="space-y-4">
                        <Label>Contacts to Export</Label>
                        <RadioGroup
                            defaultValue={userContactIds.length === 0 ? 'all' : 'selected'}
                            onValueChange={(value) => setSelectedContacts(value as 'selected' | 'all')}
                            className="flex flex-col space-y-1"
                        >
                            <div className="flex items-center space-x-2">
                                <RadioGroupItem value="selected" id="selected" disabled={userContactIds.length === 0} />
                                <Label
                                    htmlFor="selected"
                                    className={userContactIds.length === 0 ? 'text-muted-foreground' : ''}
                                >
                                    Selected Contacts ({userContactIds.length})
                                </Label>
                            </div>
                            <div className="flex items-center space-x-2">
                                <RadioGroupItem value="all" id="all" />
                                <Label htmlFor="all">All Contacts</Label>
                            </div>
                        </RadioGroup>
                    </div>

                    {showProgress && (
                        <div className="space-y-2">
                            <Progress value={progress} />
                        </div>
                    )}
                </div>

                <DialogFooter>
                    <DialogClose asChild>
                        <Button variant="outline">Cancel</Button>
                    </DialogClose>
                    <Button onClick={handleExport} disabled={isExporting}>
                        {isExporting ? 'Exporting...' : 'Export'}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </SearchParamDialog>
    );
};
