import { FC } from 'react';
import BetaBadge from '../../../components/beta-badge';

export const FeedHeader: FC = () => {
    return (
        <div className="top-0 z-50 w-full p-4 pb-0 flex items-center gap-2 bg-gradient-to-b from-bg-background-secondary/20 to-bg-background-secondary/70">
            <span className="font-medium text-2xl">Actions</span>
            <BetaBadge featureName="actions-feed" />
        </div>
    );
};
