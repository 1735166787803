import { FC } from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@shadcn/ui/components/ui/card';
import { Button } from '@shadcn/ui/components/ui/button';
import { PlanCode, SubscriptionDetailsDto, SubscriptionStatus } from '@zaplify/subscriptions';
import { readableBillingFrequencyUnit, toUpperCase } from '../../../../../helpers/text';
import { getPriceReadableFormat } from '../../../../../helpers/currency';

interface SubscriptionDetailsProps {
    subscription: SubscriptionDetailsDto;
    isLockedFromSelfService: boolean;
    onUpgradePlan: () => void;
    onCancelPlan: () => void;
    onRenewPlan: () => void;
}

export const SubscriptionDetails: FC<SubscriptionDetailsProps> = ({
    subscription,
    isLockedFromSelfService,
    onUpgradePlan,
    onCancelPlan,
    onRenewPlan,
}) => {
    const isNonRenewing = subscription.subscription?.status === SubscriptionStatus.NON_RENEWING;
    const {
        currentPlanCode,
        isEnterprise,
        subscriptionData,
        calculatedBillingFrequencyUnit,
        totalPricePerPerMonth,
        totalNetPricePerMonth,
        vatTotal,
        renewalAt,
    } = useSubscriptionDetails(subscription, isNonRenewing);

    // TODO: Fix the number of active users on the free plan
    return (
        <Card>
            <CardHeader>
                <CardTitle>Your Subscription</CardTitle>
                <CardDescription>Manage your subscription and billing details</CardDescription>
            </CardHeader>
            <CardContent>
                <div className="flex flex-col gap-4">
                    <div className="flex items-center justify-between">
                        <div className="flex flex-col gap-1">
                            <span className="text-sm text-text-tertiary">Current Plan</span>
                            <span className="font-medium">
                                {(subscriptionData.planCode === 'team' && 'Team') ||
                                    (subscriptionData.planCode === 'basic' && 'Pro') ||
                                    (subscriptionData.planCode === 'enterprise' && 'Enterprise') ||
                                    subscriptionData.planName}
                            </span>
                            <span className="text-sm text-text-tertiary">
                                <span className="font-medium">{subscription.subscription.totalSeats} users</span> (
                                {subscription.subscription.totalUsers} active)
                            </span>
                        </div>
                        <div className="flex flex-col gap-1 text-right">
                            {isEnterprise ? (
                                <span className="font-medium">Custom price</span>
                            ) : (
                                <>
                                    <span className="text-sm text-text-tertiary">Price</span>
                                    <span className="font-medium">
                                        {totalNetPricePerMonth} / {toUpperCase(calculatedBillingFrequencyUnit)}
                                    </span>
                                    <span className="text-xs text-text-tertiary">Excl. {vatTotal} VAT</span>
                                </>
                            )}
                        </div>
                    </div>

                    {renewalAt && currentPlanCode !== PlanCode.FREE && !isNonRenewing && (
                        <div className="flex items-center justify-between">
                            <div className="flex flex-col gap-1">
                                <span className="text-sm text-text-tertiary">Next renewal</span>
                                <span className="font-medium">{renewalAt}</span>
                            </div>
                        </div>
                    )}
                    {isNonRenewing && (
                        <div className="flex items-center justify-between">
                            <div className="flex flex-col gap-1">
                                <span className="text-sm text-text-tertiary">
                                    Your current subscription will expire{' '}
                                    {renewalAt ? (
                                        <>
                                            on <b>{renewalAt}</b>
                                        </>
                                    ) : (
                                        'soon'
                                    )}
                                </span>
                                <span className="font-medium">Changed your mind?</span>
                                {!isLockedFromSelfService && <Button onClick={onRenewPlan}>Renew Plan</Button>}
                            </div>
                        </div>
                    )}
                    {!isNonRenewing && (
                        <div className="flex items-center gap-2">
                            {!isLockedFromSelfService && <Button onClick={onUpgradePlan}>Upgrade Plan</Button>}
                            {!isLockedFromSelfService && !isNonRenewing && currentPlanCode !== PlanCode.FREE && (
                                <Button variant="outline" onClick={onCancelPlan}>
                                    Cancel Plan
                                </Button>
                            )}
                        </div>
                    )}
                    {isLockedFromSelfService && (
                        <p className="text-sm text-text-tertiary">
                            Contact your account manager to make changes to your subscription
                        </p>
                    )}
                </div>
            </CardContent>
        </Card>
    );
};

const useSubscriptionDetails = (subscriptionDetails: SubscriptionDetailsDto, isNonRenewing: boolean) => {
    const currentPlanCode = subscriptionDetails?.subscription?.current?.planCode;
    const isEnterprise = !subscriptionDetails.subscription.scheduled && currentPlanCode === PlanCode.ENTERPRISE;
    const subscriptionData =
        isEnterprise || isNonRenewing
            ? subscriptionDetails.subscription.current
            : subscriptionDetails.subscription.scheduled;
    const calculatedBillingFrequencyUnit = readableBillingFrequencyUnit(
        subscriptionData.periodAmount,
        subscriptionData.periodUnit
    );

    const totalPricePerPerMonth =
        !isEnterprise && subscriptionDetails.invoices?.next
            ? getPriceReadableFormat({
                  price: Number(subscriptionDetails.invoices.next.price) / 100,
                  billingCurrency: subscriptionDetails.invoices.next.currency,
              })
            : 0;

    const totalNetPricePerMonth =
        !isEnterprise && subscriptionDetails.invoices?.next
            ? getPriceReadableFormat({
                  price: Number(subscriptionDetails.invoices.next.price_net) / 100,
                  billingCurrency: subscriptionDetails.invoices.next.currency,
              })
            : 0;
    const vatTotal =
        !isEnterprise && subscriptionDetails.invoices?.next
            ? getPriceReadableFormat({
                  price:
                      Number(subscriptionDetails.invoices.next.price - subscriptionDetails.invoices.next.price_net) /
                      100,
                  billingCurrency: subscriptionDetails.invoices.next.currency,
              })
            : 0;

    const hasRenewalDate = subscriptionDetails.subscription?.renewalAt;
    const renewalDate = hasRenewalDate && new Date(subscriptionDetails.subscription?.renewalAt);
    const renewalAt =
        renewalDate &&
        renewalDate.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });

    return {
        currentPlanCode,
        isEnterprise,
        subscriptionData,
        calculatedBillingFrequencyUnit,
        totalPricePerPerMonth,
        totalNetPricePerMonth,
        vatTotal,
        renewalAt,
    };
};
