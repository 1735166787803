import { RequireAtLeastOne, getCountryFromCountryCode, sleep } from '@zaplify/utils';
import {
    CompanyParsed,
    ExperienceEnrichedParsed,
    GetProfileErrorReasons,
    getZaplifySdk,
    InvitationSendResponse,
    ProfileFullWithExperienceParsed,
    ProspectDataWithConnectionStatus,
    RegularProfileLI,
    WebExtensionAction,
} from '@zaplify/sdk';
import {
    ConversationCreateResponse,
    GetConversationsResponse,
    GetMessagesFromConversationResponse,
    GetConnectionsResponse,
    GetPendingInvitationsResponse,
    useWebExtensionCommunication,
} from '@zaplify/sdk';
import { ProspectDataDto, ProspectDataExperienceDto, ProspectLinkedinProfileVerifiedStatus } from '@zaplify/prospects';
import { isPrivateEmail } from '@zaplify/utils';
import { LinkedinPost } from '@zaplify/sdk';
import {
    ConversationMessageParsed,
    LinkedinConversationsResponse,
    LinkedinVoyagerConversationMessage,
} from '@zaplify/web-extension-shared';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { parseConversationMessage } from '@zaplify/web-extension-shared';
import { ConnectionStatus } from '@zaplify/campaigns';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { WebExtensionActions } from '@zaplify/sdk';
import { LinkedinProfileDto, LinkedinProfileExperienceDto } from '@andsend/services/linkedin-profiles/shared';
import { differenceInDays } from 'date-fns';
import { useSdk } from '../sdk/use-sdk';
import dayjs from 'dayjs';
type GetProfileResult =
    | { success: false; reason: GetProfileErrorReasons }
    | {
          success: true;
          prospectData: ProspectDataDto;
          connectionStatus: ConnectionStatus | null;
      };

export const useWebExtension = () => {
    const { useNewExtensionGetConversation } = useFlags();
    const { refreshLinkedinTab, reloadExtension, sendRequest, handleErrors, sendMessageToRuntime } =
        useWebExtensionCommunication();

    const {
        account: { getAccount, createAccount: createAccountMutation },
        linkedinProfiles: {
            getLinkedinProfileByMemberIdOrPublicIdentifier,
            addFoundLinkedinProfile: addFoundLinkedinProfileMutation,
        },
    } = useSdk();
    const { mutateAsync: createAccount } = useMutation(createAccountMutation());
    const { mutateAsync: addFoundLinkedinProfile } = useMutation(addFoundLinkedinProfileMutation());
    const communication = useWebExtensionCommunication();
    const queryClient = useQueryClient();
    const liActions = new WebExtensionActions(queryClient, communication);

    const auth = {
        async loginWithCustomToken(token: string) {
            await sendMessageToRuntime(WebExtensionAction.A__AUTH_LOGIN, { token });
        },
        async logout() {
            await sendMessageToRuntime(WebExtensionAction.A__AUTH_LOGOUT, {});
        },
        async chooseOrganization(organizationId: string) {
            await sendMessageToRuntime(WebExtensionAction.A__AUTH_CHOOSE_ORGANIZATION, { organizationId });
        },
        async getCurrentState() {
            return await sendMessageToRuntime(WebExtensionAction.A__AUTH_GET_CURRENT_STATE, {});
        },
    };

    const getExtensionVersion = async (): Promise<{
        hasExtension: boolean;
        currentVersion?: string | undefined;
        isLoggedIn: boolean | undefined;
    }> => {
        // handle for versions 1 and 2
        // later on CEHandler can be dropped
        if (!window.chrome?.runtime) {
            return { hasExtension: false, isLoggedIn: undefined };
        }
        try {
            const [extensionVersionResult, cookies] = await Promise.all([
                sendMessageToRuntime('GET_EXTENSION_VERSION' as any, {}) as Promise<any>,
                getLinkedinCookies(),
            ]);

            const currentVersion =
                typeof extensionVersionResult === 'string' ? extensionVersionResult : extensionVersionResult?.version;
            const hasExtension = currentVersion ? true : false;
            return {
                hasExtension: hasExtension,
                currentVersion,
                isLoggedIn: Boolean(cookies?.li_at),
            };
        } catch (err) {
            console.warn('Received error from web extension', err);
            return { hasExtension: false, isLoggedIn: undefined };
        }
    };

    const getLinkedinCookies = async (): Promise<{
        li_at: chrome.cookies.Cookie | null;
        all: chrome.cookies.Cookie[];
    }> => {
        try {
            const cookies: chrome.cookies.Cookie[] = await getCookies({ website: 'LinkedIn' });
            return {
                li_at: cookies.find((cookie) => cookie?.name === 'li_at') || null,
                all: cookies,
            };
        } catch (error) {
            console.error('Error while trying to get Linkedin Cookie', error, (error as any)?.message);
            return {
                li_at: null,
                all: [],
            };
        }
    };

    const getCookies = async ({ website }: { website: 'LinkedIn' }): Promise<chrome.cookies.Cookie[]> => {
        try {
            const response = await sendMessageToRuntime<{ cookies: (chrome.cookies.Cookie | null)[] }>(
                WebExtensionAction.GET_COOKIES,
                { website }
            );
            return (response?.cookies.filter((x) => x) as chrome.cookies.Cookie[]) || [];
        } catch (e) {
            console.log('Error getting cookies from extension', e);
            return [];
        }
    };

    const getLinkedInCompany = async (linkedinId: string) => {
        const result = await liActions.getCompany(linkedinId);
        if (result) {
            const parsedResult = liProfileParseCompany(result);
            createAccount({
                accountId: parsedResult.organizationId,
                linkedinUrl: parsedResult.organizationLinkedinUrl,
                linkedinCompanyId: parsedResult.organizationLinkedinId,
                data: {
                    name: result.name,
                    industry: result.industries?.[0],
                    slogan: result.tagline,
                    size: result.size,
                    website: result.websiteUrl,
                    aboutUs: result.description,
                    logoUrl: result.imageUrl,
                    followers: result.followers,
                    locations: result.locations,
                    foundedYear: result.foundedYear,
                },
            }).catch((error) => {
                console.error('Error creating account', error);
            });
            return parsedResult;
        }
        return null;
    };

    async function getLinkedInProfileFull(
        input: RequireAtLeastOne<{ publicIdentifier: string }>
    ): Promise<GetProfileResult>;
    async function getLinkedInProfileFull(input: RequireAtLeastOne<{ url: string }>): Promise<GetProfileResult>;
    async function getLinkedInProfileFull(input: { memberId: string }): Promise<GetProfileResult>;
    async function getLinkedInProfileFull(input: {
        memberId?: string;
        url?: string;
        publicIdentifier?: string;
    }): Promise<GetProfileResult> {
        let publicIdentifier = input.publicIdentifier;
        let memberId = input.memberId;

        let regularProfile: RegularProfileLI | null = null;
        if (!publicIdentifier && memberId) {
            regularProfile = await getLinkedInProfile(memberId);
            publicIdentifier = regularProfile.publicIdentifier;
        }

        if (!publicIdentifier && input.url) {
            const match = input.url.match(/linkedin\.com\/in\/([^\s\/]+)\/?/);
            publicIdentifier = match?.[1];
        }

        console.log('Getting Linkedin Profile: ', publicIdentifier);

        if (!publicIdentifier) {
            throw new Error('Invalid linkedin URL');
        }

        // Check if the profile is already in the backend
        const linkedinProfileFromBackend = await queryClient
            .fetchQuery(getLinkedinProfileByMemberIdOrPublicIdentifier(publicIdentifier))
            .then((profile) => (profile ? linkedinProfileDtoToProspectDataDto(profile) : null));

        let prospectData: ProspectDataDto | null = null;
        let connectionStatus: ConnectionStatus | null = null;
        let foundProspectFromExtension: boolean = false;

        console.log('Linkedin profile BACKEND last checked at: ', linkedinProfileFromBackend?.lastCheckedAt);
        if (
            linkedinProfileFromBackend &&
            dayjs(linkedinProfileFromBackend.lastCheckedAt).isAfter(dayjs().subtract(2, 'months'))
        ) {
            console.log('Using linkedin profile from BACKEND', linkedinProfileFromBackend?.linkedinUserId);

            try {
                if (!regularProfile) {
                    regularProfile = await getLinkedInProfile(publicIdentifier);
                }

                // If the data is 1 day old, use it, else check if there are new experiences
                if (dayjs(linkedinProfileFromBackend.lastCheckedAt).isAfter(dayjs().subtract(1, 'days'))) {
                    prospectData = linkedinProfileFromBackend;
                    connectionStatus = regularProfile.status;
                } else {
                    // @TODO: Enable on next web-extension release'
                    const experiences = await liActions.getLinkedInProfileExperiences(regularProfile.memberId);
                    const hasChangedExperiences =
                        experiences && experiences?.length !== linkedinProfileFromBackend.experiences.length;
                    // const hasChangedExperiences = false;
                    const hasChangedHeadline =
                        linkedinProfileFromBackend.linkedinHeadline?.toLocaleLowerCase() !==
                        regularProfile.headline?.toLocaleLowerCase();
                    const hasDataChanged = hasChangedExperiences || hasChangedHeadline;

                    if (!hasDataChanged) {
                        prospectData = linkedinProfileFromBackend;
                        connectionStatus = regularProfile.status;
                    } else {
                        console.log(
                            'Data has changed, fetching new profile',
                            // experiences,
                            regularProfile,
                            linkedinProfileFromBackend
                        );
                    }
                }
            } catch (error) {
                console.error('Error using backend linkedin profile', error);
            }
        }

        // If not found from backend, or data isn't valid, fetch profile from the web extension
        if (!prospectData) {
            // Get the full profile from the web extension
            const result = await liActions.getLinkedInProfileFull(publicIdentifier);

            console.log('Found Linkedin profile identifier FULL RESULT: ', result);
            if (result.error) {
                if (result.reason === 'profile_unavailable') {
                    return { success: false, reason: 'profile_unavailable' };
                } else {
                    console.warn(
                        `Got unknown error when fetching linkedin profile: ${JSON.stringify(
                            input
                        )}. Error: ${JSON.stringify(result)}`
                    );
                    throw new Error(`Error fetching linkedin profile for prospect: ${JSON.stringify(input)}`);
                }
            }
            foundProspectFromExtension = true;
            prospectData = webExtensionLiProfileToCreateProspect(result);
            connectionStatus = result.connectionStatus;
        }

        // Check which experiences already have a company in the backend
        // const experiencesWithCompany = await enrichProspectData(prospectData);
        // prospectData.experiences = experiencesWithCompany;

        if (foundProspectFromExtension) {
            addFoundLinkedinProfile({
                prospectData: {
                    ...prospectData,
                    linkedinProfileVerification: {
                        status: ProspectLinkedinProfileVerifiedStatus.verified,
                        checkedAt: new Date(),
                    },
                },
            }).catch((error) => {
                console.error('Error adding found linkedin profile', error);
            });
        }
        return {
            success: true,
            prospectData,
            connectionStatus,
        };
    }

    const enrichProspectExperiences = async (prospectData: ProspectDataDto): Promise<ProspectDataExperienceDto[]> => {
        const experiencesWithCompany = await Promise.all(
            prospectData.experiences.map(async (experience) => {
                if (!experience.organizationLinkedinId || !experience.organizationId) {
                    return { ...experience, account: null };
                }

                const account = await queryClient.fetchQuery(
                    getAccount({
                        linkedinCompanyId: experience.organizationLinkedinId,
                        accountId: experience.organizationId,
                    })
                );
                return {
                    ...experience,
                    account,
                };
            })
        );

        const newExperiences: ProspectDataExperienceDto[] = [];
        await Promise.all(
            experiencesWithCompany.map(async (experience, index) => {
                // If company is already in the backend
                if (experience?.account?.data?.name) {
                    newExperiences.push({
                        ...experience,
                        organizationName: experience?.account?.data?.name || experience.organizationName,
                        organizationLinkedinUrl: experience?.account?.linkedinUrl || experience.organizationLinkedinUrl,
                        organizationDomain: experience?.account?.data?.website || experience.organizationDomain,
                        organizationIndustry: experience?.account?.data?.industry || experience.organizationIndustry,
                        organizationId: experience?.account?.accountId || experience.organizationId,
                        organizationLinkedinId:
                            experience?.account?.data?.linkedinId || experience.organizationLinkedinId,
                        organizationSize: experience?.account?.data?.size || experience.organizationSize,
                        organizationImageUrl: experience?.account?.data?.logoUrl || experience.organizationImageUrl,
                        organizationDescription:
                            experience?.account?.data?.aboutUs || experience.organizationDescription,
                    });
                } else {
                    await sleep(index * 200);
                    if (!experience.organizationLinkedinId) {
                        newExperiences.push(experience);
                    } else {
                        const company = await getLinkedInCompany(experience.organizationLinkedinId);
                        newExperiences.push({
                            ...experience,
                            ...company,
                        });
                    }
                }
            })
        );

        if (newExperiences?.length > 0) {
            addFoundLinkedinProfile({
                prospectData: {
                    ...prospectData,
                    experiences: newExperiences,
                    linkedinProfileVerification: {
                        status: ProspectLinkedinProfileVerifiedStatus.verified,
                        checkedAt: new Date(),
                    },
                },
            }).catch((error) => {
                console.error('Error adding found linkedin profile', error);
            });
        }

        return newExperiences;
    };

    const getLinkedInProfile = async (url: string): Promise<RegularProfileLI> => {
        const match = url.match(/linkedin\.com\/in\/([^\s\/]+)\/?/);
        const publicIdentifier = match?.[1] || url;

        console.log('Found Linkedin profile identifier', publicIdentifier);

        if (!publicIdentifier) {
            throw new Error('Invalid linkedin URL');
        }

        const result = await liActions.getLinkedInProfile(publicIdentifier);

        // Sometimes the public identifier isn't returned, so we extract it from the profileUrl as a fallback
        let publicIdentifierParsed: string;
        if (!result.publicIdentifier && result.profileUrl) {
            const match = result.profileUrl.match(/linkedin\.com\/in\/([^\s\/]+)\/?/);
            publicIdentifierParsed = match?.[1];
            // const publicIdentifierParsed = result.profileUrl.match(/linkedin\.com\/in\/([^\s\/]+)\/?/);
            // const result.publicIdentifier = publicIdentifierParsed;
        }

        console.log('Found Linkedin profile identifier RESULT: ', result);
        return {
            publicIdentifier: publicIdentifierParsed,
            ...result,
        };
    };
    /**
     * Gets the last 20 posts from a Linkedin profile
     */
    const getLinkedinPosts = async (memberId: string): Promise<LinkedinPost[]> => {
        const posts = await liActions.getLinkedinPosts(memberId);
        console.log('Found Linkedin posts: ', posts);
        return posts;
    };

    const getMe = () => {
        return liActions.getMe();
    };

    const getMessagesFromConversation = async (payload: {
        memberId: string;
        conversationId: string;
        deliveredAtBeforeTimestamp: number;
        countBefore: number;
        cacheStaleTime: number;
    }): Promise<ConversationMessageParsed[]> => {
        if (useNewExtensionGetConversation) {
            try {
                const messages = await liActions.getMessagesFromConversation({
                    memberId: payload.memberId,
                    conversationId: payload.conversationId,
                    deliveredAt: payload.deliveredAtBeforeTimestamp,
                    countBefore: payload.countBefore,
                    cacheStaleTime: payload.cacheStaleTime,
                });
                return messages || [];
            } catch (error) {
                console.error(
                    `🚀 ~~~ useWebExtension | getting messages from conversations error: ${JSON.stringify(error)}`
                );
            }
        }

        console.log('Track Linkedin: Sending request: ', WebExtensionAction.GET_MESSAGES_FROM_CONVERSATION);
        const result = await sendRequest<GetMessagesFromConversationResponse>(
            WebExtensionAction.GET_MESSAGES_FROM_CONVERSATION,
            {
                memberId: payload.memberId,
                conversationId: payload.conversationId,
                deliveredAt: payload.deliveredAtBeforeTimestamp,
                countBefore: payload.countBefore,
            }
        );

        const parsedResult: ConversationMessageParsed[] = [];

        if (result?.messengerMessagesByAnchorTimestamp) {
            const elements = result?.messengerMessagesByAnchorTimestamp?.elements;

            elements.forEach((message) => {
                const parsedMessage = parseConversationMessage(
                    message as LinkedinVoyagerConversationMessage,
                    payload.memberId
                );
                if (!parsedMessage) return;
                parsedResult.push(parsedMessage);
            });
        }
        return parsedResult;
    };

    const sendInvitation = async (payload: {
        prospectMemberId: string;
        messageContent?: { text: string };
        isUserPremium?: boolean /** Skips checking if max CR with messages sent */;
    }): Promise<InvitationSendResponse> => {
        return await liActions.sendInvitation(payload);
    };

    const sendMessage = async (payload: {
        memberId: string;
        prospectMemberId: string;
        messageContent: {
            text: string;
        };
    }): Promise<ConversationCreateResponse> => {
        return await liActions.sendMessage(payload);
    };

    const getConversations = async (payload: {
        memberId: string;
        /** Date */
        since?: number;
        /** MinPageSize */
        moreThan?: number;
        /** Cursor for pagination (oldestConversationLastEventTime) */
        nextCursor?: string;
        /** Search keywords */
        keywords?: string;
        /** Cache stale time */
        cacheStaleTime: number;
    }): Promise<GetConversationsResponse> => {
        return await liActions.getConversations(payload);
    };

    const getConversationsNew = async (payload: {
        userMemberId: string;
        since?: number;
        startingCursor?: string;
        cacheStaleTime: number;
    }): Promise<LinkedinConversationsResponse> => {
        return await liActions.getConversationsNew(payload);
    };

    const getConversationFromId = async (payload: {
        memberId: string;
        prospectMemberIds: string[];
        personData: {
            firstName?: string;
            lastName?: string;
            memberId: string;
        };
    }): Promise<GetConversationsResponse> => {
        const result = await liActions.getConversationFromId(payload);
        return result;
    };

    const getAcceptedConnections = async ({ since }: { since: number }): Promise<GetConnectionsResponse> => {
        return await liActions.getAcceptedConnections(since);
    };

    const searchConnections = async ({
        keyword,
        size,
        from,
    }: {
        keyword?: string;
        size: number;
        from: number;
    }): Promise<GetConnectionsResponse> => {
        return await liActions.searchConnections({ keyword, size, from });
    };

    //TODO: Figure out what it returns and type it
    const getPendingInvitations = async (payload: {
        since: number;
    }): Promise<GetPendingInvitationsResponse | { error: string }> => {
        return await liActions.getPendingInvitations(payload.since);
    };

    return {
        auth,
        getLinkedinCookies,
        getLinkedInProfileFull,
        getLinkedInProfile,
        getLinkedinPosts,
        getMessagesFromConversation,
        enrichProspectExperiences,
        reloadExtension,
        sendInvitation,
        getMe,
        sendMessage,
        getConversations,
        getConversationsNew,
        getConversationFromId,
        getAcceptedConnections,
        searchConnections,
        getExtensionVersion,
        getPendingInvitations,
    };
};

const getDomainFromUrl = (url?: string) => {
    if (!url) return undefined;
    const extractDomainRegex = /^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/\n]+)/im;
    const domain = url?.match(extractDomainRegex)?.[1];
    return domain;
};

const webExtensionLiProfileToCreateProspect = (profile: ProfileFullWithExperienceParsed): ProspectDataDto => {
    let topExperience = profile.experiences?.[0];

    if (profile.topPosition?.companyName) {
        const topPositionExperience = profile.experiences.find(
            (experience) =>
                experience.companyName === profile.topPosition.companyName &&
                experience.startDate == profile.topPosition.startDate &&
                experience.endDate == profile.topPosition.endDate
        );
        if (topPositionExperience) {
            topExperience = topPositionExperience;
        } else {
            topExperience = {
                companyName: profile.topPosition.companyName,
                startDate: profile.topPosition.startDate,
                endDate: profile.topPosition.endDate,
                title: profile.headline,
                companyId: profile.topPosition.id,
                description: null,
            };
        }
    }

    // Important to default to undefined and not null – otherwise we overwrite existing email addresses with "null" on the backend
    const email = isPrivateEmail(profile.contactInfo?.emailAddress || profile.conatctInfo?.emailAddress)
        ? undefined
        : profile.contactInfo?.emailAddress || profile.conatctInfo?.emailAddress || undefined;

    return {
        firstName: profile.firstName,
        lastName: profile.lastName,
        fullName: profile.firstName + ' ' + (profile.lastName || ''),

        linkedinProfileUrl: profile.profileUrl,
        linkedinProfileImgUrl: profile.profilePicture,
        linkedinUserId: profile.memberId, // must not exist for the sync and backfill to happen
        linkedinNumberOfConnections: profile.numberOfConnections,
        linkedinHeadline: profile.headline,
        linkedinDescription: profile.aboutSummary,
        linkedinProfileVerification: {
            status: profile.memberId
                ? ProspectLinkedinProfileVerifiedStatus.verified
                : ProspectLinkedinProfileVerifiedStatus.profile_not_found,
            checkedAt: new Date(),
        },

        location: profile?.location || getCountryFromCountryCode(profile.countryCode) || undefined,

        // @REVIEW – We will overwrite selected main experience here as well right. Should prob not be overwriting if user selected main position
        occupationTitle: topExperience?.title || profile.headline,
        organizationIndustry: topExperience?.company?.industries?.[0] || undefined,
        organizationId: topExperience?.company?.universalName || topExperience?.companyPublicIdentifier,
        organizationLinkedinId: topExperience?.company?.id,
        organizationName: topExperience?.company?.name || topExperience?.companyName,
        organizationSize: topExperience?.company?.size || undefined,
        organizationDomain: getDomainFromUrl(topExperience?.company?.websiteUrl),
        organizationLinkedinUrl:
            topExperience?.company?.universalName &&
            'https://www.linkedin.com/company/' + topExperience?.company?.universalName,

        experiences: profile.experiences?.map((experience) =>
            liProfileExperienceToCreateProspectExperience(experience)
        ),

        email: email,
        phoneNumbers: profile.contactInfo?.phoneNumbers?.length ? profile.contactInfo?.phoneNumbers : undefined,
    };
};

const linkedinProfileDtoToProspectDataDto = (
    profile: LinkedinProfileDto
): ProspectDataDto & { lastCheckedAt: Date } => {
    const getCurrentExperience = (): LinkedinProfileExperienceDto | undefined => {
        return profile.experiences?.find((experience) => experience.isCurrent) || profile.experiences?.[0];
    };
    return {
        lastCheckedAt: profile.lastChecked,
        firstName: profile.firstName,
        lastName: profile.lastName,
        fullName: profile.firstName + ' ' + (profile.lastName || ''),
        linkedinProfileUrl: `https://www.linkedin.com/in/${profile.publicIdentifier ?? profile.memberId}`,
        linkedinProfileImgUrl: profile.profileImageUrl,
        linkedinUserId: profile.memberId,
        linkedinNumberOfConnections: profile.numberOfConnections,
        linkedinHeadline: profile.headline,
        linkedinDescription: profile.personalSummary,
        linkedinProfileVerification: {
            status: ProspectLinkedinProfileVerifiedStatus.verified,
            checkedAt: profile.lastChecked,
        },
        location: profile.locationString,
        occupationTitle: getCurrentExperience()?.jobTitle,
        occupationStartDate: getCurrentExperience()?.jobStartDate,

        organizationName: getCurrentExperience()?.companyName,
        organizationIndustry: getCurrentExperience()?.companyIndustry,
        organizationId: getCurrentExperience()?.companyPublicIdentifier,
        organizationLinkedinId: getCurrentExperience()?.companyId,
        organizationSize: getCurrentExperience()?.companySizeRange,
        organizationDomain: getDomainFromUrl(getCurrentExperience()?.companyWebsite),
        organizationLinkedinUrl: `https://www.linkedin.com/company/${getCurrentExperience()?.companyPublicIdentifier}`,
        experiences: profile.experiences?.map((experience) => {
            return {
                id: experience.companyId,
                organizationName: experience.companyName,
                organizationId: experience.companyPublicIdentifier,
                organizationLinkedinId: experience.companyId,
                organizationLinkedinUrl: `https://www.linkedin.com/company/${experience.companyPublicIdentifier}`,
                organizationDomain: getDomainFromUrl(experience.companyWebsite),
                organizationIndustry: experience.companyIndustry,
                organizationSize: experience.companySizeRange,
                occupationTitle: experience.jobTitle,
                occupationStartDate: experience.jobStartDate,
                occupationEndDate: experience.jobEndDate,
                occupationDescription: experience.summary,
            };
        }),
        email: profile.email,
        phoneNumbers: profile.phoneNumbers,
        interests: profile.interests,
        skills: profile.skills,
        education: profile.education,
    };
};

const liProfileDateParser = (date: { month: number; year: number }) => {
    if (!date) return null;
    return new Date(date.year, date.month - 1);
};

const liProfileParseCompany = (company: CompanyParsed) => {
    return {
        id: company.universalName,
        organizationName: company.name,
        organizationLinkedinUrl: 'https://www.linkedin.com/company/' + company.universalName,
        organizationDomain: getDomainFromUrl(company.websiteUrl),
        organizationIndustry: company.industries?.[0],
        organizationId: company.universalName,
        organizationLinkedinId: company.id,
        organizationSize: company?.size,
        organizationImageUrl: undefined,
        organizationDescription: company.description,
    };
};

const liProfileExperienceToCreateProspectExperience = (
    experience: ExperienceEnrichedParsed
): ProspectDataExperienceDto => {
    if (experience.company) {
        return {
            ...liProfileParseCompany(experience.company),
            occupationTitle: experience.title,
            occupationStartDate: liProfileDateParser(experience.startDate)?.toDateString(),
            occupationEndDate: liProfileDateParser(experience.endDate)?.toDateString(),
            occupationDescription: experience.description,
        };
    } else {
        return {
            id: '',
            organizationName: experience.companyName,
            organizationLinkedinUrl: undefined,
            organizationDomain: undefined,
            organizationIndustry: undefined,
            organizationId: experience.companyPublicIdentifier,
            organizationLinkedinId: experience.companyId,
            organizationImageUrl: experience.imageUrl,
            organizationDescription: experience.description,
            organizationSize: undefined,
            occupationTitle: experience.title,
            occupationStartDate: liProfileDateParser(experience.startDate)?.toDateString(),
            occupationEndDate: liProfileDateParser(experience.endDate)?.toDateString(),
            occupationDescription: experience.description,
        };
    }
};
