import { CircularProgress } from '@mui/material';
import { useAtom } from 'jotai';
import { SearchTypes, peopleDataAtom, peopleLoadingAtom } from '../../../atoms/globalSearch';
import CategoryExpandShrink from '../CategoryShowMore';
import { PeopleCard } from '../PeopleCard/PeopleCard';
import ProspectRow from '../ProspectRow';
import { SearchSectionTitle } from '../globalSearchPopup';
import { useGlobalSearch } from '../hooks/GlobalSearch';
import { useAccount } from '../hooks/Account';

export const PeopleSection = ({
    groups,
    searchValue,
    onClick,
    onClickAway,
    userId,
}: {
    groups: { id: string; name: string }[];
    searchValue: string;
    onClick: (node: React.ReactNode) => void;
    onClickAway: () => void;
    userId: string;
}) => {
    const globalSearch = useGlobalSearch();
    const { onAddPerson } = useAccount();
    const [people, setPeople] = useAtom(peopleDataAtom);
    const [loading, setLoading] = useAtom(peopleLoadingAtom);
    const pagination = people?.pagination;

    const numberOfPeople = people?.data?.length;

    const handleShowMore = async () => {
        try {
            setLoading(true);

            const query = {
                match: searchValue,
                perPage: 5,
                page: pagination.page + 1,
                searchIn: [SearchTypes.PEOPLE],
            };

            const response = await globalSearch.search(query);

            setPeople((prev) => ({
                data: [...prev.data, ...response?.people?.data],
                pagination: response?.people?.pagination,
            }));
        } finally {
            setLoading(false);
        }
    };

    if (numberOfPeople === 0) return null;
    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <SearchSectionTitle title="People" size="12px" toolTip="People that are not in your contacts" />
                {!people?.data && loading && <CircularProgress size="12px" style={{ marginLeft: '8px' }} />}
            </div>
            {people?.data.map((person) => (
                <div key={person._id}>
                    <ProspectRow
                        prospect={{
                            _id: person._id,
                            fullName: person.fullName,
                            organizationName: person.companyName,
                            firstName: person.fullName.split(' ')[0],
                            lastName: person.fullName.split(' ')[1],
                            title: person.title,
                        }}
                        owners={person.assignedTo}
                        userId={userId}
                        searchValue={searchValue}
                        onClickAway={onClickAway}
                        onClick={() =>
                            onClick(
                                <PeopleCard
                                    person={person}
                                    groups={groups}
                                    userId={userId}
                                    handleAddPerson={async (groupId?: string) => onAddPerson(person?._id, groupId)}
                                />
                            )
                        }
                    />
                </div>
            ))}
            {numberOfPeople === pagination?.limit * pagination?.page && (
                <CategoryExpandShrink
                    page={pagination.page}
                    numberOfPages={pagination.numberOfPages}
                    loading={loading}
                    numberOfRows={numberOfPeople}
                    onShowMore={() => handleShowMore()}
                />
            )}
        </div>
    );
};
