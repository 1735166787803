import { getZaplifySdk } from '@zaplify/sdk';
import { createContext, useCallback, useContext, useEffect, useState, useRef } from 'react';
import useLinkedinTrackerUpdates from './use-tracker-updates';
import { useAuth } from '../providers/authentication-provider';
import { useLinkedin } from './use-linkedin';
import { useProspectSync } from './use-prospect-sync';
import { sleep } from '@zaplify/utils';

function log(message: string) {
    console.log(`🚀 [useChatsSync] | ${message}`);
}

function logError(message: string) {
    console.error(`🚀 [useChatsSync] | ${message}`);
}

type ChatsSyncContextType = {
    setPingTrackersStatus: React.Dispatch<
        React.SetStateAction<{
            status: 'active' | 'paused';
            timestamp: number | null;
        }>
    >;
};

// TODO: Feature flag this and the other one so they don't run at the same time

const ChatsSyncContext = createContext<ChatsSyncContextType | undefined>(undefined);

export function ChatsSyncProvider({ children }: { children: React.ReactNode }) {
    const [pingTrackerStatus, setPingTrackersStatus] = useState<{
        status: 'active' | 'paused';
        timestamp: number | null;
    }>({
        status: 'active',
        timestamp: null,
    });
    const {
        authState: { isImpersonating, isLoggedIn },
    } = useAuth();
    const { extensionStatus, linkedinAccount } = useLinkedin();
    const { syncLinkedinInbox } = useLinkedinTrackerUpdates();
    const { runBackgroundJobs } = useProspectSync();
    const intervalRef = useRef<NodeJS.Timeout>();

    useEffect(() => {
        if (!isLoggedIn) {
            return;
        }
        console.log('Running background jobs');
        runBackgroundJobs();
    }, [linkedinAccount?.LINKEDIN?.userId, isLoggedIn]);

    const syncEmailInbox = async () => {
        if (!isLoggedIn) {
            console.log('User not logged in, skipping syncEmailInbox');
            return;
        }
        try {
            const trackersSdk = getZaplifySdk().profiles.trackers;
            const isPaused = pingTrackerStatus.status === 'paused';
            const timeSinceLastPing = Date.now() - (pingTrackerStatus.timestamp || 0);
            const shouldSkipPing = isPaused && (timeSinceLastPing < 1000 || !pingTrackerStatus.timestamp);

            if (shouldSkipPing) {
                log('Skipping syncEmailInbox due to conditions');
                return;
            }

            setPingTrackersStatus({
                status: 'paused',
                timestamp: Date.now(),
            });

            await trackersSdk.syncEmail();

            setPingTrackersStatus({
                status: 'active',
                timestamp: Date.now(),
            });
        } catch (error) {
            logError(`Error in syncEmailInbox: ${JSON.stringify(error)}`);
            setPingTrackersStatus({
                status: 'active',
                timestamp: Date.now(),
            });
        }
    };

    const checkImpersonationAndSync = useCallback(async () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = undefined;
        }

        try {
            log('checkImpersonationAndSync called');
            if (extensionStatus !== 'CONNECTED') {
                log('Skipping checkImpersonationAndSync due to channelAccounts.linkedIn.status === "LOADING"');
                return;
            }

            setPingTrackersStatus({
                status: 'paused',
                timestamp: Date.now(),
            });
            syncLinkedinInbox();
            syncEmailInbox(); // @TODO Is this really dependent on the extension? Should probably be moved to before the extension status check!

            const interval = setInterval(() => {
                const trackersPausedLessThanOneSecondAgo = Date.now() - (pingTrackerStatus.timestamp || 0) < 1000;
                const timeout = (pingTrackerStatus.timestamp || Date.now()) + 30 * 1000;
                if (
                    (pingTrackerStatus.status === 'paused' || trackersPausedLessThanOneSecondAgo) &&
                    timeout > Date.now()
                ) {
                    log('Skipping checkImpersonationAndSync due to conditions');
                    return;
                }

                syncEmailInbox();
                setTimeout(() => {
                    syncLinkedinInbox();
                }, 8000 * Math.random());
            }, 1000 * 66);

            intervalRef.current = interval;

            return () => {
                log('checkImpersonationAndSync cleanup');
                clearInterval(interval);
                intervalRef.current = undefined;
            };
        } catch (error) {
            logError(`Error in checkImpersonationAndSync: ${JSON.stringify(error)}`);
        }
    }, [extensionStatus, pingTrackerStatus.status, isImpersonating, isLoggedIn]);

    useEffect(() => {
        if (isImpersonating) {
            console.log('Global admin detected, skipping prospect sync');
            return;
        }

        if (!isLoggedIn) {
            console.log('User not logged in, skipping prospect sync');
            return;
        }
        checkImpersonationAndSync();
    }, [extensionStatus, isLoggedIn]);

    return (
        <ChatsSyncContext.Provider
            value={{
                setPingTrackersStatus,
            }}
        >
            {children}
        </ChatsSyncContext.Provider>
    );
}

export function useChatsSync() {
    const context = useContext(ChatsSyncContext);
    if (context === undefined) {
        throw new Error('useChatsSync must be used within a ChatsSyncProvider');
    }
    return context;
}
