import { useQuery } from '@apollo/client';
import { GET_PLAYBOOK_BY_GROUP_ID } from '@zaplify/graphql';
import { FC, useMemo, useEffect, useState, useCallback, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { usePlaybookImages } from '../../../hooks/use-playbook-images';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ChevronRight, Target, Users, FileText, Check } from 'lucide-react';
import { usePlaybookForm } from '../../../hooks/use-playbook-form';
import { paths } from '../../../../../../routes/paths';
import { Button } from '@shadcn/ui/components/ui/button';
import { ChannelType } from '@zaplify/channel-accounts/shared';
import { useSdk } from '../../../../../sdk/use-sdk';
import { useMutation, useQuery as useQueryTan } from '@tanstack/react-query';
import { Template, usePlaybookTemplates } from '../../../hooks/use-playbook-templates';
import { useToast } from '@shadcn/ui/hooks/use-toast';
import { LinkedinProfileDto } from '@andsend/services/linkedin-profiles/shared';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@shadcn/ui/components/ui/card';
import { Tabs, TabsContent } from '@shadcn/ui/components/ui/tabs';
import { PurposeStepContent } from './purpose-step-content';
import { ContactSelection } from './contact-selection';
import { CustomInstructionsSection } from './custom-instructions-section';

const STEPS = {
    PURPOSE: 0,
    INSTRUCTIONS: 1,
    CONTACTS: 2,
};

export const MultiStepPlaybookForm: FC = () => {
    const navigate = useNavigate();
    const { groupId } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const template: Template | undefined = searchParams.get('template') as Template | undefined;
    const formRef = useRef<HTMLFormElement>(null);

    const stepParam = parseInt(searchParams.get('step') || '1', 10);
    const currentStep = Math.max(0, stepParam - 1);

    const { getTemplate } = usePlaybookTemplates();
    const { toast } = useToast();
    const {
        playbook: { generatePlaybookContext: generatePlaybookContextMutation, suggestTargetGroupAndPurpose },
    } = useSdk();
    const { mutateAsync: generatePlaybookContext } = useMutation(generatePlaybookContextMutation());

    const [isLoading, setIsLoading] = useState(false);
    const [suggestedContacts, setSuggestedContacts] = useState<LinkedinProfileDto[]>([]);
    const [existingUserContactIds, setExistingUserContactIds] = useState<string[]>([]);

    const { unusedImgSrc } = usePlaybookImages();
    const { data: playbookData, loading: queryLoading } = useQuery(GET_PLAYBOOK_BY_GROUP_ID, {
        variables: { groupId },
        skip: !groupId,
    });
    const playbook = useMemo(() => playbookData?.Groups[0], [playbookData]);
    const { formSchema, savePlaybook, moveContactsToGroup, addContactsToGroup } = usePlaybookForm(
        groupId,
        playbook?.AssistantSetting?.id
    );

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            name: 'New playbook',
            purpose: '',
            targetGroup: '',
            tone: '',
            languages: ['English'],
            defaultChannel: ChannelType.LINKEDIN,
            context: '',
            default: false,
            imgSrc: unusedImgSrc,
        },
    });
    const { control, watch, handleSubmit, formState, reset, setValue } = form;

    const { data: targetGroupAndPurposeData } = useQueryTan(suggestTargetGroupAndPurpose());

    const {
        contactSuggestions: { getRecommendationsForPurpose },
    } = useSdk();

    const purposeVal = watch('purpose');
    const targetGroupVal = watch('targetGroup');

    useEffect(() => {
        if (currentStep === STEPS.INSTRUCTIONS && !watch('context')) {
            if (watch('purpose')) {
                setIsLoading(true);
                generateCustomInstruction(watch('purpose'), watch('targetGroup'))
                    .then((instruction) => {
                        setValue('context', instruction);
                        setIsLoading(false);
                    })
                    .catch((error) => {
                        console.error('Failed to generate instructions:', error);
                        setIsLoading(false);
                        toast({
                            title: 'Generation failed',
                            description: 'Failed to generate custom instructions. Please try again or enter your own.',
                            variant: 'destructive',
                        });
                    });
            }
        }
    }, [currentStep]);

    const generateCustomInstruction = async (purpose: string, targetGroup: string): Promise<string> => {
        const generatedContext = await generatePlaybookContext({
            currentContext: watch('context'),
            feedback: `Generate custom instructions to achieve the following purpose: ${purpose} for this target group: ${targetGroup}`,
        });
        return generatedContext;
    };

    useEffect(() => {
        if (playbook && !formState.isDirty) {
            const defaultChannel =
                playbook.AssistantSetting.defaultChannel === ChannelType.EMAIL
                    ? ChannelType.EMAIL
                    : ChannelType.LINKEDIN;
            reset(
                {
                    name: playbook.name ?? 'New playbook',
                    purpose: playbook.AssistantSetting.purpose ?? '',
                    targetGroup: playbook.AssistantSetting.targetGroup ?? '',
                    tone: playbook.AssistantSetting.tone ?? '',
                    languages: playbook.AssistantSetting.languages ?? [],
                    defaultChannel,
                    context: playbook.AssistantSetting.context ?? '',
                    default: playbook.default ?? false,
                    imgSrc: playbook.imgSrc ?? unusedImgSrc,
                },
                {
                    keepDirty: false,
                    keepValues: false,
                }
            );
        }
    }, [playbook]);

    useEffect(() => {
        if (template) {
            const templateValues = getTemplate(template);
            reset({
                name: templateValues.name,
                purpose: templateValues.purpose,
                tone: templateValues.tone,
                context: templateValues.context,
                imgSrc: templateValues.preview.imageSrc,
                languages: ['English'],
                defaultChannel: ChannelType.LINKEDIN,
                default: false,
            });
        }
    }, [template]);

    useEffect(() => {
        if (unusedImgSrc && !watch('imgSrc')) {
            setValue('imgSrc', unusedImgSrc);
        }
    }, [unusedImgSrc]);

    const {
        data: recommendations,
        isLoading: isLoadingRecommendations,
        refetch: refetchRecommendations,
    } = useQueryTan(
        getRecommendationsForPurpose(purposeVal, targetGroupVal, {
            enabled: false,
        })
    );

    const filteredRecommendations = useMemo(() => {
        return recommendations ? recommendations?.filter((recommendation) => !!recommendation?.memberId) : [];
    }, [recommendations]);

    const [hasFetchedRecommendations, setHasFetchedRecommendations] = useState(false);

    useEffect(() => {
        if (currentStep > STEPS.PURPOSE && purposeVal && !hasFetchedRecommendations) {
            setHasFetchedRecommendations(true);
            refetchRecommendations();
        }
    }, [currentStep, purposeVal, targetGroupVal, hasFetchedRecommendations, refetchRecommendations]);

    useEffect(() => {
        setHasFetchedRecommendations(false);
    }, [purposeVal, targetGroupVal]);

    const selectedContactsCount = useMemo(() => {
        return suggestedContacts.length + existingUserContactIds.length;
    }, [suggestedContacts, existingUserContactIds]);

    const handleSuggestedContactsChange = useCallback((contacts: LinkedinProfileDto[]) => {
        setSuggestedContacts(contacts);
    }, []);

    const handleExistingContactsChange = useCallback((contacts: string[]) => {
        setExistingUserContactIds(contacts);
    }, []);

    const onSubmit = async (values: z.infer<typeof formSchema>) => {
        try {
            const playbook = await savePlaybook(values);
            existingUserContactIds.length > 0 &&
                moveContactsToGroup(playbook.id, existingUserContactIds).catch((error) => {
                    console.error('Failed to move contacts to group:', error);
                    toast({
                        title: 'Failed to move contacts to group',
                        description: 'Please try again or contact support',
                        variant: 'destructive',
                    });
                });
            suggestedContacts.length > 0 &&
                addContactsToGroup(playbook.id, suggestedContacts).catch((error) => {
                    console.error('Failed to add contacts to group:', error);
                    toast({
                        title: 'Failed to add contacts to group',
                        description: 'Please try again or contact support',
                        variant: 'destructive',
                    });
                });
            navigate('/new' + paths.PLAYBOOKS);
        } catch (error) {
            console.error('Failed to save playbook:', error);
            const formErrors = formState.errors;
            const errorMessages = Object.entries(formErrors)
                .map(([field, error]) => `${field}: ${error?.message}`)
                .join('\n');

            toast({
                title: 'Form Validation Failed',
                description: errorMessages || 'Please check all required fields are filled correctly',
                variant: 'error',
            });
        }
    };

    const handleStepChange = (step: number) => {
        if (step > currentStep) {
            if (currentStep === STEPS.PURPOSE && !watch('purpose')) {
                toast({
                    title: 'Purpose required',
                    description: 'Please enter a purpose before proceeding to the next step',
                    variant: 'destructive',
                });
                return;
            }
        }
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set('step', (step + 1).toString());
        setSearchParams(newSearchParams, { replace: true });
    };

    const handleFormSubmit = () => {
        form.handleSubmit(onSubmit, (errors) => {
            const errorMessages = Object.entries(errors)
                .map(([field, error]) => error?.message)
                .join(', ');

            toast({
                title: 'Please add all required information:',
                description: errorMessages || 'Please check all required fields are filled correctly',
                variant: 'destructive',
                duration: 10000,
            });
        })();
    };

    useEffect(() => {
        if (!searchParams.has('step')) {
            const newSearchParams = new URLSearchParams(searchParams);
            newSearchParams.set('step', '1');
            setSearchParams(newSearchParams, { replace: true });
        }
    }, []);

    if (queryLoading) {
        return (
            <div className="flex justify-center items-center p-4">
                <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-primary"></div>
            </div>
        );
    }

    return (
        <div className="flex flex-col min-h-screen">
            <div className="container max-w-5xl mx-auto py-8 px-4 flex-grow">
                <PlaybookFormHeader />
                <div className="flex items-start gap-8 flex-col md:flex-row">
                    <StepNavigation currentStep={currentStep} handleStepChange={handleStepChange} />

                    <div className="flex-1 w-full">
                        <Card className="w-full mb-8">
                            <form
                                ref={formRef}
                                className="w-full"
                                id="playbook-form"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                }}
                            >
                                <Tabs value={`step-${currentStep + 1}`} className="w-full">
                                    <TabsContent value="step-1" className="m-0">
                                        <CardHeader>
                                            <CardTitle className="text-2xl">Define the purpose</CardTitle>
                                            <CardDescription>
                                                Describe who you want to reach out to and why
                                            </CardDescription>
                                        </CardHeader>
                                        <CardContent>
                                            <PurposeStepContent
                                                purpose={watch('purpose')}
                                                targetGroup={watch('targetGroup')}
                                                name={watch('name')}
                                                setValue={setValue}
                                                targetGroupAndPurposeData={targetGroupAndPurposeData}
                                            />
                                        </CardContent>
                                    </TabsContent>

                                    <TabsContent value="step-2" className="m-0">
                                        <CardHeader>
                                            <CardTitle className="text-2xl">Custom Instructions</CardTitle>
                                            <CardDescription>
                                                Provide guidance for your agent to follow when creating messages to the
                                                contacts assigned to this playbook
                                            </CardDescription>
                                        </CardHeader>
                                        <CardContent>
                                            <CustomInstructionsSection
                                                form={form}
                                                isLoading={isLoading}
                                                setIsLoading={setIsLoading}
                                            />
                                        </CardContent>
                                    </TabsContent>

                                    <TabsContent value="step-3" className="m-0">
                                        <CardHeader>
                                            <CardTitle className="text-2xl">Add contacts</CardTitle>
                                            <CardDescription>
                                                Select contacts you want to reach out to now or add them later
                                            </CardDescription>
                                        </CardHeader>
                                        <CardContent>
                                            <ContactSelection
                                                onSuggestedContactsChange={handleSuggestedContactsChange}
                                                onExistingContactsChange={handleExistingContactsChange}
                                                recommendations={filteredRecommendations}
                                                isLoadingRecommendations={isLoadingRecommendations}
                                            />
                                        </CardContent>
                                    </TabsContent>
                                </Tabs>
                            </form>
                        </Card>
                    </div>
                </div>
            </div>
            <StickyFormStepper
                currentStep={currentStep}
                handleStepChange={handleStepChange}
                selectedContactsCount={selectedContactsCount}
                onSubmit={handleFormSubmit}
            />
        </div>
    );
};

const PlaybookFormHeader: FC = () => {
    const { groupId } = useParams();
    let headerText = 'Create your playbook';
    let headerDescription =
        'Playbooks help your agent understand your goals, find matching contacts in your network and deliver personalized, value creating messages that resonate with them.';
    if (groupId !== undefined) {
        headerText = 'Update your playbook';
        headerDescription = '';
    }

    return (
        <div className="text-center mb-8">
            <h1 className="text-3xl font-bold tracking-tight mb-2">{headerText}</h1>
            <p className="text-muted-foreground max-w-2xl mx-auto">{headerDescription}</p>
        </div>
    );
};

const StickyFormStepper: FC<{
    currentStep: number;
    handleStepChange: (step: number) => void;
    selectedContactsCount: number;
    onSubmit: () => void;
}> = ({ currentStep, handleStepChange, selectedContactsCount, onSubmit }) => {
    const navigate = useNavigate();
    const { groupId } = useParams();
    return (
        <div className="sticky bottom-0 left-0 right-0 w-full border-t bg-white shadow-md z-10">
            <div className="container max-w-5xl mx-auto flex justify-between items-center h-16 px-4">
                <div className="flex items-center">
                    <Button
                        type="button"
                        variant="outline"
                        onClick={(e) => {
                            e.preventDefault();
                            navigate('/new' + paths.PLAYBOOKS);
                        }}
                        className="gap-2 text-muted-foreground font-semibold rounded-lg"
                    >
                        Exit
                    </Button>
                </div>
                <div className="flex items-center gap-2">
                    {currentStep > STEPS.PURPOSE && (
                        <Button
                            type="button"
                            variant="outline"
                            onClick={(e) => {
                                e.preventDefault();
                                handleStepChange(currentStep - 1);
                            }}
                            size="sm"
                        >
                            Previous
                        </Button>
                    )}
                    {currentStep < STEPS.CONTACTS ? (
                        <Button
                            type="button"
                            onClick={(e) => {
                                e.preventDefault();
                                handleStepChange(currentStep + 1);
                            }}
                            size="sm"
                            className="gap-1"
                        >
                            Next step
                            <ChevronRight className="h-4 w-4" />
                        </Button>
                    ) : (
                        <Button type="button" onClick={onSubmit} size="sm" className="gap-1">
                            {groupId
                                ? selectedContactsCount
                                    ? `Update and add ${selectedContactsCount} contacts`
                                    : 'Update playbook'
                                : selectedContactsCount
                                ? `Create and add ${selectedContactsCount} contacts`
                                : 'Create playbook'}
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};
const StepIndicator: FC<{
    title: string;
    active: boolean;
    completed: boolean;
    icon: React.ReactNode;
    onClick: () => void;
}> = ({ title, active, completed, icon, onClick }) => {
    return (
        <div
            className={`flex items-center gap-3 p-3 rounded-lg transition-colors cursor-pointer ${
                active
                    ? 'bg-background-brand-primary-subtle text-text-brand-secondary'
                    : completed
                    ? 'text-primary'
                    : 'text-muted-foreground'
            }`}
            onClick={onClick}
        >
            <div
                className={`flex items-center justify-center w-8 h-8 rounded-full shrink-0 ${
                    active
                        ? 'bg-primary text-primary-foreground'
                        : completed
                        ? 'bg-primary/20 text-primary'
                        : 'bg-muted text-muted-foreground'
                }`}
            >
                {icon}
            </div>
            <span className="font-medium">{title}</span>
            {completed && (
                <Check className="h-6 w-6 bg-background-tertiary rounded-full p-1 ml-auto animate-in fade-in zoom-in duration-300" />
            )}
        </div>
    );
};

const StepNavigation: FC<{
    currentStep: number;
    handleStepChange: (step: number) => void;
}> = ({ currentStep, handleStepChange }) => {
    return (
        <div className="w-full md:w-64 shrink-0 hidden md:block">
            <div className="sticky top-4 space-y-2">
                <StepIndicator
                    title="Define the purpose"
                    active={currentStep === STEPS.PURPOSE}
                    completed={currentStep > STEPS.PURPOSE}
                    icon={<Target className="h-5 w-5" />}
                    onClick={() => handleStepChange(STEPS.PURPOSE)}
                />
                <StepIndicator
                    title="Add instructions"
                    active={currentStep === STEPS.INSTRUCTIONS}
                    completed={currentStep > STEPS.INSTRUCTIONS}
                    icon={<FileText className="h-5 w-5" />}
                    onClick={() => handleStepChange(STEPS.INSTRUCTIONS)}
                />
                <StepIndicator
                    title="Add contacts"
                    active={currentStep === STEPS.CONTACTS}
                    completed={currentStep > STEPS.CONTACTS}
                    icon={<Users className="h-5 w-5" />}
                    onClick={() => handleStepChange(STEPS.CONTACTS)}
                />
            </div>
        </div>
    );
};
