import NoSeatsLeftInfo from './NoSeatsLeftInfo';
import SubscriptionIssueModal from './SubscriptionIssueModal';

const NoSeatsLeftInfoModal = ({
    organizationName,
    activeUsersCount,
    adminEmail,
}: {
    organizationName: string;
    activeUsersCount: number;
    adminEmail?: string;
}) => {
    return (
        <SubscriptionIssueModal organizationName={organizationName} activeUsersCount={activeUsersCount}>
            <NoSeatsLeftInfo adminEmail={adminEmail} />
        </SubscriptionIssueModal>
    );
};

export default NoSeatsLeftInfoModal;
