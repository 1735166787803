import { UseQueryOptions, UseSuspenseQueryOptions } from '@tanstack/react-query';
import { getZaplifySdk } from '@zaplify/sdk';

export const getPersonByIdCacheKey = (id: string) => ['getPersonById', id];

const fetchPersonById = (personId: string) => {
    return getZaplifySdk().profiles.sources.previewProspectingDetails({ personId });
};

type PersonData = Awaited<ReturnType<typeof fetchPersonById>>;

export const getPersonById = (
    personId: string,
    options?: Omit<UseQueryOptions<PersonData, Error>, 'queryKey' | 'queryFn'> &
        Omit<UseSuspenseQueryOptions<PersonData, Error>, 'queryKey' | 'queryFn'>
): UseQueryOptions<PersonData, Error> & UseSuspenseQueryOptions<PersonData, Error> => {
    return {
        queryKey: getPersonByIdCacheKey(personId),
        queryFn: () => fetchPersonById(personId),
        enabled: !!personId,
        ...options,
    };
};
