import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { palette } from '../../theme/palette';

type Props = {
    color?: string;
};

export const ErrorIcon: React.FC<Props & React.HTMLAttributes<HTMLElement>> = ({ color }: Props) => {
    return (
        <SvgIcon viewBox='0 0 20 20'>
            <path
                d='M9.99992 0.833252C4.93992 0.833252 0.833252 4.93992 0.833252 9.99992C0.833252 15.0599 4.93992 19.1666 9.99992 19.1666C15.0599 19.1666 19.1666 15.0599 19.1666 9.99992C19.1666 4.93992 15.0599 0.833252 9.99992 0.833252ZM10.9166 14.5833H9.08325V12.7499H10.9166V14.5833ZM10.9166 10.9166H9.08325V5.41659H10.9166V10.9166Z'
                fill={color || palette.redDark}
            />
        </SvgIcon>
    );
};
