import { BaseSdk } from '../../base.sdk';

interface FeedbackDto {
    featureName: string;
    rating: number;
    feedback: string;
    timestamp: string;
}

export class FeedbackSdk extends BaseSdk {
    constructor(apiEndpoint: string, token?: string) {
        super(apiEndpoint, token);
    }

    async sendFeedback(feedback: FeedbackDto): Promise<void> {
        return await this.post({
            path: this.feedbackPath,
            payload: feedback,
        });
    }
}
