import { ReactNode } from 'react';
import { AuthHeader } from './auth-header';

interface AuthPageLayoutProps {
    children: ReactNode;
}

export function AuthPageLayout({ children }: AuthPageLayoutProps) {
    return (
        <div className="grid min-h-svh lg:grid-cols-1 bg-background-tertiary">
            <div className="flex flex-col gap-4 p-4 md:p-6 z-10">
                <AuthHeader />
                <div className="flex flex-1 items-center justify-center">
                    <div className="w-full max-w-md">{children}</div>
                </div>
            </div>
        </div>
    );
}
