import { IEvent, Topics } from '@zaplify/common';

export class ProspectReviewedEvent extends IEvent {
    public static override readonly TOPIC: Topics = Topics.PROSPECTS;
    constructor(readonly prospectId: string, readonly organizationId: string) {
        super();
    }

    get partitionKey(): string {
        return this.organizationId;
    }
}
