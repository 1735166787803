import { FC, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { cn } from '@shadcn/ui/lib/utils';
import { Button } from '@shadcn/ui/components/ui/button';
import { Plus } from 'lucide-react';
import { CreatePlaybookDialog, useCreatePlaybookDialog } from '../../../components/dialogs/create-playbook-dialog';

export const PageHeader: FC = () => {
    const { openCreatePlaybookDialog } = useCreatePlaybookDialog();
    return (
        <div className="flex flex-shrink-0 min-h-0 items-center gap-4 justify-between pb-4">
            <div className="flex flex-col gap-1">
                <span className="font-medium text-2xl">Playbooks</span>
                <span className="text-text-tertiary text-sm">
                    Organize your communication with playbooks. Guide your interactions towards your goals by assigning
                    contacts to different playbooks.
                </span>
            </div>
            <Button onClick={openCreatePlaybookDialog}>
                <Plus />
                Create Playbook
            </Button>
        </div>
    );
};
