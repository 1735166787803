import { CircularProgress, Typography } from '@mui/material';
import { useAtom } from 'jotai';
import { SearchTypes, accountsDataAtom, accountsLoadingAtom } from '../../../atoms/globalSearch';
import { AccountCard } from '../AccountCard/AccountCard';
import AccountRow from '../AccountRow';
import CategoryExpandShrink from '../CategoryShowMore';
import { SearchSectionTitle } from '../globalSearchPopup';
import { useGlobalSearch } from '../hooks/GlobalSearch';

export const AccountSection = ({
    userId,
    groups,
    searchValue,
    onClick,
}: {
    userId: string;
    groups: { id: string; name: string }[];
    searchValue: string;
    onClick: (node: React.ReactNode) => void;
}) => {
    const [accounts, setAccounts] = useAtom(accountsDataAtom);
    const [loading, setLoading] = useAtom(accountsLoadingAtom);
    const pagination = accounts?.pagination;
    const numberOfAccounts = accounts?.data?.length;
    const globalSearch = useGlobalSearch();

    const handleShowMore = async () => {
        try {
            setLoading(true);
            const query = {
                match: searchValue,
                perPage: 5,
                page: pagination.page + 1,
                searchIn: [SearchTypes.ACCOUNTS],
            };

            const response = await globalSearch.search(query);
            setAccounts((prev) => ({
                data: [...prev.data, ...response?.accounts?.data],
                pagination: response?.accounts?.pagination,
            }));
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <SearchSectionTitle
                    title="AI search"
                    size="12px"
                    toolTip="Search results based on your specific input processed by our AI-assistant"
                />
                {!accounts?.data && loading && <CircularProgress size="12px" style={{ marginLeft: '8px' }} />}
            </div>
            {accounts?.data?.map((account) => (
                <div key={account.id}>
                    <AccountRow
                        account={account}
                        searchValue={searchValue}
                        onClick={() => onClick(<AccountCard userId={userId} account={account} groups={groups} />)}
                    />
                </div>
            ))}
            {numberOfAccounts === 0 ? (
                <Typography sx={{ lineHeight: '19px', fontSize: '13px', color: '#6B7985', marginBottom: '10px' }}>
                    Not enough data for relevant search.
                </Typography>
            ) : (
                numberOfAccounts >= pagination?.limit && (
                    <CategoryExpandShrink
                        page={pagination.page}
                        numberOfPages={pagination.numberOfPages}
                        loading={loading}
                        numberOfRows={numberOfAccounts}
                        onShowMore={() => handleShowMore()}
                    />
                )
            )}
        </div>
    );
};
