import { Grid, Typography } from '@mui/material';
import { useDispatch } from 'react-redux-latest';
import { ReactComponent as InformationIcon } from '../../../../../components/icons/information2.svg';
import { ReactComponent as ReloadIcon } from '../../../../../components/icons/reload.svg';
import { getSalesforceFields } from '../../../../../redux/ducks/salesforce/operations';
import { AppDispatch, useAppSelector } from '../../../../../redux/store/configureStore';
import { RefreshFieldsContainer } from './containers';

export const RefreshFields = () => {
    const dispatch = useDispatch<AppDispatch>();
    const selectedStrategy = useAppSelector((state) => state.salesforce.selectedStrategy);

    return (
        <RefreshFieldsContainer>
            <Grid container className={'refresh-body'}>
                <Grid item xs={1}>
                    <Grid container justifyContent={'center'}>
                        <InformationIcon />
                    </Grid>
                </Grid>
                <Grid item xs={11}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography>
                                If there are fields you want to map that are missing, please go to your Salesforce
                                account and create the missing fields. Come back here afterwards and refresh the fields.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} className={'refresh-action-item'}>
                            <Grid
                                container
                                alignItems={'center'}
                                className={'refresh-action-container'}
                                onClick={() => dispatch(getSalesforceFields(selectedStrategy, true))}
                            >
                                <ReloadIcon />
                                <Typography className={'refresh-action-text'}>Refresh fields</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </RefreshFieldsContainer>
    );
};
