import { Topics } from './topics';

/**
 * @deprecated Use IQuery instead.
 */
export abstract class IUntypedQuery {
    public static readonly TOPIC: Topics;
}

/**
 * Use in conjuction with IQueryHandler.
 */
export abstract class IQuery<ReturnType> {
    public static readonly TOPIC: Topics;

    /**
     * Will never be assigned or used as a value.
     * Only used to infer the return type of the query.
     */
    returnType: ReturnType | undefined = undefined;
}

/**
 * Enforces return types of handler to correspond with that of the query.
 */
export interface IQueryHandler<T extends IQuery<any>> {
    handle(q: T): Promise<T['returnType']>;
}
