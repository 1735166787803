import { getZaplifySdk } from '@zaplify/sdk';
import { BLOCKLIST_LIMIT } from '../../config';
import { getBlocklistByFirstCharacter, getFlatBlocklist } from '../../helpers/blocklist';
import { userOrganizationActionTypes } from '../actionTypes';
import { handleErrorNotification, handleSuccessNotification, handleWarningNotification } from './index';

export const updateBlocklistCompanyState = (name, deleted) => {
    const firstLetter = name.charAt(0).toUpperCase();
    return {
        type: userOrganizationActionTypes.CHANGE_BLOCKLIST,
        firstLetter: firstLetter,
        name: name,
        deleted: deleted,
    };
};

export const updateBlacklist = (blacklist) => {
    return {
        type: userOrganizationActionTypes.UPDATE_BLACKLIST,
        blacklist: blacklist,
    };
};

export const updateOrganizationRewards = (organizatinRewards) => {
    return {
        type: userOrganizationActionTypes.UPDATE_REWARDS,
        rewards: organizatinRewards,
    };
};

export const getOrganizationRewards = () => async (dispatch, getState) => {
    const profilesSdk = getZaplifySdk().profiles;
    const organizationId = getState().user.zaplifyUser.userOrganizationId;
    try {
        const organizationRewards = await profilesSdk.userOrganization.getOrganizationRewards(organizationId);
        dispatch(updateOrganizationRewards(organizationRewards));
    } catch (error) {
        dispatch(handleErrorNotification('Something went wrong', 2000));
        console.log('getOrganizationRewards failed');
    }
};

export const updateOrganization = (organizationDto) => async (dispatch, getState) => {
    const organizationId = getState().user.zaplifyUser.userOrganizationId;
    const profilesSdk = getZaplifySdk().profiles;
    try {
        await profilesSdk.userOrganization.updateOrganization(organizationId, organizationDto);
    } catch (error) {
        dispatch(handleErrorNotification('Something went wrong', 2000));
        console.log('updateOrganization failed');
    }
};

export const getBlacklist = () => async (dispatch, getState) => {
    const organizationId = getState().user.zaplifyUser.userOrganizationId;
    try {
        const blacklist = await getZaplifySdk().profiles.prospects.getBlacklist(organizationId);
        const tempBlacklist = getBlocklistByFirstCharacter(blacklist.organizationBlocklist);
        dispatch(updateBlacklist(tempBlacklist));
    } catch (error) {
        dispatch(handleErrorNotification('Something went wrong', 2000));
        console.log('getBlacklist failed');
    }
};

export const updateBlocklistCompany = (name, deleted) => async (dispatch, getState) => {
    const profilesSdk = getZaplifySdk().profiles;
    const organizationId = getState().user.zaplifyUser.userOrganizationId;
    try {
        dispatch(updateBlocklistCompanyState(name, deleted));
        const blocklist = getState().userOrganization.blacklist;

        //transforming blocklist to flat array of company objects
        const companiesList = getFlatBlocklist(blocklist);

        const tempBlocklist = (companiesList as any)
            .filter((company) => !company.deleted)
            .map((company) => {
                return { name: company.name };
            });
        await profilesSdk.prospects.replaceBlacklist({
            organizationId,
            organizationBlocklist: tempBlocklist,
        } as any);
    } catch (error) {
        dispatch(handleErrorNotification('Something went wrong', 2000));
        console.log('updateBlocklistCompany failed');
    }
};

export const unblockCompanyFromBlocklist = (name) => async (dispatch, getState) => {
    const profilesSdk = getZaplifySdk().profiles;
    const organizationId = getState().user.zaplifyUser.userOrganizationId;
    try {
        await profilesSdk.prospects.deleteCompanyFromBlocklist(organizationId, { name: name });
        dispatch(updateBlocklistCompanyState(name, true));
    } catch (error) {
        dispatch(handleErrorNotification('Something went wrong', 2000));
        console.log('unblockCompanyFromBlocklist failed');
    }
};

export const undoUnblockCompany = (name) => async (dispatch, getState) => {
    try {
        const blocklist = getState().userOrganization.blacklist;

        //transforming blocklist to flat array of company objects
        const companiesList = getFlatBlocklist(blocklist);

        const blockedCompaniesNumber = (companiesList as any).filter((company) => !company.deleted).length;

        blockedCompaniesNumber !== BLOCKLIST_LIMIT
            ? dispatch(updateBlocklistCompany(name, false))
            : dispatch(handleErrorNotification(`Your organization has reached the max number of blocked companies`));
    } catch (error) {
        dispatch(handleErrorNotification('Something went wrong', 2000));
        console.log('undoUnblockCompany failed');
    }
};

export const setBlacklistThunk =
    (newBlacklist, prevBlacklist, csv = false) =>
    async (dispatch, getState) => {
        const organizationId = getState().user.zaplifyUser.userOrganizationId;

        try {
            const oldBlocklist = await getZaplifySdk().profiles.prospects.getBlacklist(organizationId);
            // merge old and new blocklist
            const mergedBlacklist = [...oldBlocklist.organizationBlocklist, ...newBlacklist];
            const { added, skipped } = await getZaplifySdk().profiles.prospects.replaceBlacklist({
                organizationId,
                organizationBlocklist: [...mergedBlacklist],
            } as any);
            const blocklist = await getZaplifySdk().profiles.prospects.getBlacklist(organizationId);

            dispatch(updateBlacklist(getBlocklistByFirstCharacter(blocklist.organizationBlocklist)));
            if (csv) {
                if (!skipped) {
                    dispatch(handleSuccessNotification(`${added} companies blocked`), 5);
                }
                if (skipped) {
                    dispatch(handleWarningNotification(`${added}/${newBlacklist.length} companies blocked.`, 5));
                }
                if (blocklist.organizationBlocklist.length === BLOCKLIST_LIMIT) {
                    dispatch(
                        handleErrorNotification(`Your organization has reached the max number of blocked companies`, 5)
                    );
                }
            }

            return;
        } catch (error) {
            dispatch(handleErrorNotification('Something went wrong', 2000));
            console.log('setBlacklistThunk failed');
        }
    };

export const deleteAllCompaniesFromBlocklist = (organizationId) => async (dispatch) => {
    const profilesSdk = getZaplifySdk().profiles;
    try {
        await profilesSdk.prospects.deleteAllCompaniesFromBlocklist(organizationId);
        dispatch(updateBlacklist({}));
    } catch (error) {
        dispatch(handleErrorNotification('Something went wrong', 2000));
        console.log('deleteAllCompaniesFromBlocklist failed');
    }
};
