import { Typography, styled } from '@mui/material';
import { useDispatch } from 'react-redux-latest';
import { AppDispatch } from '../redux/store/configureStore';
import { logoutAndRedirect } from '../redux/actions';
import { Button } from '@zaplify/ui';
import { ZaplifyIcon } from './icons/zaplify-logo';
import { isMobileBrowser } from '../services/utils/helpFunctions';
import { companyNameCorrectlyCased } from '../enums/company-name';
import { AndSendIcon } from './icons/and-send/andsend-icon';
import { isAndSend } from '../helpers/is-and-send';

const MobileContainer = styled('div')(({ theme }) => ({
    background: theme.palette.background.default,
    height: '100vh',
    width: '100vw',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1000000,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: 20,
    padding: 20,
    boxSizing: 'border-box',
    '& svg': {
        width: '60px',
        height: '60px',
    },
}));

export const MobileView = ({ children }) => {
    const dispatch = useDispatch<AppDispatch>();
    if (!isMobileBrowser()) return <>{children}</>;

    const handleLogout = () => {
        dispatch(logoutAndRedirect());
    };

    return (
        <MobileContainer>
            {isAndSend() ? <AndSendIcon variation="color" /> : <ZaplifyIcon />}
            <Typography variant="h3">You're in, but mobile is not supported, yet 😌</Typography>
            <Typography variant="subtitle2">
                You need to install our Chrome Extension to use {companyNameCorrectlyCased}, which currently is only
                available on desktop.
                <br /> See you there!
            </Typography>
            <Button variant="text" text="Logout" onClick={handleLogout} />
        </MobileContainer>
    );
};
