import { Container } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../../redux/store/configureStore';
import { paths } from '../../../routes/paths';

export const Component = () => {
    const isLoadingUser = useAppSelector((state) => state.user.isLoadingData);

    if (isLoadingUser) {
        return null;
    }

    return (
        <Container sx={{}}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '8px' }}>
                <Typography
                    component="h1"
                    sx={{
                        fontSize: '32px',
                        fontWeight: '600',
                        lineHeight: '40px',
                        textAlign: 'center',
                        textWrap: 'balance',
                    }}
                >
                    Password changed! 🎉
                </Typography>
                <Typography sx={{ textAlign: 'center', textWrap: 'balance' }}>
                    You can now use your new password to log in.
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginTop: '24px',
                        gap: '16px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '8px',
                        }}
                    >
                        <Link to={paths.OLD_LOGIN} style={{ textDecoration: 'none' }}>
                            <Typography
                                component={'span'}
                                sx={{
                                    color: '#6583EF',
                                    fontSize: '14px',
                                    fontWeight: 500,
                                    lineHeight: '21px',
                                }}
                            >
                                Go back to Sign in
                            </Typography>
                        </Link>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
};

Component.displayName = 'ResetPasswordCompleted';
