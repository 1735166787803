import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { ChainedCommands, Editor } from '@tiptap/react';
import { SmileIcon } from 'lucide-react';
import { forwardRef, useState } from 'react';
import { Popover, PopoverTrigger, PopoverContent } from '@shadcn/ui/components/ui/popover';
import { cn } from '@shadcn/ui/lib/utils';

export const EmojisPicker = ({ editor, className }: { editor: Editor; className?: string }) => {
    const onEmojiSelect = (emoji: any) => {
        editor.commands.focus();
        setTimeout(() => {
            editor?.commands.insertContent(emoji.native);
        }, 25);
    };

    return (
        <Popover>
            <PopoverTrigger>
                <SmileIcon className="cursor-pointer" size={20} style={{ stroke: '#0000008a', strokeWidth: 2 }} />
            </PopoverTrigger>
            <PopoverContent className={cn('z-[1000] w-fit', className)}>
                <Picker data={data} onEmojiSelect={onEmojiSelect} />
            </PopoverContent>
        </Popover>
    );
};

export const EmojisPopoverContent = forwardRef<
    HTMLDivElement,
    {
        onEmojiSelect: (emoji: any, toggleBold: () => ChainedCommands) => void;
        className?: string;
    }
>(({ onEmojiSelect, className }, ref) => {
    return (
        <div ref={ref} className={cn('z-[1000]', className)}>
            <Picker data={data} onEmojiSelect={onEmojiSelect} />
        </div>
    );
});
