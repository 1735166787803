import { SvgIcon } from '@mui/material';
import React from 'react';
import { isAndSend } from '../../helpers/is-and-send';
import { ReactComponent as AndSendLogo } from '../../components/icons/and-send/ANDSEND_logotype_small_black_transparent.svg';

type Props = {
    some?: string;
};

const ZaplifyLogo: React.FC<Props & React.HTMLAttributes<HTMLElement>> = ({ ...props }: Props) => {
    return isAndSend() ? (
        <AndSendLogo {...(props as any)} />
    ) : (
        <SvgIcon {...props} viewBox="0 0 111 27">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M110.131 11.8926L110.131 15.8735C106.855 15.8735 104.199 18.547 104.199 21.8449L100.244 21.8449C100.244 16.3484 104.67 11.8926 110.131 11.8926Z"
                fill="#FE5C5E"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M100.172 0L100.172 3.95833C103.439 3.95833 106.087 6.61663 106.087 9.89583L110.031 9.89583C110.031 4.4305 105.617 -5.8325e-08 100.172 0Z"
                fill="#FE5C5E"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M98.3965 11.998L98.3965 15.979C95.1203 15.979 92.4644 18.6525 92.4644 21.9504L88.5096 21.9504C88.5096 16.4539 92.9361 11.998 98.3965 11.998Z"
                fill="#5F8EFA"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M88.4473 0L88.4473 3.95833C91.7142 3.95833 94.3626 6.61663 94.3626 9.89583L98.3061 9.89583C98.3061 4.4305 93.8922 -5.8325e-08 88.4473 0Z"
                fill="#5F8EFA"
            />
            <path
                d="M3.44275 19.2472C3.39342 19.3132 3.44048 19.4071 3.52283 19.4071H10.6513C10.7065 19.4071 10.7513 19.4519 10.7513 19.5071V21.5164C10.7513 21.5716 10.7065 21.6164 10.6513 21.6164H0.1C0.0447719 21.6164 0 21.5716 0 21.5164V20.1058C0 20.0842 0.0069498 20.0633 0.0198159 20.046L7.00193 10.6766C7.05109 10.6107 7.00401 10.5169 6.92175 10.5169H0.379254C0.324025 10.5169 0.279254 10.4721 0.279254 10.4169V8.4076C0.279254 8.35237 0.324026 8.3076 0.379254 8.3076H10.372C10.4273 8.3076 10.472 8.35237 10.472 8.4076V9.81816C10.472 9.83975 10.465 9.86077 10.4521 9.87806L3.44275 19.2472Z"
                fill="#23323F"
            />
            <path
                d="M24.3357 8.4076C24.3357 8.35237 24.3805 8.3076 24.4357 8.3076H26.6652C26.7204 8.3076 26.7652 8.35237 26.7652 8.4076V21.5164C26.7652 21.5716 26.7204 21.6164 26.6652 21.6164H24.4357C24.3805 21.6164 24.3357 21.5716 24.3357 21.5164V19.6488C24.3357 19.5506 24.2066 19.5098 24.1472 19.588C22.9456 21.171 21.2491 21.9624 19.0578 21.9624C17.103 21.9624 15.4368 21.2881 14.0591 19.9395C12.6815 18.5731 11.9927 16.914 11.9927 14.962C11.9927 13.01 12.6815 11.3598 14.0591 10.0111C15.4368 8.64476 17.103 7.96157 19.0578 7.96157C21.2491 7.96157 22.9456 8.75306 24.1472 10.336C24.2066 10.4142 24.3357 10.3734 24.3357 10.2753V8.4076ZM19.365 19.7266C20.7799 19.7266 21.962 19.2741 22.9115 18.3691C23.861 17.4463 24.3357 16.3106 24.3357 14.962C24.3357 13.6134 23.861 12.4866 22.9115 11.5816C21.962 10.6588 20.7799 10.1975 19.365 10.1975C17.9687 10.1975 16.7958 10.6588 15.8464 11.5816C14.8969 12.4866 14.4222 13.6134 14.4222 14.962C14.4222 16.3106 14.8969 17.4463 15.8464 18.3691C16.7958 19.2741 17.9687 19.7266 19.365 19.7266Z"
                fill="#23323F"
            />
            <path
                d="M38.1535 7.96157C40.1083 7.96157 41.7746 8.64476 43.1522 10.0111C44.5299 11.3598 45.2187 13.01 45.2187 14.962C45.2187 16.914 44.5299 18.5731 43.1522 19.9395C41.7746 21.2881 40.1083 21.9624 38.1535 21.9624C35.9622 21.9624 34.2657 21.171 33.0641 19.588C33.0048 19.5098 32.8756 19.5506 32.8756 19.6488V26.8399C32.8756 26.8952 32.8309 26.9399 32.7756 26.9399H30.5461C30.4909 26.9399 30.4461 26.8952 30.4461 26.8399V8.4076C30.4461 8.35237 30.4909 8.3076 30.5461 8.3076H32.7756C32.8309 8.3076 32.8756 8.35237 32.8756 8.4076V10.2753C32.8756 10.3734 33.0048 10.4142 33.0641 10.336C34.2657 8.75306 35.9622 7.96157 38.1535 7.96157ZM37.8464 19.7266C39.2426 19.7266 40.4155 19.2741 41.365 18.3691C42.3144 17.4463 42.7892 16.3106 42.7892 14.962C42.7892 13.6134 42.3144 12.4866 41.365 11.5816C40.4155 10.6588 39.2426 10.1975 37.8464 10.1975C36.4315 10.1975 35.2493 10.6588 34.2998 11.5816C33.3504 12.4866 32.8756 13.6134 32.8756 14.962C32.8756 16.3106 33.3504 17.4463 34.2998 18.3691C35.2493 19.2741 36.4315 19.7266 37.8464 19.7266Z"
                fill="#23323F"
            />
            <path
                d="M48.2177 21.6164C48.1625 21.6164 48.1177 21.5716 48.1177 21.5164V2.28555C48.1177 2.23032 48.1625 2.18555 48.2177 2.18555H50.4472C50.5024 2.18555 50.5472 2.23032 50.5472 2.28555V21.5164C50.5472 21.5716 50.5024 21.6164 50.4472 21.6164H48.2177Z"
                fill="#23323F"
            />
            <path
                d="M55.4824 5.8854C55.0169 5.8854 54.626 5.73456 54.3095 5.4329C53.993 5.13123 53.8348 4.76746 53.8348 4.34157C53.8348 3.91569 53.993 3.55192 54.3095 3.25025C54.626 2.93084 55.0169 2.77113 55.4824 2.77113C55.9292 2.77113 56.3108 2.93084 56.6273 3.25025C56.9438 3.55192 57.102 3.91569 57.102 4.34157C57.102 4.76746 56.9438 5.13123 56.6273 5.4329C56.3108 5.73456 55.9292 5.8854 55.4824 5.8854ZM54.3536 21.6164C54.2984 21.6164 54.2537 21.5716 54.2537 21.5164V8.4076C54.2537 8.35237 54.2984 8.3076 54.3536 8.3076H56.5832C56.6384 8.3076 56.6832 8.35237 56.6832 8.4076V21.5164C56.6832 21.5716 56.6384 21.6164 56.5832 21.6164H54.3536Z"
                fill="#23323F"
            />
            <path
                d="M67.5664 4.76567C67.5664 4.82402 67.5157 4.86984 67.4575 4.86601C65.0727 4.70911 63.8803 5.72322 63.8803 7.90834V8.2076C63.8803 8.26283 63.9251 8.3076 63.9803 8.3076H67.4664C67.5217 8.3076 67.5664 8.35237 67.5664 8.4076V10.4435C67.5664 10.4987 67.5217 10.5435 67.4664 10.5435H63.9803C63.9251 10.5435 63.8803 10.5883 63.8803 10.6435V21.5164C63.8803 21.5716 63.8355 21.6164 63.7803 21.6164H61.5508C61.4955 21.6164 61.4508 21.5716 61.4508 21.5164V10.6435C61.4508 10.5883 61.406 10.5435 61.3508 10.5435H59.3167C59.2615 10.5435 59.2167 10.4987 59.2167 10.4435V8.4076C59.2167 8.35237 59.2615 8.3076 59.3167 8.3076H61.3508C61.406 8.3076 61.4508 8.26283 61.4508 8.2076V7.90834C61.4508 6.11608 61.972 4.75858 63.0146 3.83584C63.8688 3.07981 64.9703 2.80421 67.4649 2.78359C67.5203 2.78313 67.5664 2.828 67.5664 2.88344V4.76567Z"
                fill="#23323F"
            />
            <path
                d="M79.683 8.37164C79.6979 8.33305 79.735 8.3076 79.7763 8.3076H82.1566C82.2275 8.3076 82.2759 8.37926 82.2494 8.44498L76.608 22.4416C76.0122 23.9499 75.1744 25.0944 74.0947 25.8752C73.0573 26.6558 71.8599 27.0125 70.5023 26.9452C70.4496 26.9426 70.4085 26.8989 70.4085 26.8461V24.8914C70.4085 24.8333 70.4582 24.7876 70.5162 24.7904C72.174 24.8695 73.3948 23.9624 74.1785 22.0689L74.4085 21.606C74.422 21.5789 74.4224 21.5472 74.4098 21.5197L68.4071 8.44934C68.3767 8.38309 68.4251 8.3076 68.498 8.3076H70.8747C70.9139 8.3076 70.9495 8.33051 70.9657 8.36619L75.5878 18.5249C75.6245 18.6055 75.7402 18.6021 75.7721 18.5194L79.683 8.37164Z"
                fill="#23323F"
            />
        </SvgIcon>
    );
};

export default ZaplifyLogo;
