import { Skeleton } from '@shadcn/ui/components/ui/skeleton';
import { useSuggestionsFeed } from '../../../hooks/use-suggestions-feed';
import { ContactWorkExperience } from './contact-work-experience';

export const ContactWorkExperiences = () => {
    const { currentContactSuggestionProfileData: profileData, isFetchingCurrentLinkedinProfile } = useSuggestionsFeed();
    const experiences = profileData?.experiences;

    if (isFetchingCurrentLinkedinProfile) {
        return (
            <div className="flex flex-col items-start justify-start w-full rounded-xl bg-background-tertiary p-4 gap-8">
                <span className="text-sm font-medium text-muted-foreground">Work Experience</span>

                <div className="rounded-xl bg-background-tertiary text-sm w-full">
                    <div className="flex flex-row gap-2">
                        <Skeleton className="w-8 h-8 rounded-full" />
                        <div className="flex flex-col gap-1">
                            <Skeleton className="h-4 w-64" />
                            <Skeleton className="h-4 w-48" />
                        </div>
                    </div>
                </div>
                <div className="rounded-xl bg-background-tertiary text-sm w-full">
                    <div className="flex flex-row gap-2">
                        <Skeleton className="w-8 h-8 rounded-full" />
                        <div className="flex flex-col gap-1">
                            <Skeleton className="h-4 w-64" />
                            <Skeleton className="h-4 w-48" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (!experiences?.length) return null;
    return (
        <div className="flex flex-col items-start justify-start gap-2 w-full rounded-xl bg-background-tertiary p-4 animate-in fade-in-10 duration-300">
            <div className="flex flex-col gap-2 items-start overflow-hidden text-text-primary w-full">
                <div className="flex flex-col gap-2 text-muted-foreground">
                    <span className="text-sm font-medium text-muted-foreground">Work Experience</span>
                    {experiences?.slice(0, 2).map((experience, index) => (
                        <ContactWorkExperience key={index} experience={experience} />
                    ))}
                </div>
            </div>
        </div>
    );
};
