import { styled } from '@mui/styles';

const EnterprisePlanCardStyled = styled('div')(({ theme: { palette } }: any) => ({
    height: '380px',
    backgroundColor: palette.colors.white,
    boxShadow: `0px 2px 7px rgba(112, 144, 176, 0.14);`,
    borderRadius: '8px',
    maxWidth: 300,
    minWidth: 220,
    flexBasis: 300,
    '& .wrapper': {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',

        '& .top': {
            padding: '34px 24px 0',

            '& .title': {
                marginBottom: '6px',
                color: palette.colors.black,
                fontSize: '19px',
                fontWeight: 500,
            },
            '& .subtitle': { color: '#6B7985', fontSize: '15px' },
        },

        '& .bottom': {
            padding: '0 16px 16px',

            '& .current-plan-button': {
                borderRadius: 300,
                padding: '12px',
                backgroundColor: palette.colors.greyInformationLight,
                color: palette.darkGrey,
                fontSize: '15px',
                fontWeight: 500,
            },
            '& .action': {
                borderRadius: '8px',
                padding: '12px 22px',
                backgroundColor: palette.colors.secondaryBlue,
                display: 'flex',
                gap: '19.5px',
                alignItems: 'center',
                color: palette.colors.bluePale,
                fontSize: '15px',
            },
        },
    },
}));

export default EnterprisePlanCardStyled;
