import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import { UseFormRegisterReturn } from 'react-hook-form';
import FeedbackMessage from './feedback-message';

const FormAutocompleteField = <FieldName extends string>({
    id,
    label,
    placeholder,
    register,
    error,
    onChange,
    getOptionLabel,
    options,
    renderOption,
}: {
    id: FieldName;
    label: string;
    placeholder: string;
    register: UseFormRegisterReturn<FieldName>;
    error?: string;
    onChange: (event: React.SyntheticEvent, value: any) => void;
    getOptionLabel: (option: any) => string;
    options: any[];
    renderOption?: (props: React.HTMLAttributes<HTMLLIElement>, option: any) => React.ReactNode;
}) => {
    return (
        <Box className={`autocomplete-${id}`}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                <Typography
                    component="label"
                    htmlFor={id}
                    sx={{ fontSize: '14px', fontWeight: 400, lineHeight: '21px' }}
                >
                    {label}
                </Typography>
                <Autocomplete
                    onChange={onChange}
                    getOptionLabel={getOptionLabel}
                    id={id}
                    options={options}
                    clearOnEscape
                    renderInput={(params) => (
                        <TextField
                            {...register}
                            {...params}
                            margin="normal"
                            fullWidth
                            size="small"
                            autoComplete={id}
                            error={!!error}
                            sx={{
                                paddingBottom: '0px',
                                backgroundColor: 'white',
                                marginTop: '0px',
                                borderRadius: '8px',
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '8px',
                                    height: '40px',
                                    '& fieldset': {
                                        borderColor: '#E4E6EB',
                                    },
                                },
                            }}
                            placeholder={placeholder}
                        />
                    )}
                    renderOption={renderOption}
                />
            </Box>
            <FeedbackMessage error={error} />
        </Box>
    );
};

export default FormAutocompleteField;
