export function getSpecialHubspotMapping(hubspot, fieldMapping) {
    const tempFieldMapping: any[] = [];
    if (hubspot.mapLinkedIn && !fieldMapping.some((f) => f.contactField === 'linkedin_profile_url'))
        tempFieldMapping.push({
            prospectField: 'prospect.linkedinProfileUrl', // double check this one first
            contactField: 'linkedin_profile_url', // for hubspot side use this precise one
            create: {
                title: 'LinkedIn Profile URL',
                description: 'LinkedIn Profile URL of the prospect',
            },
        });

    if (hubspot.mapCampaignName && !fieldMapping.some((f) => f.contactField === 'zaplify_campaign_name')) {
        tempFieldMapping.push({
            prospectField: 'custom.campaignName', // double check this one first
            contactField: 'zaplify_campaign_name', // for hubspot side use this precise one
            create: {
                title: 'Zaplify Prospect Campaign Name',
                description: 'Prospect campaign name in Andsend',
            },
        });
    }

    if (
        hubspot.mapLinkedInConnection &&
        !fieldMapping.some((f) => f.contactField === 'zaplify_is_linkedin_connected')
    ) {
        tempFieldMapping.push({
            prospectField: 'contactRef.isLinkedInConnected', // double check this one first
            contactField: 'zaplify_is_linkedin_connected', // for hubspot side use this precise one
            create: {
                title: 'Zaplify Prospect is Connected to LinkedIn',
                description: 'Prospect accept connect to Linkedin in Andsend',
            },
        });
    }

    if (hubspot.mapZaplifyProspectStatus && !fieldMapping.some((f) => f.contactField === 'zaplify_prospect_status'))
        tempFieldMapping.push({
            prospectField: 'contactRef.statusInCampaign', // double check this one first
            contactField: 'zaplify_prospect_status', // for hubspot side use this precise one
            create: {
                title: 'Zaplify Prospect Status',
                description: 'Prospect status in Zaplify campaign',
            },
        });

    return tempFieldMapping;
}
