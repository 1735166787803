import { FC, useState, useEffect, useCallback } from 'react';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '@shadcn/ui/components/ui/dialog';
import {
    BillingPeriodUnit,
    CreatedEstimateDto,
    IPlan,
    IPlanPackage,
    PlanCode,
    UpdateSubscriptionDto,
} from '@zaplify/subscriptions';
import { useChargebeeSubscription } from '../../../hooks/use-chargebee-subscription';
import { PlanSelection } from './plan-selection';
import { ConfirmationView } from './confirmation-view';
import { getZaplifySdk } from '@zaplify/sdk';
import { useAuth } from '../../../../../providers/authentication-provider';
import { useBillingListener, PaymentStatus } from '../../../../../hooks/use-billing-listener';
interface PickPlanDialogProps {
    isOpen: boolean;
    onClose: () => void;
    onSuccess: () => void;
}

type Step = 'select-plan' | 'confirm';

export async function getAvailablePlans(): Promise<{ plans: IPlan[] }> {
    const profilesSdk = getZaplifySdk().profiles;
    const response = await profilesSdk.billing.getAvailablePlans();

    if (!response) {
        return { plans: [] };
    }

    return response;
}

export const calculateAmountOfUsersExceedsSelectedPlan = (
    selectedPlan: IPlanPackage,
    occupiedSeatsCount: number
): number => {
    const newAmountOfSeats = selectedPlan?.seats;

    return occupiedSeatsCount - newAmountOfSeats;
};

export const PickPlanDialog: FC<PickPlanDialogProps> = ({ isOpen, onClose, onSuccess }) => {
    const { loadSelfServicePortal, subscription, isChargebeeOpen, refetchSubscription } = useChargebeeSubscription();
    const { setPaymentStatus } = useBillingListener();
    const {
        authState: { user },
    } = useAuth();
    const [step, setStep] = useState<Step>('select-plan');
    const [availablePlans, setAvailablePlans] = useState<IPlan[]>([]);
    const [selectedPlan, setSelectedPlan] = useState<{
        plan: IPlan;
        package: IPlanPackage;
    } | null>(null);
    const [selectedBillingPeriod, setSelectedBillingPeriod] = useState<{
        unit: BillingPeriodUnit;
        amount: number;
    }>({ unit: BillingPeriodUnit.YEAR, amount: 1 });
    const [couponCode, setCouponCode] = useState<string>('');
    const [isCouponCodeValid, setIsCouponCodeValid] = useState<boolean>(false);
    const [loadingState, setLoadingState] = useState<'idle' | 'loading'>('idle');
    const [estimates, setEstimates] = useState<CreatedEstimateDto[]>([]);
    const [selectedTeamPlanEstimatesSummary, setSelectedTeamPlanEstimatesSummary] = useState<
        (CreatedEstimateDto & { planCode: PlanCode }) | null
    >(null);
    const [selectedTeamPlanEstimatesTotal, setSelectedTeamPlanEstimatesTotal] = useState<
        (CreatedEstimateDto & { planCode: PlanCode }) | null
    >(null);
    const [upgradePlanErrorText, setUpgradePlanErrorText] = useState<string | null>(null);
    // Fetch available plans when dialog opens
    useEffect(() => {
        if (isOpen) {
            fetchAvailablePlans()
                .then((plans) => {
                    setAvailablePlans(plans);

                    // Set initial billing period options
                    const availablePeriods = [...new Set(plans.map((plan) => plan.billingPeriod.unit))];
                    if (availablePeriods.includes(BillingPeriodUnit.YEAR)) {
                        setSelectedBillingPeriod({ unit: BillingPeriodUnit.YEAR, amount: 1 });
                    } else if (availablePeriods.includes(BillingPeriodUnit.MONTH)) {
                        setSelectedBillingPeriod({ unit: BillingPeriodUnit.MONTH, amount: 1 });
                    }
                })
                .finally(() => {
                    setLoadingState('idle');
                });
        }
    }, [isOpen]);

    const fetchAvailablePlans = async () => {
        setLoadingState('loading');
        try {
            const response = await getAvailablePlans();
            return response.plans;
        } catch (error) {
            console.error('Failed to fetch plans:', error);
            setLoadingState('idle');
        }
        return [];
    };

    const handleClose = () => {
        setStep('select-plan');
        setSelectedPlan(null);
        setCouponCode('');
        setIsCouponCodeValid(false);
        onClose();
    };

    const handleSelectPlan = async (plan: IPlan, selectedPackage: IPlanPackage) => {
        // const selectedPackage = plan.packages[0]; // Get first package or implement package selection logic
        // setSelectedPlan(
        //     {
        //         plan: availablePlans.find((plan) => plan.billingPeriod.unit === periodUnit && plan.code === planCode),
        //         package: selectedPackage,
        //     }
        // );
        await createCheckoutEstimate({
            planToSet: plan,
            packageToSet: selectedPackage,
            couponCode,
        });
        setSelectedPlan({ plan, package: selectedPackage });
        setStep('confirm');
    };

    const createCheckoutEstimate = useCallback(
        async ({
            planToSet,
            packageToSet,
            couponCode,
        }: {
            planToSet?: IPlan;
            packageToSet: IPlanPackage;
            couponCode?: string;
        }) => {
            const planToSetOrDefault: IPlan | null = planToSet;

            if (!planToSetOrDefault) {
                return;
            }

            if (!packageToSet) {
                return;
            }
            loadingState === 'idle' && setLoadingState('loading');
            const profilesSdk = getZaplifySdk().profiles;
            const checkoutEstimate: UpdateSubscriptionDto & { couponCode?: string } = {
                numberOfSeats: packageToSet.seats,
                billingPeriod: { ...planToSetOrDefault.billingPeriod },
                creditNumber: packageToSet.credits,
                planCode: planToSetOrDefault.code,
            };
            try {
                // new organization with no subscription
                const estimates: Array<CreatedEstimateDto & { planCode: PlanCode }> = [];
                const estimate = await profilesSdk.billing.createCheckoutEstimate({
                    ...checkoutEstimate,
                });
                estimates.push({ ...estimate, planCode: planToSetOrDefault.code });

                console.log('estimates', estimates);
                setEstimates(estimates);
                const estimateToSet = estimates.find((estimate) => estimate.planCode === planToSetOrDefault.code);
                setSelectedTeamPlanEstimatesSummary(estimateToSet);

                // existing organization with subscription
                // As agreed, values from this endpoint will be only used to display the total amount to charge

                const [totalEstimation] = await Promise.all([
                    profilesSdk.subscription.requestEstimateOnSubscriptionUpdate(user.userOrganizationId, {
                        ...checkoutEstimate,
                        couponCode,
                    }),
                ]);
                console.log('totalEstimation', totalEstimation);
                // used to display Total (charged today) values
                setSelectedTeamPlanEstimatesTotal({
                    ...totalEstimation,
                    planCode: checkoutEstimate.planCode,
                });

                setLoadingState('idle');
            } catch (error) {
                console.error('Failed to create checkout estimate:', error);
                setLoadingState('idle');
            }
        },
        [step, selectedPlan]
    );

    const handleBack = () => {
        setStep('select-plan');
        setCouponCode('');
        setIsCouponCodeValid(false);
    };

    const handleBillingPeriodChange = (newPeriod: { unit: BillingPeriodUnit; amount: number }) => {
        setSelectedBillingPeriod(newPeriod);
        // Filter plans based on new billing period but don't automatically select it
        // console.log('availablePlans', availablePlans);
        // const filteredPlans = availablePlans.filter(
        //     (plan) => plan.billingPeriod.unit === newPeriod.unit && plan.billingPeriod.amount === newPeriod.amount
        // );
        // console.log('filteredPlans', filteredPlans);
        // setAvailablePlans(filteredPlans);
    };

    const handleCouponCode = async (code: string) => {
        if (!code) return;

        try {
            const profilesSdk = getZaplifySdk().profiles;
            const response = await profilesSdk.billing.verifyCouponCode(code);
            setIsCouponCodeValid(response.isValid);
            if (response.isValid) {
                await createCheckoutEstimate({
                    planToSet: selectedPlan?.plan,
                    packageToSet: selectedPlan?.package,
                    couponCode: code,
                });
                setCouponCode(code);
            } else {
                setCouponCode(code);
            }
        } catch (error) {
            setIsCouponCodeValid(false);
            setCouponCode(code);
            console.error('Failed to verify coupon:', error);
        }
    };

    const handlePurchase = async (couponCode?: string) => {
        if (!selectedPlan) return;

        setLoadingState('loading');
        setPaymentStatus(PaymentStatus.PROCESSING);
        const profilesSdk = getZaplifySdk().profiles;
        console.log('seats', selectedPlan.package.seats);
        const upgradePlanDTO: UpdateSubscriptionDto = {
            numberOfSeats: selectedPlan.package.seats,
            billingPeriod: {
                unit: selectedPlan.plan.billingPeriod.unit,
                amount: selectedPlan.plan.billingPeriod.amount,
            },
            creditNumber: selectedPlan.package.credits,
            planCode: selectedPlan.plan.code,
            couponCode,
        } as UpdateSubscriptionDto;

        try {
            const response = await profilesSdk.subscription.requestSubscriptionUpdate(
                user.userOrganizationId,
                upgradePlanDTO,
                (errorMessage) => {
                    const errorObject =
                        typeof errorMessage === 'string' && errorMessage.startsWith('{')
                            ? JSON.parse(errorMessage)
                            : { data: { message: errorMessage, status: 400 } };
                    if (errorObject.data?.message && errorObject.data?.status === 400) {
                        const message = errorObject.data?.message;
                        if (message.includes('Please update your billing address')) {
                            setUpgradePlanErrorText(message);
                        }
                        if (message.includes('Cannot change the subscription as there is no valid card on file')) {
                            setUpgradePlanErrorText(message);
                        }
                    } else {
                        throw new Error(errorMessage);
                    }
                }
            );
            console.log('response', response);

            if (response !== undefined) {
                window?.analytics?.track('User Upgraded Plan', {
                    numberOfSeats: selectedPlan.package.seats,
                    billingPeriodAmount: selectedPlan.plan.billingPeriod.amount,
                    billingPeriodUnit: selectedPlan.plan.billingPeriod.unit,
                    creditNumber: selectedPlan.package.credits,
                    planCode: selectedPlan.plan.code,
                });
            }
        } catch (error: any) {
            console.error('Failed to upgrade plan:', error);
            // throw error;
        } finally {
            setLoadingState('idle');
            setStep('select-plan');
            onSuccess();
        }
    };

    if (!subscription) {
        return null;
    }

    return (
        <Dialog open={isOpen} onOpenChange={handleClose} modal={!isChargebeeOpen}>
            <DialogContent className="max-w-5xl bg-gradient-to-br from-brand-100 to-background-warning-primary/10">
                <DialogHeader>
                    <DialogTitle>Upgrade your Andsend account</DialogTitle>
                    <DialogDescription>
                        {upgradePlanErrorText && (
                            <span className="text-destructive font-bold">* {upgradePlanErrorText}</span>
                        )}
                    </DialogDescription>
                </DialogHeader>
                {step === 'select-plan' && (
                    <PlanSelection
                        onSelectPlan={async (plan, selectedPackage) => await handleSelectPlan(plan, selectedPackage)}
                        subscription={subscription}
                        availablePlans={availablePlans}
                        selectedBillingPeriod={selectedBillingPeriod}
                        onBillingPeriodChange={handleBillingPeriodChange}
                        loadingState={loadingState}
                    />
                )}
                {selectedPlan && step === 'confirm' && (
                    <ConfirmationView
                        onBack={handleBack}
                        selectedPlan={selectedPlan}
                        onLoadSelfServicePortal={loadSelfServicePortal}
                        onPurchase={handlePurchase}
                        subscription={subscription}
                        couponCode={couponCode}
                        onCouponCodeChange={handleCouponCode}
                        isCouponCodeValid={isCouponCodeValid}
                        loadingState={loadingState}
                        selectedPlanEstimatesSummary={selectedTeamPlanEstimatesSummary}
                        selectedPlanEstimatesTotal={selectedTeamPlanEstimatesTotal}
                        refetchSubscription={refetchSubscription}
                    />
                )}
            </DialogContent>
        </Dialog>
    );
};
