import { useEffect } from 'react';
import { useApps } from './hooks/use-apps';
import { useCrmAccounts } from './hooks/use-crm-accounts';
import { OutlookAuthCard } from './components/outlook-auth-card';
import { Button } from '@shadcn/ui/components/ui/button';
import { Card, CardContent } from '@shadcn/ui/components/ui/card';
import { X } from 'lucide-react';
import { GmailAuthCard } from './components/gmail-auth-card';
import { Alert, AlertDescription } from '@shadcn/ui/components/ui/alert';
import { LinkedInAuthCard } from './components/linkedin-auth-card';
import { HubspotAuthCard } from './components/hubspot-auth-card';
import { PipedriveAuthCard } from './components/pipedrive-auth-card';
import { SalesforceAuthCard } from './components/salesforce-auth-card';
import { Link } from 'react-router-dom';
// Gmail OAuth component

export const AppsPage = () => {
    const {
        accounts: emailAccounts,
        isLoading: emailLoading,
        error,
        clearError,
        handleGmailAuth,
        handleOutlookAuth,
        connectToLinkedIn,
        linkedInConnectionStatus,
        extensionStatus,
        isConnecting,
    } = useApps();

    const { accounts: crmAccounts, isLoading: crmLoading, disconnectCrm } = useCrmAccounts();

    // Handle messages from OAuth popups
    useEffect(() => {
        const handleWindowMessage = (event) => {
            // Ensure the message is from our domain
            if (event.origin !== window.location.origin) return;

            // Process auth codes
            if (event.data && event.data.code) {
                if (event.data.service === 'outlook') {
                    handleOutlookAuth(event.data.code);
                } else if (event.data.service === 'gmail') {
                    handleGmailAuth(event.data.code);
                }
            }
        };

        window.addEventListener('message', handleWindowMessage);
        return () => window.removeEventListener('message', handleWindowMessage);
    }, [handleGmailAuth, handleOutlookAuth]);

    return (
        <div className="container py-0 space-y-6">
            <div className="flex items-center justify-between">
                <h1 className="text-2xl font-bold tracking-tight">Connect your apps</h1>
            </div>

            {error && (
                <div
                    className="transition-opacity duration-300 ease-in-out opacity-100"
                    onTransitionEnd={(e) => {
                        if (
                            e.propertyName === 'opacity' &&
                            e.target === e.currentTarget &&
                            e.currentTarget.style.opacity === '0'
                        ) {
                            clearError();
                        }
                    }}
                >
                    <Alert variant="destructive" className="mb-4">
                        <div className="flex items-center justify-between w-full">
                            <div dangerouslySetInnerHTML={{ __html: error }} />
                            <AlertDescription>
                                <Button
                                    variant="ghost"
                                    size="sm"
                                    className="h-6 w-6 p-0"
                                    onClick={(e) => {
                                        (e.currentTarget.closest('.transition-opacity') as HTMLElement).style.opacity =
                                            '0';
                                    }}
                                >
                                    <X className="h-4 w-4" />
                                </Button>
                            </AlertDescription>
                        </div>
                    </Alert>
                </div>
            )}
            <div>
                <h2 className="text-xl font-medium mb-4 border-b pb-1">Social</h2>

                <div className="grid md:grid-cols-3 gap-6">
                    <LinkedInAuthCard
                        onConnect={connectToLinkedIn}
                        isLoading={emailLoading || linkedInConnectionStatus === 'pending'}
                        isAdded={emailAccounts.linkedin.isAdded}
                        status={emailAccounts.linkedin.status}
                        connectionStatus={linkedInConnectionStatus}
                    />
                    {/* Additional social account cards can be added here */}
                </div>
            </div>
            <div>
                <h2 className="text-xl font-medium mb-4 border-b pb-1">Email</h2>

                <div className="grid md:grid-cols-3 gap-6">
                    {!emailAccounts.gmail.isAdded && !emailAccounts.outlook.isAdded ? (
                        <>
                            <GmailAuthCard
                                onSuccess={handleGmailAuth}
                                isLoading={emailLoading || isConnecting}
                                isAdded={emailAccounts.gmail.isAdded}
                                status={emailAccounts.gmail.status}
                            />
                            <OutlookAuthCard
                                onSuccess={handleOutlookAuth}
                                loading={emailLoading || isConnecting}
                                isAdded={emailAccounts.outlook.isAdded}
                                status={emailAccounts.outlook.status}
                            />
                        </>
                    ) : emailAccounts.gmail.isAdded ? (
                        <GmailAuthCard
                            onSuccess={handleGmailAuth}
                            isLoading={emailLoading || isConnecting}
                            isAdded={emailAccounts.gmail.isAdded}
                            status={emailAccounts.gmail.status}
                        />
                    ) : (
                        <OutlookAuthCard
                            onSuccess={handleOutlookAuth}
                            loading={emailLoading || isConnecting}
                            isAdded={emailAccounts.outlook.isAdded}
                            status={emailAccounts.outlook.status}
                        />
                    )}
                </div>
            </div>

            <div>
                <h2 className="text-xl font-medium mb-4 border-b pb-1">CRM</h2>
                {import.meta.env.MODE === 'development' && (
                    <div className="grid md:grid-cols-3 gap-6">
                        <HubspotAuthCard
                            isActive={crmAccounts?.hubspot?.settings?.isActive}
                            isConnected={!!crmAccounts?.hubspot?.settings}
                            isLoading={crmLoading}
                            onConnect={() => {}}
                            onDisconnect={() => disconnectCrm('hubspot')}
                        />
                        <PipedriveAuthCard
                            isActive={crmAccounts?.pipedrive?.settings?.isActive}
                            isConnected={!!crmAccounts?.pipedrive?.settings}
                            isLoading={crmLoading}
                            onConnect={() => {}}
                            onDisconnect={() => disconnectCrm('pipedrive')}
                        />
                        <SalesforceAuthCard
                            isActive={crmAccounts?.salesforce?.settings?.isActive}
                            isConnected={!!crmAccounts?.salesforce?.settings}
                            isLoading={crmLoading}
                            onConnect={() => {}}
                            onDisconnect={() => disconnectCrm('salesforce')}
                        />
                    </div>
                )}
                {import.meta.env.MODE !== 'development' && (
                    <div className="flex ">
                        <Button variant="default" asChild>
                            <Link to="/integrations">Integrations</Link>
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};
