import { Box, Link, Typography } from '@mui/material';
import { AccountResponseDto } from '@zaplify/prospects';
import { MapPin, UsersRound, Globe, Linkedin } from 'lucide-react';
import { CardClass, LinkClass, userClickedExternalLinkInGlobalSearch } from '../tracking';

export const DetailsSection = ({ account }: { account: AccountResponseDto }) => {
    const niceUrl = (url: string) => {
        if (!url) return '';
        return url.replace(/(https?:\/\/)?(www\.)?/i, '').replace(/\/$/, '');
    };

    const getEmployeeText = (numberOfEmployees: string) => {
        if (!numberOfEmployees) return '';
        if (numberOfEmployees.trim().endsWith('employees')) {
            return numberOfEmployees;
        } else {
            return `${numberOfEmployees} employees`;
        }
    };

    const details = [
        {
            icon: <MapPin size={20} strokeWidth={1} />,
            label: account.headquarters,
            condition: account.headquarters,
        },
        {
            icon: <UsersRound size={20} strokeWidth={1} />,
            label: `${getEmployeeText(account.numberOfEmployees)}`,
            condition: account.numberOfEmployees,
        },
        {
            icon: <Globe size={20} strokeWidth={1} />,
            label: (
                <Link
                    sx={{
                        overflow: 'hidden',
                        color: 'var(--others-links, #6583EF)',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontSize: '13px',
                        fontWeight: 400,
                        lineHeight: '19px',
                        textDecoration: 'none',
                    }}
                    href={account.website}
                    target="_blank"
                    onClick={() => userClickedExternalLinkInGlobalSearch(CardClass.Account, LinkClass.Website)}
                >
                    {niceUrl(account.website)}
                </Link>
            ),
            condition: account.website,
        },
        {
            icon: <Linkedin size={20} strokeWidth={1} />,
            label: (
                <Link
                    sx={{
                        overflow: 'hidden',
                        color: 'var(--others-links, #6583EF)',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontSize: '13px',
                        // fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '19px',
                        textDecoration: 'none',
                    }}
                    href={account.linkedinUrl}
                    target="_blank"
                    onClick={() => userClickedExternalLinkInGlobalSearch(CardClass.Account, LinkClass.LinkedIn)}
                >
                    {niceUrl(account.linkedinUrl)}
                </Link>
            ),
            condition: account.linkedinUrl,
        },
    ].filter((detail) => detail.condition);
    return (
        <>
            {details.map((detail, i) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        gap: 'var(--S-xs, 6px)',
                        alignSelf: 'stretch',
                    }}
                    key={i}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            width: '20px',
                            height: '20px',
                            padding: '2.5px 1.667px',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {detail.icon}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            alignContent: 'center',
                            gap: '2px var(--S-3xs, 2px)',
                            flex: '1 0 0',
                            alignSelf: 'stretch',
                            flexWrap: 'wrap',
                        }}
                    >
                        <Typography
                            sx={{
                                overflow: 'hidden',
                                color: 'var(--grey-palette-grey-5, #3A4B59)',
                                textOverflow: 'ellipsis',
                                fontSize: '13px',
                                // fontStyle: 'normal',
                                fontWeight: 400,
                                lineHeight: '19px',
                            }}
                        >
                            {detail.label}
                        </Typography>
                    </Box>
                </Box>
            ))}
        </>
    );
};
