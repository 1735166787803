import dayjs from 'dayjs';
import { WebExtensionAction } from './enums';
import { ExtensionErrorResponse } from './interfaces';

/* global chrome */
const extensionId =
    import.meta?.env?.VITE_ZAPLIFY_CHROME_EXTENSION_ID ||
    (typeof process !== 'undefined' && process?.env?.PLASMO_PUBLIC_ZAPLIFY_CHROME_EXTENSION_ID) ||
    'nolanbablkmlhllilaloenjhaplnfhof';

export class ChromeExtensionNotFoundError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'ChromeExtensionNotFoundError';
    }
}
type DirectRequestResponse =
    | { status: false; logs?: any[]; reason?: string; raw?: any; error?: any }
    | { status: number; logs?: any[]; text: string; headers: any };

const noResponseError = { error: 'NO_EXTENSION_RESPONSE', reason: 'No response from the extension' };
const timeoutError = { error: 'TIMEOUT', reason: 'Extension did not respond in time' };
const MIN_RELOAD_INTERVAL = 30_000;
let lastReloadTime = 0;

export const useWebExtensionCommunication = () => {
    const sendMessageToRuntime = async <T>(actionName: WebExtensionAction, payload?: any): Promise<T> => {
        return new Promise<T>((resolve, reject) => {
            if (window['chrome'] && window['chrome'].runtime) {
                const replyHandler = (reply: any) => {
                    if (chrome.runtime.lastError) {
                        reject(chrome.runtime.lastError);
                    }
                    resolve(reply);
                };
                const message = { action: actionName, payload, replyHandler };
                window['chrome'].runtime.sendMessage(extensionId, message, replyHandler);

                const timeoutPromise = new Promise((_, timeoutReject) => {
                    setTimeout(() => {
                        timeoutReject(timeoutError);
                    }, 60000);
                });

                return Promise.race([replyHandler, timeoutPromise]);
            }
            reject(new ChromeExtensionNotFoundError('No chrome runtime found'));
        });
    };

    const handleErrors = async (error: ExtensionErrorResponse | null) => {
        if (error === null) {
            await reloadExtension();
            return;
        }
        if (error === noResponseError) {
            await reloadExtension();
        } else if (error.error === 'linkedin_no_tab') {
            await refreshLinkedinTab();
        } else if (error.error === 'linkedin_not_accessible') {
            await reloadExtension();
        } else if (error === timeoutError) {
            await reloadExtension();
        }
    };

    const sendDirectRequest = async (payload: {
        url: string;
        method: string;
        headers: any;
        body: any;
    }): Promise<DirectRequestResponse> => {
        return sendRequest(WebExtensionAction.DIRECT_REQUEST_GQL_ENDPOINT, payload);
    };

    const sendRequest = async <T>(action: WebExtensionAction, payload: any): Promise<T> => {
        try {
            console.log(dayjs().format('HH:mm:ss'), 'Track Linkedin: Sending request:', action, payload);
            let res = (await sendMessageToRuntime<T>(action, payload)) as T | ExtensionErrorResponse;
            if (!res) {
                res = noResponseError;
            }
            if (res && typeof res === 'object' && 'error' in res && 'reason' in res) {
                console.error({
                    message: 'Received error from extension',
                    request: {
                        action,
                        payload,
                    },
                    response: res,
                });
                await handleErrors(res);
                throw res;
            }
            return res as T;
        } catch (err) {
            console.error({
                message: 'Received exception from chrome runtime',
                request: {
                    action,
                    payload,
                },
                error: err,
            });
            throw err;
        }
    };

    const reloadExtension = () => {
        console.error('Error detected, reloading extension');

        return (() => {
            const now = Date.now();
            if (now - lastReloadTime > MIN_RELOAD_INTERVAL && Math.random() > 0.5) {
                // Some randomness so we don't reload on every request
                lastReloadTime = now;
                return sendMessageToRuntime(WebExtensionAction.GLOBAL_RELOAD_EXTENSION, {}) as Promise<void>;
            } else {
                console.warn('Reload request ignored. Please wait for 30 seconds between each reload.');
                return Promise.resolve();
            }
        })();
    };

    const refreshLinkedinTab = () => {
        console.warn('Error detected, refreshing LI tab');
        return sendMessageToRuntime(WebExtensionAction.GLOBAL_REFRESH_LINKEDIN_TAB, {}) as Promise<void>;
    };

    return {
        sendMessageToRuntime,
        handleErrors,
        sendRequest,
        sendDirectRequest,
        reloadExtension,
        refreshLinkedinTab,
    };
};
