import * as React from 'react';
import {
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogFooter,
    DialogClose,
    DialogDescription,
} from '@shadcn/ui/components/ui/dialog';
import { Button } from '@shadcn/ui/components/ui/button';
import { SearchParamDialog, useSearchParamDialog } from '@shadcn/ui/components/search-param-dialog';
import { Progress } from '@shadcn/ui/components/ui/progress';
import { useQuery as useQueryTan } from '@tanstack/react-query';
import { useSdk } from '../../sdk';
import { useReferFriendsDialog } from './refer-friends-dialog';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../../routes/paths';

export const useContactLimitReachedDialog = () => {
    const { open, close, value } = useSearchParamDialog('contact-limit-reached');

    return {
        openContactLimitReachedDialog: () => open(),
        closeContactLimitReachedDialog: close,
        value,
    };
};

export const ContactLimitReachedDialog: React.FC = () => {
    const { closeContactLimitReachedDialog } = useContactLimitReachedDialog();
    const navigate = useNavigate();
    const {
        userContacts: { getUserContactLimit },
    } = useSdk();
    const { data: limit, isLoading: limitLoading } = useQueryTan(getUserContactLimit());

    const handleUpgrade = () => {
        navigate(paths.ACCOUNT_MANAGEMENT + '/subscription');
    };

    return (
        <SearchParamDialog param={'contact-limit-reached'}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Contact Limit Reached</DialogTitle>
                </DialogHeader>
                <DialogDescription>
                    You have reached the maximum number of contacts allowed in your current plan.
                </DialogDescription>

                {!limitLoading && (
                    <div className="w-full flex flex-col gap-2 text-sm text-foreground-secondary">
                        <span className="[&>*]:inline-block">
                            <span className="font-semibold">
                                {limit} / {limit}
                            </span>{' '}
                            contacts used
                        </span>
                        <Progress value={100} className="[&>div]:bg-background-error-primary" />
                    </div>
                )}
                <p className="text-sm">To add more contacts, please upgrade your plan or refer friends.</p>
                <DialogFooter>
                    <DialogClose asChild>
                        <Button variant="outline">Close</Button>
                    </DialogClose>
                    <Button onClick={handleUpgrade} autoFocus>
                        Upgrade
                    </Button>
                </DialogFooter>
            </DialogContent>
        </SearchParamDialog>
    );
};
