import { useAuth } from '../providers/authentication-provider';

/**
 * Hook to access the current authenticated user's data
 * @throws {Error} If used outside of an authenticated context
 * @returns {UserData} Object containing user information
 */
export function useUser() {
    const {
        authState: { user },
    } = useAuth();

    if (!user) {
        throw new Error('useUser must be used within an authenticated context');
    }

    return {
        userId: user.id, // this naming lends itself to more readable code
        ...user,
    };
}
