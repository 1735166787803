import {
    IPlan,
    CreateCheckoutEstimateDto,
    CreatedEstimateDto,
    CreateNewCheckoutDto,
    HostedCheckout,
} from '@zaplify/subscriptions';
import { BaseSdk } from '../../base.sdk';

export class BillingSdk extends BaseSdk {
    //ADD RETURN TYPE
    async getAvailablePlans() {
        return await this.get<{ plans: IPlan[] }>(`${this.billingPath}/plans`);
    }

    async createCheckoutEstimate(createCheckoutEstimate: CreateCheckoutEstimateDto) {
        return this.post<CreatedEstimateDto>({
            path: `${this.billingPath}/checkout/estimate`,
            payload: {
                ...createCheckoutEstimate,
            },
        });
    }

    async createCheckoutNew(createNewCheckout: CreateNewCheckoutDto, callback?: (...args: any) => any) {
        return this.post<HostedCheckout>({
            path: `${this.billingPath}/checkout/new`,
            payload: {
                ...createNewCheckout,
            },
            callback,
        });
    }

    async createFreeSubscription(headers?: { [key: string]: string }) {
        return this.post<void>({
            path: `${this.billingPath}/subscription/new`,
            headers,
        });
    }

    async verifyCouponCode(couponCode: string) {
        return this.post<{ isValid: boolean }>({
            path: `${this.discountsPath}/coupons/${couponCode}/verify`,
        });
    }
}
