import { BaseSdk } from '../../base.sdk';
import { SendTestEmailDto } from '@zaplify/campaigns';
import { WorkflowTemplatesDto } from '@zaplify/campaigns';
import { WorkflowTemplateDto } from '@zaplify/campaigns';

export class WorkflowSdk extends BaseSdk {
    async getWorkflowTemplates(): Promise<WorkflowTemplateDto[]> {
        return [];
    }

    async sendTestEmail(campaignId: string, sendTestEmailDto: SendTestEmailDto) {
        return [];
    }
}
