import { useToast } from '@shadcn/ui/hooks/use-toast';
import {
    Toast,
    ToastClose,
    ToastDescription,
    ToastProvider,
    ToastTitle,
    toastVariants,
    ToastViewport,
} from '@shadcn/ui/components/ui/toast';
import { CheckCircle, TriangleAlert } from 'lucide-react';
import { type VariantProps } from 'class-variance-authority';

const ToastIcon = ({ variant }: { variant: string }) => {
    if (!variant || variant === 'success' || variant === 'default') {
        return <CheckCircle className="w-4 h-4 text-foreground-success-primary" />;
    } else if (variant === 'warning') {
        return <TriangleAlert className="w-4 h-4 text-foreground-warning-primary" />;
    } else if (variant === 'error' || variant === 'destructive') {
        return <TriangleAlert className="w-4 h-4 text-foreground-error-primary" />;
    }
};

export function Toaster() {
    const { toasts } = useToast();

    return (
        <ToastProvider>
            {toasts.map(function ({ id, title, description, action, variant, ...props }) {
                return (
                    <Toast key={id} {...props}>
                        <div className="flex h-full justify-center rounded-full bg-secondary mt-1">
                            <ToastIcon variant={variant} />
                        </div>
                        <div className="flex flex-col gap-1">
                            {title && <ToastTitle>{title}</ToastTitle>}
                            {description && <ToastDescription>{description}</ToastDescription>}
                        </div>
                        {action}
                        <ToastClose />
                    </Toast>
                );
            })}
            <ToastViewport />
        </ToastProvider>
    );
}
