import {
    LinkedinVoyagerConversationMessage,
    ConversationMessageParsed,
    LinkedinVoyagerRenderContent,
    ConversationMessageAttachmentParsed,
} from './interfaces';

export function parseConversationMessage(
    message: LinkedinVoyagerConversationMessage,
    userMemberId: string,
): ConversationMessageParsed | undefined {
    if (message?._type !== 'com.linkedin.messenger.Message') return undefined;

    const senderURN = message.sender.entityUrn;
    const senderId = senderURN.split('urn:li:fsd_profile:')[1];

    const participantUserIds =
        message.conversation.conversationParticipants?.map(
            (participant) => participant.entityUrn.split('urn:li:fsd_profile:')[1],
        ) || [];
    const receivers = participantUserIds.filter((participantId) => participantId !== senderId);

    const messageText = message.body.text;
    const messageSubject = message.subject ? message.subject : undefined;
    const messageId = message.backendUrn.split('urn:li:messagingMessage:')[1];
    const conversationId = message.backendConversationUrn.split('urn:li:messagingThread:')[1];
    const timestamp = message.deliveredAt;

    const direction = senderId === userMemberId ? 'OUT' : 'IN';

    return {
        direction,
        timestamp: timestamp,
        data: {
            senderId: senderId,
            receiverIds: receivers,
            entityUrn: message.entityUrn,
            subject: messageSubject,
            message: messageText,
            messageId: messageId,
            conversationId: conversationId,
            attachments: parseMessageAttachments(message.renderContent),
        },
    };
}

function parseMessageAttachments(
    renderContent: LinkedinVoyagerRenderContent[],
): ConversationMessageAttachmentParsed | undefined {
    try {
        const imageUrls = renderContent?.map((content) => content?.vectorImage?.rootUrl)?.filter((x) => x);

        const feedUpdateHostUrns = renderContent
            ?.filter((content) => content?.hostUrnData?.type === 'FEED_UPDATE')
            ?.map((content) => content?.hostUrnData?.hostUrn);

        // Has the structure "urn:li:fsd_update:(urn:li:activity:666666,MESSAGING_RESHARE,EMPTY,DEFAULT,false)"
        // These can be mapped to linkedin.com/feed/update/urn:li:activity:66666/
        const feedUpdateUrls = feedUpdateHostUrns
            ?.map((hostUrn) => {
                try {
                    const urlEnd = hostUrn?.match(/urn:li:activity:\d+/)?.[0];
                    if (!urlEnd) return undefined;
                    return `https://linkedin.com/feed/update/${urlEnd}`;
                } catch (error) {
                    console.error('Error parsing feed update host urn:', error);
                    return undefined;
                }
            })
            .filter((x) => x) as string[];

        return {
            imageUrls,
            feedUpdates: feedUpdateUrls,
        };
    } catch (error) {
        console.error('Error parsing message attachments:', error);
        return undefined;
    }
}
