import { styled } from '@mui/styles';

const OrderSummaryStyled = styled('div')(({ theme: { palette } }: any) => ({
    margin: '0 46px',
    borderRadius: 14,
    backgroundColor: palette.colors.white,

    '& .rounded': { borderRadius: 8, width: '100%' },
    '& .plan-name': { fontSize: 17, fontWeight: 500 },
    '& .plan-price': { fontSize: 15 },
    '& .credits': { fontSize: 15, fontWeight: 500 },
    '& .monthly': { fontSize: 15 },
    '& .renewal-at': { fontSize: 13, color: palette.colors.greyDark },
    '& .total-label': { fontSize: 15, fontWeight: 500 },
    '& .change-credits-container': {
        display: 'flex',
        marginLeft: '28px',
        alignItems: 'center',
        paddingBottom: 5,
        '& .change-button': {
            color: palette.darkBlue,
            fontSize: 14,
            fontWeight: 500,
            textDecoration: 'underline',
            cursor: 'pointer',
            marginLeft: 7,
        },
    },
    '& .divider': { backgroundColor: palette.colors.greyInformationLight },
    '& .total-price': { fontSize: 17, fontWeight: 500 },
    '& .purchase': {
        width: '100%',

        '&-button': {
            width: '100%',
            boxSizing: 'border-box',
            borderRadius: 8,
            backgroundColor: palette.colors.bluePale,
            padding: 14,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 15,
            fontWeight: 500,
            color: palette.colors.white,
        },
    },
    '& .billing-info': {
        fontSize: 13,
        color: palette.colors.greyDark,
    },
}));

export default OrderSummaryStyled;
