import { webCryptoRandom } from '@zaplify/frontend-common';
import { IConnectToPortArg } from '../types/extension';
import * as cheerio from 'cheerio';
import { MessageType } from '@zaplify/services/messaging/shared';
import { ChromeExtensionNotFoundError } from '@zaplify/sdk';
import { convert as convertHtmlToText } from 'html-to-text';

export function getRandomColorsWithContrast(): { color: string; backgroundColor: string } {
    const hue = Math.floor(webCryptoRandom() * 360);
    const saturation = Math.floor(webCryptoRandom() * 100);
    const light = Math.floor(webCryptoRandom() * 100);
    const threshold = 65;
    const backgroundColor = `hsl(${hue}, ${saturation}% , ${light}%)`;
    const switchColor = Math.sign(light - threshold) * -100;
    const color = `hsl(0, 0%, ${switchColor}%)`;

    return { color, backgroundColor };
}
export function isNumber(value: any) {
    return typeof value === 'number' || value instanceof Number;
}
export function isString(value: any) {
    return typeof value === 'string' || value instanceof String;
}

const extensionId = import.meta?.env?.VITE_ZAPLIFY_CHROME_EXTENSION_ID || 'nolanbablkmlhllilaloenjhaplnfhof';

export function importHTML(html: string) {
    const stringCopy = (' ' + html).slice(1);
    const parser = new DOMParser();
    const parsedDocument = parser.parseFromString(stringCopy, 'text/html');
    const formattedString = parsedDocument.documentElement.innerHTML;
    return (' ' + formattedString).slice(1);
}

export function htmlToPlain(inputString: string, options = {}) {
    // Check if there is any HTML in the input
    const isHtml = /<[a-z][\s\S]*>/i.test(inputString);
    if (!isHtml) {
        return inputString;
    }

    let html = inputString;
    // (End-section + Newline + New-section) should just be End-section + New-section
    html = html.replaceAll('</div><br><div>', '</div><div>');

    // (New-section + Newline + End-section) should just be Newline
    html = html.replaceAll('<div><br></div>', '<br>');

    const text = convertHtmlToText(html, { wordwrap: false, ...options })
        .replace(/ | | | | | | | | | /gi, ' ')
        .trim();
    return text;
}

const reconnectWhenReady = ({ extensionId, portName, messageListener, onReconnected }: IConnectToPortArg) => {
    let port: chrome.runtime.Port | undefined;
    return port;
    // if (
    //   isChromeAvailable() === !1 ||
    //   !(await checkExtensionConnection(
    //     () =>
    //       la({
    //         extensionId: extensionId,
    //         message: {
    //           action: "GLOBAL/READY_TO_CONNECT",
    //         },
    //       }),
    //     1e3,
    //     5,
    //     []
    //   ))
    // )
    //   return;
    // const o = await uR({
    //   extensionId: extensionId,
    //   portName: portName,
    //   messageListener: messageListener,
    //   onReconnected: onReconnected,
    // });
    // return onReconnected && onReconnected(), o.port;
};

let port: chrome.runtime.Port | undefined;

export const connectCE = async ({
    extensionId,
    portName,
    messageListener,
    onReconnected,
}: IConnectToPortArg & {
    onConnected?: () => void;
}): Promise<{
    success: boolean;
    port?: chrome.runtime.Port;
}> => {
    return new Promise((resolve, reject) => {
        if (!window?.chrome?.runtime) {
            return reject('Chrome runtime not found');
        }

        port = window.chrome.runtime.connect(extensionId, {
            name: portName,
        });
        port.onDisconnect.addListener(async () => {
            console.log('disconnected from chrome extension');
            if (port) {
                port.onMessage.removeListener(messageListener);
                port.disconnect();
                port = undefined;
            }
            const reconnectedPort = (await reconnectWhenReady({
                extensionId: extensionId,
                portName: portName,
                messageListener: messageListener,
                onReconnected: onReconnected,
            })) as any;
            if (!reconnectedPort || reconnectedPort['errors']) {
                // Handle error
            } else {
                port = reconnectedPort;
                port!.onMessage.addListener(messageListener);
            }
        });
        port.onMessage.addListener(messageListener);
        console.log('connected to chrome extension port', portName);

        // @TODO Figure out how to resolve on connect
        resolve({
            success: true,
            port: port,
        });
    });
};

export const sendMessageToRuntime = async (actionName: any, payload?: any) => {
    if (window['chrome'] && window['chrome'].runtime) {
        return new Promise((resolve, reject) => {
            const replyHandler = (reply: any) => {
                if (chrome.runtime.lastError) {
                    return reject(chrome.runtime.lastError);
                }
                resolve(reply);
            };
            const message = { action: actionName, payload, replyHandler };
            window['chrome'].runtime.sendMessage(extensionId, message, replyHandler);
        });
    } else {
        throw new ChromeExtensionNotFoundError('No chrome runtime found');
    }
};

export function getSendMessageChannelErrorsText(
    channelAccounts: {
        email: {
            value: string | null;
            status: 'SCOPES' | 'NOT_AUTHENTICATED' | 'AUTHENTICATED' | 'LOADING';
        };
        linkedIn: {
            value: string | null;
            status: 'SCOPES' | 'NOT_AUTHENTICATED' | 'AUTHENTICATED' | 'LOADING';
        };
    },
    selectedMessageType: MessageType.emailMessage | MessageType.linkedinMessage | MessageType.linkedinConnectionRequest
) {
    const { email, linkedIn } = channelAccounts;

    switch (selectedMessageType) {
        case MessageType.emailMessage: {
            if (email.status === 'AUTHENTICATED') {
                return null;
            }
            if (email.status === 'LOADING') {
                return null;
            }
            if (email.status === 'NOT_AUTHENTICATED') {
                return 'Connect your email account to send and receive emails.';
            }
            if (email.status === 'SCOPES') {
                return 'Reconnect your email to send and receive emails.';
            }
            break;
        }
        case MessageType.linkedinConnectionRequest:
        case MessageType.linkedinMessage: {
            if (linkedIn.status === 'AUTHENTICATED') {
                return null;
            }

            if (linkedIn.status === 'LOADING') {
                return null;
            }
            if (linkedIn.status === 'NOT_AUTHENTICATED') {
                return 'Connect your LinkedIn account to send and receive LinkedIn messages.';
            }
        }
    }

    return null;
}

type EmailState = 'Email exists' | 'Email missing' | 'Enrichment';
export function getEmailState(email: string | null | undefined): EmailState {
    let emailState: EmailState;
    const emailStatuses = {
        enriching: '...',
        emailIssues: ['none', 'None', '', null],
    };

    if (email && isEmail(email)) {
        emailState = 'Email exists';
    } else if (email === emailStatuses.enriching) {
        emailState = `Enrichment`;
    } else if (email && emailStatuses.emailIssues.includes(email)) {
        emailState = 'Email missing';
    } else {
        emailState = 'Email missing';
    }
    return emailState;
}

const isEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};
