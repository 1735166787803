import { getZaplifySdk } from '@zaplify/sdk';
import { useRef } from 'react';
import { useDispatch } from 'react-redux-latest';
import { ChargebeePortalSection } from '../../enums/chargebee';
import { updateSubscription } from '../../redux/actions';
import { AppDispatch } from '../../redux/store/configureStore';

export const useSelfServicePortal = () => {
    const dispatch = useDispatch<AppDispatch>();
    const cbInstance = useRef(null);

    const loadSelfServicePortal = async (section?: ChargebeePortalSection, paymentSourceAddCallback?: any) => {
        const profilesSdk = getZaplifySdk().profiles;

        if (!cbInstance.current) {
            cbInstance.current = window.Chargebee.init({ site: import.meta.env.VITE_CHARGEBEE_SITE });
        }

        await cbInstance.current.setPortalSession(function () {
            return profilesSdk.subscription.createPortalSession();
        });

        const cbPortal = cbInstance.current.createChargebeePortal();

        const updateSubscriptionAfterChange = () => {
            dispatch(updateSubscription());
        };

        const callbacks = {
            close: function () {
                console.log('close');
                updateSubscriptionAfterChange();
            },
            paymentSourceAdd: async function () {
                console.log('paymentSourceAdd');
                updateSubscriptionAfterChange();
                if (paymentSourceAddCallback) {
                    cbInstance.current.closeAll();
                    paymentSourceAddCallback();
                }
            },
            paymentSourceUpdate: function () {
                console.log('paymentSourceUpdate');
                updateSubscriptionAfterChange();
            },
            subscriptionChanged: function () {
                console.log('subscriptionChanged');
                updateSubscriptionAfterChange();
            },
            subscriptionCustomFieldsChanged: function () {
                updateSubscriptionAfterChange();
            },
        };

        if (section) {
            cbPortal.openSection(
                {
                    sectionType: window.Chargebee.getPortalSections()[section],
                },
                callbacks
            );
        } else {
            cbPortal.open(callbacks);
        }
    };

    return { loadSelfServicePortal };
};
