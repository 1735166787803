import { getZaplifySdk } from '@zaplify/sdk';
import { createMutationOptions } from './sdk-utils';
import { IMessage } from '@zaplify/campaigns';
import { MessageType } from '@zaplify/services/messaging/shared';
import { ChannelType } from '@zaplify/channel-accounts/shared';

export const regenerateMessage = () => {
    return createMutationOptions<
        IMessage,
        Error,
        {
            messageId: string;
            feedback: string;
            content?: string;
            subjectLine?: string;
            actionType: MessageType;
        }
    >({
        mutationFn: (variables) => getZaplifySdk().profiles.campaigns.regenerateMessage(variables),
        invalidateQueries: () => [],
    });
};

export const getRegeneratedMessage = () => {
    return createMutationOptions<
        { content: string; subjectLine?: string },
        Error,
        {
            prospectId: string;
            channelType?: ChannelType;
            actionType: MessageType;
            feedback: string;
            message: { subjectLine?: string; content: string };
        }
    >({
        mutationFn: (variables) => getZaplifySdk().profiles.campaigns.getRegeneratedMessage(variables),
        invalidateQueries: () => [],
    });
};

export const generateMessageAsEmail = () => {
    return createMutationOptions<
        IMessage,
        Error,
        {
            messageId: string;
            content: string;
            subjectLine: string;
        }
    >({
        mutationFn: (variables) => getZaplifySdk().profiles.campaigns.generateMessageAsEmail(variables),
        invalidateQueries: () => [],
    });
};
