import { IEvent, Topics } from '@zaplify/common';

export class LinkedinConversationCreatedEvent extends IEvent {
    public static override readonly TOPIC: Topics = Topics.CAMPAIGNS;
    public readonly userId: string;
    public readonly prospectId?: string;
    public readonly conversationId: string;
    public readonly participantMemberIds: string[];

    constructor(params: {
        userId: string;
        prospectId?: string;
        conversationId: string;
        participantMemberIds: string[];
    }) {
        super();
        this.userId = params.userId;
        this.prospectId = params.prospectId;
        this.conversationId = params.conversationId;
        this.participantMemberIds = params.participantMemberIds;
    }

    get partitionKey(): string {
        return this.userId;
    }
}
