import { useState } from 'react';
import { ReactComponent as Google } from '../../../../components/icons/google.svg';
import { signInWithGoogle } from '../../../../services/firebase';
import { Button } from '@shadcn/ui/components/ui/button';

export const GoogleAuthButton = ({ children }: { children: React.ReactNode }) => {
    const [localError, setLocalError] = useState('');

    const handleSubmit = async (event) => {
        setLocalError('');
        try {
            await signInWithGoogle();
            return '';
        } catch (error: any) {
            if (error?.message && error.message.includes('private'))
                setLocalError("We don't support private emails. Please use a business email instead.");
        }
    };

    return (
        <>
            <Button variant="outline" className="w-full" onClick={handleSubmit} type="button">
                <Google className="w-4 h-4" />
                {children}
            </Button>
            {localError && <p className="text-sm text-destructive mt-2">{localError}</p>}
        </>
    );
};

export default GoogleAuthButton;
