import { CreditsDto, PlanCode } from '@zaplify/subscriptions';
import { OrganizationDto, UserOrganizationComposedDto } from './userOrganization.dto';
import { UserStatus, UserRole } from './constants';
import { IInvitationReduced, InvitationStatus } from './invitation.dto';
import { IUserAccount } from './userAccount';

export class AccountLocationDto {
    country: string;
    city: string;

    constructor(dto: AccountLocationDto) {
        this.country = dto?.country;
        this.city = dto?.city;
    }

    static fromDocument(location: AccountLocationDto) {
        const locationDto = new AccountLocationDto({
            country: location?.country,
            city: location?.city,
        });
        return locationDto;
    }
}

export interface IUserData {
    id?: any;
    firebaseId: string;
    userOrganizationId: string;
    email: string;
    firstName: string;
    lastName: string;
    handle: string;
    location: AccountLocationDto;
    isSubscribed: boolean;
    isActive: boolean;
    status: UserStatus;
    channelAccounts: string[];
    roles: UserRole[];
    seatId?: string;
    // referralLinkId?: string;
    createdAt?: Date;
}

export class UserDto implements IUserData {
    id?: string;
    firebaseId: string;
    userOrganizationId: string;
    email: string;
    firstName: string;
    lastName: string;
    handle: string;
    location: AccountLocationDto;
    isSubscribed: boolean;
    isActive: boolean;
    status: UserStatus;
    channelAccounts: string[];
    roles: UserRole[];
    seatId?: string;
    // referralLinkId?: string;
    createdAt?: Date;

    constructor(dto: UserDto) {
        this.id = dto.id;
        this.firebaseId = dto.firebaseId;
        this.userOrganizationId = dto.userOrganizationId;
        this.email = dto.email;
        this.firstName = dto.firstName;
        this.lastName = dto.lastName;
        this.handle = dto.handle;
        this.location = dto.location;
        this.isSubscribed = dto.isSubscribed;
        this.isActive = dto.isActive;
        this.status = dto.status;
        this.channelAccounts = dto.channelAccounts;
        this.roles = dto.roles;
        this.seatId = dto.seatId;
        // this.referralLinkId = dto.referralLinkId;
        this.createdAt = dto.createdAt;
    }

    static fromDocument(user: UserDto) {
        if (!user) return null;
        const userDto = new UserDto({
            id: user.id,
            firebaseId: user.firebaseId,
            userOrganizationId: user.userOrganizationId,
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            handle: user.handle,
            location: AccountLocationDto.fromDocument(user.location),
            isSubscribed: user.isSubscribed,
            isActive: user.isActive,
            status: user.status,
            channelAccounts: user?.channelAccounts || [],
            roles: user.roles,
            seatId: user.seatId,
            // referralLinkId: user.referralLinkId,
            createdAt: user?.createdAt || new Date(),
        });

        return userDto;
    }

    static fullName(userDto: UserDto) {
        return `${userDto.firstName} ${userDto.lastName}`;
    }

    static getSummarizedString(userDto: UserDto) {
        return `
        Full name: ${userDto.firstName} ${userDto.lastName}
        Email: ${userDto.email}
        Location: ${userDto.location}
        `;
    }
}

export const UserRoleDictionary: { [K in keyof typeof UserRole]: string } = {
    [UserRole.ORGANIZATION_ADMIN]: 'Admin',
    [UserRole.ORGANIZATION_MEMBER]: 'Standard',
    [UserRole.GLOBAL_ADMIN]: 'Global Admin',
    // [UserRole.SYSTEM_ADMIN]: 'System Admin',
    // [UserRole.BILLING_ADMIN]: 'Billing Admin',
    // [UserRole.TEAM_LEADER]: 'Team Leader',
};

export interface UserOrganizationCredits extends CreditsDto {
    addons: [];
    creditsAvailable: number;
    creditsLimit: number | string;
    creditsUsed: number;
    creditsUsedThisMonth: number;
    organizationId: string;
    planCode: PlanCode;
    seatId: string;
    userId: string;
}
export interface UserOrInvitationRowDto {
    id: string;
    firebaseId: string;
    email: string;
    createdAt: string;
    firstName: string;
    lastName: string;
    location: AccountLocationDto;
    isSubscribed: boolean;
    personId: string;
    channelAccounts: string[];
    roles: UserRole[];
    credits: UserOrganizationCredits;
    status: UserStatus | InvitationStatus;
    invitationId: string;
    seatId?: string | null;
    isActive: boolean;
}

export interface UserViewDto {
    id?: string;
    firebaseId: string;
    email: string;
    firstName: string;
    lastName: string;
    location: AccountLocationDto;
    isSubscribed: boolean;
    isActive: boolean;
    // userOrganizationIds: string[];
    userOrganizationId: string;
    channelAccounts: string[];
    createdAt?: Date;
    role: UserRole;
    roles: UserRole[];
    status: UserStatus;
    signatures: string[];
    // referralLinkId?: string;

    // THIS WAS THERE BEFORE, BUT IS NOT THE ACTUAL RETURNED TYPE

    // personId: string;
    // channelAccounts: ChannelAccountDto[];
    // credits: UserOrganizationCredits;
    // invitationId: string;
    // seatId?: string | null;
}

export const UserStatusDictionary: { [K in keyof typeof UserStatus]: string } = {
    [UserStatus.ACTIVATED]: 'Active',
    [UserStatus.DEACTIVATED]: 'Deactivated',
    [UserStatus.INVITED]: 'Invited',
    [UserStatus.NO_SEATS]: 'No seats',
    [UserStatus.NO_SEAT]: 'No seat',
};

export class UserComposedDto {
    id: string | undefined;
    firebaseId: string;
    firstName: string;
    lastName: string;
    email: string;
    handle: string;
    status: UserStatus;
    isActive: boolean;
    isSubscribed: boolean;
    location: AccountLocationDto;
    userOrganizationId: string | null;
    roles: UserRole[];
    createdAt: Date | undefined;
    userOrganization: any; // UserOrganizationComposedDto;
    channelAccounts: string[];
    apps: {
        hubSpot: any; // SettingsDto;
        pipedrive: any; // PipedriveSettingsDto;
        salesforce: any; // SalesforceSettingsDto;
        upsales: any; // UpsalesSettingsDto;
    };
    hasMultipleAccounts: boolean = false;

    constructor(dto: UserComposedDto) {
        this.id = dto.id;
        this.firebaseId = dto.firebaseId;
        this.firstName = dto.firstName;
        this.lastName = dto.lastName;
        this.email = dto.email;
        this.handle = dto.handle;
        this.location = dto.location;
        this.isActive = dto.isActive;
        this.isSubscribed = dto.isSubscribed;
        this.userOrganization = dto.userOrganization;
        this.channelAccounts = dto.channelAccounts;
        this.createdAt = dto.createdAt;
        this.userOrganizationId = dto.userOrganizationId;
        this.roles = dto.roles;
        this.status = dto.status;
        this.hasMultipleAccounts = dto.hasMultipleAccounts;
        this.apps = dto.apps;
    }

    static fromObjects(
        user: UserDto,
        userOrganization?: OrganizationDto,
        hubSpot?: any, // SettingsDto,
        pipedrive?: any, // PipedriveSettingsDto,
        salesforce?: any, // SalesforceSettingsDto,
        upsales?: any, // UpsalesSettingsDto,
        accounts?: { users: IUserAccount[]; invitations: IInvitationReduced[] }, // IUserAccount[],
    ) {
        return new UserComposedDto({
            id: user.id,
            firebaseId: user.firebaseId,
            userOrganizationId: user.userOrganizationId,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            handle: user.handle,
            status: user.status,
            isActive: user.isActive,
            isSubscribed: user.isSubscribed,
            location: user.location,
            roles: user.roles,
            createdAt: user.createdAt,

            userOrganization: userOrganization ? new UserOrganizationComposedDto(userOrganization, '') : null,
            channelAccounts: user.channelAccounts,
            apps: {
                hubSpot: hubSpot,
                pipedrive: pipedrive,
                salesforce: salesforce,
                upsales: upsales,
            },
            hasMultipleAccounts: (accounts?.users?.length || 0) + (accounts?.invitations?.length || 0) > 1,
        });
    }
}
