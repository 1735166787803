import { Box, CircularProgress as MUICircularProgress, circularProgressClasses } from '@mui/material';
import { paletteAndsend } from '@zaplify/ui';

interface CircularProgress {
    children: React.ReactNode;
    percentage: number;
    size?: number;
}

export const CircularProgress: React.FC<CircularProgress> = ({ children, percentage, size = 40 }) => {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <MUICircularProgress
                variant="determinate"
                value={100}
                size={size}
                thickness={6}
                sx={{
                    color: '#fff', // White background for the progress circle
                }}
            />
            <MUICircularProgress
                variant="determinate"
                value={percentage}
                size={size}
                thickness={6}
                sx={{
                    color: paletteAndsend.primary.light,
                    position: 'absolute',
                    left: 0,
                    [`& .${circularProgressClasses.circle}`]: {
                        strokeLinecap: 'butt',

                        filter: 'drop-shadow(0 0 1px rgba(0, 0, 0, 0.1))',
                    },
                }}
            />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {children}
            </Box>
        </Box>
    );
};
