import { ArrowBigUpDash } from 'lucide-react';
import { companyNameCorrectlyCased } from '../../enums/company-name';
import { Box, Typography } from '@mui/material';
import { Button } from '@zaplify/ui';

export const UpgradeSection: React.FC<{ onClickUpgrade: () => void }> = ({ onClickUpgrade }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                padding: 'var(--M-m-compact, 16px)',
                alignItems: 'center',
                gap: 'var(--M-m-compact, 16px)',
                alignSelf: 'stretch',
                borderRadius: 'var(--radius-lg, 14px)',
                background: 'var(--primary-extra-light, rgba(106, 118, 251, 0.10))',
                '& > svg': {
                    color: '#6A76FB',
                },
                '& > span > button': {
                    height: '40px',
                },
            }}
        >
            <ArrowBigUpDash size={40} strokeWidth={1} />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: 'var(--S-2xs, 4px)',
                    flex: '1 0 0',
                }}
            >
                <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                    {`Upgrade your ${companyNameCorrectlyCased}`}
                </Typography>
                <Typography variant="subtitle2" color="textPrimary">
                    Get unlimited contacts and premium features
                </Typography>
            </Box>
            <Button classes={'blueDark'} onClick={onClickUpgrade}>
                Upgrade
            </Button>
        </Box>
    );
};
