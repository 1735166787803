import { IEvent, Topics } from '@zaplify/common';
import { ProspectDataExperienceDto } from '../prospects';

export class NewProspectExperienceFoundEvent extends IEvent {
    public static override readonly TOPIC: Topics = Topics.PROSPECTS;
    constructor(readonly prospectId: string, readonly newExperience: ProspectDataExperienceDto) {
        super();
    }

    get partitionKey(): string {
        return this.prospectId;
    }
}
