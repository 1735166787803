import { ThemeProvider } from '@shadcn/ui/theme-provider';
import { Outlet } from 'react-router-dom';

import { SidebarsProvider } from '@shadcn/ui/hooks/use-sidebars';
import { Toaster } from '@shadcn/ui/components/ui/toaster';
import { Suspense } from 'react';

import { LoaderView } from '../components/loader';

export function RootLayout() {
    console.log('root layout rendered');
    return (
        <Suspense fallback={<LoaderView />}>
            <ThemeProvider defaultTheme="light" storageKey="andsend-vite-ui-theme">
                <SidebarsProvider>
                    <Outlet />
                </SidebarsProvider>
                <Toaster />
            </ThemeProvider>
        </Suspense>
    );
}

export default RootLayout;
