import Check from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, FormControl, IconButton, MenuItem, Select, Tooltip, Typography } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import React, { useEffect, useState } from 'react';
import { DropEvent, useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux-latest';
import useFileUpload from '../hooks/use-file-upload';
import { setNotification } from '../redux/actions';
import { AppDispatch } from '../redux/store/configureStore';
import FileUploadStyled from './file-upload.styled';

export type DropResult = { [key: string]: any }[];

export enum Mimetype {
    TEXT_CSV = 'text/csv',
    APPLICATION_VND_MS_EXEL = 'application/vnd.ms-excel',
    APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_SPREADSHEETML_SHEET = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

type Props = {
    mimeTypes?: string;
    multiple?: boolean;
    columnSelection?: boolean;
    dataValidator?: (data: { results: DropResult; headers: string[] }) => string[];
    additionalInfo?: () => React.ReactNode;
    onColumnSelected?: (columnName: string | null) => void;
    onResult: (data: DropResult) => void;
    onHeaders?: (headers: string[]) => void;
    onResetAll: () => void;
};

const acceptedMimeTypes = `${Mimetype.APPLICATION_VND_MS_EXEL},${Mimetype.APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_SPREADSHEETML_SHEET},${Mimetype.TEXT_CSV}`;

const FileUpload = ({
    mimeTypes = acceptedMimeTypes,
    multiple = false,
    columnSelection = false,
    onColumnSelected,
    onResult,
    onHeaders,
    dataValidator,
    additionalInfo,
    onResetAll,
}: Props) => {
    const dispatch = useDispatch<AppDispatch>();
    const { errors, fileName, headers, handleReset, handleSingleFileDropAccepted, result } = useFileUpload();
    const { getInputProps, getRootProps } = useDropzone({
        accept: mimeTypes,
        onDropAccepted: handleDropAccepted,
        multiple,
    });
    const [selectedColumn, setSelectedColumn] = useState<string | null>(null);

    useEffect(() => {
        if (!Object.keys(result).length) {
            return;
        }

        if (!multiple) {
            onResult(result);
        }
    }, [result]);

    useEffect(() => {
        if (!Object.keys(headers).length) {
            return;
        }

        onHeaders && onHeaders(headers);
        onColumnSelected && onColumnSelected(headers[0]);
    }, [headers]);

    async function handleDropAccepted(files: File[], event: DropEvent) {
        try {
            if (!multiple) {
                await handleSingleFileDropAccepted({ file: files[0] }, dataValidator);
            }
        } catch (error) {
            dispatch(setNotification("Couldn't upload file", 'error'));
        }
    }

    const handleColumnSelection = (event: SelectChangeEvent<string>) => {
        setSelectedColumn(event.target.value as string);
        onColumnSelected && onColumnSelected(event.target.value as string);
    };

    const handleColumnSelectionReset = () => {
        setSelectedColumn(null);
        onColumnSelected && onColumnSelected(null);
    };

    const handleResetAll = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        handleColumnSelectionReset();
        handleReset(e);
        onResetAll();
    };

    return (
        <>
            <FileUploadStyled>
                <div className="wrapper">
                    <div data-Cy="upload-prospects-button" className="info-container" {...getRootProps()}>
                        {!fileName && (
                            <>
                                <input {...getInputProps()} />
                                <div className="info">
                                    Drag and drop your excel or csv file here or <span>click</span> to upload.
                                </div>
                            </>
                        )}
                        {fileName && (
                            <div className="files">
                                <div className="file-name">
                                    <div className="file-name-text">
                                        <Tooltip title={fileName} arrow>
                                            <Typography noWrap>{fileName}</Typography>
                                        </Tooltip>
                                        <IconButton onClick={handleResetAll} className="icon" size="large">
                                            <ClearIcon />
                                        </IconButton>
                                    </div>
                                    <Box fontWeight="fontWeightRegular" className="file-uploaded">
                                        <Check className="file-uploaded-info" />
                                        <span className="file-uploaded-info">Uploaded</span>
                                    </Box>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                {errors.length === 0 && columnSelection && headers.length > 0 && (
                    <div className="columns-select-wrapper">
                        <div className="columns-select">
                            <div className="label">Select column</div>
                            <FormControl variant="outlined">
                                <Select
                                    value={selectedColumn && selectedColumn.length > 0 ? selectedColumn : headers[0]}
                                    onChange={handleColumnSelection}
                                    name="column-select"
                                    inputProps={{ 'aria-label': 'select csv column' }}
                                    className="select"
                                >
                                    {headers.map((c, idx) => (
                                        <MenuItem key={`${c}-${idx}`} value={c}>
                                            {c}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                )}
                <div className="errors">
                    {errors.length > 0 && errors.map((error) => <div className="error">{error}</div>)}
                </div>
            </FileUploadStyled>
            {headers.length === 0 && additionalInfo && additionalInfo()}
        </>
    );
};

export default FileUpload;
