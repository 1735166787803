import { IUntypedCommand, Topics } from '@zaplify/common';
import { CreateProspectDataDto } from '../prospects';

export class ValidateProspectImportCommand extends IUntypedCommand {
    public static override readonly TOPIC = Topics.PROSPECTS;
    constructor(
        readonly prospectsData: CreateProspectDataDto[],
        readonly userId: string,
        readonly userOrganizationId: string,
    ) {
        super();
    }
}
