import { UserComposedDto } from '@zaplify/users/shared';
import { useAtom } from 'jotai';
import { atomWithReset } from 'jotai/utils';

export const userAtom = atomWithReset<UserComposedDto | null>(null);
export const userLoadingAtom = atomWithReset<boolean>(true);

export const useUser = () => {
    const [user] = useAtom(userAtom);

    return { user };
};
