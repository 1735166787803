import { FC, useState } from 'react';
import { Button } from '@shadcn/ui/components/ui/button';
import { Input } from '@shadcn/ui/components/ui/input';
import { InputDecreaseIncrease } from '@shadcn/ui/components/input-decrease-increase';
import { cn } from '@shadcn/ui/lib/utils';
import { BillingPeriodUnit, IPlan, IPlanPackage, PlanCode, SubscriptionDetailsDto } from '@zaplify/subscriptions';
import { AndSendLoader } from '@shadcn/ui/components/icons/and-send-loader/andsend-loader';
import { Loader2, AlertTriangle } from 'lucide-react';
import { calculateAmountOfUsersExceedsSelectedPlan } from './pick-plan-dialog';

interface PlanSelectionProps {
    onSelectPlan: (plan: IPlan, planPackage: IPlanPackage) => void;
    subscription: SubscriptionDetailsDto;
    availablePlans: IPlan[];
    selectedBillingPeriod: {
        unit: BillingPeriodUnit;
        amount: number;
    };
    onBillingPeriodChange: (period: { unit: BillingPeriodUnit; amount: number }) => void;
    loadingState: 'idle' | 'loading';
}

export const PlanSelection: FC<PlanSelectionProps> = ({
    onSelectPlan,
    subscription,
    availablePlans,
    selectedBillingPeriod,
    onBillingPeriodChange,
    loadingState,
}) => {
    const [proSeats, setProSeats] = useState(subscription.subscription?.totalSeats || 1);
    const [teamSeats, setTeamSeats] = useState(subscription.subscription?.totalSeats || 1);

    const getPricePerMonth = (planCode: 'basic' | 'team') => {
        const selectedPlan = availablePlans.find(
            (plan) => plan.code === planCode && plan.billingPeriod.unit === selectedBillingPeriod.unit
        );
        const isAnnual = selectedBillingPeriod.unit === BillingPeriodUnit.YEAR;
        return Number(selectedPlan?.pricePerSeat / (isAnnual ? 12 : 1)).toFixed(0) || 0;
    };

    const calculateTotalPrice = (planCode: 'basic' | 'team', seats: number) => {
        const selectedPlan = availablePlans.find(
            (plan) => plan.code === planCode && plan.billingPeriod.unit === selectedBillingPeriod.unit
        );
        return Number(selectedPlan?.pricePerSeat * seats).toFixed(0) || 0;
    };

    const handleBillingPeriodChange = (unit: BillingPeriodUnit) => {
        onBillingPeriodChange({
            unit,
            amount: 1,
        });
    };

    const handleSelectPlan = (planCode: PlanCode, seats: number) => {
        const selectedPlan = availablePlans.find(
            (plan) => plan.code === planCode && plan.billingPeriod.unit === selectedBillingPeriod.unit
        );
        console.log('selectedPlan', selectedPlan);
        if (selectedPlan) {
            const selectedPackage = { seats, credits: selectedPlan.packages[0].credits * seats };
            console.log('selectedPlan', selectedPlan);
            onSelectPlan(selectedPlan, selectedPackage);
        }
    };

    const isProPlan = subscription.subscription.current.planCode === PlanCode.BASIC;
    const isTeamPlan = subscription.subscription.current.planCode === PlanCode.TEAM;
    const isEnterprisePlan = subscription.subscription.current.planCode === PlanCode.ENTERPRISE;

    // Calculate the number of occupied seats (active users)
    const currentUsersCount = subscription.subscription.totalUsers;

    // Calculate if pro plan seats exceed occupied seats
    const proExceed = calculateAmountOfUsersExceedsSelectedPlan({ seats: proSeats, credits: 0 }, currentUsersCount);
    const showProWarning = proExceed > 0;

    // Calculate if team plan seats exceed occupied seats
    const teamExceed = calculateAmountOfUsersExceedsSelectedPlan({ seats: teamSeats, credits: 0 }, currentUsersCount);
    const showTeamWarning = teamExceed > 0;

    if (loadingState === 'loading') {
        return (
            <div className="flex justify-center items-center p-18">
                <Loader2 className="h-8 w-8 animate-spin text-brand-600" />
            </div>
        );
    }

    return (
        <>
            <div className="flex justify-center mt-4 mb-8">
                <div className="inline-flex rounded-full p-1 bg-brand-100">
                    <div
                        className={cn(
                            'px-4 py-1 rounded-full transition-colors cursor-pointer',
                            selectedBillingPeriod.unit === BillingPeriodUnit.YEAR
                                ? 'bg-brand-600 text-white'
                                : 'text-brand-600'
                        )}
                        onClick={() => handleBillingPeriodChange(BillingPeriodUnit.YEAR)}
                    >
                        Annually
                    </div>
                    <div
                        className={cn(
                            'px-4 py-1 rounded-full transition-colors cursor-pointer',
                            selectedBillingPeriod.unit === BillingPeriodUnit.MONTH
                                ? 'bg-brand-600 text-white'
                                : 'text-brand-600'
                        )}
                        onClick={() => handleBillingPeriodChange(BillingPeriodUnit.MONTH)}
                    >
                        Monthly
                    </div>
                </div>
            </div>

            <div className="grid grid-cols-3 gap-6 px-6">
                {/* Pro Plan */}
                <div
                    className={cn(
                        'bg-white border-2 border-brand-100 rounded-lg p-6 flex flex-col relative before:absolute before:inset-0 before:rounded-lg before:bg-gradient-to-b before:from-brand-50/50 before:to-transparent before:h-32 before:-z-10',
                        isProPlan && 'ring-2 ring-brand-600 ring-offset-2'
                    )}
                >
                    {isProPlan && (
                        <div className="absolute -top-3 left-1/2 -translate-x-1/2 bg-brand-600 text-white px-3 py-1 rounded-full text-xs">
                            Current Plan
                        </div>
                    )}
                    <h3 className="text-xl font-medium mb-2">Pro</h3>
                    <p className="text-sm text-muted-foreground mb-6">
                        All you need to maximize your day-to-day efficiency.
                    </p>
                    <div className="mb-4 w-full items-center justify-center flex">
                        <InputDecreaseIncrease
                            type="number"
                            min={1}
                            value={proSeats}
                            onChange={(value) => setProSeats(value)}
                            placeholder="1"
                        />
                    </div>
                    <div className="mb-6">
                        <div className="text-2xl font-medium">
                            €{getPricePerMonth('basic')}
                            <span className="text-sm font-normal">/user/month</span>
                        </div>
                        <div className="text-sm text-muted-foreground">
                            Total price: €{calculateTotalPrice('basic', proSeats)}{' '}
                            {selectedBillingPeriod.unit === BillingPeriodUnit.YEAR ? 'per year' : 'per month'}
                        </div>
                    </div>
                    <div className="flex-grow">
                        <div className="text-sm mb-2">All features from the free plan and:</div>
                        <ul className="space-y-2 text-sm">
                            <li>✓ Multiple AI playbooks</li>
                            <li>✓ Enrich imported contacts</li>
                            <li>✓ Export contacts</li>
                            <li>✓ CRM integrations</li>
                            <li>✓ 50,000 contacts</li>
                        </ul>
                    </div>
                    {showProWarning && (
                        <div className="mt-4 mb-2 p-3 bg-amber-50 border border-amber-200 rounded-md flex items-start gap-2">
                            <AlertTriangle className="h-4 w-4 text-amber-600 mt-0.5 flex-shrink-0" />
                            <div className="text-amber-800 text-sm">
                                Your organization has {currentUsersCount} active users. To decrease team size, please
                                deactivate {Math.abs(proExceed)} {Math.abs(proExceed) > 1 ? 'users' : 'user'}.
                            </div>
                        </div>
                    )}
                    <Button
                        onClick={() => handleSelectPlan(PlanCode.BASIC, proSeats)}
                        className="w-full mt-6 bg-brand-600 hover:bg-brand-700"
                        disabled={(proSeats === subscription.subscription.totalSeats && isProPlan) || showProWarning}
                    >
                        {!isProPlan
                            ? 'Select plan'
                            : proSeats === subscription.subscription.totalSeats
                            ? 'Current plan'
                            : proSeats > subscription.subscription.totalSeats
                            ? 'Add seats'
                            : 'Remove seats'}
                    </Button>
                </div>

                {/* Team Plan */}
                <div
                    className={cn(
                        'bg-white border-2 border-brand-300 rounded-lg p-6 flex flex-col relative before:absolute before:inset-0 before:rounded-lg before:bg-gradient-to-b before:from-brand-100/50 before:to-transparent before:h-32 before:-z-10',
                        'ring-2 ring-brand-200 ring-offset-2',
                        isTeamPlan && 'ring-brand-600'
                    )}
                >
                    <div className="absolute -top-3 left-1/2 -translate-x-1/2 bg-brand-600 text-white px-3 py-1 rounded-full text-xs">
                        {isTeamPlan ? 'Current Plan' : 'Most Popular'}
                    </div>
                    <h3 className="text-xl font-medium mb-2">Team</h3>
                    <p className="text-sm text-muted-foreground mb-6">
                        When you want to scale the power of Andsend to the whole team.
                    </p>
                    <div className="mb-4 w-full items-center justify-center flex">
                        <InputDecreaseIncrease
                            type="number"
                            min={1}
                            value={teamSeats}
                            onChange={(value) => setTeamSeats(value)}
                            placeholder="5"
                        />
                    </div>
                    <div className="mb-6">
                        <div className="text-2xl font-medium">
                            €{getPricePerMonth('team')}
                            <span className="text-sm font-normal">/user/month</span>
                        </div>
                        <div className="text-sm text-muted-foreground">
                            Total price: €{calculateTotalPrice('team', teamSeats)}{' '}
                            {selectedBillingPeriod.unit === BillingPeriodUnit.YEAR ? 'per year' : 'per month'}
                        </div>
                    </div>
                    <div className="flex-grow">
                        <div className="text-sm mb-2">All features from the Pro plan and:</div>
                        <ul className="space-y-2 text-sm">
                            <li>✓ Collaborative features</li>
                            <li>✓ Unlimited users in your organization</li>
                            <li>✓ Customer Success Manager</li>
                            <li>✓ Centralized billing for all users</li>
                            <li>✓ Admin capabilities</li>
                        </ul>
                    </div>
                    {showTeamWarning && (
                        <div className="mt-4 mb-2 p-3 bg-amber-50 border border-amber-200 rounded-md flex items-start gap-2">
                            <AlertTriangle className="h-4 w-4 text-amber-600 mt-0.5 flex-shrink-0" />
                            <div className="text-amber-800 text-sm">
                                Your organization has {currentUsersCount} active users. To decrease team size, please
                                deactivate {Math.abs(teamExceed)} {Math.abs(teamExceed) > 1 ? 'users' : 'user'}.
                            </div>
                        </div>
                    )}
                    <Button
                        onClick={() => handleSelectPlan(PlanCode.TEAM, teamSeats)}
                        className="w-full mt-6 bg-brand-600 hover:bg-brand-700"
                        disabled={(teamSeats === subscription.subscription.totalSeats && isTeamPlan) || showTeamWarning}
                    >
                        {!isTeamPlan
                            ? 'Select plan'
                            : teamSeats === subscription.subscription.totalSeats
                            ? 'Current plan'
                            : teamSeats > subscription.subscription.totalSeats
                            ? 'Add seats'
                            : 'Remove seats'}
                    </Button>
                </div>

                {/* Enterprise Plan */}
                <div
                    className={cn(
                        'bg-white border-2 border-gray-utility-100 rounded-lg p-6 flex flex-col relative before:absolute before:inset-0 before:rounded-lg before:bg-gradient-to-b before:from-gray-utility-50/50 before:to-transparent before:h-32 before:-z-10',
                        isEnterprisePlan && 'ring-2 ring-brand-600 ring-offset-2'
                    )}
                >
                    {isEnterprisePlan && (
                        <div className="absolute -top-3 left-1/2 -translate-x-1/2 bg-brand-600 text-white px-3 py-1 rounded-full text-xs">
                            Current Plan
                        </div>
                    )}
                    <h3 className="text-xl font-medium mb-2">Enterprise</h3>
                    <p className="text-sm text-muted-foreground mb-6">
                        Get in touch with us to talk about your custom Andsend Enterprise plan.
                    </p>
                    <div className="flex-grow">
                        <div className="bg-gray-utility-50 rounded-lg p-6 text-center">
                            <p className="text-sm text-gray-utility-600 mb-4">
                                Get in touch with us to talk about your custom Andsend Enterprise plan.
                            </p>
                            <a
                                href="mailto:sales@andsend.com"
                                className="text-brand-600 hover:text-brand-700 font-medium"
                            >
                                Contact us
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
