import { cn } from '@shadcn/ui/lib/utils';
import { ArrowDown, ArrowUp } from 'lucide-react';
import React, { useEffect } from 'react';
import { useSuggestionsFeed } from '../hooks/use-suggestions-feed';

export const FeedController = ({ className }: { className?: string }) => {
    const {
        goToPreviousSuggestion,
        goToNextSuggestion,
        contactSuggestions,
        contactSuggestionIndex,
        setContactSuggestionIndex,
    } = useSuggestionsFeed();
    const indicatorContainerRef = React.useRef<HTMLDivElement>(null);
    const [timeoutInProgress, setTimeoutInProgress] = React.useState(false); // a boolean for if timeout is im progress, used to stop user from spam clicking next or back in certain conditions
    const isInfinite = true;
    const visibleItemsCount = 8;
    const withIndicator = true;
    const items = contactSuggestions?.length || 0;
    const handleUpClick = () => {
        goToPreviousSuggestion();
    };
    const handleDownClick = () => {
        goToNextSuggestion();
    };
    /**
     * Total item
     */

    const originalItemsLength = items;

    /**
     * Is the carousel repeating it's item
     */
    const isRepeating = React.useMemo(() => {
        return isInfinite && originalItemsLength > visibleItemsCount;
    }, [isInfinite, visibleItemsCount, originalItemsLength]);

    /**
     * Current Index Item of the Carousel
     */
    // const [contactSuggestionIndex, setContactSuggestionIndex] = React.useState(isRepeating ? visibleItemsCount : 0);

    /**
     * Is the carousel's transition enabled
     */
    const [isTransitionEnabled, setTransitionEnabled] = React.useState(true);

    /**
     * Handle if the carousel is repeating
     * and the currentIndex have been set to the last or first item
     */
    React.useEffect(() => {
        if (isRepeating) {
            if (contactSuggestionIndex === visibleItemsCount || contactSuggestionIndex === originalItemsLength) {
                setTransitionEnabled(true);
            }
        }
    }, [contactSuggestionIndex, isRepeating, visibleItemsCount, originalItemsLength]);

    React.useEffect(() => {
        if (withIndicator) {
            const active = indicatorContainerRef.current?.querySelector('.dots-active');
            if (active) {
                let index = active.getAttribute('data-index');
                if (index !== null && indicatorContainerRef.current?.scrollTo) {
                    indicatorContainerRef.current?.scrollTo({
                        top: ((Number(index) - 2) / 5) * 44,
                        behavior: 'smooth',
                    });
                }
            }
        }
    }, [withIndicator, contactSuggestionIndex]);

    useEffect(() => {
        items && setContactSuggestionIndex(visibleItemsCount);
    }, [items, visibleItemsCount]);

    /**
     * Move forward to the next item//
     */
    const nextItem = () => {
        const isOnEdgeForward = contactSuggestionIndex > originalItemsLength;
        if (isOnEdgeForward) {
            setTimeoutInProgress(true);
        }

        if (isRepeating || contactSuggestionIndex < originalItemsLength - visibleItemsCount) {
            setContactSuggestionIndex((prevState) => prevState + 1);
        }
        handleDownClick();
    };

    /**
     * Move backward to the previous item
     */
    const previousItem = () => {
        const isOnEdgeBack = isRepeating ? contactSuggestionIndex <= visibleItemsCount : contactSuggestionIndex === 0;

        if (isOnEdgeBack) {
            setTimeoutInProgress(true);
        }

        if (isRepeating || contactSuggestionIndex > 0) {
            setContactSuggestionIndex((prevState) => prevState - 1);
        }
        handleUpClick();
    };

    /**
     * Handle when carousel transition's ended
     */
    const handleTransitionEnd = () => {
        if (isRepeating) {
            if (contactSuggestionIndex === 0) {
                setTransitionEnabled(false);
                setContactSuggestionIndex(originalItemsLength);
            } else if (contactSuggestionIndex === originalItemsLength + visibleItemsCount) {
                setTransitionEnabled(false);
                setContactSuggestionIndex(visibleItemsCount);
            }
        }

        setTimeoutInProgress(false);
    };

    // render dots to indicate position in the feed
    const renderDots = React.useMemo(() => {
        const minDots = 3;
        const localShow = isRepeating ? visibleItemsCount : 0;
        // For non-repeating mode, use the actual number of items rather than dividing by visibleItemsCount
        const suggestionsCount = isRepeating ? Math.ceil(originalItemsLength / visibleItemsCount) : originalItemsLength;

        const totalDots = isRepeating
            ? originalItemsLength
            : suggestionsCount < minDots
            ? suggestionsCount
            : Math.max(minDots, suggestionsCount);

        // Calculate which dot should be active
        const activeIndex = isRepeating
            ? contactSuggestionIndex - localShow < 0
                ? originalItemsLength + (contactSuggestionIndex - localShow)
                : contactSuggestionIndex - localShow
            : contactSuggestionIndex;

        return Array.from({ length: totalDots }, (_, index) => {
            let className = '';

            // Determine dot appearance based on distance from active dot
            if (index === activeIndex) {
                className = 'dots-active';
            } else {
                const distance = Math.abs(activeIndex - index);
                className = distance <= 1 ? 'dots-close' : 'dots-far';
            }

            return <div key={index} data-index={index} className={className} />;
        });
    }, [contactSuggestionIndex, isRepeating, originalItemsLength, visibleItemsCount]);

    React.useEffect(() => {
        const downHandler = ({ key }) => {
            if (key === 'ArrowDown') {
                nextItem();
            }
        };

        const upHandler = ({ key }) => {
            if (key === 'ArrowUp') {
                previousItem();
            }
        };

        window.addEventListener('keydown', downHandler);
        window.addEventListener('keyup', upHandler);

        return () => {
            window.removeEventListener('keydown', downHandler);
            window.removeEventListener('keyup', upHandler);
        };
    }, [items, nextItem, previousItem]);

    return (
        <div
            className={cn(
                'flex flex-col h-[60%] max-h-[60%] items-center justify-center overflow-hidden min-w-10',
                '[&>.arrow-button]:flex-shrink-0 [&>.arrow-button]:!w-10 [&>.arrow-button]:!h-10 [&>.arrow-button]:rounded-full [&>.arrow-button]:bg-background-secondary [&>.arrow-button]:border [&>.arrow-button]:cursor-pointer [&>.arrow-button]:flex [&>.arrow-button]:items-center [&>.arrow-button]:justify-center [&>.arrow-button]:shadow [&>.arrow-button]:transition-all [&>.arrow-button]:duration-150',
                className
            )}
        >
            <button onClick={previousItem} className="arrow-button">
                <ArrowUp strokeWidth={1.5} size={20} />
            </button>
            <div
                ref={indicatorContainerRef}
                onTransitionEnd={handleTransitionEnd}
                className={`indicator-container flex flex-col items-center gap-1 mx-auto my-0 no-scrollbar overflow-auto [&>*]:rounded-full transition-all duration-250 ease-linear [&>div:first-child]:mt-1 [&>div:last-child]:mb-1 [&>.dots-active]:w-[0.7rem] [&>.dots-active]:h-[0.7rem] [&>.dots-active]:bg-background-brand-primary [&>*]:flex-shrink-0 [&>*]:flex-grow [&>.dots-close]:w-2 [&>.dots-close]:h-2 [&>.dots-close]:bg-background-brand-primary-alt [&>.dots-far]:w-[0.3rem] [&>.dots-far]:h-[0.3rem] [&>.dots-far]:bg-background-brand-primary-alt [&>*]:transition-all [&>*]:duration-250 [&>*]:ease-linear`}
            >
                {renderDots}
            </div>
            <button onClick={nextItem} className="arrow-button">
                <ArrowDown strokeWidth={1.5} size={20} />
            </button>
        </div>
    );
};
