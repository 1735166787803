import { BaseSdk } from '../../base.sdk';
import { GlobalSearchPeopleInCompanyResultDto, GlobalSearchResponseDto } from '@zaplify/prospects';

export class SearchSdk extends BaseSdk {
    async globalSearch(query: string) {
        return await this.get<GlobalSearchResponseDto>(`${this.search}?${query}`);
    }

    async globalSearchInCompany(query: string) {
        return await this.get<GlobalSearchPeopleInCompanyResultDto>(`${this.search}/company?${query}`);
    }
}
