import { makeAssistantToolUI } from '@assistant-ui/react';
import { ActionCards, Action } from '@shadcn/ui/components/action-cards';

type ActionsListToolArgs = {
    userId: string;
};

type ActionsListToolResult = {
    actions: Action[];
};

function ActionsListDisplay({ actions }: ActionsListToolArgs & ActionsListToolResult) {
    return (
        <div className="action-card-stack mx-auto w-full max-w-md">
            <ActionCards actions={actions} />
        </div>
    );
}

// export const ActionsListTool = makeAssistantToolUI<ActionsListToolArgs, string>({
//     toolName: 'actions_list',
//     render: function ActionsListUI({ args, result }) {
//         let resultObj: ActionsListToolResult | { error: string };
//         try {
//             resultObj = result ? JSON.parse(result) : { actions: [] };
//         } catch (e) {
//             resultObj = { error: result || 'Unknown error' };
//         }

//         return (
//             <div className="mb-4 flex flex-col items-center gap-2">
//                 <pre className="whitespace-pre-wrap break-all text-center">actions-list({JSON.stringify(args)})</pre>
//                 {'actions' in resultObj && <ActionsListDisplay actions={resultObj.actions} userId={args.userId} />}
//                 {'error' in resultObj && <p className="text-red-500">{resultObj.error}</p>}
//             </div>
//         );
//     },
// });

export const ActionsListTool = makeAssistantToolUI<ActionsListToolArgs, ActionsListToolResult>({
    toolName: 'actions_list',
    render: function ActionsListUI({ args, result }) {
        if (!result) {
            return <div>No actions found</div>;
        }

        return (
            <div className="mb-4 flex flex-col items-center gap-2">
                <ActionsListDisplay {...result} userId={args.userId} />
            </div>
        );
    },
});
