import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Typography } from '@mui/material';
import React from 'react';

export function getRequiredText(field, handleDeleteField, idx) {
    if (field.options && field.required) {
        return <Typography style={{ fontSize: 13, color: '#96A2AC', marginLeft: 14 }}>1 Channel Required</Typography>;
    }
    if (field.required) {
        return <span style={{ fontSize: 13, color: '#96A2AC', marginLeft: 14 }}>Required</span>;
    }

    return (
        <Button className="trash-button" onClick={() => handleDeleteField(idx)}>
            <DeleteIcon style={{ fill: '#96A2AC', height: 18 }} /> <span style={{ fontSize: 13 }}>Optional</span>
        </Button>
    );
}

export const disableUsedField = (fieldToExtend, currentSelected) => {
    const fieldUsed = currentSelected.find((field) => {
        if (field.prospect === fieldToExtend.name) {
            return field;
        }
    });
    return { ...fieldToExtend, disabled: !!fieldUsed };
};
