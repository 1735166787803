import { FC, useMemo } from 'react';
import { GET_TOTAL_NUMBER_OF_USER_CONTACTS_IN_ANY_PLAYBOOK } from '@zaplify/graphql';
import { useQuery } from '@apollo/client';
import { PageHeader } from '../../../components/page-header';
import { Button } from '@shadcn/ui/components/ui/button';
import { PanelLeft } from 'lucide-react';
import { useParams, useNavigate } from 'react-router-dom';
interface ContactsPageHeaderProps {}

const ContactsPageHeader: FC<ContactsPageHeaderProps> = ({}) => {
    const { loading: isContactsLoading, data: contactsData } = useQuery(
        GET_TOTAL_NUMBER_OF_USER_CONTACTS_IN_ANY_PLAYBOOK,
        {
            fetchPolicy: 'network-only',
        }
    );
    const { prospectId } = useParams();
    const navigate = useNavigate();

    const totalContacts = useMemo(() => {
        return contactsData?.UserContacts_aggregate?.aggregate?.count || 0;
    }, [contactsData]);

    return (
        <PageHeader
            title="My contacts"
            count={totalContacts}
            isLoadingCount={isContactsLoading}
            rightElement={
                prospectId && (
                    <Button variant="ghost" size="icon" onClick={() => navigate('/new/contacts')}>
                        <PanelLeft />
                    </Button>
                )
            }
        />
    );
};

export default ContactsPageHeader;
