import { ButtonGroup, Button, SxProps } from '@mui/material';
import { palette } from '../../theme/palette';

type PropsType = {
    leftContent: string | React.ReactNode;
    rightContent?: string | React.ReactNode;
    leftProps?: any;
    rightProps?: any;
    leftClick: (e: React.MouseEvent<HTMLLIElement>) => void;
    rightClick?: (e: React.MouseEvent<HTMLLIElement>) => void;
    disabled?: boolean;
    startIcon?: React.ReactNode;
    className?: string;
    sx?: SxProps;
};

export function SplitButton({
    leftContent,
    rightContent,
    leftProps,
    rightProps,
    leftClick,
    rightClick,
    disabled,
    className,
    sx,
}: PropsType) {
    const hasRightButton = rightContent && rightClick;
    const borderRadius = (sx as any)?.['borderRadius'] ?? '8px';
    return (
        <ButtonGroup
            disabled={disabled}
            className={className}
            sx={{
                '& > button': {
                    border: '1px solid var(--grey-palette-grey-2, #E4E6EB)',
                    backgroundColor: 'white',
                    color: '#3A4B59',

                    '&:hover': {
                        color: palette.graphics.infoBox,
                        backgroundColor: 'rgba(21, 27, 38, 0.04)',
                        border: '1px solid var(--grey-palette-grey-2, #E4E6EB)',
                    },
                    '&.Mui-disabled': {
                        color: palette.colors.grey,
                    },
                },
                ...sx,
            }}
        >
            <Button
                onClick={leftClick}
                {...leftProps}
                style={{
                    width: '100%',
                    borderTopLeftRadius: borderRadius,
                    borderBottomLeftRadius: borderRadius,
                    borderTopRightRadius: hasRightButton ? 0 : borderRadius,
                    borderBottomRightRadius: hasRightButton ? 0 : borderRadius,
                }}
            >
                {leftContent}
            </Button>
            {hasRightButton && (
                <Button
                    onClick={rightClick}
                    {...rightProps}
                    style={{
                        borderTopRightRadius: borderRadius,
                        borderBottomRightRadius: borderRadius,
                    }}
                >
                    {rightContent}
                </Button>
            )}
        </ButtonGroup>
    );
}

export default SplitButton;
