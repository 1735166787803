import { getZaplifySdk } from '@zaplify/sdk';
import { LinkedinTriggerDto } from '@zaplify/campaigns';
import { ChatsProspect, ChatsProspectStatus } from '../../../types/prospect';
import { useAtom, useSetAtom } from 'jotai';
import { ProspectDataExperienceDto, ProspectDto } from '@zaplify/prospects';
import { atomWithReset } from 'jotai/utils';
import { prospectIdToIsGeneratingMessageAtom } from '../../../atoms/chats';
import { useNotifications } from '../../../hooks/use-notifications';

function log(text: string) {
    console.log(`🚀 [useProspectData] | ${text}`);
}
function logError(text: string) {
    console.error(`🚀 [useProspectData] | ${text}`);
}

const messengerProspectsAtom = atomWithReset<Map<string, ProspectDto>>(new Map());
messengerProspectsAtom.debugLabel = 'messangerProspects';

const useProspectData = () => {
    const { notify } = useNotifications();
    const [prospectCache, setProspectCache] = useAtom(messengerProspectsAtom);
    const setProspectIdsToIGeneratingMessage = useSetAtom(prospectIdToIsGeneratingMessageAtom);

    function getProspectData(id: string): ProspectDto {
        return prospectCache.get(id);
    }

    async function fetchProspectData(id: string, noCache = false): Promise<ProspectDto> {
        if (!id) {
            return;
        }
        const prospectsSdk = getZaplifySdk().profiles.prospects;

        if (!noCache && prospectCache.has(id)) {
            return;
        }

        try {
            const prospect = await prospectsSdk.getProspect(id);
            setProspectCache((prev) => new Map(prev.set(id, prospect)));
            return prospect;
        } catch (error) {
            logError(`fetchProspectData | error: ${JSON.stringify(error)}`);
            notify({
                message: 'An error occurred while getting contact information.',
                type: 'error',
            });
            return;
        }
    }

    async function fetchProspectNoCache(id: string) {
        log(`refetchProspect | id: ${id}`);
        return await fetchProspectData(id, true);
    }

    async function fetchProspectLinkedinTracker(
        prospectId: string,
        channelAccountId: string = null
    ): Promise<LinkedinTriggerDto | null> {
        log(`fetchProspectLinkedinTracker | id: ${channelAccountId}`);
        const trackersSdk = getZaplifySdk().profiles.trackers;

        try {
            if (!channelAccountId) {
                logError(`fetchProspectLinkedinTracker | id: ${channelAccountId} No channelAccountId`);
                return null;
            }

            log(`fetchProspectLinkedinTracker | getTracker | id: ${prospectId}`);
            return await trackersSdk.getLinkedinTracker(channelAccountId, prospectId);
        } catch (error) {
            logError(`fetchProspectLinkedinTracker | error: ${JSON.stringify(error)}`);
            return null;
        }
    }

    async function selectProspectMainExperience(
        prospectId: string,
        experience: ProspectDataExperienceDto
    ): Promise<ProspectDto> {
        log(`selectProspectMainExperience | prospectId: ${prospectId} | experience: ${experience}`);
        const prospectsSdk = getZaplifySdk().profiles.prospects;

        try {
            log(`selectProspectMainExperience | selectMainExperience | prospectId: ${prospectId}`);
            await prospectsSdk.selectProspectMainExperience({ prospectId, experience });
            setProspectIdsToIGeneratingMessage((prev) => {
                const newMap = new Map(prev);
                newMap.set(prospectId, true);
                return newMap;
            });

            return await fetchProspectNoCache(prospectId);
        } catch (error) {
            logError(`selectProspectMainExperience | error: ${JSON.stringify(error)}`);
            return null;
        }
    }

    return {
        getProspectData,
        fetchProspectData,
        fetchProspectLinkedinTracker,
        fetchProspectNoCache,
        selectProspectMainExperience,
    };
};

export default useProspectData;

export const createChatProspectObject = (
    prospect: ProspectDto,
    linkedinConnectionStatus: ChatsProspectStatus,
    lastChecked?: string
): ChatsProspect => {
    return {
        accountName: prospect?.data?.organizationName || '',
        contactLocation: prospect?.data?.location || '',
        industry: prospect?.data?.industry || '',
        name: prospect?.data.fullName || '',
        linkedinUserId: prospect?.data?.linkedinUserId || '',
        linkedinProfileUrl: prospect?.data?.linkedinProfileUrl || '',
        linkedinProfileVerification: prospect?.data?.linkedinProfileVerification,
        email: prospect?.data.email || '',
        occupationTitle: prospect?.data?.occupationTitle || '',
        organizationId: prospect?.data?.organizationId || '',
        organizationDomain: prospect?.data?.organizationDomain || '',
        organizationLinkedinUrl: prospect?.data?.organizationLinkedinUrl || '',
        phoneNumbers: prospect?.data?.phoneNumbers || [],
        experiences: prospect?.data?.experiences || [],
        status: prospect?.data?.linkedinProfileUrl ? linkedinConnectionStatus || 'loading' : 'missing_linkedin_url',
        organizationSize: prospect?.data?.organizationSize || '',
        id: prospect?.id,
        lastChecked,
    };
};
