//Mapping from hash to color
const colors = [
    '--blue-light-350',
    '--green-light-350',
    '--purple-350',
    '--pink-350',
    '--orange-350',
    '--turquoise-light-350',
];
const getHash = (str: string): number => {
    let hash = 0;
    if (!str) return 0;
    if (typeof str !== 'string') return 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
        hash = hash & hash;
    }
    hash = ((hash % colors.length) + colors.length) % colors.length;
    return hash;
};

export function stringToColor(str: string) {
    const hash = getHash(str);
    const color = `hsl(var(${colors[hash]}))`;
    return color;
}
