import { ProspectsByCompanyDto, ProspectsQueryProspectsByCompanyDto } from '@zaplify/prospects';
import { CompaniesDto } from '@zaplify/prospects';
import { copyObjectWithNewValues } from '../../../services/utils/object';
import { ProspectsTransactionState, Selected } from './types';
import { ProspectSearchFiltersDto } from '@zaplify/prospects';
import { ProspectSearchFilterState } from '../prospect-search-filters/types';
import { DEFAULT_COMPANIES_PER_PAGE, IntegrationConfigForPreviewProspectsAction } from '../../../config';
import { cleanCompanyData } from '../../../helpers/cleaner';
import { IntegrationType } from '@zaplify/enums';

export function handleSelectAll(
    checked: boolean,
    prospectsInCompanies: ProspectsByCompanyDto[],
    selected: Selected,
    purchased: ProspectsTransactionState
) {
    if (!checked) {
        const prospects = prospectsInCompanies.reduce(
            (acc, curr) => ({
                ...acc,
                ...curr.prospects.reduce((acc, curr) => {
                    if (!curr.personId) return acc;
                    if (purchased[curr.personId]) return acc;
                    return { ...acc, [curr.personId]: true };
                }, {}),
            }),
            {}
        );
        const formattedProspects = copyObjectWithNewValues(prospects);
        return { ...selected, ...formattedProspects };
    }

    prospectsInCompanies.forEach((company) =>
        company.prospects.forEach((prospect) => prospect.personId && delete selected[prospect.personId])
    );
    return selected;
}

export function handleSelectCompany(prospectIds: string[], checked: boolean, selected: any) {
    if (!checked) {
        const newProspects = {};
        prospectIds.forEach((id: string) => (newProspects[id] = true));
        return { ...selected, ...newProspects };
    }
    prospectIds.forEach((id: string) => delete selected[id]);
    return selected;
}

function getExactOrganizations(organizations: (CompaniesDto | string)[]) {
    if (!organizations) return [];
    return organizations.filter((organization) => (organization as any)?.id) as CompaniesDto[];
}

function getFuzzyOrganizations(organizations: (CompaniesDto | string)[]) {
    if (!organizations) return [];
    return organizations
        .filter((org) => !(org as any)?.id)
        .map((org) => ((org as any)?.name ? (org as any).name : org)) as string[];
}

export function getFormattedProspectSearch({
    filters,
    fromCompanyIndex = 0,
    amountOfCompanies = DEFAULT_COMPANIES_PER_PAGE,
}: {
    filters: ProspectSearchFilterState;
    fromCompanyIndex?: number;
    amountOfCompanies?: number;
}): ProspectSearchFiltersDto {
    return {
        organizationFilter: {
            industries: {
                include: filters.industries,
                exclude: getCleanedExcludeFiltersArray(filters.excludeIndustries),
            },
            organizationSizes: { include: filters.organizationSizes },
            organizations: {
                include: getFuzzyOrganizations(filters.organizations),
                exclude: getFuzzyOrganizations(filters.excludeOrganizations),
            },
            organizationsExact: {
                include: getExactOrganizations(filters.organizations),
                exclude: getExactOrganizations(filters.excludeOrganizations),
            },
            organizationDomains: {
                include: filters.organizationDomains,
                exclude: getCleanedExcludeFiltersArray(filters.excludeOrganizationDomains),
            },
            keywords: { include: [], exclude: [] },
        },
        personalFilter: {
            keywords: {
                include: filters.personalKeywords,
                exclude: getCleanedExcludeFiltersArray(filters.excludePersonalKeywords),
            },
            locations: { include: filters.locations, exclude: filters.excludeLocations },
            occupationTitles: {
                include: filters.occupationTitles,
                exclude: getCleanedExcludeFiltersArray(filters.excludeOccupationTitles),
            },
            occupationFunctions: {
                include: filters.occupationFunctions,
                exclude: getCleanedExcludeFiltersArray(filters.excludeOccupationFunctions),
            },
            occupationSeniorities: {
                include: filters.occupationSeniorities,
                exclude: getCleanedExcludeFiltersArray(filters.excludeOccupationSeniorities),
            },
        },
        options: {
            include: {
                available: true,
                blocklisted: false,
                assigned: false,
            },
            size: amountOfCompanies,
            from: fromCompanyIndex,
            prospectsPerCompany: getProspectsPerCompanyValue({
                oneProspectPerCompany: filters.oneProspectPerCompany,
                searchOrganizationsLength: filters.organizations.length,
            }),
        },

        crmFilters: {
            excludeOwnedCompanies: filters.hideOwnedCrmCompanies,
        },
        activityFilters: {
            excludePersonsInCampaigns: true,
        },
    };
}

const getProspectsPerCompanyValue = ({
    oneProspectPerCompany,
    searchOrganizationsLength,
}: {
    oneProspectPerCompany: boolean;
    searchOrganizationsLength: number;
}) => {
    if (searchOrganizationsLength > 0 && searchOrganizationsLength < 5) {
        return DEFAULT_COMPANIES_PER_PAGE * 2;
    }
    if (searchOrganizationsLength > 1 || (searchOrganizationsLength === 0 && !oneProspectPerCompany)) {
        return 5;
    }
    if (searchOrganizationsLength > 1 && searchOrganizationsLength < 40 && oneProspectPerCompany) {
        return 3;
    }
    if (oneProspectPerCompany) {
        return 1;
    }
    if (searchOrganizationsLength > 1 || searchOrganizationsLength === 0) {
        return 5;
    }
};

function getCleanedExcludeFiltersArray(values: any[]) {
    if (!(values?.length > 0)) return [];
    return values.map((val) => val.name || val);
}

export async function postFilter(prospectSearch, sdk) {
    return await sdk.sources.createFilter(getFormattedProspectSearch({ filters: prospectSearch, fromCompanyIndex: 0 }));
}

export async function postUpdatedSource(prospectSearch, sourceId, sdk) {
    return await sdk.sources.updateSource(
        sourceId,
        getFormattedProspectSearch({ filters: prospectSearch, fromCompanyIndex: 0 })
    );
}

export function addWebhooksData(prospects, webhooksData) {
    if (prospects[webhooksData.prospectId]) {
        const tempProspect = { ...prospects[webhooksData.prospectId] };
        tempProspect.apps.webhooks = {
            associatedId: webhooksData.assignedId || webhooksData.associatedId,
            ...webhooksData,
        };
        return {
            prospect: tempProspect,
        };
    } else {
        return null;
    }
}

export function addHubspotData(prospects, hubSpotData) {
    if (prospects[hubSpotData.prospectId]) {
        const tempProspect = { ...prospects[hubSpotData.prospectId] };
        tempProspect.apps.hubSpot = hubSpotData;
        return {
            prospect: tempProspect,
        };
    } else {
        return null;
    }
}

export function getSelectedProspectsArray(selected, purchased) {
    return Object.keys(selected).filter((id) => {
        if (purchased[id] || !selected[id]) {
            return false;
        }

        return id;
    });
}

export const getIntegrationDetails = (integrationType: IntegrationType, payload, state) => {
    for (const prospectsInCompany of payload) {
        const cleanCompanyName = cleanCompanyData(prospectsInCompany.organizationName);
        const {
            syncedCompaniesStateField,
            requestedProspectsStateField,
            prospectsSyncedStateField,
            syncedCompanyURLField,
        } = IntegrationConfigForPreviewProspectsAction[integrationType];

        if (prospectsInCompany[syncedCompanyURLField] === undefined) {
            return;
        }

        state[syncedCompaniesStateField][cleanCompanyName] = {
            organizationId: prospectsInCompany.organizationId,
            companyName: cleanCompanyName,
            url: prospectsInCompany[syncedCompanyURLField],
            organizationDomain: prospectsInCompany.organizationDomain,
        };

        if (!prospectsInCompany[syncedCompanyURLField]) {
            prospectsInCompany.prospects.forEach((prospect) => {
                state[requestedProspectsStateField][prospect.personId] = true;
                state[prospectsSyncedStateField][prospect.personId] = {
                    personId: prospect.personId,
                    organizationId: prospectsInCompany.organizationId,
                    url: null,
                };
            });
        }
    }
};
