import { ICommand, Topics } from '@zaplify/common';
import { CompanyBlocklistDto, BlocklistedOrganizationDto } from '../prospects';

interface IUpdateCompanyBlocklistCommand {
    organizationId: string;
    blocklist: BlocklistedOrganizationDto[];
}

export class ReplaceCompanyBlocklistCommand extends ICommand<{
    blocklist: CompanyBlocklistDto;
    added: number;
    skipped: number;
}> {
    public static override readonly TOPIC = Topics.PROSPECTS;
    public readonly organizationId: string;
    public readonly blocklist: BlocklistedOrganizationDto[];

    constructor(values: IUpdateCompanyBlocklistCommand) {
        super();
        this.organizationId = values.organizationId;
        this.blocklist = values.blocklist;
    }
}
