import { ScrollArea } from '@shadcn/ui/components/ui/scroll-area';
import { Button } from '@shadcn/ui/components/ui/button';
import { Briefcase, Building2, Linkedin, MapPin, Text, User, UserCheck, X } from 'lucide-react';
import { cn } from '@shadcn/ui/lib/utils';
import { Suspense, useEffect } from 'react';
import { Skeleton } from '@shadcn/ui/components/ui/skeleton';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@shadcn/ui/components/ui/accordion';
import { useWebExtension } from '../../hooks/use-web-extension';
import { useParams } from 'react-router-dom';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useSidebars } from '@shadcn/ui/hooks/use-sidebars';
import { useAccountDetailsDialog } from '../dialogs/account-details-dialog';
import { BriefcaseClock } from '../contact-details-sidebar/briefcase-clock';
import { WorkExperience } from '../contact-details-sidebar/work-experience';
import { ProspectDataDto } from '@zaplify/prospects';
import { Avatar, AvatarFallback, AvatarImage } from '@shadcn/ui/components/ui/avatar';
import { getInitials } from '../../functions/get-initials';

interface LinkedInMemberSidebarProps {
    isOpen: boolean;
    onClose: () => void;
    className?: string;
    direction?: 'left' | 'right';
}

export const LinkedInMemberSidebarSkeleton = ({
    isOpen,
    onClose,
    className,
    direction = 'right',
}: Omit<LinkedInMemberSidebarProps, 'memberId'>) => {
    return (
        <div
            className={cn(
                'bg-background-primary flex flex-col h-full transition-transform duration-300 ease-in-out min-w-full max-w-full rounded-[inherit]',
                direction === 'right' ? 'border-l' : 'border-r',
                direction === 'right'
                    ? isOpen
                        ? 'translate-x-0'
                        : 'translate-x-full'
                    : isOpen
                    ? 'translate-x-0'
                    : '-translate-x-[50%]',
                className
            )}
        >
            <div className="flex justify-end items-start w-full">
                <Button variant="ghost" size="icon" onClick={onClose}>
                    <X className="h-4 w-4" />
                </Button>
            </div>
            <div className="flex flex-col items-center py-4 justify-center">
                <div className="w-16 h-16 rounded-full overflow-hidden">
                    <Skeleton className="w-full h-full" />
                </div>
            </div>
            <ScrollArea className="flex-1">
                <Accordion
                    type="multiple"
                    className="w-full"
                    defaultValue={['personal-details', 'contact-details', 'work-experience']}
                >
                    <AccordionItem value="personal-details">
                        <AccordionTrigger className="p-4">Personal details</AccordionTrigger>
                        <AccordionContent className="px-4">
                            <div className="flex flex-col gap-3">
                                <div className="flex items-start gap-3">
                                    <User className="h-5 w-5 stroke-[1]" />
                                    <TextSkeleton />
                                </div>
                                <div className="flex items-start gap-3">
                                    <Briefcase className="h-5 w-5 stroke-[1]" />
                                    <TextSkeleton />
                                </div>
                                <div className="flex items-start gap-3">
                                    <Text className="h-5 w-5 stroke-[1]" />
                                    <TextSkeleton />
                                </div>
                                <div className="flex items-start gap-3">
                                    <Building2 className="h-5 w-5 stroke-[1]" />
                                    <TextSkeleton />
                                </div>
                                <div className="flex items-start gap-3">
                                    <MapPin className="h-5 w-5 stroke-[1]" />
                                    <TextSkeleton />
                                </div>
                                <div className="flex items-start gap-3">
                                    <UserCheck className="h-5 w-5 stroke-[1]" />
                                    <TextSkeleton />
                                </div>
                            </div>
                        </AccordionContent>
                    </AccordionItem>

                    <AccordionItem value="work-experience">
                        <AccordionTrigger className="p-4">Work experience</AccordionTrigger>
                        <AccordionContent className="px-4">
                            <div className="flex flex-col gap-3">
                                <div className="flex items-start gap-3">
                                    <BriefcaseClock />
                                    <TextSkeleton />
                                </div>
                                <div className="flex items-start gap-3">
                                    <BriefcaseClock />
                                    <TextSkeleton />
                                </div>
                                <div className="flex items-start gap-3">
                                    <BriefcaseClock />
                                    <TextSkeleton />
                                </div>
                            </div>
                        </AccordionContent>
                    </AccordionItem>

                    <AccordionItem value="contact-details">
                        <AccordionTrigger className="p-4">Contact details</AccordionTrigger>
                        <AccordionContent className="px-4 max-w-full">
                            <div className="flex flex-col gap-3">
                                <div className="flex items-start gap-3">
                                    <Linkedin className="h-5 w-5 stroke-[1]" />
                                    <TextSkeleton />
                                </div>
                            </div>
                        </AccordionContent>
                    </AccordionItem>
                </Accordion>
            </ScrollArea>
        </div>
    );
};

const TextSkeleton = () => <Skeleton className="h-5 w-32 bg-background-secondary" />;

export const LinkedInMemberSidebar = ({
    isOpen,
    onClose,
    className,
    direction = 'right',
}: LinkedInMemberSidebarProps) => {
    const { memberId } = useParams();
    const { getLinkedInProfileFull } = useWebExtension();
    const { openAccountDetailsDialog } = useAccountDetailsDialog();

    const { data, error } = useSuspenseQuery({
        queryKey: ['linkedinProfile', memberId],
        queryFn: () =>
            getLinkedInProfileFull({ publicIdentifier: memberId }).catch(() => {
                return { success: false, prospectData: null, connectionStatus: null };
            }),
    });

    const profileNotFound = error || !data.success || !data.prospectData;
    const profile: ProspectDataDto | null = profileNotFound ? null : data.prospectData;
    const connectionStatus = profileNotFound ? null : data.connectionStatus;

    return (
        <div
            className={cn(
                'bg-background-primary flex flex-col h-full transition-transform duration-300 ease-in-out min-w-full max-w-full rounded-[inherit]',
                direction === 'right' ? 'border-l' : 'border-r',
                direction === 'right'
                    ? isOpen
                        ? 'translate-x-0'
                        : 'translate-x-full'
                    : isOpen
                    ? 'translate-x-0'
                    : '-translate-x-[50%]',
                className
            )}
        >
            <div className="flex justify-end items-start w-full">
                <Button variant="ghost" size="icon" onClick={onClose}>
                    <X className="h-4 w-4" />
                </Button>
            </div>

            {profileNotFound ? (
                <div className="flex flex-col items-center justify-center h-full text-center px-4">
                    <p className="text-xl font-semibold mb-4">Profile Not Found</p>
                    <p className="text-sm text-muted-foreground mb-6">We couldn't retrieve the LinkedIn profile.</p>
                    <div className="text-sm text-muted-foreground mb-6 w-full">
                        <p className="mb-1">You can try visiting the profile:</p>
                        <a
                            href={`https://www.linkedin.com/in/${memberId}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-primary hover:underline break-words w-full inline-block px-2"
                        >
                            linkedin.com/in/{memberId}
                        </a>
                    </div>
                    <Button variant="outline" onClick={onClose} className="min-w-[100px]">
                        Close
                    </Button>
                </div>
            ) : (
                <>
                    <div className="flex flex-col items-center">
                        <div className="w-16 h-16 rounded-full overflow-hidden">
                            <Avatar className="w-full h-full">
                                <AvatarImage src={profile.linkedinProfileImgUrl ?? undefined} alt={profile.fullName} />
                                <AvatarFallback className="text-2xl font-medium">
                                    {getInitials(profile.fullName)}
                                </AvatarFallback>
                            </Avatar>
                        </div>
                        <div className="flex flex-col py-1">
                            <span className="text-md font-medium">{profile.fullName}</span>
                        </div>
                    </div>
                    <ScrollArea className="flex-1">
                        <Accordion
                            type="multiple"
                            className="w-full"
                            defaultValue={['personal-details', 'contact-details', 'work-experience']}
                        >
                            <AccordionItem value="personal-details">
                                <AccordionTrigger className="p-4">LinkedIn Profile</AccordionTrigger>
                                <AccordionContent className="px-4">
                                    <div className="flex flex-col gap-3">
                                        {profile.linkedinHeadline && (
                                            <div className="flex justify-between gap-3 items-center">
                                                <div className="flex items-start gap-3">
                                                    <Text className="h-5 w-5 stroke-[1] flex-shrink-0" />
                                                    <span className="text-sm">{profile.linkedinHeadline}</span>
                                                </div>
                                            </div>
                                        )}
                                        {profile?.occupationTitle && (
                                            <div className="flex justify-between gap-3 items-center">
                                                <div className="flex items-start gap-3">
                                                    <Briefcase className="h-5 w-5 stroke-[1] flex-shrink-0" />
                                                    <span className="text-sm">{profile?.occupationTitle}</span>
                                                </div>
                                            </div>
                                        )}
                                        {profile?.occupationTitle && profile?.organizationName && (
                                            <div className="flex items-start gap-3">
                                                <Building2 className="h-5 w-5 stroke-[1]" />
                                                <span
                                                    onClick={() =>
                                                        openAccountDetailsDialog(profile?.organizationId ?? '')
                                                    }
                                                    className="text-sm hover:underline cursor-pointer"
                                                >
                                                    {profile?.organizationName}
                                                </span>
                                            </div>
                                        )}
                                        {profile.location && (
                                            <div className="flex items-start gap-3">
                                                <MapPin className="h-5 w-5 stroke-[1] flex-shrink-0" />
                                                <span className="text-sm">{profile.location}</span>
                                            </div>
                                        )}
                                        {connectionStatus && (
                                            <div className="flex items-start gap-3">
                                                <UserCheck
                                                    className={cn(
                                                        'h-5 w-5 stroke-[2]',
                                                        connectionStatus === 'connected'
                                                            ? 'text-green-600'
                                                            : 'text-red-500'
                                                    )}
                                                />
                                                <span
                                                    className={cn(
                                                        'text-sm',
                                                        connectionStatus === 'connected'
                                                            ? 'text-green-600'
                                                            : 'text-red-500'
                                                    )}
                                                >
                                                    {connectionStatus === 'connected' ? 'Connected' : 'Not connected'}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </AccordionContent>
                            </AccordionItem>

                            <AccordionItem value="work-experience">
                                <AccordionTrigger className="p-4">Work experience</AccordionTrigger>
                                <AccordionContent className="px-4">
                                    <div className="flex flex-col gap-3">
                                        <div className="flex items-start gap-3">
                                            <BriefcaseClock />
                                            <div className="flex flex-col gap-3">
                                                {profile?.experiences?.slice(0, 3).map((experience, index) => (
                                                    <div
                                                        key={index}
                                                        className="'transition-all duration-300 ease-in-out',"
                                                    >
                                                        <WorkExperience key={index} experience={experience} />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </AccordionContent>
                            </AccordionItem>

                            <AccordionItem value="contact-details">
                                <AccordionTrigger className="p-4">Contact details</AccordionTrigger>
                                <AccordionContent className="px-4 max-w-full">
                                    <div className="flex flex-col gap-3">
                                        {profile.linkedinProfileUrl && (
                                            <div className="flex items-start gap-3">
                                                <Linkedin className="h-5 w-5 stroke-[1]" />
                                                <a
                                                    href={profile.linkedinProfileUrl}
                                                    className="text-sm line-clamp-1 flex-1 break-all hover:underline"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {profile.linkedinProfileUrl.replace(/(https:\/\/)?(www\.)?/i, '')}
                                                </a>
                                            </div>
                                        )}
                                    </div>
                                </AccordionContent>
                            </AccordionItem>
                        </Accordion>
                    </ScrollArea>
                </>
            )}
        </div>
    );
};

export const LinkedInMemberSidebarWrapper = ({ className, onClose }: { className?: string; onClose?: () => void }) => {
    const { sidebarState, toggleSidebar } = useSidebars();
    const { memberId } = useParams();
    const isExpanded = sidebarState?.searchProfile;

    useEffect(() => {
        if (!isExpanded && memberId) {
            toggleSidebar('searchProfile');
        }
    }, [memberId]);

    const handleClose = () => {
        toggleSidebar('searchProfile');
        onClose?.();
    };

    if (!memberId) return null;

    return (
        <div
            className={cn(
                'flex transition-[width] duration-300 ease-in-out overflow-hidden max-w-full md:max-w-[280px]',
                className,
                isExpanded ? 'w-full md:w-[280px]' : 'w-0'
            )}
        >
            <Suspense fallback={<LinkedInMemberSidebarSkeleton isOpen={isExpanded} onClose={handleClose} />}>
                <LinkedInMemberSidebar isOpen={isExpanded} onClose={handleClose} />
            </Suspense>
        </div>
    );
};
