import { IEvent, Topics } from '@zaplify/common';
import { Creator, NoteDto } from '../dtos';

export class NoteCreatedEvent extends IEvent {
    public static override readonly TOPIC = Topics.PROSPECTS;
    public readonly noteId: string;
    public readonly userId: string;
    public readonly prospectId: string;
    public readonly creator: Creator;
    public readonly content: string;
    public readonly title: string;
    public readonly discarded: boolean;
    public readonly createdAt: Date | undefined;

    constructor(params: NoteDto) {
        super();
        this.noteId = params.id;
        this.userId = params.userId;
        this.prospectId = params.prospectId;
        this.creator = params.creator;
        this.content = params.content;
        this.title = params.title;
        this.discarded = true;
        this.createdAt = params.createdAt;
    }

    get partitionKey(): string {
        return this.userId;
    }
}
