import { FC, useState, useEffect } from 'react';
import { Crown } from 'lucide-react';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '@shadcn/ui/components/ui/dialog';
import { Button } from '@shadcn/ui/components/ui/button';
import { PaymentStatus, useBillingListener } from '../../../../../hooks/use-billing-listener';
interface SuccessDialogProps {
    isOpen: boolean;
    onClose: () => void;
}

export const SuccessDialog: FC<SuccessDialogProps> = ({ isOpen, onClose }) => {
    const [loading, setLoading] = useState(true);
    const myOnClose = () => {
        setLoading(true);
        onClose();
        setLoading(false);
    };
    const { paymentStatus } = useBillingListener();

    useEffect(() => {
        if (paymentStatus === PaymentStatus.SUCCESS) {
            setLoading(false);
        }
    }, [paymentStatus]);
    return (
        <Dialog open={isOpen} onOpenChange={myOnClose}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle></DialogTitle>
                    <DialogDescription></DialogDescription>
                </DialogHeader>

                <div className="flex flex-col items-center justify-center gap-4 py-8">
                    {paymentStatus === PaymentStatus.SUCCESS || paymentStatus === PaymentStatus.NONE ? (
                        <>
                            <Crown className="h-12 w-12 text-brand" />
                            <h3 className="text-2xl font-semibold">Plan upgraded</h3>
                            <p className="text-center text-muted-foreground">
                                Please contact{' '}
                                <a href="mailto:support@andsend.com" className="text-brand hover:underline">
                                    support@andsend.com
                                </a>{' '}
                                if you have any questions
                            </p>
                        </>
                    ) : paymentStatus === PaymentStatus.PROCESSING ? (
                        <>
                            <div className="flex items-center gap-2">
                                <svg
                                    className="animate-spin h-4 w-4"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                    ></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg>
                            </div>
                            <h3 className="text-2xl font-semibold">Processing payment...</h3>
                            <p>Please wait while we process your payment...</p>
                        </>
                    ) : (
                        <>
                            <p></p>
                        </>
                    )}
                    {paymentStatus === PaymentStatus.SUCCESS ||
                        (paymentStatus === PaymentStatus.NONE && (
                            <Button onClick={myOnClose} className="mt-2" disabled={loading}>
                                {loading ? (
                                    <div className="flex items-center gap-2">
                                        <svg
                                            className="animate-spin h-4 w-4"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                        >
                                            <circle
                                                className="opacity-25"
                                                cx="12"
                                                cy="12"
                                                r="10"
                                                stroke="currentColor"
                                                strokeWidth="4"
                                            ></circle>
                                            <path
                                                className="opacity-75"
                                                fill="currentColor"
                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                            ></path>
                                        </svg>
                                    </div>
                                ) : (
                                    'Done'
                                )}
                            </Button>
                        ))}
                </div>
            </DialogContent>
        </Dialog>
    );
};
