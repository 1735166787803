import { MarkdownText } from '@shadcn/ui/components/assistant-ui/markdown-text';
import { ToolFallback } from '@shadcn/ui/components/assistant-ui/tool-fallback';
import { FC } from 'react';
import { AssistantActionBar, BranchPicker, UserMessage as UserMessagePrimitive } from '@assistant-ui/react-ui';
import { MeAvatar } from '../../../components/me-avatar';
export const UserMessage: FC = () => {
    return (
        <UserMessagePrimitive.Root>
            <MeAvatar size="lg" className="aui-avatar-root col-start-2 row-start-1 mt-1 ml-4" />
            <UserMessagePrimitive.Content
                className="col-start-1 row-start-1 w-fit ml-auto bg-background-brand-primary-subtle text-text-primary-on-brand-subtle rounded-xl"
                components={{ Text: MarkdownText, tools: { Fallback: ToolFallback } }}
            />
            <BranchPicker />
            <AssistantActionBar />
        </UserMessagePrimitive.Root>
    );
};

UserMessage.displayName = 'UserMessage';
