import { IQuery } from '@zaplify/common';
import { Topics } from '@zaplify/common';
import { IReferralLinkData } from '../referral-link';

export class GetUserReferralLinksQuery extends IQuery<IReferralLinkData[]> {
    public static override readonly TOPIC = Topics.USERS;
    constructor(readonly userId: string) {
        super();
    }
}
