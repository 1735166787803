import * as React from 'react';
import { Avatar, AvatarFallback, AvatarImage } from '@shadcn/ui/components/ui/avatar';
import { useMemo } from 'react';
import { useAuth } from '../providers/authentication-provider';
import { useLinkedin } from '../hooks/use-linkedin';
import { cn } from '@shadcn/ui/lib/utils';
interface MeAvatarProps {
    className?: string;
    size?: 'sm' | 'md' | 'lg' | 'xl';
}

const getInitials = (name: string) => {
    const parts = name.split(' ').filter(Boolean);
    const initials = parts.length > 1 ? parts[0][0] + parts[parts.length - 1][0] : name.slice(0, 2);
    return initials.toUpperCase();
};

export const MeAvatar: React.FC<MeAvatarProps> = ({
    className,
    size = 'md',
}: {
    className?: string;
    size?: 'sm' | 'md' | 'lg' | 'xl';
}) => {
    const { authState } = useAuth();
    const { linkedinAccount: linkedinChannelAccount } = useLinkedin();
    const imgUrl = useMemo(() => linkedinChannelAccount?.avatarUrl, [linkedinChannelAccount?.avatarUrl]);
    const fullName = useMemo(
        () => `${authState?.user?.firstName} ${authState?.user?.lastName}`,
        [authState?.user?.firstName, authState?.user?.lastName]
    );
    const initials = useMemo(() => getInitials(fullName), [fullName]);
    return (
        <Avatar className={cn(className)} size={size}>
            <AvatarImage src={imgUrl} />
            <AvatarFallback>{initials}</AvatarFallback>
        </Avatar>
    );
};
