import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import PropTypes from 'prop-types';

const AVERAGE_READ_SPEED = 20;
const PREVENT_AUTO_HIDE = null;

export const Notification = (props) => {
    const { onClose, severity, text, horizontal, vertical, open = true } = props;

    function setAutoHideDuration(textLength) {
        const minDuration = 6000;
        const textAverageReadTime = textLength * AVERAGE_READ_SPEED;

        if (textAverageReadTime < minDuration) {
            return minDuration;
        }

        if (textAverageReadTime > minDuration && textAverageReadTime < 5000) {
            return textAverageReadTime;
        }

        if (textAverageReadTime > 5000) {
            return PREVENT_AUTO_HIDE;
        }
    }

    return (
        <Snackbar
            anchorOrigin={{ vertical: vertical || 'bottom', horizontal: horizontal || 'left' }}
            open={open}
            autoHideDuration={setAutoHideDuration(text.length)}
            onClose={onClose}
        >
            <MuiAlert elevation={7} onClose={onClose} severity={severity || 'success'}>
                {text}
            </MuiAlert>
        </Snackbar>
    );
};

Notification.propTypes = {
    text: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    severity: PropTypes.oneOf(['success', 'error', 'info', 'warning']),
    vertical: PropTypes.string,
    horizontal: PropTypes.string,
    open: PropTypes.bool,
};
