import Lottie from 'lottie-react';
import andSendIconBlack from './ANDSEND_icon_black.json';
import andSendIconWhite from './ANDSEND_icon_white.json';
import andSendMainLoaderColor from './ANDSEND_icon_color.json';

export const AndSendLoader = (
    props: {
        variation?: 'color' | 'black' | 'white';
        loop?: boolean;
        start?: number;
        end?: number;
    } & React.AllHTMLAttributes<HTMLDivElement>,
) => {
    let animationData;

    switch (props.variation) {
        case 'black': {
            animationData = andSendIconBlack;
            break;
        }
        case 'white': {
            animationData = andSendIconWhite;
            break;
        }
        case 'color': {
            animationData = andSendMainLoaderColor;
            break;
        }
        default: {
            animationData = andSendIconBlack;
            break;
        }
    }

    return <Lottie animationData={animationData} loop={props.loop ?? true} className={props.className} {...props} />;
};
