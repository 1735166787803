import * as React from 'react';
import { DialogContent, DialogHeader, DialogTitle, DialogDescription } from '@shadcn/ui/components/ui/dialog';
import { SearchParamDialog, useSearchParamDialog } from '@shadcn/ui/components/search-param-dialog';
import { CreatePlaybookCard } from '../../pages/playbooks/components/playbook-cards/create-playbook-card';
import { Separator } from '@shadcn/ui/components/ui/separator';
import { usePlaybookTemplates } from '../../pages/playbooks/hooks/use-playbook-templates';
import { PlaybookTemplateCard } from '../../pages/playbooks/components/playbook-cards/playbook-template-card';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../../routes/paths';
import { useFlags } from 'launchdarkly-react-client-sdk';
export const createPlaybookParam = 'create-playbook';

export const useCreatePlaybookDialog = () => {
    const { open, close } = useSearchParamDialog(createPlaybookParam);

    return {
        openCreatePlaybookDialog: () => open('true'),
        closeCreatePlaybookDialog: close,
    };
};

export const CreatePlaybookDialog: React.FC = () => {
    const { getTemplatePreviews } = usePlaybookTemplates();
    const navigate = useNavigate();
    const { newPlaybookView } = useFlags();
    const playbookPath = (newPlaybookView ? '/new' + paths.PLAYBOOKS : paths.PLAYBOOKS) + '/new';

    return (
        <SearchParamDialog modal={true} param={createPlaybookParam}>
            <DialogContent className="max-w-[70%] px-0">
                <div className="flex flex-col gap-1 flex-1 px-6">
                    <DialogHeader>
                        <DialogTitle className="font-semibold">Create a new playbook</DialogTitle>
                    </DialogHeader>
                    <DialogDescription>Start from scratch or use a template to help get you started</DialogDescription>
                </div>
                <div className="flex flex-col gap-4 w-full overflow-y-auto max-h-[70vh] px-6">
                    <CreatePlaybookCard size={'small'} onClick={() => navigate(playbookPath)} />
                    <Separator />
                    <div className="flex flex-col gap-4">
                        <div>
                            <span className="font-medium">Templates</span>
                            <p className="text-sm text-text-tertiary">
                                Get inspired and learn how playbooks can help you get the best out of your communication
                            </p>
                        </div>
                        <div className="flex justify-center">
                            <div className="inline-flex flex-wrap gap-4">
                                {getTemplatePreviews().map((template) => (
                                    <PlaybookTemplateCard key={template.title} {...template} />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </DialogContent>
        </SearchParamDialog>
    );
};
