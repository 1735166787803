import { useResetAtom } from 'jotai/utils';
import { Target } from 'lucide-react';
import { messageComposerStateAtom } from '../../../../atoms/chats';
import { paths } from '../../../../routes/paths';
import { NavigationMainItem } from './navigation-main-item';
import { Tooltip } from '@mui/material';
import { useLocation } from 'react-router-dom';

export const ActionsListItem = ({
    openSidebar,
    numOutreachSuggestions,
}: {
    openSidebar: boolean;
    numOutreachSuggestions: number;
}) => {
    const resetMessageComposerStateAtom = useResetAtom(messageComposerStateAtom);
    const { pathname } = useLocation();
    const todosActive = pathname.startsWith(paths.TODOS);

    return (
        <Tooltip title={openSidebar ? '' : 'Actions'} placement="right" arrow>
            {/* important className for Frigade */}
            <span onClick={resetMessageComposerStateAtom} className="actions-nav-item">
                <NavigationMainItem
                    title="Actions"
                    to={paths.TODOS}
                    icon={<Target size={20} strokeWidth="1px" />}
                    isActive={todosActive}
                    isSidebarPinned={openSidebar}
                />
            </span>
        </Tooltip>
    );
};
