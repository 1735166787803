import * as React from 'react';
import { useEffect, Suspense } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { MessageComposer } from './message-composer/message-composer';
import { Skeleton } from '@shadcn/ui/components/ui/skeleton';
import { MessageComposerProvider } from '../../../hooks/messaging/use-message-composer';
import { useMessages } from '../../../hooks/messaging/use-messages';
import { ContactAvatar } from '../../../components/contact-avatar';
import { AndSendIcon } from '@shadcn/ui/components/icons/and-send/andsend-icon';
import { useSdk } from '../../../sdk';
import { MessageList, MessageListSkeleton } from './message-list';
import { DeleteNoteDialog } from '../../../components/dialogs/delete-note-dialog';
import { Loader2 } from 'lucide-react';
import { useProspectSync } from '../../../hooks/use-prospect-sync';
import { useLinkedin } from '../../../hooks/use-linkedin';
import { getProspectLinkedinTracker } from '../../../sdk/internal/tracker.sdk';
import { useIsMobile } from '@shadcn/ui/hooks/use-mobile';
import { ProspectLinkedinProfileVerifiedStatus } from '@zaplify/prospects';
import { SparklesAI } from './message-composer/sparkles-ai';

export const ConversationSkeleton = () => {
    return (
        <div className="flex flex-col h-full justify-end w-full">
            <Suspense fallback={<MessageListSkeleton />}>
                <MessageListSkeleton />
            </Suspense>

            <div className="px-4 pb-4 bg-background flex-shrink-0">
                <div className="flex flex-col">
                    <Skeleton className="h-[200px] w-full border-4 border-background-brand-primary-subtle">
                        <Skeleton className="m-5 w-[80%] h-2 bg-background-brand-primary-subtle" />
                    </Skeleton>
                </div>
            </div>
        </div>
    );
};

export const ConversationComponent = () => {
    const scrollAreaRef = React.useRef<HTMLDivElement>(null);
    const { prospectId } = useParams();
    const { messages } = useMessages({ prospectId });
    const { prospectIdsCurrentlySyncing } = useProspectSync();
    const {
        prospect: { getProspectById },
    } = useSdk();
    const { data: prospectData } = useQuery(getProspectById(prospectId));
    const { linkedinAccount } = useLinkedin();
    const { data: linkedinTracker } = useQuery(getProspectLinkedinTracker(prospectId, linkedinAccount?.id));
    const [messageComposerSize, setMessageComposerSize] = React.useState<'large' | 'small'>('large');
    const [lastSizeToggleTimestamp, setLastSizeToggleTimestamp] = React.useState(0);
    const isMobile = useIsMobile();

    const [isSyncingMessages, setIsSyncingMessages] = React.useState(prospectIdsCurrentlySyncing.has(prospectId));
    useEffect(() => {
        if (
            prospectData?.data.linkedinProfileVerification?.status ===
            ProspectLinkedinProfileVerifiedStatus.profile_not_found
        ) {
            setIsSyncingMessages(false);
            return;
        }
        const syncing = prospectIdsCurrentlySyncing.has(prospectId);
        if (syncing) {
            setIsSyncingMessages(true);
        } else if (isSyncingMessages) {
            setTimeout(() => {
                setIsSyncingMessages(false);
            }, 8000);
        }

        if (!linkedinTracker?.connectionStatus) {
            setIsSyncingMessages(true);
        } else if (isSyncingMessages) {
            setTimeout(() => {
                setIsSyncingMessages(false);
            }, 8000);
        }
    }, [prospectData?.data, prospectIdsCurrentlySyncing, linkedinTracker, prospectId]);

    const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
        if (isMobile) {
            return;
        }
        if (Date.now() - lastSizeToggleTimestamp < 100) {
            return;
        }
        const element = e.target as HTMLDivElement;
        const isOverflowing = element.scrollHeight > element.clientHeight;
        const scrollBottom = element.scrollHeight - element.scrollTop - element.clientHeight;

        if (isOverflowing) {
            if (messageComposerSize === 'small' && scrollBottom <= 1) {
                setMessageComposerSize('large');
                scrollAreaRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
                setLastSizeToggleTimestamp(Date.now());
            } else if (messageComposerSize === 'large' && scrollBottom > 60) {
                setMessageComposerSize('small');
                setLastSizeToggleTimestamp(Date.now());
            }
        }
    };

    return (
        <MessageComposerProvider key={prospectId} prospectId={prospectId}>
            <DeleteNoteDialog />
            <div className="flex flex-col h-full w-full justify-end">
                <Suspense fallback={<MessageListSkeleton />}>
                    {messages?.length > 0 ? (
                        <MessageList scrollAreaRef={scrollAreaRef} onScroll={handleScroll} />
                    ) : (
                        <div className="flex flex-col h-full justify-between items-center">
                            <div className="flex flex-col h-full">
                                <div className="flex flex-col items-center justify-center gap-3 p-10">
                                    <ContactAvatar
                                        avatarUrl={prospectData?.data?.linkedinProfileImgUrl}
                                        fullName={prospectData?.data?.fullName}
                                        size="xl"
                                    />
                                    {prospectData?.data?.fullName ? (
                                        <span className="text-md font-medium">{prospectData.data.fullName}</span>
                                    ) : (
                                        <Skeleton className="h-6 w-48 bg-background-brand-primary-subtle" />
                                    )}
                                    <div className="flex flex-row bg-background-tertiary rounded-xl p-2 items-center justify-center text-sm text-center">
                                        <span>
                                            Adjust the message as needed using the
                                            <div className=" mx-1 inline-block">
                                                <SparklesAI height={'14px'} />
                                            </div>
                                            button and get the conversation going.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {isSyncingMessages && (
                                // Syncing messages loader box
                                <div className="flex flex-row justify-center items-center w-fit p-4 gap-2 mb-8 border rounded-xl">
                                    Syncing messages...
                                    <Loader2 className="h-6 w-6 animate-spin" />
                                </div>
                            )}
                        </div>
                    )}
                </Suspense>

                <div className="flex bottom-auto left-auto right-auto mb-14 md:mb-0 md:px-4 md:pb-4 md:w-full md:min-w-full bg-background flex-shrink-0">
                    <Suspense
                        fallback={
                            <Skeleton className="h-[200px] w-full border-4 border-background-brand-primary-subtle">
                                <Skeleton className="m-5 w-[80%] h-2 bg-background-brand-primary-subtle" />
                            </Skeleton>
                        }
                    >
                        <MessageComposer size={messageComposerSize} intercomAdjustedSendButton />
                    </Suspense>
                </div>
            </div>
        </MessageComposerProvider>
    );
};

export const Conversation = () => {
    return <ConversationComponent />;
};

export default Conversation;
