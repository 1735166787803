import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { trackPageView, createPageView } from '../lib/tracking';
import { LDClient, useLDClient } from 'launchdarkly-react-client-sdk';
import LogRocket from 'logrocket';
import { useAuth } from '../providers/authentication-provider';
import { UserComposedDto } from '@zaplify/users/shared';

export const usePageTracking = () => {
    const { pathname } = useLocation();
    const {
        authState: { user },
    } = useAuth();

    useEffect(() => {
        if (user?.id && user?.userOrganizationId) {
            const pageView = createPageView(user?.id, user?.userOrganizationId);
            trackPageView(pageView);
        }
    }, [pathname, user?.id, user?.userOrganizationId]);
};

export const useIdentifyUser = () => {
    const {
        authState: { user, isImpersonating },
    } = useAuth();
    const ldClient = useLDClient();
    const hasIdentified = useRef(false);
    useEffect(() => {
        if (isImpersonating) {
            window?.analytics?.reset();
            hasIdentified.current = false;
            return;
        }
        if (user?.id && !hasIdentified.current) {
            identifyUser(user, ldClient)
                .catch((error) => {
                    console.error('Error identifying user:', error);
                })
                .finally(() => {
                    hasIdentified.current = true;
                });
        }
    }, [user?.id, ldClient, isImpersonating]);
};

const identifyUser = async (user: UserComposedDto, ldClient: LDClient) => {
    console.log('identifyUser', user);

    if (!user?.id) {
        return;
    }

    window?.analytics?.identify(user.id, {
        first_name: user.firstName,
        last_name: user.lastName,
        email: user.email,
        location: user.location,
        organization_id: user.userOrganizationId,
        organization: user.userOrganization?.name,
        created_at: user.createdAt,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    if (user.userOrganization?.id) {
        window?.analytics?.group(user.userOrganization?.id, {
            name: user.userOrganization?.name,
            seats_available: user.userOrganization?.seatSummmary?.availableSeatsCount,
            seats_total: user.userOrganization?.seatSummmary?.totalSeatsCoun,
            seats_occupied: user.userOrganization?.seatSummmary?.occupiedSeatsCount,
            deactivated_users_count: user.userOrganization?.seatSummmary?.deactivatedUsersCount,
        });
    }

    // @TODO Remove this when we have added it to Segment instead
    LogRocket?.identify(user.id, {
        first_name: user.firstName,
        last_name: user.lastName,
        email: user.email,
    });

    // @TODO Remove this when we have added it to Segment instead
    ldClient?.identify(
        {
            kind: 'user',
            key: user.id,
            name: user.firstName + ' ' + user.lastName,
            email: user.email,
            organization: user.userOrganization?.name,
            organizationId: user.userOrganization?.id,
            plan: 'not-set-here',
            created_at: user.createdAt,
            user_id: user.id,
            // @TODO Check if can be removed and remove camelCase props below
            createdAt: user.createdAt,
            userId: user.id,
        },
        null,
        () => {
            console.log("New user context's flags available");
        }
    );
};
