import { getZaplifySdk } from '@zaplify/sdk';
import { createMutationOptions } from './sdk-utils';
export interface FeedbackDto {
    featureName: string;
    rating: number;
    feedback: string;
    timestamp: string;
}

export const sendFeedback = () => {
    return createMutationOptions<void, Error, FeedbackDto>({
        mutationFn: (variables) => getZaplifySdk().profiles.feedback.sendFeedback(variables),
        invalidateQueries: (data) => [],
    });
};
