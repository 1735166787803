export const PROSPECT_FIELDS = {
    LINKEDIN_PROFILE_URL: 'prospect.linkedinProfileUrl',
    ORGANIZATION_DOMAIN: 'prospect.organizationDomain',
    ORGANIZATION_INDUSTRY: 'prospect.organizationIndustry',
    ORGANIZATION_SIZE: 'prospect.organizationSize',
} as const;

export const CUSTOM_FIELDS = {
    CAMPAIGN_NAME: 'custom.campaignName',
} as const;

export const CONTACT_REF_FIELDS = {
    IS_LINKEDIN_CONNECTED: 'contactRef.isLinkedInConnected',
    STATUS_IN_CAMPAIGN: 'contactRef.statusInCampaign',
} as const;
