import { Box, Button, Typography } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import { Skeleton } from '@mui/material';
import EnterprisePlanCardStyled from './EnterprisePlanCard.styled';
import { PlanCode } from '@zaplify/subscriptions';
import { companyNameCorrectlyCased } from '../../enums/company-name';

export const EnterprisePlanCard = ({
    loadingState,
    planCode,
}: {
    loadingState: 'loading' | 'idle';
    planCode: PlanCode;
}) => {
    return (
        <EnterprisePlanCardStyled>
            {loadingState == 'loading' && <EnterprisePlanCardLoading />}
            {loadingState === 'idle' && (
                <div className="wrapper">
                    <div className="top">
                        <Typography className="title">Enterprise</Typography>
                        <div className="subtitle">
                            {`Get in touch with us to talk about your custom ${companyNameCorrectlyCased} Enterprise plan.`}
                        </div>
                    </div>
                    <div className="bottom">
                        <div className="action">
                            <PhoneIcon />
                            Get in touch with your customer success manager
                        </div>
                    </div>
                </div>
            )}
        </EnterprisePlanCardStyled>
    );
};

const EnterprisePlanCardLoading = () => (
    <Box height={'100%'} display={'flex'} flexDirection={'column'} justifyContent={'space-between'}>
        <Box padding={'28px 24px 0'}>
            <Skeleton
                animation="wave"
                variant="rectangular"
                width={100}
                height={24}
                style={{ marginBottom: '12px', borderRadius: '4px' }}
            />
            <Skeleton animation="wave" variant="rectangular" width="100%" height={21} style={{ borderRadius: '4px' }} />
        </Box>
        <Box padding={'0 16px 16px'}>
            <Skeleton animation="wave" variant="rectangular" height={50} style={{ borderRadius: '300px' }} />
        </Box>
    </Box>
);
