export function isNotZaplifyDomain(email: string): boolean {
    const nonZaplifyDomain: string = 'zaplify';
    const emailParts: string[] = email.split('@');

    // Make sure the email address has a domain part
    if (emailParts.length !== 2) {
        return true;
    }

    // Extract the domain part and check if it does not match nonZaplifyDomain
    const domain: string = emailParts[1].split('.')[0];
    return domain !== nonZaplifyDomain;
}

export function validateEmail(email: string): boolean {
    const emailRegex: RegExp = /\S+@\S+\.\S+/;
    return emailRegex.test(email);
}

export const ensureHttps = (url: string) => {
    if (!url) return null;
    const hasProtocol = /^https?:\/\//i.test(url);
    return hasProtocol ? url : `https://${url}`;
};
