import ArrowForward from '@mui/icons-material/ArrowForward';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { CSVDownloadErrorMessages } from '../../../constants';
import { Button as ButtonAtom } from '../../atoms/button';
import { FileOpener } from '../../atoms/fileOpener';
import { Modal } from '../../atoms/modal';
import { CSVModalContainer } from './containers';

const ImportCSVModal = (props) => {
    const [companies, setCompanies] = useState([]);
    const [errorMessage, setErrorMessage] = useState(CSVDownloadErrorMessages.noError);
    const handleImported = (companies) => setCompanies(companies);
    const handleClick = () => {
        props.handleTargetsChange && props.handleTargetsChange(companies, 'organizations');
        props.openModal && props.openModal();
        props.closeModal();
        setCompanies([]);
    };

    return (
        <Modal
            closeModal={() => {
                props.closeModal();
                props.closeBigModal && props.closeBigModal();
            }}
            isOpen={props.isOpen}
        >
            <CSVModalContainer>
                <Typography variant='h3'>Upload CSV with companies to block</Typography>
                <Typography>
                    Before you upload your file below, make sure your file is ready to be imported. Remove legal
                    entities from company names, e.g. Inc or LLC. To be valid, your list must contain only one column
                    with company names.{' '}
                    {props.blacklist
                        ? 'Note that the blocklist is limited to max 5.000 companies.'
                        : 'Note that the upload is limited to max 150 companies.'}{' '}
                    See tutorial{' '}
                    {props.blacklist ? (
                        <a
                            rel='noopener noreferrer'
                            href='https://www.loom.com/share/ce1bdb286e4144488d0eeb3407f096c4'
                            target='_blank'
                        >
                            here
                        </a>
                    ) : (
                        <a
                            rel='noopener noreferrer'
                            href='https://www.loom.com/share/ce1bdb286e4144488d0eeb3407f096c4'
                            target='_blank'
                        >
                            here
                        </a>
                    )}
                    .
                </Typography>
                <div>
                    <FileOpener
                        limit={props.maxCompanies || 150}
                        handleError={(value) => setErrorMessage(value)}
                        handleImported={handleImported}
                    />
                </div>
                <Typography>
                    {(errorMessage === CSVDownloadErrorMessages.noError &&
                        companies &&
                        companies.length > 0 &&
                        companies.length + ' companies') ||
                        (errorMessage === CSVDownloadErrorMessages.twoColumns &&
                            'Imported file has more than one column') ||
                        (errorMessage === CSVDownloadErrorMessages.companiesLimit &&
                            (props.blacklist
                                ? 'You can blocklist max 5.000 companies'
                                : 'Imported file has more than 150 companies')) ||
                        (errorMessage === CSVDownloadErrorMessages.unknown && "Couldn't import file")}
                </Typography>
                <ButtonAtom
                    disabled={
                        (companies && !(companies.length > 0)) || errorMessage !== CSVDownloadErrorMessages.noError
                    }
                    endIcon={<ArrowForward />}
                    text='Next'
                    variant='contained'
                    onClick={() => handleClick()}
                />
            </CSVModalContainer>
        </Modal>
    );
};

ImportCSVModal.propTypes = {
    closeModal: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    setCSVDisplay: PropTypes.func,
    handleTargetsChange: PropTypes.func.isRequired,
    handleCompanies: PropTypes.func,
};

export default ImportCSVModal;
