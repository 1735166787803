import { Link } from '@mui/material';
import { LinkedInError } from '../../components/organisms/outreach-integrations-modal/linkedin-connection-error';
import { companyNameCorrectlyCased } from '../../enums/company-name';

export type TextElement = {
    title: string | React.ReactElement;
    steps: string[];
};

const textElements: TextElement[] = [
    {
        title: 'Couldn’t connect to LinkedIn. Follow these steps:',
        steps: [
            'Ensure that your browser is either Google Chrome, Microsoft Edge, or another Chromium-based browser.',
            'Make sure that your Chrome extension is installed and active.',
            'Make sure you’re logged in to LinkedIn in this browser.',
            'Close all open LinkedIn tabs and open a new LinkedIn tab.',
            `Go back to ${companyNameCorrectlyCased} and connect again.`,
        ],
    },
    {
        title: 'If it doesn’t work, try this:',
        steps: [
            'Close your browser and open it again.',
            'Log-out and log-in from LinkedIn.',
            `Go back to ${companyNameCorrectlyCased} and connect again.`,
        ],
    },
    {
        title: (
            <>
                If none of the above work, <Link>contact our support</Link>.
            </>
        ),
        steps: [],
    },
];

export const ErrorList = () => (
    <LinkedInError textElements={textElements} onLinkedInConnect={() => {}} label="" hideButton={true} />
);
