import { useState, useCallback, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AuthenticationStatus, ChannelProvider, ChannelType } from '@zaplify/channel-accounts/shared';
import { useSdk } from '../../../../sdk';
import { useAuth } from '../../../../providers/authentication-provider';
import { useToast } from '@shadcn/ui/hooks/use-toast';
import { getZaplifySdk } from '@zaplify/sdk';

// Error messages
const ErrorMessages = {
    GMAIL_INVALID_SCOPE: 'Unable to add channel account: Invalid Gmail scope to send email',
    MICROSOFT_INVALID_ACCOUNT_TYPE:
        'Unable to add channel account: Invalid account type for Microsoft email. Non personal required',
    MICROSOFT_CREATE_ERROR: 'Could not complete the authentication with Microsoft. Please contact our support.',
    GENERAL_GOOGLE_ERROR: 'Could not complete the authentication with Google. Please contact our support.',
    GENERAL_MICROSOFT_ERROR: 'Could not complete the authentication with Microsoft. Please contact our support.',
    MICROSOFT_ON_PREMISES:
        'Could not connect to Office 365. It seems like your Microsoft email is not hosted on office.com. Read more in <a href="https://intercom.help/zaplify/en/articles/8205345">our article</a> or contact our support.',
    GMAIL_SCOPES_INVALID:
        'Zaplify needs some additional access to be able to send emails. Please make sure to tick all the required permission checkboxes when going through the authentication flow. <a href="https://intercom.help/zaplify/en/articles/8005143-how-do-i-add-missing-gmail-permissions" target="_blank"><strong>Read more</strong></a>',
    OUTLOOK_SCOPES_INVALID:
        'Zaplify needs some additional access to be able to send emails. Please make sure to tick all the required permission checkboxes when going through the authentication flow.',
    LINKEDIN_CONNECTION_ERROR: 'Could not complete the authentication with LinkedIn. Please contact our support.',
};

export type LinkedInConnectionStatus = 'not_connected' | 'pending' | 'connected' | 'error';
export type ExtensionStatus = 'not_installed' | 'installed' | 'loading';

export function useApps() {
    const {
        channelAccount: {
            getMyChannelAccounts,
            addChannelAccount,
            reauthorizeChannelAccount,
            disconnectChannelAccount,
        },
    } = useSdk();
    const {
        authState: { userId },
    } = useAuth();
    const queryClient = useQueryClient();
    const [error, setError] = useState<string | null>(null);
    const [extensionStatus, setExtensionStatus] = useState<ExtensionStatus>('loading');
    const [linkedInConnectionStatus, setLinkedInConnectionStatus] = useState<LinkedInConnectionStatus>('not_connected');

    const { toast } = useToast();
    const chromeStoreZaplifyId = 'nolanbablkmlhllilaloenjhaplnfhof';
    const extensionId = import.meta?.env?.VITE_ZAPLIFY_CHROME_EXTENSION_ID || chromeStoreZaplifyId;

    // Query to fetch channel accounts
    const { data: channelAccounts, isLoading } = useQuery(getMyChannelAccounts());

    // Transform the response to the format we need for the UI
    const accounts = channelAccounts || [];
    const gmailAccount = accounts.find((account) => account.provider === 'GMAIL');
    const gmail = { isAdded: !!gmailAccount, provider: gmailAccount?.provider, status: gmailAccount?.status };
    const outlookAccount = accounts.find((account) => account.provider === 'OUTLOOK');
    const outlook = {
        isAdded: !!outlookAccount,
        provider: outlookAccount?.provider,
        status: outlookAccount?.status,
    };
    const linkedinAccount = accounts.find((account) => account.provider === 'LINKEDIN');
    const linkedin = {
        isAdded: !!linkedinAccount,
        provider: linkedinAccount?.provider,
        status: linkedinAccount?.status,
    };

    // Mutation to add a Gmail account
    const addEmailAccountMutation = useMutation(addChannelAccount());

    // Function to handle adding Gmail account
    const addGmail = async (authCode: string) => {
        const gmailChannelAccountDto = {
            type: ChannelType.EMAIL,
            provider: ChannelProvider.GMAIL,
            userId,
            GMAIL: {
                authCode,
            },
        };

        try {
            await addEmailAccountMutation.mutateAsync(gmailChannelAccountDto);

            // Track the event if analytics is available
            const analytics = (window as any).analytics;
            analytics?.track('User Configured Gmail');

            // Clear any previous errors
            setError(null);
        } catch (error: any) {
            console.error('Gmail connection error:', error);

            if (error?.payload?.errorStatus === AuthenticationStatus.SCOPES_INVALID) {
                setError(ErrorMessages.GMAIL_SCOPES_INVALID);
            } else {
                setError(ErrorMessages.GENERAL_GOOGLE_ERROR);
            }
        }
    };

    // Function to handle adding Outlook account
    const addOutlook = async (authCode: string) => {
        const outlookChannelAccountDto = {
            type: ChannelType.EMAIL,
            provider: ChannelProvider.OUTLOOK,
            userId,
            OUTLOOK: {
                authCode,
            },
        };

        try {
            await addEmailAccountMutation.mutateAsync(outlookChannelAccountDto);

            // Track the event if analytics is available
            const analytics = (window as any).analytics;
            analytics?.track('User Configured Outlook');

            // Clear any previous errors
            setError(null);
        } catch (error: any) {
            console.error('Outlook connection error:', error);

            if (error?.payload?.errorStatus === AuthenticationStatus.SCOPES_INVALID) {
                setError(ErrorMessages.OUTLOOK_SCOPES_INVALID);
            } else if (
                error?.payload?.errorStatus === AuthenticationStatus.CONFIGURATION_INVALID ||
                error?.message === ErrorMessages.MICROSOFT_INVALID_ACCOUNT_TYPE
            ) {
                setError(ErrorMessages.MICROSOFT_INVALID_ACCOUNT_TYPE);
            } else if (error?.payload?.errorStatus === AuthenticationStatus.ON_PREMISES_ACCOUNT_CONFIGURATION_INVALID) {
                setError(ErrorMessages.MICROSOFT_ON_PREMISES);
            } else {
                setError(ErrorMessages.GENERAL_MICROSOFT_ERROR);
            }
        }
    };

    // Mutation to refresh a Gmail account
    const reauthorizeEmailAccountMutation = useMutation(reauthorizeChannelAccount());

    // Function to handle refreshing Gmail account
    const reauthorizeGmail = async (authCode: string) => {
        const notAuthenticatedGmailAccount = accounts.find(
            (account) => account.provider === 'GMAIL' && account.status === 'NOT_AUTHENTICATED'
        );

        if (!notAuthenticatedGmailAccount) {
            throw new Error('We could not find your original account');
        }

        const gmailChannelAccountDto = {
            GMAIL: {
                authCode,
            },
        };

        try {
            await reauthorizeEmailAccountMutation.mutateAsync({
                id: notAuthenticatedGmailAccount.id,
                data: gmailChannelAccountDto,
            });

            // Clear any previous errors
            setError(null);
        } catch (error: any) {
            console.error('Gmail refresh error:', error);
            setError(ErrorMessages.GENERAL_GOOGLE_ERROR);
        }
    };

    // Function to handle refreshing Outlook account
    const reauthorizeOutlook = async (authCode: string) => {
        const notAuthenticatedOutlookAccount = accounts.find(
            (account) => account.provider === 'OUTLOOK' && account.status === 'NOT_AUTHENTICATED'
        );

        if (!notAuthenticatedOutlookAccount) {
            throw new Error('We could not find your original account');
        }

        const outlookChannelAccountDto = {
            OUTLOOK: {
                authCode,
            },
        };

        try {
            await reauthorizeEmailAccountMutation.mutateAsync({
                id: notAuthenticatedOutlookAccount.id,
                data: outlookChannelAccountDto,
            });

            // Clear any previous errors
            setError(null);
        } catch (error: any) {
            console.error('Outlook refresh error:', error);
            setError(ErrorMessages.GENERAL_MICROSOFT_ERROR);
        }
    };

    // Handler for Gmail OAuth callback
    const handleGmailAuth = useCallback(
        async (authCode: string) => {
            const needsReauthorization = accounts.some(
                (account) => account.provider === 'GMAIL' && account.status === 'NOT_AUTHENTICATED'
            );

            try {
                if (needsReauthorization) {
                    await reauthorizeGmail(authCode);
                } else {
                    await addGmail(authCode);
                }

                // Refresh the accounts data
                queryClient.invalidateQueries({ queryKey: ['getMyChannelAccounts'] });
            } catch (error) {
                console.error('Gmail auth error:', error);
            }
        },
        [accounts, queryClient, reauthorizeGmail, addGmail]
    );

    // Handler for Outlook OAuth callback
    const handleOutlookAuth = useCallback(
        async (authCode: string) => {
            const needsReauthorization = accounts.some(
                (account) => account.provider === 'OUTLOOK' && account.status === 'NOT_AUTHENTICATED'
            );

            try {
                if (needsReauthorization) {
                    await reauthorizeOutlook(authCode);
                } else {
                    await addOutlook(authCode);
                }

                // Refresh the accounts data
                queryClient.invalidateQueries({ queryKey: ['getMyChannelAccounts'] });
            } catch (error) {
                console.error('Outlook auth error:', error);
            }
        },
        [accounts, queryClient, reauthorizeOutlook, addOutlook]
    );

    // Check extension status
    useEffect(() => {
        checkExtensionStatus();
    }, []);

    const checkExtensionStatus = async () => {
        try {
            if (typeof chrome !== 'undefined' && chrome.runtime && chrome.runtime.sendMessage) {
                chrome.runtime.sendMessage(extensionId, { type: 'PING' }, (response) => {
                    if (chrome.runtime.lastError || !response) {
                        setExtensionStatus('not_installed');
                    } else {
                        setExtensionStatus('installed');
                    }
                });
            } else {
                setExtensionStatus('not_installed');
            }
        } catch (error) {
            console.error('Error checking extension status:', error);
            setExtensionStatus('not_installed');
        }
    };

    const connectToLinkedIn = async () => {
        if (extensionStatus !== 'installed') {
            window.open('https://chrome.google.com/webstore/detail/andsend/nolanbablkmlhllilaloenjhaplnfhof', '_blank');
            return;
        }

        setLinkedInConnectionStatus('pending');

        try {
            if (typeof chrome !== 'undefined' && chrome.runtime && chrome.runtime.sendMessage) {
                chrome.runtime.sendMessage(extensionId, { type: 'AUTHENTICATE_LINKEDIN' }, (response) => {
                    if (chrome.runtime.lastError || !response || response.error) {
                        setLinkedInConnectionStatus('error');
                        toast({
                            title: 'Connection failed',
                            description: 'Could not connect to LinkedIn. Please try again.',
                            variant: 'destructive',
                        });
                    } else {
                        // Poll for account connection
                        const interval = setInterval(async () => {
                            // Fetch the latest accounts data
                            await queryClient.invalidateQueries({ queryKey: [getMyChannelAccounts().queryKey] });
                            const accounts = queryClient.getQueryData([getMyChannelAccounts().queryKey]) as any[];
                            const linkedInAccount = accounts?.find(
                                (account) => account.provider === 'LINKEDIN' && account.status === 'AUTHENTICATED'
                            );

                            if (linkedInAccount) {
                                setLinkedInConnectionStatus('connected');
                                clearInterval(interval);

                                toast({
                                    title: 'Connected',
                                    description: 'Successfully connected to LinkedIn',
                                });
                            }
                        }, 2000);

                        setTimeout(() => {
                            clearInterval(interval);
                            queryClient.invalidateQueries({ queryKey: [getMyChannelAccounts().queryKey] });
                        }, 30000);
                    }
                });
            }
        } catch (error) {
            console.error('Error connecting to LinkedIn:', error);
            setLinkedInConnectionStatus('error');
            toast({
                title: 'Connection failed',
                description: 'Could not connect to LinkedIn. Please try again.',
                variant: 'destructive',
            });
        }
    };

    // Clear error message
    const clearError = useCallback(() => {
        setError(null);
    }, []);

    // Disconnect an account
    const disconnectAccount = async (accountId: string) => {
        try {
            const channelAccount = accounts.find((account) => account.id === accountId);
            if (!channelAccount) {
                throw new Error('Channel account not found');
            }
            const disconnectChannelAccountMutation = useMutation(disconnectChannelAccount());

            await disconnectChannelAccountMutation.mutateAsync({ userId, channelAccountId: channelAccount.id });

            // Refresh accounts data
            await queryClient.invalidateQueries({ queryKey: ['getMyChannelAccounts'] });

            toast({
                title: 'Account disconnected',
                description: 'All associated messages will be removed from your conversations',
            });
        } catch (error) {
            console.error('Error disconnecting account:', error);
            toast({
                title: 'Error',
                description: 'Could not disconnect the account. Please try again.',
                variant: 'destructive',
            });
        }
    };

    // Manually refresh accounts
    const refreshAccounts = async () => {
        try {
            await queryClient.invalidateQueries({ queryKey: ['getMyChannelAccounts'] });
        } catch (error) {
            console.error('Error refreshing accounts:', error);
        }
    };

    return {
        accounts: {
            gmail,
            outlook,
            linkedin,
        },
        channelAccounts,
        isLoading: addEmailAccountMutation.isPending || reauthorizeEmailAccountMutation.isPending,
        error,
        clearError,
        handleGmailAuth,
        handleOutlookAuth,
        isConnecting: addEmailAccountMutation.isPending || reauthorizeEmailAccountMutation.isPending,
        connectToLinkedIn,
        linkedInConnectionStatus,
        extensionStatus,
        disconnectAccount,
        refreshAccounts,
    };
}
