import { styled } from '@mui/styles';

export const SubscriptionCanceledContainer = styled('div')(({ theme }) => ({
    background: '#F7F8FA',
    width: 913,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    '& > .left-container': {
        padding: '96px 170px 0 60px',
        boxSizing: 'border-box',
        '& > .header': {
            fontWeight: 500,
            fontSize: 13,
            color: theme.palette.darkGrey,
        },
        '& > .account-state-title': {
            marginTop: 4,
            fontWeight: 400,
            fonstSize: 24,
        },
        '& > .canceled-date': {
            marginTop: 15,
            color: theme.palette.darkGrey,
        },
        '& > .body': {
            marginTop: 40,
            fontSize: 15,
            color: theme.palette.subline,
        },
        '& .upgrade-button': {
            marginTop: 20,
            borderRadius: 300,
        },
        '& > .paused-details': {
            marginTop: 24,
            fontSize: 15,
            color: theme.palette.darkGrey,
        },
    },
    '& > .right-container': {
        background: '#FEF2F6',
        padding: '80px 29px 90px 29px',
        boxSizing: 'border-box',
        width: 305,
        '& > .title': {
            marginBottom: 47,
            textAlign: 'center',
        },
        '& > .feature-card': {
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: 14,
            gap: 20,
            background: '#FFFFFF',
            borderRadius: 8,
            marginBottom: 18,
            color: theme.palette.subline,
            fontSize: 14,
        },
    },
}));
