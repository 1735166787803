import { FC } from 'react';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@shadcn/ui/components/ui/dialog';
import { Button } from '@shadcn/ui/components/ui/button';
import { UserRole } from '@zaplify/users/shared';
import { UserToManage } from '../../../../../../hooks/useUsersManagement';

interface RoleChangeDialogProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    user: UserToManage;
    role: UserRole;
}

export const RoleChangeDialog: FC<RoleChangeDialogProps> = ({ isOpen, onClose, onConfirm, user, role }) => {
    const isSettingAdmin = role === UserRole.ORGANIZATION_ADMIN;

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>{isSettingAdmin ? 'Set as Admin' : 'Set as Member'}</DialogTitle>
                    <DialogDescription>
                        {isSettingAdmin
                            ? `Are you sure you want to make ${
                                  user.fullName || user.email
                              } an admin? They will have full access to manage the organization.`
                            : `Are you sure you want to make ${
                                  user.fullName || user.email
                              } a member? They will have limited access to manage the organization.`}
                    </DialogDescription>
                </DialogHeader>
                <DialogFooter>
                    <Button variant="outline" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            onConfirm();
                            onClose();
                        }}
                    >
                        {isSettingAdmin ? 'Set as Admin' : 'Set as Member'}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};
