import { BaseSdk } from '../../base.sdk';
import { ConfirmPasswordResetDto, EmailDto, ActionCodeDto } from '@zaplify/users/shared';

export class AuthenticationSdk extends BaseSdk {
    async resetPassword(email: string) {
        const resp = this.post<EmailDto>({
            path: `${this.authenticationPath}/reset-password`,
            payload: { emailAdress: email },
        });
        return resp;
    }

    async confirmPasswordReset(confirmPasswordReset: ConfirmPasswordResetDto) {
        const resp = this.post<ConfirmPasswordResetDto>({
            path: `${this.authenticationPath}/confirm-password-reset`,
            payload: confirmPasswordReset,
        });
        return resp;
    }

    async sendEmailVerification(emailDto: EmailDto) {
        const resp = this.post<EmailDto>({
            path: `${this.authenticationPath}/send-email-verification`,
            payload: emailDto,
        });
        return resp;
    }

    async setEmailVerified(actionCode: ActionCodeDto) {
        const resp = this.post<ActionCodeDto>({
            path: `${this.authenticationPath}/set-email-verified`,
            payload: { actionCode },
        });
        return resp;
    }

    async chooseOrganization(organizationId?: string, authAsUser?: string) {
        const resp = this.post<ActionCodeDto>({
            path: `${this.authenticationPath}/choose-organization`,
            payload: { organizationId, authenticateAsUser: authAsUser },
        });
        return resp;
    }
}
