import { IEvent, Topics } from '@zaplify/common';
import { SubscriptionDto } from '../subscription.dto';

export class SubscriptionCreatedEvent extends IEvent {
    public static override readonly TOPIC = Topics.BILLING;

    constructor(readonly data: SubscriptionDto) {
        super();
    }

    get partitionKey(): string {
        return this.data.organizationId;
    }
}
