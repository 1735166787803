import { FC, useMemo } from 'react';
import { useAuth } from '../../../providers/authentication-provider';
import { cn } from '@shadcn/ui/lib/utils';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button } from '@shadcn/ui/components/ui/button';
import { Building, ChevronLeft, CreditCard, Link, Users } from 'lucide-react';
import { useIsMobile } from '@shadcn/ui/hooks/use-mobile';

interface AccountManagementHeaderProps {
    title: string;
}

export const AccountManagementHeader: FC<AccountManagementHeaderProps> = ({ title }) => {
    const {
        authState: {
            user: {
                userOrganization: { name },
            },
        },
    } = useAuth();

    const navigate = useNavigate();

    const isMobile = useIsMobile();

    return (
        <div className="flex items-center justify-between border-b px-4 py-4">
            <div className="flex items-center">
                {isMobile && (
                    <Button variant="ghost" size="icon" className="mr-2" onClick={() => navigate(-1)}>
                        <ChevronLeft className="h-5 w-5" />
                    </Button>
                )}
                {/* <NavBar items={mobileNavItems} /> */}
                <div className="flex flex-col">
                    <span className="text-sm text-muted-foreground">{name} organization</span>
                    <h1 className="font-semibold text-xl">{title}</h1>
                </div>
            </div>
        </div>
    );
};
