import { Badge } from '@shadcn/ui/components/ui/badge';
import { cn } from '@shadcn/ui/lib/utils';
import { useActions } from '../../../hooks/use-actions';
import { motion, AnimatePresence } from 'motion/react';
import { ComponentProps, FC } from 'react';
import { Popover, PopoverContent, PopoverTrigger } from '@shadcn/ui/components/ui/popover';
import { ActionCard } from '../../actions/components/action-card';
import { paths } from '../../../../routes/paths';
import { ScrollArea } from '@shadcn/ui/components/ui/scroll-area';
import { ChevronDown } from 'lucide-react';

type CompletedActionsBadgeProps = ComponentProps<typeof Badge>;

export const CompletedActionsBadge: FC<CompletedActionsBadgeProps> = ({ className, ...props }) => {
    const { prospectIdsWithCompletedActions, getCompletedActions } = useActions();
    const completedActionsCount = prospectIdsWithCompletedActions.reduce((acc, prospectId) => {
        return acc + getCompletedActions(prospectId).length;
    }, 0);

    if (completedActionsCount === 0) {
        return null;
    }

    return (
        <Popover>
            <PopoverTrigger>
                <Badge
                    variant="secondary"
                    className={cn('flex items-center gap-2 cursor-pointer', className)}
                    {...props}
                >
                    <Badge variant="success" className="p-[0.1rem] px-2">
                        <AnimatePresence mode="wait">
                            <motion.span
                                key={completedActionsCount}
                                initial={{ scale: 1.5, opacity: 0 }}
                                animate={{ scale: 1, opacity: 1 }}
                                transition={{
                                    type: 'spring',
                                    stiffness: 500,
                                    damping: 15,
                                    duration: 0.3,
                                }}
                            >
                                {completedActionsCount}
                            </motion.span>
                        </AnimatePresence>
                    </Badge>
                    <span>Completed</span>
                    <ChevronDown className="w-4 h-4" />
                </Badge>
            </PopoverTrigger>
            <PopoverContent
                className="mr-3 rounded-xl p-2 w-[350px]"
                side="bottom"
                onOpenAutoFocus={(e) => e.preventDefault()}
            >
                <div className="flex items-center justify-between border-b p-1">
                    <span className="text-sm text-muted-foreground font-medium">Completed actions</span>
                </div>
                <div className="max-h-[300px] overflow-y-auto mt-1">
                    {prospectIdsWithCompletedActions.length ? (
                        prospectIdsWithCompletedActions.map((prospectId, index) => (
                            <ActionCard
                                prospectId={prospectId}
                                isCompleted={true}
                                key={'completed-' + index}
                                linkPrefix={paths.NEW.MESSAGES}
                                disableSelected
                            />
                        ))
                    ) : (
                        <div className="flex flex-col items-center w-full text-text-tertiary">
                            <span className="mt-10 mb-10">No completed actions today</span>
                        </div>
                    )}
                </div>
            </PopoverContent>
        </Popover>
    );
};
