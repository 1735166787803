import { Badge } from '@shadcn/ui/components/ui/badge';
import { Tooltip, TooltipContent, TooltipTrigger } from '@shadcn/ui/components/ui/tooltip';
import * as React from 'react';

export const AwaitingResponseBadge: React.FC<{}> = () => {
    return (
        <Tooltip>
            <TooltipTrigger>
                <Badge variant="gray">
                    <div className="flex flex-row gap-2 items-center">
                        <div className="w-1.5 h-1.5 rounded-full bg-gray-utility-700" />
                        Awaiting response
                    </div>
                </Badge>
            </TooltipTrigger>
            <TooltipContent>You'll get a new action when the contact replies or it's time to follow up</TooltipContent>
        </Tooltip>
    );
};
