import { useQuery, useSubscription } from '@apollo/client';
import { GET_CONTACT_SUGGESTIONS_COUNT, GET_CONTACT_SUGGESTIONS_COUNT_SUBSCRIPTION } from '@zaplify/graphql';
import { Button } from '@shadcn/ui/components/ui/button';
import { Loader2, UserRoundSearchIcon } from 'lucide-react';
import { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { paths } from '../../../../routes/paths';
import { PageHeader } from '../../../components/page-header';
import { useFlags } from 'launchdarkly-react-client-sdk';
export const DiscoverPageHeader: FC = () => {
    const { data: contactSuggestionsCount, loading: contactSuggestionsCountLoading } = useSubscription(
        GET_CONTACT_SUGGESTIONS_COUNT_SUBSCRIPTION
    );
    const { shadcnSearchEnabled } = useFlags();
    const redirectPath = shadcnSearchEnabled ? paths.NEW.DISCOVER_PATHS.SEARCH : paths.DISCOVER_PATHS.SEARCH;

    return (
        <PageHeader
            title="Discover"
            description="Discover contacts inside and outside your network that align with your playbook goals."
            count={contactSuggestionsCount?.ContactSuggestions_aggregate?.aggregate?.count}
            isLoadingCount={contactSuggestionsCountLoading}
            rightElement={
                <Link to={redirectPath}>
                    <Button variant="outline" id="filter-search-button" className="hidden md:flex">
                        <UserRoundSearchIcon className="w-4 h-4" />
                        <span>Filter search</span>
                    </Button>
                </Link>
            }
        />
    );
};
