import { FC, useMemo, useState } from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@shadcn/ui/components/ui/card';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@shadcn/ui/components/ui/select';
import { BaseChart } from './charts/base-chart';
import { Separator } from '@shadcn/ui/components/ui/separator';
import { StatsCard } from './stats-card';
import { useDashboardMetrics } from '../hooks/use-dashboard-metrics';
import {
    differenceInDays,
    format,
    sub,
    isFirstDayOfMonth,
    isLastDayOfMonth,
    startOfYear,
    endOfYear,
    startOfMonth,
    endOfMonth,
    startOfWeek,
    endOfWeek,
    isSameYear,
    isSameMonth,
} from 'date-fns';
import { Clock, CornerUpLeft, CornerUpRight, MessageCircle, UserPlus } from 'lucide-react';

interface DashboardMetricsProps {
    startDate: Date;
    endDate: Date;
    distinctProspects: boolean;
}

const chartConfig = {
    messagesSent: {
        label: 'Messages Sent',
        theme: {
            light: 'hsl(221.2 83.2% 53.3%)',
            dark: 'hsl(217.2 91.2% 59.8%)',
        },
    },
    messagesReceived: {
        label: 'Messages Received',
        theme: {
            light: 'hsl(262.1 83.3% 57.8%)',
            dark: 'hsl(263.4 70% 50.4%)',
        },
    },
    connectionsAccepted: {
        label: 'New Connections',
        theme: {
            light: 'hsl(291.5 95.5% 58.8%)',
            dark: 'hsl(291.5 95.5% 58.8%)',
        },
    },
};

const colorClass = (value: number, previousValue: number) => {
    if (value > previousValue) {
        return 'text-text-success-primary';
    } else if (value < previousValue) {
        return 'text-text-error-primary';
    } else {
        return 'text-muted-foreground';
    }
};

export const DashboardMetrics: FC<DashboardMetricsProps> = ({ startDate, endDate, distinctProspects }) => {
    const [frequency, setFrequency] = useState<'daily' | 'weekly'>('daily');

    const { messagesSentData, latestMetrics, previousMetrics, loading, error, timeSaved, previousTimeSaved } =
        useDashboardMetrics({
            startDate,
            endDate,
            frequency,
            distinctProspects,
        });

    const periodName = useMemo(() => {
        const days = differenceInDays(endDate, startDate);
        const currentPeriod = `${format(startDate, 'MMM d')} - ${format(endDate, 'MMM d')}`;

        // Check for single day
        if (days === 0) {
            return `${format(startDate, 'MMM d')} vs the previous day`;
        }

        // Check for full week (Monday to Sunday)
        if (
            days === 6 &&
            startDate.getTime() === startOfWeek(startDate, { weekStartsOn: 1 }).getTime() &&
            endDate.getTime() === endOfWeek(startDate, { weekStartsOn: 1 }).getTime()
        ) {
            return `${currentPeriod} vs previous week`;
        }

        // Check for full month
        if (isFirstDayOfMonth(startDate) && isLastDayOfMonth(endDate) && isSameMonth(startDate, endDate)) {
            return `${format(startDate, 'MMMM yyyy')} vs the previous month`;
        }

        // Check for full year
        if (
            startDate.getTime() === startOfYear(startDate).getTime() &&
            endDate.getTime() === endOfYear(startDate).getTime()
        ) {
            return `${format(startDate, 'yyyy')} vs the previous year`;
        }

        // Default to showing exact date ranges
        const previousPeriod = `${format(sub(startDate, { days: days + 1 }), 'MMM d')} - ${format(
            sub(startDate, { days: 1 }),
            'MMM d'
        )}`;
        return `${currentPeriod} vs ${previousPeriod}`;
    }, [startDate, endDate]);

    if (error) {
        console.error(error);
        return null;
    }

    if (loading || !messagesSentData.length) {
        return null;
    }

    return (
        <div className="flex flex-col gap-8">
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                <StatsCard
                    title="Time Saved"
                    icon={<Clock className="w-4 h-4 text-text-brand-secondary" />}
                    description="Estimated time saved using Andsend"
                    tooltipDescription="Estimated time saved using Andsend. (5 minutes per message sent using Andsend)"
                    currentValue={timeSaved}
                    previousValue={previousTimeSaved}
                    format="hours"
                    periodName={periodName}
                />
                <StatsCard
                    title={distinctProspects ? 'Contacts Messaged' : 'Messages Sent'}
                    icon={
                        <div className="flex items-center gap-2">
                            <MessageCircle className="w-4 h-4 text-text-brand-secondary" />
                            <div className="-ml-3 mt-2 z-10 rounded-full bg-background p-[1px]">
                                <CornerUpRight className="w-2 h-2 text-text-brand-secondary stroke-[3]" />
                            </div>
                        </div>
                    }
                    description={
                        distinctProspects
                            ? 'Number of unique contacts messaged'
                            : 'Total messages sent to your contacts'
                    }
                    tooltipDescription={
                        distinctProspects
                            ? 'Number of unique contacts messaged'
                            : `Total messages sent to your contacts of which ${latestMetrics.messagesSentFromApp} were sent using Andsend`
                    }
                    currentValue={latestMetrics.messagesSent}
                    previousValue={previousMetrics.messagesSent}
                    periodName={periodName}
                />
                <StatsCard
                    title={distinctProspects ? 'Contacts Responded' : 'Messages Received'}
                    icon={
                        <div className="flex items-center gap-2">
                            <MessageCircle className="w-4 h-4 text-text-brand-secondary" />
                            <div className="-ml-3 mt-2 z-10 rounded-full bg-background p-[1px]">
                                <CornerUpLeft className="w-2 h-2 text-text-brand-secondary stroke-[3]" />
                            </div>
                        </div>
                    }
                    description={
                        distinctProspects
                            ? 'Number of unique contacts who responded'
                            : 'Total responses received from your contacts'
                    }
                    currentValue={latestMetrics.messagesReceived}
                    previousValue={previousMetrics.messagesReceived}
                    periodName={periodName}
                />
                <StatsCard
                    title="New Connections"
                    icon={<UserPlus className="w-4 h-4 text-text-brand-secondary" />}
                    description="New connections made with contacts"
                    currentValue={latestMetrics.connectionsAccepted}
                    previousValue={previousMetrics.connectionsAccepted}
                    periodName={periodName}
                />
            </div>

            <Separator />

            <Card className="w-full">
                <CardHeader>
                    <div className="flex justify-between items-center">
                        <CardTitle>Activity Overview</CardTitle>
                        <div className="flex gap-4 items-center">
                            <Select
                                value={frequency}
                                onValueChange={(value: 'daily' | 'weekly') => setFrequency(value)}
                            >
                                <SelectTrigger className="w-[120px]">
                                    <SelectValue placeholder="Select frequency" />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectItem value="daily">Daily</SelectItem>
                                    <SelectItem value="weekly">Weekly</SelectItem>
                                </SelectContent>
                            </Select>
                        </div>
                    </div>
                    <CardDescription>
                        {distinctProspects
                            ? 'Overview of unique prospects contacted over time'
                            : 'Overview of your messaging and connection activity over time'}
                    </CardDescription>
                </CardHeader>
                <CardContent>
                    <BaseChart
                        data={messagesSentData}
                        visibleLines={{
                            messagesSent: true,
                            messagesReceived: true,
                            connectionsAccepted: true,
                        }}
                        chartConfig={chartConfig}
                    />
                </CardContent>
            </Card>
        </div>
    );
};
