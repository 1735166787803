export const convertArrayToObject = (collection: Array<any>, key: string) => {
    const initialValue = {};
    return collection.reduce((obj, item) => {
        return {
            ...obj,
            [item[key]]: item,
        };
    }, initialValue);
};

export function uniqueValuesFilter(element: any, index: any, array: string | any[]) {
    return array.indexOf(element) === index;
}

export function generateRange(start: number, end: number, stepSize = 1) {
    const length = end - start + 1;
    return Array.from({ length }, (_, i) => (start + i) * stepSize);
}

export const arraysEqual = (a: any[], b: any[]) => {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (let i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
    }

    return true;
};

export const getKeyToArrayThatIncludesValue = (object: any, search: any) => {
    return Object.keys(object).find((key) => object[key].find((value: any) => value === search));
};

/**
 * Returns an array with arrays of the given size.
 *
 * @param inputArr {Array} array to split
 * @param chunk_size {Integer} Size of every group
 */
export function chunkArray<T>(inputArr: T[], chunk_size: number): T[][] {
    let index = 0;
    const arrayLength = inputArr.length;
    const tempArray: T[][] = [];

    for (index = 0; index < arrayLength; index += chunk_size) {
        const myChunk = inputArr.slice(index, index + chunk_size);
        // Do something if you want with the group
        tempArray.push(myChunk);
    }

    return tempArray;
}
