import { Provider, useUser } from '@frigade/react';
import { ReactNode, useEffect } from 'react';
import { useAppSelector } from '../redux/store/configureStore';

export const ProductTourProvider = ({ children }: { children?: ReactNode }) => {
    const userId = useAppSelector((state) => state.user.zaplifyUser?.id);

    return (
        <Provider apiKey={import.meta.env.VITE_FRIGADE_API_KEY} userId={userId}>
            <Identify>{children}</Identify>
        </Provider>
    );
};

const Identify = ({ children }) => {
    const user = useAppSelector((state) => state.user.zaplifyUser);
    const { addProperties } = useUser();

    useEffect(() => {
        addProperties({
            email: user?.email,
            firstName: user?.firstName,
            lastName: user?.lastName,
            createdAt: user?.createdAt,
        });
    }, [user]);
    return <>{children}</>;
};
