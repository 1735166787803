import { isMobile } from 'is-mobile';

export function isMobileBrowser() {
    const userAgent = navigator.userAgent || navigator.vendor;
    return isMobile({ ua: userAgent, tablet: true });
}

export function isBrowserMultitouch() {
    return window.navigator.maxTouchPoints > 1;
}

export function isChromeBrowser() {
    return (function (agent) {
        switch (true) {
            case agent.indexOf('edge') > -1:
                return false;
            case agent.indexOf('edg') > -1:
                return false;
            case agent.indexOf('opr') > -1 && !!window.opr:
                return false;
            case agent.indexOf('chrome') > -1 && !!window.chrome:
                return true;
            case agent.indexOf('trident') > -1:
                return false;
            case agent.indexOf('firefox') > -1:
                return false;
            case agent.indexOf('safari') > -1:
                return false;
            default:
                return false;
        }
    })(window.navigator.userAgent.toLowerCase());
}

export function isChromiumBasedBrowser() {
    return !!window.chrome;
}

export function isInAppBrowser() {
    const userAgent = navigator.userAgent || navigator.vendor || '';
    console.log(`🚀 isInAppBrowser > userAgent: ${userAgent}`);
    const inAppBrowserKeywords = [
        /LinkedIn/i,
        /FBAN|FBAV/i, // Facebook
        /Instagram/i,
        /Twitter/i,
        /Snapchat/i,
        /WhatsApp/i,
        /Messenger/i,
        /WebView/i,
    ];

    return inAppBrowserKeywords.some((keyword) => keyword.test(userAgent));
}

export function hasExtensionAPI() {
    return typeof chrome !== 'undefined' && typeof chrome.runtime !== 'undefined';
}

export function canInstallChromeExtensions() {
    return isChromeBrowser() && hasExtensionAPI();
}

export const sleep = (m) => new Promise((r) => setTimeout(r, m));
