import { Box, IconButton, InputAdornment, TextField, Typography, Tooltip } from '@mui/material';
import { Expand, Eye, EyeOff, Trash2 } from 'lucide-react';
import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import FeedbackMessage from './feedback-message';

const FormInputField = <FieldName extends string>({
    id,
    disabled = false,
    tooltip,
    label,
    register,
    error,
    placeholder,
    type,
    info = null,
    endAdornment = null,
    isLoading = false,
    canRemove = false,
    onRemove,
    multiline = false,
    rows = 4,
    handleClickExpand = null,
}: {
    id?: FieldName;
    disabled?: boolean;
    tooltip?: string;
    label?: string;
    register: UseFormRegisterReturn<FieldName>;
    error: string | undefined;
    placeholder: string;
    type: string;
    info?: string | null;
    endAdornment?: React.ReactNode | null;
    isLoading?: boolean;
    canRemove?: boolean;
    onRemove?: () => void;
    multiline?: boolean;
    rows?: number;
    handleClickExpand?: () => void;
}) => {
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = React.useCallback(() => setShowPassword((show) => !show), []);

    return (
        <Tooltip title={tooltip}>
            <Box sx={{ width: '100%' }} className={`form-input-${id}`}>
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '6px' }}>
                    {label && (
                        <Typography
                            component="label"
                            htmlFor={id}
                            sx={{ fontSize: '13px', fontWeight: 400, lineHeight: '21px', color: '#575c65' }}
                        >
                            {label}
                        </Typography>
                    )}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <TextField
                            id={id}
                            margin="normal"
                            fullWidth
                            size="small"
                            name={id}
                            multiline={multiline}
                            rows={multiline ? rows : 1}
                            autoComplete={id}
                            placeholder={placeholder}
                            sx={{
                                paddingBottom: '0px',
                                backgroundColor: disabled ? '#f0f0f0' : 'white',
                                marginTop: '0px',
                                borderRadius: '8px',
                                '& .MuiOutlinedInput-root': {
                                    ...(handleClickExpand ? { paddingRight: '26px' } : {}),
                                    borderRadius: '8px',
                                    height: multiline ? 'auto' : '40px',
                                    '& fieldset': {
                                        borderColor: disabled ? '#d3d3d3' : '#E4E6EB',
                                    },
                                },
                            }}
                            {...register}
                            type={type === 'password' ? (showPassword ? 'text' : 'password') : type}
                            error={!!error}
                            InputProps={{
                                endAdornment: handleClickExpand ? (
                                    <Box
                                        sx={{
                                            '& .MuiIconButton-root': {
                                                position: 'absolute',
                                                top: 4,
                                                right: 16,
                                            },
                                        }}
                                    >
                                        <Tooltip title="Expand" placement="top">
                                            <IconButton aria-label="expand" onClick={handleClickExpand} edge="end">
                                                <Expand size={16} strokeWidth={1} />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                ) : endAdornment && type === 'submit' ? (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            edge="end"
                                            disabled={disabled}
                                        >
                                            {showPassword ? <EyeOff size={16} /> : <Eye size={16} />}
                                        </IconButton>
                                    </InputAdornment>
                                ) : canRemove ? (
                                    <IconButton
                                        onClick={onRemove}
                                        sx={{
                                            '&:hover': {
                                                color: '#FE5C5E',
                                            },
                                        }}
                                        disabled={disabled}
                                    >
                                        <Trash2 size={18} />
                                    </IconButton>
                                ) : null,
                            }}
                            disabled={isLoading || disabled}
                        />
                    </Box>
                </Box>
                <FeedbackMessage error={error} info={info} />
            </Box>
        </Tooltip>
    );
};

export default FormInputField;
