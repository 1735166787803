import { IEvent, Topics } from '@zaplify/common';

export class MessageGenerationDataSourceFoundEvent extends IEvent {
    public static override readonly TOPIC = Topics.CAMPAIGNS;

    constructor(readonly data: { userId: string; organizationId: string; sourceUrl: string }) {
        super();
    }

    get partitionKey(): string {
        return this.data.organizationId;
    }
}
