import * as React from 'react';
import { cn } from '@shadcn/ui/lib/utils';
import { motion } from 'motion/react';
import { OnMessageAddedByProspectIdSubscription, OnUserContactsByIdsSubscription } from '@zaplify/graphql';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { ContactAvatar } from '../../../components/contact-avatar';
import { useDraftMessage } from '../../../hooks/messaging/use-draft-messages';
import { Linkedin, Pen } from 'lucide-react';
import { htmlToPlain } from '../../../functions/html-to-plain';
import { ContactSource } from '@zaplify/services/user-contacts/shared';
import { AddContactButton } from '../../../components/buttons/add-contact-button';
import { ConversationCardSkeleton } from '../../../components/conversation-card-skeleton';
import { Badge } from '@shadcn/ui/components/ui/badge';
import { paths } from '../../../../routes/paths';

export function replaceNewLinesWithBreaks(inputString: string): string {
    inputString = inputString.replace(/\r\n/g, '<br />');
    inputString = inputString.replace(/\\r/g, '');
    inputString = inputString.replace(/\r/g, '');
    inputString = inputString.replace(/\n/g, '<br />');
    inputString = inputString.replace(/\\n/g, '<br />');

    return inputString;
}

export interface ConversationCardItem {
    prospectId: string;
    firstName: string;
    lastName: string;
    organizationName: string;
    title: string;
    linkedinProfileImgUrl: string;
    sentOn: string;
    direction: string;
    content: string;
    subjectLine: string;
    actionType: string;
}

export const ConversationCard = ({
    prospectId,
    conversation,
    userContact,
    prospectData,
}: {
    prospectId: string;
    conversation: {
        sentOn?: string;
        direction: string;
        content?: string;
        subjectLine?: string;
        actionType?: string;
    };
    userContact: OnUserContactsByIdsSubscription['UserContacts'][0];
    prospectData: { fullName: string; organizationName: string; linkedinProfileImgUrl?: string };
}) => {
    if (!prospectData?.fullName) {
        return <ConversationCardSkeleton key={'todo-0'} withBadge={false} />;
    }

    const [searchParams] = useSearchParams();

    const { draftMessage } = useDraftMessage(prospectId);
    const message = React.useMemo(() => conversation, [conversation]);
    const userContactData = React.useMemo(() => userContact, [userContact]);
    const isSelected = useParams().prospectId === prospectId;
    const prospectFirstName = prospectData?.fullName?.split(' ')?.[0];

    const playbookId = React.useMemo(() => {
        return userContactData?._GroupToUserContacts?.[0]?.Group?.id;
    }, [userContactData]);
    const userContactId = React.useMemo(() => {
        return userContactData?.id;
    }, [userContactData]);
    const showAddContactButton = userContactId && !playbookId;
    const lastMessageIsInbound = React.useMemo(() => {
        return message?.direction === 'in';
    }, [message]);

    const isDraft = React.useMemo(() => {
        if (lastMessageIsInbound && new Date(message.sentOn + 'Z') > new Date(draftMessage?.updatedAt)) {
            return false;
        }
        return !!draftMessage?.content || !!draftMessage?.subjectLine;
    }, [draftMessage, message]);

    const conversationPreviewText = React.useMemo(() => {
        if (isDraft) {
            if (draftMessage?.content) {
                return htmlToPlain(draftMessage?.content);
            }
            if (draftMessage?.subjectLine) {
                return htmlToPlain(draftMessage?.subjectLine);
            }
        }
        if (message?.actionType === 'LINKEDIN_CONNECTION_REQUEST_ACCEPTED') {
            return 'Connection request accepted';
        }
        if (message?.actionType === 'LINKEDIN_CONNECTION_REQUEST_SENT') {
            return 'Connection request sent';
        }

        if (message) {
            return htmlToPlain(
                message?.content ? replaceNewLinesWithBreaks(message?.content) : message?.subjectLine || ''
            );
        } else {
            return null;
        }
    }, [message, isDraft, draftMessage]);

    return (
        <motion.div
            layout
            initial={false}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -300 }}
            transition={{
                layout: { type: 'spring', stiffness: 300, damping: 30 },
                duration: 0.5,
            }}
        >
            <Link
                to={{
                    pathname: `./${prospectId}`,
                    search: searchParams.toString(),
                }}
                onClick={(e) => {
                    if (showAddContactButton) {
                        e.preventDefault();
                    }
                }}
                className={cn(
                    'group',
                    'flex justify-between items-center gap-2 text-sm',
                    'rounded-md',
                    'p-2',
                    'hover:bg-background-secondary-hover',
                    isSelected ? 'bg-background-brand-primary-subtle' : '',
                    showAddContactButton ? 'cursor-default' : ''
                )}
            >
                <div className="flex justify-start items-center gap-2">
                    <ContactAvatar fullName={prospectData?.fullName} avatarUrl={prospectData?.linkedinProfileImgUrl} />
                    <div className="flex flex-col gap-1 items-start">
                        <div className="flex justify-between items-center w-full">
                            <div className="flex items-center gap-1">
                                <span className="font-medium flex-shrink-0">{prospectData?.fullName}</span>
                                <span className="text-muted-foreground line-clamp-1 break-all">
                                    - {prospectData.organizationName}
                                </span>
                            </div>
                        </div>
                        <p
                            className={cn(
                                'flex text-sm text-muted-foreground line-clamp-1 break-all items-center',
                                isDraft ? 'italic' : ''
                            )}
                        >
                            {isDraft && <Pen className="stroke-[2] inline-block h-3" />}
                            {!isDraft &&
                                (message?.direction === 'in' ? (
                                    <i>{prospectFirstName}: </i>
                                ) : message?.direction === 'out' ? (
                                    <i>You: </i>
                                ) : (
                                    <Badge variant="outline">No conversation</Badge>
                                ))}
                            {conversationPreviewText}
                        </p>
                    </div>
                </div>
                {showAddContactButton && (
                    <AddContactButton
                        source={ContactSource.LinkedinImport}
                        userContactId={userContactId}
                        variant="outline"
                        size="sm"
                        alwaysDropdown={true}
                        startIcon={<Linkedin className="w-4 h-4" />}
                    />
                )}
            </Link>
        </motion.div>
    );
};
