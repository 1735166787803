import { OutreachSuggestionDto } from '@zaplify/services/messaging/shared';
import moment from 'moment';

export interface ScoredOutreachSuggestionDto extends OutreachSuggestionDto {
    score: number;
    badge?: 'hot conversation' | 'new contact' | 'reengage' | 'matching playbook';
    contactAddedAt?: Date;
    timeToAnswerScore?: number;
    timeSinceAddedScore?: number;
}

export const scoreOutreachSuggestions = (outreachSuggestions: OutreachSuggestionDto[]) => {
    const outreachSuggestionsWithMetaData = outreachSuggestions.filter((s) => s?.ConversationMetaData);
    const outreachSuggestionsWithoutMetaData = outreachSuggestions.filter((s) => !s?.ConversationMetaData);

    const getBadge = ({
        suggestion,
        isHotConversation,
        isRecentlyAdded,
        isReengagement,
        isFromOnboarding,
    }: {
        suggestion: OutreachSuggestionDto;
        isHotConversation: boolean;
        isRecentlyAdded: boolean;
        isReengagement: boolean;
        isFromOnboarding: boolean;
    }) => {
        if (!suggestion?.ConversationMetaData) {
            return undefined;
        }
        if (isHotConversation) {
            return 'hot conversation';
        }
        if (isFromOnboarding) {
            return 'matching playbook';
        }
        if (isReengagement) {
            return 'reengage';
        }
        if (isRecentlyAdded) {
            return 'new contact';
        }
        return undefined;
    };

    const getTimeToAnswerScore = (suggestion: OutreachSuggestionDto) => {
        const lastTimeToAnswer = suggestion?.ConversationMetaData?.lastTimeToAnswer;
        if (
            new Date(suggestion.ConversationMetaData.lastMessageAt).getTime() <
            moment().subtract(1, 'day').toDate().getTime()
        ) {
            return 0;
        }
        if (suggestion.ConversationMetaData.isNextMessageFollowUp) {
            return 0;
        }
        if (suggestion.ConversationMetaData.createdAt.valueOf() < moment().subtract(2, 'day').toDate().valueOf()) {
            return 0;
        }
        if (!lastTimeToAnswer) {
            return 0;
        }
        const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
        // Calculate the score based on the last time to answer.
        // The score is determined by subtracting the ratio of lastTimeToAnswer to one day in milliseconds from 1.
        // Ensure the score is between 0 and 1 by using Math.min and Math.max.
        // If the calculated score is greater than 1, it will be capped at 1.
        // If the calculated score is less than 0, it will be set to 0.
        return Math.max(Math.min(1, 1 - lastTimeToAnswer / oneDayInMilliseconds), 0);
    };

    const getTimeSinceAddedScore = (suggestion: OutreachSuggestionDto) => {
        const userContactAddedAt = suggestion?.ConversationMetaData?.userContactAddedAt;
        let timeSinceAdded: number;
        if (!userContactAddedAt) {
            timeSinceAdded = moment().diff(suggestion.createdAt);
        } else {
            timeSinceAdded = moment().diff(userContactAddedAt);
        }
        // If the contact was added less than 20 minutes ago, give it a boost.
        const lowTimeSinceAddedBoost = timeSinceAdded < 20 * 60 * 1000 ? 3 : 1;

        const twoDaysInMilliseconds = 2 * 24 * 60 * 60 * 1000;
        // Calculate the score based on the time since the contact was added.
        // The score is determined by subtracting the ratio of timeSinceAdded to two days in milliseconds from 1.
        // Ensure the score is between 0 and 1 by using Math.min and Math.max.
        // If the calculated score is greater than 1, it will be capped at 1.
        // If the calculated score is less than 0, it will be set to 0.
        return lowTimeSinceAddedBoost * Math.max(Math.min(1, 1 - timeSinceAdded / twoDaysInMilliseconds), 0);
    };

    const getIsFollowUpScore = (suggestion: OutreachSuggestionDto) => {
        const isFollowUp = suggestion?.ConversationMetaData?.isNextMessageFollowUp;
        const replyBoost = 2;
        return replyBoost * (isFollowUp ? 0 : 1);
    };

    const getRecentMessagesScore = (suggestion: OutreachSuggestionDto) => {
        const messagesLastWeek = suggestion?.ConversationMetaData?.numberOfMessagesLastWeek;
        const messagesLastDay = suggestion?.ConversationMetaData?.numberOfMessagesLastDay;
        if (!messagesLastWeek && !messagesLastDay) {
            return 0;
        }
        if (messagesLastDay > 0) {
            return 1;
        }
        if (messagesLastWeek > 0) {
            return 0.5;
        }
        return 0;
    };

    const getIsContactFromOnboarding = (suggestion: OutreachSuggestionDto) => {
        const isFromOnboarding = suggestion?.ConversationMetaData.userContactFromOnboarding;
        const threeDaysInMilliseconds = 3 * 24 * 60 * 60 * 1000;
        const onboardingWasRecent = moment().diff(suggestion.createdAt) < threeDaysInMilliseconds;
        return isFromOnboarding && onboardingWasRecent;
    };

    const scoreOutreachSuggestion = (suggestion: OutreachSuggestionDto) => {
        const timeToAnswerScore = getTimeToAnswerScore(suggestion);
        const timeSinceAddedScore = getTimeSinceAddedScore(suggestion);
        const isFollowUpScore = getIsFollowUpScore(suggestion);
        const contactFromOnboarding = getIsContactFromOnboarding(suggestion);
        const recentMessagesScore = getRecentMessagesScore(suggestion);

        const badge = getBadge({
            suggestion,
            isHotConversation: timeToAnswerScore > 0,
            isRecentlyAdded: timeSinceAddedScore > 0 || suggestion.ConversationMetaData.numberOfMessages === 0,
            isReengagement: recentMessagesScore === 0 && suggestion.ConversationMetaData.numberOfMessages > 0,
            isFromOnboarding: contactFromOnboarding,
        });

        return {
            ...suggestion,
            score: timeToAnswerScore + timeSinceAddedScore + isFollowUpScore + recentMessagesScore,
            contactAddedAt: suggestion?.ConversationMetaData?.userContactAddedAt,
            timeToAnswerScore,
            timeSinceAddedScore,
            isFollowUpScore,
            recentMessagesScore,
            badge,
        };
    };

    const scoredOutreachSuggestionsWithMetaData = outreachSuggestionsWithMetaData
        .map(scoreOutreachSuggestion)
        // Sort by score in descending order
        .sort((a, b) => {
            if (a.score === b.score) {
                return a.createdAt > b.createdAt ? -1 : 1;
            }
            return b.score - a.score;
        }) as ScoredOutreachSuggestionDto[];
    const scoredOutreachSuggestions = scoredOutreachSuggestionsWithMetaData.concat(
        outreachSuggestionsWithoutMetaData as ScoredOutreachSuggestionDto[]
    );

    return scoredOutreachSuggestions;
};
