import { styled } from '@mui/styles';

const PricePerMonthStyled = styled('div')(({ theme: { palette } }: any) => ({
    '& > span': {
        color: palette.colors.black,

        '&:first-child': {
            fontSize: '28px',
            fontWeight: 500,
        },
    },
}));

const PricePerUserPerMonth = ({
    estimatedPricePerMonth,
    dataCy,
}: {
    estimatedPricePerMonth: string;
    dataCy?: string;
}) => {
    return (
        <PricePerMonthStyled>
            <span data-cy={dataCy}>{estimatedPricePerMonth}</span>
            <span>/user/month</span>
        </PricePerMonthStyled>
    );
};

export default PricePerUserPerMonth;
