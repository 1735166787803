import { useQuery } from '@apollo/client';
import { GET_PERFORMANCE_METRICS } from '@zaplify/graphql';
import { format, startOfWeek } from 'date-fns';
import { useMemo } from 'react';

interface PerformanceMetricsProps {
    startDate: Date;
    endDate: Date;
    frequency: 'daily' | 'weekly';
}

export const usePerformanceMetrics = ({ startDate, endDate, frequency }: PerformanceMetricsProps) => {
    const { data, loading, error } = useQuery(GET_PERFORMANCE_METRICS, {
        variables: {
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
            organizationId: 'TRAILING_METRICS',
        },
        context: {
            headers: {
                'x-hasura-role': 'GLOBAL_ADMIN',
            },
        },
    });

    const sortedMetrics = useMemo(() => {
        if (!data?.OutreachCompletionMetrics) return [];

        return [...data.OutreachCompletionMetrics].sort(
            (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
        );
    }, [data, frequency]);

    const trailingOmtmData = useMemo(() => {
        if (!data?.OutreachCompletionMetrics) return [];

        const calculateScore = (completed: number, goalsReached: number, suggestions: number) => {
            return suggestions ? Number(((completed * goalsReached) / suggestions).toFixed(2)) : 0;
        };

        let previousMetric = null;
        let previousTotalScore = null;

        return sortedMetrics.map((metric: any) => {
            const totalScore = calculateScore(
                metric.trailingTotalCompleted,
                metric.trailingTotalGoalsReached,
                metric.trailingTotalSuggestions
            );
            let completedChange = 0;
            let goalsReachedChange = 0;
            let suggestionsChange = 0;
            if (previousMetric) {
                completedChange = metric.trailingTotalCompleted - previousMetric.trailingTotalCompleted;
                goalsReachedChange = metric.trailingTotalGoalsReached - previousMetric.trailingTotalGoalsReached;
                suggestionsChange = metric.trailingTotalSuggestions - previousMetric.trailingTotalSuggestions;
            }
            previousMetric = metric;

            return {
                date: format(new Date(metric.date), 'MMM dd, yyyy'),
                trailingTotal: totalScore,
                trailingTotalCompleted: metric.trailingTotalCompleted,
                trailingTotalGoalsReached: metric.trailingTotalGoalsReached,
                trailingTotalSuggestions: metric.trailingTotalSuggestions,
                trailingTotalCompletedChange: completedChange,
                trailingTotalGoalsReachedChange: goalsReachedChange,
                trailingTotalSuggestionsChange: suggestionsChange,
                raw: {
                    ...metric,
                    scores: {
                        trailingTotal: totalScore,
                    },
                },
            };
        });
    }, [data, frequency]);

    const omtmData = useMemo(() => {
        if (!data?.OutreachCompletionMetrics) return [];

        const calculateScore = (completed: number, goalsReached: number, suggestions: number) => {
            return suggestions ? Number(((completed * goalsReached) / suggestions).toFixed(2)) : 0;
        };

        if (frequency === 'daily') {
            return sortedMetrics.map((metric: any) => {
                const sameDayScore = calculateScore(
                    metric.completedSameDay,
                    metric.goalsReachedSameDay,
                    metric.suggestionsSameDay
                );
                const within2DaysScore = calculateScore(
                    metric.completedWithin2Days,
                    metric.goalsReachedWithin2Days,
                    metric.suggestionsSameDay
                );
                const within3DaysScore = calculateScore(
                    metric.completedWithin3Days,
                    metric.goalsReachedWithin3Days,
                    metric.suggestionsSameDay
                );
                const within7DaysScore = calculateScore(
                    metric.completedWithin7Days,
                    metric.goalsReachedWithin7Days,
                    metric.suggestionsSameDay
                );
                const within30DaysScore = calculateScore(
                    metric.completedWithin30Days,
                    metric.goalsReachedWithin30Days,
                    metric.suggestionsSameDay
                );

                return {
                    date: format(new Date(metric.date), 'MMM dd, yyyy'),
                    sameDay: sameDayScore,
                    within2Days: within2DaysScore,
                    within3Days: within3DaysScore,
                    within7Days: within7DaysScore,
                    within30Days: within30DaysScore,
                    raw: {
                        ...metric,
                        scores: {
                            sameDay: sameDayScore,
                            within2Days: within2DaysScore,
                            within3Days: within3DaysScore,
                            within7Days: within7DaysScore,
                            within30Days: within30DaysScore,
                        },
                    },
                };
            });
        } else {
            // Weekly aggregation
            const weeklyData = new Map();

            sortedMetrics.forEach((metric: any) => {
                const date = new Date(metric.date);
                const weekStart = startOfWeek(date, { weekStartsOn: 1 }); // Start week on Monday
                const weekKey = format(weekStart, 'yyyy-MM-dd');

                if (!weeklyData.has(weekKey)) {
                    weeklyData.set(weekKey, {
                        completedSameDay: 0,
                        goalsReachedSameDay: 0,
                        suggestionsSameDay: 0,
                        completedWithin2Days: 0,
                        goalsReachedWithin2Days: 0,
                        suggestionsWithin2Days: 0,
                        completedWithin3Days: 0,
                        goalsReachedWithin3Days: 0,
                        suggestionsWithin3Days: 0,
                        completedWithin7Days: 0,
                        goalsReachedWithin7Days: 0,
                        suggestionsWithin7Days: 0,
                        completedWithin30Days: 0,
                        goalsReachedWithin30Days: 0,
                        suggestionsWithin30Days: 0,
                        count: 0,
                    });
                }

                const weekData = weeklyData.get(weekKey);
                weekData.completedSameDay += metric.completedSameDay;
                weekData.goalsReachedSameDay += metric.goalsReachedSameDay;
                weekData.suggestionsSameDay += metric.suggestionsSameDay;
                weekData.completedWithin2Days += metric.completedWithin2Days;
                weekData.goalsReachedWithin2Days += metric.goalsReachedWithin2Days;
                weekData.suggestionsWithin2Days += metric.suggestionsWithin2Days;
                weekData.completedWithin3Days += metric.completedWithin3Days;
                weekData.goalsReachedWithin3Days += metric.goalsReachedWithin3Days;
                weekData.suggestionsWithin3Days += metric.suggestionsWithin3Days;
                weekData.completedWithin7Days += metric.completedWithin7Days;
                weekData.goalsReachedWithin7Days += metric.goalsReachedWithin7Days;
                weekData.suggestionsWithin7Days += metric.suggestionsWithin7Days;
                weekData.completedWithin30Days += metric.completedWithin30Days;
                weekData.goalsReachedWithin30Days += metric.goalsReachedWithin30Days;
                weekData.suggestionsWithin30Days += metric.suggestionsWithin30Days;
                weekData.count += 1;
            });

            return Array.from(weeklyData.entries()).map(([weekKey, data]) => {
                const sameDayScore = calculateScore(
                    data.completedSameDay,
                    data.goalsReachedSameDay,
                    data.suggestionsSameDay
                );
                const within2DaysScore = calculateScore(
                    data.completedWithin2Days,
                    data.goalsReachedWithin2Days,
                    data.suggestionsSameDay
                );
                const within3DaysScore = calculateScore(
                    data.completedWithin3Days,
                    data.goalsReachedWithin3Days,
                    data.suggestionsSameDay
                );
                const within7DaysScore = calculateScore(
                    data.completedWithin7Days,
                    data.goalsReachedWithin7Days,
                    data.suggestionsSameDay
                );
                const within30DaysScore = calculateScore(
                    data.completedWithin30Days,
                    data.goalsReachedWithin30Days,
                    data.suggestionsSameDay
                );

                return {
                    date: format(new Date(weekKey), 'MMM dd, yyyy'),
                    sameDay: sameDayScore,
                    within2Days: within2DaysScore,
                    within3Days: within3DaysScore,
                    within7Days: within7DaysScore,
                    within30Days: within30DaysScore,
                    raw: {
                        ...data,
                        scores: {
                            sameDay: sameDayScore,
                            within2Days: within2DaysScore,
                            within3Days: within3DaysScore,
                            within7Days: within7DaysScore,
                            within30Days: within30DaysScore,
                        },
                    },
                };
            });
        }
    }, [data, frequency]);

    const suggestionsData = useMemo(() => {
        if (!data?.OutreachCompletionMetrics) return [];

        if (frequency === 'daily') {
            return data.OutreachCompletionMetrics.map((metric: any) => ({
                date: format(new Date(metric.date), 'MMM dd, yyyy'),
                sameDay: metric.suggestionsSameDay,
                within2Days: metric.suggestionsSameDay,
                within3Days: metric.suggestionsSameDay,
                within7Days: metric.suggestionsSameDay,
                within30Days: metric.suggestionsSameDay,
            }));
        } else {
            // Weekly aggregation
            const weeklyData = new Map();

            sortedMetrics.forEach((metric: any) => {
                const date = new Date(metric.date);
                const weekStart = startOfWeek(date, { weekStartsOn: 1 }); // Start week on Monday
                const weekKey = format(weekStart, 'yyyy-MM-dd');

                if (!weeklyData.has(weekKey)) {
                    weeklyData.set(weekKey, {
                        suggestionsSameDay: 0,
                    });
                }

                const weekData = weeklyData.get(weekKey);
                weekData.suggestionsSameDay += metric.suggestionsSameDay;
            });

            return Array.from(weeklyData.entries()).map(([weekKey, data]) => ({
                date: format(new Date(weekKey), 'MMM dd, yyyy'),
                sameDay: data.suggestionsSameDay,
                within2Days: data.suggestionsSameDay,
                within3Days: data.suggestionsSameDay,
                within7Days: data.suggestionsSameDay,
                within30Days: data.suggestionsSameDay,
            }));
        }
    }, [data, frequency]);

    const completionData = useMemo(() => {
        if (!data?.OutreachCompletionMetrics) return [];

        if (frequency === 'daily') {
            return data.OutreachCompletionMetrics.map((metric: any) => ({
                date: format(new Date(metric.date), 'MMM dd, yyyy'),
                sameDay: metric.completedSameDay,
                within2Days: metric.completedWithin2Days,
                within3Days: metric.completedWithin3Days,
                within7Days: metric.completedWithin7Days,
                within30Days: metric.completedWithin30Days,
            }));
        } else {
            // Weekly aggregation
            const weeklyData = new Map();

            sortedMetrics.forEach((metric: any) => {
                const date = new Date(metric.date);
                const weekStart = startOfWeek(date, { weekStartsOn: 1 }); // Start week on Monday
                const weekKey = format(weekStart, 'yyyy-MM-dd');

                if (!weeklyData.has(weekKey)) {
                    weeklyData.set(weekKey, {
                        completedSameDay: 0,
                        completedWithin2Days: 0,
                        completedWithin3Days: 0,
                        completedWithin7Days: 0,
                        completedWithin30Days: 0,
                    });
                }

                const weekData = weeklyData.get(weekKey);
                weekData.completedSameDay += metric.completedSameDay;
                weekData.completedWithin2Days += metric.completedWithin2Days;
                weekData.completedWithin3Days += metric.completedWithin3Days;
                weekData.completedWithin7Days += metric.completedWithin7Days;
                weekData.completedWithin30Days += metric.completedWithin30Days;
            });

            return Array.from(weeklyData.entries()).map(([weekKey, data]) => ({
                date: format(new Date(weekKey), 'MMM dd, yyyy'),
                sameDay: data.completedSameDay,
                within2Days: data.completedWithin2Days,
                within3Days: data.completedWithin3Days,
                within7Days: data.completedWithin7Days,
                within30Days: data.completedWithin30Days,
            }));
        }
    }, [data, frequency]);

    const goalsReachedData = useMemo(() => {
        if (!data?.OutreachCompletionMetrics) return [];

        if (frequency === 'daily') {
            return data.OutreachCompletionMetrics.map((metric: any) => ({
                date: format(new Date(metric.date), 'MMM dd, yyyy'),
                sameDay: metric.goalsReachedSameDay,
                within2Days: metric.goalsReachedWithin2Days,
                within3Days: metric.goalsReachedWithin3Days,
                within7Days: metric.goalsReachedWithin7Days,
                within30Days: metric.goalsReachedWithin30Days,
            }));
        } else {
            // Weekly aggregation
            const weeklyData = new Map();

            sortedMetrics.forEach((metric: any) => {
                const date = new Date(metric.date);
                const weekStart = startOfWeek(date, { weekStartsOn: 1 }); // Start week on Monday
                const weekKey = format(weekStart, 'yyyy-MM-dd');

                if (!weeklyData.has(weekKey)) {
                    weeklyData.set(weekKey, {
                        goalsReachedSameDay: 0,
                    });
                }

                const weekData = weeklyData.get(weekKey);
                weekData.goalsReachedSameDay += metric.goalsReachedSameDay;
            });

            return Array.from(weeklyData.entries()).map(([weekKey, data]) => ({
                date: format(new Date(weekKey), 'MMM dd, yyyy'),
                sameDay: data.goalsReachedSameDay,
            }));
        }
    }, [data, frequency]);

    const metrics = useMemo(() => {
        if (!omtmData.length) return null;

        const latest = omtmData[omtmData.length - 1];
        const earliest = omtmData[0];
        const percentageChange = Number(
            ((latest.within30Days - earliest.within30Days) / earliest.within30Days) * 100
        ).toFixed(2);

        return {
            latest: latest.within30Days.toFixed(1),
            earliest: earliest.within30Days.toFixed(1),
            percentageChange,
        };
    }, [omtmData, frequency]);

    return {
        omtmData,
        trailingOmtmData,
        suggestionsData,
        completionData,
        goalsReachedData,
        numberOfUsers: data?.numberOfUsers?.aggregate?.count,
        numberOfUsersBeforePeriod: data?.numberOfUsersBeforePeriod?.aggregate?.count,
        metrics,
        loading,
        error,
    };
};
