import { Button } from '@shadcn/ui/components/ui/button';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@shadcn/ui/components/ui/tooltip';
import { FC } from 'react';
import { useImportFromUrlDialog } from '../../../../components/dialogs/import-from-url-modal';
import { LinkIcon } from 'lucide-react';

export const ImportPageHeader: FC = () => {
    const { openDialog } = useImportFromUrlDialog();

    return (
        <header className="flex justify-start md:justify-between items-center p-1 border-b md:border-none">
            <div className="flex flex-col max-w-[70%] md:max-w-full items-start justify-between">
                <h1 className="font-medium text-2xl">LinkedIn</h1>
                <h3 className="text-sm text-text-tertiary">Add your most important LinkedIn connections to Andsend</h3>
            </div>
            <TooltipProvider>
                <Tooltip>
                    <TooltipTrigger asChild>
                        <Button className="absolute right-5" onClick={() => openDialog()}>
                            <span className="hidden md:block">Add from url</span>
                            <LinkIcon className="w-4 h-4 md:hidden" />
                        </Button>
                    </TooltipTrigger>
                    <TooltipContent>Add a person from a LinkedIn URL</TooltipContent>
                </Tooltip>
            </TooltipProvider>
        </header>
    );
};
