import { FC, useState, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from '@shadcn/ui/components/ui/card';
import { Tabs, TabsContent } from '@shadcn/ui/components/ui/tabs';
import { Button } from '@shadcn/ui/components/ui/button';
import { ChevronRight, Upload, Table, List, Check, AlertCircle, ArrowLeft } from 'lucide-react';
import { toast } from '@shadcn/ui/hooks/use-toast';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@shadcn/ui/components/ui/tooltip';
import { FieldMappingStep, FieldMappingStepRef, SYSTEM_FIELDS } from './components/field-mapping-step';
import UploadCSVStep from './components/upload-csv-step';
import { ReviewStep } from './components/review-step';
import { useSdk } from '../../../sdk/use-sdk';
import { useMutation } from '@tanstack/react-query';
import { CreateProspectDataDto } from '@zaplify/prospects';
import { ContactSource } from '@zaplify/services/user-contacts/shared';
import { getRequiredFields, getFieldMetadata } from './validation';
import { BulkAddContactsButton } from '../../../components/bulk-add-contact-button';
const STEPS = {
    UPLOAD: 0,
    MAPPING: 1,
    REVIEW: 2,
};

export const ImportListPage: FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [file, setFile] = useState<File | null>(null);
    const [csvData, setCsvData] = useState<any[]>([]);
    const [headers, setHeaders] = useState<string[]>([]);
    const [fieldMapping, setFieldMapping] = useState<Record<string, string>>({});
    const [mappedProspects, setMappedProspects] = useState<CreateProspectDataDto[]>([]);
    const [isProcessing, setIsProcessing] = useState(false);
    const [shouldReset, setShouldReset] = useState(false);
    const [validationWarnings, setValidationWarnings] = useState<{ fieldId: string; message: string }[]>([]);
    const [invalidProspectsCount, setInvalidProspectsCount] = useState(0);

    // Reference to the field mapping component to access its handleApplyMapping function
    const fieldMappingRef = useRef<FieldMappingStepRef>(null);

    const stepParam = parseInt(searchParams.get('step') || '1', 10);
    const currentStep = Math.max(0, stepParam - 1);

    // Check if required fields are properly mapped (not mapped to __NOT_MAPPED__)
    const missingRequiredFields = (): string[] => {
        // Get required fields from the validation schema
        const requiredFields = getRequiredFields();

        // All required fields must be mapped to proceed
        return requiredFields.filter((field) => !fieldMapping[field] || fieldMapping[field] === '__NOT_MAPPED__');
    };
    // Check if required fields are properly mapped (not mapped to __NOT_MAPPED__)
    const requiredFieldsMapped = () => {
        const missingFields = missingRequiredFields();
        return missingFields.length === 0;
    };

    // Get missing required fields to show to user
    const getMissingRequiredFields = () => {
        const requiredFields = getRequiredFields();
        return requiredFields.filter((field) => !fieldMapping[field] || fieldMapping[field] === '__NOT_MAPPED__');
    };

    const canProceed = {
        [STEPS.UPLOAD]: !!file,
        [STEPS.MAPPING]: requiredFieldsMapped(),
        [STEPS.REVIEW]: mappedProspects.length > 0 && invalidProspectsCount === 0,
    };

    // SDK for importing contacts
    const {
        prospect: { runBulkImport },
    } = useSdk();
    const { mutateAsync: importContactsMutation } = useMutation(runBulkImport());

    useEffect(() => {
        if (!searchParams.has('step')) {
            const newSearchParams = new URLSearchParams(searchParams);
            newSearchParams.set('step', '1');
            setSearchParams(newSearchParams, { replace: true });
            return;
        }

        if (currentStep > STEPS.UPLOAD && (!file || csvData.length === 0 || headers.length === 0)) {
            const newSearchParams = new URLSearchParams();
            newSearchParams.set('step', '1');
            setSearchParams(newSearchParams, { replace: true });
            toast({
                title: 'No CSV data',
                description: 'Please upload a CSV file first',
                variant: 'destructive',
            });
            return;
        }

        if (currentStep === STEPS.REVIEW && Object.keys(fieldMapping).length === 0) {
            const newSearchParams = new URLSearchParams();
            newSearchParams.set('step', '2');
            setSearchParams(newSearchParams, { replace: true });
            toast({
                title: 'No field mapping',
                description: 'Please map your fields first',
                variant: 'destructive',
            });
            return;
        }
    }, [currentStep, file, csvData, headers, fieldMapping, searchParams, setSearchParams]);

    const handleStepChange = (step: number) => {
        if (step > currentStep) {
            // Validate current step before proceeding
            if (currentStep === STEPS.UPLOAD && !file) {
                toast({
                    title: 'CSV file required',
                    description: 'Please upload a CSV file before proceeding',
                    variant: 'destructive',
                });
                return;
            }

            if (currentStep === STEPS.MAPPING) {
                // Apply field mapping before proceeding to review step
                if (fieldMappingRef.current) {
                    const result = fieldMappingRef.current.handleApplyMapping();
                    if (!result.success) {
                        // Don't proceed if mapping failed
                        return;
                    }
                    console.log('result', result);

                    // Store validation state but don't block progression
                    if (result.invalidCount > 0) {
                        setInvalidProspectsCount(result.invalidCount);
                        setValidationWarnings(result.validationErrors || []);
                    } else {
                        // Reset validation state if no issues
                        setInvalidProspectsCount(0);
                        setValidationWarnings([]);
                    }
                } else if (!requiredFieldsMapped()) {
                    const missingFields = getMissingRequiredFields();
                    // Use metadata to get user-friendly labels
                    const fieldMetadata = getFieldMetadata();
                    const fieldLabels = missingFields
                        .map((fieldId) => fieldMetadata[fieldId]?.label || fieldId)
                        .join(', ');

                    toast({
                        title: 'Required fields not mapped',
                        description: `Please map the following required fields: ${fieldLabels}`,
                        variant: 'destructive',
                    });
                    return;
                }
            }
        }
        // No special handling needed when going back - existing mappings will be preserved

        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set('step', (step + 1).toString());
        setSearchParams(newSearchParams, { replace: true });
    };

    const handleFileUpload = (uploadedFile: File, parsedData: any[], parsedHeaders: string[]) => {
        setShouldReset(false);
        setFile(uploadedFile);
        setCsvData(parsedData);
        setHeaders(parsedHeaders);
    };

    const handleFieldMappingComplete = (mapping: Record<string, string>, prospects: any[]) => {
        setFieldMapping(mapping);
        setMappedProspects(prospects);
    };

    const handleImportComplete = () => {
        toast({
            title: 'Import successful',
            description: `Successfully imported ${mappedProspects.length} contacts`,
            variant: 'success',
        });
        // Reset the form and go back to first step
        setFile(null);
        setCsvData([]);
        setHeaders([]);
        setFieldMapping({});
        setMappedProspects([]);

        const newSearchParams = new URLSearchParams();
        newSearchParams.set('step', '1');
        setSearchParams(newSearchParams, { replace: true });
    };

    const handleReset = () => {
        // Reset all state
        setFile(null);
        setCsvData([]);
        setHeaders([]);
        setFieldMapping({});
        setMappedProspects([]);
        setIsProcessing(false);
        setShouldReset(true);

        // Go back to step 1
        const newSearchParams = new URLSearchParams();
        newSearchParams.set('step', '1');
        setSearchParams(newSearchParams, { replace: true });
    };

    console.log('Before rendering FieldMappingStep', { csvData, headers });

    return (
        <div className="flex flex-col min-h-screen">
            <header className="flex justify-start md:justify-between items-center p-1 border-b md:border-none">
                <div className="flex flex-col max-w-[70%] md:max-w-full items-start justify-between">
                    <h1 className="font-medium text-2xl">List Import</h1>
                    <h3 className="text-sm text-text-tertiary">
                        Import contacts from a CSV or Excel file to add them to Andsend
                    </h3>
                </div>
            </header>
            <div className="container mx-auto py-8 px-4 flex-grow flex flex-col overflow-hidden">
                <div className="flex items-start gap-8 flex-col md:flex-row flex-grow overflow-hidden">
                    <StepNavigation currentStep={currentStep} handleStepChange={handleStepChange} />

                    <div className="flex-1 w-full flex flex-col overflow-auto">
                        <Card className="w-full mb-8 flex-grow overflow-hidden">
                            <Tabs value={`step-${currentStep + 1}`} className="w-full h-full flex flex-col">
                                <TabsContent value="step-1" className="m-0">
                                    <CardHeader>
                                        <CardTitle className="text-2xl">Upload Contacts</CardTitle>
                                        <CardDescription>
                                            <p>Upload a CSV or Excel file containing your contacts.</p>
                                        </CardDescription>
                                    </CardHeader>
                                    <CardContent>
                                        <h2 className="text-sm font-medium mb-2">Requirements</h2>
                                        <ol className="list-decimal pl-5 space-y-2 mb-4 text-sm text-muted-foreground">
                                            <li>
                                                Required information: First Name, Last Name, Company Name, and either an
                                                Email address or LinkedIn URL
                                            </li>
                                            <li>Optional information: Job Title, Phone Number, Industry, Website</li>
                                            <li>Maximum 500 contacts per import</li>
                                        </ol>
                                        <UploadCSVStep onFileUploaded={handleFileUpload} reset={shouldReset} />
                                    </CardContent>
                                </TabsContent>

                                <TabsContent value="step-2" className="m-0">
                                    <CardHeader>
                                        <CardTitle className="text-2xl">Map Fields</CardTitle>
                                        <CardDescription>
                                            Match the fields in your CSV to our system fields
                                        </CardDescription>
                                    </CardHeader>
                                    <CardContent className="pb-20 overflow-auto max-h-[calc(100vh-300px)]">
                                        <FieldMappingStep
                                            ref={fieldMappingRef}
                                            csvData={csvData}
                                            headers={headers}
                                            onFieldMappingComplete={handleFieldMappingComplete}
                                            onMappingChange={setFieldMapping}
                                            initialMapping={fieldMapping}
                                        />
                                    </CardContent>
                                </TabsContent>

                                <TabsContent value="step-3" className="m-0 h-full">
                                    <CardHeader>
                                        <CardTitle className="text-2xl">Review</CardTitle>
                                        <CardDescription>
                                            Review and confirm your contacts before importing
                                        </CardDescription>
                                    </CardHeader>
                                    <CardContent className="overflow-auto max-h-[calc(100vh-300px)]">
                                        <ReviewStep
                                            mappedProspects={mappedProspects}
                                            isProcessing={isProcessing}
                                            setIsProcessing={setIsProcessing}
                                            onImportComplete={handleImportComplete}
                                            onProspectsUpdate={setMappedProspects}
                                            onValidationUpdate={(invalidCount, warnings) => {
                                                setInvalidProspectsCount(invalidCount);
                                                setValidationWarnings(warnings);
                                            }}
                                        />
                                    </CardContent>
                                </TabsContent>
                            </Tabs>
                        </Card>
                    </div>
                </div>
            </div>
            <div className="sticky bottom-0 left-0 right-0 w-full border-t bg-white shadow-md z-10 mt-auto">
                <div className="flex justify-between items-center h-16 px-4">
                    <div className="flex items-center">
                        {(currentStep !== STEPS.UPLOAD || file) && (
                            <Button
                                type="button"
                                variant="outline"
                                onClick={handleReset}
                                className="gap-2 text-muted-foreground font-semibold rounded-lg"
                            >
                                Exit
                            </Button>
                        )}
                    </div>
                    <div className="flex items-center gap-2">
                        {currentStep > STEPS.UPLOAD && (
                            <Button
                                type="button"
                                variant="outline"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleStepChange(currentStep - 1);
                                }}
                                size="sm"
                            >
                                Previous
                            </Button>
                        )}
                        {currentStep < STEPS.REVIEW ? (
                            <TooltipProvider>
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <div>
                                            <Button
                                                type="button"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    handleStepChange(currentStep + 1);
                                                }}
                                                size="sm"
                                                className="gap-1"
                                                disabled={!canProceed[currentStep]}
                                            >
                                                Next step
                                                <ChevronRight className="h-4 w-4" />
                                            </Button>
                                        </div>
                                    </TooltipTrigger>
                                    {!canProceed[currentStep] && (
                                        <TooltipContent>
                                            {currentStep === STEPS.UPLOAD && !file && 'Please upload a CSV file'}
                                            {currentStep === STEPS.MAPPING &&
                                                !requiredFieldsMapped() &&
                                                `All required fields must be mapped. Missing: ${missingRequiredFields()
                                                    .map((field) => getFieldMetadata()[field].label)
                                                    .join(', ')}`}
                                        </TooltipContent>
                                    )}
                                </Tooltip>
                            </TooltipProvider>
                        ) : (
                            <TooltipProvider>
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <div>
                                            <BulkAddContactsButton
                                                contacts={mappedProspects.map((p) => ({
                                                    createProspect: p,
                                                }))}
                                                source={ContactSource.FileImport}
                                                onAdded={handleImportComplete}
                                                disabled={
                                                    isProcessing ||
                                                    mappedProspects.length === 0 ||
                                                    invalidProspectsCount > 0
                                                }
                                                size="sm"
                                            />
                                        </div>
                                    </TooltipTrigger>
                                    {(mappedProspects.length === 0 || invalidProspectsCount > 0) && !isProcessing && (
                                        <TooltipContent>
                                            {mappedProspects.length === 0 && 'No contacts to import'}
                                            {invalidProspectsCount > 0 && (
                                                <>
                                                    {invalidProspectsCount} contacts have validation issues
                                                    <AlertCircle className="h-4 w-4 ml-1 inline" />
                                                </>
                                            )}
                                        </TooltipContent>
                                    )}
                                </Tooltip>
                            </TooltipProvider>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

const StepIndicator: FC<{
    title: string;
    active: boolean;
    completed: boolean;
    icon: React.ReactNode;
    onClick: () => void;
}> = ({ title, active, completed, icon, onClick }) => {
    return (
        <div
            className={`flex items-center gap-3 p-3 rounded-lg transition-colors cursor-pointer ${
                active
                    ? 'bg-background-brand-primary-subtle text-text-brand-secondary'
                    : completed
                    ? 'text-primary'
                    : 'text-muted-foreground'
            }`}
            onClick={onClick}
        >
            <div
                className={`flex items-center justify-center w-8 h-8 rounded-full shrink-0 ${
                    active
                        ? 'bg-primary text-primary-foreground'
                        : completed
                        ? 'bg-primary/20 text-primary'
                        : 'bg-muted text-muted-foreground'
                }`}
            >
                {icon}
            </div>
            <span className="font-medium">{title}</span>
            {completed && (
                <Check className="h-6 w-6 bg-background-tertiary rounded-full p-1 ml-auto animate-in fade-in zoom-in duration-300" />
            )}
        </div>
    );
};

const StepNavigation: FC<{
    currentStep: number;
    handleStepChange: (step: number) => void;
}> = ({ currentStep, handleStepChange }) => {
    return (
        <div className="w-full md:w-64 shrink-0 hidden md:block">
            <div className="sticky top-4 space-y-2">
                <StepIndicator
                    title="Upload Contacts"
                    active={currentStep === STEPS.UPLOAD}
                    completed={currentStep > STEPS.UPLOAD}
                    icon={<Upload className="h-5 w-5" />}
                    onClick={() => handleStepChange(STEPS.UPLOAD)}
                />
                <StepIndicator
                    title="Map Fields"
                    active={currentStep === STEPS.MAPPING}
                    completed={currentStep > STEPS.MAPPING}
                    icon={<Table className="h-5 w-5" />}
                    onClick={() => handleStepChange(STEPS.MAPPING)}
                />
                <StepIndicator
                    title="Review"
                    active={currentStep === STEPS.REVIEW}
                    completed={currentStep > STEPS.REVIEW}
                    icon={<List className="h-5 w-5" />}
                    onClick={() => handleStepChange(STEPS.REVIEW)}
                />
            </div>
        </div>
    );
};

const StickyFormStepper: FC<{
    currentStep: number;
    handleStepChange: (step: number) => void;
    isProcessing: boolean;
    onImport: () => void;
    totalContacts: number;
    canProceed: { [key: number]: boolean };
}> = ({ currentStep, handleStepChange, isProcessing, onImport, totalContacts, canProceed }) => {
    return (
        <div className="sticky bottom-0 left-0 right-0 w-full border-t bg-white shadow-md z-10">
            <div className="container max-w-full mx-auto flex justify-between items-center h-16 px-4">
                <div className="flex items-center">
                    <Button
                        type="button"
                        variant="outline"
                        onClick={() => {
                            window.history.back();
                        }}
                        className="gap-2 text-muted-foreground font-semibold rounded-lg"
                    >
                        Exit
                    </Button>
                </div>
                <div className="flex items-center gap-2">
                    {currentStep > STEPS.UPLOAD && (
                        <Button
                            type="button"
                            variant="outline"
                            onClick={(e) => {
                                e.preventDefault();
                                handleStepChange(currentStep - 1);
                            }}
                            size="sm"
                        >
                            Previous
                        </Button>
                    )}
                    {currentStep < STEPS.REVIEW ? (
                        <Button
                            type="button"
                            onClick={(e) => {
                                e.preventDefault();
                                handleStepChange(currentStep + 1);
                            }}
                            size="sm"
                            className="gap-1"
                            disabled={!canProceed[currentStep]}
                        >
                            Next step
                            <ChevronRight className="h-4 w-4" />
                        </Button>
                    ) : (
                        <Button
                            type="button"
                            onClick={onImport}
                            size="sm"
                            className="gap-1"
                            disabled={isProcessing || totalContacts === 0}
                        >
                            {isProcessing ? 'Importing...' : `Import ${totalContacts} contacts`}
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ImportListPage;
