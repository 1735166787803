import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux-latest';
import { useNavigate } from 'react-router-dom';
import {
    getSalesforceDefaultMapping,
    getSalesforceFields,
    getSalesforceSettings,
} from '../../../../redux/ducks/salesforce/operations';
import { AppDispatch, useAppSelector } from '../../../../redux/store/configureStore';
import browserHistory from '../../../../routes/history';
import { Configuration } from './components/configuration';
import { FieldMapContact } from './components/contact/fieldMap';
import { IntegrationNotCompletedModal } from './components/integrationNotCompletModal';
import { FieldMapLead } from './components/lead/fieldMap';
import { TopBar } from './components/topBar';
import { salesforceContactStrategy, salesforceLeadStrategy } from './constants';
import { BodyContainer, SalesforceContainer, ViewContainer } from './containers';

const SalesforceView = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const history = browserHistory;
    const saveStatus = useAppSelector((state) => state.salesforce.saveStatus);
    const integrationStrategy = useAppSelector((state) => state.salesforce.integrationStrategy);
    const selectedStrategy = useAppSelector((state) => state.salesforce.selectedStrategy);
    const [currentPath, setCurrentPath] = useState('');

    const [displayIntegrationNotCompletedModal, setDisplayIntegrationNotCompletedModal] = useState(false);

    const handleLeave = () => {
        history.block(() => {});
        navigate(currentPath);
    };

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            dispatch(getSalesforceSettings(true));
        }
        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        history.block((prompt) => {
            if (!saveStatus) {
                setDisplayIntegrationNotCompletedModal(true);
                setCurrentPath(prompt.pathname);
                return 'true';
            }
        });
        return () => {
            history.block(() => {});
        };
    }, [history, saveStatus]);

    useEffect(() => {
        if (selectedStrategy !== '') {
            dispatch(getSalesforceFields(selectedStrategy));
        }
        if (selectedStrategy !== integrationStrategy) {
            dispatch(getSalesforceDefaultMapping(selectedStrategy));
        } else {
            dispatch(getSalesforceSettings());
        }
    }, [selectedStrategy]);

    const getFieldMapComponent = () => {
        switch (selectedStrategy) {
            case salesforceContactStrategy:
                return <FieldMapContact />;
            case salesforceLeadStrategy:
                return <FieldMapLead />;
            default:
                return null;
        }
    };

    return (
        <ViewContainer>
            <SalesforceContainer>
                <IntegrationNotCompletedModal
                    isOpen={displayIntegrationNotCompletedModal}
                    setIsOpen={setDisplayIntegrationNotCompletedModal}
                    handleLeave={handleLeave}
                />
                <TopBar />
                <BodyContainer>
                    {getFieldMapComponent()}
                    <Configuration />
                </BodyContainer>
            </SalesforceContainer>
        </ViewContainer>
    );
};

export default SalesforceView;
