import { Typography } from '@mui/material';
import { Button } from '@zaplify/ui';
import React, { useState } from 'react';
import { Avatar } from '../../../../components/atoms/avatar';
import { ImportedProspectsOverviewContainer } from './containers';
import { getTotalNumberOfProspects } from './functions';
import { ViewProspectsModal } from './ViewProspectsModal';

const DataBox = (props) => (
    <div>
        <div className="top-container">
            <Avatar size={54} backgroundColor={props.color} color={props.fontColor}>
                <Typography variant="h2" color={'inherit'}>
                    {Array.isArray(props.prospects) && props.prospects.length}
                </Typography>
            </Avatar>
            {props.handleViewClick && (
                <Button
                    width="79px"
                    borderRadius="500px"
                    classes={['view-more-button']}
                    text="View"
                    variant="secondary"
                    onClick={props.handleViewClick}
                />
            )}
        </div>
        <Typography variant="subtitle1">{props.title}</Typography>
        <Typography className="subtitle">{props.subtitle}</Typography>
    </div>
);

export const ImportedProspectsOverview = (props) => {
    const [viewErrorModal, setViewErrorModal] = useState(false);
    const [viewSkippedModal, setViewSkippedModal] = useState(false);
    const totalNumberOfProspects = getTotalNumberOfProspects(
        props.validProspects,
        props.skippedProspects,
        props.errorProspects
    );

    const handleViewErrorClick = () => setViewErrorModal(true);
    const handleErrorModalClose = () => setViewErrorModal(false);

    const handleViewSkippedClick = () => setViewSkippedModal(true);
    const handleSkippedModalClose = () => setViewSkippedModal(false);

    return (
        <ImportedProspectsOverviewContainer>
            <DataBox
                prospects={props.validProspects}
                totalNumberOfProspects={totalNumberOfProspects}
                color="rgba(95, 142, 250, 0.14)"
                fontColor="#5F8EFA"
                title="Contacts found"
                subtitle="These contacts will be added to your campaign."
            />
            <DataBox
                prospects={props.skippedProspects}
                totalNumberOfProspects={totalNumberOfProspects}
                color="rgba(254, 92, 94, 0.14)"
                fontColor="#FE5C5E"
                title="Contacts Skipped"
                subtitle="These contacts already exist in organization."
                handleViewClick={props.skippedProspects?.length > 0 && handleViewSkippedClick}
            />
            <DataBox
                prospects={props.errorProspects}
                totalNumberOfProspects={totalNumberOfProspects}
                color="rgba(135, 35, 54, 0.14)"
                fontColor="#872336"
                title="Error Contacts"
                subtitle="These contacts could not be created due to errors."
                handleViewClick={props.errorProspects?.length > 0 && handleViewErrorClick}
            />
            {viewErrorModal && (
                <ViewProspectsModal errorModal onClose={handleErrorModalClose} prospects={props.errorProspects} />
            )}
            {viewSkippedModal && (
                <ViewProspectsModal onClose={handleSkippedModalClose} prospects={props.skippedProspects} />
            )}
        </ImportedProspectsOverviewContainer>
    );
};
