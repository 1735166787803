import { PaymentStatus } from '../../components/pick-plan-modal/enums/billing';
import { uiActionTypes } from '../actionTypes';

const initialState = {
    displayBlocklistModal: false,
    notification: {
        message: '',
        severity: '',
    },
    movingProspects: false,
    processingPaymentType: 'idle', // idle | paid | free
    paymentStatus: PaymentStatus.NONE,
};

const uiReducer = (state = initialState, action) => {
    switch (action.type) {
        case uiActionTypes.SET_NOTIFICATION:
            return {
                ...state,
                notification: {
                    message: action.message,
                    severity: action.severity,
                },
            };
        case uiActionTypes.BLOCKLIST_MODAL:
            return {
                ...state,
                displayBlocklistModal: action.payload,
            };
        case uiActionTypes.MOVING_PROSPECTS:
            return {
                ...state,
                movingProspects: action.payload,
            };
        case uiActionTypes.PROCESSING_PAYMENT_TYPE:
            return {
                ...state,
                processingPaymentType: action.payload,
            };
        case uiActionTypes.PAYMENT_STATUS:
            return {
                ...state,
                paymentStatus: action.payload,
            };
        default:
            return { ...state };
    }
};

export default uiReducer;
