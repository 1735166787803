import { CampaignStatus, CampaignType } from '@zaplify/campaigns';
import { getZaplifySdk } from '@zaplify/sdk';
import { batch } from 'react-redux-latest';
import { paths } from '../../../routes/paths';
import { findSourceWithProspectSearch } from '../../../services/utils/findSourceWithProspectSearch';
import { getConnectionRequestLimitCheckedWorkflowSteps } from '../../../services/utils/get-linkedIn-free-checked-workflow-steps';
import { getDoubleDigitTime, getUTCTime } from '../../../services/utils/time-helper';
import { redirectToPage } from '../../../services/utils/urlHelper';
import { newCampaignActionTypes } from '../../actionTypes';
import { validateLinkedInAccount, validateOutreach } from '../../ducks/outreach/operations';
import { updateOutreachIsLoadingState, updateSemiAutoAI, updateWholeWorkflow } from '../../ducks/outreach/slice';
import { resetProspectSearch } from '../../ducks/prospect-search-filters';
import { setupSearchFilters } from '../../ducks/prospect-search-filters/operations';
import { draftPreState } from '../../reducers/newCampaign/constants';
import { updateCampaignName, updateIsLoadingData } from '../campaigns';
import { handleErrorNotification, handleSuccessNotification } from '../ui';
import { getDays, getEditStep, getFormattedSteps, getStepsWithCorrectSubject } from './functions/utilityFunctions';

export const updateValue = (key, value) => {
    return {
        type: newCampaignActionTypes.UPDATE_VALUE,
        key: key,
        value: value,
    };
};

export const updateNewCampaignName = (name) => {
    return {
        type: newCampaignActionTypes.UPDATE_CAMPAIGN_NAME,
        name: name,
    };
};

export const redirectWithName = (value) => {
    return {
        type: newCampaignActionTypes.REDIRECT,
        url: value,
    };
};

export const previousStep = () => {
    return {
        type: newCampaignActionTypes.PREVIOUS_STEP,
    };
};

export const nextStep = () => {
    return {
        type: newCampaignActionTypes.NEXT_STEP,
    };
};

export const updateStep = (step) => {
    return {
        type: newCampaignActionTypes.UPDATE_STEP,
        step: step,
    };
};

export const resetNewCampaign = () => {
    return {
        type: newCampaignActionTypes.RESET_CAMPAIGN,
    };
};

export const savedDraft = () => {
    return {
        type: newCampaignActionTypes.SAVED_DRAFT,
    };
};

export const updatePersonFilter = (value) => {
    console.log('### updateSource', value);
    return {
        type: newCampaignActionTypes.UPDATE_PERSON_FILTER,
        personFilterId: value,
    };
};

export const updateCreateCampaignType = (value) => {
    return {
        type: newCampaignActionTypes.CHANGE_CREATE_CAMPAIGN_TYPE,
        value: value,
    };
};

export const updateSavedTime = () => {
    const date = new Date();
    const hour = getDoubleDigitTime(date.getHours());
    const minutes = getDoubleDigitTime(date.getMinutes());
    const timeString = 'Last saved ' + hour + ':' + minutes;

    return {
        type: newCampaignActionTypes.UPDATE_SAVED_TIME,
        payload: timeString,
    };
};

export const redirect =
    (popup = false) =>
    async (dispatch, getState) => {
        const campaignName = getState().newCampaign.name;
        dispatch(redirectWithName(popup ? `?created-campaign=${campaignName}` : '?created-campaign='));
        setTimeout(() => {
            dispatch(redirectWithName(''));
        }, 2000);
    };

export const addWorkflow = () => async (dispatch, getState) => {
    return;
};

export const publishCampaign =
    (shouldRedirect = true, validateLinkedIn = true) =>
    async (dispatch, getState) => {
        const sdk = getZaplifySdk().profiles;

        const passedMessageValidation = await dispatch(validateOutreach());
        const passedLinkedInPremiumValidation = !validateLinkedIn || (await dispatch(validateLinkedInAccount()));
        if (passedMessageValidation && passedLinkedInPremiumValidation) {
            try {
                const newCampaignState = getState().newCampaign;

                await dispatch(addWorkflow());

                await sdk.campaigns.publishDraft(newCampaignState.campaignId);

                window?.analytics?.track('User Created Campaign', {
                    campaignName: newCampaignState.name,
                });

                if (shouldRedirect) dispatch(redirect());

                !shouldRedirect && dispatch(updateValue('status', 'PENDING'));
                return true;
            } catch (error) {
                console.log('errror publishCampaign');
            }
        } else {
            return false;
        }
    };

export const createCampaignDraft =
    (autoSave = false) =>
    async (dispatch, getState) => {
        dispatch(updateValue('saving', true));
        dispatch(addWorkflow());

        if (window?.analytics) {
            window?.analytics?.track('User Created Campaign Draft', {
                campaignName: getState().newCampaign.name,
            });
        }
        if (!autoSave) dispatch(notificationSetup({ success: 'Workflow changes saved' }));
        dispatch(updateSavedTime());
    };

export const createOrUpdateName =
    (id = null, dashboard = false) =>
    async (dispatch, getState) => {
        const newCampaignState = getState().newCampaign;
        const outreach = getState().outreach;
        const campaignId = id || newCampaignState.campaignId;
        const sdk = getZaplifySdk().profiles;

        try {
            if (!campaignId) {
                const campaign = {
                    name: newCampaignState.name,
                    sources: [],
                    workflow: null,
                };
                const campaignResponse = await sdk.campaigns.createDraft(campaign);
                window.analytics && window?.analytics?.track('User Created Campaign First Step');
                return campaignResponse.id;
            } else {
                const campaign = {
                    name: newCampaignState.name,
                    workflow: {
                        isManual: outreach.isManual,
                    },
                };
                await sdk.campaigns.updateCampaignById(campaignId, campaign);
                if (dashboard) dispatch(updateCampaignName(campaignId, newCampaignState.name));
            }
        } catch (error) {
            console.log('createOrUpdateName error');
        }
    };

function IsJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export const updateCampaignWorkflow = () => async (dispatch, getState) => {
    return;
};

export const editDraftPrework = (campaign, editStep, currentUrl, navigate) => async (dispatch, getState) => {
    const status = campaign?.status;
    const step = editStep || getEditStep(campaign);
    const draftStatusParameter =
        status === CampaignStatus.draft || status === CampaignStatus.pending ? 'draft=true' : 'draft=false';
    redirectToPage({
        url: `${paths.EDIT_CAMPAIGN}/${campaign.id}?step=${step}&${draftStatusParameter}`,
        currentUrl,
        navigate,
    });
};

export const editDraftSetup = (campaignId: string, editStep) => async (dispatch, getState) => {
    const sdk = getZaplifySdk().profiles;
    dispatch(resetProspectSearch());

    dispatch(updateOutreachIsLoadingState(true));
    const campaign = await sdk.campaigns.getCampaign(campaignId);
    const personFilterId = campaign.targetGroup?.personFilterId;
    console.log('### campaign setup', campaign.targetGroup);

    const personFilter = personFilterId ? await sdk.sources.getPersonFilter(personFilterId) : null;
    dispatch(updatePersonFilter(personFilter?.id || null));
    const workflow = campaign.workflow;

    if (personFilter) {
        dispatch(setupSearchFilters(personFilter));
    }

    //Updates the workflow of the campaign
    if (workflow?.steps?.length > 0) {
        const channelAccounts = getState().user.channelAccounts;
        workflow.steps = getConnectionRequestLimitCheckedWorkflowSteps(workflow.steps, channelAccounts);
        dispatch(updateWholeWorkflow(workflow as any));
        dispatch(updateSemiAutoAI(campaign.type === CampaignType.SEMI_AUTO_AI ? 'on' : 'off'));
    }
    batch(() => {
        //updateValues
        dispatch(updateValue('step', editStep ? editStep : getEditStep(campaign)));
        dispatch(updateValue('name', campaign.name));
        dispatch(updateValue('campaignId', campaignId));
        dispatch(updateValue('prospectsInCampaign', campaign.targetGroup?.prospects?.map((p) => p.id) || []));
        //reset "draftPreState" to initial state
        dispatch(updateValue('draftPreState', draftPreState));
        dispatch(updateValue('status', campaign.status));
        dispatch(updateValue('campaignTemplateName', campaign.workflow.name));
        dispatch(updateIsLoadingData(false));
        //dispatch(updateIsLoadingWorkflow(false));
        dispatch(updateOutreachIsLoadingState(false));
    });
};

export const notificationSetup = (message) => async (dispatch, getState) => {
    if (message.success) {
        dispatch(updateValue('successNotification', message.success));
        setTimeout(() => {
            dispatch(updateValue('successNotification', ''));
        }, 3000);
    } else if (message.error) {
        dispatch(updateValue('errorNotification', message.error));
        setTimeout(() => {
            dispatch(updateValue('errorNotification', ''));
        }, 3000);
    }
};

export const fetchCampaignProspectDetails = (campaignId) => async (dispatch, getState) => {
    //Fetches details about prospects in campaign and prospects in review and updates the new campaign state.

    const sdk = getZaplifySdk().profiles;

    const id = campaignId || getState().newCampaign.campaignId;

    //Updates prospectsInCampaign state
    let campaign = await sdk.campaigns.getCampaign(id);
    dispatch(updateValue('prospectsInCampaign', campaign.targetGroup?.prospects?.map((p) => p.id) || []));
};

export const sendTestEmail = (campaignId, stepNumber) => async (dispatch, getState) => {
    try {
        const profilesSdk = getZaplifySdk().profiles;
        let sendEmail = await profilesSdk.workflow.sendTestEmail(campaignId, stepNumber);
        dispatch(handleSuccessNotification("Check your inbox! Can take a couple of minutes before it's sent", 5));
    } catch (error) {
        const errorResponse = JSON.parse(error['message']);
        if (errorResponse.data.message === 'The email account is not authenticated') {
            dispatch(handleErrorNotification('Impossile to send test email. Please reconnect your email.', 10));
        } else {
            dispatch(handleErrorNotification('Impossile to send test email. Please contact our support team.', 10));
        }
    }
};
