import { Alert as AlertMaterialUI, AlertTitle, Collapse } from '@mui/material';
import { styled } from '@mui/styles';
import { ReactNode, useState } from 'react';

interface AlertProps {
    children?: ReactNode;
    className?: string;
    title?: string;
    [K: string]: any;
}

const AlertComponent = styled(({ children, className, ...props }: AlertProps) => (
    <AlertMaterialUI severity='info' onClose={props.onDismiss} className={className}>
        {props.title && <AlertTitle>{props.title}</AlertTitle>}
        {children}
    </AlertMaterialUI>
))(({ theme }: any) => ({
    boxShadow: `0 2px 40px ${theme.palette.colors.overlay.dark(14)}`,
    borderRadius: '10px',
    textAlign: 'left',
}));

export const Alert = (props: AlertProps) => {
    const [isVisible, setVisible] = useState(true);

    const hideAlert = () => {
        setVisible(false);
    };

    return (
        <Collapse in={isVisible}>
            <AlertComponent title={props.title} onDismiss={hideAlert}>
                {props.children}
            </AlertComponent>
        </Collapse>
    );
};
