import { Box, Button, Typography } from '@mui/material';
import { companyNameCorrectlyCased } from '../../enums/company-name';
import { useState } from 'react';
import { Check, Mail } from 'lucide-react';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const MobileInfo = () => {
    const [isEmailSent, setIsEmailSent] = useState(false);
    const { displaySendEmailReminderOnMobile } = useFlags();

    return (
        <Box
            sx={{
                width: '360px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                gap: '32px',
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '8px' }}>
                <Typography
                    component="h1"
                    sx={{
                        fontSize: '32px',
                        fontWeight: '600',
                        lineHeight: '40px',
                        textAlign: 'center',
                        textWrap: 'balance',
                    }}
                >
                    Please switch to a desktop device to continue.
                </Typography>
                <Typography sx={{ textAlign: 'center', textWrap: 'balance' }}>
                    {`To connect your LinkedIn account to ${companyNameCorrectlyCased}, you need to use our Chrome Extension, which can only be
                    installed on a desktop browser.`}
                </Typography>
                {displaySendEmailReminderOnMobile && (
                    <Button
                        sx={{
                            width: '300px',
                            height: '40px',
                            borderRadius: '8px',
                            backgroundColor: 'hsl(218 80% 60%)',
                            color: '#FFFFFF',
                            marginTop: '16px',
                        }}
                        onClick={() => {
                            if (!isEmailSent) {
                                setIsEmailSent(true);
                                window?.analytics?.track?.('User Clicked Send Email Reminder From Mobile');
                            }
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                            {isEmailSent ? <Check strokeWidth={1} /> : <Mail strokeWidth={1} />}
                            {isEmailSent ? 'Email sent' : 'Send me an email reminder'}
                        </Box>
                    </Button>
                )}
            </Box>
        </Box>
    );
};
