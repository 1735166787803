import { Box, ButtonBase, Typography, TypographyProps } from '@mui/material';
import { paletteV2 } from '@zaplify/ui';
import { AlertCircle } from 'lucide-react';
import React from 'react';
import { TextElement } from './connect-likedIn.container';
import { FeedbackSection } from './feedback-section';

export const LinkedInError = ({
    textElements,
    onLinkedInConnect,
    label: buttonLabel,
    disableNumeration = false,
    hideButton,
}: {
    textElements: TextElement[];
    onLinkedInConnect: () => void;
    label: string;
    disableNumeration?: boolean;
    hideButton?: boolean;
}) => (
    <Box
        sx={{
            minWidth: '520px',
            padding: '24px 32px',
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
        }}
    >
        <FeedbackSection icon={<AlertCircle />} variant="error">
            <ErrorList textElements={textElements} disableNumeration={disableNumeration} />
        </FeedbackSection>
        {!hideButton && <ConnectButton onClick={onLinkedInConnect}>{buttonLabel}</ConnectButton>}
    </Box>
);

interface ErrorListElementProps extends TypographyProps {
    children: React.ReactElement | string;
}

const ErrorList = ({
    textElements,
    disableNumeration,
}: {
    textElements: TextElement[];
    disableNumeration: boolean;
}) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
        }}
    >
        {textElements.map((textElement, index) => (
            <Box key={index}>
                <ErrorListElement sx={{ fontWeight: 500 }}>{textElement.title}</ErrorListElement>
                {textElement.steps.map((step, i) => (
                    <ErrorListElement key={step}>
                        <>
                            {!disableNumeration && `${i + 1}. ${step}`}
                            {disableNumeration && step}
                        </>
                    </ErrorListElement>
                ))}
            </Box>
        ))}
    </Box>
);

const ErrorListElement = ({ children, ...props }: ErrorListElementProps) => {
    return <Typography sx={{ fontSize: '14px', color: paletteV2.subline, ...props.sx }}>{children}</Typography>;
};

const ConnectButton = ({ children, onClick }) => {
    return (
        <ButtonBase
            sx={{
                width: '100%',
                boxSizing: 'border-box',
                borderRadius: '6px',
                background: paletteV2.primary.medium,
                padding: '11px 16px',
                color: paletteV2.neutrals.white,
                fontSize: '15px',
                fontWeight: 500,
                ':hover': {
                    background: paletteV2.primary.darken,
                },
            }}
            onClick={onClick}
        >
            {children}
        </ButtonBase>
    );
};
