import { styled } from '@mui/styles';

export const ReviewContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    boxSizing: 'border-box',
    width: '773px',
    maxWidth: '90%',
    '& > .review-imported-heading': {
        marginBottom: 3,
    },
    '& > .review-imported-subline': {
        marginBottom: 30,
    },
}));

export const ImportedProspectsOverviewContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyConent: 'space-between',
    boxSizing: 'border-box',
    border: '1px solid #E4E6EB',
    boxShadow: '0px 2px 6px rgba(112, 144, 176, 0.07)',
    borderRadius: 14,
    background: 'white',
    '& > div': {
        width: '33%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        paddingLeft: 60,
        paddingTop: 25,
        paddingBottom: 60,
        paddingRight: 5,
        borderRight: '1px solid #E4E6EB',
        boxSizing: 'border-box',
        '& > div:first-child': {
            marginBottom: 10,
        },
        '& > h6:nth-child(2)': {
            fontWeight: 500,
        },
        '& .subtitle': {
            textAlign: 'left',
            color: theme.palette.subline,
            fontSize: 13,
        },
        '& > .top-container': {
            display: 'flex',
            alignItems: 'center',
            '& > span': {
                marginLeft: 12,
            },
        },
    },
    '& > div:last-child': {
        borderRight: 'none',
    },
}));

export const ModalContainer = styled('div')(({ theme }) => ({
    width: '90vw',
    height: '90vh',
    background: 'white',
    margin: 'auto',
    marginTop: '5vh',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 1178,
    borderRadius: 14,
    '& .MuiTableCell-body': {
        border: '0.5px solid #E4E6EB',
        whiteSpace: 'nowrap',
        '&.error': {
            background: '#EEE0E2',
            border: '0.5px solid #872336',
        },
        '& > svg': {
            marginRight: 10,
            marginBottom: '-3px',
        },
    },
    '& > .header-container': {
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: '1px solid #E4E6EB',
        padding: '20px 50px',
        '& .close-button': {
            marginLeft: 10,
        },
        '& .subtitle': {
            fontSize: 14,
            marginTop: 4,
            color: theme.palette.subline,
        },
    },
    '& > .MuiTableContainer-root': {
        padding: '5px 50px',
        boxSizing: 'border-box',
        display: 'flex',
        overflow: 'auto',
    },
    '& .MuiTable-root': {
        borderCollapse: 'separate',
    },
}));
