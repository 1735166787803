import { Globe, Linkedin, MapPin, Users } from 'lucide-react';
import { useQuery } from '@tanstack/react-query';
import { AccountLogo } from '../../account-logo';
import { Tooltip, TooltipContent, TooltipTrigger } from '@shadcn/ui/components/ui/tooltip';
import { ACCOUNT_DETAILS_SEARCH_PEOPLE_PAGE_SIZE, SearchPeopleInAccount } from './search-people-in-account';
import { useMemo, useState } from 'react';
import { searchInCompany } from '../../../sdk/internal/global-search.sdk';
import { ensureHttps } from '../../../../helpers/email';
import { useSdk } from '../../../sdk';

function DetailsNotFoundCard() {
    return (
        <div className="flex flex-col items-center justify-center p-8 space-y-4 text-center">
            <div className="w-12 h-12 rounded-lg flex items-center justify-center">
                <Globe className="w-6 h-6 text-muted-foreground" />
            </div>
            <div className="space-y-2">
                <h3 className="font-medium">Company Details Not Found</h3>
                <p className="text-sm text-muted-foreground">We couldn't find any details about this company.</p>
            </div>
        </div>
    );
}

export function AccountPreview({ accountId }: { accountId: string }) {
    const {
        account: { getAccountById },
    } = useSdk();

    const { data: account, isLoading: isLoadingAccount } = useQuery(
        getAccountById(accountId, { enabled: !!accountId })
    );

    // Backup account data
    const { data: backupAccountData } = useQuery(
        searchInCompany(
            {
                match: '',
                page: 1,
                perPage: ACCOUNT_DETAILS_SEARCH_PEOPLE_PAGE_SIZE,
                accountId,
            },
            {
                enabled: !!accountId && !isLoadingAccount && !account?.data?.name,
            }
        )
    );

    const previewData = useMemo(
        () => ({
            id: account?.accountId || accountId,
            name: account?.data.name || backupAccountData?.data[0]?.companyName,
            description: account?.data.aboutUs || null,
            industry: account?.data.industry || backupAccountData?.data[0]?.companyIndustry,
            website: ensureHttps(account?.data.website || backupAccountData?.data[0]?.companyWebsite || ''),
            size: account?.data.size || null,
            locations: account?.data.locations || null,
            linkedinUrl: account?.linkedinUrl || null,
        }),
        [accountId, account?.accountId, backupAccountData?.data]
    );

    if (isLoadingAccount) return null;
    if (!previewData?.name) return <DetailsNotFoundCard />;
    return <AccountDetailCard {...previewData} />;
}

interface AccountDetailProps {
    id: string;
    name: string;
    description?: string;
    industry?: string;
    website?: string;
    size?: string;
    locations?: string[];
    linkedinUrl?: string;
}

export function AccountDetailCard(account: AccountDetailProps) {
    if (!account) return null;

    return (
        <div className="flex flex-col p-4 overflow-y-auto space-y-8">
            <Header account={account} />
            <CompanyDetails account={account} />
            <SearchPeopleInAccount accountId={account.id} accountName={account.name} />
        </div>
    );
}

const Header = ({ account }: { account: AccountDetailProps }) => {
    const organizationLogoHostname = account.website && new URL(account.website).hostname;

    return (
        <div className="flex items-center gap-4">
            <AccountLogo accountWebsite={account.website} className="w-12 h-12" />
            <div>
                <Tooltip delayDuration={300}>
                    <TooltipTrigger asChild>
                        <a
                            href={`https://${organizationLogoHostname}`}
                            className="text-xl font-medium hover:underline"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {account.name}
                        </a>
                    </TooltipTrigger>
                    <TooltipContent>Go to company website</TooltipContent>
                </Tooltip>
                <p className="text-muted-foreground">{account.industry}</p>
            </div>
        </div>
    );
};

const CompanyDetails = ({ account }: { account: AccountDetailProps }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const splitNewlines = (text: string) => text?.split(/\n|\\n|\\\n/);
    const formattedDescription = useMemo(
        () => (account.description ? splitNewlines(account.description) : []),
        [account.description]
    );
    const descriptionPreview = useMemo(
        () =>
            account.description
                ? splitNewlines(account.description.slice(0, 250) + (account.description.length > 250 ? '...' : ''))
                : [],
        [formattedDescription]
    );

    return (
        <div className="space-y-4">
            <h4 className="text-sm text-muted-foreground">COMPANY DETAILS</h4>
            <div className="space-y-4">
                {account.description && (
                    <div>
                        <div className="text-sm text-muted-foreground leading-relaxed transition-all duration-300">
                            {(isExpanded ? formattedDescription : descriptionPreview).map((line, index) => (
                                <p key={index} className="pb-2">
                                    {line}
                                </p>
                            ))}
                        </div>
                        {account.description.length > 180 && (
                            <button
                                onClick={() => setIsExpanded(!isExpanded)}
                                className="text-sm text-blue-500 hover:underline mt-1"
                            >
                                {isExpanded ? 'Show less' : 'Show more'}
                            </button>
                        )}
                    </div>
                )}

                <div className="grid grid-cols-1 gap-3 text-sm">
                    {account.locations && (
                        <div className="flex items-center gap-2">
                            <MapPin className="text-muted-foreground h-4 w-4 stroke-[1]" />
                            <span>{account.locations[0]}</span>
                        </div>
                    )}
                    {account.size && (
                        <div className="flex items-center gap-2">
                            <Users className="text-muted-foreground h-4 w-4 stroke-[1]" />
                            <span>{account.size}</span>
                        </div>
                    )}
                    {account.website && (
                        <div className="flex items-center gap-2">
                            <Globe className="text-muted-foreground h-4 w-4 stroke-[1]" />
                            <a
                                href={account.website}
                                className="hover:underline text-blue-500"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {`${new URL(account.website).host.replace(/^www\./, '')}${new URL(
                                    account.website
                                ).pathname.replace(/\/$/, '')}`}
                            </a>
                        </div>
                    )}
                    {account.linkedinUrl && (
                        <div className="flex items-center gap-2">
                            <Linkedin className="text-muted-foreground h-4 w-4 stroke-[1]" />
                            <a
                                href={account.linkedinUrl}
                                className="hover:underline text-blue-500"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {`${new URL(account.linkedinUrl).hostname}${new URL(account.linkedinUrl).pathname}`}
                            </a>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
