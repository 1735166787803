import { useState } from 'react';
import { useSuggestionsFeed } from '../../../hooks/use-suggestions-feed';
import { Button } from '@shadcn/ui/components/ui/button';

export const ContactDescription = () => {
    const { currentContactSuggestionProfileData: profileData } = useSuggestionsFeed();
    const [isCollapsed, setIsCollapsed] = useState(true);
    const toggleCollapse = () => setIsCollapsed(!isCollapsed);

    const description = profileData?.linkedinDescription;
    if (!description) return null;

    const needTruncation = description.length > 200;
    const displayedDescription = isCollapsed ? description?.slice(0, 200) : description;

    return (
        <div className="flex flex-col items-start justify-start gap-2 w-full rounded-xl bg-background-tertiary p-4">
            <div className="flex flex-col gap-2 items-start overflow-hidden text-text-primary w-full">
                <div className="flex flex-col gap-2 text-muted-foreground">
                    <span className="text-sm font-medium text-muted-foreground">LinkedIn Description</span>
                    <p className="text-sm">
                        {displayedDescription} {needTruncation && isCollapsed && '...'}
                    </p>
                    {needTruncation && (
                        <Button variant="link" size="sm" onClick={toggleCollapse}>
                            {isCollapsed ? 'Read more' : 'Read less'}
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};
