import { X } from 'lucide-react';
import { useSearchParams } from 'react-router-dom';
import { Input } from '@shadcn/ui/components/ui/input';
import { LinkedinSearchType } from '../hooks/use-linkedin-search';
import { LINKEDIN_SEARCH_TYPE_PARAM } from './type-switcher';
import { useEffect } from 'react';

export const LINKEDIN_SEARCH_TERM_PARAM = 'q';

export const SearchInput = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const searchTerm = searchParams.get(LINKEDIN_SEARCH_TERM_PARAM) || '';
    const type = (searchParams.get(LINKEDIN_SEARCH_TYPE_PARAM) as LinkedinSearchType) || 'inbox';

    const handleSearchChange = (value: string) => {
        const newParams = new URLSearchParams(searchParams);
        if (value) {
            newParams.set(LINKEDIN_SEARCH_TERM_PARAM, value);
        } else {
            newParams.delete(LINKEDIN_SEARCH_TERM_PARAM);
        }
        setSearchParams(newParams, { replace: true });
    };

    useEffect(() => {
        handleSearchChange('');
    }, [type]);

    return (
        <div className="relative flex w-full min-w-[150px] md:max-w-[300px]">
            <Input
                placeholder={type === 'inbox' ? 'Search conversations...' : 'Search connections...'}
                value={searchTerm}
                onChange={(e) => handleSearchChange(e.target.value)}
                className="w-full"
            />
            {searchTerm && (
                <button
                    onClick={() => handleSearchChange('')}
                    className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-500 hover:text-gray-700"
                >
                    <X className="h-4 w-4" />
                </button>
            )}
        </div>
    );
};
