import { FC, useState, useRef, useCallback, ChangeEvent, useEffect } from 'react';
import { parse } from 'papaparse';
import { read, utils } from 'xlsx';
import { Loader2, Upload, File, XCircle } from 'lucide-react';
import { Button } from '@shadcn/ui/components/ui/button';
import { Input } from '@shadcn/ui/components/ui/input';
import { toast } from '@shadcn/ui/hooks/use-toast';

interface UploadCSVStepProps {
    onFileUploaded: (file: File, data: any[], headers: string[]) => void;
    reset?: boolean;
}

export const UploadCSVStep: FC<UploadCSVStepProps> = ({ onFileUploaded, reset }) => {
    const [file, setFile] = useState<File | null>(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [csvData, setCsvData] = useState<any[]>([]);
    const [headers, setHeaders] = useState<string[]>([]);
    const fileInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (reset) {
            handleRemoveFile();
        }
    }, [reset]);

    const processExcelFile = useCallback(
        async (file: File) => {
            setIsProcessing(true);
            try {
                const buffer = await file.arrayBuffer();
                const workbook = read(buffer);
                const firstSheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[firstSheetName];
                const parsedData = utils.sheet_to_json(worksheet);
                const parsedHeaders = Object.keys(parsedData[0] || {});

                setCsvData(parsedData);
                setHeaders(parsedHeaders);
                onFileUploaded(file, parsedData, parsedHeaders);

                toast({
                    title: 'Excel file processed',
                    description: `Found ${parsedData.length} rows with ${parsedHeaders.length} columns`,
                    variant: 'success',
                });
            } catch (error) {
                console.error('Error parsing Excel:', error);
                toast({
                    title: 'Error processing Excel',
                    description: 'Could not process the file. Please check the format.',
                    variant: 'destructive',
                });
            }
            setIsProcessing(false);
        },
        [onFileUploaded]
    );

    const processFile = useCallback(
        (file: File) => {
            setIsProcessing(true);

            if (file.type === 'text/csv') {
                parse(file, {
                    header: true,
                    skipEmptyLines: true,
                    complete: (results: any) => {
                        const parsedData = results.data;
                        const parsedHeaders = results.meta.fields || [];

                        setCsvData(parsedData);
                        setHeaders(parsedHeaders);
                        onFileUploaded(file, parsedData, parsedHeaders);
                        setIsProcessing(false);

                        toast({
                            title: 'CSV file processed',
                            description: `Found ${parsedData.length} rows with ${parsedHeaders.length} columns`,
                            variant: 'success',
                        });
                    },
                    error: (error: any) => {
                        console.error('Error parsing CSV:', error);
                        setIsProcessing(false);
                        toast({
                            title: 'Error processing CSV',
                            description: error.message || 'Could not process the file. Please check the format.',
                            variant: 'destructive',
                        });
                    },
                });
            } else {
                processExcelFile(file);
            }
        },
        [onFileUploaded, processExcelFile]
    );

    const isValidFileType = (file: File) => {
        const validTypes = [
            'text/csv',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx
            'application/vnd.ms-excel', // xls
        ];
        return validTypes.includes(file.type);
    };

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files?.[0];
        if (selectedFile) {
            if (isValidFileType(selectedFile)) {
                setFile(selectedFile);
                processFile(selectedFile);
            } else {
                toast({
                    title: 'Invalid file type',
                    description: 'Please upload a CSV or Excel file',
                    variant: 'destructive',
                });
            }
        }
    };

    const handleRemoveFile = () => {
        setFile(null);
        setCsvData([]);
        setHeaders([]);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();

        const droppedFile = e.dataTransfer.files?.[0];
        if (droppedFile && isValidFileType(droppedFile)) {
            setFile(droppedFile);
            processFile(droppedFile);
        } else {
            toast({
                title: 'Invalid file type',
                description: 'Please upload a CSV or Excel file',
                variant: 'destructive',
            });
        }
    };

    return (
        <div className="space-y-6">
            {!file ? (
                <div
                    className="border-2 border-dashed rounded-lg p-10 text-center cursor-pointer transition-colors hover:border-primary/50"
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                    onClick={() => fileInputRef.current?.click()}
                >
                    <Upload className="h-12 w-12 mx-auto text-muted-foreground mb-4" />
                    <p className="text-lg font-medium mb-2">Drag & drop file here</p>
                    <p className="text-sm text-muted-foreground mb-4">or click to browse files</p>
                    <Input
                        ref={fileInputRef}
                        type="file"
                        accept=".csv,.xlsx,.xls,text/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                        onChange={handleFileChange}
                        className="hidden"
                    />
                    <Button variant="outline" type="button" className="mx-auto">
                        Select file
                    </Button>
                    <p className="text-xs text-muted-foreground mt-4">Supported formats: CSV, Excel (.xlsx, .xls)</p>
                </div>
            ) : (
                <div className="border rounded-lg p-6">
                    <div className="flex items-center justify-between mb-4">
                        <div className="flex items-center gap-3">
                            <div className="p-2 bg-primary/10 rounded-lg">
                                <File className="h-6 w-6 text-primary" />
                            </div>
                            <div>
                                <p className="font-medium">{file.name}</p>
                                <p className="text-sm text-muted-foreground">
                                    {(file.size / 1024).toFixed(2)} KB • {csvData.length} rows
                                </p>
                            </div>
                        </div>
                        <Button variant="ghost" size="icon" onClick={handleRemoveFile} disabled={isProcessing}>
                            <XCircle className="h-5 w-5" />
                        </Button>
                    </div>

                    {isProcessing ? (
                        <div className="flex items-center justify-center py-4">
                            <Loader2 className="h-6 w-6 animate-spin text-primary mr-2" />
                            <span>Processing file...</span>
                        </div>
                    ) : (
                        <>
                            <div className="border rounded-lg overflow-hidden">
                                <div className="overflow-x-auto">
                                    <table className="w-full text-sm">
                                        <thead>
                                            <tr className="bg-muted">
                                                {headers.slice(0, 5).map((header, index) => (
                                                    <th key={index} className="px-4 py-2 text-left font-medium">
                                                        {header}
                                                    </th>
                                                ))}
                                                {headers.length > 5 && (
                                                    <th className="px-4 py-2 text-left font-medium">
                                                        +{headers.length - 5} more
                                                    </th>
                                                )}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {csvData.slice(0, 3).map((row, rowIndex) => (
                                                <tr key={rowIndex} className="border-t">
                                                    {headers.slice(0, 5).map((header, colIndex) => (
                                                        <td key={colIndex} className="px-4 py-2 truncate max-w-[200px]">
                                                            {row[header] || '-'}
                                                        </td>
                                                    ))}
                                                    {headers.length > 5 && <td className="px-4 py-2">...</td>}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                {csvData.length > 3 && (
                                    <div className="p-2 text-center text-sm text-muted-foreground border-t">
                                        Showing 3 of {csvData.length} rows
                                    </div>
                                )}
                            </div>
                            <p className="mt-4 text-sm text-muted-foreground">
                                Preview shows first 3 rows. Continue to map these fields to our system.
                            </p>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default UploadCSVStep;
