import { FC, useState, useEffect, ReactNode, useRef } from 'react';
import { Button } from '@shadcn/ui/components/ui/button';
import { Input } from '@shadcn/ui/components/ui/input';
import { Checkbox } from '@shadcn/ui/components/ui/checkbox';
import { ChevronRight, Search, Building, Users, Briefcase, MapPin } from 'lucide-react';
import { useSearchContext } from '../context/search-context';
import { ProspectSearchAutoCompleteFields, LocationDto } from '@zaplify/prospects';
import { getZaplifySdk } from '@zaplify/sdk';
import { Popover, PopoverContent, PopoverTrigger } from '@shadcn/ui/components/ui/popover';
import { ScrollArea } from '@shadcn/ui/components/ui/scroll-area';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@shadcn/ui/components/ui/tabs';
import { industries } from '../../../../../views/campaign/newCampaign/addProspects/prospectSearch/constants';
import { cn } from '@shadcn/ui/lib/utils';

type FilterOption = {
    name: string;
    count?: string;
    value?: string;
};

type FilterConfig = {
    label: string;
    icon: ReactNode;
    filterKey: 'organizationFilter' | 'personalFilter';
    fieldKey: string;
    autocompleteField?: ProspectSearchAutoCompleteFields;
    staticOptions?: FilterOption[];
    isLocation?: boolean;
};

export const SearchFilters: FC = () => {
    return (
        <div className="flex items-center gap-4 w-full">
            <FilterPopover
                config={{
                    label: 'Company',
                    icon: <Building className="mr-2 h-4 w-4" />,
                    filterKey: 'organizationFilter',
                    fieldKey: 'organizations',
                    autocompleteField: ProspectSearchAutoCompleteFields.JOB_COMPANY_NAME,
                }}
            />
            <FilterPopover
                config={{
                    label: 'Employee Count',
                    icon: <Users className="mr-2 h-4 w-4" />,
                    filterKey: 'organizationFilter',
                    fieldKey: 'organizationSizes',
                    staticOptions: [
                        { name: '1-10', value: '1-10' },
                        { name: '11-50', value: '11-50' },
                        { name: '51-200', value: '51-200' },
                        { name: '201-500', value: '201-500' },
                        { name: '501-1000', value: '501-1000' },
                        { name: '1001-5000', value: '1001-5000' },
                        { name: '5001-10000', value: '5001-10000' },
                        { name: '10001+', value: '10001+' },
                    ],
                }}
            />
            <FilterPopover
                config={{
                    label: 'Job title',
                    icon: <Briefcase className="mr-2 h-4 w-4" />,
                    filterKey: 'personalFilter',
                    fieldKey: 'occupationTitles',
                    autocompleteField: ProspectSearchAutoCompleteFields.JOB_TITLE_NEW,
                }}
            />
            <FilterPopover
                config={{
                    label: 'Industry',
                    icon: <Briefcase className="mr-2 h-4 w-4" />,
                    filterKey: 'organizationFilter',
                    fieldKey: 'industries',
                    staticOptions: industries.map((industry) => ({ name: industry })),
                }}
            />
            <FilterPopover
                config={{
                    label: 'Location',
                    icon: <MapPin className="mr-2 h-4 w-4" />,
                    filterKey: 'personalFilter',
                    fieldKey: 'locations',
                    autocompleteField: ProspectSearchAutoCompleteFields.LOCATION_NAME,
                    isLocation: true,
                }}
            />
        </div>
    );
};

type FilterState = {
    include: FilterOption[];
    exclude: FilterOption[];
};

const FilterPopover: FC<{ config: FilterConfig }> = ({ config }) => {
    const { filters, updateFilter } = useSearchContext();
    const [searchValue, setSearchValue] = useState('');
    const [options, setOptions] = useState<FilterOption[]>(config.staticOptions || []);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [filterState, setFilterState] = useState<FilterState>({
        include: [],
        exclude: [],
    });
    const selectedCount = filterState.include.length + filterState.exclude.length;

    // Initialize filter state from context when component mounts
    useEffect(() => {
        if (!filters) return;

        const contextFilter = filters[config.filterKey]?.[config.fieldKey];
        if (!contextFilter) return;

        const convertToFilterOption = (item: any): FilterOption => {
            if (typeof item === 'string') {
                return { name: item, value: item };
            }
            if (item && typeof item === 'object' && item.name) {
                return item;
            }
            return { name: String(item), value: String(item) };
        };

        const include = Array.isArray(contextFilter.include) ? contextFilter.include.map(convertToFilterOption) : [];

        const exclude = Array.isArray(contextFilter.exclude) ? contextFilter.exclude.map(convertToFilterOption) : [];

        setFilterState({ include, exclude });
    }, [filters, config.filterKey, config.fieldKey]);

    useEffect(() => {
        if (!open) {
            const formatOptions = (options: FilterOption[]) =>
                options.map((opt) => (config.isLocation ? { name: opt.value || opt.name } : opt.value || opt.name));

            const formattedData = {
                [config.fieldKey]: {
                    include: formatOptions(filterState.include),
                    exclude: formatOptions(filterState.exclude),
                },
            };

            updateFilter(config.filterKey, formattedData);
        }
    }, [open]);

    const latestRequestRef = useRef<number>(0);
    useEffect(() => {
        if (!searchValue || !config.autocompleteField) return;

        const abortController = new AbortController();
        const requestId = Date.now();
        latestRequestRef.current = requestId;

        const delayDebounceFn = setTimeout(async () => {
            if (!searchValue || !config.autocompleteField) return;

            setLoading(true);
            try {
                const sdk = getZaplifySdk().profiles;
                const results = (await sdk.sources.autocomplete({
                    field: config.autocompleteField,
                    query: searchValue,
                })) as FilterOption[];

                // Only update state if this is still the latest request and not aborted
                if (requestId === latestRequestRef.current && !abortController.signal.aborted) {
                    const uniqueResults = results.filter(
                        (option, index, self) => index === self.findIndex((t) => t.name === option.name)
                    );

                    setOptions(uniqueResults);
                }
            } catch (error) {
                console.error(`Error fetching ${config.label} options:`, error);
            } finally {
                if (requestId === latestRequestRef.current) {
                    setLoading(false);
                }
            }
        }, 300);

        return () => {
            clearTimeout(delayDebounceFn);
            abortController.abort();
        };
    }, [searchValue, config.autocompleteField, config.label]);

    const handleSearch = (value: string) => {
        setSearchValue(value);
    };

    const handleOptionToggle = (option: FilterOption, isExclude: boolean) => {
        const listKey = isExclude ? 'exclude' : 'include';
        const optionValue = option.value || option.name;

        setFilterState((prev) => {
            const isSelected = prev[listKey].some((item) => (item.value || item.name) === optionValue);

            if (isSelected) {
                return {
                    ...prev,
                    [listKey]: prev[listKey].filter((item) => (item.value || item.name) !== optionValue),
                };
            } else {
                return {
                    ...prev,
                    [listKey]: [...prev[listKey], option],
                };
            }
        });
    };

    const isOptionSelected = (option: FilterOption, isExclude: boolean): boolean => {
        const listKey = isExclude ? 'exclude' : 'include';
        const optionValue = option.value || option.name;

        return filterState[listKey].some((item) => (item.value || item.name) === optionValue);
    };

    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
                <Button
                    variant="outline"
                    className={`gap-1 justify-start ${selectedCount > 0 ? 'bg-background-brand-section-subtle' : ''}`}
                >
                    {config.icon}
                    <span className="flex-1 text-left">{config.label}</span>
                    {selectedCount > 0 && (
                        <span className="mr-2 ml-0.5 px-2 py-1 text-xs font-semibold bg-background-secondary rounded-md">
                            {selectedCount}
                        </span>
                    )}
                    <ChevronRight className="h-4 w-4" />
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-80 p-0" align="start">
                <Tabs defaultValue="include" className="w-full">
                    <div className="flex items-center border-b">
                        <TabsList className="h-10 grid w-full grid-cols-2">
                            <TabsTrigger value="include">
                                Include{' '}
                                <span className="mr-2 ml-0.5 px-2 py-1 text-xs font-semibold">
                                    {filterState.include.length > 0 && filterState.include.length}
                                </span>
                            </TabsTrigger>
                            <TabsTrigger value="exclude">
                                Exclude{' '}
                                <span className="mr-2 ml-0.5 px-2 py-1 text-xs font-semibold">
                                    {filterState.exclude.length > 0 && filterState.exclude.length}
                                </span>
                            </TabsTrigger>
                        </TabsList>
                    </div>

                    {/* Include Tab */}
                    <TabsContent value="include" className="p-0">
                        {config.autocompleteField && (
                            <div className="flex items-center border-b p-2">
                                <Search className="h-4 w-4 mr-2 text-muted-foreground" />
                                <Input
                                    placeholder={`Search ${config.label.toLowerCase()}s...`}
                                    value={searchValue}
                                    onChange={(e) => handleSearch(e.target.value)}
                                    className="border-0 focus-visible:ring-0 focus-visible:ring-offset-0"
                                />
                            </div>
                        )}
                        <OptionsList
                            options={searchValue ? options : config.staticOptions || filterState.include}
                            loading={loading}
                            searchValue={searchValue}
                            entityName={config.label.toLowerCase()}
                            onSelect={(option) => handleOptionToggle(option, false)}
                            isSelected={(option) => isOptionSelected(option, false)}
                            prefix={config.fieldKey}
                        />
                    </TabsContent>

                    {/* Exclude Tab */}
                    <TabsContent value="exclude" className="p-0">
                        {config.autocompleteField && (
                            <div className="flex items-center border-b p-2">
                                <Search className="h-4 w-4 mr-2 text-muted-foreground" />
                                <Input
                                    placeholder={`Search ${config.label.toLowerCase()}s to exclude...`}
                                    value={searchValue}
                                    onChange={(e) => handleSearch(e.target.value)}
                                    className="border-0 focus-visible:ring-0 focus-visible:ring-offset-0"
                                />
                            </div>
                        )}
                        <OptionsList
                            options={searchValue ? options : config.staticOptions || filterState.exclude}
                            loading={loading}
                            searchValue={searchValue}
                            entityName={config.label.toLowerCase()}
                            onSelect={(option) => handleOptionToggle(option, true)}
                            isSelected={(option) => isOptionSelected(option, true)}
                            prefix={`exclude-${config.fieldKey}`}
                        />
                    </TabsContent>
                </Tabs>
            </PopoverContent>
        </Popover>
    );
};

const OptionsList: FC<{
    options: FilterOption[];
    loading: boolean;
    searchValue: string;
    entityName: string;
    onSelect: (option: FilterOption) => void;
    isSelected: (option: FilterOption) => boolean;
    prefix: string;
}> = ({ options, loading, searchValue, entityName, onSelect, isSelected, prefix }) => {
    return (
        <ScrollArea className="h-[250px]">
            {loading ? (
                <div className="py-2">
                    {Array.from({ length: 5 }).map((_, index) => (
                        <div key={`skeleton-${index}`} className="flex items-center px-3 py-2 mb-0.5">
                            <div className="w-4 h-4 mr-2 rounded-sm bg-muted animate-pulse" />
                            <div className="flex-1 h-4 bg-muted rounded animate-pulse" />
                            <div className="w-8 h-3 ml-2 bg-muted rounded animate-pulse" />
                        </div>
                    ))}
                </div>
            ) : options.length > 0 ? (
                <div>
                    {options.map((option) => (
                        <div
                            key={`${prefix}-${option.name}`}
                            className={cn('flex items-center px-3 py-2 cursor-pointer hover:bg-muted mb-0.5 ', {
                                'bg-background-brand-section-subtle': isSelected(option),
                            })}
                            onClick={() => onSelect(option)}
                        >
                            <Checkbox
                                id={`${prefix}-${option.name}`}
                                className="mr-2"
                                onClick={(e) => e.stopPropagation()}
                                checked={isSelected(option)}
                                onCheckedChange={() => onSelect(option)}
                            />
                            <span className="flex-1 truncate">{option.name}</span>
                            {option.count && <span className="text-xs text-muted-foreground">{option.count}</span>}
                        </div>
                    ))}
                </div>
            ) : (
                <div className="p-3 text-center text-sm text-muted-foreground">
                    {searchValue
                        ? `No ${entityName}s found`
                        : `Type to search ${entityName}s${prefix.startsWith('exclude') ? ' to exclude' : ''}`}
                </div>
            )}
        </ScrollArea>
    );
};
