import { styled } from '@mui/styles';

const PaymentDetailsStyled = styled('div')(({ theme: { palette } }) => ({
    '& .label': {
        fontSize: '14px',
        color: palette.darkGrey,
    },
    '& .email': {
        marginRight: 10,
        fontSize: 15,
        fontWeight: 500,
    },
    '& .change-button': {
        fontSize: 14,
        fontWeight: 500,
        color: palette.darkBlue,
        textDecoration: 'underline',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    '& .billing-address': {
        marginBottom: '24px',
        fontSize: 15,
        fontWeight: 400,
        color: palette.colors.subline,
        '& .info': {},
    },
    '& .payment-method': {
        '& .title': {
            fontSize: '14px',
            color: palette.darkGrey,
        },
        '& .payment-icon': {
            marginRight: 19,
        },
        '& .card-number': {
            fontSize: 15,
            fontWeight: 500,
        },
    },
}));

export default PaymentDetailsStyled;
