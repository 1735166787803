import { ICommand, Topics } from '@zaplify/common';

export class BackfillEmailsForContactCommand extends ICommand<void> {
    public static override readonly TOPIC = Topics.CAMPAIGNS;
    constructor(
        readonly prospectId: string,
        readonly userId: string,
        readonly organizationId: string,
        readonly options?: { initialSync?: boolean },
    ) {
        super();
    }
}
