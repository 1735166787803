import { ChevronLeftIcon, Loader2, Settings2, UserRound, type LucideIcon, Bot } from 'lucide-react';

import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@shadcn/ui/components/ui/collapsible';
import {
    SidebarGroup,
    SidebarMenu,
    SidebarMenuBadge,
    SidebarMenuButton,
    SidebarMenuItem,
    SidebarMenuSub,
    SidebarMenuSubButton,
    SidebarMenuSubItem,
} from '@shadcn/ui/components/ui/sidebar';
import { NavLink } from 'react-router-dom';
import { ActionsCountBadge } from '../actions-count-badge';
import { Suspense } from 'react';
import { Badge } from '@shadcn/ui/components/ui/badge';
import { useAuth } from '../../providers/authentication-provider';
import { useIsMobile } from '@shadcn/ui/hooks/use-mobile';
import { useMemo } from 'react';
// import teamIcon from '../../../assets/team-plan.png';
import React from 'react';

export function NavMain({
    items,
}: {
    items: {
        title: string;
        url: string;
        icon?: LucideIcon;
        isActive?: boolean;
        items?: {
            title: string;
            url: string;
            hideOnMobile?: boolean;
        }[];
        asLink?: boolean;
        className?: string;
        hideOnMobile?: boolean;
    }[];
}) {
    // TODO: refactor this to have the menu listed in here instead of in a json object
    const {
        authState: { isGlobalAdmin },
    } = useAuth();
    const isMobile = useIsMobile();

    const isAdmin = isGlobalAdmin;

    // Memoize the items array with admin items
    const finalItems = useMemo(() => {
        if (isAdmin && !items.some((item) => item.title === 'Global Admin')) {
            return [
                ...items,
                {
                    title: 'Global Admin',
                    url: '/new/admin',
                    icon: Settings2,
                    items: [],
                },
            ];
        }
        return items;
    }, [items, isAdmin]);

    // Memoize the filtered items
    const filteredItems = useMemo(() => {
        return finalItems
            .filter((item) => !item.hideOnMobile || !isMobile)
            .map((item) => {
                if (!item?.url) return null;
                return {
                    ...item,
                    items: item.items?.filter((subItem) => !subItem.hideOnMobile || !isMobile),
                };
            });
    }, [finalItems, isMobile]);

    return (
        <SidebarGroup>
            <SidebarMenu>
                {filteredItems.map((item) => {
                    if (!item?.url) return null;

                    return (
                        <Collapsible key={item.title} asChild defaultOpen={true} className="group/collapsible">
                            <SidebarMenuItem>
                                {/* Main menu item */}
                                <NavLink to={item.url}>
                                    {({ isActive: mainIsActive }) => (
                                        <SidebarMenuButton tooltip={item.title} isActive={mainIsActive} asChild>
                                            <div>
                                                {item.icon && <item.icon />}
                                                <div className={item.className}>
                                                    <span>{item.title}</span>
                                                </div>
                                                {item.title === 'Actions' && (
                                                    <SidebarMenuBadge>
                                                        <Suspense
                                                            fallback={
                                                                <Badge
                                                                    variant="secondary"
                                                                    className="w-fit bg-background-warning-secondary border-border-primary"
                                                                >
                                                                    <Loader2 className="h-4 w-4 animate-spin" />
                                                                </Badge>
                                                            }
                                                        >
                                                            <ActionsCountBadge />
                                                        </Suspense>
                                                    </SidebarMenuBadge>
                                                )}
                                                {item.items?.length > 0 && (
                                                    <CollapsibleTrigger asChild>
                                                        <ChevronLeftIcon className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:-rotate-90" />
                                                    </CollapsibleTrigger>
                                                )}
                                            </div>
                                        </SidebarMenuButton>
                                    )}
                                </NavLink>

                                {/* Submenu items */}
                                <CollapsibleContent>
                                    {item.items?.length > 0 && (
                                        <SidebarMenuSub>
                                            {item.items
                                                .filter((subItem) => !subItem.hideOnMobile || !isMobile)
                                                .map((subItem) => {
                                                    if (!subItem?.url) return null;

                                                    return (
                                                        <SidebarMenuSubItem key={subItem.title}>
                                                            <NavLink to={subItem.url}>
                                                                {({ isActive: subIsActive }) => (
                                                                    <SidebarMenuSubButton
                                                                        isActive={subIsActive}
                                                                        asChild
                                                                    >
                                                                        <div className="flex items-center justify-between gap-2">
                                                                            <span>{subItem.title}</span>
                                                                            {/* {subItem.title === 'List import' && (
                                                                                <img
                                                                                    src={teamIcon}
                                                                                    alt="Team plan"
                                                                                    className="w-4 h-4"
                                                                                />
                                                                            )} */}
                                                                        </div>
                                                                    </SidebarMenuSubButton>
                                                                )}
                                                            </NavLink>
                                                        </SidebarMenuSubItem>
                                                    );
                                                })}
                                        </SidebarMenuSub>
                                    )}
                                </CollapsibleContent>
                            </SidebarMenuItem>
                        </Collapsible>
                    );
                })}
            </SidebarMenu>
        </SidebarGroup>
    );
}

// Memoize the entire component
export const MemoizedNavMain = React.memo(NavMain);
