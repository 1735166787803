import { ICommand, Topics } from '@zaplify/common';

export class DeleteOutreachSuggestionCommand extends ICommand<void> {
    public static override readonly TOPIC = Topics.CAMPAIGNS;

    constructor(
        public readonly userId: string,
        public readonly params: { suggestionId?: string; prospectId?: string },
    ) {
        super();
    }
}
