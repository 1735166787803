import React, { useEffect, useState } from 'react';
import { cn } from '@shadcn/ui/lib/utils';
import { ConversationsSectionAccordionItem } from '@shadcn/ui/components/conversations-section';
import { AnimatePresence } from 'motion/react';
import { ActionCard } from './action-card';
import { ConversationCardSkeleton } from '../../../components/conversation-card-skeleton';
import { Accordion } from '@shadcn/ui/components/ui/accordion';
import { useActions } from '../../../hooks/use-actions';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { CompletedActionsCountBadge } from '../../../components/actions-count-badge';
import { useIsMobile } from '@shadcn/ui/hooks/use-mobile';
import { Button } from '@shadcn/ui/components/ui/button';
import { paths } from '../../../../routes/paths';
import { CheckCircleIcon } from 'lucide-react';

interface ActionsListProps {}

export const ActionsListSkeleton = () => {
    return (
        <div className={cn('w-full max-w-xs mx-auto h-screen max-h-screen overflow-hidden border-r')}>
            <Accordion type="multiple" defaultValue={['Focus', 'Completed']} className={cn('flex flex-col')}>
                <ConversationsSectionAccordionItem title={'Focus'}>
                    <AnimatePresence>
                        <ConversationCardSkeleton key={'todo-0'} />
                        <ConversationCardSkeleton key={'todo-1'} />
                        <ConversationCardSkeleton key={'todo-2'} />
                    </AnimatePresence>
                </ConversationsSectionAccordionItem>
                <ConversationsSectionAccordionItem title={'Completed'} lastSection>
                    <AnimatePresence>
                        <ConversationCardSkeleton key={'completed-0'} />
                    </AnimatePresence>
                </ConversationsSectionAccordionItem>
            </Accordion>
        </div>
    );
};

export const ActionsList: React.FC<ActionsListProps> = () => {
    const { prospectIdsWithActions, prospectIdsWithCompletedActions, isLoadingActions } = useActions();
    const { prospectId } = useParams();
    const navigate = useNavigate();
    const [accordionValue, setAccordionValue] = useState<('Focus' | 'Completed')[]>(['Focus', 'Completed']);
    const isMobile = useIsMobile();
    const handleAccordionChange = (value: string[]) => {
        setAccordionValue(value as ('Focus' | 'Completed')[]);
    };

    useEffect(() => {
        if (prospectIdsWithActions.length && !prospectId && !isMobile) {
            navigate(`./messages/${prospectIdsWithActions[0]}`);
        }
    }, [prospectIdsWithActions]);

    return (
        <div className={cn('flex flex-col w-full md:max-w-xs mx-auto h-full max-h-full overflow-hidden border-r')}>
            <Accordion
                type="multiple"
                onValueChange={handleAccordionChange}
                defaultValue={['Focus', 'Completed']}
                className={cn('flex flex-col max-h-full')}
            >
                <ConversationsSectionAccordionItem
                    title={'Focus'}
                    className={cn(accordionValue.includes('Completed') ? 'max-h-[80%]' : 'max-h-[94%]')}
                >
                    <AnimatePresence>
                        {!isLoadingActions &&
                            prospectIdsWithActions?.length === 0 &&
                            prospectIdsWithCompletedActions?.length === 0 && (
                                <div className="flex flex-col items-center w-full text-text-tertiary py-10">
                                    <span className="">You have no actions</span>
                                    <span className="mb-4">Add new contacts or come back later</span>
                                    <Button onClick={() => navigate(paths.NEW.DISCOVER)}>Add contacts</Button>
                                </div>
                            )}
                        {!isLoadingActions &&
                            prospectIdsWithActions?.length === 0 &&
                            prospectIdsWithCompletedActions?.length > 0 && (
                                <div className="flex flex-col items-center w-full text-text-tertiary py-8">
                                    <CheckCircleIcon className="w-8 h-8 mb-3 text-background-success-primary" />
                                    <div className="inline-block">
                                        <span className="">Well done!</span>
                                        <span className=""> You completed all your actions</span>
                                    </div>
                                    <div className="inline-block">
                                        <span>Want more? Check out</span>
                                        <Link to={paths.NEW.DISCOVER} className="text-text-brand-secondary">
                                            {' '}
                                            your recommendations
                                        </Link>
                                    </div>
                                </div>
                            )}
                        {isLoadingActions
                            ? Array.from({ length: 30 }).map((_, index) => (
                                  <ConversationCardSkeleton key={'todo-skeleton-' + index} withBadge />
                              ))
                            : prospectIdsWithActions
                                  .slice(0, 30)
                                  .map((prospectId, index) => (
                                      <ActionCard
                                          prospectId={prospectId}
                                          isCompleted={false}
                                          key={'todo-' + index}
                                          linkPrefix={'./messages'}
                                      />
                                  ))}
                    </AnimatePresence>
                </ConversationsSectionAccordionItem>
                <ConversationsSectionAccordionItem
                    title={'Completed'}
                    lastSection
                    className={'flex-1'}
                    titleEndAdornment={<CompletedActionsCountBadge />}
                >
                    <AnimatePresence>
                        {prospectIdsWithCompletedActions.length ? (
                            prospectIdsWithCompletedActions.map((prospectId, index) => (
                                <ActionCard
                                    prospectId={prospectId}
                                    isCompleted={true}
                                    key={'completed-' + index}
                                    linkPrefix={'./messages'}
                                />
                            ))
                        ) : (
                            <div className="flex flex-col items-center w-full text-text-tertiary">
                                <span className="mt-10 mb-10">No completed actions today</span>
                            </div>
                        )}
                    </AnimatePresence>
                </ConversationsSectionAccordionItem>
            </Accordion>
        </div>
    );
};
