import { z } from 'zod';
import { tool } from 'ai';
import { ProspectDto } from '@zaplify/prospects';
import { LinkedinProfileDto } from '@andsend/services/linkedin-profiles/shared';

const SearchContactsFreeTextToolArgsZ = z.object({
    query: z.string(),
    limit: z.number().optional(),
    skip: z.number().optional(),
    includeMemberIds: z.array(z.string()).optional(),
});

export type SearchContactsFreeTextToolArgs = z.infer<typeof SearchContactsFreeTextToolArgsZ>;

export type SearchContactsFreeTextToolResult = {
    contacts: {
        prospect?: ProspectDto;
        linkedinProfile: LinkedinProfileDto;
    }[];
};

export const SearchContactsFreeTextToolFactory = {
    name: 'searchContactsFreeText',
    makeTool: (execute: (args: SearchContactsFreeTextToolArgs) => Promise<SearchContactsFreeTextToolResult>) =>
        tool({
            description:
                "Search the user's linkedin connections by free text. Search by name, title, headline, etc. Accepts a list of memberIds to include in the search. If no memberIds are provided, all the user's connections will be searched. This is a paginated tool. So you can pass in a limit and skip to get the next page of results.",
            parameters: SearchContactsFreeTextToolArgsZ,
            execute,
        }),
};

const GetContactsByPlaybookToolArgsZ = z.object({
    groupId: z.string(),
    limit: z.number().optional(),
    skip: z.number().optional(),
});

export type GetContactsByPlaybookToolArgs = z.infer<typeof GetContactsByPlaybookToolArgsZ>;

export type GetContactsByPlaybookToolResult = {
    contacts: {
        prospect: ProspectDto;
        linkedinProfile?: LinkedinProfileDto;
    }[];
};

export const GetContactsByPlaybookToolFactory = {
    name: 'getContactsByPlaybook',
    makeTool: (execute: (args: GetContactsByPlaybookToolArgs) => Promise<GetContactsByPlaybookToolResult>) =>
        tool({
            description:
                'Get contacts by playbook and display the result to the user. This is a paginated tool. So you can pass in a limit and skip to get the next page of results. The playbook is identified by the groupId.',
            parameters: GetContactsByPlaybookToolArgsZ,
            execute,
        }),
};

const GetContactsByLinkedinUserIdsToolArgsZ = z.object({
    linkedinUserIds: z.array(z.string()),
});

export type GetContactsByLinkedinUserIdsToolArgs = z.infer<typeof GetContactsByLinkedinUserIdsToolArgsZ>;

export type GetContactsByLinkedinUserIdsToolResult = {
    contacts: {
        prospect: ProspectDto;
        linkedinProfile?: LinkedinProfileDto;
    }[];
};

export const GetContactsByLinkedinUserIdsToolFactory = {
    name: 'getContactsByLinkedinUserIds',
    makeTool: (
        execute: (args: GetContactsByLinkedinUserIdsToolArgs) => Promise<GetContactsByLinkedinUserIdsToolResult>,
    ) =>
        tool({
            description:
                'Get contacts by linkedin user ids (also called memberIds) and display the result to the user. Useful for displaying specific contacts to avoid listing them in text.',
            parameters: GetContactsByLinkedinUserIdsToolArgsZ,
            execute,
        }),
};
