import { CircularProgress } from '@mui/material';
import React from 'react';
import { ProgressBarContainer } from './containers';

export const ProgressBar = ({ show }) => {
    if (!show) {
        return null;
    }
    return (
        <ProgressBarContainer>
            <CircularProgress />
        </ProgressBarContainer>
    );
};
