import { UseQueryOptions } from '@tanstack/react-query';
import { getZaplifySdk } from '@zaplify/sdk';
import { createMutationOptions } from './sdk-utils';
import { ChannelAccountDto, LinkedinDto } from '@zaplify/channel-accounts/shared';
import { CreateChannelAccountDto, ReauthorizeChannelAccountDto } from '@zaplify/channel-accounts/shared';

const fetchMyChannelAccounts = () => {
    return getZaplifySdk().profiles.channelAccount.getMyAccounts();
};

type ChannelAccountsData = Awaited<ReturnType<typeof fetchMyChannelAccounts>>;

export const getMyChannelAccounts = (
    options?: Omit<UseQueryOptions<ChannelAccountsData, Error>, 'queryKey' | 'queryFn'>
): UseQueryOptions<ChannelAccountsData, Error> => {
    return {
        queryKey: ['getMyChannelAccounts'],
        queryFn: () => fetchMyChannelAccounts(),
        ...options,
    };
};

export const updateLinkedinAvatarUrl = (idempotencyConfig?: {
    getCurrentData?: () => Promise<ChannelAccountDto | undefined>;
    isEqual?: (
        current: ChannelAccountDto | undefined,
        variables: { linkedinChannelAccountId: string; avatarUrl: string }
    ) => boolean;
}) => {
    return createMutationOptions<
        void,
        Error,
        { linkedinChannelAccountId: string; avatarUrl: string },
        ChannelAccountDto
    >({
        mutationFn: (variables) =>
            getZaplifySdk().profiles.channelAccount.updateLinkedinAvatarUrl(
                variables.linkedinChannelAccountId,
                variables.avatarUrl
            ),
        invalidateQueries: () => [['getMyChannelAccounts']],
        ...idempotencyConfig,
    });
};

export const setPremiumStatus = (idempotencyConfig?: {
    getCurrentData?: () => Promise<ChannelAccountDto | undefined>;
    isEqual?: (
        current: ChannelAccountDto | undefined,
        variables: { linkedinChannelAccountId: string; isPremium: boolean }
    ) => boolean;
}) => {
    return createMutationOptions<
        void,
        Error,
        { linkedinChannelAccountId: string; isPremium: boolean },
        ChannelAccountDto
    >({
        mutationFn: (variables) =>
            getZaplifySdk().profiles.channelAccount.updateLinkedinPremiumStatus(
                variables.linkedinChannelAccountId,
                variables.isPremium
            ),
        invalidateQueries: () => [['getMyChannelAccounts']],
        ...idempotencyConfig,
    });
};

export const addChannelAccount = () => {
    return createMutationOptions<ChannelAccountDto, Error, CreateChannelAccountDto>({
        mutationFn: (variables) => getZaplifySdk().profiles.user.addChannelAccount(variables.userId, variables),
        invalidateQueries: () => [['getMyChannelAccounts']],
    });
};

export const reauthorizeChannelAccount = () => {
    return createMutationOptions<ChannelAccountDto, Error, { id: string; data: ReauthorizeChannelAccountDto }>({
        mutationFn: (variables) => {
            return getZaplifySdk().profiles.channelAccount.reauthorizeChannelAccount(variables.id, variables.data);
        },
        invalidateQueries: () => [['getMyChannelAccounts']],
    });
};

export const disconnectChannelAccount = () => {
    return createMutationOptions<ChannelAccountDto, Error, { userId: string; channelAccountId: string }>({
        mutationFn: (variables) =>
            getZaplifySdk().profiles.user.disconnectChannelAccount(variables.userId, variables.channelAccountId),
        invalidateQueries: () => [['getMyChannelAccounts']],
    });
};

export const createLinkedinChannelAccount = () => {
    return createMutationOptions<
        ChannelAccountDto,
        Error,
        { LINKEDIN: Required<Omit<LinkedinDto, 'proxy' | 'numberOfConnections' | 'restriction'>>; name: string }
    >({
        mutationFn: (variables) =>
            getZaplifySdk().profiles.channelAccount.createLinkedinChannelAccount(variables.LINKEDIN, variables.name),
        invalidateQueries: () => [['getMyChannelAccounts']],
    });
};

export const updateEmailSignature = (idempotencyConfig?: {
    getCurrentData?: () => Promise<ChannelAccountDto | undefined>;
    isEqual?: (
        current: ChannelAccountDto | undefined,
        variables: { emailChannelAccountId: string; signature: string }
    ) => boolean;
}) => {
    return createMutationOptions<void, Error, { emailChannelAccountId: string; signature: string }, ChannelAccountDto>({
        mutationFn: (variables) =>
            getZaplifySdk().profiles.channelAccount.updateEmailSignature(
                variables.emailChannelAccountId,
                variables.signature
            ),
        invalidateQueries: () => [['getMyChannelAccounts']],
        ...idempotencyConfig,
    });
};

export const resyncEmailSignature = () => {
    return createMutationOptions<{ signature: string }, Error, { channelAccountId: string }>({
        mutationFn: (variables) =>
            getZaplifySdk().profiles.channelAccount.resyncGmailSignature(variables.channelAccountId),
        invalidateQueries: () => [['getMyChannelAccounts']],
    });
};
