import { ChannelAccountStatus } from '@zaplify/channel-accounts/shared';
import { ChannelType } from '@zaplify/channel-accounts/shared';

export function getLinkedInAccount(channelAccounts) {
    if (!channelAccounts) return null;
    const linkedInAccount = channelAccounts.find((account) => account && account.type === ChannelType.LINKEDIN);
    return linkedInAccount;
}

export function isChannelAccountsAuthenticated(channelAccounts): boolean {
    if (!channelAccounts) return true;
    return channelAccounts.every((account) => account.status === ChannelAccountStatus.AUTHENTICATED);
}
