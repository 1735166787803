import { useEditor } from '@tiptap/react';
import { useEffect, useState, useRef } from 'react';
import { useExtensions } from './use-extensions';
import { formatHref } from '../../../functions/format-href';

export const useTextEditor = ({
    content,
    type,
    onBodyTextChange,
    isEditable = true,
    placeholder,
    isStreaming,
    isSending,
    maxStreamingTime = 2000,
}: {
    content: string;
    type: 'plain' | 'html';
    onBodyTextChange: (bodyText: string) => void;
    isEditable: boolean;
    placeholder: string;
    isStreaming: boolean;
    isSending: boolean;
    maxStreamingTime?: number;
}) => {
    const [linkState, setLinkState] = useState<{
        url: string;
        text: string;
        anchor: HTMLElement | null; // Instead of any
    }>({ url: '', text: '', anchor: null });
    const extensions = useExtensions({ placeholder });
    const [isRunningStreaming, setIsRunningStreaming] = useState(false);
    const [streamingContent, setStreamingContent] = useState('');
    const streamingContentRef = useRef('');

    const editor = useEditor(
        {
            extensions,
            editable: true,
            editorProps: {
                attributes: {
                    class: 'prosemirror-editor',
                },
            },
            /**
             * Update Jotai messageComposerAtom with user input text.
             * Prevent setting empty <p></p> tag to allow loadMessageComposerState to set proper state in atom,
             * otherwise composerHasContent in that method is truthy and it will set messageComposerAtom to empty string.
             */
            onUpdate: ({ editor }) => {
                if (isRunningStreaming) {
                    return;
                }
                const newContent = editor.getHTML();
                // Only clean up extra newlines if bullet lists are present
                let text = editor.getText({ blockSeparator: '\n' });
                // Remove extra newlines from bullet lists (tiptap bug)
                if (newContent.includes('<ul>') || newContent.includes('<ol>')) {
                    text = text.replace(/\n{3,}/g, '\n').trim();
                }

                if (newContent !== content) {
                    if (type === 'plain') {
                        onBodyTextChange(text);
                    } else {
                        onBodyTextChange(newContent);
                    }
                }
            },
        },
        [placeholder, isEditable, isSending]
    );

    const openLinkPopover = (event: React.BaseSyntheticEvent) => {
        const { href } = editor?.getAttributes('link') || {};
        if (href) {
            setLinkState({
                url: href as string,
                text: editor?.state.selection.content().content.firstChild?.text || '',
                anchor: event.currentTarget,
            });
            return;
        }
        setLinkState((prev) => ({
            ...prev,
            anchor: event.currentTarget,
        }));
    };

    const moveCursorOutsideOfElement = () => {
        const { state } = editor;
        const { tr } = state;
        const pos = state.selection.$to.pos;
        //@ts-expect-error
        editor.view.dispatch(tr.setSelection(state.selection.constructor.near(tr.doc.resolve(pos))));
    };

    const closeLinkPopover = () => {
        moveCursorOutsideOfElement();
        setLinkState({
            url: '',
            text: '',
            anchor: null,
        });
        editor.commands.focus();
    };

    const handleLinkChange = (event: React.BaseSyntheticEvent) => {
        const linkValue = event.currentTarget.value;
        setLinkState((prev) => ({
            ...prev,
            url: linkValue,
        }));
    };

    const setLink = () => {
        if (!editor) return;

        if (linkState.url === '') {
            editor.chain().focus().extendMarkRange('link').unsetLink().run();
        } else {
            const formattedHref = formatHref(linkState.url);
            const isImage = editor.isActive('image');

            if (isImage) {
                editor.chain().focus().setLink({ href: formattedHref }).focus().run();
            } else if (linkState.text && editor.state.selection.empty) {
                editor.chain().focus().insertContent(`<a href="${formattedHref}">${linkState.text}</a>`).run();
            } else {
                editor.chain().focus().extendMarkRange('link').setLink({ href: formattedHref }).run();
            }
        }

        closeLinkPopover();
    };

    const handleSetContent = (content: string) => {
        if (type === 'plain') {
            if (!content?.startsWith('<p>')) {
                content = `<p>${content.split('\n').join('<br>')}</p>`;
            }
        }
        editor.commands.setContent(content || '');
    };

    /**
     * Update Tiptap editor content when the content actually changes and the editor is not focused.
     */
    useEffect(() => {
        if (editor && !editor.isFocused) {
            if (isStreaming) {
                setIsRunningStreaming(true);
                streamingContentRef.current = '';
                const words = content.split(' ');
                try {
                    editor.setEditable(false);

                    words.forEach((word, index) => {
                        setTimeout(() => {
                            streamingContentRef.current += (index > 0 ? ' ' : '') + word;
                            setStreamingContent(streamingContentRef.current);
                            if (index === words.length - 1) {
                                setTimeout(() => {
                                    setIsRunningStreaming(false);
                                }, 100);
                            }
                        }, 20 * index);
                    });
                } catch (error) {
                    console.error('useTextEditor: error', error);
                } finally {
                    editor.setEditable(true);
                }
            } else {
                handleSetContent(content);
            }
        }
    }, [content, editor, isStreaming]);

    useEffect(() => {
        if (editor && isRunningStreaming) {
            handleSetContent(streamingContent);
            // if (streamingContent === content) {
            //     setIsRunningStreaming(false);
            // }
        }
    }, [streamingContent, editor, isRunningStreaming, content]);

    useEffect(() => {
        if (editor) {
            editor.setEditable(isEditable);
        }
    }, [isEditable, editor]);

    useEffect(() => {
        if (editor && isSending) {
            editor.commands.setContent('<p></p>');
        }
    }, [isSending, editor]);

    return {
        editor,
        openLinkPopover,
        linkState,
        closeLinkPopover,
        handleLinkChange,
        setLink,
        handleSetContent,
        isRunningStreaming,
    };
};
