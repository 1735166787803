import { useQuery } from '@apollo/client';
import { GET_PLAYBOOKS, GetPlaybooksQuery } from '@zaplify/graphql';
import React, { createContext, useContext, useState, useCallback, useMemo } from 'react';

type PlaybooksViewContextType = {
    searchInput: string;
    setSearchInput: (value: string) => void;
    playbooks: GetPlaybooksQuery['Groups'];
};

const PlaybooksViewContext = createContext<PlaybooksViewContextType | undefined>(undefined);

export function PlaybooksViewProvider({ children }: { children: React.ReactNode }) {
    const [searchInput, setSearchInput] = useState('');

    const { data: playbooksData, previousData } = useQuery(GET_PLAYBOOKS, {
        variables: {
            name: '%' + searchInput + '%',
        },
    });

    // Keep the previous data until the new data is loaded to avoid flickering
    const playbooks = useMemo(() => playbooksData?.Groups || previousData?.Groups || [], [playbooksData, previousData]);

    return (
        <PlaybooksViewContext.Provider value={{ playbooks, searchInput, setSearchInput }}>
            {children}
        </PlaybooksViewContext.Provider>
    );
}

export function usePlaybooksView() {
    const context = useContext(PlaybooksViewContext);
    if (context === undefined) {
        throw new Error('usePlaybooksView must be used within a PlaybooksViewProvider');
    }
    return context;
}
