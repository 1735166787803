import { getZaplifySdk } from '@zaplify/sdk';
import { PageViewDto } from '@zaplify/users/shared';

const getAnonymousId = () => {
    const cookies = document.cookie;
    let anonymousId = null;
    const cookiesArray = cookies.split('; ');
    for (let cookie of cookiesArray) {
        if (cookie.startsWith('ajs_anonymous_id=')) {
            anonymousId = cookie.split('=')[1];
            break;
        }
    }

    if (!anonymousId) {
        anonymousId = crypto.randomUUID();
        document.cookie = `ajs_anonymous_id=${anonymousId}; path=/;`;
    }

    return anonymousId;
};

export const createPageView = (userId: string, userOrganizationId: string) => {
    const hostname = window.location.hostname;
    const path = location.pathname;
    const pageTitle = document.title;
    const userAgent = navigator.userAgent;
    const referrer = document.referrer || '';
    const url = window.location.href;
    const search = location.search;
    const anonymousId = getAnonymousId();

    const searchParams = new URLSearchParams(search);

    const pageView: PageViewDto = {
        hostname,
        path,
        pageTitle,
        userId: userId || null,
        anonymousId,
        organizationId: userOrganizationId,
        userAgent,
        referrer,
        url,
        search,
        utm_content: searchParams.get('utm_content'),
        utm_campaign: searchParams.get('utm_campaign'),
        utm_medium: searchParams.get('utm_medium'),
        utm_source: searchParams.get('utm_source'),
        utm_term: searchParams.get('utm_term'),
    };
    return pageView;
};

export const trackPageView = async (pageView: PageViewDto) => {
    const sdk = getZaplifySdk();
    try {
        await sdk.profiles.pageView.recordPageView(pageView);
    } catch (error) {
        console.error('Error calling endpoint:', error);
    }
};
