import { MemoryLevel } from '@zaplify/services/messaging/shared';
import { useSdk } from '../sdk';
import { useUser } from '../hooks/use-user';
import { useQuery, useSuspenseQuery, useMutation } from '@tanstack/react-query';
import { Button } from '@shadcn/ui/components/ui/button';
import { Card, CardContent } from '@shadcn/ui/components/ui/card';
import { Pencil, Trash2, X, Check, ChevronDown, ChevronUp, Info } from 'lucide-react';
import { formatDistanceToNow } from 'date-fns';
import { useState } from 'react';
import { Textarea } from '@shadcn/ui/components/ui/textarea';
import { useMemoryExplainerDialog } from './dialogs/memory-explainer-dialog';

type UserMemoryItemProps = {
    memory: any;
    onDelete: () => void;
    level: MemoryLevel;
    prospectId?: string;
    groupId?: string;
};

const UserMemoryItem = ({ memory, onDelete, level, prospectId, groupId }: UserMemoryItemProps) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedMemoryText, setEditedMemoryText] = useState(memory.memory);
    const { openMemoryExplainerDialog } = useMemoryExplainerDialog();
    const {
        userMemory: { updateUserMemory: updateMemoryMutation },
    } = useSdk();

    const { mutateAsync: updateMemory } = useMutation(updateMemoryMutation());

    const handleEditClick = () => {
        setIsEditing(true);
        setEditedMemoryText(memory.memory);
    };

    const handleCancelEdit = () => {
        setIsEditing(false);
    };

    const handleSaveEdit = async () => {
        await updateMemory({ memoryId: memory.memoryId, memory: editedMemoryText });
        setIsEditing(false);
    };

    return (
        <Card className="relative overflow-hidden border border-border-secondary group">
            {isEditing ? (
                <CardContent className="p-4">
                    <Textarea
                        value={editedMemoryText}
                        onChange={(e) => setEditedMemoryText(e.target.value)}
                        className="min-h-[80px] border border-border-primary rounded-md focus:border-primary focus:ring-primary"
                    />
                    <div className="flex justify-end gap-2 mt-3">
                        <Button variant="outline" size="sm" onClick={handleCancelEdit} className="flex items-center">
                            <X className="h-4 w-4 mr-1" />
                            Cancel
                        </Button>
                        <Button
                            variant="default"
                            size="sm"
                            onClick={handleSaveEdit}
                            className="flex items-center bg-primary text-primary-foreground"
                        >
                            <Check className="h-4 w-4 mr-1" />
                            Save
                        </Button>
                    </div>
                </CardContent>
            ) : (
                <>
                    <CardContent className="p-4">
                        <p className="text-foreground-primary text-sm">{memory.memory}</p>
                        <p className="mt-2 text-xs text-foreground-tertiary">
                            {memory.createdAt
                                ? formatDistanceToNow(new Date(memory.createdAt), { addSuffix: true })
                                : ''}
                        </p>
                    </CardContent>
                    <div className="flex flex-row gap-1 absolute bottom-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity">
                        <Button
                            variant="ghost"
                            size="sm"
                            className="flex items-center justify-center p-2 rounded-full hover:bg-background-primary-hover"
                            onClick={openMemoryExplainerDialog}
                        >
                            <Info className="h-4 w-4 text-text-brand-primary" />
                        </Button>
                        <Button
                            variant="ghost"
                            size="sm"
                            className="flex items-center justify-center p-2 rounded-full hover:bg-background-primary-hover"
                            onClick={handleEditClick}
                        >
                            <Pencil className="h-4 w-4 text-text-brand-primary" />
                        </Button>
                        <Button
                            variant="ghost"
                            size="sm"
                            className="flex items-center justify-center p-2 rounded-full hover:bg-background-primary-hover"
                            onClick={() => onDelete()}
                        >
                            <Trash2 className="h-4 w-4 text-destructive" />
                        </Button>
                    </div>
                </>
            )}
        </Card>
    );
};

export const UserMemoryList = ({
    level,
    prospectId,
    groupId,
    alwaysShowAll = false,
}: {
    level: MemoryLevel;
    prospectId?: string;
    groupId?: string;
    alwaysShowAll?: boolean;
}) => {
    const {
        userMemory: { getUserMemories, deleteUserMemory: deleteUserMemoryMutation },
    } = useSdk();

    const MAX_MEMORIES_PREVIEW = 2;
    const { data: userMemories } = useSuspenseQuery(getUserMemories(level, prospectId, groupId));
    const [showAll, setShowAll] = useState(false);
    const { openMemoryExplainerDialog } = useMemoryExplainerDialog();

    const { mutateAsync: deleteUserMemory } = useMutation(deleteUserMemoryMutation());

    const handleDelete = async (memoryId: string) => {
        await deleteUserMemory({
            memoryId,
            level,
            prospectId,
            groupId,
        });
    };

    const displayedMemories = showAll || alwaysShowAll ? userMemories : userMemories?.slice(0, MAX_MEMORIES_PREVIEW);
    const hasMoreMemories = !alwaysShowAll && userMemories && userMemories.length > MAX_MEMORIES_PREVIEW;

    if (displayedMemories?.length === 0) {
        return (
            <div className="flex flex-col gap-2 items-start w-full">
                <div className="text-foreground-tertiary text-sm bg-background-primary-hover p-4 rounded-md w-full">
                    <div className="mb-2">
                        No memories added yet.
                        {level === 'playbook' &&
                            ' ' +
                                'As you provide feedback on messages, your agent will pick up on your preferences and learn information about you and your contacts'}
                    </div>
                </div>
                <Button variant="text" size="sm" onClick={openMemoryExplainerDialog} className=" p-0 h-auto">
                    <p className="flex items-center gap-1 text-text-brand-primary font-normal text-xs hover:underline">
                        <Info className="h-3 w-3 scale-75" />
                        Learn more about memories
                    </p>
                </Button>
            </div>
        );
    }

    return (
        <div className="space-y-3 overflow-y-auto max-h-[800px]">
            {displayedMemories?.map((memory) => (
                <UserMemoryItem
                    key={memory.memoryId}
                    memory={memory}
                    onDelete={() => handleDelete(memory.memoryId)}
                    level={level}
                    prospectId={prospectId}
                    groupId={groupId}
                />
            ))}

            {hasMoreMemories && (
                <Button
                    variant="text"
                    className="w-full text-text-brand-primary flex items-center justify-start mt-2"
                    onClick={() => setShowAll(!showAll)}
                >
                    {showAll ? 'Show Less' : 'Show More'}
                </Button>
            )}
        </div>
    );
};
