import { getZaplifySdk } from '@zaplify/sdk';
import { atom, useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { LocalStorageKeys } from '../../../config';

const oneDay = 1000 * 60 * 60 * 24 * 1;

// prospectId -> avatarUrl
const prospectAvatarUrlsAtom = atomWithStorage(LocalStorageKeys.PROSPECT_AVATAR_URLS, {
    initializedOnTimestamp: 0,
    avatars: {},
} as {
    initializedOnTimestamp: number;
    avatars: Record<string, string>;
});

export const avatarUrlsAtom = atom((get) => {
    return get(prospectAvatarUrlsAtom)?.avatars;
});

const useProspectAvatars = () => {
    const [prospectAvatarUrls, setProspectAvatarUrls] = useAtom(prospectAvatarUrlsAtom);

    async function setProspectAvatarUrl(prospectId: string, avatarUrl: string) {
        setProspectAvatarUrls((prev) => ({
            ...prev,
            avatars: {
                ...prev.avatars,
                [prospectId]: avatarUrl,
            },
        }));
    }

    async function fetchProspectAvatarUrls(prospectIds: string[]) {
        let avatars = prospectAvatarUrls?.avatars || {};
        try {
            // Invalidate cache after one day
            if (!prospectAvatarUrls.initializedOnTimestamp) {
                setProspectAvatarUrls((prev) => ({
                    ...prev,
                    initializedOnTimestamp: Date.now(),
                }));
            } else if (Date.now() - prospectAvatarUrls.initializedOnTimestamp > oneDay) {
                setProspectAvatarUrls({
                    initializedOnTimestamp: Date.now(),
                    avatars: {},
                });
                avatars = {};
                console.log('Invalidating prospect avatar cache', prospectAvatarUrls);
            }

            // Update only missing prospectIds
            const alreadyFetchedProspectIds = Object.keys(avatars || {});
            const missingProspectIds = prospectIds.filter((id) => !alreadyFetchedProspectIds.includes(id));
            if (!missingProspectIds.length) return;
            console.log('Fetching prospect avatar URLs for', missingProspectIds, 'out of', prospectIds);

            const newAvatarUrls = await getZaplifySdk().profiles.prospects.getAvatarUrls(missingProspectIds);
            setProspectAvatarUrls((prev) => ({
                ...prev,
                avatars: {
                    ...prev.avatars,
                    ...newAvatarUrls,
                },
            }));
        } catch (error) {
            console.error('Failed to fetch prospect avatar URLs', error);
        }
    }

    return {
        prospectAvatarUrls: prospectAvatarUrls.avatars,
        fetchProspectAvatarUrls,
        setProspectAvatarUrl,
    };
};

export default useProspectAvatars;
