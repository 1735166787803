import { FC, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../../providers/authentication-provider';
import { useToast } from '@shadcn/ui/hooks/use-toast';
import { Loader2 } from 'lucide-react';
import { clearLocalStorageByKey, getLocalStorageByKey } from '../../../../services/utils/localStorage';
import { useSdk } from '../../../sdk/use-sdk';
import { useMutation } from '@tanstack/react-query';

// Use the correct local storage key for Salesforce
export const salesforceLocalstorageKey = 'salesforce_environment';

const CallbackPage: FC = () => {
    const { appId } = useParams();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { toast } = useToast();
    const {
        authState: { userOrganizationId },
    } = useAuth();
    const {
        userOrganization: { connectHubspot, connectPipedrive, connectSalesforce },
    } = useSdk();

    const code = searchParams.get('code');

    // Redirect if missing required params
    if (!appId || !userOrganizationId || !code) {
        navigate('/new/account-management/apps');
        return null;
    }

    const loginUrl = getLocalStorageByKey(salesforceLocalstorageKey) || 'https://login.salesforce.com';

    const hubspotMutation = useMutation(
        connectHubspot({
            onSuccess: () => {
                toast({
                    title: 'Hubspot Connected',
                    description: 'Your Hubspot account has been successfully connected.',
                    variant: 'default',
                });
                navigate(`/new/account-management/apps/${appId}`);
            },
            onError: (error) => {
                console.error('Error in Hubspot connection:', error);
                toast({
                    title: 'Hubspot Connection Failed',
                    description: 'Failed to connect Hubspot. Please try again.',
                    variant: 'destructive',
                });
                navigate('/new/account-management/apps');
            },
        })
    );

    const pipedriveMutation = useMutation(
        connectPipedrive({
            onSuccess: () => {
                toast({
                    title: 'Pipedrive Connected',
                    description: 'Your Pipedrive account has been successfully connected.',
                    variant: 'default',
                });
                navigate(`/new/account-management/apps/${appId}`);
            },
            onError: (error) => {
                console.error('Error in Pipedrive connection:', error);
                toast({
                    title: 'Pipedrive Connection Failed',
                    description: 'Failed to connect Pipedrive. Please try again.',
                    variant: 'destructive',
                });
                navigate('/new/account-management/apps');
            },
        })
    );

    const salesforceMutation = useMutation(
        connectSalesforce({
            onSuccess: () => {
                clearLocalStorageByKey(salesforceLocalstorageKey);
                toast({
                    title: 'Salesforce Connected',
                    description: 'Your Salesforce account has been successfully connected.',
                    variant: 'default',
                });
                navigate(`/new/account-management/apps/${appId}`);
            },
            onError: (error) => {
                console.error('Error in Salesforce connection:', error);
                toast({
                    title: 'Salesforce Connection Failed',
                    description: 'Failed to connect Salesforce. Please try again.',
                    variant: 'destructive',
                });
                navigate('/new/account-management/apps');
            },
        })
    );

    useEffect(() => {
        // Only attempt connection if not already in progress or completed
        if (code && !hubspotMutation.isSuccess && !pipedriveMutation.isSuccess && !salesforceMutation.isSuccess) {
            switch (appId.toLowerCase()) {
                case 'hubspot':
                    if (!hubspotMutation.isPending) {
                        hubspotMutation.mutate({ organizationId: userOrganizationId, code });
                    }
                    break;
                case 'pipedrive':
                    if (!pipedriveMutation.isPending) {
                        pipedriveMutation.mutate({ organizationId: userOrganizationId, code });
                    }
                    break;
                case 'salesforce':
                    if (!salesforceMutation.isPending) {
                        salesforceMutation.mutate({
                            organizationId: userOrganizationId,
                            code,
                            loginUrl,
                        });
                    }
                    break;
            }
        }
    }, [code, appId, userOrganizationId]); // Only re-run if these values change

    const isProcessing = hubspotMutation.isPending || pipedriveMutation.isPending || salesforceMutation.isPending;

    return (
        <div className="flex flex-col items-center justify-center h-[calc(100vh-120px)]">
            {isProcessing && (
                <>
                    <Loader2 className="h-12 w-12 text-primary animate-spin mb-4" />
                    <h3 className="text-lg font-medium mb-2">Connecting to {appId}</h3>
                    <p className="text-gray-500 text-center mb-6">
                        Please wait while we complete the connection process...
                    </p>
                </>
            )}
        </div>
    );
};

export default CallbackPage;
