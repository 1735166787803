import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProspectStatus, WorkflowStepDto } from '@zaplify/campaigns';
import { ProspectSubStatus } from '@zaplify/campaigns';
import { CompanyDto } from '@zaplify/prospects';
import { ProspectManagementDto } from '@zaplify/campaigns';
import { CampaignProspectDto } from '@zaplify/campaigns';
import { CampaignProspectFinishedReasons } from '@zaplify/campaigns';
import { ProspectViews } from '../../../services/utils/constants';
import { copyObjectWithNewValues } from '../../../services/utils/object';
import { ProspectsSlice } from './types';

const prefix = 'prospects';

const initialProspectsCountByStatus: { [key in ProspectStatus]: number } = {
    ACTIVE: 0,
    COMPLETED: 0,
    INACTIVE: 0,
    ON_HOLD: 0,
    QUEUED: 0,
    IN_REVIEW: 0,
};

const initialProspectsCountByFinishedReason: { [key in CampaignProspectFinishedReasons]: number } = {
    EMAIL_BOUNCED: 0,
    EMAIL_AUTO_REPLY: 0,
    MISSING_EMAIL_ADDRESS: 0,
    MISSING_LINKEDIN_URL: 0,
    LI_PROFILE_UNAVAILABLE: 0,
    ALREADY_CONNECTED: 0,
    CONNECTION_REQUEST_REQUIRES_EMAIL: 0,
    NOT_CONNECTED: 0,
    COMPLETED_WORKFLOW_NO_REPLY: 0,
    REPLIED: 0,
    EMAIL_UNSUBSCRIBED: 0,
    UNKNOWN: 0,
};

export const initialStatusFilters: ProspectStatus[] = [
    ProspectStatus.ACTIVE,
    ProspectStatus.COMPLETED,
    ProspectStatus.IN_REVIEW,
    ProspectStatus.INACTIVE,
    ProspectStatus.QUEUED,
    ProspectStatus.ON_HOLD,
];

const initialWorkflowTypesAll = {
    EMAIL_MESSAGE: false,
    EMAIL_TRIGGER: false,
    LINKEDIN_ACTIVITY_SCANNER: false,
    LINKEDIN_CONNECTION_REQUEST: false,
    LINKEDIN_LIKE_POST: false,
    LINKEDIN_MESSAGE: false,
    LINKEDIN_PROFILE_VISIT: false,
};

const initialState: ProspectsSlice = {
    companiesWithProspects: [],
    prospects: {},
    prospectsInVerification: {},
    prospectsOnAllPages: {},
    prospectsByCompany: {},
    companies: [],
    selected: {},
    pagesCount: 0,
    prospectsCount: 0,
    numberOfProspectsInVerification: 0,
    statusFilters: [],
    finishedReasonsFilters: [],
    workflowAllPossibleSteps: initialWorkflowTypesAll,
    fullName: '',
    order: 'asc',
    orderBy: 'data.organizationName',
    prospectDrawerProspect: null,
    prospectDrawerState: false,
    loading: false,
    page: 0,
    rowsPerPage: 50,
    view: ProspectViews.companies,
    prospectsCountByStatus: initialProspectsCountByStatus,
    prospectsCountByFinishedReason: initialProspectsCountByFinishedReason,
    workflowSteps: [],
    loadingState: 'idle',
    totalProspectsCount: 0,
};

const prospectsSlice = createSlice({
    name: prefix,
    initialState,
    reducers: {
        updateCompaniesWithProspects: (
            state,
            action: PayloadAction<{
                companiesWithProspects: {
                    company: CompanyDto;
                    id: string;
                    prospects?: CampaignProspectDto[];
                }[];
            }>
        ) => {
            state.companiesWithProspects = action.payload.companiesWithProspects;
        },
        updateProspectsState: (
            state,
            action: PayloadAction<
                Omit<ProspectManagementDto, 'prospects'> & {
                    prospects: { [key: string]: CampaignProspectDto };
                }
            >
        ) => {
            state.prospects = action.payload.prospects || {};
            state.prospectsByCompany = action.payload.prospectsByCompany || {};
            state.companies = action.payload.companies || [];
            state.pagesCount = action.payload.pagesCount || 1;
            state.prospectsCount = action.payload.prospectsCount || 0;
            state.prospectsCountByStatus = action.payload.prospectsCountByStatus || initialProspectsCountByStatus;
            state.prospectsCountByFinishedReason = action.payload.prospectsCountByFinishedReasons;
            state.totalProspectsCount = action.payload.totalProspectsCount;
        },
        setProspectsOnAllPages: (
            state: ProspectsSlice,
            action: PayloadAction<{ [prospectId: string]: CampaignProspectDto }>
        ) => {
            state.prospectsOnAllPages = action.payload;
        },
        updateNameFilter: (state: ProspectsSlice, action: PayloadAction<string>) => {
            state.fullName = action.payload; // previously "fullName: fullName" was passed
        },
        selectProspect: (state: ProspectsSlice, action: PayloadAction<CampaignProspectDto>) => {
            let tempSelected = {};
            if (state.selected[action.payload?.id]) {
                tempSelected = { ...state.selected };
                delete tempSelected[action.payload?.id];
            } else tempSelected = { ...state.selected, [action.payload?.id]: true };

            state.selected = tempSelected;
        },
        selectAllOnPage: (state: ProspectsSlice, action: PayloadAction<boolean>) => {
            // previously "checked: checked" was passed
            let newSelects;
            if (action.payload) {
                newSelects = { ...state.selected, ...copyObjectWithNewValues(state.prospects) };
            } else {
                newSelects = { ...state.selected };
                Object.keys(state.prospects).forEach((prospectId) => delete newSelects[prospectId]);
            }
            state.selected = newSelects;
        },
        updateSelected: (state: ProspectsSlice, action: PayloadAction<{ [prospectId: string]: boolean }>) => {
            state.selected = Object.keys(action.payload)?.length > 0 ? action.payload : {};
        },
        selectAllInCompany: (state: ProspectsSlice, action: PayloadAction<{ companyName: string }>) => {
            let newSelects2;
            const prospectsByCompany = { ...state.prospectsByCompany };
            const prospectIdsInCompany = [...prospectsByCompany[action.payload.companyName]];
            const allProspectsSelected = prospectIdsInCompany.every((p) => {
                if (state.prospectsOnAllPages[p]?.status === ProspectStatus.IN_REVIEW) return true;
                return state.selected[p];
            });

            if (!allProspectsSelected) {
                const newProspects = {};
                prospectIdsInCompany.forEach((prospectId) => {
                    const prospectIsInReview =
                        state.prospectsOnAllPages[prospectId]?.status === ProspectStatus.IN_REVIEW;
                    if (!prospectIsInReview) newProspects[prospectId] = true;
                });
                newSelects2 = { ...state.selected, ...newProspects };
            } else {
                newSelects2 = { ...state.selected };
                prospectIdsInCompany.forEach((prospectId) => delete newSelects2[prospectId]);
            }
            state.selected = newSelects2;
        },
        setSort: (state: ProspectsSlice, action: PayloadAction<{ order: 'asc' | 'desc'; orderBy: string }>) => {
            state.order = action.payload.order;
            state.orderBy = action.payload.orderBy;
        },
        updateProspectDrawerProspect: (state: ProspectsSlice, action: PayloadAction<string>) => {
            state.prospectDrawerProspect = action.payload;
        },
        updateLoadingState: (state: ProspectsSlice, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        updateProspectsPage: (state: ProspectsSlice, action: PayloadAction<number>) => {
            state.page = action.payload;
        },
        updateProspectsPerPage: (state: ProspectsSlice, action: PayloadAction<number>) => {
            state.rowsPerPage = action.payload;
        },
        updateProspectsView: (state: ProspectsSlice, action: PayloadAction<ProspectViews>) => {
            state.view = action.payload;
        },
        updateProspectsInVerification: (
            state: ProspectsSlice,
            action: PayloadAction<{ [key: string]: CampaignProspectDto }>
        ) => {
            state.prospectsInVerification = action.payload;
        },
        addProspectInVerification: (state: ProspectsSlice, action: PayloadAction<CampaignProspectDto>) => {
            state.prospectsInVerification[action.payload.id] = action.payload;
        },
        deleteProspectInVerification: (state: ProspectsSlice, action: PayloadAction<string>) => {
            delete state.prospectsInVerification[action.payload];
        },
        updateProspectsCountsByStatus: (
            state: ProspectsSlice,
            action: PayloadAction<{
                prospectsCountByStatus: Partial<{ [key in ProspectStatus]: number }>;
            }>
        ) => {
            state.prospectsCountByStatus = {
                ...state.prospectsCountByStatus,
                ...action.payload.prospectsCountByStatus,
            };
        },
        updateProspectsCountsBySubStatus: (
            state: ProspectsSlice,
            action: PayloadAction<{
                prospectsCountBySubStatus: Partial<{ [key in ProspectSubStatus]: number }>;
            }>
        ) => {
            state.prospectsCountByFinishedReason = {
                ...state.prospectsCountByFinishedReason,
                ...action.payload.prospectsCountBySubStatus,
            };
        },
        updateProspectsByCompany: (
            state: ProspectsSlice,
            action: PayloadAction<{ companyName: string; prospects: string[] }>
        ) => {
            if (!state.prospectsByCompany[action.payload.companyName]) {
                state.prospectsByCompany[action.payload.companyName] = [];
            }

            state.prospectsByCompany[action.payload.companyName] = [
                ...state.prospectsByCompany[action.payload.companyName],
                ...action.payload.prospects,
            ];
        },
        updateWorkflowSteps: (state: ProspectsSlice, action: PayloadAction<WorkflowStepDto[]>) => {
            state.workflowSteps = action.payload;
        },
        updateNumberOfProspectsInVerification: (state: ProspectsSlice, action: PayloadAction<number>) => {
            state.numberOfProspectsInVerification = action.payload;
        },
        resetProspectsState: () => {
            return initialState;
        },
        updateFinishedReasonsFilters: (
            state: ProspectsSlice,
            action: PayloadAction<CampaignProspectFinishedReasons[]>
        ) => {
            state.finishedReasonsFilters = action.payload;
        },
        updateStatusFilters: (state: ProspectsSlice, action: PayloadAction<ProspectStatus[]>) => {
            state.statusFilters = action.payload;
        },
        updateFilters: (
            state: ProspectsSlice,
            action: PayloadAction<{
                statusFilters: ProspectStatus[];
                finishedReasonsFilters: CampaignProspectFinishedReasons[];
            }>
        ) => {
            state.statusFilters = action.payload.statusFilters;
            state.finishedReasonsFilters = action.payload.finishedReasonsFilters;
        },
        deleteProspectsById: (state: ProspectsSlice, action: PayloadAction<string[]>) => {
            const prospects = { ...state.prospects };
            const prospectsOnAllPages = { ...state.prospectsOnAllPages };
            const prospectsByCompany = { ...state.prospectsByCompany };
            const selected = { ...state.selected };
            const prospectsCountByStatus = { ...state.prospectsCountByStatus };
            const prospectsCountByFinishedReason = { ...state.prospectsCountByFinishedReason };
            const companiesWithProspects = [...state.companiesWithProspects];

            action.payload.forEach((prospectId) => {
                prospectsCountByStatus[prospects[prospectId]?.status] -= 1;
                prospects[prospectId]?.finishedReasons?.forEach((reason) => {
                    prospectsCountByFinishedReason[reason] -= 1;
                });
                delete prospects[prospectId];
                delete prospectsOnAllPages[prospectId];
                Object.keys(prospectsByCompany).forEach((companyName) => {
                    prospectsByCompany[companyName] = prospectsByCompany[companyName].filter((p) => p !== prospectId);
                    if (prospectsByCompany[companyName].length === 0) {
                        delete prospectsByCompany[companyName];
                    }
                });
                companiesWithProspects.forEach((companyWithProspects, idx) => {
                    companyWithProspects.prospects = companyWithProspects.prospects?.filter((p) => p.id !== prospectId);
                    if (companyWithProspects.prospects?.length === 0) {
                        companiesWithProspects.splice(idx, 1);
                    }
                });
                delete selected[prospectId];
            });

            state.prospects = prospects;
            state.prospectsOnAllPages = prospectsOnAllPages;
            state.prospectsByCompany = prospectsByCompany;
            state.selected = selected;
            state.prospectsCountByStatus = prospectsCountByStatus;
            state.prospectsCountByFinishedReason = prospectsCountByFinishedReason;
            state.companiesWithProspects = companiesWithProspects;
            state.prospectsCount = state.prospectsCount - action.payload.length;
        },
        setLoadingState: (state: ProspectsSlice, action: PayloadAction<'idle' | 'selecting-all'>) => {
            state.loadingState = action.payload;
        },
    },
});

export const {
    updateCompaniesWithProspects,
    updateProspectsState,
    setProspectsOnAllPages,
    updateNameFilter,
    selectProspect,
    selectAllOnPage,
    updateSelected,
    selectAllInCompany,
    setSort,
    updateProspectDrawerProspect,
    updateLoadingState,
    updateProspectsPage,
    updateProspectsPerPage,
    updateProspectsView,
    updateProspectsInVerification,
    addProspectInVerification,
    deleteProspectInVerification,
    updateProspectsCountsByStatus,
    updateProspectsCountsBySubStatus,
    updateProspectsByCompany,
    updateWorkflowSteps,
    updateNumberOfProspectsInVerification,
    resetProspectsState,
    updateFinishedReasonsFilters,
    updateStatusFilters,
    updateFilters,
    deleteProspectsById,
    setLoadingState,
} = prospectsSlice.actions;

export default prospectsSlice.reducer;
