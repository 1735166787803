import { ProspectDataDto, ProspectDto } from '@zaplify/prospects';
import { BaseSdk } from '../../base.sdk';
import { LinkedinUserActivitiesDto } from '@zaplify/campaigns';
import { CompanyResearchSummaryDto, UserMemoryDto, MemoryLevel } from '@zaplify/services/messaging/shared';

export class MessengerSdk extends BaseSdk {
    async setupMessenger(language?: string) {
        return this.post<{ playbookId: string; assistantSettingsId: string }>({
            path: `${this.messengerPath}/setup`,
            payload: language ? { groupSettings: { languages: [language] } } : {},
        });
    }

    async importLinkedinAnalysis(body: {
        profiles: {
            profile: ProspectDataDto;
            activities: LinkedinUserActivitiesDto;
        }[];
        assistantId: string;
    }): Promise<ProspectDto[]> {
        return this.post<ProspectDto[]>({
            path: `${this.messengerPath}/linkedin-conversation-analysis`,
            payload: body,
        });
    }

    async researchCompanyByDomain(domain: string) {
        return this.post<CompanyResearchSummaryDto>({
            path: `${this.messengerPath}/research-company-by-domain`,
            payload: { domain },
        });
    }

    async getUserMemories(level: MemoryLevel, prospectId?: string, groupId?: string) {
        return this.get<UserMemoryDto[]>(`${this.messengerPath}/user-memories`, {
            params: { level, prospectId, groupId },
        });
    }

    async updateUserMemory(memoryId: string, memory: string) {
        return this.post<UserMemoryDto>({
            path: `${this.messengerPath}/user-memory`,
            payload: { memoryId, memory },
        });
    }

    async deleteUserMemory(memoryId: string) {
        return this.delete<void>(`${this.messengerPath}/user-memory/${memoryId}`);
    }
}
