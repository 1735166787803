import { IEvent, Topics } from '@zaplify/common';

export class OutreachSuggestionMessageCreatedEvent extends IEvent {
    public static override readonly TOPIC: Topics = Topics.CAMPAIGNS;
    public readonly userId: string;
    public readonly organizationId: string;
    public readonly outreachSuggestionId: string;
    public readonly messageId: string;
    public readonly prospectId: string;
    public readonly assistantId?: string;
    public readonly fromReply?: boolean;

    constructor(params: {
        userId: string;
        organizationId: string;
        outreachSuggestionId: string;
        messageId: string;
        prospectId: string;
        metadata: {
            assistantId?: string;
            fromReply?: boolean;
        };
    }) {
        super();
        this.userId = params.userId;
        this.organizationId = params.organizationId;
        this.outreachSuggestionId = params.outreachSuggestionId;
        this.messageId = params.messageId;
        this.prospectId = params.prospectId;
        this.assistantId = params.metadata?.assistantId;
        this.fromReply = Boolean(params.metadata?.fromReply);
    }

    get partitionKey(): string {
        return this.prospectId;
    }
}
