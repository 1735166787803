import { IEvent, Topics } from '@zaplify/common';

export class ConversationMetaDataCreatedEvent extends IEvent {
    public static override readonly TOPIC: Topics = Topics.CAMPAIGNS;
    public readonly userId: string;
    public readonly conversationAnalysisId: string;
    public readonly prospectId: string;
    public readonly userContactId: string;

    constructor(params: { userId: string; conversationAnalysisId: string; prospectId: string; userContactId: string }) {
        super();
        this.userId = params.userId;
        this.conversationAnalysisId = params.conversationAnalysisId;
        this.userContactId = params.userContactId;
        this.prospectId = params.prospectId;
    }

    get partitionKey(): string {
        return this.userId;
    }
}
