import { emptyInitialDays, emptyInitialTimeStates } from '../../../services/utils/constants';
import { getDayOffset, getUTCToLocalTime } from '../../../services/utils/time-helper';

export function getInitialLocalTime(incomingTimeArray) {
    //Creating a deep copy of the emptyInitialTimeStates array
    const time = emptyInitialTimeStates.map((obj) => ({ ...obj }));

    //Getting the local times, from the UTC time in the incomingTimeArray
    const localFromTime = getUTCToLocalTime([incomingTimeArray[0], incomingTimeArray[1]]);
    const localToTime = getUTCToLocalTime([incomingTimeArray[2], incomingTimeArray[3]]);

    //Setting the value of the timeArray to local times
    time[0].value = localFromTime[0];
    time[1].value = localFromTime[1];
    time[2].value = localToTime[0];
    time[3].value = localToTime[1];

    return time;
}

export function getInitialLocalDays(incomingTimeArray, weekDays, offset) {
    //Creating a deep copy of the emptyInitialDays & emptyInitialTimeStates array
    const returnDays = emptyInitialDays.map((obj) => ({ ...obj }));
    const utcTimeArray = emptyInitialTimeStates.map((obj) => ({ ...obj }));

    //Setting the value of the utcTimeArray to incoming UTC times
    utcTimeArray[0].value = incomingTimeArray[0];
    utcTimeArray[1].value = incomingTimeArray[1];
    utcTimeArray[2].value = incomingTimeArray[2];
    utcTimeArray[3].value = incomingTimeArray[3];

    //Getting the day offset
    const dayOffset = getDayOffset(utcTimeArray, -offset);

    //Looping over the weekdays, setting the day in the returnDays array according to incoming days and day offset
    for (let i = 0; i < weekDays.length; i++) {
        if (weekDays[i] + dayOffset > 6) returnDays[0].checked = true;
        else if (weekDays[i] + dayOffset < 0) returnDays[6].checked = true;
        else returnDays[weekDays[i] + dayOffset].checked = true;
    }

    return returnDays;
}
