import { Card } from '@shadcn/ui/components/ui/card';
import { LinkedinPersonList } from './linkedin-person-list';
import { TypeSwitcher } from './type-switcher';
import { SearchInput } from './linkedin-search-input';
import { ToggleHiddenContacts } from './toggle-hidden-contacts';
import { useIsMobile } from '@shadcn/ui/hooks/use-mobile';

export const ImportLinkedinView = () => {
    const isMobile = useIsMobile();
    if (isMobile) {
        return (
            <div className="pt-6">
                <SearchViewTop />
                <div className="space-y-4">
                    <LinkedinPersonList />
                </div>
            </div>
        );
    }
    return (
        <div className="pt-6">
            <SearchViewTop />
            <div className="space-y-4">
                <LinkedinPersonList />
            </div>
        </div>
    );
};

const SearchViewTop = () => {
    return (
        <div className="flex flex-col gap-4 mb-6">
            <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4">
                <TypeSwitcher />
                <SearchInput />
            </div>
            <ToggleHiddenContacts />
        </div>
    );
};
