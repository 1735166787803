import { IEvent, Topics } from '@zaplify/common';
import { ProspectDto } from '../prospects';

export class ProspectEnrichedEvent extends IEvent {
    public static override readonly TOPIC: Topics = Topics.PROSPECTS;
    constructor(readonly prospect: ProspectDto) {
        super();
    }

    get partitionKey(): string {
        return this.prospect.userOrganizationId;
    }
}
