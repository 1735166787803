import ReferralHero from './components/referral-hero';
import { useParams } from 'react-router-dom';
import { useSuspenseQuery } from '@tanstack/react-query';
import { IReferralPageDto } from '@zaplify/users/shared';
import { apiEndpoint } from '@zaplify/sdk';
import { AuthHeader } from './components/auth-header';
import { cacheSignupParams } from '../../functions/referral-handling';

// import { LoginForm } from './components/login-form';
// import liOnboardingBackground from './assets/li-onboarding-background.jpg';
// import { BackgroundGradientAnimation } from '@shadcn/ui/components/background-gradient';
// import { AndSendLogotype } from '../../../components/icons/and-send/andsend-logo';

// import { AndSendIcon } from '../../../components/icons/and-send/andsend-icon';
// import { AnimatedRipples, AnimatedEllipticalRipples } from './components/gradient';
// This would normally come from an API call using the referral ID
// For now, we'll mock the data

const getReferralData = async (id: string, signal?: AbortSignal) => {
    const response = await fetch(`${apiEndpoint}/social/referral-page/${id}`, { signal });
    return response.json() as Promise<IReferralPageDto>;
};

// export const generateMetadata = ({ params }: { params: { id: string } }): Metadata => {
//     const referralData = getMockReferralData(params.id);
//     const name = `${referralData.userData.firstName} ${referralData.userData.lastName}`;

//     return {
//         title: `Join AndSend via ${name}'s Invitation`,
//         description: `${name} has invited you to try AndSend, the AI-powered Customer Relationship Agent that helps you focus on the contacts that matter.`,
//     };
// };

export const ReferralPage = () => {
    const { userHandle } = useParams();
    const { data: referralData, isLoading } = useSuspenseQuery({
        queryKey: ['referralPage', userHandle],
        queryFn: async ({ signal }) => getReferralData(userHandle, signal),
    });

    if (isLoading) {
        return <div>Loading...</div>;
    }

    cacheSignupParams({ referralLinkId: referralData.referralLinkId });

    return (
        <div className="min-h-screen text-gray-900 dark:text-white overflow-hidden">
            <div className="fixed inset-0 z-[-1] bg-background-primary-solid dark:bg-black">
                <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-blue-100/50 via-gray-50 to-gray-50 dark:from-purple-900/20 dark:via-black dark:to-black"></div>
                <div className="absolute top-0 right-0 w-full h-full bg-gradient-to-bl from-teal-100/30 via-blue-100/30 to-purple-100/30 dark:from-teal-500/10 dark:via-purple-500/10 dark:to-orange-500/10 blur-3xl opacity-30"></div>
            </div>
            <div className="flex flex-col gap-4 p-6 md:p-10 z-10">
                <AuthHeader />
            </div>
            <div className="mx-auto px-4 md:px-8 py-6 md:py-16">
                <div className="flex flex-col items-center justify-center py-24">
                    {referralData.userData ? (
                        <ReferralHero
                            referralData={referralData}
                            referrerName={`${referralData.userData.firstName} ${referralData.userData.lastName}`}
                        />
                    ) : (
                        <>Sorry, we couldn't find that business card 😯</>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ReferralPage;

// export const ReferralPage = () => {
//     return (
//         <div className="grid min-h-svh lg:grid-cols-1 bg-background-primary-solid">
//             {/* <AnimatedEllipticalRipples /> */}
//             {/* <AnimatedRipples /> */}
//             <div className="flex flex-col gap-4 p-6 md:p-10 z-10">
//                 <div className="flex justify-center gap-2 md:justify-start">
//                     <a href="https://www.andsend.com" className="flex items-center gap-2 font-medium">
//                         <AndSendLogotype variation="transparent_black" className="h-8" />
//                     </a>
//                 </div>
//                 <div className="flex flex-1 items-center justify-center">
//                     <div className="w-full max-w-md">
//                         <LoginForm />
//                     </div>
//                 </div>
//             </div>
//             {/* <div className="relative hidden bg-muted lg:block"> */}
//             {/* <AnimatedRipples /> */}
//             {/* <img
//                     src={liOnboardingBackground}
//                     alt="Image"
//                     className="absolute inset-0 h-full w-full object-cover dark:brightness-[0.2] dark:grayscale"
//                 /> */}
//             {/* <BackgroundGradientAnimation
//                     gradientBackgroundStart="rgb(108, 0, 162)"
//                     gradientBackgroundEnd="rgb(0, 17, 82)"
//                     interactive={false}
//                     containerClassName="absolute inset-0 h-full w-full object-cover dark:brightness-[0.2] dark:grayscale rounded-none shadow-none"
//                     className="absolute inset-0 h-full w-full object-cover dark:brightness-[0.2] dark:grayscale rounded-none shadow-none"
//                 >
//                     <div className="w-full flex justify-center items-center">
//                         <AndSendIcon variation="color" className="h-8" />
//                     </div>
//                 </BackgroundGradientAnimation> */}
//             {/* </div> */}
//         </div>
//     );
// };
