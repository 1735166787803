import { Box } from '@mui/material';

export const SplitView = ({ leftComponent, rightComponent }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                width: '100%',
                minHeight: '100%',
                maxHeight: '100vh',
            }}
        >
            <Box
                sx={{
                    width: '60%',
                    paddingLeft: '80px',
                    paddingTop: '150px',
                    display: 'flex',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                        gap: '32px',
                    }}
                >
                    {leftComponent}
                </Box>
            </Box>

            <Box
                sx={{
                    width: '40%',
                    position: 'relative',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {rightComponent}
            </Box>
        </Box>
    );
};
