import { TaskDetailsDto, TaskDto, UpcommingTaskDto, TaskStatusDto } from '@zaplify/campaigns';

import { BaseSdk } from '../../base.sdk';
import { AwaitingTasksDto } from '@zaplify/campaigns';

export class TasksSdk extends BaseSdk {
    async getAwaitingTasks(): Promise<AwaitingTasksDto | null> {
        return await this.get<AwaitingTasksDto>(`${this.tasksPath}/awaiting`);
    }

    async getUpcomingTasks(): Promise<UpcommingTaskDto[] | null> {
        return await this.get<UpcommingTaskDto[]>(`${this.tasksPath}/upcoming`);
    }

    async getTaskDetails(taskId: string): Promise<TaskDetailsDto | null> {
        return await this.get<TaskDetailsDto>(`${this.tasksPath}/${taskId}/details`);
    }

    async setTaskComplete(taskId: string, result: TaskStatusDto) {
        return await this.post<TaskStatusDto>({ path: `${this.tasksPath}/${taskId}/complete`, payload: result });
    }

    async setTaskFail(taskId: string, result: TaskStatusDto) {
        return await this.post<TaskStatusDto>({
            path: `${this.tasksPath}/${taskId}/fail`,
            payload: { ...result, retry: true },
        });
    }

    async setTaskFailNoRetry(taskId: string, result: TaskStatusDto) {
        return await this.post<TaskStatusDto>({
            path: `${this.tasksPath}/${taskId}/fail`,
            payload: { ...result, retry: false },
        });
    }

    async skipTask(id: string) {
        return await this.post({ path: `${this.tasksPath}/${id}/skip` });
    }

    /** The html should be compressed using gzip (using e.g. the pako lib). */
    async saveHtml(taskId: string, compressedHtml: string) {
        return await this.post<void>({ path: `${this.tasksPath}/${taskId}/save-html`, payload: { compressedHtml } });
    }
}
