import { IUntypedCommand } from '@zaplify/common';
import { Topics } from '@zaplify/common';

export class UpdateOrganizationSettingsCommand extends IUntypedCommand {
    public static override readonly TOPIC = Topics.USERS;

    constructor(
        readonly id: string,
        readonly organizationName: string,
        readonly domain: string,
        readonly website: string,
    ) {
        super();
    }
}
