import { FC } from 'react';
import { Search } from 'lucide-react';
import { Input } from '@shadcn/ui/components/ui/input';
import { usePlaybooksView } from '../hooks/use-playbooks-view';

export const SearchBar: FC = () => {
    const { searchInput, setSearchInput } = usePlaybooksView();
    return (
        <div className="flex gap-1 items-center bg-background-tertiary rounded-xl px-2 max-w-72">
            <Search className="text-text-tertiary w-4 h-4" />
            <Input
                placeholder="Search playbooks..."
                className="border-none shadow-none focus-visible:ring-0"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
            />
        </div>
    );
};
