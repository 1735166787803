import { ICommand, Topics } from '@zaplify/common';
import { CreateMessageDto, MessageDto } from '../dtos';

export class CreateMessageCommand extends ICommand<MessageDto | null> {
    public static override readonly TOPIC = Topics.CAMPAIGNS;

    constructor(public readonly createMessageDto: CreateMessageDto) {
        super();
    }
}
