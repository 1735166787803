import { styled } from '@mui/styles';

const SearchHistoryRowStyled = styled('div')(({ theme: { palette } }: any) => ({
    marginBottom: '5px',
    borderRadius: '4px',
    padding: '10px 8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& .MuiTypography-root': {
        fontSize: '14px',
        color: palette.colors.grey,
        fontWeight: '400',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    '&:hover': {
        backgroundColor: palette.colors.whiteDark,
        cursor: 'pointer',
    },
    '& .launch': {
        marginLeft: '15px',
        fontSize: '17px',
        color: palette.colors.lightGrey,
    },
}));

export default SearchHistoryRowStyled;
