import { styled } from '@mui/styles';

export const ImportProspectsViewContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    overflow: 'auto',
    boxSizing: 'border-box',
    position: 'relative',
    height: '100%',
}));

export const ImportProspectsStepViewContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    overflow: 'auto',
    width: '100%',
    justifyContent: 'center',
    paddingBottom: '50px',
    alignItems: 'center',
}));

export const NavigationBarContainer = styled('div')(({ theme }) => ({
    position: 'absolute',
    zIndex: 2,
    bottom: '0',
    left: '0',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    padding: '4px',
    background: 'white',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    '& > div:first-child': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '& > .import-prospects-navigation-text-bold': {
            fontWeight: 500,
        },
        '& > .import-prospects-navigation-text-thin': {
            fontWeight: 300,
        },
        '& > p': {
            marginLeft: 4,
        },
    },
    '& > div:nth-child(2)': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '& > .import-prospects-next-button': {
            marginLeft: 10,
            ' & > .loader': {
                color: 'white',
            },
            '& > .uploading': {
                background: '#FFFFFF',
                border: '1px solid #E4E6EB',
                color: '#5F8EFA',
            },
        },
    },
}));
