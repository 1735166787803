import { Topics } from '@zaplify/common';

export class MigrateUserResourcesCommand {
    public static readonly TOPIC = Topics.USERS;

    constructor(
        public readonly source: {
            userId?: string;
            userOrganizationId?: string;
        },
        public readonly target: {
            userId?: string;
            userOrganizationId?: string;
        },
        public readonly resources: [],
    ) {}
}
