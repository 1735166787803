import { IEvent, Topics } from '@zaplify/common';

export class PrimaryProspectJobPositionChangedEvent extends IEvent {
    public static override readonly TOPIC: Topics = Topics.PROSPECTS;
    constructor(
        readonly prospectId: string,
        readonly userOrganizationId: string,
        readonly oldExperienceId: string,
        readonly newExperienceId: string,
    ) {
        super();
    }

    get partitionKey(): string {
        return this.userOrganizationId;
    }
}
