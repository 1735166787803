import { ICommand, Topics } from '@zaplify/common';

export class DeleteUserMemoryCommand extends ICommand<void> {
    public static override readonly TOPIC = Topics.CAMPAIGNS;
    public readonly memoryId: string;
    public readonly userId: string;

    constructor({ memoryId, userId }: { memoryId: string; userId: string }) {
        super();
        this.memoryId = memoryId;
        this.userId = userId;
    }
}
