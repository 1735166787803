import { Avatar, AvatarFallback, AvatarImage } from '@shadcn/ui/components/ui/avatar';
import { Button } from '@shadcn/ui/components/ui/button';
import { ScrollArea } from '@shadcn/ui/components/ui/scroll-area';
import { Skeleton } from '@shadcn/ui/components/ui/skeleton';
import { ContactSource } from '@zaplify/services/user-contacts/shared';
import { LinkedinPerson, LinkedinSearchType, useLinkedinSearch } from '../hooks/use-linkedin-search';
import { ContactOwnersAvatarDisplay } from '../../../../components/dialogs/global-search/contact-owners-avatar-display';
import { AlertCircle, InboxIcon, Loader2 } from 'lucide-react';
import { AddContactButton } from '../../../../components/buttons/add-contact-button';
import { useRef, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { LINKEDIN_SEARCH_TERM_PARAM } from './linkedin-search-input';
import { LINKEDIN_SEARCH_TYPE_PARAM } from './type-switcher';
import { useContactOwners } from '../../../../hooks/use-contact-owners';
import { HIDE_ADDED_CONTACTS_PARAM } from './toggle-hidden-contacts';

export const LinkedinPersonList = () => {
    const [searchParams] = useSearchParams();
    const searchType = (searchParams.get(LINKEDIN_SEARCH_TYPE_PARAM) as LinkedinSearchType) || 'inbox';
    const searchTerm = searchParams.get(LINKEDIN_SEARCH_TERM_PARAM) || '';

    const {
        persons,
        isLoading,
        isError,
        fetchMore: fetchMorePersons,
        hasMore,
    } = useLinkedinSearch(searchTerm, searchType);
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const lastScrollPositionRef = useRef(0);

    useEffect(() => {
        if (scrollContainerRef.current && persons.length > 0) {
            scrollContainerRef.current.scrollTop = lastScrollPositionRef.current;
        }
    }, [persons]);

    const handleLoadMore = () => {
        if (scrollContainerRef.current) {
            lastScrollPositionRef.current = scrollContainerRef.current.scrollTop;
        }
        fetchMorePersons();
    };

    if (isLoading && persons.length === 0) {
        return <ListSkeleton />;
    }

    if (persons.length === 0) {
        return <NoResults searchTerm={searchTerm} searchType={searchType} />;
    }

    if (isError) {
        return <ErrorComponent searchType={searchType} />;
    }

    return (
        <ScrollArea ref={scrollContainerRef} className="h-[calc(100vh-250px)] min-h-[400px]">
            <div className="space-y-2">
                {persons.map((person) => (
                    <LinkedinPersonRow key={person.memberId} person={person} />
                ))}
            </div>
            {hasMore && (
                <div className="flex justify-center p-4">
                    <LoadMoreButton isLoading={isLoading} handleLoadMore={handleLoadMore} />
                </div>
            )}
        </ScrollArea>
    );
};

const LinkedinPersonRow = ({ person }: { person: LinkedinPerson }) => {
    const [searchParams] = useSearchParams();
    const hideAdded = searchParams.get(HIDE_ADDED_CONTACTS_PARAM) === 'true';
    const userIdBasedLinkedinUrl = `https://linkedin.com/in/${person.memberId}`;
    const { isOwnedByUser } = useContactOwners({
        linkedinUrl: userIdBasedLinkedinUrl,
    });

    if (isOwnedByUser && hideAdded) {
        return null;
    }

    return (
        <div className="flex items-center justify-between p-4 rounded-lg border min-h-[76px] hover:bg-background-primary-hover">
            <div className="flex items-center space-x-4 flex-1 min-w-0 pr-2">
                <Avatar>
                    {person.profilePicture ? <AvatarImage src={person.profilePicture} /> : null}
                    <AvatarFallback>
                        {person.firstName?.[0]}
                        {person.lastName?.[0]}
                    </AvatarFallback>
                </Avatar>
                <div className="flex flex-col min-w-0">
                    <div className="flex flex-row gap-2 items-center">
                        <div className="font-medium whitespace-nowrap">
                            {person.firstName} {person.lastName}
                        </div>
                        {person.headline && <div className="text-sm text-gray-500 truncate">{person.headline}</div>}
                    </div>
                    {person.lastMessage?.text && (
                        <div className="text-sm text-gray-500 mt-1 truncate">
                            {person.lastMessage.text.length > 150
                                ? person.lastMessage.text.substring(0, 150) + '...'
                                : person.lastMessage.text}
                        </div>
                    )}
                </div>
            </div>
            <div className="flex items-center gap-1">
                <ContactOwnersAvatarDisplay avatarSize="sm" iconSize="hidden" linkedinUrl={userIdBasedLinkedinUrl} />
                <AddContactButton
                    source={ContactSource.LinkedinImport}
                    linkedinUserId={person.memberId}
                    variant="secondary"
                />
            </div>
        </div>
    );
};

const NoResults = ({ searchTerm, searchType }: { searchTerm?: string; searchType: LinkedinSearchType }) => {
    return (
        <ScrollArea className="h-[calc(100vh-250px)] min-h-[400px]">
            <div className="flex flex-col items-center justify-center h-full space-y-2 p-4">
                <div className="text-gray-400">
                    <InboxIcon className="w-12 h-12" />
                </div>
                <div className="text-lg font-medium text-gray-600">
                    No {searchType === 'inbox' ? 'conversations' : 'connections'} found
                    {searchTerm ? ` for "${searchTerm}"` : ''}
                </div>
            </div>
        </ScrollArea>
    );
};

const ErrorComponent = ({ searchType }: { searchType: LinkedinSearchType }) => {
    return (
        <ScrollArea className="h-[calc(100vh-250px)] min-h-[400px]">
            <div className="flex flex-col items-center justify-center h-full space-y-2 p-4">
                <div className="text-gray-400">
                    <AlertCircle className="w-12 h-12" />
                </div>
                <div className="text-lg text-gray-600">
                    Failed to load {searchType === 'inbox' ? 'conversations' : 'connections'}
                </div>
            </div>
        </ScrollArea>
    );
};

const ListSkeleton = () => {
    return (
        <div className="space-y-4">
            {Array.from({ length: 10 }).map((_, i) => (
                <div key={i} className="flex items-center space-x-4 justify-between border p-2 rounded-md w-full">
                    <div className="flex items-center space-x-4 p-2">
                        <Skeleton className="h-12 w-12 rounded-full" />
                        <div className="space-y-2">
                            <Skeleton className="h-4 w-[200px]" />
                            <Skeleton className="h-3 w-[300px]" />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

const LoadMoreButton = ({ isLoading, handleLoadMore }: { isLoading: boolean; handleLoadMore: () => void }) => {
    return (
        <Button onClick={handleLoadMore} disabled={isLoading} className="min-w-[120px]">
            {isLoading ? (
                <div className="flex items-center gap-2">
                    <Loader2 className="h-4 w-4 animate-spin" />
                    <span>Loading</span>
                </div>
            ) : (
                'Load more'
            )}
        </Button>
    );
};
