import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

type Props = {
    some?: string;
};

const Crown: React.FC<Props & React.HTMLAttributes<HTMLElement>> = ({ ...props }: Props) => (
    <SvgIcon {...props} viewBox="0 0 68 54">
        <path
            d="M68.0008 12.9784C68.0008 15.5737 65.8864 17.6779 63.2786 17.6779C63.2493 17.6779 63.2248 17.663 63.1956 17.6626L57.2314 50.3125C56.9036 52.0901 55.3452 53.3943 53.5154 53.3943H14.4862C12.6611 53.3943 11.0956 52.0948 10.7698 50.3078L4.80682 17.6662C4.77731 17.6662 4.75252 17.6779 4.61675 17.6779C2.00891 17.6779 -0.105469 15.5737 -0.105469 12.9784C-0.105469 10.3831 2.11516 8.27887 4.61675 8.27887C7.11835 8.27887 9.33898 10.3831 9.33898 12.9784C9.33898 14.0356 8.9233 14.9628 8.33114 15.7476L18.9125 24.1715C20.7907 25.6671 23.5756 25.0578 24.6511 22.9167L31.4511 9.38209C30.1168 8.5538 29.1723 7.14394 29.1723 5.45916C29.1723 2.86385 31.3918 0.759644 34.0008 0.759644C36.6098 0.759644 38.6168 2.86385 38.6168 5.45916C38.6168 7.14394 37.6767 8.5538 36.3383 9.38326L43.1383 22.9179C44.2139 25.0585 46.9999 25.6671 48.877 24.1726L59.4583 15.7488C58.9695 14.9639 58.5563 13.93 58.5563 12.9784C58.5563 10.3819 60.6695 8.27887 63.2786 8.27887C65.8876 8.27887 68.0008 10.3819 68.0008 12.9784Z"
            fill="url(#paint0_linear_13526_217174)"
        />
        <defs>
            <linearGradient
                id="paint0_linear_13526_217174"
                x1="-0.105469"
                y1="53.3943"
                x2="68.3448"
                y2="52.943"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#CBCFFF" />
                <stop offset="1" stop-color="#F8C9BA" />
            </linearGradient>
        </defs>
    </SvgIcon>
);

export default Crown;
