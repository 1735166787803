import { useSetAtom } from 'jotai';
import { channelAccountsAtom } from '../../../atoms/chats';
import { getZaplifySdk } from '@zaplify/sdk';
import { getEmailChannelAccount } from '../utils/get-email-account-status';
import { ChannelAccountDto } from '@zaplify/channel-accounts/shared';
import { isImpersonatingAUser } from '../../../helpers/is-global-admin';
import { useAppSelector } from '../../../redux/store/configureStore';
import dayjs from 'dayjs';
import { useWebExtension } from '../../../new/hooks/use-web-extension';
function log(message: string) {
    console.log(`🚀 [useChannelAccountData] | ${message}`);
}

function logError(message: string) {
    console.error(`🚀 [useChannelAccountData] | ${message}`);
}

export interface LinkedinAtomData {
    id: string | null;
    value: string | null;
    status: 'SCOPES' | 'NOT_AUTHENTICATED' | 'AUTHENTICATED' | 'LOADING';
    isPremium?: boolean;
    avatarUrl?: string;
}
export interface EmailAtomData {
    id: string | null;
    value: string | null;
    status: 'SCOPES' | 'NOT_AUTHENTICATED' | 'AUTHENTICATED' | 'LOADING';
}

const useChannelAccountData = () => {
    const setChannelAccounts = useSetAtom(channelAccountsAtom);
    const user = useAppSelector((state) => state.user.zaplifyUser);
    const { getMe } = useWebExtension();

    const refetchChannelAccounts = async () => {
        try {
            const tempAccountStatus: {
                email: EmailAtomData;
                linkedIn: LinkedinAtomData;
                hasFetched: boolean;
            } = {
                email: {
                    id: null,
                    value: null,
                    status: 'NOT_AUTHENTICATED',
                },
                linkedIn: {
                    id: null,
                    value: null,
                    status: 'NOT_AUTHENTICATED',
                    isPremium: false,
                },
                hasFetched: true,
            };

            const channelAccounts = await getChannelAccountWithRetries();

            if (!channelAccounts) {
                setChannelAccounts(tempAccountStatus);
                return;
            }
            const emailAccount = channelAccounts.find((account) => account.type === 'EMAIL');
            if (emailAccount) {
                const email = getEmailChannelAccount(emailAccount);
                tempAccountStatus.email.id = emailAccount.id;
                tempAccountStatus.email.value = email.value;
                tempAccountStatus.email.status = email.status;
            }
            const linkedInAccount = channelAccounts.find((account) => account.type === 'LINKEDIN');
            if (linkedInAccount) {
                if (await isValidAvatarUrl(linkedInAccount.avatarUrl)) {
                    tempAccountStatus.linkedIn.avatarUrl = linkedInAccount.avatarUrl;
                }
                if (linkedInAccount.updatedAt < dayjs().subtract(7, 'day').toDate() && Math.random() < 0.2) {
                    fetchLinkedinAvatarUrl(linkedInAccount).catch((error) => {
                        console.error('Error fetching LinkedIn avatar URL:', error);
                    });
                }
                tempAccountStatus.linkedIn.id = linkedInAccount.id;
                tempAccountStatus.linkedIn.value = linkedInAccount.LINKEDIN.userId;
                tempAccountStatus.linkedIn.status = 'AUTHENTICATED';
                tempAccountStatus.linkedIn.isPremium = linkedInAccount.LINKEDIN.isPremiumAccount;
            }
            setChannelAccounts(tempAccountStatus);
            return tempAccountStatus;
        } catch (error) {
            logError(`Error in refetchChannelAccounts: ${JSON.stringify(error)}`);
        }
    };

    async function getChannelAccountWithRetries(retries = 0): Promise<ChannelAccountDto[]> {
        try {
            const profilesSdk = getZaplifySdk().profiles;
            if (!user?.id) return [];

            return await profilesSdk.user.getChannelAccounts(user.id);
        } catch (error) {
            if (retries < 15) {
                console.warn(`Retrying getChannelAccountWithRetries | retries: ${retries}`);
                await new Promise((resolve) => setTimeout(resolve, 10_000));
                return getChannelAccountWithRetries(retries + 1);
            }
        }
    }

    async function isValidAvatarUrl(avatarUrl: string): Promise<boolean> {
        const urlPattern = /^https:\/\/media/i;
        if (!urlPattern.test(avatarUrl)) {
            return false;
        }
        try {
            const response = await fetch(avatarUrl, { method: 'HEAD' });
            if (response.status === 403 || response.status === 404) {
                return false;
            }

            return true;
        } catch (error) {
            console.error('Error checking LinkedIn avatar URL:', error);
        }
    }

    async function fetchLinkedinAvatarUrl(linkedinChannelAccount: ChannelAccountDto) {
        try {
            if (!linkedinChannelAccount?.LINKEDIN?.userId) {
                return null;
            }
            if (await isImpersonatingAUser()) {
                return;
            }

            console.log('fetchLinkedinAvatarUrl | Fetching LinkedIn avatar URL');
            const me = await getMe();
            if (!me || me.memberId !== linkedinChannelAccount.LINKEDIN.userId || !me.profilePicture) {
                return null;
            }

            const avatarUrl = me.profilePicture;

            // Quick fix for keeping linkedin premium in sync
            if (
                typeof me.isPremiumSubscriber === 'boolean' &&
                linkedinChannelAccount.LINKEDIN.isPremiumAccount !== me.isPremiumSubscriber
            ) {
                console.log('fetchLinkedinAvatarUrl | Updating LinkedIn premium status');
                getZaplifySdk()
                    .profiles.channelAccount.updateLinkedinPremiumStatus(
                        linkedinChannelAccount.id,
                        me.isPremiumSubscriber
                    )
                    .catch((error) => {
                        console.error('Error updating LinkedIn premium status:', error);
                    });
            }

            getZaplifySdk()
                .profiles.channelAccount.updateLinkedinAvatarUrl(linkedinChannelAccount.id, avatarUrl)
                .catch((error) => {
                    console.error('Error updating LinkedIn avatar URL:', error);
                });

            return avatarUrl;
        } catch (error) {
            console.error('Error fetching LinkedIn avatar URL:', error);
            return null;
        }
    }

    return { refetchChannelAccounts };
};

export default useChannelAccountData;
