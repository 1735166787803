import { IEvent, Topics } from '@zaplify/common';
import { MessageType } from '../enums';

export class OutreachSuggestionReadEvent extends IEvent {
    public static override readonly TOPIC: Topics = Topics.CAMPAIGNS;
    public readonly userId: string;
    public readonly organizationId: string;
    public readonly prospectId: string;
    public readonly outreachSuggestionId: string;
    public readonly scheduledOn?: Date;
    public readonly messageAssistantId?: string;
    public readonly messageActionType?: MessageType;

    constructor(params: {
        userId: string;
        organizationId: string;
        prospectId: string;
        outreachSuggestionId: string;
        scheduledOn?: Date;
        messageAssistantId?: string;
        messageActionType?: MessageType;
    }) {
        super();
        this.userId = params.userId;
        this.organizationId = params.organizationId;
        this.prospectId = params.prospectId;
        this.outreachSuggestionId = params.outreachSuggestionId;
        this.scheduledOn = params.scheduledOn;
        this.messageAssistantId = params.messageAssistantId;
        this.messageActionType = params.messageActionType;
    }

    get partitionKey(): string {
        return this.prospectId;
    }
}
