import { Box, Typography } from '@mui/material';
import { useDispatch } from 'react-redux-latest';
import FileUpload, { DropResult } from '../../../../components/file-upload';
import {
    addProspectsToImportProspects,
    resetImportProspects,
    updateProspectsFieldsInImportProspects,
} from '../../../../redux/actions';
import { AppDispatch, useAppSelector } from '../../../../redux/store/configureStore';
import { ImportProspectsStepViewContainer } from '../containers';
import { UploadCSVContainer } from './containers';
import { WarningBox } from '@zaplify/ui';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { PlanCode } from '@zaplify/subscriptions';

export const UploadCSV = () => {
    const dispatch = useDispatch<AppDispatch>();
    const userIsOnFreePlan = useAppSelector(
        (state) => state.user?.zaplifyUser?.currentSeat?.subscription?.planCode === PlanCode.FREE
    );

    const { csvImportFreeUserQuota } = useFlags();

    const handleResult = (result: DropResult) => {
        dispatch(addProspectsToImportProspects(result));
    };

    const handleHeaders = (headers: string[]) => {
        dispatch(updateProspectsFieldsInImportProspects(headers));
    };

    const handleResetAll = () => {
        dispatch(resetImportProspects());
    };

    return (
        <ImportProspectsStepViewContainer>
            <UploadCSVContainer>
                <Typography variant="h3">Upload list with contacts</Typography>
                <Typography className="description">
                    Upload an Excel or CSV list containing following requirements::
                </Typography>
                <div className="csv-upload-info-container">
                    <Typography>
                        1. Required Columns for <b>First name</b>, <b>Last Name</b>, <b>Company Name</b> and{' '}
                        <b>1 required Channel</b> (Email or LinkedIn)
                    </Typography>
                    <Typography>
                        2. Can additionally contain Columns for <b>Job Title</b>, <b>Contact location</b>,{' '}
                        <b>Full Name</b>, <b>Phone nr</b>, <b>Industry</b>, <b>Email</b>, <b>LinkedIn URL</b> and{' '}
                        <b>Website</b>
                    </Typography>
                    <Typography>3. Max 500 contacts</Typography>
                </div>
                <FileUpload
                    onHeaders={handleHeaders}
                    onResult={handleResult}
                    dataValidator={({ results }) => {
                        const errors: string[] = [];

                        if (results.length > 500) {
                            errors.push('* Imported file has more than 500 companies');
                        }

                        return errors;
                    }}
                    onResetAll={handleResetAll}
                />
                {userIsOnFreePlan && (
                    <Box sx={{ marginTop: '10px' }}>
                        <WarningBox
                            text={`You can only import ${
                                csvImportFreeUserQuota ?? 50
                            } prospects / month on the free plan`}
                        />
                    </Box>
                )}
            </UploadCSVContainer>
        </ImportProspectsStepViewContainer>
    );
};
