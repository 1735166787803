import { IEvent, Topics } from '@zaplify/common';
import { UserDto } from '../users';

export class UserDeactivatedEvent extends IEvent {
    public static override readonly TOPIC = Topics.USERS;
    constructor(readonly userDto: UserDto) {
        super();
    }

    get partitionKey(): string {
        return this.userDto.userOrganizationId;
    }
}
