import AddIcon from '@mui/icons-material/Add';
import { Typography } from '@mui/material';
import { useDispatch } from 'react-redux-latest';
import { Button } from '../../../../../components/atoms/button';
import { ReactComponent as AndSendLogo } from '../../../../../components/icons/and-send/ANDSEND_logotype_small_black_transparent.svg';
import { ReactComponent as Arrow } from '../../../../../components/icons/arrow.svg';
import HubSpotLogo from '../../../../../components/icons/hubspot-logo.svg';
import { ReactComponent as ZaplifyLogo } from '../../../../../components/icons/zaplify-logo.svg';
import { companyNameCorrectlyCased } from '../../../../../enums/company-name';
import { isAndSend } from '../../../../../helpers/is-and-send';
import { updateHubspotSaveStatus } from '../../../../../redux/ducks/hubspot/operations';
import { addField, deleteField, updateField } from '../../../../../redux/ducks/hubspot/slice';
import { AppDispatch, useAppSelector } from '../../../../../redux/store/configureStore';
import { ProgressBar } from '../../salesforce/components/progressBar';
import { disableUsedField } from '../../utils/fields';
import { CampaignNameField } from './campaignNameField';
import { FieldMappingContainer } from './containers';
import { LinkedinConnectionStatusField } from './linkedInConnectionStatusField';
import { LinkedInField } from './linkedInField';
import { FieldSelector } from './selector/fieldSelector';
import { fieldValidator } from './utils';
import { ZaplifyStatusField } from './zaplifyStatusField';

export const FieldMap = () => {
    const dispatch = useDispatch<AppDispatch>();
    const fields = useAppSelector((state) => state.hubspot.fieldMapping).map((field, idx) => {
        return { ...field, idx };
    });

    const contactFields = useAppSelector((state) => state.hubspot?.availableFields?.contact || []).map((contactField) =>
        disableUsedField(contactField, fields, 'contactField', 'name')
    );

    const linkedInMappingExist = contactFields.some((field) => field.name === 'linkedin_profile_url');
    const linkedInConnectionMappingExist = contactFields.some(
        (field) => field.name === 'zaplify_is_linkedin_connected'
    );
    const campaignNameMappingExist = contactFields.some((field) => field.name === 'zaplify_campaign_name');
    const prospectStatusMappingExist = contactFields.some((field) => field.name === 'zaplify_prospect_status');

    const prospectFields = useAppSelector((state) => state.hubspot?.availableFields?.prospect || []);
    const handleDeleteField = (index) => {
        dispatch(deleteField({ index: index }));
        dispatch(updateHubspotSaveStatus());
    };

    const leftOnChange = (e, index) => {
        dispatch(updateField({ idx: index, field: 'prospectField', value: e.target.value }));
        dispatch(updateHubspotSaveStatus());
    };
    const rightOnChange = (e, index) => {
        dispatch(updateField({ idx: index, field: 'contactField', value: e.target.value }));
        dispatch(updateHubspotSaveStatus());
    };

    const handleAddField = () => {
        dispatch(addField());
        dispatch(updateHubspotSaveStatus());
    };

    if (!contactFields.length) {
        return (
            <FieldMappingContainer>
                <ProgressBar show={!contactFields.length} />
            </FieldMappingContainer>
        );
    }

    return (
        <FieldMappingContainer data-cy="hubspot-field-mapping-container">
            <Typography>
                <b>Field mapping</b>
            </Typography>
            <Typography>{`Specify which ${companyNameCorrectlyCased} fields should correspond to Hubspot fields.`}</Typography>
            <div>
                {isAndSend() ? (
                    <AndSendLogo style={{ height: '20px', width: 'auto' }} />
                ) : (
                    <ZaplifyLogo style={{ height: '20px', width: 'auto' }} />
                )}
                <Arrow />
                <img alt="hubspot-company-logo" src={HubSpotLogo} />
            </div>
            <div>
                {fields.map((field) => (
                    <FieldSelector
                        field={fieldValidator(field)}
                        leftOptions={prospectFields}
                        rightOptions={contactFields}
                        leftOnChange={leftOnChange}
                        rightOnChange={rightOnChange}
                        handleDeleteField={handleDeleteField}
                    />
                ))}
                {!linkedInMappingExist && <LinkedInField />}
                {!linkedInConnectionMappingExist && <LinkedinConnectionStatusField />}
                {!campaignNameMappingExist && <CampaignNameField />}
                {!prospectStatusMappingExist && <ZaplifyStatusField />}
            </div>
            <Button
                dataCy="add-field-button"
                borderRadius="100px"
                variant="outlined"
                height="40px"
                startIcon={<AddIcon />}
                text="Add field"
                onClick={handleAddField}
            />
            <Typography variant="body2">
                Don't see the property you're looking for? Create a new property in hubspot.
            </Typography>
            <Typography variant="body2">Outreach actions and status will be documented and saved as a note.</Typography>
        </FieldMappingContainer>
    );
};
