import * as React from 'react';
import { Dialog } from '@shadcn/ui/components/ui/dialog';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';

interface SearchParamDialogProps extends React.ComponentPropsWithoutRef<typeof Dialog> {
    children: React.ReactNode;
    param: string;
}

export const useSearchParamDialog = (param: string) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [paramValue, setParamValue] = React.useState<string | undefined>(undefined);
    const [isOpen, setIsOpen] = React.useState(searchParams.get(param)?.length > 0);

    const open = React.useCallback(
        (value = 'true') => {
            setSearchParams((prevParams) => {
                prevParams.set(param, value);
                return prevParams;
            });
        },
        [param, setSearchParams],
    );

    const close = () => {
        setSearchParams((prevParams) => {
            prevParams.delete(param);
            if (paramValue) {
                prevParams.delete(paramValue);
            }
            return prevParams;
        });
    };

    const handleOpenChange = (isOpen: boolean) => {
        if (!isOpen) {
            setSearchParams((prevParams) => {
                prevParams.delete(param);
                return prevParams;
            });
        }
    };

    useEffect(() => {
        const paramValue = searchParams.get(param);
        setParamValue(paramValue?.length > 0 ? paramValue : undefined);
    }, [searchParams, param]);

    useEffect(() => {
        setIsOpen(searchParams.get(param)?.length > 0);
    }, [searchParams, param]);

    return {
        open,
        close,
        value: paramValue,
        handleOpenChange,
        isOpen,
    };
};

export const SearchParamDialog: React.FC<SearchParamDialogProps> = ({ children, param, ...dialogProps }) => {
    const { handleOpenChange, isOpen } = useSearchParamDialog(param);

    return (
        <Dialog {...dialogProps} open={isOpen} onOpenChange={handleOpenChange}>
            {children}
        </Dialog>
    );
};
