import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: PipeDriveStateType = {
    account: {},
    integrationScope: {},
    fieldMapping: [],
    isActive: false,
    availableFields: {},
    firstTime: false,
    saveStatus: false,
    mapLinkedIn: false,
    mapZaplifyProspectStatus: false,
    mapCampaignName: false,
    mapOrganizationSize: false,
    mapOrganizationIndustry: false,
    mapOrganizationDomain: false,
    mapLinkedInConnection: false,
};

export interface PipeDriveStateType {
    account: Account;
    integrationScope: IntegrationScopeType;
    fieldMapping: FieldMapping[];
    isActive: boolean;
    availableFields: AvailableFieldsType;
    saveStatus: boolean;
    firstTime: boolean;
    mapLinkedIn: boolean;
    mapZaplifyProspectStatus: boolean;
    mapCampaignName: boolean;
    mapOrganizationSize: boolean;
    mapOrganizationIndustry: boolean;
    mapOrganizationDomain: boolean;
    mapLinkedInConnection: boolean;
}

interface Account {
    companyId?: string;
    companyName?: string;
    companyDomain?: string;
    companyCountry?: string;
}

export interface FieldMapping {
    prospectField: string;
    personField?: string;
    organizationField?: string;
    required?: boolean;
    errorText?: string;
}

interface IntegrationScopeType {
    prospects?: boolean;
    campaignsActivity?: boolean;
    actionMessages?: boolean;
    gdprDelete?: boolean;
}

interface AvailableFieldsType {
    person?: AvailableFields[];
    prospect?: AvailableFields[];
    organization?: AvailableFields[];
}

export interface AvailableFields {
    fieldType: string;
    key: string;
    name: string;
}

const pipedriveSlice = createSlice({
    name: 'pipedrive',
    initialState: initialState as PipeDriveStateType,
    reducers: {
        updateSettings(state, action: PayloadAction<any>) {
            return {
                ...state,
                account: action.payload.account,
                integrationScope: action.payload.integrationScope,
                fieldMapping: action.payload.fieldMapping || [],
                isActive: action.payload.isActive,
            };
        },
        updateAvailableFields(state, action: PayloadAction<any>) {
            state.availableFields = action.payload;
        },
        updateField(state, action: PayloadAction<{ idx: number; field: string; value: any }>) {
            if (action.payload.field === 'organizationField')
                delete state.fieldMapping[action.payload.idx]['personField'];
            if (action.payload.field === 'personField')
                delete state.fieldMapping[action.payload.idx]['organizationField'];
            state.fieldMapping[action.payload.idx][action.payload.field] = action.payload.value;
        },
        updateScope(state, action: PayloadAction<{ key: string; value: any }>) {
            state.integrationScope = { ...state.integrationScope, [action.payload.key]: action.payload.value };
        },
        addField(state) {
            state.fieldMapping = [...state.fieldMapping, { prospectField: '', personField: '' }];
        },
        addPersonField(state) {
            state.fieldMapping = [...state.fieldMapping, { prospectField: '', personField: '' }];
        },
        addOrganizationField(state) {
            state.fieldMapping = [...state.fieldMapping, { prospectField: '', organizationField: '' }];
        },
        deleteField(state, action: PayloadAction<{ index: number }>) {
            state.fieldMapping.splice(action.payload.index, 1);
        },
        updateFieldMapping(state, action: PayloadAction<any>) {
            state.fieldMapping = action.payload;
        },
        updatePipedriveActiveState(state, action: PayloadAction<boolean>) {
            state.isActive = action.payload;
        },
        updatePipedriveShowLinkedInMapping(state, action: PayloadAction<boolean>) {
            state.mapLinkedIn = action.payload;
        },
        updatePipedriveShowCampaignNameMapping(state, action: PayloadAction<boolean>) {
            state.mapCampaignName = action.payload;
        },
        updatePipedriveShowLinkedInConnectionMapping(state, action: PayloadAction<boolean>) {
            state.mapLinkedInConnection = action.payload;
        },
        updatePipedriveShowProspecStatusMapping(state, action: PayloadAction<boolean>) {
            state.mapZaplifyProspectStatus = action.payload;
        },
        updatePipedriveShowOrganizationSizeMapping(state, action: PayloadAction<boolean>) {
            state.mapOrganizationSize = action.payload;
        },
        updatePipedriveShowOrganizationIndustryMapping(state, action: PayloadAction<boolean>) {
            state.mapOrganizationIndustry = action.payload;
        },
        updatePipedriveShowOrganizationDomainMapping(state, action: PayloadAction<boolean>) {
            state.mapOrganizationDomain = action.payload;
        },
        updatePipedriveFirstTimeState(state, action: PayloadAction<boolean>) {
            state.firstTime = action.payload;
        },
        updateSaveStatus(state, action: PayloadAction<any>) {
            state.saveStatus = action.payload;
        },
        updatePipedriveCustomSwitches(state, action: PayloadAction<boolean>) {
            state.mapOrganizationDomain = action.payload;
            state.mapOrganizationIndustry = action.payload;
            state.mapOrganizationSize = action.payload;
            state.mapZaplifyProspectStatus = action.payload;
            state.mapCampaignName = action.payload;
            state.mapLinkedIn = action.payload;
            state.mapLinkedInConnection = action.payload;
        },
        /*updateValue(state, action: PayloadAction<{index: number}>) {
            state.fieldMapping.splice(action.payload.index, 1)
        },*/
    },
});

export const {
    updateSettings,
    updateAvailableFields,
    updateField,
    updateScope,
    addField,
    addPersonField,
    addOrganizationField,
    deleteField,
    updateFieldMapping,
    updatePipedriveActiveState,
    updatePipedriveShowLinkedInMapping,
    updatePipedriveFirstTimeState,
    updatePipedriveShowProspecStatusMapping,
    updatePipedriveShowOrganizationSizeMapping,
    updatePipedriveShowOrganizationIndustryMapping,
    updatePipedriveShowOrganizationDomainMapping,
    updatePipedriveShowCampaignNameMapping,
    updatePipedriveShowLinkedInConnectionMapping,
    updateSaveStatus,
    updatePipedriveCustomSwitches,
} = pipedriveSlice.actions;

export const pipedriveReducer = pipedriveSlice.reducer;
