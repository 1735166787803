import { useState, useEffect, useRef } from 'react';
import { useAuth } from '../providers/authentication-provider';
import { connect, Socket } from 'socket.io-client';

export const useWebSocket = () => {
    const ws = useRef<Socket | null>(null);
    const socketEndpoint = import.meta.env.VITE_SOCKET_ENDPOINT;
    const [isConnected, setIsConnected] = useState(false);
    const {
        authState: {
            user,
            tokenResult: { token },
        },
    } = useAuth();

    const subscribe = (channel: string, callback: (...args: any[]) => void) => {
        ws.current?.on(channel, callback);
    };

    const unsubscribe = (channel: string, callback: (...args: any[]) => void) => {
        ws.current?.off(channel, callback);
    };

    const connectWebSocket = () => {
        if (!user?.id || !user?.userOrganizationId || !token) {
            setIsConnected(false);
            return;
        }

        console.log(`WS: Connecting socket for userId: ${user.id} and organizationId: ${user.userOrganizationId}`);
        ws.current = connect(socketEndpoint, {
            timeout: 5000,
            transports: ['websocket'],
            query: {
                authorization: `Bearer ${token}`,
            },
        });

        ws.current.on('connect', () => {
            console.log('WS: Connected', new Date());
            setIsConnected(true);
        });

        ws.current.on('error', (err) => {
            console.error('WS: Got error', new Date(), err);
        });
    };

    const disconnectWebSocket = () => {
        if (ws.current) {
            ws.current.close();
            setIsConnected(false);
        }
    };

    useEffect(() => {
        connectWebSocket();
        return disconnectWebSocket;
    }, [token]);

    return {
        subscribe,
        unsubscribe,
        isConnected,
    };
};
