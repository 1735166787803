export function findError(prospect, dataPoint) {
    if (!prospect.errors) return null;

    const error = prospect.errors.find((error) => error.property === dataPoint);
    if (error) return error.messages.join('. ');
}

/**
 * Maps prospect datapoint to displayable string value (in TableRowComponent)
 */
export const mapDataPoint = (prospect, dataPoint) => {
    if (dataPoint === 'phoneNumbers') {
        return prospect?.prospectData?.phoneNumbers?.[0]?.number || '';
    } else {
        return prospect?.prospectData?.[dataPoint] || prospect?.[dataPoint] || '';
    }
};
