import { IEvent, Topics } from '@zaplify/common';
import { PageViewDto } from '../users';

export class UserPageViewEvent extends IEvent {
    public static override readonly TOPIC = Topics.USERS;
    type = 'page';
    userId: string | null;
    anonymousId: string;
    organizationId: string;
    properties: {
        title: string;
        path: string;
        referrer: string;
        search: string;
        url: string;
    };
    campaign?: {
        content?: string;
        name?: string;
        medium?: string;
        source?: string;
        keyword?: string;
    };
    context: {
        hostname: string;
        userAgent: string;
        ip: string;
    };

    constructor(pageView: PageViewDto) {
        super();
        this.userId = pageView.userId || null;
        this.anonymousId = pageView.anonymousId;
        this.organizationId = pageView.organizationId;
        if (pageView.utm_campaign) {
            this.campaign = {
                content: pageView.utm_content,
                name: pageView.utm_campaign,
                medium: pageView.utm_medium,
                source: pageView.utm_source,
                keyword: pageView.utm_term,
            };
        }
        this.properties = {
            title: pageView.pageTitle,
            path: pageView.path,
            referrer: pageView.referrer,
            search: pageView.search,
            url: pageView.url,
        };
        this.context = {
            hostname: pageView.hostname,
            userAgent: pageView.userAgent,
            ip: pageView.ip || '',
        };
    }

    get partitionKey(): string {
        return this.userId || this.anonymousId;
    }
}
