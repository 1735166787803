import CloseIcon from '@mui/icons-material/Close';
import { Box, ButtonBase, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { getZaplifySdk } from '@zaplify/sdk';
import { IPlan, PlanCode } from '@zaplify/subscriptions';
import { useAtom, useSetAtom } from 'jotai';
import React, { useMemo, useState } from 'react';
import { batch, useDispatch } from 'react-redux-latest';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid4 } from 'uuid';
import {
    availablePeriodsAtom,
    availablePlansAtom,
    loadingStateAtom,
    selectedBillingPeriodAtom,
    selectedPackagesAtom,
    selectedPlanAtom,
    selectedPlanEstimatesSummaryAtom,
    selectedPlanEstimatesTotalAtom,
    selfServiceModalAtom,
    upgradePlanErrorTextAtom,
} from '../../atoms/selfService';
import { LocalStorageKeys } from '../../config';
import { ChargebeePortalSection } from '../../enums/chargebee';
import { getPriceReadableFormat } from '../../helpers/currency';
import useSubscription from '../../hooks/useSubscription';
import { handleFreePlanSelection, logoutAndRedirect, updatePaymentStatus } from '../../redux/actions';
import { AppDispatch, useAppSelector } from '../../redux/store/configureStore';
import { useSelfServicePortal } from '../../services/chargebee/use-load-self-service-portal';
import { palette } from '../../theme/palette';
import PaymentProcessingFree from '../PaymentProcessingFree';
import { BillingPeriodSelector } from './BillingPeriodSelector';
import CouponCode from './CouponCode';
import { EnterprisePlanCard } from './EnterprisePlanCard';
import { FreePlanCard } from './FreePlanCard';
import GoBackIconButton from './GoBackIconButton';
import OrderSummary from './OrderSummary';
import PaymentDetails from './PaymentDetails';
import PaymentProcessing from './PaymentProcessing';
import PaymentSuccess from './PaymentSuccess';
import PlanCard, { PlanCardPremiumHeader, PlanCardPremiumTitle } from './PlanCard';
import { ModalView } from './constants';
import { PaymentStatus } from './enums/billing';
import { paths } from '../../routes/paths';
import { getAuthenticationToken } from '@zaplify/utils';
import { companyNameCorrectlyCased } from '../../enums/company-name';

function generatePlanName(planCode: PlanCode) {
    switch (planCode) {
        case PlanCode.BASIC:
            return 'Pro';
        case PlanCode.TEAM:
            return 'Team';
        case PlanCode.ENTERPRISE:
            return 'Enterprise';
        default:
            return '';
    }
}

const styles = makeStyles(() => ({
    paper: {
        minHeight: '550px',
        minWidth: '914px',
        //width: '98%',
        maxWidth: 'min(98vw, 1400px)',
        borderRadius: 14,
        backgroundColor: palette.colors.whiteDark,
        margin: 0,
    },
    processing: {
        borderRadius: 14,
        width: '520px',
        height: '340px',
        backgroundColor: palette.colors.whiteDark,
    },
    proccessingFree: {
        backgroundColor: palette.colors.whiteDark,
        height: '100vh',
        width: '100vw',
        maxWidth: '100vw',
        maxHeight: '100vh',
        margin: 0,
        borderRadius: 0,
        padding: 0,
    },
    success: { borderRadius: 14, width: '520px', backgroundColor: palette.colors.whiteDark },
}));

const PickPlanModal = ({
    children,
    open,
    upgradeAccount = false,
    closeCallback,
    fullscreenLoadingFree,
}: {
    children: React.ReactChild | React.ReactChild[];
    open: boolean;
    // TODO: consider removing this prop and always show the close button as user will always have a plan (free plan auto)
    upgradeAccount?: boolean;
    closeCallback?: () => void;
    fullscreenLoadingFree?: boolean;
}) => {
    const navigate = useNavigate();
    let handleClose: (reason: 'backdropClick' | 'escapeKeyDown' | 'click') => void;
    const classes = styles();
    const dispatch = useDispatch<AppDispatch>();
    const [selectedPackages] = useAtom(selectedPackagesAtom);
    const [selectedPlan] = useAtom(selectedPlanAtom);
    const [loadingState] = useAtom(loadingStateAtom);
    const [selectedPlanEstimatesSummary] = useAtom(selectedPlanEstimatesSummaryAtom);
    const [selectedPlanEstimatesTotal] = useAtom(selectedPlanEstimatesTotalAtom);
    const setSelfServiceModal = useSetAtom(selfServiceModalAtom);
    const setUpgradePlanErrorText = useSetAtom(upgradePlanErrorTextAtom);
    const paymentStatus = useAppSelector((state) => state.ui.paymentStatus);
    const [availablePlans] = useAtom(availablePlansAtom);
    const [availablePeriods] = useAtom(availablePeriodsAtom);
    const [selectedBillingPeriod] = useAtom(selectedBillingPeriodAtom);
    const [modalView, setModalView] = useState(ModalView.PICK_PLAN);
    const [couponCode, setCouponCode] = useState('');
    const [isCouponCodeValid, setIsCouponCodeValid] = useState<string | boolean>('');
    const [shouldShowDiscount, setShouldShowDiscount] = useState<boolean>(false);
    const user = useAppSelector((state) => state.user);
    const userSubscription = useAppSelector((state) => state.user.subscription);
    const currentUsersCount = userSubscription?.subscription?.totalUsers;

    const {
        handleSetSelectedPackage,
        handlePurchase,
        handleSelectBillingPeriod,
        handleSetSelectedPlan,
        createCheckoutEstimate,
        handleCheckout,
    } = useSubscription({
        organizationId: user.zaplifyUser.userOrganization.id,
        modalView,
        currentPlanBillingPeriod: user.subscription.billingPeriodUnit,
        currentPlanBillingAmount: user.subscription.billingPeriod,
        currentNumberOfSeats: currentUsersCount,
    });
    const { loadSelfServicePortal } = useSelfServicePortal();
    const totalAmountToChargeReadable = getPriceReadableFormat({
        price: (selectedPlanEstimatesTotal?.immediate.total || 0) / 100,
        maximumFractionDigits: 2,
        billingCurrency: user.subscription.billingCurrency,
    });
    const estimatedPricePerMonth = getPriceReadableFormat({
        price: (selectedPlanEstimatesSummary?.immediate.total || 0) / 100,
        maximumFractionDigits: 2,
        billingCurrency: user.subscription.billingCurrency,
    });
    const immediatePurchase = selectedPlanEstimatesTotal?.immediate?.total !== 0;

    const vatTotalReadable = !selectedPlanEstimatesTotal?.immediate?.vat
        ? null
        : getPriceReadableFormat({
              price: (selectedPlanEstimatesTotal?.immediate.vat || 0) / 100,
              maximumFractionDigits: 2,
              billingCurrency: user.subscription.billingCurrency,
              round: false,
          });

    const uniqueKeyForCurrentRender = useMemo(() => uuid4(), [modalView]);

    handleClose = (reason: 'backdropClick' | 'escapeKeyDown' | 'click') => {
        // @TODO Could force user without plan to choose something by uncommenting this (the old way we did it)
        // if(!upgradeAccount) {
        //     return;
        // }

        if (paymentStatus === PaymentStatus.SUCCESS) {
            getAuthenticationToken(true);
            dispatch(updatePaymentStatus(PaymentStatus.NONE));
        }

        localStorage.removeItem(LocalStorageKeys.PLAN);
        setSelfServiceModal('none');
        window?.analytics?.track('User Closed Self-Service Modal');

        if (closeCallback) {
            closeCallback();
        }
    };

    const handleGoBack = () => {
        setCouponCode('');
        setIsCouponCodeValid('');
        setShouldShowDiscount(false);
        setUpgradePlanErrorText(null);
        setModalView(ModalView.PICK_PLAN);
    };

    const handleLogout = () => {
        dispatch(logoutAndRedirect());
    };

    const getSelectedPlan = (plan: IPlan) => {
        if (plan.code === PlanCode.BASIC && plan.billingPeriod.unit === 'month')
            return {
                credits: selectedPackages['month-basic'].credits,
                seats: selectedPackages['month-basic'].seats,
            };
        if (plan.code === PlanCode.TEAM && plan.billingPeriod.unit === 'month') {
            return {
                credits: selectedPackages['month-team'].credits,
                seats: selectedPackages['month-team'].seats,
            };
        }
        if (plan.code === PlanCode.BASIC && plan.billingPeriod.unit === 'year')
            return {
                credits: selectedPackages['year-basic'].credits,
                seats: selectedPackages['year-basic'].seats,
            };
        if (plan.code === PlanCode.TEAM && plan.billingPeriod.unit === 'year') {
            return {
                credits: selectedPackages['year-team'].credits,
                seats: selectedPackages['year-team'].seats,
            };
        }
    };

    const openConfirmationView = (plan: IPlan) => {
        handleSetSelectedPlan(plan.billingPeriod.unit, plan.code);
        setModalView(ModalView.CONFIRM);
        return;
    };

    const handleSelectPlan = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, plan: IPlan) => {
        //If this is the initial pick-plan-modal, i.e organization doesn't have a subscription, we open the chargebee checkout flow
        //Else we open the review view
        const subscription = user?.subscription;
        const hasSubscription = Boolean(subscription?.planCode);
        const hasBillingDetails = Boolean(subscription?.card);
        setModalView(ModalView.CONFIRM);
        await createCheckoutEstimate({
            plans: availablePlans,
            planToSet: plan,
            packagesToSet: selectedPackages,
            selectedModalView: ModalView.CONFIRM,
            couponCode: couponCode,
        });
        if (!hasBillingDetails && hasSubscription) {
            loadSelfServicePortal(ChargebeePortalSection.PAYMENT_SOURCES, () => openConfirmationView(plan));
            return;
        }
        if (!upgradeAccount) {
            const selectedPlan = getSelectedPlan(plan);
            if (!selectedPlan) return;
            handleCheckout(e, selectedPlan, plan);
        } else {
            openConfirmationView(plan);
        }
    };

    const handleCouponCode = async (couponCode: string) => {
        const profilesSdk = getZaplifySdk().profiles;
        if (!couponCode) {
            return;
        }
        try {
            const response = await profilesSdk.billing.verifyCouponCode(couponCode);
            setIsCouponCodeValid(response.isValid);
            if (response.isValid) {
                await createCheckoutEstimate({
                    plans: availablePlans,
                    planToSet: selectedPlan,
                    packagesToSet: selectedPackages,
                    selectedModalView: ModalView.CONFIRM,
                    couponCode: couponCode,
                });
                setCouponCode(couponCode);
                setShouldShowDiscount(true);
            }
        } catch (error) {
            setIsCouponCodeValid(false);
        }
    };

    const handleChangeOrganization = async () => {
        navigate(paths.OLD_ACCOUNTS);
    };

    return (
        <Dialog
            open={open}
            onClose={(_, reason) => handleClose(reason)}
            classes={{
                paper:
                    (paymentStatus === PaymentStatus.NONE && classes.paper) ||
                    (paymentStatus === PaymentStatus.PROCESSING && classes.processing) ||
                    (paymentStatus === PaymentStatus.PROCESSING_FREE && classes.proccessingFree) ||
                    classes.success,
            }}
            scroll="paper"
            disableEnforceFocus
            fullScreen={fullscreenLoadingFree}
        >
            {paymentStatus === PaymentStatus.NONE && (
                <>
                    <DialogTitle style={{ padding: modalView === ModalView.CONFIRM ? 0 : '16px 24px' }}>
                        {modalView === ModalView.PICK_PLAN && (
                            <>
                                <Box
                                    style={{
                                        display: 'flex',
                                        gap: 10,
                                        justifyContent: 'end',
                                        alignItems: 'center',
                                    }}
                                >
                                    {!upgradeAccount && (
                                        <>
                                            <ButtonBase onClick={handleLogout}>
                                                <Typography
                                                    style={{
                                                        textDecoration: 'underline',
                                                        color: palette.colors.greyDark,
                                                    }}
                                                >
                                                    Log out
                                                </Typography>
                                            </ButtonBase>
                                            <Typography style={{ color: palette.colors.greyDark }}>or</Typography>
                                            <ButtonBase onClick={handleChangeOrganization}>
                                                <Typography
                                                    style={{
                                                        textDecoration: 'underline',
                                                        color: palette.colors.greyDark,
                                                    }}
                                                >
                                                    Switch workspace
                                                </Typography>
                                            </ButtonBase>
                                        </>
                                    )}
                                    {upgradeAccount && (
                                        <ButtonBase onClick={() => handleClose('click')}>
                                            <CloseIcon style={{ color: palette.colors.greyDark }} />
                                        </ButtonBase>
                                    )}
                                </Box>
                                {children}
                                <Box display="flex" justifyContent="center" mt={'20px'}>
                                    <BillingPeriodSelector
                                        onChange={(billingPeriod) => {
                                            handleSelectBillingPeriod({
                                                selectedBillingPeriod: billingPeriod,
                                                availablePeriods,
                                                currentBillingPeriod: {
                                                    amount: user.subscription.billingPeriod,
                                                    unit: user.subscription.billingPeriodUnit,
                                                },
                                            });
                                        }}
                                        disable={loadingState === 'loading'}
                                        availablePeriods={availablePeriods}
                                        selectedBillingPeriod={selectedBillingPeriod!}
                                    />
                                </Box>
                            </>
                        )}
                        {modalView === ModalView.CONFIRM && (
                            <>
                                <Box display="flex">
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        p={'16px 24px 44.5px'}
                                        bgcolor={palette.colors.white}
                                        flex={'0 50%'}
                                    >
                                        <Box mr={4}>
                                            <GoBackIconButton data-cy="go-back-button" onClick={handleGoBack} />
                                        </Box>
                                        <Typography variant="h3">Confirm your new plan</Typography>
                                    </Box>
                                    <Box
                                        display="flex"
                                        justifyContent="flex-end"
                                        alignItems="start"
                                        p={'16px 24px 44.5px'}
                                        bgcolor={palette.colors.secondaryBlue}
                                        flex={'0 50%'}
                                    >
                                        <ButtonBase onClick={() => handleClose('click')} data-cy="close-summary-button">
                                            <CloseIcon style={{ color: palette.colors.greyDark }} />
                                        </ButtonBase>{' '}
                                    </Box>
                                </Box>
                            </>
                        )}
                    </DialogTitle>
                    <DialogContent
                        style={{
                            padding: modalView === ModalView.CONFIRM ? 0 : '30px',
                            margin: modalView === ModalView.CONFIRM ? 0 : '0px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                        }}
                    >
                        {/* {modalView === ModalView.PICK_PLAN && user.subscription.subscription?.id && ( */}
                        {modalView === ModalView.PICK_PLAN && (
                            <Box>
                                <Box display="flex" style={{ gap: 15 }}>
                                    {!upgradeAccount && (
                                        <FreePlanCard
                                            loadingState={loadingState}
                                            planCode={user.subscription.planCode}
                                            onSelect={() => {
                                                dispatch(updatePaymentStatus(PaymentStatus.PROCESSING));
                                                dispatch(
                                                    handleFreePlanSelection({
                                                        options: { idempotencyKey: uniqueKeyForCurrentRender },
                                                    })
                                                );
                                            }}
                                        />
                                    )}
                                    {availablePlans.map((plan) => {
                                        const selectedPackageTemp =
                                            selectedPackages[`${plan!.billingPeriod.unit}-${plan!.code}`];

                                        return (
                                            <PlanCard
                                                dataCy={plan.code}
                                                plan={plan}
                                                loadingState={loadingState}
                                                onPackageChange={(selectedPackage, planCode) => {
                                                    handleSetSelectedPackage(selectedPackage, planCode);
                                                }}
                                                onPlanSelect={(e) => handleSelectPlan(e, plan)}
                                                selectedPackage={selectedPackageTemp}
                                                premium
                                            >
                                                <PlanCardPremiumHeader>
                                                    {generatePlanName(plan.code)}
                                                </PlanCardPremiumHeader>
                                                <PlanCardPremiumTitle>
                                                    {plan!.code === 'basic'
                                                        ? 'All you need to maximize your day-to-day efficiency.'
                                                        : `When you want to scale the power of ${companyNameCorrectlyCased} to the whole team.`}
                                                </PlanCardPremiumTitle>
                                            </PlanCard>
                                        );
                                    })}
                                    <EnterprisePlanCard
                                        loadingState={loadingState}
                                        planCode={user.subscription?.subscription?.current?.planCode}
                                    />
                                </Box>
                            </Box>
                        )}
                        {modalView === ModalView.CONFIRM && (
                            <Box display="flex" flexGrow={1}>
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems={'start'}
                                    pl="104px"
                                    pt={'9px'}
                                    bgcolor={palette.colors.white}
                                    flex={'0 40%'}
                                >
                                    <Box width={'105px'} />
                                    <PaymentDetails
                                        subscriptionDetails={user.subscription}
                                        loadingState={loadingState}
                                    />
                                    <CouponCode
                                        handleCouponCode={handleCouponCode}
                                        isCouponCodeValid={isCouponCodeValid}
                                        setIsCouponCodeValid={setIsCouponCodeValid}
                                    />
                                </Box>
                                <Box bgcolor={palette.colors.secondaryBlue} flex={'0 50%'}>
                                    <Box p={'0px'}>
                                        <OrderSummary
                                            subscriptionDetails={user.subscription}
                                            vatTotal={vatTotalReadable}
                                            estimatedPricePerMonth={estimatedPricePerMonth!}
                                            selectedPlan={selectedPlan!}
                                            onPurchase={handlePurchase}
                                            totalAmountToCharge={totalAmountToChargeReadable}
                                            loadingState={loadingState}
                                            shouldShowDiscount={shouldShowDiscount}
                                            couponCode={couponCode}
                                            immediatePurchase={immediatePurchase}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        )}
                    </DialogContent>
                </>
            )}
            {paymentStatus === PaymentStatus.PROCESSING && <PaymentProcessing />}
            {paymentStatus === PaymentStatus.PROCESSING_FREE && <PaymentProcessingFree />}
            {paymentStatus === PaymentStatus.SUCCESS && (
                <>
                    <DialogTitle>
                        <Box display="flex" justifyContent="flex-end" width="100%">
                            <ButtonBase onClick={() => handleClose('click')}>
                                <CloseIcon style={{ color: palette.colors.greyDark }} />
                            </ButtonBase>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <PaymentSuccess onPressedDone={() => handleClose('click')} />
                    </DialogContent>
                </>
            )}
        </Dialog>
    );
};

export default PickPlanModal;
