import { Box, CircularProgress, MenuItem, Stack, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useMutation } from '@tanstack/react-query';
import { ArrowRight } from 'lucide-react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux-latest';
import { Link } from 'react-router-dom';
import { AppDispatch } from '../../../redux/store/configureStore';
import useCountries from '../hooks/use-countries';
import useFallbackCountries from '../hooks/use-fallback-countries';
import FeedbackMessage from '../../../components/form/feedback-message';
import AutocompleteField from '../../../components/form/form-autocomplete-field';
import FormInputField from '../../../components/form/form-input-field';
import LoadingButton from '../../../components/form/loading-button';
import { LocalStorageKeys } from '../../../config';
import { getAuthenticationToken, getEmailDomain } from '@zaplify/utils';
import { getZaplifySdk } from '@zaplify/sdk';
import { stripTopLevelDomain } from '../../../services/utils/emailHelper';
import { CreateUserDto } from '@zaplify/users/shared';
import { PlanCode } from '@zaplify/subscriptions';
import { getAuth } from 'firebase/auth';
import { paths } from '../../../routes/paths';
import { fetchUserError, handleFreePlanSelection, stopLoading, updatePaymentStatus } from '../../../redux/actions';
import { v4 as uuid4 } from 'uuid';
import { PaymentStatus } from '../../../components/pick-plan-modal/enums/billing';
import { companyNameCorrectlyCased } from '../../../enums/company-name';
import { isOnWindows } from '../../../services/utils/languages-list';

type ConfigureProfileFormValues = {
    firstName: string;
    lastName: string;
    country: string | null;
    website: string;
    isSubscribed: boolean;
};

const countryCodeToFlagEmoji = (countryCode: string) => {
    return countryCode.toUpperCase().replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397));
};

const getAnonymousId = (): string => {
    const cookies = document.cookie;
    let anonymousId = null;
    const cookiesArray = cookies.split('; ');
    for (let cookie of cookiesArray) {
        if (cookie.startsWith('ajs_anonymous_id=')) {
            anonymousId = cookie.split('=')[1];
            return anonymousId;
        }
    }
};

const ConfigureProfileForm = () => {
    const dispatch = useDispatch<AppDispatch>();
    const [authErrorMessage, setAuthErrorMessage] = useState<string | null>(null);
    const configureProfile = async (createUser: CreateUserDto) => {
        try {
            const zaplifyUser = await getZaplifySdk().profiles.user.createUser(createUser);
            localStorage.removeItem(LocalStorageKeys.INVITATION_ID);
            localStorage.removeItem(LocalStorageKeys.INVITATION_SECURITY_TOKEN);
            if (!zaplifyUser) {
                return;
            }

            const addrevenue = window['ADDREVENUE'];
            if (addrevenue) {
                try {
                    addrevenue.sendEvent('Signup', { orderId: zaplifyUser.userOrganizationId });
                } catch (error) {
                    console.error('Addrevnue error while sending Signup event', error);
                }
            } else {
                console.error('Addrevnue not loaded');
            }

            // Important that we make sure the auth token is refreshed also after creating the new user, so that subsequent requests are made using correct user
            await getAuthenticationToken(true);
            // @TODO Below two actions should probably be moved since they can be unpredictable after the async action getAuthenticationToken(true) has been called
            dispatch(updatePaymentStatus(PaymentStatus.PROCESSING_FREE));
            dispatch(
                handleFreePlanSelection({
                    options: { idempotencyKey: uuid4() },
                })
            );
            return zaplifyUser;
        } catch (error) {
            dispatch(fetchUserError('error fetching user'));
            dispatch(stopLoading());
            throw new Error('Error configuring profile. Try again later', {
                cause: error,
            });
        }
    };

    const { register, handleSubmit, formState, setValue, trigger, getValues } = useForm<ConfigureProfileFormValues>({
        defaultValues: {
            firstName: '',
            lastName: '',
            country: '',
            website: '',
            isSubscribed: true,
        },
        mode: 'all',
    });
    const { errors } = formState;
    const { mutate, status: formStatus } = useMutation({
        mutationFn: async (data: ConfigureProfileFormValues) => {
            // @TODO Improve this state management
            const zaplifySdk = getZaplifySdk();
            const firebaseUser = getAuth().currentUser;
            const domain = getEmailDomain(firebaseUser.email);
            const [organizationMatchingDomain, accounts] = await Promise.all([
                zaplifySdk.profiles.user.getUserOrganizationByDomain(domain),
                zaplifySdk.profiles.user.getUserAccounts(),
            ]);
            const userHasAccountWithDomain = accounts.users.some((account) =>
                domain.includes(account.organization.name.toLowerCase())
            );
            const organizationHasTeamFeatures = [PlanCode.TEAM, PlanCode.ENTERPRISE].includes(
                organizationMatchingDomain?.subscription?.subscription?.planCode
            );
            const referredByReferralLinkId = localStorage.getItem(LocalStorageKeys.REFERRAL_LINK);
            const invitationId = localStorage.getItem(LocalStorageKeys.INVITATION_ID);
            const invitationSecurityToken = localStorage.getItem(LocalStorageKeys.INVITATION_SECURITY_TOKEN);

            const isJoinByDomain =
                organizationMatchingDomain && !userHasAccountWithDomain && organizationHasTeamFeatures;
            const isJoinByInvitation = invitationId && invitationSecurityToken;
            const isCreateOrganization = !isJoinByDomain && !isJoinByInvitation;
            const anonymousId = getAnonymousId();

            console.log({
                message: 'the info',
                isJoinByDomain,
                isJoinByInvitation,
                isCreateOrganization,
                organizationMatchingDomain,
                userHasAccountWithDomain,
            });

            const country = data.country.split(' ')[1];
            let createUserDto: CreateUserDto;
            if (isJoinByInvitation) {
                const invitationInfo = await zaplifySdk.profiles.user.getInvitationDetails(
                    invitationId,
                    invitationSecurityToken
                );
                createUserDto = {
                    anonymousId: anonymousId,
                    firebaseId: firebaseUser.uid,
                    email: firebaseUser.email,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    location: {
                        country: country,
                        city: '',
                    },
                    isSubscribed: data.isSubscribed,
                    referredByReferralLinkId,
                    joinByInvitation: {
                        id: invitationInfo.id,
                        organizationId: invitationInfo.organizationId,
                    },
                };
            } else if (isJoinByDomain) {
                createUserDto = {
                    anonymousId: anonymousId,
                    firebaseId: firebaseUser.uid,
                    email: firebaseUser.email,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    location: {
                        country: country,
                        city: '',
                    },
                    isSubscribed: data.isSubscribed,
                    referredByReferralLinkId,
                    joinByDomain: {
                        domain: domain,
                    },
                };
            } else if (isCreateOrganization) {
                const cleanedDomain = stripTopLevelDomain(domain);
                const companyName = cleanedDomain[0].toUpperCase() + cleanedDomain.slice(1);
                createUserDto = {
                    anonymousId: anonymousId,
                    firebaseId: firebaseUser.uid,
                    email: firebaseUser.email,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    location: {
                        country: country,
                        city: '',
                    },
                    isSubscribed: data.isSubscribed,
                    referredByReferralLinkId,
                    createOrganization: {
                        name: companyName,
                        domain: domain || getEmailDomain(firebaseUser.email),
                        website: data.website,
                    },
                };
            } else {
                throw new Error('Unsupported scenario');
            }

            return await configureProfile(createUserDto);
        },
        onError: (error: unknown) => {
            if (error instanceof Error) {
                setAuthErrorMessage(error.message);
            } else {
                setAuthErrorMessage('An error occurred');
            }
        },
    });
    const { countries, status: countriesStatus, isError: countriesIsError, error: countriesError } = useCountries();
    const [fallbackCountries] = useFallbackCountries();

    const onSubmit = (data: ConfigureProfileFormValues) => {
        mutate(data);
    };
    const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    const isLoading =
        formStatus === 'pending' ||
        countriesStatus === 'pending' ||
        !fallbackCountries.length ||
        // Note: We do not want to stop loading if formStatus is 'success', because if the submission succeeds the token is refreshed and there's a bunch of asynchronous proccesses happening that eventually will lead to us being redirecting from this view to the next. And, while we wait for those asynchronous processes, we don't want the user to be able to "Next" again, since that would create a new second user in a second workspace.
        // This means that, if the form submission is successful, we will never stop loading, because we will be redirected a few seconds later anyway
        formStatus === 'success';

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} onKeyDown={handleKeyDown} noValidate>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '16px',
                        padding: '16px',
                        boxSizing: 'border-box',
                    }}
                >
                    <FormInputField<'firstName'>
                        id="firstName"
                        label="First name"
                        register={register('firstName', {
                            required: 'First name is required',
                        })}
                        error={errors.firstName?.message}
                        placeholder="John"
                        type="text"
                        isLoading={isLoading}
                    />
                    <FormInputField<'lastName'>
                        id="lastName"
                        label="Last name"
                        register={register('lastName', {
                            required: 'Last name is required',
                        })}
                        error={errors.lastName?.message}
                        placeholder="Doe"
                        type="text"
                        isLoading={isLoading}
                    />
                    <AutocompleteField<'country'>
                        id="country"
                        label="Your country"
                        placeholder="Select your country"
                        register={register('country', {
                            required: 'Country is required',
                        })}
                        error={errors.country?.message}
                        onChange={(_, newValue) => {
                            !countriesIsError && setValue('country', newValue?.commonName || null);
                            countriesIsError && setValue('country', newValue?.name || null);
                            trigger('country');
                        }}
                        getOptionLabel={(country) =>
                            countriesIsError
                                ? country.name
                                : `${isOnWindows ? '' : countryCodeToFlagEmoji(country.countryCode)} ${
                                      country.commonName
                                  }`
                        }
                        options={countriesIsError ? fallbackCountries : countries}
                        renderOption={(props, option) => (
                            <>
                                {!countriesIsError && (
                                    <MenuItem {...props} key={option.countryCode}>
                                        {isOnWindows ? '' : countryCodeToFlagEmoji(option.countryCode)}{' '}
                                        {option.commonName}
                                    </MenuItem>
                                )}
                                {countriesIsError && <MenuItem {...props}>{option.name}</MenuItem>}
                            </>
                        )}
                    />
                </Box>
                <Stack
                    sx={{
                        paddingX: '18px',
                        boxSizing: 'border-box',
                    }}
                >
                    <FormControlLabel
                        control={
                            <Checkbox
                                size="small"
                                inputProps={{ 'aria-label': 'Do you confirm subscribing to a newsletter?' }}
                                {...register('isSubscribed')}
                                defaultChecked
                            />
                        }
                        label={
                            <Typography
                                component={'span'}
                                sx={{
                                    fontSize: '13px',
                                    color: '#6B7985',
                                    lineHeight: '19px',
                                }}
                            >
                                Get feature updates and tips via email (recommended).
                            </Typography>
                        }
                    />
                </Stack>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '8px',
                        paddingX: '16px',
                        boxSizing: 'border-box',
                    }}
                >
                    <LoadingButton isLoading={isLoading} disabled={isLoading} type="submit">
                        {isLoading ? (
                            <CircularProgress size={24} sx={{ color: '#969FAC' }} />
                        ) : (
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: '8px',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    component={'span'}
                                    sx={{
                                        fontSize: '14px',
                                        fontWeight: 500,
                                        lineHeight: '21px',
                                        color: 'white',
                                    }}
                                >
                                    Next
                                </Typography>
                                <ArrowRight size={20} stroke={'#fff'} />
                            </Box>
                        )}
                    </LoadingButton>
                    <Typography
                        component={'span'}
                        sx={{
                            fontSize: '13px',
                            color: '#6B7985',
                            lineHeight: '19px',
                            textAlign: 'center',
                            paddingX: '4px',
                        }}
                    >
                        By continuing, you agree to the {companyNameCorrectlyCased}{' '}
                        <Link to={paths.LEGAL.PRIVACY_POLICY} data-cy="privacy-policy-link">
                            Privacy Policy
                        </Link>{' '}
                        and <Link to={paths.LEGAL.TERMS}>Terms of Service</Link>.
                    </Typography>

                    <FeedbackMessage
                        id="configure-profile-form-feedback-message"
                        error={
                            countriesIsError && !fallbackCountries.length ? countriesError?.message : authErrorMessage
                        }
                        info={''}
                    />
                </Box>
            </form>
            {/* <DevTool control={control} /> */}
        </>
    );
};

export default ConfigureProfileForm;
