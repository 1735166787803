export function getSpecialPipedriveMapping(pipedrive, fieldMapping) {
    const tempFieldMapping: any[] = [];
    if (pipedrive.mapLinkedIn && !fieldMapping.some((f) => f.prospectField === 'prospect.linkedinProfileUrl'))
        tempFieldMapping.push({
            prospectField: 'prospect.linkedinProfileUrl', // double check this one first
            personField: 'LinkedIn Profile Url', // for pipedrive side use this precise one
            create: {
                title: 'LinkedIn Profile URL',
                description: 'LinkedIn Profile URL of the prospect',
            },
        });

    if (pipedrive.mapCampaignName && !fieldMapping.some((f) => f.prospectField === 'custom.campaignName')) {
        tempFieldMapping.push({
            prospectField: 'custom.campaignName', // double check this one first
            personField: 'Zaplify Campaign Name', // for pipedrive side use this precise one
            create: {
                title: 'Zaplify Prospect Campaign Name',
                description: 'Prospect campaign name in Zaplify',
            },
        });
    }

    if (
        pipedrive.mapLinkedInConnection &&
        !fieldMapping.some((f) => f.prospectField === 'personRef.isLinkedInConnected')
    ) {
        tempFieldMapping.push({
            prospectField: 'personRef.isLinkedInConnected', // double check this one first
            personField: 'Is LinkedIn Connected', // for pipedrive side use this precise one
            create: {
                title: 'Contact creator is connected on Linkedin',
                description: 'Contact creator is connected on Linkedin',
            },
        });
    }

    if (
        pipedrive.mapZaplifyProspectStatus &&
        !fieldMapping.some((f) => f.prospectField === 'personRef.statusInCampaign')
    )
        tempFieldMapping.push({
            prospectField: 'personRef.statusInCampaign', // double check this one first
            personField: 'Zaplify Prospect status', // for pipedrive side use this precise one
            create: {
                title: 'Zaplify Prospect Status',
                description: 'Prospect status in Zaplify campaign',
            },
        });

    if (
        pipedrive.mapOrganizationDomain &&
        !fieldMapping.some((f) => f.organizationField === 'prospect.organizationDomain')
    )
        tempFieldMapping.push({
            prospectField: 'prospect.organizationDomain', // double check this one first
            organizationField: 'Company Domain', // for pipedrive side use this precise one
            create: {
                title: 'Company Domain',
                description: '',
            },
        });

    if (
        pipedrive.mapOrganizationIndustry &&
        !fieldMapping.some((f) => f.organizationField === 'prospect.organizationIndustry')
    )
        tempFieldMapping.push({
            prospectField: 'prospect.organizationIndustry', // double check this one first
            organizationField: 'Company Industry', // for pipedrive side use this precise one
            create: {
                title: 'Company Industry',
                description: '',
            },
        });

    if (pipedrive.mapOrganizationSize && !fieldMapping.some((f) => f.organizationField === 'prospect.organizationSize'))
        tempFieldMapping.push({
            prospectField: 'prospect.organizationSize', // double check this one first
            organizationField: 'Company Size', // for pipedrive side use this precise one
            create: {
                title: 'Company Size',
                description: '',
            },
        });
    return tempFieldMapping;
}
