import { FC, Suspense, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ContactDetailsSidebar } from '../../components/contact-details-sidebar/contact-details-sidebar';
import { ContactsTable } from './components/contacts-table';
import ContactsPageHeader from './components/contacts-page-header';
import { useSidebars } from '@shadcn/ui/hooks/use-sidebars';
import { ScrollArea } from '@shadcn/ui/components/ui/scroll-area';

export const ContactsPage: FC = () => {
    const { prospectId } = useParams();
    const { toggleSidebar, sidebarState } = useSidebars();

    useEffect(() => {
        if (prospectId && !sidebarState.details) {
            toggleSidebar('details');
        } else if (!prospectId && sidebarState.details) {
            toggleSidebar('details');
        }
    }, [prospectId]);

    return (
        <div className="flex h-full max-w-full w-full overflow-hidden">
            <div className="flex flex-col min-w-0 min-h-0 h-full max-h-full w-full overflow-hidden gap-2 p-4">
                <ScrollArea className="flex-1 flex flex-col min-w-0">
                    <Suspense fallback={<div>Loading...</div>}>
                        <ContactsPageHeader />
                    </Suspense>

                    <div className="flex flex-1 min-h-0 max-w-full">
                        <Suspense fallback={<div>Loading...</div>}>
                            <ContactsTable />
                        </Suspense>
                    </div>
                </ScrollArea>
            </div>
            <ContactDetailsSidebar />
        </div>
    );
};

export default ContactsPage;
