import { Box } from '@mui/material';
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { isImpersonatingAUser } from '../../../helpers/is-global-admin';
import { useAppSelector } from '../../../redux/store/configureStore';
import { paths } from '../../../routes/paths';
import { AiPreferencesOnboardingInfoSection } from './ai-preferences-onboarding-info-section';
import { SplitView } from '../../onboarding/split-view-onboarding';
import { SelectPurposeStep } from './select-purpose-step';
import { SelectWebsiteStep } from './select-website-step';
import { CompletingOnboardingLoadingScreen } from './completing-onboarding-loading-screen';
import { useSeatsAndCredits } from '../../../new/hooks/use-seat-and-credits';
import { useSetupUserProfile } from './hooks/use-setup-user-profile';
import { ConversationMessageParsed } from '@zaplify/web-extension-shared';
import { PlaybookTemplate } from '../../playbooks/manage/hooks/use-playbook-templates';
import { sleep } from '@zaplify/utils';
import { ProspectDataWithConnectionStatus } from '@zaplify/sdk';
import { channelAccountsAtom } from '../../../atoms/chats';
import { useAtom } from 'jotai';
import useChannelAccountData from '../../chats/hooks/use-channel-account-data';
import { useFlags } from 'launchdarkly-react-client-sdk';
type Steps = 'select purpose' | 'select website' | 'configuring profile';

export const AiPreferencesForm = () => {
    const country = useAppSelector((state) => state.user.zaplifyUser?.location?.country);
    const domainFromRedux = useAppSelector((state) => state.user.zaplifyUser?.userOrganization?.domain);
    const name = useAppSelector((state) => state.user.zaplifyUser?.userOrganization?.name);
    const navigate = useNavigate();
    const { createProspectsFromAllLiConversation } = useFlags();
    const [channelAccounts] = useAtom(channelAccountsAtom);
    const { refetchChannelAccounts } = useChannelAccountData();
    const [firstPlaybook, setFirstPlaybook] = useState<Partial<PlaybookTemplate>>(undefined);
    const [language, setLanguage] = useState<string>(convertsCountryToLanguage(country));
    const hasStartedSetup = useRef(false);

    const userSignedUpWithPrivateEmailDomain = ['gmail.com', 'hotmail.com', 'outlook.com'].includes(
        domainFromRedux.toLowerCase()
    );
    const [website, setWebsite] = useState<string>(userSignedUpWithPrivateEmailDomain ? '' : domainFromRedux);
    const [step, setStep] = useState<Steps>('select purpose');
    const [setupUserPromise, setSetupUserPromise] = useState<
        Promise<{
            playbookId: string;
            assistantSettingsId: string;
            lastConversations: {
                profile: ProspectDataWithConnectionStatus;
                conversation: ConversationMessageParsed[];
            }[];
        }>
    >(null);

    const { setupNewZaplifyUser, addRefererAsContact, getRecentLinkedinConversations } = useSetupUserProfile();

    const { waitForSeats } = useSeatsAndCredits();

    const setupUser = async (): Promise<{
        playbookId: string;
        assistantSettingsId: string;
        lastConversations: { profile: ProspectDataWithConnectionStatus; conversation: ConversationMessageParsed[] }[];
    }> => {
        await sleep(2000);
        console.log('onboarding: setupUser');
        let lastConversationsPromise: Promise<
            {
                profile: ProspectDataWithConnectionStatus;
                conversation: ConversationMessageParsed[];
            }[]
        >;

        lastConversationsPromise = getRecentLinkedinConversations(18);
        try {
            await waitForSeats();
            console.log('onboarding: waited for seats');

            console.log('onboarding: set up ai profile settings');
            const initialPlaybook = await setupNewZaplifyUser(language);
            console.log('onboarding: done setting up ai profile settings');

            addRefererAsContact().catch(console.error);

            const lastConversations = await lastConversationsPromise;
            console.log('onboarding: imported recent linkedin conversations');
            return { ...initialPlaybook, lastConversations };
        } catch (error) {
            console.log('Error setting up AI profile settings', error);
            return { playbookId: '', assistantSettingsId: '', lastConversations: [] };
        }
    };

    useEffect(() => {
        if (!channelAccounts?.linkedIn?.value) {
            refetchChannelAccounts().catch(console.error);
        }

        if (!hasStartedSetup.current && channelAccounts?.linkedIn?.value) {
            hasStartedSetup.current = true;
            setSetupUserPromise(setupUser());
        }
    }, [channelAccounts?.linkedIn?.value]);

    const handleNextStep = () => {
        if (step === 'select purpose') {
            setStep('select website');
        } else if (step === 'select website') {
            setStep('configuring profile');
        }
    };

    useEffect(() => {
        setLanguage(convertsCountryToLanguage(country));
    }, [country]);

    useEffect(() => {
        (async () => {
            const isImpersonating = await isImpersonatingAUser();
            if (isImpersonating) {
                console.log('AI Preferences view is impersonating, redirecting to home');
                navigate(paths.HOME);
            }
        })();
    }, []);

    if (step === 'configuring profile') {
        return (
            <CompletingOnboardingLoadingScreen
                firstPlaybook={{
                    name: 'My first playbook',
                    purpose: firstPlaybook.purpose,
                    context: firstPlaybook.context,
                    targetGroup: firstPlaybook.targetGroup,
                    tone: firstPlaybook.tone,
                    language: language,
                }}
                userOrganizationName={name}
                userSelectedWebsite={website}
                domain={domainFromRedux}
                setupUserPromise={setupUserPromise}
            />
        );
    }

    return (
        <SplitView
            leftComponent={
                <>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            gap: '32px',
                        }}
                    >
                        {step === 'select purpose' && (
                            <SelectPurposeStep setPlaybook={setFirstPlaybook} onContinue={handleNextStep} />
                        )}
                        {step === 'select website' && (
                            <SelectWebsiteStep domain={website} setDomain={setWebsite} onContinue={handleNextStep} />
                        )}
                    </Box>
                </>
            }
            rightComponent={<AiPreferencesOnboardingInfoSection />}
        />
    );
};

function convertsCountryToLanguage(country) {
    const countryData = countries.find((c) => c.name === country);
    if (!countryData) {
        return 'English';
    }
    const language = countryData.languages.find((l) => l[0]);
    return language || 'English';
}

export const countries = [
    {
        name: 'United States',
        languages: ['English'],
    },
    {
        name: 'Canada',
        languages: ['English', 'French'],
    },
    {
        name: 'Mexico',
        languages: ['Spanish', 'English'],
    },
    {
        name: 'Argentina',
        languages: ['Spanish'],
    },
    {
        name: 'Brazil',
        languages: ['Portuguese', 'English'],
    },
    {
        name: 'United Kingdom',
        languages: ['English'],
    },
    {
        name: 'Germany',
        languages: ['German', 'English'],
    },
    {
        name: 'France',
        languages: ['French', 'English'],
    },
    {
        name: 'Spain',
        languages: ['Spanish', 'English'],
    },
    {
        name: 'Italy',
        languages: ['Italian', 'English'],
    },
    {
        name: 'Netherlands',
        languages: ['Dutch', 'English'],
    },
    {
        name: 'Belgium',
        languages: ['Dutch', 'French', 'German', 'English'],
    },
    {
        name: 'Switzerland',
        languages: ['German', 'French', 'Italian', 'English'],
    },
    {
        name: 'Sweden',
        languages: ['Swedish', 'English'],
    },
    {
        name: 'Norway',
        languages: ['Norwegian', 'English'],
    },
    {
        name: 'Denmark',
        languages: ['Danish', 'English'],
    },
    {
        name: 'Finland',
        languages: ['Finnish', 'Swedish', 'English'],
    },
    {
        name: 'Portugal',
        languages: ['Portuguese', 'English'],
    },
    {
        name: 'Greece',
        languages: ['Greek', 'English'],
    },
    {
        name: 'Poland',
        languages: ['Polish', 'English'],
    },
    {
        name: 'Austria',
        languages: ['German', 'English'],
    },
    {
        name: 'Ireland',
        languages: ['English', 'Irish'],
    },
    {
        name: 'Czech Republic',
        languages: ['Czech', 'English'],
    },
    {
        name: 'Hungary',
        languages: ['Hungarian', 'English'],
    },
    {
        name: 'Romania',
        languages: ['Romanian', 'English'],
    },
    {
        name: 'Bulgaria',
        languages: ['Bulgarian', 'English'],
    },
    {
        name: 'Slovakia',
        languages: ['Slovak', 'English'],
    },
    {
        name: 'Croatia',
        languages: ['Croatian', 'English'],
    },
    {
        name: 'Serbia',
        languages: ['Serbian', 'English'],
    },
    {
        name: 'Bosnia and Herzegovina',
        languages: ['Bosnian', 'Croatian', 'Serbian', 'English'],
    },
    {
        name: 'Montenegro',
        languages: ['Montenegrin', 'Serbian', 'English'],
    },
    {
        name: 'North Macedonia',
        languages: ['Macedonian', 'Albanian', 'English'],
    },
    {
        name: 'Albania',
        languages: ['Albanian', 'English'],
    },
    {
        name: 'Kosovo',
        languages: ['Albanian', 'Serbian', 'English'],
    },
    {
        name: 'Latvia',
        languages: ['Latvian', 'Russian', 'English'],
    },
    {
        name: 'Lithuania',
        languages: ['Lithuanian', 'Russian', 'English'],
    },
    {
        name: 'Estonia',
        languages: ['Estonian', 'Russian', 'English'],
    },
    {
        name: 'Ukraine',
        languages: ['Ukrainian', 'English'],
    },
];
