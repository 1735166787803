import { useLocation, useNavigate } from 'react-router-dom';
import { userOpenedGlobalSearch } from '../tracking';
import { useEffect, useState } from 'react';

export const useGlobalSearchModal = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(() => {
        return new URLSearchParams(location.search).get('globalSearch') === 'true';
    });

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        setIsOpen(searchParams.get('globalSearch') === 'true');
    }, [location.search]);

    const open = () => {
        const searchParams = new URLSearchParams(location.search);
        if (searchParams.get('globalSearch') === 'true') {
            return;
        }
        userOpenedGlobalSearch();
        searchParams.set('globalSearch', 'true');
        navigate(`${location.pathname}?${searchParams.toString()}`);
    };

    const close = () => {
        const searchParams = new URLSearchParams(location.search);
        if (searchParams.get('globalSearch') !== 'true') {
            return;
        }
        searchParams.delete('globalSearch');
        navigate(`${location.pathname}?${searchParams.toString()}`);
    };

    return {
        open,
        close,
        isOpen,
    };
};
