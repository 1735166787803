import { UseQueryOptions, UseSuspenseQueryOptions } from '@tanstack/react-query';
import { createMutationOptions } from './sdk-utils';
import { getZaplifySdk } from '@zaplify/sdk';
import { UpdateUserOrganizationDto, UserOrganizationComposedDto } from '@zaplify/users/shared';
import { UseMutationOptions } from '@tanstack/react-query';

// Base field mapping type
export interface BaseFieldMapping {
    required?: boolean;
    create?: {
        title: string;
        description: string;
    };
}

// HubSpot field mapping
export interface HubspotFieldMapping extends BaseFieldMapping {
    prospectField: string; // Andsend/Zaplify field
    contactField: string; // HubSpot field
}

// Pipedrive field mapping
export interface PipedriveFieldMapping extends BaseFieldMapping {
    prospectField: string; // Andsend/Zaplify field
    personField?: string; // Pipedrive person field
    organizationField?: string; // Pipedrive organization field
}

// Salesforce field mapping
export interface SalesforceFieldMapping extends BaseFieldMapping {
    source: {
        type: string;
        fieldName: string;
    };
    destination: {
        objectType: string;
        fieldName: string;
        required: boolean;
    };
}

export type FieldMapping = HubspotFieldMapping | PipedriveFieldMapping | SalesforceFieldMapping;

export type SettingsDto = {
    organizationId: string;
    account: {
        portalId?: string;
        timeZone?: string;
        currency?: string;
        uiDomain?: string;
        utcOffsetMilliseconds?: number;
        utcOffset?: string;
        organizationId?: string;
        organizationDomain?: string;
    };
    oauth: {
        accessToken: string;
        refreshToken: string;
        expiresAt: number;
    };
    integrationScope: {
        fields: string[];
        prospects: boolean;
        campaignsActivity: boolean;
        actionMessages: boolean;
        gdprDelete: boolean;
    };
    integrationStrategy?: 'ContactStrategy' | 'LeadStrategy';
    fieldMapping: FieldMapping[];
    isActive: boolean;
};

export const updateOrganizationName = () => {
    return createMutationOptions<
        void,
        Error,
        { organizationId: string; updateUserOrganizationDto: UpdateUserOrganizationDto }
    >({
        mutationFn: (variables) =>
            getZaplifySdk().profiles.userOrganization.updateOrganization(
                variables.organizationId,
                variables.updateUserOrganizationDto
            ),
        invalidateQueries: () => {
            // Invalidate any queries that might contain organization data
            return [['getUserAccounts']];
        },
    });
};
export type IntegrationType = 'HUBSPOT' | 'PIPEDRIVE' | 'SALESFORCE';

export interface OrganizationIntegration {
    type: IntegrationType;
    settings?: SettingsDto;
}

const getOrganizationIntegrationsFn = async (organizationId: string) => {
    const integrations: OrganizationIntegration[] = [];

    try {
        const hubspotSettings: SettingsDto = (await getZaplifySdk().profiles.hubspot.getSettings(
            organizationId
        )) as unknown as SettingsDto;

        integrations.push({ type: 'HUBSPOT', settings: hubspotSettings });
    } catch (error) {
        console.error('Failed to fetch HubSpot settings:', error);
    }

    try {
        const pipedriveSettings: SettingsDto = (await getZaplifySdk().profiles.pipedrive.getSettings(
            organizationId
        )) as unknown as SettingsDto;

        integrations.push({ type: 'PIPEDRIVE', settings: pipedriveSettings });
    } catch (error) {
        console.error('Failed to fetch Pipedrive settings:', error);
    }

    try {
        const salesforceSettings: SettingsDto = (await getZaplifySdk().profiles.salesforce.getSettings(
            organizationId
        )) as unknown as SettingsDto;

        integrations.push({ type: 'SALESFORCE', settings: salesforceSettings });
    } catch (error) {
        console.error('Failed to fetch Salesforce settings:', error);
    }

    return integrations;
};

type OrganizationIntegrationsData = Awaited<ReturnType<typeof getOrganizationIntegrationsFn>>;

export const getOrganizationIntegrations = (
    organizationId: string,
    options?: Omit<
        UseQueryOptions<OrganizationIntegrationsData, Error> &
            UseSuspenseQueryOptions<OrganizationIntegrationsData, Error>,
        'queryKey' | 'queryFn'
    >
): UseQueryOptions<OrganizationIntegrationsData, Error> &
    UseSuspenseQueryOptions<OrganizationIntegrationsData, Error> => {
    return {
        queryKey: ['getOrganizationIntegrations', organizationId],
        queryFn: () => getOrganizationIntegrationsFn(organizationId),
        enabled: !!organizationId,
        ...options,
    };
};

// Add these mutation functions to support our CRM integrations

// Update the integration scope (e.g., enable/disable prospects or action logging)
export const updateIntegrationScope = () => {
    return createMutationOptions<
        void,
        Error,
        {
            organizationId: string;
            integrationType: 'HUBSPOT' | 'PIPEDRIVE' | 'SALESFORCE';
            scope: string;
            value: boolean;
        }
    >({
        mutationFn: async (variables) => {
            // Get current settings to update
            const { organizationId, integrationType, scope, value } = variables;

            let settings;
            switch (integrationType) {
                case 'HUBSPOT':
                    settings = await getZaplifySdk().profiles.hubspot.getSettings(organizationId);
                    break;
                case 'PIPEDRIVE':
                    settings = await getZaplifySdk().profiles.pipedrive.getSettings(organizationId);
                    break;
                case 'SALESFORCE':
                    settings = await getZaplifySdk().profiles.salesforce.getSettings(organizationId);
                    break;
            }

            // Update the specific scope
            if (settings) {
                settings.integrationScope[scope] = value;

                // Update settings on the backend
                const updateSettingsObj = {
                    organizationId,
                    fieldMapping: settings.fieldMapping,
                    integrationScope: settings.integrationScope,
                    isActive: settings.isActive,
                };

                switch (integrationType) {
                    case 'HUBSPOT':
                        await getZaplifySdk().profiles.hubspot.updateSettings(updateSettingsObj, (error: any) => {
                            console.error('Failed to update HubSpot settings:', error);
                        });
                        break;
                    case 'PIPEDRIVE':
                        await getZaplifySdk().profiles.pipedrive.updateSettings(updateSettingsObj, (error: any) => {
                            console.error('Failed to update Pipedrive settings:', error);
                        });
                        break;
                    case 'SALESFORCE':
                        await getZaplifySdk().profiles.salesforce.updateSettings(updateSettingsObj, (error: any) => {
                            console.error('Failed to update Salesforce settings:', error);
                        });
                        break;
                }
            }
        },
        invalidateQueries: () => [['getOrganizationIntegrations']],
    });
};

// Update field mappings for an integration
export const updateFieldMapping = () => {
    return createMutationOptions<
        void,
        Error,
        {
            organizationId: string;
            integrationType: 'HUBSPOT' | 'PIPEDRIVE' | 'SALESFORCE';
            fieldMapping: any[];
            integrationStrategy?: 'ContactStrategy' | 'LeadStrategy';
            isActive?: boolean;
        }
    >({
        mutationFn: async (variables) => {
            const { organizationId, integrationType, fieldMapping, integrationStrategy, isActive = true } = variables;

            let settings;
            switch (integrationType) {
                case 'HUBSPOT':
                    settings = await getZaplifySdk().profiles.hubspot.getSettings(organizationId);
                    break;
                case 'PIPEDRIVE':
                    settings = await getZaplifySdk().profiles.pipedrive.getSettings(organizationId);
                    break;
                case 'SALESFORCE':
                    settings = await getZaplifySdk().profiles.salesforce.getSettings(organizationId);
                    break;
            }

            if (settings) {
                // Update settings on the backend
                const updateSettingsObj = {
                    organizationId,
                    fieldMapping: fieldMapping,
                    integrationScope: settings.integrationScope,
                    isActive: isActive,
                };

                switch (integrationType) {
                    case 'HUBSPOT':
                        await getZaplifySdk().profiles.hubspot.updateSettings(updateSettingsObj, (error: any) => {
                            console.error('Failed to update HubSpot settings:', error);
                        });
                        break;
                    case 'PIPEDRIVE':
                        await getZaplifySdk().profiles.pipedrive.updateSettings(updateSettingsObj, (error: any) => {
                            console.error('Failed to update Pipedrive settings:', error);
                        });
                        break;
                    case 'SALESFORCE':
                        if (integrationStrategy) {
                            updateSettingsObj['integrationStrategy'] = integrationStrategy as
                                | 'ContactStrategy'
                                | 'LeadStrategy';
                        }
                        await getZaplifySdk().profiles.salesforce.updateSettings(updateSettingsObj, (error: any) => {
                            console.error('Failed to update Salesforce settings:', error);
                        });
                        break;
                }
            }
        },
        invalidateQueries: () => [['getOrganizationIntegrations']],
    });
};

// Fetch available field mappings for an integration
export type AvailableHubspotFields = {
    contact: {
        name: string;
        label: string;
        fieldType: string;
    }[];
    prospect: {
        name: string;
        label: string;
        fieldType: string;
    }[];
};

export type AvailablePipedriveFields = {
    person: {
        key: string;
        name: string;
        fieldType: string;
    }[];
    organization: {
        key: string;
        name: string;
        fieldType: string;
    }[];
    prospect: {
        key: string;
        name: string;
        fieldType: string;
    }[];
};

export type AvailableSalesforceContactFields = {
    contact: {
        name: string;
        label: string;
        fieldType: string;
    }[];
    contactPickList: {
        name: string;
        label: string;
        fieldType: string;
    }[];
    account: {
        name: string;
        label: string;
        fieldType: string;
    }[];
    accountPickList: {
        name: string;
        label: string;
        fieldType: string;
    }[];
    prospect: {
        name: string;
        label: string;
        fieldType: string;
    }[];
};

export type AvailableSalesforceLeadFields = {
    lead: {
        name: string;
        label: string;
        fieldType: string;
    }[];
    leadPickList: {
        name: string;
        label: string;
        fieldType: string;
    }[];
    prospect: {
        name: string;
        label: string;
        fieldType: string;
    }[];
};

export type AvailableFields =
    | AvailableHubspotFields
    | AvailablePipedriveFields
    | AvailableSalesforceContactFields
    | AvailableSalesforceLeadFields;

const getAvailableFieldsFn = async (
    organizationId: string,
    integrationType: IntegrationType,
    integrationStrategy?: string
): Promise<AvailableFields> => {
    try {
        switch (integrationType) {
            case 'HUBSPOT':
                return (await getZaplifySdk().profiles.hubspot.getAllFields(organizationId)) as AvailableHubspotFields;
            case 'PIPEDRIVE':
                return (await getZaplifySdk().profiles.pipedrive.getAllFields(
                    organizationId
                )) as AvailablePipedriveFields;
            case 'SALESFORCE': {
                const result = await getZaplifySdk().profiles.salesforce.getAllFields(
                    organizationId,
                    integrationStrategy
                );
                if (integrationStrategy === 'leadStrategy') {
                    return result as AvailableSalesforceLeadFields;
                } else {
                    return result as AvailableSalesforceContactFields;
                }
            }
            default:
                throw new Error(`Unknown integration type: ${integrationType}`);
        }
    } catch (error) {
        console.error(`Failed to fetch available fields for ${integrationType}:`, error);
        if (integrationType === 'HUBSPOT') {
            return { contact: [], prospect: [] } as AvailableHubspotFields;
        } else if (integrationType === 'PIPEDRIVE') {
            return { person: [], organization: [], prospect: [] } as AvailablePipedriveFields;
        } else {
            return integrationStrategy === 'leadStrategy'
                ? ({ lead: [], leadPickList: [], prospect: [] } as AvailableSalesforceLeadFields)
                : ({
                      contact: [],
                      contactPickList: [],
                      account: [],
                      accountPickList: [],
                      prospect: [],
                  } as AvailableSalesforceContactFields);
        }
    }
};

export const getAvailableFields = (
    organizationId: string,
    integrationType: IntegrationType,
    integrationStrategy?: string,
    options?: Omit<
        UseQueryOptions<AvailableFields, Error> & UseSuspenseQueryOptions<AvailableFields, Error>,
        'queryKey' | 'queryFn'
    >
): UseQueryOptions<AvailableFields, Error> & UseSuspenseQueryOptions<AvailableFields, Error> => {
    return {
        queryKey: ['getAvailableFields', organizationId, integrationType, integrationStrategy],
        queryFn: () => getAvailableFieldsFn(organizationId, integrationType, integrationStrategy),
        enabled: !!organizationId,
        ...options,
    };
};

const getOrganizationByUserFn = async (userId: string) => {
    return await getZaplifySdk().profiles.userOrganization.getOrganizationByUser(userId);
};

export const getOrganizationByUser = (
    userId: string,
    options?: Omit<UseQueryOptions<UserOrganizationComposedDto[], Error>, 'queryKey' | 'queryFn'>
): UseQueryOptions<UserOrganizationComposedDto[], Error> => {
    return {
        queryKey: ['getOrganizationByUser', userId],
        queryFn: () => getOrganizationByUserFn(userId),
        ...options,
    };
};

// Fix the useMutation hooks with correct typing

export type ConnectHubspotParams = {
    organizationId: string;
    code: string;
};

export type ConnectPipedriveParams = {
    organizationId: string;
    code: string;
};

export type ConnectSalesforceParams = {
    organizationId: string;
    code: string;
    loginUrl?: string;
};

// Connection mutations
export const connectHubspot = (
    options?: Omit<UseMutationOptions<void, Error, { organizationId: string; code: string }>, 'mutationFn'>
): UseMutationOptions<void, Error, { organizationId: string; code: string }> => {
    return createMutationOptions<void, Error, { organizationId: string; code: string }>({
        mutationFn: ({ organizationId, code }) => getZaplifySdk().profiles.hubspot.connect(organizationId, code),
        invalidateQueries: () => [['getOrganizationIntegrations']],
        ...options,
    });
};

export const connectPipedrive = (
    options?: Omit<UseMutationOptions<void, Error, { organizationId: string; code: string }>, 'mutationFn'>
): UseMutationOptions<void, Error, { organizationId: string; code: string }> => {
    return createMutationOptions<void, Error, { organizationId: string; code: string }>({
        mutationFn: ({ organizationId, code }) => getZaplifySdk().profiles.pipedrive.connect(organizationId, code),
        invalidateQueries: () => [['getOrganizationIntegrations']],
        ...options,
    });
};

export const connectSalesforce = (
    options?: Omit<
        UseMutationOptions<void, Error, { organizationId: string; code: string; loginUrl: string }>,
        'mutationFn'
    >
): UseMutationOptions<void, Error, { organizationId: string; code: string; loginUrl: string }> => {
    return createMutationOptions<void, Error, { organizationId: string; code: string; loginUrl: string }>({
        mutationFn: ({ organizationId, code, loginUrl }) =>
            getZaplifySdk().profiles.salesforce.connect(organizationId, code, loginUrl),
        invalidateQueries: () => [['getOrganizationIntegrations']],
        ...options,
    });
};

// Export all functions
export const userOrganizationSdk = {
    updateOrganizationName,
    getOrganizationIntegrations,
    updateIntegrationScope,
    updateFieldMapping,
    getAvailableFields,
    connectHubspot,
    connectPipedrive,
    connectSalesforce,
};
