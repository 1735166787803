import { BaseSdk } from '../../base.sdk';

export class AppsSdk extends BaseSdk {
    async bulkSyncPersons(personIds: string[]) {
        return this.post<void>({ path: `${this.appsPath}/prospects/bulk-sync`, payload: personIds });
    }

    async bulkSyncCompanies(personsByCompanies: { [key: string]: string[] }) {
        return this.post<void>({ path: `${this.appsPath}/prospects/bulk-sync-companies`, payload: personsByCompanies });
    }
}
