import { FC, useMemo, useState } from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@shadcn/ui/components/ui/card';
import { Checkbox } from '@shadcn/ui/components/ui/checkbox';
import { Label } from '@shadcn/ui/components/ui/label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@shadcn/ui/components/ui/select';
import { BaseChart, OMTMChart } from './charts/BaseChart';
import { usePerformanceMetrics } from '../hooks/use-chart-data';
import { cn } from '@shadcn/ui/lib/utils';
import { Separator } from '@shadcn/ui/components/ui/separator';
import moment from 'moment';
import { StatsCard } from './stats-card';

interface PerformanceMetricsCardProps {
    startDate: Date;
    endDate: Date;
}

const chartConfig = {
    within30Days: {
        label: 'Within 30 Days',
        theme: {
            light: 'hsl(15.3 74.6% 27.8%)',
            dark: 'hsl(15.3 74.6% 27.8%)',
        },
    },
    within7Days: {
        label: 'Within 7 Days',
        theme: {
            light: 'hsl(322.7 81.1% 41.4%)',
            dark: 'hsl(322.7 81.1% 41.4%)',
        },
    },
    within3Days: {
        label: 'Within 3 Days',
        theme: {
            light: 'hsl(291.5 95.5% 58.8%)',
            dark: 'hsl(291.5 95.5% 58.8%)',
        },
    },
    within2Days: {
        label: 'Within 2 Days',
        theme: {
            light: 'hsl(262.1 83.3% 57.8%)',
            dark: 'hsl(263.4 70% 50.4%)',
        },
    },
    sameDay: {
        label: 'Same Day',
        theme: {
            light: 'hsl(221.2 83.2% 53.3%)',
            dark: 'hsl(217.2 91.2% 59.8%)',
        },
    },
    trailingTotal: {
        label: 'Total',
        theme: {
            light: 'hsl(221.2 83.2% 53.3%)',
            dark: 'hsl(217.2 91.2% 59.8%)',
        },
    },
};

const colorClass = (value: number, previousValue: number) => {
    if (value > previousValue) {
        return 'text-text-success-primary';
    } else if (value < previousValue) {
        return 'text-text-error-primary';
    } else {
        return 'text-muted-foreground';
    }
};

export const PerformanceMetricsCard: FC<PerformanceMetricsCardProps> = ({ startDate, endDate }) => {
    // const [showMore, setShowMore] = useState(false);
    const [visibleLines, setVisibleLines] = useState<Record<string, boolean>>({
        sameDay: true,
        within2Days: true,
        within3Days: true,
        within7Days: true,
        within30Days: true,
    });
    const [frequency, setFrequency] = useState<'daily' | 'weekly'>('daily');
    const {
        omtmData,
        trailingOmtmData,
        suggestionsData,
        completionData,
        goalsReachedData,
        metrics,
        loading,
        error,
        numberOfUsers,
        numberOfUsersBeforePeriod,
    } = usePerformanceMetrics({
        startDate,
        endDate,
        frequency,
    });

    const periodName = useMemo(() => {
        const start = moment(startDate || new Date());
        const end = moment(endDate || new Date());
        return `since ${end.from(start, true)} ago`;
    }, [startDate, endDate]);

    if (error) {
        console.error(error);
        return null;
    }

    if (loading || !omtmData.length) {
        return null;
    }

    const latestMetrics = trailingOmtmData[trailingOmtmData.length - 1];
    const earliestMetrics = trailingOmtmData[0];
    const percentageChange = Number(
        ((latestMetrics.trailingTotal - earliestMetrics.trailingTotal) / earliestMetrics.trailingTotal) * 100
    ).toFixed(2);

    const toggleLine = (key: string) => {
        setVisibleLines((prev) => ({
            ...prev,
            [key]: !prev[key],
        }));
    };

    return (
        <div className="flex flex-col gap-8">
            <Card className="w-full">
                <CardHeader>
                    <div className="flex justify-between items-center">
                        <CardTitle>One Metric That Matters</CardTitle>
                    </div>
                    <CardDescription>
                        Score: {latestMetrics.trailingTotal.toFixed(1)} ({percentageChange}% from{' '}
                        {earliestMetrics.trailingTotal.toFixed(1)})
                    </CardDescription>
                </CardHeader>
                <CardContent>
                    <OMTMChart
                        data={trailingOmtmData}
                        visibleLines={{ trailingTotal: true }}
                        chartConfig={chartConfig}
                    />
                </CardContent>
            </Card>
            {/* <Button className="max-w-40" variant="outline" onClick={() => setShowMore(!showMore)}>
                {showMore ? 'Show Less' : 'Show More'}
            </Button> */}

            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <StatsCard
                    title="Total Suggestions"
                    description="The number of suggestions that have been created until the end of the period."
                    currentValue={latestMetrics.raw.trailingTotalSuggestions}
                    previousValue={earliestMetrics.raw.trailingTotalSuggestions}
                    periodName={periodName}
                />
                <StatsCard
                    title="Total Actions"
                    description="The number of actions that have been completed until the end of the period."
                    currentValue={latestMetrics.raw.trailingTotalCompleted}
                    previousValue={earliestMetrics.raw.trailingTotalCompleted}
                    periodName={periodName}
                />
                <StatsCard
                    title="Total Goals Reached"
                    description="The number of goals that have been reached until the end of the period."
                    currentValue={latestMetrics.raw.trailingTotalGoalsReached}
                    previousValue={earliestMetrics.raw.trailingTotalGoalsReached}
                    periodName={periodName}
                />
            </div>
            <Separator />
            <h1 className="text-2xl font-bold">Per User</h1>

            <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                <StatsCard
                    title="Total Users"
                    description="The number of users that have made at least one suggestion."
                    currentValue={numberOfUsers}
                    previousValue={numberOfUsersBeforePeriod}
                    periodName={periodName}
                />
                <StatsCard
                    title="Total Suggestions"
                    description="The number of suggestions per user."
                    currentValue={latestMetrics.raw.trailingTotalSuggestions / numberOfUsers}
                    previousValue={earliestMetrics.raw.trailingTotalSuggestions / numberOfUsers}
                    periodName={periodName}
                />
                <StatsCard
                    title="Total Actions"
                    description="The number of actions per user."
                    currentValue={latestMetrics.raw.trailingTotalCompleted / numberOfUsers}
                    previousValue={earliestMetrics.raw.trailingTotalCompleted / numberOfUsers}
                    periodName={periodName}
                />
                <StatsCard
                    title="Total Goals Reached"
                    description="The number of goals reached per user."
                    currentValue={latestMetrics.raw.trailingTotalGoalsReached / numberOfUsers}
                    previousValue={earliestMetrics.raw.trailingTotalGoalsReached / numberOfUsers}
                    periodName={periodName}
                />
            </div>
            <Separator />
            <Card className="w-full">
                <CardHeader>
                    <div className="flex justify-between items-center">
                        <CardTitle>Daily OMTM</CardTitle>
                        <Select value={frequency} onValueChange={(value: 'daily' | 'weekly') => setFrequency(value)}>
                            <SelectTrigger className="w-[120px]">
                                <SelectValue placeholder="Select frequency" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value="daily">Daily</SelectItem>
                                <SelectItem value="weekly">Weekly</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>
                    <CardDescription>
                        Score: {omtmData[omtmData.length - 1].sameDay.toFixed(1)} ({percentageChange}% from{' '}
                        {omtmData[0].sameDay.toFixed(1)})
                    </CardDescription>
                    <div className="flex flex-wrap gap-4 mt-2">
                        {Object.entries(chartConfig).map(([key, config]) => (
                            <div key={key} className="flex items-center space-x-2">
                                <Checkbox
                                    id={key}
                                    checked={visibleLines[key]}
                                    onCheckedChange={() => toggleLine(key)}
                                    className="data-[state=checked]:bg-primary data-[state=checked]:border-primary"
                                />
                                <Label
                                    htmlFor={key}
                                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                    style={{ color: config.theme.light }}
                                >
                                    {config.label}
                                </Label>
                            </div>
                        ))}
                    </div>
                </CardHeader>
                <CardContent>
                    <BaseChart data={omtmData} visibleLines={visibleLines} chartConfig={chartConfig} />
                </CardContent>
            </Card>
            <div className="flex flex-col gap-8">
                <h1 className="text-2xl font-bold">Breakdown Metrics</h1>
                <Card className="w-full">
                    <CardHeader>
                        <div className="flex justify-between items-center">
                            <CardTitle>Suggestions</CardTitle>
                        </div>
                        <CardDescription>The number of suggestions that were created on a given day.</CardDescription>
                    </CardHeader>
                    <CardContent>
                        <BaseChart data={suggestionsData} visibleLines={visibleLines} chartConfig={chartConfig} />
                    </CardContent>
                </Card>
                <Card className="w-full">
                    <CardHeader>
                        <div className="flex justify-between items-center">
                            <CardTitle>Completed Actions</CardTitle>
                        </div>
                        <CardDescription>
                            The number of actions that were completed from a given day, within the time period.
                        </CardDescription>
                    </CardHeader>
                    <CardContent>
                        <BaseChart data={completionData} visibleLines={visibleLines} chartConfig={chartConfig} />
                    </CardContent>
                </Card>
                <Card className="w-full">
                    <CardHeader>
                        <div className="flex justify-between items-center">
                            <CardTitle>Goals Reached</CardTitle>
                        </div>
                        <CardDescription>
                            The number of goals that were reached from a given day, within the time period.
                        </CardDescription>
                    </CardHeader>
                    <CardContent>
                        <BaseChart data={goalsReachedData} visibleLines={visibleLines} chartConfig={chartConfig} />
                    </CardContent>
                </Card>
            </div>
        </div>
    );
};
