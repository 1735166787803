import { ICommand, Topics } from '@zaplify/common';
import { PersonFilterDto } from '../dtos';

export class UpdateProspectSearchCommand extends ICommand<PersonFilterDto> {
    public static override readonly TOPIC = Topics.PROSPECTS;
    constructor(
        readonly userId: string,
        readonly filterId: string,
        readonly personFilterDto: Partial<
            Pick<
                PersonFilterDto,
                'organizationFilter' | 'personalFilter' | 'crmFilters' | 'activityFilters' | 'options'
            >
        >,
    ) {
        super();
    }
}
