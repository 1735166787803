import { Box } from '@mui/material';
import { AccountResponseDto, GlobalSearchPeopleInCompanyResultDto } from '@zaplify/prospects';
import { useEffect, useState } from 'react';
import { useAccount } from '../hooks/Account';
import { PersonTable } from './PeopleTable/PersonTable';
import { HeaderSection } from './HeaderSection';
import { DescriptionSection } from './DescriptionSection';
import { TagsSection } from './TagsSection';
import { DetailsSection } from './DetailsSection';
import { SectionTitle } from '../SectionTitle';
import { useGlobalSearch } from '../hooks/GlobalSearch';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const AccountCard = ({
    account,
    groups,
    userId,
}: {
    account: AccountResponseDto;
    groups: { id: string; name: string }[];
    userId: string;
}) => {
    const defaultNumberOfPeopleShown = 10;
    const [prospects, setProspects] = useState<GlobalSearchPeopleInCompanyResultDto | null>(null);
    const [numberOfPeopleShown, setNumberOfPeopleShown] = useState(defaultNumberOfPeopleShown);
    const { onAddPerson } = useAccount();
    const { searchInCompany: searchGlobalSearch } = useGlobalSearch();

    async function searchInCompany(companyId: string, searchTerm: string, perPage: number, page: number) {
        const query = {
            match: searchTerm,
            perPage: perPage,
            page: page,
            companyId: companyId,
        };
        return searchGlobalSearch(query);
    }

    useEffect(() => {
        setProspects(null);
        setNumberOfPeopleShown(defaultNumberOfPeopleShown);
        if (account.id) {
            searchInCompany(account.id, '', numberOfPeopleShown, 1).then((prospects) => {
                setProspects(prospects);
            });
        }
    }, [account?.id]);

    useEffect(() => {
        if (numberOfPeopleShown === defaultNumberOfPeopleShown) return;
        updateProspects();
    }, [numberOfPeopleShown]);

    const updateProspects = async () => {
        const newProspects = await searchInCompany(account.id, '', numberOfPeopleShown, 1);
        setProspects(newProspects);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                padding: '18px',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: 'var(--M-m-compact, 16px)',
                flex: '1 0 0',
                alignSelf: 'stretch',
                overflowX: 'hidden',
                overflowY: 'auto',
                height: '550px',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: 'var(--S-s-spacious, 12px)',
                    alignSelf: 'stretch',
                    height: '100%',
                }}
            >
                <HeaderSection account={account} />
                <SectionTitle title="Company details" />
                <DescriptionSection account={account} />
                <TagsSection account={account} />
                <DetailsSection account={account} />
                <SectionTitle title="People" />
                <PersonTable
                    userId={userId}
                    prospects={prospects}
                    groups={groups}
                    onAdd={onAddPerson}
                    setNumberOfPeopleShown={setNumberOfPeopleShown}
                    companyId={account.id}
                />
            </Box>
        </Box>
    );
};
