import { BriefcaseIcon, Building2, LinkedinIcon, MapPin } from 'lucide-react';
import { useSuggestionsFeed } from '../../../hooks/use-suggestions-feed';
import { Skeleton } from '@shadcn/ui/components/ui/skeleton';
import { useMemo } from 'react';

export const ContactDetails = () => {
    const {
        currentContactSuggestionProfileData: profileData,
        isFetchingCurrentLinkedinProfile,
        currentContactSuggestion,
    } = useSuggestionsFeed();

    const companyName = profileData.organizationName || currentContactSuggestion?.accountName;
    const title = profileData.occupationTitle || currentContactSuggestion?.contactTitle;
    const location = profileData.location || currentContactSuggestion?.contactLocation;
    const numberOfConnections = profileData.linkedinNumberOfConnections
        ? profileData.linkedinNumberOfConnections + ' connections'
        : '';

    const info = useMemo(
        () =>
            [
                {
                    icon: <Building2 className="w-4 h-4 text-muted-foreground" />,
                    label: companyName,
                    loading: !companyName && isFetchingCurrentLinkedinProfile,
                },
                {
                    icon: <BriefcaseIcon className="w-4 h-4 text-muted-foreground" />,
                    label: title,
                    loading: !title && isFetchingCurrentLinkedinProfile,
                },
                {
                    icon: <MapPin className="w-4 h-4 text-muted-foreground" />,
                    label: location,
                    loading: !location && isFetchingCurrentLinkedinProfile,
                },
                {
                    icon: <LinkedinIcon className="w-4 h-4 text-muted-foreground" />,
                    label: numberOfConnections,
                    loading: isFetchingCurrentLinkedinProfile,
                },
            ].filter((item) => item.label || item.loading),
        [companyName, title, location, numberOfConnections, isFetchingCurrentLinkedinProfile]
    );

    return (
        <div className="flex flex-col items-start gap-2 animate-in fade-in-60 duration-100">
            <p className="text-sm font-medium flex flex-row items-center gap-2">
                Contact details
                {isFetchingCurrentLinkedinProfile && (
                    <LinkedinIcon className="w-4 h-4 animate-pulse text-text-brand-primary-subtle" />
                )}
            </p>
            <div className="flex flex-col gap-2 text-muted-foreground">
                {info.map((item) => (
                    <div className="flex flex-row items-center gap-2">
                        {item.icon}
                        {item.loading ? <Skeleton className="h-4 w-56" /> : <p className="text-sm">{item.label}</p>}
                    </div>
                ))}
            </div>
        </div>
    );
};
