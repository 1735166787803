import { Badge } from '@shadcn/ui/components/ui/badge';
import React from 'react';

interface CompanyTagProps {
    children?: React.ReactNode;
    className?: string;
}

export const CompanyTag: React.FC<CompanyTagProps> = ({ children, className }) => {
    return (
        <Badge variant="outline" className={`border-border-primary rounded-md ${className}`}>
            {children}
        </Badge>
    );
};
