import { getAuthenticationToken } from '@zaplify/utils';
import { createContext, useEffect, useRef, useState } from 'react';
import { connect } from 'socket.io-client';
import { useAppSelector } from '../redux/store/configureStore';

const WebSocketContext = createContext([]);
const WebSocketProvider = ({ children }) => {
    const ws = useRef(null);
    const socketEndpoint = import.meta.env.VITE_SOCKET_ENDPOINT;
    const [token, setToken] = useState<string>('');
    const [isConnected, setIsConnected] = useState(false);
    const [subscribe, setSubscribe] = useState(() => () => {});
    const [unsubscribe, setUnsubscribe] = useState(() => () => {});
    const userOrganizationId = useAppSelector((state) => state.user.zaplifyUser?.userOrganization?.id);
    const userId = useAppSelector((state) => state.user.zaplifyUser?.id);

    useEffect(() => {
        if (!userId || !userOrganizationId) return;
        console.log(`WS: Conecting socket for userId: ${userId} and organizationId: ${userOrganizationId}`);
        if (!token || !userId) {
            setIsConnected(false);
            return;
        }
        ws.current = connect(socketEndpoint, {
            timeout: 5000,
            transports: ['websocket'],
            query: {
                authorization: `Bearer ${token}`,
            },
        });
        setSubscribe(() => (channel, callback) => {
            ws.current?.on(channel, callback);
        });
        setUnsubscribe(() => (channel, callback) => {
            ws.current?.off(channel, callback);
        });
        ws.current.on('connect', () => {
            console.log('WS: Connected', new Date());
            setIsConnected(true);
        });
        ws.current.on('error', (err) => {
            console.error('WS: Got error', new Date(), err);
        });
        return () => {
            ws.current.close();
            setIsConnected(false);
        };
    }, [token]);

    // @TODO We could find a better way to get the token atomically by centralizing the token together with userId and organizationId in a global state
    useEffect(() => {
        const getToken = async () => {
            console.log('Getting token for socket');
            const token = await getAuthenticationToken();
            !!token && token.token.length > 0 && setToken(token.token);
        };
        getToken();
    }, [userId, userOrganizationId]);

    return (
        <WebSocketContext.Provider value={[subscribe, unsubscribe, isConnected]}>{children}</WebSocketContext.Provider>
    );
};
export { WebSocketContext, WebSocketProvider };
