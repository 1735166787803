import { useSubscription } from '@apollo/client';
import { ASSISTANT_SETTINGS_SUBSCRIPTION } from '@zaplify/graphql';
import { useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { assistantSettingsAtom, assistantSettingsLoadingAtom } from '../../atoms/assistantSettings';

export const AssistantSettingsSubscription = () => {
    const setAssistantSettings = useSetAtom(assistantSettingsAtom);
    const setLoading = useSetAtom(assistantSettingsLoadingAtom);

    const { loading } = useSubscription(ASSISTANT_SETTINGS_SUBSCRIPTION, {
        onData: (data) => {
            if (data.data.data?.AssistantSettings?.length) {
                const newAssistantSettings = data.data.data.AssistantSettings.reduce((acc, assistantSettings) => {
                    acc[assistantSettings.id] = assistantSettings;
                    return acc;
                }, {});
                setAssistantSettings(newAssistantSettings);
            }
        },
    });
    useEffect(() => {
        setLoading(loading);
    }, [loading]);

    return null;
};
