import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@shadcn/ui/lib/utils';

const keyboardSymbolVariants = cva(
    'pointer-events-none inline-flex h-full select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-xs font-medium text-muted-foreground',
    {
        variants: {
            variant: {
                default: '',
            },
        },
        defaultVariants: {
            variant: 'default',
        },
    },
);

export interface KeyboardSymbolProps
    extends React.HTMLAttributes<HTMLDivElement>,
        VariantProps<typeof keyboardSymbolVariants> {}

function KeyboardSymbol({ className, variant, ...props }: KeyboardSymbolProps) {
    return <kbd className={cn(keyboardSymbolVariants({ variant }), className)} {...props} />;
}

function OSMetaKey() {
    const isMac = navigator.userAgent.includes('Mac');
    return isMac ? '⌘' : 'Ctrl';
}

export { KeyboardSymbol, OSMetaKey };
