import { Tooltip } from '@mui/material';
import { BookUser, MessagesSquare } from 'lucide-react';
import { paths } from '../../../../routes/paths';
import { NavigationMainItem } from './navigation-main-item';
import { useResetAtom } from 'jotai/utils';
import { messageComposerStateAtom } from '../../../../atoms/chats';
import { useLocation } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const ContactsListItem = ({ openSidebar }: { openSidebar: boolean }) => {
    const { pathname } = useLocation();
    const { useNewMyContactsTable } = useFlags();
    const isActive = pathname.startsWith(paths.CHATS);
    const resetMessageComposerStateAtom = useResetAtom(messageComposerStateAtom);

    return (
        <Tooltip title={openSidebar ? '' : 'Contacts'} placement="right" arrow>
            {/* important className for Frigade */}
            <span onClick={resetMessageComposerStateAtom} className="all-conversations-nav-item">
                <NavigationMainItem
                    title="Contacts"
                    to={`${useNewMyContactsTable ? '/new' : ''}/contacts`}
                    icon={<BookUser size={20} strokeWidth="1px" />}
                    isActive={isActive}
                    isSidebarPinned={openSidebar}
                />
            </span>
        </Tooltip>
    );
};
