import { IQuery, Topics } from '@zaplify/common';
import { CompanyBlocklistDto } from '../prospects';

export class GetBlocklistByOrganizationQuery extends IQuery<CompanyBlocklistDto> {
    public static override readonly TOPIC = Topics.PROSPECTS;

    constructor(readonly organizationId: string) {
        super();
    }
}
