import { useAtom, useAtomValue } from 'jotai';
import { useEffect } from 'react';
import {
    completedOutreachSuggestionsAtom,
    completedOutreachSuggestionsLoadingAtom,
    outreachSuggestionsAtom,
    outreachSuggestionsLoadingAtom,
    prospectIdToIsGeneratingMessageAtom,
} from '../../../atoms/chats';
import { getZaplifySdk } from '@zaplify/sdk';
import { isImpersonatingAUser } from '../../../helpers/is-global-admin';

export const useOutreachSuggestions = () => {
    const [prospectIdToIsGeneratingMessage, setProspectIdToIsGeneratingMessage] = useAtom(
        prospectIdToIsGeneratingMessageAtom
    );
    const outreachSuggestions = useAtomValue(outreachSuggestionsAtom);
    const completedOutreachSuggestions = useAtomValue(completedOutreachSuggestionsAtom);
    const outreachSuggestionsLoading = useAtomValue(outreachSuggestionsLoadingAtom);
    const completedOutreachSuggestionsLoading = useAtomValue(completedOutreachSuggestionsLoadingAtom);

    useEffect(() => {
        setProspectIdToIsGeneratingMessage((prev) => {
            const newMap = new Map(prev);
            outreachSuggestions.forEach((suggestion) => {
                if (suggestion.Message.content) {
                    newMap.set(suggestion.prospectId, false);
                } else {
                    newMap.set(suggestion.prospectId, true);
                }
            });
            return newMap;
        });
    }, [outreachSuggestions]);

    return {
        outreachSuggestions,
        outreachSuggestionsLoading,
        completedOutreachSuggestions,
        completedOutreachSuggestionsLoading,
        prospectIdToIsGeneratingMessage,
    };
};

export const useOutreachSuggestionsActions = () => {
    const markSuggestionRead = async (suggestionId: string) => {
        if (await isImpersonatingAUser()) {
            return;
        }
        getZaplifySdk().profiles.outreachSuggestions.markReadOutreachSuggestion(suggestionId);
    };

    return {
        markSuggestionRead,
    };
};
