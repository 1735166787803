import { IEvent, Topics } from '@zaplify/common';
import { ChannelProvider, ChannelType } from '../types';

type IChannelAccountMetadata = { emailAddress?: string; linkedinProfileUrl?: string; linkedinUserId?: string };

export class ChannelAccountConnectedEvent extends IEvent {
    public static override readonly TOPIC = Topics.CHANNEL_ACCOUNTS;

    constructor(
        readonly userId: string,
        readonly organizationId: string,
        readonly channelAccountId: string,
        readonly type: ChannelType,
        readonly provider: ChannelProvider,
        readonly data: IChannelAccountMetadata,
    ) {
        super();
    }

    get partitionKey(): string {
        return this.channelAccountId;
    }
}
