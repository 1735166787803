import React from 'react';
import { Avatar, AvatarFallback, AvatarImage } from '@shadcn/ui/components/ui/avatar';
import { Building2Icon } from 'lucide-react';
import { PreviewProspectDetailsDto } from '@zaplify/prospects';

export const ContactWorkExperience = ({ experience }: { experience: PreviewProspectDetailsDto['experiences'][0] }) => {
    return (
        <div className="rounded-xl bg-background-tertiary text-sm w-full">
            <div className="flex flex-row gap-2">
                <Avatar size="sm">
                    <AvatarImage src={`https://logo.clearbit.com/${experience.organizationDomain}`} />
                    <AvatarFallback>
                        <Building2Icon className="w-4 h-4" />
                    </AvatarFallback>
                </Avatar>
                <div className="flex flex-col">
                    <p className="font-medium line-clamp-1">
                        {experience.occupationTitle} <span className="font-normal">-</span>{' '}
                        {experience.organizationName}
                    </p>
                    <p className="text-muted-foreground">{`${experience.occupationStartDate || 'Unknown'} - ${
                        experience.occupationEndDate || 'Present'
                    }`}</p>
                </div>
            </div>
        </div>
    );
};
