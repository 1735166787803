import { CircularProgress } from '@mui/material';
import { useAtom } from 'jotai';
import { SearchTypes, companyNamesDataAtom, companyNamesLoadingAtom } from '../../../atoms/globalSearch';
import { AccountCard } from '../AccountCard/AccountCard';
import AccountRow from '../AccountRow';
import CategoryExpandShrink from '../CategoryShowMore';
import { SearchSectionTitle } from '../globalSearchPopup';
import { useGlobalSearch } from '../hooks/GlobalSearch';

export const CompanyNameSearchSection = ({
    userId,
    groups,
    searchValue,
    onClick,
}: {
    userId: string;
    groups: { id: string; name: string }[];
    searchValue: string;
    onClick: (node: React.ReactNode) => void;
}) => {
    const [companyNames, setCompanyNames] = useAtom(companyNamesDataAtom);
    const [loading, setLoading] = useAtom(companyNamesLoadingAtom);
    const pagination = companyNames?.pagination;
    const numberOfCompanyNames = companyNames?.data?.length;
    const globalSearch = useGlobalSearch();

    const handleShowMore = async () => {
        setLoading(true);
        try {
            const query = {
                match: searchValue,
                perPage: 5,
                page: pagination.page + 1,
                searchIn: [SearchTypes.COMPANY_NAMES],
            };

            const response = await globalSearch.search(query);
            const newCompanyNames = {
                data: [...companyNames.data, ...response?.companyNames?.data],
                pagination: response?.companyNames?.pagination,
            };
            setCompanyNames(newCompanyNames);
        } finally {
            setLoading(false);
        }
    };

    if (numberOfCompanyNames === 0) return null;
    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <SearchSectionTitle title="Company name matches" size="12px" />
                {!companyNames?.data && loading && <CircularProgress size="12px" style={{ marginLeft: '8px' }} />}
            </div>
            {companyNames?.data?.map((account) => (
                <div key={account.id}>
                    {account.name && (
                        <AccountRow
                            account={account}
                            searchValue={searchValue}
                            onClick={() => onClick(<AccountCard userId={userId} account={account} groups={groups} />)}
                        />
                    )}
                </div>
            ))}
            {numberOfCompanyNames > pagination?.limit && (
                <CategoryExpandShrink
                    page={pagination.page}
                    numberOfPages={pagination.numberOfPages}
                    loading={loading}
                    numberOfRows={numberOfCompanyNames}
                    onShowMore={() => handleShowMore()}
                />
            )}
        </div>
    );
};
