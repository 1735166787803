import { FormControl, Select } from '@mui/material';
import { styled } from '@mui/styles';
import React, { ReactNode } from 'react';

interface CustomSelectProps {
    children: ReactNode;
    className?: string;
    labelId?: string;
    id?: string;
    value?: string;
    required?: boolean;
    [K: string]: any;
}
export const CustomSelect: React.FC<CustomSelectProps> = styled(({ className, children, ...props }) => (
    <Select
        className={className}
        labelId={props.labelId}
        id={props.id}
        value={props.value}
        onChange={props.onChange}
        required={props.required}
        //MenuProps={MenuProps}
    >
        {children}
    </Select>
))(({ side, theme }: any) => ({
    borderRadius: side === 'left' ? '8px 0 0 8px' : '0 8px 8px 0',
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    ...(side === 'left' && { borderRight: 'none' }),
    fontSize: 16,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
    '& > div': {
        padding: '10.5px 31px 10.5px 14px',
        textAlign: 'left',
    },
}));

export const CustomFormControl = styled(
    ({
        className,
        children,
        ...props
    }: {
        children: ReactNode;
        classNam?: string;
        required?: boolean;
        [K: string]: any;
    }) => (
        <FormControl className={className} variant='outlined' required={props.required}>
            {children}
        </FormControl>
    ),
)(({ theme }: any) => ({
    width: '50%',
    maxWidth: 231,
}));
