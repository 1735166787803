import { getZaplifySdk } from '@zaplify/sdk';
import { batch } from 'react-redux-latest';
import { handleErrorNotification, setNotification, updateIntegrations } from '../../../redux/actions';
import { getSpecialUpsalesMapping } from './helpers';
import {
    updateAvailableActivityTypes,
    updateAvailableFields,
    updateFieldMapping,
    updateSaveStatus,
    updateSettings,
    updateUpsalesActiveState,
    updateUpsalesCustomSwitches,
} from './slice';

export const getUpsalesSettings = () => async (dispatch, getState) => {
    const profilesSdk = getZaplifySdk().profiles;
    const user = getState().user.zaplifyUser;
    try {
        const upsalesSettings = await profilesSdk.upsales.getSettings(user.userOrganization.id);

        if (upsalesSettings) {
            batch(() => {
                dispatch(updateSettings(upsalesSettings));
                dispatch(updateUpsalesSaveStatus());
            });
        }
    } catch (error) {
        console.log('error fetching Upsales settings: ');
        dispatch(
            updateSettings({
                account: {},
                integrationScope: {},
                fieldMapping: [],
                isActive: false,
                availableFields: {},
                firstTime: false,
            })
        );
    } finally {
    }
};

export const getUpsalesFields = () => async (dispatch, getState) => {
    const profilesSdk = getZaplifySdk().profiles;
    const user = getState().user.zaplifyUser;
    try {
        const fields = await profilesSdk.upsales.getAllFields(user.userOrganization.id);
        dispatch(updateAvailableFields(fields));
    } catch (error) {
        console.log('error loading fields: ');
    } finally {
    }
};

export const getUpsalesActivityTypes = () => async (dispatch, getState) => {
    const profilesSdk = getZaplifySdk().profiles;
    const user = getState().user.zaplifyUser;
    try {
        const activityTypes = await profilesSdk.upsales.getActivityTypes(user.userOrganization.id);
        dispatch(updateAvailableActivityTypes(activityTypes.options));
    } catch (error) {
        console.log('error loading fields: ');
    } finally {
    }
};

export const updateUpsalesSettings = () => async (dispatch, getState) => {
    const profilesSdk = getZaplifySdk().profiles;
    const user = getState().user.zaplifyUser;
    const upsales = getState().upsales;
    try {
        const fieldMapping = [...upsales.fieldMapping];

        const newFields = getSpecialUpsalesMapping(upsales, fieldMapping);
        const newFieldMapping = [...fieldMapping, ...newFields];

        const updateSettingsObj = {
            organizationId: user.userOrganization.id,
            fieldMapping: newFieldMapping,
            integrationScope: upsales.integrationScope,
            isActive: upsales.isActive,
            customActivityType: upsales.customActivityType,
        };
        const errorCallback = (error) => {
            throw new Error(error);
        };
        await profilesSdk.upsales.updateSettings(updateSettingsObj, errorCallback);
        const updatedSettings: any = await profilesSdk.upsales.getSettings(user.userOrganization.id);

        batch(() => {
            dispatch(updateFieldMapping(updatedSettings.fieldMapping));
            dispatch(updateUpsalesSaveStatus());
            dispatch(getUpsalesFields());
            dispatch(updateUpsalesCustomSwitches(false));
        });

        if (upsales.firstTime) {
            await dispatch(resumeOrPauseUpsales(true));
            dispatch(setNotification('Successfully started your Upsales integration', 'success'));
        } else {
            dispatch(setNotification('Successfully updated your Upsales settings', 'success'));
        }
        window?.analytics?.track('User Updated Integration', {
            type: 'Upsales',
        });
    } catch (error: any) {
        if (error.message) {
            const parseError = JSON.parse(error.message);
            dispatch(handleErrorNotification(parseError.data.message, 2000));
        } else {
            dispatch(setNotification("Couldn't update your Upsales settings", 'error'));
        }
        console.log('error loading fields: ');
    } finally {
        dispatch(updateIntegrations());
    }
};

export const resumeOrPauseUpsales = (value) => async (dispatch, getState) => {
    const profilesSdk = getZaplifySdk().profiles;
    const user = getState().user.zaplifyUser;
    try {
        if (value) await profilesSdk.upsales.resume(user.userOrganization.id);
        else await profilesSdk.upsales.pause(user.userOrganization.id);
        await dispatch(updateUpsalesActiveState(value));
    } catch (error) {
        console.log('error resume or pause: ');
    } finally {
        dispatch(updateIntegrations());
    }
};

export const updateUpsalesSaveStatus = () => async (dispatch, getState) => {
    try {
        const upsales = getState().upsales;
        const fieldMapping = upsales.fieldMapping;
        const isOldInterface = upsales.isOldInterface;
        const customActivityType = upsales.customActivityType;

        let saveStatus = true;

        fieldMapping.forEach((field) => {
            const secondField = field.contactField || field.companyField;
            //Check source
            if (field.prospectField === '' || secondField === undefined || secondField === '') {
                saveStatus = false;
            }
        });
        if (isOldInterface && !customActivityType) {
            saveStatus = false;
        }
        dispatch(updateSaveStatus(saveStatus));
    } catch (error) {
        console.log('error loading fields: ');
    } finally {
    }
};

export const disconnectUpsales = () => async (dispatch, getState) => {
    const profilesSdk = getZaplifySdk().profiles;
    const user = getState().user.zaplifyUser;
    try {
        await profilesSdk.upsales.disconnect(user.userOrganization.id);

        batch(() => {
            //Resetting Upsales state to initial
            dispatch(
                updateSettings({
                    account: {},
                    integrationScope: {},
                    fieldMapping: [],
                    isActive: false,
                    availableFields: {},
                    firstTime: false,
                })
            );

            dispatch(setNotification('Successfully disconnected your Upsales integration', 'success'));
        });
    } catch (error) {
        console.log('error resume or pause: ');
    } finally {
        dispatch(updateIntegrations());
    }
};
