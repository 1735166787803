import { IEvent, Topics } from '@zaplify/common';

export class UserContactUnassignedFromGroupEvent extends IEvent {
    public static override readonly TOPIC: Topics = Topics.PROSPECTS;
    public readonly userId: string;
    public readonly userContactId: string;
    public readonly prospectId: string;
    public readonly groupId: string;

    constructor(params: { groupId: string; userId: string; userContactId: string; prospectId: string }) {
        super();
        this.groupId = params.groupId;
        this.userId = params.userId;
        this.userContactId = params.userContactId;
        this.prospectId = params.prospectId;
    }

    get partitionKey(): string {
        return this.prospectId;
    }
}
