import { BubbleMenu, Editor } from '@tiptap/react';
import { BoldIcon, ItalicIcon, LinkIcon, UnderlineIcon } from 'lucide-react';
import { useRef } from 'react';
import { Tooltip, TooltipContent, TooltipTrigger } from '@shadcn/ui/components/ui/tooltip';
import './toolbar-menu.css';

export const ToolbarMenu = ({
    editor,
    openLinkPopover,
    shouldShowToolbarMenu,
}: {
    editor: Editor;
    openLinkPopover: (event: React.BaseSyntheticEvent) => void;
    shouldShowToolbarMenu: boolean;
}) => {
    const shouldShowToolbarMenuRef = useRef(null);
    shouldShowToolbarMenuRef.current = shouldShowToolbarMenu;
    // There is an issue wrapping the image in a link mark, so this is turned off for now.
    // When this issue will be fixed in "setLink" in use-text-editor.ts, this can be turned back on.
    const isImageActive = editor.isActive('image');

    return (
        <BubbleMenu
            className="bubble-menu"
            tippyOptions={{ duration: 100 }}
            editor={editor}
            shouldShow={({ editor, state }) => {
                return shouldShowToolbarMenuRef.current && (!state.selection.empty || editor.isActive('link'));
            }}
        >
            {!isImageActive && (
                <>
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <button
                                onClick={() => {
                                    const isBold = editor.isActive('bold');
                                    if (isBold) {
                                        editor.chain().focus().unsetBold().run();
                                    } else {
                                        editor.chain().focus().setBold().run();
                                    }
                                }}
                                className={editor.isActive('bold') ? 'is-active' : ''}
                            >
                                <BoldIcon strokeWidth={3} size={14} />
                            </button>
                        </TooltipTrigger>
                        <TooltipContent>Bold</TooltipContent>
                    </Tooltip>
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <button
                                onClick={() => {
                                    const isItalic = editor.isActive('italic');
                                    if (isItalic) {
                                        editor.chain().focus().unsetItalic().run();
                                    } else {
                                        editor.chain().focus().setItalic().run();
                                    }
                                }}
                                className={editor.isActive('italic') ? 'is-active' : ''}
                            >
                                <ItalicIcon strokeWidth={3} size={14} />
                            </button>
                        </TooltipTrigger>
                        <TooltipContent>Italic</TooltipContent>
                    </Tooltip>
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <button
                                onClick={() => {
                                    const isUnderline = editor.isActive('underline');
                                    if (isUnderline) {
                                        editor.chain().focus().unsetUnderline().run();
                                    } else {
                                        editor.chain().focus().setUnderline().run();
                                    }
                                }}
                                className={editor.isActive('underline') ? 'is-active' : ''}
                            >
                                <UnderlineIcon strokeWidth={3} size={14} />
                            </button>
                        </TooltipTrigger>
                        <TooltipContent>Underline</TooltipContent>
                    </Tooltip>
                    <div style={{ height: '20px', width: '2px', backgroundColor: '#ccc' }}></div>
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <button
                                onClick={openLinkPopover}
                                style={{ padding: '.5rem' }}
                                className={editor.isActive('link') ? 'is-active' : ''}
                            >
                                <LinkIcon strokeWidth={3} size={14} />
                            </button>
                        </TooltipTrigger>
                        <TooltipContent>Link</TooltipContent>
                    </Tooltip>
                </>
            )}
        </BubbleMenu>
    );
};
