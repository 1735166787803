const isEmail = (email: string) => {
    const pattern = /\S+@\S+\.\S+/;
    try {
        return pattern.test(email.trim());
    } catch (err) {}

    return false;
};

type EmailState = 'Email exists' | 'Email missing' | 'Enrichment';
export function getEmailState(email: string | null | undefined): EmailState {
    let emailState: EmailState;
    const emailIssues = ['none', 'None', '', null];

    if (!email) {
        emailState = 'Email missing';
    } else if (isEmail(email)) {
        emailState = 'Email exists';
    } else if (email === '...') {
        emailState = `Enrichment`;
    } else if (emailIssues.includes(email)) {
        emailState = 'Email missing';
    } else {
        emailState = 'Email missing';
    }
    return emailState;
}
