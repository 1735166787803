import { Typography } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import React from 'react';
import { ReactComponent as Arrow } from '../../../../components/icons/arrow.svg';
import ZaplifyLogo from '../../../../components/icons/zaplify-logo.svg';
import AndSendLogo from '../../../../components/icons/and-send/ANDSEND_logotype_small_black_transparent.svg';
import { isAndSend } from '../../../../helpers/is-and-send';
import { companyNameCorrectlyCased } from '../../../../enums/company-name';

export const FieldMappingInfoHeader = (props) => {
    const logo = isAndSend() ? AndSendLogo : ZaplifyLogo;
    return (
        <>
            <Typography>
                <b>Field mapping</b>
            </Typography>
            <Typography>{`Match your CSV columns to ${companyNameCorrectlyCased} Fields to complete your import.`}</Typography>
            <div>
                <DescriptionIcon style={{ height: 20 }} />
                <Arrow style={{ height: 20 }} />
                <img alt="zaplify-company-logo" src={logo} style={{ height: 20 }} />
            </div>
        </>
    );
};
