import { EditCampaignSteps } from '@zaplify/constants';
import { ActionType } from '@zaplify/campaigns';
import { produce } from 'immer';

//Get days converted from utc days to days with offset
export function getDays(days, time, offset) {
    const dayOffset = getDayOffset(time, offset);
    return days
        .filter((day) => day.checked)
        .map((day) => (day.value + dayOffset < 0 && 6) || (day.value + dayOffset > 6 && 0) || day.value + dayOffset)
        .sort((a, b) => a - b);
}

function getDayOffset(time, offset) {
    if (parseInt(time[0].value) + offset / 60 > 23) return 1;
    else if (parseInt(time[0].value) + offset / 60 < 0) return -1;
    else return 0;
}

export function getFormattedSteps(steps) {
    return steps.map((step) => ({
        stepId: step.id,
        ...((step.subject?.content || step.subject?.content === '') && { subject: step.subject.content }),
        ...((step.message?.content || step.message?.content === '') && { message: step.message.content }),
        delay: step.delay,
        ...(step.sameConversationSwitch && { sameConversationSwitch: step.sameConversationSwitch.isOn }),
    }));
}

export function getStepsWithCorrectSubject(incomingSteps) {
    const newSteps = produce(incomingSteps, (draftSteps) => {
        let emailSubject = '';
        draftSteps.forEach((step) => {
            if (step.sameConversationSwitch?.isOn) {
                step.subject.content = emailSubject;
            } else if (step.type === ActionType.emailMessage) {
                emailSubject = step.subject.content;
            }
        });
    });
    return newSteps;
}

export function getEditStep(campaign) {
    let step = EditCampaignSteps.AddProspects;

    if (campaign.workflow?.steps?.length > 0) {
        step = EditCampaignSteps.ConfigureWorkflow;
    }
    if (campaign?.statistics?.totalProspects > 0) step = EditCampaignSteps.ConfigureWorkflow;
    return step;
}
