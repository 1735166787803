import { IUntypedCommand } from '@zaplify/common';
import { Topics } from '@zaplify/common';
import { CustomerDetailsDto } from '../users';

export class CreateUserOrganizationCommand extends IUntypedCommand {
    public static override readonly TOPIC = Topics.USERS;
    constructor(readonly name: string, readonly domain: string, readonly owner: CustomerDetailsDto) {
        super();
    }
}
