export function isAndSend() {
    return true;
    // try {
    //     const url = window.location.href;
    //     const urlIsAndSend = url.includes('andsend.com') || url.includes('localhost');
    //     return urlIsAndSend;
    // } catch (e) {
    //     return false;
    // }
}
