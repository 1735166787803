import { ProspectDataDto, ProspectOwnerDto } from '../prospects';

interface ESSearchCount {
    value: number;
    relation: 'eq' | 'gte' | 'lte' | string;
}

export namespace PreviewProspectingDtos {
    /**
     * Response for previewing prosepcting
     */
    export class ResponsePerCompany {
        prospectsByCompany: CompanyGroupDto[];
        counts: Counts;
        assignedPersonIds?: string[];

        constructor(iPreviewProspectingListViewDto: ResponsePerCompany) {
            this.prospectsByCompany = iPreviewProspectingListViewDto.prospectsByCompany.map((c) =>
                CompanyGroupDto.fromDocument(c),
            );
            this.counts = iPreviewProspectingListViewDto.counts;
            this.assignedPersonIds = iPreviewProspectingListViewDto.assignedPersonIds;
        }

        static fromDocument(iPreviewProspectingListViewDto: ResponsePerCompany) {
            const previewProspectingListViewDto = new ResponsePerCompany(iPreviewProspectingListViewDto);
            return previewProspectingListViewDto;
        }
    }

    export class ResponsePerPerson {
        persons: ProspectDto[];
        counts: Counts;
        assignedPersonIds?: string[];

        constructor(iPreviewProspectingListViewDto: ResponsePerPerson) {
            this.persons = iPreviewProspectingListViewDto.persons;
            this.counts = iPreviewProspectingListViewDto.counts;
            this.assignedPersonIds = iPreviewProspectingListViewDto.assignedPersonIds;
        }

        static fromDocument(iPreviewProspectingListViewDto: ResponsePerPerson) {
            const previewProspectingListViewDto = new ResponsePerPerson(iPreviewProspectingListViewDto);
            return previewProspectingListViewDto;
        }
    }

    export interface CompanyCounts {
        [companyName: string]: {
            available: number;
            assigned: number;
            blocklisted: number;
        };
    }

    export interface Counts {
        totalPersons: ESSearchCount;

        availablePersons?: ESSearchCount;
        assignedPersons?: ESSearchCount;
        blocklistedPersons?: ESSearchCount;

        availableCompanies?: ESSearchCount;
    }

    /**
     * A group of list-view-prospects in the same company, with an average company score.
     */
    export class CompanyGroupDto {
        hasMoreProspects: boolean;

        //Organization level
        organizationName: string;
        organizationId?: string;
        organizationIndustry?: string;
        organizationSize?: string;
        organizationDomain?: string;
        organizationLinkedinUrl?: string;
        description?: string;
        tags?: string[];

        hubspotCompanyUrl?: string;
        recommended?: boolean;

        prospects: CompanyProspectDto[];

        constructor(iProspectSearchPreviewCompanyResponseDto: CompanyGroupDto) {
            this.hasMoreProspects = iProspectSearchPreviewCompanyResponseDto.hasMoreProspects;
            this.organizationName = iProspectSearchPreviewCompanyResponseDto.organizationName;
            this.organizationId = iProspectSearchPreviewCompanyResponseDto.organizationId;
            this.organizationIndustry = iProspectSearchPreviewCompanyResponseDto.organizationIndustry;
            this.organizationSize = iProspectSearchPreviewCompanyResponseDto.organizationSize;
            this.organizationDomain = iProspectSearchPreviewCompanyResponseDto.organizationDomain;
            this.organizationLinkedinUrl = iProspectSearchPreviewCompanyResponseDto.organizationLinkedinUrl;
            this.description = iProspectSearchPreviewCompanyResponseDto.description;
            this.tags = iProspectSearchPreviewCompanyResponseDto.tags;
            this.hubspotCompanyUrl = iProspectSearchPreviewCompanyResponseDto.hubspotCompanyUrl;
            this.recommended = iProspectSearchPreviewCompanyResponseDto.recommended;
            this.prospects = iProspectSearchPreviewCompanyResponseDto.prospects.map((p) =>
                CompanyProspectDto.fromDocument(p),
            );
        }

        static fromDocument(iProspectSearchPreviewCompanyResponseDto: CompanyGroupDto) {
            const prospectSearchPreviewCompanyResponseDto = new CompanyGroupDto(
                iProspectSearchPreviewCompanyResponseDto,
            );
            return prospectSearchPreviewCompanyResponseDto;
        }
    }

    export class CompanyProspectDto {
        _score?: number;
        addedBy?: { userId: string; fullName: string }[];

        //Personal level
        firstName: string;
        lastName: string;
        fullName: string;
        location?: string;

        //Occupation level
        occupationTitle?: string;
        industry?: string;

        personId: string;
        hubspotContactUrl?: string;

        constructor(iListViewPreviewProspect: CompanyProspectDto) {
            this._score = iListViewPreviewProspect._score;
            this.firstName = iListViewPreviewProspect.firstName;
            this.lastName = iListViewPreviewProspect.lastName;
            this.fullName = iListViewPreviewProspect.fullName;
            this.location = iListViewPreviewProspect.location;
            this.industry = iListViewPreviewProspect.industry;
            this.occupationTitle = iListViewPreviewProspect.occupationTitle;
            this.personId = iListViewPreviewProspect.personId;
            this.hubspotContactUrl = iListViewPreviewProspect.hubspotContactUrl;
            this.addedBy = iListViewPreviewProspect.addedBy;
        }

        static fromDocument(iListViewPreviewProspect: CompanyProspectDto) {
            const listViewPreviewProspectDto = new CompanyProspectDto(iListViewPreviewProspect);
            return listViewPreviewProspectDto;
        }
    }

    export type ProspectDto = ProspectDataDto & {
        personId: string;
        _score?: number;
        addedBy?: ProspectOwnerDto[];
        hubspotCompanyUrl?: string;
        hubspotContactUrl?: string;
    };
}
