import makeStyles from '@mui/styles/makeStyles';

export const styles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
    container: {
        margin: '18px 23px',
        width: '443px',
        height: '43px',
        border: '0.8px solid #E4E6EB',
        boxShadow: '0px 2px 6px rgba(112, 144, 176, 0.07)',
        borderRadius: '8px',
    },
    input: {
        width: '100%',
        border: 'none',
        '&:focus': {
            outline: 'none',
        },
        fontSize: '14px',
        lineHeight: '26px',
        '&::placeholder': {
            color: '#96A2AC',
        },
    },
    innerInput: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        margin: '0 14px',
    },
}));
