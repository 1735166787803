import { useMutation } from '@tanstack/react-query';
import { getZaplifySdk } from '@zaplify/sdk';
import { Dispatch } from 'react';
import { SET_NOTIFICATION } from '../constants/state';
import { ConfirmEmailAction } from '../types/state';

const useResetEmail = ({
    emailFromQuery,
    dispatch,
}: {
    emailFromQuery: string;
    dispatch: Dispatch<ConfirmEmailAction>;
}) => {
    const {
        mutate: resendResetEmail,
        status: resetStatus,
        error: resetError,
    } = useMutation({
        mutationFn: async () => {
            const authenticationSdk = getZaplifySdk().profiles.authentication;
            return await authenticationSdk.resetPassword(emailFromQuery);
        },
        onSuccess: (data) => {
            console.log(`🚀 [ConfirmEmail] resendResetEmail > onSuccess: ${JSON.stringify(data)}`);
            dispatch({
                type: SET_NOTIFICATION,
                payload: { isVisible: true, message: 'Email sent', severity: 'success' },
            });
        },
        onError: (data) => {
            console.error(`🔥 [ConfirmEmail] resendResetEmail > onError: ${JSON.stringify(data)}`);
            dispatch({
                type: SET_NOTIFICATION,
                payload: { isVisible: true, message: data.message, severity: 'error' },
            });
        },
    });

    return { resendResetEmail, resetStatus, resetError };
};

export default useResetEmail;
