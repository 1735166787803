import { IEvent, Topics } from '@zaplify/common';

export class LinkedinAccountRestrictedEvent extends IEvent {
    public static override readonly TOPIC = Topics.CHANNEL_ACCOUNTS;

    constructor(readonly channelAccountId: string, readonly wasRestricted: boolean) {
        super();
    }

    get partitionKey(): string {
        return this.channelAccountId;
    }
}
