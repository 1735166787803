import { Pen } from 'lucide-react';
import { useParams } from 'react-router-dom';
import { useEditContactDetailsDialog } from '../dialogs/edit-contact-details-dialog';
import { Tooltip, TooltipContent, TooltipTrigger } from '@shadcn/ui/components/ui/tooltip';
import { Button } from '@shadcn/ui/components/ui/button';

export const EditButton = () => {
    const { prospectId } = useParams();
    const { openEditContactDetailsDialog } = useEditContactDetailsDialog();

    return (
        <div className="flex ml-auto">
            <Tooltip delayDuration={300}>
                <TooltipTrigger asChild>
                    <Button
                        variant="outline"
                        className="p-1 h-6"
                        onClick={(e) => {
                            e.preventDefault();
                            openEditContactDetailsDialog(prospectId);
                        }}
                    >
                        <Pen className="!w-3 !h-3" />
                    </Button>
                </TooltipTrigger>
                <TooltipContent>Edit details</TooltipContent>
            </Tooltip>
        </div>
    );
};
