import { Button, CircularProgress } from '@mui/material';
import ProspectsPerCompanyStyled from './prospects-per-company.styled';

type Props = {
    onExpandCollapse: (moreLess: 'more' | 'less') => void;
    loading: boolean;
    showLess: boolean;
    showMore: boolean;
    showInfo: boolean;
};

const ProspectsPerCompany = ({ onExpandCollapse, loading, showLess, showInfo, showMore }: Props) => {
    return (
        <ProspectsPerCompanyStyled>
            {showLess && (
                <Button onClick={() => onExpandCollapse('less')} className="action" variant="text" disabled={loading}>
                    - Show less
                </Button>
            )}
            {showInfo && <span className="info">All matched prospects are shown</span>}
            {showMore && (
                <Button
                    className={`action ${loading ? 'hovered' : ''}`}
                    variant="text"
                    onClick={() => onExpandCollapse('more')}
                    disabled={loading}
                >
                    {!loading && <span>+ Show more</span>}
                    {loading && <CircularProgress size={20} />}
                </Button>
            )}
        </ProspectsPerCompanyStyled>
    );
};

export default ProspectsPerCompany;
