import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../new/providers/authentication-provider';
import { paths } from './paths';
import { UserRole } from '@zaplify/users/shared';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';

type IAuthRole = 'none' | 'user' | 'admin';
type IUserState =
    | 'offline'
    | 'loggedout'
    | 'loggedin_unverified'
    | 'loggedin_verified'
    | 'loggedin_has_users_or_invitations'
    | 'loggedin_user_not_selected'
    | 'loggedin_user_selected'
    | 'loggedin_user_selected_and_linkedin_not_connected'
    | 'loggedin_user_selected_and_aiprofile_not_configured'
    | 'loggedin_user_selected_and_fully_configured'
    | 'loggedin_user_selected_and_fully_configured_and_is_admin';
type IAuthGlobalAdmin = 'isGlobalAdmin' | 'isImpersonating';
type IConnectedAccount = 'linkedin' | 'email';

interface RequireAuthProps {
    allowedRoles?: IAuthRole[];
    requiredUserState?: IUserState;
    globalAdmin?: IAuthGlobalAdmin[];
    connectedAccounts?: IConnectedAccount[];
}

export const RequireAuth: React.FC<RequireAuthProps> = ({
    allowedRoles,
    requiredUserState = 'loggedin_verified',
    globalAdmin,
    connectedAccounts,
}: RequireAuthProps) => {
    const { authState } = useAuth();
    const { feedOnboarding } = useFlags();
    const { isLoggedIn, isEmailVerified, nbrOfUserAccounts, nbrOfInvitations, hasSelectedUser, user, onboardingState } =
        authState || {};
    const location = useLocation();

    const currentUserIs = useMemo<Record<IUserState, boolean>>(
        () => ({
            offline: !!hasSelectedUser && !user,
            loggedout: !isLoggedIn,
            loggedin_unverified: isLoggedIn && !isEmailVerified,
            loggedin_verified: isLoggedIn && isEmailVerified,
            loggedin_has_users_or_invitations:
                isLoggedIn && isEmailVerified && nbrOfUserAccounts + nbrOfInvitations > 0,
            loggedin_user_not_selected: isLoggedIn && isEmailVerified && !hasSelectedUser,
            loggedin_user_selected: !!hasSelectedUser,
            loggedin_user_selected_and_linkedin_not_connected:
                !!hasSelectedUser && !onboardingState.hasLinkedInConnected,
            loggedin_user_selected_and_aiprofile_not_configured:
                !!hasSelectedUser && !onboardingState.hasCompletedOnboarding,
            loggedin_user_selected_and_fully_configured: !!hasSelectedUser && onboardingState.hasCompletedOnboarding,
            loggedin_user_selected_and_fully_configured_and_is_admin:
                !!hasSelectedUser &&
                onboardingState.hasCompletedOnboarding &&
                globalAdmin?.includes('isGlobalAdmin') &&
                user?.roles?.includes(UserRole.GLOBAL_ADMIN),
        }),
        [
            hasSelectedUser,
            user,
            isLoggedIn,
            isEmailVerified,
            nbrOfUserAccounts,
            nbrOfInvitations,
            onboardingState?.hasLinkedInConnected,
            onboardingState?.hasCompletedOnboarding,
            globalAdmin,
        ]
    );

    const isAuthorized = useMemo(() => currentUserIs[requiredUserState] || false, [currentUserIs, requiredUserState]);

    const unauthorizedRedirection = useMemo(() => {
        if (currentUserIs.loggedout) return paths.NEW_LOGIN;
        if (currentUserIs.loggedin_unverified) return paths.NEW_CONFIRM_EMAIL;
        if (currentUserIs.loggedin_user_not_selected && nbrOfUserAccounts === 0) return paths.WORKSPACES.NEW;
        if (currentUserIs.loggedin_user_not_selected) return paths.WORKSPACES.ROOT;
        if (currentUserIs.offline) return paths.SERVER_OFFLINE;
        if (currentUserIs.loggedin_user_selected_and_linkedin_not_connected) {
            return feedOnboarding ? paths.NEW.ONBOARDING_PATHS.CONNECT_LINKEDIN : paths.ONBOARDING_CONNECT_LINKEDIN;
        }
        if (currentUserIs.loggedin_user_selected_and_aiprofile_not_configured) {
            return feedOnboarding ? paths.NEW.ONBOARDING_PATHS.SETUP_PLAYBOOK : paths.ONBOARDING_AI_PREFERENCES;
        }
        return paths.HOME;
    }, [currentUserIs, nbrOfUserAccounts, feedOnboarding]);

    return isAuthorized ? <Outlet /> : <Navigate to={unauthorizedRedirection} state={{ from: location }} replace />;
};

export default RequireAuth;
