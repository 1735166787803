import { Route } from 'react-router-dom';
import { RequireAuth } from '../require-auth';
import { paths } from '../paths';

import { Component as ConfigureProfile } from '../../views/auth/page/configure-profile';
import { Component as ConfirmEmail } from '../../views/auth/page/confirm-email';
import { Component as Login } from '../../views/auth/page/login';
import { Component as Register } from '../../views/auth/page/register';
import { Component as ResetPassword } from '../../views/auth/page/reset-password';
import { Component as ResetPasswordCompleted } from '../../views/auth/page/reset-password-completed';
import { AuthTemplate } from '../../templates/auth';

export const legacyAuthRoutes = (
    <>
        {/* Old auth routes */}
        <Route element={<AuthTemplate />}>
            <Route
                // errorElement={<ErrorPage />}
                element={<RequireAuth requiredUserState={'loggedout'} />}
            >
                <Route path={paths.OLD_LOGIN} element={<Login />} />
                <Route path={paths.OLD_REGISTER} element={<Register />} />
                <Route path={paths.OLD_RESET_PASSWORD} element={<ResetPassword />} />
                <Route path={paths.OLD_RESET_PASSWORD_COMPLETED} element={<ResetPasswordCompleted />} />
            </Route>
            <Route path={paths.OLD_CONFIRM_EMAIL} element={<ConfirmEmail />} />
            <Route
                // errorElement={<ErrorPage />}
                element={<RequireAuth requiredUserState={'loggedin_unverified'} />}
            ></Route>
            <Route
                // errorElement={<ErrorPage />}
                element={<RequireAuth requiredUserState={'loggedout'} />}
            >
                <Route path={paths.OLD_CONFIRM_PASSWORD_RESET} element={<ConfirmEmail />} />
            </Route>
            <Route
                // errorElement={<ErrorPage />}
                element={<RequireAuth requiredUserState={'loggedin_user_not_selected'} />}
            >
                <Route path={paths.OLD_CONFIGURE_PROFILE} element={<ConfigureProfile />} />
            </Route>
        </Route>
        {/** End of protected routes HAS SELECTED USER */}
    </>
);
