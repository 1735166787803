import Lottie from 'lottie-react';
import networkAnimation from './network-animation.json';
import { MeAvatar } from '../../../../components/me-avatar';
import { useState, useEffect } from 'react';

export const NetworkAnimation = () => {
    const [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setIsVisible(true);
        }, 1000);
    }, []);

    return (
        <div className="flex flex-col items-center justify-center gap-4 w-[350px] h-[300px] overflow-hidden">
            {isVisible && (
                <>
                    <MeAvatar
                        className="relative -bottom-[160px] translate-x-[1px] z-10 transition-all duration-1000 opacity-0 animate-fade-in"
                        size="xl"
                    />
                    <div className="translate-y-[-30px]">
                        <NetworkAnimated height="400px" width="400px" animate={true} />
                    </div>
                </>
            )}
        </div>
    );
};

const NetworkAnimated = ({
    height = '16px',
    width = '16px',
    animate = false,
    loop = true,
}: {
    height?: string;
    width?: string;
    animate?: boolean;
    loop?: boolean;
}) => {
    return (
        <Lottie
            animationData={networkAnimation}
            loop={animate ? loop : false}
            autoplay={animate}
            style={{ height, width }}
        />
    );
};
