//Mapping from hash to color
const colors = ['#ABAAF2', '#EBA4C1', '#EBB5A4', '#CAD977', '#92D195', '#BFA1E5', '#A1DEE5'];

const getHash = (str: string): number => {
    var hash = 0;
    if (!str) return 0;
    if (str.length === 0) return 0;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
        hash = hash & hash;
    }
    hash = ((hash % colors.length) + colors.length) % colors.length;
    return hash;
};

export function stringToColor(str: string) {
    const hash = getHash(str);
    const color = colors[hash];
    return color;
}
