import { FC, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Form, FormControl, FormField, FormItem, FormLabel } from '@shadcn/ui/components/ui/form';
import { Textarea } from '@shadcn/ui/components/ui/textarea';
import { Skeleton } from '@shadcn/ui/components/ui/skeleton';
import { cn } from '@shadcn/ui/lib/utils';
import { Button } from '@shadcn/ui/components/ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '@shadcn/ui/components/ui/popover';
import { Tooltip, TooltipContent, TooltipTrigger } from '@shadcn/ui/components/ui/tooltip';
import { TooltipProvider } from '@shadcn/ui/components/ui/tooltip';
import { SparklesAI } from '../../../../actions/components/message-composer/sparkles-ai';
import { useMutation } from '@tanstack/react-query';
import { useSdk } from '../../../../../sdk/use-sdk';
import { ToggleGroup, ToggleGroupItem } from '@shadcn/ui/components/toggle-group';
import { Mail, Linkedin, ChevronDown, Sparkles } from 'lucide-react';
import { ChannelType } from '@zaplify/channel-accounts/shared';
import { Checkbox } from '@shadcn/ui/components/ui/checkbox';
import LanguageSelector from '../../../components/language-selector';
import { UserMemoryList } from '../../../../../components/user-memory-list';
import { useParams } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
interface CustomInstructionsSectionProps {
    form: UseFormReturn<any>;
    isLoading: boolean;
    setIsLoading: (loading: boolean) => void;
}

export const CustomInstructionsSection: FC<CustomInstructionsSectionProps> = ({ form, isLoading, setIsLoading }) => {
    const [showDetailedSettings, setShowDetailedSettings] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const { groupId } = useParams();
    const { userMemoryEnabled } = useFlags();

    let placeholder = form.watch('context')
        ? 'How would you like to change the context?'
        : 'What type of context would you like to add';

    placeholder =
        placeholder + '\n\n(e.g. add details about special deals, meeting links, or strategies for engaging customers)';

    return (
        <div onClick={(e) => e.stopPropagation()}>
            <Form {...form}>
                <div className="flex flex-col gap-4">
                    <FormField
                        control={form.control}
                        name="context"
                        render={({ field }) => (
                            <FormItem id="playbook-context-field">
                                <div className="flex items-end justify-between">
                                    <p className="text-xs text-text-secondary">
                                        Include key talking points, useful links, product details, and communication
                                        style that's relevant to the playbook.
                                    </p>
                                    <SuggestionsDialog
                                        open={isDialogOpen}
                                        setModalOpenState={setIsDialogOpen}
                                        currentContext={field.value}
                                        onSuggestionSelect={(suggestion) => {
                                            if (!suggestion) {
                                                setIsLoading(true);
                                                field.onChange('');
                                                return;
                                            }
                                            field.onChange(suggestion);
                                            setIsDialogOpen(!!suggestion);
                                            setIsLoading(false);
                                        }}
                                    />
                                </div>
                                <FormControl>
                                    <div className="relative">
                                        <Textarea
                                            {...field}
                                            ref={(el) => {
                                                if (el && !isLoading) {
                                                    if (!el.dataset.initialHeightSet) {
                                                        el.style.height = '0px';
                                                        const newHeight = Math.max(el.scrollHeight, 300);
                                                        el.style.height = newHeight + 'px';
                                                        el.dataset.initialHeightSet = 'true';
                                                    }
                                                    el.addEventListener(
                                                        'input',
                                                        () => {
                                                            el.style.height = '0px';
                                                            el.style.height = Math.max(el.scrollHeight, 300) + 'px';
                                                        },
                                                        { once: true }
                                                    );
                                                }
                                            }}
                                            placeholder="Enter additional instructions, relevant links, or context for message customization"
                                            disabled={isLoading}
                                            className={cn(
                                                'min-h-[300px] max-h-[500px] resize-vertical text-lg leading-7 px-4 py-3 whitespace-pre-wrap ',
                                                isLoading && 'opacity-0'
                                            )}
                                            value={field.value || ''}
                                        />
                                        {isLoading && (
                                            <div className="absolute inset-0 flex flex-col gap-3 bg-background p-4 rounded-lg border border-border">
                                                <div className="flex items-center gap-2 mb-2">
                                                    <div className="animate-spin h-4 w-4 border-2 border-primary border-t-transparent rounded-full"></div>
                                                    <p className="text-sm font-medium">
                                                        Generating custom instructions based on your purpose and target
                                                        group...
                                                    </p>
                                                </div>
                                                <Skeleton className="w-1/3 h-4" />
                                                <Skeleton className="w-2/3 h-4" />
                                                <Skeleton className="w-full h-4" />
                                                <Skeleton className="w-4/5 h-4" />
                                                <Skeleton className="w-3/4 h-4" />
                                            </div>
                                        )}
                                    </div>
                                </FormControl>
                            </FormItem>
                        )}
                    />

                    {groupId && userMemoryEnabled && (
                        <div className="flex flex-col gap-2">
                            <p className="text-sm font-medium flex items-center gap-2">
                                <Sparkles className="h-4 w-4" /> Playbook Memories
                            </p>
                            <UserMemoryList level={'playbook'} groupId={groupId} />
                        </div>
                    )}

                    <div className="flex flex-col gap-4">
                        <Button
                            type="button"
                            variant="ghost"
                            className="flex items-center gap-2 w-fit text-text-brand-tertiary"
                            onClick={(e) => {
                                e.preventDefault();
                                setShowDetailedSettings(!showDetailedSettings);
                            }}
                        >
                            Detailed settings
                            <ChevronDown
                                className={cn(
                                    'h-4 w-4 transition-transform duration-300',
                                    showDetailedSettings && 'rotate-180'
                                )}
                            />
                        </Button>

                        {showDetailedSettings && (
                            <div className="flex flex-col gap-4 pl-4">
                                <FormField
                                    control={form.control}
                                    name="languages"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Languages</FormLabel>
                                            <FormControl>
                                                <LanguageSelector value={field.value} onChange={field.onChange} />
                                            </FormControl>
                                        </FormItem>
                                    )}
                                />

                                <FormField
                                    control={form.control}
                                    name="defaultChannel"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Default channel</FormLabel>
                                            <FormControl>
                                                <ToggleGroup
                                                    type="single"
                                                    value={field.value}
                                                    onValueChange={(value) => {
                                                        if (value) {
                                                            field.onChange(value);
                                                        }
                                                    }}
                                                    className="justify-start"
                                                >
                                                    <ToggleGroupItem
                                                        value={ChannelType.LINKEDIN}
                                                        aria-label="Toggle LinkedIn"
                                                    >
                                                        <Linkedin className="h-4 w-4 " />
                                                        LinkedIn
                                                    </ToggleGroupItem>
                                                    <ToggleGroupItem
                                                        value={ChannelType.EMAIL}
                                                        aria-label="Toggle Email"
                                                    >
                                                        <Mail className="h-4 w-4 " />
                                                        Email
                                                    </ToggleGroupItem>
                                                </ToggleGroup>
                                            </FormControl>
                                        </FormItem>
                                    )}
                                />

                                <FormField
                                    control={form.control}
                                    name="default"
                                    render={({ field }) => (
                                        <FormItem className="flex gap-2 items-center">
                                            <FormLabel>Set as Default Playbook</FormLabel>
                                            <FormControl className="flex items-center">
                                                <Checkbox
                                                    className="!mt-0"
                                                    checked={field.value}
                                                    onCheckedChange={(checked) => {
                                                        field.onChange(checked);
                                                    }}
                                                    onClick={(e) => e.stopPropagation()}
                                                />
                                            </FormControl>
                                        </FormItem>
                                    )}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </Form>
        </div>
    );
};

const SuggestionsDialog: FC<{
    currentContext: string;
    open: boolean;
    setModalOpenState: (open: boolean) => void;
    onSuggestionSelect: (suggestion: string) => void;
}> = ({ currentContext, open, setModalOpenState, onSuggestionSelect }) => {
    const [input, setInput] = useState('');
    const {
        playbook: { generatePlaybookContext: generatePlaybookContextMutation },
    } = useSdk();
    const { mutateAsync: generatePlaybookContext, isPending: loading } = useMutation(generatePlaybookContextMutation());

    const handleGenerate = async (feedback: string) => {
        try {
            setInput('');
            onSuggestionSelect('');
            setModalOpenState(false);
            const generatedContext = await generatePlaybookContext({
                currentContext,
                feedback,
            });
            onSuggestionSelect(generatedContext);
        } catch (error) {
            console.error('Failed to generate context:', error);
            setInput(feedback);
            onSuggestionSelect(currentContext);
        }
    };

    let placeholder = currentContext
        ? 'How would you like to change the context?'
        : 'What type of context would you like to add';

    placeholder =
        placeholder + '\n\n(e.g. add details about special deals, meeting links, or strategies for engaging customers)';

    return (
        <Popover open={open} onOpenChange={setModalOpenState}>
            <TooltipProvider>
                <Tooltip>
                    <TooltipTrigger asChild>
                        <PopoverTrigger asChild>
                            <Button type="button" variant="ghost" size="sm" className="h-8 w-8 p-0">
                                <SparklesAI animate={loading} width="32px" height="32px" />
                            </Button>
                        </PopoverTrigger>
                    </TooltipTrigger>
                    <TooltipContent>
                        <p>Get AI suggestions for playbook context</p>
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>
            <PopoverContent className="w-[300px] sm:w-[350px] md:w-[400px] p-0" align="end" side="top">
                <div className="flex flex-col gap-4 p-2">
                    <Textarea
                        rows={6}
                        placeholder={placeholder}
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        disabled={loading}
                        className="resize-none border-0 focus-visible:ring-0 focus-visible:ring-offset-0 shadow-none"
                    />
                    <div className="flex justify-end">
                        <Button onClick={() => handleGenerate(input)} disabled={loading || !input.trim()}>
                            {loading ? (
                                <>
                                    <div className="mr-2 h-4 w-4 animate-spin rounded-full border-2 border-background border-t-foreground"></div>
                                    Update...
                                </>
                            ) : (
                                'Update'
                            )}
                        </Button>
                    </div>
                </div>
            </PopoverContent>
        </Popover>
    );
};
