import { IEvent, Topics } from '@zaplify/common';

export class ProspectPurchasedEvent extends IEvent {
    public static override readonly TOPIC: Topics = Topics.PROSPECTS;
    readonly organizationId: string;
    readonly userId: string;
    readonly prospectId: string;
    readonly personId: string | null;

    constructor(command: { organizationId: string; userId: string; prospectId: string; personId: string | null }) {
        super();
        this.organizationId = command.organizationId;
        this.userId = command.userId;
        this.prospectId = command.prospectId;
        this.personId = command.personId;
    }

    get partitionKey(): string {
        return this.organizationId;
    }
}
