import React, { Suspense } from 'react';
import { motion } from 'motion/react';
import { Skeleton } from '@shadcn/ui/components/ui/skeleton';
import { useSuggestionsFeed } from '../../hooks/use-suggestions-feed';
import { ContactCardActionRow } from './contact/contact-card-action-row';
import { ContactCardHeader } from './contact/contact-card-header';
import { ContactCompanyInfo } from './contact/contact-company-info';
import { ContactRecommendationMotivation } from './contact/contact-recommendation-motivation';
import { ContactCardRecommendationReason } from './contact/recommendation-reason';
import { ContactDetails } from './contact/contact-details';

export const ContactSuggestionCard: React.FC = () => {
    const { currentContactSuggestionId } = useSuggestionsFeed();
    return (
        <div
            // initial={{ opacity: 0 }}
            // animate={{ opacity: 1 }}
            // exit={{ opacity: 0 }}
            // transition={{ duration: 0.2 }}
            className="flex w-full h-full"
            key={currentContactSuggestionId}
        >
            {currentContactSuggestionId ? (
                <Suspense
                    fallback={
                        <div className="flex flex-col items-center justify-center h-full w-full">
                            <Skeleton className="flex flex-col items-center justify-center h-full xs:w-[85%] lg:w-[70%] bg-background-secondary rounded-2xl" />
                        </div>
                    }
                >
                    <ContactSuggestionCardComponent />
                </Suspense>
            ) : (
                <div className="flex flex-col items-center justify-center h-full w-full">
                    <Skeleton className="flex flex-col xs:w-[85%] lg:w-[70%] h-full border rounded-2xl shadow-md" />
                </div>
            )}
        </div>
    );
};

export const ContactSuggestionCardComponent: React.FC = () => {
    const { currentContactSuggestionId } = useSuggestionsFeed();
    const containerRef = React.useRef<HTMLDivElement>(null);

    return (
        <div className="flex flex-row w-full h-full justify-center items-center">
            <div
                className="flex flex-col xs:w-[85%] lg:w-[70%] h-full border rounded-2xl shadow-md p-4 gap-4"
                key={currentContactSuggestionId}
            >
                <div
                    className="flex flex-col gap-4 overflow-y-auto max-h-[calc(100vh-12rem)] h-full"
                    ref={containerRef}
                >
                    <div className="flex items-start gap-2 flex-wrap justify-between">
                        <ContactCardHeader />
                        <ContactCardRecommendationReason />
                    </div>
                    <ContactRecommendationMotivation />
                    <div className="p-2 -mt-1">
                        <ContactDetails />
                    </div>
                    <ContactCompanyInfo />
                    <ContactCardActionRow className={'pt-4 mt-auto sticky bottom-0 bg-background-primary'} />
                </div>
            </div>
        </div>
    );
};
