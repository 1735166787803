import { ArrowForwardIos } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { Button } from '@zaplify/ui';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../routes/paths';

export const Component = () => {
    const navigate = useNavigate();
    const { scheduleOnboardingView } = useFlags();
    const { link, force } = scheduleOnboardingView || {};

    const goToNextStep = () => {
        navigate(paths.CAMPAIGNS);
        window.analytics.track("User didn't schedule onboarding call");
    };

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
            }}
        >
            <Typography sx={{ marginTop: '30px' }} variant="h2" className="title">
                Welcome to Zaplify!
            </Typography>
            <Typography sx={{ marginTop: '10px' }} className="info">
                {force
                    ? 'Schedule your free onboarding call.'
                    : 'Want help getting started? Schedule your free onboarding call.'}
            </Typography>
            <Box className="meetings-iframe-container" sx={{ width: '100%', height: '100%', marginTop: '10px' }}>
                <iframe
                    title={'hubspot'}
                    style={{
                        width: '100%',
                        height: '100%',
                        border: 'none',
                    }}
                    src={`${link}?embed=true`}
                />
            </Box>
            {!force && (
                <Box sx={{ margin: '20px' }}>
                    <Button
                        variant="contained"
                        borderRadius={300}
                        width={100}
                        height="40px"
                        endIcon={<ArrowForwardIos />}
                        onClick={goToNextStep}
                    >
                        Skip
                    </Button>
                </Box>
            )}
        </Box>
    );
};

Component.displayName = 'ScheduleOnboardingView';
