import { useInfiniteQuery, InfiniteData } from '@tanstack/react-query';
import { useLinkedin } from '../../../../hooks/use-linkedin';
import { useCallback, useMemo } from 'react';
import { useDebounce } from '../../../../hooks/use-debounce';
import { Connection, LinkedinConversation } from '@zaplify/sdk';

export type LinkedinPerson = {
    memberId: string;
    firstName: string;
    lastName: string;
    headline: string | null;
    profilePicture: string | null;
    lastMessage?: {
        text: string;
        createdAt: number;
    } | null;
    isInMail?: boolean | null;
};

export type LinkedinSearchResult = {
    persons: LinkedinPerson[];
    hasMore: boolean;
    nextCursor?: string;
};

export type LinkedinSearchType = 'inbox' | 'connections';

const mapConversationToPerson = (conversation: LinkedinConversation): LinkedinPerson => ({
    memberId: conversation.prospect.memberId,
    firstName: conversation.prospect.firstName,
    lastName: conversation.prospect.lastName,
    headline: conversation.prospect.headline,
    profilePicture: conversation.prospect.profilePicture,
    lastMessage: conversation.lastMessage,
    isInMail: conversation.conversation?.isInMail,
});

const mapConnectionToPerson = (connection: Connection): LinkedinPerson => ({
    memberId: connection.memberId,
    firstName: connection.firstName,
    lastName: connection.lastName,
    headline: connection.headline,
    profilePicture: connection.profilePicture,
});

export const useLinkedinSearch = (
    searchTerm: string = '',
    type: LinkedinSearchType = 'inbox',
    { debounceTime = 400 }: { debounceTime?: number } = {}
) => {
    const { getConversations, searchConnections, linkedinAccount, extensionStatus } = useLinkedin();
    const debouncedSearchTerm = useDebounce(searchTerm, debounceTime);

    const fetchInboxData = useCallback(
        async (nextCursor?: string) => {
            const result = await getConversations({
                memberId: linkedinAccount.LINKEDIN.userId,
                keywords: debouncedSearchTerm,
                nextCursor,
                moreThan: 10,
                cacheStaleTime: 300_000,
            });

            if (result.conversations.length === 0) {
                console.warn('useLinkedinSearch: No conversations found in inbox', {
                    searchTerm: debouncedSearchTerm,
                    username: linkedinAccount.LINKEDIN.username,
                });
                return { persons: [], hasMore: false };
            }

            return {
                persons: result.conversations.map(mapConversationToPerson),
                hasMore: !!result.oldestConversationLastEventTime,
                nextCursor: result.oldestConversationLastEventTime,
            };
        },
        [debouncedSearchTerm, linkedinAccount?.LINKEDIN?.userId, getConversations]
    );

    const fetchConnectionsData = useCallback(
        async (nextCursor?: string) => {
            const size = debouncedSearchTerm ? 100 : 40;
            const page = nextCursor ? parseInt(nextCursor) : 0;

            const result = await searchConnections({
                keyword: debouncedSearchTerm || undefined,
                from: page * size,
                size,
            });

            // For some reason, asking for 40 might return fewer sometimes, even if there are more connections after.
            // So we just check if there are more than 10 connections (the chance of them reaching the end of the list is very low anyways)
            const hasMore = result.connections.length > 10;

            return {
                persons: result.connections.map(mapConnectionToPerson),
                hasMore,
                nextCursor: hasMore ? (page + 1).toString() : undefined,
            };
        },
        [debouncedSearchTerm, searchConnections]
    );

    const fetchData = useCallback(
        async ({ pageParam = undefined }) => {
            return type === 'inbox' ? fetchInboxData(pageParam) : fetchConnectionsData(pageParam);
        },
        [type, fetchInboxData, fetchConnectionsData]
    );

    const { data, fetchNextPage, hasNextPage, isFetching, isError, error } = useInfiniteQuery({
        queryKey: ['linkedin-search', type, debouncedSearchTerm] as const,
        queryFn: fetchData,
        initialPageParam: undefined as string | undefined,
        enabled: extensionStatus === 'CONNECTED' && !!linkedinAccount?.LINKEDIN?.userId,
        getNextPageParam: (lastPage) => lastPage.nextCursor,
        staleTime: 1000 * 60 * 2, // Cache for 2 minutes
    });

    const persons = useMemo(() => {
        const allPersons = (data as InfiniteData<LinkedinSearchResult>)?.pages.flatMap((page) => page.persons) ?? [];
        const uniquePersons = allPersons.reduce((acc, person) => {
            if (!acc.some((p) => p.memberId === person.memberId)) {
                acc.push(person);
            }
            return acc;
        }, [] as LinkedinPerson[]);

        return uniquePersons.sort((a, b) => {
            const aTime = a.lastMessage?.createdAt ?? 0;
            const bTime = b.lastMessage?.createdAt ?? 0;
            return bTime - aTime;
        });
    }, [data?.pages]);

    return {
        persons,
        fetchMore: fetchNextPage,
        hasMore: hasNextPage,
        isLoading: isFetching,
        isError,
        error,
    };
};
