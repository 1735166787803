import { IQuery, Topics } from '@zaplify/common';
import { NoteDto } from '../dtos';

export class GetNotesOnUserProspectQuery extends IQuery<NoteDto[]> {
    public static override readonly TOPIC = Topics.PROSPECTS;
    public readonly userId: string;
    public readonly prospectId: string;

    constructor(userId: string, prospectId: string) {
        super();
        this.userId = userId;
        this.prospectId = prospectId;
    }
}
