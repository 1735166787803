import { LinkedinPostDto } from './linkedin-post.dto';

export enum LinkedinActivityType {
    MESSAGE_READ = 'MESSAGE_READ',
    MESSAGE_SENT = 'MESSAGE_SENT',
    CONVERSATION_REPLY = 'CONVERSATION_REPLY',
    CONNECTION_REQUEST_ACCEPT = 'CONNECTION_REQUEST_ACCEPT',
    CONNECTION_REQUEST_SENT = 'CONNECTION_REQUEST_SENT',
    PERSON_MADE_POST = 'PERSON_MADE_POST',
}

export enum LinkedinActivitySource {
    MESSAGING_SCAN = 'MESSAGING_SCAN',
    CONNECTION_SCAN = 'CONNECTION_SCAN',
}

export interface ILinkedinTriggerActivityAttachments {
    imageUrls?: string[];
    feedUpdates?: string[];
}

export interface ILinkedinTriggerActivityData {
    source?: LinkedinActivitySource;
    subject?: string;
    message?: string;
    messageId?: string;
    conversationId?: string;
    attachments?: ILinkedinTriggerActivityAttachments;
    post?: LinkedinPostDto;
}

export interface ILinkedinConnectionRequestSentActivityData extends ILinkedinTriggerActivityData {
    message?: string;
    messageId: string;
    attachments?: ILinkedinTriggerActivityAttachments;
}

export interface ILinkedinMessageSentActivityData extends ILinkedinTriggerActivityData {
    message?: string;
    messageId: string;
    conversationId: string;
    attachments?: ILinkedinTriggerActivityAttachments;
}

export interface ILinkedinConversationReplyActivityData extends ILinkedinTriggerActivityData {
    message?: string;
    messageId: string;
    conversationId: string;
    attachments?: ILinkedinTriggerActivityAttachments;
}

export interface ILinkedinPersonMadePostActivityData extends ILinkedinTriggerActivityData {
    post: LinkedinPostDto;
}

export type ILinkedinConnectionRequestAcceptedActivityData = ILinkedinTriggerActivityData;

export abstract class LinkedinTriggerActivityDto {
    abstract readonly type: LinkedinActivityType;
    abstract data: ILinkedinTriggerActivityData;
    constructor(readonly timestamp: number) {}
}

export class LinkedinConversationReplyActivityDto extends LinkedinTriggerActivityDto {
    type = LinkedinActivityType.CONVERSATION_REPLY;
    data: ILinkedinConversationReplyActivityData;
    constructor(timestamp: number, data: ILinkedinConversationReplyActivityData) {
        super(timestamp);
        this.data = data;
    }
}

export class LinkedinMessageSentActivityDto extends LinkedinTriggerActivityDto {
    type = LinkedinActivityType.MESSAGE_SENT;
    data: ILinkedinMessageSentActivityData;
    constructor(timestamp: number, data: ILinkedinMessageSentActivityData) {
        super(timestamp);
        this.data = data;
    }
}

export class LinkedinConnectionRequestAcceptedActivityDto extends LinkedinTriggerActivityDto {
    type = LinkedinActivityType.CONNECTION_REQUEST_ACCEPT;
    data: ILinkedinConnectionRequestAcceptedActivityData;
    constructor(timestamp: number, data: ILinkedinConnectionRequestAcceptedActivityData) {
        super(timestamp);
        this.data = data;
    }
}

export class LinkedinConnectionRequestSentActivityDto extends LinkedinTriggerActivityDto {
    type = LinkedinActivityType.CONNECTION_REQUEST_SENT;
    data: ILinkedinConnectionRequestSentActivityData;
    constructor(timestamp: number, data: ILinkedinConnectionRequestSentActivityData) {
        super(timestamp);
        this.data = data;
    }
}

export class LinkedinPersonMadePostActivityDto extends LinkedinTriggerActivityDto {
    type = LinkedinActivityType.PERSON_MADE_POST;
    data: ILinkedinPersonMadePostActivityData;
    constructor(timestamp: number, data: ILinkedinPersonMadePostActivityData) {
        super(timestamp);
        this.data = data;
    }
}
