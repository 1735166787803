import { Box, ButtonBase, Typography } from '@mui/material';
import { Button, Notification } from '@zaplify/ui';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { ReactComponent as ProspectsFeedIllustration } from './components/prospects-feed-illustration.svg';
import { paths } from '../../routes/paths';

export const Component = () => {
    const { showImportLinkedinConversations, displayImportCsvProspectsInProspecting } = useFlags();
    const { pathname } = useLocation();
    const [notifcation, setNotification] = useState<{
        text: string;
        severity: 'success' | 'error' | 'info' | 'warning';
    }>({ text: '', severity: 'success' });

    return (
        <Box sx={{ overflow: 'hidden', height: '100%' }}>
            <Box
                sx={{
                    boxSizing: 'border-box',
                    padding: '16px',
                    gap: '16px',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    fontFamily:
                        '"Helvetica Neue", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu","Cantarell", "Fira Sans", "Droid Sans", sans-serif !important',
                }}
            >
                <Box sx={{ gap: '16px', display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="h3" sx={{ fontSize: '24px' }}>
                        Import
                    </Typography>
                </Box>
                <Outlet />
                {Boolean(notifcation.text) && (
                    <Notification
                        open={Boolean(notifcation.text)}
                        onClose={() => setNotification({ ...notifcation, text: '' })}
                        text={notifcation.text}
                        severity={notifcation.severity}
                    />
                )}
            </Box>
        </Box>
    );
};

Component.displayName = 'ProspectingView';

export const EmptyProspectsPanel = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 'var(--M-m-spacious, 32px)',
                flex: '1 0 0',
                alignSelf: 'stretch',
                paddingTop: '50px',
                '& p': {
                    color: 'var(--grey-palette-grey-4, #6B7985)',
                    fontSize: 15,
                    fontWeight: 400,
                },
                '& button': {
                    boxShadow: 'none',
                    height: '48px',
                    borderRadius: '8px',
                    background: 'var(--primary-20-base, #6A76FB)',
                    '&:hover': {
                        boxShadow: 'none',
                    },
                },
                '& svg': {
                    width: '270px',
                },
            }}
        >
            <ProspectsFeedIllustration />
            <Box sx={{ gap: '8px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant="h3">Get a curated feed of prospects</Typography>
                <Typography>Add prospects to receive AI suggestions of potential leads</Typography>
            </Box>
        </Box>
    );
};
