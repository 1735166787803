import { CONTACT_REF_FIELDS, CUSTOM_FIELDS, PROSPECT_FIELDS } from './constants';
import { FieldMapping, UpsalesStateType } from './slice';

function fieldMappingHasField(
    isSourceFieldExist: boolean,
    fieldMapping: FieldMapping[],
    fieldMappingField: string,
    destinationField: string
): boolean {
    return isSourceFieldExist && !fieldMapping.some((field) => field[fieldMappingField] === destinationField);
}

export function getSpecialUpsalesMapping(upsales: UpsalesStateType, fieldMapping: FieldMapping[]) {
    const tempFieldMapping: any[] = [];
    if (
        fieldMappingHasField(upsales.mapLinkedIn, fieldMapping, 'prospectField', PROSPECT_FIELDS.LINKEDIN_PROFILE_URL)
    ) {
        tempFieldMapping.push({
            prospectField: PROSPECT_FIELDS.LINKEDIN_PROFILE_URL, // double check this one first
            contactField: 'LinkedIn Profile Url', // for upsales side use this precise one
            create: {
                title: 'LinkedIn Profile URL',
                description: 'LinkedIn Profile URL of the prospect',
            },
        });
    }

    if (fieldMappingHasField(upsales.mapCampaignName, fieldMapping, 'prospectField', CUSTOM_FIELDS.CAMPAIGN_NAME)) {
        tempFieldMapping.push({
            prospectField: CUSTOM_FIELDS.CAMPAIGN_NAME, // double check this one first
            contactField: 'Zaplify Campaign Name', // for upsales side use this precise one
            create: {
                title: 'Zaplify Prospect Campaign Name',
                description: 'Prospect campaign name in Andsend',
            },
        });
    }

    if (
        fieldMappingHasField(
            upsales.mapLinkedInConnection,
            fieldMapping,
            'prospectField',
            CONTACT_REF_FIELDS.IS_LINKEDIN_CONNECTED
        )
    ) {
        tempFieldMapping.push({
            prospectField: CONTACT_REF_FIELDS.IS_LINKEDIN_CONNECTED, // double check this one first
            contactField: 'Is LinkedIn Connected', // for upsales side use this precise one
            create: {
                title: 'Contact creator is connected on Linkedin',
                description: 'Contact creator is connected on Linkedin',
            },
        });
    }

    if (
        fieldMappingHasField(
            upsales.mapZaplifyProspectStatus,
            fieldMapping,
            'prospectField',
            CONTACT_REF_FIELDS.STATUS_IN_CAMPAIGN
        )
    ) {
        tempFieldMapping.push({
            prospectField: CONTACT_REF_FIELDS.STATUS_IN_CAMPAIGN, // double check this one first
            contactField: 'Zaplify Prospect status', // for upsales side use this precise one
            create: {
                title: 'Zaplify Prospect Status',
                description: 'Prospect status in Zaplify campaign',
            },
        });
    }

    if (
        fieldMappingHasField(
            upsales.mapOrganizationDomain,
            fieldMapping,
            'companyField',
            PROSPECT_FIELDS.ORGANIZATION_DOMAIN
        )
    ) {
        tempFieldMapping.push({
            prospectField: PROSPECT_FIELDS.ORGANIZATION_DOMAIN, // double check this one first
            companyField: 'Company Domain', // for upsales side use this precise one
            create: {
                title: 'Company Domain',
                description: '',
            },
        });
    }

    if (
        fieldMappingHasField(
            upsales.mapOrganizationIndustry,
            fieldMapping,
            'companyField',
            PROSPECT_FIELDS.ORGANIZATION_INDUSTRY
        )
    ) {
        tempFieldMapping.push({
            prospectField: PROSPECT_FIELDS.ORGANIZATION_INDUSTRY, // double check this one first
            companyField: 'Company Industry', // for upsales side use this precise one
            create: {
                title: 'Company Industry',
                description: '',
            },
        });
    }

    if (
        fieldMappingHasField(
            upsales.mapOrganizationSize,
            fieldMapping,
            'companyField',
            PROSPECT_FIELDS.ORGANIZATION_SIZE
        )
    ) {
        tempFieldMapping.push({
            prospectField: PROSPECT_FIELDS.ORGANIZATION_SIZE, // double check this one first
            companyField: 'Company Size', // for upsales side use this precise one
            create: {
                title: 'Company Size',
                description: '',
            },
        });
    }

    return tempFieldMapping;
}
