import { Minus, Plus } from 'lucide-react';
import * as React from 'react';

import { Button } from './ui/button';
import { Input } from './ui/input';

type InputDecreaseIncrease = {
    min?: number;
    max?: number;
    step?: number;
    value?: number;
    onChange?: (value: number) => void;
};
type InputDecreaseIncreaseProps = React.ComponentProps<'input'> & InputDecreaseIncrease;

const InputDecreaseIncrease = React.forwardRef<HTMLInputElement, InputDecreaseIncreaseProps>(
    ({ className, type, min = 0, max = 100, step = 1, value: initialValue, onChange, ...props }, ref) => {
        const [value, setValue] = React.useState(initialValue || min);

        const decrease = () => {
            setValue((prevValue) => Math.max(min, prevValue - step));
        };

        const increase = () => {
            setValue((prevValue) => Math.min(max, prevValue + step));
        };

        React.useEffect(() => {
            onChange?.(value);
        }, [value, onChange]);

        return (
            <div className="flex items-center">
                <Button
                    onClick={decrease}
                    size="icon"
                    variant="outline"
                    className="w-10 rounded-r-none border-r-0 shadow-none"
                >
                    <Minus className="h-4 w-4" />
                </Button>
                <Input
                    type="number"
                    className="h-9 w-20 rounded-none border-x-0 p-0 text-center focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0 pointer-events-none
          [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                    value={value}
                    readOnly
                    min={min}
                    max={max}
                    step={step}
                />
                <Button
                    onClick={increase}
                    size="icon"
                    variant="outline"
                    className="w-10 rounded-l-none border-l-0 shadow-none"
                >
                    <Plus className="h-4 w-4" />
                </Button>
            </div>
        );
    },
);
InputDecreaseIncrease.displayName = 'InputDecreaseIncrease';

export { InputDecreaseIncrease };
