import { Box, Button, Typography } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux-latest';
import { isNumber, isString } from '../../../helpers/utils';
import { setBlacklistThunk } from '../../../redux/actions';
import { AppDispatch, RootState } from '../../../redux/store/configureStore';
import { Modal } from '../../atoms/modal';
import FileUpload, { DropResult } from '../../file-upload';
import { InfoIcon } from '../../icons/info';
import ImportBlocklistCompaniesStyled from './ImportBlocklistCompanies.styled';

const BLACK_LIST_LIMIT = 5000;

const BlacklistModal = ({ closeModal }: { closeModal: () => void }) => {
    const dispatch = useDispatch<AppDispatch>();
    const blacklist = useSelector((state: RootState) => state.userOrganization.blacklist);
    const numberOfCompaniesLeftToBlock = BLACK_LIST_LIMIT - blacklist.length;
    const [rawData, setRawData] = useState<DropResult>([]);
    const [selectedColumn, setSelectedColumn] = useState<string | null>(null);

    // Will update the blocklist with the new data, not overwrite the old one
    const setBlocklist = async () => {
        if (!selectedColumn) {
            return;
        }
        const payload = rawData
            .map((item) => ({ name: item[selectedColumn] ? `${item[selectedColumn]}` : null }))
            .filter((item) => isString(item.name) && !isNumber(item.name));

        await dispatch(setBlacklistThunk(payload, blacklist, true));
        closeModal();
    };

    const validateData = (data: DropResult) => {
        const errors: string[] = [];
        const noResults = data.length === 0;
        const resultsExceedLimit = data.length > BLACK_LIST_LIMIT;
        const resultsExceedNumberOfCompaniesLeftToBlock = numberOfCompaniesLeftToBlock - data.length <= 0;

        if (noResults) {
            errors.push('File seems to be empty');
            return errors;
        }

        if (resultsExceedLimit) {
            errors.push('Blocklist is limited to max 5 000 companies');
        }

        if (resultsExceedNumberOfCompaniesLeftToBlock) {
            errors.push(
                `There are already ${blacklist.length} companies blocked, so file can contain max ${numberOfCompaniesLeftToBlock} companies`
            );
        }

        return errors;
    };

    const handleResetAll = () => {
        setRawData([]);
    };

    return (
        <>
            <Modal closeModal={closeModal} isOpen={true}>
                <ImportBlocklistCompaniesStyled>
                    <Typography variant="h3" className="title">
                        Upload list with companies to block
                    </Typography>
                    <Box textAlign="left" width={'100%'}>
                        <Typography className="description">
                            In order to block companies, upload a list with the following requirements:
                        </Typography>
                        <Box className="rules">
                            <Typography>1. It should contain one column with company names</Typography>
                            <Typography>
                                2. All legal entities should be removed from company names, e.g. Inc or LLC
                            </Typography>
                            <Typography>3. Column must include headline (eg. Company Name)</Typography>
                        </Box>
                    </Box>
                    <FileUpload
                        columnSelection
                        onResult={(data: DropResult) => setRawData(data)}
                        dataValidator={({ results }) => validateData(results)}
                        additionalInfo={() => (
                            <div className="additional-info-wrapper">
                                <div className="additional-info">
                                    <InfoIcon className="icon" />
                                    <div className="text">
                                        Note! The blocklist is limited to max 5.000 companies. When you block companies
                                        they will be blocked for all users in your organization.
                                    </div>
                                </div>
                            </div>
                        )}
                        onColumnSelected={(columnName) => setSelectedColumn(columnName)}
                        onResetAll={handleResetAll}
                    />
                    <div className="action">
                        <Button
                            className={`action-button ${rawData.length === 0 && 'disabled'}`}
                            disableElevation
                            variant="contained"
                            disabled={rawData.length === 0}
                            onClick={setBlocklist}
                        >
                            Upload
                        </Button>
                    </div>
                </ImportBlocklistCompaniesStyled>
            </Modal>
        </>
    );
};

export default BlacklistModal;
