import { getDateString } from '@zaplify/frontend-common';
import { ProspectDto } from '@zaplify/prospects';
import { getProspectEducationSorted, getProspectExperiencesSorted } from '../../../../../helpers/prospects';

export const useFormattedContact = () => {
    const getContactData = (userContact: { userContactId: string; prospect: ProspectDto }) => {
        const { personId, phoneNumbers, experiences, education, linkedinProfileVerification, ...prospectData } =
            userContact?.prospect?.data;

        //getting stringified education & experiences & phonenumbers
        const formattedExperiences = getProspectExperiences(experiences);
        const formattedEducation = getProspectEducation(education);
        const formattedPhoneNumbers = getProspectPhoneNumbers(phoneNumbers);

        //Replacing hashtags cause csv breaks otherwise
        const linkedinDescription = prospectData?.linkedinDescription?.replace(/#/g, '');
        prospectData.linkedinDescription = linkedinDescription;

        return {
            id: userContact.userContactId,
            ...prospectData,
            ...formattedPhoneNumbers,
            ...formattedExperiences,
            ...formattedEducation,
        };
    };

    return { getContactData };
};

function getObjectKeysFromArray(data: Array<object>): string[] {
    const dataObjectKeys = [];
    data.forEach((item) => {
        Object.keys(item).forEach((key) => {
            if (!dataObjectKeys.includes(key)) {
                dataObjectKeys.push(key);
            }
        });
    });
    return dataObjectKeys;
}

export function getProspectExperiences(experiences) {
    if (Array.isArray(experiences) && experiences.length) {
        return experiences
            .sort((a, b) => getProspectExperiencesSorted(a, b))
            .reduce(
                (acc, curr, idx) => ({
                    ...acc,
                    [`experience ${idx + 1}`]: JSON.stringify(curr),
                }),
                {}
            );
    } else {
        return {};
    }
}

export function getProspectEducation(education) {
    if (Array.isArray(education) && education.length) {
        return education
            .sort((a, b) => getProspectEducationSorted(a, b))
            .reduce(
                (acc, curr, idx) => ({
                    ...acc,
                    [`education ${idx + 1}`]: JSON.stringify(curr),
                }),
                {}
            );
    } else {
        return {};
    }
}

export function getProspectPhoneNumbers(phoneNumbers) {
    if (Array.isArray(phoneNumbers) && phoneNumbers.length) {
        return phoneNumbers.reduce(
            (acc, curr, idx) => ({
                ...acc,
                [`phoneNumber ${idx + 1}`]: curr.number,
            }),
            {}
        );
    } else {
        return {};
    }
}

//Returns file name for prospect csv file. Format - "zaplify-prospects-CAMPIGNNAME-DATE"
export function getFileName() {
    let fileName = 'contacts';
    fileName += '-';
    fileName += getDateString();

    return fileName;
}
