import AddIcon from '@mui/icons-material/Add';
import { Typography } from '@mui/material';
import { useDispatch } from 'react-redux-latest';
import { Button } from '../../../../../../components/atoms/button';
import { updateSalesforceSaveStatus } from '../../../../../../redux/ducks/salesforce/operations';
import { addField, deleteField, updateField } from '../../../../../../redux/ducks/salesforce/slice';
import { AppDispatch, useAppSelector } from '../../../../../../redux/store/configureStore';
import { disableUsedFieldSalesforce } from '../../../utils/fields';
import { ObjectTypes } from '../../constants';
import { FieldMappingContainer } from '../containers';
import { FieldMapHeader } from '../fieldMapHeader';
import { ProgressBar } from '../progressBar';
import { RefreshFields } from '../refreshFields';
import { FieldSelector } from '../selector/fieldSelector';
import { StaticFieldSelector } from '../selector/staticFieldSelector';
import { fieldValidator } from '../utils';

export const FieldMapLead = () => {
    const dispatch = useDispatch<AppDispatch>();
    const fields = useAppSelector((state) => state.salesforce.fieldMapping).map((field, idx) => {
        return { ...field, idx };
    });

    const fieldsLead = fields.filter((field) => field.destination.objectType === ObjectTypes.lead);

    const leadFields = useAppSelector((state) => state.salesforce.availableFields?.lead || []);
    const leadPickListFields = useAppSelector((state) => state.salesforce.availableFields?.leadPickList || []);
    const prospectFields = useAppSelector((state) => state.salesforce.availableFields?.prospect || []);

    const handleDeleteField = (index) => {
        dispatch(deleteField({ index: index }));
        dispatch(updateSalesforceSaveStatus());
    };

    const leftOnChange = (e, index) => {
        const prospectField = prospectFields.find((field) => field.name === e.target.value);
        if (!prospectField) {
            return;
        }
        dispatch(
            updateField({ idx: index, type: 'source', objectType: prospectField.resource, fieldName: e.target.value })
        );
        dispatch(updateSalesforceSaveStatus());
    };
    const rightOnChange = (e, index) => {
        const field = [...leadFields, ...leadPickListFields].find((field) => field.name === e.target.value);
        if (!field) {
            return;
        }
        dispatch(
            updateField({
                idx: index,
                type: 'destination',
                objectType: field.resource,
                fieldName: e.target.value,
                isStatic: !!field.options?.length,
            })
        );
        dispatch(updateSalesforceSaveStatus());
    };

    const getStaticFieldOptions = (field) => {
        const leadField = leadPickListFields.find((leadField) => leadField.name === field.destination.fieldName);

        if (leadField && leadField.options) {
            return leadField?.options.map((option) => option.value);
        }
        return [];
    };

    const getStaticPlaceholder = (field) => {
        const leadField = leadPickListFields.find((leadField) => leadField.name === field.destination.fieldName);
        if (leadField) {
            return `Select *${leadField.label}*`;
        }
        return `Select * NOT FIELD FIND *`;
    };

    const formattedProspectFields = prospectFields.map((field) => ({ name: field.name, label: field.label }));
    const formattedLeadFields = leadFields.map((field) => ({ name: field.name, label: field.label }));
    const formattedPickListLeadFields = leadPickListFields.map((field) => ({ name: field.name, label: field.label }));
    const concatFormattedLeadFields = [...formattedLeadFields, ...formattedPickListLeadFields].map(
        (formattedContactField) => disableUsedFieldSalesforce(formattedContactField, fieldsLead, 'fieldName', 'name')
    );

    if (!leadFields.length) {
        return (
            <FieldMappingContainer>
                <ProgressBar show={!leadFields.length} />
            </FieldMappingContainer>
        );
    }

    return (
        <FieldMappingContainer>
            <FieldMapHeader />
            <Typography variant="body2">Prospect</Typography>
            <div>
                {fieldsLead.map((field) => {
                    if (field.source.type === 'static') {
                        return (
                            <StaticFieldSelector
                                field={fieldValidator(field)}
                                getStaticPlaceholder={getStaticPlaceholder}
                                getStaticFieldOptions={getStaticFieldOptions}
                                leftOnChange={leftOnChange}
                                handleDeleteField={handleDeleteField}
                            />
                        );
                    }
                    return (
                        <FieldSelector
                            field={fieldValidator(field)}
                            leftOptions={formattedProspectFields}
                            rightOptions={concatFormattedLeadFields}
                            leftOnChange={leftOnChange}
                            rightOnChange={rightOnChange}
                            handleDeleteField={handleDeleteField}
                        />
                    );
                })}
            </div>

            <Button
                borderRadius="100px"
                variant="outlined"
                height="40px"
                startIcon={<AddIcon />}
                text="Add field"
                onClick={() => {
                    dispatch(addField({ objectType: ObjectTypes.lead }));
                    dispatch(updateSalesforceSaveStatus());
                }}
            />
            <div className={'refresh-container'}>
                <RefreshFields />
            </div>
        </FieldMappingContainer>
    );
};
