import { Box, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

interface NavigationListItemProps {
    title: string;
    to: string;
    isActive: boolean;
    isSidebarPinned: boolean;
    onClick?: () => void;
    showIndicator?: boolean;
    icon?: React.ReactNode; // Add this line
}

export const NavigationListItem = ({
    title,
    to,
    isActive,
    isSidebarPinned,
    onClick,
    showIndicator = false,
    icon,
}: NavigationListItemProps) => {
    return (
        <ListItem
            className="submenu-item"
            component={Link}
            to={to}
            disablePadding
            onClick={onClick}
            sx={{
                textDecoration: 'none',
                display: 'flex',
                borderRadius: 2,
                justifyContent: isSidebarPinned ? 'flex-start' : 'center',
                backgroundColor: isActive ? '#D7D8FF' : '',
                '&:hover': {
                    backgroundColor: isActive ? '#D7D8FF' : '#EBEBFF',
                },
                '.MuiListItemText-root': {
                    margin: 0,
                    textAlign: 'left',
                },
            }}
        >
            <ListItemButton
                disableRipple
                sx={{
                    py: 0.5,
                    px: 0,
                    width: '100%',
                    '&:hover': {
                        background: 'unset !important',
                    },
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'flex-start', width: '100%', gap: '8px', px: 1 }}>
                    {icon && <ListItemIcon sx={{ minWidth: 'auto' }}>{icon}</ListItemIcon>}
                    <ListItemText
                        primary={title}
                        primaryTypographyProps={{
                            sx: {
                                fontSize: '14px',
                                fontWeight: isActive ? 'inherit' : '400 !important',
                                color: isActive ? 'rgb(92, 92, 214) !important' : '#475467 !important',
                                textAlign: 'left',
                                display: 'flex',
                                justifyContent: 'flex-start',
                            },
                        }}
                        sx={{ flex: 1, margin: 0 }}
                    />
                    {showIndicator && (
                        <Box
                            sx={{
                                width: '6px',
                                height: '6px',
                                background: 'var(--Component-colors-Utility-Orange-utility-orange-700, #B93815)',
                                borderRadius: '50%',
                                fill: 'var(--Component-colors-Utility-Orange-utility-orange-700, #B93815)',
                            }}
                        />
                    )}
                </Box>
            </ListItemButton>
        </ListItem>
    );
};
