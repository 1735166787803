import { styled } from '@mui/styles';

export const ViewContainer = styled('div')(({ theme }) => ({
    width: '100%',
    height: '100%',
}));

export const IntegrationsViewContainer = styled('div')(({ theme }) => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 60,
    '& > p:nth-child(2)': {
        marginTop: 11,
    },
    '& > div:nth-child(3)': {
        marginTop: 30,
        background: '#FFFFFF',
        border: '0.8px solid #E4E6EB',
        boxSizing: 'border-box',
        boxShadow: '0px 2px 6px rgb(112 144 176 / 7%)',
        borderRadius: 14,
        padding: '40px 50px',
        width: '100%',
        maxWidth: 1160,
        '& > div:nth-child(2)': {
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            marginTop: 26,
        },
    },
}));

export const UnactivatedAppCardContainer = styled('div')(({ disabled, theme }) => ({
    border: '0.8px solid #E4E6EB',
    boxSizing: 'border-box',
    boxShadow: '0px 2px 6px rgb(112 144 176 / 7%)',
    background: disabled ? '#F7F8FA' : '#FFFFFF',
    borderRadius: 14,
    width: 256,
    padding: 26,
    textAlign: 'center',
    '& > div:first-child': {
        height: 65,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    '& > p': {
        marginTop: 14,
    },
    '& > span > button': {
        marginTop: 20,
    },
    '& > a  > button': {
        marginTop: 20,
    },
}));

export const ActivatedHeightAppCardContainer = styled('div')(({ theme }) => ({
    height: 64,
}));

export const ActivatedAppCardContainer = styled('div')(({ theme }) => ({
    border: '0.8px solid #E4E6EB',
    boxSizing: 'border-box',
    boxShadow: '0px 2px 6px rgb(112 144 176 / 7%)',
    borderRadius: 14,
    width: 256,
    padding: 26,
    textAlign: 'center',
    '& > div:first-child': {
        display: 'flex',
        justifyContent: 'flex-end',
        height: 22,
        '& > button': {
            height: 40,
            width: 40,
            marginRight: '-10px',
            marginTop: '-5px',
            '& > span > a > svg': {
                marginTop: 5,
                marginLeft: 2,
            },
        },
    },
    '& > span:nth-child(3)': {
        marginTop: 10,
    },
    '& > p:nth-child(4)': {
        marginTop: 10,
    },
    '& > button:nth-child(5)': {
        marginTop: 10,
    },
}));
