import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { palette } from '../../theme/palette';

type Props = {
    color?: string;
};

export const SuccessIcon: React.FC<Props & React.HTMLAttributes<HTMLElement>> = ({ color }: Props) => {
    return (
        <SvgIcon viewBox='0 0 20 20'>
            <path
                d='M18.6666 3.74825L8.20742 14.2166L4.32075 10.3299L5.61325 9.03742L8.20742 11.6316L17.3741 2.46492L18.6666 3.74825ZM16.6408 8.36825C16.7599 8.89075 16.8333 9.44075 16.8333 9.99992C16.8333 14.0516 13.5516 17.3333 9.49992 17.3333C5.44825 17.3333 2.16659 14.0516 2.16659 9.99992C2.16659 5.94825 5.44825 2.66659 9.49992 2.66659C10.9483 2.66659 12.2866 3.08825 13.4233 3.81242L14.7433 2.49242C13.2583 1.44742 11.4524 0.833252 9.49992 0.833252C4.43992 0.833252 0.333252 4.93992 0.333252 9.99992C0.333252 15.0599 4.43992 19.1666 9.49992 19.1666C14.5599 19.1666 18.6666 15.0599 18.6666 9.99992C18.6666 8.90909 18.4649 7.86408 18.1166 6.89242L16.6408 8.36825Z'
                fill={color || palette.newGreen}
            />
        </SvgIcon>
    );
};
