import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { palette } from '../../theme/palette';

type Props = {
    color?: string;
};

export const WarningIcon: React.FC<Props & React.HTMLAttributes<HTMLElement>> = ({ color }: Props) => {
    return (
        <SvgIcon viewBox='0 0 20 20'>
            <path
                d='M0.916748 18.2499H21.0834L11.0001 0.833252L0.916748 18.2499ZM11.9167 15.4999H10.0834V13.6666H11.9167V15.4999ZM11.9167 11.8333H10.0834V8.16658H11.9167V11.8333Z'
                fill={color || palette.orange}
            />
        </SvgIcon>
    );
};
