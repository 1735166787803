import { Tooltip } from '@mui/material';
import { MessagesSquare } from 'lucide-react';
import { paths } from '../../../../routes/paths';
import { NavigationMainItem } from './navigation-main-item';
import { useResetAtom } from 'jotai/utils';
import { messageComposerStateAtom } from '../../../../atoms/chats';
import { useLocation } from 'react-router-dom';

export const AllConversationsListItem = ({ openSidebar }: { openSidebar: boolean }) => {
    const { pathname } = useLocation();
    const isActive = pathname.startsWith(paths.CHATS);
    const resetMessageComposerStateAtom = useResetAtom(messageComposerStateAtom);

    return (
        <Tooltip title={openSidebar ? '' : 'All conversations'} placement="right" arrow>
            {/* important className for Frigade */}
            <span onClick={resetMessageComposerStateAtom} className="all-conversations-nav-item">
                <NavigationMainItem
                    title="All conversations"
                    to={paths.CHATS}
                    icon={<MessagesSquare size={20} strokeWidth="1px" />}
                    isActive={isActive}
                    isSidebarPinned={openSidebar}
                />
            </span>
        </Tooltip>
    );
};
