import React from 'react';
import { cn } from '@shadcn/ui/lib/utils';
import { paths } from '../../../../routes/paths';

// Basic styles for text content
const pStyle = 'text-base my-2 mb-4';
const h2Style = 'text-2xl font-semibold mt-8 mb-4';
const h3Style = 'text-xl font-semibold mt-6 mb-3';
const linkStyle = 'text-primary underline hover:text-primary/90';
const listItemStyle = 'ml-6 my-1';

export default function TermsOfService20230709() {
    return (
        <div className="container mx-auto py-12 px-4 max-w-4xl">
            <h1 className="text-4xl font-bold mb-6">Zaplify Terms of Service (ARCHIVED)</h1>

            <div>
                <h4 className="text-xl mb-8">Last Updated: July 9, 2023</h4>

                <p className={cn(pStyle, 'italic font-bold')}>
                    IMPORTANT NOTE: THIS IS AN ARCHIVED VERSION OF THE ToS. PLEASE REFER TO{' '}
                    <a href={paths.LEGAL.TERMS} className={linkStyle}>
                        THIS LINK
                    </a>{' '}
                    FOR THE CURRENT VERSION OF THE TERMS OF SERVICE.
                </p>

                <div className="border-t border-b border-border my-8 py-0.5"></div>

                <h2 className={h2Style}>1. Terms</h2>
                <p className={pStyle}>
                    By accessing the web application at{' '}
                    <a href="https://zaplify.com/" className={linkStyle} target="_blank" rel="noreferrer">
                        https://zaplify.com/
                    </a>
                    , you are agreeing to be bound by these Terms of Service, all applicable laws and regulations, and
                    agree that you are responsible for compliance with any applicable local laws. If you do not agree
                    with any of these terms, you are prohibited from using or accessing this site. The materials
                    contained in this web application are protected by applicable copyright and trademark law.
                </p>

                <h2 className={h2Style}>2. Use License</h2>
                <ol className="list-decimal pl-6 mb-4">
                    <li className={cn(listItemStyle, 'mb-2')}>
                        Permission is granted to use Zaplify's licensed web application, and download permitted data.
                        This is the grant of a license, not a transfer of title, and under this license you may not:
                        <ol className="list-[lower-alpha] pl-6 mt-2">
                            <li className={listItemStyle}>modify or copy the materials;</li>
                            <li className={listItemStyle}>redistribute the materials for any commercial purpose;</li>
                            <li className={listItemStyle}>
                                display or give access to the licensed, or confidential, materials to any non-permitted
                                third-party or sub-contractor;
                            </li>
                            <li className={listItemStyle}>
                                use the service by more concurrent users than the number of licenses purchased;
                            </li>
                            <li className={listItemStyle}>
                                attempt to decompile or reverse engineer any software contained on Zaplify's web
                                application;
                            </li>
                            <li className={listItemStyle}>
                                remove any copyright or other proprietary notations from the materials;
                            </li>
                            <li className={listItemStyle}>
                                transfer the materials to another person or "mirror" the materials on any other server;
                            </li>
                            <li className={listItemStyle}>
                                use the service in any way that causes damage to the platform or company brand.
                            </li>
                        </ol>
                    </li>
                    <li className={listItemStyle}>
                        This license shall automatically terminate if you violate any of these restrictions and may be
                        terminated by Zaplify at any time. Upon terminating your viewing of these materials or upon the
                        termination of this license, you must destroy any downloaded materials in your possession
                        whether in electronic or printed format.
                    </li>
                </ol>

                <h2 className={h2Style}>3. Disclaimer</h2>
                <ol className="list-decimal pl-6 mb-4">
                    <li className={listItemStyle}>
                        The materials on Zaplify's web application are provided on an "as is" basis. Zaplify makes no
                        warranties, expressed or implied, and hereby disclaims and negates all other warranties
                        including, without limitation, implied warranties or conditions of merchantability, fitness for
                        a particular purpose, or non-infringement of intellectual property or other violation of rights.
                    </li>
                    <li className={listItemStyle}>
                        Further, while Zaplify is obliged to deliver a reasonable level of quality and functionality of
                        core features and fix issues arising within the service in reasonable time, Zaplify does not
                        warrant or make any representations concerning the reliability of the use of the materials on
                        its web application or otherwise relating to such materials or on any sites linked to this site.
                        As the service is, and will remain, a work in progress, specific features may be added and
                        removed without notice by Zaplify.
                    </li>
                </ol>

                <h2 className={h2Style}>4. Limitations</h2>
                <ol className="list-decimal pl-6 mb-4">
                    <li className={listItemStyle}>
                        In no event shall Zaplify or its suppliers be liable for any damages (including, without
                        limitation, damages for loss of data or profit, or due to business interruption) arising out of
                        the use or inability to use the materials on Zaplify's web application, even if Zaplify or a
                        Zaplify authorized representative has been notified orally or in writing of the possibility of
                        such damage. Because some jurisdictions do not allow limitations on implied warranties, or
                        limitations of liability for consequential or incidental damages, these limitations may not
                        apply to you.
                    </li>
                    <li className={listItemStyle}>
                        You are responsible for data that you have input to the platform that is used by the service and
                        the repercussions your activity on the platform, such as activities that may hurt your company's
                        brand or any illegal activity.
                    </li>
                </ol>

                <h2 className={h2Style}>5. Accuracy of materials</h2>
                <p className={pStyle}>
                    The materials appearing on Zaplify's web application could include technical, typographical, or
                    photographic errors. Zaplify does not warrant that any of the materials on its web application are
                    accurate, complete or current. Zaplify may make changes to the materials contained on its web
                    application at any time without notice. However Zaplify does not make any commitment to update the
                    materials.
                </p>

                <h2 className={h2Style}>6. Links</h2>
                <p className={pStyle}>
                    Zaplify has not reviewed all of the sites linked to its web application and is not responsible for
                    the contents of any such linked site. The inclusion of any link does not imply endorsement by
                    Zaplify of the site. Use of any such linked web application is at the user's own risk.
                </p>

                <h2 className={h2Style}>7. Modifications</h2>
                <p className={pStyle}>
                    By using this web application you are agreeing to be bound by the then current version of these
                    Terms of Service. Zaplify may change the Terms of Service at any time, and while Zaplify will make
                    efforts to notify you of such changes, you are responsible for reviewing the Terms of Service for
                    latest updates.
                </p>

                <h2 className={h2Style}>8. Data ownership</h2>
                <p className={pStyle}>
                    To use the service, users must provide some personal information, which is stored securely in
                    accordance with the Data Processing Agreement standards. While Zaplify may use this data for
                    processing purposes, the user is the owner of this data, which will be discarded upon the
                    termination of a user's account. This also implies to other data that has been provided by the user
                    to the service. Zaplify retains ownership of non-personal information and meta-data from the
                    platform such as anonymized user-statistics even after the termination of this contract. Upon the
                    action of downloading, or exporting, permitted personal and non-personal data, the user takes
                    ownership of this data, becoming its controller and hence is responsible for its security, privacy
                    restrictions/compliance and correct use.
                </p>
                <p className={pStyle}>
                    The user grants Zaplify permission to use the company name and logo of the registered user's
                    company, on zaplify.com and in other Zaplify marketing materials. The customer has right to opt out
                    from such usage by providing a written opt-out notice to Zaplify.
                </p>
                <p className={pStyle}>
                    Zaplify also processes, collects, and generates non-user data in the service. All data generated and
                    used within the service remain under the ownership of Zaplify. Unless the user exports or copies
                    service-generated data, data remains under Zaplify's control, and hence it is Zaplify's obligation
                    to ensure its security and its accordance with data-protection-laws.
                </p>

                <h2 className={h2Style}>9. Governing Law</h2>
                <p className={pStyle}>
                    These terms and conditions are governed by and construed in accordance with the laws of Stockholm,
                    Sweden and you irrevocably submit to the exclusive jurisdiction of the courts in that State or
                    location.
                </p>
            </div>
        </div>
    );
}

TermsOfService20230709.displayName = 'TermsOfService20230709';
