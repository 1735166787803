import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@shadcn/ui/components/ui/button';
import { AndSendIcon } from '@shadcn/ui/components/icons/and-send/andsend-icon';
import { AndSendLogotype } from '../../components/icons/and-send/andsend-logo';
import { SidebarTrigger } from '@shadcn/ui/components/ui/sidebar';
import { Search } from 'lucide-react';
import { useGlobalSearchDialog } from './dialogs/global-search-dialog';

export const MobileHeader: React.FC = () => {
    const navigate = useNavigate();
    const { openGlobalSearch } = useGlobalSearchDialog();

    return (
        <header className="md:hidden sticky top-0 w-full min-w-full h-14 bg-background-primary-inverted text-text-primary-inverted border-b backdrop-blur flex items-center justify-center px-4">
            <SidebarTrigger className="absolute left-6 w-4 h-4" />
            <div className="flex items-center justify-center" onClick={() => navigate('/')}>
                <AndSendLogotype variation="transparent_white" height={'22px'} />
            </div>
            <Search className="absolute right-6 w-4 h-4" onClick={() => openGlobalSearch()} />
        </header>
    );
};

export default MobileHeader;
