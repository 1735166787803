import { campaignActionTypes } from '../actionTypes';

const initialState = {
    campaigns: [],
    statistics: null,
    sources: [],
    isLoadingData: true,
};

const campaignReducer = (state = initialState, action) => {
    const campaigns = state.campaigns;
    switch (action.type) {
        case campaignActionTypes.UPDATE_CAMPAIGNS:
            return {
                ...state,
                campaigns: action.campaigns.filter((c) => !c.isArchived),
            };
        case campaignActionTypes.UPDATE_STATISTICS:
            return {
                ...state,
                statistics: action.statistics,
            };
        case campaignActionTypes.UPDATE_CAMPAIGN:
            const indexToUpdate = campaigns.findIndex((c) => c.id === action.campaign.id);
            let newCampaigns = [];
            if (indexToUpdate > -1) {
                newCampaigns = [...campaigns];
                newCampaigns[indexToUpdate] = action.campaign;
            }
            return {
                ...state,
                campaigns: newCampaigns.filter((c) => !c.isArchived),
            };
        case campaignActionTypes.UPDATE_CAMPAIGN_NAME:
            return {
                ...state,
                campaigns: campaigns
                    .map((c) => {
                        if (c.id === action.payload.id) {
                            return {
                                ...c,
                                name: action.payload.name,
                            };
                        }
                        return c;
                    })
                    .filter((c) => !c.isArchived),
            };
        case campaignActionTypes.ADD_SOURCE:
            const tempSources = [...state.sources];
            tempSources.push({
                campaignId: action.campaignId,
                source: action.source,
            });
            return {
                ...state,
                sources: tempSources,
            };
        case campaignActionTypes.UPDATE_SOURCES:
            return {
                ...state,
                sources: action.sources,
            };
        case campaignActionTypes.ADD_CAMPAIGN:
            return {
                ...state,
                campaigns: [...state.campaigns, action.campaign],
            };
        case campaignActionTypes.IS_LOADING_DATA:
            return {
                ...state,
                isLoadingData: action.payload,
            };
        default:
            return { ...state };
    }
};

export default campaignReducer;
