import { ReactNode, useEffect } from 'react';
import { useSeatsAndCredits } from '../../new/hooks/use-seat-and-credits';
import { useAppSelector } from '../../redux/store/configureStore';

export const SeatsAndCreditsProvider = ({ children }: { children?: ReactNode }) => {
    const { waitForSeats } = useSeatsAndCredits();
    const userId = useAppSelector((state) => state.user?.zaplifyUser?.id);

    // Refetch seat when userid updates
    useEffect(() => {
        if (userId) {
            waitForSeats().catch(console.error);
        }
    }, [userId]);

    return <>{children}</>;
};
