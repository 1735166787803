import { useSubscription, useQuery } from '@apollo/client';
import {
    GET_CONTACT_SUGGESTIONS,
    GET_CONTACT_SUGGESTIONS_BY_GROUP_ID,
    GET_CONTACT_SUGGESTIONS_COUNT_SUBSCRIPTION,
    GetContactSuggestionByIdQuery,
} from '@zaplify/graphql';
import React, { createContext, useContext, useState } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { paths } from '../../../../../routes/paths';
import { useSdk } from '../../../../sdk/use-sdk';
import { useQueryClient, useQuery as useQueryTan } from '@tanstack/react-query';
import { ContactSuggestionReason } from '@zaplify/campaigns';
import { ProspectDataExperienceDto } from '@zaplify/prospects';
import { useWebExtension } from '../../../../hooks/use-web-extension';
import { AccountDto } from '@zaplify/services/accounts/shared';

type ContactProfileData = {
    fullName: string;
    occupationTitle: string;
    location: string;
    linkedinProfileUrl: string;
    linkedinNumberOfConnections: number;
    linkedinHeadline: string;
    linkedinDescription: string;
    experiences: ProspectDataExperienceDto[];
    organizationName: string;
    profilePictureUrl: string;
};

type ContactSuggestionsType = GetContactSuggestionByIdQuery['ContactSuggestions'][0];

type SuggestionsFeedContextType = {
    currentContactSuggestionId: string;
    currentContactSuggestion: ContactSuggestionsType;
    currentContactSuggestionProfileData: ContactProfileData;
    currentAccountId: string;
    currentAccountData: AccountDto;
    contactSuggestions: ContactSuggestionsType[];
    contactSuggestionIndex: number;
    goToPreviousSuggestion: () => void;
    goToNextSuggestion: () => void;
    setContactSuggestionIndex: (value: React.SetStateAction<number>) => void;
    isFetchingCurrentLinkedinProfile: boolean;
    isFetchingCurrentAccountData: boolean;
    suggestionsMatchingSelectedPlaybook: ContactSuggestionsType[];
};

const SuggestionsFeedContext = createContext<SuggestionsFeedContextType | undefined>(undefined);

export function SuggestionsFeedProvider({ children, groupId }: { children: React.ReactNode; groupId?: string }) {
    const [searchParams] = useSearchParams();
    const { getLinkedInProfileFull } = useWebExtension();
    const {
        account: { getAccountById },
    } = useSdk();

    const insideNetworkParam = searchParams.get('insideNetwork');
    const currentContactSuggestionId = useParams().contactSuggestionId;
    const navigate = useNavigate();
    const [nextSuggestion, setNextSuggestion] = useState<ContactSuggestionsType | null>(null);
    const [prevSuggestion, setPrevSuggestion] = useState<ContactSuggestionsType | null>(null);
    const reactQueryClient = useQueryClient();

    const { data: contactSuggestionsCount } = useSubscription(GET_CONTACT_SUGGESTIONS_COUNT_SUBSCRIPTION);

    const { data: contactSuggestionsByGroupId, refetch: refetchContactSuggestionsByGroupId } = useQuery(
        GET_CONTACT_SUGGESTIONS_BY_GROUP_ID,
        {
            variables: { groupId: groupId },
            skip: !groupId,
        }
    );
    const { data: allContactSuggestions, refetch: refetchAllContactSuggestions } = useQuery(GET_CONTACT_SUGGESTIONS, {
        skip: !!groupId,
    });

    React.useEffect(() => {
        if (nextSuggestion?.accountId) {
            reactQueryClient.prefetchQuery(getAccountById(nextSuggestion.accountId));
        }
        if (prevSuggestion?.accountId) {
            reactQueryClient.prefetchQuery(getAccountById(prevSuggestion.accountId));
        }
    }, [nextSuggestion?.accountId, prevSuggestion?.accountId, getAccountById, reactQueryClient]);

    React.useEffect(() => {
        if (contactSuggestionsCount) {
            refetchAllContactSuggestions();
            groupId && refetchContactSuggestionsByGroupId();
        }
    }, [contactSuggestionsCount]);

    const suggestionsMatchingSelectedPlaybook = React.useMemo(() => {
        return (
            (groupId ? contactSuggestionsByGroupId?.ContactSuggestions : allContactSuggestions?.ContactSuggestions) ??
            []
        );
    }, [groupId, contactSuggestionsByGroupId, allContactSuggestions]);

    const contactSuggestions = React.useMemo(() => {
        return suggestionsMatchingSelectedPlaybook.filter((suggestion) => {
            if (insideNetworkParam === 'true') {
                return suggestion.reason !== ContactSuggestionReason.SIMILAR_TO_OTHER_ACCOUNTS.toString();
            } else if (insideNetworkParam === 'false') {
                return suggestion.reason === ContactSuggestionReason.SIMILAR_TO_OTHER_ACCOUNTS.toString();
            }
            return true;
        });
    }, [groupId, contactSuggestionsByGroupId, allContactSuggestions, insideNetworkParam]);

    const currentContactSuggestion = React.useMemo(() => {
        return contactSuggestions?.find((suggestion) => suggestion.id === currentContactSuggestionId);
    }, [currentContactSuggestionId, contactSuggestions]);

    const { data: linkedinProfileResult, isFetching: isFetchingCurrentLinkedinProfile } = useQueryTan({
        queryKey: ['linkedinProfile', currentContactSuggestion?.contactLinkedinUrl],
        queryFn: () => getLinkedInProfileFull({ url: currentContactSuggestion?.contactLinkedinUrl }),
        staleTime: 60_000 * 10,
        enabled: !!currentContactSuggestion?.contactLinkedinUrl,
    });

    const currentContactSuggestionProfileData = React.useMemo(() => {
        const linkedinProfile = linkedinProfileResult?.success ? linkedinProfileResult.prospectData : null;
        return {
            fullName: linkedinProfile?.fullName,
            occupationTitle: linkedinProfile?.occupationTitle,
            location: linkedinProfile?.location,
            linkedinProfileUrl: linkedinProfile?.linkedinProfileUrl,
            linkedinNumberOfConnections: linkedinProfile?.linkedinNumberOfConnections,
            linkedinHeadline: linkedinProfile?.linkedinHeadline,
            linkedinDescription: linkedinProfile?.linkedinDescription,
            experiences: linkedinProfile?.experiences,
            organizationName: linkedinProfile?.organizationName,
            profilePictureUrl: linkedinProfile?.linkedinProfileImgUrl,
        };
    }, [linkedinProfileResult]);

    React.useEffect(() => {
        if (
            !contactSuggestions?.map((suggestion) => suggestion.id).includes(currentContactSuggestionId) &&
            contactSuggestions?.length > 0
        ) {
            const firstSuggestionId = contactSuggestions[0].id;
            navigate({
                pathname: `./${firstSuggestionId}`,
                search: searchParams.toString(),
            });
        } else if (contactSuggestions && contactSuggestions.length === 0) {
            navigate({
                pathname: `${paths.NEW.DISCOVER_PATHS.SUGGESTIONS}`,
                search: searchParams.toString(),
            });
        }
    }, [currentContactSuggestionId, contactSuggestions, searchParams.toString()]);

    const currentAccountId = currentContactSuggestion?.accountId;

    const { data: currentAccountData, isFetching: isFetchingCurrentAccountData } = useQueryTan(
        getAccountById(currentAccountId)
    );

    const goToPreviousSuggestion = () => {
        if (!contactSuggestions || contactSuggestions.length === 0) return;
        const currentIndex = contactSuggestions.findIndex((suggestion) => suggestion.id === currentContactSuggestionId);
        const previousIndex = (currentIndex - 1 + contactSuggestions.length) % contactSuggestions.length;
        const previousSuggestionId = contactSuggestions[previousIndex].id;
        navigate({
            pathname: `${paths.NEW.DISCOVER_PATHS.SUGGESTIONS}/${previousSuggestionId}`,
            search: searchParams.toString(),
        });
    };

    const goToNextSuggestion = () => {
        if (!contactSuggestions || contactSuggestions.length === 0) return;
        const currentIndex = contactSuggestions.findIndex((suggestion) => suggestion.id === currentContactSuggestionId);
        const nextIndex = (currentIndex + 1) % contactSuggestions.length;
        const nextSuggestionId = contactSuggestions[nextIndex].id;
        navigate({
            pathname: `${paths.NEW.DISCOVER_PATHS.SUGGESTIONS}/${nextSuggestionId}`,
            search: searchParams.toString(),
        });
    };

    const [contactSuggestionIndex, setContactSuggestionIndex] = React.useState(0);

    React.useEffect(() => {
        const index = contactSuggestions?.findIndex((suggestion) => suggestion.id === currentContactSuggestionId) || 0;
        setContactSuggestionIndex(index);
    }, [currentContactSuggestionId, contactSuggestions]);

    React.useEffect(() => {
        if (!contactSuggestions || contactSuggestions.length === 0) return;
        const currentIndex = contactSuggestions.findIndex((suggestion) => suggestion.id === currentContactSuggestionId);
        const previousIndex = (currentIndex - 1 + contactSuggestions.length) % contactSuggestions.length;
        const nextIndex = (currentIndex + 1) % contactSuggestions.length;
        setPrevSuggestion(contactSuggestions[previousIndex]);
        setNextSuggestion(contactSuggestions[nextIndex]);
    }, [currentContactSuggestionId, contactSuggestions]);

    return (
        <SuggestionsFeedContext.Provider
            value={{
                currentContactSuggestionId,
                currentContactSuggestion,
                currentContactSuggestionProfileData,
                currentAccountId,
                currentAccountData,
                contactSuggestions,
                contactSuggestionIndex,
                setContactSuggestionIndex,
                goToPreviousSuggestion,
                goToNextSuggestion,
                isFetchingCurrentLinkedinProfile,
                isFetchingCurrentAccountData,
                suggestionsMatchingSelectedPlaybook,
            }}
        >
            {children}
        </SuggestionsFeedContext.Provider>
    );
}

export function useSuggestionsFeed() {
    const context = useContext(SuggestionsFeedContext);
    if (context === undefined) {
        throw new Error('useSuggestionsFeed must be used within a SuggestionsFeedProvider');
    }
    return context;
}
