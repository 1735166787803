import React from 'react';
import { useInView } from 'react-intersection-observer';

interface LazyLoadProps {
    children: any;
    minHeight?: number;
    options?: any;
}

export const LazyLoad = ({ options, minHeight, children }: LazyLoadProps) => {
    const { ref, inView } = useInView({ triggerOnce: false, ...options });
    return (
        <div ref={ref} style={{ minHeight: minHeight || 100 }}>
            {inView && children}
        </div>
    );
};
