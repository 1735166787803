import { forwardRef, useEffect, useState, useRef, useMemo } from 'react';
import { Card } from '@shadcn/ui/components/ui/card';
import { motion } from 'motion/react';
import { cn } from '@shadcn/ui/lib/utils';
import { Progress } from '@shadcn/ui/components/ui/progress';
import { BookOpen, ChevronDown, Loader2 } from 'lucide-react';
import { useSdk } from '../../../../sdk';
import { useSuspenseQuery as useSuspenseQueryTan } from '@tanstack/react-query';
import { useSubscription, useSuspenseQuery } from '@apollo/client';
import { GET_GROUPS, GET_MESSAGES_SENT_FROM_APP_SUBSCRIPTION } from '@zaplify/graphql';
import dayjs from 'dayjs';
import { useAuth } from '../../../../providers/authentication-provider';
import { Button } from '@shadcn/ui/components/ui/button';
import { useActions } from '../../../../hooks/use-actions';
import { Popover, PopoverContent, PopoverTrigger } from '@shadcn/ui/components/ui/popover';
import { Badge } from '@shadcn/ui/components/ui/badge';
import { ActionCard } from '../../../actions/components/action-card';
import { paths } from '../../../../../routes/paths';
import { Separator } from '@shadcn/ui/components/ui/separator';
import { TrophyAnimation } from '../../../../components/animations/trophy-animation';
import { Skeleton } from '@shadcn/ui/components/ui/skeleton';
import { Link } from 'react-router-dom';

interface AgentCardHeaderProps {
    isDailyGoalReached?: boolean;
}

interface DynamicProgressBarProps {
    isAgentFullyTrained: boolean;
    progressValue: number;
    actionsCompletedToday: number;
    dailyGoalProgressPercentage: number;
    isDailyGoalReached: boolean;
}

interface AnimationConfig {
    duration: number;
    springTension: number;
    springDamping: number;
    thresholdPixels: number;
    transitionDurationMs: number;
}

// Constants
const ANIMATION_CONFIG: AnimationConfig = {
    duration: 250,
    springTension: 0.05,
    springDamping: 0.1,
    thresholdPixels: 3,
    transitionDurationMs: 250,
};

const DAILY_GOAL = 10;

// Helper components
const AgentCardHeader = ({ isDailyGoalReached }: AgentCardHeaderProps) => (
    <div className="space-y-2 px-2">
        <div className="flex items-center justify-between">
            <div className="flex items-center gap-2 relative">
                {isDailyGoalReached ? (
                    <motion.div className="w-5 h-5 flex items-center justify-center relative">
                        {/* Subtle pulsating ring for completed state */}
                        <motion.div
                            className="absolute inset-0 rounded-full bg-indigo-100"
                            animate={{
                                scale: [1, 1.2, 1],
                                opacity: [0, 0.2, 0],
                            }}
                            transition={{
                                duration: 4,
                                repeat: Infinity,
                                repeatDelay: 8,
                            }}
                        />
                        {/* Inner dot that stays visible */}
                        <motion.div className="absolute rounded-full bg-indigo-500/20 w-[35%] h-[35%] top-[32.5%] left-[32.5%]" />
                        <motion.div
                            className="absolute rounded-full bg-indigo-500 w-[20%] h-[20%] top-[40%] left-[40%]"
                            animate={{
                                opacity: [0.7, 1, 0.7],
                            }}
                            transition={{
                                duration: 2,
                                repeat: Infinity,
                                repeatType: 'reverse',
                            }}
                        />
                    </motion.div>
                ) : (
                    <motion.div className="w-5 h-5 flex items-center -ml-3 justify-center relative">
                        {/* Outer scanning wave that happens occasionally */}
                        <motion.div
                            className="absolute inset-0 rounded-full bg-indigo-300/60"
                            initial={{ scale: 0.8, opacity: 0 }}
                            animate={{
                                scale: [0.8, 1.8],
                                opacity: [0, 0.5, 0],
                            }}
                            transition={{
                                duration: 1.5,
                                times: [0, 0.4, 1],
                                repeat: Infinity,
                                repeatDelay: 7,
                                ease: 'easeOut',
                            }}
                        />

                        {/* Secondary scanning pulse with different timing */}
                        <motion.div
                            className="absolute inset-0 rounded-full bg-indigo-200/50"
                            initial={{ scale: 0.85, opacity: 0 }}
                            animate={{
                                scale: [0.85, 1.5],
                                opacity: [0, 0.5, 0],
                            }}
                            transition={{
                                duration: 1.2,
                                times: [0, 0.5, 1],
                                repeat: Infinity,
                                repeatDelay: 7,
                                delay: 0.3, // slight delay after first wave
                                ease: 'easeOut',
                            }}
                        />

                        {/* Subtle ambient glow that's always present */}
                        <motion.div
                            className="absolute inset-0 rounded-full bg-indigo-100/10"
                            animate={{
                                scale: [0.95, 1.1, 0.95],
                                opacity: [0.05, 0.15, 0.05],
                            }}
                            transition={{
                                duration: 5,
                                repeat: Infinity,
                                repeatType: 'mirror',
                                ease: 'easeInOut',
                            }}
                        />

                        {/* Central dot with subtle activity */}
                        <motion.div
                            className="absolute rounded-full bg-indigo-500 w-[40%] h-[40%] top-[30%] left-[30%]"
                            animate={{
                                opacity: [0.6, 0.8, 0.9, 0.7, 0.6],
                                scale: [0.9, 1, 1.05, 0.95, 0.9],
                            }}
                            transition={{
                                duration: 8,
                                times: [0, 0.2, 0.5, 0.7, 1],
                                repeat: Infinity,
                                ease: 'easeInOut',
                            }}
                        />
                    </motion.div>
                )}
                <span className={cn('font-medium', 'text-text-brand-primary')}>Your agent</span>
                {isDailyGoalReached && (
                    <motion.div
                        initial={{ opacity: 0, y: -5 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        <Badge variant="outline" className="bg-indigo-50 text-indigo-600 border-indigo-200">
                            Daily Goal Completed
                        </Badge>
                    </motion.div>
                )}
            </div>
        </div>
    </div>
);

const DynamicProgressBar = ({
    isAgentFullyTrained,
    progressValue,
    actionsCompletedToday,
    dailyGoalProgressPercentage,
    isDailyGoalReached,
}: DynamicProgressBarProps) => {
    // If agent is fully trained, show daily goal progress
    if (isAgentFullyTrained) {
        return (
            <>
                <div className="flex items-center justify-between">
                    <motion.p
                        className={cn(
                            'text-sm',
                            isDailyGoalReached ? 'text-indigo-700 font-medium' : 'text-muted-foreground'
                        )}
                        animate={
                            isDailyGoalReached
                                ? {
                                      opacity: [0.9, 1, 0.9],
                                  }
                                : {}
                        }
                        transition={
                            isDailyGoalReached
                                ? {
                                      duration: 4,
                                      repeat: Infinity,
                                      repeatType: 'reverse',
                                  }
                                : {}
                        }
                    >
                        {isDailyGoalReached
                            ? 'Congratulations! You reached your daily goal 🎉'
                            : `Complete ${
                                  DAILY_GOAL - actionsCompletedToday
                              } more actions today to reach your daily goal.`}
                    </motion.p>
                </div>
                <motion.div
                    className="relative w-full"
                    animate={
                        isDailyGoalReached
                            ? {
                                  opacity: [1, 1],
                              }
                            : {}
                    }
                >
                    <Progress
                        value={Math.ceil(dailyGoalProgressPercentage * 100)}
                        className={cn(
                            'h-2',
                            isDailyGoalReached
                                ? '[&>div]:bg-gradient-to-r [&>div]:from-indigo-300 [&>div]:to-indigo-500 [&>div]:shadow-[0_0_10px_rgba(99,102,241,0.4)]'
                                : '[&>div]:bg-brand-gradient-2'
                        )}
                    />
                    {isDailyGoalReached && (
                        <motion.div
                            className="absolute inset-0"
                            initial={{ opacity: 0 }}
                            animate={{
                                opacity: [0, 0.4, 0],
                                scale: [1, 1.02, 1],
                            }}
                            transition={{
                                duration: 3.5,
                                repeat: Infinity,
                                repeatDelay: 7,
                                ease: 'easeInOut',
                            }}
                        >
                            <div className="h-2 w-full rounded-full overflow-hidden">
                                <div className="h-full w-full bg-gradient-to-r from-indigo-200 to-indigo-400 opacity-40"></div>
                            </div>
                        </motion.div>
                    )}
                </motion.div>
                {isDailyGoalReached && (
                    <motion.p
                        className="text-xs text-indigo-600 mt-1 italic"
                        initial={{ opacity: 0, y: 5 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.3 }}
                    >
                        Consistency strengthens connections and helps your agent learn what works best for you.
                    </motion.p>
                )}
            </>
        );
    }

    return (
        <>
            <p className="text-sm text-muted-foreground">
                {`Train your agent to get better actions by sending more messages and engaging with more contacts.`}
            </p>
            <div className="relative w-full">
                <Progress value={progressValue * 100} className="h-2 [&>div]:bg-brand-gradient-2" />
            </div>
        </>
    );
};

interface AgentTrainingCardProps {}

export const AgentTrainingCard = forwardRef<HTMLDivElement, AgentTrainingCardProps>(({}, ref) => {
    const {
        linkedinProfiles: { getLinkedinConnections },
    } = useSdk();
    const { prospectIdsWithCompletedActions, getCompletedActions } = useActions();
    const completedActionsCount = prospectIdsWithCompletedActions.reduce((acc, prospectId) => {
        return acc + getCompletedActions(prospectId).length;
    }, 0);
    const { data: connections } = useSuspenseQueryTan(getLinkedinConnections());

    // Fetch messages data
    const { data: messagesSentFromApp, loading: messagesSentFromAppLoading } = useSubscription(
        GET_MESSAGES_SENT_FROM_APP_SUBSCRIPTION
    );

    // Get today's start and end timestamps for subscription
    const { startOfDay, endOfDay } = useMemo(() => {
        const today = dayjs();
        return {
            startOfDay: today.startOf('day').toISOString(),
            endOfDay: today.endOf('day').toISOString(),
        };
    }, []);

    const { data: groupsData } = useSuspenseQuery(GET_GROUPS);

    // Get user data
    const {
        authState: { user },
    } = useAuth();

    // State
    const [isHovering, setIsHovering] = useState(false);
    const [hasReachedGoal, setHasReachedGoal] = useState(true);
    const [goalAnimationComplete, setGoalAnimationComplete] = useState(false);
    const [showTrophyAnimation, setShowTrophyAnimation] = useState(false);
    const [previousCompletedActionsCount, setPreviousCompletedActionsCount] = useState(0);

    // Compute progress for daily goal
    const dailyGoalProgress = Math.min(completedActionsCount / DAILY_GOAL, 1);
    const isDailyGoalReached = dailyGoalProgress >= 1;

    // to test the trophy animation
    // useEffect(() => {
    //     setInterval(() => {
    //         console.log('showTrophyAnimation useInterval', showTrophyAnimation);
    //         showTrophyAnimation ? setShowTrophyAnimation(false) : setShowTrophyAnimation(true);
    //     }, 6_000);
    // }, [setShowTrophyAnimation]);

    // Track when exactly 10 messages are reached
    useEffect(() => {
        if (previousCompletedActionsCount === 9 && completedActionsCount === 10) {
            setShowTrophyAnimation(true);

            // Hide the animation after 3 seconds
            const timer = setTimeout(() => {
                setShowTrophyAnimation(false);
            }, 3000);

            return () => clearTimeout(timer);
        }

        setPreviousCompletedActionsCount(completedActionsCount);
    }, [completedActionsCount, previousCompletedActionsCount]);

    // Track when goal is reached to trigger animations
    useEffect(() => {
        if (isDailyGoalReached && !hasReachedGoal) {
            setHasReachedGoal(true);

            // Set a timeout to mark animation completion after the celebration
            const timer = setTimeout(() => {
                setGoalAnimationComplete(true);
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [isDailyGoalReached, hasReachedGoal]);

    // Calculate training progress
    const totalConnections = connections?.length || 0;
    const totalMessages = messagesSentFromApp?.Messages_aggregate.aggregate?.count || 0;

    const isNewUser = dayjs(user?.createdAt).isAfter(dayjs().subtract(1, 'week'));
    const progressConnections = isNewUser ? Math.min(totalConnections / 800, 1) : 1;
    const progressMessages = Math.min(totalMessages / 20, 1);
    const progressValue = Math.min((progressConnections + progressMessages) / 2, 1);

    const isAgentFullyTrained = progressValue >= 1;

    if (messagesSentFromAppLoading) {
        return <Skeleton className="w-full min-h-24 rounded-xl" />;
    }

    return (
        <div className={cn('w-full transition-all')}>
            <div
                className={cn(
                    'w-full z-50 relative flex justify-center',
                    'transform-origin-center-right transition-all duration-250 opacity-100 pointer-events-auto'
                )}
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
            >
                <Card
                    ref={ref}
                    className={cn(
                        'w-full group z-10 flex flex-col p-4 gap-2 shadow mx-auto',
                        'bg-background-brand-section-subtle border-border-brand',
                        'transition-all duration-250 ease-out',
                        {
                            'border-indigo-200 bg-indigo-50/20': isAgentFullyTrained && isDailyGoalReached,
                            'shadow-[0_0_15px_rgba(79,70,229,0.1)]':
                                isHovering || (isAgentFullyTrained && isDailyGoalReached),
                        }
                    )}
                >
                    {hasReachedGoal && !goalAnimationComplete && <GoalReachedAnimationOverlay />}
                    {showTrophyAnimation && (
                        <div className="absolute inset-0 z-20 flex items-center justify-center pointer-events-none">
                            <div className="flex flex-col items-center justify-center">
                                <TrophyAnimation loop={false} className="w-20 h-20" />
                                <p className="text-md font-bold text-text-primary">You reached your daily goal!</p>
                            </div>
                        </div>
                    )}

                    {groupsData?.Groups.length === 1 && groupsData?.Groups[0].name === 'My first playbook' ? (
                        // CTA to setup the first playbook a playbook
                        <motion.div
                            className="flex flex-col w-full gap-1"
                            animate={{
                                opacity: showTrophyAnimation ? 0.5 : 1,
                            }}
                            transition={{
                                duration: 0.5,
                                ease: 'easeInOut',
                            }}
                        >
                            <AgentCardHeader isDailyGoalReached={false} />
                            <div className="flex justify-between items-center gap-4">
                                <p className="text-sm">
                                    Align your agent's behavior with your goals by setting your preferences in a
                                    playbook.
                                </p>
                                <Link
                                    to={`/new/playbooks/${groupsData?.Groups[0].id}?step=1`}
                                    onClick={() => {
                                        window?.analytics?.track?.(
                                            'User clicked customize playbook in action feed top card'
                                        );
                                    }}
                                >
                                    <Button variant="outline" size="sm">
                                        <BookOpen className="w-4 h-4" />
                                        <span className="text-sm">Customize playbook</span>
                                    </Button>
                                </Link>
                            </div>
                        </motion.div>
                    ) : (
                        // Agent training card
                        <motion.div
                            className="flex flex-col w-full gap-1"
                            animate={{
                                opacity: showTrophyAnimation ? 0.5 : 1,
                            }}
                            transition={{
                                duration: 0.5,
                                ease: 'easeInOut',
                            }}
                        >
                            <AgentCardHeader isDailyGoalReached={isAgentFullyTrained && isDailyGoalReached} />

                            <DynamicProgressBar
                                isAgentFullyTrained={isAgentFullyTrained}
                                progressValue={progressValue}
                                actionsCompletedToday={completedActionsCount}
                                dailyGoalProgressPercentage={dailyGoalProgress}
                                isDailyGoalReached={isDailyGoalReached}
                            />
                        </motion.div>
                    )}
                </Card>
            </div>
        </div>
    );
});

const GoalReachedAnimationOverlay = () => (
    <motion.div
        className="absolute inset-0 pointer-events-none z-10 rounded-md overflow-hidden"
        initial={{ opacity: 0 }}
        animate={{ opacity: [0, 0.3, 0] }}
        transition={{
            duration: 3,
            times: [0, 0.5, 1],
            ease: 'easeOut',
        }}
    >
        <div className="absolute inset-0 bg-indigo-50 mix-blend-overlay"></div>
        <div className="absolute inset-0">
            {Array.from({ length: 15 }).map((_, i) => (
                <motion.div
                    key={i}
                    className={
                        'absolute w-1 h-10 bg-gradient-to-b from-indigo-100/0 via-indigo-400/50 to-indigo-100/0 opacity-70'
                    }
                    style={{
                        left: `${Math.random() * 100}%`,
                        top: `${Math.random() * 100}%`,
                        rotate: `${Math.random() * 45 - 22.5}deg`,
                        scale: Math.random() * 0.6 + 0.4,
                    }}
                    initial={{ y: -30, opacity: 0 }}
                    animate={{
                        y: 30,
                        opacity: [0, 0.7, 0],
                    }}
                    transition={{
                        duration: 1.6 + Math.random() * 0.8,
                        delay: Math.random() * 1.2,
                        ease: 'easeOut',
                    }}
                />
            ))}
        </div>
    </motion.div>
);
