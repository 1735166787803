import { configureStore as configureToolkitStore } from '@reduxjs/toolkit';
import { getZaplifySdk } from '@zaplify/sdk';
import LogRocket from 'logrocket';
import { TypedUseSelectorHook, useSelector } from 'react-redux-latest';
import { apiEndpoint } from '@zaplify/sdk';
import socketMiddleware from '../middleware/socketMiddleware';
import rootReducer from '../reducers';

let store;

export default function configureStore(initialState: any) {
    const services = {
        zaplifySdk: getZaplifySdk(apiEndpoint),
    };
    const extraMiddleWare = getMiddlewares(initialState);

    store = configureToolkitStore({
        reducer: rootReducer,
        preloadedState: initialState,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                thunk: {
                    extraArgument: services,
                },
                serializableCheck: {
                    ignoredActionPaths: ['firebaseUser', 'auth', 'socketClient'], // replace the left to my path
                    ignoredPaths: ['socketClient', 'statistics.dateFrom', 'statistics.dateTo'],
                },
                immutableCheck: {
                    ignoredPaths: ['socketClient'],
                },
            }).concat(extraMiddleWare),
    });
    return store;
}

export type RootState = ReturnType<typeof rootReducer>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export type AppDispatch = typeof store.dispatch;

function getMiddlewares(initialState) {
    if (import.meta.env.MODE === 'production') {
        LogRocket.init('kdqs9m/appzaplify', { ingestServer: 'https://core5.andsend.com/la' });
        return [socketMiddleware(initialState.socketClient), LogRocket.reduxMiddleware()];
    } else {
        return [socketMiddleware(initialState.socketClient)];
    }
}
