import { AvailableFields as AvailableFieldsPipedrive } from '../../../../redux/ducks/pipedrive/slice';
import { AvailableFields as AvailableFieldsHubspot } from '../../../../redux/ducks/hubspot/slice';

export const disableUsedField = (
    fieldToExtend: AvailableFieldsPipedrive | AvailableFieldsHubspot,
    currentSelected,
    destination: string,
    sourceKey: string
): any => {
    const fieldUsed = currentSelected.find((field) => {
        if (field[destination] === fieldToExtend[sourceKey]) {
            return field;
        }
    });
    return { ...fieldToExtend, disabled: !!fieldUsed };
};

export const disableUsedFieldSalesforce = (
    fieldToExtend,
    currentSelected,
    destination: string,
    sourceKey: string
): any => {
    const fieldUsed = currentSelected.find((field) => {
        if (field.destination[destination] === fieldToExtend[sourceKey]) {
            return field;
        }
    });
    return { ...fieldToExtend, disabled: !!fieldUsed };
};
