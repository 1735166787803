import dayjs from 'dayjs';
import { useIsMobile } from '@shadcn/ui/hooks/use-mobile';
import { Suspense, useEffect, useMemo, useRef, useState } from 'react';
import { useMessages } from '../../../hooks/messaging/use-messages';
import { MessageComposerProvider } from '../../../hooks/messaging/use-message-composer';
import { MessageComposer } from '../../actions/components/message-composer/message-composer';
import { Message } from '../../actions/components/message/message';
import { cn } from '@shadcn/ui/lib/utils';
import { UserRoundIcon, ArrowRight, ChevronUp, Sparkles } from 'lucide-react';
import { paths } from '../../../../routes/paths';
import { useActionCardDetails } from '../hooks/use-action-card-details';
import { useNavigate } from 'react-router-dom';
import { Badge } from '@shadcn/ui/components/ui/badge';
import { useActions } from '../../../hooks/use-actions';
interface CardConversationProps {
    prospectId: string;
    isCompleted?: boolean;
    className?: string;
    preview?: boolean;
}

export const CardConversation = ({ prospectId, isCompleted, className, preview = false }: CardConversationProps) => {
    const { messages, linkedinMessagesLastSyncedAt } = useMessages({ prospectId });
    const isMobile = useIsMobile();
    const scrollAreaRef = useRef<HTMLDivElement>(null);
    const [numberOfDisplayedMessages, setNumberOfDisplayedMessages] = useState(20);
    const isInitialRender = useRef(true);
    const isOverflowing = useRef(false);

    const navigate = useNavigate();
    const { isOpen, openDetails, onClose } = useActionCardDetails();
    const { getAction } = useActions();

    const scheduledAction = getAction(prospectId);
    const goal = scheduledAction?.goal || 'Initiate a conversation';

    useEffect(() => {
        if (preview) {
            setNumberOfDisplayedMessages(20);
        }
    }, [preview]);

    const scrollToBottom = () => {
        if (scrollAreaRef.current) {
            scrollAreaRef.current.scrollBy({ top: 1000000, behavior: 'instant' });
        }
    };

    useEffect(() => {
        if (scrollAreaRef.current && messages.length > 0 && !preview) {
            if (scrollAreaRef.current) {
                scrollToBottom();
                setTimeout(
                    () => {
                        scrollToBottom();
                    },
                    preview ? 320 : 50
                );
            }
        }
    }, [messages, preview]);

    useEffect(() => {
        if (scrollAreaRef.current) {
            isOverflowing.current = scrollAreaRef.current.scrollHeight > scrollAreaRef.current.clientHeight;
        }
    }, [scrollAreaRef.current]);

    const uniqueMessages = useMemo(() => {
        return messages.filter((message, index, self) => index === self.findIndex((t) => t.id === message.id));
    }, [messages]);

    const messagesInView = useMemo(() => {
        return uniqueMessages.slice(-numberOfDisplayedMessages);
    }, [uniqueMessages, numberOfDisplayedMessages]);

    const previewMessage = useMemo(() => {
        return uniqueMessages.filter((message) => message.__typename === 'Messages').slice(-1)[0];
    }, [uniqueMessages]);

    const previewMessageIsAvailable = !!previewMessage;

    // Add scroll handler
    const handleScroll = () => {
        if (!scrollAreaRef.current || isInitialRender.current) {
            isInitialRender.current = false;
            return;
        }

        const { scrollTop } = scrollAreaRef.current;
        if (scrollTop < 10) {
            setNumberOfDisplayedMessages((prev) => prev + 20);
        }
    };

    return (
        <div
            className={cn(
                'flex flex-col h-full relative border border-border-secondary rounded-lg',
                preview ? 'overflow-hidden' : 'overflow-auto', // Only allow scrolling when not in preview
                'transition-all duration-300 ease-in-out origin-top',
                preview && 'max-h-[130px]',
                className
            )}
            onClick={(e) => {
                !preview && e.stopPropagation();
            }}
        >
            <div
                className={cn(
                    'flex flex-col flex-1 gap-4 overflow-y-auto p-4',
                    !preview && 'min-h-48 overscroll-contain',
                    preview && 'overflow-hidden'
                )}
                ref={scrollAreaRef}
                onScroll={handleScroll}
            >
                {/* Top bar badges */}
                <div
                    className={cn(
                        'sticky top-0 right-0 z-10 flex gap-2 justify-end -translate-y-2',
                        preview && 'hidden'
                    )}
                >
                    <Badge
                        variant="outline"
                        className="flex gap-1 bg-background-primary cursor-pointer py-1 shadow"
                        onClick={() => {
                            if (isOpen) {
                                onClose();
                            } else {
                                openDetails();
                            }
                        }}
                    >
                        <UserRoundIcon className="w-4 h-4 stroke-1" />
                        <span>{isOpen ? 'Hide details' : 'Show details'}</span>
                    </Badge>
                    <Badge
                        variant="outline"
                        className="flex gap-1 bg-background-primary cursor-pointer py-1 shadow"
                        onClick={() => {
                            navigate(`${paths.NEW.MESSAGES}/${prospectId}`);
                        }}
                    >
                        <span>Go to conversation</span>
                        <ArrowRight className="w-4 h-4 stroke-1" />
                    </Badge>
                </div>
                <div className={cn('flex flex-1', preview && 'hidden')} />

                {/* Messages */}
                {(!preview && uniqueMessages.length > 0) || (preview && previewMessageIsAvailable) ? (
                    preview ? (
                        <div key={`preview-${previewMessage.id}`}>
                            <Message {...previewMessage} className="[&_::part(content-wrapper)]:line-clamp-2" />
                        </div>
                    ) : (
                        messagesInView.map((message) => (
                            <div
                                key={`full-${message.id}`}
                                style={{
                                    backfaceVisibility: 'hidden',
                                }}
                            >
                                <Message {...message} />
                            </div>
                        ))
                    )
                ) : (
                    <div className="flex flex-col justify-center items-center h-full">
                        <span className="text-sm text-text-quaternary">No previous chats</span>
                    </div>
                )}
                {isOverflowing.current && preview && (
                    <div className="absolute bottom-0 left-0 right-0 h-[35px] bg-gradient-to-b from-background-primary/80 to-background-secondary blur" />
                )}
            </div>
            {linkedinMessagesLastSyncedAt && isMobile && (
                <div className="flex justify-center flex-col items-center w-full text-sm text-text-tertiary">
                    <span>
                        LinkedIn messages last synced at{' '}
                        {dayjs(linkedinMessagesLastSyncedAt).format('YYYY-MM-DD HH:mm')}
                    </span>
                    <span className="text-xs">Sync not supported on mobile</span>
                </div>
            )}

            <Suspense fallback={<div>Loading...</div>}>
                <MessageComposerProvider prospectId={prospectId}>
                    <div
                        className={cn(
                            'flex sticky bottom-0',
                            'transition-[opacity,transform] duration-300 ease-in-out',
                            preview ? 'opacity-0 translate-y-4 pointer-events-none h-0' : 'opacity-100 translate-y-0'
                        )}
                    >
                        <MessageComposer
                            size="small"
                            bigOnFocus
                            className={cn(
                                '[&_.bottom-section]:py-2 [&_.editor-content]:pt-0',
                                'transition-transform duration-300 ease-in-out'
                            )}
                        />
                    </div>
                </MessageComposerProvider>
            </Suspense>
        </div>
    );
};
