import { IEvent, Topics } from '@zaplify/common';

export class UserAcceptedInvitationEvent extends IEvent {
    public static override readonly TOPIC = Topics.USERS;

    constructor(readonly userId: string, readonly invitationId: string, readonly organizationId: string) {
        super();
    }

    get partitionKey(): string {
        return this.organizationId;
    }
}
