import PropTypes from 'prop-types';
import Joyride, { ACTIONS } from 'react-joyride';
import { Tooltip } from './elements';

export const Guide = (props) => {
    const { run, steps, showSkipButton = false, disableOverlay = true, zIndex = 1500, onClose } = props;
    const handleJoyrideCallback = (data) => {
        const { action } = data;
        if (action === ACTIONS.CLOSE) onClose && onClose();
    };
    return (
        <Joyride
            {...props}
            callback={handleJoyrideCallback}
            showSkipButton={showSkipButton}
            disableOverlay={disableOverlay}
            run={run}
            disableScrolling={true}
            tooltipComponent={Tooltip}
            scrollOffset={0}
            steps={steps}
            styles={{
                options: {
                    arrowColor: '#2B2D36',
                    zIndex: zIndex,
                    beaconSize: 20,
                },
            }}
            locale={{ close: 'Got it' }}
        />
    );
};

Guide.propTypes = {
    run: PropTypes.bool.isRequired,
    steps: PropTypes.array.isRequired,
    showSkipButton: PropTypes.bool,
    disableOverlay: PropTypes.bool,
    zIndex: PropTypes.number,
    shouldCloseOnOverlayClick: PropTypes.bool,
    onClose: PropTypes.func,
};
