export interface SearchHistoryEntry {
    searchTerm: string;
    timestamp: number;
}

export type SearchHistory = SearchHistoryEntry[];

const createSearchHistoryEntry = (searchValue: string) => {
    return {
        searchTerm: searchValue,
        timestamp: new Date().valueOf(),
    };
};

const getOrCreateSearchHistory = () => {
    let searchHistoryString = localStorage.getItem('searchHistory');
    if (!searchHistoryString) {
        searchHistoryString = '[]';
    }
    return JSON.parse(searchHistoryString) as SearchHistory;
};

const addSearchHistoryEntry = (searchHistory: SearchHistory, searchValue: string) => {
    const searchTermHistorySet = new Set(searchHistory.map((search) => search.searchTerm.toLowerCase()));
    if (searchTermHistorySet.has(searchValue.toLowerCase())) {
        searchHistory = searchHistory.filter((search) => search.searchTerm.toLowerCase() !== searchValue.toLowerCase());
    }
    searchHistory = [createSearchHistoryEntry(searchValue), ...searchHistory].slice(0, 11);
    return searchHistory;
};

const saveSearchHistory = (searchHistory: SearchHistory) => {
    localStorage.setItem('searchHistory', JSON.stringify(searchHistory));
};

const updateSearchHistory = (searchValue: string) => {
    if (searchValue.length === 0) return;
    const searchHistory = getOrCreateSearchHistory();
    const newSearchHistory = addSearchHistoryEntry(searchHistory, searchValue);
    saveSearchHistory(newSearchHistory);
};

export const useSearchHistory = () => {
    return {
        updateSearchHistory,
        getSearchHistory: () => getOrCreateSearchHistory(),
    };
};
