import { GroupDto } from '@zaplify/services/user-contacts/shared';
import { getZaplifySdk } from '@zaplify/sdk';
import { atomWithReset } from 'jotai/utils';
import { useAtomValue } from 'jotai';
import { playbookImages } from '../../../hooks/playbooks/playbook-images';
import { useLocation, useNavigate } from 'react-router-dom';
import { paths } from '../../../routes/paths';
import { useApolloClient } from '@apollo/client';
import { GET_GROUPS, GET_PLAYBOOK_BY_GROUP_ID, GET_PLAYBOOKS } from '@zaplify/graphql';
import { useAssistantSettingsSubscription } from './use-assistant-settings-subscription';

export const groupsLoadingAtom = atomWithReset<boolean>(false);
export const groupsStateAtom = atomWithReset<GroupDto[]>([]);

export const useGroups = () => {
    const groups = useAtomValue(groupsStateAtom);
    const loading = useAtomValue(groupsLoadingAtom);
    const groupsSdk = getZaplifySdk().profiles.groups;
    const navigate = useNavigate();
    const location = useLocation();
    const apolloQueryClient = useApolloClient();
    const { loading: assistantSettingsLoading, allAssistantSettings } = useAssistantSettingsSubscription();

    const renameGroup = async (groupId: string, newName: string) => {
        return await groupsSdk.updateGroup(groupId, { name: newName });
    };

    const refetchPlaybooks = async () => {
        await apolloQueryClient.refetchQueries({
            include: [GET_PLAYBOOKS, GET_GROUPS, GET_PLAYBOOK_BY_GROUP_ID],
        });
    };

    const createGroup = async (input: { name: string; imgSrc?: string }) => {
        if (!input.imgSrc) {
            input.imgSrc = getUnusedImgSrc();
        }

        const createdGroup = await groupsSdk.createGroup({ name: input.name, imgSrc: input.imgSrc });
        await refetchPlaybooks();
        return createdGroup;
    };

    const removeGroup = async (groupId: string) => {
        await groupsSdk.deleteGroup(groupId);
        await refetchPlaybooks();
    };

    const updateGroup = async (
        groupId: string,
        updatedGroupPayload: Partial<
            Omit<GroupDto, 'id' | 'createdAt' | 'updatedAt' | 'userId' | 'assistantSettingsId'>
        >
    ) => {
        const updatedGroup = await groupsSdk.updateGroup(groupId, updatedGroupPayload);
        await refetchPlaybooks();
        return updatedGroup;
    };

    const setDefaultGroup = async (groupId: string) => {
        return await groupsSdk.updateGroup(groupId, { default: true });
    };

    const cloneGroup = async (group: GroupDto) => {
        const newGroup = { ...group, name: `${group.name} (copy)` };
        delete newGroup.id;
        const settings = allAssistantSettings[group.assistantSettingsId];
        const clone = await groupsSdk.createGroupWithAssistantSettings({
            group: newGroup,
            assistantSettings: settings,
        });
        await refetchPlaybooks();
        return clone;
    };

    const getUnusedImgSrc = () => {
        const availableImages = playbookImages
            .map((image) => image)
            .filter((src) => !groups.some((group) => group.imgSrc === src));

        if (availableImages.length > 0) {
            return availableImages[0];
        } else {
            // loop over all images after index
            return playbookImages[groups.length % playbookImages.length];
        }
    };

    const getStatistics = async (input: { groupIds?: string[]; sinceTimestamp?: number }) => {
        return await groupsSdk.getStatistics(input);
    };

    const startCreateGroupWithContactFlow = (userContactId: string) => {
        navigate(paths.PLAYBOOKS + `?createNew=true&createWithContact=${userContactId}`, {
            state: { backLink: location.pathname },
        });
    };

    return {
        groups,
        loading,
        renameGroup,
        createGroup,
        removeGroup,
        updateGroup,
        setDefaultGroup,
        cloneGroup,
        getStatistics,
        startCreateGroupWithContactFlow,
    };
};
