import { IQuery } from '@zaplify/common';
import { Topics } from '@zaplify/common';
import { IOrganizationUserStats } from '../users/organization-user-stats.dto';

export class GetOrganizationUserStatsQuery extends IQuery<IOrganizationUserStats> {
    public static override readonly TOPIC = Topics.USERS;
    constructor(readonly organizationId: string) {
        super();
    }
}
