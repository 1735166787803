import {
    GlobalSearchCampaignsResponseDto,
    GlobalSearchProspectsResponseDto,
    GlobalSearchAccountsResponseDto,
    GlobalSearchCompaniesResponseDto,
    GlobalSearchPeopleResponseDto,
    GlobalSearchContactsResponseDto,
    GlobalSearchLinkedinProspectsResponseDto,
} from '@zaplify/prospects';
import { atomWithReset } from 'jotai/utils';

// Create atoms for each piece of state
export const campaignsDataAtom = atomWithReset<GlobalSearchCampaignsResponseDto>(null);
campaignsDataAtom.debugLabel = 'campaignsDataAtom';
export const campaignsLoadingAtom = atomWithReset<boolean>(false);

export const companyNamesDataAtom = atomWithReset<GlobalSearchAccountsResponseDto>(null);
export const companyNamesLoadingAtom = atomWithReset<boolean>(false);

export const companiesDataAtom = atomWithReset<GlobalSearchCompaniesResponseDto>(null);
export const companiesLoadingAtom = atomWithReset<boolean>(false);

export const accountsDataAtom = atomWithReset<GlobalSearchAccountsResponseDto>(null);
export const accountsLoadingAtom = atomWithReset<boolean>(false);

export const peopleDataAtom = atomWithReset<GlobalSearchPeopleResponseDto>(null);
export const peopleLoadingAtom = atomWithReset<boolean>(false);

export const prospectsDataAtom = atomWithReset<GlobalSearchProspectsResponseDto>(null);
export const prospectsLoadingAtom = atomWithReset<boolean>(false);

export const LinkedinPeopleAtom = atomWithReset<GlobalSearchLinkedinProspectsResponseDto>(null);
export const LinkedinPeopleLoadingAtom = atomWithReset<boolean>(false);

export const contactsDataAtom = atomWithReset<GlobalSearchContactsResponseDto>(null);
export const contactsLoadingAtom = atomWithReset<boolean>(false);

export enum SearchTypes {
    CAMPAIGNS = 'campaigns',
    COMPANIES = 'companies',
    COMPANY_NAMES = 'companyNames',
    PROSPECTS = 'prospects',
    PROSPECTS_FROM_LINKEDIN = 'prospectsFromLinkedin',
    ACCOUNTS = 'accounts',
    PEOPLE = 'people',
    CONTACTS = 'contacts',
}
