import { useSetAtom } from 'jotai';
import { displayOutOfCreditsModalAtom } from '../atoms/outOfCreditsModal';

export const useShowContactsLimitModal = () => {
    const setDisplayOutOfCreditsModal = useSetAtom(displayOutOfCreditsModalAtom);

    const showContactLimitModal = () => {
        setDisplayOutOfCreditsModal(true);
    };

    return { showContactLimitModal };
};
