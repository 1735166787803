const trackEvent = (event: string, attributes: Record<string, any> = {}) => {
    window.analytics && window?.analytics?.track(event, attributes);
};

export enum ResultType {
    Person = 'persons',
    RelevantAccounts = 'relevant accounts',
    MatchingAccounts = 'matching accounts',
    Campaigns = 'campaigns',
    Prospect = 'prospect',
    LinkedinProspect = 'linkedin prospect',
}

export enum CardClass {
    Person = 'person card',
    Account = 'account card',
}

export enum LinkClass {
    LinkedIn = 'linkedin',
    Website = 'website',
}

export enum SectionType {
    Description = 'description',
    Tags = 'tags',
}

const userOpenedGlobalSearch = () => {
    trackEvent('User Clicked Global Search');
};

const userMadeSearchInGlobalSearch = (searchTerm: string) => {
    trackEvent('User Made Search In Global Search', { searchTerm });
};

const userClickedGlobalSearchResult = (resultType: ResultType) => {
    trackEvent('User Clicked On Search Result In Global Search', { resultType });
};

const userAddedProspectInGlobalSearch = (numberOfPeople: number, cardClass: CardClass) => {
    trackEvent('User Added Person To Campaign In Global Search', { numberOfPeople, cardClass });
};

const userClickedExternalLinkInGlobalSearch = (cardClass: CardClass, linkClass: LinkClass) => {
    trackEvent('User Clicked On External Link In Global Search', { cardClass, linkClass });
};

const userClickedShowMorePeopleInGlobalSearch = (numberOfPeople: number) => {
    trackEvent('User Clicked On Show More People In Global Search', { numberOfPeople });
};

const userClickedShowMoreInformationInGlobalSearch = (sectionType: SectionType) => {
    trackEvent('User Clicked On Show More Information In Global Search', { sectionType });
};

export {
    userOpenedGlobalSearch,
    userMadeSearchInGlobalSearch,
    userClickedGlobalSearchResult,
    userAddedProspectInGlobalSearch,
    userClickedExternalLinkInGlobalSearch,
    userClickedShowMorePeopleInGlobalSearch,
    userClickedShowMoreInformationInGlobalSearch,
};
