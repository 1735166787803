import { UserContactDto } from '@zaplify/services/user-contacts/shared';
import { BaseSdk } from '../../base.sdk';

export class UserContactsSdk extends BaseSdk {
    async assignToGroup(userContactId: string | string[], groupId: string): Promise<UserContactDto[]> {
        if (Array.isArray(userContactId) && userContactId.length > 1) {
            return await this.patch<UserContactDto[]>(`${this.userContactsPath}/batch/group`, {
                contactIds: userContactId,
                groupId,
            });
        } else {
            let singleUserContactId = userContactId;
            if (Array.isArray(userContactId)) {
                singleUserContactId = userContactId[0];
            }
            const updatedUserContact = await this.patch<UserContactDto>(
                `${this.userContactsPath}/${singleUserContactId}/group`,
                { groupId },
            );
            return updatedUserContact ? [updatedUserContact] : [];
        }
    }

    async getByProspectId(prospectId: string): Promise<UserContactDto | null> {
        return await this.get<UserContactDto>(`${this.userContactsPath}/by-prospectId/${prospectId}`);
    }

    async getTotalCount(): Promise<{ count: number } | null> {
        return await this.get<{ count: number }>(`${this.userContactsPath}/count`);
    }

    async deleteMany(ids: string[]): Promise<void> {
        return await this.delete(`${this.userContactsPath}/batch?ids=[${ids.map((id) => `"${id}"`).join(',')}]`);
    }

    async activateMany(ids: string[]): Promise<void> {
        return;
    }

    async deactivateMany(ids: string[]): Promise<void> {
        return;
    }

    async getLimit() {
        return await this.get<number | 'none'>(`${this.userContactsPath}/limit`);
    }

    async getByLinkedinMemberId(linkedinMemberId: string): Promise<UserContactDto | null> {
        return await this.get<UserContactDto>(`${this.userContactsPath}/by-linkedin-member-id/${linkedinMemberId}`);
    }
}
