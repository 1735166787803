import { Separator } from '@shadcn/ui/components/ui/separator';
import { cn } from '@shadcn/ui/lib/utils';
import { Linkedin, Mail, SquarePen } from 'lucide-react';
import * as React from 'react';

type MessageFooterProps = {
    sentOn: string;
    channelType: 'LINKEDIN' | 'EMAIL' | 'NOTE';
    conversationId?: string;
};

export const MessageFooter = React.forwardRef<HTMLDivElement, MessageFooterProps & React.ComponentProps<'div'>>(
    ({ sentOn, channelType, conversationId, className, ...props }, ref) => {
        let icon;
        switch (channelType) {
            case 'LINKEDIN':
                icon = (
                    <>
                        <span>sent via:</span>
                        <Linkedin
                            size={16}
                            onClick={() =>
                                conversationId &&
                                window.open(`https://www.linkedin.com/messaging/thread/${conversationId}`, '_blank')
                            }
                            className={cn('hover:cursor-pointer', conversationId ? '' : 'hidden')}
                        />
                    </>
                );
                break;
            case 'EMAIL':
                icon = (
                    <>
                        <span>sent via:</span>
                        <Mail size={16} />
                    </>
                );
                break;
            case 'NOTE':
                icon = <span className="italic">Only visible to you</span>;
                break;
            default:
                icon = <span></span>;
        }
        return (
            <div className={cn('flex gap-1 items-center text-text-tertiary', className)}>
                <span className="text-xs">{sentOn}</span>
                <Separator className="h-4" orientation="vertical" decorative />
                <div className="text-xs flex gap-1 items-center">{icon}</div>
            </div>
        );
    }
);
MessageFooter.displayName = 'MessageFooter';
