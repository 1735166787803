import React, { useState } from 'react';
import PapaParse, { UnparseConfig } from 'papaparse';
import { exportToXLSX } from '../../../helpers/export-xlsx';
import { Menu, MenuItem } from '@mui/material';
import { useNotifications } from '../../../hooks/use-notifications';

declare global {
    interface Navigator {
        msSaveBlob?: (blob: any, defaultName?: string) => boolean;
    }
}

export const LINK_TYPE = 'link';
export const BUTTON_TYPE = 'button';

export interface FileDownloaderProps {
    children: React.ReactNode;
    data?: any;
    dataCallback?: any;
    filename: string;
    type?: 'link' | 'button';
    style?: any;
    className?: string;
    bom?: boolean;
    config?: UnparseConfig;
    defaultFileType?: 'csv' | 'xlsx';
}

const downloadCSV = async (
    data: any,
    dataCallback: any,
    filename: string,
    bom: boolean,
    config: UnparseConfig
): Promise<void> => {
    const bomCode = bom ? '\ufeff' : '';

    let csvContent: any = null;
    if (dataCallback) {
        const rawData = await dataCallback();
        csvContent = PapaParse.unparse(rawData, config);
    } else if (data && typeof data === 'object') {
        csvContent = PapaParse.unparse(data, config);
    } else {
        csvContent = data;
    }

    const csvData = new Blob([`${bomCode}${csvContent}`], {
        type: 'text/csv;charset=utf-8;sep=";"',
    });

    let csvURL: any = null;
    if (navigator.msSaveBlob) {
        csvURL = navigator.msSaveBlob(csvData, `${filename}.csv`);
    } else {
        csvURL = window.URL.createObjectURL(csvData);
    }

    const link = document.createElement('a');
    link.href = csvURL as string;
    link.setAttribute('download', `${filename}.csv`);
    link.click();
    link.remove();
};

const downloadExcel = async (
    data: any,
    dataCallback: any,
    filename: string,
    bom: boolean,
    config: UnparseConfig
): Promise<void> => {
    if (dataCallback) {
        const rawData = await dataCallback();
        exportToXLSX(rawData, filename);
    } else if (data && typeof data === 'object') {
        exportToXLSX(data, filename);
    } else {
        exportToXLSX(data, filename);
    }
};

const FileDownloader = ({
    children,
    data,
    dataCallback,
    filename,
    type = LINK_TYPE,
    className,
    style,
    bom = false,
    config = {},
    defaultFileType,
}: FileDownloaderProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { notify } = useNotifications();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        if (defaultFileType === 'csv') {
            handleCSVDownload();
            return;
        }
        if (defaultFileType === 'xlsx') {
            handleExcelDownload();
            return;
        }
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleExcelDownload = () => {
        handleClose();
        notify({
            message: 'Downloading Excel file...',
            type: 'info',
            duration: 5000,
        });
        downloadExcel(data, dataCallback, filename, bom, config);
    };

    const handleCSVDownload = () => {
        handleClose();
        notify({
            message: 'Downloading CSV file...',
            type: 'info',
            duration: 5000,
        });
        downloadCSV(data, dataCallback, filename, bom, config);
    };

    return (
        <>
            {type === LINK_TYPE ? (
                // eslint-disable-next-line
                <a onClick={handleClick} className={className} style={style}>
                    {children}
                </a>
            ) : (
                <button onClick={handleClick} className={className} style={style}>
                    {children}
                </button>
            )}
            <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem onClick={handleExcelDownload}>Microsoft Excel (.xlsx)</MenuItem>
                <MenuItem onClick={handleCSVDownload}>CSV (.csv)</MenuItem>
            </Menu>
        </>
    );
};

export default FileDownloader;
