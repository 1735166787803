import AddIcon from '@mui/icons-material/Add';
import { useDispatch } from 'react-redux-latest';
import { Button } from '../../../../components/atoms/button';
import { addImportProspectsField } from '../../../../redux/actions';
import { ImportProspectsStepViewContainer } from '../containers';
import { FieldMappingContainer } from './containers';
import { FieldMappingInfoHeader } from './elements';
import { FieldMappingComponent } from './fieldMappingComponent';

export const FieldMapping = () => {
    const dispatch = useDispatch();

    const addProspectField = () => dispatch(addImportProspectsField());

    return (
        <ImportProspectsStepViewContainer>
            <FieldMappingContainer>
                <FieldMappingInfoHeader />
                <FieldMappingComponent />
                <Button
                    borderRadius="100px"
                    variant="outlined"
                    height="40px"
                    startIcon={<AddIcon />}
                    text="Add field"
                    onClick={addProspectField}
                />
            </FieldMappingContainer>
        </ImportProspectsStepViewContainer>
    );
};

FieldMapping.propTypes = {};
