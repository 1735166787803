import { Sparkles } from 'lucide-react';
import { Card, CardContent } from '@shadcn/ui/components/ui/card';
import { useQuery, useSubscription } from '@apollo/client';
import { GET_CONTACT_SUGGESTIONS, GET_CONTACT_SUGGESTIONS_COUNT_SUBSCRIPTION } from '@zaplify/graphql';
import { FC, useEffect, useMemo } from 'react';
import { ContactSuggestion } from '../../../discover/suggestions/components/contact-suggestion-card/types';
import { ContactSuggestionReason } from '@zaplify/campaigns';
import { Avatar, AvatarFallback, AvatarImage } from '@shadcn/ui/components/ui/avatar';
import { getInitials } from '../../../../functions/get-initials';
import { ContactSource } from '@zaplify/services/user-contacts/shared';
import { AddContactButton } from '../../../../components/buttons/add-contact-button';

export default function ContactSuggestionsCard() {
    const { data: contactSuggestions, refetch } = useQuery(GET_CONTACT_SUGGESTIONS);
    const { data: contactSuggestionsCount } = useSubscription(GET_CONTACT_SUGGESTIONS_COUNT_SUBSCRIPTION);
    useEffect(() => {
        refetch();
    }, [contactSuggestionsCount, refetch]);

    const topSuggestion = useMemo(
        () =>
            contactSuggestions?.ContactSuggestions.filter(
                (s) => s.reason !== ContactSuggestionReason.SIMILAR_TO_OTHER_ACCOUNTS.toString()
            ).slice(0, 3),
        [contactSuggestions]
    );

    if (topSuggestion?.length === 0) {
        return null;
    }

    return (
        <Card className="overflow-hidden border-l-4 border-l-green-500">
            <CardContent className="p-0 gap-2">
                <div className="bg-green-50 px-4 py-2 flex items-center gap-2">
                    <Sparkles size={16} className="text-green-600" />
                    <span className="font-medium text-sm">Your Playbook needs contacts to manage</span>
                </div>
                <div className="p-4">
                    <div className="flex justify-start gap-2 items-center mb-4">
                        <h3 className="text-lg font-medium">Contacts suggested for your first playbook</h3>
                    </div>

                    <div className="space-y-3">
                        {topSuggestion?.map((suggestion) => (
                            <ContactSuggestionRow key={suggestion.id} suggestion={suggestion} />
                        ))}
                    </div>
                </div>
                <div className="bg-green-50/50 p-4 border-t border-green-100">
                    <div className="flex items-start gap-2">
                        <div className="mt-1">
                            <Sparkles size={16} className="text-green-600" />
                        </div>
                        <div>
                            <h4 className="font-medium text-sm mb-1">Why these suggestions?</h4>
                            <p className="text-sm text-gray-600">
                                Our agents analyze your network to find contacts that align with your playbook's goals.
                                Each suggestion is carefully selected based on industry, role, previous conversations
                                and potential for meaningful connection.
                            </p>
                        </div>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
}

const ContactSuggestionRow: FC<{ suggestion: ContactSuggestion }> = ({ suggestion }) => {
    return (
        <div className="flex items-center justify-between gap-2 p-2 py-3 rounded-lg hover:bg-gray-50 border border-gray-200">
            <div className="flex items-center gap-3">
                <Avatar className="h-10 w-10">
                    <AvatarImage src={suggestion.contactImgUrl} alt={suggestion.contactName} />
                    <AvatarFallback>{getInitials(suggestion.contactName)}</AvatarFallback>
                </Avatar>
                <div>
                    <div className="flex items-center gap-2">
                        <h4 className="font-medium text-sm">{suggestion.contactName}</h4>
                    </div>
                    <p className="text-sm text-gray-600">
                        {suggestion.contactTitle} at {suggestion.accountName}
                    </p>
                </div>
            </div>
            <AddContactButton
                linkedinUrl={suggestion.contactLinkedinUrl}
                source={ContactSource.Onboarding}
                variant="secondary"
            />
        </div>
    );
};
