import { Box, Typography } from '@mui/material';
import { companyNameCorrectlyCased } from '../../../enums/company-name';

const AuthFormHeader = ({ type }: { type: 'login' | 'register' }) => {
    console.log('AuthFormHeader type', type);
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '8px' }}>
            <Typography
                component="h1"
                sx={{
                    fontSize: '32px',
                    fontWeight: '600',
                    lineHeight: '40px',
                    textAlign: 'center',
                    textWrap: 'balance',
                }}
            >
                {`Welcome ${type === 'login' ? 'back 💫' : `to ${companyNameCorrectlyCased} 💫`}`}
            </Typography>
            <Typography sx={{ textAlign: 'center', textWrap: 'balance' }}>
                Personalized communication that sounds like you
            </Typography>
        </Box>
    );
};

export default AuthFormHeader;
