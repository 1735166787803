import React, { FC } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid } from 'recharts';
import * as RechartsPrimitive from 'recharts';
import {
    ChartContainer,
    ChartTooltip,
    ChartLegend,
    ChartLegendContent,
    useChart,
    getPayloadConfigFromPayload,
    ChartTooltipContent,
} from '@shadcn/ui/components/ui/chart';
import { cn } from '@shadcn/ui/lib/utils';
import { TooltipProps } from 'recharts';

interface BaseChartProps {
    data: {
        date: string;
        sameDay?: number;
        within2Days?: number;
        within3Days?: number;
        within7Days?: number;
        within30Days?: number;
    }[];
    visibleLines: Record<string, boolean>;
    chartConfig: Record<string, any>;
}

interface OMTMChartProps {
    data: {
        date: string;
        trailingTotal: number;
        trailingTotalCompleted: number;
        trailingTotalGoalsReached: number;
        trailingTotalSuggestions: number;
        raw: {
            date: string;
            trailingTotal: number;
            trailingTotalCompleted: number;
            trailingTotalGoalsReached: number;
            trailingTotalSuggestions: number;
        };
    }[];
    visibleLines: Record<string, boolean>;
    chartConfig: Record<string, any>;
}

export const BaseChart: FC<BaseChartProps> = ({ data, visibleLines, chartConfig }) => {
    return (
        <ChartContainer config={chartConfig} className="h-[300px] w-full">
            <LineChart data={data}>
                <CartesianGrid strokeDasharray="3 3" className="stroke-muted" />
                <ChartLegend content={<ChartLegendContent />} />
                <XAxis dataKey="date" tickLine={false} axisLine={false} className="text-muted-foreground" />
                <YAxis tickLine={false} axisLine={false} width={40} className="text-muted-foreground" />
                <ChartTooltip content={<ChartTooltipContent />} />
                {Object.keys(chartConfig).map(
                    (key) =>
                        visibleLines[key] && (
                            <Line
                                key={key}
                                type="linear"
                                dataKey={key}
                                strokeWidth={2}
                                dot={true}
                                activeDot={{ r: 4, strokeWidth: 0 }}
                                stroke={chartConfig[key].theme.light}
                            />
                        )
                )}
            </LineChart>
        </ChartContainer>
    );
};

export const OMTMChart: FC<OMTMChartProps> = ({ data, visibleLines, chartConfig }) => {
    return (
        <ChartContainer config={chartConfig} className="h-[300px] w-full">
            <LineChart data={data}>
                <CartesianGrid strokeDasharray="3 3" className="stroke-muted" />
                <ChartLegend content={<ChartLegendContent />} />
                <XAxis dataKey="date" tickLine={false} axisLine={false} className="text-muted-foreground" />
                <YAxis tickLine={false} axisLine={false} width={40} className="text-muted-foreground" />
                <ChartTooltip content={<OMTMChartTooltipContent />} />
                {Object.keys(chartConfig).map(
                    (key) =>
                        visibleLines[key] && (
                            <Line
                                key={key}
                                type="linear"
                                dataKey={key}
                                strokeWidth={2}
                                dot={true}
                                activeDot={{ r: 4, strokeWidth: 0 }}
                                stroke={chartConfig[key].theme.light}
                            />
                        )
                )}
            </LineChart>
        </ChartContainer>
    );
};

const OMTMChartTooltipContent = React.forwardRef<
    HTMLDivElement,
    React.ComponentProps<typeof RechartsPrimitive.Tooltip> &
        React.ComponentProps<'div'> & {
            hideLabel?: boolean;
            hideIndicator?: boolean;
            indicator?: 'line' | 'dot' | 'dashed';
            nameKey?: string;
            labelKey?: string;
        }
>(
    (
        {
            active,
            payload,
            className,
            indicator = 'dot',
            hideLabel = false,
            hideIndicator = false,
            label,
            labelFormatter,
            labelClassName,
            formatter,
            color,
            nameKey,
            labelKey,
        },
        ref
    ) => {
        const { config } = useChart();

        const tooltipLabel = React.useMemo(() => {
            if (hideLabel || !payload?.length) {
                return null;
            }

            const [item] = payload;
            const key = `${labelKey || item.dataKey || item.name || 'value'}`;
            const itemConfig = getPayloadConfigFromPayload(config, item, key);
            const value =
                !labelKey && typeof label === 'string'
                    ? config[label as keyof typeof config]?.label || label
                    : itemConfig?.label;

            if (labelFormatter) {
                return <div className={cn('font-medium', labelClassName)}>{labelFormatter(value, payload)}</div>;
            }

            if (!value) {
                return null;
            }

            return <div className={cn('font-medium', labelClassName)}>{value}</div>;
        }, [label, labelFormatter, payload, hideLabel, labelClassName, config, labelKey]);

        if (!active || !payload?.length) {
            return null;
        }

        const nestLabel = payload.length === 1 && indicator !== 'dot';

        return (
            <div
                ref={ref}
                className={cn(
                    'grid min-w-[8rem] items-start gap-1.5 rounded-lg border border-border/50 bg-background px-2.5 py-1.5 text-xs shadow-xl',
                    className
                )}
            >
                {!nestLabel ? tooltipLabel : null}
                <div className="grid gap-1.5">
                    {payload.map((item, index) => {
                        const key = `${nameKey || item.name || item.dataKey || 'value'}`;
                        const itemConfig = getPayloadConfigFromPayload(config, item, key);
                        const indicatorColor = color || item.payload.fill || item.color;

                        return (
                            <div
                                key={item.dataKey}
                                className={cn(
                                    'flex w-full flex-wrap items-stretch gap-2 [&>svg]:h-2.5 [&>svg]:w-2.5 [&>svg]:text-muted-foreground',
                                    indicator === 'dot' && 'items-center'
                                )}
                            >
                                {formatter && item?.value !== undefined && item.name ? (
                                    formatter(item.value, item.name, item, index, item.payload)
                                ) : (
                                    <>
                                        {itemConfig?.icon ? (
                                            <itemConfig.icon />
                                        ) : (
                                            !hideIndicator && (
                                                <div
                                                    className={cn(
                                                        'shrink-0 rounded-[2px] border-[--color-border] bg-[--color-bg]',
                                                        {
                                                            'h-2.5 w-2.5': indicator === 'dot',
                                                            'w-1': indicator === 'line',
                                                            'w-0 border-[1.5px] border-dashed bg-transparent':
                                                                indicator === 'dashed',
                                                            'my-0.5': nestLabel && indicator === 'dashed',
                                                        }
                                                    )}
                                                    style={
                                                        {
                                                            '--color-bg': indicatorColor,
                                                            '--color-border': indicatorColor,
                                                        } as React.CSSProperties
                                                    }
                                                />
                                            )
                                        )}
                                        <div
                                            className={cn(
                                                'flex flex-1 justify-between leading-none',
                                                nestLabel ? 'items-end' : 'items-center'
                                            )}
                                        >
                                            <div className="grid gap-1.5">
                                                {nestLabel ? tooltipLabel : null}
                                                <div className="text-muted-foreground">
                                                    {itemConfig?.label || item.name}:{' '}
                                                    {item.value && (
                                                        <span className="font-mono font-medium tabular-nums text-foreground">
                                                            {item.value.toLocaleString()}
                                                        </span>
                                                    )}
                                                </div>
                                                <div className="text-xs text-muted-foreground">
                                                    Actions: {item.payload.raw.trailingTotalCompleted}
                                                    <span
                                                        className={cn(
                                                            'text-xs',
                                                            'ml-1',
                                                            item.payload.trailingTotalCompletedChange > 0
                                                                ? 'text-green-500'
                                                                : 'text-red-500'
                                                        )}
                                                    >
                                                        ({item.payload.trailingTotalCompletedChange > 0 ? '+' : ''}
                                                        {item.payload.trailingTotalCompletedChange.toLocaleString()})
                                                    </span>
                                                    <br />
                                                    Goals: {item.payload.raw.trailingTotalGoalsReached}
                                                    <span
                                                        className={cn(
                                                            'text-xs',
                                                            'ml-1',
                                                            item.payload.trailingTotalGoalsReachedChange > 0
                                                                ? 'text-green-500'
                                                                : 'text-red-500'
                                                        )}
                                                    >
                                                        ({item.payload.trailingTotalGoalsReachedChange > 0 ? '+' : ''}
                                                        {item.payload.trailingTotalGoalsReachedChange.toLocaleString()})
                                                    </span>
                                                    <br />
                                                    Suggestions: {item.payload.raw.trailingTotalSuggestions}
                                                    <span
                                                        className={cn(
                                                            'text-xs',
                                                            'ml-1',
                                                            item.payload.trailingTotalSuggestionsChange > 0
                                                                ? 'text-green-500'
                                                                : 'text-red-500'
                                                        )}
                                                    >
                                                        ({item.payload.trailingTotalSuggestionsChange > 0 ? '+' : ''}
                                                        {item.payload.trailingTotalSuggestionsChange.toLocaleString()})
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
);
OMTMChartTooltipContent.displayName = 'ChartTooltip';
