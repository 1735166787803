import { Box, Chip, TextField, Typography } from '@mui/material';
import { ArrowRight } from 'lucide-react';
import { ActionButton } from '../../onboarding/connect-linkedin-step-action';
import { SectionTitle } from './section-title';
import { PlaybookTemplate, Template, usePlaybookTemplates } from '../../playbooks/manage/hooks/use-playbook-templates';
import { useState } from 'react';

interface ISelectPurposeStepProps {
    setPlaybook: (playbook: Partial<PlaybookTemplate>) => void;
    onContinue: () => void;
}

export const SelectPurposeStep = ({ onContinue, setPlaybook }: ISelectPurposeStepProps) => {
    const [selectedOther, setSelectedOther] = useState(false);
    const [purpose, setPurpose] = useState('');
    const { getTemplate } = usePlaybookTemplates();

    const completeStep = () => {
        if (['sales', 'investors', 'network'].includes(purpose)) {
            const playbook = getTemplate(purpose as Template);
            setPlaybook({
                name: playbook.name,
                tone: playbook.tone,
                purpose: playbook.purpose,
                context: playbook.context,
                targetGroup: playbook.targetGroup,
            });
        } else {
            setPlaybook({
                name: purpose,
                tone: 'Casual',
                purpose: purpose,
            });
        }

        onContinue();
    };

    return (
        <Box
            sx={{
                maxWidth: '800px',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                flexDirection: 'column',
            }}
        >
            <SectionTitle
                title={'What would you like to use Andsend for?'}
                subtitle="It will help us to provide you the best experience"
            />
            <Box
                sx={{
                    display: 'flex',
                    paddingY: 'var(--M-m-regular, 24px)',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: 'var(--M-m-compact, 16px)',
                    alignSelf: 'stretch',
                    borderRadius: 'var(--radius-none, 0px)',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        gap: '12px',
                    }}
                >
                    <SelectChip
                        label="Start sales conversations  💬"
                        onClick={() => {
                            setSelectedOther(false);
                            setPurpose('sales');
                        }}
                        selected={purpose === 'sales'}
                    />
                    <SelectChip
                        label="Get in touch with investors 🤝"
                        onClick={() => {
                            setSelectedOther(false);
                            setPurpose('investors');
                        }}
                        selected={purpose === 'investors'}
                    />
                    <SelectChip
                        label="Nurture my network 🌳"
                        onClick={() => {
                            setSelectedOther(false);
                            setPurpose('network');
                        }}
                        selected={purpose === 'network'}
                    />
                    <SelectChip label={'Other'} onClick={() => setSelectedOther(true)} selected={selectedOther} />
                </Box>
                {selectedOther && (
                    <TextField
                        fullWidth
                        placeholder="What specifically would you like to do?"
                        value={purpose}
                        onChange={(e) => setPurpose(e.target.value)}
                        sx={{
                            background: 'white',
                            borderRadius: '8px',
                            '& fieldset': {
                                borderRadius: '8px',
                                border: '1px solid var(--grey-palette-grey-3, #E4E6EB)',
                            },
                        }}
                    />
                )}
            </Box>
            {!!purpose && (
                <ActionButton onClick={completeStep}>
                    Continue <ArrowRight size={15} style={{ marginLeft: '8px', color: 'inherit' }} />
                </ActionButton>
            )}
        </Box>
    );
};

const SelectChip = ({ label, onClick, selected = false }) => (
    <Chip
        sx={{
            display: 'flex',
            height: '56px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '8px',
            borderRadius: '8px',
            border: '1px solid',
            boxShadow: selected ? '0px 0px 0px 4px #9E77ED3D' : '0px 1px 2px 0px #1018280F',
            borderColor: selected ? '#585AD0' : '#E4E6EB',
            opacity: '0px',
            padding: '16px 24px 16px 24px',
            background: 'var(--grey-palette-white, #FFF)',
        }}
        onClick={onClick}
        label={
            <Typography
                sx={{
                    fontSize: '16px',
                    fontWeight: 500,
                    lineHeight: '24px',
                    textAlign: 'center',
                }}
            >
                {label}
            </Typography>
        }
    />
);
