import { useEffect, useRef } from 'react';

import { useLocation } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface Props {
    children: React.ReactNode;
}

export const FreshVersionProvider: React.FC<Props> = ({ children }) => {
    const isOutdatedVersionRef = useRef<boolean>(false);
    const { pathname } = useLocation();
    const { refreshNewFrontendOnNavigate } = useFlags();

    const shouldSkipRefreshOnNavigation = (path: string): boolean => {
        // Exclude refreshing when navigating between individual chats and todos (chats/[id] or todos/[id])
        return path.includes('/actions/') || path.includes('/action-feed/');
    };

    useEffect(() => {
        if (isOutdatedVersionRef.current && refreshNewFrontendOnNavigate && !shouldSkipRefreshOnNavigation(pathname)) {
            isOutdatedVersionRef.current = false;
            window.location.reload();
        }
    }, [pathname, refreshNewFrontendOnNavigate]);

    useEffect(() => {
        let interval: NodeJS.Timeout;
        if (navigator.serviceWorker) {
            const handleServiceWorkerMessage = (event: MessageEvent) => {
                if (event.data.type === 'NEW_VERSION_AVAILABLE') {
                    console.log('Service Worker: New version available', event.data);
                    if (!isOutdatedVersionRef.current) {
                        isOutdatedVersionRef.current = true;
                    }
                } else if (event.data.type === 'KEEP_ALIVE_RESPONSE') {
                    if (import.meta.env.VITE_SHOW_SERVICE_WORKER_LOGS !== 'false') {
                        console.log('Service Worker: Received keep-alive response', event.data);
                    }
                }
            };

            navigator.serviceWorker.addEventListener('message', handleServiceWorkerMessage);
            interval = setInterval(() => {
                navigator.serviceWorker.controller?.postMessage({ type: 'KEEP_ALIVE_REQUEST' });
            }, 5000);

            return () => {
                navigator.serviceWorker.removeEventListener('message', handleServiceWorkerMessage);
                if (interval) clearInterval(interval);
                console.log('Service Worker: Clearing keep-alive interval');
            };
        }
    }, []);

    return <>{children}</>;
};
