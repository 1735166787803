import { LinkedinConversation, ProspectDataWithConnectionStatus, getZaplifySdk } from '@zaplify/sdk';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSeatsAndCredits } from '../../../new/hooks/use-seat-and-credits';
import { ContactSource } from '@zaplify/services/user-contacts/shared';
import { useProspectSync } from '../../../hooks/use-prospect-sync';
import { ProspectDataDto, ProspectDto } from '@zaplify/prospects';
import { channelAccountsAtom } from '../../../atoms/chats';
import { useAtom } from 'jotai';
import { chunkArray } from '@zaplify/utils';
import { useWebExtension } from '../../../new/hooks/use-web-extension';
function log(message: string) {
    console.log(`🚀 [useLiConversationsImport] | ${message}`);
}

function logError(message: string) {
    console.error(`🚀 [useLiConversationsImport] | ${message}`);
}

const useLiConversationsImport = () => {
    const {
        getConversations,
        getLinkedInProfile,
        getLinkedInProfileFull,
        getMessagesFromConversation: getMessagesFromConversationFromExtension,
    } = useWebExtension();
    const [channelAccounts] = useAtom(channelAccountsAtom);
    const { importProspectDataFromExtension, linkedinImportMultipleOwners } = useFlags();
    const { refetch: refetchCredits } = useSeatsAndCredits();
    const { syncMany } = useProspectSync();

    const getMessagesFromConversation = async (conversationId: string, numberOfMessages: number) => {
        const messages = await getMessagesFromConversationFromExtension({
            memberId: channelAccounts.linkedIn.value,
            conversationId: conversationId,
            deliveredAtBeforeTimestamp: Date.now(),
            countBefore: numberOfMessages,
            cacheStaleTime: 60_000,
        });
        return messages;
    };

    const getLiConversations = async (
        nextCursor?: string,
        keyword?: string,
        minPageSize: number = 10
    ): Promise<{ conversations: LinkedinConversation[]; nextCursor: string }> => {
        if (!channelAccounts.linkedIn.value) throw new Error('LinkedIn channel account not found');

        const res = await getConversations({
            memberId: channelAccounts.linkedIn.value,
            moreThan: minPageSize,
            nextCursor: nextCursor,
            keywords: keyword,
            cacheStaleTime: 60_000,
        });
        const newNextCursor = res.oldestConversationLastEventTime;
        const conversations = res.conversations.sort((a, b) => b.lastMessage.createdAt - a.lastMessage.createdAt);

        const uniqueMemberIds = new Set<string>();
        conversations.forEach((c) => uniqueMemberIds.add(c.prospect.memberId));
        const uniqueConversations = Array.from(uniqueMemberIds).map((memberId) =>
            conversations.find((c) => c.prospect.memberId === memberId)
        );
        log(
            `getLiConversations | got conversations: ${conversations?.length}, uniqueConversations: ${uniqueConversations?.length}, uniqueMemberIds: ${uniqueMemberIds?.size}`
        );
        return { conversations: uniqueConversations, nextCursor: newNextCursor };
    };

    const fetchProfiles = async (memberIds: string[]): Promise<ProspectDataWithConnectionStatus[]> => {
        const uniqueMemberIds = new Set<string>(memberIds);
        const prospectData: ProspectDataWithConnectionStatus[] = [];
        // Work in batches of 10 to avoid rate limiting
        const batchSize = 10;

        const batches = chunkArray(Array.from(uniqueMemberIds), batchSize);
        for (const batch of batches) {
            await Promise.all(
                batch.map((memberId, index) =>
                    // Add a delay to "look" like normal behavior
                    new Promise((resolve) => setTimeout(resolve, index * 200)).then(async () => {
                        try {
                            const profileResult = await getLinkedInProfileFull({
                                url: `https://linkedin.com/in/${memberId}`,
                            });

                            if (!profileResult.success) {
                                console.error('Error fetching full LinkedIn profile', profileResult);
                                return;
                            }

                            prospectData.push({
                                ...profileResult.prospectData,
                                connectionStatus: profileResult.connectionStatus,
                            });
                        } catch (error) {
                            console.error('Error fetching LinkedIn profile', error);
                        }
                    })
                )
            );
        }
        return prospectData;
    };

    const purchaseLinkedinProfiles = async (
        prospectData: ProspectDataDto[],
        groupId?: string,
        source?: ContactSource
    ): Promise<ProspectDto[]> => {
        try {
            const prospects =
                (await getZaplifySdk().profiles.sources.runBulkImport(
                    prospectData,
                    source || ContactSource.LinkedinImport,
                    groupId,
                    { includeAssigned: linkedinImportMultipleOwners ? true : false }
                )) || [];

            if (!prospects?.length) {
                const validationRes = await getZaplifySdk().profiles.sources.validateBulkImport({
                    prospectsData: prospectData,
                });
                console.log('Was unable to import prospect, validation response:', validationRes);
            } else {
                syncMany(prospects.map((p) => ({ prospectId: p.id })));
            }

            refetchCredits();
            return prospects;
        } catch (error) {
            console.error('Error purchasing LinkedIn profiles', error);
            throw error;
        }
    };

    const purchaseFromLinkedinMemberIds = async (memberIds: string[], campaignId?: string): Promise<string[]> => {
        const prospectIdsPurchased: string[] = [];
        const uniqueMemberIds = new Set<string>(memberIds);
        if (importProspectDataFromExtension) {
            const prospectData = await fetchProfiles(memberIds);
            const prospects = await purchaseLinkedinProfiles(prospectData, campaignId);
            return prospects.map((p) => p.id);
        } else {
            for (const memberId of uniqueMemberIds) {
                try {
                    let prospectIds: string[] = [];
                    const profile = await getLinkedInProfile('https://linkedin.com/in/' + memberId);
                    console.log('🚀 ~~~ getLiConversations | getting profile from pdl:', profile.profileUrl);
                    prospectIds = await getZaplifySdk().profiles.sources.purchasePersons(
                        { personIds: [], linkedinUrls: [profile.profileUrl] },
                        ContactSource.LinkedinImport,
                        campaignId
                    );

                    if (prospectIds?.length) {
                        prospectIdsPurchased.push(...prospectIds);
                        console.log('🚀 ~~~ getLiConversations | purchased prospect:', memberId, prospectIds);
                    }
                } catch (error) {
                    console.log('Error importing recent LI conversations', error);
                }
            }
        }

        return prospectIdsPurchased;
    };

    const addProspectToCampaignFromLinkedinProfileUrl = async (profileUrl: string, campaignId: string) => {
        const prospectIdsPurchased: string[] = [];
        try {
            const profile = await getLinkedInProfile(profileUrl);

            console.log('🚀 ~~~ getLiConversations | got profile:', profile.profileUrl);

            let prospectIds: string[] = [];
            if (importProspectDataFromExtension) {
                const result = await getLinkedInProfileFull({
                    publicIdentifier: profile.publicIdentifier,
                });
                if (!result.success) {
                    console.log('Error getting profile full', result);
                    return;
                }

                const prospects = await getZaplifySdk().profiles.sources.runBulkImport(
                    [result.prospectData],
                    ContactSource.LinkedinImport,
                    campaignId,
                    { includeAssigned: true }
                );
                prospectIds = prospects.map((p) => p.id);

                if (!prospectIds?.length) {
                    const validationRes = await getZaplifySdk().profiles.sources.validateBulkImport({
                        prospectsData: [result.prospectData],
                    });
                    console.log('Was unable to import prospect, validation response:', validationRes);
                } else {
                    syncMany(prospectIds.map((id) => ({ prospectId: id })));
                }
            } else {
                prospectIds = await getZaplifySdk().profiles.sources.purchasePersons(
                    { personIds: [], linkedinUrls: [profile.profileUrl] },
                    ContactSource.LinkedinImport,
                    campaignId
                );
            }

            if (prospectIds?.length) {
                prospectIdsPurchased.push(...prospectIds);
                console.log('🚀 ~~~ getLiConversations | purchased prospect:', profile.profileUrl, prospectIds);
            }
        } catch (error) {
            console.log('Error importing recent LI conversations', error);
        }

        return prospectIdsPurchased;
    };

    return {
        getMessagesFromConversation,
        getLiConversations,
        fetchProfiles,
        purchaseLinkedinProfiles,
        purchaseFromLinkedin: purchaseFromLinkedinMemberIds,
        addProspectToCampaignFromLinkedinProfileUrl,
    };
};

export default useLiConversationsImport;
