import { cn } from '@shadcn/ui/lib/utils';
import { Button } from '@shadcn/ui/components/ui/button';
import { Input } from '@shadcn/ui/components/ui/input';
import { Link, useSearchParams } from 'react-router-dom';
import { Card, CardContent, CardDescription, CardTitle, CardHeader } from '@shadcn/ui/components/ui/card';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../../../new/providers/authentication-provider';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { paths } from '../../../../routes/paths';

import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form, FormField, FormLabel, FormMessage } from '@shadcn/ui/components/ui/form';
import { FormControl } from '@shadcn/ui/components/ui/form';
import { FormItem } from '@shadcn/ui/components/ui/form';
import { Loader2 } from 'lucide-react';
import { GoogleAuthButton } from './google-auth-button';
import { validateEmailDomain } from '../../../../services/utils/emailHelper';
import { useSdk } from '../../../sdk';
import { LocalStorageKeys } from '../../../../config';

const getMockedEmailAddress = (mockEmailDomain?: string | undefined): string | undefined => {
    if (!mockEmailDomain) {
        return;
    }
    const suffix =
        typeof mockEmailDomain === 'string' && mockEmailDomain.length > 4 && RegExp(/^[a-z0-9]*$/).test(mockEmailDomain)
            ? mockEmailDomain
            : Date.now();
    return `user-${Date.now()}@testemail-${suffix}.com`;
};

const registerFormSchema = z.object({
    email: z.string().email('Invalid email address'),
    password: z.string().min(6, 'Password must be at least 6 characters'),
});

type RegisterFormValues = z.infer<typeof registerFormSchema>;

interface RegisterFormProps extends React.ComponentPropsWithoutRef<'div'> {
    onSuccess?: () => void;
    referralData?: {
        referringUsersName?: string;
    };
    hideCard?: boolean;
}

export function RegisterForm({ className, onSuccess, referralData, hideCard = false, ...props }: RegisterFormProps) {
    const [searchParams] = useSearchParams();
    const mockEmail = searchParams.get('mockEmail');

    const { loading: userIsLoading } = useAuth();
    const [authErrorMessage, setAuthErrorMessage] = useState<string | null>(null);
    const form = useForm<RegisterFormValues>({
        resolver: zodResolver(registerFormSchema),
        defaultValues: {
            email: getMockedEmailAddress(mockEmail) || '',
            password: '',
        },
        mode: 'all',
    });
    const {
        authentication: { sendEmailVerification },
    } = useSdk();
    const sendEmailVerificationMutation = useMutation(sendEmailVerification());
    const registerUser = async (email: string, password: string) => {
        if ((window as any)?.fpr) (window as any).fpr('referral', { email: email });

        const { user } = await createUserWithEmailAndPassword(getAuth(), email, password);
        await sendEmailVerificationMutation.mutateAsync({ emailAdress: email });

        window?.analytics?.identify({
            email: email,
        });

        window?.analytics?.track('User Registered', {
            email: email,
        });
        return user;
    };

    const { mutate: signUp, status } = useMutation({
        mutationFn: async (data: RegisterFormValues) => {
            const isEmailValid = await validateEmailDomain(data.email);

            if (!isEmailValid) {
                throw new Error('Please enter your work email (you@yourcompany.com)');
            }

            return await registerUser(data.email, data.password);
        },
        onSuccess: (data) => {
            window?.analytics?.track('User Signed Up', {
                email: form.getValues('email'),
                ...(referralData?.referringUsersName && { referrerName: referralData.referringUsersName }),
            });

            if (onSuccess) {
                onSuccess();
            }
            // The redirect here is handled by the new token listener in the authentication provider
        },
        onError: (error: any) => {
            console.error('firebase error', error);
            if (error.code === 'auth/email-already-in-use') {
                setAuthErrorMessage('Email already in use. Please try another email or login.');
            } else {
                setAuthErrorMessage(error.message || 'An error occurred during registration. Please try again.');
            }
        },
    });

    const onSubmit = (data: RegisterFormValues) => {
        signUp(data);
    };

    const formContent = (
        <Form {...form}>
            <div className="grid gap-6">
                <div className="flex flex-col gap-4">
                    <GoogleAuthButton>Sign up with Google</GoogleAuthButton>
                </div>
                <div className="relative text-center text-sm after:absolute after:inset-0 after:top-1/2 after:z-0 after:flex after:items-center after:border-t after:border-border">
                    <span className="relative z-10 bg-background px-2 text-muted-foreground">Or continue with</span>
                </div>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <div className="grid gap-3">
                        <FormField
                            control={form.control}
                            name="email"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Email</FormLabel>
                                    <FormControl>
                                        <Input
                                            placeholder="name@work-email.com"
                                            type="email"
                                            autoComplete="email"
                                            {...field}
                                            disabled={userIsLoading}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="password"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Password</FormLabel>
                                    <FormControl>
                                        <Input
                                            type="password"
                                            autoComplete="new-password"
                                            placeholder="Enter your password"
                                            disabled={userIsLoading}
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        {authErrorMessage && <div className="text-sm text-destructive">{authErrorMessage}</div>}
                        <Button
                            type="submit"
                            variant="default"
                            disabled={!form.formState.isValid || status === 'pending' || userIsLoading}
                            className="w-full mt-3"
                        >
                            {status === 'pending' ? (
                                <>
                                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                    Creating account...
                                </>
                            ) : (
                                'Register with email'
                            )}
                        </Button>
                    </div>
                </form>
                <div className="text-center text-sm">
                    Already have an account?{' '}
                    <Link to={paths.NEW_LOGIN} className="underline underline-offset-4 text-primary">
                        Sign in
                    </Link>
                </div>
            </div>
        </Form>
    );

    if (hideCard) {
        return (
            <div className={cn('w-full', className)} {...props}>
                {formContent}
                <div className="mt-4 text-balance text-center text-xs text-muted-foreground [&_a]:underline [&_a]:underline-offset-4 [&_a]:hover:text-primary">
                    By clicking register, you agree to our <Link to={paths.LEGAL.TERMS}>Terms of Service</Link> and{' '}
                    <Link to={paths.LEGAL.PRIVACY_POLICY}>Privacy Policy</Link>.
                </div>
            </div>
        );
    }

    return (
        <div className={cn('flex flex-col gap-6 w-full max-w-md mx-auto', className)} {...props}>
            <Card className="w-full hover:shadow-lg transition-shadow duration-300 focus-within:shadow-lg">
                <CardHeader className="text-center">
                    <CardTitle className="text-3xl mb-1">Signup for free</CardTitle>
                    <CardDescription className="text-lg">Get started with Andsend in 2 minutes</CardDescription>
                </CardHeader>
                <CardContent className="w-full">{formContent}</CardContent>
            </Card>
            <div className="text-balance text-center text-xs text-muted-foreground [&_a]:font-medium [&_a]:hover:text-primary">
                By submitting this form, you agree to our <br />
                <Link to={paths.LEGAL.TERMS} target="_blank" rel="noopener noreferrer">
                    Terms of Service
                </Link>
                ,{' '}
                <Link to={paths.LEGAL.PRIVACY_POLICY} target="_blank" rel="noopener noreferrer">
                    Privacy Policy
                </Link>{' '}
                and{' '}
                <a href={paths.LEGAL.DPA} target="_blank" rel="noopener noreferrer">
                    Data Processing Agreement
                </a>
                .
            </div>
        </div>
    );
}
