import { Typography } from '@mui/material';
import { toUpperCase } from '../helpers/text';
import { theme } from '../theme';
import { OrganizationIcon } from './icons/organization';

const colors = (theme.palette as any).colors;

const BasicOrganizationInfo = ({
    organizationName,
    activeUsersCount,
}: {
    organizationName: string;
    activeUsersCount: number;
}) => (
    <div
        style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            placeContent: 'center',
            padding: '28px 0 19px',
            background: colors.whiteDark,
            borderRadius: '14px 14px 0 0',
        }}
    >
        <OrganizationIcon style={{ height: 58, width: 58 }} />
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: 18 }}>
            <Typography style={{ color: colors.black, fontSize: 19, fontWeight: 500 }}>
                {toUpperCase(organizationName)}
            </Typography>
            <Typography style={{ color: colors.subline, fontSize: 15 }}>
                {activeUsersCount} {`${activeUsersCount === 1 ? 'user' : 'users'}`}
            </Typography>
        </div>
    </div>
);

export default BasicOrganizationInfo;
