import { BillingPeriodUnit, BillingPeriodUnitReadable, BillingPeriodUnitToNumber } from './billing.enums';

export const calculatePredictGoal = (
    creditsRenewal: number,
    periodMonthAmount: number,
    activeUsersCount: number,
): number => {
    if (!periodMonthAmount || !activeUsersCount) {
        return 0;
    }
    return Math.ceil(creditsRenewal / periodMonthAmount / activeUsersCount);
};

export const getPeriodInMonths = (periodAmount: number, periodUnit: BillingPeriodUnit): number => {
    const factor = BillingPeriodUnitToNumber[periodUnit];
    return typeof factor === 'number' ? factor * periodAmount : periodAmount;
};

export const getPeriodFromMonths = (periodInMonths: number) => {
    let unit: BillingPeriodUnit;
    if (periodInMonths % 12 === 0) unit = BillingPeriodUnit.YEAR;
    else if (periodInMonths === 6) unit = BillingPeriodUnit.HALF_YEAR;
    else if (periodInMonths === 3) unit = BillingPeriodUnit.QUARTER;
    else unit = BillingPeriodUnit.MONTH;

    // const unit = BillingPeriodNumberToUnit[periodInMonths] || BillingPeriodUnit.MONTH;
    const factor = BillingPeriodUnitToNumber[unit];
    const period = periodInMonths / factor;
    return {
        period,
        unit,
    };
};

export function readableBillingFrequencyUnit(
    billingPeriod: number,
    billingPeriodUnit: BillingPeriodUnit,
): 'monthly' | 'quarterly' | 'half-year' | 'annually' {
    let period = '';

    if (billingPeriodUnit === 'year') {
        return 'annually';
    }

    if (billingPeriodUnit === 'month') {
        if (billingPeriod >= 1 && billingPeriod < 3) {
            period = 'monthly';
        }
        if (billingPeriod >= 3 && billingPeriod < 6) {
            period = 'quarterly';
        }
        if (billingPeriod >= 6 && billingPeriod < 12) {
            period = 'half-year';
        }
        if (billingPeriod >= 12) {
            period = 'annually';
        }
    }

    return period as 'monthly' | 'quarterly' | 'half-year' | 'annually';
}

export const getFormattedPeriodString = (periodAmount: number, periodUnit: BillingPeriodUnit): string => {
    return `${periodAmount > 1 ? periodAmount : ''} ${BillingPeriodUnitReadable[periodUnit]}${
        periodAmount > 1 ? 's' : ''
    }`
        .trim()
        .toLowerCase();
};
