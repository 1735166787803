import { IEvent, Topics } from '@zaplify/common';

export class FailedToChangeUserOrganizationEvent extends IEvent {
    public static override readonly TOPIC = Topics.USERS;

    constructor(
        readonly userId: string,
        readonly organizationUpdateFailedId: string,
        readonly oldOrganizationId: string,
    ) {
        super();
    }

    get partitionKey(): string {
        return this.oldOrganizationId;
    }
}
