import {
    CreateCampaignDto,
    CampaignStatisticsDto,
    UpdateCampaignDto,
    CampaignOrganizationStatisticsRequestDto,
    ExtendedTeamStatisticsPageDto,
    KeyMetrics,
    TeamStatsLiveSummary,
    CampaignProspectsForExportDto,
    MoveProspectsToAnotherCampaignDto,
    OutreachSettingsDto,
    IMessage,
} from '@zaplify/campaigns';
import { CampaignsDto } from '@zaplify/campaigns';
import { CampaignDto } from '@zaplify/campaigns';
import { TextFieldDto } from '@zaplify/campaigns';
import { BaseSdk } from '../../base.sdk';
import { AiWorkflowGenerationOptions, AiWorkflowGenerationInputs } from '@zaplify/campaigns';
import { LaunchProspectsDto } from '@zaplify/campaigns';
import { ProspectManagementDto } from '@zaplify/campaigns';
import { ChannelType } from '@zaplify/channel-accounts/shared';
import { MessageType, MessageDto } from '@zaplify/services/messaging/shared';

export class CampaignsSdk extends BaseSdk {
    async assignFilterToCampaign(campaignId: string, personFilterId: string) {
        return null;
    }

    async getUserCampaigns() {
        return [];
    }

    async getProspectsForExport(campaignId: string, query: string) {
        return {
            prospects: [],
        };
    }
    async getProspects(campaignId: string, query: string): Promise<ProspectManagementDto> {
        return {
            prospects: [],
            companies: [],
            prospectsByCompany: {},
            prospectsCount: 0,
            totalProspectsCount: 0,
            prospectsCountByStatus: {} as any,
            prospectsCountByFinishedReasons: {} as any,
            perPage: 0,
            currentPage: 0,
            pagesCount: 0,
        };
    }

    async assignProspectsToCampaign(campaignId: string, prospectIds: string[]) {
        return [];
    }

    async pauseProspects(campaignId: string, prospectIds: string[]) {
        return {};
    }

    async startProspects(campaignId: string, prospectIds: string[]) {
        return {};
    }

    async createDraft(createCampaignDto: CreateCampaignDto) {
        return {} as any;
    }

    // @TODO move to messenger sdk
    async setupMessenger(language?: string) {
        return this.post<{ playbookId: string; assistantSettingsId: string }>({
            path: `messenger/setup`,
            payload: language ? { groupSettings: { languages: [language] } } : {},
        });
    }

    async publishDraft(campaignId: string) {
        return null as any;
    }

    async getCampaign(campaignId: string) {
        return null as any;
    }
    async startCampaign(campaignId: string) {
        return null as any;
    }

    async pauseCampaign(campaignId: string) {
        return null as any;
    }

    async archiveCampaign(campaignId: string): Promise<void> {
        return;
    }

    async getCampaignStatistics(campaignId: string, dateFrom?: Date, dateTo?: Date) {
        return {
            queuedProspects: 0,
            activeProspects: 0,
            completedProspects: 0,
            replies: 0,
        };
    }

    async updateCampaignById(campaignId: string, updateCampaignDto: UpdateCampaignDto) {
        return;
    }

    async cloneCampaign(campaignId: string, errorCallback: (error: any) => {}) {
        return;
    }

    async removeProspectsFromCampaign(campaignId: string, prospectIds: string[]): Promise<void> {
        return;
    }
    async moveProspectsToAnotherCampaign(
        campaignId: string,
        moveProspectsToAnotherCampaignDto: MoveProspectsToAnotherCampaignDto,
    ) {
        return [];
    }

    async canMoveProspectsToAnotherCampaign(
        campaignId: string,
        prospectIds: string[],
    ): Promise<{
        allowedToMove: string[];
        notAllowedToMove: {
            id: string;
            reason: string;
        }[];
    }> {
        return {
            allowedToMove: [],
            notAllowedToMove: [],
        };
    }

    async getCopySuggestion(
        inputForm: AiWorkflowGenerationInputs,
        workflowSteps: any,
        options: AiWorkflowGenerationOptions,
    ) {
        return {} as any;
    }

    async getSingleMessageCopySuggestion(
        inputForm: AiWorkflowGenerationInputs,
        workflowSteps: any,
        options: AiWorkflowGenerationOptions,
        targetStepId: string,
    ) {
        return { message: '' };
    }

    async resumeAutoRepliedProspect(
        campaignId: string,
        prospectIds: string[],
    ): Promise<{
        successful: string[];
        unsuccessful: string[];
    }> {
        return {
            successful: [],
            unsuccessful: [],
        };
    }

    // LEAVE
    async getOutreachSettings() {
        return this.get<OutreachSettingsDto>(`${this.outreachSettingsPath}`);
    }

    // LEAVE
    async updateOutreachSettings(outreachSettings: OutreachSettingsDto): Promise<void> {
        return this.post({
            path: `${this.outreachSettingsPath}`,
            payload: outreachSettings,
        });
    }

    async sendSuggestedEmailMessage(
        messageId: string,
        prospectId: string,
        subject: string,
        content: string,
    ): Promise<MessageDto> {
        return this.put(`${this.campaignsPath}/email/message/${messageId}`, {
            prospectId,
            subject,
            content,
        });
    }

    async sendNewEmailMessage({
        prospectId,
        subject,
        content,
    }: {
        prospectId: string;
        subject: string;
        content: string;
    }): Promise<MessageDto> {
        return this.post({
            path: `${this.campaignsPath}/email/message`,
            payload: {
                prospectId,
                subject,
                content,
            },
        });
    }

    /** Feedback must be non-empty string. Changes the subjectLine and content of message in DB, and returns the altered entity. */
    async regenerateMessage({
        messageId,
        feedback,
        content,
        subjectLine,
        actionType,
    }: {
        messageId: string;
        feedback: string;
        content?: string;
        subjectLine?: string;
        actionType: MessageType;
    }): Promise<IMessage> {
        return this.post({
            path: `${this.campaignsPath}/messages/${messageId}/regenerate`,
            payload: {
                feedback,
                content,
                subjectLine,
                actionType,
            },
        });
    }

    /** Feedback must be non-empty string. Changes the subjectLine and content of message in DB, and returns the altered entity. */
    async generateMessageAsEmail({
        messageId,
        content,
        subjectLine,
    }: {
        messageId: string;
        content?: string;
        subjectLine?: string;
    }): Promise<IMessage> {
        return this.post({
            path: `${this.campaignsPath}/messages/${messageId}/as-email`,
            payload: {
                content,
                subjectLine,
            },
        });
    }

    /** Feedback must be non-empty string. Does not alter values in DB. */
    async getRegeneratedMessage({
        prospectId,
        channelType,
        actionType,
        feedback,
        message,
    }: {
        prospectId: string;
        channelType?: ChannelType;
        actionType: MessageType;
        feedback: string;
        message: { subjectLine?: string; content: string };
    }): Promise<{ subjectLine?: string; content: string } | null> {
        const queryParams = new URLSearchParams({
            prospectId,
            actionType,
            feedback,
            ...message,
        });
        if (channelType) queryParams.append('channelType', channelType);

        return this.get<{ subjectLine?: string; content: string }>(
            `${this.campaignsPath}/messages/regenerated?${queryParams.toString()}`,
        );
    }

    async setLinkedinMessageSuggestionSent({
        prospectId,
        content,
        sentOn,
        messageId,
        externalMessageId,
        conversationId,
        messageType,
        linkedinAccountId,
    }: {
        prospectId: string;
        content: string;
        sentOn: Date;
        messageId: string | undefined;
        externalMessageId: string;
        conversationId: string | null;
        messageType: MessageType.linkedinConnectionRequest | MessageType.linkedinMessage;
        linkedinAccountId: string;
    }) {
        return this.put(`${this.campaignsPath}/linkedin/message/${messageId}`, {
            prospectId,
            content,
            sentOn,
            messageType,
            externalMessageId,
            conversationId,
            linkedinAccountId,
        });
    }
}

interface CampaignKeyMetricsRequestDto {
    dateFrom: Date;
    dateTo: Date;
    userIds: string[];
}

export interface UpdateWorkflowCopyWritingDto {
    steps: StepCopyDto[];
}

export interface StepCopyDto {
    stepId: string;
    subject?: TextFieldDto;
    message: TextFieldDto;
}
