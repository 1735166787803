import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { getZaplifySdk } from '@zaplify/sdk';
import { PlanCode, PublicSeatDto } from '@zaplify/subscriptions';
import { UserComposedDto, UserOrInvitationRowDto, UserRole } from '@zaplify/users/shared';
import { useAtom } from 'jotai';
import { useFlags } from 'launchdarkly-react-client-sdk';
import * as React from 'react';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { channelAccountsAtom, sidebarPinStateAtom } from '../../../atoms/chats';
import InactiveAccountInfoModal from '../../../components/InactiveAccountInfoModal';
import NoSeatsLeftInfoModal from '../../../components/NoSeatsLeftInfoModal';
import BlockList from '../../../components/blocklist/block-list';
import { ContactLimitExceededPopover } from '../../../components/limit-exceeded-modal';
import { MobileView } from '../../../components/mobile-view';
import PickPlanModal from '../../../components/pick-plan-modal/PickPlanModal';
import { SubscriptionCancelledModal } from '../../../components/subscription-cancelled-modal';
import { LocalStorageKeys } from '../../../config';
import { useProspectSync } from '../../../hooks/use-prospect-sync';
import { useSeatsAndCredits } from '../../../new/hooks/use-seat-and-credits';
import { useAppSelector } from '../../../redux/store/configureStore';
import { PageContainer } from '../../containers';
import EnsureAuthenticated from '../../ensureAuthenticated';
import { NavigationDrawer } from './navigation-drawer';
import { useWindowResizing } from '../../../hooks/use-window-resizing';
import { useSidebarCollapseMemory } from './hooks/use-sidebar-collapse-memory';
import { LoaderView } from '../../../views/loader';
import { GlobalSearchPopupV2 } from '../../../components/GlobalSearch/globalSearchPopup';

function log(text: string) {
    return `🚀 [InAppTemplate] ${text};`;
}

const determineModal = ({
    zaplifyUser,
    isNotLoadingData,
    seat,
    loadingSeats,
    unableToFetchSeats,
}: {
    zaplifyUser: UserComposedDto;
    isNotLoadingData: boolean;
    seat: PublicSeatDto | null;
    loadingSeats: boolean;
    unableToFetchSeats: boolean;
}): ModalTypeInfo | null => {
    // Return null if data is still loading
    if (!isNotLoadingData) return null;

    const isUserDeactivated = zaplifyUser.status === 'DEACTIVATED';
    const isOrganizationOnFreePlan = seat?.subscription?.planCode === PlanCode.FREE;

    // Show 'INACTIVE_ACCOUNT' modal if the user's account is deactivated
    if (isUserDeactivated) {
        console.log('User account is deactivated', { isUserDeactivated });
        return 'INACTIVE_ACCOUNT';
    }

    if (loadingSeats || unableToFetchSeats) return null;

    // Show 'NO_SEATS' modal if:
    // - User does not have seat
    if (!seat?.seatId) {
        console.log('No seats left', { loadingSeats, seat, isOrganizationOnFreePlan });
        return 'NO_SEATS';
    }

    // Return null if no conditions are met
    return null;
};

type ModalTypeInfo =
    | 'NO_CREDITS'
    | 'PICK_PLAN'
    | 'INACTIVE_ACCOUNT'
    | 'NO_SEATS'
    | 'ONBOARDING'
    | 'CONNECT_CHANNEL_ACCOUNTS'
    | null;

const InAppTemplate = () => {
    const [admin, setAdmin] = React.useState<UserOrInvitationRowDto | null>(null);
    const [modalType, setModalType] = React.useState<ModalTypeInfo | null>(null);
    const user = useAppSelector((state) => state.user);
    const userOrganizationId = user.zaplifyUser?.userOrganizationId;

    const [sidebarPinState] = useAtom(sidebarPinStateAtom);
    const [channelAccounts] = useAtom(channelAccountsAtom);
    const { runBackgroundJobs } = useProspectSync();

    const { seat, seatSummary, loading: loadingSeats, unableToFetch: unableToFetchSeats } = useSeatsAndCredits();
    const activeUsersCount = seatSummary?.occupiedSeatsCount || 0;
    const organizationName = user.zaplifyUser?.userOrganization?.name || '';

    const { isNavigationSidebarOpen: keepSidebarOpen, setIsNavigationSidebarOpen: setKeepSidebarOpen } =
        useSidebarCollapseMemory();
    const [openSidebar, setOpenSidebar] = React.useState(keepSidebarOpen);

    useWindowResizing((width, height) => {
        const isSmallScreen = width <= 1030;
        setKeepSidebarOpen(!isSmallScreen);
    });

    const handleChangeSidebarState = () => {
        if (keepSidebarOpen) {
            setKeepSidebarOpen(false);
            setOpenSidebar(false);
        } else {
            setKeepSidebarOpen(true);
        }
    };

    // User must hover 40ms to open the sidebar
    const mouseOnDrawerTimeRef = React.useRef<NodeJS.Timeout | null>(null);
    const handleMouseEnter = () => {
        mouseOnDrawerTimeRef.current = setTimeout(() => {
            setOpenSidebar(true);
        }, 40);
    };
    const handleMouseLeave = () => {
        if (mouseOnDrawerTimeRef.current) {
            clearTimeout(mouseOnDrawerTimeRef.current);
            mouseOnDrawerTimeRef.current = null;
        }
        // Existing onMouseLeave logic
        if (!keepSidebarOpen) {
            setOpenSidebar(false);
        }
    };

    React.useEffect(() => {
        setOpenSidebar(keepSidebarOpen);
    }, [keepSidebarOpen]);

    React.useEffect(() => {
        log(`runBackgroundJobs`);
        runBackgroundJobs();
    }, [channelAccounts?.linkedIn?.id]);

    React.useEffect(() => {
        if (!userOrganizationId) return;
        const getOrgAdmin = async () => {
            const userOrganizationSdk = getZaplifySdk().profiles.userOrganization;
            const organizationUsers = await userOrganizationSdk.getUsersAndInvitationsByOrganization(
                userOrganizationId
            );
            const admin = organizationUsers.find(
                (user): user is UserOrInvitationRowDto =>
                    'roles' in user && user.roles.includes(UserRole.ORGANIZATION_ADMIN)
            );
            setAdmin(admin);
        };

        getOrgAdmin();
    }, [userOrganizationId]);

    const isLoadingUser = user?.isLoadingData;
    const isLoadingSubscription = user?.isLoadingSubscription;

    React.useEffect(() => {
        const type: ModalTypeInfo = determineModal({
            zaplifyUser: user?.zaplifyUser,
            isNotLoadingData: !isLoadingUser && !isLoadingSubscription,
            seat,
            loadingSeats,
            unableToFetchSeats,
        });
        setModalType(type);
    }, [user, isLoadingUser, isLoadingSubscription, seat, loadingSeats, unableToFetchSeats]);

    const isSidebarPinned = sidebarPinState === 'pinned';
    /**
     * the widths are a combination of the sidebar width + hozizontal padding + right margin
     */
    const widthWithPinnedSidebarOn = isSidebarPinned ? 'calc(100vw - 190px)' : 'calc(100vw - 40px)';

    return (
        <EnsureAuthenticated>
            <Suspense fallback={<LoaderView />}>
                <MobileView>
                    <PageContainer>
                        <GlobalSearchPopupV2 />
                        <SubscriptionCancelledModal />
                        <NavigationDrawer />
                        <Box
                            component="main"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: 'calc(100vh - 8px)',
                                boxSizing: 'border-box',
                                width: widthWithPinnedSidebarOn,
                                marginRight: '4px',
                            }}
                        >
                            <Box
                                sx={{
                                    borderRadius: 'var(--radius-lg, 14px)',
                                    background: 'var(--grey-palette-white, #FFF)',
                                    boxShadow:
                                        '0px 4px 12px 0px rgba(30, 36, 48, 0.08), 0px 1px 3px 0px rgba(30, 36, 48, 0.10)',
                                    marginTop: '4px',
                                    // marginBottom: '4px',
                                    height: 'calc(100vh - 8px)',
                                    maxHeight: 'calc(100vh - 8px)',
                                    boxSizing: 'border-box',
                                    width: '100%',
                                    border: '1px solid var(--grey-palette-grey-3, #E4E6EB)',
                                    overflow: 'hidden',
                                }}
                            >
                                <Outlet />
                            </Box>
                        </Box>
                        <BlockList positionAtTop />

                        {modalType === 'PICK_PLAN' && (
                            <PickPlanModal open upgradeAccount={!!localStorage.getItem(LocalStorageKeys.PLAN)}>
                                <Box width="100%" display={'flex'} justifyContent={'center'}>
                                    <Typography variant="h4">Select plan to get started 👑</Typography>
                                </Box>
                            </PickPlanModal>
                        )}
                        {modalType === 'INACTIVE_ACCOUNT' && (
                            <InactiveAccountInfoModal
                                organizationName={organizationName}
                                activeUsersCount={activeUsersCount}
                            />
                        )}
                        {modalType === 'NO_SEATS' && (
                            <NoSeatsLeftInfoModal
                                organizationName={organizationName}
                                activeUsersCount={activeUsersCount}
                                adminEmail={admin?.email}
                            />
                        )}
                        <ContactLimitExceededPopover />
                    </PageContainer>
                </MobileView>
            </Suspense>
        </EnsureAuthenticated>
    );
};

export default InAppTemplate;
