import { ButtonBase } from '@mui/material';
import { paletteAndsend } from '@zaplify/ui';

export const ActionButton = <T extends (...args: any[]) => void>({
    children,
    onClick,
    disabled = false,
}: {
    children: React.ReactNode;
    onClick?: T;
    disabled?: boolean;
}) => {
    return (
        <ButtonBase
            sx={{
                minWidth: '100px',
                boxSizing: 'border-box',
                borderRadius: '6px',
                background: paletteAndsend.primary.main,
                padding: '11px 16px',
                color: '#FFFFFF',
                fontSize: '15px',
                fontWeight: 500,
                ':hover': {
                    background: paletteAndsend.primary.light,
                },
            }}
            onClick={onClick}
            disabled={disabled}
        >
            {children}
        </ButtonBase>
    );
};
