import { Box } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { Plus } from 'lucide-react';
import { bindHover, usePopupState } from 'material-ui-popup-state/hooks';
import SidebarHoverMenu from '../../../../components/molecules/sidebar-hover-menu';
import { paths } from '../../../../routes/paths';
import { NavigationListItem } from './navigation-list-item';
import { NavigationMainItem } from './navigation-main-item';
import { useLocation } from 'react-router-dom';
import { useSeatsAndCredits } from '../../../../new/hooks/use-seat-and-credits';
import { PlanCode } from '@zaplify/subscriptions';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const AddContactsListItem = ({ openSidebar }: { openSidebar: boolean }) => {
    const popupState = usePopupState({
        variant: 'popover',
        popupId: 'contacts',
    });
    const { pathname } = useLocation();
    const { seat } = useSeatsAndCredits();
    const { useNewImportLinkedin } = useFlags();

    const planCode = seat?.subscription?.planCode;
    const onTeamPlan = planCode === PlanCode.TEAM;

    const contactsActive = pathname.startsWith(paths.CONTACTS);
    const discoverActive = pathname.startsWith(paths.DISCOVER);
    const linkedinActive = pathname.startsWith(paths.IMPORT_PATHS.LINKEDIN);
    const listImportActive = pathname.startsWith(paths.IMPORT_PATHS.PROSPECTS);
    const isActive = !openSidebar && (contactsActive || discoverActive || linkedinActive || listImportActive);

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '2px',
                }}
                {...bindHover(popupState)}
            >
                <NavigationMainItem
                    title="Add contacts"
                    to={paths.DISCOVER}
                    icon={<Plus size={20} strokeWidth="1px" />}
                    isActive={isActive}
                    isSidebarPinned={openSidebar}
                    showChevron
                />
                {openSidebar ? (
                    <Box sx={{ display: 'flex' }}>
                        <Box
                            sx={{
                                width: '23px',
                                borderRight: '1px solid #E4E7EC',
                            }}
                        />
                        <Box sx={{ flex: 1, px: 2, minWidth: 0, display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                            <NavigationListItem
                                title="Discover"
                                to={paths.DISCOVER}
                                isActive={discoverActive}
                                isSidebarPinned={openSidebar}
                            />
                            <NavigationListItem
                                title="LinkedIn"
                                to={
                                    useNewImportLinkedin ? paths.NEW.IMPORT_PATHS.LINKEDIN : paths.IMPORT_PATHS.LINKEDIN
                                }
                                isActive={linkedinActive}
                                isSidebarPinned={openSidebar}
                            />
                            {onTeamPlan && (
                                <NavigationListItem
                                    title="List import"
                                    to={paths.IMPORT_PATHS.PROSPECTS}
                                    isActive={listImportActive}
                                    isSidebarPinned={openSidebar}
                                />
                            )}
                        </Box>
                    </Box>
                ) : (
                    <SidebarHoverMenu id="contacts" popupState={popupState}>
                        <MenuItem onClick={popupState.close}>
                            <NavigationListItem
                                title="Discover"
                                to={paths.DISCOVER}
                                isActive={discoverActive}
                                isSidebarPinned={openSidebar}
                            />
                        </MenuItem>
                        <MenuItem onClick={popupState.close}>
                            <NavigationListItem
                                title="Import"
                                to={paths.IMPORT}
                                isActive={linkedinActive}
                                isSidebarPinned={openSidebar}
                            />
                        </MenuItem>
                    </SidebarHoverMenu>
                )}
            </Box>
        </>
    );
};
