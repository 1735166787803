import { useDispatch } from 'react-redux-latest';
import { AppDispatch } from '../../../redux/store/configureStore';
//import { ErrorModal } from '../../navigation/components/start-campaign-modal';
import { useEffect, useState } from 'react';
import { updateWholeImportProspectsState } from '../../../redux/actions';
import { initialStateProspectingView } from '../../../redux/reducers/newCampaign/importProspects';
import { ImportProspectsViewContainer } from './containers';
import { FieldMapping } from './fieldMapping';
import { NavigationBar } from './navigationBar';
import { ReviewImportedProspects } from './review';
import { UploadCSV } from './uploadCSV';

export const Component = () => {
    const dispatch = useDispatch<AppDispatch>();
    const [currentStep, setCurrentStep] = useState(1);

    useEffect(() => {
        dispatch(updateWholeImportProspectsState(initialStateProspectingView));
    }, []);

    return (
        <ImportProspectsViewContainer>
            {currentStep === 1 && <UploadCSV />}
            {currentStep === 2 && <FieldMapping />}
            {currentStep === 3 && <ReviewImportedProspects />}
            <NavigationBar currentStep={currentStep} handleStep={(step) => setCurrentStep(step)} />
        </ImportProspectsViewContainer>
    );
};

Component.displayName = 'ImportProspectsView';
