import { BaseSdk } from '../../base.sdk';

export class UserAdminSdk extends BaseSdk {
    async forceRestartProspect(campaignId: string, prospectIds: string[]) {
        return this.post<void>({
            path: `${this.userAdminPath}/campaigns/forceRestartProspects`,
            payload: [{ campaignId, prospectIds }],
        });
    }
}
