import { NoteDto } from '@zaplify/services/notes/shared';
import { createMutationOptions } from './sdk-utils';
import { getZaplifySdk } from '@zaplify/sdk';
import { MutationOptions } from '@tanstack/react-query';

export interface CreateNoteBody {
    userId: string;
    prospectId: string;
    content: string;
    title: string;
}

export const deleteNote = (options?: MutationOptions<void, Error, { noteId: string }>) => {
    return createMutationOptions<void, Error, { noteId: string }>({
        mutationFn: (variables) => getZaplifySdk().profiles.notes.deleteNote(variables.noteId),
        ...options,
    });
};

export const createNote = (options?: MutationOptions<NoteDto, Error, CreateNoteBody>) => {
    return createMutationOptions<NoteDto, Error, CreateNoteBody>({
        mutationFn: (variables) => getZaplifySdk().profiles.notes.createNote(variables),
        ...options,
    });
};
