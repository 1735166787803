import { Box, Typography } from '@mui/material';
import { PeopleResponseDto } from '@zaplify/prospects';
import { HeaderSection as AccountHeaderSection } from '../AccountCard/HeaderSection';
import ProspectRow from '../ProspectRow';
import { SectionTitle } from '../SectionTitle';
import { useEffect, useState } from 'react';
import { AddContactButton } from './AddContactButton';
import { ProspectDetails } from './ProspectDetails';
import { CardClass, userAddedProspectInGlobalSearch } from '../tracking';
import { useDispatch } from 'react-redux-latest';
import { AppDispatch } from '../../../redux/store/configureStore';
import { handleErrorNotification } from '../../../redux/actions';
import { AccountCard } from '../AccountCard/AccountCard';
import { getZaplifySdk } from '@zaplify/sdk';
import { ChevronLeft } from 'lucide-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { AccountDto } from '@zaplify/services/accounts/shared';
import { useShowContactsLimitModal } from '../../../hooks/use-show-contact-limit-modal';
import { channelAccountsAtom } from '../../../atoms/chats';
import { useAtom } from 'jotai';

export const PeopleCard = ({
    person,
    groups,
    userId,
    handleAddPerson,
}: {
    person: PeopleResponseDto;
    groups: { id: string; name: string }[];
    userId: string;
    handleAddPerson: (groupId?: string) => Promise<any>;
}) => {
    const { enableMultipleProspectOwners } = useFlags();
    const [personsPurchased, setPersonsPurchased] = useState([]);
    const [addingPersonLoaders, setAddingPersonLoaders] = useState({});
    const [showAccountModal, setShowAccountModal] = useState(false);
    const [channelAccounts] = useAtom(channelAccountsAtom);
    const dispatch = useDispatch<AppDispatch>();
    const [account, setAccount] = useState<AccountDto | null>(null);
    const [addIsDisabled, setAddIsDisabled] = useState(false);
    const { showContactLimitModal } = useShowContactsLimitModal();
    const sdk = getZaplifySdk();

    useEffect(() => {
        showAccountModal && setShowAccountModal(false);
    }, [person]);

    useEffect(() => {
        if (person.companyName) {
            const fetchAccount = async (companyName) => {
                try {
                    const accountData = await sdk.profiles.prospects.getAccountByName(companyName);
                    setAccount(accountData);
                } catch (error) {
                    console.error('Failed to fetch account', error);
                    setAccount(null);
                }
            };

            fetchAccount(person.companyName);
        }
    }, [person.companyName]);

    useEffect(() => {
        setAddIsDisabled(person.assignedTo?.some((o) => o.userId === userId) || personsPurchased.includes(person._id));
    }, [person.assignedTo, personsPurchased, person._id]);

    const onClickAdd = async (personId: string, groupId?: string) => {
        try {
            setAddingPersonLoaders({ ...addingPersonLoaders, [person._id]: true });
            await handleAddPerson(groupId);
            setPersonsPurchased([...personsPurchased, personId]);
            userAddedProspectInGlobalSearch(1, CardClass.Person);
        } catch (error: any) {
            console.error(`🚀 ~~~ PeopleCard | onCLickAdd | catch: ${JSON.stringify(error)}`);
            setPersonsPurchased(personsPurchased.filter((id) => id !== personId));
            dispatch(handleErrorNotification(error.message || 'Failed to add contact'));
            if (error.status === 403) {
                showContactLimitModal();
            }
        } finally {
            setAddingPersonLoaders({ personId: false });
        }
    };

    if (showAccountModal && account) {
        return (
            <Box sx={{ direction: 'flex-start' }}>
                <Box
                    sx={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: '12px',
                        marginTop: '12px',
                    }}
                    onClick={() => setShowAccountModal(false)}
                >
                    <ChevronLeft size={16} style={{ marginRight: '8px' }} />
                    <Typography variant="body1" color="primary">
                        Back to {person.fullName}
                    </Typography>
                </Box>
                <AccountCard
                    userId={userId}
                    account={{
                        id: account.accountId,
                        name: account.data.name,
                        description: account.data.aboutUs,
                        numberOfEmployees: account.data.size,
                        industry: account.data.industry,
                        location: account.data.locations,
                        website: account.data.website,
                        headquarters: account.data.headquarters,
                        linkedinUrl: account.linkedinUrl,
                        tags: account.data.specialties,
                    }}
                    groups={groups}
                />
            </Box>
        );
    }

    return (
        <Box
            sx={{
                display: 'flex',
                padding: '18px',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: 'var(--M-m-compact, 16px)',
                flex: '1 0 0',
                alignSelf: 'stretch',
                overflowX: 'hidden',
                overflowY: 'auto',
                height: '550px',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: 'var(--M-m-compact, 16px)',
                    flex: '1 0 0',
                    alignSelf: 'stretch',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        alignSelf: 'stretch',
                    }}
                >
                    <ProspectRow
                        prospect={{
                            _id: person._id,
                            fullName: person.fullName,
                            organizationName: person.companyName,
                            firstName: person.fullName.split(' ')[0],
                            lastName: person.fullName.split(' ')[1],
                        }}
                        avatarUrl={person.linkedinProfileImgUrl}
                        userId={userId}
                        searchValue={person.fullName}
                    />
                    <SectionTitle title="Company" />
                    <Box
                        onClick={() => setShowAccountModal(true)}
                        sx={{
                            cursor: account && 'pointer',
                            paddingY: '2px',
                            '&:hover': {
                                textDecoration: account && 'underline',
                                backgroundColor: account && '#F7F8FA',
                            },
                            width: '100%',
                        }}
                    >
                        <AccountHeaderSection
                            account={{
                                website: person.companyWebsite,
                                name: person.companyName,
                                industry: person.companyIndustry,
                            }}
                        />
                    </Box>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        gap: 'var(--S-s-spacious, 12px)',
                        alignSelf: 'stretch',
                    }}
                >
                    <SectionTitle title="Prospect details" />
                    <ProspectDetails
                        userId={userId}
                        userAvatarUrl={channelAccounts?.linkedIn?.avatarUrl}
                        title={person.title}
                        location={person.location}
                        website={person.companyWebsite}
                        companyName={person.companyName}
                        owners={enableMultipleProspectOwners ? person.assignedTo : []}
                    />
                </Box>
            </Box>
            <AddContactButton
                groups={groups}
                disabled={addIsDisabled}
                userId={userId}
                onAdd={(group) => onClickAdd(person._id, group?.id)}
                loading={addingPersonLoaders[person._id]}
                owners={person.assignedTo}
            />
        </Box>
    );
};
