import { Route, Navigate } from 'react-router-dom';
import { Suspense } from 'react';
import { paths } from '../paths';
import { AccountManagementPage } from '../../new/pages/account-management/account-management-page';
import { ReactRouterErrorBoundary } from '../react-router-error-boundary';
import PersonalizationPage from '../../new/pages/account-management/personalization/personalization-page';
import SubscriptionPage from '../../new/pages/account-management/subscription/subscription-page';
import { UsersPage } from '../../new/pages/account-management/users/users-page';
import { AppsPage } from '../../new/pages/account-management/apps/apps-page';
import { AppSettingsPage } from '../../new/pages/account-management/apps/app-settings-page';
import CallbackPage from '../../new/pages/account-management/apps/callback-page';
import { ProfilePage } from '../../new/pages/account-management/profile/profile-page';

export const accountManagementRoutes = (
    <Route path="account-management" element={<AccountManagementPage />} ErrorBoundary={ReactRouterErrorBoundary}>
        <Route path={'profile'} element={<ProfilePage />} />
        <Route path={'memories'} element={<PersonalizationPage />} />
        <Route index element={<Navigate to={'subscription'} replace />} />
        <Route path="subscription/:activeModal?" element={<SubscriptionPage />} />
        <Route
            path="users/:userIdToManage?/:activeModal?"
            element={
                <Suspense fallback={<div>Loading...</div>}>
                    <UsersPage />
                </Suspense>
            }
        />
        <Route
            path="apps"
            element={
                <Suspense fallback={<div>Loading...</div>}>
                    <AppsPage />
                </Suspense>
            }
        />
        {/**
         * Integration routes
         */}

        <Route path="apps/:appId" element={<AppSettingsPage />} />
        <Route
            path="apps/:appId/callback"
            element={
                <Suspense fallback={<div>Loading...</div>}>
                    <CallbackPage />
                </Suspense>
            }
        />
    </Route>
);
