import { ICommand, Topics } from '@zaplify/common';
import { CoreMessage } from 'ai';

export class AgentChatCommand extends ICommand<string> {
    public static override readonly TOPIC = Topics.PROSPECTS;

    public readonly messages: CoreMessage[];
    public readonly threadId: string;
    public readonly userId: string;

    constructor(input: { messages: CoreMessage[]; threadId: string; userId: string }) {
        super();
        this.messages = input.messages;
        this.threadId = input.threadId;
        this.userId = input.userId;
    }
}
