import { gql } from '../__generated__';

export const GET_CONVERSATIONS = gql(`
  query GetMessagesByProspectId {
    Messages(where: {sentOn: {_is_null: false}}, order_by: [{prospectId: desc}, {sentOn: desc}], distinct_on: prospectId) {
      prospectId
      sentOn
      fullName
      content
      id
      actionType
      linkedinUserId
      prospectFullName
      deliveryStatus
      Attachments {
        type
        value
      }
      OutreachSuggestion {
        id
      }
    }
  }
`);

export const GET_OUTREACH_SUGGESTIONS = gql(`
query GetOutreachSuggestions($limit: Int, $notYetSent: Boolean! = true ,$scheduledOnLt: timestamp!, $sentOnGte: timestamp!) {
  focus: OutreachSuggestions(limit: $limit, where: {Message: {sentOn: {_is_null: $notYetSent}}, scheduledOn: {_lt: $scheduledOnLt}}) {
    accountName
    accountWebsite
    email
    linkedinUrl
    numberOfEmployees
    title
    name
    industry
    contactLocation
    id
    updatedAt
    createdAt
    prospectId
    unread
    Message {
      id
      subjectLine
      content
      actionType
      channelType
    }
    ConversationMetaData {
      id
      numberOfMessages
      isNextMessageFollowUp
      messagesPerDay
      lastNetworkUpdateAt
      youThemMessageRatio
      lastUserNoteAt
      hasMessagesSentFromApp
      medianMessageLength
      connectionStatus
      lastTimeToAnswer
      userContactAddedAt
      userContactFromOnboarding
      lastMessageAt
      numberOfMessagesLastDay
      numberOfMessagesLastWeek
      createdAt
      updatedAt
    }
  
  }
  completed: OutreachSuggestions(limit: $limit, where: {Message: {sentOn: {_is_null: false, _gte: $sentOnGte}}, scheduledOn: {_lt: $scheduledOnLt}}) {
    accountName
    accountWebsite
    email
    linkedinUrl
    numberOfEmployees
    title
    name
    industry
    contactLocation
    id
    updatedAt
    createdAt
    prospectId
    unread
    Message {
      id
      subjectLine
      content
      actionType
      channelType
    }
    ConversationMetaData {
      id
      numberOfMessages
      isNextMessageFollowUp
      messagesPerDay
      lastNetworkUpdateAt
      youThemMessageRatio
      lastUserNoteAt
      hasMessagesSentFromApp
      medianMessageLength
      connectionStatus
      lastTimeToAnswer
      userContactAddedAt
      createdAt
      updatedAt
    }
  }
  focusCount: OutreachSuggestions_aggregate(limit: $limit, where: {Message: {sentOn: {_is_null: $notYetSent}}, scheduledOn: {_lt: $scheduledOnLt}}) {
    aggregate {
      count
    }
  }
  completedCount: OutreachSuggestions_aggregate(limit: $limit, where: {Message: {sentOn: {_is_null: false}}, scheduledOn: {_lt: $scheduledOnLt}}) {
    aggregate {
      count
    }
  }
}
`);

export const GET_OUTREACH_SUGGESTIONS_BY_PROSPECT_ID = gql(`
query GetOutreachSuggestionsByProspectId($prospectId: String!, $scheduledOnBefore: timestamp!) {
  focus: OutreachSuggestions(where: {prospectId: {_eq: $prospectId}, scheduledOn: { _lt: $scheduledOnBefore }, Message: {sentOn: {_is_null: true}}}, order_by: {Message: {createdAt: desc}}) {
    id
    updatedAt
    createdAt
    Message {
      id
      subjectLine
      content
      actionType
      channelType
    }
  }
  completed: OutreachSuggestions(where: {prospectId: {_eq: $prospectId}, scheduledOn: {_lt: $scheduledOnBefore}, Message: {sentOn: {_is_null: false}}}, order_by: {Message: {createdAt: desc}}) {
    id
  }
  future: OutreachSuggestions(where: {prospectId: {_eq: $prospectId}, scheduledOn: {_gt: $scheduledOnBefore}, Message: {sentOn: {_is_null: true}}}, order_by: {Message: {createdAt: desc}}) {
    id
    scheduledOn
    scheduledOnMotivation
  }
  unscheduled: OutreachSuggestions(where: {prospectId: {_eq: $prospectId}, scheduledOn: {_is_null: true}, Message: {sentOn: {_is_null: true}}}, order_by: {Message: {createdAt: desc}}) {
    id
  }
}
`);

export const GET_ACTIVE_OUTREACH_SUGGESTION_BY_PROSPECT_IDS = gql(`
query GetActiveOutreachSuggestionByProspectIds($prospectIds: [String!]!) {
  OutreachSuggestions(where: {prospectId: {_in: $prospectIds}, scheduledOn: {_is_null: false}, Message: {sentOn: {_is_null: true}}}, order_by: {Message: {createdAt: desc}}) {
    id
    prospectId
  }
}
`);

export const GET_CONTACT_SUGGESTIONS = gql(`
query GetContactSuggestions {
  ContactSuggestions(
    where: {approvedAt: {_is_null: true}, rejectedAt: {_is_null: true}, expiresAt: {_gt: "now()"}},
    order_by: {createdAt: desc}
  ) {
    id
    contactName
    reason
    personId
    organizationId
    contactEmailVerified
    contactLinkedinUrl
    contactEmail
    contactTitle
    contactLocation
    campaignId
    campaignName
    accountIndustry
    accountWebsite
    accountTags
    accountName
    accountId
    accountAboutUs
    accountNumberOfEmployees
    userId
    groupId
    prospectId
    suggestionMotivation
    contactImgUrl
  }
}
`);

export const GET_CONTACT_SUGGESTION_BY_ID = gql(`
query GetContactSuggestionById($id: String!) {
  ContactSuggestions(where: {id: {_eq: $id}}) {
    id
    contactName
    reason
    personId
    organizationId
    contactEmailVerified
    contactLinkedinUrl
    contactEmail
    contactTitle
    contactLocation
    campaignId
    campaignName
    accountIndustry
    accountWebsite
    accountTags
    accountName
    accountId
    accountAboutUs
    accountNumberOfEmployees
    userId
    groupId
    prospectId
    suggestionMotivation
    contactImgUrl
  }
}
`);

export const GET_CONTACT_SUGGESTIONS_BY_GROUP_ID = gql(`
query GetContactSuggestionsByGroupId($groupId: String!) {
  ContactSuggestions(
    where: {approvedAt: {_is_null: true}, rejectedAt: {_is_null: true}, expiresAt: {_gt: "now()"}, groupId: {_eq: $groupId}},
    order_by: {createdAt: desc}
  ) {
    id
    contactName
    reason
    personId
    organizationId
    contactEmailVerified
    contactLinkedinUrl
    contactEmail
    contactTitle
    contactLocation
    campaignId
    campaignName
    accountIndustry
    accountWebsite
    accountTags
    accountName
    accountId
    accountAboutUs
    accountNumberOfEmployees
    userId
    groupId
    prospectId
    suggestionMotivation
    contactImgUrl
  }
}
`);

export const GET_CONTACT_SUGGESTIONS_COUNT = gql(`
query GetContactSuggestionsCount {
  ContactSuggestions_aggregate(
    where: {approvedAt: {_is_null: true}, rejectedAt: {_is_null: true}, expiresAt: {_gt: "now()"}}
  ) {
    aggregate {
      count
    }
  }
}
`);

export const GET_OUTREACH_SUGGESTION_BY_PK = gql(`
query GetOutreachSuggestionById($id: String!) {
  OutreachSuggestions_by_pk(id: $id) {
    Message {
      id
      direction
      conversationId
      prospectId
      channelType
      actionType
      contentType
      subjectLine
      content
      sentOn
      fullName
      approvedAt
      linkedinUserId
      prospectFullName
      }
    id
    email
    name
    accountName
    unread
    industry
    contactLocation
    prospectId
    linkedinUrl
  }
}
`);

// get all messages for a prospect
export const GET_PROSPECT_MESSAGES_HISTORY = gql(`
query GetProspectMessagesHistory($prospectId: String!) {
  Messages(where: {prospectId: {_eq: $prospectId}, sentOn: {_is_null: false}}, order_by: [{sentOn: asc_nulls_last}]) {
    id
    direction
    conversationId
    prospectId
    channelType
    actionType
    contentType
    subjectLine
    content
    sentOn
    fullName
    channelType
    linkedinUserId
    externalMessageId
    prospectFullName
    deliveryStatus
    Attachments {
        type
        value
      }
  }
}
`);

export const GET_PROSPECT_NOTES = gql(`
query GetProspectNotes($prospectId: String!) {
  Notes(where: {prospectId: {_eq: $prospectId}, discarded: {_eq: false}}, order_by: [{createdAt: asc_nulls_last}]) {
    id
    content
    prospectId
    title
    userId
    createdAt
    updatedAt
    creator
    happenedOn
  }
}
`);

export const GET_NOTE_BY_ID = gql(`
query GetNoteById($noteId: String!) {
  Notes(where: {id: {_eq: $noteId}, discarded: {_eq: false}}) {
    id
    content
    prospectId
    title
    userId
    createdAt
    updatedAt
    creator
  }
}
`);

export const GET_ALL_PROSPECT_SUBJECT_LINES = gql(`
query GetAllProspectSubjectLines($prospectId: String!) {
  Messages(where: {prospectId: {_eq: $prospectId}, sentOn: {_is_null: false}}, order_by: [{sentOn: desc_nulls_last}]) {
    id
    subjectLine
  }
}
`);

export const GET_LAST_PROSPECT_MESSAGE = gql(`
  query GetLastProspectMessage($prospectId: String!) {
    Messages(
      where: { prospectId: { _eq: $prospectId }, sentOn: { _is_null: false } }
      order_by: { sentOn: desc }
      limit: 1
    ) {
      id
      direction
      conversationId
      prospectId
      channelType
      actionType
      contentType
      subjectLine
      content
      sentOn
      fullName
      channelType
      linkedinUserId
      externalMessageId
      prospectFullName
      deliveryStatus
    }
  }
`);

export const GET_MESSAGES_SENT_FROM_APP = gql(`
query GetMessagesSentFromApp {
  Messages_aggregate(where: {isSentFromApp: {_eq: true}, sentOn: {_is_null: false}, direction: {_eq: "out"}}) {
    aggregate {
      count
    }
  }
}
`);

export const GET_ASSISTANT_SETTINGS = gql(`
    query GetAssistantSettings {
        AssistantSettings {
            id
            userId
            organizationId
            purpose
            language
            languages
            tone
            context
            createdAt
            updatedAt
        }
    }
`);

export const GET_GROUPS = gql(`
    query Groups {
        Groups(order_by: {createdAt: asc}) {
            id
            userId
            default
            name
            assistantSettingsId
            imgSrc
            createdAt
            updatedAt
        }
    }
`);

export const GET_USER_CONTACTS_BY_GROUP_IDS = gql(`
    query GetUserContactsByGroupIds($groupIds: [String!] $orderBy: [UserContacts_order_by!] = [{createdAt: desc}] ) {
        UserContacts(where: {_GroupToUserContacts: {Group: {id: {_in: $groupIds}}}}, order_by: $orderBy) {
            id
            prospectId
            status
            createdAt
            _GroupToUserContacts {
                Group {
                    id
                    name
                    imgSrc
                }
            }
        }
        totalCount: UserContacts_aggregate(where: {_GroupToUserContacts: {Group: {id: {_in: $groupIds}}}}) {
            aggregate {
                count
            }
        }
    }
`);

export const GET_USER_CONTACTS_PROSPECT_IDS_BY_GROUP_IDS = gql(`
    query GetUserContactsProspectIdsByGroupIds($groupIds: [String!] $orderBy: [UserContacts_order_by!] = [{createdAt: desc}] ) {
        UserContacts(where: {_GroupToUserContacts: {Group: {id: {_in: $groupIds}}}}, order_by: $orderBy) {
            prospectId
        }
    }
`);

export const GET_USER_CONTACT_BY_PROSPECT_ID = gql(`
    query GetUserContactByProspectId($prospectId: String!) {
        UserContacts(where: {prospectId: {_eq: $prospectId}}) {
            id
            userId
            prospectId
            createdAt
            updatedAt
            status
            _GroupToUserContacts {
                Group {
                    id
                    userId
                    name
                    default
                    assistantSettingsId
                    imgSrc
                    createdAt
                    updatedAt
                }
            }
        }
    }
`);

export const GET_TOTAL_NUMBER_OF_USER_CONTACTS = gql(`
    query GetTotalNumberOfUserContacts {
        UserContacts_aggregate {
            aggregate {
                count
            }
        }
    }
`);

export const GET_TOTAL_NUMBER_OF_USER_CONTACTS_IN_ANY_PLAYBOOK = gql(`
    query GetTotalNumberOfUserContactsInAnyPlaybook {
        UserContacts_aggregate(where: {_GroupToUserContacts: {Group: {id: {_is_null: false}}}}) {
            aggregate {
                count
            }
        }
    }
`);
export const GET_USER_CONTACT = gql(`
    query GetUserContactById($userContactId: String!) {
        UserContacts(where: {id: {_eq: $userContactId}}) {
            id
            userId
            prospectId
            createdAt
            updatedAt
            status
            _GroupToUserContacts {
                Group {
                    id
                    userId
                    name
                    default
                    assistantSettingsId
                    imgSrc
                    createdAt
                    updatedAt
                }
            }
        }
    }
`);
export const GET_USER_CONTACTS_BY_IDS = gql(`
query GetUserContactsByIds($ids: [String!]) {
        UserContacts(where: {id: {_in: $ids}}) {
            id
            userId
            prospectId
            createdAt
            updatedAt
            status
            _GroupToUserContacts {
                Group {
                    id
                    userId
                    name
                    default
                    assistantSettingsId
                    imgSrc
                    createdAt
                    updatedAt
                }
            }
        }
    }
`);

export const GET_ALL_USER_CONTACTS_IN_ANY_PLAYBOOK = gql(`
    query GetAllUserContactsInAnyPlaybook(
        $orderBy: [UserContacts_order_by!] = [{createdAt: desc}]
    ) {
        UserContacts(
            where: {_GroupToUserContacts: {Group: {id: {_is_null: false}}}}
            order_by: $orderBy
        ) {
            id
            prospectId
            status
            createdAt
            _GroupToUserContacts {
                Group {
                    id
                    name
                    imgSrc
                }
            }
        }
        totalCount: UserContacts_aggregate {
            aggregate {
                count
            }
        }
    }
`);

export const GET_ALL_USER_CONTACTS = gql(`
    query GetAllUserContacts(
        $orderBy: [UserContacts_order_by!] = [{createdAt: desc}]
    ) {
        UserContacts(
            order_by: $orderBy
        ) {
            id
            prospectId
            status
            createdAt
            _GroupToUserContacts {
                Group {
                    id
                    name
                    imgSrc
                }
            }
        }
        totalCount: UserContacts_aggregate(where: {_GroupToUserContacts: {Group: {id: {_is_null: false}}}}) {
            aggregate {
                count
            }
        }
    }
`);

// export const GET_USER_CONTACTS_BY_GROUP_IDS = gql(`
//     query GetAllUserContactsByGroupIds(
//         $groupIds: [String!],
//         $limit: Int! = 10,
//         $offset: Int! = 0,
//         $orderBy: [UserContacts_order_by!] = [{createdAt: desc}]
//     ) {
//         UserContacts(
//             limit: $limit,
//             offset: $offset,
//             order_by: $orderBy,
//             where: {
//                 groupId: {_in: $groupIds}
//             }
//         ) {
//             id
//             prospectId
//             status
//             _GroupToUserContacts {
//                 Group {
//                     id
//                     name
//                     imgSrc
//                 }
//             }
//         }
//         totalCount: UserContacts_aggregate {
//             aggregate {
//                 count
//             }
//         }
//     }
// `);

export const GET_ACTIONS_COUNT = gql(`
    query GetActionsCount($notYetSent: Boolean! = true, $scheduledOnLt: timestamp!) {
        OutreachSuggestions_aggregate(where: {Message: {sentOn: {_is_null: $notYetSent}}, scheduledOn: {_lt: $scheduledOnLt}}) {
          aggregate {
            count
          }
        }
    }
`);

export const GET_PLAYBOOKS = gql(`
    query GetPlaybooks($name: String) {
        Groups(where: {name: {_ilike: $name}}, order_by: {createdAt: asc}) {
            id
            userId
            default
            name
            assistantSettingsId
            imgSrc
            createdAt
            updatedAt
            AssistantSetting {
                id
                userId
                organizationId
                purpose
                language
                languages
                tone
                targetGroup
                context
                createdAt
                updatedAt
                defaultChannel
            }
        }
}
`);

export const GET_PLAYBOOK_BY_GROUP_ID = gql(`
    query GetPlaybookByGroupId($groupId: String!) {
        Groups(where: {id: {_eq: $groupId}}) {
            id
            userId
            default
            name
            assistantSettingsId
            imgSrc
            createdAt
            updatedAt
            AssistantSetting {
                id
                userId
                organizationId
                purpose
                language
                languages
                tone
                context
                targetGroup
                createdAt
                updatedAt
                defaultChannel
            }
            _GroupToUserContacts {
                UserContact {
                  id
                }
            }
        }
    }
`);

export const GET_PERFORMANCE_METRICS = gql(`
    query GetOutreachCompletionMetrics($startDate: timestamp!, $endDate: timestamp!, $organizationId: String!) {
        OutreachCompletionMetrics(
            where: { _and: [{ date: { _gte: $startDate } }, { date: { _lte: $endDate } }, { organizationId: { _eq: $organizationId } }] }
            order_by: { date: asc }
        ) {
            date
            trailingTotalCompleted
            trailingTotalGoalsReached
            trailingTotalSuggestions
            completedSameDay
            completedWithin2Days
            completedWithin3Days
            completedWithin7Days
            completedWithin30Days
            goalsReachedSameDay
            goalsReachedWithin2Days
            goalsReachedWithin3Days
            goalsReachedWithin7Days
            goalsReachedWithin30Days
            suggestionsSameDay
            suggestionsWithin2Days
            suggestionsWithin3Days
            suggestionsWithin7Days
            suggestionsWithin30Days
        }
        numberOfUsers: OutreachSuggestions_aggregate(distinct_on: userId) {
            aggregate {
              count
            }
        }
        numberOfUsersBeforePeriod: OutreachSuggestions_aggregate(distinct_on: userId, where: {createdAt: {_lt: $startDate}}) {
            aggregate {
              count
            }
        }
    }
`);
export const GET_DASHBOARD_METRICS = gql(`
query GetDashboardMetrics($startDate: timestamp!, $endDate: timestamp!, $previousStartDate: timestamp!, $organizationId: String!) {
  numberOfSentMessages: Messages_aggregate(where: {_and: [{organizationId: {_eq: $organizationId}},{direction: {_eq: "out"}}, {sentOn: {_is_null: false}}, {sentOn: {_gte: $startDate}}, {sentOn: {_lte: $endDate}}, {actionType: {_in: ["LINKEDIN_MESSAGE", "EMAIL_MESSAGE"]}}]}) {
    aggregate {
      count
    }
    nodes {
      prospectId
      isSentFromApp
      userId
      channelType
      sentOn
    }
  }
  numberOfReceivedMessages: Messages_aggregate(where: {_and: [{organizationId: {_eq: $organizationId}},{direction: {_eq: "in"}}, {sentOn: {_is_null: false}}, {sentOn: {_gte: $startDate}}, {sentOn: {_lte: $endDate}}, {actionType: {_in: ["LINKEDIN_MESSAGE", "EMAIL_MESSAGE"]}}]}) {
    aggregate {
      count
    }
    nodes {
      prospectId
      userId
      channelType
      sentOn
    }
  }
  numberOfNewConnections: Messages_aggregate(where: {_and: [{organizationId: {_eq: $organizationId}},{direction: {_eq: "in"}}, {sentOn: {_is_null: false}}, {sentOn: {_gte: $startDate}}, {sentOn: {_lte: $endDate}}, {actionType: {_eq: "LINKEDIN_CONNECTION_REQUEST_ACCEPTED"}}]}) {
    aggregate {
      count
    }
    nodes {
      prospectId
      prospectFullName
      userId
      channelType
      actionType
      sentOn
    }
  }
  numberOfSentMessagesPrev: Messages_aggregate(where: {_and: [{organizationId: {_eq: $organizationId}},{direction: {_eq: "out"}}, {sentOn: {_is_null: false}}, {sentOn: {_gte: $previousStartDate}}, {sentOn: {_lt: $startDate}}, {actionType: {_in: ["LINKEDIN_MESSAGE", "EMAIL_MESSAGE"]}}]}) {
    aggregate {
      count
    }
    nodes {
      prospectId
      isSentFromApp
      userId
      channelType
      sentOn
    }
  }
  numberOfReceivedMessagesPrev: Messages_aggregate(where: {_and: [{organizationId: {_eq: $organizationId}},{direction: {_eq: "in"}}, {sentOn: {_is_null: false}}, {sentOn: {_gte: $previousStartDate}}, {sentOn: {_lt: $startDate}}, {actionType: {_in: ["LINKEDIN_MESSAGE", "EMAIL_MESSAGE"]}}]}) {
    aggregate {
      count
    }
    nodes {
      prospectId
      userId
      channelType
      sentOn
    }
  }
  numberOfNewConnectionsPrev: Messages_aggregate(where: {_and: [{organizationId: {_eq: $organizationId}},{direction: {_eq: "in"}}, {sentOn: {_is_null: false}}, {sentOn: {_gte: $previousStartDate}}, {sentOn: {_lt: $startDate}}, {actionType: {_eq: "LINKEDIN_CONNECTION_REQUEST_ACCEPTED"}}]}) {
    aggregate {
      count
    }
    nodes {
      prospectId
      prospectFullName
      userId
      channelType
      actionType
      sentOn
    }
  }
}`);

export const GET_TEAM_DASHBOARD_METRICS = gql(`
query GetTeamDashboardMetrics($startDate: timestamp!, $endDate: timestamp!, $previousStartDate: timestamp!, $organizationId: String!) {
  numberOfSentMessages: Messages_aggregate(where: {_and: [{organizationId: {_eq: $organizationId}},{direction: {_eq: "out"}}, {sentOn: {_is_null: false}}, {sentOn: {_gte: $startDate}}, {sentOn: {_lte: $endDate}}, {actionType: {_in: ["LINKEDIN_MESSAGE", "EMAIL_MESSAGE"]}}]}) {
    aggregate {
      count
    }
    nodes {
      prospectId
      isSentFromApp
      userId
      channelType
      sentOn
    }
  }
  numberOfReceivedMessages: Messages_aggregate(where: {_and: [{organizationId: {_eq: $organizationId}},{direction: {_eq: "in"}}, {sentOn: {_is_null: false}}, {sentOn: {_gte: $startDate}}, {sentOn: {_lte: $endDate}}, {actionType: {_in: ["LINKEDIN_MESSAGE", "EMAIL_MESSAGE"]}}]}) {
    aggregate {
      count
    }
    nodes {
      prospectId
      userId
      channelType
      sentOn
    }
  }
  numberOfNewConnections: Messages_aggregate(where: {_and: [{organizationId: {_eq: $organizationId}},{direction: {_eq: "in"}}, {sentOn: {_is_null: false}}, {sentOn: {_gte: $startDate}}, {sentOn: {_lte: $endDate}}, {actionType: {_eq: "LINKEDIN_CONNECTION_REQUEST_ACCEPTED"}}]}) {
    aggregate {
      count
    }
    nodes {
      prospectId
      prospectFullName
      userId
      channelType
      actionType
      sentOn
    }
  }
  numberOfSentMessagesPrev: Messages_aggregate(where: {_and: [{organizationId: {_eq: $organizationId}},{direction: {_eq: "out"}}, {sentOn: {_is_null: false}}, {sentOn: {_gte: $previousStartDate}}, {sentOn: {_lt: $startDate}}, {actionType: {_in: ["LINKEDIN_MESSAGE", "EMAIL_MESSAGE"]}}]}) {
    aggregate {
      count
    }
    nodes {
      prospectId
      isSentFromApp
      userId
      channelType
      sentOn
    }
  }
  numberOfReceivedMessagesPrev: Messages_aggregate(where: {_and: [{organizationId: {_eq: $organizationId}},{direction: {_eq: "in"}}, {sentOn: {_is_null: false}}, {sentOn: {_gte: $previousStartDate}}, {sentOn: {_lt: $startDate}}, {actionType: {_in: ["LINKEDIN_MESSAGE", "EMAIL_MESSAGE"]}}]}) {
    aggregate {
      count
    }
    nodes {
      prospectId
      userId
      channelType
      sentOn
    }
  }
  numberOfNewConnectionsPrev: Messages_aggregate(where: {_and: [{organizationId: {_eq: $organizationId}},{direction: {_eq: "in"}}, {sentOn: {_is_null: false}}, {sentOn: {_gte: $previousStartDate}}, {sentOn: {_lt: $startDate}}, {actionType: {_eq: "LINKEDIN_CONNECTION_REQUEST_ACCEPTED"}}]}) {
    aggregate {
      count
    }
    nodes {
      prospectId
      prospectFullName
      userId
      channelType
      actionType
      sentOn
    }
  }
}`);

export const GET_MESSAGES_SENT_TODAY = gql(`
query GetMessagesSentToday($startDate: timestamp!, $endDate: timestamp!) {
  Messages_aggregate(where: {isSentFromApp: {_eq: true}, direction: {_eq: "out"}, sentOn: {_gte: $startDate, _lte: $endDate}}) {
    aggregate {
      count
    }
  }
}
`);
