import AddIcon from '@mui/icons-material/Add';
import { Typography } from '@mui/material';
import { batch, useDispatch } from 'react-redux-latest';
import { v4 as uuidv4 } from 'uuid';
import { Button } from '../../../../../components/atoms/button';
import { updateUpsalesSaveStatus } from '../../../../../redux/ducks/upsales/operations';
import { addCompanyField, addContactField, deleteField, updateField } from '../../../../../redux/ducks/upsales/slice';
import { AppDispatch, useAppSelector } from '../../../../../redux/store/configureStore';
import { disableUsedField } from '../../utils/fields';
import { ActivityType } from './activityType';
import { CampaignNameField } from './campaignNameField';
import { CompanyIndustryField } from './company-industry-field';
import { CompanySizeField } from './company-size-field';
import { FieldMappingContainer, MapperContainer } from './containers';
import { FieldMapHeader } from './fieldMapHeader';
import { LinkedInField } from './linkedInField';
import { LinkedinConnectionStatusField } from './linkedinConnectionStatusField';
import { ProgressBar } from './progressBar';
import { FieldSelector } from './selector/fieldSelector';
import { ZaplifyStatusField } from './zaplify-status-field';
import { companyFieldValidator, contactFieldValidator } from './utils';

export const FieldMap = () => {
    const dispatch = useDispatch<AppDispatch>();
    const fields = useAppSelector((state) => state.upsales.fieldMapping).map((field, idx) => ({ ...field, idx }));

    const companyFields = fields.filter((field) => field.companyField !== undefined);
    const contactFields = fields.filter((field) => field.contactField !== undefined);

    const availableContactFields = useAppSelector((state) => state.upsales.availableFields?.contact || []).map(
        (contactField) => disableUsedField(contactField, contactFields, 'contactField', 'key')
    );
    const availableProspectFields = useAppSelector((state) => state.upsales.availableFields?.prospect || []);
    const availableCompanyField = useAppSelector((state) => state.upsales.availableFields?.company || []).map(
        (companyField) => disableUsedField(companyField, companyFields, 'companyField', 'key')
    );

    const handleDeleteField = (index) => dispatch(deleteField({ index: index }));

    const leftOnChange = (e, index) => {
        batch(() => {
            dispatch(updateField({ idx: index, field: 'prospectField', value: e.target.value }));
            dispatch(updateUpsalesSaveStatus());
        });
    };

    const companyRightOnChange = (e, index) => {
        batch(() => {
            dispatch(updateField({ idx: index, field: 'companyField', value: e.target.value }));
            dispatch(updateUpsalesSaveStatus());
        });
    };

    const contactRightOnChange = (e, index) => {
        batch(() => {
            dispatch(updateField({ idx: index, field: 'contactField', value: e.target.value }));
            dispatch(updateUpsalesSaveStatus());
        });
    };

    const formattedProspectFields = availableProspectFields.map((field) => ({ name: field.key, label: field.name }));
    const formattedContactFields = availableContactFields.map((contactField) => ({
        name: contactField.key,
        label: contactField.name,
        disabled: contactField.disabled,
    }));

    const formattedCompanyFields = availableCompanyField.map((companyField) => ({
        name: companyField.key,
        label: companyField.name,
        disabled: companyField.disabled,
    }));

    const linkedInMappingExist = formattedContactFields.some(
        (field) => field.label.toLowerCase() === 'linkedin profile url'
    );
    const campaignNameMappingExist = formattedContactFields.some(
        (field) => field.label.toLowerCase() === 'zaplify campaign name'
    );
    const mapLinkedInConnectionExist = formattedContactFields.some(
        (field) => field.label.toLowerCase() === 'zaplify is linkedin connected'
    );
    const prospectStatusMappingExist = formattedContactFields.some(
        (field) => field.label.toLowerCase() === 'zaplify prospect status'
    );
    const sizeMappingExists = formattedCompanyFields.some((field) => field.label.toLowerCase() === 'company size');
    const industryMappingExists = formattedCompanyFields.some(
        (field) => field.label.toLowerCase() === 'company industry'
    );

    const addCompanyFieldHandle = () => {
        dispatch(addCompanyField());
        dispatch(updateUpsalesSaveStatus());
    };
    const addContactFieldHandle = () => {
        dispatch(addContactField());
        dispatch(updateUpsalesSaveStatus());
    };

    if (!availableContactFields.length) {
        return (
            <FieldMappingContainer>
                <ProgressBar show={!availableContactFields.length} />
            </FieldMappingContainer>
        );
    }

    return (
        <FieldMappingContainer>
            <FieldMapHeader />
            <Typography variant="body2">Contact</Typography>
            <MapperContainer>
                <div>
                    {contactFields.map((field) => (
                        <FieldSelector
                            key={uuidv4()}
                            field={contactFieldValidator(field)}
                            leftOptions={formattedProspectFields}
                            rightOptions={formattedContactFields}
                            leftOnChange={leftOnChange}
                            rightOnChange={contactRightOnChange}
                            handleDeleteField={handleDeleteField}
                        />
                    ))}
                    {!linkedInMappingExist && <LinkedInField />}
                    {!mapLinkedInConnectionExist && <LinkedinConnectionStatusField />}
                    {!campaignNameMappingExist && <CampaignNameField />}
                    {!prospectStatusMappingExist && <ZaplifyStatusField />}
                </div>
                <Button
                    borderRadius="100px"
                    variant="outlined"
                    height="40px"
                    startIcon={<AddIcon />}
                    text="Add field"
                    onClick={addContactFieldHandle}
                />
            </MapperContainer>
            <MapperContainer>
                <Typography variant="body2">Company</Typography>
                <div>
                    {companyFields.map((field) => (
                        <FieldSelector
                            key={uuidv4()}
                            field={companyFieldValidator(field)}
                            leftOptions={formattedProspectFields}
                            rightOptions={formattedCompanyFields}
                            leftOnChange={leftOnChange}
                            rightOnChange={companyRightOnChange}
                            handleDeleteField={handleDeleteField}
                        />
                    ))}
                    {!sizeMappingExists && <CompanySizeField />}
                    {!industryMappingExists && <CompanyIndustryField />}
                </div>
                <Button
                    borderRadius="100px"
                    variant="outlined"
                    height="40px"
                    startIcon={<AddIcon />}
                    text="Add field"
                    onClick={addCompanyFieldHandle}
                />
            </MapperContainer>
            <ActivityType />
            <Typography variant="body2">
                Don't see the property you're looking for? Create a new property in Upsales.
            </Typography>
            <Typography variant="body2">Outreach actions and status will be documented and saved as a note.</Typography>
        </FieldMappingContainer>
    );
};
