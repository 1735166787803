import { ICommand } from '@zaplify/common';
import { Topics } from '@zaplify/common';

export class DeleteUserCommand extends ICommand {
    public static override readonly TOPIC = Topics.USERS;
    constructor(
        readonly userId: string,
        readonly isDeletingOrganization?: boolean,
        readonly deleteOrganizationArg?: { id: string; domain: string },
    ) {
        super();
    }
}
