import { motion } from 'motion/react';

export const TypewriterText: React.FC<{ text: string; delay?: number }> = ({ text, delay = 0 }) => {
    return (
        <motion.span initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.01, delay }}>
            {text.split('').map((char, i) => (
                <motion.span
                    key={i}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.01, delay: delay + i * 0.02 }}
                >
                    {char}
                </motion.span>
            ))}
        </motion.span>
    );
};
