import React, { useEffect, useMemo, useState } from 'react';
import { motion } from 'motion/react';
import { cn } from '@shadcn/ui/lib/utils';
import { CheckIcon, ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon } from 'lucide-react';
import { useUser } from '../../../../hooks/use-user';
import { useFeedOnboarding } from '../hooks/use-feed-onboarding';
import { Button } from '@shadcn/ui/components/ui/button';
import { TypewriterText } from '../../../../components/typewriter-text';

export const OnboardingHeader: React.FC<{ onboardingCompleted: boolean }> = ({ onboardingCompleted }) => {
    const { firstName } = useUser();
    const { steps } = useFeedOnboarding();

    const scrollToBottom = () => {
        const scrollContainer = document.querySelector('.h-full.w-full.overflow-y-auto');
        if (scrollContainer) {
            scrollContainer.scrollTo({ top: scrollContainer.scrollHeight, behavior: 'smooth' });
        }
    };

    return (
        <div className="flex flex-col items-start w-full max-w-[800px] mx-auto p-4 gap-4">
            <h1 className="text-3xl font-semibold text-gray-900 mb-2">Setting up your Andsend experience</h1>
            <p className="text-gray-600 text-lg">
                <TypewriterText
                    text={`Hi ${firstName}, welcome to Andsend! We're setting up your experience based on your LinkedIn profile, your connections and website.`}
                    delay={0.5}
                />
            </p>

            <div className="grid grid-cols-3 gap-4 w-full">
                {Object.values(steps).map((step, index) => {
                    const isComplete = step.status === 'completed';
                    const isActive = step.status === 'in-progress';
                    const isPending = step.status === 'pending';

                    return (
                        <div
                            key={step.id}
                            className="flex flex-col items-start space-y-2 bg-background-primary-alt p-4 rounded-lg border border-border"
                        >
                            <span className="text-sm font-medium text-gray-900 flex items-center justify-between w-full">
                                <div className="flex items-center gap-2">{step.title}</div>
                                <div className="flex items-center gap-2">
                                    {isComplete && <CheckIcon className="w-4 h-4 text-green-500" />}
                                </div>
                            </span>
                            <div className="w-full bg-gray-200 h-1.5 rounded-full">
                                <motion.div
                                    className={cn('h-full rounded-full', isComplete ? 'bg-blue-500' : 'bg-blue-500')}
                                    initial={{ width: '0%' }}
                                    animate={{
                                        width: `${step.progress}%`,
                                    }}
                                    transition={{ duration: 1, delay: index * 0.4 }}
                                />
                            </div>
                            {isActive && <span className="text-xs text-gray-500">{step.description}</span>}
                            {isComplete && <span className="text-xs text-gray-500">Completed</span>}
                            {isPending && <span className="text-xs text-gray-500">Waiting to start</span>}
                        </div>
                    );
                })}
            </div>
            {onboardingCompleted && (
                <div className="flex justify-center w-full">
                    <Button
                        variant="default"
                        onClick={scrollToBottom}
                        className="px-3 py-2 rounded-full shadow-md gap-3 animate-fade-in transform transition-transform hover:scale-105"
                    >
                        <div className="h-2 w-2 bg-green-500 rounded-full" />
                        <span className="font-medium">Setup complete! Click to continue to Andsend</span>
                        <ChevronDownIcon className="h-5 w-5" />
                    </Button>
                </div>
            )}
        </div>
    );
};
