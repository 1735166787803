import { Route, Navigate } from 'react-router-dom';
import { RequireAuth } from '../require-auth';
import { DefaultTemplate } from '../../templates/default';
import { Component as Accounts } from '../../views/accounts';
import { paths } from '../paths';
import { Component as Integrations } from '../../views/integrations';
import { Component as NewConnectLinkedIn } from '../../views/onboarding/connect-linkedin';
import { Component as OnboardingAIPreferences } from '../../views/prompt-view/onboarding-ai-preferences';
import { Component as ImportView } from '../../views/prospecting/import-view';
import { Component as DiscoverView } from '../../views/prospecting/discover-view';
import { Component as ImportProspectsView } from '../../views/prospecting/import-prospects-view/import-prospects-view';
import { AuthTemplate } from '../../templates/auth';
import InAppTemplate from '../../templates/default/new/in-app-template-with-sidebar';

export const legacyAppRoutes = (
    <>
        <Route element={<DefaultTemplate />}>
            {/* Example of a legacy route */}
            <Route element={<RequireAuth requiredUserState={'loggedin_has_users_or_invitations'} />}>
                <Route path={paths.OLD_ACCOUNTS} element={<Accounts />} />
            </Route>

            {/* Redirect to new routes if needed */}
            <Route path={paths.PLAYBOOKS} element={<Navigate to={paths.NEW.PLAYBOOKS} replace />} />
            <Route
                path={`${paths.DISCOVER}/suggestions`}
                element={<Navigate to={paths.NEW.DISCOVER_PATHS.SUGGESTIONS} replace />}
            />
            <Route
                path={paths.ACCOUNT_MANAGEMENT + '/:view'}
                element={
                    <Navigate
                        to={
                            paths.NEW.ACCOUNT_MANAGEMENT_PATHS.ROOT +
                            '/' +
                            paths.NEW.ACCOUNT_MANAGEMENT_PATHS.SUBSCRIPTION
                        }
                        replace
                    />
                }
            />
        </Route>
        <Route element={<InAppTemplate />}>
            <Route
                // errorElement={<ErrorPage />}
                element={<RequireAuth requiredUserState={'loggedin_user_selected_and_fully_configured'} />}
            >
                <Route path={paths.DISCOVER} element={<DiscoverView />}>
                    <Route index element={<Navigate to={paths.NEW.DISCOVER_PATHS.SUGGESTIONS} replace />} />

                    <Route
                        path={paths.DISCOVER_PATHS.SEARCH}
                        element={<Navigate to={paths.NEW.DISCOVER_PATHS.SEARCH} replace />}
                    />
                </Route>
                <Route path={paths.IMPORT} element={<ImportView />}>
                    <Route index element={<Navigate to={paths.IMPORT_PATHS.LINKEDIN} replace />} />
                    <Route path={paths.IMPORT_PATHS.PROSPECTS} element={<ImportProspectsView />} />
                </Route>

                <Route path={paths.INTEGRATIONS + '/:app'} element={<Integrations />} />
                <Route path={paths.INTEGRATIONS} element={<Integrations />} />
            </Route>
        </Route>
        <Route element={<AuthTemplate hideExitButton />}>
            <Route
                // errorElement={<ErrorPage />}
                element={<RequireAuth requiredUserState={'loggedin_user_selected_and_linkedin_not_connected'} />}
            >
                <Route path={paths.ONBOARDING_CONNECT_LINKEDIN} element={<NewConnectLinkedIn />} />
            </Route>
            <Route
                // errorElement={<ErrorPage />}
                element={<RequireAuth requiredUserState={'loggedin_user_selected_and_aiprofile_not_configured'} />}
            >
                <Route path={paths.ONBOARDING_AI_PREFERENCES} element={<OnboardingAIPreferences />} />
            </Route>
        </Route>
        {/** End of protected routes HAS SELECTED USER */}
    </>
);
