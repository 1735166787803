import { ICommand, Topics } from '@zaplify/common';
import { UserMemoryDto } from '../dtos';

export class UpdateUserMemoryCommand extends ICommand<UserMemoryDto> {
    public static override readonly TOPIC = Topics.CAMPAIGNS;
    public readonly memoryId: string;
    public readonly memory: string;
    public readonly userId: string;

    constructor({ memoryId, memory, userId }: { memoryId: string; memory: string; userId: string }) {
        super();
        this.memoryId = memoryId;
        this.memory = memory;
        this.userId = userId;
    }
}
