import { FC } from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@shadcn/ui/components/ui/card';
import { Tooltip, TooltipProvider, TooltipTrigger, TooltipContent } from '@shadcn/ui/components/ui/tooltip';
import { cn } from '@shadcn/ui/lib/utils';

interface StatsCardProps {
    title: string;
    description?: string;
    currentValue: number;
    previousValue: number;
    periodName: string;
}

export const StatsCard: FC<StatsCardProps> = ({ title, description, currentValue, previousValue, periodName }) => {
    const difference = currentValue - previousValue;
    const isTrendingUp = difference > 0;
    const isNoChange = difference === 0;

    return (
        <Card className="w-full">
            <CardHeader>
                <div className="flex justify-between items-center">
                    <CardTitle className="text-sm font-medium">{title}</CardTitle>
                    <TooltipProvider>
                        <Tooltip delayDuration={0}>
                            <TooltipTrigger asChild>
                                {isTrendingUp ? (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="w-4 h-4 text-green-500 inline-block ml-1"
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 17l6-6 4 4 8-8" />
                                    </svg>
                                ) : isNoChange ? (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="w-4 h-4 text-gray-500 inline-block ml-1"
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 4h16" />
                                    </svg>
                                ) : (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="w-4 h-4 text-red-500 inline-block ml-1"
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 7l6 6 4-4 8 8" />
                                    </svg>
                                )}
                            </TooltipTrigger>
                            <TooltipContent>
                                <p>{isTrendingUp ? 'Trending up' : isNoChange ? 'No change' : 'Trending down'}</p>
                                {description && <p className="text-xs">{description}</p>}
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                </div>
            </CardHeader>

            <CardContent>
                <div className="text-2xl font-bold">{currentValue.toLocaleString()}</div>
                <p className={cn('text-xs text-muted-foreground')}>
                    {isTrendingUp
                        ? `+${difference.toLocaleString()} ${periodName}`
                        : isNoChange
                        ? `No change ${periodName}`
                        : `-${Math.abs(difference).toLocaleString()} ${periodName}`}
                </p>
            </CardContent>
        </Card>
    );
};
