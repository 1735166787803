import { LinkedinConversation, ProspectDataWithConnectionStatus, getZaplifySdk } from '@zaplify/sdk';
import { ContactSource } from '@zaplify/services/user-contacts/shared';
import { UpdateUserOrganizationDto } from '@zaplify/users/shared';
import LogRocket from 'logrocket';
import { useDispatch } from 'react-redux-latest';
import { companyNameCorrectlyCased } from '../../../../enums/company-name';
import useLiConversationsImport from '../../../chats/hooks/use-li-conversations-import';
import { useNotes } from '../../../chats/hooks/use-notes';
import { ProspectDataDto } from '@zaplify/prospects';
import { Creator } from '@zaplify/services/notes/shared';
import { AppDispatch, useAppSelector } from '../../../../redux/store/configureStore';
import { updateOrganization, refetchUserAndSubscription } from '../../../../redux/actions';
import { ConversationMessageParsed } from '@zaplify/web-extension-shared';
import { ConnectionStatus } from '@zaplify/campaigns';

export const useSetupUserProfile = () => {
    const dispatch: AppDispatch = useDispatch();
    const { saveNote } = useNotes();
    const userId = useAppSelector((state) => state.user.zaplifyUser.id);
    const {
        getLiConversations,
        fetchProfiles,
        getMessagesFromConversation,
        purchaseLinkedinProfiles,
        purchaseFromLinkedin,
    } = useLiConversationsImport();
    const sdk = getZaplifySdk();

    const fetchConversationsWithTimeout = async (limit: number): Promise<LinkedinConversation[]> => {
        const res = await getLiConversations(undefined, undefined, limit);
        const conversations = res.conversations || [];

        return [
            ...conversations.filter((c) => !c.conversation.isInMail),
            ...conversations.filter((c) => c.conversation.isInMail),
        ].slice(0, limit);
    };

    const getCompanyProfile = async (domain: string) => {
        const researchSummary = await sdk.profiles.messenger.researchCompanyByDomain(domain);
        return `My company profile:
${researchSummary.researchSummary}`;
    };

    const fetchConversationMessages = async (
        conversationId: string,
        messageLimit: number
    ): Promise<ConversationMessageParsed[]> => {
        try {
            return await getMessagesFromConversation(conversationId, messageLimit);
        } catch (error) {
            console.error('Error fetching conversation messages:', error);
            return [];
        }
    };

    const fetchConversationsBatch = async (
        conversations: LinkedinConversation[],
        batchSize: number = 5
    ): Promise<Array<{ memberId: string; messages: ConversationMessageParsed[] }>> => {
        const results: Array<{ memberId: string; messages: ConversationMessageParsed[] }> = [];

        for (let i = 0; i < conversations.length; i += batchSize) {
            const batch = conversations.slice(i, i + batchSize);
            const batchPromises = batch.map(async (conv) => {
                const messages = await fetchConversationMessages(conv.conversation.id, 10);
                return {
                    memberId: conv.prospect.memberId,
                    messages,
                };
            });

            const batchResults = await Promise.all(batchPromises);
            results.push(...batchResults);
        }

        return results;
    };

    const getRecentLinkedinConversations = async (
        CONVERSATIONS_LIMIT: number
    ): Promise<
        Array<{
            profile: ProspectDataWithConnectionStatus;
            connectionStatus: ConnectionStatus;
            conversation: ConversationMessageParsed[];
        }>
    > => {
        LogRocket.log(`Getting LI conversations`);

        try {
            const conversations = await fetchConversationsWithTimeout(CONVERSATIONS_LIMIT);
            if (!conversations.length) return [];

            const memberIds = conversations.map((c) => c.prospect.memberId);
            const profiles = await fetchProfiles(memberIds);

            const conversationMessages = await fetchConversationsBatch(conversations);

            return profiles.map((profile) => ({
                profile,
                connectionStatus: profile.connectionStatus,
                conversation: conversationMessages
                    .filter((msg) => msg.memberId === profile.linkedinUserId)
                    .flatMap((msg) => msg.messages),
            }));
        } catch (error) {
            console.error('onboarding: Error getting recent linkedin conversations', error);
            return [];
        }
    };

    const handleSaveSettings = async (userOrganizationName: string, domain: string, website: string) => {
        const organizationDTO: UpdateUserOrganizationDto = {
            name: userOrganizationName,
            domain,
            website,
        };
        await dispatch(updateOrganization(organizationDTO));
        await dispatch(refetchUserAndSubscription());
    };

    const addRefererAsContact = async () => {
        try {
            const sdk = getZaplifySdk();
            const refererLinkedinUserId = await sdk.profiles.user.getRefererLinkedinUserId();
            if (!refererLinkedinUserId) {
                LogRocket.log('No referer linkedin user id found');
                return;
            }
            LogRocket.log('Adding referer as contact', refererLinkedinUserId);
            const prospectIds = await purchaseFromLinkedin([refererLinkedinUserId]);
            if (!prospectIds?.length) {
                LogRocket.log('No prospect ids found');
            }
            LogRocket.log('Purchased referer from linkedin');
            const prospect = await sdk.profiles.prospects.getProspect(prospectIds[0]);
            const note = {
                title: '',
                content: `Since you signed up using ${prospect?.data.firstName}'s referral link, we've added them as a contact. Ask ${prospect?.data.firstName} about their experience with ${companyNameCorrectlyCased}, or thank them for referring you.`,
                prospectId: prospectIds[0],
                userId: userId,
                creator: Creator.System,
            };
            await saveNote(note);

            LogRocket.log('Added referer as contact');
        } catch (err) {
            console.log('Error adding referer as contact', err);
        }
    };

    const importRecentLiConversations = async (prospectData: ProspectDataDto[]) => {
        try {
            await purchaseLinkedinProfiles(prospectData, undefined, ContactSource.Onboarding);
            LogRocket.log('Imported recent LI conversations');
        } catch (error) {
            console.log('Error importing recent LI conversations', error);
        }
    };

    async function setupNewZaplifyUser(language: string) {
        return await sdk.profiles.campaigns.setupMessenger(language);
    }

    async function updateFirstPlaybookSettings({
        assistantSettingId,
        purpose,
        context,
        tone,
        language,
        targetGroup,
    }: {
        assistantSettingId: string;
        purpose: string;
        context: string;
        tone: string;
        language: string;
        targetGroup?: string;
    }) {
        await sdk.profiles.assistant.putAssistantSettings(
            {
                purpose,
                context,
                tone,
                targetGroup,
                languages: [language],
            },
            assistantSettingId
        );
        window.analytics?.identify({
            purpose_of_using_zaplify: purpose,
            outreach_language: language,
        });
    }

    return {
        handleSaveSettings,
        addRefererAsContact,
        importRecentLiConversations,
        setupNewZaplifyUser,
        getRecentLinkedinConversations,
        updateFirstPlaybookSettings,
        getCompanyProfile,
    };
};
