import { FC, useState } from 'react';
import { Button } from '@shadcn/ui/components/ui/button';
import { RefreshCw } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../providers/authentication-provider';

export const OfflinePage: FC = () => {
    const { refreshAuthToken } = useAuth();
    const [isRefreshing, setIsRefreshing] = useState(false);
    const navigate = useNavigate();
    const handleRefresh = async () => {
        try {
            setIsRefreshing(true);
            await refreshAuthToken();
            navigate(-1);
        } catch (err) {
            console.error('error occurred while refreshing auth token', err);
        } finally {
            setIsRefreshing(false);
        }
    };
    return (
        <div className="flex justify-center items-center">
            <div className="mt-16 p-4 text-center flex flex-col gap-4 w-fit">
                <div className="text-2xl">
                    <span role="img" aria-label="crescent moon">
                        🌙
                    </span>
                </div>
                <div className="text-lg font-medium leading-5 text-text-primary">Did you go offline?</div>
                <div className="text-sm leading-6 text-text-secondary">
                    It seems like you've lost connection with our servers
                    <br /> Please check your connection and hit the button below
                </div>

                <Button onClick={handleRefresh} disabled={isRefreshing}>
                    <RefreshCw className="w-5 h-5" />
                    Retry
                </Button>
            </div>
        </div>
    );
};
