import { userOrganizationActionTypes } from '../actionTypes';

const initialState = {
    blacklist: {},
    rewards: {},
};

const userOrganizationReducer = (state = initialState, action) => {
    switch (action.type) {
        case userOrganizationActionTypes.CHANGE_BLOCKLIST:
            return {
                ...state,
                blacklist: {
                    ...state.blacklist,
                    [action.firstLetter]: {
                        ...state.blacklist[action.firstLetter],
                        [action.name]: { name: action.name, deleted: action.deleted },
                    },
                },
            };
        case userOrganizationActionTypes.UPDATE_BLACKLIST:
            return {
                ...state,
                blacklist: action.blacklist,
            };
        case userOrganizationActionTypes.UPDATE_REWARDS:
            return {
                ...state,
                rewards: action.rewards,
            };
        default:
            return { ...state };
    }
};

export default userOrganizationReducer;
