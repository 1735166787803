import { useMemo } from 'react';
import { useUser } from './use-user';
import { useSdk } from '../sdk';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { OwnersDto } from '@zaplify/prospects';

const checkIsOwnedByUser = (owners: OwnersDto, userId: string) => {
    if (!owners) {
        return false;
    }

    const existingOwners = {
        ...owners?.linkedinUrlsMap,
        ...owners?.personIdsMap,
        ...owners?.prospectIdsMap,
    };
    if (existingOwners) {
        for (const [_, owners] of Object.entries(existingOwners)) {
            if (owners.some((owner) => owner.userId === userId)) {
                return true;
            }
        }
    }
    return false;
};

export const useGetContactOwners = () => {
    const { userId } = useUser();
    const queryClient = useQueryClient();
    const {
        prospectOwners: { getOwners },
    } = useSdk();

    const getIsContactOwnedByUser = async ({
        linkedinUrl,
        personId,
        prospectId,
    }: {
        linkedinUrl?: string;
        personId?: string;
        prospectId?: string;
    }) => {
        if (!linkedinUrl && !personId && !prospectId) {
            return false;
        }

        try {
            const owners = await queryClient.fetchQuery(
                getOwners({
                    linkedinUrls: linkedinUrl ? [linkedinUrl] : [],
                    personIds: personId ? [personId] : [],
                    prospectIds: prospectId ? [prospectId] : [],
                })
            );

            return checkIsOwnedByUser(owners, userId);
        } catch (error) {
            console.error(error);
            return false;
        }
    };

    return { getIsContactOwnedByUser };
};

export const useContactOwners = ({
    personId,
    linkedinUrl,
    prospectId,
}: {
    personId?: string;
    linkedinUrl?: string;
    prospectId?: string;
}) => {
    const {
        prospectOwners: { getOwners },
    } = useSdk();
    const { userId } = useUser();

    const {
        data: owners,
        isLoading: isLoadingOwners,
        refetch: refetchOwners,
    } = useQuery(
        getOwners({
            prospectIds: [prospectId],
            personIds: [personId],
            linkedinUrls: [linkedinUrl],
        })
    );

    const isOwnedByUser = useMemo(() => checkIsOwnedByUser(owners, userId), [owners, userId]);

    const uniqueOwners = useMemo(() => {
        if (!owners) {
            return [];
        }

        const existingOwners = {
            ...owners?.linkedinUrlsMap,
            ...owners?.personIdsMap,
            ...owners?.prospectIdsMap,
        };

        if (!existingOwners) {
            return [];
        }

        return Object.values(existingOwners)
            .flat()
            .filter((o, index, self) => index === self.findIndex((t) => t.userId === o.userId));
    }, [owners]);

    const otherOwnersInOrganization = useMemo(
        () => uniqueOwners.filter((o) => o.userId !== userId),
        [uniqueOwners, userId]
    );

    return { isOwnedByUser, isLoadingOwners, otherOwnersInOrganization, refetchOwners };
};
