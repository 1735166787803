import { Tooltip, TooltipTrigger, TooltipContent } from '@shadcn/ui/components/ui/tooltip';
import { ProspectDataExperienceDto } from '@zaplify/prospects';
import moment from 'moment';

export const WorkExperience = ({ experience }: { experience: ProspectDataExperienceDto }) => {
    function getExperienceDateRange(experience: ProspectDataExperienceDto) {
        let dateString = '';
        if (experience.occupationStartDate && new Date(experience.occupationStartDate).toString() !== 'Invalid Date') {
            const date = moment(new Date(experience.occupationStartDate).toISOString()).format('MMM YYYY');
            dateString += date + ' - ';
        }
        if (experience.occupationEndDate && new Date(experience.occupationEndDate).toString() !== 'Invalid Date') {
            const date = moment(new Date(experience.occupationEndDate).toISOString()).format('MMM YYYY');
            dateString += date;
        } else {
            dateString += 'Present';
        }

        return dateString;
    }

    return (
        <div className="flex items-start gap-3">
            <div className="flex flex-col gap-1">
                <span className="text-sm font-medium">
                    {experience.organizationDomain ? (
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <a href={'https://' + experience.organizationDomain} target="_blank" rel="noreferrer">
                                    {experience.organizationName}
                                </a>
                            </TooltipTrigger>
                            <TooltipContent>Go to company website</TooltipContent>
                        </Tooltip>
                    ) : (
                        experience.organizationName
                    )}
                </span>
                <span className="text-sm font-normal">{experience.occupationTitle}</span>
                <span className="text-sm text-text-tertiary">{getExperienceDateRange(experience)}</span>
            </div>
        </div>
    );
};
