import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

type Props = {
    props?: string;
};

export const InfoIcon: React.FC<Props & React.SVGProps<SVGSVGElement>> = ({ ...props }: Props) => (
    <SvgIcon {...props} viewBox="0 0 18 19">
        <path d="M8.99962 0C4.02971 0 0 4.02971 0 8.99962C0 13.9695 4.02971 18 8.99962 18C13.9695 18 18 13.9695 18 8.99962C18 4.02971 13.9695 0 8.99962 0ZM10.8731 13.9482C10.4099 14.131 10.0411 14.2697 9.76457 14.3657C9.48876 14.4617 9.168 14.5097 8.80305 14.5097C8.24229 14.5097 7.80571 14.3726 7.49486 14.099C7.184 13.8255 7.02933 13.4789 7.02933 13.0575C7.02933 12.8937 7.04076 12.7261 7.06362 12.5554C7.08724 12.3848 7.12457 12.1928 7.17562 11.9771L7.75543 9.92914C7.80648 9.73257 7.85067 9.54591 7.88571 9.37219C7.92076 9.19695 7.93752 9.03619 7.93752 8.8899C7.93752 8.62933 7.88343 8.44648 7.776 8.34362C7.66705 8.24076 7.4621 8.19048 7.15657 8.19048C7.00724 8.19048 6.85333 8.21257 6.69562 8.25905C6.53943 8.30705 6.40381 8.35048 6.29257 8.39314L6.44571 7.76229C6.82514 7.60762 7.18857 7.47505 7.53524 7.36533C7.88191 7.2541 8.20952 7.19924 8.5181 7.19924C9.07505 7.19924 9.50476 7.33486 9.80724 7.60305C10.1082 7.872 10.2598 8.22171 10.2598 8.65143C10.2598 8.74057 10.2491 8.89752 10.2286 9.12152C10.208 9.34629 10.1691 9.55124 10.1128 9.73943L9.536 11.7813C9.48876 11.9451 9.44686 12.1326 9.40876 12.3421C9.37143 12.5516 9.35314 12.7116 9.35314 12.819C9.35314 13.0903 9.41333 13.2754 9.53524 13.3737C9.65562 13.472 9.86667 13.5215 10.1653 13.5215C10.3063 13.5215 10.464 13.4964 10.6423 13.4476C10.819 13.3989 10.947 13.3554 11.0278 13.3181L10.8731 13.9482ZM10.771 5.66019C10.5021 5.9101 10.1783 6.03505 9.79962 6.03505C9.42171 6.03505 9.09562 5.9101 8.82438 5.66019C8.55467 5.41029 8.41829 5.10629 8.41829 4.75124C8.41829 4.39695 8.55543 4.09219 8.82438 3.84C9.09562 3.58705 9.42171 3.46133 9.79962 3.46133C10.1783 3.46133 10.5029 3.58705 10.771 3.84C11.04 4.09219 11.1749 4.39695 11.1749 4.75124C11.1749 5.10705 11.04 5.41029 10.771 5.66019Z" />
    </SvgIcon>
);
