import { FC, Suspense, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { AccountManagementHeader } from './components/account-management-header';
import { AccountManagementNav } from './components/account-management-nav';
import { useSidebars } from '@shadcn/ui/hooks/use-sidebars';
import { cn } from '@shadcn/ui/lib/utils';

export const AccountManagementPage: FC = () => {
    const {
        toggleSidebar,
        sidebarState: { main },
    } = useSidebars();

    useEffect(() => {
        if (main) {
            toggleSidebar('main');
        }
        return () => {
            if (!main) {
                toggleSidebar('main');
            }
        };
    }, [toggleSidebar, main]);

    return (
        <div className="flex h-full max-w-full overflow-hidden">
            <div className="flex-1 flex flex-col min-w-0 overflow-hidden">
                <AccountManagementHeader title="Account Management" />

                <div className="flex flex-1 min-h-0 overflow-hidden">
                    <div className="w-64 md:min-w-[250px] border-r overflow-hidden flex-shrink-0 hidden md:block">
                        <AccountManagementNav />
                    </div>
                    <div className="flex-1 min-w-0 overflow-auto p-6 bg-background-secondary">
                        <Suspense fallback={<div>Loading...</div>}>
                            <Outlet />
                        </Suspense>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccountManagementPage;
