import { UseMutationOptions, useQuery, UseQueryResult, UseSuspenseQueryOptions } from '@tanstack/react-query';
import { UseQueryOptions } from '@tanstack/react-query';
import { getZaplifySdk } from '@zaplify/sdk';
import {
    CompletedTaskDataDto,
    RecurringTaskDto,
    RecurringTaskStatus,
    TaskDataDto,
    TaskName,
} from '@zaplify/services/data-source-sync/shared';
import { createMutationOptions } from './sdk-utils';

const fetchTasks = (workerId: string) => {
    return getZaplifySdk().profiles.dataSourcesSync.getTasks(workerId);
};

type GetTasksReturnType = Awaited<ReturnType<typeof fetchTasks>>;

export const getTasks = (
    workerId: string,
    options?: Omit<UseQueryOptions<GetTasksReturnType, Error>, 'queryKey' | 'queryFn'>
): UseQueryOptions<GetTasksReturnType, Error> => {
    return {
        queryKey: ['getLinkedInConversationSyncTask'],
        queryFn: () => fetchTasks(workerId),
        ...options,
    };
};

export const startTask = (
    options?: Omit<
        UseMutationOptions<RecurringTaskDto, Error, { workerId: string; taskName: TaskName }>,
        'mutationFn' | 'invalidateQueries'
    >
) => {
    return createMutationOptions<RecurringTaskDto, Error, { workerId: string; taskName: TaskName }>({
        mutationFn: (variables) =>
            getZaplifySdk().profiles.dataSourcesSync.startTask(variables.workerId, variables.taskName),
        ...options,
    });
};

export const completeTask = (
    options?: Omit<
        UseMutationOptions<void, Error, { data: CompletedTaskDataDto; workerId: string }>,
        'mutationFn' | 'invalidateQueries'
    >
) => {
    return createMutationOptions<void, Error, { data: CompletedTaskDataDto; workerId: string }>({
        mutationFn: (variables) => {
            return getZaplifySdk().profiles.dataSourcesSync.completeTask(variables.data, variables.workerId);
        },
        invalidateQueries: (data) => [['getLinkedInConversationSyncTask']],
        ...options,
    });
};

export const startFetchProfileTask = (
    options?: Omit<
        UseMutationOptions<void, Error, { workerId: string; memberId: string }>,
        'mutationFn' | 'invalidateQueries'
    >
) => {
    return createMutationOptions<void, Error, { workerId: string; memberId: string }>({
        mutationFn: (variables) =>
            getZaplifySdk().profiles.dataSourcesSync.startFetchProfileTask(variables.workerId, variables.memberId),
        ...options,
    });
};

export const completeFetchProfileTask = (
    options?: Omit<
        UseMutationOptions<void, Error, { workerId: string; memberId: string; taskData: CompletedTaskDataDto }>,
        'mutationFn' | 'invalidateQueries'
    >
) => {
    return createMutationOptions<void, Error, { workerId: string; memberId: string; taskData: CompletedTaskDataDto }>({
        mutationFn: (variables) =>
            getZaplifySdk().profiles.dataSourcesSync.completeFetchProfileTask(
                variables.workerId,
                variables.memberId,
                variables.taskData
            ),
        ...options,
    });
};
