import { companyNameCorrectlyCased } from '../../../../../enums/company-name';
import { FieldMapping } from '../../../../../redux/ducks/pipedrive/slice';

enum FieldKeysEnum {
    PERSON = 'personField',
    ORGANIZATION = 'organizationField',
}

export const contactFieldValidator = (field: FieldMapping): FieldMapping => {
    return fieldValidator(field, FieldKeysEnum.PERSON);
};

export const companyFieldValidator = (field: FieldMapping): FieldMapping => {
    return fieldValidator(field, FieldKeysEnum.ORGANIZATION);
};

const fieldValidator = (field: FieldMapping, key: FieldKeysEnum): FieldMapping => {
    if (field.prospectField === '' && field[key] === '') {
        field.errorText = 'Please select fields.';
    } else if (field.prospectField === '' && field[key] !== '') {
        field.errorText = `Please select ${companyNameCorrectlyCased} field column.`;
    } else if (field.prospectField !== '' && field[key] === '') {
        field.errorText = 'Please select Pipedrive field column.';
    }
    return field;
};
