import { Briefcase, Linkedin, Mail, MapPin, Users } from 'lucide-react';
import { Avatar, AvatarFallback, AvatarImage } from '@shadcn/ui/components/ui/avatar';
import { getInitials } from '../../../functions/get-initials';
import { Tooltip, TooltipContent, TooltipTrigger } from '@shadcn/ui/components/ui/tooltip';
import { AccountLogo } from '../../account-logo';
import { AddContactButton } from '../../buttons/add-contact-button';
import { ContactOwnersAvatarDisplay } from './contact-owners-avatar-display';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useSdk } from '../../../sdk';
import { ContactSource } from '@zaplify/services/user-contacts/shared';
import { ensureHttps } from '../../../../helpers/email';

export function ProspectPreview({ prospectId }: { prospectId: string }) {
    const {
        prospect: { getProspectById },
        account: { getAccountById },
    } = useSdk();

    const { data: prospect, isLoading } = useQuery(getProspectById(prospectId));
    const { data: account } = useQuery(getAccountById(prospect?.data?.organizationId));

    if (isLoading || !prospect) return null;

    const accountData = useMemo(
        () =>
            account
                ? {
                      name: account.data.name,
                      industry: account.data.industry,
                      website: account.data.website,
                      size: account.data.size,
                  }
                : {
                      name: prospect.data.organizationName,
                      industry: '',
                      website: prospect.data.organizationDomain,
                      size: prospect.data.organizationSize,
                  },
        [
            account?.data.name,
            account?.data.industry,
            account?.data.website,
            account?.data.size,
            prospect.data.organizationName,
            prospect.data.organizationDomain,
            prospect.data.organizationSize,
        ]
    );

    const prospectData = prospect.data;
    const previewData = useMemo(
        () => ({
            personId: prospectData.personId,
            prospectId,
            linkedinUrl: prospectData.linkedinProfileUrl,
            linkedinProfileImgUrl: prospectData.linkedinProfileImgUrl,
            fullName: prospectData.fullName,
            occupationTitle: prospectData.occupationTitle,
            location: prospectData.location,
            skills: prospectData.skills,
            account: accountData,
        }),
        [
            prospectData.personId,
            prospectId,
            prospectData.linkedinProfileUrl,
            prospectData.linkedinProfileImgUrl,
            prospectData.fullName,
            prospectData.occupationTitle,
            prospectData.location,
            prospectData.skills,
            accountData,
        ]
    );

    return <PersonDetailCard {...previewData} />;
}

interface PersonPreviewProps {
    personId: string;
    prospectId: string;
    linkedinUrl: string;
    linkedinProfileImgUrl: string;
    fullName: string;
    occupationTitle: string;
    location: string;
    skills: string[];
    account: AccountPreviewProps;
}

interface AccountPreviewProps {
    name: string;
    industry: string;
    website: string;
    size: string;
}

export function PersonDetailCard(person: PersonPreviewProps) {
    if (!person) return null;

    return (
        <div className="flex flex-col h-full p-2 max-h-full">
            <div className="flex-1 min-h-0 overflow-y-auto space-y-8">
                <Header person={person} />
                <Account account={person.account} />
                <PersonDetails person={person} />
            </div>
            <div className="flex-none pt-4 w-full">
                {person.personId ? (
                    <AddContactButton personId={person.personId} source={ContactSource.GlobalSearch} />
                ) : (
                    <AddContactButton linkedinUrl={person.linkedinUrl} source={ContactSource.GlobalSearch} />
                )}
            </div>
        </div>
    );
}

const Header = ({ person }: { person: PersonPreviewProps }) => {
    return (
        <div className="flex items-center gap-4">
            <Avatar className="w-12 h-12">
                <AvatarImage src={person.linkedinProfileImgUrl} />
                <AvatarFallback>{getInitials(person.fullName)}</AvatarFallback>
            </Avatar>

            <div>
                <h3 className="text-xl font-medium">{person.fullName}</h3>
                <p className="text-muted-foreground">{person.occupationTitle}</p>
            </div>
        </div>
    );
};

const Account = ({ account }: { account: AccountPreviewProps }) => {
    return (
        <div className="space-y-4">
            <h4 className="text-sm font-medium text-muted-foreground">COMPANY</h4>
            <div className="flex items-center gap-4">
                <AccountLogo accountWebsite={account?.website} className="w-12 h-12" />
                <div>
                    <Tooltip delayDuration={300}>
                        <TooltipTrigger asChild>
                            <a
                                href={ensureHttps(account.website)}
                                className="font-medium hover:underline"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {account.name}
                            </a>
                        </TooltipTrigger>
                        <TooltipContent>Go to company website</TooltipContent>
                    </Tooltip>
                    <p className="text-sm text-muted-foreground">{account.industry}</p>
                </div>
            </div>
            {account.size && (
                <div className="flex items-start gap-3">
                    <Users className="h-5 w-5 stroke-[1]" />
                    <span className="text-sm">{account.size}</span>
                </div>
            )}
        </div>
    );
};

const PersonDetails = ({ person }: { person: PersonPreviewProps }) => {
    const { personId, linkedinUrl, prospectId } = person;

    return (
        <div className="space-y-4">
            <h4 className="text-sm font-medium text-muted-foreground">PROSPECT DETAILS</h4>
            <div className="space-y-4">
                <div className="flex items-center gap-3">
                    <Briefcase className="h-5 w-5 stroke-[1]" />
                    <span>
                        {person.occupationTitle} at {person.account.name}
                    </span>
                </div>
                <div className="flex items-center gap-3">
                    <MapPin className="h-5 w-5 stroke-[1]" />
                    <span>{person?.location}</span>
                </div>
                <div className="flex items-center gap-3">
                    <Linkedin className="h-5 w-5 stroke-[1]" />
                    <span className="text-muted-foreground bg-gray-100 rounded-2xl px-2 py-1">•••••••••</span>
                </div>
                <div className="flex items-center gap-3">
                    <Mail className="h-5 w-5 stroke-[1]" />
                    <span className="text-muted-foreground bg-gray-100 rounded-2xl px-2 py-1 ">•••••••••••••••</span>
                </div>
                <ContactOwnersAvatarDisplay
                    personId={personId}
                    linkedinUrl={linkedinUrl}
                    prospectId={prospectId}
                    avatarSize="md"
                />
            </div>
        </div>
    );
};
