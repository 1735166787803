import { IUntypedCommand, Topics } from '@zaplify/common';
import { ProspectLinkedinProfileVerifiedStatus } from '../prospects';

export type SetProspectLinkedinDataPayload =
    | {
          linkedinProfileVerifiedStatus: ProspectLinkedinProfileVerifiedStatus.verified;
          checkedAt: Date;
          linkedinUserId: string;
          linkedinProfileImgUrl?: string;
          linkedinProfileUrl?: string;
      }
    | { linkedinProfileVerifiedStatus: ProspectLinkedinProfileVerifiedStatus.profile_not_found; checkedAt: Date };

export class SetProspectLinkedinDataCommand extends IUntypedCommand {
    public static override readonly TOPIC = Topics.PROSPECTS;
    constructor(readonly prospectId: string, readonly userId: string, readonly input: SetProspectLinkedinDataPayload) {
        super();
    }
}
