import makeStyles from '@mui/styles/makeStyles';

export const styles = makeStyles(() => ({
    root: {
        position: 'relative !important',
        top: '58px !important',
    },
    paper: {
        top: '65px',
        width: '530px',
        border: '1px solid #E4E6EB',
        background: '#F7F8FA',
        borderTopLeftRadius: '14px',
        borderBottomLeftRadius: '14px',
    },
    titleButtonWrapper: {
        display: 'flex',
        padding: '9px 37px',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #E4E6EB',
        background: '#fff',
    },
    title: {
        fontSize: '19px',
        fontWeight: 500,
        letterSpacing: '0px',
        color: '#23323F',
    },
    titleSmaller: {
        color: '#3A4B59',
        fontSize: '17px',
        fontWeight: 500,
    },
    descriptionWrapper: {
        padding: '12px 37px',
        borderBottom: '1px solid #E4E6EB',
        fontSize: '14px',
        lineHeight: '21px',
        background: '#fff',
    },
    description: {
        color: '#3A4B59',
    },
    descriptionLink: {
        color: '#5F8EFA',
        textDecoration: 'none',
    },
    iconButton: {
        padding: '0 !important',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    blocklistWrapper: {
        backgroundColor: '#F7F8FA',
        height: 'calc(100% - 55px)',
    },
    actionsWrapper: {
        backgroundColor: '#F7F8FA',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-evenly',
        fontWeight: '500 !important',
        padding: '20px 97px 15px',
    },
    buttonInputWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: '10px',
    },
    buttonBlue: {
        width: '96px !important',
    },
    textButtonWrapper: {
        borderBottom: '1px solid #E4E6EB',
        display: 'flex',
        justifyContent: 'space-around',
        padding: '21px 26px',
    },
    blockedWrapper: {
        backgroundColor: '#fff',
        border: '1px solid #E4E6EB',
        borderRadius: '8px',
        margin: '0 20px',
        alignItems: 'center',
        '& ul': {
            paddingBottom: 0,
        },
    },
    textBlocked: {
        fontSize: '14px',
        color: '#6B7985',
    },
    listWrapper: {},
    textWrapper: {},
}));

export const positionAtTopStyles = makeStyles(() => ({
    root: {
        position: 'relative !important',
    },
    paper: {
        width: '530px',
        marginTop: '8px',
        height: 'calc(100% - 16px)',
        border: '1px solid #E4E6EB',
        background: '#F7F8FA',
        borderTopLeftRadius: '14px',
        borderBottomLeftRadius: '14px',
    },
    titleButtonWrapper: {
        display: 'flex',
        padding: '9px 37px',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #E4E6EB',
        background: '#fff',
    },
    title: {
        fontSize: '19px',
        fontWeight: 500,
        letterSpacing: '0px',
        color: '#23323F',
    },
    titleSmaller: {
        color: '#3A4B59',
        fontSize: '17px',
        fontWeight: 500,
    },
    descriptionWrapper: {
        padding: '12px 37px',
        borderBottom: '1px solid #E4E6EB',
        fontSize: '14px',
        lineHeight: '21px',
        background: '#fff',
    },
    description: {
        color: '#3A4B59',
    },
    descriptionLink: {
        color: '#5F8EFA',
        textDecoration: 'none',
    },
    iconButton: {
        padding: '0 !important',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    blocklistWrapper: {
        backgroundColor: '#F7F8FA',
        height: 'calc(100% - 55px)',
    },
    actionsWrapper: {
        backgroundColor: '#F7F8FA',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-evenly',
        fontWeight: '500 !important',
        padding: '20px 97px 15px',
    },
    buttonInputWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: '10px',
    },
    buttonBlue: {
        width: '96px !important',
    },
    textButtonWrapper: {
        borderBottom: '1px solid #E4E6EB',
        display: 'flex',
        justifyContent: 'space-around',
        padding: '21px 26px',
    },
    blockedWrapper: {
        backgroundColor: '#fff',
        border: '1px solid #E4E6EB',
        borderRadius: '8px',
        margin: '0 20px',
        alignItems: 'center',
        '& ul': {
            paddingBottom: 0,
        },
    },
    textBlocked: {
        fontSize: '14px',
        color: '#6B7985',
    },
    listWrapper: {},
    textWrapper: {},
}));
