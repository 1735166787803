import { ReauthorizeChannelAccountDto, ChannelAccountDto, LinkedinDto } from '@zaplify/channel-accounts/shared';
import { BaseSdk } from '../../base.sdk';

export class channelAccountSdk extends BaseSdk {
    async getMyAccounts() {
        try {
            const accounts = await this.get<ChannelAccountDto[]>(`${this.channelAccountPath}`);
            return accounts || [];
        } catch (err) {
            console.error('error while fetching channel accounts', err);
            return [];
        }
    }

    async getLinkedinChannelAccount() {
        return this.get<ChannelAccountDto>(`${this.channelAccountPath}/linkedin`);
    }

    async reauthorizeChannelAccount(
        channelAccountId: string,
        reauthorizeChannelAccountDto: ReauthorizeChannelAccountDto,
    ) {
        return this.post<ChannelAccountDto>({
            path: `${this.channelAccountPath}/${channelAccountId}/reauthorize`,
            payload: reauthorizeChannelAccountDto,
        });
    }

    async updateSessionCookie(channelAccountId: string, sessionCookie: string, linkedinUserId: string, log?: string) {
        return this.post<void>({
            path: `${this.channelAccountPath}/${channelAccountId}/update-cookie`,
            payload: {
                sessionCookie,
                linkedinUserId,
                log,
            },
        });
    }

    async createLinkedinChannelAccount(
        LINKEDIN: Required<Omit<LinkedinDto, 'proxy' | 'numberOfConnections' | 'restriction'>>,
        name: string,
    ) {
        return this.post<ChannelAccountDto>({
            path: `${this.channelAccountPath}/linkedin`,
            payload: {
                LINKEDIN,
                name: name,
            },
        });
    }

    async updateLinkedinAvatarUrl(channelAccountId: string, avatarUrl: string) {
        return this.patch<void>(`${this.channelAccountPath}/${channelAccountId}/linkedin-avatar-url`, {
            avatarUrl,
        });
    }

    async updateLinkedinPremiumStatus(channelAccountId: string, isPremium: boolean) {
        return this.patch<void>(`${this.channelAccountPath}/${channelAccountId}`, {
            isCurrentlyLinkedinPremium: isPremium,
        });
    }

    async updateEmailSignature(channelAccountId: string, signature: string) {
        return this.patch<void>(`${this.channelAccountPath}/${channelAccountId}/email-signature`, {
            signature,
        });
    }

    async resyncGmailSignature(channelAccountId: string): Promise<{ signature: string }> {
        return this.patch<{ signature: string }>(
            `${this.channelAccountPath}/${channelAccountId}/email-signature/resync`,
        );
    }
}
