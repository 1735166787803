import { Badge } from '@shadcn/ui/components/ui/badge';
import * as React from 'react';

export const ReEngageBadge: React.FC<{}> = () => {
    return (
        <Badge variant="blue">
            <div className="flex flex-row gap-2 items-center">
                <div className="w-1.5 h-1.5 rounded-full bg-blue-utility-700" />
                Re-engage
            </div>
        </Badge>
    );
};
