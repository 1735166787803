import { Drawer, IconButton, Typography } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import { ProspectSearchAutoCompleteFields } from '@zaplify/prospects';
import { getZaplifySdk } from '@zaplify/sdk';
import { Button, GuardedAnchor } from '@zaplify/ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux-latest';
import { BLOCKLIST_LIMIT } from '../../config';
import { getBlocklistByFirstCharacter, getFlatBlocklist } from '../../helpers/blocklist';
import { debounce } from '../../helpers/debounce';
import {
    deleteAllCompaniesFromBlocklist,
    displayBlocklistModal,
    getBlacklist,
    handleErrorNotification,
    handleSuccessNotification,
    handleWarningNotification,
    updateBlocklistCompany,
} from '../../redux/actions';
import { AppDispatch, useAppSelector } from '../../redux/store/configureStore';
import CloseIcon from '../icons/close';
import PlusIcon from '../icons/plus';
import UploadListIcon from '../icons/uploadList';
import ListWithFirstLetters from '../list-with-first-letters/list-with-first-letters';
import { ConfirmationModal } from '../molecules/confirmationModal';
import BlacklistModal from '../organisms/blacklistModal';
import SearchWithIcon from '../search-with-icon/search-with-icon';
import Search from '../search/Search';
import { styles, positionAtTopStyles } from './styles';

//TODO
//csv modals contains data before using them but they shouldn't

const calculateBlockedCompaniesNumber = (blockedCompanies: any) => {
    const flatBlocklist = getFlatBlocklist(blockedCompanies);
    const numberOfBlocked = (flatBlocklist as any[]).filter((company) => !company.deleted).length;
    return numberOfBlocked || 0;
};

export default function BlockList({ positionAtTop }: { positionAtTop?: boolean }) {
    const sdk = getZaplifySdk();
    const dispatch = useDispatch<AppDispatch>();
    const classes = positionAtTop ? positionAtTopStyles() : styles();
    const isBlocklistOpen = useAppSelector((state) => state.ui.displayBlocklistModal);
    const blockedCompanies = useAppSelector((state) => state.userOrganization.blacklist);
    const organizationId = useAppSelector((state) => state.user.zaplifyUser.userOrganizationId);
    const [shouldShowInput, setShouldShowInput] = useState(false);
    const [newCompanyName, setNewCompanyName] = useState('');
    const [searchBlocklistValue, setSearchBlocklistValue] = useState('');
    const [filteredCompanies, setFilteredCompanies] = useState([]);
    const [filteredBlockedCompanies, setFilteredBlockedCompanies] = useState([]);
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
    const [searchDisabled, setSearchDisabled] = useState(false);
    const [isUploadVisible, setIsUploadVisible] = useState(false);

    const blockedCompaniesNumber = useMemo(() => calculateBlockedCompaniesNumber(blockedCompanies), [blockedCompanies]);

    const getCompanies = async (companyQuery) =>
        await sdk.profiles.sources.autocomplete({
            field: ProspectSearchAutoCompleteFields.JOB_COMPANY_NAME,
            query: companyQuery,
        });

    // eslint-disable-next-line
    const filterCompanies = useCallback(async (query) => {
        const companies = await getCompanies(query);
        if (companies.length > 0) {
            setFilteredCompanies(companies);
        }
        if (companies.length === 0) {
            setFilteredCompanies([]);
        }
    }, []);

    const showInput = (shouldShowInput) => setShouldShowInput(!shouldShowInput);

    const handleAddCompanyValue = (value) => {
        setNewCompanyName(() => value);
    };
    const debouncedHandleSearch = useCallback(debounce(handleAddCompanyValue, 300), []);

    const handleSearchBlockList = (value) => {
        setSearchBlocklistValue(value);
    };

    const displayUnblockCompaniesConfirmation = () => {
        setDisplayConfirmationModal(true);
    };

    const onAddToBlocklist = () => {
        blockedCompaniesNumber >= BLOCKLIST_LIMIT
            ? showNotification(newCompanyName)
            : addCompanyToBlocklist(newCompanyName);
        setFilteredCompanies([]);
    };

    const addCompanyToBlocklist = (companyName) => {
        if (newCompanyName) {
            try {
                setSearchDisabled(true);
                setSearchBlocklistValue('');
                dispatch(updateBlocklistCompany(companyName, false));
                showNotification(companyName);
                setNewCompanyName('');
                setSearchDisabled(false);
                window?.analytics?.track('User Blocklisted Companies');
            } catch (error) {
                dispatch(handleWarningNotification(`Adding company failed. Please try again.`));
            }
        }
    };

    const checkIfListContainsValue = () => {
        return Object.keys(blockedCompanies).some((el) => !blockedCompanies[el].deleted && el === newCompanyName);
    };
    const showNotification = (companyName) => {
        if (!checkIfListContainsValue() && blockedCompaniesNumber < BLOCKLIST_LIMIT) {
            dispatch(handleSuccessNotification(`${companyName} blocked`));
        }
        if (checkIfListContainsValue()) {
            dispatch(handleWarningNotification(`${companyName} already blocked`));
        }
        if (blockedCompaniesNumber === BLOCKLIST_LIMIT) {
            dispatch(
                handleErrorNotification('Your organization has reached the max number of blocked companies', 5000)
            );
        }
    };

    const handleOnDrawerCloseActions = () => {
        setSearchBlocklistValue('');
        setFilteredBlockedCompanies([]);
        dispatch(displayBlocklistModal(false));
    };

    useEffect(() => {
        if (!isBlocklistOpen) {
            return;
        }
        dispatch(getBlacklist());
    }, [isBlocklistOpen]);

    useEffect(() => {
        if (!blockedCompanies && !searchBlocklistValue) {
            return;
        }

        const companiesList = getFlatBlocklist(blockedCompanies) as any[];

        const filteredBlocklist = [];

        companiesList.forEach((company) => {
            if (company?.name?.toLowerCase().includes(searchBlocklistValue.toLowerCase())) {
                filteredBlocklist.push(company);
            }
        });

        const formattedFilteredBlocklist = getBlocklistByFirstCharacter(filteredBlocklist);

        setFilteredBlockedCompanies(formattedFilteredBlocklist as any);
    }, [searchBlocklistValue, blockedCompanies]);

    useEffect(() => {
        if (!isBlocklistOpen) {
            return;
        }
        filterCompanies(newCompanyName);
    }, [newCompanyName, isBlocklistOpen]);

    return (
        <>
            <Drawer
                anchor={'right'}
                open={isBlocklistOpen}
                onClose={() => {
                    handleOnDrawerCloseActions();
                }}
                elevation={0}
                classes={{
                    root: classes.root,
                    paper: classes.paper,
                }}
            >
                <div role="button" data-cy="blocklist-modal">
                    <div>
                        <div className={classes.titleButtonWrapper}>
                            <Typography className={classes.title}>Blocklist</Typography>
                            <IconButton
                                data-cy="close-blocklist-icon"
                                onClick={() => {
                                    handleOnDrawerCloseActions();
                                }}
                                disableRipple
                                className={classes.iconButton}
                                size="large"
                            >
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <div className={classes.descriptionWrapper}>
                            <Typography className={classes.description}>
                                {
                                    'Companies and prospects you block will not be contacted nor found in prospecting processes for users in your organization. '
                                }
                                <GuardedAnchor
                                    href="https://intercom.help/zaplify/en/articles/5045630-2-block-your-existing-customers"
                                    target="blank"
                                    className={classes.descriptionLink}
                                >
                                    Read more
                                </GuardedAnchor>
                            </Typography>
                        </div>
                    </div>
                    <div className={classes.blocklistWrapper}>
                        <div className={classes.actionsWrapper}>
                            <div className={classes.buttonContainer}>
                                <Button
                                    dataCy="type-in-companies-button"
                                    startIcon={<PlusIcon />}
                                    variant="contained"
                                    text="Add companies"
                                    fontColor="#3A4B59"
                                    classes={['white-grey-border', 'outlined']}
                                    onClick={() => showInput(shouldShowInput)}
                                />
                                <Button
                                    dataCy="block-companies-upload-list-button"
                                    startIcon={<UploadListIcon />}
                                    variant="contained"
                                    text="Upload list"
                                    fontColor="#3A4B59"
                                    classes={['white-grey-border', 'outlined']}
                                    onClick={() => {
                                        dispatch(displayBlocklistModal(false));
                                        setFilteredBlockedCompanies([]);
                                        setIsUploadVisible(true);
                                    }}
                                />
                            </div>
                            <Collapse in={shouldShowInput}>
                                <div className={classes.buttonInputWrapper}>
                                    <Search
                                        companyName={newCompanyName}
                                        placeholder="Company name"
                                        filteredList={filteredCompanies}
                                        handleAddCompanyValue={debouncedHandleSearch}
                                        disabled={searchDisabled}
                                    ></Search>
                                    <Button
                                        dataCy="block-button"
                                        variant="contained"
                                        text="Block"
                                        customColor="#3A4B59"
                                        classes={['blue', 'wide']}
                                        onClick={onAddToBlocklist}
                                    />
                                </div>
                            </Collapse>
                            <div className={classes.blockedWrapper}>
                                <div className={classes.textButtonWrapper}>
                                    <div className={classes.textWrapper}>
                                        <Typography className={classes.titleSmaller}>
                                            Blocked Companies {blockedCompaniesNumber}
                                        </Typography>
                                        <Typography className={classes.textBlocked}>
                                            Blocklist is limited to max{' '}
                                            {BLOCKLIST_LIMIT.toLocaleString().replace(',', ' ')} companies
                                        </Typography>
                                    </div>
                                    <Button
                                        dataCy="unblock-all-button"
                                        variant="outlined"
                                        text="Unblock All"
                                        fontColor="#3A4B59"
                                        disabled={!blockedCompaniesNumber}
                                        classes={['white-grey-border', 'blocklistVariant']}
                                        onClick={displayUnblockCompaniesConfirmation}
                                    ></Button>
                                    <ConfirmationModal
                                        zIndex={2000}
                                        isOpen={displayConfirmationModal}
                                        closeModal={() => setDisplayConfirmationModal(false)}
                                        variant="greyRed"
                                        title={`Do you really want to unblock all ${blockedCompaniesNumber} companies?`}
                                        leftButtonText="Cancel"
                                        rightButtonText="Unblock All"
                                        leftButtonClick={() => setDisplayConfirmationModal(false)}
                                        rightButtonClick={() => {
                                            setDisplayConfirmationModal(false);
                                            dispatch(deleteAllCompaniesFromBlocklist(organizationId));
                                        }}
                                    />
                                </div>
                                <SearchWithIcon onChangeMethod={handleSearchBlockList} />
                                {searchBlocklistValue ? (
                                    <ListWithFirstLetters list={filteredBlockedCompanies} />
                                ) : (
                                    <ListWithFirstLetters list={blockedCompanies} />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Drawer>
            {isUploadVisible && <BlacklistModal closeModal={() => setIsUploadVisible(false)} />}
        </>
    );
}
