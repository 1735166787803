import { Box, CircularProgress, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { paths } from '../../../routes/paths';
import LoadingButton from '../../../components/form/loading-button';
import FeedbackMessage from '../../../components/form/feedback-message';
import FormInputField from '../../../components/form/form-input-field';
import { useAuth } from '../../../providers/user/authorization-provider';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

type LoginFormValues = {
    email: string;
    password: string;
};

const LoginForm = () => {
    // const userIsLoading = useAppSelector((state) => state.user.isLoadingData);
    const { loading: userIsLoading } = useAuth();
    const [authErrorMessage, setAuthErrorMessage] = useState<string | null>(null);
    const { register, handleSubmit, formState, getValues } = useForm<LoginFormValues>({
        defaultValues: {
            email: '',
            password: '',
        },
        mode: 'onChange',
    });
    const { errors } = formState;
    const { mutate: signIn, status } = useMutation({
        mutationFn: async (data: LoginFormValues) => {
            return await signInWithEmailAndPassword(getAuth(), data.email, data.password);
        },
        onSuccess: (data) => {
            window?.analytics?.ready(() => {
                window?.analytics?.track('User Signed In', {
                    email: getValues('email'),
                });
            });
            // The redirect here is handled by the new token listener in the authentication provider
        },
        onError: (error: any) => {
            console.error('firebase error', error);
            setAuthErrorMessage('Invalid email or password');
        },
    });

    const onSubmit = (data: LoginFormValues) => {
        signIn(data);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                <FormInputField<'email'>
                    id="email"
                    label="Email"
                    register={register('email', {
                        required: true,
                        pattern: {
                            value: /\S+@\S+\.\S+/,
                            message: 'Invalid email address',
                        },
                    })}
                    error={errors.email?.message}
                    placeholder="name@work-email.com"
                    type="email"
                    isLoading={status === 'pending' || userIsLoading}
                />
                <FormInputField<'password'>
                    id="password"
                    label="Password"
                    register={register('password', {
                        required: true,
                        minLength: {
                            value: 6,
                            message: 'Should contain at least 6 characters.',
                        },
                    })}
                    error={errors.password?.message}
                    placeholder="Enter your password"
                    type="password"
                    endAdornment={true}
                    isLoading={status === 'pending' || userIsLoading}
                />
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <Link to={paths.OLD_RESET_PASSWORD} style={{ textDecoration: 'none' }}>
                        <Typography
                            component="span"
                            sx={{
                                color: '#6583EF',
                                fontSize: '14px',
                                fontWeight: 500,
                                lineHeight: '21px',
                            }}
                        >
                            Forgot password?
                        </Typography>
                    </Link>
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginTop: '24px',
                    gap: '16px',
                }}
            >
                <LoadingButton
                    isLoading={status === 'pending' || userIsLoading}
                    disabled={!formState.isValid || status === 'pending' || userIsLoading}
                    type="submit"
                >
                    {status === 'pending' || userIsLoading ? (
                        <CircularProgress size={24} sx={{ color: '#969FAC' }} />
                    ) : (
                        <Typography
                            component={'span'}
                            sx={{
                                fontSize: '14px',
                                fontWeight: 500,
                                lineHeight: '21px',
                                color: 'white',
                            }}
                        >
                            Sign in with email
                        </Typography>
                    )}
                </LoadingButton>
                <FeedbackMessage id="login-form-feedback-message" error={authErrorMessage} info={''} />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '8px',
                    }}
                >
                    <Typography
                        component={'span'}
                        sx={{
                            color: '#3A4B59',
                            fontSize: '14px',
                            lineHeight: '21px',
                        }}
                    >
                        Don't have an account?
                    </Typography>
                    <Link to={paths.OLD_REGISTER} style={{ textDecoration: 'none' }}>
                        <Typography
                            component={'span'}
                            sx={{
                                color: '#6583EF',
                                fontSize: '14px',
                                fontWeight: 500,
                                lineHeight: '21px',
                            }}
                        >
                            Register
                        </Typography>
                    </Link>
                </Box>
            </Box>
        </form>
    );
};

export default LoginForm;
