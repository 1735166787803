import { ICommand, Topics } from '@zaplify/common';
import { CreateProspectDataDto, ProspectDto } from '../prospects';

export class ImportProspectsCommand extends ICommand<ProspectDto[]> {
    public static override readonly TOPIC = Topics.PROSPECTS;
    constructor(
        readonly userId: string,
        readonly prospectsData: CreateProspectDataDto[],
        readonly params: {
            includeAssigned?: boolean;
            groupId: string | 'default' | 'none';
            source?: string;
        },
    ) {
        super();
    }
}
