import React from 'react';
import { useSuggestionsFeed } from '../../hooks/use-suggestions-feed';
import { AccountCardHeader } from './account/account-card-header';
import { AccountCardChannels } from './account/account-card-channels';
import { AccountCardDescription } from './account/account-card-description';
import { AccountCardSpecialties } from './account/account-card-specialties';
import { AccountCardCompanySize } from './account/account-card-company-size';
import { cn } from '@shadcn/ui/lib/utils';
import { SplitCardContactSection } from './contact/split-card-contact-section';

export const SplitContactSuggestionCardComponent: React.FC = () => {
    const { currentContactSuggestionId } = useSuggestionsFeed();
    const subCardClassName = 'flex flex-col w-full h-full p-4 gap-4 md:overflow-y-auto';

    return (
        <div
            className="flex flex-col overflow-y-auto md:overflow-y-hidden md:flex-row w-full h-full border rounded-2xl shadow-md"
            key={currentContactSuggestionId}
        >
            <div className={cn(subCardClassName, 'border-r rounded-l-[inherit]')}>
                <AccountCardHeader />
                <AccountCardChannels />
                <AccountCardDescription />
                <AccountCardSpecialties asSection={false} />
                <AccountCardCompanySize />
            </div>
            <SplitCardContactSection />
        </div>
    );
};
