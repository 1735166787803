import { GetProspectMessagesHistoryQuery } from '@zaplify/graphql';
import {
    ConversationCreateResponse,
    ExtensionErrorResponse,
    getZaplifySdk,
    InvitationSendError,
    InvitationSendErrorReason,
    InvitationSendResponse,
} from '@zaplify/sdk';
import { MessageType } from '@zaplify/services/messaging/shared';
import {
    LinkedinGeneratedError,
    LinkedinGeneratedInvitationError,
    MessageNotSentError,
    MessageSyncError,
} from './use-messages';
import { htmlToPlain } from '../../functions/html-to-plain';
import { useLinkedin } from '../use-linkedin';
import { sleep } from '@zaplify/utils';

type Message = GetProspectMessagesHistoryQuery['Messages'][number];

const log = (message: string) => {
    console.log(`🚀 [useLinkedinMessages] ${message}`);
};

const logError = (error: string) => {
    console.error(`🚀 [useLinkedinMessages] ${error}`);
};

export const useLinkedinMessages = () => {
    const { extensionStatus, getMemberId, sendInvitation, sendMessage: sendLiMessage, isPremium } = useLinkedin();

    const sendLinkedinMessage = async (
        recipient: { prospectName: string; linkedinUserId: string },
        message: Message,
        previouslyAttemptedToSendMessage: boolean
    ) => {
        if (message.actionType !== MessageType.linkedinMessage)
            throw new MessageNotSentError('Invalid message type', recipient.prospectName);

        if (extensionStatus === 'RESTARTING' || extensionStatus === 'LOADING') {
            console.log('Extension is restarting or loading, waiting for 5 seconds');
            await sleep(5000);
        }
        const plainMessage = htmlToPlain(message.content);
        const memberId = getMemberId();
        message.content = plainMessage;
        const payload = {
            memberId,
            prospectMemberId: recipient?.linkedinUserId,
            messageContent: { text: plainMessage },
        };

        let sentMessage: ConversationCreateResponse;
        try {
            log(
                'sendLinkedinMessage | Sending message for messageId: ' +
                    message.id +
                    ' previouslyAttemptedToSendMessage' +
                    previouslyAttemptedToSendMessage
            );
            sentMessage = await sendLiMessage(payload);
            log('sendLinkedinMessage | Sent message for messageId: ' + message.id);
        } catch (error: any) {
            console.error(`sendLinkedinMessage error: ${JSON.stringify(error)} | message: ${JSON.stringify(message)}`);
            if (error?.error === 'linkedin_not_logged_in') {
                const loginErrorDesc = 'Please go to linkedin.com and make sure you are logged in';
                throw new LinkedinGeneratedError(loginErrorDesc, error?.reason ? error.reason : loginErrorDesc, error);
            }
            throw new MessageNotSentError(
                'An error occurred when trying to send your message.',
                recipient.prospectName,
                {
                    cause: error,
                }
            );
        }

        const conversationId = getIdFromLinkedInUrn(
            sentMessage.value.backendConversationUrn,
            'urn:li:messagingThread:'
        );
        const messageId = getIdFromLinkedInUrn(sentMessage.value.backendUrn, 'urn:li:messagingMessage:');

        if (!previouslyAttemptedToSendMessage) {
            /**
             * For conversation, the synchronization is done in "use-tracker-updates.ts" with syncLinkedinInbox
             */
            try {
                log('sendLinkedinMessage | Updating LinkedIn message suggestion sent for messageId: ' + message.id);
                await updateLinkedInMessage(message, plainMessage, messageId, conversationId);
            } catch (error) {
                throw new MessageSyncError(
                    'Error fetching sent messages from LinkedIn',
                    MessageType.linkedinMessage,
                    recipient.prospectName,
                    {
                        cause: error,
                    }
                );
            }
        }
        log('LinkedIn message sent successfully: ' + JSON.stringify(message));
    };

    const updateLinkedInMessage = async (
        message: Message,
        plainMessage: string,
        messageId?: string,
        conversationId?: string
    ) => {
        const linkedinMemberId = getMemberId();
        message.externalMessageId = messageId;
        const messageToPersist = {
            prospectId: message.prospectId as string,
            messageId: message.id,
            conversationId: conversationId,
            content: htmlToPlain(plainMessage, { preserveNewlines: true }),
            sentOn: message.sentOn as Date,
            externalMessageId: messageId,
            messageType: (message.actionType === MessageType.linkedinConnectionRequest
                ? MessageType.linkedinConnectionRequest
                : MessageType.linkedinMessage) as MessageType.linkedinMessage | MessageType.linkedinConnectionRequest,
            linkedinAccountId: linkedinMemberId,
        };
        const campaignsSdk = getZaplifySdk().profiles.campaigns;
        try {
            await campaignsSdk.setLinkedinMessageSuggestionSent(messageToPersist);
        } catch (error) {
            console.error(`updateLinkedInMessage error: ${JSON.stringify(error)}`);
            throw error;
        }
    };

    /**
     * This function safely splits a string on a given delimiter and returns the second element of the resulting array.
     * If the string does not contain the delimiter or the resulting array has less than two elements, it returns an empty string.
     *
     * @param urn - The string to be split.
     * @param delimiter - The delimiter to split the string on.
     * @returns The second element of the split string or an empty string.
     */
    const getIdFromLinkedInUrn = (urn: string, delimiter: string): string => {
        if (urn.includes(delimiter)) {
            const splittedUrn = urn.split(delimiter);
            if (splittedUrn.length > 1) {
                return splittedUrn[1];
            }
        }
        return '';
    };

    const sendLinkedinConnectionRequest = async (
        recipient: { prospectName: string; linkedinUserId: string },
        pendingMessage: Message
    ) => {
        if (pendingMessage.actionType !== MessageType.linkedinConnectionRequest)
            throw new MessageNotSentError('Invalid message type', recipient.prospectName);

        const plainMessage = htmlToPlain(pendingMessage.content);
        const invitationPayload = {
            prospectMemberId: recipient?.linkedinUserId,
            messageContent: pendingMessage.content
                ? {
                      text: plainMessage,
                  }
                : undefined,
            isUserPremium: isPremium,
        };
        let invitation: InvitationSendResponse;
        try {
            log('sendLinkedinConnectionRequest | Sending invitation for messageId: ' + pendingMessage.id);
            invitation = await sendInvitation(invitationPayload);
            log('sendLinkedinConnectionRequest | Sent invitation for messageId: ' + pendingMessage.id);
        } catch (error) {
            const exception = error as ExtensionErrorResponse;
            if (!exception || 'error' in exception) {
                logError(`sendLinkedinConnectionRequest | error: ${JSON.stringify(exception)}`);
                const sendInviteError = exception as InvitationSendError;
                const customErrorsToDescriptionMapping: { [key: string]: any } = {
                    [InvitationSendErrorReason.CUSTOM_MESSAGE_TOO_LONG]: `Connection request message to ${recipient.prospectName} was too long`,
                    [InvitationSendErrorReason.PROFILE_CANT_BE_ACCESSED]: `${recipient.prospectName}'s LinkedIn profile cannot be accessed`,
                    [InvitationSendErrorReason.INVALID_INVITATION_STATE]: 'Invalid invitation state',
                };
                const liErrorsToDescriptionMapping: { [key: string]: any } = {
                    [InvitationSendErrorReason.MAX_CONNECTIONS_WITH_MESSAGE_SENT]: `You have reached Linkedin's monthly limit of 5 connection requests with messages . Either remove the message or upgrade to LinkedIn Premium.`,
                    [InvitationSendErrorReason.REINVITE_THREE_WEEKS_DELAY]: `You can only send ${recipient.prospectName} a connection request once every three weeks`,
                    [InvitationSendErrorReason.ACCOUNT_BLOCKED]: `${recipient.prospectName}'s LinkedIn account is blocked`,
                    [InvitationSendErrorReason.DESKTOP_CONNECT_RESTRICTED]:
                        "You've reached LinkedIn's periodic connection request limit. Please try again tomorrow.",
                };
                if (sendInviteError.reason in liErrorsToDescriptionMapping) {
                    throw new LinkedinGeneratedInvitationError(
                        sendInviteError.error,
                        liErrorsToDescriptionMapping[sendInviteError.reason],
                        sendInviteError
                    );
                }
                throw new MessageNotSentError(
                    customErrorsToDescriptionMapping[sendInviteError.reason],
                    recipient.prospectName,
                    {
                        cause: sendInviteError,
                    }
                );
            }
            throw error;
        }
        try {
            log('sendLinkedinConnectionRequest | Updating LinkedIn message suggestion sent');
            pendingMessage.actionType = MessageType.linkedinConnectionRequest;
            pendingMessage.externalMessageId = invitation.invitationId;
            pendingMessage.sentOn = new Date(invitation.sentAt);
            pendingMessage.content = plainMessage;
            await updateLinkedInMessage(pendingMessage, plainMessage);
            log(
                'sendLinkedinConnectionRequest | Updated LinkedIn message suggestion sent for messageId: ' +
                    pendingMessage.id
            );
        } catch (error) {
            logError(`sendLinkedinConnectionRequest | error: ${JSON.stringify(error)}`);
            throw error;
        }
    };

    return {
        sendLinkedinMessage,
        updateLinkedInMessage,
        sendLinkedinConnectionRequest,
    };
};
