import { createContext, ReactNode, useContext, useEffect, useState, useCallback, useMemo } from 'react';
import { IdTokenResult as FbIdTokenResult, User as FbUser } from 'firebase/auth';
import { LocalStorageKeys } from '../../config';
import { getZaplifySdk, IBearerTokenClaims } from '@zaplify/sdk';
import {
    IInvitationReduced,
    IUserAccount,
    UserComposedDto,
    UserOrganizationComposedDto,
    UserRole,
} from '@zaplify/users/shared';
import { GroupDto } from '@zaplify/services/user-contacts/shared';
import { useSdk } from '../../new/sdk';
import { useQueryClient } from '@tanstack/react-query';
import { ChannelAccountDto, ChannelType } from '@zaplify/channel-accounts/shared';
import { createApolloClient } from '../../providers/create-apollo-client';
import { ApolloProvider } from '@apollo/client';
import { LoaderView } from '../components/loader';
import { auth } from '../../services/firebase';
import { claimWaitingInvitations } from '../functions/invitations-handling';

class OnboardingState {
    hasCompletedOnboarding: boolean;
    isImpersonating: boolean;
    hasLinkedInConnected: boolean;
    constructor({
        user,
        groups,
        isImpersonating,
        channelAccounts,
        totalNumberOfUserContacts,
    }: {
        user: UserComposedDto;
        groups?: GroupDto[];
        isImpersonating?: boolean;
        channelAccounts?: ChannelAccountDto[];
        totalNumberOfUserContacts?: number;
    }) {
        const isUserOldEnoughToBeFromZaplify = user?.createdAt && new Date(user?.createdAt) < new Date('2024-10-01');
        const hasGroupFromOldMigration = groups?.length === 1 && groups?.[0]?.name === 'Default';
        const isUserFromZaplify =
            isUserOldEnoughToBeFromZaplify && hasGroupFromOldMigration && totalNumberOfUserContacts === 0;

        this.isImpersonating = isImpersonating;
        this.hasCompletedOnboarding = groups?.length > 0 && !isUserFromZaplify;
        this.hasLinkedInConnected = channelAccounts.some((account) => account.type === ChannelType.LINKEDIN);
    }
}

interface IdTokenResult extends FbIdTokenResult {
    claims: IBearerTokenClaims;
}

export interface IAuthState {
    isLoggedIn: boolean;
    isEmailVerified: boolean;
    hasSelectedUser: boolean;
    nbrOfUserAccounts: number;
    nbrOfInvitations: number;
    authUserId: string;
    userId: string;
    userOrganizationId: string;
    tokenResult: IdTokenResult | null;
    isImpersonating: boolean;
    isGlobalAdmin: boolean;
    userAccounts: IUserAccount[];
    invitations: IInvitationReduced[];
    user: UserComposedDto & {
        userOrganization: UserOrganizationComposedDto;
    };
    seatId: string;
    onboardingState: OnboardingState;
}

interface AuthContextType {
    authState: IAuthState;
    loading: boolean;
    login: (email: string, password: string) => void;
    logout: () => void;
    refreshAuthToken: () => Promise<void>;
    refreshOnboardingState: () => Promise<void>;
    reloadClaims: (organizationId?: string) => Promise<void>;
}

const authStateDefault: IAuthState = {
    isLoggedIn: false,
    isEmailVerified: false,
    hasSelectedUser: false,
    nbrOfUserAccounts: 0,
    nbrOfInvitations: 0,
    tokenResult: null,
    authUserId: '',
    userId: '',
    userOrganizationId: '',
    seatId: '',
    userAccounts: [],
    invitations: [],
    isImpersonating: false,
    isGlobalAdmin: false,
    onboardingState: null,
    user: null,
};

const AuthContext = createContext<AuthContextType>({
    authState: authStateDefault,
    loading: true,
    login: () => {},
    logout: () => {},
    refreshAuthToken: async () => {},
    refreshOnboardingState: async () => {},
    reloadClaims: async () => {},
});

export const useAuth = () => useContext(AuthContext);

export const AuthenticationProvider = ({ children }: { children?: ReactNode }) => {
    // const [searchParams, setSearchParams] = useSearchParams();
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [loading, setLoading] = useState(true);
    const [authUser, setAuthUser] = useState<FbUser | null>(null);
    const [authState, setAuthState] = useState<IAuthState>(authStateDefault);
    // Add a state to track Apollo client initialization
    const [apolloClient, setApolloClient] = useState<Awaited<ReturnType<typeof createApolloClient>> | null>(null);
    const [apolloLoading, setApolloLoading] = useState(true);

    const tanstackQueryClient = useQueryClient();
    const {
        channelAccount: { getMyChannelAccounts },
        userContacts: { getTotalUserContactsCount },
    } = useSdk();

    const getAccountsAndInvitations = async (): Promise<{
        accounts: IUserAccount[];
        invitations: IInvitationReduced[];
    }> => {
        try {
            const accountsAndInvitations = await getZaplifySdk().profiles.user.getUserAccounts();
            return {
                accounts: accountsAndInvitations?.users || [],
                invitations: accountsAndInvitations?.invitations || [],
            };
        } catch (err) {
            return {
                accounts: [],
                invitations: [],
            };
        }
    };

    const loadUser = async (): Promise<{
        onboardingState: OnboardingState;
        user: UserComposedDto;
        channelAccounts: ChannelAccountDto[];
    }> => {
        const [zaplifyUser, groups, channelAccounts, totalNumberOfUserContacts] = await Promise.all([
            getZaplifySdk()
                .profiles.user.getUserByFirebaseId()
                .catch(() => null),
            getZaplifySdk()
                .profiles.groups.getGroups()
                .catch(() => []),
            tanstackQueryClient.fetchQuery(getMyChannelAccounts()).catch(() => []),
            tanstackQueryClient.fetchQuery(getTotalUserContactsCount()).catch(() => ({ count: 0 })),
        ]);
        const onboardingState = new OnboardingState({
            user: zaplifyUser,
            groups,
            isImpersonating: false,
            channelAccounts,
            totalNumberOfUserContacts: totalNumberOfUserContacts?.count || 0,
        });

        return { onboardingState, user: zaplifyUser, channelAccounts };
    };

    const loadAuthState = async (_authUser?: FbUser | null | undefined) => {
        if (!_authUser) {
            setAuthUser(null);
            setAuthState(authStateDefault);
            return;
        }

        if (_authUser !== authUser) {
            setAuthUser(_authUser);
        }
        const tokenResult = (await _authUser.getIdTokenResult()) as IdTokenResult;
        const { accounts, invitations } = await getAccountsAndInvitations();
        const currentAuthUserClaims = tokenResult?.claims || {};
        const authUserId = currentAuthUserClaims.authUserId || _authUser.uid;
        const selectedUserId = currentAuthUserClaims.userId;
        const seatId = currentAuthUserClaims.seatId;
        console.log('currentAuthUserClaims', currentAuthUserClaims);
        let user = null;
        let onboardingState = null;
        let channelAccounts = [];
        if (seatId) {
            ({ user, onboardingState, channelAccounts } = await loadUser());
        } else {
            onboardingState = new OnboardingState({
                user,
                groups: [],
                isImpersonating: false,
                channelAccounts,
                totalNumberOfUserContacts: 0,
            });
        }

        setAuthState({
            isLoggedIn: !!authUserId,
            nbrOfUserAccounts: accounts.length || 0,
            nbrOfInvitations: invitations.length || 0,
            isEmailVerified: _authUser.emailVerified || false,
            hasSelectedUser: !!selectedUserId,
            isImpersonating: _authUser.uid !== authUserId,
            authUserId,
            userId: selectedUserId,
            userOrganizationId: user?.userOrganizationId,
            seatId,
            tokenResult,
            isGlobalAdmin: currentAuthUserClaims.roles?.includes(UserRole.GLOBAL_ADMIN) || false,
            userAccounts: accounts,
            invitations,
            onboardingState: {
                ...onboardingState,
                isImpersonating: _authUser.uid !== authUserId,
            },
            user,
        });
    };

    // Initialize Apollo client separately from auth
    useEffect(() => {
        let isMounted = true;

        const initApolloClient = async () => {
            try {
                const clientInstance = await createApolloClient();
                if (isMounted) {
                    setApolloClient(clientInstance);
                    setApolloLoading(false);
                }
            } catch (error) {
                console.error('Failed to initialize Apollo client', error);
                if (isMounted) {
                    setApolloLoading(false);
                }
            }
        };

        initApolloClient();

        return () => {
            isMounted = false;
        };
    }, []);

    // Memoize functions to keep them stable between renders
    const login = useCallback(() => {}, []);

    const logoutCallback = useCallback(async () => {
        await auth.signOut();
        window?.analytics?.track('User Signed Out', {});
        Object.keys(LocalStorageKeys).forEach((key) => localStorage.removeItem(LocalStorageKeys[key]));
    }, []);

    const refreshAuthTokenCallback = useCallback(async () => {
        try {
            await loadAuthState(authUser);
        } catch (err) {
            console.error('Error while trying to loadAuthState in refreshAuthToken', err);
            throw err;
        }
    }, [authUser]); // Add loadAuthState if it's not defined inline

    const refreshOnboardingStateCallback = useCallback(async () => {
        try {
            await loadAuthState(authUser);
        } catch (err) {
            console.error('Error while trying to loadAuthState in refreshOnboardingState', err);
            throw err;
        }
    }, [authUser]); // Add loadAuthState if it's not defined inline

    const reloadClaimsCallback = useCallback(
        async (organizationId?: string) => {
            console.log('reloadClaimsCallback', { organizationId, authState });
            if (!organizationId && !authState.userOrganizationId) return;
            await getZaplifySdk().profiles.authentication.chooseOrganization(
                organizationId || authState.userOrganizationId
            );
            await auth.currentUser.getIdToken(true);
            await loadAuthState(authUser);
        },
        [authUser, authState.userOrganizationId]
    ); // Add loadAuthState if not defined inline

    // Memoize the entire context value
    const contextValue = useMemo(
        () => ({
            authState,
            loading,
            login,
            logout: logoutCallback,
            refreshAuthToken: refreshAuthTokenCallback,
            refreshOnboardingState: refreshOnboardingStateCallback,
            reloadClaims: reloadClaimsCallback,
        }),
        [
            authState,
            loading,
            login,
            logoutCallback,
            refreshAuthTokenCallback,
            refreshOnboardingStateCallback,
            reloadClaimsCallback,
        ]
    );

    // Separate effect for token changes
    useEffect(() => {
        console.log('AuthenticationProvider runs');
        setLoading(true);
        const unsubscribe = auth.onIdTokenChanged(async (fbUser) => {
            try {
                console.log('Token changed:', { fbUser });
                await claimWaitingInvitations(fbUser);
                await loadAuthState(fbUser);
            } catch (error) {
                console.error('Error while fetching user data', error);
            } finally {
                setLoading(false);
                setIsPageLoading(false);
            }
        });

        return () => unsubscribe();
    }, []);

    return (
        <AuthContext.Provider value={contextValue}>
            {apolloClient ? (
                <ApolloProvider client={apolloClient.client}>
                    {isPageLoading ? <LoaderView /> : children}
                </ApolloProvider>
            ) : apolloLoading ? (
                <LoaderView />
            ) : (
                <div>Failed to initialize Apollo client</div>
            )}
        </AuthContext.Provider>
    );
};
