import { Briefcase, Clock } from 'lucide-react';

export const BriefcaseClock = () => {
    return (
        <div className="flex">
            <Briefcase className="h-5 w-5 stroke-[1]" />
            <div className="flex items-center p-[0.1rem] ml-[-9px] mt-[8px] rounded-full bg-background-primary">
                <Clock className="h-[9px] w-[9px] stroke-[2]" />
            </div>
        </div>
    );
};
