import { FreshVersionProvider } from '../../providers/fresh-version-provider';
import { AuthorizationProvider } from '../../providers/user/authorization-provider';
import { useEffect, useRef } from 'react';
import { configureStore } from '../../redux/store';
import SocketClient from '../../services/utils/sockets/socketClient';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from '../../theme';
import { StyledEngineProvider } from '@mui/material/styles';
import { NotificationProvider } from '../../providers/notification.provider';
import { Provider } from 'react-redux-latest';

export const LegacyAuthProviders = ({ children }) => {
    const store = useRef(
        configureStore({
            socketClient: new SocketClient(),
        })
    );

    useEffect(() => {
        const unsubscribe = store.current.subscribe(() => {
            console.log('Redux State Updated:', store.current.getState());
        });
        return () => unsubscribe();
    }, []);

    return (
        <Provider store={store.current}>
            <FreshVersionProvider>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                        {' '}
                        {/* @TODO This is the old authorization provider which does not have the same authContext */}
                        <AuthorizationProvider>
                            <NotificationProvider>
                                <div className="mui-scope">{children}</div>
                            </NotificationProvider>
                        </AuthorizationProvider>
                    </ThemeProvider>
                </StyledEngineProvider>
            </FreshVersionProvider>
        </Provider>
    );
};
