// new file: navigation-list-item-button.tsx
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'react-router-dom';

interface NavigationMainItemProps {
    title: string;
    to: string;
    icon: React.ReactNode;
    isActive: boolean;
    isSidebarPinned: boolean;
    showChevron?: boolean;
}

export const NavigationMainItem = ({
    title,
    to,
    icon,
    isActive,
    isSidebarPinned,
    showChevron = false,
    ...restProps
}: NavigationMainItemProps) => {
    return (
        <ListItemButton
            disableRipple
            component={Link}
            to={to}
            sx={{
                textDecoration: 'none',
                borderRadius: 2,
                px: isSidebarPinned ? 1 : 0,
                py: '6px',
                paddingLeft: '4px !important',
                marginLeft: '2px !important',

                justifyContent: isSidebarPinned ? 'flex-start' : 'center',
                backgroundColor: isActive ? '#D7D8FF' : '',
                '&:hover': {
                    backgroundColor: isActive ? '#D7D8FF' : '#EBEBFF',
                },
                '.MuiListItemText-root': { margin: 0 },
            }}
            {...restProps}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: isSidebarPinned ? '4px' : '0px' }}>
                <ListItemIcon
                    sx={{
                        minWidth: 'auto',
                        color: isActive ? 'rgb(92, 92, 214) !important' : '',
                        height: '16px',
                        width: '16px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: '1px',
                        '& svg': {
                            strokeWidth: 2, // Makes the icon strokes thicker
                            width: '16px', // Match parent size
                            height: '16px', // Match parent size
                        },
                    }}
                >
                    {icon}
                </ListItemIcon>
                <ListItemText
                    sx={{
                        margin: 0,
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        variant="body2"
                        sx={{
                            display: isSidebarPinned ? 'inherit' : 'none',
                            color: isActive ? 'rgb(92, 92, 214) !important' : '#475467 !important',
                            fontSize: '14px !important',
                            fontWeight: isActive ? '500 !important' : '400 !important',
                        }}
                    >
                        {title}
                    </Typography>
                </ListItemText>{' '}
            </Box>
        </ListItemButton>
    );
};
