import { ReactNode, memo, useMemo } from 'react';
import { AppDispatch, useAppSelector } from '../redux/store/configureStore';
import { fetchCampaigns } from '../redux/actions';
import { useDispatch } from 'react-redux-latest';

const ReduxProvider = memo(() => {
    const dispatch = useDispatch<AppDispatch>();
    dispatch(fetchCampaigns());
    return null;
});

export const ReduxInitialStateProvider = ({ children }: { children?: ReactNode }) => {
    const userId = useAppSelector((state) => state.user?.zaplifyUser?.id);

    const reduxProvider = useMemo(() => {
        return userId ? <ReduxProvider /> : null;
    }, [userId]);

    return (
        <>
            {reduxProvider}
            {children}
        </>
    );
};
