import { FC, useEffect, useMemo, useState } from 'react';
import { useActions } from '../../../../hooks/use-actions';
import { useSdk } from '../../../../sdk/use-sdk';
import { useQuery as useQueryTan } from '@tanstack/react-query';
import { ActionCard } from '../../components/cards/action-feed-card';
import { MessagesSquare } from 'lucide-react';
import { useParams } from 'react-router-dom';
import { cn } from '@shadcn/ui/lib/utils';

export const OnboardingActionCard: FC = () => {
    const { prospectIdsWithActions } = useActions();
    const pageProspectId = useParams().prospectId;
    const [selectedProspectId, setSelectedProspectId] = useState<string | null>(null);
    const {
        prospect: { getProspectById },
    } = useSdk();

    useEffect(() => {
        if (prospectIdsWithActions.length > 1 && !selectedProspectId) {
            setSelectedProspectId(prospectIdsWithActions[0]);
        }
    }, [prospectIdsWithActions]);

    const { data: prospectData } = useQueryTan(getProspectById(selectedProspectId));

    if (!selectedProspectId) {
        return null;
    }

    return (
        <div className={cn('', { 'border-l-4 border-l-purple-500 rounded-xl min-w-full': !pageProspectId })}>
            {!pageProspectId && (
                <div className="bg-purple-50 px-4 -mb-2 pb-4 pt-2 flex flex-col items-start gap-2 rounded-tr-lg">
                    <div className="flex items-center gap-2">
                        <MessagesSquare size={16} className="text-purple-600" />
                        <span className="font-medium text-sm">Complete your first Playbook action</span>
                    </div>
                </div>
            )}
            <ActionCard
                prospectId={selectedProspectId}
                prospectData={{ ...prospectData?.data, prospectId: selectedProspectId }}
                isCompleted={false}
                baseUrl="/new/action-feed/onboarding"
            />
        </div>
    );
};
