import PropTypes from 'prop-types';
import { useState } from 'react';
import GoogleLogin from 'react-google-login';
import { HelpModal } from './helpModal';

const googlelogo = 'https://i.pinimg.com/originals/39/21/6d/39216d73519bca962bd4a01f3e8f4a4b.png';

const SignInGoogleButton = (props) => {
    const [authenticationFlow, setAuthenticationFlow] = useState(0);

    const handleAuthenticationFlow = (view) => setAuthenticationFlow(view);

    const config = {
        auth: {
            clientId: props.clientId,
            uxMode: props.uxMode || 'popup',
            redirectUri: props.redirectUri || null,
            accessType: props.accessType,
            scopes: props.scopes || ['user.read'],
            responseType: props.responseType || 'token',
            prompt: props.prompt || 'select_account',
        },
    };

    return (
        <>
            <button
                onClick={() => handleAuthenticationFlow(1)}
                style={{ boxShadow: 'rgba(0, 0, 0, 0.45) 0px 1px 2px', border: 'none', background: 'white' }}
            >
                <span style={{ padding: '10px', fontWeight: 500, float: 'left' }}>
                    <img
                        src={googlelogo}
                        alt='Google/Gmail/GSuite'
                        style={{ width: '22px', verticalAlign: 'middle' }}
                    />
                    <span style={{ paddingLeft: '10px', lineHeight: '30px', color: '#6f7782', fontSize: '14px' }}>
                        {props.buttonText}
                    </span>
                </span>
            </button>
            {authenticationFlow === 2 && (
                <GoogleLogin
                    autoLoad={authenticationFlow === 2}
                    clientId={config.auth.clientId}
                    responseType={config.auth.responseType}
                    accessType={config.auth.accessType}
                    buttonText=''
                    onSuccess={props.onSuccess}
                    onFailure={props.onFailure}
                    prompt={config.auth.prompt}
                    scope={config.auth.scopes.join(' ')}
                    uxMode={config.auth.uxMode}
                    redirectUri={config.auth.redirectUri}
                    cookiePolicy={'single_host_origin'}
                    icon={false}
                    className={'bg-grey'}
                    render={() => <div />}
                />
            )}
            <HelpModal isOpen={authenticationFlow === 1} handleAuthenticationFlow={handleAuthenticationFlow} />
        </>
    );
};

SignInGoogleButton.propTypes = {
    clientId: PropTypes.string.isRequired,
    scopes: PropTypes.array,
    redirectUri: PropTypes.string,
    responseType: PropTypes.string,
    prompt: PropTypes.string,
    uxMode: PropTypes.string,
    accessType: PropTypes.string.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onFailure: PropTypes.func.isRequired,
    buttonText: PropTypes.string.isRequired,
};

export default SignInGoogleButton;
