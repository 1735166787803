import { Typography } from '@mui/material';
import Whatshot from '@mui/icons-material/Whatshot';
import { Button, Modal } from '@zaplify/frontend-common';
import { useSearchParams } from '../../hooks/useSearchParams';
import { useAppSelector } from '../../redux/store/configureStore';
import { SubscriptionCanceledContainer } from './containers';

export const SubscriptionCancelledModal = () => {
    const { setSearchParams } = useSearchParams();
    const subscription = useAppSelector((state) => state.user.subscription);
    const endDate = new Date(subscription.subscription?.renewalAt || '').toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });

    const openPickPlanModal = () => setSearchParams('pick-plan', 'true');

    const displayModal = subscription.status === 'cancelled';

    return (
        <Modal
            isOpen={displayModal}
            disableScrolling
            style={{ content: { background: 'none', maxHeight: '90vh', borderRadius: 14 } }}
        >
            <SubscriptionCanceledContainer>
                <div className="left-container">
                    <Typography className="header">Account Inactive</Typography>
                    <Typography variant="h4" className="account-state-title">
                        Your Zaplify account is inactive
                    </Typography>
                    <Typography className="canceled-date">Subscription cancelled {endDate}</Typography>
                    <Typography className="body">
                        Want to keep using Zaplify? Select a plan that suits your needs
                    </Typography>
                    <Button
                        variant="contained"
                        onClick={openPickPlanModal}
                        classes={['upgrade-button']}
                        text="Upgrade plan"
                    />
                    <Typography className="paused-details">
                        <i>We’ve paused outreach for all prospects you’re currently engaging with</i>
                    </Typography>
                </div>
                <div className="right-container">
                    <Typography className="title" variant="h4">
                        What will an upgrade include?
                    </Typography>
                    <div className="feature-card">
                        <Whatshot />
                        Dedicated Customer Success manager
                    </div>
                    <div className="feature-card">
                        <Whatshot />1 on 1 copywriting sessions with our content team
                    </div>
                    <div className="feature-card">
                        <Whatshot />
                        In-depth analysis of your Ideal Customer Profile
                    </div>
                </div>
            </SubscriptionCanceledContainer>
        </Modal>
    );
};
