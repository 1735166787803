import { useEffect } from 'react';

import { userAtom, userLoadingAtom } from '../views/chats/hooks/use-user';
import { useSetAtom } from 'jotai';
import { getZaplifySdk } from '@zaplify/sdk';

export const MeProvider = () => {
    const setUser = useSetAtom(userAtom);
    const setLoading = useSetAtom(userLoadingAtom);

    const fetchMe = async () => {
        const userComposed = await getZaplifySdk().profiles.user.getUserByFirebaseId();
        setUser(userComposed);
        setLoading(false);
    };

    useEffect(() => {
        fetchMe();
    }, []);

    return null;
};
