import { styled } from '@mui/styles';

const FileUploadStyled = styled('div')(({ theme: { palette, spacing } }: any) => ({
    width: '100%',

    '& .wrapper': {
        border: `1px solid ${palette.colors.greyInformationLight}`,
        backgroundColor: palette.colors.whiteDark,
        boxSizing: 'border-box',
        borderRadius: '4px',
        padding: 15,

        '& .info-container': {
            width: '100%',
            backgroundColor: palette.colors.white,
            border: `1.5px dashed ${palette.colors.greyLighter}`,
            borderRadius: 4,
            textAlign: 'center',
            color: palette.colors.black,
            cursor: 'pointer',

            '& .info': {
                margin: 30,

                '& span': {
                    color: palette.primary.light,
                },
            },

            '& .files': {
                gap: spacing(2),
                alignItems: 'center',
                position: 'relative',
                margin: '30px 37px',

                '& .file-name': {
                    alignItems: 'center',

                    '& .file-name-text': {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    },
                    '& .file-uploaded': {
                        display: 'flex',
                        gap: 6,
                        alignItems: 'center',
                        color: palette.colors.mint,

                        '&-info': {
                            fontSize: 13,
                        },
                    },
                },
                '& .icon': {
                    marginLeft: 60,
                    fontSize: 28,
                    padding: 0,

                    '&-check': {
                        color: palette.colors.mint,
                    },
                },
            },
        },
    },
    '& .columns-select-wrapper': {
        display: 'flex',
        justifyContent: 'center',

        '& .columns-select': {
            minWidth: 200,
            textAlign: 'left',

            '& .label': { color: palette.subline, fontWeight: 500, padding: spacing(2, 0, 1) },
            '& .select': {
                height: 43,
                minWidth: 200,

                '& div:first-of-type': { zIndex: 2 },
                '& svg': { zIndex: 2 },
                '& > .select-option': { cursor: 'pointer' },
                '& .MuiSelect-select:focus': { backgroundColor: 'transparent' },
                '& .MuiOutlinedInput-notchedOutline': { zIndex: 1, backgroundColor: palette.colors.white },
            },
        },
    },
    '& .errors': {
        textAlign: 'left',

        '& .error': {
            paddingTop: spacing(1),
            color: palette.colors.error,
        },
    },
}));

export default FileUploadStyled;
