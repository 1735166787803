import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isImpersonatingAUser } from '../../helpers/is-global-admin';
import { useAppSelector } from '../../redux/store/configureStore';
import { paths } from '../../routes/paths';
import { isChromiumBasedBrowser, isMobileBrowser } from '../../services/utils/helpFunctions';
import { ChromiumInfo } from './chromium-info';
import { MobileInfo } from './mobile-info';
import { ConnectLinkedInStepV2 } from './connect-linkedin-step-v2';
import { SplitView } from './split-view-onboarding';
import { ConnectLinkedinInfoSection } from './connect-linkedin-info-section';

export const Component = () => {
    const navigate = useNavigate();
    const isLoadingUser = useAppSelector((state) => state.user.isLoadingData);

    useEffect(() => {
        (async () => {
            const isImpersonating = await isImpersonatingAUser();
            if (isImpersonating) {
                console.log('Onboarding view is impersonating, redirecting to home');
                navigate(paths.HOME);
                return;
            }
        })();
    }, [isLoadingUser]);

    if (isMobileBrowser()) {
        return <MobileInfo />;
    }

    if (!isChromiumBasedBrowser()) {
        return <ChromiumInfo />;
    }

    return <SplitView leftComponent={<ConnectLinkedInStepV2 />} rightComponent={<ConnectLinkedinInfoSection />} />;
};

Component.displayName = 'OnboardingConnectLinkedInView';
