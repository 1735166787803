import { FC } from 'react';
import { PageHeader } from './components/page-header';
import { SearchBar } from './components/search-bar';
import { PlaybooksGrid } from './components/playbooks-grid';
import { PlaybooksViewProvider } from './hooks/use-playbooks-view';

export const PlaybooksPage: FC = () => {
    return (
        <div className="flex h-full max-w-full max-h-full">
            <div className="flex-1 flex flex-col min-w-0 p-4 max-h-full">
                <PageHeader />
                <div className="flex flex-col gap-4 py-4 max-h-full overflow-y-auto">
                    <PlaybooksViewProvider>
                        <SearchBar />
                        <PlaybooksGrid />
                    </PlaybooksViewProvider>
                </div>
            </div>
        </div>
    );
};

export default PlaybooksPage;
