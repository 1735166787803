import { Typography } from '@mui/material';
import { ReactComponent as AndSendLogo } from '../../../../../components/icons/and-send/ANDSEND_logotype_small_black_transparent.svg';
import { ReactComponent as Arrow } from '../../../../../components/icons/arrow.svg';
import UpsalesLogoText from '../../../../../components/icons/upsales-logo-3.svg';
import { ReactComponent as ZaplifyLogo } from '../../../../../components/icons/zaplify-logo.svg';
import { companyNameCorrectlyCased } from '../../../../../enums/company-name';
import { isAndSend } from '../../../../../helpers/is-and-send';
import { FieldMapHeaderContainer } from './containers';

export const FieldMapHeader = () => {
    return (
        <FieldMapHeaderContainer data-cy="upsales-field-mapping-container">
            <div className={'text-container'}>
                <Typography>
                    <b>Field mapping</b>
                </Typography>
                <Typography>{`Specify which ${companyNameCorrectlyCased} fields should correspond to Upsales fields.`}</Typography>
            </div>
            <div className={'logos-container'}>
                {isAndSend() ? (
                    <AndSendLogo style={{ height: '20px', width: 'auto' }} />
                ) : (
                    <ZaplifyLogo style={{ height: '20px', width: 'auto' }} />
                )}
                <Arrow />
                <img alt="upsales-company-logo" src={UpsalesLogoText} />
            </div>
        </FieldMapHeaderContainer>
    );
};
