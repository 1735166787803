import { Box, Chip, Typography } from '@mui/material';
import { AccountResponseDto } from '@zaplify/prospects';
import { Tag } from 'lucide-react';
import { useEffect, useState } from 'react';
import { SectionType, userClickedShowMoreInformationInGlobalSearch } from '../tracking';

export const TagsSection = ({ account }: { account: AccountResponseDto }) => {
    const [showAllTags, setShowAllTags] = useState(false);
    const DEFAULT_NUMBER_OF_TAGS_SHOWN = 7;

    useEffect(() => {
        if (showAllTags) {
            userClickedShowMoreInformationInGlobalSearch(SectionType.Tags);
        }
    }, [showAllTags]);

    if (!account?.tags?.length) return null;
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: 'var(--S-xs, 6px)',
                alignSelf: 'stretch',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    width: '20 px',
                    height: '20 px',
                    padding: '2.5px',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Tag size={20} strokeWidth={1} />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: 'var(--S-xs, 12px)',
                    flex: '1 0 0',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        alignContent: 'flex-start',
                        gap: '4px var(--S-2xs, 4px)',
                        alignSelf: 'stretch',
                        flexWrap: 'wrap',
                    }}
                >
                    {account?.tags
                        ?.slice(0, showAllTags ? account.tags.length : DEFAULT_NUMBER_OF_TAGS_SHOWN)
                        ?.map((tag, i) => (
                            <Chip
                                sx={{
                                    fontSize: '11px',
                                    fontWeight: 500,
                                    lineHeight: '13px',
                                    borderRadius: 'var(--radius-round, 100px)',
                                    background: 'var(--grey-palette-grey-1, #F7F8FA)',
                                    color: '#6B7985',
                                    display: 'flex',
                                    padding: 'var(--S-2xs, 4px) var(--S-s-regular, 8px)',
                                    alignItems: 'center',
                                    gap: 'var(--S-xs, 6px)',
                                }}
                                size="small"
                                key={i}
                                label={tag.length > 50 ? tag.slice(0, 50) + ' ...' : tag}
                            />
                        ))}
                </Box>
                {account?.tags.length > DEFAULT_NUMBER_OF_TAGS_SHOWN && (
                    <Typography
                        component="span"
                        sx={{
                            color: 'var(--primary-20-base, #6A76FB)',
                            fontSize: '13px',
                            fontWeight: 500,
                            lineHeight: '19px',
                            cursor: 'pointer',
                        }}
                        onClick={() => setShowAllTags(!showAllTags)}
                    >
                        {showAllTags ? 'Show less' : 'Show more'}
                    </Typography>
                )}
            </Box>
        </Box>
    );
};
