import { IEvent, Topics } from '@zaplify/common';
import { MessageDto } from '../dtos';

export class TrackedMessagesEvent extends IEvent {
    public static override readonly TOPIC = Topics.CAMPAIGNS;

    constructor(
        readonly userId: string,
        readonly organizationId: string,
        readonly prospectId: string,
        readonly channelAccountId: string,
        readonly messages: MessageDto[],
    ) {
        super();
    }

    get partitionKey(): string {
        return this.prospectId;
    }
}
