import { IQuery, Topics } from '@zaplify/common';
import { CampaignProspectsForExportDto, GetProspectsManagementQuery } from '../..';

export class GetCampaignProspectsForExportQuery extends IQuery<CampaignProspectsForExportDto> {
    public static override readonly TOPIC = Topics.CAMPAIGNS;

    constructor(
        readonly campaignId: string,
        readonly context: { organizationId: string; readonly userId: string },
        readonly getCampaignProspectsDto: GetProspectsManagementQuery,
    ) {
        super();
    }
}
