import { makeAssistantToolUI, useThreadRuntime } from '@assistant-ui/react';
import {
    SearchContactsFreeTextToolFactory,
    SearchContactsFreeTextToolArgs,
    SearchContactsFreeTextToolResult,
    WebSearchToolFactory,
    WebSearchToolArgs,
    WebSearchToolResult,
} from '@andsend/services/agent/shared';
import { ContactCard } from '../../../../components/contact-card/contact-card';
import { GlobeIcon } from 'lucide-react';

const WebSearchUI = ({ args, result }: { args: WebSearchToolArgs; result: WebSearchToolResult }) => {
    if (!result) {
        return <div>No contacts found</div>;
    }

    return (
        <div className="flex flex-row items-center gap-2 rounded-full bg-background-secondary p-2 px-4 mb-2 w-fit">
            <GlobeIcon className="w-4 h-4" />
            Searched for "{args.query}"
        </div>
    );
};

const WebSearchToolUI = makeAssistantToolUI<WebSearchToolArgs, WebSearchToolResult>({
    toolName: WebSearchToolFactory.name,
    render: ({ args, result }) => WebSearchUI({ args, result }),
});

export const webSearchToolsUI = [WebSearchToolUI];
