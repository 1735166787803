import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { OnboardingHeader } from './components/onboarding-header';
import { TypingIndicator } from './components/typing-indicator';
import FirstPlaybookCard from './components/first-playbook-card';
import ContactSuggestionsCard from './components/contact-suggestions-card';
import { OnboardingActionCard } from './components/first-action-card';
import { CheckCircle, ChevronRight } from 'lucide-react';
import { motion, AnimatePresence } from 'motion/react';
import { Link } from 'react-router-dom';
import { paths } from '../../../../routes/paths';
import { Button } from '@shadcn/ui/components/ui/button';
import { FeedOnboardingProvider, useFeedOnboarding } from './hooks/use-feed-onboarding';

export const OnboardingFeedPage: FC = () => {
    return (
        <FeedOnboardingProvider>
            <OnboardingFeedPageComponent />
        </FeedOnboardingProvider>
    );
};

export const OnboardingFeedPageComponent: FC = () => {
    const { fetchLinkedinConversations, analyzeWebsite, conversationAnalysis, steps, setupCompleted } =
        useFeedOnboarding();
    const analyzeWebsiteCompleted = steps.analyzeWebsite.status === 'completed';
    const fetchLinkedinConversationsCompleted = steps.fetchLinkedinConversations.status === 'completed';
    const conversationAnalysisCompleted = steps.conversationAnalysis.status === 'completed';
    const onboardingStepsComplete = useMemo(() => {
        return fetchLinkedinConversationsCompleted && analyzeWebsiteCompleted && conversationAnalysisCompleted;
    }, [fetchLinkedinConversationsCompleted, analyzeWebsiteCompleted, conversationAnalysisCompleted]);
    const [onboardingComplete, setOnboardingComplete] = useState(false);
    const onboardingStartTime = useRef(Date.now());

    useEffect(() => {
        if (onboardingStepsComplete) {
            const timeElapsed = Date.now() - onboardingStartTime.current;
            if (timeElapsed > 45_000) {
                setOnboardingComplete(true);
            } else {
                setTimeout(() => setOnboardingComplete(true), 45_000 - timeElapsed);
            }
        }
    }, [onboardingStepsComplete]);

    useEffect(() => {
        if (setupCompleted) {
            fetchLinkedinConversations();
        }
    }, [setupCompleted]);

    useEffect(() => {
        if (setupCompleted) {
            analyzeWebsite();
        }
    }, [setupCompleted]);

    useEffect(() => {
        if (fetchLinkedinConversationsCompleted && analyzeWebsiteCompleted) {
            conversationAnalysis();
        }
    }, [fetchLinkedinConversationsCompleted, analyzeWebsiteCompleted]);

    return (
        <div className="h-full w-full overflow-y-auto">
            <div className="flex flex-col items-center w-full max-w-3xl mx-auto p-4 gap-6 pt-8">
                <OnboardingHeader onboardingCompleted={onboardingComplete} />
                {analyzeWebsiteCompleted && (
                    <motion.div
                        initial={{ x: -200, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ duration: 1 }}
                    >
                        <FirstPlaybookCard />
                    </motion.div>
                )}
                {conversationAnalysisCompleted && (
                    <motion.div
                        initial={{ x: -100, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ duration: 1 }}
                        className="w-full"
                    >
                        <OnboardingActionCard />
                    </motion.div>
                )}
                {analyzeWebsiteCompleted && fetchLinkedinConversationsCompleted && (
                    <motion.div
                        initial={{ x: -100, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ duration: 1 }}
                    >
                        <ContactSuggestionsCard />
                    </motion.div>
                )}
                {onboardingComplete && <OnboardingCompletedSection />}
                <SettingUpExperience isComplete={onboardingComplete} />
            </div>
        </div>
    );
};

const SettingUpExperience: FC<{ isComplete: boolean }> = ({ isComplete }) => {
    const onboardingTipSequence = [
        'Andsend will assist you in managing your business relationships, but your involvement is crucial to keep the connection genuine and thriving.',
        'Elements will appear as they become ready, interact with them to understand the system while we set things up.',
        'Playbooks affect your message suggestions; create more playbooks for different purposes.',
        "Andsend tracks your contacts and automatically suggests messages when they reply or it's time to follow up.",
        'Your agent learns about you and your contacts, improving message suggestions over time.',
        'We are scanning your network and will start suggesting contacts to add to your playbooks.',
    ];
    const [currentTipIndex, setCurrentTipIndex] = useState(0);
    const [direction, setDirection] = useState<'next' | 'prev'>('next');

    useEffect(() => {
        const interval = setInterval(() => {
            goToNextTip();
        }, 7_000);
        return () => clearInterval(interval);
    }, []);

    const goToNextTip = () => {
        setDirection('next');
        setCurrentTipIndex((prevIndex) => (prevIndex + 1) % onboardingTipSequence.length);
    };

    return (
        <div className="flex flex-col w-full items-center pb-8">
            {!isComplete && (
                <div className="flex gap-2 text-md text-gray-500">
                    <TypingIndicator />
                    <p>Setting up your experience...</p>
                </div>
            )}
            <motion.div
                key={currentTipIndex}
                initial={{
                    opacity: 0,
                    x: direction === 'next' ? 50 : -50,
                }}
                animate={{
                    opacity: 1,
                    x: 0,
                }}
                exit={{
                    opacity: 0,
                    x: direction === 'next' ? -50 : 50,
                }}
                transition={{ duration: 0.3 }}
                className="w-full p-2 items-center text-center max-w-2xl"
            >
                <p className="text-gray-500 text-xs">{onboardingTipSequence[currentTipIndex]}</p>
            </motion.div>
        </div>
    );
};

const OnboardingCompletedSection: FC = () => {
    return (
        <AnimatePresence>
            <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{ duration: 1.5 }}
                className={`flex flex-col items-center gap-4 py-16`}
            >
                <div className="w-16 h-16 rounded-full bg-green-50 flex items-center justify-center">
                    <CheckCircle size={30} className="text-green-500 animate-slide-in-from-top" />
                </div>
                <h1 className="text-3xl font-semibold text-gray-900">Your Andsend experience is ready!</h1>
                <p className="text-gray-600 text-lg">
                    Ready to start using Andsend? Press the button to open the full experience
                </p>
                <Link to={paths.NEW.ACTIONS} reloadDocument>
                    <Button variant="default" size="lg" className="py-4">
                        Go to Action Feed
                        <ChevronRight size={16} />
                    </Button>
                </Link>
            </motion.div>
        </AnimatePresence>
    );
};

export default OnboardingFeedPage;
