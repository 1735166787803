import { FC } from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@shadcn/ui/components/ui/card';
import { Tooltip, TooltipProvider, TooltipTrigger, TooltipContent } from '@shadcn/ui/components/ui/tooltip';
import { cn } from '@shadcn/ui/lib/utils';
import { Info } from 'lucide-react';
import { formatDistance } from 'date-fns';

interface StatsCardProps {
    title: string;
    description?: string;
    tooltipDescription?: string;
    currentValue: number;
    previousValue?: number;
    periodName: string;
    format?: 'hours' | 'number';
    icon?: React.ReactNode;
}

export const StatsCard: FC<StatsCardProps> = ({
    title,
    description,
    tooltipDescription,
    currentValue,
    previousValue,
    periodName,
    format = 'number',
    icon,
}) => {
    const difference = previousValue !== undefined ? currentValue - previousValue : 0;
    const percentageChange =
        previousValue !== undefined && previousValue !== 0
            ? Math.round(((currentValue - previousValue) / previousValue) * 100)
            : currentValue > 0
            ? 100
            : 0;
    const isTrendingUp = difference > 0;
    const isNoChange = difference === 0;

    const formatValue = (value: number) => {
        if (value === 0) {
            return '-';
        }
        if (format === 'hours') {
            return formatDistance(0, value * 60 * 60 * 1000, { includeSeconds: false });
        }

        return value.toLocaleString();
    };

    return (
        <Card className="w-full border border-border-brand">
            <CardHeader>
                <div className="flex justify-between items-center">
                    <div className="flex items-center gap-2">
                        {icon}
                        <CardTitle className="text-sm font-medium">{title}</CardTitle>
                    </div>
                    <TooltipProvider>
                        <Tooltip delayDuration={0}>
                            <TooltipTrigger asChild>
                                <div>
                                    {previousValue !== undefined && (
                                        <TooltipProvider>
                                            <Tooltip delayDuration={0}>
                                                <TooltipTrigger asChild>
                                                    <Info className="w-4 h-4 text-brand-500" />
                                                </TooltipTrigger>
                                                <TooltipContent>
                                                    <p>{tooltipDescription || description}</p>
                                                </TooltipContent>
                                            </Tooltip>
                                        </TooltipProvider>
                                    )}
                                </div>
                            </TooltipTrigger>
                        </Tooltip>
                    </TooltipProvider>
                </div>
                {description && <CardDescription>{description}</CardDescription>}
            </CardHeader>

            <CardContent>
                <div className="flex items-center gap-2">
                    <span className="text-2xl font-bold">{formatValue(currentValue)}</span>
                    <div>
                        {previousValue !== undefined && (
                            <TooltipProvider>
                                <Tooltip delayDuration={0}>
                                    <TooltipTrigger asChild>
                                        {isTrendingUp ? (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth={1.5}
                                                stroke="currentColor"
                                                className="w-4 h-4 text-green-500 inline-block"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M3 17l6-6 4 4 8-8"
                                                />
                                            </svg>
                                        ) : isNoChange ? (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth={1.5}
                                                stroke="currentColor"
                                                className="w-4 h-4 text-gray-500 inline-block"
                                            >
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 12h16" />
                                            </svg>
                                        ) : (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth={1.5}
                                                stroke="currentColor"
                                                className="w-4 h-4 text-red-500 inline-block"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M3 7l6 6 4-4 8 8"
                                                />
                                            </svg>
                                        )}
                                    </TooltipTrigger>
                                    <TooltipContent>
                                        <p>
                                            {isTrendingUp ? 'Trending up' : isNoChange ? 'No change' : 'Trending down'}
                                        </p>
                                        {isTrendingUp
                                            ? `+${formatValue(difference)} (${percentageChange}%)`
                                            : isNoChange
                                            ? ``
                                            : `-${formatValue(Math.abs(difference))} (${Math.abs(percentageChange)}%)`}
                                        <p>{periodName}</p>
                                    </TooltipContent>
                                </Tooltip>
                            </TooltipProvider>
                        )}
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};
