import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getLocalStorageByKey } from '../../../services/utils/localStorage';

const initialState: UpsalesStateType = {
    account: {},
    integrationScope: {},
    fieldMapping: [],
    isActive: false,
    availableFields: {},
    availableActivityTypes: [],
    customActivityType: null,
    isOldInterface: false,
    firstTime: false,
    saveStatus: false,
    mapLinkedIn: false,
    mapZaplifyProspectStatus: false,
    mapCampaignName: false,
    mapOrganizationSize: false,
    mapOrganizationIndustry: false,
    mapOrganizationDomain: false,
    mapLinkedInConnection: false,
};

export interface UpsalesStateType {
    account: Account;
    integrationScope: IntegrationScopeType;
    fieldMapping: FieldMapping[];
    isActive: boolean;
    isOldInterface: boolean;
    customActivityType?: number;
    availableFields: AvailableFieldsType;
    availableActivityTypes: ActivityTypes[];
    saveStatus: boolean;
    firstTime: boolean;
    mapLinkedIn: boolean;
    mapZaplifyProspectStatus: boolean;
    mapCampaignName: boolean;
    mapOrganizationSize: boolean;
    mapOrganizationIndustry: boolean;
    mapOrganizationDomain: boolean;
    mapLinkedInConnection: boolean;
}

interface Account {
    customerId?: string;
    uiDomain?: string;
}

export interface FieldMapping {
    prospectField: string;
    contactField?: string;
    companyField?: string;
    required?: boolean;
    errorText?: string;
}

interface IntegrationScopeType {
    prospects?: boolean;
    campaignsActivity?: boolean;
    actionMessages?: boolean;
    gdprDelete?: boolean;
}

interface AvailableFieldsType {
    contact?: AvailableFields[];
    prospect?: AvailableFields[];
    company?: AvailableFields[];
}

interface ActivityTypes {
    id: number;
    name: string;
    roles: [
        {
            name: string;
            id: number;
        }
    ];
}

export interface AvailableFields {
    fieldType: string;
    key: string;
    name: string;
}

const upsalesSlice = createSlice({
    name: 'upsales',
    initialState: initialState as UpsalesStateType,
    reducers: {
        updateSettings(state, action: PayloadAction<any>) {
            const isOldInterfaceLocalStorage = getLocalStorageByKey('isOldInterfaceManual');

            return {
                ...state,
                account: action.payload.account,
                isOldInterface: isOldInterfaceLocalStorage ?? action.payload.isOldInterface,
                customActivityType: action.payload.customActivityType,
                integrationScope: action.payload.integrationScope,
                fieldMapping: action.payload.fieldMapping || [],
                isActive: action.payload.isActive,
            };
        },
        updateAvailableFields(state, action: PayloadAction<any>) {
            state.availableFields = action.payload;
        },
        updateAvailableActivityTypes(state, action: PayloadAction<any>) {
            state.availableActivityTypes = action.payload;
        },
        updateCustomActivityType(state, action: PayloadAction<any>) {
            state.customActivityType = action.payload;
        },
        updateField(state, action: PayloadAction<{ idx: number; field: string; value: any }>) {
            if (action.payload.field === 'compnayField') delete state.fieldMapping[action.payload.idx]['contactField'];
            if (action.payload.field === 'contactField') delete state.fieldMapping[action.payload.idx]['companyField'];
            state.fieldMapping[action.payload.idx][action.payload.field] = action.payload.value;
        },
        updateScope(state, action: PayloadAction<{ key: string; value: any }>) {
            state.integrationScope = { ...state.integrationScope, [action.payload.key]: action.payload.value };
        },
        addField(state) {
            state.fieldMapping = [...state.fieldMapping, { prospectField: '', contactField: '' }];
        },
        addContactField(state) {
            state.fieldMapping = [...state.fieldMapping, { prospectField: '', contactField: '' }];
        },
        addCompanyField(state) {
            state.fieldMapping = [...state.fieldMapping, { prospectField: '', companyField: '' }];
        },
        deleteField(state, action: PayloadAction<{ index: number }>) {
            state.fieldMapping.splice(action.payload.index, 1);
        },
        updateFieldMapping(state, action: PayloadAction<any>) {
            state.fieldMapping = action.payload;
        },
        updateUpsalesActiveState(state, action: PayloadAction<boolean>) {
            state.isActive = action.payload;
        },
        updateUpsalesShowLinkedInMapping(state, action: PayloadAction<boolean>) {
            state.mapLinkedIn = action.payload;
        },
        updateUpsalesShowCampaignNameMapping(state, action: PayloadAction<boolean>) {
            state.mapCampaignName = action.payload;
        },
        updateUpsalesShowLinkedInConnectionMapping(state, action: PayloadAction<boolean>) {
            state.mapLinkedInConnection = action.payload;
        },
        updateUpsalesShowProspecStatusMapping(state, action: PayloadAction<boolean>) {
            state.mapZaplifyProspectStatus = action.payload;
        },
        updateUpsalesShowOrganizationSizeMapping(state, action: PayloadAction<boolean>) {
            state.mapOrganizationSize = action.payload;
        },
        updateUpsalesShowOrganizationIndustryMapping(state, action: PayloadAction<boolean>) {
            state.mapOrganizationIndustry = action.payload;
        },
        updateUpsalesShowOrganizationDomainMapping(state, action: PayloadAction<boolean>) {
            state.mapOrganizationDomain = action.payload;
        },
        updateUpsalesFirstTimeState(state, action: PayloadAction<boolean>) {
            state.firstTime = action.payload;
        },
        updateSaveStatus(state, action: PayloadAction<any>) {
            state.saveStatus = action.payload;
        },
        updateUpsalesCustomSwitches(state, action: PayloadAction<boolean>) {
            state.mapOrganizationDomain = action.payload;
            state.mapOrganizationIndustry = action.payload;
            state.mapOrganizationSize = action.payload;
            state.mapZaplifyProspectStatus = action.payload;
            state.mapCampaignName = action.payload;
            state.mapLinkedIn = action.payload;
            state.mapLinkedInConnection = action.payload;
        },
    },
});

export const {
    updateSettings,
    updateAvailableFields,
    updateAvailableActivityTypes,
    updateCustomActivityType,
    updateField,
    updateScope,
    addField,
    addContactField,
    addCompanyField,
    deleteField,
    updateFieldMapping,
    updateUpsalesActiveState,
    updateUpsalesShowLinkedInMapping,
    updateUpsalesFirstTimeState,
    updateUpsalesShowProspecStatusMapping,
    updateUpsalesShowOrganizationSizeMapping,
    updateUpsalesShowOrganizationIndustryMapping,
    updateUpsalesShowOrganizationDomainMapping,
    updateUpsalesShowCampaignNameMapping,
    updateUpsalesShowLinkedInConnectionMapping,
    updateSaveStatus,
    updateUpsalesCustomSwitches,
} = upsalesSlice.actions;

export const upsalesReducer = upsalesSlice.reducer;
