import { ICommand, Topics } from '@zaplify/common';
import { LinkedinAccountActivitiesDto } from './linkedin-account-activities.dto';

export type ILogs = {
    logs: any[];
    errors: any[];
};

export class TrackFoundLinkedinActivitiesCommand extends ICommand<void> {
    public static override readonly TOPIC = Topics.CAMPAIGNS;

    public userId: string;
    public organizationId: string;
    public linkedinTrackingInfo: LinkedinAccountActivitiesDto;
    /**
     * If true, the cursor of last synced activity will be updated in the database.
     * Do not set unless you can guarantee that all activities up until that point are synced.
     */
    public updateSyncedUntilTimestamps: boolean;

    constructor(
        public readonly params: {
            userId: string;
            organizationId: string;
            linkedinTrackingInfo: LinkedinAccountActivitiesDto;
            updateSyncedUntilTimestamps: boolean;
        },
    ) {
        super();
        this.userId = params?.userId;
        this.organizationId = params?.organizationId;
        this.linkedinTrackingInfo = params?.linkedinTrackingInfo;
        this.updateSyncedUntilTimestamps = params?.updateSyncedUntilTimestamps;
    }
}
