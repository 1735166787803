import { ChannelAccountDto, ChannelAccountStatus, GmailScopes, OutlookScopes } from '@zaplify/channel-accounts/shared';

export const parseEmailAccountStatus = (
    channelAccount: ChannelAccountDto
): {
    address: string | null;
    status: ChannelAccountStatus;
} => {
    if (channelAccount.status == ChannelAccountStatus.NOT_AUTHENTICATED) {
        return {
            address: null,
            status: ChannelAccountStatus.NOT_AUTHENTICATED,
        };
    }
    if (channelAccount.GMAIL) {
        if (
            !GmailScopes.required.every((scope) => channelAccount.GMAIL?.scopes?.includes(scope)) ||
            channelAccount.GMAIL.scopes?.includes('https://www.googleapis.com/auth/gmail.metadata')
        ) {
            return {
                address: channelAccount.GMAIL.emailAddress,
                status: ChannelAccountStatus.SCOPES,
            };
        }
    }
    if (
        channelAccount.OUTLOOK &&
        !OutlookScopes.required.every((scope) => channelAccount.OUTLOOK?.scopes?.includes(scope))
    ) {
        return {
            address: channelAccount.OUTLOOK.emailAddress,
            status: ChannelAccountStatus.SCOPES,
        };
    }
    return {
        address: null,
        status: ChannelAccountStatus.AUTHENTICATED,
    };
};
