import { Label } from '@shadcn/ui/components/ui/label';
import { Switch } from '@shadcn/ui/components/ui/switch';
import { useSearchParams } from 'react-router-dom';

export const HIDE_ADDED_CONTACTS_PARAM = 'hideAdded';

export const ToggleHiddenContacts = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const hideAdded = searchParams.get(HIDE_ADDED_CONTACTS_PARAM) === 'true';

    const toggleHideAdded = () => {
        setSearchParams((prev) => {
            prev.set(HIDE_ADDED_CONTACTS_PARAM, (!hideAdded).toString());
            return prev;
        });
    };

    return (
        <div className="flex items-center space-x-2">
            <Switch
                id="hide-added"
                checked={hideAdded}
                onCheckedChange={toggleHideAdded}
                className="data-[state=checked]:bg-background-brand-primary data-[state=unchecked]:bg-input"
            />
            <Label htmlFor="hide-added">Hide added contacts</Label>
        </div>
    );
};
