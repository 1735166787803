import { Box, CircularProgress, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { getZaplifySdk } from '@zaplify/sdk';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { paths } from '../../../routes/paths';
import FeedbackMessage from '../../../components/form/feedback-message';
import FormInputField from '../../../components/form/form-input-field';
import LoadingButton from '../../../components/form/loading-button';

type ResetPasswordFormValues = {
    email: string;
};

const ResetPasswordForm = () => {
    const navigate = useNavigate();
    const [authErrorMessage, setAuthErrorMessage] = useState<string | null>(null);
    const { register, handleSubmit, formState, getValues } = useForm<ResetPasswordFormValues>({
        defaultValues: {
            email: '',
        },
        mode: 'onChange',
    });
    const { errors } = formState;
    const { mutate: signIn, status } = useMutation({
        mutationFn: async (data: ResetPasswordFormValues) => {
            const zaplifySdk = getZaplifySdk();
            await zaplifySdk.profiles.authentication.resetPassword(data.email);
        },
        onSuccess: () => {
            navigate(
                `${paths.OLD_CONFIRM_PASSWORD_RESET}?email=${encodeURIComponent(getValues('email'))}&resetPassword=true`
            );
        },
        onError: (error: any) => {
            setAuthErrorMessage(error.message || 'An error occurred');
        },
    });

    const onSubmit = (data: ResetPasswordFormValues) => {
        signIn(data);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                <FormInputField<'email'>
                    id="email"
                    label="Email"
                    register={register('email', {
                        required: true,
                        pattern: {
                            value: /\S+@\S+\.\S+/,
                            message: 'Invalid email address',
                        },
                    })}
                    error={errors.email?.message}
                    placeholder="name@work-email.com"
                    type="email"
                    isLoading={status === 'pending'}
                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginTop: '24px',
                    gap: '16px',
                }}
            >
                <LoadingButton
                    isLoading={status === 'pending'}
                    disabled={!formState.isValid || status === 'pending'}
                    type="submit"
                >
                    {status === 'pending' ? (
                        <CircularProgress size={24} sx={{ color: '#969FAC' }} />
                    ) : (
                        <Typography
                            component={'span'}
                            sx={{
                                fontSize: '14px',
                                fontWeight: 500,
                                lineHeight: '21px',
                                color: 'white',
                            }}
                        >
                            Send instructions
                        </Typography>
                    )}
                </LoadingButton>
                <FeedbackMessage id="reset-password-form-feedback-message" error={authErrorMessage} info={''} />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '8px',
                    }}
                >
                    <Link to={paths.OLD_LOGIN} style={{ textDecoration: 'none' }}>
                        <Typography
                            component={'span'}
                            sx={{
                                color: '#6583EF',
                                fontSize: '14px',
                                fontWeight: 500,
                                lineHeight: '21px',
                            }}
                        >
                            Go back to Sign in
                        </Typography>
                    </Link>
                </Box>
            </Box>
        </form>
    );
};

export default ResetPasswordForm;
