import { QueryClient } from '@tanstack/react-query';
import { WebExtensionAction } from './enums';
import { useWebExtensionCommunication } from './use-web-extension-communication';
import {
    ConversationCreateResponse,
    GetConversationsResponse,
    GetConnectionsResponse,
    GetPendingInvitationsResponse,
    InvitationSendResponse,
    MeGetResponse,
    ProfileFullWithExperienceParsed,
    RegularProfileLI,
    ExperienceParsed,
    CompanyParsed,
} from './interfaces';
import {
    ConversationMessageParsed,
    LinkedinConversationsResponse,
    ExperienceEnrichedParsed,
    GetCompanyLinkedinRequest,
} from '@zaplify/web-extension-shared';
import { LinkedinPost } from './interfaces/post.interface';
import dayjs from 'dayjs';

// Query keys
export const webExtensionKeys = {
    all: ['web-extension'] as const,
    me: () => [...webExtensionKeys.all, 'me'] as const,
    profile: (identifier: string) => [...webExtensionKeys.all, 'profile', identifier] as const,
    profileFull: (identifier: string) => [...webExtensionKeys.all, 'profile-full', identifier] as const,
    profileExperiences: (memberId: string) => [...webExtensionKeys.all, 'profile-experiences', memberId] as const,
    conversations: (
        memberId: string,
        keywords: string | undefined,
        nextCursor: string | undefined,
        moreThan: number | undefined,
    ) => [...webExtensionKeys.all, 'conversations', memberId, keywords, nextCursor, moreThan] as const,
    conversationsNew: (memberId: string, startingCursor: string) =>
        [...webExtensionKeys.all, 'conversations-new', memberId, startingCursor] as const,
    conversation: (memberId: string, conversationId: string, countBefore: number) =>
        [...webExtensionKeys.all, 'conversation', memberId, conversationId, countBefore] as const,
    connections: (since: number) => [...webExtensionKeys.all, 'connections', since] as const,
    pendingInvitations: (since: number) => [...webExtensionKeys.all, 'pending-invitations', since] as const,
    posts: (memberId: string) => [...webExtensionKeys.all, 'posts', memberId] as const,
    conversationById: (memberId: string, prospectMemberIds: string[]) =>
        [...webExtensionKeys.all, 'conversation-by-id', memberId, ...prospectMemberIds] as const,
    searchConnections: (keyword: string | undefined, size: number, from: number) =>
        [...webExtensionKeys.all, 'search-connections', keyword || '', size, from] as const,
    company: (linkedinId: string) => [...webExtensionKeys.all, 'company', linkedinId] as const,
};

const THIRTY_SECONDS_STALE_TIME = 30 * 1000;
const ONE_MIN_STALE_TIME = 60 * 1000;
const ONE_HOUR_STALE_TIME = 60 * ONE_MIN_STALE_TIME;
const ONE_DAY_STALE_TIME = 24 * ONE_HOUR_STALE_TIME;
const ONE_WEEK_STALE_TIME = 7 * ONE_DAY_STALE_TIME;

export class WebExtensionActions {
    private queryClient: QueryClient;
    private communication: ReturnType<typeof useWebExtensionCommunication>;

    constructor(queryClient: QueryClient, communication: ReturnType<typeof useWebExtensionCommunication>) {
        this.queryClient = queryClient;
        this.communication = communication;
    }

    // Get Me
    async getMe() {
        return this.queryClient.fetchQuery({
            queryKey: webExtensionKeys.me(),
            queryFn: () => this.communication.sendRequest<MeGetResponse>(WebExtensionAction.ME_GET, {}),
            staleTime: ONE_HOUR_STALE_TIME,
        });
    }

    // Get LinkedIn Profile Full
    async getLinkedInProfileFull(publicIdentifier: string) {
        return this.queryClient.fetchQuery({
            queryKey: webExtensionKeys.profileFull(publicIdentifier),
            queryFn: () =>
                this.communication.sendRequest<ProfileFullWithExperienceParsed>(
                    WebExtensionAction.REGULAR_PROFILE_GET_FULL,
                    {
                        publicIdentifier,
                    },
                ),
            staleTime: ONE_HOUR_STALE_TIME,
        });
    }

    // Get LinkedIn Profile Experiences
    async getLinkedInProfileExperiences(memberId: string) {
        return this.queryClient.fetchQuery({
            queryKey: webExtensionKeys.profileExperiences(memberId),
            queryFn: () =>
                this.communication.sendRequest<ExperienceEnrichedParsed[]>(
                    WebExtensionAction.REGULAR_PROFILE_GET_EXPERIENCES,
                    {
                        memberId,
                    },
                ),
            staleTime: ONE_HOUR_STALE_TIME,
        });
    }

    // Get LinkedIn Profile
    async getLinkedInProfile(publicIdentifier: string) {
        return this.queryClient.fetchQuery({
            queryKey: webExtensionKeys.profile(publicIdentifier),
            queryFn: () =>
                this.communication.sendRequest<RegularProfileLI>(WebExtensionAction.REGULAR_PROFILE_GET, {
                    publicIdentifier,
                }),
            staleTime: ONE_HOUR_STALE_TIME,
        });
    }

    // Get LinkedIn Company
    async getCompany(linkedinId: string) {
        return this.queryClient.fetchQuery({
            queryKey: webExtensionKeys.company(linkedinId),
            queryFn: async () => {
                return this.communication.sendRequest<CompanyParsed>(WebExtensionAction.COMPANY_GET, {
                    companyId: linkedinId,
                });
                // const parser = new GetCompanyLinkedinRequest({ companyNumericId: linkedinId });
                // const response = await this.communication.sendDirectRequest(parser.createRequest());
                // return parser.parseResponse(response);
            },
            staleTime: ONE_WEEK_STALE_TIME,
        });
    }

    // Get Conversations
    async getConversations(payload: {
        memberId: string;
        since?: number;
        moreThan?: number;
        nextCursor?: string;
        keywords?: string;
        cacheStaleTime: number;
    }) {
        return this.queryClient.fetchQuery({
            queryKey: webExtensionKeys.conversations(
                payload.memberId,
                payload.keywords,
                payload.nextCursor,
                payload.moreThan,
            ),
            queryFn: async () => {
                return this.communication.sendRequest<GetConversationsResponse>(
                    WebExtensionAction.CONVERSATION_GET,
                    payload,
                );
            },
            staleTime: payload.cacheStaleTime,
        });
    }

    // Get Conversations
    async getConversationsNew(payload: {
        userMemberId: string;
        since?: number;
        startingCursor?: string;
        cacheStaleTime: number;
    }) {
        return this.queryClient.fetchQuery({
            queryKey: webExtensionKeys.conversationsNew(payload.userMemberId, payload.startingCursor || ''),
            queryFn: async () => {
                return this.communication.sendRequest<LinkedinConversationsResponse>(
                    WebExtensionAction.CONVERSATION_GET_NEW,
                    payload,
                );
            },
            staleTime: payload.cacheStaleTime,
        });
    }

    // Get Messages From Conversation
    async getMessagesFromConversation(payload: {
        memberId: string; // The users memberId
        conversationId: string;
        deliveredAt: number;
        countBefore: number;
        cacheStaleTime: number;
    }) {
        return this.queryClient.fetchQuery({
            queryKey: webExtensionKeys.conversation(payload.memberId, payload.conversationId, payload.countBefore),
            queryFn: () => {
                return this.communication.sendRequest<ConversationMessageParsed[]>(
                    WebExtensionAction.GET_MESSAGES_FROM_CONVERSATION_NEW,
                    payload,
                );
            },
            staleTime: payload.cacheStaleTime,
        });
    }

    // Send Invitation
    async sendInvitation(payload: {
        prospectMemberId: string;
        messageContent?: { text: string };
        isUserPremium?: boolean;
    }) {
        const mutation = await this.queryClient.getMutationCache().build(this.queryClient, {
            mutationFn: () => {
                return this.communication.sendRequest<InvitationSendResponse>(
                    WebExtensionAction.INVITATION_SEND,
                    payload,
                );
            },
        });
        return mutation.execute(payload);
    }

    // Send Message
    async sendMessage(payload: { memberId: string; prospectMemberId: string; messageContent: { text: string } }) {
        const mutation = await this.queryClient.getMutationCache().build(this.queryClient, {
            mutationFn: () => {
                return this.communication.sendRequest<ConversationCreateResponse>(
                    WebExtensionAction.CONVERSATION_CREATE,
                    payload,
                );
            },
        });
        return mutation.execute(payload);
    }

    // Get Accepted Connections
    async getAcceptedConnections(since: number) {
        return this.queryClient.fetchQuery({
            queryKey: webExtensionKeys.connections(since),
            queryFn: () => {
                return this.communication.sendRequest<GetConnectionsResponse>(WebExtensionAction.ME_GET_CONNECTIONS, {
                    since,
                });
            },
            staleTime: ONE_MIN_STALE_TIME,
        });
    }

    async getLinkedinPosts(memberId: string) {
        return this.queryClient.fetchQuery({
            queryKey: webExtensionKeys.posts(memberId),
            queryFn: async () => {
                let result = await this.communication.sendRequest<LinkedinPost[]>(
                    WebExtensionAction.REGULAR_PROFILE_GET_POSTS,
                    { memberId },
                );

                result = result
                    .filter(
                        (post) =>
                            post?.postedOnString &&
                            !post.postedOnString.includes('year') &&
                            !post.postedOnString.includes('months'),
                    )
                    .map((post) => {
                        const postedOnDate = this.parsePostedOnString(post.postedOnString);
                        return { ...post, postedOnDate };
                    });

                return result;
            },
            staleTime: ONE_HOUR_STALE_TIME,
        });
    }

    parsePostedOnString = (postedOn: string): string => {
        const match = postedOn.match(/(\d+) (\w+) ago/);
        if (!match) return postedOn;

        const value = parseInt(match[1]);
        const unit = match[2];

        const postedOnDate = dayjs()
            .subtract(value, unit.toLowerCase() as dayjs.ManipulateType)
            .endOf(unit as dayjs.ManipulateType);

        return postedOnDate.format('DD MMM');
    };

    // Get Pending Invitations
    async getPendingInvitations(since: number) {
        return this.queryClient.fetchQuery({
            queryKey: webExtensionKeys.pendingInvitations(since),
            queryFn: () => {
                return this.communication.sendRequest<GetPendingInvitationsResponse>(
                    WebExtensionAction.ME_GET_PENDING_INVITATIONS,
                    { since },
                );
            },
            staleTime: ONE_MIN_STALE_TIME,
        });
    }

    // Get Conversation From Id
    async getConversationFromId(payload: {
        memberId: string;
        prospectMemberIds: string[];
        personData: {
            firstName?: string;
            lastName?: string;
            memberId: string;
        };
    }) {
        return this.queryClient.fetchQuery({
            queryKey: webExtensionKeys.conversationById(payload.memberId, payload.prospectMemberIds),
            queryFn: () => {
                return this.communication.sendRequest<GetConversationsResponse>(
                    WebExtensionAction.CONVERSATION_GET_ID,
                    payload,
                );
            },
            staleTime: ONE_MIN_STALE_TIME,
        });
    }

    // Search Connections
    async searchConnections(payload: { keyword?: string; size: number; from: number }) {
        return this.queryClient.fetchQuery({
            queryKey: webExtensionKeys.searchConnections(payload.keyword, payload.size, payload.from),
            queryFn: () => {
                return this.communication.sendRequest<GetConnectionsResponse>(
                    WebExtensionAction.ME_FIND_CONNECTIONS,
                    payload,
                );
            },
            staleTime: ONE_MIN_STALE_TIME,
        });
    }
}
