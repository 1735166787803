/* global chrome */
import { ChromeExtensionNotFoundError } from '@zaplify/sdk';
const extensionId = import.meta?.env?.VITE_ZAPLIFY_CHROME_EXTENSION_ID || 'nolanbablkmlhllilaloenjhaplnfhof';

export const sendMessageToRuntime = (actionName: any, payload?: any) => {
    return new Promise((resolve, reject) => {
        if (window['chrome'] && window['chrome'].runtime) {
            const replyHandler = (reply: any) => {
                if (chrome.runtime.lastError) {
                    reject(chrome.runtime.lastError);
                }
                resolve(reply);
            };
            const message = { action: actionName, payload, replyHandler };
            window['chrome'].runtime.sendMessage(extensionId, message, replyHandler);
            return replyHandler;
        }
        reject(new ChromeExtensionNotFoundError('No chrome runtime found'));
    });
};

export const extensionStuff = () => {
    (async () => {
        const result = await sendMessageToRuntime('GET_EXTENSION_VERSION');
    })();
};

// Connect to Port
interface IConnectToPortArg {
    extensionId: string;
    portName: string;
    messageListener: (msg: chrome.runtime.PortMessageEvent) => void;
    onReconnected: (msg: chrome.runtime.PortMessageEvent) => void;
}
const reconnectWhenReady = ({ extensionId, portName, messageListener, onReconnected }: IConnectToPortArg) => {
    let port: chrome.runtime.Port | undefined;
    return port;
    // if (
    //   isChromeAvailable() === !1 ||
    //   !(await checkExtensionConnection(
    //     () =>
    //       la({
    //         extensionId: extensionId,
    //         message: {
    //           action: "GLOBAL/READY_TO_CONNECT",
    //         },
    //       }),
    //     1e3,
    //     5,
    //     []
    //   ))
    // )
    //   return;
    // const o = await uR({
    //   extensionId: extensionId,
    //   portName: portName,
    //   messageListener: messageListener,
    //   onReconnected: onReconnected,
    // });
    // return onReconnected && onReconnected(), o.port;
};

let port: chrome.runtime.Port | undefined;
const connect = async ({ extensionId, portName, messageListener, onReconnected }: IConnectToPortArg) => {
    console.log('in connect');
    return new Promise((resolve, reject) => {
        if (!window?.chrome?.runtime) {
            return reject('Chrome runtime not found');
        }
        port = window.chrome.runtime.connect(extensionId, {
            name: portName,
        });
        port.onDisconnect.addListener(async () => {
            console.log('disconnected from chrome extension');
            if (port) {
                port.onMessage.removeListener(messageListener);
                port.disconnect();
                port = undefined;
            }
            const reconnectedPort = await reconnectWhenReady({
                extensionId: extensionId,
                portName: portName,
                messageListener: messageListener,
                onReconnected: onReconnected,
            });
            if (!reconnectedPort || reconnectedPort['errors']) {
                // Handle error
            } else {
                port = reconnectedPort;
                port.onMessage.addListener(messageListener);
            }
        });
        port.onMessage.addListener(messageListener);
        // @TODO Figure out how to resolve on connect
        resolve({
            success: true,
            port: port,
        });
    });
};

// Example usage
export const exampleConnectToLiRealtime = (onMessage: (msg: chrome.runtime.PortMessageEvent) => void) => {
    console.log('connecting...');
    let lastHeartbeat = new Date();

    setInterval(async () => {
        const msSinceLastHeartbeat = Date.now() - lastHeartbeat.getTime();
        console.log('interval', lastHeartbeat, msSinceLastHeartbeat);
        if (msSinceLastHeartbeat < 60_000) {
            console.log('ok!', lastHeartbeat, msSinceLastHeartbeat);
            return; //Ok. we're still alive.
        }
        // Not alive. Reload extension, but don't try an infinite number of times
        if (msSinceLastHeartbeat < 600_000) {
            console.log('reloading!');
            await sendMessageToRuntime('GLOBAL_RELOAD_EXTENSION', {});
        }
    }, 15_000);

    connect({
        extensionId: extensionId,
        portName: 'linkedin_realtime',
        messageListener: async (message) => {
            console.log('received message from extension!', message);
            lastHeartbeat = new Date();
            onMessage(message);
        },
        onReconnected: () => {
            console.log('reconnected with chrome extension');
            lastHeartbeat = new Date();
        },
    });
};

const exampleAskForLinkedinActivities = async () => {
    if (true) {
        // console.log('setting interval');
        // console.log('send message async');
        // const res = await sendMessageAsync({
        //     action: 'MESSAGE_SEND',
        //     payload: {
        //         conversationUrn:
        //             'urn:li:msg_conversation:(urn:li:fsd_profile:ACoAABXq3zgBMn3wl8CH20ruBWmOEr36dxJfqOQ,2-MDM3MTk5ZTMtOWFlYy00MWYwLWFhNTgtNzA1MWMxMzljZWQ2XzAxMg==)',
        //         messageContents: [
        //             {
        //                 dispatchOrder: 1,
        //                 text: 'This message was sent. For sure.',
        //             },
        //         ],
        //     },
        // });
        // const res = await sendMessageAsync({
        //     action: 'MESSAGE_GET_MESSAGES_FROM_CONVERSATION',
        //     payload: {
        //         conversationId: '2-MDM3MTk5ZTMtOWFlYy00MWYwLWFhNTgtNzA1MWMxMzljZWQ2XzAxMg==',
        //         deliveredAt: 1654862455274,
        //         countBefore: 20,
        //     },
        // });
        // const res = await sendMessageAsync({
        //     action: 'CONVERSATION_CREATE',
        //     payload: {
        //         memberId: 'ACoAAB2-KwQBWJH5qKZ---crhZpza0dB0-rv39A',
        //         messageContent: { text: 'Hi there,' },
        //     },
        // });
        // const res = await sendMessageAsync({
        //     action: 'CONVERSATION_GET',
        //     payload: {
        //         since: Math.round(Date.now() - 86400 * 3 * 1000),
        //     },
        // });
        // console.log('sending to background');
        // const res = await sendToBackground({
        //     // @ts-ignore
        //     name: 'LINKEDIN',
        //     body: {
        //         action: 'CONVERSATION_GET',
        //         payload: {
        //             since: Math.round(Date.now() - 86400 * 3 * 1000),
        //         },
        //     },
        //     extensionId: extensionId, // find this in chrome's extension manager
        // });
        // // messageId
        // // parsed data for the other commands
        // console.log('successfully sent message async', res);
        // console.log('running');
        // const res = await sendMessageToRuntime('REGULAR_PROFILE_GET', {
        //     publicIdentifier: 'paularunsten',
        // });
        // console.log('result', res);
        // console.log('running');
        // const res2 = await sendMessageToRuntime('INVITATION_SEND', {
        //     prospectMemberId: res.memberId,
        // });
        // console.log('result', res2);
    }
};

setTimeout(async () => {
    await exampleAskForLinkedinActivities();
    // exampleConnectToLiRealtime();
}, 3000);
