const figmaColors = {
    white: '#FFFFFF',
    black: '#23323F',
    grey: '#6F7782',
    darkGrey: '#6B7985',
    paleGrey: '#B7BFC6',
    greyLighter: '#CDD1DA',
    discreteGrey: '#646F79',
    whiteGrey: '#F7F8FA',
    strokeGrey: '#E1E6E8',
    informationGreyDark: '#555A72',
    informationGreyPale: '#E4E6EB',
    hoverGrey: '#DDDFE4',
    iconGrey: '#6F7782',
    iconGreyPale: '#CDD1DA',
    badgeGrey: '#F0F0F0',
    disabledGrey: '#E9EAED',
    dropDownGrey: '#EFF0F2',
    lightGrey: '#96A2AC',
    iconDefaultGrey: '#909AA4',
    iconGreyDark: '#2B2D36',
    darkerGrey: '#454964',
    selectGrey: '#F0F1F4',
    shadow: '#60657B',
    blue: '#255AE3',
    secondaryBlue: '#EFF4FE',
    blueLighter: '#5f8efa',
    blueDiamond: '#0a66c2',
    deepDenim: '#5f8efa',
    babyBlue: '#4279F6',
    bluePale: '#5F8EFA',
    blueAlphabet: '#AFC6F4',
    blueCloudless: '#D8E5FF',
    inactiveBlue: '#A9C3FF',
    lightBlue: '#EFF4FE',
    red: '#FE5C5E',
    error: '#872336',
    redPale: '#FF787A',
    purple: '#8B78BB',
    darkPurple: '#A073AB',
    brightPurple: '#796EFF',
    yellow: '#F6AE42',
    melon: '#FF7A59',
    green: '#4DCD7D',
    mint: '#02B07D',
    email: '#FE5C5E',
    linkedIn: '#0E76A8',
    linkedIn2: '#0A66C2',
    twitter: '#5BC0FA',
    subline: '#3A4B59',
    orangeWhite: '#FEF7EC',
    blueLight: '#EFF4FE',
    newGreen: '#00C281',
    greenLight: '#E6F4F0',
    redLight: '#FCE3E7',
    blueDarker: '#7090b0',
    mediumBlue: '#E0E8FF',
    pink: '#FFE2E3',
    headlines: '#151B26',
    golden: '#FFC570',
    purpleText: '#8D72C8',
    gold: '#FEC165',
};

const customColors = {
    white: figmaColors.white,
    whiteDark: figmaColors.whiteGrey,
    black: figmaColors.black,
    grey: figmaColors.grey,
    greyLight: figmaColors.paleGrey,
    lightGrey: figmaColors.lightGrey,
    greyDark: figmaColors.darkGrey,
    greyDiscrete: figmaColors.discreteGrey,
    greyStroke: figmaColors.strokeGrey,
    greyInformationDark: figmaColors.informationGreyDark,
    greyInformationLight: figmaColors.informationGreyPale,
    greyHover: figmaColors.hoverGrey,
    greyIcon: figmaColors.iconGrey,
    greyPaleIcon: figmaColors.iconGreyPale,
    greyBadge: figmaColors.badgeGrey,
    greyDisabled: figmaColors.disabledGrey,
    greyDropDownHover: figmaColors.dropDownGrey,
    greyIconDefault: figmaColors.iconDefaultGrey,
    greyHoverDark: figmaColors.iconGreyDark,
    greyDarker: figmaColors.darkerGrey,
    greySelect: figmaColors.selectGrey,
    blue: figmaColors.babyBlue,
    secondaryBlue: figmaColors.secondaryBlue,
    blueLighter: figmaColors.blueLighter,
    blueDiamond: figmaColors.blueDiamond,
    deepDenim: figmaColors.deepDenim,
    bluePale: figmaColors.bluePale,
    blueAlphabet: figmaColors.blueAlphabet,
    blueCloudless: figmaColors.blueCloudless,
    blueBackground: figmaColors.blue,
    blueInactive: figmaColors.inactiveBlue,
    blueLight: figmaColors.blueLight,
    red: figmaColors.red,
    error: figmaColors.error,
    redPale: figmaColors.redPale,
    redLight: figmaColors.redLight,
    green: figmaColors.green,
    mint: figmaColors.mint,
    mediumBlue: figmaColors.mediumBlue,
    greenLight: figmaColors.greenLight,
    yellow: figmaColors.yellow,
    purple: figmaColors.purple,
    purpleDark: figmaColors.darkPurple,
    purpleBright: figmaColors.brightPurple,
    email: figmaColors.email,
    linkedIn: figmaColors.linkedIn,
    linkedIn2: figmaColors.linkedIn2,
    twitter: figmaColors.twitter,
    subline: figmaColors.subline,
    lightBlue: figmaColors.lightBlue,
    shadow: figmaColors.shadow,
    pink: figmaColors.pink,
    purpleText: figmaColors.purpleText,
    gradient: 'linear-gradient(268.89deg, #FD5E60 17.77%, #5F8EFA 80.67%)',
    overlay: {
        bright: (opacity) => `rgba(255,255,255,${opacity / 100})`,
        dark: (opacity) => `rgba(0,0,0,${opacity / 100})`,
    },
    greyLighter: figmaColors.greyLighter,
    orangeWhite: figmaColors.orangeWhite,
    newGreen: figmaColors.newGreen,
    blueDarker: figmaColors.blueDarker,
    melon: figmaColors.melon,
    headlines: figmaColors.headlines,
    golden: figmaColors.golden,
    gold: figmaColors.gold,
};

export const palette = {
    primary: {
        main: customColors.blue,
        light: customColors.bluePale,
    },
    secondary: {
        main: customColors.red,
        light: customColors.redPale,
    },
    channels: {
        linkedIn: customColors.linkedIn,
        email: customColors.email,
        twitter: customColors.twitter,
    },
    error: {
        main: customColors.red,
    },
    text: {
        primary: customColors.black,
        secondary: customColors.grey,
        filled: customColors.greyDark,
        disabled: customColors.greyLight,
        white: customColors.white,
        discrete: customColors.greyDiscrete,
        link: customColors.blue,
    },
    background: {
        default: customColors.whiteDark,
        paper: customColors.white,
        white: customColors.white,
        blue: customColors.blueBackground,
        grey: '#E7E7E7',
    },
    graphics: {
        stroke: customColors.greyStroke,
        informationDark: customColors.greyInformationDark,
        informationLight: customColors.greyInformationLight,
        hover: customColors.greyHover,
        icon: customColors.greyIcon,
        badge: customColors.greyBadge,
        disabled: customColors.greyDisabled,
        dropDownHover: customColors.greyDropDownHover,
        iconDefault: customColors.greyIconDefault,
        iconHover: customColors.greyHoverDark,
        infoBox: customColors.greyDarker,
        newGreen: customColors.newGreen,
    },
    state: {
        success: customColors.green,
        error: customColors.red,
        inactive: customColors.blueInactive,
    },
    accents: {
        purple: customColors.purple,
        darkPurple: customColors.purpleDark,
        brightPurple: customColors.purpleBright,
        yellow: customColors.yellow,
    },
    shadow: customColors.shadow,
    divider: customColors.greyLight,
    colors: customColors,
    gradient: customColors.gradient,
    black: '#23323F',
    subline: '#3A4B59',
    lightGrey: '#96A2AC',
    darkGrey: '#6B7985',
    lighterBlue: '#F7F9FF',
    newGreen: customColors.newGreen,
    orange: '#E9A847',
    redDark: '#872336',
    darkBlue: '#467CF6',
    mediumBlue: customColors.mediumBlue,
    yellowBackground: '#FEF7EC',
    linkedIn: '#E9A847',
    gradients: {
        grey: `linear-gradient(to right, ${customColors.greyDark}, ${customColors.grey})`,
        // TODO switch from figmaColors to customColors
        red: `linear-gradient(to right, ${figmaColors.red}, ${figmaColors.red})`,
        blue: `linear-gradient(to right, ${customColors.blue}, #62A1FF)`,
        blueLight: `linear-gradient(90deg, ${figmaColors.blueLighter}24 0%, ${figmaColors.blueDiamond}00 100%, ${figmaColors.deepDenim}00 100%)`,
        twitter: `linear-gradient(90deg, #0E76A8 0%, #1590CB 100%)`,
        linkedIn: `linear-gradient(90deg, #0E76A8 0%, #1590CB 100%)`,
        email: 'linear-gradient(90deg, #FE5C5E 0%, #FF7476 100%)',
        taskbar: `linear-gradient(89.71deg, #959BEB 0%, #E6A097 100%)`,
        icon: `linear-gradient(89.71deg, #CBCFFF 0%, #F8C9BA 100%)`,
        iconGradient: `linear-gradient(180deg, #CDD1DA 0%, #B5B9C1 100%)`,
        golden: `linear-gradient(180deg, #FFC570 0%, #E9A847 100%)`,
        blueRed: `linear-gradient(270deg, #FD5E60 0%, #5F8EFA 100%)`,
    },
};
