import { gql } from '../__generated__';

export const OUTREACH_SUGGESTIONS_SUBSCRIPTION = gql(`
    subscription OnOutreachSuggestionAdded(
        $limit: Int
        $sentOn: Boolean
        $scheduledOnLt: timestamp
    ) {
        OutreachSuggestions(
            limit: $limit
            where: {
                scheduledOn: { _lt: $scheduledOnLt }
                Message: { sentOn: { _is_null: $sentOn } }
            }
            order_by: [{ score: desc }, { Message: { createdAt: desc } }]
        ) {
            accountName
            name
            id
            createdAt
            updatedAt
            userId
            prospectId
            unread
            scheduledOn
            score
            Message {
                id
                subjectLine
                content
                actionType
                channelType
                linkedinUserId
            }
            ConversationMetaData {
                id
                numberOfMessages
                isNextMessageFollowUp
                messagesPerDay
                lastNetworkUpdateAt
                youThemMessageRatio
                lastUserNoteAt
                hasMessagesSentFromApp
                medianMessageLength
                connectionStatus
                lastTimeToAnswer
                userContactAddedAt
                lastMessageAt
                numberOfMessagesLastDay
                numberOfMessagesLastWeek
                userContactFromOnboarding
                createdAt
                updatedAt
            }
        }
    }
`);

export const ACTIONS_SUBSCRIPTION_BY_PROSPECT_IDS = gql(`
    subscription ActionsSubscriptionByProspectIds(
        $limit: Int
        $scheduledOnLt: timestamp
        $prospectIds: [String!]!
    ) {
        OutreachSuggestions(
            limit: $limit
            where: {
                scheduledOn: { _lt: $scheduledOnLt }
                Message: { sentOn: { _is_null: true } }
                prospectId: { _in: $prospectIds }
            }
            order_by: [{ score: desc }, { Message: { createdAt: desc } }]
        ) {
            id 
            createdAt
            prospectId
            accountName
            name
            unread
            isGenerating
            goal
            Message {
                content
                subjectLine
            }
            ConversationMetaData {
                id
                numberOfMessages
                isNextMessageFollowUp
                messagesPerDay
                lastNetworkUpdateAt
                youThemMessageRatio
                lastUserNoteAt
                hasMessagesSentFromApp
                medianMessageLength
                connectionStatus
                lastTimeToAnswer
                userContactAddedAt
                lastMessageAt
                numberOfMessagesLastDay
                numberOfMessagesLastWeek
                userContactFromOnboarding
                createdAt
                updatedAt
                topics
            }
        }
    }
`);

export const ACTIONS_SUBSCRIPTION = gql(`
    subscription ActionsSubscription(
        $limit: Int
        $scheduledOnLt: timestamp
    ) {
        OutreachSuggestions(
            limit: $limit
            where: {
                scheduledOn: { _lt: $scheduledOnLt }
                Message: { sentOn: { _is_null: true } }
            }
            order_by: [{ score: desc }, { Message: { createdAt: desc } }]
        ) {
            id 
            createdAt
            prospectId
            accountName
            name
            unread
            isGenerating
            goal
            Message {
                content
                subjectLine
            }
            ConversationMetaData {
                id
                numberOfMessages
                isNextMessageFollowUp
                messagesPerDay
                lastNetworkUpdateAt
                youThemMessageRatio
                lastUserNoteAt
                hasMessagesSentFromApp
                medianMessageLength
                connectionStatus
                lastTimeToAnswer
                userContactAddedAt
                lastMessageAt
                numberOfMessagesLastDay
                numberOfMessagesLastWeek
                userContactFromOnboarding
                createdAt
                updatedAt
                topics
            }
        }
    }
`);

export const COMPLETED_ACTIONS_SUBSCRIPTION = gql(`
    subscription CompletedActionsSubscription(
        $limit: Int
        $scheduledOnLt: timestamp
        $completedOnGte: timestamp
    ) {
        OutreachSuggestions(
            limit: $limit
            where: {
                scheduledOn: { _lt: $scheduledOnLt }
                Message: { sentOn: { _is_null: false, _gte: $completedOnGte } }
            }
            order_by: { Message: { sentOn: desc } }
        ) {
            id
            createdAt
            prospectId
            accountName
            name
            goal
            Message {
                content
                subjectLine
                actionType
            }
            ConversationMetaData {
                topics
            }
        }
    }
`);

export const GET_PROSPECT_NOTES_SUBSCRIPTION = gql(`
subscription GetProspectNotesSubscription($prospectId: String!) {
  Notes(where: {prospectId: {_eq: $prospectId}, discarded: {_eq: false}}, order_by: [{createdAt: asc_nulls_last}]) {
    id
  }
}
`);

export const COMPLETED_OUTREACH_SUGGESTIONS_SUBSCRIPTION = gql(`
    subscription CompletedOutreachSuggestions(
        $limit: Int
        $sentOn: Boolean
        $scheduledOnLt: timestamp
        $sentOnGte: timestamp
    ) {
        OutreachSuggestions(
            limit: $limit
            where: {
                scheduledOn: { _lt: $scheduledOnLt }
                Message: { sentOn: { _is_null: $sentOn, _gte: $sentOnGte } }
            }
            order_by: [{ score: desc }, { Message: { createdAt: desc } }]
        ) {
            accountName
            name
            id
            createdAt
            updatedAt
            userId
            prospectId
            unread
            scheduledOn
            score
            Message {
                id
                subjectLine
                content
                actionType
                channelType
                linkedinUserId
                sentOn
            }
            ConversationMetaData {
                id
                numberOfMessages
                isNextMessageFollowUp
                messagesPerDay
                lastNetworkUpdateAt
                youThemMessageRatio
                lastUserNoteAt
                hasMessagesSentFromApp
                medianMessageLength
                connectionStatus
                lastTimeToAnswer
                userContactAddedAt
                lastMessageAt
                numberOfMessagesLastDay
                numberOfMessagesLastWeek
                createdAt
                updatedAt
            }
        }
    }
`);

export const NEXT_SCHEDULED_OUTREACH_SUGGESTIONS_SUBSCRIPTION = gql(`
    subscription NextScheduledOutreachSuggestions(
        $prospectId: String!
    ) {
        OutreachSuggestions(
            limit: 1
            where: {
                Message: { sentOn: { _is_null: true } }
                prospectId: { _eq: $prospectId }
            }
        ) {
            accountName
            name
            id
            createdAt
            prospectId
            unread
            scheduledOn
            scheduledOnMotivation
            Message {
                id
                subjectLine
                content
                actionType
                channelType
                linkedinUserId
                direction
                sentOn
                updatedAt
            }
        }
    }
`);

export const GET_CONVERSATIONS_W_LAST_MSG_SUBSCRIPTION = gql(`
  subscription OnMessageAddedByProspectId {
    Messages(where: {sentOn: {_is_null: false}}, order_by: [{prospectId: desc}, {sentOn: desc}], distinct_on: prospectId) {
      id
      prospectId
      sentOn
      fullName
      content
      subjectLine
      direction
      actionType
      prospectFullName
      deliveryStatus
      OutreachSuggestion {
        id
      }
    }
  }
`);

export const GET_LINKEDIN_CONVERSATIONS_SUBSCRIPTION = gql(`
    subscription GetLinkedinConversationsSubscription {
        LinkedinConversations(order_by: {lastMessageSentAt: desc}) {
            id
            prospectId
            lastMessageType
            lastMessageText
            lastMessageSentAt
            lastMessageSentDirection
            participantMemberIds
        }
    }
`);

export const PROSPECT_ACTIONS_SUBSCRIPTION = gql(`
    subscription GetOutreachSuggestion($prospectId: String!, $scheduledOnBefore: timestamp!) {
        OutreachSuggestions(where: {prospectId: {_eq: $prospectId}, scheduledOn: { _lt: $scheduledOnBefore }, Message: {sentOn: {_is_null: true}}}, order_by: {Message: {createdAt: desc}}) {
            id
            accountName
            name
            createdAt
            updatedAt
            userId
            prospectId
            unread
            scheduledOn
            score
            goal
            isGenerating
            Message {
                id
                createdAt
                subjectLine
                content
                actionType
                channelType
                linkedinUserId
            }
            ConversationMetaData {
                topics
            }
        }
    }
`);

export const PROSPECT_FUTURE_ACTIONS_SUBSCRIPTION = gql(`
    subscription GetProspectFutureActions($prospectId: String!, $scheduledOnBefore: timestamp!) {
        OutreachSuggestions(where: {prospectId: {_eq: $prospectId}, scheduledOn: {_gt: $scheduledOnBefore}, Message: {sentOn: {_is_null: true}}}, order_by: {Message: {createdAt: desc}}) {
            id
            scheduledOn
            scheduledOnMotivation
        }
    }
`);

export const PROSPECT_UNSCHEDULED_ACTIONS_SUBSCRIPTION = gql(`
    subscription GetProspectUnscheduledActions($prospectId: String!) {
        OutreachSuggestions(where: {prospectId: {_eq: $prospectId}, scheduledOn: {_is_null: true}, Message: {sentOn: {_is_null: true}}}, order_by: {Message: {createdAt: desc}}) {
            id
            scheduledOnMotivation
        }
    }
`);

export const MESSAGE_SUBSCRIPTION = gql(`
    subscription OnMessageUpdated($messageId: String!) {
        Messages(
            where: { id: { _eq: $messageId } }
        ) {
            id
            subjectLine
            content
            actionType
            channelType
            deliveryStatus
            Attachments {
                type
                value
            }
        }
    }
`);

export const GET_PROSPECT_MESSAGES_HISTORY_SUBSCRIPTION = gql(`
subscription GetProspectMessagesHistorySubscription($prospectId: String!) {
  Messages(where: {prospectId: {_eq: $prospectId}, sentOn: {_is_null: false}}, order_by: [{sentOn: asc_nulls_last}]) {
    id
  }
}
`);

export const ASSISTANT_SETTINGS_SUBSCRIPTION = gql(`
    subscription GetAssistantSettingsStreamingSubscription {
        AssistantSettings {
            id
            userId
            organizationId
            purpose
            language
            languages
            tone
            context
            defaultChannel
            createdAt
            updatedAt
        }
    }
`);

export const USER_CONTACTS_SUBSCRIPTION = gql(`
    subscription GetUserContactsSubscription {
        UserContacts {
            id
            prospectId
            status
            _GroupToUserContacts {
                Group {
                    id
                    name
                    imgSrc
                }
            }
        }
    }
`);

export const USER_CONTACTS_BY_IDS_SUBSCRIPTION = gql(`
    subscription OnUserContactsByIds($prospectIds: [String!]!) {
        UserContacts(where: {prospectId: {_in: $prospectIds}}) {
            id
            prospectId
            status
            _GroupToUserContacts {
                Group {
                    id
                    name
                    imgSrc
                }
            }
        }
    }
`);

export const GROUPS_SUBSCRIPTION = gql(`
    subscription GetGroups {
        Groups(order_by: {createdAt: asc}) {
            id
            userId
            default
            name
            assistantSettingsId
            imgSrc
            createdAt
            updatedAt
        }
    }
`);

export const NOTES_SUBSCRIPTION = gql(`
    subscription GetNotes {
        Notes(where: { discarded: { _eq: false } }, order_by: { createdAt: asc }) {
            id
            prospectId
            creator
            content
            title
            createdAt
            updatedAt
        }
    }
`);

export const GET_CONTACT_SUGGESTIONS_COUNT_SUBSCRIPTION = gql(`
    subscription GetContactSuggestionsCountSubscription {
        ContactSuggestions_aggregate(
            where: {approvedAt: {_is_null: true}, rejectedAt: {_is_null: true}, expiresAt: {_gt: "now()"}}
        ) {
            aggregate {
                count
            }
        }
    }
`);

export const GET_MESSAGES_SENT_FROM_APP_SUBSCRIPTION = gql(`
    subscription GetMessagesSentFromAppSubscription {
        Messages_aggregate(where: {isSentFromApp: {_eq: true}, sentOn: {_is_null: false}, direction: {_eq: "out"}}) {
            aggregate {
      count
    }
  }
}
`);
