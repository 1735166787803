const userActionsPrefix = '@@user';
const campaignActionPrefix = '@@campaigns';
const newCampaignActionPrefix = '@@new-campaigns';
const userOrganizationActionPrefix = '@@user-organization';
const creditsActionPrefix = '@@credits';
const uiActionsPrefix = '@@user-interface';
const prospectSearchActionsPrefix = '@@prospect-search';
const outreachActionsPrefix = '@@outreach';
const socketActionPrefix = '@@socket';
const hubspotActionPrefix = '@@hubspot';
const webhooksActionPrefix = '@@web-hooks';
const importProspectsActionPrefix = '@@importProspects';

export const userActionTypes = {
    LOGIN_SUCCESS: `${userActionsPrefix}/LOGIN-SUCCESS`,
    LOGIN_FAILED: `${userActionsPrefix}/LOGIN-FAILED`,
    FETCH_USER_SUCCESS: `${userActionsPrefix}/FETCH-USER-SUCCESS`,
    FETCH_USER_FAILED: `${userActionsPrefix}/FETCH-USER-FAILED`,
    LOGOUT_SUCCESS: `${userActionsPrefix}/LOGOUT-SUCCESS`,
    LOGOUT_FAILED: `${userActionsPrefix}/LOGOUT-FAILED`,
    REGISTER_SUCCESS: `${userActionsPrefix}/REGISTER-SUCCESS`,
    REGISTER_FAILED: `${userActionsPrefix}/REGISTER-FAILED`,
    AUTH_STATE: `${userActionsPrefix}/AUTH-STATE`,
    CLEAN_AUTH_ERROR: `${userActionsPrefix}/CLEAN-AUTH-ERROR`,
    START_LOADING: `${userActionsPrefix}/START_LOADING`,
    STOP_LOADING: `${userActionsPrefix}/STOP_LOADING`,
    UPDATE_APPS: `${userActionsPrefix}/UPDATE-APPS`,
    PREVENT_REDIRECT_TO_LOGIN: `${userActionsPrefix}/PREVENT_REDIRECT_TO_LOGIN:`,
    SET_SUBSCRIPTION: `${userActionsPrefix}/SET_SUBSCRIPTION`,
    SET_CHANNEL_ACCOUNTS: `${userActionsPrefix}/SET_CHANNEL_ACCOUNTS`,
    UPDATE_SUBSCRIPTION_LOADING: `${userActionsPrefix}/UPDATE_SUBSCRIPTION_LOADING`,
    UPDATE_CREATED_FREE_SUBSCRIPTION: `${userActionsPrefix}/UPDATE_CREATED_FREE_SUBSCRIPTION`,
};

export const campaignActionTypes = {
    UPDATE_CAMPAIGNS: `${campaignActionPrefix}/UPDATE-CAMPAIGNS`,
    UPDATE_STATISTICS: `${campaignActionPrefix}/UPDATE-STATISTICS`,
    UPDATE_CAMPAIGN: `${campaignActionPrefix}/UPDATE-CAMPAIGN`,
    UPDATE_CAMPAIGN_NAME: `${campaignActionPrefix}/UPDATE_CAMPAIGN_NAME`,
    ADD_SOURCE: `${campaignActionPrefix}/ADD-SOURCE`,
    UPDATE_SOURCES: `${campaignActionPrefix}/UPDATE-SOURCES`,
    ADD_CAMPAIGN: `${campaignActionPrefix}/ADD-CAMPAIGN`,
    ADD_PROSPECTS_TO_ANOTHER_CAMPAIGN: `${campaignActionPrefix}/ADD_PROSPECTS_TO_ANOTHER_CAMPAIGN`,
    IS_LOADING_DATA: `${campaignActionPrefix}/IS_LOADING_DATA`,
};

export const newCampaignActionTypes = {
    UPDATE_VALUE: `${newCampaignActionPrefix}/UPDATE-VALUE`,
    UPDATE_DAYS: `${newCampaignActionPrefix}/UPDATE-DAYS`,
    UPDATE_TIMES: `${newCampaignActionPrefix}/UPDATE-TIMES`,
    UPDATE_TIME_OFFSET: `${newCampaignActionPrefix}/UPDATE-TIME_OFFSET`,
    UPDATE_CAMPAIGN: `${newCampaignActionPrefix}/UPDATE-CAMPAIGN`,
    UPDATE_CAMPAIGN_NAME: `${newCampaignActionPrefix}/UPDATE-CAMPAIGN-NAME`,
    UPDATE_PERSON_FILTER: `${newCampaignActionPrefix}/UPDATE-TARGET-SEARCH`,
    PREVIOUS_STEP: `${newCampaignActionPrefix}/PREVIOUS-STEP`,
    NEXT_STEP: `${newCampaignActionPrefix}/NEXT-STEP`,
    UPDATE_STEP: `${newCampaignActionPrefix}/UPDATE-STEP`,
    RESET_CAMPAIGN: `${newCampaignActionPrefix}/RESET-CAMPAIGN`,
    SET_INITIAL_NEW_CAMPAIGN: `${newCampaignActionPrefix}/SET_INITIAL_NEW_CAMPAIGN`,
    UPDATE_ALERT_MESSAGE: `${newCampaignActionPrefix}/UPDATE_ALERT_MESSAGE`,
    REDIRECT: `${newCampaignActionPrefix}/REDIRECT`,
    UPDATE_SAVED_TIME: `${newCampaignActionPrefix}/UPDATE_SAVED_TIME`,
    CHANGE_CREATE_CAMPAIGN_TYPE: `${newCampaignActionPrefix}/CHANGE_CREATE_CAMPAIGN_TYPE`,
};

export const userOrganizationActionTypes = {
    CHANGE_BLOCKLIST: `${userOrganizationActionPrefix}/CHANGE_BLOCKLIST`,
    UPDATE_BLACKLIST: `${userOrganizationActionPrefix}/UPDATE-BLACKLIST`,
    UPDATE_USERORGANIZATION: `${userOrganizationActionPrefix}/UPDATE-USERORGANIZATION`,
    CHANGE_STATUS: `${userOrganizationActionPrefix}/CHANGE_STATUS`,
    UPDATE_REWARDS: `${userOrganizationActionPrefix}/UPDATE_REWARDS`,
};

export const creditsActionTypes = {
    UPDATE_CREDITS: `${creditsActionPrefix}/UPDATE_CREDITS`,
};

export const uiActionTypes = {
    REVIEW_PROSPECTS_MODAL: `${uiActionsPrefix}/REVIEW-PROSPECTS-MODAL`,
    REVIEW_PROSPECTS_TOOLTIP: `${uiActionsPrefix}/REVIEW-PROSPECTS-TOOLTIP`,
    HAS_REVIEWIED_PROSPECTS_TOOLTIP: `${uiActionsPrefix}/HAS-REVIEWIED-PROSPECTS-TOOLTIP`,
    SET_NOTIFICATION: `${uiActionsPrefix}/SET-NOTIFICATION`,
    BLOCKLIST_MODAL: `${uiActionsPrefix}/BLOCKLIST_MODAL`,
    MOVING_PROSPECTS: `${uiActionsPrefix}/MOVING_PROSPECTS`,
    PROCESSING_PAYMENT_TYPE: `${uiActionsPrefix}/PROCESSING_PAYMENT_TYPE`,
    PAYMENT_STATUS: `${uiActionsPrefix}/PAYMENT_STATUS`,
};

export const prospectSearchActionTypes = {
    UPDATE_SEARCH: `${prospectSearchActionsPrefix}/UPDATE-SEARCH`,
    RESET_SEARCH: `${prospectSearchActionsPrefix}/RESET-SEARCH`,
    UPDATE_WHOLE: `${prospectSearchActionsPrefix}/UPDATE-WHOLE`,
};

export const outreachActionTypes = {
    UPDATE_VALUE: `${outreachActionsPrefix}/UPDATE-VALUE`,
    UPDATE_TEMPLATE: `${outreachActionsPrefix}/UPDATE-TEMPLATE`,
    UPDATE_DAYS: `${outreachActionsPrefix}/UPDATE-DAYS`,
    UPDATE_TIMES: `${outreachActionsPrefix}/UPDATE-TIMES`,
    UPDATE_TIME_OFFSET: `${outreachActionsPrefix}/UPDATE-TIME_OFFSET`,
    UPDATE_CAMPAIGN: `${outreachActionsPrefix}/UPDATE-CAMPAIGN`,
    UPDATE_CAMPAIGN_NAME: `${outreachActionsPrefix}/UPDATE-CAMPAIGN-NAME`,
    UPDATE_ALERT_MESSAGE: `${outreachActionsPrefix}/UPDATE-ALERT-MESSAGE`,
    UPDATE_ALERT_MODAL: `${outreachActionsPrefix}/UPDATE-ALERT-MODAL`,
    SET_INITIAL_NEW_CAMPAIGN: `${outreachActionsPrefix}/SET_INITIAL_NEW_CAMPAIGN`,
    UPDATE_SKIP_ACTION_SWITCH: `${outreachActionsPrefix}/UPDATE-SKIP-ACTION-SWITCH`,
    UPDATE_SAME_THREAD_SWITCH: `${outreachActionsPrefix}/UPDATE-SAME-THREAD-SWITCH`,
    UPDATE_MESSAGE: `${outreachActionsPrefix}/UPDATE-MESSAGE`,
    UPDATE_SUBJECT: `${outreachActionsPrefix}/UPDATE-SUBJECT`,
    UPDATE_DELAY: `${outreachActionsPrefix}/UPDATE-DELAY`,
    UPDATE_STOP: `${outreachActionsPrefix}/UPDATE-STOP`,
    UPDATE_VALIDATION_MESSAGE: `${outreachActionsPrefix}/UPDATE-VALIDATION-MESSAGE`,
    UPDATE_FLOW_SPLIT: `${outreachActionsPrefix}/UPDATE-FLOW-SPLIT`,
    UPDATE_WHOLE_WORKFLOW: `${outreachActionsPrefix}/UPDATE-WHOLE-WORKFLOW`,
    RESET_OUTREACH: `${outreachActionsPrefix}/RESET-OUTREACH`,
    SET_VALIDATION_ERRORS: `${uiActionsPrefix}/SET_VALIDATION_ERRORS`,
    DELETE_VALIDATION_ERROR: `${uiActionsPrefix}/DELETE_VALIDATION_ERROR`,
    CLEAR_VALIDATION_ERRORS: `${uiActionsPrefix}/CLEAR_VALIDATION_ERRORS`,
    UPDATE_MANUAL_STATE: `${uiActionsPrefix}/UPDATE_MANUAL_STATE`,
    UPDATE_IS_LOADING: `${outreachActionsPrefix}/UPDATE_IS_LOADING`,
};

export const socketClientActionTypes = {
    SEND: `${socketActionPrefix}/SEND`,
    SEND_SUCCESS: `${socketActionPrefix}/SEND_SUCCESS`,
    SEND_FAIL: `${socketActionPrefix}/SEND_FAIL`,
};

export const hubspotActionTypes = {
    UPDATE_SETTINGS: `${hubspotActionPrefix}/UPDATE-SETTINGS`,
    UPDATE_AVAILABLE_FIELDS: `${hubspotActionPrefix}/UPDATE-AVIALABLE-FIELDS`,
    UPDATE_FIELD: `${hubspotActionPrefix}/UPDATE-FIELD`,
    UPDATE_SCOPE: `${hubspotActionPrefix}/UPDATE-SCOPE`,
    ADD_FIELD: `${hubspotActionPrefix}/ADD-FIELD`,
    DELETE_FIELD: `${hubspotActionPrefix}/DELETE-FIELD`,
    UPDATE_VALUE: `${hubspotActionPrefix}/UPDATE-VALUE`,
    UPDATE_SAVE_STATUS: `${hubspotActionPrefix}/UPDATE-SAVE-STATUS`,
};

export const importProspectsActionTypes = {
    UPDATE_PROSPECTS: `${importProspectsActionPrefix}/UPDATE-PROSPECTS`,
    UPDATE_AVAILABLE_FIELDS: `${importProspectsActionPrefix}/UPDATE-AVIALABLE-FIELDS`,
    UPDATE_FIELD: `${importProspectsActionPrefix}/UPDATE-FIELD`,
    ADD_FIELD: `${importProspectsActionPrefix}/ADD-FIELD`,
    DELETE_FIELD: `${importProspectsActionPrefix}/DELETE-FIELD`,
    UPDATE_REVIEW_DETAILS: `${importProspectsActionPrefix}/UPDATE-REVIEW-DETAILS`,
    UPDATE_STEP: `${importProspectsActionPrefix}/UPDATE-STEP`,
    UPDATE_IMPORTED_PROSPECTS_FIELDS: `${importProspectsActionPrefix}/UPDATE-IMPORTED-PROSPECTS-FIELDS`,
    RESET_IMPORT_PROSPECTS: `${importProspectsActionPrefix}/RESET_IMPORT_PROSPECTS`,
    UPDATE_WHOLE_STATE: `${importProspectsActionPrefix}/UPDATE_WHOLE_STATE`,
};

export const webhooksActionTypes = {
    UPDATE_AVAILABLE_WEBHOOKS_EVENTS: `${webhooksActionPrefix}/UPDATE_AVAILABLE_WEBHOOKS_EVENTS`,
    UPDATE_ADDED_WEBHOOKS_EVENTS: `${webhooksActionPrefix}/UPDATE_ADDED_WEBHOOKS_EVENTS`,
    UPDATE_WEBHOOK_DRAWER: `${webhooksActionPrefix}/UPDATE_DRAWER`,
    UPDATE_WEBHOOK_URL: `${webhooksActionPrefix}/UPDATE_WEBHOOK_URL`,
    UPDATE_WEBHOOK_EVENT_NAME: `${webhooksActionPrefix}/UPDATE_WEBHOOK_EVENT_NAME`,
    UPDATE_WEBHOOK_TEST_RESULT: `${webhooksActionPrefix}/UPDATE_WEBHOOK_TEST_RESULT`,
};
