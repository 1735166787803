import { UserRole } from '@zaplify/users/shared';
import { getAuthenticationToken } from '@zaplify/utils';

export const isImpersonatingAUser = async () => {
    const authToken = await getAuthenticationToken();
    // @TODO Remove the dependency on local storage for this. Source of truth is the claims from the auth token
    const isImpersonatingUser = authToken.authUserId !== authToken.rootAuthUserId;
    return isImpersonatingUser;
};

export const isGlobalAdmin = async () => {
    const token = await getAuthenticationToken();
    const isGlobalAdmin = Boolean(token?.claims?.roles?.includes(UserRole.GLOBAL_ADMIN));

    return isGlobalAdmin;
};
