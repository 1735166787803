import Lottie from 'lottie-react';
import sparklesAIAnimation from './sparkles-ai.json';

export const SparklesAI = ({
    height = '16px',
    width = '16px',
    animate = false,
    loop = true,
}: {
    height?: string;
    width?: string;
    animate?: boolean;
    loop?: boolean;
}) => {
    return (
        <Lottie
            animationData={sparklesAIAnimation}
            loop={animate ? loop : false}
            autoplay={animate}
            style={{ height, width }}
        />
    );
};
