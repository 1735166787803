'use client';

import {
    ChevronsUpDown,
    CircleHelp,
    CircleArrowUp,
    Cloud,
    LogOut,
    RefreshCw,
    Settings,
    SparklesIcon,
    FlaskConical,
} from 'lucide-react';

import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from '@shadcn/ui/components/ui/dropdown-menu';
import { SidebarMenu, SidebarMenuButton, SidebarMenuItem, SidebarTrigger } from '@shadcn/ui/components/ui/sidebar';
import { cn } from '@shadcn/ui/lib/utils';
import { useAuth } from '../../providers/authentication-provider';
import { MeAvatar } from '../me-avatar';
import { NavLink, useNavigate } from 'react-router-dom';
import { paths } from '../../../routes/paths';
import { PlanCode } from '@zaplify/subscriptions';
import { useSidebars } from '@shadcn/ui/hooks/use-sidebars';
import { Progress } from '@shadcn/ui/components/ui/progress';
import { useIsMobile } from '@shadcn/ui/hooks/use-mobile';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@shadcn/ui/components/ui/tooltip';
import { useSdk } from '../../sdk';
import { useSuspenseQuery as useSuspenseQueryTan } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import { useSuspenseQuery } from '@apollo/client';
import { GET_MESSAGES_SENT_FROM_APP } from '@zaplify/graphql';
import dayjs from 'dayjs';
import { useFlags } from 'launchdarkly-react-client-sdk';

export function NavTop() {
    const {
        sidebarState: { main: isMain },
    } = useSidebars();
    const isMobile = useIsMobile();
    const {
        authState: {
            user,
            tokenResult: {
                claims: { plan },
            },
        },
        logout,
    } = useAuth();
    const navigate = useNavigate();
    const {
        linkedinProfiles: { getLinkedinConnections },
        user: { updateBetaFeatures: updateBetaFeaturesMutation },
    } = useSdk();

    const { data: connections, isLoading: isLoadingConnections } = useSuspenseQueryTan(getLinkedinConnections());
    const { data: messagesSentFromApp } = useSuspenseQuery(GET_MESSAGES_SENT_FROM_APP);
    const { mutate: updateBetaFeatures } = useMutation(updateBetaFeaturesMutation());
    const { useActionFeed, showEnableBetaFeaturesToggle } = useFlags();

    if (!user) return null;

    const totalConnections = connections?.length || 0;
    const totalMessages = messagesSentFromApp?.Messages_aggregate.aggregate.count || 0;

    // Get connections job progress (we don't know what number of connections they have, so we cap it to 800 or 3 days)
    const isNewUser = dayjs(user?.createdAt).isAfter(dayjs().subtract(1, 'week'));
    const progressConnections = isNewUser ? Math.min(totalConnections / 800, 1) : 1;

    const progressMessages = Math.min(totalMessages / 20, 1);

    const progressValue = Math.min((progressConnections + progressMessages) / 2, 1);

    return (
        <SidebarMenu className={cn(`flex ${isMain ? 'flex-row' : 'flex-col'} items-center justify-between mt-1`)}>
            <SidebarMenuItem>
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <SidebarMenuButton
                            size="lg"
                            className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground ml-0"
                        >
                            <MeAvatar />
                            <div className="grid flex-1 text-left text-sm leading-tight">
                                <span className="truncate ">{`${user.firstName}`}</span>
                            </div>
                            <ChevronsUpDown className="ml-auto size-4" />
                        </SidebarMenuButton>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent
                        className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
                        side={isMobile ? 'top' : 'right'}
                        align="end"
                        sideOffset={4}
                    >
                        <DropdownMenuLabel className="p-0 font-normal">
                            <div className="flex items-center gap-2 px-1 py-1.5 text-left text-sm">
                                <MeAvatar />
                                <div className="grid flex-1 text-left text-sm leading-tight">
                                    <span className="truncate font-semibold">{`${user.firstName} ${user.lastName}`}</span>
                                    <span className="truncate text-xs">{user.email}</span>
                                </div>
                            </div>
                            <TooltipProvider>
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <div className="p-2 space-y-2">
                                            <div className="flex items-center gap-2">
                                                <SparklesIcon className="h-4 w-4 text-background-brand-primary" />
                                                <span className="text-sm cursor-default">
                                                    {progressValue < 1 ? 'Agent Training' : 'Agent Fully Trained'}
                                                </span>
                                            </div>
                                            <Progress
                                                value={progressValue * 100}
                                                className="h-2 [&>div]:bg-brand-gradient-2"
                                            />
                                        </div>
                                    </TooltipTrigger>
                                    <TooltipContent side="right">
                                        <p className="max-w-[300px]">
                                            Your agent will improve over time as you send more messages and engage with
                                            more contacts.
                                        </p>
                                    </TooltipContent>
                                </Tooltip>
                            </TooltipProvider>
                        </DropdownMenuLabel>
                        <DropdownMenuSeparator />
                        <DropdownMenuGroup>
                            {(plan == PlanCode.FREE || !plan) && (
                                <DropdownMenuItem asChild>
                                    <NavLink
                                        to={
                                            paths.NEW.ACCOUNT_MANAGEMENT_PATHS.ROOT +
                                            '/' +
                                            paths.NEW.ACCOUNT_MANAGEMENT_PATHS.SUBSCRIPTION +
                                            '/upgrade-plan'
                                        }
                                    >
                                        <CircleArrowUp />
                                        Upgrade to Pro
                                    </NavLink>
                                </DropdownMenuItem>
                            )}
                            {plan == PlanCode.BASIC && (
                                <DropdownMenuItem asChild>
                                    <NavLink
                                        to={
                                            paths.NEW.ACCOUNT_MANAGEMENT_PATHS.ROOT +
                                            '/' +
                                            paths.NEW.ACCOUNT_MANAGEMENT_PATHS.SUBSCRIPTION +
                                            '/upgrade-plan'
                                        }
                                    >
                                        <CircleArrowUp />
                                        Upgrade to Team
                                    </NavLink>
                                </DropdownMenuItem>
                            )}
                        </DropdownMenuGroup>
                        <DropdownMenuSeparator />
                        <DropdownMenuGroup>
                            <DropdownMenuItem asChild>
                                <NavLink
                                    to={
                                        paths.NEW.ACCOUNT_MANAGEMENT_PATHS.ROOT +
                                        '/' +
                                        paths.NEW.ACCOUNT_MANAGEMENT_PATHS.PROFILE
                                    }
                                >
                                    <Settings />
                                    Settings
                                </NavLink>
                            </DropdownMenuItem>
                            <DropdownMenuItem asChild>
                                <NavLink to={paths.INTEGRATIONS}>
                                    <Cloud />
                                    Integrations
                                </NavLink>
                            </DropdownMenuItem>
                            <DropdownMenuItem asChild>
                                {/* <NavLink to={'/accounts'}>
                                    <RefreshCw />
                                    Switch workspace
                                </NavLink> */}
                                <NavLink to={paths.WORKSPACES.ROOT}>
                                    <RefreshCw />
                                    Switch workspace
                                </NavLink>
                            </DropdownMenuItem>
                            <DropdownMenuItem onClick={() => window.open('https://help.andsend.com/en/')}>
                                <CircleHelp />
                                Help and resources
                            </DropdownMenuItem>
                            {showEnableBetaFeaturesToggle && (
                                <DropdownMenuItem
                                    onClick={() => {
                                        updateBetaFeatures();
                                    }}
                                >
                                    <FlaskConical />
                                    {useActionFeed ? 'Disable' : 'Enable'} beta features
                                </DropdownMenuItem>
                            )}
                        </DropdownMenuGroup>
                        <DropdownMenuSeparator />
                        <DropdownMenuItem onClick={logout}>
                            <LogOut />
                            Log out
                        </DropdownMenuItem>
                        <a
                            href={paths.LEGAL.PRIVACY_POLICY}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-xs pl-2 underline cursor-pointer"
                        >
                            Privacy policy
                        </a>
                    </DropdownMenuContent>
                </DropdownMenu>
            </SidebarMenuItem>
            <SidebarMenuItem>
                <SidebarTrigger className="hover:animate-wiggle" />
            </SidebarMenuItem>
        </SidebarMenu>
    );
}
