import { LocalStorageKeys } from '../../config';

export const cacheSignupParams = ({ referralLinkId }: { referralLinkId?: string }) => {
    if (referralLinkId?.length > 0) {
        localStorage.setItem(LocalStorageKeys.REFERRAL_LINK, referralLinkId);
    }
};

export const unsetReferral = () => {
    localStorage.removeItem(LocalStorageKeys.REFERRAL_LINK);
};
