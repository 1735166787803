export enum ProspectStatus {
    ACTIVE = 'ACTIVE',
    /** @deprecated */
    IN_REVIEW = 'IN_REVIEW',
    QUEUED = 'QUEUED',
    INACTIVE = 'INACTIVE',
    COMPLETED = 'COMPLETED',
    ON_HOLD = 'ON_HOLD',
}

/**
 * Prospect subStatuses related to exectutions
 */
export enum ProspectSubStatus {
    REPLIED = 'REPLIED',
    COMPLETED_WORKFLOW = 'COMPLETED_WORKFLOW',
    ACCEPTED_CONNECTION_REQUEST = 'ACCEPTED_CONNECTION_REQUEST',

    // Errors
    MISSING_LINKEDIN_URL = 'MISSING_LINKEDIN_URL',
    LI_PROFILE_UNAVAILABLE = 'LI_PROFILE_UNAVAILABLE',
    MISSING_EMAIL_ADDRESS = 'MISSING_EMAIL_ADDRESS',
    AWAITING_ENRICHMENT = 'AWAITING_ENRICHMENT',
    EMAIL_BOUNCED = 'EMAIL_BOUNCED',
    EMAIL_AUTO_REPLY = 'EMAIL_AUTO_REPLY',
    EMAIL_UNSUBSCRIBED = 'EMAIL_UNSUBSCRIBED',
    UNKNOWN_ERROR = 'UNKNOWN_ERROR',

    // Execution
    ALREADY_CONNECTED = 'ALREADY_CONNECTED',
    CONNECTION_REQUEST_REQUIRES_EMAIL = 'CONNECTION_REQUEST_REQUIRES_EMAIL',
    NOT_CONNECTED = 'NOT_CONNECTED',

    // Prospect subStatuses related to reviews
    VERIFYING = 'VERIFYING',
    JOB_POSITION_OUTDATED = 'JOB_POSITION_OUTDATED',
    REVIEW_FINISHED = 'REVIEW_FINISHED',
    DATA_PROCESSING_FINISHED = 'DATA_PROCESSING_FINISHED',
}

export enum ProspectStatusNumerical {
    active = 1,
    queued = 5,
    inactive = 2,
    completed = 3,
    failed = 4,
    campaignPaused = 6,
}

export enum ProspectStatusText {
    active = 'Active',
    queued = 'Queued',
    finished = 'Finished',
    replied = 'Replied',
    failed = 'Failed',
    paused = 'Paused',
}

export type ProspectStatusInfo = {
    text: string;
    status: ProspectStatusNumerical | null;
    subtitle: string | null;
    additionalInformation?: string | undefined;
    touchpointType?: boolean | TouchpointType;
    taskState?: WhereInTime;
    touchpointSchedule?: string | undefined;
};

export enum TouchpointType {
    manual = 1,
    auto = 2,
}

export enum WhereInTime {
    BEFORE = 'before',
    AFTER = 'after',
    BETWEEN = 'between',
}
