import { BaseSdk } from '../../base.sdk';
import { NoteDto } from '@zaplify/services/notes/shared';

interface CreateNoteBody {
    userId: string;
    prospectId: string;
    content: string;
    title: string;
}

export class NotesSdk extends BaseSdk {
    async createNote(body: CreateNoteBody) {
        return await this.post<NoteDto>({
            path: `${this.notesPath}`,
            payload: {
                creator: 'user',
                ...body,
            },
        });
    }

    async deleteNote(noteId: string) {
        return await this.delete<void>(`${this.notesPath}/${noteId}`);
    }
}
