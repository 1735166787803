import { IEvent, Topics } from '@zaplify/common';

export class ProxyFailedEvent extends IEvent {
    public static override readonly TOPIC = Topics.CHANNEL_ACCOUNTS;

    constructor(readonly channelAccountId: string) {
        super();
    }

    get partitionKey(): string {
        return this.channelAccountId;
    }
}
