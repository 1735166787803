/**
 * Converts plain text URLs in the input string to HTML anchor tags.
 *
 * This function processes the input text to identify URLs and converts them into clickable anchor tags with
 * specific styles. If the input text contains HTML content, it modifies existing anchor and span tags
 * to ensure consistent styling. If the input text contains meta or head tags, it skips processing.
 *
 * @param {string} inputText - The input string potentially containing URLs or HTML content.
 * @returns {string} - The processed string with URLs converted to anchor tags or modified HTML content.
 */
export function convertLinksToAnchors(inputText: string): string {
    const linkRegex = /<a\s+(?!(?:[^>]*?\s+)?href=(["']?)mailto:)/gi;

    // This modifies existing anchor and span tags to ensure consistent styling
    const modifiedText = inputText
        .replace(
            linkRegex,
            '<a target="_blank" style="color: #6583ef; text-decoration: none" onmouseover="this.style.textDecoration=\'underline\'" onmouseout="this.style.textDecoration=\'none\'" '
        )
        .replace(/<span[^>]*style="[^"]*color:[^"]*"[^>]*>/gi, '<span style="color: inherit">');

    const appAwareLink = (url) =>
        `<a target="_blank" href="${url}" style="color: #6583ef; text-decoration: none" 
                onmouseover="this.style.textDecoration='underline'" 
                onmouseout="this.style.textDecoration='none'">${url}</a>`;

    const tokens = modifiedText.split(/(<[^>]+>)/g);

    for (let i = 0; i < tokens.length; i++) {
        if (!tokens[i].startsWith('<')) {
            tokens[i] = tokens[i].replace(/(?<!["'=\w@])(https?:\/\/[^\s<>"']+)/g, (match) => {
                return appAwareLink(match);
            });
        }
    }

    return tokens.join('');
}
