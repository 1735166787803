import { FC, useMemo, useState } from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@shadcn/ui/components/ui/card';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@shadcn/ui/components/ui/select';
import { BaseChart } from './charts/base-chart';
import { Separator } from '@shadcn/ui/components/ui/separator';
import { useTeamMetrics } from '../hooks/use-team-metrics';
import { Avatar, AvatarFallback } from '@shadcn/ui/components/ui/avatar';
import { StatsCard } from './stats-card';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@shadcn/ui/components/ui/table';
import { ArrowDownIcon, ArrowUpIcon, Clock, MessageCircle, CornerUpRight, CornerUpLeft, UserPlus } from 'lucide-react';
import {
    differenceInDays,
    format,
    sub,
    isFirstDayOfMonth,
    isLastDayOfMonth,
    startOfYear,
    endOfYear,
    startOfWeek,
    endOfWeek,
    isSameYear,
    isSameMonth,
} from 'date-fns';
interface TeamMetricsProps {
    startDate: Date;
    endDate: Date;
    distinctProspects: boolean;
}

const chartConfig = {
    messagesSent: {
        label: 'Messages Sent',
        theme: {
            light: 'hsl(221.2 83.2% 53.3%)',
            dark: 'hsl(217.2 91.2% 59.8%)',
        },
    },
    messagesReceived: {
        label: 'Messages Received',
        theme: {
            light: 'hsl(262.1 83.3% 57.8%)',
            dark: 'hsl(263.4 70% 50.4%)',
        },
    },
    connectionsAccepted: {
        label: 'New Connections',
        theme: {
            light: 'hsl(291.5 95.5% 58.8%)',
            dark: 'hsl(291.5 95.5% 58.8%)',
        },
    },
};

export const TeamMetrics: FC<TeamMetricsProps> = ({ startDate, endDate, distinctProspects }) => {
    const [frequency, setFrequency] = useState<'daily' | 'weekly'>('daily');

    const {
        aggregatedMetrics,
        topPerformers,
        loading,
        error,
        latestMetrics,
        previousMetrics,
        timeSaved,
        previousTimeSaved,
    } = useTeamMetrics({
        startDate,
        endDate,
        frequency,
        distinctProspects,
    });

    const periodName = useMemo(() => {
        const days = differenceInDays(endDate, startDate);
        const currentPeriod = `${format(startDate, 'MMM d')} - ${format(endDate, 'MMM d')}`;

        // Check for single day
        if (days === 0) {
            return `${format(startDate, 'MMM d')} vs the previous day`;
        }

        // Check for full week (Monday to Sunday)
        if (
            days === 6 &&
            startDate.getTime() === startOfWeek(startDate, { weekStartsOn: 1 }).getTime() &&
            endDate.getTime() === endOfWeek(startDate, { weekStartsOn: 1 }).getTime()
        ) {
            return `${currentPeriod} vs previous week`;
        }

        // Check for full month
        if (isFirstDayOfMonth(startDate) && isLastDayOfMonth(endDate) && isSameMonth(startDate, endDate)) {
            return `${format(startDate, 'MMMM yyyy')} vs the previous month`;
        }

        // Check for full year
        if (
            startDate.getTime() === startOfYear(startDate).getTime() &&
            endDate.getTime() === endOfYear(startDate).getTime()
        ) {
            return `${format(startDate, 'yyyy')} vs the previous year`;
        }

        // Default to showing exact date ranges
        const previousPeriod = `${format(sub(startDate, { days: days + 1 }), 'MMM d')} - ${format(
            sub(startDate, { days: 1 }),
            'MMM d'
        )}`;
        return `${currentPeriod} vs ${previousPeriod}`;
    }, [startDate, endDate]);

    if (error) {
        console.error(error);
        return null;
    }

    if (loading || !aggregatedMetrics.length) {
        return null;
    }

    return (
        <div className="flex flex-col gap-8">
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                <StatsCard
                    title="Team Time Saved"
                    icon={<Clock className="w-4 h-4 text-text-brand-secondary" />}
                    description="Total estimated time saved by the team"
                    tooltipDescription="Total estimated time saved by the team. (5 minutes per message sent using Andsend)"
                    currentValue={timeSaved}
                    previousValue={previousTimeSaved}
                    format="hours"
                    periodName={periodName}
                />
                <StatsCard
                    title={distinctProspects ? 'Team Contacts Messaged' : 'Team Messages Sent'}
                    description="Total across all team members"
                    icon={
                        <div className="flex items-center gap-2">
                            <MessageCircle className="w-4 h-4 text-text-brand-secondary" />
                            <div className="-ml-3 mt-2 z-10 rounded-full bg-background p-[1px]">
                                <CornerUpRight className="w-2 h-2 text-text-brand-secondary stroke-[3]" />
                            </div>
                        </div>
                    }
                    tooltipDescription={
                        distinctProspects
                            ? 'Total across all team members'
                            : `Total across all team members of which ${latestMetrics.messagesSentFromApp} were sent using Andsend`
                    }
                    currentValue={latestMetrics.messagesSent}
                    previousValue={previousMetrics.messagesSent}
                    periodName={periodName}
                />
                <StatsCard
                    title={distinctProspects ? 'Team Contacts Responded' : 'Team Messages Received'}
                    description="Total across all team members"
                    icon={
                        <div className="flex items-center gap-2">
                            <MessageCircle className="w-4 h-4 text-text-brand-secondary" />
                            <div className="-ml-3 mt-2 z-10 rounded-full bg-background p-[1px]">
                                <CornerUpLeft className="w-2 h-2 text-text-brand-secondary stroke-[3]" />
                            </div>
                        </div>
                    }
                    currentValue={latestMetrics.messagesReceived}
                    previousValue={previousMetrics.messagesReceived}
                    periodName={periodName}
                />
                <StatsCard
                    title="Team New Connections"
                    icon={<UserPlus className="w-4 h-4 text-text-brand-secondary" />}
                    description="Total new connections made"
                    currentValue={latestMetrics.connectionsAccepted}
                    previousValue={previousMetrics.connectionsAccepted}
                    periodName={periodName}
                />
            </div>

            <Separator />

            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <Card className="w-full md:col-span-2">
                    <CardHeader>
                        <div className="flex justify-between items-center">
                            <CardTitle>Team Activity Overview</CardTitle>
                            <div className="flex gap-4 items-center">
                                <Select
                                    value={frequency}
                                    onValueChange={(value: 'daily' | 'weekly') => setFrequency(value)}
                                >
                                    <SelectTrigger className="w-[120px]">
                                        <SelectValue placeholder="Select frequency" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectItem value="daily">Daily</SelectItem>
                                        <SelectItem value="weekly">Weekly</SelectItem>
                                    </SelectContent>
                                </Select>
                            </div>
                        </div>
                        <CardDescription>
                            {distinctProspects
                                ? 'Overview of unique prospects contacted by the team over time'
                                : 'Overview of team messaging and connection activity over time'}
                        </CardDescription>
                    </CardHeader>
                    <CardContent>
                        <BaseChart
                            data={aggregatedMetrics}
                            visibleLines={{
                                messagesSent: true,
                                messagesReceived: true,
                                connectionsAccepted: true,
                            }}
                            chartConfig={chartConfig}
                        />
                    </CardContent>
                </Card>

                <Card className="w-full">
                    <CardHeader>
                        <CardTitle>Top Performers</CardTitle>
                        <CardDescription>Team performance breakdown</CardDescription>
                    </CardHeader>
                    <CardContent>
                        <Table>
                            <TableHeader>
                                <TableRow>
                                    <TableHead>Member</TableHead>
                                    <TableHead className="text-right">Messages</TableHead>
                                    <TableHead className="text-right">Responses</TableHead>
                                    <TableHead className="text-right">Connections</TableHead>
                                    <TableHead className="text-right">Score</TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {topPerformers
                                    .sort((a, b) => {
                                        if (distinctProspects) {
                                            const aResponseRate =
                                                a.metrics.uniqueProspectsReceived / a.metrics.uniqueProspectsSent || 0;
                                            const bResponseRate =
                                                b.metrics.uniqueProspectsReceived / b.metrics.uniqueProspectsSent || 0;
                                            const aScore =
                                                (aResponseRate *
                                                    a.metrics.uniqueProspectsSent *
                                                    (a.metrics.connectionsAccepted + 1)) /
                                                100;
                                            const bScore =
                                                (bResponseRate *
                                                    b.metrics.uniqueProspectsSent *
                                                    (b.metrics.connectionsAccepted + 1)) /
                                                100;
                                            return bScore - aScore;
                                        }
                                        return b.score - a.score;
                                    })
                                    .map((performer) => (
                                        <TableRow key={performer.id}>
                                            <TableCell>
                                                <div className="flex items-center gap-2">
                                                    <Avatar>
                                                        <AvatarFallback>{performer.initials}</AvatarFallback>
                                                    </Avatar>
                                                    <div className="flex flex-col">
                                                        <span className="font-medium">{performer.name}</span>
                                                    </div>
                                                </div>
                                            </TableCell>
                                            <TableCell className="text-right">
                                                <div className="flex items-center justify-end gap-2">
                                                    {distinctProspects
                                                        ? performer.metrics.uniqueProspectsSent
                                                        : performer.metrics.messagesSent}
                                                    {distinctProspects ? (
                                                        performer.metrics.uniqueProspectsSent >
                                                        performer.metrics.uniqueProspectsSentPrev ? (
                                                            <ArrowUpIcon className="h-4 w-4 text-text-success-primary" />
                                                        ) : (
                                                            <ArrowDownIcon className="h-4 w-4 text-text-error-primary" />
                                                        )
                                                    ) : performer.metrics.messagesSent >
                                                      performer.metrics.previousMessagesSent ? (
                                                        <ArrowUpIcon className="h-4 w-4 text-text-success-primary" />
                                                    ) : (
                                                        <ArrowDownIcon className="h-4 w-4 text-text-error-primary" />
                                                    )}
                                                </div>
                                            </TableCell>
                                            <TableCell className="text-right">
                                                <div className="flex items-center justify-end gap-2">
                                                    {distinctProspects
                                                        ? performer.metrics.uniqueProspectsReceived
                                                        : performer.metrics.messagesReceived}
                                                    {distinctProspects ? (
                                                        performer.metrics.uniqueProspectsReceived >
                                                        performer.metrics.uniqueProspectsReceivedPrev ? (
                                                            <ArrowUpIcon className="h-4 w-4 text-text-success-primary" />
                                                        ) : (
                                                            <ArrowDownIcon className="h-4 w-4 text-text-error-primary" />
                                                        )
                                                    ) : performer.metrics.messagesReceived >
                                                      performer.metrics.previousMessagesReceived ? (
                                                        <ArrowUpIcon className="h-4 w-4 text-text-success-primary" />
                                                    ) : (
                                                        <ArrowDownIcon className="h-4 w-4 text-text-error-primary" />
                                                    )}
                                                </div>
                                            </TableCell>
                                            <TableCell className="text-right">
                                                <div className="flex items-center justify-end gap-2">
                                                    {distinctProspects
                                                        ? performer.metrics.connectionsAccepted
                                                        : performer.metrics.connectionsAccepted}
                                                    {performer.metrics.connectionsAccepted >
                                                    performer.metrics.previousConnectionsAccepted ? (
                                                        <ArrowUpIcon className="h-4 w-4 text-text-success-primary" />
                                                    ) : (
                                                        <ArrowDownIcon className="h-4 w-4 text-text-error-primary" />
                                                    )}
                                                </div>
                                            </TableCell>
                                            <TableCell className="text-right font-medium">
                                                {!distinctProspects
                                                    ? performer.score.toFixed(2)
                                                    : performer.metrics.connectionsAccepted}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </CardContent>
                </Card>
            </div>
        </div>
    );
};
