import { initializeApp } from 'firebase/app';
import { EmailAuthProvider, getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

const projectId = import.meta.env.VITE_FIREBASE_PROJECT_ID;

const config = {
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    authDomain: `${projectId}.firebaseapp.com`,
    databaseURL: `https://${projectId}.firebaseio.com`,
    projectId: `${projectId}`,
    storageBucket: `${projectId}.appspot.com`,
    messagingSenderId: import.meta.env.VITE_FIREBASE_SENDER_ID,
    appId: import.meta.env.VITE_FIREBASE_APP_ID,
};
initializeApp(config);
export const authProvider = new EmailAuthProvider();
export const auth = getAuth();

export const reduxFirebaseConfig = {
    useFirestoreForProfile: false,
    enableLogging: true,
};

const provider = new GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'select_account' });

export const signInWithGoogle = () => signInWithPopup(auth, provider);
