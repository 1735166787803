import { FormControl, Select } from '@mui/material';
import { IPlanPackage } from '@zaplify/subscriptions';
import { useState } from 'react';

export const SelectPackageDropdown = ({
    selectedPackage,
    packages,
    onPackageChange,
    displaySeats = true,
    dataCy = '',
}: {
    selectedPackage: IPlanPackage;
    packages: IPlanPackage[];
    onPackageChange: (plan: IPlanPackage) => void;
    displaySeats?: boolean;
    dataCy?: string;
}) => {
    const [numberOfSeatsSelected, setNumberOfSeatsSelected] = useState(selectedPackage?.seats || packages[0].seats);
    const onSelectChange = (numberOfSeats: number) => {
        onPackageChange({ ...selectedPackage, seats: numberOfSeats });
    };

    return (
        <FormControl variant="outlined" style={{ display: 'flex' }}>
            <input
                type="number"
                value={numberOfSeatsSelected}
                onChange={(e) => {
                    const seats = parseInt(e.target.value);
                    if (seats < 1) return;
                    setNumberOfSeatsSelected(seats);

                    if (!seats) {
                        return;
                    }

                    onSelectChange(seats);
                }}
                style={{
                    padding: '14px',
                    fontSize: '16px',
                    color: '#6B7985',
                    maxWidth: '200px',
                    borderRadius: '12px',
                    border: '1px solid #D9E0E7',
                }}
                data-cy={dataCy}
            />
        </FormControl>
    );
};
