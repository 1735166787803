import { UseMutationOptions, useQueryClient, InvalidateQueryFilters } from '@tanstack/react-query';

export const createMutationOptions = <TData, TError, TVariables, TCurrentData = TData>(
    options: Omit<UseMutationOptions<TData, TError, TVariables>, 'mutationFn'> & {
        mutationFn: (variables: TVariables) => Promise<TData>;
        invalidateQueries?: (data: TData, variables: TVariables) => (string[] | InvalidateQueryFilters)[];
        getCurrentData?: () => Promise<TCurrentData | undefined>;
        isEqual?: (current: TCurrentData | undefined, variables: TVariables) => boolean;
    }
): UseMutationOptions<TData, TError, TVariables> => {
    const queryClient = useQueryClient();
    const { getCurrentData, isEqual, invalidateQueries, mutationFn, ...rest } = options;
    return {
        mutationFn: async (variables: TVariables) => {
            // If idempotency check is configured
            if (getCurrentData && isEqual) {
                const currentData = await getCurrentData();
                if (isEqual(currentData, variables)) {
                    return undefined as unknown as TData; // Just skip the call and return undefined cast to TData
                }
            }
            return mutationFn(variables);
        },
        onSuccess: (data: TData, variables: TVariables, context: unknown) => {
            if (invalidateQueries) {
                const queriesToInvalidate = invalidateQueries(data, variables);
                queriesToInvalidate.forEach((queryKey) => {
                    if (Array.isArray(queryKey)) {
                        queryClient.invalidateQueries({ queryKey });
                    } else if (typeof queryKey === 'object' && 'predicate' in queryKey) {
                        queryClient.invalidateQueries(queryKey);
                    } else {
                        queryClient.invalidateQueries({ queryKey: [queryKey] });
                    }
                });
            }

            if (options.onSuccess) {
                options.onSuccess(data, variables, context);
            }
        },
        ...rest,
    };
};
