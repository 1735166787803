import { useState, useEffect, createContext, useContext, ReactNode } from 'react';
import { LocalStorageKeys, SocketEvents } from '../../config';
import { useWebSocket } from './use-websocket';
import { useAuth } from '../providers/authentication-provider';
import { Dialog } from '@shadcn/ui/components/ui/dialog';
import { useToast } from '@shadcn/ui/hooks/use-toast';

export enum PaymentStatus {
    NONE = 'none',
    PROCESSING = 'processing',
    SUCCESS = 'success',
    FAILED = 'failed',
    PROCESSING_FREE = 'processing_free',
}

interface BillingContextType {
    loading: boolean;
    BillingDialog: JSX.Element | null;
    paymentStatus: PaymentStatus;
    setPaymentStatus: (status: PaymentStatus) => void;
}

const BillingContext = createContext<BillingContextType | undefined>(undefined);

export function BillingProvider({ children }: { children: ReactNode }) {
    const { subscribe, unsubscribe, isConnected } = useWebSocket();
    const { toast } = useToast();
    const [loading, setLoading] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>(PaymentStatus.NONE);
    const {
        reloadClaims,
        authState: { userOrganizationId },
    } = useAuth();

    const handleSeatUnassigned = async () => {
        console.log('UseBillingListener: Seat unassigned');
        await reloadClaims(userOrganizationId);
    };

    const handlePaymentSucceeded = async () => {
        console.log('UseBillingListener: Payment succeeded');
        setPaymentStatus(PaymentStatus.SUCCESS);
        toast({
            title: 'Payment successful',
            description: 'Your payment has been successfully processed.',
        });
    };

    const handleSeatAssigned = async () => {
        console.log('UseBillingListener: Seat assigned');
        await reloadClaims(userOrganizationId);
        localStorage.removeItem(LocalStorageKeys.PLAN);
        setLoading(false);
    };

    const handleSubscriptionChanged = async () => {
        console.log('UseBillingListener: Subscription changed');
        console.log('UseBillingListener: Payment status:', paymentStatus);

        setPaymentStatus(PaymentStatus.SUCCESS);
    };

    useEffect(() => {
        if (!isConnected) {
            console.log('UseBillingListener: Not subscribing to events, isConnected:', isConnected);
            return;
        }

        subscribe(SocketEvents.billing.SEAT_UNASSIGNED, handleSeatUnassigned);
        subscribe(SocketEvents.billing.SEAT_ASSIGNED, handleSeatAssigned);
        subscribe(SocketEvents.billing.PAYMENT_SUCCEEDED, handlePaymentSucceeded);
        subscribe(SocketEvents.billing.SUBSCRIPTION_CHANGED, handleSubscriptionChanged);

        console.log('UseBillingListener: Subscribed to billing events');

        return () => {
            unsubscribe(SocketEvents.billing.SEAT_UNASSIGNED, () => {});
            unsubscribe(SocketEvents.billing.SEAT_ASSIGNED, () => {});
            unsubscribe(SocketEvents.billing.PAYMENT_SUCCEEDED, () => {});
            unsubscribe(SocketEvents.billing.SUBSCRIPTION_CHANGED, () => {});
        };
    }, [isConnected]);

    const BillingDialog = loading ? (
        <Dialog open={loading} modal>
            <div className="flex flex-col items-center justify-center p-6">
                <h2 className="text-xl font-semibold mb-4">Processing Payment</h2>
                <p className="text-muted-foreground">Please wait while we process your payment...</p>
            </div>
        </Dialog>
    ) : null;

    const value = {
        loading,
        BillingDialog,
        paymentStatus,
        setPaymentStatus,
    };

    return <BillingContext.Provider value={value}>{children}</BillingContext.Provider>;
}

export function useBillingListener() {
    const context = useContext(BillingContext);
    if (context === undefined) {
        throw new Error('useBillingListener must be used within a BillingProvider');
    }
    return context;
}
