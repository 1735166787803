import PaymentIcon from '@mui/icons-material/Payment';
import { Box, Skeleton, Typography } from '@mui/material';
import { getZaplifySdk } from '@zaplify/sdk';
import { SubscriptionDetailsDto } from '@zaplify/subscriptions';
import { useAtom } from 'jotai';
import { useRef } from 'react';
import { useDispatch } from 'react-redux-latest';
import { upgradePlanErrorTextAtom } from '../../atoms/selfService';
import { updateSubscription } from '../../redux/actions';
import { AppDispatch } from '../../redux/store/configureStore';
import { palette } from '../../theme/palette';
import PaymentDetailsStyled from './PaymentDetails.styled';
import { ChargebeePortalSection } from './enums/billing';

const PaymentDetails = ({
    subscriptionDetails,
    loadingState,
}: {
    subscriptionDetails: SubscriptionDetailsDto;
    loadingState: 'idle' | 'loading';
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const [upgradePlanErrorText, setUpgradePlanErrorText] = useAtom(upgradePlanErrorTextAtom);
    const cbInstance = useRef(window.Chargebee.init({ site: import.meta.env.VITE_CHARGEBEE_SITE }));

    const loadSelfServicePortal = async (section?: ChargebeePortalSection) => {
        const profilesSdk = getZaplifySdk().profiles;
        setUpgradePlanErrorText(null);
        await cbInstance.current.setPortalSession(function () {
            return profilesSdk.subscription.createPortalSession();
        });

        const cbPortal = cbInstance.current.createChargebeePortal();

        if (section) {
            cbPortal.openSection(
                {
                    sectionType: window.Chargebee.getPortalSections()[section],
                },
                {
                    close: () => {
                        dispatch(updateSubscription());
                    },
                }
            );
        } else {
            cbPortal.open({});
        }
    };

    return (
        <PaymentDetailsStyled>
            {loadingState === 'loading' && <PaymentDetailsLoader />}
            {loadingState === 'idle' && (
                <>
                    <Box marginBottom={'31px'}>
                        <Typography variant="h4">Payment details</Typography>
                    </Box>
                    <Box marginBottom={'24px'}>
                        <Typography className="label">Invoice sent to </Typography>
                        <Box display="flex">
                            <div className="email">{subscriptionDetails.customer.address?.email}</div>
                            <div
                                className="change-button"
                                onClick={() => loadSelfServicePortal(ChargebeePortalSection.ADDRESS)}
                            >
                                Change
                            </div>
                        </Box>
                    </Box>
                    <Box className="billing-address">
                        <Typography className="label">Billing address</Typography>
                        <Box display="flex" flexDirection="row">
                            <Box marginRight={'15px'}>
                                <Typography>
                                    {subscriptionDetails.customer.address?.firstName}{' '}
                                    {subscriptionDetails.customer.address?.lastName}
                                </Typography>
                                {subscriptionDetails.customer.address?.line1 && (
                                    <Typography>{subscriptionDetails.customer.address?.line1}</Typography>
                                )}
                                {subscriptionDetails.customer.address?.line2 && (
                                    <Typography>{subscriptionDetails.customer.address?.line2}</Typography>
                                )}
                                {subscriptionDetails.customer.address?.line3 && (
                                    <Typography>{subscriptionDetails.customer.address?.line3}</Typography>
                                )}
                            </Box>
                            <Box display="flex" alignItems="flex-end">
                                <div
                                    className="change-button"
                                    onClick={() => loadSelfServicePortal(ChargebeePortalSection.ADDRESS)}
                                >
                                    Change
                                </div>
                            </Box>
                        </Box>
                    </Box>
                    <Box className="payment-method">
                        <Typography className="title">Payment method</Typography>
                        <Box display="flex">
                            <Box display="flex" alignItems={'center'} marginRight={'15px'}>
                                <PaymentIcon className="payment-icon" />
                                <span className="card-number">
                                    {subscriptionDetails?.customer?.card?.last4
                                        ? `•••• •••• •••• ${subscriptionDetails?.customer?.card?.last4} `
                                        : 'No card'}
                                </span>
                            </Box>
                            <Box
                                display="flex"
                                alignItems="center"
                                className="change-button"
                                onClick={() => loadSelfServicePortal(ChargebeePortalSection.PAYMENT_SOURCES)}
                            >
                                {subscriptionDetails?.customer?.card?.last4 ? 'Change?' : 'Add card'}
                            </Box>
                        </Box>
                    </Box>
                </>
            )}
        </PaymentDetailsStyled>
    );
};

export default PaymentDetails;

const PaymentDetailsLoader = () => {
    return (
        <div
            style={{
                marginTop: 6,
                borderRadius: '0 0 8px 8px',
                background: palette.colors.white,
            }}
        >
            <Box>
                <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width={100}
                    height={18}
                    style={{ marginBottom: 30, borderRadius: 4 }}
                />
                <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width={100}
                    height={18}
                    style={{ marginBottom: 8, borderRadius: 4 }}
                />
                <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width={280}
                    height={15}
                    style={{ marginBottom: 30, borderRadius: 4 }}
                />
                <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width={100}
                    height={13}
                    style={{ marginBottom: 8, borderRadius: 4 }}
                />
                <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width={110}
                    height={13}
                    style={{ marginBottom: 8, borderRadius: 4 }}
                />
                <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width={160}
                    height={13}
                    style={{ marginBottom: 8, borderRadius: 4 }}
                />
                <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width={120}
                    height={13}
                    style={{ marginBottom: 8, borderRadius: 4 }}
                />
                <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width={240}
                    height={13}
                    style={{ marginBottom: 8, borderRadius: 4 }}
                />
            </Box>
        </div>
    );
};
