import { Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import ListSubheader from '@mui/material/ListSubheader';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import React, { ReactElement } from 'react';
import { VariableSizeList } from 'react-window';

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
    const { data, index, style } = props;
    return React.cloneElement(data[index], {
        style: {
            ...style,
            top: style.top + LISTBOX_PADDING,
        },
    });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
    const ref = React.useRef(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<HTMLDivElement, { children: ReactElement<any, any> }>(
    function ListboxComponent(props, ref) {
        const { children, ...other } = props;
        const itemData = React.Children.toArray(children);
        const theme = useTheme();
        const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
        const itemCount = itemData.length;
        const itemSize = smUp ? 36 : 48;

        const getChildSize = (child) => {
            if (React.isValidElement(child) && child.type === ListSubheader) {
                return 48;
            }

            return itemSize;
        };

        const getHeight = () => {
            if (itemCount > 8) {
                return 8 * itemSize;
            }
            return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
        };

        const gridRef = useResetCache(itemCount);

        return (
            <div ref={ref}>
                <OuterElementContext.Provider value={other}>
                    <VariableSizeList
                        itemData={itemData}
                        height={getHeight() + 2 * LISTBOX_PADDING}
                        width='100%'
                        ref={gridRef}
                        outerElementType={OuterElementType}
                        innerElementType='ul'
                        itemSize={(index) => getChildSize(itemData[index])}
                        overscanCount={5}
                        itemCount={itemCount}
                    >
                        {renderRow}
                    </VariableSizeList>
                </OuterElementContext.Provider>
            </div>
        );
    },
);

const useStyles = makeStyles({
    listbox: {
        boxSizing: 'border-box',
        '& ul': {
            padding: 0,
            margin: 0,
        },
    },
});

//TBD - Used when grouping options
/*const renderGroup = (params) => [
  <ListSubheader key={params.key} component="div">
    {params.group}
  </ListSubheader>,
  params.children,
];*/

export default function VirtualizedAutocomplete(props) {
    const classes = useStyles();

    return (
        <Autocomplete
            id={props.id}
            className={props.name}
            style={{ width: props.width || 300 }}
            disableListWrap
            classes={classes}
            ListboxComponent={ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
            onChange={props.onChange}
            inputValue={props.inputValue}
            onInputChange={props.onInputChange}
            getOptionLabel={props.getOptionLabel}
            filterOptions={props.filterOptions}
            /*renderGroup={renderGroup}*/
            options={props.options}
            /*groupBy={(option) => option[0].toUpperCase()}*/
            renderInput={(params: any) => {
                params.inputProps.autoComplete = 'new-password';
                return <TextField {...params} variant='outlined' label={props.label} />;
            }}
            renderOption={(rprops, option, { selected }) => (
                <li {...rprops}>
                    <Typography noWrap>{props.getOptionLabel ? props.getOptionLabel(option) : option}</Typography>
                </li>
            )}
        />
    );
}
