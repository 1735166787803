import React from 'react';

interface PipedriveIconProps extends React.ButtonHTMLAttributes<SVGElement> {}

export const PipedriveIcon: React.FC<PipedriveIconProps> = ({ ...props }) => {
    return (
        <svg {...props} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="9" cy="9" r="9" />
            <path
                d="M10.0505 3.85693C8.88802 3.85693 8.21398 4.40847 7.89161 4.77616C7.85254 4.44932 7.6474 4.03056 6.84637 4.03056H5.09778V5.9303H5.81089C5.92811 5.9303 5.96719 5.97115 5.96719 6.09372V14.7855H8.03814V11.5376V11.2924C8.36051 11.5989 8.97593 12.0278 9.94303 12.0278C11.9651 12.0278 13.3816 10.3528 13.3816 7.94238C13.3914 5.50133 12.0433 3.85693 10.0505 3.85693ZM9.63043 10.1383C8.51681 10.1383 8.00884 9.02502 8.00884 7.98323C8.00884 6.34906 8.85871 5.76688 9.65974 5.76688C10.6366 5.76688 11.3009 6.64525 11.3009 7.96281C11.2911 9.47442 10.451 10.1383 9.63043 10.1383Z"
                className="fill-foreground-secondary"
            />
        </svg>
    );
};
