export const BillingPeriod = {
    1: 'month',
    3: 'quarter',
    6: 'half-year',
    12: 'year',
};

export enum BillingPeriodUnit {
    MONTH = 'month',
    QUARTER = 'quarter',
    HALF_YEAR = 'half-year',
    YEAR = 'year',
}

export const BillingPeriodUnitToNumber = {
    [BillingPeriodUnit.MONTH]: 1,
    [BillingPeriodUnit.QUARTER]: 3,
    [BillingPeriodUnit.HALF_YEAR]: 6,
    [BillingPeriodUnit.YEAR]: 12,
};

export const BillingPeriodNumberToUnit = {
    1: BillingPeriodUnit.MONTH,
    3: BillingPeriodUnit.QUARTER,
    6: BillingPeriodUnit.HALF_YEAR,
    12: BillingPeriodUnit.YEAR,
};

export const BillingPeriodUnitReadable = {
    [BillingPeriodUnit.MONTH]: 'Month',
    [BillingPeriodUnit.YEAR]: 'Year',
    [BillingPeriodUnit.QUARTER]: 'Quarter',
    [BillingPeriodUnit.HALF_YEAR]: 'Half-year',
};

export const BillingFrequencyUnit = {
    month: 'monthly',
    year: 'annually',
    quarter: 'quarterly',
    half_year: 'semi-annually',
};

export const BillingFrequencyUnitReadable = {
    [BillingPeriodUnit.MONTH]: 'Monthly',
    [BillingPeriodUnit.YEAR]: 'Annual',
    [BillingPeriodUnit.QUARTER]: 'Quarterly',
    [BillingPeriodUnit.HALF_YEAR]: 'Semi-Annual',
};

export const BillingFrequencyReadable = {
    [BillingPeriodUnit.MONTH]: 'Monthly',
    [BillingPeriodUnit.YEAR]: 'Annually',
    [BillingPeriodUnit.QUARTER]: 'Quarterly',
    [BillingPeriodUnit.HALF_YEAR]: 'Semi-Annually',
};

export enum SubscriptionStatus {
    ACTIVE = 'active',
    CANCELLED = 'cancelled',
    NON_RENEWING = 'non_renewing',
}

export enum PlanCode {
    FREE = 'free',
    BASIC = 'basic',
    TEAM = 'team',
    ENTERPRISE = 'enterprise',
}

export enum BillingChange {
    UP = 'up',
    NONE = 'none',
    DOWN = 'down',
}

export enum Currency {
    SEK = 'SEK',
    EUR = 'EUR',
}
