import { ICommand, Topics } from '@zaplify/common';
import { ChannelAccountDto, ReauthorizeChannelAccountDto } from '../types/channel-accounts';

export class ReauthorizeChannelAccountCommand extends ICommand<ChannelAccountDto> {
    public static override readonly TOPIC = Topics.CHANNEL_ACCOUNTS;
    constructor(
        readonly userId: string,
        readonly channelAccountId: string,
        readonly reauthorizeChannelAccountDto: ReauthorizeChannelAccountDto,
    ) {
        super();
    }
}
