import type React from 'react';

import { useState } from 'react';
import { motion, AnimatePresence } from 'motion/react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from '@shadcn/ui/components/ui/dialog';
import { Button } from '@shadcn/ui/components/ui/button';
import { ArrowRight, Check } from 'lucide-react';
import { RegisterForm } from './register-form';
import { Link } from 'react-router-dom';
import { paths } from '../../../../routes/paths';

interface SignupDialogProps {
    open: boolean;
    onOpenChange: (open: boolean) => void;
    referringUsersName: string;
}

export function SignupDialog({ open, onOpenChange, referringUsersName }: SignupDialogProps) {
    const [isSuccess, setIsSuccess] = useState(false);

    // Reset form when dialog closes
    const handleOpenChange = (open: boolean) => {
        if (!open) {
            setTimeout(() => setIsSuccess(false), 300); // Reset after close animation
        }
        onOpenChange(open);
    };

    // Handle successful registration
    const handleRegisterSuccess = () => {
        setIsSuccess(true);
    };

    return (
        <Dialog open={open} onOpenChange={handleOpenChange}>
            <DialogContent className="sm:max-w-md bg-background border-border">
                <DialogHeader>
                    <DialogTitle className="text-2xl text-foreground">Create Your Account</DialogTitle>
                    <DialogDescription>Join Andsend and connect with {referringUsersName}</DialogDescription>
                </DialogHeader>

                <AnimatePresence mode="wait">
                    {isSuccess ? (
                        <motion.div
                            key="success"
                            initial={{ opacity: 0, scale: 0.9 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 0.9 }}
                            className="flex flex-col items-center text-center py-6"
                        >
                            <div className="w-16 h-16 rounded-full bg-green-100 dark:bg-green-500/20 flex items-center justify-center mb-4">
                                <Check className="h-8 w-8 text-green-600 dark:text-green-500" />
                            </div>
                            <h2 className="text-xl font-bold mb-2 text-foreground">Account Created!</h2>
                            <p className="text-muted-foreground mb-6">
                                Thanks for joining Andsend through {referringUsersName}'s invitation. Check your email
                                to verify your account.
                            </p>
                            <Button asChild className="bg-primary hover:bg-primary/90 text-primary-foreground w-full">
                                <Link to={paths.HOME}>
                                    Continue to Andsend
                                    <ArrowRight className="ml-2 h-4 w-4" />
                                </Link>
                            </Button>
                        </motion.div>
                    ) : (
                        <motion.div key="form" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                            <RegisterForm
                                className="p-0"
                                hideCard={true}
                                onSuccess={handleRegisterSuccess}
                                referralData={{ referringUsersName }}
                            />
                        </motion.div>
                    )}
                </AnimatePresence>
            </DialogContent>
        </Dialog>
    );
}
