import { UseQueryOptions, UseSuspenseQueryOptions, UseMutationOptions } from '@tanstack/react-query';
import { getZaplifySdk } from '@zaplify/sdk';
import { ProspectDataDto } from '@zaplify/prospects';
import { LinkedinProfileDto } from '@andsend/services/linkedin-profiles/shared';

const fetchLinkedinProfileData = (memberIdOrPublicIdentifier: string) => {
    return getZaplifySdk().profiles.linkedinProfiles.getLinkedinProfileByMemberIdOrPublicIdentifier(
        memberIdOrPublicIdentifier
    );
};
type LinkedinProfileData = Awaited<ReturnType<typeof fetchLinkedinProfileData>>;
export const getLinkedinProfileByMemberIdOrPublicIdentifier = (
    memberIdOrPublicIdentifier: string,
    options?: Omit<
        UseQueryOptions<LinkedinProfileData, Error> & UseSuspenseQueryOptions<LinkedinProfileData, Error>,
        'queryKey' | 'queryFn'
    >
): UseQueryOptions<LinkedinProfileData, Error> & UseSuspenseQueryOptions<LinkedinProfileData, Error> => {
    return {
        queryKey: ['getLinkedinProfileByMemberIdOrPublicIdentifier', memberIdOrPublicIdentifier],
        queryFn: () => fetchLinkedinProfileData(memberIdOrPublicIdentifier),
        enabled: !!memberIdOrPublicIdentifier,
        staleTime: 1000 * 60 * 60, // 1 hour
        ...options,
    };
};

const addFoundLinkedinProfileFn = (params: {
    prospectData?: ProspectDataDto;
    linkedinProfile?: LinkedinProfileDto;
}) => {
    return getZaplifySdk().profiles.linkedinProfiles.addFoundLinkedinProfile(params);
};
type AddFoundLinkedinProfileParams = Parameters<typeof addFoundLinkedinProfileFn>[0];
type AddFoundLinkedinProfileData = Awaited<ReturnType<typeof addFoundLinkedinProfileFn>>;
export const addFoundLinkedinProfile = (
    options?: Omit<UseMutationOptions<AddFoundLinkedinProfileData, Error, AddFoundLinkedinProfileParams>, 'mutationFn'>
): UseMutationOptions<AddFoundLinkedinProfileData, Error, AddFoundLinkedinProfileParams> => {
    return {
        mutationFn: (prospectData) => addFoundLinkedinProfileFn(prospectData),
        ...options,
    };
};

const getLinkedinConnectionsFn = () => {
    return getZaplifySdk().profiles.linkedinProfiles.getLinkedinConnections();
};
type GetLinkedinConnectionsData = Awaited<ReturnType<typeof getLinkedinConnectionsFn>>;
export const getLinkedinConnections = (
    options?: Omit<
        UseQueryOptions<GetLinkedinConnectionsData, Error> & UseSuspenseQueryOptions<GetLinkedinConnectionsData, Error>,
        'queryKey' | 'queryFn'
    >
): UseQueryOptions<GetLinkedinConnectionsData, Error> & UseSuspenseQueryOptions<GetLinkedinConnectionsData, Error> => {
    return {
        queryKey: ['getLinkedinConnections'],
        queryFn: () => getLinkedinConnectionsFn(),
        ...options,
        staleTime: 1000 * 60,
    };
};
