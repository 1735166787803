import { Route } from 'react-router-dom';
import { paths } from '../paths';
import PrivacyPolicyNew from '../../new/pages/legal/privacy-policy';
import PrivacyPolicy20241219 from '../../new/pages/legal/archive/privacy-policy-2024-12-19';
import TermsOfServiceNew from '../../new/pages/legal/terms-of-service';
import TermsOfService20230709 from '../../new/pages/legal/archive/terms-of-service-2023-07-09-zaplify-archive';
import TermsOfService20241219 from '../../new/pages/legal/archive/terms-of-service-2024-12-19';
import NotFoundPage from '../../new/pages/not-found-page';
import RootLayout from '../../new/layouts/root-layout';

import PrivacyPolicyTemp from '../../new/pages/legal/privacy-policy-temp-2024-12-19';
import TermsOfServiceTemp from '../../new/pages/legal/terms-of-service-temp-2024-12-19';

// @TODO Delete the temp versions and replace with the new ones

export const newPublicRoutes = (
    <Route element={<RootLayout />}>
        {/* <Route path={paths.LEGAL.PRIVACY_POLICY} element={<PrivacyPolicyNew />} /> */}
        <Route path={paths.LEGAL.PRIVACY_POLICY} element={<PrivacyPolicyTemp />} />
        <Route path={paths.LEGAL.PRIVACY_POLICY_2024_12_19} element={<PrivacyPolicy20241219 />} />
        {/* <Route path={paths.LEGAL.TERMS} element={<TermsOfServiceNew />} /> */}
        <Route path={paths.LEGAL.TERMS} element={<TermsOfServiceTemp />} />
        <Route path={paths.LEGAL.TERMS_2023_07_09} element={<TermsOfService20230709 />} />
        <Route path={paths.LEGAL.TERMS_2024_12_19} element={<TermsOfService20241219 />} />
        <Route path={paths.NOT_FOUND} element={<NotFoundPage />} />
    </Route>
);
