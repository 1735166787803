import { useQuery } from '@tanstack/react-query';

const allCountriesList = [
    { countryCode: 'AD', commonName: 'Andorra' },
    { countryCode: 'AE', commonName: 'United Arab Emirates' },
    { countryCode: 'AF', commonName: 'Afghanistan' },
    { countryCode: 'AG', commonName: 'Antigua and Barbuda' },
    { countryCode: 'AI', commonName: 'Anguilla' },
    { countryCode: 'AL', commonName: 'Albania' },
    { countryCode: 'AM', commonName: 'Armenia' },
    { countryCode: 'AO', commonName: 'Angola' },
    { countryCode: 'AR', commonName: 'Argentina' },
    { countryCode: 'AS', commonName: 'American Samoa' },
    { countryCode: 'AT', commonName: 'Austria' },
    { countryCode: 'AU', commonName: 'Australia' },
    { countryCode: 'AW', commonName: 'Aruba' },
    { countryCode: 'AZ', commonName: 'Azerbaijan' },
    { countryCode: 'BA', commonName: 'Bosnia and Herzegovina' },
    { countryCode: 'BB', commonName: 'Barbados' },
    { countryCode: 'BD', commonName: 'Bangladesh' },
    { countryCode: 'BE', commonName: 'Belgium' },
    { countryCode: 'BF', commonName: 'Burkina Faso' },
    { countryCode: 'BG', commonName: 'Bulgaria' },
    { countryCode: 'BH', commonName: 'Bahrain' },
    { countryCode: 'BI', commonName: 'Burundi' },
    { countryCode: 'BJ', commonName: 'Benin' },
    { countryCode: 'BM', commonName: 'Bermuda' },
    { countryCode: 'BN', commonName: 'Brunei Darussalam' },
    { countryCode: 'BO', commonName: 'Bolivia, Plurinational State of' },
    { countryCode: 'BR', commonName: 'Brazil' },
    { countryCode: 'BS', commonName: 'Bahamas' },
    { countryCode: 'BT', commonName: 'Bhutan' },
    { countryCode: 'BV', commonName: 'Bouvet Island' },
    { countryCode: 'BW', commonName: 'Botswana' },
    { countryCode: 'BY', commonName: 'Belarus' },
    { countryCode: 'BZ', commonName: 'Belize' },
    { countryCode: 'CA', commonName: 'Canada' },
    { countryCode: 'CC', commonName: 'Cocos (Keeling) Islands' },
    { countryCode: 'CD', commonName: 'Congo, Democratic Republic of the' },
    { countryCode: 'CF', commonName: 'Central African Republic' },
    { countryCode: 'CG', commonName: 'Congo' },
    { countryCode: 'CH', commonName: 'Switzerland' },
    { countryCode: 'CI', commonName: "Côte d'Ivoire" },
    { countryCode: 'CK', commonName: 'Cook Islands' },
    { countryCode: 'CL', commonName: 'Chile' },
    { countryCode: 'CM', commonName: 'Cameroon' },
    { countryCode: 'CN', commonName: 'China' },
    { countryCode: 'CO', commonName: 'Colombia' },
    { countryCode: 'CR', commonName: 'Costa Rica' },
    { countryCode: 'CU', commonName: 'Cuba' },
    { countryCode: 'CV', commonName: 'Cabo Verde' },
    { countryCode: 'CW', commonName: 'Curaçao' },
    { countryCode: 'CY', commonName: 'Cyprus' },
    { countryCode: 'CZ', commonName: 'Czechia' },
    { countryCode: 'DE', commonName: 'Germany' },
    { countryCode: 'DJ', commonName: 'Djibouti' },
    { countryCode: 'DK', commonName: 'Denmark' },
    { countryCode: 'DM', commonName: 'Dominica' },
    { countryCode: 'DO', commonName: 'Dominican Republic' },
    { countryCode: 'DZ', commonName: 'Algeria' },
    { countryCode: 'EC', commonName: 'Ecuador' },
    { countryCode: 'EE', commonName: 'Estonia' },
    { countryCode: 'EG', commonName: 'Egypt' },
    { countryCode: 'ER', commonName: 'Eritrea' },
    { countryCode: 'ES', commonName: 'Spain' },
    { countryCode: 'ET', commonName: 'Ethiopia' },
    { countryCode: 'FI', commonName: 'Finland' },
    { countryCode: 'FJ', commonName: 'Fiji' },
    { countryCode: 'FM', commonName: 'Micronesia, Federated States of' },
    { countryCode: 'FO', commonName: 'Faroe Islands' },
    { countryCode: 'FR', commonName: 'France' },
    { countryCode: 'GA', commonName: 'Gabon' },
    { countryCode: 'GB', commonName: 'United Kingdom' },
    { countryCode: 'GD', commonName: 'Grenada' },
    { countryCode: 'GE', commonName: 'Georgia' },
    { countryCode: 'GF', commonName: 'French Guiana' },
    { countryCode: 'GH', commonName: 'Ghana' },
    { countryCode: 'GL', commonName: 'Greenland' },
    { countryCode: 'GM', commonName: 'Gambia' },
    { countryCode: 'GN', commonName: 'Guinea' },
    { countryCode: 'GP', commonName: 'Guadeloupe' },
    { countryCode: 'GQ', commonName: 'Equatorial Guinea' },
    { countryCode: 'GR', commonName: 'Greece' },
    { countryCode: 'GT', commonName: 'Guatemala' },
    { countryCode: 'GU', commonName: 'Guam' },
    { countryCode: 'GW', commonName: 'Guinea-Bissau' },
    { countryCode: 'GY', commonName: 'Guyana' },
    { countryCode: 'HK', commonName: 'Hong Kong' },
    { countryCode: 'HN', commonName: 'Honduras' },
    { countryCode: 'HR', commonName: 'Croatia' },
    { countryCode: 'HT', commonName: 'Haiti' },
    { countryCode: 'HU', commonName: 'Hungary' },
    { countryCode: 'ID', commonName: 'Indonesia' },
    { countryCode: 'IE', commonName: 'Ireland' },
    { countryCode: 'IL', commonName: 'Israel' },
    { countryCode: 'IM', commonName: 'Isle of Man' },
    { countryCode: 'IN', commonName: 'India' },
    { countryCode: 'IQ', commonName: 'Iraq' },
    { countryCode: 'IR', commonName: 'Iran, Islamic Republic of' },
    { countryCode: 'IS', commonName: 'Iceland' },
    { countryCode: 'IT', commonName: 'Italy' },
    { countryCode: 'JM', commonName: 'Jamaica' },
    { countryCode: 'JO', commonName: 'Jordan' },
    { countryCode: 'JP', commonName: 'Japan' },
    { countryCode: 'KE', commonName: 'Kenya' },
    { countryCode: 'KG', commonName: 'Kyrgyzstan' },
    { countryCode: 'KH', commonName: 'Cambodia' },
    { countryCode: 'KI', commonName: 'Kiribati' },
    { countryCode: 'KM', commonName: 'Comoros' },
    { countryCode: 'KN', commonName: 'Saint Kitts and Nevis' },
    { countryCode: 'KP', commonName: "Korea, Democratic People's Republic of" },
    { countryCode: 'KR', commonName: 'Korea, Republic of' },
    { countryCode: 'KW', commonName: 'Kuwait' },
    { countryCode: 'KY', commonName: 'Cayman Islands' },
    { countryCode: 'KZ', commonName: 'Kazakhstan' },
    { countryCode: 'LA', commonName: "Lao People's Democratic Republic" },
    { countryCode: 'LB', commonName: 'Lebanon' },
    { countryCode: 'LC', commonName: 'Saint Lucia' },
    { countryCode: 'LI', commonName: 'Liechtenstein' },
    { countryCode: 'LK', commonName: 'Sri Lanka' },
    { countryCode: 'LR', commonName: 'Liberia' },
    { countryCode: 'LS', commonName: 'Lesotho' },
    { countryCode: 'LT', commonName: 'Lithuania' },
    { countryCode: 'LU', commonName: 'Luxembourg' },
    { countryCode: 'LV', commonName: 'Latvia' },
    { countryCode: 'LY', commonName: 'Libya' },
    { countryCode: 'MA', commonName: 'Morocco' },
    { countryCode: 'MC', commonName: 'Monaco' },
    { countryCode: 'MD', commonName: 'Moldova, Republic of' },
    { countryCode: 'ME', commonName: 'Montenegro' },
    { countryCode: 'MG', commonName: 'Madagascar' },
    { countryCode: 'MK', commonName: 'North Macedonia' },
    { countryCode: 'ML', commonName: 'Mali' },
    { countryCode: 'MM', commonName: 'Myanmar' },
    { countryCode: 'MN', commonName: 'Mongolia' },
    { countryCode: 'MO', commonName: 'Macao' },
    { countryCode: 'MR', commonName: 'Mauritania' },
    { countryCode: 'MT', commonName: 'Malta' },
    { countryCode: 'MU', commonName: 'Mauritius' },
    { countryCode: 'MV', commonName: 'Maldives' },
    { countryCode: 'MW', commonName: 'Malawi' },
    { countryCode: 'MX', commonName: 'Mexico' },
    { countryCode: 'MY', commonName: 'Malaysia' },
    { countryCode: 'MZ', commonName: 'Mozambique' },
    { countryCode: 'NA', commonName: 'Namibia' },
    { countryCode: 'NC', commonName: 'New Caledonia' },
    { countryCode: 'NE', commonName: 'Niger' },
    { countryCode: 'NF', commonName: 'Norfolk Island' },
    { countryCode: 'NG', commonName: 'Nigeria' },
    { countryCode: 'NI', commonName: 'Nicaragua' },
    { countryCode: 'NL', commonName: 'Netherlands' },
    { countryCode: 'NO', commonName: 'Norway' },
    { countryCode: 'NP', commonName: 'Nepal' },
    { countryCode: 'NR', commonName: 'Nauru' },
    { countryCode: 'NZ', commonName: 'New Zealand' },
    { countryCode: 'OM', commonName: 'Oman' },
    { countryCode: 'PA', commonName: 'Panama' },
    { countryCode: 'PE', commonName: 'Peru' },
    { countryCode: 'PF', commonName: 'French Polynesia' },
    { countryCode: 'PG', commonName: 'Papua New Guinea' },
    { countryCode: 'PH', commonName: 'Philippines' },
    { countryCode: 'PK', commonName: 'Pakistan' },
    { countryCode: 'PL', commonName: 'Poland' },
    { countryCode: 'PM', commonName: 'Saint Pierre and Miquelon' },
    { countryCode: 'PR', commonName: 'Puerto Rico' },
    { countryCode: 'PS', commonName: 'Palestine, State of' },
    { countryCode: 'PT', commonName: 'Portugal' },
    { countryCode: 'PW', commonName: 'Palau' },
    { countryCode: 'PY', commonName: 'Paraguay' },
    { countryCode: 'QA', commonName: 'Qatar' },
    { countryCode: 'RE', commonName: 'Réunion' },
    { countryCode: 'RO', commonName: 'Romania' },
    { countryCode: 'RS', commonName: 'Serbia' },
    { countryCode: 'RW', commonName: 'Rwanda' },
    { countryCode: 'SA', commonName: 'Saudi Arabia' },
    { countryCode: 'SB', commonName: 'Solomon Islands' },
    { countryCode: 'SC', commonName: 'Seychelles' },
    { countryCode: 'SD', commonName: 'Sudan' },
    { countryCode: 'SE', commonName: 'Sweden' },
    { countryCode: 'SG', commonName: 'Singapore' },
    { countryCode: 'SH', commonName: 'Saint Helena, Ascension and Tristan da Cunha' },
    { countryCode: 'SI', commonName: 'Slovenia' },
    { countryCode: 'SK', commonName: 'Slovakia' },
    { countryCode: 'SL', commonName: 'Sierra Leone' },
    { countryCode: 'SM', commonName: 'San Marino' },
    { countryCode: 'SN', commonName: 'Senegal' },
    { countryCode: 'SO', commonName: 'Somalia' },
    { countryCode: 'SR', commonName: 'Suriname' },
    { countryCode: 'SS', commonName: 'South Sudan' },
    { countryCode: 'SV', commonName: 'El Salvador' },
    { countryCode: 'SY', commonName: 'Syrian Arab Republic' },
    { countryCode: 'SZ', commonName: 'Eswatini' },
    { countryCode: 'TC', commonName: 'Turks and Caicos Islands' },
    { countryCode: 'TD', commonName: 'Chad' },
    { countryCode: 'TF', commonName: 'French Southern Territories' },
    { countryCode: 'TG', commonName: 'Togo' },
    { countryCode: 'TH', commonName: 'Thailand' },
    { countryCode: 'TJ', commonName: 'Tajikistan' },
    { countryCode: 'TK', commonName: 'Tokelau' },
    { countryCode: 'TL', commonName: 'Timor-Leste' },
    { countryCode: 'TM', commonName: 'Turkmenistan' },
    { countryCode: 'TN', commonName: 'Tunisia' },
    { countryCode: 'TO', commonName: 'Tonga' },
    { countryCode: 'TR', commonName: 'Turkey' },
    { countryCode: 'TT', commonName: 'Trinidad and Tobago' },
    { countryCode: 'TV', commonName: 'Tuvalu' },
    { countryCode: 'TW', commonName: 'Taiwan, Province of China' },
    { countryCode: 'TZ', commonName: 'Tanzania, United Republic of' },
    { countryCode: 'UA', commonName: 'Ukraine' },
    { countryCode: 'UG', commonName: 'Uganda' },
    { countryCode: 'US', commonName: 'United States' },
    { countryCode: 'UY', commonName: 'Uruguay' },
    { countryCode: 'UZ', commonName: 'Uzbekistan' },
    { countryCode: 'VA', commonName: 'Holy See' },
    { countryCode: 'VC', commonName: 'Saint Vincent and the Grenadines' },
    { countryCode: 'VE', commonName: 'Venezuela, Bolivarian Republic of' },
    { countryCode: 'VN', commonName: 'Vietnam' },
    { countryCode: 'VU', commonName: 'Vanuatu' },
    { countryCode: 'WS', commonName: 'Samoa' },
    { countryCode: 'YE', commonName: 'Yemen' },
    { countryCode: 'ZA', commonName: 'South Africa' },
    { countryCode: 'ZM', commonName: 'Zambia' },
    { countryCode: 'ZW', commonName: 'Zimbabwe' },
];

const fetchCountries = () => {
    // const response = await fetch('https://restcountries.com/v3.1/all');
    // if (!response.ok) {
    //     throw new Error('Network response was not ok');
    // }
    // return response.json().then((data) =>
    //     data
    //         .map((country: any) => ({
    //             commonName: country.name.common,
    //             countryCode: country.cca2,
    //         }))
    //         .sort((a: any, b: any) => a.commonName.localeCompare(b.commonName))
    // );
    return allCountriesList;
};

const useCountries = () => {
    const {
        data: countryList,
        status,
        isError,
        error,
    } = useQuery({
        queryKey: ['countries'],
        queryFn: fetchCountries,
        staleTime: 1000 * 60 * 60 * 24,
    });

    return { countries: countryList, status, isError, error };
};

export default useCountries;
