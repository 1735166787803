import { Button } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux-latest';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppDispatch } from '../../../redux/store/configureStore';
import { paths } from '../../../routes/paths';
import { redirectToPage } from '../../../services/utils/urlHelper';
import { Loader } from '../../../views/auth/containers';
import { ZaplifyLogoLink } from '../TopBar/elements';
import { TopBarAuthContainer } from './containers';

type Props = {
    showLogin?: boolean;
    showRegister?: boolean;
    centered?: boolean;
};
const TopBarAuth = ({ showLogin = false, showRegister = false, centered }: Props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [showLoader, setShowLoader] = useState(false);
    const dispatch = useDispatch<AppDispatch>();

    const redirectToLogin = async () => {
        setShowLoader(true);
        window?.analytics?.track('User Clicked Back To Login');
        redirectToPage({
            url: paths.NEW_LOGIN,
            navigate,
            currentUrl: location.pathname,
        });
    };

    const redirectToRegister = async () => {
        setShowLoader(true);
        window?.analytics?.track('User clicked redirect to register page');
        redirectToPage({
            url: paths.NEW_REGISTER,
            navigate,
            currentUrl: location.pathname,
        });
    };

    return (
        <>
            {showLoader && <Loader />}
            <TopBarAuthContainer>
                <ZaplifyLogoLink className={`logo ${centered ? 'centered' : ''}`} />
                {showLogin && (
                    <div className="log-in">
                        {/* <Hidden smDown> */}
                        <p className="text">Already a Zaplify user?</p>
                        {/* </Hidden> */}
                        <Button className="button" variant="outlined" color="primary" onClick={redirectToLogin}>
                            Log in
                        </Button>
                    </div>
                )}
                {showRegister && (
                    <div className="log-in">
                        <p className="text">New to Zaplify?</p>
                        <Button className="button" variant="outlined" color="primary" onClick={redirectToRegister}>
                            Register
                        </Button>
                    </div>
                )}
            </TopBarAuthContainer>
        </>
    );
};

export default TopBarAuth;
