import * as React from 'react';
import { ScrollArea } from './ui/scroll-area';
import { useEffect, useRef, useState } from 'react';
import { AccordionContent, AccordionItem, AccordionTrigger } from './ui/accordion';
import { cn } from '../lib/utils';

interface ConversationsSectionProps {
    title: string;
    titleEndAdornment?: React.ReactNode;
    lastSection?: boolean;
}

const ConversationsSectionAccordionItem = React.forwardRef<
    HTMLDivElement,
    ConversationsSectionProps & React.ComponentProps<'div'>
>(({ title, titleEndAdornment, children, className, lastSection, ...props }, ref) => {
    const scrollableRef = useRef<HTMLDivElement>(null);
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (scrollableRef.current) {
                setIsScrolled(scrollableRef.current.scrollTop > 0);
            }
        };
        if (scrollableRef.current) {
            scrollableRef.current.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (scrollableRef.current) {
                scrollableRef.current.removeEventListener('scroll', handleScroll);
            }
        };
    }, [scrollableRef?.current?.scrollTop]);

    return (
        <AccordionItem
            value={title}
            className={cn('overflow-hidden min-h-14', lastSection ? 'border-b-0' : '', className)}
        >
            <AccordionTrigger className="flex flex-row space-between p-4 align-center text-sm">
                <div className="flex flex-row justify-start items-center gap-2">
                    {title}
                    {titleEndAdornment}
                </div>
            </AccordionTrigger>
            <div className="flex max-h-[95%]">
                <AccordionContent asChild className="flex max-h-full w-full">
                    <div
                        ref={scrollableRef}
                        className={cn('px-2 overflow-auto pb-2 w-full space-y-1', isScrolled ? 'border-t' : '')}
                    >
                        {children}
                    </div>
                </AccordionContent>
            </div>
        </AccordionItem>
    );
});
ConversationsSectionAccordionItem.displayName = 'ConversationsSectionAccordionItem';

export { ConversationsSectionAccordionItem as ConversationsSectionAccordionItem };
