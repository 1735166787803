import React, { useState } from 'react';
import { IconButton, MenuItem, Modal, Typography } from '@mui/material';
import { ConnectToSalesforceModalContainer } from './containers';
import CloseIcon from '../../../../../components/icons/close';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { clearLocalStorageByKey, setLocalStorage } from '../../../../../services/utils/localStorage';
import { salesforceLocalstorageKey, salesforceProductionLink, salesforceSandboxLink } from '../constants';
import { PlaceHolder } from './placeHolder';

export const ConnectToSalesforceModal = ({ isOpen, setIsOpen, oauthConfig }) => {
    const [environment, setEnvironment] = useState('');

    const handleChange = (event) => {
        setEnvironment(event.target.value);
        setLocalStorage(salesforceLocalstorageKey, event.target.value);
    };

    const handleClose = () => {
        setEnvironment('');
        clearLocalStorageByKey(salesforceLocalstorageKey);
        setIsOpen(false);
    };

    const redirectIsAvailable = environment !== '';

    const redirectToSalesforce = () => {
        if (!redirectIsAvailable) {
            return;
        }
        window.location.replace(`${environment}${oauthConfig}`);
    };

    return (
        <>
            <Modal open={isOpen} onClose={handleClose}>
                <ConnectToSalesforceModalContainer>
                    <div className="modal-container">
                        <div className="input-container">
                            <div>
                                <IconButton onClick={handleClose} disableRipple className="close-icon" size="large">
                                    <CloseIcon />
                                </IconButton>
                            </div>
                            <div className="header-container">
                                <Typography variant="h3" className="title margin-bottom">
                                    Connect to Salesforce
                                </Typography>
                            </div>
                            <div>
                                <Typography variant="h4" paragraph className="typography title-small">
                                    Salesforce Environment
                                </Typography>
                                <Typography paragraph className="typography title-small">
                                    Most people would choose the default, “Production”
                                </Typography>
                                <FormControl className="form-control" variant="filled">
                                    <Select
                                        displayEmpty
                                        renderValue={
                                            environment !== ''
                                                ? undefined
                                                : () => <PlaceHolder>Select option...</PlaceHolder>
                                        }
                                        value={environment}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={salesforceProductionLink}>
                                            Production - salesforce.com
                                        </MenuItem>
                                        <MenuItem value={salesforceSandboxLink}>Sandbox - test.salesforce.com</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                        <Divider className="divider" />
                        <div className="buttons-container">
                            <Button className="button button-outlined-blue " variant="outlined" onClick={handleClose}>
                                Close
                            </Button>
                            <Button
                                className={`${!redirectIsAvailable ? 'disabled' : 'button-blue'} button button-longer`}
                                disabled={!redirectIsAvailable}
                                variant="contained"
                                onClick={redirectToSalesforce}
                            >
                                Continue
                            </Button>
                        </div>
                    </div>
                </ConnectToSalesforceModalContainer>
            </Modal>
        </>
    );
};
