import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import TopBarAuth from '../../components/organisms/TopBarAuth/TopBarAuth';
import { PageContainer } from '../containers';
import { LoaderView } from '../../views/loader';

export const OnboardingTemplate = () => {
    return (
        <Suspense fallback={<LoaderView />}>
            <PageContainer>
                <TopBarAuth />
                <Outlet />
            </PageContainer>
        </Suspense>
    );
};
