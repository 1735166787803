import { Button } from '@shadcn/ui/components/ui/button';
import { useSearchHistory } from './hooks/use-search-history';
import { SearchResults } from './search-result';

export const SearchHistory = ({ onClick }: { onClick: (query: string) => void }) => {
    const { getSearchHistory } = useSearchHistory();
    const searchHistory = getSearchHistory();

    const suggestedHistory: { searchTerm: string; timestamp: number }[] = [];
    if (searchHistory?.length < 5) {
        suggestedHistory.push({ searchTerm: 'Aage Reerslev', timestamp: new Date('2025-01-16').valueOf() });
        suggestedHistory.push({ searchTerm: 'Andsend', timestamp: new Date('2025-01-15').valueOf() });
        suggestedHistory.push({
            searchTerm: 'https://www.linkedin.com/in/kevinostlin/',
            timestamp: new Date('2025-01-14').valueOf(),
        });
        suggestedHistory.push({
            searchTerm: 'AI sales engagement platform personalized follow-ups',
            timestamp: new Date('2025-01-13').valueOf(),
        });
    }

    return (
        <SearchResults
            sectionTitle="Recent searches"
            results={[...searchHistory, ...suggestedHistory].map((search) => (
                <Button
                    variant="ghost"
                    key={search.timestamp}
                    className="p-2 border-none w-full justify-start hover:bg-muted/50 font-normal"
                    onClick={() => onClick(search.searchTerm)}
                >
                    {search.searchTerm}
                </Button>
            ))}
        />
    );
};
