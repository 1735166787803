import { styled } from '@mui/styles';

const GlobalSearchPopupStyled = styled('div')(({ theme: { palette } }) => ({
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    '& .input-wrapper': {
        width: '100%',
        display: 'flex',
        justifyItems: 'space-between',
        padding: '18px',
        boxSizing: 'border-box',
        borderBottom: `1px solid #21212121`,
        textAlign: 'left',
        color: palette.subline,

        '& * > input': {
            fontSize: '14px',
        },
        '& * > input::placeholder': {
            color: palette.colors.subline,
        },
        '& * > svg': {
            color: palette.colors.greyDark,
        },
    },
    '& .wrapper': {
        maxHeight: '590px',
        marginTop: '6px',
        overflowY: 'scroll',

        '& .loader': {
            margin: '26px 0',
            textAlign: 'center',
        },
        '& .divider': {
            backgroundColor: palette.colors.greyInformationLight,
        },
        '& .section': {
            padding: '8px',

            '& * > a': { textDecoration: 'none' },
        },
        '& * > .title': {
            marginBottom: '10px',
            fontSize: '13px',
            fontWeight: '500',
            color: palette.colors.greyDark,
        },
    },
    '& .close-icon': {
        cursor: 'pointer',
        color: palette.colors.greyDark,
        strokeWidth: 1,
        '&:hover': {
            opacity: 0.8,
        },
    },
    '& .linear-progress': {
        width: '100%',
        position: 'relative',
        top: 0,
    },
}));

export default GlobalSearchPopupStyled;
