import { useState, useEffect } from 'react';

const useFallbackCountries = () => {
    const [fallbackCountries, setFallbackCountries] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        import('../config/countryList.js')
            .then((module) => {
                setFallbackCountries(module.countryList);
            })
            .catch((error) => {
                console.error('Failed to load countries list', error);
                setError(error);
            });
    }, []);

    return [fallbackCountries, error];
};

export default useFallbackCountries;
