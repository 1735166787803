// import { datadogRum } from '@datadog/browser-rum';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { routes } from './routes/new-routes';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { reduxFirebaseConfig } from './services/firebase';
import { useEffect, useState } from 'react';

const router = createBrowserRouter(routes);

const App = () => {
    // @TODO: Remove this once we have a proper firebase config setup... this file magically works because the file is included and logging the object below keeps the file from being removed during build.
    console.log(reduxFirebaseConfig);
    const [isAnalyticsReady, setIsAnalyticsReady] = useState(false);

    useEffect(() => {
        if (window.analytics && typeof window.analytics.ready === 'function') {
            window.analytics.ready(() => {
                console.log('analytics loaded (ready callback ran)');
                setIsAnalyticsReady(true);
            });
            // Also set
            if (window.analytics.initialized && window.analytics.identify && window.analytics.track) {
                console.log('analytics loaded (initialized true)');
                setIsAnalyticsReady(true);
            }
        } else {
            console.warn('Analytics ready function is not available');
            setIsAnalyticsReady(true); // Proceed even if the ready function doesn't exist
        }
    }, [window.analytics?.initialized]);

    if (!isAnalyticsReady) {
        console.log('Analytics not ready');
        return null; // or a loading spinner, or any placeholder you prefer
    }
    console.log('Analytics is ready');
    return (
        <>
            <RouterProvider router={router} />
        </>
    );
};

const AppWithLaunchDarkly = withLDProvider({
    clientSideID: import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_ID,
    options: {
        streaming: true,
        // streamUrl: 'https://core5.andsend.com/lds', Stream URL does not need to be proxied
        baseUrl: 'https://core5.andsend.com/ldb',
        eventsUrl: 'https://core5.andsend.com/lde',
    },
})(App);

export default AppWithLaunchDarkly;
