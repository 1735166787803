import { styled } from '@mui/styles';

export const ModalContainer = styled('div')(({ theme }) => ({
    width: '90vw',
    height: '90vh',
    background: 'white',
    margin: 'auto',
    marginTop: '5vh',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 1178,
    borderRadius: 14,
    '& .MuiTableCell-body': {
        border: '0.5px solid #E4E6EB',
        whiteSpace: 'nowrap',
        '&.error': {
            background: '#EEE0E2',
            border: '0.5px solid #872336',
        },
        '& > svg': {
            marginRight: 10,
            marginBottom: '-3px',
        },
    },
    '& > .header-container': {
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: '1px solid #E4E6EB',
        padding: '20px 50px',
        '& .close-button': {
            marginLeft: 10,
        },
        '& .subtitle': {
            fontSize: 14,
            marginTop: 4,
            color: theme.palette.subline,
        },
    },
    '& > .MuiTableContainer-root': {
        padding: '5px 50px',
        boxSizing: 'border-box',
        display: 'flex',
        overflow: 'auto',
    },
    '& .MuiTable-root': {
        borderCollapse: 'separate',
    },
}));
