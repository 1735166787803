import { UserRole } from './constants';

export enum InvitationStatus {
    SENT = 'sent',
    READ = 'read',
    REVOKED = 'revoked',
    ACCEPTED = 'accepted',
    REJECTED = 'rejected',
    EXPIRED = 'expired',
}

export interface IInvitationReduced {
    invitationId: string;
    status: string;
    organization: {
        id: string;
        name: string;
    };
}

export interface IInvitation {
    id: string;
    organizationId: string;
    status: InvitationStatus;
    validUntil: Date;
    assignedRoles: UserRole[];
    securityHash: string;
    createdAt: Date;
}

export class InvitationReducedDto implements IInvitationReduced {
    invitationId: string;
    status: string;
    organization: {
        id: string;
        name: string;
    };
    constructor(dto: InvitationReducedDto) {
        this.invitationId = dto.invitationId;
        this.status = dto.status;
        this.organization = dto.organization;
    }
}

export interface InvitationDto {
    id: string;
    organizationId: string;
    userId: string;
    email: string;
    status: InvitationStatus;
    validUntil: Date;
    assignedRoles: UserRole[];
    securityToken: string;
    createdAt: Date;
}

export class CreateInvitationDto {
    organizationId: string;

    emails: string[];

    role: UserRole;

    constructor(dto: CreateInvitationDto) {
        this.organizationId = dto.organizationId;
        this.emails = dto.emails;
        this.role = dto.role;
    }
}
