import { darken, lighten } from '@mui/material';

const andsendColors = {
    primary: {
        gray: {
            lightMode: '#667085',
            darkMode: '#85888E',
        },
        brand: '#5C5FFF',
        error: '#F04438',
        warning: '#F79009',
        success: '#17B26A',
    },
    secondary: {
        /** Can be swapped with default success color */
        greenLight: '#66C61C',
        turquiseLight: '#32DBF1',
        turquise: '#00CFFF',
        blueLight: '#0BA5EC',
        blue: '#1A69FF',
        purple: '#A15DEE',
        pink: '#EE46BC',
        rose: '#F63D68',
        /** Can be swapped with default error color */
        red: '#FF333D',
        /** Can be swapped with default warning color */
        orange: '#FF773D',
        /** Can be swapped with default warning color */
        yellow: '#FFC229',
    },
};

export const paletteAndsend = {
    primary: {
        main: andsendColors.primary.brand,
        light: lighten(andsendColors.primary.brand, 0.1),
        dark: darken(andsendColors.primary.brand, 0.1),
    },
    secondary: {
        main: andsendColors.primary.gray.lightMode,
        light: lighten(andsendColors.primary.gray.lightMode, 0.1),
        dark: darken(andsendColors.primary.gray.lightMode, 0.1),
    },
    error: {
        main: andsendColors.primary.error,
        light: lighten(andsendColors.primary.error, 0.1),
        dark: darken(andsendColors.primary.error, 0.1),
    },
    warning: {
        main: andsendColors.primary.warning,
        light: lighten(andsendColors.primary.warning, 0.1),
        dark: darken(andsendColors.primary.warning, 0.1),
    },
    // info: {
    //     main: '#FF6C00',
    //     light: '#FF6C00',
    //     dark: '#FF6C00',
    // },
    success: {
        main: andsendColors.primary.success,
        light: lighten(andsendColors.primary.success, 0.1),
        dark: darken(andsendColors.primary.success, 0.1),
    },
    blue: {
        200: '#B9CFF8',
    },
    // text: {
    //     primary: '#FF6C00',
    //     secondary: '#FF6C00',
    //     disabled: '#FF6C00',
    // },
    // background: {
    //     default: '#FF6C00',
    //     paper: '#FF6C00',
    // },
};
