import { IQuery, Topics } from '@zaplify/common';
import { Invoice } from 'chargebee';

export class GetInvoiceQuery extends IQuery<Invoice> {
    public static override readonly TOPIC = Topics.BILLING;

    constructor(readonly invoiceId: string) {
        super();
    }
}
