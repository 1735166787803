import { LocationDto } from '@zaplify/prospects';
import { getZaplifySdk } from '@zaplify/sdk';
import { batch } from 'react-redux-latest';
import { replaceProspectSearch, updateLastFilterPointName, updateSearch } from '.';
import { updatePersonFilter } from '../../actions';
import { postFilter, postUpdatedSource } from '../prospect-search-result/helpers';
import { getUnformattedProspectSearch } from './helpers';
import { UpdateSearchPayload } from './types';
import { SearchScopes } from '@zaplify/constants';

export const setupSearchFilters = (source) => async (dispatch) => {
    let normalizedSearch;
    if (source?.type?.class !== 'BULK_IMPORT') {
        normalizedSearch = getUnformattedProspectSearch(source);
        normalizedSearch.previewProspects = null;
    } else {
        dispatch(thunkUpdateSearch({ value: SearchScopes.uploadProspects, point: 'searchScope' }));
        return;
    }
    dispatch(replaceProspectSearch(normalizedSearch));
};

export const saveSourceToFlow = () => async (dispatch, getState) => {
    console.log('saveSourceToFlow');
    const sdk = getZaplifySdk().profiles;
    const campaignId = window.location.pathname.split('edit/')[1];
    try {
        const source = await dispatch(saveSource());
        if (!getState().newCampaign?.personFilterId) {
            sdk.campaigns.assignFilterToCampaign(campaignId, source.id);
            dispatch(updatePersonFilter(source.id));
        }
    } catch (error) {
        console.log("couldn't save filters");
    } finally {
    }
};

export const saveSource = () => async (dispatch, getState) => {
    console.log('saveSource');
    const prospectSearch = getState().prospectSearchFilters;
    const sdk = getZaplifySdk().profiles;
    if (getState().newCampaign?.personFilterId) {
        const sourceId = getState().newCampaign?.personFilterId;
        return await postUpdatedSource(prospectSearch, sourceId, sdk);
    } else {
        const filter = await postFilter(prospectSearch, sdk);
        return filter;
    }
};

export const thunkUpdateSearch =
    ({ value, point }: UpdateSearchPayload) =>
    async (dispatch, getState) => {
        batch(() => {
            dispatch(updateSearch({ value, point }));
            // dispatch(updateHasSomeFiltersSelected(getState().prospectSearchFilters));
            if (Array.isArray(value)) {
                dispatch(updateLastFilterPointName({ value: value[value.length - 1], point }));
            }
            if (typeof value === 'string') {
                dispatch(updateLastFilterPointName({ value: value, point }));
            }
            if ((value as LocationDto).name) {
                dispatch(updateLastFilterPointName({ value: (value as LocationDto).name, point }));
            }
        });
    };
