import { useQuery } from '@apollo/client';
import { GET_TEAM_DASHBOARD_METRICS } from '@zaplify/graphql';
import {
    format,
    startOfWeek,
    parseISO,
    eachDayOfInterval,
    eachWeekOfInterval,
    sub,
    endOfDay,
    differenceInDays,
} from 'date-fns';
import { useMemo } from 'react';
import { useAuth } from '../../../providers/authentication-provider';
import { UserRole, UserStatus } from '@zaplify/users/shared';
import { getZaplifySdk } from '@zaplify/sdk';
import { useQuery as useTanstackQuery } from '@tanstack/react-query';

interface TeamMetricsProps {
    startDate: Date;
    endDate: Date;
    frequency: 'daily' | 'weekly';
    distinctProspects: boolean;
}

interface DateMetric {
    messagesSent: number;
    messagesReceived: number;
    connectionsAccepted: number;
    uniqueProspectsSent: Set<string>;
    uniqueProspectsReceived: Set<string>;
    uniqueConnectionsAccepted: Set<string>;
}

interface PerformanceMetric {
    userId: string;
    name: string;
    initials: string;
    messagesSent: number;
    messagesReceived: number;
    connectionsAccepted: number;
    uniqueProspectsSent: Set<string>;
    uniqueProspectsReceived: Set<string>;
    uniqueProspectsSentPrev: Set<string>;
    uniqueProspectsReceivedPrev: Set<string>;
    previousMessagesSent: number;
    previousMessagesReceived: number;
    previousConnectionsAccepted: number;
}

export const useTeamMetrics = ({ startDate, endDate, frequency, distinctProspects }: TeamMetricsProps) => {
    const {
        authState: { user, userOrganizationId },
    } = useAuth();

    const { data: organizationUsers, isLoading: loadingUsers } = useTanstackQuery({
        queryKey: ['organization-users', userOrganizationId],
        queryFn: async () => {
            const userOrganizationSdk = getZaplifySdk().profiles.userOrganization;
            return userOrganizationSdk.getUsersByOrganization(userOrganizationId);
        },
        enabled: !!user?.roles.includes(UserRole.ORGANIZATION_ADMIN),
    });

    const previousStartDate = useMemo(() => {
        const periodLength = differenceInDays(endDate, startDate);
        return sub(startDate, { days: periodLength });
    }, [startDate, endDate]);

    const {
        data: metricsData,
        loading: loadingMetrics,
        error,
    } = useQuery(GET_TEAM_DASHBOARD_METRICS, {
        variables: {
            startDate: new Date(startDate.getTime() + 10).toISOString(),
            endDate: endOfDay(endDate).toISOString(),
            previousStartDate: previousStartDate.toISOString(),
            organizationId: userOrganizationId,
        },
        fetchPolicy: 'no-cache',
        context: {
            headers: {
                'x-hasura-role': user?.roles.includes(UserRole.ORGANIZATION_ADMIN)
                    ? UserRole.ORGANIZATION_ADMIN
                    : UserRole.ORGANIZATION_MEMBER,
            },
        },
        skip: !user?.roles.includes(UserRole.ORGANIZATION_ADMIN),
    });

    const aggregatedMetrics = useMemo(() => {
        if (!metricsData) return [];

        const dateMap = new Map<string, DateMetric>();
        const dateRange =
            frequency === 'daily'
                ? eachDayOfInterval({ start: startDate, end: endDate })
                : eachWeekOfInterval({ start: startDate, end: endDate }, { weekStartsOn: 1 });

        // Initialize all dates
        dateRange.forEach((date) => {
            const dateKey = format(date, 'yyyy-MM-dd');
            dateMap.set(dateKey, {
                messagesSent: 0,
                messagesReceived: 0,
                connectionsAccepted: 0,
                uniqueProspectsSent: new Set<string>(),
                uniqueProspectsReceived: new Set<string>(),
                uniqueConnectionsAccepted: new Set<string>(),
            });
        });

        // Process sent messages
        metricsData.numberOfSentMessages.nodes.forEach((node) => {
            const date = parseISO(node.sentOn);
            const dateKey =
                frequency === 'daily'
                    ? format(date, 'yyyy-MM-dd')
                    : format(startOfWeek(date, { weekStartsOn: 1 }), 'yyyy-MM-dd');

            const metrics = dateMap.get(dateKey);
            if (metrics) {
                metrics.messagesSent++;
                metrics.uniqueProspectsSent.add(node.prospectId);
            }
        });

        // Process received messages
        metricsData.numberOfReceivedMessages.nodes.forEach((node) => {
            const date = parseISO(node.sentOn);
            const dateKey =
                frequency === 'daily'
                    ? format(date, 'yyyy-MM-dd')
                    : format(startOfWeek(date, { weekStartsOn: 1 }), 'yyyy-MM-dd');

            const metrics = dateMap.get(dateKey);
            if (metrics) {
                metrics.messagesReceived++;
                metrics.uniqueProspectsReceived.add(node.prospectId);
            }
        });

        // Process connections
        metricsData.numberOfNewConnections.nodes.forEach((node) => {
            const date = parseISO(node.sentOn);
            const dateKey =
                frequency === 'daily'
                    ? format(date, 'yyyy-MM-dd')
                    : format(startOfWeek(date, { weekStartsOn: 1 }), 'yyyy-MM-dd');

            const metrics = dateMap.get(dateKey);
            if (metrics) {
                metrics.connectionsAccepted++;
                metrics.uniqueConnectionsAccepted.add(node.prospectId);
            }
        });

        return Array.from(dateMap.entries())
            .map(([dateKey, metrics]) => ({
                date: format(parseISO(dateKey), 'MMM dd, yyyy'),
                messagesSent: distinctProspects ? metrics.uniqueProspectsSent.size : metrics.messagesSent,
                messagesReceived: distinctProspects ? metrics.uniqueProspectsReceived.size : metrics.messagesReceived,
                connectionsAccepted: distinctProspects
                    ? metrics.uniqueConnectionsAccepted.size
                    : metrics.connectionsAccepted,
            }))
            .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    }, [metricsData, frequency, startDate, endDate, distinctProspects]);

    const latestMetrics = useMemo(() => {
        if (!metricsData)
            return { messagesSent: 0, messagesReceived: 0, connectionsAccepted: 0, messagesSentFromApp: 0 };

        if (distinctProspects) {
            const uniqueSentProspects = new Set(metricsData.numberOfSentMessages.nodes.map((n) => n.prospectId));
            const uniqueReceivedProspects = new Set(
                metricsData.numberOfReceivedMessages.nodes.map((n) => n.prospectId)
            );
            const uniqueConnectionsAccepted = new Set(
                metricsData.numberOfNewConnections.nodes.map((n) => n.prospectId)
            );

            return {
                messagesSent: uniqueSentProspects.size,
                messagesSentFromApp: uniqueSentProspects.size,
                messagesReceived: uniqueReceivedProspects.size,
                connectionsAccepted: uniqueConnectionsAccepted.size,
            };
        }

        return {
            messagesSent: metricsData.numberOfSentMessages.aggregate.count,
            messagesSentFromApp: metricsData.numberOfSentMessages.nodes.filter((n) => n.isSentFromApp).length,
            messagesReceived: metricsData.numberOfReceivedMessages.aggregate.count,
            connectionsAccepted: metricsData.numberOfNewConnections.aggregate.count,
        };
    }, [metricsData, distinctProspects]);

    const previousMetrics = useMemo(() => {
        if (!metricsData) return { messagesSent: 0, messagesReceived: 0, connectionsAccepted: 0 };

        if (distinctProspects) {
            const uniqueSentProspects = new Set(metricsData.numberOfSentMessagesPrev.nodes.map((n) => n.prospectId));
            const uniqueReceivedProspects = new Set(
                metricsData.numberOfReceivedMessagesPrev.nodes.map((n) => n.prospectId)
            );

            return {
                messagesSent: uniqueSentProspects.size,
                messagesReceived: uniqueReceivedProspects.size,
                connectionsAccepted: metricsData.numberOfNewConnectionsPrev.aggregate.count,
            };
        }

        return {
            messagesSent: metricsData.numberOfSentMessagesPrev.aggregate.count,
            messagesReceived: metricsData.numberOfReceivedMessagesPrev.aggregate.count,
            connectionsAccepted: metricsData.numberOfNewConnectionsPrev.aggregate.count,
        };
    }, [metricsData, distinctProspects]);

    // Calculate time saved (assuming 5 minutes per message sent)
    const timeSaved = useMemo(() => {
        const totalMessagesSent = metricsData?.numberOfSentMessages.nodes.filter((n) => n.isSentFromApp).length ?? 0;
        return Math.round((totalMessagesSent * 5) / 60); // Convert to hours
    }, [metricsData]);

    const previousTimeSaved = useMemo(() => {
        const totalMessagesSent =
            metricsData?.numberOfSentMessagesPrev.nodes.filter((n) => n.isSentFromApp).length ?? 0;
        return Math.round((totalMessagesSent * 5) / 60); // Convert to hours
    }, [metricsData]);
    // Calculate top performers
    const topPerformers = useMemo(() => {
        if (!metricsData || !organizationUsers) return [];

        const performanceMap = new Map<string, PerformanceMetric>();
        // Initialize metrics for all active users with seats
        organizationUsers
            .filter((user) => user.status === UserStatus.ACTIVATED && user.seatId)
            .forEach((user) => {
                performanceMap.set(user.id, {
                    userId: user.id,
                    name: `${user.firstName} ${user.lastName}`,
                    initials: `${user.firstName[0]}${user.lastName[0]}`,
                    messagesSent: 0,
                    messagesReceived: 0,
                    connectionsAccepted: 0,
                    uniqueProspectsSent: new Set<string>(),
                    uniqueProspectsReceived: new Set<string>(),
                    uniqueProspectsSentPrev: new Set<string>(),
                    uniqueProspectsReceivedPrev: new Set<string>(),
                    previousMessagesSent: 0,
                    previousMessagesReceived: 0,
                    previousConnectionsAccepted: 0,
                });
            });
        // Process all metrics for each user
        metricsData.numberOfSentMessages.nodes.forEach((node) => {
            const metrics = performanceMap.get(node.userId);
            if (metrics) {
                metrics.messagesSent++;
                metrics.uniqueProspectsSent.add(node.prospectId);
            }
        });

        metricsData.numberOfReceivedMessages.nodes.forEach((node) => {
            const metrics = performanceMap.get(node.userId);
            if (metrics) {
                metrics.messagesReceived++;
                metrics.uniqueProspectsReceived.add(node.prospectId);
            }
        });

        metricsData.numberOfNewConnections.nodes.forEach((node) => {
            const metrics = performanceMap.get(node.userId);
            if (metrics) {
                metrics.connectionsAccepted++;
            }
        });
        metricsData.numberOfSentMessagesPrev.nodes.forEach((node) => {
            const metrics = performanceMap.get(node.userId);
            if (metrics) {
                metrics.previousMessagesSent++;
                metrics.uniqueProspectsSentPrev.add(node.prospectId);
            }
        });

        metricsData.numberOfReceivedMessagesPrev.nodes.forEach((node) => {
            const metrics = performanceMap.get(node.userId);
            if (metrics) {
                metrics.previousMessagesReceived++;
                metrics.uniqueProspectsReceivedPrev.add(node.prospectId);
            }
        });

        metricsData.numberOfNewConnectionsPrev.nodes.forEach((node) => {
            const metrics = performanceMap.get(node.userId);
            if (metrics) {
                metrics.previousConnectionsAccepted++;
            }
        });

        // Calculate performance score for each user
        return Array.from(performanceMap.entries())
            .map(([userId, metrics]) => {
                const responseRate = metrics.messagesReceived / metrics.messagesSent || 0;
                const score = (responseRate * metrics.messagesSent * (metrics.connectionsAccepted + 1)) / 100;

                return {
                    id: userId,
                    name: metrics.name,
                    initials: metrics.initials,
                    score,
                    metrics: {
                        messagesSent: metrics.messagesSent,
                        previousMessagesSent: metrics.previousMessagesSent,
                        messagesReceived: metrics.messagesReceived,
                        previousMessagesReceived: metrics.previousMessagesReceived,
                        connectionsAccepted: metrics.connectionsAccepted,
                        previousConnectionsAccepted: metrics.previousConnectionsAccepted,
                        uniqueProspectsSent: metrics.uniqueProspectsSent.size,
                        uniqueProspectsReceived: metrics.uniqueProspectsReceived.size,
                        uniqueProspectsSentPrev: metrics.uniqueProspectsSentPrev.size,
                        uniqueProspectsReceivedPrev: metrics.uniqueProspectsReceivedPrev.size,
                    },
                };
            })
            .sort((a, b) => b.score - a.score);
    }, [metricsData, organizationUsers]);

    return {
        aggregatedMetrics,
        latestMetrics,
        previousMetrics,
        timeSaved,
        previousTimeSaved,
        topPerformers,
        loading: loadingMetrics || loadingUsers,
        error,
    };
};
