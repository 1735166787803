import { Typography } from '@mui/material';
import { theme } from '../theme';

const colors = (theme.palette as any).colors;

const NoSeatsLeftInfo = ({ adminEmail }: { adminEmail?: string }) => (
    <div style={{ display: 'flex', padding: '42px 24px', flexDirection: 'column', alignItems: 'center' }}>
        <Typography
            style={{
                marginBottom: 18,
                fontWeight: 500,
                fontSize: 24,
                color: colors.black,
            }}
        >
            Looks like there are no seats left
        </Typography>
        <Typography sx={{ fontSize: 17, color: colors.subline, textWrap: 'balance', textAlign: 'center' }}>
            To join your team, ask the workspace admin{' '}
            <Typography
                component={'span'}
                sx={{
                    textDecoration: 'underline',
                    color: colors.blue,
                    fontSize: 17,
                }}
            >
                {adminEmail}
            </Typography>{' '}
            to assign you a paid seat.
        </Typography>
    </div>
);

export default NoSeatsLeftInfo;
