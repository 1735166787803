import { cn } from '@shadcn/ui/lib/utils';
import { AndSendLogotype } from '../../components/icons/and-send/andsend-logo';
import { AndSendIcon } from '../../components/icons/and-send/andsend-icon';
import { AndSendLoader } from '@shadcn/ui/components/icons/and-send-loader/andsend-loader';

export const AccountLogo = ({ accountWebsite, className }: { accountWebsite: string; className?: string }) => {
    const organizationLogoHostname = (() => {
        try {
            const url = new URL(accountWebsite);
            return url.hostname;
        } catch (error) {
            return accountWebsite;
        }
    })();

    const isValidHostname = organizationLogoHostname && organizationLogoHostname.includes('.');
    const classNames = cn('w-full h-full rounded-full object-contain', className);
    if (organizationLogoHostname?.includes('andsend.com')) {
        return <AndSendLoader variation="color" className={classNames + ' p-1'} />;
    }

    return (
        <img
            className={classNames}
            src={isValidHostname ? `https://logo.clearbit.com/${organizationLogoHostname}` : '/images/no-logo-icon.svg'}
            alt={isValidHostname ? `${organizationLogoHostname} logo` : 'Logo not available'}
            onError={(e) => {
                e.currentTarget.onerror = null;
                e.currentTarget.src = '/images/no-logo-icon.svg';
            }}
        />
    );
};
