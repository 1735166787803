import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: SalesforceStateType = {
    account: {},
    integrationScope: {},
    fieldMapping: [],
    isActive: false,
    isLoading: false,
    availableFields: {},
    firstTime: false,
    mapLinkedIn: false,
    integrationStrategy: '',
    selectedStrategy: '',
    saveStatus: false,
    mapZaplifyProspectStatus: false,
    mapOrganizationSize: false,
    mapOrganizationIndustry: false,
    mapOrganizationDomain: false,
};

export interface SalesforceStateType {
    account: Account;
    integrationScope: IntegrationScopeType;
    fieldMapping: FieldMapping[];
    isActive: boolean;
    isLoading: boolean;
    availableFields: AvailableFieldsType;
    firstTime: boolean;
    integrationStrategy: string;
    selectedStrategy: string;
    mapLinkedIn: boolean;
    saveStatus: boolean;
    mapZaplifyProspectStatus: boolean;
    mapOrganizationSize: boolean;
    mapOrganizationIndustry: boolean;
    mapOrganizationDomain: boolean;
}

interface Account {
    organizationId?: string;
    organizationDomain?: string;
}

export interface FieldMapping {
    source: {
        type?: string;
        fieldName?: string;
        value?: string;
    };
    destination: {
        objectType: string;
        fieldName: string;
        required: boolean;
    };
    errorText?: string;
}

interface IntegrationScopeType {
    prospects?: boolean;
    campaignsActivity?: boolean;
    actionMessages?: boolean;
    gdprDelete?: boolean;
}

interface AvailableFieldsType {
    contact?: AvailableFields[];
    contactPickList?: AvailableFields[];
    prospect?: AvailableFields[];
    account?: AvailableFields[];
    accountPickList?: AvailableFields[];
    lead?: AvailableFields[];
    leadPickList?: AvailableFields[];
}

export interface AvailableFields {
    type: string;
    label: string;
    name: string;
    options?: any[];
    required: boolean;
    resource: string;
}

const salesforceSlice = createSlice({
    name: 'salesforce',
    initialState: initialState as SalesforceStateType,
    reducers: {
        updateSettings(state, action: PayloadAction<any>) {
            return {
                ...state,
                account: action.payload.account,
                integrationScope: action.payload.integrationScope,
                fieldMapping: action.payload.fieldMapping || [],
                isActive: action.payload.isActive,
                integrationStrategy: action.payload.integrationStrategy,
                selectedStrategy: action.payload.integrationStrategy,
            };
        },
        updateSalesforceActiveState(state, action: PayloadAction<boolean>) {
            state.isActive = action.payload;
        },
        updateSalesforceFirstTimeState(state, action: PayloadAction<boolean>) {
            state.firstTime = action.payload;
        },
        updateAvailableFields(state, action: PayloadAction<any>) {
            state.availableFields = action.payload;
        },
        updateScope(state, action: PayloadAction<{ key: string; value: any }>) {
            state.integrationScope[action.payload.key] = action.payload.value;
        },
        updateSaveStatus(state, action: PayloadAction<any>) {
            state.saveStatus = action.payload;
        },
        updateLoadingStatus(state, action: PayloadAction<any>) {
            state.isLoading = action.payload;
        },
        updateField(
            state,
            action: PayloadAction<{
                idx: number;
                type: string;
                objectType: string;
                fieldName: string;
                isStatic?: boolean;
            }>
        ) {
            if (action.payload.isStatic) {
                delete state.fieldMapping[action.payload.idx]['source']['fieldName'];
                state.fieldMapping[action.payload.idx]['source']['type'] = 'static';
                state.fieldMapping[action.payload.idx]['source']['value'] = '';
            }
            if (action.payload.type === 'source' && action.payload.objectType === 'static') {
                delete state.fieldMapping[action.payload.idx][action.payload.type]['fieldName'];
                delete state.fieldMapping[action.payload.idx][action.payload.type]['value'];
                state.fieldMapping[action.payload.idx][action.payload.type]['value'] = action.payload.fieldName;
            } else if (action.payload.type === 'source') {
                delete state.fieldMapping[action.payload.idx][action.payload.type]['type'];
                state.fieldMapping[action.payload.idx][action.payload.type]['type'] = action.payload.objectType;
                delete state.fieldMapping[action.payload.idx][action.payload.type]['fieldName'];
                state.fieldMapping[action.payload.idx][action.payload.type]['fieldName'] = action.payload.fieldName;
            } else {
                delete state.fieldMapping[action.payload.idx][action.payload.type]['objectType'];
                state.fieldMapping[action.payload.idx][action.payload.type]['objectType'] = action.payload.objectType;
                delete state.fieldMapping[action.payload.idx][action.payload.type]['fieldName'];
                state.fieldMapping[action.payload.idx][action.payload.type]['fieldName'] = action.payload.fieldName;
            }
        },
        addField(state, action: PayloadAction<{ objectType: string }>) {
            state.fieldMapping.push({
                source: {
                    type: '',
                    fieldName: '',
                },
                destination: {
                    objectType: action.payload.objectType,
                    fieldName: '',
                    required: false,
                },
            });
        },
        deleteField(state, action: PayloadAction<{ index: number }>) {
            state.fieldMapping.splice(action.payload.index, 1);
        },
        updateFieldMapping(state, action: PayloadAction<any>) {
            state.fieldMapping = action.payload;
        },
        updateSelectedStrategy(state, action: PayloadAction<any>) {
            state.selectedStrategy = action.payload;
        },
        updateIntegrationStrategy(state, action: PayloadAction<any>) {
            state.integrationStrategy = action.payload;
        },
    },
});

export const {
    updateSettings,
    updateField,
    deleteField,
    addField,
    updateSelectedStrategy,
    updateScope,
    updateAvailableFields,
    updateSalesforceActiveState,
    updateSalesforceFirstTimeState,
    updateFieldMapping,
    updateSaveStatus,
    updateIntegrationStrategy,
    updateLoadingStatus,
} = salesforceSlice.actions;

export const salesforceReducer = salesforceSlice.reducer;
