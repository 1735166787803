import { styled } from '@mui/styles';

const startAuthorizationFlow = async (navigateUrl) => {
    // Create popup interaction handler.
    const popupHandler = new PopupHandler();
    // Show the UI once the url has been created. Get the window handle for the popup.
    const popupWindow = popupHandler.initiateAuthRequest(navigateUrl);
    // Monitor the window for the query. Return the string value and close the popup when the query is received. Default timeout is 3 minutes.
    const code = await popupHandler.monitorPopupForQuery(popupWindow, 180 * 1000);
    // Handle code response from code param string.
    return code;
};

const microsoftlogo =
    'https://www.eventful.se/wp-content/uploads/2019/04/PNGPIX-COM-Microsoft-Logo-Icon-PNG-Transparent-500x500.png';
export const SignInOutlookButton = styled(({ className, ...props }) => {
    const oauthUrl = 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize';
    const config = {
        auth: {
            clientId: props.clientId,
            redirectUri: props.redirectUri,
            responseType: props.responseType,
            authority: 'https://login.microsoftonline.com/common',
            scopes: props.scopes || ['user.read'],
            prompt: props.prompt || 'select_account',
        },
    };

    const clickHandler = async () => {
        const navigateUrl =
            `${oauthUrl}?client_id=${config.auth.clientId}&response_type=${config.auth.responseType}` +
            `&redirect_uri=${encodeURIComponent(config.auth.redirectUri)}` +
            `&scope=${config.auth.scopes.join(' ')}&response_mode=query&prompt=${config.auth.prompt}&state=ms`;
        // Acquire code with popup
        const code = await startAuthorizationFlow(navigateUrl);
        props.onSuccess({
            code,
        });
    };

    let cssClasses = `btn-microsoft-login ${className} ${props.cssClass}`;
    return (
        <button type='button' className={cssClasses} onClick={clickHandler}>
            <span style={{ padding: '10px 10px 10px 10px' }}>
                <img
                    src={microsoftlogo}
                    alt='Microsoft/Outlook/Exchange'
                    style={{ width: '22px', verticalAlign: 'middle' }}
                />
                <span style={{ paddingLeft: '10px', lineHeight: '30px', fontSize: '14px', fontWeight: 500 }}>
                    {props.buttonText}
                </span>
            </span>
        </button>
    );
})(() => ({
    background: '#fff',
    border: 'none',
    borderRadius: '2px',
    boxShadow: '0 1px 2px rgba(0,0,0,0.45)',
    color: 'rgba(0, 0, 0, 0.54)',
    display: 'inline-flex',
    padding: '0.05em 0.45em',
}));

/**
 * Constants
 */
export const BrowserConstants = {
    // Default popup window width
    POPUP_WIDTH: 483,
    // Default popup window height
    POPUP_HEIGHT: 600,
    // Default popup monitor poll interval in milliseconds
    POLL_INTERVAL_MS: 50,
};

/**
 * Includes functions for monitoring the popup window for the auth code.
 */
export class PopupHandler {
    currentWindow: Window;

    constructor() {
        // Properly sets this reference for the unload event.
        this.unloadWindow = this.unloadWindow.bind(this);
    }

    /**
     * Opens a popup window with given request Url.
     * @param requestUrl
     */
    initiateAuthRequest(requestUrl) {
        // Check that request url is not empty.
        if (typeof requestUrl === 'string' && requestUrl.length > 0) {
            // Save auth code request
            console.log('Navigate to:' + requestUrl);
            // Open the popup window to requestUrl.
            return this.openPopup(requestUrl, 'MSAL.JS', BrowserConstants.POPUP_WIDTH, BrowserConstants.POPUP_HEIGHT);
        } else {
            // Throw error if request URL is empty.
            console.error('Navigate url is empty');
        }
    }

    /**
     * Monitors a window until it loads a url with the auth code param, or hits a specified timeout.
     * @param popupWindow - window that is being monitored
     * @param timeout - milliseconds until timeout
     */
    monitorPopupForQuery(popupWindow, timeout) {
        return new Promise((resolve, reject) => {
            const maxTicks = timeout / BrowserConstants.POLL_INTERVAL_MS;
            let ticks = 0;

            const intervalId = setInterval(() => {
                if (popupWindow.closed) {
                    // Window is closed
                    this.cleanPopup();
                    clearInterval(intervalId);
                    reject('Cancelled by user');
                    return;
                }

                // Run clock
                ticks++;

                let href;
                try {
                    /*
                     * Will throw if cross origin,
                     * which should be caught and ignored
                     * since we need the interval to keep running
                     */
                    href = popupWindow.location.href;
                } catch (e) {
                    //Do nothing
                }

                // Don't process blank pages or cross domain
                if (!href || href === 'about:blank') {
                    return;
                }

                const urlParams = new URLSearchParams(popupWindow.location.search);
                const code = urlParams.get('code');
                if (code) {
                    // Success case
                    this.cleanPopup(popupWindow);
                    clearInterval(intervalId);
                    resolve(code);
                    return;
                } else if (ticks > maxTicks) {
                    // Timeout error
                    this.cleanPopup(popupWindow);
                    clearInterval(intervalId);
                    reject('Window timeout error');
                    return;
                }
            }, BrowserConstants.POLL_INTERVAL_MS);
        });
    }

    /**
     * @hidden
     *
     * Configures popup window for login.
     *
     * @param urlNavigate
     * @param title
     * @param popUpWidth
     * @param popUpHeight
     * @ignore
     * @hidden
     */
    openPopup(urlNavigate, title, popUpWidth, popUpHeight) {
        try {
            /**
             * adding winLeft and winTop to account for dual monitor
             * using screenLeft and screenTop for IE8 and earlier
             */
            const winLeft = window.screenLeft ? window.screenLeft : window.screenX;
            const winTop = window.screenTop ? window.screenTop : window.screenY;
            /**
             * window.innerWidth displays browser window"s height and width excluding toolbars
             * using document.documentElement.clientWidth for IE8 and earlier
             */
            const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
            const left = width / 2 - popUpWidth / 2 + winLeft;
            const top = height / 2 - popUpHeight / 2 + winTop;

            // open the window
            const popupWindow = window.open(
                urlNavigate,
                title,
                'width=' + popUpWidth + ', height=' + popUpHeight + ', top=' + top + ', left=' + left,
            );
            if (popupWindow.focus) {
                popupWindow.focus();
            }
            this.currentWindow = popupWindow;
            window.addEventListener('beforeunload', this.unloadWindow);

            return popupWindow;
        } catch (e) {
            console.error('error on popup');
        }
    }

    /**
     * Event callback to unload main window.
     */
    unloadWindow(e) {
        console.log('Unloading window');
        this.currentWindow.close();
        // Guarantees browser unload will happen, so no other errors will be thrown.
        delete e['returnValue'];
    }

    /**
     * Closes popup, removes any state vars created during popup calls.
     * @param popupWindow
     */
    cleanPopup(popupWindow?: Window) {
        console.log('Cleaning window');
        if (popupWindow) {
            // Close window.
            popupWindow.close();
        }
        // Remove window unload function
        window.removeEventListener('beforeunload', this.unloadWindow);
    }
}
