import { Box, Collapse, FormControlLabel, FormGroup, Switch } from '@mui/material';
import { useDispatch } from 'react-redux-latest';
import { SideTextField } from '../../../../../components/atoms/sideTextField';
import { CustomFormControl } from '../../../../../components/molecules/twinSelect/elements';
import { updateHubspotShowProspectStatusMapping } from '../../../../../redux/ducks/hubspot/slice';
import { AppDispatch, useAppSelector } from '../../../../../redux/store/configureStore';
import { LinkedInFormContainer } from './containers';
import { companyNameCorrectlyCased } from '../../../../../enums/company-name';

export const ZaplifyStatusField = () => {
    const dispatch = useDispatch<AppDispatch>();

    const display = useAppSelector((state) => state.hubspot?.mapZaplifyProspectStatus);

    const handleSwitch = (event) => {
        dispatch(updateHubspotShowProspectStatusMapping(event.target.checked));
    };

    return (
        <>
            <LinkedInFormContainer className={display ? 'linkedin-open' : 'linkedin-closed'}>
                <FormControlLabel
                    control={<Switch color="primary" name="checkedA" checked={display} onChange={handleSwitch} />}
                    label={`Create new Hubspot field for ${companyNameCorrectlyCased} prospect status`}
                />
            </LinkedInFormContainer>
            <Collapse in={display}>
                <div>
                    <Box display="flex" className={'box-row'}>
                        <FormGroup row>
                            <CustomFormControl variant="outlined" required>
                                <SideTextField
                                    id="outlined-read-only-input"
                                    defaultValue="Prospect Status"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    variant="outlined"
                                    side="left"
                                />
                            </CustomFormControl>
                            <CustomFormControl variant="outlined" required>
                                <SideTextField
                                    id="outlined-read-only-input"
                                    defaultValue="Prospect Status"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    variant="outlined"
                                />
                            </CustomFormControl>
                        </FormGroup>
                    </Box>
                </div>
            </Collapse>
        </>
    );
};
