import { ICommand, Topics } from '@zaplify/common';
import { UserContactDto } from '../dtos';

export class AssignUserContactToGroupCommand extends ICommand<UserContactDto | null> {
    public static override readonly TOPIC = Topics.PROSPECTS;
    public readonly userId: string;
    public readonly userContactId: string;
    public readonly groupId: string | 'none';

    constructor(params: { userId: string; userContactId: string; groupId: string }) {
        super();
        this.userId = params.userId;
        this.userContactId = params.userContactId;
        this.groupId = params.groupId;
    }
}
