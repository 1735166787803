import { IQuery, Topics } from '@zaplify/common';
import { PreviewProspectingDtos, ProspectSearchFiltersDto } from '../sources';

export class GetProspectingPreviewListViewPersonsQuery extends IQuery<PreviewProspectingDtos.ResponsePerPerson> {
    public static override readonly TOPIC = Topics.PROSPECTS;
    constructor(
        readonly userId: string,
        readonly userOrganizationId: string,
        readonly prospectSearchFilters: ProspectSearchFiltersDto,
    ) {
        super();
    }
}
