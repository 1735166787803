export enum BillingPeriodUnit {
    MONTH = 'month',
    YEAR = 'year',
    QUARTER = 'quarter',
    HALF_YEAR = 'half-year',
}

export const BillingPeriodFrequency = {
    month: 'monthly',
    year: 'annually',
    quarter: 'quarterly',
    half_year: 'semi-annually',
};

export enum SubscriptionStatus {
    ACTIVE = 'active',
    CANCELLED = 'cancelled',
    NON_RENEWING = 'non_renewing',
}
