import { FC, ReactNode } from 'react';
import { Badge } from '@shadcn/ui/components/ui/badge';
import { Loader2 } from 'lucide-react';

interface PageHeaderProps {
    title: string;
    description?: string;
    count?: number;
    isLoadingCount?: boolean;
    rightElement?: ReactNode;
}

export const PageHeader: FC<PageHeaderProps> = ({
    title,
    description,
    count,
    isLoadingCount = false,
    rightElement,
}) => {
    const showCountBadge = typeof count === 'number' && !isLoadingCount;
    const showLoading = isLoadingCount && typeof count === 'number';

    return (
        <header className="flex items-center justify-center md:justify-between pb-4 mb-4">
            <div className="flex flex-col gap-2 w-full">
                <div className="flex gap-2 items-center justify-start">
                    <h1 className="font-medium text-2xl">{title}</h1>
                    <div className="flex items-center">
                        {showLoading && <Loader2 className="h-4 w-4 animate-spin" />}
                        {showCountBadge && (
                            <Badge
                                variant="gray"
                                className="bg-background-tertiary rounded-full px-2 py-0.5 border-none text-text-primary"
                            >
                                {count}
                            </Badge>
                        )}
                    </div>
                </div>
                {description && <span className="text-text-tertiary text-sm">{description}</span>}
            </div>
            {rightElement && <div className="hidden md:flex">{rightElement}</div>}
        </header>
    );
};
