import { AnyAction, createAsyncThunk } from '@reduxjs/toolkit';
import { IntegrationType } from '@zaplify/enums';
import { PreviewProspectDetailsDto, ProspectsByCompanyDto } from '@zaplify/prospects';
import { ProfilesSdk, getZaplifySdk } from '@zaplify/sdk';
import { batch } from 'react-redux-latest';
import { NavigateFunction } from 'react-router-dom';
import { DEFAULT_COMPANIES_PER_PAGE } from '../../../config';
import { TransactionState } from '../../../enums/prospects';
import {
    fetchCampaignProspectDetails,
    handleErrorNotification,
    handleMoveProspects,
    handleSuccessNotification,
} from '../../actions';
import { ProspectSearchFilterState } from '../prospect-search-filters/types';
import { getFormattedProspectSearch, getSelectedProspectsArray } from './helpers';
import {
    addHubspotRequestedProspects,
    addPipedriveRequestedProspects,
    addSalesforceRequestedProspects,
    addUpsalesRequestedProspects,
    addWebhookRequestedProspects,
    resetHasMoreProspects,
    updateAllSelected,
    updateBulkProspectTransactionState,
    updateDetailedProspects,
    updateErrors,
    updateHasMoreProspects,
    updateLoadingHitsState,
    updateProspectHits,
    updateProspectsCount,
    updatePurchasingProspectsState,
    updatePurchasingToOtherCampaign,
    updateSelected,
} from './slice';
import { ProspectHits, ProspectSearchResultStateType, ProspectsTransactionState } from './types';
import { SearchScopes } from '@zaplify/constants';
import { setLocalStorage } from '../../../services/utils/localStorage';
import { clearLocalStorageByKey } from '../../../services/utils/localStorage';
import { IProspectToSync } from '@zaplify/campaigns';
import { ContactSource } from '@zaplify/services/user-contacts/shared';

type Props = {
    prospectSearchFilters: ProspectSearchFilterState;
    pagination?: boolean;
    navigate: NavigateFunction;
    companiesPerPage?: number;
};

export const previewProspectsAction = createAsyncThunk(
    'preview/prospects',
    // setting thunkAPI to type "AsyncThunkConfig" is causing multiple typing errors
    async (
        { prospectSearchFilters, pagination, navigate, companiesPerPage = DEFAULT_COMPANIES_PER_PAGE }: Props,
        thunkAPI: any
    ): Promise<AnyAction> => {
        const {
            getState,
            extra: { zaplifySdk },
            dispatch,
        } = thunkAPI;
        const profilesSdk = zaplifySdk.profiles;
        const feedView = getState().prospectSearchFilters.searchScope === SearchScopes.feed;
        const campaignId = getState().newCampaign.campaignId;

        batch(() => {
            dispatch(updateErrors({ type: 'previewProspectsAction', value: false }));
            dispatch(resetHasMoreProspects());
            if (!pagination) {
                dispatch(updateProspectsCount(0));
                dispatch(updateLoadingHitsState(true));
                dispatch(updateDetailedProspects({}));
            }
        });

        try {
            const prospectSearchResultState = getState().prospectSearchResult;
            const page = prospectSearchResultState.page;

            if (!pagination) dispatch(updateProspectHits({} as ProspectHits));

            const fromPage = (page - 1) * companiesPerPage;
            const prospectSearchFilter = getFormattedProspectSearch({
                filters: prospectSearchFilters,
                fromCompanyIndex: fromPage,
                amountOfCompanies: companiesPerPage,
            });
            const prospectsResponse = await profilesSdk.sources.previewProspectingListNew(prospectSearchFilter, false);
            const hasMoreProspects = {};

            //Calculating total number of prospects in result
            const numProspectsInResult = prospectsResponse?.prospectsByCompany?.reduce((acc, curr) => {
                hasMoreProspects[curr.organizationName] = curr.hasMoreProspects;
                return (acc += curr.prospects.length);
            }, 0);

            const counts = prospectsResponse.counts;

            const hits = {
                total: counts.totalPersons.value,
                available: counts.availablePersons.value,
                blocklisted: counts.blocklistedPersons.value,
                assigned: counts.assignedPersons.value,
                availableCompanies: counts.availableCompanies.value,
            };

            batch(() => {
                if (!pagination) dispatch(updateSelected({}));
                dispatch(updateProspectsCount(numProspectsInResult || 0));
                dispatch(updateProspectHits(hits));
                dispatch(updateHasMoreProspects(hasMoreProspects));
            });

            //Adding params to url for intercom
            const params = new URLSearchParams(window.location.search);
            params.set('prospects-loaded', 'true');
            params.set('hits-loaded', 'true');
            navigate(`${window.location.pathname}?${params.toString()}`);
            window.analytics && window?.analytics?.track('User Searched For Prospects');

            const userId = getState().user.zaplifyUser.id;
            if (!userId) return prospectsResponse.prospectsByCompany;
            if (prospectsResponse.prospectsByCompany.length > 0) {
                clearLocalStorageByKey(`prospectSearchFilters-userId:${userId}`);
                setLocalStorage(`prospectSearchFilters-userId:${userId}`, JSON.stringify(prospectSearchFilters));
            }

            return prospectsResponse.prospectsByCompany;
        } catch (error) {
            console.log('previewProspectsAction error', error);
            dispatch(handleErrorNotification("Couldn't load prospects.", 2));
            dispatch(updateErrors({ type: 'previewProspectsAction', value: true }));
        } finally {
            dispatch(updateLoadingHitsState(false));
        }
    }
);

type getFeedProps = {
    includeCampaign?: boolean;
};

export const getFeed = createAsyncThunk(
    'loading-feed',
    // setting thunkAPI to type "AsyncThunkConfig" is causing multiple typing errors
    async ({ includeCampaign = true }: getFeedProps, thunkAPI: any): Promise<AnyAction> => {
        const {
            getState,
            extra: { zaplifySdk },
            dispatch,
        } = thunkAPI;
        const profilesSdk = zaplifySdk.profiles;

        try {
            const prospectSearchFilters = getState().prospectSearchFilters;
            const feedView = getState().prospectSearchFilters.searchScope === SearchScopes.feed;
            const campaignId = includeCampaign ? getState().newCampaign.campaignId : undefined;

            const fromPage = 0;
            const prospectSearchFilter = getFormattedProspectSearch({
                filters: prospectSearchFilters,
                fromCompanyIndex: fromPage,
            });

            const prospectsResponse = await profilesSdk.sources.previewProspectingListNew(prospectSearchFilter);
            const hasMoreProspects = {};

            //Calculating total number of prospects in result
            const numProspectsInResult = prospectsResponse?.prospectsByCompany?.reduce((acc, curr) => {
                hasMoreProspects[curr.organizationName] = curr.hasMoreProspects;
                return (acc += curr.prospects.length);
            }, 0);

            const counts = prospectsResponse.counts;

            const hits = {
                total: counts.totalPersons.value,
                available: counts.availablePersons.value,
                blocklisted: counts.blocklistedPersons.value,
                assigned: counts.assignedPersons.value,
                availableCompanies: counts.availableCompanies.value,
            };

            batch(() => {
                dispatch(updateProspectsCount(numProspectsInResult || 0));
                dispatch(updateProspectHits(hits));
                dispatch(updateHasMoreProspects(hasMoreProspects));
            });

            window.analytics && window?.analytics?.track('User Loaded More Prospects In Feed');

            return prospectsResponse.prospectsByCompany;
        } catch (error) {
            console.log('Load More Prospects In Feed Error', error);
        } finally {
        }
    }
);

export const getBulkCompaniesPayload = (
    syncingProspectIds: string[],
    prospectSearchState: ProspectSearchResultStateType
): { [key: string]: string[] } => {
    const companiesWithProspects = {};
    syncingProspectIds.forEach((personId) => {
        const organizationName = prospectSearchState.prospectsInCompanies.find((company) =>
            company.prospects.find((prospect) => prospect.personId === personId)
        )?.organizationName;
        if (organizationName) {
            if (!companiesWithProspects[organizationName]) {
                companiesWithProspects[organizationName] = [];
            }
            companiesWithProspects[organizationName].push(personId);
        }
    });

    return companiesWithProspects;
};

export const getRequestedProspectsConfiguration = (
    prospectSearchState: ProspectSearchResultStateType,
    inset: boolean,
    integrations: string[],
    fullPageSync: boolean
): {
    requestedProspects: string[];
    dispatchFn: ((prospectId: string[]) => {})[];
} => {
    const configuration = {
        HUBSPOT: {
            requestedProspects: prospectSearchState.hubspotRequestedProspects,
            dispatchFn: addHubspotRequestedProspects,
        },
        PIPEDRIVE: {
            requestedProspects: prospectSearchState.pipedriveRequestedProspects,
            dispatchFn: addPipedriveRequestedProspects,
        },
        SALESFORCE: {
            requestedProspects: prospectSearchState.salesforceRequestedProspects,
            dispatchFn: addSalesforceRequestedProspects,
        },
        UPSALES: {
            requestedProspects: prospectSearchState.upsalesRequestedProspects,
            dispatchFn: addUpsalesRequestedProspects,
        },
        WEBHOOK: {
            requestedProspects: prospectSearchState.webhookRequestedProspects,
            dispatchFn: addWebhookRequestedProspects,
        },
    };
    const requestedProspects: string[] = [];
    const dispatchFn: ((prospectId: string[]) => {})[] = [];
    if (inset) {
        for (const [key, value] of Object.entries(configuration)) {
            if (integrations.includes(key)) {
                requestedProspects.push(...Object.keys(value.requestedProspects));
                dispatchFn.push(value.dispatchFn);
            }
        }
    } else {
        if (!integrations.length || !fullPageSync) {
            for (const [key, value] of Object.entries(configuration)) {
                requestedProspects.push(...Object.keys(value.requestedProspects));
                dispatchFn.push(value.dispatchFn);
            }
        } else {
            for (const [key, value] of Object.entries(configuration)) {
                if (!integrations.includes(key)) {
                    requestedProspects.push(...Object.keys(value.requestedProspects));
                    dispatchFn.push(value.dispatchFn);
                }
            }
        }
    }

    return {
        requestedProspects,
        dispatchFn,
    };
};
export const bulkSyncProspects =
    (isFullPageSyncActivated = false) =>
    async (dispatch, getState) => {
        const profilesSdk = getZaplifySdk().profiles;
        const prospectSearchState: ProspectSearchResultStateType = getState().prospectSearchResult;
        const results = getRequestedProspectsConfiguration(
            prospectSearchState,
            true,
            ['WEBHOOK'],
            isFullPageSyncActivated
        );

        try {
            const syncedProspectIds = results.requestedProspects;

            const syncingProspectIds = Object.keys(prospectSearchState.syncingProspects).filter(
                (prospect) => !syncedProspectIds.includes(prospect)
            );
            const companiesWithProspects = getBulkCompaniesPayload(syncingProspectIds, prospectSearchState);
            if (syncingProspectIds?.length > 0) {
                if (!isFullPageSyncActivated) {
                    profilesSdk.apps.bulkSyncCompanies(companiesWithProspects);
                    profilesSdk.hubspot.bulkSyncCompanies(companiesWithProspects);
                }
                profilesSdk.apps.bulkSyncPersons(syncingProspectIds);
                results.dispatchFn.forEach((dispatchFn) => {
                    dispatch(dispatchFn(syncingProspectIds));
                });
            }
        } catch (error) {
            dispatch(handleErrorNotification('Failed to load webhooks data from server', 2));
            console.log('Failed to load webhooks data from server');
        }
    };

export const fullBulkSyncProspectsPerIntegration = (integrationTypes: Array<string>) => async (dispatch, getState) => {
    const profilesSdk = getZaplifySdk().profiles;
    const prospectSearchState: ProspectSearchResultStateType = getState().prospectSearchResult;
    const results = getRequestedProspectsConfiguration(prospectSearchState, true, integrationTypes, true);

    const allPersons: string[] = [];
    getState().prospectSearchResult.prospectsInCompanies.forEach((prospectsInCampaign) => {
        const prospects = prospectsInCampaign.prospects;
        prospects.forEach((prospect) => {
            if (prospect.personId) {
                allPersons.push(prospect.personId);
            }
        });
    });

    try {
        const syncedProspectIds = [...results.requestedProspects];

        const syncingProspectIds = allPersons.filter((prospect) => !syncedProspectIds.includes(prospect));
        const companiesWithProspects = getBulkCompaniesPayload(syncingProspectIds, prospectSearchState);

        if (syncingProspectIds?.length > 0) {
            if (integrationTypes.includes(IntegrationType.HUBSPOT)) {
                profilesSdk.hubspot.bulkSyncCompanies(companiesWithProspects);
            } else {
                profilesSdk.apps.bulkSyncCompanies(companiesWithProspects);
            }

            results.dispatchFn.forEach((dispatchFn) => {
                dispatch(dispatchFn(syncingProspectIds));
            });
        }
    } catch (error) {
        dispatch(handleErrorNotification('Failed to load webhooks data from server', 2));
        console.log('Failed to load webhooks data from server');
    }
};

type PurchaseProspectsProps = {
    ids?: readonly string[];
    toOtherCampaignId?: string;
    toOtherGroupId?: string;
    syncMany: (jobs: IProspectToSync[]) => Promise<void>;
    resyncHits?: boolean;
    availableCredits: number;
    source?: ContactSource;
};

export const purchaseProspects =
    ({
        ids,
        toOtherCampaignId,
        toOtherGroupId,
        syncMany,
        availableCredits,
        source,
        resyncHits = true,
    }: PurchaseProspectsProps) =>
    async (dispatch, getState, { zaplifySdk }) => {
        // const profilesSdk: ProfilesSdk = zaplifySdk.profiles;
        // try {
        //     dispatch(updatePurchasingProspectsState(true));
        //     toOtherCampaignId && dispatch(updatePurchasingToOtherCampaign(true));
        //     const selected = getState().prospectSearchResult.selected;
        //     const hits = getState().prospectSearchResult.hits;
        //     const purchased = getState().prospectSearchResult.prospectTransactionState;
        //     const campaignId = toOtherCampaignId || getState().newCampaign.campaignId;
        //     const groupId = toOtherGroupId;
        //     const totalAvailableCredits = availableCredits < 0 ? 0 : availableCredits;
        //     const numSelected = Object.keys(selected)?.filter((id) => selected[id])?.length;
        //     let selectedPersonIds: string[] =
        //         (ids?.length && [...ids]) || getSelectedProspectsArray(selected, purchased);
        //     const prospectTransactionState: ProspectsTransactionState = {};
        //     if (numSelected > totalAvailableCredits) {
        //         selectedPersonIds = selectedPersonIds.slice(0, totalAvailableCredits - numSelected);
        //     }
        //     Object.keys(selectedPersonIds).forEach((id) => {
        //         prospectTransactionState[selectedPersonIds[id]] = TransactionState.BOUGHT;
        //     });
        //     const prospectsIdsToMarkAsBought =
        //         prospectTransactionState && Object.keys(prospectTransactionState).length > 0
        //             ? prospectTransactionState
        //             : { [selectedPersonIds[0]]: TransactionState.BOUGHT };
        //     dispatch(updateBulkProspectTransactionState(prospectsIdsToMarkAsBought));
        //     const prospectIds = await profilesSdk.sources.purchasePersons(
        //         { personIds: selectedPersonIds, linkedinUrls: [] },
        //         source,
        //         groupId
        //     );
        //     syncMany &&
        //         syncMany(
        //             prospectIds.map((prospectId) => {
        //                 return { prospectId: prospectId };
        //             })
        //         );
        //     batch(() => {
        //         {
        //             resyncHits && dispatch(getProspectHits());
        //         }
        //         !toOtherCampaignId &&
        //             dispatch(handleSuccessNotification(`Successfully added ${selectedPersonIds.length} contacts`, 2));
        //         toOtherCampaignId &&
        //             dispatch(handleSuccessNotification(`${selectedPersonIds.length} Contacts added`, 2));
        //         dispatch(fetchCampaignProspectDetails(getState().newCampaign.campaignId));
        //     });
        //     const prospectDetails = await profilesSdk.prospects.getProspectByIdsBatch(prospectIds);
        //     const formattedProspects = prospectDetails.reduce(
        //         (acc, curr) => ({ ...acc, [curr?.data?.personId]: curr.data }),
        //         {}
        //     );
        //     dispatch(updateDetailedProspects(formattedProspects));
        //     if (numSelected === hits) {
        //         dispatch(updateAllSelected(true));
        //     }
        //     if (numSelected > 0) {
        //         const tempSelected = { ...selected };
        //         selectedPersonIds?.forEach((id) => delete tempSelected[id]);
        //         dispatch(updateSelected(tempSelected));
        //     }
        //     dispatch(handleMoveProspects(false));
        //     syncMany(
        //         prospectDetails.map((prospect) => ({
        //             prospectId: prospect.id,
        //             linkedinProfileUrl: prospect.data.linkedinProfileUrl,
        //         }))
        //     );
        //     window.analytics &&
        //         window?.analytics?.track('User Added Prospects', {
        //             numberOfProspects: selectedPersonIds.length,
        //         });
        //     return;
        // } catch (error) {
        //     console.log('Failed to unlock', error);
        //     dispatch(handleErrorNotification('Failed to add contacts', 2));
        //     throw error;
        // } finally {
        //     dispatch(updatePurchasingProspectsState(false));
        //     toOtherCampaignId && dispatch(updatePurchasingToOtherCampaign(false));
        // }
    };

export const getProspectHits =
    () =>
    async (dispatch, getState, { zaplifySdk }) => {
        // const profilesSdk: ProfilesSdk = zaplifySdk.profiles;
        // const prospectSearchFilters = getState().prospectSearchFilters;
        // try {
        //     dispatch(updateLoadingHitsState(true));
        //     const prospectSearchFilter = getFormattedProspectSearch({ filters: prospectSearchFilters });
        //     const prospectsResponse = await profilesSdk.sources.previewProspectingListNew(prospectSearchFilter);
        //     const counts = prospectsResponse.counts;
        //     const hits = {
        //         total: counts.totalPersons.value,
        //         available: counts.availablePersons.value,
        //         blocklisted: counts.blocklistedPersons.value,
        //         assigned: counts.assignedPersons.value,
        //         availableCompanies: counts.availableCompanies.value,
        //     };
        //     dispatch(updateProspectHits(hits));
        // } catch (error) {
        //     console.log('getProspecthits error');
        //     dispatch(handleErrorNotification('Failed to prospects count', 2));
        // } finally {
        //     dispatch(updateLoadingHitsState(false));
        // }
    };

export const selectExtendedProspects = createAsyncThunk(
    'select-extended-prospects',
    async ({ totalNumberOfProspectsToGet }: { totalNumberOfProspectsToGet: number }, thunkAPI: any) => {
        // const {
        //     dispatch,
        //     getState,
        //     extra: { zaplifySdk },
        // } = thunkAPI;
        // const profilesSdk: ProfilesSdk = zaplifySdk.profiles;
        // const prospectSearchState = getState().prospectSearchFilters;
        // const prospectSearchResultState = getState().prospectSearchResult;
        // const purchasedProspects = prospectSearchResultState.prospectTransactionState;
        // const currentPage = prospectSearchResultState.page;
        // try {
        //     const numberOfProspectsOnCurrentPage = (prospectSearchResultState.prospectsInCompanies || []).reduce(
        //         (acc, curr) => (acc += curr.prospects.length),
        //         0
        //     );
        //     let availableProspectsOnPageCount = 0;
        //     const prospectsToSelectOnCurrentPage: { [key: string]: boolean } =
        //         prospectSearchResultState.prospectsInCompanies.reduce(
        //             (acc, curr) => ({
        //                 ...acc,
        //                 ...curr.prospects.reduce((acc, curr: any) => {
        //                     if (purchasedProspects[curr.personId]) return acc;
        //                     //Just adding so many prospects that the user wants to
        //                     availableProspectsOnPageCount++;
        //                     if (availableProspectsOnPageCount > totalNumberOfProspectsToGet) return acc;
        //                     return { ...acc, [curr.personId]: true };
        //                 }, {}),
        //             }),
        //             {}
        //         );
        //     const extraProspectsLeft =
        //         totalNumberOfProspectsToGet - availableProspectsOnPageCount <= 0
        //             ? 0
        //             : totalNumberOfProspectsToGet - availableProspectsOnPageCount;
        //     const extraProspectsToSelect: { [key: string]: boolean } = Array.from(Array(extraProspectsLeft)).reduce(
        //         (acc, curr, idx) => ((acc[idx] = true), acc),
        //         {}
        //     );
        //     dispatch(updateSelected({ ...prospectsToSelectOnCurrentPage, ...extraProspectsToSelect }));
        //     if (totalNumberOfProspectsToGet <= availableProspectsOnPageCount) {
        //         return prospectsToSelectOnCurrentPage;
        //     }
        //     const numberOfProspectsToGetFromNextPages = totalNumberOfProspectsToGet - availableProspectsOnPageCount;
        //     const amountOfPagesToGet = Math.ceil(numberOfProspectsToGetFromNextPages / DEFAULT_COMPANIES_PER_PAGE);
        //     const prospectSearchFilter = getFormattedProspectSearch({
        //         filters: prospectSearchState,
        //         fromCompanyIndex: currentPage * DEFAULT_COMPANIES_PER_PAGE,
        //         amountOfCompanies: amountOfPagesToGet * DEFAULT_COMPANIES_PER_PAGE,
        //     });
        //     const prospectsResponse = await profilesSdk.sources.previewProspectingListNew(prospectSearchFilter);
        //     let prospectsIdsToAddCount = numberOfProspectsToGetFromNextPages;
        //     const prospectsIdsToAdd = prospectsResponse.persons.reduce(
        //         (acc, curr, idx) => ({
        //             ...acc,
        //             ...curr.prospects.reduce((acc, curr: any) => {
        //                 if (purchasedProspects[curr.personId]) {
        //                     return acc;
        //                 }
        //                 --prospectsIdsToAddCount;
        //                 if (prospectsIdsToAddCount < 0) {
        //                     return acc;
        //                 }
        //                 return { ...acc, [curr.personId]: true };
        //             }, {}),
        //         }),
        //         {}
        //     );
        //     const allIdsToSelect = { ...prospectsToSelectOnCurrentPage, ...prospectsIdsToAdd };
        //     //Checking if prospects are still selected, otherwise user might have clicked clear all
        //     if (Object.keys(getState().prospectSearchResult.selected).length > 0) {
        //         dispatch(updateSelected(allIdsToSelect));
        //         return allIdsToSelect;
        //     }
        //     return {};
        // } catch (error) {
        //     dispatch(handleErrorNotification("Couldn't select prospects", 2));
        //     dispatch(updateSelected({}));
        // }
    }
);

export const extendProspectsInCompany = createAsyncThunk(
    'extend-prospects-in-company',
    async ({ company, companyIndex }: { company: ProspectsByCompanyDto; companyIndex: number }, thunkAPI: any) => {
        // const {
        //     getState,
        //     extra: { dispatch, zaplifySdk },
        // } = thunkAPI;
        // const prospectSearchFilters = getState().prospectSearchFilters;
        // const oneProspectPerCompany = prospectSearchFilters.oneProspectPerCompany;
        // const prospectSearchFilter = getFormattedProspectSearch({
        //     filters: prospectSearchFilters,
        // });
        // try {
        //     const extendedProspects: PreviewProspectDetailsDto[] =
        //         await zaplifySdk.profiles.sources.expandMoreProspectPerCompany({
        //             companyName: company.organizationName.toLowerCase(),
        //             from: company.prospects.length,
        //             size: 20,
        //             filters: prospectSearchFilter,
        //         });
        //     return {
        //         extendedProspects,
        //         companyIndex,
        //         companyName: company.organizationName,
        //         oneProspectPerCompany,
        //     };
        // } catch (err) {}
    }
);
