import { cn } from '@shadcn/ui/lib/utils';
import { motion } from 'motion/react';

interface CircleCheckAnimationProps {
    className?: string;
}

export const CircleCheckAnimation = ({ className }: CircleCheckAnimationProps) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className={cn(className)}
        >
            <motion.circle
                cx="12"
                cy="12"
                r="10"
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.3, ease: 'easeOut' }}
            />
            <motion.path
                d="m9 12 2 2 4-4"
                initial={{ pathLength: 0 }}
                animate={{ pathLength: 1 }}
                transition={{ duration: 0.5, ease: 'easeInOut', delay: 0.2 }}
            />
        </svg>
    );
};
