export enum WebExtensionAction {
    A__AUTH_LOGIN = 'A__AUTH_LOGIN',
    A__AUTH_LOGOUT = 'A__AUTH_LOGOUT',
    A__AUTH_CHOOSE_ORGANIZATION = 'A__AUTH_CHOOSE_ORGANIZATION',
    A__AUTH_GET_CURRENT_STATE = 'A__AUTH_GET_CURRENT_STATE',
    GET_MESSAGES_FROM_CONVERSATION = 'MESSAGE_GET_MESSAGES_FROM_CONVERSATION',
    GET_MESSAGES_FROM_CONVERSATION_NEW = 'MESSAGE_GET_MESSAGES_FROM_CONVERSATION_NEW',
    GLOBAL_RELOAD_EXTENSION = 'GLOBAL_RELOAD_EXTENSION',
    ME_GET = 'ME_GET',
    CONVERSATION_GET_ID = 'CONVERSATION_GET_ID',
    GET_COOKIES = 'GET_COOKIES',
    GET_EXTENSION_VERSION = 'GET_EXTENSION_VERSION',
    GET_LINKEDIN_USER = 'GET_LINKEDIN_USER',
    REGULAR_PROFILE_GET = 'REGULAR_PROFILE_GET',
    REGULAR_PROFILE_GET_FULL = 'REGULAR_PROFILE_GET_FULL',
    REGULAR_PROFILE_GET_EXPERIENCES = 'REGULAR_PROFILE_GET_EXPERIENCES',
    REGULAR_PROFILE_GET_POSTS = 'REGULAR_PROFILE_GET_POSTS',
    COMPANY_GET = 'COMPANY_GET',
    CONVERSATION_CREATE = 'CONVERSATION_CREATE',
    INVITATION_SEND = 'INVITATION_SEND',
    ME_GET_PENDING_INVITATIONS = 'ME_GET_PENDING_INVITATIONS',
    ME_GET_CONNECTIONS = 'ME_GET_CONNECTIONS',
    ME_FIND_CONNECTIONS = 'ME_FIND_CONNECTIONS',
    CONVERSATION_GET = 'CONVERSATION_GET',
    CONVERSATION_GET_NEW = 'CONVERSATION_GET_NEW',
    GLOBAL_REFRESH_LINKEDIN_TAB = 'GLOBAL_REFRESH_LINKEDIN_TAB',
    DIRECT_REQUEST_GQL_ENDPOINT = 'DIRECT_REQUEST_GQL_ENDPOINT',
}

export enum WebExtensionToAndsendEventType {
    LINKEDIN_DECORATED_EVENT_RECEIVED = 'LINKEDIN_DECORATED_EVENT_RECEIVED',
    WEB_EXTENSION_LOG = 'WEB_EXTENSION_LOG',
}
