import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
    GET_CONVERSATIONS_W_LAST_MSG_SUBSCRIPTION,
    OnMessageAddedByProspectIdSubscription,
    USER_CONTACTS_BY_IDS_SUBSCRIPTION,
    OnUserContactsByIdsSubscription,
    USER_CONTACTS_SUBSCRIPTION,
    GetUserContactsSubscriptionSubscription,
    GET_LINKEDIN_CONVERSATIONS_SUBSCRIPTION,
} from '@zaplify/graphql';
import { useSubscription } from '@apollo/client';
import { cn } from '@shadcn/ui/lib/utils';
import { AnimatePresence } from 'motion/react';
import { ConversationCardSkeleton } from '../../../components/conversation-card-skeleton';
import { ConversationCard } from './conversation-card';
import { Input } from '@shadcn/ui/components/ui/input';
import { Search } from 'lucide-react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useIsMobile } from '@shadcn/ui/hooks/use-mobile';
import { ConversationLinkedinSearch } from './conversation-linkedin-search';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSdk } from '../../../sdk';
import { useQuery as useTanQuery } from '@tanstack/react-query';
import { ProspectDataWithId } from '@zaplify/prospects';
import { PlaybookFilterBadge } from '../../../components/playbook-filter-badge';
interface ConversationsListProps {}

const PAGE_SIZE = 50;

export const ConversationsList: React.FC<ConversationsListProps> = () => {
    const [displayLimit, setDisplayLimit] = useState(PAGE_SIZE);
    const listContainerRef = useRef<HTMLDivElement>(null);
    const { showAllLinkedinConversations } = useFlags();
    const [query, setQuery] = useState('');
    const navigate = useNavigate();
    const { prospectId } = useParams();
    const [prospectIdToProspect, setProspectIdToProspect] = useState<
        Record<
            string,
            ProspectDataWithId<
                'fullName' | 'linkedinProfileImgUrl' | 'linkedinUserId' | 'occupationTitle' | 'organizationName'
            >
        >
    >({});
    const [searchParams, setSearchParams] = useSearchParams();
    const searchParamGroupId = searchParams.get('groupId');
    const isMobile = useIsMobile();

    const { data: conversationsData, loading: loadingConversations } = useSubscription(
        GET_CONVERSATIONS_W_LAST_MSG_SUBSCRIPTION
    );

    const { data: linkedinConversationsData } = useSubscription(GET_LINKEDIN_CONVERSATIONS_SUBSCRIPTION);
    const { data: userContactsData } = useSubscription(USER_CONTACTS_SUBSCRIPTION);

    const linkedinConversations = useMemo(() => {
        return linkedinConversationsData?.LinkedinConversations ?? [];
    }, [linkedinConversationsData]);

    const prospectIdToUserContact = useMemo(() => {
        const userContactsMap: Record<string, GetUserContactsSubscriptionSubscription['UserContacts'][0]> = {};
        userContactsData?.UserContacts.filter((contact) =>
            searchParamGroupId ? contact._GroupToUserContacts?.[0]?.Group?.id === searchParamGroupId : true
        ).forEach((contact) => (userContactsMap[contact.prospectId] = contact));
        return userContactsMap;
    }, [userContactsData, searchParamGroupId]);

    const conversations = useMemo(() => {
        if (!conversationsData) return [];

        const allConversations = [
            ...conversationsData?.Messages,
            ...((showAllLinkedinConversations && linkedinConversations) || [])?.map((conversation) => ({
                ...conversation,
                sentOn: conversation.lastMessageSentAt,
                direction: conversation.lastMessageSentDirection,
                actionType: conversation.lastMessageType,
                content: conversation.lastMessageText,
                subjectLine: undefined,
            })),
        ];

        // Pick the conversation with the last sentOn for each prospect
        const mergedConversations = allConversations.reduce<typeof allConversations>((acc, conversation) => {
            const existingConversation = acc.find((c) => c.prospectId === conversation.prospectId);

            if (!existingConversation) {
                acc.push(conversation);
            } else if (new Date(conversation.sentOn) > new Date(existingConversation.sentOn)) {
                // Replace the existing conversation if the current one is more recent
                const index = acc.findIndex((c) => c.prospectId === conversation.prospectId);
                acc[index] = conversation;
            }

            return acc;
        }, []);

        return mergedConversations
            .filter((conversation) => !!prospectIdToUserContact[conversation.prospectId])
            .sort((a, b) => {
                return new Date(b.sentOn).getTime() - new Date(a.sentOn).getTime();
            });
    }, [conversationsData, prospectIdToUserContact]);

    const {
        prospect: { findProspectData },
    } = useSdk();

    const prospectIds = useMemo(() => {
        return [
            ...new Set([
                ...conversations?.map((conversation) => conversation.prospectId),
                ...(query.length > 1 ? Object.keys(prospectIdToUserContact) : []),
            ]),
        ];
    }, [conversations, prospectIdToUserContact, query]);

    const { data: prospectData, isLoading: isProspectLoading } = useTanQuery(
        findProspectData({
            // If there is no query, you might be sending in 600 prospectIds, and get the wrong ones first, so the latest convo wont be shown as the prospect is not in the list
            prospectIds: query.length > 1 ? prospectIds : prospectIds.slice(0, displayLimit),
            regex: query.length > 1 ? [query] : undefined,
            page: 1,
            perPage: displayLimit,
            includeFields: [
                'fullName',
                'linkedinProfileImgUrl',
                'linkedinUserId',
                'occupationTitle',
                'organizationName',
            ],
        })
    );

    useEffect(() => {
        if (!prospectData) return;

        const prospectMap: Record<
            string,
            ProspectDataWithId<
                'fullName' | 'linkedinProfileImgUrl' | 'linkedinUserId' | 'occupationTitle' | 'organizationName'
            >
        > = {};
        prospectData?.prospects?.forEach((prospect) => (prospectMap[prospect.prospectId] = prospect));
        setProspectIdToProspect(prospectMap);
    }, [prospectData]);

    // Add scroll handler
    useEffect(() => {
        const container = listContainerRef.current;
        if (!container) return;

        const handleScroll = () => {
            const { scrollTop, scrollHeight, clientHeight } = container;
            const isNearBottom = scrollHeight - scrollTop - clientHeight < 10;

            if (isNearBottom && prospectData?.count > prospectData?.prospects?.length) {
                setDisplayLimit((prev) => prev + PAGE_SIZE);
            }
        };

        container.addEventListener('scroll', handleScroll);
        return () => container.removeEventListener('scroll', handleScroll);
    }, [prospectData]);

    const loading =
        (loadingConversations && !conversations?.length) ||
        (isProspectLoading && !Object.keys(prospectIdToProspect).length);
    console.log(
        'loading',
        loading,
        loadingConversations,
        conversations?.length,
        isProspectLoading,
        Object.keys(prospectIdToProspect).length
    );

    const filteredProspectIds = useMemo(() => {
        const matchingConversations =
            conversations?.filter((conversation) => prospectIdToProspect[conversation.prospectId]) ?? [];

        const matchingProspects = [
            ...matchingConversations,
            ...(prospectData?.prospects?.filter(
                (p) => !matchingConversations.find((c) => c.prospectId === p.prospectId)
            ) ?? []),
        ].map((p) => p.prospectId);

        return matchingProspects;
    }, [conversations, prospectData]);

    // useEffect(() => {
    //     if (filteredProspectIds.length > 0 && !prospectId && !isMobile && !createProspectsFromAllLiConversation) {
    //         navigate(`./${filteredProspectIds[0]}`);
    //     }
    // }, [filteredProspectIds]);

    return (
        <div className={cn('flex flex-col w-full md:max-w-xs mx-auto h-full max-h-full overflow-hidden border-r')}>
            <div className="flex gap-2 items-center py-2 px-4 border-b">
                <Search className="text-text-tertiary w-5" />
                <Input
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder="Search Andsend & Linkedin"
                    className="border-none ring-0 shadow-none focus-visible:ring-0"
                />
                <PlaybookFilterBadge size="sm" />
            </div>
            <div className="flex flex-col max-h-full p-2 w-full gap-1 overflow-y-auto" ref={listContainerRef}>
                {loading ? (
                    <>
                        <ConversationCardSkeleton key={'todo-0'} withBadge={false} />
                        <ConversationCardSkeleton key={'todo-1'} withBadge={false} />
                        <ConversationCardSkeleton key={'todo-2'} withBadge={false} />
                        <ConversationCardSkeleton key={'todo-3'} withBadge={false} />
                        <ConversationCardSkeleton key={'todo-4'} withBadge={false} />
                    </>
                ) : (
                    <AnimatePresence>
                        <div className="flex flex-col w-full gap-1">
                            <AnimatePresence>
                                {filteredProspectIds.map((prospectId, index) => (
                                    <ConversationCard
                                        prospectId={prospectId}
                                        conversation={conversations.find((c) => c.prospectId === prospectId)}
                                        userContact={prospectIdToUserContact[prospectId]}
                                        prospectData={prospectIdToProspect[prospectId]}
                                        key={'todo-' + prospectId}
                                    />
                                ))}
                            </AnimatePresence>
                        </div>
                        {/* {query.length > 2 && (
                            <div className="flex flex-col max-h-full overflow-auto w-full gap-1">
                                {filteredContacts.map((contact) => (
                                    <ConversationCard
                                        key={'contact-' + contact.id}
                                        prospectId={contact.prospectId}
                                        conversation={null}
                                        userContact={contact}
                                        prospectData={prospectIdToProspect[contact.prospectId]}
                                    />
                                ))}
                            </div>
                        )} */}
                    </AnimatePresence>
                )}
                {!searchParamGroupId && (
                    <ConversationLinkedinSearch
                        queryString={query}
                        excludeMemberIds={Object.values(prospectIdToProspect).map((p) => p.linkedinUserId) ?? []}
                    />
                )}
            </div>
        </div>
    );
};
