import { FC, useState, useRef, useEffect } from 'react';
import { ChevronDown, ChevronUp, X, Check } from 'lucide-react';
import { Badge } from '@shadcn/ui/components/ui/badge';
import { Button } from '@shadcn/ui/components/ui/button';
import { cn } from '@shadcn/ui/lib/utils';
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from '@shadcn/ui/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '@shadcn/ui/components/ui/popover';

export interface LanguageSelectorProps {
    value: string[];
    onChange: (newValue: string[]) => void;
    placeholder?: string;
}

export const LanguageSelector: FC<LanguageSelectorProps> = ({ value = [], onChange, placeholder }) => {
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState('');

    const languages = Object.values(languages_list);
    const selectedLanguages = value.map((v) => languages.find((l) => l.value === v)).filter(Boolean);

    const filteredLanguages = languages.filter(
        (lang) =>
            lang.displayName.toLowerCase().includes(search.toLowerCase()) ||
            lang.value.toLowerCase().includes(search.toLowerCase())
    );

    const handleToggleSelection = (langValue: string) => {
        const newValue = value.includes(langValue) ? value.filter((v) => v !== langValue) : [...value, langValue];
        onChange(newValue);
    };

    const handleRemoveSelection = (e: React.MouseEvent, langValue: string) => {
        e.stopPropagation();
        const newValue = value.filter((v) => v !== langValue);
        onChange(newValue);
    };

    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
                <Button type="button" variant="outline" className="w-full justify-start h-auto min-h-10 py-2 px-3">
                    <div className="flex flex-wrap gap-1 items-center">
                        {selectedLanguages.length === 0 ? (
                            <span className="text-muted-foreground font-normal">
                                {placeholder || 'Select languages'}
                            </span>
                        ) : (
                            selectedLanguages.map(
                                (lang) =>
                                    lang && (
                                        <Badge
                                            key={lang.value}
                                            variant="outline"
                                            className="inline-flex items-center gap-1 px-2 py-1"
                                        >
                                            <span>{lang.displayName}</span>
                                            <button
                                                type="button"
                                                onClick={(e) => handleRemoveSelection(e, lang.value)}
                                                className="focus:outline-none"
                                            >
                                                <X size={12} />
                                            </button>
                                        </Badge>
                                    )
                            )
                        )}
                    </div>
                    <ChevronDown
                        size={12}
                        className={cn('ml-auto transition-transform duration-300', open && 'rotate-180')}
                    />
                </Button>
            </PopoverTrigger>
            <PopoverContent
                className="w-[var(--radix-popover-trigger-width)] p-0"
                align="start"
                side="top"
                sideOffset={5}
                avoidCollisions={false}
            >
                <Command className="min-h-0">
                    <CommandInput
                        placeholder="Search languages..."
                        value={search}
                        onValueChange={setSearch}
                        className="h-9 px-1"
                        autoFocus
                    />
                    <CommandList className="max-h-60 overflow-auto">
                        <CommandEmpty className="py-2">No languages found</CommandEmpty>
                        <CommandGroup>
                            {filteredLanguages.map((lang) => (
                                <CommandItem
                                    key={lang.value}
                                    value={lang.value}
                                    onSelect={() => handleToggleSelection(lang.value)}
                                    className="flex items-center px-2 py-1.5 cursor-pointer"
                                >
                                    <div className="flex items-center space-x-2 flex-1">
                                        <div className="h-4 w-4 flex items-center justify-center">
                                            {value.includes(lang.value) && <Check className="h-4 w-4" />}
                                        </div>
                                        <span className="text-sm">{lang.displayName}</span>
                                    </div>
                                </CommandItem>
                            ))}
                        </CommandGroup>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    );
};

export default LanguageSelector;

const languages_list_raw: { [key: string]: { displayName: string; displayNameNoEmoji: string; value: string } } = {
    Albanian: { displayName: '🇦🇱 Albanian', displayNameNoEmoji: 'Albanian', value: 'Albanian' },
    Arabic: { displayName: '🇦🇪 Arabic', displayNameNoEmoji: 'Arabic', value: 'Arabic' },
    Bosnian: { displayName: '🇧🇦 Bosnian', displayNameNoEmoji: 'Bosnian', value: 'Bosnian' },
    Bulgarian: { displayName: '🇧🇬 Bulgarian', displayNameNoEmoji: 'Bulgarian', value: 'Bulgarian' },
    Cantonese: { displayName: '🇭🇰 Cantonese', displayNameNoEmoji: 'Cantonese', value: 'Cantonese' },
    Croatian: { displayName: '🇭🇷 Croatian', displayNameNoEmoji: 'Croatian', value: 'Croatian' },
    Czech: { displayName: '🇨🇿 Czech', displayNameNoEmoji: 'Czech', value: 'Czech' },
    Danish: { displayName: '🇩🇰 Danish', displayNameNoEmoji: 'Danish', value: 'Danish' },
    Dutch: { displayName: '🇳🇱 Dutch', displayNameNoEmoji: 'Dutch', value: 'Dutch' },
    English: { displayName: '🇬🇧 English', displayNameNoEmoji: 'English', value: 'English' },
    Estonian: { displayName: '🇪🇪 Estonian', displayNameNoEmoji: 'Estonian', value: 'Estonian' },
    Filipino: { displayName: '🇵🇭 Filipino', displayNameNoEmoji: 'Filipino', value: 'Filipino' },
    Finnish: { displayName: '🇫🇮 Finnish', displayNameNoEmoji: 'Finnish', value: 'Finnish' },
    French: { displayName: '🇫🇷 French', displayNameNoEmoji: 'French', value: 'French' },
    German: { displayName: '🇩🇪 German', displayNameNoEmoji: 'German', value: 'German' },
    Greek: { displayName: '🇬🇷 Greek', displayNameNoEmoji: 'Greek', value: 'Greek' },
    Hindi: { displayName: '🇮🇳 Hindi', displayNameNoEmoji: 'Hindi', value: 'Hindi' },
    Hungarian: { displayName: '🇭🇺 Hungarian', displayNameNoEmoji: 'Hungarian', value: 'Hungarian' },
    Indonesian: { displayName: '🇮🇩 Indonesian', displayNameNoEmoji: 'Indonesian', value: 'Indonesian' },
    Icelandic: { displayName: '🇮🇸 Icelandic', displayNameNoEmoji: 'Icelandic', value: 'Icelandic' },
    Italian: { displayName: '🇮🇹 Italian', displayNameNoEmoji: 'Italian', value: 'Italian' },
    Japanese: { displayName: '🇯🇵 Japanese', displayNameNoEmoji: 'Japanese', value: 'Japanese' },
    Korean: { displayName: '🇰🇷 Korean', displayNameNoEmoji: 'Korean', value: 'Korean' },
    Macedonian: { displayName: '🇲🇰 Macedonian', displayNameNoEmoji: 'Macedonian', value: 'Macedonian' },
    Malay: { displayName: '🇲🇾 Malay', displayNameNoEmoji: 'Malay', value: 'Malay' },
    Mandarin: { displayName: '🇨🇳 Mandarin', displayNameNoEmoji: 'Mandarin', value: 'Mandarin' },
    Montenegrin: { displayName: '🇲🇪 Montenegrin', displayNameNoEmoji: 'Montenegrin', value: 'Montenegrin' },
    Norwegian: { displayName: '🇳🇴 Norwegian', displayNameNoEmoji: 'Norwegian', value: 'Norwegian' },
    Polish: { displayName: '🇵🇱 Polish', displayNameNoEmoji: 'Polish', value: 'Polish' },
    Portuguese: { displayName: '🇵🇹 Portuguese', displayNameNoEmoji: 'Portuguese', value: 'Portuguese' },
    Romanian: { displayName: '🇷🇴 Romanian', displayNameNoEmoji: 'Romanian', value: 'Romanian' },
    Russian: { displayName: '🇷🇺 Russian', displayNameNoEmoji: 'Russian', value: 'Russian' },
    Serbian: { displayName: '🇷🇸 Serbian', displayNameNoEmoji: 'Serbian', value: 'Serbian' },
    Slovak: { displayName: '🇸🇰 Slovak', displayNameNoEmoji: 'Slovak', value: 'Slovak' },
    Slovenian: { displayName: '🇸🇮 Slovenian', displayNameNoEmoji: 'Slovenian', value: 'Slovenian' },
    Spanish: { displayName: '🇪🇸 Spanish', displayNameNoEmoji: 'Spanish', value: 'Spanish' },
    Swedish: { displayName: '🇸🇪 Swedish', displayNameNoEmoji: 'Swedish', value: 'Swedish' },
    Thai: { displayName: '🇹🇭 Thai', displayNameNoEmoji: 'Thai', value: 'Thai' },
    Turkish: { displayName: '🇹🇷 Turkish', displayNameNoEmoji: 'Turkish', value: 'Turkish' },
    Ukrainian: { displayName: '🇺🇦 Ukrainian', displayNameNoEmoji: 'Ukrainian', value: 'Ukrainian' },
    Vietnamese: { displayName: '🇻🇳 Vietnamese', displayNameNoEmoji: 'Vietnamese', value: 'Vietnamese' },
};

export const isOnWindows = navigator?.userAgent?.includes('Windows');

const languages_list: { [key: string]: { displayName: string; value: string } } = Object.keys(
    languages_list_raw
).reduce((acc, key) => {
    acc[key] = {
        displayName: isOnWindows ? languages_list_raw[key].displayNameNoEmoji : languages_list_raw[key].displayName,
        value: languages_list_raw[key].value,
    };
    return acc;
}, {});

export const languages_list_no_emojis = [
    'Arabic',
    'Danish',
    'Dutch',
    'English',
    'Estonian',
    'Finnish',
    'French',
    'German',
    'Greek',
    'Hindi',
    'Italian',
    'Norwegian',
    'Polish',
    'Portuguese',
    'Russian',
    'Spanish',
    'Swedish',
    'Turkish',
];
