import { useEffect } from 'react';
import { useDispatch } from 'react-redux-latest';
import { getHubspotFields, getHubspotSettings } from '../../../../redux/ducks/hubspot/operations';
import { AppDispatch } from '../../../../redux/store/configureStore';
import { Configuration } from './components/configuration';
import { FieldMap } from './components/fieldMap';
import { TopBar } from './components/topBar';
import { BodyContainer, HubSpotContainer, ViewContainer } from './containers';

const HubspotView = () => {
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            dispatch(getHubspotSettings());
            dispatch(getHubspotFields());
        }
        return () => {
            isMounted = false;
        };
    }, []);

    return (
        <ViewContainer>
            <HubSpotContainer>
                <TopBar />
                <BodyContainer>
                    <FieldMap />
                    <Configuration />
                </BodyContainer>
            </HubSpotContainer>
        </ViewContainer>
    );
};

export default HubspotView;
