import { FC } from 'react';

export const DashboardHeader: FC = () => {
    return (
        <header className="sticky top-0 z-50 w-full border-b bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60 p-4">
            <span className="font-medium text-2xl">Statistics</span>
            <p className="text-sm text-muted-foreground">Insights into your Andsend usage and performance</p>
        </header>
    );
};

export default DashboardHeader;
