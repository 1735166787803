import {
    getProspectById,
    purchaseProspects,
    selectProspectMainExperience,
    updateProspectData,
    findProspectData,
    runBulkImport,
} from './internal/prospect.sdk';
import { snoozeOutreachSuggestion } from './internal/outreach-suggestion.sdk';
import { getAccountById, getAccount, createAccount } from './internal/account.sdk';
import {
    getMyChannelAccounts,
    setPremiumStatus,
    updateLinkedinAvatarUrl,
    addChannelAccount,
    reauthorizeChannelAccount,
    disconnectChannelAccount,
    updateEmailSignature,
    resyncEmailSignature,
    createLinkedinChannelAccount,
} from './internal/channel-account.sdk';
import {
    getUserContactLimit,
    assignUserContactsToGroup,
    deleteUserContacts,
    getTotalUserContactsCount,
} from './internal/user-contacts.sdk';
import {
    getUserCreditsAndSeatSummary,
    getUserReferralLink,
    notifyInvitationClicked,
    updateBetaFeatures,
    getUserAccounts,
    createUser,
    getUserOrganizationByDomain,
    updateUserSettings,
} from './internal/user.sdk';
import { search, searchInCompany } from './internal/global-search.sdk';
import { getOwners } from './internal/prospect-owners.sdk';
import { getRegeneratedMessage, regenerateMessage, generateMessageAsEmail } from './internal/messaging.sdk';
import {
    createGroupWithAssistantSettings,
    updateGroup,
    deleteGroup,
    getGroupStatistics,
    updateAssistantSettings,
    generatePlaybookContext,
    suggestTargetGroupAndPurpose,
} from './internal/group.sdk';
import { deleteNote, createNote } from './internal/notes.sdk';
import { getPersonById } from './internal/person.sdk';
import {
    approveContactSuggestion,
    skipContactSuggestion,
    createContactSuggestion,
    getRecommendationsForPurpose,
} from './internal/contact-suggestions.sdk';
import {
    getTasks,
    completeTask,
    startTask,
    startFetchProfileTask,
    completeFetchProfileTask,
} from './internal/data-source-synk.sdk';
import {
    addFoundLinkedinProfile,
    getLinkedinConnections,
    getLinkedinProfileByMemberIdOrPublicIdentifier,
} from './internal/linkedin-profiles.sdk';
import { getConnection } from './internal/linkedin-connections.sdk';
import { getLinkedinCursorToSyncFrom, getProspectLinkedinTracker, syncProspect } from './internal/tracker.sdk';
import { sendFeedback } from './internal/feedback.sdk';
import { getUserMemories, updateUserMemory, deleteUserMemory } from './internal/user-memory.sdk';
import {
    resetPassword,
    confirmPasswordReset,
    sendEmailVerification,
    setEmailVerified,
    chooseOrganization,
} from './internal/authentication.sdk';
import {
    updateOrganizationName,
    getOrganizationIntegrations,
    updateIntegrationScope,
    updateFieldMapping,
    getAvailableFields,
    getOrganizationByUser,
    connectHubspot,
    connectPipedrive,
    connectSalesforce,
} from './internal/user-organization.sdk';
import { trackFoundLinkedinConversations } from './internal/linkedin-conversations.sdk';
import { setupMessenger, importLinkedinAnalysis } from './internal/onboarding.sdk';

export const useSdk = () => {
    return {
        prospect: {
            getProspectById,
            updateProspectData,
            selectProspectMainExperience,
            purchaseProspects,
            findProspectData,
            runBulkImport,
        },
        person: {
            getPersonById,
        },
        feedback: {
            sendFeedback,
        },

        contactSuggestions: {
            approveContactSuggestion,
            skipContactSuggestion,
            createContactSuggestion,
            getRecommendationsForPurpose,
        },

        outreachSuggestion: {
            snoozeOutreachSuggestion,
        },

        prospectOwners: {
            getOwners,
        },

        account: {
            getAccountById,
            getAccount,
            createAccount,
        },

        channelAccount: {
            getMyChannelAccounts,
            setPremiumStatus,
            updateLinkedinAvatarUrl,
            addChannelAccount,
            reauthorizeChannelAccount,
            disconnectChannelAccount,
            updateEmailSignature,
            resyncEmailSignature,
            createLinkedinChannelAccount,
        },
        userContacts: {
            getTotalUserContactsCount,
            getUserContactLimit,
            assignUserContactsToGroup,
            deleteUserContacts,
        },
        user: {
            getUserReferralLink,
            getUserCreditsAndSeatSummary,
            notifyInvitationClicked,
            updateBetaFeatures,
            addChannelAccount,
            getUserAccounts,
            createUser,
            getUserOrganizationByDomain,
            updateUserSettings,
        },
        globalSearch: {
            search,
            searchInCompany,
        },
        messaging: {
            getRegeneratedMessage,
            regenerateMessage,
            generateMessageAsEmail,
        },
        playbook: {
            createGroupWithAssistantSettings,
            updateGroup,
            deleteGroup,
            getGroupStatistics,
            updateAssistantSettings,
            generatePlaybookContext,
            suggestTargetGroupAndPurpose,
        },
        notes: {
            deleteNote,
            createNote,
        },
        backgroundTasks: {
            getTasks,
            completeTask,
            startTask,
            startFetchProfileTask,
            completeFetchProfileTask,
        },
        linkedinProfiles: {
            getLinkedinProfileByMemberIdOrPublicIdentifier,
            addFoundLinkedinProfile,
            getLinkedinConnections,
        },
        linkedinConnections: {
            getConnection,
        },
        trackers: {
            getLinkedinCursorToSyncFrom,
            getProspectLinkedinTracker,
            syncProspect,
        },
        userMemory: {
            getUserMemories,
            updateUserMemory,
            deleteUserMemory,
        },
        integrations: {
            getIntegrations: () => Promise.resolve([]),
            disconnectIntegration: ({ type }: { type: string }) => Promise.resolve(),
        },
        authentication: {
            resetPassword,
            confirmPasswordReset,
            sendEmailVerification,
            setEmailVerified,
            chooseOrganization,
        },
        userOrganization: {
            updateOrganizationName,
            getOrganizationIntegrations,
            updateIntegrationScope,
            updateFieldMapping,
            getAvailableFields,
            getOrganizationByUser,
            connectHubspot,
            connectPipedrive,
            connectSalesforce,
        },
        linkedinConversations: {
            trackFoundLinkedinConversations,
        },
        onboarding: {
            setupMessenger,
            importLinkedinAnalysis,
        },
    };
};
