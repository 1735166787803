import { Box, Typography } from '@mui/material';
import { Skeleton } from '@mui/material';

import PricePerUserPerMonth from './PlanEstimates';
import PackageSelect from './PackageSelect';
import TotalPricePerPerMonth from './TotalPricePerPerMonth';
import { BillingPeriodUnit } from '../../enums/billing';
import AllPlanInformations from './AllPlanInformations';
import { PlanCode } from '@zaplify/subscriptions';
import PlanCardWrapper from './PlanCardWrapper';
import { palette } from '../../theme/palette';

export const FreePlanCard = ({
    loadingState,
    planCode,
    onSelect,
}: {
    loadingState: 'loading' | 'idle';
    planCode: PlanCode;
    onSelect: () => void;
}) => (
    <PlanCardWrapper premium={false}>
        {loadingState == 'loading' && <FreePlanCardLoading />}
        {loadingState === 'idle' && (
            <Box
                sx={{
                    width: '100%',
                    marginTop: '6px',
                    borderRadius: '0 0 8px 8px',
                    padding: '20px 24px',
                    background: palette.colors.white,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    height: '100%',
                }}
            >
                <div className="top" style={{ marginBottom: '22px' }}>
                    <Typography
                        className="title"
                        sx={{ marginBottom: '6px', color: 'black', fontSize: '19px', fontWeight: 500 }}
                    >
                        Free
                    </Typography>
                    <div className="subtitle" style={{ color: '#6B7985', fontSize: '15px', marginTop: '7px' }}>
                        Amplify value in every conversation for free.
                    </div>
                </div>
                <Box marginTop={'0px'} marginBottom={'43px'}>
                    <Typography variant="h5">Single user</Typography>
                </Box>

                <Box>
                    <PricePerUserPerMonth estimatedPricePerMonth="€0" />
                </Box>
                <Box marginTop={'14px'}>
                    <TotalPricePerPerMonth price="€0" billingPeriod={BillingPeriodUnit.MONTH} />
                </Box>
                <Box marginTop={'60px'}>
                    <AllPlanInformations planCode={PlanCode.FREE} />
                </Box>
                <Box sx={{ marginTop: 'auto' }}>
                    <PackageSelect state="active" onPlanSelect={onSelect} />
                </Box>
            </Box>
        )}
    </PlanCardWrapper>
);

const FreePlanCardLoading = () => (
    <Box height={'100%'} display={'flex'} width="100%" flexDirection={'column'} justifyContent={'flex-start'}>
        <Box padding={'28px 24px 0'} sx={{ boxSizing: 'borderBox' }}>
            <Skeleton
                animation="wave"
                variant="rectangular"
                width="100%"
                height={24}
                style={{ marginBottom: '12px', borderRadius: '4px' }}
            />
            <Skeleton animation="wave" variant="rectangular" width="100%" height={21} style={{ borderRadius: '4px' }} />
        </Box>
        <Box padding={'0 16px 16px'} marginTop="154px">
            <Skeleton animation="wave" variant="rectangular" height={50} style={{ borderRadius: '300px' }} />
        </Box>
    </Box>
);
