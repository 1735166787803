import { styled } from '@mui/styles';

export const FieldMappingContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 45px 30px 60px',
    width: '618px',
    maxWidth: '90%',
    alignItems: 'start',
    boxSizing: 'border-box',
    '& > div:nth-child(3)': {
        display: 'flex',
        width: '100%',
        padding: '20px 40px',
        justifyContent: 'space-between',
        boxSizing: 'border-box',
        maxWidth: 408,
        '& > img': {
            height: 16,
        },
    },
    '& > .field-mapping': {
        '& > .field': {
            alignItems: 'center',
            marginBottom: 5,
            flexWrap: 'nowrap !important',
            width: 650,
            flexDirection: 'row',
            display: 'flex',

            '& .trash-button': {
                color: '#96A2AC',
            },
        },
        marginBottom: 5,
    },
    '& > p:nth-child(6)': {
        marginTop: 10,
    },
}));
