import { Box, Typography } from '@mui/material';
import LiInfoBackgroundInfo from '../../onboarding/assets/li-onboarding-background.jpg';
import { AndSendIcon } from '../../../components/icons/and-send/andsend-icon';

export const AiPreferencesOnboardingInfoSection = () => {
    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                backgroundImage: `url(${LiInfoBackgroundInfo})`,
                backgroundColor: `black`,
                alignContent: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundSize: 'cover',
                backgroundPosition: '30%',
                paddingX: '24px',
            }}
        >
            <Box
                sx={{
                    padding: '24px',
                    maxWidth: '80%',
                    backgroundColor: 'rgba(255, 255, 255, 1)',
                    borderRadius: '16px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '20px',
                        gap: '16px',
                        mb: 3,
                        transform: 'translateY(-30px)',
                    }}
                >
                    <IconCircle>
                        <AndSendIcon
                            variation="black-striped"
                            style={{ width: '40px', height: '40px' }}
                            opacity="80%"
                        />
                    </IconCircle>
                </Box>
                <Typography
                    sx={{
                        fontSize: '18px',
                        fontWeight: 600,
                        lineHeight: '28px',
                        textAlign: 'left',
                        mb: 2,
                    }}
                >
                    Let's configure Andsend for you
                </Typography>
                <Typography
                    sx={{
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '24px',
                        textAlign: 'left',
                        color: 'text.secondary',
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '12px' }}>
                        <Box>🎯 Align Andsend with your goals</Box>
                        <Box>💬 Receive message suggestions that sound like you</Box>
                        <Box>📚 Tailored contact recommendations based on your company background and goals</Box>
                        <Box>🔍 Focus on what truly matters to you</Box>
                    </Box>
                </Typography>
            </Box>
        </Box>
    );
};

export const IconCircle = ({ children }: { children: React.ReactNode }) => {
    return (
        <Box
            sx={{
                width: '80px',
                height: '80px',
                borderRadius: '50%',
                border: '1px solid #D0D5DD',
                backgroundColor: 'rgba(255, 255, 255, 1)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            {children}
        </Box>
    );
};
