import { Typography } from '@mui/material';
import { ReactComponent as AndSendLogo } from '../../../../../components/icons/and-send/ANDSEND_logotype_small_black_transparent.svg';
import { ReactComponent as Arrow } from '../../../../../components/icons/arrow.svg';
import SalesforceLogoText from '../../../../../components/icons/salesforce-logo-3.svg';
import { ReactComponent as ZaplifyLogo } from '../../../../../components/icons/zaplify-logo.svg';
import { companyNameCorrectlyCased } from '../../../../../enums/company-name';
import { isAndSend } from '../../../../../helpers/is-and-send';
import { FieldMapHeaderContainer } from './containers';
import { RadioGroupStrategy } from './radioGroup';

export const FieldMapHeader = () => {
    return (
        <FieldMapHeaderContainer data-cy="salesforce-field-mapping-container">
            <div className={'text-container'}>
                <Typography>
                    <b>Field mapping</b>
                </Typography>
                <Typography>{`Specify which ${companyNameCorrectlyCased} fields should correspond to Salesforce fields.`}</Typography>
            </div>
            <RadioGroupStrategy />
            <div className={'logos-container'}>
                {isAndSend() ? (
                    <AndSendLogo style={{ height: '20px', width: 'auto' }} />
                ) : (
                    <ZaplifyLogo style={{ height: '20px', width: 'auto' }} />
                )}
                <Arrow />
                <img alt="salesforce-company-logo" src={SalesforceLogoText} />
            </div>
        </FieldMapHeaderContainer>
    );
};
