import { sleep } from './helpFunctions';
import { UserRole } from '@zaplify/users/shared';
import { getAuth } from 'firebase/auth';

interface IBearerTokenClaims {
    authUserId?: string;
    organizationId?: string;
    userId?: string;
    roles?: UserRole[];
}

interface ITokenResult {
    token: string;
    rootAuthUserId?: string;
    authUserId?: string;
    claims: IBearerTokenClaims;
}

export async function getAuthenticationToken(forceRefresh = false, retriesLeft = 1): Promise<ITokenResult> {
    if (retriesLeft < 0) {
        console.warn('Could not get authentication token. Are you logged in?');
        return { token: '', claims: {} };
    }
    const firebaseUser = getAuth().currentUser;
    const token = firebaseUser ? await firebaseUser.getIdToken(forceRefresh) : null;
    // TODO: due to the issue in Chrome Extension, which during running production build on local environment on MacOS.
    // first "currentUser" is null, and then after sleep, it return valid object
    if (!token) {
        await sleep(3000);
        return await getAuthenticationToken(forceRefresh, retriesLeft - 1);
    }

    const tokenResult = await firebaseUser?.getIdTokenResult();
    const claims = tokenResult?.claims || {};

    return {
        token: token || '',
        rootAuthUserId: firebaseUser?.uid,
        // @TODO Extend fb interface instead so that we don't need type assertion
        authUserId: (claims['authUserId'] as string) || undefined,
        claims: claims as IBearerTokenClaims,
    };
}
