import { atomFamily, atomWithStorage } from 'jotai/utils';
import { useAtom } from 'jotai';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { ChannelType } from '../../types/channel-type';
import { htmlToPlain } from '../../functions/html-to-plain';

const DRAFT_MESSAGES_KEY = 'draft_messages_new';

interface MessageComposer {
    content: string;
    subjectLine: string;
    channelType: ChannelType;
}

export interface IDraftMessage extends MessageComposer {
    updatedAt: number;
}

type DraftMessageType = 'todo' | 'conversation' | 'note';

interface ProspectDraftMessages {
    todo?: IDraftMessage;
    conversation?: IDraftMessage;
    note?: IDraftMessage;
}

// Create an atom family for draft messages, keyed by prospect ID
const draftMessagesAtomFamily = atomFamily((prospectId: string) =>
    atomWithStorage<ProspectDraftMessages>(`${DRAFT_MESSAGES_KEY}_${prospectId}`, {})
);

export const useDraftMessage = (prospectId: string) => {
    const location = useLocation();
    const isConversation = !location.pathname.includes('actions');
    const type = isConversation ? 'conversation' : ('todo' as DraftMessageType);
    const [draftMessages, setDraftMessages] = useAtom(draftMessagesAtomFamily(prospectId));

    const setDraftMessage = useCallback(
        (newMessage: Partial<Omit<IDraftMessage, 'updatedAt'>> | null) => {
            if (
                !newMessage ||
                (htmlToPlain(newMessage.content) === '' && newMessage.subjectLine === '' && !newMessage.channelType)
            ) {
                const updatedDrafts = { ...draftMessages };
                delete updatedDrafts[type];
                setDraftMessages(updatedDrafts);
                return;
            }

            const previousMessage = draftMessages[type] || {};

            const updatedDrafts = {
                ...draftMessages,
                [type]: {
                    ...previousMessage,
                    ...newMessage,
                    updatedAt: Date.now(),
                },
            };
            setDraftMessages(updatedDrafts);
        },
        [type, draftMessages, setDraftMessages]
    );

    const removeDraftMessage = useCallback(
        ({ all, note }: { all?: boolean; note?: boolean } = { all: false, note: false }) => {
            if (all) {
                setDraftMessages({});
                return;
            }

            const updatedDrafts = { ...draftMessages };
            if (note) {
                delete updatedDrafts.note;
            } else {
                delete updatedDrafts[type];
            }
            setDraftMessages(updatedDrafts);
        },
        [type, draftMessages, setDraftMessages]
    );

    const hasDraftMessage = useCallback(() => {
        return !!draftMessages[type];
    }, [draftMessages, type]);

    // Get the current draft message based on type
    const draftMessage = draftMessages[type];

    return {
        draftMessage,
        setDraftMessage,
        removeDraftMessage,
        hasDraftMessage,
    };
};
