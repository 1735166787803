import { Topics } from './topics';

export abstract class IEvent {
    public static readonly TOPIC: Topics;
    public _external_name?: string;
    public _external_ignore_fields?: string[];
    public eid?: string;

    abstract get partitionKey(): string;

    constructor() {}
}
