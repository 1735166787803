import { Button, ButtonBase, CircularProgress, Skeleton, Typography } from '@mui/material';
import { getZaplifySdk } from '@zaplify/sdk';
import { CreateUserDto, IInvitationReduced, IUserAccount } from '@zaplify/users/shared';
import { getAuthenticationToken, getEmailDomain } from '@zaplify/utils';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux-latest';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import ZaplifyLogo from '../../components/icons/ZaplifyLogo';
import { OrganizationIcon } from '../../components/icons/organization';
import { LocalStorageKeys } from '../../config';
import { setNotification } from '../../redux/actions';
import { AppDispatch, useAppSelector } from '../../redux/store/configureStore';
import { paths } from '../../routes/paths';
import { theme } from '../../theme';
import { useSeatsAndCredits } from '../../new/hooks/use-seat-and-credits';
import { companyNameCorrectlyCased } from '../../enums/company-name';
import { Link } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useApolloClient } from '@apollo/client';
import { getAuth } from 'firebase/auth';
import { useAuth } from '../../new/providers/authentication-provider';

const colors = (theme.palette as any).colors;

export const Component = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { authState } = useAuth();
    const [userInvitations, setInvitations] = useState<IInvitationReduced[]>([]);
    const [userAccounts, setUserAccounts] = useState<IUserAccount[]>([]);
    const [loading, setLoading] = useState(true);
    const [selecting, setSelecting] = useState<null | string>(null);
    const [hasDomainOrganization, setHasDomainOrganization] = useState(false);
    const [numberOfUsersInOrg, setNumberOfUsersInOrg] = useState({});
    const authErrorMessage = useAppSelector((state) => state.user.authErrorMessage);
    const userSdk = getZaplifySdk().profiles.user;
    const userOrgSdk = getZaplifySdk().profiles.userOrganization;
    const { refetch: refetchCredits } = useSeatsAndCredits();

    const tanStackClient = useQueryClient();
    const apolloClient = useApolloClient();

    const getAccounts = useCallback(async () => {
        try {
            const currentUser = getAuth().currentUser;
            const domain = getEmailDomain(currentUser.email);
            const accounts = await userSdk.getUserAccounts();

            const users = accounts.users;
            const invitations = accounts.invitations;
            console.log('users and invitations', { users, invitations });
            const userHasAccountWithDomain = users?.some((user) =>
                domain.includes(user.organization.name?.toLowerCase())
            );

            try {
                users.map(async (account) => {
                    if (account.userId && account.organization?.id) {
                        const { count } = await userOrgSdk.getActiveUsersCount(account.organization.id);
                        setNumberOfUsersInOrg((prev) => ({
                            ...prev,
                            [account.organization.id]: count,
                        }));
                    }
                });
            } catch (error) {
                console.error('Error getting number of users in org', error);
            }

            setHasDomainOrganization(userHasAccountWithDomain);
            setInvitations(invitations);
            setUserAccounts(users);
            refetchCredits();
            setLoading(false);
        } catch (error) {
            console.error('error accounts', error);
            dispatch(setNotification('Error getting accounts', 'error'));
            setLoading(false);
        }
    }, []);

    const handleSelect = async (action: 'select_account' | 'create_new_workspace', organizationId: string) => {
        const selectOrganization = async (organizationId?: string) => {
            await getZaplifySdk().profiles.authentication.chooseOrganization(organizationId);
            await getAuthenticationToken(true);
            await Promise.all([tanStackClient.clear(), apolloClient.resetStore()]);
        };

        if (action === 'select_account') {
            setSelecting(organizationId);
            console.log('did select organization');
            await selectOrganization(organizationId);
            return;
        }

        if (action === 'create_new_workspace') {
            setSelecting('new_workspace');
            console.log('did select create new workspace');
            await selectOrganization(); // exiting to "no chosen user" required to create new account
            return;
        }
    };

    const handleJoinOrganization = async ({
        userOrganizationId,
        invitationId,
        organizationName,
        userAccountToBaseNewAccountOf,
    }: {
        userOrganizationId: string;
        invitationId: string;
        organizationName: string;
        userAccountToBaseNewAccountOf?: IUserAccount;
    }) => {
        const userSdk = getZaplifySdk().profiles.user;

        if (!userAccountToBaseNewAccountOf && organizationName) {
            // @TODO Support this flow also if localStorage keys do not exist – eg. by fetching the invitation and setting those keys
            //  localStorage.getItem(LocalStorageKeys.INVITATION_SECURITY_TOKEN);
            //  localStorage.getItem(LocalStorageKeys.INVITATION_ID);
            console.log('joining org by invitation: no user in zaplify yet. redirecting to create first user account');
            navigate(paths.OLD_CONFIGURE_PROFILE);
            return;
        } else if (userAccountToBaseNewAccountOf && userOrganizationId && invitationId) {
            setSelecting(userOrganizationId);
            console.log('joining org by invitation: already has user in zaplify');
            try {
                const currentFbUser = await getAuthenticationToken();
                const payload: CreateUserDto = {
                    firebaseId: currentFbUser.authUserId,
                    email: userAccountToBaseNewAccountOf.email,
                    firstName: userAccountToBaseNewAccountOf.firstName,
                    lastName: userAccountToBaseNewAccountOf.lastName,
                    location: userAccountToBaseNewAccountOf.location,
                    isSubscribed: userAccountToBaseNewAccountOf.isSubscribed,
                    joinByInvitation: {
                        id: invitationId,
                        organizationId: userOrganizationId,
                    },
                    // note, referral link not relevant when joining organization – only when creating account for first time
                };
                await userSdk.createUser(payload);

                localStorage.removeItem(LocalStorageKeys.INVITATION_ID);
                localStorage.removeItem(LocalStorageKeys.INVITATION_SECURITY_TOKEN);
                localStorage.removeItem(LocalStorageKeys.INVITATION_STATUS);

                await getAuthenticationToken(true);
                dispatch(setNotification('Successfully joined organization', 'success'));
            } catch (error) {
                console.error('Error handleJoinOrganization handleJoinOrganization');
                dispatch(setNotification('Unable to join to this user organization', 'error'));
            }
        } else {
            console.error(
                'joining org by invitation: something in this invitation is off. Seems like either the organizationId or invitationId is missing',
                { userAccountToBaseNewAccountOf, userOrganizationId, invitationId }
            );
        }
    };

    // @TODO Maybe refactor (talk to Kevin before)
    // Redirect once state has been updated with the correct user
    useEffect(() => {
        if (selecting === 'new_workspace' && !authState.user?.userOrganizationId) {
            setSelecting(null);
            navigate(paths.OLD_CONFIGURE_PROFILE);
        } else if (!!selecting && selecting === authState.user?.userOrganizationId) {
            setSelecting(null);
            try {
                // Hard reload to clear cache
                window.location.href = paths.HOME;
            } catch (error) {
                console.error('Failed to redirect using window.location:', error);
                navigate(paths.HOME);
            }
        }
    }, [authState.user]);

    useEffect(() => {
        getAccounts();
    }, []);

    useEffect(() => {
        if (authErrorMessage) {
            dispatch(setNotification(authErrorMessage, 'error'));
            setSelecting(null);
        }
    }, [authErrorMessage]);

    return (
        <div
            style={{
                background: colors.whiteGrey,
                boxSizing: 'border-box',
                width: '100%',
            }}
        >
            <div style={{ backgroundColor: colors.white }}>
                <ZaplifyLogo style={{ width: '110px', height: '27px', padding: '16px 57px' }} />
            </div>

            <div style={{ marginTop: 44, marginBottom: 51, textAlign: 'center' }}>
                <div
                    style={{ marginBottom: 14, fontSize: 42, fontWeight: 500 }}
                >{`Your ${companyNameCorrectlyCased} Workspaces`}</div>
            </div>
            <div
                style={{
                    display: 'flex',
                    gap: 17,
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {loading && (
                    <Skeleton
                        style={{ borderRadius: 18 }}
                        variant="rectangular"
                        animation="wave"
                        height={98}
                        width={574}
                    />
                )}
                {!loading &&
                    userAccounts.map((account) => {
                        return (
                            <div
                                key={uuidv4()}
                                style={{
                                    minWidth: 574,
                                    boxSizing: 'border-box',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: '19px 16px',
                                    border: `1px solid ${colors.greyInformationLight}`,
                                    borderRadius: 18,
                                    background: colors.white,
                                    boxShadow: '0px 2px 7px rgba(112, 144, 176, 0.14)',
                                }}
                            >
                                <div style={{ display: 'flex', gap: 18 }}>
                                    <OrganizationIcon style={{ height: 58, width: 58 }} />
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Typography style={{ color: colors.black, fontSize: 19, fontWeight: 500 }}>
                                            {account.organization.name}
                                        </Typography>
                                        {Boolean(numberOfUsersInOrg[account.organization?.id]) && (
                                            <Typography style={{ color: colors.subline, fontSize: 15 }}>
                                                {numberOfUsersInOrg[account.organization.id]} users
                                            </Typography>
                                        )}
                                    </div>
                                </div>
                                {
                                    <Button
                                        variant="outlined"
                                        onClick={() => handleSelect('select_account', account.organization.id)}
                                        style={{
                                            height: 54,
                                            width: 154,
                                            borderRadius: 100,
                                            border: `1px solid ${colors.bluePale}`,
                                            color: colors.bluePale,
                                            fontWeight: 500,
                                            fontSize: 17,
                                            boxShadow: '0px 2px 6px rgba(112, 144, 176, 0.07)',
                                        }}
                                        className={`button active small`}
                                        disableElevation
                                        disabled={selecting === account.organization.id}
                                    >
                                        <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                                            Select
                                            {selecting === account.organization.id && (
                                                <CircularProgress style={{ height: 20, width: 20 }} />
                                            )}
                                        </div>
                                    </Button>
                                }
                            </div>
                        );
                    })}

                {!loading &&
                    userInvitations.map((invitation) => {
                        return (
                            <div
                                key={uuidv4()}
                                style={{
                                    minWidth: 574,
                                    boxSizing: 'border-box',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: '19px 16px',
                                    border: `1px solid ${colors.greyInformationLight}`,
                                    borderRadius: 18,
                                    background: colors.white,
                                    boxShadow: '0px 2px 7px rgba(112, 144, 176, 0.14)',
                                }}
                            >
                                <div style={{ display: 'flex', gap: 18 }}>
                                    <OrganizationIcon style={{ height: 58, width: 58 }} />
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Typography style={{ color: colors.black, fontSize: 19, fontWeight: 500 }}>
                                            {invitation.organization.name}
                                        </Typography>
                                        {Boolean(numberOfUsersInOrg[invitation.organization?.id]) && (
                                            <Typography style={{ color: colors.subline, fontSize: 15 }}>
                                                {numberOfUsersInOrg[invitation.organization.id]} users
                                            </Typography>
                                        )}
                                    </div>
                                </div>

                                <Button
                                    variant="contained"
                                    onClick={() =>
                                        handleJoinOrganization({
                                            userOrganizationId: invitation.organization.id,
                                            invitationId: invitation.invitationId,
                                            organizationName: invitation.organization.name,
                                            userAccountToBaseNewAccountOf: userAccounts?.length
                                                ? userAccounts[0]
                                                : undefined,
                                        })
                                    }
                                    style={{
                                        height: 54,
                                        width: 154,
                                        borderRadius: 100,
                                        fontWeight: 500,
                                        fontSize: 17,
                                        background: colors.bluePale,
                                        boxShadow: '0px 2px 6px rgba(112, 144, 176, 0.07)',
                                        color: colors.white,
                                    }}
                                    className={`button active small`}
                                    disableElevation
                                    disabled={selecting === invitation.organization.id}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            gap: 10,
                                            alignItems: 'center',
                                            lineHeight: '20px',
                                        }}
                                    >
                                        Join team
                                        {selecting === invitation.organization.id && (
                                            <CircularProgress style={{ height: 20, width: 20 }} />
                                        )}
                                    </div>
                                </Button>
                            </div>
                        );
                    })}
                <div
                    style={{
                        width: 504,
                        height: 1,
                        background: colors.greyInformationLight,
                    }}
                />
                {!loading && (
                    <div style={{ display: 'flex', gap: 3, alignItems: 'center', marginTop: 7 }}>
                        <Typography style={{ fontSize: 14, color: colors.subline }}>
                            Looking for a different workspace?
                            <br />
                            Ask someone to invite you or{' '}
                            <Link
                                to={'#'}
                                onClick={async (e) => {
                                    e.preventDefault();
                                    await handleSelect('create_new_workspace', undefined);
                                }}
                            >
                                create a new workspace
                            </Link>
                        </Typography>
                    </div>
                )}
            </div>
        </div>
    );
};

Component.displayName = 'Accounts';
