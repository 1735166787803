import { FC, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { ImportPageHeader } from './linkedin/components/import-page-header';
import { Skeleton } from '@shadcn/ui/components/ui/skeleton';

export const ImportPage: FC = () => {
    return (
        <div className="flex flex-col h-full max-w-full max-h-full overflow-hidden">
            <Suspense fallback={<SuspenseSkeleton />}>
                <Outlet />
            </Suspense>
        </div>
    );
};

const SuspenseSkeleton = () => {
    return (
        <div className="space-y-4">
            {Array.from({ length: 5 }).map((_, i) => (
                <div key={i} className="flex items-center space-x-4">
                    <Skeleton className="h-12 w-12 rounded-full" />
                    <div className="space-y-2">
                        <Skeleton className="h-4 w-[200px]" />
                        <Skeleton className="h-4 w-[150px]" />
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ImportPage;
