import { EditCampaignSteps } from '@zaplify/constants';
import { apiEndpoint, getZaplifySdk } from '@zaplify/sdk';
import { batch } from 'react-redux-latest';
import { PaymentStatus } from '../../components/pick-plan-modal/enums/billing';
import { LocalStorageKeys } from '../../config';
import { paths } from '../../routes/paths';
import { userActionTypes } from '../actionTypes';
import { createOrUpdateName, updateNewCampaignName } from './newCampaign/main';
import { handleErrorNotification, updatePaymentStatus } from './ui';
import { getOrganizationRewards } from './userOrganization';
import { companyNameCorrectlyCased } from '../../enums/company-name';
import { isAndSend } from '../../helpers/is-and-send';
import {
    createUserWithEmailAndPassword,
    getAuth,
    signInWithEmailAndPassword,
    confirmPasswordReset as fbConfirmPasswordReset,
} from 'firebase/auth';

const getErrorMessage = (errorCode) => {
    switch (errorCode) {
        case 'auth/wrong-password':
            return 'Email or password incorrect';
        case 'auth/user-not-found':
            return 'The password or email you entered is incorrect.';
        case 'auth/email-already-in-use':
            return `This email is already linked to a ${companyNameCorrectlyCased} account. Please sign in with your email address and password.`;
        case 'auth/too-many-requests':
            return 'Too many invalid requests, please try again later';
        default:
            return 'An unexpected error occurred. Please try again later';
    }
};

const loginError = (firebaseError) => {
    return {
        type: userActionTypes.LOGIN_FAILED,
        authErrorMessage: getErrorMessage(firebaseError.code),
    };
};

export const setUserAction = (user) => {
    return {
        type: userActionTypes.FETCH_USER_SUCCESS,
        zaplifyUser: user,
    };
};

export const fetchUserError = (errorMessage) => {
    return {
        type: userActionTypes.FETCH_USER_FAILED,
        authErrorMessage: errorMessage,
    };
};

const shouldPreventRedirectToLogin = (preventRedirectToLogin) => {
    return {
        type: userActionTypes.PREVENT_REDIRECT_TO_LOGIN,
        preventRedirectToLogin: preventRedirectToLogin,
    };
};

const logoutError = (errorMessage) => {
    return {
        type: userActionTypes.LOGOUT_FAILED,
        authErrorMessage: errorMessage,
    };
};

export const startLoading = () => {
    return {
        type: userActionTypes.START_LOADING,
    };
};

export const stopLoading = () => {
    return {
        type: userActionTypes.STOP_LOADING,
    };
};

export const updateSubscriptionLoading = (value) => {
    return {
        type: userActionTypes.UPDATE_SUBSCRIPTION_LOADING,
        payload: value,
    };
};

export const cleanAuthError = () => {
    return {
        type: userActionTypes.CLEAN_AUTH_ERROR,
    };
};

export const updateSeatAction = (seat) => {
    return {
        type: userActionTypes.UPDATE_SEAT,
        seat: seat,
    };
};

export const updateAppsAction = (appSettings) => {
    return {
        type: userActionTypes.UPDATE_APPS,
        settings: appSettings,
    };
};

export const setSubscriptionAction = (subscription) => {
    return {
        type: userActionTypes.SET_SUBSCRIPTION,
        payload: subscription,
    };
};

export const setChannelAccountsAction = (channelAccounts) => {
    return {
        type: userActionTypes.SET_CHANNEL_ACCOUNTS,
        payload: channelAccounts,
    };
};

export const setCreatedFreeSubscriptionAction = (value) => {
    return {
        type: userActionTypes.UPDATE_CREATED_FREE_SUBSCRIPTION,
        payload: value,
    };
};

export const loginUser = (email, password) => async (dispatch, getState) => {
    try {
        dispatch(startLoading());
        const { user } = await signInWithEmailAndPassword(getAuth(), email, password);

        window?.analytics?.track('User Signed In', {
            email: email,
        });
    } catch (error) {
        dispatch(loginError(error));
    } finally {
        dispatch(stopLoading());
    }
};

export const logoutUser = () => async (dispatch, getState) => {
    try {
        // @TODO Hit choose organization endpoint with no organization id.
        // So that next time user logs in, they can choose org.
        // For global admins this will also ensure that they have exited impersonation the next time they login.
        await getAuth().signOut();
        window?.analytics?.track('User Signed Out', {});
        clearLocalStorageKeys();
    } catch (error) {
        dispatch(logoutError('error logging out'));
    }
};

export const clearLocalStorageKeys = () => {
    Object.keys(LocalStorageKeys).forEach((key) => localStorage.removeItem(LocalStorageKeys[key]));
};

export const logoutAndRedirect = () => async (dispatch, getState) => {
    try {
        dispatch(shouldPreventRedirectToLogin(true));
        // @TODO Hit choose organization endpoint with no organization id.
        // So that next time user logs in, they can choose org.
        // For global admins this will also ensure that they have exited impersonation the next time they login.
        await getAuth().signOut();
        window?.analytics?.track('User Signed Out', {});
        clearLocalStorageKeys();
        window.open(isAndSend() ? 'https://andsend.com' : 'https://zaplify.com/', '_self');
    } catch (error) {
        dispatch(logoutError('error logging out'));
    }
};

export const registerUser = (email, password) => async (dispatch, getState) => {
    const zaplifySdk = getZaplifySdk(apiEndpoint);
    try {
        // dispatch(startLoading());
        // if (!(await validateEmailDomain(email))) {
        //     dispatch(registerCustomError('Please enter your work email (you@yourcompany.com)', 'No work email'));
        //     return;
        // }
        if ((window as any)?.fpr) (window as any).fpr('referral', { email: email });

        const { user } = await createUserWithEmailAndPassword(getAuth(), email, password);
        await zaplifySdk.profiles.authentication.sendEmailVerification({ emailAdress: email });

        window?.analytics?.identify({
            email: email,
        });

        window?.analytics?.track('User Registered', {
            email: email,
        });
        return user;
    } catch (error: any) {
        // dispatch(registerError(error));
        if (error.code === 'auth/email-already-in-use') {
            throw new Error(error.message);
        }
        throw new Error('An error occurred. Please try again later');
    }
};

export const resendEmailVerification = () => async (dispatch, getState) => {
    const zaplifySdk = getZaplifySdk(apiEndpoint);
    try {
        dispatch(startLoading());
        await zaplifySdk.profiles.authentication.sendEmailVerification({ emailAdress: getAuth().currentUser.email });
        return true;
    } catch (error) {
        console.log('failed resendEmailVerification');
        return false;
    } finally {
        dispatch(stopLoading());
    }
};

export interface ICreateOrgState {
    action: 'none' | 'create' | 'join_by_invitation' | 'join_by_domain';
    domain: string;
    companyName?: string;
    referralLinkId?: string;
}

export interface ICreateUserState {
    firstName: string;
    lastName: string;
    country: string;
    city?: string;
    isSubscribed: boolean;
    website?: string;
    phone?: string;
}

export const confirmPasswordReset = (actionCode, newPassword) => async (dispatch, getState) => {
    try {
        await fbConfirmPasswordReset(getAuth(), actionCode, newPassword);
        return {
            isSuccess: true,
            message: 'Successfully set new password',
        };
    } catch (error) {
        throw new Error('Your request to reset password has expired or the link has already been used', {
            cause: error,
        });
    }
};

export const refetchUserAndSubscription = () => async (dispatch) => {
    const profilesSdk = getZaplifySdk().profiles;
    const promises = [profilesSdk.subscription.fetchSusbscriptionDetails(), profilesSdk.user.getUserByFirebaseId()];
    const [subscriptionDetailsResult, zaplifyUserResult] = await Promise.allSettled(promises);

    if (zaplifyUserResult.status === 'fulfilled') {
        dispatch(setUserAction(zaplifyUserResult.value));
    } else {
        console.error(zaplifyUserResult.reason);
    }

    if (subscriptionDetailsResult.status === 'fulfilled') {
        dispatch(setSubscriptionAction(subscriptionDetailsResult.value));
        dispatch(getOrganizationRewards());
    } else {
        console.error(subscriptionDetailsResult.reason);
    }

    return true;
};

export const updateIntegrations = () => async (dispatch, getState) => {
    const zaplifySdk = getZaplifySdk();
    try {
        const zaplifyUser = await zaplifySdk.profiles.user.getUserByFirebaseId();

        if (zaplifyUser) {
            const apps = zaplifyUser.apps;
            dispatch(updateAppsAction(apps));
        }
    } catch (error) {
        console.log('error updating user credits');
    }
};

export const updateSubscription = () => async (dispatch, getState) => {
    try {
        dispatch(updateSubscriptionLoading(true));
        const details = await getZaplifySdk().profiles.subscription.fetchSusbscriptionDetails();
        if (details) {
            dispatch(setSubscriptionAction(details));
            dispatch(updateSubscriptionLoading(false));
        }
        return true;
    } catch (error) {
        dispatch(updateSubscriptionLoading(false));
        console.error('### error updating user subscription');
        return false;
    }
};

export const updateChannelAccountsState = (userId) => async (dispatch) => {
    const profilesSdk = getZaplifySdk().profiles;
    try {
        const channelAccountsFromDB = await profilesSdk.user.getChannelAccounts(userId);
        dispatch(setChannelAccountsAction(channelAccountsFromDB || []));
    } catch (error) {
        console.log('updateChannelAccountsState error');
    }
};

export const sendUserToCreateCampaignFlow = (navigate) => async (dispatch, getState) => {
    console.log('sendUserToCreateCampaignFlow');
    dispatch(updateNewCampaignName(`Campaign #1`));
    const campaignId = await dispatch(createOrUpdateName());
    window?.analytics?.track('User was redirected to create campaign flow');
    navigate(`${paths.EDIT_CAMPAIGN}/${campaignId}?step=${EditCampaignSteps.AddProspects}&view-welcome-screen=true`);
};

export const handleFreePlanSelection =
    ({ options: { idempotencyKey }, update = false }) =>
    async (dispatch) => {
        const profilesSdk = getZaplifySdk().profiles;

        try {
            await profilesSdk.billing.createFreeSubscription({ 'idempotency-key': idempotencyKey });

            dispatch(setCreatedFreeSubscriptionAction(true));

            if (update) {
                dispatch(refetchUserAndSubscription());
            }
        } catch (error) {
            dispatch(handleErrorNotification('Failed to start plan'));
        }
    };

export const handleSubscriptionChange = () => async (dispatch, getState) => {
    const paymentStatus = getState().ui.paymentStatus;
    console.log('handleSubscriptionChange', paymentStatus);
    if (paymentStatus === PaymentStatus.PROCESSING) {
        dispatch(updatePaymentStatus(PaymentStatus.SUCCESS));
    }
};
