import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

export const SideTextField = styled(({ className, children, ...props }) => (
    <TextField
        className={className}
        id={props.id}
        defaultValue={props.defaultValue}
        InputProps={props.InputProps}
        variant={props.variant || 'standard'}
    />
))(({ side, theme }) => ({
    '& > div': {
        borderRadius: side === 'left' ? '8px 0 0 8px' : '0 8px 8px 0',
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        ...(side === 'left' && { borderRight: 'none' }),
        fontSize: 16,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '& > input': {
            padding: '10.5px 14px',
            paddingRight: 50,
            textAlign: 'left',
            textOverflow: 'ellipsis',
        },
        '&:focus': {
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}));
