import { cn } from '@shadcn/ui/lib/utils';
import { Button } from '@shadcn/ui/components/ui/button';
import { Input } from '@shadcn/ui/components/ui/input';
import { Label } from '@shadcn/ui/components/ui/label';
import { Link } from 'react-router-dom';
import { Card, CardContent, CardDescription, CardTitle, CardHeader } from '@shadcn/ui/components/ui/card';
import { ReactComponent as Google } from '../../../../components/icons/google.svg';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../../../new/providers/authentication-provider';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { paths } from '../../../../routes/paths';

import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form, FormField, FormLabel, FormMessage } from '@shadcn/ui/components/ui/form';
import { FormControl } from '@shadcn/ui/components/ui/form';
import { FormItem } from '@shadcn/ui/components/ui/form';
import { Loader2 } from 'lucide-react';
import { GoogleAuthButton } from './google-auth-button';

const loginFormSchema = z.object({
    email: z.string().email('Invalid email address'),
    password: z.string().min(1, 'Password is required'),
});

type LoginFormValues = z.infer<typeof loginFormSchema>;

export function LoginForm({ className, ...props }: React.ComponentPropsWithoutRef<'div'>) {
    const { loading: userIsLoading } = useAuth();
    const [authErrorMessage, setAuthErrorMessage] = useState<string | null>(null);
    const form = useForm<z.infer<typeof loginFormSchema>>({
        resolver: zodResolver(loginFormSchema),
        defaultValues: {
            email: '',
            password: '',
        },
        mode: 'all',
    });

    const { mutate: signIn, status } = useMutation({
        mutationFn: async (data: LoginFormValues) => {
            return await signInWithEmailAndPassword(getAuth(), data.email, data.password);
        },
        onSuccess: (data) => {
            window?.analytics?.ready(() => {
                window?.analytics?.track('User Signed In', {
                    email: form.getValues('email'),
                });
            });
            // The redirect here is handled by the new token listener in the authentication provider
        },
        onError: (error: any) => {
            console.error('firebase error', error);
            setAuthErrorMessage('Invalid email or password');
        },
    });

    const onSubmit = (data: LoginFormValues) => {
        signIn(data);
    };

    return (
        <div className={cn('flex flex-col gap-6 w-full max-w-md mx-auto', className)} {...props}>
            <Card className="w-full hover:shadow-lg transition-shadow duration-300 focus-within:shadow-lg">
                <CardHeader className="text-center">
                    <CardTitle className="text-3xl mb-1">Welcome back!</CardTitle>
                    <CardDescription className="text-lg">Log in to your Andsend account</CardDescription>
                </CardHeader>
                <CardContent className="w-full">
                    <Form {...form}>
                        <div className="grid gap-6">
                            <div className="flex flex-col gap-4">
                                <GoogleAuthButton>Login with Google</GoogleAuthButton>
                            </div>
                            <div className="relative text-center text-sm after:absolute after:inset-0 after:top-1/2 after:z-0 after:flex after:items-center after:border-t after:border-border">
                                <span className="relative z-10 bg-background px-2 text-muted-foreground">
                                    Or continue with
                                </span>
                            </div>
                            <form onSubmit={form.handleSubmit(onSubmit)}>
                                <div className="grid gap-3">
                                    <FormField
                                        control={form.control}
                                        name="email"
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel>Email</FormLabel>
                                                <FormControl>
                                                    <Input
                                                        placeholder="name@work-email.com"
                                                        type="email"
                                                        autoComplete="email"
                                                        {...field}
                                                        disabled={userIsLoading}
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                    <FormField
                                        control={form.control}
                                        name="password"
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel>Password</FormLabel>
                                                <FormControl>
                                                    <Input
                                                        type="password"
                                                        autoComplete="current-password"
                                                        placeholder="Enter your password"
                                                        disabled={userIsLoading}
                                                        {...field}
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                    <div className="flex items-center">
                                        <Link
                                            to={paths.NEW_RESET_PASSWORD}
                                            className="ml-auto text-sm underline-offset-4 hover:underline"
                                            tabIndex={-1}
                                        >
                                            Forgot your password?
                                        </Link>
                                    </div>
                                    {authErrorMessage && (
                                        <div className="text-sm text-destructive">{authErrorMessage}</div>
                                    )}
                                    <Button
                                        type="submit"
                                        variant="default"
                                        disabled={!form.formState.isValid || status === 'pending' || userIsLoading}
                                        className="w-full mt-3"
                                    >
                                        {status === 'pending' ? (
                                            <>
                                                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                                Logging in...
                                            </>
                                        ) : (
                                            'Login with email'
                                        )}
                                    </Button>
                                </div>
                            </form>
                            <div className="text-center text-sm">
                                Don&apos;t have an account?{' '}
                                <Link to={paths.NEW_REGISTER} className="underline underline-offset-4 text-primary">
                                    Sign up
                                </Link>
                            </div>
                        </div>
                    </Form>
                </CardContent>
            </Card>
        </div>
    );
}
