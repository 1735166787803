import { Box, CircularProgress, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { memo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux-latest';
import { Link, useNavigate } from 'react-router-dom';
import { confirmPasswordReset } from '../../../redux/actions';
import { AppDispatch } from '../../../redux/store/configureStore';
import { paths } from '../../../routes/paths';
import { firebaseActionLink } from '../../../services/utils/urlHelper';
import FeedbackMessage from '../../../components/form/feedback-message';
import FormInputField from '../../../components/form/form-input-field';
import LoadingButton from '../../../components/form/loading-button';

type CreatePasswprdFormValues = {
    password: string;
};

const CreatePasswordForm = memo(() => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const [authErrorMessage, setAuthErrorMessage] = useState<string | null>(null);
    const { register, handleSubmit, formState } = useForm<CreatePasswprdFormValues>({
        defaultValues: {
            password: '',
        },
        mode: 'onChange',
    });
    const { errors } = formState;
    const { mutate: signIn, status } = useMutation({
        mutationFn: async (data: CreatePasswprdFormValues) => {
            await dispatch(confirmPasswordReset(firebaseActionLink.getCurrentActionCode(), data.password));
        },
        onSuccess: (data) => {
            navigate(paths.OLD_RESET_PASSWORD_COMPLETED);
        },
        onError: (error: any) => {
            setAuthErrorMessage(error.message || 'An error occurred');
        },
    });

    const onSubmit = (data: CreatePasswprdFormValues) => {
        signIn(data);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                <FormInputField<'password'>
                    id="password"
                    label="Password"
                    register={register('password', {
                        required: true,
                        minLength: {
                            value: 6,
                            message: 'Should contain at least 6 characters.',
                        },
                    })}
                    error={errors.password?.message}
                    placeholder="Enter your password"
                    type="password"
                    info="Should contain at least 6 characters."
                    endAdornment
                    isLoading={status === 'pending'}
                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginTop: '24px',
                    gap: '16px',
                }}
            >
                <LoadingButton
                    isLoading={status === 'pending'}
                    disabled={!formState.isValid || status === 'pending'}
                    type="submit"
                >
                    {status === 'pending' ? (
                        <CircularProgress size={24} sx={{ color: '#969FAC' }} />
                    ) : (
                        <Typography
                            component={'span'}
                            sx={{
                                fontSize: '14px',
                                fontWeight: 500,
                                lineHeight: '21px',
                                color: 'white',
                            }}
                        >
                            Change password
                        </Typography>
                    )}
                </LoadingButton>
                <FeedbackMessage id="create-password-form-feedback-message" error={authErrorMessage} info={''} />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '8px',
                    }}
                >
                    <Link to={paths.OLD_LOGIN} style={{ textDecoration: 'none' }}>
                        <Typography
                            component={'span'}
                            sx={{
                                color: '#6583EF',
                                fontSize: '14px',
                                fontWeight: 500,
                                lineHeight: '21px',
                            }}
                        >
                            Go back to Sign in
                        </Typography>
                    </Link>
                </Box>
            </Box>
        </form>
    );
});

export default CreatePasswordForm;
