import { convert as convertHtmlToText } from 'html-to-text';

export function htmlToPlain(inputString: string, options = {}) {
    // Check if there is any HTML in the input
    const isHtml = /<[a-z][\s\S]*>/i.test(inputString);
    if (!isHtml) {
        return inputString;
    }

    let html = inputString;
    // (End-section + Newline + New-section) should just be End-section + New-section
    html = html.replaceAll('</div><br><div>', '</div><div>');

    // (New-section + Newline + End-section) should just be Newline
    html = html.replaceAll('<div><br></div>', '<br>');

    const text = convertHtmlToText(html, { wordwrap: false, ...options })
        .replace(/ | | | | | | | | | /gi, ' ')
        .trim();
    return text;
}
