import { ChannelType } from '@zaplify/channel-accounts/shared';
import { MessageType } from '../enums';
import { DeliveryStatus } from '@zaplify/campaigns';

export class MessageDto {
    id: string;
    direction: string;
    conversationId?: string;
    channelAccountId?: string;
    campaignId?: string;
    prospectId?: string;
    fullName?: string;
    personId?: string;
    channelType?: ChannelType;
    actionType?: MessageType;
    contentType?: string;
    subjectLine?: string;
    content?: string;
    sentOn?: Date;
    scheduledOn?: Date;
    userId: string;
    organizationId: string;
    externalMessageId?: string;
    stepId?: string;
    linkedinUserId?: string;
    prospectFullName?: string;
    approvedAt?: Date;
    isSentFromApp?: boolean;
    assistantId?: string;
    deliveryStatus?: DeliveryStatus;
    createdAt: Date;
    updatedAt: Date;

    constructor(data: MessageDto) {
        this.id = data.id;
        this.direction = data.direction;
        this.conversationId = data.conversationId;
        this.channelAccountId = data.channelAccountId;
        this.campaignId = data.campaignId;
        this.prospectId = data.prospectId;
        this.fullName = data.fullName;
        this.personId = data.personId;
        this.channelType = data.channelType;
        this.actionType = data.actionType;
        this.contentType = data.contentType;
        this.subjectLine = data.subjectLine;
        this.content = data.content;
        this.sentOn = data.sentOn;
        this.scheduledOn = data.scheduledOn;
        this.userId = data.userId;
        this.organizationId = data.organizationId;
        this.externalMessageId = data.externalMessageId;
        this.stepId = data.stepId;
        this.linkedinUserId = data.linkedinUserId;
        this.prospectFullName = data.prospectFullName;
        this.approvedAt = data.approvedAt;
        this.isSentFromApp = data.isSentFromApp;
        this.assistantId = data.assistantId;
        this.deliveryStatus = data.deliveryStatus;
        this.createdAt = data.createdAt;
        this.updatedAt = data.updatedAt;
    }
}

export class MessageAttachmentDto {
    id: string;
    userId: string;
    messageId: string;
    type: MessageAttachmentType;
    value: string;
    createdAt: Date;

    constructor(data: MessageAttachmentDto) {
        this.id = data.id;
        this.userId = data.userId;
        this.messageId = data.messageId;
        this.type = data.type;
        this.value = data.value;
        this.createdAt = data.createdAt;
    }
}

export enum MessageAttachmentType {
    LINKEDIN_POST = 'LINKEDIN_POST',
    IMAGE = 'IMAGE',
}
