import { styled } from '@mui/styles';

export const TopContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    padding: '35px 52px 26px 72px',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.colors.greyInformationLight}`,
    '& > a > button': {
        border: '1px solid rgb(228, 230, 235)',
    },
    '& > div:nth-child(2)': {
        display: 'flex',
        alignItems: 'center',
        '& > div:nth-child(2)': {
            marginLeft: 10,
        },
        '& > div:nth-child(3)': {
            padding: '15px 20px',
            marginLeft: 20,
            border: `1px solid ${theme.palette.colors.greyInformationLight}`,
            boxSizing: 'border-box',
            borderRadius: 8,
        },
    },
}));

export const FieldMapHeaderContainer = styled('div')(({ theme }) => ({
    '& .text-container': {
        padding: '0px 0px 25px 0px',
    },
    '& .logos-container': {
        display: 'flex',
        width: '100%',
        padding: '20px 40px',
        alignItems: 'center',
        justifyContent: 'space-between',
        boxSizing: 'border-box',
        maxWidth: 408,
    },
}));

export const MapperContainer = styled('div')(({ theme }) => ({
    marginBottom: 15,
}));

export const FieldMappingContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    borderRight: `1px solid ${theme.palette.colors.greyInformationLight}`,
    padding: '26px 45px 30px 60px',
    width: '55%',
    boxSizing: 'border-box',
    '& .box-row': {
        marginBottom: 5,
        alignItems: 'center',
    },
    '& .refresh-container': {
        marginTop: '22px',
    },
}));

export const ConfigurationContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: '26px 45px 30px 60px',
    width: '45%',
    boxSizing: 'border-box',
    '& > div': {
        '& > label': {
            marginTop: 10,
            '& > span:nth-child(2)': {
                fontSize: 13,
                color: theme.palette.colors.subline,
            },
        },
    },
}));

export const FormContainer = styled('div')(({ theme }) => ({
    width: '50%',
    maxWidth: '231px',
}));

export const ActivityTypeContainer = styled('div')(({ theme }) => ({
    marginBottom: 5,
}));

export const LinkedInFormContainer = styled('div')(({ className, theme }) => ({
    marginBottom: 5,
    width: '100%',
    padding: '2px 10px',
    maxWidth: 461,
    '&.linkedin-closed': {
        background: theme.palette.colors.white,
        border: '0.8px solid rgb(196,196,196)',
        boxSizing: 'border-box',
        borderRadius: 8,
    },
}));

export const ProgressBarContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
}));
