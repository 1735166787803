import React from 'react';
import { Tooltip } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

type Props = {
    title?: string | any;
    tooltipWidth?: number | string;
    arrow?: boolean;
    children: React.ReactElement;
};

const TooltipCustom = ({ title, tooltipWidth, arrow, children }: Props) => {
    const useStyles = makeStyles(() => ({
        styles: () => ({
            width: tooltipWidth,
        }),
    }));

    const classes = useStyles();

    return (
        <Tooltip title={title} arrow={arrow} classes={{ tooltip: classes.styles }}>
            {children}
        </Tooltip>
    );
};

export default TooltipCustom;
