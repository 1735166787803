import { useSubscription, useQuery } from '@apollo/client';
import {
    GET_PROSPECT_MESSAGES_HISTORY,
    GET_PROSPECT_MESSAGES_HISTORY_SUBSCRIPTION,
    GET_PROSPECT_NOTES,
    GET_PROSPECT_NOTES_SUBSCRIPTION,
    GetProspectMessagesHistoryQuery,
    GetProspectNotesQuery,
} from '@zaplify/graphql';
import React, { createContext, useContext, useState, useMemo, useEffect } from 'react';
import { useToast } from '@shadcn/ui/hooks/use-toast';
import { useLinkedinMessages } from './use-linkedin-messages';
import { InvitationSendError, InvitationSendErrorReason } from '@zaplify/sdk';
import { useEmailMessages } from './use-email-messages';
import { MessageType } from '../../types/message-type';
import { useChatsSync } from '../use-chats-sync';
import { usePendingMessages } from './use-pending-messages';
import { ChannelType } from '../../types/channel-type';
import { useMutation, useQuery as useTanQuery } from '@tanstack/react-query';
import { useSdk } from '../../sdk';
import { useAuth } from '../../providers/authentication-provider';
import { useConnectionRequestsLimitReachedDialog } from '../../components/dialogs/connection-reqs-limit-reached-dialog';

type Message = GetProspectMessagesHistoryQuery['Messages'][number];

const log = (message: string) => {
    console.log(`🚀 [useMessages] ${message}`);
};

const logError = (error: string) => {
    console.error(`🚀 [useMessages] ${error}`);
};

export const actionTypeToChannelType = (actionType: string): ChannelType => {
    const lowerCaseActionType = actionType.toLowerCase();
    if (lowerCaseActionType.includes('linkedin')) {
        return ChannelType.LINKEDIN;
    } else if (lowerCaseActionType.includes('email')) {
        return ChannelType.EMAIL;
    }
    return ChannelType.LINKEDIN;
};

export class MessageNotSentError extends Error {
    readonly userDescription: string;
    constructor(message: string, messageRecipient?: string, options?: ErrorOptions) {
        super(message, options);
        this.name = 'MessageNotSentError';
        this.userDescription = message
            ? message
            : `We were unable to send your message${
                  messageRecipient ? ` to ${messageRecipient}` : ''
              }. Please try again.`;
    }
}
export class LinkedinGeneratedError<T = any> extends Error {
    readonly userDescription: string;
    readonly cause: T;
    constructor(message: string, customUserMessage: string, cause: T) {
        super(message);
        this.name = 'MessageSyncError';
        this.cause = cause;
        this.userDescription = customUserMessage;
    }
}

export class LinkedinGeneratedInvitationError extends LinkedinGeneratedError<InvitationSendError> {
    constructor(message: string, customUserMessage: string, cause: InvitationSendError) {
        super(message, customUserMessage, cause);
    }
}

export class MessageSyncError extends Error {
    readonly userDescription: string;
    constructor(message: string, messageType: MessageType, messageRecipient?: string, options?: ErrorOptions) {
        super(message, options);
        this.name = 'MessageSyncError';
        this.userDescription = `We were unable to send your message${
            messageRecipient ? ` to ${messageRecipient}` : ''
        }. ${
            messageType === MessageType.linkedinMessage
                ? 'Please check your LinkedIn account to see if the message was delivered.'
                : messageType === MessageType.emailMessage
                ? 'Please check your email account to see if the message was delivered.'
                : 'Please wait a bit and refresh the page before trying again.'
        }`;
    }
}

export function useMessages({ prospectId }: { prospectId: string }) {
    const { toast } = useToast();
    const { authState } = useAuth();
    const { setPingTrackersStatus } = useChatsSync();
    const { sendLinkedinConnectionRequest, sendLinkedinMessage } = useLinkedinMessages();
    const { sendEmailMessage } = useEmailMessages();
    const { openConnectionRequestsLimitReachedDialog } = useConnectionRequestsLimitReachedDialog();
    const [isSending, setIsSending] = useState(false);
    const { addPendingMessage, removePendingMessage, setPendingMessageError, watchPendingMessage } =
        usePendingMessages();
    const pendingMessage = watchPendingMessage(prospectId);

    const { data: messagesQueryData, refetch: refetchMessages } = useQuery(GET_PROSPECT_MESSAGES_HISTORY, {
        variables: {
            prospectId: prospectId,
        },
        skip: !prospectId,
    });

    const { data: notesQueryData, refetch: refetchNotes } = useQuery(GET_PROSPECT_NOTES, {
        variables: {
            prospectId: prospectId,
        },
        skip: !prospectId,
    });

    useSubscription(GET_PROSPECT_MESSAGES_HISTORY_SUBSCRIPTION, {
        variables: {
            prospectId: prospectId,
        },
        skip: !prospectId,
        onData: () => {
            refetchMessages();
        },
    });

    useSubscription(GET_PROSPECT_NOTES_SUBSCRIPTION, {
        variables: {
            prospectId: prospectId,
        },
        skip: !prospectId,
        onData: () => {
            refetchNotes();
        },
    });

    const {
        notes: { createNote: createNoteMutation, deleteNote: deleteNoteMutation },
        trackers: { getLinkedinCursorToSyncFrom },
    } = useSdk();
    const { data: linkedinCursorToSyncFrom } = useTanQuery(getLinkedinCursorToSyncFrom());
    const { mutateAsync: createNote } = useMutation(createNoteMutation({ onSuccess: refetchNotes }));
    const { mutateAsync: deleteNote } = useMutation(deleteNoteMutation({ onSuccess: () => refetchNotes() }));

    const messages = useMemo(() => messagesQueryData?.Messages || [], [messagesQueryData?.Messages]);
    const notes = useMemo(() => notesQueryData?.Notes || [], [notesQueryData?.Notes]);

    const messagesAndNotes = useMemo(
        () =>
            [...messages, ...notes].sort((a, b) => {
                const dateA = a.__typename === 'Messages' ? a.sentOn : a.happenedOn || a.createdAt;
                const dateB = b.__typename === 'Messages' ? b.sentOn : b.happenedOn || b.createdAt;
                return new Date(dateA).getTime() - new Date(dateB).getTime();
            }),
        [messages, notes]
    );

    useEffect(() => {
        removePendingMessage(prospectId);
    }, [messages]);

    const addNote = async ({ content, title }: { content: string; title?: string }) => {
        try {
            await createNote({
                userId: authState.user.id,
                prospectId: prospectId,
                content,
                title: title || '',
            });
        } catch (error) {
            logError(`addNote | error: ${JSON.stringify(error)}`);
            toast({
                title: 'An error occurred while adding the note. Please try again.',
                variant: 'error',
            });
        }
    };

    const removeNote = async ({ noteId }: { noteId: string }) => {
        try {
            await deleteNote({ noteId });
            toast({
                title: 'Note removed successfully',
                variant: 'success',
            });
        } catch (error) {
            logError(`deleteNote | error: ${JSON.stringify(error)}`);
            toast({
                title: 'An error occurred while removing the note. Please try again.',
                variant: 'error',
            });
        }
    };

    const createPendingMessage = ({
        body,
        subjectLine,
        actionType,
        prospectName,
        prospectId,
        messageId,
    }: {
        body: string;
        subjectLine: string;
        actionType: MessageType;
        prospectName: string;
        prospectId: string;
        messageId: string | null;
    }): Message => {
        return {
            content: body,
            sentOn: new Date(),
            actionType: actionType,
            direction: 'out',
            prospectFullName: prospectName,
            prospectId,
            id: messageId || '',
            channelType: actionTypeToChannelType(actionType),
            externalMessageId: null,
            subjectLine: actionType === MessageType.emailMessage ? subjectLine : null,
            deliveryStatus: 'PENDING',
            fullName: 'You',
            Attachments: [],
            __typename: 'Messages',
            contentType: actionTypeToChannelType(actionType) === ChannelType.EMAIL ? 'html' : 'text',
        };
    };

    const sendMessage = async ({
        message,
        recipient,
        onError,
        suggestedChannelType,
    }: {
        message: {
            body: string;
            subjectLine?: string;
            actionType: MessageType;
            messageId?: string;
        };
        recipient: {
            prospectId: string;
            prospectName: string;
            linkedinUserId: string;
        };
        onError: () => void;
        suggestedChannelType?: ChannelType;
    }) => {
        setIsSending(true);
        setTimeout(() => {
            setIsSending(false);
        }, 3000);

        setPingTrackersStatus({
            status: 'paused',
            timestamp: Date.now(),
        });
        const messageToSend = createPendingMessage({
            body: message.body,
            subjectLine: message.subjectLine,
            actionType: message.actionType,
            prospectName: recipient.prospectName,
            prospectId: recipient.prospectId,
            messageId: message.messageId,
        });
        try {
            addPendingMessage(messageToSend, messageToSend.prospectId);
            setTimeout(() => {
                removePendingMessage(messageToSend.prospectId);
            }, 10_000);

            await handleSendMessage(messageToSend, recipient, suggestedChannelType);
            log('Sent message successfully');
        } catch (error: unknown) {
            log(`sendMessage | error: ${JSON.stringify(error)}`);
            setPendingMessageError(messageToSend.prospectId);
            handleSendMessageError(error, messageToSend, onError);
        } finally {
            setPingTrackersStatus({
                status: 'active',
                timestamp: Date.now(),
            });
        }
    };

    const handleSendMessage = async (
        message: Message,
        recipient: { prospectName: string; linkedinUserId: string },
        suggestedChannelType?: ChannelType
    ) => {
        log('handleSendMessage for' + message.actionType + ' message with outreachSuggestionId: ' + message.id);

        switch (message.actionType) {
            case MessageType.emailMessage:
                await sendEmailMessage(message, suggestedChannelType);
                break;
            case MessageType.linkedinMessage:
                await sendLinkedinMessage(recipient, message, false);
                break;
            case MessageType.linkedinConnectionRequest:
                await sendLinkedinConnectionRequest(recipient, message);
                break;
            default:
                throw new MessageNotSentError('Invalid message type', message.prospectFullName);
        }
    };

    const handleSendMessageError = (error: unknown, message: any, onError: () => void) => {
        if (
            error instanceof MessageNotSentError ||
            error instanceof MessageSyncError ||
            error instanceof LinkedinGeneratedError
        ) {
            const parsedError = error as MessageNotSentError | MessageSyncError | LinkedinGeneratedError;
            if (parsedError instanceof MessageNotSentError || parsedError instanceof LinkedinGeneratedError) {
                console.error({
                    error,
                    errorCause: (error as Error)?.cause,
                    pendingMessage: message,
                    message: 'An error occurred when trying to send a message.',
                });
                onError();

                if (parsedError instanceof LinkedinGeneratedInvitationError) {
                    const invitationError = parsedError as LinkedinGeneratedInvitationError;
                    if (
                        invitationError?.cause?.reason === InvitationSendErrorReason.MAX_CONNECTIONS_WITH_MESSAGE_SENT
                    ) {
                        openConnectionRequestsLimitReachedDialog();
                        return;
                    }
                }
            }

            toast({
                title: parsedError.userDescription,
                variant: 'error',
            });
            return;
        }

        toast({
            title: "An error occurred while sending the message. Please verify that the message wasn't sent before trying again.",
            variant: 'error',
        });
    };

    return {
        sendMessage,
        addNote,
        removeNote,
        messages: pendingMessage ? [...(messagesAndNotes || []), pendingMessage] : messagesAndNotes || [],
        isSending,
        linkedinMessagesLastSyncedAt: linkedinCursorToSyncFrom?.MESSAGING_SCAN,
    };
}
