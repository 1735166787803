import { IQuery } from '@zaplify/common';
import { Topics } from '@zaplify/common';
import { OrganizationDto } from '../users';

export class GetUserOrganizationByDomainQuery extends IQuery<OrganizationDto> {
    public static override readonly TOPIC = Topics.USERS;
    constructor(readonly domain: string) {
        super();
    }
}
