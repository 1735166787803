import { FC, useState } from 'react';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@shadcn/ui/components/ui/dialog';
import { Button } from '@shadcn/ui/components/ui/button';

interface RenewalSubscriptionDialogProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => Promise<void>;
    renewalDate: string;
}

export const RenewalSubscriptionDialog: FC<RenewalSubscriptionDialogProps> = ({
    isOpen,
    onClose,
    onConfirm,
    renewalDate,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Renew Subscription</DialogTitle>
                    <DialogDescription>
                        Your subscription is currently set to cancel on {renewalDate}. Would you like to renew your
                        subscription?
                    </DialogDescription>
                </DialogHeader>
                <DialogFooter>
                    <Button variant="outline" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            setIsLoading(true);
                            onConfirm()
                                .then(() => {
                                    setIsLoading(false);
                                })
                                .catch(() => {
                                    setIsLoading(false);
                                });
                        }}
                        disabled={isLoading}
                    >
                        {isLoading ? 'Renewing...' : 'Renew Subscription'}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};
