import { useQuery } from '@apollo/client';
import { playbookImages } from './playbook-images';
import { GET_PLAYBOOKS } from '@zaplify/graphql';
import { useEffect, useMemo, useState } from 'react';

export const usePlaybookImages = () => {
    const { data: allPlaybooksData, loading } = useQuery(GET_PLAYBOOKS, { variables: { name: '%' } });

    const allPlaybooks = useMemo(() => allPlaybooksData?.Groups, [allPlaybooksData]);

    const [unusedImgSrc, setUnusedImgSrc] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (loading) return;
        const availableImages = playbookImages
            .map((image) => image)
            .filter((src) => !allPlaybooks.some((group) => group.imgSrc === src));

        if (availableImages.length > 0) {
            setUnusedImgSrc(availableImages[0]);
        } else {
            setUnusedImgSrc(playbookImages[allPlaybooks.length % playbookImages.length]);
        }
    }, [allPlaybooks]);

    return {
        unusedImgSrc,
    };
};
