import { Button, Popper, Typography } from '@mui/material';
import { styled } from '@mui/styles';

export const Tooltip = (props) => (
    <Popper open={props.open} anchorEl={props.anchor}>
        <TooltipContainer>
            <Typography>{props.children}</Typography>
            <Button onClick={() => props.onClick}>Close</Button>
        </TooltipContainer>
    </Popper>
);

export const TooltipContainer = styled('div')(({ theme }: any) => ({
    background: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'column',
}));
