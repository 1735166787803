import { UseMutationOptions } from '@tanstack/react-query';
import { getZaplifySdk } from '@zaplify/sdk';
import { LinkedinConversationsResponse } from '@zaplify/web-extension-shared';

const trackFoundLinkedinConversationsFn = (userId: string, memberId: string, data: LinkedinConversationsResponse) => {
    return getZaplifySdk().profiles.linkedinConversations.trackFoundLinkedinConversations(userId, memberId, data);
};
type TrackFoundLinkedinConversationsData = Awaited<ReturnType<typeof trackFoundLinkedinConversationsFn>>;
export const trackFoundLinkedinConversations = (
    options?: Omit<
        UseMutationOptions<
            TrackFoundLinkedinConversationsData,
            Error,
            { userId: string; memberId: string; data: LinkedinConversationsResponse }
        >,
        'mutationKey' | 'mutationFn'
    >
): UseMutationOptions<
    TrackFoundLinkedinConversationsData,
    Error,
    { userId: string; memberId: string; data: LinkedinConversationsResponse }
> => {
    return {
        mutationFn: (data) => trackFoundLinkedinConversationsFn(data.userId, data.memberId, data.data),
        ...options,
    };
};
