import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '@shadcn/ui/components/ui/dropdown-menu';
import { CircleXIcon } from 'lucide-react';

const LinkEditMenu = ({ editor, linkState, closeLinkPopover, handleLinkChange, handleKeyDown }) => {
    return (
        <DropdownMenu open={Boolean(linkState.anchor)} onOpenChange={closeLinkPopover}>
            <DropdownMenuTrigger asChild>
                <div ref={linkState.anchor} />
            </DropdownMenuTrigger>
            <DropdownMenuContent
                side="bottom"
                align="center"
                style={{
                    borderRadius: '8px',
                    boxShadow: '0px 8px 16px 0px #1E243014, 0px 1px 6px 0px #1E24301A',
                    padding: '0.5rem',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <input
                    value={linkState.url}
                    onChange={handleLinkChange}
                    onKeyDown={handleKeyDown}
                    style={{
                        height: '30px',
                        overflowX: 'scroll',
                        outline: 'none',
                        border: 'none',
                        flex: 1,
                        marginRight: '8px',
                    }}
                />
                <CircleXIcon
                    onClick={() => {
                        editor.chain().focus().unsetLink().run();
                        closeLinkPopover();
                    }}
                    strokeWidth={3}
                    size={14}
                    color="#23323F"
                    cursor={'pointer'}
                />
            </DropdownMenuContent>
        </DropdownMenu>
    );
};

export default LinkEditMenu;
