import React, { forwardRef } from 'react';
import { replaceNewLinesWithBreaks } from '../../../../functions/replace-new-lines-with-breaks';
import { convertLinksToAnchors } from '../../../../functions/convert-links-to-anchors';
import { useEffect, useRef, useState } from 'react';
import { GetProspectMessagesHistoryQuery } from '@zaplify/graphql';
import { MessageAttachmentType } from '@zaplify/services/messaging/shared';
import { ShadowContent } from './shadow-content';
import { cn } from '@shadcn/ui/lib/utils';
import { UserRoundPlus } from 'lucide-react';
import { Dialog, DialogContent } from '@shadcn/ui/components/ui/dialog';

type MessageContentProps = {
    subjectLine?: string;
    content: string;
    channelType?: 'EMAIL' | 'LINKEDIN';
    attachments?: GetProspectMessagesHistoryQuery['Messages'][0]['Attachments'];
    isNote?: boolean;
    actionType: GetProspectMessagesHistoryQuery['Messages'][0]['actionType'];
    backgroundColor?: string;
    className?: string;
};

export const MessageContent = forwardRef<HTMLDivElement, MessageContentProps>(({ className, ...message }, ref) => {
    const cleanedText = replaceNewLinesWithBreaks(message.content || '');
    const textWithLinks = convertLinksToAnchors(cleanedText);
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [isOverflowing, setIsOverflowing] = useState(false);
    const contentRef = useRef<HTMLDivElement>(null);
    const collapsedHeight = 500;
    const imageAttachments = message.attachments?.filter(
        (attachment) => attachment?.type === MessageAttachmentType.IMAGE
    );
    const linkedinPostAttachments = message.attachments?.filter(
        (attachment) => attachment?.type === MessageAttachmentType.LINKEDIN_POST
    );
    const [selectedImage, setSelectedImage] = useState<string | null>(null);

    useEffect(() => {
        const checkOverflow = () => {
            if (contentRef.current) {
                setIsOverflowing(contentRef.current.scrollHeight > collapsedHeight);
            }
        };

        checkOverflow();

        window.addEventListener('resize', checkOverflow);
        return () => {
            window.removeEventListener('resize', checkOverflow);
        };
    }, [cleanedText]);

    return (
        <div className={cn('flex flex-col gap-1 max-w-full')}>
            {message.actionType === 'LINKEDIN_CONNECTION_REQUEST' && (
                <div className="flex gap-1 items-center">
                    <UserRoundPlus className="h-4 w-4 text-text-brand-primary-subtle" />
                    <p className="text-sm text-text-brand-primary-subtle">Connection Request</p>
                </div>
            )}
            {message.channelType !== 'LINKEDIN' && message.subjectLine?.length > 0 && (
                <p
                    className={cn(
                        'text-sm font-medium w-full border-b pb-1',
                        message.isNote ? 'border-border-note' : 'border-border-primary'
                    )}
                >
                    {message.subjectLine}
                </p>
            )}
            <ShadowContent
                className={cn('text-sm break-words max-w-full', isCollapsed ? 'max-h-[500px] overflow-hidden' : '')}
                content={textWithLinks}
                ref={contentRef}
            />
            {isCollapsed && isOverflowing && (
                <button
                    className="text-sm text-text-brand-primary-subtle hover:underline"
                    onClick={() => setIsCollapsed(false)}
                >
                    Show more
                </button>
            )}
            {imageAttachments?.map((attachment) => (
                <img
                    src={attachment?.value}
                    className="max-w-full w-auto h-[160px] object-contain cursor-pointer"
                    onClick={() => setSelectedImage(attachment?.value || null)}
                />
            ))}
            <Dialog open={!!selectedImage} onOpenChange={() => setSelectedImage(null)}>
                <DialogContent className="max-w-screen-lg w-full p-0">
                    <img
                        src={selectedImage || ''}
                        className="w-full h-full object-contain"
                        onClick={() => setSelectedImage(null)}
                    />
                </DialogContent>
            </Dialog>
            {linkedinPostAttachments?.map((attachment) => (
                <div
                    key={attachment?.value}
                    className="flex flex-col gap-1 text-sm hover:underline text-text-brand-primary-subtle"
                >
                    <a href={attachment?.value} target="_blank" rel="noreferrer">
                        Shared LinkedIn post
                    </a>
                </div>
            ))}
        </div>
    );
});
