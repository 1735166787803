import Link from '@mui/material/Link';
import React, { MouseEvent } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface GuardedAnchorProps {
    href: string;
    [x: string]: any; // for other props like 'className', 'style', etc.
}

export const GuardedAnchor: React.FC<GuardedAnchorProps> = ({ href, ...props }) => {
    const { externalLinkWarning } = useFlags();
    const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
        try {
            const url = new URL(href, window.location.origin);
            if (url.hostname !== window.location.hostname && externalLinkWarning) {
                const userConfirmed = window.confirm('You will be redirected to an external resource. Continue?');
                if (!userConfirmed) {
                    event.preventDefault();
                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    return <Link href={href} {...props} onClick={handleClick} />;
};
