import { ScrollArea } from '@shadcn/ui/components/ui/scroll-area';
import { Button } from '@shadcn/ui/components/ui/button';
import {
    Briefcase,
    Building2,
    FileText,
    Info,
    Linkedin,
    Mail,
    MessagesSquare,
    Pen,
    Phone,
    Sparkles,
    Tag,
    Trash,
    Users,
    X,
} from 'lucide-react';
import { cn } from '@shadcn/ui/lib/utils';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Suspense, useMemo, useState } from 'react';
import { User, MapPin } from 'lucide-react';
import { Tooltip, TooltipTrigger, TooltipContent } from '@shadcn/ui/components/ui/tooltip';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@shadcn/ui/components/ui/accordion';
import { HubspotIcon } from '@shadcn/ui/components/icons/integrations/hubspot-icon';
import { SalesforceIcon } from '@shadcn/ui/components/icons/integrations/salesforce-icon';
import { PipedriveIcon } from '@shadcn/ui/components/icons/integrations/pipedrive-icon';
import { Skeleton } from '@shadcn/ui/components/ui/skeleton';
import { PlaybookBadge } from '../playbook-badge';
import { getEmailState } from '../../functions/email-state';
import { BriefcaseClock } from './briefcase-clock';
import { WorkExperience } from './work-experience';
import { CompanyTag } from './company-tag';
import { Separator } from '@shadcn/ui/components/ui/separator';
import { useEditContactDetailsDialog } from '../dialogs/edit-contact-details-dialog';
import { ContactOwnersAvatarDisplay } from '../dialogs/global-search/contact-owners-avatar-display';
import { useDeleteContactDialog } from '../dialogs/delete-contact-dialog';
import { useSdk } from '../../sdk';
import { useQuery as useTanQuery, useSuspenseQuery as useTanSuspenseQuery } from '@tanstack/react-query';
import { ProspectDataExperienceDto } from '@zaplify/prospects';
import { ErrorBoundaryNew } from '../../../views/chats/components/error-boundary/error-boundary';
import { getProspectComposedById } from '../../sdk/internal/prospect.sdk';
import { Badge } from '@shadcn/ui/components/ui/badge';
import { EditButton } from './edit-button';
import { useSidebars } from '@shadcn/ui/hooks/use-sidebars';
import { Image, ImageFallback, ImageRoot } from '@shadcn/ui/components/image';
import { useAccountDetailsDialog } from '../dialogs/account-details-dialog';
import { useQuery } from '@apollo/client';
import { GET_USER_CONTACT_BY_PROSPECT_ID } from '@zaplify/graphql';
import { paths } from '../../../routes/paths';
import { UserMemoryList } from '../user-memory-list';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface ContactDetailsSidebarProps {
    isOpen: boolean;
    onClose: () => void;
    className?: string;
}

export const ContactDetailsSidebarSkeleton = ({
    isOpen,
    onClose,
    className,
    direction = 'right',
}: ContactDetailsSidebarProps & { direction?: 'left' | 'right' }) => {
    return (
        <div
            className={cn(
                'bg-background-primary flex flex-col h-full transition-transform duration-300 ease-in-out min-w-full max-w-full rounded-[inherit]',
                direction === 'right' ? 'border-l' : 'border-r',
                direction === 'right'
                    ? isOpen
                        ? 'translate-x-0'
                        : 'translate-x-full'
                    : isOpen
                    ? 'translate-x-0'
                    : '-translate-x-[50%]',
                className
            )}
        >
            <div className="p-4 border-b flex justify-between items-center h-14 z-20 w-full bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
                <span className="font-medium text-md">Details</span>
                <Button variant="ghost" size="icon" onClick={onClose}>
                    <X className="h-4 w-4" />
                </Button>
            </div>
            <ScrollArea className="flex-1">
                <Accordion
                    type="multiple"
                    className="w-full"
                    defaultValue={[
                        'playbook',
                        'personal-details',
                        'contact-details',
                        'company',
                        'work-experience',
                        'manage',
                    ]}
                >
                    <AccordionItem value="playbook" className="max-w-full">
                        <AccordionTrigger className="p-4">
                            <Tooltip delayDuration={300}>
                                <TooltipTrigger asChild>
                                    <div className="flex gap-1 items-center">
                                        Playbook
                                        <Info className="h-4 w-4 stroke-[1]" />
                                    </div>
                                </TooltipTrigger>
                                <TooltipContent>
                                    This playbook provides the guidelines for messages with this contact
                                </TooltipContent>
                            </Tooltip>
                        </AccordionTrigger>
                        <AccordionContent className="px-4 max-w-full">
                            <TextSkeleton />
                        </AccordionContent>
                    </AccordionItem>

                    <AccordionItem value="personal-details">
                        <AccordionTrigger className="p-4">Personal details</AccordionTrigger>
                        <AccordionContent className="px-4">
                            <div className="flex flex-col gap-3">
                                <div className="flex items-start gap-3">
                                    <User className="h-5 w-5 stroke-[1]" />
                                    <TextSkeleton />
                                </div>
                                <div className="flex items-start gap-3">
                                    <Briefcase className="h-5 w-5 stroke-[1]" />
                                    <TextSkeleton />
                                </div>
                                <div className="flex items-start gap-3">
                                    <Building2 className="h-5 w-5 stroke-[1]" />
                                    <TextSkeleton />
                                </div>
                                <div className="flex items-start gap-3">
                                    <MapPin className="h-5 w-5 stroke-[1]" />
                                    <TextSkeleton />
                                </div>
                            </div>
                        </AccordionContent>
                    </AccordionItem>

                    <AccordionItem value="contact-details">
                        <AccordionTrigger className="p-4">Contact details</AccordionTrigger>
                        <AccordionContent className="px-4 max-w-full">
                            <div className="flex flex-col gap-3">
                                <div className="flex items-start gap-3">
                                    <Linkedin className="h-5 w-5 stroke-[1]" />
                                    <TextSkeleton />
                                </div>
                                <div className="flex items-start gap-3">
                                    <Mail className="h-5 w-5 stroke-[1]" />
                                    <TextSkeleton />
                                </div>
                            </div>
                        </AccordionContent>
                    </AccordionItem>

                    <AccordionItem value="company">
                        <AccordionTrigger className="p-4">Company</AccordionTrigger>
                        <AccordionContent className="px-4">
                            <div className="flex flex-col gap-3">
                                <div className="flex items-start gap-3">
                                    <Building2 className="h-5 w-5 stroke-[1]" />
                                    <TextSkeleton />
                                </div>
                                <div className="flex items-start gap-3">
                                    <Users className="h-5 w-5 stroke-[1]" />
                                    <TextSkeleton />
                                </div>
                                <div className="flex items-start gap-3">
                                    <FileText className="h-5 w-5 stroke-[1]" />
                                    <div className="flex flex-col gap-0 flex-1">
                                        <TextSkeleton />
                                    </div>
                                </div>
                                {/* {account?.data?.specialties?.length > 0 && (
                                    <div className="flex items-start gap-3">
                                        <Tag className="h-5 w-5 stroke-[1]" />
                                        <div className="flex flex-col gap-0 flex-1">
                                            <div className="flex flex-row flex-wrap gap-1 flex-1">
                                                {account?.data?.specialties
                                                    .slice(
                                                        0,
                                                        showAllSpecialties
                                                            ? account?.data?.specialties.length
                                                            : defaultShowNumberOfSpecialties
                                                    )
                                                    .map((specialty, index) => (
                                                        <CompanyTag key={index}>{specialty}</CompanyTag>
                                                    ))}
                                            </div>
                                            <div>
                                                <Button
                                                    variant="text"
                                                    className="p-0"
                                                    onClick={() => setShowAllSpecialties(!showAllSpecialties)}
                                                >
                                                    {showAllSpecialties ? 'Show less' : 'Show more'}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                )} */}
                            </div>
                        </AccordionContent>
                    </AccordionItem>

                    <AccordionItem value="work-experience">
                        <AccordionTrigger className="p-4">Work experience</AccordionTrigger>
                        <AccordionContent className="px-4">
                            <div className="flex flex-col gap-3">
                                <div className="flex items-start gap-3">
                                    <BriefcaseClock />
                                    {/* <div className="flex flex-col gap-3">
                                        {sortedExperiencesWithCurrentOrgFirst
                                            ?.slice(0, showAllExperiences ? 12 : defaultShowNumberOfExperiences)
                                            .map((experience, index) => (
                                                <div key={index} className="'transition-all duration-300 ease-in-out',">
                                                    <WorkExperience key={index} experience={experience} />
                                                </div>
                                            ))}

                                        <div>
                                            <Button
                                                variant="text"
                                                className="p-0"
                                                onClick={() => setShowAllExperiences(!showAllExperiences)}
                                            >
                                                {showAllExperiences ? 'Show less' : 'Show more'}
                                            </Button>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </AccordionContent>
                    </AccordionItem>

                    <AccordionItem value="manage">
                        <AccordionTrigger className="p-4">Manage</AccordionTrigger>
                        <AccordionContent className="px-4">
                            {/* <Button
                                variant="text"
                                className="p-1 text-text-error-primary hover:text-text-error-primary/80"
                                onClick={() => openDeleteContactDialog(contact.id)}
                            >
                                <Trash className="h-4 w-4" />
                                Delete contact
                            </Button> */}
                        </AccordionContent>
                    </AccordionItem>
                </Accordion>
            </ScrollArea>
        </div>
    );
};

function moveCurrentOrgToFront(experiences: ProspectDataExperienceDto[], currentOrganization?: string) {
    if (!currentOrganization) return experiences;
    const currentOrgIndex = experiences.findIndex(
        (experience) => experience.organizationName?.toLowerCase() === currentOrganization?.toLowerCase()
    );
    if (currentOrgIndex === -1) return experiences;
    const currentOrg = experiences[currentOrgIndex];
    experiences.splice(currentOrgIndex, 1);
    experiences.unshift(currentOrg);
    return experiences;
}

const getEmailTooltip = (email: string): string => {
    const emailState = getEmailState(email);
    let emailText = email?.toLowerCase();
    if (emailState === 'Email missing') {
        emailText = 'We could not find this email address. Enter it manually.';
    } else if (emailState === 'Enrichment') {
        emailText =
            'Email is still enriching, this could take a day or two. You can always snooze the contact for later.';
    }
    return emailText;
};

const getEmailText = (email: string): string => {
    const emailState = getEmailState(email);
    let emailText = email?.toLowerCase();
    if (emailState === 'Email missing') {
        emailText = 'Not found';
    } else if (emailState === 'Enrichment') {
        emailText = 'Looking for email...';
    }
    return emailText;
};

function getProspectExperiencesSorted(a: ProspectDataExperienceDto, b: ProspectDataExperienceDto) {
    if (!a.occupationEndDate && !b.occupationEndDate) {
        return new Date(b.occupationStartDate || 0).valueOf() - new Date(a.occupationStartDate || 0).valueOf();
    }
    if (!a.occupationEndDate) {
        return -1;
    }
    if (!b.occupationEndDate) {
        return 1;
    }
    return new Date(b.occupationEndDate || 0).valueOf() - new Date(a.occupationEndDate || 0).valueOf();
}

const TextSkeleton = () => <Skeleton className="h-5 w-32 bg-background-secondary" />;

const CRMDetails = () => {
    const { prospectId } = useParams();
    const { data: contactComposed } = useTanQuery(getProspectComposedById(prospectId));
    if (!contactComposed) return null;

    const connectedApps = [];
    if (contactComposed?.apps?.hubSpot) connectedApps.push({ type: 'HubSpot', data: contactComposed.apps.hubSpot });
    if (contactComposed?.apps?.pipedrive)
        connectedApps.push({ type: 'Pipedrive', data: contactComposed.apps.pipedrive });
    if (contactComposed?.apps?.salesforce)
        connectedApps.push({ type: 'Salesforce', data: contactComposed.apps.salesforce });
    if (contactComposed?.apps?.upsales) connectedApps.push({ type: 'Upsales', data: contactComposed.apps.upsales });

    if (connectedApps.length === 0) return null;

    return (
        <div className="flex flex-col gap-3">
            {connectedApps.map((app) => (
                <Tooltip key={app.type} delayDuration={300}>
                    <TooltipTrigger asChild>
                        <div className="flex items-start gap-3">
                            {app.type === 'HubSpot' && <HubspotIcon className="w-6 -mr-1 -mb-1" />}
                            {app.type === 'Pipedrive' && <PipedriveIcon className="w-6 -mr-1 -mb-1" />}
                            {app.type === 'Salesforce' && <SalesforceIcon className="w-6 -mr-1 -mb-1" />}
                            <a
                                href={app.data.url}
                                target="_blank"
                                rel="noreferrer"
                                className="text-sm line-clamp-1 flex-1 break-all hover:underline"
                            >
                                {app.data.url?.replace(/(https:\/\/)?(www\.)?/i, '')}
                            </a>
                        </div>
                    </TooltipTrigger>
                    <TooltipContent>Go to {app.type} profile</TooltipContent>
                </Tooltip>
            ))}
        </div>
    );
};

export const ContactDetailsSidebar = ({ className }: { className?: string }) => {
    const { sidebarState, toggleSidebar } = useSidebars();
    const { prospectId } = useParams();
    const isExpanded = sidebarState?.details;

    if (!prospectId) return null;
    return (
        <div
            className={cn(
                'flex transition-[width] duration-300 ease-in-out overflow-hidden max-w-full md:max-w-[280px]',
                className,
                isExpanded ? 'w-full md:w-[280px]' : 'w-0'
            )}
        >
            <Suspense
                fallback={
                    <ContactDetailsSidebarSkeleton isOpen={isExpanded} onClose={() => toggleSidebar('details')} />
                }
            >
                <ContactDetailsSidebarComponent isOpen={isExpanded} onClose={() => toggleSidebar('details')} />
            </Suspense>
        </div>
    );
};

export const ContactDetailsSidebarComponent = ({
    isOpen,
    onClose,
    className,
    direction = 'right',
}: ContactDetailsSidebarProps & { direction?: 'left' | 'right' }) => {
    const { prospectId } = useParams();
    const location = useLocation();
    const { userMemoryEnabled } = useFlags();
    const { openDeleteContactDialog } = useDeleteContactDialog();
    const { openEditContactDetailsDialog } = useEditContactDetailsDialog();
    const { openAccountDetailsDialog } = useAccountDetailsDialog();
    const [showAllExperiences, setShowAllExperiences] = useState(false);
    const [showCompleteAboutUs, setShowCompleteAboutUs] = useState(false);
    const [showAllSpecialties, setShowAllSpecialties] = useState(false);
    const {
        prospect: { getProspectById },
        account: { getAccountById },
    } = useSdk();
    const { data: contact, error: prospectError } = useTanQuery(
        prospectId ? getProspectById(prospectId) : { queryKey: [], queryFn: () => Promise.resolve(null) }
    );
    const { data: account, error: accountError } = useTanQuery(
        getAccountById(
            contact?.data?.organizationId ||
                contact?.data?.organizationLinkedinUrl?.split('/')?.[3] ||
                contact?.data?.organizationName
        )
    );
    const { data: userContactData } = useQuery(GET_USER_CONTACT_BY_PROSPECT_ID, {
        variables: { prospectId },
        skip: !prospectId,
    });

    const sortedExperiencesWithCurrentOrgFirst = useMemo(() => {
        if (!contact?.data) return [];
        const { experiences, organizationName } = contact.data;
        const sortedExperiences = [...experiences].sort(getProspectExperiencesSorted);
        return moveCurrentOrgToFront(sortedExperiences, organizationName);
    }, [contact?.data]);

    const numberOfOngoingExperiences = sortedExperiencesWithCurrentOrgFirst?.filter(
        (experience) => !experience.occupationEndDate
    ).length;

    const defaultShowNumberOfExperiences = 2;
    const defaultShowNumberOfSpecialties = 2;

    return (
        <div
            className={cn(
                'bg-background-primary flex flex-col h-full transition-transform duration-200 ease-in-out min-w-full rounded-[inherit]',
                direction === 'right' ? 'border-l' : 'border-r',
                direction === 'right'
                    ? isOpen
                        ? 'translate-x-0'
                        : 'translate-x-full'
                    : isOpen
                    ? 'translate-x-0'
                    : '-translate-x-[50%]',
                className
            )}
        >
            <div className="p-4 border-b flex justify-between items-center h-14 z-20 w-full bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60 rounded-tr-[inherit]">
                <span className="font-medium text-md">Details</span>
                <Button variant="ghost" size="icon" onClick={onClose}>
                    <X className="h-4 w-4" />
                </Button>
            </div>
            <ScrollArea className="flex-1 [&>div]:overscroll-contain">
                {contact && location.pathname.includes('contacts') && (
                    <>
                        <div className="p-4">
                            <Link to={`${paths.NEW.MESSAGES}/${prospectId}`}>
                                <Button variant="outline" className="w-full">
                                    <MessagesSquare className="h-4 w-4" />
                                    Go to conversation
                                </Button>
                            </Link>
                        </div>
                        <Separator />
                    </>
                )}

                <Accordion
                    type="multiple"
                    className="w-full"
                    defaultValue={[
                        'playbook',
                        'personal-details',
                        'contact-details',
                        'company',
                        'work-experience',
                        'manage',
                        'user-memories',
                    ]}
                >
                    <AccordionItem value="playbook" className="max-w-full">
                        <AccordionTrigger className="p-4">
                            <Tooltip delayDuration={300}>
                                <TooltipTrigger asChild>
                                    <div className="flex gap-1 items-center">
                                        Playbook
                                        <Info className="h-4 w-4 stroke-[1]" />
                                    </div>
                                </TooltipTrigger>
                                <TooltipContent>
                                    This playbook provides the guidelines for messages with this contact
                                </TooltipContent>
                            </Tooltip>
                        </AccordionTrigger>
                        <AccordionContent className="px-4 max-w-full">
                            <PlaybookBadge prospectId={prospectId} responsive={false} size="large" />
                        </AccordionContent>
                    </AccordionItem>

                    <AccordionItem value="personal-details">
                        <AccordionTrigger className="p-4">
                            <div className="flex justify-between items-center w-full pr-2">
                                <span>Personal details</span>
                            </div>
                        </AccordionTrigger>
                        <AccordionContent className="px-4">
                            <div className="flex flex-col gap-3">
                                <div className="flex items-start gap-3">
                                    <User className="h-5 w-5 stroke-[1]" />
                                    <Tooltip delayDuration={300}>
                                        <TooltipTrigger asChild>
                                            <Link to={`${paths.NEW.CONTACTS}/${contact?.id}`}>
                                                <span className="text-sm hover:underline cursor-pointer">
                                                    {contact?.data?.fullName}
                                                </span>
                                            </Link>
                                        </TooltipTrigger>
                                        <TooltipContent>Show in Contacts</TooltipContent>
                                    </Tooltip>
                                </div>
                                {contact?.data?.occupationTitle && (
                                    <div className="flex justify-between gap-3 items-center">
                                        <div className="flex items-start gap-3 items-center">
                                            <Briefcase className="h-5 w-5 stroke-[1] flex-shrink-0" />
                                            <Tooltip delayDuration={300}>
                                                <TooltipTrigger asChild>
                                                    <span className="text-sm">{contact?.data?.occupationTitle}</span>
                                                </TooltipTrigger>
                                                <TooltipContent>{`${contact?.data?.occupationTitle} at ${contact?.data?.organizationName}`}</TooltipContent>
                                            </Tooltip>
                                            {numberOfOngoingExperiences > 1 && (
                                                <Tooltip delayDuration={300}>
                                                    <TooltipTrigger>
                                                        <Badge
                                                            variant="warning"
                                                            className="text-xs h-5 px-2"
                                                            onClick={() => openEditContactDetailsDialog(prospectId)}
                                                        >
                                                            +{numberOfOngoingExperiences}
                                                        </Badge>
                                                    </TooltipTrigger>
                                                    <TooltipContent>
                                                        {`${numberOfOngoingExperiences} current positions`}
                                                    </TooltipContent>
                                                </Tooltip>
                                            )}
                                        </div>
                                        {numberOfOngoingExperiences > 1 && <EditButton />}
                                    </div>
                                )}
                                {contact?.data?.occupationTitle && contact?.data?.organizationName && (
                                    <div className="flex items-start gap-3">
                                        <ImageRoot className="h-5 w-5">
                                            <Image
                                                src={`https://logo.clearbit.com/${contact?.data?.organizationDomain}`}
                                            />
                                            <ImageFallback className="text-text-primary">
                                                <Building2 className="h-5 w-5 stroke-[1]" />
                                            </ImageFallback>
                                        </ImageRoot>
                                        <Tooltip delayDuration={300}>
                                            <TooltipTrigger asChild>
                                                <span
                                                    onClick={() =>
                                                        openAccountDetailsDialog(
                                                            account?.accountId ?? contact?.data?.organizationId ?? ''
                                                        )
                                                    }
                                                    className="text-sm hover:underline cursor-pointer"
                                                >
                                                    {contact?.data?.organizationName}
                                                </span>
                                            </TooltipTrigger>
                                            <TooltipContent>{`${contact?.data?.occupationTitle} at ${contact?.data?.organizationName}`}</TooltipContent>
                                        </Tooltip>
                                    </div>
                                )}
                                {contact?.data?.location && (
                                    <div className="flex items-start gap-3">
                                        <MapPin className="h-5 w-5 stroke-[1]" />
                                        <span className="text-sm">{contact?.data?.location}</span>
                                    </div>
                                )}
                                {contact?.data?.personId && (
                                    <ContactOwnersAvatarDisplay
                                        personId={contact?.data?.personId}
                                        prospectId={prospectId}
                                        linkedinUrl={contact?.data?.linkedinProfileUrl}
                                    />
                                )}
                            </div>
                        </AccordionContent>
                    </AccordionItem>

                    <AccordionItem value="contact-details">
                        <AccordionTrigger className="p-4">
                            <div className="flex justify-between items-center w-full pr-2">
                                <span>Contact details</span>
                            </div>
                        </AccordionTrigger>
                        <AccordionContent className="px-4 max-w-full">
                            <div className="flex flex-col gap-3">
                                {contact?.data?.linkedinProfileUrl && (
                                    <div className="flex items-start gap-3">
                                        <Linkedin className="h-5 w-5 stroke-[1]" />
                                        <Tooltip delayDuration={300}>
                                            <TooltipTrigger asChild>
                                                <a
                                                    href={contact?.data?.linkedinProfileUrl}
                                                    className="text-sm line-clamp-1 flex-1 break-all hover:underline"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {contact?.data?.linkedinProfileUrl?.replace(
                                                        /(https:\/\/)?(www\.)?/i,
                                                        ''
                                                    )}
                                                </a>
                                            </TooltipTrigger>
                                            <TooltipContent>Go to LinkedIn profile</TooltipContent>
                                        </Tooltip>
                                        <EditButton />
                                    </div>
                                )}
                                <div className="flex items-start gap-3">
                                    <Mail className="h-5 w-5 stroke-[1]" />
                                    <Tooltip delayDuration={300}>
                                        <TooltipTrigger asChild>
                                            <span className="text-sm line-clamp-1 flex-1 break-all">
                                                {getEmailText(contact?.data?.email)}
                                            </span>
                                        </TooltipTrigger>
                                        <TooltipContent>{getEmailTooltip(contact?.data?.email)}</TooltipContent>
                                    </Tooltip>
                                    <EditButton />
                                </div>
                                {Boolean(contact?.data?.phoneNumbers?.[0]) && (
                                    <div className="flex items-start gap-3">
                                        <Phone className="h-5 w-5 stroke-[1]" />
                                        <span className="text-sm line-clamp-1 flex-1 break-all">
                                            {contact?.data?.phoneNumbers?.[0].number || '...'}
                                        </span>
                                        <EditButton />
                                    </div>
                                )}
                                <CRMDetails />
                            </div>
                        </AccordionContent>
                    </AccordionItem>

                    <AccordionItem value="company">
                        <AccordionTrigger className="p-4">Company</AccordionTrigger>
                        <AccordionContent className="px-4">
                            <div className="flex flex-col gap-3">
                                <div className="flex items-start gap-3">
                                    <ImageRoot className="h-5 w-5">
                                        <Image src={`https://logo.clearbit.com/${contact?.data?.organizationDomain}`} />
                                        <ImageFallback className="text-text-primary">
                                            <Building2 className="h-5 w-5 stroke-[1]" />
                                        </ImageFallback>
                                    </ImageRoot>
                                    {contact?.data?.organizationName && (
                                        <Tooltip delayDuration={300}>
                                            <TooltipTrigger asChild>
                                                <a
                                                    href={`https://${contact?.data?.organizationDomain}`}
                                                    className="text-sm hover:underline"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {contact?.data?.organizationName}
                                                </a>
                                            </TooltipTrigger>
                                            <TooltipContent>Go to company website</TooltipContent>
                                        </Tooltip>
                                    )}
                                </div>
                                {contact?.data?.organizationSize && (
                                    <div className="flex items-start gap-3">
                                        <Users className="h-5 w-5 stroke-[1]" />
                                        <span className="text-sm">{contact?.data?.organizationSize} employees</span>
                                    </div>
                                )}
                                {account?.data?.aboutUs && (
                                    <div className="flex items-start gap-3">
                                        <FileText className="h-5 w-5 stroke-[1]" />
                                        <div className="flex flex-col gap-0 flex-1 overflow-hidden">
                                            <span
                                                className={cn(
                                                    'text-sm flex-1 break-words',
                                                    showCompleteAboutUs ? '' : 'line-clamp-3'
                                                )}
                                            >
                                                {account?.data?.aboutUs}
                                            </span>
                                            <div>
                                                <Button
                                                    variant="text"
                                                    className="p-0"
                                                    onClick={() => setShowCompleteAboutUs(!showCompleteAboutUs)}
                                                >
                                                    {showCompleteAboutUs ? 'Show less' : 'Show more'}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {account?.data?.specialties?.length > 0 && (
                                    <div className="flex items-start gap-3">
                                        <Tag className="h-5 w-5 stroke-[1]" />
                                        <div className="flex flex-col gap-0 flex-1">
                                            <div className="flex flex-row flex-wrap gap-1 flex-1">
                                                {account?.data?.specialties
                                                    .slice(
                                                        0,
                                                        showAllSpecialties
                                                            ? account?.data?.specialties.length
                                                            : defaultShowNumberOfSpecialties
                                                    )
                                                    .map((specialty, index) => (
                                                        <CompanyTag key={index}>{specialty}</CompanyTag>
                                                    ))}
                                            </div>
                                            <div>
                                                <Button
                                                    variant="text"
                                                    className="p-0"
                                                    onClick={() => setShowAllSpecialties(!showAllSpecialties)}
                                                >
                                                    {showAllSpecialties ? 'Show less' : 'Show more'}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </AccordionContent>
                    </AccordionItem>

                    <AccordionItem value="work-experience">
                        <AccordionTrigger className="p-4">Work experience</AccordionTrigger>
                        <AccordionContent className="px-4">
                            <div className="flex flex-col gap-3">
                                <div className="flex items-start gap-3">
                                    <BriefcaseClock />
                                    <div className="flex flex-col gap-3">
                                        {sortedExperiencesWithCurrentOrgFirst
                                            ?.slice(0, showAllExperiences ? 12 : defaultShowNumberOfExperiences)
                                            .map((experience, index) => (
                                                <div key={index} className="'transition-all duration-300 ease-in-out',">
                                                    <WorkExperience key={index} experience={experience} />
                                                </div>
                                            ))}

                                        <div>
                                            <Button
                                                variant="text"
                                                className="p-0"
                                                onClick={() => setShowAllExperiences(!showAllExperiences)}
                                            >
                                                {showAllExperiences ? 'Show less' : 'Show more'}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </AccordionContent>
                    </AccordionItem>

                    {userMemoryEnabled && (
                        <AccordionItem value="user-memories">
                            <AccordionTrigger className="p-4 flex flex-row gap-2 items-center justify-between w-full">
                                <Tooltip delayDuration={300}>
                                    <TooltipTrigger asChild>
                                        <div className="flex flex-row gap-2 items-center">
                                            <Sparkles className="h-4 w-4 stroke-[1]" />
                                            Conversation Memories
                                        </div>
                                    </TooltipTrigger>
                                    <TooltipContent>
                                        A memory is a piece of information that your agent learned and stored.
                                    </TooltipContent>
                                </Tooltip>
                            </AccordionTrigger>
                            <AccordionContent className="px-4">
                                <UserMemoryList level={'conversation'} prospectId={prospectId} />
                            </AccordionContent>
                        </AccordionItem>
                    )}

                    <AccordionItem value="manage">
                        <AccordionTrigger className="p-4">Manage</AccordionTrigger>
                        <AccordionContent className="px-4">
                            <Button
                                variant="text"
                                className="p-1 text-text-error-primary hover:text-text-error-primary/80"
                                onClick={() => openDeleteContactDialog([userContactData?.UserContacts[0]?.id])}
                            >
                                <Trash className="h-4 w-4" />
                                Delete contact
                            </Button>
                        </AccordionContent>
                    </AccordionItem>
                </Accordion>
            </ScrollArea>
        </div>
    );
};
