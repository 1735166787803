import { WorkflowStateModal } from '../../../enums/workflow';
import { alertModalMessages, initialDays, initialTimeStates } from '../../../services/utils/constants';
import { OutreachStateInterface } from './types';

export const OutreachState: OutreachStateInterface = {
    templateId: '',
    templateName: '',
    steps: [],
    connections: [],
    time: [...initialTimeStates],
    days: [...initialDays],
    offset: 0,
    alertMessages: { ...alertModalMessages },
    isModalVisible: false,
    flowSplit: {
        onAction: null,
        firstAction: null,
        secondAction: null,
    },
    validationErrors: {},
    isManual: false,
    semiAutoAI: 'off',
    unsavedChanges: false,
    updateEditors: false,
    isLoading: false,
    outreachSettings: {
        unsubscribeFooterHtml: null,
    },
    currentStepModalView: WorkflowStateModal.NONE,
};
