import { FC, useState, useCallback, useEffect } from 'react';
import { Button } from '@shadcn/ui/components/ui/button';
import {
    AlertCircle,
    Trash2,
    RefreshCw,
    Settings2,
    Users,
    Building,
    Handshake,
    MessageSquare,
    Loader2,
} from 'lucide-react';
import { Separator } from '@shadcn/ui/components/ui/separator';
import { ReactComponent as HubspotLogo } from '../../../../../../components/icons/hubspot-logo.svg';
import { Switch } from '@shadcn/ui/components/ui/switch';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
    DialogFooter,
} from '@shadcn/ui/components/ui/dialog';
import { ReactComponent as Arrow } from '../../../../../../components/icons/arrow.svg';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@shadcn/ui/components/ui/select';
import { PlusCircle } from 'lucide-react';
import {
    OrganizationIntegration,
    AvailableFields,
    HubspotFieldMapping,
    AvailableHubspotFields,
} from '../../../../../sdk/internal/user-organization.sdk';

const FieldMappingDialog: FC<{
    fieldMapping: HubspotFieldMapping[];
    onUpdateMapping?: (mapping: HubspotFieldMapping[]) => void;
    availableFields?: AvailableHubspotFields;
    loadAvailableFields?: (crmType: string) => void;
    isLoadingFields?: boolean;
}> = ({ fieldMapping = [], onUpdateMapping, availableFields, loadAvailableFields, isLoadingFields = false }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [localMapping, setLocalMapping] = useState<HubspotFieldMapping[]>(fieldMapping);
    const [errors, setErrors] = useState<Record<number, string>>({});

    // Open the dialog and load fields if they're not already loaded
    const openDialog = useCallback(() => {
        if (loadAvailableFields) {
            loadAvailableFields('hubspot');
        }
        setLocalMapping(fieldMapping);
        setErrors({});
        setIsOpen(true);
    }, [fieldMapping, loadAvailableFields]);

    // Check for duplicates and update the errors state
    const validateMappings = useCallback((mappings: HubspotFieldMapping[]) => {
        const newErrors: Record<number, string> = {};
        const hubspotFieldsUsed: Record<string, number> = {};

        mappings.forEach((mapping, index) => {
            if (mapping.contactField) {
                if (hubspotFieldsUsed[mapping.contactField] !== undefined) {
                    newErrors[index] = 'This Hubspot field is already mapped';
                    newErrors[hubspotFieldsUsed[mapping.contactField]] = 'This Hubspot field is already mapped';
                } else {
                    hubspotFieldsUsed[mapping.contactField] = index;
                }
            }
        });

        setErrors(newErrors);
        return newErrors;
    }, []);

    const handleUpdateMapping = (index: number, field: 'prospectField' | 'contactField', value: string) => {
        const newMapping = [...localMapping];
        const currentMapping = newMapping[index] || { prospectField: '', contactField: '', required: false };

        if (field === 'prospectField') {
            // Handle prospect field selection
            const fieldInfo = availableFields?.prospect?.find((f) => f.name === value);

            // Set required based on standard required fields
            const alwaysRequiredFields = ['firstName', 'lastName', 'email', 'organizationName', 'organizationDomain'];

            newMapping[index] = {
                ...currentMapping,
                prospectField: `prospect.${value}`,
                required: alwaysRequiredFields.includes(value),
            };
        } else {
            // Handle Hubspot field selection
            newMapping[index] = {
                ...currentMapping,
                contactField: value,
            };
        }

        setLocalMapping(newMapping);
        validateMappings(newMapping);
    };

    const handleAddMapping = () => {
        setLocalMapping([...localMapping, { prospectField: '', contactField: '', required: false }]);
    };

    const handleRemoveMapping = (index: number) => {
        const newMapping = localMapping.filter((_, i) => i !== index);
        setLocalMapping(newMapping);
        validateMappings(newMapping);
    };

    const handleSave = () => {
        const newErrors = validateMappings(localMapping);
        if (Object.keys(newErrors).length === 0) {
            onUpdateMapping?.(localMapping);
            setIsOpen(false);
        }
    };

    // Helper function to check if a Hubspot field is already selected in another row
    const isHubspotFieldSelected = (fieldName: string, currentIndex: number) => {
        return localMapping.some((mapping, idx) => idx !== currentIndex && mapping.contactField === fieldName);
    };

    return (
        <Dialog open={isOpen} onOpenChange={setIsOpen}>
            <DialogTrigger asChild>
                <Button variant="outline" size="sm" onClick={openDialog}>
                    View & Configure
                </Button>
            </DialogTrigger>
            <DialogContent className="max-w-3xl max-h-[80vh] flex flex-col">
                <DialogHeader className="mb-6">
                    <DialogTitle className="text-xl font-semibold">Field Mapping Configuration</DialogTitle>
                    <DialogDescription>Configure how fields are mapped between Andsend and Hubspot</DialogDescription>
                </DialogHeader>

                <div className="flex-1 overflow-y-auto pb-6">
                    {/* Header row with labels */}
                    <div className="grid grid-cols-[1fr,40px,1fr,40px] items-center mb-6 px-1">
                        <div className="text-center font-medium text-base">Andsend</div>
                        <div></div>
                        <div className="text-center font-medium text-base">Hubspot</div>
                        <div></div>
                    </div>

                    {isLoadingFields ? (
                        <div className="flex flex-col items-center justify-center py-12">
                            <Loader2 className="h-8 w-8 animate-spin text-primary mb-4" />
                            <p className="text-sm text-muted-foreground">Loading available fields...</p>
                        </div>
                    ) : (
                        <div className="space-y-4">
                            {localMapping.map((item, idx) => (
                                <div key={idx} className="grid grid-cols-[1fr,40px,1fr,40px] items-center gap-2">
                                    <Select
                                        value={item.prospectField.replace('prospect.', '')}
                                        onValueChange={(value) => handleUpdateMapping(idx, 'prospectField', value)}
                                    >
                                        <SelectTrigger className={`w-full`}>
                                            <SelectValue placeholder="Select Andsend field" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            {availableFields?.prospect?.map((field) => (
                                                <SelectItem key={field.name} value={field.name}>
                                                    {field.label}{' '}
                                                    {[
                                                        'firstName',
                                                        'lastName',
                                                        'email',
                                                        'organizationName',
                                                        'organizationDomain',
                                                    ].includes(field.name) && '*'}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>

                                    <div className="flex justify-center">
                                        <Arrow className="h-4 w-4 flex-shrink-0" />
                                    </div>

                                    <div>
                                        <Select
                                            value={item.contactField}
                                            onValueChange={(value) => handleUpdateMapping(idx, 'contactField', value)}
                                        >
                                            <SelectTrigger
                                                className={`w-full ${
                                                    errors[idx] ? 'border-red-500 focus:ring-red-500' : ''
                                                }`}
                                            >
                                                <SelectValue placeholder="Select Hubspot field" />
                                            </SelectTrigger>
                                            <SelectContent>
                                                {availableFields?.contact?.map((field) => (
                                                    <SelectItem
                                                        key={field.name}
                                                        value={field.name}
                                                        disabled={isHubspotFieldSelected(field.name, idx)}
                                                        className={
                                                            isHubspotFieldSelected(field.name, idx) ? 'opacity-50' : ''
                                                        }
                                                    >
                                                        {field.label}
                                                    </SelectItem>
                                                ))}
                                            </SelectContent>
                                        </Select>
                                        {errors[idx] && <p className="text-xs text-red-500 mt-1">{errors[idx]}</p>}
                                    </div>

                                    <Button
                                        variant="ghost"
                                        size="sm"
                                        className="text-red-500 hover:text-red-700 flex justify-center"
                                        onClick={() => handleRemoveMapping(idx)}
                                    >
                                        <Trash2 className="h-4 w-4" />
                                    </Button>
                                </div>
                            ))}

                            <Button variant="outline" size="sm" className="w-full" onClick={handleAddMapping}>
                                <PlusCircle className="h-4 w-4 mr-2" />
                                Add Field Mapping
                            </Button>
                        </div>
                    )}

                    <div className="mt-6 text-sm text-muted-foreground">
                        <p>* Required fields</p>
                        <p className="mt-2">
                            Don't see the property you're looking for? Create a new property in Hubspot.
                        </p>
                        <p className="mt-1">Outreach actions and status will be documented and saved as notes.</p>
                    </div>
                </div>

                <DialogFooter className="pt-4 border-t mt-auto">
                    <Button variant="outline" onClick={() => setIsOpen(false)}>
                        Cancel
                    </Button>
                    <Button onClick={handleSave} disabled={isLoadingFields || Object.keys(errors).length > 0}>
                        Save Changes
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

interface HubspotSettingsProps {
    isConnected: boolean;
    onDisconnect: () => void;
    isDisconnecting: boolean;
    account?: OrganizationIntegration;
    onUpdateScope?: (scope: string, value: boolean) => void;
    onUpdateFieldMapping?: (fieldMapping: HubspotFieldMapping[]) => void;
    isUpdatingSettings?: boolean;
    availableFields?: AvailableHubspotFields;
    loadAvailableFields?: (crmType: string) => void;
    isLoadingFields?: boolean;
}

export const HubspotSettings: FC<HubspotSettingsProps> = ({
    isConnected,
    onDisconnect,
    isDisconnecting,
    onUpdateScope,
    account,
    onUpdateFieldMapping,
    isUpdatingSettings = false,
    availableFields,
    loadAvailableFields,
    isLoadingFields,
}) => {
    const settings = account?.settings;

    if (!isConnected) {
        return (
            <div className="flex flex-col items-center justify-center py-12">
                <AlertCircle className="h-12 w-12 text-yellow-500 mb-4" />
                <h3 className="text-lg font-medium mb-2">Not Connected</h3>
                <p className="text-muted text-center mb-6">
                    Your Hubspot account is not connected. Please connect it from the apps page.
                </p>
                <Button variant="outline" asChild>
                    <a href="/account-management/apps">Go to Apps</a>
                </Button>
            </div>
        );
    }

    // Type assertion to ensure the field mapping is treated as HubspotFieldMapping[]
    const fieldMapping = (settings?.fieldMapping as HubspotFieldMapping[]) || [];

    return (
        <div className="space-y-6">
            <div className="flex flex-col items-left">
                <HubspotLogo className="object-contain" />
                <div className="mt-2">
                    <p className="text-sm text-muted-foreground">Manage your Hubspot connection settings</p>
                </div>
            </div>

            <Separator />

            <div className="space-y-4">
                <h4 className="text-md font-medium flex items-center gap-2">
                    <RefreshCw className="h-5 w-5 text-muted-foreground mt-0.5" />
                    Sync Settings
                </h4>

                {/* Contacts/Prospects Sync */}
                <div className="rounded-md border p-4">
                    <div className="flex items-center justify-between">
                        <div className="flex items-start gap-4">
                            <Users className="h-5 w-5 text-muted-foreground mt-0.5" />
                            <div className="space-y-1">
                                <p className="text-sm font-medium">Contact Sync</p>
                                <p className="text-sm text-muted-foreground">
                                    Sync contacts between Andsend and Hubspot
                                </p>
                            </div>
                        </div>
                        <Switch
                            checked={settings?.integrationScope?.prospects ?? false}
                            onCheckedChange={(value) => onUpdateScope?.('prospects', value)}
                            disabled={isDisconnecting || isUpdatingSettings}
                            className="data-[state=checked]:bg-background-brand-primary data-[state=unchecked]:bg-input"
                        />
                    </div>
                </div>

                {/* Actions Logging */}
                <div className="rounded-md border p-4">
                    <div className="flex items-center justify-between">
                        <div className="flex items-start gap-4">
                            <MessageSquare className="h-5 w-5 text-muted-foreground mt-0.5" />
                            <div className="space-y-1">
                                <p className="text-sm font-medium">Action Logging</p>
                                <p className="text-sm text-muted-foreground">
                                    Log Andsend actions and messages in Hubspot
                                </p>
                            </div>
                        </div>
                        <Switch
                            checked={settings?.integrationScope?.actionMessages ?? false}
                            onCheckedChange={(value) => onUpdateScope?.('actionMessages', value)}
                            disabled={isDisconnecting || isUpdatingSettings}
                            className="data-[state=checked]:bg-background-brand-primary data-[state=unchecked]:bg-input"
                        />
                    </div>
                </div>

                <Separator />
                <h4 className="text-md font-medium">Field Mapping</h4>

                <div className="rounded-md border p-4">
                    <div className="flex items-start gap-4 justify-between">
                        <div className="flex items-start gap-4">
                            <Settings2 className="h-5 w-5 text-muted-foreground mt-0.5" />
                            <div className="space-y-1">
                                <p className="text-sm font-medium">Contact Field Mapping</p>
                                <p className="text-sm text-muted-foreground">
                                    Configure how contact fields map between Andsend and Hubspot
                                </p>
                                <div className="mt-2 text-xs text-muted-foreground">
                                    <p>• {settings?.fieldMapping?.length || 0} contact field mappings</p>
                                </div>
                            </div>
                        </div>
                        <FieldMappingDialog
                            fieldMapping={fieldMapping}
                            onUpdateMapping={onUpdateFieldMapping}
                            availableFields={availableFields}
                            loadAvailableFields={loadAvailableFields}
                            isLoadingFields={isLoadingFields}
                        />
                    </div>
                </div>
            </div>

            <Separator />

            {/* Danger zone */}
            <div className="space-y-4">
                <h4 className="text-md font-medium text-red-500">Danger Zone</h4>

                <div className="rounded-md border border-red-200 p-4">
                    <div className="flex items-start gap-4">
                        <Trash2 className="h-5 w-5 text-red-500 mt-0.5" />
                        <div className="space-y-1 flex-1">
                            <p className="text-sm font-medium">Disconnect Account</p>
                            <p className="text-sm text-muted-foreground">
                                Remove this Hubspot account from your connected accounts. This will stop all syncing
                                between Andsend and Hubspot.
                            </p>
                        </div>
                        <Button variant="destructive" size="sm" onClick={onDisconnect} disabled={isDisconnecting}>
                            {isDisconnecting ? 'Disconnecting...' : 'Disconnect'}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};
