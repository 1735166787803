import { IEvent, Topics } from '@zaplify/common';
import { v4 as uuid } from 'uuid';

export class BillingEvent extends IEvent {
    public static override readonly TOPIC = Topics.BILLING;
    constructor(
        readonly id: string,
        readonly customerId: string,
        readonly createdAt: Date,
        readonly content: {},
        override readonly eid: string = uuid(),
    ) {
        super();
    }

    get partitionKey(): string {
        return this.customerId;
    }
}
