import { Button } from '@mui/material';
import { useState } from 'react';
import { ReactComponent as Google } from '../../../components/icons/google.svg';
import { signInWithGoogle } from '../../../services/firebase';
import FeedbackMessage from '../../../components/form/feedback-message';

const GoogleAuthButton = ({ children }: { children: React.ReactNode }) => {
    const [localError, setLocalError] = useState('');

    const handleSubmit = async (event) => {
        setLocalError('');
        try {
            await signInWithGoogle();
            return '';
        } catch (error: any) {
            if (error?.message && error.message.includes('private'))
                setLocalError("We don't support private emails. Please use a business email instead.");
        }
    };

    return (
        <>
            <Button
                variant="outlined"
                disableElevation
                fullWidth
                sx={{
                    padding: '9px',
                    borderRadius: '8px',
                    backgroundColor: 'white',
                    borderColor: '#E4E6EB',
                    gap: '6px',
                    ':focus': {
                        borderColor: '#E4E6EB',
                    },
                }}
                onClick={handleSubmit}
            >
                <Google
                    style={{
                        width: '20px',
                        height: '20px',
                    }}
                />
                {children}
            </Button>
            {localError && <FeedbackMessage id="google-auth-feedback-message" error={localError} info={''} />}
        </>
    );
};

export default GoogleAuthButton;
