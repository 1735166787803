import { AccountDto } from '@zaplify/services/accounts/shared';
import { useAccountDetailsDialog } from '../../../../../components/dialogs/account-details-dialog';
import { Button } from '@shadcn/ui/components/ui/button';
import { Tooltip, TooltipContent, TooltipTrigger } from '@shadcn/ui/components/ui/tooltip';
import { SquareArrowOutUpRight } from 'lucide-react';

export const ViewEmployeesButton = ({ accountData }: { accountData: AccountDto }) => {
    const { openAccountDetailsDialog } = useAccountDetailsDialog();
    return (
        <Tooltip delayDuration={300}>
            <TooltipTrigger asChild>
                <Button
                    variant="outline"
                    className="w-fit"
                    onClick={() => openAccountDetailsDialog(accountData?.accountId || '')}
                >
                    <span className="sm:hidden xl:block">View employees</span>
                    <SquareArrowOutUpRight className="w-4 h-4" />
                </Button>
            </TooltipTrigger>
            <TooltipContent>View employees</TooltipContent>
        </Tooltip>
    );
};
