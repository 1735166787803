import { IconButton, Switch, Typography } from '@mui/material';
import Delete from '@mui/icons-material/Delete';
import Settings from '@mui/icons-material/Settings';
import { Button, GuardedLink } from '@zaplify/ui';
import { useState } from 'react';
import { ConnectToSalesforceModal } from './apps/salesforce/components/connectToSalesforceModal';
import { ProgressBar } from './apps/salesforce/components/progressBar';
import { ActivatedAppCardContainer, ActivatedHeightAppCardContainer, UnactivatedAppCardContainer } from './containers';
import { ReactComponent as ErrorIcon } from '../../components/icons/error-icon.svg';

export const ErrorBox = ({ errorText }) => {
    if (!errorText) return null;
    return (
        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 4, marginBottom: 10 }}>
            <ErrorIcon height={20} width={20} />
            <Typography
                style={{
                    fontSize: 14,
                    fontWeight: 400,
                    textAlign: 'left',
                    color: '#CC2946',
                }}
            >
                {errorText}
            </Typography>
        </div>
    );
};

export const UnactivatedAppCard = (props) => (
    <UnactivatedAppCardContainer disabled={props.disabled} data-cy="card-unactivated">
        <div data-cy="crm-logo">
            <img alt={props.alt || 'company-logo'} src={props.logo} />
        </div>
        <Typography data-cy="crm-description">{props.description}</Typography>
        <Button
            dataCy="connect-button"
            disabled={props.href ? false : true}
            href={props.href}
            text={props.buttonText}
            target="_blank"
            borderRadius="100px"
            variant={props.href ? 'contained' : 'outlined'}
            height={props.href ? '45px' : '38px'}
        />
    </UnactivatedAppCardContainer>
);

export const UnactivatedAppCardSalesforce = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleChangeModalStatus = () => {
        setIsOpen(!isOpen);
    };

    return (
        <UnactivatedAppCardContainer disabled={props.disabled} data-cy="card-unactivated">
            <div data-cy="crm-logo">
                <img alt={props.alt || 'company-logo'} src={props.logo} />
            </div>
            <Typography data-cy="crm-description">{props.description}</Typography>
            {props.isLoading ? (
                <ProgressBar show={props.isLoading} />
            ) : (
                <Button
                    dataCy="connect-button"
                    disabled={!props.oauthConfig}
                    onClick={handleChangeModalStatus}
                    text={props.buttonText}
                    target="_blank"
                    borderRadius="100px"
                    variant={props.oauthConfig ? 'contained' : 'outlined'}
                    height={props.oauthConfig ? '45px' : '38px'}
                />
            )}

            <ConnectToSalesforceModal isOpen={isOpen} setIsOpen={setIsOpen} oauthConfig={props.oauthConfig} />
        </UnactivatedAppCardContainer>
    );
};

export const ActivatedAppCard = (props) => {
    return (
        <ActivatedAppCardContainer data-cy="card-activated">
            <div data-cy="crm-logo">
                <IconButton size="large">
                    <GuardedLink to={props.settingsPage} data-cy={props.dataCyEdit}>
                        <Settings />
                    </GuardedLink>
                </IconButton>
            </div>
            <ActivatedHeightAppCardContainer>
                <img alt={props.alt || 'company-logo'} src={props.logo} />
            </ActivatedHeightAppCardContainer>
            <Switch
                data-cy={props.dataCySwitch}
                color="primary"
                checked={props.checked}
                onChange={(event) => props.switchOnChange(event)}
                onClick={(event) => event.stopPropagation()}
            />
            <Typography data-cy="crm-description" variant="body2">
                {props.description}
            </Typography>
            <IconButton onClick={(event) => props.disconnect(event)} size="large" data-cy={props.dataCyDelete}>
                <Delete />
            </IconButton>
        </ActivatedAppCardContainer>
    );
};
