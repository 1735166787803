import { getZaplifySdk } from '@zaplify/sdk';
import { createMutationOptions } from './sdk-utils';
import { UseMutationOptions, UseQueryOptions } from '@tanstack/react-query';
import { ProspectDataDto } from '@zaplify/prospects';

export const approveContactSuggestion = (
    options?: Omit<
        UseMutationOptions<string, Error, { contactSuggestionId: string; groupId?: string }>,
        'mutationFn' | 'invalidateQueries'
    >
) => {
    return createMutationOptions<string, Error, { contactSuggestionId: string; groupId?: string }>({
        mutationFn: (variables) =>
            getZaplifySdk().profiles.contactSuggestions.approveSuggestion(variables.contactSuggestionId, {
                groupId: variables.groupId,
            }),
        invalidateQueries: (data) => [],
        ...options,
    });
};

export const skipContactSuggestion = (
    options?: Omit<UseMutationOptions<void, Error, { contactSuggestionId: string }>, 'mutationFn' | 'invalidateQueries'>
) => {
    return createMutationOptions<void, Error, { contactSuggestionId: string }>({
        mutationFn: (variables) =>
            getZaplifySdk().profiles.contactSuggestions.rejectSuggestion(variables.contactSuggestionId),
        invalidateQueries: (data) => [],
        ...options,
    });
};

export const createContactSuggestion = (
    options?: Omit<UseMutationOptions<void, Error, { data: ProspectDataDto }>, 'mutationFn' | 'invalidateQueries'>
) => {
    return createMutationOptions<void, Error, { data: ProspectDataDto }>({
        mutationFn: (variables) => getZaplifySdk().profiles.contactSuggestions.createSuggestion(variables.data),
        invalidateQueries: (data) => [],
        ...options,
    });
};

const getRecommendations = (purpose: string, targetGroup: string) => {
    return getZaplifySdk().profiles.contactSuggestions.getRecommendationsForPurpose(purpose, targetGroup);
};

type GetRecommendationsForPurposeReturnType = Awaited<ReturnType<typeof getRecommendations>>;

export const getRecommendationsForPurpose = (
    purpose: string,
    targetGroup: string,
    options?: Omit<UseQueryOptions<GetRecommendationsForPurposeReturnType, Error>, 'queryFn' | 'queryKey'>
): UseQueryOptions<GetRecommendationsForPurposeReturnType, Error> => {
    return {
        queryKey: ['getRecommendationsForPurpose', purpose, targetGroup],
        queryFn: () => getRecommendations(purpose, targetGroup),
        ...options,
    };
};
