'use client';

import { useState } from 'react';
import { MessageCircle, Star } from 'lucide-react';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@shadcn/ui/components/ui/tooltip';
import { Textarea } from '@shadcn/ui/components/ui/textarea';
import { Button } from '@shadcn/ui/components/ui/button';
import { Badge } from '@shadcn/ui/components/ui/badge';
import { Popover, PopoverContent, PopoverTrigger } from '@shadcn/ui/components/ui/popover';
import { motion } from 'motion/react';
import { useSdk } from '../sdk';
import { useMutation } from '@tanstack/react-query';

export default function BetaBadge({ featureName }: { featureName: string }) {
    const [rating, setRating] = useState(0);
    const [hoverRating, setHoverRating] = useState(0);
    const [feedback, setFeedback] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [open, setOpen] = useState(false);

    const {
        feedback: { sendFeedback },
    } = useSdk();
    const { mutateAsync: sendFeedbackMutation, isPending: isSubmitting } = useMutation(sendFeedback());

    const handleSubmit = async () => {
        try {
            // Send feedback to API endpoint
            sendFeedbackMutation({
                featureName,
                rating,
                feedback,
                timestamp: new Date().toISOString(),
            });
            setSubmitted(true);

            // Single timeout for cleanup
            const timer = setTimeout(() => {
                setOpen(false);
            }, 2000);
            const timer2 = setTimeout(() => {
                setSubmitted(false);
                setRating(0);
                setFeedback('');
            }, 2500);

            // Cleanup timeout if component unmounts or user closes popover
            return () => {
                clearTimeout(timer);
                clearTimeout(timer2);
            };
        } catch (error) {
            console.error('Error submitting feedback:', error);
        }
    };

    const handlePopoverChange = (newOpen: boolean) => {
        setOpen(newOpen);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if ((e.metaKey || e.ctrlKey) && e.key === 'Enter' && rating > 0) {
            e.preventDefault();
            handleSubmit();
        }
    };

    const handleStarHover = (hoveredRating: number) => {
        setHoverRating(hoveredRating);
    };

    const handleStarHoverEnd = () => {
        setHoverRating(0);
    };

    return (
        <div className="relative inline-block">
            <TooltipProvider>
                <Tooltip delayDuration={0}>
                    <TooltipTrigger asChild>
                        <span>
                            <Popover open={open} onOpenChange={handlePopoverChange}>
                                <PopoverTrigger asChild>
                                    <div>
                                        <Badge
                                            variant="secondary"
                                            className="flex justify-center items-center gap-1 cursor-pointer bg-blue-100 text-blue-800 hover:bg-blue-200 dark:bg-blue-900 dark:text-blue-300 dark:hover:bg-blue-800 "
                                        >
                                            BETA
                                            <MessageCircle className="w-3 h-3" />
                                        </Badge>
                                    </div>
                                </PopoverTrigger>
                                <PopoverContent className="w-80 relative">
                                    {submitted && (
                                        <motion.div
                                            initial={{ opacity: 0, scale: 0.95 }}
                                            animate={{ opacity: 1, scale: 1 }}
                                            transition={{ duration: 0.2 }}
                                            className="absolute inset-0 flex flex-col items-center justify-center bg-background/95 z-50"
                                        >
                                            <motion.p
                                                initial={{ opacity: 0, y: 5 }}
                                                animate={{ opacity: 1, y: 0 }}
                                                transition={{ delay: 0.2 }}
                                                className="text-center font-medium"
                                            >
                                                Thank you for your feedback!
                                            </motion.p>
                                        </motion.div>
                                    )}
                                    <div className="space-y-4">
                                        <div className="space-y-2">
                                            <h4 className="font-medium leading-none">Beta Feature Feedback</h4>
                                            <p className="text-sm text-muted-foreground">
                                                Help us improve by rating your experience
                                            </p>
                                        </div>
                                        <div
                                            className="flex justify-center space-x-1"
                                            onMouseLeave={handleStarHoverEnd}
                                        >
                                            {[1, 2, 3, 4, 5].map((star) => (
                                                <Star
                                                    key={star}
                                                    className={`h-6 w-6 cursor-pointer ${
                                                        (hoverRating || rating) >= star
                                                            ? 'fill-yellow-400 text-yellow-400'
                                                            : 'text-muted-foreground'
                                                    }`}
                                                    onClick={() => setRating(star)}
                                                    onMouseEnter={() => handleStarHover(star)}
                                                />
                                            ))}
                                        </div>
                                        <Textarea
                                            placeholder="Share your thoughts (optional)"
                                            className="min-h-[80px]"
                                            value={feedback}
                                            onChange={(e) => setFeedback(e.target.value)}
                                            onKeyDown={handleKeyDown}
                                        />
                                        <Button
                                            className="w-full"
                                            onClick={handleSubmit}
                                            disabled={rating === 0 || isSubmitting}
                                        >
                                            {'Submit Feedback'}
                                        </Button>
                                    </div>
                                </PopoverContent>
                            </Popover>
                        </span>
                    </TooltipTrigger>
                    <TooltipContent side="right">
                        <p>Leave feedback</p>
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>
        </div>
    );
}
