import { styled } from '@mui/styles';
import { useDropzone } from 'react-dropzone';

export const ImageDropZone = (props) => {
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: 'image/jpeg, image/png, image/gif',
        onDrop: props.onDrop,
    });

    return (
        <DropZoneContainer filesUploaded={acceptedFiles.length > 0} {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            <p>
                {acceptedFiles.length > 0
                    ? acceptedFiles[0].name
                    : 'Click or drag and drop image here (JPEG, PNG or GIF Format)'}
            </p>
        </DropZoneContainer>
    );
};

export const DropZoneContainer = styled('div')(({ filesUploaded, theme }: any) => ({
    textAlign: 'center',
    padding: 20,
    background: filesUploaded ? '#FFF' : '#F7F8FA',
    border: '1px dashed #E4E6EB',
    boxSizing: 'border-box',
    borderRadius: 4,
    color: filesUploaded ? '#3A4B59' : '#bdbdbd',
    marginTop: 15,
    cursor: 'pointer',
    width: '100%',
}));
