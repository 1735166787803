import React, { ReactNode, useMemo } from 'react';
import { CreatePlaybookDialog } from './dialogs/create-playbook-dialog';
import { DeleteContactSearchParamDialog } from './dialogs/delete-contact-dialog';
import { EditContactDetailsDialog } from './dialogs/edit-contact-details-dialog';
import { SnoozeDialog } from './dialogs/snooze-dialog';
import { ReferFriendsDialog } from './dialogs/refer-friends-dialog';
import { GlobalSearchDialog } from './dialogs/global-search-dialog';
import { AccountDetailsDialog } from './dialogs/account-details-dialog';
import { ImportFromUrlDialog } from './dialogs/import-from-url-modal';
import { ContactLimitReachedDialog } from './dialogs/contact-limit-reached-dialog';
import { ExportContactsDialog } from './dialogs/export-contacts-dialog';
import { ConnectionRequestsLimitReachedDialog } from './dialogs/connection-reqs-limit-reached-dialog';
import { MemoryExplainerDialog } from './dialogs/memory-explainer-dialog';

export const AppDialogs: React.FC<{ children: ReactNode }> = ({ children }) => {
    const memoizedDialogs = useMemo(
        () => (
            <>
                <AccountDetailsDialog />
                <GlobalSearchDialog />
                <CreatePlaybookDialog />
                <DeleteContactSearchParamDialog />
                <ExportContactsDialog />
                <EditContactDetailsDialog />
                <SnoozeDialog />
                <ReferFriendsDialog />
                <ImportFromUrlDialog />
                <ContactLimitReachedDialog />
                <ConnectionRequestsLimitReachedDialog />
                <MemoryExplainerDialog />
            </>
        ),
        []
    );

    return (
        <>
            {memoizedDialogs}
            {children}
        </>
    );
};
