import { IUntypedCommand } from '@zaplify/common';
import { Topics } from '@zaplify/common';
import { UserRole } from '../users';

export class GrantRoleCommand extends IUntypedCommand {
    public static override readonly TOPIC = Topics.USERS;
    constructor(readonly userId: string, readonly role: UserRole) {
        super();
    }
}
