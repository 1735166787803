import { CreatedEstimateDto, SubscriptionDetailsDto, UpdateSubscriptionDto } from '@zaplify/subscriptions';
import { BaseSdk } from '../../base.sdk';

export type ManageSeatsDto = {
    assign: Array<{
        userId: string;
    }>;
};

export type PortalSession = {
    access_url: string;
    created_at: number;
    customer_id: string;
    expires_at: number;
    id: string;
    liked_customers: object[];
    object: string;
    redirect_url: string;
    status: string;
    token: string;
};
export class SubscriptionSdk extends BaseSdk {
    async fetchSusbscriptionDetails() {
        return await this.get<SubscriptionDetailsDto>(`${this.subscriptionPath}`);
    }

    async createPortalSession() {
        return this.post<PortalSession>({
            path: `${this.subscriptionPath}/self-service`,
        });
    }

    async manageSeats(organizationId: string, manageSeatsDto: ManageSeatsDto) {
        return this.post<any>({
            path: `${this.subscriptionPath}/${organizationId}/seats`,
            payload: {
                ...manageSeatsDto,
            },
        });
    }

    async requestSubscriptionCancel(organizationId: string, callback?: (error: unknown) => void) {
        return this.post({
            path: `${this.subscriptionPath}/${organizationId}/cancel`,
            callback,
        });
    }

    async requestSubscriptionUpdate(
        organizationId: string,
        updateSubscriptionDto: UpdateSubscriptionDto,
        errorCallback?: (error: any) => void,
    ) {
        return this.post<void>({
            path: `${this.subscriptionPath}/${organizationId}/update`,
            payload: updateSubscriptionDto,
            callback: errorCallback,
        });
    }

    async requestEstimateOnSubscriptionUpdate(organizationId: string, createCheckoutEstimate: UpdateSubscriptionDto) {
        return this.post<CreatedEstimateDto>({
            path: `${this.subscriptionPath}/${organizationId}/update/estimate`,
            payload: {
                ...createCheckoutEstimate,
            },
        });
    }
}
