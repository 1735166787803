import { FC, useState, useCallback, useMemo } from 'react';
import { useSearchContext } from '../context/search-context';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@shadcn/ui/components/ui/table';
import { Avatar } from '@shadcn/ui/components/ui/avatar';
import { Checkbox } from '@shadcn/ui/components/ui/checkbox';
import {
    Pagination,
    PaginationContent,
    PaginationItem,
    PaginationLink,
    PaginationNext,
    PaginationPrevious,
} from '@shadcn/ui/components/ui/pagination';
import { AddContactButton } from '../../../../components/buttons/add-contact-button';
import { ContactSource } from '@zaplify/services/user-contacts/shared';
import { AccountLogo } from '../../../../components/account-logo';
import { ContactOwnersAvatarDisplay } from '../../../../components/dialogs/global-search/contact-owners-avatar-display';
import { getZaplifySdk } from '@zaplify/sdk';
import { Button } from '@shadcn/ui/components/ui/button';
import { Loader2 } from 'lucide-react';
import { useQuery } from '@tanstack/react-query';
import { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider } from '@shadcn/ui/components/ui/tooltip';
import { PreviewProspectingDtos } from '@zaplify/prospects';
import { useUser } from '../../../../hooks/use-user';
import { BulkAddContactsButton } from '../../../../components/bulk-add-contact-button';
import { Link } from 'react-router-dom';
import { paths } from '../../../../../routes/paths';

interface SearchResultRowProps {
    person: PreviewProspectingDtos.ProspectDto;
    ownedByUser: boolean;
    isSelected: boolean;
    onToggleSelect: (personId: string) => void;
    isBlocklisted: boolean;
    isLoadingBlocklist: boolean;
}

const SearchResultRow: FC<SearchResultRowProps> = ({
    person,
    isSelected,
    ownedByUser,
    onToggleSelect,
    isBlocklisted,
    isLoadingBlocklist,
}) => {
    const isUnselectable = ownedByUser || isBlocklisted;
    const linkedinUserId = person.linkedinProfileUrl?.split('/in/')?.[1] ?? null;

    return (
        <TableRow className={`border-b hover:bg-muted/20 ${isUnselectable ? 'opacity-50' : ''}`}>
            <TableCell className="py-3">
                <Checkbox
                    checked={isSelected}
                    onCheckedChange={() => onToggleSelect(person.personId)}
                    className="mx-auto"
                    disabled={isUnselectable}
                />
            </TableCell>
            <TableCell className="py-3">
                <Link
                    className="flex w-full gap-3"
                    to={
                        linkedinUserId
                            ? paths.NEW.DISCOVER_PATHS.SEARCH + `/${linkedinUserId}`
                            : paths.NEW.DISCOVER_PATHS.SEARCH
                    }
                >
                    <div className="flex items-center gap-2">
                        <Avatar className="h-10 w-10 rounded-full">
                            <div className="bg-muted h-full w-full flex items-center justify-center text-sm font-medium text-muted-foreground rounded-full">
                                {person.fullName
                                    .split(' ')
                                    .map((name) => name[0])
                                    .join('')}
                            </div>
                        </Avatar>
                        <div className="flex flex-col">
                            <span className="font-medium text-sm">{person.fullName}</span>
                            <span className="text-xs text-muted-foreground">{person.occupationTitle}</span>
                            <span className="text-xs text-muted-foreground">{person.location}</span>
                        </div>
                    </div>
                </Link>
            </TableCell>
            <TableCell className="py-3">
                <div className="flex items-center gap-2">
                    <AccountLogo accountWebsite={person.organizationDomain} className="w-8 h-8 rounded-md" />
                    <div className="flex flex-col">
                        <div className="flex items-center gap-1">
                            <span className="text-sm">{person.organizationName}</span>
                            {isLoadingBlocklist ? (
                                <Loader2 className="w-3 h-3 animate-spin text-muted-foreground" />
                            ) : (
                                isBlocklisted && (
                                    <span className="text-xs bg-red-100 text-red-800 px-1.5 py-0.5 rounded-full">
                                        Blocklisted
                                    </span>
                                )
                            )}
                        </div>
                        <span className="text-xs text-muted-foreground">{person.organizationDomain}</span>
                    </div>
                </div>
            </TableCell>
            <TableCell className="py-3">
                <ContactOwnersAvatarDisplay
                    personId={person.personId}
                    linkedinUrl={person.linkedinProfileUrl}
                    iconSize="sm"
                    avatarSize="sm"
                />
            </TableCell>
            <TableCell className="py-3">
                {isLoadingBlocklist ? (
                    <Button variant="outline" disabled className="opacity-70">
                        <Loader2 className="w-5 h-5 mr-2 animate-spin" />
                        Loading
                    </Button>
                ) : !isUnselectable ? (
                    <AddContactButton personId={person.personId} variant="outline" source={ContactSource.UserSearch} />
                ) : (
                    ownedByUser && (
                        <Button variant="outline" disabled>
                            Already Added
                        </Button>
                    )
                )}
            </TableCell>
        </TableRow>
    );
};

export const SearchResults: FC = () => {
    const { persons, totalCount, page, setPage } = useSearchContext();
    const { userId } = useUser();
    const { data: blocklistedCompanies = [], isLoading: isLoadingBlocklist } = useQuery({
        queryKey: ['blocklistedCompanies', persons.map((p) => p.personId).join(',')],
        queryFn: async () => {
            if (persons.length === 0) return [];

            const result = await getZaplifySdk().profiles.sources.filterBlocklistedCompanies({
                companyNames: persons.map((person) => ({
                    organizationName: person.organizationName?.toLowerCase(),
                    organizationDomain: person.organizationDomain?.toLowerCase(),
                })),
            });
            return result;
        },
        enabled: persons.length > 0,
    });

    const isOwnedByUser = useCallback((person: PreviewProspectingDtos.ProspectDto, userId: string) => {
        return person.addedBy?.some((owner) => owner.userId === userId);
    }, []);

    const [selectedPersonIds, setSelectedPersonIds] = useState<string[]>([]);

    const isBlocklisted = useCallback(
        (organizationName: string, organizationDomain: string | null) => {
            return blocklistedCompanies.find(
                (company) =>
                    company.organizationName === organizationName || company.organizationDomain === organizationDomain
            )?.isBlocklisted;
        },
        [blocklistedCompanies]
    );

    const selectablePersons = useMemo(() => {
        return persons.filter(
            (person) =>
                !isOwnedByUser(person, userId) && !isBlocklisted(person.organizationName, person.organizationDomain)
        );
    }, [persons, isOwnedByUser, isBlocklisted, userId, blocklistedCompanies]);

    const selectablePersonIds = useMemo(() => {
        return selectablePersons.map((person) => person.personId);
    }, [selectablePersons]);

    const totalPages = Math.ceil(totalCount / 10);

    const toggleSelectAll = useCallback(() => {
        if (selectedPersonIds.length === selectablePersonIds.length) {
            setSelectedPersonIds([]);
        } else {
            setSelectedPersonIds([...selectablePersonIds]);
        }
    }, [selectedPersonIds, selectablePersonIds]);

    const toggleSelectProspect = useCallback(
        (personId: string) => {
            if (selectedPersonIds.includes(personId)) {
                setSelectedPersonIds(selectedPersonIds.filter((id) => id !== personId));
            } else {
                setSelectedPersonIds([...selectedPersonIds, personId]);
            }
        },
        [selectedPersonIds]
    );

    const isAllSelected = useMemo(() => {
        return selectablePersonIds.length > 0 && selectedPersonIds.length === selectablePersonIds.length;
    }, [selectedPersonIds, selectablePersonIds]);

    return (
        <div className="flex flex-col gap-4 h-full overflow-hidden">
            {selectedPersonIds.length > 0 && (
                <BulkAddContactsButton
                    contacts={selectedPersonIds.map((personId) => ({
                        personId,
                    }))}
                    source={ContactSource.UserSearch}
                    className="w-fit"
                    onAdded={() => {
                        setSelectedPersonIds([]);
                    }}
                />
            )}
            <div className="rounded-md border flex-1 overflow-hidden flex flex-col">
                <div className="overflow-auto flex-1">
                    <Table>
                        <TableHeader className="sticky top-0 bg-background z-10">
                            <TableRow>
                                <TableHead className="w-[50px]">
                                    <Checkbox
                                        checked={isAllSelected}
                                        onCheckedChange={toggleSelectAll}
                                        className="mx-auto"
                                    />
                                </TableHead>
                                <TableHead>Prospect</TableHead>
                                <TableHead>Company</TableHead>
                                <TableHead>
                                    <TooltipProvider>
                                        <Tooltip>
                                            <TooltipTrigger asChild>
                                                <span>Owners</span>
                                            </TooltipTrigger>
                                            <TooltipContent>
                                                Will show if any other in your Andsend team already added this contact
                                            </TooltipContent>
                                        </Tooltip>
                                    </TooltipProvider>
                                </TableHead>
                                <TableHead className="w-[100px]">Actions</TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {persons.map((person) => (
                                <SearchResultRow
                                    person={person}
                                    ownedByUser={isOwnedByUser(person, userId)}
                                    isSelected={selectedPersonIds.includes(person.personId)}
                                    onToggleSelect={toggleSelectProspect}
                                    isBlocklisted={!!isBlocklisted(person.organizationName, person.organizationDomain)}
                                    isLoadingBlocklist={isLoadingBlocklist}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </div>
            </div>

            {totalPages > 1 && (
                <div className="flex justify-center mt-auto py-2">
                    <Pagination>
                        <PaginationContent>
                            <PaginationItem>
                                <PaginationPrevious
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setPage(Math.max(1, page - 1));
                                    }}
                                    className={page === 1 ? 'pointer-events-none opacity-50' : ''}
                                />
                            </PaginationItem>

                            {Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
                                let pageNum;
                                if (totalPages <= 5) {
                                    pageNum = i + 1;
                                } else if (page <= 3) {
                                    pageNum = i + 1;
                                } else if (page >= totalPages - 2) {
                                    pageNum = totalPages - 4 + i;
                                } else {
                                    pageNum = page - 2 + i;
                                }

                                return (
                                    <PaginationItem key={pageNum}>
                                        <PaginationLink
                                            href="#"
                                            isActive={pageNum === page}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setPage(pageNum);
                                            }}
                                        >
                                            {pageNum}
                                        </PaginationLink>
                                    </PaginationItem>
                                );
                            })}

                            <PaginationItem>
                                <PaginationNext
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setPage(Math.min(totalPages, page + 1));
                                    }}
                                    className={page === totalPages ? 'pointer-events-none opacity-50' : ''}
                                />
                            </PaginationItem>
                        </PaginationContent>
                    </Pagination>
                </div>
            )}
        </div>
    );
};
