import { ProspectOwnerDto } from '@zaplify/prospects';
import { useEffect, useState } from 'react';
import AddPersonButton from '../../atoms/add-person-button';

const getAddedText = (userId: string, disabled: boolean, selectedCampaign: string, owners?: ProspectOwnerDto[]) => {
    if (disabled) {
        const allNames = owners
            ?.map((o) => {
                if (o.userId === userId) {
                    return 'you';
                } else {
                    return o.fullName;
                }
            })
            ?.join(' & ');

        return owners?.length ? `Added by ${allNames}` : 'Added';
    }

    return 'Add';
};

export const AddContactButton = ({
    userId,
    groups,
    disabled,
    onAdd,
    loading,
    owners,
}: {
    userId: string;
    groups: { id: string; name: string }[];
    disabled: boolean;
    onAdd: (group: { id: string; name: string }) => void;
    loading: boolean;
    owners?: ProspectOwnerDto[];
}) => {
    const [selectedCampaign, setSelectedCampaign] = useState('');
    const [addedText, setAddedText] = useState<null | string>(getAddedText(userId, disabled, selectedCampaign, owners));

    useEffect(() => {
        setAddedText(getAddedText(userId, disabled, selectedCampaign, owners));
    }, [disabled, selectedCampaign, owners]);

    const onClick = (event, groupId: string) => {
        onAdd(groups.find((group) => group.id === groupId));
        setSelectedCampaign(groups.find((group) => group.id === groupId)?.name || '');
    };

    return (
        <AddPersonButton
            added={disabled}
            isAddingLoader={loading}
            handleAddClick={onClick}
            groups={groups}
            variant="primary"
            addedText={addedText}
        />
    );
};
