import AddIcon from '@mui/icons-material/Add';
import { Typography } from '@mui/material';
import { useDispatch } from 'react-redux-latest';
import { Button } from '../../../../../components/atoms/button';
import { updatePipedriveSaveStatus } from '../../../../../redux/ducks/pipedrive/operations';
import {
    addOrganizationField,
    addPersonField,
    deleteField,
    updateField,
} from '../../../../../redux/ducks/pipedrive/slice';
import { AppDispatch, useAppSelector } from '../../../../../redux/store/configureStore';
import { ProgressBar } from '../../salesforce/components/progressBar';
import { disableUsedField } from '../../utils/fields';
import { CampaignNameField } from './campaignNameField';
import { FieldMappingContainer, MapperContainer } from './containers';
import { FieldMapHeader } from './fieldMapHeader';
import { LinkedInField } from './linkedInField';
import { LinkedinConnectionStatusField } from './linkedinConnectionStatusField';
import { OrganizationDomainField } from './organization-industry-domain';
import { OrganizationIndustryField } from './organization-industry-field';
import { OrganizationSizeField } from './organization-size-field';
import { FieldSelector } from './selector/fieldSelector';
import { ZaplifyStatusField } from './zaplify-status-field';
import { companyFieldValidator, contactFieldValidator } from './utils';

export const FieldMap = () => {
    const dispatch = useDispatch<AppDispatch>();
    const fields = useAppSelector((state) => state.pipedrive.fieldMapping).map((field, idx) => ({ ...field, idx }));

    const organizationFields = fields.filter((field) => field.organizationField !== undefined);
    const personFields = fields.filter((field) => field.personField !== undefined);

    const availablePersonFields = useAppSelector((state) => state.pipedrive.availableFields?.person || []).map(
        (contactField) => disableUsedField(contactField, personFields, 'personField', 'key')
    );
    const availableProspectFields = useAppSelector((state) => state.pipedrive.availableFields?.prospect || []);
    const availableOrganizationField = useAppSelector(
        (state) => state.pipedrive.availableFields?.organization || []
    ).map((organizationField) => disableUsedField(organizationField, organizationFields, 'organizationField', 'key'));

    const handleDeleteField = (index) => {
        dispatch(deleteField({ index: index }));
        dispatch(updatePipedriveSaveStatus());
    };

    const leftOnChange = (e, index) => {
        dispatch(updateField({ idx: index, field: 'prospectField', value: e.target.value }));
        dispatch(updatePipedriveSaveStatus());
    };

    const organizationRightOnChange = (e, index) => {
        dispatch(updateField({ idx: index, field: 'organizationField', value: e.target.value }));
        dispatch(updatePipedriveSaveStatus());
    };

    const personRightOnChange = (e, index) => {
        dispatch(updateField({ idx: index, field: 'personField', value: e.target.value }));
        dispatch(updatePipedriveSaveStatus());
    };

    const formattedProspectFields = availableProspectFields.map((field) => ({ name: field.key, label: field.name }));
    const formattedContactFields = availablePersonFields.map((contactField) => ({
        name: contactField.key,
        label: contactField.name,
        disabled: contactField.disabled,
    }));

    const formattedOrganizationFields = availableOrganizationField.map((organizationField) => ({
        name: organizationField.key,
        label: organizationField.name,
        disabled: organizationField.disabled,
    }));

    const linkedInMappingExist = formattedContactFields.some(
        (field) => field.label.toLowerCase() === 'linkedin profile url'
    );
    const campaignNameMappingExist = formattedContactFields.some(
        (field) => field.label.toLowerCase() === 'zaplify campaign name'
    );
    const mapLinkedInConnectionExist = formattedContactFields.some(
        (field) => field.label.toLowerCase() === 'zaplify is linkedin connected'
    );
    const prospectStatusMappingExist = formattedContactFields.some(
        (field) => field.label.toLowerCase() === 'zaplify prospect status'
    );
    const sizeMappingExists = formattedOrganizationFields.some((field) => field.label.toLowerCase() === 'company size');
    const industryMappingExists = formattedOrganizationFields.some(
        (field) => field.label.toLowerCase() === 'company industry'
    );
    const domainMappingExists = formattedOrganizationFields.some(
        (field) => field.label.toLowerCase() === 'company domain'
    );

    const addOrganizationFieldHandle = () => {
        dispatch(addOrganizationField());
        dispatch(updatePipedriveSaveStatus());
    };
    const addPersonFieldHandle = () => {
        dispatch(addPersonField());
        dispatch(updatePipedriveSaveStatus());
    };

    if (!availablePersonFields.length) {
        return (
            <FieldMappingContainer>
                <ProgressBar show={!availablePersonFields.length} />
            </FieldMappingContainer>
        );
    }

    return (
        <FieldMappingContainer>
            <FieldMapHeader />
            <Typography variant="body2">Person</Typography>
            <MapperContainer>
                <div>
                    {personFields.map((field) => (
                        <FieldSelector
                            field={contactFieldValidator(field)}
                            leftOptions={formattedProspectFields}
                            rightOptions={formattedContactFields}
                            leftOnChange={leftOnChange}
                            rightOnChange={personRightOnChange}
                            handleDeleteField={handleDeleteField}
                        />
                    ))}
                    {!linkedInMappingExist && <LinkedInField />}
                    {!mapLinkedInConnectionExist && <LinkedinConnectionStatusField />}
                    {!campaignNameMappingExist && <CampaignNameField />}
                    {!prospectStatusMappingExist && <ZaplifyStatusField />}
                </div>
                <Button
                    borderRadius="100px"
                    variant="outlined"
                    height="40px"
                    startIcon={<AddIcon />}
                    text="Add field"
                    onClick={addPersonFieldHandle}
                />
            </MapperContainer>
            <MapperContainer>
                <Typography variant="body2">Organization</Typography>
                <div>
                    {organizationFields.map((field) => (
                        <FieldSelector
                            field={companyFieldValidator(field)}
                            leftOptions={formattedProspectFields}
                            rightOptions={formattedOrganizationFields}
                            leftOnChange={leftOnChange}
                            rightOnChange={organizationRightOnChange}
                            handleDeleteField={handleDeleteField}
                        />
                    ))}
                    {!sizeMappingExists && <OrganizationSizeField />}
                    {!industryMappingExists && <OrganizationIndustryField />}
                    {!domainMappingExists && <OrganizationDomainField />}
                </div>
                <Button
                    borderRadius="100px"
                    variant="outlined"
                    height="40px"
                    startIcon={<AddIcon />}
                    text="Add field"
                    onClick={addOrganizationFieldHandle}
                />
            </MapperContainer>
            <Typography variant="body2">
                Don't see the property you're looking for? Create a new property in Pipedrive.
            </Typography>
            <Typography variant="body2">Outreach actions and status will be documented and saved as a note.</Typography>
        </FieldMappingContainer>
    );
};
