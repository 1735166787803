import { useState } from 'react';
import { MessageCircle, UserPlus, Calendar, Send, ChevronLeft, ChevronRight, RefreshCw } from 'lucide-react';
import { Textarea } from './ui/textarea';
import { Button } from './ui/button';
export interface Action {
    id: string;
    title: string;
    description: string;
    type: 'connect' | 'message' | 'schedule' | 'respond';
    contact: {
        name: string;
        role: string;
        company: string;
    };
}

export interface ActionCardsProps {
    actions: Action[];
}

export const ActionCard = ({ action, status }: { action: Action; status: 'active' | 'inactive' | 'prev' | 'next' }) => {
    const [message, setMessage] = useState('Hi ' + action.contact.name + ', I hope this message finds you well...');

    const getActionButton = () => {
        switch (action.type) {
            case 'connect':
                return (
                    <Button>
                        <UserPlus size={16} />
                        Connect
                    </Button>
                );
            case 'message':
                return (
                    <Button>
                        <MessageCircle size={16} />
                        Message
                    </Button>
                );
            case 'schedule':
                return (
                    <Button>
                        <Calendar size={16} />
                        Schedule
                    </Button>
                );
            case 'respond':
                return (
                    <Button>
                        <Send size={16} />
                        Respond
                    </Button>
                );
        }
    };

    const handleRegenerateMessage = () => {
        // In a real application, this would call an AI service to generate a new message
        setMessage('New generated message for ' + action.contact.name + '...');
    };

    const handleSendMessage = () => {
        // In a real application, this would send the message
        console.log('Sending message:', message);
    };

    return (
        <div
            className={`action-card absolute inset-0 bg-white/80 backdrop-blur-sm rounded-2xl p-6 shadow-lg
                  border border-gray-200/50 ${status}`}
        >
            <div className="space-y-4">
                <div className="space-y-2">
                    <h3 className="text-lg font-semibold">{action.title}</h3>
                    <p className="text-sm text-gray-600">{action.description}</p>
                </div>

                <div className="bg-white/50 rounded-xl p-4 space-y-2">
                    <h4 className="font-medium">{action.contact.name}</h4>
                    <p className="text-sm text-gray-600">{action.contact.role}</p>
                    <p className="text-sm text-gray-500">{action.contact.company}</p>
                </div>

                <div className="space-y-4">
                    <Textarea
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        className="min-h-[120px] resize-none bg-white/50"
                        placeholder="Enter your message..."
                    />

                    <div className="flex justify-between items-center">
                        <button onClick={handleRegenerateMessage} className="action-button bg-gray-50 text-gray-700">
                            <RefreshCw size={16} />
                            Regenerate
                        </button>

                        {getActionButton()}
                    </div>
                </div>
            </div>
        </div>
    );
};

export const ActionCards = ({ actions }: ActionCardsProps) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const goToNext = () => {
        setCurrentIndex((prev) => (prev + 1) % actions.length);
    };

    const goToPrev = () => {
        setCurrentIndex((prev) => (prev - 1 + actions.length) % actions.length);
    };

    const getCardStatus = (index: number): 'active' | 'inactive' | 'prev' | 'next' => {
        if (index === currentIndex) return 'active';

        // Calculate relative position from current index
        const relativePosition = (index - currentIndex + actions.length) % actions.length;

        if (relativePosition === 1) return 'next';
        if (relativePosition === actions.length - 1) return 'prev';
        return 'inactive';
    };

    return (
        <div className="relative h-[500px] select-none">
            <div className="card-stack relative h-full">
                {actions.map((action, index) => (
                    <ActionCard key={action.id} action={action} status={getCardStatus(index)} />
                ))}
            </div>

            <div className="absolute inset-x-0 bottom-[-24px] flex justify-center gap-4">
                <button onClick={goToPrev} className="nav-button">
                    <ChevronLeft className="w-6 h-6 text-gray-700" />
                </button>
                <button onClick={goToNext} className="nav-button">
                    <ChevronRight className="w-6 h-6 text-gray-700" />
                </button>
            </div>
        </div>
    );
};

export default ActionCards;
