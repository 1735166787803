import { ICommand, Topics } from '@zaplify/common';
import { AssistantSettingsDto } from '../dtos';

export class SetAssistantSettingsCommand extends ICommand<void> {
    public static override readonly TOPIC = Topics.CAMPAIGNS;

    constructor(
        public readonly userId: string,
        public readonly assistantSettingsDto: Partial<Omit<AssistantSettingsDto, 'userId'>>,
        public readonly assistantSettingsId?: string,
    ) {
        super();
    }
}
