import { FC, useState, useEffect, KeyboardEvent, useMemo, useRef } from 'react';
import { Button } from '@shadcn/ui/components/ui/button';
import { Card, CardContent } from '@shadcn/ui/components/ui/card';
import { Separator } from '@shadcn/ui/components/ui/separator';
import {
    Loader2,
    RefreshCw,
    Save,
    Image as ImageIcon,
    Smile,
    Bold,
    Italic,
    Link as LinkIcon,
    Underline as UnderlineIcon,
} from 'lucide-react';
import DOMPurify from 'dompurify';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useToast } from '@shadcn/ui/hooks/use-toast';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import TextAlign from '@tiptap/extension-text-align';
import Underline from '@tiptap/extension-underline';
import ResizeImageExtension from 'tiptap-extension-resize-image';
import imageCompression from 'browser-image-compression';
import { getZaplifySdk } from '@zaplify/sdk';
import { Popover, PopoverContent, PopoverTrigger } from '@shadcn/ui/components/ui/popover';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { cn } from '@shadcn/ui/lib/utils';
import { useAuth } from '../../../../../providers/authentication-provider';

const MAX_SIGNATURE_LENGTH = 20000;

export const useExtension = ({ placeholder }: { placeholder?: string }) => {
    return useMemo(() => {
        return [
            StarterKit,
            Link.configure({
                autolink: true,
                linkOnPaste: true,
                openOnClick: false,
            }).extend({
                inclusive: false,
            }),
            Underline,
            ResizeImageExtension.configure({
                inline: false,
            }),
            TextAlign.configure({
                types: ['heading', 'paragraph'],
                alignments: ['left', 'center', 'right', 'justify'],
            }),
            Placeholder.configure({
                placeholder,
            }),
        ];
    }, [placeholder]);
};

// Helper function to upload images to the server
const uploadImage = async (userId: string, image: File): Promise<string> => {
    const profilesSdk = getZaplifySdk().profiles;

    // Getting filetype of dropped file
    const fileType = image.type?.split('/')?.[1];

    let compressedImage: File | null = null;

    if (fileType === 'gif') {
        // No compression for .gif images. Library doesn't support it.
        compressedImage = image;
    } else {
        // Compressing image to max size 0.5 MB
        const options = { maxSizeMB: 0.5, maxWidthOrHeight: 1920 };
        compressedImage = await imageCompression(image, options);
    }

    if (!compressedImage) throw new Error('Failed to process image');

    // Converting image to base64, and stripping metadata prefix
    const base64ImageFull = await imageCompression.getDataUrlFromFile(compressedImage);
    const base64Image = base64ImageFull.split(',')[1];

    // Posting image to backend, receive image url from backend
    const imageUrl = await profilesSdk.user.uploadImage(
        userId,
        {
            base64Image: base64Image,
            fileType: fileType,
        },
        (error: any) => {
            throw new Error(error || 'Image upload error');
        }
    );

    return imageUrl;
};

interface EmailSignatureEditorProps {
    savedSignature: string;
    emailChannelAccountId: string;
    isOutlook: boolean;
    updateSignature: (signature: string, emailChannelAccountId: string) => Promise<void>;
    resyncGmailSignature?: (emailChannelAccountId: string) => Promise<{ signature: string }>;
    onSave?: () => Promise<void>;
}

export const EmailSignatureEditor: FC<EmailSignatureEditorProps> = ({
    savedSignature,
    emailChannelAccountId,
    isOutlook,
    updateSignature,
    resyncGmailSignature,
    onSave,
}) => {
    const {
        authState: { userId },
    } = useAuth();
    const { gmailEmailSignature } = useFlags();
    const { toast } = useToast();
    const sanitizedSaved = DOMPurify.sanitize(savedSignature);
    const [emailSignature, setEmailSignature] = useState(sanitizedSaved);
    const [isSaving, setIsSaving] = useState(false);
    const [isSyncing, setIsSyncing] = useState(false);
    const [isUploadingImage, setIsUploadingImage] = useState(false);
    const [linkUrl, setLinkUrl] = useState('');
    const [showLinkInput, setShowLinkInput] = useState(false);
    const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const extensions = useExtension({ placeholder: 'Enter your email signature' });

    const editor = useEditor({
        extensions,
        content: sanitizedSaved,
        onUpdate: ({ editor }) => {
            const html = editor.getHTML();
            if (html.length <= MAX_SIGNATURE_LENGTH) {
                setEmailSignature(html);
            } else {
                // If signature is too long, truncate it
                editor.commands.setContent(emailSignature);
                toast({
                    title: 'Signature too long',
                    description: `Email signature cannot exceed ${MAX_SIGNATURE_LENGTH} characters.`,
                    variant: 'destructive',
                });
            }
        },
    });

    useEffect(() => {
        if (editor && sanitizedSaved !== editor.getHTML()) {
            editor.commands.setContent(sanitizedSaved);
        }
    }, [sanitizedSaved, editor]);

    const syncSignature = async () => {
        if (!resyncGmailSignature || isOutlook) return;

        try {
            setIsSyncing(true);
            const { signature } = await resyncGmailSignature(emailChannelAccountId);
            const sanitized = DOMPurify.sanitize(signature);

            if (editor) {
                editor.commands.setContent(sanitized);
            }

            setEmailSignature(sanitized);
            toast({
                title: 'Signature synced',
                description: 'Successfully synced your Gmail signature.',
            });
        } catch (error) {
            toast({
                title: 'Sync failed',
                description: 'Failed to sync your Gmail signature. Please try again.',
                variant: 'destructive',
            });
        } finally {
            setIsSyncing(false);
        }
    };

    const saveSignature = async () => {
        try {
            setIsSaving(true);
            await updateSignature(emailSignature, emailChannelAccountId);

            if (onSave) {
                await onSave();
            }

            toast({
                title: 'Signature saved',
                description: 'Your email signature has been saved successfully.',
            });
        } catch (error) {
            toast({
                title: 'Save failed',
                description: 'Failed to save your email signature. Please try again.',
                variant: 'destructive',
            });
        } finally {
            setIsSaving(false);
        }
    };

    const setLink = () => {
        if (linkUrl) {
            // Add https:// if not present and not empty
            const url = linkUrl.trim();
            const formattedUrl = url.startsWith('http') ? url : `https://${url}`;

            editor?.chain().focus().extendMarkRange('link').setLink({ href: formattedUrl }).run();
            setShowLinkInput(false);
            setLinkUrl('');
        } else {
            editor?.chain().focus().extendMarkRange('link').unsetLink().run();
            setShowLinkInput(false);
        }
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            setLink();
        } else if (event.key === 'Escape') {
            setShowLinkInput(false);
            setLinkUrl('');
        }
    };

    const handleEmojiSelect = (emoji: any) => {
        editor?.chain().focus().insertContent(emoji.native).run();
        setEmojiPickerOpen(false);
    };

    const handleImageUpload = async () => {
        fileInputRef.current?.click();
    };

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file || !editor) return;

        try {
            setIsUploadingImage(true);

            // Upload the image using the Zaplify SDK
            const imageUrl = await uploadImage(userId, file);

            // Insert the image into the editor
            editor.chain().focus().setImage({ src: imageUrl }).run();

            toast({
                title: 'Image uploaded',
                description: 'Image has been successfully added to your signature.',
            });
        } catch (error: any) {
            console.error('Error uploading image:', error);
            toast({
                title: 'Image upload failed',
                description: error.message || 'Failed to upload image. Please try again.',
                variant: 'error',
            });
        } finally {
            // Reset the input
            event.target.value = '';
            setIsUploadingImage(false);
        }
    };

    // Handle emoji picker clicks to prevent them from closing the popover
    const handleEmojiPickerClick = (e: React.MouseEvent) => {
        e.stopPropagation();
    };

    if (!editor) {
        return <div className="py-4 text-center">Loading editor...</div>;
    }

    return (
        <div className="space-y-4">
            <h4 className="text-md font-medium">Email Signature</h4>

            <Card>
                <CardContent className="p-0">
                    <div className="text-editor-container">
                        {/* Simplified toolbar with direct buttons */}
                        <div className="border-b p-2 flex flex-wrap gap-2 bg-gray-50 rounded-t-md">
                            {/* Basic formatting */}
                            <Button
                                variant="ghost"
                                size="sm"
                                onClick={() => editor.chain().focus().toggleBold().run()}
                                className={editor.isActive('bold') ? 'bg-gray-200' : ''}
                                title="Bold"
                            >
                                <Bold className="h-4 w-4" />
                            </Button>
                            <Button
                                variant="ghost"
                                size="sm"
                                onClick={() => editor.chain().focus().toggleItalic().run()}
                                className={editor.isActive('italic') ? 'bg-gray-200' : ''}
                                title="Italic"
                            >
                                <Italic className="h-4 w-4" />
                            </Button>

                            {/* Link */}
                            <Button
                                variant="ghost"
                                size="sm"
                                onClick={() => setShowLinkInput(!showLinkInput)}
                                className={editor.isActive('link') ? 'bg-gray-200' : ''}
                                title="Add Link"
                            >
                                <LinkIcon className="h-4 w-4" />
                            </Button>

                            <Separator orientation="vertical" className="h-6 mx-1" />

                            {/* Direct buttons for image and emoji */}
                            <Button
                                variant="ghost"
                                size="sm"
                                onClick={handleImageUpload}
                                title="Insert Image"
                                disabled={isUploadingImage}
                            >
                                {isUploadingImage ? (
                                    <Loader2 className="h-4 w-4 animate-spin" />
                                ) : (
                                    <ImageIcon className="h-4 w-4" />
                                )}
                            </Button>

                            <Popover open={emojiPickerOpen} onOpenChange={setEmojiPickerOpen}>
                                <PopoverTrigger asChild>
                                    <Button variant="ghost" size="sm" title="Insert Emoji">
                                        <Smile className="h-4 w-4" />
                                    </Button>
                                </PopoverTrigger>
                                <PopoverContent
                                    className="p-0 w-auto"
                                    side="bottom"
                                    sideOffset={5}
                                    onClick={handleEmojiPickerClick}
                                >
                                    <Picker
                                        data={data}
                                        onEmojiSelect={handleEmojiSelect}
                                        theme="light"
                                        previewPosition="none"
                                        skinTonePosition="none"
                                    />
                                </PopoverContent>
                            </Popover>
                        </div>

                        {/* Link input popup */}
                        <div className={cn('p-2 border-b flex gap-2 items-center', showLinkInput ? 'block' : 'hidden')}>
                            <input
                                type="text"
                                value={linkUrl}
                                onChange={(e) => setLinkUrl(e.target.value)}
                                onKeyDown={handleKeyDown}
                                placeholder="Enter URL"
                                className="flex-1 p-1 border rounded text-sm"
                                autoFocus
                            />
                            <Button size="sm" onClick={setLink}>
                                Set Link
                            </Button>
                            <Button size="sm" variant="ghost" onClick={() => setShowLinkInput(false)}>
                                Cancel
                            </Button>
                        </div>

                        {/* Editor content */}
                        <div className="p-2 min-h-[150px] h-full bg-white rounded-b-md">
                            <EditorContent
                                editor={editor}
                                className="h-full [&_.ProseMirror]:h-full [&_.ProseMirror]:min-h-[150px] [&_.ProseMirror]:outline-none"
                            />
                        </div>
                    </div>
                </CardContent>
            </Card>

            <div className="flex justify-between gap-2">
                {!isOutlook && resyncGmailSignature && (
                    <Button
                        variant="outline"
                        onClick={syncSignature}
                        disabled={isSyncing || isSaving}
                        className="flex gap-2"
                    >
                        {isSyncing ? <Loader2 className="h-4 w-4 animate-spin" /> : <RefreshCw className="h-4 w-4" />}
                        Sync from Gmail
                    </Button>
                )}
                <Button onClick={saveSignature} disabled={isSaving || isSyncing} className="flex gap-2 ml-auto">
                    {isSaving ? <Loader2 className="h-4 w-4 animate-spin" /> : <Save className="h-4 w-4" />}
                    Save Signature
                </Button>
            </div>

            {/* Hidden file input for image upload */}
            <input type="file" ref={fileInputRef} onChange={handleFileChange} accept="image/*" className="hidden" />
        </div>
    );
};
