import {
    LinkedinActivityType,
    LinkedinAccountActivitiesDto,
    LinkedinTriggerActivityDto,
    ConnectionStatus,
    LinkedinPersonMadePostActivityDto,
} from '@zaplify/campaigns';
import { getZaplifySdk } from '@zaplify/sdk';
import { atom, useAtom, useSetAtom } from 'jotai';
import { useCallback, useRef, useState } from 'react';
import { IProspectToSync } from '@zaplify/campaigns';
import { isPrivateEmail, sleep } from '@zaplify/utils';
import { atomWithReset } from 'jotai/utils';
import {
    ProspectDataDto,
    ProspectDto,
    ProspectLinkedinProfileVerifiedStatus,
    SetProspectLinkedinDataPayload,
} from '@zaplify/prospects';
import { SyncProspectRequestDto } from '@zaplify/services/messaging/shared';
import { useAuth } from '../providers/authentication-provider';
import { useLinkedin } from './use-linkedin';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSdk } from '../sdk';
import { useWebExtension } from './use-web-extension';

function log(message: string) {
    console.log(`🚀 [useProspectSync] ${message}`);
}

export const useProspectSync = () => {
    const [prospectIdsCurrentlySyncing, setProspectIdsCurrentlySyncing] = useState<Set<string>>(new Set());
    // const setPingTrackersStatus = useSetAtom(pingTrackersStatusAtom);
    const { linkedinAccount } = useLinkedin();
    const { authState } = useAuth();
    const { extensionStatus } = useLinkedin();
    const {
        prospect: { updateProspectData, getProspectById },
        trackers: { syncProspect: syncProspectMutation },
    } = useSdk();

    const [failedProspectSyncJobs, setFailedProspectSyncJobs] = useState<IProspectToSync[]>([]);
    const {
        getLinkedInProfileFull,
        getLinkedInProfile,
        getLinkedinPosts,
        getConversationFromId,
        getMessagesFromConversation,
        enrichProspectExperiences,
    } = useWebExtension();
    const syncAttemptsRef = useRef<Record<string, number>>({});
    const memberId = linkedinAccount?.LINKEDIN.userId;

    const { mutateAsync: mutateProspectData } = useMutation(updateProspectData());
    const { mutateAsync: mutateSyncProspect } = useMutation(syncProspectMutation());
    const queryClient = useQueryClient();

    const runBackgroundJobs = useCallback(async () => {
        try {
            const trackersSdk = await getZaplifySdk().profiles.trackers;
            if (!linkedinAccount?.id) {
                return;
            }

            const jobs = await trackersSdk.getProspectsToSync();
            console.log('Running background jobs', jobs);
            if (!jobs?.length) {
                return;
            }

            await syncMany(jobs);
        } catch (error) {
            console.error('Error running background jobs', error);
        }
    }, [linkedinAccount, extensionStatus]);

    const syncMany = async (jobs: IProspectToSync[]) => {
        for (const job of jobs) {
            await syncProspect(job);
            await sleep(2000);
        }
    };

    const getProspectsToSync = async (prospectIds?: string[]) => {
        const trackersSdk = getZaplifySdk().profiles.trackers;
        const jobs = await trackersSdk.getProspectsToSync(prospectIds);
        return jobs;
    };

    const syncProspect = async (job: IProspectToSync): Promise<void> => {
        log(`syncProspect | Syncing prospect ${job.prospectId}. Stack: ${new Error().stack}`);

        if (
            extensionStatus === 'NOT_AUTHENTICATED' ||
            extensionStatus === 'NOT_INSTALLED' ||
            authState.isImpersonating ||
            !memberId
        ) {
            console.warn('Not running syncProspect', {
                extensionStatus,
                isImpersonating: authState.isImpersonating,
                memberId,
            });
            return;
        }

        const { prospectId } = job;
        const trackersSdk = getZaplifySdk().profiles.trackers;
        try {
            // Check sync attempts for this prospect
            const currentAttempts = syncAttemptsRef.current[job.prospectId] || 0;
            if (currentAttempts >= 3) {
                console.warn(`Skipping sync for prospect ${job.prospectId} - already attempted 3 times`);
                setFailedProspectSyncJobs((prev) => prev.filter((j) => j.prospectId !== job.prospectId));
                return;
            }

            // Increment attempt counter
            syncAttemptsRef.current[job.prospectId] = currentAttempts + 1;

            setProspectIdsCurrentlySyncing((prev) => new Set([...prev, prospectId]));
            const prospect = await queryClient.fetchQuery(getProspectById(prospectId));
            let linkedinResult: SyncProspectRequestDto & { prospectData: ProspectDataDto };

            if (!job.linkedinProfileUrl) {
                job.linkedinProfileUrl = prospect.data.linkedinProfileUrl;
            }

            if (job.linkedinProfileUrl) {
                linkedinResult = await fetchLinkedinProfile(prospect, job.linkedinProfileUrl);

                if (linkedinResult?.prospectData) {
                    console.log('Sync ran full profile, updating prospect data', linkedinResult?.prospectData);

                    if (ProspectDataDto.hasEmail(prospect.data) && linkedinResult?.prospectData?.email) {
                        linkedinResult.prospectData.email = undefined;
                        console.log(
                            `Prospect has email, not sending it for update. Existing email: ${prospect.data.email}, found email: ${linkedinResult?.prospectData.email}`
                        );
                    }

                    if (linkedinResult.prospectData.email && isPrivateEmail(linkedinResult.prospectData.email)) {
                        console.log('Email is private, not sending it for update');
                        linkedinResult.prospectData.email = undefined;
                    }

                    // Ensure all null values are undefined so we dont overwrite existing values with null
                    Object.keys(linkedinResult.prospectData).forEach((key) => {
                        if (linkedinResult.prospectData[key] === null) {
                            linkedinResult.prospectData[key] = undefined;
                        }
                    });

                    await mutateProspectData({
                        prospectId,
                        prospectData: linkedinResult?.prospectData,
                    });
                }
            }

            await mutateSyncProspect({
                prospectId: prospectId,
                linkedinData: linkedinResult?.linkedinData,
            });

            setFailedProspectSyncJobs((prev) => {
                return prev.filter((j) => j.prospectId !== prospectId);
            });
        } catch (error) {
            console.error(`Error syncing prospect ${prospectId}`, error);
            setFailedProspectSyncJobs((prev) => {
                const failedJobs = prev.filter((j) => j.prospectId !== prospectId);
                const retries = job.retries ?? 0;

                if (job.retries >= 3) {
                    return failedJobs;
                }

                const currentJob = { ...job, retries: retries + 1 };
                return [...failedJobs, currentJob];
            });
        } finally {
            setProspectIdsCurrentlySyncing((prev) => {
                prev.delete(prospectId);
                return new Set([...prev]);
            });
            // setPingTrackersStatus({
            //     status: 'active',
            //     timestamp: Date.now(),
            // });
        }
    };

    const fetchLinkedinProfile = async (
        prospect: ProspectDto,
        linkedinProfileUrl: string
    ): Promise<SyncProspectRequestDto & { prospectData: ProspectDataDto }> => {
        // setPingTrackersStatus({
        //     status: 'paused',
        //     timestamp: Date.now(),
        // });

        console.log('Syncing prospect', prospect.id);

        let linkedinProfile: {
            memberId: string;
            profilePicture: string;
            profileUrl: string;
            connectionStatus: ConnectionStatus;
        } | null = null;
        let prospectData: ProspectDataDto | null = null;
        let errorReason: string | null = null;

        try {
            console.log('Sync is fetching full profile', prospect.id, linkedinProfileUrl);
            const res = await getLinkedInProfileFull({ url: linkedinProfileUrl });

            if (res.success === false) {
                errorReason = res.reason;
            } else {
                linkedinProfile = {
                    connectionStatus: res.connectionStatus,
                    memberId: res.prospectData.linkedinUserId,
                    profilePicture: res.prospectData.linkedinProfileImgUrl,
                    profileUrl: res.prospectData.linkedinProfileUrl,
                };
                prospectData = res.prospectData;

                console.log('Sync is enriching prospect experiences', prospect.id, linkedinProfileUrl);
                const experiencesWithCompany = await enrichProspectExperiences(res.prospectData);
                prospectData.experiences = experiencesWithCompany;
            }
        } catch (error) {
            if ((error as any)?.reason && (error as any).reason === 'profile_unavailable') {
                errorReason = 'profile_unavailable';
            } else {
                console.log('Sync is fetching old, partial profile', prospect.id, linkedinProfileUrl, error);
                try {
                    const res = await getLinkedInProfile(linkedinProfileUrl);
                    if (res.error) {
                        errorReason = res.reason;
                    } else {
                        linkedinProfile = {
                            connectionStatus: res?.status,
                            memberId: res?.memberId,
                            profilePicture: res?.profilePicture,
                            profileUrl: res?.profileUrl,
                        };
                    }
                } catch (error) {
                    errorReason = (error as any)?.reason;
                }
            }
        }

        if (errorReason) {
            if (errorReason === 'profile_unavailable') {
                console.warn(`LinkedIn profile is invalid for prospect: ${prospect.id}`);
                await updateProspectDataWithLinkedinData({
                    originalProspect: prospect,
                    linkedinProspectData: {
                        linkedinProfileVerification: {
                            status: ProspectLinkedinProfileVerifiedStatus.profile_not_found,
                            checkedAt: new Date(),
                        },
                    },
                });
                return {
                    prospectId: prospect.id,
                    linkedinData: {
                        linkedinProfileStatus: ProspectLinkedinProfileVerifiedStatus.profile_not_found,
                    },
                    prospectData: prospectData,
                };
            } else {
                console.warn(
                    `Got unknown error when fetching linkedin profile: ${prospect.id}, errorReason: ${errorReason}`
                );
                return;
            }
        }

        const linkedinUserId = linkedinProfile?.memberId;

        // @TODO if profile is unavailable triggeer message generation either way
        // @TODO chrome extension should distinguish between random error and profile not found

        if (!linkedinUserId) {
            console.error(`LinkedIn userId not found for prospect: ${prospect.id}`);
            throw new Error(`LinkedIn profile not found for prospect: ${prospect.id}`);
        }

        await updateProspectDataWithLinkedinData({
            originalProspect: prospect,
            linkedinProspectData: prospectData,
        });

        console.log(`Updated prospect ${prospect.id} with linkedinUserId: ${linkedinUserId}`);

        const getConversationResult = await getConversationFromId({
            memberId: memberId,
            prospectMemberIds: [linkedinUserId],
            personData: {
                firstName: prospect.data.firstName,
                lastName: prospect.data.lastName,
                memberId: prospect.data.linkedinUserId,
            },
        });

        console.log('got getConversationResult', getConversationResult);
        const scanStartedAt = Date.now();
        const linkedinActivities: LinkedinAccountActivitiesDto = {
            lastActivityTimestamp: scanStartedAt,
            messagesSyncedUntil: scanStartedAt,
            connectionsSyncedUntil: scanStartedAt,
            userActivities: [
                {
                    connectionStatus: linkedinProfile.connectionStatus,
                    linkedinUserId: linkedinUserId,
                    activities: [],
                },
            ],
        };

        if (
            !(getConversationResult as any)?.error &&
            Array.isArray(getConversationResult) &&
            getConversationResult.length > 0
        ) {
            const activities: LinkedinTriggerActivityDto[] = [];
            const conversationIds = getConversationResult.map((c) => c.conversationId);

            for (const conversationId of conversationIds) {
                const [getMessagesResult] = await Promise.all([
                    getMessagesFromConversation({
                        memberId: memberId,
                        conversationId: conversationId,
                        deliveredAtBeforeTimestamp: Date.now(),
                        countBefore: 50,
                        cacheStaleTime: 0,
                    }),
                ]);

                if (!(getMessagesResult as any).error) {
                    const messages = getMessagesResult;
                    const conversationActivities = messages?.map((message) => {
                        return {
                            type:
                                message.direction === 'IN'
                                    ? LinkedinActivityType.CONVERSATION_REPLY
                                    : LinkedinActivityType.MESSAGE_SENT,
                            timestamp: message.timestamp,
                            data: {
                                subject: message.data.subject,
                                message: message.data.message,
                                messageId: message.data.messageId,
                                conversationId: message.data.conversationId,
                                attachments: message.data.attachments,
                            },
                        };
                    });

                    activities.push(...conversationActivities);
                } else {
                    console.error(
                        'Error getting messages:',
                        (getMessagesResult as any).error,
                        (getMessagesResult as any).reason
                    );
                }
            }

            linkedinActivities.userActivities[0].activities.push(
                ...activities.sort((a, b) => b.timestamp - a.timestamp)
            );
        } else {
            console.warn(`Conversation not found for prospect ${prospect.id}`, getConversationResult);
        }

        const linkedinPosts = await getLinkedinPosts(linkedinUserId).catch((err) => {
            console.error('Getting Linkedin posts failed', JSON.stringify(err));
            return [];
        });
        if (linkedinPosts?.length) {
            const posts = linkedinPosts.map((post): LinkedinPersonMadePostActivityDto => {
                return new LinkedinPersonMadePostActivityDto(Date.now(), { post: post });
            });

            if (posts.length > 0) {
                linkedinActivities.userActivities[0].activities.push(posts[0]);
            }
        }
        return {
            prospectId: prospect.id,
            linkedinData: {
                connectionStatus: linkedinProfile.connectionStatus,
                linkedinProfileStatus: ProspectLinkedinProfileVerifiedStatus.verified,
                linkedinUserId: linkedinUserId,
                linkedinActivities: {
                    triggerActivitiesByLiUser: linkedinActivities,
                    updateSyncedUntilTimestamps: false,
                },
            },
            prospectData: prospectData,
        };
    };

    const updateProspectDataWithLinkedinData = async ({
        originalProspect,
        linkedinProspectData,
    }: {
        originalProspect: ProspectDto;
        linkedinProspectData: Partial<ProspectDataDto>;
    }) => {
        /**
         * Validate email (and dont overwrite if it exists or is private)
         */
        if (ProspectDataDto.hasEmail(originalProspect.data) && linkedinProspectData?.email) {
            linkedinProspectData.email = undefined;
            console.log(
                `Prospect has email, not sending it for update. Existing email: ${originalProspect.data.email}, found email: ${linkedinProspectData.email}`
            );
        }
        if (linkedinProspectData.email && isPrivateEmail(linkedinProspectData.email)) {
            console.log('Email is private, not sending it for update');
            linkedinProspectData.email = undefined;
        }

        // Ensure all null values are undefined so we dont overwrite existing values with null
        Object.keys(linkedinProspectData).forEach((key) => {
            if (linkedinProspectData[key] === null) {
                linkedinProspectData[key] = undefined;
            }
        });

        await mutateProspectData({
            prospectId: originalProspect.id,
            prospectData: linkedinProspectData,
        });
    };

    return {
        fetchLinkedinProfile,
        prospectIdsCurrentlySyncing,
        syncProspect,
        syncMany,
        getProspectsToSync,
        runBackgroundJobs,
    };
};
