import { ChannelType } from '@zaplify/channel-accounts/shared';
import { IQuery, Topics } from '@zaplify/common';
import { MessageType } from '../enums';

export class GetRegeneratedMessageQuery extends IQuery<{ subjectLine?: string; content: string }> {
    public static override readonly TOPIC = Topics.CAMPAIGNS;
    constructor(
        public readonly userId: string,
        public readonly prospectId: string,
        public readonly actionType: MessageType,
        public readonly feedback: string,
        public readonly content: string,
        public readonly subjectLine?: string,
        public readonly channelType?: ChannelType,
    ) {
        super();
    }
}
