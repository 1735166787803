import { IconButton, Typography } from '@mui/material';
import TooltipCustom from '../atoms/tooltipCustom';
import { InfoIcon } from '../icons/info';

export const VatInformation = ({ vat, customerCountry }: { vat: string; customerCountry: string }) => {
    if (!vat) {
        return null;
    }

    return (
        <>
            <Typography style={{ color: '#6B7985' }}>Incl. {vat} VAT</Typography>
            <TooltipCustom
                title={
                    customerCountry === 'SE'
                        ? 'VAT ("Moms") applies for Swedish companies.'
                        : 'To remove VAT, enter your company VAT-number in the billing address section.'
                }
                arrow={true}
            >
                <IconButton size={'small'} style={{ paddingRight: 0, color: '#CDD1DA' }}>
                    <InfoIcon style={{ fontSize: 18 }} />
                </IconButton>
            </TooltipCustom>
        </>
    );
};
