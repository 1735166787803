import { forwardRef, Suspense, useEffect, useState } from 'react';
import { useActions } from '../../../../hooks/use-actions';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSnoozeDialog } from '../../../../components/dialogs/snooze-dialog';
import { Card } from '@shadcn/ui/components/ui/card';
import { ContactAvatar } from '../../../../components/contact-avatar';
import { ChevronDown, Ellipsis, Sparkles, X, Clock, ChevronUp } from 'lucide-react';
import { motion } from 'motion/react';
import { cn } from '@shadcn/ui/lib/utils';
import { CardConversation } from '../card-conversation';
import { MessageListSkeleton } from '../message-list';
import { ActionBadge } from '../../../../components/action-badges/action-badge';
import { Tooltip, TooltipContent, TooltipTrigger } from '@shadcn/ui/components/ui/tooltip';
import { ActionCardDetails } from '../action-card-details';
import { Skeleton } from '@shadcn/ui/components/ui/skeleton';
import { ConnectionStatusBadge } from '../../../actions/components/connection-status-badge';
import { useAccountDetailsDialog } from '../../../../components/dialogs/account-details-dialog';
import { CircleCheckAnimation } from './circle-check-animation';
import { useActionCardDetails } from '../../hooks/use-action-card-details';
import { Button } from '@shadcn/ui/components/ui/button';
import { ProspectDataWithId } from '@zaplify/prospects';

interface ActionCardProps {
    prospectId: string;
    prospectData: ProspectDataWithId<
        | 'fullName'
        | 'linkedinUserId'
        | 'linkedinProfileImgUrl'
        | 'occupationTitle'
        | 'organizationName'
        | 'organizationId'
    >;
    onExpand?: () => void;
    isCompleted?: boolean;
    onRemoveCard?: () => void;
    baseUrl?: string;
}

export const ActionCard = forwardRef<HTMLDivElement, ActionCardProps>(
    ({ prospectId, prospectData, onExpand, isCompleted, onRemoveCard, baseUrl }, ref) => {
        const hoverDelay = 50;
        const pageProspectId = useParams().prospectId;
        const {
            getAction,
            getCompletedActions,
            handleSnooze: handleSnoozeAction,
            handleStopSuggestingActions,
        } = useActions();
        const { openSnoozeDialog } = useSnoozeDialog();
        const scheduledAction = getAction(prospectId);
        const completedActions = getCompletedActions(prospectId);
        const action = scheduledAction || completedActions[0];
        const navigate = useNavigate();
        const { isOpen: isDetailsOpen, onClose, openDetails } = useActionCardDetails();
        const { openAccountDetailsDialog } = useAccountDetailsDialog();
        const location = useLocation();

        const urlPath = baseUrl || '/new/action-feed';

        useEffect(() => {
            console.log('action', action);
        }, [action]);

        const handleCardClick = (disableCollapse?: boolean) => {
            if (isTextSelected()) {
                return;
            }
            if (onExpand && pageProspectId !== prospectId) {
                onExpand();
            }

            if (pageProspectId !== prospectId) {
                navigate({ pathname: `${urlPath}/${prospectId}`, search: location.search });
            }

            if (!disableCollapse && pageProspectId === prospectId) {
                navigate({
                    pathname: urlPath,
                    search: location.search,
                });
            }
        };

        // When the card is clicked, open the details panel
        useEffect(() => {
            if (pageProspectId === prospectId) {
                openDetails();
            }
        }, [pageProspectId]);

        // Add escape key handler
        useEffect(() => {
            const handleEscape = (e: KeyboardEvent) => {
                if (e.key === 'Escape' && pageProspectId === prospectId) {
                    navigate({
                        pathname: urlPath,
                        search: location.search,
                    });
                }
            };

            document.addEventListener('keydown', handleEscape);
            return () => document.removeEventListener('keydown', handleEscape);
        }, [pageProspectId, prospectId, navigate, location.search]);

        const handleSnoozeClick = (e: React.MouseEvent<HTMLButtonElement>, snoozeType: 'later' | 'not-interested') => {
            e.stopPropagation();
            if (snoozeType === 'not-interested') {
                handleStopSuggestingActions(prospectId);
            } else {
                handleSnoozeAction(prospectId, 'auto');
            }
            onRemoveCard?.();
            navigate({ pathname: urlPath, search: location.search });
            e.preventDefault();
        };

        const handleAccountDetailsClick = (e: React.MouseEvent<HTMLSpanElement>) => {
            e.stopPropagation();
            if (prospectData?.organizationId) {
                openAccountDetailsDialog(prospectData.organizationId);
            }
        };

        const [goal, setGoal] = useState(action?.goal || 'Initiate a conversation');
        useEffect(() => {
            setGoal((prev) => {
                if ((action as any)?.isGenerating) return 'Thinking...';
                return action?.goal || prev;
            });
        }, [action]);
        const [topics, setTopics] = useState(action?.ConversationMetaData?.topics || 'Introduction');
        useEffect(() => {
            setTopics((prev) => {
                return action?.ConversationMetaData?.topics || prev;
            });
        }, [action]);

        // Prepare content that won't change during loading
        const fullName = prospectData?.fullName || '';
        const organizationName = prospectData?.organizationName || '';

        // Add this helper function near the top of the component
        const isTextSelected = () => {
            return window.getSelection()?.toString().length > 0;
        };

        return (
            <motion.div
                className="action-card relative flex w-full justify-center"
                id={`action-card-${prospectId}`}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={
                    isCompleted
                        ? {
                              opacity: 0,
                              x: 100,
                              transition: {
                                  duration: 0.2,
                                  ease: 'easeInOut',
                                  delay: 0.4,
                              },
                          }
                        : { opacity: 0, y: -10 }
                }
            >
                <div
                    className={cn(
                        'flex justify-center max-h-[min(88dvh,1000px)] w-full h-fit'
                        // pageProspectId === prospectId ? 'h-[calc(93vh-84px)]' : ''
                    )}
                >
                    <Card
                        className={cn(
                            'action-card-main',
                            'w-full group z-10 flex flex-col p-4 gap-2 transition-all duration-200 ease-in-out overflow-hidden',
                            pageProspectId !== prospectId &&
                                'transition-all duration-200 hover:shadow-md hover:-translate-y-1 cursor-pointer action-card-main-collapsed',
                            pageProspectId === prospectId && '-translate-y-1 shadow-xl action-card-main-expanded', // action-card-main-expanded used for product tour
                            isDetailsOpen && pageProspectId === prospectId && '-translate-x-[calc(280px/2)]'
                        )}
                        id={`action-card-main-${prospectId}`}
                        onClick={() => pageProspectId !== prospectId && handleCardClick(true)}
                        ref={ref}
                    >
                        {/* Top Section - Always clickable */}
                        <div
                            className={cn('flex flex-col gap-2', pageProspectId === prospectId && 'cursor-pointer')}
                            onClick={(e) => {
                                if (pageProspectId === prospectId) {
                                    handleCardClick(false);
                                }
                            }}
                        >
                            <div className="flex flex-row gap-2 w-full justify-between max-w-full">
                                <div className="flex flex-row gap-2 items-center flex-1 min-w-0">
                                    <div className="flex items-end">
                                        <ContactAvatar
                                            fullName={prospectData?.fullName}
                                            avatarUrl={prospectData?.linkedinProfileImgUrl}
                                        />
                                        <ConnectionStatusBadge
                                            prospectId={prospectId}
                                            collapsed
                                            className="-ml-2 -mb-1 z-10"
                                        />
                                    </div>
                                    <div className="flex flex-row gap-2 max-w-full">
                                        <div className="flex flex-shrink-0 min-w-[24px] min-h-[20px]">
                                            {!prospectData?.fullName ? (
                                                <Skeleton className="w-24 h-5 rounded-md" />
                                            ) : (
                                                <span className="font-medium">{fullName}</span>
                                            )}
                                        </div>
                                        {!!prospectData?.fullName && fullName && (
                                            <>
                                                <span className="text-muted-foreground">•</span>
                                                <Tooltip delayDuration={500}>
                                                    <TooltipTrigger asChild>
                                                        <div className="flex flex-1 min-w-[32px] min-h-[20px]">
                                                            <span
                                                                className={cn('text-muted-foreground truncate', {
                                                                    'cursor-pointer hover:underline':
                                                                        prospectData?.organizationId,
                                                                })}
                                                                onClick={handleAccountDetailsClick}
                                                            >
                                                                {organizationName}
                                                            </span>
                                                        </div>
                                                    </TooltipTrigger>
                                                    {prospectData?.organizationId && (
                                                        <TooltipContent>
                                                            <p>View company</p>
                                                        </TooltipContent>
                                                    )}
                                                </Tooltip>
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="flex flex-row gap-2 items-center flex-shrink-0">
                                    <ActionBadge prospectId={prospectId} isCompleted={isCompleted} />
                                    <Tooltip delayDuration={500}>
                                        <TooltipTrigger asChild>
                                            <div
                                                className={cn(
                                                    `flex-row items-center -m-1 p-1 flex opacity-0 group-hover:opacity-100 hover:bg-background-secondary rounded-md transition-all duration-200 delay-${hoverDelay}`
                                                )}
                                                // whileHover={{ scale: 1.05 }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    openSnoozeDialog({ prospectId });
                                                    e.stopPropagation();
                                                }}
                                            >
                                                <Ellipsis className="w-5 h-5 text-muted-foreground" />
                                            </div>
                                        </TooltipTrigger>
                                        <TooltipContent>
                                            <p>Manage...</p>
                                        </TooltipContent>
                                    </Tooltip>
                                </div>
                            </div>

                            <div className="flex flex-row gap-1 w-full text-sm">
                                <span className="text-muted-foreground">Topic</span>
                                <span className="text-muted-foreground">•</span>
                                <span>{topics}</span>
                            </div>
                        </div>

                        {/* Goal section - Always clickable */}
                        <Tooltip delayDuration={300}>
                            <TooltipTrigger asChild>
                                <div
                                    className={cn(
                                        'flex justify-start items-center gap-2 w-full text-sm rounded-xl bg-background-brand-secondary-section p-2',
                                        (action as any)?.isGenerating && 'animate-pulse',
                                        pageProspectId === prospectId && 'cursor-pointer'
                                    )}
                                    onClick={(e) => {
                                        if (pageProspectId === prospectId) {
                                            handleCardClick(false);
                                        }
                                    }}
                                >
                                    {isCompleted ? (
                                        <CircleCheckAnimation className="w-4 h-4 text-foreground-success-primary" />
                                    ) : (
                                        <Sparkles className="w-4 h-4 text-foreground-brand-secondary" />
                                    )}
                                    <span>{goal}</span>
                                </div>
                            </TooltipTrigger>
                            <TooltipContent side="left" className="max-w-[200px]">
                                <p>{isCompleted ? 'Completed goal' : 'Suggested goal with the next message'}</p>
                            </TooltipContent>
                        </Tooltip>

                        {/* Conversation section - Not clickable when expanded */}
                        <Suspense fallback={<MessageListSkeleton />}>
                            <CardConversation
                                prospectId={prospectId}
                                isCompleted={isCompleted}
                                className={cn(
                                    'overflow-x-hidden opacity-80 group-hover:opacity-100 transition-all duration-200',
                                    { 'opacity-100': pageProspectId === prospectId }
                                )}
                                preview={pageProspectId !== prospectId}
                            />
                        </Suspense>

                        {/* Chin section - Not clickable when expanded except for the collapse button */}
                        <div
                            className={cn(
                                'flex items-center transition-all duration-200 ease-in-out text-text-secondary -mt-0 -mb-2',
                                pageProspectId === prospectId && 'opacity-100'
                            )}
                        >
                            {/* Expand Button */}
                            <div
                                className={cn(
                                    'flex flex-row flex-1 justify-center w-full gap-2 items-center ease-in-out transition-all duration-50 cursor-pointer grow',
                                    pageProspectId === prospectId
                                        ? 'opacity-0 hover:opacity-100'
                                        : 'opacity-0 group-hover:opacity-100'
                                )}
                                onClick={(e) => {
                                    handleCardClick(false);
                                }}
                            >
                                {pageProspectId === prospectId ? (
                                    <ChevronUp className="w-4 h-4" />
                                ) : (
                                    <ChevronDown className="w-4 h-4" />
                                )}
                                <span>{pageProspectId === prospectId ? 'Collapse' : 'Expand'}</span>
                            </div>

                            {/* Snooze Buttons */}
                            <div className="flex flex-row justify-end gap-1 shrink-0">
                                <Tooltip delayDuration={200}>
                                    <TooltipTrigger asChild>
                                        <Button
                                            variant="ghost"
                                            className="h-7 px-4"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleSnoozeClick(e, 'later');
                                            }}
                                        >
                                            <Clock className="w-4 h-4" />
                                        </Button>
                                    </TooltipTrigger>
                                    <TooltipContent>
                                        <p>Remind me later</p>
                                    </TooltipContent>
                                </Tooltip>
                                <Tooltip delayDuration={200}>
                                    <TooltipTrigger asChild>
                                        <Button
                                            variant="ghost"
                                            className="h-7 px-4"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleSnoozeClick(e, 'not-interested');
                                            }}
                                        >
                                            <X className="w-4 h-4" />
                                        </Button>
                                    </TooltipTrigger>
                                    <TooltipContent>
                                        <p>Not interested</p>
                                    </TooltipContent>
                                </Tooltip>
                            </div>
                        </div>
                    </Card>
                    {prospectId === pageProspectId && (
                        <div className="max-w-0 overflow-visible" onClick={(e) => isDetailsOpen && e.stopPropagation()}>
                            <ActionCardDetails
                                className={cn('-translate-y-1 min-w-[280px] h-full -translate-x-[calc(280px/2)]')}
                            />
                        </div>
                    )}
                </div>
            </motion.div>
        );
    }
);
