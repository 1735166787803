import { emailConfig } from '../../../../../config';
import { ReactComponent as OutlookIcon } from '../../../../../components/icons/outlook-bigger.svg';
import { useState, useEffect } from 'react';
import { BaseAppCard } from './base-app-card';
import { ChannelAccountStatus } from '@zaplify/channel-accounts/shared';

const startAuthorizationFlow = async (navigateUrl: string) => {
    // Create popup interaction handler.
    const popupHandler = new PopupHandler();
    // Show the UI once the url has been created. Get the window handle for the popup.
    const popupWindow = popupHandler.initiateAuthRequest(navigateUrl);
    // Monitor the window for the query. Return the string value and close the popup when the query is received. Default timeout is 3 minutes.
    const code = await popupHandler.monitorPopupForQuery(popupWindow, 180 * 1000);
    // Handle code response from code param string.
    return code as string;
};

interface OutlookAuthCardProps {
    onSuccess: (code: string) => void;
    loading: boolean;
    isAdded: boolean;
    status: ChannelAccountStatus;
}

export const OutlookAuthCard = ({ onSuccess, loading, isAdded, status }: OutlookAuthCardProps) => {
    const clientId = emailConfig.ms.clientId;
    const redirectUri = emailConfig.ms.redirectUrl;
    const responseType = 'code';
    const scopes = emailConfig.ms.scopes;
    const prompt = 'consent';

    const title = 'Outlook';
    const oauthUrl = 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize';
    const config = {
        auth: {
            clientId,
            redirectUri,
            responseType,
            authority: 'https://login.microsoftonline.com/common',
            scopes,
            prompt,
        },
    };

    const handleConnect = async () => {
        const navigateUrl =
            `${oauthUrl}?client_id=${config.auth.clientId}&response_type=${config.auth.responseType}` +
            `&redirect_uri=${encodeURIComponent(config.auth.redirectUri)}` +
            `&scope=${config.auth.scopes.join(' ')}&response_mode=query&prompt=${config.auth.prompt}&state=ms`;
        // Acquire code with popup
        const code = await startAuthorizationFlow(navigateUrl);
        if (code) {
            onSuccess(code);
        }
    };

    return (
        <BaseAppCard
            title={title}
            appId="outlook"
            bgColor="bg-blue-50"
            icon={<OutlookIcon className="h-6 w-6 object-contain" />}
            isConnected={isAdded && status === ChannelAccountStatus.AUTHENTICATED}
            onConnect={handleConnect}
            isActive={isAdded && status === ChannelAccountStatus.AUTHENTICATED}
            isLoading={loading}
            connectButtonText={
                status === ChannelAccountStatus.NOT_AUTHENTICATED ? 'Reconnect Outlook' : 'Connect Outlook'
            }
            connectedText="Your Outlook account is connected."
            notConnectedText="Connect your Outlook account to send emails."
        />
    );
};

/**
 * Constants
 */
export const BrowserConstants = {
    // Default popup window width
    POPUP_WIDTH: 483,
    // Default popup window height
    POPUP_HEIGHT: 600,
    // Default popup monitor poll interval in milliseconds
    POLL_INTERVAL_MS: 50,
};

/**
 * Includes functions for monitoring the popup window for the auth code.
 */
export class PopupHandler {
    constructor() {
        // Properly sets this reference for the unload event.
        this.unloadWindow = this.unloadWindow.bind(this);
    }

    /**
     * Opens a popup window with given request Url.
     * @param requestUrl
     */
    initiateAuthRequest(requestUrl) {
        // Check that request url is not empty.
        if (typeof requestUrl === 'string' && requestUrl.length > 0) {
            // Save auth code request
            console.log('Navigate to:' + requestUrl);
            // Open the popup window to requestUrl.
            return this.openPopup(requestUrl, 'MSAL.JS', BrowserConstants.POPUP_WIDTH, BrowserConstants.POPUP_HEIGHT);
        } else {
            // Throw error if request URL is empty.
            console.error('Navigate url is empty');
        }
    }

    /**
     * Monitors a window until it loads a url with the auth code param, or hits a specified timeout.
     * @param popupWindow - window that is being monitored
     * @param timeout - milliseconds until timeout
     */
    monitorPopupForQuery(popupWindow, timeout) {
        return new Promise((resolve, reject) => {
            const maxTicks = timeout / BrowserConstants.POLL_INTERVAL_MS;
            let ticks = 0;

            const intervalId = setInterval(() => {
                if (popupWindow.closed) {
                    // Window is closed
                    //@ts-ignore
                    this.cleanPopup();
                    clearInterval(intervalId);
                    reject('Cancelled by user');
                    return;
                }

                // Run clock
                ticks++;

                let href;
                try {
                    /*
                     * Will throw if cross origin,
                     * which should be caught and ignored
                     * since we need the interval to keep running
                     */
                    href = popupWindow.location.href;
                } catch (e) {
                    //Do nothing
                }

                // Don't process blank pages or cross domain
                if (!href || href === 'about:blank') {
                    return;
                }

                const urlParams = new URLSearchParams(popupWindow.location.search);
                const code = urlParams.get('code');
                if (code) {
                    // Success case
                    this.cleanPopup(popupWindow);
                    clearInterval(intervalId);
                    resolve(code);
                    return;
                } else if (ticks > maxTicks) {
                    // Timeout error
                    this.cleanPopup(popupWindow);
                    clearInterval(intervalId);
                    reject('Window timeout error');
                    return;
                }
            }, BrowserConstants.POLL_INTERVAL_MS);
        });
    }

    /**
     * @hidden
     *
     * Configures popup window for login.
     *
     * @param urlNavigate
     * @param title
     * @param popUpWidth
     * @param popUpHeight
     * @ignore
     * @hidden
     */
    openPopup(urlNavigate, title, popUpWidth, popUpHeight) {
        try {
            /**
             * adding winLeft and winTop to account for dual monitor
             * using screenLeft and screenTop for IE8 and earlier
             */
            const winLeft = window.screenLeft ? window.screenLeft : window.screenX;
            const winTop = window.screenTop ? window.screenTop : window.screenY;
            /**
             * window.innerWidth displays browser window"s height and width excluding toolbars
             * using document.documentElement.clientWidth for IE8 and earlier
             */
            const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
            const left = width / 2 - popUpWidth / 2 + winLeft;
            const top = height / 2 - popUpHeight / 2 + winTop;

            // open the window
            const popupWindow = window.open(
                urlNavigate,
                title,
                'width=' + popUpWidth + ', height=' + popUpHeight + ', top=' + top + ', left=' + left
            );
            if (popupWindow.focus) {
                popupWindow.focus();
            }
            //@ts-ignore
            this.currentWindow = popupWindow;
            window.addEventListener('beforeunload', this.unloadWindow);

            return popupWindow;
        } catch (e) {
            console.error('error on popup');
        }
    }

    /**
     * Event callback to unload main window.
     */
    unloadWindow(e) {
        console.log('Unloading window');
        //@ts-ignore
        this.currentWindow.close();
        // Guarantees browser unload will happen, so no other errors will be thrown.
        delete e['returnValue'];
    }

    /**
     * Closes popup, removes any state vars created during popup calls.
     * @param popupWindow
     */
    cleanPopup(popupWindow) {
        console.log('Cleaning window');
        if (popupWindow) {
            // Close window.
            popupWindow.close();
        }
        // Remove window unload function
        window.removeEventListener('beforeunload', this.unloadWindow);
    }
}
