import { Input } from '@shadcn/ui/components/ui/input';

import { SearchIcon } from 'lucide-react';
import { cn } from '@shadcn/ui/lib/utils';
export const SearchInput = ({
    className,
    placeholder,
    filterValue,
    setFilterValue,
}: {
    className?: string;
    placeholder: string;
    filterValue: string;
    setFilterValue: (value: string) => void;
}) => {
    return (
        <div className={cn('relative', className)}>
            <SearchIcon className="absolute left-2 top-1/2 h-4 w-4 -translate-y-1/2 text-muted-foreground" />
            <Input
                type="search"
                placeholder={placeholder}
                value={filterValue}
                onChange={(event) => setFilterValue(event.target.value)}
                className="w-full pl-8"
            />
        </div>
    );
};
