import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthenticationProvider } from '../../new/providers/authentication-provider';
import { FreshVersionProvider } from '../../providers/fresh-version-provider';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            refetchOnMount: false,
            refetchInterval: false,
            refetchIntervalInBackground: false,
        },
    },
});
export const BaseProviders = ({ children }) => {
    return (
        <GoogleOAuthProvider clientId={import.meta.env.VITE_OAUTH_GOOGLE_CLIENT_ID}>
            <QueryClientProvider client={queryClient}>
                <AuthenticationProvider>
                    <FreshVersionProvider>{children}</FreshVersionProvider>
                </AuthenticationProvider>
            </QueryClientProvider>
        </GoogleOAuthProvider>
    );
};
