import { FC } from 'react';
import { usePlaybooksView } from '../hooks/use-playbooks-view';
import { PlaybookCard } from './playbook-cards/playbook-card';
import { CreatePlaybookCard } from './playbook-cards/create-playbook-card';
import { useCreatePlaybookDialog } from '../../../components/dialogs/create-playbook-dialog';

export const PlaybooksGrid: FC = () => {
    const { playbooks } = usePlaybooksView();
    const { openCreatePlaybookDialog } = useCreatePlaybookDialog();
    return (
        <div className="flex flex-wrap gap-6">
            <CreatePlaybookCard onClick={openCreatePlaybookDialog} />
            {playbooks.map((playbook, index) => (
                <div id={`playbook-card-${index}`} key={playbook.id}>
                    <PlaybookCard key={playbook.id} groupId={playbook.id} />
                </div>
            ))}
        </div>
    );
};
