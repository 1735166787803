import { BaseSdk } from '../../base.sdk';
import { LinkedinConversationsResponse } from '@zaplify/web-extension-shared';

export class LinkedinConversationsSdk extends BaseSdk {
    async trackFoundLinkedinConversations(userId: string, memberId: string, data: LinkedinConversationsResponse) {
        return await this.post({
            path: `${this.linkedinConversationsPath}/track-found-conversations`,
            payload: {
                userId,
                memberId,
                data,
            },
        });
    }
}
