const LINKEDIN_URL_PATTERN = /^(https?:\/\/)?(www\.)?(\w+.)?linkedin\.com\/in\/[\w\d\-_%]+\/?/i;
export const isValidLinkedInUrl = (url: string): boolean => {
    return LINKEDIN_URL_PATTERN.test(url);
};

export const getLinkedInSlug = (url: string): string | null => {
    if (!isValidLinkedInUrl(url)) {
        return null;
    }
    const matches = url.match(/linkedin\.com\/in\/([^\/?&]+)/i);
    return matches ? matches[1] : '';
};
