import { IEvent, Topics } from '@zaplify/common';

export class OrganizationDeletedEvent extends IEvent {
    public static override readonly TOPIC = Topics.USERS;
    constructor(readonly organizationId: string, readonly data: { domain: string; name: string }) {
        super();
    }

    get partitionKey(): string {
        return this.organizationId;
    }
}
