import SearchIcon from '@mui/icons-material/Search';
import { Box, Dialog, InputAdornment, LinearProgress, TextField, Tooltip, Typography } from '@mui/material';
import { useSetAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { InfoIcon, X } from 'lucide-react';
import React, { ReactNode, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import {
    SearchTypes,
    accountsDataAtom,
    accountsLoadingAtom,
    campaignsDataAtom,
    campaignsLoadingAtom,
    companiesDataAtom,
    companiesLoadingAtom,
    companyNamesDataAtom,
    companyNamesLoadingAtom,
    contactsDataAtom,
    contactsLoadingAtom,
    peopleDataAtom,
    peopleLoadingAtom,
    prospectsDataAtom,
    LinkedinPeopleAtom,
    LinkedinPeopleLoadingAtom,
    prospectsLoadingAtom,
} from '../../atoms/globalSearch';
import { debounce } from '../../helpers/debounce';
import { useAppSelector } from '../../redux/store/configureStore';
import LookingForSomethingV2 from './LookingForSomething';
import { AccountSection } from './SearchResultSection/AccountSection';
import { CompanyNameSearchSection } from './SearchResultSection/CompanyNameSection';
import { PeopleSection } from './SearchResultSection/PeopleSection';
import { RecentSearchSection } from './SearchResultSection/RecentSearchSection';
import GlobalSearchPopupStyled from './globalSearchPopup.styled';
import { useGlobalSearch } from './hooks/GlobalSearch';
import { useSearchHistory } from './hooks/SearchHistory';
import { ResultType, userClickedGlobalSearchResult, userMadeSearchInGlobalSearch } from './tracking';
import { ContactSection } from './SearchResultSection/ContactSection';
import { useGlobalSearchModal } from './hooks/use-global-search-modal';
import { useGroups } from '../../views/chats/hooks/use-groups';
import { LinkedinPeopleSection } from './SearchResultSection/LinkedinPeopleSection';

export const GlobalSearchPopupV2: React.FC<{}> = () => {
    const textFieldRef = useRef<HTMLInputElement>(null);
    const globalSearch = useGlobalSearch();

    const setAccountData = useSetAtom(accountsDataAtom);
    const setAccountLoading = useSetAtom(accountsLoadingAtom);

    const setPeopleData = useSetAtom(peopleDataAtom);
    const setPeopleLoading = useSetAtom(peopleLoadingAtom);

    // Use atoms for campaigns
    const setCampaignsData = useSetAtom(campaignsDataAtom);
    const setCampaignsLoading = useSetAtom(campaignsLoadingAtom);

    const setCompanyNamesData = useSetAtom(companyNamesDataAtom);
    const setCompanyNamesLoading = useSetAtom(companyNamesLoadingAtom);

    const setCompaniesData = useSetAtom(companiesDataAtom);
    const setCompaniesLoading = useSetAtom(companiesLoadingAtom);

    const setProspectsData = useSetAtom(prospectsDataAtom);
    const setProspectsLoading = useSetAtom(prospectsLoadingAtom);

    const setContactsData = useSetAtom(contactsDataAtom);
    const setContactsLoading = useSetAtom(contactsLoadingAtom);

    const setProspectsLinkedinData = useSetAtom(LinkedinPeopleAtom);
    const setProspectsLinkedinDataLoading = useSetAtom(LinkedinPeopleLoadingAtom);

    const [globalLoading, setGlobalLoading] = useState(false);

    const [searchValue, setSearchValue] = useState('');
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { groups } = useGroups();
    const allGroups = groups;
    const userId = useAppSelector((state) => state.user.zaplifyUser.id);

    const open = Boolean(anchorEl);
    const hasSearchableValue = searchValue.length > 2;
    const pageYOffset = window.pageYOffset;

    const [selectedCard, setSelectedCard] = useState(null);
    const { updateSearchHistory, getSearchHistory } = useSearchHistory();
    const { close: closeGlobalSearchModal, isOpen } = useGlobalSearchModal();

    React.useEffect(() => {
        function handleKeyDown(e: KeyboardEvent) {
            if ((navigator?.platform?.toLowerCase().includes('mac') ? e.metaKey : e.ctrlKey) && e.key === 'k') {
                e.preventDefault();
                e.stopPropagation();
                closeGlobalSearchModal();
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    useEffect(() => {
        debouncedSearch(searchValue);
        setSelectedCard(null);
    }, [searchValue]);

    useEffect(() => {
        if (selectedCard) {
            updateSearchHistory(searchValue);
        }
    }, [selectedCard]);

    useEffect(() => {
        if (hasSearchableValue && !globalLoading) {
            userMadeSearchInGlobalSearch(searchValue);
        }
    }, [hasSearchableValue, searchValue, globalLoading]);

    useEffect(() => {
        selectInputBoxFromMagicKeyStroke(textFieldRef.current);
    }, []);

    useLayoutEffect(() => {
        window.scroll({ top: pageYOffset });
    }, [globalLoading]);

    const selectInputBoxFromMagicKeyStroke = (target: HTMLElement) => {
        if (!anchorEl) {
            window?.analytics?.track?.('User Clicked Global Search Bar');
            setAnchorEl(target);
            target?.querySelector('input')?.focus();
        } else {
            target?.blur();
            target?.querySelector('input')?.blur();
        }
    };

    const handleClick = (event) => {
        window?.analytics?.track?.('User Clicked Global Search Bar');
        setAnchorEl(event.currentTarget);
    };

    const handleClickAway = () => {
        setAnchorEl(null);
        setSelectedCard(null);
        closeGlobalSearchModal();
    };

    const resetGlobalSearch = () => {
        setCampaignsData(RESET);
        setCampaignsLoading(RESET);

        setCompanyNamesData(RESET);
        setCompanyNamesLoading(RESET);

        setCompaniesData(RESET);
        setCompaniesLoading(RESET);

        setAccountData(RESET);
        setAccountLoading(RESET);

        setPeopleData(RESET);
        setPeopleLoading(RESET);

        setProspectsData(RESET);
        setProspectsLoading(RESET);

        setContactsData(RESET);
        setContactsLoading(RESET);

        setProspectsLinkedinData(RESET);
        setProspectsLinkedinDataLoading(RESET);
    };

    const debouncedSearch = useCallback(
        debounce(async (value) => {
            resetGlobalSearch();
            if (!value || value.length < 3) {
                return;
            }
            setGlobalLoading(true);
            const perPage = 5;
            const searchPromises = [
                searchAccounts(value, 1, perPage),
                searchPeople(value, 1, perPage),
                searchCampaigns(value, 1, perPage),
                searchCompanyNames(value, 1, perPage),
                searchCompanies(value, 1, perPage),
                searchProspects(value, 1, perPage),
                searchContacts(value, 1, perPage),
                searchLinkedinProspects(value, 1, perPage),
            ];

            // Create a promise that resolves after 5 seconds
            const timeoutPromise = new Promise((resolve) => setTimeout(resolve, 3000));

            const hasAnyPromiseResolved = Promise.race(searchPromises);
            await hasAnyPromiseResolved;

            // Wait for all promises to settle or for the timeout, whichever comes first
            await Promise.race([Promise.allSettled(searchPromises), timeoutPromise]);
            setGlobalLoading(false);
        }, 500),
        []
    );

    const searchAccounts = async (value, page, perPage) => {
        setAccountLoading(true);
        const accountsResponse = await globalSearch.search({
            match: value,
            page: page,
            perPage: perPage,
            searchIn: [SearchTypes.ACCOUNTS],
        });
        setAccountData(accountsResponse.accounts);
        setAccountLoading(false);
    };

    const searchPeople = async (value, page, perPage) => {
        setPeopleLoading(true);
        const peopleResponse = await globalSearch.search({
            match: value,
            page: page,
            perPage: perPage,
            searchIn: [SearchTypes.PEOPLE],
        });
        setPeopleData(peopleResponse.people);
        setPeopleLoading(false);
    };

    const searchCampaigns = async (value, page, perPage) => {
        setCampaignsLoading(true);
        const campaignsResponse = await globalSearch.search({
            match: value,
            page: page,
            perPage: perPage,
            searchIn: [SearchTypes.CAMPAIGNS],
        });
        setCampaignsData(campaignsResponse.campaigns);
        setCampaignsLoading(false);
    };
    const searchCompanyNames = async (value, page, perPage) => {
        setCompanyNamesLoading(true);
        const companyNamesResponse = await globalSearch.search({
            match: value,
            page: page,
            perPage: perPage,
            searchIn: [SearchTypes.COMPANY_NAMES],
        });
        setCompanyNamesData(companyNamesResponse.companyNames);
        setCompanyNamesLoading(false);
    };

    const searchCompanies = async (value, page, perPage) => {
        setCompaniesLoading(true);
        const companiesResponse = await globalSearch.search({
            match: value,
            page: page,
            perPage: perPage,
            searchIn: [SearchTypes.COMPANIES],
        });
        setCompaniesData(companiesResponse.companies);
        setCompaniesLoading(false);
    };

    const searchProspects = async (value, page, perPage) => {
        setProspectsLoading(true);
        const prospectsResponse = await globalSearch.search({
            match: value,
            page: page,
            perPage: perPage,
            searchIn: [SearchTypes.PROSPECTS],
        });
        setProspectsData(prospectsResponse.prospects);
        setProspectsLoading(false);
    };

    const searchContacts = async (value, page, perPage) => {
        setProspectsLoading(true);
        const prospectsResponse = await globalSearch.search({
            match: value,
            page: page,
            perPage: perPage,
            searchIn: [SearchTypes.CONTACTS],
        });
        setContactsData(prospectsResponse.contacts);
        setContactsLoading(false);
    };

    const searchLinkedinProspects = async (value, page, perPage) => {
        setProspectsLinkedinDataLoading(true);
        const prospectsResponse = await globalSearch.searchLinkedinProspect({
            match: value,
            page: page,
            perPage: perPage,
        });
        setProspectsLinkedinData(prospectsResponse);
        setProspectsLinkedinDataLoading(false);
    };

    const handleOnSearchValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        if (newValue.length > 2) {
            setGlobalLoading(true);
        }

        setSearchValue(newValue);
    };

    const handleClose = () => {
        closeGlobalSearchModal();
    };

    const searchHistory = getSearchHistory();

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            PaperProps={{
                style: {
                    borderRadius: '16px',
                    width: selectedCard ? 960 : 690,
                    maxHeight: 'min(650px, 95vh)',
                    minHeight: globalLoading ? '575px' : '580px',
                    gap: '8px',
                },
            }}
            maxWidth="lg"
        >
            <GlobalSearchPopupStyled>
                <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1', alignSelf: 'stretch' }}>
                    <Box
                        className="input-wrapper"
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyItems: 'space-between',
                        }}
                    >
                        <TextField
                            autoFocus
                            className={`${open ? 'input focused' : 'input'} global-search-bar`}
                            placeholder="“Dutch food tech firms”, “linkedin.com/in/kevinostlin”, “IKEA”, “Bill Gates” etc."
                            onClick={handleClick}
                            fullWidth
                            variant={'standard'}
                            inputRef={textFieldRef}
                            InputProps={{
                                disableUnderline: true,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon className="search-icon" />
                                    </InputAdornment>
                                ),
                            }}
                            value={searchValue}
                            onChange={handleOnSearchValueChange}
                            ref={textFieldRef}
                        />
                        <Tooltip title="Close" placement="bottom" arrow PopperProps={{ sx: { zIndex: 9999 } }}>
                            <X onClick={handleClose} className="close-icon" />
                        </Tooltip>
                    </Box>
                    {globalLoading && <LinearProgress />}
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        flex: '1',
                        alignSelf: 'stretch',
                    }}
                >
                    <Box
                        sx={{
                            borderRight: '1px solid var(--grey-palette-grey-3, #E5E5E5)',
                            display: 'flex',
                            gap: 1,
                            flex: '1',
                            flexDirection: 'column',
                            alignItems: 'stretch',
                            alignSelf: 'stretch',
                            width: '100%',
                            height: '600px',
                            overflow: 'auto',
                        }}
                    >
                        {!globalLoading && !hasSearchableValue && !searchHistory?.length && <LookingForSomethingV2 />}
                        {!globalLoading && !hasSearchableValue && !!searchHistory?.length && (
                            <RecentSearchSection
                                searchHistory={searchHistory}
                                onClickSearchHistoryEntry={setSearchValue}
                            />
                        )}
                        {!globalLoading && hasSearchableValue && (
                            <Box sx={{ padding: '18px' }}>
                                <ContactSection
                                    searchValue={searchValue}
                                    userId={userId}
                                    onClickAway={handleClickAway}
                                    onClickTracking={() => {
                                        userClickedGlobalSearchResult(ResultType.Prospect);
                                    }}
                                />
                                <LinkedinPeopleSection
                                    searchValue={searchValue}
                                    userId={userId}
                                    onClick={(node: ReactNode) => {
                                        setSelectedCard(node);
                                        userClickedGlobalSearchResult(ResultType.LinkedinProspect);
                                    }}
                                    onClickAway={handleClickAway}
                                    groups={allGroups}
                                />
                                <PeopleSection
                                    searchValue={searchValue}
                                    onClick={(node: ReactNode) => {
                                        setSelectedCard(node);
                                        userClickedGlobalSearchResult(ResultType.Prospect);
                                    }}
                                    onClickAway={handleClickAway}
                                    groups={allGroups}
                                    userId={userId}
                                />
                                <AccountSection
                                    searchValue={searchValue}
                                    userId={userId}
                                    onClick={(node: ReactNode) => {
                                        setSelectedCard(node);
                                        userClickedGlobalSearchResult(ResultType.RelevantAccounts);
                                    }}
                                    groups={allGroups}
                                />
                                <CompanyNameSearchSection
                                    searchValue={searchValue}
                                    userId={userId}
                                    onClick={(node: ReactNode) => {
                                        setSelectedCard(node);
                                        userClickedGlobalSearchResult(ResultType.MatchingAccounts);
                                    }}
                                    groups={allGroups}
                                />
                            </Box>
                        )}
                    </Box>
                    {selectedCard}
                </Box>
            </GlobalSearchPopupStyled>
        </Dialog>
    );
};

export const SearchSectionTitle = ({ title, size, toolTip }: { title: string; size?: string; toolTip?: string; }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 'var(--S-2xs, 4px)',
                marginBottom: '4px',
            }}
        >
            <Typography
                sx={{
                    color: 'var(--grey-palette-grey-4, #6B7985)',
                    letterSpacing: 0.1,
                    fontSize: size || '10px',
                    fontWeight: '500',
                    paddingTop: '2px',
                    lineHeight: '19px',
                }}
            >
                {title}
            </Typography>
            {toolTip && (
                <Tooltip
                    title={toolTip}
                    placement="top"
                    arrow
                    PopperProps={{
                        sx: {
                            zIndex: 6000,
                            '& .MuiTooltip-tooltip': {
                                padding: '4px 8px',
                                borderRadius: 'var(--radius-sm, 6px)',
                                backgroundColor: 'var(--others-dark, #313135)',
                                color: '#FFF',
                                fontSize: '13px',
                            },
                        },
                    }}
                >
                    <InfoIcon size={20} strokeWidth={1} stroke="#23323F" />
                </Tooltip>
            )}
        </Box>
    );
};
