import { useApolloClient } from '@apollo/client';
import {
    ConnectionStatus,
    LinkedinAccountActivitiesDto,
    LinkedinActivitySource,
    LinkedinActivityType,
    LinkedinUserActivitiesDto,
} from '@zaplify/campaigns';
import {
    GetConnectionsResponse,
    GetConversationsResponse,
    getZaplifySdk,
    WebExtensionToAndsendEventType,
} from '@zaplify/sdk';
import { getAuthenticationToken } from '@zaplify/utils';
import { useEffect, useState } from 'react';
import { GET_PROSPECT_MESSAGES_HISTORY } from '@zaplify/graphql';
import { LinkedinConversationsResponse, parseConversationMessage } from '@zaplify/web-extension-shared';
import { useAuth } from '../providers/authentication-provider';
import { useLinkedin } from './use-linkedin';
import { parseLinkedinConversationToUserActivity } from '../functions/trackers';
import LogRocket from 'logrocket';
import { getAuth } from 'firebase/auth';
import { ContactSource } from '@zaplify/services/user-contacts/shared';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useAddContacts } from './use-add-contacts';
import { useSdk } from '../sdk/use-sdk';
import { useMutation } from '@tanstack/react-query';

const useLinkedinTrackerUpdates = () => {
    const {
        authState: { isImpersonating, isLoggedIn, userId },
    } = useAuth();
    const {
        linkedinConversations: { trackFoundLinkedinConversations: trackFoundLinkedinConversationsMutation },
    } = useSdk();
    const client = useApolloClient();
    const {
        linkedinAccount,
        extensionVersion,
        extensionStatus,
        getConversations,
        getConversationsNew,
        getMessagesFromConversation,
        getMemberId,
        getPendingInvitations,
        getAcceptedConnections: getAcceptedConnectionRequests,
    } = useLinkedin();
    const { addContacts } = useAddContacts();
    const { mutateAsync: trackFoundLinkedinConversations } = useMutation(trackFoundLinkedinConversationsMutation());
    const { createProspectsFromAllLiConversation } = useFlags();

    // Set tab idle after some time of inactivity
    const [tabIdle, setTabIdle] = useState(false);
    useEffect(() => {
        if (!isLoggedIn) {
            return;
        }
        let idleTimeout: NodeJS.Timeout | null = null;

        const handleFocus = () => {
            setTabIdle(false);
            if (idleTimeout) {
                clearTimeout(idleTimeout);
                idleTimeout = null;
            }
        };

        const handleBlur = () => {
            idleTimeout = setTimeout(() => {
                setTabIdle(true);
            }, 120 * 1000);
        };

        window.addEventListener('focus', handleFocus);
        window.addEventListener('blur', handleBlur);

        return () => {
            window.removeEventListener('focus', handleFocus);
            window.removeEventListener('blur', handleBlur);
            if (idleTimeout) {
                clearTimeout(idleTimeout);
            }
        };
    }, [isLoggedIn]);

    const getPendingConnections = async (since: number): Promise<LinkedinUserActivitiesDto[]> => {
        const pendingConnections = await getPendingInvitations({
            since,
        });

        if ('error' in pendingConnections) {
            console.error(
                `🚀 ~~~ syncLinkedinInbox - pendingConnections | pendingConnections.error: ${JSON.stringify(
                    pendingConnections
                )}`
            );
            return [];
        }

        const result: LinkedinUserActivitiesDto[] = [];
        for (const invitation of pendingConnections.invitations) {
            const prospectActivities: LinkedinUserActivitiesDto = {
                linkedinUserId: invitation.toMemberId,
                connectionStatus: ConnectionStatus.PENDING,
                activities: [
                    {
                        type: LinkedinActivityType.CONNECTION_REQUEST_SENT,
                        timestamp: invitation.sentAt,
                        data: {
                            message: invitation.message,
                            messageId: invitation.invitationId,
                        },
                    },
                ],
            };
            result.push(prospectActivities);
        }
        return result;
    };

    const getAcceptedConnections = async (since: number): Promise<LinkedinUserActivitiesDto[]> => {
        let acceptedConnections: GetConnectionsResponse;
        try {
            acceptedConnections = await getAcceptedConnectionRequests({ since });
        } catch (error) {
            console.error(
                `🚀 ~~~ syncLinkedinInbox - getAcceptedConnections | acceptedConnections.error: ${JSON.stringify(
                    error
                )}`
            );

            return [];
        }

        const result: LinkedinUserActivitiesDto[] = [];
        for (const connection of acceptedConnections.connections) {
            const prospectActivities: LinkedinUserActivitiesDto = {
                linkedinUserId: connection.memberId,
                connectionStatus: ConnectionStatus.CONNECTED,
                activities: [
                    {
                        type: LinkedinActivityType.CONNECTION_REQUEST_ACCEPT,
                        timestamp: connection.connectedAt,
                        data: {
                            source: LinkedinActivitySource.MESSAGING_SCAN,
                        },
                    },
                ],
            };
            result.push(prospectActivities);
        }
        return result;
    };

    const getConversationMessageActivities = async (
        conversations: { recipientMemberId: string; conversationId: string }[],
        getConversationsSince: number
    ) => {
        return Promise.all(
            conversations.map(async (conversation) => {
                let purchaseProspectPromise: Promise<any>;
                // Create prospect
                if (createProspectsFromAllLiConversation) {
                    const userContact = await getZaplifySdk().profiles.userContacts.getByLinkedinMemberId(
                        conversation.recipientMemberId
                    );

                    if (!userContact) {
                        console.log('🚀 ~~~ syncLinkedinInbox | no user contact found, purchasing prospect');
                        purchaseProspectPromise = addContacts([{ linkedinUserId: conversation.recipientMemberId }], {
                            groupId: 'none',
                            source: ContactSource.LinkedinImport,
                        });
                    }
                }

                const getConversationsPayload = {
                    memberId: getMemberId(),
                    conversationId: conversation.conversationId,
                    deliveredAtBeforeTimestamp: Date.now(),
                    countBefore: 20,
                };
                const prospectActivities: LinkedinUserActivitiesDto = {
                    linkedinUserId: conversation.recipientMemberId,
                    connectionStatus: null,
                    activities: [],
                };

                try {
                    const messages = await getMessagesFromConversation({
                        ...getConversationsPayload,
                        cacheStaleTime: 0, // Must be instant
                    });
                    // Since we get all older messages, we need to filter out the ones that are before the last scan
                    const messagesFiltered = messages.filter((message) => message.timestamp > getConversationsSince);
                    const userActivity = parseLinkedinConversationToUserActivity(messagesFiltered, getMemberId(), null);
                    if (userActivity.activities.length !== messagesFiltered.length) {
                        console.log('🚀 ~~~ syncLinkedinInbox | messages have been filtered out', {
                            messagesFiltered,
                            userActivity,
                        });
                    }
                    prospectActivities.activities.push(...userActivity.activities);

                    await purchaseProspectPromise;
                } catch (error) {
                    console.error(
                        `🚀 ~~~ syncLinkedinInbox | get messages from conversations error: ${JSON.stringify(error)}`
                    );
                    return;
                }

                return prospectActivities;
            })
        );
    };

    const findConversations = async (params: {
        memberId: string;
        getConversationsSince: number;
    }): Promise<
        {
            recipientMemberId: string;
            conversationId: string;
        }[]
    > => {
        const { memberId, getConversationsSince } = params;
        if (extensionVersion >= '3.0.1') {
            const response = await getConversationsNew({
                userMemberId: memberId,
                since: getConversationsSince,
                cacheStaleTime: 0, // Must be instant
            });

            trackFoundLinkedinConversations({
                userId: userId,
                memberId: memberId,
                data: {
                    ...response,
                    conversations: response.conversations.filter(
                        (conv) => conv.lastActivityAt >= getConversationsSince
                    ),
                },
            }).catch((error) => {
                console.error(
                    `🚀 ~~~ syncLinkedinInbox | trackFoundLinkedinConversations error: ${JSON.stringify(error)}`
                );
            });
            return response.conversations
                ?.filter((conv) => conv.participants.length === 2)
                ?.filter((conv) => conv.lastActivityAt >= getConversationsSince)
                .map((conversation) => ({
                    recipientMemberId: conversation.participants.find((conv) => conv.memberId !== response.userMemberId)
                        ?.memberId,
                    conversationId: conversation.conversationId,
                }));
        } else {
            // @TODO: Remove this once we're sure it works
            const response = await getConversations({
                memberId: memberId,
                since: getConversationsSince,
                cacheStaleTime: 0, // Must be instant
            });
            return response.conversations.map((conversation) => ({
                recipientMemberId: conversation.prospect.memberId,
                conversationId: conversation.conversation.id,
            }));
        }
    };

    const syncLinkedinInbox = async () => {
        try {
            if (!isLoggedIn) {
                console.log('User not logged in, skipping syncLinkedinInbox');
                return;
            }

            if (isImpersonating) {
                console.log('Global admin detected, skipping prospect sync');
                return;
            }

            const { isAllowed, timeSinceLastUpdate } = checkRateLimit('last_tracker_update_timestamp', 45_000);
            if (!isAllowed) {
                console.log('Rate limit exceeded for syncLinkedinInbox', { timeSinceLastUpdate });
                // return;
            }

            if (extensionStatus !== 'CONNECTED') {
                console.log('Skipping syncLinkedinInbox due to invalid linkedin connection status');
                return;
            }

            // If the tab is not focused, do fewer updates to not use linkedin (we dont want to stop polling completely)
            if (tabIdle) {
                if (Math.random() > 0.3) {
                    console.log('Tab is not focused, skipping syncLinkedinInbox');
                    return;
                }
                console.log('Tab is not focused, but still running syncLinkedinInbox');
            }
            // TODO: We can't remove this until we're certain that authState isn't lagging behind on account switch
            // @IMPORTANT – this is to avoid leaking data
            const info = await getAuthenticationToken();
            const firebaseUserId = getAuth().currentUser?.uid;

            if (!userId || !info.claims.authUserId || info.claims.authUserId !== firebaseUserId) {
                console.log(`🚀 ~~~ syncLinkedinInbox – ignoring due to super admin`);
                return;
            }
            const memberId = getMemberId();
            if (!memberId) {
                console.log('Skipping syncLinkedinInbox due to invalid linkedin memberId');
                return;
            }

            const trackersSdk = getZaplifySdk().profiles.trackers;
            // Get from what timestamp to check triggers
            // @TODO Linkedin triggers repository – use lastCheckedAt instead of lastActivityAt?
            const res = await trackersSdk.getLinkedinCursorToSyncFrom();
            const scanStartedAt = Date.now();
            const getConversationsSince = (res ? res.MESSAGING_SCAN : scanStartedAt) - 30 * 1000; // 30 seconds before last scan
            const getConnectionsSince = (res ? res.CONNECTION_SCAN : scanStartedAt) - 30 * 1000; // 30 seconds before last scan
            let conversations: { recipientMemberId: string; conversationId: string }[];
            try {
                conversations = await findConversations({
                    memberId,
                    getConversationsSince,
                });
            } catch (error) {
                console.error(`🚀 ~~~ syncLinkedinInbox | conversations.error: ${JSON.stringify(error)}`);
                return;
            }

            console.log(`🚀 ~~~ syncLinkedinInbox | found ${conversations?.length} conversations`);

            if (!conversations) {
                return;
            }

            const userActivitiesPromises = getConversationMessageActivities(conversations, getConversationsSince);

            const shouldCheckConnections = Math.random() < 0.4;
            const connectionsSyncedUntil = shouldCheckConnections ? scanStartedAt : undefined;
            const acceptedConnectionsPromise = shouldCheckConnections
                ? getAcceptedConnections(getConnectionsSince)
                : Promise.resolve([]);
            // Pending connections are not very important, so dont check every time
            const pendingConnectionsPromise = shouldCheckConnections
                ? getPendingConnections(getConnectionsSince)
                : Promise.resolve([]);

            const [pendingConnections, acceptedConnections, fullConversations] = await Promise.allSettled([
                pendingConnectionsPromise,
                acceptedConnectionsPromise,
                userActivitiesPromises,
            ]);

            if (pendingConnections.status === 'rejected') {
                console.error(`🚀 ~~~ syncLinkedinInbox | pendingConnections error: ${pendingConnections.reason}`);
                return;
            }

            if (acceptedConnections.status === 'rejected') {
                console.error(`🚀 ~~~ syncLinkedinInbox | acceptedConnections error: ${acceptedConnections.reason}`);
                return;
            }

            if (fullConversations.status === 'rejected') {
                console.error(`🚀 ~~~ syncLinkedinInbox | fullConversations error: ${fullConversations.reason}`);
                return;
            }

            const userActivities = fullConversations.value;

            const appendConnectionActivitiesToPayload = (connectionActivities: LinkedinUserActivitiesDto[]) => {
                for (const connectionActivity of connectionActivities) {
                    const existingUserActivities = userActivities.find(
                        (payloadActivity) => payloadActivity.linkedinUserId === connectionActivity.linkedinUserId
                    );

                    if (existingUserActivities) {
                        existingUserActivities.connectionStatus = connectionActivity.connectionStatus;
                        existingUserActivities.activities.push(...connectionActivity.activities);
                    } else {
                        userActivities.push(connectionActivity);
                    }
                }
            };

            appendConnectionActivitiesToPayload(pendingConnections.value);
            appendConnectionActivitiesToPayload(acceptedConnections.value);

            await trackersSdk.syncLinkedin(true, {
                lastActivityTimestamp: scanStartedAt,
                messagesSyncedUntil: scanStartedAt,
                userActivities,
                connectionsSyncedUntil,
            });
        } catch (error: any) {
            console.error(`🚀 ~~~ syncLinkedinInbox | error: ${JSON.stringify(error)}`);
        }
    };

    const addLiveLinkedinEvent = async (event: any) => {
        try {
            if (isImpersonating) {
                console.warn('Global admin detected, skipping addLiveLinkedinEvent');
                return;
            }
            console.log('Linkedin live event received', event);
            if (event._type === 'com.linkedin.messenger.Message') {
                const trackersSdk = getZaplifySdk().profiles.trackers;
                if (extensionStatus !== 'CONNECTED') {
                    console.warn('addLiveLinkedinEvent | extensionStatus not CONNECTED', { extensionStatus });
                    return;
                }
                const memberId = getMemberId();
                const parsedMessage = parseConversationMessage(event, memberId);
                const userActivity = parseLinkedinConversationToUserActivity([parsedMessage], memberId, null);
                const payload: LinkedinAccountActivitiesDto = {
                    connectionsSyncedUntil: undefined,
                    lastActivityTimestamp: undefined,
                    messagesSyncedUntil: undefined,
                    userActivities: [userActivity],
                };
                await trackersSdk.syncLinkedin(
                    false, // Important that this does not move timestamps!!!
                    payload
                );
            }
            await client.refetchQueries({ include: [GET_PROSPECT_MESSAGES_HISTORY] });
        } catch (error) {
            console.error(`🚀 ~~~ addLiveLinkedinEvent | error: ${JSON.stringify(error)}`);
        }
    };

    useEffect(() => {
        if (!isLoggedIn) {
            return;
        }
        setupEventListener();
    }, [isLoggedIn]);

    useEffect(() => {
        if (!isLoggedIn) {
            return;
        }
        const handler = (e: Event) => {
            const event = e as any as { detail: { detail: { type: WebExtensionToAndsendEventType; payload: any } } };
            const detail = event?.detail?.detail;
            if (detail?.type === WebExtensionToAndsendEventType.LINKEDIN_DECORATED_EVENT_RECEIVED) {
                LogRocket.log('openLiveConnection got event', detail);
                if (!linkedinAccount?.LINKEDIN?.userId) {
                    return;
                }
                addLiveLinkedinEvent(detail.payload);
            } else if (detail?.type === WebExtensionToAndsendEventType.WEB_EXTENSION_LOG) {
                LogRocket.log('openLiveConnection got log event', detail?.payload?.message, detail?.payload?.payload);
            }
            return true;
        };

        // Listen to events from the event emitter
        eventEmitter.addEventListener('andsendExtensionMessage', handler);

        return () => {
            // Clean up the event listener when the component unmounts
            eventEmitter.removeEventListener('andsendExtensionMessage', handler);
        };
    }, [linkedinAccount?.LINKEDIN?.userId, addLiveLinkedinEvent, isLoggedIn]);

    return { syncLinkedinInbox, addLiveLinkedinEvent };
};

const checkRateLimit = (key: string, minInterval: number): { isAllowed: boolean; timeSinceLastUpdate: number } => {
    try {
        const now = Date.now();
        const lastUpdate = localStorage.getItem(key);

        // First time or invalid stored time
        if (!lastUpdate || isNaN(parseInt(lastUpdate))) {
            localStorage.setItem(key, now.toString());
            return { isAllowed: true, timeSinceLastUpdate: 0 };
        }

        const lastUpdateTime = parseInt(lastUpdate);
        const timeSinceLastUpdate = now - lastUpdateTime;

        // Handle clock changes or invalid times
        if (timeSinceLastUpdate < 0) {
            localStorage.setItem(key, now.toString());
            return { isAllowed: true, timeSinceLastUpdate: 0 };
        }

        if (timeSinceLastUpdate < minInterval) {
            return { isAllowed: false, timeSinceLastUpdate };
        }

        localStorage.setItem(key, now.toString());
        return { isAllowed: true, timeSinceLastUpdate };
    } catch (error) {
        return { isAllowed: true, timeSinceLastUpdate: 0 }; // Fail open on any storage errors
    }
};

// Only a single event listener is needed for the entire application
const eventEmitter = new EventTarget();
let isListenerAdded = false;
// Function to set up the event listener
const setupEventListener = () => {
    if (isListenerAdded) {
        return;
    }
    isListenerAdded = true;
    console.log('Event listener added');

    const eventHandler = (e: Event) => {
        // Dispatch the event through the event emitter
        eventEmitter.dispatchEvent(new CustomEvent('andsendExtensionMessage', { detail: e }));
    };

    window.addEventListener('andsendExtensionMessage', eventHandler);
};

export default useLinkedinTrackerUpdates;
