import { Button } from '@shadcn/ui/components/ui/button';
import { RefreshCw } from 'lucide-react';
import { useNavigate, useRouteError } from 'react-router-dom';
import React, { Component, ErrorInfo, ReactNode } from 'react';

export const ReactRouterErrorBoundary = () => {
    const error = useRouteError();
    const navigate = useNavigate();

    const handleRefresh = () => {
        navigate(0); // Refresh current route
    };

    return (
        <div className="flex justify-center items-center">
            <div className="mt-16 p-4 text-center flex flex-col gap-4 w-fit">
                <div className="text-2xl">
                    <span role="img" aria-label="hushed face">
                        😯
                    </span>
                </div>
                <div className="text-lg font-medium leading-5 text-text-primary">Something went wrong</div>
                <div className="text-sm leading-6 text-text-secondary">
                    We're working on fixing the problem <br /> Please retry again soon
                </div>
                <Button onClick={handleRefresh}>
                    <RefreshCw className="w-5 h-5" />
                    Refresh page
                </Button>
            </div>
        </div>
    );
};

interface Props {
    children: ReactNode;
}

interface State {
    hasError: boolean;
    error?: Error;
}

export class CustomErrorBoundary extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: Error) {
        return { hasError: true, error };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // Log the error if needed
        console.error('Error caught by CustomErrorBoundary:', error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // return <h1>Something went wrong!</h1>;
            console.error('Error caught by CustomErrorBoundary:', this.state.error);
        }
        return this.props.children;
    }
}
