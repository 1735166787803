import { Box } from '@mui/material';
import React from 'react';
import { getRequiredText } from '../../../../../campaign/newCampaign/addProspects/importProspects/fieldMapping/functions';
import TwinSelect from '../../../../../../components/molecules/twinSelect';
import { ErrorBox } from '../../../../elements';

export const FieldSelector = ({ field, leftOptions, rightOptions, leftOnChange, rightOnChange, handleDeleteField }) => {
    return (
        <>
            <Box display="flex" className={'box-row'}>
                <TwinSelect
                    key={field.prospectField + (field.personField || field.organizationField)}
                    index={field.idx}
                    leftValue={field.prospectField}
                    rightValue={field.personField || field.organizationField}
                    leftOptions={leftOptions}
                    rightOptions={rightOptions}
                    leftOnChange={(event) => leftOnChange(event, field.idx)}
                    rightOnChange={(event) => rightOnChange(event, field.idx)}
                    disabledRight={field.required}
                />
                {getRequiredText({ ...field, required: field.required }, handleDeleteField, field.idx)}
            </Box>
            <ErrorBox errorText={field.errorText} />
        </>
    );
};
