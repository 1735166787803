import { z } from 'zod';

// Helper function to extract domain from URL
const extractDomain = (url: string) => {
    try {
        const domain = new URL(url).hostname;
        return domain.replace(/^www\./, '');
    } catch {
        return url;
    }
};

// Validation schema that matches backend validation rules from BulkImportedProspect
export const prospectSchema = z.object({
    // Required fields
    firstName: z.string().min(1, 'First Name field cannot be empty').max(100, 'Text must be between 1-100 characters'),
    lastName: z.string().min(1, 'Last name field cannot be empty').max(100, 'Text must be between 1-100 characters'),
    fullName: z.string().min(1, 'Full Name field cannot be empty').max(200, 'Text must be between 1-200 characters'),
    organizationName: z.string().min(1).max(100, 'Text must be between 1-100 characters'),
    occupationTitle: z.string().min(1).max(100, 'Text must be between 1-100 characters').optional(),
    // Optional fields - only validate if they have values
    email: z.union([
        z.string().email('Email is badly formated').min(1).max(100, 'Text must be between 1-100 characters'),
        z.string().length(0),
        z.undefined(),
    ]),

    linkedinProfileUrl: z.union([
        z
            .string()
            .url('Must be a valid URL')
            .regex(/linkedin\.com\/in\//i, 'Must be a valid LinkedIn profile URL')
            .max(100, 'Text must be between 1-100 characters'),
        z.string().length(0),
        z.undefined(),
    ]),

    linkedinUserId: z.string().max(100, 'Text must be between 1-100 characters').optional(),
    linkedinProfileImgUrl: z.string().max(500, 'Text must be between 1-500 characters').optional(),
    linkedinHeadline: z.string().max(1000, 'Text must be between 1-1000 characters').optional(),
    linkedinDescription: z.string().max(5000, 'Text must be between 1-5000 characters').optional(),

    gender: z.string().max(100, 'Text must be between 1-100 characters').optional(),
    location: z.string().max(100, 'Text must be between 1-100 characters').optional(),
    locationCountry: z.string().max(100, 'Text must be between 1-100 characters').optional(),
    locationRegion: z.string().max(100, 'Text must be between 1-100 characters').optional(),
    locationCity: z.string().max(100, 'Text must be between 1-100 characters').optional(),

    industry: z.string().max(100, 'Text must be between 1-100 characters').optional(),
    occupationRole: z.string().max(100, 'Text must be between 1-100 characters').optional(),
    occupationSubRole: z.string().max(100, 'Text must be between 1-100 characters').optional(),
    occupationDuration: z.string().max(100, 'Text must be between 1-100 characters').optional(),
    occupationStartDate: z.string().max(100, 'Text must be between 1-100 characters').optional(),

    organizationId: z.string().max(100, 'Text must be between 1-100 characters').optional(),
    organizationIndustry: z.string().max(100, 'Text must be between 1-100 characters').optional(),
    organizationSize: z.string().max(100, 'Text must be between 1-100 characters').optional(),

    // Special validations
    organizationDomain: z.union([
        z
            .string()
            .min(1)
            .max(100, 'Text must be between 1-100 characters')
            .refine(
                (val) => {
                    // Basic domain validation (can be made more robust)
                    return /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/.test(val);
                },
                {
                    message: 'Invalid domain name. Please enter the company website domain (e.g. "company.com")',
                }
            ),
        z.string().length(0),
        z.undefined(),
    ]),

    // Other social profiles
    facebookProfileUrl: z.string().max(100, 'Text must be between 1-100 characters').optional(),
    twitterProfileUrl: z.string().max(100, 'Text must be between 1-100 characters').optional(),
    githubProfileUrl: z.string().max(100, 'Text must be between 1-100 characters').optional(),

    // Other fields
    birthYear: z.string().max(100, 'Text must be between 1-100 characters').optional(),
    birthDate: z.string().max(100, 'Text must be between 1-100 characters').optional(),
    phoneNumbers: z
        .array(
            z.object({
                phoneNumber: z.string().max(50, 'Phone number must be less than 50 characters'),
            })
        )
        .optional(),
});

// Metadata for field display in UI
const FIELD_DISPLAY_METADATA: Record<string, { label: string }> = {
    firstName: { label: 'First Name' },
    lastName: { label: 'Last Name' },
    fullName: { label: 'Full Name' },
    occupationTitle: { label: 'Job Title' },
    organizationName: { label: 'Company Name' },
    email: { label: 'Email' },
    linkedinProfileUrl: { label: 'LinkedIn URL' },
    linkedinUserId: { label: 'LinkedIn User ID' },
    linkedinProfileImgUrl: { label: 'LinkedIn Profile Image' },
    linkedinHeadline: { label: 'LinkedIn Headline' },
    linkedinDescription: { label: 'LinkedIn Description' },
    gender: { label: 'Gender' },
    location: { label: 'Location' },
    locationCountry: { label: 'Country' },
    locationRegion: { label: 'Region' },
    locationCity: { label: 'City' },
    industry: { label: 'Industry' },
    occupationRole: { label: 'Job Role' },
    occupationSubRole: { label: 'Job Sub-role' },
    occupationDuration: { label: 'Job Duration' },
    occupationStartDate: { label: 'Job Start Date' },
    organizationId: { label: 'Company ID' },
    organizationIndustry: { label: 'Company Industry' },
    organizationSize: { label: 'Company Size' },
    organizationDomain: { label: 'Company Website' },
    facebookProfileUrl: { label: 'Facebook URL' },
    twitterProfileUrl: { label: 'Twitter URL' },
    githubProfileUrl: { label: 'GitHub URL' },
    birthYear: { label: 'Birth Year' },
    birthDate: { label: 'Birth Date' },
    phoneNumbers: { label: 'Phone Numbers' },
};

// Get all field IDs from the Zod schema
export const getAllFieldIds = (): string[] => {
    return Object.keys(prospectSchema.shape).filter(
        (key) =>
            ![
                'fullName',
                'locationCountry',
                'locationRegion',
                'locationCity',
                'occupationRole',
                'occupationSubRole',
                'organizationId',
                'gender',
                'occupationDuration',
                'linkedinUserId',
                'linkedinProfileImgUrl',
                'linkedinHeadline',
                'linkedinDescription',
                'facebookProfileUrl',
                'twitterProfileUrl',
                'githubProfileUrl',
                'birthYear',
                'birthDate',
            ].includes(key)
    );
};

// Get required field IDs from the Zod schema
export const getRequiredFields = (): string[] => {
    // Fields with .min(1) are required
    return ['firstName', 'lastName', 'organizationName', 'occupationTitle'];
};

// Get field metadata for UI display, including required status
export const getFieldMetadata = () => {
    const allFields = getAllFieldIds();
    const requiredFields = new Set(getRequiredFields());

    return allFields.reduce((metadata, fieldId) => {
        metadata[fieldId] = {
            required: requiredFields.has(fieldId),
            label: FIELD_DISPLAY_METADATA[fieldId]?.label || fieldId,
        };
        return metadata;
    }, {} as Record<string, { required: boolean; label: string }>);
};

export type ProspectValidationError = {
    path: string[];
    message: string;
};

export const validateProspect = (prospect: any): ProspectValidationError[] => {
    try {
        // If organizationDomain looks like a URL, try to extract the domain
        if (prospect.organizationDomain?.includes('://')) {
            prospect.organizationDomain = extractDomain(prospect.organizationDomain);
        }

        // Generate fullName if missing but first and last name exist
        if (!prospect.fullName && prospect.firstName && prospect.lastName) {
            prospect.fullName = `${prospect.firstName} ${prospect.lastName}`;
        }

        // Only validate fields that have values
        const result = prospectSchema.parse(prospect);
        return [];
    } catch (error) {
        if (error instanceof z.ZodError) {
            return error.errors
                .filter((err) => {
                    // Skip empty optional fields
                    const pathStr = err.path.join('.');
                    const value = err.path.reduce((obj, key) => obj?.[key], prospect);
                    return !(value === '' || value === undefined || value === null);
                })
                .map((err) => ({
                    path: err.path.map(String),
                    message: err.message,
                }));
        }
        return [{ path: ['unknown'], message: 'Unknown validation error' }];
    }
};
