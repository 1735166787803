import { ICommand, Topics } from '@zaplify/common';

export class AddThreadMessageCommand extends ICommand<void> {
    public static override readonly TOPIC = Topics.PROSPECTS;

    public readonly userId: string;
    public readonly threadId: string;
    public readonly parentId: string | null;
    public readonly format: string;
    public readonly content: any;
    public readonly metadata: any;
    public readonly role: string;

    constructor(input: {
        userId: string;
        threadId: string;
        parentId: string | null;
        format: string;
        content: any;
        metadata: any;
        role: string;
    }) {
        super();
        this.userId = input.userId;
        this.threadId = input.threadId;
        this.parentId = input.parentId;
        this.format = input.format;
        this.content = input.content;
        this.metadata = input.metadata;
        this.role = input.role;
    }
}
