import { ReactNode, useState, createContext, useContext } from 'react';
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
} from '@shadcn/ui/components/ui/dialog';
import { Button } from '@shadcn/ui/components/ui/button';
import { Sparkles, Brain, MessageSquare, Globe } from 'lucide-react';
import { SearchParamDialog, useSearchParamDialog } from '@shadcn/ui/components/search-param-dialog';

export const memoryExplainerDialogParam = 'memory-explainer';

export const useMemoryExplainerDialog = () => {
    const { open, close } = useSearchParamDialog(memoryExplainerDialogParam);

    return {
        openMemoryExplainerDialog: () => open(),
        closeMemoryExplainerDialog: close,
    };
};

const MemoryExplainerContent = () => (
    <div className="flex flex-col gap-4 py-4">
        <div className="flex items-center gap-2">
            <Sparkles className="h-5 w-5 text-primary" />
            <h3 className="text-lg font-medium">Conversation Memories</h3>
        </div>

        <p className="text-sm text-secondary-foreground">
            A memory is information that is automatically detected and saved. As you provide feedback on messages, your
            agent will learn and save information about you and your contacts.
        </p>

        <div className="mt-2">
            <h4 className="font-medium mb-2">There are 3 levels of memories:</h4>
            <ul className="space-y-4 list-none pl-0">
                <li className="p-3 border rounded-md">
                    <div className="flex items-center gap-2 mb-1">
                        <MessageSquare className="h-4 w-4 text-blue-500" />
                        <strong>Conversation</strong>
                    </div>
                    <p className="text-sm text-secondary-foreground">
                        Details specific to your interaction with this contact, such as previous discussions, meeting
                        history, and personal preferences. These memories only apply to that specific conversation.
                    </p>
                </li>
                <li className="p-3 border rounded-md">
                    <div className="flex items-center gap-2 mb-1">
                        <Brain className="h-4 w-4 text-purple-500" />
                        <strong>Playbook</strong>
                    </div>
                    <p className="text-sm text-secondary-foreground">
                        Strategic knowledge shared across all contacts in the same playbook, like strategies, how to
                        format messages or answers to common questions.
                    </p>
                </li>
                <li className="p-3 border rounded-md">
                    <div className="flex items-center gap-2 mb-1">
                        <Globe className="h-4 w-4 text-green-500" />
                        <strong>Global</strong>
                    </div>
                    <p className="text-sm text-secondary-foreground">
                        Universal information about you and your company that applies to all conversations, such as
                        recent events, your company values, product offerings, and recent company milestones or news.
                    </p>
                </li>
            </ul>
        </div>

        <div className="mt-2 p-3 bg-secondary rounded-md">
            <p className="text-sm text-secondary-foreground">
                Memories are automatically created when you provide feedback on message suggestions.
            </p>
        </div>
    </div>
);

export const MemoryExplainerDialog = () => {
    return (
        <SearchParamDialog param={memoryExplainerDialogParam}>
            <DialogContent className="sm:max-w-[700px]">
                <DialogHeader>
                    <DialogTitle>Understanding Memories</DialogTitle>
                    <DialogDescription>
                        Learn how your agent uses memories to improve your conversations
                    </DialogDescription>
                </DialogHeader>

                <MemoryExplainerContent />

                <div className="flex justify-end mt-4">
                    <DialogClose asChild>
                        <Button>Close</Button>
                    </DialogClose>
                </div>
            </DialogContent>
        </SearchParamDialog>
    );
};

export default MemoryExplainerDialog;
