import { memo, FC, Suspense, useEffect, useMemo } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { ActionsList, ActionsListSkeleton } from './components/actions-list';
import ConversationPageHeader, { ConversationPageHeaderSkeleton } from '../../components/conversation-page-header';
import { ContactDetailsSidebar } from '../../components/contact-details-sidebar/contact-details-sidebar';
import { cn } from '@shadcn/ui/lib/utils';
import { useSidebars } from '@shadcn/ui/hooks/use-sidebars';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { paths } from '../../../routes/paths';

export const ActionsPage: FC = () => {
    const { toggleSidebar, sidebarState } = useSidebars();
    const { prospectId } = useParams();
    const { useActionFeed } = useFlags();
    const navigate = useNavigate();
    const toggleDetails = () => {
        toggleSidebar('details');
    };

    useEffect(() => {
        if (useActionFeed) {
            navigate(`${paths.NEW.ACTION_FEED}${prospectId ? `/${prospectId}` : ''}`);
        }
    }, [useActionFeed, navigate, prospectId]);

    return useMemo(
        () => (
            <div className="flex h-full max-w-full overflow-hidden">
                <div className="flex-1 flex flex-col min-w-0 overflow-hidden">
                    <Suspense fallback={<ConversationPageHeaderSkeleton />}>
                        <ConversationPageHeader onToggleDetails={toggleDetails} isDetailsOpen={sidebarState.details} />
                    </Suspense>

                    <div className="flex flex-1 flex-shrink-0 min-h-0 overflow-hidden">
                        <div
                            className={cn('flex min-w-full w-full md:min-w-[320px] md:w-[320px] overflow-hidden', {
                                'hidden md:flex': prospectId,
                            })}
                        >
                            <Suspense fallback={<ActionsListSkeleton />}>
                                <ActionsList />
                            </Suspense>
                        </div>
                        <div className={cn('flex flex-1 min-w-0 overflow-hidden', { 'hidden md:flex': !prospectId })}>
                            <Outlet />
                        </div>
                    </div>
                </div>
                <ContactDetailsSidebar />
            </div>
        ),
        [prospectId, sidebarState.details, toggleDetails]
    );
};

export default ActionsPage;
