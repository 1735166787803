import { styled } from '@mui/styles';
import { palette } from '../../../theme/palette';

export const ProspectDetailsPopupContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    background: '#FFFFFF',
    border: '1px solid #E4E6EB',
    boxShadow: '0px 2px 6px rgba(112, 144, 176, 0.07)',
    borderRadius: 8,
    height: 350,
    width: 340,
    zIndex: 100,
    overflowY: 'auto',
    '& > .top-container': {
        padding: '13px 20px',
        borderBottom: '1px solid #E4E6EB',
        '& .name': {
            fontSize: 12,
            fontWeight: 500,
            color: palette.subline,
        },
        '& > .title': {
            color: palette.darkGrey,
            fontSize: 12,
        },
    },

    '& > .integration-container': {
        textAlign: 'left',

        padding: '13px 20px',
        borderBottom: '1px solid #E4E6EB',
        '& > div:first-child': {
            marginBottom: 8,
        },
        '& .data-header': {
            color: palette.darkGrey,
            '& .data-header-value-active': {
                fontWeight: 500,
                color: palette.darkBlue,
            },
            '& .data-header-value-inactive': {
                fontWeight: 500,
                color: palette.darkGrey,
            },
        },
        '& .owner-top-container': {
            textAlign: 'left',
            display: 'flex',
            alignItems: 'center',
            '& .icon-container': {
                marginTop: 4,
            },
        },
        '& .header-name': {
            fontWeight: 500,
        },
    },

    '& > .channels-container': {
        padding: '13px 20px',
        borderBottom: '1px solid #E4E6EB',
        '& > div:first-child': {
            marginBottom: 8,
        },
    },
    '& > .details-container': {
        padding: '13px 20px',
        boxSizing: 'border-box',
        '& .margin-bottom': {
            marginBottom: 15,
        },
        '& .skeleton-row': {
            display: 'flex',
            gap: 5,
        },
        '& > .details-section': {
            boxSizing: 'border-box',
            display: 'flex',
            marginBottom: 14,
            '& > .icon-container': {
                '& > svg': {
                    fill: palette.lightGrey,
                },
            },
            '& > .body-container': {
                width: 260,
                marginLeft: 10,
                '& p': {
                    fontSize: 12,
                },
                '& > .title': {
                    fontWeight: 500,
                    color: palette.darkGrey,
                },
            },
            '& .current-company-name': {
                color: palette.primary.main,
                fontWeight: 500,
            },
            '& .past-company-name': {
                fontWeight: 500,
            },
            '& .experience-container': {
                marginBottom: 8,
            },
        },
    },
    '& .skills-container': {
        display: 'inline-block',
        '& > .skill-chip': {
            background: '#F0F1F4',
            borderRadius: 4,
            padding: 4,
            float: 'left',
            marginRight: 6,
            marginBottom: 6,
            fontSize: 12,
            color: palette.subline,
        },
    },
}));
