import { IQuery, Topics } from '@zaplify/common';
import { UserMemoryDto } from '../dtos/user-memory.dto';
import { MemoryLevel } from '../dtos/user-memory.dto';

export class GetUserMemoriesQuery extends IQuery<UserMemoryDto[]> {
    public static override readonly TOPIC = Topics.CAMPAIGNS;

    readonly userId: string;
    readonly prospectId?: string;
    readonly groupId?: string;
    readonly level?: MemoryLevel;

    constructor({
        userId,
        prospectId,
        groupId,
        level,
    }: {
        userId: string;
        prospectId?: string;
        groupId?: string;
        level?: MemoryLevel;
    }) {
        super();
        this.userId = userId;
        this.prospectId = prospectId;
        this.groupId = groupId;
        this.level = level;
    }
}
