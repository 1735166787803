import ArrowBack from '@mui/icons-material/ArrowBack';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { useDispatch } from 'react-redux-latest';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../../../components/atoms/button';
import { ReactComponent as SalesforceIcon } from '../../../../../components/icons/salesforce-logo.svg';
import { updateSalesforceSettings } from '../../../../../redux/ducks/salesforce/operations';
import { AppDispatch, useAppSelector } from '../../../../../redux/store/configureStore';
import { paths } from '../../../../../routes/paths';
import { TopContainer } from './containers';

export const TopBar = () => {
    const dispatch = useDispatch<AppDispatch>();
    const salesforceAccountId = useAppSelector((state) => state.salesforce.account?.organizationId);
    const saveStatus = useAppSelector((state) => state.salesforce.saveStatus);
    const navigate = useNavigate();

    return (
        <TopContainer>
            <IconButton data-cy="go-back-button" size="large" onClick={() => navigate(paths.INTEGRATIONS)}>
                <ArrowBack />
            </IconButton>
            <div>
                <SalesforceIcon />
                <div>
                    <Typography variant="h3">Salesforce</Typography>
                    <Typography>CRM Software</Typography>
                </div>
                <div>
                    <Typography>Company ID: {salesforceAccountId}</Typography>
                </div>
            </div>
            <Tooltip title={!saveStatus ? 'All required fields needs to be completed' : ''}>
                <span>
                    <Button
                        dataCy={'save-salesforce-settings'}
                        disabled={!saveStatus}
                        borderRadius="300px"
                        height="45px"
                        text="Save"
                        onClick={() => dispatch(updateSalesforceSettings())}
                        startIcon={<ArrowUpward />}
                    />
                </span>
            </Tooltip>
        </TopContainer>
    );
};
