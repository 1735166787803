import React from 'react';
import { cn } from '@shadcn/ui/lib/utils';
import { paths } from '../../../../routes/paths';

// Basic styles for text content
const pStyle = 'text-base my-2 mb-4';
const h2Style = 'text-2xl font-semibold mt-8 mb-4';
const h3Style = 'text-xl font-semibold mt-6 mb-3';
const linkStyle = 'text-primary underline hover:text-primary/90';
const listItemStyle = 'ml-6 my-1';

export default function PrivacyPolicy20241219() {
    return (
        <div className="container mx-auto py-12 px-4 max-w-4xl">
            <h1 className="text-4xl font-bold mb-6">Privacy Policy (ARCHIVED)</h1>

            <div>
                <h4 className="text-xl mb-8">Last Updated: December 19, 2024</h4>

                <p className={cn(pStyle, 'italic font-bold')}>
                    IMPORTANT NOTE: THIS IS AN ARCHIVED VERSION OF THE PRIVACY POLICY. PLEASE REFER TO{' '}
                    <a href={paths.LEGAL.PRIVACY_POLICY} className={linkStyle}>
                        THIS LINK
                    </a>{' '}
                    FOR THE CURRENT VERSION OF THE PRIVACY POLICY.
                </p>

                <div className="border-t border-b border-border my-8 py-0.5"></div>

                <p className={pStyle}>
                    Andsend understands that it is important how information about you is processed and protected. We
                    value the data you share with us and treat it respectfully.
                </p>

                <p className={pStyle}>
                    In this privacy policy (the "Privacy Policy", "Policy"), which applies to anyone who in any way uses
                    Andsend's services or products (the "Service") and/or otherwise has contact with us, we describe how
                    and why we process your personal data and what rights you have under applicable personal data
                    legislation.
                </p>

                <p className={pStyle}>
                    Unless otherwise specified, it is Andsend Group AB, ("Andsend", "we", "us") who is responsible for
                    the processing of your personal data.
                </p>

                <p className={pStyle}>
                    You are always welcome to contact us with questions regarding this policy or other data protection
                    issues. Contact details can be found at the end of the Policy.
                </p>

                {/* Sections 1-3 */}
                <h2 className={h2Style}>1. What is Personal Data?</h2>
                <p className={pStyle}>
                    Personal Data is all information that can be directly or indirectly attributed to a natural living
                    person. Examples of this are social security numbers, e-mail addresses, but even encrypted data if
                    they can be linked to natural persons.
                </p>

                <h2 className={h2Style}>2. What is the "Processing" of Personal Data?</h2>
                <p className={pStyle}>
                    The concept of processing is broad and covers most things one can do with Personal Data. Everything
                    from collection and storage to modification, use or deletion constitutes "processing".
                </p>

                <h2 className={h2Style}>3. When do we collect your Personal Data?</h2>
                <p className={pStyle}>
                    We collect information from various sources possible in accordance with applicable data protection
                    regulation.
                </p>

                <p className={pStyle}>
                    <strong>Information you submit to us yourself</strong>
                    <br />
                    We collect and process Personal Data that you provide to us, e.g. when you visit our website, buy
                    our service, contact us or use our digital channels.
                </p>

                <p className={pStyle}>
                    <strong>Information collected when you use our services</strong>
                    <br />
                    When you use our services, we record information about your use. This information includes which of
                    our services you use and how you behave through our service. For example, we use Google Analytics,
                    Google APIs and other tools to record information. This information may include cookies and unique
                    identification files.
                </p>

                <p className={pStyle}>
                    <strong>Data from other sources</strong>
                    <br />
                    We collect personal information, e.g. names and e-mail addresses, which you have provided to our
                    partners and other sub-suppliers. We may also collect and update your contact information through
                    third-party services. We do this to ensure that we always have up-to-date information about you and
                    to be able to submit offers of our own and selected partner's products and services as well as
                    provide the service to you as a client.
                </p>

                {/* Sections 4-6 */}
                <h2 className={h2Style}>4. Why do we process your Personal Data?</h2>
                <p className={pStyle}>
                    For us to provide services to you and other clients, we must process your personal information. We
                    process your information mainly to:
                </p>
                <ul className="list-disc pl-8 mb-4">
                    <li className={listItemStyle}>Manage and provide our services</li>
                    <li className={listItemStyle}>Manage customer service issues and other inquiries</li>
                    <li className={listItemStyle}>
                        Provide information and promote our partners or client's services and goods that you may be
                        interested in
                    </li>
                    <li className={listItemStyle}>
                        Improve our services, for example by researching and evaluating customer satisfaction or the
                        market
                    </li>
                </ul>

                <h2 className={h2Style}>5. On which legal ground are we processing your Personal Data?</h2>
                <p className={pStyle}>
                    To process Personal Data, it is required a legal basis. Andsend processes Personal Data on the
                    following grounds:
                </p>
                <ul className="list-disc pl-8 mb-4">
                    <li className={listItemStyle}>
                        Because Personal Data processing is necessary for us to fulfill an agreement we have with you,
                        e.g. to provide a service you have purchased
                    </li>
                    <li className={listItemStyle}>
                        Because Personal Data processing is necessary to fulfill a legal obligation, e.g. accounting
                        obligations
                    </li>
                    <li className={listItemStyle}>
                        Because we have a legitimate interest in processing your Personal Data to communicate to you
                        about goods and services you previously purchased or might be interested in.
                    </li>
                    <li className={listItemStyle}>
                        When the Personal Data processing is allowed because you have given your consent to it.
                    </li>
                </ul>

                <h2 className={h2Style}>6. How long will we save your information?</h2>
                <p className={pStyle}>
                    Your Personal Data will only be processed for as long as it is necessary for the purposes for which
                    it was collected – for example, as long as we must fulfill the obligations we have as a result of an
                    agreement with you.
                </p>
                <p className={pStyle}>
                    We may save the data longer if required by law or to safeguard our legal interests, e.g. whether a
                    legal process is in progress.
                </p>
                <p className={pStyle}>
                    Your Personal Data will be deleted or anonymized when they are no longer relevant to the purposes
                    for which they have been collected.
                </p>

                {/* Sections 7-9 */}
                <h2 className={h2Style}>7. Do we share your Personal Data?</h2>
                <p className={pStyle}>
                    Only the people who need to process Personal Data for the purposes mentioned above have access to
                    your Personal Data.
                </p>
                <p className={pStyle}>
                    We may share your personal information with companies and suppliers who provide services for us,
                    e.g. to maintain or support our IT-systems. These companies may only process your Personal Data
                    following our instructions and may not use your Personal Data for their purposes unless specifically
                    agreed with you.
                </p>
                <p className={pStyle}>
                    We may also share the personal information necessary to establish, enforce and exercise Andsend's
                    legal interests, such as collecting debts.
                </p>
                <p className={pStyle}>
                    We may also share your personal information if it is required by law or government regulations, for
                    example if we must submit information to the relevant jurisdictional Tax Agency or Competition
                    Authority.
                </p>

                <h2 className={h2Style}>8. Where is your information processed?</h2>
                <p className={pStyle}>
                    We aim to process your personal information within the EU. If we use service providers that transfer
                    your Personal Data to countries outside the EU and the EEA, we will take steps to protect your data
                    in accordance with applicable legal requirements, for instance by demanding that the supplier
                    protect the data following applicable data protection rules. If you would like to know more about
                    what protective measures we have taken, please contact us.
                </p>

                <h2 className={h2Style}>9. Security measures to keep your Personal Data safe</h2>
                <p className={pStyle}>
                    We employ appropriate technical and organizational security measures to help protect your Personal
                    Data against loss and to guard against access by unauthorized persons. We regularly review our
                    security policies and procedures to ensure that your Personal Data is secure and protected.
                </p>

                {/* Sections 10-12 */}
                <h2 className={h2Style}>10. Integrations user data</h2>
                <h3 className={h3Style}>10.1 Google user data</h3>
                <p className={pStyle}>
                    Andsend provides the ability to integrate with your Gmail account to let our Service help you send
                    e-mails via the Gmail API. Since the service (Andsend) is designed to integrate into Gmail it is
                    necessary for the service to connect to your Google account.
                </p>
                <p className={pStyle}>
                    To provide the automated service of sending, processing and monitoring messages created by the user
                    and approved to be sent through Andsend's service, we need to read, compose, and modify Gmail
                    message bodies (including attachments), metadata, headers, and settings.
                </p>
                <p className={pStyle}>
                    To minimize the amount of Personal Data collected and to request the API scope(s) as narrow as
                    possible, but still to provide accurate functioning and statistics from the Service, we will only
                    read specific parts of an incoming email by singling out key words suitable to administrate,
                    identify and query the messages assignable to our Service. We do not read the entire content of any
                    specific message unless expressly agreed to by the Client. Reasons for such approval would be for
                    necessary security purposes such as investigating abuse, to comply with applicable law or for
                    quality improvements in internal operations. Operations for quality improvements will be set forth
                    only when the data is aggregated and anonymized.
                </p>
                <p className={pStyle}>
                    The only information we store is your e-mail address of the Gmail account connected to the Service,
                    the authentication tokens, and identifiers for messages and conversations sent through the Service.
                    We only use this information to provide the following services:
                </p>
                <ul className="list-disc pl-8 mb-4">
                    <li className={listItemStyle}>
                        Creating and sending e-mail through Andsend's services on behalf of the Client
                    </li>
                    <li className={listItemStyle}>Tracking of replies to messages sent through our services</li>
                </ul>
                <p className={pStyle}>
                    We do not store incoming e-mails from your Gmail account. We will not use Gmail data for serving our
                    own or any third-party advertisements. Any of your Gmail data collected or processed by us will not
                    be shared with any third parties other than stated in section 8. You can always revoke Andsend's
                    access to your Gmail account by contacting us at{' '}
                    <a href="mailto:support@andsend.com" className={linkStyle}>
                        support@andsend.com
                    </a>{' '}
                    or{' '}
                    <a
                        href="https://myaccount.google.com/permissions?utm_source=google-account&utm_medium=web"
                        className={linkStyle}
                        target="_blank"
                        rel="noreferrer"
                    >
                        here
                    </a>
                    . If you decide to do so, Andsend will lose access to your Gmail account and will no longer be able
                    to provide you campaign sending services through the Gmail API. We delete all data collected from
                    your Gmail account upon request. The following scopes are used with our Service:
                </p>
                <ul className="list-disc pl-8 mb-4">
                    <li className={listItemStyle}>
                        <a
                            href="https://www.googleapis.com/auth/gmail.send"
                            className={linkStyle}
                            target="_blank"
                            rel="noreferrer"
                        >
                            https://www.googleapis.com/auth/gmail.send
                        </a>
                    </li>
                    <li className={listItemStyle}>
                        <a
                            href="https://www.googleapis.com/auth/gmail.metadata"
                            className={linkStyle}
                            target="_blank"
                            rel="noreferrer"
                        >
                            https://www.googleapis.com/auth/gmail.metadata
                        </a>
                    </li>
                    <li className={listItemStyle}>
                        <a
                            href="https://www.googleapis.com/auth/gmail.readonly"
                            className={linkStyle}
                            target="_blank"
                            rel="noreferrer"
                        >
                            https://www.googleapis.com/auth/gmail.readonly (optional opt-in by Customer)
                        </a>
                    </li>
                </ul>

                <h3 className={h3Style}>10.2 Outlook</h3>
                <p className={pStyle}>
                    Andsend integrates with Outlook (Office 365 and Microsoft Exchange) similarly as to how Andsend
                    integrates with Gmail, but by connecting with the Microsoft Graph API instead of the Gmail API. It
                    is optional for the user to opt-in to the Outlook integration and Microsoft Graph API. The scopes
                    used with the Microsoft Graph API are the following:
                </p>
                <ul className="list-disc pl-8 mb-4">
                    <li className={listItemStyle}>
                        <a
                            href="https://learn.microsoft.com/en-us/graph/permissions-reference#openid-connect-oidc-scopes"
                            className={linkStyle}
                            target="_blank"
                            rel="noreferrer"
                        >
                            email, offline_access, openid, profile
                        </a>
                    </li>
                    <li className={listItemStyle}>
                        <a
                            href="https://learn.microsoft.com/en-us/graph/permissions-reference"
                            className={linkStyle}
                            target="_blank"
                            rel="noreferrer"
                        >
                            User.Read
                        </a>
                    </li>
                    <li className={listItemStyle}>
                        <a
                            href="https://learn.microsoft.com/en-us/graph/permissions-reference"
                            className={linkStyle}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Mail.ReadWrite
                        </a>
                    </li>
                    <li className={listItemStyle}>
                        <a
                            href="https://learn.microsoft.com/en-us/graph/permissions-reference"
                            className={linkStyle}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Mail.Send
                        </a>
                    </li>
                </ul>

                <h3 className={h3Style}>10.3 LinkedIn</h3>
                <p className={pStyle}>
                    Installing the Andsend Chrome Extension allows Andsend to receive access to your session cookie to
                    perform actions on linkedin.com such as profile visits, connection requests and messaging access on
                    LinkedIn.
                </p>

                <h2 className={h2Style}>11. Your rights</h2>
                <p className={pStyle}>
                    <i>Access to, and correction of your data</i>
                </p>
                <p className={pStyle}>
                    As a registered client, you have the right to request the correction of incorrect information at any
                    time or change the personal information you have provided. You also have the right to see what
                    personal information we have recorded about you. You do this by requesting an extraction from
                    registers.
                </p>

                <p className={pStyle}>
                    <i>Revoke consent with future effect</i>
                </p>
                <p className={pStyle}>
                    If we process your Personal Data on the basis that you have the right to withdraw your consent
                    regarding future processing at any time.
                </p>

                <p className={pStyle}>
                    <i>Oppose processing</i>
                </p>
                <p className={pStyle}>
                    You have the right to oppose our processing of your Personal Data we carry out with a balance of
                    interest as a legal ground. You can opt-out advertising and communication at any time. You can
                    unsubscribe by clicking on a link in the current mailing or contact us (see contact details below).
                </p>

                <p className={pStyle}>
                    <i>Deletion</i>
                </p>
                <p className={pStyle}>
                    In some circumstances, you have the right to have your Personal Data deleted. However, this does not
                    apply if, for example, we are required by law to preserve the information.
                </p>

                <p className={pStyle}>
                    <i>Limitation of processing</i>
                </p>
                <p className={pStyle}>
                    You may also be entitled to request that the processing of your Personal Data to be restricted.
                    However, if you request the processing of your data to be restricted, it may mean that we no longer
                    can fulfill our possible obligations to you during the time the processing is limited.
                </p>

                <p className={pStyle}>
                    <i>Data portability</i>
                </p>
                <p className={pStyle}>
                    In the event that you wish to transfer your data from us, you also have the right to receive a copy
                    of the Personal Data relating to you collected in a structured, machine-readable format (right to
                    data portability). This right only covers the information that you have shared with us.
                </p>

                <h2 className={h2Style}>12. About cookies</h2>
                <p className={pStyle}>
                    When you visit our website, we use cookies. We will soon be updating our cookie policy.
                </p>

                <h2 className={h2Style}>13. Complaints</h2>
                <p className={pStyle}>
                    Should you be dissatisfied with our way of processing your Personal Data, please first contact us at{' '}
                    <a href="mailto:support@andsend.com" className={linkStyle}>
                        support@andsend.com
                    </a>
                    . If you are not satisfied with our way of handling your complaint, you can submit your complaint to
                    the competent supervisory authority.
                </p>

                <h2 className={h2Style}>14. Contact</h2>
                <p className={pStyle}>
                    Information to Personal Data controller: If you have further questions about our data protection or
                    if you wish to exercise your rights as above, you can easily reach us at{' '}
                    <a href="mailto:gdpr@andsend.com" className={linkStyle}>
                        gdpr@andsend.com
                    </a>
                    .
                </p>

                <h2 className={h2Style}>15. Changes to the Privacy Policy</h2>
                <p className={pStyle}>
                    We reserve the right to make changes to our privacy policy. You will always find the latest version
                    on the site. This will be communicated to you in case of updates that are crucial to our processing
                    of Personal Data (for example, modification of stated purposes).
                </p>

                <h2 className={h2Style}>16. Contact us</h2>
                <p className={pStyle}>
                    If you have any questions about this Privacy Policy or our data practices, please contact us at:
                </p>
                <p className={pStyle}>
                    <strong>Andsend Group AB</strong>
                    <br />
                    Drottninggatan 33
                    <br />
                    111 51 Stockholm
                    <br />
                    Sweden
                    <br />
                    Email: privacy@andsend.com
                </p>
            </div>
        </div>
    );
}

PrivacyPolicy20241219.displayName = 'PrivacyPolicy20241219';
