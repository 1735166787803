import { getShortFormatDateWithOrdinal } from '../../helpers/date';

export function getDateString() {
    const d = new Date().toLocaleString().split(',')[0].split('/');
    return d[0];
}

export function getDaysBetween(d1, d2) {
    let diff = Math.abs(d1?.getTime() - d2?.getTime());
    return diff / (1000 * 60 * 60 * 24);
}

export function getHoursBetween(d1, d2) {
    let diff = Math.abs(d1?.getTime() - d2?.getTime());
    return diff / (1000 * 60 * 60);
}

export function getCreditsRefreshDate(date) {
    if (date) return date.split('T')[0];
    else return '';
}

//Formats single digit time. For example 5 to 05
export function getDoubleDigitTime(time) {
    return (time < 10 ? '0' + time : time).toString();
}

//Takes an array of ["hh", "mm"] in UTC and returns and array in local time
export function getUTCToLocalTime(time) {
    const date = new Date();

    date.setUTCHours(time[0]);
    date.setUTCMinutes(time[1]);

    //Make sure that time is in double digit format
    const hours = getDoubleDigitTime(date.getHours());
    const minutes = getDoubleDigitTime(date.getMinutes());

    const timeArray = [hours, minutes];

    return timeArray;
}

export function getUTCTime(time, part) {
    const date = new Date();

    //Sets time to hours and minutes matching scheduling
    if (part === 1) {
        date.setHours(time[0].value);
        date.setMinutes(time[1].value);
    } else {
        date.setHours(time[2].value);
        date.setMinutes(time[3].value);
    }

    //Make sure that time is in double digit format
    const hours = getDoubleDigitTime(date.getUTCHours());
    const minutes = getDoubleDigitTime(date.getUTCMinutes());

    //Time formatted in hh:mm, in utc time
    const returnTime = hours + ':' + minutes;
    return returnTime;
}

//Returns an array of days in utc time, used when sending days to backend
export function getLocalToUTCDays(days, time, offset) {
    const dayOffset = getDayOffset(time, offset);
    return days
        .filter((day) => day.checked)
        .map((day) => (day.value + dayOffset < 0 && 6) || (day.value + dayOffset > 6 && 0) || day.value + dayOffset)
        .sort((a, b) => a - b);
}

//Returns an array of days in local time, used when receiving days from backend
export function getUTCToLocalDays(days, time, offset) {
    const dayOffset = getDayOffset(time, -offset);
    return days
        .filter((day) => day.checked)
        .map((day) => (day.value + dayOffset < 0 && 6) || (day.value + dayOffset > 6 && 0) || day.value + dayOffset)
        .sort((a, b) => a - b);
}

export function getDayOffset(time, offset) {
    if (parseInt(time[0].value) + offset / 60 > 23) return 1;
    else if (parseInt(time[0].value) + offset / 60 < 0) return -1;
    else return 0;
}

export function getTimeBetween(d1: Date, d2: Date) {
    const daysBetween = getDaysBetween(d1, d2);
    if (daysBetween >= 1) return Math.round(daysBetween) + 'd';
    else return Math.round(getHoursBetween(d1, d2)) + 'h';
}

export function isToday(date: Date) {
    const today = new Date();
    return (
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
    );
}

export function getDueTime(dateFrom: Date, dateTo: Date) {
    const now = new Date();

    if (now < dateFrom) {
        if (isToday(dateFrom))
            return (
                'Due Today ' + getDoubleDigitTime(dateFrom.getHours()) + ' - ' + getDoubleDigitTime(dateTo.getHours())
            );
        else return 'Due ' + getShortFormatDateWithOrdinal(dateFrom);
    }
    if (now > dateTo) {
        return timePassed(now, dateTo);
    }
}

export function timePassed(dateFrom?: Date, dateTo?: Date): string {
    const now = new Date();
    if (!dateFrom && !dateTo) return '';

    if (now < dateFrom) {
        if (now.toDateString() === dateFrom.toDateString()) {
            return `Due Today ${dateFrom.getHours()} ${dateFrom.getMinutes()}`;
        } else {
            return `Due ${getShortFormatDateWithOrdinal(dateFrom)}`;
        }
    }

    if (dateFrom > dateTo) {
        [dateFrom, dateTo] = [dateTo, dateFrom];
    }

    let daysPassed = (dateTo.getTime() - dateFrom.getTime()) / (1000 * 3600 * 24);
    let daysPassedRounded = Math.ceil((dateTo.getTime() - dateFrom.getTime()) / (1000 * 3600 * 24));

    let result = '';

    if (daysPassed < 1) {
        return 'Due Today';
    }

    if (daysPassedRounded >= 365) {
        const yearDiff = Math.floor(daysPassedRounded / 365);
        result += `${yearDiff}yr `;
        daysPassedRounded -= yearDiff * 365;
    }

    if (daysPassedRounded >= 30) {
        const monthDiff = Math.floor(daysPassedRounded / 30);
        result += `${monthDiff}mo `;
        daysPassedRounded -= monthDiff * 30;
    }

    if (daysPassedRounded >= 7) {
        const weekDiff = Math.floor(daysPassedRounded / 7);
        result += `${weekDiff}w `;
        daysPassedRounded -= weekDiff * 7;
    }

    if (daysPassedRounded > 0) {
        result += `${daysPassedRounded}d`;
    }

    if (!result) {
        return 'Due Today';
    }

    return `${result.trim()} ago`;
}
