import SubscriptionIssueDialog from './subscription-issue-dialog';

interface NoSeatsLeftInfoDialogProps {
    organizationName: string;
    activeUsersCount: number;
    adminEmail?: string;
    open?: boolean;
}
interface NoSeatsLeftInfoProps {
    adminEmail?: string;
}

const NoSeatsLeftInfo = ({ adminEmail }: NoSeatsLeftInfoProps) => (
    <div className="flex flex-col items-center px-6 py-10">
        <span className="mb-5 font-medium text-2xl text-secondary-foreground">Looks like there are no seats left</span>
        <span className="text-base text-secondary-foreground text-center">
            To join your team, ask the workspace admin <span className="underline text-blue-600">{adminEmail}</span> to
            assign you a paid seat.
        </span>
    </div>
);

const NoSeatsLeftInfoDialog = ({
    organizationName,
    activeUsersCount,
    adminEmail,
    open = true,
}: NoSeatsLeftInfoDialogProps) => {
    return (
        <SubscriptionIssueDialog organizationName={organizationName} activeUsersCount={activeUsersCount} open={open}>
            <NoSeatsLeftInfo adminEmail={adminEmail} />
        </SubscriptionIssueDialog>
    );
};

export default NoSeatsLeftInfoDialog;
