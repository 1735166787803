import { useDispatch } from 'react-redux-latest';
import { AppDispatch } from '../redux/store/configureStore';
import { handleErrorNotification, handleSuccessNotification } from '../redux/actions';

export const useClipboard = () => {
    const dispatch = useDispatch<AppDispatch>();
    const copyToClipboard = (text: string) => {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                dispatch(handleSuccessNotification('Copied to clipboard', 7));
            })
            .catch((error) => {
                dispatch(handleErrorNotification('Something went wrong', 10));
            });
    };

    return {
        copyToClipboard,
    };
};
