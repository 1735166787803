import { Button } from '@mui/material';

type LoadingButtonProps = {
    isLoading: boolean;
    disabled: boolean;
    onClick?: () => void;
    type?: 'button' | 'submit' | 'reset';
    children: React.ReactElement;
    fullWidth?: boolean;
};

const LoadingButton: React.FC<LoadingButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
    isLoading,
    disabled,
    onClick,
    type,
    children,
    fullWidth = true,
    ...props
}) => {
    return (
        <Button
            variant="contained"
            disableElevation
            fullWidth={fullWidth}
            sx={{
                padding: '9px',
                gap: '6px',
                borderRadius: '8px',
                backgroundColor: '#6A76FB',
                ':hover': {
                    backgroundColor: '#838dfc',
                },
                ...props.style,
            }}
            type={type}
            disabled={disabled}
            onClick={onClick}
        >
            {children}
        </Button>
    );
};

export default LoadingButton;
