import * as React from 'react';
import { cn } from '@shadcn/ui/lib/utils';
import { motion } from 'motion/react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { ContactAvatar } from '../../../components/contact-avatar';
import { Button } from '@shadcn/ui/components/ui/button';
import { ArrowRight, Check, Ellipsis, Loader2, Pen, Sparkles } from 'lucide-react';
import { Tooltip, TooltipContent } from '@shadcn/ui/components/ui/tooltip';
import { TooltipTrigger } from '@radix-ui/react-tooltip';
import { useSnoozeDialog } from '../../../components/dialogs/snooze-dialog';
import { useActions } from '../../../hooks/use-actions';
import { ActionBadge } from '../../../components/action-badges/action-badge';
import { MessageType } from '@zaplify/services/messaging/shared';
import { Badge } from '@shadcn/ui/components/ui/badge';
import { useDraftMessage } from '../../../hooks/messaging/use-draft-messages';
import { useSubscription } from '@apollo/client';
import { PROSPECT_ACTIONS_SUBSCRIPTION } from '@zaplify/graphql';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useSdk } from '../../../sdk';
import { useQuery as useQueryTan } from '@tanstack/react-query';
import { htmlToPlain } from '../../../functions/html-to-plain';
import { useAuth } from '../../../providers/authentication-provider';

dayjs.extend(utc);

export function replaceNewLinesWithBreaks(inputString: string): string {
    inputString = inputString.replace(/\r\n/g, '<br />');
    inputString = inputString.replace(/\\r/g, '');
    inputString = inputString.replace(/\r/g, '');
    inputString = inputString.replace(/\n/g, '<br />');
    inputString = inputString.replace(/\\n/g, '<br />');
    return inputString;
}

/** isCompleted must be passed on because the same contact can be both completed and scheduled */
export const ActionCard = React.memo(
    ({
        prospectId,
        isCompleted,
        linkPrefix,
        className,
        disableSelected,
    }: {
        prospectId: string;
        isCompleted: boolean;
        linkPrefix?: string;
        className?: string;
        disableSelected?: boolean;
    }) => {
        const {
            prospect: { getProspectById },
        } = useSdk();
        const { data: prospect } = useQueryTan(getProspectById(prospectId));

        const {
            authState: { isImpersonating },
        } = useAuth();
        const { getAction, getCompletedActions, handleMarkRead, actionsScheduledOnLtTimestamp } = useActions();
        const { openSnoozeDialog } = useSnoozeDialog();
        const { draftMessage } = useDraftMessage(prospectId);
        const { prospectId: paramsProspectId } = useParams();
        const isSelected = paramsProspectId === prospectId && !disableSelected;
        const scheduledAction = getAction(prospectId);
        const completedActions = getCompletedActions(prospectId);
        const action = scheduledAction || completedActions[0];
        const numCompletedActions = completedActions?.length;
        const isUnread = scheduledAction?.unread;
        const location = useLocation();

        const { data: subscribedAction } = useSubscription(PROSPECT_ACTIONS_SUBSCRIPTION, {
            variables: { prospectId, scheduledOnBefore: actionsScheduledOnLtTimestamp },
            skip: !prospectId,
        });

        const contactDisplayName = React.useMemo(() => {
            if (prospect?.data?.fullName) {
                return prospect.data.fullName.split(' ')[0] + ' ' + prospect.data.fullName.split(' ')[1] || '';
            }
            return action?.name.split(' ')[0] + ' ' + action?.name.split(' ')[1]?.[0] || '';
        }, [prospect?.data?.fullName, action?.name]);

        const completedActionText = React.useMemo(() => {
            if (!isCompleted) return '';
            if (completedActions[0]?.Message?.actionType === MessageType.linkedinMessage) {
                return 'Sent LinkedIn message';
            }
            if (completedActions[0]?.Message?.actionType === MessageType.emailMessage) {
                return 'Sent email';
            }
            if (completedActions[0]?.Message?.actionType === MessageType.linkedinConnectionRequest) {
                return 'Sent LinkedIn connection request';
            }
            return '';
        }, [isCompleted, completedActions]);

        const isDraft = React.useMemo(() => {
            if (isCompleted) return false;
            if (!draftMessage?.content && !draftMessage?.subjectLine) return false;
            if (
                draftMessage?.content?.trim() &&
                htmlToPlain(draftMessage?.content) !== htmlToPlain(action?.Message?.content)
            ) {
                return true;
            }
            return false;
        }, [draftMessage, action, isCompleted]);

        const conversationPreviewText = React.useMemo(() => {
            if (
                draftMessage?.content?.trim() &&
                htmlToPlain(draftMessage?.content) !== htmlToPlain(action?.Message?.content)
            ) {
                return htmlToPlain(replaceNewLinesWithBreaks(draftMessage.content));
            }
            if (draftMessage?.subjectLine && draftMessage?.subjectLine !== action?.Message?.subjectLine) {
                return draftMessage.subjectLine;
            }
            if (action) {
                return htmlToPlain(
                    action?.Message?.content
                        ? replaceNewLinesWithBreaks(action?.Message?.content)
                        : action?.Message?.subjectLine || ''
                );
            }
            return '';
        }, [draftMessage, action]);

        const [isThinking, setIsThinking] = React.useState(false);
        const thinkingTime = 2000;

        const isGenerating = React.useMemo(() => {
            if (isCompleted) return false;
            return subscribedAction?.OutreachSuggestions?.[0]?.isGenerating || false;
        }, [subscribedAction, isCompleted]);

        React.useEffect(() => {
            if (isCompleted) return;

            if (
                isGenerating &&
                dayjs().diff(dayjs.utc(subscribedAction?.OutreachSuggestions?.[0]?.updatedAt), 'milliseconds') <=
                    thinkingTime
            ) {
                setIsThinking(true);
                const timer = setTimeout(() => {
                    setIsThinking(false);
                }, Math.max(0, thinkingTime - dayjs().diff(dayjs.utc(subscribedAction?.OutreachSuggestions?.[0]?.updatedAt), 'milliseconds')));

                return () => clearTimeout(timer);
            }
        }, [isGenerating, subscribedAction?.OutreachSuggestions?.[0]?.updatedAt, isCompleted]);

        const handleMarkReadClick = React.useCallback(() => {
            if (isUnread && !isImpersonating) {
                handleMarkRead(action?.id);
            }
        }, [isUnread, action?.id, handleMarkRead, isImpersonating]);

        return (
            <motion.div
                layout
                initial={false}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -300 }}
                transition={{
                    layout: { type: 'spring', stiffness: 300, damping: 30 },
                    duration: 0.5,
                }}
            >
                <Link
                    to={{
                        pathname: `${linkPrefix ? linkPrefix + '/' : ''}${prospectId}`,
                        search: location.search,
                    }}
                    onClick={handleMarkReadClick}
                    className={cn(
                        'group',
                        'flex gap-2',
                        isSelected ? 'bg-background-active' : '',
                        'rounded-md',
                        'p-2',
                        'hover:bg-background-secondary-hover',
                        className
                    )}
                >
                    <ContactAvatar
                        avatarUrl={prospect?.data?.linkedinProfileImgUrl}
                        fullName={prospect?.data?.fullName}
                    />
                    <div className="flex flex-col gap-1 items-start w-full relative">
                        <div className="flex justify-between items-center w-full">
                            <div className="flex items-center gap-1">
                                <span
                                    className={cn('font-medium flex-shrink-0', isCompleted && 'text-muted-foreground')}
                                >
                                    {contactDisplayName}
                                </span>
                                <span className="text-muted-foreground line-clamp-1 break-all">
                                    - {prospect?.data?.organizationName || action?.accountName}
                                </span>
                            </div>
                            {isCompleted ? (
                                <div className="flex items-center gap-1">
                                    {numCompletedActions > 1 && (
                                        <Tooltip delayDuration={500}>
                                            <TooltipTrigger>
                                                <Badge variant="outline">{numCompletedActions}</Badge>
                                            </TooltipTrigger>
                                            <TooltipContent>
                                                <p>{numCompletedActions} completed actions</p>
                                            </TooltipContent>
                                        </Tooltip>
                                    )}
                                </div>
                            ) : (
                                <div onClick={(e) => e.preventDefault()}>
                                    <Tooltip delayDuration={500}>
                                        <TooltipTrigger asChild>
                                            <Button
                                                variant="ghost"
                                                size="sm"
                                                className="hidden p-0.5 h-fit opacity-0 group-hover:flex group-hover:opacity-100 transition-opacity"
                                                onClick={() => openSnoozeDialog({ prospectId })}
                                            >
                                                <Ellipsis className="w-5 h-5" />
                                            </Button>
                                        </TooltipTrigger>
                                        <TooltipContent className="max-w-72 space-y-1">
                                            <p>Manage...</p>
                                        </TooltipContent>
                                    </Tooltip>
                                </div>
                            )}
                        </div>
                        <ActionBadge prospectId={prospectId} isCompleted={isCompleted} />
                        {isCompleted ? (
                            <div className="flex items-center text-sm text-muted-foreground line-clamp-1 break-all">
                                <Check size={16} strokeWidth={1} className="mr-1" />
                                <span>{completedActionText}</span>
                            </div>
                        ) : (
                            <p
                                className={cn(
                                    'flex items-center text-sm text-muted-foreground line-clamp-1 break-all',
                                    isUnread && !isDraft && 'font-semibold',
                                    isDraft && 'italic'
                                )}
                            >
                                {isThinking ? (
                                    <>
                                        <Loader2 className="h-3 animate-spin text-background-brand-primary inline-block" />
                                        <span>Thinking...</span>
                                    </>
                                ) : isGenerating ? (
                                    <>
                                        <Loader2 className="h-3 animate-spin text-background-brand-primary inline-block" />
                                        <span>Generating message...</span>
                                    </>
                                ) : (
                                    <>
                                        {isDraft ? (
                                            <Pen className="stroke-[2] inline-block h-3" />
                                        ) : (
                                            <Sparkles className="stroke-[2] inline-block h-3 text-background-brand-primary" />
                                        )}
                                        {conversationPreviewText}
                                    </>
                                )}
                            </p>
                        )}
                    </div>
                </Link>
            </motion.div>
        );
    }
);

ActionCard.displayName = 'ActionCard';
