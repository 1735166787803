import { styled } from '@mui/styles';
import { ErrorIcon } from '../error-icon';
import { Typography } from '@mui/material';

export const WarningBoxContainer = styled('div')(({ theme }: any) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 52,
    background: theme.palette.colors.orangeWhite,
    border: '1px solid #FEC165',
    boxSizing: 'border-box',
    borderRadius: 8,
    '& > p': {
        color: '#212121',
        fontSize: 14,
        fontWeight: 500,
        marginLeft: 15,
    },
}));

export const WarningBox = ({ text }: { text: string }) => (
    <WarningBoxContainer>
        <ErrorIcon color='#FEC165' />
        <Typography>{text}</Typography>
    </WarningBoxContainer>
);
