import { createContext, useRef, useContext, useMemo, useState, useEffect, useCallback } from 'react';
import { useQuery, useSubscription } from '@apollo/client';
import {
    ACTIONS_SUBSCRIPTION,
    ACTIONS_SUBSCRIPTION_BY_PROSPECT_IDS,
    ActionsSubscriptionByProspectIdsSubscription,
    COMPLETED_ACTIONS_SUBSCRIPTION,
    CompletedActionsSubscriptionSubscription,
    GET_USER_CONTACTS_PROSPECT_IDS_BY_GROUP_IDS,
} from '@zaplify/graphql';
import dayjs from 'dayjs';
import { scoreActions, ScoredOutreachSuggestion } from '../functions/score-outreach-suggestions';
import { toast } from '@shadcn/ui/hooks/use-toast';
import { useMutation as useMutationTan } from '@tanstack/react-query';
import {
    dismissOutreachSuggestionByProspectId,
    markReadOutreachSuggestion,
    snoozeOutreachSuggestion,
} from '../sdk/internal/outreach-suggestion.sdk';
import { useSearchParams } from 'react-router-dom';

type CompletedAction = CompletedActionsSubscriptionSubscription['OutreachSuggestions'][number];

type ActionContextType = {
    isLoadingActions: boolean;
    totalActionsCount: number;
    actionsScheduledOnLtTimestamp: string;
    prospectIdsWithActions: string[];
    prospectIdsWithCompletedActions: string[];
    totalCompletedActions: number;
    // selectedGroupIds: string[];
    // setSelectedGroupIds: (groupIds: string[] | ((prevValue: string[]) => string[])) => void;
    getBadge: (prospectId: string) => string | null;
    getAction: (prospectId: string) => ScoredOutreachSuggestion | null;
    getCompletedActions: (prospectId: string) => CompletedAction[] | null;
    handleSnooze: (actionId: string, duration: number | 'auto') => Promise<void>;
    handleStopSuggestingActions: (prospectId: string) => Promise<void>;
    handleMarkRead: (actionId: string) => Promise<void>;
};

const ActionContext = createContext<ActionContextType | undefined>(undefined);

export const ActionsProvider = ({ children }: { children: React.ReactNode }) => {
    const scheduledOnLt = useRef(dayjs().add(1, 'days').startOf('day').format('YYYY-MM-DD')).current;
    const completedOnGte = useRef(dayjs().startOf('day').format('YYYY-MM-DD')).current;

    const { mutateAsync: markReadSuggestion } = useMutationTan(markReadOutreachSuggestion());
    const { mutateAsync: snoozeSuggestion } = useMutationTan(snoozeOutreachSuggestion());
    const { mutateAsync: dismissSuggestion } = useMutationTan(dismissOutreachSuggestionByProspectId());

    const [searchParams] = useSearchParams();
    const searchParamGroupId = searchParams.get('groupId');

    const { data: userContacts } = useQuery(GET_USER_CONTACTS_PROSPECT_IDS_BY_GROUP_IDS, {
        variables: {
            groupIds: [searchParamGroupId],
        },
        skip: !searchParamGroupId,
    });

    const selectedProspectIds = useMemo(() => {
        if (searchParamGroupId) {
            return userContacts?.UserContacts.map((userContact) => userContact.prospectId) || [];
        }
        return [];
    }, [userContacts, searchParamGroupId]);

    const { data: actionsDataByProspectId, loading: isLoadingActionsByProspectId } = useSubscription(
        ACTIONS_SUBSCRIPTION_BY_PROSPECT_IDS,
        {
            variables: {
                limit: 200,
                scheduledOnLt,
                prospectIds: selectedProspectIds,
            },
            skip: selectedProspectIds?.length === 0,
        }
    );

    const { data: actionsData, loading: isLoadingActions } = useSubscription(ACTIONS_SUBSCRIPTION, {
        variables: {
            limit: 200,
            scheduledOnLt,
        },
    });
    const { data: completedActionsData } = useSubscription(COMPLETED_ACTIONS_SUBSCRIPTION, {
        variables: {
            limit: 50,
            scheduledOnLt,
            completedOnGte,
        },
    });

    const completedActions = useMemo(() => {
        if (completedActionsData?.OutreachSuggestions) {
            return completedActionsData.OutreachSuggestions;
        }
        return [];
    }, [completedActionsData]);

    const scoreActionsCallback = useCallback(
        (actions: ActionsSubscriptionByProspectIdsSubscription['OutreachSuggestions']) => {
            if (!actions) return [];
            return scoreActions(actions);
        },
        []
    );

    const scoredActions = useMemo(() => {
        let actionsToSort = null;

        if (searchParamGroupId && !isLoadingActionsByProspectId) {
            actionsToSort = actionsDataByProspectId?.OutreachSuggestions;
        } else if (!searchParamGroupId && !isLoadingActions) {
            actionsToSort = actionsData?.OutreachSuggestions;
        }

        return actionsToSort ? scoreActionsCallback(actionsToSort) : [];
    }, [
        actionsDataByProspectId?.OutreachSuggestions,
        actionsData?.OutreachSuggestions,
        isLoadingActions,
        isLoadingActionsByProspectId,
        searchParamGroupId,
        scoreActionsCallback,
    ]);

    const totalActionsCount = Array.from(
        new Set(actionsData?.OutreachSuggestions.map((action) => action.prospectId))
    ).length;
    const prospectIdsWithActions = Array.from(new Set(scoredActions.map((action) => action.prospectId)));
    const prospectIdsWithCompletedActions = Array.from(new Set(completedActions.map((action) => action.prospectId)));

    const getAction = useCallback(
        (prospectId: string) => {
            const scheduledActionsForProspect = scoredActions.filter((action) => action.prospectId === prospectId);
            const scheduledAction = scheduledActionsForProspect.reduce((latest, action) => {
                return !latest || new Date(action.createdAt) > new Date(latest.createdAt) ? action : latest;
            }, null);
            return scheduledAction;
        },
        [scoredActions]
    );

    const getCompletedActions = useCallback(
        (prospectId: string) => {
            const actions = completedActions.filter((action) => action.prospectId === prospectId);
            return actions;
        },
        [completedActions]
    );

    const getBadge = useCallback(
        (prospectId: string) => {
            const action = getAction(prospectId);
            if (!action) {
                return null;
            }
            return action?.badge;
        },
        [getAction]
    );

    const handleSnooze = useCallback(
        async (prospectId: string, duration: number | 'auto') => {
            if (duration !== 'auto' && duration < 0) {
                return;
            }
            try {
                await snoozeSuggestion({ prospectId, daysToSnooze: duration });
                if (duration === 'auto') return;

                if (duration > 0) {
                    toast({
                        title: 'Action snoozed',
                        description: `The action will re-appear in ${duration} ${duration > 1 ? 'days' : 'day'}`,
                        variant: 'success',
                        duration: 5000,
                    });
                } else {
                    toast({
                        title: 'Creating action',
                        description: 'An action will be created immediately',
                        variant: 'success',
                        duration: 5000,
                    });
                }
            } catch (error) {
                toast({
                    title: 'Failed to snooze action',
                    description: 'Please try again',
                    variant: 'error',
                    duration: 8000,
                });
            }
        },
        [snoozeSuggestion]
    );

    const handleStopSuggestingActions = useCallback(
        async (prospectId: string) => {
            try {
                await dismissSuggestion({ prospectId });
                toast({
                    title: 'Action discarded',
                    description: 'A new action will be created if new messages appear in the conversation',
                    variant: 'success',
                    duration: 5000,
                });
            } catch (error) {
                toast({
                    title: 'Failed to discard action',
                    description: 'Please try again',
                    variant: 'error',
                    duration: 8000,
                });
            }
        },
        [dismissSuggestion]
    );

    const handleMarkRead = useCallback(
        async (actionId: string) => {
            try {
                await markReadSuggestion({ outreachSuggestionId: actionId });
            } catch (error) {
                toast({
                    title: 'Failed to mark action as read',
                    description: 'Please try again',
                    variant: 'error',
                    duration: 8000,
                });
            }
        },
        [markReadSuggestion]
    );

    const contextValue = useMemo(
        () => ({
            isLoadingActions,
            actionsScheduledOnLtTimestamp: scheduledOnLt,
            totalActionsCount,
            prospectIdsWithActions,
            prospectIdsWithCompletedActions,
            totalCompletedActions: completedActions.length,
            // selectedGroupIds,
            // setSelectedGroupIds,
            getBadge,
            getAction,
            getCompletedActions,
            handleSnooze,
            handleStopSuggestingActions,
            handleMarkRead,
        }),
        [
            isLoadingActions,
            scheduledOnLt,
            totalActionsCount,
            prospectIdsWithActions,
            prospectIdsWithCompletedActions,
            completedActions.length,
            // selectedGroupIds,
        ]
    );

    return <ActionContext.Provider value={contextValue}>{children}</ActionContext.Provider>;
};

export const useActions = () => {
    const context = useContext(ActionContext);
    if (!context) {
        console.error('useActions must be used within an ActionsProvider', new Error().stack);
    }
    return context;
};
