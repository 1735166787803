export const convertValueToCurrency = (value: number, currency: string): string => {
    return value.toLocaleString('en-US', { style: 'currency', currency: currency }).replace(',', ' ');
};

export const getPriceReadableFormat = ({
    price,
    maximumFractionDigits = 0,
    billingCurrency = 'EUR',
    round = true,
}): string => {
    if (!round) {
        return getFormattedNumber(billingCurrency, maximumFractionDigits, price);
    }
    const decimalPart = price - Math.floor(price);
    const roundedPrice = decimalPart > 0.5 ? Math.ceil(price) : Math.floor(price);
    return getFormattedNumber(billingCurrency, maximumFractionDigits, roundedPrice);
};

const getFormattedNumber = (billingCurrency: string, maximumFractionDigits: number, number: number): string => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: billingCurrency,
        minimumFractionDigits: maximumFractionDigits,
        maximumFractionDigits,
    }).format(number);
};
