import { makeAssistantToolUI, useThreadRuntime } from '@assistant-ui/react';
import {
    SearchContactsFreeTextToolFactory,
    SearchContactsFreeTextToolArgs,
    SearchContactsFreeTextToolResult,
    GetContactsByPlaybookToolResult,
    GetContactsByPlaybookToolFactory,
    GetContactsByPlaybookToolArgs,
    GetContactsByLinkedinUserIdsToolResult,
    GetContactsByLinkedinUserIdsToolFactory,
    GetContactsByLinkedinUserIdsToolArgs,
} from '@andsend/services/agent/shared';
import { ContactCard } from '../../../../components/contact-card/contact-card';
import { ChevronRight } from 'lucide-react';
import { ChevronLeft } from 'lucide-react';
import { useState } from 'react';
import { cn } from '@shadcn/ui/lib/utils';
import { Button } from '@shadcn/ui/components/ui/button';

interface CardStackProps {
    cards: React.ReactNode[];
}

const CardStack = ({ cards }: CardStackProps) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const goToNext = () => {
        setCurrentIndex((prev) => (prev + 1) % cards.length);
    };

    const goToPrev = () => {
        setCurrentIndex((prev) => (prev - 1 + cards.length) % cards.length);
    };

    const getCardStatus = (index: number): 'active' | 'inactive' | 'prev' | 'next' => {
        if (index === currentIndex) return 'active';
        const relativePosition = (index - currentIndex + cards.length) % cards.length;
        if (relativePosition === 1) return 'next';
        if (relativePosition === cards.length - 1) return 'prev';
        return 'inactive';
    };

    return (
        <div className="h-[22rem] select-none p-4 pl-20 mb-1">
            <div className="card-stack relative h-52">
                {cards.map((card, index) => (
                    <div
                        key={index}
                        className={cn('absolute inset-0 transition-all duration-300 h-64 w-64', {
                            'z-30 translate-x-0 opacity-100': getCardStatus(index) === 'active',
                            'z-20 translate-x-[calc(25%)] opacity-50 scale-90 cursor-pointer hover:opacity-75 hover:scale-[0.94] [&_button]:pointer-events-none':
                                getCardStatus(index) === 'next',
                            'z-10 -translate-x-[calc(25%)] opacity-50 scale-90 cursor-pointer hover:opacity-75 hover:scale-[0.94] [&_button]:pointer-events-none':
                                getCardStatus(index) === 'prev',
                            'z-0 translate-x-0 opacity-0 pointer-events-none': getCardStatus(index) === 'inactive',
                        })}
                        onClick={() => {
                            if (getCardStatus(index) === 'next') {
                                goToNext();
                            } else if (getCardStatus(index) === 'prev') {
                                goToPrev();
                            }
                        }}
                    >
                        {card}
                    </div>
                ))}
            </div>

            <div className="-ml-24 mt-[6rem] z-50 flex justify-center gap-4">
                <Button variant="ghost" size="icon" onClick={goToPrev}>
                    <ChevronLeft className="w-6 h-6" />
                </Button>
                <div className="flex items-center gap-2">
                    <span className="text-sm text-muted-foreground">
                        {currentIndex + 1} / {cards.length}
                    </span>
                </div>
                <Button variant="ghost" size="icon" onClick={goToNext}>
                    <ChevronRight className="w-6 h-6" />
                </Button>
            </div>
        </div>
    );
};

const ContactCardsUI = ({
    args,
    result,
}: {
    args: SearchContactsFreeTextToolArgs | GetContactsByPlaybookToolArgs | GetContactsByLinkedinUserIdsToolArgs;
    result: SearchContactsFreeTextToolResult | GetContactsByPlaybookToolResult | GetContactsByLinkedinUserIdsToolResult;
}) => {
    if (!result) {
        return <div>No contacts found</div>;
    }

    return (
        <CardStack
            cards={result.contacts.map((contact) => (
                <ContactCard linkedinUserId={contact?.linkedinProfile?.memberId} prospectId={contact.prospect?.id} />
            ))}
        />
    );
};

const SearchContactsFreeTextToolUI = makeAssistantToolUI<
    SearchContactsFreeTextToolArgs,
    SearchContactsFreeTextToolResult
>({
    toolName: SearchContactsFreeTextToolFactory.name,
    render: ({ args, result }) => ContactCardsUI({ args, result }),
});

const GetContactsByPlaybookToolUI = makeAssistantToolUI<GetContactsByPlaybookToolArgs, GetContactsByPlaybookToolResult>(
    {
        toolName: GetContactsByPlaybookToolFactory.name,
        render: ({ args, result }) => ContactCardsUI({ args, result }),
    }
);

const GetContactsByLinkedinUserIdsToolUI = makeAssistantToolUI<
    GetContactsByLinkedinUserIdsToolArgs,
    GetContactsByLinkedinUserIdsToolResult
>({
    toolName: GetContactsByLinkedinUserIdsToolFactory.name,
    render: ({ args, result }) => ContactCardsUI({ args, result }),
});

export const contactToolsUI = [
    SearchContactsFreeTextToolUI,
    GetContactsByPlaybookToolUI,
    GetContactsByLinkedinUserIdsToolUI,
];
