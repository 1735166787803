import { useQuery } from '@apollo/client';
import { Button } from '@shadcn/ui/components/ui/button';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '@shadcn/ui/components/ui/dropdown-menu';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@shadcn/ui/components/ui/tooltip';
import { GET_GROUPS } from '@zaplify/graphql';
import { Check, ChevronDown, Loader2, Plus } from 'lucide-react';
import { cn } from '@shadcn/ui/lib/utils';
import { useToast } from '@shadcn/ui/hooks/use-toast';
import { ContactSource } from '@zaplify/services/user-contacts/shared';
import { useContactOwners } from '../../hooks/use-contact-owners';
import { useMemo } from 'react';
import { useAddContacts } from '../../hooks/use-add-contacts';

interface AddContactButtonProps {
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
    personId?: string;
    linkedinUrl?: string;
    linkedinUserId?: string;
    userContactId?: string;
    className?: string;
    variant?: 'default' | 'secondary' | 'ghost' | 'outline';
    source: ContactSource;
    size?: 'default' | 'sm';
    alwaysDropdown?: boolean;
    onAdded?: () => void;
    onStartedAdding?: () => void;
    preAddValidation?: () => void;
    addToGroupId?: string;
}

export function AddContactButton({
    startIcon: inputtedStartIcon,
    endIcon: inputtedEndIcon,
    personId,
    linkedinUrl,
    linkedinUserId,
    userContactId,
    className,
    variant = 'default',
    source,
    size = 'default',
    onAdded,
    onStartedAdding,
    addToGroupId,
    alwaysDropdown = false,
    preAddValidation,
}: AddContactButtonProps) {
    const { toast } = useToast();
    const { addContacts, purchasingInProgress } = useAddContacts();

    const userIdBasedLinkedinUrl = linkedinUserId ? `https://linkedin.com/in/${linkedinUserId}` : undefined;
    const { isOwnedByUser, refetchOwners } = useContactOwners({
        personId,
        linkedinUrl: userIdBasedLinkedinUrl ? userIdBasedLinkedinUrl : linkedinUrl,
    });

    const { data: playbooks, loading: loadingPlaybooks } = useQuery(GET_GROUPS);
    const groups = playbooks?.Groups || [];

    // Get the default playbook name
    const defaultPlaybook = useMemo(() => {
        if (addToGroupId) {
            return playbooks?.Groups?.find((group) => group.id === addToGroupId)?.name || 'Default';
        }
        return playbooks?.Groups?.find((group) => group.default)?.name || 'Default';
    }, [playbooks, addToGroupId]);

    const handleAddClick = async (groupId?: string) => {
        preAddValidation?.();
        const result = await addContacts([{ personId, linkedinUrl, linkedinUserId, userContactId }], {
            groupId: groupId || addToGroupId,
            source,
            onStartedAdding,
            onAdded: () => {
                const selectedGroup = playbooks?.Groups?.find((g) => g.id === (groupId || addToGroupId));
                const groupName = selectedGroup?.name || playbooks?.Groups?.find((g) => g.default)?.name || 'Default';

                if (!userContactId) {
                    toast({
                        title: 'Contact added',
                        description: `Contact assigned to playbook ${groupName}. An action will be created for you to start the conversation.`,
                        duration: 10000,
                    });
                }

                onAdded?.();
                refetchOwners();
            },
        });
    };

    const disabled = isOwnedByUser || purchasingInProgress;

    const startIcon = isOwnedByUser ? (
        <Check className="w-5 h-5" strokeWidth={1} />
    ) : purchasingInProgress ? (
        <Loader2 className="w-5 h-5 animate-spin" />
    ) : (
        <Plus className="w-5 h-5" strokeWidth={1} />
    );
    const endIcon = alwaysDropdown ? (
        isOwnedByUser ? (
            <Check className="w-5 h-5" strokeWidth={1} />
        ) : purchasingInProgress ? (
            <Loader2 className="w-5 h-5 animate-spin" />
        ) : (
            <ChevronDown className={cn('w-4 h-4', size === 'sm' && 'w-3 h-3')} strokeWidth={1} />
        )
    ) : (
        <ChevronDown className={cn('w-4 h-4', size === 'sm' && 'w-3 h-3')} strokeWidth={1} />
    );

    const addText = isOwnedByUser ? 'Added' : purchasingInProgress ? 'Adding' : 'Add';

    return (
        <div className={cn('flex', 'h-fit', className)}>
            {!alwaysDropdown && (
                <TooltipProvider>
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <Button
                                variant={variant}
                                disabled={disabled}
                                size={size}
                                className={cn(
                                    'gap-2 whitespace-nowrap w-full',
                                    !loadingPlaybooks && groups.length >= 2 && 'rounded-r-none',
                                    variant === 'secondary' && 'border border-gray-200',
                                    variant === 'ghost' && 'bg-transparent hover:bg-transparent',
                                    size === 'sm' && 'text-xs'
                                )}
                                onClick={() => handleAddClick()}
                            >
                                {inputtedStartIcon}
                                {startIcon}
                                <span>{addText}</span>
                                {inputtedEndIcon}
                            </Button>
                        </TooltipTrigger>
                        {!isOwnedByUser && !purchasingInProgress && (
                            <TooltipContent>
                                <p>
                                    Add to {defaultPlaybook} playbook {!addToGroupId && '(your default playbook)'}
                                </p>
                            </TooltipContent>
                        )}
                    </Tooltip>
                </TooltipProvider>
            )}

            {!loadingPlaybooks && (groups.length >= 2 || alwaysDropdown) && (
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button
                            variant={variant}
                            size={size}
                            disabled={disabled}
                            className={cn(
                                'rounded-l-none px-3',
                                !alwaysDropdown && 'border-l-[0.5px]',
                                variant === 'secondary' && 'border border-gray-200 border-l-0',
                                variant === 'ghost' && 'bg-transparent hover:bg-transparent',
                                size === 'sm' && 'text-xs',
                                alwaysDropdown && 'rounded-l-md'
                            )}
                        >
                            {inputtedStartIcon}
                            {alwaysDropdown && <span>{addText}</span>}
                            {inputtedEndIcon}
                            {endIcon}
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="start">
                        {groups.map((group) => (
                            <DropdownMenuItem key={group.id} onClick={() => handleAddClick(group.id)}>
                                {group.name.length > 30 ? `${group.name.slice(0, 30)}...` : group.name}
                            </DropdownMenuItem>
                        ))}
                    </DropdownMenuContent>
                </DropdownMenu>
            )}
        </div>
    );
}
