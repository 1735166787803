import { Socket, connect as socketConnect } from 'socket.io-client';
import { LocalStorageKeys } from '../../../config';
import { socketEndpoint } from './socketEndpoint';

interface ISocketClient {
    socket: Promise<Socket>;
    connect(token: string): Promise<Socket>;
    disconnect(): Promise<void>;
    on(event: string, callback: (...args: any[]) => void): Promise<void>;
    off(event: string, callback: (...args: any[]) => void): Promise<void>;
}

// TODO: this should be removed and all web socket logic should be moved to the web-socket-listener wrapper
export default class SocketClient implements ISocketClient {
    emit(event: string, data: any): Promise<void> {
        throw new Error('Method not implemented.');
    }
    socket;

    connect(token) {
        if (!token) return;

        const socket = socketConnect(socketEndpoint, {
            timeout: 5000,
            transports: ['websocket'],
            query: {
                authorization: `Bearer ${token}`,
            },
        });

        this.socket = new Promise((resolve, reject) => {
            socket.on('connect', () => {
                console.log('Succesfuly connected');
                resolve(socket);
            });
            socket.on('connect_error', (error) => {
                console.log('Connect error');
                reject(error);
            });
            socket.on('error', (error) => {
                console.log('error');
                reject(error);
            });
            socket.io.on('reconnect', (attempt) => {
                console.log('Succesfuly reconnected');
            });
            socket.io.on('reconnect_attempt', (attempt) => {
                console.log('reconnect_attempt', attempt);
            });
            socket.io.on('reconnect_error', () => {
                console.log('Reconnect error');
            });
            socket.io.on('reconnect_failed', () => {
                console.log('reconnect_failed');
            });
        });

        return this.socket;
    }

    disconnect(): Promise<void> {
        return new Promise((resolve) => {
            Promise.resolve(this.socket).then((socket) => {
                if (socket) {
                    socket.disconnect(() => {
                        this.socket = null;
                        console.log('Succesfuly disconnected');
                        resolve();
                    });
                }
            });
        });
    }

    on(event: string, callback: (...args: any[]) => void): Promise<void> {
        return new Promise(async (resolve, reject) => {
            Promise.resolve(this.socket).then((socket) => {
                if (socket) {
                    socket.on(event, callback);
                    resolve();
                } else {
                    reject('Socket is not connected');
                }
            });
        });
    }

    off(event: string, callback: (...args: any[]) => void): Promise<void> {
        return new Promise(async (resolve, reject) => {
            Promise.resolve(this.socket).then((socket) => {
                if (socket) {
                    socket.off(event, callback);
                    resolve();
                } else {
                    reject();
                }
            });
        });
    }
}
