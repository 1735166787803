import { Creator, NoteDto } from '../dtos';
import { ICommand, Topics } from '@zaplify/common';

export interface CreateNoteCommandParams {
    userId: string;
    prospectId: string;
    creator: Creator;
    content: string;
    title: string;
    uniqueIdentifier?: string;
    happenedOn?: Date;
}

export class CreateNoteCommand extends ICommand<NoteDto> {
    public static override readonly TOPIC = Topics.PROSPECTS;
    public readonly note: CreateNoteCommandParams;

    constructor(note: CreateNoteCommandParams) {
        super();
        this.note = note;
    }
}
