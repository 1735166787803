import { ProspectDataWithConnectionStatus } from '@zaplify/sdk';
import { UseQueryOptions } from '@tanstack/react-query';
import { useLiConversationsImport } from './use-li-conversations-import';
import { ConnectionStatus } from '@zaplify/campaigns';
import { ProspectDataDto } from '@zaplify/prospects';

export const useLinkedinProfileSearch = () => {
    const { fetchProfiles } = useLiConversationsImport();

    const fetchLinkedinProfilesQuery = (
        linkedinSlugs: string[],
        options?: Omit<
            UseQueryOptions<{ prospectData: ProspectDataDto; connectionStatus: ConnectionStatus }[], Error>,
            'queryKey' | 'queryFn'
        >
    ): UseQueryOptions<{ prospectData: ProspectDataDto; connectionStatus: ConnectionStatus }[], Error> => {
        return {
            queryKey: ['fetchLinkedinProfiles', linkedinSlugs],
            queryFn: () => fetchProfiles({ memberIds: linkedinSlugs, slowFetch: true }),
            enabled: linkedinSlugs?.length > 0,
            ...options,
        };
    };

    return {
        fetchLinkedinProfilesQuery,
    };
};
