import { useAccountDetailsDialog } from '../../../../../../components/dialogs/account-details-dialog';
import { AccountCardDescription } from '../account/account-card-description';
import { Button } from '@shadcn/ui/components/ui/button';
import { AccountLogo } from '../../../../../../components/account-logo';
import { useSuggestionsFeed } from '../../../hooks/use-suggestions-feed';

export const ContactCompanyInfo = () => {
    const {
        currentContactSuggestion: contactSuggestion,
        currentAccountData: accountData,
        currentAccountId: accountId,
    } = useSuggestionsFeed();
    const { openAccountDetailsDialog } = useAccountDetailsDialog();

    const name = accountData?.data?.name || contactSuggestion?.accountName;
    const website = contactSuggestion?.accountWebsite || accountData?.data?.website;
    const industry = accountData?.data?.industry || contactSuggestion?.accountIndustry;
    const size = accountData?.data?.size;

    return (
        <div className="flex flex-col gap-2 p-2 text-sm border-t">
            <div className="flex flex-row items-center gap-2">
                <AccountLogo accountWebsite={website} className="w-10 h-10 rounded-lg" />
                <div className="flex flex-col">
                    <p className="text-sm font-medium">{name}</p>
                    <div className="flex flex-col gap-2">
                        <div className="flex flex-row items-center gap-2">
                            {industry && <p className="text-sm">{industry}</p>}
                            {size && <p className="text-sm"> - {size}</p>}
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-2">
                <AccountCardDescription asSection={false} />
                <div className="py-2">
                    {accountId && (
                        <Button
                            variant="outline"
                            size="sm"
                            className="w-fit"
                            onClick={() => openAccountDetailsDialog(accountId)}
                        >
                            View Company
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};
