import { Box, CircularProgress, Typography } from '@mui/material';
import { CheckCircleIcon } from 'lucide-react';
import { motion, AnimatePresence } from 'motion/react';
import { StepProgress } from './completing-onboarding-loading-screen';

interface ProgressChecklistProps {
    steps: { [key: string]: { status: StepProgress; infoText: string } };
}

export const ProgressChecklist: React.FC<ProgressChecklistProps> = ({ steps }) => {
    const items = Object.entries(steps).filter(
        ([_, step]) => step.status === 'completed' || step.status === 'inProgress'
    );

    return (
        <div className="space-y-4">
            <AnimatePresence>
                {items.map(([key, step]) => (
                    <motion.div
                        key={key}
                        initial={{ opacity: 0, x: 10 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: -10 }}
                        transition={{ duration: 1 }}
                    >
                        <ProgressItem step={step} />
                    </motion.div>
                ))}
            </AnimatePresence>
        </div>
    );
};

interface ProgressItemProps {
    step: { status: StepProgress; infoText: string };
}

const ProgressItem: React.FC<ProgressItemProps> = ({ step }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'start',
                gap: '8px',
                padding: 1,
            }}
        >
            <Box sx={{ height: '24px', width: '24px' }}>
                {step.status === 'completed' ? (
                    <motion.div
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ type: 'spring', stiffness: 200, damping: 15 }}
                    >
                        <CheckCircleIcon className="h-6 w-6 text-green-500" />
                    </motion.div>
                ) : step.status === 'inProgress' ? (
                    <CircularProgress size={24} className="text-blue-500" />
                ) : null}
            </Box>
            <Typography
                sx={{
                    color: step.status === 'completed' ? '#9CA3AF' : '#3A4B59',
                    fontSize: '16px',
                    lineHeight: '24px',
                    fontWeight: 500,
                }}
            >
                {step.infoText}
            </Typography>
        </Box>
    );
};
