export function getEmailDomain(email: string) {
    return email?.split('@')?.pop();
}

export function isEmailCorrect(email: string) {
    return /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email,
    );
}

export const isEmail = (email: string) => {
    const pattern = /\S+@\S+\.\S+/;
    try {
        return pattern.test(email.trim());
    } catch (err) {
        console.error(`Error validation email`);
    }

    return false;
};

export function getEmailState(prospect: { createdAt?: any; data?: any }, review = false) {
    const { data } = prospect;
    const today = new Date();
    const emailState = { text: '', duration: 0 };

    if (isEmail(data.email)) {
        emailState.text = review ? 'Email' : data.email;
    } else if (data.email?.length === 4) {
        emailState.text = 'No Email';
    } else if ((today.getTime() - Date.parse(prospect.createdAt)) / (1000 * 60 * 60 * 24) < 4) {
        emailState.text = 'Finding Email';
        emailState.duration = Math.round(
            4 - (today.getTime() - Date.parse(prospect.createdAt)) / (1000 * 60 * 60 * 24),
        );
    } else {
        emailState.text = 'No Email';
    }

    return emailState;
}

export function isLinkedInCorrect(url: string | string[]) {
    return url.includes('linkedin.com');
}
