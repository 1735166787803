import { Box } from '@mui/material';

interface StyledBoxProps {
    children: React.ReactNode;
}

export const StyledPersonsSection: React.FC<StyledBoxProps> = ({ children }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: 'var(--no-spacing, 0px)',
                alignSelf: 'stretch',
                width: '100%',
                height: '100%',
            }}
        >
            {children}
        </Box>
    );
};

export const StyledPersonRow: React.FC<StyledBoxProps> = ({ children }) => {
    return (
        <Box
            className="person-row"
            sx={{
                display: 'flex',
                padding: 'var(--S-xs, 6px) var(--S-s-regular, 6px)',
                alignItems: 'center',
                gap: 'var(--S-s-regular, 8px)',
                alignSelf: 'stretch',
                width: '100%',
                boxSizing: 'border-box',
                '& .MuiAvatar-root': {
                    color: 'var(--grey-palette-white, #FFF)',
                    textAlign: 'center',
                    /* S1/Medium */
                    fontSize: 15,
                    fontWeight: 500,
                    lineHeight: '20px' /* 133.333% */,
                },
                '& .details': {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: 'var(--S-3xs, 2px)',
                    flex: '1 0 0',
                },
                '& .name': {
                    display: 'flex',
                    alignItems: 'center',
                    gap: 'var(--S-s-regular, 8px)',
                    alignSelf: 'stretch',
                    '& .text': {
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 1,
                        flex: '1 0 0',
                        overflow: 'hidden',
                        color: 'var(--grey-palette-grey-5, #3A4B59)',
                        textOverflow: 'ellipsis',

                        /* S1/Medium */
                        fontSize: 15,
                        fontWeight: 500,
                        lineHeight: '20px' /* 133.333% */,
                    },
                },
                '& .title': {
                    display: 'flex',
                    alignItems: 'center',
                    gap: 'var(--S-2xs, 4px)',
                    alignSelf: 'stretch',
                    '& .icon-container': {
                        display: 'flex',
                        alignItems: 'center',
                        gap: 'var(--S-2xs, 4px)',
                        alignSelf: 'stretch',
                        '& .icon': {
                            width: 12,
                            height: 12,
                            flexShrink: 0,
                        },
                    },
                    '& .text': {
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 1,
                        flex: '1 0 0',
                        overflow: 'hidden',
                        color: 'var(--grey-palette-grey-4, #6B7985)',
                        textOverflow: 'ellipsis',

                        /* S3/Regular */
                        fontSize: 13,
                        fontWeight: 400,
                        lineHeight: '19px' /* 146.154% */,
                    },
                },
                '& .location': {
                    display: 'flex',
                    alignItems: 'center',
                    gap: 'var(--S-2xs, 4px)',
                    alignSelf: 'stretch',
                    '& .icon-container': {
                        display: 'flex',
                        alignItems: 'center',
                        gap: 'var(--S-2xs, 4px)',
                        alignSelf: 'stretch',
                        '& .icon': {
                            width: 12,
                            height: 12,
                            flexShrink: 0,
                        },
                    },
                    '& .text': {
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 1,
                        flex: '1 0 0',
                        overflow: 'hidden',
                        color: 'var(--grey-palette-grey-4, #6B7985)',
                        textOverflow: 'ellipsis',

                        /* S3/Regular */
                        fontSize: 13,
                        fontWeight: 400,
                        lineHeight: '19px' /* 146.154% */,
                    },
                },
                '& .add-person-button': {
                    maxWidth: 100,
                    '& button:nth-of-type(2)': {
                        paddingX: '8px',
                    },
                },
                '& .success-tag': {
                    display: 'flex',
                    height: 32,
                    padding: 'var(--S-s-regular, 8px)',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 'var(--S-xs, 6px)',
                    borderRadius: 'var(--radius-sm, 6px)',
                    '& .icon-container': {
                        width: 16,
                        height: 16,
                        '& .icon': {
                            width: 16,
                            height: 16,
                            flexShrink: 0,
                            stroke: 'var(--system-green-base, #36B37E)',
                        },
                    },
                    '& .MuiTypography-root': {
                        color: 'var(--system-green-base, #36B37E)',
                        /* S2/Medium */
                        fontSize: 14,
                        fontWeight: 500,
                        lineHeight: '21px' /* 150% */,
                    },
                },
            }}
        >
            {children}
        </Box>
    );
};
