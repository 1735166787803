import { FC, useCallback, useEffect } from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@shadcn/ui/components/ui/table';
import { Button } from '@shadcn/ui/components/ui/button';
import { Avatar } from '@shadcn/ui/components/ui/avatar';
import { Card, CardContent } from '@shadcn/ui/components/ui/card';
import { Loader2, MoreHorizontal } from 'lucide-react';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '@shadcn/ui/components/ui/dropdown-menu';
import { InvitationStatus, UserRole, UserRoleDictionary, UserStatus } from '@zaplify/users/shared';

import { InviteUsersDialog } from './components/dialogs/invite-users-dialog';
import { StatusChangeDialog } from './components/dialogs/status-change-dialog';
import { RoleChangeDialog } from './components/dialogs/role-change-dialog';
import { CancelInvitationDialog } from './components/dialogs/cancel-invitation-dialog';

import { useUsersManagement, UserToManage } from './hooks/use-users-management';
import { useSeatsAndCredits } from '../../../hooks/use-seat-and-credits';
import { useAuth } from '../../../providers/authentication-provider';
import { useParams, useNavigate } from 'react-router-dom';

function isActiveUser(user: UserToManage) {
    const invitationStatuses = Object.values(InvitationStatus);
    return (
        !invitationStatuses.includes(user.status as InvitationStatus) &&
        user.status !== UserStatus.DEACTIVATED &&
        user.creditsUsed !== null &&
        user.creditsUsed !== undefined
    );
}

type ModalType = 'activate' | 'deactivate' | 'set_as_admin' | 'set_as_member' | 'cancel_invitation' | 'invite';

export const UsersPage: FC = () => {
    const {
        authState: { user },
    } = useAuth();
    const organizationId = user?.userOrganization?.id;
    const organizationName = user?.userOrganization?.name;
    const currentUserIsAdmin = user.roles.includes(UserRole.ORGANIZATION_ADMIN);

    const { seatSummary } = useSeatsAndCredits();
    const availableSeats = seatSummary?.availableSeatsCount;

    const { activeModal, userIdToManage } = useParams<{ activeModal: ModalType; userIdToManage: string }>();
    const navigate = useNavigate();

    const {
        usersToManage,
        getUsersAndInvitationsByOrganization,
        isLoadingRow,
        cancelInvitation,
        resendInvitation,
        inviteUsers,
        updateUserStatus,
        updateUserSeat,
        updateUserRole,
    } = useUsersManagement({ organizationId, currentUserId: user?.id });

    // const currentLoggedInUser = usersToManage.find((u) => u.email === user?.email);
    // const currentUserIsAdmin = currentLoggedInUser ? isUserAnAdmin(currentLoggedInUser) : undefined;
    const isLoadingTable = usersToManage.length === 0;
    const numberOfActiveUsers = usersToManage.filter(isActiveUser).length;

    const handleCloseModal = useCallback(() => {
        navigate('../..', { relative: 'path' });
    }, [navigate]);

    const handleShowModal = useCallback(
        (userId: string, type: ModalType) => {
            navigate(`./${userId}/${type}`);
        },
        [navigate]
    );

    const openStatusChangeModal = useCallback(
        ({ user, status }: { user: UserToManage; status: UserStatus }) => {
            handleShowModal(user.userId, status === UserStatus.ACTIVATED ? 'activate' : 'deactivate');
        },
        [handleShowModal]
    );

    const handleAddSeatAction = useCallback(
        ({ user, availableSeats }: { user: UserToManage; availableSeats: number }) => {
            if (availableSeats > 0) {
                updateUserSeat({ user, action: 'assign' });
            } else {
                navigate('../subscription/upgrade-plan', { relative: 'path' });
            }
        },
        [updateUserSeat, navigate]
    );

    const openRoleChangeModal = useCallback(
        ({ user, role }: { user: UserToManage; role: UserRole }) => {
            handleShowModal(user.userId, role === UserRole.ORGANIZATION_ADMIN ? 'set_as_admin' : 'set_as_member');
        },
        [handleShowModal]
    );

    const openInvitationModal = useCallback(
        ({ user, action }: { user: UserToManage; action: 'resend' | 'cancel' }) => {
            if (action === 'resend') {
                resendInvitation(user);
            }
            if (action === 'cancel') {
                handleShowModal(user.userId, 'cancel_invitation');
            }
        },
        [handleShowModal, resendInvitation]
    );

    useEffect(() => {
        if (organizationId) {
            getUsersAndInvitationsByOrganization(organizationId);
        }
    }, [organizationId]);

    if (!organizationId) return null;

    const selectedUser = userIdToManage ? usersToManage.find((u) => u.userId === userIdToManage) : null;

    return (
        <div className="flex flex-col gap-6 p-0">
            <div className="flex items-center justify-between">
                <div className="flex flex-col gap-1">
                    <span className="text-sm text-text-tertiary">{organizationName}</span>
                    <span className="text-2xl font-medium">{numberOfActiveUsers} Active users</span>
                </div>
                <Button onClick={() => handleShowModal('new', 'invite')}>Invite Members</Button>
            </div>

            <Card>
                <CardContent className="p-0">
                    {isLoadingTable ? (
                        <div className="flex items-center justify-center p-6">
                            <Loader2 className="h-6 w-6 animate-spin" />
                        </div>
                    ) : (
                        <Table>
                            <TableHeader>
                                <TableRow>
                                    <TableHead>User</TableHead>
                                    <TableHead>Role</TableHead>
                                    <TableHead>User status</TableHead>
                                    <TableHead>Seat</TableHead>
                                    {currentUserIsAdmin && <TableHead className="w-[100px]">Actions</TableHead>}
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {usersToManage.map((userToManage) => (
                                    <TableRow key={userToManage.userId || userToManage.email}>
                                        <TableCell>
                                            <div className="flex items-center gap-4">
                                                <Avatar>
                                                    <div className="flex h-full w-full items-center justify-center bg-primary text-primary-foreground">
                                                        {userToManage.initials}
                                                    </div>
                                                </Avatar>
                                                <div className="flex flex-col">
                                                    {userToManage.fullName && (
                                                        <span className="font-medium">{userToManage.fullName}</span>
                                                    )}
                                                    <span className="text-sm text-text-tertiary">
                                                        {userToManage.email}
                                                    </span>
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell>{UserRoleDictionary[userToManage.roles[0]] || '-'}</TableCell>
                                        <TableCell>
                                            {userToManage.status === UserStatus.ACTIVATED ? (
                                                <span className="text-success">Active</span>
                                            ) : userToManage.status === UserStatus.DEACTIVATED ? (
                                                <span className="text-destructive">Deactivated</span>
                                            ) : (
                                                <span className="text-warning">Pending</span>
                                            )}
                                        </TableCell>
                                        <TableCell>{userToManage.hasSeat ? 'Yes' : 'No'}</TableCell>
                                        {currentUserIsAdmin && (
                                            <TableCell>
                                                {/* TODO: Fix the loading state when re-sending an invitation */}
                                                {isLoadingRow?.userId === userToManage.userId && userToManage.userId ? (
                                                    <Loader2 className="h-4 w-4 animate-spin" />
                                                ) : (
                                                    <UserActions
                                                        userToManage={userToManage}
                                                        availableSeats={availableSeats}
                                                        onStatusChange={openStatusChangeModal}
                                                        onRoleChange={openRoleChangeModal}
                                                        onAddSeat={handleAddSeatAction}
                                                        onInvitation={openInvitationModal}
                                                    />
                                                )}
                                            </TableCell>
                                        )}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    )}
                </CardContent>
            </Card>

            <InviteUsersDialog
                isOpen={activeModal === 'invite'}
                onClose={handleCloseModal}
                onInvite={(emails, role) => {
                    inviteUsers(emails, role, organizationId);
                    handleCloseModal();
                }}
            />

            {selectedUser && activeModal === 'activate' && (
                <StatusChangeDialog
                    isOpen={true}
                    onClose={handleCloseModal}
                    onConfirm={() => updateUserStatus({ user: selectedUser, status: UserStatus.ACTIVATED })}
                    user={selectedUser}
                    status={UserStatus.ACTIVATED}
                />
            )}

            {selectedUser && activeModal === 'deactivate' && (
                <StatusChangeDialog
                    isOpen={true}
                    onClose={handleCloseModal}
                    onConfirm={() => updateUserStatus({ user: selectedUser, status: UserStatus.DEACTIVATED })}
                    user={selectedUser}
                    status={UserStatus.DEACTIVATED}
                />
            )}

            {selectedUser && activeModal === 'set_as_admin' && (
                <RoleChangeDialog
                    isOpen={true}
                    onClose={handleCloseModal}
                    onConfirm={() => updateUserRole({ user: selectedUser, role: UserRole.ORGANIZATION_ADMIN })}
                    user={selectedUser}
                    role={UserRole.ORGANIZATION_ADMIN}
                />
            )}

            {selectedUser && activeModal === 'set_as_member' && (
                <RoleChangeDialog
                    isOpen={true}
                    onClose={handleCloseModal}
                    onConfirm={() => updateUserRole({ user: selectedUser, role: UserRole.ORGANIZATION_MEMBER })}
                    user={selectedUser}
                    role={UserRole.ORGANIZATION_MEMBER}
                />
            )}

            {selectedUser && activeModal === 'cancel_invitation' && (
                <CancelInvitationDialog
                    isOpen={true}
                    onClose={handleCloseModal}
                    onConfirm={() => cancelInvitation(selectedUser)}
                    user={selectedUser}
                />
            )}
        </div>
    );
};

interface UserActionsProps {
    userToManage: UserToManage;
    availableSeats: number;
    onStatusChange: (data: { user: UserToManage; status: UserStatus }) => void;
    onRoleChange: (data: { user: UserToManage; role: UserRole }) => void;
    onAddSeat: (data: { user: UserToManage; availableSeats: number }) => void;
    onInvitation: (data: { user: UserToManage; action: 'resend' | 'cancel' }) => void;
}

const UserActions: FC<UserActionsProps> = ({
    userToManage,
    availableSeats,
    onStatusChange,
    onRoleChange,
    onAddSeat,
    onInvitation,
}) => {
    const isUsersOwnUserRow = false; //currentLoggedInUser.email === userToManage.email;
    const actionExists = !isUsersOwnUserRow || !!userToManage.invitationId;

    if (!actionExists) return null;

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button variant="ghost" size="icon">
                    <MoreHorizontal className="h-4 w-4" />
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
                {!isUsersOwnUserRow &&
                    !userToManage.invitationId &&
                    userToManage.status === UserStatus.ACTIVATED &&
                    !userToManage.hasSeat && (
                        <DropdownMenuItem
                            onClick={() =>
                                onAddSeat({
                                    user: userToManage,
                                    availableSeats,
                                })
                            }
                        >
                            {availableSeats > 0 ? 'Add seat' : 'Upgrade to add seat'}
                        </DropdownMenuItem>
                    )}
                {!isUsersOwnUserRow && !userToManage.invitationId && (
                    <DropdownMenuItem
                        onClick={() =>
                            onStatusChange({
                                user: userToManage,
                                status:
                                    userToManage.status === UserStatus.ACTIVATED
                                        ? UserStatus.DEACTIVATED
                                        : UserStatus.ACTIVATED,
                            })
                        }
                    >
                        {userToManage.status === UserStatus.ACTIVATED ? 'Deactivate' : 'Activate'}
                    </DropdownMenuItem>
                )}
                {!isUsersOwnUserRow && (
                    <DropdownMenuItem
                        onClick={() => {
                            const newRole = userToManage.roles.includes(UserRole.ORGANIZATION_ADMIN)
                                ? UserRole.ORGANIZATION_MEMBER
                                : UserRole.ORGANIZATION_ADMIN;
                            onRoleChange({ user: userToManage, role: newRole });
                        }}
                    >
                        Change role to {userToManage.roles.includes(UserRole.ORGANIZATION_ADMIN) ? 'Member' : 'Admin'}
                    </DropdownMenuItem>
                )}
                {!!userToManage.invitationId && (
                    <>
                        <DropdownMenuItem onClick={() => onInvitation({ user: userToManage, action: 'cancel' })}>
                            Cancel invite
                        </DropdownMenuItem>
                        <DropdownMenuItem onClick={() => onInvitation({ user: userToManage, action: 'resend' })}>
                            Send invite again
                        </DropdownMenuItem>
                    </>
                )}
            </DropdownMenuContent>
        </DropdownMenu>
    );
};
