import { BriefcaseIcon, Building2, LinkedinIcon, MapPin } from 'lucide-react';
import { Skeleton } from '@shadcn/ui/components/ui/skeleton';
import { useMemo } from 'react';
import { useSdk } from '../../sdk';
import { useQuery } from '@tanstack/react-query';

export const ContactDetails = ({ linkedinUserId, prospectId }: { linkedinUserId: string; prospectId?: string }) => {
    const {
        linkedinProfiles: { getLinkedinProfileByMemberIdOrPublicIdentifier },
        prospect: { getProspectById },
    } = useSdk();
    const { data: linkedinProfile, isLoading: isFetchingCurrentLinkedinProfile } = useQuery(
        getLinkedinProfileByMemberIdOrPublicIdentifier(linkedinUserId)
    );
    const { data: prospect } = useQuery(getProspectById(prospectId));
    const companyName = prospect?.data?.organizationName;
    const title = prospect?.data?.occupationTitle;
    const location = linkedinProfile?.locationString || prospect?.data?.location;
    const numberOfConnections = linkedinProfile?.numberOfConnections
        ? linkedinProfile.numberOfConnections + ' connections'
        : '';

    const info = useMemo(
        () =>
            [
                {
                    icon: <Building2 className="w-4 h-4 text-muted-foreground flex-shrink-0 self-start" />,
                    label: companyName,
                    loading: !companyName && isFetchingCurrentLinkedinProfile,
                },
                {
                    icon: <BriefcaseIcon className="w-4 h-4 text-muted-foreground flex-shrink-0 self-start" />,
                    label: title,
                    loading: !title && isFetchingCurrentLinkedinProfile,
                },
                {
                    icon: <MapPin className="w-4 h-4 text-muted-foreground flex-shrink-0 self-start" />,
                    label: location,
                    loading: !location && isFetchingCurrentLinkedinProfile,
                },
                {
                    icon: <LinkedinIcon className="w-4 h-4 text-muted-foreground flex-shrink-0 self-start" />,
                    label: numberOfConnections,
                    loading: isFetchingCurrentLinkedinProfile,
                },
            ].filter((item) => item.label || item.loading),
        [companyName, title, location, numberOfConnections, isFetchingCurrentLinkedinProfile]
    );

    return (
        <div className="flex flex-col items-start gap-2 animate-in fade-in-60 duration-100">
            <p className="text-sm font-medium flex flex-row items-center gap-2">
                Contact details
                {isFetchingCurrentLinkedinProfile && (
                    <LinkedinIcon className="w-4 h-4 animate-pulse text-text-brand-primary-subtle" />
                )}
            </p>
            <div className="flex flex-col gap-2 text-muted-foreground">
                {info.map((item) => (
                    <div className="flex flex-row items-center gap-2">
                        {item.icon}
                        {item.loading ? (
                            <Skeleton className="h-4 w-56" />
                        ) : (
                            <p className="text-sm line-clamp-2">{item.label}</p>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};
