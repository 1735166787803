import { ReactNode } from 'react';
import { Button } from '@shadcn/ui/components/ui/button';
import { Link } from 'react-router-dom';
import { CheckCircle, XCircle, Loader2, Settings } from 'lucide-react';
import { cn } from '@shadcn/ui/lib/utils';
import { Card } from '@shadcn/ui/components/ui/card';
import { Badge } from '@shadcn/ui/components/ui/badge';

export type ConnectionStatus = 'connected' | 'not_connected' | 'pending' | 'error';

export interface BaseAppCardProps {
    title: string;
    showTitle?: boolean;
    description?: string;
    appId: string; // Used for routing to app settings
    bgColor: string; // Tailwind color class for the header bg
    icon: ReactNode;
    isConnected: boolean;
    isActive: boolean;
    connectionStatus?: ConnectionStatus;
    onConnect: () => void;
    isLoading: boolean;
    connectButtonText?: string;
    connectedText?: string;
    notConnectedText?: string;
    hideSettingsButton?: boolean;
}

export function BaseAppCard({
    title,
    showTitle = true,
    description,
    appId,
    bgColor,
    icon,
    isConnected,
    isActive,
    connectionStatus = isConnected ? 'connected' : 'not_connected',
    onConnect,
    isLoading,
    connectButtonText = 'Connect',
    connectedText = `Your ${title} account is connected.`,
    notConnectedText = `Connect your ${title} account to send messages.`,
    hideSettingsButton = false,
}: BaseAppCardProps) {
    const isPending = connectionStatus === 'pending';
    const isError = connectionStatus === 'error';

    // The action button only shows if not connected
    const showActionButton = !isConnected;
    // The settings button only shows if connected
    const showSettingsButton = (isActive || isConnected) && !hideSettingsButton;
    const realConnectedText = isActive
        ? connectedText
        : `Your ${title} account is connected, but requires configuration.`;
    return (
        <Card
            className={cn(
                'bg-white rounded-lg overflow-hidden border border-border-secondary',
                isConnected && 'border-background-brand-primary/60'
            )}
        >
            <div className={cn('p-4')}>
                <div className="flex items-center justify-between w-full">
                    {showTitle && (
                        <div className="flex items-center space-x-1">
                            <div className="h-10 w-10 rounded-full overflow-hidden flex justify-center items-center bg-white/20">
                                {icon}
                            </div>
                            <h3 className="text-lg font-medium">{title}</h3>
                        </div>
                    )}
                    {!showTitle && <div className="w-full flex justify-center items-center">{icon}</div>}

                    {isLoading || isPending ? (
                        <Loader2 className="h-6 w-6 animate-spin text-gray-400" />
                    ) : isConnected && isActive ? (
                        <Badge variant="success">Connected</Badge>
                    ) : isConnected && !isActive ? (
                        <Badge variant="warning">Configuration Required</Badge>
                    ) : isError ? (
                        <Badge variant="destructive">Error</Badge>
                    ) : (
                        <></>
                    )}
                </div>
            </div>

            <div className="p-6">
                {description && <p className="text-sm text-gray-500 mb-4">{description}</p>}

                <div className="mb-4">
                    <p className="text-gray-600">{isConnected ? realConnectedText : notConnectedText}</p>
                </div>

                <div className="flex space-x-2">
                    {showActionButton && (
                        <Button onClick={onConnect} disabled={isLoading || isPending} className="flex-1">
                            {isError ? `Retry to connect` : connectButtonText}
                            {(isLoading || isPending) && <Loader2 className="ml-2 h-4 w-4 animate-spin" />}
                        </Button>
                    )}

                    {showSettingsButton && (
                        <Button asChild variant="outline" className={showActionButton ? 'w-10 px-0' : 'flex-1'}>
                            <Link to={`./${appId}`}>
                                <Settings className="h-4 w-4 mr-2" />
                                {!showActionButton && 'Settings'}
                            </Link>
                        </Button>
                    )}
                </div>
            </div>
        </Card>
    );
}
