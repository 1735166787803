import React, { useEffect, useRef, useState } from 'react';
import { ChevronsDownUpIcon, ChevronsUpDownIcon } from 'lucide-react';
import { cn } from '@shadcn/ui/lib/utils';
import { AccountCardSpecialties } from './account-card-specialties';
import { useSuggestionsFeed } from '../../../hooks/use-suggestions-feed';
import { Skeleton } from '@shadcn/ui/components/ui/skeleton';
export const AccountCardDescription = ({ asSection = true }: { asSection?: boolean }) => {
    const { currentAccountData: accountData, isFetchingCurrentAccountData } = useSuggestionsFeed();
    const [isExpanded, setIsExpanded] = useState(false);
    const [isOverflowing, setIsOverflowing] = useState(false);
    const descriptionRef = useRef<HTMLParagraphElement>(null);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    useEffect(() => {
        if (descriptionRef.current) {
            setIsOverflowing(descriptionRef.current.scrollHeight > descriptionRef.current.clientHeight);
        }
    }, [accountData]);

    if (isFetchingCurrentAccountData) {
        return (
            <div className="flex flex-col items-start justify-start gap-2 w-full rounded-xl bg-background-tertiary p-4">
                <div className="flex flex-col gap-2 items-start overflow-hidden text-text-primary w-full">
                    <div className="flex flex-col gap-2 text-muted-foreground">
                        <span className="text-sm font-medium text-muted-foreground">Description</span>
                        <Skeleton className="h-4 w-64" />
                    </div>
                </div>
            </div>
        );
    }

    if (!accountData?.data?.aboutUs) return null;

    return (
        <div className="flex flex-col items-start justify-start gap-2 w-full text-muted-foreground">
            <div
                className={cn(
                    'flex flex-row gap-1 items-center justify-center overflow-hidden w-full text-muted-foreground',
                    !isExpanded && 'max-h-[6rem]',
                    asSection && 'p-4 bg-background-secondary'
                )}
            >
                <div className="flex flex-col gap-1 flex-1">
                    {asSection && <span className="text-sm font-medium text-muted-foreground">Description</span>}
                    <p
                        ref={descriptionRef}
                        className={cn('text-sm flex-1 self-start', { 'line-clamp-3': !isExpanded })}
                    >
                        {accountData?.data?.aboutUs}
                    </p>
                </div>
                {isOverflowing && !isExpanded && (
                    <ChevronsUpDownIcon
                        className="w-4 h-4 text-muted-foreground cursor-pointer"
                        onClick={toggleExpand}
                    />
                )}
                {isExpanded && (
                    <ChevronsDownUpIcon
                        className="w-4 h-4 text-muted-foreground cursor-pointer"
                        onClick={toggleExpand}
                    />
                )}
            </div>
            {!asSection && <AccountCardSpecialties />}
        </div>
    );
};
