import { FreshVersionProvider } from '../../providers/fresh-version-provider';
import { AuthorizationProvider } from '../../providers/user/authorization-provider';
import { ReduxInitialStateProvider } from '../../providers/redux-initial-state.provider';
import { useRef } from 'react';
import { configureStore } from '../../redux/store';
import SocketClient from '../../services/utils/sockets/socketClient';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from '../../theme';
import { StyledEngineProvider } from '@mui/material/styles';
import { PageViewTracker } from '../../tracking/PageViewTracker';
import { NotificationProvider } from '../../providers/notification.provider';
import { Provider } from 'react-redux-latest';
import { WebSocketProvider } from '../../providers/websocket-provider';
import { AnalyticsProvider } from '../../providers/user/analytics-provider';
import { UserImpersonationProvider } from '../../providers/user/user-impersonation-provider';
import { ProductTourProvider } from '../../providers/product-tour-provider';
import BillingListener from '../../providers/user/billing-listener';
import { SeatsAndCreditsProvider } from '../../providers/user/seats-and-credits-provider';
import { MeProvider } from '../../providers/user.provider';
import { SubscriptionsProvider } from '../../providers/subscriptions.provider';

export const LegacyAppProviders = ({ children }) => {
    // useAtomsDevtools('Andsend Jotai Devtools');
    // const { resetLocalFiltersWhenImpersonating } = useProspectSearch();
    const reduxFirebaseConfig = {
        useFirestoreForProfile: false,
        enableLogging: true,
    };
    const store = useRef(
        configureStore({
            socketClient: new SocketClient(),
        })
    );
    return (
        <Provider store={store.current}>
            <FreshVersionProvider>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                        {' '}
                        {/* @TODO This is the old authorization provider which does not have the same authContext */}
                        <AuthorizationProvider>
                            <ReduxInitialStateProvider>
                                <PageViewTracker>
                                    <NotificationProvider>
                                        <WebSocketProvider>
                                            <AnalyticsProvider>
                                                <UserImpersonationProvider>
                                                    <ProductTourProvider>
                                                        <BillingListener>
                                                            <SeatsAndCreditsProvider>
                                                                <SubscriptionsProvider>
                                                                    <div className="mui-scope">{children}</div>
                                                                </SubscriptionsProvider>
                                                            </SeatsAndCreditsProvider>
                                                            <MeProvider />
                                                        </BillingListener>
                                                    </ProductTourProvider>
                                                </UserImpersonationProvider>
                                            </AnalyticsProvider>
                                        </WebSocketProvider>
                                    </NotificationProvider>
                                </PageViewTracker>
                            </ReduxInitialStateProvider>
                        </AuthorizationProvider>
                    </ThemeProvider>
                </StyledEngineProvider>
            </FreshVersionProvider>
        </Provider>
    );
};
