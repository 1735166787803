import { Paper } from '@mui/material';
import { styled } from '@mui/styles';
import { oldThemeShadow } from '@zaplify/ui';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { ActionText } from '../../components/atoms/actionText';
import { Loader as LoaderAtom } from '../../components/atoms/loader';
import { paths } from '../../routes/paths';
import { palette } from '../../theme/palette';

export const ViewContainer = styled(({ children, className }: { children?: ReactNode; className: string }) => (
    <div className={className}>
        <div>{children}</div>
    </div>
))(({ theme }: { theme: any }) => ({
    display: 'flex',
    padding: '16px',
    width: 'calc(100% - 2 * 16px)',
    height: 'calc(100% - 2 * 16px)',
    [theme.breakpoints.up('sm')]: {
        padding: '32px',
        width: 'calc(100% - 2 * 32px)',
        height: 'calc(100% - 2 * 32px)',
    },
    '& > div': {
        margin: 'auto',
        textAlign: 'center',
        width: '100%',
    },
}));

export const TileContainer = styled(({ children, className }: { children?: ReactNode; className: string }) => (
    <Paper className={className}>{children}</Paper>
))(({ theme }: { theme: any }) => ({
    borderTop: `6px solid linear-gradient(261.84deg, #FD5E60 17.77%, #5F8EFA 80.67%)`,
    boxShadow: oldThemeShadow.g,
    margin: 'auto',
    backgroundColor: theme.palette.colors.white,
    position: 'relative',
    textAlign: 'center',
    padding: '32px',
    maxWidth: '100vw',
    '& p.verification-success': {
        color: theme.palette.colors.green,
    },
    '& p.verification-error': {
        color: theme.palette.error.main,
    },
}));

export const ColorTopContainer = styled(({ children, className }: { children?: ReactNode; className: string }) => (
    <Paper className={className}>{children}</Paper>
))(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    boxSizing: 'border-box',
    backgroundImage: 'linear-gradient(270deg, #FD5E60 17.71%, #4189F5 80.73%)',
    borderRadius: '14px',
    boxShadow: '0px 8px 18px rgba(112, 144, 176, 0.13)',
    margin: 'auto',
    backgroundColor: theme.palette.colors.white,
    paddingTop: 6,
    width: 475,
    maxWidth: '95%',
    height: 'auto',
}));

export const RegisterConfigureContainer = styled(
    ({ children, className }: { children?: ReactNode; className: string }) => (
        <Paper className={className}>{children}</Paper>
    )
)(({ theme }) => ({
    boxSizing: 'border-box',
    background: 'white',
    paddingTop: 6,
    borderRadius: '5px 5px 14px 14px',
    padding: '32px',
    boxShadow: '0px 8px 18px rgba(112, 144, 176, 0.13)',
    textAlign: 'center',
    '& .title': {
        color: theme.palette.colors.black,
        fontWeight: 500,
        fontSize: 24,
        margin: '24px 0 8px 0',
    },
    '& .info': {
        margin: '17px 0 19px',
        lineHeight: '22px',
        color: theme.palette.colors.greyDark,
        fontWeight: 400,
        fontSize: 15,
    },
    '& .submit': {
        '& .button ': {
            width: 396,
            height: 54,
            borderRadius: 100,
            fontWeight: 500,
            fontSize: 17,
            marginTop: 20,
        },
        '& .active': {
            background: theme.palette.colors.bluePale,
            boxShadow: '0px 2px 6px rgba(112, 144, 176, 0.07)',
            color: theme.palette.colors.white,
        },
        '& .disabled': {
            background: theme.palette.colors.greyInformationLight,
            color: theme.palette.colors.greyDark,
            pointerEvents: 'none',
        },
    },
}));

export const RegisterContainer = styled(({ children, className }: { children?: ReactNode; className: string }) => (
    <Paper className={className}>{children}</Paper>
))(({ theme }) => ({
    textAlign: 'center',
    width: 475,
    maxWidth: '95%',
    margin: 'auto',
    paddingTop: 6,
    boxSizing: 'border-box',
    backgroundImage: 'linear-gradient(270deg, #FD5E60 17.71%, #4189F5 80.73%)',
    borderRadius: '14px',
    boxShadow: '0px 8px 18px rgba(112, 144, 176, 0.13)',
    '& .action-container': {
        padding: '0 40px 25px 40px',
        borderRadius: '5px 5px 14px 14px',
        background: theme.palette.colors.white,
        '& .title': {
            padding: '36px 0 24px 0',
            fontWeight: 500,
            fontSize: 24,
        },
        '& .description': {
            padding: '36px 0 24px 0',
            fontWeight: 400,
            fontSize: 15,
        },
    },
    '& p.verification-success': {
        color: theme.palette.colors.green,
    },
    '& p.verification-error': {
        color: theme.palette.error.main,
    },
    '& .error': {
        padding: 14,
        gap: 10,
        borderRadius: 8,
        background: '#FAE6E6',
        fontSize: 14,
        lineHeight: '21px',
    },
}));

export const LoginContainer = styled(({ children, className }: { children?: ReactNode; className: string }) => (
    <Paper className={className}>{children}</Paper>
))(({ theme }) => ({
    textAlign: 'center',
    width: 475,
    maxWidth: '95%',
    margin: 'auto',
    paddingTop: 6,
    boxSizing: 'border-box',
    backgroundImage: 'linear-gradient(270deg, #FD5E60 17.71%, #4189F5 80.73%)',
    borderRadius: '14px',
    boxShadow: '0px 8px 18px rgba(112, 144, 176, 0.13)',
    '& .action-container': {
        padding: '0 40px 25px 40px',
        borderRadius: '5px 5px 14px 14px',
        background: theme.palette.colors.white,
        '& .title': {
            padding: '36px 0 24px 0',
            fontWeight: 500,
            fontSize: 24,
        },
        '& .back': {
            marginTop: '19px',
            color: palette.darkGrey,

            '& .text': {
                color: palette.darkGrey,
            },
            '& a': { textDecoration: 'none' },
            '& * > .link': {
                color: palette.darkBlue,
            },
        },
    },
}));

export const FieldsContainer = styled('div')(({ theme }) => ({
    '& .tip-container': {
        display: 'flex',
        margin: 0,
        fontSize: 13,
        '& .text': {
            color: theme.palette.colors.greyDark,
        },
        '& .dot': {
            paddingRight: 8,
            color: theme.palette.colors.greyLighter,
        },
        '& .forgot': {
            textAlign: 'left',
        },
    },
    '& .privacy': {
        fontSize: 13,
        color: '#6B7985',
        textAlign: 'left',
        '& .link': {
            cursor: 'pointer',
            fontSize: 13,
            color: theme.palette.colors.greyDark,
            textDecoration: 'underline',
        },
    },
    '& .submit': {
        '& .button ': {
            width: 396,
            maxWidth: '100%',
            height: 54,
            borderRadius: 100,
            fontWeight: 500,
            fontSize: 17,
            marginTop: 22,
        },
        '& .active': {
            background: theme.palette.colors.bluePale,
            boxShadow: '0px 2px 6px rgba(112, 144, 176, 0.07)',
            color: theme.palette.colors.white,
        },
        '& .disabled': {
            background: theme.palette.colors.greyInformationLight,
            color: theme.palette.colors.greyDark,
            pointerEvents: 'none',
        },
        '& .sign-in': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            color: theme.palette.colors.bluePale,
            fontWeight: 500,
            fontSize: 15,
            textDecoration: 'none',
            '& .icon': {
                fontSize: 15,
                marginTop: 11,
                marginLeft: 12,
            },
            '& > p': {
                margin: '11px 0 0 0',
            },
        },
    },
    '& div.submit': {
        '& .error': {
            minHeight: '10px',
            color: theme.palette.error.main,
            marginBottom: 0,
        },
    },
    '& .google-sign-up-container': {
        '& > p': {
            margin: 10,
            color: theme.palette.lightGrey,
            fontWeight: 500,
        },
    },
    '& .register-text': {
        marginTop: 15,
        '& > a': {
            textDecoration: 'none',
            color: theme.palette.primary.light,
        },
    },
}));

export const TextFieldsHalfWidthContainer = styled('div')(({ theme }: { theme: any }) => ({
    '& > div': {
        textAlign: 'left',
        width: '100%',
        margin: '0',
        '&:first-child': {
            marginBottom: theme.spacing(2),
        },
        [theme.breakpoints.up('sm')]: {
            width: 'calc(50% - 4px)',
            marginLeft: '4px',
            '&:first-child': {
                margin: '0 4px 0 0',
            },
        },
    },
}));

export const SubscriptionContainer = styled('div')(({ theme }) => ({
    textAlign: 'left',
    width: '100%',
    marginTop: 3,
    '& .text': {
        fontSize: 14,
        color: theme.palette.colors.greyDark,
    },
    '& .MuiIconButton-label': {
        color: theme.palette.colors.greyLighter,
    },
}));

export const UnderTileContainer = styled('div')(({ theme }: { theme: any }) => ({
    margin: 'auto',
    boxShadow: oldThemeShadow.g,
    background: theme.palette.colors.white,
    borderRadius: '4px',
    marginTop: theme.spacing(3),
    width: '416px',
    textAlign: 'center',
    padding: '0 32px 0 32px',
    '& > *': {
        display: 'inline-block',
        margin: theme.spacing(1),
    },
}));

export const Loader = styled(({ className }) => (
    <div className={className}>
        <LoaderAtom />
    </div>
))(({ theme }) => ({
    top: '8px',
    left: '8px',
    zIndex: 1000,
    position: 'absolute',
    width: 'calc(100% - 16px)',
    height: 'calc(100% - 16px)',
    backgroundColor: theme.palette.colors.overlay.bright(95),
}));

export const ConfirmatoryQuestionsContainer = styled('div')(({ theme }: { theme: any }) => ({
    marginTop: theme.spacing(5),
    '& > *': {
        marginTop: theme.spacing(1),
    },
}));

export const ConfirmationContainer = styled('div')(({ theme }) => ({
    boxSizing: 'border-box',
    height: 400,
    paddingTop: 60,
    borderRadius: '5px 5px 14px 14px',
    background: theme.palette.colors.white,
    '& .title': {
        marginBottom: 11,
        fontSize: 24,
        fontWeight: 500,
        color: theme.palette.colors.black,
    },
    '& .info': {
        marginBottom: 24,
        fontSize: 14,
        color: theme.palette.colors.greyDark,
    },
    '& .email-container': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        margin: 'auto',
        width: 360,
        height: 47,
        border: `1px solid ${theme.palette.colors.greyInformationLight}`,
        borderRadius: 4,
        background: theme.palette.colors.whiteDark,
        '& .label': {
            margin: '4px 0 4px 13px',
            fontSize: 11,
            color: theme.palette.colors.lightGrey,
        },
        '& .email': {
            margin: '0 0 0 13px',
            fontSize: 15,
            color: theme.palette.colors.greyDark,
        },
    },
    '& .information-container': {
        height: 'auto',
        marginTop: 22,
        fontSize: 14,
        fontWeight: 400,
        color: theme.palette.colors.greyDark,
        lineHeight: '21px',
        cursor: 'pointer',
        '& .link': {
            color: theme.palette.colors.blueLighter,
        },
        '& .lower': {
            marginTop: 18,
        },
    },
    '& .buttons_container': {
        margin: '22px 0',
        '& .button': {
            width: 165,
            height: 40,
            border: '1px solid #E4E6EB',
            borderRadius: 8,
            margin: '0 5px',
            '& .icon-info': {
                color: theme.palette.colors.black,
                fontSize: 14,
                margin: '0px 0px 0px 10px',
            },
        },
    },
}));

export const TitleContainer = styled('div')(({ theme }) => ({
    '& > *': {
        marginBottom: '20px',
    },
}));

export const ForgotPasswordLink = styled(({ className }) => {
    const navigate = useNavigate();

    return (
        <div
            className={className}
            style={{ marginTop: '9px', textAlign: 'right', fontSize: 14, fontWeight: 500, color: palette.darkBlue }}
        >
            <ActionText
                text="Forgot password?"
                dataCy="forgot-password-link"
                onClick={() => navigate(paths.OLD_RESET_PASSWORD)}
            />
        </div>
    );
})(() => ({
    textAlign: 'center',
}));

export const LinkedInAuthenticationContainer = styled('div')(({ theme }: { theme: any }) => ({
    position: 'absolute',
    margin: 'auto',
    left: '0',
    top: '30vh',
    right: '0',
    boxShadow: oldThemeShadow.g,
    background: theme.palette.colors.white,
    borderRadius: '4px',
    width: '416px',
    textAlign: 'center',
    padding: '32px 32px',
    '& > *': {
        margin: theme.spacing(1),
    },
}));
