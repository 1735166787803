import { IEvent, Topics } from '@zaplify/common';
import { IUserData } from './user.dto';
import { UserRole } from './constants';

export class UserAccountUpdatedEvent extends IEvent {
    public static override readonly TOPIC = Topics.USERS;

    get partitionKey(): string {
        return this.user.userOrganizationId;
    }
    constructor(readonly user: IUserData) {
        super();
    }
}

export class UserRolesUpdatedEvent extends IEvent {
    public static override readonly TOPIC = Topics.USERS;

    get partitionKey(): string {
        return this.userId;
    }
    constructor(
        readonly userId: string,
        readonly roles: UserRole[],
        readonly grantedRoles: UserRole[],
        readonly revokedRoles: UserRole[],
    ) {
        super();
    }
}

export class UserChannelAccountsUpdatedEvent extends IEvent {
    public static override readonly TOPIC = Topics.USERS;

    get partitionKey(): string {
        return this.userId;
    }
    constructor(
        readonly userId: string,
        readonly channelAccountIds: string[],
        readonly assignedChannelAccountIds: string[],
        readonly unassignedChannelAccountIds: string[],
    ) {
        super();
    }
}
