import { styled } from '@mui/styles';

const CategoryExpandShrinkStyled = styled('div')(({ theme: { palette, spacing } }: any) => ({
    '& .show-more, & .show-less': {
        alignItems: 'center',
        maxHeight: 33,
        minHeight: 33,
        borderRadius: '8px',
        fontSize: 14,
        color: palette.primary.light,
        display: 'flex',
        padding: 'var(--S-s-regular, 8px)',
        justifyContent: 'center',
        gap: 'var(--S-xs, 6px)',
        alignSelf: 'stretch',
        width: '100%',

        '&:hover': {
            backgroundColor: palette.background.default,
        },
    },
    '& .icon': {
        stroke: palette.primary.light,
        strokeWidth: 1,
    },
}));

export default CategoryExpandShrinkStyled;
