import { IEvent, Topics } from '@zaplify/common';

export class OutreachSuggestionRemovedEvent extends IEvent {
    public static override readonly TOPIC: Topics = Topics.CAMPAIGNS;
    public readonly userId: string;
    public readonly organizationId: string;
    public readonly outreachSuggestionId: string;
    public readonly prospectId: string;

    constructor(params: { userId: string; organizationId: string; outreachSuggestionId: string; prospectId: string }) {
        super();
        this.userId = params.userId;
        this.organizationId = params.organizationId;
        this.outreachSuggestionId = params.outreachSuggestionId;
        this.prospectId = params.prospectId;
    }

    get partitionKey(): string {
        return this.prospectId;
    }
}
