import { ICommand, Topics } from '@zaplify/common';

export class GenerateMessageCommand extends ICommand<void> {
    public static override readonly TOPIC = Topics.CAMPAIGNS;

    constructor(
        public readonly prospectId: string,
        public readonly userId: string,
        public readonly scheduleNow?: boolean,
    ) {
        super();
    }
}
