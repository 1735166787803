import { ContactCardRecommendationReason } from './recommendation-reason';
import { ContactCardActionRow } from './contact-card-action-row';
import { ContactCardHeader } from './contact-card-header';
import { ContactRecommendationMotivation } from './contact-recommendation-motivation';
import { ContactWorkExperiences } from './contact-work-experiences';
import { ContactDetails } from './contact-details';
import { ContactDescription } from './contact-description';

export const SplitCardContactSection = () => {
    return (
        <div className="flex flex-col w-full h-full p-4 gap-4 md:overflow-y-auto bg-background-secondary rounded-r-[inherit]">
            <div className="flex items-start gap-2 flex-wrap justify-between">
                <ContactCardHeader />
                <ContactCardRecommendationReason />
            </div>
            <ContactDetails />
            <ContactRecommendationMotivation />
            <ContactWorkExperiences />
            <ContactDescription />
            <div className="h-full" />
            <ContactCardActionRow />
        </div>
    );
};
