import { IPlan, IPlanPackage } from '@zaplify/subscriptions';
import { getZaplifySdk } from '@zaplify/sdk';

export function getCheckoutDTO(selectedPackage: IPlanPackage, selectedPlan: IPlan) {
    if (!selectedPackage?.seats) return;

    return {
        numberOfSeats: selectedPackage.seats,
        billingPeriod: selectedPlan.billingPeriod,
        creditNumber: selectedPackage.credits,
        planCode: selectedPlan.code,
    };
}

export async function getAvailablePlans(): Promise<{ plans: IPlan[] }> {
    const profilesSdk = getZaplifySdk().profiles;
    const response = await profilesSdk.billing.getAvailablePlans();

    if (!response) {
        return { plans: [] };
    }

    return response;
}

export const calculateAmountOfUsersExceedsSelectedPlan = (
    selectedPlan: IPlanPackage,
    occupiedSeatsCount: number
): number => {
    const newAmountOfSeats = selectedPlan?.seats;

    return occupiedSeatsCount - newAmountOfSeats;
};
