import { createMutationOptions } from './sdk-utils';
import { getZaplifySdk } from '@zaplify/sdk';
import { ConfirmPasswordResetDto, EmailDto, ActionCodeDto } from '@zaplify/users/shared';

export const resetPassword = () => {
    return createMutationOptions<EmailDto, Error, string>({
        mutationFn: (email) => getZaplifySdk().profiles.authentication.resetPassword(email),
    });
};

export const confirmPasswordReset = () => {
    return createMutationOptions<ConfirmPasswordResetDto, Error, ConfirmPasswordResetDto>({
        mutationFn: (confirmPasswordReset) =>
            getZaplifySdk().profiles.authentication.confirmPasswordReset(confirmPasswordReset),
    });
};

export const sendEmailVerification = () => {
    return createMutationOptions<EmailDto, Error, EmailDto>({
        mutationFn: (emailDto) => getZaplifySdk().profiles.authentication.sendEmailVerification(emailDto),
    });
};

export const setEmailVerified = () => {
    return createMutationOptions<ActionCodeDto, Error, ActionCodeDto>({
        mutationFn: (actionCode) => getZaplifySdk().profiles.authentication.setEmailVerified(actionCode),
    });
};

export const chooseOrganization = () => {
    return createMutationOptions<ActionCodeDto, Error, { organizationId?: string; authAsUser?: string }>({
        mutationFn: (variables) =>
            getZaplifySdk().profiles.authentication.chooseOrganization(variables.organizationId, variables.authAsUser),
        invalidateQueries: () => {
            // Invalidate user-related queries when organization changes
            return [['user-accounts'], ['user-credits-and-seat-summary'], ['channel-accounts']];
        },
    });
};
