import { FC, useEffect, useMemo } from 'react';
import { Badge } from '@shadcn/ui/components/ui/badge';
import { cn } from '@shadcn/ui/lib/utils';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@shadcn/ui/components/ui/tooltip';
import { ContactSuggestionReason } from '@zaplify/campaigns';
import { useSuggestionsFeed } from '../hooks/use-suggestions-feed';

export const NetworkDistanceFilter: FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();
    const { suggestionsMatchingSelectedPlaybook: contactSuggestions } = useSuggestionsFeed();

    const numberOfSuggestionsInsideNetwork = useMemo(() => {
        return contactSuggestions.filter(
            (suggestion) => suggestion.reason !== ContactSuggestionReason.SIMILAR_TO_OTHER_ACCOUNTS.toString()
        ).length;
    }, [contactSuggestions]);
    const hasSuggestionsInsideNetwork = useMemo(() => {
        return numberOfSuggestionsInsideNetwork > 0;
    }, [numberOfSuggestionsInsideNetwork]);

    const numberOfSuggestionsOutsideNetwork = useMemo(() => {
        return contactSuggestions.filter(
            (suggestion) => suggestion.reason === ContactSuggestionReason.SIMILAR_TO_OTHER_ACCOUNTS.toString()
        ).length;
    }, [contactSuggestions]);
    const hasSuggestionsOutsideNetwork = useMemo(() => {
        return numberOfSuggestionsOutsideNetwork > 0;
    }, [numberOfSuggestionsOutsideNetwork]);

    useEffect(() => {
        if (!location.search.includes('insideNetwork')) {
            setSearchParams((prevParams) => {
                const newParams = new URLSearchParams(prevParams);
                if (hasSuggestionsInsideNetwork) {
                    newParams.set('insideNetwork', 'true');
                } else if (hasSuggestionsOutsideNetwork) {
                    newParams.set('insideNetwork', 'false');
                }
                return newParams;
            });
        }
    }, [hasSuggestionsInsideNetwork, hasSuggestionsOutsideNetwork, setSearchParams, location.pathname]);

    // Add this check to force 'false' when there are no inside network suggestions
    useEffect(() => {
        if (!hasSuggestionsInsideNetwork && hasSuggestionsOutsideNetwork) {
            setSearchParams((prevParams) => {
                const newParams = new URLSearchParams(prevParams);
                newParams.set('insideNetwork', 'false');
                return newParams;
            });
        }
        if (!hasSuggestionsOutsideNetwork && hasSuggestionsInsideNetwork) {
            setSearchParams((prevParams) => {
                const newParams = new URLSearchParams(prevParams);
                newParams.set('insideNetwork', 'true');
                return newParams;
            });
        }
    }, [hasSuggestionsInsideNetwork, hasSuggestionsOutsideNetwork, setSearchParams]);

    if (!hasSuggestionsInsideNetwork && !hasSuggestionsOutsideNetwork) return null;

    return (
        <div className="flex gap-2">
            <TooltipProvider>
                <Tooltip>
                    <TooltipTrigger>
                        <NetworkDistanceFilterBadge
                            label="Inside network"
                            numberOfSuggestions={numberOfSuggestionsInsideNetwork}
                            selected={searchParams.get('insideNetwork') !== 'false'}
                            onClick={() => {
                                setSearchParams((prevParams) => {
                                    prevParams.set('insideNetwork', 'true');
                                    return prevParams;
                                });
                            }}
                        />
                    </TooltipTrigger>
                    <TooltipContent side="bottom">Contacts who are already in your Linkedin network</TooltipContent>
                </Tooltip>
                <Tooltip>
                    <TooltipTrigger>
                        <NetworkDistanceFilterBadge
                            label="Outside network"
                            numberOfSuggestions={numberOfSuggestionsOutsideNetwork}
                            selected={searchParams.get('insideNetwork') === 'false'}
                            onClick={() => {
                                setSearchParams((prevParams) => {
                                    prevParams.set('insideNetwork', 'false');
                                    return prevParams;
                                });
                            }}
                        />
                    </TooltipTrigger>
                    <TooltipContent side="bottom">
                        Contacts who are not yet in your Linkedin network but may be relevant
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>
        </div>
    );
};

const NetworkDistanceFilterBadge: FC<{
    label: string;
    selected: boolean;
    numberOfSuggestions: number;
    onClick: () => void;
    className?: string;
}> = ({ label, selected, numberOfSuggestions, onClick, className }) => {
    return (
        <Badge
            variant="outline"
            className={cn(
                'flex h-9 text-sm items-center gap-2 bg-brand-primary cursor-pointer',
                selected && 'bg-background-brand-primary text-text-primary-on-brand',
                className
            )}
            onClick={onClick}
        >
            <span className="max-w-40 overflow-hidden text-ellipsis break-all whitespace-nowrap">{label}</span>
            <Badge
                variant="outline"
                className={cn(
                    'bg-background-primary',
                    selected && 'bg-background-brand-primary text-text-primary-on-brand'
                )}
            >
                {numberOfSuggestions}
            </Badge>
        </Badge>
    );
};
