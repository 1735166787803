import { FC, useState, useEffect, forwardRef, useImperativeHandle, useMemo } from 'react';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@shadcn/ui/components/ui/select';
import { Button } from '@shadcn/ui/components/ui/button';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@shadcn/ui/components/ui/table';
import { toast } from '@shadcn/ui/hooks/use-toast';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { CreateProspectDataDto, IPhoneNumber } from '@zaplify/prospects';
import { validateProspect, getRequiredFields, getFieldMetadata, getAllFieldIds } from '../validation';

interface FieldMappingStepProps {
    csvData: any[];
    headers: string[];
    onFieldMappingComplete: (mapping: Record<string, string>, mappedProspects: CreateProspectDataDto[]) => void;
    onMappingChange?: (mapping: Record<string, string>) => void;
    initialMapping?: Record<string, string>;
}

export interface FieldMappingStepRef {
    handleApplyMapping: () => {
        success: boolean;
        validProspects: CreateProspectDataDto[];
        invalidCount: number;
        validationErrors?: { fieldId: string; message: string }[];
    };
}

// Generate system fields directly from the Zod schema
export const SYSTEM_FIELDS = (() => {
    const metadata = getFieldMetadata();
    return getAllFieldIds().map((id) => ({
        id,
        label: metadata[id]?.label || id,
        required: metadata[id]?.required || false,
    }));
})();

// Field mapping configuration with pattern types and priorities
const FIELD_MAPPING_PATTERNS = {
    firstName: {
        exact: ['first name', 'firstname', 'fname'],
        contains: ['given name', 'first'],
        priority: 2,
        excludeIfContains: ['last', 'middle'],
    },
    lastName: {
        exact: ['last name', 'lastname', 'lname'],
        contains: ['surname', 'family name', 'last'],
        priority: 2,
        excludeIfContains: ['first', 'middle'],
    },
    fullName: {
        exact: ['full name', 'fullname', 'complete name'],
        contains: ['name'],
        priority: 1,
        excludeIfContains: ['first', 'last', 'company', 'organization'],
    },
    email: {
        exact: ['email', 'email address', 'e-mail'],
        contains: ['mail'],
        priority: 3,
        excludeIfContains: ['alternate', 'other', 'secondary'],
    },
    phoneNumbers: {
        exact: ['phone number', 'telephone number', 'mobile number', 'phone'],
        contains: ['phone', 'mobile', 'cell', 'tel'],
        priority: 2,
        excludeIfContains: ['fax'],
    },
    linkedinProfileUrl: {
        exact: ['linkedin profile url', 'linkedin url', 'linkedin profile link'],
        contains: ['linkedin'],
        priority: 3,
        excludeIfContains: ['company', 'organization'],
    },
    occupationTitle: {
        exact: ['job title', 'position title', 'occupation title'],
        contains: ['title', 'position', 'role', 'occupation'],
        priority: 2,
        excludeIfContains: ['company', 'organization'],
    },
    occupationRole: {
        exact: ['job function', 'job category', 'role category'],
        contains: ['function', 'category'],
        priority: 1,
        excludeIfContains: ['company', 'organization'],
    },
    organizationName: {
        exact: ['company name', 'organization name', 'employer name', 'business name'],
        contains: ['company', 'organization', 'employer', 'business'],
        priority: 3,
        excludeIfContains: ['size', 'type', 'url', 'website'],
    },
    organizationDomain: {
        exact: ['company website', 'company url', 'company domain', 'organization website'],
        contains: ['website', 'domain', 'url'],
        priority: 3,
        excludeIfContains: ['linkedin', 'social', 'personal'],
    },
    organizationIndustry: {
        exact: ['company industry', 'organization industry', 'business industry'],
        contains: ['industry'],
        priority: 2,
        excludeIfContains: ['experience'],
    },
    organizationSize: {
        exact: ['company size', 'organization size', 'employee count'],
        contains: ['size', 'employees', 'headcount'],
        priority: 2,
        excludeIfContains: ['team', 'department'],
    },
    location: {
        exact: ['location', 'address', 'place'],
        contains: ['region'],
        priority: 1,
        excludeIfContains: ['city', 'country', 'state'],
    },
    locationCity: {
        exact: ['city', 'town', 'municipality'],
        contains: [],
        priority: 2,
        excludeIfContains: ['country', 'state'],
    },
    locationCountry: {
        exact: ['country', 'nation'],
        contains: [],
        priority: 2,
        excludeIfContains: ['city', 'state'],
    },
    industry: {
        exact: ['industry', 'sector', 'field'],
        contains: ['business type', 'domain'],
        priority: 2,
        excludeIfContains: ['company', 'experience'],
    },
};

// Create the component with forwardRef
export const FieldMappingStep = forwardRef<FieldMappingStepRef, FieldMappingStepProps>((props, ref) => {
    const { csvData, headers, onFieldMappingComplete, onMappingChange, initialMapping } = props;
    console.log('FieldMappingStep mounted', { csvData, headers });

    // Use the current mapping from parent component if available via onMappingChange
    const [fieldMapping, setFieldMapping] = useState<Record<string, string>>(initialMapping || {});
    const [autoMappingDone, setAutoMappingDone] = useState(false);
    const [currentContactIndex, setCurrentContactIndex] = useState(0);

    // Track if this is the first render to avoid overriding parent mappings
    const [isFirstRender, setIsFirstRender] = useState(true);

    // Initialize mapping from parent's fieldMapping if it exists via a ref passed back
    useEffect(() => {
        if (isFirstRender && onMappingChange) {
            // Request current mappings from parent
            const currentParentMappings = fieldMapping;
            if (Object.keys(currentParentMappings).length > 0) {
                setFieldMapping(currentParentMappings);
                setAutoMappingDone(true);
            }
            setIsFirstRender(false);
        }
    }, [isFirstRender, fieldMapping, onMappingChange]);

    // Enhanced automatic field mapping
    useEffect(() => {
        if (headers.length > 0 && !autoMappingDone) {
            const initialMap = { ...fieldMapping };
            const mappedHeaders = new Set<string>();

            // Sort fields by priority (highest first)
            const fieldsByPriority = Object.entries(FIELD_MAPPING_PATTERNS).sort(
                ([, a], [, b]) => b.priority - a.priority
            );

            // First pass: Try exact matches
            for (const [fieldId, patterns] of fieldsByPriority) {
                for (const header of headers) {
                    if (mappedHeaders.has(header)) continue;

                    const headerLower = header.toLowerCase().trim();

                    // Skip if header contains any excluded terms
                    if (patterns.excludeIfContains?.some((term) => headerLower.includes(term))) {
                        continue;
                    }

                    // Check exact matches first
                    if (patterns.exact.includes(headerLower)) {
                        initialMap[fieldId] = header;
                        mappedHeaders.add(header);
                        break;
                    }
                }
            }

            // Second pass: Try contains matches
            for (const [fieldId, patterns] of fieldsByPriority) {
                if (initialMap[fieldId]) continue; // Skip if already mapped

                for (const header of headers) {
                    if (mappedHeaders.has(header)) continue;

                    const headerLower = header.toLowerCase().trim();

                    // Skip if header contains any excluded terms
                    if (patterns.excludeIfContains?.some((term) => headerLower.includes(term))) {
                        continue;
                    }

                    // Check contains matches
                    if (patterns.contains.some((pattern) => headerLower.includes(pattern))) {
                        initialMap[fieldId] = header;
                        mappedHeaders.add(header);
                        break;
                    }
                }
            }

            setFieldMapping(initialMap);
            setAutoMappingDone(true);

            if (onMappingChange) {
                onMappingChange(initialMap);
            }

            if (Object.keys(initialMap).length > 0) {
                toast({
                    title: 'Automatic field mapping applied',
                    description: `Successfully mapped ${Object.keys(initialMap).length} fields automatically`,
                    variant: 'success',
                });
            }
        }
    }, [headers, onMappingChange, fieldMapping, autoMappingDone]);

    const handleFieldMap = (systemFieldId: string, csvHeaderValue: string) => {
        // If this CSV header is already mapped to another field, remove that mapping
        const existingMappings = { ...fieldMapping };
        Object.keys(existingMappings).forEach((key) => {
            if (existingMappings[key] === csvHeaderValue && key !== systemFieldId) {
                delete existingMappings[key];
            }
        });

        // Set the new mapping
        let newMapping: Record<string, string>;
        if (csvHeaderValue && csvHeaderValue !== '__NOT_MAPPED__') {
            newMapping = {
                ...existingMappings,
                [systemFieldId]: csvHeaderValue,
            };
        } else {
            newMapping = { ...existingMappings };
            delete newMapping[systemFieldId];
        }

        setFieldMapping(newMapping);

        // Notify parent of mapping change
        if (onMappingChange) {
            onMappingChange(newMapping);
        }
    };

    // Add phone number transformation helper
    const transformPhoneNumber = (value: string | null | undefined): IPhoneNumber[] => {
        if (!value) return [];
        return [{ number: value, type: 'mobile' }];
    };

    const handleApplyMapping = (): {
        success: boolean;
        validProspects: CreateProspectDataDto[];
        invalidCount: number;
        validationErrors?: { fieldId: string; message: string }[];
    } => {
        // Validate required fields
        const requiredFields = getRequiredFields();
        const missingRequired = requiredFields.filter((field) => !fieldMapping[field]);

        if (missingRequired.length > 0) {
            const metadata = getFieldMetadata();
            const missingLabels = missingRequired.map((id) => metadata[id]?.label || id).join(', ');

            toast({
                title: 'Required fields missing',
                description: `Please map the following required fields: ${missingLabels}`,
                variant: 'destructive',
            });
            return {
                success: false,
                validProspects: [],
                invalidCount: 0,
            };
        }

        // Map CSV data directly to CreateProspectDataDto format
        const mappedProspects = csvData.map((row) => {
            // Initialize with required fields set to empty strings
            const prospect: CreateProspectDataDto = {
                firstName: '',
                lastName: '',
                fullName: '',
                organizationName: '',
            };

            // Map each field from the CSV to the corresponding field in CreateProspectDataDto
            Object.entries(fieldMapping).forEach(([systemField, csvHeader]) => {
                if (csvHeader && csvHeader !== '__NOT_MAPPED__') {
                    if (systemField === 'phoneNumbers') {
                        prospect[systemField] = transformPhoneNumber(row[csvHeader]);
                    } else {
                        prospect[systemField] = row[csvHeader] || '';
                    }
                }
            });

            // Generate fullName if missing but first and last name exist
            if (!prospect.fullName && prospect.firstName && prospect.lastName) {
                prospect.fullName = `${prospect.firstName} ${prospect.lastName}`;
            }

            return prospect;
        });

        // Filter out any empty prospects (no required fields)
        const validProspects = mappedProspects.filter((prospect) => {
            return requiredFields.every((field) => !!prospect[field]);
        });

        // Validate each prospect
        const invalidProspects = validProspects
            .map((prospect, index) => ({ prospect, index, errors: validateProspect(prospect) }))
            .filter(({ errors }) => errors.length > 0);

        onFieldMappingComplete(fieldMapping, validProspects);
        return {
            success: true,
            validProspects: validProspects,
            invalidCount: invalidProspects.length,
            validationErrors: invalidProspects.flatMap(({ errors }) =>
                errors.map((error) => ({
                    fieldId: error.path?.join('.') || '',
                    message: typeof error.message === 'string' ? error.message : JSON.stringify(error.message),
                }))
            ),
        };
    };

    // Expose handleApplyMapping function to parent
    useImperativeHandle(ref, () => ({
        handleApplyMapping,
    }));

    const handleClearMapping = () => {
        setFieldMapping({});

        // Notify parent of mapping change
        if (onMappingChange) {
            onMappingChange({});
        }
    };

    // Check if we have sample data to show
    const hasSampleData = csvData.length > 0;
    const sampleRow = hasSampleData ? csvData[currentContactIndex] : {};

    const handlePreviousContact = () => {
        setCurrentContactIndex((prev) => (prev > 0 ? prev - 1 : prev));
    };

    const handleNextContact = () => {
        setCurrentContactIndex((prev) => (prev < csvData.length - 1 ? prev + 1 : prev));
    };

    return (
        <div className="space-y-6 w-full">
            <div className="flex justify-between items-center flex-wrap gap-2">
                <h3 className="text-lg font-medium">Map CSV Headers to Contact Fields</h3>
                <div className="space-x-2">
                    <Button
                        variant="outline"
                        size="sm"
                        onClick={() => {
                            setAutoMappingDone(false);
                            handleClearMapping();
                        }}
                    >
                        Clear All
                    </Button>
                    <Button
                        variant="outline"
                        size="sm"
                        onClick={() => {
                            setAutoMappingDone(false);
                        }}
                    >
                        Auto Map
                    </Button>
                </div>
            </div>

            <div className="border rounded-lg overflow-hidden">
                <div className="overflow-x-auto">
                    <div>
                        <Table>
                            <TableHeader className="bg-muted">
                                <TableRow>
                                    <TableHead className="w-[25%]">System Field</TableHead>
                                    <TableHead className="w-[30%]">CSV Header</TableHead>
                                    <TableHead className="w-[45%]">
                                        <div className="flex items-center justify-between">
                                            <span>Sample Data</span>
                                            {hasSampleData && (
                                                <div className="flex items-center gap-2">
                                                    <Button
                                                        variant="ghost"
                                                        size="icon"
                                                        className="h-6 w-6"
                                                        onClick={handlePreviousContact}
                                                        disabled={currentContactIndex === 0}
                                                    >
                                                        <ChevronLeft className="h-4 w-4" />
                                                    </Button>
                                                    <span className="text-xs text-muted-foreground min-w-[80px] text-center">
                                                        Contact {currentContactIndex + 1} of {csvData.length}
                                                    </span>
                                                    <Button
                                                        variant="ghost"
                                                        size="icon"
                                                        className="h-6 w-6"
                                                        onClick={handleNextContact}
                                                        disabled={currentContactIndex === csvData.length - 1}
                                                    >
                                                        <ChevronRight className="h-4 w-4" />
                                                    </Button>
                                                </div>
                                            )}
                                        </div>
                                    </TableHead>
                                </TableRow>
                            </TableHeader>
                        </Table>
                    </div>
                    <div className="overflow-y-auto" style={{ maxHeight: 'calc(100vh - 480px)' }}>
                        <Table>
                            <TableBody>
                                {SYSTEM_FIELDS.map((field) => (
                                    <TableRow key={field.id} className="border-t">
                                        <TableCell className="px-4 py-3 bg-white sticky left-0 w-[25%] font-medium">
                                            {field.label}{' '}
                                            {field.required && <span className="text-destructive">*</span>}
                                        </TableCell>
                                        <TableCell className="px-4 py-3 w-[30%]">
                                            <Select
                                                value={fieldMapping[field.id] || '__NOT_MAPPED__'}
                                                onValueChange={(value) => handleFieldMap(field.id, value)}
                                            >
                                                <SelectTrigger className="w-full">
                                                    <SelectValue placeholder="Select a column">
                                                        {fieldMapping[field.id] || 'Not mapped'}
                                                    </SelectValue>
                                                </SelectTrigger>
                                                <SelectContent>
                                                    <SelectItem value="__NOT_MAPPED__">Not mapped</SelectItem>
                                                    {headers
                                                        .filter((header) => header && header.trim().length > 0)
                                                        .map((header) => (
                                                            <SelectItem key={header} value={header}>
                                                                {header}
                                                            </SelectItem>
                                                        ))}
                                                </SelectContent>
                                            </Select>
                                        </TableCell>
                                        <TableCell className="px-4 py-3 truncate w-[45%] text-muted-foreground">
                                            {fieldMapping[field.id] && hasSampleData
                                                ? (sampleRow[fieldMapping[field.id]] &&
                                                  sampleRow[fieldMapping[field.id]].length > 50
                                                      ? sampleRow[fieldMapping[field.id]].substring(0, 50) + '...'
                                                      : sampleRow[fieldMapping[field.id]]) || '—'
                                                : '—'}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </div>
            </div>

            <p className="text-sm text-muted-foreground">
                <span className="text-destructive">*</span> Required fields
            </p>
        </div>
    );
});

export default FieldMappingStep;
