import { IUntypedCommand } from '@zaplify/common';
import { Topics } from '@zaplify/common';

export class AssignChannelAccountCommand extends IUntypedCommand {
    public static override readonly TOPIC = Topics.USERS;
    constructor(
        readonly userId: string,
        readonly organizationId: string,
        readonly channelAccountId: string,
        readonly channelType: any,
        readonly channelProvider: any,
    ) {
        super();
    }
}
