import { Box, Tooltip, AvatarGroup, Avatar } from '@mui/material';
import { stringToColor } from '../../views/chats/utils/string-to-color';

interface Props {
    users: { userId: string; fullName: string }[];
    userId?: string;
    ignoreYou?: boolean;
    userAvatarUrl?: string;
    size?: 'tiny' | 'small' | 'medium' | 'large';
    spacing?: 'small' | 'medium';
    startIcon?: React.ReactNode;
    maxNumAvatars?: number;
}

export const UsersAvatarGroup = ({
    users,
    userId,
    ignoreYou = true,
    userAvatarUrl,
    size = 'medium',
    spacing = 'medium',
    startIcon,
    maxNumAvatars = 3,
}: Props) => {
    const sizeMap = {
        tiny: 18,
        small: 24,
        medium: 32,
        large: 40,
    };
    const fontMap = {
        tiny: '10px !important',
        small: '12px !important',
        medium: '14px !important',
        large: '16px !important',
    };
    const avatarSize = sizeMap[size];
    const fontSize = fontMap[size];
    const filteredUsers = ignoreYou && userId ? users?.filter((owner) => owner?.userId !== userId) : users;

    // Put user in the first position
    if (userId && !ignoreYou) {
        const userIndex = filteredUsers?.findIndex((owner) => owner?.userId === userId);
        if (userIndex !== -1) {
            const user = filteredUsers?.splice(userIndex, 1);
            filteredUsers?.unshift(user[0]);
        }
    }

    if (!filteredUsers?.length) {
        return null;
    }

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                gap: '4px',
            }}
        >
            <Tooltip
                title={
                    <div>
                        This person is in contact with:
                        <br />
                        {filteredUsers?.map((owner) => (
                            <>
                                - {owner?.userId === userId ? 'You' : owner.fullName}
                                <br />
                            </>
                        ))}
                    </div>
                }
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {startIcon && startIcon}
                    <AvatarGroup
                        max={maxNumAvatars}
                        spacing={spacing}
                        sx={{
                            fontSize: '14px',
                            '& .MuiAvatarGroup-avatar': {
                                width: `${avatarSize}px`,
                                height: `${avatarSize}px`,
                                fontSize: fontSize,
                            },
                        }}
                    >
                        {filteredUsers?.map((owner) => {
                            const isYou = userId ? owner?.userId === userId : false;

                            const [firstName, lastName] = owner.fullName?.toUpperCase()?.split(' ') || [];

                            return (
                                <Avatar
                                    sx={{
                                        bgcolor: stringToColor(owner.fullName),
                                        width: `${avatarSize}px`,
                                        height: `${avatarSize}px`,
                                    }}
                                    src={isYou ? userAvatarUrl : undefined}
                                >
                                    {`${firstName?.[0] || ''}${lastName?.[0] || ''}`}
                                </Avatar>
                            );
                        })}
                    </AvatarGroup>
                </Box>
            </Tooltip>
        </Box>
    );
};
