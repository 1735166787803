import { createContext, useContext, useState, ReactNode, useCallback, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getZaplifySdk } from '@zaplify/sdk';
import {
    ProspectSearchFiltersDto,
    PreviewProspectingDtos,
    IPersonFilter,
    IOrganizationFilter,
} from '@zaplify/prospects';

export const PROSPECT_SEARCH_PAGE_SIZE = 10;
const LOCAL_STORAGE_FILTERS_KEY = 'andsend_search_filters';

const initialFilters: ProspectSearchFiltersDto = {
    organizationFilter: {
        keywords: { include: [], exclude: [] },
        industries: { include: [], exclude: [] },
        organizations: { include: [], exclude: [] },
        organizationsExact: { include: [], exclude: [] },
        organizationSizes: { include: [], exclude: [] },
        organizationDomains: { include: [], exclude: [] },
    },
    personalFilter: {
        keywords: { include: [], exclude: [] },
        locations: { include: [], exclude: [] },
        occupationTitles: { include: [], exclude: [] },
        occupationFunctions: { include: [], exclude: [] },
        occupationSeniorities: { include: [], exclude: [] },
        personIds: { include: [], exclude: [] },
        names: { include: [], exclude: [] },
    },
    options: {
        from: 0,
        size: PROSPECT_SEARCH_PAGE_SIZE,
        include: {
            assigned: false,
            available: true,
            blocklisted: false,
        },
    },
    activityFilters: {
        excludePersonsInCampaigns: false,
    },
    crmFilters: {
        excludeOwnedCompanies: false,
    },
};

interface SearchContextType {
    filters: ProspectSearchFiltersDto;
    updateFilter: (key: keyof ProspectSearchFiltersDto, value: IPersonFilter | IOrganizationFilter) => void;
    resetFilters: () => void;
    persons: PreviewProspectingDtos.ProspectDto[];
    isLoading: boolean;
    isEmptyFilters: boolean;
    handleSearch: () => void;
    page: number;
    setPage: (page: number) => void;
    totalCount: number;
    loadPersistedFilters: (options?: { fallbackToGeneratedFilters?: boolean }) => Promise<void>;
    hasSearched: boolean;
}

const SearchContext = createContext<SearchContextType | undefined>(undefined);

export const useSearchContext = () => {
    const context = useContext(SearchContext);
    if (!context) {
        throw new Error('useSearchContext must be used within a SearchProvider');
    }
    return context;
};

const saveFiltersToLocalStorage = (filters: ProspectSearchFiltersDto) => {
    try {
        localStorage.setItem(LOCAL_STORAGE_FILTERS_KEY, JSON.stringify(filters));
    } catch (error) {
        console.error('Error saving filters to localStorage:', error);
    }
};

const getFiltersFromLocalStorage = (): ProspectSearchFiltersDto | null => {
    try {
        const storedFilters = localStorage.getItem(LOCAL_STORAGE_FILTERS_KEY);
        return storedFilters ? JSON.parse(storedFilters) : null;
    } catch (error) {
        console.error('Error retrieving filters from localStorage:', error);
        return null;
    }
};

export const SearchProvider = ({ children }: { children: ReactNode }) => {
    const [filters, setFilters] = useState<ProspectSearchFiltersDto>(
        getFiltersFromLocalStorage() || { ...initialFilters }
    );
    const [page, setPage] = useState(1);

    const [searchResults, setSearchResults] = useState<PreviewProspectingDtos.ResponsePerPerson | null>(null);
    const [prospectSearchFiltersId, setProspectSearchFiltersId] = useState<string | null>(null);
    const isEmptyFilters = useCallback(() => {
        const { organizationFilter, personalFilter } = filters;

        // Check if any of the include arrays in organizationFilter have items
        const hasOrgFilters =
            organizationFilter &&
            Object.values(organizationFilter).some((filter) => filter?.include && filter.include.length > 0);

        // Check if any of the include arrays in personalFilter have items
        const hasPersonalFilters =
            personalFilter &&
            Object.values(personalFilter).some((filter) => filter?.include && filter.include.length > 0);

        return !hasOrgFilters && !hasPersonalFilters;
    }, [filters]);

    // Function to get the count of prospects
    const getCount = useCallback(() => {
        if (!searchResults) return 0;
        const totalPersons = searchResults.counts?.totalPersons?.value || 0;
        const assignedPersons = searchResults.counts?.assignedPersons?.value || 0;
        return totalPersons - assignedPersons;
    }, [searchResults]);

    // Check if there are no results on the current page
    const noResultsOnPage = useCallback(() => {
        const remainingCount = getCount();
        const pageSize = PROSPECT_SEARCH_PAGE_SIZE;
        return remainingCount - (page - 1) * pageSize <= 0;
    }, [getCount, page]);

    // Get filters for search
    const getFilters = useCallback(() => {
        // Update pagination info
        const updatedFilters = {
            ...filters,
            options: {
                ...filters.options,
                from: (page - 1) * PROSPECT_SEARCH_PAGE_SIZE,
                size: PROSPECT_SEARCH_PAGE_SIZE,
            },
        };

        return updatedFilters;
    }, [filters, page]);

    // Load persisted filters via API
    const loadPersistedFilters = useCallback(
        async (options?: { fallbackToGeneratedFilters?: boolean }) => {
            try {
                const sdk = getZaplifySdk().profiles;
                let filter: any = null;
                if (prospectSearchFiltersId) {
                    try {
                        filter = await sdk.sources.getPersonFilter(prospectSearchFiltersId);
                    } catch (error) {
                        // If filter not found (404) or any other error, clear the ID
                        console.error('Error fetching filter, clearing stored filter ID:', error);
                        setProspectSearchFiltersId(null);

                        // Try fallback if option is enabled
                        if (options?.fallbackToGeneratedFilters) {
                            filter = await sdk.sources.getGeneratedFilters().catch(() => null);
                        }
                    }
                } else if (options?.fallbackToGeneratedFilters) {
                    filter = await sdk.sources.getGeneratedFilters().catch(() => null);
                }

                if (filter) {
                    setFilters({
                        organizationFilter: filter.organizationFilter,
                        personalFilter: filter.personalFilter,
                        options: filter.options,
                        activityFilters: filter.activityFilters,
                        crmFilters: filter.crmFilters,
                    });
                    if (filter.id) {
                        setProspectSearchFiltersId(filter.id);
                    }
                }
            } catch (error) {
                console.error('Error loading persisted filters:', error);
                setProspectSearchFiltersId(null);
            }
        },
        [prospectSearchFiltersId]
    );

    // Load persisted filters when component mounts
    useEffect(() => {
        // First try to load from the API if possible, falling back to generated filters if needed
        loadPersistedFilters({ fallbackToGeneratedFilters: true });
    }, []);

    const { isLoading, refetch, isFetchedAfterMount } = useQuery({
        queryKey: ['prospectSearch', getFilters()],
        queryFn: async () => {
            try {
                if (noResultsOnPage() && page > 1) {
                    setPage(1);
                    return null;
                }

                (window as any)?.analytics?.track('User Searched For Prospects');

                const sdk = getZaplifySdk().profiles;
                const searchResult = await sdk.sources.previewProspectingListNew(getFilters());

                setSearchResults(searchResult);
                saveFiltersToLocalStorage(filters);
                return searchResult;
            } catch (error) {
                console.error('Error searching for prospects', error);
                setSearchResults(null);
                throw error;
            }
        },
        enabled: false,
    });

    useEffect(() => {
        if (page == 1 && isLoading) return;
        refetch();
    }, [page]);

    const handleSearch = useCallback(() => {
        setPage(1);
        refetch();
    }, [setPage, refetch]);

    const resetFilters = useCallback(() => {
        setFilters({ ...initialFilters });
        setProspectSearchFiltersId(null);
        setSearchResults(null);
        localStorage.removeItem(LOCAL_STORAGE_FILTERS_KEY);
    }, []);

    const updateFilter = useCallback((key: keyof ProspectSearchFiltersDto, value: any) => {
        setFilters((prev) => {
            const updated = { ...prev };

            if (key === 'organizationFilter') {
                updated.organizationFilter = {
                    ...updated.organizationFilter,
                    ...value,
                };
            } else if (key === 'personalFilter') {
                updated.personalFilter = {
                    ...updated.personalFilter,
                    ...value,
                };
            } else if (key === 'options') {
                updated.options = {
                    ...updated.options,
                    ...value,
                };
            } else if (key === 'activityFilters') {
                updated.activityFilters = {
                    ...updated.activityFilters,
                    ...value,
                };
            } else if (key === 'crmFilters') {
                updated.crmFilters = {
                    ...updated.crmFilters,
                    ...value,
                };
            }

            return updated;
        });
    }, []);

    const value = {
        filters,
        updateFilter,
        resetFilters,
        persons: searchResults?.persons || [],
        isLoading,
        isEmptyFilters: isEmptyFilters(),
        handleSearch,
        page,
        setPage,
        totalCount: getCount(),
        loadPersistedFilters,
        hasSearched: isFetchedAfterMount,
    };

    return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>;
};
