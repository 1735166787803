import React, { createContext, useContext, useState, useCallback } from 'react';

type SidebarState = {
    main: boolean;
    todos: boolean;
    details: boolean;
    searchProfile: boolean;
};

type SidebarsContextType = {
    sidebarState: SidebarState;
    toggleSidebar: (sidebar: keyof SidebarState) => void;
    getSidebarProps: (sidebar: keyof SidebarState) => {
        defaultOpen?: boolean;
        open?: boolean;
        onOpenChange?: (open: boolean) => void;
    };
};

const SidebarsContext = createContext<SidebarsContextType | undefined>(undefined);

const SIDEBAR_COOKIE_NAME = 'sidebars:state';
const SIDEBAR_COOKIE_MAX_AGE = 60 * 60 * 24 * 7; // 7 days

export function SidebarsProvider({ children }: { children: React.ReactNode }) {
    const [sidebarState, setSidebarState] = useState<SidebarState>(() => {
        // Try to load initial state from cookie
        try {
            const cookie = document.cookie
                .split('; ')
                .find((row) => row.startsWith(SIDEBAR_COOKIE_NAME))
                ?.split('=')[1];
            return cookie
                ? JSON.parse(decodeURIComponent(cookie))
                : {
                      main: true,
                      todos: true,
                      details: true,
                      searchProfile: true,
                  };
        } catch {
            return {
                main: true,
                todos: true,
                details: true,
                searchProfile: true,
            };
        }
    });

    const toggleSidebar = useCallback((sidebar: keyof SidebarState) => {
        setSidebarState((prev) => {
            const newState = { ...prev, [sidebar]: !prev[sidebar] };

            // Save to cookie
            document.cookie = `${SIDEBAR_COOKIE_NAME}=${encodeURIComponent(
                JSON.stringify(newState),
            )}; path=/; max-age=${SIDEBAR_COOKIE_MAX_AGE}`;

            return newState;
        });
    }, []);

    const getSidebarProps = useCallback(
        (sidebar: keyof SidebarState) => ({
            defaultOpen: sidebarState[sidebar],
            open: sidebarState[sidebar],
            onOpenChange: (open: boolean) => {
                setSidebarState((prev) => {
                    const newState = { ...prev, [sidebar]: open };

                    // Save to cookie
                    document.cookie = `${SIDEBAR_COOKIE_NAME}=${encodeURIComponent(
                        JSON.stringify(newState),
                    )}; path=/; max-age=${SIDEBAR_COOKIE_MAX_AGE}`;

                    return newState;
                });
            },
        }),
        [sidebarState],
    );

    return (
        <SidebarsContext.Provider value={{ sidebarState, toggleSidebar, getSidebarProps }}>
            {children}
        </SidebarsContext.Provider>
    );
}

export function useSidebars() {
    const context = useContext(SidebarsContext);
    if (context === undefined) {
        throw new Error('useSidebars must be used within a SidebarsProvider');
    }
    return context;
}
