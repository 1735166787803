import React from 'react';

interface SalesforceIconProps extends React.ButtonHTMLAttributes<SVGElement> {}

export const SalesforceIcon: React.FC<SalesforceIconProps> = ({ ...props }) => {
    return (
        <svg {...props} viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse cx="14.1238" cy="14" rx="14.1238" ry="14" />
            <path
                d="M12.3228 8.50422C12.9678 7.84208 13.8658 7.43141 14.859 7.43141C16.1792 7.43141 17.3311 8.1567 17.9445 9.23342C18.4775 8.99878 19.0675 8.86825 19.6883 8.86825C22.0695 8.86825 24 10.7868 24 13.1533C24 15.52 22.0695 17.4385 19.6883 17.4385C19.4031 17.4387 19.1186 17.4108 18.839 17.3552C18.2989 18.3045 17.2695 18.9458 16.088 18.9458C15.5935 18.9458 15.1256 18.8333 14.7091 18.6332C14.1615 19.9021 12.8858 20.7919 11.3989 20.7919C9.85043 20.7919 8.53077 19.8266 8.02422 18.4729C7.80286 18.5192 7.57351 18.5433 7.33818 18.5433C5.49459 18.5434 4 17.0557 4 15.2203C4 13.9903 4.67151 12.9164 5.66923 12.3418C5.46382 11.8761 5.34957 11.3622 5.34957 10.8218C5.34957 8.71107 7.08889 7 9.23419 7C10.4937 7 11.6131 7.59001 12.3228 8.50422Z"
                className="fill-foreground-secondary"
            />
        </svg>
    );
};
