import { IEvent, Topics } from '@zaplify/common';
import { AssistantSettingsDto } from '../dtos';

export class AssistantSettingsCreatedEvent extends IEvent {
    public static override readonly TOPIC: Topics = Topics.CAMPAIGNS;
    public readonly assistantSettingsId: string;
    public readonly userId: string;
    public readonly organizationId: string;
    public readonly purpose: string;
    public readonly languages: string[];
    public readonly tone?: string;
    public readonly context?: string;
    public readonly targetGroup?: string;

    constructor(params: AssistantSettingsDto) {
        super();
        this.assistantSettingsId = params.id;
        this.userId = params.userId;
        this.organizationId = params.organizationId;
        this.purpose = params.purpose;
        this.languages = params.languages;
        this.tone = params.tone;
        this.context = params.context;
        this.targetGroup = params.targetGroup;
    }

    get partitionKey(): string {
        return this.organizationId;
    }
}
