import { useLinkedin } from '../../../hooks/use-linkedin';
import { ImportLinkedinView } from './components/import-linkedin-view';
import { ImportPageHeader } from './components/import-page-header';
import { Card, CardContent } from '@shadcn/ui/components/ui/card';
import { Button } from '@shadcn/ui/components/ui/button';
import { Chrome, AlertCircle, LogIn, Settings } from 'lucide-react';

export const ImportLinkedinPage: React.FC = () => {
    const { extensionStatus } = useLinkedin();

    if (extensionStatus === 'NOT_INSTALLED') {
        return (
            <ErrorState
                icon={AlertCircle}
                title="Chrome Extension Required"
                description="To import your LinkedIn connections, you'll need to install our Chrome extension first."
                actionLabel="Install Extension"
                actionHref="https://chrome.google.com/webstore/detail/andsend/nolanbablkmlhllilaloenjhaplnfhof"
            />
        );
    }

    if (extensionStatus === 'NOT_AUTHENTICATED') {
        return (
            <ErrorState
                icon={LogIn}
                title="LinkedIn Login Required"
                description="Please sign in to your LinkedIn account to access your connections."
                actionLabel="Open LinkedIn"
                actionHref="https://www.linkedin.com/login"
            />
        );
    }

    return (
        <>
            <ImportPageHeader />
            <ImportLinkedinView />
        </>
    );
};

const ErrorState = ({
    icon: Icon,
    title,
    description,
    actionLabel,
    actionHref,
}: {
    icon: any;
    title: string;
    description: string;
    actionLabel?: string;
    actionHref?: string;
}) => {
    return (
        <div className="flex items-center justify-center h-[calc(100vh-200px)]">
            <Card className="w-[400px]">
                <CardContent className="pt-6">
                    <div className="flex flex-col items-center gap-4 text-center">
                        <div className="p-3 bg-secondary rounded-full">
                            <Icon className="w-6 h-6 text-secondary-foreground" />
                        </div>
                        <div className="space-y-2">
                            <h3 className="text-xl font-semibold">{title}</h3>
                            <p className="text-sm text-muted-foreground">{description}</p>
                        </div>
                        {actionLabel && actionHref && (
                            <Button asChild className="mt-2">
                                <a href={actionHref} target="_blank" rel="noopener noreferrer">
                                    {actionLabel}
                                </a>
                            </Button>
                        )}
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};

export default ImportLinkedinPage;
