import {
    ConnectionStatus,
    LinkedinActivityType,
    LinkedinTriggerActivityDto,
    LinkedinUserActivitiesDto,
} from '@zaplify/campaigns';
import { ConversationMessageParsed } from '@zaplify/web-extension-shared';

export function parseLinkedinConversationToUserActivity(
    linkedinMessages: ConversationMessageParsed[],
    userLinkedinUserId: string,
    connectionStatus: ConnectionStatus | null
): LinkedinUserActivitiesDto {
    const activities: LinkedinTriggerActivityDto[] = [];
    let linkedinUserId: string;

    for (const message of linkedinMessages) {
        if (message.data?.receiverIds?.length > 1) {
            continue;
        }

        if (!linkedinUserId) {
            const notUserReceiver = message.data.receiverIds.find((receiverId) => receiverId !== userLinkedinUserId);
            if (notUserReceiver) {
                linkedinUserId = notUserReceiver;
            } else {
                if (message.data.senderId !== userLinkedinUserId) {
                    linkedinUserId = message.data.senderId;
                }
            }
        }
        const type =
            message.direction === 'IN' ? LinkedinActivityType.CONVERSATION_REPLY : LinkedinActivityType.MESSAGE_SENT;

        const activity: LinkedinTriggerActivityDto = {
            type: type,
            timestamp: message.timestamp,
            data: {
                message: message.data.message,
                messageId: message.data.messageId,
                conversationId: message.data.conversationId,
                attachments: message.data.attachments,
            },
        };
        activities.push(activity);
    }
    return {
        activities: activities,
        connectionStatus,
        linkedinUserId: linkedinUserId,
    };
}
