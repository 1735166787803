import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OutreachSettingsDto } from '@zaplify/campaigns';
import { convertArrayToObject } from '../../../helpers/array';
import { alertModalMessages } from '../../../services/utils/constants';
import { getInitialLocalDays, getInitialLocalTime } from '../../reducers/newCampaign/functions';
import { OutreachState } from './constants';
import { DayType, FlowSplitType, OutreachStateInterface, TimeType, UpdateWorkflowType } from './types';

const outreachSlice = createSlice({
    name: 'outreach',
    initialState: OutreachState as OutreachStateInterface,
    reducers: {
        updateOutreach(state, action: PayloadAction<any>) {
            state[action.payload.key] = action.payload.value;
            state.unsavedChanges = true;
        },
        updateTemplate(state, action: PayloadAction<any>) {
            return {
                ...state,
                templateId: action.payload.id,
                templateName: action.payload.name,
                steps: action.payload.steps,
                connections: action.payload.connections,
                unsavedChanges: true,
            };
        },
        updateDays(state, action: PayloadAction<number>) {
            state.days[action.payload].checked = !state.days[action.payload].checked;
            state.unsavedChanges = true;
        },
        updateTimeObject(state, action: PayloadAction<TimeType[]>) {
            state.time = action.payload;
            state.unsavedChanges = true;
        },
        updateTime(state, action: PayloadAction<{ index: number; value: any }>) {
            state.time[action.payload.index].value = action.payload.value;
            state.unsavedChanges = true;
        },
        updateDaysObject(state, action: PayloadAction<DayType[]>) {
            state.days = action.payload;
            state.unsavedChanges = true;
        },
        updateTimeOffset(state, action: PayloadAction<number>) {
            state.offset = action.payload;
        },
        updateCurrentStepModalView(state, action: PayloadAction<number>) {
            state.currentStepModalView = action.payload;
        },
        updateSkipActionSwitch(state, action: PayloadAction<number>) {
            state.steps[action.payload].skipActionSwitch.isOn = !state.steps[action.payload].skipActionSwitch.isOn;
            state.unsavedChanges = true;
        },
        updateSameThreadSwitch(state, action: PayloadAction<number>) {
            state.steps[action.payload].sameConversationSwitch.isOn =
                !state.steps[action.payload].sameConversationSwitch.isOn;
            state.unsavedChanges = true;
        },
        updateMessage(state, action: PayloadAction<{ step: number; message: string }>) {
            if (state.steps[action.payload.step].message.content === action.payload.message) return;
            state.steps[action.payload.step].message.content = action.payload.message;
            state.unsavedChanges = true;
        },
        updateChildSubject(state, action: PayloadAction<{ step: number; message: string }>) {
            if (state.steps[action.payload.step]?.subject == null) return;
            let unsavedChanges = false;
            for (const childStep of state.steps) {
                if (
                    state.steps.indexOf(childStep) <= action.payload.step ||
                    childStep.type !== state.steps[action.payload.step].type
                ) {
                    continue;
                }
                if (!childStep.sameConversationSwitch?.isOn) {
                    return;
                }
                if (state.steps[action.payload.step].subject.content !== childStep.subject.content) {
                    childStep.subject.content = action.payload.message;
                    unsavedChanges = true;
                }
            }
            if (!state.unsavedChanges) {
                state.unsavedChanges = unsavedChanges;
            }
        },
        updateSubject(state, action: PayloadAction<{ step: number; message: string }>) {
            if (state.steps[action.payload.step]?.subject == null) return;
            if (state.steps[action.payload.step].subject.content === action.payload.message) return;
            state.steps[action.payload.step].subject.content = action.payload.message;
            state.unsavedChanges = true;
        },
        updateDelay(state, action: PayloadAction<{ step: number; delay: string }>) {
            state.steps[action.payload.step].delay = action.payload.delay;
            state.unsavedChanges = true;
        },
        updateStop(state, action: PayloadAction<{ step: string; stop: string }>) {
            state.steps[action.payload.step].dependsOn.switch.isOn = action.payload.stop;
            state.unsavedChanges = true;
        },
        updateValidationMessage(state, action: PayloadAction<{ step: number; message: string }>) {
            state.steps[action.payload.step].validationMessage = action.payload.message;
        },
        updateFlowSplit(state, action: PayloadAction<FlowSplitType>) {
            state.flowSplit = { ...action.payload };
        },
        handleValidationErrors(state, action: PayloadAction<any[]>) {
            state.validationErrors = convertArrayToObject(action.payload, 'stepId');
        },
        deleteValidationError(state, action: PayloadAction<string>) {
            delete state.validationErrors[action.payload];
        },
        clearValidationErrors(state) {
            state.validationErrors = {};
        },
        updateAlertMessage(state, action: PayloadAction<any>) {
            state.alertMessages = { ...action.payload };
        },
        updateAlertModal(state, action: PayloadAction<boolean | undefined>) {
            if (action.payload != null)
                return {
                    ...state,
                    isModalVisible: action.payload,
                };
            if (state.isModalVisible)
                return {
                    ...state,
                    isModalVisible: false,
                    alertMessages: { ...alertModalMessages },
                };
            else
                return {
                    ...state,
                    isModalVisible: true,
                };
        },
        resetOutreach() {
            return OutreachState;
        },
        updatedUnsavedOutreachState(state, action: PayloadAction<boolean>) {
            state.unsavedChanges = action.payload;
        },
        updateWholeWorkflow(state, action: PayloadAction<UpdateWorkflowType>) {
            const weekDays = action.payload.weekDays;
            const incomingTimeArray = [...action.payload.fromTime.split(':'), ...action.payload.toTime.split(':')];
            const time = getInitialLocalTime(incomingTimeArray);
            const tempDays = getInitialLocalDays(incomingTimeArray, weekDays, action.payload.offset);

            return {
                ...state,
                steps: action.payload.steps,
                connections: action.payload.connections,
                time: time,
                days: tempDays,
                isManual: action.payload.isManual || false,
                templateName: action.payload.name || '',
            };
        },
        updateManualState(state, action: PayloadAction<boolean>) {
            state.isManual = action.payload;
            state.unsavedChanges = true;
        },
        updateSemiAutoAI(state, action: PayloadAction<'on' | 'off'>) {
            state.semiAutoAI = action.payload;
        },
        updateEditorsState(state, action: PayloadAction<boolean>) {
            if (action.payload != null) state.updateEditors = action.payload;
            else state.updateEditors = !state.updateEditors;
        },
        updateOutreachIsLoadingState(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        updateOutreachSettings(state, action: PayloadAction<OutreachSettingsDto>) {
            state.outreachSettings = action.payload;
        },
    },
});

export const {
    updateOutreach,
    updateTemplate,
    updateDays,
    updateTime,
    updateTimeOffset,
    updateSkipActionSwitch,
    updateSameThreadSwitch,
    updateMessage,
    updateSubject,
    updateChildSubject,
    updateDelay,
    updateStop,
    updateValidationMessage,
    updateFlowSplit,
    handleValidationErrors,
    clearValidationErrors,
    deleteValidationError,
    updateAlertModal,
    updateAlertMessage,
    resetOutreach,
    updateWholeWorkflow,
    updateManualState,
    updateSemiAutoAI,
    updatedUnsavedOutreachState,
    updateEditorsState,
    updateOutreachIsLoadingState,
    updateCurrentStepModalView,
    updateTimeObject,
    updateDaysObject,
    updateOutreachSettings,
} = outreachSlice.actions;

export const outreachReducer = outreachSlice.reducer;
