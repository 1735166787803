export const firebaseActionLink = {
    mode: 'mode',
    actionCode: 'oobCode',
    verifyEmailModeName: 'verifyEmail',
    resetPasswordModeName: 'resetPassword',
    getCurrentMode: () => {
        return getParameterByName('mode');
    },
    getCurrentActionCode: () => {
        return getParameterByName('oobCode');
    },
};

export function getParameterByName(name) {
    var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

export function redirectToPage({ url, navigate, currentUrl }) {
    if (currentUrl.includes(url)) {
        window.location.reload();
    } else {
        navigate(url);
    }
}

export function redirectToPageWithCurrentParameters(url, navigate) {
    navigate({
        pathname: url,
        search: window.location.search,
    });
}

export const isUrl = (url) => {
    try {
        if (url.includes('.com')) return true;
        else return false;
    } catch {
        return false;
    }
};

export const formatUrl = (url) => {
    if (url.includes('http')) return url;
    return 'https://' + url;
};

export function isValidHttpUrl(string) {
    let url;
    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }
    return url.protocol === 'http:' || url.protocol === 'https:';
}
