import { FC } from 'react';
import { PlaybookForm } from './components/playbook-form/playbook-form';
import { PlaybooksViewProvider } from '../hooks/use-playbooks-view';
import { MultiStepPlaybookForm } from './components/playbook-form/multi-step-playbook-form';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const PlaybookFormPage: FC = () => {
    const { multiStepPlaybookForm } = useFlags();
    return (
        <PlaybooksViewProvider>
            <div className="flex h-full max-w-full max-h-full">
                <div className="flex-1 flex flex-col min-w-0 max-h-full">
                    <div className="flex flex-col gap-4 max-h-full overflow-y-auto">
                        {multiStepPlaybookForm ? <MultiStepPlaybookForm /> : <PlaybookForm />}
                    </div>
                </div>
            </div>
        </PlaybooksViewProvider>
    );
};

export default PlaybookFormPage;
