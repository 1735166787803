import { AndSendLogotype } from '../../../../components/icons/and-send/andsend-logo';
import { Button } from '@shadcn/ui/components/ui/button';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { paths } from '../../../../routes/paths';
import { useAuth } from '../../../../new/providers/authentication-provider';
import { LogOut } from 'lucide-react';

export function AuthHeader() {
    const location = useLocation();
    const { authState, logout } = useAuth();
    const isLoggedIn = authState.isLoggedIn;
    const isLoginPage = location.pathname.includes('login');
    const isRegisterPage = location.pathname.includes('register');

    return (
        <div className="flex justify-between items-center w-full">
            <a href="https://www.andsend.com" className="flex items-center gap-2 font-medium">
                <AndSendLogotype variation="transparent_black" className="h-8" />
            </a>

            <div className="flex gap-3">
                {isLoggedIn ? (
                    <Button variant="outline" onClick={logout} className="flex items-center gap-2">
                        <LogOut className="w-4 h-4" />
                        Log out
                    </Button>
                ) : (
                    <>
                        <Link to={paths.NEW_LOGIN}>
                            <Button
                                variant="outline"
                                size="sm"
                                className={`text-sm hover:bg-background ${
                                    isLoginPage
                                        ? ''
                                        : 'bg-transparent border-transparent hover:border-border shadow-none'
                                }`}
                            >
                                Sign in
                            </Button>
                        </Link>

                        <Link to={paths.NEW_REGISTER}>
                            <Button
                                variant="outline"
                                size="sm"
                                className={`text-sm hover:bg-background ${
                                    isRegisterPage
                                        ? ''
                                        : 'bg-transparent border-transparent hover:border-border shadow-none'
                                }`}
                            >
                                Sign up
                            </Button>
                        </Link>
                    </>
                )}
            </div>
        </div>
    );
}
