import { styled } from '@mui/styles';

const ProspectsPerCompanyStyled = styled('div')(({ theme: { palette, spacing } }: any) => ({
    width: '100%',
    padding: spacing(1.5),
    display: 'flex',
    gap: 18,
    justifyContent: 'center',
    alignItems: 'center',
    borderTop: `1px solid ${palette.colors.greyInformationLight}`,

    '& .info': {
        padding: spacing(0.5, 1.6),
        backgroundColor: palette.background.default,
        borderRadius: '8px',
        fontSize: 15,
        color: palette.subline,
    },
    '& .action': {
        alignItems: 'center',
        maxHeight: 33,
        minHeight: 33,
        borderRadius: '8px',
        fontSize: 14,
        color: palette.primary.light,

        '&.hovered': {
            minWidth: 114,
            backgroundColor: palette.background.default,
        },
    },
}));

export default ProspectsPerCompanyStyled;
