import { ICommand, Topics } from '@zaplify/common';

export class FilterBlocklistedCompaniesCommand extends ICommand<
    {
        organizationName: string;
        organizationDomain: string | null;
        isBlocklisted: boolean;
    }[]
> {
    public static override readonly TOPIC = Topics.PROSPECTS;
    constructor(
        readonly organizationId: string,
        readonly companyNames: {
            organizationName: string;
            organizationDomain: string | null;
        }[],
    ) {
        super();
    }
}
