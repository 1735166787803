import { ChannelType } from '@zaplify/channel-accounts/shared';
import { MessageType } from '../enums/message-type.enum';
import { IEvent, Topics } from '@zaplify/common';
import { htmlToPlain } from '@zaplify/utils';

export interface ISentMessageMetadata {
    completedTodo: boolean;
    hasBody?: boolean;
    editedSuggestion?: boolean;
    sentenceDistance?: number;
    assistantId?: string;
}

export class MessageSentEvent extends IEvent {
    public static override readonly TOPIC = Topics.CAMPAIGNS;
    public override _external_ignore_fields = ['content'];

    constructor(
        readonly organizationId: string,
        readonly userId: string,
        readonly prospectId: string,
        readonly messageId: string,
        readonly sentOn: Date,
        readonly channelType: ChannelType,
        readonly messagetype: MessageType,
        readonly content?: string,
        readonly metadata?: ISentMessageMetadata,
    ) {
        super();
    }

    static jaccardSimilarity(currentContent: string, suggestedContent: string): number {
        if (!currentContent || !suggestedContent) {
            return 0;
        }

        const tokenize = (text: string): Set<string> => {
            return new Set(
                text
                    .toLowerCase()
                    .split(/\s+/)
                    .filter((s) => !!s),
            );
        };

        const plainCurrentContent = htmlToPlain(currentContent);
        const plainSuggestedContent = htmlToPlain(suggestedContent);

        const tokensA = tokenize(plainCurrentContent);
        const tokensB = tokenize(plainSuggestedContent);

        const intersection = new Set([...tokensA].filter((token) => tokensB.has(token)));
        const union = new Set([...tokensA, ...tokensB]);

        return union.size === 0 ? 0 : intersection.size / union.size;
    }

    get partitionKey(): string {
        return this.prospectId;
    }
}
