import { useCallback, useRef, useEffect, useState, useMemo, useLayoutEffect } from 'react';
import { useActions } from '../../../hooks/use-actions';
import { ActionCard } from './cards/action-feed-card';
import { AnimatePresence } from 'motion/react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AgentTrainingCard } from './cards/agent-training-card';
import { ActionCardSkeleton } from './cards/action-card-skeleton';
import { cn } from '@shadcn/ui/lib/utils';
import { FeedHeader } from './feed-header';
import { PlaybookFilterBadge } from '../../../components/playbook-filter-badge';
import { CompletedActionsBadge } from './completed-actions-badge';
import { useActionCardDetails } from '../hooks/use-action-card-details';
import { motion } from 'motion/react';
import { useSdk } from '../../../sdk';
import { useQuery as useTanQuery } from '@tanstack/react-query';
import { debounce } from '../../../../helpers/debounce';
import { ProspectDataDto } from '@zaplify/prospects';
import { Badge } from '@shadcn/ui/components/ui/badge';
import { ArrowUp } from 'lucide-react';

type ProspectFields = Pick<
    ProspectDataDto,
    'fullName' | 'linkedinProfileImgUrl' | 'occupationTitle' | 'organizationName' | 'linkedinUserId' | 'organizationId'
>;
type ProspectDataWithId = ProspectFields & { prospectId: string };

export const ActionFeed = () => {
    const { prospectIdsWithActions, isLoadingActions, totalActionsCount, prospectIdsWithCompletedActions } =
        useActions();
    const cardRefs = useRef<Record<string, HTMLDivElement | null>>({});
    const feedContainerRef = useRef<HTMLDivElement>(null);
    const selectedProspectId = useParams().prospectId;
    const navigate = useNavigate();

    const [snapshotProspectIds, setSnapshotProspectIds] = useState<string[]>([]);
    const [newActionsProspectIds, setNewActionsProspectIds] = useState<string[]>([]);
    const [removedProspectIds, setRemovedProspectIds] = useState<string[]>([]);
    const [displayLimit, setDisplayLimit] = useState(10);
    const [prospectDataMap, setProspectDataMap] = useState<Record<string, ProspectDataWithId>>({});

    // TODO: Move inside of the card
    const { onClose: closeDetails, isOpen: isDetailsOpen } = useActionCardDetails();

    const {
        prospect: { findProspectData },
    } = useSdk();

    // Set ref for action cards
    const setCardRef = (prospectId: string, ref: HTMLDivElement | null) => {
        cardRefs.current[prospectId] = ref;
    };

    const scrollToCard = (prospectId: string) => {
        const cardRef = cardRefs.current[prospectId];
        if (cardRef && feedContainerRef.current) {
            // Remove the setTimeout and use a single requestAnimationFrame
            requestAnimationFrame(() => {
                const containerRect = feedContainerRef.current?.getBoundingClientRect();
                const cardRect = cardRef.getBoundingClientRect();

                if (!containerRect) return;

                const position = cardRect.top - containerRect.top + (feedContainerRef.current?.scrollTop || 0);

                if (position > 0 && feedContainerRef.current) {
                    feedContainerRef.current.scrollTo({
                        top: position - 45,
                        behavior: 'smooth',
                    });
                }
            });
        }
    };

    // Handler for clicks on the container
    const handleContainerClick = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            // Only handle clicks if they're directly on the container itself
            if (e.target !== e.currentTarget) {
                return;
            }
            if (!selectedProspectId) return;

            // Check if click target is outside of the active card
            const clickedElement = e.target as HTMLElement;
            const activeCardRef = cardRefs.current[selectedProspectId];

            // click is not inside the active card
            if (!activeCardRef?.contains(clickedElement)) {
                // Check if clicked inside any other card ref
                const clickedInsideAnyCard = Object.values(cardRefs.current).some(
                    (ref) => ref && ref.contains(clickedElement)
                );

                // Only collapse if click was not inside any card
                if (!clickedInsideAnyCard) {
                    // Navigate to action feed without prospect ID to collapse
                    closeDetails();
                    const searchParams = new URLSearchParams(location.search);
                    searchParams.delete('details');
                    setTimeout(
                        () => {
                            navigate(
                                { pathname: '/new/action-feed', search: searchParams.toString() },
                                { preventScrollReset: true }
                            );
                        },
                        isDetailsOpen ? 300 : 0
                    );
                }
            }
        },
        [selectedProspectId, navigate, isDetailsOpen]
    );

    // Calculate completed prospects
    const completedProspects = useMemo(() => {
        return new Set(prospectIdsWithCompletedActions);
    }, [prospectIdsWithCompletedActions]);

    const filteredProspectIds = useMemo(() => {
        // Create a stable order using the snapshot instead of live updates
        const stableOrder = [...snapshotProspectIds];

        const filtered = stableOrder
            .filter((id) => {
                // Remove if explicitly removed
                if (removedProspectIds.includes(id)) return false;

                // Remove if completed and no longer has active actions
                if (completedProspects.has(id) && !prospectIdsWithActions.includes(id)) return false;

                return true;
            })
            .slice(0, displayLimit);

        // Ensure selected prospect is included
        if (selectedProspectId && !filtered.includes(selectedProspectId)) {
            const originalIndex = stableOrder.indexOf(selectedProspectId);
            if (originalIndex !== -1) {
                const insertIndex = Math.min(originalIndex, filtered.length);
                filtered.splice(insertIndex, 0, selectedProspectId);
            }
        }

        // Remove any duplicate prospect IDs while preserving order
        const uniqueFiltered = filtered.filter((id, index) => filtered.indexOf(id) === index);
        return uniqueFiltered;
    }, [
        snapshotProspectIds,
        removedProspectIds,
        completedProspects,
        prospectIdsWithActions,
        selectedProspectId,
        displayLimit,
    ]);

    const prospectIdsToFetch = useMemo(() => {
        return filteredProspectIds.filter((id) => !prospectDataMap[id]);
    }, [filteredProspectIds, prospectDataMap]);

    const { data: newProspectData, isLoading: isProspectLoading } = useTanQuery(
        findProspectData(
            {
                prospectIds: prospectIdsToFetch,
                page: 1,
                perPage: prospectIdsToFetch.length,
                includeFields: [
                    'fullName',
                    'linkedinProfileImgUrl',
                    'occupationTitle',
                    'organizationName',
                    'linkedinUserId',
                    'organizationId',
                ],
            },
            {
                enabled: prospectIdsToFetch.length > 0,
            }
        )
    );

    const debouncedScrollToCard = useCallback(
        debounce((prospectId: string) => {
            scrollToCard(prospectId);
        }, 300),
        []
    );

    const handleRefreshActions = useCallback(() => {
        // Create new array with new IDs at the beginning, followed by existing snapshot IDs
        const reorderedIds = [
            ...newActionsProspectIds,
            ...prospectIdsWithActions.filter((id) => prospectIdsWithActions.includes(id)),
        ];

        const scrollPosition = feedContainerRef.current?.scrollTop || 0;
        feedContainerRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
        setTimeout(
            () => {
                setSnapshotProspectIds(reorderedIds);
                setNewActionsProspectIds([]);
                setRemovedProspectIds([]);
                setDisplayLimit(10);
            },
            scrollPosition > 1000 ? 1000 : 500
        );
    }, [prospectIdsWithActions, snapshotProspectIds, completedProspects]);

    // Initialize snapshot when first loading
    useEffect(() => {
        if (isLoadingActions) return;

        if (prospectIdsWithActions.length > 0) {
            if (snapshotProspectIds.length < 5) {
                setSnapshotProspectIds(prospectIdsWithActions);
            } else {
                // Remove any prospectIds that are no longer in prospectIdsWithActions, except for if it is the selected prospect
                setSnapshotProspectIds(
                    snapshotProspectIds.filter((id) => prospectIdsWithActions.includes(id) || id === selectedProspectId)
                );
            }
        }
    }, [isLoadingActions, prospectIdsWithActions, selectedProspectId]);

    // Check for new actions by comparing current IDs with snapshot, but only within display limit
    useEffect(() => {
        if (snapshotProspectIds.length > 0) {
            setNewActionsProspectIds(
                prospectIdsWithActions.slice(0, displayLimit).filter((id) => !snapshotProspectIds.includes(id))
            );
        }
    }, [prospectIdsWithActions, snapshotProspectIds, displayLimit]);

    useEffect(() => {
        if (selectedProspectId && cardRefs.current[selectedProspectId]) {
            debouncedScrollToCard(selectedProspectId);
        }
    }, [selectedProspectId]);

    // Update prospect data map when new data arrives
    useEffect(() => {
        if (newProspectData?.prospects) {
            setProspectDataMap((prev) => ({
                ...prev,
                ...Object.fromEntries(
                    newProspectData.prospects.map((prospect) => [
                        prospect.prospectId,
                        {
                            prospectId: prospect.prospectId,
                            fullName: prospect.fullName,
                            linkedinProfileImgUrl: prospect.linkedinProfileImgUrl,
                            occupationTitle: prospect.occupationTitle,
                            organizationName: prospect.organizationName,
                            linkedinUserId: prospect.linkedinUserId,
                            organizationId: prospect.organizationId,
                        },
                    ])
                ),
            }));
        }
    }, [newProspectData]);

    useEffect(() => {
        const container = feedContainerRef.current;
        if (!container) return;

        const handleScroll = () => {
            const { scrollTop, scrollHeight, clientHeight } = container;
            const isNearBottom = scrollHeight - scrollTop - clientHeight < 400;

            if (isNearBottom && snapshotProspectIds.length > filteredProspectIds.length) {
                setDisplayLimit((prev) => prev + 10);
            }
        };

        container.addEventListener('scroll', handleScroll);
        return () => container.removeEventListener('scroll', handleScroll);
    }, [snapshotProspectIds, filteredProspectIds]);

    return (
        <div
            className={cn(
                'w-full h-full overflow-y-auto flex flex-col items-center bg-background-secondary overscroll-none'
            )}
            ref={feedContainerRef}
            onMouseDown={handleContainerClick}
        >
            <FeedHeader />
            <div className="sticky top-0 w-full flex flex-row justify-between z-[100] bg-transparent px-4 pt-8 pointer-events-none">
                <div className="pointer-events-auto">
                    <PlaybookFilterBadge size="md" />
                </div>
                {newActionsProspectIds.length > 0 && (
                    <Badge
                        variant="outline"
                        className={cn(
                            'bg-white flex items-center justify-center gap-2 pointer-events-auto cursor-pointer',
                            'animate-slide-in-from-top absolute top-2 left-1/2 -translate-x-1/2 z-[110]'
                        )}
                        onClick={handleRefreshActions}
                    >
                        <ArrowUp className="w-4 h-4" />
                        {`New action${newActionsProspectIds.length === 1 ? '' : 's'} available`}
                        <Badge variant="warning" className="p-[0.1rem] px-2">
                            {newActionsProspectIds.length}
                        </Badge>
                    </Badge>
                )}
                <div className="flex items-center gap-2 pointer-events-auto">
                    <CompletedActionsBadge variant="outline" className="h-8 bg-background-primary" />
                </div>
            </div>

            {/* New Actions Badge */}

            <div
                className={cn(
                    'relative flex flex-col gap-6 p-4 -top-12 items-center w-[calc(min(70%,1200px))] max-w-[calc(min(70%,1200px))] h-fit',
                    'overflow-x-visible' // Allow details card to overflow and slide out
                )}
            >
                <AgentTrainingCard key="agent-training-card" />
                {isLoadingActions && !filteredProspectIds ? (
                    Array.from({ length: totalActionsCount || 10 }).map((_, index) => (
                        <ActionCardSkeleton key={`skeleton-${index}`} />
                    ))
                ) : filteredProspectIds?.length > 0 ? (
                    filteredProspectIds.map((prospectId, index) => (
                        <AnimatePresence mode="sync">
                            <ActionCard
                                key={prospectId}
                                prospectId={prospectId}
                                prospectData={prospectDataMap[prospectId]}
                                ref={(ref) => setCardRef(prospectId, ref)}
                                isCompleted={
                                    completedProspects.has(prospectId) && !prospectIdsWithActions.includes(prospectId)
                                }
                                onRemoveCard={() => setRemovedProspectIds((value) => [...value, prospectId])}
                            />
                        </AnimatePresence>
                    ))
                ) : (
                    <motion.div
                        key="empty-state"
                        className="flex flex-col text-muted-foreground items-center justify-center h-full w-full"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 1, duration: 0.3, ease: 'easeInOut' }}
                    >
                        <p>
                            You are all set!{' '}
                            <Link to="/new/discover/suggestions" className="text-foreground-brand-primary">
                                Go to discover
                            </Link>{' '}
                            to find new contacts.
                        </p>
                    </motion.div>
                )}
            </div>
        </div>
    );
};
