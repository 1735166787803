import { FC, Suspense, useState } from 'react';
import { DatePickerWithRange } from '../../components/date-range-picker';
import { addDays } from 'date-fns';
import { DateRange } from 'react-day-picker';
import { DashboardMetrics } from './components/dashboard-metrics';
import DashboardHeader from './components/dashboard-header';
import { Switch } from '@shadcn/ui/components/ui/switch';
import { Label } from '@shadcn/ui/components/ui/label';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@shadcn/ui/components/ui/tabs';
import { useAuth } from '../../providers/authentication-provider';
import { UserRole } from '@zaplify/users/shared';
import { TeamMetrics } from './components/team-metrics';
import { PlanCode } from '@zaplify/subscriptions';
import { Lock } from 'lucide-react';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@shadcn/ui/components/ui/tooltip';
import { Link, useNavigate, useParams } from 'react-router-dom';

export const DashboardPage: FC = () => {
    const {
        authState: {
            user,
            tokenResult: {
                claims: { plan },
            },
        },
    } = useAuth();
    const isAdmin = user.roles.includes(UserRole.ORGANIZATION_ADMIN);
    const { tab } = useParams<{ tab: 'personal' | 'team' }>();
    const [date, setDate] = useState<DateRange>({
        from: addDays(new Date(), -30),
        to: new Date(),
    });
    const [distinctProspects, setDistinctProspects] = useState(false);
    const navigate = useNavigate();
    return (
        <div className="flex flex-col w-full h-full overflow-auto">
            <Suspense fallback={<div>Loading...</div>}>
                <DashboardHeader />
            </Suspense>

            {isAdmin ? (
                <Tabs defaultValue={tab || 'personal'} className="flex flex-col w-full p-4">
                    <div className="flex justify-between items-center mb-4 flex-wrap gap-4">
                        <TabsList>
                            <TabsTrigger
                                value="personal"
                                onClick={() => {
                                    if (tab !== 'personal') {
                                        navigate('..', { relative: 'path' });
                                    }
                                }}
                            >
                                <Link to="personal">Personal Statistics</Link>
                            </TabsTrigger>
                            <TooltipProvider>
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <div>
                                            <TabsTrigger
                                                value="team"
                                                disabled={
                                                    ![PlanCode.TEAM, PlanCode.ENTERPRISE].includes(plan as PlanCode)
                                                }
                                                onClick={() => {
                                                    if (
                                                        [PlanCode.TEAM, PlanCode.ENTERPRISE].includes(plan as PlanCode)
                                                    ) {
                                                        if (tab !== 'team') {
                                                            navigate('team');
                                                        }
                                                    }
                                                }}
                                            >
                                                {![PlanCode.TEAM, PlanCode.ENTERPRISE].includes(plan as PlanCode) && (
                                                    <Lock className="w-4 h-4 mr-2" />
                                                )}
                                                Team Statistics
                                            </TabsTrigger>
                                        </div>
                                    </TooltipTrigger>
                                    {![PlanCode.TEAM, PlanCode.ENTERPRISE].includes(plan as PlanCode) && (
                                        <TooltipContent className="max-w-[200px]">
                                            <p>
                                                Team Statistics is available on Team and Enterprise plans.{' '}
                                                <Link
                                                    to="/account-management/subscription"
                                                    className="text-primary underline"
                                                >
                                                    Upgrade now
                                                </Link>
                                            </p>
                                        </TooltipContent>
                                    )}
                                </Tooltip>
                            </TooltipProvider>
                        </TabsList>
                        <div className="flex items-center gap-4 flex-wrap">
                            <div className="flex items-center gap-2">
                                <Switch
                                    id="distinct-prospects"
                                    checked={distinctProspects}
                                    onCheckedChange={setDistinctProspects}
                                    className="data-[state=checked]:bg-background-brand-primary data-[state=unchecked]:bg-input"
                                />
                                <Label htmlFor="distinct-prospects">Unique Contacts</Label>
                            </div>
                            <DatePickerWithRange date={date} setDate={setDate} />
                        </div>
                    </div>

                    <TabsContent value="personal">
                        <Suspense fallback={<div>Loading...</div>}>
                            {date.from && date.to && (
                                <DashboardMetrics
                                    startDate={date.from}
                                    endDate={date.to}
                                    distinctProspects={distinctProspects}
                                />
                            )}
                        </Suspense>
                    </TabsContent>

                    {[PlanCode.TEAM, PlanCode.ENTERPRISE].includes(plan as PlanCode) && (
                        <TabsContent value="team">
                            <Suspense fallback={<div>Loading...</div>}>
                                {date.from && date.to && (
                                    <TeamMetrics
                                        startDate={date.from}
                                        endDate={date.to}
                                        distinctProspects={distinctProspects}
                                    />
                                )}
                            </Suspense>
                        </TabsContent>
                    )}
                </Tabs>
            ) : (
                <>
                    <div className="flex justify-end gap-4 p-4">
                        <div className="flex items-center gap-2">
                            <Switch
                                id="distinct-prospects"
                                checked={distinctProspects}
                                onCheckedChange={setDistinctProspects}
                                className="data-[state=checked]:bg-background-brand-primary data-[state=unchecked]:bg-input"
                            />
                            <Label htmlFor="distinct-prospects">Unique Contacts</Label>
                        </div>
                        <DatePickerWithRange date={date} setDate={setDate} />
                    </div>
                    <Suspense fallback={<div>Loading...</div>}>
                        <div className="flex flex-col gap-4 p-4 pt-0">
                            {date.from && date.to && (
                                <DashboardMetrics
                                    startDate={date.from}
                                    endDate={date.to}
                                    distinctProspects={distinctProspects}
                                />
                            )}
                        </div>
                    </Suspense>
                </>
            )}
        </div>
    );
};

export default DashboardPage;
