import { IQuery, Topics } from '@zaplify/common';
import { ProspectDataDto } from '../prospects';

export type ProspectDataWithId<T extends keyof ProspectDataDto = keyof ProspectDataDto> = { prospectId: string } & Pick<
    ProspectDataDto,
    T
>;

export class GetProspectDataByIdsQuery<T extends keyof ProspectDataDto = keyof ProspectDataDto> extends IQuery<
    ProspectDataWithId<T>[]
> {
    public static override readonly TOPIC = Topics.PROSPECTS;
    constructor(readonly prospectIds: string[], readonly projectFields: T[]) {
        super();
    }
}
