import { getZaplifySdk } from '@zaplify/sdk';
import { CreatedEstimateDto, IPlan, IPlanPackage, PlanCode, UpdateSubscriptionDto } from '@zaplify/subscriptions';
import { useAtom, useSetAtom } from 'jotai';
import { useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux-latest';
import {
    availablePeriodsAtom,
    availablePlansAtom,
    estimatesAtom,
    loadingStateAtom,
    selectedBillingPeriodAtom,
    selectedPackagesAtom,
    selectedPlanAtom,
    selectedPlanEstimatesSummaryAtom,
    selectedPlanEstimatesTotalAtom,
    upgradePlanErrorTextAtom,
} from '../atoms/selfService';
import { ModalView } from '../components/pick-plan-modal/constants';
import { PaymentStatus } from '../components/pick-plan-modal/enums/billing';
import { getAvailablePlans, getCheckoutDTO } from '../components/pick-plan-modal/helpers';
import { BillingPeriodUnit } from '../enums/billing';
import { setNotification, updatePaymentStatus, updateProcessingPaymentType } from '../redux/actions';
import { AppDispatch, useAppSelector } from '../redux/store/configureStore';
import { useEffectOnce } from './use-effect-once';
import { useSeatsAndCredits } from '../new/hooks/use-seat-and-credits';

const useSubscription = ({
    organizationId,
    modalView,
    currentPlanBillingPeriod,
    currentPlanBillingAmount,
    currentPlanCode,
    currentNumberOfSeats,
}: {
    organizationId: string;
    modalView: ModalView; // provide this if you want to use the hook to update the subscription from pick plan modal
    currentPlanBillingPeriod?: BillingPeriodUnit;
    currentPlanBillingAmount?: number;
    currentPlanCode?: PlanCode;
    currentNumberOfSeats: number;
}) => {
    const cbInstance = useRef(window.Chargebee?.init({ site: import.meta.env.VITE_CHARGEBEE_SITE }));
    const dispatch = useDispatch<AppDispatch>();
    const { seatSummary, refetch: refetchCredits } = useSeatsAndCredits();

    const [currentBillingPeriod] = useState<{
        unit: BillingPeriodUnit | undefined;
        amount: number | undefined;
    }>({ unit: currentPlanBillingPeriod, amount: currentPlanBillingAmount });
    const setAvailablePeriods = useSetAtom(availablePeriodsAtom);
    const [availablePlans, setAvailablePlans] = useAtom(availablePlansAtom);
    const [selectedBillingPeriod, setSelectedBillingPeriod] = useAtom(selectedBillingPeriodAtom);
    const [selectedPlan, setSelectedPlan] = useAtom(selectedPlanAtom);
    const [selectedPackages, setSelectedPackages] = useAtom(selectedPackagesAtom);
    const [loadingState, setLoadingState] = useAtom(loadingStateAtom);
    const setEstimates = useSetAtom(estimatesAtom);
    const setSelectedTeamPlanEstimatesTotal = useSetAtom(selectedPlanEstimatesTotalAtom);
    const setSelectedTeamPlanEstimatesSummary = useSetAtom(selectedPlanEstimatesSummaryAtom);
    const setUpgradePlanErrorText = useSetAtom(upgradePlanErrorTextAtom);

    useEffectOnce(() => {
        (async () => {
            const responsePlans = await fetchPlans();

            if (responsePlans) {
                const availablePeriodsToSet = setupAvailablePeriods(responsePlans);
                const tempAvailablePlans = setupAvailablePlans(
                    availablePeriodsToSet,
                    responsePlans,
                    BillingPeriodUnit.YEAR
                );

                setSelectedBillingPeriod({ unit: BillingPeriodUnit.YEAR, amount: 1 });

                const planToSet = setupSelectedPlan(tempAvailablePlans);
                const tempPackages = setupSelectedPackages({ availablePlans: tempAvailablePlans });
                await createCheckoutEstimate({
                    plans: tempAvailablePlans,
                    planToSet,
                    packagesToSet: tempPackages,
                });

                setLoadingState('idle');
            }
        })();
    });

    const setupAvailablePeriods = (responsePlans: IPlan[]): BillingPeriodUnit[] => {
        const availablePeriods: BillingPeriodUnit[] = [];
        responsePlans.forEach((plan) => {
            if (!availablePeriods.includes(plan.billingPeriod.unit)) {
                availablePeriods.push(plan.billingPeriod.unit);
            }
        });
        const availablePeriodsToSet = [...new Set(responsePlans.map((plan) => plan.billingPeriod.unit))];

        setAvailablePeriods(
            availablePeriodsToSet.sort((a, b) => {
                if (a > b) {
                    return -1;
                } else if (a < b) {
                    return 1;
                } else {
                    return 0;
                }
            })
        );

        return availablePeriodsToSet;
    };

    const setupAvailablePlans = (
        availablePeriodsToSet: BillingPeriodUnit[],
        responsePlans: IPlan[],
        billingPeriod: BillingPeriodUnit
    ): IPlan[] => {
        const hasMonthlyPlan = availablePeriodsToSet.some((period) => period === billingPeriod);
        const periodUnitToCompare = !!billingPeriod
            ? billingPeriod
            : hasMonthlyPlan
            ? BillingPeriodUnit.MONTH
            : currentBillingPeriod.unit || availablePeriodsToSet[0];
        const tempAvailablePlans = responsePlans.filter((plan) => plan.billingPeriod.unit === periodUnitToCompare);
        setAvailablePlans(tempAvailablePlans);

        return tempAvailablePlans;
    };

    const setupSelectedPlan = (availablePlans: IPlan[]): IPlan => {
        const planWithSameAmountAndCode: IPlan | undefined = availablePlans.find((plan) => {
            if (
                plan.billingPeriod.amount === selectedBillingPeriod?.amount &&
                plan.billingPeriod.unit === selectedBillingPeriod.unit &&
                plan.code === currentPlanCode
            ) {
                return plan;
            }
        });
        const planToSet = planWithSameAmountAndCode || availablePlans[0];
        setSelectedPlan(planToSet);

        return planToSet;
    };

    const setupSelectedPackages = ({
        availablePlans,
        selectedBillingPeriod,
    }: {
        availablePlans: IPlan[];
        selectedBillingPeriod?: { unit: BillingPeriodUnit; amount: number };
    }): { [key: string]: IPlanPackage & { seatsLeftAfterPackageUpdate: number } } => {
        const hasBillingPeriodSummary = !!currentBillingPeriod.unit && !!currentBillingPeriod.amount;
        const monthlyPlan: IPlan | undefined = availablePlans.find(
            (plan) => plan.billingPeriod.unit === BillingPeriodUnit.MONTH
        );
        const tempBillingPeriod = selectedBillingPeriod
            ? selectedBillingPeriod
            : hasBillingPeriodSummary && monthlyPlan?.billingPeriod
            ? monthlyPlan?.billingPeriod
            : availablePlans[0].billingPeriod;

        const tempPackages = availablePlans
            .filter((plan) =>
                selectedBillingPeriod
                    ? plan.billingPeriod.unit === selectedBillingPeriod.unit
                    : plan.billingPeriod.unit === tempBillingPeriod!.unit
            )
            .reduce((acc, plan) => {
                const seatsLeftAfterPackageUpdate = plan.packages[0].seats - (currentNumberOfSeats ?? 0);
                return {
                    ...acc,
                    [`${plan.billingPeriod.unit}-${plan.code}`]: {
                        ...plan.packages[0],
                        seatsLeftAfterPackageUpdate,
                    },
                };
            }, {});

        setSelectedPackages({ ...tempPackages });

        return { ...tempPackages };
    };

    const fetchPlans = useCallback(async (): Promise<IPlan[]> => {
        if (loadingState === 'idle') setLoadingState('loading');

        try {
            const availablePlans = await getAvailablePlans();

            return availablePlans.plans;
        } catch (error) {
            setNotification('Failed to fetch plans', 'error');
        } finally {
            setLoadingState('idle');
        }

        return [] as IPlan[];
    }, [seatSummary, selectedPlan]);

    const createCheckoutEstimate = useCallback(
        async ({
            plans,
            planToSet,
            packagesToSet = {},
            selectedModalView = ModalView.PICK_PLAN,
            couponCode,
        }: {
            plans?: IPlan[];
            planToSet?: IPlan;
            packagesToSet?: { [key: string]: IPlanPackage };
            selectedModalView?: ModalView;
            couponCode?: string;
        }) => {
            const planToSetOrDefault: IPlan | null = planToSet || selectedPlan;

            if (!planToSetOrDefault) {
                return;
            }
            const selectedPackage =
                packagesToSet[`${planToSetOrDefault.billingPeriod.unit}-${planToSetOrDefault.code}`];

            if (!selectedPackage) {
                return;
            }
            loadingState === 'idle' && setLoadingState('loading');
            const profilesSdk = getZaplifySdk().profiles;
            const createCheckoutEstimate: UpdateSubscriptionDto & { couponCode?: string } = {
                numberOfSeats: selectedPackage.seats,
                billingPeriod: { ...planToSetOrDefault.billingPeriod },
                creditNumber: selectedPackage.credits,
                planCode: planToSetOrDefault.code,
            };
            try {
                // new organization with no subscription
                const estimates: Array<CreatedEstimateDto & { planCode: PlanCode }> = [];

                // call "profilesSdk.billing.createCheckoutEstimate" for each plan in "plansToEstimate"
                // and then set the result to "setSelectedTeamPlanEstimatesAtom"
                await Promise.all(
                    (plans || availablePlans).map(async (plan) => {
                        const selectedPackageToSet = packagesToSet[`${plan.billingPeriod.unit}-${plan.code}`];
                        const numberOfSeats = selectedPackageToSet.seats;
                        const creditNumber = selectedPackageToSet.credits;
                        const tempEstimate = await profilesSdk.billing.createCheckoutEstimate({
                            numberOfSeats,
                            billingPeriod: { ...plan.billingPeriod },
                            creditNumber,
                            planCode: plan.code,
                        });
                        estimates.push({ ...tempEstimate, planCode: plan.code });
                    })
                );
                setEstimates(estimates);
                const estimateToSet = estimates.find((estimate) => estimate.planCode === planToSetOrDefault.code);
                setSelectedTeamPlanEstimatesSummary(estimateToSet);

                // existing organization with subscription
                // As agreed, values from this endpoint will be only used to display the total amount to charge
                // https://zaplify.atlassian.net/browse/ZFY-2309?focusedCommentId=11785
                if (selectedModalView === ModalView.CONFIRM) {
                    const [totalEstimation] = await Promise.all([
                        profilesSdk.subscription.requestEstimateOnSubscriptionUpdate(organizationId, {
                            ...createCheckoutEstimate,
                            couponCode,
                        }),
                    ]);
                    // used to display Total (charged today) values
                    setSelectedTeamPlanEstimatesTotal({
                        ...totalEstimation,
                        planCode: createCheckoutEstimate.planCode,
                    });
                }

                setLoadingState('idle');
            } catch (error) {
                setLoadingState('idle');
                setNotification('Failed to create plan estimate', 'error');
            }
        },
        [selectedPackages, modalView, selectedPlan]
    );

    const handleSetSelectedPlan = (periodUnit: BillingPeriodUnit, planCode: PlanCode) => {
        // setLoadingState('loading');
        setSelectedPlan(
            availablePlans.find((plan) => plan.billingPeriod.unit === periodUnit && plan.code === planCode)
        );
    };

    const handlePurchase = async (couponCode?: string) => {
        if (!selectedPackages || !selectedPlan) return;

        dispatch(updatePaymentStatus(PaymentStatus.PROCESSING));
        dispatch(updateProcessingPaymentType('paid'));
        setUpgradePlanErrorText(null);
        const selectedPackage = selectedPackages[`${selectedPlan.billingPeriod.unit}-${selectedPlan.code}`];
        const profilesSdk = getZaplifySdk().profiles;
        const upgradePlanDTO = {
            numberOfSeats: selectedPackage.seats,
            billingPeriod: { ...selectedPlan.billingPeriod },
            creditNumber: selectedPackage.credits,
            planCode: selectedPlan.code,
            couponCode,
        };

        const errorCallback = (errorMessage) => {
            const errorObject = JSON.parse(errorMessage);
            if (errorObject.data?.message && errorObject.data?.status === 400) {
                const message = errorObject.data?.message;
                dispatch(updatePaymentStatus(PaymentStatus.NONE));
                if (message.includes('Please update your billing address')) {
                    setUpgradePlanErrorText(message);
                }
                if (message.includes('Cannot change the subscription as there is no valid card on file')) {
                    setUpgradePlanErrorText(message);
                }
                dispatch(setNotification(message, 'error'));
            } else {
                throw new Error(errorMessage);
            }
        };

        try {
            const response = await profilesSdk.subscription.requestSubscriptionUpdate(
                organizationId,
                upgradePlanDTO,
                errorCallback
            );

            // response is only undefined when non successful response is returned
            // need to rely on errorCallback to handle the error because without it we don't know status code etc.
            // The state machine should keep the user at checkout with error message displayed in the UI if possible.
            if (response !== undefined) {
                window?.analytics?.track('User Upgraded Plan', {
                    numberOfSeats: selectedPackage?.seats,
                    billingPeriodAmount: selectedPlan?.billingPeriod?.amount,
                    billingPeriodUnit: selectedPlan?.billingPeriod?.unit,
                    creditNumber: selectedPackage?.credits,
                    planCode: selectedPlan.code,
                });
                dispatch(updatePaymentStatus(PaymentStatus.SUCCESS));
            }
        } catch (error: any) {
            dispatch(setNotification('Failed to upgrade plan', 'error'));
            dispatch(updatePaymentStatus(PaymentStatus.NONE));
        } finally {
            refetchCredits();
        }
    };

    const handleCheckout = async (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        selectedPackage: IPlanPackage,
        plan: IPlan
    ) => {
        event.preventDefault();
        if (!selectedPackages || !selectedPlan) return;

        const profilesSdk = getZaplifySdk().profiles;
        const checkoutDTO = getCheckoutDTO(selectedPackage, plan);
        if (!checkoutDTO) return;

        cbInstance.current.openCheckout({
            hostedPage: async () => {
                try {
                    const response = await profilesSdk.billing.createCheckoutNew(checkoutDTO, (error) => {
                        let err = JSON.parse(error);

                        dispatch(setNotification((err as any).data?.message || 'Unknown error', 'error'));
                    });

                    return response.hostedPage;
                } catch (error) {
                    console.log('Error during handleCheckout');
                }
            },
            success(hostedPageId) {
                dispatch(updatePaymentStatus(PaymentStatus.SUCCESS));
                console.log(hostedPageId);
                window?.analytics?.track('User Purchased Plan', {
                    numberOfSeats: checkoutDTO?.numberOfSeats,
                    billingPeriodAmount: checkoutDTO?.billingPeriod?.amount,
                    billingPeriodUnit: checkoutDTO?.billingPeriod?.unit,
                    creditNumber: checkoutDTO?.creditNumber,
                    planCode: plan?.code,
                });
            },
            close: () => {
                console.log('checkout new closed');
            },
            step(step) {
                if (step === 'thankyou_screen') {
                    dispatch(updatePaymentStatus(PaymentStatus.PROCESSING));
                }
            },
        });
    };

    const handleSelectBillingPeriod = async ({
        selectedBillingPeriod,
        availablePeriods,
        currentBillingPeriod,
    }: {
        selectedBillingPeriod: { amount: number; unit: BillingPeriodUnit };
        availablePeriods: BillingPeriodUnit[];
        currentBillingPeriod: { unit: BillingPeriodUnit; amount: number };
    }) => {
        setLoadingState('loading');
        setSelectedBillingPeriod(selectedBillingPeriod);
        let responsePlans: IPlan[] = [];

        try {
            responsePlans = await fetchPlans();
        } catch (error) {
            setLoadingState('idle');
            setSelectedBillingPeriod(currentBillingPeriod);
            return;
        }

        const tempPlans = responsePlans.filter((p) => p.billingPeriod.unit === selectedBillingPeriod.unit);
        setSelectedPlan(tempPlans[0]);
        setupSelectedPackages({ availablePlans: responsePlans, selectedBillingPeriod });
        setAvailablePlans(tempPlans);

        setLoadingState('idle');
    };

    const handleSetSelectedPackage = async (selectedPackage: IPlanPackage, selectedPlanCode: PlanCode) => {
        setLoadingState('loading');

        let packageToUpdate = selectedPackages[`${currentBillingPeriod.unit}-${selectedPlanCode}`];
        const occupiedSeats = currentNumberOfSeats ?? 0;
        packageToUpdate = {
            ...selectedPackage,
            seatsLeftAfterPackageUpdate: selectedPackage.seats - occupiedSeats,
        };
        const updatedPackages = {
            ...selectedPackages,
            [`${selectedBillingPeriod?.unit}-${selectedPlanCode}`]: packageToUpdate,
        };

        setSelectedPackages(updatedPackages);
        setLoadingState('idle');
    };

    return {
        handleSetSelectedPlan,
        handleSetSelectedPackage,
        handlePurchase,
        handleCheckout,
        handleSelectBillingPeriod,
        createCheckoutEstimate,
    };
};

export default useSubscription;
