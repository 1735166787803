import { IQuery, Topics } from '@zaplify/common';
import { MessageDto } from '../dtos';

export class GetMostActiveConversationContactsQuery extends IQuery<string[]> {
    public static override readonly TOPIC = Topics.CAMPAIGNS;
    public readonly userId: string;
    public readonly limit: number;
    public readonly filterByProspectIds?: string[];

    constructor({
        userId,
        limit,
        filterByProspectIds,
    }: {
        userId: string;
        limit: number;
        filterByProspectIds?: string[];
    }) {
        super();
        this.userId = userId;
        this.limit = limit;
        this.filterByProspectIds = filterByProspectIds;
    }
}
