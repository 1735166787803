import { readableBillingFrequencyUnit as readableBillingFrequencyUnitNEW } from '@zaplify/subscriptions';
import { BillingPeriodUnit } from '../enums/billing';
import { webCryptoRandom } from '@zaplify/frontend-common';

const nonInitialModifiers = ['a', 'an', 'and', 'on', 'at'];

export enum TextModifier {
    EXCEPT_NON_INITIAL_ARTICLES = 'except-non-initial-articles',
}

type TextModifierOptions = TextModifier.EXCEPT_NON_INITIAL_ARTICLES;

export function titleCase(str: string | undefined | null, options?: TextModifierOptions): string | null {
    if (!str) {
        return null;
    }

    return str
        .toLowerCase()
        .split(' ')
        .map(function (word) {
            if (
                !word[0] ||
                (options && options === TextModifier.EXCEPT_NON_INITIAL_ARTICLES && nonInitialModifiers.includes(word))
            ) {
                return word;
            }
            return word.replace(word[0], word[0].toUpperCase());
        })
        .join(' ');
}

export function generateRandomLetter() {
    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

    return alphabet[Math.floor(webCryptoRandom() * alphabet.length)];
}

export function toUpperCase(text: string) {
    return text.charAt(0).toUpperCase() + text.slice(1);
}

/**
 * @deprecated Please use the period.util functions from @zaplify/subscriptions instead
 * @param billingPeriod
 * @param billingPeriodUnit
 * @returns
 */
export function readableBillingFrequencyUnit(
    billingPeriod: number | undefined,
    billingPeriodUnit: BillingPeriodUnit | undefined
): 'monthly' | 'quarterly' | 'half-year' | 'annually' {
    return readableBillingFrequencyUnitNEW(billingPeriod, billingPeriodUnit);
}

export const capitalizeFirstLetters = (word) => {
    if (!word || word === '') return '';
    return word
        .split(' ')
        .map((str) => (str = str.charAt(0).toUpperCase() + str.substring(1).toLowerCase()))
        .join(' ');
};

export function replaceNewLinesWithBreaks(inputString: string): string {
    inputString = inputString.replace(/\r\n/g, '<br />');
    inputString = inputString.replace(/\\r/g, '');
    inputString = inputString.replace(/\r/g, '');
    inputString = inputString.replace(/\n/g, '<br />');
    inputString = inputString.replace(/\\n/g, '<br />');

    return inputString;
}

const NUMBER_OF_PRECEDING_CHARACTERS_TO_CHECK = 20;
/**
 * Converts plain text URLs in the input string to HTML anchor tags.
 *
 * This function processes the input text to identify URLs and converts them into clickable anchor tags with
 * specific styles. If the input text contains HTML content, it modifies existing anchor and span tags
 * to ensure consistent styling. If the input text contains meta or head tags, it skips processing.
 *
 * @param {string} inputText - The input string potentially containing URLs or HTML content.
 * @returns {string} - The processed string with URLs converted to anchor tags or modified HTML content.
 */
export function convertLinksToAnchors(inputText: string): string {
    const linkRegex = /<a\s+(?!(?:[^>]*?\s+)?href=(["']?)mailto:)/gi;

    // This modifies existing anchor and span tags to ensure consistent styling
    const modifiedText = inputText
        .replace(
            linkRegex,
            '<a target="_blank" style="color: #6583ef; text-decoration: none" onmouseover="this.style.textDecoration=\'underline\'" onmouseout="this.style.textDecoration=\'none\'" '
        )
        .replace(/<span[^>]*style="[^"]*color:[^"]*"[^>]*>/gi, '<span style="color: inherit">');

    const appAwareLink = (url) =>
        `<a target="_blank" href="${url}" style="color: #6583ef; text-decoration: none" 
                onmouseover="this.style.textDecoration='underline'" 
                onmouseout="this.style.textDecoration='none'">${url}</a>`;

    const tokens = modifiedText.split(/(<[^>]+>)/g);

    for (let i = 0; i < tokens.length; i++) {
        if (!tokens[i].startsWith('<')) {
            tokens[i] = tokens[i].replace(/(?<!["'=\w@])(https?:\/\/[^\s<>"']+)/g, (match) => {
                return appAwareLink(match);
            });
        }
    }

    return tokens.join('');
}
