import { IEvent, Topics } from '@zaplify/common';
import { ChannelProvider, ChannelType } from '../types';

export class ChannelAccountDisconnectedEvent extends IEvent {
    public static override readonly TOPIC = Topics.CHANNEL_ACCOUNTS;

    constructor(
        readonly userId: string,
        readonly organizationId: string,
        readonly channelAccountId: string,
        readonly type: ChannelType,
        readonly provider: ChannelProvider,
    ) {
        super();
    }

    get partitionKey(): string {
        return this.channelAccountId;
    }
}
