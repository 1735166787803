import { Skeleton } from '@shadcn/ui/components/ui/skeleton';
import { motion } from 'motion/react';
import { cn } from '@shadcn/ui/lib/utils';

export const ActionCardSkeleton = () => {
    return (
        <motion.div
            className="flex w-full justify-center"
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.05 }}
            layout
        >
            <div className={cn(`flex justify-center h-fit w-full`)}>
                <Skeleton className="w-full h-32 bg-background-secondary rounded-xl" />
            </div>
        </motion.div>
    );
};
