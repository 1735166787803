import { useLocation, useNavigate } from 'react-router-dom';

export const useSearchParams = () => {
    const navigate = useNavigate();
    const { search, pathname } = useLocation();
    const searchParams = new URLSearchParams(search);

    const setSearchParams = (name: string, value: string) => {
        searchParams.set(name, value);
        navigate({
            pathname: pathname,
            search: searchParams.toString(),
        });
    };

    const deleteSearchParams = (name: string) => {
        searchParams.delete(name);
        navigate({
            pathname: pathname,
            search: searchParams.toString(),
        });
    };
    return { searchParams, setSearchParams, deleteSearchParams };
};
