import { useEffect, useMemo, useState } from 'react';
import { SearchHistory } from './global-search/search-history';
import {
    AccountPreviewRow,
    PersonPreviewData,
    LinkedinPersonPreviewRow,
    ContactPreviewRow,
    SearchPreviewRowRoot,
    SearchResultType,
} from './global-search/search-preview-row';
import { SearchResults } from './global-search/search-result';
import { Avatar, AvatarFallback, AvatarImage } from '@shadcn/ui/components/ui/avatar';
import { getInitials } from '../../functions/get-initials';
import { ArrowLeft, Loader2, SearchIcon, XIcon } from 'lucide-react';
import { PersonDetailCard } from './global-search/person-detail-card';
import { cn } from '@shadcn/ui/lib/utils';
import { SearchBoxLayout } from './global-search/search-box-layout';
import { Dialog, DialogTrigger, DialogContent, DialogTitle, DialogDescription } from '@shadcn/ui/components/ui/dialog';
import { Input } from '@shadcn/ui/components/ui/input';
import { AccountPreview } from './global-search/account-detail-card';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import { paths } from '../../../routes/paths';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useSdk } from '../../sdk/use-sdk';
import { PeopleResponseDto } from '@zaplify/prospects';
import { GlobalSearchTypes } from '../../sdk/internal/global-search.sdk';
import { useLinkedinGlobalSearch } from './global-search/hooks/use-global-search-linkedin';
import { useSearchHistory } from './global-search/hooks/use-search-history';
import { Button } from '@shadcn/ui/components/ui/button';
import { SearchParamDialog, useSearchParamDialog } from '@shadcn/ui/components/search-param-dialog';
import { useIsMobile } from '@shadcn/ui/hooks/use-mobile';

function useDebounce<T>(value: T, delay: number = 300, onDebounce?: () => void): T {
    const [debouncedValue, setDebouncedValue] = useState<T>(value);

    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedValue(value);
            onDebounce && onDebounce();
        }, delay);
        return () => clearTimeout(timer);
    }, [value, delay]);

    return debouncedValue;
}

const GLOBAL_SEARCH_PARAM = 'global-search';
const GLOBAL_SEARCH_QUERY_PARAM = 'global-search-query';

export const useGlobalSearchDialog = () => {
    const { open, close, isOpen } = useSearchParamDialog(GLOBAL_SEARCH_PARAM);

    return {
        openGlobalSearch: () => open(),
        closeGlobalSearch: close,
        isOpen,
    };
};

export function GlobalSearchDialog() {
    const { isOpen } = useGlobalSearchDialog();
    const [selectedResult, setSelectedResult] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchQuery, setSearchQuery] = useState(searchParams.get(GLOBAL_SEARCH_QUERY_PARAM) || '');
    const MIN_SEARCH_QUERY_LENGTH = 3;
    const [showGlobalLoading, setShowGlobalLoading] = useState(false);
    const debouncedSearchQuery = useDebounce(searchQuery, 300);
    const isMobile = useIsMobile();
    const {
        globalSearch: { search },
    } = useSdk();
    const navigate = useNavigate();
    const { updateSearchHistory } = useSearchHistory();

    const { data: contactResults, isLoading: isLoadingContacts } = useQuery(
        search(
            { match: debouncedSearchQuery, page: 1, perPage: 10, searchIn: [GlobalSearchTypes.CONTACTS] },
            { enabled: debouncedSearchQuery.length >= MIN_SEARCH_QUERY_LENGTH }
        )
    );
    const { data: peopleResults, isLoading: isLoadingPeople } = useQuery(
        search(
            { match: debouncedSearchQuery, page: 1, perPage: 10, searchIn: [GlobalSearchTypes.PEOPLE] },
            { enabled: debouncedSearchQuery.length >= MIN_SEARCH_QUERY_LENGTH }
        )
    );
    const { data: accountsResults, isLoading: isLoadingAccounts } = useQuery(
        search(
            { match: debouncedSearchQuery, page: 1, perPage: 10, searchIn: [GlobalSearchTypes.ACCOUNTS] },
            { enabled: debouncedSearchQuery.length >= MIN_SEARCH_QUERY_LENGTH }
        )
    );
    const { data: companyNamesResults, isLoading: isLoadingCompanyNames } = useQuery(
        search(
            { match: debouncedSearchQuery, page: 1, perPage: 10, searchIn: [GlobalSearchTypes.COMPANY_NAMES] },
            { enabled: debouncedSearchQuery.length >= MIN_SEARCH_QUERY_LENGTH }
        )
    );
    const { searchLinkedinProspectQuery } = useLinkedinGlobalSearch(debouncedSearchQuery, {
        enabled: debouncedSearchQuery.length >= MIN_SEARCH_QUERY_LENGTH,
    });
    const { data: linkedinProfilesResults, isLoading: isLoadingLinkedinProfiles } = useQuery(
        searchLinkedinProspectQuery()
    );

    useEffect(() => {
        setSelectedResult(null);
    }, [debouncedSearchQuery]);

    useEffect(() => {
        if (selectedResult) {
            updateSearchHistory(debouncedSearchQuery);
        }
    }, [selectedResult]);

    useEffect(() => {
        setShowGlobalLoading(true);
        const timer = setTimeout(() => {
            setShowGlobalLoading(false);
        }, 1700);

        return () => clearTimeout(timer);
    }, [debouncedSearchQuery, searchQuery]);

    useEffect(() => {
        setSearchParams((prevParams) => {
            const newParams = new URLSearchParams(prevParams);
            if (!isOpen) {
                newParams.delete(GLOBAL_SEARCH_QUERY_PARAM);
            } else if (debouncedSearchQuery) {
                newParams.set(GLOBAL_SEARCH_QUERY_PARAM, debouncedSearchQuery);
            } else {
                newParams.delete(GLOBAL_SEARCH_QUERY_PARAM);
            }
            return newParams;
        });
    }, [debouncedSearchQuery, setSearchParams, isOpen]);

    const createPersonResult: (person: PeopleResponseDto) => PersonPreviewData = useMemo(
        () => (person: PeopleResponseDto) => ({
            personId: person._id,
            type: 'person' as SearchResultType,
            title: person.fullName,
            subtitle: person.title,
            place: person.companyName,
            icon: (
                <Avatar>
                    <AvatarImage src={person.linkedinProfileImgUrl} />
                    <AvatarFallback>{getInitials(person.fullName)}</AvatarFallback>
                </Avatar>
            ),
        }),
        []
    );

    const contactResult = useMemo(() => {
        return contactResults?.contacts?.data?.map((contact) => (
            <ContactPreviewRow
                prospectId={contact.prospectId}
                onClick={() => {
                    navigate(`${paths.NEW.MESSAGES}/${contact.prospectId}`);
                }}
            />
        ));
    }, [contactResults]);

    const peopleResult = useMemo(() => {
        return peopleResults?.people?.data?.map((person) => {
            const personResult = createPersonResult(person);
            return (
                <SearchPreviewRowRoot
                    result={personResult}
                    onClick={() =>
                        setSelectedResult(
                            <PersonDetailCard
                                prospectId={''}
                                personId={person._id}
                                linkedinUrl={''}
                                linkedinProfileImgUrl={person.linkedinProfileImgUrl}
                                fullName={person.fullName}
                                occupationTitle={person.title}
                                account={{
                                    name: person.companyName,
                                    industry: person.companyIndustry,
                                    website: person.companyWebsite,
                                    size: '',
                                }}
                                location={person.location}
                                skills={[]}
                            />
                        )
                    }
                />
            );
        });
    }, [peopleResults]);

    const linkedinProfilesResult = useMemo(() => {
        return linkedinProfilesResults?.map((person) => (
            <LinkedinPersonPreviewRow
                linkedinUrl={person.linkedinProfileUrl}
                onClick={() =>
                    setSelectedResult(
                        <PersonDetailCard
                            prospectId={''}
                            personId={''}
                            linkedinUrl={person.linkedinProfileUrl}
                            linkedinProfileImgUrl={person.linkedinProfileImgUrl}
                            fullName={person.fullName}
                            occupationTitle={person.occupationTitle}
                            account={{
                                name: person.organizationName,
                                industry: person.organizationIndustry,
                                website: person.organizationDomain,
                                size: person.organizationSize,
                            }}
                            location={person.location}
                            skills={[]}
                        />
                    )
                }
            />
        ));
    }, [linkedinProfilesResults]);

    const accountsResult = useMemo(() => {
        return accountsResults?.accounts?.data?.map((account) => (
            <AccountPreviewRow
                accountId={account.id}
                onClick={() => setSelectedResult(<AccountPreview accountId={account.id} />)}
                accountData={{ name: account.name, industry: account.industry, website: account.website }}
            />
        ));
    }, [accountsResults]);

    const nameMatchingAccountsResult = useMemo(() => {
        return companyNamesResults?.companyNames?.data?.map((account) => (
            <AccountPreviewRow
                accountId={account.id}
                onClick={() => setSelectedResult(<AccountPreview accountId={account.id} />)}
                accountData={{ name: account.name, industry: account.industry, website: account.website }}
            />
        ));
    }, [companyNamesResults]);

    const searchResults = useMemo(() => {
        const results = [
            {
                sectionTitle: 'LinkedIn Profiles',
                results: linkedinProfilesResult,
                isLoading: isLoadingLinkedinProfiles,
            },
            {
                sectionTitle: 'Contacts',
                results: contactResult,
                isLoading: isLoadingContacts,
            },
            {
                sectionTitle: 'People',
                results: peopleResult,
                isLoading: isLoadingPeople,
            },
            {
                sectionTitle: 'Accounts',
                results: accountsResult,
                isLoading: isLoadingAccounts,
            },
            {
                sectionTitle: 'Similar Account Names',
                results: nameMatchingAccountsResult,
                isLoading: isLoadingCompanyNames,
            },
        ];

        // Sort results to move sections with zero results to the bottom
        return results.sort((a, b) => (a.results?.length === 0 ? 1 : 0) - (b.results?.length === 0 ? 1 : 0));
    }, [
        contactResult,
        peopleResult,
        accountsResult,
        nameMatchingAccountsResult,
        linkedinProfilesResult,
        isLoadingContacts,
        isLoadingPeople,
        isLoadingAccounts,
        isLoadingCompanyNames,
        isLoadingLinkedinProfiles,
    ]);

    return (
        <SearchParamDialog param={GLOBAL_SEARCH_PARAM}>
            <DialogContent
                className={cn(
                    'flex flex-col fixed-size overflow-hidden',
                    'h-[80vh] max-h-[800px] p-0 gap-0',
                    'animate-in fade-in-0 slide-in-from-left-1/3 duration-200 ease-in-out',
                    {
                        'max-w-[900px]': selectedResult,
                        'max-w-[600px]': !selectedResult,
                        'absolute top-[3.5rem] left-0 translate-x-0 translate-y-0 h-[calc(100dvh-3.5rem)] w-full':
                            isMobile,
                    }
                )}
            >
                <DialogDescription></DialogDescription>
                <VisuallyHidden>
                    <DialogTitle>Search</DialogTitle>
                </VisuallyHidden>
                <div className="px-2 py-3 border-b flex flex-row items-center">
                    {isMobile && selectedResult ? (
                        <Button variant="outline" size="sm" onClick={() => setSelectedResult(null)}>
                            <ArrowLeft className="w-5 h-5" />
                        </Button>
                    ) : (
                        <SearchIcon className="w-5 h-5 ml-2 text-text-tertiary" />
                    )}
                    <Input
                        placeholder="Search Contacts, People, Companies or LinkedIn URLs..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="border-none ring-0 shadow-none focus-visible:ring-0 text-sm md:text-base"
                    />
                    {searchQuery.length > 0 && (
                        <Button
                            variant="text"
                            className="ml-2 mr-6 text-text-tertiary"
                            onClick={() => setSearchQuery('')}
                        >
                            Clear
                        </Button>
                    )}
                </div>
                <SearchBoxLayout
                    leftSide={
                        <div className="h-full overflow-y-auto">
                            {searchQuery.length < MIN_SEARCH_QUERY_LENGTH ? (
                                <SearchHistory onClick={setSearchQuery} />
                            ) : showGlobalLoading ? (
                                <div className="flex items-center justify-center py-4">
                                    <Loader2 className="h-10 w-10 animate-spin" />
                                </div>
                            ) : (
                                <>
                                    {searchResults.map((result) => (
                                        <SearchResults
                                            key={result.sectionTitle}
                                            sectionTitle={result.sectionTitle}
                                            results={result.results}
                                            isLoading={result.isLoading}
                                        />
                                    ))}
                                </>
                            )}
                        </div>
                    }
                    rightSide={selectedResult}
                />
            </DialogContent>
        </SearchParamDialog>
    );
}
