import React from 'react';
import { useEffect, useRef } from 'react';

interface ShadowContentProps {
    content: string;
    className?: string;
}

export const ShadowContent = React.forwardRef<HTMLDivElement, ShadowContentProps & React.ComponentProps<'div'>>(
    ({ content, className, ...props }, ref) => {
        useEffect(() => {
            const currentRef = ref && typeof ref === 'object' && 'current' in ref ? ref.current : null;
            if (!currentRef) return;

            let shadowRoot = currentRef.shadowRoot;

            if (!shadowRoot) {
                shadowRoot = currentRef.attachShadow({ mode: 'open' });
                const style = document.createElement('style');
                style.textContent = `
                    .content-wrapper,
                    .content-wrapper * {
                        white-space: normal !important;
                        word-break: normal !important;
                        overflow-wrap: break-word !important;
                        width: 100%;
                        max-width: 100%;
                        overflow-x: hidden;
                    }
                    img {
                        max-width: 100%;
                        height: auto;
                    }
                    img[style]:not([width="100%"]) {
                        max-width: min(100%, attr(width));
                        object-fit: contain;
                    }
                `;
                shadowRoot.appendChild(style);

                const wrapper = document.createElement('div');
                wrapper.className = 'content-wrapper';
                wrapper.setAttribute('part', 'content-wrapper');
                wrapper.innerHTML = content;
                shadowRoot.appendChild(wrapper);
            }
        }, [content]);

        return <div className={className} ref={ref}></div>;
    }
);
