import { FC, useState } from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@shadcn/ui/components/ui/card';
import { Button } from '@shadcn/ui/components/ui/button';
import { Input } from '@shadcn/ui/components/ui/input';
import { Label } from '@shadcn/ui/components/ui/label';
import { useToast } from '@shadcn/ui/hooks/use-toast';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { useMutation } from '@tanstack/react-query';
import { useSdk } from '../../../sdk';
import { UserDto } from '@zaplify/users/shared';
import { useAuth } from '../../../providers/authentication-provider';
import { Loader2 } from 'lucide-react';
import { ReferralLinkField } from '../../../components/dialogs/referral-link-field';

const formSchema = z.object({
    firstName: z.string().min(1, 'First name is required'),
    lastName: z.string().min(1, 'Last name is required'),
    email: z.string().email('Invalid email address'),
});

type FormData = z.infer<typeof formSchema>;

export const ProfilePage: FC = () => {
    const { toast } = useToast();
    const {
        authState: { user },
        refreshOnboardingState,
    } = useAuth();
    const userData = user as unknown as UserDto;
    const sdk = useSdk();

    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
    } = useForm<FormData>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            firstName: userData.firstName || '',
            lastName: userData.lastName || '',
            email: userData.email || '',
        },
    });

    const { mutateAsync: updateUserSettings } = useMutation(sdk.user.updateUserSettings());
    const updateProfile = useMutation({
        mutationFn: (data: FormData) =>
            updateUserSettings({
                userId: userData.id,
                data: {
                    ...data,
                    email: '', // Updating email not supported yet
                },
            }),
        onSuccess: () => {
            refreshOnboardingState();
            toast({
                title: 'Profile updated',
                description: 'Your profile has been updated successfully.',
            });
        },
        onError: (error: any) => {
            toast({
                title: 'Error',
                description: 'Failed to update profile. Please try again.',
                variant: 'destructive',
            });
        },
    });

    const onSubmit = (data: FormData) => {
        updateProfile.mutate(data);
    };

    return (
        <div className="container mx-auto py-10 max-w-lg space-y-4">
            <Card className="w-full">
                <CardHeader className="space-y-1">
                    <CardTitle>Profile Settings</CardTitle>
                    <CardDescription>Update your account settings</CardDescription>
                </CardHeader>
                <CardContent>
                    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
                        <div className="grid grid-cols-2 gap-4">
                            <div className="space-y-2">
                                <Label htmlFor="firstName">First Name</Label>
                                <Input id="firstName" {...register('firstName')} className="px-3 py-2 h-auto" />
                                {errors.firstName && <p className="text-sm text-red-500">{errors.firstName.message}</p>}
                            </div>
                            <div className="space-y-2">
                                <Label htmlFor="lastName">Last Name</Label>
                                <Input id="lastName" {...register('lastName')} className="px-3 py-2 h-auto" />
                                {errors.lastName && <p className="text-sm text-red-500">{errors.lastName.message}</p>}
                            </div>
                        </div>
                        {/* Email field hidden until email updates are supported
                        <div className="space-y-2">
                            <Label htmlFor="email">Email</Label>
                            <Input id="email" type="email" {...register('email')} className="px-3 py-2 h-auto" />
                            {errors.email && <p className="text-sm text-red-500">{errors.email.message}</p>}
                        </div>
                        */}
                        <Button type="submit" disabled={updateProfile.isPending}>
                            {updateProfile.isPending && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                            Save Changes
                        </Button>
                    </form>
                </CardContent>
            </Card>

            <Card className="w-full">
                <CardHeader className="space-y-1">
                    <CardTitle>Referral Link</CardTitle>
                    <CardDescription>Share this link to invite others to Andsend.</CardDescription>
                </CardHeader>
                <CardContent>
                    <ReferralLinkField />
                </CardContent>
            </Card>
        </div>
    );
};
