import { Menu } from '@mui/material';
import { styled } from '@mui/styles';

export const MenuContainer = styled(({ className, ...props }) => (
    <Menu getContentAnchorEl={null} {...props} classes={{ paper: className }} />
))(({ theme }) => ({
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    width: 309,
    marginTop: '52px',
    '&.no-margin': {
        marginTop: 0,
    },
    border: `1px solid ${theme.palette.colors.greyInformationLight}`,
    boxShadow: '0px 2px 7px rgba(112, 144, 176, 0.14)',
    borderRadius: 8,
    '& .user-data-container': {
        display: 'flex',
        flexDirection: 'row',
        margin: '20px 18px 20px 15px',
        '& .user-data': {
            flexDirection: 'column',
            marginLeft: 10,
            textTransform: 'capitalize',
            cursor: 'default',
            '& .name': {
                color: theme.palette.colors.black,
                fontWeight: '500',
                fontSize: 15,
            },
            '& .company': {
                color: theme.palette.colors.greyDark,
                fontSize: 14,
            },
        },
    },
    '& .divider': {
        margin: '10px 0',
        background: theme.palette.colors.greyInformationLight,
    },
    '& .menu-item-link': {
        textDecoration: 'none',
    },
    '& .menu-item': {
        width: 290,
        height: 42,
        justifySelf: 'center',
        borderRadius: 8,
        '&:hover': {
            background: theme.palette.colors.greySelect,
        },
        '&:hover .icon': {
            color: theme.palette.colors.bluePale,
        },
        '& .text': {
            '& > span': {
                fontSize: 14,
                fontWeight: 500,
                color: theme.palette.colors.subline,
            },
            '&.MuiListItemText-root': {
                margin: 0,
            },
        },
        '& .icon': {
            color: '#96A2AC',
            '&.blocklist': {
                '& .MuiSvgIcon-root': {
                    fontSize: 20,
                },
            },
            '&.integrations': {
                '& .MuiSvgIcon-root': {
                    marginLeft: '-2px',
                },
            },
            '&.webhooks': {
                '& .MuiSvgIcon-root': {
                    marginLeft: '2px',
                    fontSize: 19,
                },
            },
            '&.blog': {
                '& .MuiSvgIcon-root': {
                    fontSize: 20,
                },
            },
            '&.help': {
                '& .MuiSvgIcon-root': {
                    fontSize: 21,
                },
            },
            '&.logout': {
                '& .MuiSvgIcon-root': {
                    paddingBottom: '4px',
                    marginLeft: '2px',
                    fontSize: 21,
                },
            },
        },
        '& .MuiListItemIcon-root': {
            minWidth: 40,
        },
    },
    '& .privacy': {
        margin: '13px 0 13px 16px ',
        color: theme.palette.colors.greyDark,
        textDecorationLine: 'underline',
        fontSize: 12,
        cursor: 'pointer',
    },
}));
