import { IQuery } from '@zaplify/common';
import { Topics } from '@zaplify/common';

export class GetCompanyOfferingSummaryQuery extends IQuery<string | null> {
    public static override readonly TOPIC = Topics.USERS;
    public readonly userId: string;
    public readonly userOrganizationId: string;

    constructor({ userId, userOrganizationId }: { userId: string; userOrganizationId: string }) {
        super();
        this.userId = userId;
        this.userOrganizationId = userOrganizationId;
    }
}
