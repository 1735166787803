import { ReactNode, useEffect, useState } from 'react';
import { useAppSelector } from '../redux/store/configureStore';
import { MessagesSubscription } from './subscriptions/messages-subscription';
import { OutreachSuggestionsSubscription } from './subscriptions/outreach-suggestions-subscription';
import { UserContactsSubscription } from './subscriptions/user-contacts-subscription';
import { GroupsSubscription } from './subscriptions/groups-subscription';
import { AssistantSettingsSubscription } from './subscriptions/assistant-settings-subscription';
import useChannelAccountData from '../views/chats/hooks/use-channel-account-data';

const ChannelAccountsProvider = () => {
    const { refetchChannelAccounts } = useChannelAccountData();
    // refetchChannelAccounts().catch((e) => {
    //     console.error('Failed to refetch channel accounts', e);
    // });
    return null;
};

const SubscriptionComponents = () => (
    <>
        <OutreachSuggestionsSubscription />
        <MessagesSubscription />
        <UserContactsSubscription />
        <GroupsSubscription />
        <AssistantSettingsSubscription />
        <ChannelAccountsProvider />
    </>
);

export const SubscriptionsProvider = ({ children }: { children?: ReactNode }) => {
    const user = useAppSelector((state) => state.user.zaplifyUser);
    const [userId, setUserId] = useState<string | undefined>(user?.id || undefined);

    useEffect(() => {
        setUserId(user?.id || undefined);
    }, [user?.id]);

    return (
        <>
            {userId && (
                <>
                    <SubscriptionComponents />
                </>
            )}
            {children}
        </>
    );
};
