import { FC } from 'react';
import { useThread } from '@assistant-ui/react';

import { ThreadWelcome as ThreadWelcomePrimitive, useThreadConfig } from '@assistant-ui/react-ui';

export const ThreadWelcome: FC = () => {
    const { welcome } = useThreadConfig();
    const suggestions = useThread((t) => t.suggestions);
    console.log(welcome, suggestions);

    return (
        <ThreadWelcomePrimitive.Root>
            <ThreadWelcomePrimitive.Center>
                <ThreadWelcomePrimitive.Avatar />
                <ThreadWelcomePrimitive.Message />
                <ThreadWelcomePrimitive.Suggestions />
            </ThreadWelcomePrimitive.Center>
        </ThreadWelcomePrimitive.Root>
    );
};

export default ThreadWelcome;
