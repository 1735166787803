export const legalEntities = {
    Sweden: ['ab', 'aktiebolag', 'hb', 'handelsbolag', '(publ)'],
    Denmark: ['as', 'a/s', 'a.s'],
    'United States': ['llc', 'limited partnership', 'limited', 'ltd', 'ltd.', 'p.l.c', 'plc', 'corp.', 'pllc', 'inc'],
};

export function cleanCompanyData(original: string | null, legalEntityNames = legalEntities): string | null {
    if (!original) return null;
    let organizationName = JSON.parse(JSON.stringify(original));

    organizationName = removeInAndAfterParenthesis(organizationName);
    // organizationName = removeAfterCharachters(organizationName, ',') // @NOTE @INVESTIGATING without it!
    organizationName = removeAfterString(organizationName, ' [–-] ');
    organizationName = removeAllSymbolsExcept(organizationName, "\\w!@=^?+/.&'-:");

    // Removes legal entities (last word, or followed by step)
    for (const country in legalEntityNames) {
        for (const leg of legalEntityNames[country]) {
            organizationName = removeSuffix(organizationName, leg + '\\.?'); // Allow for "." after suffix
        }
    }

    return organizationName.trim();
}

function removeInAndAfterParenthesis(string: string) {
    return string.replace(/\s\(.*?\)\s*.*/g, ' ');
}

/**
 * Removes everything after string (including string)
 * @param string original
 * @param toRemoveFrom string to remove after
 */
function removeAfterString(string: string, toRemoveFrom: string) {
    let re = new RegExp(`${toRemoveFrom}.*`, 'ui');
    return string.replace(re, ' ');
}

function removeAllSymbolsExcept(string: string, toKeep: string) {
    let re = new RegExp(`[^\\p{L}\\s${toKeep}]`, 'gui');
    return string.replace(re, ' ');
}

/**
 * Removes ending of string
 */
function removeSuffix(string: string, suffixRegex: string) {
    let re = new RegExp(`\\s+${suffixRegex}\\s*$`, 'ui'); // Allows for whitespaces + "suffix" + whitespaces + End Of String
    return string.replace(re, '');
}
