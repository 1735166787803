import { ProspectSubStatus } from '@zaplify/campaigns';
import { ProspectStatus } from '@zaplify/campaigns';
import { CampaignProspectDto } from '@zaplify/campaigns';
import { ProspectDataEducationDto } from '@zaplify/prospects';
import { ProspectDataExperienceDto } from '@zaplify/prospects';

export function getProspectExperiencesSorted(a: ProspectDataExperienceDto, b: ProspectDataExperienceDto) {
    if (!a.occupationEndDate && !b.occupationEndDate) {
        return new Date(b.occupationStartDate || 0).valueOf() - new Date(a.occupationStartDate || 0).valueOf();
    }
    if (!a.occupationEndDate) {
        return -1;
    }
    if (!b.occupationEndDate) {
        return 1;
    }
    return new Date(b.occupationEndDate || 0).valueOf() - new Date(a.occupationEndDate || 0).valueOf();
}

export function getProspectEducationSorted(a: ProspectDataEducationDto, b: ProspectDataEducationDto) {
    if (!a.endDate && !b.endDate) {
        return new Date(b.startDate || 0).valueOf() - new Date(a.startDate || 0).valueOf();
    }
    if (!a.endDate) {
        return -1;
    }
    if (!b.endDate) {
        return 1;
    }
    return new Date(b.endDate || 0).valueOf() - new Date(a.endDate || 0).valueOf();
}

export const getLengthOfExperienceInPreviousCompany = (
    start: string | null | undefined,
    end: string | null | undefined
): string | null => {
    // TODO: verify if this is correct
    if (!start) {
        return null;
    }

    if (start === 'unknown' || end === 'unknown') {
        return null;
    }

    const startYear = new Date(start).getFullYear();
    const endYear = new Date(end || new Date().toLocaleString()).getFullYear();

    const startDate = new Date(start).toLocaleString('en-US', { year: 'numeric', month: 'short' });
    const endDate = !end
        ? 'Present'
        : new Date(end || new Date().toLocaleString()).toLocaleString('en-US', {
              year: startYear !== endYear ? 'numeric' : undefined,
              month: 'short',
          });

    let difference = {
        years: 0,
        months: 0,
    };

    if (!end) {
        difference = getNumberOfYearsAndMonthsBetweenTwoDates(new Date(start), new Date());
    }
    if (!!end) {
        difference = getNumberOfYearsAndMonthsBetweenTwoDates(new Date(start), new Date(end));
    }

    const years = `${difference.years} yr${difference.years > 1 ? 's ' : ' '}`;
    const months = `${difference.months} mo${difference.months > 1 ? 's' : ''}`;

    return `${startDate} - ${endDate} · ${difference.years > 0 ? years : ''}${difference.months > 0 ? months : ''}`;
};

export const getNumberOfYearsAndMonthsBetweenTwoDates = (start: Date, end: Date): { years: number; months: number } => {
    let years = end.getFullYear() - start.getFullYear();
    let months = end.getMonth() - start.getMonth();

    if (months < 0) {
        years--;
        months += 12;
    }

    return { years, months };
};
