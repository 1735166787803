import { Button } from '@shadcn/ui/components/ui/button';
import { Link, useLocation } from 'react-router-dom';
import { paths } from '../../routes/paths';
import { useToast } from '@shadcn/ui/hooks/use-toast';
import { useEffect } from 'react';

export const NotFoundPage = () => {
    const { toast } = useToast();
    const location = useLocation();

    useEffect(() => {
        // Check if there was an error message passed through location state
        if (location.state?.errorMessage) {
            toast({
                title: 'Error',
                description: location.state.errorMessage,
                variant: 'destructive',
            });
        }
    }, [toast, location.state]);

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-background px-4">
            <div className="flex flex-col items-center max-w-md text-center space-y-6">
                <div className="space-y-2">
                    <h1 className="text-4xl font-bold tracking-tighter sm:text-5xl text-primary">404</h1>
                    <h2 className="text-3xl font-semibold tracking-tight">Page not found</h2>
                    <p className="text-muted-foreground">
                        Sorry, the page you're looking for doesn't exist or has been moved.
                    </p>
                </div>

                <div className="space-y-3">
                    <Button asChild className="min-w-[160px]">
                        <Link to={paths.NEW.ACTIONS}>Go to Dashboard</Link>
                    </Button>

                    <div>
                        <Button asChild variant="outline" className="min-w-[160px]">
                            <Link to={paths.NEW_LOGIN}>Sign In</Link>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotFoundPage;
