import { useEffect } from 'react';
import { useDispatch } from 'react-redux-latest';
import { getPipedriveFields, getPipedriveSettings } from '../../../../redux/ducks/pipedrive/operations';
import { AppDispatch } from '../../../../redux/store/configureStore';
import { Configuration } from './components/configuration';
import { FieldMap } from './components/fieldMap';
import { TopBar } from './components/topBar';
import { BodyContainer, PipedriveContainer, ViewContainer } from './containers';

const PipedriveView = () => {
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            dispatch(getPipedriveSettings());
            dispatch(getPipedriveFields());
        }
        return () => {
            isMounted = false;
        };
    }, []);

    return (
        <ViewContainer>
            <PipedriveContainer>
                <TopBar />
                <BodyContainer>
                    <FieldMap />
                    <Configuration />
                </BodyContainer>
            </PipedriveContainer>
        </ViewContainer>
    );
};

export default PipedriveView;
