import { useAuth } from '../providers/authentication-provider';
import { Button } from '@shadcn/ui/components/ui/button';
import { useNavigate } from 'react-router-dom';
import NoSeatsLeftInfoDialog from '../components/dialogs/no-seats-left-dialog';
import { useState } from 'react';
import { useToast } from '@shadcn/ui/hooks/use-toast';
import { useChargebeeSubscription } from './account-management/hooks/use-chargebee-subscription';
import { SubscriptionStatus } from '@zaplify/subscriptions';
import { getZaplifySdk } from '@zaplify/sdk';
import { v4 as uuid4 } from 'uuid';

export const NoSeatPage = () => {
    const {
        authState: { user },
        logout,
    } = useAuth();

    const { toast } = useToast();
    const [loading, setLoading] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const { subscription, isLoadingSubscription, refetchSubscription } = useChargebeeSubscription();

    // User comes from cancelled subscription
    // User is invited to the org but have not yet been assigned a seat
    //
    const isCancelled = subscription?.subscription?.status === SubscriptionStatus.CANCELLED;

    const createFreeSubscription = async () => {
        const profilesSdk = getZaplifySdk().profiles;

        await profilesSdk.billing.createFreeSubscription({ 'idempotency-key': uuid4() });
    };

    const handleReactivate = () => {
        setLoading(true);
        createFreeSubscription()
            .catch((error: any) => {
                toast({
                    title: 'Error',
                    description: error.message,
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            {isCancelled ? (
                <div className="flex flex-col items-center justify-center h-full bg-background-primary p-4">
                    <div className="max-w-md w-full bg-white rounded-lg shadow-md p-8 text-center">
                        <h1 className="text-2xl font-bold mb-4">Your subscription is cancelled</h1>
                        <p className="text-muted-foreground mb-6">Reactivate account on free plan</p>
                        <div className="space-y-4">
                            <Button variant="default" className="w-full" disabled={loading} onClick={handleReactivate}>
                                {loading ? (
                                    <span className="flex items-center">
                                        <svg
                                            className="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                        >
                                            <circle
                                                className="opacity-25"
                                                cx="12"
                                                cy="12"
                                                r="10"
                                                stroke="currentColor"
                                                strokeWidth="4"
                                            ></circle>
                                            <path
                                                className="opacity-75"
                                                fill="currentColor"
                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                            ></path>
                                        </svg>
                                        Reactivating...
                                    </span>
                                ) : (
                                    'Reactivate'
                                )}
                            </Button>
                            <Button variant="ghost" className="w-full text-muted-foreground" onClick={logout}>
                                Log out
                            </Button>
                        </div>
                    </div>
                </div>
            ) : (
                <NoSeatsLeftInfoDialog
                    activeUsersCount={user?.userOrganization?.activeUsersCount || 0}
                    organizationName={user?.userOrganization?.name}
                    adminEmail={user?.userOrganization?.adminEmail}
                    open={true}
                />
            )}
        </>
    );
};

export default NoSeatPage;
