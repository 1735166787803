import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { styled } from '@mui/styles';

const Text = styled(({ children, className, dataCy, ...props }) => (
    <span className={classNames(className, props.mode)} onClick={props.onClick} data-cy={dataCy}>
        {children}
    </span>
))(({ theme }) => ({
    color: theme.palette.primary.light,
    '&:hover': {
        cursor: 'pointer',
        color: theme.palette.primary.main,
        borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
    '&.red': {
        color: theme.palette.colors.red,
        '&:hover': {
            color: theme.palette.colors.redPale,
            borderBottom: `1px solid ${theme.palette.colors.redPale}`,
        },
    },
    '&.blue': {
        color: theme.palette.colors.blue,
        '&:hover': {
            color: theme.palette.colors.bluePale,
            borderBottom: `1px solid ${theme.palette.colors.bluePale}`,
        },
    },
    '&.green': {
        color: theme.palette.colors.green,
        '&:hover': {
            color: theme.palette.colors.greenPale,
            borderBottom: `1px solid ${theme.palette.colors.greenPale}`,
        },
    },
    '&.black': {
        color: theme.palette.colors.blackLighter,
        '&:hover': {
            color: theme.palette.colors.black,
            borderBottom: `1px solid ${theme.palette.colors.black}`,
        },
    },
    '&.white': {
        color: theme.palette.graphics.stroke,
        '&:hover': {
            color: theme.palette.colors.white,
            borderBottom: `1px solid ${theme.palette.colors.white}`,
        },
    },
    '&.grey': {
        fontSize: 14,
        color: theme.palette.colors.greyDark,
        textDecoration: 'underline',
        cursor: 'pointer',
        '&:hover': {
            borderBottom: 'none',
        },
    },
}));

export const TextColor = {
    red: 'red',
    blue: 'blue',
    green: 'green',
    black: 'black',
    white: 'white',
};

export const ActionText = (props) => {
    return (
        <Text mode={props.class} onClick={props.onClick} dataCy={props.dataCy}>
            {props.text}
        </Text>
    );
};

ActionText.propTypes = {
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    class: PropTypes.string,
    dataCy: PropTypes.string,
};
