import React, { useState } from 'react';
import { Tag } from 'lucide-react';
import { CompanyTag } from '../../../../../../components/contact-details-sidebar/company-tag';
import { Button } from '@shadcn/ui/components/ui/button';
import { useSuggestionsFeed } from '../../../hooks/use-suggestions-feed';

export const AccountCardSpecialties = ({ asSection = true }: { asSection?: boolean }) => {
    const { currentAccountData: accountData } = useSuggestionsFeed();
    const [showAllSpecialties, setShowAllSpecialties] = useState(false);
    const defaultShowNumberOfSpecialties = 3;
    const specialties = accountData?.data?.specialties;

    if (!specialties?.length) return null;

    return (
        <div className="flex items-start gap-3">
            {!asSection && <Tag className="h-5 w-5 stroke-[1]" />}
            <div className="flex flex-col gap-0 flex-1">
                <div className="flex flex-row flex-wrap gap-1 flex-1">
                    {specialties
                        .sort()
                        .slice(0, showAllSpecialties ? specialties.length : defaultShowNumberOfSpecialties)
                        .map((specialty, index) => (
                            <CompanyTag className="text-sm text-muted-foreground font-normal" key={index}>
                                {specialty}
                            </CompanyTag>
                        ))}
                </div>
                {!asSection && (
                    <div>
                        <Button
                            variant="text"
                            className="p-0"
                            onClick={() => setShowAllSpecialties(!showAllSpecialties)}
                        >
                            {showAllSpecialties ? 'Show less' : 'Show more'}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};
