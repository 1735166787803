import { ICommand, Topics } from '@zaplify/common';

export class DeleteThreadCommand extends ICommand<void> {
    public static override readonly TOPIC = Topics.PROSPECTS;

    public readonly userId: string;
    public readonly threadId: string;

    constructor(input: { userId: string; threadId: string }) {
        super();
        this.userId = input.userId;
        this.threadId = input.threadId;
    }
}
