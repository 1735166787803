import { IEvent, Topics } from '@zaplify/common';

export class UserContactRemovedEvent extends IEvent {
    public static override readonly TOPIC: Topics = Topics.PROSPECTS;
    public readonly id: string;
    public readonly userId: string;
    public readonly prospectId: string;

    constructor(params: { id: string; userId: string; prospectId: string }) {
        super();
        this.id = params.id;
        this.userId = params.userId;
        this.prospectId = params.prospectId;
    }

    get partitionKey(): string {
        return this.prospectId;
    }
}
