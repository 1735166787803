import React, { useEffect, useMemo } from 'react';
import { useMessages } from '../../../hooks/messaging/use-messages';
import { Message } from './message/message';
import { Loader2 } from 'lucide-react';
import { useIsMobile } from '@shadcn/ui/hooks/use-mobile';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';

export const MessageListSkeleton: React.FC = () => {
    return (
        <div className="flex w-full justify-center p-4">
            <Loader2 className="h-10 w-10 animate-spin text-background-brand-primary" />
        </div>
    );
};

export const MessageList: React.FC<{
    scrollAreaRef: React.RefObject<HTMLDivElement>;
    onScroll: (event: React.UIEvent<HTMLDivElement>) => void;
}> = ({ scrollAreaRef, onScroll }) => {
    const { prospectId: pageProspectId } = useParams();
    const { messages, linkedinMessagesLastSyncedAt } = useMessages({ prospectId: pageProspectId });
    const isMobile = useIsMobile();

    useEffect(() => {
        if (scrollAreaRef.current) {
            const lastChild = scrollAreaRef.current.lastElementChild;
            if (lastChild) {
                lastChild.scrollIntoView({ behavior: 'instant', block: 'end', inline: 'nearest' });
                setTimeout(() => {
                    lastChild.scrollIntoView({ behavior: 'instant', block: 'end', inline: 'nearest' });
                }, 50);
            }
        }
    }, [messages]);

    const uniqueMessages = useMemo(() => {
        return messages.filter((message, index, self) => index === self.findIndex((t) => t.id === message.id));
    }, [messages]);

    return (
        <div className="flex flex-col gap-4 flex-1 p-4 overflow-auto" ref={scrollAreaRef} onScroll={onScroll}>
            {/* Div to push messages to the bottom */}
            <div className="flex flex-1" />
            {uniqueMessages?.map((message: any) => (
                <Message key={message.id} {...message} />
            ))}
            {linkedinMessagesLastSyncedAt && isMobile && (
                <div className="flex justify-center flex-col items-center w-full text-sm text-text-tertiary">
                    <span>
                        LinkedIn messages last synced at{' '}
                        {dayjs(linkedinMessagesLastSyncedAt).format('YYYY-MM-DD HH:mm')}
                    </span>
                    <span className="text-xs">Sync not supported on mobile</span>
                </div>
            )}
        </div>
    );
};
