import React, { Suspense, useEffect, useMemo } from 'react';
import { Badge } from '@shadcn/ui/components/ui/badge';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@shadcn/ui/components/ui/tooltip';
import { Clock, Loader2, UserRoundPlus, UserRoundX } from 'lucide-react';
import { useLinkedin } from '../../../hooks/use-linkedin';
import { useSdk } from '../../../sdk';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { getProspectLinkedinTracker } from '../../../sdk/internal/tracker.sdk';
import { useParams } from 'react-router-dom';
import { ProspectLinkedinProfileVerifiedStatus } from '@zaplify/prospects';
import { ConnectionStatus } from '@zaplify/campaigns';
import { Skeleton } from '@shadcn/ui/components/ui/skeleton';
import { cn } from '@shadcn/ui/lib/utils';
import { useIsMobile } from '@shadcn/ui/hooks/use-mobile';
import { useEditContactDetailsDialog } from '../../../components/dialogs/edit-contact-details-dialog';

interface ConnectionStatusBadgeProps {
    collapsed?: boolean;
    className?: string;
    prospectId?: string;
}

type ConnectionBadgeStatuses = ConnectionStatus | 'invalid' | 'syncing' | 'connect_linkedin' | 'no_linkedin_url';

const ConnectionStatusTextMapping: Record<ConnectionBadgeStatuses, string> = {
    connected: 'Connected',
    not_connected: 'Not Connected',
    pending: 'Pending',
    invalid: 'Invalid LinkedIn URL',
    syncing: 'Syncing',
    no_linkedin_url: 'No LinkedIn URL',
    connect_linkedin: 'Connect LinkedIn',
};

const ConnectionStatusTooltipTextMapping: Record<ConnectionBadgeStatuses, string> = {
    connected: 'You are connected on LinkedIn',
    not_connected: 'You are not connected on LinkedIn',
    pending: 'Connection request pending on LinkedIn',
    invalid: 'Invalid LinkedIn URL. Please update the LinkedIn URL in the contact details.',
    syncing: 'Syncing LinkedIn data',
    no_linkedin_url: 'No LinkedIn URL. Please update the LinkedIn URL in the contact details.',
    connect_linkedin:
        'Ensure that the Chrome Extension is installed and enabled, and that you are logged in to your Linkedin account in the same browser. Connect your Linkedin account in the settings page to see whether you are connected to this contact.',
};

export const ConnectionStatusBadge: React.FC<ConnectionStatusBadgeProps> = ({ collapsed, className, prospectId }) => {
    return (
        <Suspense fallback={<Skeleton className="w-24 h-5 rounded-full bg-background-secondary" />}>
            <ConnectionStatusBadgeComponent collapsed={collapsed} className={className} prospectId={prospectId} />
        </Suspense>
    );
};

const ConnectionStatusBadgeComponent: React.FC<ConnectionStatusBadgeProps> = ({ collapsed, className, prospectId }) => {
    const { extensionStatus, getLinkedInProfile, linkedinAccount } = useLinkedin();
    const { openEditContactDetailsDialog } = useEditContactDetailsDialog();
    const {
        prospect: { getProspectById },
    } = useSdk();
    const isMobile = useIsMobile();
    const { prospectIdParam } = useParams();
    const { data: linkedinTracker, refetch: refetchLinkedinTracker } = useQuery(
        getProspectLinkedinTracker(prospectId || prospectIdParam, linkedinAccount?.id)
    );
    const { data: prospect } = useQuery(getProspectById(prospectId || prospectIdParam));

    const isInvalidLinkedinUrl = useMemo(() => {
        if (!prospect) {
            return false;
        }
        if (
            prospect.data?.linkedinProfileVerification?.status ===
            ProspectLinkedinProfileVerifiedStatus.profile_not_found
        ) {
            return true;
        }
    }, [prospect]);

    const [connectionStatus, setConnectionStatus] = React.useState<ConnectionBadgeStatuses>(
        isInvalidLinkedinUrl ? 'invalid' : linkedinTracker?.connectionStatus || 'syncing'
    );

    useEffect(() => {
        if (linkedinTracker?.connectionStatus) {
            setConnectionStatus(linkedinTracker.connectionStatus);
        }
        if (extensionStatus !== 'CONNECTED' && extensionStatus !== 'LOADING') {
            setConnectionStatus('connect_linkedin');
        }
        if (prospect && !prospect?.data?.linkedinProfileUrl) {
            setConnectionStatus('no_linkedin_url');
        }
    }, [extensionStatus, linkedinTracker?.connectionStatus, linkedinAccount?.id, prospect?.data?.linkedinProfileUrl]);

    useEffect(() => {
        // Be very careful of this useEffect to not have any loops.
        if (connectionStatus === 'syncing') {
            refetchLinkedinTracker();
        }
    }, [connectionStatus, refetchLinkedinTracker]);

    useEffect(() => {
        let intervalId: NodeJS.Timeout | null = null;

        const fetchProfile = async () => {
            if (
                extensionStatus === 'CONNECTED' &&
                connectionStatus === 'invalid' &&
                prospect?.data?.linkedinProfileUrl
            ) {
                intervalId = setInterval(async () => {
                    const profile = await getLinkedInProfile(prospect.data.linkedinProfileUrl).catch((e) => {
                        console.error('Error fetching profile from LinkedIn', prospect?.data?.linkedinProfileUrl, e);
                        return null;
                    });
                    console.log('Connection status badge fetched profile', profile);
                    if (profile?.status) {
                        setConnectionStatus(profile.status);
                    }
                }, 30_000);
            }
        };

        fetchProfile();

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [extensionStatus, connectionStatus, getLinkedInProfile, prospect?.data?.linkedinProfileUrl]);

    useEffect(() => {
        if (isInvalidLinkedinUrl) {
            setConnectionStatus('invalid');
        }
    }, [isInvalidLinkedinUrl]);

    const variant = useMemo(() => {
        if (connectionStatus === 'connected') {
            return 'success';
        } else if (connectionStatus === 'pending') {
            return 'warning';
        } else if (connectionStatus === 'not_connected') {
            return 'outline';
        } else if (connectionStatus === 'syncing') {
            return 'outline';
        } else if (connectionStatus === 'no_linkedin_url') {
            return 'destructive';
        } else {
            return 'destructive';
        }
    }, [connectionStatus]);

    const handleBadgeClick = () => {
        if (connectionStatus === 'invalid' || connectionStatus === 'no_linkedin_url') {
            openEditContactDetailsDialog(prospectId);
        }
    };

    const isClickable = connectionStatus === 'invalid' || connectionStatus === 'no_linkedin_url';

    return (
        <TooltipProvider>
            <Tooltip delayDuration={100}>
                <TooltipTrigger asChild>
                    <div className={cn(className, { hidden: isMobile && connectionStatus !== 'connected' })}>
                        <Badge
                            variant={variant}
                            className={cn('flex items-center gap-1', {
                                'cursor-pointer hover:opacity-80': isClickable,
                                'p-[0.2rem]': collapsed,
                                'bg-background-primary': variant === 'outline',
                                '[&>svg]:h-2 [&>svg]:w-2 [&>svg]:stroke-[2]': collapsed,
                            })}
                            onClick={handleBadgeClick}
                        >
                            <ConnectionStatusIcon connectionStatus={connectionStatus} />
                            {!collapsed && (
                                <span className="hidden xl:inline ml-1">
                                    {ConnectionStatusTextMapping[connectionStatus || 'invalid']}
                                </span>
                            )}
                        </Badge>
                    </div>
                </TooltipTrigger>
                <TooltipContent className="max-w-96">
                    {ConnectionStatusTooltipTextMapping[connectionStatus || 'invalid']}
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    );
};

const ConnectionStatusIcon: React.FC<{ connectionStatus: ConnectionBadgeStatuses }> = ({ connectionStatus }) => {
    if (connectionStatus === 'connected') {
        return <UserRoundPlus className="h-3 w-3" />;
    } else if (connectionStatus === 'pending') {
        return <Clock className="h-3 w-3" />;
    } else if (connectionStatus === 'syncing') {
        return <Loader2 className="h-3 w-3 animate-spin" />;
    } else {
        return <UserRoundX className="h-3 w-3" />;
    }
};
