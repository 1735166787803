import { styled } from '@mui/styles';
import { PlanCode } from '@zaplify/subscriptions';

export const UsersExceedsSelectedPlanStyled = styled('div')(({ theme }) => ({
    borderRadius: 8,
    backgroundColor: `${theme.palette.colors.red}14`,
    padding: 13,
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.colors.red,
}));

const UsersExceedsSelectedPlan = ({
    exceed,
    currentUsersCount,
    currentPlanCode,
}: {
    exceed: number;
    currentUsersCount: number | undefined;
    currentPlanCode: PlanCode;
}) => {
    return (
        <UsersExceedsSelectedPlanStyled>
            {currentPlanCode !== PlanCode.ENTERPRISE && (
                <>
                    Your organization has {currentUsersCount} active users. To decrease team size, please deactivate{' '}
                    {Math.abs(exceed)} {Math.abs(exceed) > 1 ? 'users' : 'user'}.
                </>
            )}
            {currentPlanCode === PlanCode.ENTERPRISE && (
                <>To decrease team size, please contact your customer success manager.</>
            )}
        </UsersExceedsSelectedPlanStyled>
    );
};

export default UsersExceedsSelectedPlan;
