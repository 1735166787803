import React from 'react';
import { Box, Typography, LinearProgress } from '@mui/material';
import { useReferrals } from '../../../hooks/use-referrals';
import { useClipboard } from '../../../hooks/use-clipboard';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CopyLinkInput } from '../../molecules/copy-link-input';
import { Modal } from '../../molecules/modal';
import { UpgradeSection } from '../../atoms/upgrade-section';
import { companyNameCorrectlyCased } from '../../../enums/company-name';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../../routes/paths';
import { useUserContacts } from '../../../views/contacts/hooks/use-user-contacts';

interface ReferralModalProps {
    open: boolean;
    onClose: () => void;
    onClickUpgrade: () => void;
}

const linkStyle = {
    color: '#6A76FB',
    cursor: 'pointer',
};

const ReferralModal: React.FC<ReferralModalProps> = ({ open, onClose, onClickUpgrade }) => {
    const { referralLink } = useReferrals();
    const { limit } = useUserContacts();
    const { copyToClipboard } = useClipboard();
    const { numberOfUserContactsGrantedPerReferral } = useFlags();
    const navigate = useNavigate();
    const handleCopy = () => {
        copyToClipboard(referralLink);
    };
    const handleClickUpgrade = () => {
        onClickUpgrade();
        onClose();
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={`Earn ${numberOfUserContactsGrantedPerReferral} contacts for every referred friend`}
            subTitle={`Refer a friend to increase your contact limit. Every time someone signs up using your link you get ${numberOfUserContactsGrantedPerReferral} extra contacts.`}
        >
            <>
                {typeof limit === 'number' && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '8px',
                            alignItems: 'flex-start',
                            alignSelf: 'stretch',
                            color: '#6A76FB', // To be able to use inherit color on LinearProgress

                            '& .MuiLinearProgress-root': {
                                height: '7px',
                                borderRadius: '4px',
                            },
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignSelf: 'stretch',
                            }}
                        >
                            <Typography color="#3A4B59" fontWeight={500}>
                                Contacts earned
                            </Typography>
                            <Typography color="#3A4B59" fontWeight={500}>
                                {limit - 50} / 300
                            </Typography>
                        </Box>
                        <LinearProgress
                            variant="determinate"
                            value={Math.min((100 * (limit - 50)) / 300, 100)}
                            sx={{ width: '100%' }}
                            color="inherit"
                        />
                        <Typography color="#6B7985">
                            <b>Pro tip:</b> Use the{' '}
                            <span
                                style={linkStyle}
                                onClick={() => {
                                    navigate(paths.PLAYBOOKS_PATHS.NEW + '?template=referral');
                                    onClose();
                                }}
                            >
                                referral playbook
                            </span>{' '}
                            to have {companyNameCorrectlyCased} do the heavy lifting for you.
                        </Typography>
                    </Box>
                )}
                <CopyLinkInput value={referralLink} onCopy={handleCopy} />
                {typeof limit === 'number' && <UpgradeSection onClickUpgrade={handleClickUpgrade} />}
            </>
        </Modal>
    );
};
export default ReferralModal;
