import { IEvent, Topics } from '@zaplify/common';

// I don't like the name of this event, but since it was called this on frontend we should keep it until a new event name has been live for a few months, in order to avoid data loss (note that this is an important metric in our funnel).
export class AIProfileOnboardingCompletedEvent extends IEvent {
    public static override readonly TOPIC: Topics = Topics.CAMPAIGNS;
    public override readonly _external_name = 'AI Profile Onboarding Completed';

    constructor(readonly userId: string, readonly organizationId: string) {
        super();
    }

    get partitionKey(): string {
        return this.organizationId;
    }
}
