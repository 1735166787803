import { Dialog, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { ReactNode, forwardRef, useContext, useEffect, useState } from 'react';
import { batch, useDispatch } from 'react-redux-latest';
import PaymentProcessingFree from '../../components/PaymentProcessingFree';
import { PaymentStatus } from '../../components/pick-plan-modal/enums/billing';
import { LocalStorageKeys, SocketEvents } from '../../config';
import userResetAtoms from '../../hooks/useResetAtoms';
import { WebSocketContext } from '../websocket-provider';
import {
    handleSubscriptionChange,
    updatePaymentStatus,
    updateProcessingPaymentType,
    refetchUserAndSubscription,
} from '../../redux/actions';
import { AppDispatch, useAppSelector } from '../../redux/store/configureStore';

function log(text: string) {
    console.log(`🙌 BillingListener | ${text}`);
}

const Transition = forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
) {
    return <Slide direction="right" ref={ref} {...props} />;
});

/**
 * SubscriptionStatusObserver is a React component that observes the subscription status of a user.
 * It sets up a socket connection to listen for changes in the subscription status.
 * When the subscription status changes, it updates the user's subscription details and user information.
 * If the subscription status is 'cancelled', it creates a free subscription for the user.
 * If the subscription status is 'active', it stops the loading state.
 *
 * @component
 * @example
 * ```jsx
 * <ChargebeeObserver>
 *   <ChildComponent />
 * </ChargebeeObserver>
 * ```
 *
 * @param {Object} props - The properties that define the SubscriptionStatusObserver component.
 * @param {ReactNode} props.children - The child components to be rendered within the ChargebeeObserver component.
 *
 * @returns {ReactElement} Returns a React element that either shows a loading dialog or the child components, based on the loading state.
 */
const BillingListener = ({ children }: { children?: ReactNode }) => {
    const [subscribe, unsubscribe, isConnected] = useContext(WebSocketContext);
    const dispatch = useDispatch<AppDispatch>();
    const [loading, setLoading] = useState(false);
    const subscriptionStatus = useAppSelector((state) => state.user.subscription?.subscription?.status);
    const userCurrentState = useAppSelector((state) => state.user.currentState);
    const processingPaymentType = useAppSelector((state) => state.ui.processingPaymentType);
    const { resetAllAtoms } = userResetAtoms();

    const handleSeatUnassigned = () => dispatch(refetchUserAndSubscription());

    const handlePaymentSucceeded = async () => {
        console.log('handlePaymentSucceeded');
        dispatch(updatePaymentStatus(PaymentStatus.SUCCESS));
    };

    const handleSeatAssigned = async () => {
        batch(async () => {
            await dispatch(refetchUserAndSubscription());
            resetAllAtoms();
            dispatch(updateProcessingPaymentType('idle'));
            dispatch(updatePaymentStatus(PaymentStatus.NONE));
            localStorage.removeItem(LocalStorageKeys.PLAN);
            setLoading(false);
        });
    };

    const handleSubscriptionChanged = () => {
        dispatch(handleSubscriptionChange());
    };

    useEffect(() => {
        if (!isConnected) {
            log('BillingListener Not subscribing to events, isConnected:' + isConnected);
            return;
        }

        subscribe(SocketEvents.billing.SEAT_UNASSIGNED, handleSeatUnassigned);
        subscribe(SocketEvents.billing.SEAT_ASSIGNED, handleSeatAssigned);
        subscribe(SocketEvents.billing.PAYMENT_SUCCEEDED, handlePaymentSucceeded);
        subscribe(SocketEvents.billing.SUBSCRIPTION_CHANGED, handleSubscriptionChanged);

        log('BillingListener subscribed to billing events');

        return () => {
            unsubscribe(SocketEvents.billing.SEAT_UNASSIGNED);
            unsubscribe(SocketEvents.billing.SEAT_ASSIGNED);
            unsubscribe(SocketEvents.billing.PAYMENT_SUCCEEDED);
            unsubscribe(SocketEvents.billing.SUBSCRIPTION_CHANGED);
        };
    }, [isConnected]);

    useEffect(() => {
        if (subscriptionStatus === 'cancelled') {
            console.log('Found cancelled subscription');
            setLoading(true);
            dispatch(updateProcessingPaymentType('free'));
            return;
        }

        if (subscriptionStatus === 'active') {
            setLoading(false);
            return;
        }

        if (processingPaymentType === 'idle') {
            setLoading(false);
        }
    }, [subscriptionStatus, userCurrentState, processingPaymentType]);

    return (
        <>
            {loading && (
                <Dialog fullScreen open TransitionComponent={Transition}>
                    <PaymentProcessingFree fromCancelled />
                </Dialog>
            )}
            {children}
        </>
    );
};

export default BillingListener;
