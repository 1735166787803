import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { prospectSearchResultInitialState } from './constants';
import { getIntegrationDetails, handleSelectAll, handleSelectCompany } from './helpers';
import {
    ErrorType,
    InitialSearchPayloadType,
    ProspectHits,
    ProspectSearchResultStateType,
    Selected,
    UpdateSearchPayloadType,
} from './types';
import { extendProspectsInCompany, getFeed, previewProspectsAction, selectExtendedProspects } from './operations';
import { DEFAULT_PROSPECTS_PER_COMPANY } from '../../../config';
import { TransactionState } from '../../../enums/prospects';
import { IntegrationType } from '@zaplify/enums';
import { ProspectDataDto } from '@zaplify/prospects';

export const selectSelected = (state: ProspectSearchResultStateType) => state.selected;

export const selectAllProspectsAdded = (state: ProspectSearchResultStateType) => state.allProspectsAdded;

const prospectSearchResultSlice = createSlice({
    name: 'prospectsSearchResult',
    initialState: prospectSearchResultInitialState as ProspectSearchResultStateType,
    reducers: {
        resetProspectsSearchResult(state) {
            return prospectSearchResultInitialState;
        },
        initialProspects(state, action: PayloadAction<InitialSearchPayloadType>) {
            return { ...state, ...action.payload };
        },
        updateProspectsState(state, action: PayloadAction<UpdateSearchPayloadType>) {
            return { ...state, ...action.payload };
        },
        updateProspectsInCompaniesState(state, action: PayloadAction<any>) {
            state.prospectsInCompanies = action.payload || [];
        },
        updateProspectTransactionState(state, action: PayloadAction<{ prospectID: string; state: TransactionState }>) {
            state.prospectTransactionState[action.payload.prospectID] = action.payload.state;
        },
        updateDetailedProspects(state, action: PayloadAction<any>) {
            state.detailedProspects = { ...state.detailedProspects, ...action.payload };
        },
        updateDetailedProspect(state, action: PayloadAction<{ prospectId: string; data: Partial<ProspectDataDto> }>) {
            state.detailedProspects[action.payload.prospectId] = {
                ...state.detailedProspects[action.payload.prospectId],
                ...action.payload.data,
            };
        },
        updateBulkProspectTransactionState(state, action: PayloadAction<{ [key: string]: TransactionState }>) {
            state.prospectTransactionState = { ...state.prospectTransactionState, ...action.payload };
        },
        updateSelected(state, action: PayloadAction<Selected>) {
            state.selected = Object.keys(action.payload)?.length > 0 ? action.payload : {};
        },
        selectAllOnPage(state, action: PayloadAction<boolean>) {
            state.selected = handleSelectAll(
                action.payload,
                state.prospectsInCompanies,
                state.selected,
                state.prospectTransactionState
            );
        },
        selectAllInCompany(state, action: PayloadAction<{ ids: string[]; isSelected: boolean }>) {
            state.selected = handleSelectCompany(action.payload.ids, action.payload.isSelected, state.selected);
        },
        selectProspect(state, action: PayloadAction<string>) {
            if (state.selected[action.payload]) delete state.selected[action.payload];
            else state.selected[action.payload] = true;
        },
        unselectProspect(state, action: PayloadAction<{ id: string }>) {
            delete state.selected[action.payload.id];
        },
        updateProspectDrawerProspect(state, action: PayloadAction<string>) {
            state.prospectDrawerProspect = action.payload;
        },
        updateProspectDrawerState(state, action: PayloadAction<boolean>) {
            state.prospectDrawerState = action.payload;
        },
        updateLoadingState(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        updateLoadingHitsState(state, action: PayloadAction<boolean>) {
            state.loadingHits = action.payload;
        },
        updateLoadingMoreState(state, action: PayloadAction<boolean>) {
            state.loadingMore = action.payload;
        },
        updateProspectsCount(state, action: PayloadAction<number>) {
            state.prospectsCount = action.payload;
        },
        updateProspectHits(state, action: PayloadAction<ProspectHits>) {
            state.hits = action.payload;
        },
        addSyncingProspects(state, action: PayloadAction<string>) {
            state.syncingProspects[action.payload] = true;
        },
        removeSyncingProspects(state, action: PayloadAction<string>) {
            delete state.syncingProspects[action.payload];
        },
        updateSyncingProspects(state, action: PayloadAction<{ [key: string]: boolean }>) {
            state.syncingProspects = action.payload;
        },
        updateAllSelected(state, action: PayloadAction<boolean>) {
            state.allProspectsAdded = action.payload;
        },
        updateErrors(state, action: PayloadAction<{ type: ErrorType; value: boolean }>) {
            state.errors = { [action.payload.type]: action.payload.value };
        },
        updateHubspotProspects(state, action: PayloadAction<any>) {
            if (action.payload.personId) {
                state.prospectsSyncedHubspot[action.payload.personId] = action.payload;
            }
        },
        updateUpsalesProspects(state, action: PayloadAction<any>) {
            if (action.payload.personId) {
                state.prospectsSyncedUpsales[action.payload.personId] = action.payload;
            }
        },
        updatePipedriveProspects(state, action: PayloadAction<any>) {
            if (action.payload.prospectId) {
                state.prospectsSyncedPipedrive[action.payload.prospectId] = action.payload;
            }
        },
        updateSalesforceProspects(state, action: PayloadAction<any>) {
            if (action.payload.personId) {
                state.prospectsSyncedSalesforce[action.payload.personId] = action.payload;
            }
        },
        addHubspotRequestedProspects(state, action: PayloadAction<string[]>) {
            if (action.payload?.length > 0)
                action.payload.forEach((id) => (state.hubspotRequestedProspects[id] = true));
        },
        addPipedriveRequestedProspects(state, action: PayloadAction<string[]>) {
            if (action.payload?.length > 0)
                action.payload.forEach((id) => (state.pipedriveRequestedProspects[id] = true));
        },
        addSalesforceRequestedProspects(state, action: PayloadAction<string[]>) {
            if (action.payload?.length > 0)
                action.payload.forEach((id) => (state.salesforceRequestedProspects[id] = true));
        },
        addUpsalesRequestedProspects(state, action: PayloadAction<string[]>) {
            if (action.payload?.length > 0)
                action.payload.forEach((id) => (state.upsalesRequestedProspects[id] = true));
        },
        addWebhookRequestedProspects(state, action: PayloadAction<string[]>) {
            if (action.payload?.length > 0)
                action.payload.forEach((id) => (state.webhookRequestedProspects[id] = true));
        },
        updateWebhooksProspects(state, action: PayloadAction<any>) {
            if (action.payload.personId) state.prospectsSyncedWebhooks[action.payload.personId] = action.payload;
        },
        updateHubspotCompany(state, action: PayloadAction<any>) {
            if (action.payload) {
                state.hubspotSyncedCompanies[action.payload.companyName] = action.payload;
            }
        },
        updateUpsalesCompany(state, action: PayloadAction<any>) {
            if (action.payload) {
                state.upsalesSyncedCompanies[action.payload.companyName] = action.payload;
            }
        },
        updatePipedriveCompany(state, action: PayloadAction<any>) {
            if (action.payload) {
                state.pipedriveSyncedCompanies[action.payload.companyName] = action.payload;
            }
        },
        updateSalesforceCompany(state, action: PayloadAction<any>) {
            if (action.payload) {
                state.salesforceSyncedCompanies[action.payload.companyName] = action.payload;
            }
        },
        updateWebhooksCompany(state, action: PayloadAction<any>) {
            if (action.payload.companyId) state.webhooksSyncedCompanies[action.payload.companyName] = action.payload;
        },
        updatePurchasingProspectsState(state, action: PayloadAction<boolean>) {
            state.purchasing = action.payload;
        },
        updatePurchasingToOtherCampaign(state, action: PayloadAction<boolean>) {
            state.purchasingToOtherCampaign = action.payload;
        },
        updatePage(state, action: PayloadAction<number>) {
            state.page = action.payload;
        },
        showLessProspectsInCompany(
            state,
            action: PayloadAction<{
                companyName: string;
                companyIndex: number;
                oneProspectPerCompany: boolean;
            }>
        ) {
            const prospectsLength = state.prospectsInCompanies[action.payload.companyIndex].prospects.length;

            if (prospectsLength > 20) {
                state.prospectsInCompanies[action.payload.companyIndex].prospects.length -= 20;
            }
            if (prospectsLength > 0 && prospectsLength < 20) {
                state.prospectsInCompanies[action.payload.companyIndex].prospects.length =
                    DEFAULT_PROSPECTS_PER_COMPANY;
            }
        },
        updateHasMoreProspects(state, action: PayloadAction<{ [key: string]: boolean }>) {
            state.hasMoreProspects = action.payload;
        },
        resetHasMoreProspects(state) {
            state.hasMoreProspects = {};
        },
        updateLoadingExtendedSelection(state, action: PayloadAction<boolean>) {
            state.loadingExtendedSelection = action.payload;
        },
        updateLoadingAIProspectsAssistant(state, action: PayloadAction<boolean>) {
            state.loadingAIAssistant = action.payload;
        },
        removeCompanyFromResults(state, action: PayloadAction<string>) {
            const index = state.prospectsInCompanies.findIndex(
                (company) => company.organizationName === action.payload
            );
            if (index !== -1) state.prospectsInCompanies.splice(index, 1);
        },
    },
    extraReducers: (builder) => {
        //@ts-ignore
        builder.addCase(previewProspectsAction.pending, (state) => {
            state.loading = true;
        });
        //@ts-ignore
        builder.addCase(previewProspectsAction.fulfilled, (state: any, { payload }) => {
            state.prospectsInCompanies = payload;
            getIntegrationDetails(IntegrationType.HUBSPOT, payload, state);
            state.loading = false;
        });
        builder.addCase(selectExtendedProspects.pending, (state) => {
            state.loadingExtendedSelection = true;
        });
        builder.addCase(selectExtendedProspects.fulfilled, (state, { payload }) => {
            // state.selected = payload && Object.keys(payload)?.length > 0 ? payload : {};
            // state.loadingExtendedSelection = false;
        });
        builder.addCase(selectExtendedProspects.rejected, (state, { payload }) => {
            state.loadingExtendedSelection = false;
        });
        builder.addCase(extendProspectsInCompany.pending, (state, action: any) => {
            state.extendProspectsInCompanyLoading = action.meta.arg.company.organizationName;
        });
        builder.addCase(extendProspectsInCompany.fulfilled, (state, { payload }: any) => {
            if (payload.extendedProspects.length < 20) {
                state.allProspectsExtended[payload.companyName] = true;
            }
            state.prospectsInCompanies[payload.companyIndex].prospects = [
                ...state.prospectsInCompanies[payload.companyIndex].prospects,
                ...payload.extendedProspects,
            ];
            state.extendProspectsInCompanyLoading = undefined;
        });
        builder.addCase(extendProspectsInCompany.rejected, (state) => {
            state.extendProspectsInCompanyLoading = undefined;
        });
        builder.addCase(getFeed.pending, (state) => {
            state.loadingFeed = true;
        });
        builder.addCase(getFeed.fulfilled, (state: any, { payload }) => {
            state.prospectsInCompanies = payload;
            state.loadingFeed = false;
        });
        builder.addCase(getFeed.rejected, (state: any, { payload }) => {
            state.loadingFeed = false;
        });
    },
});

export const {
    resetProspectsSearchResult: resetProspects,
    initialProspects,
    updateProspectsState,
    updateSelected,
    selectAllOnPage,
    selectAllInCompany,
    selectProspect,
    unselectProspect,
    updateProspectDrawerProspect,
    updateProspectDrawerState,
    updateLoadingState,
    updateLoadingMoreState,
    updateProspectsInCompaniesState,
    updateProspectsCount,
    updateProspectHits,
    resetProspectsSearchResult,
    updateAllSelected,
    updateErrors,
    updateProspectTransactionState,
    updateBulkProspectTransactionState,
    updateHubspotProspects,
    updateWebhooksProspects,
    addSyncingProspects,
    removeSyncingProspects,
    updateSyncingProspects,
    updateHubspotCompany,
    updateWebhooksCompany,
    updateLoadingHitsState,
    updatePurchasingProspectsState,
    updatePurchasingToOtherCampaign,
    updateDetailedProspects,
    updateDetailedProspect,
    addHubspotRequestedProspects,
    addPipedriveRequestedProspects,
    addSalesforceRequestedProspects,
    addUpsalesRequestedProspects,
    addWebhookRequestedProspects,
    updatePipedriveCompany,
    updatePipedriveProspects,
    updateUpsalesCompany,
    updateUpsalesProspects,
    updateSalesforceCompany,
    updateSalesforceProspects,
    updatePage,
    updateHasMoreProspects,
    resetHasMoreProspects,
    showLessProspectsInCompany,
    updateLoadingExtendedSelection,
    updateLoadingAIProspectsAssistant,
    removeCompanyFromResults,
} = prospectSearchResultSlice.actions;
export default prospectSearchResultSlice.reducer;
