import React, { useMemo, useState } from 'react';
import { Badge } from '@shadcn/ui/components/ui/badge';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@shadcn/ui/components/ui/tooltip';
import { ChevronDown, Plus, Settings, User } from 'lucide-react';
import { useQuery, useApolloClient } from '@apollo/client';
import {
    GET_ALL_USER_CONTACTS,
    GET_GROUPS,
    GET_USER_CONTACT_BY_PROSPECT_ID,
    GET_USER_CONTACTS_BY_GROUP_IDS,
} from '@zaplify/graphql';
import { Avatar, AvatarFallback, AvatarImage } from '@shadcn/ui/components/ui/avatar';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '@shadcn/ui/components/ui/dropdown-menu';
import { cn } from '@shadcn/ui/lib/utils';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../routes/paths';
import { useCreatePlaybookDialog } from './dialogs/create-playbook-dialog';
import { useSdk } from '../sdk';
import { useMutation } from '@tanstack/react-query';
import { useFlags } from 'launchdarkly-react-client-sdk';
interface PlaybookBadgeProps {
    prospectId: string;
    responsive?: boolean;
    size?: 'small' | 'large';
    className?: string;
}

export const PlaybookBadge: React.FC<PlaybookBadgeProps> = ({
    prospectId,
    responsive = true,
    size = 'small',
    className,
}) => {
    const {
        data: userContactData,
        loading: userContactLoading,
        refetch,
    } = useQuery(GET_USER_CONTACT_BY_PROSPECT_ID, {
        variables: { prospectId },
        skip: !prospectId,
    });
    const { createProspectsFromAllLiConversation } = useFlags();
    const client = useApolloClient();
    const {
        userContacts: { assignUserContactsToGroup },
    } = useSdk();
    const { mutateAsync: assignContactsToGroup } = useMutation(assignUserContactsToGroup());

    const userContact = useMemo(() => {
        if (userContactLoading || !userContactData) return null;
        return userContactData.UserContacts[0];
    }, [userContactData, userContactLoading]);

    const group = useMemo(() => {
        if (!userContact) return null;
        return userContact._GroupToUserContacts[0]?.Group;
    }, [userContact?._GroupToUserContacts?.[0]?.Group]);

    const handleAssignToGroup = async (groupId: string) => {
        await assignContactsToGroup({
            contactIds: [userContact.id],
            groupId,
        });
        await client.refetchQueries({
            include: [GET_ALL_USER_CONTACTS, GET_USER_CONTACTS_BY_GROUP_IDS, GET_USER_CONTACT_BY_PROSPECT_ID],
        });
    };

    return (
        <DropdownMenu>
            <DropdownMenuTrigger className="focus-visible:outline-none">
                <TooltipProvider>
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <div>
                                <Badge
                                    variant="brand"
                                    className={cn(
                                        'flex flex-1 items-center gap-2 hover:brightness-[1.01] justify-between',
                                        className
                                    )}
                                >
                                    <div className="flex flex-1 items-center gap-2">
                                        <Avatar className={cn(size === 'large' ? 'h-4 w-4' : 'h-3 w-3')}>
                                            {group?.imgSrc ? (
                                                <AvatarImage src={group?.imgSrc} className="scale-[1000%]" />
                                            ) : (
                                                <AvatarFallback>{/* <User className="h-3 w-3" /> */}</AvatarFallback>
                                            )}
                                        </Avatar>
                                        <span
                                            className={cn(
                                                'break-all line-clamp-1 text-ellipsis',
                                                responsive ? 'hidden xl:inline' : 'webkit-box',
                                                size == 'large' ? 'text-sm' : ''
                                            )}
                                        >
                                            {group?.name || 'Not in playbook'}
                                        </span>
                                    </div>
                                    <ChevronDown className={cn(size === 'large' ? 'h-4 w-4' : 'h-3 w-3')} />
                                </Badge>
                            </div>
                        </TooltipTrigger>
                        <TooltipContent>Assign playbook</TooltipContent>
                    </Tooltip>
                </TooltipProvider>
            </DropdownMenuTrigger>
            <PlaybookDropdownContent
                handleGroupChange={handleAssignToGroup}
                selectedGroupIds={group ? [group.id] : []}
            />
        </DropdownMenu>
    );
};

export const PlaybookDropdownContent: React.FC<{
    topLevelItem?: {
        name: string;
        id: string;
        imgSrc?: string;
    };
    keepOpenOnSelect?: boolean;
    selectedGroupIds?: string[];
    handleGroupChange: (groupId: string) => void;
}> = ({ topLevelItem, selectedGroupIds, handleGroupChange, keepOpenOnSelect = false }) => {
    const navigate = useNavigate();
    const { openCreatePlaybookDialog: openCreatePlaybookDialog } = useCreatePlaybookDialog();
    const { data: groupsData, loading: groupsLoading } = useQuery(GET_GROUPS);

    const { newPlaybookView } = useFlags();
    const playbookPath = newPlaybookView ? '/new' + paths.PLAYBOOKS : paths.PLAYBOOKS;

    const allGroups = useMemo(() => {
        if (groupsLoading || !groupsData) return [];
        return groupsData.Groups;
    }, [groupsData, groupsLoading]);

    const onGroupChange = (groupId: string) => {
        handleGroupChange(groupId);
    };

    return (
        <DropdownMenuContent className="max-w-52 text-ellipsis">
            {(topLevelItem ? [topLevelItem, ...allGroups] : allGroups).map((group) => (
                <DropdownMenuItem
                    key={group.id}
                    className={cn(
                        'p-2 text-ellipsis break-all',
                        selectedGroupIds?.includes(group.id) && 'bg-background-brand-primary-subtle'
                    )}
                    onClick={(e) => {
                        onGroupChange(group.id);
                        if (keepOpenOnSelect) {
                            e.preventDefault();
                            e.stopPropagation();
                        }
                    }}
                >
                    <Avatar className="h-4 w-4">
                        <AvatarImage src={group?.imgSrc} className="scale-[1000%]" />
                    </Avatar>
                    <span>{group.name}</span>
                </DropdownMenuItem>
            ))}
            <DropdownMenuItem
                key="manage-group-dropdown-item"
                className="text-text-brand-primary-subtle hidden md:flex"
                onClick={() => navigate(playbookPath)}
            >
                <Settings className="h-3 w-3" />
                <span>Manage playbooks</span>
            </DropdownMenuItem>
            <DropdownMenuItem
                key="create-new-group-dropdown-item"
                className="text-text-brand-primary-subtle hidden md:flex"
                onClick={openCreatePlaybookDialog}
            >
                <Plus className="h-3 w-3" />
                <span>Create new</span>
            </DropdownMenuItem>
        </DropdownMenuContent>
    );
};
