import ArrowBack from '@mui/icons-material/ArrowBack';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import { IconButton, Typography } from '@mui/material';
import { GuardedLink } from '@zaplify/ui';
import { useDispatch } from 'react-redux-latest';
import { Button } from '../../../../../components/atoms/button';
import { ReactComponent as PipedriveIcon } from '../../../../../components/icons/pipedrive-logo-2.svg';
import { updatePipedriveSettings } from '../../../../../redux/ducks/pipedrive/operations';
import { AppDispatch, useAppSelector } from '../../../../../redux/store/configureStore';
import { TopContainer } from './containers';

export const TopBar = () => {
    const dispatch = useDispatch<AppDispatch>();
    const pipedriveAccountId = useAppSelector((state) => state.pipedrive.account?.companyId);
    const saveStatus = useAppSelector((state) => state.pipedrive.saveStatus);

    return (
        <TopContainer>
            <GuardedLink to="/integrations">
                <IconButton data-cy="go-back-button" size="large">
                    <ArrowBack />
                </IconButton>
            </GuardedLink>
            <div>
                <PipedriveIcon />
                <div>
                    <Typography variant="h3">Pipedrive</Typography>
                    <Typography>CRM Software</Typography>
                </div>
                <div>
                    <Typography>Company ID: {pipedriveAccountId}</Typography>
                </div>
            </div>
            <Button
                dataCy={'save-pipedrive-settings'}
                disabled={!saveStatus}
                borderRadius="300px"
                height="45px"
                text="Save"
                onClick={() => dispatch(updatePipedriveSettings())}
                startIcon={<ArrowUpward />}
            />
        </TopContainer>
    );
};
