import { useState, useEffect, useCallback } from 'react';
import { useToast } from '@shadcn/ui/hooks/use-toast';
import { useSdk } from '../../../../sdk';
import { useAuth } from '../../../../providers/authentication-provider';
import { useQuery, useMutation } from '@tanstack/react-query';
import {
    AvailableFields,
    AvailableHubspotFields,
    AvailablePipedriveFields,
    AvailableSalesforceContactFields,
    AvailableSalesforceLeadFields,
    HubspotFieldMapping,
    PipedriveFieldMapping,
    SalesforceFieldMapping,
    IntegrationType,
} from '../../../../sdk/internal/user-organization.sdk';
import { FieldMapping } from '../types';

type CrmType = 'hubspot' | 'pipedrive' | 'salesforce';
type SalesforceStrategyType = 'ContactStrategy' | 'LeadStrategy';

export function useCrmAccounts() {
    const { toast } = useToast();
    const {
        authState: { userOrganizationId },
    } = useAuth();
    const [isUpdatingSettings, setIsUpdatingSettings] = useState(false);
    const [selectedCrmType, setSelectedCrmType] = useState<CrmType | null>(null);
    const [integrationStrategy, setIntegrationStrategy] = useState<SalesforceStrategyType | undefined>();

    const {
        userOrganization: {
            getOrganizationIntegrations,
            updateIntegrationScope,
            updateFieldMapping,
            getAvailableFields,
        },
    } = useSdk();

    // Query to fetch integrations
    const {
        data: integrations = [],
        refetch: refetchIntegrations,
        error,
        isLoading,
    } = useQuery({
        ...getOrganizationIntegrations(userOrganizationId),
        enabled: !!userOrganizationId,
    });

    // Extract accounts from integrations
    const accounts = {
        hubspot: integrations.find((i) => i.type === 'HUBSPOT'),
        pipedrive: integrations.find((i) => i.type === 'PIPEDRIVE'),
        salesforce: integrations.find((i) => i.type === 'SALESFORCE'),
    };

    // Set initial integration strategy from account settings when accounts are loaded
    useEffect(() => {
        if (accounts.salesforce?.settings?.integrationStrategy && selectedCrmType === 'salesforce') {
            // Ensure we use a consistent strategy format
            const strategy = String(accounts.salesforce.settings.integrationStrategy).toLowerCase();
            const normalizedStrategy: SalesforceStrategyType = strategy.includes('contact')
                ? 'ContactStrategy'
                : 'LeadStrategy';

            setIntegrationStrategy(normalizedStrategy);
        }
    }, [accounts.salesforce?.settings?.integrationStrategy, selectedCrmType]);

    // Query to fetch available fields for the selected CRM
    const {
        data: availableFields,
        isLoading: isLoadingFields,
        error: fieldsError,
        refetch: refetchFields,
    } = useQuery({
        ...getAvailableFields(
            userOrganizationId,
            selectedCrmType ? (selectedCrmType.toUpperCase() as IntegrationType) : undefined,
            integrationStrategy
        ),
        enabled: !!userOrganizationId && !!selectedCrmType,
    });

    // Type guards for available fields
    const isHubspotFields = (fields: AvailableFields | undefined): fields is AvailableHubspotFields => {
        return fields !== undefined && 'contact' in fields && !('contactPickList' in fields) && !('person' in fields);
    };

    const isPipedriveFields = (fields: AvailableFields | undefined): fields is AvailablePipedriveFields => {
        return fields !== undefined && 'person' in fields && 'organization' in fields;
    };

    const isSalesforceContactFields = (
        fields: AvailableFields | undefined
    ): fields is AvailableSalesforceContactFields => {
        return fields !== undefined && 'contact' in fields && 'contactPickList' in fields;
    };

    const isSalesforceLeadFields = (fields: AvailableFields | undefined): fields is AvailableSalesforceLeadFields => {
        return fields !== undefined && 'lead' in fields && 'leadPickList' in fields;
    };

    // Mutation to update integration scope
    const updateScopeMutation = useMutation({
        ...updateIntegrationScope(),
        onSuccess: () => {
            refetchIntegrations();
        },
    });

    // Mutation to update field mappings
    const updateFieldMappingMutation = useMutation({
        ...updateFieldMapping(),
        onSuccess: () => {
            refetchIntegrations();
        },
    });

    const loadAvailableFields = useCallback(
        (crmType: string, strategy?: SalesforceStrategyType | string) => {
            // Convert string to valid CrmType
            const validCrmType = crmType.toLowerCase() as CrmType;
            setSelectedCrmType(validCrmType);

            // For Salesforce, we need to store the integration strategy
            if (validCrmType === 'salesforce') {
                // Normalize strategy name first
                let strategyToUse = strategy || accounts.salesforce?.settings?.integrationStrategy || 'ContactStrategy';

                // Convert to proper case if needed
                if (typeof strategyToUse === 'string') {
                    const strategyLower = strategyToUse.toLowerCase();
                    const normalizedStrategy: SalesforceStrategyType = strategyLower.includes('contact')
                        ? 'ContactStrategy'
                        : 'LeadStrategy';

                    setIntegrationStrategy(normalizedStrategy);
                } else {
                    setIntegrationStrategy(strategyToUse as SalesforceStrategyType);
                }
            } else {
                setIntegrationStrategy(undefined);
            }

            // Manually trigger refetch of fields after state updates
            setTimeout(() => {
                refetchFields();
            }, 0);
        },
        [accounts.salesforce?.settings?.integrationStrategy, refetchFields]
    );

    // Method to update integration strategy
    const updateIntegrationStrategy = async (crmType: CrmType, strategy: SalesforceStrategyType) => {
        try {
            setIsUpdatingSettings(true);
            // Update local state to reflect the new strategy
            setIntegrationStrategy(strategy);

            // Here you would call your API to update the strategy in the backend
            // This is a placeholder - you'll need to implement the actual API call
            // await updateStrategyMutation.mutateAsync({
            //     organizationId: userOrganizationId,
            //     integrationType: crmType.toUpperCase() as IntegrationType,
            //     strategy,
            // });

            toast({
                title: 'Integration strategy updated',
                description: `Your ${crmType} integration strategy has been set to ${
                    strategy === 'ContactStrategy' ? 'Contact' : 'Lead'
                }.`,
            });

            // Refresh available fields for the new strategy
            loadAvailableFields(crmType, strategy);
        } catch (error) {
            console.error(`Error updating ${crmType} integration strategy:`, error);
            toast({
                title: 'Error updating integration strategy',
                description: `Could not update your ${crmType} integration strategy. Please try again.`,
                variant: 'destructive',
            });
        } finally {
            setIsUpdatingSettings(false);
        }
    };

    const handleUpdateScope = async (crmType: CrmType, scope: string, value: boolean) => {
        try {
            setIsUpdatingSettings(true);

            const integrationType = crmType.toUpperCase() as IntegrationType;

            await updateScopeMutation.mutateAsync({
                organizationId: userOrganizationId,
                integrationType,
                scope,
                value,
            });

            const localScope = scope === 'prospects' ? 'Contacts' : scope;

            toast({
                title: 'Settings updated',
                description: `${localScope} sync has been ${value ? 'enabled' : 'disabled'}.`,
            });
        } catch (error) {
            console.error(`Error updating ${crmType} settings:`, error);
            toast({
                title: 'Error updating settings',
                description: `Could not update your ${crmType} settings. Please try again.`,
                variant: 'destructive',
            });
        } finally {
            setIsUpdatingSettings(false);
        }
    };
    type UpdateFieldMappingDto = {
        organizationId: string;
        integrationType: IntegrationType;
        fieldMapping: HubspotFieldMapping[] | PipedriveFieldMapping[] | SalesforceFieldMapping[];
        integrationStrategy?: SalesforceStrategyType;
        isActive?: boolean;
    };

    const handleUpdateFieldMapping = async (crmType: CrmType, fieldMapping: FieldMapping[]) => {
        try {
            setIsUpdatingSettings(true);

            const integrationType = crmType.toUpperCase() as IntegrationType;

            // Convert the generic FieldMapping to the appropriate CRM-specific format
            let formattedMapping;
            const updateFieldMappingObj: UpdateFieldMappingDto = {
                organizationId: userOrganizationId,
                integrationType,
                fieldMapping,
            };
            if (crmType === 'hubspot') {
                updateFieldMappingObj.fieldMapping = fieldMapping.map((mapping) => ({
                    prospectField: mapping.prospectField,
                    contactField: mapping.crmField,
                }));
                updateFieldMappingObj.isActive = true;
            } else if (crmType === 'pipedrive') {
                // For Pipedrive, we MUST preserve the original field mappings
                // Don't transform them - they should already have personField/organizationField
                updateFieldMappingObj.isActive = true;
            } else if (crmType === 'salesforce') {
                updateFieldMappingObj.integrationStrategy = integrationStrategy;
                updateFieldMappingObj.isActive = true;
            } else {
                updateFieldMappingObj.isActive = true;
            }

            console.log(`Sending ${crmType} field mappings:`, updateFieldMappingObj);

            await updateFieldMappingMutation.mutateAsync(updateFieldMappingObj);

            toast({
                title: 'Field mapping updated',
                description: `Your ${crmType} field mapping has been updated.`,
            });

            // Refresh integrations to get the updated field mappings
            refetchIntegrations();
        } catch (error) {
            console.error(`Error updating ${crmType} field mapping:`, error);
            toast({
                title: 'Error updating field mapping',
                description: `Could not update your ${crmType} field mapping. Please try again.`,
                variant: 'destructive',
            });
        } finally {
            setIsUpdatingSettings(false);
        }
    };

    const disconnectCrm = async (crmType: CrmType) => {
        try {
            setIsUpdatingSettings(true);
            // Implementation pending - would use a similar SDK pattern
            // await disconnectIntegrationMutation.mutateAsync({ type: crmType.toUpperCase() });

            toast({
                title: 'CRM disconnected',
                description: `Your ${crmType} account has been disconnected.`,
            });

            refetchIntegrations();
        } catch (error) {
            console.error(`Error disconnecting ${crmType}:`, error);
            toast({
                title: 'Error disconnecting CRM',
                description: `Could not disconnect your ${crmType} account. Please try again.`,
                variant: 'destructive',
            });
        } finally {
            setIsUpdatingSettings(false);
        }
    };

    return {
        accounts,
        isLoading,
        error,
        isUpdatingSettings,
        disconnectCrm,
        updateScope: handleUpdateScope,
        updateFieldMapping: handleUpdateFieldMapping,
        updateIntegrationStrategy,
        refreshAccounts: refetchIntegrations,
        availableFields,
        isLoadingFields,
        loadAvailableFields,
        isHubspotFields,
        isPipedriveFields,
        isSalesforceContactFields,
        isSalesforceLeadFields,
    };
}
