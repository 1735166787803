import { Box, ButtonBase, LinearProgress, Typography } from '@mui/material';
import { paletteAndsend } from '@zaplify/ui';
import { ArrowUp, CircleArrowUp, Share2 } from 'lucide-react';
import { bindHover, usePopupState } from 'material-ui-popup-state/hooks';
import { companyNameCorrectlyCased } from '../../enums/company-name';
import { useUserContacts } from '../../views/contacts/hooks/use-user-contacts';
import { CircularProgress } from '../atoms/circular-progress';
import SidebarHoverMenu from '../molecules/sidebar-hover-menu';

interface ContactLimitDisplayProps {
    onUpgradeClick: () => void;
    isSidebarPinned: boolean;
    showReferralModal: () => void;
}

export const ContactLimitDisplay: React.FC<ContactLimitDisplayProps> = ({
    onUpgradeClick,
    isSidebarPinned,
    showReferralModal,
}) => {
    const popupState = usePopupState({
        variant: 'popover',
        popupId: 'contactLimitDisplay',
    });
    const { totalAmountOfContacts, limit: maxContacts } = useUserContacts();
    const sidebarIsNotPinned = !isSidebarPinned;

    const isAtLimit = totalAmountOfContacts === maxContacts;

    if (maxContacts === 'none') return null;

    const progressValue = Math.min((totalAmountOfContacts / maxContacts) * 100, 100);
    if (progressValue < 20) return null;

    const upgradeClick = isAtLimit ? onUpgradeClick : showReferralModal;

    if (sidebarIsNotPinned) {
        return (
            <Box
                sx={{
                    position: 'relative',
                    borderRadius: '8px',
                    background: paletteAndsend.blue['200'],
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    '&:hover': {
                        cursor: 'pointer',
                    },
                    padding: '4px',
                }}
                {...bindHover(popupState)}
            >
                <CircularProgress percentage={40} size={32}>
                    <ArrowUp size={16} strokeWidth={2} stroke={paletteAndsend.primary.light} />
                </CircularProgress>
                <SidebarHoverMenu id="contactLimitDisplay" popupState={popupState} transform={{ y: '-16px' }}>
                    <ContactLimitDetails
                        onUpgradeClick={upgradeClick}
                        totalAmountOfContacts={totalAmountOfContacts}
                        maxContacts={typeof maxContacts === 'string' ? 0 : maxContacts}
                        progress={progressValue}
                        sidebarIsNotPinned
                    />
                </SidebarHoverMenu>
            </Box>
        );
    }

    return (
        <ContactLimitDetails
            onUpgradeClick={upgradeClick}
            totalAmountOfContacts={totalAmountOfContacts}
            maxContacts={typeof maxContacts === 'string' ? 0 : maxContacts}
            progress={progressValue}
        />
    );
};

interface ContactLimitDetailsProps {
    onUpgradeClick: () => void;
    totalAmountOfContacts: number;
    maxContacts: number;
    progress: number;
    sidebarIsNotPinned?: boolean;
}

const ContactLimitDetails: React.FC<ContactLimitDetailsProps> = ({
    onUpgradeClick,
    totalAmountOfContacts,
    maxContacts,
    progress,
    sidebarIsNotPinned = false,
}) => {
    const CtaButton =
        totalAmountOfContacts === maxContacts ? (
            <Box
                onClick={onUpgradeClick}
                sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    width: '100%',
                    justifyContent: 'flex-start',
                    borderRadius: '8px',
                    cursor: 'pointer',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginY: '4px',
                    }}
                >
                    <CircleArrowUp size={20} strokeWidth={1} stroke="#6B7985" style={{ marginRight: '8px' }} />
                    <Typography variant="button" sx={{ textTransform: 'none' }}>
                        {`Upgrade ${companyNameCorrectlyCased}`}
                    </Typography>
                </Box>
            </Box>
        ) : (
            <Box
                onClick={onUpgradeClick}
                sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    width: '100%',
                    justifyContent: 'flex-start',
                    borderRadius: '8px',
                    cursor: 'pointer',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginY: '4px',
                    }}
                >
                    <Share2 size={20} strokeWidth={1} stroke="#6B7985" style={{ marginRight: '8px' }} />
                    <Typography variant="button" sx={{ textTransform: 'none' }}>
                        {`Refer to increase limit`}
                    </Typography>
                </Box>
            </Box>
        );

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '8px',
                borderRadius: '8px',
                backgroundColor: '#fff',
                boxShadow: sidebarIsNotPinned ? 'unset' : '0 1px 3px rgba(0, 0, 0, 0.1)',
                width: '100%',
                boxSizing: 'border-box',
                border: sidebarIsNotPinned ? 'none' : '1px solid #E4E6EB',
            }}
        >
            <Typography
                sx={{
                    fontSize: '13px',
                    marginBottom: '8px',
                }}
            >
                <span style={{ color: '#6B7985', fontWeight: 400 }}>
                    <b>
                        {totalAmountOfContacts}/{maxContacts}
                    </b>{' '}
                    contacts - upgrade or refer a friend to increase.
                </span>
            </Typography>
            <LinearProgress
                variant="determinate"
                value={progress}
                sx={{
                    width: '100%',
                    height: '8px',
                    borderRadius: '4px',
                    backgroundColor: '#e0e0e0',
                    '& .MuiLinearProgress-bar': {
                        background: 'linear-gradient(90deg, #9198F4 0%, #EB968B 100%)',
                    },
                    marginBottom: '8px',
                }}
            />
            {CtaButton}
        </Box>
    );
};
