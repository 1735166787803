import { IQuery, PaginationDto } from '@zaplify/common';
import { Topics } from '@zaplify/common';
import { UserDto } from '../users/user.dto';

export interface IPaginatedRepositoryQuery {
    match: any;
    sort?: any;
    page?: number;
    perPage?: number;
}

export class GetUsersQuery extends IQuery<PaginationDto<UserDto>> {
    public static override readonly TOPIC = Topics.USERS;

    constructor(readonly query: IPaginatedRepositoryQuery) {
        super();
    }
}
