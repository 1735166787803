import { PageViewDto } from '@zaplify/users/shared';
import { BaseSdk } from '../../base.sdk';

export class PageViewSdk extends BaseSdk {
    async recordPageView(pageViewDto: PageViewDto) {
        return this.post<void>({
            path: `${this.pageViewPath}`,
            payload: pageViewDto,
        });
    }
}
