import { Badge } from '@shadcn/ui/components/ui/badge';
import * as React from 'react';

export const RepliedBadge: React.FC = () => {
    return (
        <Badge variant="success">
            <div className="flex flex-row gap-2 items-center">
                <div className="w-1.5 h-1.5 rounded-full bg-success-700 dark:bg-success-500" />
                Replied
            </div>
        </Badge>
    );
};
