import { IEvent, Topics } from '@zaplify/common';

/**
 * Event used for tracking active users
 */
export class UserCheckedEmailActivitiesEvent extends IEvent {
    public static override readonly TOPIC = Topics.CAMPAIGNS;

    constructor(readonly userId: string) {
        super();
    }

    get partitionKey(): string {
        return this.userId;
    }
}
