import { Box, Button, List, Tooltip, Typography } from '@mui/material';
import { PlanCode } from '@zaplify/subscriptions';
import { useAtom } from 'jotai';

import { UserPlus2 } from 'lucide-react';
import { useEffect, useState } from 'react';

import { channelAccountsAtom, sidebarPinStateAtom } from '../../../atoms/chats';
import { selfServiceModalAtom } from '../../../atoms/selfService';
import { ContactLimitDisplay } from '../../../components/limit-exceeded-modal/contact-limit-display';
import ReferralModal from '../../../components/organisms/referral-modal';
import PickPlanModal from '../../../components/pick-plan-modal/PickPlanModal';
import { companyNameCorrectlyCased } from '../../../enums/company-name';
import { isGlobalAdmin as getIsGlobalAdmin } from '../../../helpers/is-global-admin';
import userResetAtoms from '../../../hooks/useResetAtoms';
import { useSeatsAndCredits } from '../../../new/hooks/use-seat-and-credits';
import useChannelAccountData from '../../../views/chats/hooks/use-channel-account-data';
import { useOutreachSuggestions } from '../../../views/chats/hooks/use-outreach-suggestions';
import { AccountDropdown } from './account-dropdown';
import { AddContactsListItem } from './navigation-list-items/add-contacts-list-item';
import { ActionsListItem } from './navigation-list-items/actions-list-item';
import { PlaybooksListItem } from './navigation-list-items/playbooks-list-item';
import { SearchBox } from './search-box';
import { TurnOnNewZaplifyButton } from './turn-on-new-zaplify-button';
import { AllConversationsListItem } from './navigation-list-items/all-conversations-list-item';
import { ContactsListItem } from './navigation-list-items/contacts-list-item';

export const NavigationDrawer = () => {
    const [channelAccountsFromAtom] = useAtom(channelAccountsAtom);
    const [selfServiceModal] = useAtom(selfServiceModalAtom);
    const [sidebarPinState, setSidebarPinState] = useAtom(sidebarPinStateAtom);
    const { resetAllAtoms } = userResetAtoms();
    const [displayReferralModal, setDisplayReferralModal] = useState(false);
    const [displayUpgradeModal, setDisplayUpgradeModal] = useState(false);
    const [isGlobalAdmin, setIsGlobalAdmin] = useState(false);
    const { outreachSuggestions } = useOutreachSuggestions();
    const { refetchChannelAccounts } = useChannelAccountData();
    const { seat } = useSeatsAndCredits();

    const planCode = seat?.subscription?.planCode;
    const onFreePlan = planCode === PlanCode.FREE;

    useEffect(() => {
        (async () => {
            setIsGlobalAdmin(await getIsGlobalAdmin());
        })();

        if (selfServiceModal === 'none') {
            resetAllAtoms();
            refetchChannelAccounts();
        }
    }, []);

    const isSidebarPinned = sidebarPinState === 'pinned';

    return (
        <Box
            component="nav"
            className="navigation-drawer"
            sx={{
                position: 'relative',
                p: 1.3,
                paddingTop: 'var(--spacing-xl, 12px)',
                width: isSidebarPinned ? '190px' : '40px',
                display: 'flex',
                flexDirection: 'column',
                gap: 0.5,
                transition: 'all 0.3s ease',
                padding: 'var(--S-s-regular, 8px)',
                maxHeight: 'calc(100vh - 20px)',
                '& .search-button': {
                    marginTop: '14px',
                    // marginTop: '6px',
                },
                '& .MuiTypography-root': {
                    overflow: 'hidden',
                    color: 'var(--grey-palette-grey-5, #3A4B59)',
                    textOverflow: 'ellipsis',
                    fontSize: '14px',
                    fontWeight: 500,
                    maxHeight: '24px',
                },
                '& .MuiListItemIcon-root': {
                    minWidth: '0',
                    mx: '6px',
                },
                '& .MuiListItemText-root': {
                    margin: 0,
                },
                '& a, & a *': {
                    textDecoration: 'none !important',
                },
            }}
        >
            <AccountDropdown
                handleChangeSidebarState={() =>
                    setSidebarPinState((prev) => (prev === 'pinned' ? 'unpinned' : 'pinned'))
                }
                isSidebarPinned={isSidebarPinned}
                currentAvatarUrl={channelAccountsFromAtom.linkedIn.avatarUrl}
                openReferralModal={() => setDisplayReferralModal(true)}
            />

            <SearchBox sideBarIsOpen={isSidebarPinned} />
            <List
                component="nav"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '6px',
                    py: 0,
                    overflowY: 'auto',
                    marginTop: '6px',
                }}
            >
                <ActionsListItem openSidebar={isSidebarPinned} numOutreachSuggestions={outreachSuggestions.length} />
                <PlaybooksListItem openSidebar={isSidebarPinned} />
                <AllConversationsListItem openSidebar={isSidebarPinned} />
                <ContactsListItem openSidebar={isSidebarPinned} />
                <AddContactsListItem openSidebar={isSidebarPinned} />
            </List>
            <Box sx={{ marginTop: 'auto', display: 'flex', flexDirection: 'column', gap: '8px' }}>
                <ContactLimitDisplay
                    onUpgradeClick={() => setDisplayUpgradeModal(true)}
                    isSidebarPinned={isSidebarPinned}
                    showReferralModal={() => setDisplayReferralModal(true)}
                />
                <Tooltip title={isSidebarPinned ? '' : 'Refer a friend'} placement="right" arrow>
                    {isSidebarPinned ? (
                        onFreePlan ? (
                            <Box sx={{}}>
                                <Button
                                    sx={{
                                        width: '100%',
                                        height: '20px',
                                        gap: '6px',
                                        borderRadius: '8px',
                                        padding: '20px',
                                        opacity: 1,
                                        background: '#838dfc',
                                        '&:hover': {
                                            backgroundColor: '#6A76FB',
                                            opacity: 0.9,
                                        },
                                    }}
                                    onClick={() => {
                                        setDisplayReferralModal(true);
                                    }}
                                >
                                    <UserPlus2 size={20} stroke="#fff" strokeWidth="1px" />
                                    <Typography style={{ fontSize: '14px', fontWeight: 500, color: '#fff' }}>
                                        Refer a friend
                                    </Typography>
                                </Button>
                            </Box>
                        ) : (
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '6px',
                                    margin: isSidebarPinned ? '10px' : '6px',
                                    marginTop: isSidebarPinned ? '6px' : '0',
                                    '&:hover': {
                                        cursor: 'pointer',
                                    },
                                }}
                                onClick={() => {
                                    setDisplayReferralModal(true);
                                }}
                            >
                                <UserPlus2 size={20} stroke="#6B7985" strokeWidth="1px" />
                                {isSidebarPinned && (
                                    <Typography style={{ fontSize: '14px', fontWeight: 500 }} color="#3A4B59">
                                        Refer a friend
                                    </Typography>
                                )}
                            </Box>
                        )
                    ) : (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                '&:hover': {
                                    cursor: 'pointer',
                                },
                                padding: '2px',
                                paddingBottom: '8px',
                            }}
                            onClick={() => {
                                setDisplayReferralModal(true);
                            }}
                        >
                            <UserPlus2 size={20} stroke="#6B7985" strokeWidth="1px" />
                        </Box>
                    )}
                </Tooltip>
                {displayUpgradeModal && (
                    <PickPlanModal
                        open={displayUpgradeModal}
                        upgradeAccount
                        closeCallback={() => setDisplayUpgradeModal(false)}
                    >
                        <Box width="100%" display={'flex'} justifyContent={'center'}>
                            <Typography variant="h4">{`Upgrade your ${companyNameCorrectlyCased} account`}</Typography>
                        </Box>
                    </PickPlanModal>
                )}
                <ReferralModal
                    open={displayReferralModal}
                    onClose={() => setDisplayReferralModal(false)}
                    onClickUpgrade={() => setDisplayUpgradeModal(true)}
                />
                {isGlobalAdmin && <TurnOnNewZaplifyButton openSidebar={isSidebarPinned} />}
            </Box>
        </Box>
    );
};
