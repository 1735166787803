export function strip(html: string) {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
}

export function htmlToPlain(html: string) {
    if (!html) return html;
    html = html.replace(/<\/div>/gi, '\n');
    html = html.replace(/<\/li>/gi, '\n');
    html = html.replace(/<li>/gi, '  *  ');
    html = html.replace(/<\/ul>/gi, '\n');
    html = html.replace(/<\/h[1-5]>/gi, '\n');
    html = html.replace(/<\/p>/gi, '\n');
    html = html.replace(/<br.*?>/gi, '\n');
    html = html.replace(/<[^>]+>/gi, '');
    html = html.replace(/&(nbsp|ensp|emsp|emsp13|emsp14|numsp|puncsp|hairsp|MediumSpace|thinsp|);/gi, ' ');

    return html;
}
