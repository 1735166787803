import { isURL } from 'class-validator';
import { isEmailCorrect } from '../../services/utils/emailHelper';

export const isUrlValid = (url: string): boolean => {
    if (!url) return false;
    return isURL(url);
};
export const validateEmail = (email) => {
    let errorMessage = '';
    if (!email) {
        errorMessage = 'Required';
    } else if (!isEmailCorrect(email)) {
        errorMessage = 'Invalid email address';
    }
    return errorMessage;
};

export const validateUrl = (website) => {
    let errorMessage = '';
    if (!website) {
        errorMessage = 'Required';
    } else if (!isUrlValid(website)) {
        errorMessage = 'Invalid website URL';
    }
    return errorMessage;
};
