import { IEvent, Topics } from '@zaplify/common';

export class GroupRemovedEvent extends IEvent {
    public static override readonly TOPIC: Topics = Topics.PROSPECTS;
    public readonly groupId: string;
    public readonly userId: string;
    public readonly assistantSettingId: string;

    constructor(params: { groupId: string; userId: string; assistantSettingId: string }) {
        super();
        this.groupId = params.groupId;
        this.userId = params.userId;
        this.assistantSettingId = params.assistantSettingId;
    }

    get partitionKey(): string {
        return this.userId;
    }
}
