/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string; output: string };
    String: { input: string; output: string };
    Boolean: { input: boolean; output: boolean };
    Int: { input: number; output: number };
    Float: { input: number; output: number };
    AttachmentType: { input: any; output: any };
    ChannelType: { input: any; output: any };
    ContactSuggestionReason: { input: any; output: any };
    Creator: { input: any; output: any };
    DeliveryStatus: { input: any; output: any };
    MessageType: { input: any; output: any };
    UserContactStatus: { input: any; output: any };
    bigint: { input: any; output: any };
    float8: { input: any; output: any };
    timestamp: { input: any; output: any };
};

/** columns and relationships of "AssistantSettings" */
export type AssistantSettings = {
    __typename?: 'AssistantSettings';
    /** An array relationship */
    Groups: Array<Groups>;
    /** An aggregate relationship */
    Groups_aggregate: Groups_Aggregate;
    context?: Maybe<Scalars['String']['output']>;
    createdAt: Scalars['timestamp']['output'];
    defaultChannel: Scalars['ChannelType']['output'];
    id: Scalars['String']['output'];
    language: Scalars['String']['output'];
    languages?: Maybe<Array<Scalars['String']['output']>>;
    organizationId: Scalars['String']['output'];
    purpose: Scalars['String']['output'];
    targetGroup?: Maybe<Scalars['String']['output']>;
    tone: Scalars['String']['output'];
    updatedAt: Scalars['timestamp']['output'];
    userId: Scalars['String']['output'];
};

/** columns and relationships of "AssistantSettings" */
export type AssistantSettingsGroupsArgs = {
    distinct_on?: InputMaybe<Array<Groups_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<Groups_Order_By>>;
    where?: InputMaybe<Groups_Bool_Exp>;
};

/** columns and relationships of "AssistantSettings" */
export type AssistantSettingsGroups_AggregateArgs = {
    distinct_on?: InputMaybe<Array<Groups_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<Groups_Order_By>>;
    where?: InputMaybe<Groups_Bool_Exp>;
};

/** aggregated selection of "AssistantSettings" */
export type AssistantSettings_Aggregate = {
    __typename?: 'AssistantSettings_aggregate';
    aggregate?: Maybe<AssistantSettings_Aggregate_Fields>;
    nodes: Array<AssistantSettings>;
};

/** aggregate fields of "AssistantSettings" */
export type AssistantSettings_Aggregate_Fields = {
    __typename?: 'AssistantSettings_aggregate_fields';
    count: Scalars['Int']['output'];
    max?: Maybe<AssistantSettings_Max_Fields>;
    min?: Maybe<AssistantSettings_Min_Fields>;
};

/** aggregate fields of "AssistantSettings" */
export type AssistantSettings_Aggregate_FieldsCountArgs = {
    columns?: InputMaybe<Array<AssistantSettings_Select_Column>>;
    distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "AssistantSettings". All fields are combined with a logical 'AND'. */
export type AssistantSettings_Bool_Exp = {
    Groups?: InputMaybe<Groups_Bool_Exp>;
    Groups_aggregate?: InputMaybe<Groups_Aggregate_Bool_Exp>;
    _and?: InputMaybe<Array<AssistantSettings_Bool_Exp>>;
    _not?: InputMaybe<AssistantSettings_Bool_Exp>;
    _or?: InputMaybe<Array<AssistantSettings_Bool_Exp>>;
    context?: InputMaybe<String_Comparison_Exp>;
    createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
    defaultChannel?: InputMaybe<ChannelType_Comparison_Exp>;
    id?: InputMaybe<String_Comparison_Exp>;
    language?: InputMaybe<String_Comparison_Exp>;
    languages?: InputMaybe<String_Array_Comparison_Exp>;
    organizationId?: InputMaybe<String_Comparison_Exp>;
    purpose?: InputMaybe<String_Comparison_Exp>;
    targetGroup?: InputMaybe<String_Comparison_Exp>;
    tone?: InputMaybe<String_Comparison_Exp>;
    updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
    userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "AssistantSettings" */
export enum AssistantSettings_Constraint {
    /** unique or primary key constraint on columns "id" */
    AssistantSettingsPkey = 'AssistantSettings_pkey',
}

/** input type for inserting data into table "AssistantSettings" */
export type AssistantSettings_Insert_Input = {
    Groups?: InputMaybe<Groups_Arr_Rel_Insert_Input>;
    context?: InputMaybe<Scalars['String']['input']>;
    createdAt?: InputMaybe<Scalars['timestamp']['input']>;
    defaultChannel?: InputMaybe<Scalars['ChannelType']['input']>;
    id?: InputMaybe<Scalars['String']['input']>;
    language?: InputMaybe<Scalars['String']['input']>;
    languages?: InputMaybe<Array<Scalars['String']['input']>>;
    organizationId?: InputMaybe<Scalars['String']['input']>;
    purpose?: InputMaybe<Scalars['String']['input']>;
    targetGroup?: InputMaybe<Scalars['String']['input']>;
    tone?: InputMaybe<Scalars['String']['input']>;
    updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
    userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type AssistantSettings_Max_Fields = {
    __typename?: 'AssistantSettings_max_fields';
    context?: Maybe<Scalars['String']['output']>;
    createdAt?: Maybe<Scalars['timestamp']['output']>;
    defaultChannel?: Maybe<Scalars['ChannelType']['output']>;
    id?: Maybe<Scalars['String']['output']>;
    language?: Maybe<Scalars['String']['output']>;
    languages?: Maybe<Array<Scalars['String']['output']>>;
    organizationId?: Maybe<Scalars['String']['output']>;
    purpose?: Maybe<Scalars['String']['output']>;
    targetGroup?: Maybe<Scalars['String']['output']>;
    tone?: Maybe<Scalars['String']['output']>;
    updatedAt?: Maybe<Scalars['timestamp']['output']>;
    userId?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type AssistantSettings_Min_Fields = {
    __typename?: 'AssistantSettings_min_fields';
    context?: Maybe<Scalars['String']['output']>;
    createdAt?: Maybe<Scalars['timestamp']['output']>;
    defaultChannel?: Maybe<Scalars['ChannelType']['output']>;
    id?: Maybe<Scalars['String']['output']>;
    language?: Maybe<Scalars['String']['output']>;
    languages?: Maybe<Array<Scalars['String']['output']>>;
    organizationId?: Maybe<Scalars['String']['output']>;
    purpose?: Maybe<Scalars['String']['output']>;
    targetGroup?: Maybe<Scalars['String']['output']>;
    tone?: Maybe<Scalars['String']['output']>;
    updatedAt?: Maybe<Scalars['timestamp']['output']>;
    userId?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "AssistantSettings" */
export type AssistantSettings_Mutation_Response = {
    __typename?: 'AssistantSettings_mutation_response';
    /** number of rows affected by the mutation */
    affected_rows: Scalars['Int']['output'];
    /** data from the rows affected by the mutation */
    returning: Array<AssistantSettings>;
};

/** input type for inserting object relation for remote table "AssistantSettings" */
export type AssistantSettings_Obj_Rel_Insert_Input = {
    data: AssistantSettings_Insert_Input;
    /** upsert condition */
    on_conflict?: InputMaybe<AssistantSettings_On_Conflict>;
};

/** on_conflict condition type for table "AssistantSettings" */
export type AssistantSettings_On_Conflict = {
    constraint: AssistantSettings_Constraint;
    update_columns?: Array<AssistantSettings_Update_Column>;
    where?: InputMaybe<AssistantSettings_Bool_Exp>;
};

/** Ordering options when selecting data from "AssistantSettings". */
export type AssistantSettings_Order_By = {
    Groups_aggregate?: InputMaybe<Groups_Aggregate_Order_By>;
    context?: InputMaybe<Order_By>;
    createdAt?: InputMaybe<Order_By>;
    defaultChannel?: InputMaybe<Order_By>;
    id?: InputMaybe<Order_By>;
    language?: InputMaybe<Order_By>;
    languages?: InputMaybe<Order_By>;
    organizationId?: InputMaybe<Order_By>;
    purpose?: InputMaybe<Order_By>;
    targetGroup?: InputMaybe<Order_By>;
    tone?: InputMaybe<Order_By>;
    updatedAt?: InputMaybe<Order_By>;
    userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: AssistantSettings */
export type AssistantSettings_Pk_Columns_Input = {
    id: Scalars['String']['input'];
};

/** select columns of table "AssistantSettings" */
export enum AssistantSettings_Select_Column {
    /** column name */
    Context = 'context',
    /** column name */
    CreatedAt = 'createdAt',
    /** column name */
    DefaultChannel = 'defaultChannel',
    /** column name */
    Id = 'id',
    /** column name */
    Language = 'language',
    /** column name */
    Languages = 'languages',
    /** column name */
    OrganizationId = 'organizationId',
    /** column name */
    Purpose = 'purpose',
    /** column name */
    TargetGroup = 'targetGroup',
    /** column name */
    Tone = 'tone',
    /** column name */
    UpdatedAt = 'updatedAt',
    /** column name */
    UserId = 'userId',
}

/** input type for updating data in table "AssistantSettings" */
export type AssistantSettings_Set_Input = {
    context?: InputMaybe<Scalars['String']['input']>;
    createdAt?: InputMaybe<Scalars['timestamp']['input']>;
    defaultChannel?: InputMaybe<Scalars['ChannelType']['input']>;
    id?: InputMaybe<Scalars['String']['input']>;
    language?: InputMaybe<Scalars['String']['input']>;
    languages?: InputMaybe<Array<Scalars['String']['input']>>;
    organizationId?: InputMaybe<Scalars['String']['input']>;
    purpose?: InputMaybe<Scalars['String']['input']>;
    targetGroup?: InputMaybe<Scalars['String']['input']>;
    tone?: InputMaybe<Scalars['String']['input']>;
    updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
    userId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "AssistantSettings" */
export type AssistantSettings_Stream_Cursor_Input = {
    /** Stream column input with initial value */
    initial_value: AssistantSettings_Stream_Cursor_Value_Input;
    /** cursor ordering */
    ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type AssistantSettings_Stream_Cursor_Value_Input = {
    context?: InputMaybe<Scalars['String']['input']>;
    createdAt?: InputMaybe<Scalars['timestamp']['input']>;
    defaultChannel?: InputMaybe<Scalars['ChannelType']['input']>;
    id?: InputMaybe<Scalars['String']['input']>;
    language?: InputMaybe<Scalars['String']['input']>;
    languages?: InputMaybe<Array<Scalars['String']['input']>>;
    organizationId?: InputMaybe<Scalars['String']['input']>;
    purpose?: InputMaybe<Scalars['String']['input']>;
    targetGroup?: InputMaybe<Scalars['String']['input']>;
    tone?: InputMaybe<Scalars['String']['input']>;
    updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
    userId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "AssistantSettings" */
export enum AssistantSettings_Update_Column {
    /** column name */
    Context = 'context',
    /** column name */
    CreatedAt = 'createdAt',
    /** column name */
    DefaultChannel = 'defaultChannel',
    /** column name */
    Id = 'id',
    /** column name */
    Language = 'language',
    /** column name */
    Languages = 'languages',
    /** column name */
    OrganizationId = 'organizationId',
    /** column name */
    Purpose = 'purpose',
    /** column name */
    TargetGroup = 'targetGroup',
    /** column name */
    Tone = 'tone',
    /** column name */
    UpdatedAt = 'updatedAt',
    /** column name */
    UserId = 'userId',
}

export type AssistantSettings_Updates = {
    /** sets the columns of the filtered rows to the given values */
    _set?: InputMaybe<AssistantSettings_Set_Input>;
    /** filter the rows which have to be updated */
    where: AssistantSettings_Bool_Exp;
};

/** Boolean expression to compare columns of type "AttachmentType". All fields are combined with logical 'AND'. */
export type AttachmentType_Comparison_Exp = {
    _eq?: InputMaybe<Scalars['AttachmentType']['input']>;
    _gt?: InputMaybe<Scalars['AttachmentType']['input']>;
    _gte?: InputMaybe<Scalars['AttachmentType']['input']>;
    _in?: InputMaybe<Array<Scalars['AttachmentType']['input']>>;
    _is_null?: InputMaybe<Scalars['Boolean']['input']>;
    _lt?: InputMaybe<Scalars['AttachmentType']['input']>;
    _lte?: InputMaybe<Scalars['AttachmentType']['input']>;
    _neq?: InputMaybe<Scalars['AttachmentType']['input']>;
    _nin?: InputMaybe<Array<Scalars['AttachmentType']['input']>>;
};

/** columns and relationships of "Attachments" */
export type Attachments = {
    __typename?: 'Attachments';
    /** An object relationship */
    Message: Messages;
    createdAt: Scalars['timestamp']['output'];
    id: Scalars['String']['output'];
    messageId: Scalars['String']['output'];
    type: Scalars['AttachmentType']['output'];
    userId: Scalars['String']['output'];
    value: Scalars['String']['output'];
};

/** aggregated selection of "Attachments" */
export type Attachments_Aggregate = {
    __typename?: 'Attachments_aggregate';
    aggregate?: Maybe<Attachments_Aggregate_Fields>;
    nodes: Array<Attachments>;
};

export type Attachments_Aggregate_Bool_Exp = {
    count?: InputMaybe<Attachments_Aggregate_Bool_Exp_Count>;
};

export type Attachments_Aggregate_Bool_Exp_Count = {
    arguments?: InputMaybe<Array<Attachments_Select_Column>>;
    distinct?: InputMaybe<Scalars['Boolean']['input']>;
    filter?: InputMaybe<Attachments_Bool_Exp>;
    predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Attachments" */
export type Attachments_Aggregate_Fields = {
    __typename?: 'Attachments_aggregate_fields';
    count: Scalars['Int']['output'];
    max?: Maybe<Attachments_Max_Fields>;
    min?: Maybe<Attachments_Min_Fields>;
};

/** aggregate fields of "Attachments" */
export type Attachments_Aggregate_FieldsCountArgs = {
    columns?: InputMaybe<Array<Attachments_Select_Column>>;
    distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "Attachments" */
export type Attachments_Aggregate_Order_By = {
    count?: InputMaybe<Order_By>;
    max?: InputMaybe<Attachments_Max_Order_By>;
    min?: InputMaybe<Attachments_Min_Order_By>;
};

/** input type for inserting array relation for remote table "Attachments" */
export type Attachments_Arr_Rel_Insert_Input = {
    data: Array<Attachments_Insert_Input>;
    /** upsert condition */
    on_conflict?: InputMaybe<Attachments_On_Conflict>;
};

/** Boolean expression to filter rows from the table "Attachments". All fields are combined with a logical 'AND'. */
export type Attachments_Bool_Exp = {
    Message?: InputMaybe<Messages_Bool_Exp>;
    _and?: InputMaybe<Array<Attachments_Bool_Exp>>;
    _not?: InputMaybe<Attachments_Bool_Exp>;
    _or?: InputMaybe<Array<Attachments_Bool_Exp>>;
    createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
    id?: InputMaybe<String_Comparison_Exp>;
    messageId?: InputMaybe<String_Comparison_Exp>;
    type?: InputMaybe<AttachmentType_Comparison_Exp>;
    userId?: InputMaybe<String_Comparison_Exp>;
    value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Attachments" */
export enum Attachments_Constraint {
    /** unique or primary key constraint on columns "id" */
    AttachmentsPkey = 'Attachments_pkey',
}

/** input type for inserting data into table "Attachments" */
export type Attachments_Insert_Input = {
    Message?: InputMaybe<Messages_Obj_Rel_Insert_Input>;
    createdAt?: InputMaybe<Scalars['timestamp']['input']>;
    id?: InputMaybe<Scalars['String']['input']>;
    messageId?: InputMaybe<Scalars['String']['input']>;
    type?: InputMaybe<Scalars['AttachmentType']['input']>;
    userId?: InputMaybe<Scalars['String']['input']>;
    value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Attachments_Max_Fields = {
    __typename?: 'Attachments_max_fields';
    createdAt?: Maybe<Scalars['timestamp']['output']>;
    id?: Maybe<Scalars['String']['output']>;
    messageId?: Maybe<Scalars['String']['output']>;
    type?: Maybe<Scalars['AttachmentType']['output']>;
    userId?: Maybe<Scalars['String']['output']>;
    value?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "Attachments" */
export type Attachments_Max_Order_By = {
    createdAt?: InputMaybe<Order_By>;
    id?: InputMaybe<Order_By>;
    messageId?: InputMaybe<Order_By>;
    type?: InputMaybe<Order_By>;
    userId?: InputMaybe<Order_By>;
    value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Attachments_Min_Fields = {
    __typename?: 'Attachments_min_fields';
    createdAt?: Maybe<Scalars['timestamp']['output']>;
    id?: Maybe<Scalars['String']['output']>;
    messageId?: Maybe<Scalars['String']['output']>;
    type?: Maybe<Scalars['AttachmentType']['output']>;
    userId?: Maybe<Scalars['String']['output']>;
    value?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "Attachments" */
export type Attachments_Min_Order_By = {
    createdAt?: InputMaybe<Order_By>;
    id?: InputMaybe<Order_By>;
    messageId?: InputMaybe<Order_By>;
    type?: InputMaybe<Order_By>;
    userId?: InputMaybe<Order_By>;
    value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Attachments" */
export type Attachments_Mutation_Response = {
    __typename?: 'Attachments_mutation_response';
    /** number of rows affected by the mutation */
    affected_rows: Scalars['Int']['output'];
    /** data from the rows affected by the mutation */
    returning: Array<Attachments>;
};

/** on_conflict condition type for table "Attachments" */
export type Attachments_On_Conflict = {
    constraint: Attachments_Constraint;
    update_columns?: Array<Attachments_Update_Column>;
    where?: InputMaybe<Attachments_Bool_Exp>;
};

/** Ordering options when selecting data from "Attachments". */
export type Attachments_Order_By = {
    Message?: InputMaybe<Messages_Order_By>;
    createdAt?: InputMaybe<Order_By>;
    id?: InputMaybe<Order_By>;
    messageId?: InputMaybe<Order_By>;
    type?: InputMaybe<Order_By>;
    userId?: InputMaybe<Order_By>;
    value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Attachments */
export type Attachments_Pk_Columns_Input = {
    id: Scalars['String']['input'];
};

/** select columns of table "Attachments" */
export enum Attachments_Select_Column {
    /** column name */
    CreatedAt = 'createdAt',
    /** column name */
    Id = 'id',
    /** column name */
    MessageId = 'messageId',
    /** column name */
    Type = 'type',
    /** column name */
    UserId = 'userId',
    /** column name */
    Value = 'value',
}

/** input type for updating data in table "Attachments" */
export type Attachments_Set_Input = {
    createdAt?: InputMaybe<Scalars['timestamp']['input']>;
    id?: InputMaybe<Scalars['String']['input']>;
    messageId?: InputMaybe<Scalars['String']['input']>;
    type?: InputMaybe<Scalars['AttachmentType']['input']>;
    userId?: InputMaybe<Scalars['String']['input']>;
    value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "Attachments" */
export type Attachments_Stream_Cursor_Input = {
    /** Stream column input with initial value */
    initial_value: Attachments_Stream_Cursor_Value_Input;
    /** cursor ordering */
    ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Attachments_Stream_Cursor_Value_Input = {
    createdAt?: InputMaybe<Scalars['timestamp']['input']>;
    id?: InputMaybe<Scalars['String']['input']>;
    messageId?: InputMaybe<Scalars['String']['input']>;
    type?: InputMaybe<Scalars['AttachmentType']['input']>;
    userId?: InputMaybe<Scalars['String']['input']>;
    value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "Attachments" */
export enum Attachments_Update_Column {
    /** column name */
    CreatedAt = 'createdAt',
    /** column name */
    Id = 'id',
    /** column name */
    MessageId = 'messageId',
    /** column name */
    Type = 'type',
    /** column name */
    UserId = 'userId',
    /** column name */
    Value = 'value',
}

export type Attachments_Updates = {
    /** sets the columns of the filtered rows to the given values */
    _set?: InputMaybe<Attachments_Set_Input>;
    /** filter the rows which have to be updated */
    where: Attachments_Bool_Exp;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
    _eq?: InputMaybe<Scalars['Boolean']['input']>;
    _gt?: InputMaybe<Scalars['Boolean']['input']>;
    _gte?: InputMaybe<Scalars['Boolean']['input']>;
    _in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
    _is_null?: InputMaybe<Scalars['Boolean']['input']>;
    _lt?: InputMaybe<Scalars['Boolean']['input']>;
    _lte?: InputMaybe<Scalars['Boolean']['input']>;
    _neq?: InputMaybe<Scalars['Boolean']['input']>;
    _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

/** Boolean expression to compare columns of type "ChannelType". All fields are combined with logical 'AND'. */
export type ChannelType_Comparison_Exp = {
    _eq?: InputMaybe<Scalars['ChannelType']['input']>;
    _gt?: InputMaybe<Scalars['ChannelType']['input']>;
    _gte?: InputMaybe<Scalars['ChannelType']['input']>;
    _in?: InputMaybe<Array<Scalars['ChannelType']['input']>>;
    _is_null?: InputMaybe<Scalars['Boolean']['input']>;
    _lt?: InputMaybe<Scalars['ChannelType']['input']>;
    _lte?: InputMaybe<Scalars['ChannelType']['input']>;
    _neq?: InputMaybe<Scalars['ChannelType']['input']>;
    _nin?: InputMaybe<Array<Scalars['ChannelType']['input']>>;
};

/** Boolean expression to compare columns of type "ContactSuggestionReason". All fields are combined with logical 'AND'. */
export type ContactSuggestionReason_Comparison_Exp = {
    _eq?: InputMaybe<Scalars['ContactSuggestionReason']['input']>;
    _gt?: InputMaybe<Scalars['ContactSuggestionReason']['input']>;
    _gte?: InputMaybe<Scalars['ContactSuggestionReason']['input']>;
    _in?: InputMaybe<Array<Scalars['ContactSuggestionReason']['input']>>;
    _is_null?: InputMaybe<Scalars['Boolean']['input']>;
    _lt?: InputMaybe<Scalars['ContactSuggestionReason']['input']>;
    _lte?: InputMaybe<Scalars['ContactSuggestionReason']['input']>;
    _neq?: InputMaybe<Scalars['ContactSuggestionReason']['input']>;
    _nin?: InputMaybe<Array<Scalars['ContactSuggestionReason']['input']>>;
};

/** columns and relationships of "ContactSuggestions" */
export type ContactSuggestions = {
    __typename?: 'ContactSuggestions';
    accountAboutUs?: Maybe<Scalars['String']['output']>;
    accountId?: Maybe<Scalars['String']['output']>;
    accountIndustry?: Maybe<Scalars['String']['output']>;
    accountLinkedinUrl?: Maybe<Scalars['String']['output']>;
    accountName: Scalars['String']['output'];
    accountNumberOfEmployees?: Maybe<Scalars['String']['output']>;
    accountTags?: Maybe<Array<Scalars['String']['output']>>;
    accountWebsite?: Maybe<Scalars['String']['output']>;
    approvedAt?: Maybe<Scalars['timestamp']['output']>;
    campaignId?: Maybe<Scalars['String']['output']>;
    campaignName?: Maybe<Scalars['String']['output']>;
    contactEmail?: Maybe<Scalars['String']['output']>;
    contactEmailVerified: Scalars['Boolean']['output'];
    contactImgUrl?: Maybe<Scalars['String']['output']>;
    contactLinkedinUrl?: Maybe<Scalars['String']['output']>;
    contactLocation?: Maybe<Scalars['String']['output']>;
    contactName: Scalars['String']['output'];
    contactTitle?: Maybe<Scalars['String']['output']>;
    createdAt: Scalars['timestamp']['output'];
    expiresAt: Scalars['timestamp']['output'];
    groupId?: Maybe<Scalars['String']['output']>;
    id: Scalars['String']['output'];
    organizationId: Scalars['String']['output'];
    personId?: Maybe<Scalars['String']['output']>;
    prospectId?: Maybe<Scalars['String']['output']>;
    reason: Scalars['ContactSuggestionReason']['output'];
    rejectedAt?: Maybe<Scalars['timestamp']['output']>;
    suggestionMotivation?: Maybe<Scalars['String']['output']>;
    updatedAt: Scalars['timestamp']['output'];
    userId: Scalars['String']['output'];
};

/** aggregated selection of "ContactSuggestions" */
export type ContactSuggestions_Aggregate = {
    __typename?: 'ContactSuggestions_aggregate';
    aggregate?: Maybe<ContactSuggestions_Aggregate_Fields>;
    nodes: Array<ContactSuggestions>;
};

/** aggregate fields of "ContactSuggestions" */
export type ContactSuggestions_Aggregate_Fields = {
    __typename?: 'ContactSuggestions_aggregate_fields';
    count: Scalars['Int']['output'];
    max?: Maybe<ContactSuggestions_Max_Fields>;
    min?: Maybe<ContactSuggestions_Min_Fields>;
};

/** aggregate fields of "ContactSuggestions" */
export type ContactSuggestions_Aggregate_FieldsCountArgs = {
    columns?: InputMaybe<Array<ContactSuggestions_Select_Column>>;
    distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "ContactSuggestions". All fields are combined with a logical 'AND'. */
export type ContactSuggestions_Bool_Exp = {
    _and?: InputMaybe<Array<ContactSuggestions_Bool_Exp>>;
    _not?: InputMaybe<ContactSuggestions_Bool_Exp>;
    _or?: InputMaybe<Array<ContactSuggestions_Bool_Exp>>;
    accountAboutUs?: InputMaybe<String_Comparison_Exp>;
    accountId?: InputMaybe<String_Comparison_Exp>;
    accountIndustry?: InputMaybe<String_Comparison_Exp>;
    accountLinkedinUrl?: InputMaybe<String_Comparison_Exp>;
    accountName?: InputMaybe<String_Comparison_Exp>;
    accountNumberOfEmployees?: InputMaybe<String_Comparison_Exp>;
    accountTags?: InputMaybe<String_Array_Comparison_Exp>;
    accountWebsite?: InputMaybe<String_Comparison_Exp>;
    approvedAt?: InputMaybe<Timestamp_Comparison_Exp>;
    campaignId?: InputMaybe<String_Comparison_Exp>;
    campaignName?: InputMaybe<String_Comparison_Exp>;
    contactEmail?: InputMaybe<String_Comparison_Exp>;
    contactEmailVerified?: InputMaybe<Boolean_Comparison_Exp>;
    contactImgUrl?: InputMaybe<String_Comparison_Exp>;
    contactLinkedinUrl?: InputMaybe<String_Comparison_Exp>;
    contactLocation?: InputMaybe<String_Comparison_Exp>;
    contactName?: InputMaybe<String_Comparison_Exp>;
    contactTitle?: InputMaybe<String_Comparison_Exp>;
    createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
    expiresAt?: InputMaybe<Timestamp_Comparison_Exp>;
    groupId?: InputMaybe<String_Comparison_Exp>;
    id?: InputMaybe<String_Comparison_Exp>;
    organizationId?: InputMaybe<String_Comparison_Exp>;
    personId?: InputMaybe<String_Comparison_Exp>;
    prospectId?: InputMaybe<String_Comparison_Exp>;
    reason?: InputMaybe<ContactSuggestionReason_Comparison_Exp>;
    rejectedAt?: InputMaybe<Timestamp_Comparison_Exp>;
    suggestionMotivation?: InputMaybe<String_Comparison_Exp>;
    updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
    userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ContactSuggestions" */
export enum ContactSuggestions_Constraint {
    /** unique or primary key constraint on columns "id" */
    ContactSuggestionsPkey = 'ContactSuggestions_pkey',
    /** unique or primary key constraint on columns "userId", "campaignId", "personId" */
    ContactSuggestionsUserIdPersonIdCampaignIdKey = 'ContactSuggestions_userId_personId_campaignId_key',
}

/** input type for inserting data into table "ContactSuggestions" */
export type ContactSuggestions_Insert_Input = {
    accountAboutUs?: InputMaybe<Scalars['String']['input']>;
    accountId?: InputMaybe<Scalars['String']['input']>;
    accountIndustry?: InputMaybe<Scalars['String']['input']>;
    accountLinkedinUrl?: InputMaybe<Scalars['String']['input']>;
    accountName?: InputMaybe<Scalars['String']['input']>;
    accountNumberOfEmployees?: InputMaybe<Scalars['String']['input']>;
    accountTags?: InputMaybe<Array<Scalars['String']['input']>>;
    accountWebsite?: InputMaybe<Scalars['String']['input']>;
    approvedAt?: InputMaybe<Scalars['timestamp']['input']>;
    campaignId?: InputMaybe<Scalars['String']['input']>;
    campaignName?: InputMaybe<Scalars['String']['input']>;
    contactEmail?: InputMaybe<Scalars['String']['input']>;
    contactEmailVerified?: InputMaybe<Scalars['Boolean']['input']>;
    contactImgUrl?: InputMaybe<Scalars['String']['input']>;
    contactLinkedinUrl?: InputMaybe<Scalars['String']['input']>;
    contactLocation?: InputMaybe<Scalars['String']['input']>;
    contactName?: InputMaybe<Scalars['String']['input']>;
    contactTitle?: InputMaybe<Scalars['String']['input']>;
    createdAt?: InputMaybe<Scalars['timestamp']['input']>;
    expiresAt?: InputMaybe<Scalars['timestamp']['input']>;
    groupId?: InputMaybe<Scalars['String']['input']>;
    id?: InputMaybe<Scalars['String']['input']>;
    organizationId?: InputMaybe<Scalars['String']['input']>;
    personId?: InputMaybe<Scalars['String']['input']>;
    prospectId?: InputMaybe<Scalars['String']['input']>;
    reason?: InputMaybe<Scalars['ContactSuggestionReason']['input']>;
    rejectedAt?: InputMaybe<Scalars['timestamp']['input']>;
    suggestionMotivation?: InputMaybe<Scalars['String']['input']>;
    updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
    userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type ContactSuggestions_Max_Fields = {
    __typename?: 'ContactSuggestions_max_fields';
    accountAboutUs?: Maybe<Scalars['String']['output']>;
    accountId?: Maybe<Scalars['String']['output']>;
    accountIndustry?: Maybe<Scalars['String']['output']>;
    accountLinkedinUrl?: Maybe<Scalars['String']['output']>;
    accountName?: Maybe<Scalars['String']['output']>;
    accountNumberOfEmployees?: Maybe<Scalars['String']['output']>;
    accountTags?: Maybe<Array<Scalars['String']['output']>>;
    accountWebsite?: Maybe<Scalars['String']['output']>;
    approvedAt?: Maybe<Scalars['timestamp']['output']>;
    campaignId?: Maybe<Scalars['String']['output']>;
    campaignName?: Maybe<Scalars['String']['output']>;
    contactEmail?: Maybe<Scalars['String']['output']>;
    contactImgUrl?: Maybe<Scalars['String']['output']>;
    contactLinkedinUrl?: Maybe<Scalars['String']['output']>;
    contactLocation?: Maybe<Scalars['String']['output']>;
    contactName?: Maybe<Scalars['String']['output']>;
    contactTitle?: Maybe<Scalars['String']['output']>;
    createdAt?: Maybe<Scalars['timestamp']['output']>;
    expiresAt?: Maybe<Scalars['timestamp']['output']>;
    groupId?: Maybe<Scalars['String']['output']>;
    id?: Maybe<Scalars['String']['output']>;
    organizationId?: Maybe<Scalars['String']['output']>;
    personId?: Maybe<Scalars['String']['output']>;
    prospectId?: Maybe<Scalars['String']['output']>;
    reason?: Maybe<Scalars['ContactSuggestionReason']['output']>;
    rejectedAt?: Maybe<Scalars['timestamp']['output']>;
    suggestionMotivation?: Maybe<Scalars['String']['output']>;
    updatedAt?: Maybe<Scalars['timestamp']['output']>;
    userId?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type ContactSuggestions_Min_Fields = {
    __typename?: 'ContactSuggestions_min_fields';
    accountAboutUs?: Maybe<Scalars['String']['output']>;
    accountId?: Maybe<Scalars['String']['output']>;
    accountIndustry?: Maybe<Scalars['String']['output']>;
    accountLinkedinUrl?: Maybe<Scalars['String']['output']>;
    accountName?: Maybe<Scalars['String']['output']>;
    accountNumberOfEmployees?: Maybe<Scalars['String']['output']>;
    accountTags?: Maybe<Array<Scalars['String']['output']>>;
    accountWebsite?: Maybe<Scalars['String']['output']>;
    approvedAt?: Maybe<Scalars['timestamp']['output']>;
    campaignId?: Maybe<Scalars['String']['output']>;
    campaignName?: Maybe<Scalars['String']['output']>;
    contactEmail?: Maybe<Scalars['String']['output']>;
    contactImgUrl?: Maybe<Scalars['String']['output']>;
    contactLinkedinUrl?: Maybe<Scalars['String']['output']>;
    contactLocation?: Maybe<Scalars['String']['output']>;
    contactName?: Maybe<Scalars['String']['output']>;
    contactTitle?: Maybe<Scalars['String']['output']>;
    createdAt?: Maybe<Scalars['timestamp']['output']>;
    expiresAt?: Maybe<Scalars['timestamp']['output']>;
    groupId?: Maybe<Scalars['String']['output']>;
    id?: Maybe<Scalars['String']['output']>;
    organizationId?: Maybe<Scalars['String']['output']>;
    personId?: Maybe<Scalars['String']['output']>;
    prospectId?: Maybe<Scalars['String']['output']>;
    reason?: Maybe<Scalars['ContactSuggestionReason']['output']>;
    rejectedAt?: Maybe<Scalars['timestamp']['output']>;
    suggestionMotivation?: Maybe<Scalars['String']['output']>;
    updatedAt?: Maybe<Scalars['timestamp']['output']>;
    userId?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "ContactSuggestions" */
export type ContactSuggestions_Mutation_Response = {
    __typename?: 'ContactSuggestions_mutation_response';
    /** number of rows affected by the mutation */
    affected_rows: Scalars['Int']['output'];
    /** data from the rows affected by the mutation */
    returning: Array<ContactSuggestions>;
};

/** on_conflict condition type for table "ContactSuggestions" */
export type ContactSuggestions_On_Conflict = {
    constraint: ContactSuggestions_Constraint;
    update_columns?: Array<ContactSuggestions_Update_Column>;
    where?: InputMaybe<ContactSuggestions_Bool_Exp>;
};

/** Ordering options when selecting data from "ContactSuggestions". */
export type ContactSuggestions_Order_By = {
    accountAboutUs?: InputMaybe<Order_By>;
    accountId?: InputMaybe<Order_By>;
    accountIndustry?: InputMaybe<Order_By>;
    accountLinkedinUrl?: InputMaybe<Order_By>;
    accountName?: InputMaybe<Order_By>;
    accountNumberOfEmployees?: InputMaybe<Order_By>;
    accountTags?: InputMaybe<Order_By>;
    accountWebsite?: InputMaybe<Order_By>;
    approvedAt?: InputMaybe<Order_By>;
    campaignId?: InputMaybe<Order_By>;
    campaignName?: InputMaybe<Order_By>;
    contactEmail?: InputMaybe<Order_By>;
    contactEmailVerified?: InputMaybe<Order_By>;
    contactImgUrl?: InputMaybe<Order_By>;
    contactLinkedinUrl?: InputMaybe<Order_By>;
    contactLocation?: InputMaybe<Order_By>;
    contactName?: InputMaybe<Order_By>;
    contactTitle?: InputMaybe<Order_By>;
    createdAt?: InputMaybe<Order_By>;
    expiresAt?: InputMaybe<Order_By>;
    groupId?: InputMaybe<Order_By>;
    id?: InputMaybe<Order_By>;
    organizationId?: InputMaybe<Order_By>;
    personId?: InputMaybe<Order_By>;
    prospectId?: InputMaybe<Order_By>;
    reason?: InputMaybe<Order_By>;
    rejectedAt?: InputMaybe<Order_By>;
    suggestionMotivation?: InputMaybe<Order_By>;
    updatedAt?: InputMaybe<Order_By>;
    userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ContactSuggestions */
export type ContactSuggestions_Pk_Columns_Input = {
    id: Scalars['String']['input'];
};

/** select columns of table "ContactSuggestions" */
export enum ContactSuggestions_Select_Column {
    /** column name */
    AccountAboutUs = 'accountAboutUs',
    /** column name */
    AccountId = 'accountId',
    /** column name */
    AccountIndustry = 'accountIndustry',
    /** column name */
    AccountLinkedinUrl = 'accountLinkedinUrl',
    /** column name */
    AccountName = 'accountName',
    /** column name */
    AccountNumberOfEmployees = 'accountNumberOfEmployees',
    /** column name */
    AccountTags = 'accountTags',
    /** column name */
    AccountWebsite = 'accountWebsite',
    /** column name */
    ApprovedAt = 'approvedAt',
    /** column name */
    CampaignId = 'campaignId',
    /** column name */
    CampaignName = 'campaignName',
    /** column name */
    ContactEmail = 'contactEmail',
    /** column name */
    ContactEmailVerified = 'contactEmailVerified',
    /** column name */
    ContactImgUrl = 'contactImgUrl',
    /** column name */
    ContactLinkedinUrl = 'contactLinkedinUrl',
    /** column name */
    ContactLocation = 'contactLocation',
    /** column name */
    ContactName = 'contactName',
    /** column name */
    ContactTitle = 'contactTitle',
    /** column name */
    CreatedAt = 'createdAt',
    /** column name */
    ExpiresAt = 'expiresAt',
    /** column name */
    GroupId = 'groupId',
    /** column name */
    Id = 'id',
    /** column name */
    OrganizationId = 'organizationId',
    /** column name */
    PersonId = 'personId',
    /** column name */
    ProspectId = 'prospectId',
    /** column name */
    Reason = 'reason',
    /** column name */
    RejectedAt = 'rejectedAt',
    /** column name */
    SuggestionMotivation = 'suggestionMotivation',
    /** column name */
    UpdatedAt = 'updatedAt',
    /** column name */
    UserId = 'userId',
}

/** input type for updating data in table "ContactSuggestions" */
export type ContactSuggestions_Set_Input = {
    accountAboutUs?: InputMaybe<Scalars['String']['input']>;
    accountId?: InputMaybe<Scalars['String']['input']>;
    accountIndustry?: InputMaybe<Scalars['String']['input']>;
    accountLinkedinUrl?: InputMaybe<Scalars['String']['input']>;
    accountName?: InputMaybe<Scalars['String']['input']>;
    accountNumberOfEmployees?: InputMaybe<Scalars['String']['input']>;
    accountTags?: InputMaybe<Array<Scalars['String']['input']>>;
    accountWebsite?: InputMaybe<Scalars['String']['input']>;
    approvedAt?: InputMaybe<Scalars['timestamp']['input']>;
    campaignId?: InputMaybe<Scalars['String']['input']>;
    campaignName?: InputMaybe<Scalars['String']['input']>;
    contactEmail?: InputMaybe<Scalars['String']['input']>;
    contactEmailVerified?: InputMaybe<Scalars['Boolean']['input']>;
    contactImgUrl?: InputMaybe<Scalars['String']['input']>;
    contactLinkedinUrl?: InputMaybe<Scalars['String']['input']>;
    contactLocation?: InputMaybe<Scalars['String']['input']>;
    contactName?: InputMaybe<Scalars['String']['input']>;
    contactTitle?: InputMaybe<Scalars['String']['input']>;
    createdAt?: InputMaybe<Scalars['timestamp']['input']>;
    expiresAt?: InputMaybe<Scalars['timestamp']['input']>;
    groupId?: InputMaybe<Scalars['String']['input']>;
    id?: InputMaybe<Scalars['String']['input']>;
    organizationId?: InputMaybe<Scalars['String']['input']>;
    personId?: InputMaybe<Scalars['String']['input']>;
    prospectId?: InputMaybe<Scalars['String']['input']>;
    reason?: InputMaybe<Scalars['ContactSuggestionReason']['input']>;
    rejectedAt?: InputMaybe<Scalars['timestamp']['input']>;
    suggestionMotivation?: InputMaybe<Scalars['String']['input']>;
    updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
    userId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "ContactSuggestions" */
export type ContactSuggestions_Stream_Cursor_Input = {
    /** Stream column input with initial value */
    initial_value: ContactSuggestions_Stream_Cursor_Value_Input;
    /** cursor ordering */
    ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ContactSuggestions_Stream_Cursor_Value_Input = {
    accountAboutUs?: InputMaybe<Scalars['String']['input']>;
    accountId?: InputMaybe<Scalars['String']['input']>;
    accountIndustry?: InputMaybe<Scalars['String']['input']>;
    accountLinkedinUrl?: InputMaybe<Scalars['String']['input']>;
    accountName?: InputMaybe<Scalars['String']['input']>;
    accountNumberOfEmployees?: InputMaybe<Scalars['String']['input']>;
    accountTags?: InputMaybe<Array<Scalars['String']['input']>>;
    accountWebsite?: InputMaybe<Scalars['String']['input']>;
    approvedAt?: InputMaybe<Scalars['timestamp']['input']>;
    campaignId?: InputMaybe<Scalars['String']['input']>;
    campaignName?: InputMaybe<Scalars['String']['input']>;
    contactEmail?: InputMaybe<Scalars['String']['input']>;
    contactEmailVerified?: InputMaybe<Scalars['Boolean']['input']>;
    contactImgUrl?: InputMaybe<Scalars['String']['input']>;
    contactLinkedinUrl?: InputMaybe<Scalars['String']['input']>;
    contactLocation?: InputMaybe<Scalars['String']['input']>;
    contactName?: InputMaybe<Scalars['String']['input']>;
    contactTitle?: InputMaybe<Scalars['String']['input']>;
    createdAt?: InputMaybe<Scalars['timestamp']['input']>;
    expiresAt?: InputMaybe<Scalars['timestamp']['input']>;
    groupId?: InputMaybe<Scalars['String']['input']>;
    id?: InputMaybe<Scalars['String']['input']>;
    organizationId?: InputMaybe<Scalars['String']['input']>;
    personId?: InputMaybe<Scalars['String']['input']>;
    prospectId?: InputMaybe<Scalars['String']['input']>;
    reason?: InputMaybe<Scalars['ContactSuggestionReason']['input']>;
    rejectedAt?: InputMaybe<Scalars['timestamp']['input']>;
    suggestionMotivation?: InputMaybe<Scalars['String']['input']>;
    updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
    userId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "ContactSuggestions" */
export enum ContactSuggestions_Update_Column {
    /** column name */
    AccountAboutUs = 'accountAboutUs',
    /** column name */
    AccountId = 'accountId',
    /** column name */
    AccountIndustry = 'accountIndustry',
    /** column name */
    AccountLinkedinUrl = 'accountLinkedinUrl',
    /** column name */
    AccountName = 'accountName',
    /** column name */
    AccountNumberOfEmployees = 'accountNumberOfEmployees',
    /** column name */
    AccountTags = 'accountTags',
    /** column name */
    AccountWebsite = 'accountWebsite',
    /** column name */
    ApprovedAt = 'approvedAt',
    /** column name */
    CampaignId = 'campaignId',
    /** column name */
    CampaignName = 'campaignName',
    /** column name */
    ContactEmail = 'contactEmail',
    /** column name */
    ContactEmailVerified = 'contactEmailVerified',
    /** column name */
    ContactImgUrl = 'contactImgUrl',
    /** column name */
    ContactLinkedinUrl = 'contactLinkedinUrl',
    /** column name */
    ContactLocation = 'contactLocation',
    /** column name */
    ContactName = 'contactName',
    /** column name */
    ContactTitle = 'contactTitle',
    /** column name */
    CreatedAt = 'createdAt',
    /** column name */
    ExpiresAt = 'expiresAt',
    /** column name */
    GroupId = 'groupId',
    /** column name */
    Id = 'id',
    /** column name */
    OrganizationId = 'organizationId',
    /** column name */
    PersonId = 'personId',
    /** column name */
    ProspectId = 'prospectId',
    /** column name */
    Reason = 'reason',
    /** column name */
    RejectedAt = 'rejectedAt',
    /** column name */
    SuggestionMotivation = 'suggestionMotivation',
    /** column name */
    UpdatedAt = 'updatedAt',
    /** column name */
    UserId = 'userId',
}

export type ContactSuggestions_Updates = {
    /** sets the columns of the filtered rows to the given values */
    _set?: InputMaybe<ContactSuggestions_Set_Input>;
    /** filter the rows which have to be updated */
    where: ContactSuggestions_Bool_Exp;
};

/** columns and relationships of "ConversationMetaData" */
export type ConversationMetaData = {
    __typename?: 'ConversationMetaData';
    /** An object relationship */
    OutreachSuggestion?: Maybe<OutreachSuggestions>;
    /** An array relationship */
    OutreachSuggestions: Array<OutreachSuggestions>;
    /** An aggregate relationship */
    OutreachSuggestions_aggregate: OutreachSuggestions_Aggregate;
    connectionStatus: Scalars['String']['output'];
    createdAt: Scalars['timestamp']['output'];
    hasMessagesSentFromApp: Scalars['Boolean']['output'];
    id: Scalars['String']['output'];
    isNextMessageFollowUp: Scalars['Boolean']['output'];
    lastMessageAt: Scalars['timestamp']['output'];
    lastNetworkUpdateAt: Scalars['timestamp']['output'];
    lastTimeToAnswer: Scalars['bigint']['output'];
    lastUserNoteAt: Scalars['timestamp']['output'];
    medianMessageLength: Scalars['float8']['output'];
    messagesPerDay: Scalars['float8']['output'];
    numberOfMessages: Scalars['Int']['output'];
    numberOfMessagesLastDay: Scalars['Int']['output'];
    numberOfMessagesLastWeek: Scalars['Int']['output'];
    prospectId: Scalars['String']['output'];
    topics: Scalars['String']['output'];
    updatedAt: Scalars['timestamp']['output'];
    userContactAddedAt: Scalars['timestamp']['output'];
    userContactFromOnboarding: Scalars['Boolean']['output'];
    userContactId: Scalars['String']['output'];
    userId: Scalars['String']['output'];
    youThemMessageRatio: Scalars['float8']['output'];
};

/** columns and relationships of "ConversationMetaData" */
export type ConversationMetaDataOutreachSuggestionsArgs = {
    distinct_on?: InputMaybe<Array<OutreachSuggestions_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<OutreachSuggestions_Order_By>>;
    where?: InputMaybe<OutreachSuggestions_Bool_Exp>;
};

/** columns and relationships of "ConversationMetaData" */
export type ConversationMetaDataOutreachSuggestions_AggregateArgs = {
    distinct_on?: InputMaybe<Array<OutreachSuggestions_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<OutreachSuggestions_Order_By>>;
    where?: InputMaybe<OutreachSuggestions_Bool_Exp>;
};

/** aggregated selection of "ConversationMetaData" */
export type ConversationMetaData_Aggregate = {
    __typename?: 'ConversationMetaData_aggregate';
    aggregate?: Maybe<ConversationMetaData_Aggregate_Fields>;
    nodes: Array<ConversationMetaData>;
};

/** aggregate fields of "ConversationMetaData" */
export type ConversationMetaData_Aggregate_Fields = {
    __typename?: 'ConversationMetaData_aggregate_fields';
    avg?: Maybe<ConversationMetaData_Avg_Fields>;
    count: Scalars['Int']['output'];
    max?: Maybe<ConversationMetaData_Max_Fields>;
    min?: Maybe<ConversationMetaData_Min_Fields>;
    stddev?: Maybe<ConversationMetaData_Stddev_Fields>;
    stddev_pop?: Maybe<ConversationMetaData_Stddev_Pop_Fields>;
    stddev_samp?: Maybe<ConversationMetaData_Stddev_Samp_Fields>;
    sum?: Maybe<ConversationMetaData_Sum_Fields>;
    var_pop?: Maybe<ConversationMetaData_Var_Pop_Fields>;
    var_samp?: Maybe<ConversationMetaData_Var_Samp_Fields>;
    variance?: Maybe<ConversationMetaData_Variance_Fields>;
};

/** aggregate fields of "ConversationMetaData" */
export type ConversationMetaData_Aggregate_FieldsCountArgs = {
    columns?: InputMaybe<Array<ConversationMetaData_Select_Column>>;
    distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type ConversationMetaData_Avg_Fields = {
    __typename?: 'ConversationMetaData_avg_fields';
    lastTimeToAnswer?: Maybe<Scalars['Float']['output']>;
    medianMessageLength?: Maybe<Scalars['Float']['output']>;
    messagesPerDay?: Maybe<Scalars['Float']['output']>;
    numberOfMessages?: Maybe<Scalars['Float']['output']>;
    numberOfMessagesLastDay?: Maybe<Scalars['Float']['output']>;
    numberOfMessagesLastWeek?: Maybe<Scalars['Float']['output']>;
    youThemMessageRatio?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "ConversationMetaData". All fields are combined with a logical 'AND'. */
export type ConversationMetaData_Bool_Exp = {
    OutreachSuggestion?: InputMaybe<OutreachSuggestions_Bool_Exp>;
    OutreachSuggestions?: InputMaybe<OutreachSuggestions_Bool_Exp>;
    OutreachSuggestions_aggregate?: InputMaybe<OutreachSuggestions_Aggregate_Bool_Exp>;
    _and?: InputMaybe<Array<ConversationMetaData_Bool_Exp>>;
    _not?: InputMaybe<ConversationMetaData_Bool_Exp>;
    _or?: InputMaybe<Array<ConversationMetaData_Bool_Exp>>;
    connectionStatus?: InputMaybe<String_Comparison_Exp>;
    createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
    hasMessagesSentFromApp?: InputMaybe<Boolean_Comparison_Exp>;
    id?: InputMaybe<String_Comparison_Exp>;
    isNextMessageFollowUp?: InputMaybe<Boolean_Comparison_Exp>;
    lastMessageAt?: InputMaybe<Timestamp_Comparison_Exp>;
    lastNetworkUpdateAt?: InputMaybe<Timestamp_Comparison_Exp>;
    lastTimeToAnswer?: InputMaybe<Bigint_Comparison_Exp>;
    lastUserNoteAt?: InputMaybe<Timestamp_Comparison_Exp>;
    medianMessageLength?: InputMaybe<Float8_Comparison_Exp>;
    messagesPerDay?: InputMaybe<Float8_Comparison_Exp>;
    numberOfMessages?: InputMaybe<Int_Comparison_Exp>;
    numberOfMessagesLastDay?: InputMaybe<Int_Comparison_Exp>;
    numberOfMessagesLastWeek?: InputMaybe<Int_Comparison_Exp>;
    prospectId?: InputMaybe<String_Comparison_Exp>;
    topics?: InputMaybe<String_Comparison_Exp>;
    updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
    userContactAddedAt?: InputMaybe<Timestamp_Comparison_Exp>;
    userContactFromOnboarding?: InputMaybe<Boolean_Comparison_Exp>;
    userContactId?: InputMaybe<String_Comparison_Exp>;
    userId?: InputMaybe<String_Comparison_Exp>;
    youThemMessageRatio?: InputMaybe<Float8_Comparison_Exp>;
};

/** unique or primary key constraints on table "ConversationMetaData" */
export enum ConversationMetaData_Constraint {
    /** unique or primary key constraint on columns "id" */
    ConversationMetaDataPkey = 'ConversationMetaData_pkey',
}

/** input type for incrementing numeric columns in table "ConversationMetaData" */
export type ConversationMetaData_Inc_Input = {
    lastTimeToAnswer?: InputMaybe<Scalars['bigint']['input']>;
    medianMessageLength?: InputMaybe<Scalars['float8']['input']>;
    messagesPerDay?: InputMaybe<Scalars['float8']['input']>;
    numberOfMessages?: InputMaybe<Scalars['Int']['input']>;
    numberOfMessagesLastDay?: InputMaybe<Scalars['Int']['input']>;
    numberOfMessagesLastWeek?: InputMaybe<Scalars['Int']['input']>;
    youThemMessageRatio?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "ConversationMetaData" */
export type ConversationMetaData_Insert_Input = {
    OutreachSuggestion?: InputMaybe<OutreachSuggestions_Obj_Rel_Insert_Input>;
    OutreachSuggestions?: InputMaybe<OutreachSuggestions_Arr_Rel_Insert_Input>;
    connectionStatus?: InputMaybe<Scalars['String']['input']>;
    createdAt?: InputMaybe<Scalars['timestamp']['input']>;
    hasMessagesSentFromApp?: InputMaybe<Scalars['Boolean']['input']>;
    id?: InputMaybe<Scalars['String']['input']>;
    isNextMessageFollowUp?: InputMaybe<Scalars['Boolean']['input']>;
    lastMessageAt?: InputMaybe<Scalars['timestamp']['input']>;
    lastNetworkUpdateAt?: InputMaybe<Scalars['timestamp']['input']>;
    lastTimeToAnswer?: InputMaybe<Scalars['bigint']['input']>;
    lastUserNoteAt?: InputMaybe<Scalars['timestamp']['input']>;
    medianMessageLength?: InputMaybe<Scalars['float8']['input']>;
    messagesPerDay?: InputMaybe<Scalars['float8']['input']>;
    numberOfMessages?: InputMaybe<Scalars['Int']['input']>;
    numberOfMessagesLastDay?: InputMaybe<Scalars['Int']['input']>;
    numberOfMessagesLastWeek?: InputMaybe<Scalars['Int']['input']>;
    prospectId?: InputMaybe<Scalars['String']['input']>;
    topics?: InputMaybe<Scalars['String']['input']>;
    updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
    userContactAddedAt?: InputMaybe<Scalars['timestamp']['input']>;
    userContactFromOnboarding?: InputMaybe<Scalars['Boolean']['input']>;
    userContactId?: InputMaybe<Scalars['String']['input']>;
    userId?: InputMaybe<Scalars['String']['input']>;
    youThemMessageRatio?: InputMaybe<Scalars['float8']['input']>;
};

/** aggregate max on columns */
export type ConversationMetaData_Max_Fields = {
    __typename?: 'ConversationMetaData_max_fields';
    connectionStatus?: Maybe<Scalars['String']['output']>;
    createdAt?: Maybe<Scalars['timestamp']['output']>;
    id?: Maybe<Scalars['String']['output']>;
    lastMessageAt?: Maybe<Scalars['timestamp']['output']>;
    lastNetworkUpdateAt?: Maybe<Scalars['timestamp']['output']>;
    lastTimeToAnswer?: Maybe<Scalars['bigint']['output']>;
    lastUserNoteAt?: Maybe<Scalars['timestamp']['output']>;
    medianMessageLength?: Maybe<Scalars['float8']['output']>;
    messagesPerDay?: Maybe<Scalars['float8']['output']>;
    numberOfMessages?: Maybe<Scalars['Int']['output']>;
    numberOfMessagesLastDay?: Maybe<Scalars['Int']['output']>;
    numberOfMessagesLastWeek?: Maybe<Scalars['Int']['output']>;
    prospectId?: Maybe<Scalars['String']['output']>;
    topics?: Maybe<Scalars['String']['output']>;
    updatedAt?: Maybe<Scalars['timestamp']['output']>;
    userContactAddedAt?: Maybe<Scalars['timestamp']['output']>;
    userContactId?: Maybe<Scalars['String']['output']>;
    userId?: Maybe<Scalars['String']['output']>;
    youThemMessageRatio?: Maybe<Scalars['float8']['output']>;
};

/** aggregate min on columns */
export type ConversationMetaData_Min_Fields = {
    __typename?: 'ConversationMetaData_min_fields';
    connectionStatus?: Maybe<Scalars['String']['output']>;
    createdAt?: Maybe<Scalars['timestamp']['output']>;
    id?: Maybe<Scalars['String']['output']>;
    lastMessageAt?: Maybe<Scalars['timestamp']['output']>;
    lastNetworkUpdateAt?: Maybe<Scalars['timestamp']['output']>;
    lastTimeToAnswer?: Maybe<Scalars['bigint']['output']>;
    lastUserNoteAt?: Maybe<Scalars['timestamp']['output']>;
    medianMessageLength?: Maybe<Scalars['float8']['output']>;
    messagesPerDay?: Maybe<Scalars['float8']['output']>;
    numberOfMessages?: Maybe<Scalars['Int']['output']>;
    numberOfMessagesLastDay?: Maybe<Scalars['Int']['output']>;
    numberOfMessagesLastWeek?: Maybe<Scalars['Int']['output']>;
    prospectId?: Maybe<Scalars['String']['output']>;
    topics?: Maybe<Scalars['String']['output']>;
    updatedAt?: Maybe<Scalars['timestamp']['output']>;
    userContactAddedAt?: Maybe<Scalars['timestamp']['output']>;
    userContactId?: Maybe<Scalars['String']['output']>;
    userId?: Maybe<Scalars['String']['output']>;
    youThemMessageRatio?: Maybe<Scalars['float8']['output']>;
};

/** response of any mutation on the table "ConversationMetaData" */
export type ConversationMetaData_Mutation_Response = {
    __typename?: 'ConversationMetaData_mutation_response';
    /** number of rows affected by the mutation */
    affected_rows: Scalars['Int']['output'];
    /** data from the rows affected by the mutation */
    returning: Array<ConversationMetaData>;
};

/** input type for inserting object relation for remote table "ConversationMetaData" */
export type ConversationMetaData_Obj_Rel_Insert_Input = {
    data: ConversationMetaData_Insert_Input;
    /** upsert condition */
    on_conflict?: InputMaybe<ConversationMetaData_On_Conflict>;
};

/** on_conflict condition type for table "ConversationMetaData" */
export type ConversationMetaData_On_Conflict = {
    constraint: ConversationMetaData_Constraint;
    update_columns?: Array<ConversationMetaData_Update_Column>;
    where?: InputMaybe<ConversationMetaData_Bool_Exp>;
};

/** Ordering options when selecting data from "ConversationMetaData". */
export type ConversationMetaData_Order_By = {
    OutreachSuggestion?: InputMaybe<OutreachSuggestions_Order_By>;
    OutreachSuggestions_aggregate?: InputMaybe<OutreachSuggestions_Aggregate_Order_By>;
    connectionStatus?: InputMaybe<Order_By>;
    createdAt?: InputMaybe<Order_By>;
    hasMessagesSentFromApp?: InputMaybe<Order_By>;
    id?: InputMaybe<Order_By>;
    isNextMessageFollowUp?: InputMaybe<Order_By>;
    lastMessageAt?: InputMaybe<Order_By>;
    lastNetworkUpdateAt?: InputMaybe<Order_By>;
    lastTimeToAnswer?: InputMaybe<Order_By>;
    lastUserNoteAt?: InputMaybe<Order_By>;
    medianMessageLength?: InputMaybe<Order_By>;
    messagesPerDay?: InputMaybe<Order_By>;
    numberOfMessages?: InputMaybe<Order_By>;
    numberOfMessagesLastDay?: InputMaybe<Order_By>;
    numberOfMessagesLastWeek?: InputMaybe<Order_By>;
    prospectId?: InputMaybe<Order_By>;
    topics?: InputMaybe<Order_By>;
    updatedAt?: InputMaybe<Order_By>;
    userContactAddedAt?: InputMaybe<Order_By>;
    userContactFromOnboarding?: InputMaybe<Order_By>;
    userContactId?: InputMaybe<Order_By>;
    userId?: InputMaybe<Order_By>;
    youThemMessageRatio?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ConversationMetaData */
export type ConversationMetaData_Pk_Columns_Input = {
    id: Scalars['String']['input'];
};

/** select columns of table "ConversationMetaData" */
export enum ConversationMetaData_Select_Column {
    /** column name */
    ConnectionStatus = 'connectionStatus',
    /** column name */
    CreatedAt = 'createdAt',
    /** column name */
    HasMessagesSentFromApp = 'hasMessagesSentFromApp',
    /** column name */
    Id = 'id',
    /** column name */
    IsNextMessageFollowUp = 'isNextMessageFollowUp',
    /** column name */
    LastMessageAt = 'lastMessageAt',
    /** column name */
    LastNetworkUpdateAt = 'lastNetworkUpdateAt',
    /** column name */
    LastTimeToAnswer = 'lastTimeToAnswer',
    /** column name */
    LastUserNoteAt = 'lastUserNoteAt',
    /** column name */
    MedianMessageLength = 'medianMessageLength',
    /** column name */
    MessagesPerDay = 'messagesPerDay',
    /** column name */
    NumberOfMessages = 'numberOfMessages',
    /** column name */
    NumberOfMessagesLastDay = 'numberOfMessagesLastDay',
    /** column name */
    NumberOfMessagesLastWeek = 'numberOfMessagesLastWeek',
    /** column name */
    ProspectId = 'prospectId',
    /** column name */
    Topics = 'topics',
    /** column name */
    UpdatedAt = 'updatedAt',
    /** column name */
    UserContactAddedAt = 'userContactAddedAt',
    /** column name */
    UserContactFromOnboarding = 'userContactFromOnboarding',
    /** column name */
    UserContactId = 'userContactId',
    /** column name */
    UserId = 'userId',
    /** column name */
    YouThemMessageRatio = 'youThemMessageRatio',
}

/** input type for updating data in table "ConversationMetaData" */
export type ConversationMetaData_Set_Input = {
    connectionStatus?: InputMaybe<Scalars['String']['input']>;
    createdAt?: InputMaybe<Scalars['timestamp']['input']>;
    hasMessagesSentFromApp?: InputMaybe<Scalars['Boolean']['input']>;
    id?: InputMaybe<Scalars['String']['input']>;
    isNextMessageFollowUp?: InputMaybe<Scalars['Boolean']['input']>;
    lastMessageAt?: InputMaybe<Scalars['timestamp']['input']>;
    lastNetworkUpdateAt?: InputMaybe<Scalars['timestamp']['input']>;
    lastTimeToAnswer?: InputMaybe<Scalars['bigint']['input']>;
    lastUserNoteAt?: InputMaybe<Scalars['timestamp']['input']>;
    medianMessageLength?: InputMaybe<Scalars['float8']['input']>;
    messagesPerDay?: InputMaybe<Scalars['float8']['input']>;
    numberOfMessages?: InputMaybe<Scalars['Int']['input']>;
    numberOfMessagesLastDay?: InputMaybe<Scalars['Int']['input']>;
    numberOfMessagesLastWeek?: InputMaybe<Scalars['Int']['input']>;
    prospectId?: InputMaybe<Scalars['String']['input']>;
    topics?: InputMaybe<Scalars['String']['input']>;
    updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
    userContactAddedAt?: InputMaybe<Scalars['timestamp']['input']>;
    userContactFromOnboarding?: InputMaybe<Scalars['Boolean']['input']>;
    userContactId?: InputMaybe<Scalars['String']['input']>;
    userId?: InputMaybe<Scalars['String']['input']>;
    youThemMessageRatio?: InputMaybe<Scalars['float8']['input']>;
};

/** aggregate stddev on columns */
export type ConversationMetaData_Stddev_Fields = {
    __typename?: 'ConversationMetaData_stddev_fields';
    lastTimeToAnswer?: Maybe<Scalars['Float']['output']>;
    medianMessageLength?: Maybe<Scalars['Float']['output']>;
    messagesPerDay?: Maybe<Scalars['Float']['output']>;
    numberOfMessages?: Maybe<Scalars['Float']['output']>;
    numberOfMessagesLastDay?: Maybe<Scalars['Float']['output']>;
    numberOfMessagesLastWeek?: Maybe<Scalars['Float']['output']>;
    youThemMessageRatio?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type ConversationMetaData_Stddev_Pop_Fields = {
    __typename?: 'ConversationMetaData_stddev_pop_fields';
    lastTimeToAnswer?: Maybe<Scalars['Float']['output']>;
    medianMessageLength?: Maybe<Scalars['Float']['output']>;
    messagesPerDay?: Maybe<Scalars['Float']['output']>;
    numberOfMessages?: Maybe<Scalars['Float']['output']>;
    numberOfMessagesLastDay?: Maybe<Scalars['Float']['output']>;
    numberOfMessagesLastWeek?: Maybe<Scalars['Float']['output']>;
    youThemMessageRatio?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type ConversationMetaData_Stddev_Samp_Fields = {
    __typename?: 'ConversationMetaData_stddev_samp_fields';
    lastTimeToAnswer?: Maybe<Scalars['Float']['output']>;
    medianMessageLength?: Maybe<Scalars['Float']['output']>;
    messagesPerDay?: Maybe<Scalars['Float']['output']>;
    numberOfMessages?: Maybe<Scalars['Float']['output']>;
    numberOfMessagesLastDay?: Maybe<Scalars['Float']['output']>;
    numberOfMessagesLastWeek?: Maybe<Scalars['Float']['output']>;
    youThemMessageRatio?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "ConversationMetaData" */
export type ConversationMetaData_Stream_Cursor_Input = {
    /** Stream column input with initial value */
    initial_value: ConversationMetaData_Stream_Cursor_Value_Input;
    /** cursor ordering */
    ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type ConversationMetaData_Stream_Cursor_Value_Input = {
    connectionStatus?: InputMaybe<Scalars['String']['input']>;
    createdAt?: InputMaybe<Scalars['timestamp']['input']>;
    hasMessagesSentFromApp?: InputMaybe<Scalars['Boolean']['input']>;
    id?: InputMaybe<Scalars['String']['input']>;
    isNextMessageFollowUp?: InputMaybe<Scalars['Boolean']['input']>;
    lastMessageAt?: InputMaybe<Scalars['timestamp']['input']>;
    lastNetworkUpdateAt?: InputMaybe<Scalars['timestamp']['input']>;
    lastTimeToAnswer?: InputMaybe<Scalars['bigint']['input']>;
    lastUserNoteAt?: InputMaybe<Scalars['timestamp']['input']>;
    medianMessageLength?: InputMaybe<Scalars['float8']['input']>;
    messagesPerDay?: InputMaybe<Scalars['float8']['input']>;
    numberOfMessages?: InputMaybe<Scalars['Int']['input']>;
    numberOfMessagesLastDay?: InputMaybe<Scalars['Int']['input']>;
    numberOfMessagesLastWeek?: InputMaybe<Scalars['Int']['input']>;
    prospectId?: InputMaybe<Scalars['String']['input']>;
    topics?: InputMaybe<Scalars['String']['input']>;
    updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
    userContactAddedAt?: InputMaybe<Scalars['timestamp']['input']>;
    userContactFromOnboarding?: InputMaybe<Scalars['Boolean']['input']>;
    userContactId?: InputMaybe<Scalars['String']['input']>;
    userId?: InputMaybe<Scalars['String']['input']>;
    youThemMessageRatio?: InputMaybe<Scalars['float8']['input']>;
};

/** aggregate sum on columns */
export type ConversationMetaData_Sum_Fields = {
    __typename?: 'ConversationMetaData_sum_fields';
    lastTimeToAnswer?: Maybe<Scalars['bigint']['output']>;
    medianMessageLength?: Maybe<Scalars['float8']['output']>;
    messagesPerDay?: Maybe<Scalars['float8']['output']>;
    numberOfMessages?: Maybe<Scalars['Int']['output']>;
    numberOfMessagesLastDay?: Maybe<Scalars['Int']['output']>;
    numberOfMessagesLastWeek?: Maybe<Scalars['Int']['output']>;
    youThemMessageRatio?: Maybe<Scalars['float8']['output']>;
};

/** update columns of table "ConversationMetaData" */
export enum ConversationMetaData_Update_Column {
    /** column name */
    ConnectionStatus = 'connectionStatus',
    /** column name */
    CreatedAt = 'createdAt',
    /** column name */
    HasMessagesSentFromApp = 'hasMessagesSentFromApp',
    /** column name */
    Id = 'id',
    /** column name */
    IsNextMessageFollowUp = 'isNextMessageFollowUp',
    /** column name */
    LastMessageAt = 'lastMessageAt',
    /** column name */
    LastNetworkUpdateAt = 'lastNetworkUpdateAt',
    /** column name */
    LastTimeToAnswer = 'lastTimeToAnswer',
    /** column name */
    LastUserNoteAt = 'lastUserNoteAt',
    /** column name */
    MedianMessageLength = 'medianMessageLength',
    /** column name */
    MessagesPerDay = 'messagesPerDay',
    /** column name */
    NumberOfMessages = 'numberOfMessages',
    /** column name */
    NumberOfMessagesLastDay = 'numberOfMessagesLastDay',
    /** column name */
    NumberOfMessagesLastWeek = 'numberOfMessagesLastWeek',
    /** column name */
    ProspectId = 'prospectId',
    /** column name */
    Topics = 'topics',
    /** column name */
    UpdatedAt = 'updatedAt',
    /** column name */
    UserContactAddedAt = 'userContactAddedAt',
    /** column name */
    UserContactFromOnboarding = 'userContactFromOnboarding',
    /** column name */
    UserContactId = 'userContactId',
    /** column name */
    UserId = 'userId',
    /** column name */
    YouThemMessageRatio = 'youThemMessageRatio',
}

export type ConversationMetaData_Updates = {
    /** increments the numeric columns with given value of the filtered values */
    _inc?: InputMaybe<ConversationMetaData_Inc_Input>;
    /** sets the columns of the filtered rows to the given values */
    _set?: InputMaybe<ConversationMetaData_Set_Input>;
    /** filter the rows which have to be updated */
    where: ConversationMetaData_Bool_Exp;
};

/** aggregate var_pop on columns */
export type ConversationMetaData_Var_Pop_Fields = {
    __typename?: 'ConversationMetaData_var_pop_fields';
    lastTimeToAnswer?: Maybe<Scalars['Float']['output']>;
    medianMessageLength?: Maybe<Scalars['Float']['output']>;
    messagesPerDay?: Maybe<Scalars['Float']['output']>;
    numberOfMessages?: Maybe<Scalars['Float']['output']>;
    numberOfMessagesLastDay?: Maybe<Scalars['Float']['output']>;
    numberOfMessagesLastWeek?: Maybe<Scalars['Float']['output']>;
    youThemMessageRatio?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type ConversationMetaData_Var_Samp_Fields = {
    __typename?: 'ConversationMetaData_var_samp_fields';
    lastTimeToAnswer?: Maybe<Scalars['Float']['output']>;
    medianMessageLength?: Maybe<Scalars['Float']['output']>;
    messagesPerDay?: Maybe<Scalars['Float']['output']>;
    numberOfMessages?: Maybe<Scalars['Float']['output']>;
    numberOfMessagesLastDay?: Maybe<Scalars['Float']['output']>;
    numberOfMessagesLastWeek?: Maybe<Scalars['Float']['output']>;
    youThemMessageRatio?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type ConversationMetaData_Variance_Fields = {
    __typename?: 'ConversationMetaData_variance_fields';
    lastTimeToAnswer?: Maybe<Scalars['Float']['output']>;
    medianMessageLength?: Maybe<Scalars['Float']['output']>;
    messagesPerDay?: Maybe<Scalars['Float']['output']>;
    numberOfMessages?: Maybe<Scalars['Float']['output']>;
    numberOfMessagesLastDay?: Maybe<Scalars['Float']['output']>;
    numberOfMessagesLastWeek?: Maybe<Scalars['Float']['output']>;
    youThemMessageRatio?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to compare columns of type "Creator". All fields are combined with logical 'AND'. */
export type Creator_Comparison_Exp = {
    _eq?: InputMaybe<Scalars['Creator']['input']>;
    _gt?: InputMaybe<Scalars['Creator']['input']>;
    _gte?: InputMaybe<Scalars['Creator']['input']>;
    _in?: InputMaybe<Array<Scalars['Creator']['input']>>;
    _is_null?: InputMaybe<Scalars['Boolean']['input']>;
    _lt?: InputMaybe<Scalars['Creator']['input']>;
    _lte?: InputMaybe<Scalars['Creator']['input']>;
    _neq?: InputMaybe<Scalars['Creator']['input']>;
    _nin?: InputMaybe<Array<Scalars['Creator']['input']>>;
};

/** Boolean expression to compare columns of type "DeliveryStatus". All fields are combined with logical 'AND'. */
export type DeliveryStatus_Comparison_Exp = {
    _eq?: InputMaybe<Scalars['DeliveryStatus']['input']>;
    _gt?: InputMaybe<Scalars['DeliveryStatus']['input']>;
    _gte?: InputMaybe<Scalars['DeliveryStatus']['input']>;
    _in?: InputMaybe<Array<Scalars['DeliveryStatus']['input']>>;
    _is_null?: InputMaybe<Scalars['Boolean']['input']>;
    _lt?: InputMaybe<Scalars['DeliveryStatus']['input']>;
    _lte?: InputMaybe<Scalars['DeliveryStatus']['input']>;
    _neq?: InputMaybe<Scalars['DeliveryStatus']['input']>;
    _nin?: InputMaybe<Array<Scalars['DeliveryStatus']['input']>>;
};

/** columns and relationships of "Groups" */
export type Groups = {
    __typename?: 'Groups';
    /** An object relationship */
    AssistantSetting: AssistantSettings;
    /** An array relationship */
    _GroupToUserContacts: Array<_GroupToUserContact>;
    /** An aggregate relationship */
    _GroupToUserContacts_aggregate: _GroupToUserContact_Aggregate;
    assistantSettingsId: Scalars['String']['output'];
    createdAt: Scalars['timestamp']['output'];
    default: Scalars['Boolean']['output'];
    id: Scalars['String']['output'];
    imgSrc?: Maybe<Scalars['String']['output']>;
    name: Scalars['String']['output'];
    updatedAt: Scalars['timestamp']['output'];
    userId: Scalars['String']['output'];
};

/** columns and relationships of "Groups" */
export type Groups_GroupToUserContactsArgs = {
    distinct_on?: InputMaybe<Array<_GroupToUserContact_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<_GroupToUserContact_Order_By>>;
    where?: InputMaybe<_GroupToUserContact_Bool_Exp>;
};

/** columns and relationships of "Groups" */
export type Groups_GroupToUserContacts_AggregateArgs = {
    distinct_on?: InputMaybe<Array<_GroupToUserContact_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<_GroupToUserContact_Order_By>>;
    where?: InputMaybe<_GroupToUserContact_Bool_Exp>;
};

/** aggregated selection of "Groups" */
export type Groups_Aggregate = {
    __typename?: 'Groups_aggregate';
    aggregate?: Maybe<Groups_Aggregate_Fields>;
    nodes: Array<Groups>;
};

export type Groups_Aggregate_Bool_Exp = {
    bool_and?: InputMaybe<Groups_Aggregate_Bool_Exp_Bool_And>;
    bool_or?: InputMaybe<Groups_Aggregate_Bool_Exp_Bool_Or>;
    count?: InputMaybe<Groups_Aggregate_Bool_Exp_Count>;
};

export type Groups_Aggregate_Bool_Exp_Bool_And = {
    arguments: Groups_Select_Column_Groups_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
    distinct?: InputMaybe<Scalars['Boolean']['input']>;
    filter?: InputMaybe<Groups_Bool_Exp>;
    predicate: Boolean_Comparison_Exp;
};

export type Groups_Aggregate_Bool_Exp_Bool_Or = {
    arguments: Groups_Select_Column_Groups_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
    distinct?: InputMaybe<Scalars['Boolean']['input']>;
    filter?: InputMaybe<Groups_Bool_Exp>;
    predicate: Boolean_Comparison_Exp;
};

export type Groups_Aggregate_Bool_Exp_Count = {
    arguments?: InputMaybe<Array<Groups_Select_Column>>;
    distinct?: InputMaybe<Scalars['Boolean']['input']>;
    filter?: InputMaybe<Groups_Bool_Exp>;
    predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Groups" */
export type Groups_Aggregate_Fields = {
    __typename?: 'Groups_aggregate_fields';
    count: Scalars['Int']['output'];
    max?: Maybe<Groups_Max_Fields>;
    min?: Maybe<Groups_Min_Fields>;
};

/** aggregate fields of "Groups" */
export type Groups_Aggregate_FieldsCountArgs = {
    columns?: InputMaybe<Array<Groups_Select_Column>>;
    distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "Groups" */
export type Groups_Aggregate_Order_By = {
    count?: InputMaybe<Order_By>;
    max?: InputMaybe<Groups_Max_Order_By>;
    min?: InputMaybe<Groups_Min_Order_By>;
};

/** input type for inserting array relation for remote table "Groups" */
export type Groups_Arr_Rel_Insert_Input = {
    data: Array<Groups_Insert_Input>;
    /** upsert condition */
    on_conflict?: InputMaybe<Groups_On_Conflict>;
};

/** Boolean expression to filter rows from the table "Groups". All fields are combined with a logical 'AND'. */
export type Groups_Bool_Exp = {
    AssistantSetting?: InputMaybe<AssistantSettings_Bool_Exp>;
    _GroupToUserContacts?: InputMaybe<_GroupToUserContact_Bool_Exp>;
    _GroupToUserContacts_aggregate?: InputMaybe<_GroupToUserContact_Aggregate_Bool_Exp>;
    _and?: InputMaybe<Array<Groups_Bool_Exp>>;
    _not?: InputMaybe<Groups_Bool_Exp>;
    _or?: InputMaybe<Array<Groups_Bool_Exp>>;
    assistantSettingsId?: InputMaybe<String_Comparison_Exp>;
    createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
    default?: InputMaybe<Boolean_Comparison_Exp>;
    id?: InputMaybe<String_Comparison_Exp>;
    imgSrc?: InputMaybe<String_Comparison_Exp>;
    name?: InputMaybe<String_Comparison_Exp>;
    updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
    userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Groups" */
export enum Groups_Constraint {
    /** unique or primary key constraint on columns "userId", "name" */
    GroupsNameUserIdKey = 'Groups_name_userId_key',
    /** unique or primary key constraint on columns "id" */
    GroupsPkey = 'Groups_pkey',
}

/** input type for inserting data into table "Groups" */
export type Groups_Insert_Input = {
    AssistantSetting?: InputMaybe<AssistantSettings_Obj_Rel_Insert_Input>;
    _GroupToUserContacts?: InputMaybe<_GroupToUserContact_Arr_Rel_Insert_Input>;
    assistantSettingsId?: InputMaybe<Scalars['String']['input']>;
    createdAt?: InputMaybe<Scalars['timestamp']['input']>;
    default?: InputMaybe<Scalars['Boolean']['input']>;
    id?: InputMaybe<Scalars['String']['input']>;
    imgSrc?: InputMaybe<Scalars['String']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
    updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
    userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Groups_Max_Fields = {
    __typename?: 'Groups_max_fields';
    assistantSettingsId?: Maybe<Scalars['String']['output']>;
    createdAt?: Maybe<Scalars['timestamp']['output']>;
    id?: Maybe<Scalars['String']['output']>;
    imgSrc?: Maybe<Scalars['String']['output']>;
    name?: Maybe<Scalars['String']['output']>;
    updatedAt?: Maybe<Scalars['timestamp']['output']>;
    userId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "Groups" */
export type Groups_Max_Order_By = {
    assistantSettingsId?: InputMaybe<Order_By>;
    createdAt?: InputMaybe<Order_By>;
    id?: InputMaybe<Order_By>;
    imgSrc?: InputMaybe<Order_By>;
    name?: InputMaybe<Order_By>;
    updatedAt?: InputMaybe<Order_By>;
    userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Groups_Min_Fields = {
    __typename?: 'Groups_min_fields';
    assistantSettingsId?: Maybe<Scalars['String']['output']>;
    createdAt?: Maybe<Scalars['timestamp']['output']>;
    id?: Maybe<Scalars['String']['output']>;
    imgSrc?: Maybe<Scalars['String']['output']>;
    name?: Maybe<Scalars['String']['output']>;
    updatedAt?: Maybe<Scalars['timestamp']['output']>;
    userId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "Groups" */
export type Groups_Min_Order_By = {
    assistantSettingsId?: InputMaybe<Order_By>;
    createdAt?: InputMaybe<Order_By>;
    id?: InputMaybe<Order_By>;
    imgSrc?: InputMaybe<Order_By>;
    name?: InputMaybe<Order_By>;
    updatedAt?: InputMaybe<Order_By>;
    userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Groups" */
export type Groups_Mutation_Response = {
    __typename?: 'Groups_mutation_response';
    /** number of rows affected by the mutation */
    affected_rows: Scalars['Int']['output'];
    /** data from the rows affected by the mutation */
    returning: Array<Groups>;
};

/** input type for inserting object relation for remote table "Groups" */
export type Groups_Obj_Rel_Insert_Input = {
    data: Groups_Insert_Input;
    /** upsert condition */
    on_conflict?: InputMaybe<Groups_On_Conflict>;
};

/** on_conflict condition type for table "Groups" */
export type Groups_On_Conflict = {
    constraint: Groups_Constraint;
    update_columns?: Array<Groups_Update_Column>;
    where?: InputMaybe<Groups_Bool_Exp>;
};

/** Ordering options when selecting data from "Groups". */
export type Groups_Order_By = {
    AssistantSetting?: InputMaybe<AssistantSettings_Order_By>;
    _GroupToUserContacts_aggregate?: InputMaybe<_GroupToUserContact_Aggregate_Order_By>;
    assistantSettingsId?: InputMaybe<Order_By>;
    createdAt?: InputMaybe<Order_By>;
    default?: InputMaybe<Order_By>;
    id?: InputMaybe<Order_By>;
    imgSrc?: InputMaybe<Order_By>;
    name?: InputMaybe<Order_By>;
    updatedAt?: InputMaybe<Order_By>;
    userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Groups */
export type Groups_Pk_Columns_Input = {
    id: Scalars['String']['input'];
};

/** select columns of table "Groups" */
export enum Groups_Select_Column {
    /** column name */
    AssistantSettingsId = 'assistantSettingsId',
    /** column name */
    CreatedAt = 'createdAt',
    /** column name */
    Default = 'default',
    /** column name */
    Id = 'id',
    /** column name */
    ImgSrc = 'imgSrc',
    /** column name */
    Name = 'name',
    /** column name */
    UpdatedAt = 'updatedAt',
    /** column name */
    UserId = 'userId',
}

/** select "Groups_aggregate_bool_exp_bool_and_arguments_columns" columns of table "Groups" */
export enum Groups_Select_Column_Groups_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
    /** column name */
    Default = 'default',
}

/** select "Groups_aggregate_bool_exp_bool_or_arguments_columns" columns of table "Groups" */
export enum Groups_Select_Column_Groups_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
    /** column name */
    Default = 'default',
}

/** input type for updating data in table "Groups" */
export type Groups_Set_Input = {
    assistantSettingsId?: InputMaybe<Scalars['String']['input']>;
    createdAt?: InputMaybe<Scalars['timestamp']['input']>;
    default?: InputMaybe<Scalars['Boolean']['input']>;
    id?: InputMaybe<Scalars['String']['input']>;
    imgSrc?: InputMaybe<Scalars['String']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
    updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
    userId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "Groups" */
export type Groups_Stream_Cursor_Input = {
    /** Stream column input with initial value */
    initial_value: Groups_Stream_Cursor_Value_Input;
    /** cursor ordering */
    ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Groups_Stream_Cursor_Value_Input = {
    assistantSettingsId?: InputMaybe<Scalars['String']['input']>;
    createdAt?: InputMaybe<Scalars['timestamp']['input']>;
    default?: InputMaybe<Scalars['Boolean']['input']>;
    id?: InputMaybe<Scalars['String']['input']>;
    imgSrc?: InputMaybe<Scalars['String']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
    updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
    userId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "Groups" */
export enum Groups_Update_Column {
    /** column name */
    AssistantSettingsId = 'assistantSettingsId',
    /** column name */
    CreatedAt = 'createdAt',
    /** column name */
    Default = 'default',
    /** column name */
    Id = 'id',
    /** column name */
    ImgSrc = 'imgSrc',
    /** column name */
    Name = 'name',
    /** column name */
    UpdatedAt = 'updatedAt',
    /** column name */
    UserId = 'userId',
}

export type Groups_Updates = {
    /** sets the columns of the filtered rows to the given values */
    _set?: InputMaybe<Groups_Set_Input>;
    /** filter the rows which have to be updated */
    where: Groups_Bool_Exp;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
    _eq?: InputMaybe<Scalars['Int']['input']>;
    _gt?: InputMaybe<Scalars['Int']['input']>;
    _gte?: InputMaybe<Scalars['Int']['input']>;
    _in?: InputMaybe<Array<Scalars['Int']['input']>>;
    _is_null?: InputMaybe<Scalars['Boolean']['input']>;
    _lt?: InputMaybe<Scalars['Int']['input']>;
    _lte?: InputMaybe<Scalars['Int']['input']>;
    _neq?: InputMaybe<Scalars['Int']['input']>;
    _nin?: InputMaybe<Array<Scalars['Int']['input']>>;
};

/** columns and relationships of "LinkedinConversations" */
export type LinkedinConversations = {
    __typename?: 'LinkedinConversations';
    /** An array relationship */
    Message: Array<Messages>;
    /** An aggregate relationship */
    Message_aggregate: Messages_Aggregate;
    categories?: Maybe<Array<Scalars['String']['output']>>;
    channelAccountId: Scalars['String']['output'];
    conversationId: Scalars['String']['output'];
    createdAt: Scalars['timestamp']['output'];
    groupChat?: Maybe<Scalars['Boolean']['output']>;
    id: Scalars['String']['output'];
    lastMessageSentAt?: Maybe<Scalars['timestamp']['output']>;
    lastMessageSentDirection?: Maybe<Scalars['String']['output']>;
    lastMessageText?: Maybe<Scalars['String']['output']>;
    lastMessageType?: Maybe<Scalars['MessageType']['output']>;
    organizationId: Scalars['String']['output'];
    participantMemberIds?: Maybe<Array<Scalars['String']['output']>>;
    prospectId?: Maybe<Scalars['String']['output']>;
    updatedAt: Scalars['timestamp']['output'];
    userId: Scalars['String']['output'];
};

/** columns and relationships of "LinkedinConversations" */
export type LinkedinConversationsMessageArgs = {
    distinct_on?: InputMaybe<Array<Messages_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<Messages_Order_By>>;
    where?: InputMaybe<Messages_Bool_Exp>;
};

/** columns and relationships of "LinkedinConversations" */
export type LinkedinConversationsMessage_AggregateArgs = {
    distinct_on?: InputMaybe<Array<Messages_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<Messages_Order_By>>;
    where?: InputMaybe<Messages_Bool_Exp>;
};

/** aggregated selection of "LinkedinConversations" */
export type LinkedinConversations_Aggregate = {
    __typename?: 'LinkedinConversations_aggregate';
    aggregate?: Maybe<LinkedinConversations_Aggregate_Fields>;
    nodes: Array<LinkedinConversations>;
};

/** aggregate fields of "LinkedinConversations" */
export type LinkedinConversations_Aggregate_Fields = {
    __typename?: 'LinkedinConversations_aggregate_fields';
    count: Scalars['Int']['output'];
    max?: Maybe<LinkedinConversations_Max_Fields>;
    min?: Maybe<LinkedinConversations_Min_Fields>;
};

/** aggregate fields of "LinkedinConversations" */
export type LinkedinConversations_Aggregate_FieldsCountArgs = {
    columns?: InputMaybe<Array<LinkedinConversations_Select_Column>>;
    distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "LinkedinConversations". All fields are combined with a logical 'AND'. */
export type LinkedinConversations_Bool_Exp = {
    Message?: InputMaybe<Messages_Bool_Exp>;
    Message_aggregate?: InputMaybe<Messages_Aggregate_Bool_Exp>;
    _and?: InputMaybe<Array<LinkedinConversations_Bool_Exp>>;
    _not?: InputMaybe<LinkedinConversations_Bool_Exp>;
    _or?: InputMaybe<Array<LinkedinConversations_Bool_Exp>>;
    categories?: InputMaybe<String_Array_Comparison_Exp>;
    channelAccountId?: InputMaybe<String_Comparison_Exp>;
    conversationId?: InputMaybe<String_Comparison_Exp>;
    createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
    groupChat?: InputMaybe<Boolean_Comparison_Exp>;
    id?: InputMaybe<String_Comparison_Exp>;
    lastMessageSentAt?: InputMaybe<Timestamp_Comparison_Exp>;
    lastMessageSentDirection?: InputMaybe<String_Comparison_Exp>;
    lastMessageText?: InputMaybe<String_Comparison_Exp>;
    lastMessageType?: InputMaybe<MessageType_Comparison_Exp>;
    organizationId?: InputMaybe<String_Comparison_Exp>;
    participantMemberIds?: InputMaybe<String_Array_Comparison_Exp>;
    prospectId?: InputMaybe<String_Comparison_Exp>;
    updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
    userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "LinkedinConversations" */
export enum LinkedinConversations_Constraint {
    /** unique or primary key constraint on columns "userId", "conversationId" */
    LinkedinConversationsConversationIdUserIdKey = 'LinkedinConversations_conversationId_userId_key',
    /** unique or primary key constraint on columns "id" */
    LinkedinConversationsPkey = 'LinkedinConversations_pkey',
}

/** input type for inserting data into table "LinkedinConversations" */
export type LinkedinConversations_Insert_Input = {
    Message?: InputMaybe<Messages_Arr_Rel_Insert_Input>;
    categories?: InputMaybe<Array<Scalars['String']['input']>>;
    channelAccountId?: InputMaybe<Scalars['String']['input']>;
    conversationId?: InputMaybe<Scalars['String']['input']>;
    createdAt?: InputMaybe<Scalars['timestamp']['input']>;
    groupChat?: InputMaybe<Scalars['Boolean']['input']>;
    id?: InputMaybe<Scalars['String']['input']>;
    lastMessageSentAt?: InputMaybe<Scalars['timestamp']['input']>;
    lastMessageSentDirection?: InputMaybe<Scalars['String']['input']>;
    lastMessageText?: InputMaybe<Scalars['String']['input']>;
    lastMessageType?: InputMaybe<Scalars['MessageType']['input']>;
    organizationId?: InputMaybe<Scalars['String']['input']>;
    participantMemberIds?: InputMaybe<Array<Scalars['String']['input']>>;
    prospectId?: InputMaybe<Scalars['String']['input']>;
    updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
    userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type LinkedinConversations_Max_Fields = {
    __typename?: 'LinkedinConversations_max_fields';
    categories?: Maybe<Array<Scalars['String']['output']>>;
    channelAccountId?: Maybe<Scalars['String']['output']>;
    conversationId?: Maybe<Scalars['String']['output']>;
    createdAt?: Maybe<Scalars['timestamp']['output']>;
    id?: Maybe<Scalars['String']['output']>;
    lastMessageSentAt?: Maybe<Scalars['timestamp']['output']>;
    lastMessageSentDirection?: Maybe<Scalars['String']['output']>;
    lastMessageText?: Maybe<Scalars['String']['output']>;
    lastMessageType?: Maybe<Scalars['MessageType']['output']>;
    organizationId?: Maybe<Scalars['String']['output']>;
    participantMemberIds?: Maybe<Array<Scalars['String']['output']>>;
    prospectId?: Maybe<Scalars['String']['output']>;
    updatedAt?: Maybe<Scalars['timestamp']['output']>;
    userId?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type LinkedinConversations_Min_Fields = {
    __typename?: 'LinkedinConversations_min_fields';
    categories?: Maybe<Array<Scalars['String']['output']>>;
    channelAccountId?: Maybe<Scalars['String']['output']>;
    conversationId?: Maybe<Scalars['String']['output']>;
    createdAt?: Maybe<Scalars['timestamp']['output']>;
    id?: Maybe<Scalars['String']['output']>;
    lastMessageSentAt?: Maybe<Scalars['timestamp']['output']>;
    lastMessageSentDirection?: Maybe<Scalars['String']['output']>;
    lastMessageText?: Maybe<Scalars['String']['output']>;
    lastMessageType?: Maybe<Scalars['MessageType']['output']>;
    organizationId?: Maybe<Scalars['String']['output']>;
    participantMemberIds?: Maybe<Array<Scalars['String']['output']>>;
    prospectId?: Maybe<Scalars['String']['output']>;
    updatedAt?: Maybe<Scalars['timestamp']['output']>;
    userId?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "LinkedinConversations" */
export type LinkedinConversations_Mutation_Response = {
    __typename?: 'LinkedinConversations_mutation_response';
    /** number of rows affected by the mutation */
    affected_rows: Scalars['Int']['output'];
    /** data from the rows affected by the mutation */
    returning: Array<LinkedinConversations>;
};

/** input type for inserting object relation for remote table "LinkedinConversations" */
export type LinkedinConversations_Obj_Rel_Insert_Input = {
    data: LinkedinConversations_Insert_Input;
    /** upsert condition */
    on_conflict?: InputMaybe<LinkedinConversations_On_Conflict>;
};

/** on_conflict condition type for table "LinkedinConversations" */
export type LinkedinConversations_On_Conflict = {
    constraint: LinkedinConversations_Constraint;
    update_columns?: Array<LinkedinConversations_Update_Column>;
    where?: InputMaybe<LinkedinConversations_Bool_Exp>;
};

/** Ordering options when selecting data from "LinkedinConversations". */
export type LinkedinConversations_Order_By = {
    Message_aggregate?: InputMaybe<Messages_Aggregate_Order_By>;
    categories?: InputMaybe<Order_By>;
    channelAccountId?: InputMaybe<Order_By>;
    conversationId?: InputMaybe<Order_By>;
    createdAt?: InputMaybe<Order_By>;
    groupChat?: InputMaybe<Order_By>;
    id?: InputMaybe<Order_By>;
    lastMessageSentAt?: InputMaybe<Order_By>;
    lastMessageSentDirection?: InputMaybe<Order_By>;
    lastMessageText?: InputMaybe<Order_By>;
    lastMessageType?: InputMaybe<Order_By>;
    organizationId?: InputMaybe<Order_By>;
    participantMemberIds?: InputMaybe<Order_By>;
    prospectId?: InputMaybe<Order_By>;
    updatedAt?: InputMaybe<Order_By>;
    userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: LinkedinConversations */
export type LinkedinConversations_Pk_Columns_Input = {
    id: Scalars['String']['input'];
};

/** select columns of table "LinkedinConversations" */
export enum LinkedinConversations_Select_Column {
    /** column name */
    Categories = 'categories',
    /** column name */
    ChannelAccountId = 'channelAccountId',
    /** column name */
    ConversationId = 'conversationId',
    /** column name */
    CreatedAt = 'createdAt',
    /** column name */
    GroupChat = 'groupChat',
    /** column name */
    Id = 'id',
    /** column name */
    LastMessageSentAt = 'lastMessageSentAt',
    /** column name */
    LastMessageSentDirection = 'lastMessageSentDirection',
    /** column name */
    LastMessageText = 'lastMessageText',
    /** column name */
    LastMessageType = 'lastMessageType',
    /** column name */
    OrganizationId = 'organizationId',
    /** column name */
    ParticipantMemberIds = 'participantMemberIds',
    /** column name */
    ProspectId = 'prospectId',
    /** column name */
    UpdatedAt = 'updatedAt',
    /** column name */
    UserId = 'userId',
}

/** input type for updating data in table "LinkedinConversations" */
export type LinkedinConversations_Set_Input = {
    categories?: InputMaybe<Array<Scalars['String']['input']>>;
    channelAccountId?: InputMaybe<Scalars['String']['input']>;
    conversationId?: InputMaybe<Scalars['String']['input']>;
    createdAt?: InputMaybe<Scalars['timestamp']['input']>;
    groupChat?: InputMaybe<Scalars['Boolean']['input']>;
    id?: InputMaybe<Scalars['String']['input']>;
    lastMessageSentAt?: InputMaybe<Scalars['timestamp']['input']>;
    lastMessageSentDirection?: InputMaybe<Scalars['String']['input']>;
    lastMessageText?: InputMaybe<Scalars['String']['input']>;
    lastMessageType?: InputMaybe<Scalars['MessageType']['input']>;
    organizationId?: InputMaybe<Scalars['String']['input']>;
    participantMemberIds?: InputMaybe<Array<Scalars['String']['input']>>;
    prospectId?: InputMaybe<Scalars['String']['input']>;
    updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
    userId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "LinkedinConversations" */
export type LinkedinConversations_Stream_Cursor_Input = {
    /** Stream column input with initial value */
    initial_value: LinkedinConversations_Stream_Cursor_Value_Input;
    /** cursor ordering */
    ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type LinkedinConversations_Stream_Cursor_Value_Input = {
    categories?: InputMaybe<Array<Scalars['String']['input']>>;
    channelAccountId?: InputMaybe<Scalars['String']['input']>;
    conversationId?: InputMaybe<Scalars['String']['input']>;
    createdAt?: InputMaybe<Scalars['timestamp']['input']>;
    groupChat?: InputMaybe<Scalars['Boolean']['input']>;
    id?: InputMaybe<Scalars['String']['input']>;
    lastMessageSentAt?: InputMaybe<Scalars['timestamp']['input']>;
    lastMessageSentDirection?: InputMaybe<Scalars['String']['input']>;
    lastMessageText?: InputMaybe<Scalars['String']['input']>;
    lastMessageType?: InputMaybe<Scalars['MessageType']['input']>;
    organizationId?: InputMaybe<Scalars['String']['input']>;
    participantMemberIds?: InputMaybe<Array<Scalars['String']['input']>>;
    prospectId?: InputMaybe<Scalars['String']['input']>;
    updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
    userId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "LinkedinConversations" */
export enum LinkedinConversations_Update_Column {
    /** column name */
    Categories = 'categories',
    /** column name */
    ChannelAccountId = 'channelAccountId',
    /** column name */
    ConversationId = 'conversationId',
    /** column name */
    CreatedAt = 'createdAt',
    /** column name */
    GroupChat = 'groupChat',
    /** column name */
    Id = 'id',
    /** column name */
    LastMessageSentAt = 'lastMessageSentAt',
    /** column name */
    LastMessageSentDirection = 'lastMessageSentDirection',
    /** column name */
    LastMessageText = 'lastMessageText',
    /** column name */
    LastMessageType = 'lastMessageType',
    /** column name */
    OrganizationId = 'organizationId',
    /** column name */
    ParticipantMemberIds = 'participantMemberIds',
    /** column name */
    ProspectId = 'prospectId',
    /** column name */
    UpdatedAt = 'updatedAt',
    /** column name */
    UserId = 'userId',
}

export type LinkedinConversations_Updates = {
    /** sets the columns of the filtered rows to the given values */
    _set?: InputMaybe<LinkedinConversations_Set_Input>;
    /** filter the rows which have to be updated */
    where: LinkedinConversations_Bool_Exp;
};

/** Boolean expression to compare columns of type "MessageType". All fields are combined with logical 'AND'. */
export type MessageType_Comparison_Exp = {
    _eq?: InputMaybe<Scalars['MessageType']['input']>;
    _gt?: InputMaybe<Scalars['MessageType']['input']>;
    _gte?: InputMaybe<Scalars['MessageType']['input']>;
    _in?: InputMaybe<Array<Scalars['MessageType']['input']>>;
    _is_null?: InputMaybe<Scalars['Boolean']['input']>;
    _lt?: InputMaybe<Scalars['MessageType']['input']>;
    _lte?: InputMaybe<Scalars['MessageType']['input']>;
    _neq?: InputMaybe<Scalars['MessageType']['input']>;
    _nin?: InputMaybe<Array<Scalars['MessageType']['input']>>;
};

/** columns and relationships of "Messages" */
export type Messages = {
    __typename?: 'Messages';
    /** An array relationship */
    Attachments: Array<Attachments>;
    /** An aggregate relationship */
    Attachments_aggregate: Attachments_Aggregate;
    /** An object relationship */
    LinkedinConversation?: Maybe<LinkedinConversations>;
    /** An object relationship */
    OutreachSuggestion?: Maybe<OutreachSuggestions>;
    /** An array relationship */
    OutreachSuggestions: Array<OutreachSuggestions>;
    /** An aggregate relationship */
    OutreachSuggestions_aggregate: OutreachSuggestions_Aggregate;
    actionType?: Maybe<Scalars['MessageType']['output']>;
    approvedAt?: Maybe<Scalars['timestamp']['output']>;
    assistantId?: Maybe<Scalars['String']['output']>;
    campaignId?: Maybe<Scalars['String']['output']>;
    channelAccountId?: Maybe<Scalars['String']['output']>;
    channelType?: Maybe<Scalars['ChannelType']['output']>;
    content?: Maybe<Scalars['String']['output']>;
    contentType?: Maybe<Scalars['String']['output']>;
    conversationId?: Maybe<Scalars['String']['output']>;
    createdAt: Scalars['timestamp']['output'];
    deliveryStatus?: Maybe<Scalars['DeliveryStatus']['output']>;
    direction: Scalars['String']['output'];
    externalMessageId?: Maybe<Scalars['String']['output']>;
    fullName?: Maybe<Scalars['String']['output']>;
    id: Scalars['String']['output'];
    isSentFromApp?: Maybe<Scalars['Boolean']['output']>;
    linkedinConversationId?: Maybe<Scalars['String']['output']>;
    linkedinUserId?: Maybe<Scalars['String']['output']>;
    organizationId: Scalars['String']['output'];
    personId?: Maybe<Scalars['String']['output']>;
    prospectFullName?: Maybe<Scalars['String']['output']>;
    prospectId?: Maybe<Scalars['String']['output']>;
    sentOn?: Maybe<Scalars['timestamp']['output']>;
    stepId?: Maybe<Scalars['String']['output']>;
    subjectLine?: Maybe<Scalars['String']['output']>;
    updatedAt: Scalars['timestamp']['output'];
    userId: Scalars['String']['output'];
};

/** columns and relationships of "Messages" */
export type MessagesAttachmentsArgs = {
    distinct_on?: InputMaybe<Array<Attachments_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<Attachments_Order_By>>;
    where?: InputMaybe<Attachments_Bool_Exp>;
};

/** columns and relationships of "Messages" */
export type MessagesAttachments_AggregateArgs = {
    distinct_on?: InputMaybe<Array<Attachments_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<Attachments_Order_By>>;
    where?: InputMaybe<Attachments_Bool_Exp>;
};

/** columns and relationships of "Messages" */
export type MessagesOutreachSuggestionsArgs = {
    distinct_on?: InputMaybe<Array<OutreachSuggestions_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<OutreachSuggestions_Order_By>>;
    where?: InputMaybe<OutreachSuggestions_Bool_Exp>;
};

/** columns and relationships of "Messages" */
export type MessagesOutreachSuggestions_AggregateArgs = {
    distinct_on?: InputMaybe<Array<OutreachSuggestions_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<OutreachSuggestions_Order_By>>;
    where?: InputMaybe<OutreachSuggestions_Bool_Exp>;
};

/** aggregated selection of "Messages" */
export type Messages_Aggregate = {
    __typename?: 'Messages_aggregate';
    aggregate?: Maybe<Messages_Aggregate_Fields>;
    nodes: Array<Messages>;
};

export type Messages_Aggregate_Bool_Exp = {
    bool_and?: InputMaybe<Messages_Aggregate_Bool_Exp_Bool_And>;
    bool_or?: InputMaybe<Messages_Aggregate_Bool_Exp_Bool_Or>;
    count?: InputMaybe<Messages_Aggregate_Bool_Exp_Count>;
};

export type Messages_Aggregate_Bool_Exp_Bool_And = {
    arguments: Messages_Select_Column_Messages_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
    distinct?: InputMaybe<Scalars['Boolean']['input']>;
    filter?: InputMaybe<Messages_Bool_Exp>;
    predicate: Boolean_Comparison_Exp;
};

export type Messages_Aggregate_Bool_Exp_Bool_Or = {
    arguments: Messages_Select_Column_Messages_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
    distinct?: InputMaybe<Scalars['Boolean']['input']>;
    filter?: InputMaybe<Messages_Bool_Exp>;
    predicate: Boolean_Comparison_Exp;
};

export type Messages_Aggregate_Bool_Exp_Count = {
    arguments?: InputMaybe<Array<Messages_Select_Column>>;
    distinct?: InputMaybe<Scalars['Boolean']['input']>;
    filter?: InputMaybe<Messages_Bool_Exp>;
    predicate: Int_Comparison_Exp;
};

/** aggregate fields of "Messages" */
export type Messages_Aggregate_Fields = {
    __typename?: 'Messages_aggregate_fields';
    count: Scalars['Int']['output'];
    max?: Maybe<Messages_Max_Fields>;
    min?: Maybe<Messages_Min_Fields>;
};

/** aggregate fields of "Messages" */
export type Messages_Aggregate_FieldsCountArgs = {
    columns?: InputMaybe<Array<Messages_Select_Column>>;
    distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "Messages" */
export type Messages_Aggregate_Order_By = {
    count?: InputMaybe<Order_By>;
    max?: InputMaybe<Messages_Max_Order_By>;
    min?: InputMaybe<Messages_Min_Order_By>;
};

/** input type for inserting array relation for remote table "Messages" */
export type Messages_Arr_Rel_Insert_Input = {
    data: Array<Messages_Insert_Input>;
    /** upsert condition */
    on_conflict?: InputMaybe<Messages_On_Conflict>;
};

/** Boolean expression to filter rows from the table "Messages". All fields are combined with a logical 'AND'. */
export type Messages_Bool_Exp = {
    Attachments?: InputMaybe<Attachments_Bool_Exp>;
    Attachments_aggregate?: InputMaybe<Attachments_Aggregate_Bool_Exp>;
    LinkedinConversation?: InputMaybe<LinkedinConversations_Bool_Exp>;
    OutreachSuggestion?: InputMaybe<OutreachSuggestions_Bool_Exp>;
    OutreachSuggestions?: InputMaybe<OutreachSuggestions_Bool_Exp>;
    OutreachSuggestions_aggregate?: InputMaybe<OutreachSuggestions_Aggregate_Bool_Exp>;
    _and?: InputMaybe<Array<Messages_Bool_Exp>>;
    _not?: InputMaybe<Messages_Bool_Exp>;
    _or?: InputMaybe<Array<Messages_Bool_Exp>>;
    actionType?: InputMaybe<MessageType_Comparison_Exp>;
    approvedAt?: InputMaybe<Timestamp_Comparison_Exp>;
    assistantId?: InputMaybe<String_Comparison_Exp>;
    campaignId?: InputMaybe<String_Comparison_Exp>;
    channelAccountId?: InputMaybe<String_Comparison_Exp>;
    channelType?: InputMaybe<ChannelType_Comparison_Exp>;
    content?: InputMaybe<String_Comparison_Exp>;
    contentType?: InputMaybe<String_Comparison_Exp>;
    conversationId?: InputMaybe<String_Comparison_Exp>;
    createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
    deliveryStatus?: InputMaybe<DeliveryStatus_Comparison_Exp>;
    direction?: InputMaybe<String_Comparison_Exp>;
    externalMessageId?: InputMaybe<String_Comparison_Exp>;
    fullName?: InputMaybe<String_Comparison_Exp>;
    id?: InputMaybe<String_Comparison_Exp>;
    isSentFromApp?: InputMaybe<Boolean_Comparison_Exp>;
    linkedinConversationId?: InputMaybe<String_Comparison_Exp>;
    linkedinUserId?: InputMaybe<String_Comparison_Exp>;
    organizationId?: InputMaybe<String_Comparison_Exp>;
    personId?: InputMaybe<String_Comparison_Exp>;
    prospectFullName?: InputMaybe<String_Comparison_Exp>;
    prospectId?: InputMaybe<String_Comparison_Exp>;
    sentOn?: InputMaybe<Timestamp_Comparison_Exp>;
    stepId?: InputMaybe<String_Comparison_Exp>;
    subjectLine?: InputMaybe<String_Comparison_Exp>;
    updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
    userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Messages" */
export enum Messages_Constraint {
    /** unique or primary key constraint on columns "id" */
    MessagesPkey = 'Messages_pkey',
    /** unique or primary key constraint on columns "userId", "externalMessageId", "actionType", "prospectId" */
    MessagesUserIdProspectIdActionTypeExternalMessageIdKey = 'Messages_userId_prospectId_actionType_externalMessageId_key',
}

/** input type for inserting data into table "Messages" */
export type Messages_Insert_Input = {
    Attachments?: InputMaybe<Attachments_Arr_Rel_Insert_Input>;
    LinkedinConversation?: InputMaybe<LinkedinConversations_Obj_Rel_Insert_Input>;
    OutreachSuggestion?: InputMaybe<OutreachSuggestions_Obj_Rel_Insert_Input>;
    OutreachSuggestions?: InputMaybe<OutreachSuggestions_Arr_Rel_Insert_Input>;
    actionType?: InputMaybe<Scalars['MessageType']['input']>;
    approvedAt?: InputMaybe<Scalars['timestamp']['input']>;
    assistantId?: InputMaybe<Scalars['String']['input']>;
    campaignId?: InputMaybe<Scalars['String']['input']>;
    channelAccountId?: InputMaybe<Scalars['String']['input']>;
    channelType?: InputMaybe<Scalars['ChannelType']['input']>;
    content?: InputMaybe<Scalars['String']['input']>;
    contentType?: InputMaybe<Scalars['String']['input']>;
    conversationId?: InputMaybe<Scalars['String']['input']>;
    createdAt?: InputMaybe<Scalars['timestamp']['input']>;
    deliveryStatus?: InputMaybe<Scalars['DeliveryStatus']['input']>;
    direction?: InputMaybe<Scalars['String']['input']>;
    externalMessageId?: InputMaybe<Scalars['String']['input']>;
    fullName?: InputMaybe<Scalars['String']['input']>;
    id?: InputMaybe<Scalars['String']['input']>;
    isSentFromApp?: InputMaybe<Scalars['Boolean']['input']>;
    linkedinConversationId?: InputMaybe<Scalars['String']['input']>;
    linkedinUserId?: InputMaybe<Scalars['String']['input']>;
    organizationId?: InputMaybe<Scalars['String']['input']>;
    personId?: InputMaybe<Scalars['String']['input']>;
    prospectFullName?: InputMaybe<Scalars['String']['input']>;
    prospectId?: InputMaybe<Scalars['String']['input']>;
    sentOn?: InputMaybe<Scalars['timestamp']['input']>;
    stepId?: InputMaybe<Scalars['String']['input']>;
    subjectLine?: InputMaybe<Scalars['String']['input']>;
    updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
    userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Messages_Max_Fields = {
    __typename?: 'Messages_max_fields';
    actionType?: Maybe<Scalars['MessageType']['output']>;
    approvedAt?: Maybe<Scalars['timestamp']['output']>;
    assistantId?: Maybe<Scalars['String']['output']>;
    campaignId?: Maybe<Scalars['String']['output']>;
    channelAccountId?: Maybe<Scalars['String']['output']>;
    channelType?: Maybe<Scalars['ChannelType']['output']>;
    content?: Maybe<Scalars['String']['output']>;
    contentType?: Maybe<Scalars['String']['output']>;
    conversationId?: Maybe<Scalars['String']['output']>;
    createdAt?: Maybe<Scalars['timestamp']['output']>;
    deliveryStatus?: Maybe<Scalars['DeliveryStatus']['output']>;
    direction?: Maybe<Scalars['String']['output']>;
    externalMessageId?: Maybe<Scalars['String']['output']>;
    fullName?: Maybe<Scalars['String']['output']>;
    id?: Maybe<Scalars['String']['output']>;
    linkedinConversationId?: Maybe<Scalars['String']['output']>;
    linkedinUserId?: Maybe<Scalars['String']['output']>;
    organizationId?: Maybe<Scalars['String']['output']>;
    personId?: Maybe<Scalars['String']['output']>;
    prospectFullName?: Maybe<Scalars['String']['output']>;
    prospectId?: Maybe<Scalars['String']['output']>;
    sentOn?: Maybe<Scalars['timestamp']['output']>;
    stepId?: Maybe<Scalars['String']['output']>;
    subjectLine?: Maybe<Scalars['String']['output']>;
    updatedAt?: Maybe<Scalars['timestamp']['output']>;
    userId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "Messages" */
export type Messages_Max_Order_By = {
    actionType?: InputMaybe<Order_By>;
    approvedAt?: InputMaybe<Order_By>;
    assistantId?: InputMaybe<Order_By>;
    campaignId?: InputMaybe<Order_By>;
    channelAccountId?: InputMaybe<Order_By>;
    channelType?: InputMaybe<Order_By>;
    content?: InputMaybe<Order_By>;
    contentType?: InputMaybe<Order_By>;
    conversationId?: InputMaybe<Order_By>;
    createdAt?: InputMaybe<Order_By>;
    deliveryStatus?: InputMaybe<Order_By>;
    direction?: InputMaybe<Order_By>;
    externalMessageId?: InputMaybe<Order_By>;
    fullName?: InputMaybe<Order_By>;
    id?: InputMaybe<Order_By>;
    linkedinConversationId?: InputMaybe<Order_By>;
    linkedinUserId?: InputMaybe<Order_By>;
    organizationId?: InputMaybe<Order_By>;
    personId?: InputMaybe<Order_By>;
    prospectFullName?: InputMaybe<Order_By>;
    prospectId?: InputMaybe<Order_By>;
    sentOn?: InputMaybe<Order_By>;
    stepId?: InputMaybe<Order_By>;
    subjectLine?: InputMaybe<Order_By>;
    updatedAt?: InputMaybe<Order_By>;
    userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Messages_Min_Fields = {
    __typename?: 'Messages_min_fields';
    actionType?: Maybe<Scalars['MessageType']['output']>;
    approvedAt?: Maybe<Scalars['timestamp']['output']>;
    assistantId?: Maybe<Scalars['String']['output']>;
    campaignId?: Maybe<Scalars['String']['output']>;
    channelAccountId?: Maybe<Scalars['String']['output']>;
    channelType?: Maybe<Scalars['ChannelType']['output']>;
    content?: Maybe<Scalars['String']['output']>;
    contentType?: Maybe<Scalars['String']['output']>;
    conversationId?: Maybe<Scalars['String']['output']>;
    createdAt?: Maybe<Scalars['timestamp']['output']>;
    deliveryStatus?: Maybe<Scalars['DeliveryStatus']['output']>;
    direction?: Maybe<Scalars['String']['output']>;
    externalMessageId?: Maybe<Scalars['String']['output']>;
    fullName?: Maybe<Scalars['String']['output']>;
    id?: Maybe<Scalars['String']['output']>;
    linkedinConversationId?: Maybe<Scalars['String']['output']>;
    linkedinUserId?: Maybe<Scalars['String']['output']>;
    organizationId?: Maybe<Scalars['String']['output']>;
    personId?: Maybe<Scalars['String']['output']>;
    prospectFullName?: Maybe<Scalars['String']['output']>;
    prospectId?: Maybe<Scalars['String']['output']>;
    sentOn?: Maybe<Scalars['timestamp']['output']>;
    stepId?: Maybe<Scalars['String']['output']>;
    subjectLine?: Maybe<Scalars['String']['output']>;
    updatedAt?: Maybe<Scalars['timestamp']['output']>;
    userId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "Messages" */
export type Messages_Min_Order_By = {
    actionType?: InputMaybe<Order_By>;
    approvedAt?: InputMaybe<Order_By>;
    assistantId?: InputMaybe<Order_By>;
    campaignId?: InputMaybe<Order_By>;
    channelAccountId?: InputMaybe<Order_By>;
    channelType?: InputMaybe<Order_By>;
    content?: InputMaybe<Order_By>;
    contentType?: InputMaybe<Order_By>;
    conversationId?: InputMaybe<Order_By>;
    createdAt?: InputMaybe<Order_By>;
    deliveryStatus?: InputMaybe<Order_By>;
    direction?: InputMaybe<Order_By>;
    externalMessageId?: InputMaybe<Order_By>;
    fullName?: InputMaybe<Order_By>;
    id?: InputMaybe<Order_By>;
    linkedinConversationId?: InputMaybe<Order_By>;
    linkedinUserId?: InputMaybe<Order_By>;
    organizationId?: InputMaybe<Order_By>;
    personId?: InputMaybe<Order_By>;
    prospectFullName?: InputMaybe<Order_By>;
    prospectId?: InputMaybe<Order_By>;
    sentOn?: InputMaybe<Order_By>;
    stepId?: InputMaybe<Order_By>;
    subjectLine?: InputMaybe<Order_By>;
    updatedAt?: InputMaybe<Order_By>;
    userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Messages" */
export type Messages_Mutation_Response = {
    __typename?: 'Messages_mutation_response';
    /** number of rows affected by the mutation */
    affected_rows: Scalars['Int']['output'];
    /** data from the rows affected by the mutation */
    returning: Array<Messages>;
};

/** input type for inserting object relation for remote table "Messages" */
export type Messages_Obj_Rel_Insert_Input = {
    data: Messages_Insert_Input;
    /** upsert condition */
    on_conflict?: InputMaybe<Messages_On_Conflict>;
};

/** on_conflict condition type for table "Messages" */
export type Messages_On_Conflict = {
    constraint: Messages_Constraint;
    update_columns?: Array<Messages_Update_Column>;
    where?: InputMaybe<Messages_Bool_Exp>;
};

/** Ordering options when selecting data from "Messages". */
export type Messages_Order_By = {
    Attachments_aggregate?: InputMaybe<Attachments_Aggregate_Order_By>;
    LinkedinConversation?: InputMaybe<LinkedinConversations_Order_By>;
    OutreachSuggestion?: InputMaybe<OutreachSuggestions_Order_By>;
    OutreachSuggestions_aggregate?: InputMaybe<OutreachSuggestions_Aggregate_Order_By>;
    actionType?: InputMaybe<Order_By>;
    approvedAt?: InputMaybe<Order_By>;
    assistantId?: InputMaybe<Order_By>;
    campaignId?: InputMaybe<Order_By>;
    channelAccountId?: InputMaybe<Order_By>;
    channelType?: InputMaybe<Order_By>;
    content?: InputMaybe<Order_By>;
    contentType?: InputMaybe<Order_By>;
    conversationId?: InputMaybe<Order_By>;
    createdAt?: InputMaybe<Order_By>;
    deliveryStatus?: InputMaybe<Order_By>;
    direction?: InputMaybe<Order_By>;
    externalMessageId?: InputMaybe<Order_By>;
    fullName?: InputMaybe<Order_By>;
    id?: InputMaybe<Order_By>;
    isSentFromApp?: InputMaybe<Order_By>;
    linkedinConversationId?: InputMaybe<Order_By>;
    linkedinUserId?: InputMaybe<Order_By>;
    organizationId?: InputMaybe<Order_By>;
    personId?: InputMaybe<Order_By>;
    prospectFullName?: InputMaybe<Order_By>;
    prospectId?: InputMaybe<Order_By>;
    sentOn?: InputMaybe<Order_By>;
    stepId?: InputMaybe<Order_By>;
    subjectLine?: InputMaybe<Order_By>;
    updatedAt?: InputMaybe<Order_By>;
    userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Messages */
export type Messages_Pk_Columns_Input = {
    id: Scalars['String']['input'];
};

/** select columns of table "Messages" */
export enum Messages_Select_Column {
    /** column name */
    ActionType = 'actionType',
    /** column name */
    ApprovedAt = 'approvedAt',
    /** column name */
    AssistantId = 'assistantId',
    /** column name */
    CampaignId = 'campaignId',
    /** column name */
    ChannelAccountId = 'channelAccountId',
    /** column name */
    ChannelType = 'channelType',
    /** column name */
    Content = 'content',
    /** column name */
    ContentType = 'contentType',
    /** column name */
    ConversationId = 'conversationId',
    /** column name */
    CreatedAt = 'createdAt',
    /** column name */
    DeliveryStatus = 'deliveryStatus',
    /** column name */
    Direction = 'direction',
    /** column name */
    ExternalMessageId = 'externalMessageId',
    /** column name */
    FullName = 'fullName',
    /** column name */
    Id = 'id',
    /** column name */
    IsSentFromApp = 'isSentFromApp',
    /** column name */
    LinkedinConversationId = 'linkedinConversationId',
    /** column name */
    LinkedinUserId = 'linkedinUserId',
    /** column name */
    OrganizationId = 'organizationId',
    /** column name */
    PersonId = 'personId',
    /** column name */
    ProspectFullName = 'prospectFullName',
    /** column name */
    ProspectId = 'prospectId',
    /** column name */
    SentOn = 'sentOn',
    /** column name */
    StepId = 'stepId',
    /** column name */
    SubjectLine = 'subjectLine',
    /** column name */
    UpdatedAt = 'updatedAt',
    /** column name */
    UserId = 'userId',
}

/** select "Messages_aggregate_bool_exp_bool_and_arguments_columns" columns of table "Messages" */
export enum Messages_Select_Column_Messages_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
    /** column name */
    IsSentFromApp = 'isSentFromApp',
}

/** select "Messages_aggregate_bool_exp_bool_or_arguments_columns" columns of table "Messages" */
export enum Messages_Select_Column_Messages_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
    /** column name */
    IsSentFromApp = 'isSentFromApp',
}

/** input type for updating data in table "Messages" */
export type Messages_Set_Input = {
    actionType?: InputMaybe<Scalars['MessageType']['input']>;
    approvedAt?: InputMaybe<Scalars['timestamp']['input']>;
    assistantId?: InputMaybe<Scalars['String']['input']>;
    campaignId?: InputMaybe<Scalars['String']['input']>;
    channelAccountId?: InputMaybe<Scalars['String']['input']>;
    channelType?: InputMaybe<Scalars['ChannelType']['input']>;
    content?: InputMaybe<Scalars['String']['input']>;
    contentType?: InputMaybe<Scalars['String']['input']>;
    conversationId?: InputMaybe<Scalars['String']['input']>;
    createdAt?: InputMaybe<Scalars['timestamp']['input']>;
    deliveryStatus?: InputMaybe<Scalars['DeliveryStatus']['input']>;
    direction?: InputMaybe<Scalars['String']['input']>;
    externalMessageId?: InputMaybe<Scalars['String']['input']>;
    fullName?: InputMaybe<Scalars['String']['input']>;
    id?: InputMaybe<Scalars['String']['input']>;
    isSentFromApp?: InputMaybe<Scalars['Boolean']['input']>;
    linkedinConversationId?: InputMaybe<Scalars['String']['input']>;
    linkedinUserId?: InputMaybe<Scalars['String']['input']>;
    organizationId?: InputMaybe<Scalars['String']['input']>;
    personId?: InputMaybe<Scalars['String']['input']>;
    prospectFullName?: InputMaybe<Scalars['String']['input']>;
    prospectId?: InputMaybe<Scalars['String']['input']>;
    sentOn?: InputMaybe<Scalars['timestamp']['input']>;
    stepId?: InputMaybe<Scalars['String']['input']>;
    subjectLine?: InputMaybe<Scalars['String']['input']>;
    updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
    userId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "Messages" */
export type Messages_Stream_Cursor_Input = {
    /** Stream column input with initial value */
    initial_value: Messages_Stream_Cursor_Value_Input;
    /** cursor ordering */
    ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Messages_Stream_Cursor_Value_Input = {
    actionType?: InputMaybe<Scalars['MessageType']['input']>;
    approvedAt?: InputMaybe<Scalars['timestamp']['input']>;
    assistantId?: InputMaybe<Scalars['String']['input']>;
    campaignId?: InputMaybe<Scalars['String']['input']>;
    channelAccountId?: InputMaybe<Scalars['String']['input']>;
    channelType?: InputMaybe<Scalars['ChannelType']['input']>;
    content?: InputMaybe<Scalars['String']['input']>;
    contentType?: InputMaybe<Scalars['String']['input']>;
    conversationId?: InputMaybe<Scalars['String']['input']>;
    createdAt?: InputMaybe<Scalars['timestamp']['input']>;
    deliveryStatus?: InputMaybe<Scalars['DeliveryStatus']['input']>;
    direction?: InputMaybe<Scalars['String']['input']>;
    externalMessageId?: InputMaybe<Scalars['String']['input']>;
    fullName?: InputMaybe<Scalars['String']['input']>;
    id?: InputMaybe<Scalars['String']['input']>;
    isSentFromApp?: InputMaybe<Scalars['Boolean']['input']>;
    linkedinConversationId?: InputMaybe<Scalars['String']['input']>;
    linkedinUserId?: InputMaybe<Scalars['String']['input']>;
    organizationId?: InputMaybe<Scalars['String']['input']>;
    personId?: InputMaybe<Scalars['String']['input']>;
    prospectFullName?: InputMaybe<Scalars['String']['input']>;
    prospectId?: InputMaybe<Scalars['String']['input']>;
    sentOn?: InputMaybe<Scalars['timestamp']['input']>;
    stepId?: InputMaybe<Scalars['String']['input']>;
    subjectLine?: InputMaybe<Scalars['String']['input']>;
    updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
    userId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "Messages" */
export enum Messages_Update_Column {
    /** column name */
    ActionType = 'actionType',
    /** column name */
    ApprovedAt = 'approvedAt',
    /** column name */
    AssistantId = 'assistantId',
    /** column name */
    CampaignId = 'campaignId',
    /** column name */
    ChannelAccountId = 'channelAccountId',
    /** column name */
    ChannelType = 'channelType',
    /** column name */
    Content = 'content',
    /** column name */
    ContentType = 'contentType',
    /** column name */
    ConversationId = 'conversationId',
    /** column name */
    CreatedAt = 'createdAt',
    /** column name */
    DeliveryStatus = 'deliveryStatus',
    /** column name */
    Direction = 'direction',
    /** column name */
    ExternalMessageId = 'externalMessageId',
    /** column name */
    FullName = 'fullName',
    /** column name */
    Id = 'id',
    /** column name */
    IsSentFromApp = 'isSentFromApp',
    /** column name */
    LinkedinConversationId = 'linkedinConversationId',
    /** column name */
    LinkedinUserId = 'linkedinUserId',
    /** column name */
    OrganizationId = 'organizationId',
    /** column name */
    PersonId = 'personId',
    /** column name */
    ProspectFullName = 'prospectFullName',
    /** column name */
    ProspectId = 'prospectId',
    /** column name */
    SentOn = 'sentOn',
    /** column name */
    StepId = 'stepId',
    /** column name */
    SubjectLine = 'subjectLine',
    /** column name */
    UpdatedAt = 'updatedAt',
    /** column name */
    UserId = 'userId',
}

export type Messages_Updates = {
    /** sets the columns of the filtered rows to the given values */
    _set?: InputMaybe<Messages_Set_Input>;
    /** filter the rows which have to be updated */
    where: Messages_Bool_Exp;
};

/** columns and relationships of "Notes" */
export type Notes = {
    __typename?: 'Notes';
    content: Scalars['String']['output'];
    createdAt: Scalars['timestamp']['output'];
    creator: Scalars['Creator']['output'];
    discarded: Scalars['Boolean']['output'];
    happenedOn: Scalars['timestamp']['output'];
    id: Scalars['String']['output'];
    prospectId: Scalars['String']['output'];
    title: Scalars['String']['output'];
    uniqueIdentifier: Scalars['String']['output'];
    updatedAt: Scalars['timestamp']['output'];
    userId: Scalars['String']['output'];
};

/** aggregated selection of "Notes" */
export type Notes_Aggregate = {
    __typename?: 'Notes_aggregate';
    aggregate?: Maybe<Notes_Aggregate_Fields>;
    nodes: Array<Notes>;
};

/** aggregate fields of "Notes" */
export type Notes_Aggregate_Fields = {
    __typename?: 'Notes_aggregate_fields';
    count: Scalars['Int']['output'];
    max?: Maybe<Notes_Max_Fields>;
    min?: Maybe<Notes_Min_Fields>;
};

/** aggregate fields of "Notes" */
export type Notes_Aggregate_FieldsCountArgs = {
    columns?: InputMaybe<Array<Notes_Select_Column>>;
    distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "Notes". All fields are combined with a logical 'AND'. */
export type Notes_Bool_Exp = {
    _and?: InputMaybe<Array<Notes_Bool_Exp>>;
    _not?: InputMaybe<Notes_Bool_Exp>;
    _or?: InputMaybe<Array<Notes_Bool_Exp>>;
    content?: InputMaybe<String_Comparison_Exp>;
    createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
    creator?: InputMaybe<Creator_Comparison_Exp>;
    discarded?: InputMaybe<Boolean_Comparison_Exp>;
    happenedOn?: InputMaybe<Timestamp_Comparison_Exp>;
    id?: InputMaybe<String_Comparison_Exp>;
    prospectId?: InputMaybe<String_Comparison_Exp>;
    title?: InputMaybe<String_Comparison_Exp>;
    uniqueIdentifier?: InputMaybe<String_Comparison_Exp>;
    updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
    userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Notes" */
export enum Notes_Constraint {
    /** unique or primary key constraint on columns "id" */
    NotesPkey = 'Notes_pkey',
    /** unique or primary key constraint on columns "userId", "uniqueIdentifier" */
    NotesUniqueIdentifierUserIdKey = 'Notes_uniqueIdentifier_userId_key',
}

/** input type for inserting data into table "Notes" */
export type Notes_Insert_Input = {
    content?: InputMaybe<Scalars['String']['input']>;
    createdAt?: InputMaybe<Scalars['timestamp']['input']>;
    creator?: InputMaybe<Scalars['Creator']['input']>;
    discarded?: InputMaybe<Scalars['Boolean']['input']>;
    happenedOn?: InputMaybe<Scalars['timestamp']['input']>;
    id?: InputMaybe<Scalars['String']['input']>;
    prospectId?: InputMaybe<Scalars['String']['input']>;
    title?: InputMaybe<Scalars['String']['input']>;
    uniqueIdentifier?: InputMaybe<Scalars['String']['input']>;
    updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
    userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Notes_Max_Fields = {
    __typename?: 'Notes_max_fields';
    content?: Maybe<Scalars['String']['output']>;
    createdAt?: Maybe<Scalars['timestamp']['output']>;
    creator?: Maybe<Scalars['Creator']['output']>;
    happenedOn?: Maybe<Scalars['timestamp']['output']>;
    id?: Maybe<Scalars['String']['output']>;
    prospectId?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
    uniqueIdentifier?: Maybe<Scalars['String']['output']>;
    updatedAt?: Maybe<Scalars['timestamp']['output']>;
    userId?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Notes_Min_Fields = {
    __typename?: 'Notes_min_fields';
    content?: Maybe<Scalars['String']['output']>;
    createdAt?: Maybe<Scalars['timestamp']['output']>;
    creator?: Maybe<Scalars['Creator']['output']>;
    happenedOn?: Maybe<Scalars['timestamp']['output']>;
    id?: Maybe<Scalars['String']['output']>;
    prospectId?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
    uniqueIdentifier?: Maybe<Scalars['String']['output']>;
    updatedAt?: Maybe<Scalars['timestamp']['output']>;
    userId?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "Notes" */
export type Notes_Mutation_Response = {
    __typename?: 'Notes_mutation_response';
    /** number of rows affected by the mutation */
    affected_rows: Scalars['Int']['output'];
    /** data from the rows affected by the mutation */
    returning: Array<Notes>;
};

/** on_conflict condition type for table "Notes" */
export type Notes_On_Conflict = {
    constraint: Notes_Constraint;
    update_columns?: Array<Notes_Update_Column>;
    where?: InputMaybe<Notes_Bool_Exp>;
};

/** Ordering options when selecting data from "Notes". */
export type Notes_Order_By = {
    content?: InputMaybe<Order_By>;
    createdAt?: InputMaybe<Order_By>;
    creator?: InputMaybe<Order_By>;
    discarded?: InputMaybe<Order_By>;
    happenedOn?: InputMaybe<Order_By>;
    id?: InputMaybe<Order_By>;
    prospectId?: InputMaybe<Order_By>;
    title?: InputMaybe<Order_By>;
    uniqueIdentifier?: InputMaybe<Order_By>;
    updatedAt?: InputMaybe<Order_By>;
    userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Notes */
export type Notes_Pk_Columns_Input = {
    id: Scalars['String']['input'];
};

/** select columns of table "Notes" */
export enum Notes_Select_Column {
    /** column name */
    Content = 'content',
    /** column name */
    CreatedAt = 'createdAt',
    /** column name */
    Creator = 'creator',
    /** column name */
    Discarded = 'discarded',
    /** column name */
    HappenedOn = 'happenedOn',
    /** column name */
    Id = 'id',
    /** column name */
    ProspectId = 'prospectId',
    /** column name */
    Title = 'title',
    /** column name */
    UniqueIdentifier = 'uniqueIdentifier',
    /** column name */
    UpdatedAt = 'updatedAt',
    /** column name */
    UserId = 'userId',
}

/** input type for updating data in table "Notes" */
export type Notes_Set_Input = {
    content?: InputMaybe<Scalars['String']['input']>;
    createdAt?: InputMaybe<Scalars['timestamp']['input']>;
    creator?: InputMaybe<Scalars['Creator']['input']>;
    discarded?: InputMaybe<Scalars['Boolean']['input']>;
    happenedOn?: InputMaybe<Scalars['timestamp']['input']>;
    id?: InputMaybe<Scalars['String']['input']>;
    prospectId?: InputMaybe<Scalars['String']['input']>;
    title?: InputMaybe<Scalars['String']['input']>;
    uniqueIdentifier?: InputMaybe<Scalars['String']['input']>;
    updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
    userId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "Notes" */
export type Notes_Stream_Cursor_Input = {
    /** Stream column input with initial value */
    initial_value: Notes_Stream_Cursor_Value_Input;
    /** cursor ordering */
    ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Notes_Stream_Cursor_Value_Input = {
    content?: InputMaybe<Scalars['String']['input']>;
    createdAt?: InputMaybe<Scalars['timestamp']['input']>;
    creator?: InputMaybe<Scalars['Creator']['input']>;
    discarded?: InputMaybe<Scalars['Boolean']['input']>;
    happenedOn?: InputMaybe<Scalars['timestamp']['input']>;
    id?: InputMaybe<Scalars['String']['input']>;
    prospectId?: InputMaybe<Scalars['String']['input']>;
    title?: InputMaybe<Scalars['String']['input']>;
    uniqueIdentifier?: InputMaybe<Scalars['String']['input']>;
    updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
    userId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "Notes" */
export enum Notes_Update_Column {
    /** column name */
    Content = 'content',
    /** column name */
    CreatedAt = 'createdAt',
    /** column name */
    Creator = 'creator',
    /** column name */
    Discarded = 'discarded',
    /** column name */
    HappenedOn = 'happenedOn',
    /** column name */
    Id = 'id',
    /** column name */
    ProspectId = 'prospectId',
    /** column name */
    Title = 'title',
    /** column name */
    UniqueIdentifier = 'uniqueIdentifier',
    /** column name */
    UpdatedAt = 'updatedAt',
    /** column name */
    UserId = 'userId',
}

export type Notes_Updates = {
    /** sets the columns of the filtered rows to the given values */
    _set?: InputMaybe<Notes_Set_Input>;
    /** filter the rows which have to be updated */
    where: Notes_Bool_Exp;
};

/** columns and relationships of "OutreachCompletionMetrics" */
export type OutreachCompletionMetrics = {
    __typename?: 'OutreachCompletionMetrics';
    completedSameDay: Scalars['Int']['output'];
    completedWithin2Days: Scalars['Int']['output'];
    completedWithin3Days: Scalars['Int']['output'];
    completedWithin7Days: Scalars['Int']['output'];
    completedWithin30Days: Scalars['Int']['output'];
    date: Scalars['timestamp']['output'];
    goalsReachedSameDay: Scalars['Int']['output'];
    goalsReachedWithin2Days: Scalars['Int']['output'];
    goalsReachedWithin3Days: Scalars['Int']['output'];
    goalsReachedWithin7Days: Scalars['Int']['output'];
    goalsReachedWithin30Days: Scalars['Int']['output'];
    id: Scalars['String']['output'];
    organizationId?: Maybe<Scalars['String']['output']>;
    suggestionsSameDay: Scalars['Int']['output'];
    suggestionsWithin2Days: Scalars['Int']['output'];
    suggestionsWithin3Days: Scalars['Int']['output'];
    suggestionsWithin7Days: Scalars['Int']['output'];
    suggestionsWithin30Days: Scalars['Int']['output'];
    trailingTotalCompleted: Scalars['Int']['output'];
    trailingTotalGoalsReached: Scalars['Int']['output'];
    trailingTotalSuggestions: Scalars['Int']['output'];
    userId?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "OutreachCompletionMetrics" */
export type OutreachCompletionMetrics_Aggregate = {
    __typename?: 'OutreachCompletionMetrics_aggregate';
    aggregate?: Maybe<OutreachCompletionMetrics_Aggregate_Fields>;
    nodes: Array<OutreachCompletionMetrics>;
};

/** aggregate fields of "OutreachCompletionMetrics" */
export type OutreachCompletionMetrics_Aggregate_Fields = {
    __typename?: 'OutreachCompletionMetrics_aggregate_fields';
    avg?: Maybe<OutreachCompletionMetrics_Avg_Fields>;
    count: Scalars['Int']['output'];
    max?: Maybe<OutreachCompletionMetrics_Max_Fields>;
    min?: Maybe<OutreachCompletionMetrics_Min_Fields>;
    stddev?: Maybe<OutreachCompletionMetrics_Stddev_Fields>;
    stddev_pop?: Maybe<OutreachCompletionMetrics_Stddev_Pop_Fields>;
    stddev_samp?: Maybe<OutreachCompletionMetrics_Stddev_Samp_Fields>;
    sum?: Maybe<OutreachCompletionMetrics_Sum_Fields>;
    var_pop?: Maybe<OutreachCompletionMetrics_Var_Pop_Fields>;
    var_samp?: Maybe<OutreachCompletionMetrics_Var_Samp_Fields>;
    variance?: Maybe<OutreachCompletionMetrics_Variance_Fields>;
};

/** aggregate fields of "OutreachCompletionMetrics" */
export type OutreachCompletionMetrics_Aggregate_FieldsCountArgs = {
    columns?: InputMaybe<Array<OutreachCompletionMetrics_Select_Column>>;
    distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type OutreachCompletionMetrics_Avg_Fields = {
    __typename?: 'OutreachCompletionMetrics_avg_fields';
    completedSameDay?: Maybe<Scalars['Float']['output']>;
    completedWithin2Days?: Maybe<Scalars['Float']['output']>;
    completedWithin3Days?: Maybe<Scalars['Float']['output']>;
    completedWithin7Days?: Maybe<Scalars['Float']['output']>;
    completedWithin30Days?: Maybe<Scalars['Float']['output']>;
    goalsReachedSameDay?: Maybe<Scalars['Float']['output']>;
    goalsReachedWithin2Days?: Maybe<Scalars['Float']['output']>;
    goalsReachedWithin3Days?: Maybe<Scalars['Float']['output']>;
    goalsReachedWithin7Days?: Maybe<Scalars['Float']['output']>;
    goalsReachedWithin30Days?: Maybe<Scalars['Float']['output']>;
    suggestionsSameDay?: Maybe<Scalars['Float']['output']>;
    suggestionsWithin2Days?: Maybe<Scalars['Float']['output']>;
    suggestionsWithin3Days?: Maybe<Scalars['Float']['output']>;
    suggestionsWithin7Days?: Maybe<Scalars['Float']['output']>;
    suggestionsWithin30Days?: Maybe<Scalars['Float']['output']>;
    trailingTotalCompleted?: Maybe<Scalars['Float']['output']>;
    trailingTotalGoalsReached?: Maybe<Scalars['Float']['output']>;
    trailingTotalSuggestions?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "OutreachCompletionMetrics". All fields are combined with a logical 'AND'. */
export type OutreachCompletionMetrics_Bool_Exp = {
    _and?: InputMaybe<Array<OutreachCompletionMetrics_Bool_Exp>>;
    _not?: InputMaybe<OutreachCompletionMetrics_Bool_Exp>;
    _or?: InputMaybe<Array<OutreachCompletionMetrics_Bool_Exp>>;
    completedSameDay?: InputMaybe<Int_Comparison_Exp>;
    completedWithin2Days?: InputMaybe<Int_Comparison_Exp>;
    completedWithin3Days?: InputMaybe<Int_Comparison_Exp>;
    completedWithin7Days?: InputMaybe<Int_Comparison_Exp>;
    completedWithin30Days?: InputMaybe<Int_Comparison_Exp>;
    date?: InputMaybe<Timestamp_Comparison_Exp>;
    goalsReachedSameDay?: InputMaybe<Int_Comparison_Exp>;
    goalsReachedWithin2Days?: InputMaybe<Int_Comparison_Exp>;
    goalsReachedWithin3Days?: InputMaybe<Int_Comparison_Exp>;
    goalsReachedWithin7Days?: InputMaybe<Int_Comparison_Exp>;
    goalsReachedWithin30Days?: InputMaybe<Int_Comparison_Exp>;
    id?: InputMaybe<String_Comparison_Exp>;
    organizationId?: InputMaybe<String_Comparison_Exp>;
    suggestionsSameDay?: InputMaybe<Int_Comparison_Exp>;
    suggestionsWithin2Days?: InputMaybe<Int_Comparison_Exp>;
    suggestionsWithin3Days?: InputMaybe<Int_Comparison_Exp>;
    suggestionsWithin7Days?: InputMaybe<Int_Comparison_Exp>;
    suggestionsWithin30Days?: InputMaybe<Int_Comparison_Exp>;
    trailingTotalCompleted?: InputMaybe<Int_Comparison_Exp>;
    trailingTotalGoalsReached?: InputMaybe<Int_Comparison_Exp>;
    trailingTotalSuggestions?: InputMaybe<Int_Comparison_Exp>;
    userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "OutreachCompletionMetrics" */
export enum OutreachCompletionMetrics_Constraint {
    /** unique or primary key constraint on columns "organizationId", "userId", "date" */
    OutreachCompletionMetricsDateUserIdOrganizationIdKey = 'OutreachCompletionMetrics_date_userId_organizationId_key',
    /** unique or primary key constraint on columns "id" */
    OutreachCompletionMetricsPkey = 'OutreachCompletionMetrics_pkey',
    /** unique or primary key constraint on columns "date" */
    UniqueDateNullUserOrg = 'unique_date_null_user_org',
}

/** input type for incrementing numeric columns in table "OutreachCompletionMetrics" */
export type OutreachCompletionMetrics_Inc_Input = {
    completedSameDay?: InputMaybe<Scalars['Int']['input']>;
    completedWithin2Days?: InputMaybe<Scalars['Int']['input']>;
    completedWithin3Days?: InputMaybe<Scalars['Int']['input']>;
    completedWithin7Days?: InputMaybe<Scalars['Int']['input']>;
    completedWithin30Days?: InputMaybe<Scalars['Int']['input']>;
    goalsReachedSameDay?: InputMaybe<Scalars['Int']['input']>;
    goalsReachedWithin2Days?: InputMaybe<Scalars['Int']['input']>;
    goalsReachedWithin3Days?: InputMaybe<Scalars['Int']['input']>;
    goalsReachedWithin7Days?: InputMaybe<Scalars['Int']['input']>;
    goalsReachedWithin30Days?: InputMaybe<Scalars['Int']['input']>;
    suggestionsSameDay?: InputMaybe<Scalars['Int']['input']>;
    suggestionsWithin2Days?: InputMaybe<Scalars['Int']['input']>;
    suggestionsWithin3Days?: InputMaybe<Scalars['Int']['input']>;
    suggestionsWithin7Days?: InputMaybe<Scalars['Int']['input']>;
    suggestionsWithin30Days?: InputMaybe<Scalars['Int']['input']>;
    trailingTotalCompleted?: InputMaybe<Scalars['Int']['input']>;
    trailingTotalGoalsReached?: InputMaybe<Scalars['Int']['input']>;
    trailingTotalSuggestions?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "OutreachCompletionMetrics" */
export type OutreachCompletionMetrics_Insert_Input = {
    completedSameDay?: InputMaybe<Scalars['Int']['input']>;
    completedWithin2Days?: InputMaybe<Scalars['Int']['input']>;
    completedWithin3Days?: InputMaybe<Scalars['Int']['input']>;
    completedWithin7Days?: InputMaybe<Scalars['Int']['input']>;
    completedWithin30Days?: InputMaybe<Scalars['Int']['input']>;
    date?: InputMaybe<Scalars['timestamp']['input']>;
    goalsReachedSameDay?: InputMaybe<Scalars['Int']['input']>;
    goalsReachedWithin2Days?: InputMaybe<Scalars['Int']['input']>;
    goalsReachedWithin3Days?: InputMaybe<Scalars['Int']['input']>;
    goalsReachedWithin7Days?: InputMaybe<Scalars['Int']['input']>;
    goalsReachedWithin30Days?: InputMaybe<Scalars['Int']['input']>;
    id?: InputMaybe<Scalars['String']['input']>;
    organizationId?: InputMaybe<Scalars['String']['input']>;
    suggestionsSameDay?: InputMaybe<Scalars['Int']['input']>;
    suggestionsWithin2Days?: InputMaybe<Scalars['Int']['input']>;
    suggestionsWithin3Days?: InputMaybe<Scalars['Int']['input']>;
    suggestionsWithin7Days?: InputMaybe<Scalars['Int']['input']>;
    suggestionsWithin30Days?: InputMaybe<Scalars['Int']['input']>;
    trailingTotalCompleted?: InputMaybe<Scalars['Int']['input']>;
    trailingTotalGoalsReached?: InputMaybe<Scalars['Int']['input']>;
    trailingTotalSuggestions?: InputMaybe<Scalars['Int']['input']>;
    userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type OutreachCompletionMetrics_Max_Fields = {
    __typename?: 'OutreachCompletionMetrics_max_fields';
    completedSameDay?: Maybe<Scalars['Int']['output']>;
    completedWithin2Days?: Maybe<Scalars['Int']['output']>;
    completedWithin3Days?: Maybe<Scalars['Int']['output']>;
    completedWithin7Days?: Maybe<Scalars['Int']['output']>;
    completedWithin30Days?: Maybe<Scalars['Int']['output']>;
    date?: Maybe<Scalars['timestamp']['output']>;
    goalsReachedSameDay?: Maybe<Scalars['Int']['output']>;
    goalsReachedWithin2Days?: Maybe<Scalars['Int']['output']>;
    goalsReachedWithin3Days?: Maybe<Scalars['Int']['output']>;
    goalsReachedWithin7Days?: Maybe<Scalars['Int']['output']>;
    goalsReachedWithin30Days?: Maybe<Scalars['Int']['output']>;
    id?: Maybe<Scalars['String']['output']>;
    organizationId?: Maybe<Scalars['String']['output']>;
    suggestionsSameDay?: Maybe<Scalars['Int']['output']>;
    suggestionsWithin2Days?: Maybe<Scalars['Int']['output']>;
    suggestionsWithin3Days?: Maybe<Scalars['Int']['output']>;
    suggestionsWithin7Days?: Maybe<Scalars['Int']['output']>;
    suggestionsWithin30Days?: Maybe<Scalars['Int']['output']>;
    trailingTotalCompleted?: Maybe<Scalars['Int']['output']>;
    trailingTotalGoalsReached?: Maybe<Scalars['Int']['output']>;
    trailingTotalSuggestions?: Maybe<Scalars['Int']['output']>;
    userId?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type OutreachCompletionMetrics_Min_Fields = {
    __typename?: 'OutreachCompletionMetrics_min_fields';
    completedSameDay?: Maybe<Scalars['Int']['output']>;
    completedWithin2Days?: Maybe<Scalars['Int']['output']>;
    completedWithin3Days?: Maybe<Scalars['Int']['output']>;
    completedWithin7Days?: Maybe<Scalars['Int']['output']>;
    completedWithin30Days?: Maybe<Scalars['Int']['output']>;
    date?: Maybe<Scalars['timestamp']['output']>;
    goalsReachedSameDay?: Maybe<Scalars['Int']['output']>;
    goalsReachedWithin2Days?: Maybe<Scalars['Int']['output']>;
    goalsReachedWithin3Days?: Maybe<Scalars['Int']['output']>;
    goalsReachedWithin7Days?: Maybe<Scalars['Int']['output']>;
    goalsReachedWithin30Days?: Maybe<Scalars['Int']['output']>;
    id?: Maybe<Scalars['String']['output']>;
    organizationId?: Maybe<Scalars['String']['output']>;
    suggestionsSameDay?: Maybe<Scalars['Int']['output']>;
    suggestionsWithin2Days?: Maybe<Scalars['Int']['output']>;
    suggestionsWithin3Days?: Maybe<Scalars['Int']['output']>;
    suggestionsWithin7Days?: Maybe<Scalars['Int']['output']>;
    suggestionsWithin30Days?: Maybe<Scalars['Int']['output']>;
    trailingTotalCompleted?: Maybe<Scalars['Int']['output']>;
    trailingTotalGoalsReached?: Maybe<Scalars['Int']['output']>;
    trailingTotalSuggestions?: Maybe<Scalars['Int']['output']>;
    userId?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "OutreachCompletionMetrics" */
export type OutreachCompletionMetrics_Mutation_Response = {
    __typename?: 'OutreachCompletionMetrics_mutation_response';
    /** number of rows affected by the mutation */
    affected_rows: Scalars['Int']['output'];
    /** data from the rows affected by the mutation */
    returning: Array<OutreachCompletionMetrics>;
};

/** on_conflict condition type for table "OutreachCompletionMetrics" */
export type OutreachCompletionMetrics_On_Conflict = {
    constraint: OutreachCompletionMetrics_Constraint;
    update_columns?: Array<OutreachCompletionMetrics_Update_Column>;
    where?: InputMaybe<OutreachCompletionMetrics_Bool_Exp>;
};

/** Ordering options when selecting data from "OutreachCompletionMetrics". */
export type OutreachCompletionMetrics_Order_By = {
    completedSameDay?: InputMaybe<Order_By>;
    completedWithin2Days?: InputMaybe<Order_By>;
    completedWithin3Days?: InputMaybe<Order_By>;
    completedWithin7Days?: InputMaybe<Order_By>;
    completedWithin30Days?: InputMaybe<Order_By>;
    date?: InputMaybe<Order_By>;
    goalsReachedSameDay?: InputMaybe<Order_By>;
    goalsReachedWithin2Days?: InputMaybe<Order_By>;
    goalsReachedWithin3Days?: InputMaybe<Order_By>;
    goalsReachedWithin7Days?: InputMaybe<Order_By>;
    goalsReachedWithin30Days?: InputMaybe<Order_By>;
    id?: InputMaybe<Order_By>;
    organizationId?: InputMaybe<Order_By>;
    suggestionsSameDay?: InputMaybe<Order_By>;
    suggestionsWithin2Days?: InputMaybe<Order_By>;
    suggestionsWithin3Days?: InputMaybe<Order_By>;
    suggestionsWithin7Days?: InputMaybe<Order_By>;
    suggestionsWithin30Days?: InputMaybe<Order_By>;
    trailingTotalCompleted?: InputMaybe<Order_By>;
    trailingTotalGoalsReached?: InputMaybe<Order_By>;
    trailingTotalSuggestions?: InputMaybe<Order_By>;
    userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: OutreachCompletionMetrics */
export type OutreachCompletionMetrics_Pk_Columns_Input = {
    id: Scalars['String']['input'];
};

/** select columns of table "OutreachCompletionMetrics" */
export enum OutreachCompletionMetrics_Select_Column {
    /** column name */
    CompletedSameDay = 'completedSameDay',
    /** column name */
    CompletedWithin2Days = 'completedWithin2Days',
    /** column name */
    CompletedWithin3Days = 'completedWithin3Days',
    /** column name */
    CompletedWithin7Days = 'completedWithin7Days',
    /** column name */
    CompletedWithin30Days = 'completedWithin30Days',
    /** column name */
    Date = 'date',
    /** column name */
    GoalsReachedSameDay = 'goalsReachedSameDay',
    /** column name */
    GoalsReachedWithin2Days = 'goalsReachedWithin2Days',
    /** column name */
    GoalsReachedWithin3Days = 'goalsReachedWithin3Days',
    /** column name */
    GoalsReachedWithin7Days = 'goalsReachedWithin7Days',
    /** column name */
    GoalsReachedWithin30Days = 'goalsReachedWithin30Days',
    /** column name */
    Id = 'id',
    /** column name */
    OrganizationId = 'organizationId',
    /** column name */
    SuggestionsSameDay = 'suggestionsSameDay',
    /** column name */
    SuggestionsWithin2Days = 'suggestionsWithin2Days',
    /** column name */
    SuggestionsWithin3Days = 'suggestionsWithin3Days',
    /** column name */
    SuggestionsWithin7Days = 'suggestionsWithin7Days',
    /** column name */
    SuggestionsWithin30Days = 'suggestionsWithin30Days',
    /** column name */
    TrailingTotalCompleted = 'trailingTotalCompleted',
    /** column name */
    TrailingTotalGoalsReached = 'trailingTotalGoalsReached',
    /** column name */
    TrailingTotalSuggestions = 'trailingTotalSuggestions',
    /** column name */
    UserId = 'userId',
}

/** input type for updating data in table "OutreachCompletionMetrics" */
export type OutreachCompletionMetrics_Set_Input = {
    completedSameDay?: InputMaybe<Scalars['Int']['input']>;
    completedWithin2Days?: InputMaybe<Scalars['Int']['input']>;
    completedWithin3Days?: InputMaybe<Scalars['Int']['input']>;
    completedWithin7Days?: InputMaybe<Scalars['Int']['input']>;
    completedWithin30Days?: InputMaybe<Scalars['Int']['input']>;
    date?: InputMaybe<Scalars['timestamp']['input']>;
    goalsReachedSameDay?: InputMaybe<Scalars['Int']['input']>;
    goalsReachedWithin2Days?: InputMaybe<Scalars['Int']['input']>;
    goalsReachedWithin3Days?: InputMaybe<Scalars['Int']['input']>;
    goalsReachedWithin7Days?: InputMaybe<Scalars['Int']['input']>;
    goalsReachedWithin30Days?: InputMaybe<Scalars['Int']['input']>;
    id?: InputMaybe<Scalars['String']['input']>;
    organizationId?: InputMaybe<Scalars['String']['input']>;
    suggestionsSameDay?: InputMaybe<Scalars['Int']['input']>;
    suggestionsWithin2Days?: InputMaybe<Scalars['Int']['input']>;
    suggestionsWithin3Days?: InputMaybe<Scalars['Int']['input']>;
    suggestionsWithin7Days?: InputMaybe<Scalars['Int']['input']>;
    suggestionsWithin30Days?: InputMaybe<Scalars['Int']['input']>;
    trailingTotalCompleted?: InputMaybe<Scalars['Int']['input']>;
    trailingTotalGoalsReached?: InputMaybe<Scalars['Int']['input']>;
    trailingTotalSuggestions?: InputMaybe<Scalars['Int']['input']>;
    userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type OutreachCompletionMetrics_Stddev_Fields = {
    __typename?: 'OutreachCompletionMetrics_stddev_fields';
    completedSameDay?: Maybe<Scalars['Float']['output']>;
    completedWithin2Days?: Maybe<Scalars['Float']['output']>;
    completedWithin3Days?: Maybe<Scalars['Float']['output']>;
    completedWithin7Days?: Maybe<Scalars['Float']['output']>;
    completedWithin30Days?: Maybe<Scalars['Float']['output']>;
    goalsReachedSameDay?: Maybe<Scalars['Float']['output']>;
    goalsReachedWithin2Days?: Maybe<Scalars['Float']['output']>;
    goalsReachedWithin3Days?: Maybe<Scalars['Float']['output']>;
    goalsReachedWithin7Days?: Maybe<Scalars['Float']['output']>;
    goalsReachedWithin30Days?: Maybe<Scalars['Float']['output']>;
    suggestionsSameDay?: Maybe<Scalars['Float']['output']>;
    suggestionsWithin2Days?: Maybe<Scalars['Float']['output']>;
    suggestionsWithin3Days?: Maybe<Scalars['Float']['output']>;
    suggestionsWithin7Days?: Maybe<Scalars['Float']['output']>;
    suggestionsWithin30Days?: Maybe<Scalars['Float']['output']>;
    trailingTotalCompleted?: Maybe<Scalars['Float']['output']>;
    trailingTotalGoalsReached?: Maybe<Scalars['Float']['output']>;
    trailingTotalSuggestions?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type OutreachCompletionMetrics_Stddev_Pop_Fields = {
    __typename?: 'OutreachCompletionMetrics_stddev_pop_fields';
    completedSameDay?: Maybe<Scalars['Float']['output']>;
    completedWithin2Days?: Maybe<Scalars['Float']['output']>;
    completedWithin3Days?: Maybe<Scalars['Float']['output']>;
    completedWithin7Days?: Maybe<Scalars['Float']['output']>;
    completedWithin30Days?: Maybe<Scalars['Float']['output']>;
    goalsReachedSameDay?: Maybe<Scalars['Float']['output']>;
    goalsReachedWithin2Days?: Maybe<Scalars['Float']['output']>;
    goalsReachedWithin3Days?: Maybe<Scalars['Float']['output']>;
    goalsReachedWithin7Days?: Maybe<Scalars['Float']['output']>;
    goalsReachedWithin30Days?: Maybe<Scalars['Float']['output']>;
    suggestionsSameDay?: Maybe<Scalars['Float']['output']>;
    suggestionsWithin2Days?: Maybe<Scalars['Float']['output']>;
    suggestionsWithin3Days?: Maybe<Scalars['Float']['output']>;
    suggestionsWithin7Days?: Maybe<Scalars['Float']['output']>;
    suggestionsWithin30Days?: Maybe<Scalars['Float']['output']>;
    trailingTotalCompleted?: Maybe<Scalars['Float']['output']>;
    trailingTotalGoalsReached?: Maybe<Scalars['Float']['output']>;
    trailingTotalSuggestions?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type OutreachCompletionMetrics_Stddev_Samp_Fields = {
    __typename?: 'OutreachCompletionMetrics_stddev_samp_fields';
    completedSameDay?: Maybe<Scalars['Float']['output']>;
    completedWithin2Days?: Maybe<Scalars['Float']['output']>;
    completedWithin3Days?: Maybe<Scalars['Float']['output']>;
    completedWithin7Days?: Maybe<Scalars['Float']['output']>;
    completedWithin30Days?: Maybe<Scalars['Float']['output']>;
    goalsReachedSameDay?: Maybe<Scalars['Float']['output']>;
    goalsReachedWithin2Days?: Maybe<Scalars['Float']['output']>;
    goalsReachedWithin3Days?: Maybe<Scalars['Float']['output']>;
    goalsReachedWithin7Days?: Maybe<Scalars['Float']['output']>;
    goalsReachedWithin30Days?: Maybe<Scalars['Float']['output']>;
    suggestionsSameDay?: Maybe<Scalars['Float']['output']>;
    suggestionsWithin2Days?: Maybe<Scalars['Float']['output']>;
    suggestionsWithin3Days?: Maybe<Scalars['Float']['output']>;
    suggestionsWithin7Days?: Maybe<Scalars['Float']['output']>;
    suggestionsWithin30Days?: Maybe<Scalars['Float']['output']>;
    trailingTotalCompleted?: Maybe<Scalars['Float']['output']>;
    trailingTotalGoalsReached?: Maybe<Scalars['Float']['output']>;
    trailingTotalSuggestions?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "OutreachCompletionMetrics" */
export type OutreachCompletionMetrics_Stream_Cursor_Input = {
    /** Stream column input with initial value */
    initial_value: OutreachCompletionMetrics_Stream_Cursor_Value_Input;
    /** cursor ordering */
    ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type OutreachCompletionMetrics_Stream_Cursor_Value_Input = {
    completedSameDay?: InputMaybe<Scalars['Int']['input']>;
    completedWithin2Days?: InputMaybe<Scalars['Int']['input']>;
    completedWithin3Days?: InputMaybe<Scalars['Int']['input']>;
    completedWithin7Days?: InputMaybe<Scalars['Int']['input']>;
    completedWithin30Days?: InputMaybe<Scalars['Int']['input']>;
    date?: InputMaybe<Scalars['timestamp']['input']>;
    goalsReachedSameDay?: InputMaybe<Scalars['Int']['input']>;
    goalsReachedWithin2Days?: InputMaybe<Scalars['Int']['input']>;
    goalsReachedWithin3Days?: InputMaybe<Scalars['Int']['input']>;
    goalsReachedWithin7Days?: InputMaybe<Scalars['Int']['input']>;
    goalsReachedWithin30Days?: InputMaybe<Scalars['Int']['input']>;
    id?: InputMaybe<Scalars['String']['input']>;
    organizationId?: InputMaybe<Scalars['String']['input']>;
    suggestionsSameDay?: InputMaybe<Scalars['Int']['input']>;
    suggestionsWithin2Days?: InputMaybe<Scalars['Int']['input']>;
    suggestionsWithin3Days?: InputMaybe<Scalars['Int']['input']>;
    suggestionsWithin7Days?: InputMaybe<Scalars['Int']['input']>;
    suggestionsWithin30Days?: InputMaybe<Scalars['Int']['input']>;
    trailingTotalCompleted?: InputMaybe<Scalars['Int']['input']>;
    trailingTotalGoalsReached?: InputMaybe<Scalars['Int']['input']>;
    trailingTotalSuggestions?: InputMaybe<Scalars['Int']['input']>;
    userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type OutreachCompletionMetrics_Sum_Fields = {
    __typename?: 'OutreachCompletionMetrics_sum_fields';
    completedSameDay?: Maybe<Scalars['Int']['output']>;
    completedWithin2Days?: Maybe<Scalars['Int']['output']>;
    completedWithin3Days?: Maybe<Scalars['Int']['output']>;
    completedWithin7Days?: Maybe<Scalars['Int']['output']>;
    completedWithin30Days?: Maybe<Scalars['Int']['output']>;
    goalsReachedSameDay?: Maybe<Scalars['Int']['output']>;
    goalsReachedWithin2Days?: Maybe<Scalars['Int']['output']>;
    goalsReachedWithin3Days?: Maybe<Scalars['Int']['output']>;
    goalsReachedWithin7Days?: Maybe<Scalars['Int']['output']>;
    goalsReachedWithin30Days?: Maybe<Scalars['Int']['output']>;
    suggestionsSameDay?: Maybe<Scalars['Int']['output']>;
    suggestionsWithin2Days?: Maybe<Scalars['Int']['output']>;
    suggestionsWithin3Days?: Maybe<Scalars['Int']['output']>;
    suggestionsWithin7Days?: Maybe<Scalars['Int']['output']>;
    suggestionsWithin30Days?: Maybe<Scalars['Int']['output']>;
    trailingTotalCompleted?: Maybe<Scalars['Int']['output']>;
    trailingTotalGoalsReached?: Maybe<Scalars['Int']['output']>;
    trailingTotalSuggestions?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "OutreachCompletionMetrics" */
export enum OutreachCompletionMetrics_Update_Column {
    /** column name */
    CompletedSameDay = 'completedSameDay',
    /** column name */
    CompletedWithin2Days = 'completedWithin2Days',
    /** column name */
    CompletedWithin3Days = 'completedWithin3Days',
    /** column name */
    CompletedWithin7Days = 'completedWithin7Days',
    /** column name */
    CompletedWithin30Days = 'completedWithin30Days',
    /** column name */
    Date = 'date',
    /** column name */
    GoalsReachedSameDay = 'goalsReachedSameDay',
    /** column name */
    GoalsReachedWithin2Days = 'goalsReachedWithin2Days',
    /** column name */
    GoalsReachedWithin3Days = 'goalsReachedWithin3Days',
    /** column name */
    GoalsReachedWithin7Days = 'goalsReachedWithin7Days',
    /** column name */
    GoalsReachedWithin30Days = 'goalsReachedWithin30Days',
    /** column name */
    Id = 'id',
    /** column name */
    OrganizationId = 'organizationId',
    /** column name */
    SuggestionsSameDay = 'suggestionsSameDay',
    /** column name */
    SuggestionsWithin2Days = 'suggestionsWithin2Days',
    /** column name */
    SuggestionsWithin3Days = 'suggestionsWithin3Days',
    /** column name */
    SuggestionsWithin7Days = 'suggestionsWithin7Days',
    /** column name */
    SuggestionsWithin30Days = 'suggestionsWithin30Days',
    /** column name */
    TrailingTotalCompleted = 'trailingTotalCompleted',
    /** column name */
    TrailingTotalGoalsReached = 'trailingTotalGoalsReached',
    /** column name */
    TrailingTotalSuggestions = 'trailingTotalSuggestions',
    /** column name */
    UserId = 'userId',
}

export type OutreachCompletionMetrics_Updates = {
    /** increments the numeric columns with given value of the filtered values */
    _inc?: InputMaybe<OutreachCompletionMetrics_Inc_Input>;
    /** sets the columns of the filtered rows to the given values */
    _set?: InputMaybe<OutreachCompletionMetrics_Set_Input>;
    /** filter the rows which have to be updated */
    where: OutreachCompletionMetrics_Bool_Exp;
};

/** aggregate var_pop on columns */
export type OutreachCompletionMetrics_Var_Pop_Fields = {
    __typename?: 'OutreachCompletionMetrics_var_pop_fields';
    completedSameDay?: Maybe<Scalars['Float']['output']>;
    completedWithin2Days?: Maybe<Scalars['Float']['output']>;
    completedWithin3Days?: Maybe<Scalars['Float']['output']>;
    completedWithin7Days?: Maybe<Scalars['Float']['output']>;
    completedWithin30Days?: Maybe<Scalars['Float']['output']>;
    goalsReachedSameDay?: Maybe<Scalars['Float']['output']>;
    goalsReachedWithin2Days?: Maybe<Scalars['Float']['output']>;
    goalsReachedWithin3Days?: Maybe<Scalars['Float']['output']>;
    goalsReachedWithin7Days?: Maybe<Scalars['Float']['output']>;
    goalsReachedWithin30Days?: Maybe<Scalars['Float']['output']>;
    suggestionsSameDay?: Maybe<Scalars['Float']['output']>;
    suggestionsWithin2Days?: Maybe<Scalars['Float']['output']>;
    suggestionsWithin3Days?: Maybe<Scalars['Float']['output']>;
    suggestionsWithin7Days?: Maybe<Scalars['Float']['output']>;
    suggestionsWithin30Days?: Maybe<Scalars['Float']['output']>;
    trailingTotalCompleted?: Maybe<Scalars['Float']['output']>;
    trailingTotalGoalsReached?: Maybe<Scalars['Float']['output']>;
    trailingTotalSuggestions?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type OutreachCompletionMetrics_Var_Samp_Fields = {
    __typename?: 'OutreachCompletionMetrics_var_samp_fields';
    completedSameDay?: Maybe<Scalars['Float']['output']>;
    completedWithin2Days?: Maybe<Scalars['Float']['output']>;
    completedWithin3Days?: Maybe<Scalars['Float']['output']>;
    completedWithin7Days?: Maybe<Scalars['Float']['output']>;
    completedWithin30Days?: Maybe<Scalars['Float']['output']>;
    goalsReachedSameDay?: Maybe<Scalars['Float']['output']>;
    goalsReachedWithin2Days?: Maybe<Scalars['Float']['output']>;
    goalsReachedWithin3Days?: Maybe<Scalars['Float']['output']>;
    goalsReachedWithin7Days?: Maybe<Scalars['Float']['output']>;
    goalsReachedWithin30Days?: Maybe<Scalars['Float']['output']>;
    suggestionsSameDay?: Maybe<Scalars['Float']['output']>;
    suggestionsWithin2Days?: Maybe<Scalars['Float']['output']>;
    suggestionsWithin3Days?: Maybe<Scalars['Float']['output']>;
    suggestionsWithin7Days?: Maybe<Scalars['Float']['output']>;
    suggestionsWithin30Days?: Maybe<Scalars['Float']['output']>;
    trailingTotalCompleted?: Maybe<Scalars['Float']['output']>;
    trailingTotalGoalsReached?: Maybe<Scalars['Float']['output']>;
    trailingTotalSuggestions?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type OutreachCompletionMetrics_Variance_Fields = {
    __typename?: 'OutreachCompletionMetrics_variance_fields';
    completedSameDay?: Maybe<Scalars['Float']['output']>;
    completedWithin2Days?: Maybe<Scalars['Float']['output']>;
    completedWithin3Days?: Maybe<Scalars['Float']['output']>;
    completedWithin7Days?: Maybe<Scalars['Float']['output']>;
    completedWithin30Days?: Maybe<Scalars['Float']['output']>;
    goalsReachedSameDay?: Maybe<Scalars['Float']['output']>;
    goalsReachedWithin2Days?: Maybe<Scalars['Float']['output']>;
    goalsReachedWithin3Days?: Maybe<Scalars['Float']['output']>;
    goalsReachedWithin7Days?: Maybe<Scalars['Float']['output']>;
    goalsReachedWithin30Days?: Maybe<Scalars['Float']['output']>;
    suggestionsSameDay?: Maybe<Scalars['Float']['output']>;
    suggestionsWithin2Days?: Maybe<Scalars['Float']['output']>;
    suggestionsWithin3Days?: Maybe<Scalars['Float']['output']>;
    suggestionsWithin7Days?: Maybe<Scalars['Float']['output']>;
    suggestionsWithin30Days?: Maybe<Scalars['Float']['output']>;
    trailingTotalCompleted?: Maybe<Scalars['Float']['output']>;
    trailingTotalGoalsReached?: Maybe<Scalars['Float']['output']>;
    trailingTotalSuggestions?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "OutreachSuggestions" */
export type OutreachSuggestions = {
    __typename?: 'OutreachSuggestions';
    /** An object relationship */
    ConversationMetaData?: Maybe<ConversationMetaData>;
    /** An object relationship */
    Message: Messages;
    accountId?: Maybe<Scalars['String']['output']>;
    accountName: Scalars['String']['output'];
    accountWebsite?: Maybe<Scalars['String']['output']>;
    campaignId?: Maybe<Scalars['String']['output']>;
    campaignName?: Maybe<Scalars['String']['output']>;
    campaignType?: Maybe<Scalars['String']['output']>;
    contactLocation?: Maybe<Scalars['String']['output']>;
    conversationMetaDataId?: Maybe<Scalars['String']['output']>;
    createdAt: Scalars['timestamp']['output'];
    email?: Maybe<Scalars['String']['output']>;
    goal?: Maybe<Scalars['String']['output']>;
    id: Scalars['String']['output'];
    industry?: Maybe<Scalars['String']['output']>;
    isGenerating: Scalars['Boolean']['output'];
    linkedinUrl?: Maybe<Scalars['String']['output']>;
    messageId: Scalars['String']['output'];
    name: Scalars['String']['output'];
    numberOfEmployees?: Maybe<Scalars['String']['output']>;
    organizationId: Scalars['String']['output'];
    personId?: Maybe<Scalars['String']['output']>;
    prospectId?: Maybe<Scalars['String']['output']>;
    scheduledOn?: Maybe<Scalars['timestamp']['output']>;
    scheduledOnMotivation?: Maybe<Scalars['String']['output']>;
    score: Scalars['Int']['output'];
    title: Scalars['String']['output'];
    unread: Scalars['Boolean']['output'];
    updatedAt: Scalars['timestamp']['output'];
    userId: Scalars['String']['output'];
};

/** aggregated selection of "OutreachSuggestions" */
export type OutreachSuggestions_Aggregate = {
    __typename?: 'OutreachSuggestions_aggregate';
    aggregate?: Maybe<OutreachSuggestions_Aggregate_Fields>;
    nodes: Array<OutreachSuggestions>;
};

export type OutreachSuggestions_Aggregate_Bool_Exp = {
    bool_and?: InputMaybe<OutreachSuggestions_Aggregate_Bool_Exp_Bool_And>;
    bool_or?: InputMaybe<OutreachSuggestions_Aggregate_Bool_Exp_Bool_Or>;
    count?: InputMaybe<OutreachSuggestions_Aggregate_Bool_Exp_Count>;
};

export type OutreachSuggestions_Aggregate_Bool_Exp_Bool_And = {
    arguments: OutreachSuggestions_Select_Column_OutreachSuggestions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
    distinct?: InputMaybe<Scalars['Boolean']['input']>;
    filter?: InputMaybe<OutreachSuggestions_Bool_Exp>;
    predicate: Boolean_Comparison_Exp;
};

export type OutreachSuggestions_Aggregate_Bool_Exp_Bool_Or = {
    arguments: OutreachSuggestions_Select_Column_OutreachSuggestions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
    distinct?: InputMaybe<Scalars['Boolean']['input']>;
    filter?: InputMaybe<OutreachSuggestions_Bool_Exp>;
    predicate: Boolean_Comparison_Exp;
};

export type OutreachSuggestions_Aggregate_Bool_Exp_Count = {
    arguments?: InputMaybe<Array<OutreachSuggestions_Select_Column>>;
    distinct?: InputMaybe<Scalars['Boolean']['input']>;
    filter?: InputMaybe<OutreachSuggestions_Bool_Exp>;
    predicate: Int_Comparison_Exp;
};

/** aggregate fields of "OutreachSuggestions" */
export type OutreachSuggestions_Aggregate_Fields = {
    __typename?: 'OutreachSuggestions_aggregate_fields';
    avg?: Maybe<OutreachSuggestions_Avg_Fields>;
    count: Scalars['Int']['output'];
    max?: Maybe<OutreachSuggestions_Max_Fields>;
    min?: Maybe<OutreachSuggestions_Min_Fields>;
    stddev?: Maybe<OutreachSuggestions_Stddev_Fields>;
    stddev_pop?: Maybe<OutreachSuggestions_Stddev_Pop_Fields>;
    stddev_samp?: Maybe<OutreachSuggestions_Stddev_Samp_Fields>;
    sum?: Maybe<OutreachSuggestions_Sum_Fields>;
    var_pop?: Maybe<OutreachSuggestions_Var_Pop_Fields>;
    var_samp?: Maybe<OutreachSuggestions_Var_Samp_Fields>;
    variance?: Maybe<OutreachSuggestions_Variance_Fields>;
};

/** aggregate fields of "OutreachSuggestions" */
export type OutreachSuggestions_Aggregate_FieldsCountArgs = {
    columns?: InputMaybe<Array<OutreachSuggestions_Select_Column>>;
    distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "OutreachSuggestions" */
export type OutreachSuggestions_Aggregate_Order_By = {
    avg?: InputMaybe<OutreachSuggestions_Avg_Order_By>;
    count?: InputMaybe<Order_By>;
    max?: InputMaybe<OutreachSuggestions_Max_Order_By>;
    min?: InputMaybe<OutreachSuggestions_Min_Order_By>;
    stddev?: InputMaybe<OutreachSuggestions_Stddev_Order_By>;
    stddev_pop?: InputMaybe<OutreachSuggestions_Stddev_Pop_Order_By>;
    stddev_samp?: InputMaybe<OutreachSuggestions_Stddev_Samp_Order_By>;
    sum?: InputMaybe<OutreachSuggestions_Sum_Order_By>;
    var_pop?: InputMaybe<OutreachSuggestions_Var_Pop_Order_By>;
    var_samp?: InputMaybe<OutreachSuggestions_Var_Samp_Order_By>;
    variance?: InputMaybe<OutreachSuggestions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "OutreachSuggestions" */
export type OutreachSuggestions_Arr_Rel_Insert_Input = {
    data: Array<OutreachSuggestions_Insert_Input>;
    /** upsert condition */
    on_conflict?: InputMaybe<OutreachSuggestions_On_Conflict>;
};

/** aggregate avg on columns */
export type OutreachSuggestions_Avg_Fields = {
    __typename?: 'OutreachSuggestions_avg_fields';
    score?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "OutreachSuggestions" */
export type OutreachSuggestions_Avg_Order_By = {
    score?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "OutreachSuggestions". All fields are combined with a logical 'AND'. */
export type OutreachSuggestions_Bool_Exp = {
    ConversationMetaData?: InputMaybe<ConversationMetaData_Bool_Exp>;
    Message?: InputMaybe<Messages_Bool_Exp>;
    _and?: InputMaybe<Array<OutreachSuggestions_Bool_Exp>>;
    _not?: InputMaybe<OutreachSuggestions_Bool_Exp>;
    _or?: InputMaybe<Array<OutreachSuggestions_Bool_Exp>>;
    accountId?: InputMaybe<String_Comparison_Exp>;
    accountName?: InputMaybe<String_Comparison_Exp>;
    accountWebsite?: InputMaybe<String_Comparison_Exp>;
    campaignId?: InputMaybe<String_Comparison_Exp>;
    campaignName?: InputMaybe<String_Comparison_Exp>;
    campaignType?: InputMaybe<String_Comparison_Exp>;
    contactLocation?: InputMaybe<String_Comparison_Exp>;
    conversationMetaDataId?: InputMaybe<String_Comparison_Exp>;
    createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
    email?: InputMaybe<String_Comparison_Exp>;
    goal?: InputMaybe<String_Comparison_Exp>;
    id?: InputMaybe<String_Comparison_Exp>;
    industry?: InputMaybe<String_Comparison_Exp>;
    isGenerating?: InputMaybe<Boolean_Comparison_Exp>;
    linkedinUrl?: InputMaybe<String_Comparison_Exp>;
    messageId?: InputMaybe<String_Comparison_Exp>;
    name?: InputMaybe<String_Comparison_Exp>;
    numberOfEmployees?: InputMaybe<String_Comparison_Exp>;
    organizationId?: InputMaybe<String_Comparison_Exp>;
    personId?: InputMaybe<String_Comparison_Exp>;
    prospectId?: InputMaybe<String_Comparison_Exp>;
    scheduledOn?: InputMaybe<Timestamp_Comparison_Exp>;
    scheduledOnMotivation?: InputMaybe<String_Comparison_Exp>;
    score?: InputMaybe<Int_Comparison_Exp>;
    title?: InputMaybe<String_Comparison_Exp>;
    unread?: InputMaybe<Boolean_Comparison_Exp>;
    updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
    userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "OutreachSuggestions" */
export enum OutreachSuggestions_Constraint {
    /** unique or primary key constraint on columns "conversationMetaDataId" */
    OutreachSuggestionsConversationMetaDataIdKey = 'OutreachSuggestions_conversationMetaDataId_key',
    /** unique or primary key constraint on columns "messageId" */
    OutreachSuggestionsMessageIdKey = 'OutreachSuggestions_messageId_key',
    /** unique or primary key constraint on columns "id" */
    OutreachSuggestionsPkey = 'OutreachSuggestions_pkey',
}

/** input type for incrementing numeric columns in table "OutreachSuggestions" */
export type OutreachSuggestions_Inc_Input = {
    score?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "OutreachSuggestions" */
export type OutreachSuggestions_Insert_Input = {
    ConversationMetaData?: InputMaybe<ConversationMetaData_Obj_Rel_Insert_Input>;
    Message?: InputMaybe<Messages_Obj_Rel_Insert_Input>;
    accountId?: InputMaybe<Scalars['String']['input']>;
    accountName?: InputMaybe<Scalars['String']['input']>;
    accountWebsite?: InputMaybe<Scalars['String']['input']>;
    campaignId?: InputMaybe<Scalars['String']['input']>;
    campaignName?: InputMaybe<Scalars['String']['input']>;
    campaignType?: InputMaybe<Scalars['String']['input']>;
    contactLocation?: InputMaybe<Scalars['String']['input']>;
    conversationMetaDataId?: InputMaybe<Scalars['String']['input']>;
    createdAt?: InputMaybe<Scalars['timestamp']['input']>;
    email?: InputMaybe<Scalars['String']['input']>;
    goal?: InputMaybe<Scalars['String']['input']>;
    id?: InputMaybe<Scalars['String']['input']>;
    industry?: InputMaybe<Scalars['String']['input']>;
    isGenerating?: InputMaybe<Scalars['Boolean']['input']>;
    linkedinUrl?: InputMaybe<Scalars['String']['input']>;
    messageId?: InputMaybe<Scalars['String']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
    numberOfEmployees?: InputMaybe<Scalars['String']['input']>;
    organizationId?: InputMaybe<Scalars['String']['input']>;
    personId?: InputMaybe<Scalars['String']['input']>;
    prospectId?: InputMaybe<Scalars['String']['input']>;
    scheduledOn?: InputMaybe<Scalars['timestamp']['input']>;
    scheduledOnMotivation?: InputMaybe<Scalars['String']['input']>;
    score?: InputMaybe<Scalars['Int']['input']>;
    title?: InputMaybe<Scalars['String']['input']>;
    unread?: InputMaybe<Scalars['Boolean']['input']>;
    updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
    userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type OutreachSuggestions_Max_Fields = {
    __typename?: 'OutreachSuggestions_max_fields';
    accountId?: Maybe<Scalars['String']['output']>;
    accountName?: Maybe<Scalars['String']['output']>;
    accountWebsite?: Maybe<Scalars['String']['output']>;
    campaignId?: Maybe<Scalars['String']['output']>;
    campaignName?: Maybe<Scalars['String']['output']>;
    campaignType?: Maybe<Scalars['String']['output']>;
    contactLocation?: Maybe<Scalars['String']['output']>;
    conversationMetaDataId?: Maybe<Scalars['String']['output']>;
    createdAt?: Maybe<Scalars['timestamp']['output']>;
    email?: Maybe<Scalars['String']['output']>;
    goal?: Maybe<Scalars['String']['output']>;
    id?: Maybe<Scalars['String']['output']>;
    industry?: Maybe<Scalars['String']['output']>;
    linkedinUrl?: Maybe<Scalars['String']['output']>;
    messageId?: Maybe<Scalars['String']['output']>;
    name?: Maybe<Scalars['String']['output']>;
    numberOfEmployees?: Maybe<Scalars['String']['output']>;
    organizationId?: Maybe<Scalars['String']['output']>;
    personId?: Maybe<Scalars['String']['output']>;
    prospectId?: Maybe<Scalars['String']['output']>;
    scheduledOn?: Maybe<Scalars['timestamp']['output']>;
    scheduledOnMotivation?: Maybe<Scalars['String']['output']>;
    score?: Maybe<Scalars['Int']['output']>;
    title?: Maybe<Scalars['String']['output']>;
    updatedAt?: Maybe<Scalars['timestamp']['output']>;
    userId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "OutreachSuggestions" */
export type OutreachSuggestions_Max_Order_By = {
    accountId?: InputMaybe<Order_By>;
    accountName?: InputMaybe<Order_By>;
    accountWebsite?: InputMaybe<Order_By>;
    campaignId?: InputMaybe<Order_By>;
    campaignName?: InputMaybe<Order_By>;
    campaignType?: InputMaybe<Order_By>;
    contactLocation?: InputMaybe<Order_By>;
    conversationMetaDataId?: InputMaybe<Order_By>;
    createdAt?: InputMaybe<Order_By>;
    email?: InputMaybe<Order_By>;
    goal?: InputMaybe<Order_By>;
    id?: InputMaybe<Order_By>;
    industry?: InputMaybe<Order_By>;
    linkedinUrl?: InputMaybe<Order_By>;
    messageId?: InputMaybe<Order_By>;
    name?: InputMaybe<Order_By>;
    numberOfEmployees?: InputMaybe<Order_By>;
    organizationId?: InputMaybe<Order_By>;
    personId?: InputMaybe<Order_By>;
    prospectId?: InputMaybe<Order_By>;
    scheduledOn?: InputMaybe<Order_By>;
    scheduledOnMotivation?: InputMaybe<Order_By>;
    score?: InputMaybe<Order_By>;
    title?: InputMaybe<Order_By>;
    updatedAt?: InputMaybe<Order_By>;
    userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type OutreachSuggestions_Min_Fields = {
    __typename?: 'OutreachSuggestions_min_fields';
    accountId?: Maybe<Scalars['String']['output']>;
    accountName?: Maybe<Scalars['String']['output']>;
    accountWebsite?: Maybe<Scalars['String']['output']>;
    campaignId?: Maybe<Scalars['String']['output']>;
    campaignName?: Maybe<Scalars['String']['output']>;
    campaignType?: Maybe<Scalars['String']['output']>;
    contactLocation?: Maybe<Scalars['String']['output']>;
    conversationMetaDataId?: Maybe<Scalars['String']['output']>;
    createdAt?: Maybe<Scalars['timestamp']['output']>;
    email?: Maybe<Scalars['String']['output']>;
    goal?: Maybe<Scalars['String']['output']>;
    id?: Maybe<Scalars['String']['output']>;
    industry?: Maybe<Scalars['String']['output']>;
    linkedinUrl?: Maybe<Scalars['String']['output']>;
    messageId?: Maybe<Scalars['String']['output']>;
    name?: Maybe<Scalars['String']['output']>;
    numberOfEmployees?: Maybe<Scalars['String']['output']>;
    organizationId?: Maybe<Scalars['String']['output']>;
    personId?: Maybe<Scalars['String']['output']>;
    prospectId?: Maybe<Scalars['String']['output']>;
    scheduledOn?: Maybe<Scalars['timestamp']['output']>;
    scheduledOnMotivation?: Maybe<Scalars['String']['output']>;
    score?: Maybe<Scalars['Int']['output']>;
    title?: Maybe<Scalars['String']['output']>;
    updatedAt?: Maybe<Scalars['timestamp']['output']>;
    userId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "OutreachSuggestions" */
export type OutreachSuggestions_Min_Order_By = {
    accountId?: InputMaybe<Order_By>;
    accountName?: InputMaybe<Order_By>;
    accountWebsite?: InputMaybe<Order_By>;
    campaignId?: InputMaybe<Order_By>;
    campaignName?: InputMaybe<Order_By>;
    campaignType?: InputMaybe<Order_By>;
    contactLocation?: InputMaybe<Order_By>;
    conversationMetaDataId?: InputMaybe<Order_By>;
    createdAt?: InputMaybe<Order_By>;
    email?: InputMaybe<Order_By>;
    goal?: InputMaybe<Order_By>;
    id?: InputMaybe<Order_By>;
    industry?: InputMaybe<Order_By>;
    linkedinUrl?: InputMaybe<Order_By>;
    messageId?: InputMaybe<Order_By>;
    name?: InputMaybe<Order_By>;
    numberOfEmployees?: InputMaybe<Order_By>;
    organizationId?: InputMaybe<Order_By>;
    personId?: InputMaybe<Order_By>;
    prospectId?: InputMaybe<Order_By>;
    scheduledOn?: InputMaybe<Order_By>;
    scheduledOnMotivation?: InputMaybe<Order_By>;
    score?: InputMaybe<Order_By>;
    title?: InputMaybe<Order_By>;
    updatedAt?: InputMaybe<Order_By>;
    userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "OutreachSuggestions" */
export type OutreachSuggestions_Mutation_Response = {
    __typename?: 'OutreachSuggestions_mutation_response';
    /** number of rows affected by the mutation */
    affected_rows: Scalars['Int']['output'];
    /** data from the rows affected by the mutation */
    returning: Array<OutreachSuggestions>;
};

/** input type for inserting object relation for remote table "OutreachSuggestions" */
export type OutreachSuggestions_Obj_Rel_Insert_Input = {
    data: OutreachSuggestions_Insert_Input;
    /** upsert condition */
    on_conflict?: InputMaybe<OutreachSuggestions_On_Conflict>;
};

/** on_conflict condition type for table "OutreachSuggestions" */
export type OutreachSuggestions_On_Conflict = {
    constraint: OutreachSuggestions_Constraint;
    update_columns?: Array<OutreachSuggestions_Update_Column>;
    where?: InputMaybe<OutreachSuggestions_Bool_Exp>;
};

/** Ordering options when selecting data from "OutreachSuggestions". */
export type OutreachSuggestions_Order_By = {
    ConversationMetaData?: InputMaybe<ConversationMetaData_Order_By>;
    Message?: InputMaybe<Messages_Order_By>;
    accountId?: InputMaybe<Order_By>;
    accountName?: InputMaybe<Order_By>;
    accountWebsite?: InputMaybe<Order_By>;
    campaignId?: InputMaybe<Order_By>;
    campaignName?: InputMaybe<Order_By>;
    campaignType?: InputMaybe<Order_By>;
    contactLocation?: InputMaybe<Order_By>;
    conversationMetaDataId?: InputMaybe<Order_By>;
    createdAt?: InputMaybe<Order_By>;
    email?: InputMaybe<Order_By>;
    goal?: InputMaybe<Order_By>;
    id?: InputMaybe<Order_By>;
    industry?: InputMaybe<Order_By>;
    isGenerating?: InputMaybe<Order_By>;
    linkedinUrl?: InputMaybe<Order_By>;
    messageId?: InputMaybe<Order_By>;
    name?: InputMaybe<Order_By>;
    numberOfEmployees?: InputMaybe<Order_By>;
    organizationId?: InputMaybe<Order_By>;
    personId?: InputMaybe<Order_By>;
    prospectId?: InputMaybe<Order_By>;
    scheduledOn?: InputMaybe<Order_By>;
    scheduledOnMotivation?: InputMaybe<Order_By>;
    score?: InputMaybe<Order_By>;
    title?: InputMaybe<Order_By>;
    unread?: InputMaybe<Order_By>;
    updatedAt?: InputMaybe<Order_By>;
    userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: OutreachSuggestions */
export type OutreachSuggestions_Pk_Columns_Input = {
    id: Scalars['String']['input'];
};

/** select columns of table "OutreachSuggestions" */
export enum OutreachSuggestions_Select_Column {
    /** column name */
    AccountId = 'accountId',
    /** column name */
    AccountName = 'accountName',
    /** column name */
    AccountWebsite = 'accountWebsite',
    /** column name */
    CampaignId = 'campaignId',
    /** column name */
    CampaignName = 'campaignName',
    /** column name */
    CampaignType = 'campaignType',
    /** column name */
    ContactLocation = 'contactLocation',
    /** column name */
    ConversationMetaDataId = 'conversationMetaDataId',
    /** column name */
    CreatedAt = 'createdAt',
    /** column name */
    Email = 'email',
    /** column name */
    Goal = 'goal',
    /** column name */
    Id = 'id',
    /** column name */
    Industry = 'industry',
    /** column name */
    IsGenerating = 'isGenerating',
    /** column name */
    LinkedinUrl = 'linkedinUrl',
    /** column name */
    MessageId = 'messageId',
    /** column name */
    Name = 'name',
    /** column name */
    NumberOfEmployees = 'numberOfEmployees',
    /** column name */
    OrganizationId = 'organizationId',
    /** column name */
    PersonId = 'personId',
    /** column name */
    ProspectId = 'prospectId',
    /** column name */
    ScheduledOn = 'scheduledOn',
    /** column name */
    ScheduledOnMotivation = 'scheduledOnMotivation',
    /** column name */
    Score = 'score',
    /** column name */
    Title = 'title',
    /** column name */
    Unread = 'unread',
    /** column name */
    UpdatedAt = 'updatedAt',
    /** column name */
    UserId = 'userId',
}

/** select "OutreachSuggestions_aggregate_bool_exp_bool_and_arguments_columns" columns of table "OutreachSuggestions" */
export enum OutreachSuggestions_Select_Column_OutreachSuggestions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
    /** column name */
    IsGenerating = 'isGenerating',
    /** column name */
    Unread = 'unread',
}

/** select "OutreachSuggestions_aggregate_bool_exp_bool_or_arguments_columns" columns of table "OutreachSuggestions" */
export enum OutreachSuggestions_Select_Column_OutreachSuggestions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
    /** column name */
    IsGenerating = 'isGenerating',
    /** column name */
    Unread = 'unread',
}

/** input type for updating data in table "OutreachSuggestions" */
export type OutreachSuggestions_Set_Input = {
    accountId?: InputMaybe<Scalars['String']['input']>;
    accountName?: InputMaybe<Scalars['String']['input']>;
    accountWebsite?: InputMaybe<Scalars['String']['input']>;
    campaignId?: InputMaybe<Scalars['String']['input']>;
    campaignName?: InputMaybe<Scalars['String']['input']>;
    campaignType?: InputMaybe<Scalars['String']['input']>;
    contactLocation?: InputMaybe<Scalars['String']['input']>;
    conversationMetaDataId?: InputMaybe<Scalars['String']['input']>;
    createdAt?: InputMaybe<Scalars['timestamp']['input']>;
    email?: InputMaybe<Scalars['String']['input']>;
    goal?: InputMaybe<Scalars['String']['input']>;
    id?: InputMaybe<Scalars['String']['input']>;
    industry?: InputMaybe<Scalars['String']['input']>;
    isGenerating?: InputMaybe<Scalars['Boolean']['input']>;
    linkedinUrl?: InputMaybe<Scalars['String']['input']>;
    messageId?: InputMaybe<Scalars['String']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
    numberOfEmployees?: InputMaybe<Scalars['String']['input']>;
    organizationId?: InputMaybe<Scalars['String']['input']>;
    personId?: InputMaybe<Scalars['String']['input']>;
    prospectId?: InputMaybe<Scalars['String']['input']>;
    scheduledOn?: InputMaybe<Scalars['timestamp']['input']>;
    scheduledOnMotivation?: InputMaybe<Scalars['String']['input']>;
    score?: InputMaybe<Scalars['Int']['input']>;
    title?: InputMaybe<Scalars['String']['input']>;
    unread?: InputMaybe<Scalars['Boolean']['input']>;
    updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
    userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type OutreachSuggestions_Stddev_Fields = {
    __typename?: 'OutreachSuggestions_stddev_fields';
    score?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "OutreachSuggestions" */
export type OutreachSuggestions_Stddev_Order_By = {
    score?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type OutreachSuggestions_Stddev_Pop_Fields = {
    __typename?: 'OutreachSuggestions_stddev_pop_fields';
    score?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "OutreachSuggestions" */
export type OutreachSuggestions_Stddev_Pop_Order_By = {
    score?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type OutreachSuggestions_Stddev_Samp_Fields = {
    __typename?: 'OutreachSuggestions_stddev_samp_fields';
    score?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "OutreachSuggestions" */
export type OutreachSuggestions_Stddev_Samp_Order_By = {
    score?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "OutreachSuggestions" */
export type OutreachSuggestions_Stream_Cursor_Input = {
    /** Stream column input with initial value */
    initial_value: OutreachSuggestions_Stream_Cursor_Value_Input;
    /** cursor ordering */
    ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type OutreachSuggestions_Stream_Cursor_Value_Input = {
    accountId?: InputMaybe<Scalars['String']['input']>;
    accountName?: InputMaybe<Scalars['String']['input']>;
    accountWebsite?: InputMaybe<Scalars['String']['input']>;
    campaignId?: InputMaybe<Scalars['String']['input']>;
    campaignName?: InputMaybe<Scalars['String']['input']>;
    campaignType?: InputMaybe<Scalars['String']['input']>;
    contactLocation?: InputMaybe<Scalars['String']['input']>;
    conversationMetaDataId?: InputMaybe<Scalars['String']['input']>;
    createdAt?: InputMaybe<Scalars['timestamp']['input']>;
    email?: InputMaybe<Scalars['String']['input']>;
    goal?: InputMaybe<Scalars['String']['input']>;
    id?: InputMaybe<Scalars['String']['input']>;
    industry?: InputMaybe<Scalars['String']['input']>;
    isGenerating?: InputMaybe<Scalars['Boolean']['input']>;
    linkedinUrl?: InputMaybe<Scalars['String']['input']>;
    messageId?: InputMaybe<Scalars['String']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
    numberOfEmployees?: InputMaybe<Scalars['String']['input']>;
    organizationId?: InputMaybe<Scalars['String']['input']>;
    personId?: InputMaybe<Scalars['String']['input']>;
    prospectId?: InputMaybe<Scalars['String']['input']>;
    scheduledOn?: InputMaybe<Scalars['timestamp']['input']>;
    scheduledOnMotivation?: InputMaybe<Scalars['String']['input']>;
    score?: InputMaybe<Scalars['Int']['input']>;
    title?: InputMaybe<Scalars['String']['input']>;
    unread?: InputMaybe<Scalars['Boolean']['input']>;
    updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
    userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type OutreachSuggestions_Sum_Fields = {
    __typename?: 'OutreachSuggestions_sum_fields';
    score?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "OutreachSuggestions" */
export type OutreachSuggestions_Sum_Order_By = {
    score?: InputMaybe<Order_By>;
};

/** update columns of table "OutreachSuggestions" */
export enum OutreachSuggestions_Update_Column {
    /** column name */
    AccountId = 'accountId',
    /** column name */
    AccountName = 'accountName',
    /** column name */
    AccountWebsite = 'accountWebsite',
    /** column name */
    CampaignId = 'campaignId',
    /** column name */
    CampaignName = 'campaignName',
    /** column name */
    CampaignType = 'campaignType',
    /** column name */
    ContactLocation = 'contactLocation',
    /** column name */
    ConversationMetaDataId = 'conversationMetaDataId',
    /** column name */
    CreatedAt = 'createdAt',
    /** column name */
    Email = 'email',
    /** column name */
    Goal = 'goal',
    /** column name */
    Id = 'id',
    /** column name */
    Industry = 'industry',
    /** column name */
    IsGenerating = 'isGenerating',
    /** column name */
    LinkedinUrl = 'linkedinUrl',
    /** column name */
    MessageId = 'messageId',
    /** column name */
    Name = 'name',
    /** column name */
    NumberOfEmployees = 'numberOfEmployees',
    /** column name */
    OrganizationId = 'organizationId',
    /** column name */
    PersonId = 'personId',
    /** column name */
    ProspectId = 'prospectId',
    /** column name */
    ScheduledOn = 'scheduledOn',
    /** column name */
    ScheduledOnMotivation = 'scheduledOnMotivation',
    /** column name */
    Score = 'score',
    /** column name */
    Title = 'title',
    /** column name */
    Unread = 'unread',
    /** column name */
    UpdatedAt = 'updatedAt',
    /** column name */
    UserId = 'userId',
}

export type OutreachSuggestions_Updates = {
    /** increments the numeric columns with given value of the filtered values */
    _inc?: InputMaybe<OutreachSuggestions_Inc_Input>;
    /** sets the columns of the filtered rows to the given values */
    _set?: InputMaybe<OutreachSuggestions_Set_Input>;
    /** filter the rows which have to be updated */
    where: OutreachSuggestions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type OutreachSuggestions_Var_Pop_Fields = {
    __typename?: 'OutreachSuggestions_var_pop_fields';
    score?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "OutreachSuggestions" */
export type OutreachSuggestions_Var_Pop_Order_By = {
    score?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type OutreachSuggestions_Var_Samp_Fields = {
    __typename?: 'OutreachSuggestions_var_samp_fields';
    score?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "OutreachSuggestions" */
export type OutreachSuggestions_Var_Samp_Order_By = {
    score?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type OutreachSuggestions_Variance_Fields = {
    __typename?: 'OutreachSuggestions_variance_fields';
    score?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "OutreachSuggestions" */
export type OutreachSuggestions_Variance_Order_By = {
    score?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Array_Comparison_Exp = {
    /** is the array contained in the given array value */
    _contained_in?: InputMaybe<Array<Scalars['String']['input']>>;
    /** does the array contain the given value */
    _contains?: InputMaybe<Array<Scalars['String']['input']>>;
    _eq?: InputMaybe<Array<Scalars['String']['input']>>;
    _gt?: InputMaybe<Array<Scalars['String']['input']>>;
    _gte?: InputMaybe<Array<Scalars['String']['input']>>;
    _in?: InputMaybe<Array<Array<Scalars['String']['input']>>>;
    _is_null?: InputMaybe<Scalars['Boolean']['input']>;
    _lt?: InputMaybe<Array<Scalars['String']['input']>>;
    _lte?: InputMaybe<Array<Scalars['String']['input']>>;
    _neq?: InputMaybe<Array<Scalars['String']['input']>>;
    _nin?: InputMaybe<Array<Array<Scalars['String']['input']>>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
    _eq?: InputMaybe<Scalars['String']['input']>;
    _gt?: InputMaybe<Scalars['String']['input']>;
    _gte?: InputMaybe<Scalars['String']['input']>;
    /** does the column match the given case-insensitive pattern */
    _ilike?: InputMaybe<Scalars['String']['input']>;
    _in?: InputMaybe<Array<Scalars['String']['input']>>;
    /** does the column match the given POSIX regular expression, case insensitive */
    _iregex?: InputMaybe<Scalars['String']['input']>;
    _is_null?: InputMaybe<Scalars['Boolean']['input']>;
    /** does the column match the given pattern */
    _like?: InputMaybe<Scalars['String']['input']>;
    _lt?: InputMaybe<Scalars['String']['input']>;
    _lte?: InputMaybe<Scalars['String']['input']>;
    _neq?: InputMaybe<Scalars['String']['input']>;
    /** does the column NOT match the given case-insensitive pattern */
    _nilike?: InputMaybe<Scalars['String']['input']>;
    _nin?: InputMaybe<Array<Scalars['String']['input']>>;
    /** does the column NOT match the given POSIX regular expression, case insensitive */
    _niregex?: InputMaybe<Scalars['String']['input']>;
    /** does the column NOT match the given pattern */
    _nlike?: InputMaybe<Scalars['String']['input']>;
    /** does the column NOT match the given POSIX regular expression, case sensitive */
    _nregex?: InputMaybe<Scalars['String']['input']>;
    /** does the column NOT match the given SQL regular expression */
    _nsimilar?: InputMaybe<Scalars['String']['input']>;
    /** does the column match the given POSIX regular expression, case sensitive */
    _regex?: InputMaybe<Scalars['String']['input']>;
    /** does the column match the given SQL regular expression */
    _similar?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to compare columns of type "UserContactStatus". All fields are combined with logical 'AND'. */
export type UserContactStatus_Comparison_Exp = {
    _eq?: InputMaybe<Scalars['UserContactStatus']['input']>;
    _gt?: InputMaybe<Scalars['UserContactStatus']['input']>;
    _gte?: InputMaybe<Scalars['UserContactStatus']['input']>;
    _in?: InputMaybe<Array<Scalars['UserContactStatus']['input']>>;
    _is_null?: InputMaybe<Scalars['Boolean']['input']>;
    _lt?: InputMaybe<Scalars['UserContactStatus']['input']>;
    _lte?: InputMaybe<Scalars['UserContactStatus']['input']>;
    _neq?: InputMaybe<Scalars['UserContactStatus']['input']>;
    _nin?: InputMaybe<Array<Scalars['UserContactStatus']['input']>>;
};

/** columns and relationships of "UserContacts" */
export type UserContacts = {
    __typename?: 'UserContacts';
    /** An array relationship */
    _GroupToUserContacts: Array<_GroupToUserContact>;
    /** An aggregate relationship */
    _GroupToUserContacts_aggregate: _GroupToUserContact_Aggregate;
    createdAt: Scalars['timestamp']['output'];
    id: Scalars['String']['output'];
    prospectId: Scalars['String']['output'];
    source?: Maybe<Scalars['String']['output']>;
    status: Scalars['UserContactStatus']['output'];
    updatedAt: Scalars['timestamp']['output'];
    userId: Scalars['String']['output'];
};

/** columns and relationships of "UserContacts" */
export type UserContacts_GroupToUserContactsArgs = {
    distinct_on?: InputMaybe<Array<_GroupToUserContact_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<_GroupToUserContact_Order_By>>;
    where?: InputMaybe<_GroupToUserContact_Bool_Exp>;
};

/** columns and relationships of "UserContacts" */
export type UserContacts_GroupToUserContacts_AggregateArgs = {
    distinct_on?: InputMaybe<Array<_GroupToUserContact_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<_GroupToUserContact_Order_By>>;
    where?: InputMaybe<_GroupToUserContact_Bool_Exp>;
};

/** aggregated selection of "UserContacts" */
export type UserContacts_Aggregate = {
    __typename?: 'UserContacts_aggregate';
    aggregate?: Maybe<UserContacts_Aggregate_Fields>;
    nodes: Array<UserContacts>;
};

/** aggregate fields of "UserContacts" */
export type UserContacts_Aggregate_Fields = {
    __typename?: 'UserContacts_aggregate_fields';
    count: Scalars['Int']['output'];
    max?: Maybe<UserContacts_Max_Fields>;
    min?: Maybe<UserContacts_Min_Fields>;
};

/** aggregate fields of "UserContacts" */
export type UserContacts_Aggregate_FieldsCountArgs = {
    columns?: InputMaybe<Array<UserContacts_Select_Column>>;
    distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "UserContacts". All fields are combined with a logical 'AND'. */
export type UserContacts_Bool_Exp = {
    _GroupToUserContacts?: InputMaybe<_GroupToUserContact_Bool_Exp>;
    _GroupToUserContacts_aggregate?: InputMaybe<_GroupToUserContact_Aggregate_Bool_Exp>;
    _and?: InputMaybe<Array<UserContacts_Bool_Exp>>;
    _not?: InputMaybe<UserContacts_Bool_Exp>;
    _or?: InputMaybe<Array<UserContacts_Bool_Exp>>;
    createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
    id?: InputMaybe<String_Comparison_Exp>;
    prospectId?: InputMaybe<String_Comparison_Exp>;
    source?: InputMaybe<String_Comparison_Exp>;
    status?: InputMaybe<UserContactStatus_Comparison_Exp>;
    updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
    userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "UserContacts" */
export enum UserContacts_Constraint {
    /** unique or primary key constraint on columns "id" */
    UserContactsPkey = 'UserContacts_pkey',
    /** unique or primary key constraint on columns "userId", "prospectId" */
    UserContactsProspectIdUserIdKey = 'UserContacts_prospectId_userId_key',
}

/** input type for inserting data into table "UserContacts" */
export type UserContacts_Insert_Input = {
    _GroupToUserContacts?: InputMaybe<_GroupToUserContact_Arr_Rel_Insert_Input>;
    createdAt?: InputMaybe<Scalars['timestamp']['input']>;
    id?: InputMaybe<Scalars['String']['input']>;
    prospectId?: InputMaybe<Scalars['String']['input']>;
    source?: InputMaybe<Scalars['String']['input']>;
    status?: InputMaybe<Scalars['UserContactStatus']['input']>;
    updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
    userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type UserContacts_Max_Fields = {
    __typename?: 'UserContacts_max_fields';
    createdAt?: Maybe<Scalars['timestamp']['output']>;
    id?: Maybe<Scalars['String']['output']>;
    prospectId?: Maybe<Scalars['String']['output']>;
    source?: Maybe<Scalars['String']['output']>;
    status?: Maybe<Scalars['UserContactStatus']['output']>;
    updatedAt?: Maybe<Scalars['timestamp']['output']>;
    userId?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type UserContacts_Min_Fields = {
    __typename?: 'UserContacts_min_fields';
    createdAt?: Maybe<Scalars['timestamp']['output']>;
    id?: Maybe<Scalars['String']['output']>;
    prospectId?: Maybe<Scalars['String']['output']>;
    source?: Maybe<Scalars['String']['output']>;
    status?: Maybe<Scalars['UserContactStatus']['output']>;
    updatedAt?: Maybe<Scalars['timestamp']['output']>;
    userId?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "UserContacts" */
export type UserContacts_Mutation_Response = {
    __typename?: 'UserContacts_mutation_response';
    /** number of rows affected by the mutation */
    affected_rows: Scalars['Int']['output'];
    /** data from the rows affected by the mutation */
    returning: Array<UserContacts>;
};

/** input type for inserting object relation for remote table "UserContacts" */
export type UserContacts_Obj_Rel_Insert_Input = {
    data: UserContacts_Insert_Input;
    /** upsert condition */
    on_conflict?: InputMaybe<UserContacts_On_Conflict>;
};

/** on_conflict condition type for table "UserContacts" */
export type UserContacts_On_Conflict = {
    constraint: UserContacts_Constraint;
    update_columns?: Array<UserContacts_Update_Column>;
    where?: InputMaybe<UserContacts_Bool_Exp>;
};

/** Ordering options when selecting data from "UserContacts". */
export type UserContacts_Order_By = {
    _GroupToUserContacts_aggregate?: InputMaybe<_GroupToUserContact_Aggregate_Order_By>;
    createdAt?: InputMaybe<Order_By>;
    id?: InputMaybe<Order_By>;
    prospectId?: InputMaybe<Order_By>;
    source?: InputMaybe<Order_By>;
    status?: InputMaybe<Order_By>;
    updatedAt?: InputMaybe<Order_By>;
    userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: UserContacts */
export type UserContacts_Pk_Columns_Input = {
    id: Scalars['String']['input'];
};

/** select columns of table "UserContacts" */
export enum UserContacts_Select_Column {
    /** column name */
    CreatedAt = 'createdAt',
    /** column name */
    Id = 'id',
    /** column name */
    ProspectId = 'prospectId',
    /** column name */
    Source = 'source',
    /** column name */
    Status = 'status',
    /** column name */
    UpdatedAt = 'updatedAt',
    /** column name */
    UserId = 'userId',
}

/** input type for updating data in table "UserContacts" */
export type UserContacts_Set_Input = {
    createdAt?: InputMaybe<Scalars['timestamp']['input']>;
    id?: InputMaybe<Scalars['String']['input']>;
    prospectId?: InputMaybe<Scalars['String']['input']>;
    source?: InputMaybe<Scalars['String']['input']>;
    status?: InputMaybe<Scalars['UserContactStatus']['input']>;
    updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
    userId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "UserContacts" */
export type UserContacts_Stream_Cursor_Input = {
    /** Stream column input with initial value */
    initial_value: UserContacts_Stream_Cursor_Value_Input;
    /** cursor ordering */
    ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type UserContacts_Stream_Cursor_Value_Input = {
    createdAt?: InputMaybe<Scalars['timestamp']['input']>;
    id?: InputMaybe<Scalars['String']['input']>;
    prospectId?: InputMaybe<Scalars['String']['input']>;
    source?: InputMaybe<Scalars['String']['input']>;
    status?: InputMaybe<Scalars['UserContactStatus']['input']>;
    updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
    userId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "UserContacts" */
export enum UserContacts_Update_Column {
    /** column name */
    CreatedAt = 'createdAt',
    /** column name */
    Id = 'id',
    /** column name */
    ProspectId = 'prospectId',
    /** column name */
    Source = 'source',
    /** column name */
    Status = 'status',
    /** column name */
    UpdatedAt = 'updatedAt',
    /** column name */
    UserId = 'userId',
}

export type UserContacts_Updates = {
    /** sets the columns of the filtered rows to the given values */
    _set?: InputMaybe<UserContacts_Set_Input>;
    /** filter the rows which have to be updated */
    where: UserContacts_Bool_Exp;
};

/** columns and relationships of "_GroupToUserContact" */
export type _GroupToUserContact = {
    __typename?: '_GroupToUserContact';
    A: Scalars['String']['output'];
    B: Scalars['String']['output'];
    /** An object relationship */
    Group: Groups;
    /** An object relationship */
    UserContact: UserContacts;
};

/** aggregated selection of "_GroupToUserContact" */
export type _GroupToUserContact_Aggregate = {
    __typename?: '_GroupToUserContact_aggregate';
    aggregate?: Maybe<_GroupToUserContact_Aggregate_Fields>;
    nodes: Array<_GroupToUserContact>;
};

export type _GroupToUserContact_Aggregate_Bool_Exp = {
    count?: InputMaybe<_GroupToUserContact_Aggregate_Bool_Exp_Count>;
};

export type _GroupToUserContact_Aggregate_Bool_Exp_Count = {
    arguments?: InputMaybe<Array<_GroupToUserContact_Select_Column>>;
    distinct?: InputMaybe<Scalars['Boolean']['input']>;
    filter?: InputMaybe<_GroupToUserContact_Bool_Exp>;
    predicate: Int_Comparison_Exp;
};

/** aggregate fields of "_GroupToUserContact" */
export type _GroupToUserContact_Aggregate_Fields = {
    __typename?: '_GroupToUserContact_aggregate_fields';
    count: Scalars['Int']['output'];
    max?: Maybe<_GroupToUserContact_Max_Fields>;
    min?: Maybe<_GroupToUserContact_Min_Fields>;
};

/** aggregate fields of "_GroupToUserContact" */
export type _GroupToUserContact_Aggregate_FieldsCountArgs = {
    columns?: InputMaybe<Array<_GroupToUserContact_Select_Column>>;
    distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "_GroupToUserContact" */
export type _GroupToUserContact_Aggregate_Order_By = {
    count?: InputMaybe<Order_By>;
    max?: InputMaybe<_GroupToUserContact_Max_Order_By>;
    min?: InputMaybe<_GroupToUserContact_Min_Order_By>;
};

/** input type for inserting array relation for remote table "_GroupToUserContact" */
export type _GroupToUserContact_Arr_Rel_Insert_Input = {
    data: Array<_GroupToUserContact_Insert_Input>;
    /** upsert condition */
    on_conflict?: InputMaybe<_GroupToUserContact_On_Conflict>;
};

/** Boolean expression to filter rows from the table "_GroupToUserContact". All fields are combined with a logical 'AND'. */
export type _GroupToUserContact_Bool_Exp = {
    A?: InputMaybe<String_Comparison_Exp>;
    B?: InputMaybe<String_Comparison_Exp>;
    Group?: InputMaybe<Groups_Bool_Exp>;
    UserContact?: InputMaybe<UserContacts_Bool_Exp>;
    _and?: InputMaybe<Array<_GroupToUserContact_Bool_Exp>>;
    _not?: InputMaybe<_GroupToUserContact_Bool_Exp>;
    _or?: InputMaybe<Array<_GroupToUserContact_Bool_Exp>>;
};

/** unique or primary key constraints on table "_GroupToUserContact" */
export enum _GroupToUserContact_Constraint {
    /** unique or primary key constraint on columns "B", "A" */
    GroupToUserContactAbPkey = '_GroupToUserContact_AB_pkey',
}

/** input type for inserting data into table "_GroupToUserContact" */
export type _GroupToUserContact_Insert_Input = {
    A?: InputMaybe<Scalars['String']['input']>;
    B?: InputMaybe<Scalars['String']['input']>;
    Group?: InputMaybe<Groups_Obj_Rel_Insert_Input>;
    UserContact?: InputMaybe<UserContacts_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type _GroupToUserContact_Max_Fields = {
    __typename?: '_GroupToUserContact_max_fields';
    A?: Maybe<Scalars['String']['output']>;
    B?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "_GroupToUserContact" */
export type _GroupToUserContact_Max_Order_By = {
    A?: InputMaybe<Order_By>;
    B?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type _GroupToUserContact_Min_Fields = {
    __typename?: '_GroupToUserContact_min_fields';
    A?: Maybe<Scalars['String']['output']>;
    B?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "_GroupToUserContact" */
export type _GroupToUserContact_Min_Order_By = {
    A?: InputMaybe<Order_By>;
    B?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "_GroupToUserContact" */
export type _GroupToUserContact_Mutation_Response = {
    __typename?: '_GroupToUserContact_mutation_response';
    /** number of rows affected by the mutation */
    affected_rows: Scalars['Int']['output'];
    /** data from the rows affected by the mutation */
    returning: Array<_GroupToUserContact>;
};

/** on_conflict condition type for table "_GroupToUserContact" */
export type _GroupToUserContact_On_Conflict = {
    constraint: _GroupToUserContact_Constraint;
    update_columns?: Array<_GroupToUserContact_Update_Column>;
    where?: InputMaybe<_GroupToUserContact_Bool_Exp>;
};

/** Ordering options when selecting data from "_GroupToUserContact". */
export type _GroupToUserContact_Order_By = {
    A?: InputMaybe<Order_By>;
    B?: InputMaybe<Order_By>;
    Group?: InputMaybe<Groups_Order_By>;
    UserContact?: InputMaybe<UserContacts_Order_By>;
};

/** primary key columns input for table: _GroupToUserContact */
export type _GroupToUserContact_Pk_Columns_Input = {
    A: Scalars['String']['input'];
    B: Scalars['String']['input'];
};

/** select columns of table "_GroupToUserContact" */
export enum _GroupToUserContact_Select_Column {
    /** column name */
    A = 'A',
    /** column name */
    B = 'B',
}

/** input type for updating data in table "_GroupToUserContact" */
export type _GroupToUserContact_Set_Input = {
    A?: InputMaybe<Scalars['String']['input']>;
    B?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "_GroupToUserContact" */
export type _GroupToUserContact_Stream_Cursor_Input = {
    /** Stream column input with initial value */
    initial_value: _GroupToUserContact_Stream_Cursor_Value_Input;
    /** cursor ordering */
    ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type _GroupToUserContact_Stream_Cursor_Value_Input = {
    A?: InputMaybe<Scalars['String']['input']>;
    B?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "_GroupToUserContact" */
export enum _GroupToUserContact_Update_Column {
    /** column name */
    A = 'A',
    /** column name */
    B = 'B',
}

export type _GroupToUserContact_Updates = {
    /** sets the columns of the filtered rows to the given values */
    _set?: InputMaybe<_GroupToUserContact_Set_Input>;
    /** filter the rows which have to be updated */
    where: _GroupToUserContact_Bool_Exp;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
    _eq?: InputMaybe<Scalars['bigint']['input']>;
    _gt?: InputMaybe<Scalars['bigint']['input']>;
    _gte?: InputMaybe<Scalars['bigint']['input']>;
    _in?: InputMaybe<Array<Scalars['bigint']['input']>>;
    _is_null?: InputMaybe<Scalars['Boolean']['input']>;
    _lt?: InputMaybe<Scalars['bigint']['input']>;
    _lte?: InputMaybe<Scalars['bigint']['input']>;
    _neq?: InputMaybe<Scalars['bigint']['input']>;
    _nin?: InputMaybe<Array<Scalars['bigint']['input']>>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
    /** ascending ordering of the cursor */
    Asc = 'ASC',
    /** descending ordering of the cursor */
    Desc = 'DESC',
}

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
    _eq?: InputMaybe<Scalars['float8']['input']>;
    _gt?: InputMaybe<Scalars['float8']['input']>;
    _gte?: InputMaybe<Scalars['float8']['input']>;
    _in?: InputMaybe<Array<Scalars['float8']['input']>>;
    _is_null?: InputMaybe<Scalars['Boolean']['input']>;
    _lt?: InputMaybe<Scalars['float8']['input']>;
    _lte?: InputMaybe<Scalars['float8']['input']>;
    _neq?: InputMaybe<Scalars['float8']['input']>;
    _nin?: InputMaybe<Array<Scalars['float8']['input']>>;
};

/** mutation root */
export type Mutation_Root = {
    __typename?: 'mutation_root';
    /** delete data from the table: "AssistantSettings" */
    delete_AssistantSettings?: Maybe<AssistantSettings_Mutation_Response>;
    /** delete single row from the table: "AssistantSettings" */
    delete_AssistantSettings_by_pk?: Maybe<AssistantSettings>;
    /** delete data from the table: "Attachments" */
    delete_Attachments?: Maybe<Attachments_Mutation_Response>;
    /** delete single row from the table: "Attachments" */
    delete_Attachments_by_pk?: Maybe<Attachments>;
    /** delete data from the table: "ContactSuggestions" */
    delete_ContactSuggestions?: Maybe<ContactSuggestions_Mutation_Response>;
    /** delete single row from the table: "ContactSuggestions" */
    delete_ContactSuggestions_by_pk?: Maybe<ContactSuggestions>;
    /** delete data from the table: "ConversationMetaData" */
    delete_ConversationMetaData?: Maybe<ConversationMetaData_Mutation_Response>;
    /** delete single row from the table: "ConversationMetaData" */
    delete_ConversationMetaData_by_pk?: Maybe<ConversationMetaData>;
    /** delete data from the table: "Groups" */
    delete_Groups?: Maybe<Groups_Mutation_Response>;
    /** delete single row from the table: "Groups" */
    delete_Groups_by_pk?: Maybe<Groups>;
    /** delete data from the table: "LinkedinConversations" */
    delete_LinkedinConversations?: Maybe<LinkedinConversations_Mutation_Response>;
    /** delete single row from the table: "LinkedinConversations" */
    delete_LinkedinConversations_by_pk?: Maybe<LinkedinConversations>;
    /** delete data from the table: "Messages" */
    delete_Messages?: Maybe<Messages_Mutation_Response>;
    /** delete single row from the table: "Messages" */
    delete_Messages_by_pk?: Maybe<Messages>;
    /** delete data from the table: "Notes" */
    delete_Notes?: Maybe<Notes_Mutation_Response>;
    /** delete single row from the table: "Notes" */
    delete_Notes_by_pk?: Maybe<Notes>;
    /** delete data from the table: "OutreachCompletionMetrics" */
    delete_OutreachCompletionMetrics?: Maybe<OutreachCompletionMetrics_Mutation_Response>;
    /** delete single row from the table: "OutreachCompletionMetrics" */
    delete_OutreachCompletionMetrics_by_pk?: Maybe<OutreachCompletionMetrics>;
    /** delete data from the table: "OutreachSuggestions" */
    delete_OutreachSuggestions?: Maybe<OutreachSuggestions_Mutation_Response>;
    /** delete single row from the table: "OutreachSuggestions" */
    delete_OutreachSuggestions_by_pk?: Maybe<OutreachSuggestions>;
    /** delete data from the table: "UserContacts" */
    delete_UserContacts?: Maybe<UserContacts_Mutation_Response>;
    /** delete single row from the table: "UserContacts" */
    delete_UserContacts_by_pk?: Maybe<UserContacts>;
    /** delete data from the table: "_GroupToUserContact" */
    delete__GroupToUserContact?: Maybe<_GroupToUserContact_Mutation_Response>;
    /** delete single row from the table: "_GroupToUserContact" */
    delete__GroupToUserContact_by_pk?: Maybe<_GroupToUserContact>;
    /** insert data into the table: "AssistantSettings" */
    insert_AssistantSettings?: Maybe<AssistantSettings_Mutation_Response>;
    /** insert a single row into the table: "AssistantSettings" */
    insert_AssistantSettings_one?: Maybe<AssistantSettings>;
    /** insert data into the table: "Attachments" */
    insert_Attachments?: Maybe<Attachments_Mutation_Response>;
    /** insert a single row into the table: "Attachments" */
    insert_Attachments_one?: Maybe<Attachments>;
    /** insert data into the table: "ContactSuggestions" */
    insert_ContactSuggestions?: Maybe<ContactSuggestions_Mutation_Response>;
    /** insert a single row into the table: "ContactSuggestions" */
    insert_ContactSuggestions_one?: Maybe<ContactSuggestions>;
    /** insert data into the table: "ConversationMetaData" */
    insert_ConversationMetaData?: Maybe<ConversationMetaData_Mutation_Response>;
    /** insert a single row into the table: "ConversationMetaData" */
    insert_ConversationMetaData_one?: Maybe<ConversationMetaData>;
    /** insert data into the table: "Groups" */
    insert_Groups?: Maybe<Groups_Mutation_Response>;
    /** insert a single row into the table: "Groups" */
    insert_Groups_one?: Maybe<Groups>;
    /** insert data into the table: "LinkedinConversations" */
    insert_LinkedinConversations?: Maybe<LinkedinConversations_Mutation_Response>;
    /** insert a single row into the table: "LinkedinConversations" */
    insert_LinkedinConversations_one?: Maybe<LinkedinConversations>;
    /** insert data into the table: "Messages" */
    insert_Messages?: Maybe<Messages_Mutation_Response>;
    /** insert a single row into the table: "Messages" */
    insert_Messages_one?: Maybe<Messages>;
    /** insert data into the table: "Notes" */
    insert_Notes?: Maybe<Notes_Mutation_Response>;
    /** insert a single row into the table: "Notes" */
    insert_Notes_one?: Maybe<Notes>;
    /** insert data into the table: "OutreachCompletionMetrics" */
    insert_OutreachCompletionMetrics?: Maybe<OutreachCompletionMetrics_Mutation_Response>;
    /** insert a single row into the table: "OutreachCompletionMetrics" */
    insert_OutreachCompletionMetrics_one?: Maybe<OutreachCompletionMetrics>;
    /** insert data into the table: "OutreachSuggestions" */
    insert_OutreachSuggestions?: Maybe<OutreachSuggestions_Mutation_Response>;
    /** insert a single row into the table: "OutreachSuggestions" */
    insert_OutreachSuggestions_one?: Maybe<OutreachSuggestions>;
    /** insert data into the table: "UserContacts" */
    insert_UserContacts?: Maybe<UserContacts_Mutation_Response>;
    /** insert a single row into the table: "UserContacts" */
    insert_UserContacts_one?: Maybe<UserContacts>;
    /** insert data into the table: "_GroupToUserContact" */
    insert__GroupToUserContact?: Maybe<_GroupToUserContact_Mutation_Response>;
    /** insert a single row into the table: "_GroupToUserContact" */
    insert__GroupToUserContact_one?: Maybe<_GroupToUserContact>;
    /** update data of the table: "AssistantSettings" */
    update_AssistantSettings?: Maybe<AssistantSettings_Mutation_Response>;
    /** update single row of the table: "AssistantSettings" */
    update_AssistantSettings_by_pk?: Maybe<AssistantSettings>;
    /** update multiples rows of table: "AssistantSettings" */
    update_AssistantSettings_many?: Maybe<Array<Maybe<AssistantSettings_Mutation_Response>>>;
    /** update data of the table: "Attachments" */
    update_Attachments?: Maybe<Attachments_Mutation_Response>;
    /** update single row of the table: "Attachments" */
    update_Attachments_by_pk?: Maybe<Attachments>;
    /** update multiples rows of table: "Attachments" */
    update_Attachments_many?: Maybe<Array<Maybe<Attachments_Mutation_Response>>>;
    /** update data of the table: "ContactSuggestions" */
    update_ContactSuggestions?: Maybe<ContactSuggestions_Mutation_Response>;
    /** update single row of the table: "ContactSuggestions" */
    update_ContactSuggestions_by_pk?: Maybe<ContactSuggestions>;
    /** update multiples rows of table: "ContactSuggestions" */
    update_ContactSuggestions_many?: Maybe<Array<Maybe<ContactSuggestions_Mutation_Response>>>;
    /** update data of the table: "ConversationMetaData" */
    update_ConversationMetaData?: Maybe<ConversationMetaData_Mutation_Response>;
    /** update single row of the table: "ConversationMetaData" */
    update_ConversationMetaData_by_pk?: Maybe<ConversationMetaData>;
    /** update multiples rows of table: "ConversationMetaData" */
    update_ConversationMetaData_many?: Maybe<Array<Maybe<ConversationMetaData_Mutation_Response>>>;
    /** update data of the table: "Groups" */
    update_Groups?: Maybe<Groups_Mutation_Response>;
    /** update single row of the table: "Groups" */
    update_Groups_by_pk?: Maybe<Groups>;
    /** update multiples rows of table: "Groups" */
    update_Groups_many?: Maybe<Array<Maybe<Groups_Mutation_Response>>>;
    /** update data of the table: "LinkedinConversations" */
    update_LinkedinConversations?: Maybe<LinkedinConversations_Mutation_Response>;
    /** update single row of the table: "LinkedinConversations" */
    update_LinkedinConversations_by_pk?: Maybe<LinkedinConversations>;
    /** update multiples rows of table: "LinkedinConversations" */
    update_LinkedinConversations_many?: Maybe<Array<Maybe<LinkedinConversations_Mutation_Response>>>;
    /** update data of the table: "Messages" */
    update_Messages?: Maybe<Messages_Mutation_Response>;
    /** update single row of the table: "Messages" */
    update_Messages_by_pk?: Maybe<Messages>;
    /** update multiples rows of table: "Messages" */
    update_Messages_many?: Maybe<Array<Maybe<Messages_Mutation_Response>>>;
    /** update data of the table: "Notes" */
    update_Notes?: Maybe<Notes_Mutation_Response>;
    /** update single row of the table: "Notes" */
    update_Notes_by_pk?: Maybe<Notes>;
    /** update multiples rows of table: "Notes" */
    update_Notes_many?: Maybe<Array<Maybe<Notes_Mutation_Response>>>;
    /** update data of the table: "OutreachCompletionMetrics" */
    update_OutreachCompletionMetrics?: Maybe<OutreachCompletionMetrics_Mutation_Response>;
    /** update single row of the table: "OutreachCompletionMetrics" */
    update_OutreachCompletionMetrics_by_pk?: Maybe<OutreachCompletionMetrics>;
    /** update multiples rows of table: "OutreachCompletionMetrics" */
    update_OutreachCompletionMetrics_many?: Maybe<Array<Maybe<OutreachCompletionMetrics_Mutation_Response>>>;
    /** update data of the table: "OutreachSuggestions" */
    update_OutreachSuggestions?: Maybe<OutreachSuggestions_Mutation_Response>;
    /** update single row of the table: "OutreachSuggestions" */
    update_OutreachSuggestions_by_pk?: Maybe<OutreachSuggestions>;
    /** update multiples rows of table: "OutreachSuggestions" */
    update_OutreachSuggestions_many?: Maybe<Array<Maybe<OutreachSuggestions_Mutation_Response>>>;
    /** update data of the table: "UserContacts" */
    update_UserContacts?: Maybe<UserContacts_Mutation_Response>;
    /** update single row of the table: "UserContacts" */
    update_UserContacts_by_pk?: Maybe<UserContacts>;
    /** update multiples rows of table: "UserContacts" */
    update_UserContacts_many?: Maybe<Array<Maybe<UserContacts_Mutation_Response>>>;
    /** update data of the table: "_GroupToUserContact" */
    update__GroupToUserContact?: Maybe<_GroupToUserContact_Mutation_Response>;
    /** update single row of the table: "_GroupToUserContact" */
    update__GroupToUserContact_by_pk?: Maybe<_GroupToUserContact>;
    /** update multiples rows of table: "_GroupToUserContact" */
    update__GroupToUserContact_many?: Maybe<Array<Maybe<_GroupToUserContact_Mutation_Response>>>;
};

/** mutation root */
export type Mutation_RootDelete_AssistantSettingsArgs = {
    where: AssistantSettings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_AssistantSettings_By_PkArgs = {
    id: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootDelete_AttachmentsArgs = {
    where: Attachments_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Attachments_By_PkArgs = {
    id: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootDelete_ContactSuggestionsArgs = {
    where: ContactSuggestions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ContactSuggestions_By_PkArgs = {
    id: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootDelete_ConversationMetaDataArgs = {
    where: ConversationMetaData_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ConversationMetaData_By_PkArgs = {
    id: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootDelete_GroupsArgs = {
    where: Groups_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Groups_By_PkArgs = {
    id: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootDelete_LinkedinConversationsArgs = {
    where: LinkedinConversations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_LinkedinConversations_By_PkArgs = {
    id: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootDelete_MessagesArgs = {
    where: Messages_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Messages_By_PkArgs = {
    id: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootDelete_NotesArgs = {
    where: Notes_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Notes_By_PkArgs = {
    id: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootDelete_OutreachCompletionMetricsArgs = {
    where: OutreachCompletionMetrics_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_OutreachCompletionMetrics_By_PkArgs = {
    id: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootDelete_OutreachSuggestionsArgs = {
    where: OutreachSuggestions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_OutreachSuggestions_By_PkArgs = {
    id: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootDelete_UserContactsArgs = {
    where: UserContacts_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_UserContacts_By_PkArgs = {
    id: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootDelete__GroupToUserContactArgs = {
    where: _GroupToUserContact_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete__GroupToUserContact_By_PkArgs = {
    A: Scalars['String']['input'];
    B: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootInsert_AssistantSettingsArgs = {
    objects: Array<AssistantSettings_Insert_Input>;
    on_conflict?: InputMaybe<AssistantSettings_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_AssistantSettings_OneArgs = {
    object: AssistantSettings_Insert_Input;
    on_conflict?: InputMaybe<AssistantSettings_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_AttachmentsArgs = {
    objects: Array<Attachments_Insert_Input>;
    on_conflict?: InputMaybe<Attachments_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Attachments_OneArgs = {
    object: Attachments_Insert_Input;
    on_conflict?: InputMaybe<Attachments_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ContactSuggestionsArgs = {
    objects: Array<ContactSuggestions_Insert_Input>;
    on_conflict?: InputMaybe<ContactSuggestions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ContactSuggestions_OneArgs = {
    object: ContactSuggestions_Insert_Input;
    on_conflict?: InputMaybe<ContactSuggestions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ConversationMetaDataArgs = {
    objects: Array<ConversationMetaData_Insert_Input>;
    on_conflict?: InputMaybe<ConversationMetaData_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ConversationMetaData_OneArgs = {
    object: ConversationMetaData_Insert_Input;
    on_conflict?: InputMaybe<ConversationMetaData_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_GroupsArgs = {
    objects: Array<Groups_Insert_Input>;
    on_conflict?: InputMaybe<Groups_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Groups_OneArgs = {
    object: Groups_Insert_Input;
    on_conflict?: InputMaybe<Groups_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_LinkedinConversationsArgs = {
    objects: Array<LinkedinConversations_Insert_Input>;
    on_conflict?: InputMaybe<LinkedinConversations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_LinkedinConversations_OneArgs = {
    object: LinkedinConversations_Insert_Input;
    on_conflict?: InputMaybe<LinkedinConversations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_MessagesArgs = {
    objects: Array<Messages_Insert_Input>;
    on_conflict?: InputMaybe<Messages_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Messages_OneArgs = {
    object: Messages_Insert_Input;
    on_conflict?: InputMaybe<Messages_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_NotesArgs = {
    objects: Array<Notes_Insert_Input>;
    on_conflict?: InputMaybe<Notes_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Notes_OneArgs = {
    object: Notes_Insert_Input;
    on_conflict?: InputMaybe<Notes_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_OutreachCompletionMetricsArgs = {
    objects: Array<OutreachCompletionMetrics_Insert_Input>;
    on_conflict?: InputMaybe<OutreachCompletionMetrics_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_OutreachCompletionMetrics_OneArgs = {
    object: OutreachCompletionMetrics_Insert_Input;
    on_conflict?: InputMaybe<OutreachCompletionMetrics_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_OutreachSuggestionsArgs = {
    objects: Array<OutreachSuggestions_Insert_Input>;
    on_conflict?: InputMaybe<OutreachSuggestions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_OutreachSuggestions_OneArgs = {
    object: OutreachSuggestions_Insert_Input;
    on_conflict?: InputMaybe<OutreachSuggestions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_UserContactsArgs = {
    objects: Array<UserContacts_Insert_Input>;
    on_conflict?: InputMaybe<UserContacts_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_UserContacts_OneArgs = {
    object: UserContacts_Insert_Input;
    on_conflict?: InputMaybe<UserContacts_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert__GroupToUserContactArgs = {
    objects: Array<_GroupToUserContact_Insert_Input>;
    on_conflict?: InputMaybe<_GroupToUserContact_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert__GroupToUserContact_OneArgs = {
    object: _GroupToUserContact_Insert_Input;
    on_conflict?: InputMaybe<_GroupToUserContact_On_Conflict>;
};

/** mutation root */
export type Mutation_RootUpdate_AssistantSettingsArgs = {
    _set?: InputMaybe<AssistantSettings_Set_Input>;
    where: AssistantSettings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_AssistantSettings_By_PkArgs = {
    _set?: InputMaybe<AssistantSettings_Set_Input>;
    pk_columns: AssistantSettings_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_AssistantSettings_ManyArgs = {
    updates: Array<AssistantSettings_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_AttachmentsArgs = {
    _set?: InputMaybe<Attachments_Set_Input>;
    where: Attachments_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Attachments_By_PkArgs = {
    _set?: InputMaybe<Attachments_Set_Input>;
    pk_columns: Attachments_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Attachments_ManyArgs = {
    updates: Array<Attachments_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ContactSuggestionsArgs = {
    _set?: InputMaybe<ContactSuggestions_Set_Input>;
    where: ContactSuggestions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ContactSuggestions_By_PkArgs = {
    _set?: InputMaybe<ContactSuggestions_Set_Input>;
    pk_columns: ContactSuggestions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ContactSuggestions_ManyArgs = {
    updates: Array<ContactSuggestions_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ConversationMetaDataArgs = {
    _inc?: InputMaybe<ConversationMetaData_Inc_Input>;
    _set?: InputMaybe<ConversationMetaData_Set_Input>;
    where: ConversationMetaData_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_ConversationMetaData_By_PkArgs = {
    _inc?: InputMaybe<ConversationMetaData_Inc_Input>;
    _set?: InputMaybe<ConversationMetaData_Set_Input>;
    pk_columns: ConversationMetaData_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_ConversationMetaData_ManyArgs = {
    updates: Array<ConversationMetaData_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_GroupsArgs = {
    _set?: InputMaybe<Groups_Set_Input>;
    where: Groups_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Groups_By_PkArgs = {
    _set?: InputMaybe<Groups_Set_Input>;
    pk_columns: Groups_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Groups_ManyArgs = {
    updates: Array<Groups_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_LinkedinConversationsArgs = {
    _set?: InputMaybe<LinkedinConversations_Set_Input>;
    where: LinkedinConversations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_LinkedinConversations_By_PkArgs = {
    _set?: InputMaybe<LinkedinConversations_Set_Input>;
    pk_columns: LinkedinConversations_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_LinkedinConversations_ManyArgs = {
    updates: Array<LinkedinConversations_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_MessagesArgs = {
    _set?: InputMaybe<Messages_Set_Input>;
    where: Messages_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Messages_By_PkArgs = {
    _set?: InputMaybe<Messages_Set_Input>;
    pk_columns: Messages_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Messages_ManyArgs = {
    updates: Array<Messages_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_NotesArgs = {
    _set?: InputMaybe<Notes_Set_Input>;
    where: Notes_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Notes_By_PkArgs = {
    _set?: InputMaybe<Notes_Set_Input>;
    pk_columns: Notes_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Notes_ManyArgs = {
    updates: Array<Notes_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_OutreachCompletionMetricsArgs = {
    _inc?: InputMaybe<OutreachCompletionMetrics_Inc_Input>;
    _set?: InputMaybe<OutreachCompletionMetrics_Set_Input>;
    where: OutreachCompletionMetrics_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_OutreachCompletionMetrics_By_PkArgs = {
    _inc?: InputMaybe<OutreachCompletionMetrics_Inc_Input>;
    _set?: InputMaybe<OutreachCompletionMetrics_Set_Input>;
    pk_columns: OutreachCompletionMetrics_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_OutreachCompletionMetrics_ManyArgs = {
    updates: Array<OutreachCompletionMetrics_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_OutreachSuggestionsArgs = {
    _inc?: InputMaybe<OutreachSuggestions_Inc_Input>;
    _set?: InputMaybe<OutreachSuggestions_Set_Input>;
    where: OutreachSuggestions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_OutreachSuggestions_By_PkArgs = {
    _inc?: InputMaybe<OutreachSuggestions_Inc_Input>;
    _set?: InputMaybe<OutreachSuggestions_Set_Input>;
    pk_columns: OutreachSuggestions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_OutreachSuggestions_ManyArgs = {
    updates: Array<OutreachSuggestions_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_UserContactsArgs = {
    _set?: InputMaybe<UserContacts_Set_Input>;
    where: UserContacts_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_UserContacts_By_PkArgs = {
    _set?: InputMaybe<UserContacts_Set_Input>;
    pk_columns: UserContacts_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_UserContacts_ManyArgs = {
    updates: Array<UserContacts_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate__GroupToUserContactArgs = {
    _set?: InputMaybe<_GroupToUserContact_Set_Input>;
    where: _GroupToUserContact_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate__GroupToUserContact_By_PkArgs = {
    _set?: InputMaybe<_GroupToUserContact_Set_Input>;
    pk_columns: _GroupToUserContact_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate__GroupToUserContact_ManyArgs = {
    updates: Array<_GroupToUserContact_Updates>;
};

/** column ordering options */
export enum Order_By {
    /** in ascending order, nulls last */
    Asc = 'asc',
    /** in ascending order, nulls first */
    AscNullsFirst = 'asc_nulls_first',
    /** in ascending order, nulls last */
    AscNullsLast = 'asc_nulls_last',
    /** in descending order, nulls first */
    Desc = 'desc',
    /** in descending order, nulls first */
    DescNullsFirst = 'desc_nulls_first',
    /** in descending order, nulls last */
    DescNullsLast = 'desc_nulls_last',
}

export type Query_Root = {
    __typename?: 'query_root';
    /** fetch data from the table: "AssistantSettings" */
    AssistantSettings: Array<AssistantSettings>;
    /** fetch aggregated fields from the table: "AssistantSettings" */
    AssistantSettings_aggregate: AssistantSettings_Aggregate;
    /** fetch data from the table: "AssistantSettings" using primary key columns */
    AssistantSettings_by_pk?: Maybe<AssistantSettings>;
    /** An array relationship */
    Attachments: Array<Attachments>;
    /** An aggregate relationship */
    Attachments_aggregate: Attachments_Aggregate;
    /** fetch data from the table: "Attachments" using primary key columns */
    Attachments_by_pk?: Maybe<Attachments>;
    /** fetch data from the table: "ContactSuggestions" */
    ContactSuggestions: Array<ContactSuggestions>;
    /** fetch aggregated fields from the table: "ContactSuggestions" */
    ContactSuggestions_aggregate: ContactSuggestions_Aggregate;
    /** fetch data from the table: "ContactSuggestions" using primary key columns */
    ContactSuggestions_by_pk?: Maybe<ContactSuggestions>;
    /** fetch data from the table: "ConversationMetaData" */
    ConversationMetaData: Array<ConversationMetaData>;
    /** fetch aggregated fields from the table: "ConversationMetaData" */
    ConversationMetaData_aggregate: ConversationMetaData_Aggregate;
    /** fetch data from the table: "ConversationMetaData" using primary key columns */
    ConversationMetaData_by_pk?: Maybe<ConversationMetaData>;
    /** An array relationship */
    Groups: Array<Groups>;
    /** An aggregate relationship */
    Groups_aggregate: Groups_Aggregate;
    /** fetch data from the table: "Groups" using primary key columns */
    Groups_by_pk?: Maybe<Groups>;
    /** fetch data from the table: "LinkedinConversations" */
    LinkedinConversations: Array<LinkedinConversations>;
    /** fetch aggregated fields from the table: "LinkedinConversations" */
    LinkedinConversations_aggregate: LinkedinConversations_Aggregate;
    /** fetch data from the table: "LinkedinConversations" using primary key columns */
    LinkedinConversations_by_pk?: Maybe<LinkedinConversations>;
    /** fetch data from the table: "Messages" */
    Messages: Array<Messages>;
    /** fetch aggregated fields from the table: "Messages" */
    Messages_aggregate: Messages_Aggregate;
    /** fetch data from the table: "Messages" using primary key columns */
    Messages_by_pk?: Maybe<Messages>;
    /** fetch data from the table: "Notes" */
    Notes: Array<Notes>;
    /** fetch aggregated fields from the table: "Notes" */
    Notes_aggregate: Notes_Aggregate;
    /** fetch data from the table: "Notes" using primary key columns */
    Notes_by_pk?: Maybe<Notes>;
    /** fetch data from the table: "OutreachCompletionMetrics" */
    OutreachCompletionMetrics: Array<OutreachCompletionMetrics>;
    /** fetch aggregated fields from the table: "OutreachCompletionMetrics" */
    OutreachCompletionMetrics_aggregate: OutreachCompletionMetrics_Aggregate;
    /** fetch data from the table: "OutreachCompletionMetrics" using primary key columns */
    OutreachCompletionMetrics_by_pk?: Maybe<OutreachCompletionMetrics>;
    /** An array relationship */
    OutreachSuggestions: Array<OutreachSuggestions>;
    /** An aggregate relationship */
    OutreachSuggestions_aggregate: OutreachSuggestions_Aggregate;
    /** fetch data from the table: "OutreachSuggestions" using primary key columns */
    OutreachSuggestions_by_pk?: Maybe<OutreachSuggestions>;
    /** fetch data from the table: "UserContacts" */
    UserContacts: Array<UserContacts>;
    /** fetch aggregated fields from the table: "UserContacts" */
    UserContacts_aggregate: UserContacts_Aggregate;
    /** fetch data from the table: "UserContacts" using primary key columns */
    UserContacts_by_pk?: Maybe<UserContacts>;
    /** fetch data from the table: "_GroupToUserContact" */
    _GroupToUserContact: Array<_GroupToUserContact>;
    /** fetch aggregated fields from the table: "_GroupToUserContact" */
    _GroupToUserContact_aggregate: _GroupToUserContact_Aggregate;
    /** fetch data from the table: "_GroupToUserContact" using primary key columns */
    _GroupToUserContact_by_pk?: Maybe<_GroupToUserContact>;
};

export type Query_RootAssistantSettingsArgs = {
    distinct_on?: InputMaybe<Array<AssistantSettings_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<AssistantSettings_Order_By>>;
    where?: InputMaybe<AssistantSettings_Bool_Exp>;
};

export type Query_RootAssistantSettings_AggregateArgs = {
    distinct_on?: InputMaybe<Array<AssistantSettings_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<AssistantSettings_Order_By>>;
    where?: InputMaybe<AssistantSettings_Bool_Exp>;
};

export type Query_RootAssistantSettings_By_PkArgs = {
    id: Scalars['String']['input'];
};

export type Query_RootAttachmentsArgs = {
    distinct_on?: InputMaybe<Array<Attachments_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<Attachments_Order_By>>;
    where?: InputMaybe<Attachments_Bool_Exp>;
};

export type Query_RootAttachments_AggregateArgs = {
    distinct_on?: InputMaybe<Array<Attachments_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<Attachments_Order_By>>;
    where?: InputMaybe<Attachments_Bool_Exp>;
};

export type Query_RootAttachments_By_PkArgs = {
    id: Scalars['String']['input'];
};

export type Query_RootContactSuggestionsArgs = {
    distinct_on?: InputMaybe<Array<ContactSuggestions_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<ContactSuggestions_Order_By>>;
    where?: InputMaybe<ContactSuggestions_Bool_Exp>;
};

export type Query_RootContactSuggestions_AggregateArgs = {
    distinct_on?: InputMaybe<Array<ContactSuggestions_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<ContactSuggestions_Order_By>>;
    where?: InputMaybe<ContactSuggestions_Bool_Exp>;
};

export type Query_RootContactSuggestions_By_PkArgs = {
    id: Scalars['String']['input'];
};

export type Query_RootConversationMetaDataArgs = {
    distinct_on?: InputMaybe<Array<ConversationMetaData_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<ConversationMetaData_Order_By>>;
    where?: InputMaybe<ConversationMetaData_Bool_Exp>;
};

export type Query_RootConversationMetaData_AggregateArgs = {
    distinct_on?: InputMaybe<Array<ConversationMetaData_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<ConversationMetaData_Order_By>>;
    where?: InputMaybe<ConversationMetaData_Bool_Exp>;
};

export type Query_RootConversationMetaData_By_PkArgs = {
    id: Scalars['String']['input'];
};

export type Query_RootGroupsArgs = {
    distinct_on?: InputMaybe<Array<Groups_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<Groups_Order_By>>;
    where?: InputMaybe<Groups_Bool_Exp>;
};

export type Query_RootGroups_AggregateArgs = {
    distinct_on?: InputMaybe<Array<Groups_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<Groups_Order_By>>;
    where?: InputMaybe<Groups_Bool_Exp>;
};

export type Query_RootGroups_By_PkArgs = {
    id: Scalars['String']['input'];
};

export type Query_RootLinkedinConversationsArgs = {
    distinct_on?: InputMaybe<Array<LinkedinConversations_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<LinkedinConversations_Order_By>>;
    where?: InputMaybe<LinkedinConversations_Bool_Exp>;
};

export type Query_RootLinkedinConversations_AggregateArgs = {
    distinct_on?: InputMaybe<Array<LinkedinConversations_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<LinkedinConversations_Order_By>>;
    where?: InputMaybe<LinkedinConversations_Bool_Exp>;
};

export type Query_RootLinkedinConversations_By_PkArgs = {
    id: Scalars['String']['input'];
};

export type Query_RootMessagesArgs = {
    distinct_on?: InputMaybe<Array<Messages_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<Messages_Order_By>>;
    where?: InputMaybe<Messages_Bool_Exp>;
};

export type Query_RootMessages_AggregateArgs = {
    distinct_on?: InputMaybe<Array<Messages_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<Messages_Order_By>>;
    where?: InputMaybe<Messages_Bool_Exp>;
};

export type Query_RootMessages_By_PkArgs = {
    id: Scalars['String']['input'];
};

export type Query_RootNotesArgs = {
    distinct_on?: InputMaybe<Array<Notes_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<Notes_Order_By>>;
    where?: InputMaybe<Notes_Bool_Exp>;
};

export type Query_RootNotes_AggregateArgs = {
    distinct_on?: InputMaybe<Array<Notes_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<Notes_Order_By>>;
    where?: InputMaybe<Notes_Bool_Exp>;
};

export type Query_RootNotes_By_PkArgs = {
    id: Scalars['String']['input'];
};

export type Query_RootOutreachCompletionMetricsArgs = {
    distinct_on?: InputMaybe<Array<OutreachCompletionMetrics_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<OutreachCompletionMetrics_Order_By>>;
    where?: InputMaybe<OutreachCompletionMetrics_Bool_Exp>;
};

export type Query_RootOutreachCompletionMetrics_AggregateArgs = {
    distinct_on?: InputMaybe<Array<OutreachCompletionMetrics_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<OutreachCompletionMetrics_Order_By>>;
    where?: InputMaybe<OutreachCompletionMetrics_Bool_Exp>;
};

export type Query_RootOutreachCompletionMetrics_By_PkArgs = {
    id: Scalars['String']['input'];
};

export type Query_RootOutreachSuggestionsArgs = {
    distinct_on?: InputMaybe<Array<OutreachSuggestions_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<OutreachSuggestions_Order_By>>;
    where?: InputMaybe<OutreachSuggestions_Bool_Exp>;
};

export type Query_RootOutreachSuggestions_AggregateArgs = {
    distinct_on?: InputMaybe<Array<OutreachSuggestions_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<OutreachSuggestions_Order_By>>;
    where?: InputMaybe<OutreachSuggestions_Bool_Exp>;
};

export type Query_RootOutreachSuggestions_By_PkArgs = {
    id: Scalars['String']['input'];
};

export type Query_RootUserContactsArgs = {
    distinct_on?: InputMaybe<Array<UserContacts_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<UserContacts_Order_By>>;
    where?: InputMaybe<UserContacts_Bool_Exp>;
};

export type Query_RootUserContacts_AggregateArgs = {
    distinct_on?: InputMaybe<Array<UserContacts_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<UserContacts_Order_By>>;
    where?: InputMaybe<UserContacts_Bool_Exp>;
};

export type Query_RootUserContacts_By_PkArgs = {
    id: Scalars['String']['input'];
};

export type Query_Root_GroupToUserContactArgs = {
    distinct_on?: InputMaybe<Array<_GroupToUserContact_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<_GroupToUserContact_Order_By>>;
    where?: InputMaybe<_GroupToUserContact_Bool_Exp>;
};

export type Query_Root_GroupToUserContact_AggregateArgs = {
    distinct_on?: InputMaybe<Array<_GroupToUserContact_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<_GroupToUserContact_Order_By>>;
    where?: InputMaybe<_GroupToUserContact_Bool_Exp>;
};

export type Query_Root_GroupToUserContact_By_PkArgs = {
    A: Scalars['String']['input'];
    B: Scalars['String']['input'];
};

export type Subscription_Root = {
    __typename?: 'subscription_root';
    /** fetch data from the table: "AssistantSettings" */
    AssistantSettings: Array<AssistantSettings>;
    /** fetch aggregated fields from the table: "AssistantSettings" */
    AssistantSettings_aggregate: AssistantSettings_Aggregate;
    /** fetch data from the table: "AssistantSettings" using primary key columns */
    AssistantSettings_by_pk?: Maybe<AssistantSettings>;
    /** fetch data from the table in a streaming manner: "AssistantSettings" */
    AssistantSettings_stream: Array<AssistantSettings>;
    /** An array relationship */
    Attachments: Array<Attachments>;
    /** An aggregate relationship */
    Attachments_aggregate: Attachments_Aggregate;
    /** fetch data from the table: "Attachments" using primary key columns */
    Attachments_by_pk?: Maybe<Attachments>;
    /** fetch data from the table in a streaming manner: "Attachments" */
    Attachments_stream: Array<Attachments>;
    /** fetch data from the table: "ContactSuggestions" */
    ContactSuggestions: Array<ContactSuggestions>;
    /** fetch aggregated fields from the table: "ContactSuggestions" */
    ContactSuggestions_aggregate: ContactSuggestions_Aggregate;
    /** fetch data from the table: "ContactSuggestions" using primary key columns */
    ContactSuggestions_by_pk?: Maybe<ContactSuggestions>;
    /** fetch data from the table in a streaming manner: "ContactSuggestions" */
    ContactSuggestions_stream: Array<ContactSuggestions>;
    /** fetch data from the table: "ConversationMetaData" */
    ConversationMetaData: Array<ConversationMetaData>;
    /** fetch aggregated fields from the table: "ConversationMetaData" */
    ConversationMetaData_aggregate: ConversationMetaData_Aggregate;
    /** fetch data from the table: "ConversationMetaData" using primary key columns */
    ConversationMetaData_by_pk?: Maybe<ConversationMetaData>;
    /** fetch data from the table in a streaming manner: "ConversationMetaData" */
    ConversationMetaData_stream: Array<ConversationMetaData>;
    /** An array relationship */
    Groups: Array<Groups>;
    /** An aggregate relationship */
    Groups_aggregate: Groups_Aggregate;
    /** fetch data from the table: "Groups" using primary key columns */
    Groups_by_pk?: Maybe<Groups>;
    /** fetch data from the table in a streaming manner: "Groups" */
    Groups_stream: Array<Groups>;
    /** fetch data from the table: "LinkedinConversations" */
    LinkedinConversations: Array<LinkedinConversations>;
    /** fetch aggregated fields from the table: "LinkedinConversations" */
    LinkedinConversations_aggregate: LinkedinConversations_Aggregate;
    /** fetch data from the table: "LinkedinConversations" using primary key columns */
    LinkedinConversations_by_pk?: Maybe<LinkedinConversations>;
    /** fetch data from the table in a streaming manner: "LinkedinConversations" */
    LinkedinConversations_stream: Array<LinkedinConversations>;
    /** fetch data from the table: "Messages" */
    Messages: Array<Messages>;
    /** fetch aggregated fields from the table: "Messages" */
    Messages_aggregate: Messages_Aggregate;
    /** fetch data from the table: "Messages" using primary key columns */
    Messages_by_pk?: Maybe<Messages>;
    /** fetch data from the table in a streaming manner: "Messages" */
    Messages_stream: Array<Messages>;
    /** fetch data from the table: "Notes" */
    Notes: Array<Notes>;
    /** fetch aggregated fields from the table: "Notes" */
    Notes_aggregate: Notes_Aggregate;
    /** fetch data from the table: "Notes" using primary key columns */
    Notes_by_pk?: Maybe<Notes>;
    /** fetch data from the table in a streaming manner: "Notes" */
    Notes_stream: Array<Notes>;
    /** fetch data from the table: "OutreachCompletionMetrics" */
    OutreachCompletionMetrics: Array<OutreachCompletionMetrics>;
    /** fetch aggregated fields from the table: "OutreachCompletionMetrics" */
    OutreachCompletionMetrics_aggregate: OutreachCompletionMetrics_Aggregate;
    /** fetch data from the table: "OutreachCompletionMetrics" using primary key columns */
    OutreachCompletionMetrics_by_pk?: Maybe<OutreachCompletionMetrics>;
    /** fetch data from the table in a streaming manner: "OutreachCompletionMetrics" */
    OutreachCompletionMetrics_stream: Array<OutreachCompletionMetrics>;
    /** An array relationship */
    OutreachSuggestions: Array<OutreachSuggestions>;
    /** An aggregate relationship */
    OutreachSuggestions_aggregate: OutreachSuggestions_Aggregate;
    /** fetch data from the table: "OutreachSuggestions" using primary key columns */
    OutreachSuggestions_by_pk?: Maybe<OutreachSuggestions>;
    /** fetch data from the table in a streaming manner: "OutreachSuggestions" */
    OutreachSuggestions_stream: Array<OutreachSuggestions>;
    /** fetch data from the table: "UserContacts" */
    UserContacts: Array<UserContacts>;
    /** fetch aggregated fields from the table: "UserContacts" */
    UserContacts_aggregate: UserContacts_Aggregate;
    /** fetch data from the table: "UserContacts" using primary key columns */
    UserContacts_by_pk?: Maybe<UserContacts>;
    /** fetch data from the table in a streaming manner: "UserContacts" */
    UserContacts_stream: Array<UserContacts>;
    /** fetch data from the table: "_GroupToUserContact" */
    _GroupToUserContact: Array<_GroupToUserContact>;
    /** fetch aggregated fields from the table: "_GroupToUserContact" */
    _GroupToUserContact_aggregate: _GroupToUserContact_Aggregate;
    /** fetch data from the table: "_GroupToUserContact" using primary key columns */
    _GroupToUserContact_by_pk?: Maybe<_GroupToUserContact>;
    /** fetch data from the table in a streaming manner: "_GroupToUserContact" */
    _GroupToUserContact_stream: Array<_GroupToUserContact>;
};

export type Subscription_RootAssistantSettingsArgs = {
    distinct_on?: InputMaybe<Array<AssistantSettings_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<AssistantSettings_Order_By>>;
    where?: InputMaybe<AssistantSettings_Bool_Exp>;
};

export type Subscription_RootAssistantSettings_AggregateArgs = {
    distinct_on?: InputMaybe<Array<AssistantSettings_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<AssistantSettings_Order_By>>;
    where?: InputMaybe<AssistantSettings_Bool_Exp>;
};

export type Subscription_RootAssistantSettings_By_PkArgs = {
    id: Scalars['String']['input'];
};

export type Subscription_RootAssistantSettings_StreamArgs = {
    batch_size: Scalars['Int']['input'];
    cursor: Array<InputMaybe<AssistantSettings_Stream_Cursor_Input>>;
    where?: InputMaybe<AssistantSettings_Bool_Exp>;
};

export type Subscription_RootAttachmentsArgs = {
    distinct_on?: InputMaybe<Array<Attachments_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<Attachments_Order_By>>;
    where?: InputMaybe<Attachments_Bool_Exp>;
};

export type Subscription_RootAttachments_AggregateArgs = {
    distinct_on?: InputMaybe<Array<Attachments_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<Attachments_Order_By>>;
    where?: InputMaybe<Attachments_Bool_Exp>;
};

export type Subscription_RootAttachments_By_PkArgs = {
    id: Scalars['String']['input'];
};

export type Subscription_RootAttachments_StreamArgs = {
    batch_size: Scalars['Int']['input'];
    cursor: Array<InputMaybe<Attachments_Stream_Cursor_Input>>;
    where?: InputMaybe<Attachments_Bool_Exp>;
};

export type Subscription_RootContactSuggestionsArgs = {
    distinct_on?: InputMaybe<Array<ContactSuggestions_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<ContactSuggestions_Order_By>>;
    where?: InputMaybe<ContactSuggestions_Bool_Exp>;
};

export type Subscription_RootContactSuggestions_AggregateArgs = {
    distinct_on?: InputMaybe<Array<ContactSuggestions_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<ContactSuggestions_Order_By>>;
    where?: InputMaybe<ContactSuggestions_Bool_Exp>;
};

export type Subscription_RootContactSuggestions_By_PkArgs = {
    id: Scalars['String']['input'];
};

export type Subscription_RootContactSuggestions_StreamArgs = {
    batch_size: Scalars['Int']['input'];
    cursor: Array<InputMaybe<ContactSuggestions_Stream_Cursor_Input>>;
    where?: InputMaybe<ContactSuggestions_Bool_Exp>;
};

export type Subscription_RootConversationMetaDataArgs = {
    distinct_on?: InputMaybe<Array<ConversationMetaData_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<ConversationMetaData_Order_By>>;
    where?: InputMaybe<ConversationMetaData_Bool_Exp>;
};

export type Subscription_RootConversationMetaData_AggregateArgs = {
    distinct_on?: InputMaybe<Array<ConversationMetaData_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<ConversationMetaData_Order_By>>;
    where?: InputMaybe<ConversationMetaData_Bool_Exp>;
};

export type Subscription_RootConversationMetaData_By_PkArgs = {
    id: Scalars['String']['input'];
};

export type Subscription_RootConversationMetaData_StreamArgs = {
    batch_size: Scalars['Int']['input'];
    cursor: Array<InputMaybe<ConversationMetaData_Stream_Cursor_Input>>;
    where?: InputMaybe<ConversationMetaData_Bool_Exp>;
};

export type Subscription_RootGroupsArgs = {
    distinct_on?: InputMaybe<Array<Groups_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<Groups_Order_By>>;
    where?: InputMaybe<Groups_Bool_Exp>;
};

export type Subscription_RootGroups_AggregateArgs = {
    distinct_on?: InputMaybe<Array<Groups_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<Groups_Order_By>>;
    where?: InputMaybe<Groups_Bool_Exp>;
};

export type Subscription_RootGroups_By_PkArgs = {
    id: Scalars['String']['input'];
};

export type Subscription_RootGroups_StreamArgs = {
    batch_size: Scalars['Int']['input'];
    cursor: Array<InputMaybe<Groups_Stream_Cursor_Input>>;
    where?: InputMaybe<Groups_Bool_Exp>;
};

export type Subscription_RootLinkedinConversationsArgs = {
    distinct_on?: InputMaybe<Array<LinkedinConversations_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<LinkedinConversations_Order_By>>;
    where?: InputMaybe<LinkedinConversations_Bool_Exp>;
};

export type Subscription_RootLinkedinConversations_AggregateArgs = {
    distinct_on?: InputMaybe<Array<LinkedinConversations_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<LinkedinConversations_Order_By>>;
    where?: InputMaybe<LinkedinConversations_Bool_Exp>;
};

export type Subscription_RootLinkedinConversations_By_PkArgs = {
    id: Scalars['String']['input'];
};

export type Subscription_RootLinkedinConversations_StreamArgs = {
    batch_size: Scalars['Int']['input'];
    cursor: Array<InputMaybe<LinkedinConversations_Stream_Cursor_Input>>;
    where?: InputMaybe<LinkedinConversations_Bool_Exp>;
};

export type Subscription_RootMessagesArgs = {
    distinct_on?: InputMaybe<Array<Messages_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<Messages_Order_By>>;
    where?: InputMaybe<Messages_Bool_Exp>;
};

export type Subscription_RootMessages_AggregateArgs = {
    distinct_on?: InputMaybe<Array<Messages_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<Messages_Order_By>>;
    where?: InputMaybe<Messages_Bool_Exp>;
};

export type Subscription_RootMessages_By_PkArgs = {
    id: Scalars['String']['input'];
};

export type Subscription_RootMessages_StreamArgs = {
    batch_size: Scalars['Int']['input'];
    cursor: Array<InputMaybe<Messages_Stream_Cursor_Input>>;
    where?: InputMaybe<Messages_Bool_Exp>;
};

export type Subscription_RootNotesArgs = {
    distinct_on?: InputMaybe<Array<Notes_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<Notes_Order_By>>;
    where?: InputMaybe<Notes_Bool_Exp>;
};

export type Subscription_RootNotes_AggregateArgs = {
    distinct_on?: InputMaybe<Array<Notes_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<Notes_Order_By>>;
    where?: InputMaybe<Notes_Bool_Exp>;
};

export type Subscription_RootNotes_By_PkArgs = {
    id: Scalars['String']['input'];
};

export type Subscription_RootNotes_StreamArgs = {
    batch_size: Scalars['Int']['input'];
    cursor: Array<InputMaybe<Notes_Stream_Cursor_Input>>;
    where?: InputMaybe<Notes_Bool_Exp>;
};

export type Subscription_RootOutreachCompletionMetricsArgs = {
    distinct_on?: InputMaybe<Array<OutreachCompletionMetrics_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<OutreachCompletionMetrics_Order_By>>;
    where?: InputMaybe<OutreachCompletionMetrics_Bool_Exp>;
};

export type Subscription_RootOutreachCompletionMetrics_AggregateArgs = {
    distinct_on?: InputMaybe<Array<OutreachCompletionMetrics_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<OutreachCompletionMetrics_Order_By>>;
    where?: InputMaybe<OutreachCompletionMetrics_Bool_Exp>;
};

export type Subscription_RootOutreachCompletionMetrics_By_PkArgs = {
    id: Scalars['String']['input'];
};

export type Subscription_RootOutreachCompletionMetrics_StreamArgs = {
    batch_size: Scalars['Int']['input'];
    cursor: Array<InputMaybe<OutreachCompletionMetrics_Stream_Cursor_Input>>;
    where?: InputMaybe<OutreachCompletionMetrics_Bool_Exp>;
};

export type Subscription_RootOutreachSuggestionsArgs = {
    distinct_on?: InputMaybe<Array<OutreachSuggestions_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<OutreachSuggestions_Order_By>>;
    where?: InputMaybe<OutreachSuggestions_Bool_Exp>;
};

export type Subscription_RootOutreachSuggestions_AggregateArgs = {
    distinct_on?: InputMaybe<Array<OutreachSuggestions_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<OutreachSuggestions_Order_By>>;
    where?: InputMaybe<OutreachSuggestions_Bool_Exp>;
};

export type Subscription_RootOutreachSuggestions_By_PkArgs = {
    id: Scalars['String']['input'];
};

export type Subscription_RootOutreachSuggestions_StreamArgs = {
    batch_size: Scalars['Int']['input'];
    cursor: Array<InputMaybe<OutreachSuggestions_Stream_Cursor_Input>>;
    where?: InputMaybe<OutreachSuggestions_Bool_Exp>;
};

export type Subscription_RootUserContactsArgs = {
    distinct_on?: InputMaybe<Array<UserContacts_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<UserContacts_Order_By>>;
    where?: InputMaybe<UserContacts_Bool_Exp>;
};

export type Subscription_RootUserContacts_AggregateArgs = {
    distinct_on?: InputMaybe<Array<UserContacts_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<UserContacts_Order_By>>;
    where?: InputMaybe<UserContacts_Bool_Exp>;
};

export type Subscription_RootUserContacts_By_PkArgs = {
    id: Scalars['String']['input'];
};

export type Subscription_RootUserContacts_StreamArgs = {
    batch_size: Scalars['Int']['input'];
    cursor: Array<InputMaybe<UserContacts_Stream_Cursor_Input>>;
    where?: InputMaybe<UserContacts_Bool_Exp>;
};

export type Subscription_Root_GroupToUserContactArgs = {
    distinct_on?: InputMaybe<Array<_GroupToUserContact_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<_GroupToUserContact_Order_By>>;
    where?: InputMaybe<_GroupToUserContact_Bool_Exp>;
};

export type Subscription_Root_GroupToUserContact_AggregateArgs = {
    distinct_on?: InputMaybe<Array<_GroupToUserContact_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<_GroupToUserContact_Order_By>>;
    where?: InputMaybe<_GroupToUserContact_Bool_Exp>;
};

export type Subscription_Root_GroupToUserContact_By_PkArgs = {
    A: Scalars['String']['input'];
    B: Scalars['String']['input'];
};

export type Subscription_Root_GroupToUserContact_StreamArgs = {
    batch_size: Scalars['Int']['input'];
    cursor: Array<InputMaybe<_GroupToUserContact_Stream_Cursor_Input>>;
    where?: InputMaybe<_GroupToUserContact_Bool_Exp>;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
    _eq?: InputMaybe<Scalars['timestamp']['input']>;
    _gt?: InputMaybe<Scalars['timestamp']['input']>;
    _gte?: InputMaybe<Scalars['timestamp']['input']>;
    _in?: InputMaybe<Array<Scalars['timestamp']['input']>>;
    _is_null?: InputMaybe<Scalars['Boolean']['input']>;
    _lt?: InputMaybe<Scalars['timestamp']['input']>;
    _lte?: InputMaybe<Scalars['timestamp']['input']>;
    _neq?: InputMaybe<Scalars['timestamp']['input']>;
    _nin?: InputMaybe<Array<Scalars['timestamp']['input']>>;
};

export type GetMessagesByProspectIdQueryVariables = Exact<{ [key: string]: never }>;

export type GetMessagesByProspectIdQuery = {
    __typename?: 'query_root';
    Messages: Array<{
        __typename?: 'Messages';
        prospectId?: string | null;
        sentOn?: any | null;
        fullName?: string | null;
        content?: string | null;
        id: string;
        actionType?: any | null;
        linkedinUserId?: string | null;
        prospectFullName?: string | null;
        deliveryStatus?: any | null;
        Attachments: Array<{ __typename?: 'Attachments'; type: any; value: string }>;
        OutreachSuggestion?: { __typename?: 'OutreachSuggestions'; id: string } | null;
    }>;
};

export type GetOutreachSuggestionsQueryVariables = Exact<{
    limit?: InputMaybe<Scalars['Int']['input']>;
    notYetSent?: Scalars['Boolean']['input'];
    scheduledOnLt: Scalars['timestamp']['input'];
    sentOnGte: Scalars['timestamp']['input'];
}>;

export type GetOutreachSuggestionsQuery = {
    __typename?: 'query_root';
    focus: Array<{
        __typename?: 'OutreachSuggestions';
        accountName: string;
        accountWebsite?: string | null;
        email?: string | null;
        linkedinUrl?: string | null;
        numberOfEmployees?: string | null;
        title: string;
        name: string;
        industry?: string | null;
        contactLocation?: string | null;
        id: string;
        updatedAt: any;
        createdAt: any;
        prospectId?: string | null;
        unread: boolean;
        Message: {
            __typename?: 'Messages';
            id: string;
            subjectLine?: string | null;
            content?: string | null;
            actionType?: any | null;
            channelType?: any | null;
        };
        ConversationMetaData?: {
            __typename?: 'ConversationMetaData';
            id: string;
            numberOfMessages: number;
            isNextMessageFollowUp: boolean;
            messagesPerDay: any;
            lastNetworkUpdateAt: any;
            youThemMessageRatio: any;
            lastUserNoteAt: any;
            hasMessagesSentFromApp: boolean;
            medianMessageLength: any;
            connectionStatus: string;
            lastTimeToAnswer: any;
            userContactAddedAt: any;
            userContactFromOnboarding: boolean;
            lastMessageAt: any;
            numberOfMessagesLastDay: number;
            numberOfMessagesLastWeek: number;
            createdAt: any;
            updatedAt: any;
        } | null;
    }>;
    completed: Array<{
        __typename?: 'OutreachSuggestions';
        accountName: string;
        accountWebsite?: string | null;
        email?: string | null;
        linkedinUrl?: string | null;
        numberOfEmployees?: string | null;
        title: string;
        name: string;
        industry?: string | null;
        contactLocation?: string | null;
        id: string;
        updatedAt: any;
        createdAt: any;
        prospectId?: string | null;
        unread: boolean;
        Message: {
            __typename?: 'Messages';
            id: string;
            subjectLine?: string | null;
            content?: string | null;
            actionType?: any | null;
            channelType?: any | null;
        };
        ConversationMetaData?: {
            __typename?: 'ConversationMetaData';
            id: string;
            numberOfMessages: number;
            isNextMessageFollowUp: boolean;
            messagesPerDay: any;
            lastNetworkUpdateAt: any;
            youThemMessageRatio: any;
            lastUserNoteAt: any;
            hasMessagesSentFromApp: boolean;
            medianMessageLength: any;
            connectionStatus: string;
            lastTimeToAnswer: any;
            userContactAddedAt: any;
            createdAt: any;
            updatedAt: any;
        } | null;
    }>;
    focusCount: {
        __typename?: 'OutreachSuggestions_aggregate';
        aggregate?: { __typename?: 'OutreachSuggestions_aggregate_fields'; count: number } | null;
    };
    completedCount: {
        __typename?: 'OutreachSuggestions_aggregate';
        aggregate?: { __typename?: 'OutreachSuggestions_aggregate_fields'; count: number } | null;
    };
};

export type GetOutreachSuggestionsByProspectIdQueryVariables = Exact<{
    prospectId: Scalars['String']['input'];
    scheduledOnBefore: Scalars['timestamp']['input'];
}>;

export type GetOutreachSuggestionsByProspectIdQuery = {
    __typename?: 'query_root';
    focus: Array<{
        __typename?: 'OutreachSuggestions';
        id: string;
        updatedAt: any;
        createdAt: any;
        Message: {
            __typename?: 'Messages';
            id: string;
            subjectLine?: string | null;
            content?: string | null;
            actionType?: any | null;
            channelType?: any | null;
        };
    }>;
    completed: Array<{ __typename?: 'OutreachSuggestions'; id: string }>;
    future: Array<{
        __typename?: 'OutreachSuggestions';
        id: string;
        scheduledOn?: any | null;
        scheduledOnMotivation?: string | null;
    }>;
    unscheduled: Array<{ __typename?: 'OutreachSuggestions'; id: string }>;
};

export type GetActiveOutreachSuggestionByProspectIdsQueryVariables = Exact<{
    prospectIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type GetActiveOutreachSuggestionByProspectIdsQuery = {
    __typename?: 'query_root';
    OutreachSuggestions: Array<{ __typename?: 'OutreachSuggestions'; id: string; prospectId?: string | null }>;
};

export type GetContactSuggestionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetContactSuggestionsQuery = {
    __typename?: 'query_root';
    ContactSuggestions: Array<{
        __typename?: 'ContactSuggestions';
        id: string;
        contactName: string;
        reason: any;
        personId?: string | null;
        organizationId: string;
        contactEmailVerified: boolean;
        contactLinkedinUrl?: string | null;
        contactEmail?: string | null;
        contactTitle?: string | null;
        contactLocation?: string | null;
        campaignId?: string | null;
        campaignName?: string | null;
        accountIndustry?: string | null;
        accountWebsite?: string | null;
        accountTags?: Array<string> | null;
        accountName: string;
        accountId?: string | null;
        accountAboutUs?: string | null;
        accountNumberOfEmployees?: string | null;
        userId: string;
        groupId?: string | null;
        prospectId?: string | null;
        suggestionMotivation?: string | null;
        contactImgUrl?: string | null;
    }>;
};

export type GetContactSuggestionByIdQueryVariables = Exact<{
    id: Scalars['String']['input'];
}>;

export type GetContactSuggestionByIdQuery = {
    __typename?: 'query_root';
    ContactSuggestions: Array<{
        __typename?: 'ContactSuggestions';
        id: string;
        contactName: string;
        reason: any;
        personId?: string | null;
        organizationId: string;
        contactEmailVerified: boolean;
        contactLinkedinUrl?: string | null;
        contactEmail?: string | null;
        contactTitle?: string | null;
        contactLocation?: string | null;
        campaignId?: string | null;
        campaignName?: string | null;
        accountIndustry?: string | null;
        accountWebsite?: string | null;
        accountTags?: Array<string> | null;
        accountName: string;
        accountId?: string | null;
        accountAboutUs?: string | null;
        accountNumberOfEmployees?: string | null;
        userId: string;
        groupId?: string | null;
        prospectId?: string | null;
        suggestionMotivation?: string | null;
        contactImgUrl?: string | null;
    }>;
};

export type GetContactSuggestionsByGroupIdQueryVariables = Exact<{
    groupId: Scalars['String']['input'];
}>;

export type GetContactSuggestionsByGroupIdQuery = {
    __typename?: 'query_root';
    ContactSuggestions: Array<{
        __typename?: 'ContactSuggestions';
        id: string;
        contactName: string;
        reason: any;
        personId?: string | null;
        organizationId: string;
        contactEmailVerified: boolean;
        contactLinkedinUrl?: string | null;
        contactEmail?: string | null;
        contactTitle?: string | null;
        contactLocation?: string | null;
        campaignId?: string | null;
        campaignName?: string | null;
        accountIndustry?: string | null;
        accountWebsite?: string | null;
        accountTags?: Array<string> | null;
        accountName: string;
        accountId?: string | null;
        accountAboutUs?: string | null;
        accountNumberOfEmployees?: string | null;
        userId: string;
        groupId?: string | null;
        prospectId?: string | null;
        suggestionMotivation?: string | null;
        contactImgUrl?: string | null;
    }>;
};

export type GetContactSuggestionsCountQueryVariables = Exact<{ [key: string]: never }>;

export type GetContactSuggestionsCountQuery = {
    __typename?: 'query_root';
    ContactSuggestions_aggregate: {
        __typename?: 'ContactSuggestions_aggregate';
        aggregate?: { __typename?: 'ContactSuggestions_aggregate_fields'; count: number } | null;
    };
};

export type GetOutreachSuggestionByIdQueryVariables = Exact<{
    id: Scalars['String']['input'];
}>;

export type GetOutreachSuggestionByIdQuery = {
    __typename?: 'query_root';
    OutreachSuggestions_by_pk?: {
        __typename?: 'OutreachSuggestions';
        id: string;
        email?: string | null;
        name: string;
        accountName: string;
        unread: boolean;
        industry?: string | null;
        contactLocation?: string | null;
        prospectId?: string | null;
        linkedinUrl?: string | null;
        Message: {
            __typename?: 'Messages';
            id: string;
            direction: string;
            conversationId?: string | null;
            prospectId?: string | null;
            channelType?: any | null;
            actionType?: any | null;
            contentType?: string | null;
            subjectLine?: string | null;
            content?: string | null;
            sentOn?: any | null;
            fullName?: string | null;
            approvedAt?: any | null;
            linkedinUserId?: string | null;
            prospectFullName?: string | null;
        };
    } | null;
};

export type GetProspectMessagesHistoryQueryVariables = Exact<{
    prospectId: Scalars['String']['input'];
}>;

export type GetProspectMessagesHistoryQuery = {
    __typename?: 'query_root';
    Messages: Array<{
        __typename?: 'Messages';
        id: string;
        direction: string;
        conversationId?: string | null;
        prospectId?: string | null;
        channelType?: any | null;
        actionType?: any | null;
        contentType?: string | null;
        subjectLine?: string | null;
        content?: string | null;
        sentOn?: any | null;
        fullName?: string | null;
        linkedinUserId?: string | null;
        externalMessageId?: string | null;
        prospectFullName?: string | null;
        deliveryStatus?: any | null;
        Attachments: Array<{ __typename?: 'Attachments'; type: any; value: string }>;
    }>;
};

export type GetProspectNotesQueryVariables = Exact<{
    prospectId: Scalars['String']['input'];
}>;

export type GetProspectNotesQuery = {
    __typename?: 'query_root';
    Notes: Array<{
        __typename?: 'Notes';
        id: string;
        content: string;
        prospectId: string;
        title: string;
        userId: string;
        createdAt: any;
        updatedAt: any;
        creator: any;
        happenedOn: any;
    }>;
};

export type GetNoteByIdQueryVariables = Exact<{
    noteId: Scalars['String']['input'];
}>;

export type GetNoteByIdQuery = {
    __typename?: 'query_root';
    Notes: Array<{
        __typename?: 'Notes';
        id: string;
        content: string;
        prospectId: string;
        title: string;
        userId: string;
        createdAt: any;
        updatedAt: any;
        creator: any;
    }>;
};

export type GetAllProspectSubjectLinesQueryVariables = Exact<{
    prospectId: Scalars['String']['input'];
}>;

export type GetAllProspectSubjectLinesQuery = {
    __typename?: 'query_root';
    Messages: Array<{ __typename?: 'Messages'; id: string; subjectLine?: string | null }>;
};

export type GetLastProspectMessageQueryVariables = Exact<{
    prospectId: Scalars['String']['input'];
}>;

export type GetLastProspectMessageQuery = {
    __typename?: 'query_root';
    Messages: Array<{
        __typename?: 'Messages';
        id: string;
        direction: string;
        conversationId?: string | null;
        prospectId?: string | null;
        channelType?: any | null;
        actionType?: any | null;
        contentType?: string | null;
        subjectLine?: string | null;
        content?: string | null;
        sentOn?: any | null;
        fullName?: string | null;
        linkedinUserId?: string | null;
        externalMessageId?: string | null;
        prospectFullName?: string | null;
        deliveryStatus?: any | null;
    }>;
};

export type GetMessagesSentFromAppQueryVariables = Exact<{ [key: string]: never }>;

export type GetMessagesSentFromAppQuery = {
    __typename?: 'query_root';
    Messages_aggregate: {
        __typename?: 'Messages_aggregate';
        aggregate?: { __typename?: 'Messages_aggregate_fields'; count: number } | null;
    };
};

export type GetAssistantSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAssistantSettingsQuery = {
    __typename?: 'query_root';
    AssistantSettings: Array<{
        __typename?: 'AssistantSettings';
        id: string;
        userId: string;
        organizationId: string;
        purpose: string;
        language: string;
        languages?: Array<string> | null;
        tone: string;
        context?: string | null;
        createdAt: any;
        updatedAt: any;
    }>;
};

export type GroupsQueryVariables = Exact<{ [key: string]: never }>;

export type GroupsQuery = {
    __typename?: 'query_root';
    Groups: Array<{
        __typename?: 'Groups';
        id: string;
        userId: string;
        default: boolean;
        name: string;
        assistantSettingsId: string;
        imgSrc?: string | null;
        createdAt: any;
        updatedAt: any;
    }>;
};

export type GetUserContactsByGroupIdsQueryVariables = Exact<{
    groupIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
    orderBy?: InputMaybe<Array<UserContacts_Order_By> | UserContacts_Order_By>;
}>;

export type GetUserContactsByGroupIdsQuery = {
    __typename?: 'query_root';
    UserContacts: Array<{
        __typename?: 'UserContacts';
        id: string;
        prospectId: string;
        status: any;
        createdAt: any;
        _GroupToUserContacts: Array<{
            __typename?: '_GroupToUserContact';
            Group: { __typename?: 'Groups'; id: string; name: string; imgSrc?: string | null };
        }>;
    }>;
    totalCount: {
        __typename?: 'UserContacts_aggregate';
        aggregate?: { __typename?: 'UserContacts_aggregate_fields'; count: number } | null;
    };
};

export type GetUserContactsProspectIdsByGroupIdsQueryVariables = Exact<{
    groupIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
    orderBy?: InputMaybe<Array<UserContacts_Order_By> | UserContacts_Order_By>;
}>;

export type GetUserContactsProspectIdsByGroupIdsQuery = {
    __typename?: 'query_root';
    UserContacts: Array<{ __typename?: 'UserContacts'; prospectId: string }>;
};

export type GetUserContactByProspectIdQueryVariables = Exact<{
    prospectId: Scalars['String']['input'];
}>;

export type GetUserContactByProspectIdQuery = {
    __typename?: 'query_root';
    UserContacts: Array<{
        __typename?: 'UserContacts';
        id: string;
        userId: string;
        prospectId: string;
        createdAt: any;
        updatedAt: any;
        status: any;
        _GroupToUserContacts: Array<{
            __typename?: '_GroupToUserContact';
            Group: {
                __typename?: 'Groups';
                id: string;
                userId: string;
                name: string;
                default: boolean;
                assistantSettingsId: string;
                imgSrc?: string | null;
                createdAt: any;
                updatedAt: any;
            };
        }>;
    }>;
};

export type GetTotalNumberOfUserContactsQueryVariables = Exact<{ [key: string]: never }>;

export type GetTotalNumberOfUserContactsQuery = {
    __typename?: 'query_root';
    UserContacts_aggregate: {
        __typename?: 'UserContacts_aggregate';
        aggregate?: { __typename?: 'UserContacts_aggregate_fields'; count: number } | null;
    };
};

export type GetTotalNumberOfUserContactsInAnyPlaybookQueryVariables = Exact<{ [key: string]: never }>;

export type GetTotalNumberOfUserContactsInAnyPlaybookQuery = {
    __typename?: 'query_root';
    UserContacts_aggregate: {
        __typename?: 'UserContacts_aggregate';
        aggregate?: { __typename?: 'UserContacts_aggregate_fields'; count: number } | null;
    };
};

export type GetUserContactByIdQueryVariables = Exact<{
    userContactId: Scalars['String']['input'];
}>;

export type GetUserContactByIdQuery = {
    __typename?: 'query_root';
    UserContacts: Array<{
        __typename?: 'UserContacts';
        id: string;
        userId: string;
        prospectId: string;
        createdAt: any;
        updatedAt: any;
        status: any;
        _GroupToUserContacts: Array<{
            __typename?: '_GroupToUserContact';
            Group: {
                __typename?: 'Groups';
                id: string;
                userId: string;
                name: string;
                default: boolean;
                assistantSettingsId: string;
                imgSrc?: string | null;
                createdAt: any;
                updatedAt: any;
            };
        }>;
    }>;
};

export type GetUserContactsByIdsQueryVariables = Exact<{
    ids?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;

export type GetUserContactsByIdsQuery = {
    __typename?: 'query_root';
    UserContacts: Array<{
        __typename?: 'UserContacts';
        id: string;
        userId: string;
        prospectId: string;
        createdAt: any;
        updatedAt: any;
        status: any;
        _GroupToUserContacts: Array<{
            __typename?: '_GroupToUserContact';
            Group: {
                __typename?: 'Groups';
                id: string;
                userId: string;
                name: string;
                default: boolean;
                assistantSettingsId: string;
                imgSrc?: string | null;
                createdAt: any;
                updatedAt: any;
            };
        }>;
    }>;
};

export type GetAllUserContactsInAnyPlaybookQueryVariables = Exact<{
    orderBy?: InputMaybe<Array<UserContacts_Order_By> | UserContacts_Order_By>;
}>;

export type GetAllUserContactsInAnyPlaybookQuery = {
    __typename?: 'query_root';
    UserContacts: Array<{
        __typename?: 'UserContacts';
        id: string;
        prospectId: string;
        status: any;
        createdAt: any;
        _GroupToUserContacts: Array<{
            __typename?: '_GroupToUserContact';
            Group: { __typename?: 'Groups'; id: string; name: string; imgSrc?: string | null };
        }>;
    }>;
    totalCount: {
        __typename?: 'UserContacts_aggregate';
        aggregate?: { __typename?: 'UserContacts_aggregate_fields'; count: number } | null;
    };
};

export type GetAllUserContactsQueryVariables = Exact<{
    orderBy?: InputMaybe<Array<UserContacts_Order_By> | UserContacts_Order_By>;
}>;

export type GetAllUserContactsQuery = {
    __typename?: 'query_root';
    UserContacts: Array<{
        __typename?: 'UserContacts';
        id: string;
        prospectId: string;
        status: any;
        createdAt: any;
        _GroupToUserContacts: Array<{
            __typename?: '_GroupToUserContact';
            Group: { __typename?: 'Groups'; id: string; name: string; imgSrc?: string | null };
        }>;
    }>;
    totalCount: {
        __typename?: 'UserContacts_aggregate';
        aggregate?: { __typename?: 'UserContacts_aggregate_fields'; count: number } | null;
    };
};

export type GetActionsCountQueryVariables = Exact<{
    notYetSent?: Scalars['Boolean']['input'];
    scheduledOnLt: Scalars['timestamp']['input'];
}>;

export type GetActionsCountQuery = {
    __typename?: 'query_root';
    OutreachSuggestions_aggregate: {
        __typename?: 'OutreachSuggestions_aggregate';
        aggregate?: { __typename?: 'OutreachSuggestions_aggregate_fields'; count: number } | null;
    };
};

export type GetPlaybooksQueryVariables = Exact<{
    name?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetPlaybooksQuery = {
    __typename?: 'query_root';
    Groups: Array<{
        __typename?: 'Groups';
        id: string;
        userId: string;
        default: boolean;
        name: string;
        assistantSettingsId: string;
        imgSrc?: string | null;
        createdAt: any;
        updatedAt: any;
        AssistantSetting: {
            __typename?: 'AssistantSettings';
            id: string;
            userId: string;
            organizationId: string;
            purpose: string;
            language: string;
            languages?: Array<string> | null;
            tone: string;
            targetGroup?: string | null;
            context?: string | null;
            createdAt: any;
            updatedAt: any;
            defaultChannel: any;
        };
    }>;
};

export type GetPlaybookByGroupIdQueryVariables = Exact<{
    groupId: Scalars['String']['input'];
}>;

export type GetPlaybookByGroupIdQuery = {
    __typename?: 'query_root';
    Groups: Array<{
        __typename?: 'Groups';
        id: string;
        userId: string;
        default: boolean;
        name: string;
        assistantSettingsId: string;
        imgSrc?: string | null;
        createdAt: any;
        updatedAt: any;
        AssistantSetting: {
            __typename?: 'AssistantSettings';
            id: string;
            userId: string;
            organizationId: string;
            purpose: string;
            language: string;
            languages?: Array<string> | null;
            tone: string;
            context?: string | null;
            targetGroup?: string | null;
            createdAt: any;
            updatedAt: any;
            defaultChannel: any;
        };
        _GroupToUserContacts: Array<{
            __typename?: '_GroupToUserContact';
            UserContact: { __typename?: 'UserContacts'; id: string };
        }>;
    }>;
};

export type GetOutreachCompletionMetricsQueryVariables = Exact<{
    startDate: Scalars['timestamp']['input'];
    endDate: Scalars['timestamp']['input'];
    organizationId: Scalars['String']['input'];
}>;

export type GetOutreachCompletionMetricsQuery = {
    __typename?: 'query_root';
    OutreachCompletionMetrics: Array<{
        __typename?: 'OutreachCompletionMetrics';
        date: any;
        trailingTotalCompleted: number;
        trailingTotalGoalsReached: number;
        trailingTotalSuggestions: number;
        completedSameDay: number;
        completedWithin2Days: number;
        completedWithin3Days: number;
        completedWithin7Days: number;
        completedWithin30Days: number;
        goalsReachedSameDay: number;
        goalsReachedWithin2Days: number;
        goalsReachedWithin3Days: number;
        goalsReachedWithin7Days: number;
        goalsReachedWithin30Days: number;
        suggestionsSameDay: number;
        suggestionsWithin2Days: number;
        suggestionsWithin3Days: number;
        suggestionsWithin7Days: number;
        suggestionsWithin30Days: number;
    }>;
    numberOfUsers: {
        __typename?: 'OutreachSuggestions_aggregate';
        aggregate?: { __typename?: 'OutreachSuggestions_aggregate_fields'; count: number } | null;
    };
    numberOfUsersBeforePeriod: {
        __typename?: 'OutreachSuggestions_aggregate';
        aggregate?: { __typename?: 'OutreachSuggestions_aggregate_fields'; count: number } | null;
    };
};

export type GetDashboardMetricsQueryVariables = Exact<{
    startDate: Scalars['timestamp']['input'];
    endDate: Scalars['timestamp']['input'];
    previousStartDate: Scalars['timestamp']['input'];
    organizationId: Scalars['String']['input'];
}>;

export type GetDashboardMetricsQuery = {
    __typename?: 'query_root';
    numberOfSentMessages: {
        __typename?: 'Messages_aggregate';
        aggregate?: { __typename?: 'Messages_aggregate_fields'; count: number } | null;
        nodes: Array<{
            __typename?: 'Messages';
            prospectId?: string | null;
            isSentFromApp?: boolean | null;
            userId: string;
            channelType?: any | null;
            sentOn?: any | null;
        }>;
    };
    numberOfReceivedMessages: {
        __typename?: 'Messages_aggregate';
        aggregate?: { __typename?: 'Messages_aggregate_fields'; count: number } | null;
        nodes: Array<{
            __typename?: 'Messages';
            prospectId?: string | null;
            userId: string;
            channelType?: any | null;
            sentOn?: any | null;
        }>;
    };
    numberOfNewConnections: {
        __typename?: 'Messages_aggregate';
        aggregate?: { __typename?: 'Messages_aggregate_fields'; count: number } | null;
        nodes: Array<{
            __typename?: 'Messages';
            prospectId?: string | null;
            prospectFullName?: string | null;
            userId: string;
            channelType?: any | null;
            actionType?: any | null;
            sentOn?: any | null;
        }>;
    };
    numberOfSentMessagesPrev: {
        __typename?: 'Messages_aggregate';
        aggregate?: { __typename?: 'Messages_aggregate_fields'; count: number } | null;
        nodes: Array<{
            __typename?: 'Messages';
            prospectId?: string | null;
            isSentFromApp?: boolean | null;
            userId: string;
            channelType?: any | null;
            sentOn?: any | null;
        }>;
    };
    numberOfReceivedMessagesPrev: {
        __typename?: 'Messages_aggregate';
        aggregate?: { __typename?: 'Messages_aggregate_fields'; count: number } | null;
        nodes: Array<{
            __typename?: 'Messages';
            prospectId?: string | null;
            userId: string;
            channelType?: any | null;
            sentOn?: any | null;
        }>;
    };
    numberOfNewConnectionsPrev: {
        __typename?: 'Messages_aggregate';
        aggregate?: { __typename?: 'Messages_aggregate_fields'; count: number } | null;
        nodes: Array<{
            __typename?: 'Messages';
            prospectId?: string | null;
            prospectFullName?: string | null;
            userId: string;
            channelType?: any | null;
            actionType?: any | null;
            sentOn?: any | null;
        }>;
    };
};

export type GetTeamDashboardMetricsQueryVariables = Exact<{
    startDate: Scalars['timestamp']['input'];
    endDate: Scalars['timestamp']['input'];
    previousStartDate: Scalars['timestamp']['input'];
    organizationId: Scalars['String']['input'];
}>;

export type GetTeamDashboardMetricsQuery = {
    __typename?: 'query_root';
    numberOfSentMessages: {
        __typename?: 'Messages_aggregate';
        aggregate?: { __typename?: 'Messages_aggregate_fields'; count: number } | null;
        nodes: Array<{
            __typename?: 'Messages';
            prospectId?: string | null;
            isSentFromApp?: boolean | null;
            userId: string;
            channelType?: any | null;
            sentOn?: any | null;
        }>;
    };
    numberOfReceivedMessages: {
        __typename?: 'Messages_aggregate';
        aggregate?: { __typename?: 'Messages_aggregate_fields'; count: number } | null;
        nodes: Array<{
            __typename?: 'Messages';
            prospectId?: string | null;
            userId: string;
            channelType?: any | null;
            sentOn?: any | null;
        }>;
    };
    numberOfNewConnections: {
        __typename?: 'Messages_aggregate';
        aggregate?: { __typename?: 'Messages_aggregate_fields'; count: number } | null;
        nodes: Array<{
            __typename?: 'Messages';
            prospectId?: string | null;
            prospectFullName?: string | null;
            userId: string;
            channelType?: any | null;
            actionType?: any | null;
            sentOn?: any | null;
        }>;
    };
    numberOfSentMessagesPrev: {
        __typename?: 'Messages_aggregate';
        aggregate?: { __typename?: 'Messages_aggregate_fields'; count: number } | null;
        nodes: Array<{
            __typename?: 'Messages';
            prospectId?: string | null;
            isSentFromApp?: boolean | null;
            userId: string;
            channelType?: any | null;
            sentOn?: any | null;
        }>;
    };
    numberOfReceivedMessagesPrev: {
        __typename?: 'Messages_aggregate';
        aggregate?: { __typename?: 'Messages_aggregate_fields'; count: number } | null;
        nodes: Array<{
            __typename?: 'Messages';
            prospectId?: string | null;
            userId: string;
            channelType?: any | null;
            sentOn?: any | null;
        }>;
    };
    numberOfNewConnectionsPrev: {
        __typename?: 'Messages_aggregate';
        aggregate?: { __typename?: 'Messages_aggregate_fields'; count: number } | null;
        nodes: Array<{
            __typename?: 'Messages';
            prospectId?: string | null;
            prospectFullName?: string | null;
            userId: string;
            channelType?: any | null;
            actionType?: any | null;
            sentOn?: any | null;
        }>;
    };
};

export type GetMessagesSentTodayQueryVariables = Exact<{
    startDate: Scalars['timestamp']['input'];
    endDate: Scalars['timestamp']['input'];
}>;

export type GetMessagesSentTodayQuery = {
    __typename?: 'query_root';
    Messages_aggregate: {
        __typename?: 'Messages_aggregate';
        aggregate?: { __typename?: 'Messages_aggregate_fields'; count: number } | null;
    };
};

export type OnOutreachSuggestionAddedSubscriptionVariables = Exact<{
    limit?: InputMaybe<Scalars['Int']['input']>;
    sentOn?: InputMaybe<Scalars['Boolean']['input']>;
    scheduledOnLt?: InputMaybe<Scalars['timestamp']['input']>;
}>;

export type OnOutreachSuggestionAddedSubscription = {
    __typename?: 'subscription_root';
    OutreachSuggestions: Array<{
        __typename?: 'OutreachSuggestions';
        accountName: string;
        name: string;
        id: string;
        createdAt: any;
        updatedAt: any;
        userId: string;
        prospectId?: string | null;
        unread: boolean;
        scheduledOn?: any | null;
        score: number;
        Message: {
            __typename?: 'Messages';
            id: string;
            subjectLine?: string | null;
            content?: string | null;
            actionType?: any | null;
            channelType?: any | null;
            linkedinUserId?: string | null;
        };
        ConversationMetaData?: {
            __typename?: 'ConversationMetaData';
            id: string;
            numberOfMessages: number;
            isNextMessageFollowUp: boolean;
            messagesPerDay: any;
            lastNetworkUpdateAt: any;
            youThemMessageRatio: any;
            lastUserNoteAt: any;
            hasMessagesSentFromApp: boolean;
            medianMessageLength: any;
            connectionStatus: string;
            lastTimeToAnswer: any;
            userContactAddedAt: any;
            lastMessageAt: any;
            numberOfMessagesLastDay: number;
            numberOfMessagesLastWeek: number;
            userContactFromOnboarding: boolean;
            createdAt: any;
            updatedAt: any;
        } | null;
    }>;
};

export type ActionsSubscriptionByProspectIdsSubscriptionVariables = Exact<{
    limit?: InputMaybe<Scalars['Int']['input']>;
    scheduledOnLt?: InputMaybe<Scalars['timestamp']['input']>;
    prospectIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type ActionsSubscriptionByProspectIdsSubscription = {
    __typename?: 'subscription_root';
    OutreachSuggestions: Array<{
        __typename?: 'OutreachSuggestions';
        id: string;
        createdAt: any;
        prospectId?: string | null;
        accountName: string;
        name: string;
        unread: boolean;
        isGenerating: boolean;
        goal?: string | null;
        Message: { __typename?: 'Messages'; content?: string | null; subjectLine?: string | null };
        ConversationMetaData?: {
            __typename?: 'ConversationMetaData';
            id: string;
            numberOfMessages: number;
            isNextMessageFollowUp: boolean;
            messagesPerDay: any;
            lastNetworkUpdateAt: any;
            youThemMessageRatio: any;
            lastUserNoteAt: any;
            hasMessagesSentFromApp: boolean;
            medianMessageLength: any;
            connectionStatus: string;
            lastTimeToAnswer: any;
            userContactAddedAt: any;
            lastMessageAt: any;
            numberOfMessagesLastDay: number;
            numberOfMessagesLastWeek: number;
            userContactFromOnboarding: boolean;
            createdAt: any;
            updatedAt: any;
            topics: string;
        } | null;
    }>;
};

export type ActionsSubscriptionSubscriptionVariables = Exact<{
    limit?: InputMaybe<Scalars['Int']['input']>;
    scheduledOnLt?: InputMaybe<Scalars['timestamp']['input']>;
}>;

export type ActionsSubscriptionSubscription = {
    __typename?: 'subscription_root';
    OutreachSuggestions: Array<{
        __typename?: 'OutreachSuggestions';
        id: string;
        createdAt: any;
        prospectId?: string | null;
        accountName: string;
        name: string;
        unread: boolean;
        isGenerating: boolean;
        goal?: string | null;
        Message: { __typename?: 'Messages'; content?: string | null; subjectLine?: string | null };
        ConversationMetaData?: {
            __typename?: 'ConversationMetaData';
            id: string;
            numberOfMessages: number;
            isNextMessageFollowUp: boolean;
            messagesPerDay: any;
            lastNetworkUpdateAt: any;
            youThemMessageRatio: any;
            lastUserNoteAt: any;
            hasMessagesSentFromApp: boolean;
            medianMessageLength: any;
            connectionStatus: string;
            lastTimeToAnswer: any;
            userContactAddedAt: any;
            lastMessageAt: any;
            numberOfMessagesLastDay: number;
            numberOfMessagesLastWeek: number;
            userContactFromOnboarding: boolean;
            createdAt: any;
            updatedAt: any;
            topics: string;
        } | null;
    }>;
};

export type CompletedActionsSubscriptionSubscriptionVariables = Exact<{
    limit?: InputMaybe<Scalars['Int']['input']>;
    scheduledOnLt?: InputMaybe<Scalars['timestamp']['input']>;
    completedOnGte?: InputMaybe<Scalars['timestamp']['input']>;
}>;

export type CompletedActionsSubscriptionSubscription = {
    __typename?: 'subscription_root';
    OutreachSuggestions: Array<{
        __typename?: 'OutreachSuggestions';
        id: string;
        createdAt: any;
        prospectId?: string | null;
        accountName: string;
        name: string;
        goal?: string | null;
        Message: {
            __typename?: 'Messages';
            content?: string | null;
            subjectLine?: string | null;
            actionType?: any | null;
        };
        ConversationMetaData?: { __typename?: 'ConversationMetaData'; topics: string } | null;
    }>;
};

export type GetProspectNotesSubscriptionSubscriptionVariables = Exact<{
    prospectId: Scalars['String']['input'];
}>;

export type GetProspectNotesSubscriptionSubscription = {
    __typename?: 'subscription_root';
    Notes: Array<{ __typename?: 'Notes'; id: string }>;
};

export type CompletedOutreachSuggestionsSubscriptionVariables = Exact<{
    limit?: InputMaybe<Scalars['Int']['input']>;
    sentOn?: InputMaybe<Scalars['Boolean']['input']>;
    scheduledOnLt?: InputMaybe<Scalars['timestamp']['input']>;
    sentOnGte?: InputMaybe<Scalars['timestamp']['input']>;
}>;

export type CompletedOutreachSuggestionsSubscription = {
    __typename?: 'subscription_root';
    OutreachSuggestions: Array<{
        __typename?: 'OutreachSuggestions';
        accountName: string;
        name: string;
        id: string;
        createdAt: any;
        updatedAt: any;
        userId: string;
        prospectId?: string | null;
        unread: boolean;
        scheduledOn?: any | null;
        score: number;
        Message: {
            __typename?: 'Messages';
            id: string;
            subjectLine?: string | null;
            content?: string | null;
            actionType?: any | null;
            channelType?: any | null;
            linkedinUserId?: string | null;
            sentOn?: any | null;
        };
        ConversationMetaData?: {
            __typename?: 'ConversationMetaData';
            id: string;
            numberOfMessages: number;
            isNextMessageFollowUp: boolean;
            messagesPerDay: any;
            lastNetworkUpdateAt: any;
            youThemMessageRatio: any;
            lastUserNoteAt: any;
            hasMessagesSentFromApp: boolean;
            medianMessageLength: any;
            connectionStatus: string;
            lastTimeToAnswer: any;
            userContactAddedAt: any;
            lastMessageAt: any;
            numberOfMessagesLastDay: number;
            numberOfMessagesLastWeek: number;
            createdAt: any;
            updatedAt: any;
        } | null;
    }>;
};

export type NextScheduledOutreachSuggestionsSubscriptionVariables = Exact<{
    prospectId: Scalars['String']['input'];
}>;

export type NextScheduledOutreachSuggestionsSubscription = {
    __typename?: 'subscription_root';
    OutreachSuggestions: Array<{
        __typename?: 'OutreachSuggestions';
        accountName: string;
        name: string;
        id: string;
        createdAt: any;
        prospectId?: string | null;
        unread: boolean;
        scheduledOn?: any | null;
        scheduledOnMotivation?: string | null;
        Message: {
            __typename?: 'Messages';
            id: string;
            subjectLine?: string | null;
            content?: string | null;
            actionType?: any | null;
            channelType?: any | null;
            linkedinUserId?: string | null;
            direction: string;
            sentOn?: any | null;
            updatedAt: any;
        };
    }>;
};

export type OnMessageAddedByProspectIdSubscriptionVariables = Exact<{ [key: string]: never }>;

export type OnMessageAddedByProspectIdSubscription = {
    __typename?: 'subscription_root';
    Messages: Array<{
        __typename?: 'Messages';
        id: string;
        prospectId?: string | null;
        sentOn?: any | null;
        fullName?: string | null;
        content?: string | null;
        subjectLine?: string | null;
        direction: string;
        actionType?: any | null;
        prospectFullName?: string | null;
        deliveryStatus?: any | null;
        OutreachSuggestion?: { __typename?: 'OutreachSuggestions'; id: string } | null;
    }>;
};

export type GetLinkedinConversationsSubscriptionSubscriptionVariables = Exact<{ [key: string]: never }>;

export type GetLinkedinConversationsSubscriptionSubscription = {
    __typename?: 'subscription_root';
    LinkedinConversations: Array<{
        __typename?: 'LinkedinConversations';
        id: string;
        prospectId?: string | null;
        lastMessageType?: any | null;
        lastMessageText?: string | null;
        lastMessageSentAt?: any | null;
        lastMessageSentDirection?: string | null;
        participantMemberIds?: Array<string> | null;
    }>;
};

export type GetOutreachSuggestionSubscriptionVariables = Exact<{
    prospectId: Scalars['String']['input'];
    scheduledOnBefore: Scalars['timestamp']['input'];
}>;

export type GetOutreachSuggestionSubscription = {
    __typename?: 'subscription_root';
    OutreachSuggestions: Array<{
        __typename?: 'OutreachSuggestions';
        id: string;
        accountName: string;
        name: string;
        createdAt: any;
        updatedAt: any;
        userId: string;
        prospectId?: string | null;
        unread: boolean;
        scheduledOn?: any | null;
        score: number;
        goal?: string | null;
        isGenerating: boolean;
        Message: {
            __typename?: 'Messages';
            id: string;
            createdAt: any;
            subjectLine?: string | null;
            content?: string | null;
            actionType?: any | null;
            channelType?: any | null;
            linkedinUserId?: string | null;
        };
        ConversationMetaData?: { __typename?: 'ConversationMetaData'; topics: string } | null;
    }>;
};

export type GetProspectFutureActionsSubscriptionVariables = Exact<{
    prospectId: Scalars['String']['input'];
    scheduledOnBefore: Scalars['timestamp']['input'];
}>;

export type GetProspectFutureActionsSubscription = {
    __typename?: 'subscription_root';
    OutreachSuggestions: Array<{
        __typename?: 'OutreachSuggestions';
        id: string;
        scheduledOn?: any | null;
        scheduledOnMotivation?: string | null;
    }>;
};

export type GetProspectUnscheduledActionsSubscriptionVariables = Exact<{
    prospectId: Scalars['String']['input'];
}>;

export type GetProspectUnscheduledActionsSubscription = {
    __typename?: 'subscription_root';
    OutreachSuggestions: Array<{
        __typename?: 'OutreachSuggestions';
        id: string;
        scheduledOnMotivation?: string | null;
    }>;
};

export type OnMessageUpdatedSubscriptionVariables = Exact<{
    messageId: Scalars['String']['input'];
}>;

export type OnMessageUpdatedSubscription = {
    __typename?: 'subscription_root';
    Messages: Array<{
        __typename?: 'Messages';
        id: string;
        subjectLine?: string | null;
        content?: string | null;
        actionType?: any | null;
        channelType?: any | null;
        deliveryStatus?: any | null;
        Attachments: Array<{ __typename?: 'Attachments'; type: any; value: string }>;
    }>;
};

export type GetProspectMessagesHistorySubscriptionSubscriptionVariables = Exact<{
    prospectId: Scalars['String']['input'];
}>;

export type GetProspectMessagesHistorySubscriptionSubscription = {
    __typename?: 'subscription_root';
    Messages: Array<{ __typename?: 'Messages'; id: string }>;
};

export type GetAssistantSettingsStreamingSubscriptionSubscriptionVariables = Exact<{ [key: string]: never }>;

export type GetAssistantSettingsStreamingSubscriptionSubscription = {
    __typename?: 'subscription_root';
    AssistantSettings: Array<{
        __typename?: 'AssistantSettings';
        id: string;
        userId: string;
        organizationId: string;
        purpose: string;
        language: string;
        languages?: Array<string> | null;
        tone: string;
        context?: string | null;
        defaultChannel: any;
        createdAt: any;
        updatedAt: any;
    }>;
};

export type GetUserContactsSubscriptionSubscriptionVariables = Exact<{ [key: string]: never }>;

export type GetUserContactsSubscriptionSubscription = {
    __typename?: 'subscription_root';
    UserContacts: Array<{
        __typename?: 'UserContacts';
        id: string;
        prospectId: string;
        status: any;
        _GroupToUserContacts: Array<{
            __typename?: '_GroupToUserContact';
            Group: { __typename?: 'Groups'; id: string; name: string; imgSrc?: string | null };
        }>;
    }>;
};

export type OnUserContactsByIdsSubscriptionVariables = Exact<{
    prospectIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type OnUserContactsByIdsSubscription = {
    __typename?: 'subscription_root';
    UserContacts: Array<{
        __typename?: 'UserContacts';
        id: string;
        prospectId: string;
        status: any;
        _GroupToUserContacts: Array<{
            __typename?: '_GroupToUserContact';
            Group: { __typename?: 'Groups'; id: string; name: string; imgSrc?: string | null };
        }>;
    }>;
};

export type GetGroupsSubscriptionVariables = Exact<{ [key: string]: never }>;

export type GetGroupsSubscription = {
    __typename?: 'subscription_root';
    Groups: Array<{
        __typename?: 'Groups';
        id: string;
        userId: string;
        default: boolean;
        name: string;
        assistantSettingsId: string;
        imgSrc?: string | null;
        createdAt: any;
        updatedAt: any;
    }>;
};

export type GetNotesSubscriptionVariables = Exact<{ [key: string]: never }>;

export type GetNotesSubscription = {
    __typename?: 'subscription_root';
    Notes: Array<{
        __typename?: 'Notes';
        id: string;
        prospectId: string;
        creator: any;
        content: string;
        title: string;
        createdAt: any;
        updatedAt: any;
    }>;
};

export type GetContactSuggestionsCountSubscriptionSubscriptionVariables = Exact<{ [key: string]: never }>;

export type GetContactSuggestionsCountSubscriptionSubscription = {
    __typename?: 'subscription_root';
    ContactSuggestions_aggregate: {
        __typename?: 'ContactSuggestions_aggregate';
        aggregate?: { __typename?: 'ContactSuggestions_aggregate_fields'; count: number } | null;
    };
};

export type GetMessagesSentFromAppSubscriptionSubscriptionVariables = Exact<{ [key: string]: never }>;

export type GetMessagesSentFromAppSubscriptionSubscription = {
    __typename?: 'subscription_root';
    Messages_aggregate: {
        __typename?: 'Messages_aggregate';
        aggregate?: { __typename?: 'Messages_aggregate_fields'; count: number } | null;
    };
};

export const GetMessagesByProspectIdDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'GetMessagesByProspectId' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Messages' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'sentOn' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_is_null' },
                                                        value: { kind: 'BooleanValue', value: false },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'order_by' },
                                value: {
                                    kind: 'ListValue',
                                    values: [
                                        {
                                            kind: 'ObjectValue',
                                            fields: [
                                                {
                                                    kind: 'ObjectField',
                                                    name: { kind: 'Name', value: 'prospectId' },
                                                    value: { kind: 'EnumValue', value: 'desc' },
                                                },
                                            ],
                                        },
                                        {
                                            kind: 'ObjectValue',
                                            fields: [
                                                {
                                                    kind: 'ObjectField',
                                                    name: { kind: 'Name', value: 'sentOn' },
                                                    value: { kind: 'EnumValue', value: 'desc' },
                                                },
                                            ],
                                        },
                                    ],
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'distinct_on' },
                                value: { kind: 'EnumValue', value: 'prospectId' },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'prospectId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'sentOn' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'actionType' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'linkedinUserId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'prospectFullName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deliveryStatus' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Attachments' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'OutreachSuggestion' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetMessagesByProspectIdQuery, GetMessagesByProspectIdQueryVariables>;
export const GetOutreachSuggestionsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'GetOutreachSuggestions' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'notYetSent' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                    },
                    defaultValue: { kind: 'BooleanValue', value: true },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'scheduledOnLt' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'timestamp' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'sentOnGte' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'timestamp' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'focus' },
                        name: { kind: 'Name', value: 'OutreachSuggestions' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'Message' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: 'sentOn' },
                                                        value: {
                                                            kind: 'ObjectValue',
                                                            fields: [
                                                                {
                                                                    kind: 'ObjectField',
                                                                    name: { kind: 'Name', value: '_is_null' },
                                                                    value: {
                                                                        kind: 'Variable',
                                                                        name: { kind: 'Name', value: 'notYetSent' },
                                                                    },
                                                                },
                                                            ],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'scheduledOn' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_lt' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'scheduledOnLt' },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'accountName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'accountWebsite' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'linkedinUrl' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'numberOfEmployees' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'industry' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'contactLocation' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'prospectId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'unread' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Message' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'subjectLine' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'actionType' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'channelType' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'ConversationMetaData' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'numberOfMessages' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'isNextMessageFollowUp' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'messagesPerDay' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'lastNetworkUpdateAt' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'youThemMessageRatio' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'lastUserNoteAt' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'hasMessagesSentFromApp' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'medianMessageLength' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'connectionStatus' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'lastTimeToAnswer' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'userContactAddedAt' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'userContactFromOnboarding' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'lastMessageAt' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'numberOfMessagesLastDay' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'numberOfMessagesLastWeek' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'completed' },
                        name: { kind: 'Name', value: 'OutreachSuggestions' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'Message' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: 'sentOn' },
                                                        value: {
                                                            kind: 'ObjectValue',
                                                            fields: [
                                                                {
                                                                    kind: 'ObjectField',
                                                                    name: { kind: 'Name', value: '_is_null' },
                                                                    value: { kind: 'BooleanValue', value: false },
                                                                },
                                                                {
                                                                    kind: 'ObjectField',
                                                                    name: { kind: 'Name', value: '_gte' },
                                                                    value: {
                                                                        kind: 'Variable',
                                                                        name: { kind: 'Name', value: 'sentOnGte' },
                                                                    },
                                                                },
                                                            ],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'scheduledOn' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_lt' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'scheduledOnLt' },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'accountName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'accountWebsite' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'linkedinUrl' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'numberOfEmployees' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'industry' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'contactLocation' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'prospectId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'unread' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Message' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'subjectLine' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'actionType' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'channelType' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'ConversationMetaData' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'numberOfMessages' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'isNextMessageFollowUp' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'messagesPerDay' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'lastNetworkUpdateAt' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'youThemMessageRatio' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'lastUserNoteAt' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'hasMessagesSentFromApp' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'medianMessageLength' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'connectionStatus' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'lastTimeToAnswer' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'userContactAddedAt' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'focusCount' },
                        name: { kind: 'Name', value: 'OutreachSuggestions_aggregate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'Message' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: 'sentOn' },
                                                        value: {
                                                            kind: 'ObjectValue',
                                                            fields: [
                                                                {
                                                                    kind: 'ObjectField',
                                                                    name: { kind: 'Name', value: '_is_null' },
                                                                    value: {
                                                                        kind: 'Variable',
                                                                        name: { kind: 'Name', value: 'notYetSent' },
                                                                    },
                                                                },
                                                            ],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'scheduledOn' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_lt' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'scheduledOnLt' },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aggregate' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'completedCount' },
                        name: { kind: 'Name', value: 'OutreachSuggestions_aggregate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'Message' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: 'sentOn' },
                                                        value: {
                                                            kind: 'ObjectValue',
                                                            fields: [
                                                                {
                                                                    kind: 'ObjectField',
                                                                    name: { kind: 'Name', value: '_is_null' },
                                                                    value: { kind: 'BooleanValue', value: false },
                                                                },
                                                            ],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'scheduledOn' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_lt' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'scheduledOnLt' },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aggregate' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetOutreachSuggestionsQuery, GetOutreachSuggestionsQueryVariables>;
export const GetOutreachSuggestionsByProspectIdDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'GetOutreachSuggestionsByProspectId' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'prospectId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'scheduledOnBefore' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'timestamp' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'focus' },
                        name: { kind: 'Name', value: 'OutreachSuggestions' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'prospectId' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_eq' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'prospectId' },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'scheduledOn' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_lt' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'scheduledOnBefore' },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'Message' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: 'sentOn' },
                                                        value: {
                                                            kind: 'ObjectValue',
                                                            fields: [
                                                                {
                                                                    kind: 'ObjectField',
                                                                    name: { kind: 'Name', value: '_is_null' },
                                                                    value: { kind: 'BooleanValue', value: true },
                                                                },
                                                            ],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'order_by' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'Message' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: 'createdAt' },
                                                        value: { kind: 'EnumValue', value: 'desc' },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Message' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'subjectLine' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'actionType' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'channelType' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'completed' },
                        name: { kind: 'Name', value: 'OutreachSuggestions' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'prospectId' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_eq' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'prospectId' },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'scheduledOn' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_lt' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'scheduledOnBefore' },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'Message' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: 'sentOn' },
                                                        value: {
                                                            kind: 'ObjectValue',
                                                            fields: [
                                                                {
                                                                    kind: 'ObjectField',
                                                                    name: { kind: 'Name', value: '_is_null' },
                                                                    value: { kind: 'BooleanValue', value: false },
                                                                },
                                                            ],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'order_by' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'Message' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: 'createdAt' },
                                                        value: { kind: 'EnumValue', value: 'desc' },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                    },
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'future' },
                        name: { kind: 'Name', value: 'OutreachSuggestions' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'prospectId' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_eq' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'prospectId' },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'scheduledOn' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_gt' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'scheduledOnBefore' },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'Message' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: 'sentOn' },
                                                        value: {
                                                            kind: 'ObjectValue',
                                                            fields: [
                                                                {
                                                                    kind: 'ObjectField',
                                                                    name: { kind: 'Name', value: '_is_null' },
                                                                    value: { kind: 'BooleanValue', value: true },
                                                                },
                                                            ],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'order_by' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'Message' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: 'createdAt' },
                                                        value: { kind: 'EnumValue', value: 'desc' },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'scheduledOn' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'scheduledOnMotivation' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'unscheduled' },
                        name: { kind: 'Name', value: 'OutreachSuggestions' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'prospectId' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_eq' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'prospectId' },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'scheduledOn' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_is_null' },
                                                        value: { kind: 'BooleanValue', value: true },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'Message' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: 'sentOn' },
                                                        value: {
                                                            kind: 'ObjectValue',
                                                            fields: [
                                                                {
                                                                    kind: 'ObjectField',
                                                                    name: { kind: 'Name', value: '_is_null' },
                                                                    value: { kind: 'BooleanValue', value: true },
                                                                },
                                                            ],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'order_by' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'Message' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: 'createdAt' },
                                                        value: { kind: 'EnumValue', value: 'desc' },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetOutreachSuggestionsByProspectIdQuery, GetOutreachSuggestionsByProspectIdQueryVariables>;
export const GetActiveOutreachSuggestionByProspectIdsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'GetActiveOutreachSuggestionByProspectIds' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'prospectIds' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'ListType',
                            type: {
                                kind: 'NonNullType',
                                type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'OutreachSuggestions' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'prospectId' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_in' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'prospectIds' },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'scheduledOn' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_is_null' },
                                                        value: { kind: 'BooleanValue', value: false },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'Message' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: 'sentOn' },
                                                        value: {
                                                            kind: 'ObjectValue',
                                                            fields: [
                                                                {
                                                                    kind: 'ObjectField',
                                                                    name: { kind: 'Name', value: '_is_null' },
                                                                    value: { kind: 'BooleanValue', value: true },
                                                                },
                                                            ],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'order_by' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'Message' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: 'createdAt' },
                                                        value: { kind: 'EnumValue', value: 'desc' },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'prospectId' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GetActiveOutreachSuggestionByProspectIdsQuery,
    GetActiveOutreachSuggestionByProspectIdsQueryVariables
>;
export const GetContactSuggestionsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'GetContactSuggestions' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ContactSuggestions' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'approvedAt' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_is_null' },
                                                        value: { kind: 'BooleanValue', value: true },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'rejectedAt' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_is_null' },
                                                        value: { kind: 'BooleanValue', value: true },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'expiresAt' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_gt' },
                                                        value: { kind: 'StringValue', value: 'now()', block: false },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'order_by' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'createdAt' },
                                            value: { kind: 'EnumValue', value: 'desc' },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'personId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'contactEmailVerified' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'contactLinkedinUrl' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'contactEmail' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'contactTitle' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'contactLocation' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'campaignId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'campaignName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'accountIndustry' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'accountWebsite' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'accountTags' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'accountName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'accountAboutUs' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'accountNumberOfEmployees' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'prospectId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'suggestionMotivation' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'contactImgUrl' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetContactSuggestionsQuery, GetContactSuggestionsQueryVariables>;
export const GetContactSuggestionByIdDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'GetContactSuggestionById' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ContactSuggestions' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'id' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_eq' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'id' },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'personId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'contactEmailVerified' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'contactLinkedinUrl' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'contactEmail' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'contactTitle' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'contactLocation' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'campaignId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'campaignName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'accountIndustry' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'accountWebsite' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'accountTags' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'accountName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'accountAboutUs' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'accountNumberOfEmployees' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'prospectId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'suggestionMotivation' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'contactImgUrl' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetContactSuggestionByIdQuery, GetContactSuggestionByIdQueryVariables>;
export const GetContactSuggestionsByGroupIdDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'GetContactSuggestionsByGroupId' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'groupId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ContactSuggestions' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'approvedAt' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_is_null' },
                                                        value: { kind: 'BooleanValue', value: true },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'rejectedAt' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_is_null' },
                                                        value: { kind: 'BooleanValue', value: true },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'expiresAt' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_gt' },
                                                        value: { kind: 'StringValue', value: 'now()', block: false },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'groupId' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_eq' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'groupId' },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'order_by' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'createdAt' },
                                            value: { kind: 'EnumValue', value: 'desc' },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'contactName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'reason' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'personId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'contactEmailVerified' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'contactLinkedinUrl' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'contactEmail' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'contactTitle' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'contactLocation' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'campaignId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'campaignName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'accountIndustry' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'accountWebsite' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'accountTags' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'accountName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'accountId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'accountAboutUs' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'accountNumberOfEmployees' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'prospectId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'suggestionMotivation' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'contactImgUrl' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetContactSuggestionsByGroupIdQuery, GetContactSuggestionsByGroupIdQueryVariables>;
export const GetContactSuggestionsCountDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'GetContactSuggestionsCount' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ContactSuggestions_aggregate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'approvedAt' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_is_null' },
                                                        value: { kind: 'BooleanValue', value: true },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'rejectedAt' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_is_null' },
                                                        value: { kind: 'BooleanValue', value: true },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'expiresAt' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_gt' },
                                                        value: { kind: 'StringValue', value: 'now()', block: false },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aggregate' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetContactSuggestionsCountQuery, GetContactSuggestionsCountQueryVariables>;
export const GetOutreachSuggestionByIdDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'GetOutreachSuggestionById' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'OutreachSuggestions_by_pk' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Message' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'direction' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'conversationId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'prospectId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'channelType' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'actionType' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'subjectLine' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'sentOn' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'approvedAt' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'linkedinUserId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'prospectFullName' } },
                                        ],
                                    },
                                },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'accountName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'unread' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'industry' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'contactLocation' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'prospectId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'linkedinUrl' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetOutreachSuggestionByIdQuery, GetOutreachSuggestionByIdQueryVariables>;
export const GetProspectMessagesHistoryDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'GetProspectMessagesHistory' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'prospectId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Messages' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'prospectId' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_eq' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'prospectId' },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'sentOn' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_is_null' },
                                                        value: { kind: 'BooleanValue', value: false },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'order_by' },
                                value: {
                                    kind: 'ListValue',
                                    values: [
                                        {
                                            kind: 'ObjectValue',
                                            fields: [
                                                {
                                                    kind: 'ObjectField',
                                                    name: { kind: 'Name', value: 'sentOn' },
                                                    value: { kind: 'EnumValue', value: 'asc_nulls_last' },
                                                },
                                            ],
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'direction' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'conversationId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'prospectId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'channelType' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'actionType' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'subjectLine' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'sentOn' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'channelType' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'linkedinUserId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'externalMessageId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'prospectFullName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deliveryStatus' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Attachments' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetProspectMessagesHistoryQuery, GetProspectMessagesHistoryQueryVariables>;
export const GetProspectNotesDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'GetProspectNotes' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'prospectId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Notes' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'prospectId' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_eq' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'prospectId' },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'discarded' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_eq' },
                                                        value: { kind: 'BooleanValue', value: false },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'order_by' },
                                value: {
                                    kind: 'ListValue',
                                    values: [
                                        {
                                            kind: 'ObjectValue',
                                            fields: [
                                                {
                                                    kind: 'ObjectField',
                                                    name: { kind: 'Name', value: 'createdAt' },
                                                    value: { kind: 'EnumValue', value: 'asc_nulls_last' },
                                                },
                                            ],
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'prospectId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'creator' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'happenedOn' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetProspectNotesQuery, GetProspectNotesQueryVariables>;
export const GetNoteByIdDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'GetNoteById' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'noteId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Notes' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'id' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_eq' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'noteId' },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'discarded' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_eq' },
                                                        value: { kind: 'BooleanValue', value: false },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'prospectId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'creator' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetNoteByIdQuery, GetNoteByIdQueryVariables>;
export const GetAllProspectSubjectLinesDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'GetAllProspectSubjectLines' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'prospectId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Messages' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'prospectId' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_eq' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'prospectId' },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'sentOn' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_is_null' },
                                                        value: { kind: 'BooleanValue', value: false },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'order_by' },
                                value: {
                                    kind: 'ListValue',
                                    values: [
                                        {
                                            kind: 'ObjectValue',
                                            fields: [
                                                {
                                                    kind: 'ObjectField',
                                                    name: { kind: 'Name', value: 'sentOn' },
                                                    value: { kind: 'EnumValue', value: 'desc_nulls_last' },
                                                },
                                            ],
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'subjectLine' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetAllProspectSubjectLinesQuery, GetAllProspectSubjectLinesQueryVariables>;
export const GetLastProspectMessageDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'GetLastProspectMessage' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'prospectId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Messages' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'prospectId' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_eq' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'prospectId' },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'sentOn' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_is_null' },
                                                        value: { kind: 'BooleanValue', value: false },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'order_by' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'sentOn' },
                                            value: { kind: 'EnumValue', value: 'desc' },
                                        },
                                    ],
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'IntValue', value: '1' },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'direction' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'conversationId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'prospectId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'channelType' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'actionType' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'subjectLine' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'sentOn' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'channelType' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'linkedinUserId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'externalMessageId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'prospectFullName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deliveryStatus' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetLastProspectMessageQuery, GetLastProspectMessageQueryVariables>;
export const GetMessagesSentFromAppDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'GetMessagesSentFromApp' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Messages_aggregate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'isSentFromApp' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_eq' },
                                                        value: { kind: 'BooleanValue', value: true },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'sentOn' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_is_null' },
                                                        value: { kind: 'BooleanValue', value: false },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'direction' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_eq' },
                                                        value: { kind: 'StringValue', value: 'out', block: false },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aggregate' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetMessagesSentFromAppQuery, GetMessagesSentFromAppQueryVariables>;
export const GetAssistantSettingsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'GetAssistantSettings' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'AssistantSettings' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'purpose' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'language' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'languages' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'tone' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'context' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetAssistantSettingsQuery, GetAssistantSettingsQueryVariables>;
export const GroupsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'Groups' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Groups' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'order_by' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'createdAt' },
                                            value: { kind: 'EnumValue', value: 'asc' },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'default' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'assistantSettingsId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'imgSrc' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GroupsQuery, GroupsQueryVariables>;
export const GetUserContactsByGroupIdsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'GetUserContactsByGroupIds' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'groupIds' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NonNullType',
                            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NonNullType',
                            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserContacts_order_by' } },
                        },
                    },
                    defaultValue: {
                        kind: 'ListValue',
                        values: [
                            {
                                kind: 'ObjectValue',
                                fields: [
                                    {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'createdAt' },
                                        value: { kind: 'EnumValue', value: 'desc' },
                                    },
                                ],
                            },
                        ],
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'UserContacts' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: '_GroupToUserContacts' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: 'Group' },
                                                        value: {
                                                            kind: 'ObjectValue',
                                                            fields: [
                                                                {
                                                                    kind: 'ObjectField',
                                                                    name: { kind: 'Name', value: 'id' },
                                                                    value: {
                                                                        kind: 'ObjectValue',
                                                                        fields: [
                                                                            {
                                                                                kind: 'ObjectField',
                                                                                name: { kind: 'Name', value: '_in' },
                                                                                value: {
                                                                                    kind: 'Variable',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'groupIds',
                                                                                    },
                                                                                },
                                                                            },
                                                                        ],
                                                                    },
                                                                },
                                                            ],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'order_by' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'prospectId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: '_GroupToUserContacts' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'Group' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'imgSrc' } },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'totalCount' },
                        name: { kind: 'Name', value: 'UserContacts_aggregate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: '_GroupToUserContacts' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: 'Group' },
                                                        value: {
                                                            kind: 'ObjectValue',
                                                            fields: [
                                                                {
                                                                    kind: 'ObjectField',
                                                                    name: { kind: 'Name', value: 'id' },
                                                                    value: {
                                                                        kind: 'ObjectValue',
                                                                        fields: [
                                                                            {
                                                                                kind: 'ObjectField',
                                                                                name: { kind: 'Name', value: '_in' },
                                                                                value: {
                                                                                    kind: 'Variable',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'groupIds',
                                                                                    },
                                                                                },
                                                                            },
                                                                        ],
                                                                    },
                                                                },
                                                            ],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aggregate' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetUserContactsByGroupIdsQuery, GetUserContactsByGroupIdsQueryVariables>;
export const GetUserContactsProspectIdsByGroupIdsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'GetUserContactsProspectIdsByGroupIds' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'groupIds' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NonNullType',
                            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NonNullType',
                            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserContacts_order_by' } },
                        },
                    },
                    defaultValue: {
                        kind: 'ListValue',
                        values: [
                            {
                                kind: 'ObjectValue',
                                fields: [
                                    {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'createdAt' },
                                        value: { kind: 'EnumValue', value: 'desc' },
                                    },
                                ],
                            },
                        ],
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'UserContacts' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: '_GroupToUserContacts' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: 'Group' },
                                                        value: {
                                                            kind: 'ObjectValue',
                                                            fields: [
                                                                {
                                                                    kind: 'ObjectField',
                                                                    name: { kind: 'Name', value: 'id' },
                                                                    value: {
                                                                        kind: 'ObjectValue',
                                                                        fields: [
                                                                            {
                                                                                kind: 'ObjectField',
                                                                                name: { kind: 'Name', value: '_in' },
                                                                                value: {
                                                                                    kind: 'Variable',
                                                                                    name: {
                                                                                        kind: 'Name',
                                                                                        value: 'groupIds',
                                                                                    },
                                                                                },
                                                                            },
                                                                        ],
                                                                    },
                                                                },
                                                            ],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'order_by' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'prospectId' } }],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GetUserContactsProspectIdsByGroupIdsQuery,
    GetUserContactsProspectIdsByGroupIdsQueryVariables
>;
export const GetUserContactByProspectIdDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'GetUserContactByProspectId' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'prospectId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'UserContacts' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'prospectId' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_eq' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'prospectId' },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'prospectId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: '_GroupToUserContacts' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'Group' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'default' } },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'assistantSettingsId' },
                                                        },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'imgSrc' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetUserContactByProspectIdQuery, GetUserContactByProspectIdQueryVariables>;
export const GetTotalNumberOfUserContactsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'GetTotalNumberOfUserContacts' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'UserContacts_aggregate' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aggregate' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetTotalNumberOfUserContactsQuery, GetTotalNumberOfUserContactsQueryVariables>;
export const GetTotalNumberOfUserContactsInAnyPlaybookDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'GetTotalNumberOfUserContactsInAnyPlaybook' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'UserContacts_aggregate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: '_GroupToUserContacts' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: 'Group' },
                                                        value: {
                                                            kind: 'ObjectValue',
                                                            fields: [
                                                                {
                                                                    kind: 'ObjectField',
                                                                    name: { kind: 'Name', value: 'id' },
                                                                    value: {
                                                                        kind: 'ObjectValue',
                                                                        fields: [
                                                                            {
                                                                                kind: 'ObjectField',
                                                                                name: {
                                                                                    kind: 'Name',
                                                                                    value: '_is_null',
                                                                                },
                                                                                value: {
                                                                                    kind: 'BooleanValue',
                                                                                    value: false,
                                                                                },
                                                                            },
                                                                        ],
                                                                    },
                                                                },
                                                            ],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aggregate' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GetTotalNumberOfUserContactsInAnyPlaybookQuery,
    GetTotalNumberOfUserContactsInAnyPlaybookQueryVariables
>;
export const GetUserContactByIdDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'GetUserContactById' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'userContactId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'UserContacts' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'id' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_eq' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'userContactId' },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'prospectId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: '_GroupToUserContacts' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'Group' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'default' } },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'assistantSettingsId' },
                                                        },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'imgSrc' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetUserContactByIdQuery, GetUserContactByIdQueryVariables>;
export const GetUserContactsByIdsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'GetUserContactsByIds' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NonNullType',
                            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'UserContacts' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'id' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_in' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'ids' },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'prospectId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: '_GroupToUserContacts' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'Group' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'default' } },
                                                        {
                                                            kind: 'Field',
                                                            name: { kind: 'Name', value: 'assistantSettingsId' },
                                                        },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'imgSrc' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetUserContactsByIdsQuery, GetUserContactsByIdsQueryVariables>;
export const GetAllUserContactsInAnyPlaybookDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'GetAllUserContactsInAnyPlaybook' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NonNullType',
                            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserContacts_order_by' } },
                        },
                    },
                    defaultValue: {
                        kind: 'ListValue',
                        values: [
                            {
                                kind: 'ObjectValue',
                                fields: [
                                    {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'createdAt' },
                                        value: { kind: 'EnumValue', value: 'desc' },
                                    },
                                ],
                            },
                        ],
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'UserContacts' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: '_GroupToUserContacts' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: 'Group' },
                                                        value: {
                                                            kind: 'ObjectValue',
                                                            fields: [
                                                                {
                                                                    kind: 'ObjectField',
                                                                    name: { kind: 'Name', value: 'id' },
                                                                    value: {
                                                                        kind: 'ObjectValue',
                                                                        fields: [
                                                                            {
                                                                                kind: 'ObjectField',
                                                                                name: {
                                                                                    kind: 'Name',
                                                                                    value: '_is_null',
                                                                                },
                                                                                value: {
                                                                                    kind: 'BooleanValue',
                                                                                    value: false,
                                                                                },
                                                                            },
                                                                        ],
                                                                    },
                                                                },
                                                            ],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'order_by' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'prospectId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: '_GroupToUserContacts' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'Group' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'imgSrc' } },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'totalCount' },
                        name: { kind: 'Name', value: 'UserContacts_aggregate' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aggregate' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetAllUserContactsInAnyPlaybookQuery, GetAllUserContactsInAnyPlaybookQueryVariables>;
export const GetAllUserContactsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'GetAllUserContacts' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NonNullType',
                            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserContacts_order_by' } },
                        },
                    },
                    defaultValue: {
                        kind: 'ListValue',
                        values: [
                            {
                                kind: 'ObjectValue',
                                fields: [
                                    {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'createdAt' },
                                        value: { kind: 'EnumValue', value: 'desc' },
                                    },
                                ],
                            },
                        ],
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'UserContacts' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'order_by' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'prospectId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: '_GroupToUserContacts' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'Group' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'imgSrc' } },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'totalCount' },
                        name: { kind: 'Name', value: 'UserContacts_aggregate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: '_GroupToUserContacts' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: 'Group' },
                                                        value: {
                                                            kind: 'ObjectValue',
                                                            fields: [
                                                                {
                                                                    kind: 'ObjectField',
                                                                    name: { kind: 'Name', value: 'id' },
                                                                    value: {
                                                                        kind: 'ObjectValue',
                                                                        fields: [
                                                                            {
                                                                                kind: 'ObjectField',
                                                                                name: {
                                                                                    kind: 'Name',
                                                                                    value: '_is_null',
                                                                                },
                                                                                value: {
                                                                                    kind: 'BooleanValue',
                                                                                    value: false,
                                                                                },
                                                                            },
                                                                        ],
                                                                    },
                                                                },
                                                            ],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aggregate' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetAllUserContactsQuery, GetAllUserContactsQueryVariables>;
export const GetActionsCountDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'GetActionsCount' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'notYetSent' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                    },
                    defaultValue: { kind: 'BooleanValue', value: true },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'scheduledOnLt' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'timestamp' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'OutreachSuggestions_aggregate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'Message' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: 'sentOn' },
                                                        value: {
                                                            kind: 'ObjectValue',
                                                            fields: [
                                                                {
                                                                    kind: 'ObjectField',
                                                                    name: { kind: 'Name', value: '_is_null' },
                                                                    value: {
                                                                        kind: 'Variable',
                                                                        name: { kind: 'Name', value: 'notYetSent' },
                                                                    },
                                                                },
                                                            ],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'scheduledOn' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_lt' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'scheduledOnLt' },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aggregate' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetActionsCountQuery, GetActionsCountQueryVariables>;
export const GetPlaybooksDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'GetPlaybooks' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Groups' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'name' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_ilike' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'name' },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'order_by' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'createdAt' },
                                            value: { kind: 'EnumValue', value: 'asc' },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'default' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'assistantSettingsId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'imgSrc' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'AssistantSetting' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'purpose' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'language' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'languages' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'tone' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'targetGroup' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'context' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'defaultChannel' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetPlaybooksQuery, GetPlaybooksQueryVariables>;
export const GetPlaybookByGroupIdDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'GetPlaybookByGroupId' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'groupId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Groups' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'id' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_eq' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'groupId' },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'default' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'assistantSettingsId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'imgSrc' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'AssistantSetting' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'purpose' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'language' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'languages' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'tone' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'context' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'targetGroup' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'defaultChannel' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: '_GroupToUserContacts' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'UserContact' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetPlaybookByGroupIdQuery, GetPlaybookByGroupIdQueryVariables>;
export const GetOutreachCompletionMetricsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'GetOutreachCompletionMetrics' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'timestamp' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'timestamp' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'OutreachCompletionMetrics' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: '_and' },
                                            value: {
                                                kind: 'ListValue',
                                                values: [
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'date' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_gte' },
                                                                            value: {
                                                                                kind: 'Variable',
                                                                                name: {
                                                                                    kind: 'Name',
                                                                                    value: 'startDate',
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'date' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_lte' },
                                                                            value: {
                                                                                kind: 'Variable',
                                                                                name: {
                                                                                    kind: 'Name',
                                                                                    value: 'endDate',
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'organizationId' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_eq' },
                                                                            value: {
                                                                                kind: 'Variable',
                                                                                name: {
                                                                                    kind: 'Name',
                                                                                    value: 'organizationId',
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'order_by' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'date' },
                                            value: { kind: 'EnumValue', value: 'asc' },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'trailingTotalCompleted' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'trailingTotalGoalsReached' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'trailingTotalSuggestions' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'completedSameDay' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'completedWithin2Days' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'completedWithin3Days' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'completedWithin7Days' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'completedWithin30Days' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'goalsReachedSameDay' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'goalsReachedWithin2Days' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'goalsReachedWithin3Days' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'goalsReachedWithin7Days' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'goalsReachedWithin30Days' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'suggestionsSameDay' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'suggestionsWithin2Days' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'suggestionsWithin3Days' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'suggestionsWithin7Days' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'suggestionsWithin30Days' } },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'numberOfUsers' },
                        name: { kind: 'Name', value: 'OutreachSuggestions_aggregate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'distinct_on' },
                                value: { kind: 'EnumValue', value: 'userId' },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aggregate' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'numberOfUsersBeforePeriod' },
                        name: { kind: 'Name', value: 'OutreachSuggestions_aggregate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'distinct_on' },
                                value: { kind: 'EnumValue', value: 'userId' },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'createdAt' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_lt' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'startDate' },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aggregate' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetOutreachCompletionMetricsQuery, GetOutreachCompletionMetricsQueryVariables>;
export const GetDashboardMetricsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'GetDashboardMetrics' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'timestamp' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'timestamp' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'previousStartDate' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'timestamp' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'numberOfSentMessages' },
                        name: { kind: 'Name', value: 'Messages_aggregate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: '_and' },
                                            value: {
                                                kind: 'ListValue',
                                                values: [
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'organizationId' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_eq' },
                                                                            value: {
                                                                                kind: 'Variable',
                                                                                name: {
                                                                                    kind: 'Name',
                                                                                    value: 'organizationId',
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'direction' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_eq' },
                                                                            value: {
                                                                                kind: 'StringValue',
                                                                                value: 'out',
                                                                                block: false,
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'sentOn' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_is_null' },
                                                                            value: {
                                                                                kind: 'BooleanValue',
                                                                                value: false,
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'sentOn' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_gte' },
                                                                            value: {
                                                                                kind: 'Variable',
                                                                                name: {
                                                                                    kind: 'Name',
                                                                                    value: 'startDate',
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'sentOn' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_lte' },
                                                                            value: {
                                                                                kind: 'Variable',
                                                                                name: {
                                                                                    kind: 'Name',
                                                                                    value: 'endDate',
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'actionType' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_in' },
                                                                            value: {
                                                                                kind: 'ListValue',
                                                                                values: [
                                                                                    {
                                                                                        kind: 'StringValue',
                                                                                        value: 'LINKEDIN_MESSAGE',
                                                                                        block: false,
                                                                                    },
                                                                                    {
                                                                                        kind: 'StringValue',
                                                                                        value: 'EMAIL_MESSAGE',
                                                                                        block: false,
                                                                                    },
                                                                                ],
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aggregate' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'nodes' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'prospectId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'isSentFromApp' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'channelType' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'sentOn' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'numberOfReceivedMessages' },
                        name: { kind: 'Name', value: 'Messages_aggregate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: '_and' },
                                            value: {
                                                kind: 'ListValue',
                                                values: [
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'organizationId' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_eq' },
                                                                            value: {
                                                                                kind: 'Variable',
                                                                                name: {
                                                                                    kind: 'Name',
                                                                                    value: 'organizationId',
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'direction' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_eq' },
                                                                            value: {
                                                                                kind: 'StringValue',
                                                                                value: 'in',
                                                                                block: false,
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'sentOn' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_is_null' },
                                                                            value: {
                                                                                kind: 'BooleanValue',
                                                                                value: false,
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'sentOn' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_gte' },
                                                                            value: {
                                                                                kind: 'Variable',
                                                                                name: {
                                                                                    kind: 'Name',
                                                                                    value: 'startDate',
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'sentOn' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_lte' },
                                                                            value: {
                                                                                kind: 'Variable',
                                                                                name: {
                                                                                    kind: 'Name',
                                                                                    value: 'endDate',
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'actionType' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_in' },
                                                                            value: {
                                                                                kind: 'ListValue',
                                                                                values: [
                                                                                    {
                                                                                        kind: 'StringValue',
                                                                                        value: 'LINKEDIN_MESSAGE',
                                                                                        block: false,
                                                                                    },
                                                                                    {
                                                                                        kind: 'StringValue',
                                                                                        value: 'EMAIL_MESSAGE',
                                                                                        block: false,
                                                                                    },
                                                                                ],
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aggregate' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'nodes' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'prospectId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'channelType' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'sentOn' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'numberOfNewConnections' },
                        name: { kind: 'Name', value: 'Messages_aggregate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: '_and' },
                                            value: {
                                                kind: 'ListValue',
                                                values: [
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'organizationId' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_eq' },
                                                                            value: {
                                                                                kind: 'Variable',
                                                                                name: {
                                                                                    kind: 'Name',
                                                                                    value: 'organizationId',
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'direction' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_eq' },
                                                                            value: {
                                                                                kind: 'StringValue',
                                                                                value: 'in',
                                                                                block: false,
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'sentOn' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_is_null' },
                                                                            value: {
                                                                                kind: 'BooleanValue',
                                                                                value: false,
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'sentOn' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_gte' },
                                                                            value: {
                                                                                kind: 'Variable',
                                                                                name: {
                                                                                    kind: 'Name',
                                                                                    value: 'startDate',
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'sentOn' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_lte' },
                                                                            value: {
                                                                                kind: 'Variable',
                                                                                name: {
                                                                                    kind: 'Name',
                                                                                    value: 'endDate',
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'actionType' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_eq' },
                                                                            value: {
                                                                                kind: 'StringValue',
                                                                                value: 'LINKEDIN_CONNECTION_REQUEST_ACCEPTED',
                                                                                block: false,
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aggregate' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'nodes' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'prospectId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'prospectFullName' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'channelType' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'actionType' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'sentOn' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'numberOfSentMessagesPrev' },
                        name: { kind: 'Name', value: 'Messages_aggregate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: '_and' },
                                            value: {
                                                kind: 'ListValue',
                                                values: [
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'organizationId' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_eq' },
                                                                            value: {
                                                                                kind: 'Variable',
                                                                                name: {
                                                                                    kind: 'Name',
                                                                                    value: 'organizationId',
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'direction' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_eq' },
                                                                            value: {
                                                                                kind: 'StringValue',
                                                                                value: 'out',
                                                                                block: false,
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'sentOn' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_is_null' },
                                                                            value: {
                                                                                kind: 'BooleanValue',
                                                                                value: false,
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'sentOn' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_gte' },
                                                                            value: {
                                                                                kind: 'Variable',
                                                                                name: {
                                                                                    kind: 'Name',
                                                                                    value: 'previousStartDate',
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'sentOn' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_lt' },
                                                                            value: {
                                                                                kind: 'Variable',
                                                                                name: {
                                                                                    kind: 'Name',
                                                                                    value: 'startDate',
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'actionType' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_in' },
                                                                            value: {
                                                                                kind: 'ListValue',
                                                                                values: [
                                                                                    {
                                                                                        kind: 'StringValue',
                                                                                        value: 'LINKEDIN_MESSAGE',
                                                                                        block: false,
                                                                                    },
                                                                                    {
                                                                                        kind: 'StringValue',
                                                                                        value: 'EMAIL_MESSAGE',
                                                                                        block: false,
                                                                                    },
                                                                                ],
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aggregate' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'nodes' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'prospectId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'isSentFromApp' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'channelType' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'sentOn' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'numberOfReceivedMessagesPrev' },
                        name: { kind: 'Name', value: 'Messages_aggregate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: '_and' },
                                            value: {
                                                kind: 'ListValue',
                                                values: [
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'organizationId' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_eq' },
                                                                            value: {
                                                                                kind: 'Variable',
                                                                                name: {
                                                                                    kind: 'Name',
                                                                                    value: 'organizationId',
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'direction' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_eq' },
                                                                            value: {
                                                                                kind: 'StringValue',
                                                                                value: 'in',
                                                                                block: false,
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'sentOn' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_is_null' },
                                                                            value: {
                                                                                kind: 'BooleanValue',
                                                                                value: false,
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'sentOn' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_gte' },
                                                                            value: {
                                                                                kind: 'Variable',
                                                                                name: {
                                                                                    kind: 'Name',
                                                                                    value: 'previousStartDate',
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'sentOn' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_lt' },
                                                                            value: {
                                                                                kind: 'Variable',
                                                                                name: {
                                                                                    kind: 'Name',
                                                                                    value: 'startDate',
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'actionType' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_in' },
                                                                            value: {
                                                                                kind: 'ListValue',
                                                                                values: [
                                                                                    {
                                                                                        kind: 'StringValue',
                                                                                        value: 'LINKEDIN_MESSAGE',
                                                                                        block: false,
                                                                                    },
                                                                                    {
                                                                                        kind: 'StringValue',
                                                                                        value: 'EMAIL_MESSAGE',
                                                                                        block: false,
                                                                                    },
                                                                                ],
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aggregate' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'nodes' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'prospectId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'channelType' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'sentOn' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'numberOfNewConnectionsPrev' },
                        name: { kind: 'Name', value: 'Messages_aggregate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: '_and' },
                                            value: {
                                                kind: 'ListValue',
                                                values: [
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'organizationId' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_eq' },
                                                                            value: {
                                                                                kind: 'Variable',
                                                                                name: {
                                                                                    kind: 'Name',
                                                                                    value: 'organizationId',
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'direction' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_eq' },
                                                                            value: {
                                                                                kind: 'StringValue',
                                                                                value: 'in',
                                                                                block: false,
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'sentOn' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_is_null' },
                                                                            value: {
                                                                                kind: 'BooleanValue',
                                                                                value: false,
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'sentOn' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_gte' },
                                                                            value: {
                                                                                kind: 'Variable',
                                                                                name: {
                                                                                    kind: 'Name',
                                                                                    value: 'previousStartDate',
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'sentOn' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_lt' },
                                                                            value: {
                                                                                kind: 'Variable',
                                                                                name: {
                                                                                    kind: 'Name',
                                                                                    value: 'startDate',
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'actionType' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_eq' },
                                                                            value: {
                                                                                kind: 'StringValue',
                                                                                value: 'LINKEDIN_CONNECTION_REQUEST_ACCEPTED',
                                                                                block: false,
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aggregate' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'nodes' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'prospectId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'prospectFullName' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'channelType' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'actionType' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'sentOn' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetDashboardMetricsQuery, GetDashboardMetricsQueryVariables>;
export const GetTeamDashboardMetricsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'GetTeamDashboardMetrics' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'timestamp' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'timestamp' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'previousStartDate' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'timestamp' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'organizationId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'numberOfSentMessages' },
                        name: { kind: 'Name', value: 'Messages_aggregate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: '_and' },
                                            value: {
                                                kind: 'ListValue',
                                                values: [
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'organizationId' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_eq' },
                                                                            value: {
                                                                                kind: 'Variable',
                                                                                name: {
                                                                                    kind: 'Name',
                                                                                    value: 'organizationId',
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'direction' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_eq' },
                                                                            value: {
                                                                                kind: 'StringValue',
                                                                                value: 'out',
                                                                                block: false,
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'sentOn' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_is_null' },
                                                                            value: {
                                                                                kind: 'BooleanValue',
                                                                                value: false,
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'sentOn' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_gte' },
                                                                            value: {
                                                                                kind: 'Variable',
                                                                                name: {
                                                                                    kind: 'Name',
                                                                                    value: 'startDate',
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'sentOn' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_lte' },
                                                                            value: {
                                                                                kind: 'Variable',
                                                                                name: {
                                                                                    kind: 'Name',
                                                                                    value: 'endDate',
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'actionType' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_in' },
                                                                            value: {
                                                                                kind: 'ListValue',
                                                                                values: [
                                                                                    {
                                                                                        kind: 'StringValue',
                                                                                        value: 'LINKEDIN_MESSAGE',
                                                                                        block: false,
                                                                                    },
                                                                                    {
                                                                                        kind: 'StringValue',
                                                                                        value: 'EMAIL_MESSAGE',
                                                                                        block: false,
                                                                                    },
                                                                                ],
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aggregate' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'nodes' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'prospectId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'isSentFromApp' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'channelType' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'sentOn' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'numberOfReceivedMessages' },
                        name: { kind: 'Name', value: 'Messages_aggregate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: '_and' },
                                            value: {
                                                kind: 'ListValue',
                                                values: [
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'organizationId' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_eq' },
                                                                            value: {
                                                                                kind: 'Variable',
                                                                                name: {
                                                                                    kind: 'Name',
                                                                                    value: 'organizationId',
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'direction' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_eq' },
                                                                            value: {
                                                                                kind: 'StringValue',
                                                                                value: 'in',
                                                                                block: false,
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'sentOn' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_is_null' },
                                                                            value: {
                                                                                kind: 'BooleanValue',
                                                                                value: false,
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'sentOn' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_gte' },
                                                                            value: {
                                                                                kind: 'Variable',
                                                                                name: {
                                                                                    kind: 'Name',
                                                                                    value: 'startDate',
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'sentOn' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_lte' },
                                                                            value: {
                                                                                kind: 'Variable',
                                                                                name: {
                                                                                    kind: 'Name',
                                                                                    value: 'endDate',
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'actionType' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_in' },
                                                                            value: {
                                                                                kind: 'ListValue',
                                                                                values: [
                                                                                    {
                                                                                        kind: 'StringValue',
                                                                                        value: 'LINKEDIN_MESSAGE',
                                                                                        block: false,
                                                                                    },
                                                                                    {
                                                                                        kind: 'StringValue',
                                                                                        value: 'EMAIL_MESSAGE',
                                                                                        block: false,
                                                                                    },
                                                                                ],
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aggregate' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'nodes' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'prospectId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'channelType' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'sentOn' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'numberOfNewConnections' },
                        name: { kind: 'Name', value: 'Messages_aggregate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: '_and' },
                                            value: {
                                                kind: 'ListValue',
                                                values: [
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'organizationId' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_eq' },
                                                                            value: {
                                                                                kind: 'Variable',
                                                                                name: {
                                                                                    kind: 'Name',
                                                                                    value: 'organizationId',
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'direction' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_eq' },
                                                                            value: {
                                                                                kind: 'StringValue',
                                                                                value: 'in',
                                                                                block: false,
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'sentOn' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_is_null' },
                                                                            value: {
                                                                                kind: 'BooleanValue',
                                                                                value: false,
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'sentOn' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_gte' },
                                                                            value: {
                                                                                kind: 'Variable',
                                                                                name: {
                                                                                    kind: 'Name',
                                                                                    value: 'startDate',
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'sentOn' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_lte' },
                                                                            value: {
                                                                                kind: 'Variable',
                                                                                name: {
                                                                                    kind: 'Name',
                                                                                    value: 'endDate',
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'actionType' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_eq' },
                                                                            value: {
                                                                                kind: 'StringValue',
                                                                                value: 'LINKEDIN_CONNECTION_REQUEST_ACCEPTED',
                                                                                block: false,
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aggregate' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'nodes' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'prospectId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'prospectFullName' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'channelType' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'actionType' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'sentOn' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'numberOfSentMessagesPrev' },
                        name: { kind: 'Name', value: 'Messages_aggregate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: '_and' },
                                            value: {
                                                kind: 'ListValue',
                                                values: [
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'organizationId' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_eq' },
                                                                            value: {
                                                                                kind: 'Variable',
                                                                                name: {
                                                                                    kind: 'Name',
                                                                                    value: 'organizationId',
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'direction' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_eq' },
                                                                            value: {
                                                                                kind: 'StringValue',
                                                                                value: 'out',
                                                                                block: false,
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'sentOn' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_is_null' },
                                                                            value: {
                                                                                kind: 'BooleanValue',
                                                                                value: false,
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'sentOn' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_gte' },
                                                                            value: {
                                                                                kind: 'Variable',
                                                                                name: {
                                                                                    kind: 'Name',
                                                                                    value: 'previousStartDate',
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'sentOn' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_lt' },
                                                                            value: {
                                                                                kind: 'Variable',
                                                                                name: {
                                                                                    kind: 'Name',
                                                                                    value: 'startDate',
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'actionType' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_in' },
                                                                            value: {
                                                                                kind: 'ListValue',
                                                                                values: [
                                                                                    {
                                                                                        kind: 'StringValue',
                                                                                        value: 'LINKEDIN_MESSAGE',
                                                                                        block: false,
                                                                                    },
                                                                                    {
                                                                                        kind: 'StringValue',
                                                                                        value: 'EMAIL_MESSAGE',
                                                                                        block: false,
                                                                                    },
                                                                                ],
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aggregate' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'nodes' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'prospectId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'isSentFromApp' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'channelType' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'sentOn' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'numberOfReceivedMessagesPrev' },
                        name: { kind: 'Name', value: 'Messages_aggregate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: '_and' },
                                            value: {
                                                kind: 'ListValue',
                                                values: [
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'organizationId' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_eq' },
                                                                            value: {
                                                                                kind: 'Variable',
                                                                                name: {
                                                                                    kind: 'Name',
                                                                                    value: 'organizationId',
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'direction' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_eq' },
                                                                            value: {
                                                                                kind: 'StringValue',
                                                                                value: 'in',
                                                                                block: false,
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'sentOn' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_is_null' },
                                                                            value: {
                                                                                kind: 'BooleanValue',
                                                                                value: false,
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'sentOn' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_gte' },
                                                                            value: {
                                                                                kind: 'Variable',
                                                                                name: {
                                                                                    kind: 'Name',
                                                                                    value: 'previousStartDate',
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'sentOn' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_lt' },
                                                                            value: {
                                                                                kind: 'Variable',
                                                                                name: {
                                                                                    kind: 'Name',
                                                                                    value: 'startDate',
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'actionType' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_in' },
                                                                            value: {
                                                                                kind: 'ListValue',
                                                                                values: [
                                                                                    {
                                                                                        kind: 'StringValue',
                                                                                        value: 'LINKEDIN_MESSAGE',
                                                                                        block: false,
                                                                                    },
                                                                                    {
                                                                                        kind: 'StringValue',
                                                                                        value: 'EMAIL_MESSAGE',
                                                                                        block: false,
                                                                                    },
                                                                                ],
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aggregate' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'nodes' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'prospectId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'channelType' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'sentOn' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'numberOfNewConnectionsPrev' },
                        name: { kind: 'Name', value: 'Messages_aggregate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: '_and' },
                                            value: {
                                                kind: 'ListValue',
                                                values: [
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'organizationId' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_eq' },
                                                                            value: {
                                                                                kind: 'Variable',
                                                                                name: {
                                                                                    kind: 'Name',
                                                                                    value: 'organizationId',
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'direction' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_eq' },
                                                                            value: {
                                                                                kind: 'StringValue',
                                                                                value: 'in',
                                                                                block: false,
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'sentOn' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_is_null' },
                                                                            value: {
                                                                                kind: 'BooleanValue',
                                                                                value: false,
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'sentOn' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_gte' },
                                                                            value: {
                                                                                kind: 'Variable',
                                                                                name: {
                                                                                    kind: 'Name',
                                                                                    value: 'previousStartDate',
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'sentOn' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_lt' },
                                                                            value: {
                                                                                kind: 'Variable',
                                                                                name: {
                                                                                    kind: 'Name',
                                                                                    value: 'startDate',
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'actionType' },
                                                                value: {
                                                                    kind: 'ObjectValue',
                                                                    fields: [
                                                                        {
                                                                            kind: 'ObjectField',
                                                                            name: { kind: 'Name', value: '_eq' },
                                                                            value: {
                                                                                kind: 'StringValue',
                                                                                value: 'LINKEDIN_CONNECTION_REQUEST_ACCEPTED',
                                                                                block: false,
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            },
                                                        ],
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aggregate' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'nodes' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'prospectId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'prospectFullName' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'channelType' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'actionType' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'sentOn' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetTeamDashboardMetricsQuery, GetTeamDashboardMetricsQueryVariables>;
export const GetMessagesSentTodayDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'GetMessagesSentToday' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'startDate' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'timestamp' } },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'endDate' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'timestamp' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Messages_aggregate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'isSentFromApp' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_eq' },
                                                        value: { kind: 'BooleanValue', value: true },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'direction' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_eq' },
                                                        value: { kind: 'StringValue', value: 'out', block: false },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'sentOn' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_gte' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'startDate' },
                                                        },
                                                    },
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_lte' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'endDate' },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aggregate' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetMessagesSentTodayQuery, GetMessagesSentTodayQueryVariables>;
export const OnOutreachSuggestionAddedDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'subscription',
            name: { kind: 'Name', value: 'OnOutreachSuggestionAdded' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'sentOn' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'scheduledOnLt' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'timestamp' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'OutreachSuggestions' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'scheduledOn' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_lt' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'scheduledOnLt' },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'Message' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: 'sentOn' },
                                                        value: {
                                                            kind: 'ObjectValue',
                                                            fields: [
                                                                {
                                                                    kind: 'ObjectField',
                                                                    name: { kind: 'Name', value: '_is_null' },
                                                                    value: {
                                                                        kind: 'Variable',
                                                                        name: { kind: 'Name', value: 'sentOn' },
                                                                    },
                                                                },
                                                            ],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'order_by' },
                                value: {
                                    kind: 'ListValue',
                                    values: [
                                        {
                                            kind: 'ObjectValue',
                                            fields: [
                                                {
                                                    kind: 'ObjectField',
                                                    name: { kind: 'Name', value: 'score' },
                                                    value: { kind: 'EnumValue', value: 'desc' },
                                                },
                                            ],
                                        },
                                        {
                                            kind: 'ObjectValue',
                                            fields: [
                                                {
                                                    kind: 'ObjectField',
                                                    name: { kind: 'Name', value: 'Message' },
                                                    value: {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'createdAt' },
                                                                value: { kind: 'EnumValue', value: 'desc' },
                                                            },
                                                        ],
                                                    },
                                                },
                                            ],
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'accountName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'prospectId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'unread' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'scheduledOn' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Message' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'subjectLine' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'actionType' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'channelType' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'linkedinUserId' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'ConversationMetaData' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'numberOfMessages' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'isNextMessageFollowUp' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'messagesPerDay' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'lastNetworkUpdateAt' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'youThemMessageRatio' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'lastUserNoteAt' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'hasMessagesSentFromApp' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'medianMessageLength' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'connectionStatus' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'lastTimeToAnswer' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'userContactAddedAt' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'lastMessageAt' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'numberOfMessagesLastDay' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'numberOfMessagesLastWeek' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'userContactFromOnboarding' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<OnOutreachSuggestionAddedSubscription, OnOutreachSuggestionAddedSubscriptionVariables>;
export const ActionsSubscriptionByProspectIdsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'subscription',
            name: { kind: 'Name', value: 'ActionsSubscriptionByProspectIds' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'scheduledOnLt' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'timestamp' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'prospectIds' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'ListType',
                            type: {
                                kind: 'NonNullType',
                                type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'OutreachSuggestions' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'scheduledOn' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_lt' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'scheduledOnLt' },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'Message' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: 'sentOn' },
                                                        value: {
                                                            kind: 'ObjectValue',
                                                            fields: [
                                                                {
                                                                    kind: 'ObjectField',
                                                                    name: { kind: 'Name', value: '_is_null' },
                                                                    value: { kind: 'BooleanValue', value: true },
                                                                },
                                                            ],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'prospectId' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_in' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'prospectIds' },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'order_by' },
                                value: {
                                    kind: 'ListValue',
                                    values: [
                                        {
                                            kind: 'ObjectValue',
                                            fields: [
                                                {
                                                    kind: 'ObjectField',
                                                    name: { kind: 'Name', value: 'score' },
                                                    value: { kind: 'EnumValue', value: 'desc' },
                                                },
                                            ],
                                        },
                                        {
                                            kind: 'ObjectValue',
                                            fields: [
                                                {
                                                    kind: 'ObjectField',
                                                    name: { kind: 'Name', value: 'Message' },
                                                    value: {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'createdAt' },
                                                                value: { kind: 'EnumValue', value: 'desc' },
                                                            },
                                                        ],
                                                    },
                                                },
                                            ],
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'prospectId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'accountName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'unread' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'isGenerating' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'goal' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Message' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'subjectLine' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'ConversationMetaData' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'numberOfMessages' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'isNextMessageFollowUp' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'messagesPerDay' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'lastNetworkUpdateAt' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'youThemMessageRatio' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'lastUserNoteAt' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'hasMessagesSentFromApp' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'medianMessageLength' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'connectionStatus' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'lastTimeToAnswer' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'userContactAddedAt' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'lastMessageAt' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'numberOfMessagesLastDay' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'numberOfMessagesLastWeek' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'userContactFromOnboarding' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'topics' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ActionsSubscriptionByProspectIdsSubscription,
    ActionsSubscriptionByProspectIdsSubscriptionVariables
>;
export const ActionsSubscriptionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'subscription',
            name: { kind: 'Name', value: 'ActionsSubscription' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'scheduledOnLt' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'timestamp' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'OutreachSuggestions' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'scheduledOn' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_lt' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'scheduledOnLt' },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'Message' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: 'sentOn' },
                                                        value: {
                                                            kind: 'ObjectValue',
                                                            fields: [
                                                                {
                                                                    kind: 'ObjectField',
                                                                    name: { kind: 'Name', value: '_is_null' },
                                                                    value: { kind: 'BooleanValue', value: true },
                                                                },
                                                            ],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'order_by' },
                                value: {
                                    kind: 'ListValue',
                                    values: [
                                        {
                                            kind: 'ObjectValue',
                                            fields: [
                                                {
                                                    kind: 'ObjectField',
                                                    name: { kind: 'Name', value: 'score' },
                                                    value: { kind: 'EnumValue', value: 'desc' },
                                                },
                                            ],
                                        },
                                        {
                                            kind: 'ObjectValue',
                                            fields: [
                                                {
                                                    kind: 'ObjectField',
                                                    name: { kind: 'Name', value: 'Message' },
                                                    value: {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'createdAt' },
                                                                value: { kind: 'EnumValue', value: 'desc' },
                                                            },
                                                        ],
                                                    },
                                                },
                                            ],
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'prospectId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'accountName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'unread' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'isGenerating' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'goal' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Message' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'subjectLine' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'ConversationMetaData' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'numberOfMessages' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'isNextMessageFollowUp' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'messagesPerDay' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'lastNetworkUpdateAt' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'youThemMessageRatio' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'lastUserNoteAt' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'hasMessagesSentFromApp' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'medianMessageLength' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'connectionStatus' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'lastTimeToAnswer' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'userContactAddedAt' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'lastMessageAt' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'numberOfMessagesLastDay' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'numberOfMessagesLastWeek' },
                                            },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'userContactFromOnboarding' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'topics' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ActionsSubscriptionSubscription, ActionsSubscriptionSubscriptionVariables>;
export const CompletedActionsSubscriptionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'subscription',
            name: { kind: 'Name', value: 'CompletedActionsSubscription' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'scheduledOnLt' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'timestamp' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'completedOnGte' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'timestamp' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'OutreachSuggestions' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'scheduledOn' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_lt' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'scheduledOnLt' },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'Message' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: 'sentOn' },
                                                        value: {
                                                            kind: 'ObjectValue',
                                                            fields: [
                                                                {
                                                                    kind: 'ObjectField',
                                                                    name: { kind: 'Name', value: '_is_null' },
                                                                    value: { kind: 'BooleanValue', value: false },
                                                                },
                                                                {
                                                                    kind: 'ObjectField',
                                                                    name: { kind: 'Name', value: '_gte' },
                                                                    value: {
                                                                        kind: 'Variable',
                                                                        name: { kind: 'Name', value: 'completedOnGte' },
                                                                    },
                                                                },
                                                            ],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'order_by' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'Message' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: 'sentOn' },
                                                        value: { kind: 'EnumValue', value: 'desc' },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'prospectId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'accountName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'goal' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Message' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'subjectLine' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'actionType' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'ConversationMetaData' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'topics' } }],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CompletedActionsSubscriptionSubscription,
    CompletedActionsSubscriptionSubscriptionVariables
>;
export const GetProspectNotesSubscriptionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'subscription',
            name: { kind: 'Name', value: 'GetProspectNotesSubscription' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'prospectId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Notes' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'prospectId' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_eq' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'prospectId' },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'discarded' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_eq' },
                                                        value: { kind: 'BooleanValue', value: false },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'order_by' },
                                value: {
                                    kind: 'ListValue',
                                    values: [
                                        {
                                            kind: 'ObjectValue',
                                            fields: [
                                                {
                                                    kind: 'ObjectField',
                                                    name: { kind: 'Name', value: 'createdAt' },
                                                    value: { kind: 'EnumValue', value: 'asc_nulls_last' },
                                                },
                                            ],
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GetProspectNotesSubscriptionSubscription,
    GetProspectNotesSubscriptionSubscriptionVariables
>;
export const CompletedOutreachSuggestionsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'subscription',
            name: { kind: 'Name', value: 'CompletedOutreachSuggestions' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'sentOn' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'scheduledOnLt' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'timestamp' } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'sentOnGte' } },
                    type: { kind: 'NamedType', name: { kind: 'Name', value: 'timestamp' } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'OutreachSuggestions' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'scheduledOn' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_lt' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'scheduledOnLt' },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'Message' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: 'sentOn' },
                                                        value: {
                                                            kind: 'ObjectValue',
                                                            fields: [
                                                                {
                                                                    kind: 'ObjectField',
                                                                    name: { kind: 'Name', value: '_is_null' },
                                                                    value: {
                                                                        kind: 'Variable',
                                                                        name: { kind: 'Name', value: 'sentOn' },
                                                                    },
                                                                },
                                                                {
                                                                    kind: 'ObjectField',
                                                                    name: { kind: 'Name', value: '_gte' },
                                                                    value: {
                                                                        kind: 'Variable',
                                                                        name: { kind: 'Name', value: 'sentOnGte' },
                                                                    },
                                                                },
                                                            ],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'order_by' },
                                value: {
                                    kind: 'ListValue',
                                    values: [
                                        {
                                            kind: 'ObjectValue',
                                            fields: [
                                                {
                                                    kind: 'ObjectField',
                                                    name: { kind: 'Name', value: 'score' },
                                                    value: { kind: 'EnumValue', value: 'desc' },
                                                },
                                            ],
                                        },
                                        {
                                            kind: 'ObjectValue',
                                            fields: [
                                                {
                                                    kind: 'ObjectField',
                                                    name: { kind: 'Name', value: 'Message' },
                                                    value: {
                                                        kind: 'ObjectValue',
                                                        fields: [
                                                            {
                                                                kind: 'ObjectField',
                                                                name: { kind: 'Name', value: 'createdAt' },
                                                                value: { kind: 'EnumValue', value: 'desc' },
                                                            },
                                                        ],
                                                    },
                                                },
                                            ],
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'accountName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'prospectId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'unread' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'scheduledOn' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Message' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'subjectLine' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'actionType' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'channelType' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'linkedinUserId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'sentOn' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'ConversationMetaData' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'numberOfMessages' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'isNextMessageFollowUp' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'messagesPerDay' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'lastNetworkUpdateAt' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'youThemMessageRatio' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'lastUserNoteAt' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'hasMessagesSentFromApp' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'medianMessageLength' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'connectionStatus' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'lastTimeToAnswer' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'userContactAddedAt' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'lastMessageAt' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'numberOfMessagesLastDay' } },
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'numberOfMessagesLastWeek' },
                                            },
                                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CompletedOutreachSuggestionsSubscription,
    CompletedOutreachSuggestionsSubscriptionVariables
>;
export const NextScheduledOutreachSuggestionsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'subscription',
            name: { kind: 'Name', value: 'NextScheduledOutreachSuggestions' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'prospectId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'OutreachSuggestions' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'limit' },
                                value: { kind: 'IntValue', value: '1' },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'Message' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: 'sentOn' },
                                                        value: {
                                                            kind: 'ObjectValue',
                                                            fields: [
                                                                {
                                                                    kind: 'ObjectField',
                                                                    name: { kind: 'Name', value: '_is_null' },
                                                                    value: { kind: 'BooleanValue', value: true },
                                                                },
                                                            ],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'prospectId' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_eq' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'prospectId' },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'accountName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'prospectId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'unread' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'scheduledOn' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'scheduledOnMotivation' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Message' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'subjectLine' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'actionType' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'channelType' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'linkedinUserId' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'direction' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'sentOn' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    NextScheduledOutreachSuggestionsSubscription,
    NextScheduledOutreachSuggestionsSubscriptionVariables
>;
export const OnMessageAddedByProspectIdDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'subscription',
            name: { kind: 'Name', value: 'OnMessageAddedByProspectId' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Messages' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'sentOn' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_is_null' },
                                                        value: { kind: 'BooleanValue', value: false },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'order_by' },
                                value: {
                                    kind: 'ListValue',
                                    values: [
                                        {
                                            kind: 'ObjectValue',
                                            fields: [
                                                {
                                                    kind: 'ObjectField',
                                                    name: { kind: 'Name', value: 'prospectId' },
                                                    value: { kind: 'EnumValue', value: 'desc' },
                                                },
                                            ],
                                        },
                                        {
                                            kind: 'ObjectValue',
                                            fields: [
                                                {
                                                    kind: 'ObjectField',
                                                    name: { kind: 'Name', value: 'sentOn' },
                                                    value: { kind: 'EnumValue', value: 'desc' },
                                                },
                                            ],
                                        },
                                    ],
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'distinct_on' },
                                value: { kind: 'EnumValue', value: 'prospectId' },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'prospectId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'sentOn' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'subjectLine' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'direction' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'actionType' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'prospectFullName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deliveryStatus' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'OutreachSuggestion' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<OnMessageAddedByProspectIdSubscription, OnMessageAddedByProspectIdSubscriptionVariables>;
export const GetLinkedinConversationsSubscriptionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'subscription',
            name: { kind: 'Name', value: 'GetLinkedinConversationsSubscription' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'LinkedinConversations' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'order_by' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'lastMessageSentAt' },
                                            value: { kind: 'EnumValue', value: 'desc' },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'prospectId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'lastMessageType' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'lastMessageText' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'lastMessageSentAt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'lastMessageSentDirection' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'participantMemberIds' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GetLinkedinConversationsSubscriptionSubscription,
    GetLinkedinConversationsSubscriptionSubscriptionVariables
>;
export const GetOutreachSuggestionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'subscription',
            name: { kind: 'Name', value: 'GetOutreachSuggestion' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'prospectId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'scheduledOnBefore' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'timestamp' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'OutreachSuggestions' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'prospectId' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_eq' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'prospectId' },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'scheduledOn' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_lt' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'scheduledOnBefore' },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'Message' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: 'sentOn' },
                                                        value: {
                                                            kind: 'ObjectValue',
                                                            fields: [
                                                                {
                                                                    kind: 'ObjectField',
                                                                    name: { kind: 'Name', value: '_is_null' },
                                                                    value: { kind: 'BooleanValue', value: true },
                                                                },
                                                            ],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'order_by' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'Message' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: 'createdAt' },
                                                        value: { kind: 'EnumValue', value: 'desc' },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'accountName' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'prospectId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'unread' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'scheduledOn' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'goal' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'isGenerating' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Message' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'subjectLine' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'actionType' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'channelType' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'linkedinUserId' } },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'ConversationMetaData' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'topics' } }],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetOutreachSuggestionSubscription, GetOutreachSuggestionSubscriptionVariables>;
export const GetProspectFutureActionsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'subscription',
            name: { kind: 'Name', value: 'GetProspectFutureActions' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'prospectId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'scheduledOnBefore' } },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'timestamp' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'OutreachSuggestions' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'prospectId' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_eq' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'prospectId' },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'scheduledOn' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_gt' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'scheduledOnBefore' },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'Message' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: 'sentOn' },
                                                        value: {
                                                            kind: 'ObjectValue',
                                                            fields: [
                                                                {
                                                                    kind: 'ObjectField',
                                                                    name: { kind: 'Name', value: '_is_null' },
                                                                    value: { kind: 'BooleanValue', value: true },
                                                                },
                                                            ],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'order_by' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'Message' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: 'createdAt' },
                                                        value: { kind: 'EnumValue', value: 'desc' },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'scheduledOn' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'scheduledOnMotivation' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetProspectFutureActionsSubscription, GetProspectFutureActionsSubscriptionVariables>;
export const GetProspectUnscheduledActionsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'subscription',
            name: { kind: 'Name', value: 'GetProspectUnscheduledActions' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'prospectId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'OutreachSuggestions' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'prospectId' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_eq' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'prospectId' },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'scheduledOn' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_is_null' },
                                                        value: { kind: 'BooleanValue', value: true },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'Message' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: 'sentOn' },
                                                        value: {
                                                            kind: 'ObjectValue',
                                                            fields: [
                                                                {
                                                                    kind: 'ObjectField',
                                                                    name: { kind: 'Name', value: '_is_null' },
                                                                    value: { kind: 'BooleanValue', value: true },
                                                                },
                                                            ],
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'order_by' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'Message' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: 'createdAt' },
                                                        value: { kind: 'EnumValue', value: 'desc' },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'scheduledOnMotivation' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GetProspectUnscheduledActionsSubscription,
    GetProspectUnscheduledActionsSubscriptionVariables
>;
export const OnMessageUpdatedDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'subscription',
            name: { kind: 'Name', value: 'OnMessageUpdated' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'messageId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Messages' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'id' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_eq' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'messageId' },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'subjectLine' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'actionType' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'channelType' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'deliveryStatus' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'Attachments' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<OnMessageUpdatedSubscription, OnMessageUpdatedSubscriptionVariables>;
export const GetProspectMessagesHistorySubscriptionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'subscription',
            name: { kind: 'Name', value: 'GetProspectMessagesHistorySubscription' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'prospectId' } },
                    type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Messages' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'prospectId' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_eq' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'prospectId' },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'sentOn' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_is_null' },
                                                        value: { kind: 'BooleanValue', value: false },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'order_by' },
                                value: {
                                    kind: 'ListValue',
                                    values: [
                                        {
                                            kind: 'ObjectValue',
                                            fields: [
                                                {
                                                    kind: 'ObjectField',
                                                    name: { kind: 'Name', value: 'sentOn' },
                                                    value: { kind: 'EnumValue', value: 'asc_nulls_last' },
                                                },
                                            ],
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GetProspectMessagesHistorySubscriptionSubscription,
    GetProspectMessagesHistorySubscriptionSubscriptionVariables
>;
export const GetAssistantSettingsStreamingSubscriptionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'subscription',
            name: { kind: 'Name', value: 'GetAssistantSettingsStreamingSubscription' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'AssistantSettings' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'organizationId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'purpose' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'language' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'languages' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'tone' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'context' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'defaultChannel' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GetAssistantSettingsStreamingSubscriptionSubscription,
    GetAssistantSettingsStreamingSubscriptionSubscriptionVariables
>;
export const GetUserContactsSubscriptionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'subscription',
            name: { kind: 'Name', value: 'GetUserContactsSubscription' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'UserContacts' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'prospectId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: '_GroupToUserContacts' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'Group' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'imgSrc' } },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetUserContactsSubscriptionSubscription, GetUserContactsSubscriptionSubscriptionVariables>;
export const OnUserContactsByIdsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'subscription',
            name: { kind: 'Name', value: 'OnUserContactsByIds' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: { kind: 'Variable', name: { kind: 'Name', value: 'prospectIds' } },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'ListType',
                            type: {
                                kind: 'NonNullType',
                                type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'UserContacts' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'prospectId' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_in' },
                                                        value: {
                                                            kind: 'Variable',
                                                            name: { kind: 'Name', value: 'prospectIds' },
                                                        },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'prospectId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: '_GroupToUserContacts' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'Group' },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                                        { kind: 'Field', name: { kind: 'Name', value: 'imgSrc' } },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<OnUserContactsByIdsSubscription, OnUserContactsByIdsSubscriptionVariables>;
export const GetGroupsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'subscription',
            name: { kind: 'Name', value: 'GetGroups' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Groups' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'order_by' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'createdAt' },
                                            value: { kind: 'EnumValue', value: 'asc' },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'default' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'assistantSettingsId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'imgSrc' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetGroupsSubscription, GetGroupsSubscriptionVariables>;
export const GetNotesDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'subscription',
            name: { kind: 'Name', value: 'GetNotes' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Notes' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'discarded' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_eq' },
                                                        value: { kind: 'BooleanValue', value: false },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'order_by' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'createdAt' },
                                            value: { kind: 'EnumValue', value: 'asc' },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'prospectId' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'creator' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetNotesSubscription, GetNotesSubscriptionVariables>;
export const GetContactSuggestionsCountSubscriptionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'subscription',
            name: { kind: 'Name', value: 'GetContactSuggestionsCountSubscription' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ContactSuggestions_aggregate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'approvedAt' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_is_null' },
                                                        value: { kind: 'BooleanValue', value: true },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'rejectedAt' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_is_null' },
                                                        value: { kind: 'BooleanValue', value: true },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'expiresAt' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_gt' },
                                                        value: { kind: 'StringValue', value: 'now()', block: false },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aggregate' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GetContactSuggestionsCountSubscriptionSubscription,
    GetContactSuggestionsCountSubscriptionSubscriptionVariables
>;
export const GetMessagesSentFromAppSubscriptionDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'subscription',
            name: { kind: 'Name', value: 'GetMessagesSentFromAppSubscription' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'Messages_aggregate' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'where' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'isSentFromApp' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_eq' },
                                                        value: { kind: 'BooleanValue', value: true },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'sentOn' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_is_null' },
                                                        value: { kind: 'BooleanValue', value: false },
                                                    },
                                                ],
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'direction' },
                                            value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                    {
                                                        kind: 'ObjectField',
                                                        name: { kind: 'Name', value: '_eq' },
                                                        value: { kind: 'StringValue', value: 'out', block: false },
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'aggregate' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'count' } }],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GetMessagesSentFromAppSubscriptionSubscription,
    GetMessagesSentFromAppSubscriptionSubscriptionVariables
>;
