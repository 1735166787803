import { FormControl, MenuItem, Select as MuiSelect } from '@mui/material';
import { styled } from '@mui/styles';

const TimeSelectComponent = styled(({ className, children, ...props }) => (
    <div className={className}>
        <FormControl className={className} size='small' variant='outlined'>
            <MuiSelect
                className={className}
                labelId='time-select-label'
                id='time-select'
                value={props.value}
                onChange={props.handleChange}
            >
                {children}
            </MuiSelect>
        </FormControl>
    </div>
))(({ theme }: any) => ({
    marginLeft: '0px',
    '& *.MuiSelect-select': {
        paddingTop: '12px',
        paddingBottom: '12px',
        paddingRight: '20px',
        paddingLeft: '14px',
    },
    '& *.MuiOutlinedInput-notchedOutline': {
        right: '0px',
        left: '3px',
        top: '0px',
        bottom: '3px',
        borderRadius: '2px',
        boxShadow: `0 2px 20px ${theme.palette.colors.overlay.dark(5)}`,
    },
    '& *.MuiPaper-root': {
        maxHeight: '100px',
    },

    '& *.MuiSelect-iconOutlined': {
        right: '0px',
    },
}));

export const Select = (props) => {
    const { onChange, label, value, values } = props;

    return (
        <TimeSelectComponent handleChange={(e) => onChange(e)} value={value} label={label}>
            {values.map((thisValue, idx) => (
                <MenuItem value={thisValue} key={idx}>
                    {thisValue}
                </MenuItem>
            ))}
        </TimeSelectComponent>
    );
};
