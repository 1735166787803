import { styled } from '@mui/styles';

export const SelectGroupModalStyled = styled('div')(({ theme }) => ({
    '& .modal-container': {
        position: 'absolute',
        width: '555px',
        height: 'auto',
        borderRadius: '14px',
        top: '50%',
        left: '50%',
        transform: `translate(-50%, -50%)`,
        backgroundColor: theme.palette.colors.white,
        '& .input-container': {
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
            padding: '50px 55px 27px 55px',
            '& .close-icon': {
                position: 'absolute',
                top: '22px',
                right: '22px',
                '&:hover': {
                    backgroundColor: 'transparent',
                },
            },
            '& .title': {
                paddingBottom: '27px',
            },
            '& .margin-bottom': {
                marginBottom: 0,
            },
            '& .typography': {
                color: theme.palette.subline,
            },
            '& .title-small': {
                marginBottom: 0,
                paddingBottom: '8px',
                fontWeight: 500,
                fontSize: '15px',
                color: theme.palette.colors.black,
            },
            '& .form-control': {
                width: '445px',
                marginBottom: '13px',
                '& .MuiSelect-select ': {
                    border: `0.8px solid ${theme.palette.colors.greyInformationLight}`,
                    borderRadius: '4px',
                    paddingRight: '25px',
                    background: theme.palette.colors.white,
                },
                '& .MuiFilledInput-underline:before': {
                    border: 'none',
                },
                '& .MuiFilledInput-input': {
                    padding: '9px 0px 9px 8px',
                    fontSize: '15px',
                    fontWeight: 400,
                    color: theme.palette.subline,
                },
                '& > .disabled .MuiFilledInput-input': {
                    color: theme.palette.colors.lightGrey,
                },
            },
            '& .warning': {
                display: 'flex',
                padding: '17px 25px',
                marginTop: '17px',
                borderRadius: '8px',
                background: theme.palette.colors.whiteDark,
                '& .icon-container': {
                    marginRight: '12px ',
                    '& .info-icon': {
                        fontSize: '22px',
                        color: theme.palette.colors.bluePale,
                    },
                },
            },
            '& .additional-info': {
                fontWeight: '400',
                fontSize: '13px',
                color: theme.palette.colors.greyDark,
                '&.bold': {
                    fontWeight: '500',
                    color: theme.palette.colors.subline,
                },
                '&.subline': {
                    color: theme.palette.colors.subline,
                    fontSize: 15,
                },
            },
        },
        '& .divider': {
            backgroundColor: theme.palette.colors.greyInformationLight,
        },
        '& .buttons-container': {
            display: 'flex',
            justifyContent: 'flex-end',
            margin: '13px 43px 21px 0',
            '& .button': {
                width: ' 108px',
                height: '45px',
                padding: '15px 35px',
                borderRadius: '40px',
                boxShadow: 'none',
                fontWeight: 500,
                fontSize: '15px',
            },
            '& .button-blue': {
                marginRight: '8px',
                background: theme.palette.colors.bluePale,
                color: theme.palette.colors.white,
                '&:hover': {
                    backgroundColor: theme.palette.colors.blue,
                },
            },
            '& .button-outlined-blue': {
                marginRight: '8px',
                border: `1px solid ${theme.palette.colors.bluePale}`,
                color: theme.palette.colors.bluePale,
            },
            '& .disabled': {
                borderRadius: '100px',
                background: theme.palette.colors.greyInformationLight,
                color: theme.palette.colors.greyDark,
                fontWeight: 500,
            },
        },
    },
}));
