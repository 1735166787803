import { useDispatch, useSelector } from 'react-redux-latest';
import TwinSelect from '../../../../components/molecules/twinSelect';
import { deleteImportProspectsField, updateImportProspectsField } from '../../../../redux/actions';
import { ProspectDataUploadableFields } from '../../../../services/utils/prospectDataFields';
import { disableUsedField, getRequiredText } from './functions';

export const FieldMappingComponent = () => {
    const dispatch = useDispatch();
    const fieldMapping = useSelector((state) => state.importProspects?.fieldMapping || []);
    const importedProspectsFields = useSelector((state) => state.importProspects?.importedProspectsFields || []);

    const availableProspectFields = ProspectDataUploadableFields.map((availableField) =>
        disableUsedField(availableField, fieldMapping)
    );
    const deleteField = (index) => dispatch(deleteImportProspectsField(index));

    const leftOnChange = (e, index) => dispatch(updateImportProspectsField(e.target.value, 'imported', index));
    const rightOnChange = (e, index) =>
        dispatch(updateImportProspectsField(e.target.value.value || e.target.value, 'prospect', index));

    const getRightOptions = (field) => {
        if (!field.required && availableProspectFields) {
            return availableProspectFields;
        } else if (field.options) {
            return field.options.map((item) => disableUsedField(item, fieldMapping));
        }
        return null;
    };

    return (
        <div className="field-mapping">
            {fieldMapping.map((field, idx) => (
                <div className="field">
                    <TwinSelect
                        key={field}
                        index={idx}
                        leftValue={field?.imported}
                        rightValue={field?.prospect}
                        rightOptions={getRightOptions(field)}
                        leftOptions={importedProspectsFields}
                        leftOnChange={leftOnChange}
                        rightOnChange={rightOnChange}
                        deleteField={deleteField}
                        required={field.required}
                        noCamelCase
                    />
                    {getRequiredText(field, deleteField, idx)}
                </div>
            ))}
        </div>
    );
};
