import merge from 'deepmerge';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import ModalComponent from 'react-modal';

ModalComponent.setAppElement('#root');

export const Modal = (props) => {
    const { isOpen, closeModal, children, shouldCloseOnOverlayClick = true, ariaHideApp, disableScrolling } = props;

    const modalStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            padding: '0',
            transform: 'translate(-50%, -50%)',
            border: '0',
        },
        overlay: {
            zIndex: props.style?.zIndex || props.zIndex || '1100',
            backgroundColor: 'rgba(0, 3, 16, 0.2)',
        },
    };

    const onRequestCloseHandler = (event) => {
        // document.body.style.overflow = 'unset';
        closeModal && closeModal();
        onClickHandler(event);
    };

    const onClickHandler = (event) => {
        event.cancelBubble = true;
        if (event?.stopPropagation) event.stopPropagation();
        if (event?.nativeEvent?.stopImmediatePropagation) event.nativeEvent.stopImmediatePropagation();
    };

    /*useEffect(() => {
        if (disableScrolling && isOpen) {
            document.body.style.overflow = 'unset';
        }
        if (!isOpen || !disableScrolling) document.body.style.overflow = 'unset';
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [isOpen]);*/

    return (
        <>
            {isOpen && (
                <div onClick={onClickHandler}>
                    <ModalComponent
                        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
                        onRequestClose={onRequestCloseHandler}
                        isOpen={isOpen}
                        style={props.style ? merge(modalStyles, props.style) : modalStyles}
                        ariaHideApp={ariaHideApp}
                        preventScroll={disableScrolling}
                    >
                        <div>{children}</div>
                    </ModalComponent>
                </div>
            )}
        </>
    );
};

Modal.propTypes = {
    children: PropTypes.node.isRequired,
    isOpen: PropTypes.bool.isRequired,
    shouldCloseOnOverlayClick: PropTypes.bool,
    closeModal: PropTypes.func,
    style: PropTypes.object,
    ariaHideApp: PropTypes.bool,
    disableScrolling: PropTypes.bool,
};
