import { Button } from '@zaplify/ui';
import { BillingPeriodUnit } from '../../enums/billing';
import { v4 as uuidv4 } from 'uuid';
import BillingPeriodSelectorStyled from './BillingPeriodSelector.styled';
import { BillingFrequencyReadable } from '@zaplify/subscriptions';

export const BillingPeriodSelector = ({
    onChange,
    disable,
    availablePeriods,
    selectedBillingPeriod,
}: {
    onChange: (billingPeriod: { amount: number; unit: BillingPeriodUnit }) => void;
    disable: boolean;
    availablePeriods: BillingPeriodUnit[];
    selectedBillingPeriod: { amount: number; unit: BillingPeriodUnit };
}) => {
    return (
        <BillingPeriodSelectorStyled>
            {availablePeriods.map((period) => {
                const text = BillingFrequencyReadable[period];
                const isPeriodSelected = !!selectedBillingPeriod && selectedBillingPeriod.unit === period;
                return (
                    <Button
                        dataCy={
                            `${isPeriodSelected ? '' : 'not-'}` +
                            'selected-' +
                            text.toLocaleLowerCase() +
                            '-billing-period-button'
                        }
                        height={44}
                        borderRadius="300px"
                        variant={isPeriodSelected ? 'outlined' : 'text'}
                        onClick={() => onChange({ amount: selectedBillingPeriod.amount, unit: period })}
                        text={text}
                        disabled={disable}
                        key={uuidv4()}
                    />
                );
            })}
        </BillingPeriodSelectorStyled>
    );
};
