import { ProfilesSdk } from './profiles/profiles.sdk';

import { apiEndpoint } from './config';

export class ZaplifySdk {
    profiles: ProfilesSdk;

    constructor(endpoint?: string, token?: string) {
        if (endpoint === undefined) endpoint = apiEndpoint;
        this.profiles = new ProfilesSdk(endpoint || '', token);
    }
}

export const getZaplifySdk = (apiEndpoint?: string, token?: string): ZaplifySdk => {
    return new ZaplifySdk(apiEndpoint, token);
};
