import { FC } from 'react';
import { Info } from 'lucide-react';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@shadcn/ui/components/ui/tooltip';

interface VatInformationProps {
    vat: string;
    customerCountry: string;
}

export const VatInformation: FC<VatInformationProps> = ({ vat, customerCountry }) => {
    if (!vat) {
        return null;
    }

    return (
        <div className="flex items-center gap-1">
            <span className="text-[#6B7985]">Incl. {vat} VAT</span>
            <TooltipProvider>
                <Tooltip>
                    <TooltipTrigger asChild>
                        <button className="p-0.5 text-[#CDD1DA] hover:text-[#6B7985]">
                            <Info className="h-4 w-4" />
                        </button>
                    </TooltipTrigger>
                    <TooltipContent>
                        {customerCountry === 'SE'
                            ? 'VAT ("Moms") applies for Swedish companies.'
                            : 'To remove VAT, enter your company VAT-number in the billing address section.'}
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>
        </div>
    );
};
