import { MessageSquare, Network, Search, Target, Users } from 'lucide-react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { paths } from '../../../../routes/paths';
import { Card, CardTitle, CardDescription, CardFooter, CardHeader, CardContent } from '@shadcn/ui/components/ui/card';
import { createElement } from 'react';
import liOnboardingBackground from '../../../pages/auth/assets/li-onboarding-background.jpg';
import { AnimatePresence, motion } from 'motion/react';

export const OnboardingRightSection = () => {
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const step = searchParams.get('step');
    const path = location.pathname;

    const isConnectLinkedin = path === paths.NEW.ONBOARDING_PATHS.CONNECT_LINKEDIN;
    const isSetupPlaybook = path === paths.NEW.ONBOARDING_PATHS.SETUP_PLAYBOOK;

    const currentIcon = (() => {
        if (isConnectLinkedin) return Network;
        if (isSetupPlaybook) return Target;
        return Users;
    })();

    return (
        <div
            className="w-full h-full flex justify-center items-center p-4 sm:p-6 md:p-10 overflow-y-auto"
            style={{
                backgroundImage: `url(${liOnboardingBackground})`,
                backgroundColor: 'black',
                backgroundSize: 'cover',
                backgroundPosition: '30%',
            }}
        >
            <div className="absolute inset-0 bg-gradient-to-br from-slate-900/50 via-purple-900/50 to-slate-900/50"></div>
            <AnimatePresence mode="wait">
                <motion.div
                    key={`${path}`}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.4 }}
                    className="w-full max-w-md relative z-10"
                >
                    <Card className="bg-white/10 backdrop-blur-md border-white/20 text-white shadow-xl max-h-[90vh] overflow-y-auto">
                        <CardHeader className="pb-2 sm:pb-4">
                            <div className="w-full relative z-10">
                                <div className="flex justify-center mb-2 sm:mb-4">
                                    <div className="p-2 sm:p-3 bg-purple-500/20 rounded-full">
                                        {createElement(currentIcon, {
                                            className: 'h-6 w-6 sm:h-8 sm:w-8 text-purple-400',
                                        })}
                                    </div>
                                </div>
                                {isConnectLinkedin && (
                                    <>
                                        <CardTitle className="text-center text-lg sm:text-xl">
                                            Connect your LinkedIn account
                                        </CardTitle>
                                        <CardDescription className="text-center text-white/70">
                                            Seamlessly integrate with your professional network
                                        </CardDescription>
                                    </>
                                )}
                                {isSetupPlaybook && (
                                    <>
                                        <CardTitle className="text-center text-lg sm:text-xl">
                                            {!!step
                                                ? 'Updating your playbook and suggestions'
                                                : 'Setup your first Andsend playbook'}
                                        </CardTitle>
                                        <CardDescription className="text-center text-white/70">
                                            Playbooks guide your agent towards your goals
                                        </CardDescription>
                                    </>
                                )}
                            </div>
                        </CardHeader>
                        <CardContent className="space-y-2 sm:space-y-4 px-3 sm:px-6">
                            <div className="space-y-3 sm:space-y-4">
                                {isConnectLinkedin && (
                                    <div className="text-white/80">
                                        <div className="bg-white/5 rounded-lg p-3 sm:p-4 space-y-2 sm:space-y-3 mt-2 sm:mt-4">
                                            <div className="flex items-start gap-2 sm:gap-3">
                                                <MessageSquare className="h-4 w-4 sm:h-5 sm:w-5 text-purple-400 mt-0.5" />
                                                <div>
                                                    <h4 className="font-medium text-sm sm:text-base text-white">
                                                        Import conversations
                                                    </h4>
                                                    <p className="text-xs sm:text-sm text-white/70">
                                                        Seamlessly import your LinkedIn conversations
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="flex items-start gap-2 sm:gap-3">
                                                <Network className="h-4 w-4 sm:h-5 sm:w-5 text-purple-400 mt-0.5" />
                                                <div>
                                                    <h4 className="font-medium text-sm sm:text-base text-white">
                                                        Network monitoring
                                                    </h4>
                                                    <p className="text-xs sm:text-sm text-white/70">
                                                        Andsend will monitor updates and opportunities in your network
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="flex items-start gap-2 sm:gap-3">
                                                <Users className="h-4 w-4 sm:h-5 sm:w-5 text-purple-400 mt-0.5" />
                                                <div>
                                                    <h4 className="font-medium text-sm sm:text-base text-white">
                                                        Contact management
                                                    </h4>
                                                    <p className="text-xs sm:text-sm text-white/70">
                                                        Keeps contact information up to date
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {isSetupPlaybook && (
                                    <div className="text-white/80">
                                        <div className="bg-white/5 rounded-lg p-3 sm:p-4 space-y-2 sm:space-y-3 mt-2 sm:mt-4">
                                            <div className="flex items-start gap-2 sm:gap-3">
                                                <Target className="h-4 w-4 sm:h-5 sm:w-5 text-purple-400 mt-0.5" />
                                                <div>
                                                    <h4 className="font-medium text-sm sm:text-base text-white">
                                                        Playbooks align with your goals
                                                    </h4>
                                                    <p className="text-xs sm:text-sm text-white/70">
                                                        Tailor your messages and suggestions to your goals
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="flex items-start gap-2 sm:gap-3">
                                                <MessageSquare className="h-4 w-4 sm:h-5 sm:w-5 text-purple-400 mt-0.5" />
                                                <div>
                                                    <h4 className="font-medium text-sm sm:text-base text-white">
                                                        Smart message suggestions
                                                    </h4>
                                                    <p className="text-xs sm:text-sm text-white/70">
                                                        Messages that steer your conversations towards your goals
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="flex items-start gap-2 sm:gap-3">
                                                <Search className="h-4 w-4 sm:h-5 sm:w-5 text-purple-400 mt-0.5" />
                                                <div>
                                                    <h4 className="font-medium text-sm sm:text-base text-white">
                                                        Find network opportunities
                                                    </h4>
                                                    <p className="text-xs sm:text-sm text-white/70">
                                                        Suggestions from your network based on your profile and playbook
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </CardContent>
                        {isSetupPlaybook && (
                            <CardFooter className="flex justify-center border-t border-white/10 pt-2 sm:pt-4 px-3 sm:px-6">
                                <p className="text-xs sm:text-sm text-white/60 text-center">
                                    Most users create 3-10 playbooks serving different purposes or segments of their
                                    network
                                </p>
                            </CardFooter>
                        )}
                    </Card>
                </motion.div>
            </AnimatePresence>
        </div>
    );
};
