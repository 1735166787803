import { Typography } from '@mui/material';
import Check from '@mui/icons-material/Check';
import { styled } from '@mui/styles';
import { PlanCode } from '@zaplify/subscriptions';

const AllPlanInformationsStyled = styled('div')(({ theme: { palette } }: any) => ({
    marginTop: 20,
    '& > .header': {
        color: palette.colors.black,
        fontSize: 14,
        fontWeight: 500,
        marginTop: 6,
    },
    '& > .feature-row': {
        marginTop: 11,
        display: 'flex',
        alignItems: 'center',
        gap: 5.25,
        color: palette.colors.black,
        fontSize: 13,

        '& > .check-icon': {
            fontSize: 14,
            color: palette.colors.greyDark,
        },
    },
}));

const AllPlanInformations = ({ planCode }: { planCode: PlanCode }) => {
    return (
        <AllPlanInformationsStyled>
            {planCode === PlanCode.FREE && (
                <>
                    <Typography className="header">All features included:</Typography>
                    <div className="feature-row">
                        <Check className="check-icon" />
                        LinkedIn + Email Messenger
                    </div>
                    <div className="feature-row">
                        <Check className="check-icon" />
                        AI generated messages
                    </div>
                    <div className="feature-row">
                        <Check className="check-icon" />
                        Search 500M decision makers
                    </div>
                    <div className="feature-row">
                        <Check className="check-icon" />
                        Contact details
                    </div>
                    <div className="feature-row">
                        <Check className="check-icon" />
                        50 contacts
                    </div>
                </>
            )}
            {planCode === PlanCode.BASIC && (
                <>
                    <Typography className="header">All features from the free plan and:</Typography>
                    <div className="feature-row">
                        <Check className="check-icon" />
                        Multiple AI playbooks
                    </div>
                    <div className="feature-row">
                        <Check className="check-icon" />
                        Enrich imported contacts
                    </div>
                    <div className="feature-row">
                        <Check className="check-icon" />
                        Export contacts
                    </div>
                    <div className="feature-row">
                        <Check className="check-icon" />
                        CRM integrations
                    </div>
                    <div className="feature-row">
                        <Check className="check-icon" />
                        50 000 contacts
                    </div>
                </>
            )}
            {planCode === PlanCode.TEAM && (
                <>
                    <Typography className="header">All features from the Pro plan and:</Typography>
                    <div className="feature-row">
                        <Check className="check-icon" />
                        Collaborative features
                    </div>
                    <div className="feature-row">
                        <Check className="check-icon" />
                        Unlimited users in your organization
                    </div>
                    <div className="feature-row">
                        <Check className="check-icon" />
                        Customer Success Manager
                    </div>
                    <div className="feature-row">
                        <Check className="check-icon" />
                        Centralized billing for all users
                    </div>
                    <div className="feature-row">
                        <Check className="check-icon" />
                        Admin capabilities
                    </div>
                </>
            )}
        </AllPlanInformationsStyled>
    );
};
export default AllPlanInformations;
