import { CircularProgress } from '@mui/material';
import { styled } from '@mui/styles';
import React from 'react';

export const Loader = styled(({ children, className, time, size }) => {
    const [progress, setProgress] = React.useState(0);

    React.useEffect(() => {
        const timer = time
            ? setInterval(() => {
                  if (progress >= 100) {
                      clearInterval(timer);
                  }
                  setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + 10 / time));
              }, 100)
            : null;
        return () => {
            time && clearInterval(timer);
        };
        //eslint-disable-next-line
    }, []);

    return (
        <div className={className}>
            <CircularProgress
                variant={time ? 'determinate' : 'indeterminate'}
                value={time ? progress : 0}
                size={size}
            />
        </div>
    );
})((theme) => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& > div': {
        margin: 'auto',
    },
}));
