import { useSubscription } from '@apollo/client';
import { USER_CONTACTS_SUBSCRIPTION } from '@zaplify/graphql';
import { useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { userContactsLoadingAtom, userContactsStateAtom } from '../../views/contacts/hooks/use-user-contacts';

export const UserContactsSubscription = () => {
    const setAllContacts = useSetAtom(userContactsStateAtom);
    const setLoading = useSetAtom(userContactsLoadingAtom);

    const { loading } = useSubscription(USER_CONTACTS_SUBSCRIPTION, {
        onData: async (data) => {
            const fetchedUserContacts = data.data.data?.UserContacts;
            const contacts = fetchedUserContacts.map((contact) => {
                const groups = contact._GroupToUserContacts.map((group) => {
                    return { id: group.Group.id, name: group.Group.name, imgSrc: group.Group.imgSrc };
                });
                return {
                    userContactId: contact.id,
                    prospectId: contact.prospectId,
                    groups: groups,
                    status: contact.status,
                };
            });

            console.log('User contacts fetched', contacts?.length);
            setAllContacts(contacts);
        },
    });

    useEffect(() => {
        setLoading(loading);
    }, [loading]);

    return null;
};
