import { Sparkles } from 'lucide-react';
import { useSuggestionsFeed } from '../../../hooks/use-suggestions-feed';

export const ContactRecommendationMotivation = () => {
    const { currentContactSuggestion: contactSuggestion } = useSuggestionsFeed();
    const text = contactSuggestion?.suggestionMotivation;
    if (!text) return null;
    return (
        <div className="flex flex-col items-start justify-start gap-2 w-full rounded-xl bg-background-brand-secondary-section p-3 text-muted-foreground">
            <div className="flex flex-row items-center justify-start gap-1">
                <Sparkles className="text-text-brand-secondary inline-block h-3 w-3" />
                <span className="text-sm font-medium text-muted-foreground inline-block">Why suggested?</span>
            </div>
            <p className="text-sm">{text}</p>
        </div>
    );
};
