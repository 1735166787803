import Link from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import TextAlign from '@tiptap/extension-text-align';
import Underline from '@tiptap/extension-underline';
import { Extension } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { useMemo } from 'react';
import ResizeImageExtension from 'tiptap-extension-resize-image';
/**
 * Leaving this as an example of an issue with passing up to date function reference. If sendMessage is passed
 * as a prop from Conversations down to TextEditor, it will be stale and cause the extension to not work.
 * Other then that, it works fine and might be used to create custom keyboard shortcuts, i.e. to open some toolbar.
 * This functionality was handled with onKeyDown in TextEditor.
 */
const CustomKeymap = Extension.create({
    name: 'customKeymap',

    addKeyboardShortcuts() {
        return {
            'Mod-Enter': () => {
                // disable default behavior so nothing happens when pressing Mod+Enter
                this.editor.commands.setContent('<p></p>');
                return true;
            },
        };
    },
});

export const useExtensions = ({ placeholder }: { placeholder?: string }) => {
    return useMemo(() => {
        return [
            StarterKit,
            Link.configure({
                autolink: true,
                linkOnPaste: true,
                openOnClick: false,
            }).extend({
                inclusive: false,
            }),
            Underline,
            ResizeImageExtension.configure({
                inline: false,
            }),
            TextAlign.configure({
                types: ['heading', 'paragraph'],
                alignments: ['left', 'center', 'right', 'justify'],
            }),
            Placeholder.configure({
                placeholder,
            }),
            CustomKeymap,
        ];
    }, [placeholder]);
};
