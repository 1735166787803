const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

export const exportToXLSX = async (csvData, fileName, allColumns?: string[]) => {
    const XLSX = await import('xlsx');
    const FileSaver = await import('file-saver');
    const ws = XLSX.utils.json_to_sheet(csvData, { header: allColumns });
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
};
