import { lazy } from 'react';
import { Route, Navigate } from 'react-router-dom';
import { OnboardingLayout } from '../../new/layouts/onboarding-layout';
import RequireAuth from '../require-auth';
import { ConnectLinkedinPage } from '../../new/pages/onboarding/connect-linkedin/connect-linkedin-page';
import { SetupPlaybookOnboardingPage } from '../../new/pages/onboarding/setup-playbook/setup-playbook-page';
import { paths } from '../paths';

// Lazy load onboarding components

export const onboardingRoutes = (
    <Route path={paths.NEW.ONBOARDING_PATHS.ROOT} element={<OnboardingLayout />}>
        <Route index element={<Navigate to={paths.NEW.ONBOARDING_PATHS.CONNECT_LINKEDIN} replace />} />

        <Route element={<RequireAuth requiredUserState={'loggedin_user_selected_and_linkedin_not_connected'} />}>
            <Route>
                <Route path={paths.NEW.ONBOARDING_PATHS.CONNECT_LINKEDIN} element={<ConnectLinkedinPage />} />
            </Route>
            <Route element={<RequireAuth requiredUserState={'loggedin_user_selected_and_aiprofile_not_configured'} />}>
                <Route>
                    <Route path={paths.NEW.ONBOARDING_PATHS.SETUP_PLAYBOOK} element={<SetupPlaybookOnboardingPage />} />
                </Route>
            </Route>
        </Route>
    </Route>
);
