import makeStyles from '@mui/styles/makeStyles';

export const styles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        borderRadius: '8px',
    },
    listItem: {
        '& span': {
            fontWeight: 500,
        },
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    subheader: {
        background: '#fff',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    unblocked: {
        display: 'flex',
        alignItems: 'center',
    },
    greenText: {
        color: '#00C281',
        paddingRight: '15px',
    },
    button: {
        '&:hover': {
            color: 'rgb(66, 121, 246) !important',
            borderColor: 'red !important',
        },
    },
}));
