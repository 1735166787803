import { ReactComponent as AndSendIconColorStriped } from './ANDSEND_symbol_icon_color_transparent.svg';
import { ReactComponent as AndSendIconColor } from './ANDSEND_symbol_color_transparent.svg';
import { ReactComponent as AndSendIconBlackStriped } from './ANDSEND_symbol_icon_black_transparent.svg';
import { ReactComponent as AndSendIconBlack } from './ANDSEND_symbol_bw_transparent.svg';
import { ReactComponent as AndSendIconWhite } from './ANDSEND_symbol_icon_white_transparent.svg';

export const AndSendIcon = (
    props: {
        variation: 'color' | 'color-striped' | 'black' | 'black-striped' | 'white';
        height?: any;
        width?: any;
    } & React.SVGProps<SVGSVGElement>,
) => {
    const height = props.height || '40px';
    const width = props.width || '40px';

    switch (props.variation) {
        case 'color': {
            return <AndSendIconColor height={height} style={{ height, width }} {...props} />;
        }
        case 'color-striped': {
            return <AndSendIconColorStriped height={height} style={{ height, width }} {...props} />;
        }
        case 'black': {
            return <AndSendIconBlack height={height} style={{ height, width }} {...props} />;
        }
        case 'black-striped': {
            return <AndSendIconBlackStriped height={height} style={{ height, width }} {...props} />;
        }
        case 'white': {
            return <AndSendIconWhite height={height} style={{ height, width }} {...props} />;
        }
        default: {
            return <AndSendIconColor height={height} style={{ height, width }} {...props} />;
        }
    }
};
