import { UseMutationOptions, UseQueryOptions } from '@tanstack/react-query';
import { getZaplifySdk } from '@zaplify/sdk';
import { createMutationOptions } from './sdk-utils';
import { ChannelAccountDto, CreateChannelAccountDto } from '@zaplify/channel-accounts/shared';
import { CreateUserDto, UpdateUserSettingsDto } from '@zaplify/users/shared';

const getUserReferralLinkFn = () => {
    return getZaplifySdk().profiles.user.getReferralLink();
};

type UserReferralLinkData = Awaited<ReturnType<typeof getUserReferralLinkFn>>;

export const getUserReferralLink = (
    options?: Omit<UseQueryOptions<UserReferralLinkData, Error>, 'queryKey' | 'queryFn'>
): UseQueryOptions<UserReferralLinkData, Error> => {
    return {
        queryKey: ['getUserReferralLink'],
        queryFn: () => getUserReferralLinkFn(),
        ...options,
    };
};

const getUserAccountsFn = () => {
    return getZaplifySdk().profiles.user.getUserAccounts();
};

type UserAccountsData = Awaited<ReturnType<typeof getUserAccountsFn>>;

export const getUserAccounts = (
    options?: Omit<UseQueryOptions<UserAccountsData, Error>, 'queryKey' | 'queryFn'>
): UseQueryOptions<UserAccountsData, Error> => {
    return {
        queryKey: ['getUserAccounts'],
        queryFn: () => getUserAccountsFn(),
        ...options,
    };
};

const getUserCreditsAndSeatSummaryFn = (userId: string) => {
    return getZaplifySdk().profiles.user.getUserCreditsAndSeatSummary(userId);
};

type UserCreditsAndSeatSummaryData = Awaited<ReturnType<typeof getUserCreditsAndSeatSummaryFn>>;

const userQueryKey = (userId: string) => ['getUser', userId];

export const getUserCreditsAndSeatSummary = (
    userId: string,
    options?: Omit<UseQueryOptions<UserCreditsAndSeatSummaryData, Error>, 'queryKey' | 'queryFn'>
): UseQueryOptions<UserCreditsAndSeatSummaryData, Error> => {
    return {
        queryKey: userQueryKey(userId),
        queryFn: () => getUserCreditsAndSeatSummaryFn(userId),
        enabled: !!userId,
        ...options,
    };
};

const notifyInvitationClickedFn = (invitationId: string, securityToken: string) => {
    return getZaplifySdk().profiles.user.notifyInvitationClicked(invitationId, securityToken);
};

export const notifyInvitationClicked = (
    invitationId: string,
    securityToken: string,
    options?: Omit<UseQueryOptions<void, Error>, 'queryKey' | 'queryFn'>
): UseQueryOptions<void, Error> => {
    return {
        queryKey: ['notifyInvitationClicked'],
        queryFn: () => notifyInvitationClickedFn(invitationId, securityToken),
        ...options,
    };
};

export const updateBetaFeatures = () => {
    return createMutationOptions<void, Error, void>({
        mutationFn: () => getZaplifySdk().profiles.user.updateBetaFeatures(),
    });
};

export const updateUserSettings = () => {
    return createMutationOptions<void, Error, { userId: string; data: UpdateUserSettingsDto }>({
        mutationFn: (dto: { userId: string; data: UpdateUserSettingsDto }) =>
            getZaplifySdk().profiles.user.updateUserSettings(dto.userId, dto.data),
    });
};

export const addChannelAccount = () => {
    return {
        mutationFn: (channelAccountDto: CreateChannelAccountDto): Promise<ChannelAccountDto> =>
            getZaplifySdk().profiles.user.addChannelAccount(channelAccountDto.userId, channelAccountDto),
        onSuccess: () => {
            // This can be used for callbacks after successful mutation
        },
        onError: (error: any) => {
            // This can be used for error handling
            console.error('Channel account error:', error);
        },
    };
};

const createUserFn = (userDto: CreateUserDto) => {
    return getZaplifySdk().profiles.user.createUser(userDto);
};

type CreateUserData = Awaited<ReturnType<typeof createUserFn>>;

export const createUser = (
    options?: Omit<UseMutationOptions<CreateUserData, Error, CreateUserDto>, 'mutationFn'>
): UseMutationOptions<CreateUserData, Error, CreateUserDto> => {
    return {
        mutationFn: createUserFn,
        ...options,
    };
};

const getUserOrganizationByDomainFn = (domain: string) => {
    return getZaplifySdk().profiles.user.getUserOrganizationByDomain(domain);
};

type UserOrganizationByDomainData = Awaited<ReturnType<typeof getUserOrganizationByDomainFn>>;

export const getUserOrganizationByDomain = (
    domain: string,
    options?: Omit<UseQueryOptions<UserOrganizationByDomainData, Error>, 'queryKey' | 'queryFn'>
): UseQueryOptions<UserOrganizationByDomainData, Error> => {
    return {
        queryKey: ['getUserOrganizationByDomain', domain],
        queryFn: () => getUserOrganizationByDomainFn(domain),
        ...options,
    };
};

// Export user SDK
export const userSdk = {
    getUserReferralLink,
    getUserCreditsAndSeatSummary,
    notifyInvitationClicked,
    updateBetaFeatures,
    addChannelAccount,
    createUser,
    getUserOrganizationByDomain,
};
