export function getFormattedImportProspects(prospects, fieldMapping) {
    return prospects.map((prospect) => {
        const tempProspect = {};

        //Mapping prospect fields from imported to zaplify fields
        fieldMapping.forEach((field) => {
            tempProspect[field.prospect] = prospect[field.imported]?.toString();
        });

        //Adding fullName if property doesn't exist on prospect
        if (!tempProspect.fullName)
            tempProspect.fullName = (tempProspect.firstName || '') + ' ' + (tempProspect.lastName || '');

        //Set organizationName to empty if property doesn't exist on prospect
        if (!tempProspect.organizationName) {
            tempProspect.organizationName = '';
        }

        if (tempProspect.phoneNumber) {
            tempProspect.phoneNumbers = [{ number: tempProspect.phoneNumber, type: 'unknown' }];
        }

        return tempProspect;
    });
}
