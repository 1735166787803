import { AssistantSettingsDto } from '@zaplify/services/messaging/shared';
import { BaseSdk } from '../../base.sdk';

export class AssistantSdk extends BaseSdk {
    async getAssistantSettings() {
        return this.get<AssistantSettingsDto>(`campaigns/${this.assistantPath}/settings`);
    }

    /** If no id is provided, the default is used */
    async putAssistantSettings(
        assistantSettings: Partial<AssistantSettingsDto>,
        assistantSettingsId?: string,
        metadata?: string,
    ) {
        return this.put<void>(`campaigns/${this.assistantPath}/settings/${assistantSettingsId ?? ''}`, {
            ...assistantSettings,
            metadata,
        });
    }
}
