import { IEvent, Topics } from '@zaplify/common';

export class LinkedinPremiumStatusChangedEvent extends IEvent {
    public static override readonly TOPIC = Topics.CHANNEL_ACCOUNTS;

    constructor(readonly channelAccountId: string, readonly isPremium: boolean) {
        super();
    }

    get partitionKey(): string {
        return this.channelAccountId;
    }
}
