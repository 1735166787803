import { ICommand, Topics } from '@zaplify/common';
import { CompanyBlocklistDto } from '../prospects';

export class RemoveAllCompaniesFromBlocklistCommand extends ICommand<CompanyBlocklistDto> {
    public static override readonly TOPIC = Topics.PROSPECTS;

    constructor(readonly organizationId: string) {
        super();
    }
}
