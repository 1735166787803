import { memo } from 'react';
import { EditorContent } from '@tiptap/react';
import type { Editor } from '@tiptap/react';
import { cn } from '@shadcn/ui/lib/utils';

interface EditorContentWrapperProps {
    editor: Editor;
    size: 'small' | 'large';
}

const EditorContentWrapper = ({ editor, size }: EditorContentWrapperProps) => {
    return (
        <div
            className={cn(
                'overflow-auto px-4 pt-2 transition-[max-height] duration-100',
                size === 'large' ? 'max-h-[30vh]' : 'max-h-[10vh]'
            )}
        >
            <EditorContent editor={editor} className="text-text-primary overflow-auto max-h-full" />
        </div>
    );
};

export default memo(EditorContentWrapper);

// export default memo(EditorContentWrapper, (prevProps, nextProps) => {
//     return prevProps.editor.isEditable === nextProps.editor.isEditable;
// });
