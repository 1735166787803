import { getZaplifySdk } from '@zaplify/sdk';
import { createMutationOptions } from './sdk-utils';

export const snoozeOutreachSuggestion = () => {
    return createMutationOptions<void, Error, { prospectId: string; daysToSnooze: number | 'auto' }>({
        mutationFn: (variables) =>
            getZaplifySdk().profiles.outreachSuggestions.snoozeOutreachSuggestion(
                variables.prospectId,
                variables.daysToSnooze
            ),
        invalidateQueries: (data) => [],
    });
};

export const dismissOutreachSuggestionByProspectId = () => {
    return createMutationOptions<void, Error, { prospectId: string }>({
        mutationFn: (variables) =>
            getZaplifySdk().profiles.outreachSuggestions.dismissOutreachSuggestionByProspectId(variables.prospectId),
        invalidateQueries: (data) => [],
    });
};

export const markReadOutreachSuggestion = () => {
    return createMutationOptions<void, Error, { outreachSuggestionId: string }>({
        mutationFn: (variables) =>
            getZaplifySdk().profiles.outreachSuggestions.markReadOutreachSuggestion(variables.outreachSuggestionId),
        invalidateQueries: (data) => [],
    });
};
