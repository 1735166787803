import PropTypes from 'prop-types';
import { CSVReader } from 'react-papaparse';
import { CSVDownloadErrorMessages } from '../../constants';

export const FileOpener = (props) => {
    const handleOnDrop = (csvData) => {
        //Standard validation function
        if (!(Array.isArray(csvData) && typeof csvData[0] === 'object' && Array.isArray(csvData[0].data))) {
            console.error('error');
            return false;
        }

        //If custom validation function is passed from props
        if (props.validateData) {
            const validation = props.validateData(csvData);
            if (!validation) {
                console.error('custom validation error', validation);
                return;
            }
        }

        props.handleError(CSVDownloadErrorMessages.noError);

        //Remove header row
        if (!props.keepHeaderRow && props.getHeaderRow) props.getHeaderRow(csvData.shift());
        else if (!props.keepHeaderRow) csvData.shift();

        //Check if csv has less rows than limit
        if (props.limit && csvData.length > props.limit) {
            props.handleError(CSVDownloadErrorMessages.companiesLimit);
            return;
        }

        //If custom data handler is passed from props
        if (props.getData) {
            props.getData(csvData);
        } else {
            //Standard data handler function, maps first column in every row.
            const jsonData = csvData
                .map((row) => {
                    const company = row.data[0];
                    return company !== '' && company;
                })
                .filter((company) => company !== false);
            props.handleImported(jsonData);
        }
    };

    const handleOnError = (err, file, inputElem, reason) => {
        props.handleError(CSVDownloadErrorMessages.unknown);
        console.error('an error occured');
    };

    const handleOnRemoveFile = (data) => {
        props.handleError(CSVDownloadErrorMessages.noError);
        console.log('removed csv');
    };

    return (
        <CSVReader onDrop={handleOnDrop} onError={handleOnError} addRemoveButton onRemoveFile={handleOnRemoveFile}>
            <span className={props.className}>Drop CSV file here or click to upload.</span>
        </CSVReader>
    );
};

FileOpener.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    handleError: PropTypes.func,
    handleImported: PropTypes.func,
    limit: PropTypes.number,
    validateData: PropTypes.func,
    getData: PropTypes.func,
    getHeaderRow: PropTypes.func,
};
