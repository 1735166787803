import { AssistantSettingsDto, GroupStatisticsDto } from '@zaplify/services/messaging/shared';
import { BaseSdk } from '../../base.sdk';
import { GroupDto } from '@zaplify/services/user-contacts/shared';

export interface ICreateGroupWithAssistantSettingsInput {
    group: Omit<GroupDto, 'id' | 'createdAt' | 'updatedAt' | 'assistantSettingsId' | 'userId'>;
    assistantSettings?: Partial<AssistantSettingsDto>;
}

export interface IUpdateGroupInput {
    id: string;
    updatedGroup: Partial<Omit<GroupDto, 'id' | 'createdAt' | 'updatedAt' | 'userId' | 'assistantSettingsId'>>;
}

export class GroupsSdk extends BaseSdk {
    async createGroup(body: { name: string; imgSrc: string }) {
        return await this.post<GroupDto>({
            path: `${this.groupsPath}`,
            payload: {
                group: {
                    name: body.name,
                    imgSrc: body.imgSrc,
                    default: false,
                },
            },
        });
    }

    async createGroupWithAssistantSettings(input: ICreateGroupWithAssistantSettingsInput) {
        return await this.post<GroupDto>({
            path: `${this.groupsPath}`,
            payload: { group: input.group, assistantSettings: input.assistantSettings },
        });
    }

    async getGroups() {
        return await this.get<GroupDto[]>(`${this.groupsPath}`);
    }

    async getStatistics(input?: { groupIds?: string[]; sinceTimestamp?: number }) {
        return await this.get<Record<string, GroupStatisticsDto>>(`${this.groupsPath}/statistics`, {
            params: {
                groupIds: input?.groupIds || [],
                sinceTimestamp: input?.sinceTimestamp,
            },
        });
    }

    async updateGroup(
        id: string,
        updatedGroup: Partial<Omit<GroupDto, 'id' | 'createdAt' | 'updatedAt' | 'userId' | 'assistantSettingsId'>>,
    ) {
        return await this.patch<GroupDto>(`${this.groupsPath}/${id}`, updatedGroup);
    }

    async deleteGroup(id: string) {
        return await this.delete<void>(`${this.groupsPath}/${id}`);
    }

    async generatePlaybookContext(currentContext: string, feedback: string) {
        return await this.post<string>({
            path: `${this.groupsPath}/generate-playbook-context`,
            payload: {
                currentContext,
                feedback,
            },
        });
    }

    async suggestTargetGroupAndPurpose() {
        return await this.get<{
            purposeSuggestions: string[];
            targetGroupSuggestions: string[];
        }>(`${this.groupsPath}/suggest-target-group-and-purpose`);
    }
}
