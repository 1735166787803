import { FC, useState, useCallback, useEffect } from 'react';
import { Button } from '@shadcn/ui/components/ui/button';
import {
    AlertCircle,
    Trash2,
    RefreshCw,
    Settings2,
    Cloud,
    PlusCircle,
    Loader2,
    Users,
    MessageSquare,
} from 'lucide-react';
import { Separator } from '@shadcn/ui/components/ui/separator';
import { RadioGroup, RadioGroupItem } from '@shadcn/ui/components/ui/radio-group';
import { Label } from '@shadcn/ui/components/ui/label';
import { Switch } from '@shadcn/ui/components/ui/switch';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
    SelectLabel,
    SelectGroup,
} from '@shadcn/ui/components/ui/select';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
    DialogFooter,
} from '@shadcn/ui/components/ui/dialog';
import { ReactComponent as Arrow } from '../../../../../../components/icons/arrow.svg';
import {
    OrganizationIntegration,
    AvailableFields,
    SalesforceFieldMapping,
    AvailableSalesforceContactFields,
    AvailableSalesforceLeadFields,
} from '../../../../../sdk/internal/user-organization.sdk';
import { useCrmAccounts } from '../../hooks/use-crm-accounts';
import { ReactComponent as SalesforceLogo } from '../../../../../../components/icons/salesforce-logo.svg';
// For backward compatibility with existing data
type SimplifiedMapping = {
    prospectField: string;
    crmField: string;
    required?: boolean;
    objectType?: string; // Added to track which object type this field belongs to
};

// Helper function to convert between mapping formats
const convertToSalesforceMapping = (
    mapping: SimplifiedMapping,
    integrationStrategy: 'ContactStrategy' | 'LeadStrategy'
): SalesforceFieldMapping => {
    // Determine the correct objectType based on the field and integration strategy
    let objectType = mapping.objectType || 'Contact';

    if (integrationStrategy === 'LeadStrategy') {
        objectType = 'Lead';
    } else if (mapping.crmField && mapping.crmField.startsWith('Account.')) {
        objectType = 'Account';
    }

    return {
        source: {
            type: 'prospect',
            fieldName: mapping.prospectField,
        },
        destination: {
            objectType: objectType,
            fieldName: mapping.crmField,
            required: mapping.required || false,
        },
    };
};

const convertFromSalesforceMapping = (mapping: SalesforceFieldMapping): SimplifiedMapping => {
    return {
        prospectField: mapping.source.fieldName,
        crmField: mapping.destination.fieldName,
        required: mapping.destination.required,
        objectType: mapping.destination.objectType,
    };
};

const FieldMappingDialog: FC<{
    fieldMapping: SalesforceFieldMapping[];
    onUpdateMapping?: (mapping: SalesforceFieldMapping[]) => void;
    availableFields?: AvailableSalesforceContactFields | AvailableSalesforceLeadFields;
    loadAvailableFields?: (crmType: string, integrationStrategy?: 'ContactStrategy' | 'LeadStrategy') => void;
    isLoadingFields?: boolean;
    integrationStrategy?: 'ContactStrategy' | 'LeadStrategy';
}> = ({
    fieldMapping = [],
    onUpdateMapping,
    availableFields = {},
    loadAvailableFields,
    isLoadingFields = true,
    integrationStrategy = 'ContactStrategy',
}) => {
    // Convert to simplified format for UI interaction
    const [isOpen, setIsOpen] = useState(false);
    const [localMapping, setLocalMapping] = useState<SimplifiedMapping[]>(
        fieldMapping.map(convertFromSalesforceMapping)
    );
    const [errors, setErrors] = useState<Record<number, string>>({});

    // Group mappings by object type
    const contactMappings = localMapping.filter((mapping) => mapping.objectType === 'Contact' || !mapping.objectType);

    const accountMappings = localMapping.filter((mapping) => mapping.objectType === 'Account');

    const leadMappings = localMapping.filter((mapping) => mapping.objectType === 'Lead');

    // Open the dialog and load fields if they're not already loaded
    const openDialog = useCallback(() => {
        if (loadAvailableFields) {
            loadAvailableFields('salesforce', integrationStrategy);
        }
        setLocalMapping(fieldMapping.map(convertFromSalesforceMapping));
        setErrors({});
        setIsOpen(true);
    }, [fieldMapping, loadAvailableFields, integrationStrategy]);

    // Check for duplicates and update the errors state
    const validateMappings = useCallback((mappings: SimplifiedMapping[]) => {
        const newErrors: Record<number, string> = {};
        const salesforceFieldsUsed: Record<string, number> = {};

        mappings.forEach((mapping, index) => {
            if (mapping.crmField) {
                if (salesforceFieldsUsed[mapping.crmField] !== undefined) {
                    newErrors[index] = 'This Salesforce field is already mapped';
                    newErrors[salesforceFieldsUsed[mapping.crmField]] = 'This Salesforce field is already mapped';
                } else {
                    salesforceFieldsUsed[mapping.crmField] = index;
                }
            }
        });

        setErrors(newErrors);
        return newErrors;
    }, []);

    const handleUpdateMapping = (index: number, field: 'prospectField' | 'crmField', value: string) => {
        const newMapping = [...localMapping];

        // Update the field
        newMapping[index] = {
            ...newMapping[index],
            [field]: value,
        };

        setLocalMapping(newMapping);
        validateMappings(newMapping);
    };

    const handleAddMapping = (objectType: string = 'Contact') => {
        setLocalMapping([
            ...localMapping,
            {
                prospectField: '',
                crmField: '',
                required: false,
                objectType,
            },
        ]);
    };

    const handleRemoveMapping = (index: number) => {
        const newMapping = localMapping.filter((_, i) => i !== index);
        setLocalMapping(newMapping);
        validateMappings(newMapping);
    };

    const handleSave = () => {
        const newErrors = validateMappings(localMapping);
        if (Object.keys(newErrors).length === 0) {
            // Convert back to SalesforceFieldMapping format before saving
            onUpdateMapping?.(localMapping.map((mapping) => convertToSalesforceMapping(mapping, integrationStrategy)));
            setIsOpen(false);
        }
    };

    // Helper function to check if a Salesforce field is already selected in another row
    const isSalesforceFieldSelected = (fieldName: string, currentIndex: number) => {
        if (!localMapping) return false;
        return localMapping.some((mapping, idx) => idx !== currentIndex && mapping.crmField === fieldName);
    };

    // Helper function to safely check if a field array exists and has items
    const hasFields = (fieldArray: any[] | undefined): boolean => {
        return !!fieldArray && fieldArray.length > 0;
    };

    // Effect to update the mapping when integration strategy changes
    useEffect(() => {
        if (isOpen) {
            loadAvailableFields?.('salesforce', integrationStrategy);
        }
    }, [integrationStrategy, isOpen, loadAvailableFields]);

    // Render a single mapping row
    const renderMappingRow = (mapping: SimplifiedMapping, index: number) => (
        <div key={index} className="grid grid-cols-[1fr,40px,1fr,40px] items-center gap-2">
            <Select
                value={mapping.prospectField}
                onValueChange={(value) => handleUpdateMapping(index, 'prospectField', value)}
            >
                <SelectTrigger>
                    <SelectValue placeholder="Select Andsend field" />
                </SelectTrigger>
                <SelectContent>
                    {availableFields?.prospect?.map((field) => (
                        <SelectItem key={field.name} value={field.name}>
                            {field.label}{' '}
                            {['firstName', 'lastName', 'email', 'organizationName', 'organizationDomain'].includes(
                                field.name
                            ) && '*'}
                        </SelectItem>
                    ))}
                </SelectContent>
            </Select>

            <Arrow className="w-5 h-5 text-muted-foreground" />

            <Select value={mapping.crmField} onValueChange={(value) => handleUpdateMapping(index, 'crmField', value)}>
                <SelectTrigger className={`w-full ${errors[index] ? 'border-red-500' : ''}`}>
                    <SelectValue placeholder="Select Salesforce field" />
                </SelectTrigger>
                <SelectContent>
                    {mapping.objectType === 'Contact' &&
                        'contact' in availableFields &&
                        hasFields(availableFields.contact) && (
                            <SelectGroup>
                                <SelectLabel>Contact Fields</SelectLabel>
                                {availableFields.contact.map((field) => (
                                    <SelectItem
                                        key={field.name}
                                        value={field.name}
                                        className={
                                            isSalesforceFieldSelected(field.name, index) ? 'text-muted-foreground' : ''
                                        }
                                    >
                                        {field.label}
                                    </SelectItem>
                                ))}
                            </SelectGroup>
                        )}

                    {mapping.objectType === 'Account' &&
                        'account' in availableFields &&
                        hasFields(availableFields.account) && (
                            <SelectGroup>
                                <SelectLabel>Account Fields</SelectLabel>
                                {availableFields.account.map((field) => (
                                    <SelectItem
                                        key={field.name}
                                        value={field.name}
                                        className={
                                            isSalesforceFieldSelected(field.name, index) ? 'text-muted-foreground' : ''
                                        }
                                    >
                                        {field.label}
                                    </SelectItem>
                                ))}
                            </SelectGroup>
                        )}

                    {mapping.objectType === 'Lead' && 'lead' in availableFields && hasFields(availableFields.lead) && (
                        <SelectGroup>
                            <SelectLabel>Lead Fields</SelectLabel>
                            {availableFields.lead.map((field) => (
                                <SelectItem
                                    key={field.name}
                                    value={field.name}
                                    className={
                                        isSalesforceFieldSelected(field.name, index) ? 'text-muted-foreground' : ''
                                    }
                                >
                                    {field.label}
                                </SelectItem>
                            ))}
                        </SelectGroup>
                    )}
                </SelectContent>
            </Select>

            <Button variant="ghost" size="icon" onClick={() => handleRemoveMapping(index)}>
                <Trash2 className="h-4 w-4" />
            </Button>

            {errors[index] && <p className="text-red-500 text-xs col-span-4 px-1">{errors[index]}</p>}
        </div>
    );

    return (
        <Dialog open={isOpen} onOpenChange={setIsOpen}>
            <DialogTrigger asChild>
                <Button variant="outline" size="sm" onClick={openDialog}>
                    View & Configure
                </Button>
            </DialogTrigger>
            <DialogContent className="max-w-3xl max-h-[80vh] flex flex-col">
                <DialogHeader className="mb-6">
                    <DialogTitle className="text-xl font-semibold">Field Mapping Configuration</DialogTitle>
                    <DialogDescription>
                        Configure how fields are mapped between Andsend and Salesforce
                    </DialogDescription>
                </DialogHeader>

                <div className="flex-1 overflow-y-auto pb-6">
                    {/* Header row with labels */}
                    <div className="grid grid-cols-[1fr,40px,1fr,40px] items-center mb-6 px-1">
                        <div className="text-center font-medium text-base">Andsend</div>
                        <div></div>
                        <div className="text-center font-medium text-base">Salesforce</div>
                        <div></div>
                    </div>

                    {isLoadingFields ? (
                        <div className="flex flex-col items-center justify-center py-12">
                            <Loader2 className="h-8 w-8 animate-spin text-primary mb-4" />
                            <p className="text-sm text-muted-foreground">Loading available fields...</p>
                        </div>
                    ) : (
                        <div className="space-y-8">
                            {integrationStrategy === 'ContactStrategy' ? (
                                <>
                                    {/* Contact mappings section */}
                                    <div className="space-y-4">
                                        <h3 className="text-base font-medium">Contact Fields</h3>
                                        <div className="space-y-4">
                                            {contactMappings.map((mapping, index) =>
                                                renderMappingRow(
                                                    { ...mapping, objectType: 'Contact' },
                                                    localMapping.findIndex((m) => m === mapping)
                                                )
                                            )}
                                        </div>
                                        <Button
                                            variant="outline"
                                            size="sm"
                                            className="w-full"
                                            onClick={() => handleAddMapping('Contact')}
                                        >
                                            <PlusCircle className="h-4 w-4 mr-2" />
                                            Add Contact Field Mapping
                                        </Button>
                                    </div>

                                    {/* Account mappings section */}
                                    <div className="space-y-4">
                                        <h3 className="text-base font-medium">Account Fields</h3>
                                        <div className="space-y-4">
                                            {accountMappings.map((mapping, index) =>
                                                renderMappingRow(
                                                    { ...mapping, objectType: 'Account' },
                                                    localMapping.findIndex((m) => m === mapping)
                                                )
                                            )}
                                        </div>
                                        <Button
                                            variant="outline"
                                            size="sm"
                                            className="w-full"
                                            onClick={() => handleAddMapping('Account')}
                                        >
                                            <PlusCircle className="h-4 w-4 mr-2" />
                                            Add Account Field Mapping
                                        </Button>
                                    </div>
                                </>
                            ) : (
                                /* Lead mappings section */
                                <div className="space-y-4">
                                    <h3 className="text-base font-medium">Lead Fields</h3>
                                    <div className="space-y-4">
                                        {leadMappings.map((mapping, index) =>
                                            renderMappingRow(
                                                { ...mapping, objectType: 'Lead' },
                                                localMapping.findIndex((m) => m === mapping)
                                            )
                                        )}
                                    </div>
                                    <Button
                                        variant="outline"
                                        size="sm"
                                        className="w-full"
                                        onClick={() => handleAddMapping('Lead')}
                                    >
                                        <PlusCircle className="h-4 w-4 mr-2" />
                                        Add Lead Field Mapping
                                    </Button>
                                </div>
                            )}
                        </div>
                    )}
                </div>

                <DialogFooter className="pt-4 border-t mt-auto">
                    <Button variant="outline" onClick={() => setIsOpen(false)}>
                        Cancel
                    </Button>
                    <Button onClick={handleSave} disabled={isLoadingFields || Object.keys(errors).length > 0}>
                        Save & Activate
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

interface SalesforceSettingsProps {
    isConnected: boolean;
    onDisconnect: () => void;
    isDisconnecting: boolean;
    account?: OrganizationIntegration;
    onUpdateScope?: (scope: string, value: boolean) => void;
    onUpdateFieldMapping?: (fieldMapping: SalesforceFieldMapping[]) => void;
    onUpdateIntegrationStrategy?: (strategy: 'ContactStrategy' | 'LeadStrategy') => void;
    isUpdatingSettings?: boolean;
    availableFields?: AvailableFields;
    loadAvailableFields?: (crmType: string, integrationStrategy?: 'ContactStrategy' | 'LeadStrategy') => void;
    isLoadingFields?: boolean;
}

export const SalesforceSettings: FC<SalesforceSettingsProps> = ({
    isConnected,
    onDisconnect,
    isDisconnecting,
    account,
    onUpdateScope,
    onUpdateFieldMapping,
    onUpdateIntegrationStrategy,
    isUpdatingSettings = false,
    availableFields,
    loadAvailableFields,
    isLoadingFields,
}) => {
    const { isSalesforceContactFields, isSalesforceLeadFields } = useCrmAccounts();
    const [currentIntegrationStrategy, setCurrentIntegrationStrategy] = useState<'ContactStrategy' | 'LeadStrategy'>(
        account?.settings?.integrationStrategy || 'ContactStrategy'
    );
    const [currentMapping, setCurrentMapping] = useState<SimplifiedMapping[]>([]);

    // Safely determine if fields are ready and which type they are
    const isSalesforceContactFieldsReady =
        availableFields && !isLoadingFields && isSalesforceContactFields(availableFields);

    const isSalesforceLeadFieldsReady = availableFields && !isLoadingFields && isSalesforceLeadFields(availableFields);

    // Group mappings by object type like in the original implementation
    const contactMappings = currentMapping.filter((mapping) => !mapping.objectType || mapping.objectType === 'Contact');

    const accountMappings = currentMapping.filter((mapping) => mapping.objectType === 'Account');

    const leadMappings = currentMapping.filter((mapping) => mapping.objectType === 'Lead');

    // Update local state when account settings change
    useEffect(() => {
        if (account?.settings?.integrationStrategy) {
            setCurrentIntegrationStrategy(account.settings.integrationStrategy);
        }

        if (account?.settings?.fieldMapping) {
            const mappings: SimplifiedMapping[] = account.settings.fieldMapping.map((mapping: any) => {
                if ('source' in mapping && 'destination' in mapping) {
                    return {
                        prospectField: mapping.source.fieldName,
                        crmField: mapping.destination.fieldName,
                        required: mapping.destination.required,
                        objectType: mapping.destination.objectType,
                    };
                } else {
                    // Handle legacy format
                    return {
                        prospectField: mapping.prospectField || '',
                        crmField: mapping.crmField || '',
                    };
                }
            });
            setCurrentMapping(mappings);
        }
    }, [account]);

    // Ensure fields are loaded when strategy changes
    useEffect(() => {
        if (loadAvailableFields) {
            loadAvailableFields('salesforce', currentIntegrationStrategy);
        }
    }, [currentIntegrationStrategy, loadAvailableFields]);

    // When integration strategy changes, load the appropriate fields
    const handleIntegrationStrategyChange = (value: 'ContactStrategy' | 'LeadStrategy') => {
        setCurrentIntegrationStrategy(value);

        // Notify parent component of the strategy change
        onUpdateIntegrationStrategy?.(value);
    };

    const handleSaveMapping = () => {
        if (onUpdateFieldMapping) {
            const salesforceMapping = currentMapping.map((mapping) =>
                convertToSalesforceMapping(mapping, currentIntegrationStrategy)
            );
            onUpdateFieldMapping(salesforceMapping);
        }
    };

    if (!isConnected) {
        return (
            <div className="flex flex-col items-center justify-center py-12">
                <AlertCircle className="h-12 w-12 text-yellow-500 mb-4" />
                <h3 className="text-lg font-medium mb-2">Not Connected</h3>
                <p className="text-gray-500 text-center mb-6">
                    Your Salesforce account is not connected. Please connect it from the apps page.
                </p>
                <Button variant="outline" asChild>
                    <a href="/account-management/apps">Go to Apps</a>
                </Button>
            </div>
        );
    }

    return (
        <div className="space-y-6">
            <div className="flex flex-col items-left">
                <SalesforceLogo className="object-contain" />
                <div className="mt-2">
                    <p className="text-sm text-muted-foreground">Manage your Salesforce connection settings</p>
                </div>
            </div>

            <Separator />

            <div className="space-y-4">
                <h4 className="text-md font-medium flex items-center gap-2">
                    <Settings2 className="h-5 w-5 text-gray-500 mt-0.5" />
                    Integration Strategy
                </h4>

                {/* Integration Strategy */}
                <div className="rounded-md border p-4">
                    <RadioGroup
                        value={currentIntegrationStrategy}
                        onValueChange={handleIntegrationStrategyChange}
                        className="flex flex-col space-y-4"
                        disabled={isUpdatingSettings}
                    >
                        <div className="flex items-center space-x-2">
                            <RadioGroupItem value="ContactStrategy" id="contact-strategy" />
                            <Label htmlFor="contact-strategy" className="font-medium">
                                Contact Strategy
                            </Label>
                        </div>
                        <p className="text-sm text-muted-foreground ml-6">
                            Maps prospects to Salesforce Contacts. Creates Accounts if they don't exist.
                        </p>

                        <div className="flex items-center space-x-2 mt-4">
                            <RadioGroupItem value="LeadStrategy" id="lead-strategy" />
                            <Label htmlFor="lead-strategy" className="font-medium">
                                Lead Strategy
                            </Label>
                        </div>
                        <p className="text-sm text-muted-foreground ml-6">
                            Maps prospects to Salesforce Leads. Simple approach that doesn't require Account management.
                        </p>
                    </RadioGroup>
                </div>
            </div>

            <Separator />

            <div className="space-y-4">
                <h4 className="text-md font-medium flex items-center gap-2">
                    <RefreshCw className="h-5 w-5 text-muted-foreground mt-0.5" />
                    Sync Settings
                </h4>

                {/* Contacts/Prospects Sync */}
                <div className="rounded-md border p-4">
                    <div className="flex items-center justify-between">
                        <div className="flex items-start gap-4">
                            <Users className="h-5 w-5 text-muted-foreground mt-0.5" />
                            <div className="space-y-1">
                                <p className="text-sm font-medium">Contact Sync</p>
                                <p className="text-sm text-muted-foreground">
                                    Sync contacts between Andsend and Salesforce
                                </p>
                            </div>
                        </div>
                        <Switch
                            checked={account?.settings?.integrationScope?.prospects ?? false}
                            onCheckedChange={(checked) => onUpdateScope?.('prospects', checked)}
                            disabled={isDisconnecting || isUpdatingSettings}
                            className="data-[state=checked]:bg-background-brand-primary data-[state=unchecked]:bg-input"
                        />
                    </div>
                </div>

                {/* Actions Logging */}
                <div className="rounded-md border p-4">
                    <div className="flex items-center justify-between">
                        <div className="flex items-start gap-4">
                            <MessageSquare className="h-5 w-5 text-muted-foreground mt-0.5" />
                            <div className="space-y-1">
                                <p className="text-sm font-medium">Action Logging</p>
                                <p className="text-sm text-muted-foreground">
                                    Log Andsend actions and messages in Salesforce
                                </p>
                            </div>
                        </div>
                        <Switch
                            checked={account?.settings?.integrationScope?.actionMessages ?? false}
                            onCheckedChange={(value) => onUpdateScope?.('actionMessages', value)}
                            disabled={isDisconnecting || isUpdatingSettings}
                            className="data-[state=checked]:bg-background-brand-primary data-[state=unchecked]:bg-input"
                        />
                    </div>
                </div>
            </div>

            <Separator />

            <div className="space-y-4">
                <div className="flex items-start gap-4 justify-between">
                    <div className="flex items-start gap-4">
                        <Settings2 className="h-5 w-5 text-muted-foreground mt-0.5" />
                        <div className="space-y-1">
                            <p className="text-sm font-medium">Field Mapping</p>
                            <p className="text-sm text-muted-foreground">
                                Configure how fields map between Andsend and Salesforce
                            </p>
                            <div className="mt-2 text-xs text-muted-foreground">
                                {account?.settings?.fieldMapping && (
                                    <p>• {account.settings.fieldMapping.length || 0} field mappings configured</p>
                                )}
                            </div>
                        </div>
                    </div>

                    <FieldMappingDialog
                        fieldMapping={(account?.settings?.fieldMapping as SalesforceFieldMapping[]) || []}
                        onUpdateMapping={onUpdateFieldMapping}
                        availableFields={
                            isSalesforceContactFieldsReady
                                ? (availableFields as AvailableSalesforceContactFields)
                                : isSalesforceLeadFieldsReady
                                ? (availableFields as AvailableSalesforceLeadFields)
                                : undefined
                        }
                        loadAvailableFields={loadAvailableFields}
                        isLoadingFields={isLoadingFields}
                        integrationStrategy={currentIntegrationStrategy}
                    />
                </div>
            </div>
            <Separator />
            <div className="space-y-4">
                <h4 className="text-md font-medium text-red-500">Danger Zone</h4>

                <div className="rounded-md border border-red-200 p-4">
                    <div className="flex items-start gap-4">
                        <Trash2 className="h-5 w-5 text-red-500 mt-0.5" />
                        <div className="space-y-1 flex-1">
                            <p className="text-sm font-medium">Disconnect Account</p>
                            <p className="text-sm text-gray-500">
                                Remove this Salesforce account from your connected accounts. This will stop all syncing
                                between Andsend and Salesforce.
                            </p>
                        </div>
                        <Button variant="destructive" size="sm" onClick={onDisconnect} disabled={isDisconnecting}>
                            {isDisconnecting ? 'Disconnecting...' : 'Disconnect'}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};
