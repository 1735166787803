import { IQuery, Topics } from '@zaplify/common';
import { SubscriptionSummaryDto } from '../subscription-summary.dto';

export class GetSubscriptionSummaryQuery extends IQuery<SubscriptionSummaryDto> {
    public static override readonly TOPIC = Topics.BILLING;

    constructor(readonly organizationId: string) {
        super();
    }
}
