import { Box, Button, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { getAuth } from 'firebase/auth';
import { Suspense } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { ReactComponent as ZaplifyLogotype } from '../../components/icons/zaplify-logotype.svg';
import { paths } from '../../routes/paths';
import { isAndSend } from '../../helpers/is-and-send';
import { AndSendLogotype } from '../../components/icons/and-send/andsend-logo';
import { LoaderView } from '../../views/loader';

export const AuthTemplate = ({ hideExitButton }: { hideExitButton?: boolean }) => {
    const navigate = useNavigate();
    const { mutate: logout } = useMutation({
        mutationFn: async () => {
            await getAuth().signOut();
        },
        onSuccess: (data) => {
            navigate(paths.OLD_LOGIN);
        },
        onError: (data) => {
            console.error(`:fire: [AuthTemplate] logout > onError: ${JSON.stringify(data)}`);
        },
    });

    const handleExit = () => {
        logout();
    };

    return (
        <Suspense fallback={<LoaderView />}>
            <Box
                sx={{
                    maxHeight: '100vh',
                    height: '100vh',
                    display: 'flex',
                    // py: 3,
                    flexDirection: 'column',
                    gap: 4,
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: 'linear-gradient(173.85deg, #F7FCFF 4.86%, #F4EFFA 91.62%)',
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        left: '24px',
                        top: '24px',
                        display: { xs: 'none', sm: 'flex' },
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        gap: 3,
                    }}
                >
                    {isAndSend() ? (
                        <AndSendLogotype variation="transparent_black" height="36px" />
                    ) : (
                        <ZaplifyLogotype />
                    )}
                    {!hideExitButton && (
                        <ExitButton onExit={handleExit}>
                            <Typography
                                component={'span'}
                                sx={{
                                    fontSize: '14px',
                                    fontWeight: 500,
                                    lineHeight: '21px',
                                    color: '#3A4B59',
                                }}
                            >
                                Exit
                            </Typography>
                        </ExitButton>
                    )}
                </Box>
                <Box
                    sx={{
                        left: '24px',
                        top: '24px',
                        display: { xs: 'flex', sm: 'none' },
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        gap: 3,
                    }}
                >
                    <Link to={paths.HOME} style={{ textDecoration: 'none' }}>
                        {isAndSend() ? (
                            <AndSendLogotype variation="transparent_black" height="36px" />
                        ) : (
                            <ZaplifyLogotype />
                        )}
                    </Link>
                    {!hideExitButton && (
                        <ExitButton onExit={handleExit}>
                            <Typography
                                component={'span'}
                                sx={{
                                    fontSize: '14px',
                                    fontWeight: 500,
                                    lineHeight: '21px',
                                    color: '#3A4B59',
                                }}
                            >
                                Exit
                            </Typography>
                        </ExitButton>
                    )}
                </Box>
                <Outlet />
            </Box>
        </Suspense>
    );
};

const ExitButton = ({ children, onExit }: { children: React.ReactNode; onExit: () => void }) => {
    return (
        <Button
            onClick={onExit}
            sx={{
                width: 'fit-content',
                padding: '4px 12px',
                display: 'flex',
                alignItems: 'center',
                border: '1px solid #E4E6EB',
                borderRadius: 2,
                boxShadow: '0px 1px 2px rgba(20, 21, 26, 0.06)',
                backgroundColor: 'var(--grey-palette-white, #fff)',
                gap: '6px',
                cursor: 'pointer',
            }}
            disableElevation
        >
            {children}
        </Button>
    );
};
