import { FC, Suspense, useEffect, useState } from 'react';
import { DatePickerWithRange } from '../../components/date-range-picker';
import { AdminHeader } from './components/admin-header';
import { PerformanceMetricsCard } from './components/performance-metrics-card';
import { TopPerformersCard } from './components/top-performers-card';
import { addDays } from 'date-fns';
import { DateRange } from 'react-day-picker';
import { useMutation } from '@tanstack/react-query';
import { getZaplifySdk } from '@zaplify/sdk';
import { Button } from '@shadcn/ui/components/ui/button';
import { TriangleAlert } from 'lucide-react';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@shadcn/ui/components/ui/tooltip';
import { useApolloClient } from '@apollo/client';
import { GET_PERFORMANCE_METRICS } from '@zaplify/graphql';
import { useToast } from '@shadcn/ui/hooks/use-toast';
import { Card, CardDescription, CardTitle, CardHeader } from '@shadcn/ui/components/ui/card';

export const AdminPage: FC = () => {
    const client = useApolloClient();
    const { toast } = useToast();
    const [date, setDate] = useState<DateRange>({
        from: addDays(new Date(), -7),
        to: new Date(),
    });
    const [daysToGenerate, setDaysToGenerate] = useState(2);
    useEffect(() => {
        const daysToGenerate = Math.ceil((date.to.getTime() - date.from.getTime()) / (1000 * 60 * 60 * 24));
        setDaysToGenerate(daysToGenerate);
    }, [date]);

    const { mutateAsync: generateMetrics, isPending } = useMutation({
        mutationFn: () => {
            return getZaplifySdk().profiles.outreachSuggestions.generateOutreachCompletionMetrics(daysToGenerate);
        },
        onSuccess: () => {
            client.refetchQueries({
                include: [GET_PERFORMANCE_METRICS],
            });
        },
        onError: (error) => {
            toast({
                title: 'Error',
                description: error.message,
                variant: 'destructive',
            });
        },
    });

    return (
        <div className="flex h-full overflow-auto">
            <div className="flex-1 flex flex-col min-w-0">
                <Suspense fallback={<div>Loading...</div>}>
                    <AdminHeader />
                </Suspense>

                <div className="flex flex-col flex-1 min-h-0 m-4 gap-4">
                    <div className="flex justify-between">
                        <div className="flex items-center gap-2">
                            <TooltipProvider>
                                <Tooltip delayDuration={0}>
                                    <TooltipTrigger asChild>
                                        <Button onClick={() => generateMetrics()} disabled={isPending}>
                                            {isPending ? (
                                                'Regenerating...'
                                            ) : (
                                                <>
                                                    <TriangleAlert className="h-4 w-4" />
                                                    <span>Regenerate</span>
                                                </>
                                            )}
                                        </Button>
                                    </TooltipTrigger>
                                    <TooltipContent>
                                        <p>
                                            Will run a heavy database job that can affect user experience. Do not run
                                            often.
                                        </p>
                                    </TooltipContent>
                                </Tooltip>
                            </TooltipProvider>
                            <input
                                type="number"
                                value={daysToGenerate}
                                onChange={(e) => setDaysToGenerate(Number(e.target.value))}
                                className="w-14 px-2 py-1 border rounded"
                                min="1"
                                max="365"
                            />
                            <span className="text-sm text-gray-600">days</span>
                        </div>
                        <DatePickerWithRange date={date} setDate={setDate} />
                    </div>

                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
                        <div className="lg:col-span-2">
                            <Suspense fallback={<div>Loading...</div>}>
                                {date.from && date.to && (
                                    <PerformanceMetricsCard startDate={date.from} endDate={date.to} />
                                )}
                            </Suspense>
                        </div>
                        <div>
                            <Suspense fallback={<div>Loading...</div>}>
                                {/* {date.from && date.to && <TopPerformersCard startDate={date.from} endDate={date.to} />} */}
                                <Card className="w-full">
                                    <CardHeader>
                                        <div className="flex justify-between items-center">
                                            <CardTitle>Performance Metrics Calculation</CardTitle>
                                        </div>
                                        <CardDescription>
                                            The performance metrics are calculated based on the data collected within
                                            the selected date range. Hover over a specific date to see the underlaying
                                            values for that specific day.
                                            <br />
                                            <br />
                                            <strong>OMTM Score:</strong> (Completed Actions * Goals Reached) /
                                            Suggestions
                                            <br />
                                            <br />
                                            Scroll down to see charts with more details and additional analysis with
                                            cohorts.
                                        </CardDescription>
                                    </CardHeader>
                                </Card>
                            </Suspense>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminPage;
