export const liMemberUrnFromMemberId = (memberId: string) => `urn:li:fsd_profile:${memberId}`;
export const liCompanyUrnFromCompanyId = (memberId: string) => `urn:li:fsd_company:${memberId}`;

export function extractIdFromUrn(urn: string): string;
export function extractIdFromUrn(urn: string, castToNumber?: false): string;
export function extractIdFromUrn(urn: string, castToNumber?: true): number;
export function extractIdFromUrn(urn: string, castToNumber?: boolean) {
    const splitUrn = urn.split(':');
    const id = splitUrn.length === 1 ? undefined : splitUrn.pop();
    return castToNumber && id !== undefined ? Number(id) : id;
}

export const extractPublicIdentifierFromUrl = (url: string) => {
    const splitUrl = url.split('/');
    return splitUrl.length === 1 ? undefined : splitUrl.pop();
};

export function genSeqOf16() {
    const e = new Array(16);
    for (var t, r = 0; r < 16; r++) {
        // @ts-ignore
        !(3 & r) && (t = 4294967296 * Math.random()), (e[r] = (t >>> ((3 & r) << 3)) & 255);
    }
    return e;
}

// Buffer pollyfill... not sure if neeeded anymore, since any reasonable chrome version should support Buffer
for (
    var BufferPollyfill = typeof Buffer == 'function' ? Buffer : Array, Iw: string[] = [], Xbe = {}, Mc = 0;
    Mc < 256;
    Mc++
) {
    // @ts-ignore
    Iw[Mc] = (Mc + 256).toString(16).substr(1);
    // @ts-ignore
    Xbe[Iw[Mc]] = Mc;
}

function bufferToUUID(e: any, t?: number) {
    let r = t || 0;
    const n = Iw;
    return `${n[e[r++]] + n[e[r++]] + n[e[r++]] + n[e[r++]]}-${n[e[r++]]}${n[e[r++]]}-${n[e[r++]]}${n[e[r++]]}-${
        n[e[r++]]
    }${n[e[r++]]}-${n[e[r++]]}${n[e[r++]]}${n[e[r++]]}${n[e[r++]]}${n[e[r++]]}${n[e[r++]]}`;
}

function genRandomId(e?: any, t?: any, r?: any) {
    const n = (t && r) || 0;
    typeof e == 'string' && ((t = e === 'binary' ? new BufferPollyfill(16) : null), (e = null));
    const a = (e = e || {}).random || (e.rng || genSeqOf16)();
    if (((a[6] = (15 & a[6]) | 64), (a[8] = (63 & a[8]) | 128), t)) for (let i = 0; i < 16; i++) t[n + i] = a[i];
    return t || bufferToUUID(a);
}

export const getSomeOriginTokenGeneratorFunc = () => genRandomId();

function bufferToString(e: any) {
    let t = '';
    for (let r = 0; r < e.length; r++) t += String.fromCharCode(e[r]);
    return t;
}

export const getSomeTrackingIdGeneratorFunc = () => bufferToString(genRandomId(null, new Array(16)));

const buildGifEntityUrn = (gifId: string) => `urn:li:msg_externalMedia:${gifId}`;

export const genRenderContentUnions = (messageContent: any) =>
    messageContent.gif
        ? [
              {
                  externalMedia: {
                      entityUrn: buildGifEntityUrn(messageContent.gif.id),
                      media: {
                          $type: 'com.linkedin.voyager.common.MediaProxyImage',
                          originalHeight: messageContent.gif.gif.height,
                          originalWidth: messageContent.gif.gif.width,
                          url: messageContent.gif.gif.url,
                      },
                  },
              },
          ]
        : messageContent.attachments
        ? messageContent.attachments
        : [];

export const extractLiProfilePictureSrcUrl = (profilePicture: any) => {
    let lastArtifact;
    const { rootUrl } = profilePicture,
        fileIdentifyingUrlPathSegment =
            (lastArtifact = profilePicture.artifacts?.[0]) == null
                ? undefined
                : lastArtifact.fileIdentifyingUrlPathSegment;
    // console.log(
    //     'getLiProfilePictureSrcUrl\n\ne.artifacts: ',
    //     profilePicture.artifacts,
    //     `url: ${rootUrl}${fileIdentifyingUrlPathSegment}`,
    // );
    return `${rootUrl}${fileIdentifyingUrlPathSegment}`;
};

// Taken from https://learn.microsoft.com/en-us/linkedin/shared/references/reference-tables/industry-codes-v2
export const getIndustryCodeToName = (id: string): string | null => {
    if (id === null || id === undefined) return '';

    const idNumber = parseInt(id);

    const industryCodeToName: Record<number, string> = {
        2190: 'Accommodation Services',
        34: 'Food and Beverage Services',
        2217: 'Bars, Taverns, and Nightclubs',
        2212: 'Caterers',
        2214: 'Mobile Food Services',
        32: 'Restaurants',
        31: 'Hospitality',
        2197: 'Bed-and-Breakfasts, Hostels, Homestays',
        2194: 'Hotels and Motels',
        1912: 'Administrative and Support Services',
        1938: 'Collection Agencies',
        110: 'Events Services',
        122: 'Facilities Services',
        1965: 'Janitorial Services',
        2934: 'Landscaping Services',
        101: 'Fundraising',
        1916: 'Office Administration',
        121: 'Security and Investigations',
        1956: 'Security Guards and Patrol Services',
        1958: 'Security Systems Services',
        104: 'Staffing and Recruiting',
        1923: 'Executive Search Services',
        1925: 'Temporary Help Services',
        1931: 'Telephone Call Centers',
        108: 'Translation and Localization',
        30: 'Travel Arrangements',
        103: 'Writing and Editing',
        48: 'Construction',
        406: 'Building Construction',
        413: 'Nonresidential Building Construction',
        408: 'Residential Building Construction',
        51: 'Civil Engineering',
        431: 'Highway, Street, and Bridge Construction',
        428: 'Subdivision of Land',
        419: 'Utility System Construction',
        435: 'Specialty Trade Contractors',
        453: 'Building Equipment Contractors',
        460: 'Building Finishing Contractors',
        436: 'Building Structure and Exterior Contractors',
        91: 'Consumer Services',
        90: 'Civic and Social Organizations',
        1909: 'Industry Associations',
        107: 'Political Organizations',
        1911: 'Professional Organizations',
        2318: 'Household Services',
        100: 'Non-profit Organizations',
        2258: 'Personal and Laundry Services',
        2272: 'Laundry and Drycleaning Services',
        2259: 'Personal Care Services',
        2282: 'Pet Services',
        131: 'Philanthropic Fundraising Services',
        89: 'Religious Institutions',
        2225: 'Repair and Maintenance',
        2247: 'Commercial and Industrial Machinery Maintenance',
        2240: 'Electronic and Precision Equipment Maintenance',
        2255: 'Footwear and Leather Goods Repair',
        2253: 'Reupholstery and Furniture Repair',
        2226: 'Vehicle Repair and Maintenance',
        1999: 'Education',
        132: 'E-Learning Providers',
        68: 'Higher Education',
        67: 'Primary and Secondary Education',
        105: 'Professional Training and Coaching',
        2018: 'Technical and Vocational Training',
        2019: 'Cosmetology and Barber Schools',
        2025: 'Fine Arts Schools',
        2020: 'Flight Training',
        2029: 'Language Schools',
        2012: 'Secretarial Schools',
        2027: 'Sports and Recreation Instruction',
        28: 'Entertainment Providers',
        38: 'Artists and Writers',
        37: 'Museums, Historical Sites, and Zoos',
        2161: 'Historical Sites',
        2159: 'Museums',
        2163: 'Zoos and Botanical Gardens',
        115: 'Musicians',
        2130: 'Performing Arts and Spectator Sports',
        2139: 'Circuses and Magic Shows',
        2135: 'Dance Companies',
        39: 'Performing Arts',
        33: 'Spectator Sports',
        2143: 'Racetracks',
        2142: 'Sports Teams and Clubs',
        2133: 'Theater Companies',
        40: 'Recreational Facilities',
        2167: 'Amusement Parks and Arcades',
        29: 'Gambling Facilities and Casinos',
        2179: 'Golf Courses and Country Clubs',
        2181: 'Skiing Facilities',
        124: 'Wellness and Fitness Services',
        201: 'Farming, Ranching, Forestry',
        63: 'Farming',
        150: 'Horticulture',
        298: 'Forestry and Logging',
        256: 'Ranching and Fisheries',
        66: 'Fisheries',
        64: 'Ranching',
        43: 'Financial Services',
        129: 'Capital Markets',
        1720: 'Investment Advice',
        45: 'Investment Banking',
        46: 'Investment Management',
        1713: 'Securities and Commodity Exchanges',
        106: 'Venture Capital and Private Equity Principals',
        1673: 'Credit Intermediation',
        41: 'Banking',
        141: 'International Trade and Development',
        1696: 'Loan Brokers',
        1678: 'Savings Institutions',
        1742: 'Funds and Trusts',
        1743: 'Insurance and Employee Benefit Funds',
        1745: 'Pension Funds',
        1750: 'Trusts and Estates',
        42: 'Insurance',
        1738: 'Claims Adjusting, Actuarial Services',
        1737: 'Insurance Agencies and Brokerages',
        1725: 'Insurance Carriers',
        75: 'Government Administration',
        73: 'Administration of Justice',
        3068: 'Correctional Institutions',
        3065: 'Courts of Law',
        3070: 'Fire Protection',
        77: 'Law Enforcement',
        78: 'Public Safety',
        2375: 'Economic Programs',
        3085: 'Transportation Programs',
        3086: 'Utilities Administration',
        388: 'Environmental Quality Programs',
        2366: 'Air, Water, and Waste Program Management',
        2368: 'Conservation Programs',
        2353: 'Health and Human Services',
        69: 'Education Administration Programs',
        2360: 'Public Assistance Programs',
        2358: 'Public Health',
        2369: 'Housing and Community Development',
        2374: 'Community Development and Urban Planning',
        3081: 'Housing Programs',
        2391: 'Military and International Affairs',
        71: 'Armed Forces',
        74: 'International Affairs',
        79: 'Public Policy Offices',
        76: 'Executive Offices',
        72: 'Legislative Offices',
        3089: 'Space Research and Technology',
        1905: 'Holding Companies',
        14: 'Hospitals and Health Care',
        2115: 'Community Services',
        2112: 'Services for the Elderly and Disabled',
        2081: 'Hospitals',
        88: 'Individual and Family Services',
        2128: 'Child Day Care Services',
        2122: 'Emergency and Relief Services',
        2125: 'Vocational Rehabilitation Services',
        13: 'Medical Practices',
        125: 'Alternative Medicine',
        2077: 'Ambulance Services',
        2048: 'Chiropractors',
        2045: 'Dentists',
        2060: 'Family Planning Centers',
        2074: 'Home Health Care Services',
        2069: 'Medical and Diagnostic Laboratories',
        139: 'Mental Health Care',
        2050: 'Optometrists',
        2063: 'Outpatient Care Centers',
        2054: 'Physical, Occupational and Speech Therapists',
        2040: 'Physicians',
        2091: 'Nursing Homes and Residential Care Facilities',
        25: 'Manufacturing',
        598: 'Apparel Manufacturing',
        615: 'Fashion Accessories Manufacturing',
        112: 'Appliances, Electrical, and Electronics Manufacturing',
        998: 'Electric Lighting Equipment Manufacturing',
        2468: 'Electrical Equipment Manufacturing',
        3255: 'Fuel Cell Manufacturing',
        1005: 'Household Appliance Manufacturing',
        54: 'Chemical Manufacturing',
        709: 'Agricultural Chemical Manufacturing',
        703: 'Artificial Rubber and Synthetic Fiber Manufacturing',
        690: 'Chemical Raw Materials Manufacturing',
        722: 'Paint, Coating, and Adhesive Manufacturing',
        18: 'Personal Care Product Manufacturing',
        15: 'Pharmaceutical Manufacturing',
        727: 'Soap and Cleaning Product Manufacturing',
        3251: 'Climate Technology Product Manufacturing',
        24: 'Computers and Electronics Manufacturing',
        973: 'Audio and Video Equipment Manufacturing',
        964: 'Communications Equipment Manufacturing',
        3: 'Computer Hardware Manufacturing',
        3245: 'Accessible Hardware Manufacturing',
        994: 'Magnetic and Optical Media Manufacturing',
        983: 'Measuring and Control Instrument Manufacturing',
        3254: 'Smart Meter Manufacturing',
        7: 'Semiconductor Manufacturing',
        144: 'Renewable Energy Semiconductor Manufacturing',
        840: 'Fabricated Metal Products',
        852: 'Architectural and Structural Metal Manufacturing',
        861: 'Boilers, Tanks, and Shipping Container Manufacturing',
        871: 'Construction Hardware Manufacturing',
        849: 'Cutlery and Handtool Manufacturing',
        883: 'Metal Treatments',
        887: 'Metal Valve, Ball, and Roller Manufacturing',
        873: 'Spring and Wire Product Manufacturing',
        876: 'Turned Products and Fastener Manufacturing',
        23: 'Food and Beverage Manufacturing',
        562: 'Breweries',
        564: 'Distilleries',
        2500: 'Wineries',
        481: 'Animal Feed Manufacturing',
        529: 'Baked Goods Manufacturing',
        142: 'Beverage Manufacturing',
        65: 'Dairy Product Manufacturing',
        504: 'Fruit and Vegetable Preserves Manufacturing',
        521: 'Meat Products Manufacturing',
        528: 'Seafood Product Manufacturing',
        495: 'Sugar and Confectionery Product Manufacturing',
        26: 'Furniture and Home Furnishings Manufacturing',
        1080: 'Household and Institutional Furniture Manufacturing',
        1095: 'Mattress and Blinds Manufacturing',
        1090: 'Office Furniture and Fixtures Manufacturing',
        145: 'Glass, Ceramics and Concrete Manufacturing',
        799: 'Abrasives and Nonmetallic Minerals Manufacturing',
        773: 'Clay and Refractory Products Manufacturing',
        779: 'Glass Product Manufacturing',
        794: 'Lime and Gypsum Products Manufacturing',
        616: 'Leather Product Manufacturing',
        622: 'Footwear Manufacturing',
        625: "Women's Handbag Manufacturing",
        55: 'Machinery Manufacturing',
        901: 'Agriculture, Construction, Mining Machinery Manufacturing',
        147: 'Automation Machinery Manufacturing',
        3247: 'Robot Manufacturing',
        918: 'Commercial and Service Industry Machinery Manufacturing',
        935: 'Engines and Power Transmission Equipment Manufacturing',
        3241: 'Renewable Energy Equipment Manufacturing',
        923: 'HVAC and Refrigeration Equipment Manufacturing',
        135: 'Industrial Machinery Manufacturing',
        928: 'Metalworking Machinery Manufacturing',
        17: 'Medical Equipment Manufacturing',
        679: 'Oil and Coal Product Manufacturing',
        61: 'Paper and Forest Product Manufacturing',
        743: 'Plastics and Rubber Product Manufacturing',
        146: 'Packaging and Containers Manufacturing',
        117: 'Plastics Manufacturing',
        763: 'Rubber Products Manufacturing',
        807: 'Primary Metal Manufacturing',
        83: 'Printing Services',
        20: 'Sporting Goods Manufacturing',
        60: 'Textile Manufacturing',
        21: 'Tobacco Manufacturing',
        1029: 'Transportation Equipment Manufacturing',
        52: 'Aviation and Aerospace Component Manufacturing',
        1: 'Defense and Space Manufacturing',
        53: 'Motor Vehicle Manufacturing',
        3253: 'Alternative Fuel Vehicle Manufacturing',
        1042: 'Motor Vehicle Parts Manufacturing',
        62: 'Railroad Equipment Manufacturing',
        58: 'Shipbuilding',
        784: 'Wood Product Manufacturing',
        332: 'Oil, Gas, and Mining',
        56: 'Mining',
        341: 'Coal Mining',
        345: 'Metal Ore Mining',
        356: 'Nonmetallic Mineral Mining',
        57: 'Oil and Gas',
        3096: 'Natural Gas Extraction',
        3095: 'Oil Extraction',
        1810: 'Professional Services',
        47: 'Accounting',
        80: 'Advertising Services',
        148: 'Government Relations Services',
        98: 'Public Relations and Communications Services',
        97: 'Market Research',
        50: 'Architecture and Planning',
        3246: 'Accessible Architecture and Design',
        11: 'Business Consulting and Services',
        86: 'Environmental Services',
        137: 'Human Resources Services',
        1862: 'Marketing Services',
        2401: 'Operations Consulting',
        123: 'Outsourcing and Offshoring Consulting',
        102: 'Strategic Management Services',
        99: 'Design Services',
        140: 'Graphic Design',
        3256: 'Regenerative Design',
        3126: 'Interior Design',
        3242: 'Engineering Services',
        3248: 'Robotics Engineering',
        3249: 'Surveying and Mapping Services',
        96: 'IT Services and IT Consulting',
        118: 'Computer and Network Security',
        3244: 'Digital Accessibility Services',
        3102: 'IT System Custom Software Development',
        3106: 'IT System Data Services',
        1855: 'IT System Design Services',
        3104: 'IT System Installation and Disposal',
        3103: 'IT System Operations and Maintenance',
        3107: 'IT System Testing and Evaluation',
        3105: 'IT System Training and Support',
        10: 'Legal Services',
        120: 'Alternative Dispute Resolution',
        9: 'Law Practice',
        136: 'Photography',
        70: 'Research Services',
        12: 'Biotechnology Research',
        114: 'Nanotechnology Research',
        130: 'Think Tanks',
        3243: 'Services for Renewable Energy',
        16: 'Veterinary Services',
        1757: 'Real Estate and Equipment Rental Services',
        1779: 'Equipment Rental Services',
        1798: 'Commercial and Industrial Equipment Rental',
        1786: 'Consumer Goods Rental',
        44: 'Real Estate',
        128: 'Leasing Non-residential Real Estate',
        1759: 'Leasing Residential Real Estate',
        1770: 'Real Estate Agents and Brokers',
        27: 'Retail',
        1339: 'Food and Beverage Retail',
        22: 'Retail Groceries',
        1445: 'Online and Mail Order Retail',
        19: 'Retail Apparel and Fashion',
        1319: 'Retail Appliances, Electrical, and Electronic Equipment',
        3186: 'Retail Art Dealers',
        111: 'Retail Art Supplies',
        1409: 'Retail Books and Printed News',
        1324: 'Retail Building Materials and Garden Equipment',
        1423: 'Retail Florists',
        1309: 'Retail Furniture and Home Furnishings',
        1370: 'Retail Gasoline',
        1359: 'Retail Health and Personal Care Products',
        3250: 'Retail Pharmacies',
        143: 'Retail Luxury Goods and Jewelry',
        1292: 'Retail Motor Vehicles',
        1407: 'Retail Musical Instruments',
        138: 'Retail Office Equipment',
        1424: 'Retail Office Supplies and Gifts',
        1431: 'Retail Recyclable Materials & Used Merchandise',
        1594: 'Technology, Information and Media',
        3133: 'Media & Telecommunications',
        82: 'Book and Periodical Publishing',
        1602: 'Book Publishing',
        81: 'Newspaper Publishing',
        1600: 'Periodical Publishing',
        36: 'Broadcast Media Production and Distribution',
        1641: 'Cable and Satellite Programming',
        1633: 'Radio and Television Broadcasting',
        35: 'Movies, Videos and Sound',
        127: 'Animation and Post-production',
        126: 'Media Production',
        1611: 'Movies and Sound Recording',
        1623: 'Sound Recording',
        1625: 'Sheet Music Publishing',
        8: 'Telecommunications',
        1649: 'Satellite Telecommunications',
        1644: 'Telecommunications Carriers',
        119: 'Wireless Services',
        6: 'Technology, Information and Internet',
        2458: 'Data Infrastructure and Analytics',
        3134: 'Blockchain Services',
        3128: 'Business Intelligence Platforms',
        3252: 'Climate Data and Analytics',
        84: 'Information Services',
        3132: 'Internet Publishing',
        3129: 'Business Content',
        113: 'Online Audio and Video Media',
        3124: 'Internet News',
        85: 'Libraries',
        3125: 'Blogs',
        1285: 'Internet Marketplace Platforms',
        3127: 'Social Networking Platforms',
        4: 'Software Development',
        109: 'Computer Games',
        3131: 'Mobile Gaming Apps',
        5: 'Computer Networking Products',
        3130: 'Data Security Software Products',
        3101: 'Desktop Computing Software Products',
        3099: 'Embedded Software Products',
        3100: 'Mobile Computing Software Products',
        116: 'Transportation, Logistics, Supply Chain and Storage',
        94: 'Airlines and Aviation',
        87: 'Freight and Package Transportation',
        1495: 'Ground Passenger Transportation',
        1504: 'Interurban and Rural Bus Services',
        1512: 'School and Employee Bus Services',
        1517: 'Shuttles and Special Needs Transportation Services',
        1532: 'Sightseeing Transportation',
        1505: 'Taxi and Limousine Services',
        1497: 'Urban Transit Services',
        95: 'Maritime Transportation',
        1520: 'Pipeline Transportation',
        1573: 'Postal Services',
        1481: 'Rail Transportation',
        92: 'Truck Transportation',
        93: 'Warehousing and Storage',
        59: 'Utilities',
        383: 'Electric Power Generation',
        385: 'Fossil Fuel Electric Power Generation',
        386: 'Nuclear Electric Power Generation',
        3240: 'Renewable Energy Power Generation',
        390: 'Biomass Electric Power Generation',
        389: 'Geothermal Electric Power Generation',
        384: 'Hydroelectric Power Generation',
        387: 'Solar Electric Power Generation',
        2489: 'Wind Electric Power Generation',
        382: 'Electric Power Transmission, Control, and Distribution',
        397: 'Natural Gas Distribution',
        398: 'Water, Waste, Steam, and Air Conditioning Services',
        404: 'Steam and Air-Conditioning Supply',
        1981: 'Waste Collection',
        1986: 'Waste Treatment and Disposal',
        400: 'Water Supply and Irrigation Systems',
        133: 'Wholesale',
        1267: 'Wholesale Alcoholic Beverages',
        1222: 'Wholesale Apparel and Sewing Supplies',
        1171: 'Wholesale Appliances, Electrical, and Electronics',
        49: 'Wholesale Building Materials',
        1257: 'Wholesale Chemical and Allied Products',
        1157: 'Wholesale Computer Equipment',
        1221: 'Wholesale Drugs and Sundries',
        1231: 'Wholesale Food and Beverage',
        1230: 'Wholesale Footwear',
        1137: 'Wholesale Furniture and Home Furnishings',
        1178: 'Wholesale Hardware, Plumbing, Heating Equipment',
        134: 'Wholesale Import and Export',
        1208: 'Wholesale Luxury Goods and Jewelry',
        1187: 'Wholesale Machinery',
        1166: 'Wholesale Metals and Minerals',
        1128: 'Wholesale Motor Vehicles and Parts',
        1212: 'Wholesale Paper Products',
        1262: 'Wholesale Petroleum and Petroleum Products',
        1153: 'Wholesale Photography Equipment and Supplies',
        1250: 'Wholesale Raw Farm Products',
        1206: 'Wholesale Recyclable Materials',

        // Deprecated
        3215: 'Animation',
        3190: 'Apparel and Fashion',
        3204: 'Arts and Crafts',
        3198: 'Automotive',
        3226: 'Aviation & Aerospace',
        3238: 'Biotechnology',
        3197: 'Building Materials',
        3211: 'Business Supplies and Equipment',
        3219: 'Commercial Real Estate',
        3235: 'Computer Hardware',
        3234: 'Computer Networking',
        3191: 'Consumer Electronics',
        3192: 'Consumer Goods',
        3189: 'Cosmetics',
        3199: 'Dairy',
        3187: 'Defense & Space',
        3227: 'Design',
        3208: 'E-Learning',
        3200: 'Education Management',
        3237: 'Entertainment',
        3196: 'Fine Art',
        3195: 'Food & Beverages',
        3224: 'Food Production',
        3193: 'Furniture',
        3232: 'Government Relations',
        3207: 'Health, Wellness and Fitness',
        3210: 'Human Resources',
        3209: 'Import and Export',
        3216: 'Industrial Automation',
        3231: 'Information Technology and Services',
        3194: 'Leisure, Travel & Tourism',
        3212: 'Luxury Goods and Jewelry',
        3236: 'Maritime',
        3221: 'Mechanical or Industrial Engineering',
        3217: 'Medical Devices',
        3205: 'Music',
        3202: 'Non-profit Organization Management',
        3214: 'Online Media',
        3206: 'Outsourcing/Offshoring',
        3223: 'Packaging and Containers',
        3239: 'Paper and Forest Products',
        3230: 'Philanthropy',
        3203: 'Program Development',
        3201: 'Public Policy',
        3213: 'Renewables & Environment',
        3233: 'Research',
        3218: 'Semiconductors',
        3225: 'Sporting Goods',
        3228: 'Tobacco',
        3222: 'Transportation/Trucking/Railroad',
        3188: 'Veterinary',
        3229: 'Warehousing',
        3220: 'Wine and Spirits',
    };
    return industryCodeToName[idNumber] || null;
};
