import { Box, CircularProgress, LinearProgress, linearProgressClasses, styled } from '@mui/material';

const BorderLinearProgress = styled(LinearProgress)(({ theme }: any) => ({
    height: 30,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.colors.greyInformationLight,
    },
}));

/* eslint-disable-next-line */
export interface ConnectionProgressProps {
    progress: number;
}

export function ConnectionProgress({ progress }: ConnectionProgressProps) {
    return (
        <Box
            sx={{
                marginTop: 'auto',
                maxHeight: 34,
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    fontSize: '14px',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: 1,
                    }}
                >
                    <CircularProgress size={20} />
                    <span>Connecting...</span>
                </Box>
                <span>{progress}%</span>
            </Box>
            <BorderLinearProgress
                variant='determinate'
                value={progress}
                sx={{
                    height: '10px',
                    borderRadius: '4px',
                }}
            />
        </Box>
    );
}

export default ConnectionProgress;
