'use client';

import { MoonIcon, SunIcon, UserRoundPlus } from 'lucide-react';
import { useTheme } from '@shadcn/ui/theme-provider';
import { SidebarMenu, SidebarMenuButton, SidebarMenuItem } from '@shadcn/ui/components/ui/sidebar';
import { Tooltip, TooltipContent, TooltipTrigger } from '@shadcn/ui/components/ui/tooltip';
import { useSuspenseQuery as useSuspenseQueryTan } from '@tanstack/react-query';
import { useReferFriendsDialog } from '../dialogs/refer-friends-dialog';
import { ContactLimitNavBarItem } from './contact-limit-card';
import { getUserContactLimit } from '../../sdk/internal/user-contacts.sdk';

export function NavFooter() {
    const { theme, setTheme } = useTheme();
    const { openReferFriendsDialog } = useReferFriendsDialog();
    let { data: limit } = useSuspenseQueryTan(getUserContactLimit());

    return (
        <SidebarMenu>
            <SidebarMenuItem>
                {limit !== 'none' ? (
                    <ContactLimitNavBarItem />
                ) : (
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <SidebarMenuButton variant="primary" onClick={openReferFriendsDialog}>
                                <UserRoundPlus />
                                <span>Refer a friend</span>
                            </SidebarMenuButton>
                        </TooltipTrigger>
                        <TooltipContent side="right">Refer a friend</TooltipContent>
                    </Tooltip>
                )}
                {(import.meta.env.MODE === 'staging' || import.meta.env.MODE === 'development') && (
                    <SidebarMenuButton
                        onClick={() => setTheme(theme === 'light' ? 'dark' : 'light')}
                        tooltip={theme === 'light' ? 'Dark mode' : 'Light mode'}
                    >
                        {theme === 'light' ? <MoonIcon /> : <SunIcon />}
                        <span>Toggle theme</span>
                    </SidebarMenuButton>
                )}
            </SidebarMenuItem>
        </SidebarMenu>
    );
}
