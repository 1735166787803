import { FC, useRef, useState, useEffect } from 'react';
import { SearchFilters } from './components/search-filters';
import { SearchResults } from './components/search-results';
import { SearchProvider, useSearchContext } from './context/search-context';
import { Button } from '@shadcn/ui/components/ui/button';
import { Table, TableBody, TableCell, TableHeader, TableRow } from '@shadcn/ui/components/ui/table';
import { TableHead } from '@shadcn/ui/components/ui/table';
import { Checkbox } from '@shadcn/ui/components/ui/checkbox';
import { ChevronLeft, ChevronRight, Sparkle, Sparkles } from 'lucide-react';
import { PageHeader } from '../../../components/page-header';
import { paths } from '../../../../routes/paths';
import { Link, useNavigate } from 'react-router-dom';
import { LinkedInMemberSidebarWrapper } from '../../../components/linkedin-profile-sidebar/linkedin-profile-sidebar';

export const SearchPage: FC = () => {
    const navigate = useNavigate();
    return (
        <SearchProvider>
            <div className="flex h-full max-w-full w-full overflow-hidden gap-2">
                <div className="flex flex-col h-full max-w-full w-full overflow-hidden">
                    <PageHeader
                        title="Search"
                        description="Extensive search across 500M+ contacts"
                        rightElement={
                            <Link to={paths.NEW.DISCOVER_PATHS.SUGGESTIONS}>
                                <Button variant="outline" id="discovery-button" className="hidden md:flex">
                                    <Sparkles className="w-4 h-4" />
                                    <span>Suggestions</span>
                                </Button>
                            </Link>
                        }
                    />
                    <div className="flex flex-col min-w-0 min-h-0 h-full max-h-full w-full overflow-hidden gap-4 p-2 py-4">
                        <SearchHeader />
                        <SearchContent />
                    </div>
                </div>
                <LinkedInMemberSidebarWrapper onClose={() => navigate(paths.NEW.DISCOVER_PATHS.SEARCH)} />
            </div>
        </SearchProvider>
    );
};

const SearchHeader: FC = () => {
    const { handleSearch } = useSearchContext();
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const [showLeftChevron, setShowLeftChevron] = useState(false);
    const [showRightChevron, setShowRightChevron] = useState(false);
    const [isHovering, setIsHovering] = useState(false);

    const checkForScrollPosition = () => {
        if (!scrollContainerRef.current) return;

        const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;

        // Show left chevron if we're not at the start
        setShowLeftChevron(scrollLeft > 0);

        // Show right chevron if we're not at the end
        setShowRightChevron(scrollLeft < scrollWidth - clientWidth - 1);
    };

    useEffect(() => {
        checkForScrollPosition();
        window.addEventListener('resize', checkForScrollPosition);

        return () => {
            window.removeEventListener('resize', checkForScrollPosition);
        };
    }, []);

    const scrollLeft = () => {
        if (!scrollContainerRef.current) return;
        scrollContainerRef.current.scrollBy({ left: -200, behavior: 'smooth' });
    };

    const scrollRight = () => {
        if (!scrollContainerRef.current) return;
        scrollContainerRef.current.scrollBy({ left: 200, behavior: 'smooth' });
    };

    return (
        <div className="flex flex-col gap-4 w-full">
            <div
                className="flex items-center gap-4 w-full relative"
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
            >
                {/* Left Chevron */}
                <button
                    onClick={scrollLeft}
                    className={`absolute left-0 z-10 h-8 w-8 flex items-center justify-center bg-white rounded-full shadow border transition-opacity ${
                        showLeftChevron && isHovering ? 'hover:opacity-100' : 'opacity-0 pointer-events-none'
                    }`}
                    aria-label="Scroll left"
                >
                    <ChevronLeft className="h-4 w-4" />
                </button>

                {/* Scrollable container */}
                <div className="flex-1 overflow-hidden relative">
                    {/* Left separator indicator */}
                    <div
                        className={`absolute left-0 top-0 bottom-0 w-10 z-[1] pointer-events-none bg-gradient-to-r from-white to-transparent transition-opacity ${
                            showLeftChevron ? 'opacity-100' : 'opacity-0'
                        }`}
                        aria-hidden="true"
                    />

                    <div
                        ref={scrollContainerRef}
                        className="overflow-x-auto w-full scrolling-touch [scrollbar-width:none] [-ms-overflow-style:none] [&::-webkit-scrollbar]:hidden"
                        onScroll={checkForScrollPosition}
                    >
                        <SearchFilters />
                    </div>

                    {/* Right separator indicator */}
                    <div
                        className={`absolute right-0 top-0 bottom-0 w-10 z-[1] pointer-events-none bg-gradient-to-l from-white to-transparent transition-opacity ${
                            showRightChevron ? 'opacity-100' : 'opacity-0'
                        }`}
                        aria-hidden="true"
                    />
                </div>

                {/* Right Chevron */}
                <button
                    onClick={scrollRight}
                    className={`absolute right-20 z-10 h-8 w-8 flex items-center justify-center bg-white rounded-full shadow border transition-opacity ${
                        showRightChevron && isHovering
                            ? 'opacity-80 hover:opacity-100'
                            : 'opacity-0 pointer-events-none'
                    }`}
                    aria-label="Scroll right"
                >
                    <ChevronRight className="h-4 w-4" />
                </button>

                <div className="flex-shrink-0">
                    <Button onClick={handleSearch}>Search</Button>
                </div>
            </div>
        </div>
    );
};

const SearchContent: FC = () => {
    const { isLoading, persons, hasSearched } = useSearchContext();

    if (isLoading) {
        return <LoadingState />;
    }

    if (!persons.length && hasSearched) {
        return (
            <div className="flex flex-col items-center justify-center text-center py-8">
                <NoResultsState />
            </div>
        );
    }

    if (!persons.length) {
        return (
            <div className="flex flex-col items-center justify-center text-center py-8">
                <EmptyFiltersState />
            </div>
        );
    }

    return <SearchResults />;
};

const LoadingState: FC = () => {
    return (
        <div className="flex flex-col gap-4 h-full overflow-hidden">
            <div className="rounded-md border overflow-hidden">
                <Table className="w-full caption-bottom text-sm">
                    <TableHeader>
                        <TableRow>
                            <TableHead className="w-[50px]">
                                <Checkbox className="mx-auto" />
                            </TableHead>
                            <TableHead>Prospect</TableHead>
                            <TableHead>Company</TableHead>
                            <TableHead>Owners</TableHead>
                            <TableHead className="w-[100px]">Actions</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody className="[&_tr:last-child]:border-0">
                        {Array.from({ length: 10 }).map((_, index) => (
                            <TableRow key={index} className="border-b">
                                <TableCell className="py-3">
                                    <Checkbox className="mx-auto" />
                                </TableCell>
                                <TableCell className="py-3">
                                    <div className="flex items-center gap-3">
                                        <div className="h-10 w-10 rounded-full bg-gray-200 animate-pulse"></div>
                                        <div className="space-y-2">
                                            <div className="h-4 w-32 bg-gray-200 rounded animate-pulse"></div>
                                            <div className="h-3 w-24 bg-gray-200 rounded animate-pulse"></div>
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell className="py-3">
                                    <div className="h-4 w-36 bg-gray-200 rounded animate-pulse"></div>
                                </TableCell>
                                <TableCell className="py-3">
                                    <div className="h-4 w-28 bg-gray-200 rounded animate-pulse"></div>
                                </TableCell>
                                <TableCell className="py-3">
                                    <div className="space-y-2">
                                        <div className="h-3 w-24 bg-gray-200 rounded animate-pulse"></div>
                                        <div className="h-3 w-20 bg-gray-200 rounded animate-pulse"></div>
                                    </div>
                                </TableCell>
                                <TableCell className="py-3">
                                    <div className="h-8 w-20 bg-gray-200 rounded animate-pulse"></div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </div>
    );
};

const EmptyFiltersState: FC = () => {
    return (
        <div className="w-full mx-auto py-14">
            <div className="text-center mb-8">
                <h2 className="text-3xl font-bold mb-3 bg-clip-text text-text-primary">
                    Discover Your Ideal Prospects
                </h2>
                <p className="text-text-secondary mx-auto">
                    Add a filter to start your search or use our quick filters to start narrowing down your search.
                </p>
            </div>
        </div>
    );
};

const NoResultsState: FC = () => {
    return (
        <div className="w-full max-w-3xl mx-auto py-">
            <div className="bg-white rounded-xl border shadow-sm overflow-hidden">
                <div className="p-6 text-center border-b">
                    <div className="w-20 h-20 mx-auto mb-4 relative">
                        <div className="absolute inset-0 bg-gray-100 rounded-full"></div>
                        <div className="absolute inset-0 flex items-center justify-center">
                            <svg
                                width="48"
                                height="48"
                                viewBox="0 0 48 48"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M24 4C12.96 4 4 12.96 4 24C4 35.04 12.96 44 24 44C35.04 44 44 35.04 44 24C44 12.96 35.04 4 24 4ZM24 40C15.18 40 8 32.82 8 24C8 15.18 15.18 8 24 8C32.82 8 40 15.18 40 24C40 32.82 32.82 40 24 40Z"
                                    fill="#E5E7EB"
                                />
                                <path
                                    d="M24 28C26.2091 28 28 26.2091 28 24C28 21.7909 26.2091 20 24 20C21.7909 20 20 21.7909 20 24C20 26.2091 21.7909 28 24 28Z"
                                    fill="#9CA3AF"
                                />
                                <path
                                    d="M24 34C29.5228 34 34 29.5228 34 24C34 18.4772 29.5228 14 24 14C18.4772 14 14 18.4772 14 24C14 29.5228 18.4772 34 24 34Z"
                                    stroke="#9CA3AF"
                                    strokeWidth="4"
                                    strokeLinecap="round"
                                    strokeDasharray="2 6"
                                />
                            </svg>
                        </div>
                    </div>

                    <h2 className="text-xl font-semibold mb-2">We couldn't find any matches</h2>
                    <p className="text-gray-600 max-w-md mx-auto mb-6">
                        Your current search criteria didn't return any results. Let's try a different approach.
                    </p>
                </div>

                <div className="p-6 bg-gray-50 flex flex-col items-center">
                    <div className="flex items-center gap-3">
                        <div>
                            <h3 className="font-medium mb-1">Search tips</h3>
                            <ul className="space-y-2 text-sm text-gray-600">
                                <li className="flex items-start gap-2">
                                    <span className="bg-gray-200 text-gray-700 rounded-full w-5 h-5 flex items-center justify-center text-xs flex-shrink-0 mt-0.5">
                                        1
                                    </span>
                                    <span>Try using broader terms or fewer filters</span>
                                </li>
                                <li className="flex items-start gap-2">
                                    <span className="bg-gray-200 text-gray-700 rounded-full w-5 h-5 flex items-center justify-center text-xs flex-shrink-0 mt-0.5">
                                        2
                                    </span>
                                    <span>Check for typos or alternative spellings</span>
                                </li>
                                <li className="flex items-start gap-2">
                                    <span className="bg-gray-200 text-gray-700 rounded-full w-5 h-5 flex items-center justify-center text-xs flex-shrink-0 mt-0.5">
                                        3
                                    </span>
                                    <span>Consider expanding your location range</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SearchPage;
