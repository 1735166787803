import { BaseSdk } from '../../../base.sdk';
import { CreateWebhookDto, TestFireWebhookDto, UpdateWebhookDto } from '@zaplify/webhooks';

export class WebhooksSdk extends BaseSdk {
    async getAllWebhooks(organizationId: string) {
        return this.get<any>(`${this.appsPath}/${this.webhooksPath}?organizationId=${organizationId}`);
    }

    async updateWebhook(userId: string, data: UpdateWebhookDto) {
        return this.put<any>(`${this.appsPath}/${this.webhooksPath}/${userId}`, data);
    }

    async createWebhook(data: CreateWebhookDto) {
        return this.post<any>({ path: `${this.appsPath}/${this.webhooksPath}`, payload: data });
    }

    async testFire(webhook: TestFireWebhookDto) {
        return this.post<any>({ path: `${this.appsPath}/${this.webhooksPath}/testFire`, payload: webhook });
    }

    async deleteWebhook(id: string) {
        return this.delete<any>(`${this.appsPath}/${this.webhooksPath}/${id}`);
    }

    async toggleWebhook(id: string) {
        return this.put<any>(`${this.appsPath}/${this.webhooksPath}/${id}/toggle`);
    }

    async getAvailableWebhookEvents() {
        return this.get<any>(`${this.appsPath}/${this.webhooksPath}/settings/events`);
    }
}
