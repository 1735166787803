import { Box, ClickAwayListener, ClickAwayListenerProps, Snackbar } from '@mui/material';
import { styled } from '@mui/styles';
import { ErrorIcon } from '../../components/icons/error-icon';
import { WarningIcon } from '../../components/icons/warning-icon';
import parse from 'html-react-parser';
import { CheckCircle, X } from 'lucide-react';
import { NotificationAction } from '../../hooks/use-notifications';
import { Link } from 'react-router-dom';

const AVERAGE_READ_SPEED = 20;
const PREVENT_AUTO_HIDE = null;

const alertIcons = {
    success: <CheckCircle strokeWidth={1} size={20} color="#079455" />,
    error: <ErrorIcon />,
    info: <WarningIcon color="#5F8EFA" />,
    warning: <WarningIcon color="#E9A847" />,
};

export const SnackbarContainer = styled('div')(({ theme }) => ({
    '& .snackbar-container': {
        display: 'flex',
        padding: 'var(--spacing-xl, 16px)',
        alignItems: 'flex-start',
        gap: 'var(--spacing-xl, 16px)',
        alignSelf: 'stretch',
        borderRadius: 'var(--radius-xl, 12px)',
        border: '1px solid var(--Colors-Border-border-primary, #D0D5DD)',
        background: 'var(--Colors-Background-bg-primary_alt, #FFF)',
        boxShadow: '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
        '& .close-icon': {
            display: 'flex',
            // width: "36px",
            // height: "36px",
            padding: 'var(--spacing-md, 8px)',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            right: '8px',
            top: '8px',
            borderRadius: 'var(--radius-md, 8px)',
            cursor: 'pointer',
        },
        '& .content': {
            display: 'flex',
            paddingRight: 'var(--spacing-4xl, 32px)',
            alignItems: 'flex-start',
            gap: 'var(--spacing-xl, 16px)',
            flex: '1 0 0',
            '& .text-content': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: 'var(--spacing-xs, 4px)',
                alignSelf: 'stretch',
                '& .main-text': {
                    alignSelf: 'stretch',
                    color: 'var(--colors-foreground-fg-primary-900, #101828)',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '20px /* 142.857% */',
                    maxWidth: '30vw',
                },
                '& .sub-text': {
                    alignSelf: 'stretch',
                    color: 'var(--colors-foreground-fg-secondary-700, #344054)',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '20px /* 142.857% */',
                    maxWidth: '30vw',
                },
            },
            '& .featured-icon': {
                width: '20px',
                height: '20px',
                borderRadius: 'var(--radius-full, 9999px)',
                '& .outline-inner': {
                    width: '28px',
                    height: '28px',
                    flexShrink: 0,
                    borderRadius: 'var(--spacing-3xl, 24px)',
                    border: '2px solid var(--Colors-Foreground-fg-success-primary, #079455)',
                    opacity: 0.3,
                },
                '& .outline-outer': {
                    width: '38px',
                    height: '38px',
                    flexShrink: 0,
                    borderRadius: 'var(--spacing-3xl, 24px)',
                    border: '2px solid var(--Colors-Foreground-fg-success-primary, #079455)',
                    opacity: 0.1,
                },
            },
        },
        '& .action': {
            marginTop: '8px',
            '& :hover': {
                cursor: 'pointer',
            },
            '& *': {
                textDecoration: 'none',
                fontWeight: 600,
                fontSize: '14px',
                color: '#475467',
            },
        },
    },
    '& .success': {
        background: theme.palette.colors.greenLight,
    },
    '& .error': {
        background: theme.palette.colors.redLight,
    },
    '& .info': {
        background: theme.palette.colors.blueLight,
    },
    '& .warning': {
        background: theme.palette.colors.orangeWhite,
    },
}));

type Props = {
    onClose: () => any;
    severity: any;
    text: string;
    subText?: string;
    horizontal?: any;
    vertical?: any;
    open?: boolean;
    duration?: number;
    action?: NotificationAction;
    clickAwayListenerProps?: Partial<ClickAwayListenerProps>;
};

export const Notification = ({
    onClose,
    severity,
    text,
    subText,
    horizontal,
    vertical,
    action,
    open = true,
    duration,
    clickAwayListenerProps,
}: Props) => {
    function getAutoHideDuration(textLength) {
        if (duration) {
            return duration;
        }
        const minDuration = 6000;
        const textAverageReadTime = textLength * AVERAGE_READ_SPEED;

        if (textAverageReadTime < minDuration) {
            return minDuration;
        }

        if (textAverageReadTime > minDuration && textAverageReadTime < 5000) {
            return textAverageReadTime;
        }

        if (textAverageReadTime > 5000) {
            return PREVENT_AUTO_HIDE;
        }
    }

    return (
        <SnackbarContainer>
            <Snackbar
                className={`snackbar-container ${severity}`}
                anchorOrigin={{ vertical: vertical || 'top', horizontal: horizontal || 'right' }}
                open={open}
                autoHideDuration={getAutoHideDuration(text.length)}
                onClose={onClose}
                sx={{
                    zIndex: 9999,
                }}
                ClickAwayListenerProps={{ ...clickAwayListenerProps }}
            >
                <Box>
                    <Box className="close-icon" onClick={onClose}>
                        <X strokeWidth={1} size={20} />
                    </Box>
                    <Box className="content">
                        <Box className="featured-icon">{alertIcons[severity]}</Box>
                        <Box className="text-content">
                            <Box className="main-text">{parse(text)}</Box>
                            {subText && <Box className="sub-text">{parse(subText)}</Box>}
                            {action && (
                                <Box className="action">
                                    {action.type === 'button' && (
                                        <Box
                                            className="button-action"
                                            onClick={() => {
                                                action.onClick();
                                                onClose();
                                            }}
                                        >
                                            {action.label}
                                        </Box>
                                    )}
                                    {action.type === 'link' && (
                                        <Link
                                            className="link-action"
                                            to={action.url}
                                            onClick={onClose}
                                            target={action.external ? '_blank' : '_self'}
                                        >
                                            {action.label}
                                        </Link>
                                    )}
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Snackbar>
        </SnackbarContainer>
    );
};
