import { atomWithReset, atomWithStorage } from 'jotai/utils';
import { PendingMessage } from '../types';
import { OnMessageAddedByProspectIdSubscription } from '@zaplify/graphql';
import { MessageComposer } from '../views/chats/types';
import { LocalStorageKeys } from '../config';
import { MessageType, OutreachSuggestionDto } from '@zaplify/services/messaging/shared';
import { IProspectToSync } from '@zaplify/campaigns';
import { EmailAtomData, LinkedinAtomData } from '../views/chats/hooks/use-channel-account-data';
import { ScoredOutreachSuggestionDto } from '../hooks/outreach-suggestion-scoring';

export const displayProspectsPanelAtom = atomWithStorage<boolean | null>(
    LocalStorageKeys.TOGGLE_SIDEBAR_PANEL,
    null,
    undefined,
    { getOnInit: true }
);
displayProspectsPanelAtom.debugLabel = 'displayProspectsPanelAtom';

export const pendingMessagesAtom = atomWithReset<Record<string, PendingMessage | null>>({});
pendingMessagesAtom.debugLabel = 'pendingMessage';

export const pingTrackersStatusAtom = atomWithReset<{
    status: 'active' | 'paused';
    timestamp: number | null;
}>({
    status: 'active',
    timestamp: null,
});
pingTrackersStatusAtom.debugLabel = 'pingTrackersStatus';

export const messageComposerStateAtom = atomWithReset<MessageComposer>({
    bodyText: '',
    subjectLine: '',
    channelType: MessageType.linkedinMessage,
    allSubjectLines: [],
});
messageComposerStateAtom.debugLabel = 'messageComposerState';

export const prospectIdToIsGeneratingMessageAtom = atomWithReset<Map<string, boolean>>(new Map());
prospectIdToIsGeneratingMessageAtom.debugLabel = 'prospectIdToIsGeneratingMessage';

export const outreachSuggestionsLoadingAtom = atomWithReset<boolean>(true);
export const outreachSuggestionsAtom = atomWithReset<ScoredOutreachSuggestionDto[]>([]);
outreachSuggestionsAtom.debugLabel = 'outreachSuggestions';

export const completedOutreachSuggestionsLoadingAtom = atomWithReset<boolean>(true);
export const completedOutreachSuggestionsAtom = atomWithReset<OutreachSuggestionDto[]>([]);

export const messagesLoadingAtom = atomWithReset<boolean>(true);
export const messagesAtom = atomWithReset<OnMessageAddedByProspectIdSubscription['Messages']>([]);
messagesAtom.debugLabel = 'messages';

export const channelAccountsAtom = atomWithReset<{
    email: EmailAtomData;
    linkedIn: LinkedinAtomData;
    hasFetched: boolean;
}>({
    email: {
        id: null,
        value: null,
        status: 'LOADING',
    },
    linkedIn: {
        id: null,
        value: null,
        status: 'LOADING',
        isPremium: false,
        avatarUrl: null,
    },
    hasFetched: false,
});
channelAccountsAtom.debugLabel = 'channelAccounts';

export const selectedMessageTypeAtom = atomWithReset<MessageType.emailMessage | MessageType.linkedinMessage>(
    MessageType.linkedinMessage
);
selectedMessageTypeAtom.debugLabel = 'selectedMessageType';

export type ExtensionError = 'not_installed' | 'not_logged_in' | 'error';
export const extensionErrorAtom = atomWithReset<ExtensionError | null>(null);
extensionErrorAtom.debugLabel = 'extensionError';

export const loadingAISettingsAtom = atomWithReset<boolean>(false);

export const prospectSyncInProgressAtom = atomWithReset<boolean>(false);
export const failedProspectSyncJobsAtom = atomWithReset<IProspectToSync[]>([]);
export const declinedOutreachSuggestionRedirectsAtom = atomWithReset<string[]>([]);
export const sidebarPinStateAtom = atomWithStorage<'pinned' | 'unpinned'>(LocalStorageKeys.SIDEBAR_PIN_STATE, 'pinned');
