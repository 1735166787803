import { FC } from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@shadcn/ui/components/ui/card';
import { Button } from '@shadcn/ui/components/ui/button';
import { CreditCard, MapPin } from 'lucide-react';
import { SubscriptionDetailsDto } from '@zaplify/subscriptions';
import { ChargebeePortalSection } from '../../../../../enums/chargebee';
import { CreditCardUi } from '@shadcn/ui/components/credit-card-ui';
interface BillingDetailsProps {
    subscription: SubscriptionDetailsDto;
    onLoadSelfServicePortal: (section?: ChargebeePortalSection, callback?: () => void) => Promise<void>;
    refetchSubscription: () => void;
}

export const BillingDetails: FC<BillingDetailsProps> = ({
    subscription,
    onLoadSelfServicePortal,
    refetchSubscription,
}) => {
    const getCardData = () => {
        return {
            ...subscription.customer?.card,
            holder: subscription.customer?.address?.firstName + ' ' + subscription.customer?.address?.lastName,
        };
    };
    return (
        <Card>
            <CardHeader>
                <CardTitle>Billing Details</CardTitle>
                <CardDescription>Manage your billing information and payment methods</CardDescription>
            </CardHeader>
            <CardContent>
                <div className="flex flex-col gap-4">
                    <div className="flex items-center justify-between">
                        <div className="flex flex-col gap-1">
                            <span className="text-sm text-text-tertiary">Payment Method</span>
                            <div className="flex items-center gap-2">
                                <span className="font-medium">
                                    {subscription.customer?.card?.last4 ? (
                                        <CreditCardUi {...getCardData()} />
                                    ) : (
                                        'No payment method'
                                    )}
                                </span>
                            </div>
                        </div>
                        <Button
                            variant="outline"
                            onClick={() =>
                                onLoadSelfServicePortal(ChargebeePortalSection.PAYMENT_SOURCES, () => {
                                    refetchSubscription();
                                })
                            }
                        >
                            Update Payment Method
                        </Button>
                    </div>
                    <div className="flex items-center justify-between">
                        <div className="flex flex-col gap-1">
                            <span className="text-sm text-text-tertiary">Billing Address</span>
                            <div className="flex items-center gap-2">
                                <MapPin className="w-4 h-4" />
                                <span className="font-medium">
                                    {subscription.customer?.address &&
                                    Object.keys(subscription.customer.address).length > 0 ? (
                                        <>
                                            {subscription.customer.address.line1}
                                            {subscription.customer.address.line2 &&
                                                subscription.customer.address.line2.trim() &&
                                                `, ${subscription.customer.address.line2}`}
                                            {(subscription.customer.address.city ||
                                                subscription.customer.address.state ||
                                                subscription.customer.address.zip) && <br />}
                                            {subscription.customer.address.city && subscription.customer.address.city}
                                            {subscription.customer.address.state &&
                                                `${subscription.customer.address.city ? ', ' : ''}${
                                                    subscription.customer.address.state
                                                }`}
                                            {subscription.customer.address.zip &&
                                                ` ${subscription.customer.address.zip}`}
                                            {subscription.customer.address.country && (
                                                <>
                                                    <br />
                                                    {subscription.customer.address.country}
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        'No billing address'
                                    )}
                                </span>
                            </div>
                        </div>
                        <Button
                            variant="outline"
                            onClick={() =>
                                onLoadSelfServicePortal(ChargebeePortalSection.ADDRESS, () => {
                                    refetchSubscription();
                                })
                            }
                        >
                            Update Billing Address
                        </Button>
                    </div>
                    <div className="flex items-center justify-between">
                        <Button
                            variant="outline"
                            onClick={() => onLoadSelfServicePortal(ChargebeePortalSection.BILLING_HISTORY)}
                        >
                            View all invoices
                        </Button>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};
