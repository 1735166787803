import { IEvent, Topics } from '@zaplify/common';

export class OrganizationNameUpdatedEvent extends IEvent {
    public static override readonly TOPIC = Topics.USERS;

    constructor(readonly userOrganizationId: string, readonly name: string) {
        super();
    }

    get partitionKey(): string {
        return this.userOrganizationId;
    }
}
