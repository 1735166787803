import React from 'react';
import LookingForSomethingStyled from './LookingForSomething.styled';

const LookingForSomethingV2 = () => (
    <LookingForSomethingStyled>
        <div className="header">
            <span>Looking for something?</span>
            <span role="img" aria-label="eyes emoji">
                👀
            </span>
        </div>
        <div className="info">
            Search to find people, contacts, and companies. Try our AI company search by typing, for example,
            <i>"Boat manufacturing companies in France"</i>.
        </div>
    </LookingForSomethingStyled>
);

export default LookingForSomethingV2;
