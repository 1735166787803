import { getZaplifySdk } from '@zaplify/sdk';
import {
    updateAvailableFields,
    updateFieldMapping,
    updateHubspotActiveState,
    updateHubspotCustomSwitches,
    updateSaveStatus,
    updateSettings,
} from './slice';
import { handleErrorNotification, setNotification, updateIntegrations } from '../../../redux/actions';
import { getSpecialHubspotMapping } from './helpers';

export const getHubspotSettings = () => async (dispatch, getState) => {
    const profilesSdk = getZaplifySdk().profiles;
    const user = getState().user.zaplifyUser;
    try {
        let hubspotSettings: any = await profilesSdk.hubspot.getSettings(user.userOrganization.id);
        if (hubspotSettings) {
            dispatch(updateSettings(hubspotSettings));
            dispatch(updateHubspotSaveStatus());
        }
    } catch (error) {
        console.log('error fetching hubspot settings');
        dispatch(
            updateSettings({
                account: {},
                integrationScope: {},
                fieldMapping: [],
                isActive: false,
                availableFields: {},
                firstTime: false,
            })
        );
    } finally {
    }
};

export const getHubspotFields = () => async (dispatch, getState) => {
    const profilesSdk = getZaplifySdk().profiles;
    const user = getState().user.zaplifyUser;
    try {
        const fields = await profilesSdk.hubspot.getAllFields(user.userOrganization.id);
        dispatch(updateAvailableFields(fields));
    } catch (error) {
        console.log('error loading fields');
    } finally {
    }
};

export const updateHubspotSettings = () => async (dispatch, getState) => {
    const profilesSdk = getZaplifySdk().profiles;
    const user = getState().user.zaplifyUser;
    const hubspot = getState().hubspot;
    try {
        const fieldMapping = [...hubspot.fieldMapping];

        const newFields = getSpecialHubspotMapping(hubspot, fieldMapping);
        const newFieldMapping = [...fieldMapping, ...newFields];

        const updateSettingsObj = {
            organizationId: user.userOrganization.id,
            fieldMapping: newFieldMapping,
            integrationScope: hubspot.integrationScope,
            isActive: hubspot.isActive,
        };
        const errorCallback = (error) => {
            throw new Error(error);
        };

        await profilesSdk.hubspot.updateSettings(updateSettingsObj, errorCallback);

        const newSettings: any = await profilesSdk.hubspot.getSettings(user.userOrganization.id);

        if (hubspot.firstTime) {
            await dispatch(resumeOrPauseHubspot(true));
            dispatch(setNotification('Successfully started your hubspot integration', 'success'));
        } else {
            dispatch(setNotification('Successfully updated your hubspot settings', 'success'));
        }
        dispatch(updateFieldMapping(newSettings.fieldMapping));
        dispatch(updateHubspotSaveStatus());
        dispatch(updateHubspotCustomSwitches(false));
        dispatch(getHubspotFields());
        window?.analytics?.track('User Updated Integration', {
            type: 'Hubspot',
        });
    } catch (error: any) {
        if (error.message) {
            const parseError = JSON.parse(error.message);
            dispatch(handleErrorNotification(parseError.data.message, 2000));
        } else {
            dispatch(setNotification("Couldn't update your hubspot settings", 'error'));
        }
        console.log('error loading fields');
    } finally {
        dispatch(updateIntegrations());
    }
};

export const resumeOrPauseHubspot = (value) => async (dispatch, getState) => {
    const profilesSdk = getZaplifySdk().profiles;
    const user = getState().user.zaplifyUser;
    try {
        if (value) await profilesSdk.hubspot.resume(user.userOrganization.id);
        else await profilesSdk.hubspot.pause(user.userOrganization.id);
        await dispatch(updateHubspotActiveState(value));
    } catch (error) {
        console.log('error resume or pause');
    } finally {
        dispatch(updateIntegrations());
    }
};

export const updateHubspotSaveStatus = () => async (dispatch, getState) => {
    try {
        const hubspot = getState().hubspot;
        const fieldMapping = hubspot.fieldMapping;

        let saveStatus = true;

        fieldMapping.forEach((field) => {
            //Check source
            if (field.prospectField === '' || field.contactField === '') {
                saveStatus = false;
            }
        });
        dispatch(updateSaveStatus(saveStatus));
    } catch (error) {
        console.log('error loading fields');
    } finally {
    }
};

export const disconnectHubspot = () => async (dispatch, getState) => {
    const profilesSdk = getZaplifySdk().profiles;
    const user = getState().user.zaplifyUser;
    try {
        await profilesSdk.hubspot.disconnect(user.userOrganization.id);

        //Resetting hubspot state to initial
        dispatch(
            updateSettings({
                account: {},
                integrationScope: {},
                fieldMapping: [],
                isActive: false,
                availableFields: {},
                firstTime: false,
            })
        );

        dispatch(setNotification('Successfully disconnected your hubspot integration', 'success'));
    } catch (error) {
        console.log('error resume or pause');
    } finally {
        dispatch(updateIntegrations());
    }
};
