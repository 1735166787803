import React, { createContext, useContext, useMemo } from 'react';
import { ChannelAccountDto, ChannelAccountStatus } from '@zaplify/channel-accounts/shared';
import { parseEmailAccountStatus } from '../functions/parse-emal-account';
import { useQuery } from '@tanstack/react-query';
import { useSdk } from '../sdk';
type EmailReader = {
    emailAccount: ChannelAccountDto | null;
    emailProvider: 'GMAIL' | 'OUTLOOK' | null;
    emailAddress: string | null;
    emailStatus: ChannelAccountStatus | null;
};

const EmailContext = createContext<EmailReader | undefined>(undefined);

export function EmailProvider({ children }: { children: React.ReactNode }) {
    const {
        channelAccount: { getMyChannelAccounts },
    } = useSdk();
    const { data: channelAccounts, isLoading: channelAccountsDataLoading } = useQuery(getMyChannelAccounts());

    const emailAccount = useMemo(
        () => channelAccounts?.find((account) => account?.GMAIL?.emailAddress || account?.OUTLOOK?.emailAddress),
        [channelAccounts]
    );
    const emailProvider = useMemo(() => (emailAccount?.GMAIL?.emailAddress ? 'GMAIL' : 'OUTLOOK'), [emailAccount]);
    const emailAddress = useMemo(
        () => emailAccount?.GMAIL?.emailAddress || emailAccount?.OUTLOOK?.emailAddress,
        [emailAccount]
    );
    const emailStatus = useMemo(
        () => (emailAccount ? parseEmailAccountStatus(emailAccount).status : null),
        [emailAccount]
    );

    return useMemo(
        () => (
            <EmailContext.Provider
                value={{
                    emailAccount,
                    emailProvider,
                    emailAddress,
                    emailStatus,
                }}
            >
                {children}
            </EmailContext.Provider>
        ),
        [emailAccount, emailProvider, emailAddress, emailStatus]
    );
}

export function useEmail() {
    const context = useContext(EmailContext);
    if (context === undefined) {
        throw new Error('useEmail must be used within a EmailProvider');
    }
    return context;
}
