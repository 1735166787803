import { checkWhereInTime } from '@zaplify/frontend-common';
import { WhereInTime } from '@zaplify/campaigns';
import { CampaignStatus } from '@zaplify/campaigns';

export function getManualTasks(campaign) {
    if (!Array.isArray(campaign.tasks) || !campaign.workflow.isManual || !(campaign.status === CampaignStatus.ongoing))
        return null;
    let due = 0;
    let overdue = 0;
    const nowISO = new Date().toISOString();
    campaign.tasks.forEach((task) => {
        const whereInTime = task?.dueFrom && task?.dueTo && checkWhereInTime(nowISO, task.dueFrom, task.dueTo);
        if (whereInTime === WhereInTime.AFTER) overdue++;
        else if (whereInTime === WhereInTime.BETWEEN) due++;
    });
    return {
        due: due,
        overdue: overdue,
    };
}
