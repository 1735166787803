import React from 'react';
import { SelectPlaceholderContainer } from './containers';

export const PlaceHolder = ({ children }) => {
    return (
        <SelectPlaceholderContainer>
            <div className="placeholder-item">{children}</div>
        </SelectPlaceholderContainer>
    );
};
