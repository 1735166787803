import { IUntypedCommand, Topics } from '@zaplify/common';

export class UpdateChannelAccountCookieCommand extends IUntypedCommand {
    public static override readonly TOPIC = Topics.CHANNEL_ACCOUNTS;
    constructor(
        readonly channelAccountId: string,
        readonly sessionCookie: string,
        readonly linkedinUserId: string,
        readonly log?: string,
    ) {
        super();
    }
}
