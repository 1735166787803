import * as React from 'react';
import {
    ColumnDef,
    ColumnFiltersState,
    PaginationState,
    SortingState,
    VisibilityState,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
    Table as ReactTable,
} from '@tanstack/react-table';
import { ChevronDown, MoreHorizontal } from 'lucide-react';

import { Button } from '@shadcn/ui/components/ui/button';
import { Checkbox } from '@shadcn/ui/components/ui/checkbox';
import {
    DropdownMenu,
    DropdownMenuCheckboxItem,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from '@shadcn/ui/components/ui/dropdown-menu';
import {
    Pagination,
    PaginationContent,
    PaginationEllipsis,
    PaginationItem,
    PaginationLink,
    PaginationNext,
    PaginationPrevious,
} from '@shadcn/ui/components/ui/pagination';
import { Skeleton } from '@shadcn/ui/components/ui/skeleton';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@shadcn/ui/components/ui/table';
import {
    GET_ALL_USER_CONTACTS_IN_ANY_PLAYBOOK,
    GET_ACTIVE_OUTREACH_SUGGESTION_BY_PROSPECT_IDS,
    GET_USER_CONTACTS_BY_GROUP_IDS,
    GetAllUserContactsQuery,
} from '@zaplify/graphql';
import { useQuery as useApolloQuery } from '@apollo/client';
import { PlaybookBadge } from '../../../components/playbook-badge';
import { Suspense, useCallback, useMemo, useRef, useState, useEffect } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { cn } from '@shadcn/ui/lib/utils';
import { AccountLogo } from '../../../components/account-logo';
import { ContactAvatar } from '../../../components/contact-avatar';
import { ProspectDataDto, ProspectDataWithId } from '@zaplify/prospects';
import { useQueries, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSdk } from '../../../sdk';
import { ContactsTableToolBar } from './contacts-table-toolbar';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@shadcn/ui/components/ui/select';

import { useAuth } from '../../../providers/authentication-provider';
import { useAccountDetailsDialog } from '../../../components/dialogs/account-details-dialog';
import { TextSearchHighlighted } from '../../../components/text-search-highlighted';
import { paths } from '../../../../routes/paths';
import { useActions } from '../../../hooks/use-actions';

type UserContact = GetAllUserContactsQuery['UserContacts'][number];
const PROSPECT_INCLUDE_FIELDS = [
    'fullName',
    'organizationName',
    'location',
    'occupationTitle',
    'organizationId',
    'organizationDomain',
    'organizationIndustry',
    'linkedinProfileImgUrl',
    'organizationSize',
    'industry',
] as const;
type ProspectFields = (typeof PROSPECT_INCLUDE_FIELDS)[number];

type RowProps = {
    contact: UserContact;
    prospect: ProspectDataWithId<ProspectFields>;
};
export type ContactsTable = ReactTable<RowProps>;

const ContactCell = ({ row, filterValue }: { row: RowProps; filterValue?: string }) => {
    const { prospectId } = useParams();
    const isSelected = prospectId === row.prospect.prospectId;
    return (
        <Link
            to={isSelected ? '.' : `./${row.prospect.prospectId}`}
            className={cn('flex items-center gap-2', isSelected && 'font-semibold')}
            state={{ from: location.pathname }}
        >
            <ContactAvatar avatarUrl={row.prospect.linkedinProfileImgUrl} fullName={row.prospect.fullName} />
            <TextSearchHighlighted text={row.prospect.fullName} value={filterValue || ''} />
        </Link>
    );
};

export const createColumns = (
    onShowAccount: (accountId: string) => void,
    onCreateActionNow: (prospectId: string) => void
): ColumnDef<RowProps>[] => [
    {
        id: 'select',
        header: ({ table }) => (
            <div className="px-4">
                <Checkbox
                    checked={
                        table.getIsAllPageRowsSelected()
                            ? true
                            : table.getIsSomePageRowsSelected()
                            ? 'indeterminate'
                            : false
                    }
                    onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
                    aria-label="Select all"
                />
            </div>
        ),
        cell: ({ row }) => (
            <div className="px-4">
                <Checkbox
                    checked={row.getIsSelected()}
                    onCheckedChange={(value) => row.toggleSelected(!!value)}
                    aria-label="Select row"
                />
            </div>
        ),
        enableSorting: false,
        enableHiding: false,
    },
    {
        id: 'contact',
        header: 'Contact',
        accessorFn: (row) => row.prospect.fullName,
        cell: ({ row, table }) => {
            const filterValue = table.getColumn('contact')?.getFilterValue() as string;
            return <ContactCell row={row.original} filterValue={filterValue} />;
        },
    },
    {
        id: 'jobTitle',
        header: 'Job title',
        accessorFn: (row) => row.prospect.occupationTitle,
        cell: ({ row, table }) => {
            const filterValue = table.getColumn('contact')?.getFilterValue() as string;
            return (
                <div>
                    <TextSearchHighlighted text={row.original.prospect.occupationTitle} value={filterValue || ''} />
                </div>
            );
        },
    },
    {
        id: 'company',
        header: 'Company',
        accessorFn: (row) => row.prospect.organizationName,
        cell: ({ row, table }) => {
            const filterValue = table.getColumn('contact')?.getFilterValue() as string;
            return (
                <div className="flex items-center gap-2">
                    <Button
                        variant="link"
                        className={cn(
                            'font-normal px-0',
                            !row.original.prospect.organizationId && 'hover:cursor-default hover:no-underline'
                        )}
                        onClick={() =>
                            row.original.prospect.organizationId
                                ? onShowAccount(row.original.prospect.organizationId)
                                : null
                        }
                    >
                        <AccountLogo accountWebsite={row.original.prospect.organizationDomain} className="w-8 h-8" />
                        <TextSearchHighlighted
                            text={row.original.prospect.organizationName}
                            value={filterValue || ''}
                            className="truncate max-w-48"
                        />
                    </Button>
                </div>
            );
        },
    },
    {
        id: 'playbook',
        header: 'Playbook',
        accessorFn: (row) => row.contact._GroupToUserContacts?.[0]?.Group?.name,
        cell: ({ row }) => (
            <Suspense
                fallback={
                    <div className="flex items-center gap-2">
                        {row.original.contact._GroupToUserContacts?.[0]?.Group?.imgSrc && (
                            <img
                                src={row.original.contact._GroupToUserContacts?.[0]?.Group?.imgSrc}
                                alt={row.original.contact._GroupToUserContacts?.[0]?.Group?.name}
                                className="h-6 w-6 rounded-full"
                            />
                        )}
                        <span>{row.original.contact._GroupToUserContacts?.[0]?.Group?.name}</span>
                    </div>
                }
            >
                <PlaybookBadge
                    prospectId={row.original.prospect.prospectId}
                    responsive={false}
                    size="large"
                    className="min-w-[170px]"
                />
            </Suspense>
        ),
    },
    {
        id: 'industry',
        header: 'Industry',
        // You might need to add this field to your GraphQL query if not already present
        accessorFn: (row) => row.prospect.organizationIndustry || row.prospect.industry,
        cell: ({ row }) => <div>{row.original.prospect.organizationIndustry || row.original.prospect.industry}</div>,
    },
    {
        id: 'companySize',
        header: 'Company size',
        accessorFn: (row) => row.prospect.organizationSize,
        cell: ({ row }) => <div>{row.original.prospect.organizationSize?.replace(/[^\d- ]/g, '')}</div>,
    },
    {
        id: 'addedOn',
        header: 'Added On',
        enableHiding: true,
        accessorFn: (row) => row.contact.createdAt,
        cell: ({ row }) => {
            const createdAt = new Date(row.original.contact.createdAt);
            return <div>{createdAt.toLocaleDateString()}</div>;
        },
    },
    {
        id: 'location',
        header: 'Location',
        accessorFn: (row) => row.prospect.location,
        cell: ({ row, table }) => {
            return <div>{row.original.prospect.location}</div>;
        },
    },
    {
        id: 'actions',
        enableHiding: false,
        cell: ({ row }) => {
            const payment = row.original;

            return (
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button variant="ghost" className="h-8 w-8 p-0">
                            <span className="sr-only">Open menu</span>
                            <MoreHorizontal className="h-4 w-4" />
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                        <DropdownMenuLabel>Actions</DropdownMenuLabel>
                        <DropdownMenuItem asChild>
                            <Link to={`./${row.original.prospect.prospectId}`}>View Details</Link>
                        </DropdownMenuItem>
                        <DropdownMenuItem
                            disabled={!row.original.prospect.organizationId}
                            onClick={() => onShowAccount(row.original.prospect.organizationId)}
                        >
                            View Account
                        </DropdownMenuItem>
                        <DropdownMenuSeparator />
                        <DropdownMenuItem asChild>
                            <Link to={`${paths.NEW.MESSAGES}/${row.original.prospect.prospectId}`}>
                                Go to Conversation
                            </Link>
                        </DropdownMenuItem>
                        <DropdownMenuItem
                            onClick={() => {
                                onCreateActionNow(row.original.prospect.prospectId);
                            }}
                        >
                            Create Action Now
                        </DropdownMenuItem>
                    </DropdownMenuContent>
                </DropdownMenu>
            );
        },
    },
];

export const ContactsTable = () => {
    const [sorting, setSorting] = React.useState<SortingState>([]);
    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
    const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({
        addedOn: false,
    });
    const [rowSelection, setRowSelection] = React.useState({});
    const fetchIdRef = useRef(0);
    const { openAccountDetailsDialog } = useAccountDetailsDialog();
    const { handleSnooze } = useActions();
    const { prospectId } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const searchParamGroupId = searchParams.get('groupId');
    // const [selectedGroupIds, setSelectedGroupIds] = React.useState<string[]>([]);
    const [pagination, setPagination] = React.useState<PaginationState>({
        pageIndex: 0,
        pageSize: 25,
    });
    const {
        authState: { user },
    } = useAuth();
    const { loading: isContactsLoading, data: contactsData } = useApolloQuery(GET_ALL_USER_CONTACTS_IN_ANY_PLAYBOOK, {
        fetchPolicy: 'network-only',
    });
    const { loading: isContactsLoadingByGroupIds, data: contactsDataByGroupIds } = useApolloQuery(
        GET_USER_CONTACTS_BY_GROUP_IDS,
        { skip: !searchParamGroupId, variables: { groupIds: [searchParamGroupId] }, fetchPolicy: 'network-only' }
    );

    const data = useMemo(
        () => (searchParamGroupId ? contactsDataByGroupIds : contactsData),
        [contactsData, contactsDataByGroupIds, searchParamGroupId]
    );
    const {
        prospect: { findProspectData },
    } = useSdk();

    const startRowIndex = pagination.pageIndex * pagination.pageSize;
    const allProspectIds = useMemo(() => data?.UserContacts.map((contact) => contact.prospectId) || [], [data]);
    const currentPageContacts = useMemo(
        () => data?.UserContacts.slice(startRowIndex, startRowIndex + pagination.pageSize) || [],
        [data, pagination.pageIndex, pagination.pageSize]
    );
    const prospectIds = useMemo(
        () => currentPageContacts.map((contact) => contact.prospectId) || [],
        [currentPageContacts]
    );
    const { loading: outreachSuggestionsLoading, data: outreachSuggestionsData } = useApolloQuery(
        GET_ACTIVE_OUTREACH_SUGGESTION_BY_PROSPECT_IDS,
        {
            skip: !prospectIds?.length,
            variables: {
                prospectIds,
            },
        }
    );

    const onShowActionNow = useMemo(
        () => async (prospectId: string) => {
            const outreachSuggestion = outreachSuggestionsData?.OutreachSuggestions?.find(
                (suggestion) => suggestion.prospectId === prospectId
            );
            if (!outreachSuggestion) {
                return;
            }
            await handleSnooze(prospectId, 0);
        },
        [outreachSuggestionsData?.OutreachSuggestions, outreachSuggestionsLoading, handleSnooze]
    );

    const columns = useMemo(() => createColumns(openAccountDetailsDialog, onShowActionNow), []);
    const [rows, setRows] = useState<RowProps[]>([]);
    const table = useReactTable({
        data: rows,
        pageCount: Math.ceil((data?.UserContacts.length || 0) / pagination.pageSize),
        columns,
        initialState: {
            pagination,
            sorting,
            columnFilters,
            columnVisibility,
            rowSelection,
        },
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        getCoreRowModel: getCoreRowModel(),
        // getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        // getFilteredRowModel: getFilteredRowModel(),
        onColumnVisibilityChange: setColumnVisibility,
        onRowSelectionChange: setRowSelection,
        onPaginationChange: setPagination,
        manualPagination: true,
        debugTable: false,
        state: {
            pagination,
            sorting,
            columnFilters,
            columnVisibility,
            rowSelection,
        },
    });

    const filterValue = table.getColumn('contact')?.getFilterValue() as string;

    const { data: prospectData, isLoading: isProspectLoading } = useQuery(
        findProspectData({
            prospectIds: allProspectIds,
            regex: filterValue ? [filterValue] : [],
            page: pagination.pageIndex + 1,
            perPage: pagination.pageSize,
            includeFields: Array.from(PROSPECT_INCLUDE_FIELDS),
        })
    );

    useEffect(() => {
        table.resetRowSelection();
    }, [data]);

    useEffect(() => {
        setPagination((prev) => ({ ...prev, pageIndex: 0 }));
    }, [filterValue]);

    const hasPreviousPage = useMemo(() => {
        return startRowIndex > 0;
    }, [pagination.pageIndex, pagination.pageSize]);
    const hasNextPage = useMemo(() => {
        return startRowIndex + pagination.pageSize < (prospectData?.count || 0);
    }, [prospectData, pagination.pageIndex, pagination.pageSize]);

    useEffect(() => {
        if (prospectData && data) {
            const contactsMap = new Map<string, UserContact>();
            data?.UserContacts.forEach((contact) => {
                contactsMap.set(contact.prospectId, contact);
            });

            const newRows = prospectData.prospects
                .map((prospect) => {
                    const contact = contactsMap.get(prospect.prospectId);
                    if (!contact) {
                        return null;
                    }
                    return {
                        contact,
                        prospect,
                    };
                })
                .filter((row) => row);
            setRows(newRows);
        } else {
            setRows([]);
        }
    }, [prospectData, currentPageContacts, data, filterValue]);

    const isLoading = isProspectLoading || isContactsLoading || isContactsLoadingByGroupIds;

    return (
        <div className="w-full">
            <ContactsTableToolBar table={table} />
            <div className="rounded-2xl border bg-background-secondary">
                <Table className="rounded-2xl overflow-hidden border-separate border-spacing-0">
                    <TableHeader className="[&_tr]:border-none">
                        {table.getHeaderGroups().map((headerGroup, index) => (
                            <TableRow key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <TableHead key={header.id}>
                                            {header.isPlaceholder
                                                ? null
                                                : flexRender(header.column.columnDef.header, header.getContext())}
                                        </TableHead>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableHeader>
                    <TableBody
                        className="bg-background-primary rounded-t-2xl"
                        style={{
                            boxShadow:
                                'rgba(20, 21, 26, 0.02) 2px 5px 5px 6px, rgba(20, 21, 26, 0.07) -7px 2px 7px 0px',
                        }}
                    >
                        {isLoading ? (
                            Array.from({ length: pagination.pageSize }).map((_, index) => (
                                <TableRow key={index}>
                                    {columns.map((column, colIndex) => (
                                        <TableCell key={colIndex}>
                                            <Skeleton className="h-11 w-full" />
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))
                        ) : table.getRowModel().rows?.length ? (
                            table.getRowModel().rows.map((row, rowIndex) => (
                                <TableRow
                                    key={row.id}
                                    data-state={row.getIsSelected() && 'selected'}
                                    className={cn(
                                        'rounded-t-2xl',
                                        row.getIsSelected() &&
                                            'bg-background-brand-primary-subtle hover:bg-background-brand-primary/30',
                                        row?.original?.prospect?.prospectId === prospectId &&
                                            'bg-background-brand-primary-subtle hover:bg-background-brand-primary/30'
                                    )}
                                >
                                    {row.getVisibleCells().map((cell, cellIndex) => (
                                        <TableCell
                                            key={cell.id}
                                            className={cn(
                                                'border-b py-3',
                                                cellIndex === 1 && 'border-r',
                                                rowIndex === 0 && 'border-t',
                                                rowIndex === 0 && cellIndex === 0 && 'rounded-tl-2xl',
                                                rowIndex === 0 &&
                                                    cellIndex === row.getVisibleCells().length - 1 &&
                                                    'rounded-tr-2xl'
                                            )}
                                        >
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={columns.length} className="h-24 text-center">
                                    No results.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>
            <div className="flex items-center justify-end space-x-2 py-4">
                <div className="flex-1 text-sm text-muted-foreground">
                    {table.getFilteredSelectedRowModel().rows.length} of {table.getFilteredRowModel().rows.length}{' '}
                    row(s) selected.
                </div>
                <div className="flex items-center gap-2">
                    <Select
                        value={String(pagination.pageSize)}
                        onValueChange={(value) => setPagination((prev) => ({ ...prev, pageSize: Number(value) }))}
                    >
                        <SelectTrigger>
                            <SelectValue placeholder={`Show ${pagination.pageSize}`} />
                        </SelectTrigger>
                        <SelectContent>
                            {[10, 25, 50, 100].map((size) => (
                                <SelectItem key={size} value={String(size)}>
                                    {size}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                    <Pagination>
                        <PaginationContent>
                            {hasPreviousPage && (
                                <PaginationItem>
                                    <PaginationPrevious
                                        onClick={() => table.previousPage()}
                                        isActive={hasPreviousPage}
                                        className={cn(!hasPreviousPage && 'hover:bg-primary')}
                                    />
                                </PaginationItem>
                            )}
                            <PaginationItem>
                                <PaginationLink className="hover:bg-primary border-none" isActive>
                                    {pagination.pageIndex + 1}
                                </PaginationLink>
                            </PaginationItem>
                            {/* <PaginationItem>
                                <span className="text-sm text-muted-foreground whitespace-nowrap">
                                    of {table.getPageCount()}
                                </span>
                            </PaginationItem> */}
                            {(hasNextPage || isProspectLoading) && (
                                <PaginationItem>
                                    <PaginationNext
                                        onClick={() => table.nextPage()}
                                        isActive={hasNextPage && !isProspectLoading}
                                        className={cn(!hasNextPage && 'hover:bg-primary')}
                                    />
                                </PaginationItem>
                            )}
                        </PaginationContent>
                    </Pagination>
                </div>
            </div>
        </div>
    );
};

export default ContactsTable;
