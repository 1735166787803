import { atomWithReset } from 'jotai/utils';
import { useAtom } from 'jotai';
import { ClickAwayListenerProps } from '@mui/material';

export interface ButtonNotificationAction {
    type: 'button';
    label: string;
    onClick: () => void;
    onClose?: () => void;
}

export interface LinkNotificationAction {
    type: 'link';
    label: string;
    url: string;
    onClick?: never;
    external?: boolean;
    onClose?: () => void;
}

export type NotificationAction = ButtonNotificationAction | LinkNotificationAction;

export interface INotification {
    message: string;
    subMessage?: string;
    type: 'success' | 'error' | 'warning' | 'info';
    duration?: number;
    action?: NotificationAction;
    clickAwayListenerProps?: Partial<ClickAwayListenerProps>;
}

export const notificationAtom = atomWithReset<INotification | undefined>(undefined);

// Singleton timeout reference
let timeoutRef: NodeJS.Timeout | null = null;

export const useNotifications = () => {
    const [notification, setNotification] = useAtom(notificationAtom);

    const handleSetNotification = (notification: INotification) => {
        if (timeoutRef) {
            clearTimeout(timeoutRef);
        }
        if (notification?.message?.includes('undefined')) {
            console.error('Notification message had undefined in text...', notification);
            notification.message = 'Something went wrong.';
        }

        setNotification(notification);
        if (notification) {
            timeoutRef = setTimeout(() => {
                setNotification(undefined);
            }, notification.duration || 6000);
        }
    };

    return {
        notification,
        notify: handleSetNotification,
    };
};
