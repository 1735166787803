import { z } from 'zod';

import { ChannelType } from '@zaplify/channel-accounts/shared';
import { useToast } from '@shadcn/ui/hooks/use-toast';
import { useMutation } from '@tanstack/react-query';
import { useSdk } from '../../../sdk';
import { useApolloClient } from '@apollo/client';
import { ContactSource, GroupDto } from '@zaplify/services/user-contacts/shared';
import { LinkedinProfileDto } from '@andsend/services/linkedin-profiles/shared';
import {
    GET_ALL_USER_CONTACTS,
    GET_PLAYBOOK_BY_GROUP_ID,
    GET_USER_CONTACT_BY_PROSPECT_ID,
    GET_USER_CONTACTS_BY_GROUP_IDS,
} from '@zaplify/graphql';
import { useAddContacts } from '../../../hooks/use-add-contacts';

const formSchema = z.object({
    name: z.string().min(1, { message: 'Give your playbook a name' }),
    purpose: z.string().min(1, { message: 'You need to set a purpose for your playbook' }),
    targetGroup: z.string().optional(),
    tone: z.string().optional(),
    languages: z.array(z.string()).min(1, { message: 'You need to add at least one language' }),
    defaultChannel: z
        .nativeEnum(ChannelType)
        .pipe(z.union([z.literal(ChannelType.LINKEDIN), z.literal(ChannelType.EMAIL)])),
    context: z.string().optional(),
    default: z.boolean(),
    imgSrc: z.string().optional(),
});

export type PlaybookFormValues = z.infer<typeof formSchema>;

export const usePlaybookForm = (groupId?: string, assistantSettingsId?: string) => {
    const formId = 'playbook-form';
    const { toast } = useToast();
    const apolloClient = useApolloClient();
    const {
        playbook: {
            createGroupWithAssistantSettings: createGroupWithAssistantSettingsMutation,
            updateGroup: updateGroupMutation,
            updateAssistantSettings: updateAssistantSettingsMutation,
        },
        userContacts: { assignUserContactsToGroup: assignUserContactsToGroupMutation },
    } = useSdk();
    const { addContacts } = useAddContacts();

    const { mutateAsync: createGroupWithAssistantSettings } = useMutation(createGroupWithAssistantSettingsMutation());
    const { mutateAsync: updateGroup } = useMutation(updateGroupMutation());
    const { mutateAsync: updateAssistantSettings } = useMutation(updateAssistantSettingsMutation());
    const { mutateAsync: assignUserContactsToGroup } = useMutation(assignUserContactsToGroupMutation());

    const { mutateAsync: savePlaybook } = useMutation({
        mutationFn: async (values: PlaybookFormValues) => {
            console.log('Saving playbook with values:', values);
            let newPlaybook: GroupDto;
            if (groupId && assistantSettingsId) {
                // Update existing playbook
                newPlaybook = await updateGroup({
                    id: groupId,
                    updatedGroup: {
                        name: values.name,
                        default: values.default,
                        imgSrc: values.imgSrc,
                    },
                });
                await updateAssistantSettings({
                    assistantSettingsId,
                    updatedAssistantSettings: {
                        context: values.context,
                        defaultChannel: values.defaultChannel,
                        targetGroup: values.targetGroup,
                        languages: values.languages,
                        purpose: values.purpose,
                        tone: values.tone,
                    },
                });
            } else {
                // Create new playbook
                newPlaybook = await createGroupWithAssistantSettings({
                    group: {
                        name: values.name,
                        default: values.default,
                        imgSrc: values.imgSrc,
                    },
                    assistantSettings: {
                        purpose: values.purpose,
                        languages: values.languages,
                        targetGroup: values.targetGroup,
                        tone: values.tone || '',
                        context: values.context || '',
                        defaultChannel: values.defaultChannel,
                    },
                });
            }
            await apolloClient.refetchQueries({
                // include: [GET_GROUPS, GET_PLAYBOOKS, GET_PLAYBOOK_BY_GROUP_ID],
                updateCache: (cache) => cache.evict({ fieldName: 'Groups' }),
            });
            return newPlaybook;
        },
        onSuccess: () => {
            toast({
                title: groupId ? 'Playbook updated' : 'Playbook created',
                description: 'The playbook has been saved successfully',
                duration: 3000,
            });
        },
        onError: (error) => {
            console.error('Failed to save playbook:', error);
            toast({
                variant: 'destructive',
                title: 'Failed to save playbook',
                description: 'Please try again',
                duration: 5000,
            });
        },
    });

    const moveContactsToGroup = async (groupId: string, userContactIds: string[]) => {
        await assignUserContactsToGroup({
            groupId,
            contactIds: userContactIds,
        });
        await apolloClient.refetchQueries({
            include: [GET_PLAYBOOK_BY_GROUP_ID, GET_USER_CONTACT_BY_PROSPECT_ID],
        });
    };

    const addContactsToGroup = async (groupId: string, contacts: LinkedinProfileDto[]) => {
        await addContacts(
            contacts.map((contact) => ({ linkedinUserId: contact.memberId })),
            {
                groupId,
                source: ContactSource.Playbook,
            }
        );
        await apolloClient.refetchQueries({
            include: [GET_PLAYBOOK_BY_GROUP_ID],
        });
    };

    return {
        savePlaybook,
        formSchema,
        formId,
        moveContactsToGroup,
        addContactsToGroup,
    };
};
