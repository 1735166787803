import { FC } from 'react';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@shadcn/ui/components/ui/dialog';
import { Button } from '@shadcn/ui/components/ui/button';
import { UserToManage } from '../../../../../../hooks/useUsersManagement';

interface CancelInvitationDialogProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    user: UserToManage;
}

export const CancelInvitationDialog: FC<CancelInvitationDialogProps> = ({ isOpen, onClose, onConfirm, user }) => {
    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>Cancel Invitation</DialogTitle>
                    <DialogDescription>
                        Are you sure you want to cancel the invitation for {user.fullName || user.email}?
                    </DialogDescription>
                </DialogHeader>
                <DialogFooter>
                    <Button variant="outline" onClick={onClose}>
                        Keep Invitation
                    </Button>
                    <Button
                        variant="destructive"
                        onClick={() => {
                            onConfirm();
                            onClose();
                        }}
                    >
                        Cancel Invitation
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};
