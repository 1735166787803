import { IQuery, Topics } from '@zaplify/common';
import { PublicSeatDto } from '@zaplify/subscriptions';

export class GetUserSeatQuery extends IQuery<PublicSeatDto> {
    public static override readonly TOPIC = Topics.BILLING;

    constructor(readonly userId: string, readonly organizationId: string) {
        super();
    }
}
