import { Avatar, Box, Tooltip, Typography } from '@mui/material';
import { ProspectOwnerDto, ProspectResponseDto } from '@zaplify/prospects';
import { useState } from 'react';
// import { globalSearchValueAtom } from '../../atoms/ui';
import { LocalStorageKeys } from '../../config';
import AutoSuggestHighlight from './AutoSuggestHighlight';
import ProspectRowStyled from './ProspectRow.styled';
import { ArrowUpRight, Building, UsersRound } from 'lucide-react';
import { stringToColor } from '../../views/chats/utils/string-to-color';
import { UsersAvatarGroup } from '../avatars/users-avatar-group';
import { channelAccountsAtom } from '../../atoms/chats';
import { useAtom } from 'jotai';

const ProspectRow = ({
    prospect,
    searchValue,
    userId,
    onClick,
    campaigns,
    onClickTracking,
    avatarUrl,
    owners,
}: {
    prospect: Partial<ProspectResponseDto>;
    campaigns?: { campaignName: string; _id: string }[];
    userId: string;
    searchValue: string;
    onClick?: () => void;
    onClickTracking?: () => void;
    onClickAway?: () => void;
    avatarUrl?: string;
    owners?: ProspectOwnerDto[];
}) => {
    const [isHovering, setIsHovering] = useState(false);
    const [channelAccounts] = useAtom(channelAccountsAtom);

    const handleProspectIconClick = ({
        campaignId,
        prospectId,
        prospectName,
    }: {
        campaignId: string;
        prospectId: string;
        prospectName: string;
    }) => {
        window.open(`/manage-prospects/${campaignId}/prospect-details/${prospectId}`);
        localStorage.setItem(LocalStorageKeys.GLOBAL_SEARCH_VALUE, prospectName);
    };

    const handleMouseOver = () => {
        setIsHovering(true);
    };

    const handleMouseOut = () => {
        setIsHovering(false);
    };

    return (
        <ProspectRowStyled
            onClick={() => {
                onClick && onClick();
                onClickTracking && onClickTracking();
            }}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            hoverable={!!onClick}
        >
            <div className="main">
                {avatarUrl ? (
                    <Avatar src={avatarUrl} sx={{ width: '32px', height: '32px', fontSize: '14px' }} />
                ) : (
                    <Avatar
                        sx={{
                            width: '32px',
                            height: '32px',
                            fontSize: '14px',
                            bgcolor: stringToColor(prospect?.fullName),
                        }}
                    >
                        {prospect?.fullName?.split(' ')?.[0]?.[0] || ''}
                        {prospect?.fullName?.split(' ')?.[1]?.[0] || ''}
                    </Avatar>
                )}
                <div className="info">
                    <div className="info-title">
                        <Tooltip
                            disableHoverListener={prospect.fullName.length <= 18}
                            title={prospect.fullName}
                            placement="top"
                            arrow
                        >
                            <span className="full-name">
                                <AutoSuggestHighlight text={prospect.fullName} value={searchValue} />
                            </span>
                        </Tooltip>
                        <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                            <Building style={{}} size={16} className="icon" />
                            <span className="organization-name">
                                <AutoSuggestHighlight text={prospect.organizationName} value={searchValue} />
                            </span>
                        </div>
                    </div>
                    {prospect.title && (
                        <div style={{ fontSize: '13px', color: '#6B7985' }}>
                            <AutoSuggestHighlight text={prospect.title} value={searchValue} fullWidth />
                        </div>
                    )}
                    {campaigns?.length > 1 && prospect.campaigns?.length > 0 && (
                        <div className="info-subtitle">Campaign: {prospect.campaigns[0].campaignName}</div>
                    )}
                </div>
            </div>
            {prospect.campaigns && prospect.campaigns.length > 0 && (
                <Box className="launch">
                    <Typography
                        sx={{
                            overflow: 'hidden',
                            color: 'var(--grey-palette-grey-4, #6B7985)',
                            textOverflow: 'ellipsis',
                            fontSize: '14px',
                            fontWeight: 400,
                            lineHeight: '21px',

                            display: onClick && isHovering ? 'inherit' : 'none',
                        }}
                    >
                        Go to contact
                    </Typography>
                    <ArrowUpRight
                        size={20}
                        strokeWidth={1}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleProspectIconClick({
                                campaignId: prospect.campaigns![0]._id,
                                prospectId: prospect._id,
                                prospectName: prospect.fullName,
                            });
                        }}
                        style={{ display: onClick && isHovering ? 'inherit' : 'none' }}
                    />
                </Box>
            )}

            {owners && owners?.filter((o) => o.userId !== userId).length > 0 && (
                <Box className="owners">
                    <UsersAvatarGroup
                        users={owners}
                        size={'small'}
                        userId={userId}
                        userAvatarUrl={channelAccounts?.linkedIn?.avatarUrl}
                        startIcon={<UsersRound size={20} strokeWidth={1} style={{ marginRight: '2px' }} />}
                    />
                </Box>
            )}
        </ProspectRowStyled>
    );
};

export default ProspectRow;
