import { IQuery, Topics } from '@zaplify/common';
import { ProspectQueryResultDto, GetProspectsDto, ProspectDataDto } from '../prospects';
import { ProspectDataWithId } from './get-prospect-data-by-ids.query';

export class FindProspectDataQuery<T extends keyof ProspectDataDto = keyof ProspectDataDto> extends IQuery<{
    prospects: Pick<ProspectDataWithId, T>[];
    count: number;
}> {
    public static override readonly TOPIC = Topics.PROSPECTS;
    constructor(readonly query: GetProspectsDto, readonly includeFields: T[]) {
        super();
    }
}
