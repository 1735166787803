import { Box } from '@mui/material';
import { useDispatch } from 'react-redux-latest';
import TwinSelect from '../../../../../../components/molecules/twinSelect';
import { updateSalesforceSaveStatus } from '../../../../../../redux/ducks/salesforce/operations';
import { updateField } from '../../../../../../redux/ducks/salesforce/slice';
import { AppDispatch } from '../../../../../../redux/store/configureStore';
import { getRequiredText } from '../../../../../campaign/newCampaign/addProspects/importProspects/fieldMapping/functions';
import { ErrorBox } from '../../../../elements';
import { companyNameCorrectlyCased } from '../../../../../../enums/company-name';

export const StaticFieldSelector = ({
    field,
    getStaticPlaceholder,
    getStaticFieldOptions,
    leftOnChange,
    handleDeleteField,
}) => {
    const dispatch = useDispatch<AppDispatch>();

    const rightStaticOnChange = (e, index) => {
        dispatch(
            updateField({
                idx: index,
                type: 'source',
                objectType: 'static',
                fieldName: e.target.value,
            })
        );
        dispatch(updateSalesforceSaveStatus());
    };

    return (
        <>
            <Box display="flex" className={'box-row'}>
                <TwinSelect
                    key={field.source.fieldName}
                    index={field.idx}
                    leftValue={`No ${companyNameCorrectlyCased} field`}
                    leftOptions={null}
                    leftOnChange={(event) => leftOnChange(event, field.idx)}
                    rightValue={field.source.value}
                    rightDisplayEmpty={true}
                    rightPlaceholder={getStaticPlaceholder(field)}
                    rightOptions={getStaticFieldOptions(field)}
                    rightOnChange={(event) => rightStaticOnChange(event, field.idx)}
                    disabledLeft={field.destination.required}
                />
                {getRequiredText({ ...field, required: field.destination.required }, handleDeleteField, field.idx)}
            </Box>
            <ErrorBox errorText={field.errorText} />
        </>
    );
};
