import { FC, Suspense, useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { ConversationsList } from './components/conversations-list';
import {
    ContactDetailsSidebar,
    ContactDetailsSidebarSkeleton,
} from '../../components/contact-details-sidebar/contact-details-sidebar';
import { cn } from '@shadcn/ui/lib/utils';
import ConversationPageHeader, { ConversationPageHeaderSkeleton } from '../../components/conversation-page-header';
import { useSidebars } from '@shadcn/ui/hooks/use-sidebars';

export const AllConversationsPage: FC = () => {
    const { toggleSidebar, sidebarState } = useSidebars();
    const { prospectId } = useParams();

    const toggleDetails = () => {
        toggleSidebar('details');
    };

    return (
        <div className="flex h-full max-w-full overflow-hidden">
            <div className="flex-1 flex flex-col min-w-0 overflow-hidden">
                <Suspense fallback={<ConversationPageHeaderSkeleton />}>
                    <ConversationPageHeader onToggleDetails={toggleDetails} isDetailsOpen={sidebarState.details} />
                </Suspense>

                <div className="flex flex-1 flex-shrink-0 min-h-0 overflow-hidden">
                    <div
                        className={cn('flex min-w-full w-full md:min-w-[320px] md:w-[320px] overflow-hidden', {
                            'hidden md:flex': prospectId,
                        })}
                    >
                        <ConversationsList />
                    </div>
                    <div className={cn('flex flex-1 min-w-0 overflow-hidden', { 'hidden md:flex': !prospectId })}>
                        <Outlet />
                    </div>
                </div>
            </div>
            <ContactDetailsSidebar />
        </div>
    );
};

export default AllConversationsPage;
