import { Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Button } from '@zaplify/ui';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux-latest';
import {
    handleSuccessNotification,
    resetImportProspects,
    runBulkImport,
    setNotification,
    updateImportProspectsReviewDetails,
    updateImportProspectsStep,
    validateImportedProspects,
} from '../../../redux/actions';
import { AppDispatch, RootState, useAppSelector } from '../../../redux/store/configureStore';
import { NavigationBarContainer } from './containers';
import { useShowContactsLimitModal } from '../../../hooks/use-show-contact-limit-modal';
import { useProspectSync } from '../../../hooks/use-prospect-sync';
import { SelectGroupModal } from '../../../components/selectGroupModal/SelectGroupModal';

const fieldMappingValidationSelector = (state) => {
    if (state.importProspects?.fieldMapping?.length > 0) {
        return !state.importProspects.fieldMapping.some(
            (field) => field.required && (field.prospect === '' || field.imported === '')
        );
    } else {
        return false;
    }
};

const shouldButtonBeDisabled = (
    step,
    haveProspectsBeenUploaded,
    fieldMappingValidation,
    numberOfValidProspects,
    validating
) => {
    if (step === 1) return !haveProspectsBeenUploaded;
    if (step === 2) return !fieldMappingValidation;
    if (step === 3) return validating;
    return !(numberOfValidProspects > 0);
};

export const NavigationBar = ({ currentStep, handleStep }) => {
    const dispatch = useDispatch<AppDispatch>();
    const { syncMany } = useProspectSync();
    const [displaySelectPlaybook, setDisplaySelectPlaybook] = useState(false);
    const haveProspectsBeenUploaded = useAppSelector(
        (state: RootState) => state.importProspects?.prospects?.length > 0
    );
    const numberOfValidProspects = useAppSelector(
        (state: RootState) => state.importProspects?.reviewDetails?.validProspects?.length || 0
    );
    const validationDetails = useAppSelector((state: RootState) => state.importProspects?.reviewDetails);
    const fieldMappingValidation = useAppSelector(fieldMappingValidationSelector);
    const [uploading, setUploading] = useState(false);
    const [validating, setValidating] = useState(false);
    const campaigns = useAppSelector((state) => state.campaigns?.campaigns);

    const hasPerformedValidation = Object.keys(validationDetails).length !== 0;

    function getButtonText(step, validProspects, uploading) {
        switch (step) {
            case 1:
                return 'Continue to field mapping';
            case 2:
                return 'Continue to review';
            case 3:
                if (!hasPerformedValidation) {
                    return 'Continuing to review...';
                }
                if (uploading) {
                    return `Uploading ${validProspects} Contacts...`;
                }
                if (!uploading) {
                    return `Select playbook`;
                }
            default:
                return 'Continue';
        }
    }

    const { showContactLimitModal } = useShowContactsLimitModal();

    const handleNext = async () => {
        console.log('handleNext', currentStep);
        if (currentStep === 1) {
            dispatch(updateImportProspectsReviewDetails({}));
            handleStep(2);
            return;
        }
        if (currentStep === 2) {
            setValidating(true);
            dispatch(validateImportedProspects());
            handleStep(3);
            return;
        }
        if (currentStep === 3) {
            setDisplaySelectPlaybook(true);
            return;
        }
        dispatch(updateImportProspectsStep(currentStep + 1));
        handleStep(currentStep + 1);
    };
    const handleBack = () => {
        if (currentStep === 3) {
            dispatch(updateImportProspectsReviewDetails({}));
        }
        dispatch(updateImportProspectsStep(currentStep - 1));
        handleStep(currentStep - 1);
    };

    const handleUpload = async (groupId?: string) => {
        setUploading(true);
        try {
            const prospectsSuccessfullyImported = await dispatch(runBulkImport(groupId, syncMany, true));
            setUploading(false);
            if (prospectsSuccessfullyImported) {
                window?.analytics?.track('User Uploaded Prospects In Prospecting View');
                dispatch(handleSuccessNotification('Successfully uploaded contacts', 5000));
                dispatch(resetImportProspects());
                handleStep(1);
            } else {
                dispatch(setNotification('Unable to upload contacts', 'error'));
            }
            return;
        } catch (error: any) {
            console.error('Error uploading prospects', error);
            dispatch(setNotification('Unable to upload contacts', 'error'));
            if (error.status === 403) {
                showContactLimitModal();
            }
            setUploading(false);
        }
    };

    const nextStepButtonDisabled =
        shouldButtonBeDisabled(
            currentStep,
            haveProspectsBeenUploaded,
            fieldMappingValidation,
            numberOfValidProspects,
            validating
        ) || uploading;
    const nextStepButtonText = getButtonText(currentStep, numberOfValidProspects, uploading);

    useEffect(() => {
        setValidating(false);
    }, [hasPerformedValidation]);

    return (
        <>
            {displaySelectPlaybook && (
                <SelectGroupModal onModalClose={() => setDisplaySelectPlaybook(false)} onSubmit={handleUpload} />
            )}
            <NavigationBarContainer>
                <div>
                    <Typography className="import-prospects-navigation-text-bold">Upload CSV / </Typography>
                    <Typography
                        className={
                            currentStep > 1
                                ? 'import-prospects-navigation-text-bold'
                                : 'import-prospects-navigation-text-thin'
                        }
                    >
                        Map Fields /{' '}
                    </Typography>
                    <Typography
                        className={
                            currentStep > 2
                                ? 'import-prospects-navigation-text-bold'
                                : 'import-prospects-navigation-text-thin'
                        }
                    >
                        Review
                    </Typography>
                </div>
                <div>
                    {currentStep > 1 && (
                        <Button
                            className="import-prospects-back-button"
                            variant="text"
                            text="Go back"
                            onClick={handleBack}
                        />
                    )}
                    <Button
                        height="48px"
                        borderRadius="100px"
                        classes={['import-prospects-next-button', uploading ? ' uploading' : ' regular']}
                        variant="contained"
                        endIcon={(uploading || validating) && <CircularProgress color="inherit" size={20} />}
                        disabled={nextStepButtonDisabled}
                        text={nextStepButtonText}
                        onClick={!validating && handleNext}
                    />
                </div>
            </NavigationBarContainer>
        </>
    );
};
