import { IQuery, Topics } from '@zaplify/common';
import { PeopleResponseDto } from '../global-search/global-search.dto';

interface GlobalSearchDto {
    match: {
        companyId: string;
        query: string;
        userId: string;
        userOrganizationId: string;
    };
    perPage: number;
    page: number;
}

export interface GlobalSearchPeopleInCompanyResultDto {
    data: PeopleResponseDto[];
    pagination: {
        page: number;
        limit: number;
        numberOfPages: number;
    };
}

export class GlobalSearchPeopleInCompanyQuery extends IQuery<GlobalSearchPeopleInCompanyResultDto> {
    public static override readonly TOPIC = Topics.PROSPECTS;
    constructor(public readonly globalSearchQuery: GlobalSearchDto) {
        super();
    }
}
