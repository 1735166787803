/* eslint-disable @typescript-eslint/no-empty-function */
import { Button as ButtonMaterialUI, SxProps } from '@mui/material';
import { darken } from '@mui/material/styles';
import { styled } from '@mui/styles';
import classNames from 'classnames';
import { ReactNode } from 'react';
import { GuardedAnchor } from '../guarded-anchor/guarded-anchor';
import { GuardedLink } from '../guarded-link/guarded-link';

const ButtonComponent = styled(
    ({ children, className, ...props }) =>
        (props.link && (
            <GuardedLink to={props.link} className={className}>
                <ButtonMaterialUI
                    className={classNames(props.mode, props.variant || 'contained')}
                    variant={props.variant !== 'secondary' ? props.variant || 'contained' : 'contained'}
                    onClick={props.onClick || (() => {})}
                    type={props.type || 'button'}
                    startIcon={props.startIcon}
                    endIcon={props.endIcon}
                    disabled={props.disabled}
                    size={props.size}
                    data-cy={props.dataCy}
                    disableRipple={props.disableRipple}
                    sx={props.sx}
                >
                    {children}
                </ButtonMaterialUI>
            </GuardedLink>
        )) ||
        (props.href && (
            <GuardedAnchor href={props.href} target={props.target || '_blank'} className={className}>
                <ButtonMaterialUI
                    className={classNames(props.mode, props.variant || 'contained')}
                    variant={props.variant !== 'secondary' ? props.variant || 'contained' : 'contained'}
                    onClick={props.onClick || (() => {})}
                    type={props.type || 'button'}
                    startIcon={props.startIcon}
                    endIcon={props.endIcon}
                    disabled={props.disabled}
                    size={props.size}
                    data-cy={props.dataCy}
                    disableRipple={props.disableRipple}
                    sx={props.sx}
                >
                    {children}
                </ButtonMaterialUI>
            </GuardedAnchor>
        )) ||
        (true && (
            <span className={classNames(className, props.mode)} style={props.fullWidth ? { width: '100%' } : {}}>
                <ButtonMaterialUI
                    fullWidth={props.fullWidth || false}
                    className={`${classNames(props.mode, props.variant || 'contained')} ${classNames(
                        props.variant === 'secondary',
                        'contained',
                    )}`}
                    variant={props.variant !== 'secondary' ? props.variant || 'contained' : 'contained'}
                    onClick={props.onClick || (() => {})}
                    href={props.href}
                    target={props.target}
                    type={props.type || 'button'}
                    startIcon={props.startIcon}
                    endIcon={props.endIcon}
                    disabled={props.disabled}
                    size={props.size}
                    data-cy={props.dataCy}
                    disableRipple={props.disableRipple}
                    sx={props.sx}
                >
                    {children}
                </ButtonMaterialUI>
            </span>
        )),
)(
    ({
        theme,
        borderRadius,
        height,
        width,
        customColor,
        hoverColor,
        hoverBorderColor,
        fontColor,
        hoverTextDecoration,
        fontWeight,
        boxShadow,
        sx,
    }: any) => ({
        textDecoration: 'none',
        height: height,
        '&:hover': {
            textDecoration: 'none',
        },
        '& > button': {
            color: theme.palette.colors.white,
            borderRadius: borderRadius || '8px',
            paddingTop: '12px',
            paddingBottom: '12px',
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            backgroundColor: theme.palette.primary.light,
            boxShadow: 'none',
            height: height || 50,
            boxSizing: 'border-box',
            width: width,
            fontWeight: fontWeight,
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
                boxShadow: 'none',
            },
            '&.outlined': {
                paddingTop: '8px',
                paddingBottom: '8px',
                backgroundColor: 'transparent',
                color: customColor || theme.palette.primary.light,
                borderColor: customColor || theme.palette.primary.light,
                '&:hover': {
                    color: (customColor && darken(customColor, 0.05)) || theme.palette.primary.main,
                    borderColor: (customColor && darken(customColor, 0.05)) || theme.palette.primary.main,
                },
            },
            '&.text': {
                paddingTop: '8px',
                paddingBottom: '8px',
                backgroundColor: 'transparent',
                color: customColor || theme.palette.graphics.icon,
                '&:hover': {
                    color: (customColor && darken(customColor, 0.05)) || theme.palette.graphics.infoBox,
                },
            },
            '&.secondary': {
                border: '0.8px solid #E1E6E8',
                backgroundColor: 'white',
                color: '#3A4B59',
                height: height || 34,
                boxShadow: boxShadow || '',
                '&:hover': {
                    color: theme.palette.graphics.infoBox,
                    backgroundColor: 'rgba(21, 27, 38, 0.04)',
                },
                '&.Mui-disabled': {
                    color: theme.palette.colors.grey,
                },
            },
            '&.grey': {
                border: '0.8px solid #E1E6E8',
                backgroundColor: theme.palette.colors.greyStroke,
                color: theme.palette.colors.greyDark,
                '&:hover': {
                    backgroundColor: theme.palette.colors.greyLight,
                },
            },
            '&.red': {
                backgroundColor: theme.palette.colors.redPale,
                '&:hover': {
                    backgroundColor: theme.palette.colors.red,
                },
                '&.outlined': {
                    backgroundColor: 'transparent',
                    color: theme.palette.colors.redPale,
                    borderColor: theme.palette.colors.redPale,
                    '&:hover': {
                        color: theme.palette.colors.red,
                        borderColor: theme.palette.colors.red,
                    },
                },
            },
            '&.green': {
                backgroundColor: theme.palette.colors.greenPale,
                '&:hover': {
                    backgroundColor: theme.palette.colors.green,
                },
                '&.outlined': {
                    backgroundColor: 'transparent',
                    color: theme.palette.colors.greenPale,
                    borderColor: theme.palette.colors.greenPale,
                    '&:hover': {
                        color: theme.palette.colors.green,
                        borderColor: theme.palette.colors.green,
                    },
                },
            },
            '&.blue': {
                backgroundColor: theme.palette.colors.bluePale,
                '&:hover': {
                    backgroundColor: theme.palette.colors.blue,
                },
                '&.outlined': {
                    backgroundColor: 'transparent',
                    color: theme.palette.colors.bluePale,
                    borderColor: theme.palette.colors.bluePale,
                    '&:hover': {
                        color: theme.palette.colors.blue,
                        borderColor: theme.palette.colors.blue,
                    },
                },
                '&.wide': {
                    width: '96px !important',
                },
            },
            '&.blueDark': {
                backgroundColor: '#6A76FB',
                '&:hover': {
                    backgroundColor: darken('#6A76FB', 0.1),
                },
                '&.outlined': {
                    backgroundColor: 'transparent',
                    color: '#6A76FB',
                    borderColor: '#6A76FB',
                    '&:hover': {
                        color: darken('#6A76FB', 0.1),
                        borderColor: darken('#6A76FB', 0.1),
                    },
                },
                '&.wide': {
                    width: '96px !important',
                },
            },
            '&.white-grey-border': {
                backgroundColor: theme.palette.colors.white,
                '&.outlined': {
                    border: `0.8px solid ${theme.palette.colors.greyInformationLight}`,
                    color: fontColor || theme.palette.subline,
                    '&:hover': {
                        color: hoverColor || theme.palette.colors.blue,
                        borderColor: hoverBorderColor || theme.palette.colors.blue,
                        textDecoration: hoverTextDecoration,
                    },
                },
                '&.blocklistVariant': {
                    borderRadius: '100px',
                    height: '32px',
                    color: theme.palette.colors.black,
                    fontWeight: '500',
                    fontSize: '14px',
                },
                '&.borderRadius': {
                    borderRadius: borderRadius,
                },
            },
            '&.white-font': {
                color: theme.palette.colors.white,
            },
            '&.black-font': {
                color: theme.palette.colors.black,
            },
            '&.transparent': {
                boxShadow: 'none',
                backgroundColor: 'transparent',
                '&:hover': {
                    boxShadow: 'none',
                    backgroundColor: 'transparent',
                },
                '&.outlined': {
                    borderColor: 'transparent',
                },
            },
            '&.black': {
                backgroundColor: '#23323F',
                '&:hover': {
                    background: '#14223A',
                },
                '&.Mui-disabled': {
                    color: theme.palette.colors.grey,
                },
            },
        },
    }),
);

export const ButtonFontColor = {
    white: 'white-font',
    black: 'black-font',
};
export const ButtonColor = {
    red: 'red',
    blue: 'blue',
    blueDark: 'blueDark',
    green: 'green',
    transparent: 'transparent',
};

export interface ButtonProps {
    onClick?: React.MouseEventHandler<Element>;
    text?: string;
    classes?: string[] | string;
    variant?: 'text' | 'outlined' | 'contained' | 'secondary';
    startIcon?: ReactNode;
    endIcon?: ReactNode;
    fullWidth?: boolean;
    link?: string;
    href?: string;
    target?: string;
    type?: 'button' | 'reset' | 'submit';
    disabled?: boolean;
    borderRadius?: string | number;
    height?: string | number;
    width?: string | number;
    customColor?: string;
    size?: string | number;
    children?: ReactNode;
    fontColor?: string;
    hoverColor?: string;
    hoverBorderColor?: string;
    hoverTextDecoration?: string;
    boxShadow?: string;
    className?: string;
    sx?: SxProps;
    dataCy?: string;
    disableRipple?: boolean;
}

export const Button = (props: ButtonProps) => {
    const extraClasses = typeof props.classes !== 'string' ? (props.classes as string[])?.join(' ') : '';
    return (
        <ButtonComponent
            fullWidth={props.fullWidth}
            variant={props.variant || 'contained'}
            mode={props.classes}
            link={props.link}
            onClick={props.onClick}
            href={props.href}
            target={props.target}
            type={props.type}
            startIcon={props.startIcon}
            endIcon={props.endIcon}
            disabled={props.disabled}
            borderRadius={props.borderRadius}
            height={props.height}
            width={props.width}
            customColor={props.customColor}
            size={props.size}
            hoverBorderColor={props.hoverBorderColor}
            hoverColor={props.hoverColor}
            fontColor={props.fontColor}
            boxShadow={props.boxShadow}
            className={`${props.className} ${extraClasses || ''}`}
            disableRipple={props.disableRipple}
            sx={props.sx}
            dataCy={props.dataCy}
        >
            {props.text || props.children}
        </ButtonComponent>
    );
};

export default Button;
