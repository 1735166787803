import { FC, useState } from 'react';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@shadcn/ui/components/ui/dialog';
import { Button } from '@shadcn/ui/components/ui/button';

interface CancelSubscriptionDialogProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => Promise<void>;
}

export const CancelSubscriptionDialog: FC<CancelSubscriptionDialogProps> = ({ isOpen, onClose, onConfirm }) => {
    const [loading, setLoading] = useState(false);
    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Cancel Subscription</DialogTitle>
                    <DialogDescription>
                        Are you sure you want to cancel your subscription? This action cannot be undone.
                    </DialogDescription>
                </DialogHeader>
                <DialogFooter>
                    <Button variant="outline" onClick={onClose}>
                        Keep Subscription
                    </Button>
                    <Button
                        variant="destructive"
                        onClick={() => {
                            setLoading(true);
                            onConfirm()
                                .then(() => {
                                    setLoading(false);
                                })
                                .catch(() => {
                                    setLoading(false);
                                });
                        }}
                        disabled={loading}
                    >
                        {loading ? 'Cancelling...' : 'Cancel Subscription'}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};
