import { useResetAtom } from 'jotai/utils';
import {
    availablePlansAtom,
    loadingStateAtom,
    selectedPackagesAtom,
    selectedPlanAtom,
    selectedPlanEstimatesSummaryAtom,
    selectedPlanEstimatesTotalAtom,
    selfServiceModalAtom,
    totalAmountToChargeAtom,
    upgradePlanErrorTextAtom,
} from '../atoms/selfService';

const userResetAtoms = () => {
    const resetSelectedPackagesAtom = useResetAtom(selectedPackagesAtom);
    const resetAvailablePlansAtom = useResetAtom(availablePlansAtom);
    const resetSelectedPlanEstimatesSummaryAtom = useResetAtom(selectedPlanEstimatesSummaryAtom);
    const resetSelectedPlanEstimatesTotalAtom = useResetAtom(selectedPlanEstimatesTotalAtom);
    const resetLoadingStateAtom = useResetAtom(loadingStateAtom);
    const resetTotalAmountToChargeAtom = useResetAtom(totalAmountToChargeAtom);
    const resetSelectedPlanAtom = useResetAtom(selectedPlanAtom);
    const resetUpgradePlanErrorTextAtom = useResetAtom(upgradePlanErrorTextAtom);
    const resetSelfServiceModalAtom = useResetAtom(selfServiceModalAtom);

    const resetAllAtoms = () => {
        resetSelectedPackagesAtom();
        resetAvailablePlansAtom();
        resetSelectedPlanEstimatesSummaryAtom();
        resetSelectedPlanEstimatesTotalAtom();
        resetLoadingStateAtom();
        resetTotalAmountToChargeAtom();
        resetSelectedPlanAtom();
        resetUpgradePlanErrorTextAtom();
        resetSelfServiceModalAtom();
    };

    return { resetAllAtoms };
};

export default userResetAtoms;
