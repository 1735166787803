import { useQuery } from '@tanstack/react-query';
import { useSdk } from '../../sdk';
import { ContactAvatar } from '../contact-avatar';
import { Card } from '@shadcn/ui/components/ui/card';
import { ConnectionStatusBadge } from '../../pages/actions/components/connection-status-badge';
import { ContactDetails } from './contact-details';
import { AddContactButton } from '../buttons/add-contact-button';
import { ContactSource } from '@zaplify/services/user-contacts/shared';
import { Tooltip, TooltipContent, TooltipTrigger } from '@shadcn/ui/components/ui/tooltip';
import { ViewConversationButton } from '../buttons/view-conversation-button';

interface ContactCardProps {
    linkedinUserId: string;
    prospectId?: string;
}

export const ContactCard: React.FC<ContactCardProps> = ({ linkedinUserId, prospectId }) => {
    const {
        linkedinProfiles: { getLinkedinProfileByMemberIdOrPublicIdentifier },
        prospect: { getProspectById },
    } = useSdk();

    const { data: linkedinProfile } = useQuery(
        getLinkedinProfileByMemberIdOrPublicIdentifier(linkedinUserId, { enabled: !!linkedinUserId })
    );
    const { data: prospect } = useQuery(getProspectById(prospectId, { enabled: !!prospectId }));

    return (
        <Card className="flex flex-col w-80 max-w-80 min-h-72 p-4 gap-1">
            <div className="card-header flex flex-row items-center gap-2 min-w-0 max-w-full">
                <div className="flex items-end">
                    <ContactAvatar
                        avatarUrl={linkedinProfile?.profileImageUrl ?? prospect?.data?.linkedinProfileImgUrl}
                        fullName={linkedinProfile?.fullName ?? prospect?.data?.fullName}
                        size="lg"
                    />
                    {prospectId && (
                        <ConnectionStatusBadge prospectId={prospectId} collapsed className="-ml-2 -mb-1 z-10" />
                    )}
                </div>
                <div className="card-header-content flex flex-col max-w-full min-w-0">
                    <p className="text-md font-medium">{linkedinProfile?.fullName ?? prospect?.data?.fullName}</p>
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <p className="text-md font-normal text-muted-foreground truncate -mt-1">
                                {linkedinProfile?.headline ?? prospect?.data?.linkedinHeadline ?? ''}
                            </p>
                        </TooltipTrigger>
                        <TooltipContent>
                            {linkedinProfile?.headline ?? prospect?.data?.linkedinHeadline ?? ''}
                        </TooltipContent>
                    </Tooltip>
                </div>
            </div>
            <ContactDetails linkedinUserId={linkedinUserId} prospectId={prospectId} />
            <div className="flex flex-1" />
            {!prospectId && linkedinProfile?.publicIdentifier && (
                <AddContactButton
                    className="mt-1"
                    linkedinUserId={linkedinUserId}
                    linkedinUrl={'https://www.linkedin.com/in/' + linkedinProfile?.publicIdentifier}
                    source={ContactSource.Agent}
                />
            )}
            {prospectId && <ViewConversationButton className="w-full mt-1" prospectId={prospectId} />}
        </Card>
    );
};
