import React from 'react';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { cn } from '@shadcn/ui/lib/utils';

interface TextSearchHighlightedProps {
    text: string;
    value: string;
    className?: string;
}

export const TextSearchHighlighted: React.FC<TextSearchHighlightedProps> = ({ text, value, className }) => {
    if (!value || !text) {
        return <span className={cn('font-normal text-muted-foreground', className)}>{text}</span>;
    }

    const matches = match(text, value, { insideWords: true, findAllOccurrences: true });
    const parts = parse(text, matches);

    const containerClass = cn('whitespace-nowrap overflow-hidden text-ellipsis', className);

    return (
        <div className={containerClass}>
            {parts.map((part, index) => (
                <span
                    key={index}
                    className={part.highlight ? 'font-semibold text-foreground' : 'font-normal text-muted-foreground'}
                >
                    {part.text}
                </span>
            ))}
        </div>
    );
};
