import { LoginForm } from './components/login-form';
import { AuthPageLayout } from './components/auth-page-layout';
// import liOnboardingBackground from './assets/li-onboarding-background.jpg';
// import { BackgroundGradientAnimation } from '@shadcn/ui/components/background-gradient';
// import { AndSendIcon } from '../../../components/icons/and-send/andsend-icon';

export const LoginPage = () => {
    return (
        <AuthPageLayout>
            <LoginForm />
        </AuthPageLayout>
    );
};
