import { IUntypedCommand } from '@zaplify/common';
import { Topics } from '@zaplify/common';
import { UserRole } from '../users';

export class UpdateInvitationRoleCommand extends IUntypedCommand {
    public static override readonly TOPIC = Topics.USERS;
    constructor(readonly invitationId: string, readonly organizationId: string, readonly role: UserRole) {
        super();
    }
}
