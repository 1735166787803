import { Template } from '../../new/pages/playbooks/hooks/use-playbook-templates';

export const playbookImages = [
    '/images/playbook-images/1.jpeg',
    '/images/playbook-images/2.jpeg',
    '/images/playbook-images/3.jpeg',
    '/images/playbook-images/4.jpeg',
    '/images/playbook-images/5.jpeg',
    '/images/playbook-images/6.jpeg',
    '/images/playbook-images/7.jpeg',
    '/images/playbook-images/8.jpeg',
    '/images/playbook-images/9.jpeg',
    '/images/playbook-images/10.jpeg',
    '/images/playbook-images/11.jpeg',
    '/images/playbook-images/12.jpeg',
    '/images/playbook-images/13.jpeg',
    '/images/playbook-images/14.jpeg',
    '/images/playbook-images/15.jpeg',
];

export const templateImages: Record<Template, string> = {
    investors: '/images/playbook-images/investors.jpeg',
    sales: '/images/playbook-images/sales.jpeg',
    network: '/images/playbook-images/network.jpeg',
    customer: '/images/playbook-images/customer.jpeg',
    outreach: '/images/playbook-images/outreach.jpeg',
    referral: '/images/playbook-images/referral.jpeg',
    productLaunch: '/images/playbook-images/launch.jpeg',
    aussie: '/images/playbook-images/aussie.jpeg',
    easter: '/images/playbook-images/easter.png',
};
