import { getZaplifySdk } from '@zaplify/sdk';
import { ProspectDto } from '@zaplify/prospects';
import { atomWithReset } from 'jotai/utils';
import { useAtom } from 'jotai';
import { UserContactStatus } from '@zaplify/services/user-contacts/shared';
import { useEffect } from 'react';

export interface UserContact {
    userContactId: string;
    prospect: ProspectDto;
    groups: Group[];
    status: UserContactStatus;
}

export interface Group {
    id: string;
    name: string;
    imgSrc?: string;
}

export interface UserContactWithoutProspectData {
    userContactId: string;
    prospectId: string;
    groups: {
        id: string;
        name: string;
        imgSrc?: string;
    }[];
    status: UserContactStatus;
}

export const userContactsLoadingAtom = atomWithReset<boolean>(false);
export const userContactsStateAtom = atomWithReset<UserContactWithoutProspectData[]>([]);
userContactsStateAtom.debugLabel = 'userContactsState';
export const userContactLimitAtom = atomWithReset<number | 'none'>(50);

export const useUserContacts = () => {
    const zaplifySdk = getZaplifySdk();
    const prospectsSdk = zaplifySdk.profiles.prospects;
    const userContactsSdk = zaplifySdk.profiles.userContacts;
    const [allContacts] = useAtom(userContactsStateAtom);
    const [contactsLoading] = useAtom(userContactsLoadingAtom);
    const [limit, setLimit] = useAtom(userContactLimitAtom);
    const prospectIds = allContacts.map((contact) => contact.prospectId);
    const totalAmountOfContacts = allContacts.length;

    const getUserContacts = async ({
        searchTerm,
        userOrganizationId,
        page,
        pageSize,
        groupId,
        statuses,
    }: {
        searchTerm?: string;
        userOrganizationId: string;
        page: number;
        pageSize: number;
        groupId?: string;
        statuses?: UserContactStatus[];
    }): Promise<{ contacts: UserContact[]; count: number }> => {
        if (contactsLoading) {
            return { contacts: [], count: 0 };
        }

        const filteredProspectIds = prospectIds.filter((prospectId) => {
            const contact = allContacts.find((contact) => contact.prospectId === prospectId);
            if (!contact) return false;

            if (groupId) {
                const inGroup = contact.groups.some((group) => group.id === groupId);
                if (!inGroup) return false;
            }

            if (statuses?.length) {
                if (!statuses.includes(contact.status)) return false;
            }
            return true;
        });

        const matchedProspects = await prospectsSdk.searchProspectIds({
            prospectIds: filteredProspectIds,
            organizationId: userOrganizationId,
            regex: [searchTerm ?? ''],
            sort: { 'data.fullName': 1 },
            page: page,
            perPage: pageSize,
        });
        if (!matchedProspects.prospects) return { contacts: [], count: 0 };

        const matchedUserContacts: UserContact[] = [];
        const prospects = matchedProspects.prospects;
        for (const prospect of prospects) {
            const userContact = allContacts.find((contact) => contact.prospectId === prospect.id);
            if (userContact) {
                matchedUserContacts.push({
                    userContactId: userContact.userContactId,
                    prospect: prospect,
                    status: userContact.status,
                    groups: userContact.groups,
                });
            }
        }
        return { contacts: matchedUserContacts, count: matchedProspects.prospectsCount };
    };

    const assignUserContactToGroups = async ({
        userContactId,
        groupId,
    }: {
        userContactId: string;
        groupId: string;
    }) => {
        await userContactsSdk.assignToGroup(userContactId, groupId);
    };

    const removeUserContacts = async (userContactIds: string[]) => {
        await userContactsSdk.deleteMany(userContactIds);
    };

    const activateUserContacts = async (userContactIds: string[]) => {
        await userContactsSdk.activateMany(userContactIds);
    };

    const deactivateUserContacts = async (userContactIds: string[]) => {
        await userContactsSdk.deactivateMany(userContactIds);
    };

    const fetchUserContactLimit = async () => {
        const limit = await userContactsSdk.getLimit();
        setLimit(limit);
    };

    useEffect(() => {
        fetchUserContactLimit();
    }, []);

    return {
        allContacts,
        getUserContacts,
        totalAmountOfContacts,
        assignUserContactToGroups,
        readyToFetch: !contactsLoading,
        removeUserContacts,
        activateUserContacts,
        deactivateUserContacts,
        limit,
    };
};
