import { FC } from 'react';
import { FeedController } from './feed-controller';
import { SplitContactSuggestionCard } from './contact-suggestion-card/split-contact-suggestion-card';
import { useSuggestionsFeed } from '../hooks/use-suggestions-feed';
import { Link, useSearchParams } from 'react-router-dom';
import { paths } from '../../../../../routes/paths';
import { NetworkAnimation } from './network-animation';
import { useState, useEffect } from 'react';
import { ContactSuggestionCard } from './contact-suggestion-card/contact-suggestion-card';

export const ContactSuggestionsFeed: FC = () => {
    const { contactSuggestions } = useSuggestionsFeed();
    const [searchParams] = useSearchParams();
    const [isInsideNetwork, setIsInsideNetwork] = useState(searchParams.get('insideNetwork') === 'true');

    useEffect(() => {
        setIsInsideNetwork(searchParams.get('insideNetwork') === 'true');
    }, [searchParams]);

    if (contactSuggestions?.length) {
        return (
            <div className="flex flex-row items-center h-full w-full gap-4">
                <FeedController className="hidden md:flex" />
                <div className="flex flex-col items-center justify-center w-full h-full">
                    {isInsideNetwork ? <ContactSuggestionCard /> : <SplitContactSuggestionCard />}
                </div>
            </div>
        );
    } else {
        return (
            <div className="flex flex-col items-center justify-center h-full w-full gap-8">
                <div className="flex flex-col items-center justify-center space-y-8 p-12 max-w-5xl mx-auto rounded-xl bg-background/60 backdrop-blur-sm shadow-sm">
                    <div className="space-y-4">
                        <div className="w-72 h-72">
                            <NetworkAnimation />
                        </div>
                        <h3 className="text-2xl font-semibold text-text-primary text-center">
                            We're analyzing your network
                        </h3>
                    </div>

                    <div className="space-y-2 max-w-xl">
                        <p className="text-center text-base text-text-secondary leading-relaxed">
                            We'll find people in your network similar to the contacts in your playbooks and update
                            suggestions daily. To get better matches, add a few good contacts to your playbooks.
                        </p>
                    </div>

                    <div className="flex flex-col items-center gap-6 w-full max-w-lg">
                        <div className="h-px w-full bg-border/60" />
                        <div className="flex flex-col items-center gap-4">
                            <p className="text-center text-sm text-muted-foreground">
                                Speed up the process by manually adding contacts from LinkedIn
                            </p>
                            <Link
                                to={paths.NEW.IMPORT_PATHS.LINKEDIN}
                                className="inline-flex items-center justify-center rounded-md text-sm font-medium h-11 px-8 py-2 bg-primary text-primary-foreground shadow hover:bg-primary/90 transition-colors"
                            >
                                <span className="flex items-center gap-2">
                                    Add LinkedIn Contacts
                                    <svg
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M6 12L10 8L6 4"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};
