import { IQuery, Topics } from '@zaplify/common';
import { BasicSeatInfoDto } from './dtos/basic-seat-info.dto';

export class GetBasicUserSeatInfoQuery extends IQuery<BasicSeatInfoDto> {
    public static override readonly TOPIC = Topics.BILLING;

    constructor(readonly userId: string, readonly organizationId: string) {
        super();
    }
}
