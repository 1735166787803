/// <reference types="chrome"/>

import { sendMessageToRuntime } from '../../extension-stuff';

const minExtensionVersion = import.meta.env.VITE_ZAPLIFY_CHROME_EXTENSION_MIN_VERSION || '2.0.0';

class CEHandler {
    extensionId = import.meta?.env?.VITE_ZAPLIFY_CHROME_EXTENSION_ID || 'nolanbablkmlhllilaloenjhaplnfhof';

    sendMessageToRuntime = (actionName, payload) => {
        return new Promise((resolve, reject) => {
            if (window.chrome?.runtime) {
                const replyHandler = (reply) => {
                    if (chrome.runtime.lastError) {
                        reject({
                            message: 'Error while sending message to extension',
                            error: chrome.runtime.lastError,
                        });
                    } else {
                        resolve(reply);
                    }
                };
                const message = { action: actionName, payload };
                chrome.runtime.sendMessage(this.extensionId, message, replyHandler);
            } else {
                reject('Chrome runtime is not available');
            }
        });
    };

    openPopup = ({
        activateTaskFlowTab = false,
        taskId,
        startTaskFlow,
    }: {
        activateTaskFlowTab?: boolean;
        taskId?: string;
        startTaskFlow?: boolean;
    }) => {
        this.sendMessageToRuntime('OPEN_POPUP', { activateTaskFlowTab, taskId, startTaskFlow });
    };
}

export const getCEHandler = () => {
    return new CEHandler();
};

export const openChromeExtension = () => {
    const CEHandler = getCEHandler();
    CEHandler.openPopup({ activateTaskFlowTab: false });
};

/**
 *
 * @deprecated
 */
export const openTaskInCE = (taskId) => {
    const CEHandler = getCEHandler();
    CEHandler.openPopup({ taskId: taskId });
};

/**
 *
 * @deprecated
 */
export const startTaskFlow = () => {
    const CEHandler = getCEHandler();
    CEHandler.openPopup({ startTaskFlow: true });
};

const getCookies = async ({ website }: { website: 'LinkedIn' }) => {
    const CEHandler = getCEHandler();
    // handle for versions 1 and 2
    // later on CEHandler can be dropped
    try {
        const response = await CEHandler.sendMessageToRuntime('GET_COOKIES', { website });
        let cookies: chrome.cookies.Cookie[];
        if (response && ((response as any).cookies as chrome.cookies.Cookie[])) {
            cookies = (response as any).cookies as chrome.cookies.Cookie[];
        }
        if (response && !Array.isArray(response)) {
            cookies = (response as any).cookies as chrome.cookies.Cookie[];
        } else {
            cookies = response as chrome.cookies.Cookie[];
        }
        return cookies || [];
    } catch (e) {
        try {
            const response = await sendMessageToRuntime('GET_COOKIES', { website });
            let cookies: chrome.cookies.Cookie[];
            if (response && ((response as any).cookies as chrome.cookies.Cookie[])) {
                cookies = (response as any).cookies as chrome.cookies.Cookie[];
            }
            if (response && !Array.isArray(response)) {
                cookies = (response as any).cookies as chrome.cookies.Cookie[];
            } else {
                cookies = response as chrome.cookies.Cookie[];
            }
            return cookies || [];
        } catch (e) {
            console.log('Error getting cookies from extension', e);
            return [];
        }
        console.log('Error getting cookies from extension', e);
        return [];
    }
};

export const getLinkedinCookies = async (): Promise<{
    li_at: chrome.cookies.Cookie | null;
    all: chrome.cookies.Cookie[];
}> => {
    try {
        const cookies: chrome.cookies.Cookie[] = await getCookies({ website: 'LinkedIn' });
        return {
            li_at: cookies.find((cookie) => cookie?.name === 'li_at') || null,
            all: cookies,
        };
    } catch (error) {
        console.error('Error while trying to get Linkedin Cookie', error, (error as any)?.message);
        return {
            li_at: null,
            all: [],
        };
    }
};

const isVersionGTE = (version, minVersion) => {
    if (!(version.includes('.') && minVersion.includes('.'))) {
        throw new Error(`Versions not valid version strings. version: ${version} minVersion: ${minVersion}`);
    }
    const verArr = version.split('.');
    const minArr = minVersion.split('.');
    for (let i = 0; i < verArr.length; i++) {
        const verInt = parseInt(verArr[i]);
        const minInt = parseInt(minArr[i] || '0');
        if (Number.isNaN(verInt) || Number.isNaN(minInt)) {
            throw new Error(
                `Versions not valid version strings (NaN error). version: ${version} minVersion: ${minVersion}`
            );
        }
        if (verInt > minInt) {
            return true;
        } else if (verInt === minInt) {
            continue;
        } else {
            return false;
        }
    }
    return true;
};

export const getExtensionVersion = async (): Promise<{
    hasExtension: boolean;
    isVersionSupported: boolean;
    currentVersion?: string | undefined;
    isLoggedIn: boolean | undefined;
}> => {
    const CE = getCEHandler();
    // handle for versions 1 and 2
    // later on CEHandler can be dropped
    if (!window.chrome?.runtime) {
        return { hasExtension: false, isVersionSupported: false, isLoggedIn: undefined };
    }
    try {
        const [extensionVersionResult, cookies] = await Promise.all([
            CE.sendMessageToRuntime('GET_EXTENSION_VERSION', {}) as Promise<any>,
            getLinkedinCookies(),
        ]);

        console.log('Extension version response: ', extensionVersionResult, 'isLoggedIn: ', Boolean(cookies?.li_at));
        const currentVersion =
            typeof extensionVersionResult === 'string' ? extensionVersionResult : extensionVersionResult?.version;
        const hasExtension = currentVersion ? true : false;
        const hasMinSupportedVersion = hasExtension ? isVersionGTE(currentVersion, minExtensionVersion) : false;
        return {
            hasExtension: hasExtension,
            isVersionSupported: hasMinSupportedVersion,
            currentVersion,
            isLoggedIn: Boolean(cookies?.li_at),
        };
    } catch (err) {
        console.warn('Received error from web extension', err);
        return { hasExtension: false, isVersionSupported: false, isLoggedIn: undefined };
    }
};
