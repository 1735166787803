import { Users } from 'lucide-react';
import { useSuggestionsFeed } from '../../../hooks/use-suggestions-feed';

export const AccountCardCompanySize = () => {
    const { currentAccountData: accountData } = useSuggestionsFeed();
    if (!accountData?.data?.size) return null;
    return (
        <div className="flex items-start gap-3">
            <Users className="h-5 w-5 stroke-[1]" />
            <span className="text-sm">{accountData?.data?.size}</span>
        </div>
    );
};
