import { GET_ALL_PROSPECT_SUBJECT_LINES, GetProspectMessagesHistoryQuery } from '@zaplify/graphql';
import { ChannelType } from '../../types/channel-type';
import { getZaplifySdk } from '@zaplify/sdk';
import { MessageDto } from '@zaplify/services/messaging/shared';
import { MessageNotSentError } from './use-messages';
import { MessageType } from '../../types/message-type';
import { useApolloClient } from '@apollo/client';

type Message = GetProspectMessagesHistoryQuery['Messages'][number];

export const useEmailMessages = () => {
    const client = useApolloClient();

    const sendEmailMessage = async (message: Message, suggestedChannelType: ChannelType) => {
        if (message.actionType !== MessageType.emailMessage) throw new Error('Invalid message type');

        const campaignsSdk = getZaplifySdk().profiles.campaigns;
        const outreachSuggestedAsEmail = suggestedChannelType === ChannelType.EMAIL;
        const prospectId = message.prospectId;
        try {
            let sentMessage: MessageDto;
            if (outreachSuggestedAsEmail) {
                if (!message.id) {
                    throw new Error('Message ID is missing on existing outreach suggestion email message');
                }
                sentMessage = await campaignsSdk.sendSuggestedEmailMessage(
                    message.id,
                    prospectId,
                    message.subjectLine,
                    message.content
                );
            } else {
                const newEmailMessage = {
                    prospectId,
                    subject: message.subjectLine,
                    content: message.content,
                };
                sentMessage = await campaignsSdk.sendNewEmailMessage(newEmailMessage);
            }

            message.externalMessageId = sentMessage.externalMessageId;

            client.refetchQueries({
                include: [GET_ALL_PROSPECT_SUBJECT_LINES],
            });
        } catch (error: any) {
            console.error(`sendEmailMessage error: ${JSON.stringify(error)} | message: ${JSON.stringify(message)}`);
            throw error.type === 'ClientPresentableException'
                ? new MessageNotSentError(error.message)
                : new MessageNotSentError(
                      'An error occurred when trying to send your message.',
                      message.prospectFullName,
                      {
                          cause: error,
                      }
                  );
        }
        console.log('Email message sent successfully: ' + JSON.stringify(message));
    };

    return {
        sendEmailMessage,
    };
};
