import { getZaplifySdk } from '@zaplify/sdk';
import { useDispatch } from 'react-redux-latest';
import { AppDispatch } from '../../../redux/store/configureStore';
import { handleErrorNotification, handleSuccessNotification } from '../../../redux/actions';
import { useProspectSync } from '../../../hooks/use-prospect-sync';
import { useShowContactsLimitModal } from '../../../hooks/use-show-contact-limit-modal';
import { useSeatsAndCredits } from '../../../new/hooks/use-seat-and-credits';
import { ContactSource } from '@zaplify/services/user-contacts/shared';

export const useAccount = () => {
    const dispatch = useDispatch<AppDispatch>();
    const profilesSdk = getZaplifySdk().profiles;
    const { syncProspect: syncProspectWithLinkedin } = useProspectSync();
    const { showContactLimitModal } = useShowContactsLimitModal();
    const { refetch: refetchCredits } = useSeatsAndCredits();

    const onAddPerson = async (personId: string, groupId?: string) => {
        try {
            let purchasedProspects: string[] = [];
            purchasedProspects = await profilesSdk.sources.purchasePersons(
                {
                    personIds: [personId],
                    linkedinUrls: [],
                },
                ContactSource.GlobalSearch,
                groupId
            );
            if (purchasedProspects.length === 0) {
                dispatch(handleErrorNotification(`Failed to add contact`, 5));
                return false;
            } else {
                dispatch(handleSuccessNotification(`Contact added`, 5));
                syncProspectWithLinkedin({ prospectId: purchasedProspects[0] });

                return true;
            }
        } catch (error: any) {
            console.error(`🚀 ~~~ useAccount | onAddPersonToCampaign | catch: ${JSON.stringify(error)}`);
            dispatch(handleErrorNotification(error.message || 'Failed to add person to campaign'));
            if (error.status === 403) {
                showContactLimitModal();
            }
        } finally {
            refetchCredits();
        }
    };

    return {
        onAddPerson,
    };
};
