import { Topics } from '@zaplify/common';
import { BillingEvent } from './billing.event';
import { ICustomCustomer } from '../../billing/customer.dto';

export class BillingCustomerDeletedEvent extends BillingEvent {
    public static override readonly TOPIC = Topics.BILLING;
    constructor(id: string, customerId: string, createdAt: Date, content: {}) {
        super(id, customerId, createdAt, content);
    }
}
