import { ChannelType } from '@zaplify/channel-accounts/shared';
import { WorkflowDto } from '../workflow';
import { ProspectStatus, ProspectSubStatus } from './prospect-status';

export enum CampaignType {
    AUTO = 'AUTO',
    SEMI_AUTO_AI = 'SEMI_AUTO_AI',
}

export enum CampaignStatus {
    draft = 'DRAFT',
    pending = 'PENDING',
    ongoing = 'ONGOING',
    finished = 'FINISHED',
}

interface Statistcs {
    activeProspects: number;
    totalProspects: number;
}

export interface SourceRefDto {
    id: string;
}
export interface ProspectRef {
    id: string;
    status?: ProspectStatus;
    subStatuses: ProspectSubStatus[];
    launchedAt?: Date;
}

export class TargetGroupDto {
    personFilterId?: string;
    sources?: SourceRefDto[];
    prospects: ProspectRef[];

    constructor(dto: TargetGroupDto) {
        this.sources = dto.sources;
        this.personFilterId = dto.personFilterId;
        this.prospects = dto.prospects;
    }

    static fromDocument(dto: TargetGroupDto): TargetGroupDto {
        const targetGroup = new TargetGroupDto(dto);
        return targetGroup;
    }
}

export class CampaignDto {
    id: string;
    name: string;
    status: CampaignStatus;
    createdAt: Date;
    isArchived: boolean;
    isPaused: boolean;
    userId: string;
    userOrganizationId: string;
    targetGroup: TargetGroupDto;
    workflow: WorkflowDto;
    type?: CampaignType;

    constructor(dto: CampaignDto) {
        this.id = dto.id;
        this.name = dto.name;
        this.status = dto.status;
        this.createdAt = dto.createdAt;
        this.isArchived = dto.isArchived;
        this.isPaused = dto.isPaused;
        this.userId = dto.userId;
        this.userOrganizationId = dto.userOrganizationId;
        this.targetGroup = new TargetGroupDto(dto.targetGroup);
        this.workflow = new WorkflowDto(dto.workflow);
        this.type = dto.type ?? CampaignType.AUTO;
    }

    static fromDocument(dto: CampaignDto): CampaignDto {
        const campaign = new CampaignDto(dto);

        return campaign;
    }
}

/** @deprecated */
export class CampaignOverviewDto {
    id: string;
    name: string;
    status: CampaignStatus;
    createdAt: Date;
    isArchived: boolean;
    isPaused: boolean;
    userId: string;
    userOrganizationId: string;
    workflow: WorkflowDto;
    statistics: {
        activeProspects: number;
        totalProspects: number;
    };
    hasProspectsToReview: boolean;
    personFilterId?: string;
    type?: CampaignType;

    constructor(dto: CampaignOverviewDto) {
        this.id = dto.id;
        this.name = dto.name;
        this.status = dto.status;
        this.createdAt = dto.createdAt;
        this.isArchived = dto.isArchived;
        this.isPaused = dto.isPaused;
        this.userId = dto.userId;
        this.userOrganizationId = dto.userOrganizationId;
        this.workflow = dto.workflow;
        this.statistics = {
            activeProspects: dto.statistics.activeProspects,
            totalProspects: dto.statistics.totalProspects,
        };
        this.hasProspectsToReview = dto.hasProspectsToReview;
        this.personFilterId = dto.personFilterId;
        this.type = dto.type ?? CampaignType.AUTO;
    }

    static fromDocument(dto: CampaignOverviewDto): CampaignOverviewDto {
        const campaign = new CampaignOverviewDto(dto);
        return campaign;
    }
}

export class CampaignOverviewNewDto {
    id: string;
    name: string;
    status: CampaignStatus;
    createdAt: Date;
    isArchived: boolean;
    isPaused: boolean;
    userId: string;
    userOrganizationId: string;
    workflow: {
        isManual: boolean;
        channelTypes: ChannelType[];
    };
    statistics: {
        activeProspects: number;
        totalProspects: number;
    };
    hasProspectsToReview: boolean;

    constructor(dto: CampaignOverviewNewDto) {
        this.id = dto.id;
        this.name = dto.name;
        this.status = dto.status;
        this.createdAt = dto.createdAt;
        this.isArchived = dto.isArchived;
        this.isPaused = dto.isPaused;
        this.userId = dto.userId;
        this.userOrganizationId = dto.userOrganizationId;
        this.workflow = dto.workflow;
        this.statistics = {
            activeProspects: dto.statistics.activeProspects,
            totalProspects: dto.statistics.totalProspects,
        };
        this.hasProspectsToReview = dto.hasProspectsToReview;
    }

    static fromDocument(dto: CampaignOverviewNewDto): CampaignOverviewNewDto {
        const campaign = new CampaignOverviewNewDto(dto);
        return campaign;
    }
}
