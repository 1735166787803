import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: TaskStateType = {
    awaiting: {
        allTasks: [],
        dueTasks: [],
        overdueTasks: [],
    },
    prospectIdsWithUnresolvedTasks: [],
};

export interface TaskStateType {
    awaiting: {
        allTasks: any[];
        dueTasks: any[];
        overdueTasks: any[];
    };
    prospectIdsWithUnresolvedTasks: string[];
}
const taskSlice = createSlice({
    name: 'tasks',
    initialState: initialState as TaskStateType,
    reducers: {
        updateAwaitingTask(
            state,
            action: PayloadAction<{
                allTasks: any[];
                dueTasks: any[];
                overdueTasks: any[];
            }>
        ) {
            state.awaiting = action.payload;
        },
        updateUnresolvedTasks(state, action: PayloadAction<{ prospectIdsWithUnresolvedTasks: string[] }>) {
            state.prospectIdsWithUnresolvedTasks = action.payload.prospectIdsWithUnresolvedTasks;
        },
    },
});

export const { updateAwaitingTask, updateUnresolvedTasks } = taskSlice.actions;

export const taskReducer = taskSlice.reducer;
