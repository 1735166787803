import { Route } from 'react-router-dom';
import { LoginPage } from '../../new/pages/auth/login-page';
import { ReferralPage } from '../../new/pages/auth/referral-page';
import RequireAuth from '../require-auth';
import { RegisterPage } from '../../new/pages/auth/register-page';
import { ConfirmEmailPage } from '../../new/pages/auth/confirm-email-page';
import { ResetPasswordPage } from '../../new/pages/auth/reset-password-page';
import { AuthPage } from '../../new/pages/auth/auth-page';
import { RootLayout } from '../../new/layouts/root-layout';
import { paths } from '../paths';
import { JoinOrganizationPage } from '../../new/pages/auth/join-organization-page';
import { NewWorkspacePage } from '../../new/pages/workspaces/new-workspace-page';
import { WorkspacesPage } from '../../new/pages/workspaces/workspaces-page';

export const newAuthRoutes = (
    <>
        <Route element={<RootLayout />}>
            <Route
                // errorElement={<ErrorPage />}
                element={<RequireAuth requiredUserState={'loggedout'} />}
            >
                <Route path={paths.NEW_LOGIN} element={<LoginPage />} />
                <Route path={paths.NEW_REGISTER} element={<RegisterPage />} />
                <Route path={paths.NEW_RESET_PASSWORD} element={<ResetPasswordPage />} />
                <Route path={paths.NEW_CONFIRM_PASSWORD_RESET} element={<ConfirmEmailPage />} />
            </Route>
            <Route
                // errorElement={<ErrorPage />}
                element={<RequireAuth requiredUserState={'loggedin_unverified'} />}
            >
                <Route path={paths.NEW_CONFIRM_EMAIL} element={<ConfirmEmailPage />} />
            </Route>

            <Route element={<RequireAuth requiredUserState={'loggedin_verified'} />}>
                <Route path={`${paths.WORKSPACES.NEW}`} element={<NewWorkspacePage />} />
            </Route>

            <Route element={<RequireAuth requiredUserState={'loggedin_has_users_or_invitations'} />}>
                <Route path={`${paths.WORKSPACES.ROOT}`} element={<WorkspacesPage />} />
                <Route path={`${paths.WORKSPACES.WORKSPACE_ID}`} element={<WorkspacesPage />} />
            </Route>

            {/* Public routes (possible to access both as logged in and logged out) */}
            <Route path={paths.NEW_REFERRAL + '/:userHandle'} element={<ReferralPage />} />
            <Route path={paths.AUTH} element={<AuthPage />} />
            <Route path={paths.NEW_JOIN_ORGANIZATION} element={<JoinOrganizationPage />} />
        </Route>
    </>
);
