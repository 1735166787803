import { styled } from '@mui/styles';
import { ReactNode } from 'react';

const PageContainerNew = styled(({ children, className }: { children?: ReactNode; className: string }) => (
    <div className={className}>
        <div>{children}</div>
    </div>
))(({ theme }) => ({
    width: '100%',
    display: 'flex',
    minHeight: '100vh',
    background: 'var(--grey-palette-grey-1, #F7F8FA)',
    '&.grey': {
        background: theme.palette.background.default,
    },
    '& > div': {
        display: 'flex',
        flexGrow: 1,
        maxWidth: '100%',
    },
}));

export const PageContainer = ({ children, className }: { children?: ReactNode; className?: string }) => {
    return <PageContainerNew className={className}>{children}</PageContainerNew>;
};

export const UnderTopBarContainerNew = styled('div')(({ informationBox }: any) => ({
    height: '100%',
}));

export const UnderTopBarContainer = ({ children }: { children?: ReactNode }) => {
    return <UnderTopBarContainerNew>{children}</UnderTopBarContainerNew>;
};
