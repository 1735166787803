import { useMutation } from '@tanstack/react-query';
import { Dispatch } from 'react';
import { clearLocalStorageKeys } from '../../../redux/actions';
import { SET_NOTIFICATION } from '../constants/state';
import { ConfirmEmailAction } from '../types/state';
import { getAuth } from 'firebase/auth';

const useDeleteFirebaseUser = ({ dispatch }: { dispatch: Dispatch<ConfirmEmailAction> }) => {
    const {
        mutateAsync: deleteFirebaseUser,
        status: deletingStatus,
        error: deletingError,
    } = useMutation({
        mutationFn: async () => {
            await getAuth().currentUser.delete();
            await getAuth().signOut();
            clearLocalStorageKeys();
        },
        onSuccess: (data) => {
            console.log(`🚀 [ConfirmEmail] deleteFirebaseUser > onSuccess: ${JSON.stringify(data)}`);
        },
        onError: (data) => {
            console.error(`🔥 [ConfirmEmail] deleteFirebaseUser > onError: ${JSON.stringify(data)}`);
            dispatch({
                type: SET_NOTIFICATION,
                payload: { isVisible: true, message: data.message, severity: 'error' },
            });
        },
    });

    return { deleteFirebaseUser, deletingStatus, deletingError };
};

export default useDeleteFirebaseUser;
