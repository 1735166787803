import { useState } from 'react';
import { motion } from 'motion/react';
import { Badge } from '@shadcn/ui/components/ui/badge';
import { MessageSquare, Zap, Clock, ArrowRight, Building2, MapPin, Briefcase } from 'lucide-react';
import { SignupDialog } from './signup-dialog';
import { Button } from '@shadcn/ui/components/ui/button';
import { IReferralPageDto } from '@zaplify/users/shared';
import { useAuth } from '../../../../new/providers/authentication-provider';
import { Link } from 'react-router-dom';
import { paths } from '../../../../routes/paths';

export default function ReferralHero({
    referralData,
    referrerName,
}: {
    referralData: IReferralPageDto;
    referrerName: string;
}) {
    const [dialogOpen, setDialogOpen] = useState(false);
    const { userData, linkedinData } = referralData;
    const fullName = `${userData.firstName} ${userData.lastName}`;
    const {
        authState: { isLoggedIn },
    } = useAuth();

    const benefits = [
        {
            icon: <MessageSquare className="h-5 w-5 text-blue-400" />,
            title: 'AI-Powered Messaging',
            description: 'Generate personalized messages that sound like you wrote them',
        },
        {
            icon: <Zap className="h-5 w-5 text-purple-400" />,
            title: 'Unified Inbox',
            description: 'Manage email, LinkedIn and notes all in one place',
        },
        {
            icon: <Clock className="h-5 w-5 text-teal-400" />,
            title: 'Smart Follow-ups',
            description: 'Never forget to follow up with important contacts',
        },
    ];

    const renderLoggedInView = () => (
        <>
            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6 }}
                className="flex flex-col items-center text-center mb-12"
            >
                <h1 className="text-3xl md:text-4xl font-bold mb-4 text-gray-900 dark:text-white">
                    Add{' '}
                    <span className="text-transparent bg-clip-text bg-gradient-to-r from-teal-500 to-blue-500">
                        {fullName}
                    </span>{' '}
                    to a playbook
                </h1>

                <p className="text-gray-800 dark:text-zinc-300 mb-2 max-w-2xl">
                    Engage with {userData.firstName} through Email, LinkedIn and meetings.
                </p>
            </motion.div>

            <div className="bg-white/90 dark:bg-zinc-900/70 backdrop-blur-sm rounded-xl border border-gray-200 dark:border-zinc-800 p-6 shadow-xl relative overflow-hidden mb-12">
                <div className="absolute top-0 left-0 w-full h-0.5 bg-gradient-to-r from-blue-500 via-purple-500 to-teal-500"></div>

                <div className="grid md:grid-cols-5 gap-6 items-center">
                    {/* Referrer section - 2 columns */}
                    <div className="md:col-span-2 flex items-center space-x-4 border-b md:border-b-0 md:border-r border-gray-200 dark:border-zinc-800 pb-4 md:pb-0 md:pr-6">
                        <div className="relative flex-shrink-0">
                            <motion.div
                                className="absolute inset-0 bg-gradient-to-br from-blue-500/30 to-teal-500/30 rounded-full blur-md"
                                animate={{ scale: [1, 1.05, 1], opacity: [0.5, 0.7, 0.5] }}
                                transition={{ duration: 3, repeat: Number.POSITIVE_INFINITY, ease: 'easeInOut' }}
                            />

                            {linkedinData?.profileImageUrl ? (
                                <img
                                    src={linkedinData.profileImageUrl || '/placeholder.svg'}
                                    alt={fullName}
                                    width={70}
                                    height={70}
                                    className="rounded-full border border-gray-300 dark:border-zinc-700 relative z-10"
                                />
                            ) : (
                                <div className="w-16 h-16 rounded-full bg-gradient-to-br from-blue-500/80 to-teal-500/80 flex items-center justify-center text-white text-xl font-bold relative z-10">
                                    {userData.firstName.charAt(0)}
                                    {userData.lastName.charAt(0)}
                                </div>
                            )}
                        </div>

                        <div className="text-left">
                            <h3 className="font-semibold text-lg text-gray-900 dark:text-white">{fullName}</h3>
                            {linkedinData?.experiences?.[0]?.companyName && (
                                <p className="text-gray-600 dark:text-zinc-400 text-sm line-clamp-2">
                                    {linkedinData.experiences[0].companyName}
                                </p>
                            )}
                            <div className="mt-2">
                                <Badge
                                    variant="outline"
                                    className="bg-blue-50 dark:bg-blue-500/10 text-blue-600 dark:text-blue-400 border-blue-200 dark:border-blue-500/20 text-xs"
                                >
                                    Andsend User
                                </Badge>
                            </div>
                        </div>
                    </div>

                    {/* LinkedIn Info section - 3 columns */}
                    <div className="md:col-span-3 text-left">
                        <div className="space-y-4">
                            {linkedinData?.experiences?.[0]?.companyName && (
                                <div className="flex items-center space-x-2">
                                    <Building2 className="h-4 w-4 text-gray-500" />
                                    <span className="text-gray-700 dark:text-gray-300">
                                        {linkedinData.experiences[0].companyName}
                                    </span>
                                </div>
                            )}
                            {linkedinData?.locationString && (
                                <div className="flex items-center space-x-2">
                                    <MapPin className="h-4 w-4 text-gray-500" />
                                    <span className="text-gray-700 dark:text-gray-300">
                                        {linkedinData.locationString}
                                    </span>
                                </div>
                            )}
                            {linkedinData?.headline && (
                                <div className="flex items-center space-x-2">
                                    <Briefcase className="h-4 w-4 text-gray-500" />
                                    <span className="text-gray-700 dark:text-gray-300">{linkedinData.headline}</span>
                                </div>
                            )}
                        </div>

                        <div className="mt-6">
                            <Link
                                to={`${paths.NEW.MESSAGES}?global-search=true&global-search-query=${encodeURIComponent(
                                    `https://www.linkedin.com/in/${linkedinData?.publicIdentifier}`
                                )}`}
                            >
                                <Button variant="default" className="w-full md:w-auto">
                                    Find {userData.firstName} on Andsend
                                    <ArrowRight className="ml-2 h-4 w-4 transition-transform group-hover:translate-x-1" />
                                </Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

    const renderLoggedOutView = () => (
        <>
            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6 }}
                className="flex flex-col items-center text-center mb-12"
            >
                <h1 className="text-3xl md:text-4xl font-bold mb-4 text-gray-900 dark:text-white">
                    <span className="text-transparent bg-clip-text bg-gradient-to-r from-teal-500 to-blue-500">
                        {fullName}
                    </span>{' '}
                    thinks you'll love Andsend
                </h1>

                <p className="text-gray-800 dark:text-zinc-300 mb-2 max-w-2xl">
                    Get your own AI-powered Customer Relationship Agent and transform how you communicate.
                </p>

                <p className="text-gray-600 dark:text-zinc-400 mb-6 max-w-2xl text-sm">
                    Join thousands of professionals who use Andsend to draft perfect messages, manage relationships, and
                    never miss a follow-up.
                </p>
            </motion.div>

            <div className="bg-white/90 dark:bg-zinc-900/70 backdrop-blur-sm rounded-xl border border-gray-200 dark:border-zinc-800 p-6 shadow-xl relative overflow-hidden mb-12">
                {/* Rest of the logged out view remains the same */}
                <div className="absolute top-0 left-0 w-full h-0.5 bg-gradient-to-r from-blue-500 via-purple-500 to-teal-500"></div>

                <div className="grid md:grid-cols-5 gap-6 items-center">
                    {/* Referrer section - 2 columns */}
                    <div className="md:col-span-2 flex items-center space-x-4 border-b md:border-b-0 md:border-r border-gray-200 dark:border-zinc-800 pb-4 md:pb-0 md:pr-6">
                        <div className="relative flex-shrink-0">
                            <motion.div
                                className="absolute inset-0 bg-gradient-to-br from-blue-500/30 to-teal-500/30 rounded-full blur-md"
                                animate={{ scale: [1, 1.05, 1], opacity: [0.5, 0.7, 0.5] }}
                                transition={{ duration: 3, repeat: Number.POSITIVE_INFINITY, ease: 'easeInOut' }}
                            />

                            {linkedinData?.profileImageUrl ? (
                                <img
                                    src={linkedinData.profileImageUrl || '/placeholder.svg'}
                                    alt={fullName}
                                    width={70}
                                    height={70}
                                    className="rounded-full border border-gray-300 dark:border-zinc-700 relative z-10"
                                />
                            ) : (
                                <div className="w-16 h-16 rounded-full bg-gradient-to-br from-blue-500/80 to-teal-500/80 flex items-center justify-center text-white text-xl font-bold relative z-10">
                                    {userData.firstName.charAt(0)}
                                    {userData.lastName.charAt(0)}
                                </div>
                            )}
                        </div>

                        <div className="text-left">
                            <h3 className="font-semibold text-lg text-gray-900 dark:text-white">{fullName}</h3>
                            {linkedinData?.experiences?.[0]?.companyName && (
                                <p className="text-gray-600 dark:text-zinc-400 text-sm line-clamp-2">
                                    {linkedinData.experiences[0].companyName}
                                </p>
                            )}
                            <div className="mt-2">
                                <Badge
                                    variant="outline"
                                    className="bg-blue-50 dark:bg-blue-500/10 text-blue-600 dark:text-blue-400 border-blue-200 dark:border-blue-500/20 text-xs"
                                >
                                    Andsend User
                                </Badge>
                            </div>
                        </div>
                    </div>

                    {/* Benefits section - 3 columns */}
                    <div className="md:col-span-3 text-left">
                        <h3 className="font-semibold text-lg text-gray-900 dark:text-white mb-3">
                            What you'll get with Andsend:
                        </h3>

                        <div className="space-y-3">
                            {benefits.map((benefit, index) => (
                                <motion.div
                                    key={index}
                                    initial={{ opacity: 0, x: 20 }}
                                    animate={{ opacity: 1, x: 0 }}
                                    transition={{ duration: 0.5, delay: 0.2 + index * 0.1 }}
                                    className="flex items-start"
                                >
                                    <div className="mr-3 mt-0.5 bg-gray-100 dark:bg-zinc-800 rounded-full p-1.5">
                                        {benefit.icon}
                                    </div>
                                    <div>
                                        <h4 className="font-medium text-gray-900 dark:text-white">{benefit.title}</h4>
                                        <p className="text-gray-600 dark:text-zinc-400 text-sm">
                                            {benefit.description}
                                        </p>
                                    </div>
                                </motion.div>
                            ))}
                        </div>

                        <div className="mt-5">
                            <Button
                                onClick={() => setDialogOpen(true)}
                                className="w-full md:w-auto group"
                                variant="default"
                            >
                                Get Your Free Account
                                <ArrowRight className="ml-2 h-4 w-4 transition-transform group-hover:translate-x-1" />
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

    return (
        <>
            <div className="max-w-4xl mx-auto">{isLoggedIn ? renderLoggedInView() : renderLoggedOutView()}</div>

            <SignupDialog open={dialogOpen} onOpenChange={setDialogOpen} referringUsersName={referrerName} />
        </>
    );
}
