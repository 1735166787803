import { useState } from 'react';
import { read, utils, WorkBook, WorkSheet } from 'xlsx';
import { DropResult } from '../components/file-upload';

const useFileUpload = () => {
    const [fileName, setFileName] = useState('');
    const [headers, setHeaders] = useState<string[]>([]);
    const [result, setResult] = useState<DropResult>([]);
    const [errors, setErrors] = useState<any[]>([]);

    const handleSingleFileDropAccepted = async (
        { file }: { file: File },
        dataValidator?: (data: { results: DropResult; headers: string[] }) => any[]
    ) => {
        const reader = new FileReader();
        const isFileUTF8Coded = await isUtf8(file);
        let results: DropResult = [];

        reader.readAsArrayBuffer(file);
        reader.onload = function (e) {
            if (!e.target) {
                console.log(`### Error reading file: no results`);
                return;
            }

            let opts = {
                type: 'binary',
                dense: true,
                ...(isFileUTF8Coded ? { codepage: 65001 } : {}),
            };
            //@ts-ignore
            const workbook: WorkBook = read(e.target.result, opts);

            results = utils
                .sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {
                    header: 1,
                    range: 0,
                })
                .filter((row) => {
                    return (row as any[]).length > 0;
                });

            // because of dense: true, we need to get the headers from the first row and map array of arrays to array of objects
            const tempHeaders = results[0] as string[];
            results.splice(0, 1);
            results = results.map((rowArr, idx) => {
                const obj: any = {};

                rowArr.forEach((cell, cellIdx) => {
                    obj[tempHeaders[cellIdx]] = cell;
                });

                return obj;
            });
            const errors = dataValidator ? dataValidator({ results, headers: tempHeaders }) : [];

            if (errors.length) {
                setErrors(errors);
                return;
            }

            setFileName(file.name);
            setHeaders(tempHeaders);
            setResult(results);
            setErrors([]);
        };
    };

    const getDataHeaders = (workSheet: WorkSheet) => {
        const headers: string[] = [];
        const range = utils.decode_range(workSheet['!ref' as string]);
        let C;
        let R = range.s.r; /* start in the first row */
        /* walk every column in the range */
        for (C = range.s.c; C <= range.e.c; ++C) {
            /* assigns cell value from "c" and "r" coordinates */
            const cell = workSheet[utils.encode_cell({ c: C, r: R })];

            let hdr = 'UNKNOWN ' + C;

            if (cell && cell.t) {
                hdr = utils.format_cell(cell);
            }

            headers.push(hdr);
        }

        return headers;
    };

    const isUtf8 = (filePart: File) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsText(filePart);
            fileReader.onload = (e) => {
                const str = e.target?.result as string;
                // roughly half
                const sampleStr = str?.slice(4, 4 + str?.length / 2);
                if (sampleStr.indexOf('�') === -1) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            };
            fileReader.onerror = () => {
                console.log(
                    "is utf-8 error - 'Failed to read the content of the file, please check whether the file is damaged'"
                );
                reject('Failed to read the content of the file, please check whether the file is damaged');
            };
        });
    };

    const handleReset = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setFileName('');
        setHeaders([]);
        setResult([]);
        e.stopPropagation();
    };

    return {
        handleSingleFileDropAccepted,
        getDataHeaders,
        isUtf8,
        handleReset,
        fileName,
        headers,
        result,
        errors,
    };
};

export default useFileUpload;
