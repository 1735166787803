import { ProspectSearchFilterState } from './types';
import { arrayLikeFilters, booleanLikeFilters, objectLikeFilters, stringLikeFilters } from './constants';
import { ProspectSearchFiltersDto } from '@zaplify/prospects';

export function getUnformattedProspectSearch(source: ProspectSearchFiltersDto) {
    const filters = source;
    return {
        //organization filters
        organizationKeywords: [],
        industries: filters.organizationFilter?.industries?.include || [],
        excludeIndustries: filters.organizationFilter?.industries?.exclude || [],
        organizations: getOrganizationArray(
            filters.organizationFilter?.organizations?.include,
            filters.organizationFilter?.organizationsExact?.include
        ),
        excludeOrganizations: getOrganizationArray(
            filters.organizationFilter?.organizations?.exclude,
            filters.organizationFilter?.organizationsExact?.exclude
        ),
        organizationDomains: filters.organizationFilter?.organizationDomains?.include || [],
        excludeOrganizationDomains: filters.organizationFilter?.organizationDomains?.exclude || [],
        organizationSizes: filters.organizationFilter?.organizationSizes?.include || [],
        //personal filters
        personalKeywords: filters.personalFilter?.keywords?.include || [],
        excludePersonalKeywords: filters.personalFilter?.keywords?.exclude || [],
        locations: filters.personalFilter?.locations?.include || [],
        excludeLocations: filters.personalFilter?.locations?.exclude || [],
        occupationTitles: filters.personalFilter?.occupationTitles?.include || [],
        excludeOccupationTitles: filters.personalFilter?.occupationTitles?.exclude || [],
        occupationFunctions: filters.personalFilter?.occupationFunctions?.include || [],
        excludeOccupationFunctions: filters.personalFilter?.occupationFunctions?.exclude || [],
        occupationSeniorities: filters.personalFilter?.occupationSeniorities?.include || [],
        excludeOccupationSeniorities: filters.personalFilter?.occupationSeniorities?.exclude || [],
        yearsInCurrentRole: {
            min: 0,
            max: 0,
        },
        options: filters.options,
    };
}

function getOrganizationArray(organizations, exactOrganizations) {
    const tempOrganizations = organizations || [];
    const tempExactOrganizations = exactOrganizations || [];
    return [...tempOrganizations, ...tempExactOrganizations];
}

export const isAnyFilterSelected = (prospectSearchFilters: ProspectSearchFilterState) => {
    let isSelected = false;

    // eslint-disable-next-line
    [...arrayLikeFilters, ...stringLikeFilters].some((filterName) => {
        const filterValue: string[] = prospectSearchFilters[filterName];
        if (filterValue.length > 0) {
            isSelected = true;
            return true;
        }
    });

    // eslint-disable-next-line
    !isSelected &&
        booleanLikeFilters.some((filterName) => {
            const filterValue: boolean = prospectSearchFilters[filterName];
            if (filterValue) {
                isSelected = true;
                return true;
            }
        });

    // eslint-disable-next-line
    !isSelected &&
        objectLikeFilters.some((filterName) => {
            const filterValue: { [key: string]: unknown } = prospectSearchFilters[filterName];

            if (Object.prototype.hasOwnProperty.call(filterValue, 'min')) {
                const minValue = filterValue['min'] as number;
                const maxValue = filterValue['min'] as number;

                if (minValue > 1 || (maxValue > 1 && maxValue <= 30)) {
                    isSelected = true;
                    return true;
                }
            }
        });

    return isSelected;
};
