export const getLocalStorageByKey = (key: string) => {
    return localStorage[key];
};

export const setLocalStorage = (key, value) => {
    if (key && value) {
        localStorage.setItem(key, value);
    }
};

export const clearLocalStorageByKey = (key) => localStorage.removeItem(key);

export const clearLocalStorage = () => localStorage.clear();
