import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../../routes/paths';
import { firebaseActionLink, redirectToPageWithCurrentParameters } from '../../../services/utils/urlHelper';
import { AuthPageLayout } from './components/auth-page-layout';
import { Skeleton } from '@shadcn/ui/components/ui/skeleton';

export const AuthPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const mode = firebaseActionLink.getCurrentMode();
        const actionCode = firebaseActionLink.getCurrentActionCode();

        if (mode && actionCode) {
            switch (mode) {
                case firebaseActionLink.verifyEmailModeName:
                    redirectToPageWithCurrentParameters(paths.NEW_CONFIRM_EMAIL, navigate);
                    break;
                case firebaseActionLink.resetPasswordModeName:
                    redirectToPageWithCurrentParameters(paths.NEW_RESET_PASSWORD, navigate);
                    break;
                default:
                    navigate(paths.NOT_FOUND, {
                        state: {
                            errorMessage: 'Invalid action link. Please try again or request a new link.',
                        },
                    });
                    break;
            }
        } else {
            navigate(paths.NOT_FOUND, {
                state: {
                    errorMessage: 'Invalid action link. Please try again or request a new link.',
                },
            });
        }
    }, [navigate]);

    return (
        <AuthPageLayout>
            <div className="flex flex-col items-center space-y-6 w-full max-w-md">
                <div className="w-full text-center">
                    <Skeleton className="h-8 w-48 mx-auto rounded-md mb-2" />
                    <Skeleton className="h-4 w-64 mx-auto rounded-md" />
                </div>
                <div className="w-full">
                    <Skeleton className="h-2 w-full rounded-full animate-pulse" />
                    <div className="text-center text-sm text-muted-foreground mt-2">
                        Redirecting you to the right page...
                    </div>
                </div>
            </div>
        </AuthPageLayout>
    );
};

export default AuthPage;
