import { useState, useEffect, useRef, FC, useMemo } from 'react';
import { Check, ArrowRight, ExternalLink } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@shadcn/ui/components/ui/button';
import { Alert, AlertDescription } from '@shadcn/ui/components/ui/alert';

import { useWebExtension } from '../../../hooks/use-web-extension';
import { isChromiumBasedBrowser, isMobileBrowser, sleep } from '../../../../services/utils/helpFunctions';
import { paths } from '../../../../routes/paths';
import { cn } from '@shadcn/ui/lib/utils';
import { LocalStorageKeys } from '../../../../config';
import { ChannelType } from '@zaplify/channel-accounts/shared';
import { ChannelProvider } from '@zaplify/channel-accounts/shared';
import { useUser } from '../../../hooks/use-user';
import { useSdk } from '../../../sdk';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useLinkedin } from '../../../hooks/use-linkedin';
import { useAuth } from '../../../providers/authentication-provider';
import { useOnboarding } from '../../../providers/onboarding-provider';
import { useFlags } from 'launchdarkly-react-client-sdk';

// Chrome extension ID - in real implementation, this should come from environment config
const chromeStoreAndsendId = 'nolanbablkmlhllilaloenjhaplnfhof';
const extensionId = chromeStoreAndsendId;

// Wait messages to show during connection process
const waitMessages = [
    'Connecting to LinkedIn...',
    'LinkedIn connection is still in progress...',
    'This is taking longer than expected. Try refreshing the LinkedIn tab in your browser.',
];

// Component type definitions
type LinkedInConnectionStatus =
    | 'connected'
    | 'notConnected'
    | 'connecting'
    | 'notAuthenticated'
    | 'notLoggedIn'
    | 'error'
    | 'loggedIn';

type ExtensionStatus = 'installed' | 'notInstalled' | 'outdated';

// Helper component for connection steps
interface ConnectionStepProps {
    label: string;
    subTitle?: string;
    isActive: boolean;
    isCompleted: boolean;
    button: React.ReactNode;
}

function convertCountryToLanguage(country: string | undefined): string {
    if (!country) return 'English';
    const countryMappings: Record<string, string> = {
        'United States': 'English',
        'United Kingdom': 'English',
        Germany: 'German',
        France: 'French',
        Spain: 'Spanish',
        Italy: 'Italian',
        Sweden: 'Swedish',
        Netherlands: 'Dutch',
        Belgium: 'Dutch',
        Denmark: 'Danish',
        Norway: 'Norwegian',
        Finland: 'Finnish',
        Poland: 'Polish',
        Portugal: 'Portuguese',
        Albania: 'Albanian',
        Bosnia: 'Bosnian',
        Bulgaria: 'Bulgarian',
        Croatia: 'Croatian',
        Czech: 'Czech',
        Estonia: 'Estonian',
        Philippines: 'Filipino',
        Hungary: 'Hungarian',
        Indonesia: 'Indonesian',
        Iceland: 'Icelandic',
        Japan: 'Japanese',
        Korea: 'Korean',
        Macedonia: 'Macedonian',
        Malaysia: 'Malay',
        China: 'Mandarin',
        Montenegro: 'Montenegrin',
        Romania: 'Romanian',
        Russia: 'Russian',
        Serbia: 'Serbian',
        Slovakia: 'Slovak',
        Slovenia: 'Slovenian',
        Thailand: 'Thai',
        Turkey: 'Turkish',
        Ukraine: 'Ukrainian',
        Vietnam: 'Vietnamese',
        India: 'English',
        Brazil: 'Portuguese',
        Argentina: 'Spanish',
        Chile: 'Spanish',
        Colombia: 'Spanish',
        Mexico: 'Spanish',
    };

    return countryMappings[country] || 'English';
}

const ConnectionStep = ({ label, subTitle, isActive, isCompleted, button }: ConnectionStepProps) => {
    return (
        <div className="flex gap-5">
            <div className="flex flex-col items-center"></div>
            <div className="flex flex-col">
                <div>
                    <div className="flex items-center gap-2">
                        <div
                            className={cn('w-8 h-8 flex-shrink-0 rounded-full flex items-center justify-center', {
                                'bg-background-primary/20': isActive,
                            })}
                        >
                            <div
                                className={cn('w-5 h-5 rounded-full flex items-center justify-center', {
                                    'bg-background-brand-primary text-white': isCompleted,
                                    'bg-background-brand-primary': isActive && !isCompleted,
                                    'bg-white border border-muted': !isActive && !isCompleted,
                                })}
                            >
                                {isCompleted ? (
                                    <Check className="h-4 w-4 text-white" />
                                ) : (
                                    <div
                                        className={cn('w-2.5 h-2.5 rounded-full', {
                                            'bg-white': isActive,
                                            'bg-muted w-2 h-2': !isActive,
                                        })}
                                    />
                                )}
                            </div>
                        </div>
                        <h4
                            className={cn('font-medium', {
                                'text-foreground': isActive,
                                'text-muted-foreground': !isActive,
                            })}
                        >
                            {label}
                        </h4>
                    </div>
                    <div className="flex flex-col p-2 gap-1 ml-8">
                        {isActive && subTitle && <p className="text-sm text-muted-foreground mt-1">{subTitle}</p>}
                        {isActive && <div className="mt-1">{button}</div>}
                    </div>
                </div>
            </div>
        </div>
    );
};

// Mobile info component - shown when on mobile device
const MobileInfo = () => (
    <div className="max-w-md mx-auto p-6 text-center">
        <h1 className="text-xl font-semibold mb-4">Mobile Not Supported</h1>
        <p className="text-muted-foreground mb-4">
            Andsend requires the Chrome extension which is only available on desktop browsers. Please access Andsend
            from a desktop device with a Chromium-based browser.
        </p>
    </div>
);

// Chromium info component - shown when not using a Chromium browser
const ChromiumInfo = () => (
    <div className="max-w-md mx-auto p-6 text-center">
        <h1 className="text-xl font-semibold mb-4">Chromium Browser Required</h1>
        <p className="text-muted-foreground mb-4">
            Andsend requires a Chromium-based browser (Chrome, Edge, Brave, Opera, etc.) to use the LinkedIn
            integration. Please switch to a supported browser.
        </p>
    </div>
);

export const ConnectLinkedinPage: FC = () => {
    const navigate = useNavigate();
    const {
        channelAccount: { createLinkedinChannelAccount: createLinkedinChannelAccountFn, getMyChannelAccounts },
    } = useSdk();
    const [linkedInConnectionStatus, setLinkedInConnectionStatus] = useState<LinkedInConnectionStatus | null>(null);
    const [extensionStatus, setExtensionStatus] = useState<ExtensionStatus | null>('notInstalled');
    const [connectingText, setConnectingText] = useState<string | null>(null);
    const { mutateAsync: createLinkedinChannelAccount } = useMutation(createLinkedinChannelAccountFn());
    const { data: channelAccounts } = useQuery(getMyChannelAccounts());
    const linkedinChannelAccount = useMemo(
        () => channelAccounts?.find((account) => account.provider === ChannelProvider.LINKEDIN),
        [channelAccounts]
    );

    // Web extension hooks
    const { getLinkedinCookies, getMe, reloadExtension, getExtensionVersion } = useWebExtension();

    // Refs to track status between re-renders
    const linkedInConnectionStatusRef = useRef<LinkedInConnectionStatus | null>(null);
    const waitMessageIndexRef = useRef<number | null>(null);
    const connectingAttemptsRef = useRef(0);
    linkedInConnectionStatusRef.current = linkedInConnectionStatus;

    // Handle connecting to LinkedIn
    const handleConnect = async () => {
        try {
            setLinkedInConnectionStatus('connecting');
            connectingAttemptsRef.current += 1;
            console.log('Connecting to LinkedIn...');
            await sleep(2000);

            const myLinkedin = await getMe();
            console.log('Connecting to LinkedIn myLinkedin', myLinkedin);
            if (myLinkedin?.error) {
                setLinkedInConnectionStatus('notLoggedIn');
                console.error('Could not get connection from LinkedIn. Are you logged in to LinkedIn?', myLinkedin);
                reloadExtension();
                return;
            }

            if (!linkedinChannelAccount) {
                const linkedinCookies = await getLinkedinCookies();

                const linkedinChannelAccountDto = {
                    name: `${myLinkedin.firstName} ${myLinkedin.lastName}`,
                    LINKEDIN: {
                        sessionCookie: linkedinCookies.li_at?.value,
                        cookies: linkedinCookies.all,
                        userAgent: navigator.userAgent,
                        headline: myLinkedin.headline,
                        username: myLinkedin.profileUrl,
                        userId: myLinkedin.memberId,
                        isPremiumAccount: myLinkedin.isPremiumSubscriber,
                    },
                };
                await createLinkedinChannelAccount(linkedinChannelAccountDto);
            }
            setLinkedInConnectionStatus('connected');
        } catch (error) {
            console.error(`connectToLinkedIn | error: ${JSON.stringify(error)}`);

            if (connectingAttemptsRef.current === 1) {
                setLinkedInConnectionStatus('notConnected');
                console.log('connectToLinkedIn | error not showing on first attempt');
                return;
            }

            if (window?.analytics) {
                window.analytics.track('LinkedIn Cookie Error', {
                    location: 'addLinkedinChannelToDB()',
                    error: error,
                });
            }
            setLinkedInConnectionStatus('error');
        }
    };

    // Continue to AI preferences onboarding
    const handleContinue = () => {
        navigate(paths.NEW.ONBOARDING_PATHS.SETUP_PLAYBOOK);
    };

    // Check status of LinkedIn connection and extension installation
    useEffect(() => {
        const checkStatus = async () => {
            try {
                if (
                    linkedInConnectionStatusRef.current === 'connecting' ||
                    linkedInConnectionStatusRef.current === 'connected'
                )
                    return;

                // In a real implementation, we would check if the user already has a LinkedIn account
                // For now, we'll simulate checking the extension status

                const extensionVersion = await getExtensionVersion();
                const linkedinCookies = await getLinkedinCookies();

                const hasExtension = extensionVersion.hasExtension;
                setExtensionStatus(hasExtension ? 'installed' : 'notInstalled');

                if (!hasExtension) {
                    setLinkedInConnectionStatus('notConnected');
                    return;
                }

                if (!linkedinCookies?.li_at?.value) {
                    setLinkedInConnectionStatus('notLoggedIn');
                    return;
                } else {
                    setLinkedInConnectionStatus('notConnected');
                }

                // Auto-connect if all conditions are met
                if (linkedInConnectionStatusRef.current === 'notConnected') {
                    await handleConnect();
                }
            } catch (error) {
                console.error('Error checking status:', error);
                setLinkedInConnectionStatus('error');
            }
        };

        const interval = setInterval(checkStatus, 2000);

        return () => clearInterval(interval);
    }, []);

    // Handle displaying wait messages during connection
    useEffect(() => {
        let interval: NodeJS.Timeout;

        setConnectingText(null);
        if (linkedInConnectionStatus === 'connecting') {
            waitMessageIndexRef.current = 0;
            interval = setInterval(() => {
                const currentIndex = waitMessageIndexRef.current || 0;
                setConnectingText(waitMessages[currentIndex]);
                if (currentIndex < waitMessages.length - 1) {
                    waitMessageIndexRef.current = currentIndex + 1;
                }
            }, 8000);
        }

        return () => clearInterval(interval);
    }, [linkedInConnectionStatus]);

    // Show mobile info if on mobile device
    if (isMobileBrowser()) {
        return <MobileInfo />;
    }

    // Show browser info if not on Chromium-based browser
    if (!isChromiumBasedBrowser()) {
        return <ChromiumInfo />;
    }

    // Determine current step status
    type ConnectionStatus = 'install_extension' | 'login' | 'connect' | 'connecting' | 'error' | 'continue';

    let currentStatus: ConnectionStatus;
    if (linkedInConnectionStatus === 'connected') {
        currentStatus = 'continue';
    } else if (extensionStatus === 'notInstalled') {
        currentStatus = 'install_extension';
    } else if (linkedInConnectionStatus === 'notLoggedIn') {
        currentStatus = 'login';
    } else if (linkedInConnectionStatus === 'notConnected') {
        currentStatus = 'connect';
    } else if (linkedInConnectionStatus === 'connecting') {
        currentStatus = 'connecting';
    } else if (linkedInConnectionStatus === 'error') {
        currentStatus = 'error';
    } else {
        currentStatus = 'connect';
    }

    // Determine which steps are active
    const isOnStepOne = currentStatus === 'install_extension';
    const isOnStepTwo = currentStatus === 'login';
    const isOnStepThree = ['connect', 'connecting', 'error', 'continue'].includes(currentStatus);

    return (
        <div className="flex flex-col items-start justify-center h-screen max-w-3xl w-full p-16">
            <h1 className="text-3xl font-semibold tracking-tight mb-2">Connect LinkedIn</h1>
            <p className="text-base text-muted-foreground mb-6">
                Connect your LinkedIn account to Andsend using our Chrome extension.
            </p>

            <div className="flex flex-col gap-4 py-5">
                <ConnectionStep
                    label="Install Chrome extension"
                    subTitle="Start by installing our extension to establish the connection with LinkedIn."
                    isActive={isOnStepOne}
                    isCompleted={!isOnStepOne}
                    button={
                        isOnStepOne && (
                            <a
                                href={`https://chrome.google.com/webstore/detail/andsend/${extensionId}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="inline-block"
                            >
                                <Button variant="default" className="flex items-center gap-2">
                                    Install Chrome Extension
                                    <ExternalLink size={15} />
                                </Button>
                            </a>
                        )
                    }
                />

                <ConnectionStep
                    label="Log in to LinkedIn in your browser"
                    subTitle="You must be logged in to LinkedIn for the connection to work"
                    isActive={isOnStepTwo}
                    isCompleted={!isOnStepOne && !isOnStepTwo}
                    button={
                        currentStatus === 'login' ? (
                            <a
                                href="https://www.linkedin.com/login"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="inline-block"
                            >
                                <Button variant="default" className="flex items-center gap-2">
                                    Log in to LinkedIn
                                    <ExternalLink size={15} />
                                </Button>
                            </a>
                        ) : (
                            currentStatus === 'error' && (
                                <Alert variant="destructive" className="mt-2">
                                    <AlertDescription>
                                        There was an error connecting to LinkedIn. Please try again.
                                    </AlertDescription>
                                </Alert>
                            )
                        )
                    }
                />

                <ConnectionStep
                    label="Connect LinkedIn account to Andsend"
                    subTitle={
                        currentStatus === 'connecting'
                            ? 'Connecting your LinkedIn account to Andsend'
                            : 'Continue to Andsend and get started.'
                    }
                    isActive={isOnStepThree}
                    isCompleted={!isOnStepOne && !isOnStepTwo && currentStatus === 'continue'}
                    button={
                        currentStatus === 'connecting' ? (
                            <>
                                <Button disabled className="flex items-center gap-2">
                                    <div className="h-4 w-4 animate-spin rounded-full border-2 border-current border-t-transparent mr-2" />
                                    Connecting
                                </Button>
                                {Boolean(connectingText) && (
                                    <p className="text-sm text-muted-foreground mt-2">{connectingText}</p>
                                )}
                            </>
                        ) : currentStatus === 'connect' ? (
                            <Button onClick={handleConnect} className="flex items-center gap-2">
                                Connect
                            </Button>
                        ) : (
                            currentStatus === 'continue' && (
                                <Button onClick={handleContinue} className="flex items-center gap-2">
                                    Continue <ArrowRight size={15} className="ml-1" />
                                </Button>
                            )
                        )
                    }
                />
            </div>
        </div>
    );
};

export default ConnectLinkedinPage;
