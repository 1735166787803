// import { datadogRum } from '@datadog/browser-rum';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import LogRocket from 'logrocket';
import { ReactNode, useEffect } from 'react';
import { useAppSelector } from '../../redux/store/configureStore';
import { isImpersonatingAUser } from '../../helpers/is-global-admin';

const clearTraits = (traitsToClear: string[]) => {
    // Get stored traits
    const traits: Record<string, any> = JSON.parse(localStorage.getItem('ajs_user_traits') || '{}');

    // Remove the specific trait
    for (const traitToClear of traitsToClear) {
        if (traits.hasOwnProperty(traitToClear)) {
            delete traits[traitToClear];
        }
    }

    // Update local storage with the modified traits
    localStorage.setItem('ajs_user_traits', JSON.stringify(traits));
};

export const AnalyticsProvider = ({ children }: { children?: ReactNode }) => {
    const auth = useAppSelector((state) => state.user);
    const ldClient = useLDClient();

    useEffect(() => {
        (async () => {
            const isImpersonating = await isImpersonatingAUser();
            const user = auth?.zaplifyUser;
            if (!user?.id) {
                return;
            }
            if (isImpersonating) {
                window?.analytics?.reset();
            } else {
                // Apparently, this seems like the only way to get rid of all properties from the local cache
                clearTraits(['has_seat']);

                window?.analytics?.ready(() => {
                    window?.analytics?.identify(user.id, {
                        first_name: user.firstName,
                        last_name: user.lastName,
                        email: user.email,
                        location: user.location,
                        organization_id: user.userOrganizationId,
                        organization: user.userOrganization?.name,
                        created_at: user.createdAt,
                        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                        // @TODO Make this reliable. Often there's no seatId at the time of analytics being run, which messes up intercom, customer.io etc big time
                        // has_seat: Boolean(user?.currentSeat?.seatId),
                    });
                    if (user.userOrganization?.id) {
                        window?.analytics?.group(user.userOrganization.id, {
                            name: user.userOrganization?.name,
                            seats_available: user.userOrganization?.seatSummmary?.availableSeatsCount,
                            seats_total: user.userOrganization?.seatSummmary?.totalSeatsCoun,
                            seats_occupied: user.userOrganization?.seatSummmary?.occupiedSeatsCount,
                            deactivated_users_count: user.userOrganization?.seatSummmary?.deactivatedUsersCount,
                        });
                    }
                });
            }

            LogRocket?.identify(user.id, {
                first_name: user.firstName,
                last_name: user.lastName,
                email: user.email,
            });

            // datadogRum.setUser({
            //     id: user.id,
            //     fullName: `${user.firstName} ${user.lastName}`,
            //     email: user.email || 'unknown.doe@example.com',
            //     plan: auth?.subscription?.subscription?.current?.planName,
            // });

            ldClient?.identify(
                {
                    kind: 'user',
                    key: user.id,
                    name: user.firstName + ' ' + user.lastName,
                    email: user.email,
                    organization: user.userOrganization?.name,
                    organizationId: user.userOrganization?.id,
                    plan: auth?.subscription?.subscription?.current?.planName,
                    created_at: user.createdAt,
                    user_id: user.id,
                    // @TODO Check if can be removed and remove camelCase props below
                    createdAt: user.createdAt,
                    userId: user.id,
                },
                null,
                () => {
                    console.log("New user context's flags available");
                }
            );
        })();
    }, [auth.zaplifyUser]);

    return <>{children}</>;
};
