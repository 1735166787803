import { getZaplifySdk } from '@zaplify/sdk';
import { createMutationOptions } from './sdk-utils';
import { GroupDto } from '@zaplify/services/user-contacts/shared';

import {
    ICreateGroupWithAssistantSettingsInput,
    IUpdateGroupInput,
} from 'libs/sdk/src/lib/profiles/user-contacts/groups.sdk';
import { AssistantSettingsDto, GroupStatisticsDto } from '@zaplify/services/messaging/shared';
import { MutationOptions, UseQueryOptions } from '@tanstack/react-query';

export const getGroupByIdCacheKey = (groupId: string) => ['group', groupId];

export const createGroupWithAssistantSettings = () => {
    return createMutationOptions<GroupDto, Error, ICreateGroupWithAssistantSettingsInput>({
        mutationFn: (variables) => getZaplifySdk().profiles.groups.createGroupWithAssistantSettings(variables),
        invalidateQueries: (data) => [getGroupByIdCacheKey(data.id)],
    });
};

export const updateAssistantSettings = (
    options?: Omit<
        MutationOptions<
            void,
            Error,
            { assistantSettingsId: string; updatedAssistantSettings: Partial<AssistantSettingsDto> }
        >,
        'mutationFn' | 'invalidateQueries'
    >
) => {
    return createMutationOptions<
        void,
        Error,
        { assistantSettingsId: string; updatedAssistantSettings: Partial<AssistantSettingsDto> }
    >({
        mutationFn: (variables) =>
            getZaplifySdk().profiles.assistant.putAssistantSettings(
                variables.updatedAssistantSettings,
                variables.assistantSettingsId
            ),
        invalidateQueries: (_, variables) => [getGroupByIdCacheKey(variables.assistantSettingsId)],
        ...options,
    });
};

export const updateGroup = () => {
    return createMutationOptions<GroupDto, Error, IUpdateGroupInput>({
        mutationFn: (variables) => getZaplifySdk().profiles.groups.updateGroup(variables.id, variables.updatedGroup),
        invalidateQueries: (data) => [getGroupByIdCacheKey(data.id)],
    });
};

export const deleteGroup = () => {
    return createMutationOptions<void, Error, { groupId: string }>({
        mutationFn: (variables) => getZaplifySdk().profiles.groups.deleteGroup(variables.groupId),
        invalidateQueries: (_, variables) => [getGroupByIdCacheKey(variables.groupId)],
    });
};

const getStatisticsFn = async (input: { groupIds?: string[]; sinceTimestamp?: number }) => {
    return getZaplifySdk().profiles.groups.getStatistics(input);
};

type GetStatisticsData = Awaited<ReturnType<typeof getStatisticsFn>>;

export const getGroupStatistics = (
    input: { groupIds?: string[]; sinceTimestamp?: number },
    options?: Omit<UseQueryOptions<GetStatisticsData, Error>, 'queryKey' | 'queryFn'>
): UseQueryOptions<GetStatisticsData, Error> => {
    return {
        queryKey: ['getGroupStatistics', input.groupIds?.join(','), input.sinceTimestamp],
        queryFn: () => getStatisticsFn(input),
        ...options,
    };
};

export const generatePlaybookContext = () => {
    return createMutationOptions<string, Error, { currentContext: string; feedback: string }>({
        mutationFn: (variables) =>
            getZaplifySdk().profiles.groups.generatePlaybookContext(variables.currentContext, variables.feedback),
    });
};

const getSuggestTargetGroupAndPurposeFn = async () => {
    return getZaplifySdk().profiles.groups.suggestTargetGroupAndPurpose();
};

type GetSuggestTargetGroupAndPurposeData = Awaited<ReturnType<typeof getSuggestTargetGroupAndPurposeFn>>;

export const suggestTargetGroupAndPurpose = (
    options?: Omit<UseQueryOptions<GetSuggestTargetGroupAndPurposeData, Error>, 'queryKey' | 'queryFn'>
): UseQueryOptions<GetSuggestTargetGroupAndPurposeData, Error> => {
    return {
        queryKey: ['suggestTargetGroupAndPurpose'],
        queryFn: () => getSuggestTargetGroupAndPurposeFn(),
        ...options,
    };
};
