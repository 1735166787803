import { UpdateSettingsDto } from '@zaplify/pipedrive';
import { getZaplifySdk } from '@zaplify/sdk';
import { handleErrorNotification, setNotification, updateIntegrations } from '../../../redux/actions';
import {
    updateAvailableFields,
    updateFieldMapping,
    updateIntegrationStrategy,
    updateSalesforceActiveState,
    updateSaveStatus,
    updateSettings,
} from './slice';

export const getSalesforceSettings =
    (redirectToIntegration = false) =>
    async (dispatch, getState) => {
        const profilesSdk = getZaplifySdk().profiles;
        const user = getState().user.zaplifyUser;
        try {
            const salesforceSettings: any = await profilesSdk.salesforce.getSettings(user.userOrganization.id);
            dispatch(updateSettings(salesforceSettings));
            dispatch(updateSalesforceSaveStatus());
        } catch (error) {
            console.log('error fetching salesforce settings');
            if (redirectToIntegration) {
                window.location.replace(`/integrations`);
            } else {
                dispatch(
                    updateSettings({
                        account: {},
                        integrationScope: {},
                        fieldMapping: [],
                        isActive: false,
                        availableFields: {},
                        firstTime: false,
                    })
                );
            }
        } finally {
        }
    };

export const getSalesforceDefaultMapping = (integrationStrategy) => async (dispatch, getState) => {
    const profilesSdk = getZaplifySdk().profiles;
    const user = getState().user.zaplifyUser;
    try {
        const defaultFieldMapping: [] = await profilesSdk.salesforce.getDefaultFieldMapping(
            user.userOrganization.id,
            integrationStrategy
        );
        dispatch(updateFieldMapping(defaultFieldMapping));
        dispatch(updateSalesforceSaveStatus());
    } catch (error) {
        console.log('error fetching salesforce settings');
    } finally {
    }
};

export const resumeOrPauseSalesforce = (salesforceActiveState) => async (dispatch, getState) => {
    const profilesSdk = getZaplifySdk().profiles;
    const user = getState().user.zaplifyUser;
    try {
        if (salesforceActiveState) await profilesSdk.salesforce.resume(user.userOrganization.id);
        else await profilesSdk.salesforce.pause(user.userOrganization.id);
        await dispatch(updateSalesforceActiveState(salesforceActiveState));
    } catch (error) {
        console.log('error resume or pause');
    } finally {
        dispatch(updateIntegrations());
    }
};

export const disconnectSalesforce = () => async (dispatch, getState) => {
    const profilesSdk = getZaplifySdk().profiles;
    const user = getState().user.zaplifyUser;
    try {
        await profilesSdk.salesforce.disconnect(user.userOrganization.id);
        //Resetting salesforce state to initial
        dispatch(
            updateSettings({
                account: {},
                integrationScope: {},
                fieldMapping: [],
                isActive: false,
                availableFields: {},
                firstTime: false,
            })
        );

        dispatch(setNotification('Successfully disconnected your salesforce integration', 'success'));
    } catch (error) {
        console.log('error resume or pause');
    } finally {
        dispatch(updateIntegrations());
    }
};

export const getSalesforceFields =
    (integrationStrategy: string, showNotification: boolean = false) =>
    async (dispatch, getState) => {
        const profilesSdk = getZaplifySdk().profiles;
        const user = getState().user.zaplifyUser;
        try {
            const fields = await profilesSdk.salesforce.getAllFields(user.userOrganization.id, integrationStrategy);
            dispatch(updateAvailableFields(fields));
            if (showNotification) {
                dispatch(setNotification('Successfully reload salesforce fields', 'success'));
            }
        } catch (error) {
            console.log('error loading fields');
        } finally {
        }
    };

export const updateSalesforceSaveStatus = () => async (dispatch, getState) => {
    try {
        const salesforce = getState().salesforce;
        const fieldMapping = salesforce.fieldMapping;

        let saveStatus = true;

        fieldMapping.forEach((field) => {
            //Check source
            if (
                (field.source.type === '' && field.source.fieldName === '') ||
                (field.source.type === 'static' && field.source.value === '') ||
                (field.source.type === 'prospect' && field.source.fieldName === '') ||
                field.destination.fieldName === ''
            ) {
                saveStatus = false;
            }
        });
        dispatch(updateSaveStatus(saveStatus));
    } catch (error) {
        console.log('error loading fields');
    } finally {
    }
};

export const updateSalesforceSettings = () => async (dispatch, getState) => {
    const profilesSdk = getZaplifySdk().profiles;
    const user = getState().user.zaplifyUser;
    const salesforce = getState().salesforce;
    try {
        const fieldMapping = [...salesforce.fieldMapping];

        const updateSettingsObj = {
            organizationId: user.userOrganization.id,
            fieldMapping: fieldMapping,
            integrationScope: salesforce.integrationScope,
            isActive: salesforce.isActive,
            integrationStrategy: salesforce.selectedStrategy,
        };
        const errorCallback = (error) => {
            throw new Error(error);
        };
        await profilesSdk.salesforce.updateSettings(updateSettingsObj, errorCallback);
        const newSettings: UpdateSettingsDto = await profilesSdk.salesforce.getSettings(user.userOrganization.id);
        if (salesforce.firstTime) {
            await dispatch(resumeOrPauseSalesforce(true));
            dispatch(setNotification('Successfully started your salesforce integration', 'success'));
        } else {
            dispatch(setNotification('Successfully updated your salesforce settings', 'success'));
        }
        dispatch(updateFieldMapping(newSettings.fieldMapping));
        dispatch(updateIntegrationStrategy(salesforce.selectedStrategy));
        dispatch(updateSalesforceSaveStatus());
        window?.analytics?.track('User Updated Integration', {
            type: 'Salesforce',
        });
    } catch (error: any) {
        if (error.message) {
            const parseError = JSON.parse(error.message);
            dispatch(handleErrorNotification(parseError.data.message, 2000));
        } else {
            dispatch(setNotification("Couldn't update your salesforce settings", 'error'));
        }
        console.log('error loading fields');
    } finally {
        dispatch(updateIntegrations());
    }
};
