import { Button, CircularProgress } from '@mui/material';
import CategoryExpandShrinkStyled from './CategoryShowMore.styled';
import { ChevronDown } from 'lucide-react';

const CategoryExpandShrink = ({
    loading,
    numberOfRows,
    page,
    numberOfPages,
    onShowMore,
}: {
    loading: boolean;
    numberOfRows: number;
    page: number;
    numberOfPages: number;
    onShowMore: () => void;
}) => {
    return (
        <CategoryExpandShrinkStyled>
            {page < numberOfPages && (
                <Button className="show-more" variant="text" onClick={onShowMore} disabled={loading}>
                    {!loading && (
                        <>
                            <span>Show more</span>
                            <ChevronDown className="icon" size={20} />
                        </>
                    )}
                    {loading && <CircularProgress />}
                </Button>
            )}
        </CategoryExpandShrinkStyled>
    );
};

export default CategoryExpandShrink;
