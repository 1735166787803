import { IQuery } from '@zaplify/common';
import { Topics } from '@zaplify/common';
import { ReferralLinkDto } from '../referral-link/dtos';

export class GetReferralLinkQuery extends IQuery<ReferralLinkDto | null> {
    public static override readonly TOPIC = Topics.USERS;
    constructor(readonly referralLinkId: string) {
        super();
    }
}
