import { styled } from '@mui/styles';

export const TopContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    padding: '35px 52px 26px 72px',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #E4E6EB',
    '& > a > button': {
        border: '1px solid rgb(228, 230, 235)',
    },
    '& > div:nth-child(2)': {
        display: 'flex',
        alignItems: 'center',
        '& > div:nth-child(2)': {
            marginLeft: 10,
        },
        '& > div:nth-child(3)': {
            padding: '15px 20px',
            marginLeft: 20,
            border: '1px solid #E4E6EB',
            boxSizing: 'border-box',
            borderRadius: 8,
        },
    },
}));

export const FieldMapHeaderContainer = styled('div')(({ theme }) => ({
    '& .text-container': {
        padding: '0px 0px 25px 0px',
    },
    '& .logos-container': {
        display: 'flex',
        width: '100%',
        padding: '20px 40px',
        alignItems: 'center',
        justifyContent: 'space-between',
        boxSizing: 'border-box',
        maxWidth: 408,
    },
}));

export const ProgressBarContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
}));

export const RefreshFieldsContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& .refresh-body': {
        background: '#F7F8FA',
        borderRadius: '8px',
        padding: '15px',
    },
    '& .refresh-action-item': {
        marginTop: '16px',
    },
    '& .refresh-action-container': {
        cursor: 'pointer',
    },
    '& .refresh-action-text': {
        color: '#5F8EFA',
    },
}));

export const FieldMappingContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    borderRight: '1px solid #E4E6EB',
    padding: '26px 45px 30px 60px',
    width: '55%',
    boxSizing: 'border-box',
    '& .box-row': {
        marginBottom: 5,
        alignItems: 'center',
    },
    '& .refresh-container': {
        marginTop: '22px',
    },
}));
export const IntegrationNotCompletedModalContainer = styled('div')(({ theme }) => ({
    '& .modal-container': {
        position: 'absolute',
        width: '555px',
        height: 'auto',
        borderRadius: '8px',
        top: '50%',
        left: '50%',
        transform: `translate(-50%, -50%)`,
        backgroundColor: theme.palette.colors.white,
        '& .input-container': {
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
            padding: '50px 55px 27px 55px',
            '& .typography': {
                color: theme.palette.subline,
            },
            '& .title-small': {
                textAlign: 'center',
                marginBottom: 0,
                paddingBottom: '8px',
            },
        },
        '& .buttons-container': {
            display: 'flex',
            justifyContent: 'center',
            margin: '13px 0 21px 0',
            '& .button': {
                width: '205px',
                height: '45px',
                padding: '15px 35px',
                borderRadius: '40px',
                boxShadow: 'none',
                fontWeight: 500,
                fontSize: '15px',
            },
            '& .button-blue': {
                marginRight: '8px',
                background: theme.palette.colors.bluePale,
                color: theme.palette.colors.white,
                '&:hover': {
                    backgroundColor: theme.palette.colors.blue,
                },
            },
            '& .button-outlined-blue': {
                marginRight: '8px',
                border: `1px solid ${theme.palette.colors.bluePale}`,
                color: theme.palette.colors.bluePale,
            },
        },
    },
}));

export const SelectPlaceholderContainer = styled('div')(({ theme }) => ({
    '& .placeholder-item': {
        color: '#6B7985',
    },
}));

export const ConnectToSalesforceModalContainer = styled('div')(({ theme }) => ({
    '& .modal-container': {
        position: 'absolute',
        width: '555px',
        height: 'auto',
        borderRadius: '8px',
        top: '50%',
        left: '50%',
        transform: `translate(-50%, -50%)`,
        backgroundColor: theme.palette.colors.white,
        '& .input-container': {
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
            padding: '50px 55px 27px 55px',
            '& .close-icon': {
                position: 'absolute',
                top: '22px',
                right: '22px',
                '&:hover': {
                    backgroundColor: 'transparent',
                },
            },
            '& .title': {
                paddingBottom: '27px',
            },
            '& .margin-bottom': {
                marginBottom: 0,
            },
            '& .typography': {
                color: theme.palette.subline,
            },
            '& .title-small': {
                marginBottom: 0,
                paddingBottom: '8px',
            },
            '& .form-control': {
                width: '445px',
                marginBottom: '13px',
                '& .MuiSelect-select ': {
                    border: `0.8px solid ${theme.palette.colors.greyInformationLight}`,
                    borderRadius: '4px',
                    background: theme.palette.colors.white,
                },
                '& .MuiFilledInput-underline:before': {
                    border: 'none',
                },
                '& .MuiFilledInput-input': {
                    padding: '9px 0px 9px 8px',
                },
            },
            '& .warning': {
                display: 'flex',
                padding: '17px 25px',
                marginTop: '17px',
                borderRadius: '8px',
                background: theme.palette.colors.whiteDark,
                '& .icon-container': {
                    marginRight: '12px ',
                    '& .info-icon': {
                        fontSize: '22px',
                        color: theme.palette.colors.bluePale,
                    },
                },
            },
            '& .divider': {
                backgroundColor: theme.palette.colors.greyInformationLight,
            },
        },
        '& .header-container': {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        '& .buttons-container': {
            display: 'flex',
            justifyContent: 'flex-end',
            margin: '13px 43px 21px 0',
            '& .button': {
                width: ' 108px',
                height: '45px',
                padding: '15px 35px',
                borderRadius: '40px',
                boxShadow: 'none',
                fontWeight: 500,
                fontSize: '15px',
            },
            '& .button-blue': {
                marginRight: '8px',
                background: theme.palette.colors.bluePale,
                color: theme.palette.colors.white,
                '&:hover': {
                    backgroundColor: theme.palette.colors.blue,
                },
            },
            '& .button-outlined-blue': {
                marginRight: '8px',
                border: `1px solid ${theme.palette.colors.bluePale}`,
                color: theme.palette.colors.bluePale,
            },
            '& .button-longer': {
                width: '140px',
            },
            '& .disabled': {
                borderRadius: '100px',
                background: theme.palette.colors.greyInformationLight,
                color: theme.palette.colors.greyDark,
                fontWeight: 500,
            },
        },
    },
}));

export const ConfigurationContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: '26px 45px 30px 60px',
    width: '45%',
    boxSizing: 'border-box',
    '& > div': {
        '& > label': {
            marginTop: 10,
            '& > span:nth-child(2)': {
                fontSize: 13,
                color: '#3A4B59',
            },
        },
    },
}));

export const FormContainer = styled('div')(({ theme }) => ({
    width: '50%',
    maxWidth: '231px',
}));

export const LinkedInFormContainer = styled('div')(({ className, theme }) => ({
    width: '100%',
    padding: '2px 10px',
    maxWidth: 461,
    '&.linkedin-closed': {
        background: '#FFFFFF',
        border: '0.8px solid rgb(196,196,196)',
        boxSizing: 'border-box',
        borderRadius: 8,
    },
}));
