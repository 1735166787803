import { Alert, Box, Snackbar, Typography } from '@mui/material';
import { Copy } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux-latest';
import { LocalStorageKeys } from '../../../config';
import { cleanAuthError } from '../../../redux/actions';
import { AppDispatch } from '../../../redux/store/configureStore';
import { copyToClipboard } from '../../../services/utils/copyToClipboard';
import { isInAppBrowser } from '../../../services/utils/helpFunctions';
import AuthFormHeader from '../components/auth-form-header';
import RegisterForm from '../components/auth-register-form';
import DividerWithText from '../components/divider-with-text';
import GoogleAuthButton from '../components/google-auth-button';

export const Component = () => {
    const search = window.location.search;
    const urlParams = new URLSearchParams(search);
    const referralLinkFromQuery = urlParams.get('referralLinkId');
    const planFromQuery = urlParams.get('plan');
    const dispatch = useDispatch<AppDispatch>();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [notification, setNotification] = useState({
        isVisible: false,
        message: '',
        severity: null,
    });

    useEffect(() => {
        const didJustAcceptInvitation = urlParams.get('from-invitation') === 'true';
        dispatch(cleanAuthError());

        if (didJustAcceptInvitation) {
            setNotification((prevState) => {
                return {
                    ...prevState,
                    isVisible: true,
                    message: 'Invitation accepted! Login or register to join your team.',
                };
            });
        }
    }, [dispatch]);

    if (referralLinkFromQuery?.length > 0) {
        localStorage.setItem(LocalStorageKeys.REFERRAL_LINK, referralLinkFromQuery);
    }

    if (planFromQuery?.length > 0) {
        localStorage.setItem(LocalStorageKeys.PLAN, planFromQuery);
    }

    return (
        <Box
            sx={{
                padding: '16px',
                boxSizing: 'border-box',
                minWidth: '240px',
                maxWidth: '380px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                gap: '32px',
            }}
        >
            <AuthFormHeader type={'register'} />
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                }}
            >
                {!isInAppBrowser() && (
                    <GoogleAuthButton>
                        <Typography
                            component={'span'}
                            sx={{
                                fontSize: '14px',
                                fontWeight: 500,
                                lineHeight: '21px',
                                color: '#3A4B59',
                            }}
                        >
                            Register with Google
                        </Typography>
                    </GoogleAuthButton>
                )}
                {isInAppBrowser() && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: '8px',
                        }}
                    >
                        <Typography
                            component={'span'}
                            sx={{
                                fontSize: '14px',
                                fontWeight: 400,
                                lineHeight: '18px',
                                color: '#3A4B59',
                            }}
                        >
                            To continue with Google sign up, copy the link below and open it in default browser.
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                            }}
                        >
                            <Typography
                                component={'span'}
                                sx={{
                                    fontSize: '14px',
                                    fontWeight: 500,
                                    lineHeight: '18px',
                                    color: '#3A4B59',
                                }}
                            >
                                app.andsend.com
                            </Typography>
                            <Copy
                                size={20}
                                onClick={() => {
                                    copyToClipboard('app.andsend.com/register');
                                    setSnackbarOpen(true);
                                }}
                                style={{ color: '#6583EF' }}
                            />
                        </Box>
                    </Box>
                )}
                <DividerWithText>
                    <Typography color="textSecondary" paddingBottom={0.5}>
                        or
                    </Typography>
                </DividerWithText>
                <RegisterForm />
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    autoHideDuration={2000}
                    onClose={() => setSnackbarOpen(false)}
                    open={snackbarOpen}
                >
                    <Alert severity="success" color="success">
                        Copied to clipboard
                    </Alert>
                </Snackbar>
            </Box>
        </Box>
    );
};

Component.displayName = 'RegisterView';
