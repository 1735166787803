import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { PageContainer } from '../containers';
import { LoaderView } from '../../views/loader';

export const DefaultTemplate = (props) => {
    return (
        <Suspense fallback={<LoaderView />}>
            <PageContainer>
                <Outlet />
            </PageContainer>
        </Suspense>
    );
};
