import { useNavigate, useSearchParams } from 'react-router-dom';
import { paths } from '../../../routes/paths';
import { useEffect } from 'react';
import { useAuth } from '../../../new/providers/authentication-provider';
import { cacheInvitationFromURL } from '../../../new/functions/invitations-handling';
import { LoaderView } from '../../../new/components/loader';
import { useToast } from '@shadcn/ui/hooks/use-toast';

export function JoinOrganizationPage() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { authState } = useAuth();
    const { toast } = useToast();

    useEffect(() => {
        console.log('join organization page', { authState, searchParams });
        cacheInvitationFromURL(searchParams);

        if (authState.isLoggedIn && authState.nbrOfUserAccounts > 0) {
            navigate(paths.WORKSPACES.ROOT);
            toast({
                title: 'You have a new invite waiting 🎉',
                description: `Just hit the "Join Team" button to accept the invitation.`,
                variant: 'default',
                duration: 10000,
                action: (
                    <a
                        href={paths.WORKSPACES.ROOT}
                        className="underline underline-offset-4 hover:text-primary-foreground"
                    >
                        Can't see the button? Click here
                    </a>
                ),
            });
        } else if (authState.isLoggedIn && authState.nbrOfUserAccounts === 0) {
            navigate(paths.WORKSPACES.NEW);
            toast({
                title: 'An invite is waiting for you! 🎉',
                description: 'You are just a few steps away from getting started with Andsend',
                variant: 'default',
                duration: 10000,
            });
        } else {
            // Fallback to redirecting to register
            toast({
                title: 'An invite is waiting for you! 🎉',
                description: 'Login or register to join your team',
                variant: 'default',
                duration: 10000,
            });
            navigate(paths.NEW_REGISTER);
        }
    }, [navigate, authState, searchParams, toast]);

    return (
        <div className="flex items-center justify-center min-h-screen w-full">
            <LoaderView />
        </div>
    );
}

export default JoinOrganizationPage;
