import ChevronRight from '@mui/icons-material/ChevronRight';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { AddAudienceContainer } from './containers';

export const AddAudienceButton = (props) => {
    return (
        <AddAudienceContainer onClick={props.onClick} className={props.className}>
            {props.icon}
            <div>
                <Typography variant='h5'>{props.title}</Typography>
                <Typography variant='h5'>{props.description}</Typography>
            </div>
            <ChevronRight />
        </AddAudienceContainer>
    );
};

AddAudienceButton.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
    emphasize: PropTypes.bool,
};
