import { IQuery } from '@zaplify/common';
import { Topics } from '@zaplify/common';
import { IInvitationReduced, IUserAccount } from '../users';

export class GetAccountsAndInvitationsQuery extends IQuery<{
    users: IUserAccount[];
    invitations: IInvitationReduced[];
}> {
    public static override readonly TOPIC = Topics.USERS;
    constructor(
        readonly firebaseId: string,
        readonly option: {
            includeUserOrganization: boolean;
        } = { includeUserOrganization: true },
    ) {
        super();
    }
}
