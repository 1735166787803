import { useCallback, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getZaplifySdk } from '@zaplify/sdk';
import { InvitationDto, InvitationStatus, UserOrInvitationRowDto, UserRole, UserStatus } from '@zaplify/users/shared';
import { useSeatsAndCredits } from '../../../../hooks/use-seat-and-credits';

function isInvitation(user: UserOrInvitationRowDto | InvitationDto): user is InvitationDto {
    return (user as UserOrInvitationRowDto).invitationId !== undefined;
}

export interface UserToManage {
    userId: string;
    organizationId: string;
    firstName: string | null;
    fullName: string;
    initials: string;
    email: string;
    roles: UserRole[];
    creditsUsed: number;
    creditsLimit: number | null;
    status: UserStatus | InvitationStatus;
    hasSeat: boolean;
    invitationId: string | null;
}

export class UserToManageModel implements UserToManage {
    userId: string;
    organizationId: string;
    firstName: string | null;
    lastName: string | null;
    fullName: string;
    initials: string;
    email: string;
    roles: UserRole[];
    creditsUsed: number;
    creditsLimit: number | null;
    status: UserStatus | InvitationStatus;
    hasSeat: boolean;
    invitationId: string | null;

    constructor(user: UserOrInvitationRowDto | InvitationDto) {
        this.userId = user['userId'] || user['id'];
        this.organizationId = user['organizationId'];
        this.invitationId = user['invitationId'] || null;
        this.hasSeat = !!user['seatId'];
        this.email = user.email;

        this.firstName = user['firstName']?.trim() || null;
        this.lastName = user['lastName']?.trim() || null;
        this.fullName = this.firstName ? `${this.firstName} ${this.lastName}` : this.email;
        const initials = `${this.firstName ? this.firstName[0] : ''}${this.lastName ? this.lastName[0] : ''}`;
        this.initials = Boolean(initials) ? initials.toUpperCase() : user['email'][0].toUpperCase();
        this.roles = user['roles'] || [];
        const userCredits = user['credits'];
        this.creditsUsed = typeof userCredits?.creditsUsed === 'number' ? userCredits.creditsUsed : 0;
        this.creditsLimit =
            typeof userCredits?.periodLimit === 'number' && userCredits?.periodLimit > -1
                ? userCredits?.periodLimit
                : null;
        this.status = user.status;
    }
}

export type UsersModalType = 'activate' | 'deactivate' | 'set_as_admin' | 'set_as_member' | null;
export type ActionStatus = 'idle' | 'pending' | 'success' | 'error';
const zaplifySdk = getZaplifySdk();
export const useUsersManagement = ({
    organizationId,
    currentUserId,
}: {
    organizationId: string;
    currentUserId: string;
}) => {
    const [modalType, setModalType] = useState<UsersModalType>(null);
    const [userToManipulate, setUserToManipulate] = useState<UserToManage | null>(null);
    const [isLoadingRow, setIsLoadingRow] = useState<UserToManage | null>(null);
    const [usersToManage, setUsersToManage] = useState<UserToManage[]>([]);
    const [actionStatus, setActionStatus] = useState<ActionStatus>('idle');

    const { refetch: refetchCredits } = useSeatsAndCredits();

    const getUsersAndInvitationsByOrganization = useCallback(async (organizationId) => {
        const userOrganizationSdk = zaplifySdk.profiles.userOrganization;

        try {
            const res = await userOrganizationSdk.getUsersAndInvitationsByOrganization(organizationId);
            const { tempUsers, tempInvitations } = res.reduce(
                (acc, curr) => {
                    if (isInvitation(curr)) {
                        acc.tempInvitations.push(curr);
                    } else {
                        acc.tempUsers.push(curr);
                    }

                    return acc;
                },
                { tempInvitations: [] as InvitationDto[], tempUsers: [] as UserOrInvitationRowDto[] }
            );
            const tempUsersToManage = tempUsers.map((user) => new UserToManageModel(user));
            const tempInvitationsToManage = tempInvitations.map((invitation) => new UserToManageModel(invitation));
            setUsersToManage([...tempUsersToManage, ...tempInvitationsToManage]);
        } catch (error) {
            console.error('getUsers error', error);
            // dispatch(setNotification("Couldn't load users", 'error'));
        }
    }, []);

    const updateUserField = ({
        userId,
        fieldName,
        fieldValue,
    }: {
        userId: string;
        fieldName: string;
        fieldValue: any;
    }) => {
        const tempUsers = [...usersToManage];
        const user = tempUsers.find((user) => (user['userId'] || user['id']) === userId);
        if (!user) {
            return;
        }

        setUsersToManage(
            tempUsers.map((user) => {
                if (user['userId'] === userId) {
                    user[fieldName] = fieldValue;
                }
                return user;
            })
        );
    };

    const updateCreditsLimit = useCallback(
        async (user: UserToManage, creditsLimit: number | string) => {
            const userSdk = zaplifySdk.profiles.user;

            try {
                setActionStatus('pending');
                const creditLimitNumber = typeof creditsLimit === 'string' ? parseInt(creditsLimit) : creditsLimit;

                await userSdk.setUserCreditLimit(user.userId, creditLimitNumber);
                if (user.userId === currentUserId) {
                    refetchCredits();
                }

                updateUserField({
                    userId: user.userId,
                    fieldName: 'creditsLimit',
                    fieldValue: creditsLimit,
                });
                // dispatch(setNotification('User credit limit set', 'success'));
                setUserToManipulate(null);
                setActionStatus('idle');
            } catch (error) {
                console.log('error updateCreditsLimit');
                // dispatch(setNotification("Couldn't set user credit limit", 'error'));
                setActionStatus('idle');
            }
        },
        [usersToManage]
    );

    const updateUserStatus = useCallback(
        async ({ user, status }: { user: UserToManage; status: UserStatus }) => {
            const userSdk = zaplifySdk.profiles.user;

            try {
                setActionStatus('pending');
                if (status === UserStatus.ACTIVATED) {
                    await userSdk.activateUser(user.userId);
                }
                if (status === UserStatus.DEACTIVATED) {
                    await userSdk.deactivateUser(user.userId);
                }
                setModalType(null);
                setActionStatus('idle');

                // Refresh table after waiting for eventual consitency. (Workaround until we can use graphql subscribe here)
                setIsLoadingRow(user);
                setTimeout(() => {
                    getUsersAndInvitationsByOrganization(organizationId);
                    setIsLoadingRow(null);
                }, 4500);

                return true;
            } catch (error) {
                // dispatch(setNotification((error as any).message, 'error'));
                setActionStatus('idle');
                return false;
            }
        },
        [usersToManage]
    );

    const updateUserSeat = useCallback(
        async ({ user, action }: { user: UserToManage; action: 'assign' | 'unassign' }) => {
            const userSdk = zaplifySdk.profiles.user;

            try {
                setActionStatus('pending');
                if (action === 'assign') {
                    await userSdk.assignSeat(user.userId);
                }
                if (action === 'unassign') {
                    await userSdk.unassignSeat(user.userId);
                }
                setModalType(null);
                setActionStatus('idle');

                // Refresh table after waiting for eventual consitency. (Workaround until we can use graphql subscribe here)
                setIsLoadingRow(user);
                setTimeout(() => {
                    getUsersAndInvitationsByOrganization(organizationId);
                    setIsLoadingRow(null);
                }, 4500);

                return true;
            } catch (error) {
                // dispatch(setNotification((error as any).message, 'error'));
                setActionStatus('idle');
                return false;
            }
        },
        [usersToManage]
    );

    const cancelInvitation = useCallback(async (user: UserToManage) => {
        const zaplifySdk = getZaplifySdk();
        const userSdk = zaplifySdk.profiles.user;

        try {
            await userSdk.cancelInvitation(user.invitationId);
            getUsersAndInvitationsByOrganization(organizationId);
            // dispatch(setNotification(`${user.email} invitation cancelled`, 'success'));
            setModalType(null);
        } catch (error) {
            // dispatch(setNotification("Couldn't cancel invitation", 'error'));
        }
    }, []);

    const resendInvitation = useCallback(async (user: UserToManage) => {
        const zaplifySdk = getZaplifySdk();
        const userSdk = zaplifySdk.profiles.user;

        try {
            await userSdk.inviteUsers({
                emails: [user.email],
                role: user.roles[0],
                organizationId,
            });
            // dispatch(setNotification(`Invitation has been resent`, 'success'));
        } catch (error) {
            // dispatch(setNotification("Couldn't resend invitation", 'error'));
        }
    }, []);

    const inviteUsers = useCallback(async (emails: string[], role: UserRole, organizationId, getUsers = true) => {
        const userSdk = zaplifySdk.profiles.user;

        try {
            setActionStatus('pending');
            const { invited, notInvited } = await userSdk.inviteUsers({ emails, role, organizationId });

            if (!invited && !notInvited) {
                // dispatch(setNotification('Something went wrong', 'error'));
            }

            if (!!invited && invited.length > 0) {
                if (getUsers) getUsersAndInvitationsByOrganization(organizationId);

                // dispatch(setNotification(`Invitation sent for ${invited.map((e) => e.email).join(', ')}`, 'success'));
            }
            if (!!notInvited && notInvited.length > 0) {
                notInvited.forEach((e) => {
                    // dispatch(setNotification(`${e.error} | ${e.email}`, 'error'));
                });
            }

            setActionStatus('idle');
        } catch (error) {
            // dispatch(setNotification("Couldn't invite users", 'error'));
            setActionStatus('idle');
        }
    }, []);

    const updateUserRole = useCallback(async ({ user, role }: { user: UserToManage; role: UserRole }) => {
        const userSdk = zaplifySdk.profiles.user;

        try {
            setActionStatus('pending');
            if (user.invitationId) {
                await userSdk.updateInvitationRole(user.invitationId, role);
            } else {
                await userSdk.updateUserRole(user.userId, role);
            }
            setUserToManipulate(null);
            setModalType(null);
            getUsersAndInvitationsByOrganization(organizationId);
            setActionStatus('idle');
        } catch (error) {}
    }, []);

    return {
        usersToManage,
        actionStatus,
        isLoadingRow,
        getUsersAndInvitationsByOrganization,
        updateCreditsLimit,
        userToManipulate,
        setUserToManipulate,
        cancelInvitation,
        resendInvitation,
        inviteUsers,
        updateUserStatus,
        updateUserSeat,
        updateUserRole,
        modalType,
        setModalType,
    };
};
