import { FormHelperText } from '@mui/material';

type FeedbackMessageProps = {
    error: string;
    info?: string;
    sx?: any;
};

const FeedbackMessage = ({ error, info = '', ...props }: FeedbackMessageProps & React.HTMLAttributes<HTMLElement>) => {
    if (error) {
        return (
            <FormHelperText
                sx={{
                    paddingLeft: '10px',
                    fontSize: '13px',
                    color: '#6B7985',
                    lineHeight: '19px',
                    whiteSpace: 'normal',
                    ...props.sx,
                }}
                error={true}
                id={props.id}
            >
                {error.toString()}
            </FormHelperText>
        );
    }
    if (info) {
        return (
            <>
                {info?.length > 0 ? (
                    <FormHelperText
                        sx={{
                            paddingLeft: '10px',
                            fontSize: '13px',
                            color: '#6B7985',
                            lineHeight: '19px',
                            whiteSpace: 'normal',
                        }}
                    >
                        {info}
                    </FormHelperText>
                ) : null}
            </>
        );
    }

    return null;
};

export default FeedbackMessage;
