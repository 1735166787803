import { UseMutationOptions, UseQueryOptions, UseSuspenseQueryOptions } from '@tanstack/react-query';
import { ProspectDataDto, ProspectDataExperienceDto, ProspectDto } from '@zaplify/prospects';
import { getZaplifySdk } from '@zaplify/sdk';
import { createMutationOptions } from './sdk-utils';
import { ContactSource } from '@zaplify/services/user-contacts/shared';
import { getProspectOwnersCacheKey } from './prospect-owners.sdk';
import { getTotalUserContactsCountCacheKey } from './user-contacts.sdk';

export const getProspectByIdCacheKey = (id: string) => ['getProspectById', id];

const fetchProspectById = (prospectId: string) => {
    return getZaplifySdk().profiles.prospects.getProspect(prospectId);
};

type ProspectData = Awaited<ReturnType<typeof fetchProspectById>>;

export const getProspectById = (
    prospectId: string,
    options?: Omit<UseQueryOptions<ProspectData, Error>, 'queryKey' | 'queryFn'> &
        Omit<UseSuspenseQueryOptions<ProspectData, Error>, 'queryKey' | 'queryFn'>
): UseQueryOptions<ProspectData, Error> & UseSuspenseQueryOptions<ProspectData, Error> => {
    return {
        queryKey: getProspectByIdCacheKey(prospectId),
        queryFn: () => fetchProspectById(prospectId),
        enabled: !!prospectId,
        ...options,
    };
};

export const getProspectComposedByIdCacheKey = (id: string) => ['getProspectComposedById', id];

const fetchProspectComposedById = (prospectId: string) => {
    return getZaplifySdk().profiles.prospects.getProspectComposed(prospectId);
};

type ProspectComposedData = Awaited<ReturnType<typeof fetchProspectComposedById>>;

export const getProspectComposedById = (
    prospectId: string,
    options?: Omit<UseQueryOptions<ProspectComposedData, Error>, 'queryKey' | 'queryFn'> &
        Omit<UseSuspenseQueryOptions<ProspectComposedData, Error>, 'queryKey' | 'queryFn'>
): UseQueryOptions<ProspectComposedData, Error> & UseSuspenseQueryOptions<ProspectComposedData, Error> => {
    return {
        queryKey: getProspectComposedByIdCacheKey(prospectId),
        queryFn: () => fetchProspectComposedById(prospectId),
        enabled: !!prospectId,
        ...options,
    };
};

export const updateProspectData = () => {
    return createMutationOptions<ProspectDto, Error, { prospectId: string; prospectData: Partial<ProspectDataDto> }>({
        mutationFn: (variables) => getZaplifySdk().profiles.prospects.updateProspectData(variables),
        invalidateQueries: (data) => [
            getProspectByIdCacheKey(data.id),
            getInvalidateFindProspectData({ prospectIds: [data.id] }),
        ],
    });
};

export const selectProspectMainExperience = () => {
    return createMutationOptions<ProspectDto, Error, { prospectId: string; experience: ProspectDataExperienceDto }>({
        mutationFn: (variables) => getZaplifySdk().profiles.prospects.selectProspectMainExperience(variables),
        invalidateQueries: (data) => [getProspectByIdCacheKey(data.id)],
    });
};

export const purchaseProspects = (
    options?: Omit<
        UseMutationOptions<
            string[],
            Error,
            { personIds: string[]; linkedinUrls: string[]; groupId?: string; source: ContactSource },
            unknown
        >,
        'mutationFn'
    >
) => {
    return createMutationOptions<
        string[],
        Error,
        { personIds: string[]; linkedinUrls: string[]; groupId?: string; source: ContactSource }
    >({
        mutationFn: (variables) =>
            getZaplifySdk().profiles.sources.purchasePersons(
                { personIds: variables.personIds, linkedinUrls: variables.linkedinUrls },
                variables.source,
                variables.groupId
            ),
        invalidateQueries: (data) => [
            ...data.map((id) => getProspectByIdCacheKey(id)),
            ...data.map((id) => getProspectOwnersCacheKey(id)),
            getTotalUserContactsCountCacheKey(),
            ['getOwners'], // This will invalidate all getOwners queries
        ],
        ...options,
    });
};

const findProspectDataFn = <T extends keyof ProspectDataDto>(body: {
    prospectIds: string[];
    regex?: string[];
    sort?: any;
    page?: number;
    perPage?: number;
    includeFields: T[];
}) => {
    return getZaplifySdk().profiles.prospects.findProspectData<T>(body);
};
type findProspectDataData<T extends keyof ProspectDataDto> = Awaited<ReturnType<typeof findProspectDataFn<T>>>;
const findProspectDataCacheKey = (body: {
    prospectIds: string[];
    regex?: string[];
    sort?: any;
    page?: number;
    perPage?: number;
    includeFields: string[];
}) => {
    const { prospectIds, ...remainingFields } = body;
    return ['findProspectData', prospectIds, remainingFields] as const;
};
export const getInvalidateFindProspectData = (body: { prospectIds: string[] }) => {
    return {
        predicate: (query) => {
            if (query.queryKey[0] !== 'findProspectData') {
                return false;
            }
            const [_, queryProspectIds] = query.queryKey;
            return Array.isArray(queryProspectIds) && body.prospectIds.every((id) => queryProspectIds.includes(id));
        },
    };
};

export const findProspectData = <T extends keyof ProspectDataDto>(
    body: {
        prospectIds: string[];
        regex?: string[];
        sort?: any;
        page?: number;
        perPage?: number;
        includeFields: T[];
    },
    options?: Omit<UseQueryOptions<findProspectDataData<T>, Error>, 'queryKey' | 'queryFn'>
): UseQueryOptions<findProspectDataData<T>, Error> => {
    return {
        queryKey: findProspectDataCacheKey(body),
        queryFn: () => findProspectDataFn<T>(body),
        enabled: !!body.prospectIds,
        ...options,
    };
};

const runBulkImportFn = (
    prospectData: ProspectDataDto[],
    source: ContactSource,
    groupId?: string | 'default' | 'none',
    options?: { includeAssigned: boolean }
) => {
    return getZaplifySdk().profiles.sources.runBulkImport(prospectData, source, groupId, options);
};
type RunBulkImportData = Awaited<ReturnType<typeof runBulkImportFn>>;

export const runBulkImport = (): UseMutationOptions<
    RunBulkImportData,
    Error,
    { prospectData: ProspectDataDto[]; source: ContactSource; groupId?: string; options?: { includeAssigned: boolean } }
> => {
    return createMutationOptions<
        RunBulkImportData,
        Error,
        {
            prospectData: ProspectDataDto[];
            source: ContactSource;
            groupId?: string;
            options?: { includeAssigned: boolean };
        }
    >({
        mutationFn: (variables) =>
            runBulkImportFn(variables.prospectData, variables.source, variables.groupId, variables.options),
        invalidateQueries: (data) => [getTotalUserContactsCountCacheKey(), ['getOwners']],
    });
};
