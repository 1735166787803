import {
    GlobalSearchResponseDto,
    GlobalSearchPeopleInCompanyResultDto,
    GlobalSearchLinkedinProspectsResponseDto,
} from '@zaplify/prospects';
import { getZaplifySdk } from '@zaplify/sdk';
import { getLinkedInSlug, isValidLinkedInUrl } from '../../../helpers/linkedin';
import useLiConversationsImport from '../../../views/chats/hooks/use-li-conversations-import';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const useGlobalSearch = () => {
    const zaplifySdk = getZaplifySdk();
    const { globalSearchLinkedinUrl } = useFlags();
    const { fetchProfiles } = useLiConversationsImport();

    const search = async ({ match, page, perPage, searchIn }): Promise<GlobalSearchResponseDto> => {
        const searchSdk = zaplifySdk.profiles.search;
        const encodedSearchIn = encodeURIComponent(JSON.stringify(searchIn));
        const queryParams = new URLSearchParams({
            match: match.toLowerCase(),
            page: page.toString(),
            perPage: perPage.toString(),
            searchIn: encodedSearchIn,
        });
        const query = queryParams.toString();
        try {
            const results: GlobalSearchResponseDto = await searchSdk.globalSearch(query);
            return results;
        } catch (error) {
            return {
                campaigns: { data: [], pagination: { limit: perPage, numberOfPages: 1, page: 1 } },
                companies: { data: [], pagination: { limit: perPage, numberOfPages: 1, page: 1 } },
                prospectsFromLinkedin: { data: [], pagination: { limit: perPage, numberOfPages: 1, page: 1 } },
                prospects: { data: [], pagination: { limit: perPage, numberOfPages: 1, page: 1 } },
                accounts: { data: [], pagination: { limit: perPage, numberOfPages: 1, page: 1 } },
                people: { data: [], pagination: { limit: perPage, numberOfPages: 1, page: 1 } },
                companyNames: { data: [], pagination: { limit: perPage, numberOfPages: 1, page: 1 } },
                contacts: { data: [], pagination: { limit: perPage, numberOfPages: 1, page: 1 } },
            };
        }
    };

    const searchInCompany = async ({
        match,
        page,
        perPage,
        companyId,
    }): Promise<GlobalSearchPeopleInCompanyResultDto> => {
        const searchSdk = zaplifySdk.profiles.search;
        const queryParams = new URLSearchParams({
            match: match.toLowerCase(),
            companyId,
            page: page.toString(),
            perPage: perPage.toString(),
        });
        const query = queryParams.toString();
        try {
            const results: GlobalSearchPeopleInCompanyResultDto = await searchSdk.globalSearchInCompany(query);
            return results;
        } catch (error) {
            return {
                data: [],
                pagination: { limit: perPage, numberOfPages: 1, page: 1 },
            };
        }
    };

    const searchLinkedinProspect = async ({
        match,
        page,
        perPage,
    }): Promise<GlobalSearchLinkedinProspectsResponseDto> => {
        try {
            const isValidLinkedinUrl = isValidLinkedInUrl(match);
            if (!isValidLinkedinUrl) {
                return {
                    data: [],
                    pagination: { limit: perPage, numberOfPages: 1, page: 1 },
                };
            }
            const linkedinSlug = getLinkedInSlug(match);

            const profiles = await fetchProfiles([linkedinSlug]);

            const existingProspects = await getZaplifySdk().profiles.prospects.getByLinkedinUserIds(
                profiles?.map((p) => p.linkedinUserId)
            );
            const owners = await getZaplifySdk().profiles.prospects.getProspectsOwners(
                Object.values(existingProspects).map((p) => p.id)
            );

            const profilesWithOwners = profiles.map((profile) => {
                const prospect = existingProspects[profile.linkedinUserId];
                return {
                    ...profile,
                    owners: owners[prospect?.id] || [],
                };
            });

            return {
                data: profilesWithOwners,
                pagination: { limit: perPage, numberOfPages: 1, page: 1 },
            };
        } catch (error) {
            console.error('Failed to fetch linkedin prospects', error);
            return {
                data: [],
                pagination: { limit: perPage, numberOfPages: 1, page: 1 },
            };
        }
    };

    return {
        search,
        searchInCompany,
        searchLinkedinProspect,
    };
};
