import { styled } from '@mui/styles';

const LookingForSomethingStyled = styled('div')(({ theme: { palette, spacing } }: any) => ({
    margin: '12px',
    borderRadius: `27px`,
    padding: '48px 70px',
    backgroundColor: palette.background.default,
    textAlign: 'center',

    '& .header': {
        marginBottom: '8px',
        display: 'flex',
        justifyContent: 'center',
        gap: '8px',
        fontSize: '17px',
        fontWeight: '500',

        '& span[role=img]': {
            fontSize: '24px',
            lineHeight: '22px',
        },
    },
    '& .info': {
        fontSize: '14px',
        color: palette.colors.greyDark,
    },
}));

export default LookingForSomethingStyled;
