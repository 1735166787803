import { Button } from '@shadcn/ui/components/ui/button';
import { AndSendIcon } from '@shadcn/ui/components/icons/and-send/andsend-icon';
import { AndSendLoader } from '@shadcn/ui/components/icons/and-send-loader/andsend-loader';
import { Tooltip, TooltipContent, TooltipTrigger } from '@shadcn/ui/components/ui/tooltip';
import { useMessageComposer } from '../../../../hooks/messaging/use-message-composer';
import React from 'react';
import { SparklesAI } from './sparkles-ai';

interface RegenerateMessageButtonProps {}

export const RegenerateMessageButton = React.forwardRef<HTMLButtonElement, RegenerateMessageButtonProps>(
    (props, ref) => {
        const { isGeneratingMessage } = useMessageComposer();
        return (
            <Tooltip>
                <TooltipTrigger asChild>
                    <Button
                        ref={ref} // Attach the ref here
                        variant="outline"
                        size="icon-sm"
                        className="bg-gradient-to-r from-slate-200 to-white"
                        {...props}
                    >
                        <SparklesAI animate={isGeneratingMessage} width="25px" height="25px" />
                    </Button>
                </TooltipTrigger>
                <TooltipContent>
                    {isGeneratingMessage ? 'Generating message...' : 'Regenerate message...'}
                </TooltipContent>
            </Tooltip>
        );
    }
);

RegenerateMessageButton.displayName = 'RegenerateMessageButton';
