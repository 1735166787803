import { styled } from '@mui/styles';

const ProspectRowStyled = styled('div')(({ theme: { palette }, hoverable }: any) => ({
    marginBottom: '5px',
    borderRadius: '4px',
    padding: '5px 0px',
    paddingRight: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    ...(hoverable && {
        '&:hover': {
            backgroundColor: palette.colors.whiteDark,
            cursor: 'pointer',
        },
    }),
    '& .main': {
        display: 'flex',
        alignItems: 'center',
        gap: '12px',

        '& .avatar': {
            '& .MuiAvatar-root': {
                fontSize: '15px',
                fontWeight: '500',
            },
            // '& .MuiAvatar-colorDefault': {
            //     background: 'linear-gradient(180deg, #CDD1DA 0%, #B5B9C1 100%)',
            // },
        },
        '& .info': {
            '& .info-title': {
                marginBottom: '3px',
                display: 'flex',
                // gap: '5px',
                flexWrap: 'wrap',

                '& .full-name': {
                    marginRight: '8px',
                    fontSize: '16px',
                    fontWeight: '600',
                    color: palette.colors.black,

                    '& span': {
                        color: palette.colors.subline,
                        fontWeight: '400',
                    },
                },
                '& .icon': {
                    fontSize: '15px',
                    color: palette.colors.greyLighter,
                },
                '& .organization-name': {
                    fontSize: '14px',
                    lineHeight: '21px',
                    color: palette.colors.greyDark,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: 140,
                },
            },
            '& .info-subtitle': {
                fontSize: '13px',
                color: palette.colors.greyDark,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: 340,
            },
        },
    },
    '& .launch': {
        marginLeft: '15px',
        color: palette.colors.lightGrey,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        gap: '2px',
    },
    '& .owners': {
        marginLeft: '15px',
        color: palette.colors.lightGrey,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        gap: '2px',
    },
}));

export default ProspectRowStyled;
