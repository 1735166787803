import { Box } from '@mui/material';
import { LucidInfo } from '../../icons';
import { theme } from '../../theme';

export interface InfoBoxProps {
    children?: React.ReactNode;
}

const palette: any = theme.palette;

// TODO: remove
export function InfoBox({ children }: InfoBoxProps) {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'start',
                alignSelf: 'stretch',
                borderRadius: '14px',
                border: '1px solid rgba(95, 142, 250, 0.3)',
                backgroundColor: 'var(--Secondary-blue, #eff4fe)',
                gap: '12px',
                padding: '16px',
            }}
        >
            <LucidInfo fill='none' />
            <Box
                sx={{
                    textAlign: 'left',
                    color: palette.colors.black,
                    fontSize: '14px',
                    lineHeight: '21px',
                }}
            >
                {children}
            </Box>
        </Box>
    );
}

export default InfoBox;
