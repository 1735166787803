import { SubscriptionDetailsDto, SubscriptionDto } from '@zaplify/subscriptions';
import { getCreditsRefreshDate } from '../../services/utils/time-helper';
import { userActionTypes } from '../actionTypes';
import { authStates } from '../authStates';
import { ChannelAccountDto } from '@zaplify/channel-accounts/shared';

interface IUserState {
    currentState: string;
    firebaseUser: any;
    zaplifyUser: any;
    isLoadingData: boolean;
    authErrorMessage?: string | null;
    typeOfError?: string | null;
    refreshDate: string;
    preventRedirectToLogin: boolean;
    subscription: SubscriptionDetailsDto | {};
    channelAccounts: ChannelAccountDto[];
    isLoadingSubscription: boolean;
    createdFreeSubscription: boolean;
}

export const userInitialState: IUserState = {
    currentState: authStates.UNKNOWN,
    firebaseUser: {},
    zaplifyUser: {},
    isLoadingData: false,
    authErrorMessage: null,
    typeOfError: null,
    refreshDate: '',
    preventRedirectToLogin: false,
    subscription: {},
    channelAccounts: null,
    isLoadingSubscription: true,
    createdFreeSubscription: false,
};

const userReducer = (state = userInitialState, action) => {
    switch (action.type) {
        case userActionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                firebaseUser: action.firebaseUser.uid,
                authErrorMessage: null,
                typeOfError: null,
            };
        case userActionTypes.START_LOADING:
            return {
                ...state,
                isLoadingData: true,
            };
        case userActionTypes.STOP_LOADING:
            return {
                ...state,
                isLoadingData: false,
            };
        case userActionTypes.LOGIN_FAILED:
            return {
                ...state,
                firebaseUser: {},
                authErrorMessage: action.authErrorMessage,
            };
        case userActionTypes.FETCH_USER_SUCCESS:
            const refreshDateString = getCreditsRefreshDate(action.zaplifyUser?.credits?.renewalDate);
            return {
                ...state,
                zaplifyUser: action.zaplifyUser,
                authErrorMessage: null,
                refreshDate: refreshDateString,
            };
        case userActionTypes.FETCH_USER_FAILED:
            return {
                ...state,
                zaplifyUser: {},
                authErrorMessage: action.authErrorMessage,
            };
        case userActionTypes.LOGOUT_SUCCESS:
            return {
                ...state,
                zaplifyUser: {},
                firebaseUser: {},
                currentState: authStates.LOGGED_OUT,
                authErrorMessage: null,
            };
        case userActionTypes.PREVENT_REDIRECT_TO_LOGIN:
            return {
                ...state,
                preventRedirectToLogin: action.preventRedirectToLogin,
            };
        case userActionTypes.LOGOUT_FAILED:
            return {
                ...state,
                zaplifyUser: {},
                firebaseUser: {},
                currentState: authStates.LOGGED_OUT,
                authErrorMessage: action.authErrorMessage,
            };
        case userActionTypes.REGISTER_SUCCESS:
            return {
                ...state,
                firebaseUser: action.firebaseUser.uid,
                authErrorMessage: null,
                typeOfError: null,
            };
        case userActionTypes.REGISTER_FAILED:
            return {
                ...state,
                firebaseUser: {},
                authErrorMessage: action.authErrorMessage,
                typeOfError: action.typeOfError,
            };
        case userActionTypes.AUTH_STATE:
            return {
                ...state,
                currentState: action.authState,
            };
        case userActionTypes.CLEAN_AUTH_ERROR:
            return {
                ...state,
                authErrorMessage: null,
                typeOfError: null,
            };
        case userActionTypes.UPDATE_APPS:
            return {
                ...state,
                zaplifyUser: {
                    ...state.zaplifyUser,
                    apps: {
                        ...state.zaplifyUser?.apps,
                        ...action.settings,
                    },
                },
            };
        case userActionTypes.SET_SUBSCRIPTION:
            return {
                ...state,
                subscription: {
                    ...action.payload,
                },
            };
        case userActionTypes.SET_CHANNEL_ACCOUNTS:
            return {
                ...state,
                channelAccounts: action.payload,
            };
        case userActionTypes.UPDATE_SUBSCRIPTION_LOADING:
            return {
                ...state,
                isLoadingSubscription: action.payload,
            };
        case userActionTypes.UPDATE_CREATED_FREE_SUBSCRIPTION:
            return {
                ...state,
                createdFreeSubscription: action.payload,
            };
        default:
            return state;
    }
};

export default userReducer;
