import { LinkedinConversation, ProspectDataWithConnectionStatus } from '@zaplify/sdk';
import { chunkArray } from '@zaplify/utils';
import { useLinkedin } from '../use-linkedin';
import { ProspectDataDto } from '@zaplify/prospects';
import { ConnectionStatus } from '@zaplify/campaigns';

function log(message: string) {
    console.log(`🚀 [useLiConversationsImport] | ${message}`);
}

export const useLiConversationsImport = () => {
    const {
        linkedinAccount: linkedinChannelAccount,
        getConversations,
        getLinkedInProfileFull,
        getMessagesFromConversation: getMessagesFromConversationFromExtension,
    } = useLinkedin();
    const linkedinMemberId = linkedinChannelAccount?.LINKEDIN?.userId;

    const getMessagesFromConversation = async (conversationId: string, numberOfMessages: number) => {
        const messages = await getMessagesFromConversationFromExtension({
            memberId: linkedinMemberId,
            conversationId: conversationId,
            deliveredAtBeforeTimestamp: Date.now(),
            countBefore: numberOfMessages,
            cacheStaleTime: 60_000,
        });
        return messages;
    };

    const getLiConversations = async (
        nextCursor?: string,
        keyword?: string,
        minPageSize: number = 10
    ): Promise<{ conversations: LinkedinConversation[]; nextCursor: string }> => {
        if (!linkedinMemberId) throw new Error('LinkedIn channel account not found');

        const res = await getConversations({
            memberId: linkedinMemberId,
            moreThan: minPageSize,
            nextCursor: nextCursor,
            keywords: keyword,
            cacheStaleTime: 60_000,
        });
        const newNextCursor = res.oldestConversationLastEventTime;
        const conversations = res.conversations.sort((a, b) => b.lastMessage.createdAt - a.lastMessage.createdAt);

        const uniqueMemberIds = new Set<string>();
        conversations.forEach((c) => uniqueMemberIds.add(c.prospect.memberId));
        const uniqueConversations = Array.from(uniqueMemberIds).map((memberId) =>
            conversations.find((c) => c.prospect.memberId === memberId)
        );
        log(
            `getLiConversations | got conversations: ${conversations?.length}, uniqueConversations: ${uniqueConversations?.length}, uniqueMemberIds: ${uniqueMemberIds?.size}`
        );
        return { conversations: uniqueConversations, nextCursor: newNextCursor };
    };

    const fetchProfiles = async (params: {
        memberIds: string[];
        slowFetch: boolean;
    }): Promise<{ prospectData: ProspectDataDto; connectionStatus: ConnectionStatus }[]> => {
        const { memberIds, slowFetch } = params;
        if (memberIds.length === 0) {
            return [];
        }

        const uniqueMemberIds = new Set<string>(memberIds);
        const results: { prospectData: ProspectDataDto; connectionStatus: ConnectionStatus }[] = [];
        // Work in batches of 10 to avoid rate limiting
        const batchSize = 10;

        const batches = chunkArray(Array.from(uniqueMemberIds), batchSize);
        for (const batch of batches) {
            await Promise.all(
                batch.map((memberId, index) =>
                    // Add a delay to "look" like normal behavior
                    new Promise((resolve) => setTimeout(resolve, index * (slowFetch ? 3000 : 200))).then(async () => {
                        try {
                            const profileResult = await getLinkedInProfileFull({ memberId });

                            if (!profileResult.success) {
                                console.error('Error fetching full LinkedIn profile', profileResult);
                                return;
                            }

                            results.push(profileResult);
                        } catch (error) {
                            console.error('Error fetching LinkedIn profile', error);
                        }
                    })
                )
            );
        }
        return results;
    };

    return {
        getMessagesFromConversation,
        getLiConversations,
        fetchProfiles,
    };
};
