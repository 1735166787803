import { Badge } from '@shadcn/ui/components/ui/badge';
import { ContactSuggestion } from '../types';
import { useSdk } from '../../../../../../sdk';
import { useQuery } from '@tanstack/react-query';
import { UserRoundPlus } from 'lucide-react';
import { cn } from '@shadcn/ui/lib/utils';

export const ConnectionStatusBadge = ({
    contactSuggestion,
    className,
}: {
    contactSuggestion: ContactSuggestion;
    className?: string;
}) => {
    const {
        linkedinConnections: { getConnection },
    } = useSdk();

    const publicIdentifier = contactSuggestion?.contactLinkedinUrl?.match(/\/in\/([^\/]+)/)?.[1]?.trim() || '';

    const { data: linkedinConnection } = useQuery(getConnection(publicIdentifier));

    if (!linkedinConnection || !publicIdentifier) {
        return null;
    }

    return (
        <Badge variant="success" className={cn('flex items-center p-1', className)}>
            <UserRoundPlus className="h-3 w-3" />
        </Badge>
    );
};
