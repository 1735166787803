import { Tooltip, Typography, styled } from '@mui/material';

/* eslint-disable-next-line */
interface ChannelCardProps {
    icon: React.ReactNode;
    title: string;
    subtitle?: string;
    children?: React.ReactNode;
}

export const ChannelCard = ({ icon, title, subtitle, children }: ChannelCardProps) => (
    <ChannelCardContainer className="channel-card">
        <div>{icon}</div>
        <Typography className="title">{title}</Typography>
        {subtitle && (
            <Tooltip title={subtitle} arrow>
                <Typography className="subtitle">{subtitle}</Typography>
            </Tooltip>
        )}
        {children}
    </ChannelCardContainer>
);

export default ChannelCard;

const ChannelCardContainer = styled('div')(({ theme: { palette } }: any) => ({
    border: '0.8px solid #E4E6EB',
    boxSizing: 'border-box',
    boxShadow: '0px 2px 6px rgb(112 144 176 / 7%)',
    position: 'relative',
    background: '#FFFFFF',
    borderRadius: 14,
    flex: 1,
    padding: 24,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '250px',
    minHeight: 220,
    maxHeight: 220,

    '& > div:first-child': {
        height: 65,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    '& > .title': {
        fontSize: 14,
        lineHeight: '21px',
    },
    '& > .subtitle': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    '& > span': {
        marginTop: 'auto',
    },
    '& > button': {
        marginTop: 'auto',
    },
    '& > a  > button': {
        marginTop: 20,
    },
    '& .check-circle-big': {
        fill: palette.state.success,
        margin: '34px auto auto',
    },
}));
