import { Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { batch, useDispatch } from 'react-redux-latest';
import { useNavigate } from 'react-router-dom';
import HubspotLogo from '../../components/icons/hubspot-logo.svg';
import PipedriveLogo from '../../components/icons/pipedrive-logo.svg';
import SalesforceLogo from '../../components/icons/salesforce-logo.svg';
import UpsalesLogo from '../../components/icons/upsales-logo.svg';
import { ConfirmationModal } from '../../components/molecules/confirmationModal';
import { SocketEvents } from '../../config';
import { useEffectOnce } from '../../hooks/use-effect-once';
import useCookie from '../../hooks/useCookie';
import { WebSocketContext } from '../../providers/websocket-provider';
import { disconnectHubspot, getHubspotSettings, resumeOrPauseHubspot } from '../../redux/ducks/hubspot/operations';
import {
    disconnectPipedrive,
    getPipedriveSettings,
    resumeOrPausePipedrive,
} from '../../redux/ducks/pipedrive/operations';
import {
    disconnectSalesforce,
    getSalesforceSettings,
    resumeOrPauseSalesforce,
} from '../../redux/ducks/salesforce/operations';
import { disconnectUpsales, getUpsalesSettings, resumeOrPauseUpsales } from '../../redux/ducks/upsales/operations';
import { updateUpsalesFirstTimeState } from '../../redux/ducks/upsales/slice';
import { AppDispatch, useAppSelector } from '../../redux/store/configureStore';
import { paths } from '../../routes/paths';
import { salesforceOAuthUrl } from './apps/salesforce/constants';
import { IntegrationsViewContainer } from './containers';
import { ActivatedAppCard, UnactivatedAppCard, UnactivatedAppCardSalesforce } from './elements';
import { companyNameCorrectlyCased } from '../../enums/company-name';

enum ConfirmationModalStates {
    Hidden = 0,
    Hubspot = 1,
    Pipedrive = 2,
    Salesforce = 3,
    Upsales = 4,
}

const IntegrationsView = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const [subscribe, unsubscribe] = useContext(WebSocketContext);
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(ConfirmationModalStates.Hidden);
    const hubspot = useAppSelector((state) => state.hubspot?.account?.portalId);
    const isHubspotActive = useAppSelector((state) => state.hubspot.isActive);
    const pipedrive = useAppSelector((state) => state.pipedrive.account.companyId);
    const isPipedriveActive = useAppSelector((state) => state.pipedrive.isActive);
    const salesforce = useAppSelector((state) => state.salesforce.account.organizationId);
    const isSalesforceActive = useAppSelector((state) => state.salesforce.isActive);
    const isSalesforceLoading = useAppSelector((state) => state.salesforce.isLoading);
    const upsales = useAppSelector((state) => state.upsales?.account?.customerId);
    const isUpsalesActive = useAppSelector((state) => state.upsales.isActive);

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            batch(() => {
                dispatch(getHubspotSettings());
                dispatch(getPipedriveSettings());
                dispatch(getSalesforceSettings());
                dispatch(getUpsalesSettings());
            });
        }

        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line
    }, []);

    useEffectOnce(() => {
        subscribe(SocketEvents.integration.UPSALES_ACCOUNT_CREATED, handleUpsalesAccountCreated);

        return () => {
            unsubscribe(SocketEvents.integration.UPSALES_ACCOUNT_CREATED, handleUpsalesAccountCreated);
        };
    });

    const handleUpsalesAccountCreated = () => {
        navigate(paths.INTEGRATIONS + '/' + 'upsales');
        dispatch(updateUpsalesFirstTimeState(true));
    };

    const closeModal = () => {
        setDisplayConfirmationModal(ConfirmationModalStates.Hidden);
    };

    const handleDeleteClick = (event, integration) => {
        event.cancelBubble = true;
        if (event.stopPropagation) event.stopPropagation();
        setDisplayConfirmationModal(integration);
    };

    const handleDisconnectHubspot = async (event) => {
        await dispatch(disconnectHubspot());
        dispatch(getHubspotSettings());
        closeModal();
    };

    const handleDisconnectPipedrive = async () => {
        await dispatch(disconnectPipedrive());
        dispatch(getPipedriveSettings());
        closeModal();
    };

    const handleDisconnectSalesforce = async () => {
        await dispatch(disconnectSalesforce());
        dispatch(getSalesforceSettings());
        closeModal();
    };

    const handleDisconnectUpsales = async () => {
        await dispatch(disconnectUpsales());
        dispatch(getUpsalesSettings());
        closeModal();
    };

    const handleActiveStateChange = (event) => {
        dispatch(resumeOrPauseHubspot(event.target.checked));
    };

    const handleUpsalesActiveStateChange = (event) => {
        dispatch(resumeOrPauseUpsales(event.target.checked));
    };

    const handlePipedriveStateChange = (event) => {
        dispatch(resumeOrPausePipedrive(event.target.checked));
    };

    const handleSalesforceActiveStateChange = (event) => {
        dispatch(resumeOrPauseSalesforce(event.target.checked));
    };

    const getDisconnectFunction = (displayConfirmationModal) => {
        switch (displayConfirmationModal) {
            case ConfirmationModalStates.Hubspot:
                return handleDisconnectHubspot;
            case ConfirmationModalStates.Pipedrive:
                return handleDisconnectPipedrive;
            case ConfirmationModalStates.Salesforce:
                return handleDisconnectSalesforce;
            case ConfirmationModalStates.Upsales:
                return handleDisconnectUpsales;
            default:
                return () => {};
        }
    };
    return (
        <IntegrationsViewContainer style={{ marginTop: 60 }}>
            <Typography variant="h3">Integrations</Typography>
            <Typography>
                {`Integrations enable you to connect your workflows and pass data between ${companyNameCorrectlyCased} and other apps.`}
            </Typography>
            <div>
                <Typography variant="h3">My integrations</Typography>
                <div>
                    {hubspot ? (
                        <ActivatedAppCard
                            dataCyEdit="edit-hubspot"
                            dataCyDelete="delete-hubspot"
                            dataCySwitch="switch-hubspot"
                            logo={HubspotLogo}
                            settingsPage="/integrations/hubspot"
                            checked={isHubspotActive || false}
                            switchOnChange={(e) => handleActiveStateChange(e)}
                            disconnect={(e) => handleDeleteClick(e, ConfirmationModalStates.Hubspot)}
                            title="Hubspot"
                            description={
                                isHubspotActive
                                    ? 'You’re currently syncing prospect data to Hubspot.'
                                    : 'No prospect data are currently syncing to Hubspot'
                            }
                        />
                    ) : (
                        <UnactivatedAppCard
                            logo={HubspotLogo}
                            description="CRM software to help you successfully track prospects and manage deals."
                            buttonText="Connect"
                            href={import.meta.env.VITE_HUBSPOT_AUTHENTICATION_URI}
                        />
                    )}
                    {salesforce ? (
                        <ActivatedAppCard
                            dataCyEdit="edit-salesforce"
                            dataCyDelete="delete-salesforce"
                            dataCySwitch="switch-salesforce"
                            logo={SalesforceLogo}
                            settingsPage="/integrations/salesforce"
                            checked={isSalesforceActive || false}
                            switchOnChange={(e) => handleSalesforceActiveStateChange(e)}
                            disconnect={(e) => handleDeleteClick(e, ConfirmationModalStates.Salesforce)}
                            title="Salesforce"
                            description={
                                isSalesforceActive
                                    ? 'You’re currently syncing prospect data to Salesforce.'
                                    : 'No prospect data are currently syncing to Salesforce'
                            }
                        />
                    ) : (
                        <UnactivatedAppCardSalesforce
                            isLoading={isSalesforceLoading}
                            logo={SalesforceLogo}
                            description="CRM software to help you successfully track prospects and manage deals."
                            buttonText="Connect"
                            oauthConfig={salesforceOAuthUrl}
                        />
                    )}
                    {pipedrive ? (
                        <ActivatedAppCard
                            dataCyEdit="edit-pipedrive"
                            dataCyDelete="delete-pipedrive"
                            dataCySwitch="switch-pipedrive"
                            logo={PipedriveLogo}
                            settingsPage="/integrations/pipedrive"
                            checked={isPipedriveActive || false}
                            switchOnChange={(e) => handlePipedriveStateChange(e)}
                            disconnect={(e) => handleDeleteClick(e, ConfirmationModalStates.Pipedrive)}
                            title="Pipedrive"
                            description={
                                isPipedriveActive
                                    ? 'You’re currently syncing prospect data to Pipedrive.'
                                    : 'No prospect data are currently syncing to Pipedrive'
                            }
                        />
                    ) : (
                        <UnactivatedAppCard
                            logo={PipedriveLogo}
                            description="CRM software to help you successfully track prospects and manage deals."
                            buttonText="Connect"
                            href={import.meta.env.VITE_PIPEDRIVE_AUTHENTICATION_URI}
                        />
                    )}
                    {upsales ? (
                        <ActivatedAppCard
                            dataCyEdit="edit-upsales"
                            dataCyDelete="delete-upsales"
                            dataCySwitch="switch-upsales"
                            logo={UpsalesLogo}
                            settingsPage="/integrations/upsales"
                            checked={isUpsalesActive || false}
                            switchOnChange={(e) => handleUpsalesActiveStateChange(e)}
                            disconnect={(e) => handleDeleteClick(e, ConfirmationModalStates.Upsales)}
                            title="Upsales"
                            description={
                                isUpsalesActive
                                    ? 'You’re currently syncing prospect data to Upsales.'
                                    : 'No prospect data are currently syncing to Upsales'
                            }
                        />
                    ) : (
                        <></>
                        // <UnactivatedAppCard
                        //     logo={UpsalesLogo}
                        //     description="CRM software to help you successfully track prospects and manage deals."
                        //     buttonText="Connect"
                        //     href={import.meta.env.VITE_UPSALES_AUTHENTICATION_URI}
                        // />
                    )}
                </div>
            </div>
            <ConfirmationModal
                isOpen={displayConfirmationModal !== ConfirmationModalStates.Hidden}
                title="Are you sure?"
                body={`If you delete this connection, ${companyNameCorrectlyCased} will stop syncing prospect data to your CRM.`}
                variant="greyRed"
                leftButtonText="Cancel"
                rightButtonText="Delete"
                closeModal={closeModal}
                leftButtonClick={closeModal}
                rightButtonClick={getDisconnectFunction(displayConfirmationModal)}
            />
        </IntegrationsViewContainer>
    );
};

export default IntegrationsView;
