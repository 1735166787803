import { Button } from '@mui/material';
import { styled } from '@mui/styles';
import { PlanSelectionState } from '../../atoms/selfService';

const PlanSelectButtonStyled = styled('div')(({ theme: { palette } }: any) => ({
    '& .button': {
        boxShadow: '0px 2px 6px rgba(112, 144, 176, 0.07)',
        borderRadius: 30,
        padding: 14,
        backgroundColor: palette.colors.bluePale,
        fontSize: 15,
        fontWeight: 500,

        '&.disabled': {
            backgroundColor: palette.colors.greyInformationLight,
            color: palette.colors.greyDark,
        },
    },
}));

const PackageSelect = ({
    state,
    onPlanSelect,
    dataCy,
}: {
    state: PlanSelectionState;
    onPlanSelect: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    dataCy?: string;
}) => {
    return (
        <PlanSelectButtonStyled>
            {state === 'active' && (
                <Button
                    data-cy={dataCy}
                    variant="contained"
                    color="primary"
                    className="button active"
                    fullWidth
                    onClick={onPlanSelect}
                >
                    Select plan
                </Button>
            )}
            {state === 'disabled' && (
                <Button className="button disabled" fullWidth disabled>
                    Current plan
                </Button>
            )}
            {state === 'error' ||
                (state === 'insufficient_seats' && (
                    <Button data-cy={dataCy} className="button disabled" fullWidth disabled>
                        Select plan
                    </Button>
                ))}
        </PlanSelectButtonStyled>
    );
};

export default PackageSelect;
