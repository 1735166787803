import { useCallback, useMemo, useState } from 'react';
import { DialogDescription, DialogContent, DialogTitle, Dialog, DialogHeader } from '@shadcn/ui/components/ui/dialog';
import { Input } from '@shadcn/ui/components/ui/input';
import { ContactSource } from '@zaplify/services/user-contacts/shared';
import { toast } from '@shadcn/ui/hooks/use-toast';
import { useSearchParamDialog } from '@shadcn/ui/components/search-param-dialog';
import { useGetContactOwners } from '../../hooks/use-contact-owners';
import { useAddContacts } from '../../hooks/use-add-contacts';
import { AddContactButton } from '../buttons/add-contact-button';

const isValidLinkedInUrl = (url: string) => {
    const trimmedUrl = url.trim();
    return /^https?:\/\/(www\.)?linkedin\.com\/in\/[a-zA-Z0-9-]+\/?$/.test(trimmedUrl);
};

const LINKEDIN_ADD_FROM_URL_PARAM = 'open-import-li-url';

export const useImportFromUrlDialog = () => {
    const { open, close, value } = useSearchParamDialog(LINKEDIN_ADD_FROM_URL_PARAM);

    return { openDialog: open, closeDialog: close, isOpen: !!value };
};

export const ImportFromUrlDialog = () => {
    const { closeDialog, isOpen } = useImportFromUrlDialog();
    const { addContacts } = useAddContacts();

    const { getIsContactOwnedByUser } = useGetContactOwners();

    const [url, setUrl] = useState('');
    const isValidUrl = useMemo(() => isValidLinkedInUrl(url.trim()), [url]);

    const closeModal = () => {
        setUrl('');
        closeDialog();
    };

    const validateIsAlreadyAdded = useCallback(async () => {
        if (!isValidUrl) return;
        const isOwned = await getIsContactOwnedByUser({
            linkedinUrl: url.trim(),
        });
        if (isOwned) {
            toast({
                title: 'Contact already added',
                description: 'This person has already been added to your contacts',
            });
            throw new Error('Contact already added');
        }
        return isOwned;
    }, [url, getIsContactOwnedByUser]);

    return (
        <Dialog open={isOpen} onOpenChange={closeDialog}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Add person from LinkedIn URL</DialogTitle>
                    <DialogDescription>Paste the LinkedIn URL of the person you want to add</DialogDescription>
                </DialogHeader>

                <div className="space-y-4">
                    <Input placeholder="LinkedIn URL" value={url} onChange={(e) => setUrl(e.target.value)} />
                </div>
                {!!url && !isValidUrl && (
                    <p className="text-xs text-red-500 py-0">
                        Please enter a valid LinkedIn profile URL to add a person
                    </p>
                )}
                <div className="flex justify-end space-x-2">
                    <AddContactButton
                        linkedinUrl={url}
                        source={ContactSource.LinkedinImport}
                        addToGroupId="default"
                        preAddValidation={validateIsAlreadyAdded}
                        onAdded={closeModal}
                    />
                </div>
            </DialogContent>
        </Dialog>
    );
};
