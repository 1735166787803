import { Tooltip, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Modal } from '@zaplify/frontend-common';
import { getZaplifySdk } from '@zaplify/sdk';
import { Button, Loader } from '@zaplify/ui';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ArrowRightToLine } from 'lucide-react';
import * as React from 'react';

export const TurnOnNewZaplifyButton = ({ openSidebar }: { openSidebar?: boolean }) => {
    const [displayModal, setDisplayModal] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const turnOnNewZaplifyWithoutCampaigns = async () => {
        try {
            setLoading(true);
            const sdk = getZaplifySdk().profiles;
            await sdk.campaigns.setupMessenger();
        } catch (e) {
            console.log('error turning on new zaplify', e);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {openSidebar ? (
                <ListItemButton onClick={() => setDisplayModal(true)}>
                    <ListItemText
                        primary={'Manage new Zaplify'}
                        sx={{ '& > .MuiTypography-root': { color: '#5F8EFA' } }}
                    />
                </ListItemButton>
            ) : (
                <Tooltip title={'Manage new Zaplify'} placement="right" arrow>
                    <span>
                        <Box
                            onClick={() => setDisplayModal(true)}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer',
                            }}
                        >
                            <ArrowRightToLine size={20} stroke="#6B7985" style={{ strokeWidth: '1px' }} />
                        </Box>
                    </span>
                </Tooltip>
            )}
            <Modal isOpen={displayModal} closeModal={() => setDisplayModal(false)}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '16px',
                        gap: '16px',
                    }}
                >
                    <Typography variant="h3">Turn on new Zaplify for this user</Typography>
                    {loading ? (
                        <Loader />
                    ) : (
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '8px',
                                flexDirection: 'column',
                            }}
                        >
                            <Button variant="secondary" onClick={turnOnNewZaplifyWithoutCampaigns}>
                                Turn on new Zaplify without campaigns
                            </Button>
                        </Box>
                    )}
                </Box>
            </Modal>
        </>
    );
};
