import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getZaplifySdk, MeGetResponse } from '@zaplify/sdk';
import { ChannelAccountDto } from '@zaplify/channel-accounts/shared';
import { useToast } from '@shadcn/ui/hooks/use-toast';
import { useSdk } from '../sdk';
import { useWebExtension } from './use-web-extension';
import { IAuthState, useAuth } from '../providers/authentication-provider';

type LinkedinReader = {
    linkedinAccount: ChannelAccountDto | null;
    extensionStatus: 'CONNECTED' | 'NOT_AUTHENTICATED' | 'MISSING_CONFIG' | 'LOADING' | 'NOT_INSTALLED' | 'RESTARTING';
    extensionVersion: string;
    isPremium: boolean;
    fetchLatestPremiumStatus: () => Promise<void>;
    getMemberId: () => string;
} & ReturnType<typeof useWebExtension>;

const LAST_RELOAD_EXTENSION_KEY = 'lastReloadExtensionTimestamp';
const LinkedinContext = createContext<LinkedinReader | undefined>(undefined);

export const syncAuthStateToExt = async (
    extAuth: ReturnType<typeof useWebExtension>['auth'],
    authState: IAuthState
) => {
    try {
        // return;
        console.log('TEST WEBEXT AUTH: Running...');

        const authUserApp = authState;
        const authUserExt: any = await extAuth.getCurrentState();

        console.log('TEST WEBEXT AUTH: auth states', { authState, authUserExt });

        if (authUserApp.isImpersonating || (!authUserApp.authUserId && authUserExt.authUserId)) {
            console.log('TEST WEBEXT AUTH: signing out...');
            await extAuth.logout();
        } else if (authUserApp.authUserId && authUserApp.authUserId !== authUserExt.authUserId) {
            console.log('TEST WEBEXT AUTH: signing out and re-signing in...');
            await extAuth.logout();
            const clientId = import.meta.env.VITE_ANDSEND_OAUTH_CLIENT_ID; // @TODO Rename to VITE_ANDSEND_OAUTH_CLIENT_ID
            const result = await getZaplifySdk().profiles.oauth.authorize(clientId);
            if (!result?.code) {
                console.error('Could not get custom token from api', result);
                throw new Error('Could not get custom token from api');
            }
            await extAuth.loginWithCustomToken(result.code);
        } else if (authUserApp.userId !== authUserExt.userId) {
            console.log('TEST WEBEXT AUTH: choosing organization...');
            await extAuth.chooseOrganization(authUserApp.userOrganizationId);
        }
        console.log('TEST WEBEXT AUTH: Ran successfully');
    } catch (err) {
        console.error('TEST WEBEXT AUTH: Error occurred', err);
    }
};

export function LinkedinProvider({ children }: { children: React.ReactNode }) {
    const [extensionStatus, setExtensionStatus] = React.useState<LinkedinReader['extensionStatus']>('LOADING');
    const [hasExtension, setHasExtension] = React.useState<boolean>(true);
    const [extensionVersion, setExtensionVersion] = React.useState<string>(undefined);
    const [isLoggedIn, setIsLoggedIn] = React.useState<boolean>(true);
    const [hasExtensionError, setHasExtensionError] = React.useState<boolean>(false);
    const [isPremium, setIsPremium] = React.useState<boolean>(false);
    const { getLinkedinCookies, getExtensionVersion, getMe, reloadExtension, auth: extAuth } = useWebExtension();
    const { toast } = useToast();
    const {
        channelAccount: {
            getMyChannelAccounts,
            setPremiumStatus: setPremiumStatusMutation,
            updateLinkedinAvatarUrl: updateLinkedinAvatarUrlMutation,
        },
    } = useSdk();
    const { authState } = useAuth();
    const { data: channelAccounts, isLoading: channelAccountsDataLoading } = useQuery(getMyChannelAccounts());
    const { mutate: updateChannelAccountAvatar } = useMutation(
        updateLinkedinAvatarUrlMutation({
            getCurrentData: async () => {
                return linkedinAccount;
            },
            isEqual: (current, variables) => {
                return current?.avatarUrl === variables.avatarUrl;
            },
        })
    );
    const { mutateAsync: setPremiumStatus } = useMutation(
        setPremiumStatusMutation({
            getCurrentData: async () => {
                return linkedinAccount;
            },
            isEqual: (current, variables) => current?.LINKEDIN?.isPremiumAccount === variables.isPremium,
        })
    );

    const linkedinAccount = useMemo(
        () => channelAccounts?.find((account) => account.provider === 'LINKEDIN'),
        [channelAccounts]
    );

    useEffect(() => {
        if (extensionStatus !== 'NOT_INSTALLED') {
            syncAuthStateToExt(extAuth, authState).catch((err) => {
                console.error('Error while trying to syncAuthStateToExt', err);
            });
        }
    }, [authState, extensionStatus]);

    useEffect(() => {
        if (linkedinAccount?.LINKEDIN?.isPremiumAccount) {
            setIsPremium(true);
        }
    }, [linkedinAccount]);

    async function checkCorrectLinkedinAccount() {
        const me = await getMe();
        updatePremiumStatus(me);
        if (me?.memberId && me?.memberId !== linkedinAccount.LINKEDIN.userId) {
            toast({
                title: 'LinkedIn account mismatch',
                description:
                    'You are logged in to a different LinkedIn account than the one connected to Andsend. Please log in to the correct account.',
                variant: 'error',
                duration: 30_000,
            });
        } else {
            if (me.profilePicture && me.profilePicture !== linkedinAccount?.avatarUrl) {
                console.log('Updating avatar url', me.profilePicture, linkedinAccount.id);
                updateChannelAccountAvatar({
                    linkedinChannelAccountId: linkedinAccount.id,
                    avatarUrl: me.profilePicture,
                });
            }
        }
    }
    useEffect(() => {
        if (!linkedinAccount?.LINKEDIN?.userId || extensionStatus !== 'CONNECTED') {
            return;
        }
        checkCorrectLinkedinAccount();
    }, [linkedinAccount?.LINKEDIN?.userId, extensionStatus]);

    async function fetchLatestPremiumStatus() {
        const me = await getMe();
        await updatePremiumStatus(me);
    }

    const updatePremiumStatus = async (me: MeGetResponse) => {
        console.log('~ updatePremiumStatus ~ me:', { me });
        const mePremiumStatus = me?.isPremiumSubscriber || false;
        if (mePremiumStatus != isPremium) {
            setIsPremium(mePremiumStatus);
            await setPremiumStatus({
                linkedinChannelAccountId: linkedinAccount.id,
                isPremium: mePremiumStatus,
            });
        }
    };

    useEffect(() => {
        async function defineChromeExtensionStatus({ log = false } = {}) {
            try {
                const { hasExtension: newHasExtension, currentVersion } = await getExtensionVersion();
                if (hasExtension !== newHasExtension) {
                    setHasExtension(newHasExtension);
                }
                if (extensionVersion !== currentVersion) {
                    setExtensionVersion(currentVersion);
                }
                log && console.log('chrome extension version: ', currentVersion);
                if (!newHasExtension) {
                    return;
                }

                const liCookie = await getLinkedinCookies();
                const newIsLoggedIn = Boolean(liCookie?.li_at);

                if (isLoggedIn !== newIsLoggedIn) {
                    setIsLoggedIn(newIsLoggedIn);
                }
            } catch (error) {
                console.log(`useEffect | defineChromeExtensionStatus | error: ${JSON.stringify(error)}`);
                if (!hasExtensionError) {
                    setHasExtensionError(true);
                }
            }
        }

        defineChromeExtensionStatus({ log: true });
        const interval = setInterval(defineChromeExtensionStatus, 5000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        let newStatus = extensionStatus;

        if (hasExtensionError) {
            newStatus = 'NOT_INSTALLED';
        } else if (!hasExtension) {
            newStatus = 'NOT_INSTALLED';
        } else if (channelAccountsDataLoading) {
            newStatus = 'LOADING';
        } else if (!linkedinAccount) {
            newStatus = 'MISSING_CONFIG';
        } else if (!isLoggedIn) {
            newStatus = 'NOT_AUTHENTICATED';
        } else {
            newStatus = 'CONNECTED';
        }

        if (newStatus !== extensionStatus) {
            setExtensionStatus(newStatus);
            console.log('🚀 ~ useEffect ~ connectionStatus:', newStatus);
        }
    }, [
        extensionStatus,
        hasExtension,
        isLoggedIn,
        hasExtensionError,
        linkedinAccount?.LINKEDIN?.userId,
        channelAccountsDataLoading,
    ]);

    const getMemberId = () => {
        if (extensionStatus !== 'CONNECTED') {
            throw new Error('Linkedin is not connected');
        }
        return linkedinAccount?.LINKEDIN?.userId || '';
    };

    return (
        <LinkedinContext.Provider
            value={{
                linkedinAccount,
                extensionStatus,
                extensionVersion,
                isPremium,
                fetchLatestPremiumStatus,
                getMemberId,
                ...useWebExtension(),
            }}
        >
            {children}
        </LinkedinContext.Provider>
    );
}

export function useLinkedin() {
    const context = useContext(LinkedinContext);
    if (context === undefined) {
        throw new Error('useLinkedin must be used within a LinkedinProvider');
    }
    return context;
}
