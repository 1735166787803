import React, { useCallback, useMemo } from 'react';
import {
    CalendarCheck,
    CalendarClock,
    CalendarPlus,
    Clock,
    Info,
    InfoIcon,
    Mail,
    PlayCircle,
    UserCog,
    UserRoundPlus,
} from 'lucide-react';
import { Button } from '@shadcn/ui/components/ui/button';
import { InfoBoxContainer, InfoBoxVariant } from './info-box-container';
import { DisabledPrimaryActionReason, useMessageComposer } from '../../../../../hooks/messaging/use-message-composer';
import { useSubscription } from '@apollo/client';
import {
    PROSPECT_ACTIONS_SUBSCRIPTION,
    PROSPECT_FUTURE_ACTIONS_SUBSCRIPTION,
    PROSPECT_UNSCHEDULED_ACTIONS_SUBSCRIPTION,
} from '@zaplify/graphql';
import { useLinkedin } from '../../../../../hooks/use-linkedin';
import { ChannelType } from '../../../../../types/channel-type';
import { extensionLinkURL } from '../../../../../constants/extension';
import { useNavigate } from 'react-router-dom';
import { useEditContactDetailsDialog } from '../../../../../components/dialogs/edit-contact-details-dialog';
import { useActions } from '../../../../../hooks/use-actions';
import { useSnoozeDialog } from '../../../../../components/dialogs/snooze-dialog';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@shadcn/ui/components/ui/tooltip';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

interface InfoBoxProps {}

export const MessageComposerInfoBox: React.FC<InfoBoxProps> = () => {
    const {
        prospectId,
        bodyLength,
        handleChannelTypeChange,
        handleShowSuggestedMessage,
        suggestedDiffersFromDraft,
        isSending,
        disabledPrimaryActionReason,
        loadingOutreachSuggestion: nextActionLoading,
    } = useMessageComposer();
    const { prospectIdsWithActions, actionsScheduledOnLtTimestamp, handleSnooze } = useActions();
    const { isPremium, fetchLatestPremiumStatus } = useLinkedin();
    const { openEditContactDetailsDialog } = useEditContactDetailsDialog();
    const navigate = useNavigate();
    const { openSnoozeDialog } = useSnoozeDialog();

    const { data: futureActionsData, loading: futureActionsLoading } = useSubscription(
        PROSPECT_FUTURE_ACTIONS_SUBSCRIPTION,
        {
            variables: { prospectId, scheduledOnBefore: actionsScheduledOnLtTimestamp },
            skip: !prospectId,
        }
    );

    const { data: unscheduledActionsData, loading: unscheduledActionsLoading } = useSubscription(
        PROSPECT_UNSCHEDULED_ACTIONS_SUBSCRIPTION,
        { variables: { prospectId }, skip: !prospectId }
    );

    const navigateToConnectChannelAccount = () => navigate('/new/account-management/apps');
    const handleShowActionNow = useCallback(() => {
        handleSnooze(prospectId, 0);
        handleShowSuggestedMessage();
    }, [handleSnooze, handleShowSuggestedMessage, prospectId]);

    const prospectHasActionNow = prospectIdsWithActions.includes(prospectId);
    const nextActionScheduledOn =
        futureActionsData?.OutreachSuggestions?.[0]?.scheduledOn &&
        dayjs.utc(futureActionsData?.OutreachSuggestions?.[0]?.scheduledOn).local();
    const nextActionMotivation = futureActionsData?.OutreachSuggestions?.[0]?.scheduledOnMotivation;
    const unscheduledAction = useMemo(
        () => unscheduledActionsData?.OutreachSuggestions?.[0],
        [unscheduledActionsData?.OutreachSuggestions?.[0]?.id]
    );
    const unscheduledActionMotivation = unscheduledAction?.scheduledOnMotivation;

    const hasLoaded = useMemo(() => {
        return !nextActionLoading && !futureActionsLoading && !unscheduledActionsLoading;
    }, [nextActionLoading, futureActionsLoading, unscheduledActionsLoading]);

    const showSuggestedMessage = () => {
        handleShowSuggestedMessage();
    };

    let variant: InfoBoxVariant = 'brand';
    let content: React.ReactNode | null = null;

    if (!hasLoaded) {
        return null;
    }

    if (isSending) {
        return null;
    }

    if (!prospectHasActionNow) {
        // No current action
        if (unscheduledAction) {
            variant = 'warning';
            content = (
                <>
                    <div className="flex items-center gap-2 flex-grow w-full">
                        <CalendarPlus className="h-5 w-5 stroke-[1] flex-shrink-0" />
                        <p>No follow-up scheduled</p>
                        <Tooltip delayDuration={500}>
                            <TooltipTrigger asChild>
                                <InfoIcon className="h-4 w-4 stroke-[1] flex-shrink-0" />
                            </TooltipTrigger>
                            <TooltipContent>
                                <div>
                                    {unscheduledActionMotivation && (
                                        <>
                                            Motivation: {unscheduledActionMotivation}
                                            <br />
                                            <br />
                                        </>
                                    )}
                                    If you receive a reply, a new Action will always be created instantly for you.
                                </div>
                            </TooltipContent>
                        </Tooltip>
                    </div>
                    <TooltipProvider>
                        <Tooltip delayDuration={300}>
                            <TooltipTrigger asChild>
                                <Button
                                    variant="outline"
                                    className="p-2 w-10 h-8"
                                    onClick={() => openSnoozeDialog({ prospectId })}
                                >
                                    <CalendarClock className="h-5 w-5 stroke-[1] flex-shrink-0" />
                                </Button>
                            </TooltipTrigger>
                            <TooltipContent>Schedule follow-up</TooltipContent>
                        </Tooltip>
                        <Tooltip delayDuration={300}>
                            <TooltipTrigger asChild>
                                <Button variant="outline" className="p-2 w-10 h-8" onClick={handleShowActionNow}>
                                    <PlayCircle className="h-5 w-5 stroke-[1] flex-shrink-0" />
                                </Button>
                            </TooltipTrigger>
                            <TooltipContent>Follow-up now</TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                </>
            );
        } else if (nextActionScheduledOn) {
            const diffInDays = nextActionScheduledOn.diff(dayjs.utc().local().startOf('day'), 'days');
            const nextActionScheduledOnString =
                diffInDays >= 7
                    ? nextActionScheduledOn.format('MMM D')
                    : diffInDays === 0
                    ? 'today'
                    : diffInDays === 1
                    ? 'tomorrow'
                    : `in ${diffInDays} days`;
            variant = 'success';
            content = (
                <>
                    <TooltipProvider>
                        <div className="flex items-center gap-2 flex-grow w-full">
                            <CalendarCheck className="h-5 w-5 stroke-[1] flex-shrink-0" />
                            <p>A follow up is scheduled {nextActionScheduledOnString}</p>
                            <Tooltip delayDuration={500}>
                                <TooltipTrigger asChild>
                                    <InfoIcon className="h-4 w-4 stroke-[1] flex-shrink-0" />
                                </TooltipTrigger>
                                <TooltipContent>
                                    <div>
                                        {nextActionMotivation && (
                                            <>
                                                Motivation: {nextActionMotivation}
                                                <br />
                                                <br />
                                            </>
                                        )}
                                        If you receive a reply, a new Action will always be created instantly for you.
                                    </div>
                                </TooltipContent>
                            </Tooltip>
                        </div>
                        <Tooltip delayDuration={300}>
                            <TooltipTrigger asChild>
                                <Button
                                    variant="outline"
                                    className="p-2 w-10 h-8"
                                    onClick={() => openSnoozeDialog({ prospectId })}
                                >
                                    <CalendarClock className=" h-5 w-5 stroke-[1] flex-shrink-0" />
                                </Button>
                            </TooltipTrigger>
                            <TooltipContent>Reschedule follow-up</TooltipContent>
                        </Tooltip>
                        <Tooltip delayDuration={300}>
                            <TooltipTrigger asChild>
                                <Button variant="outline" className="p-2 w-10 h-8" onClick={handleShowActionNow}>
                                    <PlayCircle className="h-5 w-5 stroke-[1] flex-shrink-0" />
                                </Button>
                            </TooltipTrigger>
                            <TooltipContent>Follow-up now</TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                </>
            );
        }
    } else if (suggestedDiffersFromDraft) {
        variant = 'brand';
        content = (
            <>
                <div className="flex items-center gap-2 flex-grow w-full">
                    <Info className="h-5 w-5 stroke-[1] flex-shrink-0" />
                    <p>There is a suggested message for this conversation</p>
                </div>
                <Button variant="outline" size="sm" onClick={() => showSuggestedMessage()}>
                    Show suggested message
                </Button>
            </>
        );
    }

    switch (disabledPrimaryActionReason) {
        case DisabledPrimaryActionReason.LINKEDIN_NOT_SUPPORTED_ON_MOBILE:
            variant = 'warning';
            content = (
                <>
                    <div className="flex items-center gap-2 flex-grow w-full">
                        <Info className="h-5 w-5 stroke-[1] flex-shrink-0" />
                        <p>LinkedIn messages are not supported on mobile</p>
                    </div>
                    <Button variant="outline" size="sm" onClick={() => handleChannelTypeChange(ChannelType.EMAIL)}>
                        Switch to email
                    </Button>
                </>
            );
            break;
        case DisabledPrimaryActionReason.EXTENSION_NOT_INSTALLED:
            variant = 'warning';
            content = (
                <>
                    <div className="flex items-center gap-2 flex-grow w-full">
                        <Info className="h-5 w-5 stroke-[1] flex-shrink-0" />
                        <p>Looks like the Andsend web extension isn't installed. Install to proceed.</p>
                    </div>
                    <Button variant="outline" size="sm" onClick={() => window.open(extensionLinkURL, '_blank')}>
                        Go to store
                    </Button>
                </>
            );
            break;
        case DisabledPrimaryActionReason.LINKEDIN_MISSING_CONFIG:
            variant = 'warning';
            content = (
                <>
                    <div className="flex items-center gap-2 flex-grow w-full">
                        <Info className="h-5 w-5 stroke-[1] flex-shrink-0" />
                        <p>Connect your LinkedIn account to enable LinkedIn messaging</p>
                    </div>
                    <Button variant="outline" size="sm" onClick={navigateToConnectChannelAccount}>
                        Connect LinkedIn
                    </Button>
                </>
            );
            break;
        case DisabledPrimaryActionReason.LINKEDIN_NOT_AUTHENTICATED:
            variant = 'warning';
            content = (
                <>
                    <div className="flex items-center gap-2 flex-grow w-full">
                        <Info className="h-5 w-5 stroke-[1] flex-shrink-0" />
                        <p>Please sign in to LinkedIn to continue.</p>
                    </div>
                </>
            );
            break;
        case DisabledPrimaryActionReason.RECIPIENT_MISSING_LINKEDIN_PROFILE:
            variant = 'blue';
            content = (
                <>
                    <div className="flex items-center gap-2 flex-grow w-full">
                        <UserCog className="h-5 w-5 stroke-[1] flex-shrink-0" />
                        <p>Contact is missing a valid LinkedIn URL. Add URL or switch to email.</p>
                    </div>
                    <Button variant="outline" size="sm" onClick={() => openEditContactDetailsDialog(prospectId)}>
                        Add URL
                    </Button>
                </>
            );
            break;
        case DisabledPrimaryActionReason.RECIPIENT_CONNECTION_PENDING:
            variant = 'blue';
            content = (
                <>
                    <div className="flex items-center gap-2 flex-grow w-full">
                        <Clock className="h-5 w-5 stroke-[1] flex-shrink-0" />
                        <p>You can't send LinkedIn messages until your connection request is accepted.</p>
                    </div>
                    <Button variant="outline" size="sm" onClick={() => handleChannelTypeChange(ChannelType.EMAIL)}>
                        Switch to email
                    </Button>
                </>
            );
            break;
        case DisabledPrimaryActionReason.MESSAGE_TOO_LONG:
            variant = 'blue';
            content = (
                <>
                    <div className="flex items-center gap-2 flex-grow w-full">
                        <UserRoundPlus className="h-5 w-5 stroke-[1] flex-shrink-0" />
                        <p>{`LinkedIn requires connection requests to be under ${
                            isPremium ? 300 : 200
                        } characters (${bodyLength}/${isPremium ? 300 : 200})`}</p>
                    </div>
                    {!isPremium && (
                        <Button variant="outline" size="sm" onClick={async () => await fetchLatestPremiumStatus()}>
                            I have premium
                        </Button>
                    )}
                </>
            );
            break;
        case DisabledPrimaryActionReason.MESSAGE_EMPTY:
            break;
        case DisabledPrimaryActionReason.SUBJECT_LINE_EMPTY:
            break;
        case DisabledPrimaryActionReason.EMAIL_NOT_CONNECTED:
            variant = 'warning';
            content = (
                <>
                    <div className="flex items-center gap-2 flex-grow w-full">
                        <Mail className="h-5 w-5 stroke-[1] flex-shrink-0" />
                        <p>Connect your email account to send and receive emails.</p>
                    </div>
                    <Button variant="outline" size="sm" onClick={navigateToConnectChannelAccount}>
                        Connect email
                    </Button>
                </>
            );
            break;
        case DisabledPrimaryActionReason.EMAIL_NOT_AUTHENTICATED:
            variant = 'warning';
            content = (
                <>
                    <div className="flex items-center gap-2 flex-grow w-full">
                        <Info className="h-5 w-5 stroke-[1] flex-shrink-0" />
                        <p>Andsend has lost connection to your email account. Please reconnect.</p>
                    </div>
                    <Button variant="outline" size="sm" onClick={navigateToConnectChannelAccount}>
                        Connect email
                    </Button>
                </>
            );

            break;
        case DisabledPrimaryActionReason.EMAIL_SCOPES:
            variant = 'warning';
            content = (
                <>
                    <div className="flex items-center gap-2 flex-grow w-full">
                        <Info className="h-5 w-5 stroke-[1] flex-shrink-0" />
                        <p>Andsend does not have the necessary permissions to send emails. Please reconnect.</p>
                    </div>
                    <Button variant="outline" size="sm" onClick={navigateToConnectChannelAccount}>
                        Connect email
                    </Button>
                </>
            );
            break;

        case DisabledPrimaryActionReason.RECIPIENT_INVALID_EMAIL:
            variant = 'warning';
            content = (
                <>
                    <div className="flex items-center gap-2 flex-grow w-full">
                        <Mail className="h-5 w-5 stroke-[1] flex-shrink-0" />
                        <p>This contact has no email address. Add it to send them an email.</p>
                    </div>
                    <Button variant="outline" size="sm" onClick={() => openEditContactDetailsDialog(prospectId)}>
                        Add email
                    </Button>
                </>
            );

            break;
    }

    if (content) {
        return (
            <InfoBoxContainer key={prospectId} variant={variant}>
                {content}
            </InfoBoxContainer>
        );
    }

    return null;
};
