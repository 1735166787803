import React, { FC } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, AreaChart, Area } from 'recharts';
import * as RechartsPrimitive from 'recharts';
import {
    ChartContainer,
    ChartTooltip,
    ChartLegend,
    ChartLegendContent,
    useChart,
    getPayloadConfigFromPayload,
    ChartTooltipContent,
} from '@shadcn/ui/components/ui/chart';
import { cn } from '@shadcn/ui/lib/utils';

interface BaseChartProps {
    data: {
        date: string;
        messagesSent?: number;
        messagesReceived?: number;
        connectionsAccepted?: number;
    }[];
    visibleLines: Record<string, boolean>;
    chartConfig: Record<string, any>;
}

export const BaseChart: FC<BaseChartProps> = ({ data, visibleLines, chartConfig }) => {
    return (
        <ChartContainer config={chartConfig} className="h-[300px] w-full">
            <AreaChart data={data}>
                <CartesianGrid strokeDasharray="3 3" className="stroke-muted" />
                <ChartLegend content={<ChartLegendContent />} />
                <XAxis dataKey="date" tickLine={false} axisLine={false} className="text-muted-foreground" />
                <YAxis tickLine={false} axisLine={false} width={40} className="text-muted-foreground" />
                <ChartTooltip content={<DashboardChartTooltipContent />} />
                {Object.keys(chartConfig).map(
                    (key) =>
                        visibleLines[key] && (
                            <Area
                                key={key}
                                type="monotone"
                                dataKey={key}
                                stackId="1"
                                stroke={chartConfig[key].theme.light}
                                fill={chartConfig[key].theme.light}
                                fillOpacity={0.4}
                            />
                        )
                )}
            </AreaChart>
        </ChartContainer>
    );
};

const DashboardChartTooltipContent = React.forwardRef<
    HTMLDivElement,
    React.ComponentProps<typeof RechartsPrimitive.Tooltip> &
        React.ComponentProps<'div'> & {
            hideLabel?: boolean;
            hideIndicator?: boolean;
            indicator?: 'line' | 'dot' | 'dashed';
            nameKey?: string;
            labelKey?: string;
        }
>(
    (
        {
            active,
            payload,
            className,
            indicator = 'dot',
            hideLabel = false,
            hideIndicator = false,
            label,
            labelFormatter,
            labelClassName,
            formatter,
            color,
            nameKey,
            labelKey,
        },
        ref
    ) => {
        const { config: chartConfig } = useChart();

        if (!active || !payload?.length) {
            return null;
        }
        const totalActions = payload.reduce((acc, item) => acc + parseInt((item?.value as string) || '0'), 0);

        return (
            <div
                ref={ref}
                className={cn(
                    'grid min-w-[8rem] items-start gap-1.5 rounded-lg border border-border/50 bg-background px-2.5 py-1.5 text-xs shadow-xl',
                    className
                )}
            >
                <div className="text-sm font-semibold">{payload[0].payload.date}</div>
                <div className="grid gap-1.5">
                    <div key={'total'} className="flex items-center justify-between gap-2">
                        <div className="flex items-center gap-1">
                            <span>Total:</span>
                        </div>
                        <span className="font-mono tabular-nums">{totalActions}</span>
                    </div>
                    {payload.map((item) => {
                        const key = item.dataKey as string;
                        const config = chartConfig[key];
                        return (
                            <div key={key} className="flex items-center justify-between gap-2">
                                <div className="flex items-center gap-1">
                                    <div
                                        className="h-2 w-2 rounded-full"
                                        style={{ backgroundColor: config.theme.light }}
                                    />
                                    <span>{config.label}:</span>
                                </div>
                                <span className="font-mono tabular-nums">{item.value}</span>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
);

DashboardChartTooltipContent.displayName = 'DashboardChartTooltipContent';
