import { ProspectDataDto, ProspectOwnerDto } from '@zaplify/prospects';
import { getZaplifySdk, ProspectDataWithConnectionStatus } from '@zaplify/sdk';
import { useLinkedinProfileSearch } from '../../../../hooks/linkedin/use-linkedin-profile-search';
import { useState } from 'react';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { getLinkedInSlug } from '../../../../../helpers/linkedin';

type ProspectWithOwnersDto = ProspectDataDto & {
    owners: ProspectOwnerDto[];
};

export const useLinkedinGlobalSearch = (searchTerm: string, opts: { enabled: boolean }) => {
    const zaplifySdk = getZaplifySdk();
    const { fetchLinkedinProfilesQuery } = useLinkedinProfileSearch();

    const linkedinSlug = getLinkedInSlug(searchTerm);
    const { data: profiles, isLoading: isLoadingProfiles } = useQuery(
        fetchLinkedinProfilesQuery([linkedinSlug], {
            enabled: !!linkedinSlug && opts.enabled,
        })
    );

    const searchLinkedinProspectInternal = async (profiles: ProspectDataDto[]): Promise<ProspectWithOwnersDto[]> => {
        if (!profiles || profiles.length === 0) {
            return [];
        }

        const existingProspects = await zaplifySdk.profiles.prospects.getByLinkedinUserIds(
            profiles.map((p) => p.linkedinUserId)
        );
        const owners = await zaplifySdk.profiles.prospects.getProspectsOwners(
            Object.values(existingProspects).map((p) => p.id)
        );

        const profilesWithOwners = profiles.map((profile) => {
            const prospect = existingProspects[profile.linkedinUserId];
            return {
                ...profile,
                owners: owners[prospect?.id] || [],
            };
        });

        return profilesWithOwners;
    };

    const searchLinkedinProspectQuery = (
        options?: Omit<UseQueryOptions<ProspectWithOwnersDto[], Error>, 'queryKey' | 'queryFn'>
    ): UseQueryOptions<ProspectWithOwnersDto[], Error> => {
        return {
            queryKey: ['searchLinkedinProspect', linkedinSlug],
            queryFn: () => searchLinkedinProspectInternal(profiles?.map((p) => p.prospectData)),
            enabled: !!profiles?.length && opts.enabled,
            ...options,
        };
    };

    return {
        searchLinkedinProspectQuery,
    };
};
