import { ICommand } from '@zaplify/common';
import { Topics } from '@zaplify/common';
import { UserRole } from '@zaplify/users/shared';

export class InviteUsersToOrganizationCommand extends ICommand {
    public static override readonly TOPIC = Topics.USERS;

    constructor(
        readonly emails: string[],
        readonly organizationId: string,
        readonly role: UserRole,
        readonly referralLinkId?: string,
    ) {
        super();
    }
}
