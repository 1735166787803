import { Button } from '@shadcn/ui/components/ui/button';
import { Input } from '@shadcn/ui/components/ui/input';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@shadcn/ui/components/ui/tooltip';
import { useToast } from '@shadcn/ui/hooks/use-toast';
import { useState, useEffect, useRef } from 'react';
import { useAuth } from '../../providers/authentication-provider';
import { useSdk } from '../../sdk';
import { useMutation } from '@tanstack/react-query';

// Helper function for clipboard functionality
const useClipboard = () => {
    return {
        copyToClipboard: async (text: string | null) => {
            if (text) {
                await navigator.clipboard.writeText(text);
            }
        },
    };
};

export const ReferralLinkField: React.FC = () => {
    const { toast } = useToast();
    const { copyToClipboard } = useClipboard();
    const {
        authState: { user },
    } = useAuth();
    const {
        user: { updateUserSettings },
    } = useSdk();

    // State
    const [isEditing, setIsEditing] = useState(false);
    const [handle, setHandle] = useState(user?.handle || '');
    const [tempHandle, setTempHandle] = useState(user?.handle || '');
    const [isCopied, setIsCopied] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    // Mutations
    const { mutateAsync: updateUserSettingsMutation } = useMutation(updateUserSettings());
    const updateHandleMutation = useMutation({
        mutationFn: async (newHandle: string) => {
            if (user?.id) {
                return updateUserSettingsMutation({
                    userId: user.id,
                    data: { handle: newHandle },
                });
            }
            throw new Error('User ID not available');
        },
        onSuccess: () => {
            setHandle(tempHandle);
            setIsEditing(false);
            toast({
                title: 'Success',
                description: 'Your referral link has been updated',
            });
        },
        onError: (error: any) => {
            // Check if this is a client presentable exception
            if (error.type === 'ClientPresentableException') {
                // Set error message to display below the input
                setError(error.message || 'This handle is not available');
            } else {
                // For other errors, show toast notification
                toast({
                    title: 'Error',
                    description: error.message || 'Failed to update referral link',
                    variant: 'destructive',
                });
            }
        },
    });

    // Effects
    useEffect(() => {
        if (user?.handle) {
            setHandle(user.handle);
            setTempHandle(user.handle);
        }
    }, [user]);

    // Derived values
    const baseUrl = `${window.location.host}/join/`;
    const fullReferralLink = `${baseUrl}${handle}`;

    // Handlers
    const handleCopy = async () => {
        await copyToClipboard(fullReferralLink);
        setIsCopied(true);
        toast({
            title: 'Copied!',
            description: 'Referral link copied to clipboard',
        });

        setTimeout(() => {
            setIsCopied(false);
        }, 2000);
    };

    const handleEdit = () => {
        setIsEditing(true);
        setTempHandle(handle);
        setError(null);
    };

    const handleSave = async () => {
        // Clear previous errors
        setError(null);

        // Trim whitespace from the handle
        const trimmedHandle = tempHandle.trim();

        if (!trimmedHandle) {
            setError('Handle cannot be empty');
            // Focus the input when there's an error
            inputRef.current?.focus();
            return;
        }

        // Update the tempHandle with the trimmed version
        setTempHandle(trimmedHandle);

        // Validate handle (alphanumeric and hyphens only)
        if (!/^[a-zA-Z0-9-]+$/.test(trimmedHandle)) {
            setError('Handle can only contain letters, numbers, and hyphens');
            inputRef.current?.focus();
            return;
        }

        updateHandleMutation.mutate(trimmedHandle);
    };

    return (
        <div className="space-y-4 py-2">
            <div className="space-y-2">
                <div className="flex flex-col sm:flex-row items-start sm:items-center gap-2">
                    <div className="w-full flex-1 flex items-center border rounded-md overflow-hidden">
                        <div className="bg-muted px-2 py-2 text-sm h-10 flex items-center pr-0.5 text-foreground-tertiary">
                            {baseUrl}
                        </div>
                        <div className="relative flex-1 group">
                            <Input
                                ref={inputRef}
                                id="handle-input"
                                value={isEditing ? tempHandle : handle}
                                onChange={(e) => {
                                    setTempHandle(e.target.value);
                                    setError(null);
                                }}
                                className={`border-0 md:text-base focus-visible:ring-0 focus-visible:ring-offset-0 disabled:opacity-100 pl-0.5 pr-14 
                                    ${error ? 'border-red-500 focus-visible:ring-red-500' : ''} 
                                    ${!isEditing ? 'bg-muted text-foreground-tertiary' : ''}`}
                                placeholder="your-handle"
                                disabled={!isEditing}
                            />

                            <div className="absolute right-0 top-0 h-full flex items-center">
                                <Button
                                    size="sm"
                                    variant="ghost"
                                    className={`px-3 py-2 font-medium bg-background-primary
                                        ${!isEditing ? 'opacity-0 group-hover:opacity-100 transition-opacity' : ''}`}
                                    onClick={isEditing ? handleSave : handleEdit}
                                    disabled={isEditing && updateHandleMutation.isPending}
                                >
                                    {isEditing ? 'Save' : 'Edit'}
                                </Button>
                            </div>
                        </div>
                    </div>

                    <TooltipProvider delayDuration={0}>
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <span className="w-full sm:w-auto">
                                    <Button
                                        type="button"
                                        variant="outline"
                                        onClick={handleCopy}
                                        disabled={isEditing}
                                        className="shrink-0 px-3 h-10 w-full sm:w-auto"
                                    >
                                        {isCopied ? 'Copied!' : 'Copy'}
                                    </Button>
                                </span>
                            </TooltipTrigger>
                            {isEditing && (
                                <TooltipContent side="bottom">
                                    <p>Please save your new user handle before copying the link</p>
                                </TooltipContent>
                            )}
                        </Tooltip>
                    </TooltipProvider>
                </div>

                {isEditing ? (
                    error ? (
                        <p className="text-sm font-medium text-red-500">{error}</p>
                    ) : (
                        <div className="flex items-center text-sm text-muted-foreground font-medium mt-1">
                            <span>Changing your Andsend link will deactivate your old link.</span>
                        </div>
                    )
                ) : null}
            </div>
        </div>
    );
};
