import { Card, CardTitle, CardDescription, CardContent, CardHeader } from '@shadcn/ui/components/ui/card';
import { UserMemoryList } from '../../../components/user-memory-list';
import { Info } from 'lucide-react';
import { Button } from '@shadcn/ui/components/ui/button';
import { useMemoryExplainerDialog } from '../../../components/dialogs/memory-explainer-dialog';

export const PersonalizationPage = () => {
    const { openMemoryExplainerDialog } = useMemoryExplainerDialog();
    return (
        <div className="space-y-4">
            <Card>
                <CardHeader>
                    <CardTitle>Global Memories</CardTitle>
                    <CardDescription>
                        As you provide feedback on messages, your agent will pick up on your preferences and learn
                        information about you and your contacts
                        <br />
                        <Button variant="text" size="sm" onClick={openMemoryExplainerDialog} className="p-0 h-auto">
                            <p className="flex items-center gap-1 text-text-brand-primary font-normal text-xs hover:underline">
                                Learn more about memories
                            </p>
                        </Button>
                    </CardDescription>
                </CardHeader>
                <CardContent>
                    <div className="flex flex-col gap-4">
                        <UserMemoryList level="global" alwaysShowAll />
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};

export default PersonalizationPage;
