import * as React from 'react';
import { TooltipProvider } from '@radix-ui/react-tooltip';
import { Badge } from '@shadcn/ui/components/ui/badge';
import { Tooltip, TooltipContent, TooltipTrigger } from '@shadcn/ui/components/ui/tooltip';

export const MatchBadge: React.FC<{}> = () => {
    return (
        <Tooltip>
            <Badge variant="blue">
                <div className="flex flex-row gap-2 items-center">
                    <div className="w-1.5 h-1.5 rounded-full bg-blue-utility-700" />
                    Match
                </div>
            </Badge>
            <TooltipContent>Matches your playbook intent</TooltipContent>
        </Tooltip>
    );
};
