import { styled } from '@mui/styles';

export const ViewContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
}));

export const HubSpotContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    background: '#FFFFFF',
    border: '0.8px solid #E4E6EB',
    boxSizing: 'border-box',
    boxShadow: '0px 2px 6px rgba(112, 144, 176, 0.07)',
    borderRadius: 14,
    marginTop: 36,
    width: '90%',
    maxWidth: 1180,
}));

export const BodyContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
}));
