import { IEvent, Topics } from '@zaplify/common';

export class AssistantSettingsRemovedEvent extends IEvent {
    public static override readonly TOPIC: Topics = Topics.CAMPAIGNS;
    public readonly userId: string;
    public readonly organizationId: string;
    public readonly id: string;

    constructor(params: { userId: string; organizationId: string; id: string }) {
        super();
        this.userId = params.userId;
        this.organizationId = params.organizationId;
        this.id = params.id;
    }

    get partitionKey(): string {
        return this.organizationId;
    }
}
