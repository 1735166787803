import { getLinkedInAccount } from './channel-accounts-helpers';
import { ActionType } from '@zaplify/campaigns';

export function getConnectionRequestLimitCheckedWorkflowSteps(steps, channelAccounts, updateMessages = false) {
    const linkedInAccount = getLinkedInAccount(channelAccounts);
    const isPremiumAccount = linkedInAccount?.LINKEDIN?.isPremiumAccount;

    const lengthCheckedSteps = steps.map((step) => {
        let tempStep = { ...step };
        if (tempStep.type !== ActionType.linkedinConnectionRequest) {
            return tempStep;
        }

        if (updateMessages && !isPremiumAccount) {
            return { ...tempStep, message: { ...tempStep.message, characterLimit: 0, content: '' } };
        }

        if (!isPremiumAccount) {
            return { ...tempStep, message: { ...tempStep.message, characterLimit: 0 } };
        }

        if (isPremiumAccount) {
            return { ...tempStep, message: { ...tempStep.message, characterLimit: 285 } };
        }

        return tempStep;
    });
    return lengthCheckedSteps;
}
