import { IEvent, Topics } from '@zaplify/common';

export class OrganizationDomainUpdatedEvent extends IEvent {
    public static override readonly TOPIC = Topics.USERS;

    constructor(readonly userOrganizationId: string, readonly domain: string) {
        super();
    }

    get partitionKey(): string {
        return this.userOrganizationId;
    }
}
