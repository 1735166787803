import { IQuery, Topics } from '@zaplify/common';
import { NoteDto } from '../dtos';

export class GetUsersNotesQuery extends IQuery<NoteDto[]> {
    public static override readonly TOPIC = Topics.PROSPECTS;
    public readonly userId: string;

    constructor(userId: string) {
        super();
        this.userId = userId;
    }
}
