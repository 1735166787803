import { IUntypedCommand, Topics } from '@zaplify/common';
import { ChannelType, ChannelProvider, CreateLinkedinDto } from '../types/channel-accounts';

interface AuthCode {
    authCode: string;
}

export class CreateChannelAccountCommand extends IUntypedCommand {
    public static override readonly TOPIC = Topics.CHANNEL_ACCOUNTS;
    constructor(
        readonly userId: string,
        readonly organizationId: string,
        readonly type: ChannelType,
        readonly provider: ChannelProvider,
        readonly name?: string,
        readonly avatarUrl?: string,
        readonly LINKEDIN?: CreateLinkedinDto,
        readonly GMAIL?: AuthCode,
        readonly OUTLOOK?: AuthCode,
    ) {
        super();
    }
}
