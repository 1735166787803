import {
    BlocklistedOrganizationDto,
    CompanyBlocklistDto,
    PreviewProspectingDtos,
    ProspectDataDto,
    ProspectDto,
    ProspectComposedDto,
    SetProspectLinkedinDataPayload,
    ProspectQueryResultDto,
    ProspectDataExperienceDto,
    ProspectWithOwnersDto,
    ProspectOwnerDto,
    OwnersDto,
    ProspectDataWithId,
} from '@zaplify/prospects';
import { BaseSdk } from '../../base.sdk';
import { AccountDto } from '@zaplify/services/accounts/shared';

export class ProspectsSdk extends BaseSdk {
    async getProspectByIdsBatch(prospectIds: string[]) {
        return await this.post<any>({ path: `${this.prospectsPath}/get-batch`, payload: prospectIds });
    }

    /**
     * @deprecated
     * Should ONLY be used in admin-purposes
     */
    async forceReleaseProspect(prospectIds: string[]) {
        return await this.post<void>({
            path: `${this.prospectsPath}/release`,
            payload: { ids: prospectIds, force: true },
        });
    }

    async getBlacklist(userOrganizationId: string) {
        return this.get<CompanyBlocklistDto>(`${this.prospectsPath}/${userOrganizationId}/blocklist`);
    }

    async getAvatarUrls(prospectIds: string[]) {
        return this.get<Record<string, string>>(
            `${this.prospectsPath}/linkedin-profile-images?prospectIds=${JSON.stringify(prospectIds)}`,
        );
    }

    async getByLinkedinUserIds(linkedinUserIds: string[]) {
        return this.get<Record<string, ProspectDto>>(
            `${this.prospectsPath}/by-linkedin-user-ids?linkedinUserIds=${JSON.stringify(linkedinUserIds)}`,
        );
    }

    async getByLinkedinPublicIdentifier(linkedinPublicIdentifier: string) {
        return this.get<ProspectDto>(
            `${this.prospectsPath}/by-linkedin-public-identifier?linkedinPublicIdentifier=${linkedinPublicIdentifier}`,
        );
    }

    async replaceBlacklist(newBlocklist: CompanyBlocklistDto) {
        return this.put<{
            blocklist: CompanyBlocklistDto;
            added: number;
            skipped: number;
        }>(`${this.prospectsPath}/${newBlocklist.organizationId}/blocklist`, newBlocklist);
    }

    async deleteCompanyFromBlocklist(organizationId: string, organizationName: BlocklistedOrganizationDto) {
        return this.delete<CompanyBlocklistDto>(`${this.prospectsPath}/${organizationId}/blocklist`, organizationName);
    }

    async deleteAllCompaniesFromBlocklist(organizationId: string) {
        return this.delete<CompanyBlocklistDto>(`${this.prospectsPath}/${organizationId}/blocklist/all`);
    }

    async updateProspectData(data: { prospectId: string; prospectData: Partial<ProspectDataDto> }) {
        return this.patch<ProspectDto>(`${this.prospectsPath}/${data.prospectId}`, {
            ...data.prospectData,
        });
    }
    async setProspectLinkedinData(data: { prospectId: string; payload: SetProspectLinkedinDataPayload }) {
        return this.patch<ProspectDto>(`${this.prospectsPath}/${data.prospectId}/linkedin-data`, {
            ...data.payload,
        });
    }
    async selectProspectMainExperience(data: { prospectId: string; experience: ProspectDataExperienceDto }) {
        return this.post<ProspectDto>({
            path: `${this.prospectsPath}/${data.prospectId}/select-main-experience`,
            payload: data.experience,
        });
    }

    getProspect(prospectId: string, options: { includeOwners: true }): Promise<ProspectWithOwnersDto>;
    getProspect(prospectId: string, options?: { includeOwners: false | undefined }): Promise<ProspectDto>;
    getProspect(prospectId: string, options?: { includeOwners?: boolean }) {
        return this.get<ProspectDto | ProspectWithOwnersDto>(
            `${this.prospectsPath}/${prospectId}${options?.includeOwners ? '?includeOwners=true' : ''}`,
        );
    }

    getProspectOwners(prospectId: string) {
        return this.get<ProspectOwnerDto[]>(`${this.prospectsPath}/${prospectId}/owners`);
    }

    getProspectsOwners(prospectIds: string[]) {
        return this.get<Record<string, ProspectOwnerDto[]>>(`${this.prospectsPath}/owners`, {
            params: { prospectIds: prospectIds },
        });
    }

    getOwners(params: { prospectIds?: string[]; personIds?: string[]; linkedinUrls?: string[] }) {
        const prospectIds = params.prospectIds?.filter((id) => id) || [];
        const personIds = params.personIds?.filter((id) => id) || [];
        const linkedinUrls = params.linkedinUrls?.filter((url) => url) || [];
        const searchParams = {
            prospectIds,
            personIds,
            linkedinUrls,
        };

        if (!prospectIds.length && !personIds.length && !linkedinUrls.length) {
            return Promise.resolve({
                prospectIdsMap: {},
                personIdsMap: {},
                linkedinUrlsMap: {},
            } as OwnersDto);
        }

        return this.get<OwnersDto>(`${this.prospectsPath}/owners/multi-id`, { params: searchParams });
    }
    getProspectComposed = async (prospectId: string) => {
        return await this.get<ProspectComposedDto>(`${this.prospectsPath}/${prospectId}/complete`);
    };

    async getAccountById(id: string) {
        return this.get<AccountDto | null>(`${this.prospectsPath}/get-account-by-id?id=${id}`);
    }
    async getAccount(body: { linkedinCompanyId?: string; accountId?: string }) {
        return this.post<AccountDto | null>({ path: `${this.prospectsPath}/get-account`, payload: body });
    }

    async getAccountByName(name: string): Promise<AccountDto | null> {
        return this.get<any>(`${this.prospectsPath}/get-account-by-name?name=${name}`);
    }

    async createAccount(account: AccountDto) {
        return this.post<AccountDto>({ path: `${this.prospectsPath}/account`, payload: account });
    }

    async searchProspectIds(body: {
        prospectIds: string[];
        organizationId: string;
        regex?: string[];
        sort?: any;
        page?: number;
        perPage?: number;
    }) {
        return this.post<ProspectQueryResultDto>({ path: `${this.prospectsPath}/search/prospectIds`, payload: body });
    }

    async findProspectData<T extends keyof ProspectDataDto>(body: {
        prospectIds: string[];
        regex?: string[];
        sort?: any;
        page?: number;
        perPage?: number;
        includeFields: T[];
    }) {
        return this.post<{ prospects: ProspectDataWithId<T>[]; count: number }>({
            path: `${this.prospectsPath}/find-prospect-data-by-ids`,
            payload: body,
        });
    }
}
