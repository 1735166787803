import { Box, Button, TextField, Typography, darken } from '@mui/material';
import { Check, Clipboard } from 'lucide-react';
import { useState } from 'react';
import { palette } from '../../../theme/palette';

interface CopyLinkInputProps {
    value: string;
    onCopy: () => void;
}

export const CopyLinkInput: React.FC<CopyLinkInputProps> = ({ value, onCopy }) => {
    const [isCopied, setIsCopied] = useState(false);

    const handleCopy = () => {
        onCopy();
        setIsCopied(true);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '8px',
            }}
        >
            <TextField
                value={value ?? 'Loading...'}
                sx={{
                    width: 'calc(100% - 100px)',
                    '& .MuiInputBase-root': {
                        borderRadius: '8px',
                    },
                    '& input': {
                        height: '7px',
                    },
                }}
            />
            <Button
                onClick={handleCopy}
                variant="contained"
                disableElevation
                sx={{
                    display: 'flex',
                    gap: '4px',
                    alignItems: 'center',
                    borderRadius: '8px',
                    backgroundColor: '#6A76FB',
                    height: '40px',
                    width: '100px',
                    '&:hover': {
                        backgroundColor: '#6A76FB',
                        '&:hover': {
                            backgroundColor: darken('#6A76FB', 0.1),
                        },
                    },
                    '& .MuiTypography-root': {
                        color: palette.colors.white,
                    },
                }}
            >
                {isCopied ? (
                    <>
                        <Check size={16} />
                        <Typography>Copied</Typography>
                    </>
                ) : (
                    <>
                        <Clipboard size={16} />
                        <Typography>Copy</Typography>
                    </>
                )}
            </Button>
        </Box>
    );
};
