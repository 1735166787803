import encodeurl from 'encodeurl';

/**
 * taken from https://github.com/sjdemartini/mui-tiptap/blob/fabb746997cdf4e5a90cf114823c8cb965911c06/src/utils/links.ts#L16
 * for a quick reference on why it is chosen over the built-in `encodeURI` function
 *
 * Key Use Case:
 * encodeurl is specifically designed for encoding full URLs while preserving their structure
 * encodeURIComponent is better suited for encoding individual URL components/parameters
 *
 * Example comparison:
 * encodeurl("https://example.com/path?param=hello world%20[test]")
 * -> "https://example.com/path?param=hello%20world%20[test]"
 *
 * encodeURIComponent would encode more aggressively
 * encodeURIComponent("https://example.com/path?param=hello world%20[test]")
 * -> "https%3A%2F%2Fexample.com%2Fpath%3Fparam%3Dhello%20world%2520%5Btest%5D"
 */
export function formatHref(value: string): string {
    let currentHrefValue = value.trim();
    if (currentHrefValue && !/^(https?:\/\/|mailto:|tel:|sms:|\/|#)/.test(currentHrefValue)) {
        currentHrefValue = `http://${currentHrefValue}`;
    }

    return encodeurl(currentHrefValue);
}
