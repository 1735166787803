interface SectionTitleProps {
    title: string;
    subtitle?: string;
}

export function SectionTitle({ title, subtitle }: SectionTitleProps) {
    return (
        <div className="mb-6">
            <h1 className="text-2xl font-semibold tracking-tight mb-2">{title}</h1>
            {subtitle && <p className="text-muted-foreground">{subtitle}</p>}
        </div>
    );
}

export default SectionTitle;
