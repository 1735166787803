import { UseQueryOptions, UseSuspenseQueryOptions, UseMutationOptions } from '@tanstack/react-query';
import { getZaplifySdk } from '@zaplify/sdk';

const fetchConnectionData = (memberIdOrPublicIdentifier: string) => {
    return getZaplifySdk().profiles.linkedinConnections.getConnection(memberIdOrPublicIdentifier);
};
type ConnectionData = Awaited<ReturnType<typeof fetchConnectionData>>;
export const getConnection = (
    memberIdOrPublicIdentifier: string,
    options?: Omit<
        UseQueryOptions<ConnectionData, Error> & UseSuspenseQueryOptions<ConnectionData, Error>,
        'queryKey' | 'queryFn'
    >
): UseQueryOptions<ConnectionData, Error> & UseSuspenseQueryOptions<ConnectionData, Error> => {
    return {
        queryKey: ['getConnection', memberIdOrPublicIdentifier],
        queryFn: () => fetchConnectionData(memberIdOrPublicIdentifier),
        enabled: !!memberIdOrPublicIdentifier,
        ...options,
    };
};
