import { createTheme, adaptV4Theme } from '@mui/material/styles';
import { palette } from './palette';

export const theme = createTheme(
    adaptV4Theme({
        overrides: {
            // Style sheet name ⚛️
            MuiTooltip: {
                // Name of the rule
                arrow: {
                    color: palette.subline,
                },
                tooltip: {
                    backgroundColor: palette.subline,
                    fontSize: 13,
                    padding: '6px 9px',
                },
            },
        },
        palette,
        shadow: {
            g: '0px 10px 40px rgba(100, 111, 121, 0.12)',
            background: '0px 10px 40px rgba(100, 111, 121, 0.12)',
            card: '0px 2px 5px rgba(0, 0, 0, 0.05)',
            hover: '0px 10px 40px rgba(100, 111, 121, 0.12)',
            main: '0px 2px 20px rgba(0, 15, 94, 0.05)',
        },
        typography: {
            fontFamily: [
                '"Helvetica Neue"',
                'Arial',
                'roboto',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            h1: {
                color: palette.text.primary,
                fontWeight: 500,
                fontSize: '35px',
                letterSpacing: '-0.24px',
                lineHeight: '40px',
            },
            h2: {
                color: palette.text.primary,
                fontWeight: 500,
                fontSize: '29px',
                letterSpacing: '-0.24px',
                lineHeight: '32px',
            },
            h3: {
                color: palette.text.primary,
                fontWeight: 500,
                fontSize: '24px',
                letterSpacing: '-0.06px',
                lineHeight: '28px',
            },
            h4: {
                color: palette.text.primary,
                fontWeight: 500,
                fontSize: '20px',
                letterSpacing: '-0.06px',
                lineHeight: '24px',
            },
            h5: {
                color: palette.text.primary,
                fontWeight: 500,
                fontSize: '16px',
                letterSpacing: '-0.05px',
                lineHeight: '20px',
            },
            h6: {
                color: palette.text.primary,
                fontWeight: 500,
                fontSize: '14px',
                letterSpacing: '-0.05px',
                lineHeight: '20px',
            },
            subtitle1: {
                color: palette.text.primary,
                fontSize: '16px',
                letterSpacing: '-0.05px',
                lineHeight: '25px',
            },
            subtitle2: {
                color: palette.text.secondary,
                fontWeight: 400,
                fontSize: '14px',
                letterSpacing: '-0.05px',
                lineHeight: '21px',
            },
            body1: {
                color: palette.text.primary,
                fontSize: '15px',
                letterSpacing: '-0.05px',
                lineHeight: '21px',
            },
            body2: {
                color: palette.text.secondary,
                fontSize: '13px',
                letterSpacing: '-0.04px',
                lineHeight: '18px',
            },
            button: {
                color: palette.text.primary,
                fontSize: '14px',
                textTransform: 'none',
            },
            caption: {
                color: palette.text.secondary,
                fontSize: '11px',
                letterSpacing: '0.33px',
                lineHeight: '13px',
            },
            overline: {
                color: palette.text.secondary,
                fontSize: '11px',
                fontWeight: 500,
                letterSpacing: '0.33px',
                lineHeight: '13px',
                textTransform: 'uppercase',
            },
        },
    } as any)
);
