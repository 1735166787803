import { Avatar, IconButton, MenuItem, Tooltip, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { PlanCode } from '@zaplify/subscriptions';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
    ChevronDown,
    ChevronsUpDown,
    ChevronUp,
    CoinsIcon,
    HelpCircle,
    LogOutIcon,
    PanelLeftClose,
    PanelLeftOpen,
    PowerIcon,
    RefreshCcw,
    Settings,
} from 'lucide-react';
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux-latest';
import { useNavigate } from 'react-router-dom';
import { MenuContainer } from '../../../components/organisms/TopBar/containers';
import { AccountAvatar } from '../../../components/organisms/TopBar/elements';
import { logoutAndRedirect } from '../../../redux/actions';
import { AppDispatch, useAppSelector } from '../../../redux/store/configureStore';
import { paths } from '../../../routes/paths';

export const AccountDropdown = ({
    handleChangeSidebarState,
    isSidebarPinned,
    currentAvatarUrl,
    openReferralModal,
}: {
    handleChangeSidebarState: () => void;
    isSidebarPinned: boolean;
    currentAvatarUrl: string;
    openReferralModal: () => void;
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const [anchorElement, setAnchorElement] = React.useState(null);
    const user = useAppSelector((state) => state.user.zaplifyUser);
    const planCode = user.currentSeat?.subscription?.planCode;
    const plan =
        planCode === 'free'
            ? 'free'
            : planCode === PlanCode.TEAM
            ? 'Premium'
            : planCode === PlanCode.BASIC
            ? 'Starter'
            : planCode === PlanCode.ENTERPRISE
            ? 'Enterprise'
            : '';
    const { referralLinkEnabled } = useFlags();
    const openMenu = Boolean(anchorElement);

    const dropdownData = [
        {
            text: 'Settings',
            icon: <Settings data-cy="settings-icon" size={20} />,
            name: 'settings',
            visible: true,
            onClick: () => {
                setAnchorElement(null);
                navigate(paths.ACCOUNT_MANAGEMENT);
            },
        },
        {
            text: 'Integrations',
            icon: <PowerIcon data-cy="integrations-icon" size={20} />,
            name: 'integrations',
            visible: true,
            onClick: () => {
                setAnchorElement(null);
                navigate('/integrations');
            },
        },
        {
            text: 'Refer a friend',
            icon: <CoinsIcon size={20} />,
            name: 'referral-links',
            visible: referralLinkEnabled,
            onClick: () => {
                openReferralModal();
            },
        },
        /*{
            text: 'Academy',
            icon: <GraduationCap data-cy="zap-blog-icon" size={20} />,
            name: 'blog',
            visible: true,
            onClick: () => {
                setAnchorElement(null);
                window.open('https://zaplify.notion.site/Zaplify-Academy-60bfdd8a033a4f2fbc5e0b71e0aa9199');
            },
            additional: <Divider className="divider" />,
        },*/
        {
            text: 'Help and resources',
            icon: <HelpCircle size={20} strokeWidth="2px" />,
            name: 'help-articles',
            visible: true,
            onClick: () => {
                setAnchorElement(null);
                window.open('https://help.andsend.com/en/');
            },
        },
        {
            text: 'Log out',
            icon: <LogOutIcon data-cy="logout-icon" size={20} />,
            name: 'logout',
            visible: true,
            onClick: () => {
                dispatch(logoutAndRedirect());
            },
        },
    ];

    dropdownData.splice(3, 0, {
        text: 'Switch workspace',
        icon: <RefreshCcw size={16} />,
        name: 'change-organization',
        visible: true,
        onClick: () => {
            navigate(paths.OLD_ACCOUNTS);
        },
    });

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            const activeElement = document.activeElement;
            const magicKey = event.ctrlKey || event.metaKey;
            const isTyping =
                activeElement &&
                (activeElement.tagName === 'INPUT' ||
                    activeElement.tagName === 'TEXTAREA' ||
                    activeElement.getAttribute('contenteditable') === 'true');

            if (magicKey && event.key === 'u' && !isTyping) {
                handleChangeSidebarState();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <>
            <Box
                sx={{
                    position: 'relative',
                    display: 'flex',
                    gap: 1,
                    alignItems: 'center',
                    boxSizing: 'border-box',
                    flexDirection: isSidebarPinned ? 'row' : 'column',
                    ...(isSidebarPinned && { marginTop: '8px' }),
                    paddingLeft: '6px !important',
                }}
            >
                <Tooltip title={isSidebarPinned ? '' : 'Account & settings'} placement="right">
                    <ListItemButton
                        sx={{
                            p: 0,
                            paddingLeft: isSidebarPinned ? 'var(--spacing-md, 8px)' : '0',
                            paddingTop: isSidebarPinned ? '0' : 'var(--spacing-md, 8px)',
                            maxHeight: '37px',
                            display: 'flex',
                            borderRadius: 'var(--radius-s, 8px)',
                            gap: 1,
                        }}
                        onClick={(e) => setAnchorElement(e.currentTarget)}
                    >
                        {!currentAvatarUrl && (
                            <Avatar
                                variant="rounded"
                                sx={{
                                    width: '20px',
                                    height: '20px',
                                    fontSize: '11px',
                                    fontWeight: '500',
                                    background: '#FE7274',
                                }}
                            >
                                {user?.firstName?.[0] + user?.lastName?.[0] || ' '}
                            </Avatar>
                        )}
                        {currentAvatarUrl && (
                            <Avatar
                                src={currentAvatarUrl}
                                sx={{
                                    position: 'inherit',
                                    width: '32px',
                                    height: '32px',
                                    fontSize: '13px',
                                }}
                            ></Avatar>
                        )}
                        <Box
                            sx={{
                                display: isSidebarPinned ? 'flex' : 'none',
                                alignItems: 'center',
                                gap: 'var(--S-xs, 6px)',
                                flex: '0 1 0',
                                '& > div:first-of-type': {
                                    display: 'block',
                                },
                            }}
                        >
                            <ListItemText
                                primary={`${user.firstName || ''}`}
                                sx={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    maxWidth: 90,
                                    '& span': {
                                        fontSize: '14px !important',
                                    },
                                }}
                            />
                            {plan && isSidebarPinned && (
                                <Box
                                    sx={{
                                        borderRadius: 'var(--radius-xs, 4px)',
                                        border: '1px solid var(--primary-20-disabled, rgba(106, 118, 251, 0.30))',
                                        background: 'var(--primary-20-extra-light, rgba(106, 118, 251, 0.10))',
                                        display: 'flex',
                                        padding: 'var(--no-spacing, 0px) var(--S-2xs, 4px)',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: 8,
                                        color: 'var(--primary-20-base, #6A76FB)',
                                        fontSize: 13,
                                        fontWeight: 500,
                                        lineHeight: '19px',
                                    }}
                                >
                                    {plan}
                                </Box>
                            )}
                        </Box>
                        <ChevronsUpDown
                            size={16}
                            strokeWidth={2}
                            style={{ display: isSidebarPinned ? 'inherit' : 'none' }}
                        />
                    </ListItemButton>
                </Tooltip>
                <Tooltip title={isSidebarPinned ? 'Keep closed' : 'Keep open'}>
                    <IconButton
                        onClick={handleChangeSidebarState}
                        sx={{
                            borderRadius: 2,
                            p: 0.7,
                            color: '#101828',
                            ':hover': {
                                animation: isSidebarPinned ? 'bounceLeft 1.2s ease' : 'bounceRight 1.2s ease',
                            },
                            '@keyframes bounceLeft ': {
                                '0%, 20%, 50%, 80%, 100%': { transform: 'translateX(0)' },
                                '40%': { transform: 'translateX(-6px)' },
                                '60%': { transform: 'translateX(-3px)' },
                            },
                            '@keyframes bounceRight ': {
                                ' 0%, 20%, 50%, 80%, 100%': { transform: 'translateX(0)' },
                                '40%': { transform: 'translateX(6px)' },
                                '60%': { transform: 'translateX(3px)' },
                            },
                        }}
                    >
                        {isSidebarPinned ? (
                            <PanelLeftClose size={20} style={{ strokeWidth: 1 }} />
                        ) : (
                            <PanelLeftOpen size={20} style={{ strokeWidth: 1 }} />
                        )}
                    </IconButton>
                </Tooltip>
            </Box>

            <MenuContainer
                anchorEl={anchorElement}
                open={openMenu}
                onClose={() => setAnchorElement(null)}
                sx={{ marginTop: isSidebarPinned ? '10px' : '20px', marginLeft: '-8px' }}
                className="no-margin"
            >
                <div className="user-data-container">
                    {!currentAvatarUrl && <AccountAvatar firstName={user.firstName} inactive className="avatar" />}
                    {currentAvatarUrl && (
                        <Avatar
                            src={currentAvatarUrl}
                            sx={{
                                position: 'inherit',
                                width: '28px',
                                height: '28px',
                                fontSize: '13px',
                            }}
                        ></Avatar>
                    )}
                    <div className="user-data">
                        <Typography className="name">{`${user.firstName || ''} ${user.lastName || ''}`}</Typography>
                        <Typography className="company">{user.company}</Typography>
                    </div>
                </div>
                <Divider className="divider" />
                {dropdownData.map((el: any) => {
                    return (
                        el.visible && (
                            <div key={el.name}>
                                {el.additional && el.additional}
                                <MenuItem onClick={el.onClick} className="menu-item bar">
                                    <ListItemIcon className={`icon ${el.name}`}>{el.icon}</ListItemIcon>
                                    <ListItemText primary={el.text} className="text" />
                                </MenuItem>
                            </div>
                        )
                    );
                })}
                <Typography onClick={() => window.open('/privacy-policy/')} className="privacy">
                    Privacy Policy
                </Typography>
            </MenuContainer>
        </>
    );
};
