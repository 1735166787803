import { getZaplifySdk } from '@zaplify/sdk';
import { importProspectsActionTypes } from '../../actionTypes';
import { getFormattedImportProspects } from './functions/importProspectsFunctions';
import { editDraftSetup, notificationSetup, updateValue } from './main';
import { IProspectToSync } from '@zaplify/campaigns';
import { ContactSource } from '@zaplify/services/user-contacts/shared';

export const updateImportProspectsStep = (step) => {
    return {
        type: importProspectsActionTypes.UPDATE_STEP,
        step: step,
    };
};

export const addProspectsToImportProspects = (prospects) => {
    return {
        type: importProspectsActionTypes.UPDATE_PROSPECTS,
        prospects: prospects,
    };
};

export const updateImportProspectsReviewDetails = (reviewDetails) => {
    return {
        type: importProspectsActionTypes.UPDATE_REVIEW_DETAILS,
        reviewDetails: reviewDetails,
    };
};

export const updateAvailableImportProspectsFields = (fields) => {
    return {
        type: importProspectsActionTypes.UPDATE_AVAILABLE_FIELDS,
        fields: fields,
    };
};

export const updateProspectsFieldsInImportProspects = (fields) => {
    return {
        type: importProspectsActionTypes.UPDATE_IMPORTED_PROSPECTS_FIELDS,
        fields: fields,
    };
};

export const updateImportProspectsField = (value, field, idx) => {
    return {
        type: importProspectsActionTypes.UPDATE_FIELD,
        payload: {
            value: value,
            field: field,
            idx: idx,
        },
    };
};

export const addImportProspectsField = () => {
    return {
        type: importProspectsActionTypes.ADD_FIELD,
    };
};

export const deleteImportProspectsField = (index) => {
    return {
        type: importProspectsActionTypes.DELETE_FIELD,
        index: index,
    };
};

export const resetImportProspects = () => {
    return {
        type: importProspectsActionTypes.RESET_IMPORT_PROSPECTS,
    };
};

export const updateWholeImportProspectsState = (payload) => {
    return {
        type: importProspectsActionTypes.UPDATE_WHOLE_STATE,
        payload: payload,
    };
};

// export const createBulkImport =
//     () =>
//     async (dispatch, getState ) => {
//         const profilesSdk = getZaplifySdk().profiles;
//         const campaignName = getState().newCampaign.name;
//         try {
//             const createBulkImportResponse = await profilesSdk.sources.createBulkImport({
//                 bulkImportConfigDto: {
//                     excludeInOrganization: true,
//                 },
//                 name: campaignName,
//             });

//             dispatch(updateValue('sources', [createBulkImportResponse.id]));
//             return createBulkImportResponse.id;
//         } catch (error) {
//             console.log('error creating bulk import');
//         } finally {
//             //dispatch(stopLoading());
//         }
//     };

export const validateImportedProspects = () => async (dispatch, getState) => {
    const profilesSdk = getZaplifySdk().profiles;
    const prospects = getState().importProspects.prospects;
    const fieldMapping = getState().importProspects.fieldMapping;
    try {
        const formattedProspects = getFormattedImportProspects(prospects, fieldMapping);

        //sending validate prospects api request
        const validationResponse = await profilesSdk.sources.validateBulkImport({
            prospectsData: formattedProspects,
        });

        dispatch(updateImportProspectsReviewDetails(validationResponse));
    } catch (error) {
        console.log('error creating bulk import');
    } finally {
        //dispatch(stopLoading());
    }
};

export const runBulkImport =
    (campaignIdParam?: string, syncFunction?: (jobs: IProspectToSync[]) => Promise<void>, ignoreCampaigns?: boolean) =>
    async (dispatch, getState) => {
        const profilesSdk = getZaplifySdk().profiles;
        const campaignId = campaignIdParam || getState().newCampaign.campaignId;
        const prospects = getState().importProspects.prospects;
        const fieldMapping = getState().importProspects.fieldMapping;
        try {
            const formattedProspects = getFormattedImportProspects(prospects, fieldMapping);
            const hasIndustryTag = fieldMapping.find((field) => field.prospect === 'industry');
            const hasOrganizationIndustryTag = fieldMapping.find((field) => field.prospect === 'organizationIndustry');

            // Each added prospect need to have organizationIndustry field if industry field is present,
            // so when running a campaign which uses 'industry' tag, will properly send messages,
            if (hasIndustryTag && !hasOrganizationIndustryTag) {
                formattedProspects.forEach((prospect) => {
                    prospect.organizationIndustry = prospect.industry;
                });
            }

            const importedProspects = await profilesSdk.sources.runBulkImport(
                formattedProspects,
                ContactSource.FileImport,
                campaignId
            );

            !ignoreCampaigns &&
                dispatch(updateValue('prospectsInCampaign', importedProspects?.length > 0 ? importedProspects : []));
            const urlParams = new URLSearchParams(window.location.search);
            const editStep = urlParams.get('step') && parseInt(urlParams.get('step'));
            !ignoreCampaigns && dispatch(editDraftSetup(campaignId, editStep));

            if (importedProspects?.length === 0) {
                console.log('imported prospects length === 0 ', formattedProspects, importedProspects);
                dispatch(notificationSetup({ error: "Couldn't import prospects" }));
                return false;
            }

            !ignoreCampaigns &&
                (await Promise.all([
                    await profilesSdk.campaigns.assignProspectsToCampaign(
                        campaignId,
                        importedProspects.map((p) => p.id)
                    ),
                ]));
            if (syncFunction) {
                syncFunction(importedProspects.map((p) => ({ prospectId: p.id }))).catch((error) => {
                    console.log('error syncing prospects', error);
                });
            }
            return true;
        } catch (error: any) {
            console.log('error creating bulk import', error);
            if (error?.type === 'ClientPresentableException') {
                throw error;
            }
        } finally {
            //dispatch(stopLoading());
        }
    };
