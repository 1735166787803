import { BillingPeriodFrequency, BillingPeriodUnit } from '../../enums/billing';
import { palette } from '../../theme/palette';

const TotalPricePerPerMonth = ({
    price,
    billingPeriod,
    dataCy,
}: {
    price: string;
    billingPeriod: BillingPeriodUnit;
    dataCy?: string;
}) => {
    return (
        <div style={{ fontSize: '15px' }} data-cy={'total-price-info-for-' + dataCy + '-plan'}>
            <span style={{ fontWeight: 500 }}>Total price: </span>
            <span style={{ color: palette.subline }}>
                {price} per month
                {billingPeriod === BillingPeriodUnit.YEAR ? `, billed ${BillingPeriodFrequency[billingPeriod]}` : ''}
            </span>
        </div>
    );
};

export default TotalPricePerPerMonth;
