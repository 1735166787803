import { Typography, Box } from '@mui/material';
import { getUrlHostname } from '../util';

export const HeaderSection = ({ account }: { account: { website?: string; name?: string; industry?: string } }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                padding: 'var(--no-spacing, 0px)',
                alignItems: 'center',
                gap: 'var(--S-s-spacious, 12px)',
                alignSelf: 'stretch',
            }}
        >
            <img
                className="image"
                style={{ width: '40px', height: '40px', borderRadius: 'var(--radius-round, 100px)' }}
                onError={(e) => {
                    (e.target as HTMLImageElement).onerror = null;
                    (e.target as HTMLImageElement).src = '/images/no-logo-icon.svg';
                }}
                src={
                    account.website
                        ? `https://logo.clearbit.com/${getUrlHostname(account.website)}`
                        : '/images/no-logo-icon.svg'
                }
            />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: 'var(--S-3xs, 2px)',
                    flex: '1 0 0',
                }}
            >
                <Typography
                    sx={{
                        color: 'var(--grey-palette-grey-5, #3A4B59)',
                        fontSize: '15px',
                        fontWeight: 500,
                        lineHeight: '20px',
                    }}
                >
                    {account.name}
                </Typography>
                <Box
                    sx={{
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 1,
                        alignSelf: 'stretch',
                    }}
                >
                    <Typography
                        sx={{
                            overflow: 'hidden',
                            color: 'var(--grey-palette-grey-4, #6B7985)',
                            textOverflow: 'ellipsis',
                            fontSize: '13px',
                            fontWeight: 400,
                            lineHeight: '19px',
                        }}
                    >
                        {account.industry}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};
