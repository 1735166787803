const languages_list_raw: { [key: string]: { displayName: string; displayNameNoEmoji: string; value: string } } = {
    Albanian: { displayName: '🇦🇱 Albanian', displayNameNoEmoji: 'Albanian', value: 'Albanian' },
    Arabic: { displayName: '🇦🇪 Arabic', displayNameNoEmoji: 'Arabic', value: 'Arabic' },
    Bosnian: { displayName: '🇧🇦 Bosnian', displayNameNoEmoji: 'Bosnian', value: 'Bosnian' },
    Bulgarian: { displayName: '🇧🇬 Bulgarian', displayNameNoEmoji: 'Bulgarian', value: 'Bulgarian' },
    Cantonese: { displayName: '🇭🇰 Cantonese', displayNameNoEmoji: 'Cantonese', value: 'Cantonese' },
    Croatian: { displayName: '🇭🇷 Croatian', displayNameNoEmoji: 'Croatian', value: 'Croatian' },
    Czech: { displayName: '🇨🇿 Czech', displayNameNoEmoji: 'Czech', value: 'Czech' },
    Danish: { displayName: '🇩🇰 Danish', displayNameNoEmoji: 'Danish', value: 'Danish' },
    Dutch: { displayName: '🇳🇱 Dutch', displayNameNoEmoji: 'Dutch', value: 'Dutch' },
    English: { displayName: '🇬🇧 English', displayNameNoEmoji: 'English', value: 'English' },
    Estonian: { displayName: '🇪🇪 Estonian', displayNameNoEmoji: 'Estonian', value: 'Estonian' },
    Filipino: { displayName: '🇵🇭 Filipino', displayNameNoEmoji: 'Filipino', value: 'Filipino' },
    Finnish: { displayName: '🇫🇮 Finnish', displayNameNoEmoji: 'Finnish', value: 'Finnish' },
    French: { displayName: '🇫🇷 French', displayNameNoEmoji: 'French', value: 'French' },
    German: { displayName: '🇩🇪 German', displayNameNoEmoji: 'German', value: 'German' },
    Greek: { displayName: '🇬🇷 Greek', displayNameNoEmoji: 'Greek', value: 'Greek' },
    Hindi: { displayName: '🇮🇳 Hindi', displayNameNoEmoji: 'Hindi', value: 'Hindi' },
    Hungarian: { displayName: '🇭🇺 Hungarian', displayNameNoEmoji: 'Hungarian', value: 'Hungarian' },
    Indonesian: { displayName: '🇮🇩 Indonesian', displayNameNoEmoji: 'Indonesian', value: 'Indonesian' },
    Icelandic: { displayName: '🇮🇸 Icelandic', displayNameNoEmoji: 'Icelandic', value: 'Icelandic' },
    Italian: { displayName: '🇮🇹 Italian', displayNameNoEmoji: 'Italian', value: 'Italian' },
    Japanese: { displayName: '🇯🇵 Japanese', displayNameNoEmoji: 'Japanese', value: 'Japanese' },
    Korean: { displayName: '🇰🇷 Korean', displayNameNoEmoji: 'Korean', value: 'Korean' },
    Macedonian: { displayName: '🇲🇰 Macedonian', displayNameNoEmoji: 'Macedonian', value: 'Macedonian' },
    Malay: { displayName: '🇲🇾 Malay', displayNameNoEmoji: 'Malay', value: 'Malay' },
    Mandarin: { displayName: '🇨🇳 Mandarin', displayNameNoEmoji: 'Mandarin', value: 'Mandarin' },
    Montenegrin: { displayName: '🇲🇪 Montenegrin', displayNameNoEmoji: 'Montenegrin', value: 'Montenegrin' },
    Norwegian: { displayName: '🇳🇴 Norwegian', displayNameNoEmoji: 'Norwegian', value: 'Norwegian' },
    Polish: { displayName: '🇵🇱 Polish', displayNameNoEmoji: 'Polish', value: 'Polish' },
    Portuguese: { displayName: '🇵🇹 Portuguese', displayNameNoEmoji: 'Portuguese', value: 'Portuguese' },
    Romanian: { displayName: '🇷🇴 Romanian', displayNameNoEmoji: 'Romanian', value: 'Romanian' },
    Russian: { displayName: '🇷🇺 Russian', displayNameNoEmoji: 'Russian', value: 'Russian' },
    Serbian: { displayName: '🇷🇸 Serbian', displayNameNoEmoji: 'Serbian', value: 'Serbian' },
    Slovak: { displayName: '🇸🇰 Slovak', displayNameNoEmoji: 'Slovak', value: 'Slovak' },
    Slovenian: { displayName: '🇸🇮 Slovenian', displayNameNoEmoji: 'Slovenian', value: 'Slovenian' },
    Spanish: { displayName: '🇪🇸 Spanish', displayNameNoEmoji: 'Spanish', value: 'Spanish' },
    Swedish: { displayName: '🇸🇪 Swedish', displayNameNoEmoji: 'Swedish', value: 'Swedish' },
    Thai: { displayName: '🇹🇭 Thai', displayNameNoEmoji: 'Thai', value: 'Thai' },
    Turkish: { displayName: '🇹🇷 Turkish', displayNameNoEmoji: 'Turkish', value: 'Turkish' },
    Ukrainian: { displayName: '🇺🇦 Ukrainian', displayNameNoEmoji: 'Ukrainian', value: 'Ukrainian' },
    Vietnamese: { displayName: '🇻🇳 Vietnamese', displayNameNoEmoji: 'Vietnamese', value: 'Vietnamese' },
};

export const isOnWindows = navigator?.userAgent?.includes('Windows');

export const languages_list: { [key: string]: { displayName: string; value: string } } = Object.keys(
    languages_list_raw
).reduce((acc, key) => {
    acc[key] = {
        displayName: isOnWindows ? languages_list_raw[key].displayNameNoEmoji : languages_list_raw[key].displayName,
        value: languages_list_raw[key].value,
    };
    return acc;
}, {});

export const languages_list_no_emojis = [
    'Arabic',
    'Danish',
    'Dutch',
    'English',
    'Estonian',
    'Finnish',
    'French',
    'German',
    'Greek',
    'Hindi',
    'Italian',
    'Norwegian',
    'Polish',
    'Portuguese',
    'Russian',
    'Spanish',
    'Swedish',
    'Turkish',
];
