import { ICommand, Topics } from '@zaplify/common';

export class RemoveNoteCommand extends ICommand<void> {
    public static override readonly TOPIC = Topics.PROSPECTS;
    public readonly noteId: string;

    constructor(noteId: string) {
        super();
        this.noteId = noteId;
    }
}
