import { ICommand, Topics } from '@zaplify/common';
import { AssistantSettingsDto } from '../dtos';

export class CreateAssistantSettingsCommand extends ICommand<AssistantSettingsDto> {
    public static override readonly TOPIC = Topics.CAMPAIGNS;

    constructor(
        public readonly userId: string,
        public readonly assistantSettingsDto?: Partial<Omit<AssistantSettingsDto, 'userId'>>,
    ) {
        super();
    }
}
