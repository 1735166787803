import { useQuery } from '@apollo/client';
import { GET_LAST_PROSPECT_MESSAGE, GET_OUTREACH_SUGGESTION_BY_PK } from '@zaplify/graphql';
import { scoreActions } from '../../functions/score-outreach-suggestions';
import { RepliedBadge } from './badges/replied-badge';
import { useActions } from '../../hooks/use-actions';
import { HotConversationBadge } from './badges/hot-conversation-badge';
import { NewContactBadge } from './badges/new-contact-badge';
import { MatchBadge } from './badges/match-badge';
import { ReEngageBadge } from './badges/re-engage-badge';
import moment from 'moment';
import { Direction } from '@zaplify/campaigns';
import { AwaitingResponseBadge } from './badges/awaiting-response-badge';

export const ActionBadge = ({ prospectId, isCompleted }: { prospectId: string; isCompleted: boolean }) => {
    const { getBadge, getAction } = useActions();

    const scheduledAction = getAction(prospectId);
    const suggestedBadge = getBadge(prospectId);

    const { data } = useQuery(GET_LAST_PROSPECT_MESSAGE, {
        variables: { prospectId: prospectId },
        skip: !prospectId,
    });
    const lastMessage = data?.Messages?.[0];
    const isReply = lastMessage?.direction === Direction.IN;

    let badge: React.ReactNode = null;
    if (isCompleted) {
        badge = <AwaitingResponseBadge />;
    } else if (suggestedBadge) {
        if (suggestedBadge === 'hot conversation') {
            badge = <HotConversationBadge />;
        } else if (suggestedBadge === 'matching playbook') {
            badge = <MatchBadge />;
        } else if (suggestedBadge === 'new contact') {
            badge = (
                <NewContactBadge addedAt={moment(scheduledAction.ConversationMetaData.userContactAddedAt).fromNow()} />
            );
        } else if (suggestedBadge === 'reengage') {
            badge = <ReEngageBadge />;
        }
    } else if (isReply) {
        badge = <RepliedBadge />;
    }

    return badge;
};
