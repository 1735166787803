import { z } from 'zod';
import { tool } from 'ai';
import { GroupDto } from '@zaplify/services/user-contacts/shared';
import { AssistantSettingsDto } from '@zaplify/services/messaging/shared';

const GetPlaybookByGroupIdToolArgsZ = z.object({
    groupId: z.string(),
});

export type GetPlaybookByGroupIdToolArgs = z.infer<typeof GetPlaybookByGroupIdToolArgsZ>;

export type GetPlaybookByGroupIdToolResult = {
    playbooks: { group: GroupDto; assistantSetting: AssistantSettingsDto }[];
};

export const GetPlaybookByGroupIdToolFactory = {
    name: 'getPlaybookByGroupId',
    makeTool: (execute: (args: GetPlaybookByGroupIdToolArgs) => Promise<GetPlaybookByGroupIdToolResult>) =>
        tool({
            description: 'Get a playbook by group id',
            parameters: GetPlaybookByGroupIdToolArgsZ,
            execute,
        }),
};

const GetAllPlaybooksToolArgsZ = z.object({});

export type GetAllPlaybooksToolArgs = z.infer<typeof GetAllPlaybooksToolArgsZ>;

export type GetAllPlaybooksToolResult = {
    playbooks: { group: GroupDto; assistantSetting: AssistantSettingsDto }[];
};

export const GetAllPlaybooksToolFactory = {
    name: 'getAllPlaybooks',
    makeTool: (execute: (args: GetAllPlaybooksToolArgs) => Promise<GetAllPlaybooksToolResult>) =>
        tool({
            description:
                'Get all playbooks. Returns a list of all playbooks including the groupId of each playbook and the assistantSettings. This tool is a good starting point when you get a question about a playbook.',
            parameters: GetAllPlaybooksToolArgsZ,
            execute,
        }),
};

const GetPlaybookByNameToolArgsZ = z.object({
    groupName: z.string(),
});

export type GetPlaybookByNameToolArgs = z.infer<typeof GetPlaybookByNameToolArgsZ>;

export type GetPlaybookByNameToolResult = {
    playbooks: { group: GroupDto; assistantSetting: AssistantSettingsDto }[];
};

export const GetPlaybookByNameToolFactory = {
    name: 'getPlaybookByName',
    makeTool: (execute: (args: GetPlaybookByNameToolArgs) => Promise<GetPlaybookByNameToolResult>) =>
        tool({
            description:
                'Get a playbook by group name and display the result to the user. This tool is useful when you get a question about a specific playbook. The names of the playbooks can be found through the getAllPlaybooks tool',
            parameters: GetPlaybookByNameToolArgsZ,
            execute,
        }),
};
