import { Button, Popper, Typography } from '@mui/material';
import { styled } from '@mui/styles';
/* eslint-disable-next-line */
export interface ToolTipProps {
    anchor?: Element | null | undefined;
    children?: any;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    open: boolean;
}

export const ToolTip = (props: ToolTipProps) => (
    <Popper open={props.open} anchorEl={props.anchor}>
        <TooltipContainer>
            <Typography>{props.children}</Typography>
            <Button onClick={() => props.onClick}>Close</Button>
        </TooltipContainer>
    </Popper>
);

export const TooltipContainer = styled('div')(({ theme }: any) => ({
    background: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'column',
}));

export default ToolTip;
