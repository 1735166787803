import { IEvent, Topics } from '@zaplify/common';

export class CurrentProspectPositionFoundEvent extends IEvent {
    public static override readonly TOPIC: Topics = Topics.PROSPECTS;
    constructor(readonly prospectId: string, readonly userOrganizationId: string, readonly experienceId: string) {
        super();
    }

    get partitionKey(): string {
        return this.userOrganizationId;
    }
}
