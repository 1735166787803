import React from 'react';
import { useTheme } from '@mui/styles';
import { Box } from '@mui/material';

type PlanCardPremiumStyledProps = {
    premium: boolean;
    children: React.ReactNode;
};

const PlanCardWrapper = ({ premium, children }: PlanCardPremiumStyledProps) => {
    const styles = {
        boxShadow: '0px 2px 7px rgba(112, 144, 176, 0.14)',
        minWidth: '280px',
        borderRadius: '8px',
        ...(premium ? { backgroundImage: 'linear-gradient(90deg, #9198F4 0%, #EB968B 100%)' } : { marginTop: '2px' }),
        display: 'flex',
        maxWidth: 340,
        marginBottom: '40px',
        '& > *': {
            backgroundColor: 'white',
        },
    };

    return <Box sx={styles}>{children}</Box>;
};

export default PlanCardWrapper;
