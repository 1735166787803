import { styled } from '@mui/styles';

export const UploadCSVContainer = styled('div')(({ theme: { palette } }) => ({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'left',
    maxWidth: 580,

    '& > .csv-upload-info-container': {
        marginBottom: 20,
        maxWidth: '669px',

        '& p': { margin: '5px 0px', color: palette.colors.subline },
        '& > div': {
            width: 450,
            marginLeft: 30,
            '& > p': {
                marginBottom: 10,
            },
        },
    },
    '& .error-message': {
        padding: '10px 0',
        margin: '0 auto',
    },
    '& .description': {
        padding: '10px 0',
        fontSize: '15px',
        margin: '7px 0 0',
        color: palette.colors.subline,
    },
}));
