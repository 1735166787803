import { Box, Typography } from '@mui/material';
import { useDispatch } from 'react-redux-latest';
import TwinSelect from '../../../../../components/molecules/twinSelect';
import { updateUpsalesSaveStatus } from '../../../../../redux/ducks/upsales/operations';
import { updateCustomActivityType } from '../../../../../redux/ducks/upsales/slice';
import { AppDispatch, useAppSelector } from '../../../../../redux/store/configureStore';
import { ActivityTypeContainer } from './containers';
import { ErrorBox } from '../../../elements';

export const ActivityType = () => {
    const dispatch = useDispatch<AppDispatch>();

    const isOldInterface = useAppSelector((state) => state.upsales.isOldInterface);
    const availableActivityTypes = useAppSelector((state) => state.upsales.availableActivityTypes);
    const customActivityType = useAppSelector((state) => state.upsales.customActivityType);

    const formattedProspectFields = availableActivityTypes.map((field) => ({
        name: `name-${field.id}`,
        label: field.name,
    }));

    const rightStaticOnChange = (e) => {
        const id = e.target.value.split('-')[1];
        dispatch(updateCustomActivityType(id));
        dispatch(updateUpsalesSaveStatus());
    };

    if (!isOldInterface) {
        return null;
    }

    return (
        <>
            <ActivityTypeContainer>
                <Typography>Your upsales interface using old version, please select default activity type.</Typography>
            </ActivityTypeContainer>
            <div>
                <Box display="flex" className={'box-row'}>
                    <TwinSelect
                        key={'activityType'}
                        index={'activityType-1'}
                        leftValue={'Default Activity type'}
                        leftOptions={null}
                        leftOnChange={() => {}}
                        disabledLeft={true}
                        rightValue={customActivityType ? `name-${customActivityType}` : ''}
                        rightDisplayEmpty={true}
                        rightPlaceholder={'Select activity type'}
                        rightOptions={formattedProspectFields}
                        rightOnChange={(event) => rightStaticOnChange(event)}
                    />
                </Box>
                <ErrorBox errorText={customActivityType ? '' : 'Please select activity type'} />
            </div>
        </>
    );
};
