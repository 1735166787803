import { FormControl, Select } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

export const CustomSelect = styled(({ className, children, ...props }) => (
    <Select
        displayEmpty={props.displayEmpty}
        className={className}
        labelId={props.labelId}
        id={props.id}
        value={props.value}
        onChange={props.onChange}
        required={props.required}
        disabled={props.disabled}
        renderValue={props.renderValue}
        filterSelectedOptions={props.filterSelectedOptions}
        //MenuProps={MenuProps}
    >
        {children}
    </Select>
))(({ side, theme }) => ({
    borderRadius: side === 'left' ? '8px 0 0 8px' : '0 8px 8px 0',
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    ...(side === 'left' && { borderRight: 'none' }),
    fontSize: 16,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
    '& > div': {
        padding: '10.5px 31px 10.5px 14px',
        textAlign: 'left',
        height: '44px',
    },
}));

export const CustomFormControl = styled(({ className, children, ...props }) => (
    <FormControl className={className} variant="outlined" required={props.required}>
        {children}
    </FormControl>
))(({ theme }) => ({
    maxWidth: 231,
    minWidth: 231,
}));
