import { AppSidebarV2 } from '../components/app-sidebar/app-sidebar-v2';
import { SidebarInset, SidebarProvider } from '@shadcn/ui/components/ui/sidebar';
import { useSidebars } from '@shadcn/ui/hooks/use-sidebars';
import { Outlet } from 'react-router-dom';
import { ChatsSyncProvider } from '../hooks/use-chats-sync';
import { PendingMessagesProvider } from '../hooks/messaging/use-pending-messages';
import { AppDialogs } from '../components/app-dialogs';
import { ActionsProvider } from '../hooks/use-actions';
import { EmailProvider } from '../hooks/use-email';
import { usePageTracking, useIdentifyUser } from '../hooks/use-analytics';
import { useAuth } from '../providers/authentication-provider';
import { BillingProvider } from '../hooks/use-billing-listener';
import { LinkedinProvider } from '../hooks/use-linkedin';
import { MobileHeader } from '../components/mobile-header';
import { useIsMobile } from '@shadcn/ui/hooks/use-mobile';
import { memo, useMemo } from 'react';
import { NoSeatPage } from '../pages/no-seat-page';

export const AppLayout = () => {
    // this should probably be in the authentication provider
    useIdentifyUser();
    // Since it returns no UI, it will not contribute to re-renders.
    usePageTracking();

    const {
        authState: { seatId },
    } = useAuth();

    const isMobile = useIsMobile();
    const { getSidebarProps } = useSidebars();
    const mainSidebarProps = useMemo(() => getSidebarProps('main'), []);

    const sidebarProps = useMemo(() => {
        console.log('sidebarProps rendered');
        if (isMobile) {
            return {
                ...mainSidebarProps,
                defaultOpen: false,
            };
        }
        return mainSidebarProps;
    }, [isMobile, mainSidebarProps]);

    return useMemo(() => {
        console.log('applayout rendered inside');
        return (
            <BillingProvider>
                {seatId && (
                    <LinkedinProvider>
                        <ChatsSyncProvider>
                            <ActionsProvider>
                                <SidebarProvider {...sidebarProps}>
                                    <PendingMessagesProvider>
                                        <div className="flex flex-row w-screen h-screen bg-background-sidebar md:p-2 pl-0 z-0 overflow-hidden">
                                            <AppSidebarV2 className="border-none z-0" />
                                            <div className="flex flex-col md:rounded-2xl overflow-hidden border bg-background-sidebar shadow-md w-screen">
                                                <div className="flex max-w-full">
                                                    <div className="flex-1 min-h-0 max-w-full">
                                                        <SidebarInset className="z-10 h-screen">
                                                            <div className="h-[98.5dvh] md:h-[98.5vh] max-w-full">
                                                                <AppDialogs>
                                                                    {isMobile && <MobileHeader />}
                                                                    <EmailProvider>
                                                                        <Outlet />
                                                                    </EmailProvider>
                                                                </AppDialogs>
                                                            </div>
                                                        </SidebarInset>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </PendingMessagesProvider>
                                </SidebarProvider>
                            </ActionsProvider>
                        </ChatsSyncProvider>
                    </LinkedinProvider>
                )}
                {!seatId && <NoSeatPage />}
            </BillingProvider>
        );
    }, [seatId, isMobile, sidebarProps]);
};

export default AppLayout;
