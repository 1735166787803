import { useSubscription } from '@apollo/client';
import { GROUPS_SUBSCRIPTION } from '@zaplify/graphql';
import { useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { groupsLoadingAtom, groupsStateAtom } from '../../views/chats/hooks/use-groups';

export const GroupsSubscription = () => {
    const setGroupsState = useSetAtom(groupsStateAtom);
    const setLoading = useSetAtom(groupsLoadingAtom);

    const { loading } = useSubscription(GROUPS_SUBSCRIPTION, {
        onData: async (data) => {
            const fetchedGroups = data.data.data?.Groups;
            console.log('Groups fetched', fetchedGroups?.length);
            setGroupsState(fetchedGroups);
        },
    });

    useEffect(() => {
        setLoading(loading);
    }, [loading]);

    return null;
};
