import { CircularProgress } from '@mui/material';
import { useAtom } from 'jotai';
import { LinkedinPeopleAtom, LinkedinPeopleLoadingAtom } from '../../../atoms/globalSearch';
import CategoryExpandShrink from '../CategoryShowMore';
import ProspectRow from '../ProspectRow';
import { SearchSectionTitle } from '../globalSearchPopup';
import { useGlobalSearch } from '../hooks/GlobalSearch';
import { PeopleCard } from '../PeopleCard/PeopleCard';
import useLiConversationsImport from '../../../views/chats/hooks/use-li-conversations-import';
import { ContactSource } from '@zaplify/services/user-contacts/shared';

export const LinkedinPeopleSection = ({
    groups,
    searchValue,
    onClick,
    onClickAway,
    userId,
}: {
    groups: { id: string; name: string }[];
    searchValue: string;
    onClick: (node: React.ReactNode) => void;
    onClickAway: () => void;
    userId: string;
}) => {
    const globalSearch = useGlobalSearch();
    const { purchaseLinkedinProfiles } = useLiConversationsImport();
    const [people, setPeople] = useAtom(LinkedinPeopleAtom);
    const [loading, setLoading] = useAtom(LinkedinPeopleLoadingAtom);
    const pagination = people?.pagination;

    const numberOfPeople = people?.data?.length;

    const handleShowMore = async () => {
        return;
    };

    if (numberOfPeople === 0) return null;
    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <SearchSectionTitle title="URL Match" size="12px" toolTip="People that match the Linkedin URL" />
                {!people?.data && loading && <CircularProgress size="12px" style={{ marginLeft: '8px' }} />}
            </div>
            {people?.data.map((person, index) => (
                <div key={index}>
                    <ProspectRow
                        prospect={{
                            _id: null,
                            fullName: person.fullName,
                            organizationName: person.organizationName,
                            firstName: person.fullName.split(' ')[0],
                            lastName: person.fullName.split(' ')[1],
                            title: person.occupationTitle,
                        }}
                        avatarUrl={person.linkedinProfileImgUrl}
                        owners={person.owners}
                        userId={userId}
                        searchValue={searchValue}
                        onClickAway={onClickAway}
                        onClick={() =>
                            onClick(
                                <PeopleCard
                                    person={{
                                        ...person,
                                        _id: person.linkedinUserId,
                                        assignedTo: person.owners,
                                        fullName: person.fullName,
                                        title: person.occupationTitle,
                                        companyName: person.organizationName,
                                        companyWebsite: person.organizationDomain,
                                        companyIndustry: person.organizationIndustry,
                                        location: person.location,
                                        linkedinProfileImgUrl: person.linkedinProfileImgUrl,
                                    }}
                                    groups={groups}
                                    userId={userId}
                                    handleAddPerson={async (groupId?: string) =>
                                        purchaseLinkedinProfiles([person], groupId, ContactSource.GlobalSearch)
                                    }
                                />
                            )
                        }
                    />
                </div>
            ))}
            {numberOfPeople === pagination?.limit * pagination?.page && (
                <CategoryExpandShrink
                    page={pagination.page}
                    numberOfPages={pagination.numberOfPages}
                    loading={loading}
                    numberOfRows={numberOfPeople}
                    onShowMore={() => handleShowMore()}
                />
            )}
        </div>
    );
};
