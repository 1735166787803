import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Menu, MenuItem, TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { apiEndpoint, getZaplifySdk } from '@zaplify/sdk';
import { useEffect, useState } from 'react';
import { getAuthenticationToken } from '@zaplify/utils';
import { isImpersonatingAUser } from '../../helpers/is-global-admin';
import { UserRole } from '@zaplify/users/shared';

export const UserImpersonationProvider = ({ children }: { children: React.ReactNode }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [inputValue, setInputValue] = useState('');
    const [isValid, setIsValid] = useState(true);
    const [isGlobalAdmin, setIsGlobalAdmin] = useState(false);
    const [isImpersonating, setIsImpersonating] = useState(false);
    // const authenticateAsUser = getSearchParam('authenticateAsUser');
    const zaplifySdk = getZaplifySdk(apiEndpoint);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const validateInput = (value: string) => {
        // Regular expression to match an email or a 24-character hex string
        return value === 'none' || /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$|^[a-f\d]{24}$/.test(value);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setInputValue(value);
        setIsValid(validateInput(value));
    };

    const handleSetImpersonation = async () => {
        if (isValid) {
            window?.analytics?.reset();
            await zaplifySdk.profiles.authentication.chooseOrganization('', inputValue);
            setInputValue('');
            await getAuthenticationToken(true);
            window?.analytics?.reset();
            window.location.reload();
        }
    };

    const handleExitImpersonation = async () => {
        window?.analytics?.reset();
        await zaplifySdk.profiles.authentication.chooseOrganization('', 'none');
        await getAuthenticationToken(true);
        window?.analytics?.reset();
        window.location.reload();
    };

    useEffect(() => {
        (async () => {
            const tokenRes = await getAuthenticationToken();
            const isImpersonating = await isImpersonatingAUser();
            setIsGlobalAdmin(tokenRes?.claims?.roles?.includes(UserRole.GLOBAL_ADMIN) || false);
            setIsImpersonating(isImpersonating);
        })();
    }, []);

    return (
        <div>
            {isGlobalAdmin && (
                <IconButton
                    aria-controls="impersonation-menu"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    style={{ position: 'fixed', bottom: '0', left: '0', zIndex: 99999 }}
                    // color="primary"
                >
                    <ArrowDropDownIcon />
                </IconButton>
            )}
            <Menu
                id="impersonation-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                sx={{
                    zIndex: 99999,
                }}
            >
                {isImpersonating ? (
                    <MenuItem onClick={handleExitImpersonation}>Exit impersonation</MenuItem>
                ) : (
                    <MenuItem>
                        <TextField
                            label="Enter email or id"
                            variant="standard"
                            value={inputValue}
                            onChange={handleInputChange}
                            error={!isValid}
                            helperText={!isValid && 'Please enter a valid email, firebaseId or MongoDB ObjectId.'}
                            onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                if (event.key === 'Enter') {
                                    event.preventDefault(); // Prevent the default action to avoid any form submission
                                    handleSetImpersonation();
                                }
                            }}
                            onBlur={handleSetImpersonation}
                        />
                    </MenuItem>
                )}
            </Menu>
            {children}
        </div>
    );
};
