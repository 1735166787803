import { Box, CircularProgress, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux-latest';
import { Link } from 'react-router-dom';
import { registerUser } from '../../../redux/actions';
import { AppDispatch, RootState, useAppSelector } from '../../../redux/store/configureStore';
import { paths } from '../../../routes/paths';
import { validateEmailDomain } from '../../../services/utils/emailHelper';
import FeedbackMessage from '../../../components/form/feedback-message';
import FormInputField from '../../../components/form/form-input-field';
import LoadingButton from '../../../components/form/loading-button';

const getQueryParamFromCurrentUrl = (parameterName: string) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get(parameterName);
};

const getMockedEmailAddress = (): string | undefined => {
    const mockEmailAddress = getQueryParamFromCurrentUrl('mockEmail');
    if (!mockEmailAddress) {
        return;
    }
    const suffix =
        typeof mockEmailAddress === 'string' &&
        mockEmailAddress.length > 4 &&
        RegExp(/^[a-z0-9]*$/).test(mockEmailAddress)
            ? mockEmailAddress
            : Date.now();
    return `user-${Date.now()}@testemail-${suffix}.com`;
};

type RegisterFormValues = {
    email: string;
    password: string;
};

const RegisterForm = () => {
    const dispatch = useDispatch<AppDispatch>();
    const userIsLoading = useAppSelector((state) => state.user.isLoadingData);
    const userRegistrationState = useAppSelector((state: RootState) => state.user.currentState);
    const [authErrorMessage, setAuthErrorMessage] = useState<string | null>(null);
    const { register, handleSubmit, formState, getValues } = useForm<RegisterFormValues>({
        defaultValues: {
            email: getMockedEmailAddress() || '',
            password: '',
        },
        mode: 'onChange',
    });
    const { errors } = formState;
    const { mutate: signUp, status } = useMutation({
        mutationFn: async (data: RegisterFormValues) => {
            const isEmailvalid = await validateEmailDomain(data.email);

            if (!isEmailvalid) {
                throw new Error('Please enter your work email (you@yourcompany.com)');
            }
            await dispatch(registerUser(data.email, data.password));
        },
        onSuccess: (data) => {
            window?.analytics?.track('User Signed Up', {
                email: getValues('email'),
            });
        },
        onError: (error: any) => {
            setAuthErrorMessage(error.message || 'An error occurred');
        },
    });

    const onSubmit = (data: RegisterFormValues) => {
        signUp(data);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                <FormInputField<'email'>
                    id="email"
                    label="Email"
                    register={register('email', {
                        required: true,
                        pattern: {
                            value: /\S+@\S+\.\S+/,
                            message: 'Invalid email address',
                        },
                    })}
                    error={errors.email?.message}
                    placeholder="name@work-email.com"
                    type="email"
                    isLoading={status === 'pending' || userIsLoading}
                />
                <FormInputField<'password'>
                    id="password"
                    label="Password"
                    register={register('password', {
                        required: true,
                        minLength: {
                            value: 6,
                            message: 'Should contain at least 6 characters.',
                        },
                    })}
                    error={errors.password?.message}
                    placeholder="Enter your password"
                    type="password"
                    info="Should contain at least 6 characters."
                    endAdornment={true}
                    isLoading={status === 'pending' || userIsLoading}
                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginTop: '24px',
                    gap: '16px',
                }}
            >
                <LoadingButton
                    isLoading={status === 'pending' || userIsLoading}
                    disabled={!formState.isValid || status === 'pending' || userIsLoading}
                    type="submit"
                >
                    {status === 'pending' || userIsLoading ? (
                        <CircularProgress size={24} sx={{ color: '#969FAC' }} />
                    ) : (
                        <Typography
                            component={'span'}
                            sx={{
                                fontSize: '14px',
                                fontWeight: 500,
                                lineHeight: '21px',
                                color: 'white',
                            }}
                        >
                            Register with email
                        </Typography>
                    )}
                </LoadingButton>
                <FeedbackMessage id="register-form-feedback-message" error={authErrorMessage} info={''} />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '8px',
                    }}
                >
                    <Typography
                        component={'span'}
                        sx={{
                            color: '#3A4B59',
                            fontSize: '14px',
                            lineHeight: '21px',
                        }}
                    >
                        Already have an account?
                    </Typography>
                    <Link to={paths.OLD_LOGIN} style={{ textDecoration: 'none' }}>
                        <Typography
                            component={'span'}
                            sx={{
                                color: '#6583EF',
                                fontSize: '14px',
                                fontWeight: 500,
                                lineHeight: '21px',
                            }}
                        >
                            Sign in
                        </Typography>
                    </Link>
                </Box>
            </Box>
        </form>
    );
};

export default RegisterForm;
