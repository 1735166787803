import { BillingPeriodUnit, CreatedEstimateDto, IPlan, IPlanPackage, PlanCode } from '@zaplify/subscriptions';
import { atomWithReset } from 'jotai/utils';

export type PlanSelectionState = 'active' | 'disabled' | 'error' | 'insufficient_seats';

const availablePeriodsAtom = atomWithReset<BillingPeriodUnit[]>([]);
const availablePlansAtom = atomWithReset<IPlan[]>([]);
const selectedBillingPeriodAtom = atomWithReset<{ amount: number; unit: BillingPeriodUnit } | null>(null);
const selectedPlanAtom = atomWithReset<IPlan | null | undefined>(null);
const selectedPackagesAtom = atomWithReset<{ [key: string]: IPlanPackage & { seatsLeftAfterPackageUpdate: number } }>(
    {}
);
const loadingStateAtom = atomWithReset<'loading' | 'idle'>('loading');
const estimatesAtom = atomWithReset<Array<CreatedEstimateDto & { planCode: PlanCode }> | null | undefined>(null);

const selectedPlanEstimatesSummaryAtom = atomWithReset<
    (CreatedEstimateDto & { planCode: PlanCode }) | null | undefined
>(null);
const selectedPlanEstimatesTotalAtom = atomWithReset<(CreatedEstimateDto & { planCode: PlanCode }) | null | undefined>(
    null
);

const totalAmountToChargeAtom = atomWithReset<number>(0);
const upgradePlanErrorTextAtom = atomWithReset<string>(null);

const selfServiceModalAtom = atomWithReset<'pick-plan' | 'upgrade-plan' | 'renew-plan' | 'cancel-plan' | 'none'>(
    'none'
);

export {
    availablePeriodsAtom,
    availablePlansAtom,
    estimatesAtom,
    loadingStateAtom,
    selectedBillingPeriodAtom,
    selectedPackagesAtom,
    selectedPlanAtom,
    selectedPlanEstimatesSummaryAtom,
    selectedPlanEstimatesTotalAtom,
    selfServiceModalAtom,
    totalAmountToChargeAtom,
    upgradePlanErrorTextAtom,
};
