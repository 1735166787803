import PapaParse from 'papaparse';
import { exportToXLSX } from '../../helpers/export-xlsx';

declare global {
    interface Navigator {
        msSaveBlob?: (blob: any, defaultName?: string) => boolean;
    }
}

export const useFileExport = () => {
    const downloadCSV = async (data: any, filename: string): Promise<void> => {
        const bomCode = '\ufeff';

        const allColumns = getColumns(data);
        let csvContent: any = null;
        if (data && typeof data === 'object') {
            csvContent = PapaParse.unparse(data, { columns: allColumns });
        } else {
            csvContent = data;
        }

        const csvData = new Blob([`${bomCode}${csvContent}`], {
            type: 'text/csv;charset=utf-8;sep=";"',
        });

        let csvURL: any = null;
        if (navigator.msSaveBlob) {
            csvURL = navigator.msSaveBlob(csvData, `${filename}.csv`);
        } else {
            csvURL = window.URL.createObjectURL(csvData);
        }

        const link = document.createElement('a');
        link.href = csvURL as string;
        link.setAttribute('download', `${filename}.csv`);
        link.click();
        link.remove();
    };

    const downloadExcel = async (data: any, filename: string): Promise<void> => {
        const allColumns = getColumns(data);

        if (data && typeof data === 'object') {
            exportToXLSX(data, filename, allColumns);
        } else {
            exportToXLSX(data, filename, allColumns);
        }
    };

    const getColumns = (data: any) => {
        const allColumns = [
            'id',
            'firstName',
            'lastName',
            'fullName',
            'location',
            'email',
            'linkedinProfileUrl',
            'linkedinUserId',
            'linkedinProfileImgUrl',
            'linkedinNumberOfConnections',
            'linkedinHeadline',
            'linkedinDescription',
            'industry',
            'occupationTitle',
            'occupationLevels',
            'organizationId',
            'organizationIndustry',
            'organizationName',
            'organizationSize',
            'organizationDomain',
            'organizationLinkedinUrl',
            'interests',
            'skills',
            'facebookProfileUrl',
            'twitterProfileUrl',
            'githubProfileUrl',
        ];

        for (const row of data) {
            for (const column in row) {
                if (!allColumns.includes(column)) {
                    allColumns.push(column);
                }
            }
        }

        return allColumns;
    };

    return { downloadCSV, downloadExcel };
};
