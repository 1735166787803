import { IQuery, Topics } from '@zaplify/common';
import { CreditsSummaryDto } from '../billing/credits.dto';

export class GetAvailableUserCreditsQuery extends IQuery<CreditsSummaryDto> {
    public static override readonly TOPIC = Topics.BILLING;

    constructor(readonly userId: string, readonly organizationId: string) {
        super();
    }
}
