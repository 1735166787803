import { FC, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { GET_GROUPS } from '@zaplify/graphql';
import { Separator } from '@shadcn/ui/components/ui/separator';
import { PlaybookFilterBadge } from '../../../../components/playbook-filter-badge';
import { NetworkDistanceFilter } from './network-distance-filter';
import { useSuggestionsFeed } from '../hooks/use-suggestions-feed';

const ContactSuggestionFilters: FC = () => {
    const { data: groups } = useQuery(GET_GROUPS);
    const { contactSuggestions } = useSuggestionsFeed();

    const groupsWithSuggestions = useMemo(() => {
        if (!contactSuggestions?.length) return [];
        return contactSuggestions
            .map((suggestion) => suggestion.groupId)
            .filter((groupId) => groupId !== null)
            .map((groupId) => groups?.Groups.find((group) => group.id === groupId))
            .filter((group) => group !== undefined)
            .filter((group, index, self) => self.findIndex((g) => g?.id === group?.id) === index); // Remove duplicates
    }, [contactSuggestions, groups]);

    const groupsWithoutSuggestions = useMemo(() => {
        if (!groups?.Groups) return [];
        return groups.Groups.filter((group) => !groupsWithSuggestions?.some((g) => g?.id === group?.id));
    }, [groups, groupsWithSuggestions]);

    const numberOfPlaybooks = useMemo(() => {
        return groups?.Groups?.length || 0;
    }, [groups]);

    return (
        <>
            <div className="flex items-center gap-2 max-h-9 p-[1px]">
                {numberOfPlaybooks > 1 && groupsWithSuggestions?.length > 0 && (
                    <>
                        <PlaybookFilterBadge
                            disabledGroupIds={groupsWithoutSuggestions.map((group) => group.id)}
                            disabledTooltip="No suggestions available, add more contacts to this playbook to receive suggestions"
                        />
                        <Separator orientation="vertical" className="h-full" />
                    </>
                )}
                <NetworkDistanceFilter />
            </div>
        </>
    );
};

export default ContactSuggestionFilters;
