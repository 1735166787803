import { IEvent, Topics } from '@zaplify/common';

export class MessageRegeneratedEvent extends IEvent {
    public static override readonly TOPIC = Topics.CAMPAIGNS;

    constructor(
        public readonly originalContent: string,
        public readonly feedback: string,
        public readonly userId: string,
        public readonly prospectId: string,
        public readonly assistantId?: string,
    ) {
        super();
    }

    get partitionKey(): string {
        return this.prospectId;
    }
}
