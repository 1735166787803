import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '@shadcn/ui/components/ui/dropdown-menu';
import { Button } from '@shadcn/ui/components/ui/button';
import * as React from 'react';
import { ChevronDown, Linkedin, Mail, SquarePen } from 'lucide-react';
import { useMessageComposer } from '../../../../hooks/messaging/use-message-composer';
import { ChannelType } from '../../../../types/channel-type';
import { useLinkedin } from '../../../../hooks/use-linkedin';
import { useEmail } from '../../../../hooks/use-email';
import { cn } from '@shadcn/ui/lib/utils';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@shadcn/ui/components/ui/tooltip';
import { useIsMobile } from '@shadcn/ui/hooks/use-mobile';
import { paths } from '../../../../../routes/paths';
import { useNavigate } from 'react-router-dom';

type ChannelSelectDropdownProps = {};

const ChannelSelectDropdown: React.FC<ChannelSelectDropdownProps> = ({ ...props }, ref) => {
    const { channelType, handleChannelTypeChange, handleGenerateMessageAsEmail } = useMessageComposer();
    const navigate = useNavigate();

    const { linkedinAccount } = useLinkedin();
    const { emailAccount } = useEmail();
    const isMobile = useIsMobile();

    const isLinkedinEnabled = !!linkedinAccount && !isMobile;
    const isEmailEnabled = !!emailAccount;

    const switchToEmail = () => {
        if (isEmailEnabled) {
            handleChannelTypeChange(ChannelType.EMAIL);
            if (channelType === ChannelType.LINKEDIN) {
                handleGenerateMessageAsEmail();
            }
        } else {
            // Redirect to email setup page
            navigate(paths.NEW.ACCOUNT_MANAGEMENT_PATHS.ROOT + '/' + paths.NEW.ACCOUNT_MANAGEMENT_PATHS.APPS);
        }
    };

    return (
        <div className="w-fit">
            <div
                className="message-composer-toggle-chat-type" // @IMPORTANT Needed for frigade tour (classname must be exact)
            >
                <DropdownMenu {...props}>
                    <Button variant="text" className="p-0">
                        <DropdownMenuTrigger asChild>
                            <div
                                className={cn(
                                    'flex gap-2 items-center rounded-full p-1',
                                    channelType === ChannelType.NOTE
                                        ? 'hover:bg-background-note-hover'
                                        : 'hover:bg-background-primary-hover'
                                )}
                            >
                                <ChannelLabel channel={channelType} />
                                <ChevronDown size={16} strokeWidth={2} />
                            </div>
                        </DropdownMenuTrigger>
                    </Button>

                    <DropdownMenuContent className="translate-y-[-0.5rem]">
                        <DropdownMenuItem
                            className="hover:cursor-pointer"
                            onClick={() => handleChannelTypeChange(ChannelType.LINKEDIN)}
                            disabled={!isLinkedinEnabled}
                        >
                            <ChannelLabel channel={ChannelType.LINKEDIN} />
                        </DropdownMenuItem>
                        <TooltipProvider>
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <div className="w-full">
                                        <DropdownMenuItem
                                            className={cn('hover:cursor-pointer', !isEmailEnabled && 'justify-between')}
                                            onClick={switchToEmail}
                                        >
                                            <ChannelLabel channel={ChannelType.EMAIL} />
                                            {!isEmailEnabled && (
                                                <span className="text-primary text-xs font-medium ml-2">Setup</span>
                                            )}
                                        </DropdownMenuItem>
                                    </div>
                                </TooltipTrigger>
                                <TooltipContent side="right" className={cn(isEmailEnabled ? 'hidden' : '')}>
                                    {isEmailEnabled ? '' : 'Click to connect your email account'}
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                        <DropdownMenuItem
                            className="hover:cursor-pointer"
                            onClick={() => handleChannelTypeChange(ChannelType.NOTE)}
                        >
                            <ChannelLabel channel={ChannelType.NOTE} />
                        </DropdownMenuItem>
                    </DropdownMenuContent>
                </DropdownMenu>
            </div>
        </div>
    );
};
ChannelSelectDropdown.displayName = 'ChannelSelectDropdown';

export { ChannelSelectDropdown };

const ChannelLabel = ({ channel }: { channel: ChannelType }) => {
    const linkedin = (
        <>
            <Linkedin size={16} strokeWidth={2} />
            LinkedIn
        </>
    );

    switch (channel) {
        case ChannelType.LINKEDIN:
            return linkedin;
        case ChannelType.EMAIL:
            return (
                <>
                    <Mail size={16} strokeWidth={2} />
                    Email
                </>
            );
        case ChannelType.NOTE:
            return (
                <>
                    <SquarePen size={16} strokeWidth={2} />
                    Note
                </>
            );
        default:
            return linkedin;
    }
};
