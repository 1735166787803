import { SearchScopes } from '@zaplify/constants';
import { ProspectSearchFilterState } from './types';

export const initialState: ProspectSearchFilterState = {
    organizationKeywords: [],
    organizationDomains: [],
    industries: [],
    excludeIndustries: [],
    organizations: [],
    excludeOrganizations: [],
    excludeOrganizationDomains: [],
    organizationSizes: [],
    personalKeywords: [],
    excludePersonalKeywords: [],
    locations: [],
    excludeLocations: [],
    occupationTitles: [],
    excludeOccupationTitles: [],
    occupationFunctions: [],
    excludeOccupationFunctions: [],
    occupationSeniorities: [],
    excludeOccupationSeniorities: [],
    yearsInCurrentRole: { min: 1, max: 20 },
    excludeInOrganization: false,
    searchScope: SearchScopes.search,
    lastFilterPointName: { point: '', value: '' },
    hasSomeFiltersSelected: false,
    oneProspectPerCompany: false,
    hideOwnedCrmCompanies: false,
    hideFilterPanel: false,
};

export const arrayLikeFilters: string[] = [
    'organizationKeywords',
    'organizationDomains',
    'industries',
    'excludeIndustries',
    'organizations',
    'excludeOrganizations',
    'excludeOrganizationDomains',
    'organizationSizes',
    'personalKeywords',
    'excludePersonalKeywords',
    'locations',
    'excludeLocations',
    'occupationTitles',
    'excludeOccupationTitles',
    'occupationFunctions',
    'excludeOccupationFunctions',
    'occupationSeniorities',
    'excludeOccupationSeniorities',
];

export const stringLikeFilters: string[] = ['searchScope'];

export const booleanLikeFilters: string[] = ['excludeInOrganization', 'oneProspectPerCompany'];

// 'lastFilterPointName' doesn't include as the value is not a filter
export const objectLikeFilters: string[] = ['yearsInCurrentRole'];

export const filtersWithSuggestions = ['industries', 'organizations', 'occupationTitles', 'locations'];
