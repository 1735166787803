import * as React from 'react';
import * as AvatarPrimitive from '@radix-ui/react-avatar';

import { cn } from '@shadcn/ui/lib/utils';
import { Avatar } from './ui/avatar';

const ImageRoot = React.forwardRef<
    React.ElementRef<typeof AvatarPrimitive.Root>,
    React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root>
>(({ className, ...props }, ref) => <Avatar ref={ref} className={cn('h-8 w-8 rounded-none', className)} {...props} />);
ImageRoot.displayName = 'ImageRoot';

const Image = React.forwardRef<
    React.ElementRef<typeof AvatarPrimitive.Image>,
    React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Image>
>(({ className, ...props }, ref) => <AvatarPrimitive.Image ref={ref} className={cn('', className)} {...props} />);
Image.displayName = 'Image';

const ImageFallback = React.forwardRef<
    React.ElementRef<typeof AvatarPrimitive.Fallback>,
    React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback>
>(({ className, ...props }, ref) => (
    <AvatarPrimitive.Fallback ref={ref} className={cn('', className)} style={{}} {...props} />
));
ImageFallback.displayName = 'ImageFallback';

export { Image, ImageFallback, ImageRoot };
