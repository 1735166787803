import React from 'react';
import HoverMenu from 'material-ui-popup-state/HoverMenu';
import { bindMenu, PopupState } from 'material-ui-popup-state/hooks';
import { theme } from '@zaplify/ui';

interface CustomHoverMenuProps {
    id: string;
    popupState: PopupState;
    children: React.ReactNode;
    transform?: {
        x?: string;
        y?: string;
    };
}

const SidebarHoverMenu: React.FC<CustomHoverMenuProps> = ({ id, popupState, children, transform }) => {
    const transformValue = {
        x: transform?.x ?? '12px',
        y: transform?.y ?? '-10px',
    };
    return (
        <HoverMenu
            id={id}
            {...bindMenu(popupState)}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            sx={{
                '.MuiPaper-root': {
                    transform: `translate(${transformValue.x}, ${transformValue.y}) !important`,
                    boxShadow: theme.shadows[2],
                    width: '200px',
                    borderRadius: '8px',
                    border: 'none',
                },
                '.MuiList-root': {
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                },
                '.MuiMenuItem-root': {
                    px: 0.5,
                    py: 0,
                    margin: 0,
                    backgroundColor: 'transparent',
                    color: 'inherit',
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                },
            }}
        >
            {children}
        </HoverMenu>
    );
};

export default SidebarHoverMenu;
