import Twitter from '@mui/icons-material/Twitter';
import { styled } from '@mui/styles';
import PropTypes from 'prop-types';
import { ChannelType } from '@zaplify/channel-accounts/shared';

const ChannelTileComponent = styled(({ children, className, ...props }) => (
    <div className={className}>
        <div className={props.channel}>
            {props.icon}
            <span>{props.label}</span>
        </div>
        <div>{children}</div>
    </div>
))(({ theme }: any) => ({
    borderRadius: '4px',
    backgroundColor: theme.palette.colors.white,
    border: `1px solid ${theme.palette.graphics.stroke}`,
    '& > div:first-child': {
        display: 'inline-flex',
        alignItems: 'center',
        verticalAlign: 'middle',
        justifyContent: 'flex-start',
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '15px',
        padding: '5px 10px',
        color: theme.palette.colors.white,
        borderRadius: '4px 4px 0 0',
        width: 'calc(100% - 20px)',
        '& > span': {
            padding: '5px 10px',
        },
        '&.TWITTER': {
            background: theme.palette.gradients.twitter,
        },
        '&.EMAIL': {
            background: theme.palette.gradients.email,
        },
        '&.LINKEDIN': {
            background: theme.palette.gradients.linkedIn,
        },
    },
    '& > div:last-child': {
        padding: '10px',
    },
}));

export const ChannelTile = (props) => {
    const { children, channel } = props;
    let icon: any = null;
    let label = '';
    switch (channel) {
        case ChannelType.LINKEDIN:
            icon = <img alt='LinkedIn' src='/images/LinkedIn.svg' height='16px' />;
            label = 'LinkedIn';
            break;
        case ChannelType.EMAIL:
            icon = <img alt='Email' src='/images/Email.svg' height='16px' />;
            label = 'Email';
            break;
        case ChannelType.TWITTER:
            icon = <Twitter />;
            label = 'Twitter';
            break;
        default:
            break;
    }

    return (
        <ChannelTileComponent channel={channel} icon={icon} label={label}>
            {children}
        </ChannelTileComponent>
    );
};

ChannelTile.propTypes = {
    channel: PropTypes.string.isRequired,
    children: PropTypes.node,
};
