import { useQuery } from '@apollo/client';
import { GET_DASHBOARD_METRICS } from '@zaplify/graphql';
import {
    format,
    startOfWeek,
    parseISO,
    eachDayOfInterval,
    eachWeekOfInterval,
    sub,
    endOfDay,
    differenceInDays,
} from 'date-fns';
import { useMemo } from 'react';
import { useAuth } from '../../../providers/authentication-provider';
import { UserRole } from '@zaplify/users/shared';

interface DashboardMetricsProps {
    startDate: Date;
    endDate: Date;
    frequency: 'daily' | 'weekly';
    distinctProspects: boolean;
}

export const useDashboardMetrics = ({ startDate, endDate, frequency, distinctProspects }: DashboardMetricsProps) => {
    const {
        authState: { userOrganizationId },
    } = useAuth();

    const previousStartDate = useMemo(() => {
        const periodLength = differenceInDays(endDate, startDate);
        return sub(startDate, { days: periodLength });
    }, [startDate, endDate]);

    const { data, loading, error } = useQuery(GET_DASHBOARD_METRICS, {
        variables: {
            startDate: startDate.toISOString(),
            endDate: endOfDay(endDate).toISOString(),
            previousStartDate: previousStartDate.toISOString(),
            organizationId: userOrganizationId,
        },
        fetchPolicy: 'no-cache',
    });

    const metrics = useMemo(() => {
        if (!data) return [];

        const metricsMap = new Map();
        const dateRange =
            frequency === 'daily'
                ? eachDayOfInterval({ start: startDate, end: endDate })
                : eachWeekOfInterval({ start: startDate, end: endDate }, { weekStartsOn: 1 });

        // Initialize all dates with zero values
        dateRange.forEach((date) => {
            const dateKey = format(date, 'yyyy-MM-dd');
            metricsMap.set(dateKey, {
                messagesSent: 0,
                messagesReceived: 0,
                connectionsAccepted: 0,
                uniqueProspectsSent: new Set(),
                uniqueProspectsReceived: new Set(),
            });
        });

        // Process sent messages
        data.numberOfSentMessages.nodes.forEach((node) => {
            const date = parseISO(node.sentOn);
            const dateKey =
                frequency === 'daily'
                    ? format(date, 'yyyy-MM-dd')
                    : format(startOfWeek(date, { weekStartsOn: 1 }), 'yyyy-MM-dd');

            const metrics = metricsMap.get(dateKey);
            if (metrics) {
                metrics.messagesSent++;
                metrics.uniqueProspectsSent.add(node.prospectId);
            }
        });

        // Process received messages
        data.numberOfReceivedMessages.nodes.forEach((node) => {
            const date = parseISO(node.sentOn);
            const dateKey =
                frequency === 'daily'
                    ? format(date, 'yyyy-MM-dd')
                    : format(startOfWeek(date, { weekStartsOn: 1 }), 'yyyy-MM-dd');

            const metrics = metricsMap.get(dateKey);
            if (metrics) {
                metrics.messagesReceived++;
                metrics.uniqueProspectsReceived.add(node.prospectId);
            }
        });

        // Process connections
        data.numberOfNewConnections.nodes.forEach((node) => {
            const date = parseISO(node.sentOn);
            const dateKey =
                frequency === 'daily'
                    ? format(date, 'yyyy-MM-dd')
                    : format(startOfWeek(date, { weekStartsOn: 1 }), 'yyyy-MM-dd');

            const metrics = metricsMap.get(dateKey);
            if (metrics) {
                metrics.connectionsAccepted++;
            }
        });

        // Convert map to array and transform data
        return Array.from(metricsMap.entries())
            .map(([dateKey, metrics]) => ({
                date: format(parseISO(dateKey), 'MMM dd, yyyy'),
                messagesSent: distinctProspects ? metrics.uniqueProspectsSent.size : metrics.messagesSent,
                messagesReceived: distinctProspects ? metrics.uniqueProspectsReceived.size : metrics.messagesReceived,
                connectionsAccepted: metrics.connectionsAccepted,
            }))
            .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    }, [data, frequency, startDate, endDate, distinctProspects]);

    const latestMetrics = useMemo(() => {
        if (!data) return { messagesSent: 0, messagesReceived: 0, connectionsAccepted: 0 };

        if (distinctProspects) {
            const uniqueSentProspects = new Set(data.numberOfSentMessages.nodes.map((n) => n.prospectId));
            const uniqueReceivedProspects = new Set(data.numberOfReceivedMessages.nodes.map((n) => n.prospectId));

            return {
                messagesSent: uniqueSentProspects.size,
                messagesSentFromApp: uniqueSentProspects.size,
                messagesReceived: uniqueReceivedProspects.size,
                connectionsAccepted: data.numberOfNewConnections.aggregate.count,
            };
        }

        return {
            messagesSent: data.numberOfSentMessages.aggregate.count,
            messagesSentFromApp: data.numberOfSentMessages.nodes.filter((n) => n.isSentFromApp).length,
            messagesReceived: data.numberOfReceivedMessages.aggregate.count,
            connectionsAccepted: data.numberOfNewConnections.aggregate.count,
        };
    }, [data, distinctProspects]);

    const previousMetrics = useMemo(() => {
        if (!data) return { messagesSent: 0, messagesReceived: 0, connectionsAccepted: 0 };

        if (distinctProspects) {
            const uniqueSentProspects = new Set(data.numberOfSentMessagesPrev.nodes.map((n) => n.prospectId));
            const uniqueReceivedProspects = new Set(data.numberOfReceivedMessagesPrev.nodes.map((n) => n.prospectId));

            return {
                messagesSent: uniqueSentProspects.size,
                messagesReceived: uniqueReceivedProspects.size,
                connectionsAccepted: data.numberOfNewConnectionsPrev.aggregate.count,
            };
        }

        return {
            messagesSent: data.numberOfSentMessagesPrev.aggregate.count,
            messagesReceived: data.numberOfReceivedMessagesPrev.aggregate.count,
            connectionsAccepted: data.numberOfNewConnectionsPrev.aggregate.count,
        };
    }, [data, distinctProspects]);

    // Calculate time saved (assuming 5 minutes per message sent)
    const timeSaved = useMemo(() => {
        const totalMessagesSent = data?.numberOfSentMessages.nodes.filter((n) => n.isSentFromApp).length ?? 0;
        return (totalMessagesSent * 5) / 60; // Convert to hours
    }, [data]);

    const previousTimeSaved = useMemo(() => {
        const totalMessagesSent = data?.numberOfSentMessagesPrev.nodes.filter((n) => n.isSentFromApp).length ?? 0;
        return Math.round((totalMessagesSent * 3) / 60); // Convert to hours
    }, [data]);

    return {
        messagesSentData: metrics,
        messagesReceivedData: metrics,
        connectionsData: metrics,
        metrics,
        latestMetrics,
        previousMetrics,
        timeSaved,
        previousTimeSaved,
        loading,
        error,
    };
};
