import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: HubspotStateType = {
    account: {},
    integrationScope: {},
    fieldMapping: [],
    saveStatus: false,
    isActive: false,
    availableFields: {},
    firstTime: false,
    mapLinkedIn: false,
    mapZaplifyProspectStatus: false,
    mapCampaignName: false,
    mapLinkedInConnection: false,
};

export interface HubspotStateType {
    account: Account;
    integrationScope: IntegrationScopeType;
    fieldMapping: FieldMapping[];
    saveStatus: boolean;
    isActive: boolean;
    availableFields: AvailableFieldsType;
    firstTime: boolean;
    mapLinkedIn: boolean;
    mapZaplifyProspectStatus: boolean;
    mapCampaignName: boolean;
    mapLinkedInConnection: boolean;
}

interface AvailableFieldsType {
    contact?: AvailableFields[];
    prospect?: AvailableFields[];
}

export interface AvailableFields {
    fieldType: string;
    label: string;
    name: string;
}

interface Account {
    portalId?: string;
    timeZone?: string;
    currency?: string;
    uiDomain?: string;
    utcOffsetMilliseconds?: string;
    utcOffset?: string;
}

export interface FieldMapping {
    prospectField: string;
    contactField: string;
    required?: boolean;
    errorText?: string;
}

interface IntegrationScopeType {
    prospects?: boolean;
    campaignsActivity?: boolean;
    actionMessages?: boolean;
    gdprDelete?: boolean;
}

const hubspotSlice = createSlice({
    name: 'hubspot',
    initialState: initialState as HubspotStateType,
    reducers: {
        updateSettings(state, action: PayloadAction<any>) {
            return {
                ...state,
                account: action.payload.account,
                integrationScope: action.payload.integrationScope,
                fieldMapping: action.payload.fieldMapping || [],
                isActive: action.payload.isActive,
            };
        },
        updateHubspotActiveState(state, action: PayloadAction<boolean>) {
            state.isActive = action.payload;
        },
        updateAvailableFields(state, action: PayloadAction<any>) {
            state.availableFields = action.payload;
        },
        updateField(state, action: PayloadAction<{ idx: number; field: string; value: any }>) {
            state.fieldMapping[action.payload.idx][action.payload.field] = action.payload.value;
        },
        updateFieldMapping(state, action: PayloadAction<any>) {
            state.fieldMapping = action.payload;
        },
        updateScope(state, action: PayloadAction<{ key: string; value: any }>) {
            state.integrationScope = { ...state.integrationScope, [action.payload.key]: action.payload.value };
        },
        addField(state) {
            state.fieldMapping = [...state.fieldMapping, { prospectField: '', contactField: '' }];
        },
        deleteField(state, action: PayloadAction<{ index: number }>) {
            state.fieldMapping.splice(action.payload.index, 1);
        },
        updateSaveStatus(state, action: PayloadAction<any>) {
            state.saveStatus = action.payload;
        },
        updateHubspotShowLinkedInMapping(state, action: PayloadAction<boolean>) {
            state.mapLinkedIn = action.payload;
        },
        updateHubspotShowCampaignNameMapping(state, action: PayloadAction<boolean>) {
            state.mapCampaignName = action.payload;
        },
        updateHubspotShowLinkedInConnectionMapping(state, action: PayloadAction<boolean>) {
            state.mapLinkedInConnection = action.payload;
        },
        updateHubspotShowProspectStatusMapping(state, action: PayloadAction<boolean>) {
            state.mapZaplifyProspectStatus = action.payload;
        },
        updateHubspotFirstTimeState(state, action: PayloadAction<boolean>) {
            state.firstTime = action.payload;
        },
        updateHubspotCustomSwitches(state, action: PayloadAction<boolean>) {
            state.mapLinkedIn = action.payload;
            state.mapCampaignName = action.payload;
            state.mapLinkedInConnection = action.payload;
            state.mapZaplifyProspectStatus = action.payload;
        },
    },
});
export const {
    updateSettings,
    updateAvailableFields,
    updateField,
    updateScope,
    addField,
    deleteField,
    updateSaveStatus,
    updateHubspotActiveState,
    updateHubspotShowLinkedInMapping,
    updateHubspotShowCampaignNameMapping,
    updateHubspotShowLinkedInConnectionMapping,
    updateHubspotShowProspectStatusMapping,
    updateHubspotFirstTimeState,
    updateFieldMapping,
    updateHubspotCustomSwitches,
} = hubspotSlice.actions;

export const hubspotReducer = hubspotSlice.reducer;
