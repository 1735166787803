import { IUntypedCommand, Topics } from '@zaplify/common';
import { ProspectDataDto } from '../prospects';

export class UpdateEnrichedProspectCommand extends IUntypedCommand {
    public static override readonly TOPIC = Topics.PROSPECTS;
    constructor(
        readonly consultantId: string,
        readonly prospect: {
            id: string;
            data: Partial<ProspectDataDto>;
        },
    ) {
        super();
    }
}
