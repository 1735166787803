import { BaseSdk } from '../../../base.sdk';
import { AvailableMappingsDto, UpdateSettingsDto, IActivityTypesDto } from '@zaplify/upsales';

export class UpsalesSdk extends BaseSdk {
    async getSettings(organizationId: string) {
        return await this.get(`${this.appsPath}/${this.upsalesPath}/settings?organizationId=${organizationId}`);
    }

    async updateSettings(updateSettingsDTO: UpdateSettingsDto, errorCallback: (error: any) => {}) {
        await this.put<void>(`${this.appsPath}/${this.upsalesPath}/settings`, updateSettingsDTO, errorCallback);
    }

    async connect(organizationId: string, code: string) {
        await this.post<void>({
            path: `${this.appsPath}/${this.upsalesPath}/connect`,
            payload: { organizationId: organizationId, code: code },
        });
    }

    async disconnect(organizationId: string) {
        await this.post<void>({
            path: `${this.appsPath}/${this.upsalesPath}/disconnect`,
            payload: { organizationId: organizationId },
        });
    }

    async pause(organizationId: string) {
        await this.post<void>({
            path: `${this.appsPath}/${this.upsalesPath}/pause`,
            payload: { organizationId: organizationId },
        });
    }

    async resume(organizationId: string) {
        await this.post<void>({
            path: `${this.appsPath}/${this.upsalesPath}/resume`,
            payload: { organizationId: organizationId },
        });
    }

    async getProspectUpsalesActivitiesById(prospectId: string) {
        await this.post<void>({ path: `${this.appsPath}/${this.upsalesPath}/prospect/${prospectId}/activities` });
    }

    async getAllFields(organizationId: string) {
        return this.get<AvailableMappingsDto>(
            `${this.appsPath}/${this.upsalesPath}/mapping/fields?organizationId=${organizationId}`,
        );
    }

    async getActivityTypes(organizationId: string) {
        return this.get<IActivityTypesDto>(
            `${this.appsPath}/${this.upsalesPath}/activity-types?organizationId=${organizationId}`,
        );
    }
}
