import { Box } from '@mui/material';
import { AndSendLoader } from '@zaplify/ui';

export const LoaderView: React.FC = () => {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <AndSendLoader variation="black" height={'13%'} />
        </Box>
    );
};
