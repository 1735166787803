export function getEmailDomain(email) {
    return email?.split('@')?.pop();
}

export function stripTopLevelDomain(domain) {
    return domain.substring(0, domain.lastIndexOf('.'));
}

export function isEmailCorrect(email) {
    return /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
    );
}
