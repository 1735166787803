export enum SearchScopes {
    search = 0,
    uploadProspects = 1,
    feed = 2,
}

export enum EditCampaignSteps {
    WelcomeScreen = 0,
    AddProspects = 1,
    ConfigureWorkflow = 2,
}

export enum ProspectViews {
    prospects = 0,
    companies = 1,
}
