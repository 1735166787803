import { BaseSdk } from '../../base.sdk';
import {
    ICreateProspectData,
    PreviewProspectingListViewDto,
    PreviewProspectDetailsDto,
    ValidatedName,
    BulkImportValidationResponseDto,
    PersonFilterDto,
    PreviewProspectingDtos,
    ProspectDto,
    ProspectDataDto,
} from '@zaplify/prospects';
import { ProspectSearchAutoCompleteFields } from '@zaplify/prospects';
import { ExpandCompanyRequestDto } from '@zaplify/prospects';
import { ProspectSearchFiltersDto } from '@zaplify/prospects';
import { LocationDto } from '@zaplify/prospects';
import { CompaniesDto } from '@zaplify/prospects';
import { ValidateBulkImportDto } from '@zaplify/prospects';
import { ContactSource } from '@zaplify/services/user-contacts/shared';

export class SourcesSdk extends BaseSdk {
    async previewProspects(prospectSearchFilter: ProspectSearchFiltersDto) {
        return this.post<{ hits: number }>({
            path: `${this.sourcesPath}/prospect-search/preview-prospecting`,
            payload: prospectSearchFilter,
        });
    }

    async getPersonFilter(filterId: string) {
        return this.get<PersonFilterDto>(`${this.sourcesPath}/${filterId}`);
    }

    async filterBlocklistedCompanies(params: {
        companyNames: { organizationName: string; organizationDomain: string | null }[];
    }) {
        return this.post<{ organizationName: string; organizationDomain: string | null; isBlocklisted: boolean }[]>({
            path: `${this.sourcesPath}/filter-blocklisted-companies`,
            payload: params,
        });
    }

    async createFilter(filters: ProspectSearchFiltersDto) {
        return this.post<PersonFilterDto>({
            path: `${this.sourcesPath}/prospect-search`,
            payload: filters,
        });
    }

    async getGeneratedFilters() {
        return this.get<ProspectSearchFiltersDto>(`${this.sourcesPath}/prospect-search/generated-filters`);
    }

    async deleteSource(sourceId: string) {
        return this.delete<void>(`${this.sourcesPath}/${sourceId}`);
    }

    // async startProspecting(sourceId: string) {
    //     return this.post<SourceDto>({ path: `${this.sourcesPath}/${sourceId}/prospect-search/run` });
    // }

    async assignProspectsToSegment(sourceId: string, prospectIds: string[]) {
        return this.post<void>({ path: `${this.sourcesPath}/${sourceId}/prospects`, payload: prospectIds });
    }

    async autocomplete(input: { field: ProspectSearchAutoCompleteFields; query: string }) {
        return this.get<CompaniesDto[] | LocationDto[] | { name: string; count: string }[]>(
            `${this.sourcesPath}/prospect-search/autocomplete?field=${input.field}&query=${input.query}`,
        );
    }

    async validateNames(names: string[]) {
        return this.post<ValidatedName[]>({
            path: `${this.sourcesPath}/prospect-search/validate-companies`,
            payload: names,
        });
    }

    async updateFilter(filterId: string, filters: Partial<ProspectSearchFiltersDto>) {
        return this.patch<PersonFilterDto>(`${this.sourcesPath}/${filterId}`, filters);
    }

    // async removeProspectsFromSource(sourceId: string, prospectIds: string[]) {
    //     return this.post<any>({ path: `${this.sourcesPath}/${sourceId}/prospects/delete`, payload: prospectIds });
    // }

    async suggestTitles(field: string, query: string[]) {
        return this.get<string[]>(
            `${this.sourcesPath}/prospect-search/suggest?field=${field}&query=${encodeURIComponent(
                JSON.stringify(query),
            )}`,
        );
    }

    // async createBulkImport(createBulkImportDto: CreateBulkImportDto) {
    //     return this.post<any>({ path: `${this.sourcesPath}/bulk-import`, payload: createBulkImportDto });
    // }

    async validateBulkImport(validateBulkImport: ValidateBulkImportDto): Promise<BulkImportValidationResponseDto> {
        return this.post<any>({ path: `${this.sourcesPath}/validate-bulk-import`, payload: validateBulkImport });
    }

    async runBulkImport(
        createProspectDataDto: ICreateProspectData[],
        source: ContactSource,
        groupId?: string | 'default' | 'none',
        options?: { includeAssigned?: boolean },
    ): Promise<ProspectDto[]> {
        const queryParams = [`source=${source}`];

        if (groupId) {
            queryParams.push(`groupId=${groupId}`);
        }
        if (typeof options?.includeAssigned === 'boolean') {
            queryParams.push(`includeAssigned=${options.includeAssigned}`);
        }

        const queryString = `?${queryParams.join('&')}`;
        return this.post<ProspectDto[]>({
            path: `${this.sourcesPath}/bulk-import/run${queryString}`,
            payload: createProspectDataDto,
        });
    }

    async previewProspectingListNew(prospectSearchFilter: ProspectSearchFiltersDto) {
        return this.post<PreviewProspectingDtos.ResponsePerPerson>({
            path: `${this.sourcesPath}/prospect-search/preview-prospecting/list-view-persons`,
            payload: prospectSearchFilter,
        });
    }

    async previewProspectingDetails(body: { personId: string }) {
        return this.post<ProspectDataDto>({
            path: `${this.sourcesPath}/prospect-search/preview-prospecting/details`,
            payload: body,
        });
    }

    async purchasePersons(
        input: { personIds: string[]; linkedinUrls: string[] },
        source: ContactSource,
        groupId?: string,
    ) {
        const queryParams = [`source=${source}`];
        if (groupId) queryParams.push(`groupId=${groupId}`);
        const queryString = `?${queryParams.join('&')}`;
        return this.post<string[]>({
            path: `${this.sourcesPath}/purchase-persons${queryString}`,
            payload: input,
        });
    }
}
