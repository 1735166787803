import { FC } from 'react';
import { FeedHeader } from './components/feed-header';
import { ActionFeed } from './components/action-feed';
import { ActionsProvider } from '../../hooks/use-actions';
import { PlaybookFilterBadge } from '../../components/playbook-filter-badge';
import { Button } from '@shadcn/ui/components/ui/button';
import { CompletedActionsBadge } from './components/completed-actions-badge';

export const ActionFeedPage: FC = () => {
    return (
        <>
            <ActionFeedPageComponent />
        </>
    );
};

const ActionFeedPageComponent: FC = () => {
    return <ActionFeed />;
};

export default ActionFeedPage;
