import { Checkbox as CheckboxMaterialUI, FormControlLabel } from '@mui/material';
import { styled } from '@mui/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const CheckboxComponent = styled(({ children, className, ...props }) => (
    <FormControlLabel
        control={
            <CheckboxMaterialUI checked={props.checked} indeterminate={props.indeterminate} onChange={props.onChange} />
        }
        label={children}
        className={classNames(className, props.mode)}
    />
))(({ theme }: any) => ({
    '& > span:first-child': {
        color: theme.palette.primary.main,
    },
    '&.red > span:first-child': {
        color: theme.palette.colors.red,
    },
    '&.blue > span:first-child': {
        color: theme.palette.colors.blue,
    },
    '&.green > span:first-child': {
        color: theme.palette.colors.green,
    },
    '&.black > span:first-child': {
        color: theme.palette.colors.black,
    },
}));

export const CheckboxColor = {
    red: 'red',
    blue: 'blue',
    green: 'green',
    black: 'black',
};

export const Checkbox = (props) => {
    return (
        <CheckboxComponent checked={props.checked} onChange={props.onChange} mode={props.class}>
            {props.children}
        </CheckboxComponent>
    );
};

Checkbox.propTypes = {
    children: PropTypes.node,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func,
    class: PropTypes.string,
};
