import { ProspectSubStatus } from '@zaplify/campaigns';
import { ImportProspectSkippedIssue } from '@zaplify/prospects';

export enum Count {
    ASSIGNED = 'assigned',
    AVAILABLE = 'available',
    BLOCK_LISTED = 'blocklisted',
    TOTAL = 'total',
}

export const SubStatusMapping: { [K in keyof typeof ProspectSubStatus]: string } = {
    [ProspectSubStatus.REPLIED]: 'Replied',
    [ProspectSubStatus.ACCEPTED_CONNECTION_REQUEST]: 'Connection request accepted',
    [ProspectSubStatus.MISSING_LINKEDIN_URL]: 'No LinkedIn URL',
    [ProspectSubStatus.LI_PROFILE_UNAVAILABLE]: 'No LinkedIn',
    [ProspectSubStatus.MISSING_EMAIL_ADDRESS]: 'No email',
    [ProspectSubStatus.EMAIL_BOUNCED]: 'Bounced email',
    [ProspectSubStatus.EMAIL_AUTO_REPLY]: 'Auto-reply',
    [ProspectSubStatus.ALREADY_CONNECTED]: 'Already connected',
    [ProspectSubStatus.CONNECTION_REQUEST_REQUIRES_EMAIL]: 'LinkedIn Account settings',
    [ProspectSubStatus.NOT_CONNECTED]: 'Not connected',
    [ProspectSubStatus.AWAITING_ENRICHMENT]: 'Not used',
    [ProspectSubStatus.JOB_POSITION_OUTDATED]: 'Not used',
    [ProspectSubStatus.DATA_PROCESSING_FINISHED]: 'Not used',
    [ProspectSubStatus.UNKNOWN_ERROR]: 'Not used',
    [ProspectSubStatus.VERIFYING]: 'Not used',
    [ProspectSubStatus.COMPLETED_WORKFLOW]: 'No reply',
    [ProspectSubStatus.EMAIL_UNSUBSCRIBED]: 'Unsubscribed email',
    [ProspectSubStatus.REVIEW_FINISHED]: '',
};

export enum TransactionState {
    BOUGHT = 'bought',
    AVAILABLE = 'available',
}

export const ImportProspectSkippedIssueMapping: { [K in keyof typeof ImportProspectSkippedIssue]: string } = {
    [ImportProspectSkippedIssue.BLOCKLISTED]: 'Blocklisted',
    [ImportProspectSkippedIssue.DUPLICATE]: 'Duplicate in file',
    [ImportProspectSkippedIssue.EXISTS_IN_CRM]: 'Exists in CRM',
    [ImportProspectSkippedIssue.EXISTS_IN_ORGANIZATION]: 'Exists in organization',
};
