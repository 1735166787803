import { IEvent, Topics } from '@zaplify/common';
import { GroupDto } from '../dtos';

export class GroupCreatedEvent extends IEvent {
    public static override readonly TOPIC: Topics = Topics.PROSPECTS;
    public readonly id: string;
    public readonly name: string;
    public readonly default: boolean;
    public readonly assistantSettingsId: string;
    public readonly imgSrc?: string;
    public readonly userId: string;
    public readonly createdAt: Date;
    public readonly updatedAt: Date;

    constructor(params: GroupDto) {
        super();
        this.id = params.id;
        this.name = params.name;
        this.default = params.default;
        this.assistantSettingsId = params.assistantSettingsId;
        this.imgSrc = params.imgSrc;
        this.userId = params.userId;
        this.createdAt = params.createdAt;
        this.updatedAt = params.updatedAt;
    }

    get partitionKey(): string {
        return this.userId;
    }
}
