import { useQuery } from '@tanstack/react-query';
import { ChargebeePortalSection } from '../../../../enums/chargebee';
import { getZaplifySdk } from '@zaplify/sdk';
import { useEffect, useRef, useState } from 'react';
import { useAuth } from '../../../providers/authentication-provider';
import { PaymentStatus, useBillingListener } from '../../../hooks/use-billing-listener';
export const useChargebeeSubscription = () => {
    const zaplifySdk = getZaplifySdk();
    const cbInstance = useRef(null);
    const [isChargebeeOpen, setIsChargebeeOpen] = useState(false);
    const {
        authState: {
            user: { userOrganizationId },
        },
    } = useAuth();

    const { paymentStatus, setPaymentStatus } = useBillingListener();

    const {
        data: subscription,
        isLoading: isLoadingSubscription,
        refetch: refetchSubscription,
    } = useQuery({
        queryKey: ['subscription'],
        queryFn: async () => {
            const response = await zaplifySdk.profiles.subscription.fetchSusbscriptionDetails();
            return response;
        },
    });

    const loadSelfServicePortal = async (section?: ChargebeePortalSection, paymentSourceAddCallback?: any) => {
        setIsChargebeeOpen(true); // this hides the dialog behind the Chargebee portal
        if (!cbInstance.current) {
            cbInstance.current = window.Chargebee.init({ site: import.meta.env.VITE_CHARGEBEE_SITE });
        }

        await cbInstance.current.setPortalSession(function () {
            return zaplifySdk.profiles.subscription.createPortalSession();
        });

        const cbPortal = cbInstance.current.createChargebeePortal();
        const updateSubscriptionAfterChange = () => {
            refetchSubscription();
        };

        const callbacks = {
            close: function () {
                console.log('close');
                updateSubscriptionAfterChange();
                setIsChargebeeOpen(false);
            },
            paymentSourceAdd: async function () {
                console.log('paymentSourceAdd');
                updateSubscriptionAfterChange();
                if (paymentSourceAddCallback) {
                    cbInstance.current.closeAll();
                    paymentSourceAddCallback();
                }
            },
            paymentSourceUpdate: function () {
                console.log('paymentSourceUpdate');
                updateSubscriptionAfterChange();
            },
            subscriptionChanged: function () {
                console.log('subscriptionChanged');
                updateSubscriptionAfterChange();
            },
            subscriptionCustomFieldsChanged: function () {
                updateSubscriptionAfterChange();
            },
        };

        if (section) {
            cbPortal.openSection(
                {
                    sectionType: window.Chargebee.getPortalSections()[section],
                },
                callbacks
            );
        } else {
            cbPortal.open(callbacks);
        }
    };

    const requestSubscriptionCancel = async () => {
        if (!userOrganizationId) {
            throw new Error('No organization ID found');
        }
        return zaplifySdk.profiles.subscription.requestSubscriptionCancel(userOrganizationId);
    };

    useEffect(() => {
        if (paymentStatus === PaymentStatus.SUCCESS) {
            refetchSubscription().then(() => {
                setPaymentStatus(PaymentStatus.NONE);
            });
        }
    }, [paymentStatus, refetchSubscription]);

    return {
        subscription,
        isLoadingSubscription,
        loadSelfServicePortal,
        requestSubscriptionCancel,
        isChargebeeOpen,
        refetchSubscription,
    };
};
