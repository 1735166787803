import { styled } from '@mui/styles';

const ImportBlocklistCompaniesStyled = styled('div')(({ theme: { palette, spacing } }: any) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '600px',
    alignItems: 'center',
    textAlign: 'center',
    padding: '45px 70px',

    '& .title': {
        fontWeight: '300',
        marginBottom: '20px',
    },
    '& .description': {
        marginBottom: '8px',
        fontSize: 15,
        fontWeight: 500,
        color: palette.colors.subline,
    },
    '& .rules': {
        marginBottom: '31px',
        textAlign: 'left',
        fontSize: 14,
        fontWeight: 400,
    },
    '& .action': {
        marginTop: 55,
        marginLeft: 'auto',

        '& .action-button': {
            maxWidth: '140px',
            height: '48px',
            backgroundColor: palette.primary.light,
            color: palette.colors.white,
            borderRadius: '25px',

            '&:hover': {
                backgroundColor: palette.primary.main,
            },
            '&.disabled': {
                backgroundColor: palette.colors.greyInformationLight,
                color: palette.colors.greyDark,
                cursor: 'not-allowed',
            },
        },
    },
    '& .additional-info-wrapper': {
        width: '100%',
        padding: spacing(1.6, 0, 3.5),

        '& .additional-info': {
            boxSizing: 'border-box',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            padding: spacing(2, 3),
            backgroundColor: palette.colors.greySelect,
            border: `1px solid transparent`,
            borderRadius: '8px',

            '& .icon': {
                fontSize: '15px',
                color: palette.colors.bluePale,
            },
            '& .text': {
                textAlign: 'left',
                padding: spacing(0, 0, 0, 1.5),
            },
        },
    },
}));

export default ImportBlocklistCompaniesStyled;
