import { IEvent, Topics } from '@zaplify/common';

export class LinkedinChannelAccountCreatedEvent extends IEvent {
    public static override readonly TOPIC = Topics.CHANNEL_ACCOUNTS;

    constructor(
        readonly params: {
            userId: string;
            organizationId: string;
            channelAccountId: string;
            linkedinUserId: string;
        },
    ) {
        super();
    }

    get partitionKey(): string {
        return this.params.channelAccountId;
    }
}
