import { IProspectToSync, TriggersSinceTimestampsDto } from '@zaplify/campaigns';
import { BaseSdk } from '../../base.sdk';
import {
    LinkedinAccountActivitiesDto,
    ILogs,
    TrackersDto,
    ConnectionStatus,
    LinkedinTriggerDto,
} from '@zaplify/campaigns';
import { SyncProspectRequestDto } from '@zaplify/services/messaging/shared';

export class TrackersSdk extends BaseSdk {
    // LINKEDIN
    async syncLinkedin(updateSyncedUntilTimestamps: boolean, triggerActivitiesByLiUser?: LinkedinAccountActivitiesDto) {
        return await this.post<TrackersDto>({
            path: `${this.trackersPath}/linkedin/sync`,
            payload: {
                triggerActivitiesByLiUser: triggerActivitiesByLiUser,
                updateSyncedUntilTimestamps,
            },
        });
    }

    async getLinkedinCursorToSyncFrom(): Promise<TriggersSinceTimestampsDto | null> {
        return await this.get(`${this.trackersPath}/linkedin/cursor-to-sync-from`);
    }

    async getLinkedinTracker(channelAccountId: string, prospectId: string): Promise<LinkedinTriggerDto | null> {
        return await this.get<LinkedinTriggerDto>(
            `${this.trackersPath}/linkedin/${channelAccountId}/tracker/${prospectId}`,
        );
    }

    // EMAIL
    async syncEmail() {
        return await this.post({
            path: `${this.trackersPath}/email/sync`,
        });
    }

    // PROSPECT
    async syncProspect(data: SyncProspectRequestDto) {
        return this.post<void>({
            path: `${this.trackersPath}/prospects/sync`,
            payload: data,
        });
    }

    async getProspectsToSync(prospectIds?: string[]): Promise<IProspectToSync[] | null> {
        return await this.get<IProspectToSync[]>(`${this.trackersPath}/prospects/to-sync`, {
            params: {
                prospectIds: prospectIds?.length ? prospectIds : undefined, // Returns all if undefined
            },
        });
    }
}
