import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/styles';
import { isAndSend } from '../../../helpers/is-and-send';
import { AndSendLogotype } from '../../icons/and-send/andsend-logo';

export const ZaplifyLogoLink = styled(({ className, ...props }) => (
    <div className={className}>
        {isAndSend() ? (
            <div onClick={() => window.open('https://andsend.com/', '_self')}>
                <AndSendLogotype variation="transparent_black" height="26px" />
            </div>
        ) : (
            <div onClick={() => window.open('https://zaplify.com/', '_self')}>
                <img alt="Logo" src="/images/logos/Zaplify-Logo.svg" height="26px" />
            </div>
        )}
    </div>
))(() => ({
    align: 'center',
}));

export const AccountAvatar = styled(({ children, className, arrowIcon, inactive, ...props }) => (
    <div onClick={props.onClick} className={className}>
        <div className="first-letter" data-cy="first-letter">
            <p>{props.firstName && props.firstName.charAt(0)}</p>
        </div>
        {arrowIcon && (
            <div className="arrow-icon-container" data-cy="homebar-arrow-dropdown">
                <ExpandMoreIcon className="arrow-icon" />
            </div>
        )}
    </div>
))(({ theme, inactive }) => ({
    display: 'flex',
    cursor: inactive ? 'default' : 'pointer',
    '&:hover': {
        background: 'inherit',
    },
    '& .first-letter': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: inactive ? 44 : 38,
        width: inactive ? 44 : 38,
        margin: 0,
        borderRadius: 50,
        fontSize: '19px',
        fontWeight: 500,
        textTransform: 'capitalize',
        color: theme.palette.colors.red,
        background: theme.palette.colors.pink,
        '&:hover': {
            background: theme.palette.colors.pink,
        },
    },
    '& .arrow-icon-container': {
        alignSelf: 'center',
        '& .arrow-icon': {
            color: theme.palette.colors.greyDark,
            fontSize: 20,
            marginLeft: 8,
            marginTop: 3,
        },
    },
}));

//     const [displayUpgradeModal, setDisplayUpgradeModal] = useState(false);
//     const { resetAllAtoms } = userResetAtoms();
//     const [selfServiceModal] = useAtom(selfServiceModalAtom);

//     useEffect(() => {
//         if (selfServiceModal === 'none') {
//             resetAllAtoms();
//         }
//     }, []);
//     return (
//         <>
//             <Button
//                 text="Upgrade"
//                 height={40}
//                 classes={['upgrade-button']}
//                 onClick={() => setDisplayUpgradeModal(true)}
//             />
//             {displayUpgradeModal && (
//                 <PickPlanModal
//                     open={displayUpgradeModal}
//                     upgradeAccount
//                     closeCallback={() => setDisplayUpgradeModal(false)}
//                 >
//                     <Box width="100%" display={'flex'} justifyContent={'center'}>
//                         <Typography variant="h4">Upgrade your Zaplify organization account</Typography>
//                     </Box>
//                 </PickPlanModal>
//             )}
//         </>
//     );
// };
