import { IQuery } from '@zaplify/common';
import { Topics } from '@zaplify/common';
import { UserStatus, UserDto } from '../users';

export class GetUsersInOrganizationQuery extends IQuery<UserDto[]> {
    public static override readonly TOPIC = Topics.USERS;

    constructor(
        readonly organizationId: string,
        readonly onlyIds: string[] = [],
        readonly excludeIds: string[] = [],
        readonly allowedStatuses?: UserStatus[],
        readonly sortBy?: { [K in keyof Partial<UserDto>]: 'asc' | 'desc' },
        readonly limit?: number,
    ) {
        super();
    }
}
