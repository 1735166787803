import { UseQueryOptions, UseSuspenseQueryOptions } from '@tanstack/react-query';
import { getZaplifySdk } from '@zaplify/sdk';
import { createMutationOptions } from './sdk-utils';
import { UserContactDto } from '@zaplify/services/user-contacts/shared';

const getUserContactLimitFn = () => {
    return getZaplifySdk().profiles.userContacts.getLimit();
};

type UserContactLimitData = Awaited<ReturnType<typeof getUserContactLimitFn>>;

export const getUserContactLimit = (
    options?: Omit<
        UseQueryOptions<UserContactLimitData, Error> & UseSuspenseQueryOptions<UserContactLimitData, Error>,
        'queryKey' | 'queryFn'
    >
): UseQueryOptions<UserContactLimitData, Error> & UseSuspenseQueryOptions<UserContactLimitData, Error> => {
    return {
        queryKey: ['getUserContactLimit'],
        queryFn: () => getUserContactLimitFn(),
        ...options,
    };
};

export const assignUserContactsToGroup = () => {
    return createMutationOptions<UserContactDto[], Error, { contactIds: string | string[]; groupId: string }>({
        mutationFn: (variables) =>
            getZaplifySdk().profiles.userContacts.assignToGroup(variables.contactIds, variables.groupId),
    });
};

export const deleteUserContacts = () => {
    return createMutationOptions<void, Error, { contactIds: string[] }>({
        mutationFn: (variables) => getZaplifySdk().profiles.userContacts.deleteMany(variables.contactIds),
    });
};

const getTotalCountFn = () => {
    return getZaplifySdk().profiles.userContacts.getTotalCount();
};

type TotalCountData = Awaited<ReturnType<typeof getTotalCountFn>>;
export const getTotalUserContactsCountCacheKey = () => ['getTotalCount'];
export const getTotalUserContactsCount = (
    options?: Omit<
        UseQueryOptions<TotalCountData, Error> & UseSuspenseQueryOptions<TotalCountData, Error>,
        'queryKey' | 'queryFn'
    >
): UseQueryOptions<TotalCountData, Error> & UseSuspenseQueryOptions<TotalCountData, Error> => {
    return {
        queryKey: [getTotalUserContactsCountCacheKey()],
        queryFn: () => getTotalCountFn(),
        ...options,
    };
};

const getUserContactByLinkedinMemberIdFn = (linkedinMemberId: string) => {
    return getZaplifySdk().profiles.userContacts.getByLinkedinMemberId(linkedinMemberId);
};

type UserContactByLinkedinMemberIdData = Awaited<ReturnType<typeof getUserContactByLinkedinMemberIdFn>>;

export const getUserContactByLinkedinMemberId = (
    linkedinMemberId: string,
    options?: Omit<
        UseQueryOptions<UserContactByLinkedinMemberIdData, Error> &
            UseSuspenseQueryOptions<UserContactByLinkedinMemberIdData, Error>,
        'queryKey' | 'queryFn'
    >
): UseQueryOptions<UserContactByLinkedinMemberIdData, Error> &
    UseSuspenseQueryOptions<UserContactByLinkedinMemberIdData, Error> => {
    return {
        queryKey: ['getUserContactByLinkedinMemberId', linkedinMemberId],
        queryFn: () => getUserContactByLinkedinMemberIdFn(linkedinMemberId),
        ...options,
    };
};
