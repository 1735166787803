import * as React from 'react';
import { DialogContent } from '@shadcn/ui/components/ui/dialog';
import { SearchParamDialog, useSearchParamDialog } from '@shadcn/ui/components/search-param-dialog';
import { AccountPreview } from './global-search/account-detail-card';
import { useSearchParams } from 'react-router-dom';
import { cn } from '@shadcn/ui/lib/utils';

export const useAccountDetailsDialog = () => {
    const { open, close, value } = useSearchParamDialog('account-details');

    return {
        openAccountDetailsDialog: (accountId: string) => open(accountId),
        closeAccountDetailsDialog: close,
        value,
    };
};

export const AccountDetailsDialog: React.FC = () => {
    const { value: dialogAccountId } = useAccountDetailsDialog();
    const [searchParams] = useSearchParams();

    // Prioritize search param, then prop, then dialog value
    const accountId = dialogAccountId;

    return (
        <SearchParamDialog param={'account-details'}>
            <DialogContent
                className={cn(
                    'flex flex-col fixed-size overflow-hidden',
                    'h-[80vh] max-h-[800px] p-4 gap-0',
                    'animate-in fade-in-0 slide-in-from-left-1/3 duration-100 ease-in-out',
                    'max-w-[600px]'
                )}
            >
                <AccountPreview accountId={accountId} />
            </DialogContent>
        </SearchParamDialog>
    );
};
