import { useSearchParams } from 'react-router-dom';

import { useCallback } from 'react';

export const useActionCardDetails = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const isOpen = searchParams.get('details') === 'open';

    const onClose = useCallback(() => {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.delete('details');
        setSearchParams(newSearchParams);
    }, [searchParams, setSearchParams]);

    const openDetails = useCallback(() => {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set('details', 'open');
        setSearchParams(newSearchParams);
    }, [searchParams, setSearchParams]);

    return {
        isOpen,
        onClose,
        openDetails,
    };
};
