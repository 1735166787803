import { Button } from '@shadcn/ui/components/ui/button';
import { DisabledPrimaryActionReason, useMessageComposer } from '../../../../hooks/messaging/use-message-composer';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '@shadcn/ui/components/ui/dropdown-menu';
import { ChevronDown, UserRoundPlus } from 'lucide-react';
import { MessageType } from '../../../../types/message-type';
import { Tooltip, TooltipContent, TooltipTrigger } from '@shadcn/ui/components/ui/tooltip';
import { KeyboardSymbol, OSMetaKey } from '@shadcn/ui/components/keyboard-symbol';

interface SendButtonProps {
    disabled?: boolean;
}

export const SendButton: React.FC<SendButtonProps> = ({ disabled, ...props }) => {
    const { messageComposerPrimaryAction, messageType, isSendable, disabledPrimaryActionReason } = useMessageComposer();

    const disabledReasonTooltipCopy = (() => {
        switch (disabledPrimaryActionReason) {
            case DisabledPrimaryActionReason.LINKEDIN_MISSING_CONFIG:
                return 'Connect your LinkedIn account to enable LinkedIn messaging';
            case DisabledPrimaryActionReason.LINKEDIN_NOT_AUTHENTICATED:
                return 'Please sign in to LinkedIn to continue.';
            case DisabledPrimaryActionReason.LINKEDIN_SYNCING:
                return 'We are still syncing your LinkedIn messages.';
            case DisabledPrimaryActionReason.RECIPIENT_MISSING_LINKEDIN_PROFILE:
                return 'Contact is missing a valid LinkedIn URL. Add URL or switch to email.';
            case DisabledPrimaryActionReason.SUBJECT_LINE_EMPTY:
                return 'Please add a subject line to send the message.';
            case DisabledPrimaryActionReason.MESSAGE_EMPTY:
                return 'Please add a message to send.';
            case DisabledPrimaryActionReason.EXTENSION_NOT_INSTALLED:
                return "Looks like the Andsend web extension isn't installed. Install to proceed.";
            case DisabledPrimaryActionReason.RECIPIENT_INVALID_EMAIL:
                return 'Contact is missing a valid email address. Add email or switch to LinkedIn.';
            case DisabledPrimaryActionReason.RECIPIENT_CONNECTION_PENDING:
                return 'Contact is pending connection. Please wait for connection to complete.';
            case DisabledPrimaryActionReason.MESSAGE_TOO_LONG:
                return 'Message is too long. Please shorten the message.';
            case DisabledPrimaryActionReason.EMAIL_NOT_AUTHENTICATED:
                return 'Please sign in to your email account to send the message.';
            case DisabledPrimaryActionReason.EMAIL_NOT_CONNECTED:
                return 'Please connect your email account to send the message.';
            case DisabledPrimaryActionReason.EMAIL_SCOPES:
                return 'We lack the necessary email permissions to send the message on your behalf.';
            case DisabledPrimaryActionReason.LINKEDIN_NOT_SUPPORTED_ON_MOBILE:
                return 'LinkedIn messages are not supported on mobile';

            default:
                return 'Message can not be sent.';
        }
    })();

    if (messageType === MessageType.linkedinConnectionRequest) {
        return (
            <div className="flex items-center">
                <Tooltip>
                    <TooltipTrigger asChild>
                        <span>
                            <Button
                                variant="default"
                                size="sm"
                                className={'rounded-r-none text-sm'}
                                onClick={isSendable ? () => messageComposerPrimaryAction() : undefined}
                                disabled={!isSendable}
                            >
                                Connect
                            </Button>
                        </span>
                    </TooltipTrigger>
                    <TooltipContent>
                        {isSendable ? <p>Send a Linkedin connection request</p> : <p>{disabledReasonTooltipCopy}</p>}
                    </TooltipContent>
                </Tooltip>
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button
                            variant="default"
                            size="sm"
                            className={'rounded-l-none border-l-0 px-2 text-sm'}
                            disabled={!isSendable}
                        >
                            <ChevronDown />
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent>
                        <DropdownMenuItem
                            onClick={() => {
                                messageComposerPrimaryAction({ omitMessage: true });
                            }}
                        >
                            <UserRoundPlus />
                            Connect without message
                        </DropdownMenuItem>
                    </DropdownMenuContent>
                </DropdownMenu>
            </div>
        );
    } else {
        const buttonText = messageType === MessageType.note ? 'Add note' : 'Send';
        return (
            <Tooltip>
                <TooltipTrigger asChild>
                    <span>
                        <Button
                            variant="default"
                            size="sm"
                            className="text-sm"
                            disabled={disabled || !isSendable}
                            {...props}
                            onClick={isSendable ? () => messageComposerPrimaryAction() : undefined}
                        >
                            {buttonText}
                        </Button>
                    </span>
                </TooltipTrigger>
                <TooltipContent>
                    {isSendable ? (
                        <p>
                            {messageType === MessageType.note ? 'Add note ' : 'Send message '}
                            <KeyboardSymbol>
                                <span>
                                    <OSMetaKey />
                                </span>
                                +<span>Enter</span>
                            </KeyboardSymbol>
                        </p>
                    ) : (
                        <p>{disabledReasonTooltipCopy}</p>
                    )}
                </TooltipContent>
            </Tooltip>
        );
    }
};
