import { CommandGroup, CommandItem, CommandList, CommandInput } from './ui/command';
import { Command as CommandPrimitive } from 'cmdk';
import { useState, useRef, useCallback, type KeyboardEvent, useEffect } from 'react';

import { Skeleton } from './ui/skeleton';

import { Check } from 'lucide-react';
import { cn } from '../lib/utils';
import * as React from 'react';

export type Option = Record<'value' | 'label', string> & Record<string, string>;

type AutoCompleteProps = {
    options: Option[];
    emptyMessage: string;
    value?: Option;
    onValueChange?: (value: Option) => void;
    isLoading?: boolean;
    disabled?: boolean;
    placeholder?: string;
    className?: string;
    allowCustomValue?: boolean;
};

/** Source: https://github.com/armandsalle/my-site/blob/main/src/react/autocomplete.tsx
 *  Demo: https://www.armand-salle.fr/post/autocomplete-select-shadcn-ui/
 */
export const AutoComplete = ({
    options,
    placeholder,
    emptyMessage,
    value,
    onValueChange,
    disabled,
    isLoading = false,
    className = '',
    allowCustomValue = false,
}: AutoCompleteProps) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const [isOpen, setOpen] = useState(false);
    const [selected, setSelected] = useState<Option | undefined>(value);
    const [inputValue, setInputValue] = useState<string>(value?.label || '');
    const [dropdownOptions, setDropdownOptions] = useState<Option[]>(options);

    useEffect(() => {
        setSelected(value);
        setInputValue(value?.label || '');
    }, [value]);

    const handleKeyDown = useCallback(
        (event: KeyboardEvent<HTMLDivElement>) => {
            const input = inputRef.current;
            if (!input) {
                return;
            }

            // Keep the options displayed when the user is typing
            if (!isOpen) {
                setOpen(true);
            }

            // This is not a default behaviour of the <input /> field
            if (event.key === 'Enter' && input.value !== '') {
                const optionToSelect = dropdownOptions.find((option) => option.label === input.value);
                if (optionToSelect) {
                    setSelected(optionToSelect);
                    onValueChange?.(optionToSelect);
                }
            }

            if (event.key === 'Escape') {
                input.blur();
            }
        },
        [isOpen, dropdownOptions, onValueChange],
    );

    const handleBlur = useCallback(() => {
        setOpen(false);
        if (allowCustomValue) {
            setInputValue(inputValue);
            onValueChange?.({ value: inputValue, label: inputValue });
        } else {
            setInputValue(selected?.label || '');
        }
    }, [inputValue, selected, allowCustomValue, onValueChange]);

    const handleSelectOption = useCallback(
        (selectedOption: Option) => {
            setInputValue(selectedOption.label);

            setSelected(selectedOption);
            onValueChange?.(selectedOption);

            // This is a hack to prevent the input from being focused after the user selects an option
            // We can call this hack: "The next tick"
            setTimeout(() => {
                inputRef?.current?.blur();
            }, 0);
        },
        [onValueChange],
    );

    useEffect(() => {
        if (inputValue === '' || !allowCustomValue) {
            setDropdownOptions(options);
            return;
        }

        const filteredOptions = options.filter((option) =>
            option.label.toLowerCase().includes(inputValue.toLowerCase()),
        );
        const inputValueOption = { value: inputValue, label: inputValue };
        const uniqueOptions = [inputValueOption, ...filteredOptions].filter(
            (option, index, self) => index === self.findIndex((o) => o.label === option.label),
        );
        setDropdownOptions(uniqueOptions);
    }, [inputValue, options, allowCustomValue]);

    return (
        <CommandPrimitive onKeyDown={handleKeyDown} className={className}>
            <div>
                <CommandInput
                    ref={inputRef}
                    value={inputValue}
                    onValueChange={isLoading ? undefined : setInputValue}
                    onBlur={handleBlur}
                    onFocus={() => setOpen(true)}
                    placeholder={placeholder}
                    disabled={disabled}
                    className="text-base"
                    noIcon={true}
                />
            </div>
            <div className="relative mt-1">
                <div
                    className={cn(
                        'animate-in fade-in-0 zoom-in-95 absolute top-0 z-10 w-full rounded-xl bg-white outline-none',
                        isOpen ? 'block' : 'hidden',
                    )}
                >
                    <CommandList className="rounded-lg ring-1 ring-slate-200 max-h-28">
                        {isLoading ? (
                            <CommandPrimitive.Loading>
                                <div className="p-1">
                                    <Skeleton className="h-8 w-full" />
                                </div>
                            </CommandPrimitive.Loading>
                        ) : null}
                        {dropdownOptions.length > 0 && !isLoading ? (
                            <CommandGroup>
                                {dropdownOptions.map((option) => {
                                    const isSelected = selected?.value === option.value;
                                    return (
                                        <CommandItem
                                            key={option.value}
                                            value={option.label}
                                            onMouseDown={(event) => {
                                                event.preventDefault();
                                                event.stopPropagation();
                                            }}
                                            onSelect={() => handleSelectOption(option)}
                                            className={cn(
                                                'flex w-full items-center gap-2',
                                                !isSelected ? 'pl-8' : null,
                                            )}
                                        >
                                            {isSelected ? <Check className="w-4" /> : null}
                                            {option.label}
                                        </CommandItem>
                                    );
                                })}
                            </CommandGroup>
                        ) : null}
                        {!isLoading ? (
                            <CommandPrimitive.Empty className="select-none rounded-sm px-2 py-3 text-center text-sm">
                                {emptyMessage}
                            </CommandPrimitive.Empty>
                        ) : null}
                    </CommandList>
                </div>
            </div>
        </CommandPrimitive>
    );
};
