import React from 'react';
import SearchIcon from '../icons/search';
import { styles } from './styles';

export default function SearchWithIcon({ onChangeMethod }) {
    const classes = styles();

    return (
        <div className={`${classes.margin} ${classes.container}`}>
            <div className={classes.innerInput}>
                <div className={classes.icon}>
                    <SearchIcon />
                </div>
                <input
                    data-cy="blocklist-search"
                    placeholder="Search"
                    className={classes.input}
                    onChange={(event) => onChangeMethod(event.target.value)}
                ></input>
            </div>
        </div>
    );
}
