import {
    ICreateUserOrganizationDto,
    IInvitation,
    ImageUploadDto,
    UserOrganizationByDomainDto,
    UserComposedDto,
    GetCsvImportCreditsResult,
    CreateUserDto,
    IUserAccount,
    IInvitationReduced,
    IReferralLinkData,
    UpdateUserSettingsDto,
} from '@zaplify/users/shared';
import { UserRole } from '@zaplify/users/shared';
import { UserDto } from '@zaplify/users/shared';
import { AccountLocationDto } from '@zaplify/users/shared';
import { CreateChannelAccountDto } from '@zaplify/channel-accounts/shared';
import { CreateLinkedinChannelAccountDto } from '@zaplify/channel-accounts/shared';
import { ChannelAccountDto } from '@zaplify/channel-accounts/shared';
import { BaseSdk } from '../../base.sdk';
import { BillingPeriodUnit, PublicSeatDto } from '@zaplify/subscriptions';

export class UserSdk extends BaseSdk {
    // Organizations
    //@TODO move to zaplifySdk.organizations – Should Not be under zaplifySdk.user right?
    async getUserOrganizationById(id: string) {
        return this.get<ICreateUserOrganizationDto>(`${this.userOrganizationsPath}/${id}`);
    }

    async getUserOrganizationByDomain(domain: string) {
        return this.get<UserOrganizationByDomainDto>(`${this.userOrganizationsPath}/by-domain/${domain}`);
    }

    // Users
    async createUser(user: CreateUserDto) {
        return this.post<UserDto>({ path: `${this.usersPath}/configure`, payload: user });
    }

    async getUserAccounts() {
        return this.get<{ users: IUserAccount[]; invitations: IInvitationReduced[] }>(`${this.usersPath}/accounts`);
    }

    async getUserByFirebaseId() {
        return this.get<UserComposedDto>(`${this.usersPath}/me`);
    }

    async getById(id: string) {
        return this.get<UserComposedDto>(`${this.usersPath}/${id}`);
    }

    async deactivateUser(userId: string) {
        return this.post<void>({ path: `${this.usersPath}/${userId}/deactivate` });
    }

    async activateUser(userId: string) {
        return this.post<void>({ path: `${this.usersPath}/${userId}/activate` });
    }

    async assignSeat(userId: string) {
        return this.post({ path: `${this.usersPath}/${userId}/assign-seat` });
    }

    async unassignSeat(userId: string) {
        return this.post({ path: `${this.usersPath}/${userId}/unassign-seat` });
    }

    async updateUserRole(userId: string, role: UserRole) {
        return this.put(`${this.usersPath}/${userId}/roles`, { role });
    }

    async updateUserSettings(userId: string, updateUserSettingsDto: UpdateUserSettingsDto) {
        return this.put<void>(`${this.usersPath}/${userId}`, updateUserSettingsDto);
    }

    async setUserCreditLimit(userId: string, creditLimit: number) {
        return this.post<void>({ path: `${this.usersPath}/${userId}/credit-limit`, payload: { creditLimit } });
    }

    // Invitations
    async inviteUsers({
        emails,
        role,
        organizationId,
    }: {
        emails: string[];
        role: UserRole;
        organizationId: string;
    }): Promise<{
        invited: { email: string }[];
        notInvited: { email: string; error: string }[];
    }> {
        return this.post({ path: `${this.usersPath}/invitations`, payload: { emails, organizationId, role } });
    }

    async cancelInvitation(invitationId: string) {
        return this.delete<void>(`${this.usersPath}/invitations/${invitationId}`);
    }

    async userInviteActivated(firebaseId: string, securityHash: string) {
        return this.patch<void>(`${this.usersPath}/invitations/${securityHash}`, { firebaseId });
    }

    async notifyInvitationClicked(invitationId: string, securityToken: string) {
        return this.patch<void>(`${this.usersPath}/invitations/${invitationId}/${securityToken}`, { status: 'read' });
    }

    async updateInvitationRole(invitationId: string, role: UserRole) {
        return this.patch(`${this.usersPath}/invitations/${invitationId}/update-role`, { role });
    }

    async getInvitationDetails(invitationId: string, securityToken: string) {
        return this.get<IInvitation>(`${this.usersPath}/invitations/${invitationId}/${securityToken}`);
    }

    async getRefererLinkedinUserId() {
        return this.get<string>(`${this.usersPath}/invitations/referer-linkedin-id`);
    }

    // Channel accounts
    async addChannelAccount(
        userId: string,
        createChannelAccountDto: CreateChannelAccountDto | CreateLinkedinChannelAccountDto,
        errorCallback?: (data: any) => void,
    ) {
        return this.post<ChannelAccountDto>({
            path: `${this.usersPath}/${userId}/channel-accounts`,
            payload: createChannelAccountDto,
            callback: errorCallback,
        });
    }

    async getChannelAccounts(userId: string) {
        try {
            const channelAccounts = await this.get<ChannelAccountDto[]>(`${this.channelAccountPath}`);
            return channelAccounts || [];
        } catch (error) {
            if ((error as any)?.message === 'HTTP GET failed, error: Request failed with status code 500') {
                return [];
            }
            throw error;
        }
    }

    async disconnectChannelAccount(userId: string, channelAccountId: string) {
        return this.delete<ChannelAccountDto>(`${this.usersPath}/${userId}/channel-accounts/${channelAccountId}`);
    }

    async getProxyCities(countryName: string) {
        return this.post<AccountLocationDto[]>({
            path: `${this.usersPath}/locations`,
            payload: { countryName: countryName },
        });
    }

    // Referrals
    async getReferralLink() {
        return this.get<IReferralLinkData>(`${this.usersPath}/referral-link`);
    }

    // Other
    async uploadImage(userId: string, imageDto: ImageUploadDto, callback?: (data: any) => void) {
        return this.post<string>({
            path: `${this.usersPath}/${userId}/upload-image`,
            payload: imageDto,
            callback,
        });
    }

    async getRemainingUploadProspectsCredits(userId: string) {
        return this.get<GetCsvImportCreditsResult>(`${this.usersPath}/${userId}/csv-import-credits`);
    }

    async getUserCreditsAndSeatSummary(userId: string) {
        return this.get<{
            seat: PublicSeatDto;
            seatSummary: { availableSeatsCount: number; occupiedSeatsCount: number; totalSeatsCount: number };
        }>(`${this.usersPath}/${userId}/credits`);
    }

    async updateBetaFeatures() {
        return this.patch<void>(`${this.usersPath}/beta-features`);
    }
}
