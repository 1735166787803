import { Typography } from '@mui/material';
import { styled } from '@mui/styles';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { copyToClipboard } from '../../../utils/copyToClipboard';
import { Button } from '../../atoms/button';
import { Modal } from '../../atoms/modal';
import { Notification } from '../../atoms/notification';
import thumbnail from '../../images/loom-thumbnail.png';

export const HelpModal = (props) => {
    const [copied, setCopied] = useState(false);
    const handleCopy = async () => {
        await copyToClipboard('733572450029-a2050rev93ddkc6b324a9uigcnj090bm.apps.googleusercontent.com');
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 3000);
    };
    return (
        <>
            <Modal
                zIndex={1500}
                isOpen={props.isOpen}
                closeModal={() => props.handleAuthenticationFlow(0)}
                shouldCloseOnOverlayClick={true}
            >
                <ModalContainer>
                    <div>
                        <a
                            href='https://www.loom.com/share/ce0030bba7b9453d94deab7512e257fc'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            <img src={thumbnail} alt='gsuite-video-guide-link' />
                        </a>
                    </div>
                    <div>
                        <div>
                            <Button onClick={() => props.handleAuthenticationFlow(0)} text='Close' variant='text' />
                        </div>
                        <div>
                            <Typography variant='h3'>Enable G Suite Before Connect</Typography>
                            <Typography>
                                To continue, please make sure your G suite Admin mark Zaplify as a trustful source for
                                accessing your G suite’s API.{' '}
                                <a
                                    rel='noopener noreferrer'
                                    href='https://www.loom.com/share/ce0030bba7b9453d94deab7512e257fc'
                                    target='_blank'
                                >
                                    Read more.
                                </a>
                            </Typography>
                            <Typography>Our OAuth client ID: </Typography>
                            <div>
                                <Typography>
                                    733572450029-a2050rev93ddkc6b324a9uigcnj090bm.apps.googleusercontent.com
                                </Typography>
                            </div>
                            <Button onClick={() => handleCopy()} text='Copy ID to clipboard' variant='secondary' />
                            <div>
                                <Button
                                    borderRadius='300px'
                                    width='170px'
                                    height='45px'
                                    href='https://admin.google.com'
                                    target='_blank'
                                    variant='outlined'
                                    text='1. Enable G-Suite'
                                />
                                <Button
                                    borderRadius='300px'
                                    width='170px'
                                    height='45px'
                                    onClick={() => props.handleAuthenticationFlow(2)}
                                    variant='contained'
                                    text='2. Connect Gmail'
                                />
                            </div>
                        </div>
                    </div>
                </ModalContainer>
            </Modal>
            <Notification
                text='Copied Client ID to clipboard'
                onClose={() => setCopied(false)}
                open={copied}
                vertical='bottom'
                horizontal='left'
            />
        </>
    );
};

HelpModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleAuthenticationFlow: PropTypes.func.isRequired,
};

export const ModalContainer = styled('div')(({ theme }: any) => ({
    display: 'flex',
    flexDirection: 'row',
    width: '664px',
    '& > div:nth-child(1)': {
        display: 'flex',
        alignItems: 'center',
        width: '155px',
        paddingLeft: '32px',
        '& > a > img': {
            width: '200px',
            height: '200px',
            marginBottom: '-40px',
        },
    },
    '& > div:nth-child(2)': {
        display: 'flex',
        flexDirection: 'column',
        width: '460px',
        '& > div:nth-child(1)': {
            alignSelf: 'flex-end',
            marginRight: '20px',
            marginTop: '20px',
        },
        '& > div:nth-child(2)': {
            padding: '0 50px 45px 50px',
            textAlign: 'left',
            alignItems: 'flex-start',
            display: 'flex',
            flexDirection: 'column',
            '& > h3:nth-child(1)': {
                fontWeight: '300',
                marginBottom: '20px',
            },
            '& > p:nth-child(2)': {
                marginBottom: '10px',
            },
            '& > p:nth-child(3)': {
                fontSize: '12px',
            },
            '& > div:nth-child(4)': {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                width: 400,
                '& > p': {
                    overflowX: 'auto',
                },
            },
            '& > span:nth-child(5)': {
                margin: '5px 0px 20px 0px',
            },
            '& > div:nth-child(6)': {
                '& > a': {
                    marginRight: '20px',
                    '& > button': {
                        padding: '9px 16px',
                    },
                },
            },
        },
    },
}));
