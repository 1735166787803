import React, { useEffect, useMemo, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from '@shadcn/ui/components/ui/dialog';
import { Progress } from '@shadcn/ui/components/ui/progress';
import { paths } from '../../../routes/paths';
import { Link } from 'react-router-dom';
import { DialogClose } from '@radix-ui/react-dialog';
import { UpgradeSection } from './upgrade-section';
import { useSdk } from '../../sdk';
import { useQuery as useQueryTan } from '@tanstack/react-query';
import { SearchParamDialog, useSearchParamDialog } from '@shadcn/ui/components/search-param-dialog';
import { useSubscription } from '@apollo/client';
import { GET_MESSAGES_SENT_FROM_APP_SUBSCRIPTION } from '@zaplify/graphql';
import { useAuth } from '../../providers/authentication-provider';
import { ReferralLinkField } from './referral-link-field';

interface ReferFriendsDialogProps {}

export const referFriendsDialogParam = 'refer-friends';

export const useReferFriendsDialog = () => {
    const { open, close } = useSearchParamDialog('refer-friends');

    return {
        openReferFriendsDialog: () => open(),
        closeReferFriendsDialog: close,
    };
};

export const ReferFriendsDialog: React.FC<ReferFriendsDialogProps> = () => {
    const {
        authState: { user },
    } = useAuth();
    const {
        userContacts: { getUserContactLimit },
    } = useSdk();
    const [previousNumberOfSentMessages, setPreviousNumberOfSentMessages] = useState<number | null>(null);
    const { openReferFriendsDialog } = useReferFriendsDialog();

    const { data: numberOfSentMessagesData } = useSubscription(GET_MESSAGES_SENT_FROM_APP_SUBSCRIPTION);
    const numberOfSentMessages = useMemo(
        () => numberOfSentMessagesData?.Messages_aggregate.aggregate.count,
        [numberOfSentMessagesData]
    );

    useEffect(() => {
        if (numberOfSentMessages) {
            if (previousNumberOfSentMessages === 4 && numberOfSentMessages >= 5) {
                openReferFriendsDialog();
            }
            setPreviousNumberOfSentMessages(numberOfSentMessages);
        }
    }, [numberOfSentMessages, previousNumberOfSentMessages, openReferFriendsDialog]);

    /** Limit */
    const { data: userContactLimit } = useQueryTan(getUserContactLimit());
    const { numberOfUserContactsGrantedPerReferral } = useFlags();

    return (
        <SearchParamDialog param={referFriendsDialogParam}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>
                        Earn {numberOfUserContactsGrantedPerReferral} contacts for every referred friend
                    </DialogTitle>
                </DialogHeader>
                <DialogDescription>
                    {`Refer a friend to increase your contact limit. Every time someone signs up using your link you get ${numberOfUserContactsGrantedPerReferral} extra contacts.`}
                </DialogDescription>
                <div className="flex flex-col gap-8">
                    {typeof userContactLimit === 'number' && (
                        <div className="flex flex-col">
                            <p className="text-m">Contacts earned: {userContactLimit - 50} / 300</p>
                            <div className="py-2">
                                <Progress value={Math.min((100 * (userContactLimit - 50)) / 300, 100)} className="" />
                            </div>
                            <p className="text-sm text-muted-foreground">
                                <b>Pro tip:</b> Use the{' '}
                                <DialogClose asChild>
                                    <Link
                                        to={
                                            (import.meta.env.MODE === 'staging' ||
                                            import.meta.env.MODE === 'development'
                                                ? '/new'
                                                : '') +
                                            paths.PLAYBOOKS_PATHS.NEW +
                                            '?template=referral'
                                        }
                                        className="text-text-brand-primary"
                                    >
                                        referral playbook
                                    </Link>
                                </DialogClose>{' '}
                                to have the system do the heavy lifting for you.
                            </p>
                        </div>
                    )}
                    {/* <ReferralLinkFieldOld /> */}
                    <ReferralLinkField />
                    {typeof userContactLimit === 'number' && <UpgradeSection />}
                </div>
            </DialogContent>
        </SearchParamDialog>
    );
};
