import { Typography, Box } from '@mui/material';
import { AccountResponseDto } from '@zaplify/prospects';
import { Text } from 'lucide-react';
import { useEffect, useState } from 'react';
import { SectionType, userClickedShowMoreInformationInGlobalSearch } from '../tracking';

export const DescriptionSection = ({ account }: { account: AccountResponseDto }) => {
    if (!account?.description) return null;

    const NUMBER_OF_CHARACTERS_SHOWN = 200;
    const [description, setDescription] = useState('');
    const [preview, setPreview] = useState('');
    const [showFullDescription, setShowFullDescription] = useState(false);

    useEffect(() => {
        const description = account.description
            .replace(/\\n/g, '\n')
            .replace(/\\r/g, '\n')
            .replace(/\\t/g, '\t')
            .replace(/\n\n/g, '\n');

        setDescription(description);
    }, [account.description]);

    useEffect(() => {
        const textPreview = createPreviewText(description, NUMBER_OF_CHARACTERS_SHOWN);
        if (textPreview) {
            setPreview(textPreview);
        }
    }, [description]);

    useEffect(() => {
        if (showFullDescription) {
            userClickedShowMoreInformationInGlobalSearch(SectionType.Description);
        }
    }, [showFullDescription]);

    useEffect(() => {
        setShowFullDescription(false);
    }, [account]);

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: 'var(--S-xs, 6px)',
                alignSelf: 'stretch',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    width: '20px',
                    height: '20px',
                    padding: '1.667px 3.333px',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Text size={20} strokeWidth={1} />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: 'var(--S-xs, 6px)',
                    flex: '1 0 0',
                }}
            >
                <Box
                    sx={{
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2,
                        alignSelf: 'stretch',
                    }}
                >
                    <Typography
                        sx={{
                            overflow: 'hidden',
                            color: 'var(--grey-palette-grey-5, #3A4B59)',
                            textOverflow: 'ellipsis',
                            fontSize: '13px',
                            fontWeight: 400,
                            lineHeight: '19px',
                        }}
                    >
                        {showFullDescription ? description : preview}
                    </Typography>
                </Box>
                {preview !== '' && (
                    <Typography
                        component="span"
                        onClick={() => setShowFullDescription(!showFullDescription)}
                        sx={{
                            color: 'var(--primary-20-base, #6A76FB)',
                            fontSize: '13px',
                            fontWeight: 500,
                            lineHeight: '19px',
                            cursor: 'pointer',
                        }}
                    >
                        {showFullDescription ? 'Show less' : 'Show more'}
                    </Typography>
                )}
            </Box>
        </Box>
    );
};

const createPreviewText = (text: string, limit: number) => {
    const descArr = text.split(' ');
    let preview = '';
    let i = 0;
    while (preview.length < limit && i < descArr.length) {
        preview += descArr[i] + ' ';
        i++;
    }

    if (preview.length === text.length) {
        return;
    }

    return preview.length > limit ? preview + '...' : preview;
};
