import { useState } from 'react';
import SearchHistoryRowStyled from './SearchHistoryRow.styled';
import { Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

export const SearchHistoryRow = ({ onClick, searchTerm }: { onClick: () => void; searchTerm: string }) => {
    const [isHovering, setIsHovering] = useState(false);

    const handleMouseOver = () => {
        setIsHovering(true);
    };

    const handleMouseOut = () => {
        setIsHovering(false);
    };
    return (
        <SearchHistoryRowStyled onClick={onClick} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
            <Typography>{searchTerm}</Typography>
            <SearchIcon sx={{ color: '#6B7985', display: isHovering ? 'inherit' : 'none' }} />
        </SearchHistoryRowStyled>
    );
};
