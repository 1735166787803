import { Box, Typography } from '@mui/material';

export const SectionTitle = ({ title, subtitle }: { title: string; subtitle?: string }) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '8px',
        }}
    >
        <Typography
            variant="h1"
            sx={{
                fontWeight: 600,
                lineHeight: '40px',
                letterSpacing: '-0.02em',
                marginBottom: '10px',
            }}
        >
            {title}
        </Typography>
        <Typography
            variant="body1"
            sx={{
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '24px',
                textAlign: 'left',
                marginBottom: '20px',
                color: '#475467',
            }}
        >
            {subtitle}
        </Typography>
    </Box>
);
