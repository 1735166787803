import { BaseSdk } from '../../base.sdk';
import { LinkedinProfileDto } from '@andsend/services/linkedin-profiles/shared';
import { ProspectDataDto } from '@zaplify/prospects';
export class LinkedinProfilesSdk extends BaseSdk {
    async getLinkedinProfileByMemberIdOrPublicIdentifier(memberIdOrPublicIdentifier: string) {
        return await this.get<LinkedinProfileDto>(`${this.linkedinProfilesPath}/${memberIdOrPublicIdentifier}`);
    }

    async addFoundLinkedinProfile(params: { prospectData?: ProspectDataDto; linkedinProfile?: LinkedinProfileDto }) {
        return await this.post({
            path: `${this.linkedinProfilesPath}/found-profile`,
            payload: params,
        });
    }

    async getLinkedinConnections() {
        return await this.get<string[]>(`${this.linkedinProfilesPath}/connections`);
    }
}
