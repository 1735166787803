import { FC, useRef, useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { cn } from '@shadcn/ui/lib/utils';
import { paths } from '../../../../routes/paths';
import { motion } from 'motion/react';

const navItems = [
    {
        label: 'Users',
        path: paths.NEW.ACCOUNT_MANAGEMENT_PATHS.USERS,
        description: 'User access and permissions',
    },
    {
        label: 'Apps',
        path: paths.NEW.ACCOUNT_MANAGEMENT_PATHS.APPS,
        description: 'Connect LinkedIn, Email, and CRM accounts',
    },
    {
        label: 'Memories',
        path: paths.NEW.ACCOUNT_MANAGEMENT_PATHS.MEMORIES,
        description: 'Global stored memories',
    },
    {
        label: 'Billing',
        path: paths.NEW.ACCOUNT_MANAGEMENT_PATHS.SUBSCRIPTION,
        description: 'Billing and subscription plans',
    },
    // {
    //     label: 'Outreach Settings',
    //     path: paths.NEW.ACCOUNT_MANAGEMENT_PATHS.OUTREACH_SETTINGS,
    //     description: 'Outreach preferences and settings',
    // },
    // {
    //     label: 'Organization Settings',
    //     path: paths.NEW.ACCOUNT_MANAGEMENT_PATHS.ORGANIZATION_SETTINGS,
    //     description: 'Organization details and settings',
    // },
];

export const AccountManagementNav: FC = () => {
    const location = useLocation();
    const [activeRect, setActiveRect] = useState<DOMRect | null>(null);
    const navRefs = useRef<(HTMLAnchorElement | null)[]>([]);

    // Find the active navigation item and update its position
    useEffect(() => {
        const activeIndex = navItems.findIndex((item) => location.pathname.includes('/' + item.path));
        if (activeIndex !== -1 && navRefs.current[activeIndex]) {
            const rect = navRefs.current[activeIndex]?.getBoundingClientRect();
            if (rect) {
                setActiveRect(rect);
            }
        }
    }, [location.pathname]);

    return (
        <>
            <div className="flex flex-col w-full h-full overflow-y-auto">
                <nav className="flex flex-col p-2 space-y-1 relative">
                    {/* Animated background for active item */}
                    {activeRect && (
                        <motion.div
                            className="absolute bg-accent rounded-md z-0 mt-1"
                            initial={false}
                            animate={{
                                height: activeRect.height,
                                width: activeRect.width,
                                x: 0,
                                y: activeRect.top - (navRefs.current[0]?.getBoundingClientRect().top || 0),
                            }}
                            transition={{
                                type: 'spring',
                                stiffness: 350,
                                damping: 30,
                            }}
                        />
                    )}

                    {navItems.map((item, index) => (
                        <NavLink
                            key={item.path}
                            to={item.path}
                            ref={(el) => (navRefs.current[index] = el)}
                            className={({ isActive }) =>
                                cn(
                                    'px-4 py-3 rounded-md flex items-center transition-colors z-10 relative',
                                    'hover:text-accent-foreground',
                                    isActive ? 'text-accent-foreground font-medium' : 'text-muted-foreground'
                                )
                            }
                        >
                            <div className="flex flex-col">
                                <span className="font-bold">{item.label}</span>
                                <span className="text-xs text-muted-foreground">{item.description}</span>
                            </div>
                        </NavLink>
                    ))}
                </nav>
            </div>
        </>
    );
};
