import AlternateEmail from '@mui/icons-material/AlternateEmail';
import LinkedIn from '@mui/icons-material/LinkedIn';
import Twitter from '@mui/icons-material/Twitter';
import { styled } from '@mui/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ChannelType } from '@zaplify/channel-accounts/shared';

const ChipContainer = styled(({ className, ...props }) => (
    <div className={classNames(className, props.channel)}>
        {props.icon}
        <span>{props.label}</span>
    </div>
))(({ theme }: any) => ({
    color: theme.palette.colors.white,
    display: 'inline-flex',
    alignItems: 'center',
    verticalAlign: 'middle',
    justifyContent: 'center',
    padding: theme.spacing(1, 1.5),
    borderRadius: '10px',
    '& > span': {
        fontWeight: 500,
        fontSize: '10px',
        marginLeft: theme.spacing(0.5),
    },
    '& > svg': {
        fontSize: '20px',
    },

    '&.EMAIL': {
        background: theme.palette.gradients.red,
    },
    '&.LINKEDIN': {
        background: theme.palette.gradients.blue,
    },
    '&.TWITTER': {
        background: theme.palette.gradients.twitter,
    },
}));

export const ChannelChip = (props) => {
    const { channel } = props;
    let icon: any = null;
    let label = '';
    switch (channel) {
        case ChannelType.LINKEDIN:
            icon = <LinkedIn />;
            label = 'LinkedIn';
            break;
        case ChannelType.EMAIL:
            icon = <AlternateEmail />;
            label = 'Email';
            break;
        case ChannelType.TWITTER:
            icon = <Twitter />;
            label = 'Twitter';
            break;
        default:
            break;
    }

    return <ChipContainer channel={channel} icon={icon} label={label} />;
};

ChannelChip.propTypes = {
    channel: PropTypes.string.isRequired,
};
