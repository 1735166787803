import { companyNameCorrectlyCased } from '../../../../enums/company-name';
import { templateImages } from '../../../../hooks/playbooks/playbook-images';
import { useQuery as useQueryTan } from '@tanstack/react-query';
import { useSdk } from '../../../sdk';
import { useEffect, useState } from 'react';

export type Template =
    | 'investors'
    | 'sales'
    | 'network'
    | 'customer'
    | 'outreach'
    | 'referral'
    | 'productLaunch'
    | 'aussie'
    | 'easter';
export interface PlaybookTemplate {
    name: string;
    tone: string;
    purpose: string;
    context: string;
    preview: {
        title: string;
        description: string;
        imageSrc: string;
    };
}

export const usePlaybookTemplates = () => {
    const images = templateImages;
    const {
        user: { getUserReferralLink },
    } = useSdk();
    const { data } = useQueryTan(getUserReferralLink());
    const [referralLink, setReferralLink] = useState<string>('<referral link>');

    useEffect(() => {
        if (data?.id) {
            setReferralLink(`${window.location.protocol}//${window.location.host}/register?referralLinkId=${data.id}`);
        }
    }, [data]);

    const playbookTemplates: Record<Template, PlaybookTemplate> = {
        investors: {
            name: 'Find Investors',
            tone: 'Professional and informative',
            purpose: 'Attract and book pitch meetings with new investors',
            context:
                'Start with introduce yourself and mention any common interests or industry overlaps.\n' +
                'Begin the conversation by highlighting the unique value proposition of your company and the problem it solves.\n' +
                'Focus on market opportunity and growth potential without delving into excessive technical details.\n' +
                'Avoid hard-selling; instead, aim to build a genuine relationship by asking insightful questions and showing interest in the investor’s expertise.\n' +
                'Emphasize milestones and traction achieved, and be clear about your fundraising goals.\n' +
                'Steer clear of overused buzzwords and be honest about challenges faced.\n' +
                'The goal is to engage the investor in a meaningful dialogue that leads to a meeting.',
            preview: {
                title: 'Find Investors Playbook',
                description: 'Attract new investors',
                imageSrc: images.investors,
            },
        },
        sales: {
            name: 'Sales',
            tone: 'Casual, short sentences. Be open and curious about their problem, not pushy.',
            purpose: 'Start sales conversations',
            context:
                'Focus on building genuine lasting connections, nobody wants to get spammed with cold messages.\n\n' +
                'Getting started: We want to start dialogs between two people, not a one way conversation. Keep it small, casual and build trust over time.\n' +
                "Understand first: Show genuine interest in your contact's challenges and how you can help them.\n" +
                'Offer real value: Clearly explain how you can help, in ways that matter to them. Do not be afraid to admit when something is not a good fit.\n' +
                'Build trust: Prioritize credibility and authenticity to build opportunities over time.\n' +
                'Nurture over time: Stay connected to strengthen relationships and turn acquaintances into long-term partnerships.\n' +
                'Sometimes simply wishing them a good holiday or congratulating on a recent achievement is the best way to stay top-of-mind.',
            preview: {
                title: 'Sales Playbook',
                description: 'Increase your sales conversion',
                imageSrc: images.sales,
            },
        },
        network: {
            name: 'Expand Network',
            tone: 'Friendly, engaging, and personalized. Show genuine interest in connecting.',
            purpose: 'Build and expand your professional network by initiating meaningful connections.',
            context:
                'Express a sincere desire to connect and learn more about them.\n' +
                'Avoid generic invitations; make each message unique to the recipient.\n' +
                'Ask open-ended questions to encourage dialogue and show that you value their insights.\n' +
                'Focus on building a genuine relationship rather than making immediate requests or pitches.\n' +
                'Be respectful of their time, and follow up appropriately to nurture the connection.',
            preview: {
                title: 'Expand Network Playbook',
                description: 'Grow your professional network',
                imageSrc: images.network,
            },
        },
        ...(new Date().getMonth() === 3
            ? {
                  easter: {
                      name: 'Easter Networking 🐣',
                      tone: 'Cheerful and positive',
                      purpose: 'Spread easter cheer and goodwill',
                      context:
                          'Do not continue the previous topic in the conversation. Instead, start a new conversation topic and focus on the easter greeting.\n\n' +
                          'If I have NOT wished them a happy easter I want the message to focus ONLY on the easter greeting. Disregard previous messages and notes.\n' +
                          "If I've already wished them a happy easter I want to focus on strengthening the relationship.\n" +
                          'Send cheerful wishes and easter greetings to clients, partners, and colleagues.\n' +
                          'You can optionally add a light Easter emoji (like 🐣🌸🥚) if it fits the tone.\n' +
                          'Keep it short and positive.\n' +
                          'The goal is to strengthen relationships and leave a positive impression.\n',
                      preview: {
                          title: 'Easter Networking',
                          description: 'Strengthen relationships and spread easter cheer',
                          imageSrc: images.easter,
                      },
                  },
              }
            : {}),
        outreach: {
            name: 'Post Event',
            tone: 'Professional and concise',
            purpose: 'Follow up and make long-term connections after an event',
            context:
                'Thank the person for the conversation and express interest in continuing the dialogue.\n' +
                'Reference generic topics discussed or insights that might have been shared during the event.\n' +
                'Offer to share resources or insights that might be relevant to the recipient.\n' +
                'Include a call-to-action to encourage further engagement or a follow-up meeting.',
            preview: {
                title: 'Follow-Up After Event',
                description: 'For conferences and webinars',
                imageSrc: images.outreach,
            },
        },
        referral: {
            name: `${companyNameCorrectlyCased} Referral`,
            tone: 'Excited and appreciative',
            purpose: `Tell my friends about ${companyNameCorrectlyCased} and encourage them to sign up`,
            context:
                `Craft messages to friends and colleagues to encourage them to sign up for ${companyNameCorrectlyCased} using your referral link.\n` +
                `${companyNameCorrectlyCased} streamlines outreach by combining LinkedIn and email communications, using AI to craft personalized messages and follow-ups.\m\n` +
                `It helps you find and engage potential customers, investors, and partners from a vast network, integrating with your CRM for seamless contact management.\n` +
                `Always include this exact link (unless it has already been sent before): ${referralLink}`,
            preview: {
                title: `${companyNameCorrectlyCased} Referral Playbook`,
                description: 'Refer friends and spread the word',
                imageSrc: images.referral,
            },
        },
        productLaunch: {
            name: 'Product Launch',
            tone: 'Exciting and informative',
            purpose: 'Generate buzz and awareness for a new product',
            context:
                'Create anticipation by teasing features and benefits before the launch.\n' +
                'Engage your audience with sneak peeks and exclusive content.\n' +
                'Highlight the unique selling points and how it solves customer problems.\n' +
                'Encourage sharing and word-of-mouth by offering incentives or contests.\n' +
                'Follow up post-launch with updates and customer testimonials.',
            preview: {
                title: 'Product Launch Playbook',
                description: 'Create excitement for your new product',
                imageSrc: images.productLaunch,
            },
        },
        customer: {
            name: 'Existing Customer',
            tone: 'Supportive and informative',
            purpose: 'Engage and retain existing customers',
            context:
                'Proactively offer support and address any potential issues before they escalate.\n' +
                'Show appreciation for their loyalty and regularly ask for feedback to understand their needs and improve your products or services.\n' +
                'Keep them informed about updates and developments that are relevant to them.\n' +
                'Aim to build a long-term relationship by demonstrating that you value their business.\n' +
                'Avoid generic messages; make each interaction meaningful and customer-focused.\n' +
                'The goal is to enhance customer satisfaction and encourage repeat business through genuine engagement.',
            preview: {
                title: 'Existing Customer Playbook',
                description: 'Strategies for existing customers',
                imageSrc: images.customer,
            },
        },
        aussie: {
            name: 'Aussie 🤠',
            tone: 'Like a true blue Aussie',
            purpose: 'Have a chinwag with a bonza Aussie flair',
            context:
                'G’day mate! Speak like a true blue Aussie to engage with your mates and swap a yarn.\n' +
                'Crikey, we’re here to deliver some ripper messages that’ll make you chuckle.\n' +
                'Don’t be a drongo; keep it light and breezy with a fair dinkum Aussie vibe.\n' +
                'Throw in some Aussie slang and a bit of larrikin humour to get the chinwag going!.\n' +
                "The outback Aussie slang should be as thick as a bushman's beard; non-Aussies should be scratching their heads like a roo in headlights.\n" +
                'Fair shake of the sauce bottle!',
            preview: {
                title: 'Aussie Playbook 🇦🇺',
                description: 'Swap a yarn with your mates',
                imageSrc: images.aussie,
            },
        },
    } as Record<Template, PlaybookTemplate>;

    const getTemplate = (template: Template): PlaybookTemplate => {
        return playbookTemplates[template];
    };

    const getTemplatePreviews = (): { title: string; description: string; imageSrc: string; template: Template }[] => {
        return Object.entries(playbookTemplates).map(([template, { preview }]) => ({
            ...preview,
            template: template as Template,
        }));
    };

    return { getTemplate, getTemplatePreviews };
};
