import { ICommand, Topics } from '@zaplify/common';
import { CoreMessage } from 'ai';

export class CreateThreadCommand extends ICommand<{ threadId: string }> {
    public static override readonly TOPIC = Topics.PROSPECTS;

    public readonly userId: string;
    public readonly title?: string;
    public readonly lastMessageAt?: Date;
    public readonly metadata?: unknown;
    public readonly externalId?: string;

    constructor(input: {
        userId: string;
        title?: string;
        lastMessageAt?: Date;
        metadata?: unknown;
        externalId?: string;
    }) {
        super();
        this.userId = input.userId;
        this.title = input.title;
        this.lastMessageAt = input.lastMessageAt;
        this.metadata = input.metadata;
        this.externalId = input.externalId;
    }
}
