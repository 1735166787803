import * as React from 'react';
import { Avatar, AvatarFallback, AvatarImage } from '@shadcn/ui/components/ui/avatar';

import { useMemo } from 'react';
import { cva } from 'class-variance-authority';
import { useQuery } from '@tanstack/react-query';
import { useSdk } from '../sdk';

interface UserAvatarProps {
    avatarUrl: string;
    fullName: string;
    size?: 'sm' | 'md' | 'lg' | 'xl';
}

const getInitials = (name: string) => {
    // Remove any emojis from the name
    const cleanName = name
        .replace(
            /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/gu,
            ''
        )
        .trim();
    const parts = cleanName.split(' ').filter(Boolean);
    const initials = parts.length > 1 ? parts[0][0] + parts[parts.length - 1][0] : cleanName.slice(0, 2);
    return initials.toUpperCase();
};

const avatarVariants = cva('relative flex shrink-0 overflow-hidden rounded-full', {
    variants: {
        size: {
            sm: 'h-6 w-6',
            md: 'h-8 w-8',
            lg: 'h-10 w-10 text-lg',
            xl: 'h-12 w-12 text-xl',
        },
    },
    defaultVariants: {
        size: 'md',
    },
});

export const ContactAvatar: React.FC<UserAvatarProps> = ({ avatarUrl, fullName, size = 'md' }) => {
    const linkedinProfileImgUrl = useMemo(() => {
        if (avatarUrl) {
            return avatarUrl;
        }
        return null;
    }, [avatarUrl]);

    const name = useMemo(() => {
        if (fullName) {
            return fullName;
        }
        return '';
    }, [fullName]);

    return (
        <Avatar className={avatarVariants({ size })}>
            <AvatarImage src={linkedinProfileImgUrl} alt={name} />
            <AvatarFallback>{getInitials(name)}</AvatarFallback>
        </Avatar>
    );
};
