import { ICommand, Topics } from '@zaplify/common';
import { RequireAtLeastOne } from '@zaplify/utils';

export class SnoozeOutreachSuggestionCommand extends ICommand<void> {
    public static override readonly TOPIC = Topics.CAMPAIGNS;

    constructor(
        public readonly params: {
            userId: string;
            daysToSnooze: number | 'auto';
        } & RequireAtLeastOne<{ suggestionsId?: string; prospectId?: string }>,
    ) {
        super();
    }
}
