import { ICommand, Topics } from '@zaplify/common';
import { GroupDto } from '../dtos';
import { AssistantSettingsDto } from '@zaplify/services/messaging/shared';

export class CreateGroupCommand extends ICommand<GroupDto> {
    public static override readonly TOPIC = Topics.PROSPECTS;

    constructor(
        public readonly group: Omit<GroupDto, 'id' | 'createdAt' | 'updatedAt' | 'assistantSettingsId'>,
        public readonly assistantSettings?: Partial<AssistantSettingsDto>,
    ) {
        super();
    }
}
