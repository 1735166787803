import { styled } from '@mui/styles';

export const TopContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    padding: '35px 52px 26px 72px',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #E4E6EB',
    '& > a > button': {
        border: '1px solid rgb(228, 230, 235)',
    },
    '& > div:nth-child(2)': {
        display: 'flex',
        alignItems: 'center',
        '& > div:nth-child(2)': {
            marginLeft: 10,
        },
        '& > div:nth-child(3)': {
            padding: '15px 20px',
            marginLeft: 20,
            border: '1px solid #E4E6EB',
            boxSizing: 'border-box',
            borderRadius: 8,
        },
    },
}));

export const FieldMappingContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    borderRight: '1px solid #E4E6EB',
    padding: '26px 45px 30px 60px',
    width: '55%',
    boxSizing: 'border-box',
    '& > div:nth-child(3)': {
        display: 'flex',
        width: '100%',
        padding: '20px 40px',
        justifyContent: 'space-between',
        boxSizing: 'border-box',
        maxWidth: 408,
        '& > img': {
            height: 16,
        },
    },
    '& > div:nth-child(4)': {
        marginBottom: 5,
        '& > div': {
            alignItems: 'center',
            marginBottom: 5,
        },
    },
    '& > p:nth-child(6)': {
        marginTop: 10,
    },
}));

export const ConfigurationContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: '26px 45px 30px 60px',
    width: '45%',
    boxSizing: 'border-box',
    '& > div': {
        '& > label': {
            marginTop: 10,
            '& > span:nth-child(2)': {
                fontSize: 13,
                color: '#3A4B59',
            },
        },
    },
}));

export const FormContainer = styled('div')(({ theme }) => ({
    width: '50%',
    maxWidth: '231px',
}));

export const LinkedInFormContainer = styled('div')(({ className, theme }) => ({
    width: '100%',
    padding: '2px 10px',
    maxWidth: 461,
    '&.linkedin-closed': {
        background: '#FFFFFF',
        border: '0.8px solid rgb(196,196,196)',
        boxSizing: 'border-box',
        borderRadius: 8,
    },
}));
