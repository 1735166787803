import { useIsMobile } from '@shadcn/ui/hooks/use-mobile';
import { cn } from '@shadcn/ui/lib/utils';

interface SearchBoxLayoutProps {
    leftSide: React.ReactNode;
    rightSide?: React.ReactNode;
}

export function SearchBoxLayout({ leftSide, rightSide }: SearchBoxLayoutProps) {
    const isMobile = useIsMobile();

    return (
        <div className="flex flex-1 min-h-0">
            <div
                className={cn('p-2 flex-1 overflow-y-auto', rightSide ? 'w-1/2' : 'w-full', {
                    hidden: isMobile && rightSide,
                })}
            >
                {leftSide}
            </div>
            <div
                className={cn(
                    'p-2 w-1/2 border-l flex-shrink-0 overflow-y-auto animate-in fade-in-0 slide-in-from-left-1/3 duration-200 ease-in-out',
                    {
                        'w-full': isMobile && rightSide,
                        hidden: !rightSide,
                    }
                )}
            >
                {rightSide}
            </div>
        </div>
    );
}
