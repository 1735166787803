import { IUntypedCommand, Topics } from '@zaplify/common';
import { ProspectDataExperienceDto } from '../prospects';

export class SelectProspectMainExperienceCommand extends IUntypedCommand {
    public static override readonly TOPIC = Topics.PROSPECTS;
    constructor(
        readonly prospectId: string,
        readonly userId: string,
        readonly experience: ProspectDataExperienceDto | string,
    ) {
        super();
    }
}
