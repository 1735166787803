import { GlobeIcon, LinkedinIcon } from 'lucide-react';
import { useSuggestionsFeed } from '../../../hooks/use-suggestions-feed';

export const AccountCardChannels = () => {
    const { currentAccountData: accountData } = useSuggestionsFeed();
    const website = accountData?.data?.website;

    if (!website && !accountData?.linkedinUrl) return null;

    return (
        <div className="flex flex-row items-center justify-start gap-4">
            {website && (
                <div className="flex flex-row items-center justify-start gap-2 text-muted-foreground">
                    <GlobeIcon className="w-4 h-4" />
                    <a
                        href={`${website}`}
                        className="text-sm hover:underline"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {website?.replace(/^(https?:\/\/)?(www\.)?/, '').replace(/^http:\/\//, '')}
                    </a>
                </div>
            )}
            {accountData?.linkedinUrl && (
                <div className="flex flex-row items-center justify-start gap-2 text-muted-foreground">
                    <LinkedinIcon className="w-4 h-4" />
                    <a
                        href={`${accountData?.linkedinUrl}`}
                        className="text-sm hover:underline"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        LinkedIn page
                    </a>
                </div>
            )}
        </div>
    );
};
