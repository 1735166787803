import * as React from 'react';
import {
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogFooter,
    DialogDescription,
    DialogClose,
} from '@shadcn/ui/components/ui/dialog';
import { Button } from '@shadcn/ui/components/ui/button';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { Input } from '@shadcn/ui/components/ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@shadcn/ui/components/ui/select';
import {
    FormField,
    FormItem,
    FormLabel,
    FormControl,
    FormDescription,
    FormMessage,
    Form,
} from '@shadcn/ui/components/ui/form';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Tooltip, TooltipContent, TooltipTrigger } from '@shadcn/ui/components/ui/tooltip';
import { Info, Loader2 } from 'lucide-react';
import { SearchParamDialog, useSearchParamDialog } from '@shadcn/ui/components/search-param-dialog';
import { useQuery as useQueryTan } from '@tanstack/react-query';
import { useSdk } from '../../sdk';
import { useProspectSync } from '../../hooks/use-prospect-sync';
import { useLinkedin } from '../../hooks/use-linkedin';
import { getProspectByIdCacheKey } from '../../sdk/internal/prospect.sdk';
import { useToast } from '@shadcn/ui/hooks/use-toast';

class LinkedinProfileError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'LinkedinProfileError';
    }
}

const formSchema = z.object({
    jobPosition: z.string().min(2, {
        message: 'Need to select a job position.',
    }),
    linkedinProfileUrl: z
        .string()
        .url()
        .regex(/^https:\/\/(www\.)?linkedin\./, {
            message: 'Please enter a valid LinkedIn profile URL.',
        }),
    email: z
        .string()
        .email({
            message: 'Please enter a valid email address.',
        })
        .or(z.literal(''))
        .or(z.literal(null))
        .optional(),
    phone: z
        .string()
        .regex(/^(\+?[0-9\- ]+)?$/, {
            message: 'Only "+" sign, digits, spaces and hyphens are allowed',
        })
        .transform((val) => (val && val.trim() === '' ? null : val))
        .nullable()
        .optional(),
});

export const useEditContactDetailsDialog = () => {
    const { open, close, value } = useSearchParamDialog('edit-contact-details');

    return {
        openEditContactDetailsDialog: (prospectId: string) => open(prospectId),
        closeEditContactDetailsDialog: close,
        value,
    };
};

export const EditContactDetailsDialog: React.FC = () => {
    const { value: prospectId, closeEditContactDetailsDialog } = useEditContactDetailsDialog();
    const { syncProspect } = useProspectSync();
    const { getLinkedInProfile, extensionStatus } = useLinkedin();
    const [loading, setLoading] = React.useState(false);
    const { toast } = useToast();
    const client = useQueryClient();

    const {
        prospect: { getProspectById },
    } = useSdk();

    const { data } = useQueryTan(getProspectById(prospectId));
    const {
        prospect: { updateProspectData, selectProspectMainExperience },
    } = useSdk();
    const { mutateAsync: updateProspect } = useMutation(updateProspectData());
    const { mutateAsync: setMainExperience } = useMutation(selectProspectMainExperience());

    const prospect = useMemo(() => data?.data, [data]);

    const getPositionString = (exp: { occupationTitle?: string; organizationName?: string }) => {
        return (
            (exp?.occupationTitle || '') +
            (exp?.occupationTitle && exp?.organizationName ? ' at ' : '') +
            (exp?.organizationName || '')
        );
    };
    const ongoingExperiences = prospect?.experiences.filter((exp) => !exp.occupationEndDate) ?? [];
    const currentPosition = useMemo(() => getPositionString(prospect), [prospect]);
    const currentPositionAlternatives = ongoingExperiences?.map((exp) => getPositionString(exp));

    const phoneNumber = useMemo(() => prospect?.phoneNumbers?.[0]?.number, [prospect]);

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            jobPosition: currentPosition,
            linkedinProfileUrl: prospect?.linkedinProfileUrl,
            email: prospect?.email,
            phone: phoneNumber,
        },
    });

    const { mutate: submitMutate } = useMutation({
        mutationFn: async (values: z.infer<typeof formSchema>) => {
            setLoading(true);
            const dirtyFields = form.formState.dirtyFields;
            const dirtyValues = Object.fromEntries(
                Object.entries(values).filter(([key]) => dirtyFields[key])
            ) as z.infer<typeof formSchema>;

            let selectedExperience: (typeof prospect.experiences)[0] | undefined;
            if ('jobPosition' in dirtyValues) {
                selectedExperience = prospect.experiences.find(
                    (exp) => getPositionString(exp) === dirtyValues.jobPosition
                );
            }
            let newLinkedinProfileUrl: string | undefined;
            if (
                'linkedinProfileUrl' in dirtyValues &&
                dirtyValues.linkedinProfileUrl.trim() !== prospect?.linkedinProfileUrl
            ) {
                newLinkedinProfileUrl = dirtyValues.linkedinProfileUrl.trim();
            }
            let newEmail: string | undefined;
            if ('email' in dirtyValues && dirtyValues.email.trim() !== prospect?.email) {
                newEmail = dirtyValues.email.trim();
            }
            let newPhone: string | undefined | null;
            if ('phone' in dirtyValues && dirtyValues.phone !== phoneNumber) {
                newPhone = dirtyValues.phone;
            }

            if (selectedExperience) {
                await setMainExperience({
                    prospectId,
                    experience: selectedExperience,
                });
            }
            if (newLinkedinProfileUrl) {
                if (extensionStatus !== 'CONNECTED') {
                    throw new LinkedinProfileError(
                        "Couldn't verify LinkedIn profile URL. Make sure you're logged in to LinkedIn and have the extension installed."
                    );
                }
                const profile = await getLinkedInProfile(newLinkedinProfileUrl);
                if (!profile?.memberId) {
                    throw new LinkedinProfileError('There is no LinkedIn user with this URL');
                }
                await syncProspect({
                    prospectId,
                    linkedinProfileUrl: newLinkedinProfileUrl,
                });
            }
            if (newEmail !== undefined || newPhone !== undefined) {
                await updateProspect({
                    prospectId,
                    prospectData: {
                        email: newEmail,
                        phoneNumbers: newPhone ? [{ number: newPhone }] : [],
                    },
                });
            }
        },
        onError: (error) => {
            if (error instanceof LinkedinProfileError) {
                form.setError('linkedinProfileUrl', { message: error.message });
            } else {
                toast({
                    title: 'Something went wrong trying to edit contact details',
                    variant: 'error',
                });
            }
        },
        onSettled: () => {
            setLoading(false);
        },
        onSuccess: () => {
            toast({
                title: 'Contact details updated',
                variant: 'success',
            });
            client.invalidateQueries({ queryKey: getProspectByIdCacheKey(prospectId) });
            closeEditContactDetailsDialog();
        },
    });

    useEffect(() => {
        form.reset({
            jobPosition: currentPosition,
            linkedinProfileUrl: prospect?.linkedinProfileUrl,
            email: prospect?.email,
            phone: phoneNumber,
        });
    }, [prospect, currentPosition, phoneNumber]);

    function onSubmit(values: z.infer<typeof formSchema>) {
        submitMutate(values);
    }

    const handleSubmit = () => {
        form.handleSubmit(onSubmit)();
    };

    if (!data) {
        return null;
    }

    return (
        <SearchParamDialog param={'edit-contact-details'}>
            <DialogContent className="max-h-[90vh] overflow-y-auto">
                <Form {...form}>
                    <div className="flex flex-col gap-4 max-w-full overflow-hidden px-0.5 pb-1">
                        <DialogHeader>
                            <DialogTitle>Edit contact details</DialogTitle>
                            <DialogDescription>Manage the contact details of {prospect?.fullName}</DialogDescription>
                        </DialogHeader>
                        <FormField
                            control={form.control}
                            name="jobPosition"
                            render={({ field }) => (
                                <FormItem className="w-full">
                                    <FormLabel>Job position</FormLabel>
                                    <Select
                                        // value={field.value.toString()}
                                        onValueChange={field.onChange}
                                    >
                                        <FormControl>
                                            <SelectTrigger className="max-w-full">
                                                <SelectValue placeholder={field.value} className="text-ellipsis" />
                                            </SelectTrigger>
                                        </FormControl>
                                        <SelectContent>
                                            {currentPositionAlternatives.map((position, index) => (
                                                <SelectItem key={index} value={position} className="truncate">
                                                    {position}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                    <FormDescription className="flex gap-1 items-center">
                                        The primary position of the contact
                                        <Tooltip>
                                            <TooltipTrigger asChild>
                                                <Info className="w-3 h-3 stroke-[2]" />
                                            </TooltipTrigger>
                                            <TooltipContent>
                                                <p>Used for providing relevant messages and actions to the contact.</p>
                                            </TooltipContent>
                                        </Tooltip>
                                    </FormDescription>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="linkedinProfileUrl"
                            render={({ field }) => (
                                <FormItem className="w-full">
                                    <FormLabel>LinkedIn profile URL</FormLabel>
                                    <FormControl>
                                        <Input {...field} className="max-w-full" />
                                    </FormControl>
                                    <FormDescription>Connect the contact's linkedin profile to Andsend</FormDescription>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="email"
                            render={({ field }) => (
                                <FormItem className="w-full">
                                    <FormLabel>Email</FormLabel>
                                    <FormControl>
                                        <Input {...field} className="max-w-full" />
                                    </FormControl>
                                    <FormDescription>Connect the contact's email to Andsend</FormDescription>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="phone"
                            render={({ field }) => (
                                <FormItem className="w-full">
                                    <FormLabel>Phone</FormLabel>
                                    <FormControl>
                                        <Input {...field} className="max-w-full" />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                    <DialogFooter className="mt-4">
                        <DialogClose asChild>
                            <Button variant="outline">Cancel</Button>
                        </DialogClose>
                        <Button type="submit" onClick={handleSubmit} disabled={!form.formState.isDirty}>
                            {loading ? (
                                <>
                                    <Loader2 className="h-5 w-5 animate-spin text-background-brand-primary" />
                                    <span className="ml-2">Saving...</span>
                                </>
                            ) : (
                                'Save changes'
                            )}
                        </Button>
                    </DialogFooter>
                </Form>
            </DialogContent>
        </SearchParamDialog>
    );
};
