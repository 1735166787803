/* eslint-disable no-use-before-define */
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { styles } from './styles';

export default function Search({ placeholder, handleAddCompanyValue, filteredList, companyName, disabled }) {
    const classes = styles();

    return (
        <Autocomplete
            freeSolo
            className={classes.root}
            value={companyName}
            options={companyName ? filteredList.map((option) => option.name) : []}
            isOptionEqualToValue={(option, value) => option === value}
            onInputChange={(_, value) => handleAddCompanyValue(value)}
            disabled={disabled}
            renderInput={(params) => (
                <TextField
                    className={classes.input}
                    {...params}
                    margin="normal"
                    variant="outlined"
                    placeholder={placeholder}
                    InputLabelProps={{ style: { color: '#96A2AC', fontSize: '14px' }, shrink: false }}
                />
            )}
        />
    );
}
