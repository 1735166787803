import { BaseSdk } from '../../base.sdk';

export class OutreachSuggestionsSdk extends BaseSdk {
    async snoozeOutreachSuggestion(prospectId: string, daysToSnooze: number | 'auto') {
        return await this.post<void>({
            path: `${this.outreachSuggestionPath}/prospect/${prospectId}/snooze`,
            payload: { daysToSnooze },
        });
    }

    async dismissOutreachSuggestionByProspectId(prospectId: string) {
        return await this.delete<void>(`${this.outreachSuggestionPath}/prospect/${prospectId}`);
    }

    async dismissOutreachSuggestion(suggestionsId: string) {
        return await this.delete<void>(`${this.outreachSuggestionPath}/${suggestionsId}`);
    }

    async markReadOutreachSuggestion(suggestionsId: string) {
        return await this.post<void>({ path: `${this.outreachSuggestionPath}/${suggestionsId}/markRead` });
    }

    async generateOutreachCompletionMetrics(regenerateLastXDays: number) {
        return await this.post<void>({
            path: `${this.outreachSuggestionPath}/generate-metrics`,
            payload: { regenerateLastXDays },
        });
    }
}
