import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { SmallModalContainer } from './containers';
import { Modal } from '../../atoms/modal';
import { Button } from '../../atoms/button';

export const ConfirmationModal = (props) => {
    const [isOpen, setIsOpen] = useState(true);
    return (
        <Modal
            style={{
                content: {
                    width: '460px',
                    maxWidth: '94%',
                    borderRadius: '4px',
                    top: '50%',
                    left: '50%',
                    boxShadow: '0px 2px 20px rgba(0, 15, 94, 0.05)',
                },
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.4)',
                },
            }}
            closeModal={props.closeModal || (() => setIsOpen(false))}
            isOpen={props.closeModal ? props.isOpen : isOpen}
            zIndex={props.zIndex}
        >
            <SmallModalContainer>
                <div>
                    <Typography variant="h3">{props.title}</Typography>
                    <Typography>{props.body}</Typography>
                </div>
                <div>
                    <Button
                        fullWidth
                        borderRadius="0"
                        variant="contained"
                        classes={(props.variant === 'greyRed' && 'grey') || undefined}
                        onClick={props.leftButtonClick}
                        text={props.leftButtonText}
                    />
                    <Button
                        fullWidth
                        borderRadius="0"
                        variant="contained"
                        classes={'red'}
                        onClick={props.rightButtonClick}
                        text={props.rightButtonText}
                    />
                </div>
            </SmallModalContainer>
        </Modal>
    );
};

ConfirmationModal.propTypes = {
    title: PropTypes.string,
    body: PropTypes.string,
    zIndex: PropTypes.number,
    isOpen: PropTypes.bool,
    closeModal: PropTypes.func,
    variant: PropTypes.oneOf(['blueRed', 'greyRed']),
    leftButtonText: PropTypes.string.isRequired,
    rightButtonText: PropTypes.string.isRequired,
    leftButtonClick: PropTypes.func.isRequired,
    rightButtonClick: PropTypes.func.isRequired,
};
