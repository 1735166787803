import React from 'react';

type Props = {
    color?: string;
};

function CloseIcon({ color }: Props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="none" viewBox="0 0 50 50">
            <g filter="url(#filter0_d_4793:91894)">
                <path
                    fill="#fff"
                    stroke="#E4E6EB"
                    strokeWidth="0.8"
                    d="M6.4 23C6.4 12.727 14.727 4.4 25 4.4c10.273 0 18.6 8.327 18.6 18.6 0 10.273-8.328 18.6-18.6 18.6-10.273 0-18.6-8.328-18.6-18.6z"
                ></path>
                <g clipPath="url(#clip0_4793:91894)">
                    <path
                        fill={color || '#6B7985'}
                        d="M32.583 16.944l-1.527-1.527L25 21.473l-6.056-6.056-1.527 1.527L23.471 23l-6.056 6.056 1.528 1.527L25 24.528l6.056 6.055 1.527-1.527L26.527 23l6.056-6.056z"
                    ></path>
                </g>
            </g>
            <defs>
                <filter
                    id="filter0_d_4793:91894"
                    width="50"
                    height="50"
                    x="0"
                    y="0"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset dy="2"></feOffset>
                    <feGaussianBlur stdDeviation="3"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0.439216 0 0 0 0 0.564706 0 0 0 0 0.690196 0 0 0 0.07 0"></feColorMatrix>
                    <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_4793:91894"></feBlend>
                    <feBlend in="SourceGraphic" in2="effect1_dropShadow_4793:91894" result="shape"></feBlend>
                </filter>
                <clipPath id="clip0_4793:91894">
                    <path fill="#fff" d="M0 0H26V26H0z" transform="translate(12 10)"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default CloseIcon;
