import { useState, useEffect, useMemo } from 'react';
import { SearchPreviewRowRoot } from './search-preview-row';
import { Avatar, AvatarFallback, AvatarImage } from '@shadcn/ui/components/ui/avatar';
import { Button } from '@shadcn/ui/components/ui/button';
import { debounce } from '../../../../helpers/debounce';
import { getInitials } from '../../../functions/get-initials';
import { useSdk } from '../../../sdk';
import { useQuery } from '@tanstack/react-query';
import { PeopleResponseDto } from '@zaplify/prospects';
import { Loader2 } from 'lucide-react';
import { motion, AnimatePresence } from 'motion/react';
import { ContactSource } from '@zaplify/services/user-contacts/shared';
import { AddContactButton } from '../../buttons/add-contact-button';

export const ACCOUNT_DETAILS_SEARCH_PEOPLE_PAGE_SIZE = 5;

export function SearchPeopleInAccount({ accountId, accountName }: { accountId: string; accountName: string }) {
    const [inputValue, setInputValue] = useState('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
    const [page, setPage] = useState(1);
    const [allPeople, setAllPeople] = useState<PeopleResponseDto[]>([]);
    const [hasMore, setHasMore] = useState(false);

    const {
        globalSearch: { searchInCompany },
    } = useSdk();

    useEffect(() => {
        setPage(1);
        setDebouncedSearchTerm('');
        setAllPeople([]);
        setHasMore(false);
    }, [accountId]);

    const { data, isLoading, error } = useQuery(
        searchInCompany({
            match: debouncedSearchTerm,
            page: page,
            perPage: ACCOUNT_DETAILS_SEARCH_PEOPLE_PAGE_SIZE,
            accountId,
        })
    );

    useEffect(() => {
        const newPeople = data?.data || [];
        if (page === 1) {
            setAllPeople(newPeople);
        } else {
            setAllPeople((prev) => [...prev, ...newPeople]);
        }
        setHasMore(newPeople.length === ACCOUNT_DETAILS_SEARCH_PEOPLE_PAGE_SIZE);
    }, [data, page]);

    const handleSearch = useMemo(
        () =>
            debounce((value: string) => {
                setPage(1);
                setDebouncedSearchTerm(value);
            }, 300),
        []
    );

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setInputValue(value);
        handleSearch(value);
    };

    const handleShowMore = () => {
        setPage((prev) => prev + 1);
    };

    if (error) {
        return <div className="text-red-500">Error loading people.</div>;
    }

    return (
        <AnimatePresence>
            <div className="space-y-4">
                <h4 className="text-sm text-muted-foreground">PEOPLE</h4>

                <div className="relative">
                    <input
                        type="text"
                        value={inputValue}
                        onChange={handleInputChange}
                        placeholder={`Search for people at ${accountName}`}
                        className="w-full px-3 py-2 border rounded-md"
                    />
                </div>

                <div className="space-y-2">
                    {allPeople.map((person) => (
                        <SearchPreviewRowRoot
                            key={person._id}
                            result={{
                                personId: person._id,
                                type: 'person',
                                title: person.fullName,
                                subtitle: person.title,
                                icon: (
                                    <Avatar>
                                        <AvatarImage src={person.linkedinProfileImgUrl} />
                                        <AvatarFallback>{getInitials(person.fullName)}</AvatarFallback>
                                    </Avatar>
                                ),
                            }}
                            button={
                                <AddContactButton
                                    variant="secondary"
                                    personId={person._id}
                                    source={ContactSource.GlobalSearch}
                                    size="sm"
                                />
                            }
                        />
                    ))}
                    {!isLoading && allPeople.length === 0 && (
                        <div className="text-muted-foreground">
                            {inputValue.length > 0
                                ? 'No people found matching your search'
                                : 'No people found at this company'}
                        </div>
                    )}
                    {isLoading && (
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.2 }}
                            className="flex justify-center"
                        >
                            <Loader2 className="h-5 w-5 animate-spin" />
                        </motion.div>
                    )}
                </div>

                {hasMore && (
                    <Button variant="outline" className="w-full" onClick={handleShowMore} disabled={isLoading}>
                        {isLoading ? 'Loading...' : 'Show more people'}
                    </Button>
                )}
            </div>
        </AnimatePresence>
    );
}
