import { ProspectDataDto } from './prospect.dto';

export interface BulkImportValidationResponseDto {
    validProspects: BulkImportProspectDataDto[];
    alreadyExistingProspects: BulkImportProspectDataDto[];
    errorProspects: BulkImportErrorProspectDataDto[];

    /** New field, should replace alreadyExistingProspects */
    skippedProspects?: IBulkImportSkippedProspect[];
}

export class BulkImportProspectDataDto extends ProspectDataDto {
    rowNumber: number;

    constructor(prospectData: ProspectDataDto, rowNumber: number) {
        super(prospectData);
        this.rowNumber = rowNumber;
    }
}

export interface BulkImportErrorProspectDataDto {
    prospectData: BulkImportProspectDataDto;
    errors: BadProspectDataExceptionDto[];
}

export interface BadProspectDataExceptionDto {
    name: string;

    messages: string[];
    row: number;
    property: string;
    value: string;
}

export interface IBulkImportSkippedProspect {
    prospectData: BulkImportProspectDataDto;
    issues: ImportProspectSkippedIssue[];
}

export enum ImportProspectSkippedIssue {
    BLOCKLISTED = 'BLOCKLISTED',
    DUPLICATE = 'DUPLICATE',
    EXISTS_IN_CRM = 'EXISTS_IN_CRM',
    EXISTS_IN_ORGANIZATION = 'EXISTS_IN_ORGANIZATION',
}
