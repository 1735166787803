export class NoteDto {
    public readonly id: string;
    public readonly userId: string;
    public readonly prospectId: string;
    public readonly creator: Creator;
    public readonly content: string;
    public readonly title: string;
    public readonly discarded: boolean;
    public readonly uniqueIdentifier?: string;
    public readonly happenedOn?: Date;
    public readonly createdAt?: Date;
    public readonly updatedAt?: Date;

    constructor(
        id: string,
        userId: string,
        prospectId: string,
        creator: Creator,
        content: string,
        title: string,
        discarded: boolean,
        uniqueIdentifier?: string,
        happenedOn?: Date,
        createdAt?: Date,
        updatedAt?: Date,
    ) {
        this.id = id;
        this.userId = userId;
        this.prospectId = prospectId;
        this.creator = creator;
        this.content = content;
        this.title = title;
        this.discarded = discarded;
        this.uniqueIdentifier = uniqueIdentifier;
        this.happenedOn = happenedOn;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
    }
}

export enum Creator {
    User = 'user',
    System = 'system',
}
