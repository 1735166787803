import { Avatar, AvatarFallback, AvatarImage } from '@shadcn/ui/components/ui/avatar';
import { Building } from 'lucide-react';
import { getInitials } from '../../../functions/get-initials';
import { AccountLogo } from '../../account-logo';
import { useQuery } from '@tanstack/react-query';
import { useSdk } from '../../../sdk';
import { ContactOwnersAvatarDisplay } from './contact-owners-avatar-display';
import { useLinkedinGlobalSearch } from './hooks/use-global-search-linkedin';
import { cn } from '@shadcn/ui/lib/utils';

export type SearchResultType = 'contact' | 'person' | 'company';

export interface PersonPreviewData {
    personId?: string;
    prospectId?: string;
    linkedinUrl?: string;
    type: SearchResultType;
    title: string;
    place?: string;
    subtitle?: string;
    icon?: React.ReactNode;
}

interface PersonPreviewRowRootProps {
    result: PersonPreviewData;
    onClick?: () => void;
    button?: React.ReactNode;
}

export function SearchPreviewRowRoot({ result, onClick, button }: PersonPreviewRowRootProps) {
    return (
        <div
            className={cn(
                'flex items-center p-2 w-full justify-between hover:bg-muted/50 rounded-md gap-1',
                onClick ? 'cursor-pointer' : ''
            )}
            onClick={onClick}
        >
            <div className="flex items-center gap-3 min-w-0 flex-1">
                <div className="flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full">
                    {result.icon || <Building className="h-4 w-4 text-muted-foreground" />}
                </div>
                <div className="flex flex-col min-w-0">
                    <div className="flex items-center gap-2 flex-wrap min-w-0 max-w-full">
                        <p className="text-sm font-medium leading-none truncate">{result.title}</p>
                        {result.place && (
                            <div className="flex items-center gap-1 text-xs text-muted-foreground min-w-0 max-w-full">
                                <Building className="h-3 w-3 flex-shrink-0" />
                                <span className="truncate">{result.place}</span>
                            </div>
                        )}
                    </div>
                    {result.subtitle && <p className="text-xs text-muted-foreground truncate">{result.subtitle}</p>}
                </div>
            </div>
            <div className="flex-shrink-0 flex items-center gap-2">
                <ContactOwnersAvatarDisplay
                    personId={result.personId}
                    linkedinUrl={result.linkedinUrl}
                    prospectId={result.prospectId}
                    spacing="tight"
                />
            </div>
            {button}
        </div>
    );
}

export function ContactPreviewRow({ prospectId, onClick }) {
    const {
        prospect: { getProspectById },
    } = useSdk();

    const { data: prospect, isLoading } = useQuery(getProspectById(prospectId));

    if (isLoading || !prospect) return null;
    const prospectData = prospect?.data;

    return (
        <SearchPreviewRowRoot
            result={{
                prospectId: prospect.id,
                linkedinUrl: prospectData.linkedinProfileUrl,
                type: 'person',
                title: prospectData.fullName,
                subtitle: prospectData.occupationTitle,
                place: prospectData.organizationName,
                icon: (
                    <Avatar>
                        <AvatarImage src={prospectData.linkedinProfileImgUrl} />
                        <AvatarFallback>{getInitials(prospectData.fullName)}</AvatarFallback>
                    </Avatar>
                ),
            }}
            onClick={onClick}
        />
    );
}

export function LinkedinPersonPreviewRow({ linkedinUrl, onClick }) {
    const { searchLinkedinProspectQuery } = useLinkedinGlobalSearch(linkedinUrl, {
        enabled: !!linkedinUrl,
    });
    const { data: linkedinProfilesResults, isLoading: isLoadingLinkedinProfiles } = useQuery(
        searchLinkedinProspectQuery()
    );

    if (isLoadingLinkedinProfiles || !linkedinProfilesResults) return null;
    const prospectData = linkedinProfilesResults[0];

    return (
        <SearchPreviewRowRoot
            result={{
                prospectId: null,
                linkedinUrl: prospectData.linkedinProfileUrl,
                type: 'person',
                title: prospectData.fullName,
                subtitle: prospectData.occupationTitle,
                place: prospectData.organizationName,
                icon: (
                    <Avatar>
                        <AvatarImage src={prospectData.linkedinProfileImgUrl} />
                        <AvatarFallback>{getInitials(prospectData.fullName)}</AvatarFallback>
                    </Avatar>
                ),
            }}
            onClick={onClick}
        />
    );
}

export function AccountPreviewRow({
    accountId,
    onClick,
    accountData,
}: {
    accountId: string;
    onClick: () => void;
    accountData?: { name: string; industry: string; website: string };
}) {
    const {
        account: { getAccountById },
    } = useSdk();

    const { data: account, isLoading } = useQuery(getAccountById(accountId, { enabled: !!accountData }));

    if (isLoading || (!account?.data?.name && !accountData?.name)) return null;

    return (
        <SearchPreviewRowRoot
            result={{
                type: 'company',
                title: account?.data?.name || accountData?.name,
                subtitle: account?.data?.industry || accountData?.industry,
                icon: (
                    <AccountLogo accountWebsite={account?.data?.website || accountData?.website} className="w-8 h-8" />
                ),
            }}
            onClick={onClick}
        />
    );
}
