import { InvitationStatus } from '@zaplify/users/shared';

export enum CSVDownloadErrorMessages {
    noError = 0,
    companiesLimit = 1,
    twoColumns = 2,
    unknown = 3,
    empty = 4,
}

export const initialCampaign = {
    name: '',
    status: 'PENDING',
    isArchived: false,
    isPaused: false,
    workflow: null,
    targetGroup: {
        targetSearches: [],
        prospects: [],
    },
};

export const alertModalMessages = {
    messageMissing: false,
    messageOverflow: false,
    subjectMissing: false,
    subjectOverflow: false,
    daysMissing: false,
    timeError: false,
    personalVariables: false,
};

export const initialDays = [
    {
        label: 'mon',
        checked: true,
        value: 0,
    },
    {
        label: 'tue',
        checked: true,
        value: 1,
    },
    {
        label: 'wed',
        checked: true,
        value: 2,
    },
    {
        label: 'thu',
        checked: true,
        value: 3,
    },
    {
        label: 'fri',
        checked: true,
        value: 4,
    },
    {
        label: 'sat',
        checked: false,
        value: 5,
    },
    {
        label: 'sun',
        checked: false,
        value: 6,
    },
];

export const emptyInitialDays = [
    {
        label: 'mon',
        checked: false,
        value: 0,
    },
    {
        label: 'tue',
        checked: false,
        value: 1,
    },
    {
        label: 'wed',
        checked: false,
        value: 2,
    },
    {
        label: 'thu',
        checked: false,
        value: 3,
    },
    {
        label: 'fri',
        checked: false,
        value: 4,
    },
    {
        label: 'sat',
        checked: false,
        value: 5,
    },
    {
        label: 'sun',
        checked: false,
        value: 6,
    },
];

export const minutesArray = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'];
export const hoursArray = [
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
];

export const initialTimeStates = [
    {
        label: 'hr',
        values: hoursArray,
        value: '11',
    },
    {
        label: 'min',
        values: minutesArray,
        value: '00',
    },
    {
        label: 'hr',
        values: hoursArray,
        value: '17',
    },
    {
        label: 'min',
        values: minutesArray,
        value: '00',
    },
];

export const emptyInitialTimeStates = [
    {
        label: 'hr',
        values: hoursArray,
        value: '01',
    },
    {
        label: 'min',
        values: minutesArray,
        value: '00',
    },
    {
        label: 'hr',
        values: hoursArray,
        value: '01',
    },
    {
        label: 'min',
        values: minutesArray,
        value: '00',
    },
];

export const InvitationStatusDictionary = {
    [InvitationStatus.SENT]: 'Invited',
    [InvitationStatus.REVOKED]: 'Revoked',
    [InvitationStatus.ACCEPTED]: 'Accepted',
    [InvitationStatus.REJECTED]: 'Rejected',
    [InvitationStatus.EXPIRED]: 'Expired',
} as const;
