import { useSubscription } from '@apollo/client';
import { COMPLETED_OUTREACH_SUGGESTIONS_SUBSCRIPTION, OUTREACH_SUGGESTIONS_SUBSCRIPTION } from '@zaplify/graphql';
import { useSetAtom } from 'jotai';
import moment from 'moment';
import {
    completedOutreachSuggestionsAtom,
    completedOutreachSuggestionsLoadingAtom,
    outreachSuggestionsAtom,
    outreachSuggestionsLoadingAtom,
} from '../../atoms/chats';
import useProspectAvatars from '../../views/chats/hooks/use-prospect-avatars';
import { OutreachSuggestionDto } from '@zaplify/services/messaging/shared';
import { ScoredOutreachSuggestionDto, scoreOutreachSuggestions } from '../../hooks/outreach-suggestion-scoring';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const NUMBER_OF_SUGGESTIONS_FETCHED = 200;
export const NUMBER_OF_SCORED_SUGESSIONS = 30;

export const OutreachSuggestionsSubscription = () => {
    const { enableConversationMetaDataScoring } = useFlags();
    const { fetchProspectAvatarUrls } = useProspectAvatars();

    const setOutreachSuggestionsLoading = useSetAtom(outreachSuggestionsLoadingAtom);
    const setCompletedOutreachSuggestionsLoading = useSetAtom(completedOutreachSuggestionsLoadingAtom);
    const setOutreachSuggestions = useSetAtom(outreachSuggestionsAtom);
    const setCompletedOutreachSuggestions = useSetAtom(completedOutreachSuggestionsAtom);
    const outreachSuggestionsQuery = {
        limit: NUMBER_OF_SUGGESTIONS_FETCHED,
        sentOn: true,
        scheduledOnLt: moment().add(1, 'days').startOf('day').format('YYYY-MM-DD'),
    };
    const completedOutreachSuggestionsQuery = {
        limit: NUMBER_OF_SUGGESTIONS_FETCHED,
        sentOn: false,
        scheduledOnLt: moment().add(1, 'days').startOf('day').format('YYYY-MM-DD'),
        sentOnGte: moment().startOf('day').format('YYYY-MM-DD'),
    };

    useSubscription(OUTREACH_SUGGESTIONS_SUBSCRIPTION, {
        variables: outreachSuggestionsQuery,
        onData: (data) => {
            if (data.data.data?.OutreachSuggestions) {
                const suggestions = data.data.data.OutreachSuggestions;
                const prospectIds = suggestions.map((s) => s.prospectId);
                fetchProspectAvatarUrls(prospectIds);
                const scoredOutreachSuggestions = enableConversationMetaDataScoring
                    ? scoreOutreachSuggestions(suggestions as OutreachSuggestionDto[])
                    : (suggestions.map((o) => {
                          return { ...o, badge: undefined };
                      }) as ScoredOutreachSuggestionDto[]);
                setOutreachSuggestions(scoredOutreachSuggestions.slice(0, 30));
                setOutreachSuggestionsLoading(false);
            }
        },
        shouldResubscribe: () => true,
    });
    useSubscription(COMPLETED_OUTREACH_SUGGESTIONS_SUBSCRIPTION, {
        variables: completedOutreachSuggestionsQuery,
        onData: (data) => {
            if (data.data.data?.OutreachSuggestions) {
                const suggestions = data.data.data.OutreachSuggestions;
                const prospectIds = suggestions.map((s) => s.prospectId);
                fetchProspectAvatarUrls(prospectIds);
                setCompletedOutreachSuggestions(suggestions as OutreachSuggestionDto[]);
                setCompletedOutreachSuggestionsLoading(false);
            }
        },
        shouldResubscribe: () => true,
    });

    return null;
};
