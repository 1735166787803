import { IEvent, Topics } from '@zaplify/common';

export class UserContactAssignedToGroupEvent extends IEvent {
    public static override readonly TOPIC: Topics = Topics.PROSPECTS;
    public readonly groupId: string;
    public readonly userId: string;
    public readonly userContactId: string;
    public readonly prospectId: string;
    public readonly reassigned: boolean;

    constructor(params: {
        groupId: string;
        userId: string;
        userContactId: string;
        prospectId: string;
        reassigned: boolean;
    }) {
        super();
        this.groupId = params.groupId;
        this.userId = params.userId;
        this.userContactId = params.userContactId;
        this.prospectId = params.prospectId;
        this.reassigned = params.reassigned;
    }

    get partitionKey(): string {
        return this.prospectId;
    }
}
