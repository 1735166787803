import { Box } from '@mui/material';
import { paletteV2 } from '@zaplify/ui';
import { cloneElement } from 'react';

type FeedbackVariant = 'info' | 'error';

const BackgroundColorMap: Record<FeedbackVariant, string> = {
    info: paletteV2.primary.light,
    error: paletteV2.system.errorBackGround,
};

const iconColorMap: Record<FeedbackVariant, string> = {
    info: paletteV2.primary.medium,
    error: paletteV2.system.red.base,
};

const borderColorMap: Record<FeedbackVariant, string> = {
    info: paletteV2.primary.darken,
    error: paletteV2.system.errorLighter,
};

export const FeedbackSection = ({
    icon,
    children,
    variant,
}: {
    icon: React.ReactElement;
    children: React.ReactNode;
    variant: FeedbackVariant;
}) => {
    const backgroundColor = BackgroundColorMap[variant];
    const iconColor = iconColorMap[variant];
    const borderColor = borderColorMap[variant];

    const iconWithColor = cloneElement(icon, {
        style: { color: iconColor, height: '20px', width: '20px' },
    });

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                padding: '16px',
                gap: '12px',
                alignSelf: 'stretch',
                borderRadius: '6px',
                border: `1px solid ${borderColor}`,
                background: backgroundColor,
                maxWidth: 'fit-content',
            }}
        >
            <Box>{iconWithColor}</Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '25px' }}>{children}</Box>
        </Box>
    );
};
