import { FormControlLabel, FormGroup, Typography, Switch } from '@mui/material';
import { batch, useDispatch } from 'react-redux-latest';
import { updateScope } from '../../../../../redux/ducks/upsales/slice';
import { AppDispatch, useAppSelector } from '../../../../../redux/store/configureStore';
import { ConfigurationContainer } from './containers';
import { companyNameCorrectlyCased } from '../../../../../enums/company-name';

export const Configuration = () => {
    const dispatch = useDispatch<AppDispatch>();
    const scope = useAppSelector((state) => state.upsales.integrationScope);

    const handleSyncProspectsChange = (e) => {
        dispatch(updateScope({ key: 'prospects', value: e.target.checked }));

        //If sync prospects is turned of, turn off other switches too
        if (!e.target.checked) {
            batch(() => {
                dispatch(updateScope({ key: 'campaignsActivity', value: false }));
                dispatch(updateScope({ key: 'actionMessages', value: false }));
            });
        }
    };
    const handleSyncCampaignActivityChange = (e) =>
        dispatch(updateScope({ key: 'campaignsActivity', value: e.target.checked }));
    const handleSyncActionMessagesChange = (e) =>
        dispatch(updateScope({ key: 'actionMessages', value: e.target.checked }));

    const disabledSwitches = !scope.prospects;

    return (
        <ConfigurationContainer data-cy="upsales-configuration-container">
            <Typography>Configuration</Typography>
            <Typography variant="body2">{`Configure ${companyNameCorrectlyCased} synchronization to Upsales.`}</Typography>
            <FormGroup>
                <FormControlLabel
                    control={
                        <Switch
                            color="primary"
                            name="prospects"
                            checked={scope.prospects || false}
                            onChange={handleSyncProspectsChange}
                        />
                    }
                    label={`Synchronize ${companyNameCorrectlyCased} prospects to Upsales. All new prospects and changes will be added to your Upsales contacts.`}
                />
                <FormControlLabel
                    control={
                        <Switch
                            color="primary"
                            name="campaignsActivity"
                            disabled={disabledSwitches}
                            checked={scope.campaignsActivity || false}
                            onChange={handleSyncCampaignActivityChange}
                        />
                    }
                    label={`Add each touchpoint sent to your ${companyNameCorrectlyCased} prospects as an activity in Upsales`}
                />
                <FormControlLabel
                    control={
                        <Switch
                            color="primary"
                            name="actionMessages"
                            disabled={disabledSwitches}
                            checked={scope.actionMessages || false}
                            onChange={handleSyncActionMessagesChange}
                        />
                    }
                    label="Include the text sent with the touchpoint in Upsales activities"
                />
            </FormGroup>
        </ConfigurationContainer>
    );
};
