import FiberManualRecord from '@mui/icons-material/FiberManualRecord';
import { Step, StepLabel, Stepper } from '@mui/material';
import { styled } from '@mui/styles';

export const ProgressWizard = styled(({ className, ...props }) => {
    return (
        <Stepper className={className} activeStep={props.activeStep}>
            {props.steps.map((label) => (
                <Step key={label}>
                    <StepLabel icon={<FiberManualRecord />}>{label}</StepLabel>
                </Step>
            ))}
        </Stepper>
    );
})(({ theme }: any) => ({
    background: 'transparent',
    width: '48%',
    margin: '0 auto',
    '& *.MuiStep-horizontal, & *.MuiStepLabel-active': {
        color: theme.palette.colors.black,
    },
    '& *.Mui-disabled': {
        color: theme.palette.colors.grey,
    },
    '& *.MuiStep-completed': {
        color: theme.palette.colors.green,
    },
    '& > *.MuiStepConnector-active > span ': {
        borderColor: theme.palette.colors.green,
    },
    '& > *.MuiStepConnector-completed > span ': {
        borderColor: theme.palette.colors.green,
    },
    '& *.MuiStepLabel-labelContainer': {
        position: 'absolute',
        top: '100px',
        width: '18%',
        display: 'flex',
        justifyContent: 'center',
    },
    '& *.MuiStepLabel-iconContainer': {
        padding: '0',
    },
    '& div > span > span > span': {
        position: 'relative',
        left: '-44%',
    },
    '& *.MuiStepLabel-completed': {
        color: theme.palette.colors.green,
    },
}));
