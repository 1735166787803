import { styled } from '@mui/styles';

export const TopBarAuthContainer = styled('div')(({ theme }) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    width: '100vw',
    height: 58,
    backgroundColor: 'white',
    boxShadow: '0px 2px 6px rgba(112, 144, 176, 0.07)',
    '& .logo': {
        marginLeft: 56,
        cursor: 'pointer',
        '&.centered': {
            margin: 'auto',
        },
    },
    '& .log-in': {
        display: 'flex',
        marginLeft: 'auto',
        marginRight: 36,
        '& .text': {
            marginRight: 19,
            fontSize: 13,
            color: theme.palette.colors.greyDark,
            cursor: 'default',
        },
        '& .button': {
            height: 38,
            border: `1px solid ${theme.palette.colors.bluePale}`,
            borderRadius: 8,
            fontSize: 14,
            color: theme.palette.colors.bluePale,
            fontWeight: 500,
        },
    },
}));
