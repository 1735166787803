import { FormGroup, MenuItem, Tooltip } from '@mui/material';
import React from 'react';
import { revertCamelCase } from '../../../services/utils/revert-camel-case';
import { SideTextField } from '../../atoms/sideTextField';
import { CustomFormControl, CustomSelect } from './elements';
import { ReactComponent as WarningIcon } from '../../icons/warning.svg';
import { MenuItemValueContainer, MenuItemWithTooltipContainer, RenderedValueContainer } from './containers';

function getMenuItemValue(option) {
    if (option.name) return option.name;
    return option;
}

const renderValue = (selected, options, placeholder) => {
    if (!selected.length) {
        return <RenderedValueContainer>{placeholder ?? 'Choose field'}</RenderedValueContainer>;
    }
    const selectedOption = options.find((option) => {
        const rawOption = option?.name ?? option;
        return rawOption === selected;
    });
    const label = selectedOption?.label ?? selectedOption;
    return <RenderedValueContainer>{label}</RenderedValueContainer>;
};

const TwinSelect = (props) => (
    <>
        <FormGroup row style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
            <CustomFormControl variant="outlined" required>
                {Array.isArray(props.leftOptions) ? (
                    <CustomSelect
                        disabled={props.disabledLeft}
                        id="customized-select"
                        side="left"
                        value={(props.required && props.leftValue === '' && 'Map field (required)') || props.leftValue}
                        onChange={(e) => props.leftOnChange(e, props.index)}
                        defaultValue="Choose field"
                        filterSelectedOptions
                        displayEmpty={props.leftDisplayEmpty}
                    >
                        {!props.leftValue && (
                            <MenuItem value="" disabled>
                                {props.leftPlaceholder ?? 'Choose field'}
                            </MenuItem>
                        )}
                        {props.leftOptions.map((option) => (
                            <MenuItem value={option.name || option}>{option.label || option}</MenuItem>
                        ))}
                    </CustomSelect>
                ) : (
                    <SideTextField
                        defaultValue={props.leftValue}
                        side="left"
                        InputProps={{
                            readOnly: true,
                        }}
                        variant="outlined"
                    />
                )}
            </CustomFormControl>
            <CustomFormControl variant="outlined" required>
                {Array.isArray(props.rightOptions) ? (
                    <CustomSelect
                        disabled={props.disabledRight}
                        id="customized-select"
                        value={props.rightValue}
                        onChange={(e) => props.rightOnChange(e, props.index)}
                        defaultValue="Choose field"
                        filterSelectedOptions
                        displayEmpty={props.rightDisplayEmpty}
                        renderValue={(selected) => renderValue(selected, props.rightOptions, props.rightPlaceholder)}
                    >
                        {!props.rightValue && (
                            <MenuItem value="" disabled>
                                {props.rightPlaceholder ?? 'Choose field'}
                            </MenuItem>
                        )}
                        {props.rightOptions.map((option, idx) => {
                            if (!option.disabled) {
                                return (
                                    <MenuItem key={option.label + idx} value={getMenuItemValue(option)}>
                                        <div>{option.label || option}</div>
                                    </MenuItem>
                                );
                            }
                            return (
                                <MenuItem
                                    onClickCapture={(event) => {
                                        event.stopPropagation();
                                    }}
                                    key={option.label + idx}
                                    value={getMenuItemValue(option)}
                                    disabled={true}
                                    style={{ pointerEvents: 'all' }}
                                >
                                    <MenuItemWithTooltipContainer>
                                        <div>
                                            <Tooltip title={`“${option.label || option}” is already mapped`} arrow>
                                                <WarningIcon />
                                            </Tooltip>
                                        </div>
                                        <MenuItemValueContainer style={{ marginLeft: '9px' }}>
                                            {option.label || option}
                                        </MenuItemValueContainer>
                                    </MenuItemWithTooltipContainer>
                                </MenuItem>
                            );
                        })}
                    </CustomSelect>
                ) : (
                    <SideTextField
                        defaultValue={props.noCamelCase ? revertCamelCase(props.rightValue) : props.rightValue}
                        side="right"
                        InputProps={{
                            readOnly: true,
                        }}
                        variant="outlined"
                    />
                )}
            </CustomFormControl>
        </FormGroup>
    </>
);

export default TwinSelect;
