import { makeAssistantToolUI } from '@assistant-ui/react';
import { Cloud, Sun, CloudRain } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from '@shadcn/ui/components/ui/card';
import { Action, ActionCards } from '@shadcn/ui/components/action-cards';
type WeatherToolArgs = {
    location: string;
};

type WeatherToolResult = {
    location: string;
    temperature: number;
};

function WeatherDisplay({ location, temperature }: WeatherToolArgs & WeatherToolResult) {
    const WeatherIcon = temperature > 80 ? Sun : temperature < 60 ? CloudRain : Cloud;
    const actions: Action[] = [
        {
            id: '1',
            title: 'Connect with Sarah',
            description: 'Build your network by connecting with Sarah',
            type: 'connect',
            contact: {
                name: 'Sarah Chen',
                role: 'Product Designer',
                company: 'Design Co',
            },
        },
        {
            id: '2',
            title: 'Message Alex',
            description: 'Follow up on the project discussion',
            type: 'message',
            contact: {
                name: 'Alex Kim',
                role: 'Senior Developer',
                company: 'Tech Corp',
            },
        },
        {
            id: '3',
            title: 'Schedule with Michael',
            description: 'Set up a strategy meeting',
            type: 'schedule',
            contact: {
                name: 'Michael Brown',
                role: 'Marketing Director',
                company: 'Growth Inc',
            },
        },
    ];
    return (
        <Card className="mx-auto w-full max-w-md">
            <CardHeader>
                <CardTitle className="text-2xl font-bold">{location}</CardTitle>
            </CardHeader>
            <CardContent>
                {/* <div className="flex flex-col items-center gap-4">
                    <WeatherIcon className="h-16 w-16" />
                    <p className="text-3xl font-semibold">{temperature}°F</p>
                </div> */}
                <ActionCards actions={actions} />
            </CardContent>
        </Card>
    );
}

export const WeatherTool = makeAssistantToolUI<WeatherToolArgs, WeatherToolResult>({
    toolName: 'weather',
    render: function WeatherUI({ args, result }) {
        if (!result) {
            return null;
        }

        return (
            <div className="mb-4 flex flex-col items-center gap-2">
                <pre className="whitespace-pre-wrap break-all text-center">weather({JSON.stringify(args)})</pre>
                <WeatherDisplay {...result} />
            </div>
        );
    },
});
