import { PlanSelectionState } from '../atoms/selfService';
import { BillingPeriodFrequency, BillingPeriodUnit, SubscriptionStatus } from '../enums/billing';
import { IPlan, IPlanPackage } from '@zaplify/subscriptions';
import { ISeatSummary } from '@zaplify/users/shared';

export const defineReadableBillingPeriodOperation = (unit: BillingPeriodUnit, amount: number) => {
    let tempFrequency = BillingPeriodFrequency.month;

    switch (unit) {
        case BillingPeriodUnit.MONTH:
            if (amount === 1) {
                tempFrequency = BillingPeriodFrequency.month;
            }
            if (amount === 3) {
                tempFrequency = BillingPeriodFrequency.quarter;
            }
            if (amount === 6) {
                tempFrequency = BillingPeriodFrequency.half_year;
            }
            if (amount === 12) {
                tempFrequency = BillingPeriodFrequency.year;
            }
            return tempFrequency;
        case BillingPeriodUnit.YEAR:
            if (amount === 1) {
                tempFrequency = BillingPeriodFrequency.year;
            }
            return tempFrequency;
        case BillingPeriodUnit.HALF_YEAR:
            if (amount === 1) {
                tempFrequency = BillingPeriodFrequency.half_year;
            }
            if (amount === 2) {
                tempFrequency = BillingPeriodFrequency.year;
            }
            return tempFrequency;
        case BillingPeriodUnit.QUARTER:
            if (amount === 1) {
                tempFrequency = BillingPeriodFrequency.quarter;
            }
            if (amount === 2) {
                tempFrequency = BillingPeriodFrequency.half_year;
            }
            if (amount === 4) {
                tempFrequency = BillingPeriodFrequency.year;
            }
            return tempFrequency;
        default:
            return BillingPeriodFrequency.month;
    }
};

export const defineSelectionStateOperation = ({
    currentPackage,
    selectedPackage,
    subscriptionSeatSummary,
    currentPlanBillingPeriod,
    selectedPlan,
    subscriptionStatus,
    currentPlanBillingAmount,
    currentPlanBillingCode,
    selectedPlanBillingCode,
}: {
    currentPackage: IPlanPackage | null | undefined;
    selectedPackage: IPlanPackage;
    subscriptionSeatSummary: { occupiedSeatsCount: number };
    currentPlanBillingPeriod: BillingPeriodUnit;
    selectedPlan: IPlan;
    subscriptionStatus: SubscriptionStatus | undefined;
    currentPlanBillingAmount: number;
    currentPlanBillingCode: string;
    selectedPlanBillingCode: string;
}): PlanSelectionState => {
    if (!currentPackage || !subscriptionStatus || !currentPlanBillingPeriod || !currentPlanBillingAmount)
        return 'active';

    const isSelectedPlanACurrentPlan =
        currentPackage.seats === selectedPackage.seats &&
        currentPackage.credits === selectedPackage.credits &&
        currentPlanBillingPeriod === selectedPlan.billingPeriod.unit &&
        currentPlanBillingAmount === selectedPlan.billingPeriod.amount &&
        currentPlanBillingCode === selectedPlanBillingCode;

    if (subscriptionSeatSummary.occupiedSeatsCount > selectedPackage.seats) {
        return 'insufficient_seats';
    }

    if (isSelectedPlanACurrentPlan && subscriptionStatus !== SubscriptionStatus.NON_RENEWING) {
        return 'disabled';
    }

    return 'active';
};
