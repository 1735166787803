import { FC, useState, useEffect } from 'react';
import { Input } from '@shadcn/ui/components/ui/input';
import { Button } from '@shadcn/ui/components/ui/button';
import { CheckCircle, XCircle, Loader2 } from 'lucide-react';
import { cn } from '@shadcn/ui/lib/utils';

interface DiscountCodeProps {
    couponCode: string;
    onCouponCodeChange: (code: string) => Promise<void>;
    isCouponCodeValid: boolean;
    isLoading?: boolean;
}

export const DiscountCode: FC<DiscountCodeProps> = ({
    couponCode,
    onCouponCodeChange,
    isCouponCodeValid,
    isLoading = false,
}) => {
    const [inputValue, setInputValue] = useState(couponCode);
    const [isDirty, setIsDirty] = useState(false);
    const [validating, setValidating] = useState(false);

    // Sync input value with parent when couponCode changes
    useEffect(() => {
        if (couponCode !== inputValue && !isDirty) {
            setInputValue(couponCode);
        }
    }, [couponCode]);

    const handleApply = async () => {
        if (!inputValue) return;

        setValidating(true);
        try {
            await onCouponCodeChange(inputValue);
        } finally {
            setValidating(false);
            setIsDirty(false);
        }
    };

    const showValidationStatus = couponCode && !isDirty;
    const isApplyDisabled = !inputValue || isLoading || validating || (!isDirty && inputValue === couponCode);

    return (
        <div className="flex flex-col gap-2">
            <span className="text-sm text-text-tertiary font-medium">Discount Code</span>
            <div className="flex items-center gap-2">
                <Input
                    type="text"
                    value={inputValue}
                    onChange={(e) => {
                        setInputValue(e.target.value);
                        setIsDirty(true);
                    }}
                    className="flex-1"
                    placeholder="Enter discount code"
                    disabled={validating || isLoading}
                />
                <Button onClick={handleApply} disabled={isApplyDisabled} size="sm">
                    {validating ? (
                        <>
                            <Loader2 className="w-4 h-4 mr-1 animate-spin" />
                            Validating
                        </>
                    ) : (
                        'Apply'
                    )}
                </Button>
            </div>
            {showValidationStatus && (
                <span
                    className={cn(
                        'flex items-center text-sm font-medium',
                        isCouponCodeValid ? 'text-success' : 'text-destructive'
                    )}
                >
                    {isCouponCodeValid ? (
                        <>
                            <CheckCircle className="w-4 h-4 mr-1" />
                            Promo code applied
                        </>
                    ) : (
                        <>
                            <XCircle className="w-4 h-4 mr-1" />
                            Promo code couldn't be applied
                        </>
                    )}
                </span>
            )}
        </div>
    );
};
