import { FC, useState, useEffect } from 'react';
import { useQuery as useApolloQuery } from '@apollo/client';
import { GET_ALL_USER_CONTACTS } from '@zaplify/graphql';
import { useQuery as useQueryTan } from '@tanstack/react-query';
import { useMemo } from 'react';
import { Button } from '@shadcn/ui/components/ui/button';
import { Input } from '@shadcn/ui/components/ui/input';
import { Users, Sparkles, Briefcase, Building2, Factory } from 'lucide-react';
import { Checkbox } from '@shadcn/ui/components/ui/checkbox';
import { Avatar, AvatarFallback, AvatarImage } from '@shadcn/ui/components/ui/avatar';
import { Skeleton } from '@shadcn/ui/components/ui/skeleton';
import { cn } from '@shadcn/ui/lib/utils';
import { useAuth } from '../../../../../providers/authentication-provider';
import { useSdk } from '../../../../../sdk/use-sdk';
import { LinkedinProfileDto } from '@andsend/services/linkedin-profiles/shared';
import { getInitials } from '../../../../../functions/get-initials';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@shadcn/ui/components/ui/tabs';
import { Badge } from '@shadcn/ui/components/ui/badge';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@shadcn/ui/components/ui/tooltip';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '@shadcn/ui/components/ui/hover-card';
import { useParams } from 'react-router-dom';

export interface ContactSelectionProps {
    onSuggestedContactsChange: (contacts: LinkedinProfileDto[]) => void;
    onExistingContactsChange: (userContactIds: string[]) => void;
    recommendations?: LinkedinProfileDto[];
    isLoadingRecommendations?: boolean;
}

export const ContactSelection: FC<ContactSelectionProps> = ({
    onSuggestedContactsChange,
    onExistingContactsChange,
    recommendations,
    isLoadingRecommendations = false,
}) => {
    const [filterValue, setFilterValue] = useState<string>('');
    const [recommendationsCount, setRecommendationsCount] = useState<number>(0);

    const [selectedSuggested, setSelectedSuggested] = useState<LinkedinProfileDto[]>([]);
    const [selectedExisting, setSelectedExisting] = useState<string[]>([]);

    useEffect(() => {
        setRecommendationsCount(recommendations?.length || 0);
    }, [recommendations]);

    const handleSuggestedContactsChange = (contacts: LinkedinProfileDto[]) => {
        setSelectedSuggested(contacts);
        onSuggestedContactsChange(contacts);
    };

    const handleExistingContactsChange = (prospectIds: string[]) => {
        setSelectedExisting(prospectIds);
        onExistingContactsChange(prospectIds);
    };

    return (
        <div className="w-full flex flex-col gap-2">
            <Tabs defaultValue="suggested" className="w-full">
                <TabsList className="grid w-full grid-cols-2 mb-4">
                    <TabsTrigger value="suggested" className="flex items-center gap-2">
                        <Sparkles className="h-4 w-4" />
                        <span>Suggested ({recommendationsCount})</span>
                    </TabsTrigger>
                    <TabsTrigger value="existing" className="flex items-center gap-2">
                        <Users className="h-4 w-4" />
                        <span>Add existing contacts </span>
                    </TabsTrigger>
                </TabsList>

                <Input
                    type="search"
                    placeholder="Search contacts..."
                    className="pl-8 mb-4"
                    value={filterValue}
                    onChange={(e) => setFilterValue(e.target.value)}
                />
                <TabsContent value="suggested" className="mt-0">
                    <MatchingContactSection
                        onSelectionChange={handleSuggestedContactsChange}
                        setRecommendationsCount={setRecommendationsCount}
                        selectedContacts={selectedSuggested}
                        filterValue={filterValue}
                        recommendations={recommendations}
                        isLoadingRecommendations={isLoadingRecommendations}
                    />
                </TabsContent>

                <TabsContent value="existing" className="mt-0">
                    <ExistingContactsSection
                        filterValue={filterValue}
                        onSelectionChange={handleExistingContactsChange}
                        selectedContacts={selectedExisting}
                    />
                </TabsContent>
            </Tabs>
        </div>
    );
};

const MatchingContactSection: FC<{
    onSelectionChange: (contacts: LinkedinProfileDto[]) => void;
    setRecommendationsCount: (count: number) => void;
    selectedContacts: LinkedinProfileDto[];
    filterValue: string;
    recommendations?: LinkedinProfileDto[];
    isLoadingRecommendations?: boolean;
}> = ({
    onSelectionChange,
    setRecommendationsCount,
    selectedContacts,
    filterValue,
    recommendations,
    isLoadingRecommendations,
}) => {
    useEffect(() => {
        setRecommendationsCount(recommendations?.length || 0);
    }, [recommendations, setRecommendationsCount]);

    const toggleContactSelection = (contact: LinkedinProfileDto) => {
        const isSelected = selectedContacts.some((c) => c.memberId === contact.memberId);
        let updatedContacts: LinkedinProfileDto[];

        if (isSelected) {
            updatedContacts = selectedContacts.filter((c) => c.memberId !== contact.memberId);
        } else {
            updatedContacts = [...selectedContacts, contact];
        }

        onSelectionChange(updatedContacts);
    };

    const isContactSelected = (contact: LinkedinProfileDto) => {
        return selectedContacts.some((c) => c.memberId === contact.memberId);
    };

    const toggleSelectAll = () => {
        if (recommendations && recommendations.length > 0) {
            if (selectedContacts.length === recommendations.length) {
                // Deselect all
                onSelectionChange([]);
            } else {
                // Select all
                onSelectionChange([...recommendations]);
            }
        }
    };

    const filteredRecommendations = useMemo(() => {
        if (!recommendations) return [];
        if (!filterValue) return recommendations;

        return recommendations.filter(
            (contact) =>
                contact.fullName.toLowerCase().includes(filterValue.toLowerCase()) ||
                (contact.headline && contact.headline.toLowerCase().includes(filterValue.toLowerCase()))
        );
    }, [recommendations, filterValue]);

    const missingSuggestions = (recommendations?.length ?? 0) === 0;

    return (
        <div>
            {!missingSuggestions && (
                <div className="flex items-center justify-between mb-2">
                    {
                        <Badge variant="outline" className="text-xs">
                            From LinkedIn
                        </Badge>
                    }
                    <Button variant="ghost" size="sm" onClick={toggleSelectAll} className="h-7 text-xs">
                        {recommendations && selectedContacts.length === recommendations.length
                            ? 'Deselect all'
                            : `Select all (${recommendations?.length || 0})`}
                    </Button>
                </div>
            )}
            <div className="space-y-1 max-h-[350px] overflow-y-auto pr-1">
                {isLoadingRecommendations ? (
                    <>
                        {[...Array(5)].map((_, index) => (
                            <div key={index} className="flex items-center gap-3 py-3 px-4 rounded-md">
                                <Skeleton className="h-4 w-4 rounded" />
                                <Skeleton className="h-8 w-8 rounded-full" />
                                <div className="flex flex-col gap-1 flex-1">
                                    <Skeleton className="h-4 w-1/3" />
                                    <Skeleton className="h-3 w-2/3" />
                                </div>
                                <Skeleton className="h-6 w-6 rounded-full" />
                            </div>
                        ))}
                    </>
                ) : filteredRecommendations && filteredRecommendations.length > 0 ? (
                    <>
                        {filteredRecommendations.map((contact) => (
                            <ContactRow
                                key={contact.memberId}
                                contact={{
                                    memberId: contact.memberId,
                                    fullName: contact.fullName,
                                    headline: contact.headline,
                                    profileImageUrl: contact.profileImageUrl,
                                    experiences: contact.experiences.map((experience) => ({
                                        companyName: experience.companyName,
                                        jobTitle: experience.jobTitle,
                                        companyIndustry: experience.companyIndustry,
                                    })),
                                }}
                                isSelected={isContactSelected(contact)}
                                onToggle={() => toggleContactSelection(contact)}
                            />
                        ))}
                    </>
                ) : (
                    <div className="flex flex-col gap-1 text-center py-8 text-sm text-muted-foreground">
                        <p>We couldn't find any matching contacts in your network.</p>
                        <p>
                            We continuously sync profiles from your LinkedIn, so our suggestions will improve over time.
                        </p>
                        <p>Change purpose or target group to get new suggestions or add contacts manually later.</p>
                    </div>
                )}
            </div>
        </div>
    );
};

const CompanyInfoRow: FC<{
    icon: React.ReactNode;
    label: string;
    value?: string;
}> = ({ icon, label, value }) => {
    if (!value) return null;

    return (
        <Tooltip>
            <TooltipTrigger asChild>
                <div className="flex items-start gap-2">
                    <div className="flex-shrink-0 mt-1">{icon}</div>
                    <span className="break-words">{value}</span>
                </div>
            </TooltipTrigger>
            <TooltipContent>{label}</TooltipContent>
        </Tooltip>
    );
};

const CompanyInfoHoverCard: FC<{
    organizationName?: string;
    occupationTitle?: string;
    industry?: string;
}> = ({ organizationName, occupationTitle, industry }) => {
    return (
        <HoverCard>
            <HoverCardTrigger>
                <Button variant="ghost" size="icon" className="h-7 w-7">
                    <span className="sr-only">Contact info</span>
                    <i className="h-4 w-4 rounded-full border border-current flex items-center justify-center text-xs">
                        i
                    </i>
                </Button>
            </HoverCardTrigger>
            <HoverCardContent className="w-80 p-4">
                <div className="space-y-3">
                    <TooltipProvider>
                        <CompanyInfoRow
                            icon={<Building2 className="h-4 w-4 text-muted-foreground" />}
                            label="Company Name"
                            value={organizationName}
                        />
                        <CompanyInfoRow
                            icon={<Briefcase className="h-4 w-4 text-muted-foreground" />}
                            label="Job Title"
                            value={occupationTitle}
                        />
                        <CompanyInfoRow
                            icon={<Factory className="h-4 w-4 text-muted-foreground" />}
                            label="Company Industry"
                            value={industry}
                        />
                    </TooltipProvider>
                </div>
            </HoverCardContent>
        </HoverCard>
    );
};

const ExistingContactsSection: FC<{
    filterValue: string;
    onSelectionChange: (contacts: string[]) => void;
    selectedContacts: string[];
}> = ({ filterValue, onSelectionChange, selectedContacts }) => {
    const PAGE_SIZE = 10;
    const { groupId } = useParams();
    const [page, setPage] = useState<number>(0);

    const {
        authState: { user },
    } = useAuth();

    // TODO: Exclude those that are already in this group
    const { loading: isContactsLoading, data: contactsData } = useApolloQuery<{
        UserContacts: { prospectId: string; id: string; _GroupToUserContacts: { Group: { id: string } }[] }[];
    }>(GET_ALL_USER_CONTACTS);

    const data = useMemo(() => contactsData, [contactsData]);

    const {
        prospect: { findProspectData },
    } = useSdk();

    const startRowIndex = page * PAGE_SIZE;
    const allProspectIdsInGroup = useMemo(
        () =>
            data?.UserContacts?.filter((contact) =>
                contact._GroupToUserContacts.some((group) => group.Group.id !== groupId)
            ).map((contact) => contact.prospectId) || [],
        [data?.UserContacts]
    );

    const { data: prospectData, isLoading: isProspectLoading } = useQueryTan(
        findProspectData({
            prospectIds: allProspectIdsInGroup,
            regex: filterValue ? [filterValue] : [],
            page: page + 1,
            perPage: PAGE_SIZE,
            includeFields: ['fullName', 'linkedinProfileImgUrl', 'linkedinHeadline', 'occupationTitle', 'experiences'],
        })
    );

    const prospectDataWithUserContactIds = useMemo(() => {
        return prospectData?.prospects.map((prospect) => ({
            ...prospect,
            userContactId: data?.UserContacts.find((contact) => contact.prospectId === prospect.prospectId)?.id,
        }));
    }, [prospectData, data]);

    useEffect(() => {
        setPage(0);
    }, [filterValue]);

    const isLoading = isContactsLoading || isProspectLoading;

    const toggleContactSelection = (userContactId: string) => {
        const isSelected = selectedContacts.includes(userContactId);
        let updatedContacts: string[];

        if (isSelected) {
            updatedContacts = selectedContacts.filter((id) => id !== userContactId);
        } else {
            updatedContacts = [...selectedContacts, userContactId];
        }

        onSelectionChange(updatedContacts);
    };

    const isContactSelected = (prospectId: string) => {
        return selectedContacts.includes(prospectId);
    };

    const toggleSelectAll = () => {
        if (prospectDataWithUserContactIds.length > 0) {
            if (selectedContacts.length === prospectDataWithUserContactIds.length) {
                onSelectionChange([]);
            } else {
                const allIds = prospectDataWithUserContactIds.map((prospect) => prospect.userContactId);
                onSelectionChange(allIds);
            }
        }
    };

    const hasPreviousPage = startRowIndex > 0;
    const hasNextPage = startRowIndex + PAGE_SIZE < (prospectData?.count || 0);

    return (
        <div>
            <div className="flex items-center justify-end mb-2">
                <Button
                    variant="ghost"
                    size="sm"
                    onClick={toggleSelectAll}
                    className="h-7 text-xs"
                    disabled={!prospectData?.prospects || prospectData.prospects.length === 0}
                >
                    {prospectData?.prospects.length
                        ? selectedContacts.length === prospectData.prospects.length
                            ? 'Deselect all'
                            : `Select all (${prospectData?.prospects?.length || 0})`
                        : ''}
                </Button>
            </div>

            <div className="space-y-1 max-h-[350px] overflow-y-auto pr-1">
                {isLoading ? (
                    <>
                        {[...Array(5)].map((_, index) => (
                            <div key={index} className="flex items-center gap-3 py-3 px-4 rounded-md">
                                <Skeleton className="h-4 w-4 rounded" />
                                <Skeleton className="h-8 w-8 rounded-full" />
                                <div className="flex flex-col gap-1 flex-1">
                                    <Skeleton className="h-4 w-1/3" />
                                    <Skeleton className="h-3 w-2/3" />
                                </div>
                                <Skeleton className="h-6 w-6 rounded-full" />
                            </div>
                        ))}
                    </>
                ) : prospectDataWithUserContactIds && prospectDataWithUserContactIds.length > 0 ? (
                    <>
                        {prospectDataWithUserContactIds.map((prospect) => (
                            <ContactRow
                                key={prospect.prospectId}
                                contact={{
                                    memberId: prospect.prospectId,
                                    fullName: prospect.fullName,
                                    headline: prospect.linkedinHeadline || prospect.occupationTitle,
                                    profileImageUrl: prospect.linkedinProfileImgUrl,
                                    experiences: prospect.experiences.map((experience) => ({
                                        companyName: experience.organizationName,
                                        jobTitle: experience.occupationTitle,
                                        companyIndustry: experience.organizationIndustry,
                                    })),
                                }}
                                isSelected={isContactSelected(prospect.userContactId)}
                                onToggle={() => toggleContactSelection(prospect.userContactId)}
                            />
                        ))}
                    </>
                ) : (
                    <p className="text-center py-4 text-sm text-muted-foreground">
                        No contacts found{' '}
                        {filterValue ? '. Try a different search term' : groupId ? 'outside of this playbook' : ''}
                    </p>
                )}
            </div>

            {!isLoading && prospectData?.prospects && prospectData.prospects.length > 0 && (
                <div className="flex items-center justify-between p-3 mt-2">
                    <div className="text-xs text-muted-foreground">
                        Showing {startRowIndex + 1}-{Math.min(startRowIndex + PAGE_SIZE, prospectData.count)} of{' '}
                        {prospectData.count}
                    </div>
                    <div className="flex items-center gap-2">
                        <Button
                            variant="outline"
                            size="sm"
                            className="h-7 text-xs px-2"
                            onClick={() => setPage(page - 1)}
                            disabled={!hasPreviousPage}
                        >
                            Previous
                        </Button>
                        <Button
                            variant="outline"
                            size="sm"
                            className="h-7 text-xs px-2"
                            onClick={() => setPage(page + 1)}
                            disabled={!hasNextPage}
                        >
                            Next
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};

const ContactRow: FC<{
    contact: {
        memberId: string;
        fullName: string;
        headline: string;
        profileImageUrl: string;
        experiences: {
            companyName: string;
            jobTitle: string;
            companyIndustry: string;
        }[];
    };
    isSelected: boolean;
    onToggle: () => void;
}> = ({ contact, isSelected, onToggle }) => {
    return (
        <div
            className={cn(
                isSelected && 'bg-background-brand-primary-subtle/50',
                'flex items-center gap-2 p-2 rounded-md hover:bg-muted/50 transition-colors'
            )}
        >
            <Checkbox id={`contact-${contact.memberId}`} checked={isSelected} onCheckedChange={onToggle} />

            <label
                htmlFor={`contact-${contact.memberId}`}
                className="flex items-center gap-3 flex-1 cursor-pointer text-sm"
            >
                <Avatar className="h-8 w-8">
                    <AvatarImage src={contact.profileImageUrl} alt={contact.fullName} />
                    <AvatarFallback>{getInitials(contact.fullName)}</AvatarFallback>
                </Avatar>

                <div className="flex-1 min-w-0">
                    <p className="font-medium truncate">{contact.fullName}</p>
                    <p className="text-xs text-muted-foreground truncate max-w-[400px]">{contact.headline}</p>
                </div>
            </label>

            <CompanyInfoHoverCard
                organizationName={contact.experiences[0]?.companyName}
                occupationTitle={contact.experiences[0]?.jobTitle}
                industry={contact.experiences[0]?.companyIndustry}
            />
        </div>
    );
};
