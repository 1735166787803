import { styled } from '@mui/styles';

export const RenderedValueContainer = styled('div')(({ theme }) => ({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
}));

export const MenuItemWithTooltipContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
}));

export const MenuItemValueContainer = styled('div')(({ theme }) => ({
    marginLeft: '9px',
}));
