import { motion } from 'motion/react';
import { cn } from '@shadcn/ui/lib/utils';

interface TypingIndicatorProps {
    className?: string;
}

export const TypingIndicator = ({ className }: TypingIndicatorProps) => {
    return (
        <div className={cn('flex items-center gap-1', className)}>
            {[0, 1, 2].map((index) => (
                <motion.div
                    key={index}
                    className="w-2 h-2 bg-background-brand-primary rounded-full"
                    initial={{ y: 0 }}
                    animate={{
                        y: [0, -4, 0],
                        scale: [1, 1.1, 1],
                    }}
                    transition={{
                        duration: 1,
                        repeat: Infinity,
                        repeatType: 'reverse',
                        delay: index * 0.15, // Stagger the animation of each dot
                        ease: 'easeInOut',
                    }}
                />
            ))}
        </div>
    );
};
