import React from 'react';
import { motion } from 'motion/react';
import { CheckCircle, Circle, Target, MessageSquare, Network, Search, Zap } from 'lucide-react';
import { cn } from '@shadcn/ui/lib/utils';
import { useOnboarding, OnboardingStep } from '../../../providers/onboarding-provider';

export const CompletingOnboardingLoadingScreen: React.FC = () => {
    const { steps, isLoading } = useOnboarding();

    const stepConfig: Record<
        OnboardingStep,
        {
            title: string;
            description: string;
            detailedDescription: string;
            icon: React.ElementType;
        }
    > = {
        fetchLinkedinConversations: {
            title: 'Fetching your recent LinkedIn conversations',
            description: 'Andsend helps you stay on top of your network and build stronger relationships',
            detailedDescription: 'Importing recent conversations to help you get started with Andsend',
            icon: Network,
        },
        analyzeWebsite: {
            title: 'Analyzing your website',
            description: 'Understanding your offering and target audience',
            detailedDescription:
                'Andsend is examining your website to identify key messaging, value propositions, and target audiences to align our suggestions with your brand.',
            icon: Search,
        },
        setupPlaybook: {
            title: 'Creating your first playbook',
            description: 'Playbooks steer your agent towards your goals',
            detailedDescription:
                'Playbooks let you organize your contacts and tell the system how to interact with your different segments',
            icon: Target,
        },
        conversationAnalysis: {
            title: 'Identifying relevant conversations',
            description: 'Finding your most valuable connections',
            detailedDescription:
                'Your agent is identifying the most promising recent conversations based on your conversation so far, relevance to your goals, and potential for conversion.',
            icon: MessageSquare,
        },
        finalSetup: {
            title: 'Analyzing your network',
            description: 'Discovering new connections and opportunities',
            detailedDescription:
                'Your agent is creating a feed of new people you can reach out to, right from within your network',
            icon: Zap,
        },
    };

    const activeStepId = Object.entries(steps).find(([_, step]) => step.status === 'in-progress')?.[0] as
        | OnboardingStep
        | undefined;

    const isComplete = Object.values(steps).every((step) => step.status === 'completed');

    return (
        <div
            className={`flex flex-col items-start justify-center h-screen w-full transition-opacity duration-1000 ${
                !isLoading && isComplete ? 'opacity-0' : 'opacity-100'
            }`}
        >
            <div className="w-full p-4 sm:p-6 md:p-10 flex flex-col bg-white overflow-y-auto">
                <div className="mb-6 md:mb-8">
                    <h1 className="text-xl sm:text-2xl md:text-3xl font-bold text-gray-800 mb-2">
                        Setting up your Andsend experience
                    </h1>
                    <p className="text-gray-500">Updating your first playbook and suggestions</p>
                </div>

                <div className="space-y-3 md:space-y-4">
                    {Object.entries(steps).map(([stepId, step]) => {
                        const config = stepConfig[stepId as OnboardingStep];
                        const isActiveStep = stepId === activeStepId;

                        return (
                            <motion.div
                                key={stepId}
                                initial={{ opacity: 0.7, y: 10 }}
                                animate={{
                                    opacity: 1,
                                    y: 0,
                                    scale: isActiveStep ? 1.02 : 1,
                                }}
                                transition={{ duration: 0.3 }}
                                className={cn(
                                    'relative border rounded-xl p-3 sm:p-4 transition-all',
                                    isActiveStep ? 'border-purple-400 bg-purple-50' : 'border-gray-200 bg-gray-50',
                                    step.status === 'completed' && 'border-green-400/50'
                                )}
                            >
                                <div className="flex items-start gap-3 sm:gap-4">
                                    <div className="mt-1 flex-shrink-0">
                                        {step.status === 'completed' ? (
                                            <CheckCircle className="h-5 w-5 sm:h-6 sm:w-6 text-green-500" />
                                        ) : step.status === 'in-progress' ? (
                                            <motion.div
                                                animate={{
                                                    rotate: 360,
                                                    scale: [1, 1.2, 1],
                                                }}
                                                transition={{
                                                    duration: 2,
                                                    repeat: Number.POSITIVE_INFINITY,
                                                    ease: 'linear',
                                                }}
                                            >
                                                <Circle className="h-5 w-5 sm:h-6 sm:w-6 text-background-brand-secondary" />
                                            </motion.div>
                                        ) : (
                                            <Circle className="h-5 w-5 sm:h-6 sm:w-6 text-gray-300" />
                                        )}
                                    </div>
                                    <div className="flex-1 min-w-0">
                                        <h3
                                            className={cn(
                                                'font-medium text-sm sm:text-base',
                                                step.status === 'completed'
                                                    ? 'text-green-600'
                                                    : step.status === 'in-progress'
                                                    ? 'text-purple-700'
                                                    : 'text-gray-500'
                                            )}
                                        >
                                            {config.title}
                                        </h3>
                                        <p className="text-xs sm:text-sm text-gray-500 mt-1">
                                            {step.status === 'in-progress'
                                                ? config.detailedDescription
                                                : config.description}
                                        </p>
                                    </div>
                                </div>
                            </motion.div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};
