import React from 'react';
import { Avatar as AvatarMaterialUI } from '@mui/material';
import { styled } from '@mui/styles';

const AvatarComponent = styled(({ children, className, ...props }) => (
    <AvatarMaterialUI
        variant={props.variant}
        className={className}
        alt={props.alt}
        src={props.src}
        imgProps={props.imgProps}
    >
        {children}
    </AvatarMaterialUI>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
))(({ theme, color, size, backgroundColor, zIndex, boxShadow }: any) => ({
    zIndex: zIndex,
    boxShadow: (boxShadow === true && theme?.shadow.card) || null,
    height:
        (typeof size == 'number' && size) ||
        (size === 'xxlarge' && '62px') ||
        (size === 'xlarge' && '47px') ||
        (size === 'large' && '40px') ||
        (size === 'small' && '25px') ||
        (size === 'medium' && '35px') ||
        '30px',
    width:
        (typeof size == 'number' && size) ||
        (size === 'xxlarge' && '62px') ||
        (size === 'xlarge' && '47px') ||
        (size === 'large' && '40px') ||
        (size === 'small' && '25px') ||
        (size === 'medium' && '35px') ||
        '30px',
    backgroundColor:
        (!backgroundColor && null) ||
        (backgroundColor === 'greyLight' && theme.palette.graphics.stroke) ||
        (backgroundColor === 'green' && theme.palette.colors.green) ||
        (backgroundColor === 'yellow' && theme.palette.colors.yellow) ||
        (backgroundColor === 'white' && theme.palette.colors.white) ||
        (backgroundColor === 'blue' && theme.palette.primary.light) ||
        (backgroundColor === 'email' && theme.palette.channels.email) ||
        (backgroundColor === 'linkedIn' && theme.palette.channels.linkedIn) ||
        (backgroundColor === 'red' && theme.palette.secondary.main) ||
        (backgroundColor === 'grey' && theme.palette.graphics.badge) ||
        (backgroundColor === 'black' && theme.palette.black) ||
        (backgroundColor && theme.palette[backgroundColor]) ||
        backgroundColor,
    color:
        (!color && null) ||
        (color === 'greyLight' && theme.palette.graphics.stroke) ||
        (color === 'green' && theme.palette.colors.green) ||
        (color === 'yellow' && theme.palette.colors.yellow) ||
        (color === 'white' && theme.palette.colors.white) ||
        (color === 'blue' && theme.palette.primary.light) ||
        (color === 'greyDark' && theme.palette.graphics.icon) ||
        (color && color),
}));

export const Avatar = (props: AvatarProps) => {
    const { src, alt, children, backgroundColor, color, size, zIndex, boxShadow, variant, className, imgProps } = props;
    return (
        <AvatarComponent
            alt={alt}
            src={src}
            backgroundColor={backgroundColor}
            color={color}
            size={size}
            zIndex={zIndex}
            boxShadow={boxShadow}
            variant={variant}
            className={className}
            imgProps={imgProps}
        >
            {children}
        </AvatarComponent>
    );
};

export interface AvatarProps {
    src?: string;
    alt?: string;
    children?: React.ReactNode;
    backgroundColor?: string;
    color?: string;
    size?: string | number;
    zIndex?: string;
    className?: string;
    boxShadow?: boolean;
    variant?: 'circle' | 'square' | 'circular' | 'rounded' | undefined;
    imgProps?: {
        alt?: string;
        src?: string;
    };
}
