import { Box, Link, Typography, styled } from '@mui/material';
import { Briefcase, MapPin, Linkedin, Mail, UsersRound } from 'lucide-react';
import { userClickedExternalLinkInGlobalSearch, CardClass, LinkClass } from '../tracking';
import { ProspectOwnerDto } from '@zaplify/prospects';
import { UsersAvatarGroup } from '../../avatars/users-avatar-group';

export const ProspectDetails = ({
    userId,
    userAvatarUrl,
    title,
    location,
    website,
    companyName,
    owners,
}: {
    userId?: string;
    userAvatarUrl?: string;
    title?: string;
    location?: string;
    website?: string;
    companyName?: string;
    owners?: ProspectOwnerDto[];
}) => {
    const linkToWebsite = () => {
        return (
            <Box sx={{}}>
                <Text>
                    {title}{' '}
                    {website && companyName && (
                        <>
                            <span>at</span>
                            <Link
                                sx={{
                                    overflow: 'hidden',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 2,
                                    color: 'var(--others-links, #6583EF)',
                                    textDecoration: 'none',
                                    marginLeft: '4px',
                                }}
                                href={formatWebsite(website)}
                                target="_blank"
                                rel="noreferrer"
                                onClick={() =>
                                    userClickedExternalLinkInGlobalSearch(CardClass.Person, LinkClass.Website)
                                }
                            >
                                {companyName}
                            </Link>
                        </>
                    )}
                </Text>
            </Box>
        );
    };

    const prospectDetails = [
        owners?.filter((o) => o.userId !== userId)?.length > 0
            ? {
                  label: (
                      <UsersAvatarGroup users={owners} userId={userId} size={'small'} userAvatarUrl={userAvatarUrl} />
                  ),
                  icon: <UsersRound size={20} strokeWidth={1} />,
              }
            : undefined,
        {
            label: (
                <div>
                    {} {linkToWebsite()}
                </div>
            ),
            icon: <Briefcase size={20} strokeWidth={1} stroke="#6B7985" />,
        },
        {
            label: location,
            icon: <MapPin size={20} strokeWidth={1} stroke="#6B7985" />,
        },
        {
            label: (
                <Box
                    sx={{
                        display: 'inline-flex',
                        padding: 'var(--S-2xs, 4px) var(--S-s-regular, 8px) var(--S-2xs, 4px) 10px',
                        alignItems: 'center',
                        gap: 'var(--S-xs, 6px)',
                        borderRadius: 'var(--radius-round, 100px)',
                        background: 'var(--grey-palette-grey-1, #F7F8FA)',
                    }}
                >
                    <span style={{ color: '#969FAC' }}>●●●●●●●●●</span>
                </Box>
            ),
            icon: <Linkedin size={20} strokeWidth={1} stroke="#6B7985" />,
        },
        {
            label: (
                <Box
                    sx={{
                        display: 'inline-flex',
                        padding: 'var(--S-2xs, 4px) var(--S-s-regular, 8px) var(--S-2xs, 4px) 10px',
                        alignItems: 'center',
                        gap: 'var(--S-xs, 6px)',
                        borderRadius: 'var(--radius-round, 100px)',
                        background: 'var(--grey-palette-grey-1, #F7F8FA)',
                    }}
                >
                    <span style={{ color: '#969FAC' }}>●●●●●●●●●●●●●●</span>
                </Box>
            ),
            icon: <Mail size={20} strokeWidth={1} stroke="#6B7985" />,
        },
    ];

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '18px',
                alignSelf: 'stretch',
            }}
        >
            {prospectDetails.map(
                (detail, index) =>
                    detail?.label && (
                        <Box
                            key={index}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 'var(--S-xs, 6px)',
                                alignSelf: 'stretch',
                            }}
                        >
                            {detail.icon}
                            <Text>{detail.label}</Text>
                        </Box>
                    )
            )}
        </Box>
    );
};

const Text = styled(Typography)`
    overflow: 'hidden',
    color: 'var(--grey-palette-grey-5, #3A4B59)',
    textOverflow: 'ellipsis',
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '19px',
    flex: '1 0 0',
`;

const formatWebsite = (website: string) => {
    // add https:// if not present
    if (!website.startsWith('http')) {
        return `https://${website}`;
    }
};
