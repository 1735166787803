import { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthPageLayout } from './components/auth-page-layout';
import { Button } from '@shadcn/ui/components/ui/button';
import { Card, CardContent } from '@shadcn/ui/components/ui/card';
import { Skeleton } from '@shadcn/ui/components/ui/skeleton';
import { paths } from '../../../routes/paths';
import { firebaseActionLink } from '../../../services/utils/urlHelper';
import { getAuth, applyActionCode } from 'firebase/auth';
import { useAuth } from '../../../new/providers/authentication-provider';
import { Loader2 } from 'lucide-react';
import { useMutation } from '@tanstack/react-query';
import { useSdk } from '../../../new/sdk/use-sdk';
import { getAuthenticationToken } from '@zaplify/utils';
import { useToast } from '@shadcn/ui/hooks/use-toast';

// Constants
const EMAIL_QUERY_PARAM = 'email';
const RESET_PASSWORD_QUERY_PARAM = 'resetPassword';

export const ConfirmEmailPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { toast } = useToast();
    const search = window.location.search;
    const urlParams = new URLSearchParams(search);
    const emailFromQuery = decodeURIComponent(urlParams.get(EMAIL_QUERY_PARAM) || '');
    const { loading: isLoadingUser } = useAuth();
    const { authentication } = useSdk();

    const [emailAddress, setEmailAddress] = useState(emailFromQuery || getAuth().currentUser?.email || '');
    const [isResetPassword, setIsResetPassword] = useState(urlParams.get(RESET_PASSWORD_QUERY_PARAM) === 'true');
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isVerifying, setIsVerifying] = useState(false);

    // Ref to track verification attempts
    const hasAttemptedVerification = useRef(false);

    // Delete Firebase user mutation
    const deleteUserMutation = useMutation({
        mutationFn: async () => {
            await getAuth().currentUser.delete();
            await getAuth().signOut();

            // Clear local storage keys
            // [
            //     LocalStorageKeys.LATEST_USER_EMAIL,
            //     LocalStorageKeys.LATEST_ORGANIZATION_ID,
            //     LocalStorageKeys.TOKEN,
            //     LocalStorageKeys.FIREBASE_TOKEN,
            // ].forEach((key) => localStorage.removeItem(key));
        },
        onError: (error: any) => {
            setErrorMessage(error.message || 'An error occurred while deleting your account.');
            toast({
                title: 'Error',
                description: error.message || 'An error occurred while deleting your account.',
                variant: 'destructive',
            });
        },
    });

    // Resend email verification mutation
    const {
        authentication: { sendEmailVerification },
    } = useSdk();
    const sendEmailVerificationMutation = useMutation(sendEmailVerification());
    const resendEmailMutation = useMutation({
        mutationFn: async () => {
            const user = getAuth().currentUser;
            if (!user) {
                throw new Error('User is not authenticated');
            }
            // return await sendEmailVerification(user);
            return await sendEmailVerificationMutation.mutateAsync({ emailAdress: user.email });
        },
        onSuccess: () => {
            toast({
                title: 'Success',
                description: 'Verification email has been sent.',
            });
        },
        onError: (error: any) => {
            setErrorMessage(error.message || 'An error occurred while sending the verification email.');
            toast({
                title: 'Error',
                description: error.message || 'An error occurred while sending the verification email.',
                variant: 'destructive',
            });
        },
    });

    // Reset password email mutation
    const resetPasswordMutation = useMutation({
        mutationFn: async () => {
            return authentication.resetPassword().mutationFn(emailAddress);
        },
        onSuccess: () => {
            toast({
                title: 'Success',
                description: 'Password reset email has been sent.',
            });
        },
        onError: (error: any) => {
            setErrorMessage(error.message || 'An error occurred while sending the password reset email.');
            toast({
                title: 'Error',
                description: error.message || 'An error occurred while sending the password reset email.',
                variant: 'destructive',
            });
        },
    });

    // Function to clean URL parameters
    const cleanupUrlParams = useCallback(() => {
        if (window.history && window.history.replaceState) {
            // Create clean URL without search parameters
            const cleanUrl = `${window.location.pathname}${window.location.hash}`;
            window.history.replaceState({}, document.title, cleanUrl);
        }
    }, []);

    // Verify account mutation
    const verifyAccountMutation = useMutation({
        mutationFn: async (actionCode: string) => {
            try {
                console.log('Verifying email with action code:', actionCode);
                await applyActionCode(getAuth(), actionCode);
                // Reload user needed to see the new emailVerified=true state on the token and user
                await getAuth().currentUser.reload();
                // Refresh token to trigger the page redirect to next route
                await getAuthenticationToken(true);
                // Clean up URL after successful verification
                cleanupUrlParams();
                return {
                    isVerified: true,
                    message: 'Your email has been verified',
                };
            } catch (error) {
                console.error('verify email error', error);
                // Clean up URL even if verification fails
                cleanupUrlParams();
                return {
                    isVerified: false,
                    message: 'Your request to verify your email has expired or the link has already been used',
                };
            }
        },
        onSuccess: (data) => {
            console.log(`🚀 [ConfirmEmail] verifyAccount > onSuccess: ${JSON.stringify(data)}`);
            if (data.isVerified) {
                toast({
                    title: 'Success',
                    description: 'Your email has been verified.',
                });
            } else {
                setErrorMessage(data.message);
                toast({
                    title: 'Error',
                    description: data.message,
                    variant: 'destructive',
                });
            }
        },
        onError: (error: any) => {
            setErrorMessage(error.message || 'An error occurred while verifying your email.');
            toast({
                title: 'Error',
                description: error.message || 'An error occurred while verifying your email.',
                variant: 'destructive',
            });
        },
    });

    const handleReenterEmail = useCallback(async () => {
        if (deleteUserMutation.isPending) return;

        if (isResetPassword) {
            navigate(paths.NEW_RESET_PASSWORD);
        } else {
            await deleteUserMutation.mutateAsync();
            navigate(paths.NEW_REGISTER);
        }
    }, [deleteUserMutation.isPending, isResetPassword, navigate, deleteUserMutation]);

    const handleResendEmail = useCallback(() => {
        if (resendEmailMutation.isPending || resetPasswordMutation.isPending) return;

        if (isResetPassword) {
            resetPasswordMutation.mutate();
        } else {
            resendEmailMutation.mutate();
        }
    }, [resendEmailMutation, resetPasswordMutation, isResetPassword]);

    useEffect(() => {
        const mode = firebaseActionLink.getCurrentMode();
        const actionCode = firebaseActionLink.getCurrentActionCode();

        // Only attempt verification if we haven't tried yet
        if (mode === firebaseActionLink.verifyEmailModeName && actionCode && !hasAttemptedVerification.current) {
            // Mark verification as attempted to prevent infinite loop
            hasAttemptedVerification.current = true;
            setIsVerifying(true);
            verifyAccountMutation.mutate(actionCode);
        } else {
            setIsVerifying(false);
            // Clean up URL params for non-verification scenarios or after processing
            if (hasAttemptedVerification.current) {
                cleanupUrlParams();
            }
        }
    }, [verifyAccountMutation, cleanupUrlParams]);

    if (isLoadingUser) {
        return null;
    }

    if (verifyAccountMutation.isPending || isVerifying) {
        return (
            <AuthPageLayout>
                <div className="flex flex-col items-center space-y-6 w-full max-w-md">
                    <Skeleton className="h-12 w-48 rounded-md" />
                    <Skeleton className="h-4 w-64 rounded-md" />
                    <div className="flex flex-row gap-4 w-full">
                        <Skeleton className="h-12 w-full rounded-md" />
                        <Skeleton className="h-12 w-full rounded-md" />
                    </div>
                    <Skeleton className="h-24 w-full rounded-md" />
                    <Skeleton className="h-8 w-48 rounded-md" />
                </div>
            </AuthPageLayout>
        );
    }

    const isRequesting =
        deleteUserMutation.isPending || resendEmailMutation.isPending || resetPasswordMutation.isPending;

    return (
        <AuthPageLayout>
            <div className="flex flex-col gap-6 w-full max-w-md mx-auto">
                <Card className="w-full border-none shadow-none bg-transparent">
                    <CardContent className="space-y-6 px-0">
                        <div className="text-center space-y-2">
                            <h1 className="text-3xl font-semibold tracking-tight">Check your email</h1>
                            <p>We've just sent you an email with instructions</p>
                        </div>

                        {/* Email Client Buttons */}
                        <div className="flex flex-row gap-9 w-full mt-6">
                            <a
                                href="https://mail.google.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="w-full"
                            >
                                <Button variant="outline" className="w-full py-5 flex gap-2">
                                    <img
                                        src="https://www.google.com/gmail/about/static/images/logo-gmail.png"
                                        alt="Gmail"
                                        className="w-6 h-6"
                                    />
                                    Open Gmail
                                </Button>
                            </a>
                            <a href="https://outlook.com/" target="_blank" rel="noopener noreferrer" className="w-full">
                                <Button variant="outline" className="w-full py-5 flex gap-1">
                                    <img
                                        src="https://img.icons8.com/color/48/000000/ms-outlook.png"
                                        alt="Outlook"
                                        className="w-6 h-6"
                                    />
                                    Open Outlook
                                </Button>
                            </a>
                        </div>

                        {/* Email Display */}
                        <div className="space-y-2 pt-8">
                            <p className="text-base font-medium">Email</p>
                            <div className="p-3 border rounded-md bg-background-disabled text-text-disabled text-base overflow-hidden text-ellipsis">
                                {emailAddress}
                            </div>
                            {errorMessage && <p className="text-sm text-destructive mt-1">{errorMessage}</p>}
                        </div>

                        {/* Additional Info */}
                        <div className="space-y-2 text-base">
                            <p className="text-slate-600">
                                Wrong email address?{' '}
                                <span
                                    onClick={handleReenterEmail}
                                    className="text-blue-500 cursor-pointer hover:underline"
                                >
                                    re-enter email address
                                </span>
                            </p>
                            <p className="text-slate-600">
                                Haven't received an email? Check your spam or{' '}
                                <span
                                    onClick={handleResendEmail}
                                    className="text-blue-500 cursor-pointer hover:underline"
                                >
                                    resend email
                                </span>
                            </p>
                        </div>

                        {isRequesting && (
                            <div className="flex justify-center">
                                <Loader2 className="h-6 w-6 animate-spin text-primary" />
                            </div>
                        )}
                    </CardContent>
                </Card>
            </div>
        </AuthPageLayout>
    );
};

export default ConfirmEmailPage;
