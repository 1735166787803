import { Menu } from '@mui/material';
import { styled } from '@mui/styles';

export const DropdownMenu = styled(({ className, ...props }) => (
    <Menu
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
        classes={{ paper: className }}
    />
))(({ theme }: any) => ({
    boxShadow: `0 2px 20px ${theme.palette.colors.overlay.dark(5)}`,
    border: `0.5px solid ${theme.palette.graphics.stroke}`,
    '& > ul': {
        padding: '0',
        '& > li': {
            padding: '14px',
        },
    },
}));
