import { List, ListItem, ListItemText, ListSubheader, Typography } from '@mui/material';
import { Button } from '@zaplify/ui';
import { useDispatch } from 'react-redux-latest';
import { LazyLoad } from '../../helpers/lazyLoad';
import { capitalizeFirstLetters } from '../../helpers/text';
import { unblockCompanyFromBlocklist, undoUnblockCompany } from '../../redux/actions/userOrganization';
import { AppDispatch } from '../../redux/store/configureStore';
import { styles } from './styles';

interface Company {
    name: string;
    deleted: boolean;
}

export default function ListWithFirstLetters({ list }) {
    const dispatch = useDispatch<AppDispatch>();
    const classes = styles();

    const sortedFirstLetters = list && Object.keys(list).sort();

    const unblockCompany = (companyName) => {
        dispatch(unblockCompanyFromBlocklist(companyName));
    };

    const undoUnblockedCompany = (companyName) => {
        dispatch(undoUnblockCompany(companyName));
    };

    return (
        <div>
            <List className={classes.root} subheader={<li />}>
                {sortedFirstLetters.map((sectionId, index) => {
                    const filteredList = list[sectionId] as Company[];
                    const listItemHeight = 48 + filteredList.length * 48;
                    return (
                        <li key={`section-${sectionId}+${index}`} className={classes.listSection}>
                            <LazyLoad minHeight={listItemHeight}>
                                <ul className={classes.ul}>
                                    <ListSubheader className={classes.subheader}>{sectionId}</ListSubheader>
                                    {Object.values(filteredList).map((company, index) => (
                                        <LazyLoad key={company.name} minHeight={48}>
                                            <ListItem key={`item-${sectionId}-${index}`}>
                                                <ListItemText
                                                    data-cy="blocked-company-name"
                                                    primary={capitalizeFirstLetters(company.name)}
                                                    key={company.name}
                                                    className={classes.listItem}
                                                />
                                                <div className={company.deleted && classes.unblocked}>
                                                    {company.deleted && (
                                                        <Typography className={classes.greenText}>Unblocked</Typography>
                                                    )}
                                                    <Button
                                                        dataCy="unblock-company-button"
                                                        variant="outlined"
                                                        text={company.deleted ? 'Undo' : 'Unblock'}
                                                        fontColor="#3A4B59"
                                                        classes={['white-grey-border', 'blocklistVariant']}
                                                        className={classes.button}
                                                        onClick={() =>
                                                            company.deleted
                                                                ? undoUnblockedCompany(company.name)
                                                                : unblockCompany(company.name)
                                                        }
                                                    />
                                                </div>
                                            </ListItem>
                                        </LazyLoad>
                                    ))}
                                </ul>
                            </LazyLoad>
                        </li>
                    );
                })}
                <div style={{ margin: '20px 0' }}></div>
            </List>
        </div>
    );
}
