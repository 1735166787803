import { uiActionTypes } from '../actionTypes';

export const setNotification = (message, severity) => {
    return {
        type: uiActionTypes.SET_NOTIFICATION,
        message: message,
        severity: severity,
    };
};

export const displayBlocklistModal = (value) => {
    return {
        type: uiActionTypes.BLOCKLIST_MODAL,
        payload: value,
    };
};

export const handleOutOfCreditModalState = (state, refreshDate) => {
    return {
        type: uiActionTypes.OUT_OF_CREDITS_MODAL,
        state: state,
        refreshDate: refreshDate,
    };
};

export const handleMoveProspects = (payload) => {
    return {
        type: uiActionTypes.MOVING_PROSPECTS,
        payload,
    };
};

export const updateProcessingPaymentType = (payload) => {
    return {
        type: uiActionTypes.PROCESSING_PAYMENT_TYPE,
        payload,
    };
};

export const updatePaymentStatus = (payload) => {
    return {
        type: uiActionTypes.PAYMENT_STATUS,
        payload,
    };
};

export const handleErrorNotification =
    (message, time = 3) =>
    async (dispatch, getState) => {
        dispatch(setNotification(message, 'error'));
        setTimeout(() => {
            dispatch(setNotification('', 'error'));
        }, time * 1000);
    };

/** @param time - In seconds */
export const handleSuccessNotification =
    (message, time = 3) =>
    async (dispatch, getState) => {
        dispatch(setNotification(message, 'success'));
        setTimeout(() => {
            dispatch(setNotification('', 'success'));
        }, time * 1000);
    };

export const handleWarningNotification =
    (message, time = 3) =>
    async (dispatch, getState) => {
        dispatch(setNotification(message, 'warning'));
        setTimeout(() => {
            dispatch(setNotification('', 'warning'));
        }, time * 1000);
    };

export const handleInfoNotification =
    (message, time = 3) =>
    async (dispatch, getState) => {
        dispatch(setNotification(message, 'info'));
        setTimeout(() => {
            dispatch(setNotification('', 'info'));
        }, time * 1000);
    };

export type ReduxNotificationAction = ReturnType<
    | typeof setNotification
    | typeof handleSuccessNotification
    | typeof handleWarningNotification
    | typeof handleInfoNotification
    | typeof handleErrorNotification
>;
