import { FC } from 'react';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@shadcn/ui/components/ui/dialog';
import { Button } from '@shadcn/ui/components/ui/button';
import { UserStatus } from '@zaplify/users/shared';
import { UserToManage } from '../../../../../../hooks/useUsersManagement';

interface StatusChangeDialogProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    user: UserToManage;
    status: UserStatus;
}

export const StatusChangeDialog: FC<StatusChangeDialogProps> = ({ isOpen, onClose, onConfirm, user, status }) => {
    const isActivating = status === UserStatus.ACTIVATED;

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>{isActivating ? 'Activate User' : 'Deactivate User'}</DialogTitle>
                    <DialogDescription>
                        {isActivating
                            ? `Are you sure you want to activate ${user.fullName || user.email}?`
                            : `Are you sure you want to deactivate ${user.fullName || user.email}?`}
                    </DialogDescription>
                </DialogHeader>
                <DialogFooter>
                    <Button variant="outline" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button
                        variant={isActivating ? 'default' : 'destructive'}
                        onClick={() => {
                            onConfirm();
                            onClose();
                        }}
                    >
                        {isActivating ? 'Activate' : 'Deactivate'}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};
