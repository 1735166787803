import { Topics } from './topics';

/**
 * @deprecated Use ICommand instead.
 */
export abstract class IUntypedCommand {
    public static readonly TOPIC: Topics;
}

/**
 * Use in conjuction with ICommandHandler.
 */
export abstract class ICommand<ReturnType = any> {
    public static readonly TOPIC: Topics;

    /**
     * Will never be assigned or used as a value.
     * Only used to infer the return type of the command.
     */
    returnType: ReturnType | undefined = undefined;
}

/**
 * Enforces return types of handler to correspond with that of the command.
 */
export interface ICommandHandler<T extends ICommand<any>> {
    handle(q: T): Promise<T['returnType']>;
}
