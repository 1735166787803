// @DEPRECATED
// @TODO Remove this file
export const authStates = {
    UNKNOWN: 'UNKNOWN',
    LOGGED_OUT: 'LOGGED_OUT',
    // NOT_VERIFIED: 'NOT_VERIFIED',
    // NOT_CONFIGURED: 'NOT_CONFIGURED',
    // FULLY_CONFIGURED: 'FULLY_CONFIGURED',
    // CHOOSE_ORGANIZATION: 'CHOOSE_ORGANIZATION',
};
