import React, { createContext, useContext, useState, useCallback, ReactNode, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { getZaplifySdk, LinkedinConversation } from '@zaplify/sdk';
import { useAuth } from './authentication-provider';
import { toast } from '@shadcn/ui/hooks/use-toast';
import { paths } from '../../routes/paths';
import { sleep } from '../../services/utils/helpFunctions';
import { useLiConversationsImport } from '../hooks/linkedin/use-li-conversations-import';
import { ConversationMessageParsed } from '@zaplify/web-extension-shared';
import { ConnectionStatus } from '@zaplify/campaigns';
import { Creator } from '@zaplify/services/notes/shared';
import { ContactSource } from '@zaplify/services/user-contacts/shared';
import { useSdk } from '../sdk';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ProspectDataDto } from '@zaplify/prospects';
import { parseLinkedinConversationToUserActivity } from '../functions/trackers';
import { ChannelProvider } from '@zaplify/channel-accounts/shared';
import { useAddContacts } from '../hooks/use-add-contacts';

export interface PlaybookTemplate {
    name: string;
    tone: string;
    purpose: string;
    context: string;
    language: string;
    targetGroup?: string;
}

export type OnboardingStep =
    | 'fetchLinkedinConversations'
    | 'setupPlaybook'
    | 'analyzeWebsite'
    | 'conversationAnalysis'
    | 'finalSetup';

export interface StepProgress {
    id: OnboardingStep;
    status: 'pending' | 'in-progress' | 'completed';
}

const MUTATION_KEYS = {
    SETUP_USER: 'setupUser',
    UPDATE_WORKSPACE: 'updateWorkspace',
    UPDATE_PLAYBOOK: 'updatePlaybook',
    GET_COMPANY_PROFILE: 'getCompanyProfile',
    GET_LINKEDIN_CONVERSATIONS: 'getLinkedinConversations',
    IMPORT_LINKEDIN_ANALYSIS: 'importLinkedinAnalysis',
} as const;

interface OnboardingContextType {
    setupNewWorkspace: (
        playbook: PlaybookTemplate,
        website: string,
        country: string,
        userOrganizationName: string,
        domain: string
    ) => Promise<void>;
    setupUser: ({ language }: { language: string }) => Promise<{ playbookId: string; assistantSettingsId: string }>;
    updatePlaybook: (assistantSettingsId: string, playbook: PlaybookTemplate) => Promise<void>;
    updateWorkspace: (userOrganizationName: string, domain: string, website: string) => Promise<void>;
    isLoading: boolean;
    error: string | null;
    steps: Record<OnboardingStep, StepProgress>;
}

// Create the context with default values
const OnboardingContext = createContext<OnboardingContextType | undefined>(undefined);

// Create the provider
export const OnboardingProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const navigate = useNavigate();
    const { authState, refreshOnboardingState } = useAuth();
    const sdk = getZaplifySdk();
    const userId = authState.userId;
    const userOrganizationId = authState.userOrganizationId;
    const {
        channelAccount: { getMyChannelAccounts },
        playbook: { updateAssistantSettings },
        onboarding: { setupMessenger, importLinkedinAnalysis: importLinkedinAnalysisMutation },
    } = useSdk();
    const { data: channelAccounts } = useQuery(getMyChannelAccounts());
    const linkedinChannelAccount = useMemo(
        () => channelAccounts?.find((account) => account.provider === ChannelProvider.LINKEDIN),
        [channelAccounts]
    );

    const { getLiConversations, fetchProfiles, getMessagesFromConversation } = useLiConversationsImport();

    // Prospect handling
    const { addContacts } = useAddContacts();

    const {
        notes: { createNote: createNoteMutationFn },
    } = useSdk();

    const { mutateAsync: createNote } = useMutation(createNoteMutationFn());

    // State management
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    // Initialize step progress state
    const [steps, setSteps] = useState<Record<OnboardingStep, StepProgress>>({
        fetchLinkedinConversations: {
            id: 'fetchLinkedinConversations',
            status: 'pending',
        },
        analyzeWebsite: {
            id: 'analyzeWebsite',
            status: 'pending',
        },
        setupPlaybook: {
            id: 'setupPlaybook',
            status: 'pending',
        },
        conversationAnalysis: {
            id: 'conversationAnalysis',
            status: 'pending',
        },
        finalSetup: {
            id: 'finalSetup',
            status: 'pending',
        },
    });

    const { mutateAsync: setupUser } = useMutation(setupMessenger());

    const { mutateAsync: updateWorkspaceSettings } = useMutation({
        mutationKey: [MUTATION_KEYS.UPDATE_WORKSPACE],
        mutationFn: ({
            userOrganizationName,
            domain,
            website,
        }: {
            userOrganizationName: string;
            domain: string;
            website: string;
        }) =>
            sdk.profiles.userOrganization.updateOrganization(userOrganizationId, {
                name: userOrganizationName,
                domain,
                website,
            }),
        retry: 3,
        retryDelay: (attemptIndex) => Math.min(1000 * Math.pow(2, attemptIndex), 30000),
    });

    const { mutateAsync: updatePlaybookSettings } = useMutation(updateAssistantSettings());

    const { mutateAsync: getCompanyProfile } = useMutation({
        mutationKey: [MUTATION_KEYS.GET_COMPANY_PROFILE],
        mutationFn: (domain: string) => sdk.profiles.messenger.researchCompanyByDomain(domain),
        retry: 3,
        retryDelay: (attemptIndex) => Math.min(1000 * Math.pow(2, attemptIndex), 30000),
    });

    const { mutateAsync: getLinkedinConversations } = useMutation({
        mutationKey: [MUTATION_KEYS.GET_LINKEDIN_CONVERSATIONS],
        mutationFn: async (limit: number = 18) => {
            const conversations = await fetchConversationsWithTimeout(limit);
            if (!conversations || !conversations.length) {
                return [];
            }

            const memberIds = conversations.map((c) => c.prospect?.memberId).filter(Boolean);
            if (memberIds.length === 0) {
                return [];
            }

            const profiles = await fetchProfiles({ memberIds, slowFetch: false });
            if (!profiles || profiles.length === 0) {
                return [];
            }

            const conversationMessages = await fetchConversationsBatch(conversations);

            return profiles.map((profile) => ({
                profile,
                connectionStatus: profile.connectionStatus,
                conversation: conversationMessages
                    .filter((msg) => msg.memberId === profile.prospectData?.linkedinUserId)
                    .flatMap((msg) => msg.messages || []),
            }));
        },
        retry: 3,
        retryDelay: (attemptIndex) => Math.min(1000 * Math.pow(2, attemptIndex), 30000),
    });

    const { mutateAsync: importLinkedinAnalysis } = useMutation(importLinkedinAnalysisMutation());

    // Update step status and progress
    const updateStep = useCallback((stepId: OnboardingStep, status: 'pending' | 'in-progress' | 'completed') => {
        setSteps((prev) => ({
            ...prev,
            [stepId]: {
                ...prev[stepId],
                status,
            },
        }));
    }, []);

    const fetchConversationsWithTimeout = useCallback(
        async (limit: number): Promise<LinkedinConversation[]> => {
            try {
                const res = await getLiConversations(undefined, undefined, limit);
                const conversations = res.conversations || [];

                return [
                    ...conversations.filter((c) => !c.conversation.isInMail),
                    ...conversations.filter((c) => c.conversation.isInMail),
                ].slice(0, limit);
            } catch (error) {
                console.error('Error fetching LinkedIn conversations:', error);
                return [];
            }
        },
        [getLiConversations]
    );

    const fetchConversationsBatch = useCallback(
        async (
            conversations: LinkedinConversation[],
            batchSize: number = 5
        ): Promise<Array<{ memberId: string; messages: ConversationMessageParsed[] }>> => {
            const results: Array<{ memberId: string; messages: ConversationMessageParsed[] }> = [];

            for (let i = 0; i < conversations.length; i += batchSize) {
                const batch = conversations.slice(i, i + batchSize);
                const batchPromises = batch.map(async (conv) => {
                    const messages = await getMessagesFromConversation(conv.conversation.id, 10);
                    return {
                        memberId: conv.prospect.memberId,
                        messages,
                    };
                });

                const batchResults = await Promise.all(batchPromises);
                results.push(...batchResults);
            }

            return results;
        },
        [getMessagesFromConversation]
    );

    const addRefererAsContact = useCallback(async (): Promise<void> => {
        try {
            const refererLinkedinUserId = await sdk.profiles.user.getRefererLinkedinUserId();
            if (!refererLinkedinUserId) {
                return;
            }

            const prospectIds = await addContacts([{ linkedinUserId: refererLinkedinUserId }], {
                source: ContactSource.Onboarding,
            });

            if (!prospectIds?.success) {
                return;
            }

            const prospect = await sdk.profiles.prospects.getProspect(prospectIds[0]);
            const note = {
                title: '',
                content: `Since you signed up using ${prospect?.data.firstName}'s referral link, we've added them as a contact. Ask ${prospect?.data.firstName} about their experience with Andsend, or thank them for referring you.`,
                prospectId: prospectIds[0],
                userId: userId,
                creator: Creator.System,
            };
            await createNote(note);
        } catch (err) {
            console.error('Error adding referer as contact', err);
        }
    }, [addContacts, sdk.profiles.user, sdk.profiles.prospects, userId, createNote]);

    const updatePlaybook = useCallback(
        async (assistantSettingsId: string, playbook: PlaybookTemplate) => {
            await updatePlaybookSettings({
                assistantSettingsId: assistantSettingsId,
                updatedAssistantSettings: playbook,
            });
            if (playbook.purpose) {
                window.analytics?.identify({
                    purpose_of_using_zaplify: playbook.purpose,
                    outreach_language: playbook.language,
                });
            }
        },
        [updatePlaybookSettings]
    );

    const updateWorkspace = useCallback(
        async (userOrganizationName: string, domain: string, website: string) => {
            await updateWorkspaceSettings({ userOrganizationName, domain, website });
        },
        [updateWorkspaceSettings]
    );

    const setupNewWorkspace = useCallback(
        async (
            playbook: PlaybookTemplate,
            website: string,
            country: string,
            userOrganizationName: string,
            domain: string
        ) => {
            setIsLoading(true);
            setError(null);

            // State machine state
            let currentStep: OnboardingStep = 'fetchLinkedinConversations';
            let setupData: {
                assistantSettingsId?: string;
                conversations?: Array<{
                    profile: { prospectData: ProspectDataDto; connectionStatus: ConnectionStatus };
                    connectionStatus: ConnectionStatus;
                    conversation: ConversationMessageParsed[];
                }>;
                companyProfile?: string;
            } = {};

            // Order of steps in the onboarding process
            const stepOrder: OnboardingStep[] = [
                'fetchLinkedinConversations',
                'analyzeWebsite',
                'setupPlaybook',
                'conversationAnalysis',
                'finalSetup',
            ];

            const getNextStep = (currentStep: OnboardingStep): OnboardingStep | null => {
                if (steps[currentStep].status === 'in-progress') {
                    return currentStep;
                }

                const currentIndex = stepOrder.indexOf(currentStep);
                const nextIndex = currentIndex + 1;

                if (nextIndex < stepOrder.length) {
                    return stepOrder[nextIndex];
                }

                return null;
            };

            // Function to execute a step
            const executeStep = async (step: OnboardingStep): Promise<void> => {
                switch (step) {
                    case 'fetchLinkedinConversations':
                        updateStep('fetchLinkedinConversations', 'in-progress');
                        const userSetup = await setupUser({ language: playbook.language });
                        setupData.assistantSettingsId = userSetup.assistantSettingsId;

                        updateStep('fetchLinkedinConversations', 'in-progress');

                        const conversations = await getLinkedinConversations(18);
                        console.log('Fetched LinkedIn conversations:', conversations.length);
                        setupData.conversations = conversations;

                        updateStep('fetchLinkedinConversations', 'completed');
                        break;

                    case 'analyzeWebsite':
                        if (website) {
                            updateStep('analyzeWebsite', 'in-progress');
                            const companyProfileResult = await getCompanyProfile(website || domain);
                            setupData.companyProfile = companyProfileResult?.researchSummary
                                ? `My company profile:\n${companyProfileResult.researchSummary}`
                                : '';
                            updateStep('analyzeWebsite', 'completed');
                        } else {
                            updateStep('analyzeWebsite', 'completed');
                        }
                        break;
                    case 'setupPlaybook':
                        updateStep('setupPlaybook', 'in-progress');

                        const companyProfile = setupData.companyProfile || '';
                        const enrichedContext = companyProfile
                            ? `My approach:\n${playbook.context}\n\n${companyProfile}`.trim()
                            : `My approach:\n${playbook.context}`.trim();

                        const updatedPlaybook = {
                            ...playbook,
                            context: enrichedContext,
                        };

                        if (!setupData.assistantSettingsId) {
                            throw new Error('Failed to get assistant settings ID');
                        }

                        await Promise.all([
                            updateWorkspace(userOrganizationName, domain, website),
                            updatePlaybook(setupData.assistantSettingsId, updatedPlaybook),
                        ]);

                        updateStep('setupPlaybook', 'completed');
                        break;

                    case 'conversationAnalysis':
                        updateStep('conversationAnalysis', 'in-progress');

                        const createdProspects = await importLinkedinAnalysis({
                            profiles: setupData.conversations!.map((c) => ({
                                profile: c.profile.prospectData,
                                activities: parseLinkedinConversationToUserActivity(
                                    c.conversation,
                                    linkedinChannelAccount?.LINKEDIN?.userId,
                                    c.profile.connectionStatus
                                ),
                            })),
                            assistantId: setupData.assistantSettingsId!,
                        });

                        if (createdProspects.length < 3) {
                            const notCreatedProspects = setupData
                                .conversations!.filter(
                                    (c) =>
                                        c.profile.prospectData.linkedinUserId &&
                                        !createdProspects.find(
                                            (p) => p.data.linkedinUserId === c.profile.prospectData.linkedinUserId
                                        )
                                )
                                .map((c) => c.profile.prospectData);

                            await getZaplifySdk().profiles.sources.runBulkImport(
                                notCreatedProspects.slice(0, 3 - createdProspects.length),
                                ContactSource.Onboarding
                            );
                        }

                        updateStep('conversationAnalysis', 'completed');
                        break;

                    case 'finalSetup':
                        updateStep('finalSetup', 'in-progress');
                        await refreshOnboardingState();

                        // Add referrer as contact if applicable (non-blocking)
                        try {
                            await addRefererAsContact();
                        } catch (refererError) {
                            console.error('Error adding referrer as contact (non-critical):', refererError);
                            // Non-critical error, continue with the flow
                        }

                        await sleep(3000);

                        updateStep('finalSetup', 'completed');
                        break;

                    default:
                        throw new Error(`Unknown step: ${step}`);
                }
            };

            try {
                // Execute steps in sequence
                while (currentStep) {
                    try {
                        // Execute current step
                        if (steps[currentStep].status === 'pending') {
                            console.log('Executing step:', currentStep);
                            await executeStep(currentStep);
                        }

                        // Move to next step if step is completed
                        const nextStep = getNextStep(currentStep);
                        if (!nextStep) {
                            break; // No more steps
                        }

                        if (nextStep === currentStep) {
                            await sleep(200);
                        }

                        currentStep = nextStep;
                    } catch (stepError) {
                        console.error(`Error in step ${currentStep}:`, stepError);
                        throw stepError;
                    }
                }

                // All steps completed successfully
                setIsLoading(false);
                console.log('Onboarding completed successfully');

                // Navigate to the next step in the onboarding flow
                const nextPath = paths.NEW.ACTION_FEED;
                navigate(nextPath);
            } catch (error) {
                console.error('Error in onboarding process:', error);

                let errorMessage = 'Failed to set up your workspace. Please try again.';
                if (error instanceof Error) {
                    errorMessage = `Error: ${error.message}`;
                }

                setError(errorMessage);
                setIsLoading(false);

                toast({
                    title: 'Error setting up workspace',
                    description: errorMessage,
                    variant: 'destructive',
                });
            }
        },
        [
            navigate,
            updateStep,
            updateWorkspaceSettings,
            updatePlaybookSettings,
            setupUser,
            getLinkedinConversations,
            getCompanyProfile,
            importLinkedinAnalysis,
            addRefererAsContact,
        ]
    );

    return (
        <OnboardingContext.Provider
            value={{
                setupNewWorkspace,
                setupUser,
                updatePlaybook,
                updateWorkspace,
                isLoading,
                error,
                steps,
            }}
        >
            {children}
        </OnboardingContext.Provider>
    );
};

// Hook to use the onboarding context
export const useOnboarding = () => {
    const context = useContext(OnboardingContext);
    if (context === undefined) {
        throw new Error('useOnboarding must be used within an OnboardingProvider');
    }
    return context;
};
