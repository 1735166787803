import { IQuery, Topics } from '@zaplify/common';
import { UserContactDto } from '../dtos';
import { RequireAtLeastOne } from '@zaplify/utils';

export class GetUserContactsQuery<T extends keyof UserContactDto = keyof UserContactDto | never> extends IQuery<
    [T] extends [never] ? UserContactDto[] : Pick<UserContactDto, T>[]
> {
    public static override readonly TOPIC = Topics.PROSPECTS;
    public readonly userId?: string;
    public readonly prospectIds?: string[];
    public readonly includeUnassignedContacts: boolean;
    constructor(
        params: RequireAtLeastOne<{ userId: string; prospectIds: string[] }> & { includeUnassignedContacts?: boolean },
    );
    constructor(
        params: RequireAtLeastOne<{ userId: string; prospectIds: string[] }> & { includeUnassignedContacts?: boolean },
        pickFields: T[],
    );
    constructor(
        params: RequireAtLeastOne<{ userId: string; prospectIds: string[] }> & { includeUnassignedContacts?: boolean },
        readonly pickFields?: T[],
    ) {
        super();
        this.userId = params.userId;
        this.prospectIds = params.prospectIds;
        this.includeUnassignedContacts = params.includeUnassignedContacts ?? false;
    }
}
