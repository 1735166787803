import { Avatar, AvatarFallback, AvatarImage } from '@shadcn/ui/components/ui/avatar';
import { Tooltip, TooltipContent, TooltipTrigger } from '@shadcn/ui/components/ui/tooltip';
import { ConnectionStatusBadge } from './connection-status-badge';
import { useSuggestionsFeed } from '../../../hooks/use-suggestions-feed';
import { getInitials } from '../../../../../../functions/get-initials';
import { Skeleton } from '@shadcn/ui/components/ui/skeleton';

export const ContactCardHeader = () => {
    const {
        currentContactSuggestion: contactSuggestion,
        currentContactSuggestionProfileData: profileData,
        isFetchingCurrentLinkedinProfile,
    } = useSuggestionsFeed();

    const name = contactSuggestion?.contactName || profileData?.fullName;
    const title = profileData.linkedinHeadline || contactSuggestion?.contactTitle || profileData?.occupationTitle;
    const profileImage = profileData?.profilePictureUrl
        ? profileData?.profilePictureUrl
        : contactSuggestion?.contactImgUrl;

    return (
        <div className="flex flex-row items-center justify-start gap-2 animate-in fade-in-90 duration-100">
            <div className="flex flex-row items-end">
                <Avatar size="xl">
                    {isFetchingCurrentLinkedinProfile && !profileImage ? (
                        <Skeleton className="w-full h-full" />
                    ) : (
                        <>
                            <AvatarImage src={profileImage} />
                            <AvatarFallback>{getInitials(name || '')}</AvatarFallback>
                        </>
                    )}
                </Avatar>
                <Tooltip>
                    <TooltipTrigger className="z-10">
                        <ConnectionStatusBadge contactSuggestion={contactSuggestion} className="-ml-4" />
                    </TooltipTrigger>
                    <TooltipContent>
                        <p>Connected on LinkedIn</p>
                    </TooltipContent>
                </Tooltip>
            </div>
            <div className="flex flex-col items-start justify-start w-full">
                <div className="flex flex-row items-center gap-2 w-full">
                    <p className="text-md font-medium">{name}</p>
                </div>
                <p className="text-sm text-muted-foreground">{title}</p>
            </div>
        </div>
    );
};
