export const salesforceLocalstorageKey = 'salesforce-environment';

export const salesforceOAuthUrl = import.meta.env.VITE_SALESFORCE_AUTHENTICATION_URI;
export const salesforceContactStrategy = 'ContactStrategy';
export const salesforceLeadStrategy = 'LeadStrategy';

export const salesforceProductionLink = 'https://login.salesforce.com/';
export const salesforceSandboxLink = 'https://test.salesforce.com/';

export const ObjectTypes = {
    lead: 'Lead',
    account: 'Account',
    contact: 'Contact',
};
