import * as React from 'react';
import { SearchParamDialog, useSearchParamDialog } from '@shadcn/ui/components/search-param-dialog';
import {
    Dialog,
    DialogClose,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@shadcn/ui/components/ui/dialog';
import { DialogContent } from '@shadcn/ui/components/ui/dialog';
import { Button } from '@shadcn/ui/components/ui/button';

interface DirtyFieldsNavigationDialogProps {
    open: boolean;
    onOpenChange: (open: boolean) => void;
    onSave?: () => Promise<void>;
    onProceed?: () => void;
}

export const DirtyFieldsNavigationDialog: React.FC<DirtyFieldsNavigationDialogProps> = ({
    open,
    onOpenChange,
    onSave,
    onProceed,
}) => {
    const handleSave = async () => {
        if (onSave) {
            await onSave();
        }
        if (onProceed) {
            onProceed();
        }
        onOpenChange(false);
    };

    const handleDontSave = () => {
        if (onProceed) {
            onProceed();
        }
        onOpenChange(false);
    };

    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Unsaved Changes</DialogTitle>
                    <DialogDescription>
                        You have unsaved changes. Do you want to save them before leaving?
                    </DialogDescription>
                </DialogHeader>
                <DialogFooter>
                    <DialogClose asChild onClick={handleDontSave}>
                        <Button variant="outline">Don't Save</Button>
                    </DialogClose>
                    <DialogClose asChild onClick={handleSave}>
                        <Button>Save</Button>
                    </DialogClose>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};
