import { ToolCallContentPartComponent } from '@assistant-ui/react';
import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from 'lucide-react';
import { useState } from 'react';
import { Button } from '../ui/button';

export const ToolFallback: ToolCallContentPartComponent = ({ toolName, argsText, result }) => {
    const [isCollapsed, setIsCollapsed] = useState(true);
    return (
        <div className="mb-4 flex w-full flex-col gap-3 rounded-lg border py-3">
            <div className="flex items-center gap-2 px-4">
                <CheckIcon className="size-4" />
                <p className="">
                    Used tool: <b>{toolName}</b>
                </p>
                <div className="flex-grow" />
                <Button onClick={() => setIsCollapsed(!isCollapsed)}>
                    {isCollapsed ? <ChevronUpIcon /> : <ChevronDownIcon />}
                </Button>
            </div>
            {!isCollapsed && (
                <div className="flex flex-col gap-2 border-t pt-2">
                    <div className="px-4">
                        <pre className="whitespace-pre-wrap">{argsText}</pre>
                    </div>
                    {result !== undefined && (
                        <div className="border-t border-dashed px-4 pt-2">
                            <p className="font-semibold">Result:</p>
                            <pre className="whitespace-pre-wrap">
                                {typeof result === 'string' ? result : JSON.stringify(result, null, 2)}
                            </pre>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
