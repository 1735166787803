import { Box, ButtonBase, Divider, Skeleton, Typography } from '@mui/material';
import { IPlan, SubscriptionDetailsDto } from '@zaplify/subscriptions';
import { useAtom } from 'jotai';
import { selectedPackagesAtom, upgradePlanErrorTextAtom } from '../../atoms/selfService';
import { readableBillingFrequencyUnit } from '../../helpers/text';
import OrderSummaryStyled from './OrderSummary.styled';
import { VatInformation } from './VatInformation';

const OrderSummary = ({
    subscriptionDetails,
    estimatedPricePerMonth,
    onPurchase,
    totalAmountToCharge,
    loadingState,
    selectedPlan,
    shouldShowDiscount,
    couponCode,
    vatTotal,
    immediatePurchase,
}: {
    subscriptionDetails: SubscriptionDetailsDto;
    estimatedPricePerMonth: string;
    vatTotal: string;
    onPurchase: (couponCode: string) => void;
    totalAmountToCharge: string;
    loadingState: 'idle' | 'loading';
    selectedPlan: IPlan;
    shouldShowDiscount: boolean;
    couponCode?: string;
    immediatePurchase: boolean;
}) => {
    const [selectedPlanPackages] = useAtom(selectedPackagesAtom);
    const [upgradePlanErrorText] = useAtom(upgradePlanErrorTextAtom);

    const selectedPlanPackage =
        selectedPlanPackages[`${selectedPlan?.billingPeriod?.unit}-${selectedPlan?.code}` || `'year-team'`];
    const hasRenewalDate = subscriptionDetails.subscription?.renewalAt;
    const renewalDate = hasRenewalDate ? new Date(subscriptionDetails.subscription?.renewalAt) : new Date();
    const renewalAt = renewalDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
    const readableBillingFrequencyValue = readableBillingFrequencyUnit(
        selectedPlan.billingPeriod.amount,
        selectedPlan.billingPeriod.unit
    );
    const planName = selectedPlan?.code === 'basic' ? 'Pro' : 'Team';

    return (
        <OrderSummaryStyled>
            {loadingState === 'loading' && <OrderSummaryLoader />}
            {loadingState === 'idle' && (
                <>
                    <Box px={'28px'} paddingTop={'28px'}>
                        <Typography className="label">Order summary</Typography>
                        <Box display="flex" justifyContent="space-between" marginTop={'18px'}>
                            <Typography className="plan-name" data-cy="summary-plan-name">
                                {planName}
                            </Typography>
                            <Typography className="plan-price" data-cy="summary-plan-price">
                                {estimatedPricePerMonth}/{readableBillingFrequencyValue}
                            </Typography>
                        </Box>
                        <Box display="flex" marginTop={'2px'}>
                            <Typography className="monthly" data-cy="summary-users">
                                {selectedPlanPackage.seats} user{selectedPlanPackage.seats > 1 && 's'}
                            </Typography>
                        </Box>
                        {!immediatePurchase && (
                            <Box marginTop={'5px'}>
                                <Typography className="renewal-at">New plan price starting {renewalAt}</Typography>
                            </Box>
                        )}
                    </Box>
                    <Box py={'22px'}>
                        <Divider className="divider" />
                    </Box>
                    <Box display={'flex'} px={'28px'} paddingBottom="5px" justifyContent={'space-between'}>
                        <Typography className="total-label">Total (charged today)</Typography>
                        <Typography className="total-price" data-cy="summary-total-price">
                            {totalAmountToCharge}
                        </Typography>
                    </Box>
                    <Box
                        display={'flex'}
                        px={'28px'}
                        paddingBottom="5px"
                        justifyContent={'right'}
                        alignItems={'center'}
                    >
                        <VatInformation vat={vatTotal} customerCountry={subscriptionDetails.customer.address.country} />
                    </Box>

                    {shouldShowDiscount && (
                        <Typography style={{ color: '#00C281', fontSize: '13px', margin: '0 0 10px 28px' }}>
                            Discount applied
                        </Typography>
                    )}
                    {upgradePlanErrorText && (
                        <Box
                            sx={{
                                px: '28px',
                                pt: '22px',
                                pb: '8px',
                            }}
                        >
                            <Typography color={'error'}>* {upgradePlanErrorText}</Typography>
                        </Box>
                    )}
                    <Box px={'28px'} marginBottom={'31px'}>
                        <ButtonBase
                            data-cy="purchase-plan-button"
                            className="purchase"
                            onClick={() => onPurchase(couponCode)}
                        >
                            <div className="purchase-button">Purchase</div>
                        </ButtonBase>
                    </Box>
                    <Box px={'28px'} paddingBottom={'28px'}>
                        <Typography className="billing-info">
                            Your payment will be {readableBillingFrequencyValue} auto-renewed unless cancelled before
                            next renewal date.
                        </Typography>
                    </Box>
                </>
            )}
        </OrderSummaryStyled>
    );
};

export default OrderSummary;
const OrderSummaryLoader = () => {
    return (
        <div style={{}} id={'foo'}>
            <Box padding={'26px 26px 0 26px'}>
                <Box mb={'16px'}>
                    <Skeleton animation="wave" variant={'text'} width={100} height={24} />
                </Box>
                <Box mb={'4px'}>
                    <Skeleton animation="wave" variant={'text'} width={110} height={20} />
                </Box>
                <Box mb={'6px'}>
                    <Skeleton animation="wave" variant={'text'} width={190} height={20} />
                </Box>
                <Box mb={'12px'}>
                    <Skeleton animation="wave" variant={'text'} width={220} height={20} />
                </Box>
            </Box>
            <Box py={'9px'}>
                <Divider className="divider" />
            </Box>
            <Box padding={'0 26px 26px 26px'}>
                <Box mt={'12px'} mb={'26px'}>
                    <Skeleton animation="wave" variant={'text'} width={140} height={24} />
                </Box>
                <Box mb={'28px'}>
                    <Skeleton
                        animation="wave"
                        variant="rectangular"
                        width={310}
                        height={45}
                        style={{ borderRadius: '8px' }}
                    />
                </Box>
                <Box mt={'12px'}>
                    <Skeleton animation="wave" variant={'text'} width={270} height={48} />
                </Box>
            </Box>
        </div>
    );
};
