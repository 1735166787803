import { ICommand, Topics } from '@zaplify/common';
import { CompanyBlocklistDto, BlocklistedOrganizationDto } from '../prospects';

export class AddCompanyToBlocklistCommand extends ICommand<CompanyBlocklistDto> {
    public static override readonly TOPIC = Topics.PROSPECTS;

    constructor(readonly organizationId: string, readonly company: BlocklistedOrganizationDto) {
        super();
    }
}
