import { Avatar, AvatarFallback, AvatarImage } from '@shadcn/ui/components/ui/avatar';
import { Building2Icon } from 'lucide-react';
import { ViewEmployeesButton } from '../view-employees-button';
import { useSuggestionsFeed } from '../../../hooks/use-suggestions-feed';

export const AccountCardHeader = () => {
    const { currentAccountData: accountData, currentContactSuggestion } = useSuggestionsFeed();

    const website = currentContactSuggestion?.accountWebsite || accountData?.data?.website;

    return (
        <div className="flex flex-row items-center justify-between gap-2">
            <div className="flex flex-row items-center justify-start gap-4">
                <Avatar size="xl">
                    <AvatarImage src={`https://logo.clearbit.com/${website}`} />
                    <AvatarFallback>
                        <Building2Icon className="w-6 h-6" />
                    </AvatarFallback>
                </Avatar>
                <div className="flex flex-col items-start justify-start">
                    <p className="text-md font-medium">{accountData?.data?.name}</p>
                    <div className="flex flex-row items-center justify-start gap-2">
                        <p className="text-sm text-muted-foreground">{accountData?.data?.industry}</p>
                    </div>
                </div>
            </div>
            <ViewEmployeesButton accountData={accountData} />
        </div>
    );
};
