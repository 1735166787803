import { Box, CircularProgress, Tooltip, Typography } from '@mui/material';
import { ChannelProvider, ChannelType } from '@zaplify/channel-accounts/shared';
import { getZaplifySdk } from '@zaplify/sdk';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux-latest';
import { useNavigate } from 'react-router-dom';
import { LocalStorageKeys } from '../../config';
import { isImpersonatingAUser as getIsImpersonatingAUser } from '../../helpers/is-global-admin';
import { refetchUserAndSubscription, updateChannelAccountsState } from '../../redux/actions';
import { AppDispatch, useAppSelector } from '../../redux/store/configureStore';
import { paths } from '../../routes/paths';
import { getExtensionVersion } from '../../services/chrome-extension';
import { isChromiumBasedBrowser, isMobileBrowser } from '../../services/utils/helpFunctions';
import useChannelAccountData from '../chats/hooks/use-channel-account-data';
import { ChromiumInfo } from './chromium-info';
import { ActionButton } from './connect-linkedin-step-action';
import { LinkButton } from './connect-linkedin-step-link';
import { MobileInfo } from './mobile-info';
import { ErrorList } from './connect-linkedin-step-error';
import { sleep } from '@zaplify/utils';
import { useAtom } from 'jotai';
import { channelAccountsAtom } from '../../atoms/chats';
import { paletteAndsend } from '@zaplify/ui';
import { ArrowRight } from 'lucide-react';
import { useWebExtension } from '../../new/hooks/use-web-extension';

const chromeStoreZaplifyId = 'nolanbablkmlhllilaloenjhaplnfhof';
const extensionId = import.meta?.env?.VITE_ZAPLIFY_CHROME_EXTENSION_ID || chromeStoreZaplifyId;

const waitMessages = [
    'Connecting to LinkedIn...',
    'LinkedIn connection is still in progress...',
    'This is taking longer than expected. Try refreshing the LinkedIn tab in your browser.',
];

export type LinkedInConnectionStatus =
    | 'connected'
    | 'notConnected'
    | 'connecting'
    | 'notAuthenticated'
    | 'notLoggedIn'
    | 'error'
    | 'loggedIn';
export type ExtensionStatus = 'installed' | 'notInstalled' | 'outdated';

export const ConnectLinkedInStepV2 = () => {
    const { newZaplifyMigration } = useFlags();
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const userId = useAppSelector((state) => state.user.zaplifyUser.id);
    const [linkedInConnectionStatus, setLinkedInConnectionStatus] = useState<LinkedInConnectionStatus | null>(null);
    const [extensionStatus, setExtensionStatus] = useState<ExtensionStatus | null>('notInstalled');
    const [connectingText, setConnectingText] = useState<string>(null);
    const { getLinkedinCookies, getMe, reloadExtension } = useWebExtension();
    const { refetchChannelAccounts } = useChannelAccountData();
    const [channelAccounts] = useAtom(channelAccountsAtom);
    const linkedInConnectionStatusRef = useRef<LinkedInConnectionStatus | null>(null);
    const waitMessageIndexRef = useRef<number | null>(null);
    const connectingAttemptsRef = useRef(0);
    linkedInConnectionStatusRef.current = linkedInConnectionStatus;

    const handleConnect = async () => {
        try {
            setLinkedInConnectionStatus('connecting');
            connectingAttemptsRef.current += 1;
            console.log('Connecting to LinkedIn...');
            await sleep(3000);

            const myLinkedin = await getMe();
            console.log('Connecting to LinkedIn myLinkedin', myLinkedin);
            if (myLinkedin?.error) {
                setLinkedInConnectionStatus('notLoggedIn');
                console.error('Could not get connection from LinkedIn. Are you logged in to LinkedIn?', myLinkedin);
                reloadExtension();
                return;
            }
            const linkedinCookies = await getLinkedinCookies();

            const linkedinChannelAccountDto = {
                userId,
                type: ChannelType.LINKEDIN,
                provider: ChannelProvider.LINKEDIN,
                LINKEDIN: {
                    sessionCookie: linkedinCookies.li_at?.value,
                    cookies: linkedinCookies.all,
                    userAgent: navigator.userAgent,
                    headline: myLinkedin.headline,
                    username: myLinkedin.profileUrl,
                    userId: myLinkedin.memberId,
                    isPremiumAccount: myLinkedin.isPremiumSubscriber,
                },
            };

            const profilesSdk = getZaplifySdk().profiles;
            await profilesSdk.channelAccount.createLinkedinChannelAccount(
                linkedinChannelAccountDto.LINKEDIN,
                `${myLinkedin.firstName} ${myLinkedin.lastName}`
            );
            await dispatch(refetchUserAndSubscription());
            await dispatch(updateChannelAccountsState(userId));
            await refetchChannelAccounts();
            localStorage.removeItem(LocalStorageKeys.LI_AUTHENTICATION_ATTEMPTS);
            setLinkedInConnectionStatus('connected');
        } catch (error) {
            console.error(`connectToLinkedIn | error: ${JSON.stringify(error)}`);

            if (connectingAttemptsRef.current === 1) {
                setLinkedInConnectionStatus('notConnected');
                console.log('connectToLinkedIn | error not showing on first attempt');
                return;
            }

            if (window?.analytics) {
                window.analytics.track('LinkedIn Cookie Error', {
                    location: 'addLinkedinChannelToDB()',
                    error: error,
                });
            }
            setLinkedInConnectionStatus('error');
        }
    };

    const handleContinue = () => {
        window?.analytics?.track('Onboarding Connect LinkedIn');

        if (newZaplifyMigration) {
            navigate(paths.NEW_ZAPLIFY_FLOW_AI_PREFERENCES);
            return;
        }

        navigate(paths.ONBOARDING_AI_PREFERENCES);
    };

    useEffect(() => {
        const checkStatus = async () => {
            if (await getIsImpersonatingAUser()) return;

            try {
                if (
                    linkedInConnectionStatusRef.current === 'connecting' ||
                    linkedInConnectionStatusRef.current === 'connected'
                )
                    return;

                const linkedInAccount = channelAccounts?.linkedIn;

                if (!!linkedInAccount && linkedInAccount?.status === 'AUTHENTICATED') {
                    setLinkedInConnectionStatus('connected');
                    return;
                }

                const [extensionVersion, linkedinCookies] = await Promise.all([
                    getExtensionVersion(),
                    getLinkedinCookies(),
                ]);
                const { hasExtension } = extensionVersion;
                setExtensionStatus(hasExtension ? 'installed' : 'notInstalled');

                if (!hasExtension) {
                    setLinkedInConnectionStatus('notConnected');
                    return;
                }

                if (!linkedinCookies.li_at?.value) {
                    setLinkedInConnectionStatus('notLoggedIn');
                    return;
                } else {
                    setLinkedInConnectionStatus('notConnected');
                }

                if (!!linkedInAccount && linkedInAccount?.status === 'AUTHENTICATED') {
                    setLinkedInConnectionStatus('connected');
                } else if (linkedInConnectionStatusRef.current === 'notConnected') {
                    await handleConnect();
                }
            } catch (error) {
                console.error('Error checking status:', error);
                setLinkedInConnectionStatus('error');
            }
        };

        const interval = setInterval(checkStatus, 2000);

        return () => clearInterval(interval);
    }, [userId]);

    useEffect(() => {
        let interval: NodeJS.Timeout;

        setConnectingText(null);
        if (linkedInConnectionStatus === 'connecting') {
            interval = setInterval(() => {
                const currentIndex = waitMessageIndexRef.current;
                setConnectingText(waitMessages[currentIndex]);
                if (currentIndex < waitMessages.length - 1) {
                    waitMessageIndexRef.current += 1;
                }
            }, 8000);
        }

        return () => clearInterval(interval);
    }, [linkedInConnectionStatus]);

    if (isMobileBrowser()) {
        return <MobileInfo />;
    }

    if (!isChromiumBasedBrowser()) {
        return <ChromiumInfo />;
    }

    type ConnectionStatus = 'install_extension' | 'login' | 'connect' | 'connecting' | 'error' | 'continue';

    let currentStatus: ConnectionStatus;
    if (linkedInConnectionStatus === 'connected') {
        currentStatus = 'continue';
    } else if (extensionStatus === 'notInstalled') {
        currentStatus = 'install_extension';
    } else if (linkedInConnectionStatus === 'notLoggedIn') {
        currentStatus = 'login';
    } else if (linkedInConnectionStatus === 'notConnected') {
        currentStatus = 'connect';
    } else if (linkedInConnectionStatus === 'connecting') {
        currentStatus = 'connecting';
    } else if (linkedInConnectionStatus === 'error') {
        currentStatus = 'error';
    } else {
        currentStatus = 'continue';
    }

    const isOnStepOne = currentStatus === 'install_extension';
    const isOnStepTwo = currentStatus === 'login';
    const isOnStepThree =
        currentStatus === 'connect' ||
        currentStatus === 'connecting' ||
        currentStatus === 'error' ||
        currentStatus === 'continue';
    return (
        <Box>
            <Typography
                variant="h1"
                sx={{
                    fontWeight: 600,
                    lineHeight: '40px',
                    letterSpacing: '-0.02em',
                    marginBottom: '10px',
                }}
            >
                Connect LinkedIn
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '24px',
                    textAlign: 'left',
                    marginBottom: '20px',
                    color: '#475467',
                }}
            >
                Connect your LinkedIn account to Andsend using our Chrome extension.
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px', paddingY: '20px' }}>
                <ConnectionStep
                    label="Install Chrome extension"
                    subTitle="Start by installing our extension to establish the connection with LinkedIn."
                    isActive={isOnStepOne}
                    isCompleted={!isOnStepOne}
                    isFirstStep
                    button={
                        <Tooltip title="If you have already done so, please try uninstalling and reinstalling it.">
                            <span>
                                <LinkButton link={`https://chrome.google.com/webstore/detail/zaplify/${extensionId}`}>
                                    Install Chrome Extension
                                </LinkButton>
                            </span>
                        </Tooltip>
                    }
                />
                <ConnectionStep
                    label="Log in to LinkedIn in your browser"
                    subTitle="To enable LinkedIn and Andsend connection you have to be logged in to your account."
                    isActive={isOnStepTwo}
                    isCompleted={!isOnStepOne && !isOnStepTwo}
                    button={
                        currentStatus === 'login' ? (
                            <LinkButton link={'https://www.linkedin.com/login'}>Log in to LinkedIn</LinkButton>
                        ) : (
                            currentStatus === 'error' && (
                                <>
                                    <ErrorList />
                                    <ActionButton onClick={handleConnect}>Connect</ActionButton>
                                </>
                            )
                        )
                    }
                />
                <ConnectionStep
                    label="Connect LinkedIn account to Andsend"
                    subTitle={
                        currentStatus === 'connecting'
                            ? 'Connecting your LinkedIn account to Andsend'
                            : 'Continue to Andsend and get started.'
                    }
                    isActive={isOnStepThree}
                    isCompleted={!isOnStepOne && !isOnStepTwo && currentStatus === 'continue'}
                    isLastStep
                    button={
                        // Will autoconnect on "connect" status anyways
                        currentStatus === 'connecting' || currentStatus === 'connect' ? (
                            <>
                                <ActionButton disabled>
                                    <CircularProgress size={15} sx={{ marginRight: '12px', color: 'inherit' }} />
                                    Connecting
                                </ActionButton>
                                {Boolean(connectingText && typeof connectingText === 'string') && (
                                    <Typography
                                        sx={{
                                            color: '#3A4B59',
                                            fontSize: 15,
                                            fontWeight: 400,
                                            textWrap: 'balance',
                                            textAlign: 'center',
                                        }}
                                    >
                                        {connectingText}
                                    </Typography>
                                )}
                            </>
                        ) : (
                            currentStatus === 'continue' && (
                                <ActionButton onClick={handleContinue}>
                                    Continue <ArrowRight size={15} style={{ marginLeft: '8px', color: 'inherit' }} />
                                </ActionButton>
                            )
                        )
                    }
                />
            </Box>
        </Box>
    );
};

const ConnectionStep = ({
    label,
    subTitle,
    isActive,
    isCompleted,
    button,
    isFirstStep,
    isLastStep,
}: {
    label: string;
    subTitle?: string;
    isActive: boolean;
    isCompleted: boolean;
    button: React.ReactNode;
    isFirstStep?: boolean;
    isLastStep?: boolean;
}) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {!isFirstStep && (
                    <Box
                        sx={{
                            position: 'relative',
                            width: 2,
                            height: 0,
                            marginBottom: '4px',
                            overflow: 'visible',
                        }}
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                                bottom: 0,
                                width: '100%',
                                height: 20,
                                backgroundColor: isCompleted || isActive ? paletteAndsend.primary.main : '#EAECF0',
                            }}
                        />
                    </Box>
                )}
                <Box
                    sx={{
                        width: 32,
                        height: 32,
                        flexShrink: 0,
                        borderRadius: '50%',
                        backgroundColor: isActive ? '#9E77ED3D' : null,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Box
                        sx={{
                            width: 24,
                            height: 24,
                            borderRadius: '50%',
                            backgroundColor: isCompleted || isActive ? paletteAndsend.primary.main : 'white',
                            borderColor: '#D0D5DD',
                            borderWidth: '1px',
                            borderStyle: 'solid',
                            color: 'white',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {isCompleted ? (
                            '✓'
                        ) : (
                            <Box
                                sx={{
                                    width: 8,
                                    height: 8,
                                    borderRadius: '50%',
                                    backgroundColor: isActive ? 'white' : '#D0D5DD',
                                }}
                            />
                        )}
                    </Box>
                </Box>

                <Box
                    sx={{
                        width: 2,
                        height: '100%',
                        marginTop: '4px',
                        backgroundColor: isLastStep ? null : isCompleted ? paletteAndsend.primary.main : '#EAECF0',
                    }}
                />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', alignItems: 'flex-start' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '8px' }}>
                    <Typography color={isActive ? 'textPrimary' : 'textSecondary'} sx={{ fontWeight: 'bold' }}>
                        {label}
                    </Typography>
                    {isActive && (
                        <Typography color={'textSecondary'} sx={{ marginTop: '4px' }}>
                            {subTitle}
                        </Typography>
                    )}
                </Box>
                {Boolean(isActive) && button}
            </Box>
        </Box>
    );
};
