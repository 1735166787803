export function getFlatBlocklist(blocklist): unknown {
    return Object.values(blocklist).reduce((acc: any, curr: any) => (acc = [...acc, ...Object.values(curr)]), []);
}

export function getBlocklistByFirstCharacter(flatBlocklist: any[]) {
    const tempBlacklist = {};

    for (let i = 0; i < flatBlocklist.length; i++) {
        if (!flatBlocklist[i].name) continue;

        const organizationFirstLetter = flatBlocklist[i].name.charAt(0).toUpperCase();

        if (!tempBlacklist[organizationFirstLetter]) {
            tempBlacklist[organizationFirstLetter] = {};
        }
        tempBlacklist[organizationFirstLetter][flatBlocklist[i].name] = {
            name: flatBlocklist[i].name,
            deleted: flatBlocklist[i].deleted,
        };
    }

    return tempBlacklist;
}
