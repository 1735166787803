import { DropdownMenu } from '@shadcn/ui/components/ui/dropdown-menu';

import { TooltipProvider, TooltipTrigger, Tooltip } from '@shadcn/ui/components/ui/tooltip';

import { useToast } from '@shadcn/ui/hooks/use-toast';
import { cn } from '@shadcn/ui/lib/utils';
import { GET_GROUPS } from '@zaplify/graphql';
import { ContactSource } from '@zaplify/services/user-contacts/shared';
import { Loader2, Plus, ChevronDown } from 'lucide-react';
import { useMemo } from 'react';
import { ContactToAdd, useAddContacts } from '../hooks/use-add-contacts';
import { useQuery } from '@apollo/client';
import { TooltipContent } from '@shadcn/ui/components/ui/tooltip';
import { Button } from '@shadcn/ui/components/ui/button';
import { DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@shadcn/ui/components/ui/dropdown-menu';

interface BulkAddContactsButtonProps {
    contacts: ContactToAdd[];
    className?: string;
    variant?: 'default' | 'secondary' | 'ghost' | 'outline';
    source: ContactSource;
    disabled?: boolean;
    size?: 'default' | 'sm';
    onAdded?: () => void;
    onStartedAdding?: () => void;
    addToGroupId?: string;
}

export function BulkAddContactsButton({
    contacts,
    className,
    variant = 'default',
    source,
    disabled: buttonDisabled,
    size = 'default',
    onAdded,
    onStartedAdding,
    addToGroupId,
}: BulkAddContactsButtonProps) {
    const { toast } = useToast();
    const { addContacts, purchasingInProgress } = useAddContacts();
    const { data: playbooks, loading: loadingPlaybooks } = useQuery(GET_GROUPS);
    const groups = playbooks?.Groups || [];

    const defaultPlaybook = useMemo(() => {
        if (addToGroupId) {
            return playbooks?.Groups?.find((group) => group.id === addToGroupId)?.name || 'Default';
        }
        return playbooks?.Groups?.find((group) => group.default)?.name || 'Default';
    }, [playbooks, addToGroupId]);

    const handleAddClick = async (groupId?: string) => {
        const result = await addContacts(contacts, {
            groupId: groupId || addToGroupId,
            source,
            onStartedAdding,
            onAdded: () => {
                const selectedGroup = playbooks?.Groups?.find((g) => g.id === (groupId || addToGroupId));
                const groupName = selectedGroup?.name || playbooks?.Groups?.find((g) => g.default)?.name || 'Default';

                toast({
                    title: 'Contacts added',
                    description: `${contacts.length} contacts assigned to playbook ${groupName}. Actions will be created for you to start conversations.`,
                    duration: 10000,
                });

                onAdded?.();
            },
        });
    };

    const disabled = purchasingInProgress || contacts.length === 0 || buttonDisabled;

    const startIcon = purchasingInProgress ? (
        <Loader2 className="w-5 h-5 animate-spin" />
    ) : (
        <Plus className="w-5 h-5" strokeWidth={1} />
    );

    const buttonText = purchasingInProgress
        ? `Adding ${contacts.length} contacts...`
        : `Add ${contacts.length} contacts`;

    return (
        <div className={cn('flex', 'h-fit', className)}>
            <TooltipProvider>
                <Tooltip>
                    <TooltipTrigger asChild>
                        <Button
                            variant={variant}
                            disabled={disabled}
                            size={size}
                            className={cn(
                                'gap-2 whitespace-nowrap w-full',
                                !loadingPlaybooks && groups.length >= 2 && 'rounded-r-none',
                                variant === 'secondary' && 'border border-gray-200',
                                variant === 'ghost' && 'bg-transparent hover:bg-transparent',
                                size === 'sm' && 'text-xs'
                            )}
                            onClick={() => handleAddClick()}
                        >
                            {startIcon}
                            <span>{buttonText}</span>
                        </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                        <p>
                            Add to {defaultPlaybook} playbook {!addToGroupId && '(your default playbook)'}
                        </p>
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>

            {!loadingPlaybooks && groups.length >= 2 && (
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button
                            variant={variant}
                            size={size}
                            disabled={disabled}
                            className={cn(
                                'rounded-l-none border-l-[0.5px] px-3',
                                variant === 'secondary' && 'border border-gray-200 border-l-0',
                                variant === 'ghost' && 'bg-transparent hover:bg-transparent',
                                size === 'sm' && 'text-xs'
                            )}
                        >
                            <ChevronDown className={cn('w-4 h-4', size === 'sm' && 'w-3 h-3')} strokeWidth={1} />
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="start">
                        {groups.map((group) => (
                            <DropdownMenuItem key={group.id} onClick={() => handleAddClick(group.id)}>
                                {group.name.length > 30 ? `${group.name.slice(0, 30)}...` : group.name}
                            </DropdownMenuItem>
                        ))}
                    </DropdownMenuContent>
                </DropdownMenu>
            )}
        </div>
    );
}
